<template>
  <v-card>
    <v-toolbar color="primary" dark flat>
      <v-app-bar-nav-icon />

      <v-toolbar-title>Schematics</v-toolbar-title>

      <v-spacer />

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="device in devicesPerRoomList" :key="device.id">
            <v-list-item-title>
              <v-btn
                :disabled="isDeviceAdded(device)"
                @click="addSelectedDevice(device)"
                block
              >
                <v-icon left>
                  {{ `mdi-${getIconByDeviceType(device.device.type)}` }}
                </v-icon>
                {{ device.room.name }}
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <template v-slot:extension>
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="accentOrange" />

          <v-tab v-for="tab in tabs" :key="tab">
            {{ tab }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="room in rooms" :key="room.id">
        <v-card flat @click="addHotspot($event)">
          <v-card-text style="position: relative; height: 100%">
            <DraggableZoomable
              class="dropzone"
              :hotspotsPositionsList="hotspotsPositionsList"
              :addedDevices="addedDevices"
              :room="room"
              @update:x="(x) => (currentX = x)"
              @update:y="(y) => (currentY = y)"
              @update:scale="(scale) => (currentScale = scale)"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import DraggableZoomable from '@/Core.Service.Domain/Controls/DigitalTwins/Views/DraggableZoomable.vue';

export default {
  name: 'DigitalTwinsView',

  components: {
    DraggableZoomable,
  },

  data(instance) {
    return {
      currentX: 0,
      currentY: 0,
      currentScale: 1,
      tab: null,
      currentDrag: null,
      rooms: [
        {
          id: 0,
          actionButtons: [
            {
              icon: (item) => instance.getIconByDeviceType(item.device.type),
              toolTip: () => '',
              action: async (item) => {
                console.log('Action button clicked', item);
              },
            },
          ],
        },
      ],
      devicesPerRoomList: [
        {
          id: 0,
          room: {
            name: 'Living Room',
            description: 'Information about this room',
          },
          device: {
            type: 'Thermostat',
            brand: 'Aginova',
            lastUpdated: '3 minutes ago',
            location: {
              lat: `33.59391`,
              long: `-117.72364`,
            },
            settings: {
              temperature: {
                main: true,
                show: true,
                text: 'Temperature',
                value: '70°F',
              },
              co2Levels: {
                show: true,
                text: 'CO2 Levels',
                value: 492,
              },
              co2Setting: {
                show: true,
                text: 'CO2 Setting',
                value: 500,
              },
              status: {
                show: true,
                text: 'Status',
                value: 'On',
              },
              heatSetting: {
                show: true,
                text: 'Heat Setting',
                value: 70,
              },
              coolSetting: {
                show: true,
                text: 'Cool Setting',
                value: 70,
              },
              system: {
                show: true,
                text: 'System',
                value: 'Cool',
              },
              fan: {
                show: true,
                text: 'Fan',
                value: 'Auto',
              },
              frontKeyPad: {
                show: true,
                text: 'Front Keypad',
                value: 'Unlocked',
              },
            },
          },
        },
        {
          id: 1,
          room: {
            name: 'Kitchen',
            description: 'Information about this room',
          },
          device: {
            type: 'Power Control',
            brand: 'Aginova',
            lastUpdated: '3 minutes ago',
            location: {
              lat: `33.59391`,
              long: `-117.72364`,
            },
            settings: {
              isRunningOnSchedule: {
                show: true,
                text: 'Running on Schedule',
                value: 'Yes',
              },
              isDeviceOn: {
                main: true,
                show: true,
                text: 'Device is On',
                value: 'On',
              },
            },
          },
        },
        {
          id: 2,
          room: {
            name: 'Bedroom',
            description: 'Information about this room',
          },
          device: {
            type: 'IAQ Sensor',
            brand: 'Aginova',
            lastUpdated: '3 minutes ago',
            location: {
              lat: `33.59391`,
              long: `-117.72364`,
            },
            settings: {
              maxCO2Reading: {
                main: true,
                show: true,
                text: 'Max CO2 Reading',
                value: 492,
              },
            },
          },
        },
        {
          id: 3,
          room: {
            name: 'Bathroom',
            description: 'Information about this room',
          },
          device: {
            type: 'Power Control',
            brand: 'Pelican',
            lastUpdated: '3 minutes ago',
            location: {
              lat: `33.59391`,
              long: `-117.72364`,
            },
            settings: {
              isRunningOnSchedule: {
                show: true,
                text: 'Running on Schedule',
                value: 'Yes',
              },
              isDeviceOn: {
                main: true,
                show: true,
                text: 'Device is On',
                value: 'On',
              },
            },
          },
        },
        {
          id: 4,
          room: {
            name: 'Main bedroom',
            description: 'Information about this room',
          },
          device: {
            type: 'Thermostat',
            brand: 'Pelican',
            lastUpdated: '3 minutes ago',
            location: {
              lat: `33.59391`,
              long: `-117.72364`,
            },
            settings: {
              temperature: {
                main: true,
                show: true,
                text: 'Temperature',
                value: '72°F',
              },
              co2Levels: {
                show: true,
                text: 'CO2 Levels',
                value: 492,
              },
              co2Setting: {
                show: true,
                text: 'CO2 Setting',
                value: 500,
              },
              status: {
                show: true,
                text: 'Status',
                value: 'On',
              },
              heatSetting: {
                show: true,
                text: 'Heat Setting',
                value: 70,
              },
              coolSetting: {
                show: true,
                text: 'Cool Setting',
                value: 70,
              },
              system: {
                show: true,
                text: 'System',
                value: 'Cool',
              },
              fan: {
                show: true,
                text: 'Fan',
                value: 'Auto',
              },
              frontKeyPad: {
                show: true,
                text: 'Front Keypad',
                value: 'Unlocked',
              },
            },
          },
        },
      ],
      tabs: ['1st', '2nd', '3rd', '4th', '5th'],
      hotspotsPositionsList: [],
      addedDevices: [],
      selectedDevice: null,
    };
  },

  methods: {
    getIconByDeviceType(type) {
      switch (type) {
        case 'Thermostat':
          return 'thermometer';
        case 'Power Control':
          return 'power';
        case 'IAQ Sensor':
          return 'air-filter';
        default:
          return 'information';
      }
    },

    addHotspot(event) {
      if (this.selectedDevice) {
        if (!this.currentDrag) {
          const rect = event.target.getBoundingClientRect();
          const scaledX = (event.clientX - rect.left) / this.currentScale;
          const scaledY = (event.clientY - rect.top) / this.currentScale;
          this.hotspotsPositionsList.push({ x: scaledX, y: scaledY });
        }
      }
      this.selectedDevice = null;
    },

    addSelectedDevice(device) {
      const found = this.addedDevices.find((item) => item.id === device.id);
      if (!found) {
        this.selectedDevice = device;
        const deviceToPush = { ...this.selectedDevice };
        this.addedDevices.push(deviceToPush);
      }
    },

    isDeviceAdded(device) {
      const found = this.addedDevices.find((item) => item.id === device.id);
      return Boolean(found);
    },
  },
};
</script>
