import DigitalTwinsRoutes from '@/Core.Service.Domain/Controls/DigitalTwins/Views/DigitalTwins.vue';

export const DigitalTwinsRoute = {
  path: '/digital-twins',
  name: 'DigitalTwins',
  component: DigitalTwinsRoutes,
  meta: {
    placeholder: 'Search for a Digital Twin',
    controlTypeName: 'Digital Twin',
    label: 'Digital Twins',
    toolTip: 'Digital Twin',
  },
};
