import { ThermostatsRoute } from '@/Core.Service.Domain/Controls/Thermostats/Routes';
import { PowerControlsRoute } from '@/Core.Service.Domain/Controls/PowerControls/Routes';

import { DigitalTwinsRoute } from '@/Core.Service.Domain/Controls/DigitalTwins/Routes';

const ControlsView = () =>
  import('@/Core.Service.Domain/Controls/Controls.Common/Views');

const routesList = [];

const ControlsRoute = {
  path: '/controls',
  name: 'Controls',
  children: [ThermostatsRoute, PowerControlsRoute, DigitalTwinsRoute],
  component: ControlsView,
  meta: { permission: 'controls:read' },
};

const ControlsEditRoute = {
  path: '/controls/:type/:controlId',
  name: 'ControlsEdit',
  component: () => import('@/modules/controls/_components/Edit'),
};

routesList.push(ControlsRoute, ControlsEditRoute);

export default routesList;
