<template>
  <div
    class="draggable"
    :style="styleObject"
    @mousedown="handleMouseDown"
    @wheel="handleWheel"
    @touchstart="handleTouchStart"
    @drop.prevent="onDrop($event, room)"
    @dragover.prevent
    @mouseup="buttonClicked = false"
  >
    <div
      v-for="(hotspot, index) in hotspotsPositionsList"
      :key="index"
      class="hotspot"
      draggable="true"
      @mousedown="buttonClicked = true"
      @mouseup="buttonClicked = false"
      @dragstart="dragStart($event, hotspot, index)"
      @dragend="dragEnd"
      :style="{
        position: 'absolute',
        top: `${hotspot.y - 5}px`,
        left: `${hotspot.x - 12}px`,
      }"
    >
      <ActionButtonsVue
        :buttonsList="room.actionButtons"
        :toolTipOffsetOverflow="true"
        :item="addedDevices[index]"
        :color="getColorByDeviceType(addedDevices[index].device.type)"
      >
        <template v-if="!currentDrag" v-slot:tooltip>
          <DigitalTwinsTooltip
            :tooltipInfo="addedDevices[index]"
            :color="getColorByDeviceType(addedDevices[index].device.type)"
            :icon="getIconByDeviceType(addedDevices[index].device.type)"
          >
            <template v-slot:default>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-left primary--text text-subtitle-2 font-weight-bold"
                      >
                        Room info
                      </th>
                      <th
                        class="text-left primary--text text-subtitle-2 font-weight-bold"
                      >
                        Data
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, key) in addedDevices[index].room"
                      :key="key"
                    >
                      <td class="primary--text">
                        {{ capitalizeFirstLetter(key) }}
                      </td>
                      <td class="primary--text">{{ value }}</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th
                        class="text-left primary--text text-subtitle-2 font-weight-bold"
                      >
                        Device info
                      </th>
                      <th
                        class="text-left primary--text text-subtitle-2 font-weight-bold"
                      >
                        Data
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, key) in removeSettingsFromDevice(
                        addedDevices[index].device
                      )"
                      :key="key"
                    >
                      <td class="primary--text">
                        {{ capitalizeFirstLetter(key) }}
                      </td>
                      <td class="primary--text">{{ value }}</td>
                    </tr>
                    <tr
                      v-for="(device, i) in filterDeviceSettingsByShow(
                        addedDevices[index].device.settings
                      )"
                      :key="i"
                    >
                      <td class="primary--text">{{ device.text }}</td>
                      <td class="primary--text">
                        {{ device.value }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </DigitalTwinsTooltip>
        </template>
      </ActionButtonsVue>
      <v-chip class="text-container" color="primary">
        <v-icon left>
          {{
            `mdi-${getIconForLabelByDeviceType(
              addedDevices[index].device.type
            )}`
          }}
        </v-icon>
        <span class="font-weight-bold">
          {{ getMainSetting(addedDevices[index].device).value }}
        </span>
      </v-chip>
    </div>
    <ImageView />
  </div>
</template>

<script>
import ImageView from '@/Core.Service.Domain/Controls/DigitalTwins/Components/Image.vue';
import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons';
import DigitalTwinsTooltip from '@/Core.Service.Domain/Controls/DigitalTwins/Components/DigitalTwinsTooltip.vue';

import Vue from 'vue';

export default {
  name: 'DraggableZoomable',
  components: {
    ActionButtonsVue,
    DigitalTwinsTooltip,
    ImageView,
  },
  props: {
    hotspotsPositionsList: {
      type: Array,
      required: true,
    },
    room: {
      type: Object,
      required: true,
    },
    addedDevices: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      x: 0,
      y: 0,
      scale: 1,
      isDragging: false,
      startX: 0,
      startY: 0,

      currentDrag: null,
      buttonClicked: false,
    };
  },

  watch: {
    x: {
      handler() {
        this.$emit('update:x', this.x);
      },
      immediate: true,
    },
    y: {
      handler() {
        this.$emit('update:y', this.y);
      },
      immediate: true,
    },
    scale: {
      handler() {
        this.$emit('update:scale', this.scale);
      },
      immediate: true,
    },
  },

  computed: {
    styleObject() {
      return {
        transform: `translate(${this.x}px, ${this.y}px) scale(${this.scale})`,
        transition: 'transform 0.1s ease',
        cursor: this.isDragging ? 'grabbing' : 'grab',
      };
    },
  },
  methods: {
    handleMouseDown(event) {
      if (this.buttonClicked) return;
      this.isDragging = true;
      this.startX = event.clientX - this.x;
      this.startY = event.clientY - this.y;

      document.addEventListener('mousemove', this.handleMouseMove);
      document.addEventListener('mouseup', this.handleMouseUp);
    },
    handleMouseMove(event) {
      if (!this.isDragging) return;
      this.x = event.clientX - this.startX;
      this.y = event.clientY - this.startY;
    },
    handleMouseUp() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.handleMouseMove);
      document.removeEventListener('mouseup', this.handleMouseUp);
    },
    handleWheel(event) {
      event.preventDefault();
      this.scale += event.deltaY * -0.01;
      // Restrict scale
      this.scale = Math.min(Math.max(0.125, this.scale), 4);
    },
    handleTouchStart(event) {
      if (event.touches.length === 1) {
        this.handleMouseDown(event.touches[0]);
      }
    },

    getColorByDeviceType(type, hex = false) {
      switch (type) {
        case 'Thermostat':
          return hex ? '#2E3C54' : 'primary';
        case 'Power Control':
          return hex ? '#D81159' : 'heatColor';
        case 'IAQ Sensor':
          return hex ? '#FF6F52' : 'accentOrange';
        default:
          return '#0000FF';
      }
    },

    removeSettingsFromDevice(device) {
      const deviceWithoutSettings = { ...device };
      delete deviceWithoutSettings.settings;
      return deviceWithoutSettings;
    },

    filterDeviceSettingsByShow(deviceSettings) {
      return Object.values(deviceSettings).filter((setting) => setting.show);
    },

    dragStart(event, hotspot, index) {
      this.currentDrag = {
        hotspot,
        index,
        offsetX: event.offsetX,
        offsetY: event.offsetY,
      };
      event.dataTransfer.effectAllowed = 'move';
    },

    dragEnd() {
      this.currentDrag = null;
    },

    handleHotspotClick(event, hotspot, index) {
      this.currentDrag = {
        hotspot,
        index,
        offsetX: event.offsetX,
        offsetY: event.offsetY,
      };
    },

    getIconByDeviceType(type) {
      switch (type) {
        case 'Thermostat':
          return 'thermometer';
        case 'Power Control':
          return 'power';
        case 'IAQ Sensor':
          return 'air-filter';
        default:
          return 'information';
      }
    },

    getIconForLabelByDeviceType(type) {
      switch (type) {
        case 'Thermostat':
          return 'thermometer';
        case 'Power Control':
          return 'power-cycle';
        case 'IAQ Sensor':
          return 'molecule-co2';
        default:
          return 'information';
      }
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    onDrop(event) {
      if (this.currentDrag) {
        event.preventDefault();

        const dropZoneRect = event.currentTarget.getBoundingClientRect();
        const x =
          (event.clientX - dropZoneRect.left - this.currentDrag.offsetX + 12) /
          this.scale;
        const y =
          (event.clientY - dropZoneRect.top - this.currentDrag.offsetY + 5) /
          this.scale;

        Vue.set(this.hotspotsPositionsList, this.currentDrag.index, {
          ...this.currentDrag.hotspot,
          x: x,
          y: y,
        });

        this.currentDrag = null;
      }
    },

    getMainSetting(device) {
      return Object.values(device.settings).find((setting) =>
        setting.main ? setting.main : false
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.draggable
  width: 100%
  height: 100%
  color: white
  display: flex
  align-items: center
  justify-content: center
  user-select: none
  border-radius: 10px

.hotspot
  cursor: pointer
  width: 80px
  height: 80px
  border-radius: 50%
  z-index: 1
  transition: background-color 0.5s
  &:hover
    background-color: rgba(46, 60, 84, 0.5)
    transition: background-color 0.5s
    .text-container
      opacity: 0
      transition: opacity 0.5s

.hotspot::v-deep
  .action-button
    margin-left: 25px
    margin-top: 14px

.text-container
  position: absolute
  // box-sizing: border-box
  // min-width: 150px
  // height: 40px
  // padding: 10px
  top: 0px
  left: 50%
  transform: translate(-50%, -50%)
  // line-height: 0.1
  // font-size: 14px
  // font-weight: bold
  // color: white
  // text-align: center
  // background-color: rgba(46, 60, 84, 0.7)
  // border-radius: 5px
  transition: opacity 0.5s
</style>
