<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    viewBox="0 0 2551 1292"
    enable-background="new 0 0 2551 1292"
    xml:space="preserve"
  >
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1.000002,1004.000000 
	C1.000000,669.694824 1.000000,335.389618 1.000000,1.042207 
	C851.233643,1.042207 1701.467285,1.042207 2552.000000,1.042207 
	C2552.000000,428.697540 2552.000000,856.395142 2551.670898,1284.922363 
	C2496.437256,1286.527344 2441.532959,1287.330078 2386.627686,1288.063354 
	C2367.027344,1288.325195 2347.424316,1288.379883 2327.823486,1288.614990 
	C2322.696777,1288.676392 2317.573975,1289.059204 2312.127441,1289.146606 
	C2310.566650,1288.892822 2309.327881,1288.786865 2307.614746,1288.689453 
	C2299.656006,1288.782349 2292.171631,1288.888306 2284.686768,1288.947876 
	C2243.462158,1289.275757 2202.237793,1289.592163 2160.648438,1289.736572 
	C2148.534424,1289.773071 2136.785400,1289.985596 2124.565918,1290.170166 
	C2108.734131,1290.368164 2093.372070,1290.594116 2077.801270,1290.632812 
	C2076.723145,1290.570435 2075.853760,1290.695435 2074.549805,1290.778076 
	C2072.079590,1290.782715 2070.043945,1290.829712 2067.842285,1290.717041 
	C2067.114014,1290.667969 2066.552002,1290.778687 2065.595215,1290.829346 
	C2064.137451,1290.802856 2063.074707,1290.836548 2061.841797,1290.715942 
	C2061.109863,1290.659790 2060.548096,1290.757812 2059.531250,1290.819458 
	C2055.718750,1290.847046 2052.361084,1290.910889 2048.628906,1290.833008 
	C2041.360840,1290.727417 2034.466187,1290.698120 2027.576416,1290.869385 
	C2026.527588,1290.895386 2023.691650,1289.731201 2025.000000,1293.000000 
	C2022.298584,1293.000000 2019.597046,1293.000000 2016.823120,1292.649658 
	C2014.149658,1291.972412 2011.548584,1291.645386 2008.524902,1291.266357 
	C2007.401489,1291.809448 2006.700806,1292.404785 2006.000000,1293.000000 
	C2004.286499,1293.000000 2002.572998,1293.000000 2000.554443,1292.574463 
	C1999.875488,1292.067383 1999.501709,1291.985962 1999.127686,1291.904541 
	C1999.085083,1292.269653 1999.042480,1292.634888 1999.000000,1293.000000 
	C1992.969116,1293.000000 1986.938110,1293.000000 1980.768799,1292.691162 
	C1979.415771,1292.120483 1978.201294,1291.858643 1976.986694,1291.596802 
	C1976.991211,1292.064575 1976.995605,1292.532227 1977.000000,1293.000000 
	C1975.286499,1293.000000 1973.572998,1293.000000 1971.764893,1292.692871 
	C1970.753906,1292.093262 1969.837524,1291.800781 1968.921265,1291.508179 
	C1968.947510,1292.005493 1968.973755,1292.502686 1969.000000,1293.000000 
	C1968.250000,1293.000000 1967.500000,1293.000000 1966.689697,1292.667969 
	C1963.571167,1290.415527 1962.370605,1290.587769 1962.000000,1293.000000 
	C1479.302368,1293.000000 996.604797,1293.000000 513.482605,1292.531982 
	C512.746582,1269.598633 512.398438,1247.133789 512.132446,1224.667725 
	C511.750122,1192.380615 511.405243,1160.093140 511.087860,1127.805298 
	C510.894775,1108.166504 510.783630,1088.526733 510.692352,1068.430908 
	C510.433228,1051.923950 510.117401,1035.873535 509.797546,1019.620056 
	C507.676086,1019.500671 506.206604,1019.334167 504.738831,1019.347961 
	C479.947266,1019.581177 455.156250,1019.875366 430.364471,1020.076599 
	C405.565063,1020.277893 380.764740,1020.368103 355.965302,1020.566711 
	C351.999695,1020.598450 348.036591,1020.942688 343.602722,1021.067383 
	C302.167511,1021.299866 261.201874,1021.607361 220.236267,1021.914856 
	C220.238403,1022.645752 220.240524,1023.376648 220.242645,1024.107544 
	C222.130905,1024.107544 224.019302,1024.122803 225.907410,1024.105469 
	C264.148956,1023.754028 302.390533,1023.401184 340.631927,1023.032898 
	C341.787933,1023.021790 342.942291,1022.840454 344.556427,1022.792480 
	C357.315491,1022.645569 369.614868,1022.366943 381.915710,1022.263245 
	C398.036621,1022.127319 414.158844,1022.157654 430.280212,1022.065796 
	C454.569489,1021.927551 478.858582,1021.696045 503.147766,1021.691467 
	C504.791290,1021.691162 507.832825,1023.210205 507.864349,1024.105591 
	C508.207275,1033.857056 508.078918,1043.625244 508.078918,1053.937378 
	C496.289276,1053.937378 485.020477,1053.937378 473.422333,1053.955688 
	C472.213623,1053.967407 471.334229,1053.960693 469.941772,1053.960205 
	C465.248657,1054.084473 461.068604,1054.202515 456.431030,1054.266235 
	C442.849640,1054.426758 429.726288,1054.687622 416.601746,1054.847168 
	C386.301697,1055.215698 356.001343,1055.560913 325.700592,1055.867676 
	C286.741547,1056.262329 247.781631,1056.573608 208.822968,1057.000122 
	C197.192825,1057.127441 185.565567,1057.520508 173.540054,1057.623657 
	C150.095673,1057.598755 127.048233,1057.742065 103.944992,1057.564087 
	C102.925880,1057.457764 101.962563,1057.672729 100.611435,1057.952637 
	C99.176384,1058.058472 98.129143,1058.099365 96.612869,1058.059204 
	C83.688118,1058.020996 71.232010,1058.022949 58.777004,1058.140259 
	C56.178101,1058.164673 53.583202,1058.614868 50.518974,1058.854004 
	C34.422092,1058.830933 18.792690,1058.822632 3.087698,1058.364990 
	C2.957258,1053.850098 2.977241,1049.780640 2.766280,1045.723267 
	C2.734906,1045.119873 1.615856,1044.572998 1.000001,1044.000000 
	C1.000000,1041.958130 1.000000,1039.916382 1.374926,1037.722046 
	C1.993940,1035.854370 2.262764,1034.142212 2.476157,1032.423218 
	C2.776151,1030.006470 3.035003,1027.584717 3.770804,1025.433594 
	C27.817877,1025.432495 51.405598,1025.162720 75.280746,1025.196777 
	C91.061081,1025.295898 106.553986,1025.091064 122.403374,1024.900879 
	C123.195175,1024.566162 123.630508,1024.216797 124.065842,1023.867371 
	C123.399338,1023.638428 122.732819,1023.409485 121.599319,1023.101929 
	C117.641685,1022.996399 114.150055,1022.903137 110.660553,1022.954590 
	C98.715080,1023.130676 86.770348,1023.357666 74.405251,1023.478333 
	C70.513168,1023.511536 67.041840,1023.708740 63.569061,1023.738831 
	C43.340973,1023.914062 23.112549,1024.050049 2.549222,1024.012573 
	C1.809471,1023.883911 1.404736,1023.941956 1.000000,1024.000000 
	C1.000000,1021.958191 1.000000,1019.916321 1.390650,1017.592773 
	C1.520867,1012.873962 1.260435,1008.437012 1.000002,1004.000000 
M960.004395,458.994812 
	C960.004395,458.994812 959.977356,458.961761 959.454712,459.072754 
	C959.292358,459.373077 959.130005,459.673401 959.000000,460.000000 
	C959.000000,460.000000 958.973328,459.967560 958.451233,460.076965 
	C958.288818,460.376709 958.126404,460.676453 957.997437,461.002411 
	C957.997437,461.002411 957.976990,460.965851 957.463501,461.098877 
	C957.292419,461.396179 957.121338,461.693451 957.005859,461.997498 
	C957.005859,461.997498 956.989136,461.944092 956.498291,461.934357 
	C956.498291,461.934357 956.013489,462.012238 955.436462,462.227936 
	C954.601868,462.789001 953.767273,463.350067 952.868591,464.447083 
	C952.895386,464.625000 952.922119,464.802917 952.989502,465.008392 
	C952.989502,465.008392 952.980652,464.960144 952.459473,465.080811 
	C952.293335,465.380676 952.127197,465.680573 952.007324,465.991364 
	C952.007324,465.991364 951.980164,465.952240 951.228943,465.915710 
	C948.630005,466.745514 946.031128,467.575287 943.180969,468.485291 
	C943.331726,469.047333 943.578003,469.965668 943.278320,471.235474 
	C941.764221,472.731842 940.250122,474.228210 938.348206,476.006653 
	C938.103027,476.217926 937.900024,476.463959 937.087769,476.848328 
	C936.062439,477.569061 935.037109,478.289795 933.382996,479.107605 
	C932.928772,479.746094 932.474487,480.384552 931.334839,481.134583 
	C925.116516,485.373779 918.797974,489.505280 915.522278,495.222656 
	C913.478149,495.858826 912.065552,495.912811 911.216003,496.631897 
	C908.105835,499.264587 905.423767,502.730164 902.086182,501.034943 
	C900.533630,503.588623 899.738037,506.064026 898.051880,507.476318 
	C888.946594,515.103088 879.775208,522.672119 870.283386,529.804260 
	C867.139404,532.166565 864.975464,537.138428 859.565674,535.368652 
	C859.677673,535.918396 859.789673,536.468079 859.725220,537.634827 
	C859.195618,537.995850 858.666077,538.356934 857.575745,538.200195 
	C835.372864,527.823669 813.169983,517.447205 790.280029,506.888092 
	C788.029419,505.571289 785.838989,504.329529 783.324463,503.332428 
	C774.279114,499.745544 765.600220,495.234283 756.859741,490.142548 
	C756.526672,486.274292 757.979004,482.074707 754.877563,478.651855 
	C754.098511,480.970398 754.345520,483.244385 754.006104,485.427155 
	C753.801758,486.741302 752.684265,487.913422 751.495667,488.650421 
	C730.233887,478.589752 708.959351,468.555725 687.741943,458.402374 
	C686.466553,457.792084 685.647034,456.229370 684.832947,454.171631 
	C684.547913,427.594086 684.214844,401.016907 683.988647,374.438873 
	C683.618225,330.915497 683.371460,287.391052 682.963989,243.868073 
	C682.764526,222.567230 682.261536,201.269363 682.009644,179.968811 
	C681.859924,167.306671 682.204224,154.632523 681.767334,141.983078 
	C681.596497,137.036865 683.022156,134.590790 687.726440,132.947220 
	C696.230164,129.976181 699.966003,122.662804 700.679688,114.480919 
	C701.445496,105.701973 697.083557,98.606812 689.251892,94.437134 
	C680.948914,90.016502 672.918884,91.302124 665.479187,97.029274 
	C656.019165,104.311668 654.372437,118.796631 663.957397,127.251617 
	C668.164246,130.962479 673.379639,133.529953 679.415344,137.445908 
	C679.415344,169.007614 679.415344,202.215118 679.415344,236.262299 
	C664.543091,236.262299 649.638000,236.262299 634.732849,236.262299 
	C634.727173,236.829224 634.721436,237.396164 634.715698,237.963089 
	C636.600525,237.963089 638.485352,237.964890 640.370178,237.962814 
	C652.200562,237.949768 664.032227,238.025421 675.860596,237.863312 
	C679.075989,237.819244 680.192810,238.795807 680.220947,242.103394 
	C680.366089,259.154816 680.859009,276.203278 680.998230,293.254700 
	C681.401917,342.715515 681.690125,392.177277 682.039062,441.638550 
	C682.070740,446.132812 682.213684,450.626251 681.617615,455.234039 
	C681.385986,455.250305 681.154297,455.266602 680.636475,454.727417 
	C680.060364,454.619202 679.484253,454.510986 678.318115,454.001434 
	C662.895447,446.359741 647.512207,438.636993 632.035095,431.107239 
	C622.958862,426.691559 613.683044,422.687286 604.586243,418.312439 
	C590.849731,411.706238 577.219971,404.878296 563.492493,398.253143 
	C545.122620,389.387451 526.653625,380.726288 508.318817,371.789246 
	C491.643005,363.660767 475.162842,355.129883 458.465332,347.047363 
	C433.571869,334.997528 408.546295,323.220734 383.644165,311.188568 
	C382.099854,310.442383 381.140747,308.485016 379.998962,306.140656 
	C379.953094,285.553131 380.103271,264.961517 379.674591,244.381989 
	C379.600311,240.816360 380.217651,239.930618 383.395844,240.005890 
	C387.045715,240.092361 390.699249,240.025940 394.351227,240.025940 
	C394.352722,239.386841 394.354248,238.747757 394.355774,238.108658 
	C389.421387,238.108658 384.487030,238.108658 378.550293,238.108658 
	C378.550293,219.012253 378.550293,200.752457 378.550293,182.492661 
	C377.718506,182.495865 376.886749,182.499084 376.054962,182.502289 
	C376.054962,185.306961 376.002289,188.112778 376.063782,190.916077 
	C376.372650,204.993515 376.621063,219.073395 377.101837,233.145187 
	C377.224945,236.748856 376.326141,238.239655 372.426727,238.059402 
	C366.115448,237.767639 359.779144,237.968292 353.454529,238.050522 
	C351.989868,238.069580 350.529877,238.449722 349.067749,238.663696 
	C349.069244,239.214996 349.070740,239.766296 349.072235,240.317596 
	C350.540710,240.530151 352.007233,240.908997 353.477936,240.925980 
	C359.303345,240.993240 365.150818,241.248062 370.949829,240.848938 
	C375.669617,240.524078 377.144226,241.918381 377.160706,246.826218 
	C377.228180,266.930481 377.772705,287.033173 377.681061,307.635376 
	C377.048737,307.590424 376.334808,307.722687 375.795990,307.474670 
	C362.277740,301.251190 348.731567,295.085449 335.287231,288.705322 
	C320.028931,281.464386 304.898651,273.953888 289.647980,266.696655 
	C279.044220,261.650726 268.294983,256.910858 257.681244,251.885452 
	C251.803833,249.102615 246.071365,246.013641 240.273575,243.062668 
	C240.358643,242.735474 240.443695,242.408295 240.528748,242.081100 
	C243.460617,241.852692 246.392471,241.624268 249.324326,241.395859 
	C249.308044,240.954132 249.291748,240.512405 249.275467,240.070679 
	C244.307343,240.070679 239.339218,240.070679 233.776352,240.070679 
	C233.776352,233.714844 233.784119,228.090668 233.774811,222.466522 
	C233.752151,208.758453 233.748962,208.758453 231.328369,206.027420 
	C231.328369,217.530441 231.328369,228.420074 231.328369,240.342560 
	C222.801651,240.342560 214.793289,240.342560 206.784943,240.342560 
	C206.807831,240.811111 206.830719,241.279678 206.853607,241.748230 
	C213.656433,241.748230 220.461624,241.852982 227.261032,241.700363 
	C230.282547,241.632523 231.340424,242.718475 231.370850,245.749893 
	C231.497696,258.391998 231.726669,271.035309 232.139694,283.670898 
	C232.252136,287.110718 231.298416,288.493896 227.699265,288.321075 
	C223.575241,288.123077 219.426025,288.454742 215.287613,288.549194 
	C212.825577,288.605438 211.504166,289.664459 212.043869,292.289886 
	C213.575119,299.738708 217.224014,305.617279 224.321747,309.015533 
	C225.031143,309.355194 225.432556,310.338165 226.026016,311.008301 
	C226.026016,311.008301 226.037109,310.958405 225.297806,311.062683 
	C223.390762,312.385986 221.483704,313.709290 218.929306,315.110962 
	C217.282394,317.711578 215.635498,320.312164 213.456009,323.439484 
	C210.868790,331.343323 211.190948,331.773926 219.667023,331.752563 
	C222.331467,331.745819 225.009201,331.908813 227.657028,331.696716 
	C231.010254,331.428162 232.400055,332.480743 232.394043,336.116760 
	C232.380112,344.545410 232.817108,352.974365 233.036957,361.404083 
	C233.100281,363.832001 233.046661,366.262970 233.046661,369.683716 
	C190.733170,369.683716 149.251434,369.683716 106.959023,369.683716 
	C106.959023,365.886017 106.959023,361.443207 107.477280,356.362579 
	C107.477280,351.302368 107.477280,346.242157 107.477280,341.181946 
	C106.775230,341.186462 106.073174,341.190979 105.371124,341.195496 
	C105.080238,346.443146 104.230476,351.665161 105.730316,357.804901 
	C105.589630,360.929626 105.538239,364.062286 105.260262,367.174774 
	C105.161690,368.278412 104.511414,369.332794 103.173370,370.196716 
	C90.867165,370.184601 78.559372,370.261017 66.256882,370.040802 
	C64.827881,370.015198 62.380455,368.520691 62.195187,367.408875 
	C61.362083,362.409515 58.837326,358.709930 55.486507,355.027771 
	C48.474277,347.322144 36.628723,347.368256 28.764460,353.456360 
	C19.990154,360.248962 17.309912,374.078308 23.031914,382.118896 
	C27.789042,388.803619 34.492535,393.188843 42.815319,392.513367 
	C51.719952,391.790710 58.479248,386.702850 61.149097,377.958099 
	C62.516945,373.477844 64.936768,372.969849 68.781082,372.956024 
	C80.568489,372.913727 92.354942,372.603973 104.825729,372.885986 
	C105.182610,374.611725 105.813469,376.331848 105.848701,378.064148 
	C105.997360,385.373505 105.968491,392.686432 105.952133,400.801971 
	C105.988594,401.875824 106.025047,402.949677 105.984428,404.872192 
	C106.009277,406.574066 106.034134,408.275940 105.969261,410.914368 
	C105.975349,419.609314 105.981445,428.304230 105.564690,437.312561 
	C105.728973,437.873291 105.893265,438.434021 106.017044,439.932037 
	C106.061783,467.063568 106.039932,494.195526 106.228828,521.326050 
	C106.254753,525.051697 105.136368,526.311523 101.432770,526.203674 
	C94.282410,525.995361 87.119583,526.080139 79.964783,526.205872 
	C75.105225,526.291321 70.250526,526.653381 65.035324,526.909302 
	C65.228493,517.838013 60.368217,512.215576 53.828823,508.116943 
	C45.471107,502.878540 35.095535,505.584290 27.128271,514.378906 
	C19.301929,523.017944 22.675539,542.360107 36.570961,546.856384 
	C48.221481,550.626221 60.131165,546.493347 64.090591,532.918091 
	C64.973923,529.889404 66.356606,528.795471 69.645035,528.820923 
	C77.607384,528.882629 85.572784,528.361389 93.539192,528.166931 
	C97.809891,528.062683 102.085220,528.148438 106.986046,528.148438 
	C106.986046,546.503235 106.986046,564.266296 106.982033,582.493469 
	C106.982033,582.493469 106.981277,582.957581 106.952431,583.902771 
	C106.970222,591.814880 106.900246,599.728577 107.048378,607.638245 
	C107.108818,610.865417 107.642029,614.083740 107.948456,617.748901 
	C107.940948,618.044250 107.939171,618.339539 107.862915,619.641235 
	C107.904610,639.095642 107.946304,658.549988 107.905930,678.792358 
	C107.920700,679.324890 107.935463,679.857483 107.921684,680.524292 
	C107.921684,680.524292 107.924889,680.661499 107.926872,681.414185 
	C107.946205,683.608215 107.965538,685.802307 107.561234,688.481384 
	C107.742767,689.644775 107.924294,690.808228 108.071777,692.873901 
	C108.071777,701.431580 108.071777,709.989258 108.071777,719.051636 
	C103.075470,719.051636 98.114395,719.132324 93.157478,719.023438 
	C89.816063,718.950134 88.658867,720.525330 89.059021,723.654663 
	C89.949310,730.617004 93.364319,735.710083 100.266998,738.955627 
	C101.355843,739.467407 102.576439,739.809265 103.504509,740.528442 
	C105.143974,741.798950 107.799530,743.195740 107.931900,744.721558 
	C108.470184,750.926453 108.055672,757.206116 108.287292,763.447327 
	C108.567474,770.997009 109.145966,778.535522 109.644165,786.917419 
	C109.691437,791.707397 109.824547,796.498657 109.748314,801.286682 
	C109.712372,803.544678 109.274742,805.796326 108.079887,808.017639 
	C100.623871,808.018677 93.163559,807.892517 85.716026,808.144531 
	C84.456291,808.187195 82.179062,809.863708 82.183823,810.775940 
	C82.246788,822.842224 82.634171,834.906860 82.862946,847.776794 
	C82.920837,848.851440 82.978729,849.926025 82.650955,851.313660 
	C82.759659,851.876221 82.868370,852.438721 82.939003,853.938049 
	C82.944641,863.697510 82.950287,873.457031 82.975494,883.441040 
	C82.975494,883.441040 83.001709,883.664856 82.925125,884.667725 
	C82.952995,904.448547 82.980858,924.229431 82.079086,944.086731 
	C78.137222,944.086731 74.195366,944.086731 70.400307,944.086731 
	C67.343376,929.465271 56.935810,921.128967 45.184277,923.352295 
	C33.136822,925.631592 25.955656,936.352905 28.042614,948.944458 
	C29.158297,955.675964 34.172421,961.611389 38.245075,961.021606 
	C30.125635,954.678040 27.939894,943.009460 31.810982,935.758606 
	C35.743690,928.392395 43.060055,924.445984 52.240234,925.879883 
	C59.618137,927.032349 66.331322,933.747375 67.226128,940.628540 
	C68.097000,947.325623 66.705650,954.047485 61.889984,958.099792 
	C57.533077,961.765930 51.085423,962.947510 45.747757,965.147217 
	C46.081047,965.238708 47.064007,965.690491 48.072384,965.757080 
	C57.157452,966.356567 65.121284,960.624695 68.883080,950.368591 
	C69.852066,947.726685 70.991310,946.726135 73.797905,946.679993 
	C98.721703,946.270203 123.641655,945.612793 148.565994,945.255432 
	C168.700638,944.966675 188.839783,944.991455 209.588699,945.444763 
	C216.364471,945.298828 223.139771,945.059875 229.916122,945.024414 
	C249.889343,944.919922 269.862976,944.867432 289.836456,944.862915 
	C294.302795,944.861877 300.608887,943.433594 302.748260,945.740784 
	C305.169617,948.351990 304.000366,954.425537 304.127747,959.000916 
	C304.334595,966.431213 306.404266,967.663513 313.445831,964.689697 
	C319.827545,961.994507 323.907867,957.054077 327.190613,950.654541 
	C331.115448,959.265991 336.915710,964.380005 346.828735,965.044556 
	C347.509338,964.689819 348.740143,964.390198 348.782043,963.971619 
	C349.072144,961.072815 349.173035,958.150269 349.222107,955.233459 
	C349.283447,951.585571 349.236847,947.935852 349.236847,944.286865 
	C349.719238,944.218201 350.201630,944.149475 350.684021,944.080750 
	C351.192017,945.364014 351.805695,946.616333 352.191040,947.935425 
	C354.848694,957.033386 360.760101,962.544922 369.987579,964.833374 
	C374.255341,965.891846 375.866791,964.609192 375.769653,960.336914 
	C375.646912,954.940125 375.740021,949.538391 375.740021,944.179138 
	C380.248199,943.714355 384.624634,943.263184 389.894348,943.111694 
	C394.592987,943.050720 399.291626,942.989746 404.917084,943.092957 
	C409.711884,943.077820 414.512177,943.198730 419.299713,943.004822 
	C423.192078,942.847168 427.069183,942.311890 431.599121,942.256653 
	C432.971832,942.580444 434.344513,942.904236 436.046173,943.821777 
	C437.571808,946.220032 439.097443,948.618347 440.805206,951.743591 
	C444.607544,960.704346 444.815460,960.844116 454.287445,960.806030 
	C455.118347,960.802673 455.965424,960.904236 456.777527,960.778992 
	C461.811707,960.002502 465.355469,961.509827 467.168549,966.674011 
	C468.913208,971.643188 472.494629,973.956055 477.953766,973.017639 
	C479.733582,972.711670 481.621429,973.071411 483.422333,972.837646 
	C484.634827,972.680298 486.372314,972.357361 486.856567,971.519775 
	C489.093811,967.649902 491.671600,963.740723 492.686188,959.500793 
	C493.188507,957.401611 490.580841,954.555603 489.373566,952.050659 
	C487.762207,948.707336 486.340546,945.417053 481.457031,946.020569 
	C476.051819,946.688538 469.633972,944.007690 466.964813,951.926086 
	C466.161133,954.310303 463.812836,957.743164 461.920685,957.914185 
	C454.490204,958.585632 446.055359,960.622742 441.815216,950.283691 
	C440.824066,947.737183 439.832916,945.190613 439.716370,942.760315 
	C457.143616,942.526489 474.570892,942.292725 492.314728,942.453369 
	C492.876862,942.312622 493.438965,942.171936 494.936615,942.096741 
	C510.622070,942.081116 526.307556,942.065552 542.415894,942.448181 
	C543.277588,942.301270 544.139343,942.154419 545.665466,942.062744 
	C546.109253,942.046448 546.553101,942.030151 547.328491,942.384277 
	C547.884033,942.249023 548.439514,942.113708 549.794739,942.020203 
	C550.862000,942.019165 551.929199,942.018127 553.478882,942.442993 
	C554.652405,942.270447 555.825989,942.097900 557.934387,941.969116 
	C585.289612,941.644714 612.644836,941.320312 640.357056,941.404419 
	C641.035950,941.285278 641.714844,941.166077 642.480957,941.048401 
	C642.480957,941.048401 642.567627,941.038574 643.218872,941.181030 
	C644.145813,941.117859 645.072815,941.054688 646.797974,941.053162 
	C647.864563,941.037781 648.931213,941.022339 650.633240,941.501953 
	C654.422180,941.333984 658.211060,941.165955 662.779846,941.063232 
	C667.252930,941.023743 671.726318,941.004944 676.199036,940.941284 
	C697.468201,940.638489 718.737122,940.323364 740.825867,940.154968 
	C744.798523,940.378967 748.791138,940.957214 752.738770,940.735596 
	C757.462341,940.470398 759.542358,941.948181 759.068970,946.930237 
	C758.709045,950.719116 758.997559,954.569580 758.997559,958.392639 
	C759.646057,958.437927 760.294556,958.483215 760.943054,958.528442 
	C761.259094,957.086060 761.796082,955.651428 761.847229,954.199707 
	C761.970398,950.705994 761.988953,947.198120 761.814941,943.707642 
	C761.671997,940.839233 762.889038,940.008118 765.630920,939.971802 
	C776.766968,939.824402 787.899109,939.363708 799.035156,939.243958 
	C834.828430,938.858887 870.622498,938.524475 906.416931,938.269653 
	C927.238403,938.121521 948.061279,938.163147 968.988098,939.030090 
	C969.261719,953.953735 968.296326,968.952332 970.755066,983.743835 
	C971.195923,983.728271 971.636780,983.712708 972.077637,983.697205 
	C972.077637,968.489075 972.077637,953.281006 972.834229,937.799438 
	C973.213013,936.280212 973.875183,934.768921 973.918396,933.240234 
	C974.058228,928.297607 973.970581,923.348511 973.970581,916.926331 
	C982.998840,916.926331 991.412659,916.884155 999.825745,916.943420 
	C1004.292542,916.974915 1010.594238,915.468567 1012.737732,917.764832 
	C1015.160889,920.360657 1013.990906,926.438660 1014.126953,931.010071 
	C1014.310181,937.167053 1016.134338,938.758301 1021.723389,935.806824 
	C1027.107666,932.963501 1031.848877,928.902649 1037.014893,925.276855 
	C1045.142456,933.958923 1045.142456,933.958984 1052.184082,936.040710 
	C1057.006470,937.466431 1057.877075,936.891357 1057.933716,932.053650 
	C1057.978516,928.228027 1058.088745,924.389832 1057.842773,920.578369 
	C1057.623657,917.183289 1058.659424,915.897766 1062.281372,915.957642 
	C1073.092041,916.136230 1083.909424,915.995911 1094.722656,915.867065 
	C1097.151245,915.838196 1099.574341,915.348328 1102.794678,915.248962 
	C1103.225952,916.730957 1104.006958,918.207031 1104.032227,919.695923 
	C1104.196045,929.353333 1104.176514,939.013794 1104.266479,948.672729 
	C1104.282227,950.365723 1104.532104,952.056580 1104.733398,954.459229 
	C1105.837891,953.465820 1106.471680,953.162415 1106.472900,952.856384 
	C1106.522827,940.738342 1106.515381,928.619995 1106.515381,915.786316 
	C1231.528809,914.842407 1356.553101,913.898376 1481.577393,912.954407 
	C1483.197632,913.940735 1480.320435,919.499878 1485.530518,916.952820 
	C1488.337891,919.279663 1490.638794,918.337769 1493.433350,916.213928 
	C1495.679932,917.906738 1497.785522,918.265381 1500.100708,916.111938 
	C1500.959595,915.313049 1502.448364,915.102356 1503.694824,914.827148 
	C1506.692505,914.165039 1509.764771,913.799927 1512.719360,912.993713 
	C1517.613281,911.658020 1522.345459,912.953247 1523.347412,916.769531 
	C1523.989624,917.224304 1524.630859,918.069885 1525.274170,918.071411 
	C1529.984497,918.082764 1534.699585,918.013916 1539.404541,917.791016 
	C1542.904053,917.625183 1546.390991,917.190796 1550.691040,916.892883 
	C1553.155396,916.239014 1555.619629,915.585144 1558.151855,915.601196 
	C1560.616455,915.601196 1563.080933,915.601196 1565.861328,915.601196 
	C1565.889648,915.337769 1566.011108,914.208923 1566.963013,912.939026 
	C1568.369263,912.841125 1569.775391,912.743225 1571.589600,913.229187 
	C1572.526367,914.786621 1573.283447,916.498108 1574.450073,917.858398 
	C1575.623657,919.226562 1577.111938,921.109070 1578.622559,921.270874 
	C1585.940674,922.054871 1593.340332,922.407288 1596.051636,913.098694 
	C1596.178711,912.662720 1596.645630,912.325806 1597.796387,911.951111 
	C1598.914307,911.827393 1600.032227,911.703674 1601.951904,911.707825 
	C1613.971802,911.481079 1625.991577,911.254272 1638.501953,911.050720 
	C1638.501953,911.050720 1638.992432,911.017639 1639.899536,911.091919 
	C1642.933105,911.057678 1645.966675,911.023438 1649.494263,911.468567 
	C1650.996094,911.309753 1652.498047,911.150879 1654.854492,911.079285 
	C1656.571655,911.041504 1658.288818,911.003784 1660.531860,911.462708 
	C1662.350464,911.276733 1664.169189,911.090759 1666.737061,911.014709 
	C1667.492798,911.005737 1668.248413,910.996826 1669.317993,911.384277 
	C1669.878296,911.247559 1670.438721,911.110779 1671.749268,911.034607 
	C1672.499268,910.990906 1673.249146,910.947205 1674.439453,911.390137 
	C1675.618896,911.235596 1676.798218,911.081055 1678.868042,911.013062 
	C1681.250488,910.995483 1683.632935,910.977905 1686.549438,911.443909 
	C1688.487427,911.064819 1690.425293,910.685730 1693.224121,910.300964 
	C1696.146606,910.271729 1699.068970,910.242493 1702.307007,910.583313 
	C1702.875244,910.473328 1703.443481,910.363403 1704.805054,910.268799 
	C1713.215942,910.205505 1721.626709,910.142273 1730.666870,910.132935 
	C1731.087280,910.119446 1731.507690,910.105896 1732.461670,910.131042 
	C1732.639160,910.114136 1732.816650,910.097168 1733.469727,910.510925 
	C1734.646851,910.348694 1735.823853,910.186462 1737.663452,910.077209 
	C1738.106201,910.067078 1738.548950,910.056946 1739.532593,910.519165 
	C1741.355103,910.346985 1743.177490,910.174866 1745.667358,910.045715 
	C1746.110962,910.009277 1746.554443,909.972778 1747.556763,910.441833 
	C1750.059692,910.239136 1752.562622,910.036438 1755.835693,909.903198 
	C1756.863647,909.865417 1757.891602,909.827576 1759.850952,909.936584 
	C1764.269165,909.851807 1768.687256,909.767090 1773.926514,909.825928 
	C1781.957031,909.564941 1789.987549,909.303894 1798.496216,909.082581 
	C1798.496216,909.082581 1798.975342,909.058594 1799.903931,909.118225 
	C1803.943970,909.097717 1807.984009,909.077209 1812.686523,909.119385 
	C1813.127686,909.071594 1813.568848,909.023804 1814.512695,909.043396 
	C1814.681641,909.042969 1814.850708,909.042603 1815.671753,909.115540 
	C1816.107666,909.092773 1816.543701,909.070007 1817.907837,909.090881 
	C1821.938110,909.071472 1825.968384,909.052063 1830.331909,909.415283 
	C1830.888184,909.254333 1831.444580,909.093384 1832.844360,909.018372 
	C1834.256714,909.037598 1835.669067,909.056824 1837.507324,909.092896 
	C1837.507324,909.092896 1837.932617,909.065796 1838.472412,909.037720 
	C1838.651001,909.013306 1838.829468,908.988892 1839.606201,909.481567 
	C1844.734619,909.117493 1849.862915,908.753418 1855.861206,908.350586 
	C1868.266235,908.280884 1880.671265,908.211243 1893.845093,908.418274 
	C1908.628174,908.477478 1923.411255,908.539612 1938.194336,908.571655 
	C1938.462769,908.572266 1938.731934,908.213196 1939.799805,908.067200 
	C1940.866455,908.047241 1941.933228,908.027222 1943.629272,908.527100 
	C1950.079712,908.311340 1956.530151,908.095581 1963.792603,907.934875 
	C1966.861084,907.939575 1969.929688,907.944336 1973.294312,908.385254 
	C1973.928467,908.085083 1974.562622,907.784912 1975.898560,907.419495 
	C1977.567383,907.570190 1979.236206,907.720947 1981.513428,907.930115 
	C1981.714233,907.895386 1981.914917,907.860718 1982.828003,907.798706 
	C1986.291992,907.799255 1989.756592,907.840515 1993.219849,907.792908 
	C2004.753662,907.634277 2016.287109,907.443665 2027.911743,907.829529 
	C2027.932129,908.210876 2027.952393,908.592163 2027.042969,909.069824 
	C2022.082642,909.064148 2017.110229,908.868591 2012.170044,909.180359 
	C2010.862061,909.262878 2008.642700,910.827637 2008.562134,911.847534 
	C2008.044800,918.397400 2013.829590,926.252441 2020.335083,928.530884 
	C2020.809204,928.686951 2021.283325,928.843079 2022.302734,929.548035 
	C2024.315552,930.183044 2026.328369,930.818054 2028.605957,931.536621 
	C2028.700073,932.484619 2028.888794,933.616272 2028.913696,934.751648 
	C2029.579346,964.998108 2027.677124,995.339417 2032.266357,1025.436157 
	C2032.497192,1026.949707 2033.372437,1028.364868 2033.947754,1029.825806 
	C2034.166992,1029.669800 2034.386230,1029.513672 2034.605347,1029.357666 
	C2034.407959,1025.056396 2034.210693,1020.755188 2034.004883,1016.272278 
	C2035.861084,1016.072998 2036.988647,1015.855835 2038.118164,1015.844788 
	C2069.593994,1015.534973 2101.069824,1015.196899 2132.546143,1014.970642 
	C2154.035156,1014.816162 2175.524902,1014.765442 2197.014404,1014.773865 
	C2215.336426,1014.781067 2233.658447,1014.939026 2251.912109,1015.850281 
	C2251.804932,1021.612732 2251.563477,1027.377319 2251.655762,1033.136475 
	C2251.693115,1035.482056 2252.454834,1037.815918 2253.177490,1040.874390 
	C2253.576416,1041.154663 2253.975830,1041.679810 2254.374512,1041.679199 
	C2262.767334,1041.667114 2271.161865,1041.674683 2279.552002,1041.495239 
	C2282.710449,1041.427734 2285.859375,1040.899658 2289.696777,1040.695312 
	C2291.676025,1040.572754 2293.655029,1040.450195 2296.540283,1040.701416 
	C2300.478516,1040.619507 2304.416748,1040.537720 2309.144287,1040.562256 
	C2333.724121,1040.316284 2358.303955,1040.070312 2383.593506,1040.045654 
	C2384.082031,1040.002686 2384.570312,1039.959717 2385.898438,1039.994385 
	C2389.375977,1040.125366 2392.857666,1040.436523 2396.330322,1040.356567 
	C2412.572510,1039.983276 2428.811768,1039.501953 2445.936035,1039.145020 
	C2447.759521,1039.111328 2449.583252,1039.077637 2451.587646,1039.073608 
	C2451.587646,1039.073608 2451.770752,1039.068481 2452.334229,1039.080444 
	C2452.520508,1039.094238 2452.707031,1039.107910 2453.092529,1039.772827 
	C2453.920898,1040.269775 2454.794922,1041.244629 2455.568848,1041.170776 
	C2457.593750,1040.977661 2459.567383,1039.967407 2461.575684,1039.922241 
	C2468.849609,1039.758789 2476.129639,1039.857910 2484.085938,1039.857910 
	C2484.085938,1042.273804 2484.299072,1045.066040 2484.036377,1047.812622 
	C2483.646240,1051.890869 2485.473389,1053.111206 2489.271240,1052.881226 
	C2493.018311,1052.654541 2496.789551,1052.831909 2500.607910,1052.831909 
	C2504.761963,1065.879639 2514.057373,1074.415771 2519.818848,1069.653442 
	C2509.492188,1067.622803 2503.762939,1061.143066 2503.734131,1051.176880 
	C2503.719727,1046.186523 2506.616943,1041.187744 2508.943115,1036.107666 
	C2510.155273,1035.638550 2511.496338,1035.365601 2512.558838,1034.668091 
	C2519.170898,1030.327148 2526.006836,1030.294067 2532.673584,1034.090332 
	C2542.224609,1039.529175 2544.736328,1049.837158 2539.472900,1061.701172 
	C2544.062012,1059.166992 2544.878906,1054.731323 2544.687012,1051.028687 
	C2544.140625,1040.487427 2538.885986,1032.796265 2527.634277,1029.471436 
	C2543.831787,1022.279663 2545.416748,1011.739136 2543.563232,1003.196655 
	C2541.662842,994.437683 2531.662354,986.290833 2523.094727,986.194824 
	C2511.844971,986.068726 2504.014648,993.758484 2500.036133,1009.340576 
	C2485.340820,1009.340576 2470.472168,1009.340576 2455.773926,1009.340576 
	C2454.603027,996.905823 2453.429443,984.441223 2452.164795,971.337524 
	C2452.121582,970.910522 2452.078613,970.483459 2452.767090,969.814819 
	C2453.458740,968.305847 2454.762207,966.790344 2454.745850,965.289001 
	C2454.601318,951.895874 2454.230469,938.505066 2453.888184,925.114502 
	C2453.875244,924.607910 2453.469971,924.111328 2453.247559,923.610107 
	C2452.854736,923.663269 2452.461914,923.716370 2452.069092,923.769531 
	C2452.069092,935.811157 2452.069092,947.852722 2452.069092,959.894348 
	C2451.805176,959.887146 2451.541504,959.880005 2451.277588,959.872803 
	C2451.126221,959.229858 2450.974854,958.586853 2450.770020,957.149597 
	C2449.538330,951.079651 2448.306641,945.009644 2447.084717,938.205505 
	C2446.408936,936.776855 2445.732910,935.348267 2444.927979,933.319214 
	C2444.629883,932.562927 2444.331787,931.806641 2444.611084,930.820312 
	C2444.084961,929.010437 2443.559082,927.200623 2443.025635,924.679993 
	C2443.012939,923.447998 2443.000244,922.216003 2443.000000,921.000000 
	C2443.000000,921.000000 2443.015625,921.012695 2443.002197,920.218079 
	C2442.943848,919.423218 2442.885498,918.628357 2442.769775,917.066833 
	C2441.851074,912.912781 2440.932617,908.758728 2439.999512,904.539062 
	C2442.116455,904.055847 2443.838379,903.662781 2445.393555,903.307800 
	C2442.800781,901.954590 2439.474609,901.335632 2438.488525,899.475952 
	C2436.305420,895.358398 2435.298096,890.617493 2433.838379,885.326477 
	C2427.620117,869.492676 2421.401855,853.658875 2415.021729,837.414062 
	C2414.903564,837.137878 2414.724854,836.908936 2414.348633,836.282898 
	C2414.348633,836.282898 2414.162354,835.856995 2414.055176,835.138611 
	C2411.014404,828.893616 2407.836426,822.711060 2404.958984,816.391724 
	C2396.967529,798.842346 2386.092041,783.133484 2374.068848,767.424805 
	C2373.730469,767.273254 2373.392090,767.121704 2373.008301,766.961792 
	C2373.008301,766.961792 2373.049805,766.940430 2373.239746,766.218323 
	C2372.048828,764.266785 2371.058838,762.151550 2369.628662,760.394775 
	C2365.595459,755.440857 2361.381348,750.634033 2357.020020,745.340271 
	C2356.841553,745.065247 2356.614502,744.839111 2356.087646,744.232544 
	C2355.853760,743.985352 2355.572754,743.817932 2355.071777,743.057861 
	C2351.755371,739.327881 2348.438965,735.597900 2344.914795,731.432983 
	C2344.719971,731.162415 2344.465332,730.972046 2344.101562,730.183777 
	C2343.193604,728.993713 2342.285645,727.803650 2341.077393,726.237549 
	C2340.850098,726.001648 2340.583496,725.825195 2340.055664,725.272278 
	C2339.853027,725.008850 2339.602783,724.803772 2339.135498,723.997253 
	C2336.365723,720.648743 2333.595947,717.300293 2331.793213,713.887756 
	C2338.756104,713.396790 2345.875732,714.769348 2352.732666,713.019958 
	C2352.705566,712.259155 2352.678467,711.498291 2352.651367,710.737488 
	C2344.765137,711.139221 2336.879150,711.541016 2328.341064,711.836304 
	C2327.008057,711.119873 2325.674805,710.403442 2324.103271,709.265015 
	C2323.912354,708.995972 2323.673828,708.779175 2323.133545,708.203857 
	C2322.910400,707.968933 2322.656982,707.773621 2322.130615,707.204224 
	C2321.913330,706.967957 2321.665771,706.769104 2321.153076,706.187378 
	C2320.936523,705.947449 2320.686035,705.750854 2320.151367,705.185547 
	C2319.930664,704.949646 2319.677979,704.754944 2319.154297,704.180969 
	C2318.948486,703.928528 2318.702881,703.723938 2318.144287,702.984131 
	C2317.512451,702.414551 2316.880615,701.844971 2315.971924,700.750793 
	C2311.492432,696.635986 2307.142090,692.367371 2302.493408,688.453308 
	C2298.495850,685.087341 2294.157715,682.125732 2289.486816,678.971313 
	C2289.163574,678.958740 2288.841553,678.973572 2288.224365,678.348511 
	C2284.481689,675.555176 2280.739258,672.761780 2276.990234,669.999939 
	C2276.990234,669.999939 2277.021240,670.000122 2276.784668,669.399109 
	C2275.523193,668.600281 2274.261475,667.801514 2272.665771,666.415710 
	C2265.445557,661.605835 2258.225342,656.795898 2250.373291,651.623840 
	C2248.858154,650.932739 2247.343262,650.241638 2245.620117,649.104309 
	C2244.719238,648.742737 2243.818604,648.381165 2242.774658,647.332825 
	C2238.205078,644.851685 2233.635254,642.370544 2228.704346,639.319214 
	C2227.463623,638.923889 2226.222656,638.528625 2224.567139,637.585632 
	C2210.286377,630.887573 2196.032959,624.129395 2181.678467,617.593201 
	C2180.373291,616.998840 2178.418701,617.830139 2176.513672,617.975281 
	C2176.513672,617.975281 2176.257568,617.955566 2176.234619,617.183960 
	C2175.956299,616.619690 2175.797607,615.678894 2175.382080,615.546814 
	C2167.275879,612.970703 2159.140137,610.488831 2150.472900,607.504700 
	C2111.927734,595.948181 2072.469238,590.048035 2032.617554,589.486084 
	C2031.681030,576.830933 2030.823486,565.244446 2029.966064,553.658020 
	C2029.291382,553.686523 2028.616821,553.715027 2027.942261,553.743530 
	C2027.942261,556.457031 2027.942261,559.170471 2027.033325,562.087341 
	C2021.020630,562.456848 2015.008057,562.826355 2008.346924,563.081909 
	C2005.930054,563.135620 2003.513062,563.189392 2000.154053,563.057983 
	C1988.496338,563.068970 1976.838379,563.046570 1965.181274,563.134888 
	C1963.783936,563.145447 1962.391235,563.757751 1960.151855,563.990662 
	C1958.363403,563.990662 1956.574951,563.990662 1954.786499,563.990662 
	C1954.796143,564.215820 1954.805786,564.440979 1954.815308,564.666138 
	C1956.209961,565.136780 1957.604614,565.607361 1960.093018,566.447083 
	C1958.262817,567.376099 1957.600708,567.999756 1956.931030,568.007935 
	C1949.773438,568.095337 1942.613403,568.145081 1935.457520,568.018738 
	C1934.655762,568.004578 1933.874390,566.841797 1933.671021,566.248596 
	C1934.765869,566.066101 1935.860596,565.883545 1937.816040,565.792236 
	C1942.643799,565.475769 1947.471680,565.159302 1952.299438,564.842834 
	C1952.284912,564.600830 1952.270386,564.358887 1952.255981,564.116882 
	C1943.507690,564.116882 1934.759399,564.116882 1925.409058,563.545715 
	C1923.598389,563.413330 1921.785767,563.143738 1919.977295,563.169617 
	C1901.250854,563.437866 1882.525146,563.758911 1863.799072,564.054993 
	C1843.350464,564.378357 1822.900879,564.653625 1802.453735,565.049255 
	C1799.501221,565.106323 1798.785278,563.828796 1798.777344,561.162048 
	C1798.768555,558.262329 1798.301880,555.364014 1798.034790,552.465149 
	C1797.393066,552.500000 1796.751343,552.534851 1796.109619,552.569702 
	C1796.109619,556.561768 1796.109619,560.553894 1796.109619,565.091675 
	C1781.055054,565.091675 1766.773804,565.142273 1752.495361,564.972473 
	C1751.588623,564.961670 1750.045288,563.317749 1749.921265,562.296997 
	C1749.406982,558.068420 1749.181641,553.789551 1749.115967,549.525024 
	C1749.001831,542.111145 1749.084229,534.694153 1749.084229,527.073608 
	C1761.386353,526.548340 1772.979248,527.365051 1784.467773,526.527344 
	C1784.432861,525.567261 1784.397949,524.607117 1784.363037,523.646973 
	C1754.300537,524.357727 1724.238037,525.068481 1693.296143,525.661438 
	C1687.529419,525.747986 1681.762695,525.834534 1675.688599,525.525146 
	C1675.131958,525.654724 1674.575195,525.784241 1673.089355,525.880615 
	C1662.312622,525.901794 1651.535767,525.928772 1640.759033,525.942871 
	C1615.824707,525.975525 1590.890381,526.001953 1565.968872,525.101562 
	C1565.644409,513.000244 1568.526123,500.786560 1565.514282,488.779480 
	C1564.937500,488.784332 1564.360596,488.789215 1563.783691,488.794098 
	C1563.783691,501.488586 1563.783691,514.183105 1562.977417,526.881287 
	C1549.989624,526.931824 1537.001953,526.982422 1523.076172,526.979431 
	C1510.027222,527.069214 1496.978149,527.158936 1483.022949,527.118652 
	C1479.033203,527.134399 1475.043579,527.150208 1470.124878,527.030273 
	C1466.055420,527.114868 1461.985962,527.199402 1457.172974,527.225037 
	C1454.716064,527.197388 1452.258667,527.119568 1449.802490,527.150085 
	C1431.360352,527.378906 1412.919189,527.716736 1394.476562,527.850891 
	C1370.333618,528.026550 1346.189697,528.046631 1321.196167,528.047119 
	C1319.774536,528.102783 1318.353027,528.158508 1316.146484,528.129211 
	C1315.356812,528.114807 1314.567261,528.100403 1314.175415,527.314819 
	C1314.411255,526.701904 1314.850952,526.089722 1314.853149,525.475952 
	C1314.901855,511.534729 1314.924438,497.593201 1314.857910,483.652161 
	C1314.854004,482.811127 1314.067261,481.973846 1313.644775,481.134827 
	C1313.229248,481.213470 1312.813721,481.292145 1312.398315,481.370789 
	C1312.300903,483.087524 1312.120972,484.804169 1312.119629,486.520935 
	C1312.113037,495.607300 1312.043701,504.695740 1312.217896,513.779175 
	C1312.309326,518.553711 1312.854248,523.319519 1312.462646,528.119019 
	C1309.681763,528.234497 1306.900757,528.349976 1303.383789,528.226196 
	C1302.883423,528.305603 1302.383057,528.385071 1301.069336,528.276917 
	C1297.446167,528.457947 1293.823853,528.773560 1290.199707,528.797546 
	C1270.142578,528.930176 1250.084961,528.991821 1229.089844,528.978821 
	C1222.718506,529.027832 1216.347046,529.076843 1209.131836,529.063232 
	C1207.439941,529.071228 1205.748169,529.079224 1203.111206,529.009705 
	C1192.813599,529.016357 1182.510376,529.222961 1172.220825,528.937805 
	C1167.682129,528.812073 1165.276001,530.344482 1164.249023,535.010925 
	C1163.392456,536.353271 1162.536011,537.695679 1161.088135,539.405151 
	C1160.081787,539.960266 1159.075439,540.515381 1157.529053,541.375000 
	C1156.665039,542.537170 1155.800903,543.699341 1154.186035,545.166687 
	C1153.609863,544.569702 1153.033691,543.972656 1152.457520,543.375610 
	C1153.528931,542.597412 1154.600220,541.819214 1155.671631,541.041016 
	C1155.285156,540.525146 1154.898682,540.009277 1154.512085,539.493469 
	C1152.663330,541.049927 1150.660400,542.459961 1149.006592,544.201538 
	C1147.120483,546.187561 1145.305176,546.454956 1144.855713,543.597717 
	C1144.016968,538.265015 1140.149780,539.241577 1137.505249,540.416443 
	C1136.103394,541.039246 1136.022461,544.635132 1135.184937,547.422119 
	C1138.176636,546.041809 1139.856445,545.266785 1142.045654,545.003418 
	C1142.238647,545.147278 1142.431519,545.291199 1142.714600,546.108459 
	C1143.330200,546.710693 1143.880981,547.736267 1144.572632,547.842407 
	C1147.695923,548.321777 1150.859253,548.540833 1153.775269,549.530579 
	C1148.319824,558.121826 1142.992676,566.799683 1137.298462,575.229553 
	C1136.188354,576.872864 1133.651855,577.552734 1130.957031,578.732788 
	C1128.652588,578.481750 1126.348145,578.230713 1123.992676,577.143555 
	C1123.495239,566.831665 1115.996216,558.976257 1105.174683,558.076660 
	C1104.462646,558.037842 1103.750610,557.999084 1102.932373,557.062683 
	C1102.917358,554.029968 1102.902222,550.997192 1103.047852,547.716309 
	C1103.047852,547.716309 1103.052124,547.420654 1103.790161,546.992981 
	C1104.498779,545.535889 1105.207275,544.078735 1105.915894,542.621643 
	C1107.473389,543.998962 1109.031006,545.376282 1110.627075,546.787720 
	C1111.262329,545.753662 1112.184448,544.252686 1113.630371,541.899231 
	C1114.331787,544.564026 1114.727051,546.065491 1115.084839,547.424438 
	C1116.435181,546.428955 1117.659668,545.721985 1118.628784,544.758362 
	C1119.660034,543.732971 1120.449707,542.464539 1121.344849,541.302246 
	C1120.044312,540.891174 1118.738525,540.114746 1117.444336,540.133667 
	C1112.371582,540.207458 1107.302612,540.532166 1100.820068,540.828247 
	C1102.133667,538.004761 1102.739502,536.702637 1103.345337,535.400574 
	C1103.631104,535.906860 1103.916870,536.413147 1104.202637,536.919495 
	C1106.952759,536.425293 1109.872803,535.093750 1112.418213,535.609924 
	C1117.846802,536.710876 1123.046387,537.001221 1128.318726,535.205566 
	C1128.987305,534.977844 1130.256470,535.330750 1130.425903,534.997253 
	C1132.920166,530.088318 1134.622437,532.714233 1135.989258,534.956360 
	C1138.588257,534.956360 1140.808716,534.956360 1143.381592,534.956360 
	C1143.476196,533.190735 1143.586304,531.136658 1143.736450,528.335632 
	C1141.830811,529.496094 1140.632935,530.225586 1139.434937,530.955078 
	C1139.448853,530.622070 1139.462769,530.289001 1139.476685,529.955933 
	C1136.081299,529.396301 1132.685913,528.836670 1129.290527,528.277039 
	C1129.484009,528.861816 1129.677368,529.446594 1129.870728,530.031372 
	C1128.153076,531.542847 1126.435303,533.054382 1124.717529,534.565857 
	C1124.306274,534.107788 1123.895020,533.649658 1123.483765,533.191589 
	C1124.540649,531.983582 1125.597656,530.775513 1127.099121,529.059448 
	C1122.232056,529.523254 1118.022339,530.620361 1114.023682,530.098999 
	C1110.420410,529.629211 1107.215698,528.719727 1104.102905,531.304932 
	C1104.000732,531.389771 1102.582886,529.890564 1101.899902,528.328186 
	C1102.095825,523.706055 1105.999268,524.425842 1108.514648,524.392273 
	C1116.677612,524.283386 1124.848145,524.738159 1133.551514,525.292542 
	C1135.381470,524.660278 1137.211548,524.028076 1139.076782,524.199585 
	C1140.023193,524.581421 1141.354004,525.563843 1141.852051,525.245056 
	C1144.553223,523.516235 1144.519531,519.271423 1141.908936,516.525269 
	C1141.115845,517.731628 1140.337158,518.916016 1139.118652,519.506714 
	C1135.572998,516.066101 1134.298950,516.407471 1132.453369,520.853210 
	C1131.981567,519.848572 1131.509644,518.843994 1131.148682,518.075378 
	C1129.166138,518.742065 1126.873657,520.339722 1125.721313,519.741516 
	C1122.579956,518.110535 1118.475708,520.267639 1115.893799,516.962830 
	C1115.648560,516.648804 1114.484375,517.005737 1113.758423,517.123230 
	C1111.892456,517.425110 1109.998657,517.639587 1108.181152,518.130371 
	C1106.408447,518.609192 1104.716431,519.387024 1103.009644,519.287598 
	C1102.532227,518.158447 1102.054688,517.029358 1101.787598,515.181030 
	C1101.790161,514.434692 1101.792725,513.688416 1102.380371,512.401611 
	C1102.168579,509.294342 1101.956665,506.187073 1101.805542,502.363617 
	C1101.781738,501.885590 1101.757935,501.407532 1102.267334,500.611908 
	C1102.084717,499.767578 1101.902100,498.923248 1101.802490,497.322510 
	C1101.554199,484.205658 1101.305908,471.088806 1101.631348,457.390991 
	C1101.369873,453.255859 1101.108398,449.120728 1100.964355,444.116364 
	C1100.906982,442.362244 1100.849609,440.608093 1100.844727,438.307068 
	C1100.816772,437.941895 1100.788818,437.576752 1100.980957,436.364044 
	C1100.936279,434.056183 1100.853271,431.748291 1100.852661,429.440430 
	C1100.843994,397.689209 1100.862915,365.937958 1100.787598,334.186920 
	C1100.784668,332.921692 1099.913452,331.658508 1099.446777,330.394379 
	C1098.986206,330.505951 1098.525757,330.617523 1098.065186,330.729065 
	C1098.065186,332.353119 1098.071289,333.977203 1098.064209,335.601227 
	C1098.035278,342.243896 1099.695679,349.502930 1091.553711,353.183258 
	C1091.167969,353.357635 1091.161621,354.371368 1091.001587,354.999756 
	C1091.001587,354.999756 1090.996704,354.976532 1090.333496,354.972839 
	C1089.489990,355.654388 1088.646362,356.335938 1087.162354,357.001434 
	C1086.452026,357.561096 1085.741577,358.120789 1084.366089,358.928375 
	C1082.570557,360.619659 1080.774902,362.310944 1079.004028,364.002686 
	C1079.004028,364.002686 1078.998657,363.978027 1078.290161,364.001678 
	C1076.027466,367.194794 1070.348022,366.835602 1070.002075,372.000793 
	C1070.002075,372.000793 1069.997681,371.977081 1069.259766,371.945435 
	C1066.362305,373.762665 1063.213013,375.280823 1060.622559,377.462402 
	C1055.906616,381.433807 1051.522461,385.799042 1046.273438,389.969360 
	C1043.496948,392.296692 1040.720581,394.623993 1037.468506,397.113525 
	C1037.293335,397.387695 1037.118286,397.661865 1036.180542,397.957855 
	C1031.360474,398.753662 1028.012939,401.508728 1025.251831,405.916718 
	C1024.840698,406.281921 1024.429443,406.647156 1023.519226,407.283905 
	C1023.237549,407.765533 1022.955872,408.247162 1022.087280,408.892914 
	C1021.354309,409.551270 1020.621338,410.209595 1019.180908,410.909760 
	C1017.788086,412.273621 1016.395264,413.637482 1014.272644,414.979584 
	C1008.245422,419.480988 1002.268738,424.052429 996.170410,428.455353 
	C992.928101,430.796265 990.584045,435.153687 985.341431,433.390137 
	C985.570251,435.083649 985.763611,436.515137 985.989136,437.988464 
	C985.989136,437.988464 985.965515,437.958099 985.248047,437.907379 
	C982.160461,440.600037 979.072876,443.292664 975.260986,445.988770 
	C973.131104,447.952850 971.001160,449.916962 968.082397,451.734009 
	C967.684082,446.787109 967.305420,441.838531 966.875916,436.894348 
	C966.739990,435.328888 966.468262,433.775208 966.258728,432.216156 
	C965.603210,432.249451 964.947632,432.282776 964.292114,432.316101 
	C964.292114,437.101624 964.461365,441.895172 964.235291,446.669983 
	C964.072449,450.109802 963.382812,453.524658 962.960693,456.989380 
	C962.960693,456.989380 962.989258,456.950165 962.368530,456.933411 
	C961.895203,457.282135 961.421875,457.630829 960.997986,458.003326 
	C960.997986,458.003326 960.995178,457.948608 960.504517,458.129700 
	C960.324707,458.412994 960.144958,458.696320 960.004395,458.994812 
M376.941864,175.700485 
	C377.064941,176.238770 377.188019,176.777054 377.311066,177.315338 
	C377.496796,177.304520 377.682526,177.293686 377.868225,177.282867 
	C377.930267,176.537689 377.992279,175.792496 378.084351,174.154526 
	C378.047516,171.775879 378.010681,169.397217 378.006226,166.258972 
	C377.993866,165.498795 377.981506,164.738617 378.004028,163.044846 
	C378.004028,157.187576 378.004028,151.330292 378.004028,145.473022 
	C377.666992,145.498428 377.329956,145.523819 376.992920,145.549210 
	C376.992920,151.701401 376.992920,157.853577 376.973083,164.751953 
	C376.978577,165.498428 376.984070,166.244904 376.933319,167.887024 
	C376.928711,170.284073 376.924072,172.681122 376.941864,175.700485 
M2085.671631,1238.703857 
	C2087.131104,1239.064575 2088.590332,1239.425171 2090.635986,1240.041748 
	C2096.895020,1238.483765 2103.235840,1241.946533 2109.898438,1239.805420 
	C2108.485596,1236.979858 2107.529297,1234.361328 2112.184814,1234.184692 
	C2112.868652,1234.158813 2113.486816,1232.396851 2114.135254,1231.437988 
	C2113.209229,1230.966187 2112.275879,1230.075317 2111.358154,1230.090942 
	C2105.688232,1230.187134 2100.021729,1230.487427 2094.177734,1230.727051 
	C2093.038330,1231.503662 2095.883545,1236.508545 2091.175781,1233.339844 
	C2090.893066,1230.572266 2089.359375,1230.022949 2086.307129,1231.113403 
	C2086.189453,1232.142212 2086.035645,1233.168335 2085.963135,1234.200317 
	C2085.877197,1235.421265 2085.861084,1236.646973 2085.671631,1238.703857 
M1522.117065,938.876587 
	C1520.317139,942.009338 1518.499512,945.132019 1516.722534,948.277710 
	C1515.123413,951.108459 1516.741577,951.348511 1519.622314,951.551880 
	C1523.181396,951.715149 1526.740479,951.878479 1531.298950,952.087646 
	C1529.336182,954.164551 1527.949097,955.509705 1526.700806,956.973083 
	C1519.828003,965.029663 1516.596558,974.593079 1517.424072,984.926392 
	C1518.289551,995.735596 1526.895264,1003.496033 1539.272217,1006.409912 
	C1533.568481,1019.018921 1539.741577,1030.132324 1547.726074,1034.330200 
	C1550.703247,1035.895508 1551.372437,1037.483887 1551.176758,1040.476562 
	C1550.785034,1046.463013 1552.515381,1052.822876 1557.516846,1055.348022 
	C1565.449951,1059.353027 1571.308838,1065.543091 1578.181274,1070.638916 
	C1586.681885,1076.941895 1596.368164,1077.215576 1605.736206,1071.662964 
	C1608.809692,1069.841187 1611.377563,1069.319946 1615.160278,1070.656738 
	C1618.464233,1071.824219 1622.910767,1071.131348 1626.409180,1069.948975 
	C1629.302856,1068.970947 1630.857788,1069.238647 1632.911255,1071.201416 
	C1642.321655,1080.195435 1655.545410,1080.488159 1665.272583,1072.067017 
	C1670.275513,1067.735596 1670.179565,1067.838623 1675.228394,1072.540405 
	C1684.477661,1081.154297 1695.231812,1083.548340 1707.426147,1080.175903 
	C1715.513550,1077.939331 1721.597290,1072.838135 1727.113037,1066.877319 
	C1733.047119,1071.219849 1738.896851,1074.393311 1746.446167,1074.114868 
	C1754.230347,1073.827759 1759.778931,1069.893555 1764.762695,1064.679077 
	C1773.382812,1073.338989 1783.133789,1078.104980 1795.011353,1076.475464 
	C1806.542480,1074.893311 1815.682617,1069.055908 1821.864624,1059.141968 
	C1835.538452,1064.092896 1840.826660,1062.477417 1848.938477,1050.916260 
	C1849.644287,1051.075928 1850.476562,1051.141724 1851.208374,1051.447998 
	C1861.488159,1055.750122 1873.561035,1050.199341 1877.667969,1040.211426 
	C1878.950562,1037.091797 1880.738281,1035.599854 1883.813477,1034.596924 
	C1896.649658,1030.411133 1902.512695,1016.872192 1899.130737,1005.101318 
	C1898.558594,1003.109314 1899.274658,1000.363098 1900.244263,998.380310 
	C1903.940796,990.820435 1904.817261,983.434143 1899.483765,976.363892 
	C1896.527222,972.444458 1892.748779,969.125793 1889.945312,965.113953 
	C1882.994629,955.167175 1869.952026,951.946533 1859.709961,958.385986 
	C1856.927734,960.135254 1855.468506,960.073181 1852.909790,957.960815 
	C1845.121338,951.530884 1836.151733,947.628906 1826.262207,952.072266 
	C1819.967163,954.900696 1814.505737,959.584595 1808.212524,963.738892 
	C1803.891724,958.864258 1797.255493,956.520081 1789.502563,957.173828 
	C1781.600708,957.840210 1775.595093,961.789185 1770.956665,968.636414 
	C1764.257812,959.108337 1755.957520,953.615417 1744.647827,953.015198 
	C1733.274780,952.411560 1724.043091,956.631836 1716.739990,964.353821 
	C1714.385132,963.085083 1712.428223,962.030823 1710.326416,960.823303 
	C1710.326416,960.823303 1710.023193,960.528564 1710.123169,959.738831 
	C1706.692017,953.891052 1700.837524,952.648376 1694.859009,952.113098 
	C1686.321777,951.348877 1679.010986,954.549438 1672.535034,960.692932 
	C1663.164429,948.357666 1652.062378,943.877747 1638.210815,951.953979 
	C1627.798462,941.853394 1621.515503,941.479980 1605.694702,949.945740 
	C1605.241943,949.604919 1604.682129,949.265015 1604.219727,948.823242 
	C1592.188477,937.333191 1578.228149,937.145508 1566.267334,948.665710 
	C1563.897583,950.948059 1562.010620,950.461975 1559.663940,949.481140 
	C1552.462891,946.471313 1545.199585,945.956726 1537.355591,948.292786 
	C1534.042358,942.719116 1530.729248,937.145386 1526.792236,930.522278 
	C1525.393433,933.358093 1524.624634,934.916748 1523.424683,936.882202 
	C1523.175049,937.410400 1522.925415,937.938599 1522.117065,938.876587 
M2354.663086,685.894531 
	C2359.808105,681.031494 2364.953369,676.168518 2370.098633,671.305542 
	C2369.544189,670.766113 2368.989990,670.226685 2368.435791,669.687195 
	C2365.859131,672.389221 2363.200684,675.018921 2360.729736,677.814392 
	C2358.392090,680.459473 2356.250732,683.278381 2353.305664,686.112793 
	C2351.540527,687.589111 2349.775391,689.065430 2348.010254,690.541748 
	C2348.562744,691.035522 2349.115234,691.529297 2349.667725,692.023071 
	C2351.105225,690.008057 2352.542969,687.993103 2354.663086,685.894531 
M2413.230225,624.124695 
	C2407.492432,629.952209 2401.754883,635.779785 2396.017090,641.607300 
	C2396.370361,641.952881 2396.723633,642.298523 2397.076904,642.644104 
	C2403.336426,637.082764 2409.848877,631.776794 2414.987305,624.201294 
	C2418.156982,621.323059 2421.326660,618.444824 2424.496582,615.566650 
	C2423.936035,615.012085 2423.375488,614.457581 2422.815186,613.903076 
	C2419.808105,617.192322 2416.801270,620.481567 2413.230225,624.124695 
M2060.142090,1238.432007 
	C2060.325684,1238.709595 2060.581787,1238.873779 2061.219971,1239.757446 
	C2063.811523,1239.862183 2066.402832,1239.966919 2069.713867,1240.425293 
	C2073.215332,1240.425293 2076.716553,1240.425293 2080.218018,1240.425293 
	C2080.255615,1240.164673 2080.292969,1239.903931 2080.330566,1239.643311 
	C2078.386719,1238.901855 2076.443115,1238.160400 2074.381592,1237.374146 
	C2076.532471,1236.482544 2078.516357,1235.660156 2081.207764,1234.544434 
	C2079.830322,1233.452637 2079.019287,1232.437500 2077.977783,1232.062744 
	C2075.852783,1231.297974 2073.610352,1230.596436 2071.400879,1230.546509 
	C2070.940918,1230.536011 2070.391602,1233.457764 2069.926514,1235.040771 
	C2069.573486,1236.243164 2069.288574,1237.465454 2068.248291,1238.402466 
	C2062.277588,1238.480469 2067.117432,1232.854370 2063.606201,1230.663452 
	C2063.606201,1232.688232 2063.606201,1234.203735 2062.950195,1235.883179 
	C2061.521240,1234.256470 2060.092285,1232.629761 2058.404053,1230.708130 
	C2057.505371,1232.285156 2056.659180,1233.770020 2055.747803,1235.131958 
	C2055.747803,1235.131958 2055.859863,1235.278076 2055.023438,1236.273804 
	C2054.666260,1236.519653 2054.309082,1236.765625 2053.308838,1236.660156 
	C2051.857910,1236.988281 2050.406738,1237.316406 2048.955811,1237.644531 
	C2048.822510,1236.557373 2048.689209,1235.470093 2048.555908,1234.382812 
	C2050.294678,1234.190674 2052.033447,1233.998413 2053.755371,1233.807983 
	C2052.476807,1229.351929 2049.950195,1229.130615 2045.456299,1232.653564 
	C2043.488403,1230.243286 2040.620483,1229.104736 2038.796387,1231.718506 
	C2037.496948,1233.580688 2037.630859,1236.977905 2038.292358,1239.366699 
	C2038.506470,1240.140381 2042.419434,1239.957642 2044.647583,1240.067993 
	C2045.399292,1240.105225 2046.173340,1239.696411 2047.277710,1239.905396 
	C2047.441040,1239.976562 2047.604370,1240.047852 2048.153320,1240.627441 
	C2052.378174,1242.093750 2055.846191,1240.525146 2059.521729,1237.958374 
	C2059.521729,1237.958374 2059.991699,1237.989624 2060.142090,1238.432007 
M2071.086426,1221.683594 
	C2076.080811,1228.099243 2082.749512,1228.349243 2087.908936,1222.040405 
	C2088.669434,1221.194336 2089.430176,1220.348389 2090.230469,1219.458496 
	C2096.055664,1228.559814 2106.197266,1228.167725 2111.693604,1218.869263 
	C2112.590332,1226.362793 2112.590332,1226.362793 2119.618652,1224.222412 
	C2119.764404,1222.805298 2119.919678,1221.298218 2120.109375,1219.456787 
	C2120.875000,1220.080444 2121.204346,1220.451538 2121.616211,1220.655884 
	C2122.036133,1220.864380 2122.537354,1220.909058 2123.008545,1220.995605 
	C2123.008545,1220.995605 2122.978760,1220.988892 2122.909668,1221.818848 
	C2124.278320,1225.471924 2126.934570,1226.050659 2132.052002,1223.617310 
	C2130.344482,1219.825317 2128.120117,1216.252197 2130.591797,1211.605835 
	C2131.961182,1209.032349 2128.171387,1204.673950 2125.072021,1204.586670 
	C2121.592285,1204.488647 2118.057617,1204.520874 2114.640625,1205.073975 
	C2113.545410,1205.251343 2112.466553,1207.146973 2111.902832,1208.477783 
	C2111.457764,1209.528442 2111.813477,1210.918335 2111.813477,1212.157593 
	C2110.167236,1206.911621 2106.436035,1204.438965 2101.285889,1204.393555 
	C2096.452393,1204.350830 2092.314941,1206.149658 2090.126465,1211.036255 
	C2083.699951,1202.644653 2074.886719,1202.286865 2070.373535,1210.475586 
	C2069.763672,1213.169678 2069.153564,1215.863647 2068.489746,1218.796387 
	C2066.331055,1218.796387 2063.950928,1218.796387 2060.920654,1218.796387 
	C2060.920654,1215.336548 2061.132812,1212.208740 2060.856201,1209.124756 
	C2060.616455,1206.449707 2059.537842,1204.421143 2056.534180,1206.992432 
	C2056.534180,1206.992432 2056.569824,1206.967529 2056.156738,1206.343262 
	C2055.455322,1206.269409 2054.754150,1206.195557 2053.241211,1205.903564 
	C2048.811279,1205.677002 2044.374878,1205.242554 2039.956665,1205.381714 
	C2038.949951,1205.413696 2037.242798,1207.380249 2037.166748,1208.541992 
	C2036.855835,1213.292358 2036.862305,1218.089600 2037.206421,1222.836670 
	C2037.283691,1223.905640 2039.055908,1225.470459 2040.277344,1225.731201 
	C2045.645630,1226.876587 2044.579712,1222.281616 2045.219360,1219.524048 
	C2050.335205,1218.412598 2050.225586,1218.389160 2051.305908,1213.319824 
	C2051.519531,1212.316284 2052.935303,1211.568970 2053.662109,1211.799561 
	C2053.662109,1216.213989 2053.662109,1220.628540 2053.662109,1225.851929 
	C2059.882568,1224.066040 2067.068359,1229.249268 2071.086426,1221.683594 
M259.734039,1034.114990 
	C258.753265,1034.079102 257.772461,1034.043213 256.535431,1033.291260 
	C255.870255,1032.245850 255.205093,1031.200317 254.539917,1030.154907 
	C254.023560,1031.150757 253.305481,1032.096558 253.043732,1033.155396 
	C252.740082,1034.383667 252.837067,1035.710938 251.954361,1037.030029 
	C251.420792,1037.096069 250.887222,1037.162231 249.906189,1036.739502 
	C249.074142,1035.316406 248.379715,1033.783813 247.364380,1032.506470 
	C246.619507,1031.569336 245.453659,1030.966919 244.475449,1030.215332 
	C243.950836,1031.446167 243.066742,1032.650635 242.973740,1033.913330 
	C242.755890,1036.870117 242.718231,1039.873657 242.997177,1042.821533 
	C243.173767,1044.687866 244.051071,1046.487915 244.614151,1048.317627 
	C245.222549,1046.518188 245.830948,1044.718750 247.480301,1042.951660 
	C248.172379,1042.908081 248.864456,1042.864624 249.988373,1043.307739 
	C250.564606,1044.286621 250.954285,1045.477783 251.764801,1046.190063 
	C252.753281,1047.058716 254.095337,1047.525024 255.284470,1048.165283 
	C255.818588,1047.109375 256.352692,1046.053345 257.854034,1044.989014 
	C258.816803,1044.898926 259.779602,1044.808716 261.239716,1045.115479 
	C264.289062,1048.695435 267.973541,1049.748657 272.289307,1047.690063 
	C276.092163,1045.876221 277.750610,1042.293091 276.796021,1038.661621 
	C276.006836,1035.659180 273.903229,1032.177856 271.343048,1030.653076 
	C267.213593,1028.193848 263.482544,1030.694092 259.734039,1034.114990 
M328.020905,1036.996338 
	C328.662201,1036.442505 329.303497,1035.888794 330.605347,1034.945435 
	C331.601715,1030.735229 328.398865,1029.384888 325.643280,1029.450439 
	C322.902527,1029.515625 319.716705,1030.839233 317.744720,1032.694214 
	C317.030487,1033.366089 319.264862,1037.172607 320.063141,1040.394531 
	C320.032867,1040.960693 320.002594,1041.526733 319.289154,1042.012207 
	C318.563629,1042.800659 317.838135,1043.589233 317.112640,1044.377686 
	C319.416290,1045.632935 321.823914,1048.044312 323.997070,1047.848877 
	C326.668701,1047.608643 329.144745,1045.192993 331.705322,1043.717651 
	C330.389862,1041.675049 329.074402,1039.632446 328.020905,1036.996338 
M2008.954468,1197.888550 
	C2009.063477,1200.036011 2009.172607,1202.183472 2009.324951,1205.184937 
	C2011.005981,1202.958374 2011.971069,1201.680054 2012.936279,1200.401733 
	C2013.886719,1201.642578 2014.837280,1202.883423 2016.006714,1204.410278 
	C2016.006714,1200.929321 2016.006714,1197.750244 2016.006714,1194.419556 
	C2014.723022,1195.908203 2013.787842,1196.992798 2012.852661,1198.077393 
	C2011.913696,1196.998047 2010.974731,1195.918701 2009.312378,1194.506958 
	C2003.236816,1194.432495 1997.092896,1193.864014 1992.884155,1195.617188 
	C1987.607544,1195.345215 1983.701416,1195.143921 1979.590576,1194.932007 
	C1979.320435,1196.252686 1979.077148,1197.442139 1978.469849,1200.410645 
	C1977.033447,1197.474243 1976.171509,1195.712036 1975.309448,1193.949829 
	C1974.598999,1194.355347 1973.888550,1194.760864 1973.178223,1195.166260 
	C1973.324097,1198.476074 1973.469971,1201.785889 1973.615845,1205.095581 
	C1974.089600,1205.134399 1974.563477,1205.173218 1975.037354,1205.212036 
	C1975.542236,1204.087891 1976.047241,1202.963745 1976.677124,1201.561523 
	C1977.948364,1203.047485 1978.843262,1204.093506 1979.786499,1205.196045 
	C1980.717285,1204.043945 1981.386230,1203.216064 1982.052490,1202.391479 
	C1986.803833,1206.253174 1993.067749,1205.682739 1995.156250,1201.255981 
	C1996.873779,1202.644897 1998.521851,1203.977539 2000.221558,1205.352051 
	C2000.593994,1202.275391 2000.891968,1199.813721 2001.190063,1197.351929 
	C2001.776123,1197.396240 2002.362305,1197.440674 2002.948486,1197.484985 
	C2003.372070,1199.880371 2003.795776,1202.275635 2004.219360,1204.671021 
	C2004.703125,1204.646118 2005.186768,1204.621338 2005.670532,1204.596558 
	C2005.803955,1202.937012 2006.086060,1201.272827 2006.033813,1199.619141 
	C2005.964600,1197.428833 2006.743896,1196.495605 2008.954468,1197.888550 
M1994.452881,1210.362671 
	C1985.489380,1211.275024 1979.775513,1216.305176 1977.421387,1224.761230 
	C1975.304077,1232.366699 1978.009277,1238.790771 1983.818726,1243.939453 
	C1989.475952,1248.953247 1998.935913,1249.686157 2005.236938,1245.672241 
	C2012.320190,1241.160034 2015.871948,1232.378662 2013.806763,1224.483521 
	C2011.728760,1216.538574 2005.643311,1211.385864 1997.240234,1210.960571 
	C1996.508911,1210.884521 1995.777466,1210.808472 1994.452881,1210.362671 
M2536.000000,890.000000 
	C2536.000000,890.000000 2536.000000,890.002441 2535.981689,889.300171 
	C2531.383057,883.715210 2525.426758,880.242859 2518.259033,881.326294 
	C2509.077148,882.714233 2501.647705,887.375793 2499.310547,897.134155 
	C2498.465576,900.662354 2496.769775,901.194458 2493.759521,901.126343 
	C2488.102051,900.998352 2482.437988,901.077942 2476.778320,901.167419 
	C2475.513184,901.187439 2474.254395,901.604553 2472.992676,901.838867 
	C2473.018555,902.371521 2473.044434,902.904114 2473.070312,903.436707 
	C2474.574463,903.603943 2476.077393,903.904663 2477.582764,903.914368 
	C2482.910400,903.948853 2488.246338,904.047241 2493.564209,903.798706 
	C2497.066162,903.635132 2498.498047,904.868835 2499.163086,908.406128 
	C2501.039062,918.385193 2514.702637,927.127747 2523.910645,924.291260 
	C2531.070801,922.085571 2536.988525,918.252136 2540.107910,910.841125 
	C2542.884277,904.245117 2541.050781,893.646606 2536.000000,890.000000 
M1560.982666,131.918182 
	C1560.982666,145.794220 1560.982666,159.670258 1560.982666,173.546295 
	C1561.651611,173.590012 1562.320679,173.633713 1562.989624,173.677429 
	C1563.292725,172.376266 1563.880737,171.069229 1563.854126,169.774841 
	C1563.624023,158.588516 1563.315796,147.403351 1562.941528,136.220840 
	C1562.883179,134.476456 1562.372314,132.747223 1562.179688,130.268555 
	C1563.131592,129.789505 1564.034180,129.095856 1565.042969,128.863525 
	C1580.946411,125.200729 1585.018433,111.277954 1581.631714,98.010788 
	C1579.677124,90.353676 1564.968140,84.124268 1556.239380,86.526169 
	C1546.945557,89.083549 1539.440430,98.836937 1540.003296,107.894760 
	C1540.655518,118.390038 1547.719482,127.670319 1558.245239,128.930511 
	C1559.241333,129.049759 1560.105591,130.269012 1560.982666,131.918182 
M65.082382,711.066406 
	C63.664902,698.859436 51.497681,689.104126 40.877674,691.659729 
	C25.603714,695.335327 18.918556,708.965210 25.754841,723.069763 
	C29.289164,730.361816 38.973015,736.296570 48.081253,734.111511 
	C58.288364,731.662842 63.260025,724.878845 66.013992,715.014893 
	C73.317924,714.997742 80.623131,715.047913 87.924805,714.910645 
	C89.517448,714.880737 91.097511,714.181335 92.683365,713.790222 
	C92.563957,713.147949 92.444557,712.505615 92.325150,711.863342 
	C83.258865,711.863342 74.192574,711.863342 65.082382,711.066406 
M2196.026367,1211.776855 
	C2196.026367,1214.746826 2196.026367,1217.716919 2196.026367,1222.165771 
	C2193.611816,1216.149536 2191.940430,1211.469971 2189.812988,1207.007324 
	C2189.203125,1205.727783 2187.394287,1204.561401 2185.932617,1204.224854 
	C2179.328125,1202.703979 2179.675293,1208.733521 2178.124756,1212.166992 
	C2175.122070,1218.816650 2174.170898,1219.473511 2166.700928,1216.996094 
	C2166.700928,1213.969116 2167.059814,1210.757935 2166.534668,1207.698730 
	C2166.305908,1206.366943 2164.328613,1204.485596 2163.027588,1204.385376 
	C2161.044678,1204.232788 2158.959473,1205.410645 2156.917725,1206.023560 
	C2156.986328,1205.794922 2157.054688,1205.566406 2157.123047,1205.337769 
	C2151.686768,1204.874390 2146.250488,1204.410889 2140.041992,1203.881714 
	C2140.041992,1209.456665 2140.030518,1214.056763 2140.044922,1218.656860 
	C2140.066650,1225.534546 2140.518311,1225.860962 2147.708252,1224.117310 
	C2147.928467,1222.580078 2148.158691,1220.975098 2148.320068,1219.848511 
	C2151.779541,1218.340820 2154.831543,1217.010620 2157.889160,1215.677856 
	C2158.804932,1224.836060 2158.831543,1225.222290 2166.234131,1224.711914 
	C2172.254639,1224.296997 2178.837891,1227.121704 2184.309570,1222.111084 
	C2184.739014,1221.717773 2186.830078,1222.513062 2187.656006,1223.284058 
	C2191.731934,1227.089233 2196.375488,1224.702881 2200.645508,1224.286499 
	C2201.949463,1224.159424 2202.950195,1220.919312 2204.127197,1219.045044 
	C2204.925781,1220.076294 2205.764404,1221.892456 2207.183838,1222.799194 
	C2208.801514,1223.832520 2211.115234,1224.791870 2212.788574,1224.387329 
	C2214.039551,1224.084961 2215.480713,1221.607910 2215.566895,1220.029785 
	C2215.782959,1216.077515 2215.029785,1212.078979 2215.136230,1208.111694 
	C2215.228027,1204.690430 2213.706299,1203.240967 2210.721680,1204.147095 
	C2208.894043,1204.702026 2207.455078,1206.536987 2205.459473,1208.095459 
	C2203.959717,1204.654663 2200.939697,1202.718262 2195.993164,1204.830933 
	C2195.993164,1206.451538 2195.993164,1208.235107 2195.999756,1210.541504 
	C2196.010010,1210.715454 2196.020264,1210.889282 2196.026367,1211.776855 
M2451.336182,562.274414 
	C2452.983398,562.148438 2454.629883,561.922791 2456.277588,561.912781 
	C2466.771240,561.848816 2477.331299,562.522339 2487.730957,561.517700 
	C2493.176025,560.991699 2495.148438,561.975281 2496.831787,567.058594 
	C2500.854980,579.208069 2516.800781,582.931458 2526.567627,577.059143 
	C2541.920410,567.828003 2540.276367,547.906189 2527.746338,540.488464 
	C2526.699219,539.868774 2525.802490,538.995422 2524.038818,537.616760 
	C2525.330322,536.832092 2526.049072,536.432861 2526.729248,535.976257 
	C2534.167236,530.983215 2537.588623,523.795898 2536.778320,515.090637 
	C2535.903076,505.685577 2529.847168,500.034027 2521.298096,496.818359 
	C2514.833008,494.386475 2504.205566,497.645660 2501.000000,503.000031 
	C2501.000000,503.000031 2501.014160,502.984039 2500.371094,503.233215 
	C2497.749756,507.874023 2495.128418,512.514832 2491.673340,518.631836 
	C2481.899902,518.631836 2470.548828,518.631836 2459.197998,518.631836 
	C2459.181885,519.237366 2459.165771,519.842834 2459.149902,520.448364 
	C2460.655029,520.616272 2462.159912,520.923035 2463.665527,520.928345 
	C2470.658691,520.953247 2477.665283,521.150208 2484.640869,520.785217 
	C2490.267090,520.490723 2494.205811,520.850098 2496.584229,527.557007 
	C2498.202148,532.120178 2503.325928,535.440125 2506.797119,539.223267 
	C2500.440186,542.878479 2496.169678,547.714539 2495.390137,554.286804 
	C2494.899170,558.427917 2493.055664,559.062622 2489.560547,559.038696 
	C2477.406982,558.955750 2465.252441,559.126465 2453.098145,559.096985 
	C2450.339355,559.090271 2448.348877,559.424622 2448.639160,563.439819 
	C2447.068359,578.489014 2448.662842,593.416382 2449.350586,608.378662 
	C2449.778076,608.366943 2450.205811,608.355164 2450.633545,608.343445 
	C2450.633545,593.167725 2450.633545,577.992065 2451.336182,562.274414 
M2008.463135,89.515076 
	C2000.007812,101.574699 2004.744873,119.030586 2020.631348,123.120079 
	C2022.016968,123.476753 2023.726074,126.024513 2023.768555,127.595543 
	C2024.065552,138.586258 2023.943848,149.587891 2024.010132,160.585693 
	C2024.019897,162.202042 2024.315430,163.816696 2024.478760,165.432144 
	C2025.000000,165.393921 2025.521118,165.355713 2026.042358,165.317490 
	C2026.042358,163.742676 2026.046631,162.167847 2026.041504,160.593048 
	C2026.006958,149.929153 2026.098755,139.262360 2025.856689,128.603149 
	C2025.783325,125.371620 2026.666260,123.554214 2029.933472,123.104958 
	C2035.562134,122.330940 2039.453125,119.013229 2042.789429,114.635033 
	C2048.936035,106.568420 2047.648193,93.975334 2040.484131,86.908112 
	C2034.881958,81.381813 2028.340698,80.598549 2020.415405,81.042877 
	C2016.612061,83.730957 2012.808716,86.419029 2008.463135,89.515076 
M95.485977,242.956833 
	C99.750755,242.956833 104.015526,242.956833 107.885567,242.956833 
	C107.467293,239.961487 106.926949,237.656311 106.858154,235.337158 
	C106.498520,223.213226 106.272774,211.085373 105.946167,198.960327 
	C105.923523,198.119370 105.460213,197.290268 105.201820,196.455658 
	C104.770950,196.488800 104.340080,196.521927 103.909210,196.555069 
	C101.396637,211.110016 105.170761,225.873764 103.692406,241.085831 
	C89.900406,241.085831 76.453651,240.936737 63.014133,241.182816 
	C59.306984,241.250702 58.262367,239.800964 56.820541,236.612946 
	C54.713501,231.954056 52.039921,226.768127 48.092949,223.869858 
	C35.684631,214.758331 17.857370,221.950806 15.596484,238.652405 
	C14.421664,247.331039 17.938477,255.361359 26.256618,260.336121 
	C32.608234,264.134766 42.878048,263.871399 48.224983,258.851379 
	C51.711994,255.577515 53.950787,251.006485 57.009476,247.227600 
	C58.172409,245.790848 60.019398,244.055389 61.632286,243.972809 
	C72.574173,243.412491 83.536827,243.257828 95.485977,242.956833 
M2445.162354,141.500137 
	C2445.399658,152.772415 2445.636963,164.044693 2445.874268,175.316971 
	C2446.494873,175.328796 2447.115479,175.340622 2447.736084,175.352448 
	C2447.811035,173.649902 2447.979736,171.945557 2447.946533,170.245102 
	C2447.658447,155.450348 2447.400391,140.654343 2446.970947,125.863426 
	C2446.869629,122.374519 2447.548828,120.172798 2451.556152,119.751129 
	C2463.514404,118.492882 2471.350342,103.597939 2466.885742,91.183884 
	C2466.167725,89.186737 2466.195312,86.921402 2465.831543,84.465652 
	C2459.996582,85.664337 2456.821777,76.551636 2448.902588,79.125343 
	C2452.181885,80.768845 2454.740479,82.050987 2458.621826,83.996124 
	C2452.405029,83.519417 2451.660645,86.350807 2451.987549,90.389099 
	C2452.362061,95.019623 2455.712402,93.625778 2458.296387,93.874001 
	C2459.121826,93.953316 2459.960693,93.892128 2460.793457,93.901146 
	C2464.200684,93.938034 2466.049316,95.502617 2466.046143,99.067833 
	C2466.042969,102.623268 2464.287598,104.408516 2460.804688,104.120461 
	C2458.303955,103.913628 2455.839355,103.267609 2453.115967,102.773529 
	C2454.051025,108.547478 2458.984619,107.450554 2462.646484,108.268250 
	C2461.678467,113.451401 2457.167480,116.724358 2449.625000,117.895767 
	C2441.813232,119.108994 2432.548096,114.700073 2429.590088,107.021324 
	C2424.754883,94.469719 2429.501953,85.341965 2441.786133,79.576057 
	C2440.051758,79.238487 2438.607910,78.992508 2437.582764,79.536064 
	C2427.036133,85.129128 2422.373291,97.629715 2427.037109,106.881195 
	C2430.324219,113.401634 2434.891602,118.169518 2442.266846,119.989281 
	C2443.446289,120.280281 2444.954590,122.253448 2445.021973,123.517776 
	C2445.323486,129.164963 2445.157227,134.837082 2445.162354,141.500137 
M2483.500000,711.983765 
	C2486.495605,711.955688 2489.565430,711.523987 2492.458008,712.055725 
	C2494.109131,712.359314 2496.440186,713.994690 2496.796875,715.441406 
	C2498.346924,721.730164 2501.385010,726.462769 2507.145752,729.821106 
	C2514.942383,734.366211 2522.590088,734.050232 2529.651367,729.251221 
	C2536.450684,724.630188 2540.232178,717.835022 2539.215088,709.190308 
	C2538.033203,699.144897 2529.167480,690.696899 2520.202881,689.863342 
	C2508.485107,688.773804 2497.749023,697.880066 2496.426758,708.939514 
	C2493.973145,709.281067 2491.390381,709.923950 2488.801025,709.951538 
	C2478.147949,710.065186 2467.490234,710.112976 2456.840820,709.884338 
	C2454.725830,709.839050 2452.639648,708.470642 2450.724609,707.778992 
	C2450.724609,722.007690 2450.724609,737.136047 2450.724609,752.264404 
	C2451.185303,752.265015 2451.645996,752.265686 2452.106689,752.266357 
	C2452.106689,739.241699 2452.106689,726.217041 2452.106689,711.962708 
	C2462.714355,711.962708 2472.613037,711.962708 2483.500000,711.983765 
M1087.328369,90.520355 
	C1077.887451,94.808395 1073.177979,102.502304 1074.256470,112.459084 
	C1075.570068,124.587433 1083.733887,130.809891 1096.341553,132.413895 
	C1096.341553,141.525696 1096.341553,150.484573 1096.341553,159.443466 
	C1096.881592,159.474319 1097.421753,159.505173 1097.961792,159.536026 
	C1098.258301,157.922928 1098.783569,156.313538 1098.809937,154.696045 
	C1098.907593,148.698669 1098.986572,142.693527 1098.778442,136.701523 
	C1098.662720,133.373215 1099.571533,131.684082 1103.094482,130.737946 
	C1110.551758,128.735306 1118.354248,117.688309 1117.722900,110.501717 
	C1116.390625,95.334839 1103.104370,85.062714 1087.328369,90.520355 
M1794.954590,159.223389 
	C1794.954590,149.966980 1794.954590,140.710571 1794.954590,131.477249 
	C1805.627686,129.547684 1812.743652,123.987488 1814.639282,113.783447 
	C1816.349609,104.577156 1816.126709,95.439240 1804.889282,91.541634 
	C1804.744751,91.491501 1804.651367,91.309952 1804.515991,91.215820 
	C1794.223267,84.054108 1776.604370,89.829071 1773.161255,101.733521 
	C1769.374512,114.825813 1774.710083,126.569542 1788.347656,130.612183 
	C1791.564575,131.565781 1792.175293,133.051453 1792.153931,135.828644 
	C1792.093994,143.644104 1792.116455,151.460541 1792.168945,159.276169 
	C1792.179199,160.786041 1792.473022,162.293991 1792.636230,163.802811 
	C1793.163208,163.834534 1793.690186,163.866241 1794.217285,163.897949 
	C1794.471558,162.644852 1794.725952,161.391769 1794.954590,159.223389 
M940.792358,116.530777 
	C944.491821,126.034378 951.887146,130.489197 961.895203,131.349365 
	C962.385925,150.692642 961.693115,169.674744 963.440308,188.606781 
	C964.638245,186.909027 964.864624,185.206604 964.835999,183.508499 
	C964.563965,167.379456 964.152527,151.251572 964.081665,135.122040 
	C964.073853,133.338531 965.568420,130.452393 967.008057,129.961960 
	C971.512268,128.427612 974.561035,125.604881 977.535950,122.057854 
	C986.342285,111.558060 982.299927,94.868965 968.354980,89.099251 
	C959.999939,85.642342 952.203735,87.855286 945.900696,93.959503 
	C939.728760,99.936798 937.831360,107.409981 940.792358,116.530777 
M210.022339,120.642082 
	C211.350479,132.344543 219.055328,137.158722 230.011276,138.531677 
	C230.348984,152.099747 229.881973,165.430344 231.430878,178.726639 
	C232.668884,177.052414 232.943268,175.363174 232.943619,173.673859 
	C232.945755,163.366028 232.950134,153.057281 232.803467,142.750977 
	C232.764145,139.986938 233.053452,138.242935 236.362961,137.424332 
	C239.110046,136.744827 242.013031,135.154938 243.979721,133.127197 
	C252.581070,124.258934 253.984222,110.222794 243.491959,100.883537 
	C237.515686,95.564011 227.508560,94.073563 220.308044,98.027611 
	C212.520065,102.304253 208.080505,111.601967 210.022339,120.642082 
M1306.338745,87.798180 
	C1295.995972,90.023460 1289.690186,97.519096 1288.903687,107.242584 
	C1288.089233,117.310600 1295.215698,127.791313 1305.087646,129.831192 
	C1308.463745,130.528824 1309.236206,132.277863 1309.182129,135.397522 
	C1309.034912,143.873810 1309.121338,152.354706 1309.175415,160.833405 
	C1309.186035,162.503174 1309.466919,164.171204 1309.623169,165.840027 
	C1310.144775,165.917450 1310.666504,165.994873 1311.188110,166.072311 
	C1311.647705,164.921600 1312.513550,163.766632 1312.501587,162.620880 
	C1312.416382,154.495834 1312.443481,146.350845 1311.839600,138.256653 
	C1311.445923,132.981445 1313.036987,130.376251 1318.269775,128.567123 
	C1328.193115,125.136330 1333.584473,113.587212 1330.816650,103.576973 
	C1327.894531,93.008911 1317.692139,86.117691 1306.338745,87.798180 
M124.756271,119.352448 
	C123.792068,111.189964 121.557968,104.033791 113.409698,99.729782 
	C105.839401,95.731071 98.788635,96.050316 91.770386,100.310646 
	C85.427734,104.160843 80.884598,114.608482 82.220161,121.453003 
	C83.822548,129.664993 91.035545,137.900528 98.216034,138.707153 
	C102.384232,139.175369 103.045967,141.250992 103.020309,144.638428 
	C102.967392,151.623962 103.009407,158.610901 103.096237,165.596222 
	C103.113998,167.024612 103.499893,168.448425 103.716080,169.874359 
	C104.258018,169.857056 104.799950,169.839752 105.341888,169.822449 
	C105.508224,168.320953 105.809601,166.820206 105.818100,165.317825 
	C105.860428,157.832214 105.728966,150.343857 105.908997,142.862488 
	C105.940727,141.544022 106.933693,139.322891 107.868324,139.094162 
	C117.929100,136.631973 122.350838,129.377289 124.756271,119.352448 
M753.861755,159.124771 
	C753.861389,154.460449 754.096191,149.781265 753.802368,145.135498 
	C753.416260,139.030731 753.207153,133.320267 761.433777,132.518906 
	C761.859436,132.477448 762.248230,131.974884 762.638733,131.667404 
	C776.033386,121.121323 776.434570,107.105293 764.524414,97.054138 
	C751.983398,86.470566 736.296448,92.770569 730.702087,105.641487 
	C724.707825,119.432472 735.454590,132.519226 747.270020,134.729889 
	C750.293091,135.295502 751.154114,136.546509 751.122986,139.310287 
	C751.049866,145.805923 751.092163,152.303497 751.159912,158.799530 
	C751.173828,160.133423 751.503052,161.464035 751.686890,162.796158 
	C752.110168,162.817413 752.533386,162.838654 752.956604,162.859894 
	C753.256470,161.911774 753.556335,160.963654 753.861755,159.124771 
M540.149719,159.343155 
	C540.149719,161.722778 540.149719,164.102386 540.149719,166.482010 
	C540.724060,166.479401 541.298340,166.476807 541.872681,166.474197 
	C541.872681,156.165909 541.872681,145.857620 541.872681,135.751358 
	C542.952148,135.326324 543.389648,135.080307 543.858337,134.980331 
	C554.935913,132.617371 563.030762,120.551422 560.659912,109.915390 
	C558.050293,98.207962 546.073669,90.229317 534.710144,93.150673 
	C525.798950,95.441566 518.363098,103.408348 519.038452,115.729172 
	C519.511658,124.361847 527.463318,133.943817 535.774963,134.794205 
	C539.392700,135.164352 540.195923,136.718170 540.133179,139.899658 
	C540.011719,146.060074 540.136597,152.225357 540.149719,159.343155 
M754.998474,263.867004 
	C754.571350,262.931824 754.144165,261.996643 753.717041,261.061493 
	C753.277893,261.175323 752.838684,261.289185 752.399536,261.403046 
	C752.280884,262.815857 751.893616,264.267792 752.092834,265.634277 
	C752.753052,270.161346 750.823181,271.323425 746.492676,271.157501 
	C738.013306,270.832581 729.512573,271.042236 721.020996,271.087341 
	C719.895630,271.093323 718.771912,271.430450 717.647461,271.614227 
	C719.317627,272.792145 720.893738,272.925598 722.470215,272.929657 
	C730.795532,272.951111 739.123535,273.063141 747.444824,272.879700 
	C750.788086,272.806030 752.233459,273.652496 752.125000,277.311707 
	C751.889954,285.243073 751.964722,293.188507 752.129150,301.123688 
	C752.175476,303.357971 752.972412,305.576691 753.425049,307.802551 
	C754.074036,307.742523 754.723022,307.682495 755.372009,307.622467 
	C755.552795,305.447754 755.937256,303.267395 755.877563,301.099274 
	C755.664734,293.364410 755.463989,285.622375 754.927673,277.906036 
	C754.648438,273.889404 756.067749,272.812775 759.986206,272.856842 
	C777.134216,273.049805 794.285583,272.948730 811.435730,272.925812 
	C813.074768,272.923615 814.713562,272.723572 816.352478,272.615417 
	C816.316956,272.097626 816.281372,271.579865 816.245789,271.062103 
	C814.524048,271.062103 812.802307,271.062103 811.080566,271.062103 
	C794.429932,271.062012 777.773987,270.825195 761.131775,271.200317 
	C755.864929,271.319031 754.157410,269.653961 754.998474,263.867004 
M387.370331,97.003036 
	C383.168121,94.034950 378.594482,92.691605 373.358765,92.907021 
	C363.308350,93.320541 353.806976,101.797089 353.090027,111.392090 
	C352.183624,123.521767 359.095764,133.137589 370.201111,134.912766 
	C374.151703,135.544281 377.272308,136.539978 376.443573,141.531937 
	C376.406311,141.756210 377.053528,142.094116 377.479187,142.463501 
	C378.360748,136.373444 378.448639,136.550247 383.919342,133.830750 
	C391.309143,130.157303 395.192413,123.969070 395.948486,115.765350 
	C396.648346,108.171524 393.633667,102.245262 387.370331,97.003036 
M736.496948,237.971756 
	C740.323730,237.967865 744.150635,237.981171 747.977295,237.956161 
	C754.193542,237.915543 754.978638,237.170868 754.978210,231.129547 
	C754.977295,218.219879 754.998108,205.309402 754.820496,192.401886 
	C754.804016,191.204346 753.433105,190.025452 752.691040,188.837891 
	C752.143372,189.066086 751.595642,189.294296 751.047974,189.522491 
	C751.047974,191.305389 751.006409,193.089432 751.054749,194.871017 
	C751.369507,206.480820 751.578491,218.095749 752.099792,229.696503 
	C752.281433,233.739029 751.175293,235.179825 746.886169,235.081451 
	C734.913452,234.806870 722.929260,234.972504 710.950806,235.088745 
	C709.387451,235.103912 707.831177,235.847748 706.271667,236.254074 
	C706.402100,236.826508 706.532593,237.398926 706.663025,237.971359 
	C716.274658,237.971359 725.886292,237.971359 736.496948,237.971756 
M156.660995,1045.023315 
	C157.235672,1046.359741 157.810349,1047.696289 158.385040,1049.032715 
	C159.289520,1047.127075 160.193985,1045.221436 160.540649,1044.491089 
	C164.279114,1042.401489 167.186218,1040.553711 170.309647,1039.208130 
	C170.979950,1038.919556 173.216537,1040.303833 173.375092,1041.166626 
	C173.810638,1043.537231 173.525391,1046.040039 173.525391,1049.187378 
	C178.248459,1048.928101 182.199158,1048.711304 186.149857,1048.494507 
	C186.148651,1047.834961 186.147461,1047.175415 186.146271,1046.515747 
	C183.333679,1046.241699 180.521088,1045.967773 177.236832,1045.647827 
	C177.236832,1042.250244 177.432587,1038.947266 177.157684,1035.683960 
	C177.011108,1033.943970 176.051117,1032.272461 175.458282,1030.570068 
	C174.575974,1031.946899 173.709732,1033.334717 172.796234,1034.690430 
	C172.634918,1034.929932 172.276245,1035.036255 171.331207,1035.628906 
	C167.990067,1029.880737 162.533905,1030.179443 156.640533,1031.069336 
	C156.640533,1035.571167 156.640533,1039.850708 156.660995,1045.023315 
M1099.850220,212.503845 
	C1099.752808,188.535522 1099.663208,187.780823 1096.595703,185.707138 
	C1096.595703,201.049896 1096.595703,216.346207 1096.595703,232.421890 
	C1087.330444,232.421890 1078.533936,232.421890 1069.737305,232.421890 
	C1079.291504,235.226944 1119.104736,234.118179 1122.079956,231.148865 
	C1115.608765,231.148865 1109.887939,231.007812 1104.178955,231.204071 
	C1100.977051,231.314133 1099.659790,230.286942 1099.811279,226.967331 
	C1100.015747,222.487564 1099.851929,217.990982 1099.850220,212.503845 
M962.959229,220.729614 
	C962.967651,223.223511 962.769592,225.739471 963.033081,228.206131 
	C963.425781,231.882843 962.157837,233.205444 958.342346,233.075073 
	C950.869629,232.819717 943.377136,232.899200 935.900391,233.108932 
	C932.108154,233.215302 928.332336,233.906662 924.549133,234.333572 
	C924.574158,234.834763 924.599121,235.335968 924.624146,235.837158 
	C937.217346,235.837158 949.810547,235.837158 963.203491,235.837158 
	C963.203491,244.576324 963.203491,252.528091 963.203491,260.479858 
	C963.786926,260.475739 964.370300,260.471649 964.953735,260.467529 
	C964.953735,252.524155 964.953735,244.580780 964.953735,236.962936 
	C966.964722,235.946167 968.399597,235.220688 970.818848,233.997482 
	C968.054565,233.094421 966.585815,232.614594 964.495483,231.931702 
	C964.495483,226.434601 964.495483,221.024414 964.495483,215.614227 
	C964.087830,215.621597 963.680237,215.628967 963.272583,215.636337 
	C963.166687,217.027191 963.060791,218.418060 962.959229,220.729614 
M224.013992,1033.971436 
	C223.422089,1032.770996 222.830200,1031.570679 222.238297,1030.370239 
	C221.621948,1031.326416 220.923431,1032.240723 220.409668,1033.249146 
	C219.741959,1034.559692 219.221207,1035.944946 218.637192,1037.298096 
	C217.519882,1036.211670 216.354446,1035.169189 215.304916,1034.020874 
	C214.753738,1033.417725 214.542694,1032.459717 213.932251,1031.965820 
	C213.105469,1031.296631 211.970901,1030.444946 211.105545,1030.576172 
	C210.489334,1030.669678 209.703613,1032.242554 209.675873,1033.175293 
	C209.547501,1037.493652 209.564865,1041.823120 209.760574,1046.138306 
	C209.799835,1047.003906 210.842773,1047.824097 211.422974,1048.665161 
	C212.136246,1047.773315 213.326263,1046.949585 213.465652,1045.975464 
	C213.776535,1043.803223 213.562744,1041.555786 213.562744,1037.862183 
	C216.083954,1041.742065 217.634064,1044.445068 219.532883,1046.875610 
	C220.187393,1047.713501 221.710663,1047.872681 222.837036,1048.342041 
	C223.200897,1047.340820 223.835526,1046.351196 223.879471,1045.336182 
	C224.030426,1041.849976 223.974579,1038.354980 224.013992,1033.971436 
M2067.130371,523.857666 
	C2068.609863,523.726440 2070.089600,523.595154 2071.569092,523.463928 
	C2071.549072,522.943420 2071.529053,522.422913 2071.508789,521.902405 
	C2057.472900,521.902405 2043.437134,521.902405 2029.040039,521.902405 
	C2029.040039,508.046753 2029.040039,494.926025 2029.040039,481.805267 
	C2028.517944,481.807098 2027.995972,481.808960 2027.473877,481.810791 
	C2027.473877,496.507202 2027.473877,511.203613 2027.473877,525.540894 
	C2040.751587,522.447998 2053.558838,524.602844 2067.130371,523.857666 
M303.749878,1044.984131 
	C303.403503,1043.513794 303.057098,1042.043457 302.681519,1040.449341 
	C306.065369,1039.970703 308.837738,1039.578613 311.610107,1039.186523 
	C311.566589,1038.558594 311.523071,1037.930786 311.479553,1037.302856 
	C308.695831,1037.093628 305.912109,1036.884399 303.128357,1036.675171 
	C302.841278,1036.181396 302.554199,1035.687622 302.267120,1035.193848 
	C303.512817,1034.252808 304.632904,1032.886963 306.034302,1032.472534 
	C308.020752,1031.885132 310.214539,1031.998901 312.319733,1031.812988 
	C312.283356,1031.304932 312.246979,1030.796875 312.210602,1030.288818 
	C307.868530,1030.288818 303.526428,1030.288818 299.221130,1030.288818 
	C299.221130,1036.148560 299.221130,1041.826050 299.221130,1047.170166 
	C303.955872,1047.170166 308.242554,1047.170166 312.529266,1047.170166 
	C312.523865,1046.509644 312.518463,1045.849243 312.513062,1045.188721 
	C309.887909,1045.188721 307.262787,1045.188721 303.749878,1044.984131 
M971.109863,1067.875732 
	C971.581604,1068.453613 972.053345,1069.031616 972.524963,1069.609619 
	C972.964783,1068.326904 973.800537,1067.039185 973.784607,1065.762329 
	C973.566528,1048.337280 973.244019,1030.913574 972.892334,1013.490479 
	C972.869995,1012.383118 972.396545,1011.284729 972.132996,1010.182129 
	C971.508911,1010.240173 970.884766,1010.298157 970.260681,1010.356140 
	C970.092651,1029.240234 969.842224,1048.123901 971.109863,1067.875732 
M193.843796,1047.029419 
	C198.292542,1042.120850 201.509140,1048.149292 206.176483,1049.321167 
	C203.879868,1042.966187 202.065598,1037.704590 200.021210,1032.533936 
	C199.692642,1031.702881 198.385117,1030.920898 197.439087,1030.793823 
	C196.833954,1030.712402 195.707809,1031.742065 195.421204,1032.502075 
	C193.455948,1037.713257 191.650848,1042.984741 189.269485,1049.721191 
	C191.514786,1048.678345 192.488190,1048.226318 193.843796,1047.029419 
M2279.429443,561.579041 
	C2277.608154,561.109680 2275.780762,560.213745 2273.966553,560.239685 
	C2261.684814,560.415405 2249.405273,560.769226 2237.126953,561.124573 
	C2236.115234,561.153809 2235.116455,561.620667 2234.111572,561.884155 
	C2235.182617,563.317749 2236.297607,563.795837 2237.414551,563.800293 
	C2249.888672,563.850037 2262.363770,563.877625 2274.836914,563.757385 
	C2276.365967,563.742737 2277.886475,562.831299 2279.429443,561.579041 
M216.884064,1023.965637 
	C217.090546,1023.752380 217.315247,1023.552063 217.485321,1023.312927 
	C217.512939,1023.273926 217.311890,1022.962158 217.194977,1022.947449 
	C215.743042,1022.764160 214.286240,1022.465759 212.832336,1022.474426 
	C186.226837,1022.632812 159.621536,1022.823242 133.016449,1023.039673 
	C132.037186,1023.047668 131.060638,1023.387878 129.330353,1023.716003 
	C130.516785,1024.401611 130.901535,1024.819580 131.283035,1024.816650 
	C159.522705,1024.599121 187.762115,1024.348389 216.884064,1023.965637 
M2095.579590,562.084290 
	C2092.792725,562.084290 2090.005859,562.084290 2087.219238,562.084290 
	C2087.237305,562.962769 2087.255371,563.841309 2087.273438,564.719788 
	C2102.761475,564.959961 2118.248291,564.866455 2133.735107,564.797119 
	C2133.743164,563.889893 2133.750977,562.982727 2133.759033,562.075562 
	C2121.358643,562.075562 2108.958496,562.075562 2095.579590,562.084290 
M753.088196,408.055908 
	C753.410034,422.858124 753.731934,437.660370 754.053772,452.462585 
	C754.643188,452.532257 755.232666,452.601929 755.822083,452.671600 
	C756.200562,451.149414 756.929077,449.621124 756.902527,448.106049 
	C756.675842,435.161530 756.318542,422.219238 755.974670,409.276947 
	C755.953247,408.470001 755.972656,407.382599 755.497192,406.955963 
	C755.120605,406.618042 754.013855,407.093872 753.088196,408.055908 
M2402.452148,562.839966 
	C2409.200195,562.459045 2415.947998,562.078064 2422.696045,561.697083 
	C2422.662354,561.237000 2422.628906,560.776978 2422.595215,560.316895 
	C2407.447021,560.316895 2392.298828,560.316895 2377.150635,560.316895 
	C2377.130859,561.159058 2377.111328,562.001221 2377.091553,562.843384 
	C2385.216064,562.843384 2393.340820,562.843384 2402.452148,562.839966 
M1310.080322,265.994476 
	C1310.378296,271.282379 1310.838623,276.567261 1310.936768,281.858887 
	C1311.090698,290.174652 1310.977417,298.495148 1311.037964,306.813171 
	C1311.047241,308.083008 1311.382812,309.350464 1311.567505,310.619019 
	C1312.083252,310.613647 1312.598999,310.608276 1313.114746,310.602936 
	C1313.393555,308.943146 1313.896973,307.285583 1313.913330,305.623199 
	C1313.993896,297.471985 1314.051758,289.317505 1313.900879,281.168213 
	C1313.812134,276.377075 1313.315552,271.593658 1313.009155,266.806305 
	C1312.885864,264.880554 1312.548706,263.348297 1310.080322,265.994476 
M2450.972412,512.500000 
	C2450.972412,505.066864 2450.972412,497.633728 2450.972412,490.200592 
	C2450.236572,490.227325 2449.500488,490.254059 2448.764648,490.280762 
	C2448.764648,505.787506 2448.764648,521.294250 2448.764648,536.801025 
	C2449.500000,536.823120 2450.235352,536.845276 2450.970703,536.867432 
	C2450.970703,529.078308 2450.970703,521.289124 2450.972412,512.500000 
M1796.912598,337.959076 
	C1796.542969,337.001556 1796.173218,336.044067 1795.803467,335.086578 
	C1795.258301,335.207489 1794.713013,335.328369 1794.167725,335.449280 
	C1794.166626,350.528564 1793.997437,365.610474 1794.327515,380.684509 
	C1795.192017,380.688171 1796.056641,380.691833 1796.921143,380.695496 
	C1796.921143,366.751343 1796.921143,352.807220 1796.912598,337.959076 
M2163.965332,563.974792 
	C2178.239014,563.974792 2192.512451,563.974792 2206.786133,563.974792 
	C2206.820557,563.215332 2206.854980,562.455872 2206.889404,561.696472 
	C2205.251709,561.503235 2203.614502,561.149475 2201.976074,561.142822 
	C2191.659668,561.100891 2181.343262,561.133423 2171.026855,561.147949 
	C2168.364746,561.151672 2165.697754,561.094849 2163.044678,561.259338 
	C2162.430420,561.297302 2161.865479,562.128723 2161.277832,562.595032 
	C2161.881592,563.049988 2162.485596,563.504883 2163.965332,563.974792 
M2456.834961,1118.289551 
	C2456.430420,1101.779785 2456.026123,1085.270142 2455.621582,1068.760376 
	C2455.090820,1068.780396 2454.560059,1068.800537 2454.029297,1068.820557 
	C2454.141357,1087.336792 2453.640137,1105.867676 2454.513672,1124.365845 
	C2455.287354,1124.360596 2456.061279,1124.355469 2456.834961,1124.350220 
	C2456.834961,1122.637451 2456.834961,1120.924683 2456.834961,1118.289551 
M753.039185,356.500061 
	C753.039185,364.275726 753.039185,372.051392 753.039185,379.827087 
	C753.768005,379.807678 754.496887,379.788269 755.225708,379.768890 
	C755.225708,364.607178 755.225708,349.445496 755.225708,334.283783 
	C754.496582,334.256378 753.767517,334.228973 753.038391,334.201569 
	C753.038391,341.301086 753.038391,348.400604 753.039185,356.500061 
M1311.030396,353.502258 
	C1311.033203,361.668823 1310.971802,369.836334 1311.093994,378.001129 
	C1311.117676,379.576599 1311.859253,381.141266 1312.269531,382.710938 
	C1312.591797,382.574799 1312.914185,382.438690 1313.236450,382.302551 
	C1313.236450,367.267883 1313.236450,352.233185 1313.236450,337.198517 
	C1312.498291,337.175201 1311.760010,337.151886 1311.021851,337.128571 
	C1311.021851,342.253754 1311.021851,347.378967 1311.030396,353.502258 
M1310.134766,220.499054 
	C1310.134766,226.299194 1310.134766,232.099335 1310.134766,237.899490 
	C1310.715454,237.918884 1311.296143,237.938293 1311.876831,237.957687 
	C1312.223022,237.138153 1312.868408,236.319595 1312.871094,235.498962 
	C1312.914062,222.189667 1312.909546,208.879807 1312.792236,195.571182 
	C1312.782471,194.467987 1311.883179,193.372635 1311.397217,192.273621 
	C1310.977661,192.401581 1310.558228,192.529541 1310.138794,192.657486 
	C1310.138794,201.604843 1310.138794,210.552200 1310.134766,220.499054 
M1961.655151,522.143677 
	C1959.484863,522.318787 1957.314453,522.493896 1955.144165,522.669067 
	C1955.163940,523.168823 1955.183716,523.668640 1955.203491,524.168457 
	C1955.758179,524.403687 1956.312378,524.843323 1956.867432,524.844299 
	C1970.476440,524.868103 1984.085815,524.873779 1997.694092,524.771118 
	C1998.666138,524.763794 1999.631470,523.901184 2000.599976,523.436279 
	C2000.477783,522.993652 2000.355469,522.550964 2000.233276,522.108276 
	C1987.694336,522.108276 1975.155396,522.108276 1961.655151,522.143677 
M1024.499268,231.996826 
	C1015.224182,232.582550 1005.749207,230.814056 995.460754,233.534485 
	C996.981873,234.536972 997.244141,234.860794 997.507263,234.861588 
	C1011.490479,234.903854 1025.473999,234.953293 1039.456665,234.869827 
	C1040.549927,234.863297 1041.637573,233.908264 1042.727783,233.394058 
	C1042.598022,232.930405 1042.468140,232.466751 1042.338379,232.003098 
	C1036.725098,232.003098 1031.111694,232.003098 1024.499268,231.996826 
M2329.500244,562.964722 
	C2336.591797,562.964722 2343.683594,562.964722 2350.775146,562.964722 
	C2350.801514,562.371765 2350.828125,561.778809 2350.854492,561.185852 
	C2350.041504,560.843201 2349.229736,560.205505 2348.415283,560.202026 
	C2335.153320,560.144348 2321.891113,560.133179 2308.629639,560.234131 
	C2307.537842,560.242371 2306.452637,561.134705 2305.364258,561.616272 
	C2305.491455,562.066772 2305.618408,562.517212 2305.745605,562.967651 
	C2313.330322,562.967651 2320.915283,562.967651 2329.500244,562.964722 
M104.163300,289.500122 
	C104.165184,293.822845 103.947113,298.160187 104.233200,302.464020 
	C104.498825,306.460083 102.962769,310.788940 106.279663,314.356873 
	C106.279663,299.295563 106.279663,284.234253 106.279663,269.172943 
	C105.573898,269.148590 104.868126,269.124237 104.162361,269.099884 
	C104.162361,275.566711 104.162361,282.033539 104.163300,289.500122 
M2286.889404,522.797424 
	C2287.495117,522.349243 2288.100830,521.901123 2288.706787,521.453064 
	C2287.820801,520.952209 2286.937012,520.019470 2286.048828,520.015320 
	C2272.919189,519.954163 2259.788574,519.982178 2246.659424,520.111694 
	C2245.564941,520.122498 2244.479492,521.015808 2243.389893,521.499084 
	C2243.516113,521.940430 2243.642334,522.381714 2243.768555,522.822998 
	C2257.849121,522.822998 2271.929443,522.822998 2286.889404,522.797424 
M1793.109131,216.499359 
	C1793.109131,222.963364 1793.109131,229.427353 1793.109131,235.891357 
	C1793.703491,235.913040 1794.297974,235.934708 1794.892334,235.956390 
	C1795.247681,235.147766 1795.911255,234.339584 1795.912476,233.530411 
	C1795.931274,220.219147 1795.899170,206.907410 1795.760620,193.597000 
	C1795.749146,192.491455 1794.890137,191.394730 1794.424805,190.293900 
	C1793.984497,190.418747 1793.544312,190.543594 1793.104004,190.668457 
	C1793.104004,198.945526 1793.104004,207.222610 1793.109131,216.499359 
M2126.498047,523.850220 
	C2129.483154,523.843689 2132.469971,523.898499 2135.452881,523.812927 
	C2138.246338,523.732910 2141.035400,523.504150 2143.826172,523.340942 
	C2137.845215,519.836182 2100.935303,520.651428 2097.644287,523.872131 
	C2107.504639,523.872131 2116.501709,523.872131 2126.498047,523.850220 
M543.938965,234.230362 
	C543.588257,220.538177 543.237549,206.846008 542.886841,193.153824 
	C542.324585,193.185638 541.762329,193.217438 541.200073,193.249252 
	C541.200073,208.414932 541.200073,223.580627 541.200073,238.746323 
	C541.866638,238.808472 542.533203,238.870621 543.199768,238.932755 
	C543.446045,237.671112 543.692261,236.409470 543.938965,234.230362 
M608.423523,237.262405 
	C607.663940,236.838867 606.906067,236.049683 606.144531,236.046173 
	C593.011292,235.985596 579.877258,235.988174 566.744385,236.095108 
	C565.584656,236.104553 564.431641,236.934082 563.275574,237.382690 
	C564.287476,238.641617 565.033569,238.948166 565.780151,238.949356 
	C578.581421,238.969757 591.382874,238.981903 604.183716,238.897049 
	C605.442749,238.888718 606.697510,238.234406 608.423523,237.262405 
M872.500244,234.041367 
	C865.862793,234.041367 859.225281,234.041367 852.587830,234.041367 
	C856.601379,238.049362 894.181030,237.443665 897.714661,234.043777 
	C889.347473,234.043777 881.423828,234.043777 872.500244,234.041367 
M1314.867065,451.948700 
	C1314.454590,437.854462 1315.914795,423.656616 1312.535278,409.733246 
	C1312.535278,424.946259 1312.535278,440.159271 1312.535278,455.372314 
	C1312.880005,455.441315 1313.224731,455.510345 1313.569580,455.579346 
	C1313.999268,454.654480 1314.428955,453.729584 1314.867065,451.948700 
M463.936066,236.994461 
	C449.840057,237.392517 435.659271,236.026108 421.632477,238.946732 
	C422.635559,239.623474 423.665588,239.962723 424.696350,239.964905 
	C437.194031,239.991364 449.691833,239.979904 462.189514,239.938126 
	C463.330841,239.934311 464.471252,239.653412 465.612091,239.501175 
	C465.336975,238.673492 465.061859,237.845810 463.936066,236.994461 
M1099.311646,258.547180 
	C1098.582642,259.772156 1097.210449,261.003906 1097.223389,262.221130 
	C1097.364990,275.652649 1097.710938,289.082184 1098.051880,302.510986 
	C1098.069092,303.188324 1098.547852,303.853912 1098.986938,304.970612 
	C1099.777222,304.050781 1100.539551,303.575714 1100.532837,303.111755 
	C1100.322144,288.493622 1100.036621,273.876587 1099.311646,258.547180 
M1794.003296,271.567932 
	C1794.003296,283.866180 1794.003296,296.164429 1794.003296,308.462677 
	C1797.921631,304.447601 1797.276611,266.454590 1794.000000,261.990356 
	C1794.000000,265.347839 1794.000000,267.971191 1794.003296,271.567932 
M2345.495117,519.105469 
	C2342.010742,519.103760 2338.525391,519.057251 2335.041992,519.113037 
	C2331.590088,519.168335 2328.137451,519.275696 2324.689941,519.455444 
	C2321.620361,519.615417 2318.557373,519.905457 2315.491455,520.138062 
	C2315.544922,520.751953 2315.598389,521.365845 2315.652100,521.979736 
	C2330.379639,521.946838 2345.113281,522.200989 2359.833252,521.800903 
	C2359.845459,520.897522 2359.857422,519.994202 2359.869629,519.090881 
	C2355.409668,519.090881 2350.949951,519.090881 2345.495117,519.105469 
M963.053894,302.452179 
	C963.316528,306.081604 963.658020,309.707581 963.818970,313.341492 
	C963.970459,316.760651 963.897827,320.189087 963.986084,323.611877 
	C964.070190,326.878265 961.550476,330.537109 965.480469,333.668396 
	C965.480469,318.004211 965.480469,302.757477 965.480469,287.510712 
	C964.981995,287.470398 964.483459,287.430084 963.984985,287.389771 
	C962.000366,291.857239 963.521301,296.800446 963.053894,302.452179 
M1797.737671,435.626526 
	C1797.482056,426.288696 1797.226562,416.950867 1796.970947,407.613037 
	C1796.624878,407.635712 1796.278931,407.658386 1795.932861,407.681030 
	C1795.932861,422.653839 1795.932861,437.626648 1795.932861,452.599426 
	C1796.533936,452.606506 1797.134888,452.613586 1797.735962,452.620667 
	C1797.735962,447.278076 1797.735962,441.935516 1797.737671,435.626526 
M2390.875000,521.894348 
	C2397.833740,521.759521 2404.793213,521.642090 2411.751465,521.484802 
	C2418.331299,521.336060 2424.910156,521.145264 2431.489502,520.972961 
	C2431.458496,520.264587 2431.427490,519.556213 2431.396484,518.847839 
	C2416.458496,519.275024 2401.489258,518.560120 2386.559814,519.302856 
	C2386.539307,519.985413 2386.519043,520.667969 2386.498779,521.350525 
	C2387.663086,521.532043 2388.827637,521.713501 2390.875000,521.894348 
M1901.451172,525.838013 
	C1910.420166,525.509399 1919.389282,525.180786 1928.358276,524.852234 
	C1919.941406,521.751404 1885.427368,522.703796 1882.500732,525.836548 
	C1889.063110,525.836548 1894.764404,525.836548 1901.451172,525.838013 
M48.919147,932.065063 
	C51.821980,932.486755 54.724812,932.908447 57.359142,933.291199 
	C55.985321,928.736267 51.202114,927.075562 47.103447,930.363220 
	C42.365250,934.163879 41.524364,944.123901 45.218296,949.370300 
	C47.539749,952.667419 50.797646,952.340820 53.720726,951.625000 
	C55.785374,951.119446 57.467659,949.052368 59.320637,947.682373 
	C58.940975,947.197998 58.561314,946.713562 58.181656,946.229187 
	C57.188950,946.916565 56.277458,947.797668 55.188042,948.254456 
	C52.146473,949.529907 49.010799,950.147522 46.708584,946.971741 
	C45.017673,944.639465 46.128990,934.969421 48.919147,932.065063 
M2197.538818,520.151245 
	C2188.891846,520.480713 2180.244873,520.810181 2171.598145,521.139648 
	C2171.625000,521.596558 2171.652100,522.053467 2171.679199,522.510376 
	C2186.566162,522.510376 2201.453125,522.510376 2216.340088,522.510376 
	C2216.389404,521.870667 2216.438721,521.230896 2216.487793,520.591187 
	C2210.591309,519.587524 2204.508301,520.344788 2197.538818,520.151245 
M2424.499756,712.313599 
	C2423.299072,711.547852 2422.090820,710.106934 2420.898682,710.119812 
	C2407.459961,710.265137 2394.023438,710.624634 2380.587646,710.967102 
	C2379.864014,710.985535 2379.149170,711.363647 2378.430420,711.574280 
	C2378.460693,711.980469 2378.490967,712.386597 2378.521484,712.792786 
	C2393.611572,712.792786 2408.701660,712.792786 2424.499756,712.313599 
M541.977234,294.742004 
	C541.977234,300.283844 541.977234,305.825684 541.977234,311.367523 
	C542.644470,311.336243 543.311768,311.304962 543.979004,311.273651 
	C543.979004,303.347534 544.368896,295.396545 543.865845,287.502472 
	C543.393311,280.086121 546.035583,272.433563 541.970642,265.230103 
	C541.970642,274.753632 541.970642,284.277130 541.977234,294.742004 
M1812.483398,525.782959 
	C1827.116333,525.782959 1841.749390,525.782959 1856.382324,525.782959 
	C1856.435669,525.195740 1856.489014,524.608582 1856.542358,524.021362 
	C1855.274292,523.743958 1854.002441,523.210205 1852.738770,523.228760 
	C1839.798340,523.418091 1826.858643,523.676208 1813.921143,524.008423 
	C1813.222412,524.026367 1812.545044,524.875671 1812.483398,525.782959 
M964.018250,361.193604 
	C963.990295,376.054718 963.989380,390.914886 965.206177,405.807831 
	C966.560425,404.380127 966.970093,402.908813 966.912720,401.455963 
	C966.717346,396.507111 966.189941,391.567841 966.097595,386.619171 
	C965.941101,378.226227 966.042297,369.828613 965.920410,361.434601 
	C965.915222,361.075378 964.759949,360.732849 964.018250,361.193604 
M2528.178223,1054.307129 
	C2528.484863,1055.484375 2528.791504,1056.661621 2529.098145,1057.838867 
	C2529.546387,1057.673340 2529.994629,1057.507812 2530.443115,1057.342285 
	C2532.231689,1049.062500 2525.568359,1043.767944 2523.318604,1036.674316 
	C2517.197510,1041.339844 2513.548584,1053.097290 2516.413330,1058.582886 
	C2516.815186,1056.215698 2517.156250,1054.208374 2517.657959,1051.253784 
	C2521.382080,1052.045654 2524.715576,1052.754517 2528.178223,1054.307129 
M175.959381,242.812714 
	C177.458939,242.351700 178.958496,241.890701 180.458054,241.429688 
	C180.347183,241.294312 180.236298,241.158920 180.125427,241.023529 
	C165.325043,241.023529 150.524673,241.023529 135.724304,241.023529 
	C135.724777,241.620575 135.725235,242.217621 135.725708,242.814667 
	C148.842087,242.814667 161.958481,242.814667 175.959381,242.812714 
M2451.117188,867.502625 
	C2451.392090,877.423523 2451.666992,887.344421 2451.941650,897.265259 
	C2452.242676,897.244995 2452.543701,897.224731 2452.844727,897.204468 
	C2452.844727,882.071228 2452.844727,866.937927 2452.844727,851.804688 
	C2452.266846,851.807129 2451.689209,851.809570 2451.111328,851.812012 
	C2451.111328,856.709717 2451.111328,861.607483 2451.117188,867.502625 
M2451.065674,656.213257 
	C2451.065674,649.335327 2451.065674,642.457397 2451.065674,635.579529 
	C2449.516113,635.908630 2449.170410,636.166382 2449.169434,636.425354 
	C2449.118408,650.575684 2449.066650,664.726257 2449.138428,678.876099 
	C2449.143311,679.831177 2450.104736,680.781372 2450.621582,681.733826 
	C2451.191650,681.589478 2451.761719,681.445129 2452.331787,681.300781 
	C2451.925293,673.252502 2451.519043,665.204163 2451.065674,656.213257 
M288.377777,1029.898560 
	C285.856201,1030.024536 283.334595,1030.150635 280.812988,1030.276733 
	C280.813507,1030.831177 280.814026,1031.385620 280.814545,1031.940186 
	C282.575165,1032.401978 284.335815,1032.863770 286.346619,1033.391235 
	C286.346619,1037.087158 286.175232,1040.566895 286.432129,1044.014648 
	C286.532898,1045.367432 287.592865,1046.648682 288.215973,1047.962524 
	C288.855927,1046.698120 289.617859,1045.476807 290.087738,1044.152100 
	C290.348419,1043.417236 290.125458,1042.509277 290.113312,1041.679443 
	C290.049561,1037.333862 288.411804,1032.246460 296.015747,1031.520386 
	C295.746216,1031.103027 295.476715,1030.685669 295.207214,1030.268311 
	C293.231964,1030.145996 291.256744,1030.023560 288.377777,1029.898560 
M2448.781250,246.474091 
	C2448.480469,231.848175 2448.179932,217.222244 2447.857666,201.542923 
	C2446.809814,202.841324 2446.295166,203.181519 2446.294434,203.522812 
	C2446.263428,217.271744 2446.240967,231.021164 2446.383545,244.768814 
	C2446.391846,245.578445 2447.775635,246.373810 2448.781250,246.474091 
M544.029846,361.543884 
	C544.029846,353.584442 544.029846,345.625000 544.029846,337.665588 
	C543.350769,337.685791 542.671753,337.706024 541.992676,337.726227 
	C542.070374,353.061218 541.797729,368.403412 542.184998,383.731995 
	C542.799011,383.725250 543.413025,383.718536 544.027039,383.711823 
	C544.027039,376.644684 544.027039,369.577515 544.029846,361.543884 
M2150.239014,1230.253784 
	C2147.819824,1230.525635 2145.070312,1230.177856 2143.072998,1231.241455 
	C2141.028320,1232.330322 2139.720459,1234.802124 2137.645020,1237.177856 
	C2139.031494,1237.451294 2140.705078,1237.498901 2142.135498,1238.112915 
	C2148.067383,1240.659180 2148.010986,1240.712891 2149.922119,1234.642090 
	C2150.407715,1233.099854 2151.144531,1231.636719 2151.766113,1230.137451 
	C2151.604004,1230.104736 2151.442871,1230.050049 2151.279785,1230.045410 
	C2151.116699,1230.040771 2150.951904,1230.086304 2150.239014,1230.253784 
M2027.784302,287.502380 
	C2027.784302,279.804291 2027.784302,272.106201 2027.784302,264.408112 
	C2027.242065,264.430084 2026.699707,264.452087 2026.157471,264.474091 
	C2026.157471,279.674408 2026.157471,294.874725 2026.157471,310.075073 
	C2026.689575,310.074921 2027.221802,310.074768 2027.753906,310.074615 
	C2027.753906,302.883575 2027.753906,295.692505 2027.784302,287.502380 
M2449.042236,284.531403 
	C2449.042236,280.210175 2449.042236,275.888977 2449.042236,271.175751 
	C2448.601562,271.201782 2447.124756,271.289032 2446.883789,271.303284 
	C2446.883789,287.752869 2446.883789,303.581970 2446.883789,319.411041 
	C2447.604736,319.403198 2448.325439,319.395355 2449.046387,319.387482 
	C2449.046387,308.098083 2449.046387,296.808716 2449.042236,284.531403 
M2449.048096,363.497284 
	C2449.048096,357.899841 2449.048096,352.302368 2449.048096,346.704926 
	C2448.667969,346.724792 2448.287842,346.744659 2447.907715,346.764526 
	C2447.907715,361.777618 2447.907715,376.790710 2447.907715,391.803802 
	C2448.287354,391.799744 2448.667236,391.795685 2449.046875,391.791656 
	C2449.046875,382.692535 2449.046875,373.593445 2449.048096,363.497284 
M1797.987549,516.473206 
	C1798.673584,504.197174 1798.631104,491.948517 1796.582031,479.774292 
	C1796.582031,494.897491 1796.582031,510.020721 1796.582031,525.143921 
	C1797.050049,525.148621 1797.517944,525.153381 1797.985962,525.158081 
	C1797.985962,522.582153 1797.985962,520.006226 1797.987549,516.473206 
M2435.994385,843.376953 
	C2444.732666,839.581055 2453.470947,835.785156 2462.209229,831.989197 
	C2461.918213,831.320312 2461.627197,830.651489 2461.336182,829.982605 
	C2448.678711,835.504089 2436.021240,841.025513 2423.363525,846.546997 
	C2423.579346,847.125366 2423.794922,847.703674 2424.010498,848.281982 
	C2427.775391,846.764099 2431.540283,845.246155 2435.994385,843.376953 
M1563.989990,459.012634 
	C1564.142578,459.850494 1564.295166,460.688385 1564.447754,461.526245 
	C1564.945801,461.485229 1565.443848,461.444214 1565.942017,461.403198 
	C1565.307007,446.487396 1566.886108,431.526672 1563.963379,416.655457 
	C1563.963379,430.473480 1563.963379,444.291504 1563.989990,459.012634 
M2450.840820,787.981934 
	C2450.840820,800.099609 2450.840820,812.217285 2450.840820,824.334961 
	C2451.355713,824.324707 2451.870850,824.314392 2452.385742,824.304077 
	C2452.385742,809.361938 2452.385742,794.419739 2452.385742,779.477539 
	C2451.783203,779.454163 2451.180420,779.430786 2450.577881,779.407410 
	C2450.577881,781.978394 2450.577881,784.549377 2450.840820,787.981934 
M1563.013916,297.500488 
	C1563.013916,303.860687 1563.013916,310.220917 1563.013916,316.581116 
	C1565.879272,308.191559 1565.291260,274.377594 1562.999634,271.606964 
	C1562.999634,279.930054 1562.999634,288.215607 1563.013916,297.500488 
M825.742798,234.558197 
	C810.713440,234.558197 795.684143,234.558197 779.739136,234.558197 
	C780.839172,236.233246 781.001099,236.699142 781.166443,236.700317 
	C795.758240,236.805679 810.350220,236.892334 824.942139,236.868759 
	C825.401001,236.868027 825.858032,235.705002 825.742798,234.558197 
M2026.950195,426.520142 
	C2026.950195,435.773834 2026.950195,445.027557 2026.950195,454.281250 
	C2027.396851,454.265076 2027.843628,454.248901 2028.290283,454.232727 
	C2028.290283,439.316833 2028.290283,424.400909 2028.290283,409.485016 
	C2027.841797,409.481903 2027.393311,409.478821 2026.944824,409.475708 
	C2026.944824,414.827179 2026.944824,420.178619 2026.950195,426.520142 
M2449.922607,421.959961 
	C2449.750488,420.858368 2449.578369,419.756744 2449.406006,418.655151 
	C2449.129883,418.700348 2448.853760,418.745575 2448.577637,418.790802 
	C2448.577637,433.931213 2448.577637,449.071625 2448.577637,464.212036 
	C2449.030273,464.213348 2449.483154,464.214661 2449.935791,464.215973 
	C2449.935791,450.428558 2449.935791,436.641144 2449.922607,421.959961 
M1564.962524,348.805725 
	C1564.808228,347.360748 1564.653809,345.915802 1564.499512,344.470825 
	C1564.203247,344.522339 1563.907104,344.573822 1563.610840,344.625336 
	C1563.610840,359.535431 1563.610840,374.445557 1563.610840,389.355682 
	C1564.061035,389.354218 1564.511353,389.352722 1564.961548,389.351257 
	C1564.961548,376.147461 1564.961548,362.943634 1564.962524,348.805725 
M2024.918823,205.512924 
	C2024.918823,216.136948 2024.918823,226.760986 2024.918823,237.385010 
	C2025.366699,237.369324 2025.814575,237.353638 2026.262451,237.337952 
	C2026.262451,222.440750 2026.262451,207.543549 2026.262451,192.646347 
	C2025.812622,192.649826 2025.362915,192.653320 2024.913086,192.656799 
	C2024.913086,196.610748 2024.913086,200.564682 2024.918823,205.512924 
M300.499847,238.957993 
	C292.581177,238.957993 284.662537,238.957993 276.743866,238.957993 
	C276.759338,239.382690 276.774811,239.807373 276.790283,240.232071 
	C291.634583,240.232071 306.478882,240.232071 321.323181,240.232071 
	C321.321014,239.812149 321.318878,239.392212 321.316711,238.972290 
	C314.711090,238.972290 308.105469,238.972290 300.499847,238.957993 
M2028.019165,350.507172 
	C2028.019165,345.913666 2028.019165,341.320129 2028.019165,336.726624 
	C2027.553833,336.743225 2027.088379,336.759827 2026.623047,336.776459 
	C2026.623047,351.662598 2026.623047,366.548767 2026.623047,381.434906 
	C2027.081421,381.436493 2027.539673,381.438080 2027.998047,381.439667 
	C2027.998047,371.461517 2027.998047,361.483337 2028.019165,350.507172 
M1563.852417,216.503387 
	C1563.832886,210.968811 1563.813232,205.434219 1563.793701,199.899643 
	C1563.341309,199.936996 1562.888916,199.974350 1562.436523,200.011688 
	C1562.436523,214.800751 1562.436523,229.589828 1562.436523,244.378891 
	C1562.908569,244.387634 1563.380615,244.396393 1563.852661,244.405151 
	C1563.852661,235.437546 1563.852661,226.469940 1563.852417,216.503387 
M525.468750,236.954468 
	C520.137695,236.970444 514.806641,236.992569 509.475586,237.000595 
	C504.180847,237.008575 498.886078,237.002365 493.591339,237.002365 
	C493.606873,237.421432 493.622406,237.840485 493.637939,238.259552 
	C507.866974,238.259552 522.096008,238.259552 536.325012,238.259552 
	C536.322754,237.824402 536.320496,237.389252 536.318237,236.954102 
	C533.030762,236.954102 529.743225,236.954102 525.468750,236.954468 
M762.839539,1010.834900 
	C762.872620,1001.991882 762.419128,993.201477 761.251892,984.488281 
	C760.814636,984.538757 760.377441,984.589294 759.940186,984.639771 
	C759.940186,994.206055 759.940186,1003.772278 759.940186,1013.338562 
	C760.326477,1013.431213 760.712769,1013.523926 761.099060,1013.616638 
	C761.657043,1012.967773 762.215088,1012.318909 762.839539,1010.834900 
M1104.991211,988.898926 
	C1104.998169,992.388489 1104.933472,995.880127 1105.033447,999.367004 
	C1105.123901,1002.520569 1105.394287,1005.669006 1105.585327,1008.819702 
	C1106.364502,1008.758057 1107.143555,1008.696411 1107.922729,1008.634766 
	C1107.312866,999.267578 1108.927734,989.818726 1106.773926,980.508240 
	C1106.175049,980.515076 1105.576294,980.521851 1104.977417,980.528687 
	C1104.977417,983.022400 1104.977417,985.516174 1104.991211,988.898926 
M2444.111328,102.910347 
	C2444.111328,97.067062 2444.111328,91.223770 2444.111328,84.226639 
	C2441.639893,85.972290 2440.485596,86.787621 2439.154297,87.727928 
	C2443.269531,89.687500 2443.454590,102.204269 2439.316895,107.054832 
	C2442.166992,107.054832 2444.502930,107.054832 2447.705811,107.054832 
	C2446.011230,105.464989 2445.102539,104.612228 2444.111328,102.910347 
M2122.210449,1238.272705 
	C2123.286377,1236.744263 2124.362305,1235.215820 2126.041016,1232.831543 
	C2122.108643,1232.831543 2120.063477,1232.831543 2117.906738,1232.831543 
	C2117.444336,1235.996704 2116.831055,1238.884155 2122.210449,1238.272705 
M1123.737915,546.012878 
	C1123.623413,546.133484 1123.479736,546.238098 1123.402832,546.379089 
	C1123.326904,546.518616 1123.318726,546.694824 1123.280884,546.854980 
	C1125.220947,546.782104 1127.161011,546.709290 1129.101074,546.636475 
	C1128.887085,544.532715 1128.673218,542.428955 1128.459229,540.325195 
	C1127.858765,540.244202 1127.258301,540.163269 1126.657837,540.082336 
	C1125.736206,541.871582 1124.814453,543.660828 1123.737915,546.012878 
M2131.450195,1237.158936 
	C2131.781494,1238.182495 2132.112793,1239.205933 2132.443848,1240.229492 
	C2132.772461,1240.119629 2133.101074,1240.009644 2133.429688,1239.899780 
	C2133.429688,1236.648682 2133.429688,1233.397583 2133.429688,1229.174194 
	C2131.478027,1230.884888 2130.312012,1231.906738 2129.300293,1232.793579 
	C2130.152588,1234.274536 2130.769043,1235.345703 2131.450195,1237.158936 
M2288.232910,270.392029 
	C2287.600342,270.225800 2286.968018,270.059570 2286.335693,269.893341 
	C2286.373291,270.671265 2286.242676,272.060730 2286.476807,272.125183 
	C2287.737793,272.472534 2289.089355,272.490784 2290.409668,272.622589 
	C2290.435059,272.283447 2290.460693,271.944305 2290.486328,271.605164 
	C2289.914307,271.339020 2289.342529,271.072876 2288.232910,270.392029 
M2508.506836,272.921417 
	C2509.171143,272.725342 2509.835449,272.529236 2510.499756,272.333160 
	C2510.396729,271.920502 2510.293457,271.507843 2510.190430,271.095215 
	C2509.000732,271.473145 2507.811035,271.851105 2506.621338,272.229034 
	C2507.019287,272.451569 2507.417480,272.674133 2508.506836,272.921417 
M2314.535645,272.281006 
	C2314.278809,272.012817 2314.024902,271.741760 2313.752197,271.490784 
	C2313.743896,271.483307 2313.541504,271.687073 2313.429443,271.792450 
	C2313.686279,272.060547 2313.943115,272.328644 2314.535645,272.281006 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M470.454865,1053.953979 
	C471.334229,1053.960693 472.213623,1053.967407 473.513123,1054.348999 
	C474.974762,1055.105225 476.012451,1055.804321 477.058380,1055.816895 
	C484.501129,1055.906006 491.945892,1055.892578 499.389465,1055.838623 
	C508.515839,1055.772461 508.503418,1055.741943 508.812256,1064.574951 
	C508.864105,1066.057861 509.072906,1067.535400 508.894836,1069.265137 
	C508.669373,1073.303711 508.803345,1077.091919 508.840118,1080.881226 
	C509.202057,1118.160278 509.527161,1155.439697 509.904236,1192.718506 
	C510.242401,1226.145996 510.632690,1259.572876 511.000000,1293.000000 
	C342.975098,1293.000000 174.950180,1293.000000 6.164369,1292.672119 
	C4.689475,1231.121338 3.936368,1169.898804 3.287882,1108.675293 
	C3.117028,1092.544922 3.269509,1076.411011 3.723713,1060.275513 
	C19.790573,1060.191895 35.407234,1060.111572 51.421074,1060.100830 
	C66.949837,1059.969360 82.081429,1059.768188 97.653946,1059.622803 
	C102.722664,1059.550171 107.349823,1059.343140 111.978340,1059.306274 
	C132.644272,1059.141846 153.310593,1059.025879 174.441055,1058.972900 
	C203.860504,1058.733398 232.815536,1058.402588 261.770844,1058.096191 
	C297.879944,1057.714111 333.989441,1057.372192 370.098389,1056.974976 
	C399.055389,1056.656494 428.011780,1056.281006 457.431030,1055.997314 
	C460.845642,1056.074829 463.819794,1056.299927 466.739685,1055.998779 
	C468.034607,1055.865234 469.219818,1054.668213 470.454865,1053.953979 
M49.003345,1247.003296 
	C49.003345,1247.003296 49.009117,1246.965942 48.367126,1246.894409 
	C48.233231,1247.266846 48.099331,1247.639404 48.002838,1248.003052 
	C48.002838,1248.003052 48.002850,1247.964844 47.265141,1247.857910 
	C44.814945,1252.919678 49.498802,1253.260254 52.006252,1254.994507 
	C52.006252,1254.994507 51.968712,1254.976562 51.788837,1255.616577 
	C52.194668,1256.091553 52.600498,1256.566528 53.003746,1256.996582 
	C53.003746,1256.996582 52.959198,1256.990112 53.174767,1257.630249 
	C54.785183,1258.907959 56.395596,1260.185547 58.006016,1261.463257 
	C59.014683,1259.975952 60.023350,1258.488525 60.999107,1256.998657 
	C60.999107,1256.998657 61.003914,1257.031128 61.666935,1257.135864 
	C61.788914,1256.756470 61.910896,1256.377075 61.998241,1255.998413 
	C61.998241,1255.998413 62.000336,1256.032837 62.697170,1256.206177 
	C64.478310,1254.491089 66.541832,1252.967163 67.898369,1250.964355 
	C68.423866,1250.188599 67.349045,1248.329102 66.998497,1247.001221 
	C66.998497,1247.001221 67.036903,1246.999268 66.912666,1246.391602 
	C66.274521,1245.915039 65.636375,1245.438477 64.996872,1245.003784 
	C64.996872,1245.003784 65.037338,1245.015137 64.885963,1244.385498 
	C64.587814,1244.074951 64.289665,1243.764526 63.846100,1243.303589 
	C63.846100,1243.303589 63.551781,1243.005615 63.113899,1242.499390 
	C62.405567,1241.986084 61.697239,1241.472778 60.995705,1241.003418 
	C60.995705,1241.003418 61.039677,1241.008545 60.869011,1240.307617 
	C56.826580,1236.567261 53.849411,1237.812134 52.008236,1244.007446 
	C52.008236,1244.007446 52.022381,1243.966064 51.311710,1243.853882 
	C50.863075,1244.571411 50.414440,1245.288940 50.003258,1246.002808 
	C50.003258,1246.002808 50.009243,1245.965698 49.353027,1245.894653 
	C49.224007,1246.265991 49.094982,1246.637207 49.003345,1247.003296 
M66.000443,1154.283569 
	C66.227531,1154.106812 66.396202,1153.885498 67.147415,1153.288208 
	C67.432373,1152.585693 68.154343,1151.474243 67.936913,1151.241943 
	C64.458282,1147.526001 60.902210,1143.877197 57.206078,1140.379761 
	C56.917961,1140.107178 55.459835,1141.071045 54.049828,1141.716797 
	C53.670403,1142.117554 53.290981,1142.518433 52.392204,1143.201904 
	C52.185993,1143.738281 51.979782,1144.274780 51.129730,1145.007080 
	C49.453743,1146.602539 47.561035,1148.035889 46.194656,1149.862915 
	C45.527462,1150.755005 45.229042,1152.887573 45.811367,1153.589600 
	C49.030190,1157.469360 52.571819,1161.081299 56.455296,1165.273438 
	C59.242493,1161.959106 61.236301,1159.588257 63.708893,1156.908691 
	C64.026260,1156.441284 64.343628,1155.973755 65.066040,1155.263916 
	C65.350533,1155.078369 65.544533,1154.823608 66.000443,1154.283569 
M60.284107,1212.029907 
	C60.113052,1211.790405 59.892624,1211.611938 59.305180,1210.866821 
	C59.391556,1209.395874 58.916626,1207.484375 59.664021,1206.532349 
	C62.500751,1202.919189 65.732925,1199.616333 68.662071,1196.369507 
	C64.560051,1192.214600 60.666485,1188.270752 56.420147,1183.969604 
	C52.636776,1187.927246 48.811676,1191.928589 44.811501,1196.113037 
	C48.864956,1200.496582 52.552662,1204.484497 56.581367,1208.841309 
	C53.314846,1212.275391 50.184940,1215.662476 46.941994,1218.937744 
	C45.089630,1220.808350 44.880085,1222.266968 47.795990,1223.858154 
	C48.006058,1224.110229 48.256702,1224.311768 48.796337,1224.994385 
	C49.417351,1225.435425 50.038361,1225.876587 50.957314,1226.778076 
	C51.288040,1226.870361 51.618763,1226.962646 51.823895,1227.781860 
	C53.555111,1230.306396 54.802876,1234.948975 58.838387,1232.056641 
	C62.879425,1229.160278 65.900787,1224.841431 69.450195,1221.059570 
	C66.620125,1218.265259 64.381691,1216.055054 61.884369,1213.345093 
	C61.425240,1213.027100 60.966114,1212.709229 60.284107,1212.029907 
M455.441254,1161.060059 
	C455.441254,1161.060059 455.793762,1160.985596 455.741760,1161.825806 
	C457.488983,1161.831787 459.236206,1161.837769 460.983429,1161.843750 
	C460.787048,1159.912720 460.590637,1157.981689 460.394226,1156.050659 
	C458.607300,1156.374268 456.820374,1156.697754 454.566406,1156.834961 
	C454.155731,1156.548584 453.745056,1156.262085 452.830322,1155.528442 
	C450.895325,1155.930908 448.521240,1157.247803 447.105804,1156.568237 
	C442.428772,1154.322632 434.322144,1154.506714 430.378387,1157.667114 
	C426.613403,1154.359985 423.986603,1154.475220 421.365753,1157.773804 
	C420.382477,1153.581055 416.982880,1156.582642 414.842163,1155.655151 
	C411.647339,1154.270752 409.407684,1155.483154 408.458466,1158.649536 
	C407.635468,1157.593018 406.812439,1156.536621 405.618286,1155.003662 
	C404.279266,1157.891479 403.293854,1160.016602 402.644745,1161.416504 
	C405.393158,1161.984253 407.701080,1162.461060 410.667969,1163.375122 
	C414.576080,1162.800659 419.448822,1166.111938 422.765686,1160.820312 
	C423.338379,1161.326294 423.911072,1161.832275 424.673096,1162.505615 
	C425.288330,1160.812378 425.774261,1159.475098 426.560059,1157.312500 
	C427.274475,1159.609863 427.693542,1160.957520 428.021667,1162.012695 
	C429.298523,1161.545044 430.901306,1160.674561 431.102905,1160.926392 
	C434.545898,1165.224731 438.526672,1162.738037 442.264008,1162.064819 
	C442.676361,1160.543945 443.061768,1159.122559 443.447174,1157.701050 
	C443.788788,1157.717285 444.130402,1157.733521 444.472046,1157.749878 
	C445.013641,1159.195190 445.555206,1160.640625 446.092926,1162.075562 
	C451.381165,1163.663696 451.381165,1163.663696 453.062988,1161.024536 
	C453.062988,1161.024536 453.016632,1161.058838 453.625092,1161.539307 
	C454.110657,1161.371460 454.596222,1161.203735 455.441254,1161.060059 
M142.839798,1120.218994 
	C143.572021,1120.421387 144.304245,1120.623901 145.351349,1121.140015 
	C145.910889,1121.129028 146.470428,1121.118042 147.959869,1121.071167 
	C147.990051,1120.373169 148.020233,1119.675049 147.844452,1118.479980 
	C147.800156,1118.306152 147.755875,1118.132324 148.146378,1117.396851 
	C149.141418,1116.332275 150.136444,1115.267700 151.131470,1114.203125 
	C150.908890,1113.860107 150.686310,1113.517212 150.463730,1113.174194 
	C148.992813,1113.476074 147.521896,1113.777954 145.349503,1113.941284 
	C140.961014,1114.239502 136.576035,1114.671265 132.182739,1114.786987 
	C129.432281,1114.859375 126.642937,1114.632568 123.918198,1114.224854 
	C120.055984,1113.646973 116.089966,1112.100952 114.368576,1117.690918 
	C114.113327,1118.519653 112.184540,1118.833130 110.868111,1119.462402 
	C111.738243,1117.338013 112.398064,1115.727051 113.343307,1113.419189 
	C108.553604,1113.708862 104.850342,1113.932861 100.303665,1114.060425 
	C98.203812,1113.749878 96.075920,1113.101440 94.016045,1113.272461 
	C93.002449,1113.356445 92.114738,1114.957031 91.170074,1115.871338 
	C92.038246,1116.620728 92.797661,1117.629272 93.803246,1118.051025 
	C94.955711,1118.534546 97.161064,1118.104980 97.464714,1118.750977 
	C99.198296,1122.439331 101.572983,1121.047241 104.676498,1120.333374 
	C109.254242,1122.638306 113.567551,1123.283813 117.048752,1117.758911 
	C117.492119,1122.030273 119.359894,1122.547119 122.483398,1121.005127 
	C124.323593,1120.096802 127.505409,1118.517944 128.174881,1119.177734 
	C132.422760,1123.364014 136.297043,1120.553711 140.850189,1119.353638 
	C141.378159,1119.502930 141.906128,1119.652100 142.839798,1120.218994 
M138.377121,1215.814331 
	C138.377121,1215.814331 138.055283,1215.941040 137.499908,1215.541504 
	C134.995102,1214.524414 132.371658,1212.978882 130.921234,1217.807739 
	C131.049957,1218.603882 131.178696,1219.399902 131.091705,1220.841675 
	C132.611755,1224.162231 134.383118,1222.396729 136.720505,1220.869019 
	C139.306427,1221.034912 141.750854,1225.500977 144.270172,1221.736328 
	C142.777740,1220.783203 141.410904,1219.910278 140.617950,1218.812622 
	C141.624283,1217.659424 142.630600,1216.506226 144.508072,1214.354614 
	C141.741486,1215.012939 140.227402,1215.373169 138.377121,1215.814331 
M238.584961,1197.861206 
	C240.374710,1197.534058 242.164459,1197.206909 244.636063,1197.111328 
	C249.140671,1200.119995 254.601868,1199.352539 256.231628,1195.361816 
	C254.903198,1195.827759 253.661896,1196.263306 251.229355,1197.116577 
	C252.961746,1194.543091 253.981689,1193.027832 255.380798,1190.949341 
	C252.708664,1191.364746 250.887390,1191.647827 249.066116,1191.930908 
	C249.269913,1192.127319 249.473694,1192.323730 249.677490,1192.520142 
	C248.487320,1193.338135 247.297165,1194.156128 246.273727,1194.333984 
	C246.241653,1193.896484 246.209579,1193.458862 246.533829,1192.610840 
	C245.686844,1192.282104 244.839844,1191.953369 243.289780,1191.236206 
	C241.383911,1191.492798 238.294357,1189.618896 238.359299,1194.472656 
	C238.430038,1195.346680 238.500778,1196.220825 238.584961,1197.861206 
M413.467316,1189.695190 
	C413.467316,1189.695190 413.082153,1189.825684 412.268005,1189.791138 
	C408.829041,1190.224731 405.390076,1190.658325 401.748962,1190.799927 
	C401.667938,1190.617920 401.586914,1190.436035 401.505829,1190.254028 
	C401.671844,1190.513672 401.837860,1190.773438 402.438202,1191.614502 
	C403.437897,1195.880005 405.373077,1197.710571 409.381104,1194.327515 
	C409.862823,1193.920898 410.502777,1193.701782 411.128143,1194.281128 
	C412.040588,1194.989990 412.953033,1195.698975 413.865509,1196.407837 
	C414.237030,1195.600098 414.608582,1194.792358 415.712158,1193.820923 
	C416.409546,1193.589233 417.106934,1193.357544 418.488007,1193.463623 
	C418.975403,1194.410034 419.462799,1195.356445 419.564697,1195.554321 
	C421.851227,1194.720459 423.791016,1194.013184 425.754913,1193.882446 
	C425.825562,1194.260620 425.896210,1194.638672 426.010803,1195.560303 
	C426.302399,1195.354004 426.593994,1195.147827 427.632721,1194.652344 
	C430.763062,1195.111694 433.893372,1195.571045 437.672241,1195.931274 
	C438.437561,1195.765625 439.202850,1195.599976 440.723877,1195.270752 
	C438.747742,1192.952148 437.205444,1189.464722 435.399231,1189.322266 
	C428.624390,1188.787720 421.763367,1189.345703 414.383087,1189.556763 
	C414.211456,1189.625732 414.039825,1189.694580 413.467316,1189.695190 
M339.718536,1192.711426 
	C339.728851,1187.526855 336.329315,1190.618286 333.448914,1190.283936 
	C327.587799,1190.444702 327.086609,1190.724854 326.444763,1194.174683 
	C327.942017,1194.407837 330.381866,1194.167236 330.778046,1194.954834 
	C332.707550,1198.790039 334.851532,1196.990234 337.677368,1195.930786 
	C338.085480,1195.980225 338.493591,1196.029541 339.392090,1196.650757 
	C343.954651,1198.994141 348.013947,1198.875488 351.005035,1193.820312 
	C352.508636,1198.203857 354.452881,1198.691040 359.260040,1196.485962 
	C360.406647,1195.959961 362.490570,1195.229492 362.894653,1195.689697 
	C366.017151,1199.246094 369.661865,1196.769165 373.010895,1196.760986 
	C374.354553,1196.757812 375.695557,1195.642212 377.002747,1195.001099 
	C377.002747,1195.001099 376.969910,1194.967163 377.115143,1195.707642 
	C380.792969,1198.362793 382.653687,1198.434692 386.268188,1195.778687 
	C384.892029,1195.279419 383.744812,1194.863281 383.541077,1194.789429 
	C384.320984,1193.286011 385.178650,1191.632935 385.890076,1190.261597 
	C378.891144,1190.261597 372.908264,1190.067139 366.945770,1190.331909 
	C363.151886,1190.500488 359.508270,1191.437988 355.633148,1189.963989 
	C354.019135,1189.349854 350.274323,1190.149658 349.891388,1191.188599 
	C347.900421,1196.590820 344.333984,1196.488892 339.584808,1194.632324 
	C339.409302,1194.101196 339.233795,1193.570190 339.000977,1193.003784 
	C339.000977,1193.003784 339.037140,1193.060669 339.718536,1192.711426 
M406.487366,1122.800293 
	C406.159698,1122.660400 405.832031,1122.520508 404.818359,1122.059814 
	C403.569153,1122.117554 402.319916,1122.175415 400.142365,1122.283569 
	C399.160858,1122.258057 398.171234,1122.140625 397.199493,1122.226074 
	C395.195618,1122.402710 392.239502,1121.996582 391.445251,1123.101929 
	C390.441803,1124.498657 391.202972,1127.163086 391.202972,1130.340332 
	C393.898590,1127.286499 395.312073,1123.018311 398.085846,1128.495117 
	C398.192688,1128.706177 400.774811,1127.663574 402.848145,1127.363892 
	C405.969635,1129.223145 409.106171,1130.923706 412.761719,1128.647827 
	C411.346710,1127.649902 410.176971,1126.824951 409.758728,1126.078369 
	C413.445923,1126.013184 413.430450,1124.380127 411.882324,1122.246216 
	C410.034149,1122.538940 408.505920,1122.780884 406.487366,1122.800293 
M442.416595,1213.767822 
	C442.935852,1214.180786 443.455139,1214.593750 443.860260,1215.645264 
	C444.662109,1216.950439 445.237488,1219.090454 446.314240,1219.382324 
	C448.385742,1219.944336 450.793243,1219.731201 452.960083,1219.313599 
	C454.382812,1219.039551 455.627899,1217.843628 456.952545,1217.060547 
	C456.783661,1216.821655 456.614807,1216.582764 456.445923,1216.343872 
	C455.022278,1216.432495 453.598602,1216.520996 450.829681,1216.693359 
	C453.265106,1214.641113 454.613617,1213.504761 455.962158,1212.368408 
	C455.819427,1212.066772 455.676697,1211.765137 455.533997,1211.463623 
	C453.381958,1211.969238 451.229919,1212.474854 449.138733,1212.228638 
	C446.078796,1212.105591 443.018890,1211.982666 439.259735,1211.906860 
	C438.573486,1211.851318 437.887207,1211.795776 436.414734,1211.505737 
	C433.899231,1211.517456 431.305878,1211.164429 428.897552,1211.678467 
	C427.462433,1211.984619 425.754700,1213.426025 425.210724,1214.776611 
	C424.724640,1215.983398 425.667023,1217.765747 425.979156,1219.294067 
	C427.647125,1218.510010 429.315094,1217.725952 431.627258,1216.912476 
	C432.036346,1217.028687 432.445465,1217.145020 432.762665,1217.969849 
	C434.333679,1218.415771 435.904663,1218.861694 437.475647,1219.307617 
	C437.590790,1218.717529 437.705933,1218.127563 437.821075,1217.537598 
	C436.846436,1217.364136 435.871796,1217.190796 435.156311,1216.285767 
	C436.473969,1215.753296 437.791626,1215.220825 439.149261,1214.672241 
	C439.847290,1216.122925 440.401245,1217.274170 441.563477,1219.689819 
	C441.748657,1216.817871 441.840363,1215.395752 442.416595,1213.767822 
M164.081879,1120.495728 
	C164.387466,1120.330200 164.693069,1120.164795 165.511139,1119.489868 
	C167.332184,1119.692871 169.153214,1119.895996 171.473312,1120.412109 
	C173.276978,1120.205322 175.082306,1120.011841 176.883682,1119.786621 
	C177.987427,1119.648682 179.160843,1119.652344 180.167450,1119.251709 
	C181.624695,1118.671997 182.959229,1117.783691 184.760284,1116.801147 
	C185.091339,1121.770752 187.720657,1121.444092 190.963669,1120.418457 
	C192.418488,1119.958618 194.110229,1120.269775 195.692001,1120.185059 
	C198.178619,1120.052246 200.663132,1119.880615 203.148529,1119.725342 
	C202.837418,1117.414673 202.526306,1115.103882 202.302444,1113.441284 
	C191.266098,1113.264282 181.390961,1112.014404 171.208923,1115.299805 
	C171.211960,1115.243042 171.144714,1116.499146 170.551254,1117.193604 
	C169.519882,1115.879150 168.488525,1114.564819 167.147354,1112.855591 
	C166.223404,1114.473145 165.540161,1115.669189 164.311005,1116.472656 
	C162.874878,1113.453003 160.250259,1111.753540 157.566956,1113.759766 
	C156.179428,1114.797119 156.506073,1118.127319 156.058289,1120.421631 
	C158.036423,1120.291748 160.013947,1120.151123 161.993042,1120.037964 
	C162.646255,1120.000610 163.303558,1120.034912 163.976715,1120.026001 
	C163.976715,1120.026001 163.964310,1120.042480 164.081879,1120.495728 
M130.465408,1164.052246 
	C129.199280,1162.622437 127.933144,1161.192749 126.225349,1159.264282 
	C126.139336,1161.053711 126.066521,1162.568604 125.254555,1164.277954 
	C119.386681,1166.632690 123.221565,1160.734131 120.942726,1159.480957 
	C120.522980,1160.533081 120.113182,1161.560303 119.610634,1162.820068 
	C118.286003,1161.315674 117.163986,1160.041382 115.585701,1158.249023 
	C114.352005,1160.968140 113.389259,1163.089966 112.529243,1164.985474 
	C114.737976,1165.121582 117.051720,1164.558594 118.050217,1165.457153 
	C121.126564,1168.225586 123.583252,1167.273438 127.034798,1165.051025 
	C128.430527,1164.958618 129.826263,1164.866333 131.673019,1165.445679 
	C134.446869,1165.668213 137.363022,1166.507935 139.948120,1165.911865 
	C141.904144,1165.460938 144.630005,1163.372437 144.909073,1161.668213 
	C145.477448,1158.196655 142.171844,1158.934326 139.971695,1158.865112 
	C139.315002,1158.844482 138.654343,1158.949341 137.226776,1158.935913 
	C135.247772,1159.262085 133.268753,1159.588379 131.023560,1159.958496 
	C131.152924,1161.474731 131.272858,1162.880371 131.316559,1164.306396 
	C131.316559,1164.306396 131.251022,1164.350220 130.465408,1164.052246 
M396.652863,1194.767456 
	C396.728851,1193.290771 397.268402,1190.732788 396.797791,1190.531128 
	C395.089844,1189.798828 393.043304,1189.856323 391.033081,1189.604248 
	C391.033081,1192.742554 391.033081,1194.840454 391.033081,1198.431396 
	C393.230103,1196.940796 394.643250,1195.981934 396.002075,1194.999512 
	C396.002075,1194.999512 396.022583,1195.054688 396.652863,1194.767456 
M131.322617,1260.373169 
	C132.927017,1260.894043 134.531418,1261.414917 136.190704,1261.953735 
	C135.886246,1259.507568 135.623413,1257.395874 135.314667,1254.915283 
	C133.576050,1254.991577 131.809052,1255.069092 129.163422,1255.000122 
	C123.140366,1255.332031 117.123138,1254.858887 111.114380,1254.851196 
	C105.395142,1254.843994 99.674469,1255.971802 93.532654,1256.647095 
	C93.532654,1257.866821 93.532654,1260.039062 93.532654,1263.053223 
	C95.114937,1261.599365 96.106339,1260.688354 97.097748,1259.777344 
	C97.160393,1260.180176 97.223030,1260.583008 97.285675,1260.985718 
	C99.328438,1261.075073 101.371193,1261.164307 102.737762,1261.223999 
	C105.930786,1261.835449 108.602303,1262.347168 111.273827,1262.858765 
	C111.407288,1262.351929 111.540749,1261.845093 111.674210,1261.338135 
	C110.996727,1260.724854 110.319244,1260.111450 109.507156,1259.376221 
	C110.734375,1258.814575 111.855759,1258.301392 113.194992,1257.688477 
	C113.811279,1259.199219 114.310097,1260.421997 115.192345,1262.584717 
	C115.723755,1260.203003 116.016006,1258.893188 116.577164,1256.378296 
	C117.217781,1258.941406 117.560280,1260.311768 117.662094,1260.719116 
	C119.782341,1261.161377 121.139809,1261.567383 122.523972,1261.704956 
	C124.703796,1261.921753 126.901581,1261.957764 129.714157,1262.043335 
	C130.129318,1261.666626 130.544479,1261.290039 131.322617,1260.373169 
M102.741295,1250.971924 
	C104.338455,1250.971924 105.935608,1250.971924 108.105392,1250.971924 
	C107.927284,1248.407349 107.787247,1246.390869 107.582298,1243.439819 
	C106.152153,1245.979004 105.344551,1247.412964 104.536949,1248.846924 
	C103.516632,1247.247437 102.496323,1245.647949 101.059868,1243.396118 
	C100.600151,1245.124146 100.284752,1246.309570 99.430038,1247.060425 
	C98.542984,1245.858765 97.655930,1244.657227 96.363731,1242.906738 
	C95.096336,1245.892090 94.157570,1248.103271 93.559692,1249.511597 
	C96.738174,1250.006226 99.294525,1250.403931 102.741295,1250.971924 
M212.675781,1221.732788 
	C214.448517,1221.653442 216.221268,1221.574097 217.994003,1221.494873 
	C218.548004,1220.809570 219.102020,1220.124268 219.656021,1219.439087 
	C212.226364,1221.644775 216.527908,1215.536499 213.179153,1214.231934 
	C212.753662,1216.275757 212.372086,1218.108643 211.183960,1219.856934 
	C209.972321,1218.250854 208.760681,1216.644897 207.531067,1215.015137 
	C207.286377,1215.837280 206.989594,1216.834473 206.546143,1218.324341 
	C205.230301,1216.720093 204.237213,1215.509399 202.949722,1213.939697 
	C202.170242,1215.272461 201.619171,1216.214844 200.425400,1216.978271 
	C199.952179,1216.140015 199.478958,1215.301880 198.850113,1214.187988 
	C195.997574,1219.030640 189.704498,1209.838501 187.349472,1217.749023 
	C188.728485,1218.356934 190.005127,1218.919678 191.183014,1219.438843 
	C190.243073,1219.850342 189.199371,1220.307373 187.383759,1221.102417 
	C188.450668,1221.765015 188.843628,1222.191284 189.290237,1222.257812 
	C193.127213,1222.829834 196.596176,1218.717285 200.612823,1221.357910 
	C200.766983,1220.776855 200.921143,1220.195679 201.798920,1219.792969 
	C205.149277,1220.333374 208.499619,1220.873901 212.675781,1221.732788 
M351.313324,1215.704712 
	C350.401520,1213.106079 348.570190,1210.788574 346.013153,1212.602295 
	C344.597992,1213.605957 344.795776,1216.883667 344.362335,1218.728394 
	C347.501953,1217.861816 349.700043,1217.255005 351.566101,1217.271729 
	C351.432770,1217.570679 351.108459,1217.989136 351.205444,1218.143799 
	C351.414948,1218.478394 351.832611,1218.885010 352.178864,1218.898071 
	C354.089722,1218.970093 356.004730,1218.932373 357.918335,1218.932373 
	C357.987976,1219.301758 358.057648,1219.671265 358.127319,1220.040649 
	C359.337006,1218.957642 360.546692,1217.874634 361.790070,1216.761353 
	C361.214020,1215.722412 360.691467,1214.779907 360.168915,1213.837402 
	C360.025879,1214.259521 359.882843,1214.681763 359.739777,1215.103882 
	C357.838165,1214.516846 355.936523,1213.929810 353.632690,1213.218628 
	C353.322052,1213.805054 352.725372,1214.931519 351.313324,1215.704712 
M177.519257,1162.034668 
	C177.519257,1162.034668 177.946365,1161.947998 178.580139,1162.116089 
	C178.988708,1163.302612 179.397293,1164.489258 179.647842,1165.216797 
	C182.368286,1165.216797 184.905685,1165.216797 187.643478,1165.216797 
	C187.208389,1164.138184 186.097244,1162.779663 186.386292,1161.861938 
	C187.731552,1157.591187 184.969193,1157.877319 182.365051,1157.908936 
	C181.202286,1157.922974 180.041519,1158.103882 178.097992,1158.063477 
	C174.580078,1158.399048 171.062164,1158.734741 167.217346,1159.101562 
	C167.787811,1161.803223 168.151459,1163.525391 168.455444,1164.965088 
	C170.399078,1163.939087 172.136703,1163.021973 173.864777,1162.845337 
	C174.670883,1164.239624 175.476974,1165.634033 176.283081,1167.028320 
	C176.644012,1166.800415 177.004959,1166.572388 177.365891,1166.344482 
	C177.272354,1164.921021 177.178818,1163.497681 177.519257,1162.034668 
M329.424774,1156.822510 
	C328.274811,1157.566040 327.124847,1158.309692 325.662231,1158.436890 
	C324.726074,1157.837036 323.816345,1156.780029 322.848755,1156.724121 
	C320.106842,1156.565308 317.327637,1157.027100 314.588531,1156.850464 
	C311.438995,1156.647461 308.850403,1156.968628 306.780579,1159.849121 
	C305.938141,1161.021484 303.877563,1161.318481 302.371216,1162.013672 
	C302.041931,1161.483765 301.712616,1160.953735 301.383301,1160.423706 
	C302.867645,1159.775879 304.352020,1159.127930 305.836365,1158.479980 
	C305.212494,1157.780029 304.588593,1157.080078 303.964691,1156.380127 
	C302.407623,1157.741333 300.423828,1158.864502 299.475220,1160.565186 
	C299.008362,1161.402222 300.383728,1164.491699 301.050812,1164.533081 
	C304.751984,1164.761963 308.492035,1164.374756 312.217987,1164.183228 
	C314.545898,1164.063477 316.878357,1163.980103 319.198029,1163.764771 
	C321.795868,1163.523560 324.381622,1163.152100 327.309967,1163.454468 
	C328.196808,1163.643311 329.083649,1163.832153 330.508698,1164.304810 
	C332.324554,1163.513916 334.140411,1162.722900 336.568268,1162.301758 
	C339.498566,1165.674194 341.776825,1165.609741 345.743347,1161.956421 
	C349.557709,1166.072998 353.848846,1160.019653 358.262726,1162.195312 
	C357.016510,1160.146118 355.770294,1158.097046 354.582275,1156.143555 
	C352.593750,1158.165039 351.097046,1159.686523 349.600372,1161.208008 
	C349.192566,1159.902832 348.784790,1158.597534 348.280914,1156.984741 
	C346.963837,1156.984741 345.481812,1156.984741 343.409271,1156.478516 
	C341.264526,1156.795044 339.119781,1157.111572 336.291107,1157.202026 
	C334.202454,1157.142578 332.113770,1157.083130 329.424774,1156.822510 
M128.115326,1198.118774 
	C128.115326,1198.118774 128.191971,1198.141602 128.982819,1198.199097 
	C131.338150,1198.829468 133.693481,1199.459839 136.048813,1200.090210 
	C136.200745,1199.550415 136.352661,1199.010742 136.504593,1198.471069 
	C135.468124,1198.109985 134.431656,1197.748901 133.892120,1197.561035 
	C134.874619,1195.921265 135.828598,1194.328979 137.385208,1191.730957 
	C131.948166,1192.104980 128.108246,1192.369141 123.450935,1192.418335 
	C119.654884,1190.577148 118.322960,1194.426880 115.434456,1195.076050 
	C113.822601,1194.144287 112.269279,1192.563354 110.587105,1192.411743 
	C106.705147,1192.061646 102.757957,1192.527588 98.842140,1192.440552 
	C91.820541,1192.284668 91.793243,1192.253174 93.622406,1199.252197 
	C95.768723,1197.519287 96.779434,1195.378418 100.527557,1198.682617 
	C102.836830,1200.718506 110.414871,1202.924072 113.844749,1196.355957 
	C114.130463,1196.511475 114.416176,1196.666992 114.775513,1197.459717 
	C116.618607,1200.499756 116.618607,1200.499756 119.807510,1196.476685 
	C121.287453,1197.606934 122.706779,1198.690796 124.805946,1199.998901 
	C125.887657,1199.356323 126.969368,1198.713745 128.115326,1198.118774 
M267.323853,1148.058105 
	C266.949097,1147.473145 266.574341,1146.888062 266.199524,1146.303101 
	C265.305695,1147.403564 264.411865,1148.504028 263.502716,1149.623413 
	C262.495636,1148.784912 261.607483,1148.045410 260.719330,1147.305908 
	C260.456116,1147.512817 260.192902,1147.719604 259.929688,1147.926514 
	C261.058929,1149.882202 262.188171,1151.837891 263.693176,1154.444336 
	C265.168640,1152.013916 266.159607,1150.381592 267.974792,1149.157471 
	C268.059937,1150.284058 268.145081,1151.410767 268.228241,1152.510986 
	C271.973175,1152.153076 275.613220,1151.805054 279.472992,1151.436157 
	C278.380859,1149.782715 277.529785,1148.494141 276.652252,1147.165527 
	C273.729431,1153.108276 272.737305,1145.849121 270.819153,1146.742676 
	C269.565979,1147.425049 268.312805,1148.107544 267.323853,1148.058105 
M463.884277,1218.634033 
	C466.710327,1217.912109 469.536377,1217.190063 472.917633,1216.692627 
	C474.839722,1217.441895 476.761810,1218.191284 479.738037,1219.351685 
	C477.844055,1217.058228 475.980438,1215.790894 476.120117,1214.808594 
	C476.961914,1208.889648 473.607880,1211.150024 470.250885,1211.973389 
	C468.511566,1212.004272 466.772217,1212.035278 464.308838,1211.772949 
	C462.028961,1211.652466 459.749115,1211.531860 457.469238,1211.411377 
	C457.355286,1211.957275 457.241364,1212.503174 457.127441,1213.049072 
	C460.202545,1213.683960 457.117462,1221.009033 463.884277,1218.634033 
M335.300598,1218.375610 
	C336.918823,1218.851929 338.537079,1219.328247 340.185089,1219.813354 
	C339.910034,1217.404663 339.671143,1215.312988 339.377533,1212.742065 
	C337.724762,1212.742065 336.014832,1212.742065 333.424500,1212.465576 
	C326.823822,1211.625732 326.823822,1211.625732 326.931824,1220.566895 
	C329.709869,1219.957275 332.273590,1219.394653 335.300598,1218.375610 
M242.403412,1129.396729 
	C242.696167,1129.823120 242.988922,1130.249634 243.281708,1130.676025 
	C243.578979,1130.156616 243.876236,1129.637207 244.835358,1129.327881 
	C248.489288,1129.585693 252.143219,1129.843506 256.779633,1130.170654 
	C254.940842,1127.496826 253.669647,1125.648315 252.319580,1123.685181 
	C250.650024,1125.959106 249.449051,1127.594849 247.655212,1130.037964 
	C247.143387,1123.757568 247.143387,1123.757568 242.104950,1123.967407 
	C237.477753,1124.501587 232.852661,1125.055786 228.220566,1125.543457 
	C227.670334,1125.601318 227.088715,1125.229980 226.510071,1125.170654 
	C222.478882,1124.757202 218.447861,1124.203491 214.408615,1124.127441 
	C213.620834,1124.112427 212.331497,1125.897217 212.108398,1127.018066 
	C211.830887,1128.412231 212.420563,1129.979126 212.635712,1131.471436 
	C212.931534,1131.447021 213.227356,1131.422607 213.523178,1131.398193 
	C213.937134,1130.251709 214.351089,1129.105347 214.995453,1127.320801 
	C216.373611,1128.459229 217.225708,1129.403809 218.280182,1129.974609 
	C219.672272,1130.728271 221.188354,1131.551025 222.713089,1131.697021 
	C225.546738,1131.968384 228.426422,1131.819946 231.284546,1131.749512 
	C232.781738,1131.712524 234.273895,1131.470825 236.814484,1131.217285 
	C234.954575,1129.810913 234.127350,1129.185303 232.985825,1128.322144 
	C234.770889,1127.732788 236.116226,1127.288574 237.577652,1126.806152 
	C238.088364,1128.257812 238.495773,1129.415771 238.689209,1129.965698 
	C240.068024,1129.072754 241.264496,1128.297974 242.446915,1128.026855 
	C242.547150,1128.348389 242.647385,1128.670044 242.403412,1129.396729 
M166.446487,1127.900513 
	C165.615997,1126.777588 164.785492,1125.654663 163.861923,1124.405884 
	C162.719559,1126.105591 161.830078,1127.428955 160.638657,1129.201660 
	C160.134125,1127.663574 159.814270,1126.688477 159.636887,1126.147705 
	C157.242996,1126.147705 155.257767,1126.147705 153.162567,1126.147705 
	C153.070221,1128.081665 152.979919,1129.973145 152.843353,1132.833130 
	C154.636475,1131.272461 155.672211,1130.370972 156.823410,1129.368896 
	C158.004532,1130.395752 159.076736,1131.327881 159.035172,1131.291748 
	C162.579178,1131.291748 164.781143,1131.291748 167.404984,1131.809570 
	C171.276978,1132.740723 175.106689,1133.173584 178.501099,1131.776733 
	C176.774734,1129.059570 175.529083,1127.098999 173.806610,1124.387939 
	C173.806610,1126.689819 173.806610,1128.099487 173.806610,1130.375244 
	C172.227646,1129.760376 170.685486,1129.636719 170.214462,1128.861938 
	C169.551056,1127.770386 169.681320,1126.196411 169.376068,1124.199829 
	C168.281876,1126.048340 167.588776,1127.219360 166.446487,1127.900513 
M320.810425,1123.274292 
	C318.708649,1123.150269 316.608307,1122.963501 314.504791,1122.914185 
	C308.366791,1122.770264 308.244812,1122.966431 309.939362,1129.230957 
	C311.578796,1128.547119 313.237640,1127.855225 315.115753,1127.884888 
	C315.812622,1131.680786 318.486908,1131.229126 321.188324,1130.707520 
	C323.598267,1130.242065 326.006409,1129.767578 328.536407,1129.273438 
	C328.314392,1127.449829 328.067169,1125.419189 327.753845,1122.845703 
	C325.440247,1123.264160 323.414551,1123.630615 320.810425,1123.274292 
M188.805267,1130.941772 
	C193.188080,1131.195557 197.890869,1134.277954 202.555664,1129.309692 
	C200.233307,1129.490845 198.766510,1129.605347 195.842041,1129.833618 
	C198.253555,1127.726807 199.585205,1126.563477 201.880951,1124.557861 
	C198.680634,1124.817871 196.729385,1124.976318 194.578995,1125.151123 
	C194.279968,1126.213745 193.925339,1127.473999 193.682846,1128.335693 
	C192.350174,1127.234985 191.157593,1126.250000 189.361038,1124.854492 
	C187.495178,1124.854492 185.629318,1124.854492 183.817474,1124.854492 
	C183.817474,1127.636353 183.817474,1129.747803 183.817474,1132.603271 
	C185.476425,1132.031738 186.767212,1131.587036 188.805267,1130.941772 
M306.702118,1216.384521 
	C305.772247,1215.274902 304.842377,1214.165283 303.773376,1212.889771 
	C302.601044,1214.360474 301.615204,1215.597168 301.468170,1215.781616 
	C298.705292,1215.080322 296.712830,1214.574707 294.505951,1214.014526 
	C294.505951,1215.986938 294.505951,1217.562256 294.505951,1218.473877 
	C299.259308,1218.974609 303.608185,1219.432739 308.615692,1219.893433 
	C310.035370,1219.952393 311.642456,1219.602051 312.838318,1220.149658 
	C315.966003,1221.582153 318.361298,1220.730591 320.280640,1218.266357 
	C321.087372,1217.230591 321.369080,1215.785767 321.889496,1214.526978 
	C319.617188,1213.987793 317.351349,1213.086914 315.070129,1213.046143 
	C313.717651,1213.021851 312.340881,1214.354126 310.551331,1214.521851 
	C309.857605,1214.066650 309.163849,1213.611450 307.664246,1212.627441 
	C308.158417,1214.713501 308.433258,1215.873779 307.857697,1217.073975 
	C307.576080,1217.038818 307.294464,1217.003784 306.702118,1216.384521 
M220.078659,1116.051880 
	C220.030930,1115.724609 220.046738,1115.137695 219.926163,1115.108154 
	C216.774658,1114.336914 213.632751,1113.378540 210.429535,1113.067993 
	C209.601196,1112.987549 207.779938,1114.995972 207.813690,1115.997681 
	C207.870895,1117.695801 209.122162,1120.828613 209.730042,1120.795044 
	C213.837021,1120.568359 217.906830,1119.668213 222.789230,1118.967651 
	C224.118469,1119.524414 225.435623,1120.507935 226.779510,1120.545776 
	C229.311707,1120.617554 232.279770,1120.829712 234.236649,1119.629883 
	C235.340607,1118.953003 234.790161,1115.578003 234.984970,1113.418335 
	C234.522568,1113.391357 234.060181,1113.364380 233.597794,1113.337402 
	C233.208862,1114.817871 232.819931,1116.298218 232.186020,1118.711060 
	C229.046143,1116.893311 226.371780,1115.345093 223.542297,1113.707031 
	C222.537796,1114.595215 221.325623,1115.666992 220.078659,1116.051880 
M106.544907,1098.629883 
	C107.450752,1098.657959 108.356590,1098.685913 109.834381,1099.030518 
	C112.560211,1098.466797 115.286041,1097.903076 118.793823,1097.177490 
	C119.136414,1097.336670 120.579453,1098.007202 122.372398,1099.287964 
	C123.990242,1099.175537 125.811577,1099.498657 127.182701,1098.858154 
	C129.865952,1097.604492 135.238953,1098.687378 134.313583,1093.907959 
	C133.511658,1089.766113 129.041367,1091.126099 125.078934,1091.153442 
	C121.861923,1091.728271 118.663605,1092.522339 115.421143,1092.798706 
	C113.356438,1092.974731 111.226273,1092.382690 108.295403,1092.006592 
	C107.472046,1092.066528 106.648697,1092.126465 105.228363,1092.261719 
	C104.263214,1092.335693 103.290016,1092.527466 102.334373,1092.462036 
	C99.034233,1092.236450 95.740219,1091.921265 92.443863,1091.640137 
	C92.545807,1093.531982 92.647751,1095.423828 92.724297,1096.844360 
	C94.399910,1096.428833 95.903481,1095.596313 97.227524,1095.821167 
	C100.209549,1096.327515 103.102699,1097.357422 106.544907,1098.629883 
M178.719406,1082.270386 
	C178.077728,1081.700806 177.487183,1080.730469 176.784256,1080.640869 
	C175.032196,1080.417358 173.226471,1080.659302 171.447266,1080.588623 
	C170.208145,1080.539429 168.977310,1080.282227 167.742767,1080.117798 
	C167.499252,1082.016357 166.987762,1083.937988 167.149109,1085.801514 
	C167.210419,1086.509766 168.941071,1087.744873 169.700546,1087.604126 
	C172.865616,1087.018188 175.819290,1084.655151 179.265274,1086.745483 
	C179.296509,1086.764404 179.942169,1085.770264 181.017380,1085.181396 
	C186.128387,1087.020142 191.245575,1088.728027 196.163132,1086.614990 
	C194.743393,1084.254395 193.559174,1082.285522 192.450409,1080.442017 
	C192.216339,1080.999268 189.818573,1081.091187 186.795563,1080.376709 
	C186.447739,1081.389771 186.088577,1082.435791 185.580734,1083.914795 
	C184.147568,1082.156250 183.045242,1080.803589 181.758362,1079.224487 
	C180.682114,1080.672241 179.824539,1081.825928 178.719406,1082.270386 
M369.477234,1243.806274 
	C368.845184,1242.906128 368.213165,1242.006104 367.313934,1240.725464 
	C366.240326,1242.254517 365.429108,1243.409912 364.417999,1244.849976 
	C363.989044,1243.685303 363.668732,1242.815674 363.557281,1242.513062 
	C361.519226,1242.513062 359.792389,1242.513062 357.688904,1242.513062 
	C357.877441,1244.039307 358.087250,1245.737549 358.237305,1246.952148 
	C362.754791,1246.952148 366.792145,1246.952148 371.032349,1246.952148 
	C370.603973,1245.980347 370.273865,1245.231567 370.832886,1244.406860 
	C371.917297,1245.778320 373.001709,1247.149902 374.244568,1248.721802 
	C375.747040,1246.988892 376.644623,1245.953735 377.953430,1244.444214 
	C378.811218,1249.531128 381.341858,1249.352539 385.302734,1247.181519 
	C383.509277,1246.486816 382.394592,1246.055054 382.047211,1245.920532 
	C383.027924,1244.311768 384.014526,1242.693359 385.001099,1241.075073 
	C384.468933,1240.494263 383.936768,1239.913452 383.404602,1239.332642 
	C380.235474,1240.706055 377.066376,1242.079346 373.351929,1243.688965 
	C373.352264,1243.689819 372.871063,1242.475952 372.267120,1240.952515 
	C370.985779,1242.903076 370.472870,1243.683716 369.477234,1243.806274 
M280.651123,1160.551758 
	C279.833649,1159.415894 279.016205,1158.280029 277.674286,1156.415405 
	C276.369263,1159.308228 275.368164,1161.527466 274.431061,1163.604736 
	C276.884796,1163.604736 278.897980,1163.604736 281.443420,1163.985474 
	C283.477203,1163.885620 285.378845,1161.819702 287.653564,1164.236328 
	C288.424469,1165.055420 291.329865,1164.739868 292.639496,1163.934326 
	C293.980255,1163.109619 294.808960,1161.128662 295.335480,1159.485352 
	C295.512360,1158.933228 294.035828,1157.125244 293.380066,1157.158936 
	C290.625244,1157.300293 287.895020,1157.921509 284.286987,1158.508423 
	C284.380951,1158.582520 283.453979,1157.852417 282.674805,1157.238892 
	C282.005463,1158.760498 281.478546,1159.958252 280.651123,1160.551758 
M257.639343,1162.444946 
	C259.149414,1163.068481 260.614044,1164.020386 262.180420,1164.237183 
	C264.446869,1164.550903 266.786926,1164.332397 269.095612,1164.340942 
	C269.095612,1161.972290 269.095612,1159.603760 269.095612,1156.983398 
	C264.350677,1157.678223 260.686523,1158.214844 256.886353,1158.096558 
	C252.730042,1157.802490 248.573730,1157.508301 243.803436,1157.170776 
	C244.441986,1160.200195 244.908417,1162.413086 245.374847,1164.625854 
	C245.721695,1164.584351 246.068542,1164.542725 246.415405,1164.501221 
	C246.754471,1163.294434 247.093552,1162.087646 247.432617,1160.880859 
	C246.700562,1166.248291 251.202377,1165.109253 253.682587,1165.652100 
	C254.489761,1165.828735 255.880127,1163.340942 257.639343,1162.444946 
M176.469086,1199.495483 
	C182.372543,1199.505371 182.902786,1198.060547 179.137436,1190.913696 
	C177.677521,1191.934814 176.276413,1192.914917 174.199387,1193.875122 
	C173.128555,1193.838867 171.855148,1194.169434 171.019119,1193.707886 
	C164.587418,1190.157471 164.384094,1190.178101 161.469971,1196.763062 
	C161.182236,1197.038208 160.894485,1197.313477 159.913544,1197.723022 
	C159.442612,1197.015137 158.971680,1196.307373 158.500824,1195.599609 
	C159.772858,1194.957275 161.044907,1194.314941 162.316940,1193.672607 
	C161.748062,1193.007446 161.179184,1192.342163 160.610306,1191.677002 
	C157.530502,1193.815552 154.450699,1195.954102 151.370895,1198.092651 
	C151.048401,1197.639648 150.725906,1197.186646 150.403397,1196.733643 
	C151.630402,1195.425537 152.857391,1194.117310 154.084396,1192.809204 
	C153.880859,1192.489624 153.677322,1192.170044 153.473785,1191.850342 
	C151.497955,1192.901001 149.522125,1193.951538 146.819427,1195.388672 
	C146.367523,1194.169556 145.857132,1192.792725 145.346756,1191.416016 
	C144.900299,1191.724487 144.453842,1192.033081 144.007385,1192.341675 
	C143.025742,1194.477295 142.044083,1196.612793 141.359344,1198.102417 
	C143.924301,1198.544067 146.024719,1199.213379 148.121811,1199.203003 
	C152.585556,1199.181030 157.047546,1198.806152 161.973618,1198.395630 
	C161.973618,1198.395630 162.405884,1198.144653 162.796661,1198.748657 
	C164.586441,1199.106201 166.375137,1199.746826 168.166260,1199.753662 
	C170.750168,1199.763550 173.335846,1199.313477 176.469086,1199.495483 
M290.439514,1249.576416 
	C291.605133,1249.464600 292.770782,1249.352783 294.883514,1249.324219 
	C300.481995,1249.197021 306.079956,1249.034912 311.679077,1248.951782 
	C318.822937,1248.845703 319.398010,1247.797485 315.843750,1241.412842 
	C312.394531,1241.412842 309.031128,1241.412842 305.552155,1241.412842 
	C305.278046,1243.476685 305.049255,1245.199463 304.820465,1246.922363 
	C304.350616,1247.154541 303.880798,1247.386719 303.410950,1247.618896 
	C302.232513,1245.946167 301.054108,1244.273560 299.896790,1242.630859 
	C299.755585,1242.939941 299.625916,1243.396240 299.366272,1243.759644 
	C298.568481,1244.876099 297.720886,1245.957031 296.892700,1247.051758 
	C296.243713,1245.950317 295.589905,1244.851807 294.947784,1243.746338 
	C294.635712,1243.209106 294.345825,1242.659058 293.608765,1241.529785 
	C292.357635,1241.808594 291.106476,1242.087402 289.284576,1241.985107 
	C287.325958,1242.077271 285.367371,1242.169312 282.598389,1242.145630 
	C282.325592,1242.140381 282.052765,1242.135010 281.031616,1241.947998 
	C277.356262,1241.763550 274.858917,1243.851074 275.423615,1247.149536 
	C276.073730,1250.947021 279.224701,1249.505371 281.779510,1248.605713 
	C284.548279,1247.630737 287.394348,1247.062012 290.439514,1249.576416 
M295.596588,1145.423584 
	C292.255585,1145.259277 288.914612,1145.094849 286.199951,1144.961426 
	C284.911530,1148.272827 283.943481,1150.760864 282.981201,1153.234009 
	C283.992645,1153.078247 285.105835,1152.906616 286.978302,1152.806396 
	C287.995148,1152.877441 289.012024,1152.948364 290.713409,1153.123779 
	C291.167572,1153.057617 291.621765,1152.991333 292.816254,1152.882080 
	C293.556030,1152.930908 294.295807,1152.979736 295.527802,1153.167236 
	C295.867126,1153.155762 296.206451,1153.144165 297.041260,1153.747803 
	C298.380432,1152.660522 299.719604,1151.573242 301.337891,1150.537109 
	C301.555969,1150.427002 301.603760,1150.266479 302.226501,1150.306030 
	C304.612885,1148.212402 303.956238,1146.667236 301.192261,1145.992065 
	C299.694916,1145.626099 298.029663,1145.947632 295.596588,1145.423584 
M306.824677,1118.975830 
	C310.485748,1119.560791 313.751007,1118.319214 313.579651,1114.502319 
	C313.414703,1110.827393 309.651550,1112.022583 307.133636,1111.989868 
	C306.810211,1111.985718 306.484985,1112.116943 305.401245,1112.082031 
	C303.389862,1112.317261 301.378510,1112.552490 299.468842,1112.775879 
	C299.058563,1114.235352 298.659760,1115.654053 297.864075,1118.484619 
	C296.340729,1115.367065 295.517578,1113.682495 295.050262,1112.726074 
	C293.388245,1114.872070 292.082306,1116.558350 290.069092,1118.173462 
	C287.916809,1116.148926 285.764526,1114.124268 283.321381,1111.826050 
	C282.260193,1114.067993 281.301117,1116.094238 280.605042,1117.564697 
	C284.185455,1118.350220 287.495514,1119.076294 291.744598,1119.995972 
	C296.504181,1119.593506 301.263733,1119.191040 306.824677,1118.975830 
M121.325562,1080.905762 
	C120.138542,1081.117798 118.930328,1081.586914 117.768692,1081.491089 
	C114.866798,1081.252075 111.986053,1080.756470 108.228874,1080.151245 
	C105.310814,1080.535767 102.392761,1080.920410 99.315720,1081.325928 
	C97.304115,1080.917847 94.794113,1080.408691 91.361511,1079.712280 
	C92.640160,1083.185669 93.427681,1085.324951 94.424164,1088.031860 
	C95.024376,1085.875366 95.422874,1084.443726 96.228355,1081.549927 
	C96.951645,1084.550415 97.306908,1086.024170 97.799408,1088.067261 
	C98.742790,1086.811279 99.282333,1086.092896 99.827629,1085.366821 
	C103.829430,1089.445923 107.108543,1089.435913 111.448807,1085.685425 
	C114.767632,1089.513062 117.223015,1085.560059 120.698326,1085.036133 
	C123.885338,1088.762207 127.160828,1089.272949 129.713470,1086.009033 
	C131.782715,1086.702515 133.510269,1087.894897 134.687729,1087.489502 
	C135.700775,1087.140747 136.962234,1084.556519 136.556183,1083.694336 
	C135.839828,1082.173096 134.025818,1080.343750 132.513718,1080.189575 
	C129.098328,1079.841309 125.586792,1080.436401 121.325562,1080.905762 
M182.113556,1220.960205 
	C182.113556,1220.960205 181.993118,1221.003052 181.816330,1220.208862 
	C183.682877,1213.699341 183.660461,1213.762329 177.168900,1214.613525 
	C172.896057,1215.173706 168.575775,1215.371948 164.668060,1215.699585 
	C165.079926,1217.036499 165.626373,1218.810303 166.184418,1220.621826 
	C168.905594,1221.068970 171.444199,1221.775513 173.995697,1221.825684 
	C176.657135,1221.878052 179.331039,1221.297974 182.113556,1220.960205 
M450.350281,1143.995972 
	C447.400330,1144.008789 444.443512,1143.922241 441.506683,1144.125366 
	C440.971924,1144.162354 440.519043,1145.383301 439.160980,1146.047119 
	C437.642578,1145.427002 435.936646,1144.036011 434.645569,1144.350098 
	C432.107605,1144.967529 430.219025,1146.493408 427.186554,1144.522705 
	C425.841553,1143.648804 422.342438,1145.753540 419.996613,1146.873535 
	C418.429688,1147.621460 417.172119,1149.017212 415.776123,1150.123169 
	C415.473145,1149.779419 415.170197,1149.435669 414.867218,1149.092041 
	C415.976349,1147.751587 417.085449,1146.411133 418.545166,1144.647095 
	C414.551971,1143.863770 412.994080,1145.987915 412.691895,1148.616943 
	C412.572205,1149.658447 414.594330,1151.908203 415.711884,1151.955933 
	C419.603821,1152.122314 423.530243,1151.605835 427.435425,1151.257568 
	C428.497559,1151.162720 429.861389,1150.183350 430.523590,1150.571289 
	C434.469940,1152.883301 437.833893,1152.927734 440.338837,1148.674438 
	C440.980469,1149.510010 441.447632,1150.118286 442.206390,1151.106323 
	C442.591278,1148.969727 442.852417,1147.520142 443.786224,1146.339233 
	C444.227264,1147.818604 444.668274,1149.297974 445.054962,1150.594971 
	C446.787415,1150.386841 448.393036,1150.193848 450.287567,1150.616211 
	C456.617676,1153.078369 462.516357,1152.092529 463.500885,1148.387085 
	C462.085358,1148.817505 460.682190,1149.244141 459.279022,1149.670776 
	C459.052094,1149.237915 458.825134,1148.805176 458.598206,1148.372437 
	C460.072327,1147.502930 462.054382,1145.573853 462.914093,1145.985962 
	C464.668671,1146.827026 465.819153,1148.928467 467.325073,1150.631348 
	C467.653687,1149.333130 468.016998,1147.897949 468.207520,1147.145264 
	C469.850250,1148.394897 471.451172,1149.612793 473.543610,1151.286377 
	C475.758057,1150.937866 477.985565,1150.248535 480.183502,1150.332397 
	C482.611115,1150.425049 485.011658,1151.226562 487.330322,1151.700073 
	C486.704987,1149.589478 486.014587,1147.259155 485.361725,1145.055542 
	C483.867493,1144.772339 482.259705,1144.096924 480.744263,1144.263062 
	C478.175568,1144.544922 475.665161,1145.358154 472.298279,1145.583496 
	C466.795471,1145.005615 461.292633,1144.427734 455.943146,1143.865967 
	C455.338776,1145.965942 454.978882,1147.216309 454.791290,1147.868164 
	C453.435089,1146.653320 452.187073,1145.535400 450.350281,1143.995972 
M417.907776,1118.062134 
	C424.403351,1117.657471 430.898926,1117.252930 437.315308,1116.853271 
	C437.624268,1114.898193 437.911682,1113.079468 438.016510,1112.416138 
	C435.676971,1111.754150 433.982544,1110.888062 432.276703,1110.864990 
	C427.248657,1110.796875 422.216125,1111.062622 416.334259,1111.054688 
	C415.189728,1111.065186 414.045166,1111.075562 412.258209,1110.858398 
	C409.182556,1110.822388 405.999969,1110.270752 403.058380,1110.880981 
	C399.378510,1111.644409 394.699097,1109.326294 391.740540,1114.542480 
	C390.358368,1115.321167 388.976196,1116.099854 387.594025,1116.878540 
	C386.960846,1116.123413 386.327667,1115.368286 385.694458,1114.613159 
	C387.760925,1113.995117 389.827362,1113.377197 391.893799,1112.759277 
	C391.292969,1112.038452 390.692139,1111.317627 390.091309,1110.596924 
	C388.632507,1111.538940 386.528900,1112.169800 385.884918,1113.504883 
	C385.149902,1115.028687 385.714844,1117.179321 385.714844,1119.069458 
	C388.582031,1118.539795 390.312469,1118.220093 392.709167,1118.130493 
	C395.166931,1117.617310 397.637573,1116.627563 400.079742,1116.693359 
	C404.738647,1116.818848 409.381409,1117.546265 414.800201,1118.093262 
	C415.569946,1118.026123 416.339691,1117.958984 417.907776,1118.062134 
M52.034794,1124.959351 
	C52.034794,1124.959351 52.002556,1124.928955 51.762402,1125.635254 
	C53.039925,1127.407104 54.317444,1129.178955 55.927277,1131.411621 
	C60.479042,1126.640625 64.398293,1122.532593 67.406708,1119.379272 
	C62.997452,1114.538208 59.270214,1110.446045 55.513432,1106.321289 
	C53.076378,1108.755859 50.597485,1111.175659 48.182018,1113.657104 
	C43.208054,1118.767212 44.667919,1123.000000 52.034794,1124.959351 
M177.384827,1248.095337 
	C178.913818,1247.282104 180.442810,1246.468872 182.082108,1245.596924 
	C182.802887,1247.275879 183.319153,1248.478394 184.166336,1250.451660 
	C184.727676,1248.250244 185.053711,1246.971680 185.238525,1246.246826 
	C186.593216,1247.711792 188.733658,1250.643066 189.174149,1250.406738 
	C193.265610,1248.210938 198.351120,1251.684692 202.053604,1248.019043 
	C202.267670,1247.807007 203.076920,1248.196167 203.622345,1248.308228 
	C203.622345,1246.519775 203.622345,1245.221558 203.622345,1244.004272 
	C199.183319,1243.698608 194.911606,1243.404541 190.667252,1243.112183 
	C190.125381,1244.846558 189.683136,1246.261963 189.240906,1247.677490 
	C188.765198,1242.227905 185.264694,1242.577148 181.611450,1242.977539 
	C179.066620,1243.256592 176.497238,1243.311523 173.172958,1243.066772 
	C167.662613,1243.022949 167.390610,1243.428711 168.815475,1249.315430 
	C172.698624,1249.793945 176.515549,1250.264282 180.332489,1250.734741 
	C180.397583,1250.234131 180.462677,1249.733521 180.527771,1249.232910 
	C179.455048,1249.085449 178.382324,1248.937988 177.384827,1248.095337 
M349.794739,1114.232300 
	C348.929535,1113.186768 348.107727,1111.289307 347.191589,1111.242676 
	C343.140259,1111.036133 339.027588,1111.101318 335.010529,1111.628052 
	C333.481079,1111.828369 331.845703,1113.303223 330.844879,1114.637329 
	C330.285126,1115.383423 330.497223,1117.387207 331.148071,1118.172241 
	C331.801086,1118.959839 333.905182,1119.612671 334.554321,1119.157349 
	C338.032654,1116.717041 341.286133,1117.888184 344.778839,1118.937012 
	C346.244934,1119.377197 348.018311,1118.794189 349.652008,1118.676270 
	C349.658142,1118.289185 349.664276,1117.902100 349.670410,1117.515015 
	C348.614227,1117.161865 347.558075,1116.808594 345.379608,1116.080078 
	C347.655884,1115.531982 348.853577,1115.243530 350.525024,1115.517700 
	C355.159058,1116.694458 359.791382,1117.878052 364.431152,1119.031738 
	C364.727081,1119.105347 365.079498,1118.931641 365.408173,1118.896606 
	C370.419983,1118.363403 375.432159,1117.834229 381.178192,1117.226440 
	C378.261078,1115.159668 375.534790,1113.228149 372.479736,1111.063599 
	C371.870667,1112.755981 371.352783,1114.194946 370.628510,1116.207275 
	C369.578247,1111.235596 367.324188,1110.331543 363.098114,1111.891235 
	C358.911377,1113.436279 354.370453,1114.021606 349.794739,1114.232300 
M99.731979,1172.849976 
	C101.128593,1173.981201 102.525215,1175.112427 103.858574,1176.192505 
	C108.282623,1176.479004 113.177933,1176.210571 117.761673,1177.278809 
	C121.398560,1178.126465 123.075554,1177.401978 124.042084,1174.065918 
	C124.135246,1173.744263 124.520615,1173.507202 124.841354,1173.152344 
	C125.427254,1174.415894 125.894302,1175.422974 126.361351,1176.430176 
	C126.628502,1176.365479 126.895653,1176.300659 127.162804,1176.235962 
	C127.248718,1174.713623 127.334641,1173.191406 127.455956,1171.041870 
	C119.936356,1171.041870 112.834984,1171.041870 107.157951,1171.041870 
	C103.218124,1170.698364 100.632881,1170.473022 97.309875,1170.149414 
	C96.338646,1171.786255 95.367424,1173.422974 94.440971,1174.984375 
	C94.154602,1173.696777 93.805557,1172.127319 93.456512,1170.557861 
	C92.920334,1170.659668 92.384155,1170.761475 91.847977,1170.863281 
	C92.374077,1173.133667 92.900177,1175.403931 93.426262,1177.674316 
	C94.925110,1176.307861 96.428421,1174.946167 97.919579,1173.571289 
	C98.281532,1173.237549 98.603111,1172.859863 99.731979,1172.849976 
M147.142700,1096.147095 
	C147.142700,1096.147095 146.866714,1095.878174 146.817047,1095.072754 
	C146.572205,1088.448975 142.076492,1091.813965 138.480453,1091.135376 
	C138.793015,1093.912476 139.026855,1095.990234 139.150925,1097.092651 
	C141.402115,1096.361450 142.995682,1095.843750 144.589249,1095.326172 
	C144.991821,1100.971313 149.581543,1099.101196 151.962479,1098.519897 
	C156.346725,1097.449463 161.321854,1100.792114 165.236008,1096.659546 
	C165.473419,1096.408813 166.880493,1097.265991 167.867188,1097.656616 
	C168.386627,1092.382446 166.400558,1090.539062 161.883499,1091.259399 
	C156.991196,1092.039429 151.355576,1090.739380 148.364380,1096.462769 
	C148.300827,1096.584351 147.737503,1096.444946 147.142700,1096.147095 
M213.451019,1191.618286 
	C211.844238,1191.607788 210.229401,1191.488403 208.632141,1191.606689 
	C203.431946,1191.991455 198.142426,1193.292603 193.064377,1192.706665 
	C185.453842,1191.828247 184.943634,1191.866211 186.617676,1198.703369 
	C189.979767,1199.009521 193.121796,1199.688354 196.207108,1199.489136 
	C201.536377,1199.145020 206.830399,1198.255249 212.480301,1198.244507 
	C215.025269,1198.288940 217.570251,1198.333374 220.115219,1198.377808 
	C220.030319,1195.934326 219.945419,1193.490723 219.848923,1190.713257 
	C217.021194,1191.427124 215.469543,1191.818848 213.451019,1191.618286 
M453.856293,1127.300293 
	C455.219971,1127.621826 456.570984,1128.019775 457.950134,1128.247803 
	C460.303558,1128.636841 462.674652,1128.919312 465.501465,1129.310547 
	C463.226501,1126.521973 461.675110,1124.620361 460.036102,1122.611328 
	C459.694519,1123.112427 459.077179,1124.018188 458.408600,1124.999023 
	C457.150360,1123.656982 456.208984,1122.652954 454.972382,1121.333984 
	C454.190491,1122.739136 453.655060,1123.701294 452.414642,1124.590088 
	C451.558777,1123.950684 450.702911,1123.311157 449.840393,1122.666748 
	C449.577301,1123.156616 449.069519,1124.102295 448.520477,1125.124756 
	C444.719727,1120.829590 434.164764,1120.585205 431.198883,1124.672119 
	C430.334747,1123.697510 429.492188,1122.747192 428.473846,1121.598633 
	C427.406555,1122.784912 426.507355,1123.784424 425.492828,1124.911987 
	C424.995453,1123.834717 424.662201,1123.112915 424.081482,1121.855225 
	C422.227142,1125.053955 419.974762,1125.561523 417.224731,1122.279419 
	C417.388580,1125.018066 417.503357,1126.937378 417.618164,1128.856567 
	C418.055969,1129.105225 418.493744,1129.353882 418.931549,1129.602539 
	C419.453094,1128.208374 419.974640,1126.814087 420.257385,1126.058350 
	C422.883392,1127.372559 424.325073,1128.633667 425.859650,1128.759277 
	C430.159546,1129.110962 434.511200,1129.235352 438.817383,1128.997070 
	C443.591156,1128.732788 448.337280,1127.968140 453.856293,1127.300293 
M237.565460,1163.661621 
	C239.310974,1163.828247 241.056503,1163.994751 243.797897,1164.256348 
	C242.020950,1161.606323 240.607269,1159.498047 238.680832,1156.625122 
	C237.517975,1159.499023 236.787567,1161.304077 235.376129,1162.926880 
	C233.672012,1162.926880 231.967896,1162.926880 230.263763,1162.926880 
	C229.907623,1162.362061 229.551483,1161.797241 229.195343,1161.232544 
	C230.788986,1160.474121 232.382629,1159.715698 234.245743,1158.828979 
	C228.081024,1158.828979 222.898499,1158.828979 219.092834,1158.828979 
	C216.628479,1161.011353 214.505585,1164.065430 213.261459,1163.741699 
	C210.882553,1163.122803 209.003815,1160.581665 206.917496,1158.838257 
	C204.075745,1158.000977 200.607574,1158.211670 197.138931,1158.414062 
	C190.752365,1158.786865 190.752243,1158.784790 192.291061,1166.544067 
	C194.438919,1165.842773 196.877380,1165.649780 198.462143,1164.386108 
	C200.779129,1162.538574 202.214569,1161.112915 203.730377,1165.025757 
	C203.985611,1165.684448 206.254379,1166.224854 207.199066,1165.843750 
	C212.083664,1163.873657 218.094788,1166.566772 222.392166,1162.109009 
	C222.801788,1161.684082 224.710495,1161.853149 225.056793,1162.350220 
	C228.493744,1167.282837 232.767853,1165.203857 237.565460,1163.661621 
M97.406235,1153.391235 
	C96.386833,1153.250488 95.367432,1153.109741 94.908112,1153.046265 
	C95.978447,1151.459351 97.069786,1149.841431 98.161125,1148.223389 
	C97.803925,1147.881104 97.446732,1147.538940 97.089531,1147.196777 
	C95.550255,1147.393677 94.010979,1147.590698 92.541122,1147.778809 
	C92.541122,1150.474731 92.541122,1152.721191 92.541122,1155.607910 
	C94.588577,1155.303223 96.274406,1155.052368 98.745132,1154.887207 
	C102.125824,1154.136597 105.492882,1153.307861 108.900818,1152.713379 
	C109.458466,1152.616089 110.169289,1153.598145 110.857407,1153.998535 
	C111.824242,1154.561157 112.830757,1155.481201 113.829521,1155.493408 
	C119.766304,1155.566162 125.718063,1155.615845 131.637543,1155.241821 
	C133.660645,1155.114014 135.604919,1153.738770 137.679977,1152.894897 
	C132.454956,1147.170776 126.722015,1146.172119 120.198441,1147.570190 
	C117.221878,1148.208008 113.604095,1149.289551 111.159721,1148.196289 
	C107.168129,1146.411377 104.233887,1147.579346 100.577461,1148.789795 
	C100.407303,1149.867554 100.237144,1150.945190 99.546440,1152.492310 
	C99.005966,1152.937134 98.465485,1153.381958 97.406235,1153.391235 
M261.718872,1082.682251 
	C261.484192,1081.789917 261.249512,1080.897705 260.584808,1079.328125 
	C258.734924,1079.271606 256.885010,1079.215088 254.348541,1078.817017 
	C248.599762,1078.817017 242.850967,1078.817017 237.027206,1078.817017 
	C236.517471,1080.663574 236.116394,1082.116699 235.371017,1084.816895 
	C234.463898,1079.407715 232.099518,1078.513306 228.140030,1079.523926 
	C225.125946,1080.293335 221.872681,1080.125610 218.394836,1080.401001 
	C218.394836,1081.870605 218.394836,1084.029053 218.394836,1085.547852 
	C220.410812,1085.296143 222.599670,1084.355469 223.387283,1085.036255 
	C227.489197,1088.581299 232.023087,1085.810181 236.280045,1086.268921 
	C238.603073,1086.519287 241.072113,1085.414551 243.775146,1084.859375 
	C243.895599,1084.154785 244.110977,1082.894897 244.542252,1080.372314 
	C245.156448,1082.943726 245.475174,1084.278076 245.790878,1085.599854 
	C246.951019,1085.101807 247.742111,1084.762329 248.533188,1084.422729 
	C249.871399,1088.385010 252.685532,1086.483154 255.754929,1086.365479 
	C257.591644,1085.266846 259.428375,1084.168091 261.718872,1082.682251 
M214.271591,1243.272339 
	C212.297424,1243.272339 210.323257,1243.272339 208.457825,1243.272339 
	C208.457825,1245.937744 208.457825,1247.985840 208.457825,1251.565796 
	C211.208694,1249.856079 213.068726,1248.699951 215.604370,1247.921143 
	C217.244446,1248.256958 220.180969,1249.173462 220.329697,1248.841309 
	C222.489304,1244.019409 223.873138,1248.342407 224.656860,1248.801270 
	C226.684387,1248.082031 229.138138,1246.950562 229.285797,1247.197754 
	C232.221924,1252.109985 236.708801,1249.814453 240.622711,1249.778564 
	C244.647568,1249.741333 248.671570,1249.456909 252.692673,1249.224121 
	C255.008545,1249.090088 257.317963,1248.844360 259.630280,1248.649048 
	C259.387848,1246.346924 259.145416,1244.044800 258.960754,1242.291138 
	C251.973221,1242.291138 246.219360,1242.366333 240.468628,1242.264282 
	C236.686584,1242.197266 233.261871,1242.468384 232.785385,1246.355591 
	C231.864471,1245.104126 230.719406,1242.274292 229.639297,1242.298950 
	C224.723572,1242.410767 219.826126,1243.325806 214.271591,1243.272339 
M451.293976,1242.288330 
	C450.002197,1241.688232 448.710419,1241.088013 447.363464,1240.462280 
	C447.061096,1241.686890 446.823303,1242.650024 446.494598,1243.981323 
	C445.091766,1242.370728 444.069519,1241.197144 442.879822,1239.831177 
	C441.958801,1241.072144 441.296936,1241.963867 441.285187,1241.979736 
	C438.149445,1241.135498 435.526123,1240.429077 432.248932,1239.546753 
	C432.420074,1243.194092 432.524078,1245.410767 432.687500,1248.892944 
	C435.362640,1247.502686 437.267883,1245.637329 438.990295,1245.793091 
	C443.006622,1246.156494 446.913910,1247.471558 450.887329,1245.514038 
	C451.449188,1245.237183 452.337982,1245.689453 453.076233,1245.694580 
	C457.343964,1245.724609 461.722107,1244.970581 465.616638,1247.549927 
	C465.711334,1247.612549 465.929535,1247.476440 466.092804,1247.449829 
	C468.662567,1247.029541 471.232758,1246.612183 473.936218,1246.172485 
	C473.685364,1245.725952 472.809814,1244.850586 472.990448,1244.384155 
	C474.950775,1239.324219 472.382843,1239.018433 468.457581,1239.787720 
	C467.557831,1239.964111 466.509521,1239.333008 465.591797,1239.475098 
	C460.993408,1240.187378 456.415741,1241.033081 451.293976,1242.288330 
M158.151718,1154.988770 
	C159.379028,1154.155151 160.606339,1153.321411 161.833633,1152.487671 
	C161.690460,1152.260620 161.547287,1152.033447 161.404114,1151.806396 
	C159.995621,1152.135376 158.587128,1152.464233 157.178635,1152.793213 
	C156.958847,1152.372314 156.739059,1151.951538 156.519287,1151.530640 
	C158.190506,1150.571167 159.951614,1148.751343 161.505569,1148.913574 
	C162.839676,1149.053223 163.937103,1151.458252 165.445007,1153.218262 
	C165.572144,1151.584839 165.672195,1150.299438 165.772247,1149.013916 
	C166.189896,1148.918701 166.607544,1148.823364 167.025192,1148.728027 
	C167.741638,1150.393921 168.458084,1152.059814 169.411926,1154.277832 
	C169.793335,1152.580444 170.037430,1151.494141 170.316177,1150.253540 
	C171.671951,1153.554688 179.332626,1156.256836 181.797485,1154.034424 
	C182.901978,1153.038696 182.913361,1150.528198 182.856430,1148.722778 
	C182.838318,1148.148315 180.765778,1146.945068 180.030075,1147.195435 
	C173.609390,1149.380493 167.164246,1146.842529 160.735046,1147.504150 
	C157.266891,1147.861206 153.769821,1147.362793 152.390198,1152.477051 
	C151.236313,1147.149658 147.708267,1146.030518 144.187073,1147.700928 
	C142.704285,1148.404419 142.670273,1152.161743 142.045761,1154.298218 
	C147.956100,1154.562622 152.676315,1154.773926 158.151718,1154.988770 
M426.154236,1239.870117 
	C423.517456,1240.424316 420.880676,1240.978516 417.347565,1241.721069 
	C416.994385,1241.532715 415.629028,1240.804321 413.915619,1239.890137 
	C413.442169,1241.754028 413.094391,1243.123047 412.634277,1244.934326 
	C411.870117,1243.358643 411.294342,1242.171387 410.710358,1240.967163 
	C409.466095,1242.745728 408.396057,1244.275269 407.325989,1245.804688 
	C406.617676,1244.310669 405.909363,1242.816528 405.144043,1241.202271 
	C400.827057,1240.769409 395.967590,1238.891357 392.476379,1243.841309 
	C391.556793,1242.985474 390.692688,1242.181274 389.828583,1241.376953 
	C389.524933,1241.579956 389.221283,1241.782837 388.917633,1241.985840 
	C389.963593,1244.049683 391.009552,1246.113525 392.289307,1248.638672 
	C393.595978,1247.219360 394.449463,1246.292236 395.151917,1245.529175 
	C396.598480,1246.553223 398.849304,1248.656006 399.248871,1248.349121 
	C402.509216,1245.844360 405.690277,1248.422974 408.855774,1248.209961 
	C413.928284,1247.868652 419.017822,1244.479858 424.247253,1247.958496 
	C424.957977,1248.431152 426.528503,1247.611206 427.700897,1247.389771 
	C427.698242,1247.092163 427.695587,1246.794556 427.692932,1246.496948 
	C426.673248,1246.140015 425.653564,1245.783081 426.034149,1245.916382 
	C426.381409,1243.733398 426.677795,1241.870117 426.154236,1239.870117 
M404.631592,1219.455322 
	C407.105927,1216.803833 409.856628,1218.416382 412.601776,1218.824097 
	C415.170105,1219.205566 417.797119,1219.192017 420.398315,1219.352173 
	C420.424652,1218.772339 420.450989,1218.192505 420.477325,1217.612671 
	C419.453827,1217.270142 418.430298,1216.927734 417.903656,1216.751465 
	C418.737579,1215.528320 419.597748,1214.266724 420.457886,1213.005127 
	C419.589325,1212.473511 418.147278,1211.321289 417.943665,1211.509399 
	C416.438446,1212.900146 415.179901,1214.557861 413.250977,1216.829712 
	C413.250977,1215.124146 413.250977,1213.718872 413.250977,1212.474976 
	C408.981293,1212.474976 404.794464,1212.401001 400.612274,1212.508667 
	C398.745361,1212.556641 396.891937,1213.129517 395.025055,1213.177002 
	C392.567444,1213.239746 389.739197,1212.274414 387.788940,1213.225220 
	C386.760498,1213.726685 387.258453,1217.359009 386.955109,1220.878052 
	C393.243835,1217.556396 398.613159,1216.785156 404.631592,1219.455322 
M117.849487,1215.376709 
	C116.257004,1215.407104 114.664581,1215.459351 113.072029,1215.463135 
	C110.370499,1215.469482 107.665993,1215.363159 104.968201,1215.458618 
	C102.612236,1215.541748 100.265968,1215.870117 97.911240,1216.027588 
	C96.435356,1216.126099 93.785263,1215.790527 93.673729,1216.198975 
	C93.192383,1217.961792 93.078514,1220.053589 93.649506,1221.750244 
	C93.847504,1222.338623 96.669991,1222.702637 97.849579,1222.175415 
	C101.103775,1220.721069 104.017166,1219.209106 107.282333,1222.261108 
	C107.948196,1222.883423 109.716972,1222.357544 110.975197,1222.307617 
	C113.410469,1222.211182 115.845383,1221.965210 118.278275,1221.990356 
	C120.548660,1222.013794 122.814682,1222.346069 125.086838,1222.439941 
	C125.402214,1222.453125 125.741814,1221.880859 126.353828,1221.319214 
	C125.227150,1220.630005 124.311821,1220.070068 124.071007,1219.922729 
	C124.849236,1218.245972 125.583939,1216.662964 126.767815,1214.112183 
	C123.141769,1214.540039 120.886665,1214.806030 117.849487,1215.376709 
M331.996765,1084.206909 
	C337.547729,1086.720947 345.124756,1085.748047 348.992218,1081.895508 
	C350.035828,1082.995605 351.076141,1084.092285 352.156006,1085.230713 
	C353.359985,1083.687744 354.322266,1082.454468 355.485870,1080.963257 
	C356.586853,1086.138184 357.269318,1086.442139 361.833771,1083.788208 
	C360.449524,1083.304077 359.371002,1082.926880 358.799835,1082.727173 
	C359.891388,1081.097778 360.931732,1079.544922 361.972046,1077.992065 
	C361.511047,1077.452271 361.050079,1076.912476 360.589081,1076.372681 
	C357.452332,1077.559570 354.315613,1078.746460 352.643677,1079.379150 
	C346.248840,1078.760498 340.949921,1078.172485 335.634918,1077.848267 
	C335.092407,1077.815308 334.475006,1079.383179 333.852356,1080.176880 
	C333.317322,1080.858887 332.725128,1081.495850 332.210052,1082.092773 
	C331.303802,1080.948853 330.647156,1080.120117 329.990540,1079.291260 
	C328.629089,1080.781860 327.267670,1082.272583 325.591644,1084.107666 
	C328.226624,1083.966797 329.769531,1083.884277 331.996765,1084.206909 
M333.171570,1146.961670 
	C332.407379,1148.058228 331.643188,1149.154785 330.390900,1150.951660 
	C330.070801,1148.381836 329.883728,1146.880127 329.670441,1145.167725 
	C323.440857,1145.167725 317.554138,1144.985840 311.694275,1145.302368 
	C310.321838,1145.376587 309.040527,1147.138184 306.672119,1148.901489 
	C309.743134,1149.671021 311.062317,1150.001709 312.700043,1150.412109 
	C311.121429,1150.915405 309.862366,1151.316895 308.603302,1151.718384 
	C308.745087,1152.316895 308.886902,1152.915405 309.028717,1153.513794 
	C311.625122,1152.820923 314.248657,1152.212891 316.805359,1151.395752 
	C317.919373,1151.039551 318.896301,1150.254639 319.935486,1149.664429 
	C321.841919,1153.624146 325.374756,1151.700562 328.210022,1151.768433 
	C332.944519,1151.881836 337.694214,1151.382080 342.330933,1152.866211 
	C342.701172,1152.984741 343.335052,1152.279785 343.125458,1152.411865 
	C340.657867,1150.269287 338.290710,1148.106689 335.776276,1146.131470 
	C335.336090,1145.785767 334.277100,1146.227661 333.171570,1146.961670 
M152.916565,1255.459351 
	C150.787033,1255.796387 147.946960,1257.136475 146.687943,1256.244629 
	C144.591446,1254.759521 142.827560,1253.278076 141.307236,1255.809692 
	C140.595428,1256.994873 141.948135,1259.417114 142.352798,1261.281494 
	C142.378265,1261.398926 142.296097,1261.539551 142.565201,1260.450562 
	C144.949524,1260.352783 148.694687,1259.055298 150.288788,1260.368530 
	C153.772522,1263.238647 157.089661,1262.260620 160.383575,1261.460938 
	C162.174316,1261.026245 163.584946,1259.025757 165.717453,1257.281372 
	C166.423584,1264.873169 170.967484,1258.761597 173.326019,1261.007568 
	C173.326019,1258.911987 173.326019,1257.454712 173.326019,1256.464111 
	C171.181427,1255.779053 168.169464,1254.200439 167.691071,1254.765747 
	C164.959747,1257.993164 162.605148,1256.131958 159.774933,1255.255981 
	C157.955231,1254.692871 155.714508,1255.489990 152.916565,1255.459351 
M166.734222,1169.686890 
	C157.799408,1170.384521 148.641388,1168.512451 138.786499,1171.603027 
	C139.644257,1171.914917 139.350388,1171.767090 139.039200,1171.702148 
	C137.803925,1171.444458 136.563248,1171.212524 135.324585,1170.970947 
	C135.234940,1172.397827 135.145294,1173.824707 135.055664,1175.251587 
	C136.182648,1175.229126 137.309875,1175.215088 138.436569,1175.182739 
	C141.976013,1175.081055 145.794830,1177.604736 148.995850,1174.081543 
	C149.039719,1174.033203 149.676041,1174.312378 149.751663,1174.535034 
	C151.222778,1178.869995 153.931915,1177.306885 156.968765,1176.470703 
	C159.348801,1175.815186 162.053680,1176.339111 164.773117,1176.339111 
	C161.454636,1172.924194 165.339371,1171.832031 166.734222,1169.686890 
M356.249115,1129.803955 
	C357.107635,1130.087036 357.966156,1130.369995 358.824677,1130.652954 
	C359.094299,1130.314209 359.363892,1129.975464 359.633484,1129.636719 
	C358.454346,1129.311646 357.275177,1128.986450 356.328369,1128.725464 
	C358.379578,1126.810425 359.446899,1125.814087 360.514160,1124.817627 
	C359.153656,1124.261597 357.820435,1123.618652 356.423096,1123.179932 
	C355.489838,1122.886963 354.445679,1122.687988 353.485168,1122.784790 
	C348.538147,1123.282959 342.882263,1120.875244 339.130707,1126.528931 
	C338.483612,1127.504150 336.376953,1127.510986 334.944519,1127.965088 
	C334.651520,1127.484619 334.358521,1127.004028 334.065521,1126.523438 
	C335.618347,1125.764038 337.171204,1125.004517 338.724060,1124.245117 
	C338.067688,1123.592285 337.411316,1122.939453 336.754944,1122.286621 
	C335.319672,1123.884766 333.602051,1125.331177 332.614258,1127.169800 
	C332.301147,1127.752563 333.854065,1130.296143 334.692017,1130.370117 
	C337.986267,1130.661255 341.341187,1130.409180 344.663574,1130.199951 
	C348.382080,1129.965942 352.089752,1129.559814 356.249115,1129.803955 
M121.168663,1250.709351 
	C123.649635,1250.333740 126.963257,1250.821533 128.436188,1249.400757 
	C131.397308,1246.544800 132.782745,1247.704102 133.933334,1249.549805 
	C135.812149,1248.704102 137.493896,1247.947021 139.237411,1247.162109 
	C140.875198,1252.132324 141.026993,1252.187012 144.552673,1250.249512 
	C143.330276,1248.206787 142.110306,1246.168091 140.616165,1243.671143 
	C131.665939,1241.438110 126.062302,1242.692505 124.416115,1248.286377 
	C123.928398,1246.416260 123.593307,1245.131470 123.404442,1244.407227 
	C120.615471,1245.225098 116.369377,1240.780884 115.828651,1246.331909 
	C115.762093,1247.015259 118.096512,1247.932617 119.156456,1248.634277 
	C118.353828,1248.983765 117.288330,1249.447754 116.222832,1249.911621 
	C116.285568,1250.274170 116.348297,1250.636719 116.411026,1250.999268 
	C117.745461,1250.999268 119.079887,1250.999268 121.168663,1250.709351 
M284.795929,1129.100342 
	C283.736328,1127.010010 282.676697,1124.919678 281.993713,1123.572266 
	C273.132477,1124.036255 265.060181,1124.458862 256.917725,1124.885132 
	C257.755249,1127.380737 258.260529,1128.886353 258.982056,1131.036255 
	C259.606506,1128.756348 259.993744,1127.342529 260.380951,1125.928711 
	C260.701233,1125.937012 261.021515,1125.945312 261.341797,1125.953613 
	C261.797180,1127.401367 262.252563,1128.849121 262.983551,1131.173096 
	C265.476318,1130.739014 268.022949,1130.072632 270.600555,1129.916260 
	C272.712402,1129.788330 274.876373,1130.533203 276.989655,1130.411987 
	C279.817078,1130.249390 282.617950,1129.624023 284.795929,1129.100342 
M292.637115,1195.144409 
	C293.140167,1193.735840 293.643250,1192.327393 294.236908,1190.665161 
	C285.065247,1193.612549 275.850616,1189.453247 266.604797,1192.809326 
	C266.604797,1193.803711 266.604797,1195.821411 266.604797,1198.569824 
	C268.154602,1197.348511 269.113281,1196.592896 270.049408,1195.855225 
	C275.291412,1199.654053 279.327576,1199.152832 283.845001,1194.099609 
	C285.766083,1199.256470 289.052612,1197.157227 292.637115,1195.144409 
M200.601364,1153.859863 
	C202.360962,1153.769775 204.126373,1153.542969 205.878998,1153.617310 
	C209.977570,1153.791016 214.069977,1154.109619 218.201385,1154.371826 
	C216.280579,1151.917969 214.931412,1150.194458 213.990265,1148.992188 
	C213.310211,1149.773438 211.955658,1151.329712 210.601120,1152.885986 
	C210.156219,1152.594482 209.711304,1152.303101 209.266388,1152.011597 
	C209.111511,1150.624146 208.956635,1149.236816 208.738358,1147.281494 
	C207.845749,1148.561157 207.259125,1149.402222 206.537384,1150.436890 
	C205.387589,1149.077881 204.470245,1147.993530 203.307236,1146.618774 
	C202.295990,1148.131104 201.494827,1149.329224 201.508743,1149.308472 
	C199.138550,1149.123657 197.708328,1149.169067 196.353928,1148.870483 
	C194.205353,1148.396851 192.127777,1147.529785 189.966492,1147.261353 
	C189.639374,1147.220825 188.702011,1149.494019 188.628784,1150.731201 
	C188.553741,1151.999512 189.193100,1153.310181 189.467392,1154.395142 
	C193.249008,1155.759521 196.228485,1150.031738 200.601364,1153.859863 
M333.907166,1248.401001 
	C336.591400,1247.288208 338.943756,1246.470947 342.023468,1248.344971 
	C343.587891,1249.296997 346.735260,1248.118164 348.912567,1247.258301 
	C349.449524,1247.046265 348.992645,1244.317627 348.992645,1243.010864 
	C347.480194,1244.575562 346.173370,1245.927612 344.122345,1248.049438 
	C343.632996,1244.963135 343.407867,1243.543213 343.007141,1241.015747 
	C342.310333,1242.992920 341.976227,1243.941040 341.516052,1245.246826 
	C340.121002,1243.656982 338.967651,1242.342529 337.531067,1240.705322 
	C336.412201,1242.466797 335.475586,1243.941406 334.401154,1245.632935 
	C334.119141,1244.173950 333.880585,1242.939575 333.856384,1242.814453 
	C331.204285,1242.814453 328.962036,1242.945435 326.746216,1242.760010 
	C325.615845,1242.665283 324.424255,1241.528809 323.463501,1241.749268 
	C322.717346,1241.920410 322.286072,1243.464233 321.714905,1244.397949 
	C322.889496,1245.103638 324.064117,1245.809448 325.238342,1246.514893 
	C324.404907,1246.785400 323.362091,1247.123779 322.319305,1247.462158 
	C322.461456,1248.025146 322.603607,1248.588135 322.745758,1249.151245 
	C325.103516,1248.584229 327.439514,1247.869751 329.828857,1247.516357 
	C330.919281,1247.355103 332.114990,1247.906250 333.907166,1248.401001 
M351.573853,1150.688232 
	C350.887909,1148.977295 350.201935,1147.266357 349.019531,1144.317261 
	C349.019531,1147.910522 349.019531,1150.247803 349.019531,1153.080078 
	C351.422241,1152.770386 353.488037,1152.472412 355.561615,1152.245361 
	C357.735168,1152.007324 359.962372,1151.453003 362.085510,1151.708130 
	C369.187653,1152.561157 371.410461,1151.570435 374.414642,1144.462402 
	C371.171082,1146.619141 367.927490,1148.775757 364.683929,1150.932373 
	C364.431488,1150.571655 364.179077,1150.210938 363.926666,1149.850098 
	C365.151703,1148.457153 366.376740,1147.064087 368.145844,1145.052368 
	C365.356171,1145.390259 363.226990,1146.320068 361.762665,1145.694214 
	C358.000549,1144.086426 356.203247,1145.669189 354.619873,1148.721069 
	C354.184509,1149.560303 353.129608,1150.078125 351.573853,1150.688232 
M64.925903,1087.271729 
	C67.613235,1084.937012 67.712624,1082.830078 64.805779,1080.412598 
	C61.652821,1077.790527 58.627792,1072.407471 55.861809,1072.606445 
	C52.618774,1072.839478 49.710419,1077.786377 46.667942,1080.700073 
	C46.070557,1081.272217 45.196125,1081.817871 45.028259,1082.515015 
	C44.766220,1083.603394 44.527054,1085.187744 45.100349,1085.904785 
	C48.167076,1089.740479 51.488846,1093.372070 55.449867,1097.907227 
	C59.192806,1093.705566 61.817413,1090.759399 64.925903,1087.271729 
M229.063431,1220.681885 
	C230.204590,1220.235718 232.004288,1219.089966 232.386719,1219.449951 
	C237.086914,1223.874023 239.098419,1220.778564 240.628220,1216.036865 
	C241.342819,1218.007080 241.830292,1219.350952 242.575790,1221.406372 
	C243.663864,1218.426147 244.412735,1216.374878 245.396225,1213.681152 
	C242.949539,1213.857178 240.776413,1214.013550 238.915253,1214.147461 
	C238.196106,1216.115723 237.675415,1217.540771 236.742706,1220.093506 
	C236.106644,1217.266602 235.769882,1215.769775 235.426880,1214.245361 
	C232.201080,1214.838379 229.240387,1217.358643 226.563629,1213.953735 
	C225.230484,1215.114014 223.471115,1216.054321 222.717926,1217.513916 
	C222.236588,1218.446533 223.242844,1220.147095 223.721008,1222.058105 
	C225.296753,1221.633423 226.809357,1221.225586 229.063431,1220.681885 
M186.014069,1174.524414 
	C187.215347,1172.999023 188.416611,1171.473755 189.826279,1169.683716 
	C185.886047,1170.049927 182.250305,1170.387695 178.161407,1170.767700 
	C177.760040,1171.657227 176.950089,1173.452148 176.140182,1175.247070 
	C178.047974,1175.484985 179.959930,1175.695068 181.860565,1175.980347 
	C182.328613,1176.050537 182.758392,1176.538086 183.208313,1176.541870 
	C188.076920,1176.584229 192.946030,1176.568359 197.815002,1176.568359 
	C197.849747,1176.292114 197.884491,1176.015869 197.919235,1175.739502 
	C196.406250,1175.316284 194.893280,1174.893066 193.900391,1174.615234 
	C194.863815,1173.202393 195.944901,1171.616943 197.025970,1170.031494 
	C196.441360,1169.423340 195.856750,1168.815063 195.272125,1168.206787 
	C193.867935,1169.377319 192.492523,1170.584717 191.051361,1171.707886 
	C189.633240,1172.812988 188.148407,1173.832275 186.014069,1174.524414 
M302.078064,1194.749878 
	C303.200867,1194.857300 305.041321,1194.580322 305.336304,1195.131104 
	C308.545349,1201.122925 312.836792,1196.926880 316.436188,1196.125122 
	C318.028381,1195.770508 319.128021,1193.204712 320.451233,1191.642578 
	C318.742432,1191.105225 316.979889,1189.986328 315.334747,1190.138306 
	C310.109314,1190.621094 304.927612,1191.577881 299.697388,1192.356201 
	C299.607208,1195.462891 299.607208,1195.462891 302.078064,1194.749878 
M387.168457,1125.288452 
	C385.442535,1122.196167 381.306091,1121.000610 378.046570,1122.475586 
	C376.704163,1123.083130 375.177948,1123.284302 372.994232,1123.868774 
	C373.056335,1123.558350 372.850586,1124.586304 372.553772,1126.068970 
	C371.183502,1124.586792 370.132538,1123.449829 368.693390,1121.893066 
	C367.437927,1124.676270 366.431885,1126.906616 365.425842,1129.136841 
	C365.779266,1129.312500 366.132690,1129.488159 366.486115,1129.663818 
	C367.284576,1128.433472 368.083008,1127.203247 368.922363,1125.909790 
	C371.044342,1127.576904 371.895599,1132.956055 375.431671,1127.769653 
	C375.462067,1127.724976 376.443604,1128.291382 376.952881,1128.616699 
	C381.205811,1131.333130 384.475433,1130.172852 387.168457,1125.288452 
M92.633575,1132.469482 
	C98.184685,1132.431274 103.735802,1132.393188 109.262054,1132.355103 
	C108.978554,1127.968750 108.354179,1125.524170 104.599701,1125.098877 
	C101.494774,1124.747192 99.161736,1124.539062 98.467484,1128.587891 
	C98.299889,1129.565186 95.823776,1130.146606 94.409233,1130.910034 
	C94.022110,1130.400269 93.634987,1129.890381 93.247864,1129.380493 
	C94.054489,1128.912720 94.899445,1128.498901 95.656670,1127.961548 
	C96.384514,1127.445068 97.021538,1126.800659 98.003220,1125.947632 
	C92.738838,1125.456909 91.283485,1127.093384 92.633575,1132.469482 
M261.201141,1217.869385 
	C261.835663,1218.742310 262.470184,1219.615356 263.233582,1220.665649 
	C264.975189,1218.279297 266.495331,1216.196289 268.489563,1213.463745 
	C262.574768,1213.463745 258.180298,1213.462402 253.785843,1213.466309 
	C253.457184,1213.466553 253.013947,1213.373291 252.818329,1213.544922 
	C251.519226,1214.685303 249.929733,1215.697998 249.140411,1217.138794 
	C248.676392,1217.985962 249.177200,1220.337280 249.833466,1220.613770 
	C251.091629,1221.144287 252.870361,1220.994141 254.250504,1220.553833 
	C256.401154,1219.867554 258.399567,1218.704102 261.201141,1217.869385 
M397.647522,1147.850586 
	C398.125549,1147.952148 398.603607,1148.053589 399.062683,1148.151001 
	C399.163879,1148.055054 399.254730,1147.968994 399.345642,1147.882935 
	C398.496613,1146.993896 397.647583,1146.104858 396.691132,1145.103394 
	C396.161072,1145.610474 395.265137,1146.467651 394.360291,1147.333252 
	C393.275177,1146.136841 392.446899,1144.386841 391.444733,1144.280762 
	C388.793213,1144.000366 386.004730,1144.149902 383.400177,1144.719727 
	C382.373810,1144.944458 381.001923,1146.746094 381.013428,1147.816406 
	C381.030548,1149.412842 381.697723,1151.837891 382.852386,1152.436401 
	C387.052063,1154.613159 394.782593,1151.872070 397.647522,1147.850586 
M375.667145,1156.401123 
	C371.310181,1156.594727 366.953217,1156.788208 362.306488,1156.994507 
	C362.907501,1157.623413 363.788635,1158.106567 363.885742,1158.714844 
	C364.878906,1164.936035 368.193970,1164.805298 373.020538,1162.671265 
	C374.973419,1161.807861 377.626892,1162.528931 380.376709,1162.528931 
	C380.232544,1160.787109 380.062469,1158.732422 379.968353,1157.595215 
	C378.373871,1157.322266 377.298126,1157.138062 375.667145,1156.401123 
M203.661346,1254.854736 
	C201.239944,1254.854736 198.818527,1254.854736 196.113144,1254.854736 
	C196.321411,1256.658325 196.525192,1258.423096 196.720230,1260.112061 
	C206.076874,1261.774048 206.076874,1261.774048 210.879990,1256.815430 
	C211.309509,1258.247437 211.689590,1259.514648 212.069672,1260.781738 
	C211.995010,1255.376953 209.979614,1253.850220 203.661346,1254.854736 
M142.899384,1124.890015 
	C141.292053,1124.907227 139.284119,1124.327637 138.168182,1125.076782 
	C136.342606,1126.302368 133.864487,1128.400146 133.875046,1130.116577 
	C133.894516,1133.278564 136.685715,1132.737305 139.177078,1132.185181 
	C142.144745,1131.527466 145.231522,1131.407349 148.411163,1131.037598 
	C146.320450,1129.639404 150.714050,1123.326172 142.899384,1124.890015 
M233.652313,1151.942139 
	C233.342896,1151.849609 233.025665,1151.776123 232.728546,1151.653809 
	C232.632050,1151.613892 232.506226,1151.377808 232.531876,1151.347290 
	C233.693634,1149.963379 234.870758,1148.592529 236.046066,1147.220093 
	C231.824982,1146.021240 228.715103,1145.928223 225.613297,1146.046509 
	C222.260483,1146.174438 222.595306,1148.815308 223.043945,1150.814209 
	C223.351929,1152.186401 224.575745,1154.106934 225.721848,1154.377563 
	C228.001266,1154.915771 230.512329,1154.546387 232.922241,1154.434814 
	C234.787781,1154.348389 235.024994,1153.844849 233.652313,1151.942139 
M115.351303,1131.691528 
	C117.833839,1130.689087 120.183975,1131.027222 122.541496,1132.210205 
	C123.395912,1132.638916 124.644585,1133.093506 125.383141,1132.773682 
	C126.944016,1132.097656 128.716949,1131.188354 129.557724,1129.845459 
	C130.056671,1129.048706 129.345917,1126.656372 128.452362,1126.046875 
	C127.432335,1125.351196 125.564644,1125.612061 124.137215,1125.864258 
	C121.762360,1126.283691 119.470459,1127.745728 117.193237,1125.395630 
	C116.776436,1124.965454 115.380692,1125.483765 113.906296,1125.609497 
	C114.149200,1127.794312 114.360321,1129.693237 115.351303,1131.691528 
M246.465012,1153.385010 
	C249.504333,1152.887695 252.543640,1152.390381 257.005035,1151.660400 
	C254.782791,1149.623291 252.906021,1147.902954 250.977264,1146.135010 
	C249.831146,1147.437134 249.002289,1148.378906 248.870789,1148.528320 
	C246.274841,1147.814697 243.903824,1146.377930 242.736786,1147.045532 
	C241.354126,1147.836548 241.009140,1150.441528 240.214340,1152.260376 
	C242.065308,1152.738403 243.916260,1153.216431 246.465012,1153.385010 
M213.478882,1085.855225 
	C212.486893,1085.329224 211.494919,1084.803345 211.120773,1084.604858 
	C212.085617,1082.938477 212.999802,1081.359619 214.514145,1078.744141 
	C211.143509,1079.139160 209.377884,1079.502930 207.607803,1079.526245 
	C200.200256,1079.623291 200.199768,1079.585938 201.812210,1087.414917 
	C205.551147,1087.125244 209.365189,1086.829834 213.478882,1085.855225 
M243.101807,1115.601318 
	C243.743301,1117.256104 244.253677,1120.252197 245.049316,1120.329956 
	C248.246704,1120.642578 251.532394,1120.051880 256.156433,1119.686646 
	C254.147293,1118.419800 253.302917,1117.887451 252.895309,1117.630371 
	C253.650497,1116.038574 254.385971,1114.488403 255.411819,1112.326050 
	C252.012100,1112.326050 249.546600,1112.039673 247.194183,1112.431885 
	C245.747269,1112.673218 244.473190,1113.951294 243.101807,1115.601318 
M280.842438,1219.532471 
	C283.837738,1219.532471 286.833038,1219.532471 289.822083,1219.532471 
	C289.552277,1218.812256 288.525909,1217.481812 288.843048,1216.688110 
	C290.744202,1211.930054 287.311707,1212.189087 285.004578,1213.076782 
	C282.765350,1213.938354 281.070953,1216.215820 279.394287,1217.651489 
	C278.534271,1216.504150 277.244385,1214.783447 275.548981,1212.521851 
	C273.993256,1215.550171 272.908264,1217.662109 271.789490,1219.839844 
	C274.995667,1219.839844 277.571930,1219.839844 280.842438,1219.532471 
M370.562103,1212.817505 
	C369.902313,1214.772705 369.242493,1216.728027 368.582672,1218.683228 
	C370.355530,1218.864258 372.141266,1219.272705 373.898285,1219.173462 
	C376.570190,1219.022583 379.222229,1218.519653 381.976624,1218.153931 
	C381.976624,1216.675293 381.976624,1214.714478 381.976624,1213.046875 
	C378.252441,1212.856201 374.773560,1212.678101 370.562103,1212.817505 
M317.087341,1084.624023 
	C318.626678,1082.543579 320.166016,1080.463135 321.705353,1078.382812 
	C321.297272,1078.121948 320.889191,1077.861084 320.481110,1077.600220 
	C319.767853,1078.384033 319.054596,1079.167969 318.156860,1080.154419 
	C314.845184,1075.978882 312.632324,1077.517334 311.067230,1082.754028 
	C310.324463,1080.527588 309.951996,1079.411255 309.584778,1078.310669 
	C307.848511,1079.922363 306.275818,1081.382202 303.658936,1083.811401 
	C308.713531,1084.303223 312.593658,1084.680664 317.087341,1084.624023 
M157.037460,1221.395142 
	C160.659073,1218.373291 156.938171,1215.539917 155.609818,1215.024902 
	C152.990952,1214.009155 149.559357,1214.134033 146.822769,1214.966675 
	C146.014954,1215.212402 146.411148,1219.415405 146.255600,1221.804932 
	C146.735443,1221.881714 147.215286,1221.958496 147.695129,1222.035278 
	C148.307861,1220.992310 148.920609,1219.949341 149.667068,1218.678833 
	C149.889435,1220.072632 150.063751,1221.165283 150.293961,1222.608398 
	C152.468933,1222.336060 154.478897,1222.084351 157.037460,1221.395142 
M267.879883,1082.454224 
	C268.448761,1082.665283 269.017609,1082.876465 270.020386,1083.248657 
	C268.445831,1083.873291 267.367859,1084.300903 266.289917,1084.728516 
	C269.768311,1085.794922 273.636993,1086.849487 276.413452,1085.557251 
	C281.021637,1083.412598 277.306427,1080.628052 275.563293,1078.015991 
	C274.419586,1078.868408 273.335144,1079.676636 273.448059,1079.592407 
	C270.915619,1078.951294 269.443909,1078.578735 267.972168,1078.206177 
	C267.729309,1079.539062 267.486450,1080.872070 267.879883,1082.454224 
M268.947144,1119.871948 
	C272.048950,1120.573853 274.708618,1119.617310 274.415314,1116.276245 
	C274.177094,1113.562622 272.299164,1111.272461 268.563263,1112.317627 
	C265.936554,1113.052490 263.151031,1113.219849 260.476074,1113.634521 
	C260.750916,1115.375000 261.025848,1117.115723 261.132904,1117.793701 
	C263.974213,1118.564941 266.086670,1119.138306 268.947144,1119.871948 
M382.939484,1077.069336 
	C380.141693,1077.613403 377.343933,1078.157471 374.546173,1078.701538 
	C374.637848,1078.925171 374.729553,1079.148804 374.821228,1079.372559 
	C372.023102,1078.750977 369.224976,1078.129395 366.426849,1077.507812 
	C372.582062,1083.990967 382.600159,1087.077148 383.615204,1083.496216 
	C382.523529,1083.145752 381.463593,1082.805542 381.328278,1082.762085 
	C382.217224,1080.716064 382.916779,1079.105957 382.939484,1077.069336 
M156.705612,1165.779419 
	C158.778015,1165.443237 160.850418,1165.106934 162.922791,1164.770630 
	C162.461395,1162.516357 162.000000,1160.262207 161.375336,1157.210205 
	C158.812775,1158.944092 157.384430,1159.910522 155.176651,1161.404419 
	C154.714951,1160.356812 154.183136,1159.150146 153.182190,1156.879150 
	C151.411438,1160.687866 150.337509,1162.997925 149.637497,1164.503662 
	C152.150818,1164.867676 154.106415,1165.150879 156.705612,1165.779419 
M467.103790,1156.305298 
	C466.751617,1158.219727 466.399475,1160.134277 466.047302,1162.048584 
	C467.681458,1162.081787 469.445648,1162.523071 470.919525,1162.053467 
	C473.189484,1161.330444 475.334229,1160.100220 477.368164,1158.823975 
	C477.935822,1158.467651 478.308411,1157.061035 478.072815,1156.376465 
	C477.847351,1155.721436 476.695099,1154.943359 475.984314,1154.975342 
	C473.236450,1155.099243 470.502228,1155.525513 467.103790,1156.305298 
M101.233162,1166.442871 
	C103.020943,1166.017944 104.808731,1165.593018 106.596497,1165.168091 
	C105.992058,1163.015137 105.387619,1160.862183 104.986916,1159.434814 
	C101.423279,1158.083862 98.933327,1162.190308 96.178482,1159.185425 
	C96.024017,1159.016968 94.246361,1159.756592 94.209641,1160.162598 
	C94.067284,1161.736572 94.196465,1163.373535 94.524162,1164.923584 
	C94.603142,1165.297241 96.377853,1165.749023 96.451889,1165.623169 
	C98.387444,1162.332642 99.117378,1166.560791 101.233162,1166.442871 
M184.564651,1260.529419 
	C186.094482,1260.055786 187.624313,1259.582275 189.180740,1259.100586 
	C190.031006,1259.910767 190.918167,1260.755981 191.805328,1261.601318 
	C192.147964,1261.134766 192.490585,1260.668091 192.833221,1260.201538 
	C189.682602,1258.217651 186.697006,1255.815918 183.293716,1254.472168 
	C182.069031,1253.988647 179.437134,1255.698120 178.224564,1257.085815 
	C177.533752,1257.876343 178.411774,1260.037842 178.583359,1261.581909 
	C180.351486,1261.335938 182.119629,1261.090088 184.564651,1260.529419 
M301.990234,1130.762817 
	C307.284607,1127.803467 303.325836,1125.446777 302.204254,1123.473877 
	C298.908478,1123.783569 296.280914,1124.030518 293.065674,1124.332642 
	C292.562897,1125.501465 291.756653,1127.375854 291.220551,1128.622314 
	C294.884277,1129.446533 298.055298,1130.159912 301.990234,1130.762817 
M151.450043,1080.059448 
	C151.785294,1082.184937 152.120544,1084.310303 152.455795,1086.435791 
	C152.735306,1086.378296 153.014832,1086.320679 153.294357,1086.263184 
	C153.637909,1084.931519 153.981476,1083.599854 154.300339,1082.363892 
	C157.864487,1082.373413 155.116425,1090.620850 160.557220,1086.876221 
	C161.789642,1086.027954 161.118973,1082.414551 161.333694,1079.967041 
	C157.423813,1079.967041 154.836349,1079.967041 151.450043,1080.059448 
M225.581879,1197.541870 
	C228.296448,1197.807983 231.011017,1198.073975 233.712173,1198.338867 
	C233.712173,1195.991577 233.712173,1193.919312 233.712173,1190.794067 
	C232.536880,1192.175537 231.986267,1192.822754 231.836746,1192.998413 
	C229.757629,1192.284058 227.887115,1191.641357 225.386154,1190.781982 
	C225.386154,1193.555664 225.386154,1195.182373 225.581879,1197.541870 
M394.582581,1155.863403 
	C392.208099,1156.601440 389.616333,1157.326050 390.519501,1160.632202 
	C390.846466,1161.829224 392.432343,1163.792969 392.875610,1163.649658 
	C394.541382,1163.110962 396.832611,1162.115234 397.248688,1160.779663 
	C397.659180,1159.462036 396.083191,1157.525513 394.582581,1155.863403 
M320.433258,1111.970337 
	C319.540985,1113.900635 318.648743,1115.830811 317.254913,1118.846191 
	C320.147461,1118.386719 321.233582,1118.099121 322.326172,1118.071899 
	C323.497101,1118.042603 324.674194,1118.258301 326.130310,1118.393188 
	C326.130310,1117.131348 326.168549,1116.165649 326.119598,1115.204224 
	C326.070343,1114.236694 325.934418,1113.273682 325.906982,1113.003540 
	C324.078766,1112.538452 322.656250,1112.176514 320.433258,1111.970337 
M293.272278,1080.676025 
	C293.602112,1082.430542 293.931946,1084.185181 294.261749,1085.939575 
	C295.617767,1085.601074 297.738953,1085.661987 298.168152,1084.839722 
	C299.021942,1083.204102 298.944672,1081.082520 299.252075,1079.161743 
	C297.387390,1079.463501 295.522736,1079.765259 293.272278,1080.676025 
M402.919678,1150.403809 
	C404.774231,1150.403809 406.628815,1150.403809 408.377441,1150.403809 
	C408.377441,1148.737427 408.377441,1146.725220 408.377441,1144.087891 
	C406.729523,1145.370239 405.634186,1146.222534 404.634033,1147.000854 
	C403.427032,1146.122803 402.467102,1145.424438 401.380798,1144.634155 
	C401.140869,1146.806641 400.875946,1148.536133 400.847137,1150.269653 
	C400.843292,1150.499878 402.039551,1150.750000 402.919678,1150.403809 
M145.611389,1085.554565 
	C147.051987,1085.839478 148.492599,1086.124268 150.641571,1086.549316 
	C149.145645,1083.824219 148.004410,1081.745361 146.297333,1078.635742 
	C144.899597,1081.624512 144.014587,1083.516846 143.129532,1085.409180 
	C143.738327,1085.580200 144.347107,1085.751221 145.611389,1085.554565 
M151.139542,1120.438721 
	C150.788635,1120.036133 150.452271,1119.617676 150.060196,1119.260254 
	C150.027710,1119.230591 149.618347,1119.614380 149.384094,1119.806030 
	C149.790222,1120.159912 150.196350,1120.513672 151.139542,1120.438721 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2312.449219,1289.294434 
	C2317.573975,1289.059204 2322.696777,1288.676392 2327.823486,1288.614990 
	C2347.424316,1288.379883 2367.027344,1288.325195 2386.627686,1288.063354 
	C2441.532959,1287.330078 2496.437256,1286.527344 2551.670898,1285.375977 
	C2552.000000,1286.066650 2552.000000,1287.133423 2551.575684,1288.393555 
	C2513.111084,1289.127075 2475.070557,1289.668457 2437.030029,1290.206787 
	C2425.235352,1290.373901 2413.440186,1290.501221 2401.647217,1290.744019 
	C2400.421387,1290.769165 2397.608398,1289.620605 2399.000000,1293.000000 
	C2394.643066,1293.000000 2390.286133,1293.000000 2385.637695,1292.489746 
	C2383.761963,1291.682983 2382.176514,1291.124146 2380.593262,1291.129883 
	C2359.788574,1291.204102 2338.984375,1291.373535 2318.179932,1291.465942 
	C2316.216553,1291.474731 2314.251953,1291.125000 2312.349121,1290.669556 
	C2312.423340,1290.029785 2312.436279,1289.662109 2312.449219,1289.294434 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M3.273514,1060.278809 
	C3.269509,1076.411011 3.117028,1092.544922 3.287882,1108.675293 
	C3.936368,1169.898804 4.689475,1231.121338 5.701731,1292.672119 
	C5.250000,1293.000000 4.500000,1293.000000 3.260627,1292.543701 
	C2.464639,1275.741699 2.158024,1259.396118 1.957950,1242.588867 
	C1.965234,1223.819824 1.865975,1205.512573 1.854696,1186.729004 
	C1.888108,1171.434937 1.852147,1156.617065 1.745791,1141.799561 
	C1.736687,1140.531250 1.259658,1139.266479 1.000000,1138.000000 
	C1.000000,1106.979126 1.000000,1075.958130 1.000001,1044.468628 
	C1.615856,1044.572998 2.734906,1045.119873 2.766280,1045.723267 
	C2.977241,1049.780640 2.957258,1053.850098 3.112359,1058.731445 
	C3.232912,1059.791016 3.253213,1060.034912 3.273514,1060.278809 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M511.333313,1293.000000 
	C510.632690,1259.572876 510.242401,1226.145996 509.904236,1192.718506 
	C509.527161,1155.439697 509.202057,1118.160278 508.840118,1080.881226 
	C508.803345,1077.091919 508.669373,1073.303711 509.129456,1069.223633 
	C509.996765,1068.902954 510.315887,1068.887939 510.635681,1068.887451 
	C510.783630,1088.526733 510.894775,1108.166504 511.087860,1127.805298 
	C511.405243,1160.093140 511.750122,1192.380615 512.132446,1224.667725 
	C512.398438,1247.133789 512.746582,1269.598633 513.028992,1292.531982 
	C512.555542,1293.000000 512.111084,1293.000000 511.333313,1293.000000 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2161.013184,1289.912598 
	C2202.237793,1289.592163 2243.462158,1289.275757 2284.686768,1288.947876 
	C2292.171631,1288.888306 2299.656006,1288.782349 2307.697754,1289.111206 
	C2307.480225,1290.150269 2306.721924,1291.294189 2305.928223,1291.319214 
	C2300.120117,1291.502930 2294.304688,1291.442749 2288.491943,1291.495605 
	C2265.677490,1291.703247 2242.862793,1291.924072 2219.688477,1292.053955 
	C2202.918457,1292.042236 2186.507812,1292.116455 2169.625488,1292.129272 
	C2158.029785,1292.091919 2146.905518,1292.058960 2135.782959,1292.178711 
	C2132.852539,1292.210205 2129.927490,1292.713379 2127.000000,1293.000000 
	C2110.979248,1293.000000 2094.958252,1293.000000 2078.397461,1292.639648 
	C2077.908203,1291.792969 2077.959229,1291.306641 2078.010254,1290.820190 
	C2093.372070,1290.594116 2108.734131,1290.368164 2124.906250,1290.389648 
	C2134.438965,1290.679199 2143.162598,1290.796509 2151.884277,1290.721924 
	C2154.929443,1290.695923 2157.970215,1290.196045 2161.013184,1289.912598 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M2399.468750,1293.000000 
	C2397.608398,1289.620605 2400.421387,1290.769165 2401.647217,1290.744019 
	C2413.440186,1290.501221 2425.235352,1290.373901 2437.030029,1290.206787 
	C2475.070557,1289.668457 2513.111084,1289.127075 2551.575684,1288.793457 
	C2552.000000,1290.329590 2552.000000,1291.659180 2552.000000,1292.994385 
	C2501.312500,1293.000000 2450.625000,1293.000000 2399.468750,1293.000000 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2220.048340,1292.140015 
	C2242.862793,1291.924072 2265.677490,1291.703247 2288.491943,1291.495605 
	C2294.304688,1291.442749 2300.120117,1291.502930 2305.928223,1291.319214 
	C2306.721924,1291.294189 2307.480225,1290.150269 2308.171875,1289.102783 
	C2309.327881,1288.786865 2310.566650,1288.892822 2312.127441,1289.146484 
	C2312.436279,1289.662109 2312.423340,1290.029785 2312.060059,1290.614746 
	C2310.806396,1291.554565 2309.903076,1292.277344 2309.000000,1293.000000 
	C2279.645752,1293.000000 2250.291748,1293.000000 2220.477539,1292.784912 
	C2220.017578,1292.569702 2220.048340,1292.140015 2220.048340,1292.140015 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M2309.468750,1293.000000 
	C2309.903076,1292.277344 2310.806396,1291.554565 2311.998535,1290.886719 
	C2314.251953,1291.125000 2316.216553,1291.474731 2318.179932,1291.465942 
	C2338.984375,1291.373535 2359.788574,1291.204102 2380.593262,1291.129883 
	C2382.176514,1291.124146 2383.761963,1291.682983 2385.173340,1292.489746 
	C2359.979248,1293.000000 2334.958252,1293.000000 2309.468750,1293.000000 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1.851407,1243.050415 
	C2.158024,1259.396118 2.464639,1275.741699 2.885627,1292.543701 
	C-0.025776,1293.609497 1.036132,1291.313110 1.032044,1290.042358 
	C0.982598,1274.674072 1.000000,1259.305664 1.210708,1243.517334 
	C1.421415,1243.097534 1.851406,1243.050415 1.851407,1243.050415 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2025.468628,1293.000000 
	C2023.691650,1289.731201 2026.527588,1290.895386 2027.576416,1290.869385 
	C2034.466187,1290.698120 2041.360840,1290.727417 2048.592529,1291.169800 
	C2048.954102,1292.098999 2048.977051,1292.549438 2049.000000,1293.000000 
	C2041.312378,1293.000000 2033.624878,1293.000000 2025.468628,1293.000000 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1.957949,1242.588867 
	C1.851406,1243.050415 1.421415,1243.097534 1.210708,1243.048828 
	C1.000000,1224.645752 1.000000,1206.291504 1.187281,1187.536377 
	C1.374562,1187.135376 1.766717,1187.205200 1.766717,1187.205200 
	C1.865975,1205.512573 1.965234,1223.819824 1.957949,1242.588867 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2219.688477,1292.053955 
	C2220.048340,1292.140015 2220.017578,1292.569702 2220.008789,1292.784912 
	C2203.645752,1293.000000 2187.291748,1293.000000 2170.467529,1292.794678 
	C2169.997559,1292.589233 2170.097412,1292.190796 2170.097412,1292.190674 
	C2186.507812,1292.116455 2202.918457,1292.042236 2219.688477,1292.053955 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1.854696,1186.729004 
	C1.766717,1187.205200 1.374562,1187.135376 1.187281,1187.067627 
	C1.000000,1170.979126 1.000000,1154.958130 1.000000,1138.468628 
	C1.259658,1139.266479 1.736687,1140.531250 1.745791,1141.799561 
	C1.852147,1156.617065 1.888108,1171.434937 1.854696,1186.729004 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2169.625488,1292.129395 
	C2170.097412,1292.190796 2169.997559,1292.589233 2169.998779,1292.794678 
	C2155.979248,1293.000000 2141.958252,1293.000000 2127.468750,1293.000000 
	C2129.927490,1292.713379 2132.852539,1292.210205 2135.782959,1292.178711 
	C2146.905518,1292.058960 2158.029785,1292.091919 2169.625488,1292.129395 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M3.311452,1025.165039 
	C3.035003,1027.584717 2.776151,1030.006470 2.476157,1032.423218 
	C2.262764,1034.142212 1.993940,1035.854370 1.374926,1037.284790 
	C1.000000,1032.975464 1.000000,1028.950928 1.000000,1024.463135 
	C1.404736,1023.941956 1.809471,1023.883911 2.553586,1024.157471 
	C2.876070,1024.833374 3.015566,1025.058594 3.311452,1025.165039 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1.000002,1004.463196 
	C1.260435,1008.437012 1.520867,1012.873962 1.390650,1017.155518 
	C1.000000,1012.975464 1.000000,1008.950928 1.000002,1004.463196 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2049.457275,1293.000000 
	C2048.977051,1292.549438 2048.954102,1292.098999 2048.967285,1291.311523 
	C2052.361084,1290.910889 2055.718750,1290.847046 2059.582520,1291.175415 
	C2060.059326,1292.045166 2060.029541,1292.522583 2060.000000,1293.000000 
	C2056.638184,1293.000000 2053.276367,1293.000000 2049.457275,1293.000000 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2068.008545,1290.876709 
	C2070.043945,1290.829712 2072.079590,1290.782715 2074.610840,1291.140137 
	C2075.071289,1292.029663 2075.035645,1292.514893 2075.000000,1293.000000 
	C2072.958252,1293.000000 2070.916748,1293.000000 2068.358887,1292.650024 
	C2067.897949,1291.825562 2067.953369,1291.351196 2068.008545,1290.876709 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2008.947632,1291.318481 
	C2011.548584,1291.645386 2014.149658,1291.972412 2016.375244,1292.649658 
	C2014.285645,1293.000000 2012.571289,1293.000000 2010.498779,1292.729492 
	C2009.743042,1292.078857 2009.345337,1291.698608 2008.947632,1291.318481 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2075.375000,1293.000000 
	C2075.035645,1292.514893 2075.071289,1292.029663 2075.045654,1291.182373 
	C2075.853760,1290.695435 2076.723145,1290.570435 2077.801514,1290.632812 
	C2077.959229,1291.306641 2077.908203,1291.792969 2077.928711,1292.639648 
	C2077.250000,1293.000000 2076.500000,1293.000000 2075.375000,1293.000000 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2062.011963,1290.870117 
	C2063.074707,1290.836548 2064.137451,1290.802856 2065.645264,1291.181641 
	C2066.060303,1292.062744 2066.030029,1292.531372 2066.000000,1293.000000 
	C2064.933350,1293.000000 2063.866455,1293.000000 2062.350586,1292.645996 
	C2061.937988,1291.817993 2061.974854,1291.344116 2062.011963,1290.870117 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1962.399902,1293.000000 
	C1962.370605,1290.587769 1963.571167,1290.415527 1966.314697,1292.667969 
	C1964.933350,1293.000000 1963.866577,1293.000000 1962.399902,1293.000000 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2008.524902,1291.266357 
	C2009.345337,1291.698608 2009.743042,1292.078857 2010.070312,1292.729492 
	C2008.933350,1293.000000 2007.866577,1293.000000 2006.399902,1293.000000 
	C2006.700806,1292.404785 2007.401489,1291.809448 2008.524902,1291.266357 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2061.841797,1290.715942 
	C2061.974854,1291.344116 2061.937988,1291.817993 2061.950684,1292.645996 
	C2061.555420,1293.000000 2061.111084,1293.000000 2060.333008,1293.000000 
	C2060.029541,1292.522583 2060.059326,1292.045166 2060.037842,1291.211792 
	C2060.548096,1290.757812 2061.109863,1290.659790 2061.841797,1290.715942 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2066.333008,1293.000000 
	C2066.030029,1292.531372 2066.060303,1292.062744 2066.040283,1291.241699 
	C2066.552002,1290.778687 2067.114014,1290.667969 2067.842285,1290.717041 
	C2067.953369,1291.351196 2067.897949,1291.825562 2067.921387,1292.650024 
	C2067.555420,1293.000000 2067.111084,1293.000000 2066.333008,1293.000000 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1977.375000,1293.000000 
	C1976.995605,1292.532227 1976.991211,1292.064575 1976.986694,1291.596802 
	C1978.201294,1291.858643 1979.415771,1292.120483 1980.315186,1292.691162 
	C1979.250000,1293.000000 1978.500000,1293.000000 1977.375000,1293.000000 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1969.333252,1293.000000 
	C1968.973755,1292.502686 1968.947510,1292.005493 1968.921265,1291.508179 
	C1969.837524,1291.800781 1970.753906,1292.093262 1971.335083,1292.692871 
	C1970.555542,1293.000000 1970.111084,1293.000000 1969.333252,1293.000000 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1999.250000,1293.000000 
	C1999.042480,1292.634888 1999.085083,1292.269653 1999.127686,1291.904541 
	C1999.501709,1291.985962 1999.875488,1292.067383 2000.124756,1292.574463 
	C2000.000000,1293.000000 1999.500000,1293.000000 1999.250000,1293.000000 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1710.023193,960.528564 
	C1710.023193,960.528564 1710.326416,960.823303 1710.591064,961.218201 
	C1712.439331,963.832153 1714.023193,966.051147 1715.928467,968.720642 
	C1723.720947,959.231750 1733.140503,955.616394 1744.512939,955.780334 
	C1756.672607,955.955566 1764.189331,963.121765 1771.137085,972.895569 
	C1775.222168,965.237061 1780.877075,961.158325 1788.769287,959.875610 
	C1797.251953,958.496887 1803.400635,962.321411 1809.626099,968.640198 
	C1811.713745,966.197998 1813.547485,964.065186 1815.367676,961.920776 
	C1824.670166,950.961670 1842.257568,950.933472 1851.018433,960.012573 
	C1853.859985,962.957397 1856.233765,963.173523 1860.001343,961.112183 
	C1872.144531,954.468384 1883.107422,957.862183 1890.060303,969.704956 
	C1890.855591,971.059326 1892.110596,972.258423 1893.397827,973.190552 
	C1901.609619,979.136658 1903.263550,990.126099 1897.409302,998.385986 
	C1896.299316,999.952148 1895.638062,1002.644958 1896.148315,1004.422424 
	C1900.066162,1018.072632 1894.032593,1029.668823 1880.575439,1032.868774 
	C1878.933105,1033.259277 1877.488281,1035.216919 1876.324829,1036.734253 
	C1873.774780,1040.059937 1872.138428,1044.521484 1868.914795,1046.805176 
	C1862.222046,1051.546631 1854.617432,1050.959351 1847.052124,1046.998657 
	C1841.960449,1061.263062 1832.176147,1061.189575 1821.114014,1055.860962 
	C1814.685181,1066.197144 1806.042603,1072.555298 1794.350952,1073.607300 
	C1782.547852,1074.669434 1772.491699,1070.680786 1764.757080,1060.092163 
	C1760.045654,1066.879883 1754.371094,1071.459717 1746.595337,1071.766846 
	C1738.747192,1072.076660 1732.183472,1068.710693 1726.759277,1062.292480 
	C1719.926514,1072.805420 1710.536255,1078.277710 1698.801147,1078.816406 
	C1686.662109,1079.373657 1677.332886,1073.377441 1670.130127,1063.653442 
	C1656.415039,1079.464111 1642.888062,1078.651367 1629.560791,1064.775391 
	C1621.136963,1071.422607 1621.134766,1071.422607 1610.596069,1065.160400 
	C1599.878052,1075.667480 1579.889893,1075.934814 1570.907715,1059.134521 
	C1570.398193,1058.181519 1569.010864,1057.361328 1567.894165,1057.121338 
	C1556.734497,1054.724121 1552.807373,1048.851074 1554.454102,1037.422729 
	C1554.642090,1036.118896 1553.203491,1034.192139 1552.001831,1033.152100 
	C1548.842651,1030.418335 1544.686646,1028.563965 1542.215210,1025.364624 
	C1537.098999,1018.742188 1539.197021,1011.599426 1542.878662,1004.494629 
	C1540.682007,1003.961426 1538.767578,1003.611572 1536.926392,1003.029724 
	C1529.454346,1000.668457 1523.441162,996.471191 1520.829834,988.762390 
	C1518.341675,981.416931 1519.796387,974.030029 1523.120972,967.496948 
	C1525.893555,962.048340 1530.344360,957.453735 1534.399170,952.390503 
	C1535.854248,951.907959 1536.961914,951.515930 1538.488403,951.104248 
	C1545.411133,951.290771 1552.095581,950.655212 1558.355957,951.997498 
	C1562.558350,952.898560 1565.213867,953.696899 1568.686401,950.168335 
	C1578.181152,940.520264 1591.996094,940.586731 1601.589478,950.066589 
	C1604.508301,952.950806 1606.557983,953.517334 1609.846191,950.645081 
	C1618.651001,942.954102 1628.808105,945.835815 1634.971924,952.815308 
	C1636.675171,954.743835 1638.054688,955.507812 1640.820801,953.777710 
	C1648.282593,949.110779 1656.143311,949.209351 1663.152710,954.619141 
	C1666.656494,957.323242 1669.183105,961.293335 1673.083252,965.757202 
	C1673.962891,964.402893 1674.669922,962.814941 1675.807983,961.647095 
	C1681.448730,955.858948 1688.589233,953.717957 1696.279419,955.164429 
	C1701.028931,956.057861 1705.453003,958.681580 1710.023193,960.528564 
M1612.648804,1034.346069 
	C1613.784302,1033.382080 1614.919922,1032.418091 1616.092896,1031.490234 
	C1616.092896,1031.490234 1616.097900,1031.507568 1616.900024,1031.816772 
	C1617.489990,1031.798218 1618.586914,1031.701904 1618.597534,1031.772095 
	C1619.222778,1035.847046 1621.683472,1034.411011 1624.996460,1033.744141 
	C1623.131714,1032.703735 1622.137573,1032.149048 1621.588013,1031.842407 
	C1622.488403,1030.314331 1623.412476,1028.745850 1624.714722,1026.535889 
	C1619.613525,1026.769775 1615.869019,1026.941528 1611.481934,1027.029663 
	C1610.328857,1026.955933 1609.175903,1026.882080 1607.297974,1026.841919 
	C1606.308228,1027.888672 1605.318481,1028.935547 1604.296753,1030.016235 
	C1601.156494,1026.958008 1597.920166,1025.343750 1594.070312,1028.864624 
	C1593.920044,1029.002075 1592.842529,1028.125488 1591.590210,1027.099609 
	C1589.800537,1027.140869 1588.010864,1027.220825 1586.221313,1027.216797 
	C1579.196899,1027.200684 1579.196899,1027.190796 1580.595337,1033.853271 
	C1582.158936,1032.845703 1583.789795,1031.794678 1585.720215,1030.550781 
	C1586.178467,1032.076782 1586.447876,1032.973511 1586.556152,1033.334106 
	C1588.914185,1033.583252 1590.956665,1033.799072 1593.657471,1034.344238 
	C1596.329468,1033.806641 1599.001343,1033.269165 1601.673340,1032.731567 
	C1601.631348,1032.225830 1601.589478,1031.720215 1601.547607,1031.214478 
	C1602.473022,1032.350464 1603.398438,1033.486450 1604.644043,1035.015259 
	C1605.963745,1032.689087 1606.999634,1030.863281 1608.742188,1029.101440 
	C1609.155884,1030.902588 1609.569702,1032.703857 1610.014038,1034.638184 
	C1610.494629,1034.492310 1611.109009,1034.305908 1612.648804,1034.346069 
M1647.987305,1041.554810 
	C1647.948120,1041.376221 1647.908813,1041.197754 1647.998169,1040.240479 
	C1646.088867,1035.007935 1642.653564,1041.309814 1640.464233,1038.704956 
	C1640.194824,1041.067505 1639.987061,1042.888550 1639.826660,1044.294556 
	C1642.890137,1044.480469 1645.972290,1044.667603 1649.534424,1045.321167 
	C1652.862427,1043.448608 1656.190552,1041.575928 1659.518555,1039.703369 
	C1659.178101,1039.068848 1658.837769,1038.434326 1658.497437,1037.799805 
	C1657.340332,1038.437378 1656.183350,1039.074951 1655.251709,1039.588379 
	C1654.033813,1038.998169 1651.934570,1037.495972 1651.516235,1037.870605 
	C1650.339111,1038.924927 1649.853760,1040.751587 1648.498291,1042.377441 
	C1648.318237,1042.285889 1648.138062,1042.194214 1647.987305,1041.554810 
M1581.312378,1005.216370 
	C1579.412231,1005.662415 1577.512207,1006.108459 1575.265625,1006.635742 
	C1575.354614,1007.582764 1575.521118,1009.354797 1575.650024,1010.726257 
	C1577.719727,1010.580444 1579.637207,1010.445312 1582.033325,1010.276489 
	C1582.033325,1009.679932 1582.033325,1008.374451 1582.761597,1006.855103 
	C1584.829834,1011.034424 1588.708618,1011.285278 1592.063477,1011.686035 
	C1591.563599,1009.629456 1591.094482,1007.699890 1590.561646,1005.507629 
	C1587.836792,1005.507629 1584.893188,1005.507629 1581.312378,1005.216370 
M1846.577637,1030.199463 
	C1847.054199,1030.478516 1847.530762,1030.757568 1848.391968,1031.629761 
	C1853.848267,1033.694946 1854.633057,1032.661011 1853.525757,1024.945679 
	C1851.392334,1024.945679 1849.271606,1024.945679 1846.777344,1024.510132 
	C1846.228760,1024.399048 1845.680298,1024.287964 1844.187012,1024.006958 
	C1836.894897,1024.001587 1829.578979,1023.515869 1822.511841,1025.205811 
	C1821.690063,1026.855225 1820.868286,1028.504761 1819.820801,1030.606934 
	C1823.301758,1030.606934 1826.514282,1030.606934 1830.360718,1030.606934 
	C1831.407715,1029.969971 1833.200439,1028.879028 1835.427002,1027.524292 
	C1835.599609,1029.515381 1835.703979,1030.718750 1835.911377,1033.112061 
	C1839.127808,1031.641357 1841.676636,1030.476074 1845.054321,1029.780273 
	C1845.546143,1029.542236 1846.037964,1029.304199 1846.496216,1029.337891 
	C1846.496216,1029.337891 1846.705566,1029.515259 1846.705566,1029.515259 
	C1846.705566,1029.515259 1846.966919,1029.432983 1846.577637,1030.199463 
M1609.479004,1007.489624 
	C1611.068726,1009.327087 1612.431519,1011.491638 1614.365234,1012.833557 
	C1615.091187,1013.337402 1617.143188,1011.930908 1619.597534,1011.022827 
	C1617.312500,1010.193115 1616.386597,1009.856934 1616.135864,1009.765930 
	C1617.128540,1007.996643 1618.003906,1006.436279 1618.879272,1004.875916 
	C1618.490356,1004.392456 1618.101318,1003.908997 1617.712402,1003.425537 
	C1614.917236,1004.430237 1612.122070,1005.434937 1608.909912,1006.238037 
	C1608.775757,1006.308960 1608.641602,1006.379883 1608.507446,1006.450745 
	C1608.756714,1006.562561 1609.005981,1006.674377 1609.479004,1007.489624 
M1606.298340,1011.821838 
	C1606.606079,1011.759949 1606.913818,1011.697998 1607.221558,1011.636047 
	C1606.859497,1011.390747 1606.497559,1011.145447 1606.194458,1010.210693 
	C1606.194458,1008.372314 1606.194458,1006.533936 1606.194458,1004.071350 
	C1604.544922,1005.209045 1603.802734,1005.720947 1603.060547,1006.232727 
	C1602.238647,1002.882019 1599.784912,1003.888306 1598.802734,1005.388428 
	C1597.863892,1006.822205 1597.947510,1009.315552 1598.451294,1011.097107 
	C1598.633057,1011.740112 1601.333374,1011.750732 1602.898804,1011.892639 
	C1603.853882,1011.979248 1604.836182,1011.766052 1606.298340,1011.821838 
M1607.413330,1000.616943 
	C1609.797852,1000.144409 1612.182373,999.671936 1613.669312,999.377258 
	C1615.868042,1000.055725 1617.589355,1000.586853 1619.739502,1001.250305 
	C1619.739502,997.903076 1619.739502,995.673157 1619.739502,993.049011 
	C1615.449585,993.451538 1611.326172,993.838440 1606.575439,994.084412 
	C1606.361328,994.076477 1606.147217,994.068542 1605.176880,993.908020 
	C1603.481812,994.826355 1601.786743,995.744629 1600.638428,996.366760 
	C1598.380981,995.314758 1595.740723,993.095703 1594.552124,993.705627 
	C1589.984985,996.049194 1585.923218,992.891724 1581.532837,993.361389 
	C1575.603882,993.995667 1575.188721,993.907715 1576.893921,999.970093 
	C1578.432739,999.309082 1580.070923,997.947632 1581.497925,998.141418 
	C1583.831177,998.458252 1585.959351,1000.079590 1588.296875,1000.619812 
	C1591.581543,1001.378967 1594.983276,1001.684753 1596.955933,997.877625 
	C1597.051392,997.693298 1597.443726,997.662964 1597.687622,997.565063 
	C1598.638306,998.675354 1599.563599,999.755981 1600.983276,1001.414124 
	C1602.175659,997.342957 1604.080200,998.331787 1606.503662,1000.062317 
	C1606.503662,1000.062317 1606.943726,1000.044678 1607.413330,1000.616943 
M1704.362915,1031.739868 
	C1703.285522,1030.639160 1701.841675,1029.716064 1701.204224,1028.401855 
	C1699.712646,1025.326782 1694.410156,1024.994995 1692.000000,1027.998779 
	C1692.000000,1027.998779 1692.012329,1027.983032 1691.262451,1028.019043 
	C1690.547607,1028.508911 1689.832764,1028.998779 1689.118042,1029.488647 
	C1690.380737,1030.906738 1691.552612,1033.422119 1692.923096,1033.535522 
	C1696.906250,1033.864624 1700.968994,1033.231201 1705.665649,1033.245483 
	C1707.423828,1032.931152 1710.644165,1032.757935 1710.690308,1032.278442 
	C1710.905151,1030.053101 1710.239624,1027.742798 1709.942505,1025.808716 
	C1703.602051,1025.325928 1705.947754,1029.675537 1704.362915,1031.739868 
M1729.261597,1026.199829 
	C1726.960571,1026.386597 1724.659668,1026.573364 1722.743042,1026.729004 
	C1720.526123,1026.227905 1718.461060,1025.761108 1716.213867,1025.253174 
	C1716.213867,1028.244995 1716.213867,1030.404907 1716.213867,1031.112671 
	C1718.301147,1031.112671 1720.490723,1030.442261 1721.385132,1031.234375 
	C1724.457642,1033.955200 1728.952271,1033.896240 1732.471313,1030.926270 
	C1733.550537,1031.466919 1734.629761,1032.007690 1735.709106,1032.548340 
	C1733.669189,1030.453857 1737.743286,1022.573669 1729.261597,1026.199829 
M1681.703857,999.824036 
	C1683.921753,999.754700 1686.162842,999.859253 1688.350952,999.566895 
	C1690.511597,999.278198 1692.618408,998.588135 1694.748901,998.074158 
	C1694.008057,995.961792 1693.267334,993.849365 1692.394165,991.359436 
	C1690.162598,992.785645 1688.817017,993.645630 1687.471313,994.505676 
	C1687.448853,994.010986 1687.426270,993.516296 1687.403687,993.021606 
	C1685.265137,993.021606 1683.126709,993.021606 1680.142456,992.879150 
	C1678.381836,993.054871 1676.163696,991.880798 1675.765259,995.278870 
	C1675.629639,996.435425 1673.260498,997.330139 1671.911865,998.344482 
	C1671.342773,997.642883 1670.773804,996.941345 1670.204834,996.239807 
	C1671.868408,995.590149 1673.531982,994.940552 1675.195557,994.290894 
	C1674.674072,993.674011 1674.152588,993.057129 1673.631104,992.440247 
	C1672.083862,993.591003 1670.597534,994.838501 1668.971924,995.864868 
	C1667.370361,996.875977 1665.627441,997.663086 1663.946411,998.548401 
	C1663.713135,998.138428 1663.479858,997.728516 1663.246582,997.318604 
	C1664.563965,996.131592 1665.881348,994.944519 1667.198608,993.757507 
	C1666.940552,993.395447 1666.682617,993.033386 1666.424561,992.671326 
	C1664.454224,993.785889 1662.483887,994.900391 1660.525879,996.007996 
	C1659.538574,994.932983 1658.564697,993.872498 1657.227661,992.416687 
	C1655.994995,994.927551 1654.959717,997.036072 1654.041748,998.905884 
	C1656.904175,999.241272 1659.316772,999.612671 1661.743652,999.787659 
	C1665.386108,1000.050354 1669.037598,1000.290771 1672.687134,1000.320618 
	C1675.435913,1000.343140 1678.187378,1000.020813 1681.703857,999.824036 
M1723.960327,997.631165 
	C1725.156128,998.079773 1726.352051,998.528320 1727.866699,999.096497 
	C1727.176392,995.348206 1729.250366,989.644836 1721.176392,992.670593 
	C1713.817627,992.670593 1706.458862,992.670593 1698.935669,992.670593 
	C1699.081543,995.649353 1699.176147,997.580322 1699.269775,999.493347 
	C1700.028076,999.705933 1700.552612,1000.048462 1700.938477,999.931885 
	C1705.985352,998.407227 1711.020142,999.656494 1716.071045,999.731384 
	C1718.494141,999.767273 1720.941284,998.175842 1723.960327,997.631165 
M1812.329102,996.063721 
	C1810.089966,994.507629 1807.937744,991.833618 1805.591675,991.651001 
	C1801.841309,991.359009 1797.962158,992.721313 1795.850342,993.087341 
	C1791.728149,992.428589 1788.790771,991.280945 1786.272095,991.883728 
	C1785.235596,992.131836 1785.100586,996.146851 1784.655640,998.025513 
	C1794.905273,998.093445 1803.966553,998.153503 1813.534302,998.546265 
	C1816.053589,996.742004 1821.233032,997.404968 1819.896851,992.489380 
	C1816.879150,992.192871 1814.185791,991.928223 1811.800293,991.693787 
	C1812.338013,993.897766 1812.651611,995.183105 1812.329102,996.063721 
M1794.193359,1024.997437 
	C1793.573242,1025.217651 1792.866821,1025.752808 1792.346191,1025.610107 
	C1785.800171,1023.816956 1779.142578,1028.034912 1772.544922,1025.176636 
	C1771.496948,1024.722534 1768.763428,1025.653442 1768.462280,1026.519043 
	C1767.889038,1028.167236 1768.206055,1030.339233 1768.820801,1032.054321 
	C1769.002686,1032.561279 1771.685547,1032.785889 1772.770508,1032.240845 
	C1776.188232,1030.523682 1779.299927,1029.651978 1782.670898,1032.373169 
	C1783.322998,1032.899414 1785.218140,1032.800049 1785.890625,1032.227173 
	C1788.691895,1029.840454 1791.385132,1031.160889 1794.285889,1031.793457 
	C1796.743896,1032.329712 1799.349487,1032.188965 1801.889648,1032.347656 
	C1801.927979,1031.814575 1801.966187,1031.281494 1802.004517,1030.748413 
	C1800.992554,1030.509155 1799.980591,1030.270020 1798.911865,1029.319702 
	C1799.294067,1027.889771 1799.676147,1026.459839 1800.058228,1025.029907 
	C1798.354980,1025.065552 1796.651733,1025.101196 1794.193359,1024.997437 
M1857.020752,990.307922 
	C1850.879639,990.755920 1844.738525,991.203857 1838.444824,991.662964 
	C1838.250000,992.177551 1837.929810,993.023315 1837.539795,994.053162 
	C1836.230957,993.021057 1835.142944,992.163147 1834.076904,991.322510 
	C1833.111084,992.716003 1832.351685,993.811829 1831.174438,995.510498 
	C1830.642822,989.709290 1827.512207,990.593201 1824.204346,991.345459 
	C1824.204346,993.847900 1824.204346,996.056946 1824.204346,998.955627 
	C1831.614258,996.141602 1838.750000,995.510681 1846.564453,997.105042 
	C1847.777832,996.413818 1849.688232,995.325439 1851.677124,994.192322 
	C1853.340820,999.186890 1854.151489,999.504150 1858.292603,996.695496 
	C1856.714844,996.356750 1855.495850,996.095032 1854.980713,995.305908 
	C1856.495605,995.432800 1858.101074,995.280579 1859.508667,995.737732 
	C1864.131836,997.239136 1868.540894,1000.174927 1873.464111,997.235229 
	C1872.323608,994.984802 1871.105957,992.582031 1870.171997,990.739136 
	C1865.752075,990.739136 1861.787476,990.739136 1857.020752,990.307922 
M1642.129639,1029.109619 
	C1641.946167,1028.915039 1641.762817,1028.720459 1641.578613,1027.841553 
	C1639.518066,1028.188477 1637.457642,1028.535400 1634.412964,1029.047974 
	C1634.263428,1028.793945 1633.529175,1027.547363 1632.336670,1025.522461 
	C1630.789062,1028.904053 1629.757812,1031.157349 1628.551636,1033.792969 
	C1630.816772,1033.206909 1632.347412,1032.377563 1633.780884,1032.520874 
	C1638.166382,1032.959106 1642.496704,1034.132935 1646.881592,1034.350952 
	C1653.016968,1034.656006 1659.182495,1034.315063 1665.334839,1034.334351 
	C1668.267578,1034.343628 1669.979736,1032.158569 1668.901611,1029.459717 
	C1668.435791,1028.294067 1667.713135,1027.230835 1666.816284,1025.588013 
	C1665.125488,1026.582886 1663.557617,1027.505249 1661.989746,1028.427734 
	C1659.685425,1026.897583 1657.253662,1026.113525 1654.877563,1026.256104 
	C1653.375244,1026.346069 1651.877075,1027.710083 1650.520386,1028.699341 
	C1648.967773,1029.831421 1647.574097,1031.181641 1646.111450,1032.437012 
	C1645.713257,1031.938110 1645.315063,1031.439331 1644.916748,1030.940430 
	C1646.250488,1029.780640 1647.584229,1028.620728 1648.917969,1027.460938 
	C1648.678101,1027.074097 1648.438354,1026.687134 1648.198486,1026.300171 
	C1646.344727,1027.443726 1644.490967,1028.587280 1642.129639,1029.109619 
M1595.437744,971.818237 
	C1593.852173,969.964600 1592.266602,968.111023 1590.792358,966.387634 
	C1588.955688,966.285217 1587.191895,966.042908 1585.441528,966.116577 
	C1580.387451,966.329407 1575.327759,966.549377 1570.292236,967.008301 
	C1567.613647,967.252380 1564.980591,967.997009 1561.898315,968.599548 
	C1561.943359,969.261292 1562.075562,971.201965 1562.156250,972.386719 
	C1565.126709,973.140259 1567.813965,974.646362 1570.091309,974.174744 
	C1572.516724,973.672424 1574.554321,971.298279 1576.901123,969.642761 
	C1577.179565,970.614563 1577.577637,972.003601 1578.181152,974.109253 
	C1578.890381,971.780090 1579.333496,970.325134 1579.776611,968.870117 
	C1580.074951,968.947388 1580.373413,969.024719 1580.671753,969.102051 
	C1580.848755,970.695923 1581.025879,972.289795 1581.294800,974.711304 
	C1585.320557,972.639038 1590.331177,977.031372 1593.418457,971.502197 
	C1593.438354,971.466248 1594.604736,972.070374 1595.437744,971.818237 
M1639.483276,997.639160 
	C1640.602661,996.396790 1641.722046,995.154419 1643.567505,993.106384 
	C1640.516479,993.463989 1638.513794,993.698669 1636.264282,993.962341 
	C1635.874268,994.986816 1635.353149,996.355957 1634.831909,997.725098 
	C1634.400757,992.253906 1630.333740,992.272461 1627.476685,993.391663 
	C1625.850708,994.028687 1625.413818,997.700806 1624.416382,1000.065430 
	C1630.788940,1000.360474 1635.746582,1000.749451 1640.700195,1000.702209 
	C1641.872070,1000.690979 1643.029907,999.214355 1644.194214,998.411743 
	C1644.014038,998.097534 1643.833862,997.783325 1643.653809,997.469055 
	C1642.440186,997.681824 1641.226685,997.894592 1639.483276,997.639160 
M1751.426880,993.635132 
	C1751.711914,993.076477 1751.996948,992.517883 1752.356445,991.813660 
	C1746.688354,992.133362 1741.303589,992.437134 1735.177979,992.782654 
	C1735.026245,993.190552 1734.539429,994.499878 1734.401489,994.871033 
	C1736.619995,996.462280 1738.019409,998.215637 1739.654297,998.473022 
	C1742.524536,998.924988 1745.622559,998.831238 1748.451294,998.194458 
	C1749.551636,997.946716 1750.181396,995.608826 1751.426880,993.635132 
M1778.586914,998.913330 
	C1778.195190,997.963440 1777.803345,997.013489 1777.480835,996.231567 
	C1778.169434,995.150635 1779.174072,993.573730 1780.752563,991.095764 
	C1776.386597,991.095764 1773.548096,990.737854 1770.871948,991.241455 
	C1769.461548,991.506897 1768.334106,993.276184 1767.078979,994.366455 
	C1767.969604,995.297363 1768.699707,996.691162 1769.782837,997.066711 
	C1772.377686,997.966614 1775.138672,998.387634 1778.586914,998.913330 
M1756.625610,1025.875488 
	C1754.041260,1025.875488 1751.457031,1025.875488 1748.872681,1025.875488 
	C1748.698608,1026.619385 1748.524414,1027.363403 1748.350342,1028.107300 
	C1750.410278,1029.227173 1752.374878,1030.612427 1754.555542,1031.396851 
	C1757.042969,1032.291504 1759.723022,1032.650513 1762.697998,1033.328735 
	C1762.697998,1029.915405 1762.697998,1027.573364 1762.697998,1024.878662 
	C1761.099121,1025.017212 1759.707153,1025.137817 1758.223389,1025.266357 
	C1757.994629,1027.179810 1757.806763,1028.750122 1757.619019,1030.320312 
	C1757.389648,1030.308472 1757.160156,1030.296509 1756.930786,1030.284546 
	C1756.930786,1029.067017 1756.930786,1027.849365 1756.625610,1025.875488 
M1620.839966,1043.257446 
	C1620.693481,1041.518799 1620.547119,1039.780029 1620.468628,1038.848999 
	C1616.784668,1038.848999 1613.716553,1038.848999 1610.494751,1038.848999 
	C1610.494751,1041.971313 1610.494751,1044.309814 1610.494751,1047.658813 
	C1613.831055,1044.350342 1618.802124,1049.871338 1620.839966,1043.257446 
M1579.596436,1045.123779 
	C1584.870972,1044.755371 1590.153809,1042.326782 1595.446045,1044.964233 
	C1595.446045,1043.126709 1595.446045,1041.068237 1595.446045,1040.240601 
	C1593.042236,1040.240601 1590.943237,1040.360229 1588.867065,1040.194458 
	C1587.674072,1040.099243 1586.520508,1039.265015 1585.344727,1039.254761 
	C1583.495361,1039.238647 1581.642212,1039.636841 1579.305664,1039.917969 
	C1579.305664,1040.947998 1579.305664,1042.716309 1579.596436,1045.123779 
M1600.530518,1045.373657 
	C1603.044067,1045.373657 1605.557617,1045.373657 1608.142090,1045.373657 
	C1608.142090,1042.829590 1608.142090,1040.759888 1608.142090,1037.634399 
	C1606.876343,1039.224609 1606.275024,1039.979980 1606.176392,1040.103882 
	C1604.577393,1039.349976 1602.749146,1037.997070 1602.280151,1038.367065 
	C1601.245117,1039.183594 1600.781738,1040.829590 1600.311279,1042.214111 
	C1600.062866,1042.944946 1600.289307,1043.837036 1600.530518,1045.373657 
M1633.013306,1038.217407 
	C1631.292236,1039.117676 1629.571045,1040.017944 1627.775024,1040.957275 
	C1627.697876,1040.757080 1627.333862,1039.811768 1626.969849,1038.866455 
	C1626.545776,1039.011108 1626.121704,1039.155884 1625.697632,1039.300659 
	C1626.442993,1041.234985 1626.681152,1043.885864 1628.089966,1044.882690 
	C1629.270630,1045.718140 1632.354004,1045.237671 1633.575684,1044.171631 
	C1634.425903,1043.429688 1633.550171,1040.710327 1633.013306,1038.217407 
M1628.782471,1004.148987 
	C1627.343384,1004.518311 1625.904175,1004.887634 1623.786133,1005.431152 
	C1623.875366,1007.034180 1623.996216,1009.204407 1624.111694,1011.280212 
	C1625.475342,1011.545715 1626.333008,1011.959778 1627.072998,1011.806396 
	C1628.715942,1011.465698 1630.300049,1010.840515 1631.908081,1010.331238 
	C1631.097534,1008.281860 1630.286865,1006.232544 1628.782471,1004.148987 
M1806.853516,1027.382690 
	C1806.853516,1028.958252 1806.853516,1030.533813 1806.853516,1032.109375 
	C1807.356812,1032.453125 1807.859985,1032.796753 1808.363281,1033.140503 
	C1810.301392,1031.335815 1812.328247,1029.608643 1814.060547,1027.624268 
	C1814.267212,1027.387451 1812.764038,1024.859619 1812.234131,1024.922241 
	C1810.365601,1025.143066 1808.575684,1026.029663 1806.853516,1027.382690 
M1673.720825,1030.815186 
	C1675.583862,1035.542603 1676.456909,1028.777100 1678.056152,1030.441772 
	C1678.656616,1029.405762 1679.257080,1028.369629 1679.857544,1027.333618 
	C1678.722290,1026.895874 1677.610596,1026.172852 1676.447021,1026.076172 
	C1673.443237,1025.827026 1673.480713,1027.963501 1673.720825,1030.815186 
M1565.374023,1032.051147 
	C1564.518188,1032.762939 1563.662231,1033.474731 1562.806274,1034.186523 
	C1562.975952,1034.488892 1563.145630,1034.791138 1563.315186,1035.093506 
	C1564.734009,1034.998047 1566.152710,1034.902710 1568.390503,1034.752197 
	C1568.009888,1032.086670 1567.672485,1029.723999 1567.335083,1027.361450 
	C1565.968872,1027.635620 1564.602661,1027.909912 1563.791138,1028.072876 
	C1564.732056,1029.579224 1565.367065,1030.596069 1565.374023,1032.051147 
M1744.038696,1029.620361 
	C1745.091919,1028.361206 1746.145020,1027.102051 1747.198242,1025.842896 
	C1746.878296,1025.527344 1746.558350,1025.211670 1746.238403,1024.896118 
	C1744.652466,1025.058960 1743.066528,1025.221802 1740.939331,1025.440186 
	C1741.181152,1027.963501 1741.388916,1030.132568 1741.596802,1032.301514 
	C1741.902222,1032.283569 1742.207764,1032.265625 1742.513306,1032.247681 
	C1742.874756,1031.544067 1743.236206,1030.840454 1744.038696,1029.620361 
M1766.459229,991.510376 
	C1766.459229,991.510376 1766.499268,991.509705 1766.459229,991.510376 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M684.615417,455.109863 
	C685.647034,456.229370 686.466553,457.792084 687.741943,458.402374 
	C708.959351,468.555725 730.233887,478.589752 751.951355,489.151123 
	C753.865662,490.125305 755.324280,490.598816 756.782898,491.072296 
	C765.600220,495.234283 774.279114,499.745544 783.324463,503.332428 
	C785.838989,504.329529 788.029419,505.571289 790.612366,507.323669 
	C790.927307,507.988434 790.909973,508.217682 790.514648,508.356415 
	C789.366455,508.163086 788.596252,508.060242 787.623047,508.078735 
	C787.177734,508.429688 787.045166,508.711151 786.895874,509.384399 
	C787.270386,511.111542 787.381470,513.282288 788.336365,513.757446 
	C794.153748,516.652039 800.162903,519.161072 806.101685,522.169067 
	C806.369446,523.480774 806.643616,524.422180 806.592896,525.292603 
	C805.602844,525.212524 804.937744,525.203369 803.887817,525.133789 
	C802.988464,525.056091 802.473938,525.038757 801.659912,524.842590 
	C795.925598,522.124512 790.490662,519.585205 784.701172,516.877930 
	C783.045898,516.432983 781.745300,516.155945 780.517639,515.497559 
	C781.239685,512.752991 781.888794,510.389893 782.567444,507.919373 
	C774.176331,504.025879 766.014160,500.238586 757.050720,496.079498 
	C756.639709,501.366577 756.326172,505.399292 756.012634,509.431976 
	C755.121033,505.228088 754.804810,501.020630 754.183044,496.858826 
	C754.022644,495.785278 753.128662,494.413849 752.186401,493.930573 
	C745.976685,490.745880 739.702332,487.678955 733.370850,484.742279 
	C725.237122,480.969727 716.675903,477.967102 708.956177,473.497437 
	C701.554504,469.211914 692.552429,468.637146 685.937256,462.854126 
	C685.723877,462.667603 685.294556,462.727997 684.920898,462.381287 
	C683.880676,461.409363 682.885559,460.729401 681.538879,459.970673 
	C673.969482,456.563324 666.713074,453.315643 659.542297,449.888519 
	C646.459412,443.635834 633.221985,437.661041 620.443237,430.832611 
	C611.316223,425.955505 600.527161,424.800690 592.464478,417.458282 
	C589.531860,414.787628 585.078064,413.802277 581.344604,411.993683 
	C560.146729,401.724670 538.906494,391.540497 517.805481,381.075958 
	C513.463745,378.922729 508.827271,378.026733 504.439423,375.253174 
	C493.841614,368.554413 481.942749,363.928802 470.630707,358.341248 
	C457.114807,351.665100 443.696045,344.791748 430.157806,338.161865 
	C427.409210,336.815826 424.280823,335.338715 421.392609,335.460968 
	C418.918945,335.565643 417.826233,335.572296 416.977875,333.148712 
	C416.601288,332.072845 415.347656,331.046783 414.243225,330.522675 
	C403.193359,325.278839 392.091583,320.144379 380.740967,315.028015 
	C380.321320,315.383911 380.165161,315.690918 379.968262,316.465149 
	C379.882935,322.995789 379.838440,329.059174 379.630463,335.136993 
	C379.466980,335.151428 379.143127,335.093933 379.121704,334.655212 
	C379.057190,329.613281 378.625854,324.971710 379.075653,320.417145 
	C379.567169,315.440186 377.505829,312.935883 373.151611,310.997070 
	C362.444885,306.229797 352.019470,300.823090 341.261627,296.181793 
	C336.516510,294.134613 332.376465,289.324432 327.705994,291.499603 
	C315.309021,282.415039 301.857513,277.765259 288.822876,271.686737 
	C275.649994,265.187500 262.678619,258.952698 249.682343,252.770157 
	C245.699692,250.875565 241.985733,247.514328 236.987823,250.131012 
	C236.987823,257.740753 236.987823,265.370026 236.953049,273.467102 
	C237.274048,304.955505 237.629822,335.976135 238.058472,367.244171 
	C238.419601,367.655670 238.707886,367.819763 239.464508,368.009796 
	C247.995346,367.815613 256.057800,367.595459 264.368713,367.209808 
	C264.772339,366.688263 264.927521,366.332184 265.122803,365.510315 
	C265.162933,359.290802 265.162933,353.537079 265.162933,346.903290 
	C267.932526,347.485992 270.088287,347.939575 272.255585,348.652466 
	C272.196259,349.251434 272.125366,349.591156 271.613220,349.943420 
	C268.374695,349.510498 267.784424,351.022339 267.854706,353.439789 
	C268.004364,358.586884 268.003876,363.738373 267.694366,368.953644 
	C266.394958,369.368103 265.466064,369.716980 264.102600,370.097961 
	C241.078232,370.708801 218.491364,371.528870 195.897842,371.804443 
	C168.437317,372.139404 140.970993,371.987640 113.507980,372.166656 
	C112.037956,372.176239 110.575195,373.298981 109.056747,373.954407 
	C109.004433,374.005280 108.902206,374.109436 108.514709,374.179901 
	C107.751495,375.737091 107.076988,377.218628 107.050911,378.711456 
	C106.927071,385.801239 106.979919,392.894135 106.728546,399.990021 
	C106.487236,399.994141 106.004555,399.998077 106.004555,399.998077 
	C105.968491,392.686432 105.997360,385.373505 105.848701,378.064148 
	C105.813469,376.331848 105.182610,374.611725 104.442070,372.400818 
	C104.076607,371.413635 104.094810,370.911652 104.113007,370.409668 
	C104.511414,369.332794 105.161690,368.278412 105.260262,367.174774 
	C105.538239,364.062286 105.589630,360.929626 106.054321,357.403503 
	C106.571899,357.001526 106.765457,357.000977 106.959023,357.000427 
	C106.959023,361.443207 106.959023,365.886017 106.959023,369.683716 
	C149.251434,369.683716 190.733170,369.683716 233.046661,369.683716 
	C233.046661,366.262970 233.100281,363.832001 233.036957,361.404083 
	C232.817108,352.974365 232.380112,344.545410 232.394043,336.116760 
	C232.400055,332.480743 231.010254,331.428162 227.657028,331.696716 
	C225.009201,331.908813 222.331467,331.745819 219.667023,331.752563 
	C211.190948,331.773926 210.868790,331.343323 214.001648,323.444885 
	C215.225540,325.718048 214.210480,329.205261 218.470749,328.892090 
	C223.138718,328.548920 227.806900,328.208618 232.647675,327.854462 
	C232.647675,322.095428 232.647675,316.750305 232.647675,311.022552 
	C230.188522,311.022552 228.082718,311.022552 225.976898,311.022552 
	C225.432556,310.338165 225.031143,309.355194 224.321747,309.015533 
	C217.224014,305.617279 213.575119,299.738708 212.043869,292.289886 
	C211.504166,289.664459 212.825577,288.605438 215.287613,288.549194 
	C219.426025,288.454742 223.575241,288.123077 227.699265,288.321075 
	C231.298416,288.493896 232.252136,287.110718 232.139694,283.670898 
	C231.726669,271.035309 231.497696,258.391998 231.370850,245.749893 
	C231.340424,242.718475 230.282547,241.632523 227.261032,241.700363 
	C220.461624,241.852982 213.656433,241.748230 206.853607,241.748230 
	C206.830719,241.279678 206.807831,240.811111 206.784943,240.342560 
	C214.793289,240.342560 222.801651,240.342560 231.328369,240.342560 
	C231.328369,228.420074 231.328369,217.530441 231.328369,206.027420 
	C233.748962,208.758453 233.752151,208.758453 233.774811,222.466522 
	C233.784119,228.090668 233.776352,233.714844 233.776352,240.070679 
	C239.339218,240.070679 244.307343,240.070679 249.275467,240.070679 
	C249.291748,240.512405 249.308044,240.954132 249.324326,241.395859 
	C246.392471,241.624268 243.460617,241.852692 240.528748,242.081100 
	C240.443695,242.408295 240.358643,242.735474 240.273575,243.062668 
	C246.071365,246.013641 251.803833,249.102615 257.681244,251.885452 
	C268.294983,256.910858 279.044220,261.650726 289.647980,266.696655 
	C304.898651,273.953888 320.028931,281.464386 335.287231,288.705322 
	C348.731567,295.085449 362.277740,301.251190 375.795990,307.474670 
	C376.334808,307.722687 377.048737,307.590424 378.204590,307.352753 
	C379.121857,307.077118 379.515625,307.084137 379.909363,307.091125 
	C381.140747,308.485016 382.099854,310.442383 383.644165,311.188568 
	C408.546295,323.220734 433.571869,334.997528 458.465332,347.047363 
	C475.162842,355.129883 491.643005,363.660767 508.318817,371.789246 
	C526.653625,380.726288 545.122620,389.387451 563.492493,398.253143 
	C577.219971,404.878296 590.849731,411.706238 604.586243,418.312439 
	C613.683044,422.687286 622.958862,426.691559 632.035095,431.107239 
	C647.512207,438.636993 662.895447,446.359741 678.678284,454.439148 
	C679.666504,455.012207 680.294617,455.147552 680.922668,455.282898 
	C681.154297,455.266602 681.385986,455.250305 682.247925,455.147552 
	C683.457336,455.077332 684.036377,455.093597 684.615417,455.109863 
M231.518295,308.666901 
	C231.854675,303.233032 232.255905,297.800781 232.394531,292.361877 
	C232.404739,291.960999 230.530884,291.169434 229.517822,291.143188 
	C224.566605,291.015106 219.610245,291.085175 214.630051,291.085175 
	C215.308289,300.993225 219.387543,305.363251 231.518295,308.666901 
M234.015778,296.736206 
	C234.015778,307.288940 234.015778,317.841675 234.015778,328.394409 
	C234.697556,328.373840 235.379349,328.353271 236.061127,328.332703 
	C235.570511,315.868896 236.881210,303.363037 233.998428,290.945496 
	C233.998428,292.570770 233.998428,294.196075 234.015778,296.736206 
M783.522034,514.375488 
	C783.822571,514.100403 784.123108,513.825256 784.423645,513.550110 
	C784.313354,513.447388 784.122070,513.243164 784.104675,513.257019 
	C783.804199,513.496643 783.525146,513.763184 783.522034,514.375488 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1485.009277,916.496033 
	C1480.320435,919.499878 1483.197632,913.940735 1481.577393,912.954407 
	C1356.553101,913.898376 1231.528809,914.842407 1106.515381,915.786316 
	C1106.515381,928.619995 1106.522827,940.738342 1106.472900,952.856384 
	C1106.471680,953.162415 1105.837891,953.465820 1104.733398,954.459229 
	C1104.532104,952.056580 1104.282227,950.365723 1104.266479,948.672729 
	C1104.176514,939.013794 1104.196045,929.353333 1104.032227,919.695923 
	C1104.006958,918.207031 1103.225952,916.730957 1102.395996,914.895630 
	C1102.000244,914.366516 1102.003174,914.190735 1102.296143,913.972046 
	C1102.975220,913.895081 1103.364380,913.860901 1104.161011,913.868408 
	C1105.113281,913.886230 1105.658325,913.862427 1106.657959,913.870361 
	C1125.244019,913.615051 1143.375488,913.328064 1161.506836,913.041138 
	C1161.542358,912.487488 1161.578003,911.933899 1161.613525,911.380310 
	C1159.956787,911.234314 1158.300293,910.964661 1156.643555,910.962219 
	C1144.983398,910.945068 1133.323364,910.987244 1121.663208,911.001221 
	C1116.433350,911.007507 1111.203613,910.992554 1105.642090,910.992371 
	C1104.867798,911.002747 1104.425049,911.007874 1103.991211,911.006470 
	C1104.000000,911.000000 1103.985840,911.018311 1103.994385,910.671753 
	C1103.989380,909.862976 1103.975952,909.400696 1104.002075,908.475037 
	C1103.710571,884.256714 1103.347168,860.502258 1103.086426,836.746582 
	C1103.051025,833.505310 1102.169189,831.678650 1099.035522,832.192444 
	C1099.035522,857.950562 1099.035522,883.446350 1099.020508,909.248596 
	C1099.004395,909.759277 1099.003296,909.963501 1098.595947,910.210693 
	C1096.215698,910.542480 1094.241333,910.831421 1091.928955,911.081299 
	C1091.078003,910.416443 1090.565186,909.790649 1090.399414,908.962158 
	C1092.332031,908.371460 1093.917358,907.983398 1096.010864,907.470947 
	C1096.010864,905.780823 1096.033447,903.988037 1096.007446,902.195862 
	C1095.718140,882.242126 1095.418579,862.288574 1095.129150,842.334778 
	C1095.083252,839.173706 1096.036743,835.522217 1094.752808,833.001831 
	C1093.678223,830.892639 1090.008911,830.105347 1087.610229,828.798767 
	C1091.957275,828.198853 1096.435669,827.569153 1100.917969,826.966370 
	C1103.827026,826.575256 1106.256958,826.957825 1106.614258,830.613464 
	C1106.630005,830.774597 1107.170654,830.884460 1107.681030,831.114990 
	C1108.796143,830.477844 1110.025879,829.775146 1112.059326,828.613281 
	C1113.201660,830.091309 1114.216064,831.654358 1115.528931,832.903625 
	C1115.812256,833.173096 1117.171143,832.312012 1118.018799,831.985474 
	C1118.003052,831.999268 1117.976562,832.031555 1118.287354,832.154541 
	C1119.970703,833.138977 1121.343262,834.000305 1122.715942,834.861694 
	C1122.425049,835.374390 1122.134277,835.887085 1121.843506,836.399780 
	C1120.550659,836.924622 1119.257935,837.449463 1117.657715,837.957947 
	C1116.939819,837.934387 1116.529297,837.927063 1116.050537,837.557739 
	C1113.935547,836.169067 1111.921021,834.514343 1109.827515,834.408020 
	C1108.919556,834.361877 1107.849609,837.504272 1106.869629,839.503662 
	C1106.933716,840.213379 1106.981689,840.617859 1106.750610,841.258911 
	C1106.601440,844.439758 1106.731323,847.384155 1106.644653,850.638550 
	C1106.358643,852.508484 1106.289307,854.068420 1106.201538,856.041138 
	C1114.850464,856.041138 1122.936035,855.921448 1131.015869,856.085388 
	C1135.996094,856.186401 1140.967773,856.719971 1145.840820,857.387207 
	C1145.679321,862.800964 1145.620239,867.886902 1145.444824,873.310608 
	C1145.421387,874.108521 1145.514404,874.568604 1145.481689,875.477905 
	C1145.207642,883.509705 1144.882080,891.094238 1144.961670,898.674500 
	C1145.037109,905.861572 1146.623413,907.024048 1153.598511,907.266541 
	C1157.419678,907.399353 1161.212646,908.340332 1165.107422,909.323486 
	C1170.328003,910.155212 1175.456665,910.829590 1180.592407,910.886475 
	C1182.729126,910.910156 1184.880249,909.617310 1187.269531,908.940186 
	C1187.514282,908.957153 1188.004761,908.959717 1188.190918,909.315796 
	C1188.742310,910.002502 1189.100952,910.612976 1189.473877,910.621887 
	C1195.751587,910.772522 1202.034302,910.968689 1208.306763,910.806030 
	C1209.212036,910.782471 1210.070312,908.953247 1211.274658,907.705078 
	C1215.190552,907.211426 1218.782104,906.972290 1222.007812,906.757507 
	C1222.007812,903.005371 1222.105957,899.587952 1221.984253,896.178345 
	C1221.813965,891.404724 1221.480103,886.636902 1221.572021,881.871277 
	C1222.642090,881.946655 1223.356079,882.017395 1224.047607,882.548279 
	C1224.025024,890.136963 1224.025024,897.265503 1224.025024,904.394104 
	C1224.687256,904.402222 1225.349487,904.410339 1226.011719,904.418457 
	C1226.011719,901.274597 1225.879150,898.123718 1226.046265,894.988831 
	C1226.243286,891.291931 1226.699341,887.608765 1227.050049,884.393188 
	C1227.086060,890.190002 1227.007446,895.516846 1227.180054,900.835571 
	C1227.308350,904.792664 1229.001465,907.253967 1233.548950,907.075562 
	C1239.694458,906.834351 1245.853394,906.931152 1252.376831,907.089294 
	C1262.497925,907.485718 1272.248657,907.675781 1282.330078,907.927124 
	C1283.107300,907.976379 1283.554077,907.964355 1284.461914,907.990479 
	C1304.268555,907.277405 1323.614136,906.526062 1343.292847,905.837158 
	C1347.271118,907.752502 1345.764404,903.591248 1347.533813,902.849121 
	C1349.190063,903.495300 1350.499512,904.141785 1351.994385,905.098511 
	C1353.162231,905.238037 1354.144653,905.067444 1355.479370,904.905884 
	C1356.536255,904.926392 1357.240845,904.937866 1358.172852,905.254395 
	C1360.585449,906.076904 1362.770752,906.594360 1365.015381,907.478149 
	C1367.435913,912.538757 1370.022095,913.208252 1378.914062,910.841064 
	C1376.020386,910.841064 1373.126709,910.841064 1370.233154,910.841064 
	C1370.259277,910.504089 1370.285522,910.167053 1370.311768,909.830078 
	C1375.263062,909.830078 1380.214355,909.830078 1385.165649,909.830078 
	C1385.171875,910.082703 1385.178223,910.335327 1385.184448,910.587952 
	C1384.136353,910.891785 1383.088257,911.195557 1380.993408,911.802856 
	C1386.439819,911.872742 1386.439819,911.872742 1388.385986,907.214355 
	C1389.101074,907.204285 1389.510254,907.227295 1389.989014,907.573303 
	C1390.437744,909.235291 1390.817017,910.574280 1391.448608,912.804749 
	C1395.361694,911.853577 1399.183228,910.924622 1403.002441,909.997803 
	C1403.000000,910.000000 1402.995117,910.005249 1403.307373,910.041565 
	C1406.018921,910.545410 1408.418213,911.013000 1410.817505,911.480591 
	C1410.908813,910.029175 1411.000122,908.577820 1411.505615,907.075012 
	C1414.832764,906.636414 1417.739258,906.014832 1420.661011,905.932617 
	C1423.222900,905.860596 1424.191406,904.980774 1424.124756,902.405762 
	C1423.934814,895.072510 1423.907837,887.735046 1424.175903,880.326904 
	C1425.714966,880.862244 1426.894775,881.469788 1428.023193,882.500732 
	C1427.971558,884.813110 1427.971558,886.702148 1427.971558,887.674133 
	C1430.146973,888.913269 1431.590576,889.735596 1433.151855,890.769226 
	C1433.290405,891.311096 1433.223145,891.622620 1432.663574,891.922546 
	C1431.138672,894.321045 1429.564575,896.609131 1428.993408,899.124939 
	C1427.871460,904.066589 1429.059204,905.063049 1434.132080,905.225769 
	C1438.677368,905.371582 1443.209595,905.922729 1447.788818,906.618530 
	C1448.386841,907.623840 1448.927246,908.881897 1449.502563,908.898132 
	C1455.109985,909.055359 1460.726196,909.094971 1466.332031,908.916626 
	C1467.170288,908.889893 1467.967163,907.563293 1469.186035,906.823975 
	C1470.390869,906.878723 1471.193115,906.947510 1472.074097,907.419678 
	C1478.438354,908.152710 1484.722290,908.566895 1491.011841,908.688171 
	C1491.678223,908.701050 1492.377930,906.995178 1493.305054,905.860596 
	C1494.457764,905.047302 1495.367676,904.460083 1496.543457,903.874878 
	C1497.162598,903.890076 1497.515991,903.903442 1498.109375,904.245605 
	C1501.560791,905.468140 1504.546387,906.005066 1504.400757,900.968872 
	C1506.491943,901.995605 1509.982422,901.480042 1508.220337,905.192810 
	C1506.904907,905.956299 1505.921387,906.788330 1504.629639,907.515869 
	C1503.894165,907.465393 1503.466797,907.519409 1502.704346,907.426880 
	C1501.229858,907.471741 1500.090820,907.663208 1498.584229,907.851440 
	C1497.343384,908.502014 1496.470215,909.155701 1495.481689,909.946655 
	C1495.639282,910.176208 1495.912231,910.268494 1496.483276,910.311401 
	C1497.283447,910.491211 1497.785400,910.720398 1498.226074,911.198181 
	C1498.276611,911.745728 1498.388428,912.044678 1498.500122,912.343567 
	C1498.645142,911.885986 1498.790039,911.428467 1499.366211,910.960938 
	C1501.515503,910.854675 1503.233521,910.758423 1505.292236,910.744873 
	C1507.773682,910.789734 1509.914551,910.751953 1512.501221,910.755127 
	C1516.672852,910.636292 1520.398804,910.476501 1524.082520,910.687744 
	C1525.188721,912.400696 1526.337158,913.742432 1527.927490,915.600586 
	C1526.325684,915.795410 1524.834717,915.976807 1523.343750,916.158203 
	C1522.345459,912.953247 1517.613281,911.658020 1512.719360,912.993713 
	C1509.764771,913.799927 1506.692505,914.165039 1503.694824,914.827148 
	C1502.448364,915.102356 1500.959595,915.313049 1500.100708,916.111938 
	C1497.785522,918.265381 1495.679932,917.906738 1493.127930,915.693726 
	C1492.209961,913.753906 1491.597168,912.334290 1490.984497,910.914673 
	C1489.311035,911.677063 1487.463257,912.210510 1486.036255,913.296692 
	C1485.305420,913.852905 1485.327515,915.397888 1485.009277,916.496033 
M1110.259888,857.878784 
	C1106.723755,857.544495 1106.752075,859.875000 1106.773071,862.379395 
	C1106.888428,876.169983 1106.936035,889.961182 1107.044800,903.751892 
	C1107.054810,905.018250 1107.332397,906.282532 1107.498169,907.650513 
	C1119.048706,907.650513 1130.263428,907.650513 1142.040039,907.650513 
	C1142.040039,901.712158 1141.917480,896.106506 1142.069458,890.508240 
	C1142.284912,882.572510 1143.435547,874.566650 1142.629761,866.751282 
	C1142.354004,864.077637 1144.620850,857.680542 1137.926758,857.632324 
	C1128.992554,857.567993 1120.056519,857.775940 1110.259888,857.878784 
M1336.417358,908.893311 
	C1332.712036,909.623352 1328.381104,907.454529 1325.245361,911.195007 
	C1337.198364,911.911255 1349.063599,911.947693 1360.927124,911.822021 
	C1361.647339,911.814331 1362.353882,910.519409 1363.066772,909.823425 
	C1362.215576,909.334106 1361.360718,908.415344 1360.513550,908.422302 
	C1352.799561,908.485657 1345.086670,908.710388 1336.417358,908.893311 
M1214.204590,910.299011 
	C1211.814209,914.107849 1215.384399,912.960632 1216.672852,912.970154 
	C1227.151733,913.047485 1237.631958,912.976746 1248.111206,912.886475 
	C1249.391846,912.875427 1250.669434,912.482300 1251.948364,912.266174 
	C1251.911011,911.565735 1251.873779,910.865234 1251.836548,910.164734 
	C1239.579834,910.164734 1227.323242,910.164734 1214.204590,910.299011 
M1432.478638,911.060425 
	C1436.268066,911.060425 1440.057617,911.060425 1445.133179,911.060425 
	C1440.576172,906.848816 1417.071411,907.281799 1414.876953,911.104004 
	C1420.687744,911.104004 1426.087769,911.104004 1432.478638,911.060425 
M1305.042236,909.899231 
	C1310.319214,909.899231 1315.596191,909.899231 1320.873169,909.899231 
	C1320.863647,909.700928 1320.854004,909.502563 1320.844360,909.304260 
	C1314.644043,909.304260 1308.443726,909.304260 1302.243408,909.304260 
	C1302.245117,909.464050 1302.246826,909.623840 1302.248535,909.783630 
	C1302.900391,909.816528 1303.552124,909.849426 1305.042236,909.899231 
M1144.991333,907.768250 
	C1144.929199,906.768372 1144.867188,905.768555 1144.805054,904.768677 
	C1144.409912,904.838745 1144.014648,904.908752 1143.619507,904.978821 
	C1143.977539,906.095947 1144.335693,907.213074 1144.991333,907.768250 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1534.051758,952.480835 
	C1530.344360,957.453735 1525.893555,962.048340 1523.120972,967.496948 
	C1519.796387,974.030029 1518.341675,981.416931 1520.829834,988.762390 
	C1523.441162,996.471191 1529.454346,1000.668457 1536.926392,1003.029724 
	C1538.767578,1003.611572 1540.682007,1003.961426 1542.878662,1004.494629 
	C1539.197021,1011.599426 1537.098999,1018.742188 1542.215210,1025.364624 
	C1544.686646,1028.563965 1548.842651,1030.418335 1552.001831,1033.152100 
	C1553.203491,1034.192139 1554.642090,1036.118896 1554.454102,1037.422729 
	C1552.807373,1048.851074 1556.734497,1054.724121 1567.894165,1057.121338 
	C1569.010864,1057.361328 1570.398193,1058.181519 1570.907715,1059.134521 
	C1579.889893,1075.934814 1599.878052,1075.667480 1610.596069,1065.160400 
	C1621.134766,1071.422607 1621.136963,1071.422607 1629.560791,1064.775391 
	C1642.888062,1078.651367 1656.415039,1079.464111 1670.130127,1063.653442 
	C1677.332886,1073.377441 1686.662109,1079.373657 1698.801147,1078.816406 
	C1710.536255,1078.277710 1719.926514,1072.805420 1726.759277,1062.292480 
	C1732.183472,1068.710693 1738.747192,1072.076660 1746.595337,1071.766846 
	C1754.371094,1071.459717 1760.045654,1066.879883 1764.757080,1060.092163 
	C1772.491699,1070.680786 1782.547852,1074.669434 1794.350952,1073.607300 
	C1806.042603,1072.555298 1814.685181,1066.197144 1821.114014,1055.860962 
	C1832.176147,1061.189575 1841.960449,1061.263062 1847.052124,1046.998657 
	C1854.617432,1050.959351 1862.222046,1051.546631 1868.914795,1046.805176 
	C1872.138428,1044.521484 1873.774780,1040.059937 1876.324829,1036.734253 
	C1877.488281,1035.216919 1878.933105,1033.259277 1880.575439,1032.868774 
	C1894.032593,1029.668823 1900.066162,1018.072632 1896.148315,1004.422424 
	C1895.638062,1002.644958 1896.299316,999.952148 1897.409302,998.385986 
	C1903.263550,990.126099 1901.609619,979.136658 1893.397827,973.190552 
	C1892.110596,972.258423 1890.855591,971.059326 1890.060303,969.704956 
	C1883.107422,957.862183 1872.144531,954.468384 1860.001343,961.112183 
	C1856.233765,963.173523 1853.859985,962.957397 1851.018433,960.012573 
	C1842.257568,950.933472 1824.670166,950.961670 1815.367676,961.920776 
	C1813.547485,964.065186 1811.713745,966.197998 1809.626099,968.640198 
	C1803.400635,962.321411 1797.251953,958.496887 1788.769287,959.875610 
	C1780.877075,961.158325 1775.222168,965.237061 1771.137085,972.895569 
	C1764.189331,963.121765 1756.672607,955.955566 1744.512939,955.780334 
	C1733.140503,955.616394 1723.720947,959.231750 1715.928467,968.720642 
	C1714.023193,966.051147 1712.439331,963.832153 1710.663574,961.294861 
	C1712.428223,962.030823 1714.385132,963.085083 1716.739990,964.353821 
	C1724.043091,956.631836 1733.274780,952.411560 1744.647827,953.015198 
	C1755.957520,953.615417 1764.257812,959.108337 1770.956665,968.636414 
	C1775.595093,961.789185 1781.600708,957.840210 1789.502563,957.173828 
	C1797.255493,956.520081 1803.891724,958.864258 1808.212524,963.738892 
	C1814.505737,959.584595 1819.967163,954.900696 1826.262207,952.072266 
	C1836.151733,947.628906 1845.121338,951.530884 1852.909790,957.960815 
	C1855.468506,960.073181 1856.927734,960.135254 1859.709961,958.385986 
	C1869.952026,951.946533 1882.994629,955.167175 1889.945312,965.113953 
	C1892.748779,969.125793 1896.527222,972.444458 1899.483765,976.363892 
	C1904.817261,983.434143 1903.940796,990.820435 1900.244263,998.380310 
	C1899.274658,1000.363098 1898.558594,1003.109314 1899.130737,1005.101318 
	C1902.512695,1016.872192 1896.649658,1030.411133 1883.813477,1034.596924 
	C1880.738281,1035.599854 1878.950562,1037.091797 1877.667969,1040.211426 
	C1873.561035,1050.199341 1861.488159,1055.750122 1851.208374,1051.447998 
	C1850.476562,1051.141724 1849.644287,1051.075928 1848.938477,1050.916260 
	C1840.826660,1062.477417 1835.538452,1064.092896 1821.864624,1059.141968 
	C1815.682617,1069.055908 1806.542480,1074.893311 1795.011353,1076.475464 
	C1783.133789,1078.104980 1773.382812,1073.338989 1764.762695,1064.679077 
	C1759.778931,1069.893555 1754.230347,1073.827759 1746.446167,1074.114868 
	C1738.896851,1074.393311 1733.047119,1071.219849 1727.113037,1066.877319 
	C1721.597290,1072.838135 1715.513550,1077.939331 1707.426147,1080.175903 
	C1695.231812,1083.548340 1684.477661,1081.154297 1675.228394,1072.540405 
	C1670.179565,1067.838623 1670.275513,1067.735596 1665.272583,1072.067017 
	C1655.545410,1080.488159 1642.321655,1080.195435 1632.911255,1071.201416 
	C1630.857788,1069.238647 1629.302856,1068.970947 1626.409180,1069.948975 
	C1622.910767,1071.131348 1618.464233,1071.824219 1615.160278,1070.656738 
	C1611.377563,1069.319946 1608.809692,1069.841187 1605.736206,1071.662964 
	C1596.368164,1077.215576 1586.681885,1076.941895 1578.181274,1070.638916 
	C1571.308838,1065.543091 1565.449951,1059.353027 1557.516846,1055.348022 
	C1552.515381,1052.822876 1550.785034,1046.463013 1551.176758,1040.476562 
	C1551.372437,1037.483887 1550.703247,1035.895508 1547.726074,1034.330200 
	C1539.741577,1030.132324 1533.568481,1019.018921 1539.272217,1006.409912 
	C1526.895264,1003.496033 1518.289551,995.735596 1517.424072,984.926392 
	C1516.596558,974.593079 1519.828003,965.029663 1526.700806,956.973083 
	C1527.949097,955.509705 1529.336182,954.164551 1531.298950,952.087646 
	C1526.740479,951.878479 1523.181396,951.715149 1519.297363,951.048340 
	C1518.972412,950.544861 1518.961548,950.052429 1519.430176,950.012573 
	C1524.587769,949.967285 1529.276489,949.961853 1533.976318,950.375732 
	C1534.008911,951.356934 1534.030396,951.918884 1534.051758,952.480835 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2251.980469,1015.030273 
	C2233.658447,1014.939026 2215.336426,1014.781067 2197.014404,1014.773865 
	C2175.524902,1014.765442 2154.035156,1014.816162 2132.546143,1014.970642 
	C2101.069824,1015.196899 2069.593994,1015.534973 2038.118164,1015.844788 
	C2036.988647,1015.855835 2035.861084,1016.072998 2034.004883,1016.272278 
	C2034.210693,1020.755188 2034.407959,1025.056396 2034.605347,1029.357666 
	C2034.386230,1029.513672 2034.166992,1029.669800 2033.947754,1029.825806 
	C2033.372437,1028.364868 2032.497192,1026.949707 2032.266357,1025.436157 
	C2027.677124,995.339417 2029.579346,964.998108 2028.913696,934.751648 
	C2028.888794,933.616272 2028.700073,932.484619 2028.605957,931.536621 
	C2026.328369,930.818054 2024.315552,930.183044 2022.287476,928.982361 
	C2022.915527,928.327271 2023.581665,928.081909 2024.198608,928.172363 
	C2030.686279,929.123108 2030.922485,928.948914 2031.003906,922.489136 
	C2031.036377,919.905640 2031.009277,917.321350 2031.009277,914.737366 
	C2031.694946,914.751587 2032.380615,914.765808 2033.066284,914.780029 
	C2033.066284,918.491516 2033.066284,922.203003 2033.066284,925.914490 
	C2033.355835,925.907043 2033.645386,925.899597 2033.934937,925.892151 
	C2033.934937,920.943542 2033.934937,915.994995 2033.950195,910.608276 
	C2034.791626,903.808899 2031.988037,901.260315 2025.839966,901.989990 
	C2024.066650,902.200439 2022.233521,901.906006 2020.598267,901.448792 
	C2024.046997,900.472229 2027.325073,899.889893 2030.679688,899.293945 
	C2030.679688,891.453491 2030.679688,883.697388 2030.758545,875.738037 
	C2030.837280,875.534790 2030.886108,875.101624 2031.274170,875.071045 
	C2033.791382,876.327026 2035.920410,877.613647 2037.752563,879.178223 
	C2036.395996,886.960388 2036.697876,887.543579 2041.797852,888.167542 
	C2041.795898,888.744324 2041.781128,888.974792 2041.359619,889.159058 
	C2039.915161,890.169373 2038.092651,891.150330 2037.982178,892.296143 
	C2037.607788,896.181641 2037.839478,900.125549 2037.839478,904.358704 
	C2039.504517,904.216492 2040.752808,904.109863 2042.036621,904.341675 
	C2042.037842,905.131836 2042.003784,905.583618 2041.545898,905.973572 
	C2039.655273,905.987488 2038.188721,906.063171 2036.112793,906.170410 
	C2035.715698,909.738708 2034.899414,913.156433 2035.047852,916.531677 
	C2035.381470,924.117920 2036.612915,931.676636 2036.730103,939.258118 
	C2037.046143,959.729126 2036.962036,980.206116 2037.063110,1000.680603 
	C2037.074219,1002.924500 2036.441528,1005.618408 2039.987305,1005.987183 
	C2040.003662,1006.003845 2040.036499,1006.036926 2040.111084,1006.412537 
	C2041.932373,1006.991272 2043.864868,1007.771118 2045.388306,1007.280457 
	C2048.846436,1006.166626 2052.072998,1004.353821 2055.453857,1002.972168 
	C2057.346436,1002.198608 2059.358887,1001.718506 2061.317383,1001.106201 
	C2061.423584,1001.494995 2061.529541,1001.883728 2061.635498,1002.272461 
	C2057.526611,1004.130432 2053.417480,1005.988403 2049.308594,1007.846375 
	C2049.491699,1008.417358 2049.675049,1008.988342 2049.858154,1009.559387 
	C2058.943848,1009.031250 2068.026123,1008.157715 2077.116211,1008.070740 
	C2086.493164,1007.980957 2095.884277,1009.049438 2105.257080,1008.894043 
	C2116.070312,1008.714783 2126.877197,1007.843872 2137.670898,1007.054382 
	C2140.005127,1006.883667 2142.271240,1005.780884 2144.073486,1005.252563 
	C2146.747314,1005.881165 2149.127441,1006.505554 2151.536865,1006.980835 
	C2152.833252,1007.236572 2154.258301,1006.993530 2155.490967,1007.391113 
	C2162.778076,1009.741272 2170.193604,1007.948059 2177.533447,1008.083618 
	C2183.466797,1008.193237 2189.419922,1007.035645 2195.359863,1007.076050 
	C2205.629150,1007.145874 2215.892822,1007.809021 2226.163086,1008.013855 
	C2230.110107,1008.092529 2234.066406,1007.545471 2238.022217,1007.379395 
	C2242.345947,1007.197876 2246.673828,1007.127502 2250.984375,1007.424927 
	C2250.948975,1008.118164 2250.929443,1008.395142 2250.559082,1008.823364 
	C2246.493164,1009.145691 2242.777588,1009.316833 2239.062256,1009.487915 
	C2239.060303,1010.208435 2239.058350,1010.929016 2239.056152,1011.649597 
	C2243.400391,1011.850525 2247.744385,1012.051453 2252.077393,1012.599731 
	C2252.037598,1013.641418 2252.009033,1014.335876 2251.980469,1015.030273 
M2034.461304,1009.036255 
	C2034.620728,1007.260559 2034.937256,1005.483032 2034.915649,1003.709534 
	C2034.647095,981.670532 2034.326904,959.632263 2034.028442,937.593628 
	C2033.998657,935.392456 2034.024414,933.190491 2034.024414,930.988953 
	C2033.653198,930.993774 2033.282104,930.998596 2032.910889,931.003357 
	C2032.910889,940.322754 2032.910889,949.642090 2032.910889,958.961426 
	C2032.565796,958.966125 2032.220825,958.970825 2031.875732,958.975525 
	C2031.875732,955.674194 2031.875732,952.372864 2031.875732,949.071533 
	C2031.695679,949.082275 2031.515747,949.092957 2031.335693,949.103699 
	C2031.335693,966.998718 2031.335693,984.893677 2031.335693,1002.788635 
	C2031.774536,1002.791016 2032.213501,1002.793335 2032.652344,1002.795715 
	C2032.652344,997.480469 2032.652344,992.165222 2032.652344,986.849976 
	C2033.136475,986.891479 2033.620483,986.932983 2034.104614,986.974487 
	C2034.104614,994.107727 2034.104614,1001.240906 2034.461304,1009.036255 
M2199.472412,1012.898438 
	C2201.964600,1012.888733 2204.474609,1013.049377 2206.940186,1012.788696 
	C2207.980957,1012.678711 2208.927979,1011.680786 2209.917480,1011.086365 
	C2209.015381,1010.413635 2208.126465,1009.179260 2207.209473,1009.158203 
	C2200.735840,1009.009521 2194.254639,1009.030396 2187.782471,1009.220276 
	C2186.845703,1009.247803 2185.943848,1010.468323 2185.025635,1011.136719 
	C2186.023926,1011.721375 2186.994385,1012.745483 2188.025635,1012.810669 
	C2191.500244,1013.030273 2194.996582,1012.908936 2199.472412,1012.898438 
M2100.779541,1012.884399 
	C2102.898682,1009.803284 2100.372559,1010.156494 2098.666260,1010.144897 
	C2092.195068,1010.100952 2085.720703,1010.048950 2079.254395,1010.242493 
	C2078.156494,1010.275391 2077.094238,1011.499695 2076.015625,1012.173462 
	C2077.204834,1012.725586 2078.400391,1013.764954 2079.582031,1013.749207 
	C2086.367676,1013.658813 2093.150146,1013.318176 2100.779541,1012.884399 
M2144.480713,1012.981506 
	C2154.394531,1012.960083 2154.394531,1012.960083 2155.554688,1010.635071 
	C2147.783447,1008.654907 2131.586182,1009.726807 2129.998779,1012.955261 
	C2134.695557,1012.955261 2139.094727,1012.955261 2144.480713,1012.981506 
M2043.949707,1013.681274 
	C2044.967651,1013.222168 2045.985718,1012.763123 2047.003662,1012.304016 
	C2046.824219,1011.770386 2046.644775,1011.236816 2046.465454,1010.703186 
	C2043.811768,1011.314209 2041.158203,1011.925293 2038.504517,1012.536316 
	C2038.589844,1012.971375 2038.675171,1013.406494 2038.760498,1013.841553 
	C2040.231079,1013.841553 2041.701782,1013.841553 2043.949707,1013.681274 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1694.175537,525.779236 
	C1724.238037,525.068481 1754.300537,524.357727 1784.363037,523.646973 
	C1784.397949,524.607117 1784.432861,525.567261 1784.467773,526.527344 
	C1772.979248,527.365051 1761.386353,526.548340 1749.084229,527.073608 
	C1749.084229,534.694153 1749.001831,542.111145 1749.115967,549.525024 
	C1749.181641,553.789551 1749.406982,558.068420 1749.921265,562.296997 
	C1750.045288,563.317749 1751.588623,564.961670 1752.495361,564.972473 
	C1766.773804,565.142273 1781.055054,565.091675 1796.109619,565.091675 
	C1796.109619,560.553894 1796.109619,556.561768 1796.109619,552.569702 
	C1796.751343,552.534851 1797.393066,552.500000 1798.034790,552.465149 
	C1798.301880,555.364014 1798.768555,558.262329 1798.777344,561.162048 
	C1798.785278,563.828796 1799.501221,565.106323 1802.453735,565.049255 
	C1822.900879,564.653625 1843.350464,564.378357 1863.799072,564.054993 
	C1882.525146,563.758911 1901.250854,563.437866 1919.977295,563.169617 
	C1921.785767,563.143738 1923.598389,563.413330 1925.885132,564.049561 
	C1927.548340,565.359314 1928.887695,566.016785 1929.869263,567.023193 
	C1930.527710,567.698303 1930.678955,568.867981 1930.584595,569.856812 
	C1909.750977,570.607422 1889.389771,571.317566 1868.673340,571.974609 
	C1867.859131,570.932312 1867.400269,569.943115 1867.412109,568.960083 
	C1872.546143,568.746521 1877.319580,569.400513 1882.119507,568.388550 
	C1881.684570,567.063965 1881.635254,566.768066 1881.580322,566.767090 
	C1869.492432,566.545715 1857.404541,566.315186 1845.315430,566.225891 
	C1844.664917,566.221008 1844.005493,567.406372 1843.350220,568.037720 
	C1844.201782,568.537720 1845.056763,569.475098 1845.904419,569.468445 
	C1852.601807,569.416016 1859.297607,569.183655 1865.991455,569.376953 
	C1865.262817,570.578674 1864.536743,571.410034 1863.362183,572.151367 
	C1841.719727,572.061340 1820.525635,572.061340 1799.356201,572.061340 
	C1798.913940,574.876465 1798.429321,577.961365 1797.708252,581.038696 
	C1797.471802,581.031189 1796.998901,581.000000 1796.726807,580.697021 
	C1796.333496,578.271484 1796.343384,576.129456 1796.033691,574.034729 
	C1795.888916,573.054932 1795.097534,571.344299 1794.649170,571.360291 
	C1788.453369,571.582153 1781.977173,569.602112 1775.851074,573.298584 
	C1773.387329,573.484436 1771.263794,573.789978 1768.930664,573.848389 
	C1767.123413,574.630859 1765.525635,575.660522 1763.927856,576.690186 
	C1764.410645,577.265991 1764.893555,577.841858 1765.376465,578.417725 
	C1766.635010,577.242676 1767.893433,576.067566 1769.400879,575.189575 
	C1769.687500,577.037781 1769.725342,578.589050 1769.369385,580.089050 
	C1766.916626,580.324341 1764.857788,580.610840 1762.208130,580.979553 
	C1761.998657,578.083679 1761.820557,575.620483 1761.661011,573.413269 
	C1758.389526,573.413269 1754.825317,573.413269 1751.019287,573.413269 
	C1751.019287,580.192566 1751.019287,586.505615 1751.016113,592.916138 
	C1751.012939,593.013550 1750.990356,593.207214 1750.620361,593.096619 
	C1749.240601,593.063416 1748.230713,593.140808 1747.216064,592.736206 
	C1747.183716,586.641113 1747.261719,581.023743 1747.041382,575.418152 
	C1746.996460,574.275635 1745.828735,573.177185 1745.178711,572.058472 
	C1744.406250,573.216125 1743.062622,574.327515 1742.964355,575.539734 
	C1742.619385,579.799377 1743.260010,584.201660 1742.458252,588.342957 
	C1741.734619,592.079468 1742.889526,593.152832 1746.016602,593.446411 
	C1745.594849,594.082642 1745.330444,594.515625 1744.672363,595.028442 
	C1740.565796,595.706909 1741.741455,598.890198 1742.356445,600.405762 
	C1742.851807,601.626831 1745.507812,601.971191 1747.508301,602.918640 
	C1747.959106,603.331055 1748.106934,603.529419 1747.817627,603.852905 
	C1745.777832,604.150940 1744.175171,604.323792 1742.371216,604.518372 
	C1742.198364,606.879028 1742.044067,608.986206 1741.558960,611.062927 
	C1740.787109,610.985901 1740.346069,610.939392 1739.868164,610.523804 
	C1738.254150,608.739624 1740.722656,604.420349 1736.212280,604.802490 
	C1736.537720,603.968018 1736.861938,603.442871 1737.553467,602.836853 
	C1738.296387,600.514465 1738.671753,598.272949 1739.296021,596.006348 
	C1739.769531,595.788513 1739.994263,595.595825 1740.218994,595.403198 
	C1739.743042,595.386780 1739.267090,595.370361 1738.413696,595.301758 
	C1737.021973,595.222778 1736.007690,595.196045 1734.895020,594.861023 
	C1735.647949,592.768616 1736.508423,590.988892 1737.347534,589.199097 
	C1738.074951,587.647583 1738.778320,586.084717 1739.492310,584.526917 
	C1737.696655,584.014587 1735.900879,583.502258 1733.786133,582.808960 
	C1731.611938,581.772278 1729.756836,580.916565 1727.901733,580.060852 
	C1727.939941,579.737793 1727.978271,579.414734 1728.016602,579.091675 
	C1731.189331,579.388977 1734.362061,579.686340 1737.872070,579.816101 
	C1738.468872,577.903015 1738.948608,576.158081 1738.953613,574.411865 
	C1738.994507,560.312866 1738.946045,546.213623 1739.176270,531.921753 
	C1739.618408,531.139709 1739.809204,530.550476 1740.193359,529.698486 
	C1741.309448,528.780579 1742.232056,528.125366 1743.633789,527.129944 
	C1733.978027,527.129944 1725.165161,527.129944 1716.150391,526.961243 
	C1714.971313,528.164673 1713.994385,529.536804 1712.735474,531.217651 
	C1706.969727,531.396912 1701.486084,531.267456 1695.884888,530.897583 
	C1695.236816,529.031128 1694.706177,527.405212 1694.175537,525.779236 
M1747.033203,536.878418 
	C1745.912231,536.235535 1744.791138,535.592651 1743.670166,534.949768 
	C1743.126953,536.010620 1742.171631,537.050232 1742.115601,538.136230 
	C1741.927612,541.782654 1742.065674,545.445618 1742.068359,549.102295 
	C1742.072876,555.598328 1742.069702,562.094299 1742.069702,568.590332 
	C1742.766602,568.770752 1743.463501,568.951233 1744.160400,569.131653 
	C1745.149414,566.834900 1746.893066,564.569641 1746.990601,562.235535 
	C1747.330200,554.105652 1747.099976,545.951965 1747.033203,536.878418 
M1763.352905,570.026428 
	C1765.482422,569.841980 1767.612061,569.657532 1769.741577,569.473083 
	C1769.703247,568.918396 1769.664795,568.363647 1769.626465,567.808960 
	C1762.839355,567.808960 1756.052368,567.808960 1749.265259,567.808960 
	C1749.239746,568.551331 1749.214355,569.293640 1749.188843,570.036011 
	C1753.590942,570.036011 1757.993042,570.036011 1763.352905,570.026428 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1090.052368,909.164856 
	C1090.565186,909.790649 1091.078003,910.416443 1091.981934,911.429504 
	C1092.893921,914.216675 1093.697144,915.697937 1096.703491,914.383240 
	C1098.232300,913.714600 1100.223877,914.103821 1102.006226,914.014893 
	C1102.003174,914.190735 1102.000244,914.366516 1101.998535,914.806519 
	C1099.574341,915.348328 1097.151245,915.838196 1094.722656,915.867065 
	C1083.909424,915.995911 1073.092041,916.136230 1062.281372,915.957642 
	C1058.659424,915.897766 1057.623657,917.183289 1057.842773,920.578369 
	C1058.088745,924.389832 1057.978516,928.228027 1057.933716,932.053650 
	C1057.877075,936.891357 1057.006470,937.466431 1052.184082,936.040710 
	C1045.142456,933.958984 1045.142456,933.958923 1037.014893,925.276855 
	C1031.848877,928.902649 1027.107666,932.963501 1021.723389,935.806824 
	C1016.134338,938.758301 1014.310181,937.167053 1014.126953,931.010071 
	C1013.990906,926.438660 1015.160889,920.360657 1012.737732,917.764832 
	C1010.594238,915.468567 1004.292542,916.974915 999.825745,916.943420 
	C991.412659,916.884155 982.998840,916.926331 973.970581,916.926331 
	C973.970581,923.348511 974.058228,928.297607 973.918396,933.240234 
	C973.875183,934.768921 973.213013,936.280212 972.056335,937.925049 
	C970.480103,938.073547 969.681824,938.096497 968.883484,938.119446 
	C948.061279,938.163147 927.238403,938.121521 906.416931,938.269653 
	C870.622498,938.524475 834.828430,938.858887 799.035156,939.243958 
	C787.899109,939.363708 776.766968,939.824402 765.630920,939.971802 
	C762.889038,940.008118 761.671997,940.839233 761.814941,943.707642 
	C761.988953,947.198120 761.970398,950.705994 761.847229,954.199707 
	C761.796082,955.651428 761.259094,957.086060 760.943054,958.528442 
	C760.294556,958.483215 759.646057,958.437927 758.997559,958.392639 
	C758.997559,954.569580 758.709045,950.719116 759.068970,946.930237 
	C759.542358,941.948181 757.462341,940.470398 752.738770,940.735596 
	C748.791138,940.957214 744.798523,940.378967 740.358887,939.722229 
	C737.603577,938.530334 735.315186,937.771179 732.991943,936.715149 
	C732.884155,936.029175 732.811340,935.640015 732.992920,935.112305 
	C733.487854,934.677734 733.728271,934.381775 734.438660,934.045410 
	C742.791504,934.004944 750.674438,934.004944 759.337769,934.004944 
	C759.337769,926.623291 759.337769,920.071167 759.337769,913.519043 
	C759.864685,913.524597 760.391541,913.530090 760.918457,913.535645 
	C760.918457,920.148621 760.918457,926.761597 760.918457,933.236938 
	C769.079773,933.236938 776.458069,933.236938 783.772827,933.609070 
	C779.504578,934.588928 775.299866,935.196716 771.095093,935.804504 
	C771.141174,936.325439 771.187195,936.846375 771.233276,937.367310 
	C776.702148,937.367310 782.170959,937.367310 788.429199,937.367310 
	C787.353882,935.849670 786.658325,934.867920 786.398438,933.884644 
	C788.575378,933.859375 790.316650,933.835693 791.908691,934.096924 
	C790.216187,939.437927 794.130676,935.847168 794.879028,936.741150 
	C795.312256,935.360474 795.671021,934.216919 796.264160,933.076172 
	C796.498413,933.078918 796.966858,933.095642 797.209839,933.369019 
	C798.913330,938.082153 802.319946,938.508301 806.265564,938.018433 
	C807.725769,937.837219 809.245972,937.835205 810.705994,938.016296 
	C814.494568,938.486206 816.367310,937.019226 816.376587,933.106323 
	C817.088440,933.122864 817.495117,933.137146 817.937256,933.508301 
	C818.815308,934.792542 819.657959,935.719910 820.500549,936.647217 
	C821.681824,935.451721 822.863159,934.256226 824.275940,933.057495 
	C824.507385,933.054382 824.970032,933.064758 825.155823,933.401550 
	C825.718201,937.505127 828.017395,938.391418 831.416016,938.039551 
	C833.692383,937.803894 836.032471,938.161621 838.302673,937.897705 
	C839.761963,937.728027 841.146179,936.911926 842.564087,936.385620 
	C841.748962,935.284546 840.933777,934.183472 840.579224,933.057556 
	C860.638794,932.699829 880.236755,932.258118 899.837036,932.066040 
	C917.797791,931.890076 935.761475,932.039917 953.723022,931.916382 
	C957.959595,931.887268 962.912842,933.588806 965.533936,928.154907 
	C965.648132,927.918091 966.477112,928.026123 967.335571,928.043823 
	C968.650269,930.661316 969.602661,933.208496 970.554993,935.755615 
	C970.981812,935.803101 971.408630,935.850525 971.835449,935.898010 
	C971.835449,928.088928 971.835449,920.279907 971.966736,912.134399 
	C972.239746,910.850037 972.381531,909.902222 972.942627,908.978149 
	C980.991211,909.360962 988.617371,909.885193 996.250793,910.034668 
	C1004.548279,910.197205 1012.852539,910.004761 1021.153870,909.979309 
	C1029.620117,909.953308 1038.099365,910.226501 1046.548828,909.841187 
	C1054.410278,909.482727 1062.208496,910.805847 1070.177979,909.342163 
	C1076.597046,908.163208 1083.412720,909.142944 1090.052368,909.164856 
M1035.583130,915.614868 
	C1036.356079,916.859924 1037.424561,918.011719 1037.851929,919.365845 
	C1039.913452,925.897766 1045.679932,933.240051 1054.990234,933.591003 
	C1054.990234,926.559753 1054.990234,919.536987 1054.990234,912.286377 
	C1042.718506,912.286377 1030.050903,912.286377 1017.364136,912.286377 
	C1017.550293,916.148071 1017.783264,919.591858 1017.864014,923.039185 
	C1017.947327,926.596680 1017.882446,930.157715 1017.882446,933.687378 
	C1026.955566,933.759155 1033.578003,926.599915 1035.583130,915.614868 
M932.208374,936.857056 
	C943.975464,936.417664 955.742554,935.978271 967.509644,935.538879 
	C967.560120,935.026123 967.610535,934.513367 967.661011,934.000549 
	C965.931213,933.682861 964.196716,933.067261 962.472412,933.094727 
	C948.932251,933.309875 935.395264,933.738037 921.854858,933.921814 
	C909.745117,934.086182 897.631958,933.980835 885.521423,934.107300 
	C883.753296,934.125732 881.991821,934.771057 880.227234,935.126404 
	C880.343445,935.706238 880.459656,936.286072 880.575867,936.865906 
	C897.470154,936.865906 914.364380,936.865906 932.208374,936.857056 
M752.476379,935.062927 
	C749.453125,935.323547 746.429932,935.584167 743.406677,935.844727 
	C743.486328,936.299072 743.565979,936.753418 743.645630,937.207764 
	C748.708130,937.207764 753.770691,937.207764 758.833191,937.207764 
	C758.841309,936.666504 758.849365,936.125305 758.857483,935.584045 
	C757.037415,935.411804 755.217407,935.239624 752.476379,935.062927 
M856.918091,937.055725 
	C860.782654,937.055725 864.647217,937.055725 869.423340,937.055725 
	C865.791565,933.365173 856.294067,933.373779 853.083252,936.479004 
	C854.267090,936.708618 855.155334,936.880920 856.918091,937.055725 
M763.220642,935.153503 
	C764.795166,935.850342 766.369629,936.547180 767.944092,937.244019 
	C768.116516,936.580200 768.288940,935.916443 768.461426,935.252625 
	C766.963928,935.174438 765.466370,935.096313 763.220642,935.153503 
M990.467163,912.050049 
	C989.971436,912.144836 989.475708,912.239685 988.979919,912.334534 
	C989.032288,912.511658 989.084656,912.688782 989.136963,912.865967 
	C989.776917,912.593994 990.416809,912.322021 990.467163,912.050049 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2027.942261,561.883972 
	C2027.942261,559.170471 2027.942261,556.457031 2027.942261,553.743530 
	C2028.616821,553.715027 2029.291382,553.686523 2029.966064,553.658020 
	C2030.823486,565.244446 2031.681030,576.830933 2032.617554,589.486084 
	C2072.469238,590.048035 2111.927734,595.948181 2150.582520,608.062805 
	C2150.091553,609.707886 2149.490479,610.794861 2148.709717,612.207336 
	C2154.865479,614.246399 2160.519531,616.038391 2166.100830,618.033813 
	C2167.263916,618.449707 2168.158691,619.617615 2168.738281,620.361816 
	C2160.483643,618.113037 2152.630127,616.066772 2144.869629,613.713440 
	C2141.354248,612.647400 2139.061768,612.677246 2138.591064,617.096436 
	C2138.478516,618.151794 2137.241943,619.087524 2136.522217,620.078247 
	C2136.160400,619.882874 2135.798584,619.687439 2135.436768,619.492065 
	C2135.864746,616.999084 2136.292725,614.506104 2136.705078,612.103210 
	C2119.521729,604.177307 2050.944336,595.828064 2033.291992,597.609924 
	C2033.291992,611.168762 2033.319580,624.768066 2033.268555,638.367126 
	C2033.258423,641.065369 2033.882080,645.389954 2032.587158,646.133972 
	C2029.541016,647.884033 2030.124634,650.123291 2030.082642,652.404602 
	C2029.974121,658.301575 2030.046265,664.201904 2030.046265,670.100891 
	C2028.885498,665.454529 2028.832886,660.822510 2028.900146,656.192261 
	C2028.969116,651.458313 2029.284302,646.665955 2023.578979,645.147461 
	C2024.141113,642.907654 2024.988281,641.096008 2024.991089,639.283020 
	C2025.025024,617.812439 2024.881226,596.341431 2024.924438,574.870911 
	C2024.931641,571.300903 2023.912109,569.824219 2020.066772,569.953125 
	C2015.688354,570.099792 2011.283936,569.466309 2006.944580,568.822510 
	C2006.287720,567.322083 2005.780884,565.939270 2004.809448,565.084717 
	C2003.807251,564.203125 2002.352783,563.835510 2001.096191,563.243103 
	C2003.513062,563.189392 2005.930054,563.135620 2008.683228,563.466675 
	C2009.058838,564.289124 2009.098267,564.726929 2009.118652,565.555664 
	C2009.409424,566.482300 2009.718628,567.483704 2010.029175,567.484192 
	C2014.989136,567.491211 2019.955322,567.470947 2024.902832,567.172241 
	C2025.663818,567.126282 2026.339233,565.664978 2027.074219,564.545654 
	C2027.376831,563.450012 2027.659546,562.666992 2027.942261,561.883972 
M2030.923096,627.513550 
	C2030.805664,619.429993 2030.736084,611.345154 2030.513428,603.264526 
	C2030.483398,602.178101 2029.680420,601.113037 2029.235352,600.038086 
	C2028.640625,601.131592 2027.474609,602.259399 2027.538086,603.313354 
	C2028.323975,616.378967 2029.287720,629.433960 2030.218872,642.491028 
	C2030.232544,642.682617 2030.417847,642.861938 2030.880859,643.676575 
	C2030.880859,638.095520 2030.880859,633.300476 2030.923096,627.513550 
M2128.055176,606.246582 
	C2139.111816,610.417969 2145.296631,611.396606 2148.610840,608.613586 
	C2139.194336,606.272583 2130.437988,604.095703 2121.681396,601.918823 
	C2121.464844,602.675598 2121.248047,603.432434 2121.031250,604.189209 
	C2123.101318,604.829773 2125.171631,605.470337 2128.055176,606.246582 
M2082.652100,597.922729 
	C2086.034424,598.210999 2089.416748,598.499268 2092.799072,598.787598 
	C2092.858643,598.032104 2092.918213,597.276672 2092.978027,596.521240 
	C2084.551514,595.601440 2076.125244,594.681641 2067.698730,593.761841 
	C2067.613037,594.386475 2067.527100,595.011047 2067.441162,595.635681 
	C2072.214844,596.371460 2076.988281,597.107300 2082.652100,597.922729 
M2027.822876,589.158203 
	C2027.737915,589.026245 2027.652954,588.894287 2027.567993,588.762451 
	C2027.478760,588.837158 2027.314087,588.909790 2027.312012,588.986938 
	C2027.291992,589.696045 2027.300293,590.405945 2027.300293,591.115601 
	C2027.447144,591.110535 2027.593872,591.105469 2027.740723,591.100403 
	C2027.787354,590.645020 2027.833984,590.189636 2027.822876,589.158203 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M684.724182,454.640747 
	C684.036377,455.093597 683.457336,455.077332 682.591614,455.090576 
	C682.213684,450.626251 682.070740,446.132812 682.039062,441.638550 
	C681.690125,392.177277 681.401917,342.715515 680.998230,293.254700 
	C680.859009,276.203278 680.366089,259.154816 680.220947,242.103394 
	C680.192810,238.795807 679.075989,237.819244 675.860596,237.863312 
	C664.032227,238.025421 652.200562,237.949768 640.370178,237.962814 
	C638.485352,237.964890 636.600525,237.963089 634.715698,237.963089 
	C634.721436,237.396164 634.727173,236.829224 634.732849,236.262299 
	C649.638000,236.262299 664.543091,236.262299 679.415344,236.262299 
	C679.415344,202.215118 679.415344,169.007614 679.415344,137.445908 
	C673.379639,133.529953 668.164246,130.962479 663.957397,127.251617 
	C654.372437,118.796631 656.019165,104.311668 665.479187,97.029274 
	C672.918884,91.302124 680.948914,90.016502 689.251892,94.437134 
	C697.083557,98.606812 701.445496,105.701973 700.679688,114.480919 
	C699.966003,122.662804 696.230164,129.976181 687.726440,132.947220 
	C683.022156,134.590790 681.596497,137.036865 681.767334,141.983078 
	C682.204224,154.632523 681.859924,167.306671 682.009644,179.968811 
	C682.261536,201.269363 682.764526,222.567230 682.963989,243.868073 
	C683.371460,287.391052 683.618225,330.915497 683.988647,374.438873 
	C684.214844,401.016907 684.547913,427.594086 684.724182,454.640747 
M681.220947,93.995949 
	C670.712769,94.125595 664.405029,98.526932 660.834534,108.221062 
	C658.533264,114.469360 661.469360,122.771469 668.035217,128.581467 
	C673.182129,133.135834 682.084045,133.692474 688.514099,130.114395 
	C695.427246,126.267479 699.405823,117.351799 698.173645,110.966095 
	C696.432312,101.941841 691.011169,96.267052 681.220947,93.995949 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2447.075195,938.939575 
	C2448.306641,945.009644 2449.538330,951.079651 2450.497803,957.565430 
	C2449.823730,957.984192 2449.421631,957.987183 2448.975830,957.570923 
	C2448.158203,952.117249 2447.384521,947.082764 2446.611084,942.048218 
	C2445.815674,942.091003 2445.020508,942.133789 2444.225098,942.176575 
	C2444.225098,944.196167 2443.972900,946.253662 2444.269287,948.228821 
	C2445.412354,955.842407 2446.730713,963.429626 2447.963379,971.438232 
	C2448.616211,980.541626 2449.289062,989.233521 2449.934082,998.385620 
	C2450.585693,1002.694763 2451.264893,1006.543640 2451.951172,1010.647461 
	C2451.964600,1011.242188 2451.970947,1011.582031 2451.667480,1012.207275 
	C2451.133545,1015.969360 2450.910156,1019.445923 2450.400879,1022.944519 
	C2449.733398,1022.982300 2449.351562,1022.998047 2448.937500,1022.651855 
	C2448.905518,1021.171875 2448.905518,1020.053833 2448.905518,1018.849426 
	C2447.665283,1019.623047 2446.681152,1020.770325 2445.742920,1020.733826 
	C2440.547363,1020.531616 2435.361572,1020.058838 2430.177490,1019.616699 
	C2429.747070,1019.579956 2429.268311,1019.155151 2428.960205,1018.786682 
	C2425.785400,1014.990234 2421.761230,1014.167297 2417.018799,1014.847900 
	C2415.135986,1015.118042 2411.736572,1013.496094 2411.352051,1014.134949 
	C2408.704346,1018.532593 2407.431885,1014.452393 2405.745117,1013.353333 
	C2405.560059,1014.152039 2405.308838,1015.303711 2405.291748,1015.300232 
	C2401.555664,1014.542175 2397.971191,1011.785583 2393.994629,1014.735107 
	C2392.839844,1015.591736 2391.128418,1017.027893 2390.279541,1016.686768 
	C2388.574219,1016.001526 2387.419189,1014.054504 2385.772949,1013.070435 
	C2384.918945,1012.559875 2383.500488,1013.009155 2382.337402,1012.997375 
	C2375.867920,1012.931702 2369.398682,1012.857605 2362.930664,1012.629883 
	C2362.932373,1012.473389 2362.993164,1012.166321 2363.281982,1011.856934 
	C2370.023193,1011.383911 2376.479492,1011.302002 2382.925293,1010.998779 
	C2384.512939,1010.924072 2386.066895,1010.128906 2387.636230,1009.664978 
	C2387.519531,1009.091858 2387.402588,1008.518799 2387.285889,1007.945679 
	C2383.683594,1007.945679 2380.073730,1008.079895 2376.483398,1007.873413 
	C2375.212402,1007.800293 2373.227051,1007.201904 2372.873291,1006.320007 
	C2371.156982,1002.038574 2369.883789,997.579590 2368.453857,993.183411 
	C2370.270752,994.756897 2372.188965,996.577026 2372.466797,998.620239 
	C2373.073975,1003.086914 2375.743896,1003.132141 2379.168945,1003.072510 
	C2401.066162,1002.691223 2422.965332,1002.428772 2444.809570,1002.510742 
	C2444.385254,1003.190857 2444.020264,1003.740540 2443.644531,1003.747742 
	C2427.234131,1004.059753 2410.823486,1004.353455 2394.411621,1004.578918 
	C2387.897949,1004.668396 2381.382080,1004.593628 2374.867188,1004.593628 
	C2374.870605,1004.997009 2374.873779,1005.400330 2374.876953,1005.803711 
	C2375.834961,1005.885315 2376.793945,1006.045166 2377.751465,1006.037170 
	C2400.139160,1005.850891 2422.526367,1005.613586 2444.914551,1005.482422 
	C2448.105957,1005.463684 2447.241699,1003.666504 2446.919922,1001.463989 
	C2446.964600,977.189575 2443.446533,953.791077 2438.326172,930.625122 
	C2436.337158,921.625977 2433.487549,912.817078 2431.017578,903.541077 
	C2429.939697,901.708130 2428.874512,900.255676 2428.050781,898.568726 
	C2428.460693,896.764709 2429.198730,894.987000 2428.712158,893.656799 
	C2421.774658,874.680908 2414.671143,855.765625 2407.994629,836.890320 
	C2409.008545,837.367493 2409.635986,837.790039 2410.279785,838.532166 
	C2411.879150,842.062256 2413.451172,845.278320 2415.048096,848.481934 
	C2417.337158,853.074036 2419.644287,857.656982 2421.957031,862.647949 
	C2424.297852,871.301941 2426.625244,879.551880 2429.056152,888.168701 
	C2431.152344,887.270325 2432.481934,886.700562 2433.811035,886.130859 
	C2435.298096,890.617493 2436.305420,895.358398 2438.488525,899.475952 
	C2439.474609,901.335632 2442.800781,901.954590 2445.393555,903.307800 
	C2443.838379,903.662781 2442.116455,904.055847 2439.999512,904.539062 
	C2440.932617,908.758728 2441.851074,912.912781 2442.495850,917.452881 
	C2441.540039,917.535217 2440.858154,917.231445 2439.339111,916.554749 
	C2440.313721,920.506836 2441.116943,923.764526 2441.673340,927.146362 
	C2441.555420,928.014954 2441.465576,928.923218 2441.869141,929.462830 
	C2442.381592,930.148315 2443.293701,930.534912 2444.033691,931.050354 
	C2444.331787,931.806641 2444.629883,932.562927 2444.953857,933.963867 
	C2444.135254,936.029968 2443.290771,937.451477 2441.763428,940.022400 
	C2444.304443,939.504395 2445.689697,939.221985 2447.075195,938.939575 
M2405.851318,1010.801208 
	C2406.476074,1010.190735 2407.139648,1009.015564 2407.719727,1009.055420 
	C2415.208008,1009.570190 2422.676514,1010.424805 2430.169678,1010.780579 
	C2431.960693,1010.865601 2433.823975,1009.433899 2435.654297,1008.699768 
	C2432.006348,1007.448853 2428.852051,1007.082581 2425.694824,1007.057068 
	C2416.037598,1006.979065 2406.375488,1006.885193 2396.723633,1007.132690 
	C2394.166016,1007.198303 2391.639160,1008.470825 2389.097900,1009.189148 
	C2389.262695,1009.870300 2389.427490,1010.551392 2389.592285,1011.232544 
	C2394.085693,1010.455872 2398.579102,1009.679260 2401.807373,1009.121277 
	C2403.351562,1009.924011 2404.269287,1010.401001 2405.851318,1010.801208 
M2435.243896,901.971069 
	C2434.586914,897.686951 2433.929932,893.402893 2433.272949,889.118774 
	C2432.315918,889.295044 2431.358887,889.471313 2430.401855,889.647583 
	C2430.710938,893.926331 2432.654541,897.856995 2435.243896,901.971069 
M2441.733887,1006.956909 
	C2440.161377,1007.223389 2438.588867,1007.489929 2436.376709,1007.864868 
	C2439.260498,1009.191040 2441.123291,1010.566345 2443.132568,1010.832642 
	C2445.352051,1011.126648 2448.733398,1012.037231 2448.575439,1006.954346 
	C2446.491211,1006.954346 2444.548828,1006.954346 2441.733887,1006.956909 
M2435.073486,906.470642 
	C2435.928223,909.345459 2436.782959,912.220276 2437.637451,915.095093 
	C2438.456543,914.850220 2439.275391,914.605286 2440.094238,914.360413 
	C2439.194336,911.120239 2438.294678,907.880066 2437.395020,904.639954 
	C2436.598877,904.987305 2435.802490,905.334656 2435.073486,906.470642 
M2441.708740,934.585571 
	C2441.998779,934.368469 2442.289062,934.151306 2442.579102,933.934204 
	C2442.402588,933.749146 2442.225830,933.564026 2442.049316,933.378906 
	C2441.822510,933.659424 2441.595703,933.939880 2441.708740,934.585571 
M2426.665039,1014.124146 
	C2427.239014,1014.005920 2427.813232,1013.887756 2428.387207,1013.769531 
	C2428.224609,1013.450867 2428.062012,1013.132263 2427.899170,1012.813599 
	C2427.276367,1013.209473 2426.653564,1013.605408 2426.665039,1014.124146 
M2440.616455,1018.000122 
	C2440.863525,1018.158813 2441.110352,1018.317505 2441.357422,1018.476074 
	C2441.445801,1018.206360 2441.534180,1017.936646 2441.622559,1017.666931 
	C2441.255127,1017.628113 2440.887451,1017.589355 2440.616455,1018.000122 
M2389.728516,1014.794128 
	C2389.992432,1014.687073 2390.256592,1014.580078 2390.520508,1014.473022 
	C2390.428711,1014.346741 2390.312256,1014.101501 2390.249756,1014.114624 
	C2389.978027,1014.171692 2389.722656,1014.304016 2389.728516,1014.794128 
M2422.231445,1013.490173 
	C2422.397705,1013.506531 2422.563721,1013.522888 2422.729980,1013.539368 
	C2422.655029,1013.448547 2422.580078,1013.357727 2422.231445,1013.490173 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M106.986038,582.029358 
	C106.986046,564.266296 106.986046,546.503235 106.986046,528.148438 
	C102.085220,528.148438 97.809891,528.062683 93.539192,528.166931 
	C85.572784,528.361389 77.607384,528.882629 69.645035,528.820923 
	C66.356606,528.795471 64.973923,529.889404 64.090591,532.918091 
	C60.131165,546.493347 48.221481,550.626221 36.570961,546.856384 
	C22.675539,542.360107 19.301929,523.017944 27.128271,514.378906 
	C35.095535,505.584290 45.471107,502.878540 53.828823,508.116943 
	C60.368217,512.215576 65.228493,517.838013 65.035324,526.909302 
	C70.250526,526.653381 75.105225,526.291321 79.964783,526.205872 
	C87.119583,526.080139 94.282410,525.995361 101.432770,526.203674 
	C105.136368,526.311523 106.254753,525.051697 106.228828,521.326050 
	C106.039932,494.195526 106.061783,467.063568 106.249329,439.308746 
	C106.316917,438.123352 106.152222,437.561279 105.987534,436.999176 
	C105.981445,428.304230 105.975349,419.609314 106.247650,410.451355 
	C106.526047,409.988373 106.994064,409.996948 107.192673,410.260895 
	C108.102539,410.895660 108.813797,411.266479 108.400215,411.050842 
	C106.406441,420.004486 109.051788,428.567993 107.820648,436.527527 
	C109.544731,437.116669 110.790718,437.542419 112.044716,438.434387 
	C112.708008,450.084595 114.058510,461.283478 113.819946,472.448425 
	C113.588715,483.269806 115.367050,494.001831 113.380806,504.992706 
	C111.723335,514.164246 112.657005,523.881714 113.069534,533.330017 
	C113.585518,545.148438 111.194366,557.115112 114.856468,568.811218 
	C115.327446,570.315430 114.367271,572.267761 113.755310,574.009888 
	C113.023514,574.028076 112.606461,574.049011 112.133026,573.632080 
	C111.807632,571.132751 111.538620,569.071350 111.269600,567.009949 
	C110.518013,567.042542 109.766434,567.075134 109.014847,567.107666 
	C109.014847,570.510742 109.225685,573.932617 108.923317,577.308594 
	C108.778351,578.927002 107.662292,580.458496 106.986038,582.029358 
M55.003429,512.997681 
	C55.003429,512.997681 54.996807,513.029907 55.010815,512.283325 
	C54.108112,511.405579 53.351830,510.238037 52.279411,509.696198 
	C44.071758,505.549133 33.964966,508.223907 28.506105,516.150085 
	C23.621944,523.241882 23.857872,533.667297 30.555532,540.279114 
	C38.367027,547.990540 47.739975,547.086670 54.179821,543.026855 
	C62.056278,538.061401 65.387886,528.021484 61.693069,519.944336 
	C60.430202,517.183594 57.266445,515.292297 55.003429,512.997681 
M111.876419,484.797058 
	C111.385246,480.544312 110.894073,476.291565 110.288040,471.044250 
	C107.062546,476.033112 107.947212,498.640320 111.879906,502.937164 
	C111.879906,496.388306 111.879906,491.065369 111.876419,484.797058 
M108.267593,514.279724 
	C108.470795,515.404419 108.835381,516.527100 108.849449,517.654175 
	C108.912941,522.740479 108.877182,527.827942 108.877182,532.915039 
	C109.398560,532.907837 109.919945,532.900574 110.441322,532.893311 
	C110.441322,524.000732 110.441322,515.108093 110.441322,506.215424 
	C110.072006,506.218811 109.702698,506.222168 109.333382,506.225555 
	C108.944870,508.636932 108.556351,511.048279 108.267593,514.279724 
M110.575943,449.028137 
	C110.367233,451.992371 110.158524,454.956604 109.949806,457.920807 
	C110.265686,457.934052 110.581558,457.947296 110.897430,457.960541 
	C110.897430,455.191284 110.897430,452.422058 110.575943,449.028137 
M110.107368,443.767853 
	C110.212746,444.196747 110.318123,444.625641 110.423500,445.054565 
	C110.623405,444.968445 110.823303,444.882355 111.023201,444.796265 
	C110.741112,444.281799 110.459030,443.767365 110.107368,443.767853 
M110.053574,464.755737 
	C110.174179,465.196716 110.294792,465.637695 110.727745,467.220703 
	C110.851082,465.502075 110.955292,465.029266 110.879341,464.587463 
	C110.852043,464.428711 110.390839,464.344574 110.053574,464.755737 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M208.976929,944.877197 
	C188.839783,944.991455 168.700638,944.966675 148.565994,945.255432 
	C123.641655,945.612793 98.721703,946.270203 73.797905,946.679993 
	C70.991310,946.726135 69.852066,947.726685 68.883080,950.368591 
	C65.121284,960.624695 57.157452,966.356567 48.072384,965.757080 
	C47.064007,965.690491 46.081047,965.238708 45.747757,965.147217 
	C51.085423,962.947510 57.533077,961.765930 61.889984,958.099792 
	C66.705650,954.047485 68.097000,947.325623 67.226128,940.628540 
	C66.331322,933.747375 59.618137,927.032349 52.240234,925.879883 
	C43.060055,924.445984 35.743690,928.392395 31.810982,935.758606 
	C27.939894,943.009460 30.125635,954.678040 38.245075,961.021606 
	C34.172421,961.611389 29.158297,955.675964 28.042614,948.944458 
	C25.955656,936.352905 33.136822,925.631592 45.184277,923.352295 
	C56.935810,921.128967 67.343376,929.465271 70.400307,944.086731 
	C74.195366,944.086731 78.137222,944.086731 82.891159,944.043335 
	C84.164230,943.955017 84.625221,943.909973 85.308365,943.904297 
	C85.530518,943.943604 85.975449,943.863953 86.351532,943.919189 
	C88.546761,943.964172 90.365974,943.960999 92.185043,943.942566 
	C114.439987,943.716919 136.694901,943.489319 159.248779,943.440430 
	C161.886368,943.898560 164.460312,944.676636 164.982361,940.712402 
	C164.995010,939.894165 164.985153,939.426086 164.966614,938.492004 
	C164.899826,915.464111 164.841736,892.902283 165.084915,870.142761 
	C165.677048,869.568176 165.967911,869.191223 166.670197,868.846313 
	C167.743484,870.337891 168.953308,871.785828 168.984421,873.258667 
	C169.400970,892.984253 169.645737,912.713440 169.958038,932.441345 
	C169.985779,934.193726 170.186813,935.943359 170.324203,937.938843 
	C174.857010,937.938843 178.827469,937.857422 182.792999,937.962036 
	C186.634521,938.063293 189.359253,936.464355 191.220764,933.003174 
	C191.211838,932.925049 191.061508,932.912292 190.531738,932.864014 
	C189.474915,932.514771 188.797562,932.200989 188.120209,931.887146 
	C188.507462,931.272583 189.058014,930.077454 189.256760,930.133362 
	C191.243973,930.692261 193.173279,931.457092 195.093491,932.547485 
	C195.789688,935.160461 196.511292,937.389771 196.769318,939.766235 
	C187.840912,939.974915 179.374863,939.976624 170.912323,940.149841 
	C169.260849,940.183655 167.623001,940.882935 165.978867,941.275330 
	C166.066559,941.929016 166.154251,942.582703 166.241959,943.236389 
	C180.137115,943.236389 194.032272,943.236389 208.056366,943.444824 
	C208.449173,944.061218 208.713043,944.469177 208.976929,944.877197 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2009.005371,89.107132 
	C2012.808716,86.419029 2016.612061,83.730957 2020.837891,81.432938 
	C2022.594604,82.202850 2023.913086,82.653526 2025.265381,82.950089 
	C2030.646118,84.130005 2036.451050,84.414230 2039.744873,89.613503 
	C2038.312866,92.229912 2037.060181,94.518616 2035.603760,96.935410 
	C2035.142334,97.267838 2034.949341,97.521378 2034.535278,98.023041 
	C2033.458374,99.606361 2032.667114,100.990768 2031.487671,103.054565 
	C2033.624878,103.414391 2035.013062,103.691589 2036.413940,103.871811 
	C2037.826050,104.053528 2039.250488,104.138954 2040.764771,104.275139 
	C2041.021973,107.476234 2042.137207,110.571037 2041.221802,112.850380 
	C2040.207642,115.376007 2037.675659,117.751007 2035.213135,119.151169 
	C2027.432373,123.575294 2017.375977,121.787651 2010.991577,115.357689 
	C2006.082764,110.413750 2005.011597,100.114235 2008.504395,92.918320 
	C2009.035400,91.824570 2008.856079,90.386147 2009.005371,89.107132 
M2022.635254,88.861092 
	C2021.078491,89.562187 2019.521729,90.263283 2018.905518,90.540833 
	C2022.411377,97.471779 2020.809814,103.723404 2020.385254,109.843193 
	C2021.938354,109.843193 2023.555420,109.843193 2024.575195,109.843193 
	C2024.039429,107.819916 2023.190186,105.919312 2023.090942,103.980362 
	C2022.846069,99.187149 2022.944702,94.376366 2022.635254,88.861092 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1204.056274,529.087219 
	C1205.748169,529.079224 1207.439941,529.071228 1209.650513,529.423340 
	C1210.239380,530.001465 1210.309448,530.219482 1210.097290,530.677979 
	C1208.157715,533.623108 1209.837891,533.994568 1211.985840,533.995789 
	C1217.092041,533.998840 1222.206665,534.131348 1227.296875,533.841125 
	C1228.244995,533.786987 1229.094360,532.003174 1229.989746,530.693970 
	C1230.002686,529.939514 1230.015137,529.508667 1230.027588,529.077759 
	C1250.084961,528.991821 1270.142578,528.930176 1290.199707,528.797546 
	C1293.823853,528.773560 1297.446167,528.457947 1301.513916,528.625488 
	C1301.976929,529.324951 1301.995361,529.675720 1301.544312,530.051270 
	C1294.419067,530.230713 1287.710205,529.744446 1281.099609,530.445557 
	C1281.134766,531.145569 1281.169800,531.845642 1281.204956,532.545654 
	C1291.043213,532.545654 1300.881592,532.545654 1310.719849,532.545654 
	C1310.769653,531.895874 1310.819458,531.246033 1310.869263,530.596252 
	C1308.583618,530.388733 1306.298096,530.181213 1304.014771,529.719360 
	C1304.051270,529.131897 1304.085571,528.798645 1304.119873,528.465454 
	C1306.900757,528.349976 1309.681763,528.234497 1312.973511,528.101685 
	C1313.484375,528.084290 1313.777588,528.086060 1313.777588,528.086060 
	C1314.567261,528.100403 1315.356812,528.114807 1316.554199,528.455933 
	C1316.958740,529.164856 1316.955688,529.547119 1316.621094,529.947632 
	C1315.876099,530.126343 1315.455444,530.271912 1315.058350,530.465698 
	C1315.008301,530.490112 1315.081421,530.908386 1315.098267,530.908447 
	C1321.459106,530.928650 1327.820068,530.928223 1334.181030,530.928223 
	C1334.172974,530.581543 1334.164917,530.234863 1334.156860,529.888184 
	C1330.126953,529.888184 1326.096924,529.888184 1322.053223,529.595459 
	C1322.041748,528.913086 1322.043945,528.523315 1322.046143,528.133606 
	C1346.189697,528.046631 1370.333618,528.026550 1394.476562,527.850891 
	C1412.919189,527.716736 1431.360352,527.378906 1449.802490,527.150085 
	C1452.258667,527.119568 1454.716064,527.197388 1457.573730,527.543457 
	C1457.989258,528.252991 1458.004150,528.644043 1457.551514,529.060913 
	C1453.132568,529.105469 1449.173340,528.995117 1445.234985,529.232788 
	C1444.234009,529.293213 1443.299683,530.459778 1442.334595,531.118042 
	C1443.338989,531.538025 1444.336060,532.296509 1445.349243,532.318848 
	C1449.539673,532.411743 1453.735229,532.281799 1457.949951,532.567993 
	C1457.595947,533.649170 1457.220825,534.395935 1456.389404,535.111572 
	C1449.964478,534.699707 1443.995605,534.319031 1438.055054,533.642822 
	C1437.773804,531.896912 1437.524780,529.201355 1437.145996,529.182922 
	C1431.553101,528.911682 1425.942993,528.996094 1418.987183,528.996094 
	C1421.040405,531.556152 1422.026733,532.786011 1422.610840,534.041016 
	C1420.390991,533.789612 1418.573608,533.513062 1416.818726,532.922485 
	C1416.747437,531.413513 1416.613525,530.218628 1416.526367,529.441467 
	C1409.199463,529.441467 1401.845215,529.441467 1394.490967,529.441467 
	C1394.330811,529.786621 1394.170654,530.131714 1394.010498,530.476868 
	C1395.348877,531.660828 1396.687256,532.844849 1397.596558,534.058960 
	C1390.927002,534.621338 1384.686523,535.153564 1378.249390,535.702576 
	C1378.059204,538.317444 1377.875366,540.844177 1377.691650,543.370972 
	C1377.223999,543.451660 1376.756226,543.532288 1376.288574,543.612976 
	C1375.814819,541.912170 1375.535522,540.121643 1374.805054,538.539429 
	C1374.230835,537.295349 1373.202271,535.469666 1372.198730,535.347107 
	C1366.783569,534.685120 1361.314331,534.198425 1355.861572,534.174744 
	C1348.586060,534.143127 1341.308350,534.585266 1334.031982,534.835999 
	C1322.129639,535.246033 1310.227661,535.665894 1298.708496,536.068237 
	C1297.940552,538.537415 1297.397949,540.281982 1296.855347,542.026550 
	C1296.467163,541.917358 1296.079102,541.808228 1295.690918,541.699097 
	C1295.690918,539.972778 1295.690918,538.246521 1295.690918,536.306824 
	C1288.325684,535.967224 1281.248657,535.640869 1273.789062,535.226379 
	C1272.889648,535.181580 1272.372437,535.224976 1271.431885,535.236633 
	C1269.029541,535.175049 1267.050415,535.145142 1264.743896,535.051880 
	C1263.977905,534.919617 1263.539307,534.850708 1263.203247,534.612122 
	C1263.485107,534.237671 1263.705200,534.096436 1264.292725,534.009949 
	C1265.053589,533.982361 1265.487671,533.963440 1266.387451,533.961426 
	C1270.584229,532.678284 1276.025635,536.845520 1278.911377,530.010254 
	C1274.716309,530.010254 1271.260742,530.010315 1267.805176,530.010315 
	C1264.373291,530.010315 1260.941284,530.010254 1256.776855,530.010254 
	C1257.271118,532.110474 1257.590698,533.468079 1257.488525,534.880981 
	C1255.355469,535.025696 1253.644287,535.115051 1251.505859,535.178955 
	C1249.364746,535.123291 1247.650879,535.093262 1246.207764,534.760620 
	C1248.924194,532.784668 1253.967407,536.328003 1254.633057,530.024475 
	C1248.082886,530.024475 1241.791748,529.956421 1235.506104,530.108948 
	C1234.703979,530.128418 1233.929932,531.298218 1233.142822,531.935059 
	C1234.044678,532.626526 1234.867188,533.744690 1235.863037,533.929504 
	C1238.881836,534.489868 1241.967773,534.688843 1244.562866,535.048584 
	C1235.433838,535.736450 1226.769287,536.404419 1217.708862,537.003235 
	C1215.742920,536.733398 1214.172852,536.532593 1212.205078,536.316162 
	C1209.202637,535.949768 1206.598022,535.599121 1204.164062,534.946777 
	C1209.713135,532.518372 1204.812500,530.906616 1204.056274,529.087219 
M1374.507812,529.857971 
	C1370.052612,530.237427 1365.379761,529.049011 1359.842529,531.058167 
	C1366.655762,534.191772 1389.737915,533.242249 1391.611694,529.847717 
	C1386.026978,529.847717 1380.765015,529.847717 1374.507812,529.857971 
M1315.417114,532.988892 
	C1321.899292,532.988892 1328.381470,532.988892 1334.863647,532.988892 
	C1328.272095,532.012695 1321.691650,532.006287 1315.111450,532.051880 
	C1314.957886,532.052917 1314.808228,532.637756 1315.417114,532.988892 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2362.993164,1012.166321 
	C2362.993164,1012.166321 2362.932373,1012.473389 2362.481445,1012.721008 
	C2358.750732,1012.968628 2355.470947,1012.968628 2351.594482,1012.968628 
	C2351.594482,1020.419678 2351.594482,1027.093750 2351.594482,1033.315308 
	C2369.611572,1033.315308 2387.204590,1033.315308 2404.852539,1033.590820 
	C2404.934082,1034.240112 2404.960693,1034.613647 2404.520508,1035.026245 
	C2392.425049,1035.254883 2380.796875,1035.519165 2369.166992,1035.616211 
	C2353.380371,1035.748047 2337.592041,1035.723022 2321.805176,1035.846680 
	C2319.804932,1035.862427 2317.808594,1036.369263 2315.810303,1036.648682 
	C2315.838867,1037.158325 2315.867432,1037.667847 2315.895996,1038.177490 
	C2316.750488,1038.451660 2317.607422,1038.971558 2318.459473,1038.963867 
	C2334.736328,1038.817871 2351.012207,1038.552856 2367.289551,1038.447021 
	C2372.548340,1038.412964 2377.810059,1038.763184 2383.008301,1039.156494 
	C2382.946045,1039.375244 2382.883789,1039.824219 2382.883789,1039.824219 
	C2358.303955,1040.070312 2333.724121,1040.316284 2308.794189,1040.203125 
	C2309.773193,1039.392334 2311.102295,1038.940674 2312.677246,1038.405396 
	C2312.814209,1037.067627 2312.962646,1035.617432 2313.495605,1034.034668 
	C2315.519775,1033.735718 2317.157227,1033.448975 2318.798584,1033.424194 
	C2326.111084,1033.313477 2333.428711,1033.379639 2340.734619,1033.112305 
	C2341.927979,1033.068726 2344.037598,1031.724365 2344.082031,1030.893188 
	C2344.381836,1025.300781 2344.238281,1019.684570 2344.238281,1014.237305 
	C2333.349854,1012.244629 2331.867432,1012.612915 2329.825684,1018.293091 
	C2330.233398,1012.339355 2326.521973,1013.586060 2323.657471,1013.593262 
	C2305.176758,1013.639587 2286.695312,1013.672607 2268.215576,1013.856445 
	C2257.359375,1013.964417 2258.901855,1011.895264 2258.706055,1023.326721 
	C2258.652344,1026.468628 2257.980225,1030.289062 2259.477783,1032.547729 
	C2260.652588,1034.319946 2264.734375,1034.166138 2267.526855,1034.859863 
	C2267.683838,1034.898804 2267.858887,1034.864868 2267.899414,1035.211182 
	C2267.499756,1036.485474 2267.412598,1037.546143 2266.882324,1038.291504 
	C2266.561035,1038.743286 2265.553711,1038.707397 2264.906006,1038.491211 
	C2262.182861,1037.084717 2259.408691,1036.077515 2256.952148,1035.185669 
	C2256.952148,1031.731323 2256.971680,1027.944702 2256.945312,1024.158325 
	C2256.927002,1021.510559 2256.990479,1018.847595 2256.706787,1016.225098 
	C2256.619141,1015.415100 2255.508789,1014.715759 2254.866699,1013.965820 
	C2254.256348,1014.755493 2253.156006,1015.522827 2253.118652,1016.338684 
	C2252.945312,1020.135681 2252.832764,1023.965942 2253.154053,1027.745605 
	C2253.420166,1030.874756 2254.322510,1033.949829 2254.932617,1037.273193 
	C2254.922363,1037.497437 2254.936768,1037.946289 2254.864746,1038.203613 
	C2254.761719,1038.636353 2254.730957,1038.811890 2254.466553,1039.122803 
	C2253.784424,1039.557129 2253.335693,1039.856079 2252.887207,1040.155029 
	C2252.454834,1037.815918 2251.693115,1035.482056 2251.655762,1033.136475 
	C2251.563477,1027.377319 2251.804932,1021.612732 2251.946289,1015.440308 
	C2252.009033,1014.335876 2252.037598,1013.641418 2252.345947,1012.363647 
	C2253.066650,1010.972961 2253.507812,1010.165771 2253.948730,1009.358582 
	C2252.935791,1009.129761 2251.922607,1008.900940 2250.909668,1008.672180 
	C2250.929443,1008.395142 2250.948975,1008.118164 2251.362305,1007.373779 
	C2256.696777,1004.691650 2258.652588,1007.240051 2259.122559,1011.810120 
	C2279.109375,1011.810120 2298.808350,1011.810120 2318.651611,1011.810120 
	C2318.796631,1009.953064 2318.911377,1008.483337 2319.219727,1007.030640 
	C2319.413330,1007.047607 2319.801025,1007.075623 2319.858887,1007.501709 
	C2320.519287,1009.247253 2321.080322,1011.689880 2321.730469,1011.713989 
	C2329.066650,1011.985535 2336.416748,1011.886047 2344.453125,1011.886047 
	C2344.453125,1005.301086 2344.453125,1000.013794 2344.453125,994.726562 
	C2344.865723,994.710876 2345.278564,994.695251 2345.691162,994.679626 
	C2346.219238,997.475830 2346.747314,1000.272034 2347.272705,1003.054443 
	C2350.173096,1003.054443 2352.753418,1003.054443 2355.951660,1003.054443 
	C2355.814941,999.976135 2355.825684,997.208862 2355.542236,994.472168 
	C2354.993408,989.179382 2355.066162,988.844482 2357.702393,988.200195 
	C2357.702393,991.677979 2357.648193,995.273804 2357.715820,998.867310 
	C2357.807129,1003.726013 2358.441406,1008.872681 2350.499512,1009.126770 
	C2351.939209,1010.646851 2353.185303,1011.749634 2354.547852,1011.919983 
	C2357.329834,1012.267700 2360.174316,1012.113647 2362.993164,1012.166321 
M2346.635254,1020.615112 
	C2346.635254,1025.009155 2346.635254,1029.403076 2346.635254,1033.797119 
	C2347.422363,1033.911133 2348.209717,1034.025146 2348.996826,1034.139038 
	C2349.351074,1032.473267 2349.975098,1030.814087 2350.016846,1029.140381 
	C2350.367432,1014.987183 2350.335693,1014.986389 2346.711182,1013.058044 
	C2346.711182,1015.506165 2346.711182,1017.582947 2346.635254,1020.615112 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2500.984619,503.015015 
	C2504.205566,497.645660 2514.833008,494.386475 2521.298096,496.818359 
	C2529.847168,500.034027 2535.903076,505.685577 2536.778320,515.090637 
	C2537.588623,523.795898 2534.167236,530.983215 2526.729248,535.976257 
	C2526.049072,536.432861 2525.330322,536.832092 2524.038818,537.616760 
	C2525.802490,538.995422 2526.699219,539.868774 2527.746338,540.488464 
	C2540.276367,547.906189 2541.920410,567.828003 2526.567627,577.059143 
	C2516.800781,582.931458 2500.854980,579.208069 2496.831787,567.058594 
	C2495.148438,561.975281 2493.176025,560.991699 2487.730957,561.517700 
	C2477.331299,562.522339 2466.771240,561.848816 2456.277588,561.912781 
	C2454.629883,561.922791 2452.983398,562.148438 2450.775391,562.575684 
	C2449.929199,562.895203 2449.643311,562.907959 2449.356934,562.915283 
	C2448.348877,559.424622 2450.339355,559.090271 2453.098145,559.096985 
	C2465.252441,559.126465 2477.406982,558.955750 2489.560547,559.038696 
	C2493.055664,559.062622 2494.899170,558.427917 2495.390137,554.286804 
	C2496.169678,547.714539 2500.440186,542.878479 2506.797119,539.223267 
	C2503.325928,535.440125 2498.202148,532.120178 2496.584229,527.557007 
	C2494.205811,520.850098 2490.267090,520.490723 2484.640869,520.785217 
	C2477.665283,521.150208 2470.658691,520.953247 2463.665527,520.928345 
	C2462.159912,520.923035 2460.655029,520.616272 2459.149902,520.448364 
	C2459.165771,519.842834 2459.181885,519.237366 2459.197998,518.631836 
	C2470.548828,518.631836 2481.899902,518.631836 2491.673340,518.631836 
	C2495.128418,512.514832 2497.749756,507.874023 2500.742188,503.481567 
	C2500.611572,504.750458 2500.203857,505.831787 2499.592529,506.782043 
	C2494.795654,514.239624 2494.906738,521.973694 2499.697998,529.050293 
	C2504.437744,536.050537 2511.812988,537.632141 2519.905518,536.128357 
	C2529.206299,534.399902 2535.788574,525.454285 2534.962646,515.685364 
	C2534.212402,506.812775 2526.100586,498.644562 2517.143311,499.087036 
	C2511.705811,499.355652 2506.367432,501.637543 2500.984619,503.015015 
M2507.530762,541.686157 
	C2506.323242,542.555969 2505.082031,543.383057 2503.913818,544.302612 
	C2497.085938,549.676514 2495.003906,558.331421 2498.627441,566.168396 
	C2502.756836,575.099243 2510.572998,578.884827 2520.765625,576.890259 
	C2528.093994,575.456116 2535.211426,567.362793 2535.960938,560.296936 
	C2537.539551,545.419739 2520.817383,535.814880 2507.530762,541.686157 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2449.961670,997.925415 
	C2449.289062,989.233521 2448.616211,980.541626 2448.319824,971.405273 
	C2449.402832,970.912292 2450.109375,970.863770 2450.992676,971.079895 
	C2451.531250,971.555237 2451.893555,971.765930 2452.255615,971.976685 
	C2453.429443,984.441223 2454.603027,996.905823 2455.773926,1009.340576 
	C2470.472168,1009.340576 2485.340820,1009.340576 2500.036133,1009.340576 
	C2504.014648,993.758484 2511.844971,986.068726 2523.094727,986.194824 
	C2531.662354,986.290833 2541.662842,994.437683 2543.563232,1003.196655 
	C2545.416748,1011.739136 2543.831787,1022.279663 2527.634277,1029.471436 
	C2538.885986,1032.796265 2544.140625,1040.487427 2544.687012,1051.028687 
	C2544.878906,1054.731323 2544.062012,1059.166992 2539.472900,1061.701172 
	C2544.736328,1049.837158 2542.224609,1039.529175 2532.673584,1034.090332 
	C2526.006836,1030.294067 2519.170898,1030.327148 2512.558838,1034.668091 
	C2511.496338,1035.365601 2510.155273,1035.638550 2508.501709,1036.081055 
	C2508.060303,1036.054565 2507.931641,1035.908691 2508.103516,1035.619141 
	C2511.500977,1033.254028 2514.726562,1031.178223 2517.516113,1029.382935 
	C2515.995361,1028.174194 2513.994385,1026.583496 2512.393555,1024.993408 
	C2514.541016,1025.499878 2516.273193,1026.067993 2518.038574,1026.501465 
	C2530.004639,1029.439819 2542.608887,1019.632690 2542.190186,1008.178345 
	C2541.729980,995.588684 2528.955078,985.238220 2516.954590,989.543762 
	C2512.249023,991.232056 2508.149658,995.856689 2504.958740,1000.046265 
	C2503.091309,1002.498108 2500.608643,1006.831848 2505.074707,1010.119690 
	C2505.199951,1010.212036 2504.764404,1010.897034 2504.839844,1011.252930 
	C2505.403809,1013.911133 2505.561035,1016.799438 2506.772217,1019.135315 
	C2507.941650,1021.391052 2510.218262,1023.072815 2511.645996,1025.074341 
	C2508.411621,1022.165649 2504.781738,1019.597595 2502.893066,1016.091736 
	C2501.199707,1012.948425 2499.686768,1011.731384 2496.216309,1011.826660 
	C2486.742920,1012.086975 2477.260010,1012.029785 2467.781006,1012.081726 
	C2463.685059,1012.104126 2459.589111,1012.085449 2454.801758,1012.085449 
	C2454.801758,1020.576477 2454.801758,1028.351074 2454.632568,1036.140991 
	C2454.463379,1036.156372 2454.127930,1036.101440 2454.069824,1035.655273 
	C2453.671875,1029.155151 2453.384033,1023.097412 2452.958740,1017.049377 
	C2452.837402,1015.325745 2452.315430,1013.630249 2451.977539,1011.921875 
	C2451.970947,1011.582031 2451.964600,1011.242188 2452.239746,1010.302246 
	C2452.633301,1006.593994 2452.963867,1003.468628 2452.720215,1000.388672 
	C2452.649902,999.500305 2450.927002,998.742859 2449.961670,997.925415 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2195.993164,1210.018677 
	C2195.993164,1208.235107 2195.993164,1206.451538 2195.993164,1204.830933 
	C2200.939697,1202.718262 2203.959717,1204.654663 2205.459473,1208.095459 
	C2207.455078,1206.536987 2208.894043,1204.702026 2210.721680,1204.147095 
	C2213.706299,1203.240967 2215.228027,1204.690430 2215.136230,1208.111694 
	C2215.029785,1212.078979 2215.782959,1216.077515 2215.566895,1220.029785 
	C2215.480713,1221.607910 2214.039551,1224.084961 2212.788574,1224.387329 
	C2211.115234,1224.791870 2208.801514,1223.832520 2207.183838,1222.799194 
	C2205.764404,1221.892456 2204.925781,1220.076294 2204.127197,1219.045044 
	C2202.950195,1220.919312 2201.949463,1224.159424 2200.645508,1224.286499 
	C2196.375488,1224.702881 2191.731934,1227.089233 2187.656006,1223.284058 
	C2186.830078,1222.513062 2184.739014,1221.717773 2184.309570,1222.111084 
	C2178.837891,1227.121704 2172.254639,1224.296997 2166.234131,1224.711914 
	C2158.831543,1225.222290 2158.804932,1224.836060 2157.889160,1215.677856 
	C2154.831543,1217.010620 2151.779541,1218.340820 2148.320068,1219.848511 
	C2148.158691,1220.975098 2147.928467,1222.580078 2147.708252,1224.117310 
	C2140.518311,1225.860962 2140.066650,1225.534546 2140.044922,1218.656860 
	C2140.030518,1214.056763 2140.041992,1209.456665 2140.041992,1203.881714 
	C2146.250488,1204.410889 2151.686768,1204.874390 2157.123047,1205.337769 
	C2157.054688,1205.566406 2156.986328,1205.794922 2156.917725,1206.023560 
	C2158.959473,1205.410645 2161.044678,1204.232788 2163.027588,1204.385376 
	C2164.328613,1204.485596 2166.305908,1206.366943 2166.534668,1207.698730 
	C2167.059814,1210.757935 2166.700928,1213.969116 2166.700928,1216.996094 
	C2174.170898,1219.473511 2175.122070,1218.816650 2178.124756,1212.166992 
	C2179.675293,1208.733521 2179.328125,1202.703979 2185.932617,1204.224854 
	C2187.394287,1204.561401 2189.203125,1205.727783 2189.812988,1207.007324 
	C2191.940430,1211.469971 2193.611816,1216.149536 2196.026367,1222.165771 
	C2196.026367,1217.716919 2196.026367,1214.746826 2196.423584,1211.476318 
	C2197.168457,1213.518433 2197.452393,1215.872925 2197.888184,1218.199097 
	C2198.136719,1219.524780 2198.632812,1220.804077 2199.017090,1222.104370 
	C2199.532715,1222.045166 2200.048340,1221.986084 2200.563965,1221.926880 
	C2200.839600,1219.009155 2201.115479,1216.091431 2201.400879,1213.070068 
	C2206.539062,1214.653198 2205.982910,1222.683594 2212.257568,1221.806396 
	C2212.257568,1216.237305 2212.257568,1211.166748 2212.257568,1206.096191 
	C2211.696533,1206.056641 2211.135254,1206.017212 2210.574219,1205.977661 
	C2210.183350,1209.256470 2209.792236,1212.535156 2209.279297,1216.836914 
	C2205.584717,1212.420410 2202.672852,1208.939331 2199.644043,1205.318726 
	C2197.916504,1207.542847 2196.954834,1208.780762 2195.993164,1210.018677 
M2151.851318,1215.782227 
	C2153.150391,1213.955688 2154.449463,1212.129272 2155.748535,1210.302734 
	C2153.791260,1209.162964 2151.954834,1207.544067 2149.844238,1207.013184 
	C2147.565186,1206.439697 2145.030273,1206.883423 2141.985107,1206.883423 
	C2142.982422,1212.705688 2140.174805,1218.339722 2143.835449,1222.840454 
	C2144.406738,1222.773560 2144.977783,1222.706665 2145.549072,1222.639648 
	C2145.834229,1220.580200 2146.119629,1218.520874 2146.435059,1216.243896 
	C2148.117676,1216.119751 2149.575684,1216.012329 2151.851318,1215.782227 
M2187.751953,1207.776733 
	C2185.831787,1205.391846 2183.810791,1205.108276 2182.469727,1208.104736 
	C2180.504883,1212.494263 2178.902344,1217.045898 2177.145508,1221.528564 
	C2177.374268,1222.138062 2177.603271,1222.747681 2177.832031,1223.357178 
	C2182.654541,1217.778076 2187.629395,1220.022461 2193.580078,1222.804321 
	C2191.319336,1217.051758 2189.642334,1212.784546 2187.751953,1207.776733 
M2161.442627,1221.792480 
	C2165.313965,1223.607788 2169.022217,1223.419800 2172.608643,1221.058350 
	C2171.726074,1220.341187 2170.798584,1219.945190 2169.960205,1220.084473 
	C2164.825684,1220.938477 2163.596680,1218.397461 2164.014160,1213.843506 
	C2164.240723,1211.370117 2163.806396,1208.836182 2163.670410,1206.329590 
	C2162.965088,1206.297852 2162.260010,1206.266235 2161.554932,1206.234619 
	C2160.245605,1210.995850 2161.242432,1216.044434 2161.442627,1221.792480 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M642.567627,941.038574 
	C642.567627,941.038574 642.480957,941.048401 642.327942,940.683716 
	C641.110474,939.915588 640.045959,939.512085 638.514221,939.104736 
	C611.030518,939.399170 584.014160,939.697449 556.664246,939.976318 
	C555.885803,939.972900 555.440918,939.988892 554.611023,939.991638 
	C550.835388,940.012329 547.444702,940.046387 543.772583,940.061401 
	C543.304138,940.062805 543.117188,940.083313 542.466553,940.079224 
	C526.025452,940.098816 510.048126,940.143066 493.718506,940.127930 
	C492.891663,940.093933 492.417114,940.119446 491.481689,940.129395 
	C474.487946,940.386902 457.955505,940.690918 441.421783,940.887268 
	C439.663757,940.908203 437.898193,940.298157 436.136139,939.980774 
	C436.242950,939.357483 436.349792,938.734253 436.456604,938.110962 
	C438.058624,938.110962 439.660767,938.125061 441.262604,938.108887 
	C458.554291,937.934082 475.845947,937.754395 493.588928,937.656006 
	C502.703217,937.506714 511.366180,937.277527 520.274658,937.066772 
	C520.520203,937.085327 521.003967,936.992798 521.419189,937.053223 
	C523.945618,937.412537 526.063843,938.006775 528.166687,937.956909 
	C535.610840,937.780701 543.047180,937.254700 550.491394,937.103455 
	C560.303833,936.903992 570.120911,936.933167 580.406616,936.927856 
	C586.207886,937.000854 591.546753,937.179443 596.864807,936.924438 
	C598.645874,936.839050 600.373901,935.647644 602.305420,934.955811 
	C602.484680,934.949890 602.843262,934.952820 602.956909,935.306396 
	C606.591064,937.565552 609.989075,937.729309 613.269836,934.954102 
	C613.359497,934.958862 613.533691,935.002441 613.767700,935.052490 
	C614.001648,935.102478 614.476807,935.158997 614.619507,935.523376 
	C620.437622,936.228149 626.114258,936.872681 631.788086,936.848938 
	C645.883545,936.789978 659.977478,936.405518 674.072876,936.212952 
	C675.675537,936.191040 677.283447,936.558838 678.888916,936.745911 
	C678.876282,937.512573 678.863586,938.279175 678.850952,939.045776 
	C673.240784,939.045776 667.630676,939.045776 661.559631,939.021423 
	C657.710815,939.031433 654.322876,939.065796 650.531799,939.078247 
	C648.783325,939.125427 647.438049,939.194458 645.742188,939.199097 
	C644.450317,939.769226 643.508972,940.403870 642.567627,941.038574 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M493.137604,937.576111 
	C475.845947,937.754395 458.554291,937.934082 441.262604,938.108887 
	C439.660767,938.125061 438.058624,938.110962 436.456604,938.110962 
	C436.349792,938.734253 436.242950,939.357483 436.136139,939.980774 
	C437.898193,940.298157 439.663757,940.908203 441.421783,940.887268 
	C457.955505,940.690918 474.487946,940.386902 491.503723,940.446777 
	C491.990479,941.205994 491.994324,941.632507 491.998138,942.058960 
	C474.570892,942.292725 457.143616,942.526489 439.044342,942.599976 
	C437.487274,942.702393 436.602234,942.965149 435.717224,943.228027 
	C434.344513,942.904236 432.971832,942.580444 431.173767,941.817871 
	C430.432800,941.138672 430.117218,940.898132 429.825623,940.389038 
	C429.831543,939.579834 429.813446,939.039185 430.099304,938.257446 
	C429.254272,937.316406 428.124756,936.054810 426.952881,936.014160 
	C422.000702,935.842529 417.035919,936.034546 411.640045,936.056458 
	C409.455017,936.056458 407.705688,936.093506 405.505676,936.104736 
	C400.577698,936.001404 396.100403,935.923828 391.785950,935.585938 
	C398.443634,934.921753 404.933685,934.371704 411.434814,934.156860 
	C417.479065,933.956970 423.535065,934.114136 430.530060,934.114136 
	C426.249603,926.478333 422.542297,919.864990 418.997986,913.168213 
	C419.517426,912.844482 419.873932,912.604187 420.521454,912.069824 
	C421.165558,910.050293 421.472229,908.313538 421.887482,906.603210 
	C422.181274,905.393127 422.622070,904.218689 422.998566,903.012329 
	C422.999908,902.996338 422.968475,903.005554 422.913696,903.344849 
	C424.062103,904.300781 425.234283,905.270020 426.481964,905.379700 
	C427.072754,905.431641 427.807983,903.839905 428.575928,903.021240 
	C428.674042,903.049622 428.877899,903.062500 429.195343,903.263306 
	C431.573364,908.399414 427.343567,907.773010 424.793427,908.992859 
	C425.836212,909.298706 426.878998,909.604614 427.985840,909.977173 
	C428.049896,910.043823 428.183319,910.171875 428.145020,910.484985 
	C428.106720,912.085999 428.106720,913.373901 428.106720,914.656982 
	C426.433319,914.937927 424.726929,915.224487 422.136414,915.659485 
	C426.108246,922.448608 429.647705,928.594055 433.327026,934.654541 
	C433.737366,935.330444 434.942780,935.523621 435.779480,935.940613 
	C436.220795,934.980042 437.015503,934.029663 437.043335,933.057312 
	C437.176849,928.395935 437.104340,923.728638 437.102234,919.063416 
	C437.099396,912.813782 438.611359,911.257874 444.949371,911.162170 
	C448.862183,911.103027 452.776611,911.151245 457.151154,911.151245 
	C455.461304,901.349426 448.099335,899.149597 440.614227,897.085754 
	C440.765930,896.639832 440.917633,896.193848 441.069336,895.747925 
	C444.055878,895.158997 447.042419,894.570068 449.921631,894.280640 
	C449.081329,895.215454 448.348419,895.850830 447.483795,896.600342 
	C451.046967,899.072510 454.550598,901.503357 458.023376,903.956543 
	C457.992523,903.978882 458.033325,903.913696 457.932129,904.329651 
	C458.302704,907.560181 458.774475,910.374756 459.380280,913.989014 
	C453.452606,913.989014 448.430603,913.989014 443.122192,913.989014 
	C443.122192,918.410767 443.122192,922.164917 443.101135,926.282593 
	C443.105804,927.130371 443.131561,927.614563 443.107208,928.541138 
	C443.057098,931.222595 443.057098,933.461609 443.057098,936.195496 
	C460.260010,936.195496 476.666779,936.195496 493.070984,936.427002 
	C493.085449,936.964844 493.108521,937.270752 493.137604,937.576111 
M440.983215,927.426697 
	C440.983215,923.157227 440.983215,918.887756 440.983215,914.618286 
	C440.491974,914.637817 440.000763,914.657410 439.509521,914.676941 
	C439.509521,921.602051 439.509521,928.527161 439.509521,935.452271 
	C440.009338,935.461426 440.509186,935.470581 441.009003,935.479736 
	C441.009003,933.114380 441.009003,930.748962 440.983215,927.426697 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M510.692383,1068.430908 
	C510.315887,1068.887939 509.996765,1068.902954 509.443756,1068.973877 
	C509.072906,1067.535400 508.864105,1066.057861 508.812256,1064.574951 
	C508.503418,1055.741943 508.515839,1055.772461 499.389465,1055.838623 
	C491.945892,1055.892578 484.501129,1055.906006 477.058380,1055.816895 
	C476.012451,1055.804321 474.974762,1055.105225 473.842468,1054.330688 
	C485.020477,1053.937378 496.289276,1053.937378 508.078918,1053.937378 
	C508.078918,1043.625244 508.207275,1033.857056 507.864349,1024.105591 
	C507.832825,1023.210205 504.791290,1021.691162 503.147766,1021.691467 
	C478.858582,1021.696045 454.569489,1021.927551 430.280212,1022.065796 
	C414.158844,1022.157654 398.036621,1022.127319 381.915710,1022.263245 
	C369.614868,1022.366943 357.315491,1022.645569 344.546143,1022.527588 
	C344.075348,1021.853333 344.073853,1021.497864 344.072327,1021.142456 
	C348.036591,1020.942688 351.999695,1020.598450 355.965302,1020.566711 
	C380.764740,1020.368103 405.565063,1020.277893 430.364471,1020.076599 
	C455.156250,1019.875366 479.947266,1019.581177 504.738831,1019.347961 
	C506.206604,1019.334167 507.676086,1019.500671 509.797546,1019.620056 
	C510.117401,1035.873535 510.433228,1051.923950 510.692383,1068.430908 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1100.760864,437.211578 
	C1100.788818,437.576752 1100.816772,437.941895 1100.559082,438.595184 
	C1099.839600,439.137085 1099.405884,439.390869 1098.676880,439.849426 
	C1098.316772,440.982605 1098.251953,441.911011 1098.136963,443.210114 
	C1098.098633,444.078308 1098.110474,444.575806 1098.084473,445.527222 
	C1098.049805,448.143890 1098.324707,450.352448 1097.970215,452.454865 
	C1097.688354,454.125641 1096.636597,455.666595 1095.560791,457.220764 
	C1093.412842,462.453400 1091.630615,467.730499 1089.637817,473.238647 
	C1089.550537,474.643707 1089.673828,475.817749 1089.835815,477.440765 
	C1089.997925,480.865753 1090.121460,483.841797 1090.267822,487.230286 
	C1090.384399,487.901886 1090.477905,488.160950 1090.414429,488.790894 
	C1090.276611,490.826813 1090.295532,492.491821 1090.290771,494.614929 
	C1090.295044,503.371674 1090.323242,511.670319 1090.265747,520.331787 
	C1090.206055,521.438416 1090.232178,522.182251 1090.248657,523.178101 
	C1090.508545,523.577576 1090.778076,523.725098 1091.368164,523.917419 
	C1092.117920,524.005249 1092.546997,524.048279 1092.840332,524.350647 
	C1092.082275,524.937561 1091.459717,525.265076 1090.684326,525.673157 
	C1092.204956,526.210205 1093.513184,526.672241 1094.849121,527.504333 
	C1094.848267,528.863342 1094.819702,529.852234 1094.493164,530.862671 
	C1093.679565,531.025391 1093.164185,531.166626 1091.366089,531.659424 
	C1093.375122,531.952393 1094.083130,532.055664 1094.815918,532.594238 
	C1094.603027,535.879272 1094.365479,538.729004 1093.696533,541.478760 
	C1092.232910,542.039734 1090.475342,542.552551 1090.304565,543.389221 
	C1089.794556,545.888672 1089.906250,548.514954 1089.523682,551.118286 
	C1088.937134,551.128967 1088.601807,551.113098 1088.122192,550.662537 
	C1087.977783,547.414551 1087.977783,544.601318 1087.977783,541.788025 
	C1087.589111,541.954163 1087.200317,542.120300 1086.811646,542.286438 
	C1085.774536,540.820801 1084.049072,539.451538 1083.827881,537.871704 
	C1083.128296,532.873352 1082.357300,532.204590 1077.260010,533.088745 
	C1075.342773,533.421326 1073.319458,533.142639 1071.344482,533.142639 
	C1071.326660,532.664185 1071.308838,532.185730 1071.291016,531.707214 
	C1072.946777,531.597046 1074.678101,531.118896 1076.245361,531.440125 
	C1083.227051,532.870911 1083.322266,532.860168 1083.610718,525.601501 
	C1083.629150,525.137634 1083.844971,524.681641 1083.976929,524.192200 
	C1085.249634,523.741211 1086.462158,523.311523 1087.896729,522.803162 
	C1087.896729,515.544617 1087.896240,508.394745 1087.896851,501.244904 
	C1087.897949,490.160553 1087.898315,490.160553 1077.635742,488.499634 
	C1080.820190,488.017944 1084.294312,487.492432 1086.642090,487.137268 
	C1088.030151,476.615692 1089.278320,467.154572 1090.347778,459.048187 
	C1088.734375,455.484741 1087.483398,452.721497 1086.080200,449.622253 
	C1083.045654,449.622253 1079.530029,449.622253 1076.014526,449.622253 
	C1076.004883,449.298004 1075.995239,448.973785 1075.985596,448.649536 
	C1079.571411,448.448029 1083.157227,448.246521 1086.623291,448.051727 
	C1087.082397,445.334167 1087.463013,443.080902 1088.218384,440.809814 
	C1091.458130,439.704071 1094.323364,438.616150 1097.375732,437.487854 
	C1097.563110,437.447510 1097.835083,437.177429 1098.172363,437.144287 
	C1098.682983,436.965973 1098.856079,436.820770 1099.187012,436.710876 
	C1099.344727,436.746216 1099.655640,436.657074 1099.909668,436.840149 
	C1100.362793,437.085999 1100.561768,437.148804 1100.760864,437.211578 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M456.968445,1055.930786 
	C428.011780,1056.281006 399.055389,1056.656494 370.098389,1056.974976 
	C333.989441,1057.372192 297.879944,1057.714111 261.770844,1058.096191 
	C232.815536,1058.402588 203.860504,1058.733398 174.435013,1058.788330 
	C173.937195,1058.280151 173.927979,1058.036377 173.936996,1057.791870 
	C185.565567,1057.520508 197.192825,1057.127441 208.822968,1057.000122 
	C247.781631,1056.573608 286.741547,1056.262329 325.700592,1055.867676 
	C356.001343,1055.560913 386.301697,1055.215698 416.601746,1054.847168 
	C429.726288,1054.687622 442.849640,1054.426758 456.450500,1054.533447 
	C456.941132,1055.213501 456.954773,1055.572144 456.968445,1055.930786 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1483.929199,527.248657 
	C1496.978149,527.158936 1510.027222,527.069214 1523.561401,527.358521 
	C1524.071899,527.971558 1524.097046,528.205444 1524.059082,528.798218 
	C1524.791870,530.020325 1525.531982,531.562805 1526.392700,531.633301 
	C1530.944336,532.006226 1535.526245,532.008606 1540.069336,532.439331 
	C1540.027588,533.141296 1540.014160,533.543274 1539.994629,534.417419 
	C1539.933472,551.015259 1539.878296,567.140930 1539.763428,583.318481 
	C1539.703613,583.370361 1539.546509,583.389648 1539.375977,582.922485 
	C1538.984741,566.784119 1538.763916,551.112793 1538.545898,535.641724 
	C1530.373901,535.008179 1523.354980,534.278687 1516.318726,534.014099 
	C1513.682129,533.914917 1511.005005,534.897400 1508.045898,535.308838 
	C1507.131836,535.109192 1506.518188,534.987427 1505.875244,534.521362 
	C1503.743164,534.510010 1501.640625,534.843018 1499.263062,535.186768 
	C1498.442139,535.221741 1497.896240,535.246094 1497.124023,534.928223 
	C1489.858398,531.384094 1492.297974,539.373962 1489.314087,541.004822 
	C1491.664551,542.694031 1493.380737,544.355347 1490.178711,546.997925 
	C1489.482422,547.572632 1489.337524,549.337158 1489.572266,550.415771 
	C1490.162476,553.127136 1491.655151,555.732727 1491.789917,558.430542 
	C1491.973511,562.104126 1490.289185,565.816284 1491.972656,569.531982 
	C1492.217041,570.071655 1492.223022,571.105957 1491.878174,571.452026 
	C1490.924683,572.409058 1489.699707,573.807251 1488.633301,573.764099 
	C1487.225220,573.707092 1485.555542,572.706787 1484.614502,571.591797 
	C1484.130737,571.018677 1484.983765,569.317139 1485.232788,568.125488 
	C1486.378784,571.976868 1487.760132,572.960693 1491.398193,569.670776 
	C1489.161987,567.840271 1487.156616,566.198669 1484.917969,564.366150 
	C1485.916992,563.454773 1487.009888,562.457825 1488.044434,561.514038 
	C1487.013550,561.036865 1486.080078,560.604858 1484.671265,559.952820 
	C1486.170166,558.833923 1487.158936,558.095825 1488.144409,557.356934 
	C1483.859253,555.868103 1484.300659,552.138977 1484.049072,548.735657 
	C1483.687500,543.844788 1486.875488,538.922424 1484.203613,533.934265 
	C1485.145630,533.301514 1485.802002,532.789551 1487.218506,531.684631 
	C1483.761475,531.840820 1481.383301,531.948242 1478.413208,532.082397 
	C1479.628052,531.099365 1480.335083,530.527161 1481.501953,529.961792 
	C1485.951050,529.968628 1489.940430,529.968628 1494.788940,529.968628 
	C1493.301270,530.876160 1492.724243,531.228210 1491.796265,531.794312 
	C1494.248413,531.794312 1496.146606,531.794312 1498.044922,531.794312 
	C1497.241821,530.192993 1496.075684,529.179749 1494.848145,529.098755 
	C1491.239868,528.860657 1487.607178,528.992676 1483.988525,528.690063 
	C1483.971680,528.016479 1483.950439,527.632568 1483.929199,527.248657 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M94.989380,242.964447 
	C83.536827,243.257828 72.574173,243.412491 61.632286,243.972809 
	C60.019398,244.055389 58.172409,245.790848 57.009476,247.227600 
	C53.950787,251.006485 51.711994,255.577515 48.224983,258.851379 
	C42.878048,263.871399 32.608234,264.134766 26.256618,260.336121 
	C17.938477,255.361359 14.421664,247.331039 15.596484,238.652405 
	C17.857370,221.950806 35.684631,214.758331 48.092949,223.869858 
	C52.039921,226.768127 54.713501,231.954056 56.820541,236.612946 
	C58.262367,239.800964 59.306984,241.250702 63.014133,241.182816 
	C76.453651,240.936737 89.900406,241.085831 103.692406,241.085831 
	C105.170761,225.873764 101.396637,211.110016 103.909210,196.555069 
	C104.340080,196.521927 104.770950,196.488800 105.201820,196.455658 
	C105.460213,197.290268 105.923523,198.119370 105.946167,198.960327 
	C106.272774,211.085373 106.498520,223.213226 106.858154,235.337158 
	C106.926949,237.656311 107.467293,239.961487 107.885567,242.956833 
	C104.015526,242.956833 99.750755,242.956833 94.989380,242.964447 
M51.914291,252.256989 
	C52.517841,251.074570 53.152950,249.907059 53.719742,248.707275 
	C57.045422,241.667496 55.093273,232.831711 49.027752,227.396774 
	C43.245068,222.215286 33.636295,221.045334 27.306217,224.751968 
	C18.787735,229.740036 14.689650,239.923340 18.677881,248.134384 
	C23.167643,257.377991 30.742941,262.224091 41.347923,259.556793 
	C45.065750,258.621735 48.154617,255.185959 51.914291,252.256989 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1524.122192,528.439331 
	C1524.097046,528.205444 1524.071899,527.971558 1524.030396,527.385254 
	C1537.001953,526.982422 1549.989624,526.931824 1563.672607,526.831421 
	C1564.897095,526.531311 1565.426636,526.281128 1565.956055,526.030884 
	C1590.890381,526.001953 1615.824707,525.975525 1640.759033,525.942871 
	C1651.535767,525.928772 1662.312622,525.901794 1673.269775,526.210938 
	C1665.180542,526.701538 1656.905518,526.748901 1648.645630,527.125793 
	C1647.147583,527.194153 1645.711182,528.612671 1644.246338,529.407898 
	C1644.463013,529.775452 1644.679688,530.143066 1644.896362,530.510681 
	C1647.920898,530.979553 1650.945435,531.448425 1653.516846,531.957520 
	C1643.378052,532.678223 1633.692627,533.358643 1623.587646,534.029541 
	C1621.773682,534.043335 1620.379028,534.066711 1618.523926,534.007996 
	C1602.548096,533.628906 1587.033447,533.285156 1571.516846,533.111938 
	C1570.075806,533.095947 1568.622803,534.162354 1566.778320,534.833252 
	C1565.561523,534.931824 1564.741577,534.923279 1563.914062,534.552063 
	C1562.605835,533.785950 1561.305176,533.382507 1560.220703,532.685547 
	C1561.986450,529.373962 1564.492432,528.700073 1567.720093,528.967773 
	C1571.129028,529.250488 1574.579590,529.031189 1578.011963,529.031189 
	C1578.005127,528.788635 1577.998291,528.546143 1577.991455,528.303589 
	C1570.843262,528.303589 1563.695190,528.303589 1556.267334,528.303589 
	C1556.162476,530.055908 1556.075562,531.508911 1555.779785,533.022339 
	C1555.571045,533.082825 1555.154053,533.205688 1554.949951,532.899597 
	C1553.805664,532.379028 1552.865356,532.164612 1552.103760,531.620911 
	C1553.862061,527.830444 1551.359985,528.257568 1549.370361,528.268188 
	C1540.954346,528.312988 1532.538208,528.380249 1524.122192,528.439331 
M1617.510620,527.943420 
	C1606.016724,528.087585 1606.016724,528.087585 1603.350830,530.976318 
	C1617.155640,530.810242 1629.985718,531.215820 1642.799683,530.671509 
	C1642.806152,529.763123 1642.812622,528.854736 1642.819214,527.946350 
	C1634.714966,527.946350 1626.610718,527.946350 1617.510620,527.943420 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M790.892578,508.446899 
	C790.909973,508.217682 790.927307,507.988434 790.955933,507.414948 
	C813.169983,517.447205 835.372864,527.823669 857.834656,538.741089 
	C862.098938,541.921204 862.697998,546.360352 863.880859,550.454895 
	C863.995117,550.850281 863.113525,551.533447 862.335083,552.082397 
	C860.872925,551.726318 859.711731,551.492432 858.675476,550.997131 
	C843.167908,543.585938 827.666077,536.162354 812.195923,528.673523 
	C810.337830,527.774109 808.672485,526.476562 806.917725,525.363525 
	C806.643616,524.422180 806.369446,523.480774 806.480774,522.214111 
	C808.729553,522.532654 810.671326,523.010498 812.442993,523.848083 
	C821.419434,528.091553 830.302185,532.537231 839.335571,536.653381 
	C841.498779,537.639038 844.111877,537.637268 846.518005,538.089600 
	C846.780884,537.424744 847.043762,536.759888 847.306641,536.094971 
	C846.706116,535.794861 846.109009,535.487671 845.504578,535.195740 
	C834.340332,529.803467 823.157471,524.449585 812.016846,519.009094 
	C804.943054,515.554688 797.931824,511.972046 790.892578,508.446899 
M853.509521,539.531189 
	C853.103333,539.555847 852.697144,539.580444 852.290955,539.605103 
	C852.375671,539.896790 852.460327,540.188416 852.545044,540.480042 
	C852.822998,540.324158 853.100952,540.168274 853.509521,539.531189 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1710.073242,960.133667 
	C1705.453003,958.681580 1701.028931,956.057861 1696.279419,955.164429 
	C1688.589233,953.717957 1681.448730,955.858948 1675.807983,961.647095 
	C1674.669922,962.814941 1673.962891,964.402893 1673.083252,965.757202 
	C1669.183105,961.293335 1666.656494,957.323242 1663.152710,954.619141 
	C1656.143311,949.209351 1648.282593,949.110779 1640.820801,953.777710 
	C1638.054688,955.507812 1636.675171,954.743835 1634.971924,952.815308 
	C1628.808105,945.835815 1618.651001,942.954102 1609.846191,950.645081 
	C1606.557983,953.517334 1604.508301,952.950806 1601.589478,950.066589 
	C1591.996094,940.586731 1578.181152,940.520264 1568.686401,950.168335 
	C1565.213867,953.696899 1562.558350,952.898560 1558.355957,951.997498 
	C1552.095581,950.655212 1545.411133,951.290771 1538.489502,950.822632 
	C1537.996460,950.012390 1537.920776,949.464233 1537.845093,948.916077 
	C1545.199585,945.956726 1552.462891,946.471313 1559.663940,949.481140 
	C1562.010620,950.461975 1563.897583,950.948059 1566.267334,948.665710 
	C1578.228149,937.145508 1592.188477,937.333191 1604.219727,948.823242 
	C1604.682129,949.265015 1605.241943,949.604919 1605.694702,949.945740 
	C1621.515503,941.479980 1627.798462,941.853394 1638.210815,951.953979 
	C1652.062378,943.877747 1663.164429,948.357666 1672.535034,960.692932 
	C1679.010986,954.549438 1686.321777,951.348877 1694.859009,952.113098 
	C1700.837524,952.648376 1706.692017,953.891052 1710.073242,960.133667 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2445.161133,141.000168 
	C2445.157227,134.837082 2445.323486,129.164963 2445.021973,123.517776 
	C2444.954590,122.253448 2443.446289,120.280281 2442.266846,119.989281 
	C2434.891602,118.169518 2430.324219,113.401634 2427.037109,106.881195 
	C2422.373291,97.629715 2427.036133,85.129128 2437.582764,79.536064 
	C2438.607910,78.992508 2440.051758,79.238487 2441.786133,79.576057 
	C2429.501953,85.341965 2424.754883,94.469719 2429.590088,107.021324 
	C2432.548096,114.700073 2441.813232,119.108994 2449.625000,117.895767 
	C2457.167480,116.724358 2461.678467,113.451401 2462.646484,108.268250 
	C2458.984619,107.450554 2454.051025,108.547478 2453.115967,102.773529 
	C2455.839355,103.267609 2458.303955,103.913628 2460.804688,104.120461 
	C2464.287598,104.408516 2466.042969,102.623268 2466.046143,99.067833 
	C2466.049316,95.502617 2464.200684,93.938034 2460.793457,93.901146 
	C2459.960693,93.892128 2459.121826,93.953316 2458.296387,93.874001 
	C2455.712402,93.625778 2452.362061,95.019623 2451.987549,90.389099 
	C2451.660645,86.350807 2452.405029,83.519417 2458.621826,83.996124 
	C2454.740479,82.050987 2452.181885,80.768845 2448.902588,79.125343 
	C2456.821777,76.551636 2459.996582,85.664337 2465.831543,84.465652 
	C2466.195312,86.921402 2466.167725,89.186737 2466.885742,91.183884 
	C2471.350342,103.597939 2463.514404,118.492882 2451.556152,119.751129 
	C2447.548828,120.172798 2446.869629,122.374519 2446.970947,125.863426 
	C2447.400391,140.654343 2447.658447,155.450348 2447.946533,170.245102 
	C2447.979736,171.945557 2447.811035,173.649902 2447.736084,175.352448 
	C2447.115479,175.340622 2446.494873,175.328796 2445.874268,175.316971 
	C2445.636963,164.044693 2445.399658,152.772415 2445.161133,141.000168 
M2464.069092,91.391624 
	C2464.181396,91.436226 2464.293945,91.480820 2464.406250,91.525459 
	C2464.297607,91.327538 2464.188965,91.129616 2463.944580,90.199837 
	C2463.099609,86.062744 2460.063477,85.546211 2456.742432,86.256790 
	C2455.717285,86.476181 2454.996582,88.118454 2454.136475,89.109169 
	C2455.083252,89.751747 2455.975830,90.820992 2456.988037,90.950348 
	C2459.269775,91.241966 2461.611084,91.068321 2464.069092,91.391624 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2483.005859,711.973267 
	C2472.613037,711.962708 2462.714355,711.962708 2452.106689,711.962708 
	C2452.106689,726.217041 2452.106689,739.241699 2452.106689,752.266357 
	C2451.645996,752.265686 2451.185303,752.265015 2450.724609,752.264404 
	C2450.724609,737.136047 2450.724609,722.007690 2450.724609,707.778992 
	C2452.639648,708.470642 2454.725830,709.839050 2456.840820,709.884338 
	C2467.490234,710.112976 2478.147949,710.065186 2488.801025,709.951538 
	C2491.390381,709.923950 2493.973145,709.281067 2496.426758,708.939514 
	C2497.749023,697.880066 2508.485107,688.773804 2520.202881,689.863342 
	C2529.167480,690.696899 2538.033203,699.144897 2539.215088,709.190308 
	C2540.232178,717.835022 2536.450684,724.630188 2529.651367,729.251221 
	C2522.590088,734.050232 2514.942383,734.366211 2507.145752,729.821106 
	C2501.385010,726.462769 2498.346924,721.730164 2496.796875,715.441406 
	C2496.440186,713.994690 2494.109131,712.359314 2492.458008,712.055725 
	C2489.565430,711.523987 2486.495605,711.955688 2483.005859,711.973267 
M2501.003662,720.210815 
	C2505.345459,728.327881 2513.961914,731.871216 2523.996582,729.014771 
	C2530.002930,727.304871 2537.265137,721.051392 2537.329346,711.289368 
	C2537.418457,697.682373 2523.212402,690.591919 2514.695557,692.375183 
	C2501.277832,695.184509 2495.750488,706.538452 2501.003662,720.210815 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1087.690674,90.359215 
	C1103.104370,85.062714 1116.390625,95.334839 1117.722900,110.501717 
	C1118.354248,117.688309 1110.551758,128.735306 1103.094482,130.737946 
	C1099.571533,131.684082 1098.662720,133.373215 1098.778442,136.701523 
	C1098.986572,142.693527 1098.907593,148.698669 1098.809937,154.696045 
	C1098.783569,156.313538 1098.258301,157.922928 1097.961792,159.536026 
	C1097.421753,159.505173 1096.881592,159.474319 1096.341553,159.443466 
	C1096.341553,150.484573 1096.341553,141.525696 1096.341553,132.413895 
	C1083.733887,130.809891 1075.570068,124.587433 1074.256470,112.459084 
	C1073.177979,102.502304 1077.887451,94.808395 1087.690674,90.359215 
M1102.397949,114.551407 
	C1099.184814,104.225632 1100.377930,99.585991 1107.374756,95.198883 
	C1097.256836,87.679947 1087.556030,90.798157 1080.340820,98.126877 
	C1074.856323,103.697739 1074.911377,115.472282 1080.298706,122.548210 
	C1085.777832,129.744492 1095.728271,131.251984 1103.722900,128.461456 
	C1109.731812,126.364082 1114.090820,119.661499 1113.175537,115.329247 
	C1105.597900,119.954529 1105.597900,119.954529 1103.763672,114.983429 
	C1109.319092,116.864891 1111.038940,115.666901 1111.116577,109.788521 
	C1111.151733,107.129486 1111.399170,104.411247 1110.929443,101.832970 
	C1110.684326,100.487045 1109.298218,98.822388 1108.040649,98.364365 
	C1107.134766,98.034485 1105.104126,99.150017 1104.447510,100.177979 
	C1103.546875,101.587975 1103.209839,103.508797 1103.099976,105.240456 
	C1102.893799,108.487442 1103.023926,111.755775 1102.397949,114.551407 
M1113.921753,111.939034 
	C1114.114136,112.330383 1114.306519,112.721733 1114.498901,113.113068 
	C1114.603638,112.990227 1114.797974,112.868530 1114.799683,112.744377 
	C1114.820190,111.143272 1114.813232,109.541809 1114.813232,107.940437 
	C1114.507324,107.948235 1114.201416,107.956024 1113.895508,107.963814 
	C1113.895508,109.047211 1113.895508,110.130608 1113.921753,111.939034 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M106.984024,582.261414 
	C107.662292,580.458496 108.778351,578.927002 108.923317,577.308594 
	C109.225685,573.932617 109.014847,570.510742 109.014847,567.107666 
	C109.766434,567.075134 110.518013,567.042542 111.269600,567.009949 
	C111.538620,569.071350 111.807632,571.132751 112.060410,574.089722 
	C112.396927,612.962646 112.749672,650.940125 113.072037,689.393555 
	C113.253311,698.171204 113.464966,706.472839 113.655045,715.169678 
	C113.051796,716.336548 112.470116,717.108276 111.888443,717.879944 
	C111.308228,716.877930 110.241592,715.884705 110.223183,714.872498 
	C110.042046,704.913696 110.117249,694.949829 109.888405,684.992737 
	C109.854897,683.534729 108.609535,682.104553 107.924896,680.661499 
	C107.924889,680.661499 107.921684,680.524292 108.211624,680.267090 
	C108.768570,679.428101 109.035568,678.846252 109.510132,677.889404 
	C109.484558,658.780640 109.265785,640.046692 108.973991,621.313843 
	C108.960007,620.415710 108.301476,619.527649 107.943100,618.634888 
	C107.939171,618.339539 107.940948,618.044250 108.260025,617.200073 
	C108.716125,606.342407 108.912994,596.033691 108.915504,585.724915 
	C108.915726,584.802612 107.655899,583.880005 106.981277,582.957581 
	C106.981277,582.957581 106.982033,582.493469 106.984024,582.261414 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M209.282806,945.160950 
	C208.713043,944.469177 208.449173,944.061218 208.528290,943.379028 
	C234.295059,942.753784 259.719696,942.452759 285.142029,942.017639 
	C291.760162,941.904480 298.371094,941.373169 305.260254,941.314270 
	C307.698883,941.711609 309.862610,941.828674 312.265961,942.255981 
	C315.002319,942.374756 317.499054,942.183350 320.426056,941.994873 
	C322.578522,942.019531 324.300720,942.041321 326.010620,942.413635 
	C325.958588,943.229858 325.918823,943.695557 325.426361,944.088135 
	C319.202454,944.014954 313.431274,944.014954 307.182068,944.014954 
	C307.518829,950.900391 307.826447,957.189636 308.134949,963.497375 
	C309.100800,963.497375 309.598450,963.579468 310.060059,963.485168 
	C318.603333,961.740295 322.245300,954.982117 326.137207,948.078064 
	C327.015717,948.038147 327.517853,948.018799 328.289001,948.211182 
	C331.637054,956.646912 337.631073,961.558533 345.999390,964.078796 
	C345.999817,964.589783 346.000244,964.794128 346.000641,964.998474 
	C336.915710,964.380005 331.115448,959.265991 327.190613,950.654541 
	C323.907867,957.054077 319.827545,961.994507 313.445831,964.689697 
	C306.404266,967.663513 304.334595,966.431213 304.127747,959.000916 
	C304.000366,954.425537 305.169617,948.351990 302.748260,945.740784 
	C300.608887,943.433594 294.302795,944.861877 289.836456,944.862915 
	C269.862976,944.867432 249.889343,944.919922 229.916122,945.024414 
	C223.139771,945.059875 216.364471,945.298828 209.282806,945.160950 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1794.967529,159.681030 
	C1794.725952,161.391769 1794.471558,162.644852 1794.217285,163.897949 
	C1793.690186,163.866241 1793.163208,163.834534 1792.636230,163.802811 
	C1792.473022,162.293991 1792.179199,160.786041 1792.168945,159.276169 
	C1792.116455,151.460541 1792.093994,143.644104 1792.153931,135.828644 
	C1792.175293,133.051453 1791.564575,131.565781 1788.347656,130.612183 
	C1774.710083,126.569542 1769.374512,114.825813 1773.161255,101.733521 
	C1776.604370,89.829071 1794.223267,84.054108 1804.515991,91.215820 
	C1804.651367,91.309952 1804.744751,91.491501 1804.889282,91.541634 
	C1816.126709,95.439240 1816.349609,104.577156 1814.639282,113.783447 
	C1812.743652,123.987488 1805.627686,129.547684 1794.954590,131.477249 
	C1794.954590,140.710571 1794.954590,149.966980 1794.967529,159.681030 
M1799.149414,90.978622 
	C1788.762085,87.788872 1778.079224,93.553223 1774.768799,104.134125 
	C1771.835205,113.509895 1777.408569,125.106483 1786.208496,127.845451 
	C1797.059448,131.222778 1807.812744,126.188370 1810.353882,118.477509 
	C1807.650146,117.919876 1804.941772,117.361290 1802.233398,116.802711 
	C1805.797852,113.685318 1811.355103,117.756065 1812.600342,111.998077 
	C1812.922241,110.509377 1809.622925,108.237610 1807.796509,106.102913 
	C1808.735596,104.772873 1813.718872,105.541840 1811.447632,100.972313 
	C1808.183960,94.405952 1804.019165,97.054604 1799.636230,100.797478 
	C1799.453369,100.168907 1799.270508,99.540344 1799.087646,98.911774 
	C1801.033081,97.518967 1802.978394,96.126160 1805.539795,94.292358 
	C1802.997559,92.959305 1801.426636,92.135536 1799.149414,90.978622 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M328.019989,947.999390 
	C327.517853,948.018799 327.015717,948.038147 326.156006,947.680420 
	C325.825287,946.255920 325.852173,945.208618 325.879059,944.161255 
	C325.918823,943.695557 325.958588,943.229858 326.474976,942.361450 
	C333.063110,941.944519 339.178040,941.804077 345.284454,941.972839 
	C348.089966,942.050354 349.204987,940.950989 349.445435,938.228638 
	C350.173126,938.056396 350.589966,937.960632 351.332886,938.073792 
	C353.528839,939.278320 355.331055,940.932678 357.282532,941.132996 
	C361.388580,941.554443 365.587555,940.987976 369.720459,941.256653 
	C372.973083,941.468018 374.296967,940.195312 374.472717,937.105591 
	C374.644745,937.083984 374.974609,936.964905 375.230896,936.835449 
	C376.833130,936.706055 378.179108,936.706055 379.124603,936.706055 
	C379.124603,923.687256 379.124603,910.903503 379.427002,898.181885 
	C380.090057,899.417969 380.746613,900.587341 380.764923,901.766663 
	C380.921997,911.887207 380.924255,922.010071 381.056213,932.131226 
	C381.124176,937.347046 382.695129,938.170776 387.351776,935.845581 
	C388.140350,935.451782 389.328552,935.858337 390.070312,936.169189 
	C389.234955,936.926392 388.661407,937.409424 387.624573,937.949524 
	C384.861359,938.026306 382.535339,937.848816 380.272614,938.148865 
	C379.139984,938.299011 378.122864,939.320068 377.053497,939.947144 
	C378.224060,940.607849 379.395599,941.820618 380.565002,941.818604 
	C383.325928,941.813782 386.085876,941.230774 388.893127,941.201721 
	C388.960388,941.951965 388.980713,942.381958 389.001038,942.812012 
	C384.624634,943.263184 380.248199,943.714355 375.740021,944.179138 
	C375.740021,949.538391 375.646912,954.940125 375.769653,960.336914 
	C375.866791,964.609192 374.255341,965.891846 369.987579,964.833374 
	C360.760101,962.544922 354.848694,957.033386 352.191040,947.935425 
	C351.805695,946.616333 351.192017,945.364014 350.684021,944.080750 
	C350.201630,944.149475 349.719238,944.218201 349.236847,944.286865 
	C349.236847,947.935852 349.283447,951.585571 349.222107,955.233459 
	C349.173035,958.150269 349.072144,961.072815 348.782043,963.971619 
	C348.740143,964.390198 347.509338,964.689819 346.414673,965.021484 
	C346.000244,964.794128 345.999817,964.589783 346.355743,964.040649 
	C346.738678,957.440491 346.868256,951.181030 346.631989,944.935486 
	C346.608459,944.313171 344.360901,943.295166 343.140137,943.287231 
	C339.338623,943.262329 335.522217,943.926880 331.735107,943.766968 
	C328.317810,943.622742 327.773041,945.288818 328.019989,947.999390 
M372.240601,946.671082 
	C371.380157,945.706665 370.561432,943.956116 369.652405,943.907898 
	C364.555328,943.637451 359.436432,943.777710 354.391418,943.777710 
	C353.805450,953.373413 361.889099,961.760010 372.232849,962.525452 
	C372.232849,957.544556 372.232849,952.593445 372.240601,946.671082 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1097.835083,437.177429 
	C1097.835083,437.177429 1097.563110,437.447510 1097.257568,437.165344 
	C1096.630615,436.585632 1096.309326,436.288086 1096.001221,435.522583 
	C1095.921387,420.655487 1095.924805,406.254211 1095.605469,391.860077 
	C1095.568237,390.181396 1094.093994,388.101044 1092.674561,386.983368 
	C1090.262329,385.083862 1090.423706,383.881439 1092.582397,382.006897 
	C1093.710083,381.027649 1094.789062,379.487610 1095.000732,378.068268 
	C1095.388794,375.466919 1095.117188,372.767212 1095.500244,370.181213 
	C1096.477661,374.321350 1097.075439,378.388824 1097.673218,382.456299 
	C1098.070190,382.425995 1098.467041,382.395691 1098.864014,382.365387 
	C1098.864014,375.869843 1098.864014,369.374268 1098.867798,362.550629 
	C1098.599243,361.438324 1098.326904,360.654083 1097.945312,359.706055 
	C1097.549316,359.680664 1097.262207,359.819031 1096.982300,359.978333 
	C1096.989380,359.999207 1096.958618,359.965851 1096.652832,359.970276 
	C1095.941040,359.953766 1095.534790,359.932800 1095.109375,359.561340 
	C1094.394897,358.480438 1093.699829,357.750061 1093.106689,356.732269 
	C1093.627563,355.786102 1094.046631,355.127350 1094.706055,354.090485 
	C1093.039185,354.494568 1092.008911,354.744354 1090.978516,354.994141 
	C1091.161621,354.371368 1091.167969,353.357635 1091.553711,353.183258 
	C1099.695679,349.502930 1098.035278,342.243896 1098.064209,335.601227 
	C1098.071289,333.977203 1098.065186,332.353119 1098.065186,330.729065 
	C1098.525757,330.617523 1098.986206,330.505951 1099.446777,330.394379 
	C1099.913452,331.658508 1100.784668,332.921692 1100.787598,334.186920 
	C1100.862915,365.937958 1100.843994,397.689209 1100.852661,429.440430 
	C1100.853271,431.748291 1100.936279,434.056183 1100.870850,436.787811 
	C1100.561768,437.148804 1100.362793,437.085999 1099.811401,436.775269 
	C1099.459106,436.527283 1099.011475,436.395172 1099.010376,435.901794 
	C1098.999268,432.301483 1098.986816,429.194580 1098.979858,426.087677 
	C1098.971069,422.163269 1098.966919,418.238831 1098.960938,414.314392 
	C1098.655640,414.348724 1098.342773,414.356140 1098.051025,414.438599 
	C1097.986816,414.456696 1097.997803,414.741089 1097.808716,415.022675 
	C1097.753418,415.461334 1097.863525,415.780243 1097.949829,416.561035 
	C1097.895630,423.741119 1097.865356,430.459259 1097.835083,437.177429 
M1098.438721,386.635040 
	C1098.005859,389.387970 1097.572998,392.140900 1097.140137,394.893860 
	C1097.696045,394.944672 1098.251953,394.995483 1098.807861,395.046295 
	C1098.807861,392.468262 1098.807861,389.890259 1098.438721,386.635040 
M1098.941650,403.791351 
	C1098.876587,402.843811 1098.811523,401.896271 1098.746460,400.948730 
	C1098.579590,400.956177 1098.412842,400.963593 1098.246094,400.971039 
	C1098.246094,403.618866 1098.246094,406.266693 1098.246094,408.914490 
	C1098.478516,408.920593 1098.710815,408.926697 1098.943237,408.932800 
	C1098.943237,407.487579 1098.943237,406.042389 1098.941650,403.791351 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2123.002930,1221.025513 
	C2122.537354,1220.909058 2122.036133,1220.864380 2121.616211,1220.655884 
	C2121.204346,1220.451538 2120.875000,1220.080444 2120.109375,1219.456787 
	C2119.919678,1221.298218 2119.764404,1222.805298 2119.618652,1224.222412 
	C2112.590332,1226.362793 2112.590332,1226.362793 2111.693604,1218.869263 
	C2106.197266,1228.167725 2096.055664,1228.559814 2090.230469,1219.458496 
	C2089.430176,1220.348389 2088.669434,1221.194336 2087.486816,1222.047363 
	C2087.064453,1222.054443 2086.808838,1221.816284 2086.855469,1221.423706 
	C2089.006836,1213.875244 2087.894775,1209.606812 2083.243164,1207.689697 
	C2079.091553,1205.978760 2075.458740,1207.316772 2071.857910,1210.001221 
	C2071.346191,1209.986938 2071.141602,1209.975220 2070.937012,1209.963623 
	C2074.886719,1202.286865 2083.699951,1202.644653 2090.126465,1211.036255 
	C2092.314941,1206.149658 2096.452393,1204.350830 2101.285889,1204.393555 
	C2106.436035,1204.438965 2110.167236,1206.911621 2111.813477,1212.157593 
	C2111.813477,1210.918335 2111.457764,1209.528442 2111.902832,1208.477783 
	C2112.466553,1207.146973 2113.545410,1205.251343 2114.640625,1205.073975 
	C2118.057617,1204.520874 2121.592285,1204.488647 2125.072021,1204.586670 
	C2128.171387,1204.673950 2131.961182,1209.032349 2130.591797,1211.605835 
	C2128.120117,1216.252197 2130.344482,1219.825317 2132.052002,1223.617310 
	C2126.934570,1226.050659 2124.278320,1225.471924 2123.280762,1221.513184 
	C2125.149170,1221.865479 2126.646973,1222.523315 2128.144531,1223.181152 
	C2128.417725,1222.821411 2128.690674,1222.461792 2128.963867,1222.102051 
	C2127.611084,1219.958008 2126.258057,1217.814087 2125.330078,1216.343750 
	C2126.509766,1213.992676 2127.451416,1212.115479 2128.393311,1210.238159 
	C2125.997803,1209.146118 2123.690918,1207.699585 2121.179443,1207.070679 
	C2119.063965,1206.541016 2116.710693,1206.961792 2114.157715,1206.961792 
	C2114.157715,1212.083496 2114.060791,1216.387573 2114.246582,1220.679443 
	C2114.280273,1221.458252 2115.472656,1222.186646 2116.130127,1222.938354 
	C2116.657227,1222.082520 2117.576172,1221.254395 2117.638916,1220.365723 
	C2117.845947,1217.437988 2117.516113,1214.459351 2117.885010,1211.562866 
	C2118.011963,1210.566406 2119.503906,1209.151855 2120.520996,1209.012207 
	C2121.758301,1208.842041 2123.153320,1209.816650 2124.479980,1210.296143 
	C2123.846436,1211.484497 2123.344482,1212.776733 2122.523193,1213.816772 
	C2122.122314,1214.324585 2121.126221,1214.362183 2120.843994,1214.459473 
	C2121.627686,1216.843384 2122.315430,1218.934448 2123.002930,1221.025513 
M2101.259766,1206.972534 
	C2095.543701,1207.182495 2092.716309,1209.301147 2092.323730,1214.051392 
	C2091.998291,1217.989868 2093.253662,1221.420288 2097.273926,1222.840698 
	C2101.697754,1224.403931 2107.786621,1221.992432 2108.837402,1218.601562 
	C2110.598633,1212.917969 2108.310791,1208.989502 2101.259766,1206.972534 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M940.654175,116.161926 
	C937.831360,107.409981 939.728760,99.936798 945.900696,93.959503 
	C952.203735,87.855286 959.999939,85.642342 968.354980,89.099251 
	C982.299927,94.868965 986.342285,111.558060 977.535950,122.057854 
	C974.561035,125.604881 971.512268,128.427612 967.008057,129.961960 
	C965.568420,130.452393 964.073853,133.338531 964.081665,135.122040 
	C964.152527,151.251572 964.563965,167.379456 964.835999,183.508499 
	C964.864624,185.206604 964.638245,186.909027 963.440308,188.606781 
	C961.693115,169.674744 962.385925,150.692642 961.895203,131.349365 
	C951.887146,130.489197 944.491821,126.034378 940.654175,116.161926 
M960.604919,89.947517 
	C958.825745,90.294647 956.995300,90.478027 955.275757,91.015877 
	C945.089172,94.202080 939.494629,104.725021 942.663696,114.597038 
	C946.176819,125.540756 956.229248,130.837463 966.766296,127.296791 
	C974.680603,124.637405 980.932251,115.320290 979.937500,107.667206 
	C978.648499,97.750732 971.087891,90.497749 960.604919,89.947517 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M103.643188,370.303192 
	C104.094810,370.911652 104.076607,371.413635 104.100082,372.156921 
	C92.354942,372.603973 80.568489,372.913727 68.781082,372.956024 
	C64.936768,372.969849 62.516945,373.477844 61.149097,377.958099 
	C58.479248,386.702850 51.719952,391.790710 42.815319,392.513367 
	C34.492535,393.188843 27.789042,388.803619 23.031914,382.118896 
	C17.309912,374.078308 19.990154,360.248962 28.764460,353.456360 
	C36.628723,347.368256 48.474277,347.322144 55.486507,355.027771 
	C58.837326,358.709930 61.362083,362.409515 62.195187,367.408875 
	C62.380455,368.520691 64.827881,370.015198 66.256882,370.040802 
	C78.559372,370.261017 90.867165,370.184601 103.643188,370.303192 
M22.854698,371.401031 
	C23.682230,382.480377 32.060543,390.543152 42.192627,390.000580 
	C52.153660,389.467163 60.929523,381.536194 60.226917,369.810303 
	C59.668583,360.492126 51.581871,351.995972 42.253761,351.844757 
	C30.902086,351.660767 23.392859,358.888672 22.854698,371.401031 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M65.098808,715.114136 
	C63.260025,724.878845 58.288364,731.662842 48.081253,734.111511 
	C38.973015,736.296570 29.289164,730.361816 25.754841,723.069763 
	C18.918556,708.965210 25.603714,695.335327 40.877674,691.659729 
	C51.497681,689.104126 63.664902,698.859436 65.143921,711.746460 
	C65.169907,713.322449 65.134361,714.218262 65.098808,715.114136 
M46.973106,731.874390 
	C58.399590,729.280518 64.024506,719.624756 63.109150,711.002136 
	C62.561939,705.847473 58.543114,698.937744 55.565388,699.069397 
	C50.835819,699.278503 46.128265,699.985718 41.007710,700.524719 
	C41.482944,703.031677 41.928234,705.380737 42.345894,707.583984 
	C44.376293,707.959412 45.993004,708.258362 47.609715,708.557251 
	C47.610493,708.997864 47.611271,709.438477 47.612049,709.879150 
	C45.835743,710.430298 44.059437,710.981384 41.928455,711.642578 
	C42.041920,713.755737 42.150860,715.784607 42.288982,718.356934 
	C46.414345,718.356934 49.897991,718.356934 53.381638,718.356934 
	C53.378262,718.873108 53.374886,719.389221 53.371506,719.905396 
	C50.031269,719.905396 46.686012,719.791931 43.352283,719.939209 
	C40.386551,720.070312 39.067684,718.911438 39.124081,715.890503 
	C39.211025,711.233093 39.245495,706.566284 39.043415,701.914795 
	C38.908257,698.803650 40.177387,697.782104 43.149117,697.936035 
	C46.519218,698.110657 49.905331,697.976379 53.985313,697.976379 
	C45.201557,690.413086 30.731209,694.686523 27.076080,705.079041 
	C22.066963,719.321289 30.964056,731.859680 46.973106,731.874390 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1101.778442,529.129761 
	C1102.582886,529.890564 1104.000732,531.389771 1104.102905,531.304932 
	C1107.215698,528.719727 1110.420410,529.629211 1114.023682,530.098999 
	C1118.022339,530.620361 1122.232056,529.523254 1127.099121,529.059448 
	C1125.597656,530.775513 1124.540649,531.983582 1123.483765,533.191589 
	C1123.895020,533.649658 1124.306274,534.107788 1124.717529,534.565857 
	C1126.435303,533.054382 1128.153076,531.542847 1129.870728,530.031372 
	C1129.677368,529.446594 1129.484009,528.861816 1129.290527,528.277039 
	C1132.685913,528.836670 1136.081299,529.396301 1139.476685,529.955933 
	C1139.462769,530.289001 1139.448853,530.622070 1139.434937,530.955078 
	C1140.632935,530.225586 1141.830811,529.496094 1143.736450,528.335632 
	C1143.586304,531.136658 1143.476196,533.190735 1143.381592,534.956360 
	C1140.808716,534.956360 1138.588257,534.956360 1135.989258,534.956360 
	C1134.622437,532.714233 1132.920166,530.088318 1130.425903,534.997253 
	C1130.256470,535.330750 1128.987305,534.977844 1128.318726,535.205566 
	C1123.046387,537.001221 1117.846802,536.710876 1112.418213,535.609924 
	C1109.872803,535.093750 1106.952759,536.425293 1104.202637,536.919495 
	C1103.916870,536.413147 1103.631104,535.906860 1103.345337,535.400574 
	C1102.739502,536.702637 1102.133667,538.004761 1100.820068,540.828247 
	C1107.302612,540.532166 1112.371582,540.207458 1117.444336,540.133667 
	C1118.738525,540.114746 1120.044312,540.891174 1121.344849,541.302246 
	C1120.449707,542.464539 1119.660034,543.732971 1118.628784,544.758362 
	C1117.659668,545.721985 1116.435181,546.428955 1115.084839,547.424438 
	C1114.727051,546.065491 1114.331787,544.564026 1113.630371,541.899231 
	C1112.184448,544.252686 1111.262329,545.753662 1110.627075,546.787720 
	C1109.031006,545.376282 1107.473389,543.998962 1105.915894,542.621643 
	C1105.207275,544.078735 1104.498779,545.535889 1103.401367,546.763733 
	C1104.383545,542.722473 1101.203613,542.210938 1099.156006,540.660645 
	C1099.411011,539.290161 1099.946655,537.596985 1099.531616,537.317871 
	C1094.882080,534.191162 1097.437256,531.613586 1100.273926,528.971191 
	C1100.977661,529.021790 1101.378052,529.075745 1101.778442,529.129761 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2035.807373,96.807304 
	C2037.060181,94.518616 2038.312866,92.229912 2039.744873,89.613503 
	C2036.451050,84.414230 2030.646118,84.130005 2025.265381,82.950089 
	C2023.913086,82.653526 2022.594604,82.202850 2021.219482,81.454048 
	C2028.340698,80.598549 2034.881958,81.381813 2040.484131,86.908112 
	C2047.648193,93.975334 2048.936035,106.568420 2042.789429,114.635033 
	C2039.453125,119.013229 2035.562134,122.330940 2029.933472,123.104958 
	C2026.666260,123.554214 2025.783325,125.371620 2025.856689,128.603149 
	C2026.098755,139.262360 2026.006958,149.929153 2026.041504,160.593048 
	C2026.046631,162.167847 2026.042358,163.742676 2026.042358,165.317490 
	C2025.521118,165.355713 2025.000000,165.393921 2024.478760,165.432144 
	C2024.315430,163.816696 2024.019897,162.202042 2024.010132,160.585693 
	C2023.943848,149.587891 2024.065552,138.586258 2023.768555,127.595543 
	C2023.726074,126.024513 2022.016968,123.476753 2020.631348,123.120079 
	C2004.744873,119.030586 2000.007812,101.574699 2008.734253,89.311119 
	C2008.856079,90.386147 2009.035400,91.824570 2008.504395,92.918320 
	C2005.011597,100.114235 2006.082764,110.413750 2010.991577,115.357689 
	C2017.375977,121.787651 2027.432373,123.575294 2035.213135,119.151169 
	C2037.675659,117.751007 2040.207642,115.376007 2041.221802,112.850380 
	C2042.137207,110.571037 2041.021973,107.476234 2040.764771,104.275139 
	C2039.250488,104.138954 2037.826050,104.053528 2036.413940,103.871811 
	C2035.013062,103.691589 2033.624878,103.414391 2031.487671,103.054565 
	C2032.667114,100.990768 2033.458374,99.606361 2034.623291,98.104355 
	C2034.996826,97.986748 2035.176147,98.145493 2035.119873,98.534210 
	C2034.562988,102.132874 2036.884155,102.512138 2038.718018,101.656509 
	C2042.876831,99.716087 2040.545288,96.374466 2040.399292,93.567513 
	C2038.831909,94.907829 2037.497437,96.049065 2036.074951,97.093750 
	C2035.986816,96.997192 2035.807373,96.807289 2035.807373,96.807304 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1561.031128,130.977600 
	C1560.105591,130.269012 1559.241333,129.049759 1558.245239,128.930511 
	C1547.719482,127.670319 1540.655518,118.390038 1540.003296,107.894760 
	C1539.440430,98.836937 1546.945557,89.083549 1556.239380,86.526169 
	C1564.968140,84.124268 1579.677124,90.353676 1581.631714,98.010788 
	C1585.018433,111.277954 1580.946411,125.200729 1565.042969,128.863525 
	C1564.034180,129.095856 1563.131592,129.789505 1561.864746,130.638397 
	C1561.376953,130.998032 1561.204102,130.987808 1561.031128,130.977600 
M1567.198608,114.051163 
	C1566.611328,106.002586 1567.990112,104.128860 1575.518433,103.160866 
	C1578.349365,102.796890 1579.323242,101.733528 1578.219482,98.857018 
	C1576.682861,94.852676 1573.897217,95.371445 1570.921875,96.594490 
	C1569.773560,97.066528 1568.805786,97.977577 1567.755615,98.688599 
	C1567.431152,98.276543 1567.106567,97.864479 1566.781982,97.452423 
	C1568.629150,95.771538 1570.476196,94.090645 1572.337769,92.396690 
	C1564.520996,87.334534 1554.725098,87.674408 1548.292969,93.187027 
	C1542.114380,98.482399 1540.183472,109.013916 1544.536377,116.983261 
	C1547.642822,122.670761 1554.542603,128.451447 1566.226074,126.027687 
	C1571.597290,124.913399 1576.205933,120.888435 1576.647461,116.490608 
	C1573.640259,115.924644 1570.628906,115.357887 1567.198608,114.051163 
M1575.342041,113.114410 
	C1580.369141,112.513779 1580.654785,111.953278 1579.558594,104.417130 
	C1576.397705,107.499016 1567.575439,103.060501 1569.448364,112.884537 
	C1571.215820,112.963516 1572.830200,113.035652 1575.342041,113.114410 
M1578.313599,115.487961 
	C1578.313599,115.487961 1578.524536,115.598183 1578.524536,115.598183 
	C1578.524536,115.598183 1578.486572,115.364441 1578.313599,115.487961 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M209.962372,120.231079 
	C208.080505,111.601967 212.520065,102.304253 220.308044,98.027611 
	C227.508560,94.073563 237.515686,95.564011 243.491959,100.883537 
	C253.984222,110.222794 252.581070,124.258934 243.979721,133.127197 
	C242.013031,135.154938 239.110046,136.744827 236.362961,137.424332 
	C233.053452,138.242935 232.764145,139.986938 232.803467,142.750977 
	C232.950134,153.057281 232.945755,163.366028 232.943619,173.673859 
	C232.943268,175.363174 232.668884,177.052414 231.430878,178.726639 
	C229.881973,165.430344 230.348984,152.099747 230.011276,138.531677 
	C219.055328,137.158722 211.350479,132.344543 209.962372,120.231079 
M211.944763,120.018890 
	C214.841858,130.570023 220.193588,135.553589 229.614731,136.033157 
	C244.520355,136.791931 251.679291,121.777618 246.873871,109.044304 
	C244.586945,102.984421 239.803421,98.857414 232.723755,97.922050 
	C222.159821,96.526360 210.152710,105.185280 211.944763,120.018890 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1942.999878,908.007202 
	C1941.933228,908.027222 1940.866455,908.047241 1939.388184,907.696655 
	C1938.983276,905.548035 1938.989868,903.769958 1939.286865,901.698914 
	C1946.730957,901.816345 1952.399048,900.195679 1953.503540,891.449097 
	C1954.073242,894.505920 1954.642944,897.562805 1955.200684,900.556213 
	C1957.548462,900.424622 1959.375977,900.082886 1961.146973,900.271606 
	C1965.115723,900.694519 1969.056274,901.381287 1973.099609,902.361450 
	C1979.707764,903.036255 1986.223633,903.369263 1992.743530,903.501709 
	C1993.473389,903.516602 1994.226562,902.395142 1995.313354,901.815247 
	C1996.116699,901.840637 1996.575562,901.851501 1997.086182,902.232483 
	C1998.223755,902.975525 1999.314941,903.677246 2000.394531,903.659851 
	C2003.625122,903.607727 2006.850830,903.145813 2010.079224,903.135559 
	C2010.950195,903.132751 2011.824341,904.166992 2012.696899,904.725220 
	C2017.450073,906.015442 2017.450073,906.015442 2018.402100,901.917847 
	C2019.341309,901.817383 2019.882935,901.812988 2020.328369,902.213318 
	C2021.376099,909.307556 2026.860352,904.158203 2030.068848,906.325928 
	C2030.068848,907.746399 2030.068848,909.397583 2029.741943,911.311646 
	C2023.194824,911.574463 2016.974609,911.574463 2010.378174,911.574463 
	C2011.485840,919.428589 2015.723755,923.793518 2020.065674,928.094788 
	C2013.829590,926.252441 2008.044800,918.397400 2008.562134,911.847534 
	C2008.642700,910.827637 2010.862061,909.262878 2012.170044,909.180359 
	C2017.110229,908.868591 2022.082642,909.064148 2027.747314,908.903076 
	C2028.241211,908.246033 2028.030884,907.755676 2027.820679,907.265320 
	C2016.287109,907.443665 2004.753662,907.634277 1993.219849,907.792908 
	C1989.756592,907.840515 1986.291992,907.799255 1982.344238,907.661377 
	C1981.542114,907.639954 1981.223511,907.755859 1980.905029,907.871704 
	C1979.236206,907.720947 1977.567383,907.570190 1975.254150,907.319458 
	C1974.072632,907.462646 1973.535400,907.705872 1972.998169,907.949097 
	C1969.929688,907.944336 1966.861084,907.939575 1963.270752,907.672546 
	C1962.503662,906.949280 1962.258179,906.488281 1962.458252,906.005005 
	C1965.248047,905.857056 1967.592407,905.731445 1970.737549,905.562866 
	C1969.752563,904.198975 1969.408936,903.299805 1969.055664,903.296021 
	C1960.253296,903.200195 1951.437866,902.940063 1942.654785,903.351196 
	C1940.265747,903.463013 1940.487549,905.390747 1942.967041,906.444946 
	C1942.989990,907.173767 1942.994995,907.590454 1942.999878,908.007202 
M1988.198242,905.967102 
	C1989.780273,905.967102 1991.362427,905.967102 1992.944458,905.967102 
	C1992.942261,905.730591 1992.940063,905.494080 1992.937988,905.257568 
	C1986.645996,905.257568 1980.354004,905.257568 1974.062012,905.257568 
	C1974.059692,905.497253 1974.057373,905.736877 1974.055176,905.976562 
	C1978.462891,905.976562 1982.870483,905.976562 1988.198242,905.967102 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M379.954163,306.615906 
	C379.515625,307.084137 379.121857,307.077118 378.434875,307.103271 
	C377.772705,287.033173 377.228180,266.930481 377.160706,246.826218 
	C377.144226,241.918381 375.669617,240.524078 370.949829,240.848938 
	C365.150818,241.248062 359.303345,240.993240 353.477936,240.925980 
	C352.007233,240.908997 350.540710,240.530151 349.072235,240.317596 
	C349.070740,239.766296 349.069244,239.214996 349.067749,238.663696 
	C350.529877,238.449722 351.989868,238.069580 353.454529,238.050522 
	C359.779144,237.968292 366.115448,237.767639 372.426727,238.059402 
	C376.326141,238.239655 377.224945,236.748856 377.101837,233.145187 
	C376.621063,219.073395 376.372650,204.993515 376.063782,190.916077 
	C376.002289,188.112778 376.054962,185.306961 376.054962,182.502289 
	C376.886749,182.499084 377.718506,182.495865 378.550293,182.492661 
	C378.550293,200.752457 378.550293,219.012253 378.550293,238.108658 
	C384.487030,238.108658 389.421387,238.108658 394.355774,238.108658 
	C394.354248,238.747757 394.352722,239.386841 394.351227,240.025940 
	C390.699249,240.025940 387.045715,240.092361 383.395844,240.005890 
	C380.217651,239.930618 379.600311,240.816360 379.674591,244.381989 
	C380.103271,264.961517 379.953094,285.553131 379.954163,306.615906 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1306.732178,87.686539 
	C1317.692139,86.117691 1327.894531,93.008911 1330.816650,103.576973 
	C1333.584473,113.587212 1328.193115,125.136330 1318.269775,128.567123 
	C1313.036987,130.376251 1311.445923,132.981445 1311.839600,138.256653 
	C1312.443481,146.350845 1312.416382,154.495834 1312.501587,162.620880 
	C1312.513550,163.766632 1311.647705,164.921600 1311.188110,166.072311 
	C1310.666504,165.994873 1310.144775,165.917450 1309.623169,165.840027 
	C1309.466919,164.171204 1309.186035,162.503174 1309.175415,160.833405 
	C1309.121338,152.354706 1309.034912,143.873810 1309.182129,135.397522 
	C1309.236206,132.277863 1308.463745,130.528824 1305.087646,129.831192 
	C1295.215698,127.791313 1288.089233,117.310600 1288.903687,107.242584 
	C1289.690186,97.519096 1295.995972,90.023460 1306.732178,87.686539 
M1299.065186,124.528229 
	C1309.290039,131.602600 1320.141846,128.918869 1326.858032,117.654907 
	C1332.029297,108.981956 1328.009644,96.462135 1318.572144,91.751884 
	C1309.908936,87.428200 1299.221436,90.218742 1294.442261,97.337479 
	C1288.869873,105.637733 1289.001221,115.791748 1299.065186,124.528229 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M859.901733,537.017822 
	C859.789673,536.468079 859.677673,535.918396 859.565674,535.368652 
	C864.975464,537.138428 867.139404,532.166565 870.283386,529.804260 
	C879.775208,522.672119 888.946594,515.103088 898.051880,507.476318 
	C899.738037,506.064026 900.533630,503.588623 902.086182,501.034943 
	C905.423767,502.730164 908.105835,499.264587 911.216003,496.631897 
	C912.065552,495.912811 913.478149,495.858826 915.522278,495.222656 
	C918.797974,489.505280 925.116516,485.373779 931.666992,481.067078 
	C931.999207,480.999573 931.977600,480.977844 931.964661,481.330292 
	C931.627258,482.795319 931.302795,483.907898 930.989258,485.009918 
	C931.000183,484.999359 931.022522,484.978851 930.653564,484.972931 
	C929.286316,485.593872 927.762573,486.004913 927.398071,486.892181 
	C926.614624,488.799255 926.428284,490.951660 925.893372,493.080078 
	C925.789185,493.156464 925.572510,493.015625 925.195679,493.071167 
	C919.697510,496.805084 914.576172,500.483459 909.196289,504.237213 
	C907.156738,504.312561 905.375854,504.312561 904.770447,504.312561 
	C902.756470,506.824341 901.173767,509.063751 899.312012,511.040405 
	C897.685608,512.767090 895.133484,513.957825 896.657959,516.948730 
	C892.531555,519.436157 888.679138,521.808289 885.054565,524.488647 
	C878.644287,529.228943 872.372681,534.156799 865.666199,539.004700 
	C863.493469,538.341431 861.697632,537.679626 859.901733,537.017822 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M124.697113,119.749878 
	C122.350838,129.377289 117.929100,136.631973 107.868324,139.094162 
	C106.933693,139.322891 105.940727,141.544022 105.908997,142.862488 
	C105.728966,150.343857 105.860428,157.832214 105.818100,165.317825 
	C105.809601,166.820206 105.508224,168.320953 105.341888,169.822449 
	C104.799950,169.839752 104.258018,169.857056 103.716080,169.874359 
	C103.499893,168.448425 103.113998,167.024612 103.096237,165.596222 
	C103.009407,158.610901 102.967392,151.623962 103.020309,144.638428 
	C103.045967,141.250992 102.384232,139.175369 98.216034,138.707153 
	C91.035545,137.900528 83.822548,129.664993 82.220161,121.453003 
	C80.884598,114.608482 85.427734,104.160843 91.770386,100.310646 
	C98.788635,96.050316 105.839401,95.731071 113.409698,99.729782 
	C121.557968,104.033791 123.792068,111.189964 124.697113,119.749878 
M89.956657,104.423683 
	C80.932098,113.511932 83.711479,130.662125 95.467194,135.325272 
	C104.271393,138.817657 113.807205,136.276794 119.322021,127.370064 
	C124.033066,119.761467 121.791367,108.508614 114.753334,103.228439 
	C106.406746,96.966522 98.423141,98.294136 89.956657,104.423683 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2383.070312,1038.937622 
	C2377.810059,1038.763184 2372.548340,1038.412964 2367.289551,1038.447021 
	C2351.012207,1038.552856 2334.736328,1038.817871 2318.459473,1038.963867 
	C2317.607422,1038.971558 2316.750488,1038.451660 2315.895996,1038.177490 
	C2315.867432,1037.667847 2315.838867,1037.158325 2315.810303,1036.648682 
	C2317.808594,1036.369263 2319.804932,1035.862427 2321.805176,1035.846680 
	C2337.592041,1035.723022 2353.380371,1035.748047 2369.166992,1035.616211 
	C2380.796875,1035.519165 2392.425049,1035.254883 2404.988525,1035.074829 
	C2420.948730,1035.057251 2435.974365,1035.030029 2451.000000,1035.001343 
	C2451.000000,1035.000000 2451.000000,1034.997192 2451.073242,1035.243530 
	C2451.430908,1035.658936 2451.715576,1035.827881 2451.982910,1035.982422 
	C2451.965576,1035.967773 2451.933594,1035.935791 2451.932617,1036.327759 
	C2451.877930,1037.502686 2451.824219,1038.285522 2451.770752,1039.068481 
	C2451.770752,1039.068481 2451.587646,1039.073608 2451.207764,1038.744873 
	C2448.935303,1038.235352 2447.042480,1038.054443 2444.671875,1037.863525 
	C2429.623779,1037.906006 2415.053711,1037.898193 2400.484619,1038.043091 
	C2395.210449,1038.095459 2389.940186,1038.513062 2384.401367,1038.772217 
	C2383.780029,1038.832886 2383.425293,1038.885254 2383.070312,1038.937622 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2273.000000,667.002686 
	C2274.261475,667.801514 2275.523193,668.600281 2276.564941,669.794556 
	C2274.977783,670.428711 2273.610352,670.667480 2271.323730,671.066589 
	C2275.622559,674.234497 2278.532227,676.573181 2281.635986,678.617493 
	C2285.680420,681.281555 2289.878906,683.712280 2294.013184,686.603943 
	C2294.280762,688.353394 2294.545898,689.742310 2294.468750,691.121277 
	C2275.883789,677.632874 2257.869385,663.812866 2237.949951,652.779419 
	C2236.179443,655.559937 2234.768799,657.775085 2233.358398,659.990234 
	C2232.985596,659.802979 2232.613037,659.615662 2232.240479,659.428345 
	C2232.444824,658.391846 2232.373779,657.181396 2232.900146,656.348389 
	C2235.460205,652.296936 2234.441650,650.177429 2229.996826,648.200562 
	C2211.573242,640.006531 2193.316650,631.437744 2174.998047,622.663696 
	C2175.001465,621.863525 2175.005615,621.406250 2175.382324,620.990845 
	C2181.384521,623.188477 2186.997314,625.390076 2192.652100,627.478699 
	C2195.033691,628.358459 2197.503174,628.999817 2200.064697,630.083984 
	C2207.256592,634.156250 2214.280518,637.966553 2221.420898,641.544250 
	C2222.359619,642.014648 2223.894775,641.295471 2225.172119,641.465942 
	C2230.248535,646.886292 2246.411377,654.901550 2249.107422,653.564270 
	C2248.003906,652.213623 2246.916016,650.882080 2245.828125,649.550537 
	C2247.343262,650.241638 2248.858154,650.932739 2250.705078,652.190491 
	C2250.830078,654.344849 2250.623047,655.932495 2250.444092,657.303650 
	C2255.723389,660.894165 2261.178223,663.832520 2265.628174,667.876465 
	C2269.144531,671.071716 2270.953369,670.897217 2273.000000,667.002686 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M753.858948,159.570160 
	C753.556335,160.963654 753.256470,161.911774 752.956604,162.859894 
	C752.533386,162.838654 752.110168,162.817413 751.686890,162.796158 
	C751.503052,161.464035 751.173828,160.133423 751.159912,158.799530 
	C751.092163,152.303497 751.049866,145.805923 751.122986,139.310287 
	C751.154114,136.546509 750.293091,135.295502 747.270020,134.729889 
	C735.454590,132.519226 724.707825,119.432472 730.702087,105.641487 
	C736.296448,92.770569 751.983398,86.470566 764.524414,97.054138 
	C776.434570,107.105293 776.033386,121.121323 762.638733,131.667404 
	C762.248230,131.974884 761.859436,132.477448 761.433777,132.518906 
	C753.207153,133.320267 753.416260,139.030731 753.802368,145.135498 
	C754.096191,149.781265 753.861389,154.460449 753.858948,159.570160 
M769.010376,121.223770 
	C773.462036,113.288673 769.741455,103.928818 762.874390,98.238243 
	C757.142883,93.488670 748.859314,92.704620 741.928833,96.466354 
	C735.890869,99.743637 730.455322,108.453491 731.101746,113.815590 
	C732.169739,122.674332 739.145874,130.394150 748.030273,132.548813 
	C755.377930,134.330765 763.548218,130.113708 769.010376,121.223770 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2536.000000,889.997559 
	C2541.050781,893.646606 2542.884277,904.245117 2540.107910,910.841125 
	C2536.988525,918.252136 2531.070801,922.085571 2523.910645,924.291260 
	C2514.702637,927.127747 2501.039062,918.385193 2499.163086,908.406128 
	C2498.498047,904.868835 2497.066162,903.635132 2493.564209,903.798706 
	C2488.246338,904.047241 2482.910400,903.948853 2477.582764,903.914368 
	C2476.077393,903.904663 2474.574463,903.603943 2473.070312,903.436707 
	C2473.044434,902.904114 2473.018555,902.371521 2472.992676,901.838867 
	C2474.254395,901.604553 2475.513184,901.187439 2476.778320,901.167419 
	C2482.437988,901.077942 2488.102051,900.998352 2493.759521,901.126343 
	C2496.769775,901.194458 2498.465576,900.662354 2499.310547,897.134155 
	C2501.647705,887.375793 2509.077148,882.714233 2518.259033,881.326294 
	C2525.426758,880.242859 2531.383057,883.715210 2535.628662,889.685669 
	C2534.122803,889.421509 2532.908203,888.860535 2531.826660,888.108521 
	C2520.592285,880.296875 2511.030273,883.879761 2504.622314,890.987610 
	C2499.169189,897.036438 2497.990967,910.374512 2508.317383,917.956970 
	C2515.091553,922.930725 2522.150146,922.673889 2529.458740,919.339233 
	C2536.140869,916.290039 2539.282471,910.783813 2538.960938,903.803528 
	C2538.747070,899.165894 2537.039062,894.597168 2536.000000,889.997559 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M442.085754,950.946899 
	C446.055359,960.622742 454.490204,958.585632 461.920685,957.914185 
	C463.812836,957.743164 466.161133,954.310303 466.964813,951.926086 
	C469.633972,944.007690 476.051819,946.688538 481.457031,946.020569 
	C486.340546,945.417053 487.762207,948.707336 489.373566,952.050659 
	C490.580841,954.555603 493.188507,957.401611 492.686188,959.500793 
	C491.671600,963.740723 489.093811,967.649902 486.856567,971.519775 
	C486.372314,972.357361 484.634827,972.680298 483.422333,972.837646 
	C481.621429,973.071411 479.733582,972.711670 477.953766,973.017639 
	C472.494629,973.956055 468.913208,971.643188 467.168549,966.674011 
	C465.355469,961.509827 461.811707,960.002502 456.777527,960.778992 
	C455.965424,960.904236 455.118347,960.802673 454.287445,960.806030 
	C444.815460,960.844116 444.607544,960.704346 441.081299,951.364563 
	C441.600189,950.972595 441.842987,950.959717 442.085754,950.946899 
M470.053558,958.118896 
	C476.427887,958.118896 482.802185,958.118896 489.822021,958.118896 
	C488.841431,956.058289 488.249237,954.738953 487.594696,953.451355 
	C484.853699,948.059387 481.535522,946.315186 473.867828,948.578979 
	C471.190857,949.369263 469.551270,953.721680 467.547211,956.525574 
	C467.482574,956.616089 468.621002,957.566528 470.053558,958.118896 
M476.946655,970.007141 
	C477.283356,967.452271 477.620026,964.897400 477.909027,962.704346 
	C479.298645,965.385376 480.369568,969.672668 481.783569,969.789001 
	C487.416382,970.252319 486.804047,964.687439 488.911469,961.208374 
	C481.435913,961.208374 474.517609,961.208374 466.792694,961.208374 
	C470.233948,964.730164 469.601685,971.059998 476.946655,970.007141 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M662.020508,939.045776 
	C667.630676,939.045776 673.240784,939.045776 678.850952,939.045776 
	C678.863586,938.279175 678.876282,937.512573 678.888916,936.745911 
	C677.283447,936.558838 675.675537,936.191040 674.072876,936.212952 
	C659.977478,936.405518 645.883545,936.789978 631.788086,936.848938 
	C626.114258,936.872681 620.437622,936.228149 615.057251,935.461182 
	C616.901611,934.715942 618.451050,934.397217 620.349792,934.113281 
	C621.849243,934.343201 622.999451,934.538269 624.603271,934.804810 
	C636.811218,934.797241 648.565613,934.724182 660.319824,934.637878 
	C684.869446,934.457520 709.419067,934.270325 733.968689,934.085876 
	C733.728271,934.381775 733.487854,934.677734 732.554504,935.051392 
	C727.402161,935.113708 722.939636,935.009583 718.486084,935.162598 
	C717.664673,935.190857 716.878967,936.258728 716.076721,936.846741 
	C716.580383,937.416077 717.123413,938.509033 717.581726,938.474609 
	C722.737610,938.087097 727.880493,937.526306 733.026855,937.012085 
	C735.315186,937.771179 737.603577,938.530334 739.949036,939.651062 
	C718.737122,940.323364 697.468201,940.638489 676.199036,940.941284 
	C671.726318,941.004944 667.252930,941.023743 662.381348,940.704956 
	C661.995422,939.913025 662.007996,939.479431 662.020508,939.045776 
M701.932190,938.840698 
	C702.916016,938.284668 703.899841,937.728638 704.883667,937.172607 
	C704.707092,936.750488 704.530518,936.328430 704.353943,935.906372 
	C699.300110,936.086487 694.154541,935.203613 689.198669,936.518494 
	C689.217957,937.299927 689.237244,938.081360 689.256531,938.862793 
	C693.186584,938.862793 697.116699,938.862793 701.932190,938.840698 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M540.154541,158.865875 
	C540.136597,152.225357 540.011719,146.060074 540.133179,139.899658 
	C540.195923,136.718170 539.392700,135.164352 535.774963,134.794205 
	C527.463318,133.943817 519.511658,124.361847 519.038452,115.729172 
	C518.363098,103.408348 525.798950,95.441566 534.710144,93.150673 
	C546.073669,90.229317 558.050293,98.207962 560.659912,109.915390 
	C563.030762,120.551422 554.935913,132.617371 543.858337,134.980331 
	C543.389648,135.080307 542.952148,135.326324 541.872681,135.751358 
	C541.872681,145.857620 541.872681,156.165909 541.872681,166.474197 
	C541.298340,166.476807 540.724060,166.479401 540.149719,166.482010 
	C540.149719,164.102386 540.149719,161.722778 540.154541,158.865875 
M540.352600,94.884132 
	C534.402588,94.434868 529.819397,97.143684 525.803040,101.172546 
	C520.155762,106.837326 519.362976,118.051186 524.153076,124.588562 
	C529.658630,132.102371 539.404724,135.132187 546.891968,131.657516 
	C555.001404,127.894089 559.175537,120.120285 558.023926,110.925377 
	C557.065552,103.273575 550.156433,96.689110 540.352600,94.884132 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2410.263184,838.212524 
	C2409.635986,837.790039 2409.008545,837.367493 2407.828369,836.642334 
	C2405.831055,832.738586 2404.386963,829.137451 2402.937500,825.123047 
	C2401.618652,820.737366 2400.304932,816.764954 2399.139160,812.505493 
	C2399.448730,811.760071 2399.887451,811.146667 2399.730713,810.865845 
	C2395.848389,803.915405 2391.925293,796.986816 2387.861084,790.142334 
	C2387.739746,789.937561 2386.029541,790.675842 2384.750488,790.831543 
	C2383.447021,789.409485 2382.432617,788.145874 2381.463623,786.848450 
	C2377.287109,781.255798 2373.124023,775.653259 2368.921631,770.076660 
	C2368.886963,770.098938 2368.808594,770.123657 2368.877441,769.825806 
	C2370.314209,768.665466 2371.682129,767.802917 2373.049805,766.940430 
	C2373.049805,766.940430 2373.008301,766.961792 2373.153076,767.235352 
	C2373.657715,767.654297 2374.017334,767.799744 2374.377197,767.945190 
	C2386.092041,783.133484 2396.967529,798.842346 2404.958984,816.391724 
	C2407.836426,822.711060 2411.014404,828.893616 2413.830322,835.646606 
	C2413.367676,836.490479 2413.129639,836.826355 2412.603516,837.177368 
	C2411.631592,837.532532 2410.947266,837.872559 2410.263184,838.212524 
M2410.481445,835.625671 
	C2410.481445,835.625671 2410.575928,835.453674 2410.575928,835.453674 
	C2410.575928,835.453674 2410.394287,835.479980 2410.481445,835.625671 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1997.968994,1211.073120 
	C2005.643311,1211.385864 2011.728760,1216.538574 2013.806763,1224.483521 
	C2015.871948,1232.378662 2012.320190,1241.160034 2005.236938,1245.672241 
	C1998.935913,1249.686157 1989.475952,1248.953247 1983.818726,1243.939453 
	C1978.009277,1238.790771 1975.304077,1232.366699 1977.421387,1224.761230 
	C1979.775513,1216.305176 1985.489380,1211.275024 1994.703369,1210.946045 
	C1994.937012,1212.060669 1994.920288,1212.591919 1994.536011,1212.908569 
	C1985.305786,1213.077637 1978.244385,1220.689819 1979.444824,1228.682007 
	C1984.519531,1228.682007 1989.610840,1228.682007 1994.892578,1229.005127 
	C1995.478760,1229.624390 1995.874634,1229.920532 1996.254150,1230.669434 
	C1996.237793,1235.993530 1996.237793,1240.865112 1996.237793,1245.717041 
	C2005.528809,1245.664307 2012.204346,1238.616577 2011.089600,1229.894165 
	C2006.459229,1229.894165 2001.708374,1229.894165 1997.170166,1229.626221 
	C1997.800659,1229.187134 1998.218506,1229.015991 1999.009766,1228.788818 
	C2003.586304,1228.493774 2007.789185,1228.254639 2011.982544,1228.015991 
	C2011.344727,1219.134888 2005.445679,1212.994629 1998.176270,1212.889160 
	C1998.066650,1212.042969 1998.017822,1211.558105 1997.968994,1211.073120 
M1994.899902,1231.976807 
	C1993.871826,1231.374878 1992.866943,1230.306396 1991.811401,1230.253662 
	C1987.735718,1230.050171 1983.643921,1230.171021 1979.646484,1230.171021 
	C1978.695068,1238.562744 1986.253052,1246.052124 1994.978516,1245.695435 
	C1994.978516,1241.444336 1994.978516,1237.168945 1994.899902,1231.976807 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M82.940468,846.971802 
	C82.634171,834.906860 82.246788,822.842224 82.183823,810.775940 
	C82.179062,809.863708 84.456291,808.187195 85.716026,808.144531 
	C93.163559,807.892517 100.623871,808.018677 108.541504,808.385193 
	C109.021675,809.455994 109.040237,810.159180 108.586014,810.859558 
	C102.738686,810.870117 97.364143,810.883606 91.562256,810.892517 
	C89.395706,810.906311 87.631432,810.767700 85.932732,811.039246 
	C85.428741,811.119812 85.109192,812.354187 84.723427,813.490356 
	C84.535789,818.181763 84.329849,822.442627 84.123917,826.703552 
	C84.856316,826.729797 85.588722,826.756042 86.321121,826.782288 
	C86.321121,822.238831 86.321121,817.695374 86.711266,813.089478 
	C88.716454,813.059631 90.331497,813.092285 91.870270,813.477905 
	C88.208984,815.113708 87.288918,817.481323 88.645355,821.011353 
	C89.041214,822.041504 88.867233,823.309998 88.868782,824.469360 
	C88.891762,841.711426 88.924271,858.953552 88.871735,876.195435 
	C88.866974,877.758118 88.293045,879.319092 87.705345,880.585693 
	C87.298691,873.863464 87.316093,867.427856 86.944511,861.014832 
	C86.844841,859.294800 85.559708,857.643555 84.827972,855.736694 
	C84.881142,854.847168 84.925117,854.181702 85.150452,853.221619 
	C85.682266,851.573853 86.032722,850.220642 86.354622,848.543335 
	C86.372475,847.241272 86.418900,846.263489 86.465317,845.285645 
	C86.182495,845.216431 85.899673,845.147217 85.616852,845.077942 
	C85.241325,845.730225 84.865807,846.382507 84.231796,847.036377 
	C83.629036,847.015869 83.284752,846.993835 82.940468,846.971802 
M86.797951,836.915527 
	C86.541389,834.685852 86.284821,832.456116 86.028259,830.226440 
	C85.529808,830.321045 85.031357,830.415649 84.532906,830.510315 
	C84.104156,833.081543 82.755028,835.839661 86.797951,836.915527 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M755.000244,264.307556 
	C754.157410,269.653961 755.864929,271.319031 761.131775,271.200317 
	C777.773987,270.825195 794.429932,271.062012 811.080566,271.062103 
	C812.802307,271.062103 814.524048,271.062103 816.245789,271.062103 
	C816.281372,271.579865 816.316956,272.097626 816.352478,272.615417 
	C814.713562,272.723572 813.074768,272.923615 811.435730,272.925812 
	C794.285583,272.948730 777.134216,273.049805 759.986206,272.856842 
	C756.067749,272.812775 754.648438,273.889404 754.927673,277.906036 
	C755.463989,285.622375 755.664734,293.364410 755.877563,301.099274 
	C755.937256,303.267395 755.552795,305.447754 755.372009,307.622467 
	C754.723022,307.682495 754.074036,307.742523 753.425049,307.802551 
	C752.972412,305.576691 752.175476,303.357971 752.129150,301.123688 
	C751.964722,293.188507 751.889954,285.243073 752.125000,277.311707 
	C752.233459,273.652496 750.788086,272.806030 747.444824,272.879700 
	C739.123535,273.063141 730.795532,272.951111 722.470215,272.929657 
	C720.893738,272.925598 719.317627,272.792145 717.647461,271.614227 
	C718.771912,271.430450 719.895630,271.093323 721.020996,271.087341 
	C729.512573,271.042236 738.013306,270.832581 746.492676,271.157501 
	C750.823181,271.323425 752.753052,270.161346 752.092834,265.634277 
	C751.893616,264.267792 752.280884,262.815857 752.399536,261.403046 
	C752.838684,261.289185 753.277893,261.175323 753.717041,261.061493 
	C754.144165,261.996643 754.571350,262.931824 755.000244,264.307556 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1938.996338,901.991821 
	C1938.989868,903.769958 1938.983276,905.548035 1938.988770,907.674072 
	C1938.731934,908.213196 1938.462769,908.572266 1938.194336,908.571655 
	C1923.411255,908.539612 1908.628174,908.477478 1893.454102,907.852173 
	C1893.022095,905.598328 1892.981079,903.910645 1893.410400,902.155518 
	C1908.919434,902.056091 1923.957886,902.023987 1938.996338,901.991821 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M107.925888,681.037842 
	C108.609535,682.104553 109.854897,683.534729 109.888405,684.992737 
	C110.117249,694.949829 110.042046,704.913696 110.223183,714.872498 
	C110.241592,715.884705 111.308228,716.877930 111.888443,717.879944 
	C112.470116,717.108276 113.051796,716.336548 114.048843,715.237915 
	C115.064766,715.367371 115.665314,715.823853 116.215683,716.661987 
	C115.751198,718.037659 115.498360,719.776855 114.896812,719.907166 
	C110.417442,720.878052 110.661438,724.093201 110.847099,727.469971 
	C110.982483,729.932129 110.817398,732.409912 110.922546,734.875000 
	C110.976448,736.138794 111.199188,738.443115 111.566162,738.488403 
	C116.867439,739.141907 116.486679,743.756897 117.851044,747.465881 
	C116.974663,748.568420 116.115692,749.328613 115.256721,750.088806 
	C115.478790,750.642761 115.700859,751.196655 115.922920,751.750610 
	C118.953339,750.831421 121.983749,749.912231 125.024170,749.368408 
	C126.028336,751.836853 127.022491,753.930054 127.926559,756.332275 
	C127.558327,757.428406 127.280190,758.215393 126.611130,758.986084 
	C125.698326,758.981873 125.176437,758.993958 124.402626,758.722900 
	C124.441597,753.976562 122.334549,752.258545 117.994820,752.989685 
	C117.039398,753.150696 116.043739,753.072754 114.817757,753.055237 
	C114.400307,752.997314 114.231712,752.989014 114.001175,752.529724 
	C113.928131,749.659729 114.145264,747.206482 113.790810,744.838806 
	C113.653801,743.923584 112.220856,743.202271 111.087601,742.372437 
	C110.164879,741.188721 109.532867,740.025269 108.912338,738.406189 
	C108.923805,732.721924 108.923805,727.493286 108.923805,722.397339 
	C102.423302,722.397339 96.886475,722.397339 91.137779,722.397339 
	C91.616776,729.165710 95.049095,733.532227 100.016594,737.328247 
	C99.985207,737.965820 99.969917,738.220703 99.954636,738.475586 
	C93.364319,735.710083 89.949310,730.617004 89.059021,723.654663 
	C88.658867,720.525330 89.816063,718.950134 93.157478,719.023438 
	C98.114395,719.132324 103.075470,719.051636 108.071777,719.051636 
	C108.071777,709.989258 108.071777,701.431580 108.323975,692.197998 
	C108.379074,690.346863 108.181969,689.171570 107.984863,687.996338 
	C107.965538,685.802307 107.946205,683.608215 107.925888,681.037842 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2000.625122,563.150513 
	C2002.352783,563.835510 2003.807251,564.203125 2004.809448,565.084717 
	C2005.780884,565.939270 2006.287720,567.322083 2006.512695,568.763000 
	C1990.685425,569.370667 1975.343872,569.688354 1960.006104,569.616821 
	C1960.338745,567.515503 1960.667480,565.803345 1960.996338,564.091187 
	C1962.391235,563.757751 1963.783936,563.145447 1965.181274,563.134888 
	C1976.838379,563.046570 1988.496338,563.068970 2000.625122,563.150513 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M387.633301,97.254028 
	C393.633667,102.245262 396.648346,108.171524 395.948486,115.765350 
	C395.192413,123.969070 391.309143,130.157303 383.919342,133.830750 
	C378.448639,136.550247 378.360748,136.373444 377.479187,142.463501 
	C377.053528,142.094116 376.406311,141.756210 376.443573,141.531937 
	C377.272308,136.539978 374.151703,135.544281 370.201111,134.912766 
	C359.095764,133.137589 352.183624,123.521767 353.090027,111.392090 
	C353.806976,101.797089 363.308350,93.320541 373.358765,92.907021 
	C378.594482,92.691605 383.168121,94.034950 387.633301,97.254028 
M355.052429,117.947823 
	C358.219360,128.358292 365.332642,133.551315 375.827728,133.114731 
	C385.704895,132.703857 392.138855,126.572052 393.828491,115.959305 
	C394.974091,108.763710 389.263153,99.225121 382.030304,96.447540 
	C366.416290,90.451401 352.544006,103.528244 355.052429,117.947823 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2020.200439,928.312866 
	C2015.723755,923.793518 2011.485840,919.428589 2010.378174,911.574463 
	C2016.974609,911.574463 2023.194824,911.574463 2030.128052,911.290771 
	C2031.872314,911.020203 2032.903687,911.033325 2033.934937,911.046387 
	C2033.934937,915.994995 2033.934937,920.943542 2033.934937,925.892151 
	C2033.645386,925.899597 2033.355835,925.907043 2033.066284,925.914490 
	C2033.066284,922.203003 2033.066284,918.491516 2033.066284,914.780029 
	C2032.380615,914.765808 2031.694946,914.751587 2031.009277,914.737366 
	C2031.009277,917.321350 2031.036377,919.905640 2031.003906,922.489136 
	C2030.922485,928.948914 2030.686279,929.123108 2024.198608,928.172363 
	C2023.581665,928.081909 2022.915527,928.327271 2022.014893,928.707947 
	C2021.283325,928.843079 2020.809204,928.686951 2020.200439,928.312866 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2008.960449,1197.050171 
	C2006.743896,1196.495605 2005.964600,1197.428833 2006.033813,1199.619141 
	C2006.086060,1201.272827 2005.803955,1202.937012 2005.670532,1204.596558 
	C2005.186768,1204.621338 2004.703125,1204.646118 2004.219360,1204.671021 
	C2003.795776,1202.275635 2003.372070,1199.880371 2002.948486,1197.484985 
	C2002.362305,1197.440674 2001.776123,1197.396240 2001.190063,1197.351929 
	C2000.891968,1199.813721 2000.593994,1202.275391 2000.221558,1205.352051 
	C1998.521851,1203.977539 1996.873779,1202.644897 1995.156250,1201.255981 
	C1993.067749,1205.682739 1986.803833,1206.253174 1982.052490,1202.391479 
	C1981.386230,1203.216064 1980.717285,1204.043945 1979.786499,1205.196045 
	C1978.843262,1204.093506 1977.948364,1203.047485 1976.677124,1201.561523 
	C1976.047241,1202.963745 1975.542236,1204.087891 1975.037354,1205.212036 
	C1974.563477,1205.173218 1974.089600,1205.134399 1973.615845,1205.095581 
	C1973.469971,1201.785889 1973.324097,1198.476074 1973.178223,1195.166260 
	C1973.888550,1194.760864 1974.598999,1194.355347 1975.309448,1193.949829 
	C1976.171509,1195.712036 1977.033447,1197.474243 1978.469849,1200.410645 
	C1979.077148,1197.442139 1979.320435,1196.252686 1979.590576,1194.932007 
	C1983.701416,1195.143921 1987.607544,1195.345215 1992.884155,1195.617188 
	C1997.092896,1193.864014 2003.236816,1194.432495 2009.588989,1194.969971 
	C2009.563843,1195.972168 2009.262207,1196.511230 2008.960449,1197.050171 
M1986.540894,1202.737671 
	C1987.524292,1201.459717 1988.507812,1200.181885 1989.491333,1198.904053 
	C1988.441162,1198.340698 1987.390991,1197.777344 1986.340942,1197.213867 
	C1986.201904,1198.917358 1986.062866,1200.620972 1986.540894,1202.737671 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M735.997437,237.971558 
	C725.886292,237.971359 716.274658,237.971359 706.663025,237.971359 
	C706.532593,237.398926 706.402100,236.826508 706.271667,236.254074 
	C707.831177,235.847748 709.387451,235.103912 710.950806,235.088745 
	C722.929260,234.972504 734.913452,234.806870 746.886169,235.081451 
	C751.175293,235.179825 752.281433,233.739029 752.099792,229.696503 
	C751.578491,218.095749 751.369507,206.480820 751.054749,194.871017 
	C751.006409,193.089432 751.047974,191.305389 751.047974,189.522491 
	C751.595642,189.294296 752.143372,189.066086 752.691040,188.837891 
	C753.433105,190.025452 754.804016,191.204346 754.820496,192.401886 
	C754.998108,205.309402 754.977295,218.219879 754.978210,231.129547 
	C754.978638,237.170868 754.193542,237.915543 747.977295,237.956161 
	C744.150635,237.981171 740.323730,237.967865 735.997437,237.971558 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M343.602722,1021.067383 
	C344.073853,1021.497864 344.075348,1021.853333 344.087128,1022.473633 
	C342.942291,1022.840454 341.787933,1023.021790 340.631927,1023.032898 
	C302.390533,1023.401184 264.148956,1023.754028 225.907410,1024.105469 
	C224.019302,1024.122803 222.130905,1024.107544 220.242645,1024.107544 
	C220.240524,1023.376648 220.238403,1022.645752 220.236267,1021.914856 
	C261.201874,1021.607361 302.167511,1021.299866 343.602722,1021.067383 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M156.650757,1044.576904 
	C156.640533,1039.850708 156.640533,1035.571167 156.640533,1031.069336 
	C162.533905,1030.179443 167.990067,1029.880737 171.331207,1035.628906 
	C172.276245,1035.036255 172.634918,1034.929932 172.796234,1034.690430 
	C173.709732,1033.334717 174.575974,1031.946899 175.458282,1030.570068 
	C176.051117,1032.272461 177.011108,1033.943970 177.157684,1035.683960 
	C177.432587,1038.947266 177.236832,1042.250244 177.236832,1045.647827 
	C180.521088,1045.967773 183.333679,1046.241699 186.146271,1046.515747 
	C186.147461,1047.175415 186.148651,1047.834961 186.149857,1048.494507 
	C182.199158,1048.711304 178.248459,1048.928101 173.525391,1049.187378 
	C173.525391,1046.040039 173.810638,1043.537231 173.375092,1041.166626 
	C173.216537,1040.303833 170.979950,1038.919556 170.309647,1039.208130 
	C167.186218,1040.553711 164.279114,1042.401489 160.540649,1044.491089 
	C160.193985,1045.221436 159.289520,1047.127075 158.385040,1049.032715 
	C157.810349,1047.696289 157.235672,1046.359741 156.650757,1044.576904 
M159.912552,1037.493530 
	C159.820953,1040.833008 162.320435,1040.335693 163.973587,1039.710327 
	C165.155853,1039.263184 165.847687,1037.519531 166.758377,1036.354370 
	C165.439575,1035.344849 164.218796,1033.820923 162.760590,1033.543091 
	C162.032990,1033.404419 160.871109,1035.544678 159.912552,1037.493530 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M106.994064,409.996948 
	C106.994064,409.996948 106.526047,409.988373 106.292519,409.983093 
	C106.034134,408.275940 106.009277,406.574066 106.243141,404.446960 
	C106.501854,404.021729 106.943863,404.024811 106.986832,404.395508 
	C107.755135,405.409332 108.480461,406.052429 109.205795,406.695526 
	C109.779900,405.796997 110.805351,404.919861 110.849213,403.996124 
	C111.030083,400.187225 111.144447,396.346436 110.853142,392.550385 
	C110.380386,386.389801 109.570038,380.255066 108.902206,374.109436 
	C108.902206,374.109436 109.004433,374.005280 109.459709,374.030090 
	C111.673965,374.397339 113.431862,375.029633 115.192101,375.036224 
	C128.611572,375.086426 142.031464,375.000702 155.451263,374.985626 
	C164.306030,374.975677 173.160843,375.012665 182.024063,375.313477 
	C182.053604,375.976440 182.074707,376.354523 181.638916,376.854340 
	C158.640045,377.411377 136.098083,377.846741 113.026566,378.292328 
	C113.026566,384.802307 113.129967,391.079926 112.986511,397.351929 
	C112.904785,400.924957 112.420006,404.488770 111.860603,408.139740 
	C111.397568,408.507050 111.190659,408.791351 110.623611,409.080383 
	C109.173660,409.389069 108.083862,409.693024 106.994064,409.996948 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2508.128906,1036.123535 
	C2506.616943,1041.187744 2503.719727,1046.186523 2503.734131,1051.176880 
	C2503.762939,1061.143066 2509.492188,1067.622803 2519.818848,1069.653442 
	C2514.057373,1074.415771 2504.761963,1065.879639 2500.607910,1052.831909 
	C2496.789551,1052.831909 2493.018311,1052.654541 2489.271240,1052.881226 
	C2485.473389,1053.111206 2483.646240,1051.890869 2484.036377,1047.812622 
	C2484.299072,1045.066040 2484.085938,1042.273804 2484.085938,1039.857910 
	C2476.129639,1039.857910 2468.849609,1039.758789 2461.575684,1039.922241 
	C2459.567383,1039.967407 2457.593750,1040.977661 2455.568848,1041.170776 
	C2454.794922,1041.244629 2453.920898,1040.269775 2453.405762,1039.382935 
	C2454.551025,1038.965088 2455.383301,1038.937134 2456.406494,1038.586670 
	C2458.112793,1037.849854 2459.619141,1037.119629 2461.144775,1037.077271 
	C2467.109863,1036.911499 2473.080811,1036.953857 2479.049561,1036.936401 
	C2486.149414,1036.915527 2486.149170,1036.923340 2486.146240,1043.801514 
	C2486.145508,1045.725220 2486.146240,1047.648804 2486.146240,1049.959229 
	C2490.164307,1049.959229 2493.477783,1049.701538 2496.731689,1050.032593 
	C2499.905273,1050.355469 2500.785645,1048.770996 2501.902588,1046.202148 
	C2503.476562,1042.581543 2505.881836,1039.322388 2507.931641,1035.908691 
	C2507.931641,1035.908691 2508.060303,1036.054565 2508.128906,1036.123535 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M226.001465,311.015442 
	C228.082718,311.022552 230.188522,311.022552 232.647675,311.022552 
	C232.647675,316.750305 232.647675,322.095428 232.647675,327.854462 
	C227.806900,328.208618 223.138718,328.548920 218.470749,328.892090 
	C214.210480,329.205261 215.225540,325.718048 214.267944,323.181549 
	C215.635498,320.312164 217.282394,317.711578 219.643982,315.032837 
	C222.251480,313.622589 224.144287,312.290497 226.037109,310.958405 
	C226.037109,310.958405 226.026016,311.008301 226.001465,311.015442 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1203.583740,529.048462 
	C1204.812500,530.906616 1209.713135,532.518372 1203.831299,535.174805 
	C1197.778076,535.802368 1192.223145,535.794617 1186.681519,536.064148 
	C1185.076416,536.142273 1183.509766,537.015747 1181.709595,537.641296 
	C1181.005493,537.848450 1180.517456,537.939026 1179.878906,537.792358 
	C1179.117920,537.728760 1178.507324,537.902283 1177.530518,538.023071 
	C1176.426758,537.959595 1175.689209,537.948792 1174.746094,537.686279 
	C1172.554199,538.702454 1170.567871,539.970337 1168.375732,541.045898 
	C1167.508179,540.958374 1166.846558,541.063049 1166.137329,540.813965 
	C1167.395142,538.308838 1168.700684,536.157471 1170.464600,533.977844 
	C1174.241089,533.826721 1177.559082,533.703918 1180.876953,533.581177 
	C1180.850708,532.777954 1180.824463,531.974670 1180.798218,531.171448 
	C1177.835815,531.171448 1174.854004,530.988281 1171.925049,531.300354 
	C1171.193970,531.378235 1170.633057,533.052551 1169.687012,533.918335 
	C1167.934937,534.240601 1166.489624,534.637573 1164.997559,534.991577 
	C1164.950806,534.948547 1164.860718,534.858948 1164.860718,534.858948 
	C1165.276001,530.344482 1167.682129,528.812073 1172.220825,528.937805 
	C1182.510376,529.222961 1192.813599,529.016357 1203.583740,529.048462 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1737.000977,910.024231 
	C1735.823853,910.186462 1734.646851,910.348694 1733.146118,910.103394 
	C1732.524414,909.828003 1732.226196,909.960144 1731.928101,910.092285 
	C1731.507690,910.105896 1731.087280,910.119446 1730.349487,909.679565 
	C1730.005737,907.533875 1729.979370,905.841614 1730.412354,904.104614 
	C1733.934448,904.113037 1736.997070,904.166138 1740.019775,904.512085 
	C1740.350464,905.526917 1740.721191,906.248901 1741.091797,906.970947 
	C1741.380615,906.025757 1741.669434,905.080627 1742.393555,904.087280 
	C1744.579834,904.075378 1746.330933,904.111572 1748.152100,904.287231 
	C1748.222168,904.426758 1748.621948,904.515869 1748.621948,904.515869 
	C1748.621948,904.515869 1748.906250,904.170166 1749.349731,904.118774 
	C1758.856445,903.699768 1767.919678,903.332214 1776.952148,903.305054 
	C1775.649536,905.657776 1774.377563,907.670105 1773.105469,909.682373 
	C1768.687256,909.767090 1764.269165,909.851807 1759.329956,909.543274 
	C1757.561157,909.377869 1756.313354,909.605774 1755.065552,909.833740 
	C1752.562622,910.036438 1750.059692,910.239136 1747.157227,909.949463 
	C1746.291382,909.223755 1745.825195,908.990417 1745.358887,908.757141 
	C1745.239258,909.172302 1745.119629,909.587463 1745.000000,910.002686 
	C1743.177490,910.174866 1741.355103,910.346985 1739.250977,910.011414 
	C1738.313232,909.677185 1737.657104,909.850708 1737.000977,910.024231 
M1756.121216,906.975281 
	C1756.516602,906.421326 1756.912109,905.867371 1757.307495,905.313477 
	C1757.106812,905.145569 1756.906128,904.977661 1756.705444,904.809814 
	C1755.770874,905.381775 1754.836426,905.953796 1753.482666,906.782349 
	C1754.550415,906.890259 1755.038940,906.939636 1756.121216,906.975281 
M1769.805420,906.154419 
	C1769.190430,906.210632 1768.575439,906.266907 1767.960327,906.323120 
	C1767.994629,906.530762 1768.028809,906.738464 1768.062988,906.946106 
	C1768.756836,906.811951 1769.450562,906.677795 1769.805420,906.154419 
M1746.235596,906.044983 
	C1745.802124,906.163635 1745.368530,906.282288 1744.935059,906.401001 
	C1745.034546,906.618347 1745.134033,906.835754 1745.233521,907.053162 
	C1745.741333,906.743042 1746.249146,906.432922 1746.235596,906.044983 
M1749.399048,906.484619 
	C1749.399048,906.484619 1749.522827,906.410278 1749.399048,906.484619 
M1743.521606,908.411316 
	C1743.521606,908.411316 1743.596313,908.531677 1743.521606,908.411316 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1099.848145,213.002731 
	C1099.851929,217.990982 1100.015747,222.487564 1099.811279,226.967331 
	C1099.659790,230.286942 1100.977051,231.314133 1104.178955,231.204071 
	C1109.887939,231.007812 1115.608765,231.148865 1122.079956,231.148865 
	C1119.104736,234.118179 1079.291504,235.226944 1069.737305,232.421890 
	C1078.533936,232.421890 1087.330444,232.421890 1096.595703,232.421890 
	C1096.595703,216.346207 1096.595703,201.049896 1096.595703,185.707138 
	C1099.663208,187.780823 1099.752808,188.535522 1099.848145,213.002731 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M975.985352,445.985321 
	C979.072876,443.292664 982.160461,440.600037 985.501831,438.224823 
	C985.239563,439.390289 984.723450,440.238342 983.800842,441.754181 
	C987.423462,439.504242 987.423462,439.504242 985.957031,437.946625 
	C985.763611,436.515137 985.570251,435.083649 985.341431,433.390137 
	C990.584045,435.153687 992.928101,430.796265 996.170410,428.455353 
	C1002.268738,424.052429 1008.245422,419.480988 1014.636353,414.989197 
	C1015.000000,414.998779 1014.995056,414.993286 1014.858765,415.284180 
	C1013.628906,416.841278 1012.535217,418.107513 1010.488953,420.476654 
	C1012.622375,419.589539 1013.329468,419.295532 1014.027344,419.354248 
	C1010.101013,423.071045 1006.042908,426.287048 1002.309082,429.843536 
	C999.017273,432.979065 996.087585,436.494812 992.916016,440.181885 
	C990.781677,442.352844 988.899475,444.449738 986.613037,445.916412 
	C985.249207,446.791168 983.250916,446.676880 981.198792,447.089783 
	C979.913818,447.796997 978.967896,448.421661 978.028687,448.723328 
	C978.384338,447.623688 978.733276,446.847015 979.301025,445.967285 
	C979.794373,445.668549 979.979004,445.408905 980.386230,445.042297 
	C981.262390,444.512512 981.825989,444.025696 982.389587,443.538879 
	C982.109314,443.287628 981.829102,443.036407 981.548828,442.785156 
	C981.001404,443.496674 980.453918,444.208191 979.687012,445.022034 
	C979.191284,445.320312 979.008972,445.581818 978.554871,445.915131 
	C977.457214,445.944672 976.725586,445.968048 975.996460,445.995239 
	C975.999084,445.999115 975.985352,445.985352 975.985352,445.985321 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1596.954224,911.942688 
	C1596.645630,912.325806 1596.178711,912.662720 1596.051636,913.098694 
	C1593.340332,922.407288 1585.940674,922.054871 1578.622559,921.270874 
	C1577.111938,921.109070 1575.623657,919.226562 1574.450073,917.858398 
	C1573.283447,916.498108 1572.526367,914.786621 1571.225342,912.652161 
	C1570.644653,909.842346 1570.428223,907.609558 1570.444092,905.120605 
	C1571.441406,904.223694 1572.206543,903.582947 1572.993530,903.338745 
	C1572.799194,904.763611 1572.582886,905.791931 1572.365356,906.826050 
	C1580.219238,906.826050 1587.691772,906.826050 1595.293335,906.826050 
	C1591.496704,896.970764 1591.333252,897.467346 1581.883301,896.747498 
	C1576.372437,896.327698 1575.893311,900.914917 1572.798828,902.595825 
	C1573.252197,900.495483 1573.865723,898.764282 1574.429565,896.710693 
	C1574.770264,894.322571 1575.160645,892.256897 1575.551147,890.191162 
	C1575.976440,890.255859 1576.401855,890.320557 1576.827271,890.385254 
	C1577.069580,891.897583 1577.311890,893.409912 1577.693481,895.791321 
	C1587.512451,892.544861 1593.620483,897.081665 1597.853271,905.711060 
	C1597.595581,908.045410 1597.274902,909.994080 1596.954224,911.942688 
M1573.308594,912.728149 
	C1577.449707,919.915833 1580.543457,921.954529 1587.742798,919.652222 
	C1590.507324,918.768250 1592.184692,914.484802 1594.642578,911.204651 
	C1594.936035,910.639954 1595.229492,910.075195 1595.705200,909.159912 
	C1588.308228,909.159912 1581.195068,909.120178 1574.086304,909.283386 
	C1573.749878,909.291138 1573.454834,911.099854 1573.308594,912.728149 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2373.144775,766.579346 
	C2371.682129,767.802917 2370.314209,768.665466 2368.531006,769.849915 
	C2357.842041,757.625000 2347.556641,745.087708 2337.334717,732.499329 
	C2336.899658,731.963501 2337.045898,730.955688 2337.255371,730.226746 
	C2338.766357,731.331787 2339.880371,732.461182 2341.134766,733.404602 
	C2343.053955,734.848145 2344.693848,735.140686 2345.036621,731.962158 
	C2345.063721,731.929443 2345.122314,731.867981 2345.122314,731.867920 
	C2348.438965,735.597900 2351.755371,739.327881 2355.079590,743.482910 
	C2355.087402,743.907959 2354.931885,744.086426 2354.581055,744.112793 
	C2354.062500,745.190186 2353.370850,746.617737 2353.812012,747.231934 
	C2356.875977,751.498108 2360.159668,755.607666 2363.405518,759.741821 
	C2365.050781,761.837341 2366.764648,763.879028 2368.447510,765.945007 
	C2368.937012,765.550720 2369.426270,765.156433 2369.915771,764.762085 
	C2368.190918,762.295715 2366.461182,759.832764 2364.742188,757.362122 
	C2362.127441,753.603271 2359.520020,749.839172 2356.992188,746.000610 
	C2357.074707,745.923950 2357.238525,745.769226 2357.238525,745.769226 
	C2361.381348,750.634033 2365.595459,755.440857 2369.628662,760.394775 
	C2371.058838,762.151550 2372.048828,764.266785 2373.144775,766.579346 
M2346.089844,738.353333 
	C2346.192383,738.396790 2346.295166,738.440186 2346.397705,738.483704 
	C2346.287842,738.304565 2346.177979,738.125427 2345.854004,737.506531 
	C2345.661865,737.227661 2345.404785,737.035522 2344.941162,736.626831 
	C2344.830322,736.583557 2344.719727,736.540283 2344.608887,736.497009 
	C2344.721924,736.685669 2344.834717,736.874329 2345.171875,737.496277 
	C2345.358154,737.782532 2345.614502,737.975037 2346.089844,738.353333 
M2349.026611,742.384705 
	C2351.790771,741.180847 2351.790771,741.180847 2348.945068,737.466064 
	C2348.741211,739.676880 2348.632568,740.856995 2349.026611,742.384705 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1101.839111,528.728943 
	C1101.378052,529.075745 1100.977661,529.021790 1100.297363,528.604980 
	C1099.189087,526.834961 1098.360718,525.427795 1097.694092,524.295288 
	C1098.505859,522.779846 1099.250854,521.388916 1100.243774,520.161621 
	C1100.911865,521.138855 1101.332031,521.952515 1101.910522,523.072876 
	C1102.367554,521.784912 1102.678345,520.909302 1102.989014,520.033569 
	C1104.716431,519.387024 1106.408447,518.609192 1108.181152,518.130371 
	C1109.998657,517.639587 1111.892456,517.425110 1113.758423,517.123230 
	C1114.484375,517.005737 1115.648560,516.648804 1115.893799,516.962830 
	C1118.475708,520.267639 1122.579956,518.110535 1125.721313,519.741516 
	C1126.873657,520.339722 1129.166138,518.742065 1131.148682,518.075378 
	C1131.509644,518.843994 1131.981567,519.848572 1132.736816,521.375854 
	C1133.018799,522.924316 1133.017212,523.950073 1133.015625,524.975830 
	C1124.848145,524.738159 1116.677612,524.283386 1108.514648,524.392273 
	C1105.999268,524.425842 1102.095825,523.706055 1101.839111,528.728943 
M1114.260620,521.123840 
	C1114.268799,521.560364 1114.277100,521.996887 1114.285278,522.433472 
	C1114.629883,522.047668 1114.974487,521.661804 1115.663818,520.857300 
	C1115.647217,520.442566 1115.630493,520.027832 1115.613892,519.613098 
	C1115.296265,519.980286 1114.978760,520.347534 1114.260620,521.123840 
M1128.638184,521.848389 
	C1129.135376,522.205750 1129.632568,522.563110 1130.129883,522.920471 
	C1130.338257,522.521912 1130.546631,522.123352 1130.755005,521.724792 
	C1130.201416,521.604614 1129.647827,521.484436 1128.638184,521.848389 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2316.248779,701.275452 
	C2316.880615,701.844971 2317.512451,702.414551 2318.104980,703.463684 
	C2317.952637,704.072693 2317.839844,704.202087 2317.416992,704.244751 
	C2312.604492,706.149292 2317.048096,707.682495 2317.725098,708.418213 
	C2321.383545,712.393494 2325.401855,716.040466 2329.335938,719.758423 
	C2330.882568,721.220093 2332.532715,722.572021 2334.135742,723.973938 
	C2334.645996,723.444214 2335.156494,722.914490 2335.666992,722.384766 
	C2332.525391,719.329956 2329.354980,716.304199 2326.260254,713.202515 
	C2325.347412,712.287476 2324.649902,711.157898 2323.975586,710.016724 
	C2324.097656,709.906799 2324.341797,709.687073 2324.341797,709.687012 
	C2325.674805,710.403442 2327.008057,711.119873 2328.770508,712.154053 
	C2329.741943,712.965149 2330.283936,713.458496 2330.826172,713.951782 
	C2333.595947,717.300293 2336.365723,720.648743 2339.147217,724.412964 
	C2339.158936,724.828613 2339.002686,724.990845 2338.749512,725.156189 
	C2338.849609,726.609619 2339.202881,727.897766 2339.397217,728.606628 
	C2338.260010,728.763855 2337.042480,728.932190 2335.485840,729.095398 
	C2331.546143,725.056641 2327.946045,721.022949 2324.565674,717.235596 
	C2318.089600,723.515930 2312.614990,728.825195 2307.076416,734.072144 
	C2307.012695,734.009888 2306.892334,733.886230 2306.897705,733.490234 
	C2307.698730,731.232300 2308.009277,728.813232 2309.384277,727.617065 
	C2312.952393,724.512756 2316.460693,721.495117 2319.414307,717.698975 
	C2321.692383,714.771301 2321.239746,713.797363 2317.912109,712.877075 
	C2317.341064,712.031982 2316.806885,711.504089 2316.081055,710.685486 
	C2313.542725,707.991028 2311.196045,705.587280 2309.202881,703.109009 
	C2312.115479,703.697693 2314.813232,704.888184 2316.248779,701.275452 
M2335.486084,725.633789 
	C2335.486084,725.633789 2335.398682,725.494629 2335.486084,725.633789 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1729.953125,904.149292 
	C1729.979370,905.841614 1730.005737,907.533875 1730.034912,909.652588 
	C1721.626709,910.142273 1713.215942,910.205505 1704.406616,909.960205 
	C1704.013550,909.452026 1704.018799,909.252563 1704.489990,909.027649 
	C1708.935791,908.971497 1712.915649,908.914124 1716.895508,908.915710 
	C1725.689087,908.919067 1725.689087,908.931152 1727.451172,906.793518 
	C1722.099243,904.917725 1700.417725,905.599976 1697.484253,908.315552 
	C1699.313354,908.647339 1700.632446,908.886597 1701.964600,909.397583 
	C1701.982178,909.850647 1701.986816,910.031921 1701.991455,910.213257 
	C1699.068970,910.242493 1696.146606,910.271729 1692.447754,910.215576 
	C1691.064575,909.984802 1690.457764,909.839478 1688.492920,909.368896 
	C1691.237427,909.074280 1692.596924,908.928345 1693.956299,908.782410 
	C1688.079468,908.001770 1682.241943,907.885559 1676.419678,908.131042 
	C1675.575317,908.166687 1674.804199,909.936218 1673.999146,910.903442 
	C1673.249146,910.947205 1672.499268,910.990906 1671.357910,910.638306 
	C1669.645752,909.164612 1668.325073,908.087219 1667.307129,906.972046 
	C1668.014038,906.925659 1668.418213,906.917053 1669.162598,906.918701 
	C1670.007324,906.299866 1670.511963,905.670776 1671.383057,904.990906 
	C1672.487915,904.974670 1673.226196,905.009277 1674.057373,905.444580 
	C1680.763184,906.153687 1687.375244,906.505432 1693.991943,906.658569 
	C1694.373413,906.667480 1694.792358,905.060425 1695.592041,904.107178 
	C1700.157959,903.508911 1704.325684,903.008789 1708.397217,902.520264 
	C1708.945923,899.589478 1709.473145,896.773621 1710.322021,894.141235 
	C1711.055420,896.795105 1711.467285,899.265503 1711.727417,901.865112 
	C1711.719971,902.239624 1711.864380,902.484985 1712.469360,902.809692 
	C1718.604370,903.309143 1724.278687,903.729248 1729.953125,904.149292 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1047.000000,390.000000 
	C1051.522461,385.799042 1055.906616,381.433807 1060.622559,377.462402 
	C1063.213013,375.280823 1066.362305,373.762665 1069.635010,372.295288 
	C1069.999512,373.089600 1069.988770,373.534088 1069.627808,373.971008 
	C1068.485718,374.613922 1067.693726,375.264374 1066.680054,376.023987 
	C1066.169067,376.336426 1066.002319,376.613556 1065.795654,377.014069 
	C1065.763672,377.053040 1065.894165,377.042419 1066.251221,376.937744 
	C1066.764038,376.659149 1066.961792,376.405579 1067.389893,376.046875 
	C1068.478882,375.345795 1069.249023,374.680756 1070.299805,373.926270 
	C1071.076660,373.244049 1071.572876,372.651276 1072.229980,371.983734 
	C1072.239380,371.914398 1072.087646,371.919800 1071.609619,371.941467 
	C1070.848267,371.970734 1070.413208,371.983704 1069.978271,371.996704 
	C1070.348022,366.835602 1076.027466,367.194794 1078.697266,364.321503 
	C1080.292236,364.720642 1081.479980,364.799927 1082.667847,364.879242 
	C1082.630493,364.374542 1082.593262,363.869843 1082.555908,363.365173 
	C1081.363647,363.577515 1080.171509,363.789886 1078.979248,364.002258 
	C1080.774902,362.310944 1082.570557,360.619659 1084.725952,359.199463 
	C1085.729492,360.311829 1086.373047,361.153107 1086.982910,362.342346 
	C1084.454834,364.963776 1081.960693,367.237244 1079.514038,369.467346 
	C1079.514038,371.181213 1080.198853,374.215485 1079.363647,374.716766 
	C1077.877319,375.608734 1075.470825,374.967407 1073.045166,374.967407 
	C1068.072144,378.393585 1062.538086,382.206329 1056.977173,385.701874 
	C1056.253296,385.162994 1055.556274,384.941315 1054.859253,384.719604 
	C1054.816162,385.095917 1054.773193,385.472229 1054.730103,385.848511 
	C1055.493530,385.899750 1056.257080,385.950958 1057.014160,386.345886 
	C1055.341675,387.799164 1053.675537,388.908752 1051.957031,389.691711 
	C1050.265747,389.571533 1048.626709,389.778015 1046.991455,389.989197 
	C1046.995117,389.993927 1047.000000,390.000000 1047.000000,390.000000 
M1076.642578,368.096924 
	C1076.777832,368.078278 1076.912964,368.059601 1077.378784,367.930511 
	C1077.242554,367.941101 1077.106445,367.951721 1076.642578,368.096924 
M1060.586670,381.447418 
	C1061.563232,380.918213 1062.539795,380.389008 1063.516357,379.859802 
	C1063.233398,379.486725 1062.950439,379.113647 1062.667358,378.740570 
	C1061.844360,379.491211 1061.021362,380.241882 1060.586670,381.447418 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M962.957031,220.269257 
	C963.060791,218.418060 963.166687,217.027191 963.272583,215.636337 
	C963.680237,215.628967 964.087830,215.621597 964.495483,215.614227 
	C964.495483,221.024414 964.495483,226.434601 964.495483,231.931702 
	C966.585815,232.614594 968.054565,233.094421 970.818848,233.997482 
	C968.399597,235.220688 966.964722,235.946167 964.953735,236.962936 
	C964.953735,244.580780 964.953735,252.524155 964.953735,260.467529 
	C964.370300,260.471649 963.786926,260.475739 963.203491,260.479858 
	C963.203491,252.528091 963.203491,244.576324 963.203491,235.837158 
	C949.810547,235.837158 937.217346,235.837158 924.624146,235.837158 
	C924.599121,235.335968 924.574158,234.834763 924.549133,234.333572 
	C928.332336,233.906662 932.108154,233.215302 935.900391,233.108932 
	C943.377136,232.899200 950.869629,232.819717 958.342346,233.075073 
	C962.157837,233.205444 963.425781,231.882843 963.033081,228.206131 
	C962.769592,225.739471 962.967651,223.223511 962.957031,220.269257 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M224.005768,1034.417358 
	C223.974579,1038.354980 224.030426,1041.849976 223.879471,1045.336182 
	C223.835526,1046.351196 223.200897,1047.340820 222.837051,1048.342041 
	C221.710663,1047.872681 220.187393,1047.713501 219.532883,1046.875610 
	C217.634064,1044.445068 216.083954,1041.742065 213.562744,1037.862183 
	C213.562744,1041.555786 213.776535,1043.803223 213.465652,1045.975464 
	C213.326263,1046.949585 212.136246,1047.773315 211.422974,1048.665161 
	C210.842773,1047.824097 209.799835,1047.003906 209.760574,1046.138306 
	C209.564865,1041.823120 209.547501,1037.493652 209.675873,1033.175293 
	C209.703613,1032.242554 210.489334,1030.669678 211.105545,1030.576172 
	C211.970901,1030.444946 213.105469,1031.296631 213.932251,1031.965820 
	C214.542694,1032.459717 214.753738,1033.417725 215.304916,1034.020874 
	C216.354446,1035.169189 217.519882,1036.211670 218.637192,1037.298096 
	C219.221207,1035.944946 219.741959,1034.559692 220.409668,1033.249146 
	C220.923431,1032.240723 221.621948,1031.326416 222.238297,1030.370239 
	C222.830200,1031.570679 223.422089,1032.770996 224.005768,1034.417358 
M218.753906,1039.345703 
	C219.047028,1039.776489 219.340149,1040.207397 219.633255,1040.638184 
	C219.562897,1040.040283 219.492523,1039.442505 218.753906,1039.345703 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1098.995117,540.994751 
	C1101.203613,542.210938 1104.383545,542.722473 1103.032471,546.977539 
	C1103.052124,547.420654 1103.047852,547.716309 1102.644775,547.896729 
	C1101.534180,549.704407 1100.826782,551.331604 1100.087891,552.563965 
	C1098.967407,550.602112 1097.878662,549.034973 1096.789795,547.467834 
	C1096.166260,547.718994 1095.542725,547.970154 1094.919189,548.221313 
	C1094.919189,550.116272 1094.871948,552.012573 1094.927368,553.905884 
	C1095.168823,562.155334 1095.393921,570.405884 1095.745483,578.650940 
	C1095.805298,580.054443 1096.452881,581.432861 1096.828857,582.822937 
	C1098.244751,581.871521 1099.660767,580.920166 1101.431885,579.971558 
	C1102.497559,579.970764 1103.207764,579.967163 1103.886719,580.317261 
	C1103.805908,581.141418 1103.756348,581.612000 1103.516968,582.275635 
	C1103.232056,583.003052 1103.137207,583.537415 1102.961670,584.509155 
	C1102.696167,587.577820 1102.511475,590.209229 1102.326782,592.840637 
	C1101.812256,592.845337 1101.297852,592.850037 1100.783325,592.854797 
	C1100.527588,591.025940 1100.567017,589.090637 1099.953369,587.390991 
	C1098.759766,584.084473 1095.314941,585.852173 1094.180298,590.200684 
	C1093.591919,590.037659 1093.254639,589.947266 1092.958252,589.400330 
	C1092.983643,580.222839 1093.107788,571.497437 1092.827271,562.785034 
	C1092.775635,561.181152 1091.084229,559.630005 1090.060547,557.640991 
	C1090.134399,556.113953 1090.297363,555.000549 1090.831543,553.901794 
	C1091.715210,552.185120 1092.311768,550.473267 1092.719727,548.717590 
	C1093.267578,546.358154 1093.670288,543.965027 1094.143555,541.979858 
	C1094.556885,543.565857 1094.961670,544.758179 1095.506714,546.363770 
	C1096.998047,544.068420 1097.996582,542.531555 1098.995117,540.994751 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2066.670410,523.860596 
	C2053.558838,524.602844 2040.751587,522.447998 2027.473877,525.540894 
	C2027.473877,511.203613 2027.473877,496.507202 2027.473877,481.810791 
	C2027.995972,481.808960 2028.517944,481.807098 2029.040039,481.805267 
	C2029.040039,494.926025 2029.040039,508.046753 2029.040039,521.902405 
	C2043.437134,521.902405 2057.472900,521.902405 2071.508789,521.902405 
	C2071.529053,522.422913 2071.549072,522.943420 2071.569092,523.463928 
	C2070.089600,523.595154 2068.609863,523.726440 2066.670410,523.860596 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M84.818779,855.960693 
	C85.559708,857.643555 86.844841,859.294800 86.944511,861.014832 
	C87.316093,867.427856 87.298691,873.863464 87.711334,881.013428 
	C87.999542,883.422791 88.003677,885.109314 87.763153,887.056396 
	C87.616066,890.886047 87.830215,894.456299 87.788315,898.023682 
	C87.682266,907.051758 87.461197,916.078552 86.943420,925.072632 
	C86.146332,925.032593 85.692032,925.025879 85.173660,924.553406 
	C85.070206,914.113892 85.196655,904.135193 84.902061,894.168945 
	C84.798096,890.651917 83.664963,887.165344 83.001709,883.664856 
	C83.001709,883.664856 82.975494,883.441040 83.297890,883.031433 
	C83.752953,880.656189 83.928879,878.692322 84.010468,876.724609 
	C84.297409,869.804016 84.551941,862.882080 84.818779,855.960693 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1892.940063,902.222900 
	C1892.981079,903.910645 1893.022095,905.598328 1893.069702,907.713806 
	C1880.671265,908.211243 1868.266235,908.280884 1855.424316,908.014832 
	C1854.983765,907.444153 1854.980347,907.209290 1855.233521,906.950256 
	C1855.646484,906.889832 1855.824707,906.888367 1855.952637,906.807434 
	C1856.078369,906.727722 1856.246948,906.466187 1856.236816,906.456848 
	C1854.106934,904.480408 1858.164307,903.936218 1857.604004,902.144348 
	C1862.188477,901.571106 1866.378296,901.099670 1870.604370,900.624146 
	C1870.863892,897.687378 1871.097656,895.043762 1871.331421,892.400146 
	C1871.832642,892.351685 1872.333984,892.303223 1872.835327,892.254761 
	C1873.185913,893.506348 1873.957642,894.809082 1873.813110,896.000671 
	C1873.233154,900.778809 1875.708496,901.329956 1879.689819,901.236633 
	C1884.096436,901.133362 1888.522461,901.858521 1892.940063,902.222900 
M1864.742065,903.948914 
	C1863.174072,904.201233 1861.606201,904.453491 1858.972290,904.877258 
	C1863.931519,907.805664 1887.138916,907.519409 1889.960449,903.926270 
	C1881.869629,903.926270 1873.778687,903.926270 1864.742065,903.948914 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2091.039551,1233.945068 
	C2095.883545,1236.508545 2093.038330,1231.503662 2094.177734,1230.727051 
	C2100.021729,1230.487427 2105.688232,1230.187134 2111.358154,1230.090942 
	C2112.275879,1230.075317 2113.209229,1230.966187 2114.135254,1231.437988 
	C2113.486816,1232.396851 2112.868652,1234.158813 2112.184814,1234.184692 
	C2107.529297,1234.361328 2108.485596,1236.979858 2109.898438,1239.805420 
	C2103.235840,1241.946533 2096.895020,1238.483765 2090.481934,1239.569580 
	C2090.565186,1237.380005 2090.802246,1235.662598 2091.039551,1233.945068 
M2095.600098,1234.734985 
	C2095.569824,1235.271729 2095.539551,1235.808472 2095.509033,1236.345337 
	C2095.906738,1236.294189 2096.608154,1236.321777 2096.645508,1236.177246 
	C2096.764648,1235.717529 2096.636719,1235.193848 2095.600098,1234.734985 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1653.969971,531.917358 
	C1650.945435,531.448425 1647.920898,530.979553 1644.896362,530.510681 
	C1644.679688,530.143066 1644.463013,529.775452 1644.246338,529.407898 
	C1645.711182,528.612671 1647.147583,527.194153 1648.645630,527.125793 
	C1656.905518,526.748901 1665.180542,526.701538 1673.734375,526.227539 
	C1674.575195,525.784241 1675.131958,525.654724 1676.122803,526.036194 
	C1681.319824,526.784241 1686.082764,527.021179 1690.845581,527.258118 
	C1690.840210,527.525513 1690.834717,527.792908 1690.829224,528.060303 
	C1684.912231,528.060303 1678.995239,528.060303 1673.078247,528.060303 
	C1678.301880,528.997253 1683.526001,529.128296 1688.738525,528.938782 
	C1691.136108,528.851624 1691.648926,529.699951 1690.596191,531.849609 
	C1678.075562,531.929382 1666.022705,531.923340 1653.969971,531.917358 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1773.515991,909.754150 
	C1774.377563,907.670105 1775.649536,905.657776 1777.240967,903.009766 
	C1778.211060,902.270813 1778.858887,902.102051 1779.512573,902.074036 
	C1787.802246,901.718445 1787.791992,901.720093 1790.431763,894.028320 
	C1790.546753,893.692871 1791.184204,893.536743 1792.277954,892.871948 
	C1792.577148,894.574585 1792.572266,895.989990 1793.096069,897.170837 
	C1793.790894,898.737061 1794.745850,901.245117 1795.854614,901.395752 
	C1802.187500,902.256470 1808.599121,902.537537 1815.053833,903.423096 
	C1816.273926,904.218018 1817.414185,904.901001 1818.576172,904.940063 
	C1823.034668,905.090088 1827.500000,905.033508 1831.848755,905.284180 
	C1831.455322,905.705688 1831.176025,905.897034 1830.442993,906.066162 
	C1825.670410,906.039612 1821.351685,906.035400 1816.623169,906.020630 
	C1814.015259,905.923096 1811.699707,905.674988 1812.024048,909.056763 
	C1807.984009,909.077209 1803.943970,909.097717 1799.431152,908.782959 
	C1798.942139,908.245178 1798.926025,908.042664 1799.250244,907.616455 
	C1802.717773,907.139099 1805.845093,906.885315 1808.972412,906.631592 
	C1809.254639,906.020508 1809.536865,905.409363 1809.819214,904.798218 
	C1809.145264,904.630920 1808.471436,904.318237 1807.797607,904.318237 
	C1798.979248,904.318176 1790.160156,904.325867 1781.343506,904.464050 
	C1780.633911,904.475159 1779.939697,905.460815 1779.238281,905.993652 
	C1780.012817,906.596130 1780.770752,907.694824 1781.564575,907.721436 
	C1787.054565,907.905518 1792.552124,907.866394 1798.034668,908.184814 
	C1798.020874,908.661377 1798.019409,908.852112 1798.018066,909.042908 
	C1789.987549,909.303894 1781.957031,909.564941 1773.515991,909.754150 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2225.153564,641.127319 
	C2223.894775,641.295471 2222.359619,642.014648 2221.420898,641.544250 
	C2214.280518,637.966553 2207.256592,634.156250 2200.034424,629.742554 
	C2198.305664,627.804932 2196.918457,626.128723 2195.139404,625.356873 
	C2189.089600,622.732300 2182.901123,620.426819 2176.765625,617.999512 
	C2178.418701,617.830139 2180.373291,616.998840 2181.678467,617.593201 
	C2196.032959,624.129395 2210.286377,630.887573 2224.775635,638.224487 
	C2225.008057,639.595886 2225.031982,640.328491 2225.086182,641.068848 
	C2225.116455,641.076721 2225.153564,641.127319 2225.153564,641.127319 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M246.439331,1042.919312 
	C245.830948,1044.718750 245.222549,1046.518188 244.614136,1048.317627 
	C244.051071,1046.487915 243.173767,1044.687866 242.997177,1042.821533 
	C242.718231,1039.873657 242.755890,1036.870117 242.973740,1033.913330 
	C243.066742,1032.650635 243.950836,1031.446167 244.475449,1030.215332 
	C245.453659,1030.966919 246.619507,1031.569336 247.364380,1032.506470 
	C248.379715,1033.783813 249.074142,1035.316406 250.245789,1037.372803 
	C251.182495,1038.811035 251.779587,1039.615845 253.058578,1041.339722 
	C252.904968,1039.103760 252.832550,1038.049438 252.760132,1036.995239 
	C252.837067,1035.710938 252.740082,1034.383667 253.043732,1033.155396 
	C253.305481,1032.096558 254.023560,1031.150757 254.539917,1030.154907 
	C255.205093,1031.200317 255.870255,1032.245850 256.550507,1034.095703 
	C256.672638,1038.265991 256.779724,1041.631714 256.886780,1044.997437 
	C256.352692,1046.053345 255.818588,1047.109375 255.284454,1048.165283 
	C254.095337,1047.525024 252.753281,1047.058716 251.764801,1046.190063 
	C250.954285,1045.477783 250.564606,1044.286621 249.630859,1042.661377 
	C248.643768,1041.142578 248.014206,1040.270142 247.384659,1039.397827 
	C247.069534,1039.530273 246.754425,1039.662720 246.439301,1039.795288 
	C246.439301,1040.836548 246.439301,1041.877930 246.439331,1042.919312 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2053.796875,1210.703491 
	C2052.935303,1211.568970 2051.519531,1212.316284 2051.305908,1213.319824 
	C2050.225586,1218.389160 2050.335205,1218.412598 2045.219360,1219.524048 
	C2044.579712,1222.281616 2045.645630,1226.876587 2040.277344,1225.731201 
	C2039.055908,1225.470459 2037.283691,1223.905640 2037.206421,1222.836670 
	C2036.862305,1218.089600 2036.855835,1213.292358 2037.166748,1208.541992 
	C2037.242798,1207.380249 2038.949951,1205.413696 2039.956665,1205.381714 
	C2044.374878,1205.242554 2048.811279,1205.677002 2053.662109,1206.337402 
	C2053.987793,1208.082031 2053.892334,1209.392822 2053.796875,1210.703491 
M2039.153320,1216.410278 
	C2039.414429,1218.860107 2039.675659,1221.309814 2039.936890,1223.759644 
	C2043.507080,1216.432983 2043.507080,1216.432983 2047.311523,1215.632080 
	C2045.961792,1214.820435 2044.063843,1213.679199 2042.166016,1212.538208 
	C2044.496216,1210.884644 2046.826294,1209.231079 2048.880371,1207.773438 
	C2040.381104,1205.538574 2039.144165,1206.384033 2039.103271,1214.982056 
	C2039.102417,1215.147827 2039.112427,1215.313599 2039.153320,1216.410278 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1124.043701,577.979675 
	C1126.348145,578.230713 1128.652588,578.481750 1131.653687,578.945618 
	C1135.512695,583.194580 1136.207764,583.371826 1138.441406,580.716553 
	C1139.817627,579.877502 1141.104736,579.114258 1141.863281,577.993225 
	C1148.573608,568.076233 1155.190186,558.095825 1162.257812,548.114807 
	C1163.232056,548.207642 1163.782959,548.319763 1164.238037,548.753540 
	C1158.033813,558.309753 1151.802856,567.466736 1145.885620,576.822205 
	C1143.903320,579.956299 1142.721558,583.596680 1140.896851,586.993408 
	C1140.435181,586.972412 1140.250610,586.963623 1140.018311,586.677002 
	C1138.991821,586.252136 1138.013184,586.105042 1136.686157,585.780884 
	C1131.901611,585.042114 1127.465576,584.480408 1123.004272,583.650879 
	C1122.714722,582.942017 1122.450439,582.501099 1122.168945,581.715942 
	C1122.332520,580.245850 1122.513062,579.119934 1123.031250,577.995972 
	C1123.593628,577.991882 1123.818604,577.985779 1124.043701,577.979675 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M320.153839,1039.548828 
	C319.264862,1037.172607 317.030487,1033.366089 317.744720,1032.694214 
	C319.716705,1030.839233 322.902527,1029.515625 325.643280,1029.450439 
	C328.398865,1029.384888 331.601715,1030.735229 329.915833,1035.047119 
	C328.215363,1034.381104 327.306458,1033.308594 326.166321,1032.927246 
	C324.887268,1032.499390 323.372375,1032.517700 322.016754,1032.720581 
	C321.553070,1032.789917 320.794373,1034.430664 320.969666,1034.622314 
	C321.787323,1035.516113 322.796631,1036.366089 323.905670,1036.825684 
	C325.091949,1037.317261 326.466553,1037.354370 327.758942,1037.589844 
	C329.074402,1039.632446 330.389862,1041.675049 331.705322,1043.717651 
	C329.144745,1045.192993 326.668701,1047.608643 323.997070,1047.848877 
	C321.823914,1048.044312 319.416290,1045.632935 317.112610,1044.377686 
	C317.838135,1043.589233 318.563629,1042.800659 319.732849,1042.430176 
	C320.557068,1043.370117 320.861603,1044.249146 321.331818,1044.349121 
	C322.954437,1044.694336 324.649567,1044.955078 326.284241,1044.827515 
	C326.761749,1044.790283 327.592255,1042.735962 327.476990,1042.649414 
	C326.242950,1041.722046 324.904663,1040.880493 323.485229,1040.270874 
	C322.460419,1039.830811 321.270264,1039.775635 320.153839,1039.548828 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2433.824707,885.728638 
	C2432.481934,886.700562 2431.152344,887.270325 2429.056152,888.168701 
	C2426.625244,879.551880 2424.297852,871.301941 2422.244141,862.392212 
	C2422.136230,859.726624 2422.009277,857.628723 2421.325195,855.731873 
	C2419.213135,849.876953 2416.888428,844.098755 2414.781250,838.175171 
	C2414.916504,838.059570 2415.183594,837.825073 2415.183594,837.825073 
	C2421.401855,853.658875 2427.620117,869.492676 2433.824707,885.728638 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M304.193756,1045.086426 
	C307.262787,1045.188721 309.887909,1045.188721 312.513062,1045.188721 
	C312.518463,1045.849243 312.523865,1046.509644 312.529266,1047.170166 
	C308.242554,1047.170166 303.955872,1047.170166 299.221130,1047.170166 
	C299.221130,1041.826050 299.221130,1036.148560 299.221130,1030.288818 
	C303.526428,1030.288818 307.868530,1030.288818 312.210602,1030.288818 
	C312.246979,1030.796875 312.283356,1031.304932 312.319733,1031.812988 
	C310.214539,1031.998901 308.020752,1031.885132 306.034302,1032.472534 
	C304.632904,1032.886963 303.512817,1034.252808 302.267120,1035.193848 
	C302.554199,1035.687622 302.841278,1036.181396 303.128357,1036.675171 
	C305.912109,1036.884399 308.695831,1037.093628 311.479553,1037.302856 
	C311.523071,1037.930786 311.566589,1038.558594 311.610107,1039.186523 
	C308.837738,1039.578613 306.065369,1039.970703 302.681519,1040.449341 
	C303.057098,1042.043457 303.403503,1043.513794 304.193756,1045.086426 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M971.088013,1067.446045 
	C969.842224,1048.123901 970.092651,1029.240234 970.260681,1010.356140 
	C970.884766,1010.298157 971.508911,1010.240173 972.132996,1010.182129 
	C972.396545,1011.284729 972.869995,1012.383118 972.892334,1013.490479 
	C973.244019,1030.913574 973.566528,1048.337280 973.784607,1065.762329 
	C973.800537,1067.039185 972.964783,1068.326904 972.525024,1069.609619 
	C972.053345,1069.031616 971.581604,1068.453613 971.088013,1067.446045 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M260.705994,1034.258301 
	C263.482544,1030.694092 267.213593,1028.193848 271.343048,1030.653076 
	C273.903229,1032.177856 276.006836,1035.659180 276.796021,1038.661621 
	C277.750610,1042.293091 276.092163,1045.876221 272.289307,1047.690063 
	C267.973541,1049.748657 264.289062,1048.695435 260.959534,1044.532471 
	C260.688263,1040.718994 260.697113,1037.488647 260.705994,1034.258301 
M265.361115,1044.971313 
	C267.338806,1044.957275 270.499481,1045.680786 271.059174,1044.782837 
	C272.418243,1042.602661 273.400238,1039.504150 272.924225,1037.093506 
	C272.583710,1035.369141 269.744141,1033.288086 267.810272,1033.047119 
	C266.648682,1032.902710 264.348572,1035.810059 263.901245,1037.668701 
	C263.416687,1039.682129 264.490936,1042.070557 265.361115,1044.971313 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M193.652710,1047.401855 
	C192.488190,1048.226318 191.514786,1048.678345 189.269485,1049.721191 
	C191.650848,1042.984741 193.455948,1037.713257 195.421204,1032.502075 
	C195.707809,1031.742065 196.833954,1030.712402 197.439087,1030.793823 
	C198.385117,1030.920898 199.692642,1031.702881 200.021210,1032.533936 
	C202.065598,1037.704590 203.879868,1042.966187 206.176483,1049.321167 
	C201.509140,1048.149292 198.292542,1042.120850 193.652710,1047.401855 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1523.345581,916.463867 
	C1524.834717,915.976807 1526.325684,915.795410 1527.927490,915.600586 
	C1526.337158,913.742432 1525.188721,912.400696 1524.424316,910.545715 
	C1527.830322,909.953308 1530.852173,909.874084 1534.321533,909.849731 
	C1537.627563,910.442017 1541.452271,908.449829 1542.851318,912.968750 
	C1542.880005,913.061279 1544.030029,912.806580 1544.786865,912.824219 
	C1544.916870,912.934631 1545.165039,913.168457 1545.089966,913.526306 
	C1545.957642,914.499451 1546.890259,915.130981 1547.846558,915.724487 
	C1548.508789,916.135498 1549.203369,916.494080 1549.883545,916.876038 
	C1546.390991,917.190796 1542.904053,917.625183 1539.404541,917.791016 
	C1534.699585,918.013916 1529.984497,918.082764 1525.274170,918.071411 
	C1524.630859,918.069885 1523.989624,917.224304 1523.345581,916.463867 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2316.110352,701.013123 
	C2314.813232,704.888184 2312.115479,703.697693 2309.217285,703.070312 
	C2308.878418,703.106140 2308.935303,703.045349 2308.930908,702.696472 
	C2304.270508,698.586914 2299.614502,694.826233 2294.923828,691.086792 
	C2294.888916,691.107971 2294.811035,691.131287 2294.811035,691.131287 
	C2294.545898,689.742310 2294.280762,688.353394 2294.009766,686.230164 
	C2292.659424,683.325684 2291.314697,681.155518 2289.970215,678.985352 
	C2294.157715,682.125732 2298.495850,685.087341 2302.493408,688.453308 
	C2307.142090,692.367371 2311.492432,696.635986 2316.110352,701.013123 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2175.009766,620.949097 
	C2175.005615,621.406250 2175.001465,621.863525 2174.652344,622.618408 
	C2172.597168,622.090393 2170.886963,621.264648 2169.177002,620.438965 
	C2168.158691,619.617615 2167.263916,618.449707 2166.100830,618.033813 
	C2160.519531,616.038391 2154.865479,614.246399 2148.709717,612.207336 
	C2149.490479,610.794861 2150.091553,609.707886 2150.851318,608.304321 
	C2159.140137,610.488831 2167.275879,612.970703 2175.382080,615.546814 
	C2175.797607,615.678894 2175.956299,616.619690 2176.034180,617.831909 
	C2175.559082,619.302979 2175.284424,620.126038 2175.009766,620.949097 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2253.032227,1040.514648 
	C2253.335693,1039.856079 2253.784424,1039.557129 2254.947754,1039.129272 
	C2258.726562,1038.963745 2261.790771,1038.927246 2264.854736,1038.890625 
	C2265.553711,1038.707397 2266.561035,1038.743286 2266.882324,1038.291504 
	C2267.412598,1037.546143 2267.499756,1036.485474 2268.176758,1034.955566 
	C2270.092529,1034.400146 2271.605225,1034.446899 2273.259766,1034.833130 
	C2278.324463,1035.543091 2283.247314,1035.913452 2288.170410,1036.283813 
	C2288.171143,1036.542358 2288.171875,1036.800781 2288.172607,1037.059326 
	C2284.783691,1037.059326 2281.395020,1037.059326 2278.006104,1037.059326 
	C2280.641113,1037.940918 2283.304932,1037.677734 2285.872559,1038.060791 
	C2287.018799,1038.231812 2287.971680,1039.701416 2289.012451,1040.581543 
	C2285.859375,1040.899658 2282.710449,1041.427734 2279.552002,1041.495239 
	C2271.161865,1041.674683 2262.767334,1041.667114 2254.374512,1041.679199 
	C2253.975830,1041.679810 2253.576416,1041.154663 2253.032227,1040.514648 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1960.574097,564.040894 
	C1960.667480,565.803345 1960.338745,567.515503 1959.707275,569.877869 
	C1956.780273,570.759277 1953.670654,570.221497 1951.689941,571.471924 
	C1950.182861,572.423462 1950.109985,575.646545 1949.398926,577.858948 
	C1949.051270,577.815308 1948.703613,577.771606 1948.355957,577.727966 
	C1948.044922,575.583069 1947.733887,573.438171 1947.380859,571.003662 
	C1945.117676,571.003662 1943.137329,571.139404 1941.182495,570.973511 
	C1937.798950,570.686279 1934.431396,570.212097 1931.057129,569.816345 
	C1930.678955,568.867981 1930.527710,567.698303 1929.869263,567.023193 
	C1928.887695,566.016785 1927.548340,565.359314 1926.186157,564.335083 
	C1934.759399,564.116882 1943.507690,564.116882 1952.255981,564.116882 
	C1952.270386,564.358887 1952.284912,564.600830 1952.299438,564.842834 
	C1947.471680,565.159302 1942.643799,565.475769 1937.139648,565.541260 
	C1935.336670,565.597473 1934.210083,565.904602 1933.083496,566.211670 
	C1933.874390,566.841797 1934.655762,568.004578 1935.457520,568.018738 
	C1942.613403,568.145081 1949.773438,568.095337 1956.931030,568.007935 
	C1957.600708,567.999756 1958.262817,567.376099 1960.093018,566.447083 
	C1957.604614,565.607361 1956.209961,565.136780 1954.815308,564.666138 
	C1954.805786,564.440979 1954.796143,564.215820 1954.786499,563.990662 
	C1956.574951,563.990662 1958.363403,563.990662 1960.574097,564.040894 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2279.420166,561.957275 
	C2277.886475,562.831299 2276.365967,563.742737 2274.836914,563.757385 
	C2262.363770,563.877625 2249.888672,563.850037 2237.414551,563.800293 
	C2236.297607,563.795837 2235.182617,563.317749 2234.111572,561.884155 
	C2235.116455,561.620667 2236.115234,561.153809 2237.126953,561.124573 
	C2249.405273,560.769226 2261.684814,560.415405 2273.966553,560.239685 
	C2275.780762,560.213745 2277.608154,561.109680 2279.420166,561.957275 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1638.011475,911.027527 
	C1625.991577,911.254272 1613.971802,911.481079 1601.592163,911.366333 
	C1603.901367,909.998230 1604.907593,907.879822 1605.434814,905.015015 
	C1612.652344,904.363892 1619.493286,903.897095 1626.142578,903.443420 
	C1626.142578,895.030701 1626.142578,886.957947 1626.432861,878.850037 
	C1627.111572,878.846008 1627.500122,878.877075 1627.909424,879.383789 
	C1627.930176,888.419983 1627.930176,896.980469 1627.930176,905.707458 
	C1620.971069,906.262207 1614.470581,906.780334 1607.970093,907.298523 
	C1607.995972,908.117126 1608.021851,908.935791 1608.047729,909.754395 
	C1618.047729,909.809814 1628.047852,909.865234 1638.033081,910.197205 
	C1638.015991,910.658325 1638.013794,910.842896 1638.011475,911.027527 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M216.442810,1024.032959 
	C187.762115,1024.348389 159.522705,1024.599121 131.283035,1024.816650 
	C130.901535,1024.819580 130.516785,1024.401611 129.330353,1023.716003 
	C131.060638,1023.387878 132.037186,1023.047668 133.016449,1023.039673 
	C159.621536,1022.823242 186.226837,1022.632812 212.832336,1022.474426 
	C214.286240,1022.465759 215.743042,1022.764160 217.194977,1022.947449 
	C217.311890,1022.962158 217.512939,1023.273926 217.485321,1023.312927 
	C217.315247,1023.552063 217.090546,1023.752380 216.442810,1024.032959 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2053.729492,1211.251465 
	C2053.892334,1209.392822 2053.987793,1208.082031 2054.067871,1206.446533 
	C2054.754150,1206.195557 2055.455322,1206.269409 2056.062500,1206.975098 
	C2055.827881,1211.383423 2055.457520,1215.171875 2055.651611,1218.931274 
	C2055.739258,1220.623657 2056.796875,1223.574463 2057.683838,1223.679443 
	C2060.737305,1224.041016 2063.909424,1223.398926 2067.036133,1223.139160 
	C2066.998779,1222.540527 2066.961182,1221.942017 2066.923584,1221.343384 
	C2064.448730,1221.070557 2061.973877,1220.797607 2058.998779,1220.469604 
	C2058.998779,1216.907471 2059.216553,1213.596680 2058.883789,1210.342163 
	C2058.762695,1209.160034 2057.327148,1208.112427 2056.491211,1207.003418 
	C2059.537842,1204.421143 2060.616455,1206.449707 2060.856201,1209.124756 
	C2061.132812,1212.208740 2060.920654,1215.336548 2060.920654,1218.796387 
	C2063.950928,1218.796387 2066.331055,1218.796387 2068.489746,1218.796387 
	C2069.153564,1215.863647 2069.763672,1213.169678 2070.655273,1210.219604 
	C2071.141602,1209.975220 2071.346191,1209.986938 2071.771729,1210.394775 
	C2072.094727,1214.191772 2072.196533,1217.592529 2071.979736,1220.988525 
	C2071.448730,1220.984253 2071.236328,1220.984741 2071.023926,1220.985229 
	C2067.068359,1229.249268 2059.882568,1224.066040 2053.662109,1225.851929 
	C2053.662109,1220.628540 2053.662109,1216.213989 2053.729492,1211.251465 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1550.287354,916.884521 
	C1549.203369,916.494080 1548.508789,916.135498 1547.846558,915.724487 
	C1546.890259,915.130981 1545.957642,914.499451 1545.405518,913.416382 
	C1546.815796,912.336487 1547.835571,911.724487 1548.855347,911.112427 
	C1548.627686,910.737732 1548.400146,910.363037 1548.172607,909.988342 
	C1547.000732,910.896851 1545.828857,911.805359 1544.656982,912.713867 
	C1544.030029,912.806580 1542.880005,913.061279 1542.851318,912.968750 
	C1541.452271,908.449829 1537.627563,910.442017 1534.374146,909.547241 
	C1533.935181,908.578674 1533.890991,907.967224 1534.169189,907.239380 
	C1535.607178,906.378113 1536.722900,905.633179 1538.107178,905.219849 
	C1541.587646,905.642334 1544.799683,905.733215 1548.464111,905.890991 
	C1551.688721,906.085083 1554.461182,906.212219 1557.250854,906.725647 
	C1555.417969,908.467102 1553.567749,909.822266 1551.337769,911.455627 
	C1551.477295,911.751587 1551.982788,912.823364 1552.484619,913.887329 
	C1554.829956,912.856934 1557.546631,908.955872 1558.083984,914.931274 
	C1555.619629,915.585144 1553.155396,916.239014 1550.287354,916.884521 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M968.935791,938.574768 
	C969.681824,938.096497 970.480103,938.073547 971.678040,938.061768 
	C972.077637,953.281006 972.077637,968.489075 972.077637,983.697205 
	C971.636780,983.712708 971.195923,983.728271 970.755066,983.743835 
	C968.296326,968.952332 969.261719,953.953735 968.935791,938.574768 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2096.068848,562.079956 
	C2108.958496,562.075562 2121.358643,562.075562 2133.759033,562.075562 
	C2133.750977,562.982727 2133.743164,563.889893 2133.735107,564.797119 
	C2118.248291,564.866455 2102.761475,564.959961 2087.273438,564.719788 
	C2087.255371,563.841309 2087.237305,562.962769 2087.219238,562.084290 
	C2090.005859,562.084290 2092.792725,562.084290 2096.068848,562.079956 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M753.159790,407.632141 
	C754.013855,407.093872 755.120605,406.618042 755.497192,406.955963 
	C755.972656,407.382599 755.953247,408.470001 755.974670,409.276947 
	C756.318542,422.219238 756.675842,435.161530 756.902527,448.106049 
	C756.929077,449.621124 756.200562,451.149414 755.822083,452.671600 
	C755.232666,452.601929 754.643188,452.532257 754.053772,452.462585 
	C753.731934,437.660370 753.410034,422.858124 753.159790,407.632141 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2401.958740,562.841675 
	C2393.340820,562.843384 2385.216064,562.843384 2377.091553,562.843384 
	C2377.111328,562.001221 2377.130859,561.159058 2377.150635,560.316895 
	C2392.298828,560.316895 2407.447021,560.316895 2422.595215,560.316895 
	C2422.628906,560.776978 2422.662354,561.237000 2422.696045,561.697083 
	C2415.947998,562.078064 2409.200195,562.459045 2401.958740,562.841675 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M108.900864,738.861816 
	C109.532867,740.025269 110.164879,741.188721 110.967224,742.793335 
	C111.086853,757.491638 111.036140,771.748840 110.637360,786.013550 
	C110.057968,786.040344 109.826660,786.059631 109.595352,786.078918 
	C109.145966,778.535522 108.567474,770.997009 108.287292,763.447327 
	C108.055672,757.206116 108.470184,750.926453 107.931900,744.721558 
	C107.799530,743.195740 105.143974,741.798950 103.504509,740.528442 
	C102.576439,739.809265 101.355843,739.467407 100.110817,738.715576 
	C99.969917,738.220703 99.985207,737.965820 100.359375,737.471680 
	C103.445793,737.775574 106.173325,738.318665 108.900864,738.861816 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1090.571289,488.419983 
	C1090.477905,488.160950 1090.384399,487.901886 1090.598633,487.341064 
	C1092.636597,488.060120 1094.366943,489.080841 1096.469604,490.116089 
	C1099.006714,491.037506 1100.186523,490.731476 1100.096436,487.969421 
	C1099.945801,483.348602 1100.057129,478.719788 1100.009277,474.094849 
	C1099.978027,471.076569 1100.817749,467.563324 1096.261963,466.529297 
	C1096.205811,464.500763 1096.159302,462.801544 1096.452148,461.135193 
	C1097.516113,462.028625 1098.240601,462.889099 1099.161865,463.983490 
	C1099.889893,461.674835 1100.473755,459.823395 1101.057617,457.971954 
	C1101.305908,471.088806 1101.554199,484.205658 1101.567749,497.892334 
	C1101.466675,499.284576 1101.600342,500.107056 1101.734131,500.929504 
	C1101.757935,501.407532 1101.781738,501.885590 1101.541504,503.024475 
	C1101.449951,506.770905 1101.622681,509.856506 1101.795288,512.942078 
	C1101.792725,513.688416 1101.790161,514.434692 1101.455322,515.554810 
	C1100.812256,515.943420 1100.501465,515.946045 1100.125000,515.476807 
	C1099.758911,508.043274 1099.458374,501.069244 1098.842285,494.167603 
	C1097.954956,494.547485 1097.383301,494.854919 1096.436768,495.140869 
	C1094.696533,494.765045 1093.331055,494.410736 1091.982422,493.700500 
	C1091.523315,491.703064 1091.047363,490.061523 1090.571289,488.419983 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1310.156128,265.586639 
	C1312.548706,263.348297 1312.885864,264.880554 1313.009155,266.806305 
	C1313.315552,271.593658 1313.812134,276.377075 1313.900879,281.168213 
	C1314.051758,289.317505 1313.993896,297.471985 1313.913330,305.623199 
	C1313.896973,307.285583 1313.393555,308.943146 1313.114746,310.602936 
	C1312.598999,310.608276 1312.083252,310.613647 1311.567505,310.619019 
	C1311.382812,309.350464 1311.047241,308.083008 1311.037964,306.813171 
	C1310.977417,298.495148 1311.090698,290.174652 1310.936768,281.858887 
	C1310.838623,276.567261 1310.378296,271.282379 1310.156128,265.586639 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2195.996582,1210.280029 
	C2196.954834,1208.780762 2197.916504,1207.542847 2199.644043,1205.318726 
	C2202.672852,1208.939331 2205.584717,1212.420410 2209.279297,1216.836914 
	C2209.792236,1212.535156 2210.183350,1209.256470 2210.574219,1205.977661 
	C2211.135254,1206.017212 2211.696533,1206.056641 2212.257568,1206.096191 
	C2212.257568,1211.166748 2212.257568,1216.237305 2212.257568,1221.806396 
	C2205.982910,1222.683594 2206.539062,1214.653198 2201.400879,1213.070068 
	C2201.115479,1216.091431 2200.839600,1219.009155 2200.563965,1221.926880 
	C2200.048340,1221.986084 2199.532715,1222.045166 2199.017090,1222.104370 
	C2198.632812,1220.804077 2198.136719,1219.524780 2197.888184,1218.199097 
	C2197.452393,1215.872925 2197.168457,1213.518433 2196.425537,1211.119507 
	C2196.020264,1210.889282 2196.010010,1210.715454 2195.996582,1210.280029 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2450.971680,513.000000 
	C2450.970703,521.289124 2450.970703,529.078308 2450.970703,536.867432 
	C2450.235352,536.845276 2449.500000,536.823120 2448.764648,536.801025 
	C2448.764648,521.294250 2448.764648,505.787506 2448.764648,490.280762 
	C2449.500488,490.254059 2450.236572,490.227325 2450.972412,490.200592 
	C2450.972412,497.633728 2450.972412,505.066864 2450.971680,513.000000 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M173.540054,1057.623657 
	C173.927979,1058.036377 173.937195,1058.280151 173.970718,1058.707764 
	C153.310593,1059.025879 132.644272,1059.141846 111.978340,1059.306274 
	C107.349823,1059.343140 102.722664,1059.550171 97.621284,1059.386963 
	C97.074326,1058.780396 97.052391,1058.462036 97.081909,1058.140381 
	C98.129143,1058.099365 99.176384,1058.058472 100.983414,1058.023438 
	C102.495735,1057.981201 103.248260,1057.933228 104.000793,1057.885254 
	C127.048233,1057.742065 150.095673,1057.598755 173.540054,1057.623657 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1796.916870,338.411072 
	C1796.921143,352.807220 1796.921143,366.751343 1796.921143,380.695496 
	C1796.056641,380.691833 1795.192017,380.688171 1794.327515,380.684509 
	C1793.997437,365.610474 1794.166626,350.528564 1794.167725,335.449280 
	C1794.713013,335.328369 1795.258301,335.207489 1795.803467,335.086578 
	C1796.173218,336.044067 1796.542969,337.001556 1796.916870,338.411072 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2163.527344,563.967285 
	C2162.485596,563.504883 2161.881592,563.049988 2161.277832,562.595032 
	C2161.865479,562.128723 2162.430420,561.297302 2163.044678,561.259338 
	C2165.697754,561.094849 2168.364746,561.151672 2171.026855,561.147949 
	C2181.343262,561.133423 2191.659668,561.100891 2201.976074,561.142822 
	C2203.614502,561.149475 2205.251709,561.503235 2206.889404,561.696472 
	C2206.854980,562.455872 2206.820557,563.215332 2206.786133,563.974792 
	C2192.512451,563.974792 2178.239014,563.974792 2163.527344,563.967285 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2456.834961,1118.750732 
	C2456.834961,1120.924683 2456.834961,1122.637451 2456.834961,1124.350220 
	C2456.061279,1124.355469 2455.287354,1124.360596 2454.513672,1124.365845 
	C2453.640137,1105.867676 2454.141357,1087.336792 2454.029297,1068.820557 
	C2454.560059,1068.800537 2455.090820,1068.780396 2455.621582,1068.760376 
	C2456.026123,1085.270142 2456.430420,1101.779785 2456.834961,1118.750732 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M753.038818,356.000092 
	C753.038391,348.400604 753.038391,341.301086 753.038391,334.201569 
	C753.767517,334.228973 754.496582,334.256378 755.225708,334.283783 
	C755.225708,349.445496 755.225708,364.607178 755.225708,379.768890 
	C754.496887,379.788269 753.768005,379.807678 753.039185,379.827087 
	C753.039185,372.051392 753.039185,364.275726 753.038818,356.000092 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2272.833008,666.709229 
	C2270.953369,670.897217 2269.144531,671.071716 2265.628174,667.876465 
	C2261.178223,663.832520 2255.723389,660.894165 2250.444092,657.303650 
	C2250.623047,655.932495 2250.830078,654.344849 2251.020996,652.371582 
	C2258.225342,656.795898 2265.445557,661.605835 2272.833008,666.709229 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M3.770805,1025.433594 
	C3.015566,1025.058594 2.876070,1024.833374 2.888601,1024.344238 
	C23.112549,1024.050049 43.340973,1023.914062 63.569061,1023.738831 
	C67.041840,1023.708740 70.513168,1023.511536 74.430077,1023.696960 
	C74.906212,1024.300293 74.945656,1024.597168 74.993317,1024.893066 
	C51.405598,1025.162720 27.817877,1025.432495 3.770805,1025.433594 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1311.026123,353.003204 
	C1311.021851,347.378967 1311.021851,342.253754 1311.021851,337.128571 
	C1311.760010,337.151886 1312.498291,337.175201 1313.236450,337.198517 
	C1313.236450,352.233185 1313.236450,367.267883 1313.236450,382.302551 
	C1312.914185,382.438690 1312.591797,382.574799 1312.269531,382.710938 
	C1311.859253,381.141266 1311.117676,379.576599 1311.093994,378.001129 
	C1310.971802,369.836334 1311.033203,361.668823 1311.026123,353.003204 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1310.136719,219.999298 
	C1310.138794,210.552200 1310.138794,201.604843 1310.138794,192.657486 
	C1310.558228,192.529541 1310.977661,192.401581 1311.397217,192.273621 
	C1311.883179,193.372635 1312.782471,194.467987 1312.792236,195.571182 
	C1312.909546,208.879807 1312.914062,222.189667 1312.871094,235.498962 
	C1312.868408,236.319595 1312.223022,237.138153 1311.876831,237.957687 
	C1311.296143,237.938293 1310.715454,237.918884 1310.134766,237.899490 
	C1310.134766,232.099335 1310.134766,226.299194 1310.136719,219.999298 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1962.135742,522.125977 
	C1975.155396,522.108276 1987.694336,522.108276 2000.233276,522.108276 
	C2000.355469,522.550964 2000.477783,522.993652 2000.599976,523.436279 
	C1999.631470,523.901184 1998.666138,524.763794 1997.694092,524.771118 
	C1984.085815,524.873779 1970.476440,524.868103 1956.867432,524.844299 
	C1956.312378,524.843323 1955.758179,524.403687 1955.203491,524.168457 
	C1955.183716,523.668640 1955.163940,523.168823 1955.144165,522.669067 
	C1957.314453,522.493896 1959.484863,522.318787 1962.135742,522.125977 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1024.998779,231.999969 
	C1031.111694,232.003098 1036.725098,232.003098 1042.338379,232.003098 
	C1042.468140,232.466751 1042.598022,232.930405 1042.727783,233.394058 
	C1041.637573,233.908264 1040.549927,234.863297 1039.456665,234.869827 
	C1025.473999,234.953293 1011.490479,234.903854 997.507263,234.861588 
	C997.244141,234.860794 996.981873,234.536972 995.460754,233.534485 
	C1005.749207,230.814056 1015.224182,232.582550 1024.998779,231.999969 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2329.000000,562.966187 
	C2320.915283,562.967651 2313.330322,562.967651 2305.745605,562.967651 
	C2305.618408,562.517212 2305.491455,562.066772 2305.364258,561.616272 
	C2306.452637,561.134705 2307.537842,560.242371 2308.629639,560.234131 
	C2321.891113,560.133179 2335.153320,560.144348 2348.415283,560.202026 
	C2349.229736,560.205505 2350.041504,560.843201 2350.854492,561.185852 
	C2350.828125,561.778809 2350.801514,562.371765 2350.775146,562.964722 
	C2343.683594,562.964722 2336.591797,562.964722 2329.000000,562.966187 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M104.162827,289.000244 
	C104.162361,282.033539 104.162361,275.566711 104.162361,269.099884 
	C104.868126,269.124237 105.573898,269.148590 106.279663,269.172943 
	C106.279663,284.234253 106.279663,299.295563 106.279663,314.356873 
	C102.962769,310.788940 104.498825,306.460083 104.233200,302.464020 
	C103.947113,298.160187 104.165184,293.822845 104.162827,289.000244 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2286.449707,522.810181 
	C2271.929443,522.822998 2257.849121,522.822998 2243.768555,522.822998 
	C2243.642334,522.381714 2243.516113,521.940430 2243.389893,521.499084 
	C2244.479492,521.015808 2245.564941,520.122498 2246.659424,520.111694 
	C2259.788574,519.982178 2272.919189,519.954163 2286.048828,520.015320 
	C2286.937012,520.019470 2287.820801,520.952209 2288.706787,521.453003 
	C2288.100830,521.901123 2287.495117,522.349243 2286.449707,522.810181 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1793.106567,215.999512 
	C1793.104004,207.222610 1793.104004,198.945526 1793.104004,190.668457 
	C1793.544312,190.543594 1793.984497,190.418747 1794.424805,190.293900 
	C1794.890137,191.394730 1795.749146,192.491455 1795.760620,193.597000 
	C1795.899170,206.907410 1795.931274,220.219147 1795.912476,233.530411 
	C1795.911255,234.339584 1795.247681,235.147766 1794.892334,235.956390 
	C1794.297974,235.934708 1793.703491,235.913040 1793.109131,235.891357 
	C1793.109131,229.427353 1793.109131,222.963364 1793.106567,215.999512 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2125.998535,523.861206 
	C2116.501709,523.872131 2107.504639,523.872131 2097.644287,523.872131 
	C2100.935303,520.651428 2137.845215,519.836182 2143.826172,523.340942 
	C2141.035400,523.504150 2138.246338,523.732910 2135.452881,523.812927 
	C2132.469971,523.898499 2129.483154,523.843689 2125.998535,523.861206 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1313.976562,527.700439 
	C1313.777588,528.086060 1313.484375,528.084290 1313.339355,528.086792 
	C1312.854248,523.319519 1312.309326,518.553711 1312.217896,513.779175 
	C1312.043701,504.695740 1312.113037,495.607300 1312.119629,486.520935 
	C1312.120972,484.804169 1312.300903,483.087524 1312.398315,481.370789 
	C1312.813721,481.292145 1313.229248,481.213470 1313.644775,481.134827 
	C1314.067261,481.973846 1314.854004,482.811127 1314.857910,483.652161 
	C1314.924438,497.593201 1314.901855,511.534729 1314.853149,525.475952 
	C1314.850952,526.089722 1314.411255,526.701904 1313.976562,527.700439 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M543.938721,234.689087 
	C543.692261,236.409470 543.446045,237.671112 543.199768,238.932755 
	C542.533203,238.870621 541.866638,238.808472 541.200073,238.746323 
	C541.200073,223.580627 541.200073,208.414932 541.200073,193.249252 
	C541.762329,193.217438 542.324585,193.185638 542.886841,193.153824 
	C543.237549,206.846008 543.588257,220.538177 543.938721,234.689087 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M608.188843,237.571167 
	C606.697510,238.234406 605.442749,238.888718 604.183716,238.897049 
	C591.382874,238.981903 578.581421,238.969757 565.780151,238.949356 
	C565.033569,238.948166 564.287476,238.641617 563.275574,237.382690 
	C564.431641,236.934082 565.584656,236.104553 566.744385,236.095108 
	C579.877258,235.988174 593.011292,235.985596 606.144531,236.046173 
	C606.906067,236.049683 607.663940,236.838867 608.188843,237.571167 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M873.000244,234.042572 
	C881.423828,234.043777 889.347473,234.043777 897.714661,234.043777 
	C894.181030,237.443665 856.601379,238.049362 852.587830,234.041367 
	C859.225281,234.041367 865.862793,234.041367 873.000244,234.042572 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M75.280746,1025.196777 
	C74.945656,1024.597168 74.906212,1024.300293 74.850159,1023.783813 
	C86.770348,1023.357666 98.715080,1023.130676 110.660553,1022.954590 
	C114.150055,1022.903137 117.641685,1022.996399 121.585228,1023.385803 
	C122.041046,1024.127686 122.043968,1024.506958 122.046898,1024.886353 
	C106.553986,1025.091064 91.061081,1025.295898 75.280746,1025.196777 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2449.019775,957.990112 
	C2449.421631,957.987183 2449.823730,957.984192 2450.524414,957.962524 
	C2450.974854,958.586853 2451.126221,959.229858 2451.277588,959.872803 
	C2451.541504,959.880005 2451.805176,959.887146 2452.069092,959.894348 
	C2452.069092,947.852722 2452.069092,935.811157 2452.069092,923.769531 
	C2452.461914,923.716370 2452.854736,923.663269 2453.247559,923.610107 
	C2453.469971,924.111328 2453.875244,924.607910 2453.888184,925.114502 
	C2454.230469,938.505066 2454.601318,951.895874 2454.745850,965.289001 
	C2454.762207,966.790344 2453.458740,968.305847 2452.375244,969.575745 
	C2451.422607,968.249695 2450.861816,967.162720 2450.171875,965.684448 
	C2449.701904,962.858765 2449.360840,960.424438 2449.019775,957.990112 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1314.862793,452.376709 
	C1314.428955,453.729584 1313.999268,454.654480 1313.569580,455.579346 
	C1313.224731,455.510345 1312.880005,455.441315 1312.535278,455.372314 
	C1312.535278,440.159271 1312.535278,424.946259 1312.535278,409.733246 
	C1315.914795,423.656616 1314.454590,437.854462 1314.862793,452.376709 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M464.361420,237.006287 
	C465.061859,237.845810 465.336975,238.673492 465.612091,239.501175 
	C464.471252,239.653412 463.330841,239.934311 462.189514,239.938126 
	C449.691833,239.979904 437.194031,239.991364 424.696350,239.964905 
	C423.665588,239.962723 422.635559,239.623474 421.632477,238.946732 
	C435.659271,236.026108 449.840057,237.392517 464.361420,237.006287 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1099.537109,258.903259 
	C1100.036621,273.876587 1100.322144,288.493622 1100.532837,303.111755 
	C1100.539551,303.575714 1099.777222,304.050781 1098.986938,304.970612 
	C1098.547852,303.853912 1098.069092,303.188324 1098.051880,302.510986 
	C1097.710938,289.082184 1097.364990,275.652649 1097.223389,262.221130 
	C1097.210449,261.003906 1098.582642,259.772156 1099.537109,258.903259 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1794.001709,271.081238 
	C1794.000000,267.971191 1794.000000,265.347839 1794.000000,261.990356 
	C1797.276611,266.454590 1797.921631,304.447601 1794.003296,308.462677 
	C1794.003296,296.164429 1794.003296,283.866180 1794.001709,271.081238 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M82.963425,884.166260 
	C83.664963,887.165344 84.798096,890.651917 84.902061,894.168945 
	C85.196655,904.135193 85.070206,914.113892 85.126320,924.912598 
	C85.785622,926.614502 86.428185,927.491394 87.097214,928.723511 
	C87.106712,930.265381 87.089737,931.452026 86.711670,932.804077 
	C86.251534,935.141541 86.152489,937.313538 86.093666,939.655151 
	C86.160851,940.060181 86.135071,940.289612 85.847870,940.789673 
	C85.494736,941.723572 85.350266,942.380981 85.154388,943.241943 
	C85.102989,943.445435 85.086220,943.864990 85.086212,943.864990 
	C84.625221,943.909973 84.164230,943.955017 83.355980,944.005127 
	C82.980858,924.229431 82.952995,904.448547 82.963425,884.166260 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1154.008057,548.853638 
	C1150.859253,548.540833 1147.695923,548.321777 1144.572632,547.842407 
	C1143.880981,547.736267 1143.330200,546.710693 1142.816650,545.516235 
	C1142.457764,544.779968 1141.996948,544.635864 1141.536133,544.491821 
	C1139.856445,545.266785 1138.176636,546.041809 1135.184937,547.422119 
	C1136.022461,544.635132 1136.103394,541.039246 1137.505249,540.416443 
	C1140.149780,539.241577 1144.016968,538.265015 1144.855713,543.597717 
	C1145.305176,546.454956 1147.120483,546.187561 1149.006592,544.201538 
	C1150.660400,542.459961 1152.663330,541.049927 1154.512085,539.493469 
	C1154.898682,540.009277 1155.285156,540.525146 1155.671631,541.041016 
	C1154.600220,541.819214 1153.528931,542.597412 1152.457520,543.375610 
	C1153.033691,543.972656 1153.609863,544.569702 1154.843262,545.164734 
	C1155.003052,546.393066 1154.505493,547.623352 1154.008057,548.853638 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2345.992676,519.098145 
	C2350.949951,519.090881 2355.409668,519.090881 2359.869629,519.090881 
	C2359.857422,519.994202 2359.845459,520.897522 2359.833252,521.800903 
	C2345.113281,522.200989 2330.379639,521.946838 2315.652100,521.979736 
	C2315.598389,521.365845 2315.544922,520.751953 2315.491455,520.138062 
	C2318.557373,519.905457 2321.620361,519.615417 2324.689941,519.455444 
	C2328.137451,519.275696 2331.590088,519.168335 2335.041992,519.113037 
	C2338.525391,519.057251 2342.010742,519.103760 2345.992676,519.098145 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M963.054810,301.963135 
	C963.521301,296.800446 962.000366,291.857239 963.984985,287.389771 
	C964.483459,287.430084 964.981995,287.470398 965.480469,287.510712 
	C965.480469,302.757477 965.480469,318.004211 965.480469,333.668396 
	C961.550476,330.537109 964.070190,326.878265 963.986084,323.611877 
	C963.897827,320.189087 963.970459,316.760651 963.818970,313.341492 
	C963.658020,309.707581 963.316528,306.081604 963.054810,301.963135 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1797.736816,436.109741 
	C1797.735962,441.935516 1797.735962,447.278076 1797.735962,452.620667 
	C1797.134888,452.613586 1796.533936,452.606506 1795.932861,452.599426 
	C1795.932861,437.626648 1795.932861,422.653839 1795.932861,407.681030 
	C1796.278931,407.658386 1796.624878,407.635712 1796.970947,407.613037 
	C1797.226562,416.950867 1797.482056,426.288696 1797.736816,436.109741 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2390.433594,521.894653 
	C2388.827637,521.713501 2387.663086,521.532043 2386.498779,521.350525 
	C2386.519043,520.667969 2386.539307,519.985413 2386.559814,519.302856 
	C2401.489258,518.560120 2416.458496,519.275024 2431.396484,518.847839 
	C2431.427490,519.556213 2431.458496,520.264587 2431.489502,520.972961 
	C2424.910156,521.145264 2418.331299,521.336060 2411.751465,521.484802 
	C2404.793213,521.642090 2397.833740,521.759521 2390.433594,521.894653 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1900.958496,525.837280 
	C1894.764404,525.836548 1889.063110,525.836548 1882.500732,525.836548 
	C1885.427368,522.703796 1919.941406,521.751404 1928.358276,524.852234 
	C1919.389282,525.180786 1910.420166,525.509399 1900.958496,525.837280 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M48.534054,932.242554 
	C46.128990,934.969421 45.017673,944.639465 46.708584,946.971741 
	C49.010799,950.147522 52.146473,949.529907 55.188042,948.254456 
	C56.277458,947.797668 57.188950,946.916565 58.181656,946.229187 
	C58.561314,946.713562 58.940975,947.197998 59.320637,947.682373 
	C57.467659,949.052368 55.785374,951.119446 53.720726,951.625000 
	C50.797646,952.340820 47.539749,952.667419 45.218296,949.370300 
	C41.524364,944.123901 42.365250,934.163879 47.103447,930.363220 
	C51.202114,927.075562 55.985321,928.736267 57.359142,933.291199 
	C54.724812,932.908447 51.821980,932.486755 48.534054,932.242554 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2198.032715,520.147461 
	C2204.508301,520.344788 2210.591309,519.587524 2216.487793,520.591187 
	C2216.438721,521.230896 2216.389404,521.870667 2216.340088,522.510376 
	C2201.453125,522.510376 2186.566162,522.510376 2171.679199,522.510376 
	C2171.652100,522.053467 2171.625000,521.596558 2171.598145,521.139648 
	C2180.244873,520.810181 2188.891846,520.480713 2198.032715,520.147461 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2424.145752,712.553223 
	C2408.701660,712.792786 2393.611572,712.792786 2378.521484,712.792786 
	C2378.490967,712.386597 2378.460693,711.980469 2378.430420,711.574280 
	C2379.149170,711.363647 2379.864014,710.985535 2380.587646,710.967102 
	C2394.023438,710.624634 2407.459961,710.265137 2420.898682,710.119812 
	C2422.090820,710.106934 2423.299072,711.547852 2424.145752,712.553223 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M556.997742,939.995728 
	C584.014160,939.697449 611.030518,939.399170 638.640991,939.340820 
	C639.490051,940.052429 639.745056,940.524170 640.000061,940.995972 
	C612.644836,941.320312 585.289612,941.644714 557.460144,941.625977 
	C556.989807,940.853821 556.993774,940.424805 556.997742,939.995728 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2448.998047,563.177551 
	C2449.643311,562.907959 2449.929199,562.895203 2450.424316,562.846680 
	C2450.633545,577.992065 2450.633545,593.167725 2450.633545,608.343445 
	C2450.205811,608.355164 2449.778076,608.366943 2449.350586,608.378662 
	C2448.662842,593.416382 2447.068359,578.489014 2448.998047,563.177551 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M541.973938,294.271332 
	C541.970642,284.277130 541.970642,274.753632 541.970642,265.230103 
	C546.035583,272.433563 543.393311,280.086121 543.865845,287.502472 
	C544.368896,295.396545 543.979004,303.347534 543.979004,311.273651 
	C543.311768,311.304962 542.644470,311.336243 541.977234,311.367523 
	C541.977234,305.825684 541.977234,300.283844 541.973938,294.271332 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1812.170654,525.560425 
	C1812.545044,524.875671 1813.222412,524.026367 1813.921143,524.008423 
	C1826.858643,523.676208 1839.798340,523.418091 1852.738770,523.228760 
	C1854.002441,523.210205 1855.274292,523.743958 1856.542358,524.021362 
	C1856.489014,524.608582 1856.435669,525.195740 1856.382324,525.782959 
	C1841.749390,525.782959 1827.116333,525.782959 1812.170654,525.560425 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1649.000244,910.989197 
	C1645.966675,911.023438 1642.933105,911.057678 1639.449097,910.801880 
	C1639.000488,910.343445 1639.002441,910.175110 1639.359863,909.939148 
	C1641.003296,909.705750 1642.320435,909.655518 1643.569946,909.338928 
	C1644.640381,909.067566 1645.633545,908.491394 1646.660889,908.050049 
	C1645.677246,907.420776 1644.731934,906.335754 1643.702515,906.248779 
	C1640.750122,905.999451 1637.739868,905.914856 1634.796265,906.207581 
	C1630.537476,906.631104 1628.807739,905.134277 1628.972534,900.717468 
	C1629.243408,893.458069 1629.082153,886.182678 1629.387817,878.868896 
	C1630.054810,878.836060 1630.437134,878.848389 1630.855713,879.336792 
	C1630.949951,885.956177 1631.338501,892.119873 1630.961182,898.236267 
	C1630.659180,903.129395 1632.645020,904.092896 1637.039551,903.973267 
	C1641.325806,903.856689 1645.639526,904.749329 1649.888672,905.561401 
	C1649.557495,907.612305 1649.278809,909.300720 1649.000244,910.989197 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M964.079346,360.788086 
	C964.759949,360.732849 965.915222,361.075378 965.920410,361.434601 
	C966.042297,369.828613 965.941101,378.226227 966.097595,386.619171 
	C966.189941,391.567841 966.717346,396.507111 966.912720,401.455963 
	C966.970093,402.908813 966.560425,404.380127 965.206177,405.807831 
	C963.989380,390.914886 963.990295,376.054718 964.079346,360.788086 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2528.113770,1053.885254 
	C2524.715576,1052.754517 2521.382080,1052.045654 2517.657959,1051.253784 
	C2517.156250,1054.208374 2516.815186,1056.215698 2516.413330,1058.582886 
	C2513.548584,1053.097290 2517.197510,1041.339844 2523.318604,1036.674316 
	C2525.568359,1043.767944 2532.231689,1049.062500 2530.443115,1057.342285 
	C2529.994629,1057.507812 2529.546387,1057.673340 2529.098145,1057.838867 
	C2528.791504,1056.661621 2528.484863,1055.484375 2528.113770,1053.885254 
M2526.530762,1047.770752 
	C2525.291260,1045.261353 2524.052002,1042.751953 2522.392822,1039.392822 
	C2520.488281,1043.339478 2519.110107,1046.195435 2517.731934,1049.051392 
	C2517.997803,1049.525391 2518.263916,1049.999390 2518.529785,1050.473511 
	C2521.331055,1049.768066 2524.132080,1049.062622 2526.530762,1047.770752 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M175.517120,242.813690 
	C161.958481,242.814667 148.842087,242.814667 135.725708,242.814667 
	C135.725235,242.217621 135.724777,241.620575 135.724304,241.023529 
	C150.524673,241.023529 165.325043,241.023529 180.125427,241.023529 
	C180.236298,241.158920 180.347183,241.294312 180.458054,241.429688 
	C178.958496,241.890701 177.458939,242.351700 175.517120,242.813690 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1153.891602,549.192139 
	C1154.505493,547.623352 1155.003052,546.393066 1155.218750,545.012085 
	C1155.800903,543.699341 1156.665039,542.537170 1157.758057,541.610474 
	C1159.404053,542.883057 1160.821167,543.920105 1162.124634,544.874023 
	C1160.597656,545.547363 1158.796021,546.341858 1156.983765,547.482117 
	C1152.800537,554.563232 1148.504517,561.226196 1144.501587,568.060974 
	C1142.130737,572.109314 1140.223022,576.428833 1138.107056,580.626465 
	C1136.207764,583.371826 1135.512695,583.194580 1132.061890,578.917725 
	C1133.651855,577.552734 1136.188354,576.872864 1137.298462,575.229553 
	C1142.992676,566.799683 1148.319824,558.121826 1153.891602,549.192139 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M388.087860,937.892517 
	C388.661407,937.409424 389.234955,936.926392 390.284363,936.235474 
	C391.065308,936.050171 391.352936,935.989685 391.623138,935.846313 
	C396.100403,935.923828 400.577698,936.001404 405.549530,936.497986 
	C406.782013,937.605469 407.414673,938.548584 408.287933,938.909546 
	C409.135132,939.259705 410.231171,938.981079 411.214722,939.036133 
	C412.123444,939.086975 413.027863,939.214111 412.521912,939.161926 
	C410.299713,940.143005 407.144989,941.535828 403.990265,942.928711 
	C399.291626,942.989746 394.592987,943.050720 389.447693,942.961853 
	C388.980713,942.381958 388.960388,941.951965 388.954773,940.877136 
	C388.675629,939.452393 388.381744,938.672485 388.087860,937.892517 
M403.518005,937.425232 
	C403.518005,937.425232 403.571594,937.519348 403.518005,937.425232 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2451.114258,867.003906 
	C2451.111328,861.607483 2451.111328,856.709717 2451.111328,851.812012 
	C2451.689209,851.809570 2452.266846,851.807129 2452.844727,851.804688 
	C2452.844727,866.937927 2452.844727,882.071228 2452.844727,897.204468 
	C2452.543701,897.224731 2452.242676,897.244995 2451.941650,897.265259 
	C2451.666992,887.344421 2451.392090,877.423523 2451.114258,867.003906 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2451.089111,656.684570 
	C2451.519043,665.204163 2451.925293,673.252502 2452.331787,681.300781 
	C2451.761719,681.445129 2451.191650,681.589478 2450.621582,681.733826 
	C2450.104736,680.781372 2449.143311,679.831177 2449.138428,678.876099 
	C2449.066650,664.726257 2449.118408,650.575684 2449.169434,636.425354 
	C2449.170410,636.166382 2449.516113,635.908630 2451.065674,635.579529 
	C2451.065674,642.457397 2451.065674,649.335327 2451.089111,656.684570 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1483.476074,527.183655 
	C1483.950439,527.632568 1483.971680,528.016479 1483.682129,528.697754 
	C1482.594849,529.315125 1481.818481,529.635132 1481.042114,529.955078 
	C1480.335083,530.527161 1479.628052,531.099365 1478.413208,532.082397 
	C1481.383301,531.948242 1483.761475,531.840820 1487.218506,531.684631 
	C1485.802002,532.789551 1485.145630,533.301514 1483.750977,533.924927 
	C1476.035522,534.375244 1469.058472,534.714233 1461.741455,534.994019 
	C1460.954712,534.014526 1460.507690,533.094177 1460.514282,532.129089 
	C1463.734131,532.086731 1466.500732,532.122192 1469.266357,532.081116 
	C1471.504028,532.047852 1472.953125,531.480469 1470.981689,528.721802 
	C1471.005005,527.994995 1471.029419,527.580444 1471.053833,527.166016 
	C1475.043579,527.150208 1479.033203,527.134399 1483.476074,527.183655 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M288.829651,1029.899902 
	C291.256744,1030.023560 293.231964,1030.145996 295.207214,1030.268311 
	C295.476715,1030.685669 295.746216,1031.103027 296.015747,1031.520386 
	C288.411804,1032.246460 290.049561,1037.333862 290.113312,1041.679443 
	C290.125458,1042.509277 290.348419,1043.417236 290.087738,1044.152100 
	C289.617859,1045.476807 288.855927,1046.698120 288.215973,1047.962524 
	C287.592865,1046.648682 286.532898,1045.367432 286.432129,1044.014648 
	C286.175232,1040.566895 286.346619,1037.087158 286.346619,1033.391235 
	C284.335815,1032.863770 282.575165,1032.401978 280.814545,1031.940186 
	C280.814026,1031.385620 280.813507,1030.831177 280.812988,1030.276733 
	C283.334595,1030.150635 285.856201,1030.024536 288.829651,1029.899902 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2313.111084,1034.167236 
	C2312.962646,1035.617432 2312.814209,1037.067627 2312.677246,1038.405396 
	C2311.102295,1038.940674 2309.773193,1039.392334 2308.399414,1040.149902 
	C2304.416748,1040.537720 2300.478516,1040.619507 2296.050537,1040.252686 
	C2294.659668,1039.342651 2293.758545,1038.881592 2292.345459,1038.158447 
	C2294.892822,1037.984253 2296.804688,1037.853394 2298.716797,1037.722656 
	C2298.707275,1037.553467 2298.697754,1037.384277 2298.688232,1037.215088 
	C2296.733398,1037.116577 2294.778320,1037.018188 2292.657959,1036.911499 
	C2293.596436,1035.953247 2294.349854,1035.183960 2295.257812,1034.298218 
	C2295.412354,1034.181763 2295.801514,1034.138306 2295.801514,1034.138306 
	C2295.801514,1034.138306 2296.174561,1034.257690 2296.185059,1034.509521 
	C2296.737793,1034.594116 2297.280518,1034.426758 2298.291504,1034.162354 
	C2303.543945,1034.099121 2308.327393,1034.133179 2313.111084,1034.167236 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2448.650391,246.824951 
	C2447.775635,246.373810 2446.391846,245.578445 2446.383545,244.768814 
	C2446.240967,231.021164 2446.263428,217.271744 2446.294434,203.522812 
	C2446.295166,203.181519 2446.809814,202.841324 2447.857666,201.542923 
	C2448.179932,217.222244 2448.480469,231.848175 2448.650391,246.824951 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M544.028442,362.027130 
	C544.027039,369.577515 544.027039,376.644684 544.027039,383.711823 
	C543.413025,383.718536 542.799011,383.725250 542.184998,383.731995 
	C541.797729,368.403412 542.070374,353.061218 541.992676,337.726227 
	C542.671753,337.706024 543.350769,337.685791 544.029846,337.665588 
	C544.029846,345.625000 544.029846,353.584442 544.028442,362.027130 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2150.513428,1230.181885 
	C2150.951904,1230.086304 2151.116699,1230.040771 2151.279785,1230.045410 
	C2151.442871,1230.050049 2151.604004,1230.104736 2151.766113,1230.137451 
	C2151.144531,1231.636719 2150.407715,1233.099854 2149.922119,1234.642090 
	C2148.010986,1240.712891 2148.067383,1240.659180 2142.135498,1238.112915 
	C2140.705078,1237.498901 2139.031494,1237.451294 2137.645020,1237.177856 
	C2139.720459,1234.802124 2141.028320,1232.330322 2143.072998,1231.241455 
	C2145.070312,1230.177856 2147.819824,1230.525635 2150.513428,1230.181885 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2027.769043,288.001923 
	C2027.753906,295.692505 2027.753906,302.883575 2027.753906,310.074615 
	C2027.221802,310.074768 2026.689575,310.074921 2026.157471,310.075073 
	C2026.157471,294.874725 2026.157471,279.674408 2026.157471,264.474091 
	C2026.699707,264.452087 2027.242065,264.430084 2027.784302,264.408112 
	C2027.784302,272.106201 2027.784302,279.804291 2027.769043,288.001923 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2449.044434,285.025360 
	C2449.046387,296.808716 2449.046387,308.098083 2449.046387,319.387482 
	C2448.325439,319.395355 2447.604736,319.403198 2446.883789,319.411041 
	C2446.883789,303.581970 2446.883789,287.752869 2446.883789,271.303284 
	C2447.124756,271.289032 2448.601562,271.201782 2449.042236,271.175751 
	C2449.042236,275.888977 2449.042236,280.210175 2449.044434,285.025360 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2384.667969,1038.764038 
	C2389.940186,1038.513062 2395.210449,1038.095459 2400.484619,1038.043091 
	C2415.053711,1037.898193 2429.623779,1037.906006 2444.644043,1038.160156 
	C2445.080322,1038.664307 2445.066162,1038.861816 2445.052246,1039.059326 
	C2428.811768,1039.501953 2412.572510,1039.983276 2396.330322,1040.356567 
	C2392.857666,1040.436523 2389.375977,1040.125366 2385.414551,1039.656250 
	C2384.843018,1039.133301 2384.755615,1038.948608 2384.667969,1038.764038 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2449.047363,363.995819 
	C2449.046875,373.593445 2449.046875,382.692535 2449.046875,391.791656 
	C2448.667236,391.795685 2448.287354,391.799744 2447.907715,391.803802 
	C2447.907715,376.790710 2447.907715,361.777618 2447.907715,346.764526 
	C2448.287842,346.744659 2448.667969,346.724792 2449.048096,346.704926 
	C2449.048096,352.302368 2449.048096,357.899841 2449.047363,363.995819 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2068.973145,1238.678833 
	C2069.288574,1237.465454 2069.573486,1236.243164 2069.926514,1235.040771 
	C2070.391602,1233.457764 2070.940918,1230.536011 2071.400879,1230.546509 
	C2073.610352,1230.596436 2075.852783,1231.297974 2077.977783,1232.062744 
	C2079.019287,1232.437500 2079.830322,1233.452637 2081.207764,1234.544434 
	C2078.516357,1235.660156 2076.532471,1236.482544 2074.381592,1237.374146 
	C2076.443115,1238.160400 2078.386719,1238.901855 2080.330566,1239.643311 
	C2080.292969,1239.903931 2080.255615,1240.164673 2080.218018,1240.425293 
	C2076.716553,1240.425293 2073.215332,1240.425293 2069.330078,1239.900635 
	C2068.955322,1239.143555 2068.964111,1238.911133 2068.973145,1238.678833 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1797.986816,516.951782 
	C1797.985962,520.006226 1797.985962,522.582153 1797.985962,525.158081 
	C1797.517944,525.153381 1797.050049,525.148621 1796.582031,525.143921 
	C1796.582031,510.020721 1796.582031,494.897491 1796.582031,479.774292 
	C1798.631104,491.948517 1798.673584,504.197174 1797.986816,516.951782 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2435.649902,843.552612 
	C2431.540283,845.246155 2427.775391,846.764099 2424.010498,848.281982 
	C2423.794922,847.703674 2423.579346,847.125366 2423.363525,846.546997 
	C2436.021240,841.025513 2448.678711,835.504089 2461.336182,829.982605 
	C2461.627197,830.651489 2461.918213,831.320312 2462.209229,831.989197 
	C2453.470947,835.785156 2444.732666,839.581055 2435.649902,843.552612 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M859.813477,537.326294 
	C861.697632,537.679626 863.493469,538.341431 865.604492,539.374817 
	C868.233093,542.118835 870.546631,544.491272 872.872070,547.258850 
	C872.829773,548.178772 872.775574,548.703491 872.337891,549.148987 
	C870.151001,549.807800 868.375916,550.628723 866.534607,551.255188 
	C865.303162,551.674133 863.977600,551.816650 862.694214,552.083008 
	C863.113525,551.533447 863.995117,550.850281 863.880859,550.454895 
	C862.697998,546.360352 862.098938,541.921204 858.114990,539.000000 
	C858.666077,538.356934 859.195618,537.995850 859.813477,537.326294 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1563.976685,458.561096 
	C1563.963379,444.291504 1563.963379,430.473480 1563.963379,416.655457 
	C1566.886108,431.526672 1565.307007,446.487396 1565.942017,461.403198 
	C1565.443848,461.444214 1564.945801,461.485229 1564.447754,461.526245 
	C1564.295166,460.688385 1564.142578,459.850494 1563.976685,458.561096 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2450.709473,787.551147 
	C2450.577881,784.549377 2450.577881,781.978394 2450.577881,779.407410 
	C2451.180420,779.430786 2451.783203,779.454163 2452.385742,779.477539 
	C2452.385742,794.419739 2452.385742,809.361938 2452.385742,824.304077 
	C2451.870850,824.314392 2451.355713,824.324707 2450.840820,824.334961 
	C2450.840820,812.217285 2450.840820,800.099609 2450.709473,787.551147 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1563.006836,297.000824 
	C1562.999634,288.215607 1562.999634,279.930054 1562.999634,271.606964 
	C1565.291260,274.377594 1565.879272,308.191559 1563.013916,316.581116 
	C1563.013916,310.220917 1563.013916,303.860687 1563.006836,297.000824 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M826.029358,234.821014 
	C825.858032,235.705002 825.401001,236.868027 824.942139,236.868759 
	C810.350220,236.892334 795.758240,236.805679 781.166443,236.700317 
	C781.001099,236.699142 780.839172,236.233246 779.739136,234.558197 
	C795.684143,234.558197 810.713440,234.558197 826.029358,234.821014 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M494.070862,940.187317 
	C510.048126,940.143066 526.025452,940.098816 542.374634,940.332214 
	C542.495361,941.089844 542.244202,941.569885 541.993042,942.049927 
	C526.307556,942.065552 510.622070,942.081116 494.470764,941.755371 
	C494.026886,941.005127 494.048859,940.596252 494.070862,940.187317 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M96.612869,1058.059082 
	C97.052391,1058.462036 97.074326,1058.780396 97.180351,1059.331055 
	C82.081429,1059.768188 66.949837,1059.969360 51.420944,1059.809814 
	C51.011246,1059.255615 50.998848,1059.062134 50.986458,1058.868774 
	C53.583202,1058.614868 56.178101,1058.164673 58.777004,1058.140259 
	C71.232010,1058.022949 83.688118,1058.020996 96.612869,1058.059082 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M404.453674,943.010803 
	C407.144989,941.535828 410.299713,940.143005 412.521912,939.161926 
	C413.027863,939.214111 412.123444,939.086975 411.214722,939.036133 
	C410.231171,938.981079 409.135132,939.259705 408.287933,938.909546 
	C407.414673,938.548584 406.782013,937.605469 406.000244,936.523804 
	C407.705688,936.093506 409.455017,936.056458 411.852875,936.314819 
	C414.135986,937.786682 415.770569,938.963074 417.643127,940.355957 
	C421.854614,940.600769 425.828156,940.629211 429.801636,940.657593 
	C430.117218,940.898132 430.432800,941.138672 430.850708,941.661621 
	C427.069183,942.311890 423.192078,942.847168 419.299713,943.004822 
	C414.512177,943.198730 409.711884,943.077820 404.453674,943.010803 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2046.937134,1239.491455 
	C2046.173340,1239.696411 2045.399292,1240.105225 2044.647583,1240.067993 
	C2042.419434,1239.957642 2038.506470,1240.140381 2038.292358,1239.366699 
	C2037.630859,1236.977905 2037.496948,1233.580688 2038.796387,1231.718506 
	C2040.620483,1229.104736 2043.488403,1230.243286 2045.777344,1233.190186 
	C2046.378052,1235.648315 2046.657593,1237.569946 2046.937134,1239.491455 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2026.947510,426.025116 
	C2026.944824,420.178619 2026.944824,414.827179 2026.944824,409.475708 
	C2027.393311,409.478821 2027.841797,409.481903 2028.290283,409.485016 
	C2028.290283,424.400909 2028.290283,439.316833 2028.290283,454.232727 
	C2027.843628,454.248901 2027.396851,454.265076 2026.950195,454.281250 
	C2026.950195,445.027557 2026.950195,435.773834 2026.947510,426.025116 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1565.962402,525.566223 
	C1565.426636,526.281128 1564.897095,526.531311 1564.075684,526.829590 
	C1563.783691,514.183105 1563.783691,501.488586 1563.783691,488.794098 
	C1564.360596,488.789215 1564.937500,488.784332 1565.514282,488.779480 
	C1568.526123,500.786560 1565.644409,513.000244 1565.962402,525.566223 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2449.929199,422.406860 
	C2449.935791,436.641144 2449.935791,450.428558 2449.935791,464.215973 
	C2449.483154,464.214661 2449.030273,464.213348 2448.577637,464.212036 
	C2448.577637,449.071625 2448.577637,433.931213 2448.577637,418.790802 
	C2448.853760,418.745575 2449.129883,418.700348 2449.406006,418.655151 
	C2449.578369,419.756744 2449.750488,420.858368 2449.929199,422.406860 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1561.006836,131.447891 
	C1561.204102,130.987808 1561.376953,130.998032 1561.810181,131.009613 
	C1562.372314,132.747223 1562.883179,134.476456 1562.941528,136.220840 
	C1563.315796,147.403351 1563.624023,158.588516 1563.854126,169.774841 
	C1563.880737,171.069229 1563.292725,172.376266 1562.989624,173.677429 
	C1562.320679,173.633713 1561.651611,173.590012 1560.982666,173.546295 
	C1560.982666,159.670258 1560.982666,145.794220 1561.006836,131.447891 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1564.962036,349.272766 
	C1564.961548,362.943634 1564.961548,376.147461 1564.961548,389.351257 
	C1564.511353,389.352722 1564.061035,389.354218 1563.610840,389.355682 
	C1563.610840,374.445557 1563.610840,359.535431 1563.610840,344.625336 
	C1563.907104,344.573822 1564.203247,344.522339 1564.499512,344.470825 
	C1564.653809,345.915802 1564.808228,347.360748 1564.962036,349.272766 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2024.916016,205.015778 
	C2024.913086,200.564682 2024.913086,196.610748 2024.913086,192.656799 
	C2025.362915,192.653320 2025.812622,192.649826 2026.262451,192.646347 
	C2026.262451,207.543549 2026.262451,222.440750 2026.262451,237.337952 
	C2025.814575,237.353638 2025.366699,237.369324 2024.918823,237.385010 
	C2024.918823,226.760986 2024.918823,216.136948 2024.916016,205.015778 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M300.999847,238.965149 
	C308.105469,238.972290 314.711090,238.972290 321.316711,238.972290 
	C321.318878,239.392212 321.321014,239.812149 321.323181,240.232071 
	C306.478882,240.232071 291.634583,240.232071 276.790283,240.232071 
	C276.774811,239.807373 276.759338,239.382690 276.743866,238.957993 
	C284.662537,238.957993 292.581177,238.957993 300.999847,238.965149 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2028.008545,351.006165 
	C2027.998047,361.483337 2027.998047,371.461517 2027.998047,381.439667 
	C2027.539673,381.438080 2027.081421,381.436493 2026.623047,381.434906 
	C2026.623047,366.548767 2026.623047,351.662598 2026.623047,336.776459 
	C2027.088379,336.759827 2027.553833,336.743225 2028.019165,336.726624 
	C2028.019165,341.320129 2028.019165,345.913666 2028.008545,351.006165 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1563.852539,217.002869 
	C1563.852661,226.469940 1563.852661,235.437546 1563.852661,244.405151 
	C1563.380615,244.396393 1562.908569,244.387634 1562.436523,244.378891 
	C1562.436523,229.589828 1562.436523,214.800751 1562.436523,200.011688 
	C1562.888916,199.974350 1563.341309,199.936996 1563.793701,199.899643 
	C1563.813232,205.434219 1563.832886,210.968811 1563.852539,217.002869 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M525.962219,236.954285 
	C529.743225,236.954102 533.030762,236.954102 536.318237,236.954102 
	C536.320496,237.389252 536.322754,237.824402 536.325012,238.259552 
	C522.096008,238.259552 507.866974,238.259552 493.637939,238.259552 
	C493.622406,237.840485 493.606873,237.421432 493.591339,237.002365 
	C498.886078,237.002365 504.180847,237.008575 509.475586,237.000595 
	C514.806641,236.992569 520.137695,236.970444 525.962219,236.954285 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M762.806274,1011.252441 
	C762.215088,1012.318909 761.657043,1012.967773 761.099060,1013.616638 
	C760.712769,1013.523926 760.326477,1013.431213 759.940186,1013.338562 
	C759.940186,1003.772278 759.940186,994.206055 759.940186,984.639771 
	C760.377441,984.589294 760.814636,984.538757 761.251892,984.488281 
	C762.419128,993.201477 762.872620,1001.991882 762.806274,1011.252441 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1103.918091,579.963623 
	C1103.207764,579.967163 1102.497559,579.970764 1101.352051,579.554199 
	C1100.725342,572.142395 1100.533936,565.150635 1100.679932,558.137817 
	C1101.691040,558.064575 1102.364868,558.012451 1103.038574,557.960327 
	C1103.750610,557.999084 1104.462646,558.037842 1105.553833,558.341187 
	C1105.932983,558.605652 1106.023193,559.035278 1105.627930,559.128296 
	C1104.836792,560.867371 1104.156006,562.503357 1104.097778,564.161255 
	C1103.912964,569.424316 1103.960449,574.695557 1103.918091,579.963623 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1104.984375,988.454407 
	C1104.977417,985.516174 1104.977417,983.022400 1104.977417,980.528687 
	C1105.576294,980.521851 1106.175049,980.515076 1106.773926,980.508240 
	C1108.927734,989.818726 1107.312866,999.267578 1107.922729,1008.634766 
	C1107.143555,1008.696411 1106.364502,1008.758057 1105.585327,1008.819702 
	C1105.394287,1005.669006 1105.123901,1002.520569 1105.033447,999.367004 
	C1104.933472,995.880127 1104.998169,992.388489 1104.984375,988.454407 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M65.556396,715.064514 
	C65.134361,714.218262 65.169907,713.322449 65.165878,712.144958 
	C74.192574,711.863342 83.258865,711.863342 92.325150,711.863342 
	C92.444557,712.505615 92.563957,713.147949 92.683365,713.790222 
	C91.097511,714.181335 89.517448,714.880737 87.924805,714.910645 
	C80.623131,715.047913 73.317924,714.997742 65.556396,715.064514 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M107.903008,619.138062 
	C108.301476,619.527649 108.960007,620.415710 108.973991,621.313843 
	C109.265785,640.046692 109.484558,658.780640 109.184135,677.803223 
	C108.429703,678.062805 108.208847,678.033569 107.987999,678.004395 
	C107.946304,658.549988 107.904610,639.095642 107.903008,619.138062 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M962.928101,456.950653 
	C963.382812,453.524658 964.072449,450.109802 964.235291,446.669983 
	C964.461365,441.895172 964.292114,437.101624 964.292114,432.316101 
	C964.947632,432.282776 965.603210,432.249451 966.258728,432.216156 
	C966.468262,433.775208 966.739990,435.328888 966.875916,436.894348 
	C967.305420,441.838531 967.684082,446.787109 968.507568,451.835388 
	C968.932739,451.936768 968.993286,451.993469 968.990723,452.315643 
	C969.034363,453.470978 969.080627,454.304138 968.803650,455.301636 
	C967.328308,456.325378 966.176147,457.184814 964.861877,457.774109 
	C964.109192,457.319489 963.518677,457.135071 962.928101,456.950653 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M962.944397,456.970032 
	C963.518677,457.135071 964.109192,457.319489 964.679932,458.052979 
	C962.035645,460.825104 959.411194,463.048248 957.017151,465.076202 
	C957.429382,466.989227 957.756470,468.507324 957.739868,470.061737 
	C955.263123,471.383881 953.130127,472.669708 950.938843,473.599182 
	C950.781738,473.023254 950.682861,472.803711 950.494873,472.302917 
	C949.179382,471.371246 947.953125,470.720886 947.078613,470.029968 
	C948.947021,468.643677 950.463623,467.297974 951.980164,465.952240 
	C951.980164,465.952240 952.007324,465.991364 952.217529,465.888062 
	C952.612122,465.509918 952.796387,465.235046 952.980652,464.960144 
	C952.980652,464.960144 952.989502,465.008392 953.157593,464.909668 
	C953.194702,464.511017 953.063721,464.211090 952.932739,463.911133 
	C953.767273,463.350067 954.601868,462.789001 955.818115,462.283997 
	C956.462891,462.208069 956.726013,462.076111 956.989136,461.944092 
	C956.989136,461.944092 957.005859,461.997498 957.213257,461.895630 
	C957.606079,461.517822 957.791504,461.241821 957.976929,460.965820 
	C957.976990,460.965851 957.997437,461.002411 958.217407,460.900177 
	C958.616028,460.521149 958.794678,460.244324 958.973267,459.967529 
	C958.973328,459.967560 959.000000,460.000000 959.220825,459.897400 
	C959.620239,459.517120 959.798767,459.239441 959.977356,458.961761 
	C959.977356,458.961761 960.004395,458.994812 960.219238,458.887146 
	C960.621094,458.502533 960.808167,458.225586 960.995239,457.948608 
	C960.995178,457.948608 960.997986,458.003326 961.283447,457.981140 
	C962.042297,457.622681 962.515808,457.286438 962.989258,456.950165 
	C962.989258,456.950165 962.960693,456.989380 962.944397,456.970032 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2295.103027,1034.414795 
	C2294.349854,1035.183960 2293.596436,1035.953247 2292.657959,1036.911499 
	C2294.778320,1037.018188 2296.733398,1037.116577 2298.688232,1037.215088 
	C2298.697754,1037.384277 2298.707275,1037.553467 2298.716797,1037.722656 
	C2296.804688,1037.853394 2294.892822,1037.984253 2292.345459,1038.158447 
	C2293.758545,1038.881592 2294.659668,1039.342651 2295.597656,1040.065796 
	C2293.655029,1040.450195 2291.676025,1040.572754 2289.354492,1040.638428 
	C2287.971680,1039.701416 2287.018799,1038.231812 2285.872559,1038.060791 
	C2283.304932,1037.677734 2280.641113,1037.940918 2278.006104,1037.059326 
	C2281.395020,1037.059326 2284.783691,1037.059326 2288.172607,1037.059326 
	C2288.171875,1036.800781 2288.171143,1036.542358 2288.170410,1036.283813 
	C2283.247314,1035.913452 2278.324463,1035.543091 2273.604492,1034.797607 
	C2280.906250,1034.419922 2288.004639,1034.417358 2295.103027,1034.414795 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2056.512695,1206.997925 
	C2057.327148,1208.112427 2058.762695,1209.160034 2058.883789,1210.342163 
	C2059.216553,1213.596680 2058.998779,1216.907471 2058.998779,1220.469604 
	C2061.973877,1220.797607 2064.448730,1221.070557 2066.923584,1221.343384 
	C2066.961182,1221.942017 2066.998779,1222.540527 2067.036133,1223.139160 
	C2063.909424,1223.398926 2060.737305,1224.041016 2057.683838,1223.679443 
	C2056.796875,1223.574463 2055.739258,1220.623657 2055.651611,1218.931274 
	C2055.457520,1215.171875 2055.827881,1211.383423 2056.269043,1207.287109 
	C2056.569824,1206.967529 2056.534180,1206.992432 2056.512695,1206.997925 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2245.724121,649.327393 
	C2246.916016,650.882080 2248.003906,652.213623 2249.107422,653.564270 
	C2246.411377,654.901550 2230.248535,646.886292 2225.172119,641.465942 
	C2225.153564,641.127319 2225.116455,641.076721 2225.478516,641.042908 
	C2226.877930,641.085144 2227.914795,641.161194 2229.127441,641.521484 
	C2233.841064,643.877014 2238.379395,645.948303 2242.917725,648.019592 
	C2243.818604,648.381165 2244.719238,648.742737 2245.724121,649.327393 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1537.600342,948.604431 
	C1537.920776,949.464233 1537.996460,950.012390 1538.070801,950.842163 
	C1536.961914,951.515930 1535.854248,951.907959 1534.399170,952.390442 
	C1534.030396,951.918884 1534.008911,951.356934 1534.207031,950.123352 
	C1532.834229,946.501160 1531.241821,943.550537 1529.424316,940.241211 
	C1527.418091,938.746887 1525.636963,937.611145 1523.855835,936.475403 
	C1524.624634,934.916748 1525.393433,933.358093 1526.792236,930.522278 
	C1530.729248,937.145386 1534.042358,942.719116 1537.600342,948.604431 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1025.793213,405.764465 
	C1028.012939,401.508728 1031.360474,398.753662 1036.579834,397.967224 
	C1036.979126,397.976624 1037.015259,398.017029 1036.894531,398.312134 
	C1037.188354,399.409668 1037.602905,400.212067 1037.996948,401.358063 
	C1037.806152,403.216644 1037.371948,404.759552 1037.528809,406.239807 
	C1037.749268,408.320465 1039.116211,410.660004 1035.257080,410.734802 
	C1033.691528,408.991608 1032.314087,407.439026 1030.738770,405.618408 
	C1029.029297,405.569946 1027.517700,405.789520 1025.952148,405.948486 
	C1025.898315,405.887909 1025.793213,405.764465 1025.793213,405.764465 
M1033.558716,402.033478 
	C1033.732544,402.045044 1033.906372,402.056610 1034.403076,401.901398 
	C1034.237305,401.898102 1034.071533,401.894806 1033.558716,402.033478 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2175.382324,620.990845 
	C2175.284424,620.126038 2175.559082,619.302979 2176.045654,618.217773 
	C2176.257568,617.955566 2176.513672,617.975281 2176.639648,617.987366 
	C2182.901123,620.426819 2189.089600,622.732300 2195.139404,625.356873 
	C2196.918457,626.128723 2198.305664,627.804932 2199.902588,629.408081 
	C2197.503174,628.999817 2195.033691,628.358459 2192.652100,627.478699 
	C2186.997314,625.390076 2181.384521,623.188477 2175.382324,620.990845 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2289.728516,678.978333 
	C2291.314697,681.155518 2292.659424,683.325684 2294.007324,685.869629 
	C2289.878906,683.712280 2285.680420,681.281555 2281.635986,678.617493 
	C2278.532227,676.573181 2275.622559,674.234497 2271.323730,671.066589 
	C2273.610352,670.667480 2274.977783,670.428711 2276.683350,670.095093 
	C2277.021240,670.000122 2276.990234,669.999939 2276.947266,670.342163 
	C2280.776367,673.461609 2284.648682,676.238770 2288.520996,679.015869 
	C2288.841553,678.973572 2289.163574,678.958740 2289.728516,678.978333 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1101.344482,457.681458 
	C1100.473755,459.823395 1099.889893,461.674835 1099.161865,463.983490 
	C1098.240601,462.889099 1097.516113,462.028625 1096.405029,460.751526 
	C1095.987671,459.311676 1095.957031,458.288452 1095.926514,457.265198 
	C1096.636597,455.666595 1097.688354,454.125641 1097.970215,452.454865 
	C1098.324707,450.352448 1098.049805,448.143890 1098.423584,445.478333 
	C1099.482788,444.978912 1100.164917,444.982269 1100.846924,444.985596 
	C1101.108398,449.120728 1101.369873,453.255859 1101.344482,457.681458 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1570.211670,905.376709 
	C1570.428223,907.609558 1570.644653,909.842346 1571.021484,912.360229 
	C1569.775391,912.743225 1568.369263,912.841125 1566.511475,912.639832 
	C1564.975952,910.226501 1564.975952,910.226501 1560.787598,913.304321 
	C1560.056641,910.832520 1559.403687,908.624512 1559.159668,906.245483 
	C1563.116333,905.841919 1566.664062,905.609314 1570.211670,905.376709 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2160.648438,1289.736572 
	C2157.970215,1290.196045 2154.929443,1290.695923 2151.884277,1290.721924 
	C2143.162598,1290.796509 2134.438965,1290.679199 2125.376221,1290.417725 
	C2136.785400,1289.985596 2148.534424,1289.773071 2160.648438,1289.736572 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2331.309570,713.919800 
	C2330.283936,713.458496 2329.741943,712.965149 2329.096191,712.207275 
	C2336.879150,711.541016 2344.765137,711.139221 2352.651367,710.737488 
	C2352.678467,711.498291 2352.705566,712.259155 2352.732666,713.019958 
	C2345.875732,714.769348 2338.756104,713.396790 2331.309570,713.919800 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2444.152344,103.334908 
	C2445.102539,104.612228 2446.011230,105.464989 2447.705811,107.054832 
	C2444.502930,107.054832 2442.166992,107.054832 2439.316895,107.054832 
	C2443.454590,102.204269 2443.269531,89.687500 2439.154297,87.727928 
	C2440.485596,86.787621 2441.639893,85.972290 2444.111328,84.226639 
	C2444.111328,91.223770 2444.111328,97.067062 2444.152344,103.334908 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1558.750732,906.416443 
	C1559.403687,908.624512 1560.056641,910.832520 1560.787598,913.304321 
	C1564.975952,910.226501 1564.975952,910.226501 1566.096191,912.710327 
	C1566.011108,914.208923 1565.889648,915.337769 1565.861328,915.601196 
	C1563.080933,915.601196 1560.616455,915.601196 1558.117920,915.266235 
	C1557.546631,908.955872 1554.829956,912.856934 1552.484619,913.887329 
	C1551.982788,912.823364 1551.477295,911.751587 1551.337769,911.455627 
	C1553.567749,909.822266 1555.417969,908.467102 1557.503174,906.741821 
	C1558.075562,906.386658 1558.413208,906.401550 1558.750732,906.416443 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1649.247192,911.228882 
	C1649.278809,909.300720 1649.557495,907.612305 1650.360840,905.508301 
	C1657.595825,905.075745 1664.306274,905.058655 1671.016602,905.041626 
	C1670.511963,905.670776 1670.007324,906.299866 1668.990967,906.657532 
	C1667.343994,906.579285 1666.208618,906.772583 1664.611084,906.994629 
	C1660.820068,907.023315 1657.491211,907.023315 1654.162231,907.023315 
	C1654.156006,907.298218 1654.149658,907.573120 1654.143311,907.848022 
	C1655.466797,907.958923 1656.790161,908.069824 1658.637329,908.224548 
	C1656.689209,909.387146 1655.344604,910.189575 1653.999878,910.992004 
	C1652.498047,911.150879 1650.996094,911.309753 1649.247192,911.228882 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2053.952148,1237.011475 
	C2054.309082,1236.765625 2054.666260,1236.519653 2055.410645,1235.788574 
	C2055.798096,1235.303467 2055.812988,1235.254883 2055.812988,1235.254883 
	C2056.659180,1233.770020 2057.505371,1232.285156 2058.404053,1230.708130 
	C2060.092285,1232.629761 2061.521240,1234.256470 2063.456299,1236.179932 
	C2062.945312,1237.292725 2061.927979,1238.108521 2060.910889,1238.924316 
	C2060.581787,1238.873779 2060.325684,1238.709595 2060.013184,1237.954468 
	C2059.691162,1237.226929 2059.497559,1236.976807 2059.304199,1236.726807 
	C2059.220703,1237.121216 2059.136963,1237.515625 2059.053467,1237.910034 
	C2055.846191,1240.525146 2052.378174,1242.093750 2048.352051,1240.354126 
	C2050.351318,1239.057739 2052.151611,1238.034668 2053.952148,1237.011475 
M2057.489014,1234.503296 
	C2057.489014,1234.503296 2057.541748,1234.615479 2057.489014,1234.503296 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M106.966850,583.430176 
	C107.655899,583.880005 108.915726,584.802612 108.915504,585.724915 
	C108.912994,596.033691 108.716125,606.342407 108.265869,616.978638 
	C107.642029,614.083740 107.108818,610.865417 107.048378,607.638245 
	C106.900246,599.728577 106.970222,591.814880 106.966850,583.430176 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1106.023193,559.035278 
	C1106.023193,559.035278 1105.932983,558.605652 1105.906982,558.388306 
	C1115.996216,558.976257 1123.495239,566.831665 1124.018188,577.561646 
	C1123.818604,577.985779 1123.593628,577.991882 1122.824707,577.724976 
	C1120.743896,567.885925 1114.656860,562.336548 1106.023193,559.035278 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2008.957520,1197.469360 
	C2009.262207,1196.511230 2009.563843,1195.972168 2009.950684,1195.136230 
	C2010.974731,1195.918701 2011.913696,1196.998047 2012.852539,1198.077393 
	C2013.787842,1196.992798 2014.723022,1195.908203 2016.006714,1194.419556 
	C2016.006714,1197.750244 2016.006714,1200.929321 2016.006714,1204.410278 
	C2014.837280,1202.883423 2013.886719,1201.642578 2012.936279,1200.401611 
	C2011.971069,1201.680054 2011.005981,1202.958374 2009.324951,1205.184937 
	C2009.172607,1202.183472 2009.063477,1200.036011 2008.957520,1197.469360 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1156.994385,547.136292 
	C1158.796021,546.341858 1160.597656,545.547363 1162.124634,544.874023 
	C1160.821167,543.920105 1159.404053,542.883057 1158.028076,541.458252 
	C1159.075439,540.515381 1160.081787,539.960266 1161.685547,539.502808 
	C1162.520996,539.734802 1162.759277,539.869202 1163.022705,540.338379 
	C1163.599365,541.345520 1164.150879,542.017883 1164.702393,542.690308 
	C1165.196655,542.182800 1165.690796,541.675293 1166.184937,541.167786 
	C1166.846558,541.063049 1167.508179,540.958374 1168.241943,541.403137 
	C1166.987427,544.112366 1165.660645,546.272156 1164.333862,548.431885 
	C1163.782959,548.319763 1163.232056,548.207642 1161.913330,548.036377 
	C1159.761841,547.696899 1158.378052,547.416565 1156.994385,547.136292 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1691.064087,531.763794 
	C1691.648926,529.699951 1691.136108,528.851624 1688.738525,528.938782 
	C1683.526001,529.128296 1678.301880,528.997253 1673.078247,528.060303 
	C1678.995239,528.060303 1684.912231,528.060303 1690.829224,528.060303 
	C1690.834717,527.792908 1690.840210,527.525513 1690.845581,527.258118 
	C1686.082764,527.021179 1681.319824,526.784241 1676.276489,526.234131 
	C1681.762695,525.834534 1687.529419,525.747986 1693.735840,525.720337 
	C1694.706177,527.405212 1695.236816,529.031128 1695.525391,530.915649 
	C1693.876831,531.370789 1692.470459,531.567322 1691.064087,531.763794 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2053.630371,1236.835938 
	C2052.151611,1238.034668 2050.351318,1239.057739 2048.159180,1240.099976 
	C2047.604370,1240.047852 2047.441040,1239.976562 2047.107422,1239.698486 
	C2046.657593,1237.569946 2046.378052,1235.648315 2046.032471,1233.478027 
	C2049.950195,1229.130615 2052.476807,1229.351929 2053.755371,1233.807983 
	C2052.033447,1233.998413 2050.294678,1234.190674 2048.555908,1234.382812 
	C2048.689209,1235.470093 2048.822510,1236.557373 2048.955811,1237.644531 
	C2050.406738,1237.316406 2051.857910,1236.988281 2053.630371,1236.835938 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M109.619766,786.498169 
	C109.826660,786.059631 110.057968,786.040344 110.667503,786.480896 
	C111.339157,794.943176 111.632584,802.945679 112.244583,810.970581 
	C112.775604,810.998596 112.988052,811.004089 113.118660,811.353882 
	C113.344803,812.496399 113.652771,813.294617 113.972900,814.369263 
	C113.963928,815.012451 113.942802,815.379211 113.565575,815.804077 
	C109.799019,815.878235 107.934082,814.611328 109.058800,810.862427 
	C109.040237,810.159180 109.021675,809.455994 109.011124,808.401794 
	C109.274742,805.796326 109.712372,803.544678 109.748314,801.286682 
	C109.824547,796.498657 109.691437,791.707397 109.619766,786.498169 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1998.636230,1228.844849 
	C1998.218506,1229.015991 1997.800659,1229.187134 1997.012207,1229.736084 
	C1996.641479,1230.113892 1996.270508,1230.216797 1996.270508,1230.216797 
	C1995.874634,1229.920532 1995.478760,1229.624390 1994.990234,1228.569092 
	C1994.899658,1222.914307 1994.901611,1218.018799 1994.903564,1213.123291 
	C1994.920288,1212.591919 1994.937012,1212.060669 1995.000000,1211.130859 
	C1995.777466,1210.808472 1996.508911,1210.884521 1997.604614,1211.016846 
	C1998.017822,1211.558105 1998.066650,1212.042969 1998.180908,1213.339233 
	C1998.376221,1219.048584 1998.506226,1223.946777 1998.636230,1228.844849 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2027.487793,561.985657 
	C2027.659546,562.666992 2027.376831,563.450012 2026.602295,564.586548 
	C2020.452759,565.014893 2014.795166,565.089783 2009.137695,565.164673 
	C2009.098267,564.726929 2009.058838,564.289124 2009.007446,563.523560 
	C2015.008057,562.826355 2021.020630,562.456848 2027.487793,561.985657 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1943.314575,908.267151 
	C1942.994995,907.590454 1942.989990,907.173767 1943.438477,906.400391 
	C1949.932373,906.038269 1955.972534,906.032837 1962.012817,906.027344 
	C1962.258179,906.488281 1962.503662,906.949280 1962.864746,907.645020 
	C1956.530151,908.095581 1950.079712,908.311340 1943.314575,908.267151 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2414.334473,624.423218 
	C2409.848877,631.776794 2403.336426,637.082764 2397.076904,642.644104 
	C2396.723633,642.298523 2396.370361,641.952881 2396.017090,641.607300 
	C2401.754883,635.779785 2407.492432,629.952209 2413.650146,624.108582 
	C2414.070068,624.092468 2414.334473,624.423218 2414.334473,624.423218 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2121.832031,1238.277344 
	C2116.831055,1238.884155 2117.444336,1235.996704 2117.906738,1232.831543 
	C2120.063477,1232.831543 2122.108643,1232.831543 2126.041016,1232.831543 
	C2124.362305,1235.215820 2123.286377,1236.744263 2121.832031,1238.277344 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1123.815430,545.731445 
	C1124.814453,543.660828 1125.736206,541.871582 1126.657837,540.082336 
	C1127.258301,540.163269 1127.858765,540.244202 1128.459229,540.325195 
	C1128.673218,542.428955 1128.887085,544.532715 1129.101074,546.636475 
	C1127.161011,546.709290 1125.220947,546.782104 1123.280884,546.854980 
	C1123.318726,546.694824 1123.326904,546.518616 1123.402832,546.379089 
	C1123.479736,546.238098 1123.623413,546.133484 1123.815430,545.731445 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1485.269897,916.724426 
	C1485.327515,915.397888 1485.305420,913.852905 1486.036255,913.296692 
	C1487.463257,912.210510 1489.311035,911.677063 1490.984497,910.914673 
	C1491.597168,912.334290 1492.209961,913.753906 1492.760742,915.507690 
	C1490.638794,918.337769 1488.337891,919.279663 1485.269897,916.724426 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1133.283569,525.134155 
	C1133.017212,523.950073 1133.018799,522.924316 1133.049683,521.511719 
	C1134.298950,516.407471 1135.572998,516.066101 1139.401245,520.034058 
	C1139.469727,521.506226 1139.255615,522.450989 1139.041504,523.395813 
	C1137.211548,524.028076 1135.381470,524.660278 1133.283569,525.134155 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M107.218155,356.681519 
	C106.765457,357.000977 106.571899,357.001526 106.086533,357.000061 
	C104.230476,351.665161 105.080238,346.443146 105.371124,341.195496 
	C106.073174,341.190979 106.775230,341.186462 107.477280,341.181946 
	C107.477280,346.242157 107.477280,351.302368 107.218155,356.681519 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M50.518974,1058.854004 
	C50.998848,1059.062134 51.011246,1059.255615 51.023766,1059.740234 
	C35.407234,1060.111572 19.790573,1060.191895 3.723713,1060.275513 
	C3.253213,1060.034912 3.232912,1059.791016 3.187951,1059.180664 
	C18.792690,1058.822632 34.422092,1058.830933 50.518974,1058.854004 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1037.944092,396.951294 
	C1040.720581,394.623993 1043.496948,392.296692 1046.636719,389.984680 
	C1047.000000,390.000000 1046.995117,389.993927 1046.880127,390.275116 
	C1046.445435,392.286133 1046.445435,392.286133 1049.880615,392.299500 
	C1046.776978,394.754730 1043.809937,396.903137 1040.904053,398.731567 
	C1041.015503,397.981293 1041.065918,397.550934 1041.426392,397.067749 
	C1042.513184,396.361816 1043.290039,395.708740 1044.221680,394.976257 
	C1044.226318,394.902893 1044.076294,394.908936 1043.575928,394.917053 
	C1042.427368,395.559814 1041.629150,396.200531 1040.477783,396.852753 
	C1039.421265,396.916199 1038.718018,396.968140 1037.998047,397.001801 
	C1037.981567,396.983551 1037.944092,396.951324 1037.944092,396.951294 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2354.023193,686.020874 
	C2356.250732,683.278381 2358.392090,680.459473 2360.729736,677.814392 
	C2363.200684,675.018921 2365.859131,672.389221 2368.435791,669.687195 
	C2368.989990,670.226685 2369.544189,670.766113 2370.098633,671.305542 
	C2364.953369,676.168518 2359.808105,681.031494 2354.331787,685.947632 
	C2354.000488,686.000732 2354.023193,686.020874 2354.023193,686.020874 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2071.055176,1221.334473 
	C2071.236328,1220.984741 2071.448730,1220.984253 2072.271973,1221.152344 
	C2077.381104,1225.517578 2082.105225,1223.382568 2086.808838,1221.816284 
	C2086.808838,1221.816284 2087.064453,1222.054443 2087.196289,1222.173462 
	C2082.749512,1228.349243 2076.080811,1228.099243 2071.055176,1221.334473 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1674.219238,911.146790 
	C1674.804199,909.936218 1675.575317,908.166687 1676.419678,908.131042 
	C1682.241943,907.885559 1688.079468,908.001770 1693.956299,908.782410 
	C1692.596924,908.928345 1691.237427,909.074280 1688.492920,909.368896 
	C1690.457764,909.839478 1691.064575,909.984802 1692.017334,910.218445 
	C1690.425293,910.685730 1688.487427,911.064819 1686.457031,910.953003 
	C1686.321533,909.973022 1686.289429,909.057800 1686.233521,909.056396 
	C1683.816162,908.993225 1681.390625,908.929260 1678.983643,909.104309 
	C1678.606079,909.131836 1678.310913,910.290771 1677.977661,910.926514 
	C1676.798218,911.081055 1675.618896,911.235596 1674.219238,911.146790 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2242.846191,647.676208 
	C2238.379395,645.948303 2233.841064,643.877014 2229.157715,641.183777 
	C2229.030273,640.337708 2229.047852,640.113586 2229.065674,639.889404 
	C2233.635254,642.370544 2238.205078,644.851685 2242.846191,647.676208 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M756.821289,490.607422 
	C755.324280,490.598816 753.865662,490.125305 752.192078,489.400574 
	C752.684265,487.913422 753.801758,486.741302 754.006104,485.427155 
	C754.345520,483.244385 754.098511,480.970398 754.877563,478.651855 
	C757.979004,482.074707 756.526672,486.274292 756.821289,490.607422 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1605.058105,905.199341 
	C1604.907593,907.879822 1603.901367,909.998230 1601.191284,911.302368 
	C1600.032227,911.703674 1598.914307,911.827393 1597.375244,911.946899 
	C1597.274902,909.994080 1597.595581,908.045410 1598.303467,905.618408 
	C1600.813110,905.159790 1602.935669,905.179565 1605.058105,905.199341 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2131.417969,1236.787842 
	C2130.769043,1235.345703 2130.152588,1234.274536 2129.300293,1232.793579 
	C2130.312012,1231.906738 2131.478027,1230.884888 2133.429688,1229.174194 
	C2133.429688,1233.397583 2133.429688,1236.648682 2133.429688,1239.899780 
	C2133.101074,1240.009644 2132.772461,1240.119629 2132.443848,1240.229492 
	C2132.112793,1239.205933 2131.781494,1238.182495 2131.417969,1236.787842 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1210.379639,530.437439 
	C1210.309448,530.219482 1210.239380,530.001465 1210.072510,529.454712 
	C1216.347046,529.076843 1222.718506,529.027832 1229.558716,529.028320 
	C1230.015137,529.508667 1230.002686,529.939514 1229.522705,530.687988 
	C1222.829956,530.816223 1216.604858,530.626831 1210.379639,530.437439 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1667.004517,907.009766 
	C1668.325073,908.087219 1669.645752,909.164612 1670.982666,910.608032 
	C1670.438721,911.110779 1669.878296,911.247559 1669.251221,910.944458 
	C1669.019409,910.085327 1668.854492,909.665955 1668.689697,909.246582 
	C1667.789062,909.799255 1666.888428,910.351990 1665.987793,910.904785 
	C1664.169189,911.090759 1662.350464,911.276733 1660.277588,910.828979 
	C1662.015503,909.129944 1664.007690,908.064575 1666.251221,906.982666 
	C1666.669922,906.980713 1666.837158,906.995239 1667.004517,907.009766 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1854.976807,906.974365 
	C1854.980347,907.209290 1854.983765,907.444153 1854.989258,908.034180 
	C1849.862915,908.753418 1844.734619,909.117493 1839.358643,908.934692 
	C1839.156372,908.197632 1839.201538,908.007568 1839.645264,907.858337 
	C1845.021362,907.590942 1849.999146,907.282654 1854.976807,906.974365 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M661.559631,939.021423 
	C662.007996,939.479431 661.995422,939.913025 661.991455,940.672302 
	C658.211060,941.165955 654.422180,941.333984 650.420105,941.008301 
	C650.449646,940.043152 650.692261,939.571655 650.934937,939.100098 
	C654.322876,939.065796 657.710815,939.031433 661.559631,939.021423 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1139.059082,523.797729 
	C1139.255615,522.450989 1139.469727,521.506226 1139.621216,520.330933 
	C1140.337158,518.916016 1141.115845,517.731628 1141.908936,516.525269 
	C1144.519531,519.271423 1144.553223,523.516235 1141.852051,525.245056 
	C1141.354004,525.563843 1140.023193,524.581421 1139.059082,523.797729 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M84.827972,855.736694 
	C84.551941,862.882080 84.297409,869.804016 84.010468,876.724609 
	C83.928879,878.692322 83.752953,880.656189 83.288109,882.919189 
	C82.950287,873.457031 82.944641,863.697510 83.214676,853.460510 
	C83.661400,852.988098 83.832466,852.993225 84.228050,853.159302 
	C84.624748,853.385620 84.796921,853.450928 84.969086,853.516235 
	C84.925117,854.181702 84.881142,854.847168 84.827972,855.736694 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1523.640259,936.678833 
	C1525.636963,937.611145 1527.418091,938.746887 1529.399902,940.540039 
	C1529.545288,942.011780 1529.490112,942.826050 1529.266113,943.773560 
	C1529.097412,943.906799 1528.762817,944.177063 1528.386597,944.076172 
	C1524.567871,945.041077 1524.461182,941.730957 1522.952026,939.741455 
	C1522.814575,939.030090 1522.745239,938.748474 1522.675903,938.466797 
	C1522.925415,937.938599 1523.175049,937.410400 1523.640259,936.678833 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2413.932129,623.931641 
	C2416.801270,620.481567 2419.808105,617.192322 2422.815186,613.903076 
	C2423.375488,614.457581 2423.936035,615.012085 2424.496582,615.566650 
	C2421.326660,618.444824 2418.156982,621.323059 2414.660889,624.312256 
	C2414.334473,624.423218 2414.070068,624.092468 2413.932129,623.931641 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M260.220032,1034.186646 
	C260.697113,1037.488647 260.688263,1040.718994 260.710876,1044.333984 
	C259.779602,1044.808716 258.816803,1044.898926 257.370422,1044.993164 
	C256.779724,1041.631714 256.672638,1038.265991 256.678650,1034.453857 
	C257.772461,1034.043213 258.753265,1034.079102 260.220032,1034.186646 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2288.372559,678.682190 
	C2284.648682,676.238770 2280.776367,673.461609 2276.950195,670.326416 
	C2280.739258,672.761780 2284.481689,675.555176 2288.372559,678.682190 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1087.016724,361.994385 
	C1086.373047,361.153107 1085.729492,360.311829 1085.058594,359.075500 
	C1085.741577,358.120789 1086.452026,357.561096 1087.863037,357.024506 
	C1089.374634,356.357208 1090.185669,355.666870 1090.996704,354.976532 
	C1090.996704,354.976532 1091.001587,354.999756 1090.989990,354.996948 
	C1092.008911,354.744354 1093.039185,354.494568 1094.706055,354.090485 
	C1094.046631,355.127350 1093.627563,355.786102 1093.103516,356.721924 
	C1092.998291,356.998962 1093.019531,357.000214 1092.773560,357.080017 
	C1092.353271,357.445892 1092.178955,357.731995 1092.002197,358.008545 
	C1092.000000,357.998962 1092.017944,358.004700 1091.770996,358.082886 
	C1091.350098,358.447083 1091.176147,358.733093 1090.999756,359.008362 
	C1090.997314,358.997620 1091.018677,359.001953 1090.775391,359.088745 
	C1090.350098,359.459259 1090.168091,359.743011 1089.987793,360.008118 
	C1089.989502,359.989532 1090.026855,359.992432 1089.699463,360.020386 
	C1088.577637,360.706360 1087.783081,361.364410 1086.989990,362.009644 
	C1086.991455,361.996826 1087.016724,361.994385 1087.016724,361.994385 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2091.107666,1233.642456 
	C2090.802246,1235.662598 2090.565186,1237.380005 2090.188965,1239.441650 
	C2088.590332,1239.425171 2087.131104,1239.064575 2086.153320,1238.356934 
	C2087.405762,1237.958984 2088.176514,1237.908081 2088.947021,1237.857056 
	C2088.291260,1235.562378 2087.635498,1233.267700 2086.979736,1230.973022 
	C2089.359375,1230.022949 2090.893066,1230.572266 2091.107666,1233.642456 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M320.108490,1039.971680 
	C321.270264,1039.775635 322.460419,1039.830811 323.485229,1040.270874 
	C324.904663,1040.880493 326.242950,1041.722046 327.476990,1042.649414 
	C327.592255,1042.735962 326.761749,1044.790283 326.284241,1044.827515 
	C324.649567,1044.955078 322.954437,1044.694336 321.331818,1044.349121 
	C320.861603,1044.249146 320.557068,1043.370117 320.074402,1042.470459 
	C320.002594,1041.526733 320.032867,1040.960693 320.108490,1039.971680 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1522.396484,938.671692 
	C1522.745239,938.748474 1522.814575,939.030090 1522.836914,940.051086 
	C1521.261108,943.476868 1519.732422,946.163330 1518.226318,949.102234 
	C1518.484009,949.579651 1518.719238,949.804626 1518.963135,950.026733 
	C1518.971802,950.023804 1518.961548,950.052429 1518.961548,950.052429 
	C1518.961548,950.052429 1518.972412,950.544861 1518.983765,950.791016 
	C1516.741577,951.348511 1515.123413,951.108459 1516.722534,948.277710 
	C1518.499512,945.132019 1520.317139,942.009338 1522.396484,938.671692 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M457.431030,1055.997314 
	C456.954773,1055.572144 456.941132,1055.213501 456.908020,1054.587646 
	C461.068604,1054.202515 465.248657,1054.084473 469.941772,1053.960205 
	C469.219818,1054.668213 468.034607,1055.865234 466.739685,1055.998779 
	C463.819794,1056.299927 460.845642,1056.074829 457.431030,1055.997314 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M327.889923,1037.293091 
	C326.466553,1037.354370 325.091949,1037.317261 323.905670,1036.825684 
	C322.796631,1036.366089 321.787323,1035.516113 320.969666,1034.622314 
	C320.794373,1034.430664 321.553070,1032.789917 322.016754,1032.720581 
	C323.372375,1032.517700 324.887268,1032.499390 326.166321,1032.927246 
	C327.306458,1033.308594 328.215363,1034.381104 329.585571,1035.241821 
	C329.303497,1035.888794 328.662201,1036.442505 327.889923,1037.293091 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1829.998657,909.032654 
	C1825.968384,909.052063 1821.938110,909.071472 1817.448242,908.716919 
	C1816.994019,907.874329 1816.999390,907.405701 1817.467529,906.947510 
	C1821.971802,906.948059 1826.013306,906.938171 1830.049805,907.278992 
	C1830.029297,908.097412 1830.013916,908.565002 1829.998657,909.032654 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1470.589355,527.098145 
	C1471.029419,527.580444 1471.005005,527.994995 1470.557617,528.738159 
	C1466.095947,529.056274 1462.057495,529.045715 1458.018921,529.035156 
	C1458.004150,528.644043 1457.989258,528.252991 1457.945557,527.572876 
	C1461.985962,527.199402 1466.055420,527.114868 1470.589355,527.098145 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1102.985474,557.511475 
	C1102.364868,558.012451 1101.691040,558.064575 1100.562500,557.715210 
	C1100.111450,555.862061 1100.115479,554.410400 1100.119385,552.958740 
	C1100.826782,551.331604 1101.534180,549.704407 1102.564331,548.020874 
	C1102.902222,550.997192 1102.917358,554.029968 1102.985474,557.511475 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M376.992920,164.005753 
	C376.992920,157.853577 376.992920,151.701401 376.992920,145.549210 
	C377.329956,145.523819 377.666992,145.498428 378.004028,145.473022 
	C378.004028,151.330292 378.004028,157.187576 377.743042,163.522491 
	C377.482086,164.000137 376.992920,164.005753 376.992920,164.005753 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1666.000000,906.999207 
	C1664.007690,908.064575 1662.015503,909.129944 1660.014648,910.580688 
	C1658.288818,911.003784 1656.571655,911.041504 1654.427246,911.035645 
	C1655.344604,910.189575 1656.689209,909.387146 1658.637329,908.224548 
	C1656.790161,908.069824 1655.466797,907.958923 1654.143311,907.848022 
	C1654.149658,907.573120 1654.156006,907.298218 1654.162231,907.023315 
	C1657.491211,907.023315 1660.820068,907.023315 1664.843140,906.984619 
	C1665.537231,906.945862 1666.000000,906.999207 1666.000000,906.999207 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2441.920410,927.022278 
	C2441.116943,923.764526 2440.313721,920.506836 2439.339111,916.554749 
	C2440.858154,917.231445 2441.540039,917.535217 2442.524414,917.836243 
	C2442.885498,918.628357 2442.943848,919.423218 2442.861328,920.657959 
	C2442.809814,921.059875 2442.898682,921.021973 2442.987793,920.984009 
	C2443.000244,922.216003 2443.012939,923.447998 2443.006836,925.295898 
	C2442.632324,926.281982 2442.276367,926.652161 2441.920410,927.022278 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M938.736084,475.724609 
	C940.250122,474.228210 941.764221,472.731842 943.677612,471.135742 
	C944.076843,471.036041 944.329041,471.184967 944.463379,471.419739 
	C944.793091,472.676788 944.988464,473.699097 944.833862,474.919312 
	C942.699951,475.466339 940.915894,475.815460 939.033936,476.053650 
	C938.935974,475.942749 938.736084,475.724609 938.736084,475.724609 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M951.604553,465.933960 
	C950.463623,467.297974 948.947021,468.643677 946.814087,470.071808 
	C945.574768,470.497803 944.951904,470.841370 944.329041,471.184967 
	C944.329041,471.184967 944.076843,471.036041 943.950562,470.960022 
	C943.578003,469.965668 943.331726,469.047333 943.180969,468.485291 
	C946.031128,467.575287 948.630005,466.745514 951.604553,465.933960 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1102.087891,512.671875 
	C1101.622681,509.856506 1101.449951,506.770905 1101.511108,503.382568 
	C1101.956665,506.187073 1102.168579,509.294342 1102.087891,512.671875 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M441.950500,950.615295 
	C441.842987,950.959717 441.600189,950.972595 440.990234,951.001099 
	C439.097443,948.618347 437.571808,946.220032 435.881683,943.524902 
	C436.602234,942.965149 437.487274,942.702393 438.607056,942.541870 
	C439.832916,945.190613 440.824066,947.737183 441.950500,950.615295 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1678.422852,910.969788 
	C1678.310913,910.290771 1678.606079,909.131836 1678.983643,909.104309 
	C1681.390625,908.929260 1683.816162,908.993225 1686.233521,909.056396 
	C1686.289429,909.057800 1686.321533,909.973022 1686.190063,910.711182 
	C1683.632935,910.977905 1681.250488,910.995483 1678.422852,910.969788 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2353.990479,685.989441 
	C2352.542969,687.993103 2351.105225,690.008057 2349.667725,692.023071 
	C2349.115234,691.529297 2348.562744,691.035522 2348.010254,690.541748 
	C2349.775391,689.065430 2351.540527,687.589111 2353.664551,686.066833 
	C2354.023193,686.020874 2354.000488,686.000732 2353.990479,685.989441 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M968.901978,451.908936 
	C971.001160,449.916962 973.131104,447.952850 975.623169,445.987061 
	C975.985352,445.985352 975.999084,445.999115 975.857056,446.294373 
	C974.440186,448.354858 973.165466,450.120056 971.531250,451.907806 
	C970.445679,451.951355 969.719482,451.972412 968.993286,451.993469 
	C968.993286,451.993469 968.932739,451.936768 968.901978,451.908936 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2447.080078,938.572510 
	C2445.689697,939.221985 2444.304443,939.504395 2441.763428,940.022400 
	C2443.290771,937.451477 2444.135254,936.029968 2445.018311,934.264038 
	C2445.732910,935.348267 2446.408936,936.776855 2447.080078,938.572510 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2441.673340,927.146362 
	C2442.276367,926.652161 2442.632324,926.281982 2443.010742,925.651367 
	C2443.559082,927.200623 2444.084961,929.010437 2444.322266,930.935303 
	C2443.293701,930.534912 2442.381592,930.148315 2441.869141,929.462830 
	C2441.465576,928.923218 2441.555420,928.014954 2441.673340,927.146362 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2086.643555,1231.043213 
	C2087.635498,1233.267700 2088.291260,1235.562378 2088.947021,1237.857056 
	C2088.176514,1237.908081 2087.405762,1237.958984 2086.224854,1237.940308 
	C2085.861084,1236.646973 2085.877197,1235.421265 2085.963135,1234.200317 
	C2086.035645,1233.168335 2086.189453,1232.142212 2086.643555,1231.043213 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M544.054016,940.080383 
	C547.444702,940.046387 550.835388,940.012329 554.470703,940.245483 
	C554.142456,941.014160 553.569458,941.515625 552.996460,942.017090 
	C551.929199,942.018127 550.862000,942.019165 549.244934,941.780518 
	C548.129028,941.698547 547.562988,941.856201 546.996887,942.013916 
	C546.553101,942.030151 546.109253,942.046448 545.226685,941.786865 
	C544.543335,941.034180 544.298706,940.557251 544.054016,940.080383 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1100.190674,515.936279 
	C1100.501465,515.946045 1100.812256,515.943420 1101.350098,515.914429 
	C1102.054688,517.029358 1102.532227,518.158447 1102.999268,519.660645 
	C1102.678345,520.909302 1102.367554,521.784912 1101.910522,523.072876 
	C1101.332031,521.952515 1100.911865,521.138855 1100.227295,519.754517 
	C1100.038696,518.101257 1100.114624,517.018799 1100.190674,515.936279 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2061.065430,1239.340820 
	C2061.927979,1238.108521 2062.945312,1237.292725 2063.784180,1236.098022 
	C2063.606201,1234.203735 2063.606201,1232.688232 2063.606201,1230.663452 
	C2067.117432,1232.854370 2062.277588,1238.480469 2068.610840,1238.540649 
	C2068.964111,1238.911133 2068.955322,1239.143555 2068.970459,1239.723755 
	C2066.402832,1239.966919 2063.811523,1239.862183 2061.065430,1239.340820 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1015.001221,415.000061 
	C1016.395264,413.637482 1017.788086,412.273621 1019.561646,410.921204 
	C1019.942383,410.932617 1019.997437,410.996277 1019.845276,411.338928 
	C1019.480591,412.639343 1019.268066,413.597046 1018.959839,414.682861 
	C1018.693054,414.947510 1018.498962,415.030426 1017.871948,415.012848 
	C1016.639648,414.975128 1015.817322,414.984192 1014.995056,414.993286 
	C1014.995056,414.993286 1015.000000,414.998779 1015.001221,415.000061 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2288.501709,270.599365 
	C2289.342529,271.072876 2289.914307,271.339020 2290.486328,271.605164 
	C2290.460693,271.944305 2290.435059,272.283447 2290.409668,272.622589 
	C2289.089355,272.490784 2287.737793,272.472534 2286.476807,272.125183 
	C2286.242676,272.060730 2286.373291,270.671265 2286.335449,269.893341 
	C2286.968018,270.059570 2287.600342,270.225800 2288.501709,270.599365 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1100.905640,444.550964 
	C1100.164917,444.982269 1099.482788,444.978912 1098.461548,445.024445 
	C1098.110474,444.575806 1098.098633,444.078308 1098.399658,442.992065 
	C1098.798950,441.483765 1098.885498,440.564209 1098.972046,439.644653 
	C1099.405884,439.390869 1099.839600,439.137085 1100.532837,438.868652 
	C1100.849609,440.608093 1100.906982,442.362244 1100.905640,444.550964 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M225.667450,311.010559 
	C224.144287,312.290497 222.251480,313.622589 219.967651,314.993652 
	C221.483704,313.709290 223.390762,312.385986 225.667450,311.010559 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1839.246826,907.817444 
	C1839.201538,908.007568 1839.156372,908.197632 1839.059570,908.676147 
	C1838.829468,908.988892 1838.651001,909.013306 1838.135620,908.887390 
	C1837.559692,908.850037 1837.320557,908.962952 1837.081421,909.075928 
	C1835.669067,909.056824 1834.256714,909.037598 1832.489746,908.618774 
	C1833.158325,907.692078 1834.181396,907.165039 1835.548340,906.730957 
	C1837.010376,907.155090 1838.128662,907.486267 1839.246826,907.817444 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1817.004883,906.937012 
	C1816.999390,907.405701 1816.994019,907.874329 1816.984131,908.695129 
	C1816.543701,909.070007 1816.107666,909.092773 1815.328857,908.823181 
	C1814.836914,908.251648 1814.687744,907.972473 1814.538574,907.693359 
	C1814.362305,908.120911 1814.186157,908.548462 1814.010010,908.976013 
	C1813.568848,909.023804 1813.127686,909.071594 1812.355225,909.088074 
	C1811.699707,905.674988 1814.015259,905.923096 1816.617432,906.246948 
	C1817.021362,906.483826 1817.004883,906.937012 1817.004883,906.937012 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1835.204468,906.637939 
	C1834.181396,907.165039 1833.158325,907.692078 1832.068115,908.575745 
	C1831.444580,909.093384 1830.888184,909.254333 1830.165283,909.223999 
	C1830.013916,908.565002 1830.029297,908.097412 1830.240479,907.195496 
	C1830.683838,906.601440 1830.837402,906.377197 1830.896729,906.088501 
	C1831.176025,905.897034 1831.455322,905.705688 1832.091431,905.258484 
	C1832.448120,905.002625 1832.933228,904.953857 1833.123779,905.227661 
	C1833.925293,905.766052 1834.536255,906.030701 1835.151855,906.382568 
	C1835.156494,906.469849 1835.204468,906.637939 1835.204468,906.637939 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2228.885010,639.604309 
	C2229.047852,640.113586 2229.030273,640.337708 2228.982178,640.899536 
	C2227.914795,641.161194 2226.877930,641.085144 2225.448242,641.035034 
	C2225.031982,640.328491 2225.008057,639.595886 2224.982910,638.498352 
	C2226.222656,638.528625 2227.463623,638.923889 2228.885010,639.604309 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1321.621094,528.090332 
	C1322.043945,528.523315 1322.041748,528.913086 1321.627441,529.641235 
	C1319.794556,529.962891 1318.373535,529.946106 1316.952637,529.929321 
	C1316.955688,529.547119 1316.958740,529.164856 1316.946533,528.498413 
	C1318.353027,528.158508 1319.774536,528.102783 1321.621094,528.090332 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2456.215576,1038.909180 
	C2455.383301,1038.937134 2454.551025,1038.965088 2453.306152,1039.057373 
	C2452.707031,1039.107910 2452.520508,1039.094238 2452.052490,1039.074463 
	C2451.824219,1038.285522 2451.877930,1037.502686 2452.298340,1036.372070 
	C2453.152588,1036.049927 2453.640137,1036.075684 2454.127930,1036.101440 
	C2454.127930,1036.101440 2454.463379,1036.156372 2454.746826,1036.437012 
	C2455.425537,1037.448242 2455.820557,1038.178711 2456.215576,1038.909180 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2508.161133,272.909058 
	C2507.417480,272.674133 2507.019287,272.451569 2506.621338,272.229034 
	C2507.811035,271.851105 2509.000732,271.473145 2510.190430,271.095215 
	C2510.293457,271.507843 2510.396729,271.920502 2510.499756,272.333160 
	C2509.835449,272.529236 2509.171143,272.725342 2508.161133,272.909058 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M553.237671,942.230042 
	C553.569458,941.515625 554.142456,941.014160 554.855713,940.258789 
	C555.440918,939.988892 555.885803,939.972900 556.664246,939.976318 
	C556.993774,940.424805 556.989807,940.853821 556.992676,941.604126 
	C555.825989,942.097900 554.652405,942.270447 553.237671,942.230042 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2450.301270,966.075684 
	C2450.861816,967.162720 2451.422607,968.249695 2452.009277,969.696533 
	C2452.078613,970.483459 2452.121582,970.910522 2452.210205,971.657104 
	C2451.893555,971.765930 2451.531250,971.555237 2450.806885,970.791748 
	C2450.396729,968.851135 2450.349121,967.463440 2450.301270,966.075684 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1162.997559,540.003662 
	C1162.759277,539.869202 1162.520996,539.734802 1161.981201,539.319214 
	C1162.536011,537.695679 1163.392456,536.353271 1164.554932,534.934937 
	C1164.860718,534.858948 1164.950806,534.948547 1164.966309,535.328735 
	C1164.320435,537.140442 1163.658936,538.572021 1162.997559,540.003662 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M650.531799,939.078247 
	C650.692261,939.571655 650.449646,940.043152 650.102417,940.760803 
	C648.931213,941.022339 647.864563,941.037781 646.401917,940.732788 
	C646.034851,940.029419 646.063782,939.646484 646.092712,939.263550 
	C647.438049,939.194458 648.783325,939.125427 650.531799,939.078247 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1755.450684,909.868408 
	C1756.313354,909.605774 1757.561157,909.377869 1758.864258,909.469849 
	C1757.891602,909.827576 1756.863647,909.865417 1755.450684,909.868408 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2341.377686,726.613647 
	C2342.285645,727.803650 2343.193604,728.993713 2344.089355,730.556763 
	C2344.077148,730.929810 2344.002197,730.996094 2343.696777,730.872192 
	C2342.584717,729.506958 2341.777588,728.265686 2341.073730,726.922852 
	C2341.176514,726.821289 2341.377686,726.613586 2341.377686,726.613647 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M107.773048,688.238892 
	C108.181969,689.171570 108.379074,690.346863 108.341003,691.746826 
	C107.924294,690.808228 107.742767,689.644775 107.773048,688.238892 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M377.973877,167.018570 
	C378.010681,169.397217 378.047516,171.775879 377.785370,174.608032 
	C377.297394,175.067078 377.108429,175.072632 376.919434,175.078171 
	C376.924072,172.681122 376.928711,170.284073 377.207764,167.442978 
	C377.482239,166.998932 377.973877,167.018570 377.973877,167.018570 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M640.178589,941.200195 
	C639.745056,940.524170 639.490051,940.052429 639.108276,939.344666 
	C640.045959,939.512085 641.110474,939.915588 642.284302,940.682983 
	C641.714844,941.166077 641.035950,941.285278 640.178589,941.200195 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M934.011780,479.010498 
	C935.037109,478.289795 936.062439,477.569061 937.515869,476.893433 
	C937.943970,476.938507 938.151184,477.129700 938.041748,477.472900 
	C936.614014,478.202911 935.295837,478.589722 933.985840,478.984924 
	C933.994141,478.993317 934.011780,479.010468 934.011780,479.010498 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M543.772583,940.061401 
	C544.298706,940.557251 544.543335,941.034180 544.894531,941.759277 
	C544.139343,942.154419 543.277588,942.301270 542.204468,942.249023 
	C542.244202,941.569885 542.495361,941.089844 542.838379,940.356812 
	C543.117188,940.083313 543.304138,940.062805 543.772583,940.061401 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M645.742188,939.199097 
	C646.063782,939.646484 646.034851,940.029419 646.002808,940.701965 
	C645.072815,941.054688 644.145813,941.117859 642.893250,941.109802 
	C643.508972,940.403870 644.450317,939.769226 645.742188,939.199097 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M82.901703,847.374268 
	C83.284752,846.993835 83.629036,847.015869 84.265121,847.339722 
	C84.543480,848.046753 84.530037,848.451843 84.373642,849.096191 
	C84.136467,849.883545 84.042229,850.431763 83.720230,850.988403 
	C83.492462,850.996826 83.036621,851.000671 83.036621,851.000671 
	C82.978729,849.926025 82.920837,848.851440 82.901703,847.374268 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M493.718506,940.127930 
	C494.048859,940.596252 494.026886,941.005127 494.002991,941.722656 
	C493.438965,942.171936 492.876862,942.312622 492.156433,942.256165 
	C491.994324,941.632507 491.990479,941.205994 491.964630,940.462219 
	C492.417114,940.119446 492.891663,940.093933 493.718506,940.127930 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M103.944992,1057.563965 
	C103.248260,1057.933228 102.495735,1057.981201 101.371231,1057.958496 
	C101.962563,1057.672729 102.925880,1057.457764 103.944992,1057.563965 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2445.494141,1039.102173 
	C2445.066162,1038.861816 2445.080322,1038.664307 2445.122070,1038.170166 
	C2447.042480,1038.054443 2448.935303,1038.235352 2451.117188,1038.730103 
	C2449.583252,1039.077637 2447.759521,1039.111328 2445.494141,1039.102173 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1933.377197,566.230164 
	C1934.210083,565.904602 1935.336670,565.597473 1936.709229,565.495667 
	C1935.860596,565.883545 1934.765869,566.066101 1933.377197,566.230164 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1666.362427,910.959717 
	C1666.888428,910.351990 1667.789062,909.799255 1668.689697,909.246582 
	C1668.854492,909.665955 1669.019409,910.085327 1669.094238,910.746338 
	C1668.248413,910.996826 1667.492798,911.005737 1666.362427,910.959717 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1102.000732,500.770691 
	C1101.600342,500.107056 1101.466675,499.284576 1101.526123,498.270508 
	C1101.902100,498.923248 1102.084717,499.767578 1102.000732,500.770691 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M246.959808,1042.935547 
	C246.439301,1041.877930 246.439301,1040.836548 246.439301,1039.795288 
	C246.754425,1039.662720 247.069534,1039.530273 247.384659,1039.397827 
	C248.014206,1040.270142 248.643768,1041.142578 249.414932,1042.417969 
	C248.864456,1042.864624 248.172379,1042.908081 246.959808,1042.935547 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1090.665039,354.974670 
	C1090.185669,355.666870 1089.374634,356.357208 1088.183228,357.032532 
	C1088.646362,356.335938 1089.489990,355.654388 1090.665039,354.974670 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M252.357239,1037.012695 
	C252.832550,1038.049438 252.904968,1039.103760 253.058578,1041.339722 
	C251.779587,1039.615845 251.182495,1038.811035 250.469543,1037.617310 
	C250.887222,1037.162231 251.420792,1037.096069 252.357239,1037.012695 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M122.403374,1024.900879 
	C122.043968,1024.506958 122.041046,1024.127686 122.052208,1023.464417 
	C122.732819,1023.409485 123.399338,1023.638428 124.065842,1023.867371 
	C123.630508,1024.216797 123.195175,1024.566162 122.403374,1024.900879 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M106.943863,404.024811 
	C106.943863,404.024811 106.501854,404.021729 106.281677,404.022644 
	C106.025047,402.949677 105.988594,401.875824 105.978348,400.400024 
	C106.004555,399.998077 106.487236,399.994141 106.760063,400.393311 
	C107.003212,401.869934 106.973534,402.947357 106.943863,404.024811 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M547.162720,942.199097 
	C547.562988,941.856201 548.129028,941.698547 548.845093,941.759644 
	C548.439514,942.113708 547.884033,942.249023 547.162720,942.199097 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1973.146240,908.167175 
	C1973.535400,907.705872 1974.072632,907.462646 1974.903320,907.352112 
	C1974.562622,907.784912 1973.928467,908.085083 1973.146240,908.167175 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1702.149170,910.398315 
	C1701.986816,910.031921 1701.982178,909.850647 1702.308594,909.354187 
	C1703.101196,909.043762 1703.562500,909.048401 1704.023926,909.053101 
	C1704.018799,909.252563 1704.013550,909.452026 1704.010010,909.952515 
	C1703.443481,910.363403 1702.875244,910.473328 1702.149170,910.398315 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M932.009705,481.011292 
	C932.474487,480.384552 932.928772,479.746094 933.697388,479.059021 
	C934.011780,479.010468 933.994141,478.993317 933.983582,479.318909 
	C933.307861,480.088959 932.642761,480.533417 931.977600,480.977844 
	C931.977600,480.977844 931.999207,480.999573 932.009705,481.011292 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M680.779541,455.005157 
	C680.294617,455.147552 679.666504,455.012207 678.973267,454.639832 
	C679.484253,454.510986 680.060364,454.619202 680.779541,455.005157 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M82.843788,851.157166 
	C83.036621,851.000671 83.492462,850.996826 83.755127,851.323853 
	C84.013031,852.100037 84.008286,852.549255 84.003532,852.998413 
	C83.832466,852.993225 83.661400,852.988098 83.233711,852.992126 
	C82.868370,852.438721 82.759659,851.876221 82.843788,851.157166 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M105.776108,437.155884 
	C106.152222,437.561279 106.316917,438.123352 106.269585,438.840088 
	C105.893265,438.434021 105.728973,437.873291 105.776108,437.155884 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1019.915405,410.900269 
	C1020.621338,410.209595 1021.354309,409.551270 1022.470642,408.883301 
	C1022.854004,408.873688 1023.027222,409.026489 1022.878052,409.309204 
	C1021.818359,410.060028 1020.907898,410.528168 1019.997437,410.996277 
	C1019.997437,410.996277 1019.942383,410.932617 1019.915405,410.900269 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1302.013916,530.026550 
	C1301.995361,529.675720 1301.976929,529.324951 1301.920654,528.719299 
	C1302.383057,528.385071 1302.883423,528.305603 1303.751831,528.345825 
	C1304.085571,528.798645 1304.051270,529.131897 1303.682251,529.749451 
	C1302.902954,530.031372 1302.458496,530.028992 1302.013916,530.026550 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2314.367676,272.438843 
	C2313.943115,272.328644 2313.686279,272.060547 2313.429443,271.792419 
	C2313.541504,271.687073 2313.743896,271.483307 2313.752197,271.490784 
	C2314.024902,271.741760 2314.278809,272.012817 2314.367676,272.438843 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1737.332275,910.050720 
	C1737.657104,909.850708 1738.313232,909.677185 1738.980469,909.775269 
	C1738.548950,910.056946 1738.106201,910.067078 1737.332275,910.050720 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1745.333740,910.024170 
	C1745.119629,909.587463 1745.239258,909.172302 1745.358887,908.757141 
	C1745.825195,908.990417 1746.291382,909.223755 1746.877930,909.696716 
	C1746.554443,909.972778 1746.110962,910.009277 1745.333740,910.024170 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M376.930664,175.389328 
	C377.108429,175.072632 377.297394,175.067078 377.770355,175.054428 
	C377.992279,175.792496 377.930267,176.537689 377.868225,177.282867 
	C377.682526,177.293686 377.496796,177.304520 377.311066,177.315338 
	C377.188019,176.777054 377.064941,176.238770 376.930664,175.389328 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M377.990051,166.638763 
	C377.973877,167.018570 377.482239,166.998932 377.235901,166.995148 
	C376.984070,166.244904 376.978577,165.498428 376.983002,164.378845 
	C376.992920,164.005753 377.482086,164.000137 377.725616,163.989288 
	C377.981506,164.738617 377.993866,165.498795 377.990051,166.638763 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M962.678894,456.941772 
	C962.515808,457.286438 962.042297,457.622681 961.258728,457.969238 
	C961.421875,457.630829 961.895203,457.282135 962.678894,456.941772 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2384.401367,1038.772217 
	C2384.755615,1038.948608 2384.843018,1039.133301 2384.994629,1039.617432 
	C2384.570312,1039.959717 2384.082031,1040.002686 2383.238770,1039.934937 
	C2382.883789,1039.824219 2382.946045,1039.375244 2383.008301,1039.156494 
	C2383.425293,1038.885254 2383.780029,1038.832886 2384.401367,1038.772217 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1024.018311,407.012360 
	C1024.429443,406.647156 1024.840698,406.281921 1025.522461,405.840576 
	C1025.793213,405.764465 1025.898315,405.887909 1025.790039,406.199463 
	C1025.120117,406.673035 1024.558472,406.835083 1024.000732,407.000153 
	C1024.004395,407.003174 1024.018311,407.012360 1024.018311,407.012360 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1022.764099,408.801208 
	C1022.955872,408.247162 1023.237549,407.765533 1023.768799,407.148132 
	C1024.018311,407.012360 1024.004395,407.003174 1023.988098,407.301941 
	C1023.656921,408.075958 1023.342102,408.551239 1023.027222,409.026489 
	C1023.027222,409.026489 1022.854004,408.873688 1022.764099,408.801208 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1814.261353,909.009705 
	C1814.186157,908.548462 1814.362305,908.120911 1814.538574,907.693359 
	C1814.687744,907.972473 1814.836914,908.251648 1815.002930,908.786499 
	C1814.850708,909.042603 1814.681641,909.042969 1814.261353,909.009705 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2027.866211,907.547424 
	C2028.030884,907.755676 2028.241211,908.246033 2028.212158,908.854980 
	C2027.952393,908.592163 2027.932129,908.210876 2027.866211,907.547424 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M107.946960,678.398376 
	C108.208847,678.033569 108.429703,678.062805 108.976562,678.178284 
	C109.035568,678.846252 108.768570,679.428101 108.225906,680.199951 
	C107.935463,679.857483 107.920700,679.324890 107.946960,678.398376 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2059.287598,1237.934204 
	C2059.136963,1237.515625 2059.220703,1237.121216 2059.304199,1236.726807 
	C2059.497559,1236.976807 2059.691162,1237.226929 2059.937988,1237.733276 
	C2059.991699,1237.989624 2059.521729,1237.958374 2059.287598,1237.934204 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2055.780273,1235.193359 
	C2055.812988,1235.254883 2055.798096,1235.303467 2055.829102,1235.290771 
	C2055.859863,1235.278076 2055.747803,1235.131958 2055.780273,1235.193359 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2339.231934,724.742798 
	C2339.602783,724.803772 2339.853027,725.008850 2340.099365,725.563232 
	C2340.143066,725.854126 2340.003418,725.995544 2339.783691,725.886963 
	C2339.290039,725.577759 2339.103027,725.315247 2339.002686,724.990845 
	C2339.002686,724.990845 2339.158936,724.828613 2339.231934,724.742798 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2340.210449,725.781128 
	C2340.583496,725.825195 2340.850098,726.001648 2341.227539,726.425537 
	C2341.377686,726.613586 2341.176514,726.821289 2340.857178,726.809937 
	C2340.270020,726.590576 2340.091797,726.322937 2340.003418,725.995544 
	C2340.003418,725.995544 2340.143066,725.854126 2340.210449,725.781128 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2344.113770,730.895874 
	C2344.465332,730.972046 2344.719971,731.162415 2345.018555,731.650513 
	C2345.122314,731.867981 2345.063721,731.929443 2344.805176,731.869446 
	C2344.261230,731.607971 2344.079834,731.336853 2344.002197,730.996094 
	C2344.002197,730.996094 2344.077148,730.929810 2344.113770,730.895874 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2318.241211,703.755188 
	C2318.702881,703.723938 2318.948486,703.928528 2319.121826,704.544006 
	C2319.089355,704.907043 2318.786621,705.214050 2318.586914,705.096741 
	C2318.132080,704.799377 2317.911865,704.583435 2317.727051,704.331543 
	C2317.839844,704.202087 2317.952637,704.072693 2318.241211,703.755188 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2319.241455,704.754272 
	C2319.677979,704.754944 2319.930664,704.949646 2320.121094,705.545410 
	C2320.090820,705.905212 2319.782959,706.215637 2319.582275,706.085938 
	C2319.128662,705.752625 2318.930420,705.505249 2318.786621,705.214050 
	C2318.786621,705.214050 2319.089355,704.907043 2319.241455,704.754272 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2320.246094,705.751343 
	C2320.686035,705.750854 2320.936523,705.947449 2321.124512,706.544006 
	C2321.096191,706.900635 2320.806152,707.195740 2320.598145,707.075317 
	C2320.131592,706.754700 2319.929199,706.508301 2319.782959,706.215637 
	C2319.782959,706.215637 2320.090820,705.905212 2320.246094,705.751343 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2321.242188,706.754089 
	C2321.665771,706.769104 2321.913330,706.967957 2322.116455,707.548584 
	C2322.102295,707.893005 2321.830811,708.167725 2321.624512,708.047058 
	C2321.161377,707.726929 2320.957275,707.483337 2320.806152,707.195740 
	C2320.806152,707.195740 2321.096191,706.900635 2321.242188,706.754089 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2322.237793,707.755432 
	C2322.656982,707.773621 2322.910400,707.968933 2323.114746,708.553040 
	C2323.096191,708.902222 2322.805908,709.191406 2322.605469,709.059448 
	C2322.152344,708.720398 2321.960938,708.467102 2321.830811,708.167725 
	C2321.830811,708.167725 2322.102295,707.893005 2322.237793,707.755432 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2323.242188,708.758423 
	C2323.673828,708.779175 2323.912354,708.995972 2324.222656,709.476074 
	C2324.341797,709.687073 2324.097656,709.906799 2323.754395,709.920898 
	C2323.139893,709.743958 2322.937988,709.496155 2322.805908,709.191406 
	C2322.805908,709.191406 2323.096191,708.902222 2323.242188,708.758423 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2374.223145,767.684998 
	C2374.017334,767.799744 2373.657715,767.654297 2373.175781,767.239502 
	C2373.392090,767.121704 2373.730469,767.273254 2374.223145,767.684998 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2355.165771,743.819092 
	C2355.572754,743.817932 2355.853760,743.985352 2356.090088,744.570740 
	C2356.092529,744.908936 2355.847168,745.156677 2355.630371,745.039551 
	C2355.139160,744.709229 2354.978760,744.430542 2354.931885,744.086426 
	C2354.931885,744.086426 2355.087402,743.907959 2355.165771,743.819092 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2356.215332,744.785461 
	C2356.614502,744.839111 2356.841553,745.065247 2357.129395,745.554749 
	C2357.238525,745.769226 2357.074707,745.923950 2356.768555,745.909180 
	C2356.188232,745.705933 2355.983398,745.459961 2355.847168,745.156677 
	C2355.847168,745.156677 2356.092529,744.908936 2356.215332,744.785461 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1036.961182,397.956360 
	C1037.118286,397.661865 1037.293335,397.387695 1037.706299,397.032410 
	C1037.944092,396.951324 1037.981567,396.983551 1037.904663,397.231781 
	C1037.629395,397.768707 1037.358521,397.947693 1037.015259,398.017029 
	C1037.015259,398.017029 1036.979126,397.976624 1036.961182,397.956360 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1639.004272,910.006775 
	C1639.002441,910.175110 1639.000488,910.343445 1638.995605,910.764709 
	C1638.992432,911.017639 1638.501953,911.050720 1638.256714,911.039124 
	C1638.013794,910.842896 1638.015991,910.658325 1638.272949,910.208984 
	C1638.527466,909.944214 1639.004272,910.006775 1639.004272,910.006775 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1732.194824,910.111694 
	C1732.226196,909.960144 1732.524414,909.828003 1732.908447,909.888062 
	C1732.816650,910.097168 1732.639160,910.114136 1732.194824,910.111694 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1981.209229,907.900940 
	C1981.223511,907.755859 1981.542114,907.639954 1981.988159,907.675049 
	C1981.914917,907.860718 1981.714233,907.895386 1981.209229,907.900940 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1798.257080,909.062744 
	C1798.019409,908.852112 1798.020874,908.661377 1798.249512,908.162354 
	C1798.476929,907.853943 1798.909912,907.840088 1798.909912,907.840088 
	C1798.926025,908.042664 1798.942139,908.245178 1798.966797,908.753174 
	C1798.975342,909.058594 1798.496216,909.082581 1798.257080,909.062744 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1837.294434,909.084412 
	C1837.320557,908.962952 1837.559692,908.850037 1837.865723,908.901428 
	C1837.932617,909.065796 1837.507324,909.092896 1837.294434,909.084412 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M952.720093,465.020477 
	C952.796387,465.235046 952.612122,465.509918 952.194458,465.882629 
	C952.127197,465.680573 952.293335,465.380676 952.720093,465.020477 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M952.900635,464.179138 
	C953.063721,464.211090 953.194702,464.511017 953.137329,464.895874 
	C952.922119,464.802917 952.895386,464.625000 952.900635,464.179138 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M956.743713,461.939240 
	C956.726013,462.076111 956.462891,462.208069 956.106628,462.176147 
	C956.013489,462.012238 956.498291,461.934357 956.743713,461.939240 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M957.720215,461.032349 
	C957.791504,461.241821 957.606079,461.517822 957.185425,461.892273 
	C957.121338,461.693451 957.292419,461.396179 957.720215,461.032349 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M937.841553,476.841614 
	C937.900024,476.463959 938.103027,476.217926 938.542114,475.865631 
	C938.736084,475.724609 938.935974,475.942749 938.911255,476.252075 
	C938.688782,476.812164 938.443665,477.001587 938.151184,477.129700 
	C938.151184,477.129700 937.943970,476.938507 937.841553,476.841614 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2412.891602,837.162231 
	C2413.129639,836.826355 2413.367676,836.490479 2413.883789,836.005798 
	C2414.162354,835.856995 2414.348633,836.282898 2414.243164,836.683350 
	C2414.137695,837.083801 2413.875244,837.507141 2413.641602,837.385315 
	C2413.236084,837.229736 2413.063721,837.195923 2412.891602,837.162231 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2414.311523,836.905518 
	C2414.724854,836.908936 2414.903564,837.137878 2415.102539,837.619568 
	C2415.183594,837.825073 2414.916504,838.059570 2414.633789,838.064087 
	C2414.159424,837.909424 2414.000977,837.722229 2413.875244,837.507141 
	C2413.875244,837.507141 2414.137695,837.083801 2414.311523,836.905518 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M958.712280,460.022247 
	C958.794678,460.244324 958.616028,460.521149 958.200684,460.887054 
	C958.126404,460.676453 958.288818,460.376709 958.712280,460.022247 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M959.716064,459.017273 
	C959.798767,459.239441 959.620239,459.517120 959.204712,459.884277 
	C959.130005,459.673401 959.292358,459.373077 959.716064,459.017273 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M960.749878,458.039185 
	C960.808167,458.225586 960.621094,458.502533 960.199585,458.879547 
	C960.144958,458.696320 960.324707,458.412994 960.749878,458.039185 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M214.922119,1243.951904 
	C219.826126,1243.325806 224.723572,1242.410767 229.639297,1242.298950 
	C230.719406,1242.274292 231.864471,1245.104126 232.785385,1246.355591 
	C233.261871,1242.468384 236.686584,1242.197266 240.468628,1242.264282 
	C246.219360,1242.366333 251.973221,1242.291138 258.960754,1242.291138 
	C259.145416,1244.044800 259.387848,1246.346924 259.630280,1248.649048 
	C257.317963,1248.844360 255.008545,1249.090088 252.692673,1249.224121 
	C248.671570,1249.456909 244.647568,1249.741333 240.622711,1249.778564 
	C236.708801,1249.814453 232.221924,1252.109985 229.285797,1247.197754 
	C229.138138,1246.950562 226.684387,1248.082031 224.656860,1248.801270 
	C223.873138,1248.342407 222.489304,1244.019409 220.329697,1248.841309 
	C220.180969,1249.173462 217.244446,1248.256958 215.274063,1247.428467 
	C214.936539,1245.941162 214.929321,1244.946533 214.922119,1243.951904 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M451.562378,1242.060059 
	C456.415741,1241.033081 460.993408,1240.187378 465.591797,1239.475098 
	C466.509521,1239.333008 467.557831,1239.964111 468.457581,1239.787720 
	C472.382843,1239.018433 474.950775,1239.324219 472.990448,1244.384155 
	C472.809814,1244.850586 473.685364,1245.725952 473.936218,1246.172485 
	C471.232758,1246.612183 468.662567,1247.029541 466.092804,1247.449829 
	C465.929535,1247.476440 465.711334,1247.612549 465.616638,1247.549927 
	C461.722107,1244.970581 457.343964,1245.724609 453.076233,1245.694580 
	C452.337982,1245.689453 451.449188,1245.237183 450.887329,1245.514038 
	C446.913910,1247.471558 443.006622,1246.156494 438.990295,1245.793091 
	C437.267883,1245.637329 435.362640,1247.502686 432.687500,1248.892944 
	C432.524078,1245.410767 432.420074,1243.194092 432.248932,1239.546753 
	C435.526123,1240.429077 438.149445,1241.135498 441.285187,1241.979736 
	C441.296936,1241.963867 441.958801,1241.072144 442.879822,1239.831177 
	C444.069519,1241.197144 445.091766,1242.370728 446.494598,1243.981323 
	C446.823303,1242.650024 447.061096,1241.686890 447.363464,1240.462280 
	C448.710419,1241.088013 450.002197,1241.688232 451.562378,1242.060059 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M101.147087,1114.156860 
	C104.850342,1113.932861 108.553604,1113.708862 113.343307,1113.419189 
	C112.398064,1115.727051 111.738243,1117.338013 110.868111,1119.462402 
	C112.184540,1118.833130 114.113327,1118.519653 114.368576,1117.690918 
	C116.089966,1112.100952 120.055984,1113.646973 123.918198,1114.224854 
	C126.642937,1114.632568 129.432281,1114.859375 132.182739,1114.786987 
	C136.576035,1114.671265 140.961014,1114.239502 145.699615,1114.270142 
	C146.003189,1115.120117 145.956665,1115.641113 145.585281,1115.967773 
	C144.136215,1116.177490 143.012009,1116.581665 141.592987,1116.841675 
	C140.609955,1116.755859 139.921738,1116.814087 139.233521,1116.872437 
	C139.592819,1117.663574 139.952133,1118.454834 140.311462,1119.245972 
	C136.297043,1120.553711 132.422760,1123.364014 128.174881,1119.177734 
	C127.505409,1118.517944 124.323593,1120.096802 122.483398,1121.005127 
	C119.359894,1122.547119 117.492119,1122.030273 117.048752,1117.758911 
	C113.567551,1123.283813 109.254242,1122.638306 104.334396,1119.832275 
	C104.193321,1118.447998 104.394348,1117.564819 104.633385,1116.514526 
	C101.574608,1118.084961 100.598579,1117.119019 101.147087,1114.156860 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M377.037720,1195.032959 
	C375.695557,1195.642212 374.354553,1196.757812 373.010895,1196.760986 
	C369.661865,1196.769165 366.017151,1199.246094 362.894653,1195.689697 
	C362.490570,1195.229492 360.406647,1195.959961 359.260040,1196.485962 
	C354.452881,1198.691040 352.508636,1198.203857 351.005035,1193.820312 
	C348.013947,1198.875488 343.954651,1198.994141 339.373352,1196.237061 
	C339.565155,1195.556885 339.775665,1195.290283 339.986206,1195.023682 
	C344.333984,1196.488892 347.900421,1196.590820 349.891388,1191.188599 
	C350.274323,1190.149658 354.019135,1189.349854 355.633148,1189.963989 
	C359.508270,1191.437988 363.151886,1190.500488 366.945770,1190.331909 
	C372.908264,1190.067139 378.891144,1190.261597 385.890076,1190.261597 
	C385.178650,1191.632935 384.320984,1193.286011 383.541077,1194.789429 
	C383.744812,1194.863281 384.892029,1195.279419 386.268188,1195.778687 
	C382.653687,1198.434692 380.792969,1198.362793 377.435455,1195.396851 
	C378.412872,1194.273071 379.069977,1193.460083 379.727081,1192.647095 
	C379.224792,1192.381714 378.722504,1192.116211 378.220154,1191.850830 
	C377.826019,1192.911499 377.431885,1193.972290 377.037720,1195.032959 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M261.014832,1080.005371 
	C261.249512,1080.897705 261.484192,1081.789917 261.122131,1082.855469 
	C258.724304,1084.046143 256.923248,1085.063599 255.122177,1086.081055 
	C252.685532,1086.483154 249.871399,1088.385010 248.533203,1084.422729 
	C247.742111,1084.762329 246.951019,1085.101807 245.790878,1085.599854 
	C245.475174,1084.278076 245.156448,1082.943726 244.542252,1080.372314 
	C244.110977,1082.894897 243.895599,1084.154785 243.775146,1084.859375 
	C241.072113,1085.414551 238.603073,1086.519287 236.280045,1086.268921 
	C232.023087,1085.810181 227.489197,1088.581299 223.387283,1085.036255 
	C222.599670,1084.355469 220.410812,1085.296143 218.394836,1085.547852 
	C218.394836,1084.029053 218.394836,1081.870605 218.394836,1080.401001 
	C221.872681,1080.125610 225.125946,1080.293335 228.140030,1079.523926 
	C232.099518,1078.513306 234.463898,1079.407715 235.371017,1084.816895 
	C236.116394,1082.116699 236.517471,1080.663574 237.027206,1078.817017 
	C242.850967,1078.817017 248.599762,1078.817017 254.749786,1079.316406 
	C257.105652,1079.879150 259.060242,1079.942261 261.014832,1080.005371 
M248.603638,1081.599121 
	C248.603638,1081.599121 248.309296,1081.445557 248.309296,1081.445557 
	C248.309296,1081.445557 248.370636,1081.811890 248.603638,1081.599121 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M100.977142,1149.187012 
	C104.233887,1147.579346 107.168129,1146.411377 111.159721,1148.196289 
	C113.604095,1149.289551 117.221878,1148.208008 120.198441,1147.570190 
	C126.722015,1146.172119 132.454956,1147.170776 137.679977,1152.894897 
	C135.604919,1153.738770 133.660645,1155.114014 131.637543,1155.241821 
	C125.718063,1155.615845 119.766304,1155.566162 113.829521,1155.493408 
	C112.830757,1155.481201 111.824242,1154.561157 110.857407,1153.998535 
	C110.169289,1153.598145 109.458466,1152.616089 108.900818,1152.713379 
	C105.492882,1153.307861 102.125824,1154.136597 98.342995,1154.680664 
	C97.917686,1154.258789 97.912392,1154.042969 97.924995,1153.826660 
	C98.465485,1153.381958 99.005966,1152.937134 100.079407,1152.376465 
	C101.216705,1152.340210 101.821037,1152.419678 102.425362,1152.499146 
	C101.942619,1151.395142 101.459885,1150.291016 100.977142,1149.187012 
M119.099327,1152.922729 
	C120.164047,1152.855103 121.228775,1152.787598 122.293503,1152.719971 
	C122.275322,1152.506592 122.257149,1152.293213 122.238976,1152.079834 
	C121.022102,1152.217407 119.805237,1152.355103 119.099327,1152.922729 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M236.965637,1163.984863 
	C232.767853,1165.203857 228.493744,1167.282837 225.056793,1162.350220 
	C224.710495,1161.853149 222.801788,1161.684082 222.392166,1162.109009 
	C218.094788,1166.566772 212.083664,1163.873657 207.199066,1165.843750 
	C206.254379,1166.224854 203.985611,1165.684448 203.730377,1165.025757 
	C202.214569,1161.112915 200.779129,1162.538574 198.462143,1164.386108 
	C196.877380,1165.649780 194.438919,1165.842773 192.291061,1166.544067 
	C190.752243,1158.784790 190.752365,1158.786865 197.138931,1158.414062 
	C200.607574,1158.211670 204.075745,1158.000977 206.917496,1158.838257 
	C209.003815,1160.581665 210.882553,1163.122803 213.261459,1163.741699 
	C214.505585,1164.065430 216.628479,1161.011353 219.092834,1158.828979 
	C222.898499,1158.828979 228.081024,1158.828979 234.245743,1158.828979 
	C232.382629,1159.715698 230.788986,1160.474121 229.195343,1161.232544 
	C229.551483,1161.797241 229.907623,1162.362061 230.263763,1162.926880 
	C231.967896,1162.926880 233.672012,1162.926880 235.823853,1163.206177 
	C236.449722,1163.725952 236.681076,1163.892334 236.965637,1163.984863 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M157.774109,1154.986938 
	C152.676315,1154.773926 147.956100,1154.562622 142.045761,1154.298218 
	C142.670273,1152.161743 142.704285,1148.404419 144.187073,1147.700928 
	C147.708267,1146.030518 151.236313,1147.149658 152.390198,1152.477051 
	C153.769821,1147.362793 157.266891,1147.861206 160.735046,1147.504150 
	C167.164246,1146.842529 173.609390,1149.380493 180.030075,1147.195435 
	C180.765778,1146.945068 182.838318,1148.148315 182.856430,1148.722778 
	C182.913361,1150.528198 182.901978,1153.038696 181.797485,1154.034424 
	C179.332626,1156.256836 171.671951,1153.554688 170.316177,1150.253540 
	C170.037430,1151.494141 169.793335,1152.580444 169.411926,1154.277832 
	C168.458084,1152.059814 167.741638,1150.393921 167.025192,1148.728027 
	C166.607544,1148.823364 166.189896,1148.918701 165.772247,1149.013916 
	C165.672195,1150.299438 165.572144,1151.584839 165.445007,1153.218262 
	C163.937103,1151.458252 162.839676,1149.053223 161.505569,1148.913574 
	C159.951614,1148.751343 158.190506,1150.571167 156.519287,1151.530640 
	C156.739059,1151.951538 156.958847,1152.372314 157.178635,1152.793213 
	C158.587128,1152.464233 159.995621,1152.135376 161.404114,1151.806396 
	C161.547287,1152.033447 161.690460,1152.260620 161.833633,1152.487671 
	C160.606339,1153.321411 159.379028,1154.155151 157.774109,1154.986938 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M426.564209,1239.938477 
	C426.677795,1241.870117 426.381409,1243.733398 426.034149,1245.916382 
	C425.653564,1245.783081 426.673248,1246.140015 427.692932,1246.496948 
	C427.695587,1246.794556 427.698242,1247.092163 427.700897,1247.389771 
	C426.528503,1247.611206 424.957977,1248.431152 424.247253,1247.958496 
	C419.017822,1244.479858 413.928284,1247.868652 408.855774,1248.209961 
	C405.690277,1248.422974 402.509216,1245.844360 399.248871,1248.349121 
	C398.849304,1248.656006 396.598480,1246.553223 395.151917,1245.529175 
	C394.449463,1246.292236 393.595978,1247.219360 392.289307,1248.638672 
	C391.009552,1246.113525 389.963593,1244.049683 388.917633,1241.985840 
	C389.221283,1241.782837 389.524933,1241.579956 389.828583,1241.376953 
	C390.692688,1242.181274 391.556793,1242.985474 392.476379,1243.841309 
	C395.967590,1238.891357 400.827057,1240.769409 405.144043,1241.202271 
	C405.909363,1242.816528 406.617676,1244.310669 407.325989,1245.804688 
	C408.396057,1244.275269 409.466095,1242.745728 410.710358,1240.967163 
	C411.294342,1242.171387 411.870117,1243.358643 412.634277,1244.934326 
	C413.094391,1243.123047 413.442169,1241.754028 413.915619,1239.890137 
	C415.629028,1240.804321 416.994385,1241.532715 417.347565,1241.721069 
	C420.880676,1240.978516 423.517456,1240.424316 426.564209,1239.938477 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M404.294373,1219.576294 
	C398.613159,1216.785156 393.243835,1217.556396 386.955109,1220.878052 
	C387.258453,1217.359009 386.760498,1213.726685 387.788940,1213.225220 
	C389.739197,1212.274414 392.567444,1213.239746 395.025055,1213.177002 
	C396.891937,1213.129517 398.745361,1212.556641 400.612274,1212.508667 
	C404.794464,1212.401001 408.981293,1212.474976 413.250977,1212.474976 
	C413.250977,1213.718872 413.250977,1215.124146 413.250977,1216.829712 
	C415.179901,1214.557861 416.438446,1212.900146 417.943665,1211.509399 
	C418.147278,1211.321289 419.589325,1212.473511 420.457886,1213.005127 
	C419.597748,1214.266724 418.737579,1215.528320 417.903656,1216.751465 
	C418.430298,1216.927734 419.453827,1217.270142 420.477325,1217.612671 
	C420.450989,1218.192505 420.424652,1218.772339 420.398315,1219.352173 
	C417.797119,1219.192017 415.170105,1219.205566 412.601776,1218.824097 
	C409.856628,1218.416382 407.105927,1216.803833 404.294373,1219.576294 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M118.240524,1215.224365 
	C120.886665,1214.806030 123.141769,1214.540039 126.767815,1214.112183 
	C125.583939,1216.662964 124.849236,1218.245972 124.071007,1219.922729 
	C124.311821,1220.070068 125.227150,1220.630005 126.353828,1221.319214 
	C125.741814,1221.880859 125.402214,1222.453125 125.086838,1222.439941 
	C122.814682,1222.346069 120.548660,1222.013794 118.278275,1221.990356 
	C115.845383,1221.965210 113.410469,1222.211182 110.975197,1222.307617 
	C109.716972,1222.357544 107.948196,1222.883423 107.282333,1222.261108 
	C104.017166,1219.209106 101.103775,1220.721069 97.849579,1222.175415 
	C96.669991,1222.702637 93.847504,1222.338623 93.649506,1221.750244 
	C93.078514,1220.053589 93.192383,1217.961792 93.673729,1216.198975 
	C93.785263,1215.790527 96.435356,1216.126099 97.911240,1216.027588 
	C100.265968,1215.870117 102.612236,1215.541748 104.968201,1215.458618 
	C107.665993,1215.363159 110.370499,1215.469482 113.072029,1215.463135 
	C114.664581,1215.459351 116.257004,1215.407104 118.240524,1215.224365 
M119.969963,1219.788940 
	C120.116348,1219.396240 120.262733,1219.003540 120.125412,1217.916016 
	C119.297867,1217.916016 118.470314,1217.916016 117.642769,1217.916016 
	C117.623489,1218.189331 117.604218,1218.462769 117.584938,1218.736084 
	C118.244865,1219.007568 118.904785,1219.278931 119.969963,1219.788940 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M129.091766,1262.070312 
	C126.901581,1261.957764 124.703796,1261.921753 122.523972,1261.704956 
	C121.139809,1261.567383 119.782341,1261.161377 117.662094,1260.719116 
	C117.560280,1260.311768 117.217781,1258.941406 116.577164,1256.378296 
	C116.016006,1258.893188 115.723755,1260.203003 115.192345,1262.584717 
	C114.310097,1260.421997 113.811279,1259.199219 113.194992,1257.688477 
	C111.855759,1258.301392 110.734375,1258.814575 109.507156,1259.376221 
	C110.319244,1260.111450 110.996727,1260.724854 111.674210,1261.338135 
	C111.540749,1261.845093 111.407288,1262.351929 111.273827,1262.858765 
	C108.602303,1262.347168 105.930786,1261.835449 102.737762,1261.223999 
	C101.371193,1261.164307 99.328438,1261.075073 97.285675,1260.985718 
	C97.223030,1260.583008 97.160393,1260.180176 97.097748,1259.777344 
	C96.106339,1260.688354 95.114937,1261.599365 93.532654,1263.053223 
	C93.532654,1260.039062 93.532654,1257.866821 93.532654,1256.647095 
	C99.674469,1255.971802 105.395142,1254.843994 111.114380,1254.851196 
	C117.123138,1254.858887 123.140366,1255.332031 129.561371,1255.340454 
	C129.918503,1255.856445 129.877716,1256.031982 129.567093,1256.363403 
	C127.958351,1257.510498 126.619461,1258.501709 125.161865,1259.580688 
	C126.708542,1260.560547 127.900162,1261.315430 129.091766,1262.070312 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M331.654602,1084.004395 
	C329.769531,1083.884277 328.226624,1083.966797 325.591644,1084.107666 
	C327.267670,1082.272583 328.629089,1080.781860 329.990540,1079.291260 
	C330.647156,1080.120117 331.303802,1080.948853 332.210052,1082.092773 
	C332.725128,1081.495850 333.317322,1080.858887 333.852356,1080.176880 
	C334.475006,1079.383179 335.092407,1077.815308 335.634918,1077.848267 
	C340.949921,1078.172485 346.248840,1078.760498 352.643677,1079.379150 
	C354.315613,1078.746460 357.452332,1077.559570 360.589081,1076.372681 
	C361.050079,1076.912476 361.511047,1077.452271 361.972046,1077.992065 
	C360.931732,1079.544922 359.891388,1081.097778 358.799835,1082.727173 
	C359.371002,1082.926880 360.449524,1083.304077 361.833771,1083.788208 
	C357.269318,1086.442139 356.586853,1086.138184 355.485870,1080.963257 
	C354.322266,1082.454468 353.359985,1083.687744 352.156006,1085.230713 
	C351.076141,1084.092285 350.035828,1082.995605 348.992218,1081.895508 
	C345.124756,1085.748047 337.547729,1086.720947 331.654602,1084.004395 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M171.077484,1117.755249 
	C171.144714,1116.499146 171.211960,1115.243042 171.208923,1115.299805 
	C181.390961,1112.014404 191.266098,1113.264282 202.302444,1113.441284 
	C202.526306,1115.103882 202.837418,1117.414673 203.148529,1119.725342 
	C200.663132,1119.880615 198.178619,1120.052246 195.692001,1120.185059 
	C194.110229,1120.269775 192.418488,1119.958618 190.963669,1120.418457 
	C187.720657,1121.444092 185.091339,1121.770752 184.760284,1116.801147 
	C182.959229,1117.783691 181.624695,1118.671997 180.167450,1119.251709 
	C179.160843,1119.652344 177.987427,1119.648682 176.883682,1119.786621 
	C175.082306,1120.011841 173.276978,1120.205322 171.224136,1119.956299 
	C171.009140,1118.918823 171.043304,1118.337036 171.077484,1117.755249 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M453.095428,1127.421387 
	C448.337280,1127.968140 443.591156,1128.732788 438.817383,1128.997070 
	C434.511200,1129.235352 430.159546,1129.110962 425.859650,1128.759277 
	C424.325073,1128.633667 422.883392,1127.372559 420.257385,1126.058350 
	C419.974640,1126.814087 419.453094,1128.208374 418.931549,1129.602539 
	C418.493744,1129.353882 418.055969,1129.105225 417.618164,1128.856567 
	C417.503357,1126.937378 417.388580,1125.018066 417.224731,1122.279419 
	C419.974762,1125.561523 422.227142,1125.053955 424.081482,1121.855225 
	C424.662201,1123.112915 424.995453,1123.834717 425.492828,1124.911987 
	C426.507355,1123.784424 427.406555,1122.784912 428.473846,1121.598633 
	C429.492188,1122.747192 430.334747,1123.697510 431.198883,1124.672119 
	C434.164764,1120.585205 444.719727,1120.829590 448.520477,1125.124756 
	C449.069519,1124.102295 449.577301,1123.156616 449.840393,1122.666748 
	C450.702911,1123.311157 451.558777,1123.950684 452.727875,1124.968018 
	C453.059204,1126.037720 453.077332,1126.729614 453.095428,1127.421387 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M212.139221,1197.594482 
	C206.830399,1198.255249 201.536377,1199.145020 196.207108,1199.489136 
	C193.121796,1199.688354 189.979767,1199.009521 186.617676,1198.703369 
	C184.943634,1191.866211 185.453842,1191.828247 193.064377,1192.706665 
	C198.142426,1193.292603 203.431946,1191.991455 208.632141,1191.606689 
	C210.229401,1191.488403 211.844238,1191.607788 213.698532,1192.273926 
	C213.343781,1194.484619 212.741516,1196.039551 212.139221,1197.594482 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M333.337738,1146.633667 
	C334.277100,1146.227661 335.336090,1145.785767 335.776276,1146.131470 
	C338.290710,1148.106689 340.657867,1150.269287 343.125458,1152.411865 
	C343.335052,1152.279785 342.701172,1152.984741 342.330933,1152.866211 
	C337.694214,1151.382080 332.944519,1151.881836 328.210022,1151.768433 
	C325.374756,1151.700562 321.841919,1153.624146 319.935486,1149.664429 
	C318.896301,1150.254639 317.919373,1151.039551 316.805359,1151.395752 
	C314.248657,1152.212891 311.625122,1152.820923 309.028717,1153.513794 
	C308.886902,1152.915405 308.745087,1152.316895 308.603302,1151.718384 
	C309.862366,1151.316895 311.121429,1150.915405 312.700043,1150.412109 
	C311.062317,1150.001709 309.743134,1149.671021 306.672119,1148.901489 
	C309.040527,1147.138184 310.321838,1145.376587 311.694275,1145.302368 
	C317.554138,1144.985840 323.440857,1145.167725 329.670441,1145.167725 
	C329.883728,1146.880127 330.070801,1148.381836 330.390900,1150.951660 
	C331.643188,1149.154785 332.407379,1148.058228 333.337738,1146.633667 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M153.289520,1255.568359 
	C155.714508,1255.489990 157.955231,1254.692871 159.774933,1255.255981 
	C162.605148,1256.131958 164.959747,1257.993164 167.691071,1254.765747 
	C168.169464,1254.200439 171.181427,1255.779053 173.326019,1256.464111 
	C173.326019,1257.454712 173.326019,1258.911987 173.326019,1261.007568 
	C170.967484,1258.761597 166.423584,1264.873169 165.717453,1257.281372 
	C163.584946,1259.025757 162.174316,1261.026245 160.383575,1261.460938 
	C157.089661,1262.260620 153.772522,1263.238647 150.288788,1260.368530 
	C148.694687,1259.055298 144.949524,1260.352783 142.565201,1260.450562 
	C142.296097,1261.539551 142.378265,1261.398926 142.352798,1261.281494 
	C141.948135,1259.417114 140.595428,1256.994873 141.307236,1255.809692 
	C142.827560,1253.278076 144.591446,1254.759521 146.687943,1256.244629 
	C147.946960,1257.136475 150.787033,1255.796387 153.289520,1255.568359 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M166.972748,1169.829346 
	C165.339371,1171.832031 161.454636,1172.924194 164.773117,1176.339111 
	C162.053680,1176.339111 159.348801,1175.815186 156.968765,1176.470703 
	C153.931915,1177.306885 151.222778,1178.869995 149.751663,1174.535034 
	C149.676041,1174.312378 149.039719,1174.033203 148.995850,1174.081543 
	C145.794830,1177.604736 141.976013,1175.081055 138.436569,1175.182739 
	C137.309875,1175.215088 136.182648,1175.229126 135.055664,1175.251587 
	C135.145294,1173.824707 135.234940,1172.397827 135.324585,1170.970947 
	C136.563248,1171.212524 137.803925,1171.444458 139.039200,1171.702148 
	C139.350388,1171.767090 139.644257,1171.914917 138.786499,1171.603027 
	C148.641388,1168.512451 157.799408,1170.384521 166.972748,1169.829346 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M242.460953,1127.523193 
	C241.264496,1128.297974 240.068024,1129.072754 238.689209,1129.965698 
	C238.495773,1129.415771 238.088364,1128.257812 237.577652,1126.806152 
	C236.116226,1127.288574 234.770889,1127.732788 232.985825,1128.322144 
	C234.127350,1129.185303 234.954575,1129.810913 236.814484,1131.217285 
	C234.273895,1131.470825 232.781738,1131.712524 231.284546,1131.749512 
	C228.426422,1131.819946 225.546738,1131.968384 222.713089,1131.697021 
	C221.188354,1131.551025 219.672272,1130.728271 218.280182,1129.974609 
	C217.225708,1129.403809 216.373611,1128.459229 214.995453,1127.320801 
	C214.351089,1129.105347 213.937134,1130.251709 213.523178,1131.398193 
	C213.227356,1131.422607 212.931534,1131.447021 212.635712,1131.471436 
	C212.420563,1129.979126 211.830887,1128.412231 212.108398,1127.018066 
	C212.331497,1125.897217 213.620834,1124.112427 214.408615,1124.127441 
	C218.447861,1124.203491 222.478882,1124.757202 226.510071,1125.170654 
	C227.088715,1125.229980 227.670334,1125.601318 228.220566,1125.543457 
	C232.852661,1125.055786 237.477753,1124.501587 242.517395,1124.460449 
	C242.773544,1125.810181 242.617249,1126.666626 242.460953,1127.523193 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M422.010498,1158.034180 
	C423.986603,1154.475220 426.613403,1154.359985 430.378387,1157.667114 
	C434.322144,1154.506714 442.428772,1154.322632 447.105804,1156.568237 
	C448.521240,1157.247803 450.895325,1155.930908 452.913147,1156.076660 
	C451.640045,1157.677246 450.284119,1158.729492 448.072052,1160.446289 
	C450.633301,1160.765625 451.863861,1160.918945 453.094452,1161.072510 
	C451.381165,1163.663696 451.381165,1163.663696 446.092926,1162.075562 
	C445.555206,1160.640625 445.013641,1159.195190 444.472046,1157.749878 
	C444.130402,1157.733521 443.788788,1157.717285 443.447174,1157.701050 
	C443.061768,1159.122559 442.676361,1160.543945 442.264008,1162.064819 
	C438.526672,1162.738037 434.545898,1165.224731 431.102905,1160.926392 
	C430.901306,1160.674561 429.298523,1161.545044 428.021667,1162.012695 
	C427.693542,1160.957520 427.274475,1159.609863 426.560059,1157.312500 
	C425.774261,1159.475098 425.288330,1160.812378 424.673096,1162.505615 
	C423.911072,1161.832275 423.338379,1161.326294 422.436218,1160.530029 
	C422.059784,1159.892090 422.012756,1159.544434 422.302673,1159.197266 
	C422.421631,1158.782715 422.203674,1158.367676 421.976990,1157.977539 
	C421.968292,1158.002563 422.010498,1158.034180 422.010498,1158.034180 
M435.718811,1160.623657 
	C440.658478,1161.446777 440.658478,1161.446777 440.140350,1158.008179 
	C438.690918,1158.649292 437.267426,1159.278809 435.718811,1160.623657 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M443.113586,1146.070557 
	C442.852417,1147.520142 442.591278,1148.969727 442.206390,1151.106323 
	C441.447632,1150.118286 440.980469,1149.510010 440.338837,1148.674438 
	C437.833893,1152.927734 434.469940,1152.883301 430.523590,1150.571289 
	C429.861389,1150.183350 428.497559,1151.162720 427.435425,1151.257568 
	C423.530243,1151.605835 419.603821,1152.122314 415.711884,1151.955933 
	C414.594330,1151.908203 412.572205,1149.658447 412.691895,1148.616943 
	C412.994080,1145.987915 414.551971,1143.863770 418.545166,1144.647095 
	C417.085449,1146.411133 415.976349,1147.751587 414.867218,1149.092041 
	C415.170197,1149.435669 415.473145,1149.779419 415.776123,1150.123169 
	C417.172119,1149.017212 418.429688,1147.621460 419.996613,1146.873535 
	C422.342438,1145.753540 425.841553,1143.648804 427.186554,1144.522705 
	C430.219025,1146.493408 432.107605,1144.967529 434.645569,1144.350098 
	C435.936646,1144.036011 437.642578,1145.427002 439.895386,1146.027588 
	C441.457733,1146.028931 442.285645,1146.049683 443.113586,1146.070557 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M47.526882,1223.458618 
	C44.880085,1222.266968 45.089630,1220.808350 46.941994,1218.937744 
	C50.184940,1215.662476 53.314846,1212.275391 56.581367,1208.841309 
	C52.552662,1204.484497 48.864956,1200.496582 44.811501,1196.113037 
	C48.811676,1191.928589 52.636776,1187.927246 56.420147,1183.969604 
	C60.666485,1188.270752 64.560051,1192.214600 68.662071,1196.369507 
	C65.732925,1199.616333 62.500751,1202.919189 59.664021,1206.532349 
	C58.916626,1207.484375 59.391556,1209.395874 59.238651,1211.357422 
	C59.037678,1211.983521 58.903233,1212.119019 58.423248,1212.117798 
	C53.951900,1213.073242 48.588978,1218.959229 48.026550,1222.981201 
	C47.856361,1223.136475 47.526882,1223.458618 47.526882,1223.458618 
M59.999992,1190.999634 
	C59.999992,1190.999634 59.983124,1191.046997 60.329567,1190.354004 
	C59.239555,1189.075928 58.149548,1187.797852 57.560261,1187.106934 
	C53.793701,1190.276245 50.530045,1193.022461 48.307888,1194.892212 
	C51.419052,1198.941895 54.101887,1202.433960 56.991688,1206.195435 
	C60.592865,1202.419800 63.518135,1199.352905 67.118599,1195.578125 
	C64.311325,1193.791016 62.131756,1192.403442 59.999992,1190.999634 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M147.407318,1096.427246 
	C147.737503,1096.444946 148.300827,1096.584351 148.364380,1096.462769 
	C151.355576,1090.739380 156.991196,1092.039429 161.883499,1091.259399 
	C166.400558,1090.539062 168.386627,1092.382446 167.867188,1097.656616 
	C166.880493,1097.265991 165.473419,1096.408813 165.236008,1096.659546 
	C161.321854,1100.792114 156.346725,1097.449463 151.962479,1098.519897 
	C149.581543,1099.101196 144.991821,1100.971313 144.589249,1095.326172 
	C142.995682,1095.843750 141.402115,1096.361450 139.150925,1097.092651 
	C139.026855,1095.990234 138.793015,1093.912476 138.480453,1091.135376 
	C142.076492,1091.813965 146.572205,1088.448975 146.640625,1095.574707 
	C146.778580,1096.193481 147.092957,1096.310303 147.407318,1096.427246 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M326.972595,1162.837280 
	C324.381622,1163.152100 321.795868,1163.523560 319.198029,1163.764771 
	C316.878357,1163.980103 314.545898,1164.063477 312.217987,1164.183228 
	C308.492035,1164.374756 304.751984,1164.761963 301.050812,1164.533081 
	C300.383728,1164.491699 299.008362,1161.402222 299.475220,1160.565186 
	C300.423828,1158.864502 302.407623,1157.741333 303.964691,1156.380127 
	C304.588593,1157.080078 305.212494,1157.780029 305.836365,1158.479980 
	C304.352020,1159.127930 302.867645,1159.775879 301.383301,1160.423706 
	C301.712616,1160.953735 302.041931,1161.483765 302.371216,1162.013672 
	C303.877563,1161.318481 305.938141,1161.021484 306.780579,1159.849121 
	C308.850403,1156.968628 311.438995,1156.647461 314.588531,1156.850464 
	C317.327637,1157.027100 320.106842,1156.565308 322.848755,1156.724121 
	C323.816345,1156.780029 324.726074,1157.837036 325.937683,1159.057129 
	C326.466278,1160.730713 326.719452,1161.784058 326.972595,1162.837280 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M356.025635,1129.516235 
	C352.089752,1129.559814 348.382080,1129.965942 344.663574,1130.199951 
	C341.341187,1130.409180 337.986267,1130.661255 334.692017,1130.370117 
	C333.854065,1130.296143 332.301147,1127.752563 332.614258,1127.169800 
	C333.602051,1125.331177 335.319672,1123.884766 336.754944,1122.286621 
	C337.411316,1122.939453 338.067688,1123.592285 338.724060,1124.245117 
	C337.171204,1125.004517 335.618347,1125.764038 334.065521,1126.523438 
	C334.358521,1127.004028 334.651520,1127.484619 334.944519,1127.965088 
	C336.376953,1127.510986 338.483612,1127.504150 339.130707,1126.528931 
	C342.882263,1120.875244 348.538147,1123.282959 353.485168,1122.784790 
	C354.445679,1122.687988 355.489838,1122.886963 356.423096,1123.179932 
	C357.820435,1123.618652 359.153656,1124.261597 360.514160,1124.817627 
	C359.446899,1125.814087 358.379578,1126.810425 356.328369,1128.725464 
	C357.275177,1128.986450 358.454346,1129.311646 359.633484,1129.636719 
	C359.363892,1129.975464 359.094299,1130.314209 358.824677,1130.652954 
	C357.966156,1130.369995 357.107635,1130.087036 356.025635,1129.516235 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M120.791489,1250.854248 
	C119.079887,1250.999268 117.745461,1250.999268 116.411026,1250.999268 
	C116.348297,1250.636719 116.285568,1250.274170 116.222832,1249.911621 
	C117.288330,1249.447754 118.353828,1248.983765 119.156456,1248.634277 
	C118.096512,1247.932617 115.762093,1247.015259 115.828651,1246.331909 
	C116.369377,1240.780884 120.615471,1245.225098 123.404442,1244.407227 
	C123.593307,1245.131470 123.928398,1246.416260 124.416115,1248.286377 
	C126.062302,1242.692505 131.665939,1241.438110 140.616165,1243.671143 
	C142.110306,1246.168091 143.330276,1248.206787 144.552673,1250.249512 
	C141.026993,1252.187012 140.875198,1252.132324 139.237411,1247.162109 
	C137.493896,1247.947021 135.812149,1248.704102 133.933334,1249.549805 
	C132.782745,1247.704102 131.397308,1246.544800 128.436188,1249.400757 
	C126.963257,1250.821533 123.649635,1250.333740 120.791489,1250.854248 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M98.047638,1170.247559 
	C100.632881,1170.473022 103.218124,1170.698364 107.157951,1171.041870 
	C112.834984,1171.041870 119.936356,1171.041870 127.455956,1171.041870 
	C127.334641,1173.191406 127.248718,1174.713623 127.162804,1176.235962 
	C126.895653,1176.300659 126.628502,1176.365479 126.361351,1176.430176 
	C125.894302,1175.422974 125.427254,1174.415894 124.841354,1173.152344 
	C124.520615,1173.507202 124.135246,1173.744263 124.042084,1174.065918 
	C123.075554,1177.401978 121.398560,1178.126465 117.761673,1177.278809 
	C113.177933,1176.210571 108.282623,1176.479004 103.858574,1176.192505 
	C102.525215,1175.112427 101.128593,1173.981201 99.199295,1172.471558 
	C98.460281,1171.477905 98.253960,1170.862793 98.047638,1170.247559 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M285.113068,1129.148926 
	C282.617950,1129.624023 279.817078,1130.249390 276.989655,1130.411987 
	C274.876373,1130.533203 272.712402,1129.788330 270.600555,1129.916260 
	C268.022949,1130.072632 265.476318,1130.739014 262.983551,1131.173096 
	C262.252563,1128.849121 261.797180,1127.401367 261.341797,1125.953613 
	C261.021515,1125.945312 260.701233,1125.937012 260.380951,1125.928711 
	C259.993744,1127.342529 259.606506,1128.756348 258.982056,1131.036255 
	C258.260529,1128.886353 257.755249,1127.380737 256.917725,1124.885132 
	C265.060181,1124.458862 273.132477,1124.036255 281.993713,1123.572266 
	C282.676697,1124.919678 283.736328,1127.010010 285.113068,1129.148926 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M292.430176,1195.430908 
	C289.052612,1197.157227 285.766083,1199.256470 283.845001,1194.099609 
	C279.327576,1199.152832 275.291412,1199.654053 270.049408,1195.855225 
	C269.113281,1196.592896 268.154602,1197.348511 266.604797,1198.569824 
	C266.604797,1195.821411 266.604797,1193.803711 266.604797,1192.809326 
	C275.850616,1189.453247 285.065247,1193.612549 294.236908,1190.665161 
	C293.643250,1192.327393 293.140167,1193.735840 292.430176,1195.430908 
M290.248566,1193.389771 
	C290.467194,1193.402588 290.685852,1193.415405 290.904480,1193.428101 
	C290.801300,1193.318848 290.698120,1193.209717 290.248566,1193.389771 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M63.551781,1243.005615 
	C63.551781,1243.005615 63.846100,1243.303589 63.815819,1243.774658 
	C64.202789,1244.502197 64.620049,1244.758667 65.037323,1245.015137 
	C65.037338,1245.015137 64.996872,1245.003784 64.869217,1245.354248 
	C65.506660,1246.136230 66.271767,1246.567749 67.036888,1246.999268 
	C67.036903,1246.999268 66.998497,1247.001221 66.840309,1247.324585 
	C65.121536,1250.442993 63.560955,1253.237915 62.000355,1256.032837 
	C62.000336,1256.032837 61.998241,1255.998413 61.724323,1256.016602 
	C61.301579,1256.366943 61.152756,1256.699097 61.003922,1257.031128 
	C61.003914,1257.031128 60.999107,1256.998657 60.659637,1257.040894 
	C57.810417,1261.490967 55.420746,1256.396606 52.959198,1256.990112 
	C52.959198,1256.990112 53.003746,1256.996582 53.119690,1256.691406 
	C52.813324,1255.916382 52.391014,1255.446533 51.968704,1254.976562 
	C51.968712,1254.976562 52.006252,1254.994507 51.996876,1254.666992 
	C50.659283,1252.214478 49.331066,1250.089722 48.002846,1247.964844 
	C48.002850,1247.964844 48.002838,1248.003052 48.271210,1247.975830 
	C48.696098,1247.621094 48.852608,1247.293579 49.009117,1246.965942 
	C49.009117,1246.965942 49.003345,1247.003296 49.275116,1246.980957 
	C49.701004,1246.627563 49.855122,1246.296631 50.009239,1245.965698 
	C50.009243,1245.965698 50.003258,1246.002808 50.311962,1246.000000 
	C51.087910,1245.320190 51.555153,1244.643188 52.022388,1243.966064 
	C52.022381,1243.966064 52.008236,1244.007446 52.318695,1243.908081 
	C54.884521,1241.229004 57.180653,1238.771606 61.039677,1241.008545 
	C61.039677,1241.008545 60.995705,1241.003418 60.924355,1241.411865 
	C61.752598,1242.215454 62.652191,1242.610474 63.551781,1243.005615 
M58.063267,1246.087524 
	C56.975391,1246.433228 55.099422,1246.572876 54.945122,1247.162964 
	C54.451206,1249.051147 54.390041,1251.136719 54.675274,1253.070190 
	C54.728977,1253.434204 57.426487,1254.015259 58.419758,1253.521729 
	C61.646358,1251.918335 61.332859,1249.311279 58.063267,1246.087524 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M349.984894,1115.027832 
	C354.370453,1114.021606 358.911377,1113.436279 363.098114,1111.891235 
	C367.324188,1110.331543 369.578247,1111.235596 370.628510,1116.207275 
	C371.352783,1114.194946 371.870667,1112.755981 372.479736,1111.063599 
	C375.534790,1113.228149 378.261078,1115.159668 381.178192,1117.226440 
	C375.432159,1117.834229 370.419983,1118.363403 365.408173,1118.896606 
	C365.079498,1118.931641 364.727081,1119.105347 364.431152,1119.031738 
	C359.791382,1117.878052 355.159058,1116.694458 350.270203,1115.253418 
	C350.015411,1114.989014 349.984894,1115.027832 349.984894,1115.027832 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M200.211639,1153.872192 
	C196.228485,1150.031738 193.249008,1155.759521 189.467392,1154.395142 
	C189.193100,1153.310181 188.553741,1151.999512 188.628784,1150.731201 
	C188.702011,1149.494019 189.639374,1147.220825 189.966492,1147.261353 
	C192.127777,1147.529785 194.205353,1148.396851 196.353928,1148.870483 
	C197.708328,1149.169067 199.138550,1149.123657 201.508743,1149.308472 
	C201.494827,1149.329224 202.295990,1148.131104 203.307236,1146.618774 
	C204.470245,1147.993530 205.387589,1149.077881 206.537384,1150.436890 
	C207.259125,1149.402222 207.845749,1148.561157 208.738358,1147.281494 
	C208.956635,1149.236816 209.111511,1150.624146 209.266388,1152.011597 
	C209.711304,1152.303101 210.156219,1152.594482 210.601120,1152.885986 
	C211.955658,1151.329712 213.310211,1149.773438 213.990265,1148.992188 
	C214.931412,1150.194458 216.280579,1151.917969 218.201385,1154.371826 
	C214.069977,1154.109619 209.977570,1153.791016 205.878998,1153.617310 
	C204.126373,1153.542969 202.360962,1153.769775 200.211639,1153.872192 
M191.753403,1152.279907 
	C191.250549,1152.243408 190.747681,1152.206909 190.244827,1152.170410 
	C190.589005,1152.357300 190.933182,1152.544189 191.753403,1152.279907 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M173.938843,1243.466553 
	C176.497238,1243.311523 179.066620,1243.256592 181.611450,1242.977539 
	C185.264694,1242.577148 188.765198,1242.227905 189.240906,1247.677490 
	C189.683136,1246.261963 190.125381,1244.846558 190.667252,1243.112183 
	C194.911606,1243.404541 199.183319,1243.698608 203.622345,1244.004272 
	C203.622345,1245.221558 203.622345,1246.519775 203.622345,1248.308228 
	C203.076920,1248.196167 202.267670,1247.807007 202.053604,1248.019043 
	C198.351120,1251.684692 193.265610,1248.210938 189.174149,1250.406738 
	C188.733658,1250.643066 186.593216,1247.711792 185.238525,1246.246826 
	C185.053711,1246.971680 184.727676,1248.250244 184.166336,1250.451660 
	C183.319153,1248.478394 182.802887,1247.275879 182.082108,1245.596924 
	C180.442810,1246.468872 178.913818,1247.282104 176.986786,1248.478516 
	C172.047653,1248.859985 174.261276,1245.540405 173.938843,1243.466553 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M333.585052,1248.267700 
	C332.114990,1247.906250 330.919281,1247.355103 329.828857,1247.516357 
	C327.439514,1247.869751 325.103516,1248.584229 322.745758,1249.151245 
	C322.603607,1248.588135 322.461456,1248.025146 322.319305,1247.462158 
	C323.362091,1247.123779 324.404907,1246.785400 325.238342,1246.514893 
	C324.064117,1245.809448 322.889496,1245.103638 321.714874,1244.397949 
	C322.286072,1243.464233 322.717346,1241.920410 323.463501,1241.749268 
	C324.424255,1241.528809 325.615845,1242.665283 326.746216,1242.760010 
	C328.962036,1242.945435 331.204285,1242.814453 333.856384,1242.814453 
	C333.880585,1242.939575 334.119141,1244.173950 334.401154,1245.632935 
	C335.475586,1243.941406 336.412201,1242.466797 337.531067,1240.705322 
	C338.967651,1242.342529 340.121002,1243.656982 341.516052,1245.246826 
	C341.976227,1243.941040 342.310333,1242.992920 343.007141,1241.015747 
	C343.407867,1243.543213 343.632996,1244.963135 344.122345,1248.049438 
	C346.173370,1245.927612 347.480194,1244.575562 348.992645,1243.010864 
	C348.992645,1244.317627 349.449524,1247.046265 348.912567,1247.258301 
	C346.735260,1248.118164 343.587891,1249.296997 342.023468,1248.344971 
	C338.943756,1246.470947 336.591400,1247.288208 333.585052,1248.267700 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M114.701874,1196.822632 
	C114.416176,1196.666992 114.130463,1196.511475 113.844742,1196.355957 
	C110.414871,1202.924072 102.836830,1200.718506 100.527557,1198.682617 
	C96.779434,1195.378418 95.768723,1197.519287 93.622406,1199.252197 
	C91.793243,1192.253174 91.820541,1192.284668 98.842140,1192.440552 
	C102.757957,1192.527588 106.705147,1192.061646 110.587105,1192.411743 
	C112.269279,1192.563354 113.822601,1194.144287 115.324905,1195.630981 
	C115.044197,1196.398193 114.873039,1196.610352 114.701874,1196.822632 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M351.966431,1150.715820 
	C353.129608,1150.078125 354.184509,1149.560303 354.619873,1148.721069 
	C356.203247,1145.669189 358.000549,1144.086426 361.762665,1145.694214 
	C363.226990,1146.320068 365.356171,1145.390259 368.145844,1145.052368 
	C366.376740,1147.064087 365.151703,1148.457153 363.926666,1149.850098 
	C364.179077,1150.210938 364.431488,1150.571655 364.683929,1150.932373 
	C367.927490,1148.775757 371.171082,1146.619141 374.414642,1144.462402 
	C371.410461,1151.570435 369.187653,1152.561157 362.085510,1151.708130 
	C359.962372,1151.453003 357.735168,1152.007324 355.561615,1152.245361 
	C353.488037,1152.472412 351.422241,1152.770386 349.019531,1153.080078 
	C349.019531,1150.247803 349.019531,1147.910522 349.019531,1144.317261 
	C350.201935,1147.266357 350.887909,1148.977295 351.966431,1150.715820 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M64.683960,1087.542480 
	C61.817413,1090.759399 59.192806,1093.705566 55.449867,1097.907227 
	C51.488846,1093.372070 48.167076,1089.740479 45.100349,1085.904785 
	C44.527054,1085.187744 44.766220,1083.603394 45.028259,1082.515015 
	C45.196125,1081.817871 46.070557,1081.272217 46.667942,1080.700073 
	C49.710419,1077.786377 52.618774,1072.839478 55.861809,1072.606445 
	C58.627792,1072.407471 61.652821,1077.790527 64.805779,1080.412598 
	C67.712624,1082.830078 67.613235,1084.937012 64.683960,1087.542480 
M47.306614,1082.679688 
	C47.062576,1083.374878 46.407803,1084.469360 46.636639,1084.704712 
	C49.586273,1087.738281 52.661938,1090.649414 55.640522,1093.528564 
	C66.370308,1085.113647 66.443184,1083.839478 57.034145,1075.360352 
	C56.689312,1075.049683 56.155338,1074.948730 54.673786,1074.288696 
	C52.535507,1076.747681 50.166695,1079.471802 47.306614,1082.679688 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M350.033356,1114.972168 
	C348.853577,1115.243530 347.655884,1115.531982 345.379608,1116.080078 
	C347.558075,1116.808594 348.614227,1117.161865 349.670410,1117.515015 
	C349.664276,1117.902100 349.658142,1118.289185 349.652008,1118.676270 
	C348.018311,1118.794189 346.244934,1119.377197 344.778839,1118.937012 
	C341.286133,1117.888184 338.032654,1116.717041 334.554321,1119.157349 
	C333.905182,1119.612671 331.801086,1118.959839 331.148071,1118.172241 
	C330.497223,1117.387207 330.285126,1115.383423 330.844879,1114.637329 
	C331.845703,1113.303223 333.481079,1111.828369 335.010529,1111.628052 
	C339.027588,1111.101318 343.140259,1111.036133 347.191589,1111.242676 
	C348.107727,1111.289307 348.929535,1113.186768 349.889832,1114.630127 
	C349.984894,1115.027832 350.015411,1114.989014 350.033356,1114.972168 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M392.126648,1113.985718 
	C394.699097,1109.326294 399.378510,1111.644409 403.058380,1110.880981 
	C405.999969,1110.270752 409.182556,1110.822388 412.611328,1111.325806 
	C413.319946,1113.869995 413.675415,1115.946899 414.030884,1118.023682 
	C409.381409,1117.546265 404.738647,1116.818848 400.079742,1116.693359 
	C397.637573,1116.627563 395.166931,1117.617310 392.572754,1117.769775 
	C393.170959,1116.888550 393.905579,1116.367920 394.640198,1115.847290 
	C393.802368,1115.226807 392.964508,1114.606323 392.126648,1113.985718 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M228.692688,1220.749878 
	C226.809357,1221.225586 225.296753,1221.633423 223.721008,1222.058105 
	C223.242844,1220.147095 222.236588,1218.446533 222.717926,1217.513916 
	C223.471115,1216.054321 225.230484,1215.114014 226.563629,1213.953735 
	C229.240387,1217.358643 232.201080,1214.838379 235.426880,1214.245361 
	C235.769882,1215.769775 236.106644,1217.266602 236.742706,1220.093506 
	C237.675415,1217.540771 238.196106,1216.115723 238.915253,1214.147461 
	C240.776413,1214.013550 242.949539,1213.857178 245.396225,1213.681152 
	C244.412735,1216.374878 243.663864,1218.426147 242.575790,1221.406372 
	C241.830292,1219.350952 241.342819,1218.007080 240.628220,1216.036865 
	C239.098419,1220.778564 237.086914,1223.874023 232.386719,1219.449951 
	C232.004288,1219.089966 230.204590,1220.235718 228.692688,1220.749878 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M186.353455,1174.706787 
	C188.148407,1173.832275 189.633240,1172.812988 191.051361,1171.707886 
	C192.492523,1170.584717 193.867935,1169.377319 195.272125,1168.206787 
	C195.856750,1168.815063 196.441360,1169.423340 197.025970,1170.031494 
	C195.944901,1171.616943 194.863815,1173.202393 193.900391,1174.615234 
	C194.893280,1174.893066 196.406250,1175.316284 197.919235,1175.739502 
	C197.884491,1176.015869 197.849747,1176.292114 197.815002,1176.568359 
	C192.946030,1176.568359 188.076920,1176.584229 183.208313,1176.541870 
	C182.758392,1176.538086 182.328613,1176.050537 181.860565,1175.980347 
	C179.959930,1175.695068 178.047974,1175.484985 176.140167,1175.247070 
	C176.950089,1173.452148 177.760040,1171.657227 178.161407,1170.767700 
	C182.250305,1170.387695 185.886047,1170.049927 189.826279,1169.683716 
	C188.416611,1171.473755 187.215347,1172.999023 186.353455,1174.706787 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M52.062927,1124.993408 
	C44.667919,1123.000000 43.208054,1118.767212 48.182018,1113.657104 
	C50.597485,1111.175659 53.076378,1108.755859 55.513432,1106.321289 
	C59.270214,1110.446045 62.997452,1114.538208 67.406708,1119.379272 
	C64.398293,1122.532593 60.479042,1126.640625 55.927277,1131.411621 
	C54.317444,1129.178955 53.039925,1127.407104 52.169228,1125.458008 
	C53.786629,1126.279175 54.997196,1127.277466 56.370590,1128.410034 
	C59.712185,1124.862915 62.625546,1121.770264 66.006439,1118.181396 
	C62.409332,1114.817261 59.212109,1111.827026 55.654942,1108.500122 
	C52.565762,1111.788208 49.558903,1114.988770 46.430866,1118.318237 
	C48.475300,1120.741333 50.269108,1122.867432 52.062927,1124.993408 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M417.185364,1111.197632 
	C422.216125,1111.062622 427.248657,1110.796875 432.276703,1110.864990 
	C433.982544,1110.888062 435.676971,1111.754150 438.016510,1112.416138 
	C437.911682,1113.079468 437.624268,1114.898193 437.315308,1116.853271 
	C430.898926,1117.252930 424.403351,1117.657471 417.554626,1117.604980 
	C418.937958,1116.238525 420.674408,1115.329224 422.645935,1114.296753 
	C420.333588,1112.984375 418.759460,1112.090942 417.185364,1111.197632 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M301.739136,1194.865845 
	C299.607208,1195.462891 299.607208,1195.462891 299.697388,1192.356201 
	C304.927612,1191.577881 310.109314,1190.621094 315.334747,1190.138306 
	C316.979889,1189.986328 318.742432,1191.105225 320.451233,1191.642578 
	C319.128021,1193.204712 318.028381,1195.770508 316.436188,1196.125122 
	C312.836792,1196.926880 308.545349,1201.122925 305.336304,1195.131104 
	C305.041321,1194.580322 303.200867,1194.857300 301.739136,1194.865845 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M387.090515,1125.687134 
	C384.475433,1130.172852 381.205811,1131.333130 376.952881,1128.616699 
	C376.443604,1128.291382 375.462067,1127.724976 375.431671,1127.769653 
	C371.895599,1132.956055 371.044342,1127.576904 368.922363,1125.909790 
	C368.083008,1127.203247 367.284576,1128.433472 366.486115,1129.663818 
	C366.132690,1129.488159 365.779266,1129.312500 365.425842,1129.136841 
	C366.431885,1126.906616 367.437927,1124.676270 368.693390,1121.893066 
	C370.132538,1123.449829 371.183502,1124.586792 372.553772,1126.068970 
	C372.850586,1124.586304 373.056335,1123.558350 372.994232,1123.868774 
	C375.177948,1123.284302 376.704163,1123.083130 378.046570,1122.475586 
	C381.306091,1121.000610 385.442535,1122.196167 387.090515,1125.687134 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M473.052063,1150.830688 
	C471.451172,1149.612793 469.850250,1148.394897 468.207520,1147.145264 
	C468.016998,1147.897949 467.653687,1149.333130 467.325073,1150.631348 
	C465.819153,1148.928467 464.668671,1146.827026 462.914093,1145.985962 
	C462.054382,1145.573853 460.072327,1147.502930 458.598206,1148.372437 
	C458.825134,1148.805176 459.052094,1149.237915 459.279022,1149.670776 
	C460.682190,1149.244141 462.085358,1148.817505 463.500885,1148.387085 
	C462.516357,1152.092529 456.617676,1153.078369 450.294464,1149.979248 
	C450.513916,1147.700562 450.726471,1146.059082 450.939026,1144.417480 
	C452.187073,1145.535400 453.435089,1146.653320 454.791290,1147.868164 
	C454.978882,1147.216309 455.338776,1145.965942 455.943146,1143.865967 
	C461.292633,1144.427734 466.795471,1145.005615 472.769684,1146.024414 
	C473.178070,1147.920410 473.115051,1149.375610 473.052063,1150.830688 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M181.999481,1220.994385 
	C179.331039,1221.297974 176.657135,1221.878052 173.995697,1221.825684 
	C171.444199,1221.775513 168.905594,1221.068970 166.184418,1220.621826 
	C165.626373,1218.810303 165.079926,1217.036499 164.668060,1215.699585 
	C168.575775,1215.371948 172.896057,1215.173706 177.168900,1214.613525 
	C183.660461,1213.762329 183.682877,1213.699341 181.905518,1220.603394 
	C181.994690,1220.997925 181.999481,1220.994385 181.999481,1220.994385 
M167.962021,1217.692627 
	C168.134552,1218.062622 168.307083,1218.432495 168.479630,1218.802490 
	C168.472458,1218.279419 168.465302,1217.756226 167.962021,1217.692627 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M294.045898,1242.114746 
	C294.345825,1242.659058 294.635712,1243.209106 294.947784,1243.746338 
	C295.589905,1244.851807 296.243713,1245.950317 296.892700,1247.051758 
	C297.720886,1245.957031 298.568481,1244.876099 299.366272,1243.759644 
	C299.625916,1243.396240 299.755585,1242.939941 299.896790,1242.630859 
	C301.054108,1244.273560 302.232513,1245.946167 303.410950,1247.618896 
	C303.880798,1247.386719 304.350616,1247.154541 304.820465,1246.922363 
	C305.049255,1245.199463 305.278046,1243.476685 305.552155,1241.412842 
	C309.031128,1241.412842 312.394531,1241.412842 315.843750,1241.412842 
	C319.398010,1247.797485 318.822937,1248.845703 311.679077,1248.951782 
	C306.079956,1249.034912 300.481995,1249.197021 294.338623,1248.835571 
	C293.877808,1246.269531 293.961853,1244.192139 294.045898,1242.114746 
M312.289246,1247.233765 
	C313.307587,1247.074951 314.325928,1246.916260 315.344269,1246.757446 
	C315.327393,1246.469482 315.310516,1246.181396 315.293640,1245.893433 
	C312.697601,1245.665161 310.101562,1245.436890 307.505524,1245.208740 
	C307.459900,1245.711182 307.414307,1246.213623 307.368683,1246.716064 
	C308.730194,1246.910767 310.091675,1247.105469 312.289246,1247.233765 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M92.320267,1132.221069 
	C91.283485,1127.093384 92.738838,1125.456909 98.003220,1125.947632 
	C97.021538,1126.800659 96.384514,1127.445068 95.656670,1127.961548 
	C94.899445,1128.498901 94.054489,1128.912720 93.247864,1129.380493 
	C93.634987,1129.890381 94.022110,1130.400269 94.409233,1130.910034 
	C95.823776,1130.146606 98.299889,1129.565186 98.467484,1128.587891 
	C99.161736,1124.539062 101.494774,1124.747192 104.599701,1125.098877 
	C108.354179,1125.524170 108.978554,1127.968750 109.262054,1132.355103 
	C103.735802,1132.393188 98.184685,1132.431274 92.320267,1132.221069 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M260.831848,1217.806152 
	C258.399567,1218.704102 256.401154,1219.867554 254.250504,1220.553833 
	C252.870361,1220.994141 251.091629,1221.144287 249.833466,1220.613770 
	C249.177200,1220.337280 248.676392,1217.985962 249.140411,1217.138794 
	C249.929733,1215.697998 251.519226,1214.685303 252.818329,1213.544922 
	C253.013947,1213.373291 253.457184,1213.466553 253.785843,1213.466309 
	C258.180298,1213.462402 262.574768,1213.463745 268.489563,1213.463745 
	C266.495331,1216.196289 264.975189,1218.279297 263.233582,1220.665649 
	C262.470184,1219.615356 261.835663,1218.742310 260.831848,1217.806152 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M397.315338,1147.875732 
	C394.782593,1151.872070 387.052063,1154.613159 382.852386,1152.436401 
	C381.697723,1151.837891 381.030548,1149.412842 381.013428,1147.816406 
	C381.001923,1146.746094 382.373810,1144.944458 383.400177,1144.719727 
	C386.004730,1144.149902 388.793213,1144.000366 391.444733,1144.280762 
	C392.446899,1144.386841 393.275177,1146.136841 394.360291,1147.333252 
	C395.265137,1146.467651 396.161072,1145.610474 396.691132,1145.103394 
	C397.647583,1146.104858 398.496613,1146.993896 399.345642,1147.882935 
	C399.254730,1147.968994 399.163879,1148.055054 399.062683,1148.151001 
	C398.603607,1148.053589 398.125549,1147.952148 397.315338,1147.875732 
M393.150726,1149.938599 
	C393.398987,1149.577881 393.647247,1149.217285 393.895508,1148.856567 
	C392.784882,1148.856567 391.674255,1148.856567 390.563599,1148.856567 
	C390.580200,1149.197876 390.596832,1149.539307 390.613434,1149.880615 
	C391.225861,1149.927856 391.838318,1149.975098 393.150726,1149.938599 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M110.753067,1085.375977 
	C107.108543,1089.435913 103.829430,1089.445923 99.827629,1085.366821 
	C99.282333,1086.092896 98.742790,1086.811279 97.799408,1088.067261 
	C97.306908,1086.024170 96.951645,1084.550415 96.228355,1081.549927 
	C95.422874,1084.443726 95.024376,1085.875366 94.424164,1088.031860 
	C93.427681,1085.324951 92.640160,1083.185669 91.361511,1079.712280 
	C94.794113,1080.408691 97.304115,1080.917847 99.315720,1081.325928 
	C102.392761,1080.920410 105.310814,1080.535767 108.640930,1080.597168 
	C109.619690,1082.487305 110.186378,1083.931641 110.753067,1085.375977 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M414.934692,1189.494141 
	C421.763367,1189.345703 428.624390,1188.787720 435.399231,1189.322266 
	C437.205444,1189.464722 438.747742,1192.952148 440.723877,1195.270752 
	C439.202850,1195.599976 438.437561,1195.765625 437.123596,1195.692383 
	C433.127350,1194.019775 429.679810,1192.586060 426.138885,1191.113525 
	C426.023132,1191.735352 425.876984,1192.520630 425.730835,1193.305908 
	C423.791016,1194.013184 421.851227,1194.720459 419.564697,1195.554321 
	C419.462799,1195.356445 418.975403,1194.410034 418.055023,1193.008545 
	C416.726257,1191.533569 415.830475,1190.513916 414.934692,1189.494141 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M375.944763,1156.677490 
	C377.298126,1157.138062 378.373871,1157.322266 379.968353,1157.595215 
	C380.062469,1158.732422 380.232544,1160.787109 380.376709,1162.528931 
	C377.626892,1162.528931 374.973419,1161.807861 373.020538,1162.671265 
	C368.193970,1164.805298 364.878906,1164.936035 363.885742,1158.714844 
	C363.788635,1158.106567 362.907501,1157.623413 362.306488,1156.994507 
	C366.953217,1156.788208 371.310181,1156.594727 375.944763,1156.677490 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M122.114365,1080.647705 
	C125.586792,1080.436401 129.098328,1079.841309 132.513718,1080.189575 
	C134.025818,1080.343750 135.839828,1082.173096 136.556183,1083.694336 
	C136.962234,1084.556519 135.700775,1087.140747 134.687729,1087.489502 
	C133.510269,1087.894897 131.782715,1086.702515 129.713470,1086.009033 
	C127.160828,1089.272949 123.885338,1088.762207 120.532623,1084.602783 
	C120.949409,1082.995605 121.531891,1081.821655 122.114365,1080.647705 
M133.456238,1085.578857 
	C133.569305,1085.510254 133.682358,1085.441772 133.795425,1085.373291 
	C133.705475,1085.276489 133.624298,1085.114746 133.523087,1085.101074 
	C133.420746,1085.087158 133.298538,1085.219849 133.456238,1085.578857 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M204.010712,1254.709229 
	C209.979614,1253.850220 211.995010,1255.376953 212.069672,1260.781738 
	C211.689590,1259.514648 211.309509,1258.247437 210.879990,1256.815430 
	C206.076874,1261.774048 206.076874,1261.774048 196.720230,1260.112061 
	C196.525192,1258.423096 196.321411,1256.658325 196.113144,1254.854736 
	C198.818527,1254.854736 201.239944,1254.854736 204.010712,1254.709229 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M143.305695,1124.822998 
	C150.714050,1123.326172 146.320450,1129.639404 148.411163,1131.037598 
	C145.231522,1131.407349 142.144745,1131.527466 139.177078,1132.185181 
	C136.685715,1132.737305 133.894516,1133.278564 133.875046,1130.116577 
	C133.864487,1128.400146 136.342606,1126.302368 138.168182,1125.076782 
	C139.284119,1124.327637 141.292053,1124.907227 143.305695,1124.822998 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M233.992569,1151.957153 
	C235.024994,1153.844849 234.787781,1154.348389 232.922241,1154.434814 
	C230.512329,1154.546387 228.001266,1154.915771 225.721848,1154.377563 
	C224.575745,1154.106934 223.351929,1152.186401 223.043945,1150.814209 
	C222.595306,1148.815308 222.260483,1146.174438 225.613297,1146.046509 
	C228.715103,1145.928223 231.824982,1146.021240 236.046066,1147.220093 
	C234.870758,1148.592529 233.693634,1149.963379 232.531876,1151.347290 
	C232.506226,1151.377808 232.632050,1151.613892 232.728546,1151.653809 
	C233.025665,1151.776123 233.342896,1151.849609 233.992569,1151.957153 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M114.961372,1131.641846 
	C114.360321,1129.693237 114.149200,1127.794312 113.906296,1125.609497 
	C115.380692,1125.483765 116.776436,1124.965454 117.193237,1125.395630 
	C119.470459,1127.745728 121.762360,1126.283691 124.137215,1125.864258 
	C125.564644,1125.612061 127.432335,1125.351196 128.452362,1126.046875 
	C129.345917,1126.656372 130.056671,1129.048706 129.557724,1129.845459 
	C128.716949,1131.188354 126.944016,1132.097656 125.383141,1132.773682 
	C124.644585,1133.093506 123.395912,1132.638916 122.541496,1132.210205 
	C120.183975,1131.027222 117.833839,1130.689087 114.961372,1131.641846 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M421.965759,1159.196777 
	C422.012756,1159.544434 422.059784,1159.892090 422.110779,1160.386719 
	C419.448822,1166.111938 414.576080,1162.800659 410.310547,1162.867310 
	C409.629547,1161.305298 409.305969,1160.250977 408.982422,1159.196777 
	C409.407684,1155.483154 411.647339,1154.270752 414.842163,1155.655151 
	C416.982880,1156.582642 420.382477,1153.581055 421.688110,1157.904053 
	C422.010498,1158.034180 421.968292,1158.002563 421.966125,1158.288574 
	C421.964539,1158.781860 421.965149,1158.989380 421.965759,1159.196777 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M246.116119,1153.539795 
	C243.916260,1153.216431 242.065308,1152.738403 240.214371,1152.260254 
	C241.009140,1150.441528 241.354126,1147.836548 242.736786,1147.045532 
	C243.903824,1146.377930 246.274841,1147.814697 248.870789,1148.528320 
	C249.002289,1148.378906 249.831146,1147.437134 250.977264,1146.135010 
	C252.906021,1147.902954 254.782791,1149.623291 257.005035,1151.660400 
	C252.543640,1152.390381 249.504333,1152.887695 246.116119,1153.539795 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M290.776398,1118.244507 
	C292.082306,1116.558350 293.388245,1114.872070 295.050262,1112.726074 
	C295.517578,1113.682495 296.340729,1115.367065 297.864075,1118.484619 
	C298.659760,1115.654053 299.058563,1114.235352 299.468842,1112.775879 
	C301.378510,1112.552490 303.389862,1112.317261 305.736206,1112.479980 
	C307.947998,1113.540894 309.824799,1114.203857 312.434937,1115.125977 
	C310.023621,1116.503296 308.023468,1117.645874 306.023315,1118.788452 
	C301.263733,1119.191040 296.504181,1119.593506 291.284058,1119.639648 
	C290.807831,1118.937012 290.792114,1118.590820 290.776398,1118.244507 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M213.329071,1086.194824 
	C209.365189,1086.829834 205.551147,1087.125244 201.812210,1087.414917 
	C200.199768,1079.585938 200.200256,1079.623291 207.607803,1079.526245 
	C209.377884,1079.502930 211.143509,1079.139160 214.514145,1078.744141 
	C212.999802,1081.359619 212.085617,1082.938477 211.120773,1084.604858 
	C211.494919,1084.803345 212.486893,1085.329224 213.329071,1086.194824 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M292.075958,1152.925049 
	C291.621765,1152.991333 291.167572,1153.057617 290.244781,1152.775146 
	C288.590454,1152.529419 287.404724,1152.632202 286.219025,1152.735107 
	C285.105835,1152.906616 283.992645,1153.078247 282.981201,1153.234009 
	C283.943481,1150.760864 284.911530,1148.272827 286.199951,1144.961426 
	C288.914612,1145.094849 292.255585,1145.259277 295.906921,1145.955322 
	C297.180054,1146.820801 298.269196,1146.973999 299.072540,1147.535767 
	C300.006226,1148.188721 300.689331,1149.199829 301.481262,1150.055420 
	C301.603760,1150.266479 301.555969,1150.427002 300.822021,1150.687622 
	C299.052673,1151.603027 297.799225,1152.367798 296.545776,1153.132690 
	C296.206451,1153.144165 295.867126,1153.155762 295.239441,1152.775391 
	C293.992706,1152.564087 293.034332,1152.744629 292.075958,1152.925049 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M161.510239,1198.575928 
	C157.047546,1198.806152 152.585556,1199.181030 148.121811,1199.203003 
	C146.024719,1199.213379 143.924301,1198.544067 141.359344,1198.102417 
	C142.044083,1196.612793 143.025742,1194.477295 144.007385,1192.341675 
	C144.453842,1192.033081 144.900299,1191.724487 145.346756,1191.416016 
	C145.857132,1192.792725 146.367523,1194.169556 146.819427,1195.388672 
	C149.522125,1193.951538 151.497955,1192.901001 153.473785,1191.850342 
	C153.677322,1192.170044 153.880859,1192.489624 154.084396,1192.809204 
	C152.857391,1194.117310 151.630402,1195.425537 150.403397,1196.733643 
	C150.725906,1197.186646 151.048401,1197.639648 151.370895,1198.092651 
	C154.450699,1195.954102 157.530502,1193.815552 160.610306,1191.677002 
	C161.179184,1192.342163 161.748062,1193.007446 162.316940,1193.672607 
	C161.044907,1194.314941 159.772858,1194.957275 158.500793,1195.599609 
	C158.971680,1196.307373 159.442612,1197.015137 160.485336,1197.904053 
	C161.208160,1198.248657 161.359192,1198.412231 161.510239,1198.575928 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M283.408752,1242.261353 
	C285.367371,1242.169312 287.325958,1242.077271 289.557373,1242.530762 
	C289.896637,1245.084229 289.963135,1247.091919 290.029602,1249.099731 
	C287.394348,1247.062012 284.548279,1247.630737 281.779510,1248.605713 
	C279.224701,1249.505371 276.073730,1250.947021 275.423615,1247.149536 
	C274.858917,1243.851074 277.356262,1241.763550 281.402771,1242.393066 
	C281.966949,1243.193848 282.159912,1243.549805 282.352905,1243.905640 
	C282.704834,1243.357544 283.056793,1242.809448 283.408752,1242.261353 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M243.111664,1115.182617 
	C244.473190,1113.951294 245.747269,1112.673218 247.194183,1112.431885 
	C249.546600,1112.039673 252.012100,1112.326050 255.411819,1112.326050 
	C254.385971,1114.488403 253.650497,1116.038574 252.895309,1117.630371 
	C253.302917,1117.887451 254.147293,1118.419800 256.156433,1119.686646 
	C251.532394,1120.051880 248.246704,1120.642578 245.049316,1120.329956 
	C244.253677,1120.252197 243.743301,1117.256104 243.111664,1115.182617 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M161.981506,1196.443481 
	C164.384094,1190.178101 164.587418,1190.157471 171.019119,1193.707886 
	C171.855148,1194.169434 173.128555,1193.838867 174.616562,1194.250244 
	C176.467270,1195.328125 177.900787,1196.030884 179.398224,1196.765015 
	C178.207047,1197.551025 177.063889,1198.305298 175.920746,1199.059692 
	C173.335846,1199.313477 170.750168,1199.763550 168.166260,1199.753662 
	C166.375137,1199.746826 164.586441,1199.106201 162.504822,1198.018799 
	C162.135818,1197.007080 162.058670,1196.725342 161.981506,1196.443481 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M280.495300,1219.686157 
	C277.571930,1219.839844 274.995667,1219.839844 271.789490,1219.839844 
	C272.908264,1217.662109 273.993256,1215.550171 275.548981,1212.521851 
	C277.244385,1214.783447 278.534271,1216.504150 279.394287,1217.651489 
	C281.070953,1216.215820 282.765350,1213.938354 285.004578,1213.076782 
	C287.311707,1212.189087 290.744202,1211.930054 288.843048,1216.688110 
	C288.525909,1217.481812 289.552277,1218.812256 289.822083,1219.532471 
	C286.833038,1219.532471 283.837738,1219.532471 280.495300,1219.686157 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M257.003754,1162.071777 
	C255.880127,1163.340942 254.489761,1165.828735 253.682587,1165.652100 
	C251.202377,1165.109253 246.700562,1166.248291 247.432617,1160.880859 
	C247.093552,1162.087646 246.754471,1163.294434 246.415405,1164.501221 
	C246.068542,1164.542725 245.721695,1164.584351 245.374847,1164.625854 
	C244.908417,1162.413086 244.441986,1160.200195 243.803436,1157.170776 
	C248.573730,1157.508301 252.730042,1157.802490 256.883606,1158.835815 
	C256.921783,1160.407349 256.962769,1161.239502 257.003754,1162.071777 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M122.022476,1098.677612 
	C120.579453,1098.007202 119.136414,1097.336670 118.793823,1097.177490 
	C115.286041,1097.903076 112.560211,1098.466797 109.600555,1098.558228 
	C110.432167,1097.507812 111.497604,1096.929810 111.632805,1096.856445 
	C110.755714,1095.204834 109.940590,1093.669922 109.125458,1092.135010 
	C111.226273,1092.382690 113.356438,1092.974731 115.421143,1092.798706 
	C118.663605,1092.522339 121.861923,1091.728271 125.427444,1091.547363 
	C124.524796,1094.186646 123.273636,1096.432129 122.022476,1098.677612 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M280.951599,1161.156128 
	C281.478546,1159.958252 282.005463,1158.760498 282.674805,1157.238892 
	C283.453979,1157.852417 284.380951,1158.582520 284.286987,1158.508423 
	C287.895020,1157.921509 290.625244,1157.300293 293.380066,1157.158936 
	C294.035828,1157.125244 295.512360,1158.933228 295.335480,1159.485352 
	C294.808960,1161.128662 293.980255,1163.109619 292.639496,1163.934326 
	C291.329865,1164.739868 288.424469,1165.055420 287.653564,1164.236328 
	C285.378845,1161.819702 283.477203,1163.885620 281.188141,1163.385620 
	C280.939117,1162.242554 280.945343,1161.699341 280.951599,1161.156128 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M370.928406,1212.658691 
	C374.773560,1212.678101 378.252441,1212.856201 381.976624,1213.046875 
	C381.976624,1214.714478 381.976624,1216.675293 381.976624,1218.153931 
	C379.222229,1218.519653 376.570190,1219.022583 373.898285,1219.173462 
	C372.141266,1219.272705 370.355530,1218.864258 368.582703,1218.683228 
	C369.242493,1216.728027 369.902313,1214.772705 370.928406,1212.658691 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M473.297821,1151.058594 
	C473.115051,1149.375610 473.178070,1147.920410 473.185669,1146.206665 
	C475.665161,1145.358154 478.175568,1144.544922 480.744263,1144.263062 
	C482.259705,1144.096924 483.867493,1144.772339 485.361725,1145.055542 
	C486.014587,1147.259155 486.704987,1149.589478 487.330322,1151.700073 
	C485.011658,1151.226562 482.611115,1150.425049 480.183502,1150.332397 
	C477.985565,1150.248535 475.758057,1150.937866 473.297821,1151.058594 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M316.780548,1084.841064 
	C312.593658,1084.680664 308.713531,1084.303223 303.658936,1083.811401 
	C306.275818,1081.382202 307.848511,1079.922363 309.584778,1078.310669 
	C309.951996,1079.411255 310.324463,1080.527588 311.067230,1082.754028 
	C312.632324,1077.517334 314.845184,1075.978882 318.156860,1080.154419 
	C319.054596,1079.167969 319.767853,1078.384033 320.481110,1077.600220 
	C320.889191,1077.861084 321.297272,1078.121948 321.705353,1078.382812 
	C320.166016,1080.463135 318.626678,1082.543579 316.780548,1084.841064 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M156.763153,1221.613892 
	C154.478897,1222.084351 152.468933,1222.336060 150.293961,1222.608398 
	C150.063751,1221.165283 149.889435,1220.072632 149.667068,1218.678833 
	C148.920609,1219.949341 148.307861,1220.992310 147.695129,1222.035278 
	C147.215286,1221.958496 146.735443,1221.881714 146.255600,1221.804932 
	C146.411148,1219.415405 146.014954,1215.212402 146.822769,1214.966675 
	C149.559357,1214.134033 152.990952,1214.009155 155.609818,1215.024902 
	C156.938171,1215.539917 160.659073,1218.373291 156.763153,1221.613892 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M267.561737,1082.329590 
	C267.486450,1080.872070 267.729309,1079.539062 267.972168,1078.206177 
	C269.443909,1078.578735 270.915619,1078.951294 273.448059,1079.592407 
	C273.335144,1079.676636 274.419586,1078.868408 275.563293,1078.015991 
	C277.306427,1080.628052 281.021637,1083.412598 276.413452,1085.557251 
	C273.636993,1086.849487 269.768311,1085.794922 266.289917,1084.728516 
	C267.367859,1084.300903 268.445831,1083.873291 270.020386,1083.248657 
	C269.017609,1082.876465 268.448761,1082.665283 267.561737,1082.329590 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M369.960022,1244.464478 
	C370.472870,1243.683716 370.985779,1242.903076 372.267120,1240.952515 
	C372.871063,1242.475952 373.352264,1243.689819 373.351929,1243.688965 
	C377.066376,1242.079346 380.235474,1240.706055 383.404602,1239.332642 
	C383.936768,1239.913452 384.468933,1240.494263 385.001099,1241.075073 
	C384.014526,1242.693359 383.027924,1244.311768 382.047211,1245.920532 
	C382.394592,1246.055054 383.509277,1246.486816 385.302734,1247.181519 
	C381.341858,1249.352539 378.811218,1249.531128 377.953430,1244.444214 
	C376.644623,1245.953735 375.747040,1246.988892 374.244568,1248.721802 
	C373.001709,1247.149902 371.917297,1245.778320 370.397461,1244.441162 
	C369.962006,1244.475342 369.960052,1244.464478 369.960022,1244.464478 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M201.075287,1219.614624 
	C200.921143,1220.195679 200.766983,1220.776855 200.612823,1221.357910 
	C196.596176,1218.717285 193.127213,1222.829834 189.290237,1222.257812 
	C188.843628,1222.191284 188.450668,1221.765015 187.383759,1221.102417 
	C189.199371,1220.307373 190.243073,1219.850342 191.183014,1219.438843 
	C190.005127,1218.919678 188.728485,1218.356934 187.349472,1217.749023 
	C189.704498,1209.838501 195.997574,1219.030640 198.850113,1214.187988 
	C199.478958,1215.301880 199.952179,1216.140015 200.731171,1217.476807 
	C201.049713,1218.521729 201.062500,1219.068237 201.075287,1219.614624 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M268.573120,1119.791748 
	C266.086670,1119.138306 263.974213,1118.564941 261.132904,1117.793701 
	C261.025848,1117.115723 260.750916,1115.375000 260.476074,1113.634521 
	C263.151031,1113.219849 265.936554,1113.052490 268.563263,1112.317627 
	C272.299164,1111.272461 274.177094,1113.562622 274.415314,1116.276245 
	C274.708618,1119.617310 272.048950,1120.573853 268.573120,1119.791748 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M257.321533,1162.258301 
	C256.962769,1161.239502 256.921783,1160.407349 256.951599,1159.163208 
	C260.686523,1158.214844 264.350677,1157.678223 269.095612,1156.983398 
	C269.095612,1159.603760 269.095612,1161.972290 269.095612,1164.340942 
	C266.786926,1164.332397 264.446869,1164.550903 262.180420,1164.237183 
	C260.614044,1164.020386 259.149414,1163.068481 257.321533,1162.258301 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M383.277893,1077.282593 
	C382.916779,1079.105957 382.217224,1080.716064 381.328278,1082.762085 
	C381.463593,1082.805542 382.523529,1083.145752 383.615204,1083.496216 
	C382.600159,1087.077148 372.582062,1083.990967 366.426849,1077.507812 
	C369.224976,1078.129395 372.023102,1078.750977 374.821228,1079.372559 
	C374.729553,1079.148804 374.637848,1078.925171 374.546173,1078.701538 
	C377.343933,1078.157471 380.141693,1077.613403 383.277893,1077.282593 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M180.300995,1085.249146 
	C179.942169,1085.770264 179.296509,1086.764404 179.265274,1086.745483 
	C175.819290,1084.655151 172.865616,1087.018188 169.700546,1087.604126 
	C168.941071,1087.744873 167.210419,1086.509766 167.149109,1085.801514 
	C166.987762,1083.937988 167.499252,1082.016357 167.742767,1080.117798 
	C168.977310,1080.282227 170.208145,1080.539429 171.447266,1080.588623 
	C173.226471,1080.659302 175.032196,1080.417358 176.784256,1080.640869 
	C177.487183,1080.730469 178.077728,1081.700806 178.873413,1082.901489 
	C179.451965,1084.104736 179.876480,1084.677002 180.300995,1085.249146 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M180.659195,1085.215332 
	C179.876480,1084.677002 179.451965,1084.104736 178.997192,1083.256104 
	C179.824539,1081.825928 180.682114,1080.672241 181.758362,1079.224487 
	C183.045242,1080.803589 184.147568,1082.156250 185.580734,1083.914795 
	C186.088577,1082.435791 186.447739,1081.389771 186.795563,1080.376709 
	C189.818573,1081.091187 192.216339,1080.999268 192.450409,1080.442017 
	C193.559174,1082.285522 194.743393,1084.254395 196.163132,1086.614990 
	C191.245575,1088.728027 186.128387,1087.020142 180.659195,1085.215332 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M156.383804,1165.606689 
	C154.106415,1165.150879 152.150818,1164.867676 149.637497,1164.503662 
	C150.337509,1162.997925 151.411438,1160.687866 153.182190,1156.879150 
	C154.183136,1159.150146 154.714951,1160.356812 155.176651,1161.404419 
	C157.384430,1159.910522 158.812775,1158.944092 161.375336,1157.210205 
	C162.000000,1160.262207 162.461395,1162.516357 162.922806,1164.770630 
	C160.850418,1165.106934 158.778015,1165.443237 156.383804,1165.606689 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M467.433350,1156.072266 
	C470.502228,1155.525513 473.236450,1155.099243 475.984314,1154.975342 
	C476.695099,1154.943359 477.847351,1155.721436 478.072815,1156.376465 
	C478.308411,1157.061035 477.935822,1158.467651 477.368164,1158.823975 
	C475.334229,1160.100220 473.189484,1161.330444 470.919525,1162.053467 
	C469.445648,1162.523071 467.681458,1162.081787 466.047302,1162.048584 
	C466.399475,1160.134277 466.751617,1158.219727 467.433350,1156.072266 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M100.876465,1166.341309 
	C99.117378,1166.560791 98.387444,1162.332642 96.451889,1165.623169 
	C96.377853,1165.749023 94.603142,1165.297241 94.524162,1164.923584 
	C94.196465,1163.373535 94.067284,1161.736572 94.209641,1160.162598 
	C94.246361,1159.756592 96.024017,1159.016968 96.178482,1159.185425 
	C98.933327,1162.190308 101.423279,1158.083862 104.986916,1159.434814 
	C105.387619,1160.862183 105.992058,1163.015137 106.596504,1165.168213 
	C104.808731,1165.593018 103.020943,1166.017944 100.876465,1166.341309 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M106.032013,1098.174194 
	C103.102699,1097.357422 100.209549,1096.327515 97.227524,1095.821167 
	C95.903481,1095.596313 94.399910,1096.428833 92.724297,1096.844360 
	C92.647751,1095.423828 92.545807,1093.531982 92.443863,1091.640137 
	C95.740219,1091.921265 99.034233,1092.236450 102.334373,1092.462036 
	C103.290016,1092.527466 104.263214,1092.335693 105.526993,1092.646606 
	C105.894417,1094.745728 105.963219,1096.459961 106.032013,1098.174194 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M184.226196,1260.686768 
	C182.119629,1261.090088 180.351486,1261.335938 178.583359,1261.581909 
	C178.411774,1260.037842 177.533752,1257.876343 178.224564,1257.085815 
	C179.437134,1255.698120 182.069031,1253.988647 183.293716,1254.472168 
	C186.697006,1255.815918 189.682602,1258.217651 192.833221,1260.201538 
	C192.490585,1260.668091 192.147964,1261.134766 191.805328,1261.601318 
	C190.918167,1260.755981 190.031006,1259.910767 189.180740,1259.100586 
	C187.624313,1259.582275 186.094482,1260.055786 184.226196,1260.686768 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M221.988647,1118.985962 
	C217.906830,1119.668213 213.837021,1120.568359 209.730042,1120.795044 
	C209.122162,1120.828613 207.870895,1117.695801 207.813690,1115.997681 
	C207.779938,1114.995972 209.601196,1112.987549 210.429535,1113.067993 
	C213.632751,1113.378540 216.774658,1114.336914 219.926163,1115.108154 
	C220.046738,1115.137695 220.030930,1115.724609 220.155212,1116.700317 
	C220.817398,1117.894531 221.403015,1118.440186 221.988647,1118.985962 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M301.608276,1130.817993 
	C298.055298,1130.159912 294.884277,1129.446533 291.220551,1128.622314 
	C291.756653,1127.375854 292.562897,1125.501465 293.065674,1124.332642 
	C296.280914,1124.030518 298.908478,1123.783569 302.204254,1123.473877 
	C303.325836,1125.446777 307.284607,1127.803467 301.608276,1130.817993 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M310.975006,1215.076538 
	C312.340881,1214.354126 313.717651,1213.021851 315.070129,1213.046143 
	C317.351349,1213.086914 319.617188,1213.987793 321.889465,1214.526978 
	C321.369080,1215.785767 321.087372,1217.230591 320.280640,1218.266357 
	C318.361298,1220.730591 315.966003,1221.582153 312.838318,1220.149658 
	C311.642456,1219.602051 310.035370,1219.952393 308.248627,1219.584961 
	C307.591980,1218.507202 307.302429,1217.738037 307.012848,1216.968872 
	C307.294464,1217.003784 307.576080,1217.038818 308.546326,1217.006592 
	C309.815002,1216.318359 310.394989,1215.697388 310.975006,1215.076538 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M189.964996,1125.265015 
	C191.157593,1126.250000 192.350174,1127.234985 193.682846,1128.335693 
	C193.925339,1127.473999 194.279968,1126.213745 194.578995,1125.151123 
	C196.729385,1124.976318 198.680634,1124.817871 201.880951,1124.557861 
	C199.585205,1126.563477 198.253555,1127.726807 195.842041,1129.833618 
	C198.766510,1129.605347 200.233307,1129.490845 202.555664,1129.309692 
	C197.890869,1134.277954 193.188080,1131.195557 188.406021,1130.707153 
	C188.663284,1128.989502 189.319794,1127.506470 189.990143,1126.315430 
	C190.091858,1127.105225 190.179718,1127.602905 190.267578,1128.100708 
	C190.501816,1128.027466 190.736038,1127.954102 190.970276,1127.880859 
	C190.652863,1127.250732 190.335449,1126.620605 189.993927,1125.809814 
	C189.969833,1125.629272 189.964981,1125.265015 189.964996,1125.265015 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M321.388885,1123.997070 
	C323.414551,1123.630615 325.440247,1123.264160 327.753845,1122.845703 
	C328.067169,1125.419189 328.314392,1127.449829 328.536407,1129.273438 
	C326.006409,1129.767578 323.598267,1130.242065 321.188324,1130.707520 
	C318.486908,1131.229126 315.812622,1131.680786 315.395447,1127.464355 
	C317.579712,1126.028198 319.484283,1125.012573 321.388885,1123.997070 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M430.983032,1216.941772 
	C429.315094,1217.725952 427.647125,1218.510010 425.979126,1219.294067 
	C425.667023,1217.765747 424.724640,1215.983398 425.210724,1214.776611 
	C425.754700,1213.426025 427.462433,1211.984619 428.897552,1211.678467 
	C431.305878,1211.164429 433.899231,1211.517456 436.632721,1211.929443 
	C436.199524,1213.907959 435.548309,1215.462646 434.897156,1217.017334 
	C435.871796,1217.190796 436.846436,1217.364136 437.821075,1217.537598 
	C437.705933,1218.127563 437.590790,1218.717529 437.475647,1219.307617 
	C435.904663,1218.861694 434.333679,1218.415771 432.874786,1217.321533 
	C432.759094,1216.322144 432.531250,1215.970825 432.303436,1215.619629 
	C431.863312,1216.060303 431.423187,1216.501099 430.983032,1216.941772 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M124.268326,1192.633179 
	C128.108246,1192.369141 131.948166,1192.104980 137.385208,1191.730957 
	C135.828598,1194.328979 134.874619,1195.921265 133.892120,1197.561035 
	C134.431656,1197.748901 135.468124,1198.109985 136.504593,1198.471069 
	C136.352661,1199.010742 136.200745,1199.550415 136.048813,1200.090210 
	C133.693481,1199.459839 131.338150,1198.829468 128.454636,1197.824829 
	C126.707069,1195.844849 125.487686,1194.239014 124.268326,1192.633179 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M449.077881,1212.980591 
	C451.229919,1212.474854 453.381958,1211.969238 455.533997,1211.463623 
	C455.676697,1211.765137 455.819427,1212.066772 455.962158,1212.368408 
	C454.613617,1213.504761 453.265106,1214.641113 450.829681,1216.693359 
	C453.598602,1216.520996 455.022278,1216.432495 456.445923,1216.343872 
	C456.614807,1216.582764 456.783661,1216.821655 456.952545,1217.060547 
	C455.627899,1217.843628 454.382812,1219.039551 452.960083,1219.313599 
	C450.793243,1219.731201 448.385742,1219.944336 446.314240,1219.382324 
	C445.237488,1219.090454 444.662109,1216.950439 444.271210,1215.284912 
	C446.147400,1214.276611 447.612640,1213.628662 449.077881,1212.980591 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M166.983109,1131.291748 
	C164.781143,1131.291748 162.579178,1131.291748 159.035172,1131.291748 
	C159.076736,1131.327881 158.004532,1130.395752 156.823410,1129.368896 
	C155.672211,1130.370972 154.636475,1131.272461 152.843353,1132.833130 
	C152.979919,1129.973145 153.070221,1128.081665 153.162567,1126.147705 
	C155.257767,1126.147705 157.242996,1126.147705 159.636887,1126.147705 
	C159.814270,1126.688477 160.134125,1127.663574 160.638657,1129.201660 
	C161.830078,1127.428955 162.719559,1126.105591 163.861923,1124.405884 
	C164.785492,1125.654663 165.615997,1126.777588 166.683594,1128.507324 
	C166.941513,1129.839966 166.962311,1130.565796 166.983109,1131.291748 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M63.230110,1157.217407 
	C61.236301,1159.588257 59.242493,1161.959106 56.455296,1165.273438 
	C52.571819,1161.081299 49.030190,1157.469360 45.811367,1153.589600 
	C45.229042,1152.887573 45.527462,1150.755005 46.194656,1149.862915 
	C47.561035,1148.035889 49.453743,1146.602539 51.527828,1144.971680 
	C51.925926,1144.936157 52.077126,1145.062622 52.031677,1145.402222 
	C50.273453,1147.881348 48.560677,1150.020874 46.326847,1152.811279 
	C47.874950,1154.016357 49.327965,1154.900269 50.474388,1156.081543 
	C56.392094,1162.179077 56.364674,1162.205688 62.576046,1156.582275 
	C62.939236,1156.935303 63.084675,1157.076416 63.230110,1157.217407 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M151.849457,1080.013184 
	C154.836349,1079.967041 157.423813,1079.967041 161.333694,1079.967041 
	C161.118973,1082.414551 161.789642,1086.027954 160.557220,1086.876221 
	C155.116425,1090.620850 157.864487,1082.373413 154.300339,1082.363892 
	C153.981476,1083.599854 153.637909,1084.931519 153.294357,1086.263184 
	C153.014832,1086.320679 152.735306,1086.378296 152.455795,1086.435791 
	C152.120544,1084.310303 151.785294,1082.184937 151.849457,1080.013184 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M369.718628,1244.135376 
	C369.960052,1244.464478 369.962006,1244.475342 369.952881,1244.479004 
	C370.273865,1245.231567 370.603973,1245.980347 371.032349,1246.952148 
	C366.792145,1246.952148 362.754791,1246.952148 358.237305,1246.952148 
	C358.087250,1245.737549 357.877441,1244.039307 357.688904,1242.513062 
	C359.792389,1242.513062 361.519226,1242.513062 363.557281,1242.513062 
	C363.668732,1242.815674 363.989044,1243.685303 364.417999,1244.849976 
	C365.429108,1243.409912 366.240326,1242.254517 367.313934,1240.725464 
	C368.213165,1242.006104 368.845184,1242.906128 369.718628,1244.135376 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M121.719971,1080.776733 
	C121.531891,1081.821655 120.949409,1082.995605 120.196152,1084.451660 
	C117.223015,1085.560059 114.767632,1089.513062 111.100937,1085.530762 
	C110.186378,1083.931641 109.619690,1082.487305 109.074905,1080.703369 
	C111.986053,1080.756470 114.866798,1081.252075 117.768692,1081.491089 
	C118.930328,1081.586914 120.138542,1081.117798 121.719971,1080.776733 
M114.650200,1084.913086 
	C114.981552,1084.714722 115.312904,1084.516235 115.644249,1084.317871 
	C115.185371,1084.330566 114.726501,1084.343140 114.650200,1084.913086 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M306.857483,1216.676758 
	C307.302429,1217.738037 307.591980,1218.507202 307.919312,1219.583740 
	C303.608185,1219.432739 299.259308,1218.974609 294.505951,1218.473877 
	C294.505951,1217.562256 294.505951,1215.986938 294.505951,1214.014526 
	C296.712830,1214.574707 298.705292,1215.080322 301.468170,1215.781616 
	C301.615204,1215.597168 302.601044,1214.360474 303.773376,1212.889771 
	C304.842377,1214.165283 305.772247,1215.274902 306.857483,1216.676758 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M122.197433,1098.982788 
	C123.273636,1096.432129 124.524796,1094.186646 125.801865,1091.620361 
	C129.041367,1091.126099 133.511658,1089.766113 134.313583,1093.907959 
	C135.238953,1098.687378 129.865952,1097.604492 127.182701,1098.858154 
	C125.811577,1099.498657 123.990242,1099.175537 122.197433,1098.982788 
M131.818680,1095.903076 
	C131.265076,1095.347900 130.711472,1094.792603 130.157898,1094.237305 
	C129.891159,1094.587769 129.624420,1094.938232 129.357681,1095.288696 
	C130.048477,1095.652832 130.739273,1096.016968 131.818680,1095.903076 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M343.999786,1156.984741 
	C345.481812,1156.984741 346.963837,1156.984741 348.280914,1156.984741 
	C348.784790,1158.597534 349.192566,1159.902832 349.600342,1161.208008 
	C351.097046,1159.686523 352.593750,1158.165039 354.582275,1156.143555 
	C355.770294,1158.097046 357.016510,1160.146118 358.262726,1162.195312 
	C353.848846,1160.019653 349.557709,1166.072998 345.247559,1161.743530 
	C343.140625,1160.240356 342.482758,1158.792480 343.999786,1156.984741 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M222.388931,1118.976807 
	C221.403015,1118.440186 220.817398,1117.894531 220.172607,1117.043701 
	C221.325623,1115.666992 222.537796,1114.595215 223.542297,1113.707031 
	C226.371780,1115.345093 229.046143,1116.893311 232.186020,1118.711060 
	C232.819931,1116.298218 233.208862,1114.817871 233.597794,1113.337402 
	C234.060181,1113.364380 234.522568,1113.391357 234.984970,1113.418335 
	C234.790161,1115.578003 235.340607,1118.953003 234.236649,1119.629883 
	C232.279770,1120.829712 229.311707,1120.617554 226.779510,1120.545776 
	C225.435623,1120.507935 224.118469,1119.524414 222.388931,1118.976807 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M100.725372,1114.108643 
	C100.598579,1117.119019 101.574608,1118.084961 104.633385,1116.514526 
	C104.394348,1117.564819 104.193321,1118.447998 104.002533,1119.658691 
	C101.572983,1121.047241 99.198296,1122.439331 97.464714,1118.750977 
	C97.161064,1118.104980 94.955711,1118.534546 93.803246,1118.051025 
	C92.797661,1117.629272 92.038246,1116.620728 91.170067,1115.871338 
	C92.114738,1114.957031 93.002449,1113.356445 94.016045,1113.272461 
	C96.075920,1113.101440 98.203812,1113.749878 100.725372,1114.108643 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M62.143227,1213.844971 
	C64.381691,1216.055054 66.620125,1218.265259 69.450195,1221.059570 
	C65.900787,1224.841431 62.879425,1229.160278 58.838387,1232.056641 
	C54.802876,1234.948975 53.555111,1230.306396 51.898907,1227.405640 
	C51.973919,1227.029419 51.990120,1227.008301 52.300838,1227.136230 
	C54.183357,1228.607544 55.755161,1229.950928 57.558289,1231.491943 
	C60.861332,1227.826904 63.787556,1224.579956 66.621788,1221.435181 
	C64.585144,1218.792603 62.983730,1216.714600 61.572731,1214.438965 
	C61.889832,1214.109131 62.016518,1213.977051 62.143227,1213.844971 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M225.484009,1197.175415 
	C225.386154,1195.182373 225.386154,1193.555664 225.386154,1190.781982 
	C227.887115,1191.641357 229.757629,1192.284058 231.836746,1192.998413 
	C231.986267,1192.822754 232.536880,1192.175537 233.712173,1190.794067 
	C233.712173,1193.919312 233.712173,1195.991577 233.712173,1198.338867 
	C231.011017,1198.073975 228.296448,1197.807983 225.484009,1197.175415 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M402.203552,1127.205078 
	C400.774811,1127.663574 398.192688,1128.706177 398.085846,1128.495117 
	C395.312073,1123.018311 393.898590,1127.286499 391.202972,1130.340332 
	C391.202972,1127.163086 390.441803,1124.498657 391.445251,1123.101929 
	C392.239502,1121.996582 395.195618,1122.402710 397.199493,1122.226074 
	C398.171234,1122.140625 399.160858,1122.258057 400.577820,1122.647461 
	C401.410034,1124.409424 401.806793,1125.807251 402.203552,1127.205078 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M163.958984,1120.036377 
	C163.303558,1120.034912 162.646255,1120.000610 161.993042,1120.037964 
	C160.013947,1120.151123 158.036423,1120.291748 156.058289,1120.421631 
	C156.506073,1118.127319 156.179428,1114.797119 157.566956,1113.759766 
	C160.250259,1111.753540 162.874878,1113.453003 164.542786,1116.997314 
	C164.502701,1118.360107 164.230850,1119.198242 163.958984,1120.036377 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M343.704529,1156.731689 
	C342.482758,1158.792480 343.140625,1160.240356 344.880554,1161.796875 
	C341.776825,1165.609741 339.498566,1165.674194 336.370636,1161.793335 
	C336.440369,1159.999268 336.707733,1158.713745 336.975037,1157.428101 
	C339.119781,1157.111572 341.264526,1156.795044 343.704529,1156.731689 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M178.879852,1158.209106 
	C180.041519,1158.103882 181.202286,1157.922974 182.365051,1157.908936 
	C184.969193,1157.877319 187.731552,1157.591187 186.386292,1161.861938 
	C186.097244,1162.779663 187.208389,1164.138184 187.643478,1165.216797 
	C184.905685,1165.216797 182.368286,1165.216797 179.647842,1165.216797 
	C179.397293,1164.489258 178.988708,1163.302612 178.121552,1161.804932 
	C178.068588,1160.398926 178.474213,1159.303955 178.879852,1158.209106 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M242.453918,1127.775024 
	C242.617249,1126.666626 242.773544,1125.810181 242.981384,1124.523682 
	C247.143387,1123.757568 247.143387,1123.757568 247.655212,1130.037964 
	C249.449051,1127.594849 250.650024,1125.959106 252.319580,1123.685181 
	C253.669647,1125.648315 254.940842,1127.496826 256.779633,1130.170654 
	C252.143219,1129.843506 248.489288,1129.585693 244.149170,1129.176880 
	C243.224533,1129.014526 242.986084,1129.003052 242.747620,1128.991577 
	C242.647385,1128.670044 242.547150,1128.348389 242.453918,1127.775024 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M334.837341,1218.832031 
	C332.273590,1219.394653 329.709869,1219.957275 326.931824,1220.566895 
	C326.823822,1211.625732 326.823822,1211.625732 333.733673,1212.937866 
	C334.307678,1215.217407 334.572510,1217.024780 334.837341,1218.832031 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M246.106995,1194.974121 
	C247.297165,1194.156128 248.487320,1193.338135 249.677490,1192.520142 
	C249.473694,1192.323730 249.269913,1192.127319 249.066116,1191.930908 
	C250.887390,1191.647827 252.708664,1191.364746 255.380798,1190.949341 
	C253.981689,1193.027832 252.961746,1194.543091 251.229355,1197.116577 
	C253.661896,1196.263306 254.903198,1195.827759 256.231628,1195.361816 
	C254.601868,1199.352539 249.140671,1200.119995 244.280899,1196.795166 
	C243.925735,1196.479126 243.919586,1196.077271 243.966705,1196.032227 
	C244.013809,1195.987305 244.108307,1195.897583 244.356964,1195.776123 
	C245.106079,1195.427856 245.606537,1195.201050 246.106995,1194.974121 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M212.309769,1197.919434 
	C212.741516,1196.039551 213.343781,1194.484619 213.931976,1192.570068 
	C215.469543,1191.818848 217.021194,1191.427124 219.848923,1190.713257 
	C219.945419,1193.490723 220.030319,1195.934326 220.115219,1198.377930 
	C217.570251,1198.333374 215.025269,1198.288940 212.309769,1197.919434 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M126.208099,1165.112549 
	C123.583252,1167.273438 121.126564,1168.225586 118.050217,1165.457153 
	C117.051720,1164.558594 114.737976,1165.121582 112.529243,1164.985474 
	C113.389259,1163.089966 114.352005,1160.968140 115.585701,1158.249023 
	C117.163986,1160.041382 118.286003,1161.315674 119.610634,1162.820068 
	C120.113182,1161.560303 120.522980,1160.533081 120.942726,1159.480957 
	C123.221565,1160.734131 119.386681,1166.632690 125.648186,1164.444092 
	C126.097252,1164.777710 126.152679,1164.945190 126.208099,1165.112549 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M453.475861,1127.360840 
	C453.077332,1126.729614 453.059204,1126.037720 453.080353,1125.004761 
	C453.655060,1123.701294 454.190491,1122.739136 454.972382,1121.333984 
	C456.208984,1122.652954 457.150360,1123.656982 458.408600,1124.999023 
	C459.077179,1124.018188 459.694519,1123.112427 460.036102,1122.611328 
	C461.675110,1124.620361 463.226501,1126.521973 465.501465,1129.310547 
	C462.674652,1128.919312 460.303558,1128.636841 457.950134,1128.247803 
	C456.570984,1128.019775 455.219971,1127.621826 453.475861,1127.360840 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M450.644653,1144.206787 
	C450.726471,1146.059082 450.513916,1147.700562 450.150024,1149.671631 
	C448.393036,1150.193848 446.787415,1150.386841 445.054962,1150.594971 
	C444.668274,1149.297974 444.227264,1147.818604 443.449890,1146.204834 
	C442.285645,1146.049683 441.457733,1146.028931 440.329102,1146.032959 
	C440.519043,1145.383301 440.971924,1144.162354 441.506683,1144.125366 
	C444.443512,1143.922241 447.400330,1144.008789 450.644653,1144.206787 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M465.032898,1212.066162 
	C466.772217,1212.035278 468.511566,1212.004272 470.590668,1212.366455 
	C471.407776,1213.995850 471.885101,1215.231934 472.362427,1216.468140 
	C469.536377,1217.190063 466.710327,1217.912109 463.560791,1218.099976 
	C463.835846,1215.732666 464.434387,1213.899414 465.032898,1212.066162 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M267.059631,1148.789917 
	C268.312805,1148.107544 269.565979,1147.425049 270.819153,1146.742676 
	C272.737305,1145.849121 273.729431,1153.108276 276.652252,1147.165527 
	C277.529785,1148.494141 278.380859,1149.782715 279.472992,1151.436157 
	C275.613220,1151.805054 271.973175,1152.153076 268.228241,1152.510986 
	C268.145081,1151.410767 268.059937,1150.284058 267.547363,1148.978027 
	C267.119934,1148.798462 267.059631,1148.789917 267.059631,1148.789917 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M123.859619,1192.525757 
	C125.487686,1194.239014 126.707069,1195.844849 128.059204,1197.796143 
	C128.191971,1198.141602 128.115326,1198.118774 127.710419,1198.003662 
	C126.245735,1198.517334 125.185959,1199.146118 124.126144,1199.774902 
	C122.706779,1198.690796 121.287453,1197.606934 119.807510,1196.476685 
	C116.618607,1200.499756 116.618607,1200.499756 114.738693,1197.141113 
	C114.873039,1196.610352 115.044197,1196.398193 115.545097,1195.942383 
	C118.322960,1194.426880 119.654884,1190.577148 123.859619,1192.525757 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M167.194046,1131.550659 
	C166.962311,1130.565796 166.941513,1129.839966 166.908188,1128.752197 
	C167.588776,1127.219360 168.281876,1126.048340 169.376068,1124.199829 
	C169.681320,1126.196411 169.551056,1127.770386 170.214462,1128.861938 
	C170.685486,1129.636719 172.227646,1129.760376 173.806610,1130.375244 
	C173.806610,1128.099487 173.806610,1126.689819 173.806610,1124.387939 
	C175.529083,1127.098999 176.774734,1129.059570 178.501099,1131.776733 
	C175.106689,1133.173584 171.276978,1132.740723 167.194046,1131.550659 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M173.555908,1243.266724 
	C174.261276,1245.540405 172.047653,1248.859985 176.949173,1248.826050 
	C178.382324,1248.937988 179.455048,1249.085449 180.527771,1249.232910 
	C180.462677,1249.733521 180.397583,1250.234131 180.332489,1250.734741 
	C176.515549,1250.264282 172.698624,1249.793945 168.815475,1249.315430 
	C167.390610,1243.428711 167.662613,1243.022949 173.555908,1243.266724 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M394.980652,1155.862061 
	C396.083191,1157.525513 397.659180,1159.462036 397.248688,1160.779663 
	C396.832611,1162.115234 394.541382,1163.110962 392.875610,1163.649658 
	C392.432343,1163.792969 390.846466,1161.829224 390.519501,1160.632202 
	C389.616333,1157.326050 392.208099,1156.601440 394.980652,1155.862061 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M320.833496,1111.892456 
	C322.656250,1112.176514 324.078766,1112.538452 325.906982,1113.003540 
	C325.934418,1113.273682 326.070343,1114.236694 326.119598,1115.204224 
	C326.168549,1116.165649 326.130310,1117.131348 326.130310,1118.393188 
	C324.674194,1118.258301 323.497101,1118.042603 322.326172,1118.071899 
	C321.233582,1118.099121 320.147461,1118.386719 317.254913,1118.846191 
	C318.648743,1115.830811 319.540985,1113.900635 320.833496,1111.892456 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M137.995514,1158.996704 
	C138.654343,1158.949341 139.315002,1158.844482 139.971695,1158.865112 
	C142.171844,1158.934326 145.477448,1158.196655 144.909073,1161.668213 
	C144.630005,1163.372437 141.904144,1165.460938 139.948120,1165.911865 
	C137.363022,1166.507935 134.446869,1165.668213 131.474915,1165.006592 
	C131.276794,1164.567627 131.251022,1164.350220 131.251022,1164.350220 
	C131.251022,1164.350220 131.316559,1164.306396 131.724915,1164.269775 
	C134.012650,1164.233276 135.892044,1164.233276 137.995514,1164.233276 
	C137.995514,1162.441162 137.995514,1160.718872 137.995514,1158.996704 
M141.392029,1160.910889 
	C140.951370,1161.734619 140.510696,1162.558472 140.070007,1163.382080 
	C140.641571,1163.465454 141.685410,1163.694458 141.711639,1163.609497 
	C141.970795,1162.769897 142.061234,1161.878052 141.392029,1160.910889 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M293.465149,1080.371582 
	C295.522736,1079.765259 297.387390,1079.463501 299.252075,1079.161743 
	C298.944672,1081.082520 299.021942,1083.204102 298.168152,1084.839722 
	C297.738953,1085.661987 295.617767,1085.601074 294.261780,1085.939697 
	C293.931946,1084.185181 293.602112,1082.430542 293.465149,1080.371582 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M336.633087,1157.315063 
	C336.707733,1158.713745 336.440369,1159.999268 336.064636,1161.608398 
	C334.140411,1162.722900 332.324554,1163.513916 330.233856,1163.723877 
	C329.981049,1161.103271 330.003082,1159.063477 330.025116,1157.023682 
	C332.113770,1157.083130 334.202454,1157.142578 336.633087,1157.315063 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M411.069458,1193.395874 
	C410.502777,1193.701782 409.862823,1193.920898 409.381104,1194.327515 
	C405.373077,1197.710571 403.437897,1195.880005 402.203003,1191.333008 
	C401.967834,1191.051514 401.951141,1191.091919 401.951141,1191.091919 
	C405.390076,1190.658325 408.829041,1190.224731 412.651245,1190.109619 
	C412.379517,1191.417236 411.724487,1192.406616 411.069458,1193.395874 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M402.799866,1150.697388 
	C402.039551,1150.750000 400.843292,1150.499878 400.847137,1150.269653 
	C400.875946,1148.536133 401.140869,1146.806641 401.380798,1144.634155 
	C402.467102,1145.424438 403.427032,1146.122803 404.634033,1147.000854 
	C405.634186,1146.222534 406.729523,1145.370239 408.377441,1144.087891 
	C408.377441,1146.725220 408.377441,1148.737427 408.377441,1150.403809 
	C406.628815,1150.403809 404.774231,1150.403809 402.799866,1150.697388 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M178.488922,1158.136230 
	C178.474213,1159.303955 178.068588,1160.398926 177.804657,1161.720947 
	C177.946365,1161.947998 177.519257,1162.034668 176.900909,1162.027832 
	C175.479813,1162.048950 174.677063,1162.076904 173.874313,1162.104736 
	C172.136703,1163.021973 170.399078,1163.939087 168.455444,1164.965088 
	C168.151459,1163.525391 167.787811,1161.803223 167.217346,1159.101562 
	C171.062164,1158.734741 174.580078,1158.399048 178.488922,1158.136230 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M214.596848,1243.612061 
	C214.929321,1244.946533 214.936539,1245.941162 214.936249,1247.239868 
	C213.068726,1248.699951 211.208694,1249.856079 208.457825,1251.565796 
	C208.457825,1247.985840 208.457825,1245.937744 208.457825,1243.272339 
	C210.323257,1243.272339 212.297424,1243.272339 214.596848,1243.612061 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M352.128632,1216.057861 
	C352.725372,1214.931519 353.322052,1213.805054 353.632690,1213.218628 
	C355.936523,1213.929810 357.838165,1214.516846 359.739777,1215.103882 
	C359.882843,1214.681763 360.025879,1214.259521 360.168915,1213.837402 
	C360.691467,1214.779907 361.214020,1215.722412 361.790070,1216.761353 
	C360.546692,1217.874634 359.337006,1218.957642 358.127319,1220.040649 
	C358.057648,1219.671265 357.987976,1219.301758 357.918335,1218.932373 
	C356.004730,1218.932373 354.089722,1218.970093 352.178864,1218.898071 
	C351.832611,1218.885010 351.414948,1218.478394 351.205444,1218.143799 
	C351.108459,1217.989136 351.432770,1217.570679 351.779846,1216.894775 
	C351.993561,1216.517700 352.128601,1216.057861 352.128632,1216.057861 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M321.099670,1123.635742 
	C319.484283,1125.012573 317.579712,1126.028198 315.285828,1127.103516 
	C313.237640,1127.855225 311.578796,1128.547119 309.939362,1129.230957 
	C308.244812,1122.966431 308.366791,1122.770264 314.504791,1122.914185 
	C316.608307,1122.963501 318.708649,1123.150269 321.099670,1123.635742 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M97.665611,1153.608887 
	C97.912392,1154.042969 97.917686,1154.258789 97.950546,1154.637817 
	C96.274406,1155.052368 94.588577,1155.303223 92.541122,1155.607910 
	C92.541122,1152.721191 92.541122,1150.474731 92.541122,1147.778809 
	C94.010979,1147.590698 95.550255,1147.393677 97.089531,1147.196777 
	C97.446732,1147.538940 97.803925,1147.881104 98.161125,1148.223389 
	C97.069786,1149.841431 95.978447,1151.459351 94.908112,1153.046265 
	C95.367432,1153.109741 96.386833,1153.250488 97.665611,1153.608887 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M201.437103,1219.703857 
	C201.062500,1219.068237 201.049713,1218.521729 201.052505,1217.566162 
	C201.619171,1216.214844 202.170242,1215.272461 202.949722,1213.939697 
	C204.237213,1215.509399 205.230301,1216.720093 206.546143,1218.324341 
	C206.989594,1216.834473 207.286377,1215.837280 207.531067,1215.015137 
	C208.760681,1216.644897 209.972321,1218.250854 211.596069,1220.147095 
	C211.956299,1220.763062 211.903564,1221.088745 211.849960,1221.414429 
	C208.499619,1220.873901 205.149277,1220.333374 201.437103,1219.703857 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M335.068970,1218.603760 
	C334.572510,1217.024780 334.307678,1215.217407 334.173889,1213.076172 
	C336.014832,1212.742065 337.724762,1212.742065 339.377533,1212.742065 
	C339.671143,1215.312988 339.910034,1217.404663 340.185089,1219.813354 
	C338.537079,1219.328247 336.918823,1218.851929 335.068970,1218.603760 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M337.114624,1195.669922 
	C334.851532,1196.990234 332.707550,1198.790039 330.778046,1194.954834 
	C330.381866,1194.167236 327.942017,1194.407837 326.444763,1194.174683 
	C327.086609,1190.724854 327.587799,1190.444702 333.874115,1190.708130 
	C335.237762,1192.644897 336.176178,1194.157349 337.114624,1195.669922 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M416.759827,1111.126221 
	C418.759460,1112.090942 420.333588,1112.984375 422.645935,1114.296753 
	C420.674408,1115.329224 418.937958,1116.238525 417.155457,1117.519775 
	C416.339691,1117.958984 415.569946,1118.026123 414.415527,1118.058472 
	C413.675415,1115.946899 413.319946,1113.869995 412.932556,1111.439575 
	C414.045166,1111.075562 415.189728,1111.065186 416.759827,1111.126221 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M391.933594,1114.264160 
	C392.964508,1114.606323 393.802368,1115.226807 394.640198,1115.847290 
	C393.905579,1116.367920 393.170959,1116.888550 392.239624,1117.654785 
	C390.312469,1118.220093 388.582031,1118.539795 385.714844,1119.069458 
	C385.714844,1117.179321 385.149902,1115.028687 385.884918,1113.504883 
	C386.528900,1112.169800 388.632507,1111.538940 390.091309,1110.596924 
	C390.692139,1111.317627 391.292969,1112.038452 391.893799,1112.759277 
	C389.827362,1113.377197 387.760925,1113.995117 385.694458,1114.613159 
	C386.327667,1115.368286 386.960846,1116.123413 387.594025,1116.878540 
	C388.976196,1116.099854 390.358368,1115.321167 391.933594,1114.264160 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M351.720947,1215.881348 
	C352.128601,1216.057861 351.993561,1216.517700 351.945831,1216.583008 
	C349.700043,1217.255005 347.501953,1217.861816 344.362335,1218.728394 
	C344.795776,1216.883667 344.597992,1213.605957 346.013153,1212.602295 
	C348.570190,1210.788574 350.401520,1213.106079 351.720947,1215.881348 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M189.663010,1125.059814 
	C189.964981,1125.265015 189.969833,1125.629272 189.982849,1125.816772 
	C189.995850,1126.004272 189.976273,1126.023438 189.976288,1126.023438 
	C189.319794,1127.506470 188.663284,1128.989502 188.032379,1130.807373 
	C186.767212,1131.587036 185.476425,1132.031738 183.817474,1132.603271 
	C183.817474,1129.747803 183.817474,1127.636353 183.817474,1124.854492 
	C185.629318,1124.854492 187.495178,1124.854492 189.663010,1125.059814 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M464.670868,1211.919556 
	C464.434387,1213.899414 463.835846,1215.732666 463.314240,1217.868042 
	C457.117462,1221.009033 460.202545,1213.683960 457.127441,1213.049072 
	C457.241364,1212.503174 457.355286,1211.957275 457.469238,1211.411377 
	C459.749115,1211.531860 462.028961,1211.652466 464.670868,1211.919556 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M472.640045,1216.580322 
	C471.885101,1215.231934 471.407776,1213.995850 470.940063,1212.333740 
	C473.607880,1211.150024 476.961914,1208.889648 476.120117,1214.808594 
	C475.980438,1215.790894 477.844055,1217.058228 479.738037,1219.351685 
	C476.761810,1218.191284 474.839722,1217.441895 472.640045,1216.580322 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M455.033447,1157.021362 
	C456.820374,1156.697754 458.607300,1156.374268 460.394226,1156.050659 
	C460.590637,1157.981689 460.787048,1159.912720 460.983429,1161.843750 
	C459.236206,1161.837769 457.488983,1161.831787 456.137512,1161.368530 
	C457.556763,1160.745850 458.580292,1160.580566 459.603821,1160.415283 
	C459.537048,1160.136475 459.470306,1159.857788 459.403564,1159.579102 
	C458.035583,1159.579102 456.667603,1159.579102 455.239960,1159.261963 
	C455.131348,1158.303711 455.082397,1157.662598 455.033447,1157.021362 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M290.422729,1118.208984 
	C290.792114,1118.590820 290.807831,1118.937012 290.814575,1119.542969 
	C287.495514,1119.076294 284.185455,1118.350220 280.605042,1117.564697 
	C281.301117,1116.094238 282.260193,1114.067993 283.321381,1111.826050 
	C285.764526,1114.124268 287.916809,1116.148926 290.422729,1118.208984 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M176.194916,1199.277588 
	C177.063889,1198.305298 178.207047,1197.551025 179.398224,1196.765015 
	C177.900787,1196.030884 176.467270,1195.328125 174.954529,1194.260254 
	C176.276413,1192.914917 177.677521,1191.934814 179.137436,1190.913696 
	C182.902786,1198.060547 182.372543,1199.505371 176.194916,1199.277588 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M402.525848,1127.284546 
	C401.806793,1125.807251 401.410034,1124.409424 401.041992,1122.622314 
	C402.319916,1122.175415 403.569153,1122.117554 405.037720,1122.367065 
	C405.257080,1122.674316 405.025055,1122.972046 404.722351,1123.029541 
	C404.037018,1123.450928 403.654388,1123.815063 403.271729,1124.179077 
	C403.789398,1124.388062 404.307068,1124.597046 404.900757,1124.888794 
	C404.976807,1124.971558 405.130188,1125.135986 405.173645,1125.476074 
	C406.480469,1125.877441 407.743835,1125.938721 409.007233,1126.000000 
	C410.176971,1126.824951 411.346710,1127.649902 412.761719,1128.647827 
	C409.106171,1130.923706 405.969635,1129.223145 402.525848,1127.284546 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M101.850876,1250.801758 
	C99.294525,1250.403931 96.738174,1250.006226 93.559692,1249.511597 
	C94.157570,1248.103271 95.096336,1245.892090 96.363731,1242.906738 
	C97.655930,1244.657227 98.542984,1245.858765 99.703445,1247.573608 
	C100.601524,1248.991821 101.226196,1249.896729 101.850876,1250.801758 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M54.545536,1141.460205 
	C55.459835,1141.071045 56.917961,1140.107178 57.206078,1140.379761 
	C60.902210,1143.877197 64.458282,1147.526001 67.936913,1151.241943 
	C68.154343,1151.474243 67.432373,1152.585693 66.669006,1153.246826 
	C66.066757,1153.083984 65.942917,1152.962402 65.865631,1152.501709 
	C62.337414,1148.857178 58.762630,1145.551514 55.030964,1142.046387 
	C54.764576,1141.718140 54.655067,1141.589233 54.545536,1141.460205 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M129.836945,1256.207520 
	C129.877716,1256.031982 129.918503,1255.856445 130.000687,1255.413818 
	C131.809052,1255.069092 133.576050,1254.991577 135.314667,1254.915283 
	C135.623413,1257.395874 135.886246,1259.507568 136.190704,1261.953735 
	C134.531418,1261.414917 132.927017,1260.894043 130.863022,1260.420654 
	C130.214600,1259.047852 130.025772,1257.627686 129.836945,1256.207520 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M97.678757,1170.198486 
	C98.253960,1170.862793 98.460281,1171.477905 98.804932,1172.297852 
	C98.603111,1172.859863 98.281532,1173.237549 97.919579,1173.571289 
	C96.428421,1174.946167 94.925110,1176.307861 93.426270,1177.674316 
	C92.900177,1175.403931 92.374077,1173.133667 91.847977,1170.863281 
	C92.384155,1170.761475 92.920334,1170.659668 93.456512,1170.557861 
	C93.805557,1172.127319 94.154602,1173.696777 94.440971,1174.984375 
	C95.367424,1173.422974 96.338646,1171.786255 97.678757,1170.198486 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M290.234558,1249.338135 
	C289.963135,1247.091919 289.896637,1245.084229 289.842712,1242.721191 
	C291.106476,1242.087402 292.357635,1241.808594 293.827332,1241.822266 
	C293.961853,1244.192139 293.877808,1246.269531 293.865082,1248.793945 
	C292.770782,1249.352783 291.605133,1249.464600 290.234558,1249.338135 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M408.720459,1158.923096 
	C409.305969,1160.250977 409.629547,1161.305298 409.981079,1162.648682 
	C407.701080,1162.461060 405.393158,1161.984253 402.644745,1161.416504 
	C403.293854,1160.016602 404.279266,1157.891479 405.618286,1155.003662 
	C406.812439,1156.536621 407.635468,1157.593018 408.720459,1158.923096 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M102.296082,1250.886841 
	C101.226196,1249.896729 100.601524,1248.991821 99.973099,1247.791016 
	C100.284752,1246.309570 100.600151,1245.124146 101.059868,1243.396118 
	C102.496323,1245.647949 103.516632,1247.247437 104.536949,1248.846924 
	C105.344551,1247.412964 106.152153,1245.979004 107.582298,1243.439819 
	C107.787247,1246.390869 107.927284,1248.407349 108.105392,1250.971924 
	C105.935608,1250.971924 104.338455,1250.971924 102.296082,1250.886841 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M306.423981,1118.882080 
	C308.023468,1117.645874 310.023621,1116.503296 312.434937,1115.125977 
	C309.824799,1114.203857 307.947998,1113.540894 306.115906,1112.531738 
	C306.484985,1112.116943 306.810211,1111.985718 307.133636,1111.989868 
	C309.651550,1112.022583 313.414703,1110.827393 313.579651,1114.502319 
	C313.751007,1118.319214 310.485748,1119.560791 306.423981,1118.882080 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M140.044083,1219.037354 
	C141.410904,1219.910278 142.777740,1220.783203 144.270172,1221.736328 
	C141.750854,1225.500977 139.306427,1221.034912 136.215393,1220.676270 
	C134.242661,1220.387695 132.775040,1220.291870 131.307419,1220.196045 
	C131.178696,1219.399902 131.049957,1218.603882 131.362854,1217.417480 
	C133.826065,1217.519043 135.847641,1218.010620 138.233582,1218.587158 
	C139.079987,1218.793823 139.562042,1218.915649 140.044083,1219.037354 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M145.283646,1085.738403 
	C144.347107,1085.751221 143.738327,1085.580200 143.129547,1085.409180 
	C144.014587,1083.516846 144.899597,1081.624512 146.297333,1078.635742 
	C148.004410,1081.745361 149.145645,1083.824219 150.641571,1086.549316 
	C148.492599,1086.124268 147.051987,1085.839478 145.283646,1085.738403 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M396.056396,1195.023071 
	C394.643250,1195.981934 393.230103,1196.940796 391.033081,1198.431396 
	C391.033081,1194.840454 391.033081,1192.742554 391.033081,1189.604248 
	C393.043304,1189.856323 395.089844,1189.798828 396.797791,1190.531128 
	C397.268402,1190.732788 396.728851,1193.290771 396.285156,1194.523071 
	C395.249573,1193.288086 394.581726,1192.297485 393.913818,1191.306885 
	C393.255127,1192.085938 392.596466,1192.864990 391.937805,1193.644043 
	C393.310699,1194.103760 394.683533,1194.563354 396.056396,1195.023071 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M237.265549,1163.823242 
	C236.681076,1163.892334 236.449722,1163.725952 236.164368,1163.297363 
	C236.787567,1161.304077 237.517975,1159.499023 238.680832,1156.625122 
	C240.607269,1159.498047 242.020950,1161.606323 243.797897,1164.256348 
	C241.056503,1163.994751 239.310974,1163.828247 237.265549,1163.823242 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M137.611145,1158.966309 
	C137.995514,1160.718872 137.995514,1162.441162 137.995514,1164.233276 
	C135.892044,1164.233276 134.012650,1164.233276 131.763031,1164.259521 
	C131.272858,1162.880371 131.152924,1161.474731 131.023560,1159.958496 
	C133.268753,1159.588379 135.247772,1159.262085 137.611145,1158.966309 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M267.191742,1148.424072 
	C267.059631,1148.789917 267.119934,1148.798462 267.135254,1148.773926 
	C266.159607,1150.381592 265.168640,1152.013916 263.693176,1154.444336 
	C262.188171,1151.837891 261.058929,1149.882202 259.929688,1147.926514 
	C260.192902,1147.719604 260.456116,1147.512817 260.719330,1147.305908 
	C261.607483,1148.045410 262.495636,1148.784912 263.502716,1149.623413 
	C264.411865,1148.504028 265.305695,1147.403564 266.199524,1146.303101 
	C266.574341,1146.888062 266.949097,1147.473145 267.191742,1148.424072 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M280.801361,1160.854004 
	C280.945343,1161.699341 280.939117,1162.242554 280.922028,1163.195312 
	C278.897980,1163.604736 276.884796,1163.604736 274.431061,1163.604736 
	C275.368164,1161.527466 276.369263,1159.308228 277.674286,1156.415405 
	C279.016205,1158.280029 279.833649,1159.415894 280.801361,1160.854004 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M425.742859,1193.594238 
	C425.876984,1192.520630 426.023132,1191.735352 426.138885,1191.113525 
	C429.679810,1192.586060 433.127350,1194.019775 436.799316,1195.741943 
	C433.893372,1195.571045 430.763062,1195.111694 427.029053,1194.809814 
	C426.425354,1194.967407 425.966858,1195.016846 425.966858,1195.016846 
	C425.896210,1194.638672 425.825562,1194.260620 425.742859,1193.594238 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M163.967850,1120.031250 
	C164.230850,1119.198242 164.502701,1118.360107 164.815735,1117.193604 
	C165.540161,1115.669189 166.223404,1114.473145 167.147354,1112.855591 
	C168.488525,1114.564819 169.519882,1115.879150 170.814362,1117.474365 
	C171.043304,1118.337036 171.009140,1118.918823 170.974609,1119.799805 
	C169.153214,1119.895996 167.332184,1119.692871 164.996063,1119.749390 
	C164.308777,1120.020142 164.136536,1120.031372 163.964310,1120.042480 
	C163.964310,1120.042480 163.976715,1120.026001 163.967850,1120.031250 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M212.262878,1221.573608 
	C211.903564,1221.088745 211.956299,1220.763062 211.999359,1220.189453 
	C212.372086,1218.108643 212.753662,1216.275757 213.179153,1214.231934 
	C216.527908,1215.536499 212.226364,1221.644775 219.656021,1219.439087 
	C219.102020,1220.124268 218.548004,1220.809570 217.994003,1221.494873 
	C216.221268,1221.574097 214.448517,1221.653442 212.262878,1221.573608 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M435.026733,1216.651611 
	C435.548309,1215.462646 436.199524,1213.907959 437.025818,1212.046631 
	C437.887207,1211.795776 438.573486,1211.851318 439.632538,1212.218262 
	C440.647583,1213.011108 441.289795,1213.492432 441.932068,1213.973755 
	C441.840363,1215.395752 441.748657,1216.817871 441.563477,1219.689819 
	C440.401245,1217.274170 439.847290,1216.122925 439.149261,1214.672241 
	C437.791626,1215.220825 436.473969,1215.753296 435.026733,1216.651611 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M106.288467,1098.402100 
	C105.963219,1096.459961 105.894417,1094.745728 105.825478,1092.608887 
	C106.648697,1092.126465 107.472046,1092.066528 108.710434,1092.070801 
	C109.940590,1093.669922 110.755714,1095.204834 111.632805,1096.856445 
	C111.497604,1096.929810 110.432167,1097.507812 109.314575,1098.399902 
	C108.356590,1098.685913 107.450752,1098.657959 106.288467,1098.402100 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M409.382996,1126.039185 
	C407.743835,1125.938721 406.480469,1125.877441 405.457458,1125.373535 
	C406.124451,1124.294922 406.551086,1123.658813 406.977722,1123.022949 
	C408.505920,1122.780884 410.034149,1122.538940 411.882324,1122.246216 
	C413.430450,1124.380127 413.445923,1126.013184 409.382996,1126.039185 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M329.724945,1156.923096 
	C330.003082,1159.063477 329.981049,1161.103271 329.964752,1163.582031 
	C329.083649,1163.832153 328.196808,1163.643311 327.141296,1163.145874 
	C326.719452,1161.784058 326.466278,1160.730713 326.093994,1159.365479 
	C327.124847,1158.309692 328.274811,1157.566040 329.724945,1156.923096 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M337.395996,1195.800293 
	C336.176178,1194.157349 335.237762,1192.644897 334.282959,1190.798706 
	C336.329315,1190.618286 339.728851,1187.526855 339.309082,1192.552490 
	C338.221069,1192.061646 337.542511,1191.729858 336.863983,1191.398193 
	C336.722595,1191.751343 336.581207,1192.104614 336.439819,1192.457886 
	C337.312653,1192.651611 338.185455,1192.845337 339.058289,1193.039062 
	C339.233795,1193.570190 339.409302,1194.101196 339.785492,1194.828003 
	C339.775665,1195.290283 339.565155,1195.556885 339.128174,1195.951172 
	C338.493591,1196.029541 338.085480,1195.980225 337.395996,1195.800293 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M60.954346,1240.658081 
	C57.180653,1238.771606 54.884521,1241.229004 52.299507,1243.918701 
	C53.849411,1237.812134 56.826580,1236.567261 60.954346,1240.658081 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M246.190369,1194.654053 
	C245.606537,1195.201050 245.106079,1195.427856 244.324020,1195.448242 
	C243.422241,1194.732544 242.859863,1193.912476 242.168915,1193.784058 
	C241.020630,1193.570679 239.789856,1193.801392 238.593826,1193.845093 
	C238.294357,1189.618896 241.383911,1191.492798 243.808090,1191.631592 
	C244.943420,1192.358521 245.560455,1192.689941 246.177490,1193.021362 
	C246.209579,1193.458862 246.241653,1193.896484 246.190369,1194.654053 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M62.348770,1256.119507 
	C63.560955,1253.237915 65.121536,1250.442993 66.842697,1247.305664 
	C67.349045,1248.329102 68.423866,1250.188599 67.898369,1250.964355 
	C66.541832,1252.967163 64.478310,1254.491089 62.348770,1256.119507 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M53.066982,1257.310181 
	C55.420746,1256.396606 57.810417,1261.490967 60.676094,1257.042236 
	C60.023350,1258.488525 59.014683,1259.975952 58.006012,1261.463257 
	C56.395596,1260.185547 54.785183,1258.907959 53.066982,1257.310181 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M301.853882,1150.180664 
	C300.689331,1149.199829 300.006226,1148.188721 299.072540,1147.535767 
	C298.269196,1146.973999 297.180054,1146.820801 296.328735,1146.222900 
	C298.029663,1145.947632 299.694916,1145.626099 301.192261,1145.992065 
	C303.956238,1146.667236 304.612885,1148.212402 301.853882,1150.180664 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M137.869217,1218.502197 
	C135.847641,1218.010620 133.826065,1217.519043 131.401321,1217.014160 
	C132.371658,1212.978882 134.995102,1214.524414 137.736511,1216.043823 
	C137.963699,1216.749390 137.954300,1216.952759 137.936310,1217.382568 
	C137.924744,1217.907959 137.905228,1218.205688 137.869217,1218.502197 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M454.799927,1156.928223 
	C455.082397,1157.662598 455.131348,1158.303711 455.154846,1159.387695 
	C455.129364,1159.830444 454.933105,1160.061890 454.623352,1160.026123 
	C453.881287,1160.346436 453.448944,1160.702637 453.016602,1161.058838 
	C453.016632,1161.058838 453.062988,1161.024536 453.078735,1161.048462 
	C451.863861,1160.918945 450.633301,1160.765625 448.072052,1160.446289 
	C450.284119,1158.729492 451.640045,1157.677246 453.165192,1156.300293 
	C453.745056,1156.262085 454.155731,1156.548584 454.799927,1156.928223 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M145.910141,1116.162231 
	C145.956665,1115.641113 146.003189,1115.120117 146.050354,1114.339355 
	C147.521896,1113.777954 148.992813,1113.476074 150.463730,1113.174194 
	C150.686310,1113.517212 150.908890,1113.860107 151.131470,1114.203125 
	C150.136444,1115.267700 149.141418,1116.332275 147.667953,1117.675049 
	C146.842087,1117.975708 146.494644,1117.998169 146.020050,1117.726807 
	C145.898651,1117.009521 145.904404,1116.585815 145.910141,1116.162231 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M442.174316,1213.870850 
	C441.289795,1213.492432 440.647583,1213.011108 439.982147,1212.194824 
	C443.018890,1211.982666 446.078796,1212.105591 449.108337,1212.604614 
	C447.612640,1213.628662 446.147400,1214.276611 444.328308,1214.965576 
	C443.455139,1214.593750 442.935852,1214.180786 442.174316,1213.870850 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M411.098816,1193.838501 
	C411.724487,1192.406616 412.379517,1191.417236 413.058350,1190.126831 
	C413.082153,1189.825684 413.467316,1189.695190 413.676331,1190.116699 
	C414.250275,1191.687012 414.615173,1192.835693 414.980103,1193.984497 
	C414.608582,1194.792358 414.237030,1195.600098 413.865479,1196.407837 
	C412.953033,1195.698975 412.040588,1194.989990 411.098816,1193.838501 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M47.633995,1247.911377 
	C49.331066,1250.089722 50.659283,1252.214478 52.001240,1254.687256 
	C49.498802,1253.260254 44.814945,1252.919678 47.633995,1247.911377 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M129.567093,1256.363403 
	C130.025772,1257.627686 130.214600,1259.047852 130.681534,1260.690674 
	C130.544479,1261.290039 130.129318,1261.666626 129.402969,1262.056885 
	C127.900162,1261.315430 126.708542,1260.560547 125.161865,1259.580688 
	C126.619461,1258.501709 127.958351,1257.510498 129.567093,1256.363403 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M238.476562,1194.158936 
	C239.789856,1193.801392 241.020630,1193.570679 242.168915,1193.784058 
	C242.859863,1193.912476 243.422241,1194.732544 244.075348,1195.569702 
	C244.108307,1195.897583 244.013809,1195.987305 243.574615,1196.002930 
	C241.614120,1196.377441 240.092819,1196.736206 238.571518,1197.094849 
	C238.500778,1196.220825 238.430038,1195.346680 238.476562,1194.158936 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M130.858215,1164.201172 
	C131.251022,1164.350220 131.276794,1164.567627 131.249390,1164.670776 
	C129.826263,1164.866333 128.430527,1164.958618 126.621445,1165.081787 
	C126.152679,1164.945190 126.097252,1164.777710 126.017761,1164.346924 
	C126.066521,1162.568604 126.139336,1161.053711 126.225349,1159.264282 
	C127.933144,1161.192749 129.199280,1162.622437 130.858215,1164.201172 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M173.869537,1162.475098 
	C174.677063,1162.076904 175.479813,1162.048950 176.683929,1162.047729 
	C177.178818,1163.497681 177.272354,1164.921021 177.365891,1166.344482 
	C177.004959,1166.572388 176.644012,1166.800415 176.283081,1167.028320 
	C175.476974,1165.634033 174.670883,1164.239624 173.869537,1162.475098 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M138.233582,1218.587158 
	C137.905228,1218.205688 137.924744,1217.907959 138.195145,1217.229980 
	C138.546143,1216.478394 138.629715,1216.105835 138.713318,1215.733398 
	C140.227402,1215.373169 141.741486,1215.012939 144.508072,1214.354614 
	C142.630600,1216.506226 141.624283,1217.659424 140.331024,1218.925049 
	C139.562042,1218.915649 139.079987,1218.793823 138.233582,1218.587158 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M131.199554,1220.518799 
	C132.775040,1220.291870 134.242661,1220.387695 135.921021,1220.787109 
	C134.383118,1222.396729 132.611755,1224.162231 131.199554,1220.518799 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M255.438538,1086.223267 
	C256.923248,1085.063599 258.724304,1084.046143 260.895264,1083.049072 
	C259.428375,1084.168091 257.591644,1085.266846 255.438538,1086.223267 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M310.763184,1214.799194 
	C310.394989,1215.697388 309.815002,1216.318359 308.971558,1216.986572 
	C308.433258,1215.873779 308.158417,1214.713501 307.664246,1212.627441 
	C309.163849,1213.611450 309.857605,1214.066650 310.763184,1214.799194 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M100.777298,1148.988403 
	C101.459885,1150.291016 101.942619,1151.395142 102.425362,1152.499146 
	C101.821037,1152.419678 101.216705,1152.340210 100.339676,1152.141846 
	C100.237144,1150.945190 100.407303,1149.867554 100.777298,1148.988403 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M238.578247,1197.478027 
	C240.092819,1196.736206 241.614120,1196.377441 243.527496,1196.047974 
	C243.919586,1196.077271 243.925735,1196.479126 243.939972,1196.679443 
	C242.164459,1197.206909 240.374710,1197.534058 238.578247,1197.478027 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M296.793518,1153.440186 
	C297.799225,1152.367798 299.052673,1151.603027 300.682434,1150.661987 
	C299.719604,1151.573242 298.380432,1152.660522 296.793518,1153.440186 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M415.346130,1193.902710 
	C414.615173,1192.835693 414.250275,1191.687012 413.876801,1190.150879 
	C414.039825,1189.694580 414.211456,1189.625732 414.658875,1189.525391 
	C415.830475,1190.513916 416.726257,1191.533569 417.713196,1192.839600 
	C417.106934,1193.357544 416.409546,1193.589233 415.346130,1193.902710 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M146.147202,1118.020752 
	C146.494644,1117.998169 146.842087,1117.975708 147.450562,1117.955811 
	C147.755875,1118.132324 147.800156,1118.306152 147.680984,1118.901611 
	C147.354996,1119.917847 147.192490,1120.512451 147.029968,1121.107178 
	C146.470428,1121.118042 145.910889,1121.129028 145.178467,1120.666504 
	C144.999191,1119.769897 144.992783,1119.347046 145.173965,1118.764893 
	C145.589035,1118.364502 145.850922,1118.169556 146.147202,1118.020752 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M140.580811,1119.299805 
	C139.952133,1118.454834 139.592819,1117.663574 139.233521,1116.872437 
	C139.921738,1116.814087 140.609955,1116.755859 141.710876,1117.089844 
	C142.580597,1117.782593 143.037613,1118.082764 143.217010,1118.728271 
	C142.770966,1119.316162 142.602539,1119.558838 142.434097,1119.801392 
	C141.906128,1119.652100 141.378159,1119.502930 140.580811,1119.299805 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M260.799805,1079.666748 
	C259.060242,1079.942261 257.105652,1079.879150 255.093079,1079.487305 
	C256.885010,1079.215088 258.734924,1079.271606 260.799805,1079.666748 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M455.214478,1159.704834 
	C456.667603,1159.579102 458.035583,1159.579102 459.403564,1159.579102 
	C459.470306,1159.857788 459.537048,1160.136475 459.603821,1160.415283 
	C458.580292,1160.580566 457.556763,1160.745850 456.163513,1160.948486 
	C455.793762,1160.985596 455.441254,1161.060059 455.187744,1160.810059 
	C454.934204,1160.559937 454.933105,1160.061890 454.933105,1160.061890 
	C454.933105,1160.061890 455.129364,1159.830444 455.214478,1159.704834 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M124.466064,1199.886963 
	C125.185959,1199.146118 126.245735,1198.517334 127.678291,1197.979980 
	C126.969368,1198.713745 125.887657,1199.356323 124.466064,1199.886963 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M286.598663,1152.770752 
	C287.404724,1152.632202 288.590454,1152.529419 289.902527,1152.722900 
	C289.012024,1152.948364 287.995148,1152.877441 286.598663,1152.770752 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M292.446106,1152.903564 
	C293.034332,1152.744629 293.992706,1152.564087 294.993347,1152.706055 
	C294.295807,1152.979736 293.556030,1152.930908 292.446106,1152.903564 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M246.355652,1192.816162 
	C245.560455,1192.689941 244.943420,1192.358521 244.159637,1191.825928 
	C244.839844,1191.953369 245.686844,1192.282104 246.355652,1192.816162 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M51.667053,1243.909912 
	C51.555153,1244.643188 51.087910,1245.320190 50.293236,1246.001953 
	C50.414440,1245.288940 50.863075,1244.571411 51.667053,1243.909912 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M406.732544,1122.911621 
	C406.551086,1123.658813 406.124451,1124.294922 405.414001,1125.033447 
	C405.130188,1125.135986 404.976807,1124.971558 404.950867,1124.587280 
	C404.958313,1123.792725 404.991669,1123.382324 405.025024,1122.972046 
	C405.025055,1122.972046 405.257080,1122.674316 405.380737,1122.527344 
	C405.832031,1122.520508 406.159698,1122.660400 406.732544,1122.911621 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M50.659370,1226.317627 
	C50.038361,1225.876587 49.417351,1225.435425 48.833214,1224.564697 
	C48.870087,1224.134888 49.182724,1223.797729 49.465179,1223.905762 
	C50.200466,1224.644897 50.653297,1225.275757 50.994438,1226.009521 
	C50.882748,1226.112183 50.659367,1226.317627 50.659370,1226.317627 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M242.575516,1129.194092 
	C242.986084,1129.003052 243.224533,1129.014526 243.818237,1129.071777 
	C243.876236,1129.637207 243.578979,1130.156616 243.281693,1130.676025 
	C242.988922,1130.249634 242.696167,1129.823120 242.575516,1129.194092 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M144.986374,1118.924072 
	C144.992783,1119.347046 144.999191,1119.769897 145.021042,1120.509644 
	C144.304245,1120.623901 143.572021,1120.421387 142.636948,1120.010254 
	C142.602539,1119.558838 142.770966,1119.316162 143.475540,1118.788452 
	C144.336578,1118.643555 144.661469,1118.783813 144.986374,1118.924072 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M150.871017,1120.653076 
	C150.196350,1120.513672 149.790222,1120.159912 149.384094,1119.806030 
	C149.618347,1119.614380 150.027710,1119.230591 150.060196,1119.260254 
	C150.452271,1119.617676 150.788635,1120.036133 150.871017,1120.653076 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M431.305145,1216.927124 
	C431.423187,1216.501099 431.863312,1216.060303 432.303436,1215.619629 
	C432.531250,1215.970825 432.759094,1216.322144 432.920715,1216.967285 
	C432.445465,1217.145020 432.036346,1217.028687 431.305145,1216.927124 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M63.332840,1242.752441 
	C62.652191,1242.610474 61.752598,1242.215454 60.920959,1241.389893 
	C61.697239,1241.472778 62.405567,1241.986084 63.332840,1242.752441 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M66.974770,1246.695435 
	C66.271767,1246.567749 65.506660,1246.136230 64.869888,1245.333252 
	C65.636375,1245.438477 66.274521,1245.915039 66.974770,1246.695435 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M64.660995,1155.506348 
	C64.343628,1155.973755 64.026260,1156.441284 63.469501,1157.062988 
	C63.084675,1157.076416 62.939236,1156.935303 62.741608,1156.370361 
	C63.059399,1155.566162 63.429379,1155.186035 64.003326,1154.995117 
	C64.358528,1155.291626 64.509758,1155.399048 64.660995,1155.506348 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M60.506977,1212.391357 
	C60.966114,1212.709229 61.425240,1213.027100 62.013786,1213.594971 
	C62.016518,1213.977051 61.889832,1214.109131 61.355152,1214.284058 
	C60.547680,1213.973755 60.148193,1213.620483 59.947151,1213.055908 
	C60.266048,1212.693604 60.386509,1212.542480 60.506977,1212.391357 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M401.985840,1191.042236 
	C401.837860,1190.773438 401.671844,1190.513672 401.505859,1190.254028 
	C401.586914,1190.436035 401.667938,1190.617920 401.850037,1190.945923 
	C401.951141,1191.091919 401.967834,1191.051514 401.985840,1191.042236 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M52.911560,1142.919189 
	C53.290981,1142.518433 53.670403,1142.117554 54.297691,1141.588623 
	C54.655067,1141.589233 54.764576,1141.718140 54.868507,1142.275024 
	C54.379749,1142.939941 53.896561,1143.177002 53.288239,1143.289917 
	C53.163105,1143.165894 52.911556,1142.919189 52.911560,1142.919189 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M283.003571,1242.203491 
	C283.056793,1242.809448 282.704834,1243.357544 282.352905,1243.905640 
	C282.159912,1243.549805 281.966949,1243.193848 281.776978,1242.483887 
	C282.052765,1242.135010 282.325592,1242.140381 283.003571,1242.203491 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M51.849747,1144.873657 
	C51.979782,1144.274780 52.185993,1143.738281 52.651878,1143.060547 
	C52.911556,1142.919189 53.163105,1143.165894 53.210438,1143.550171 
	C52.864220,1144.310547 52.470669,1144.686523 52.077126,1145.062622 
	C52.077126,1145.062622 51.925926,1144.936157 51.849747,1144.873657 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M454.623352,1160.026123 
	C454.933105,1160.061890 454.934204,1160.559937 455.007996,1160.797852 
	C454.596222,1161.203735 454.110657,1161.371460 453.320862,1161.299072 
	C453.448944,1160.702637 453.881287,1160.346436 454.623352,1160.026123 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M147.494934,1121.089111 
	C147.192490,1120.512451 147.354996,1119.917847 147.783966,1119.150146 
	C148.020233,1119.675049 147.990051,1120.373169 147.494934,1121.089111 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M51.878769,1255.296631 
	C52.391014,1255.446533 52.813324,1255.916382 53.120983,1256.713867 
	C52.600498,1256.566528 52.194668,1256.091553 51.878769,1255.296631 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M164.023102,1120.269043 
	C164.136536,1120.031372 164.308777,1120.020142 164.739838,1120.004150 
	C164.693069,1120.164795 164.387466,1120.330200 164.023102,1120.269043 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M48.709007,1224.298828 
	C48.256702,1224.311768 48.006058,1224.110229 47.661438,1223.658447 
	C47.526882,1223.458618 47.856361,1223.136475 48.242596,1223.083984 
	C48.898651,1223.225342 49.083282,1223.480713 49.182724,1223.797729 
	C49.182724,1223.797729 48.870087,1224.134888 48.709007,1224.298828 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M51.961700,1227.042236 
	C51.618763,1226.962646 51.288040,1226.870361 50.808342,1226.547852 
	C50.659367,1226.317627 50.882748,1226.112183 51.227448,1226.110840 
	C51.711475,1226.409058 51.850803,1226.708618 51.990128,1227.008301 
	C51.990120,1227.008301 51.973919,1227.029419 51.961700,1227.042236 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M59.397469,1211.671143 
	C59.892624,1211.611938 60.113052,1211.790405 60.395538,1212.210693 
	C60.386509,1212.542480 60.266048,1212.693604 59.735786,1212.938477 
	C59.059631,1212.830688 58.873901,1212.571411 58.768787,1212.254517 
	C58.903233,1212.119019 59.037678,1211.983521 59.397469,1211.671143 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M64.961639,1244.700317 
	C64.620049,1244.758667 64.202789,1244.502197 63.888527,1243.849854 
	C64.289665,1243.764526 64.587814,1244.074951 64.961639,1244.700317 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M161.725739,1196.603271 
	C162.058670,1196.725342 162.135818,1197.007080 162.309433,1197.716797 
	C162.405884,1198.144653 161.973618,1198.395630 161.741928,1198.485718 
	C161.359192,1198.412231 161.208160,1198.248657 160.831940,1197.836914 
	C160.894485,1197.313477 161.182236,1197.038208 161.725739,1196.603271 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M65.648048,1154.499878 
	C65.544533,1154.823608 65.350533,1155.078369 64.863518,1155.385132 
	C64.509758,1155.399048 64.358528,1155.291626 64.118317,1154.780884 
	C64.223274,1154.104614 64.476196,1153.907349 64.991226,1153.978516 
	C65.345566,1154.281006 65.496811,1154.390503 65.648048,1154.499878 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M66.348526,1153.412598 
	C66.396202,1153.885498 66.227531,1154.106812 65.824249,1154.391724 
	C65.496811,1154.390503 65.345566,1154.281006 65.110748,1153.769775 
	C65.236755,1153.110718 65.500717,1152.934937 65.819069,1152.840698 
	C65.942917,1152.962402 66.066757,1153.083984 66.348526,1153.412598 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M181.993896,1221.000488 
	C181.993118,1221.003052 182.113556,1220.960205 182.056519,1220.977295 
	C181.999481,1220.994385 181.994690,1220.997925 181.993896,1221.000488 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M61.335434,1257.083496 
	C61.152756,1256.699097 61.301579,1256.366943 61.741642,1256.016357 
	C61.910896,1256.377075 61.788914,1256.756470 61.335434,1257.083496 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M138.545212,1215.773926 
	C138.629715,1216.105835 138.546143,1216.478394 138.203720,1217.003418 
	C137.954300,1216.952759 137.963699,1216.749390 138.014191,1216.243652 
	C138.055283,1215.941040 138.377121,1215.814331 138.545212,1215.773926 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M48.688122,1246.930176 
	C48.852608,1247.293579 48.696098,1247.621094 48.252510,1247.980225 
	C48.099331,1247.639404 48.233231,1247.266846 48.688122,1246.930176 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M49.681133,1245.930176 
	C49.855122,1246.296631 49.701004,1246.627563 49.256424,1246.983521 
	C49.094982,1246.637207 49.224007,1246.265991 49.681133,1245.930176 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M425.988831,1195.288574 
	C425.966858,1195.016846 426.425354,1194.967407 426.655487,1194.954590 
	C426.593994,1195.147827 426.302399,1195.354004 425.988831,1195.288574 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1642.637207,1029.730835 
	C1644.490967,1028.587280 1646.344727,1027.443726 1648.198486,1026.300171 
	C1648.438354,1026.687134 1648.678101,1027.074097 1648.917969,1027.460938 
	C1647.584229,1028.620728 1646.250488,1029.780640 1644.916748,1030.940430 
	C1645.315063,1031.439331 1645.713257,1031.938110 1646.111450,1032.437012 
	C1647.574097,1031.181641 1648.967773,1029.831421 1650.520386,1028.699341 
	C1651.877075,1027.710083 1653.375244,1026.346069 1654.877563,1026.256104 
	C1657.253662,1026.113525 1659.685425,1026.897583 1661.989746,1028.427734 
	C1663.557617,1027.505249 1665.125488,1026.582886 1666.816284,1025.588013 
	C1667.713135,1027.230835 1668.435791,1028.294067 1668.901611,1029.459717 
	C1669.979736,1032.158569 1668.267578,1034.343628 1665.334839,1034.334351 
	C1659.182495,1034.315063 1653.016968,1034.656006 1646.881592,1034.350952 
	C1642.496704,1034.132935 1638.166382,1032.959106 1633.780884,1032.520874 
	C1632.347412,1032.377563 1630.816772,1033.206909 1628.551636,1033.792969 
	C1629.757812,1031.157349 1630.789062,1028.904053 1632.336670,1025.522461 
	C1633.529175,1027.547363 1634.263428,1028.793945 1634.412964,1029.047974 
	C1637.457642,1028.535400 1639.518066,1028.188477 1641.247925,1028.291748 
	C1639.893066,1029.492188 1638.869019,1030.242310 1637.844971,1030.992432 
	C1638.194336,1031.473511 1638.543823,1031.954712 1638.893311,1032.435791 
	C1640.141235,1031.534180 1641.389160,1030.632446 1642.637207,1029.730835 
M1657.351440,1032.145142 
	C1658.390625,1031.961914 1659.429810,1031.778564 1660.469116,1031.595215 
	C1660.452759,1031.365601 1660.436523,1031.135986 1660.420166,1030.906372 
	C1658.085571,1030.906372 1655.750977,1030.906372 1653.416382,1030.906372 
	C1653.395874,1031.115112 1653.375366,1031.323730 1653.354858,1031.532471 
	C1654.412476,1031.814087 1655.470093,1032.095825 1657.351440,1032.145142 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1595.336792,972.096924 
	C1594.604736,972.070374 1593.438354,971.466248 1593.418457,971.502197 
	C1590.331177,977.031372 1585.320557,972.639038 1581.294800,974.711304 
	C1581.025879,972.289795 1580.848755,970.695923 1580.671753,969.102051 
	C1580.373413,969.024719 1580.074951,968.947388 1579.776611,968.870117 
	C1579.333496,970.325134 1578.890381,971.780090 1578.181152,974.109253 
	C1577.577637,972.003601 1577.179565,970.614563 1576.901123,969.642761 
	C1574.554321,971.298279 1572.516724,973.672424 1570.091309,974.174744 
	C1567.813965,974.646362 1565.126709,973.140259 1562.156250,972.386719 
	C1562.075562,971.201965 1561.943359,969.261292 1561.898315,968.599548 
	C1564.980591,967.997009 1567.613647,967.252380 1570.292236,967.008301 
	C1575.327759,966.549377 1580.387451,966.329407 1585.441528,966.116577 
	C1587.191895,966.042908 1588.955688,966.285217 1590.792358,966.387634 
	C1592.266602,968.111023 1593.852173,969.964600 1595.336792,972.096924 
M1583.926270,971.378174 
	C1583.726196,971.360901 1583.526001,971.343689 1583.325928,971.326416 
	C1583.417603,971.429199 1583.509277,971.531921 1583.926270,971.378174 
M1586.035767,971.431885 
	C1586.270752,971.505432 1586.505615,971.578918 1586.740601,971.652405 
	C1586.661011,971.427063 1586.581543,971.201782 1586.035767,971.431885 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1854.276733,995.833313 
	C1855.495850,996.095032 1856.714844,996.356750 1858.292603,996.695496 
	C1854.151489,999.504150 1853.340820,999.186890 1851.677124,994.192322 
	C1849.688232,995.325439 1847.777832,996.413818 1846.564453,997.105042 
	C1838.750000,995.510681 1831.614258,996.141602 1824.204346,998.955627 
	C1824.204346,996.056946 1824.204346,993.847900 1824.204346,991.345459 
	C1827.512207,990.593201 1830.642822,989.709290 1831.174438,995.510498 
	C1832.351685,993.811829 1833.111084,992.716003 1834.076904,991.322510 
	C1835.142944,992.163147 1836.230957,993.021057 1837.539795,994.053162 
	C1837.929810,993.023315 1838.250000,992.177551 1838.444824,991.662964 
	C1844.738525,991.203857 1850.879639,990.755920 1857.325195,990.804199 
	C1855.765625,992.259399 1852.923584,992.494751 1854.276733,995.833313 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1798.968628,1030.030762 
	C1799.980591,1030.270020 1800.992554,1030.509155 1802.004517,1030.748413 
	C1801.966187,1031.281494 1801.927979,1031.814575 1801.889648,1032.347656 
	C1799.349487,1032.188965 1796.743896,1032.329712 1794.285889,1031.793457 
	C1791.385132,1031.160889 1788.691895,1029.840454 1785.890625,1032.227173 
	C1785.218140,1032.800049 1783.322998,1032.899414 1782.670898,1032.373169 
	C1779.299927,1029.651978 1776.188232,1030.523682 1772.770508,1032.240845 
	C1771.685547,1032.785889 1769.002686,1032.561279 1768.820801,1032.054321 
	C1768.206055,1030.339233 1767.889038,1028.167236 1768.462280,1026.519043 
	C1768.763428,1025.653442 1771.496948,1024.722534 1772.544922,1025.176636 
	C1779.142578,1028.034912 1785.800171,1023.816956 1792.346191,1025.610107 
	C1792.866821,1025.752808 1793.573242,1025.217651 1794.403076,1025.338623 
	C1793.815430,1026.388916 1793.018066,1027.098267 1791.184570,1028.729248 
	C1794.493896,1029.282593 1796.731201,1029.656616 1798.968628,1030.030762 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1606.063599,1000.052734 
	C1604.080200,998.331787 1602.175659,997.342957 1600.983276,1001.414124 
	C1599.563599,999.755981 1598.638306,998.675354 1597.687622,997.565063 
	C1597.443726,997.662964 1597.051392,997.693298 1596.955933,997.877625 
	C1594.983276,1001.684753 1591.581543,1001.378967 1588.296875,1000.619812 
	C1585.959351,1000.079590 1583.831177,998.458252 1581.497925,998.141418 
	C1580.070923,997.947632 1578.432739,999.309082 1576.893921,999.970093 
	C1575.188721,993.907715 1575.603882,993.995667 1581.532837,993.361389 
	C1585.923218,992.891724 1589.984985,996.049194 1594.552124,993.705627 
	C1595.740723,993.095703 1598.380981,995.314758 1600.638428,996.366760 
	C1601.786743,995.744629 1603.481812,994.826355 1605.542114,994.410645 
	C1605.959473,996.626404 1606.011597,998.339539 1606.063599,1000.052734 
M1604.829468,996.424316 
	C1604.630249,996.166077 1604.431030,995.907898 1604.231812,995.649658 
	C1604.284912,996.034058 1604.338013,996.418396 1604.829468,996.424316 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1813.027954,998.213623 
	C1803.966553,998.153503 1794.905273,998.093445 1784.655640,998.025513 
	C1785.100586,996.146851 1785.235596,992.131836 1786.272095,991.883728 
	C1788.790771,991.280945 1791.728149,992.428589 1795.850342,993.087341 
	C1797.962158,992.721313 1801.841309,991.359009 1805.591675,991.651001 
	C1807.937744,991.833618 1810.089966,994.507629 1812.660889,996.554932 
	C1813.004395,997.435303 1813.016113,997.824463 1813.027954,998.213623 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1723.377441,997.321411 
	C1720.941284,998.175842 1718.494141,999.767273 1716.071045,999.731384 
	C1711.020142,999.656494 1705.985352,998.407227 1700.938477,999.931885 
	C1700.552612,1000.048462 1700.028076,999.705933 1699.269775,999.493347 
	C1699.176147,997.580322 1699.081543,995.649353 1698.935669,992.670593 
	C1706.458862,992.670593 1713.817627,992.670593 1721.592041,993.032227 
	C1722.464233,994.703003 1722.920898,996.012268 1723.377441,997.321411 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1844.225342,1029.310669 
	C1841.676636,1030.476074 1839.127808,1031.641357 1835.911377,1033.112061 
	C1835.703979,1030.718750 1835.599609,1029.515381 1835.427002,1027.524292 
	C1833.200439,1028.879028 1831.407715,1029.969971 1830.360718,1030.606934 
	C1826.514282,1030.606934 1823.301758,1030.606934 1819.820801,1030.606934 
	C1820.868286,1028.504761 1821.690063,1026.855225 1822.511841,1025.205811 
	C1829.578979,1023.515869 1836.894897,1024.001587 1844.865479,1024.370850 
	C1844.809570,1025.503296 1844.075073,1026.271729 1842.736328,1027.672241 
	C1842.566040,1027.484863 1843.395630,1028.397705 1844.225342,1029.310669 
M1839.144409,1028.197388 
	C1838.600952,1028.574585 1838.057495,1028.951782 1837.514038,1029.328979 
	C1837.622437,1029.579468 1837.730835,1029.829956 1837.839355,1030.080444 
	C1838.917480,1029.762451 1839.995605,1029.444458 1841.073853,1029.126587 
	C1840.673584,1028.764404 1840.273193,1028.402222 1839.144409,1028.197388 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1680.937744,999.852051 
	C1678.187378,1000.020813 1675.435913,1000.343140 1672.687134,1000.320618 
	C1669.037598,1000.290771 1665.386108,1000.050354 1661.743652,999.787659 
	C1659.316772,999.612671 1656.904175,999.241272 1654.041748,998.905884 
	C1654.959717,997.036072 1655.994995,994.927551 1657.227661,992.416687 
	C1658.564697,993.872498 1659.538574,994.932983 1660.525879,996.007996 
	C1662.483887,994.900391 1664.454224,993.785889 1666.424561,992.671326 
	C1666.682617,993.033386 1666.940552,993.395447 1667.198608,993.757507 
	C1665.881348,994.944519 1664.563965,996.131592 1663.246582,997.318604 
	C1663.479858,997.728516 1663.713135,998.138428 1663.946411,998.548401 
	C1665.627441,997.663086 1667.370361,996.875977 1668.971924,995.864868 
	C1670.597534,994.838501 1672.083862,993.591003 1673.631104,992.440247 
	C1674.152588,993.057129 1674.674072,993.674011 1675.195557,994.290894 
	C1673.531982,994.940552 1671.868408,995.590149 1670.204834,996.239807 
	C1670.773804,996.941345 1671.342773,997.642883 1671.911865,998.344482 
	C1673.260498,997.330139 1675.629639,996.435425 1675.765259,995.278870 
	C1676.163696,991.880798 1678.381836,993.054871 1680.364502,993.240723 
	C1674.477783,996.035461 1680.488892,997.787415 1680.937744,999.852051 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1639.748291,997.873230 
	C1641.226685,997.894592 1642.440186,997.681824 1643.653809,997.469055 
	C1643.833862,997.783325 1644.014038,998.097534 1644.194214,998.411743 
	C1643.029907,999.214355 1641.872070,1000.690979 1640.700195,1000.702209 
	C1635.746582,1000.749451 1630.788940,1000.360474 1624.416382,1000.065430 
	C1625.413818,997.700806 1625.850708,994.028687 1627.476685,993.391663 
	C1630.333740,992.272461 1634.400757,992.253906 1634.831909,997.725098 
	C1635.353149,996.355957 1635.874268,994.986816 1636.264282,993.962341 
	C1638.513794,993.698669 1640.516479,993.463989 1643.567505,993.106384 
	C1641.722046,995.154419 1640.602661,996.396790 1639.748291,997.873230 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1751.226196,993.929077 
	C1750.181396,995.608826 1749.551636,997.946716 1748.451294,998.194458 
	C1745.622559,998.831238 1742.524536,998.924988 1739.654297,998.473022 
	C1738.019409,998.215637 1736.619995,996.462280 1734.401489,994.871033 
	C1734.539429,994.499878 1735.026245,993.190552 1735.177979,992.782654 
	C1741.303589,992.437134 1746.688354,992.133362 1752.356445,991.813660 
	C1751.996948,992.517883 1751.711914,993.076477 1751.226196,993.929077 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1681.320801,999.838013 
	C1680.488892,997.787415 1674.477783,996.035461 1680.787354,993.311890 
	C1683.126709,993.021606 1685.265137,993.021606 1687.403687,993.021606 
	C1687.426270,993.516296 1687.448853,994.010986 1687.471313,994.505676 
	C1688.817017,993.645630 1690.162598,992.785645 1692.394165,991.359436 
	C1693.267334,993.849365 1694.008057,995.961792 1694.748901,998.074158 
	C1692.618408,998.588135 1690.511597,999.278198 1688.350952,999.566895 
	C1686.162842,999.859253 1683.921753,999.754700 1681.320801,999.838013 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1854.628662,995.569580 
	C1852.923584,992.494751 1855.765625,992.259399 1857.726318,991.019836 
	C1861.787476,990.739136 1865.752075,990.739136 1870.171997,990.739136 
	C1871.105957,992.582031 1872.323608,994.984802 1873.464111,997.235229 
	C1868.540894,1000.174927 1864.131836,997.239136 1859.508667,995.737732 
	C1858.101074,995.280579 1856.495605,995.432800 1854.628662,995.569580 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1731.895996,1030.546021 
	C1728.952271,1033.896240 1724.457642,1033.955200 1721.385132,1031.234375 
	C1720.490723,1030.442261 1718.301147,1031.112671 1716.213867,1031.112671 
	C1716.213867,1030.404907 1716.213867,1028.244995 1716.213867,1025.253174 
	C1718.461060,1025.761108 1720.526123,1026.227905 1722.743042,1026.729004 
	C1724.659668,1026.573364 1726.960571,1026.386597 1729.697632,1026.537720 
	C1730.721191,1028.099121 1731.308594,1029.322632 1731.895996,1030.546021 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1705.000488,1032.975342 
	C1700.968994,1033.231201 1696.906250,1033.864624 1692.923096,1033.535522 
	C1691.552612,1033.422119 1690.380737,1030.906738 1689.118042,1029.488525 
	C1689.832764,1028.998779 1690.547607,1028.508911 1691.678223,1028.135742 
	C1692.161499,1028.312256 1692.229126,1028.372070 1692.296631,1028.431885 
	C1692.192261,1028.290894 1692.088013,1028.149902 1691.983765,1028.008911 
	C1694.410156,1024.994995 1699.712646,1025.326782 1701.204224,1028.401855 
	C1701.841675,1029.716064 1703.285522,1030.639160 1704.677490,1032.118530 
	C1704.992065,1032.497192 1705.000488,1032.975342 1705.000488,1032.975342 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1608.035522,1029.037354 
	C1606.999634,1030.863281 1605.963745,1032.689087 1604.644043,1035.015259 
	C1603.398438,1033.486450 1602.473022,1032.350464 1601.547607,1031.214478 
	C1601.589478,1031.720215 1601.631348,1032.225830 1601.673340,1032.731567 
	C1599.001343,1033.269165 1596.329468,1033.806641 1593.188232,1033.801025 
	C1592.545044,1031.412354 1592.370972,1029.566895 1592.197021,1027.721436 
	C1592.842529,1028.125488 1593.920044,1029.002075 1594.070312,1028.864624 
	C1597.920166,1025.343750 1601.156494,1026.958008 1604.296753,1030.016235 
	C1605.318481,1028.935547 1606.308228,1027.888672 1607.679688,1027.196655 
	C1608.052856,1028.046753 1608.044189,1028.541992 1608.035522,1029.037354 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1607.202637,994.225342 
	C1611.326172,993.838440 1615.449585,993.451538 1619.739502,993.049011 
	C1619.739502,995.673157 1619.739502,997.903076 1619.739502,1001.250305 
	C1617.589355,1000.586853 1615.868042,1000.055725 1613.669312,999.377258 
	C1612.182373,999.671936 1609.797852,1000.144409 1607.256836,999.916870 
	C1607.134521,997.552979 1607.168579,995.889221 1607.202637,994.225342 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1778.209717,998.957642 
	C1775.138672,998.387634 1772.377686,997.966614 1769.782837,997.066711 
	C1768.699707,996.691162 1767.969604,995.297363 1767.078979,994.366455 
	C1768.334106,993.276184 1769.461548,991.506897 1770.871948,991.241455 
	C1773.548096,990.737854 1776.386597,991.095764 1780.752563,991.095764 
	C1779.174072,993.573730 1778.169434,995.150635 1777.480835,996.231567 
	C1777.803345,997.013489 1778.195190,997.963440 1778.209717,998.957642 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1756.778198,1026.253662 
	C1756.930786,1027.849365 1756.930786,1029.067017 1756.930786,1030.284546 
	C1757.160156,1030.296509 1757.389648,1030.308472 1757.619019,1030.320312 
	C1757.806763,1028.750122 1757.994629,1027.179810 1758.223389,1025.266357 
	C1759.707153,1025.137817 1761.099121,1025.017212 1762.697998,1024.878662 
	C1762.697998,1027.573364 1762.697998,1029.915405 1762.697998,1033.328735 
	C1759.723022,1032.650513 1757.042969,1032.291504 1754.555542,1031.396851 
	C1752.374878,1030.612427 1750.410278,1029.227173 1748.350342,1028.107300 
	C1748.524414,1027.363403 1748.698608,1026.619385 1748.872681,1025.875488 
	C1751.457031,1025.875488 1754.041260,1025.875488 1756.778198,1026.253662 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1620.785889,1043.640869 
	C1618.802124,1049.871338 1613.831055,1044.350342 1610.494751,1047.658813 
	C1610.494751,1044.309814 1610.494751,1041.971313 1610.494751,1038.848999 
	C1613.716553,1038.848999 1616.784668,1038.848999 1620.468628,1038.848999 
	C1620.547119,1039.780029 1620.693481,1041.518799 1620.785889,1043.640869 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1579.451050,1044.804199 
	C1579.305664,1042.716309 1579.305664,1040.947998 1579.305664,1039.917969 
	C1581.642212,1039.636841 1583.495361,1039.238647 1585.344727,1039.254761 
	C1586.520508,1039.265015 1587.674072,1040.099243 1588.867065,1040.194458 
	C1590.943237,1040.360229 1593.042236,1040.240601 1595.446045,1040.240601 
	C1595.446045,1041.068237 1595.446045,1043.126709 1595.446045,1044.964233 
	C1590.153809,1042.326782 1584.870972,1044.755371 1579.451050,1044.804199 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1591.893555,1027.410522 
	C1592.370972,1029.566895 1592.545044,1031.412354 1592.859131,1033.636353 
	C1590.956665,1033.799072 1588.914185,1033.583252 1586.556152,1033.334106 
	C1586.447876,1032.973511 1586.178467,1032.076782 1585.720215,1030.550781 
	C1583.789795,1031.794678 1582.158936,1032.845703 1580.595337,1033.853271 
	C1579.196899,1027.190796 1579.196899,1027.200684 1586.221313,1027.216797 
	C1588.010864,1027.220825 1589.800537,1027.140869 1591.893555,1027.410522 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1605.806274,1011.687378 
	C1604.836182,1011.766052 1603.853882,1011.979248 1602.898804,1011.892639 
	C1601.333374,1011.750732 1598.633057,1011.740112 1598.451294,1011.097107 
	C1597.947510,1009.315552 1597.863892,1006.822205 1598.802734,1005.388428 
	C1599.784912,1003.888306 1602.238647,1002.882019 1603.060547,1006.232788 
	C1603.802734,1005.720947 1604.544922,1005.209045 1606.194458,1004.071350 
	C1606.194458,1006.533936 1606.194458,1008.372314 1606.058716,1010.742676 
	C1605.922974,1011.274597 1605.806274,1011.687378 1605.806274,1011.687378 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1600.416016,1045.015015 
	C1600.289307,1043.837036 1600.062866,1042.944946 1600.311279,1042.214111 
	C1600.781738,1040.829590 1601.245117,1039.183594 1602.280151,1038.367065 
	C1602.749146,1037.997070 1604.577393,1039.349976 1606.176392,1040.103882 
	C1606.275024,1039.979980 1606.876343,1039.224609 1608.142090,1037.634399 
	C1608.142090,1040.759888 1608.142090,1042.829590 1608.142090,1045.373657 
	C1605.557617,1045.373657 1603.044067,1045.373657 1600.416016,1045.015015 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1609.326904,1006.439697 
	C1612.122070,1005.434937 1614.917236,1004.430237 1617.712402,1003.425537 
	C1618.101318,1003.908997 1618.490356,1004.392456 1618.879272,1004.875916 
	C1618.003906,1006.436279 1617.128540,1007.996643 1616.135864,1009.765930 
	C1616.386597,1009.856934 1617.312500,1010.193115 1619.597534,1011.022827 
	C1617.143188,1011.930908 1615.091187,1013.337402 1614.365234,1012.833557 
	C1612.431519,1011.491638 1611.068726,1009.327087 1609.400391,1007.055664 
	C1609.321655,1006.621704 1609.326904,1006.439697 1609.326904,1006.439697 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1633.229492,1038.545898 
	C1633.550171,1040.710327 1634.425903,1043.429688 1633.575684,1044.171631 
	C1632.354004,1045.237671 1629.270630,1045.718140 1628.089966,1044.882690 
	C1626.681152,1043.885864 1626.442993,1041.234985 1625.697632,1039.300659 
	C1626.121704,1039.155884 1626.545776,1039.011108 1626.969849,1038.866455 
	C1627.333862,1039.811768 1627.697876,1040.757080 1627.775024,1040.957275 
	C1629.571045,1040.017944 1631.292236,1039.117676 1633.229492,1038.545898 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1629.129395,1004.166077 
	C1630.286865,1006.232544 1631.097534,1008.281860 1631.908081,1010.331299 
	C1630.300049,1010.840515 1628.715942,1011.465698 1627.072998,1011.806396 
	C1626.333008,1011.959778 1625.475342,1011.545715 1624.111694,1011.280212 
	C1623.996216,1009.204407 1623.875366,1007.034180 1623.786133,1005.431152 
	C1625.904175,1004.887634 1627.343384,1004.518311 1629.129395,1004.166077 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1649.095581,1042.273682 
	C1649.853760,1040.751587 1650.339111,1038.924927 1651.516235,1037.870605 
	C1651.934570,1037.495972 1654.033813,1038.998169 1655.251709,1039.588379 
	C1656.183350,1039.074951 1657.340332,1038.437378 1658.497437,1037.799805 
	C1658.837769,1038.434326 1659.178101,1039.068848 1659.518555,1039.703369 
	C1656.190552,1041.575928 1652.862427,1043.448608 1649.287354,1044.764893 
	C1649.058716,1043.563721 1649.077148,1042.918701 1649.095581,1042.273682 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1612.124512,1027.113159 
	C1615.869019,1026.941528 1619.613525,1026.769775 1624.714722,1026.535889 
	C1623.412476,1028.745850 1622.488403,1030.314331 1621.588013,1031.842407 
	C1622.137573,1032.149048 1623.131714,1032.703735 1624.996460,1033.744141 
	C1621.683472,1034.411011 1619.222778,1035.847046 1618.597534,1031.772095 
	C1618.586914,1031.701904 1617.489990,1031.798218 1616.781250,1031.311035 
	C1615.791626,1030.143921 1614.905029,1029.502075 1614.054810,1028.815186 
	C1613.388184,1028.276611 1612.766235,1027.682617 1612.124512,1027.113159 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1806.804688,1027.023193 
	C1808.575684,1026.029663 1810.365601,1025.143066 1812.234131,1024.922241 
	C1812.764038,1024.859619 1814.267212,1027.387451 1814.060547,1027.624268 
	C1812.328247,1029.608643 1810.301392,1031.335815 1808.363281,1033.140503 
	C1807.859985,1032.796753 1807.356812,1032.453125 1806.853516,1032.109375 
	C1806.853516,1030.533813 1806.853516,1028.958252 1806.804688,1027.023193 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1847.150879,1024.945679 
	C1849.271606,1024.945679 1851.392334,1024.945679 1853.525757,1024.945679 
	C1854.633057,1032.661011 1853.848267,1033.694946 1848.072021,1031.038086 
	C1847.490356,1030.108643 1847.228638,1029.770874 1846.966919,1029.432983 
	C1846.966919,1029.432983 1846.705566,1029.515259 1846.705566,1029.515259 
	C1846.705566,1029.515259 1846.496216,1029.337891 1846.401123,1028.865479 
	C1846.587524,1027.243896 1846.869263,1026.094849 1847.150879,1024.945679 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1581.949463,1005.507629 
	C1584.893188,1005.507629 1587.836792,1005.507629 1590.561646,1005.507629 
	C1591.094482,1007.699890 1591.563599,1009.629456 1592.063477,1011.686035 
	C1588.708618,1011.285278 1584.829834,1011.034424 1582.363525,1006.702759 
	C1581.960205,1006.202759 1581.954834,1005.855225 1581.949463,1005.507629 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1705.333008,1033.110352 
	C1705.000488,1032.975342 1704.992065,1032.497192 1705.006836,1032.258423 
	C1705.947754,1029.675537 1703.602051,1025.325928 1709.942505,1025.808716 
	C1710.239624,1027.742798 1710.905151,1030.053101 1710.690308,1032.278442 
	C1710.644165,1032.757935 1707.423828,1032.931152 1705.333008,1033.110352 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1648.796875,1042.325562 
	C1649.077148,1042.918701 1649.058716,1043.563721 1649.047363,1044.531738 
	C1645.972290,1044.667603 1642.890137,1044.480469 1639.826660,1044.294556 
	C1639.987061,1042.888550 1640.194824,1041.067505 1640.464233,1038.704956 
	C1642.653564,1041.309814 1646.088867,1035.007935 1647.713867,1040.627197 
	C1647.429565,1041.014038 1646.989746,1041.001465 1646.717041,1040.730225 
	C1645.813232,1040.786011 1645.182251,1041.113037 1644.551147,1041.440186 
	C1645.348511,1041.924194 1646.145996,1042.408325 1647.077515,1043.055664 
	C1647.460327,1042.846558 1647.709106,1042.474487 1647.957886,1042.102417 
	C1648.138062,1042.194214 1648.318237,1042.285889 1648.796875,1042.325562 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1813.281128,998.379944 
	C1813.016113,997.824463 1813.004395,997.435303 1812.979004,996.757263 
	C1812.651611,995.183105 1812.338013,993.897766 1811.800293,991.693787 
	C1814.185791,991.928223 1816.879150,992.192871 1819.896851,992.489380 
	C1821.233032,997.404968 1816.053589,996.742004 1813.281128,998.379944 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1732.183594,1030.736084 
	C1731.308594,1029.322632 1730.721191,1028.099121 1730.125000,1026.541504 
	C1737.743286,1022.573669 1733.669189,1030.453857 1735.709106,1032.548340 
	C1734.629761,1032.007690 1733.550537,1031.466919 1732.183594,1030.736084 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1611.803223,1027.071411 
	C1612.766235,1027.682617 1613.388184,1028.276611 1614.054810,1028.815186 
	C1614.905029,1029.502075 1615.791626,1030.143921 1616.380127,1031.156372 
	C1616.097900,1031.507568 1616.092896,1031.490234 1615.776855,1031.729980 
	C1614.214966,1032.686401 1612.969116,1033.403076 1611.723267,1034.119629 
	C1611.109009,1034.305908 1610.494629,1034.492310 1610.014038,1034.638184 
	C1609.569702,1032.703857 1609.155884,1030.902588 1608.388916,1029.069336 
	C1608.044189,1028.541992 1608.052856,1028.046753 1608.042236,1027.179810 
	C1609.175903,1026.882080 1610.328857,1026.955933 1611.803223,1027.071411 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1581.630981,1005.362000 
	C1581.954834,1005.855225 1581.960205,1006.202759 1581.999512,1006.809692 
	C1582.033325,1008.374451 1582.033325,1009.679932 1582.033325,1010.276489 
	C1579.637207,1010.445312 1577.719727,1010.580444 1575.650024,1010.726257 
	C1575.521118,1009.354797 1575.354614,1007.582764 1575.265625,1006.635742 
	C1577.512207,1006.108459 1579.412231,1005.662415 1581.630981,1005.362000 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1673.689453,1030.447266 
	C1673.480713,1027.963501 1673.443237,1025.827026 1676.447021,1026.076172 
	C1677.610596,1026.172852 1678.722290,1026.895874 1679.857544,1027.333618 
	C1679.257080,1028.369629 1678.656616,1029.405762 1678.056152,1030.441772 
	C1676.456909,1028.777100 1675.583862,1035.542603 1673.689453,1030.447266 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1723.668945,997.476318 
	C1722.920898,996.012268 1722.464233,994.703003 1722.028076,993.026733 
	C1729.250366,989.644836 1727.176392,995.348206 1727.866699,999.096497 
	C1726.352051,998.528320 1725.156128,998.079773 1723.668945,997.476318 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1798.940186,1029.675293 
	C1796.731201,1029.656616 1794.493896,1029.282593 1791.184570,1028.729248 
	C1793.018066,1027.098267 1793.815430,1026.388916 1794.780640,1025.408203 
	C1796.651733,1025.101196 1798.354980,1025.065552 1800.058228,1025.029907 
	C1799.676147,1026.459839 1799.294067,1027.889771 1798.940186,1029.675293 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1565.688110,1031.832031 
	C1565.367065,1030.596069 1564.732056,1029.579224 1563.791138,1028.072876 
	C1564.602661,1027.909912 1565.968872,1027.635620 1567.335205,1027.361450 
	C1567.672485,1029.723999 1568.009888,1032.086670 1568.390503,1034.752197 
	C1566.152710,1034.902710 1564.734009,1034.998047 1563.315186,1035.093506 
	C1563.145630,1034.791138 1562.975952,1034.488892 1562.806274,1034.186523 
	C1563.662231,1033.474731 1564.518188,1032.762939 1565.688110,1031.832031 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1743.818115,1029.878662 
	C1743.236206,1030.840454 1742.874756,1031.544067 1742.513306,1032.247681 
	C1742.207764,1032.265625 1741.902222,1032.283569 1741.596802,1032.301514 
	C1741.388916,1030.132568 1741.181152,1027.963501 1740.939331,1025.440186 
	C1743.066528,1025.221802 1744.652466,1025.058960 1746.238403,1024.896118 
	C1746.558350,1025.211670 1746.878296,1025.527344 1747.198242,1025.842896 
	C1746.145020,1027.102051 1745.091919,1028.361206 1743.818115,1029.878662 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1846.964111,1024.727905 
	C1846.869263,1026.094849 1846.587524,1027.243896 1846.417969,1028.729492 
	C1846.037964,1029.304199 1845.546143,1029.542236 1844.639893,1029.545410 
	C1843.395630,1028.397705 1842.566040,1027.484863 1842.736328,1027.672241 
	C1844.075073,1026.271729 1844.809570,1025.503296 1845.337891,1024.455933 
	C1845.680298,1024.287964 1846.228760,1024.399048 1846.964111,1024.727905 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1612.186035,1034.232910 
	C1612.969116,1033.403076 1614.214966,1032.686401 1615.758057,1031.711914 
	C1614.919922,1032.418091 1613.784302,1033.382080 1612.186035,1034.232910 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1606.889038,994.154907 
	C1607.168579,995.889221 1607.134521,997.552979 1607.022095,999.630737 
	C1606.943726,1000.044678 1606.503662,1000.062317 1606.283691,1000.057495 
	C1606.011597,998.339539 1605.959473,996.626404 1605.920166,994.486938 
	C1606.147217,994.068542 1606.361328,994.076477 1606.889038,994.154907 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1647.972656,1041.828613 
	C1647.709106,1042.474487 1647.460327,1042.846558 1647.020142,1042.741455 
	C1646.882324,1041.843262 1646.936035,1041.422363 1646.989746,1041.001465 
	C1646.989746,1041.001465 1647.429565,1041.014038 1647.649658,1041.016602 
	C1647.908813,1041.197754 1647.948120,1041.376221 1647.972656,1041.828613 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1606.052246,1011.754639 
	C1605.806274,1011.687378 1605.922974,1011.274597 1606.029297,1011.087402 
	C1606.497559,1011.145447 1606.859497,1011.390747 1607.221558,1011.636108 
	C1606.913818,1011.697998 1606.606079,1011.759949 1606.052246,1011.754639 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1846.772217,1029.816162 
	C1847.228638,1029.770874 1847.490356,1030.108643 1847.879639,1030.741577 
	C1847.530762,1030.757568 1847.054199,1030.478516 1846.772217,1029.816162 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1766.479248,991.510010 
	C1766.499268,991.509705 1766.459229,991.510376 1766.479248,991.510010 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1609.118408,1006.338867 
	C1609.326904,1006.439697 1609.321655,1006.621704 1609.288452,1006.703918 
	C1609.005981,1006.674377 1608.756714,1006.562561 1608.507446,1006.450745 
	C1608.641602,1006.379883 1608.775757,1006.308960 1609.118408,1006.338867 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M379.793945,335.122559 
	C379.838440,329.059174 379.882935,322.995789 380.188110,316.354492 
	C380.722290,315.572266 380.907501,315.306458 381.004425,314.979095 
	C392.091583,320.144379 403.193359,325.278839 414.243225,330.522675 
	C415.347656,331.046783 416.601288,332.072845 416.977875,333.148712 
	C417.826233,335.572296 418.918945,335.565643 421.392609,335.460968 
	C424.280823,335.338715 427.409210,336.815826 430.157806,338.161865 
	C443.696045,344.791748 457.114807,351.665100 470.630707,358.341248 
	C481.942749,363.928802 493.841614,368.554413 504.439423,375.253174 
	C508.827271,378.026733 513.463745,378.922729 517.805481,381.075958 
	C538.906494,391.540497 560.146729,401.724670 581.344604,411.993683 
	C585.078064,413.802277 589.531860,414.787628 592.464478,417.458282 
	C600.527161,424.800690 611.316223,425.955505 620.443237,430.832611 
	C633.221985,437.661041 646.459412,443.635834 659.542297,449.888519 
	C666.713074,453.315643 673.969482,456.563324 681.597046,460.430908 
	C681.981873,469.304749 681.957031,477.639587 681.629639,486.091309 
	C680.406128,486.780701 679.485046,487.353271 678.313843,488.140106 
	C675.380432,493.571472 672.697205,498.788544 669.767334,504.003357 
	C669.520691,504.001068 669.027466,503.998199 668.877808,503.727844 
	C667.917175,502.878296 667.106201,502.299072 666.156006,501.522797 
	C665.825317,501.054688 665.576965,500.853699 665.131104,500.530273 
	C664.779968,500.092926 664.531799,499.895538 664.146851,499.393005 
	C663.641418,497.396729 663.234924,495.752991 663.109253,493.911438 
	C662.926636,492.809052 662.463318,491.904510 661.784241,490.861084 
	C661.417358,490.422607 661.266235,490.123047 661.114990,489.497101 
	C660.584900,489.017792 660.054993,488.864838 659.525024,488.711914 
	C659.581116,489.302948 659.637207,489.893982 659.664429,490.816406 
	C659.082031,492.093018 658.528503,493.038269 657.560059,493.974854 
	C656.482300,493.948547 655.802795,494.020111 655.159302,493.899506 
	C649.002747,492.745483 648.991333,492.736084 648.962646,498.779419 
	C648.940918,503.358093 648.958313,507.936981 648.958313,512.639771 
	C640.279724,512.639771 632.382141,512.639771 625.341675,512.639771 
	C625.067749,508.687073 625.970642,504.344482 624.255493,501.699524 
	C622.425171,498.876953 618.121826,497.658081 614.907837,495.744995 
	C617.095459,493.362122 619.613403,490.619446 622.419373,487.562988 
	C618.902771,484.133514 615.616821,481.296234 612.785522,478.061035 
	C610.130859,475.027679 608.333557,475.866211 605.953796,478.351807 
	C602.622131,481.831573 600.202881,486.689850 594.288330,486.758240 
	C593.583069,486.766357 592.637085,487.921173 592.235535,488.753326 
	C589.077209,495.298767 586.024231,501.895081 582.534668,508.654053 
	C580.890808,510.300446 579.905579,514.441345 577.630798,510.620300 
	C576.033142,507.936646 575.390015,503.546906 576.434143,500.691956 
	C578.047119,496.281708 575.638123,493.827484 574.527527,490.634186 
	C574.009155,490.652344 573.490845,490.670502 572.972473,490.688629 
	C572.701294,495.780609 572.430054,500.872559 571.793152,505.976196 
	C570.439331,506.053101 569.451172,506.118317 568.197754,505.972565 
	C566.274475,504.191742 564.616516,502.621887 562.890320,500.746277 
	C562.198303,499.972290 561.574524,499.504120 560.873962,498.699280 
	C557.517639,494.548920 553.154846,493.922211 548.147278,494.124542 
	C547.571960,494.045197 547.340759,494.021210 547.057922,493.615845 
	C546.868042,492.743103 546.729858,492.251740 546.639648,491.506287 
	C546.655518,490.910370 546.623352,490.568573 546.720825,489.760101 
	C546.886780,486.138367 546.941223,482.983398 546.956055,479.828217 
	C547.007324,468.903473 547.002014,468.903442 558.171753,469.194244 
	C560.109741,469.167297 561.832520,468.876556 563.555237,468.585846 
	C563.492004,468.097992 563.428772,467.610138 563.365540,467.122284 
	C561.487671,467.122284 559.609863,467.122284 557.305542,467.142212 
	C553.789917,467.308228 550.700684,467.454315 547.311951,467.614563 
	C546.965576,462.981537 546.624756,458.422974 546.442749,453.507538 
	C546.042664,450.148193 545.483704,447.145721 544.957275,443.666809 
	C544.934509,437.078949 544.879089,430.967499 544.846924,424.867798 
	C544.870117,424.879517 544.887207,424.928253 545.229858,424.911255 
	C546.049133,424.007233 546.525757,423.120239 547.266541,422.253723 
	C547.870422,422.359863 548.210205,422.445618 548.680664,422.839172 
	C550.285217,424.923889 551.782959,426.681641 553.227478,428.482147 
	C555.679871,431.538971 558.041565,432.102020 560.856140,428.751312 
	C562.642700,426.624481 564.891113,424.885498 567.340576,422.983063 
	C571.598633,423.955292 575.448547,424.917084 579.298401,425.878845 
	C579.471436,425.239136 579.644470,424.599396 579.817505,423.959686 
	C576.385681,422.642365 572.953857,421.325043 569.450562,419.641296 
	C565.505005,415.084869 561.630859,410.894806 557.507935,406.435760 
	C554.788635,408.902893 552.491821,410.986755 549.809692,413.046753 
	C544.949646,411.681885 540.474854,410.340942 535.844055,408.700134 
	C526.401367,401.229218 516.171692,396.068756 504.629639,393.736359 
	C507.585754,395.649475 511.066528,396.500580 514.054443,398.212097 
	C519.855652,401.535248 525.380127,405.341766 531.015259,409.303955 
	C531.021362,410.134460 531.031433,410.608948 531.045410,411.462708 
	C531.045959,415.201447 531.042542,418.560913 531.026733,422.356720 
	C530.503540,425.285767 529.505066,427.804535 529.636414,430.262939 
	C529.740906,432.220154 531.225037,434.103668 532.032837,436.366272 
	C531.244446,438.658356 530.519836,440.604095 529.465027,442.778625 
	C527.751770,442.541077 526.368652,442.074707 524.906128,441.258972 
	C524.481628,440.597076 524.136658,440.284576 523.728882,439.600708 
	C522.968018,432.901276 524.638550,426.291107 521.298645,420.184021 
	C520.166504,423.970093 520.674561,427.964447 519.562500,431.440460 
	C518.211182,435.664093 522.610657,441.364197 516.223083,444.691895 
	C516.079956,445.480255 516.039673,445.798492 515.681030,446.247681 
	C514.455322,446.479370 513.547913,446.580139 512.191589,446.460297 
	C509.484589,446.108887 507.226593,445.978088 504.863647,445.663452 
	C504.574036,445.253510 504.342072,445.097809 504.027710,444.558472 
	C503.315308,438.492981 502.755737,432.862152 501.841766,427.289490 
	C501.694061,426.388916 499.970215,425.746857 498.667633,424.824677 
	C495.981171,422.573578 497.723358,421.744690 499.227386,420.136108 
	C500.342529,418.943390 500.357635,416.494141 500.305176,414.620239 
	C500.294006,414.220490 497.280640,413.153107 496.663666,413.650421 
	C493.796722,415.961304 488.947083,417.481628 491.886993,422.971680 
	C492.438110,424.000854 491.984039,425.568268 491.872314,426.999146 
	C491.750732,427.111023 491.438416,427.218719 491.018005,427.166046 
	C490.315430,427.100769 490.033264,427.088135 489.485992,426.937164 
	C487.588806,426.977020 485.940552,427.395142 484.327820,427.286346 
	C480.280548,427.013245 476.249298,426.501801 471.865723,426.077698 
	C470.985474,426.441132 470.451172,426.812775 469.681824,427.418213 
	C469.038696,428.694122 468.630615,429.736298 468.222565,430.778473 
	C467.553528,426.638306 465.092896,426.260437 462.182922,427.009033 
	C459.977905,427.576294 459.784180,429.093231 461.036133,431.151306 
	C461.011719,431.631714 461.006256,431.824097 460.795563,432.208191 
	C460.429871,432.927673 460.269379,433.455505 459.906403,434.046082 
	C459.447601,434.228149 459.221588,434.389801 458.947296,435.026917 
	C459.848419,439.334625 460.828125,443.209229 461.807831,447.083801 
	C462.198639,447.043274 462.589478,447.002747 462.980286,446.962250 
	C464.001373,443.259888 465.022491,439.557526 466.033447,435.714294 
	C466.023285,435.573395 466.017456,435.290985 466.360107,435.149170 
	C468.463898,434.339233 470.225067,433.671051 472.209320,432.893829 
	C472.710876,432.582520 472.900940,432.317017 473.278870,431.942322 
	C473.927643,431.884979 474.300140,431.873657 475.033478,431.906372 
	C475.874878,431.969604 476.355499,431.988770 476.858795,432.362183 
	C477.589325,433.485107 478.297119,434.253815 479.124664,435.246185 
	C479.524780,435.652863 479.805206,435.835876 480.112793,436.470367 
	C480.501251,440.590790 480.862488,444.259705 481.175110,448.337036 
	C482.595367,449.848663 483.988190,451.757751 485.547302,451.904877 
	C490.326904,452.355927 495.170319,452.130920 499.992188,452.599121 
	C499.995453,455.739746 499.995453,458.450043 499.995453,461.160370 
	C500.494568,461.167297 500.993683,461.174255 501.492767,461.181213 
	C502.001556,458.223419 502.510345,455.265625 503.470703,452.214996 
	C507.625763,452.154572 511.329193,452.186981 515.021851,452.419220 
	C515.011108,452.619049 515.007874,453.019287 515.098511,453.256042 
	C515.465881,453.669312 515.742676,453.845795 516.011108,454.009460 
	C516.002808,453.996613 515.973145,453.988770 516.161682,454.304047 
	C517.556213,454.752777 518.762207,454.886261 520.228699,455.161682 
	C520.646667,455.869049 520.804138,456.434479 520.810913,457.371338 
	C520.607971,462.185638 520.555725,466.628448 519.990051,471.046387 
	C513.874695,471.021545 508.272858,471.021545 502.670990,471.021545 
	C502.670929,471.653534 502.670837,472.285492 502.670776,472.917480 
	C508.623962,472.917480 514.577148,472.917480 520.616333,473.382294 
	C520.708496,481.777679 520.708252,481.775360 513.009949,482.600739 
	C512.688965,482.635132 512.380493,482.785797 511.695526,482.826324 
	C510.266785,483.514587 509.208679,484.258789 507.792297,485.036804 
	C506.909668,485.505798 506.385254,485.940948 505.488708,486.498901 
	C503.825867,486.601868 502.535217,486.582031 501.075653,486.170776 
	C500.077393,481.084869 499.248047,476.390381 498.418701,471.695892 
	C498.259918,471.622345 498.101166,471.548767 497.942383,471.475220 
	C497.291870,476.891357 496.641388,482.307495 495.539154,487.830505 
	C491.059204,487.916046 487.031006,487.894714 482.988220,487.454346 
	C482.908569,486.477478 482.843475,485.919678 482.607666,485.093140 
	C480.240692,483.581635 478.505707,483.379028 477.511719,486.388275 
	C477.260345,487.149323 475.900574,487.593842 474.994232,488.079468 
	C472.363495,489.488861 469.295624,490.397766 467.166168,492.342712 
	C464.826782,494.479431 462.903992,495.181213 460.244049,493.475616 
	C457.627197,491.797607 455.144409,489.845306 452.368622,488.494476 
	C447.204285,485.981354 441.826935,483.907806 436.640503,481.436615 
	C435.532928,480.908905 434.861572,479.465576 434.272217,478.065979 
	C434.880096,475.100189 435.983337,473.790863 438.953064,473.924652 
	C441.618134,474.044769 444.320465,473.336700 447.310364,473.156067 
	C449.793884,473.879974 451.973450,474.441711 454.423523,474.987793 
	C454.873718,474.953827 455.053406,474.935516 455.530029,475.040741 
	C456.844849,475.428375 457.862793,475.692535 459.284210,475.974335 
	C460.494446,475.992706 461.301178,475.993408 462.329285,476.200867 
	C463.121490,476.231201 464.163971,476.101379 464.186768,475.870758 
	C464.335541,474.366058 464.263275,472.839508 464.263275,471.320068 
	C464.698822,471.200043 465.134369,471.080048 465.569885,470.960022 
	C466.343018,472.557831 467.116119,474.155670 468.107361,476.204315 
	C468.777405,474.520691 469.313690,473.173126 470.220917,470.893494 
	C471.579926,472.470001 472.408813,473.664001 473.480469,474.571442 
	C473.976776,474.991730 474.945984,474.853577 476.118103,474.979675 
	C477.363953,474.993622 478.192505,474.996429 479.201019,475.241669 
	C479.984131,475.312836 480.587280,475.141571 481.373352,474.855316 
	C481.778107,474.541412 481.925598,474.297577 482.333649,473.924072 
	C483.923706,472.728668 485.178955,471.617920 486.434204,470.507202 
	C484.733429,469.949219 483.051361,468.996613 481.328033,468.914856 
	C477.655640,468.740692 473.939056,468.794128 470.282227,469.164978 
	C465.112305,469.689270 459.979980,470.584900 454.575806,470.930115 
	C451.185699,467.553406 449.197815,470.292877 446.611511,471.876892 
	C443.617401,471.549011 441.015930,471.182983 438.414093,471.180389 
	C432.560303,471.174530 431.798584,472.487976 433.694519,478.798218 
	C430.830322,478.152222 428.192352,477.170898 425.166718,476.131104 
	C424.520142,476.048462 424.261230,476.024231 423.908020,475.567413 
	C424.169678,472.410248 424.525604,469.685699 425.012024,465.962036 
	C422.608459,462.533264 421.774597,458.300781 426.670197,454.131653 
	C427.338318,453.664246 427.667328,453.325775 428.065643,452.727844 
	C428.420105,452.292145 428.705231,452.115936 429.290985,452.220276 
	C432.705719,452.631866 435.880554,453.171448 438.908295,452.721344 
	C440.417603,452.497040 442.626251,450.613190 442.845184,449.217438 
	C443.628540,444.223572 443.696198,439.117432 444.303833,433.806915 
	C445.360046,432.118164 446.146912,430.676239 447.503571,428.190247 
	C448.312592,430.594421 448.703979,431.757477 449.088684,433.177826 
	C449.411316,433.318085 449.740692,433.201019 450.200714,432.999512 
	C450.331360,432.915070 450.641846,432.894623 450.954346,432.813782 
	C451.528564,432.401733 451.790253,432.070496 452.407166,431.649963 
	C453.491730,431.577759 454.221039,431.594849 455.013336,431.925598 
	C455.455719,432.364166 455.835144,432.489105 456.214539,432.614075 
	C456.017639,431.694702 455.820740,430.775299 455.751953,429.519775 
	C454.459991,428.403656 453.069244,427.021637 451.614594,426.950745 
	C446.087372,426.681183 440.539459,426.835541 434.942535,426.519043 
	C430.823029,424.758698 431.231079,428.404724 430.443207,430.331787 
	C429.321655,433.075043 430.531616,434.843903 432.797028,435.558990 
	C433.318970,433.204315 433.766357,431.186127 434.612335,429.177246 
	C436.073334,430.528992 437.135742,431.871368 438.218781,433.239807 
	C438.392792,432.809570 438.573853,432.361938 439.042236,432.068756 
	C439.951141,432.797119 440.572723,433.371033 441.264008,433.792236 
	C441.333679,433.639557 441.669281,433.642365 441.431702,434.074799 
	C441.001923,438.248535 440.809753,441.989838 440.632385,445.443024 
	C436.715424,446.048828 433.350739,446.569244 429.649536,447.080902 
	C428.592651,446.382172 427.872284,445.692200 427.445160,444.950684 
	C427.446747,444.283234 427.155090,443.667328 426.641357,443.027557 
	C426.419312,443.003693 426.003784,442.839844 425.998749,442.515381 
	C425.410828,441.459106 424.827942,440.727325 424.170746,439.543335 
	C422.268066,433.376160 426.859619,426.755890 420.638275,420.140686 
	C420.638275,428.266266 420.638275,435.034393 420.638275,442.393738 
	C419.374084,442.458191 418.170685,442.519592 416.888855,442.175812 
	C415.585144,437.640900 414.543365,433.442261 413.033569,429.419281 
	C412.514587,428.036407 410.998627,426.788666 409.634216,426.015533 
	C407.311005,424.699127 404.747314,423.807159 402.145844,422.534760 
	C401.794586,422.074219 401.533508,421.881714 401.021210,421.489685 
	C399.178284,419.614594 397.553589,417.988586 395.893768,416.399261 
	C393.500000,414.107117 391.603119,414.100037 390.236877,417.758118 
	C387.782410,419.654785 385.630096,421.428162 383.136719,423.139160 
	C382.565979,423.050629 382.336304,423.024506 382.060242,422.531860 
	C381.967102,405.404694 382.001892,388.742950 381.790710,372.084320 
	C381.756866,369.412384 380.623596,366.754395 380.184601,363.799927 
	C384.077057,359.403137 383.449097,367.054596 386.746216,365.348297 
	C385.427002,362.459351 387.992676,357.341125 381.130035,357.712250 
	C381.838013,356.406464 382.420990,355.331238 383.339966,354.146179 
	C386.382965,352.344543 387.910095,353.142609 388.241333,356.700928 
	C387.793060,359.879486 388.216797,361.867706 392.345642,361.732330 
	C391.139221,359.292145 390.384674,357.765961 389.671112,355.866760 
	C393.125031,353.923004 396.537964,352.352173 400.083893,351.065857 
	C400.556732,351.584808 400.896545,351.819214 401.289429,352.171387 
	C401.342438,352.289154 401.168549,352.480133 401.141907,352.830688 
	C401.409485,353.618011 401.703705,354.054810 401.997925,354.491577 
	C402.273071,353.913910 402.548187,353.336243 402.849121,352.815308 
	C402.874939,352.872101 402.761383,352.820435 403.132996,352.876221 
	C405.476013,353.111847 407.447388,353.291687 410.007294,353.525208 
	C409.873077,351.003998 409.744781,348.593964 409.581055,345.518280 
	C407.103088,347.054413 405.480103,348.060577 403.633484,348.992004 
	C403.124084,348.729553 402.973175,348.464935 402.917786,347.748199 
	C402.067200,346.975220 401.255981,346.577484 400.095398,346.246582 
	C397.935120,346.580414 396.124146,346.847382 393.847107,347.108826 
	C393.071350,347.129700 392.761719,347.156067 392.116028,347.085022 
	C389.904205,347.765533 388.028473,348.543427 386.839691,349.036438 
	C384.778290,348.029907 382.929077,347.277557 381.259766,346.236694 
	C380.605133,345.828491 380.353485,344.773926 380.139648,343.931335 
	C380.642822,343.693298 380.867737,343.478119 380.990967,343.022491 
	C380.946320,342.841553 380.781525,342.507202 381.106750,342.702728 
	C381.927551,342.869049 382.423157,342.839844 383.247406,342.806458 
	C384.385956,342.159088 385.195892,341.515930 386.303284,341.066223 
	C387.821167,341.835236 389.041595,342.410828 390.677094,343.182220 
	C389.779694,338.732941 390.944031,332.950043 383.306763,336.341614 
	C382.516998,336.371735 382.200470,336.358856 381.688538,336.107422 
	C380.926758,335.620117 380.360321,335.371338 379.793945,335.122559 
M428.168030,382.992645 
	C423.607330,383.931396 423.053284,385.280151 425.208649,390.972534 
	C426.311005,389.556702 427.280334,388.311676 428.635925,387.265533 
	C429.006439,387.493652 429.376953,387.721771 429.899261,388.716614 
	C431.075623,389.806091 432.521118,391.996613 433.379944,391.786713 
	C436.439209,391.039001 439.298889,389.474731 442.246826,388.214172 
	C444.068848,392.866608 444.972229,393.200500 448.557434,389.994080 
	C448.557434,389.994080 449.028137,389.989441 449.501495,390.155396 
	C450.046021,390.105316 450.590576,390.055237 451.732513,389.946411 
	C451.929474,389.911072 452.126434,389.875732 453.051117,389.879486 
	C453.917206,388.248199 454.783325,386.616882 455.649414,384.985626 
	C454.232330,384.320190 452.846344,383.166077 451.392792,383.073547 
	C444.950562,382.663452 438.491882,384.806000 431.271088,383.003021 
	C430.507812,383.004333 429.744507,383.005646 428.168030,382.992645 
M402.311584,359.470367 
	C401.071869,358.900269 399.609070,357.638824 398.652893,357.947418 
	C397.622284,358.279999 397.066132,360.082703 396.174744,362.471405 
	C396.192566,362.882355 396.210419,363.293335 396.327545,364.321716 
	C398.226166,364.659546 400.124817,364.997345 402.915588,365.739166 
	C405.078644,365.704498 408.668579,367.561737 407.875183,362.606171 
	C409.449707,362.445740 411.024231,362.285339 412.598755,362.124908 
	C412.087067,362.695251 411.575378,363.265564 411.063690,363.835907 
	C412.492706,364.465424 413.958710,365.695557 415.343658,365.610199 
	C419.266449,365.368500 423.156097,364.588440 427.447845,364.011993 
	C427.447845,364.011993 427.837006,364.007080 428.217468,364.523132 
	C429.331329,364.941528 430.445221,365.359924 432.254181,366.073486 
	C435.918335,366.401581 438.348480,363.824463 437.935974,359.792816 
	C437.838440,358.839722 437.045288,357.268250 436.442902,357.204651 
	C435.305206,357.084412 434.073639,357.852295 432.331543,357.903412 
	C430.891846,357.937164 429.452179,357.970917 427.274231,357.998993 
	C426.536255,357.993073 425.798248,357.987122 424.281738,357.822693 
	C422.520416,359.041718 420.748901,360.246460 419.002747,361.486816 
	C418.294281,361.990021 417.622345,363.017456 416.967468,362.990417 
	C416.310455,362.963257 415.415161,362.037079 415.107574,361.302551 
	C413.138397,356.600189 409.801880,356.125183 404.408051,360.007812 
	C403.781403,360.032959 403.154724,360.058105 402.311584,359.470367 
M436.418915,419.996521 
	C436.418915,419.996521 436.894867,420.003510 437.462585,420.389282 
	C439.340088,419.830658 441.217590,419.272003 443.493439,419.266968 
	C446.079498,422.168915 448.445801,420.120697 451.401581,418.982056 
	C451.585663,418.988342 451.769745,418.994629 452.493530,419.469788 
	C455.938873,422.412109 459.788422,420.093933 461.598816,418.285583 
	C465.090576,414.797852 466.030884,417.028198 466.862640,418.666138 
	C469.816589,418.666138 472.030853,418.666138 474.139832,418.666138 
	C474.139832,416.892975 474.139832,414.995209 474.139832,413.975555 
	C471.492493,413.603302 469.433624,413.054688 467.385345,413.091858 
	C464.864044,413.137573 462.351562,413.671661 459.317139,413.993042 
	C458.971954,413.997101 458.626801,414.001160 457.876221,413.294647 
	C455.746216,413.482971 453.618500,413.704926 451.485596,413.850494 
	C449.193512,414.006927 446.896515,414.091583 443.674713,414.086975 
	C443.054565,414.060669 442.434448,414.034332 441.539307,413.362885 
	C438.143494,413.155457 434.747650,412.948029 430.592926,412.694244 
	C432.970154,415.930908 434.456696,417.954865 436.418915,419.996521 
M445.433716,402.013184 
	C445.216888,402.013947 445.000092,402.014679 444.253479,401.543091 
	C440.067017,402.047821 435.880585,402.552551 431.528625,403.077240 
	C431.528625,404.836548 431.528625,406.790588 431.528625,409.571533 
	C433.203766,407.987030 434.205139,407.039825 434.743713,406.530396 
	C437.014160,407.674774 438.851013,409.399536 440.630127,409.341919 
	C445.263397,409.191742 449.871338,408.259796 454.628998,407.620941 
	C453.474915,406.775940 452.256042,405.883484 451.641113,404.997803 
	C452.323517,404.190399 453.005890,403.382965 453.688293,402.575531 
	C453.259766,402.345856 452.831238,402.116211 452.402679,401.886536 
	C451.589264,402.598450 450.775818,403.310333 449.281403,403.996246 
	C448.215546,403.341339 447.149719,402.686432 445.433716,402.013184 
M576.801147,475.187286 
	C580.645020,467.955750 577.817871,462.109985 570.574036,461.890442 
	C569.510742,465.354126 568.464539,468.762268 567.542297,471.766296 
	C570.510620,473.098541 573.381897,474.387238 576.801147,475.187286 
M486.653534,475.023499 
	C487.500732,475.945404 488.347961,476.867279 489.195190,477.789215 
	C490.450348,475.965271 492.327271,474.277283 492.765106,472.274628 
	C493.119415,470.653961 491.741516,468.654633 491.002106,466.433136 
	C489.342896,469.912506 488.246704,472.211212 486.653534,475.023499 
M489.535461,385.412231 
	C489.174622,385.785339 488.813782,386.158417 488.452942,386.531494 
	C491.182739,388.202972 493.912537,389.874451 496.642303,391.545898 
	C496.951508,390.999817 497.260712,390.453735 497.569916,389.907654 
	C495.084076,388.515747 492.598236,387.123871 489.535461,385.412231 
M539.924988,398.694153 
	C539.410706,398.430756 538.896484,398.167328 538.382263,397.903900 
	C538.385986,398.652740 538.389709,399.401550 538.393433,400.150360 
	C538.905212,399.881805 539.417053,399.613251 539.924988,398.694153 
M500.629120,392.937836 
	C501.221497,392.598389 501.813843,392.258972 502.406189,391.919525 
	C501.889435,391.650726 501.156311,391.032501 500.903015,391.189301 
	C500.442200,391.474579 500.266266,392.220184 500.629120,392.937836 
M594.202515,430.609009 
	C594.206726,430.214111 594.210938,429.819244 594.215088,429.424316 
	C594.051208,429.422821 593.744873,429.410339 593.744019,429.421417 
	C593.713745,429.814362 593.712097,430.209503 594.202515,430.609009 
M416.202240,397.608826 
	C416.206421,397.214417 416.210632,396.819977 416.214813,396.425598 
	C416.051147,396.424072 415.745148,396.411560 415.744293,396.422577 
	C415.714050,396.815033 415.712341,397.209717 416.202240,397.608826 
M609.588867,464.517059 
	C609.588867,464.517059 609.477173,464.583954 609.588867,464.517059 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M237.985596,366.996735 
	C237.629822,335.976135 237.274048,304.955505 237.422943,273.467590 
	C253.236191,273.000580 268.544952,273.033447 283.853149,272.955963 
	C285.575317,272.947235 287.294342,272.314575 289.014801,271.971252 
	C301.857513,277.765259 315.309021,282.415039 327.705994,291.499603 
	C332.376465,289.324432 336.516510,294.134613 341.261627,296.181793 
	C352.019470,300.823090 362.444885,306.229797 373.151611,310.997070 
	C377.505829,312.935883 379.567169,315.440186 379.075653,320.417145 
	C378.625854,324.971710 379.057190,329.613281 378.811829,334.765594 
	C377.684875,335.548309 376.846375,335.781921 375.644104,336.006409 
	C374.197449,336.790192 373.114624,337.583130 371.733154,338.141235 
	C369.935760,337.502136 368.436981,337.097839 366.778564,336.650482 
	C366.778564,338.792877 366.778564,340.763397 366.778564,341.469757 
	C369.986694,341.992920 372.473541,342.398468 375.245483,342.822449 
	C375.911163,342.851532 376.291809,342.862244 377.107727,342.907532 
	C377.834137,342.938568 378.125214,342.934967 378.489502,343.361816 
	C377.987640,348.045441 374.032867,345.865356 371.595581,347.123108 
	C369.593750,348.020782 367.953613,349.057678 366.313507,350.094574 
	C367.559814,350.720428 368.806152,351.346283 370.188385,352.307373 
	C372.858795,353.261932 375.393250,353.881226 377.941284,354.908386 
	C378.024170,358.679474 378.093475,362.042664 377.861298,365.663513 
	C374.478424,366.850555 374.324646,365.135406 373.814423,362.642914 
	C373.412140,360.677765 371.366608,359.049042 370.050903,357.270874 
	C368.708405,358.859192 367.303955,360.401428 366.064270,362.066315 
	C365.728394,362.517426 365.890442,363.339294 365.438934,363.993317 
	C363.755554,363.995850 362.457184,363.996033 361.081909,363.627716 
	C360.990692,360.845032 360.976410,358.430817 361.011963,355.661865 
	C360.960236,354.842194 360.858643,354.377228 360.861145,353.470978 
	C361.071136,350.616180 361.176971,348.202728 361.220398,345.439514 
	C360.668427,344.360718 360.178894,343.631622 359.190765,342.159882 
	C358.894623,344.087372 358.732910,345.140045 358.312561,346.468628 
	C357.950775,349.157745 357.847565,351.570923 357.685669,354.249695 
	C357.499573,355.043854 357.372131,355.572388 357.054443,356.552521 
	C357.168365,358.543060 357.787170,360.104919 357.677429,361.613770 
	C357.589386,362.824615 356.617950,363.971191 355.973328,364.705536 
	C355.178009,363.879547 354.447937,363.494293 353.343201,363.005005 
	C352.227692,362.869324 351.486877,362.837646 350.553772,362.864319 
	C350.361450,362.922668 349.961365,362.987610 349.613403,363.054565 
	C348.828308,364.363678 348.391174,365.605865 347.842712,367.200134 
	C347.644104,368.036285 347.556763,368.520355 347.373840,368.979126 
	C347.278259,368.953766 346.924255,368.922546 346.677979,368.546570 
	C341.405701,367.784607 336.384949,367.187744 331.352448,367.061920 
	C323.775757,366.872528 316.190826,367.012634 308.915588,367.012634 
	C305.230774,357.744354 312.876709,354.257568 318.157288,347.922913 
	C315.751190,346.120850 313.506561,344.439728 311.142029,342.487000 
	C310.418335,341.736969 309.814453,341.258606 309.053284,340.544098 
	C308.342773,339.793854 307.789520,339.279755 307.053955,338.474060 
	C305.469055,338.332397 304.066528,338.482300 302.457245,338.759155 
	C301.963806,339.059662 301.746094,339.296631 301.400299,339.726044 
	C300.941406,340.041656 300.734985,340.276855 300.374542,340.683014 
	C299.925659,341.025269 299.731445,341.279724 299.290283,341.654724 
	C298.520264,342.355743 298.042511,342.970703 297.371826,343.718384 
	C296.940552,344.062225 296.746613,344.308350 296.299377,344.672119 
	C295.503784,345.364258 295.005859,345.973663 294.140808,346.672272 
	C290.704498,347.764587 287.635315,348.767700 284.256653,349.922974 
	C282.479126,350.514099 280.989716,351.361694 279.548431,351.286591 
	C277.825378,351.196777 276.143494,350.317932 274.383698,349.357513 
	C274.734497,345.442139 272.055054,340.633423 278.371033,339.575714 
	C278.724030,339.516602 278.751862,337.515869 279.021240,335.866791 
	C277.254639,336.961578 276.252930,337.582367 274.009277,338.972809 
	C274.466675,333.556000 274.822662,329.340088 275.463287,324.821594 
	C275.938934,321.420593 276.129944,318.322174 276.601715,315.027588 
	C281.915131,305.476654 281.842468,305.642151 274.628479,302.474457 
	C270.440521,300.635498 267.868988,301.018524 266.685242,305.886475 
	C266.194977,307.902527 265.021851,309.752502 264.057556,311.905151 
	C266.666901,313.449402 269.045685,314.857208 271.522095,316.684692 
	C271.830627,319.385803 272.041534,321.667206 272.347076,324.412537 
	C272.413208,327.514313 272.260803,330.158142 272.386841,332.788635 
	C272.551788,336.230560 272.337402,339.272888 267.342987,339.596832 
	C273.991333,340.261017 272.661804,344.553101 272.244019,348.393158 
	C270.088287,347.939575 267.932526,347.485992 265.162933,346.903290 
	C265.162933,353.537079 265.162933,359.290802 264.863403,365.663574 
	C264.416016,366.646881 264.268158,367.011108 264.120270,367.375305 
	C256.057800,367.595459 247.995346,367.815613 239.354660,367.791931 
	C238.573227,367.277710 238.309601,367.093903 237.985596,366.996735 
M327.273071,355.230743 
	C329.508728,355.123016 331.744354,355.015289 334.175415,355.651581 
	C332.677429,357.401306 331.179443,359.151001 329.681458,360.900726 
	C331.399872,361.246307 332.762482,360.841888 333.703308,359.978027 
	C336.878723,357.062408 339.904388,353.983704 343.755493,350.990356 
	C349.656006,351.639252 346.644958,358.446320 350.367493,360.705292 
	C351.459198,354.068756 348.317261,349.053497 342.465393,348.584930 
	C339.410126,345.570740 336.519257,342.353821 333.201416,339.663452 
	C332.180756,338.835876 329.349182,338.726166 328.453766,339.526398 
	C324.908203,342.695007 321.801453,346.354706 318.010712,350.386902 
	C319.690613,351.804382 321.623383,353.435211 323.863159,355.529083 
	C324.142273,355.766266 324.421356,356.003448 324.982666,356.879211 
	C325.980652,357.730408 326.978668,358.581573 327.976685,359.432739 
	C328.272705,359.123016 328.568695,358.813293 328.864716,358.503571 
	C328.067719,357.601746 327.270721,356.699951 327.273071,355.230743 
M301.185638,309.191162 
	C300.380341,303.739502 296.289795,306.572723 292.894196,306.173553 
	C292.359863,306.428711 291.825531,306.683868 290.795441,306.381866 
	C285.470123,306.479553 284.157959,308.501678 286.185791,314.205383 
	C286.115753,315.182556 286.045715,316.159760 285.615875,317.540466 
	C286.066193,318.311005 286.516541,319.081512 287.225372,320.385529 
	C287.548096,320.604279 287.870850,320.822998 288.258057,321.678528 
	C288.664276,322.725952 289.070496,323.773346 290.368805,324.904022 
	C290.667450,324.903320 290.966095,324.902649 292.159515,325.051514 
	C293.767975,324.810913 295.382172,324.335876 296.983795,324.375122 
	C301.231689,324.479095 305.478394,325.027374 309.716919,324.946259 
	C311.735199,324.907593 314.804749,324.445465 315.549744,323.129120 
	C318.293854,318.280426 319.739197,321.770203 321.250946,323.264008 
	C323.012299,322.787323 324.489899,322.387421 326.465210,321.999969 
	C326.465210,321.999969 326.963074,322.001587 327.197083,322.607361 
	C327.397675,322.851044 327.578522,323.116333 327.809143,323.327148 
	C327.894897,323.405548 328.225769,323.377625 328.228302,323.362366 
	C328.558838,321.363525 328.869202,319.361389 329.161926,317.479218 
	C326.832123,317.841827 325.021851,318.440002 323.256653,318.328064 
	C320.213928,318.135010 317.199249,317.500153 313.683197,317.155243 
	C313.255035,317.403473 312.826874,317.651733 311.823639,317.363556 
	C307.128387,317.968689 302.439636,318.642334 297.729309,319.087860 
	C297.171967,319.140564 296.496490,317.943939 295.953430,316.430908 
	C295.962769,315.534607 295.972137,314.638275 296.568146,313.503204 
	C298.015503,312.653259 299.462830,311.803314 301.620056,311.266357 
	C304.148407,312.087646 306.676758,312.908936 309.178833,313.721710 
	C309.178833,310.705872 309.178833,307.923737 309.178833,305.530151 
	C306.321899,307.149750 303.814240,308.571320 301.185638,309.191162 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M862.335083,552.082397 
	C863.977600,551.816650 865.303162,551.674133 866.534607,551.255188 
	C868.375916,550.628723 870.151001,549.807800 872.415649,549.591797 
	C872.871460,552.569641 873.101379,555.055176 872.795959,557.473083 
	C872.474304,560.020020 871.641052,562.502258 870.845947,565.265015 
	C870.157349,568.786316 871.971680,569.911682 874.860596,570.312012 
	C877.812927,570.721069 880.968262,570.848022 883.617249,572.032288 
	C890.569092,575.140259 897.359741,578.633423 904.069214,582.243530 
	C906.148682,583.362610 907.761169,585.349487 909.934875,587.241455 
	C914.567871,585.914001 918.870728,588.091858 920.007812,593.333740 
	C920.055603,594.293274 920.097717,594.841003 920.007080,595.671875 
	C918.936584,596.006470 917.746033,596.451599 917.105347,596.040283 
	C914.510620,594.374451 913.764465,594.583740 913.031982,598.129639 
	C913.085144,599.278564 913.138977,599.933960 913.104736,600.760071 
	C912.799377,601.100037 912.556396,601.154663 911.847290,601.118286 
	C911.115723,601.182190 910.824463,601.222656 910.367737,601.334717 
	C909.940430,601.450867 909.720642,601.374390 909.066650,601.131104 
	C907.954285,601.095032 907.318176,601.104797 906.417480,600.867065 
	C905.189819,598.926575 903.170715,597.019592 903.462219,595.580750 
	C904.323730,591.328857 901.576782,590.613220 898.848633,589.301025 
	C895.666870,587.770691 892.648499,585.900696 889.181152,584.142456 
	C888.186157,584.441406 887.569214,584.775879 886.514893,585.064575 
	C879.679016,585.304749 873.312744,587.509583 866.900269,584.843384 
	C866.872314,583.682251 866.817078,583.018860 866.988647,582.132935 
	C868.920593,581.065674 870.625610,580.221008 872.622437,579.447815 
	C874.445251,580.205261 875.976379,580.891296 877.853577,581.732300 
	C877.254700,576.693237 877.032654,572.110962 870.103027,574.128662 
	C869.054932,574.070679 868.455017,574.062744 867.489624,574.065308 
	C865.424866,575.414612 863.226868,576.477600 862.262085,578.222534 
	C861.853333,578.961731 864.034973,581.133301 865.025391,583.036255 
	C865.007141,583.926208 865.002869,584.433716 864.700500,585.096558 
	C863.026367,585.949280 861.650391,586.646667 859.874084,587.281128 
	C857.956848,586.838562 856.439880,586.458862 854.583801,586.061523 
	C852.945557,586.938049 851.646484,587.832275 850.203857,588.367065 
	C848.220398,584.535706 845.886597,584.022400 842.541870,586.892578 
	C839.293335,586.987549 836.445679,586.703552 833.733704,587.184570 
	C832.386597,587.423584 830.601929,588.850525 830.203064,590.114807 
	C828.853149,594.393494 828.034058,598.839722 826.625610,603.242188 
	C824.183594,603.514893 822.128174,603.767395 819.817139,604.103821 
	C819.094177,604.452271 818.626892,604.716675 817.965210,604.637817 
	C816.544800,604.450745 815.318726,604.606934 814.025574,604.284058 
	C813.981140,602.197449 814.003784,600.589844 814.012207,598.989868 
	C813.997925,598.997498 813.984802,599.027039 814.289917,598.942261 
	C815.114319,598.213257 815.633545,597.569031 816.400024,596.671387 
	C817.429382,593.381958 817.192810,590.992493 813.066528,590.618774 
	C811.683716,589.193726 810.440918,587.233521 809.184021,587.224365 
	C799.301514,587.152771 789.397827,587.151733 779.538391,587.749634 
	C773.183228,588.135010 772.607605,589.753357 774.998840,596.434692 
	C775.004150,596.596680 775.022278,596.920349 774.673706,596.958496 
	C773.157288,598.880127 771.989441,600.763611 770.533203,602.732910 
	C769.382446,603.287354 768.520142,603.755981 767.174683,604.160461 
	C765.719849,604.095215 764.748108,604.094177 763.423706,604.007202 
	C761.662537,603.251953 760.159973,602.724426 758.873901,601.870728 
	C757.742126,601.119446 756.852600,600.003296 755.921387,598.716919 
	C756.015686,597.936218 756.044556,597.486877 756.309387,596.791626 
	C756.463440,595.044067 756.381592,593.542358 756.351929,591.660339 
	C756.537170,590.532654 756.670227,589.785278 757.012573,588.947693 
	C757.155884,588.062561 757.089966,587.267700 757.066895,586.101074 
	C757.131165,585.480652 757.152527,585.231995 757.413757,584.715332 
	C757.475403,582.650513 757.297119,580.853699 757.091919,578.588013 
	C757.064941,573.886963 757.064941,569.654846 757.064941,565.089355 
	C759.483765,565.089355 761.121277,565.092346 762.758850,565.088806 
	C770.730042,565.071594 778.702332,565.109863 786.671265,564.967407 
	C787.763550,564.947876 788.839722,564.026306 789.923279,563.522278 
	C789.268433,562.687012 788.613586,561.851746 787.953491,560.680054 
	C787.963501,559.895508 787.978760,559.447327 788.253296,558.751038 
	C790.720642,550.866821 789.016357,544.102173 784.058350,537.842957 
	C784.413269,537.168335 784.674194,536.733948 785.365784,536.226074 
	C794.846924,535.938416 801.841553,531.789734 804.272583,525.194153 
	C804.937744,525.203369 805.602844,525.212524 806.592896,525.292603 
	C808.672485,526.476562 810.337830,527.774109 812.195923,528.673523 
	C827.666077,536.162354 843.167908,543.585938 858.675476,550.997131 
	C859.711731,551.492432 860.872925,551.726318 862.335083,552.082397 
M792.649353,553.103455 
	C792.356079,553.372925 792.062744,553.642395 791.769409,553.911804 
	C792.302185,554.104980 792.834900,554.298157 793.367676,554.491394 
	C793.309021,554.078552 793.250427,553.665710 792.649353,553.103455 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M684.965942,462.673309 
	C685.294556,462.727997 685.723877,462.667603 685.937256,462.854126 
	C692.552429,468.637146 701.554504,469.211914 708.956177,473.497437 
	C716.675903,477.967102 725.237122,480.969727 733.370850,484.742279 
	C739.702332,487.678955 745.976685,490.745880 752.186401,493.930573 
	C753.128662,494.413849 754.022644,495.785278 754.183044,496.858826 
	C754.804810,501.020630 755.121033,505.228088 756.012634,509.431976 
	C756.326172,505.399292 756.639709,501.366577 757.050720,496.079498 
	C766.014160,500.238586 774.176331,504.025879 782.567444,507.919373 
	C781.888794,510.389893 781.239685,512.752991 780.236084,515.626953 
	C778.284180,519.576111 776.653931,522.999573 775.096069,526.455688 
	C771.712341,533.962463 768.365479,541.485840 764.896179,549.280029 
	C763.222717,549.666809 761.656616,549.775940 759.917114,549.562866 
	C757.399780,546.276428 759.914246,544.860901 761.641113,543.135620 
	C763.908203,540.870789 766.102905,538.533508 768.144836,536.418030 
	C763.522583,529.186768 753.876587,526.102600 755.508301,518.157837 
	C756.874451,522.627563 752.619934,526.412354 748.435974,530.295410 
	C747.378601,531.276733 746.668640,532.632385 745.494629,534.012878 
	C743.737244,534.755066 742.166138,535.106873 740.864624,535.900696 
	C739.587585,536.679565 738.584778,537.907959 737.154785,539.037598 
	C735.324158,541.887634 734.012756,544.794312 732.171936,547.314331 
	C731.436646,548.320923 729.533203,548.474182 728.199341,548.634155 
	C728.608582,545.322998 728.979858,542.393494 729.351135,539.463989 
	C728.958374,539.369141 728.565552,539.274292 728.172729,539.179443 
	C726.139648,541.467651 724.106628,543.755920 721.711060,545.982910 
	C713.313965,542.509094 705.279419,539.096436 697.101624,535.362793 
	C692.672791,532.928650 691.810303,529.899902 694.451904,525.872986 
	C694.803772,525.336670 694.781921,524.553040 695.139343,524.022888 
	C698.032471,519.731689 696.761719,517.390564 691.973511,515.801025 
	C689.491943,514.977234 685.620850,513.033447 685.491333,511.355316 
	C684.951904,504.365723 685.595886,497.284821 685.988586,490.510193 
	C687.139648,491.690979 688.107056,492.599792 689.076294,493.506683 
	C695.904541,499.895599 695.904724,499.895508 702.852173,493.418762 
	C709.005005,487.682831 709.004211,487.678009 703.101746,481.613190 
	C702.176147,480.662048 701.288269,479.672974 700.340088,478.745117 
	C695.255249,473.769012 695.223511,473.770477 690.538330,479.327850 
	C690.115417,479.829620 689.822815,480.441284 689.378906,481.066284 
	C689.288025,481.130707 689.000366,481.469055 688.710693,481.693542 
	C687.941895,482.938171 687.462769,483.958313 686.989563,484.987915 
	C686.995422,484.997375 686.971924,484.987305 686.713379,484.838318 
	C686.059753,484.699677 685.664673,484.710022 685.180786,484.277771 
	C685.049927,476.781189 685.007935,469.727264 684.965942,462.673309 
M755.480530,512.616516 
	C755.480530,512.616516 755.580322,512.439575 755.580322,512.439575 
	C755.580322,512.439575 755.384766,512.465332 755.480530,512.616516 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M288.822876,271.686737 
	C287.294342,272.314575 285.575317,272.947235 283.853149,272.955963 
	C268.544952,273.033447 253.236191,273.000580 237.457703,272.999756 
	C236.987823,265.370026 236.987823,257.740753 236.987823,250.131012 
	C241.985733,247.514328 245.699692,250.875565 249.682343,252.770157 
	C262.678619,258.952698 275.649994,265.187500 288.822876,271.686737 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M182.015640,375.028656 
	C173.160843,375.012665 164.306030,374.975677 155.451263,374.985626 
	C142.031464,375.000702 128.611572,375.086426 115.192101,375.036224 
	C113.431862,375.029633 111.673965,374.397339 109.512024,373.979187 
	C110.575195,373.298981 112.037956,372.176239 113.507980,372.166656 
	C140.970993,371.987640 168.437317,372.139404 195.897842,371.804443 
	C218.491364,371.528870 241.078232,370.708801 264.099854,370.472107 
	C264.615814,371.553589 264.699982,372.293030 264.420441,373.205872 
	C261.930725,373.574219 259.807220,373.902344 257.678314,373.941803 
	C246.398773,374.151001 235.117874,374.287628 223.433228,374.379578 
	C219.733429,374.400940 216.438324,374.524841 213.142105,374.577240 
	C202.766846,374.742157 192.391159,374.880188 182.015640,375.028656 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M765.003540,549.002441 
	C768.365479,541.485840 771.712341,533.962463 775.096069,526.455688 
	C776.653931,522.999573 778.284180,519.576111 780.163147,516.008423 
	C781.745300,516.155945 783.045898,516.432983 784.533203,517.189697 
	C782.514282,522.693604 780.308594,527.717773 778.079529,532.795349 
	C788.209717,536.416931 798.404358,532.728149 801.959412,525.021362 
	C802.473938,525.038757 802.988464,525.056091 803.887817,525.133789 
	C801.841553,531.789734 794.846924,535.938416 785.079285,536.160034 
	C783.809326,536.274231 783.256470,536.381042 782.703613,536.487854 
	C783.123901,537.019653 783.544128,537.551392 783.964355,538.083191 
	C789.016357,544.102173 790.720642,550.866821 787.897095,558.742920 
	C786.807678,558.950134 786.333862,558.917297 785.941284,558.487549 
	C789.170837,549.597839 784.626099,538.089600 776.780945,536.190491 
	C774.532837,540.135132 772.280823,544.086548 769.928589,547.871094 
	C769.558655,547.831604 769.289001,547.959045 769.048340,548.059937 
	C769.077454,548.033447 769.132263,548.089905 768.859985,548.246338 
	C767.786865,550.956726 766.621826,551.334290 765.003540,549.002441 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M790.514648,508.356415 
	C797.931824,511.972046 804.943054,515.554688 812.016846,519.009094 
	C823.157471,524.449585 834.340332,529.803467 845.504578,535.195740 
	C846.109009,535.487671 846.706116,535.794861 847.306641,536.094971 
	C847.043762,536.759888 846.780884,537.424744 846.518005,538.089600 
	C844.111877,537.637268 841.498779,537.639038 839.335571,536.653381 
	C830.302185,532.537231 821.419434,528.091553 812.442993,523.848083 
	C810.671326,523.010498 808.729553,522.532654 806.487183,521.843811 
	C800.162903,519.161072 794.153748,516.652039 788.336365,513.757446 
	C787.381470,513.282288 787.270386,511.111542 787.083862,509.245544 
	C787.639282,508.548859 787.781799,508.279114 787.826050,507.957397 
	C788.596252,508.060242 789.366455,508.163086 790.514648,508.356415 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M231.121552,308.709534 
	C219.387543,305.363251 215.308289,300.993225 214.630051,291.085175 
	C219.610245,291.085175 224.566605,291.015106 229.517822,291.143188 
	C230.530884,291.169434 232.404739,291.960999 232.394531,292.361877 
	C232.255905,297.800781 231.854675,303.233032 231.121552,308.709534 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M801.659912,524.842590 
	C798.404358,532.728149 788.209717,536.416931 778.079529,532.795349 
	C780.308594,527.717773 782.514282,522.693604 784.887817,517.357666 
	C790.490662,519.585205 795.925598,522.124512 801.659912,524.842590 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M106.986832,404.395508 
	C106.973534,402.947357 107.003212,401.869934 107.001373,400.389191 
	C106.979919,392.894135 106.927071,385.801239 107.050911,378.711456 
	C107.076988,377.218628 107.751495,375.737091 108.514709,374.179901 
	C109.570038,380.255066 110.380386,386.389801 110.853142,392.550385 
	C111.144447,396.346436 111.030083,400.187225 110.849213,403.996124 
	C110.805351,404.919861 109.779900,405.796997 109.205795,406.695526 
	C108.480461,406.052429 107.755135,405.409332 106.986832,404.395508 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M272.255585,348.652466 
	C272.661804,344.553101 273.991333,340.261017 267.342987,339.596832 
	C272.337402,339.272888 272.551788,336.230560 272.386841,332.788635 
	C272.260803,330.158142 272.413208,327.514313 272.652039,324.519745 
	C273.634430,324.483398 274.406555,324.803772 275.178680,325.124146 
	C274.822662,329.340088 274.466675,333.556000 274.009277,338.972809 
	C276.252930,337.582367 277.254639,336.961578 279.021240,335.866791 
	C278.751862,337.515869 278.724030,339.516602 278.371033,339.575714 
	C272.055054,340.633423 274.734497,345.442139 274.265961,349.749481 
	C274.259460,351.101471 274.310669,351.638123 274.332672,352.627838 
	C274.267365,358.017242 274.231262,362.953613 273.892059,367.927551 
	C273.385498,367.973297 273.182068,367.981506 272.869202,367.533264 
	C272.524689,361.361481 272.289581,355.646149 272.054443,349.930847 
	C272.125366,349.591156 272.196259,349.251434 272.255585,348.652466 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M271.613220,349.943420 
	C272.289581,355.646149 272.524689,361.361481 272.661041,367.856445 
	C271.063171,368.720093 269.564026,368.804077 268.064911,368.888062 
	C268.003876,363.738373 268.004364,358.586884 267.854706,353.439789 
	C267.784424,351.022339 268.374695,349.510498 271.613220,349.943420 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M267.694366,368.953674 
	C269.564026,368.804077 271.063171,368.720093 272.770447,368.312927 
	C273.182068,367.981506 273.385498,367.973297 274.022919,368.091370 
	C274.606293,368.455231 274.779877,368.673645 275.186554,369.144836 
	C276.987976,369.235474 278.580505,369.054108 280.607635,368.940979 
	C281.896667,369.917114 282.751099,370.825043 283.605560,371.732971 
	C282.707703,372.277954 281.870667,373.088776 280.898956,373.311249 
	C279.010345,373.743591 277.046143,373.845734 274.827332,374.152466 
	C274.021393,374.485077 273.501373,374.749847 272.781555,374.652527 
	C270.393463,374.122528 268.205139,373.954620 265.874390,373.576172 
	C265.416046,373.254578 265.100098,373.143494 264.784149,373.032440 
	C264.699982,372.293030 264.615814,371.553589 264.534424,370.440033 
	C265.466064,369.716980 266.394958,369.368103 267.694366,368.953674 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M234.007111,296.278809 
	C233.998428,294.196075 233.998428,292.570770 233.998428,290.945496 
	C236.881210,303.363037 235.570511,315.868896 236.061127,328.332703 
	C235.379349,328.353271 234.697556,328.373840 234.015778,328.394409 
	C234.015778,317.841675 234.015778,307.288940 234.007111,296.278809 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M684.920898,462.381287 
	C685.007935,469.727264 685.049927,476.781189 684.833435,484.378784 
	C683.694031,485.273102 682.813110,485.623810 681.932129,485.974487 
	C681.957031,477.639587 681.981873,469.304749 681.948608,460.509644 
	C682.885559,460.729401 683.880676,461.409363 684.920898,462.381287 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M376.007874,336.015564 
	C376.846375,335.781921 377.684875,335.548309 378.833252,335.204285 
	C379.143127,335.093933 379.466980,335.151428 379.630432,335.136993 
	C380.360321,335.371338 380.926758,335.620117 381.700195,336.435669 
	C382.244385,338.938507 382.581573,340.874542 382.918762,342.810608 
	C382.423157,342.839844 381.927551,342.869049 380.948181,342.453369 
	C380.081909,340.493866 379.699371,338.979248 379.269714,337.278046 
	C378.541504,336.996185 377.274689,336.505890 376.007874,336.015564 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M783.381714,514.199707 
	C783.525146,513.763184 783.804199,513.496643 784.104675,513.257019 
	C784.122070,513.243164 784.313354,513.447388 784.423645,513.550110 
	C784.123108,513.825256 783.822571,514.100403 783.381714,514.199707 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M380.740967,315.028015 
	C380.907501,315.306458 380.722290,315.572266 380.228882,315.887238 
	C380.165161,315.690918 380.321320,315.383911 380.740967,315.028015 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M787.623047,508.078735 
	C787.781799,508.279114 787.639282,508.548859 787.210449,508.905518 
	C787.045166,508.711151 787.177734,508.429688 787.623047,508.078735 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M238.058456,367.244171 
	C238.309601,367.093903 238.573227,367.277710 238.886292,367.765991 
	C238.707886,367.819763 238.419601,367.655670 238.058456,367.244171 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M264.368713,367.209839 
	C264.268158,367.011108 264.416016,366.646881 264.823303,366.129395 
	C264.927521,366.332184 264.772339,366.688263 264.368713,367.209839 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1188.004761,908.959717 
	C1188.004761,908.959717 1187.514282,908.957153 1186.965332,908.656250 
	C1179.283691,908.542480 1172.151001,908.729675 1165.018311,908.916870 
	C1161.212646,908.340332 1157.419678,907.399353 1153.598511,907.266541 
	C1146.623413,907.024048 1145.037109,905.861572 1144.961670,898.674500 
	C1144.882080,891.094238 1145.207642,883.509705 1145.758789,875.455566 
	C1146.637085,874.556641 1147.112671,874.129211 1147.588257,873.701782 
	C1146.912598,873.458801 1146.236938,873.215759 1145.561279,872.972778 
	C1145.620239,867.886902 1145.679321,862.800964 1145.873535,856.913452 
	C1146.010254,849.472717 1146.248291,842.822266 1145.927612,836.198730 
	C1145.725098,832.014893 1147.133057,830.855774 1151.250366,830.920654 
	C1159.834595,831.056091 1168.427979,830.607300 1177.439941,830.332703 
	C1182.020752,828.619873 1179.676514,825.118469 1179.719238,822.518799 
	C1179.732300,821.716492 1177.015137,820.249939 1175.481812,820.154785 
	C1170.337280,819.835510 1165.162964,819.998169 1159.947021,819.637451 
	C1159.173828,818.844971 1158.453491,818.391479 1157.759766,817.562683 
	C1160.434082,808.820679 1165.778198,803.514282 1174.793213,802.833618 
	C1178.711548,802.537781 1180.622925,801.042114 1180.191406,796.915100 
	C1180.416748,796.741577 1180.561890,796.603760 1180.537476,796.559631 
	C1180.431641,796.368286 1180.276367,796.204346 1180.412598,795.733398 
	C1197.223633,795.295410 1213.761108,795.085266 1230.298950,795.062134 
	C1234.403687,795.056396 1238.522949,796.039978 1242.611572,795.894653 
	C1250.208984,795.624817 1257.783936,794.467712 1265.376953,794.373962 
	C1286.661621,794.111267 1307.951050,794.252747 1329.237183,794.068298 
	C1331.173462,794.051514 1333.099365,792.837646 1335.359863,791.933228 
	C1335.477417,789.355164 1335.265015,787.023499 1335.052856,784.304199 
	C1334.937988,783.146301 1334.822876,782.376099 1334.932129,781.385864 
	C1335.838501,780.443848 1336.520508,779.721863 1336.899658,779.320435 
	C1338.111450,779.604248 1339.003662,779.813232 1340.152588,779.895874 
	C1340.616211,779.455627 1340.823242,779.141724 1341.364014,778.776611 
	C1348.183594,780.996277 1354.886719,779.341736 1361.485596,779.840637 
	C1361.350708,777.780701 1361.218384,775.760681 1361.085449,773.730347 
	C1359.701172,774.038086 1358.580933,774.173584 1357.551636,774.554565 
	C1356.822754,774.824341 1356.216797,775.426025 1355.105591,775.913452 
	C1354.055786,775.983643 1353.456421,776.020020 1352.590576,775.883179 
	C1351.289307,773.598389 1350.006470,772.422974 1347.476929,774.354248 
	C1346.827881,774.121826 1346.557373,774.061707 1346.037720,773.812256 
	C1342.173828,776.678284 1338.620850,774.241699 1335.035889,773.064514 
	C1335.029297,765.121887 1335.035645,757.616394 1335.378662,750.015625 
	C1336.158203,749.779663 1336.601196,749.638977 1337.398682,749.608459 
	C1340.459961,749.894348 1343.166382,750.070068 1345.887207,750.632202 
	C1349.247559,754.485168 1352.073853,754.013367 1354.682861,749.513977 
	C1358.829224,749.508057 1362.570312,749.608582 1366.222900,750.188721 
	C1366.105957,763.638489 1366.126709,776.609070 1366.016724,789.578491 
	C1365.987061,793.072510 1367.450684,794.070618 1370.867676,794.080383 
	C1377.461914,794.099243 1384.052490,794.751038 1390.649902,794.893555 
	C1391.800781,794.918457 1392.977783,793.732971 1394.502930,793.281006 
	C1395.897827,793.630737 1396.932251,793.806030 1398.361572,793.993164 
	C1400.860229,793.751709 1402.964355,793.498291 1405.483398,793.100159 
	C1407.920898,792.957214 1409.943359,792.959045 1412.430664,793.041016 
	C1418.933228,793.092102 1424.970947,793.062988 1431.311035,793.318115 
	C1446.861938,793.698059 1462.110352,793.804138 1477.358887,793.848389 
	C1477.912964,793.849976 1478.468872,793.244080 1479.490601,792.941895 
	C1490.667847,792.919861 1501.378418,792.877319 1512.456299,792.969482 
	C1514.549805,793.194397 1516.296143,793.493103 1517.998413,793.330872 
	C1521.350464,793.011475 1524.678223,792.436279 1528.000977,792.062622 
	C1527.985962,792.158264 1527.906982,792.335144 1527.982910,792.593994 
	C1528.707764,792.679810 1529.356567,792.506775 1530.389160,792.189392 
	C1531.847290,792.112427 1532.921753,792.179810 1534.044067,792.531433 
	C1534.765381,792.815063 1535.438843,792.814453 1536.539307,792.792053 
	C1545.845215,792.761230 1554.724121,792.750305 1563.602905,792.743774 
	C1570.139648,792.739014 1570.139648,792.741516 1569.966431,785.557739 
	C1570.045166,779.726501 1570.086182,774.364197 1570.409058,768.957397 
	C1570.989624,768.595154 1571.288208,768.277344 1571.586792,767.959534 
	C1571.102417,767.639038 1570.618042,767.318481 1570.097168,766.531311 
	C1570.083130,760.114990 1570.105835,754.165283 1570.439697,748.172119 
	C1571.162720,748.058044 1571.574585,747.987549 1572.449463,748.003540 
	C1579.960327,747.930054 1587.008423,747.770203 1594.454590,747.712708 
	C1596.138794,748.173706 1597.424927,748.532288 1598.718384,749.284668 
	C1599.246216,751.526550 1599.766846,753.374634 1600.128418,755.630310 
	C1599.325928,759.965393 1598.579956,763.881775 1598.149292,767.832458 
	C1598.084717,768.424744 1599.496948,769.177917 1600.225952,769.856628 
	C1600.812622,769.028381 1601.950073,768.160217 1601.893311,767.378662 
	C1601.599609,763.328064 1601.000854,759.299622 1600.444824,755.096680 
	C1600.326294,754.676208 1600.373169,754.442688 1600.881836,754.002686 
	C1601.484741,751.901978 1601.728149,750.028015 1602.404297,748.003174 
	C1616.095825,747.628906 1629.354736,747.405640 1642.746582,747.517273 
	C1642.987061,748.628052 1643.094604,749.403992 1643.403076,751.630798 
	C1645.118896,749.607727 1646.176636,748.360596 1647.600220,747.183472 
	C1649.204834,747.220642 1650.443481,747.187805 1651.826660,747.560181 
	C1652.178589,750.241089 1652.385986,752.516846 1652.593384,754.792542 
	C1653.082886,754.809937 1653.572388,754.827271 1654.061890,754.844666 
	C1654.260864,752.922424 1654.459961,751.000122 1654.969971,748.923645 
	C1655.482544,748.367432 1655.683960,747.965454 1655.918945,747.427124 
	C1655.952637,747.290833 1656.003540,747.014648 1656.374634,747.075562 
	C1657.494629,747.100769 1658.243530,747.064941 1659.323730,747.175049 
	C1661.134399,747.464417 1662.614014,747.607971 1664.046631,748.130371 
	C1663.065918,749.193298 1662.132202,749.877258 1661.068481,750.656494 
	C1664.119751,751.311401 1664.119751,751.311401 1668.014771,747.962280 
	C1668.003418,751.623962 1668.052002,754.789429 1667.983398,757.952271 
	C1667.926147,760.597229 1668.968872,762.020935 1671.767822,762.007446 
	C1679.233032,761.971497 1686.698853,762.031799 1694.163818,761.982300 
	C1697.904541,761.957520 1698.995361,759.647156 1698.618042,756.337769 
	C1698.468628,755.028259 1698.094849,753.744385 1697.846191,752.099121 
	C1699.409058,751.455627 1700.948975,751.161743 1702.790283,750.848755 
	C1704.010620,750.877197 1704.929443,750.924866 1705.882935,751.441528 
	C1705.959595,763.398071 1706.050537,774.885864 1706.010254,786.373108 
	C1705.999756,789.371582 1706.753052,791.185730 1710.207642,790.976196 
	C1714.164185,790.736084 1713.892578,787.631531 1714.065796,785.174500 
	C1714.415771,780.209656 1714.377319,775.217346 1714.902832,770.151245 
	C1715.576050,770.029236 1715.845337,769.992371 1716.461182,769.977295 
	C1719.554565,770.507568 1722.301636,771.015930 1725.029785,771.514343 
	C1725.010986,771.504333 1724.975952,771.480774 1725.046997,771.850708 
	C1727.327881,776.724487 1730.511108,781.033203 1731.464355,785.788879 
	C1732.355103,790.232910 1734.010986,791.020020 1737.915283,790.893372 
	C1750.922852,790.471802 1763.938721,790.310364 1777.005859,790.441162 
	C1776.261353,794.043640 1776.546997,797.126221 1776.995361,800.455139 
	C1777.789917,806.354919 1777.363037,812.424072 1777.376465,818.422485 
	C1777.387085,823.160278 1776.044067,824.040222 1771.223511,823.216980 
	C1768.678101,822.782227 1766.035400,822.917114 1763.436768,822.794800 
	C1763.427856,823.187866 1763.419067,823.580994 1763.410156,823.974121 
	C1767.604614,824.304993 1771.799072,824.635864 1776.127441,825.213379 
	C1776.577759,825.651428 1776.894409,825.842834 1777.320801,826.487183 
	C1777.588623,833.428772 1777.920288,839.920532 1777.806641,846.404480 
	C1777.768799,848.560730 1776.587524,850.696960 1775.592651,853.042480 
	C1773.934570,854.183350 1772.629761,855.917297 1771.288574,855.945984 
	C1751.530396,856.368713 1731.767578,856.564514 1711.995361,856.471069 
	C1711.075195,854.804321 1710.165283,853.480835 1709.255493,852.157288 
	C1708.961426,852.315674 1708.667358,852.474060 1708.373291,852.632446 
	C1708.579712,853.672363 1708.786011,854.712219 1708.616455,855.961914 
	C1702.338501,856.589844 1696.436279,857.008057 1690.534180,857.426270 
	C1690.410522,856.868835 1690.286865,856.311462 1690.163208,855.754028 
	C1695.019653,854.102661 1696.418701,846.857239 1701.990723,848.136597 
	C1703.936768,845.464661 1705.512817,843.300659 1707.394043,841.386841 
	C1708.051147,844.154480 1708.402954,846.671936 1708.754761,849.189392 
	C1709.475708,849.163513 1710.196777,849.137695 1710.917725,849.111816 
	C1710.917725,845.393555 1710.917725,841.675293 1711.172363,837.741943 
	C1714.288208,835.071777 1717.149170,832.616760 1720.385742,830.212524 
	C1721.506470,830.175171 1722.251831,830.087036 1723.305054,829.965393 
	C1724.076050,829.343750 1724.539062,828.755615 1725.150391,827.854858 
	C1726.827148,826.645569 1728.355347,825.748962 1730.237305,824.794312 
	C1731.728638,824.513428 1732.866455,824.290527 1734.246094,824.147583 
	C1734.815674,824.316711 1735.143188,824.406006 1735.735352,824.756592 
	C1738.519653,825.167786 1741.039429,825.317566 1743.559204,825.467407 
	C1743.613281,824.846924 1743.667236,824.226440 1743.721313,823.606018 
	C1742.237305,823.302734 1740.753296,822.999512 1739.238037,822.252991 
	C1739.196045,820.148743 1738.965210,818.453735 1739.215576,816.833130 
	C1739.966431,811.974609 1737.974854,810.567139 1733.216797,810.822327 
	C1725.750610,811.222900 1725.731079,810.940674 1725.925171,818.571289 
	C1725.946289,819.397278 1726.053589,820.220947 1725.918701,821.333801 
	C1723.691162,823.970947 1721.665771,826.320068 1719.306885,828.752136 
	C1716.662109,830.620850 1714.350830,832.406555 1711.855713,833.756958 
	C1711.507690,831.217468 1710.503174,828.563416 1711.417969,827.164917 
	C1712.315430,825.792908 1715.164795,825.697815 1717.745361,824.848877 
	C1715.563232,823.156311 1714.049805,821.982544 1712.837891,820.530701 
	C1713.470581,819.827942 1713.801880,819.403259 1714.327393,818.867676 
	C1714.759155,818.559265 1714.918335,818.311279 1715.393188,817.955688 
	C1717.136475,818.275085 1718.485474,818.651794 1719.867310,819.037598 
	C1719.867310,817.124817 1719.867310,815.224365 1719.867310,812.775574 
	C1718.237061,813.903625 1717.060059,814.718018 1716.515381,815.094910 
	C1714.437378,814.633240 1712.969971,814.307251 1711.494141,813.982178 
	C1711.485596,813.983215 1711.475952,813.982910 1711.220581,813.594971 
	C1705.979736,812.953369 1700.853271,811.886963 1696.444580,815.840515 
	C1694.480225,813.425049 1692.417236,810.848755 1689.296387,815.083435 
	C1688.695923,815.276184 1688.465698,815.382507 1687.888184,815.476685 
	C1686.660645,814.669922 1685.780518,813.875305 1683.578125,811.887085 
	C1682.998169,815.473267 1682.598145,817.947021 1682.141602,820.858276 
	C1682.057617,821.880066 1682.030029,822.464417 1681.749268,823.295898 
	C1682.105103,824.395386 1682.689575,825.964661 1683.327393,825.986572 
	C1688.363403,826.159668 1693.409668,826.030701 1698.452759,826.008789 
	C1701.578003,825.995178 1704.703369,826.006287 1707.868408,826.006287 
	C1707.868408,830.622986 1707.868408,834.262573 1707.665771,838.044312 
	C1707.115479,838.528137 1706.767700,838.869629 1706.122559,839.429565 
	C1704.380615,840.685425 1702.936035,841.722900 1701.102051,843.040039 
	C1700.743042,841.427307 1700.516968,840.411438 1700.294312,839.411377 
	C1694.925537,840.392334 1698.530029,843.461914 1698.863159,845.555908 
	C1696.921753,847.304565 1695.098633,848.763306 1693.490479,850.429504 
	C1688.806152,855.282959 1683.630737,858.126831 1676.403320,857.369629 
	C1671.031006,856.806763 1665.497437,857.783813 1659.833984,857.797974 
	C1658.977783,857.355164 1658.323486,857.189270 1657.572510,856.684326 
	C1656.993042,855.357849 1656.510132,854.370483 1656.009521,853.090332 
	C1655.947510,852.406982 1655.902954,852.016296 1656.065186,851.350586 
	C1657.101074,850.805786 1657.932861,850.302063 1658.758789,850.311523 
	C1660.990112,850.336975 1663.219116,850.580811 1665.809570,850.887695 
	C1668.077148,852.083923 1669.091187,851.742432 1668.678223,849.136902 
	C1668.504028,848.580933 1668.574341,848.272095 1669.196289,847.969360 
	C1669.891113,847.910950 1670.168091,847.876892 1670.802002,847.747559 
	C1672.041382,848.303406 1672.872192,849.049377 1673.825806,849.571045 
	C1674.419800,849.895996 1675.199341,849.882080 1676.138794,849.909058 
	C1676.583008,849.511292 1676.783936,849.224731 1677.100098,848.800842 
	C1677.215454,848.663513 1677.465454,848.406433 1677.728760,848.405640 
	C1678.338501,848.340942 1678.684814,848.277161 1679.140381,848.389343 
	C1679.439941,848.777039 1679.672729,848.919983 1680.054932,849.255859 
	C1680.381226,849.720337 1680.585449,849.944763 1680.830078,850.109680 
	C1680.888672,850.149231 1681.188232,850.001099 1681.195801,849.921875 
	C1681.375122,848.048340 1681.529419,846.172424 1681.589233,845.466370 
	C1678.824585,844.904419 1676.767334,844.422852 1674.685059,844.107056 
	C1674.157959,844.027100 1673.550659,844.476196 1672.835938,844.393433 
	C1671.670532,843.806091 1670.649292,843.507446 1669.206177,843.165405 
	C1667.444336,843.444458 1666.104614,843.766907 1664.381714,844.018677 
	C1662.053955,844.669312 1660.109131,845.390625 1658.164429,846.111938 
	C1656.158569,844.916321 1654.154663,844.917725 1652.150879,844.915405 
	C1651.586548,844.914734 1651.021851,844.884521 1650.458130,844.899963 
	C1648.712769,844.947632 1646.967773,845.010925 1644.926025,844.955322 
	C1644.062012,844.568115 1643.494873,844.293579 1642.586426,844.032104 
	C1640.559326,845.491516 1638.873535,846.937927 1636.653687,848.842590 
	C1638.268921,849.399780 1639.134399,849.698425 1640.117676,850.225464 
	C1640.517212,850.640808 1640.799194,850.827637 1641.328369,851.272827 
	C1642.730103,852.353638 1643.884521,853.176147 1644.824097,854.421082 
	C1643.770996,856.376343 1642.932861,857.909241 1641.824951,859.001099 
	C1638.037354,858.200256 1634.519409,857.840271 1630.703979,857.449890 
	C1630.590698,854.982605 1630.479248,852.555359 1630.768066,850.083252 
	C1632.063721,849.443481 1632.958984,848.848450 1635.098633,847.426392 
	C1632.184692,847.211914 1630.933105,847.119812 1629.594482,846.564270 
	C1629.467773,842.774048 1629.421387,839.447327 1629.390015,836.120483 
	C1629.305786,827.214355 1629.440674,827.065918 1638.520264,826.615906 
	C1642.614746,826.747437 1646.286255,826.630554 1649.956299,826.480957 
	C1651.010620,826.437988 1652.072144,826.157043 1653.110962,826.237244 
	C1655.264160,826.403198 1657.412964,826.978027 1659.546143,826.900879 
	C1660.589722,826.863220 1661.594360,825.742249 1662.616577,825.111633 
	C1661.497681,824.407654 1660.356445,823.064941 1659.263672,823.103455 
	C1652.229614,823.351013 1645.206299,823.900696 1637.785889,824.287720 
	C1635.735229,824.215881 1633.956543,824.577698 1632.458618,824.085388 
	C1631.191895,823.669128 1629.386597,822.324829 1629.312744,821.285461 
	C1628.975830,816.532471 1629.164795,811.742188 1629.164795,806.603882 
	C1633.676514,806.347839 1637.790649,806.055786 1641.909912,805.900330 
	C1644.892456,805.787720 1649.336548,804.609009 1650.595947,806.077698 
	C1654.401367,810.515259 1657.559814,807.865051 1661.435303,806.770752 
	C1663.597900,806.519653 1665.502319,806.287842 1667.113159,805.474609 
	C1667.468872,805.294983 1666.784912,803.056335 1666.579834,801.765869 
	C1664.890747,802.231812 1663.201782,802.697693 1661.003174,803.142944 
	C1660.153564,803.136414 1659.813843,803.150574 1659.220825,802.989685 
	C1656.132080,799.122925 1654.192871,800.096924 1652.521729,804.023804 
	C1652.032959,804.081238 1651.838379,804.111511 1651.197998,804.139160 
	C1645.448975,804.150696 1640.145874,804.169861 1634.842773,804.177185 
	C1631.036621,804.182434 1629.328735,802.251831 1628.885986,798.473999 
	C1627.756470,788.839294 1629.184814,780.433533 1638.711304,775.395081 
	C1638.841553,775.326294 1638.905029,775.131348 1639.326172,775.047058 
	C1640.614746,774.717224 1641.576782,774.335510 1642.948120,773.935547 
	C1643.883667,773.110413 1644.409912,772.303467 1645.413574,771.370972 
	C1648.148926,771.245361 1650.406982,771.245361 1653.135498,771.245361 
	C1653.135498,774.786804 1653.135498,777.759338 1653.135498,780.685059 
	C1654.303955,778.797119 1655.331055,777.137512 1656.598145,776.016479 
	C1658.480713,780.235352 1661.305542,779.722900 1664.676636,778.765991 
	C1665.333374,778.917419 1665.600464,778.958191 1666.169800,779.105347 
	C1668.619385,778.463196 1670.766602,777.714722 1673.292358,777.015259 
	C1676.581299,777.432983 1679.491577,777.801636 1682.673706,778.204712 
	C1682.673706,776.100220 1682.673706,774.385620 1682.673706,773.591125 
	C1679.612305,773.737854 1676.932739,773.866333 1674.081543,773.692505 
	C1671.286987,770.375122 1668.473877,771.269226 1665.377930,772.988403 
	C1664.653564,772.994934 1664.172241,773.000061 1663.507080,772.655762 
	C1661.809937,772.170044 1660.296509,772.033691 1658.857788,771.344238 
	C1658.956177,767.726440 1659.185181,764.642822 1658.903564,761.606506 
	C1658.788940,760.370361 1657.565796,759.237000 1656.501221,758.057495 
	C1655.465454,758.091370 1654.775269,758.124512 1653.640137,758.016357 
	C1645.234131,757.685181 1645.235107,757.685242 1644.827759,765.726807 
	C1644.811157,766.055420 1644.745728,766.381531 1644.356201,766.781128 
	C1642.196655,769.141846 1640.383911,771.430298 1638.230103,773.742188 
	C1630.284180,778.129272 1625.052612,783.608459 1626.730225,793.483398 
	C1627.309814,796.895203 1626.060669,800.617615 1625.508667,805.393494 
	C1625.758179,806.375305 1626.686035,808.679626 1626.863892,811.040527 
	C1627.136230,814.656555 1626.892090,818.311584 1626.506348,821.984497 
	C1625.081055,822.389709 1624.016602,822.760498 1622.640259,823.136536 
	C1619.903320,823.142883 1617.478394,823.143982 1614.765747,822.832153 
	C1607.431030,822.518127 1600.383789,822.517029 1593.063965,822.304199 
	C1592.569580,820.542664 1592.347900,818.992981 1592.076538,817.097290 
	C1590.270386,817.097290 1588.228760,817.097290 1585.896729,817.060791 
	C1585.411133,817.019043 1585.216064,817.013794 1584.723877,817.016479 
	C1584.229248,817.036987 1584.031738,817.049683 1583.467529,816.971008 
	C1581.082397,818.549011 1579.063721,820.218445 1577.027832,822.147949 
	C1577.005615,822.581665 1577.000732,822.755371 1576.580566,822.944824 
	C1570.817871,822.992554 1565.470825,823.024536 1559.814941,823.044189 
	C1559.300293,823.038025 1559.094482,823.044128 1558.472534,823.054199 
	C1553.938232,823.693359 1549.819946,824.328552 1545.610107,824.939941 
	C1545.518555,824.916016 1545.333984,824.958252 1545.014648,824.635193 
	C1543.557251,824.154480 1542.419189,823.858215 1541.281250,823.859680 
	C1518.857422,823.888000 1496.433594,823.950867 1473.767334,823.988953 
	C1473.524780,823.971252 1473.038452,823.972229 1472.629150,823.960693 
	C1471.127686,823.953064 1470.035278,823.957092 1468.620361,823.953125 
	C1467.867798,823.960388 1467.437988,823.975647 1466.554077,823.986450 
	C1463.093262,824.008057 1460.086304,824.034058 1456.797119,824.050049 
	C1456.326538,824.049866 1456.138550,824.059753 1455.511963,824.047607 
	C1452.715698,824.016602 1450.357788,824.007568 1447.532471,823.984131 
	C1441.359497,824.192749 1435.653931,824.415833 1429.619873,824.658081 
	C1428.852661,824.664795 1428.413940,824.652405 1427.807617,824.484497 
	C1427.113525,824.453308 1426.587036,824.577698 1425.650513,824.737854 
	C1424.142578,824.783813 1423.044434,824.794006 1421.634277,824.546387 
	C1415.552002,824.457275 1409.781860,824.625977 1403.665161,824.793457 
	C1402.857422,824.774780 1402.396240,824.757324 1401.634033,824.515259 
	C1399.220337,824.183228 1397.107544,824.075806 1394.996826,823.528259 
	C1394.990723,821.298706 1394.982788,819.509277 1395.301392,817.534302 
	C1396.791382,816.318604 1397.921509,815.247437 1399.125366,814.267090 
	C1401.393677,812.419800 1403.624390,809.189941 1406.011719,809.056519 
	C1416.301025,808.481384 1426.641357,808.820618 1437.095215,809.101318 
	C1437.559448,809.599121 1437.891846,809.822571 1438.390503,810.320679 
	C1439.517822,811.211670 1440.426636,812.001343 1441.478149,812.317383 
	C1441.728027,812.392578 1442.454590,810.881836 1442.973145,809.771729 
	C1442.985718,809.223694 1442.987183,809.004395 1443.307983,808.690308 
	C1444.416748,808.289062 1445.205933,807.982483 1446.392212,807.766357 
	C1449.886353,808.896240 1452.983521,809.935547 1455.805176,811.128174 
	C1456.354736,812.193298 1457.179810,813.105042 1458.287842,814.304810 
	C1461.777588,814.508179 1465.362549,816.846313 1468.139160,812.744080 
	C1468.343872,809.556213 1468.411011,806.745544 1468.993774,803.954224 
	C1470.538696,803.915649 1471.567871,803.857727 1472.785889,804.087280 
	C1473.930908,805.653259 1474.887207,806.931702 1475.370117,808.163086 
	C1471.199219,810.350708 1473.344849,813.548157 1473.528564,816.422363 
	C1474.375122,816.241089 1475.221680,816.059753 1476.395996,816.040222 
	C1481.385010,815.999817 1486.046021,815.797546 1491.591187,815.557007 
	C1492.447754,815.660889 1494.473511,816.421265 1496.217163,816.003601 
	C1499.226562,815.282715 1502.060547,813.829712 1505.042236,812.945923 
	C1505.361450,813.464233 1505.606689,813.720520 1505.928589,814.238098 
	C1506.628418,814.674988 1507.251587,814.850708 1508.131836,814.904236 
	C1508.600586,814.474548 1508.812622,814.167114 1509.350220,813.822693 
	C1511.054199,814.149109 1512.432617,814.512573 1513.936768,814.909180 
	C1513.936768,812.705566 1513.936768,810.553528 1513.936768,807.651123 
	C1512.292358,809.148193 1511.263672,810.084595 1511.270752,810.078125 
	C1506.661743,809.335327 1503.128174,808.765808 1499.062256,808.147339 
	C1497.817749,808.132080 1497.105591,808.165771 1496.126099,808.360840 
	C1493.056396,810.887634 1489.618042,808.417664 1487.330444,809.162292 
	C1482.867920,810.614929 1479.740967,806.850037 1475.775757,808.251831 
	C1475.680420,807.721191 1475.585205,807.190613 1475.489990,806.660034 
	C1481.546753,805.221680 1487.603516,803.783386 1493.765869,802.731506 
	C1494.401001,803.920837 1494.930420,804.723694 1496.186768,806.629211 
	C1496.186768,802.528870 1496.186768,799.871033 1496.186768,795.922424 
	C1494.296143,798.154297 1493.398315,799.214111 1492.123779,800.370544 
	C1490.936401,800.409851 1489.660278,800.659790 1489.387939,800.247253 
	C1487.056763,796.714722 1483.827759,797.304382 1480.282471,798.052612 
	C1479.676514,798.256104 1479.300415,798.462219 1478.622559,798.434387 
	C1476.375854,799.186951 1474.430908,800.173340 1471.959473,801.115784 
	C1470.388184,801.131958 1469.343140,801.192078 1467.979126,801.085571 
	C1463.896362,800.918945 1460.132690,800.918945 1456.279907,800.918945 
	C1455.902588,802.703064 1455.609741,804.088074 1455.481079,804.696472 
	C1451.945923,805.208862 1448.993652,805.636719 1445.635742,806.081177 
	C1444.153442,806.065125 1443.076782,806.032471 1441.866455,805.762329 
	C1441.425049,805.338806 1441.117310,805.152649 1440.717529,804.637756 
	C1440.374146,803.622070 1440.122803,802.935181 1440.377441,803.630920 
	C1437.552490,804.620422 1435.286865,806.029053 1432.972412,806.114197 
	C1424.715088,806.418091 1416.440918,806.322144 1408.173706,806.272461 
	C1404.789062,806.252136 1401.586914,806.288391 1399.318237,809.479675 
	C1399.009888,809.913269 1397.397461,809.419861 1396.232056,809.054260 
	C1394.826172,805.034058 1392.508789,803.356323 1388.352661,803.812683 
	C1384.648071,804.219543 1380.861816,803.884277 1376.916748,803.581543 
	C1375.081177,803.295837 1373.440308,803.305176 1371.564331,802.899292 
	C1369.018066,802.487854 1366.706299,802.477051 1364.399048,802.572205 
	C1364.335815,802.574707 1364.319458,803.716614 1364.271362,804.484680 
	C1365.825806,804.869141 1367.163696,805.200012 1368.438110,805.515198 
	C1367.969360,807.033142 1367.595825,808.242554 1366.989624,810.205200 
	C1369.513428,809.509033 1371.066162,809.080750 1372.912842,808.734985 
	C1374.471436,807.879395 1375.736450,806.941162 1377.468018,805.979858 
	C1381.416626,805.998962 1384.912354,806.251648 1388.376831,806.025635 
	C1392.057373,805.785583 1394.103149,807.045044 1393.963867,811.019287 
	C1394.009766,811.777466 1394.304443,812.391541 1394.645020,813.332886 
	C1393.857788,814.923828 1393.024414,816.187439 1391.923828,817.695679 
	C1391.369263,820.251282 1391.081787,822.562317 1390.426514,824.736816 
	C1385.290405,824.713989 1380.522217,824.911926 1375.753540,824.925110 
	C1363.252075,824.959656 1350.750488,824.890198 1338.248901,824.904480 
	C1315.819214,824.930115 1293.389404,824.986816 1270.886475,824.624146 
	C1269.808716,819.866882 1270.522705,815.069763 1267.841919,810.058899 
	C1266.628906,812.070007 1266.052002,813.026367 1265.402344,814.103516 
	C1263.761841,813.179626 1262.490845,812.463806 1261.087769,811.434082 
	C1258.326538,808.142029 1255.697510,805.163940 1252.625732,802.054749 
	C1241.503540,801.699524 1240.706421,802.543091 1242.232544,812.455078 
	C1242.307983,812.945557 1242.252441,813.510071 1242.483521,813.909485 
	C1242.790161,814.439392 1243.357300,815.265869 1243.746826,815.231018 
	C1247.488647,814.896240 1251.216919,814.406860 1255.048584,813.574341 
	C1255.922852,812.948730 1256.735962,812.434082 1257.463867,812.534851 
	C1258.645996,812.698547 1259.772339,813.265198 1261.176758,813.866455 
	C1262.550049,815.962830 1263.668701,817.854309 1264.950317,820.021362 
	C1268.250610,818.712524 1269.020874,819.234436 1268.775879,823.887207 
	C1268.723877,824.875000 1268.449829,825.851135 1268.091553,826.839233 
	C1267.654663,826.876404 1267.407349,826.922791 1266.870972,826.657532 
	C1254.371582,826.210632 1242.163940,826.091309 1229.734375,825.956055 
	C1229.512451,825.940186 1229.067383,825.950989 1228.714844,825.933960 
	C1227.892334,825.932739 1227.422485,825.948547 1226.565552,825.954590 
	C1224.793213,826.307678 1223.407837,826.670593 1222.000977,827.499756 
	C1221.982910,844.310608 1221.986206,860.655334 1221.576416,877.048340 
	C1220.890991,877.148987 1220.618774,877.201233 1220.015625,877.286865 
	C1217.536011,878.212036 1215.443359,879.642456 1213.225220,879.874695 
	C1209.516968,880.263000 1205.728760,879.885925 1201.848267,879.636230 
	C1201.502930,879.207581 1201.236206,879.057495 1200.858887,878.634888 
	C1200.501709,877.542175 1200.208130,876.805603 1199.522095,875.084839 
	C1199.147461,877.138123 1198.977661,878.068787 1198.434448,879.067383 
	C1197.821655,879.216919 1197.582153,879.298706 1196.980469,879.380188 
	C1194.637085,878.782837 1192.585205,878.348083 1190.701294,877.527832 
	C1189.563110,877.032288 1187.765503,875.842834 1187.839600,875.128418 
	C1188.331665,870.381042 1185.439697,870.021179 1181.994141,869.746155 
	C1181.590576,868.543762 1181.189087,867.713440 1180.917969,866.655884 
	C1181.358765,866.270569 1181.669312,866.112610 1182.372314,865.939148 
	C1188.344360,865.865723 1193.927612,865.883850 1199.498047,865.627563 
	C1200.029907,865.603149 1200.482910,863.865967 1201.351807,862.888062 
	C1202.447998,862.637329 1203.164795,862.422424 1204.233887,862.342773 
	C1205.052002,862.663147 1205.517822,862.848267 1205.953369,863.382202 
	C1206.252075,864.223633 1206.609375,865.156555 1206.905396,865.137512 
	C1208.904907,865.008850 1210.891602,864.678772 1213.177979,864.374512 
	C1213.177979,863.185791 1213.177979,861.171326 1213.177979,859.885620 
	C1210.872070,859.885620 1209.014526,859.885620 1206.947266,859.607483 
	C1205.824829,859.220947 1204.912354,859.112549 1203.592896,858.890442 
	C1201.784668,858.849121 1200.383423,858.921448 1198.629395,858.996826 
	C1197.806519,859.016663 1197.336426,859.033386 1196.510132,859.005432 
	C1194.804077,859.320801 1193.453979,859.680908 1191.751953,860.026855 
	C1190.930054,860.008545 1190.460449,860.004272 1189.855469,859.695190 
	C1186.657471,858.901978 1183.594727,858.413635 1180.531982,857.925293 
	C1180.484619,857.176941 1180.437256,856.428589 1180.389893,855.680237 
	C1183.076782,855.059692 1185.763550,854.439148 1188.839844,853.868774 
	C1190.731689,854.412476 1192.233765,854.905945 1195.061890,855.835022 
	C1194.654541,852.263550 1194.296143,849.121094 1193.978271,845.644775 
	C1194.032471,844.863281 1194.046021,844.415649 1194.234375,843.778748 
	C1193.587158,843.060852 1192.764771,842.532349 1191.926758,841.625183 
	C1191.542236,840.241211 1191.173462,839.235779 1190.804688,838.230408 
	C1188.764526,841.070923 1186.963867,837.681641 1184.758545,838.006348 
	C1184.321899,838.006714 1184.147339,838.008667 1183.608643,838.033142 
	C1181.855713,838.705933 1180.466797,839.356140 1178.770630,840.013794 
	C1178.259277,840.036072 1178.054932,840.050964 1177.618408,839.866638 
	C1176.497681,839.438782 1175.609009,839.210083 1174.559082,838.982727 
	C1174.397827,838.984070 1174.075317,838.973938 1173.721680,838.824341 
	C1170.504639,838.674683 1167.641235,838.674683 1164.096069,838.674683 
	C1163.530762,840.648071 1162.846436,843.036926 1162.138672,845.507874 
	C1166.221802,845.705444 1169.614258,845.869568 1173.005127,846.373535 
	C1173.011353,847.166809 1173.019409,847.620239 1172.821289,848.349609 
	C1171.872070,850.455872 1171.129028,852.286072 1170.386108,854.116333 
	C1172.265015,854.579834 1174.180664,854.933289 1176.010132,855.544800 
	C1177.276001,855.967896 1179.431152,856.757568 1179.424927,857.365173 
	C1179.408936,858.903625 1178.398682,860.431763 1177.844238,861.631104 
	C1176.068604,860.589294 1174.235229,859.880249 1172.401855,859.171265 
	C1172.271240,860.023010 1172.140503,860.874756 1172.017090,862.198730 
	C1172.078125,863.298157 1172.131714,863.925293 1171.905029,864.758545 
	C1171.478882,868.997803 1174.662231,866.117676 1175.999634,867.480347 
	C1176.012085,868.712891 1176.024292,869.536377 1175.733276,870.625427 
	C1175.244629,874.333557 1174.926758,877.777893 1174.973145,881.217407 
	C1174.983765,882.007690 1176.312378,883.501038 1176.921387,883.444946 
	C1180.763794,883.090759 1184.574341,882.391113 1188.859619,881.861938 
	C1189.944702,881.922302 1190.565674,881.926208 1191.569824,881.889465 
	C1192.973999,881.813721 1193.995239,881.778564 1195.016357,882.175171 
	C1195.052856,883.180847 1195.089233,883.754761 1195.293945,884.590332 
	C1197.057861,884.559753 1198.653442,884.267578 1200.414062,883.877686 
	C1200.781494,883.614929 1200.936279,883.413391 1201.008301,883.113892 
	C1200.973022,883.052429 1200.961426,882.911133 1201.407959,882.940552 
	C1205.969849,882.929810 1210.085327,882.889526 1214.185303,883.251770 
	C1213.269897,886.462463 1212.654297,889.412964 1211.350708,892.019348 
	C1210.693848,893.332764 1208.817871,894.806702 1207.443359,894.857361 
	C1201.527954,895.075195 1195.593994,894.792358 1189.504883,894.364258 
	C1187.272705,893.594543 1185.202393,893.152405 1182.661743,892.609802 
	C1179.375854,892.876953 1176.201050,893.135132 1172.979492,893.054810 
	C1172.715210,892.328125 1172.523438,891.919250 1172.250610,891.574524 
	C1172.211060,891.524536 1171.782959,891.782043 1171.534668,891.897217 
	C1172.006104,892.398376 1172.477539,892.899475 1172.905396,893.798584 
	C1172.582397,895.447876 1172.302979,896.699158 1171.920898,898.245361 
	C1172.902588,899.017700 1173.986816,899.495178 1175.474487,900.003052 
	C1179.512329,899.873535 1182.768799,896.727783 1186.745728,898.678589 
	C1187.058838,898.832153 1188.119873,897.461426 1189.311279,896.864929 
	C1193.571289,896.947510 1197.520020,896.265320 1201.101929,897.120178 
	C1209.763794,899.187195 1214.241333,895.748962 1215.860718,887.483521 
	C1215.892334,887.322083 1215.922485,887.160400 1216.308105,886.946289 
	C1217.098511,885.901184 1217.534180,884.908630 1218.044067,883.645081 
	C1218.366333,883.094055 1218.614380,882.813904 1219.084229,882.547791 
	C1219.606201,882.441467 1219.794922,882.224365 1220.208496,881.909546 
	C1220.768433,881.894653 1220.992188,881.880676 1221.216064,881.866638 
	C1221.480103,886.636902 1221.813965,891.404724 1221.984253,896.178345 
	C1222.105957,899.587952 1222.007812,903.005371 1222.007812,906.757507 
	C1218.782104,906.972290 1215.190552,907.211426 1210.810791,907.711670 
	C1208.039185,907.973572 1206.051514,907.888428 1204.073608,907.991760 
	C1198.715088,908.271667 1193.360718,908.631531 1188.004761,908.959717 
M1200.238037,817.320129 
	C1201.222290,817.129028 1202.206421,816.937866 1203.971924,816.814575 
	C1208.735596,817.041565 1213.541992,817.743530 1218.247070,817.319885 
	C1221.135132,817.059753 1223.868042,815.076233 1227.204712,813.954346 
	C1227.384155,813.971558 1227.563721,813.988831 1227.947754,814.695496 
	C1228.090698,815.375916 1228.233765,816.056335 1228.317261,816.453369 
	C1230.080444,815.923340 1231.543945,815.483459 1233.235962,815.476501 
	C1233.447021,815.742126 1233.716309,815.919189 1234.537842,816.502563 
	C1235.207886,814.617188 1235.877930,812.731873 1236.678833,810.478394 
	C1233.749878,810.891846 1231.953125,811.442383 1230.200195,811.326843 
	C1227.184204,811.128174 1224.195557,810.514404 1220.345947,810.039429 
	C1219.779541,810.069824 1219.213135,810.100220 1217.924316,810.061829 
	C1213.047485,810.511841 1208.170776,810.961853 1202.770020,810.713562 
	C1201.672119,810.533447 1200.574097,810.353271 1198.882080,810.334717 
	C1197.227417,811.879395 1195.572754,813.424011 1193.295288,815.549988 
	C1196.218628,816.070740 1198.040527,816.395325 1200.238037,817.320129 
M1202.275146,843.967041 
	C1201.671631,844.240234 1201.067993,844.513489 1200.464478,844.786682 
	C1200.532837,845.019409 1200.601074,845.252136 1200.669434,845.484863 
	C1203.986084,845.484863 1207.302734,845.484863 1210.619385,845.484863 
	C1210.634521,845.129517 1210.649658,844.774231 1210.664795,844.418884 
	C1209.436279,844.253662 1208.207886,844.088440 1206.696655,843.497742 
	C1206.231323,843.328125 1205.766113,843.158447 1205.169434,842.234070 
	C1205.022583,840.800110 1204.875732,839.366150 1204.728760,837.932190 
	C1204.449097,837.952148 1204.169434,837.972168 1203.889771,837.992126 
	C1203.586670,839.999207 1203.283691,842.006287 1203.000000,844.002319 
	C1203.000000,844.002319 1203.010010,843.983521 1202.275146,843.967041 
M1660.777344,839.842285 
	C1660.864380,837.328369 1660.951294,834.814453 1661.038330,832.300537 
	C1660.727295,832.300537 1660.416260,832.300537 1660.002197,832.300537 
	C1659.803589,833.385437 1659.592285,834.539734 1659.374023,835.732117 
	C1656.783447,835.165894 1654.544922,834.676575 1652.002319,833.603088 
	C1649.688354,833.409485 1647.374512,833.215881 1644.408081,833.006409 
	C1643.973145,833.003235 1643.538208,833.000000 1642.566406,832.667847 
	C1640.911499,833.506775 1638.457153,833.969116 1637.833130,835.293762 
	C1637.244385,836.543945 1638.630859,840.235474 1639.062866,840.221436 
	C1643.258423,840.084961 1647.435913,839.394531 1652.501709,838.940918 
	C1654.994873,839.289001 1657.488037,839.637146 1659.707275,840.105408 
	C1659.810059,840.076843 1659.912720,840.048279 1660.777344,839.842285 
M1704.492554,802.787598 
	C1703.982910,802.528015 1703.473145,802.268433 1702.202271,801.877808 
	C1701.290405,802.177124 1699.894165,802.212341 1699.559570,802.826294 
	C1698.642090,804.509705 1698.170776,806.436340 1697.522827,808.266785 
	C1699.282471,808.518799 1701.044800,808.980103 1702.800903,808.957275 
	C1704.228271,808.938721 1705.648071,808.339783 1707.903076,807.892761 
	C1708.176147,807.833069 1708.449341,807.773376 1709.370850,807.661499 
	C1710.352783,808.004395 1711.334595,808.347290 1712.278320,808.676819 
	C1712.004761,806.339172 1711.773682,804.364502 1711.454834,801.639282 
	C1709.266602,802.103455 1707.145386,802.553406 1704.492554,802.787598 
M1664.365845,790.907349 
	C1666.067139,790.887085 1667.768311,790.866821 1670.119751,790.671143 
	C1674.449707,790.131897 1678.806885,789.744812 1683.097046,788.982300 
	C1684.949829,788.652954 1686.664673,787.547241 1689.075928,786.920105 
	C1689.289551,786.948425 1689.503174,786.976685 1689.828613,787.628418 
	C1690.320435,788.066772 1690.812134,788.505066 1691.722656,788.547913 
	C1691.862427,788.189575 1692.002197,787.831238 1692.886230,787.645020 
	C1694.375244,788.003906 1695.864380,788.362732 1697.481079,788.752258 
	C1697.481079,786.343384 1697.481079,784.372986 1697.481079,781.815247 
	C1695.725464,783.009521 1694.550049,783.809082 1693.412231,784.583008 
	C1687.715210,780.039062 1681.702271,782.632446 1674.869263,783.155762 
	C1674.547852,783.103149 1674.226562,783.050598 1673.423950,782.485046 
	C1672.019775,782.351074 1670.615601,782.217102 1668.281006,782.032227 
	C1666.980469,782.688965 1665.680054,783.345642 1663.959229,783.412537 
	C1662.113037,783.233032 1660.266724,783.053528 1658.420532,782.874084 
	C1658.374878,783.369934 1658.329346,783.865784 1658.283691,784.361694 
	C1659.089478,785.033264 1659.895386,785.704773 1660.767090,786.431335 
	C1659.637329,787.664246 1658.670898,788.718994 1657.257935,790.261047 
	C1659.836060,790.479675 1661.591919,790.628601 1664.365845,790.907349 
M1628.670898,760.202759 
	C1633.544678,762.494385 1641.830811,757.691284 1642.572876,751.582642 
	C1641.660645,753.211243 1641.281372,754.706970 1640.322266,755.398499 
	C1639.048584,756.316895 1636.252441,757.430542 1635.954346,757.042664 
	C1632.550781,752.615173 1629.778320,755.633850 1626.470459,756.818726 
	C1624.808105,753.811279 1622.933350,752.862671 1619.755005,756.082947 
	C1619.466309,756.055115 1619.177490,756.027344 1618.628296,755.540161 
	C1618.529419,755.409546 1618.452881,755.247742 1618.325439,755.157288 
	C1618.200928,755.068726 1618.024536,755.052734 1617.871094,755.004883 
	C1617.609497,756.967041 1617.347900,758.929138 1617.112671,760.693787 
	C1620.959351,762.476257 1624.144287,762.963562 1627.518311,759.998169 
	C1627.697876,759.991394 1627.877563,759.984558 1628.670898,760.202759 
M1217.712402,799.061401 
	C1214.245972,799.080933 1211.416504,800.455994 1212.100464,804.237244 
	C1212.873901,808.512512 1216.164795,806.675781 1218.895630,806.172119 
	C1221.634766,805.667053 1224.464844,805.653564 1227.452515,805.408386 
	C1227.344482,803.791199 1227.201050,801.646057 1227.033569,799.139832 
	C1224.618042,799.139832 1222.503296,799.139832 1219.408936,799.114624 
	C1219.083252,799.138977 1218.757568,799.163391 1217.712402,799.061401 
M1600.426025,782.914673 
	C1603.105957,780.274048 1603.779541,775.223083 1601.576050,772.904724 
	C1601.070801,772.373352 1599.657593,772.131287 1598.982666,772.445129 
	C1597.123047,773.309998 1597.724731,781.507690 1600.426025,782.914673 
M1598.037354,790.240784 
	C1599.164795,791.043945 1600.292358,791.847168 1601.419678,792.650391 
	C1601.935059,791.046814 1602.790894,789.450134 1602.823242,787.836914 
	C1602.839478,787.021423 1601.365723,786.176147 1600.565674,785.344299 
	C1599.712036,786.710876 1598.858398,788.077454 1598.037354,790.240784 
M1198.975586,841.472900 
	C1199.192505,840.213623 1199.409302,838.954346 1199.675049,837.410767 
	C1198.105957,837.410767 1196.763916,837.410767 1195.277954,837.410767 
	C1195.737549,840.408997 1196.135010,843.002563 1196.532593,845.596069 
	C1196.861084,845.616211 1197.189575,845.636353 1197.518066,845.656494 
	C1197.992310,844.522339 1198.466675,843.388123 1198.975586,841.472900 
M1652.534790,785.606750 
	C1652.534790,787.615234 1652.534790,789.623718 1652.534790,791.632202 
	C1653.010498,791.863647 1653.486328,792.095154 1653.962036,792.326660 
	C1654.639893,791.183777 1655.712769,790.102783 1655.903809,788.883667 
	C1656.197144,787.012024 1656.394409,784.654541 1652.534790,785.606750 
M1329.410156,816.482605 
	C1329.410156,816.482605 1329.523193,816.414612 1329.410156,816.482605 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1123.029419,583.918701 
	C1127.465576,584.480408 1131.901611,585.042114 1136.669189,586.253662 
	C1137.008423,595.361389 1136.943848,603.820251 1137.060059,612.276672 
	C1137.104614,615.521423 1137.561401,618.760498 1137.859253,622.280396 
	C1137.901001,622.744507 1137.913818,622.930542 1137.913818,623.405029 
	C1137.891846,624.076660 1137.882812,624.459778 1137.855225,625.235352 
	C1138.805664,627.453125 1139.728638,629.304810 1140.755127,631.097229 
	C1141.986450,633.247375 1143.306152,635.347046 1144.046875,636.573425 
	C1146.508423,637.882324 1149.008545,638.482361 1150.061279,640.052673 
	C1150.666504,640.955383 1149.175781,643.590393 1148.171143,645.151184 
	C1147.206299,646.650208 1145.610596,647.731079 1144.362793,649.062134 
	C1138.827148,654.967346 1133.317017,660.896545 1127.631104,666.956177 
	C1127.509766,667.328979 1127.555176,667.562683 1127.600586,667.796387 
	C1127.827026,667.627991 1128.053345,667.459595 1128.609863,667.210083 
	C1134.601074,661.557800 1140.287354,656.011902 1145.917236,650.409180 
	C1153.429565,642.933289 1153.352051,642.975098 1160.858154,650.219299 
	C1165.088989,654.302368 1169.550781,658.146301 1173.978760,662.335815 
	C1174.192627,662.893005 1174.424805,663.123169 1174.879517,663.458130 
	C1175.216431,663.898926 1175.457397,664.100098 1175.914062,664.541626 
	C1177.720581,665.872681 1179.349487,666.917725 1181.241455,667.965698 
	C1181.679810,667.958435 1181.854980,667.948303 1182.026855,667.958374 
	C1182.023438,667.978638 1182.063232,667.968506 1182.031128,668.254272 
	C1181.923096,668.911133 1181.847046,669.282349 1181.454712,669.783447 
	C1180.479492,670.807800 1179.820557,671.702332 1179.161621,672.596924 
	C1179.150879,672.088928 1179.140137,671.580994 1179.129395,671.072998 
	C1172.723267,671.072998 1166.317261,671.072998 1159.599365,670.823364 
	C1158.867676,668.932007 1158.598755,665.936035 1158.003906,665.869934 
	C1154.317627,665.460083 1150.461914,665.276001 1146.896362,666.068604 
	C1146.091553,666.247498 1146.211914,670.588562 1145.793457,673.357605 
	C1144.804932,675.589355 1143.929565,677.477173 1142.910156,679.003235 
	C1141.852539,676.449646 1141.483398,673.599365 1139.864258,672.260803 
	C1138.398193,671.048584 1134.682007,670.470276 1133.561646,671.448792 
	C1132.119019,672.708740 1132.275024,675.799011 1131.924683,677.313171 
	C1136.017944,678.395264 1139.108643,679.212341 1142.064331,680.411255 
	C1141.382690,682.078857 1140.836060,683.364624 1139.699951,686.037354 
	C1141.910767,684.896729 1142.912598,684.379822 1144.304199,683.828613 
	C1146.896851,684.850830 1149.015747,686.197510 1151.330688,686.867432 
	C1153.025391,687.357788 1155.145996,687.428589 1156.773926,686.843506 
	C1159.046753,686.026672 1161.037231,684.424133 1163.148560,683.157837 
	C1162.214111,681.893677 1161.279663,680.629517 1160.222168,679.048340 
	C1160.059814,677.814880 1160.020508,676.898499 1159.989014,675.989014 
	C1159.996826,675.995911 1159.980103,675.981812 1160.342896,676.007935 
	C1162.292480,675.280884 1163.879272,674.527710 1165.535278,673.741638 
	C1165.920898,674.271362 1166.439575,674.983826 1167.002930,675.757812 
	C1167.792725,674.776917 1168.409546,674.010803 1169.026367,673.244690 
	C1169.769531,675.793091 1169.277222,677.934265 1169.093140,680.101624 
	C1168.915161,682.197205 1169.054443,684.319763 1169.054443,686.430481 
	C1169.547241,686.438904 1170.040039,686.447388 1170.532837,686.455811 
	C1170.971313,683.533142 1171.409668,680.610413 1171.645752,679.036682 
	C1177.614380,678.003967 1182.684937,677.126587 1187.884277,676.454102 
	C1188.207153,676.933655 1188.463379,677.125427 1188.914062,677.428162 
	C1189.244995,677.872131 1189.486572,678.069519 1189.844971,678.532043 
	C1190.868896,679.647888 1191.818848,680.446167 1192.898438,681.441162 
	C1193.229004,681.887634 1193.472656,682.084656 1193.826660,682.566040 
	C1195.223633,684.549011 1196.552612,686.194946 1197.978149,687.960510 
	C1191.124146,696.481567 1191.158447,705.219421 1197.068604,714.452271 
	C1199.423950,717.007812 1201.314331,716.253967 1203.221802,714.316589 
	C1205.161377,712.346436 1207.216675,710.490295 1209.174805,708.628540 
	C1213.656006,712.872559 1217.645752,716.835754 1221.848145,720.558960 
	C1224.220581,722.660828 1224.179077,724.673584 1222.481934,726.992798 
	C1222.439209,726.960388 1222.334473,726.936646 1221.989014,726.943970 
	C1221.413330,726.962158 1221.183105,726.973022 1220.535278,726.997009 
	C1215.737305,727.343994 1211.356812,727.677734 1206.626587,727.974915 
	C1203.190674,726.667908 1199.704102,725.720032 1200.879883,721.547607 
	C1186.680542,721.547607 1172.592163,721.547607 1158.503662,721.547607 
	C1158.503784,721.966553 1158.503906,722.385437 1158.504150,722.804382 
	C1169.726807,722.804382 1180.949585,722.804382 1193.292969,722.804382 
	C1187.195190,729.378418 1181.834839,734.941162 1176.784424,740.772156 
	C1175.684204,742.042480 1175.691895,744.272400 1175.025391,746.376099 
	C1174.223389,748.042908 1173.662720,749.437317 1172.934570,750.737976 
	C1168.371216,758.888184 1168.864868,763.255005 1175.106201,770.282227 
	C1175.290527,773.395508 1175.525146,776.070618 1175.750977,779.171631 
	C1175.843384,780.728027 1175.944458,781.858398 1175.797852,783.153564 
	C1175.602173,784.172974 1175.654419,785.027588 1175.732666,786.353455 
	C1175.794312,788.649048 1175.639160,790.501404 1175.901733,792.292480 
	C1176.690918,797.676147 1175.547974,800.763977 1169.287964,802.349915 
	C1159.765869,804.762390 1154.846680,813.555847 1156.668213,822.812256 
	C1161.823975,822.812256 1167.090576,822.811401 1172.357178,822.812683 
	C1174.446289,822.813171 1176.608154,822.736206 1175.417969,825.943726 
	C1166.468384,825.977844 1157.973022,826.199158 1149.493164,825.932068 
	C1144.698364,825.781006 1140.580811,825.861938 1139.828369,831.708374 
	C1135.437134,828.349487 1133.181885,829.356689 1129.829468,837.051636 
	C1134.094238,833.796265 1137.753418,831.087524 1140.926025,836.579224 
	C1140.665161,837.545044 1140.394043,838.595947 1140.143188,838.591125 
	C1132.749268,838.449890 1125.357300,838.203735 1117.965088,837.974304 
	C1119.257935,837.449463 1120.550659,836.924622 1121.843506,836.399780 
	C1122.134277,835.887085 1122.425049,835.374390 1122.715942,834.861694 
	C1121.343262,834.000305 1119.970703,833.138977 1118.243652,831.993103 
	C1117.790283,831.656738 1117.691528,831.604797 1117.592773,831.552856 
	C1117.739990,831.692505 1117.887329,831.832153 1118.034668,831.971741 
	C1117.171143,832.312012 1115.812256,833.173096 1115.528931,832.903625 
	C1114.216064,831.654358 1113.201660,830.091309 1112.059326,828.613281 
	C1110.025879,829.775146 1108.796143,830.477844 1107.681030,831.114990 
	C1107.170654,830.884460 1106.630005,830.774597 1106.614258,830.613464 
	C1106.256958,826.957825 1103.827026,826.575256 1100.917969,826.966370 
	C1096.435669,827.569153 1091.957275,828.198853 1087.610229,828.798767 
	C1090.008911,830.105347 1093.678223,830.892639 1094.752808,833.001831 
	C1096.036743,835.522217 1095.083252,839.173706 1095.129150,842.334778 
	C1095.418579,862.288574 1095.718140,882.242126 1096.007446,902.195862 
	C1096.033447,903.988037 1096.010864,905.780823 1096.010864,907.470947 
	C1093.917358,907.983398 1092.332031,908.371460 1090.399414,908.962158 
	C1083.412720,909.142944 1076.597046,908.163208 1070.177979,909.342163 
	C1062.208496,910.805847 1054.410278,909.482727 1046.548828,909.841187 
	C1038.099365,910.226501 1029.620117,909.953308 1021.153870,909.979309 
	C1012.852539,910.004761 1004.548279,910.197205 996.250793,910.034668 
	C988.617371,909.885193 980.991211,909.360962 972.883423,908.723145 
	C972.421753,908.095642 972.438538,907.746948 972.875061,907.258301 
	C983.255493,906.939026 993.226501,906.956787 1003.169983,906.442993 
	C1005.688904,906.312805 1008.653381,905.060364 1010.496582,903.341003 
	C1017.286438,897.007202 1023.681580,890.250916 1030.243408,883.671631 
	C1048.530151,865.336121 1066.834595,847.018250 1085.080811,828.642395 
	C1085.680420,828.038513 1085.723633,826.882324 1086.014160,825.993469 
	C1086.002197,826.003357 1085.984619,826.028748 1086.328369,826.012024 
	C1092.806885,820.161865 1098.851562,814.228027 1105.142212,808.567749 
	C1106.655151,807.206543 1108.911743,806.327515 1110.955078,805.976562 
	C1114.466675,805.373474 1118.079468,805.360168 1121.649780,805.099243 
	C1121.638184,804.710266 1121.626465,804.321289 1121.614746,803.932251 
	C1118.383057,803.619873 1115.151367,803.307495 1110.743896,802.881470 
	C1116.223022,796.757629 1120.730225,791.720215 1125.577637,786.429810 
	C1126.227051,780.531799 1126.798218,774.884094 1126.740356,769.242859 
	C1126.708862,766.174133 1127.361206,764.150269 1130.663330,763.137939 
	C1131.309448,762.610657 1131.679321,762.182861 1132.384766,761.529297 
	C1134.613525,758.244690 1136.498535,758.279236 1138.695068,761.103943 
	C1139.509644,762.151428 1140.864502,762.778809 1142.085449,763.887451 
	C1143.058472,764.662781 1143.867554,765.380859 1144.790527,765.585815 
	C1146.711060,766.012329 1149.998047,766.979797 1150.424316,766.294617 
	C1152.380249,763.150574 1155.546509,758.577271 1154.478882,756.291809 
	C1153.170410,753.490540 1148.179810,752.165649 1144.534912,750.926025 
	C1143.885620,750.705200 1142.038696,754.005554 1140.306641,755.761719 
	C1137.831665,755.970093 1135.794922,756.096863 1132.973267,756.272461 
	C1134.056030,754.650818 1134.704590,753.679443 1135.353149,752.708069 
	C1133.721069,752.728638 1132.697388,753.389038 1131.981445,754.293274 
	C1131.016846,755.511658 1130.289185,756.917725 1129.132812,757.872009 
	C1128.504517,757.366882 1128.203125,757.232971 1127.568604,756.906006 
	C1126.252563,749.070740 1124.631348,741.383423 1129.253174,734.159790 
	C1131.811768,736.104614 1133.819458,738.596924 1137.367065,735.811768 
	C1141.932007,735.348938 1146.106812,734.874207 1150.208862,734.407715 
	C1148.976074,733.024536 1148.152710,731.319641 1146.867065,730.830688 
	C1144.607788,729.971436 1141.988647,729.323364 1139.674927,729.657654 
	C1138.543213,729.821106 1137.759399,732.392090 1136.446289,733.924622 
	C1135.135986,733.925354 1134.197876,733.875000 1132.582275,733.788269 
	C1134.443848,732.133240 1135.629761,731.078796 1136.815796,730.024353 
	C1136.447998,729.571594 1136.080322,729.118896 1135.712646,728.666138 
	C1133.439819,730.509888 1131.166992,732.353577 1128.593140,733.887085 
	C1124.819702,730.150330 1125.103516,723.981995 1127.802246,722.886780 
	C1124.921997,717.872314 1126.682129,712.405212 1126.911377,707.439331 
	C1128.559937,707.768066 1129.779175,707.893860 1131.000732,708.008606 
	C1131.002930,707.997559 1130.980347,708.000244 1130.927002,708.313477 
	C1131.242554,709.086243 1131.611328,709.545776 1131.988037,710.004517 
	C1131.996094,710.003601 1131.993286,710.020203 1132.098877,710.339478 
	C1135.467407,713.112427 1138.730469,715.566040 1141.996704,718.009644 
	C1142.000000,717.999695 1141.980225,718.006653 1142.003662,718.364563 
	C1146.276733,724.659119 1149.375854,724.750061 1152.007324,719.007812 
	C1152.000000,719.000000 1152.015991,719.015320 1152.378418,719.064575 
	C1154.167114,716.914551 1155.593506,714.715271 1157.036133,712.315796 
	C1157.204102,711.750610 1157.355713,711.385620 1157.763428,711.216919 
	C1158.923218,710.636292 1160.216064,710.026184 1160.637451,709.042786 
	C1161.240601,707.634888 1161.207153,705.954285 1161.445557,704.390137 
	C1159.854614,704.535522 1158.242065,704.571472 1156.682007,704.875793 
	C1155.975952,705.013489 1155.373657,705.684326 1155.151489,705.830994 
	C1153.914673,704.853943 1153.021729,704.148621 1151.966919,703.115845 
	C1150.681641,701.322998 1146.651978,700.313721 1151.372314,698.065308 
	C1155.208618,698.260010 1158.671021,695.827759 1162.295532,698.039917 
	C1162.782471,698.337097 1164.021606,697.213135 1164.969238,697.003235 
	C1165.735229,696.833557 1166.602051,697.172913 1167.397095,697.072571 
	C1170.476318,696.683777 1174.473877,697.256409 1176.412231,695.536133 
	C1179.829590,692.502991 1180.961914,694.409180 1181.821411,695.832397 
	C1184.889038,695.832397 1187.129272,695.832397 1189.344727,695.832397 
	C1189.344727,694.213623 1189.344727,692.364929 1189.344727,691.610657 
	C1184.324219,691.002502 1179.826416,690.457703 1175.328613,689.912903 
	C1175.534302,690.337830 1175.739990,690.762756 1175.945679,691.187622 
	C1173.989502,692.933777 1172.033447,694.679932 1170.077271,696.426086 
	C1169.653564,695.962158 1169.229736,695.498230 1168.805908,695.034302 
	C1170.072632,693.661804 1171.339233,692.289307 1173.306763,690.157471 
	C1167.833984,690.915527 1163.486084,691.535339 1159.130371,692.093140 
	C1158.709961,692.146912 1158.237061,691.676147 1157.794067,691.686523 
	C1155.500977,691.740051 1153.209595,691.871033 1150.652710,691.769287 
	C1149.583618,691.029358 1148.779419,690.495178 1147.409302,689.584961 
	C1147.409302,692.574280 1147.409302,694.672241 1147.409302,696.770203 
	C1146.970337,697.023865 1146.531250,697.277527 1146.092285,697.531189 
	C1143.365967,694.615112 1140.639648,691.699036 1138.054810,688.457642 
	C1138.888184,687.174072 1139.580200,686.215820 1140.272095,685.257568 
	C1140.075195,685.060364 1139.878296,684.863220 1139.681396,684.666016 
	C1138.263306,685.645935 1136.845337,686.625854 1135.423218,687.608643 
	C1134.321899,686.899414 1133.247925,686.207886 1132.086060,685.218506 
	C1130.768311,683.824402 1129.538452,682.728271 1128.212402,681.309387 
	C1126.420166,679.016235 1124.724243,677.045776 1123.081909,675.137634 
	C1124.210815,673.240540 1125.200928,671.576782 1126.191040,669.913086 
	C1119.552856,673.989075 1119.429077,675.118530 1124.579346,679.983826 
	C1125.512451,680.865356 1126.693726,681.484375 1127.878296,682.532104 
	C1129.099365,683.909607 1130.202515,684.979614 1131.176025,686.369385 
	C1131.046265,688.375427 1131.046265,690.061768 1131.046265,692.357971 
	C1130.399292,692.487793 1129.210205,692.726379 1127.966797,692.624146 
	C1122.292847,686.170288 1116.673218,680.057251 1111.280029,674.190613 
	C1114.386475,670.040588 1117.302124,666.145447 1120.517944,662.176025 
	C1121.326538,661.497925 1121.834961,660.894104 1122.666870,660.199829 
	C1129.551514,653.623474 1136.112793,647.137573 1142.673950,640.651733 
	C1142.111450,640.119507 1141.549072,639.587280 1140.986572,639.055054 
	C1134.590210,645.965881 1128.193726,652.876648 1121.514160,659.894531 
	C1120.770630,660.619019 1120.310547,661.236389 1119.532227,661.953857 
	C1116.235107,665.150818 1113.036255,668.076050 1110.404175,671.444153 
	C1109.558472,672.526306 1109.455200,675.493591 1110.295776,676.377075 
	C1115.768066,682.127869 1121.593994,687.542358 1127.200928,693.435181 
	C1126.109985,695.491455 1125.122192,697.179382 1124.028809,698.572266 
	C1122.962402,697.512024 1122.001709,696.746765 1120.832031,695.678223 
	C1118.473267,694.815552 1116.323364,694.256165 1114.075439,693.390015 
	C1112.980591,692.384033 1111.983765,691.684753 1110.993408,690.992798 
	C1111.000122,691.000061 1111.017212,691.011414 1110.898438,690.687256 
	C1108.848633,689.238281 1106.917480,688.113525 1104.993164,686.994385 
	C1105.000000,687.000000 1105.011597,687.013306 1105.027344,686.660278 
	C1102.401367,683.881470 1099.603394,681.599304 1097.182617,678.970032 
	C1095.949707,677.631042 1094.422119,674.367310 1094.675293,674.178162 
	C1100.626221,669.731079 1102.991577,660.270142 1112.727905,661.221375 
	C1111.740967,659.440613 1110.866455,657.862793 1109.975464,656.255127 
	C1104.599243,657.702698 1103.207153,656.733521 1103.109985,650.955811 
	C1102.964478,642.295593 1102.998901,633.632385 1102.912598,624.970947 
	C1102.897583,623.467041 1102.664917,621.965332 1102.532715,620.462585 
	C1102.066895,620.495911 1101.601074,620.529175 1101.135254,620.562500 
	C1101.135254,631.530701 1101.135254,642.498840 1101.135254,654.298340 
	C1097.751099,652.975769 1095.231079,651.990906 1092.711182,651.006104 
	C1093.232544,650.866760 1093.753906,650.727417 1094.275391,650.588074 
	C1095.543579,636.014038 1094.903198,621.360901 1094.832520,606.289795 
	C1094.779175,600.659851 1094.605347,595.466553 1094.431396,590.273254 
	C1095.314941,585.852173 1098.759766,584.084473 1099.953369,587.390991 
	C1100.567017,589.090637 1100.527588,591.025940 1100.783325,592.854797 
	C1101.297852,592.850037 1101.812256,592.845337 1102.326782,592.840637 
	C1102.511475,590.209229 1102.696167,587.577820 1103.387451,584.518860 
	C1110.272461,584.033752 1116.651001,583.976257 1123.029419,583.918701 
M1133.392456,631.152161 
	C1133.337891,631.566101 1133.283203,631.980042 1133.228516,632.394043 
	C1133.572144,632.033386 1133.915771,631.672729 1134.733521,631.154358 
	C1134.904419,630.867554 1135.075317,630.580688 1135.246216,630.293884 
	C1134.780273,630.469116 1134.314331,630.644348 1133.392456,631.152161 
M1129.140137,635.926453 
	C1128.419800,636.944092 1127.699585,637.961731 1126.261475,638.953430 
	C1122.460083,641.692200 1119.006226,644.719849 1116.956665,648.914856 
	C1117.339233,649.242554 1117.721924,649.570312 1118.104492,649.898010 
	C1121.074585,646.270630 1124.044556,642.643250 1127.668701,638.929443 
	C1128.494019,637.995239 1129.319214,637.061096 1130.470093,635.897583 
	C1130.437500,635.687500 1130.404785,635.477356 1130.372192,635.267273 
	C1130.179077,635.437378 1129.985962,635.607483 1129.140137,635.926453 
M1133.255859,802.874512 
	C1131.534668,799.848328 1129.500732,798.388306 1126.224976,801.648132 
	C1127.418213,802.315613 1128.411865,802.871521 1129.574829,803.522034 
	C1128.222656,804.792542 1127.159912,805.791016 1126.097290,806.789429 
	C1126.317749,807.138611 1126.538330,807.487793 1126.758789,807.836975 
	C1128.504883,806.951111 1131.764771,809.679688 1131.929688,804.825256 
	C1131.936890,804.611145 1133.181885,804.439087 1133.834839,804.955200 
	C1135.441406,807.804199 1137.442139,807.635498 1139.018677,805.188232 
	C1139.688965,804.147705 1139.867798,801.682312 1139.190308,801.081055 
	C1136.735352,798.902649 1135.160034,800.731201 1133.255859,802.874512 
M1157.552002,779.420837 
	C1159.231934,778.750427 1161.040894,778.270020 1162.501831,777.277405 
	C1162.871582,777.026245 1162.276611,774.238525 1161.874512,774.173889 
	C1160.594971,773.968079 1159.197388,774.496521 1157.043213,775.076904 
	C1156.165161,775.135925 1155.049438,775.572998 1154.446533,775.194153 
	C1149.645752,772.177917 1149.073853,772.299805 1146.352783,776.954529 
	C1139.656494,778.464966 1137.572632,772.151428 1132.848145,767.847046 
	C1136.523682,777.286682 1138.556519,778.723450 1147.215088,779.691772 
	C1149.218872,779.817078 1151.231689,780.112976 1153.223145,780.006775 
	C1154.516113,779.937866 1155.777832,779.282532 1157.552002,779.420837 
M1141.685181,746.996338 
	C1142.353271,747.389465 1143.064453,748.176880 1143.682373,748.109436 
	C1146.876221,747.761169 1149.758423,746.346802 1153.357544,747.797852 
	C1155.040649,748.476440 1157.941650,745.615356 1160.447754,744.950989 
	C1162.161255,744.496765 1164.146118,745.066406 1166.009644,745.178284 
	C1166.039917,745.369568 1166.070190,745.560791 1166.100464,745.752075 
	C1168.207642,745.752075 1170.314941,745.752075 1172.663574,745.752075 
	C1172.663574,744.154175 1172.663574,742.257324 1172.663574,739.723083 
	C1171.019287,740.811707 1169.867310,741.574402 1168.771240,742.300110 
	C1163.748413,738.493713 1161.466675,738.949768 1157.587036,743.576111 
	C1156.493896,744.879639 1154.237427,745.207520 1152.514526,745.982910 
	C1151.997314,745.282532 1151.479980,744.582092 1150.962769,743.881714 
	C1152.819580,743.087769 1154.676514,742.293823 1156.533447,741.499878 
	C1156.158569,741.033508 1155.783813,740.567139 1155.409058,740.100769 
	C1153.496460,740.593872 1150.856079,742.131653 1149.799927,741.395691 
	C1145.662720,738.512695 1143.404785,740.672485 1141.030762,742.753540 
	C1140.321655,741.878174 1139.616089,740.264709 1138.902954,740.261292 
	C1136.432007,740.249634 1133.958496,740.797913 1130.410645,741.278931 
	C1132.286499,742.452209 1133.309692,743.092163 1134.367920,743.754089 
	C1133.378174,745.283508 1132.437988,746.736206 1131.497925,748.188904 
	C1131.688721,748.604492 1131.879395,749.020081 1132.070190,749.435669 
	C1135.098389,748.402527 1138.126465,747.369446 1141.685181,746.996338 
M1115.789429,650.788025 
	C1114.183716,651.799438 1112.578125,652.810791 1110.972534,653.822205 
	C1111.385864,654.283630 1111.799194,654.745056 1112.212646,655.206482 
	C1113.397461,653.957397 1114.582275,652.708252 1115.789429,650.788025 
M1130.247925,766.240723 
	C1130.954956,766.812866 1131.661987,767.385010 1132.369019,767.957214 
	C1132.607056,767.553101 1132.844971,767.148987 1133.083008,766.744873 
	C1132.107300,766.338684 1131.131470,765.932495 1130.247925,766.240723 
M1131.736084,633.565186 
	C1131.736084,633.565186 1131.450928,633.438599 1131.450928,633.438599 
	C1131.450928,633.438599 1131.521240,633.747803 1131.736084,633.565186 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1110.690552,857.872864 
	C1120.056519,857.775940 1128.992554,857.567993 1137.926758,857.632324 
	C1144.620850,857.680542 1142.354004,864.077637 1142.629761,866.751282 
	C1143.435547,874.566650 1142.284912,882.572510 1142.069458,890.508240 
	C1141.917480,896.106506 1142.040039,901.712158 1142.040039,907.650513 
	C1130.263428,907.650513 1119.048706,907.650513 1107.498169,907.650513 
	C1107.332397,906.282532 1107.054810,905.018250 1107.044800,903.751892 
	C1106.936035,889.961182 1106.888428,876.169983 1106.773071,862.379395 
	C1106.752075,859.875000 1106.723755,857.544495 1110.690552,857.872864 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1347.186890,902.849487 
	C1345.764404,903.591248 1347.271118,907.752502 1343.299805,905.405701 
	C1342.979980,901.959900 1342.986450,899.007996 1343.247314,895.889587 
	C1344.506470,894.973694 1345.511108,894.224304 1346.565186,893.892029 
	C1349.568359,898.281006 1353.572388,895.347412 1357.392822,895.915894 
	C1359.792847,893.705505 1361.846924,891.475098 1363.919189,889.610474 
	C1365.228394,893.442017 1366.519165,896.907715 1367.853638,900.490906 
	C1365.802856,900.837891 1364.285034,901.094727 1362.767090,901.351501 
	C1362.788330,901.523865 1362.809448,901.696228 1362.830688,901.868591 
	C1365.077759,901.868591 1367.324707,901.868591 1369.927612,901.868591 
	C1368.322510,896.876892 1366.822388,892.211792 1365.320557,887.220947 
	C1365.450928,886.263550 1365.583252,885.632019 1366.074585,885.010864 
	C1366.911133,885.056824 1367.388550,885.092285 1367.884399,885.460449 
	C1367.748657,886.741394 1367.594604,887.689758 1367.242432,889.857422 
	C1369.071899,887.885986 1370.025879,886.857971 1371.357544,885.422852 
	C1369.809692,885.023193 1369.049438,884.826904 1368.243896,884.321838 
	C1367.759766,883.318481 1367.320923,882.623901 1367.208130,881.658691 
	C1369.994873,881.204224 1372.452515,880.958252 1374.916382,880.847656 
	C1382.958984,880.486572 1391.043701,882.425720 1399.444336,880.442993 
	C1401.113770,880.494385 1402.396851,880.510620 1403.764648,880.853149 
	C1405.790405,882.794434 1407.731323,884.409546 1409.672363,886.024597 
	C1409.923950,885.722412 1410.175659,885.420227 1410.427246,885.118042 
	C1409.962402,884.134094 1409.497437,883.150085 1409.360107,881.951355 
	C1414.397461,881.290771 1419.107056,880.844971 1423.816772,880.399170 
	C1423.907837,887.735046 1423.934814,895.072510 1424.124756,902.405762 
	C1424.191406,904.980774 1423.222900,905.860596 1420.661011,905.932617 
	C1417.739258,906.014832 1414.832764,906.636414 1411.028809,907.036133 
	C1403.398560,907.115845 1396.658936,907.183044 1389.919312,907.250244 
	C1389.510254,907.227295 1389.101074,907.204285 1387.914062,907.141479 
	C1379.742798,907.105164 1372.349487,907.108521 1364.956055,907.111877 
	C1362.770752,906.594360 1360.585449,906.076904 1358.427734,904.984009 
	C1358.973633,902.995789 1359.680420,901.609192 1359.882446,900.152527 
	C1359.935181,899.772278 1358.167603,898.582458 1357.668091,898.792480 
	C1356.414551,899.319336 1354.991577,900.233276 1354.439087,901.378967 
	C1354.023682,902.240723 1354.852173,903.702332 1355.127075,904.896851 
	C1354.144653,905.067444 1353.162231,905.238037 1352.089600,904.746948 
	C1352.178589,902.337830 1352.357910,900.590454 1352.645508,897.787476 
	C1350.012573,899.692688 1344.986816,896.981201 1347.186890,902.849487 
M1405.084595,887.268616 
	C1404.977051,886.735657 1404.869629,886.202637 1404.762207,885.669678 
	C1404.536255,885.715637 1404.310425,885.761597 1404.084473,885.807556 
	C1404.341553,886.576904 1404.598511,887.346313 1404.970215,888.895203 
	C1404.581421,890.861511 1404.192627,892.827759 1403.354126,895.405579 
	C1402.427002,899.915833 1401.974609,900.254089 1397.005859,898.430725 
	C1398.560669,897.843872 1400.115479,897.257080 1401.670288,896.670288 
	C1401.304077,896.206482 1400.937988,895.742737 1400.571899,895.278931 
	C1395.815552,895.866943 1391.059204,896.454956 1385.901367,897.092590 
	C1386.003662,897.337891 1385.595215,896.358948 1385.291016,895.629883 
	C1383.386963,897.112488 1381.689453,898.434265 1379.991943,899.755981 
	C1380.157471,900.288086 1380.322998,900.820129 1380.488647,901.352173 
	C1387.987915,901.716370 1395.487183,902.080505 1402.986450,902.444641 
	C1402.970947,902.041138 1402.955566,901.637634 1402.940186,901.234192 
	C1403.888550,902.027222 1404.836914,902.820312 1406.613892,903.840515 
	C1409.741943,902.417419 1412.869873,900.994324 1415.351929,899.865112 
	C1413.343994,895.900696 1411.360596,891.984619 1409.366943,888.048340 
	C1408.396973,888.005859 1406.773315,887.934875 1405.084595,887.268616 
M1377.187012,891.391724 
	C1378.373047,891.301025 1379.612183,891.398193 1380.733887,891.080383 
	C1383.060059,890.421448 1385.449585,889.771118 1387.541138,888.622131 
	C1388.220703,888.248779 1388.053711,886.334656 1388.274048,885.125366 
	C1386.279297,884.764099 1384.287598,884.383850 1382.286377,884.063232 
	C1382.004272,884.018066 1381.675537,884.264404 1381.370972,884.382690 
	C1380.509277,888.642456 1377.887451,890.125488 1373.847290,888.253113 
	C1375.348022,886.967651 1376.717163,885.795105 1378.086182,884.622498 
	C1377.668945,884.173096 1377.251587,883.723633 1376.834351,883.274170 
	C1375.288818,884.936646 1373.743164,886.599060 1372.197510,888.261475 
	C1373.616211,889.409546 1375.034790,890.557617 1377.187012,891.391724 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1342.992798,896.056091 
	C1342.986450,899.007996 1342.979980,901.959900 1342.966553,905.343262 
	C1323.614136,906.526062 1304.268555,907.277405 1284.422607,907.701294 
	C1283.612793,907.055725 1283.303467,906.737549 1282.994141,906.419373 
	C1282.662476,906.901489 1282.330933,907.383667 1281.999390,907.865845 
	C1272.248657,907.675781 1262.497925,907.485718 1252.375732,906.705322 
	C1252.029175,905.344666 1252.054077,904.574341 1252.439697,903.857666 
	C1253.530151,903.943970 1254.259888,903.976624 1255.277832,904.221313 
	C1260.404663,904.484375 1265.251099,904.724487 1270.079468,904.524841 
	C1273.766479,904.372498 1277.430542,903.663086 1281.434570,903.149902 
	C1283.654907,902.666321 1285.545288,902.234253 1287.854736,901.861450 
	C1288.554688,901.947388 1288.835449,901.973999 1289.228760,902.289734 
	C1290.215210,902.586365 1291.088989,902.598694 1291.962891,902.600403 
	C1293.706421,902.603821 1295.450073,902.601440 1297.500488,902.601440 
	C1297.500488,901.076660 1297.500488,898.996887 1297.970459,896.757874 
	C1301.509033,896.610840 1304.631958,896.962219 1307.621582,896.482300 
	C1308.975220,896.265015 1310.063965,894.396912 1311.556396,893.115479 
	C1312.595825,891.680237 1313.351562,890.407227 1314.480347,889.043579 
	C1316.574219,889.625793 1318.295288,890.298462 1320.115112,891.343262 
	C1326.516724,892.214722 1332.353149,899.002563 1339.285767,892.986084 
	C1340.502075,893.993469 1341.747437,895.024780 1342.992798,896.056091 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1117.657715,837.958008 
	C1125.357300,838.203735 1132.749268,838.449890 1140.143188,838.591125 
	C1140.394043,838.595947 1140.665161,837.545044 1141.208008,836.387085 
	C1141.001465,834.517151 1140.513672,833.246582 1140.026001,831.975952 
	C1140.580811,825.861938 1144.698364,825.781006 1149.493164,825.932068 
	C1157.973022,826.199158 1166.468384,825.977844 1175.466675,826.325073 
	C1176.323242,827.925598 1176.670410,829.162048 1177.017578,830.398499 
	C1168.427979,830.607300 1159.834595,831.056091 1151.250366,830.920654 
	C1147.133057,830.855774 1145.725098,832.014893 1145.927612,836.198730 
	C1146.248291,842.822266 1146.010254,849.472717 1145.976074,856.585571 
	C1140.967773,856.719971 1135.996094,856.186401 1131.015869,856.085388 
	C1122.936035,855.921448 1114.850464,856.041138 1106.201538,856.041138 
	C1106.289307,854.068420 1106.358643,852.508484 1107.056396,850.534790 
	C1112.210327,853.670593 1117.543945,852.945251 1122.735718,852.961670 
	C1125.554077,852.970581 1128.372314,852.959717 1131.190674,852.964050 
	C1141.665527,852.980286 1141.668823,852.981079 1140.803467,842.463623 
	C1140.779663,842.173279 1140.505005,841.903564 1140.028564,841.059448 
	C1137.902832,841.059448 1135.462646,841.062073 1133.022583,841.058960 
	C1124.358276,841.047852 1115.693970,841.034729 1107.029663,841.022339 
	C1106.981689,840.617859 1106.933716,840.213379 1107.323364,839.447388 
	C1110.546875,838.697144 1113.332764,838.308472 1116.118774,837.919800 
	C1116.529297,837.927063 1116.939819,837.934387 1117.657715,837.958008 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1320.016235,890.971191 
	C1318.295288,890.298462 1316.574219,889.625793 1314.510010,888.703308 
	C1314.783691,887.577148 1315.400757,886.700745 1316.282959,885.447937 
	C1314.729248,885.296753 1313.617920,885.188660 1312.410889,884.620544 
	C1311.403687,883.253723 1310.487671,881.552124 1309.581055,881.557190 
	C1290.766235,881.661926 1271.952515,881.960388 1252.725342,882.148315 
	C1250.923950,881.999939 1249.535889,881.923706 1247.753418,881.846680 
	C1245.814209,882.226868 1244.271362,882.617432 1242.723389,882.986450 
	C1240.897949,883.421753 1239.068115,883.838379 1236.984497,884.322510 
	C1234.665649,880.461670 1230.762329,881.882263 1226.717529,882.196045 
	C1225.585938,882.109314 1224.828003,882.098694 1224.070190,882.088135 
	C1223.356079,882.017395 1222.642090,881.946655 1221.572021,881.871277 
	C1220.992188,881.880676 1220.768433,881.894653 1219.999146,881.933533 
	C1219.178955,882.070801 1218.981934,882.262634 1218.862305,882.533813 
	C1218.614380,882.813904 1218.366333,883.094055 1217.720703,883.644592 
	C1216.894653,883.958618 1216.466064,884.002197 1215.814453,883.882812 
	C1215.127686,883.429688 1214.664185,883.139465 1214.200684,882.849304 
	C1210.085327,882.889526 1205.969849,882.929810 1201.428345,882.576904 
	C1201.001587,881.455261 1201.001099,880.726746 1201.247803,879.978760 
	C1201.494995,879.959229 1201.974121,879.831055 1201.974121,879.831055 
	C1205.728760,879.885925 1209.516968,880.263000 1213.225220,879.874695 
	C1215.443359,879.642456 1217.536011,878.212036 1220.098022,877.616028 
	C1220.976440,878.562988 1221.441772,879.214111 1222.265747,879.896606 
	C1223.103027,879.891296 1223.581787,879.854736 1224.364258,879.874756 
	C1225.077148,879.895203 1225.486450,879.859070 1226.200195,879.847046 
	C1226.708008,879.855103 1226.911133,879.838989 1227.551636,879.873169 
	C1230.638916,879.920227 1233.288818,879.917053 1236.330078,879.971863 
	C1237.508911,879.987244 1238.296265,879.944641 1239.278320,879.899780 
	C1239.473022,879.897400 1239.854980,879.820251 1240.326172,879.868652 
	C1244.898560,879.894104 1248.999756,879.871094 1253.562866,879.858643 
	C1278.994385,879.637268 1303.963989,879.405334 1328.835205,879.399109 
	C1328.490356,880.050110 1328.243774,880.475342 1327.572754,880.947876 
	C1324.529907,881.272583 1321.911499,881.550049 1318.406006,881.921570 
	C1320.224609,883.905334 1321.171875,884.938599 1322.109009,885.960754 
	C1321.334473,887.772949 1320.638916,889.400208 1319.973022,891.028198 
	C1320.002686,891.028992 1320.016235,890.971191 1320.016235,890.971191 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1433.067871,891.915039 
	C1433.223145,891.622620 1433.290405,891.311096 1433.451782,890.634033 
	C1434.148071,889.879761 1434.661987,889.471863 1435.534424,889.034668 
	C1437.090210,889.015564 1438.287476,889.025879 1439.856934,889.318298 
	C1442.305298,889.929565 1444.381348,890.258667 1446.605347,890.611267 
	C1446.827881,887.231995 1447.000610,884.609314 1447.384277,881.810364 
	C1447.351196,881.103455 1447.107178,880.572815 1446.510132,880.119019 
	C1445.440308,880.147888 1444.723511,880.099915 1444.011719,879.786560 
	C1444.031860,879.167969 1444.046753,878.814697 1444.479980,878.368286 
	C1448.020386,878.234070 1451.208618,878.705933 1453.409180,875.444336 
	C1453.948853,874.644531 1455.766235,874.706848 1457.299805,874.589722 
	C1459.234863,875.057007 1460.866577,875.307312 1462.222534,875.515320 
	C1459.049438,877.058899 1456.017822,878.533569 1452.691284,880.071411 
	C1451.995972,880.139465 1451.595581,880.144531 1450.862671,880.164124 
	C1449.705078,882.480164 1448.880127,884.781677 1448.086182,887.432251 
	C1448.744751,890.537476 1453.436646,892.760742 1449.214844,896.152832 
	C1450.103760,897.193298 1450.851807,898.913879 1451.910645,899.133057 
	C1454.138672,899.594360 1456.529663,899.278198 1458.853638,899.262878 
	C1464.866943,899.223328 1471.331421,900.606812 1475.478638,894.066406 
	C1475.651611,894.047913 1475.996216,893.998596 1476.017822,894.398376 
	C1476.038696,895.065063 1476.038208,895.331909 1475.934570,895.921082 
	C1475.260864,896.977722 1474.690186,897.712097 1473.598755,899.116394 
	C1478.383789,899.116394 1482.465332,899.397461 1486.488770,899.034851 
	C1490.283569,898.692871 1495.176880,900.892334 1497.220825,895.202759 
	C1497.347900,894.849060 1499.036255,895.056335 1499.783203,895.317078 
	C1499.117065,897.519165 1498.665894,899.406799 1498.159058,901.620728 
	C1498.025391,902.603577 1497.947388,903.260193 1497.869385,903.916748 
	C1497.515991,903.903442 1497.162598,903.890076 1496.431641,903.422852 
	C1494.530640,902.398865 1493.009766,901.342529 1491.483643,901.335022 
	C1478.163208,901.269470 1464.840942,901.324036 1451.521606,901.510315 
	C1448.593140,901.551208 1447.001953,901.252014 1446.700195,897.655701 
	C1446.546875,895.828674 1444.088867,894.195068 1442.672607,892.474121 
	C1441.638916,894.135742 1440.317993,895.697815 1439.692383,897.501099 
	C1439.380005,898.401672 1440.338867,899.743225 1440.845825,901.251770 
	C1438.430542,900.357422 1436.225098,899.540710 1433.789795,898.638916 
	C1434.865479,897.623291 1435.535767,896.990540 1436.043091,896.511597 
	C1434.963257,894.843384 1434.015503,893.379211 1433.067871,891.915039 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1106.750610,841.258911 
	C1115.693970,841.034729 1124.358276,841.047852 1133.022583,841.058960 
	C1135.462646,841.062073 1137.902832,841.059448 1140.028564,841.059448 
	C1140.505005,841.903564 1140.779663,842.173279 1140.803467,842.463623 
	C1141.668823,852.981079 1141.665527,852.980286 1131.190674,852.964050 
	C1128.372314,852.959717 1125.554077,852.970581 1122.735718,852.961670 
	C1117.543945,852.945251 1112.210327,853.670593 1107.272949,850.224731 
	C1106.731323,847.384155 1106.601440,844.439758 1106.750610,841.258911 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1099.035522,908.942200 
	C1099.035522,883.446350 1099.035522,857.950562 1099.035522,832.192444 
	C1102.169189,831.678650 1103.051025,833.505310 1103.086426,836.746582 
	C1103.347168,860.502258 1103.710571,884.256714 1103.591919,908.496643 
	C1101.773315,908.968506 1100.404419,908.955322 1099.035522,908.942200 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1227.091431,882.272156 
	C1230.762329,881.882263 1234.665649,880.461670 1236.984497,884.322510 
	C1239.068115,883.838379 1240.897949,883.421753 1242.723389,882.986450 
	C1244.271362,882.617432 1245.814209,882.226868 1247.712891,882.252686 
	C1247.542480,887.772400 1246.485718,892.900757 1246.632690,897.994324 
	C1246.806519,904.009888 1247.257568,903.996887 1252.017822,903.312500 
	C1252.038330,903.476318 1252.058838,903.640137 1252.079102,903.804016 
	C1252.054077,904.574341 1252.029175,905.344666 1252.005371,906.498962 
	C1245.853394,906.931152 1239.694458,906.834351 1233.548950,907.075562 
	C1229.001465,907.253967 1227.308350,904.792664 1227.180054,900.835571 
	C1227.007446,895.516846 1227.086060,890.190002 1227.067383,884.119385 
	C1227.082397,883.005493 1227.086914,882.638794 1227.091431,882.272156 
M1236.665649,889.533081 
	C1236.439087,894.023682 1235.894287,898.541931 1236.279297,902.979492 
	C1236.348633,903.778137 1240.230225,904.546204 1242.390869,904.675903 
	C1243.018799,904.713501 1244.441895,902.441650 1244.364258,901.306213 
	C1244.100952,897.458130 1243.328491,893.644897 1242.737305,889.729858 
	C1241.396729,889.592590 1239.382202,889.386292 1236.665649,889.533081 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1432.663574,891.922485 
	C1434.015503,893.379211 1434.963257,894.843384 1436.043091,896.511597 
	C1435.535767,896.990540 1434.865479,897.623291 1433.789795,898.638916 
	C1436.225098,899.540710 1438.430542,900.357422 1440.845825,901.251770 
	C1440.338867,899.743225 1439.380005,898.401672 1439.692383,897.501099 
	C1440.317993,895.697815 1441.638916,894.135742 1442.672607,892.474121 
	C1444.088867,894.195068 1446.546875,895.828674 1446.700195,897.655701 
	C1447.001953,901.252014 1448.593140,901.551208 1451.521606,901.510315 
	C1464.840942,901.324036 1478.163208,901.269470 1491.483643,901.335022 
	C1493.009766,901.342529 1494.530640,902.398865 1496.165771,903.420898 
	C1495.367676,904.460083 1494.457764,905.047302 1492.831543,905.823975 
	C1485.408447,906.347778 1478.701904,906.682007 1471.995239,907.016357 
	C1471.193115,906.947510 1470.390869,906.878723 1468.797363,906.772705 
	C1461.253052,906.588684 1454.500366,906.441895 1447.747559,906.295044 
	C1443.209595,905.922729 1438.677368,905.371582 1434.132080,905.225769 
	C1429.059204,905.063049 1427.871460,904.066589 1428.993408,899.124939 
	C1429.564575,896.609131 1431.138672,894.321045 1432.663574,891.922485 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1105.973755,910.987183 
	C1111.203613,910.992554 1116.433350,911.007507 1121.663208,911.001221 
	C1133.323364,910.987244 1144.983398,910.945068 1156.643555,910.962219 
	C1158.300293,910.964661 1159.956787,911.234314 1161.613525,911.380310 
	C1161.578003,911.933899 1161.542358,912.487488 1161.506836,913.041138 
	C1143.375488,913.328064 1125.244019,913.615051 1106.592041,913.519653 
	C1106.038818,912.420593 1106.006348,911.703857 1105.973755,910.987183 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1336.895508,908.890625 
	C1345.086670,908.710388 1352.799561,908.485657 1360.513550,908.422302 
	C1361.360718,908.415344 1362.215576,909.334106 1363.066772,909.823486 
	C1362.353882,910.519409 1361.647339,911.814331 1360.927124,911.822021 
	C1349.063599,911.947693 1337.198364,911.911255 1325.245361,911.195007 
	C1328.381104,907.454529 1332.712036,909.623352 1336.895508,908.890625 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1214.635498,910.231873 
	C1227.323242,910.164734 1239.579834,910.164734 1251.836548,910.164734 
	C1251.873779,910.865234 1251.911011,911.565735 1251.948364,912.266174 
	C1250.669434,912.482300 1249.391846,912.875427 1248.111206,912.886475 
	C1237.631958,912.976746 1227.151733,913.047485 1216.672852,912.970154 
	C1215.384399,912.960632 1211.814209,914.107849 1214.635498,910.231873 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1389.989014,907.573242 
	C1396.658936,907.183044 1403.398560,907.115845 1410.614868,907.087585 
	C1411.000122,908.577820 1410.908813,910.029175 1410.817505,911.480591 
	C1408.418213,911.013000 1406.018921,910.545410 1403.256592,909.902100 
	C1402.806885,909.678040 1402.720337,909.629822 1402.633667,909.581543 
	C1402.757446,909.719543 1402.881226,909.857544 1403.004883,909.995605 
	C1399.183228,910.924622 1395.361694,911.853577 1391.448608,912.804749 
	C1390.817017,910.574280 1390.437744,909.235291 1389.989014,907.573242 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1365.015381,907.478149 
	C1372.349487,907.108521 1379.742798,907.105164 1387.608154,907.174561 
	C1386.439819,911.872742 1386.439819,911.872742 1380.993408,911.802856 
	C1383.088257,911.195557 1384.136353,910.891785 1385.184448,910.587952 
	C1385.178223,910.335327 1385.171875,910.082703 1385.165649,909.830078 
	C1380.214355,909.830078 1375.263062,909.830078 1370.311768,909.830078 
	C1370.285522,910.167053 1370.259277,910.504089 1370.233154,910.841064 
	C1373.126709,910.841064 1376.020386,910.841064 1378.914062,910.841064 
	C1370.022095,913.208252 1367.435913,912.538757 1365.015381,907.478149 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1431.983154,911.082214 
	C1426.087769,911.104004 1420.687744,911.104004 1414.876953,911.104004 
	C1417.071411,907.281799 1440.576172,906.848816 1445.133179,911.060425 
	C1440.057617,911.060425 1436.268066,911.060425 1431.983154,911.082214 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1424.175903,880.326904 
	C1419.107056,880.844971 1414.397461,881.290771 1409.138184,881.652527 
	C1406.952393,881.221313 1405.316040,880.874146 1403.679810,880.526917 
	C1402.396851,880.510620 1401.113770,880.494385 1399.429077,880.054382 
	C1399.443481,879.033447 1399.859741,878.436279 1400.643799,877.961426 
	C1402.015259,878.074524 1403.018555,878.065186 1404.348145,878.097778 
	C1405.110474,878.101685 1405.546387,878.063660 1406.374023,878.138428 
	C1407.836182,878.156677 1408.906982,878.062134 1410.227417,878.031311 
	C1410.647583,878.073853 1410.818115,878.052612 1411.436157,878.172791 
	C1414.790039,878.340820 1417.721680,878.598083 1420.591553,878.286804 
	C1421.833008,878.152222 1422.947388,876.844299 1424.331055,875.948486 
	C1424.973267,875.619995 1425.404053,875.412231 1425.979492,875.423645 
	C1426.643799,876.097595 1427.163208,876.552429 1427.855957,877.325623 
	C1428.679810,877.932129 1429.330444,878.220215 1429.931152,878.766907 
	C1429.578979,879.653076 1429.276733,880.280640 1428.896851,881.150208 
	C1428.571045,881.620605 1428.322876,881.848999 1428.074707,882.077393 
	C1426.894775,881.469788 1425.714966,880.862244 1424.175903,880.326904 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1533.846802,907.355835 
	C1533.890991,907.967224 1533.935181,908.578674 1533.926758,909.492493 
	C1530.852173,909.874084 1527.830322,909.953308 1524.466553,910.174561 
	C1520.398804,910.476501 1516.672852,910.636292 1512.485474,910.416992 
	C1512.056030,909.128174 1512.088257,908.218506 1512.055298,907.247559 
	C1511.990234,907.186401 1512.009766,907.008850 1512.443359,907.077026 
	C1514.920532,907.093201 1516.963867,907.041260 1519.469849,907.048462 
	C1524.570557,907.190430 1529.208618,907.273132 1533.846802,907.355835 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1188.190918,909.315796 
	C1193.360718,908.631531 1198.715088,908.271667 1204.073608,907.991760 
	C1206.051514,907.888428 1208.039185,907.973572 1210.486328,907.966187 
	C1210.070312,908.953247 1209.212036,910.782471 1208.306763,910.806030 
	C1202.034302,910.968689 1195.751587,910.772522 1189.473877,910.621887 
	C1189.100952,910.612976 1188.742310,910.002502 1188.190918,909.315796 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1165.107422,909.323425 
	C1172.151001,908.729675 1179.283691,908.542480 1186.720703,908.639221 
	C1184.880249,909.617310 1182.729126,910.910156 1180.592407,910.886475 
	C1175.456665,910.829590 1170.328003,910.155212 1165.107422,909.323425 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1447.788818,906.618530 
	C1454.500366,906.441895 1461.253052,906.588684 1468.394531,906.786743 
	C1467.967163,907.563293 1467.170288,908.889893 1466.332031,908.916626 
	C1460.726196,909.094971 1455.109985,909.055359 1449.502563,908.898132 
	C1448.927246,908.881897 1448.386841,907.623840 1447.788818,906.618530 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1472.074097,907.419678 
	C1478.701904,906.682007 1485.408447,906.347778 1492.588623,906.050110 
	C1492.377930,906.995178 1491.678223,908.701050 1491.011841,908.688171 
	C1484.722290,908.566895 1478.438354,908.152710 1472.074097,907.419678 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1508.552368,905.261353 
	C1509.982422,901.480042 1506.491943,901.995605 1504.393555,900.929321 
	C1503.999023,900.907837 1504.073853,900.878235 1504.112793,900.564453 
	C1504.808716,899.805786 1505.465576,899.360840 1506.411621,898.896179 
	C1507.086792,898.890625 1507.472778,898.904724 1508.140503,898.907471 
	C1508.941895,898.374695 1509.461426,897.853149 1510.182617,897.122681 
	C1510.994385,896.462158 1511.604492,896.010559 1512.542114,895.582275 
	C1513.470703,895.387207 1514.071899,895.168762 1515.065674,894.993225 
	C1515.715454,895.091309 1515.972778,895.146484 1516.152710,895.483887 
	C1515.323975,896.659729 1514.572632,897.553345 1513.482544,898.597900 
	C1512.739990,899.820801 1512.336182,900.892761 1511.962036,901.982666 
	C1511.991699,902.000671 1511.960693,901.938538 1511.676147,902.057373 
	C1511.248169,903.134399 1511.104614,904.092529 1511.071777,905.306396 
	C1511.458252,906.044373 1511.734009,906.526611 1512.009766,907.008789 
	C1512.009766,907.008850 1511.990234,907.186401 1511.713867,907.218872 
	C1510.475708,906.588074 1509.514038,905.924744 1508.552368,905.261353 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1226.717529,882.196045 
	C1227.086914,882.638794 1227.082397,883.005493 1227.060547,883.645874 
	C1226.699341,887.608765 1226.243286,891.291931 1226.046265,894.988831 
	C1225.879150,898.123718 1226.011719,901.274597 1226.011719,904.418457 
	C1225.349487,904.410339 1224.687256,904.402222 1224.025024,904.394104 
	C1224.025024,897.265503 1224.025024,890.136963 1224.047607,882.548279 
	C1224.828003,882.098694 1225.585938,882.109314 1226.717529,882.196045 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1102.296143,913.972046 
	C1100.223877,914.103821 1098.232300,913.714600 1096.703491,914.383240 
	C1093.697144,915.697937 1092.893921,914.216675 1092.320068,911.468506 
	C1094.241333,910.831421 1096.215698,910.542480 1098.867188,910.449280 
	C1101.025024,910.769470 1102.505371,910.893921 1103.985840,911.018311 
	C1103.985840,911.018311 1104.000000,911.000000 1103.984863,911.361084 
	C1103.897705,912.423645 1103.825684,913.125244 1103.753662,913.826782 
	C1103.364380,913.860901 1102.975220,913.895081 1102.296143,913.972046 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1428.023193,882.500732 
	C1428.322876,881.848999 1428.571045,881.620605 1429.291138,881.068481 
	C1430.538818,880.554565 1431.314697,880.364441 1432.600586,880.307800 
	C1433.461914,880.432800 1433.813354,880.424316 1434.134766,880.860962 
	C1434.462036,883.892029 1434.818970,886.478027 1435.175903,889.063965 
	C1434.661987,889.471863 1434.148071,889.879761 1433.334229,890.422852 
	C1431.590576,889.735596 1430.146973,888.913269 1427.971558,887.674133 
	C1427.971558,886.702148 1427.971558,884.813110 1428.023193,882.500732 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1116.050537,837.557739 
	C1113.332764,838.308472 1110.546875,838.697144 1107.307129,839.142151 
	C1107.849609,837.504272 1108.919556,834.361877 1109.827515,834.408020 
	C1111.921021,834.514343 1113.935547,836.169067 1116.050537,837.557739 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1355.479370,904.905884 
	C1354.852173,903.702332 1354.023682,902.240723 1354.439087,901.378967 
	C1354.991577,900.233276 1356.414551,899.319336 1357.668091,898.792480 
	C1358.167603,898.582458 1359.935181,899.772278 1359.882446,900.152527 
	C1359.680420,901.609192 1358.973633,902.995789 1358.200317,904.678955 
	C1357.240845,904.937866 1356.536255,904.926392 1355.479370,904.905884 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1508.220337,905.192871 
	C1509.514038,905.924744 1510.475708,906.588074 1511.778809,907.280090 
	C1512.088257,908.218506 1512.056030,909.128174 1512.039795,910.376038 
	C1509.914551,910.751953 1507.773682,910.789734 1505.306396,910.438354 
	C1504.966064,909.239624 1504.951904,908.429993 1504.937744,907.620361 
	C1505.921387,906.788330 1506.904907,905.956299 1508.220337,905.192871 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1347.533813,902.849121 
	C1344.986816,896.981201 1350.012573,899.692688 1352.645508,897.787476 
	C1352.357910,900.590454 1352.178589,902.337830 1351.904053,904.436768 
	C1350.499512,904.141785 1349.190063,903.495300 1347.533813,902.849121 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1498.109375,904.245605 
	C1497.947388,903.260193 1498.025391,902.603577 1498.541260,901.562378 
	C1500.005493,901.084473 1501.031860,900.991333 1502.279541,900.935913 
	C1502.501099,900.973694 1502.945923,900.908447 1503.229492,900.961060 
	C1503.699951,900.968567 1503.886963,900.923401 1504.073853,900.878235 
	C1504.073853,900.878235 1503.999023,900.907837 1504.006104,900.947388 
	C1504.546387,906.005066 1501.560791,905.468140 1498.109375,904.245605 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1504.629639,907.515869 
	C1504.951904,908.429993 1504.966064,909.239624 1504.965942,910.355713 
	C1503.233521,910.758423 1501.515503,910.854675 1499.204468,910.956970 
	C1498.611572,910.963013 1498.287476,910.949585 1498.287476,910.949585 
	C1497.785400,910.720398 1497.283447,910.491211 1496.359863,910.148376 
	C1495.938354,910.034668 1495.596924,909.809387 1495.596924,909.809387 
	C1496.470215,909.155701 1497.343384,908.502014 1498.776489,908.131348 
	C1500.570679,908.134094 1501.805176,907.853699 1503.039551,907.573364 
	C1503.466797,907.519409 1503.894165,907.465393 1504.629639,907.515869 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1304.623047,909.890747 
	C1303.552124,909.849426 1302.900391,909.816528 1302.248535,909.783630 
	C1302.246826,909.623840 1302.245117,909.464050 1302.243408,909.304260 
	C1308.443726,909.304260 1314.644043,909.304260 1320.844360,909.304260 
	C1320.854004,909.502563 1320.863647,909.700928 1320.873169,909.899231 
	C1315.596191,909.899231 1310.319214,909.899231 1304.623047,909.890747 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1103.994385,910.671753 
	C1102.505371,910.893921 1101.025024,910.769470 1099.273315,910.406372 
	C1099.003296,909.963501 1099.004395,909.759277 1099.020508,909.248596 
	C1100.404419,908.955322 1101.773315,908.968506 1103.552368,908.960083 
	C1103.975952,909.400696 1103.989380,909.862976 1103.994385,910.671753 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1502.704346,907.426880 
	C1501.805176,907.853699 1500.570679,908.134094 1499.144043,908.134521 
	C1500.090820,907.663208 1501.229858,907.471741 1502.704346,907.426880 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1144.842529,908.049194 
	C1144.335693,907.213074 1143.977539,906.095947 1143.619507,904.978821 
	C1144.014648,904.908752 1144.409912,904.838745 1144.805054,904.768677 
	C1144.867188,905.768555 1144.929199,906.768372 1144.842529,908.049194 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1145.444824,873.310608 
	C1146.236938,873.215759 1146.912598,873.458801 1147.588257,873.701782 
	C1147.112671,874.129211 1146.637085,874.556641 1145.884277,875.006348 
	C1145.514404,874.568604 1145.421387,874.108521 1145.444824,873.310608 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1104.161011,913.868408 
	C1103.825684,913.125244 1103.897705,912.423645 1103.976074,911.367554 
	C1104.425049,911.007874 1104.867798,911.002747 1105.642090,910.992371 
	C1106.006348,911.703857 1106.038818,912.420593 1106.137329,913.487915 
	C1105.658325,913.862427 1105.113281,913.886230 1104.161011,913.868408 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1282.330078,907.927124 
	C1282.330933,907.383667 1282.662476,906.901489 1282.994141,906.419373 
	C1283.303467,906.737549 1283.612793,907.055725 1283.961426,907.663086 
	C1283.554077,907.964355 1283.107300,907.976379 1282.330078,907.927124 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1498.226074,911.198181 
	C1498.287476,910.949585 1498.611572,910.963013 1498.773193,910.966980 
	C1498.790039,911.428467 1498.645142,911.885986 1498.500122,912.343567 
	C1498.388428,912.044678 1498.276611,911.745728 1498.226074,911.198181 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1496.061768,910.197754 
	C1495.912231,910.268494 1495.639282,910.176208 1495.481689,909.946655 
	C1495.596924,909.809387 1495.938354,910.034668 1496.061768,910.197754 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1518.203735,948.849854 
	C1519.732422,946.163330 1521.261108,943.476868 1522.905029,940.480774 
	C1524.461182,941.730957 1524.567871,945.041077 1528.319214,944.299622 
	C1528.337524,944.994446 1528.046875,945.364929 1527.375732,945.769043 
	C1526.016235,946.015259 1525.037476,946.227844 1524.058716,946.440430 
	C1524.191650,946.946777 1524.324585,947.453125 1524.457520,947.959473 
	C1525.810913,947.472473 1527.164185,946.985474 1528.762695,946.341797 
	C1529.150269,945.336914 1529.292603,944.488586 1529.434814,943.640320 
	C1529.490112,942.826050 1529.545288,942.011780 1529.625000,940.898682 
	C1531.241821,943.550537 1532.834229,946.501160 1534.196045,949.704102 
	C1529.276489,949.961853 1524.587769,949.967285 1519.430176,950.012573 
	C1518.961548,950.052429 1518.971802,950.023804 1518.882568,949.804688 
	C1518.628418,949.315002 1518.431885,949.069763 1518.203735,948.849854 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1930.584595,569.856812 
	C1934.431396,570.212097 1937.798950,570.686279 1941.182495,570.973511 
	C1943.137329,571.139404 1945.117676,571.003662 1947.380859,571.003662 
	C1947.733887,573.438171 1948.044922,575.583069 1948.355957,577.727966 
	C1948.703613,577.771606 1949.051270,577.815308 1949.398926,577.858948 
	C1950.109985,575.646545 1950.182861,572.423462 1951.689941,571.471924 
	C1953.670654,570.221497 1956.780273,570.759277 1959.703369,570.267090 
	C1975.343872,569.688354 1990.685425,569.370667 2006.458862,569.112488 
	C2011.283936,569.466309 2015.688354,570.099792 2020.066772,569.953125 
	C2023.912109,569.824219 2024.931641,571.300903 2024.924438,574.870911 
	C2024.881226,596.341431 2025.025024,617.812439 2024.991089,639.283020 
	C2024.988281,641.096008 2024.141113,642.907654 2023.578979,645.147461 
	C2029.284302,646.665955 2028.969116,651.458313 2028.900146,656.192261 
	C2028.832886,660.822510 2028.885498,665.454529 2030.046265,670.100891 
	C2030.046265,664.201904 2029.974121,658.301575 2030.082642,652.404602 
	C2030.124634,650.123291 2029.541016,647.884033 2032.587158,646.133972 
	C2033.882080,645.389954 2033.258423,641.065369 2033.268555,638.367126 
	C2033.319580,624.768066 2033.291992,611.168762 2033.291992,597.609924 
	C2050.944336,595.828064 2119.521729,604.177307 2136.705078,612.103210 
	C2136.292725,614.506104 2135.864746,616.999084 2135.436768,619.492065 
	C2135.798584,619.687439 2136.160400,619.882874 2136.522217,620.078247 
	C2137.241943,619.087524 2138.478516,618.151794 2138.591064,617.096436 
	C2139.061768,612.677246 2141.354248,612.647400 2144.869629,613.713440 
	C2152.630127,616.066772 2160.483643,618.113037 2168.738281,620.361755 
	C2170.886963,621.264648 2172.597168,622.090393 2174.652832,622.961365 
	C2193.316650,631.437744 2211.573242,640.006531 2229.996826,648.200562 
	C2234.441650,650.177429 2235.460205,652.296936 2232.900146,656.348389 
	C2232.373779,657.181396 2232.444824,658.391846 2232.240479,659.428345 
	C2232.613037,659.615662 2232.985596,659.802979 2233.358398,659.990234 
	C2234.768799,657.775085 2236.179443,655.559937 2237.949951,652.779419 
	C2257.869385,663.812866 2275.883789,677.632874 2294.468750,691.121277 
	C2294.811035,691.131287 2294.888916,691.107971 2294.893555,691.437866 
	C2299.577148,695.526917 2304.256104,699.286133 2308.935303,703.045349 
	C2308.935303,703.045349 2308.878418,703.106140 2308.864014,703.144836 
	C2311.196045,705.587280 2313.542725,707.991028 2315.600830,710.693848 
	C2312.594238,710.992920 2309.876221,710.992920 2307.157959,710.992920 
	C2307.171143,711.964722 2307.184326,712.936523 2307.197510,713.908264 
	C2310.781494,713.670288 2314.365234,713.432251 2317.949219,713.194214 
	C2321.239746,713.797363 2321.692383,714.771301 2319.414307,717.698975 
	C2316.460693,721.495117 2312.952393,724.512756 2309.384277,727.617065 
	C2308.009277,728.813232 2307.698730,731.232300 2306.512939,733.521362 
	C2301.082520,734.607910 2297.661377,738.215515 2296.408691,743.813110 
	C2293.389893,745.283508 2290.744141,746.739868 2287.729980,748.398926 
	C2286.784180,748.253418 2285.159180,748.003418 2282.613525,747.611816 
	C2283.543701,749.312073 2283.737793,749.989929 2284.177002,750.413513 
	C2286.057373,752.226868 2288.010010,753.965271 2289.936279,755.731018 
	C2290.577393,754.033447 2291.159668,752.309753 2291.892578,750.652771 
	C2292.144287,750.082947 2292.799072,749.701904 2293.222900,749.195679 
	C2294.589111,747.563599 2295.933594,745.913513 2297.561523,744.092529 
	C2300.938232,740.654602 2304.039307,737.394531 2307.140137,734.134460 
	C2312.614990,728.825195 2318.089600,723.515930 2324.565674,717.235596 
	C2327.946045,721.022949 2331.546143,725.056641 2335.522949,729.093506 
	C2335.899170,729.096680 2335.963867,729.058960 2335.922363,729.372437 
	C2336.233887,729.802856 2336.587402,729.919861 2336.932617,730.069092 
	C2336.924561,730.101318 2336.921143,730.167786 2336.921387,730.167786 
	C2337.045898,730.955688 2336.899658,731.963501 2337.334717,732.499329 
	C2347.556641,745.087708 2357.842041,757.625000 2368.461914,770.147705 
	C2368.808594,770.123657 2368.886963,770.098938 2368.843262,770.441833 
	C2373.680420,778.394714 2378.328369,786.169250 2383.534912,793.549683 
	C2386.410156,797.625488 2387.672119,800.669617 2382.188477,803.282776 
	C2381.642578,803.542969 2381.444824,804.533813 2381.085449,805.185547 
	C2381.282471,805.480957 2381.479492,805.776428 2381.676514,806.071838 
	C2384.014893,804.912842 2386.353516,803.753784 2388.928223,802.477722 
	C2389.953369,804.186646 2390.947021,805.864746 2391.961182,807.530334 
	C2395.617676,813.534668 2399.281494,819.534668 2402.942627,825.536377 
	C2404.386963,829.137451 2405.831055,832.738586 2407.441650,836.587708 
	C2414.671143,855.765625 2421.774658,874.680908 2428.712158,893.656799 
	C2429.198730,894.987000 2428.460693,896.764709 2427.676758,898.616455 
	C2419.048340,903.529602 2410.088867,901.381775 2402.237061,903.192505 
	C2400.522949,905.038940 2398.808838,906.885376 2396.524658,909.345581 
	C2401.417236,910.276367 2403.914795,907.426025 2407.270264,905.465698 
	C2410.256348,903.721313 2414.770020,904.531982 2418.615967,904.335571 
	C2422.750244,904.124268 2426.891602,904.053589 2431.030029,903.921509 
	C2433.487549,912.817078 2436.337158,921.625977 2438.326172,930.625122 
	C2443.446533,953.791077 2446.964600,977.189575 2446.582520,1001.483521 
	C2445.764160,1002.019287 2445.313965,1002.073242 2444.864014,1002.127258 
	C2422.965332,1002.428772 2401.066162,1002.691223 2379.168945,1003.072510 
	C2375.743896,1003.132141 2373.073975,1003.086914 2372.466797,998.620239 
	C2372.188965,996.577026 2370.270752,994.756897 2368.453857,993.183411 
	C2369.883789,997.579590 2371.156982,1002.038574 2372.873291,1006.320007 
	C2373.227051,1007.201904 2375.212402,1007.800293 2376.483398,1007.873413 
	C2380.073730,1008.079895 2383.683594,1007.945679 2387.285889,1007.945679 
	C2387.402588,1008.518799 2387.519531,1009.091858 2387.636230,1009.664978 
	C2386.066895,1010.128906 2384.512939,1010.924072 2382.925293,1010.998779 
	C2376.479492,1011.302002 2370.023193,1011.383911 2363.281982,1011.856934 
	C2360.174316,1012.113647 2357.329834,1012.267700 2354.547852,1011.919983 
	C2353.185303,1011.749634 2351.939209,1010.646851 2350.499512,1009.126770 
	C2358.441406,1008.872681 2357.807129,1003.726013 2357.715820,998.867310 
	C2357.648193,995.273804 2357.702393,991.677979 2357.702393,988.200195 
	C2355.066162,988.844482 2354.993408,989.179382 2355.542236,994.472168 
	C2355.825684,997.208862 2355.814941,999.976135 2355.951660,1003.054443 
	C2352.753418,1003.054443 2350.173096,1003.054443 2347.272705,1003.054443 
	C2346.747314,1000.272034 2346.219238,997.475830 2345.691162,994.679626 
	C2345.278564,994.695251 2344.865723,994.710876 2344.453125,994.726562 
	C2344.453125,1000.013794 2344.453125,1005.301086 2344.453125,1011.886047 
	C2336.416748,1011.886047 2329.066650,1011.985535 2321.730469,1011.713989 
	C2321.080322,1011.689880 2320.519287,1009.247253 2319.906006,1007.049561 
	C2319.273926,997.614380 2318.652832,989.057556 2318.031494,980.500793 
	C2317.427002,980.564453 2316.822266,980.628113 2316.217773,980.691772 
	C2317.153809,989.465698 2318.090088,998.239685 2319.026123,1007.013672 
	C2318.911377,1008.483337 2318.796631,1009.953064 2318.651611,1011.810120 
	C2298.808350,1011.810120 2279.109375,1011.810120 2259.122559,1011.810120 
	C2258.652588,1007.240051 2256.696777,1004.691650 2251.377930,1006.957520 
	C2246.673828,1007.127502 2242.345947,1007.197876 2238.022217,1007.379395 
	C2234.066406,1007.545471 2230.110107,1008.092529 2226.163086,1008.013855 
	C2215.892822,1007.809021 2205.629150,1007.145874 2195.359863,1007.076050 
	C2189.419922,1007.035645 2183.466797,1008.193237 2177.533447,1008.083618 
	C2170.193604,1007.948059 2162.778076,1009.741272 2155.490967,1007.391113 
	C2154.258301,1006.993530 2152.833252,1007.236572 2151.536865,1006.980835 
	C2149.127441,1006.505554 2146.747314,1005.881165 2144.073486,1005.252563 
	C2142.271240,1005.780884 2140.005127,1006.883667 2137.670898,1007.054382 
	C2126.877197,1007.843872 2116.070312,1008.714783 2105.257080,1008.894043 
	C2095.884277,1009.049438 2086.493164,1007.980957 2077.116211,1008.070740 
	C2068.026123,1008.157715 2058.943848,1009.031250 2049.858154,1009.559387 
	C2049.675049,1008.988342 2049.491699,1008.417358 2049.308594,1007.846375 
	C2053.417480,1005.988403 2057.526611,1004.130432 2061.635498,1002.272461 
	C2061.529541,1001.883728 2061.423584,1001.494995 2061.317383,1001.106201 
	C2059.358887,1001.718506 2057.346436,1002.198608 2055.453857,1002.972168 
	C2052.072998,1004.353821 2048.846436,1006.166626 2045.388306,1007.280457 
	C2043.864868,1007.771118 2041.932373,1006.991272 2040.453491,1006.381348 
	C2041.943481,1004.733582 2043.165527,1003.492737 2044.387573,1002.251831 
	C2043.965210,1001.898132 2043.542847,1001.544434 2043.120483,1001.190735 
	C2042.070679,1002.784058 2041.020874,1004.377319 2039.971069,1005.970581 
	C2036.441528,1005.618408 2037.074219,1002.924500 2037.063110,1000.680603 
	C2036.962036,980.206116 2037.046143,959.729126 2036.730103,939.258118 
	C2036.612915,931.676636 2035.381470,924.117920 2035.047852,916.531677 
	C2034.899414,913.156433 2035.715698,909.738708 2036.112793,906.170410 
	C2038.188721,906.063171 2039.655273,905.987488 2041.570801,906.437622 
	C2041.451904,912.390686 2040.868408,917.816406 2040.337891,923.247253 
	C2040.278442,923.855286 2040.492188,924.498108 2040.623291,925.115356 
	C2041.148804,927.589539 2041.711426,930.056030 2042.222412,932.533203 
	C2042.641968,934.567566 2043.008301,936.612854 2043.567749,938.928833 
	C2047.442383,944.671265 2052.521240,946.556885 2059.055908,945.739441 
	C2063.292236,945.209595 2067.642578,945.668152 2071.927002,945.424561 
	C2075.705078,945.209839 2079.134033,945.573853 2081.759277,949.162598 
	C2081.953125,951.992859 2082.638672,954.747498 2081.844482,956.974243 
	C2081.108643,959.036682 2078.790527,960.534729 2077.148682,962.303467 
	C2079.987549,963.370483 2082.373291,962.768616 2083.102295,959.127014 
	C2083.967529,954.804871 2086.754883,952.400696 2090.824951,951.201050 
	C2091.966553,953.524780 2091.004150,957.398010 2095.217773,956.063965 
	C2094.583984,954.941406 2093.973633,953.860291 2093.363281,952.779175 
	C2094.627441,952.315308 2095.887695,951.464233 2097.156738,951.450806 
	C2104.776367,951.370239 2106.425537,952.516846 2108.522705,958.824097 
	C2108.550293,958.906860 2108.825928,958.907227 2109.260254,959.254639 
	C2111.674316,959.700867 2113.821533,959.766235 2115.951660,959.991638 
	C2119.955566,960.415466 2121.318359,958.539734 2121.070801,954.721191 
	C2120.866943,951.574951 2121.060059,948.402893 2121.489746,945.173828 
	C2123.573730,945.115601 2125.244629,945.125916 2127.006592,945.437988 
	C2127.426758,946.780762 2127.756104,947.821777 2127.799805,947.959961 
	C2129.333252,946.828247 2130.735352,944.895386 2132.173340,944.868469 
	C2150.054932,944.534302 2167.941650,944.477234 2185.879395,944.714539 
	C2184.997070,946.386047 2184.313721,948.059875 2183.068359,948.911316 
	C2181.204590,950.185547 2178.945557,950.881653 2176.482910,951.876953 
	C2170.504639,954.080139 2164.898193,956.230042 2159.291748,958.379944 
	C2159.626953,959.139587 2159.962158,959.899170 2160.297607,960.658813 
	C2165.956543,957.884705 2171.615479,955.110535 2177.646484,952.234375 
	C2181.345459,950.764038 2184.672852,949.395813 2188.357422,947.993042 
	C2193.460693,943.659180 2198.342041,944.287231 2203.447266,947.353699 
	C2204.797607,948.164917 2206.531006,948.338745 2208.374023,948.987000 
	C2211.115479,950.770569 2213.571045,952.372437 2215.983887,954.375854 
	C2216.576904,955.949219 2217.120361,958.060791 2217.863770,958.133667 
	C2221.468018,958.486877 2225.125977,958.292969 2228.038086,958.292969 
	C2228.038086,953.178711 2228.038086,949.216125 2228.038086,944.835571 
	C2225.065918,944.977234 2221.967773,945.124878 2218.517090,945.250732 
	C2217.123779,946.421448 2216.083252,947.613953 2215.597656,948.170349 
	C2212.885010,947.917786 2210.518066,947.697449 2207.823730,947.289429 
	C2202.884766,945.052368 2198.837402,941.369812 2193.140381,942.392151 
	C2192.529785,942.501709 2191.617676,940.929260 2190.856445,939.706482 
	C2190.551025,935.641052 2191.076904,931.657776 2189.747070,928.441833 
	C2186.707031,921.090454 2187.672363,913.852051 2188.252930,905.852539 
	C2202.206543,905.852539 2215.670410,905.852539 2229.417236,906.028381 
	C2235.063721,911.724670 2240.427490,917.245117 2245.374268,922.871033 
	C2238.427002,925.550964 2231.896240,928.125427 2225.365723,930.699829 
	C2225.631104,931.347107 2225.896484,931.994324 2226.162109,932.641541 
	C2232.599854,929.946716 2239.046387,927.271362 2245.448730,924.495544 
	C2245.749756,924.365112 2245.662842,923.340149 2246.111328,922.799866 
	C2247.499268,923.701782 2248.615967,924.451294 2249.552002,925.382324 
	C2263.339111,939.094299 2277.158447,952.774963 2290.822510,966.608704 
	C2293.473145,969.292053 2296.137451,970.688721 2299.997559,970.404602 
	C2304.531494,970.070740 2309.108887,970.326416 2314.767090,970.326416 
	C2313.223877,972.307922 2312.497314,973.241089 2311.770508,974.174316 
	C2312.287109,974.536804 2312.803711,974.899292 2313.320068,975.261841 
	C2314.527588,973.536804 2315.735107,971.811768 2317.095459,970.404724 
	C2317.780762,971.857971 2318.312988,972.993286 2318.845459,974.128540 
	C2319.218994,974.013428 2319.592529,973.898315 2319.966064,973.783203 
	C2320.176758,972.480469 2320.387695,971.177734 2320.497070,970.501709 
	C2327.626953,968.888489 2334.288086,967.381348 2340.949219,965.874207 
	C2340.771240,965.009460 2340.593262,964.144775 2340.415283,963.280029 
	C2333.694336,964.427124 2326.973633,965.574158 2319.805420,966.797607 
	C2319.561035,966.222168 2318.902344,964.670898 2318.020508,962.593506 
	C2317.154785,963.876648 2316.645264,964.631592 2315.745117,965.346680 
	C2314.783691,965.237122 2314.212891,965.167419 2313.642090,965.097717 
	C2313.432861,968.849731 2310.677490,968.228821 2308.276855,968.225403 
	C2305.781006,968.221802 2303.260010,968.002563 2300.794678,968.278259 
	C2296.802002,968.724670 2293.961914,967.241150 2291.176758,964.393982 
	C2281.879639,954.891113 2272.201416,945.760681 2262.928223,936.235291 
	C2258.611084,931.800720 2254.890625,926.785767 2251.285889,922.004639 
	C2257.074463,919.778076 2262.477051,917.580811 2267.879639,915.383545 
	C2267.662842,914.896179 2267.446289,914.408813 2267.229736,913.921387 
	C2261.661865,916.322205 2255.170898,916.669067 2250.610596,922.017578 
	C2247.575684,919.816711 2244.686523,917.896057 2242.246826,915.515198 
	C2237.223877,910.613098 2232.440430,905.465210 2226.881592,899.718933 
	C2229.193848,899.718933 2230.770264,899.485229 2232.252686,899.759399 
	C2238.687256,900.949219 2244.496094,899.895874 2250.376221,896.728699 
	C2259.414307,891.860840 2268.872559,887.772583 2278.177246,883.696899 
	C2278.507568,884.364197 2278.812500,884.688049 2279.023193,885.337891 
	C2278.907715,886.114685 2278.886475,886.565369 2278.612305,887.337036 
	C2278.273926,889.812500 2278.095215,891.968079 2278.118896,894.121460 
	C2278.217773,903.088501 2278.240234,903.097168 2269.264404,903.152893 
	C2265.322021,903.177368 2261.378906,903.157043 2257.436279,903.157043 
	C2257.448730,904.023865 2257.461182,904.890625 2257.473877,905.757446 
	C2262.798340,905.757446 2268.195801,906.295227 2273.424805,905.588745 
	C2277.671387,905.014893 2278.217285,906.521179 2278.239990,910.032349 
	C2278.274902,915.444397 2278.723877,920.853699 2279.024902,926.813232 
	C2284.712891,926.813232 2290.004395,926.830017 2295.295898,926.809326 
	C2303.671387,926.776672 2304.913574,927.798706 2306.865723,935.873413 
	C2308.309814,941.846375 2309.925781,947.777710 2311.465088,953.727600 
	C2312.515381,952.979370 2312.628662,952.393921 2312.498291,951.869080 
	C2310.936279,945.589600 2309.462891,939.283997 2307.705566,933.058777 
	C2306.540771,928.932556 2306.894531,925.906860 2311.635254,924.544128 
	C2312.650391,924.252380 2313.387695,922.994507 2314.253174,922.181641 
	C2314.304199,921.550171 2314.355225,920.918701 2314.406250,920.287170 
	C2307.483398,924.579773 2305.602783,921.083618 2304.579590,915.850464 
	C2304.203613,913.926819 2302.941650,912.185303 2302.489502,910.265015 
	C2301.984863,908.122925 2301.942627,905.871948 2301.763184,904.248474 
	C2301.093262,903.001770 2300.594727,902.074097 2300.474609,901.123840 
	C2306.235352,898.469238 2311.617920,895.837280 2317.432617,893.115234 
	C2320.969238,892.361206 2324.073486,891.697205 2328.185791,890.817505 
	C2324.319092,889.523804 2322.018555,888.754028 2318.751709,887.661011 
	C2318.383545,887.976013 2316.895020,889.249207 2315.098145,890.333740 
	C2314.789795,888.710876 2314.789795,887.276733 2314.789795,885.842590 
	C2314.246094,885.759033 2313.702637,885.675415 2313.158936,885.591858 
	C2312.588867,888.467529 2312.018555,891.343201 2311.378418,894.571960 
	C2308.184082,896.079346 2304.090820,898.010925 2299.687012,899.959717 
	C2298.394531,899.300293 2297.412842,898.623535 2296.320801,897.577332 
	C2294.075439,892.804749 2291.940430,888.401672 2289.865234,883.609863 
	C2290.540771,879.104492 2295.948730,872.845032 2299.248047,873.263672 
	C2304.722656,873.958374 2305.441162,869.749207 2307.749512,867.108032 
	C2307.382324,866.515625 2307.015137,865.923218 2306.647949,865.330811 
	C2300.499268,868.537109 2294.350342,871.743408 2288.179199,874.961365 
	C2287.240723,874.395630 2285.651123,873.437500 2283.978027,872.164673 
	C2276.153564,860.546448 2268.412842,849.243042 2260.786621,837.564148 
	C2261.221191,835.245300 2260.881348,832.668579 2262.005371,831.497498 
	C2264.002686,829.417114 2267.080078,828.420105 2269.344238,826.535767 
	C2271.397461,824.826904 2272.968994,822.539429 2274.752930,820.507080 
	C2274.265869,819.963318 2273.778809,819.419495 2273.291748,818.875732 
	C2271.850586,819.790710 2270.409180,820.705627 2268.899902,821.151611 
	C2269.581543,816.070862 2268.789307,811.958801 2264.435059,809.460083 
	C2261.476562,807.762146 2258.439941,807.913574 2255.619385,810.160645 
	C2252.395020,812.729187 2251.200928,816.152527 2252.286377,819.914673 
	C2253.447998,823.940857 2256.444824,826.205750 2260.816406,826.451782 
	C2259.921143,827.931885 2259.074463,829.073425 2258.296143,830.123108 
	C2256.634033,829.592224 2255.315186,829.170959 2253.857422,828.448120 
	C2253.246826,827.683105 2252.762695,827.231628 2252.305664,826.754272 
	C2245.314697,819.451843 2238.229492,812.235962 2231.390869,804.793396 
	C2228.958984,802.146545 2227.128174,798.947571 2225.008789,796.000366 
	C2224.994873,795.999939 2225.000977,796.027771 2225.322754,795.924927 
	C2226.103760,794.874390 2226.563232,793.926758 2227.003174,792.982544 
	C2226.983643,792.986023 2226.980713,793.025879 2227.312012,792.921997 
	C2227.775635,792.388489 2227.908447,791.958801 2228.123291,791.451660 
	C2228.205322,791.374207 2228.502930,791.035034 2228.858398,791.034180 
	C2229.485107,790.682983 2229.756104,790.332703 2230.007324,789.983643 
	C2229.987549,789.984924 2229.981201,790.024292 2230.332031,790.039795 
	C2231.130127,789.363892 2231.577148,788.672424 2232.004883,787.983276 
	C2231.986084,787.985596 2231.980713,788.023804 2232.338379,788.067566 
	C2233.139404,787.403259 2233.582520,786.695190 2234.008057,785.989197 
	C2233.990479,785.991272 2233.989258,786.026611 2234.284424,786.017334 
	C2234.727051,785.674438 2234.874756,785.340881 2235.011230,785.003662 
	C2235.000000,785.000000 2235.008057,785.022461 2235.301270,785.016968 
	C2235.736328,784.677856 2235.878418,784.344360 2236.010254,784.006226 
	C2236.000000,784.001648 2236.013184,784.021790 2236.359619,784.076782 
	C2237.145996,783.420898 2237.586182,782.710022 2238.010498,781.996887 
	C2237.994873,781.994568 2237.996338,782.026184 2238.290039,782.015259 
	C2238.728271,781.674866 2238.872803,781.345398 2239.008789,781.007935 
	C2239.000000,781.000000 2239.017334,781.019775 2239.371338,781.094482 
	C2241.491211,778.772705 2243.257324,776.376160 2245.003906,773.982910 
	C2244.984619,773.986267 2244.981445,774.025757 2245.258301,773.978271 
	C2245.702148,773.610535 2245.869385,773.290222 2246.010986,772.978149 
	C2245.985352,772.986328 2245.969482,773.037476 2246.271484,772.896484 
	C2246.730225,772.350586 2246.887207,771.945618 2247.125977,771.460571 
	C2247.208008,771.380493 2247.511963,771.037781 2247.867188,771.036926 
	C2248.489258,770.691101 2248.756104,770.346130 2249.010986,770.000244 
	C2248.998779,769.999390 2249.004150,770.024048 2249.294678,770.011597 
	C2249.732178,769.663879 2249.879150,769.328613 2250.009033,768.992676 
	C2249.991699,768.992065 2249.993896,769.026611 2250.279785,769.001221 
	C2250.718994,768.650146 2250.872314,768.324463 2251.010254,767.997070 
	C2250.995361,767.995483 2250.999023,768.025635 2251.285156,767.998535 
	C2251.723389,767.644531 2251.875000,767.317627 2252.008789,766.990845 
	C2251.990967,766.990967 2251.990234,767.026733 2252.280518,767.001953 
	C2252.721191,766.653748 2252.871826,766.330322 2253.011230,766.003418 
	C2253.000000,766.000000 2253.007568,766.022583 2253.302246,766.018433 
	C2253.739746,765.675659 2253.882324,765.337036 2254.010254,764.997009 
	C2253.995361,764.995544 2253.999023,765.025391 2254.291260,765.012573 
	C2254.730469,764.667175 2254.877441,764.334595 2255.010986,763.999878 
	C2254.997803,763.997742 2255.002441,764.024475 2255.291992,764.006958 
	C2255.729980,763.656433 2255.878662,763.323425 2256.009033,762.991394 
	C2255.990967,762.992371 2255.995605,763.027771 2256.388184,762.974487 
	C2257.130127,761.490601 2257.208984,759.907471 2257.905273,758.672546 
	C2258.594482,757.449890 2259.827148,756.462708 2261.010986,755.647888 
	C2261.254395,755.480469 2262.337402,756.532532 2262.861816,757.076355 
	C2262.784424,757.075073 2262.878174,757.019897 2263.273438,756.732666 
	C2263.574463,754.483276 2263.574463,752.465942 2263.574463,749.553406 
	C2261.775391,751.625183 2260.695312,752.868958 2259.614990,754.112793 
	C2259.626953,753.573547 2259.638916,753.034363 2259.651123,752.495178 
	C2257.497070,751.649536 2255.395752,750.305786 2253.177490,750.071655 
	C2249.583496,749.692078 2250.210938,752.768311 2249.604980,755.023315 
	C2248.027832,755.367615 2246.845459,755.673157 2245.663330,755.978699 
	C2245.226074,755.288879 2244.788818,754.598999 2244.351562,753.909119 
	C2246.049561,753.278564 2247.747559,752.648010 2249.445557,752.017395 
	C2248.999268,751.450623 2248.552979,750.883789 2248.106689,750.316956 
	C2246.264648,751.259888 2244.422852,752.202881 2242.579590,753.146484 
	C2240.551270,747.683838 2236.117920,752.058044 2233.085449,750.741943 
	C2232.750244,750.596497 2231.931641,751.565552 2230.937744,752.321472 
	C2232.598145,752.685547 2233.677979,752.922363 2235.380371,753.295654 
	C2233.522705,754.995178 2232.190186,756.214478 2229.987793,758.229492 
	C2232.566162,758.229492 2233.987549,758.329224 2235.390137,758.208435 
	C2238.175781,757.968628 2240.950195,757.602112 2243.730469,757.298035 
	C2245.743896,757.077759 2247.759033,756.873718 2250.162109,756.717896 
	C2252.216553,756.199707 2253.882324,755.626160 2255.548340,755.052551 
	C2255.774658,755.348145 2256.001221,755.643738 2256.227539,755.939392 
	C2254.809082,757.627625 2253.390869,759.315918 2251.990234,761.006104 
	C2252.008057,761.008057 2252.007568,760.972290 2251.739746,761.027832 
	C2251.305176,761.392029 2251.138672,761.700623 2250.990723,762.008057 
	C2251.009033,762.006897 2251.003906,761.970764 2250.707764,762.099243 
	C2250.266357,762.828613 2250.120850,763.429565 2249.997559,764.024902 
	C2250.019287,764.019409 2250.029541,763.975159 2249.708252,763.921509 
	C2248.914551,764.247437 2248.442383,764.626953 2247.989502,765.007812 
	C2248.009033,765.009033 2248.009521,764.970276 2247.733398,765.084717 
	C2246.959961,765.803650 2246.462891,766.408081 2245.989746,767.012939 
	C2246.013184,767.013367 2246.018066,766.966553 2245.774170,767.062622 
	C2245.343018,767.444763 2245.155518,767.730896 2244.991699,768.016235 
	C2245.015137,768.015381 2245.020020,767.968628 2244.743652,768.087830 
	C2244.171631,768.482544 2243.876221,768.758057 2243.468262,769.104309 
	C2243.214600,769.288086 2243.104248,769.425476 2242.771973,769.842407 
	C2240.337402,772.732849 2238.154785,775.367981 2235.989502,778.004272 
	C2236.006836,778.005371 2236.000488,777.971252 2235.699707,778.094604 
	C2235.257568,778.815613 2235.116211,779.413208 2234.991943,780.009094 
	C2235.009033,780.007446 2235.006104,779.973572 2234.725830,780.007202 
	C2234.287842,780.364685 2234.130127,780.688599 2233.991211,781.011230 
	C2234.010010,781.009949 2234.012451,780.972595 2233.737793,781.020691 
	C2233.299561,781.383362 2233.135986,781.697876 2232.991211,782.011169 
	C2233.009766,782.009888 2233.012207,781.972656 2232.733398,782.017639 
	C2232.294434,782.376648 2232.134277,782.690674 2231.990723,783.006470 
	C2232.007324,783.008301 2232.008545,782.974915 2231.692383,782.911133 
	C2230.908936,783.229614 2230.441406,783.611938 2229.986816,783.997559 
	C2230.000000,784.000854 2229.994141,783.973755 2229.688477,784.094727 
	C2229.247314,784.809021 2229.111816,785.402222 2228.988281,785.998169 
	C2229.000000,786.000793 2228.990967,785.978027 2228.690674,785.972168 
	C2228.252930,786.308472 2228.115234,786.650635 2227.988770,786.996460 
	C2228.000000,787.000000 2227.992432,786.977539 2227.692139,786.973145 
	C2227.253174,787.311279 2227.114746,787.653870 2226.989014,787.999390 
	C2227.001709,788.002258 2227.000488,787.975525 2226.686035,787.892761 
	C2225.904541,788.207947 2225.437256,788.605957 2224.989014,789.005737 
	C2225.008057,789.007568 2225.004883,788.969421 2224.734619,789.130005 
	C2224.301270,789.863464 2224.138184,790.436340 2223.991943,791.007935 
	C2224.008789,791.006775 2224.003418,790.973877 2223.713379,790.989258 
	C2223.273193,791.338989 2223.123291,791.673340 2222.991211,792.008179 
	C2223.009033,792.008545 2223.008057,791.973267 2222.730225,792.012024 
	C2222.292236,792.373108 2222.131836,792.695496 2221.988037,793.017212 
	C2222.004639,793.016602 2222.016357,792.986633 2221.644043,792.988525 
	C2218.939697,791.983337 2216.607422,790.976135 2214.229004,789.729004 
	C2214.183105,789.489014 2214.041504,789.021362 2214.128906,788.576538 
	C2214.176758,786.668762 2213.532471,784.791931 2214.201416,783.813416 
	C2217.280518,779.311523 2220.726074,775.060303 2224.040039,770.719116 
	C2223.789062,770.263916 2223.537842,769.808655 2223.286865,769.353455 
	C2221.599609,770.132935 2219.472168,770.509460 2218.314453,771.773865 
	C2215.751953,774.572998 2213.740723,777.869934 2211.354980,780.843933 
	C2210.784912,781.554749 2209.630127,781.796448 2208.855469,781.859985 
	C2209.232666,780.180847 2209.721191,778.896851 2209.728516,777.610107 
	C2209.790527,766.911072 2209.763672,756.211487 2209.763672,744.296570 
	C2215.692627,744.916809 2220.668457,745.515564 2225.661621,745.875000 
	C2226.408936,745.928711 2227.237061,744.860962 2228.370361,744.358826 
	C2230.014893,745.317261 2231.218750,746.763916 2232.640381,747.025024 
	C2235.305420,747.514404 2238.111328,747.529663 2240.830322,747.337708 
	C2242.949219,747.188049 2245.716064,747.046387 2246.895264,745.733643 
	C2247.798096,744.728577 2246.671143,741.899963 2246.447021,739.882385 
	C2245.910889,739.713318 2245.375000,739.544312 2244.838867,739.375244 
	C2242.761230,741.108459 2240.683594,742.841675 2238.884766,744.342224 
	C2238.637451,743.119202 2238.636475,740.605347 2237.996582,740.430420 
	C2236.192627,739.937317 2234.062988,740.136108 2232.182617,740.577148 
	C2230.606201,740.946899 2229.199707,742.041504 2227.513184,742.591064 
	C2226.729736,741.743713 2226.060791,740.531067 2225.588135,740.603271 
	C2223.912842,740.859192 2222.334473,741.840881 2220.663818,742.016113 
	C2218.029297,742.292297 2215.349609,742.172485 2212.689453,742.157288 
	C2209.579102,742.139526 2208.278564,743.673462 2208.109375,746.744568 
	C2207.462646,758.474792 2206.683838,770.197876 2205.738770,782.146057 
	C2206.402588,783.918030 2207.281494,785.467468 2207.764160,787.247070 
	C2205.898682,787.332764 2204.429932,787.188354 2203.023682,786.692749 
	C2200.041016,783.828735 2196.995850,781.315918 2193.064209,778.071533 
	C2195.354980,778.071533 2196.405518,778.071533 2197.456055,778.071533 
	C2197.584961,777.753906 2197.713867,777.436218 2197.842773,777.118591 
	C2191.753418,773.076477 2185.664062,769.034424 2179.551514,764.976807 
	C2180.658691,762.518738 2181.615967,760.393738 2182.573242,758.268799 
	C2182.157959,758.002808 2181.742920,757.736877 2181.327637,757.470886 
	C2179.826416,759.342285 2178.325439,761.213684 2176.904785,762.984680 
	C2172.447510,760.976318 2168.018066,758.980469 2163.601562,756.560303 
	C2162.873291,754.756409 2162.132324,753.376831 2161.518066,752.233398 
	C2165.786133,746.540955 2170.541504,741.377930 2171.506592,733.715210 
	C2163.800537,736.017578 2164.056396,743.692139 2159.892822,747.990479 
	C2159.438721,747.266663 2159.212646,746.816833 2159.112061,746.340393 
	C2157.210449,737.318359 2151.865723,733.848450 2142.838379,735.639954 
	C2141.871094,735.831848 2140.672363,735.426147 2139.714600,734.998596 
	C2134.752686,732.784119 2129.833984,730.472412 2124.629883,727.914551 
	C2119.027344,728.662476 2113.077637,728.583740 2108.576172,731.098267 
	C2105.451172,732.844177 2103.312256,733.269775 2100.211914,732.565308 
	C2095.089355,731.401123 2089.910156,730.487000 2084.402832,729.329773 
	C2083.983398,728.134766 2083.694336,727.035522 2083.885254,726.027161 
	C2085.005859,720.103882 2087.621826,714.336914 2085.905029,706.937866 
	C2084.490234,708.441895 2083.260254,709.139771 2083.218506,709.902649 
	C2083.065918,712.689941 2079.875732,714.678589 2082.201660,718.487915 
	C2083.302002,720.290161 2080.223877,724.643799 2079.009521,727.920410 
	C2077.738281,728.180115 2076.326660,728.468567 2074.335938,728.875305 
	C2076.620361,730.591431 2078.224609,731.796692 2079.627197,733.349243 
	C2078.531250,735.177856 2077.637207,736.659180 2076.743164,738.140503 
	C2077.084717,738.027588 2077.426514,737.914734 2077.768311,737.801819 
	C2078.258789,740.479980 2078.749512,743.158142 2079.154297,746.178223 
	C2079.057373,746.989441 2079.046387,747.458801 2079.032715,748.226929 
	C2079.041016,748.724731 2079.052246,748.923706 2079.072754,749.428955 
	C2079.361572,750.492004 2079.640869,751.248718 2079.728027,752.101135 
	C2079.295166,752.366943 2079.120117,752.588867 2078.705078,752.899719 
	C2077.992920,752.902283 2077.586426,752.867737 2077.036621,752.505249 
	C2075.110107,752.115906 2073.326172,752.001770 2071.543701,752.014465 
	C2070.585938,752.021301 2069.632324,752.321289 2068.672363,752.356628 
	C2066.780518,752.426208 2064.882080,752.476501 2062.992676,752.393677 
	C2061.681885,752.336304 2060.381348,752.043091 2058.752930,751.618774 
	C2056.233154,751.090027 2054.036377,750.797791 2052.741211,750.625488 
	C2052.741211,743.393005 2052.741211,736.751465 2052.781006,729.674500 
	C2052.490234,725.649475 2052.175781,722.058411 2051.823975,718.470947 
	C2051.572021,715.901001 2052.669922,714.811096 2055.270264,714.931885 
	C2057.950439,715.056396 2060.640625,714.959534 2063.326660,714.959534 
	C2063.312500,714.448792 2063.298584,713.937988 2063.284668,713.427246 
	C2061.113770,713.427246 2058.914062,713.202515 2056.779053,713.477783 
	C2053.217285,713.936829 2051.488281,712.654602 2051.449707,708.999695 
	C2051.386719,703.010498 2051.310059,697.018616 2051.054199,691.035767 
	C2050.938721,688.341614 2050.377441,685.666565 2050.009277,682.991455 
	C2050.000000,683.000000 2050.017334,682.981934 2049.998535,682.587830 
	C2047.642944,681.883179 2045.314331,681.412903 2042.968262,681.298218 
	C2039.119507,681.110107 2034.967651,682.463440 2031.668091,678.558716 
	C2029.281006,677.901367 2026.958496,677.135803 2026.713867,681.087280 
	C2026.234009,687.852539 2025.955322,694.223328 2025.667236,700.808350 
	C2024.042114,700.649475 2022.738770,700.522034 2021.177734,700.194763 
	C2013.078369,696.476746 2008.876587,690.547485 2008.771606,681.830994 
	C2008.760498,680.911682 2008.253296,679.998230 2007.717041,678.750732 
	C1996.597656,678.310364 1985.737793,678.179321 1974.877441,678.145203 
	C1974.199463,678.143066 1973.519043,678.899536 1972.503418,679.278442 
	C1971.367920,679.176758 1970.568848,679.099609 1969.446533,678.991272 
	C1971.554810,675.238770 1973.607056,671.872498 1975.342773,668.350220 
	C1976.006714,667.002869 1976.657715,664.931824 1976.086304,663.824707 
	C1974.210083,660.190247 1971.770386,656.846619 1969.284668,653.106445 
	C1965.767334,652.718628 1962.496094,652.783936 1959.261475,652.471008 
	C1955.006714,652.059509 1952.823730,654.060364 1951.707886,658.152466 
	C1950.820923,659.437256 1950.214600,660.562439 1949.372559,661.847778 
	C1948.720337,662.641418 1948.303955,663.274902 1947.432861,663.964111 
	C1944.184692,664.037842 1941.362061,663.815857 1938.606567,664.149048 
	C1936.362549,664.420410 1934.198853,665.356384 1931.936035,666.325684 
	C1931.797363,667.092468 1931.721924,667.531128 1931.324951,667.923950 
	C1927.967285,665.168091 1924.931274,662.458008 1922.050293,659.476990 
	C1922.062134,656.805298 1921.918823,654.404602 1921.772095,651.945679 
	C1918.872559,652.403931 1917.169189,652.882690 1915.460327,652.903198 
	C1910.633667,652.961243 1905.804810,652.831238 1900.642456,652.824219 
	C1899.300293,654.091919 1898.292358,655.310791 1897.266602,656.551331 
	C1895.957886,655.142456 1894.795776,653.891479 1893.358765,652.344543 
	C1892.517212,654.652832 1891.910034,656.318237 1891.302979,657.983704 
	C1891.002319,657.911865 1890.701538,657.839966 1890.400879,657.768127 
	C1890.173584,656.362366 1889.946289,654.956604 1889.743774,653.703979 
	C1885.288574,652.139404 1881.712036,652.160645 1879.812134,657.174316 
	C1879.514160,657.960815 1878.154541,658.708313 1877.245117,658.759949 
	C1876.723389,658.789551 1875.902344,657.457275 1875.608887,656.603149 
	C1875.250122,655.558411 1875.302490,654.372437 1875.166504,653.153442 
	C1875.166504,655.719666 1875.166504,658.146240 1875.166504,661.046814 
	C1881.591919,658.485352 1889.094849,662.369385 1894.927490,656.828369 
	C1895.522339,657.671631 1896.411255,658.931885 1897.717285,660.783447 
	C1899.033325,658.226196 1900.070068,656.211914 1901.427246,654.131958 
	C1902.173950,654.690674 1902.600098,655.315002 1902.968750,656.280945 
	C1900.841797,659.324951 1900.950073,660.825806 1904.894653,661.110046 
	C1906.658691,661.237122 1908.344360,662.451111 1909.986450,663.408081 
	C1909.679688,663.889465 1909.422485,664.098267 1908.754639,664.320740 
	C1899.274658,665.424377 1889.882324,662.759705 1879.998413,665.743530 
	C1879.904297,665.668762 1878.815308,664.802795 1876.930664,663.304077 
	C1877.094971,666.423767 1877.207397,668.557251 1877.386230,671.948792 
	C1878.598511,670.100464 1879.071777,669.378845 1879.617065,668.547363 
	C1882.483154,671.768250 1885.734131,672.870178 1889.669678,670.398499 
	C1890.245605,670.036743 1891.137451,670.025391 1891.864380,670.083923 
	C1894.375000,670.285950 1896.877319,670.590637 1900.746338,671.000732 
	C1903.597534,674.698486 1905.964111,669.268066 1909.008789,668.327515 
	C1909.254272,669.370544 1909.466187,670.118347 1909.956543,670.905884 
	C1910.422119,670.955811 1910.609375,670.966064 1910.835449,671.416016 
	C1911.039795,678.923645 1911.039917,678.942688 1904.041138,679.001038 
	C1898.761719,679.045105 1893.475342,678.893372 1888.206055,679.133179 
	C1886.693848,679.201904 1885.229980,680.328613 1883.462280,680.961914 
	C1882.805298,680.967957 1882.430054,680.982422 1881.840820,680.656738 
	C1879.318970,680.532715 1877.010986,680.748779 1874.478516,680.938965 
	C1873.952393,680.895264 1873.651978,680.908813 1873.200317,680.700806 
	C1872.598022,680.618835 1872.148438,680.789612 1871.445068,680.946167 
	C1870.853149,680.949219 1870.514893,680.966492 1869.892334,680.647705 
	C1857.070435,680.196350 1844.532959,680.081177 1831.686523,679.691162 
	C1824.121704,679.601746 1816.866455,679.914001 1809.609985,679.948303 
	C1789.841797,680.041748 1770.072998,679.989258 1750.304810,680.082214 
	C1748.201172,680.092102 1746.100220,680.681274 1743.529541,680.992920 
	C1727.443726,681.029724 1711.826294,681.120178 1696.209351,681.060120 
	C1694.384521,681.053162 1692.562866,680.215454 1690.924683,679.569031 
	C1691.419800,678.955383 1691.729858,678.535522 1692.440308,677.939087 
	C1696.961914,678.224792 1699.979492,677.859009 1700.745361,672.440857 
	C1701.006836,670.590759 1704.791626,668.869995 1707.275146,667.818848 
	C1709.275513,666.972046 1711.740479,667.222473 1714.339844,667.097168 
	C1717.432861,668.150330 1720.185547,669.092346 1722.914795,670.439636 
	C1723.320679,677.447510 1728.329712,673.661377 1731.225098,674.256897 
	C1732.600220,674.539551 1735.673096,673.033325 1735.711914,672.228088 
	C1735.880859,668.731567 1736.246582,664.560547 1734.575439,661.843689 
	C1732.753296,658.881592 1723.490479,661.916992 1721.622437,665.378418 
	C1718.859253,665.476318 1716.559448,665.635071 1713.800537,665.696533 
	C1709.789795,665.119873 1706.238281,664.640503 1701.510498,664.002319 
	C1700.947754,664.202881 1699.068848,665.354553 1697.126831,665.473389 
	C1691.526489,665.816101 1685.898926,665.848328 1680.285889,665.760132 
	C1678.818481,665.737122 1677.329712,664.913391 1675.914185,664.309082 
	C1674.289673,663.615662 1672.735718,662.757324 1671.020874,661.573608 
	C1670.638672,657.809570 1670.386719,654.442627 1670.588623,650.997070 
	C1682.703979,650.601624 1694.365479,650.284729 1706.455322,650.023560 
	C1708.894531,650.023926 1710.905273,649.968689 1713.240845,649.938110 
	C1713.771729,649.894043 1713.977783,649.825317 1714.639160,649.747192 
	C1719.953369,649.706604 1724.812012,649.675415 1730.085571,649.772156 
	C1732.326538,649.628113 1734.152710,649.356079 1736.363281,649.082031 
	C1738.885742,649.090637 1741.024048,649.101257 1743.365234,649.140991 
	C1743.568115,649.169983 1743.954102,649.031067 1744.386719,649.047607 
	C1746.549805,649.089111 1748.280029,649.114014 1750.317749,649.414673 
	C1761.801880,649.485901 1772.978760,649.281311 1784.439941,649.083374 
	C1785.101929,649.088867 1785.479614,649.087708 1786.327271,649.096436 
	C1790.054565,649.106323 1793.311768,649.106323 1797.451416,649.106323 
	C1797.451416,656.402466 1797.451416,662.939453 1797.451416,669.476379 
	C1797.974976,669.449280 1798.498535,669.422119 1799.022095,669.394958 
	C1799.022095,664.316345 1799.305420,659.217041 1798.935669,654.165588 
	C1798.619141,649.840088 1800.280029,648.930298 1804.320312,648.960999 
	C1823.800049,649.109070 1843.282104,648.949585 1862.763428,648.893860 
	C1870.006836,648.873108 1870.025513,648.866150 1869.910889,641.875000 
	C1869.649902,625.950806 1869.368530,610.026978 1869.487671,594.048279 
	C1871.744629,595.084717 1873.609497,596.175781 1875.991333,597.569397 
	C1876.896240,592.741089 1880.817871,593.948181 1884.188721,593.910095 
	C1893.130737,593.809204 1902.082031,593.971680 1911.011719,593.599121 
	C1914.881958,593.437744 1917.795898,594.345032 1919.966309,597.801025 
	C1919.990601,598.430664 1920.006348,598.791687 1919.848877,599.287537 
	C1919.474976,599.637878 1919.253296,599.828979 1918.635620,600.060791 
	C1918.220215,602.280640 1917.608521,604.651489 1918.317017,606.522400 
	C1918.898438,608.058228 1921.072021,609.173767 1922.135498,605.976562 
	C1922.303589,605.863098 1922.709106,605.880371 1923.119629,605.916992 
	C1924.078979,605.945129 1924.627930,605.936768 1925.333740,605.915588 
	C1925.490723,605.902771 1925.804932,605.925293 1926.326904,605.844482 
	C1927.172852,605.626099 1927.496948,605.488464 1928.143555,605.421753 
	C1931.572998,608.366455 1934.679810,611.240295 1937.980469,614.389832 
	C1939.799072,616.484802 1941.423706,618.304077 1943.048340,620.123291 
	C1942.810303,620.372742 1942.572144,620.622253 1942.334106,620.871704 
	C1941.352539,620.630249 1940.371094,620.388855 1939.184204,620.096924 
	C1940.427002,625.752930 1943.728394,621.657898 1946.202881,622.296143 
	C1947.469971,624.423401 1948.524414,626.266113 1949.260742,628.207275 
	C1948.732788,629.471497 1948.522705,630.637268 1948.298828,631.880066 
	C1949.844238,631.387634 1951.013184,631.015198 1952.159302,630.715881 
	C1952.136353,630.789062 1952.233765,630.907593 1952.156982,631.334717 
	C1951.263916,636.191589 1952.790405,637.900696 1957.564697,637.666565 
	C1966.863647,637.210571 1965.836548,637.770264 1965.568237,629.680664 
	C1965.519287,628.204407 1965.581421,626.724426 1965.781738,624.947388 
	C1967.123901,624.080811 1968.223022,623.303833 1969.441284,622.990845 
	C1971.662354,622.420349 1973.953857,622.124817 1976.426758,621.989380 
	C1978.389526,622.487610 1980.140381,622.855469 1981.894531,622.872498 
	C1982.750366,622.880859 1984.158447,621.717773 1984.390747,621.962769 
	C1988.283936,626.069519 1991.182129,621.272949 1994.536987,620.971436 
	C1996.408691,620.803162 1998.307861,620.941223 2001.130371,620.941223 
	C2000.780640,621.333740 2001.393555,620.645813 2002.265869,620.199341 
	C2003.957031,620.781921 2005.388916,621.123047 2006.760986,621.449890 
	C2006.760986,619.630371 2006.760986,617.803650 2006.760986,615.976868 
	C2006.490112,616.429993 2006.219238,616.883179 2005.948242,617.336304 
	C2003.976440,616.863831 2002.004517,616.391418 1999.779907,615.734558 
	C1996.747681,616.867493 1992.767944,615.574097 1991.175415,619.704590 
	C1990.854004,620.538147 1988.662476,620.650635 1987.333130,621.095520 
	C1987.179688,620.728577 1987.026123,620.361633 1986.872559,619.994690 
	C1988.232178,618.827576 1989.591797,617.660522 1990.951416,616.493469 
	C1990.751465,616.111267 1990.551636,615.729126 1990.351685,615.346985 
	C1988.446533,616.050781 1986.541382,616.754639 1984.616943,617.465637 
	C1981.969849,613.850708 1979.444824,615.586792 1976.551025,618.002808 
	C1973.778931,618.362366 1971.399780,618.668701 1968.945801,618.735352 
	C1968.592773,618.320618 1968.314209,618.145630 1967.986816,617.771057 
	C1967.735840,617.585083 1967.533447,617.598633 1967.331177,617.612183 
	C1967.545044,617.752808 1967.758911,617.893494 1968.067871,618.263245 
	C1968.362671,618.781555 1968.635620,618.956238 1968.920654,619.380310 
	C1967.753540,620.140381 1966.421753,620.281799 1965.606567,621.005310 
	C1964.809937,621.712280 1964.540527,623.013184 1963.606201,624.095520 
	C1960.434448,624.340332 1957.696167,624.542908 1954.618042,624.677002 
	C1951.848511,623.283325 1949.418823,621.958130 1947.150391,620.214111 
	C1952.093506,616.998779 1950.765869,612.950378 1949.853271,608.141785 
	C1948.256104,612.023987 1946.968628,615.153320 1945.505859,618.708801 
	C1942.828979,616.483887 1941.210938,615.138977 1939.409180,613.496460 
	C1936.132446,609.265930 1930.018921,606.883606 1932.697388,600.018921 
	C1937.834106,598.240967 1935.729370,603.289734 1937.401123,606.365601 
	C1937.401123,603.302795 1937.401123,601.514526 1937.401123,600.759766 
	C1932.026855,599.361755 1927.131958,598.088440 1922.056519,596.523926 
	C1919.355225,594.545227 1916.890381,591.514282 1914.303833,591.406738 
	C1904.034546,590.979553 1893.721802,591.308167 1883.436279,591.673096 
	C1877.380737,591.888062 1875.996460,590.192566 1878.048828,583.900940 
	C1882.123413,583.699280 1886.233521,583.495789 1890.343750,583.292297 
	C1890.316406,583.015137 1890.289062,582.737976 1890.261719,582.460815 
	C1885.413940,582.460815 1880.566162,582.460815 1875.671143,582.460815 
	C1874.744385,586.728516 1876.938477,592.957397 1869.094238,591.185303 
	C1869.047119,584.506958 1869.037964,578.267334 1869.028809,572.027710 
	C1889.389771,571.317566 1909.750977,570.607422 1930.584595,569.856812 
M2057.725342,988.088379 
	C2057.985596,987.878296 2058.188965,987.622986 2058.747803,987.065002 
	C2058.972900,986.825562 2059.137451,986.549927 2059.894531,986.058533 
	C2062.075195,983.861877 2064.255859,981.665222 2066.436523,979.468567 
	C2065.998779,979.071960 2065.561279,978.675415 2065.123779,978.278809 
	C2063.028564,980.802124 2060.933350,983.325500 2058.399658,986.059814 
	C2058.119385,986.248901 2057.922363,986.502625 2057.384521,987.063538 
	C2057.108154,987.265320 2056.929199,987.532349 2056.497314,988.117249 
	C2056.538574,988.350403 2056.579834,988.583618 2056.621094,988.816833 
	C2056.837402,988.637512 2057.053467,988.458130 2057.725342,988.088379 
M2054.265869,990.955017 
	C2054.326660,991.223694 2054.387451,991.492371 2054.448730,991.761169 
	C2054.692383,991.560974 2054.936279,991.360840 2055.536133,990.885010 
	C2055.488037,990.639893 2055.440186,990.394714 2055.392090,990.149597 
	C2055.156250,990.325012 2054.920166,990.500488 2054.265869,990.955017 
M2250.959717,792.187988 
	C2251.096191,791.840332 2251.232910,791.492676 2251.369385,791.145020 
	C2250.848633,791.376709 2250.327881,791.608337 2249.376953,792.204895 
	C2249.346436,792.616150 2249.316162,793.027466 2249.285645,793.438843 
	C2249.659912,793.066101 2250.034180,792.693359 2250.959717,792.187988 
M2094.504639,987.206238 
	C2094.089111,987.278687 2093.673584,987.351135 2094.441162,987.217285 
	C2093.899658,987.517151 2094.411377,987.233826 2095.458496,986.715149 
	C2095.978271,986.472717 2096.498047,986.230225 2097.799316,985.889099 
	C2102.393311,984.639709 2107.084717,982.172607 2111.550537,982.528748 
	C2115.082275,982.810364 2118.074219,984.047241 2121.059082,981.841187 
	C2122.809814,982.278137 2124.891113,983.535767 2126.246338,982.990295 
	C2129.619385,981.632874 2132.661865,979.454285 2135.956787,977.535522 
	C2136.172119,979.019348 2136.393555,980.544189 2136.559814,981.690430 
	C2138.568604,981.171692 2140.245850,980.738464 2141.923096,980.305359 
	C2142.504639,982.782776 2142.943604,984.652222 2143.382324,986.521729 
	C2143.653076,986.424927 2143.923828,986.328186 2144.194580,986.231445 
	C2144.194580,982.794556 2144.194580,979.357727 2144.194580,977.604187 
	C2138.761475,976.776123 2134.479736,976.123535 2130.138672,975.461914 
	C2129.827148,980.252869 2127.675293,981.653564 2122.422852,980.508545 
	C2125.598389,977.219482 2128.547119,974.165161 2130.624512,972.013550 
	C2129.284912,972.569641 2126.639893,973.667419 2124.113770,974.801453 
	C2124.113770,974.801453 2124.137939,974.826355 2123.343994,974.931091 
	C2123.117676,975.683777 2122.891113,976.436401 2121.939697,976.667175 
	C2117.295898,976.485596 2112.653076,976.267700 2108.007568,976.169556 
	C2107.446289,976.157654 2106.887451,977.042603 2106.299561,977.080750 
	C2104.394043,977.204163 2102.475342,977.126892 2100.561768,977.126892 
	C2100.488525,976.654907 2100.415039,976.182861 2100.341553,975.710876 
	C2097.825439,977.513184 2096.001709,979.501404 2097.624756,983.068970 
	C2097.966309,983.819702 2097.281250,985.037292 2096.553467,986.313232 
	C2096.027588,986.543518 2095.501953,986.773865 2094.504639,987.206238 
M2246.818848,797.031738 
	C2246.979004,796.394409 2247.139160,795.757080 2247.299316,795.119812 
	C2246.753662,795.638184 2246.207764,796.156555 2245.298096,796.982300 
	C2245.615479,797.077209 2245.932861,797.172119 2246.818848,797.031738 
M2072.027344,740.945251 
	C2072.027344,740.945251 2072.054688,740.917786 2071.813965,740.162659 
	C2069.640869,740.609741 2065.599854,737.263245 2065.564453,743.522034 
	C2063.912109,744.334045 2062.260010,745.146057 2060.607666,745.958069 
	C2059.981201,745.233459 2059.354736,744.508850 2058.728271,743.784241 
	C2060.809814,743.097168 2062.891357,742.410095 2064.972900,741.722961 
	C2064.278320,740.942444 2063.583496,740.161865 2062.888916,739.381287 
	C2061.313721,740.607300 2059.545898,741.664917 2058.256836,743.140808 
	C2057.727539,743.746887 2057.989990,746.121582 2058.304932,746.209167 
	C2060.041260,746.692383 2061.903076,746.739807 2063.725830,746.889099 
	C2064.494873,746.952087 2065.273682,746.898621 2066.503174,747.281555 
	C2067.664307,747.178833 2068.825684,747.076111 2070.680176,746.836731 
	C2071.540771,745.272400 2072.401367,743.708130 2073.184814,741.439819 
	C2072.810303,741.261536 2072.436035,741.083191 2072.027344,740.945251 
M1977.244385,610.299561 
	C1977.244385,610.299561 1977.271240,610.247009 1977.997070,610.008362 
	C1977.839966,608.381653 1977.887329,605.411011 1977.492065,605.350891 
	C1975.267944,605.012817 1972.305542,603.181824 1970.941528,607.727112 
	C1969.271362,608.655457 1967.601318,609.583801 1965.931152,610.512146 
	C1965.657715,610.038818 1965.384277,609.565491 1965.110840,609.092224 
	C1966.480835,607.924438 1967.850952,606.756714 1969.221069,605.588989 
	C1968.946289,605.162292 1968.671509,604.735657 1968.396851,604.308960 
	C1966.779175,605.437073 1964.735229,606.279480 1963.711914,607.805969 
	C1963.144531,608.652466 1964.282715,610.642273 1964.652222,612.116821 
	C1966.399658,611.668884 1968.155640,611.250244 1969.888672,610.752197 
	C1970.317993,610.628723 1970.673340,610.247925 1970.992554,610.682190 
	C1973.255127,614.046692 1975.262329,612.482300 1977.244385,610.299561 
M2243.847656,977.036255 
	C2243.969238,978.492554 2244.090820,979.948914 2244.138184,980.516846 
	C2246.830566,980.289124 2249.243408,980.085144 2251.847656,979.864868 
	C2251.847656,978.782776 2251.847656,976.904297 2251.847656,974.405701 
	C2250.206299,975.544128 2249.052979,976.344177 2248.028076,977.054993 
	C2246.532471,976.283447 2245.264404,975.629211 2243.263184,974.699707 
	C2241.268311,974.472717 2239.273193,974.245728 2237.387451,974.031189 
	C2236.810547,976.253540 2236.408203,977.802795 2235.344727,979.021606 
	C2233.780029,979.334167 2232.215332,979.646790 2230.650879,979.959412 
	C2230.552490,979.640747 2230.454102,979.322021 2230.355713,979.003357 
	C2231.793945,977.750305 2233.232422,976.497192 2234.670654,975.244141 
	C2234.369629,974.843750 2234.068604,974.443359 2233.767578,974.043030 
	C2232.160889,974.946594 2230.554199,975.850159 2228.947510,976.753723 
	C2228.916504,976.311951 2228.885254,975.870239 2228.854248,975.428467 
	C2226.612061,975.527832 2224.369141,975.610046 2222.128418,975.735168 
	C2221.260742,975.783630 2220.005615,976.347351 2219.596436,975.963379 
	C2216.654785,973.201965 2214.493408,974.978638 2212.258301,976.931885 
	C2211.932129,977.216919 2211.416260,977.284424 2210.988037,977.452576 
	C2210.572998,977.791382 2210.158203,978.130249 2209.743164,978.469116 
	C2209.224121,977.061829 2208.704834,975.654541 2208.185791,974.247314 
	C2207.621094,974.269653 2207.056396,974.291992 2206.491455,974.314331 
	C2205.912842,976.608459 2205.334229,978.902527 2204.755859,981.196655 
	C2206.624023,981.194885 2208.492432,981.174194 2210.360107,981.202576 
	C2210.936768,981.211304 2211.509033,981.387512 2212.087402,981.429260 
	C2216.245605,981.730225 2220.513184,982.694580 2224.523438,982.054382 
	C2226.956299,981.665955 2228.774414,981.183411 2231.223145,982.176331 
	C2232.297119,982.611938 2234.334717,980.671814 2236.561768,980.069946 
	C2236.965088,980.565369 2237.368164,981.060791 2237.771484,981.556213 
	C2239.803467,979.794189 2241.835693,978.032166 2243.847656,977.036255 
M2317.856934,867.724304 
	C2315.807861,867.580200 2313.758789,867.436096 2311.809570,867.299011 
	C2311.809570,868.899841 2311.809570,870.889954 2311.809570,873.501404 
	C2313.458252,871.937012 2314.585205,870.867432 2315.699707,869.810059 
	C2316.991699,870.901794 2317.981689,871.738281 2319.454102,873.004944 
	C2321.302734,872.656250 2323.151123,872.307556 2325.676758,872.029358 
	C2328.509277,872.029358 2331.341797,872.029358 2334.119873,872.029358 
	C2333.869873,871.250610 2333.473877,870.109253 2333.139160,868.950195 
	C2332.790283,867.742371 2332.576172,865.506653 2332.171631,865.476379 
	C2330.492676,865.350525 2328.723145,865.751343 2327.071777,866.243530 
	C2326.306152,866.471680 2325.734863,867.351746 2325.063232,867.163330 
	C2322.772705,862.589172 2320.433350,866.917297 2317.856934,867.724304 
M1905.901001,584.873901 
	C1908.416626,586.822693 1909.913940,585.168457 1910.866943,583.156189 
	C1911.296753,582.248657 1910.720703,580.864868 1910.601196,579.697205 
	C1908.806885,580.206543 1907.012695,580.715881 1904.472900,581.152344 
	C1903.979614,581.097412 1903.486450,581.042480 1903.002930,581.002075 
	C1903.002930,581.002075 1903.016846,581.014893 1902.874756,580.366577 
	C1900.539185,576.514893 1897.106079,581.971069 1894.206665,579.165894 
	C1894.254639,581.808411 1894.200195,585.135132 1895.582642,585.915222 
	C1897.531738,587.015015 1900.557495,586.377747 1903.095947,586.163574 
	C1903.913330,586.094543 1904.645630,585.016357 1905.901001,584.873901 
M2340.916016,881.866638 
	C2340.978760,882.020935 2341.011230,882.201477 2341.114258,882.321350 
	C2341.193848,882.414062 2341.429443,882.511963 2341.480225,882.469543 
	C2342.962646,881.227173 2344.430908,879.967468 2346.330322,878.343445 
	C2346.927734,880.406677 2347.198242,881.340698 2347.185791,881.297363 
	C2349.944092,881.297363 2352.342041,881.297363 2354.687256,881.297363 
	C2354.687256,879.993958 2354.687256,878.121216 2354.687256,877.619934 
	C2351.873779,877.047546 2349.666992,876.849670 2347.643066,876.134033 
	C2343.537354,874.682068 2340.562500,876.001770 2340.031982,879.905640 
	C2338.416260,880.356140 2336.800537,880.806641 2335.185059,881.257080 
	C2335.049072,880.968262 2334.913330,880.679443 2334.777344,880.390564 
	C2336.052734,879.252991 2337.327881,878.115356 2338.603271,876.977783 
	C2338.408691,876.591370 2338.214111,876.204895 2338.019775,875.818420 
	C2335.374268,876.793579 2332.728760,877.768799 2330.035400,878.761475 
	C2332.860840,884.838440 2334.791992,885.263123 2340.916016,881.866638 
M1771.488525,669.430176 
	C1771.004150,669.985962 1770.097656,670.545166 1770.102173,671.096924 
	C1770.137817,675.510620 1767.107178,674.605408 1764.972046,675.257568 
	C1764.541870,674.726074 1764.111572,674.194641 1763.681274,673.663147 
	C1765.252441,672.825317 1766.823608,671.987488 1768.873901,670.894043 
	C1764.675171,670.488953 1761.616699,669.614685 1758.813477,670.129456 
	C1756.982544,670.465759 1755.392944,672.714539 1754.052856,674.392273 
	C1753.878540,674.610352 1756.336914,677.669556 1756.739624,677.494019 
	C1760.019287,676.064392 1763.077271,676.546204 1766.403687,677.275574 
	C1768.042847,677.634888 1771.021729,677.015137 1771.670288,675.865601 
	C1774.363892,671.092590 1776.077148,673.615906 1778.004761,676.594421 
	C1778.410522,675.488037 1778.693604,674.716064 1779.709351,674.327515 
	C1781.249878,674.688049 1782.790405,675.048645 1784.558838,675.462585 
	C1784.630249,673.898193 1784.723267,671.859985 1784.859497,668.873596 
	C1782.979736,670.418579 1781.897949,671.307617 1780.853882,672.165710 
	C1779.477173,671.409851 1778.243164,670.732361 1776.386475,669.941711 
	C1775.970459,669.881836 1775.554565,669.821960 1774.389038,669.515442 
	C1773.607056,669.582275 1772.825073,669.649109 1771.488525,669.430176 
M2336.885010,785.977417 
	C2336.776855,789.302002 2337.447510,791.754517 2340.386963,790.945618 
	C2340.824219,787.473633 2341.142090,784.947449 2341.621826,781.138184 
	C2339.542969,782.350769 2338.231934,783.115479 2336.320068,783.587097 
	C2330.897949,783.069580 2325.483643,782.400024 2320.049316,782.130371 
	C2318.487305,782.052917 2316.867676,783.129211 2314.850586,783.822205 
	C2311.536133,781.612000 2309.390869,782.267090 2308.905029,786.217224 
	C2308.406250,790.272583 2310.378174,790.890259 2314.641846,790.322998 
	C2318.438477,789.817810 2319.511963,788.544861 2320.279785,784.449829 
	C2321.447510,786.236389 2322.615234,788.022949 2323.907959,790.000671 
	C2324.457031,788.473511 2325.069580,786.768616 2326.281738,783.396057 
	C2327.384033,786.403320 2328.051758,788.225098 2328.710693,790.022766 
	C2330.984375,790.022766 2333.473633,790.022766 2336.212646,790.022766 
	C2334.402832,788.449951 2333.263428,787.459900 2332.124268,786.469971 
	C2333.702881,786.038086 2335.281494,785.606201 2336.885010,785.977417 
M2301.969238,785.109497 
	C2301.176270,785.020447 2300.383057,784.931458 2298.313965,784.699280 
	C2297.032715,782.962769 2294.288086,781.448914 2290.022705,782.689209 
	C2287.091309,783.541504 2283.795166,783.070740 2280.686279,783.411072 
	C2279.067139,783.588379 2276.833740,783.769287 2276.095703,784.811340 
	C2275.344482,785.871399 2275.485107,788.534424 2276.348633,789.496338 
	C2277.232666,790.481384 2279.577148,790.767700 2281.084961,790.443176 
	C2283.579346,789.906555 2285.915771,788.634399 2288.321533,787.671021 
	C2290.543213,792.648987 2293.130127,792.944824 2297.696777,788.764099 
	C2298.915283,789.418762 2300.110840,790.061035 2301.739014,790.935913 
	C2301.841064,788.787109 2301.910889,787.319153 2301.969238,785.109497 
M2309.899170,839.233276 
	C2311.812012,838.359985 2314.127197,837.863281 2315.456299,836.443481 
	C2316.059082,835.799805 2314.877930,832.015747 2314.207275,831.927490 
	C2312.240234,831.668335 2310.123047,832.548950 2307.756348,833.048218 
	C2304.011475,825.864746 2298.601074,837.091980 2294.061523,832.730896 
	C2294.061523,834.688538 2294.061523,836.612061 2294.061523,839.257812 
	C2295.503662,837.922913 2296.485352,837.014282 2297.306396,836.254089 
	C2298.691895,837.194946 2299.788574,838.490112 2301.055176,838.685913 
	C2303.715820,839.097168 2306.465332,838.930786 2309.899170,839.233276 
M2174.524414,712.026733 
	C2170.739746,712.026733 2166.955078,712.026733 2163.170410,712.026733 
	C2163.200195,712.886108 2163.229980,713.745483 2163.259766,714.604858 
	C2178.330322,715.126221 2193.394043,715.058716 2208.444092,713.808167 
	C2208.448242,713.211121 2208.452393,712.614014 2208.456543,712.016907 
	C2197.476562,712.016907 2186.496338,712.016907 2174.524414,712.026733 
M1889.118530,606.569580 
	C1891.821289,605.531616 1894.524048,604.493652 1896.881348,603.588379 
	C1898.073608,604.917847 1899.217163,606.193115 1900.360840,607.468445 
	C1900.694824,607.202942 1901.028809,606.937439 1901.362793,606.671936 
	C1901.153931,604.938416 1900.945068,603.204956 1900.727417,601.398804 
	C1901.936157,601.029358 1903.217163,600.637817 1904.498169,600.246338 
	C1904.415405,599.670410 1904.332642,599.094421 1904.249878,598.518494 
	C1899.967773,599.152771 1895.686890,599.796265 1891.401611,600.409546 
	C1890.865845,600.486267 1890.298950,600.460144 1889.760620,600.382690 
	C1888.316772,600.174927 1885.816162,599.383362 1885.625854,599.765381 
	C1884.812988,601.397095 1884.164551,603.478516 1884.521606,605.171143 
	C1884.702271,606.027466 1887.150757,606.405579 1889.118530,606.569580 
M2211.201904,967.679871 
	C2209.680420,968.234375 2208.159180,968.788879 2206.637695,969.343445 
	C2206.401367,968.919495 2206.165039,968.495483 2205.928711,968.071533 
	C2207.377197,966.873840 2208.825684,965.676086 2210.274170,964.478394 
	C2209.946289,964.050903 2209.618408,963.623413 2209.290527,963.195923 
	C2207.700439,964.472046 2205.846436,965.546326 2204.644043,967.118958 
	C2204.129639,967.791443 2204.767578,970.468140 2205.238281,970.568787 
	C2206.945801,970.934082 2208.811523,970.465820 2210.603271,970.566345 
	C2211.743652,970.630310 2213.125488,971.719543 2213.933838,971.344788 
	C2216.138672,970.322876 2218.177002,968.862183 2220.029297,967.280518 
	C2220.216797,967.120422 2218.879395,964.092163 2217.929443,963.888428 
	C2215.292236,963.322815 2212.090332,962.831848 2211.201904,967.679871 
M2102.799561,965.539368 
	C2100.940918,966.402527 2098.917480,967.049744 2097.326660,968.263794 
	C2096.864990,968.616150 2097.294678,971.389221 2097.920654,971.661438 
	C2099.352539,972.283997 2101.160889,972.014221 2102.810303,972.171692 
	C2103.968994,972.282349 2105.223145,972.877808 2106.248291,972.590820 
	C2108.344238,972.004150 2110.475830,971.206055 2112.255127,969.998230 
	C2112.942871,969.531189 2113.187988,966.864563 2112.823975,966.662598 
	C2111.251709,965.790649 2109.389404,965.419128 2107.607910,964.963257 
	C2107.176758,964.853027 2106.650146,965.115967 2105.941650,965.250000 
	C2105.108643,968.787537 2103.443359,971.385254 2097.934570,969.720764 
	C2100.030273,968.228882 2101.384521,967.264709 2102.799561,965.539368 
M1743.918091,659.453735 
	C1741.996582,660.850037 1738.699463,662.030396 1740.826050,664.928162 
	C1741.708984,666.131287 1744.967407,665.818970 1747.140625,665.792480 
	C1750.151733,665.755615 1753.158447,665.338379 1756.533936,665.052979 
	C1756.360596,664.525452 1756.151001,663.640991 1755.785034,662.826721 
	C1755.095093,661.291992 1754.315308,659.797546 1753.572876,658.286316 
	C1752.006226,659.297546 1750.462769,660.346924 1748.866943,661.309998 
	C1747.032471,662.417114 1745.151733,663.447876 1743.291138,664.511780 
	C1742.998169,664.020691 1742.705200,663.529602 1742.412231,663.038452 
	C1743.861328,661.980530 1745.310425,660.922546 1747.626221,659.231934 
	C1745.833008,659.231934 1745.213013,659.231934 1743.918091,659.453735 
M2278.933838,713.939392 
	C2279.760254,713.726257 2280.586670,713.513184 2281.413086,713.300049 
	C2281.365479,713.091797 2281.317627,712.883484 2281.269775,712.675171 
	C2266.091797,712.675171 2250.913574,712.675171 2235.735352,712.675171 
	C2235.738525,713.096497 2235.741699,713.517761 2235.744629,713.939087 
	C2249.846191,713.939087 2263.947754,713.939087 2278.933838,713.939392 
M2120.495117,713.033752 
	C2110.545410,713.033752 2100.595703,713.033752 2090.646240,713.033752 
	C2090.656250,713.437744 2090.666260,713.841736 2090.676270,714.245728 
	C2105.558838,714.245728 2120.441650,714.245728 2135.324219,714.245728 
	C2135.324219,713.849731 2135.324463,713.453796 2135.324463,713.057800 
	C2130.713867,713.057800 2126.103271,713.057800 2120.495117,713.033752 
M2332.861816,902.978516 
	C2331.700928,903.160339 2330.539795,903.342224 2329.378906,903.524048 
	C2329.423096,903.795593 2329.467285,904.067078 2329.511475,904.338623 
	C2344.146240,904.338623 2358.781006,904.338623 2373.415771,904.338623 
	C2373.413818,903.879456 2373.411865,903.420288 2373.409912,902.961121 
	C2360.199951,902.961121 2346.989990,902.961121 2332.861816,902.978516 
M2336.669678,887.943726 
	C2333.902100,887.372559 2331.134521,886.801392 2327.273682,886.004639 
	C2329.052002,889.297424 2329.948486,890.956787 2330.854248,892.633911 
	C2331.301270,891.340698 2331.762207,890.006409 2332.699951,887.292603 
	C2334.607666,889.843750 2335.879639,891.545105 2336.934082,892.955261 
	C2337.953613,891.560730 2339.031738,890.086060 2340.645020,887.879150 
	C2341.381592,890.494873 2341.771484,891.879944 2342.044189,892.848206 
	C2343.320068,892.045044 2344.562500,891.411011 2345.621826,890.551270 
	C2346.536621,889.808655 2347.243164,888.809387 2348.041992,887.923523 
	C2346.875732,887.318298 2345.659180,886.131287 2344.553955,886.227478 
	C2342.162354,886.435425 2339.827881,887.300720 2336.669678,887.943726 
M1950.776733,596.295349 
	C1950.023804,596.045471 1948.634644,595.524536 1948.609131,595.584534 
	C1947.224731,598.834106 1944.928955,599.752869 1941.483643,599.158691 
	C1940.851440,599.049683 1939.192871,601.354248 1939.100220,602.624390 
	C1939.003296,603.953430 1940.058472,606.441833 1940.896606,606.571533 
	C1943.988525,607.049866 1947.198975,606.761597 1950.902832,606.761597 
	C1950.902832,603.344482 1950.902832,600.233093 1950.776733,596.295349 
M2314.649658,844.728699 
	C2315.360840,844.121826 2316.072021,843.514893 2316.783203,842.908020 
	C2315.250488,842.415527 2314.191650,842.642212 2313.275146,843.134277 
	C2312.338867,843.637146 2311.541748,844.400208 2311.447266,844.471802 
	C2309.460693,843.411804 2308.138184,842.162964 2306.833252,842.181274 
	C2305.783691,842.195984 2303.956055,843.696350 2303.900635,844.622681 
	C2303.812012,846.108215 2304.748291,848.884521 2305.521973,848.982727 
	C2309.185791,849.447693 2312.945801,849.273499 2316.659912,849.090210 
	C2317.165527,849.065369 2317.605469,847.705811 2318.288330,846.629272 
	C2314.533936,847.629028 2314.533936,847.629028 2314.649658,844.728699 
M2285.119141,836.789062 
	C2287.593506,836.400269 2290.068115,836.011475 2292.542480,835.622742 
	C2292.493408,835.123657 2292.444336,834.624573 2292.395264,834.125488 
	C2283.788086,834.235413 2275.161377,833.677979 2266.574707,834.486389 
	C2266.572266,835.253967 2266.569580,836.021484 2266.566895,836.789062 
	C2272.441650,836.789062 2278.316650,836.789062 2285.119141,836.789062 
M2389.096191,909.554565 
	C2386.132568,910.799683 2383.159668,912.089417 2381.394287,907.055664 
	C2379.220703,911.894409 2380.682861,914.985168 2382.308350,917.131714 
	C2386.660400,914.431458 2390.154297,912.263428 2394.936768,909.296143 
	C2392.224854,909.296143 2391.024658,909.296143 2389.096191,909.554565 
M2120.984375,658.238159 
	C2122.289795,661.367249 2125.830811,662.454773 2129.490967,661.379700 
	C2129.029785,657.507263 2128.597168,653.873230 2128.124756,649.904907 
	C2124.897949,652.115417 2126.938965,658.289124 2120.984375,658.238159 
M1950.983887,638.743530 
	C1950.760864,637.623352 1950.537964,636.503174 1950.314941,635.382996 
	C1949.727661,635.394409 1949.140381,635.405884 1948.552979,635.417358 
	C1948.361328,638.008179 1948.170166,640.599121 1947.978027,643.189880 
	C1947.811401,645.437927 1948.260864,647.167542 1951.087646,646.939331 
	C1953.434937,646.749817 1956.538208,647.700745 1956.464478,643.910034 
	C1956.016724,644.056580 1955.568970,644.203125 1955.121216,644.349670 
	C1953.749756,642.755920 1952.378174,641.162231 1950.983887,638.743530 
M2349.898682,822.195801 
	C2347.361084,822.243347 2344.823486,822.290955 2341.833008,822.347046 
	C2343.699707,825.520630 2345.243652,828.145447 2346.527832,830.328796 
	C2349.405762,827.184143 2352.013428,824.334656 2355.512207,820.511658 
	C2353.139893,821.172363 2351.849854,821.531616 2349.898682,822.195801 
M2212.606934,690.812317 
	C2211.115967,691.297546 2209.625244,691.782837 2206.572998,692.776245 
	C2210.645020,694.855713 2213.321777,696.222717 2215.566162,697.368896 
	C2216.050049,693.007629 2216.421143,689.663635 2216.791992,686.319641 
	C2216.531250,686.213013 2216.270508,686.106384 2216.009521,685.999756 
	C2214.990967,687.404297 2213.972412,688.808838 2212.606934,690.812317 
M2329.266357,847.062683 
	C2330.034668,847.542114 2330.802734,848.021484 2331.868164,848.686279 
	C2331.868164,846.337585 2331.868164,844.393005 2331.868164,841.985413 
	C2330.465088,842.907776 2329.414062,843.598633 2328.774902,844.018677 
	C2326.471680,843.194702 2324.470215,842.478638 2321.270508,841.333923 
	C2323.260986,844.577698 2324.476562,846.559143 2325.632568,848.442871 
	C2326.564697,848.033936 2327.551025,847.601257 2329.266357,847.062683 
M2381.983643,866.420715 
	C2386.554443,864.408020 2391.125244,862.395325 2395.696045,860.382629 
	C2395.496826,859.932861 2395.297607,859.483154 2395.098389,859.033386 
	C2388.216064,861.928406 2381.333984,864.823486 2374.451904,867.718506 
	C2374.673340,868.236084 2374.894775,868.753601 2375.115967,869.271179 
	C2377.168457,868.418213 2379.220947,867.565308 2381.983643,866.420715 
M2356.344238,842.879578 
	C2354.768311,836.066345 2353.325928,834.059326 2349.609863,833.508057 
	C2349.825684,838.045654 2352.559814,842.348206 2356.344238,842.879578 
M2139.384033,661.281006 
	C2135.333496,660.246704 2135.333496,660.246704 2134.250732,663.556030 
	C2137.724365,664.441589 2141.133301,665.310669 2144.542236,666.179749 
	C2144.822754,665.425049 2145.103516,664.670349 2145.384033,663.915588 
	C2143.624268,663.135498 2141.864746,662.355408 2139.384033,661.281006 
M2072.519287,958.882812 
	C2069.181396,956.989258 2068.015625,959.568298 2067.272949,961.840027 
	C2067.058594,962.495422 2068.999512,964.759827 2069.770264,964.672852 
	C2072.754639,964.336365 2074.349365,962.559143 2072.519287,958.882812 
M1989.772217,646.870911 
	C1992.317749,646.410156 1996.042847,648.296387 1997.249756,643.045288 
	C1993.975098,643.045288 1991.412598,642.597961 1989.094971,643.160034 
	C1986.283813,643.841858 1985.790649,645.453491 1989.772217,646.870911 
M2368.016846,872.491821 
	C2369.628906,871.723877 2371.240723,870.955933 2372.852783,870.187927 
	C2372.616699,869.694824 2372.380615,869.201721 2372.144531,868.708618 
	C2367.093018,870.848389 2362.041748,872.988220 2356.990234,875.128052 
	C2357.186035,875.688049 2357.381836,876.248108 2357.577637,876.808167 
	C2360.832764,875.491760 2364.088135,874.175293 2368.016846,872.491821 
M2063.471924,961.987671 
	C2062.299805,962.898254 2061.127686,963.808899 2059.955811,964.719482 
	C2060.088379,965.059509 2060.221191,965.399536 2060.353760,965.739502 
	C2062.037109,965.664734 2063.720459,965.589905 2065.051758,965.530762 
	C2065.412598,963.520752 2065.873535,962.019531 2065.913330,960.507202 
	C2065.994385,957.442444 2063.183838,957.100830 2060.340820,959.736938 
	C2061.484619,960.271912 2062.660400,960.821960 2063.471924,961.987671 
M2340.762939,818.752197 
	C2341.139893,817.849609 2342.077148,816.685059 2341.798584,816.089355 
	C2340.505859,813.325623 2338.875732,810.719788 2337.253906,807.858398 
	C2335.030029,810.957520 2335.406006,812.156494 2340.762939,818.752197 
M1965.403564,646.797302 
	C1966.911987,646.372620 1968.420410,645.947876 1970.738770,645.295044 
	C1965.467651,642.168640 1962.514648,642.493958 1960.206787,646.191589 
	C1961.834717,646.423218 1963.218994,646.620117 1965.403564,646.797302 
M2197.016846,687.358582 
	C2199.400146,688.650757 2201.783691,689.942871 2204.166992,691.234985 
	C2204.464600,690.713074 2204.762207,690.191223 2205.059814,689.669312 
	C2204.022949,685.886414 2200.210693,685.633972 2197.301025,684.233215 
	C2197.025879,685.105774 2196.750488,685.978271 2197.016846,687.358582 
M2378.268311,903.188965 
	C2380.614014,904.566650 2381.260498,903.292297 2380.822998,901.289612 
	C2380.220703,898.534485 2379.260254,895.857666 2378.272461,892.539307 
	C2374.265869,896.452271 2377.692871,899.432312 2378.268311,903.188965 
M2277.905518,738.462646 
	C2277.066162,738.127075 2276.227051,737.791565 2275.387695,737.456055 
	C2275.261963,738.231323 2274.745850,739.333130 2275.075684,739.727295 
	C2276.844482,741.841553 2278.803467,743.800720 2280.784912,745.722595 
	C2280.896240,745.830627 2281.791260,745.130920 2282.321533,744.807251 
	C2281.129639,742.988831 2279.939453,741.169373 2278.742920,739.354309 
	C2278.653076,739.218384 2278.515869,739.113892 2277.905518,738.462646 
M2387.814941,925.761353 
	C2387.231201,924.168518 2386.647461,922.575684 2385.916016,920.579529 
	C2382.268799,924.246216 2385.319336,927.239380 2385.734131,930.226624 
	C2385.816162,930.817932 2387.080078,931.245239 2387.798828,931.748169 
	C2388.156250,930.954895 2388.782227,930.167847 2388.800537,929.366699 
	C2388.822510,928.423096 2388.318359,927.467224 2387.814941,925.761353 
M2116.497803,657.565125 
	C2114.612061,653.191528 2110.641113,654.428894 2107.213135,654.134888 
	C2108.878174,658.149902 2112.452148,657.832825 2116.497803,657.565125 
M2253.002686,977.950195 
	C2253.489502,980.826721 2251.559082,984.349609 2255.918213,987.290833 
	C2255.918213,983.511475 2255.991455,980.627991 2255.798340,977.762451 
	C2255.780029,977.491455 2254.087158,977.333435 2253.002686,977.950195 
M2263.997803,776.515564 
	C2261.958496,778.700439 2259.919189,780.885254 2257.879883,783.070129 
	C2258.327637,783.473877 2258.775391,783.877563 2259.223145,784.281311 
	C2262.676514,780.695251 2266.130127,777.109192 2269.583496,773.523132 
	C2269.173340,773.123779 2268.763184,772.724487 2268.352783,772.325134 
	C2267.064453,773.554688 2265.775879,774.784302 2263.997803,776.515564 
M2345.514404,913.589172 
	C2348.024658,912.713684 2350.534668,911.838257 2353.044922,910.962769 
	C2352.821045,910.239136 2352.597168,909.515442 2352.373291,908.791809 
	C2349.480469,909.554382 2346.587891,910.317017 2343.695312,911.079529 
	C2344.058838,911.942383 2344.422363,912.805176 2345.514404,913.589172 
M1744.971802,676.006531 
	C1748.676758,675.870178 1748.676758,675.870178 1746.117798,669.893311 
	C1744.257202,671.450623 1742.491455,672.928589 1739.922241,675.079041 
	C1742.141479,675.410645 1743.204468,675.569458 1744.971802,676.006531 
M1974.226196,646.342896 
	C1977.166138,646.083008 1980.106201,645.823181 1983.046143,645.563354 
	C1982.929565,644.853271 1982.812988,644.143188 1982.696533,643.433044 
	C1979.682861,644.290588 1975.785156,640.090942 1974.226196,646.342896 
M2142.970215,963.852966 
	C2141.757080,967.263184 2141.138184,970.587891 2143.463623,973.488586 
	C2144.157715,973.386597 2144.851562,973.284668 2145.545654,973.182678 
	C2144.927002,969.966370 2144.308105,966.750061 2142.970215,963.852966 
M2225.998047,700.552917 
	C2224.216309,699.709534 2222.434326,698.866150 2220.652588,698.022705 
	C2220.313965,698.656250 2219.975342,699.289734 2219.636719,699.923218 
	C2222.531982,701.643127 2225.427246,703.362976 2228.322266,705.082886 
	C2228.699463,704.578003 2229.076660,704.073181 2229.453613,703.568298 
	C2228.521729,702.682922 2227.589844,701.797546 2225.998047,700.552917 
M2135.063477,629.513733 
	C2135.372070,627.756470 2135.680420,625.999207 2135.989014,624.241943 
	C2135.228516,624.100891 2134.468018,623.959778 2133.707764,623.818665 
	C2133.086670,626.923767 2132.465576,630.028870 2131.844727,633.133972 
	C2132.483887,633.297913 2133.123047,633.461792 2133.762207,633.625732 
	C2134.157471,632.518738 2134.552734,631.411682 2135.063477,629.513733 
M2255.329590,971.685974 
	C2255.508057,968.559875 2255.686279,965.433777 2255.864502,962.307739 
	C2255.083984,962.306213 2254.303223,962.304749 2253.522461,962.303284 
	C2253.148193,965.495667 2251.303711,968.688293 2255.329590,971.685974 
M2254.538818,990.071777 
	C2251.701416,993.295044 2253.009766,996.496582 2253.742432,999.701172 
	C2254.476807,999.705688 2255.211426,999.710144 2255.945801,999.714600 
	C2255.751221,996.580139 2255.556396,993.445679 2254.538818,990.071777 
M2369.865723,810.162354 
	C2370.022461,810.917786 2369.996094,812.212097 2370.366943,812.338196 
	C2372.282227,812.989868 2377.070312,808.892212 2377.448242,805.761902 
	C2374.867432,807.292114 2372.683838,808.586914 2369.865723,810.162354 
M2284.985840,810.174011 
	C2282.302246,811.727783 2279.618408,813.281555 2276.934570,814.835266 
	C2277.332764,815.440552 2277.730713,816.045837 2278.128906,816.651123 
	C2281.641113,816.172546 2283.743164,813.610657 2284.985840,810.174011 
M2184.526123,754.047546 
	C2186.210693,751.437927 2187.895020,748.828308 2189.579590,746.218689 
	C2188.720703,745.788940 2187.861572,745.359192 2187.002686,744.929443 
	C2185.941406,747.925476 2184.879883,750.921509 2184.526123,754.047546 
M2199.506348,724.153198 
	C2198.351074,726.174744 2197.196045,728.196350 2196.040771,730.217896 
	C2196.605469,730.577942 2197.169922,730.937988 2197.734619,731.298035 
	C2199.561523,728.637146 2201.388428,725.976196 2203.215332,723.315308 
	C2202.885986,723.002075 2202.556396,722.688782 2202.227051,722.375549 
	C2201.475342,722.794556 2200.723389,723.213562 2199.506348,724.153198 
M2131.463135,642.962097 
	C2131.927246,641.073853 2132.391113,639.185608 2132.855225,637.297363 
	C2132.140137,637.089355 2131.424805,636.881348 2130.709717,636.673340 
	C2129.772949,639.576843 2128.835938,642.480347 2127.899170,645.383850 
	C2128.379639,645.649719 2128.859863,645.915588 2129.340088,646.181519 
	C2129.961426,645.351379 2130.582764,644.521301 2131.463135,642.962097 
M2046.565552,998.529114 
	C2048.069092,997.799622 2049.572754,997.070068 2051.076416,996.340576 
	C2050.731689,995.834717 2050.387207,995.328857 2050.042480,994.823059 
	C2048.956055,995.860107 2047.869629,996.897156 2046.565552,998.529114 
M2327.763672,879.525574 
	C2327.697021,879.377930 2327.630371,879.230347 2327.563721,879.082703 
	C2327.444092,879.363525 2327.360596,879.667114 2327.198975,879.921265 
	C2326.610352,880.847656 2325.995361,881.757446 2325.390381,882.673462 
	C2325.827637,882.855164 2326.264893,883.036865 2326.702148,883.218567 
	C2327.085205,882.169128 2327.468506,881.119690 2327.763672,879.525574 
M2242.716064,800.638611 
	C2241.404541,801.258362 2240.092773,801.878113 2238.781250,802.497864 
	C2239.142822,803.010681 2239.504150,803.523499 2239.865723,804.036316 
	C2240.801514,803.132019 2241.737549,802.227661 2242.716064,800.638611 
M2366.758057,991.472900 
	C2366.165283,991.108704 2365.572266,990.744507 2364.979492,990.380249 
	C2364.854492,990.650146 2364.729492,990.920044 2364.604492,991.190002 
	C2365.160156,991.414551 2365.715576,991.639160 2366.758057,991.472900 
M2087.482422,956.157654 
	C2088.083252,956.101379 2088.684082,956.045105 2089.284912,955.988831 
	C2089.236572,955.733032 2089.188232,955.477173 2089.140137,955.221313 
	C2088.607178,955.373657 2088.074219,955.526001 2087.482422,956.157654 
M2255.695557,787.603088 
	C2255.202637,787.642395 2254.709961,787.681702 2254.217041,787.721069 
	C2254.289062,788.001099 2254.361328,788.281128 2254.433594,788.561096 
	C2254.828857,788.445007 2255.223877,788.328918 2255.695557,787.603088 
M2052.507812,993.556335 
	C2052.530273,993.184753 2052.552979,992.813171 2052.575439,992.441589 
	C2052.427734,992.705933 2052.279785,992.970276 2052.507812,993.556335 
M2063.515625,999.735046 
	C2063.567383,999.802734 2063.619141,999.870422 2063.515625,999.735046 
M2045.443237,1000.649170 
	C2045.443237,1000.649170 2045.577515,1000.375000 2045.577515,1000.375000 
	C2045.577515,1000.375000 2045.282349,1000.431213 2045.443237,1000.649170 
M2225.554443,747.347107 
	C2225.554443,747.347107 2225.443359,747.555176 2225.443359,747.555176 
	C2225.443359,747.555176 2225.687988,747.517578 2225.554443,747.347107 
M2363.475098,988.520752 
	C2363.475098,988.520752 2363.516846,988.493164 2363.475098,988.520752 
M2237.527344,804.764587 
	C2237.527344,804.764587 2237.686768,804.433533 2237.686768,804.433533 
	C2237.686768,804.433533 2237.320312,804.506104 2237.527344,804.764587 
M2256.275879,785.524597 
	C2256.452148,785.547424 2256.628418,785.570251 2256.804688,785.593140 
	C2256.728027,785.491577 2256.651367,785.390015 2256.275879,785.524597 
M2252.538818,789.825439 
	C2252.631348,789.748108 2252.788330,789.677856 2252.797607,789.591553 
	C2252.807129,789.507751 2252.664307,789.407471 2252.588379,789.314392 
	C2252.495605,789.391174 2252.403076,789.467957 2252.538818,789.825439 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1814.984009,903.015381 
	C1808.599121,902.537537 1802.187500,902.256470 1795.854614,901.395752 
	C1794.745850,901.245117 1793.790894,898.737061 1793.096069,897.170837 
	C1792.572266,895.989990 1792.577148,894.574585 1792.277954,892.871948 
	C1791.184204,893.536743 1790.546753,893.692871 1790.431763,894.028320 
	C1787.791992,901.720093 1787.802246,901.718445 1779.512573,902.074036 
	C1778.858887,902.102051 1778.211060,902.270813 1777.271729,902.669373 
	C1767.919678,903.332214 1758.856445,903.699768 1749.144897,904.075073 
	C1748.496582,904.082764 1748.081909,904.147766 1748.081909,904.147766 
	C1746.330933,904.111572 1744.579834,904.075378 1742.076172,904.050659 
	C1740.902222,904.114563 1740.481079,904.166931 1740.059814,904.219238 
	C1736.997070,904.166138 1733.934448,904.113037 1730.412354,904.104614 
	C1724.278687,903.729248 1718.604370,903.309143 1712.445312,902.560547 
	C1711.933594,902.066711 1711.906494,901.901367 1711.879272,901.735962 
	C1711.467285,899.265503 1711.055420,896.795105 1710.318970,893.713379 
	C1709.941528,892.247192 1709.888794,891.392273 1710.041016,890.339600 
	C1710.797485,889.385620 1711.349121,888.629272 1712.336548,887.872192 
	C1714.393066,887.729858 1716.014038,887.588196 1717.560913,887.453003 
	C1718.940674,878.249695 1718.975342,878.232971 1728.646118,877.987061 
	C1729.791748,877.957886 1730.931763,877.713684 1732.518555,877.665039 
	C1750.637329,877.485962 1768.311768,877.211060 1786.385376,877.010864 
	C1787.861328,877.035095 1788.938354,876.984619 1790.384033,876.988281 
	C1791.493164,876.983582 1792.233521,876.924805 1793.420166,876.955444 
	C1796.595825,876.926697 1799.325317,876.808533 1802.487793,876.765381 
	C1805.262451,876.754761 1807.604004,876.669128 1810.337402,876.708252 
	C1811.528198,876.708557 1812.327026,876.584167 1813.537476,876.552246 
	C1830.298828,876.422791 1846.648804,876.200867 1863.250244,876.024536 
	C1863.668213,876.032898 1863.834595,875.995667 1864.398193,876.023315 
	C1865.865356,876.050354 1866.935303,876.012573 1868.297852,876.238586 
	C1869.258667,878.833618 1869.926880,881.164795 1871.036133,885.034668 
	C1866.286255,884.026733 1863.230957,883.282227 1860.137085,882.772644 
	C1859.014404,882.587830 1857.727539,882.758240 1856.645142,883.137085 
	C1855.338745,883.594238 1854.170288,884.445251 1852.812256,884.792297 
	C1850.942627,881.423828 1848.866089,881.109558 1846.228516,883.345764 
	C1845.182861,883.305237 1843.738525,883.458923 1843.623901,883.933533 
	C1843.388550,884.908020 1843.865356,886.054565 1844.027344,887.447754 
	C1843.975342,888.188477 1843.945190,888.611694 1843.695557,889.224976 
	C1843.558105,889.791809 1843.640259,890.168518 1843.722534,890.545288 
	C1844.210815,890.135559 1844.699097,889.725830 1845.600098,889.386353 
	C1849.163086,890.506409 1851.791138,890.202942 1853.545166,886.905884 
	C1856.136353,889.068604 1858.249023,890.880798 1860.777100,887.299194 
	C1861.321411,886.528259 1863.075562,886.183838 1864.186890,886.323364 
	C1867.152954,886.695618 1870.072632,887.438049 1873.215576,888.074829 
	C1873.575439,884.262024 1873.954834,880.241760 1874.699707,876.216675 
	C1876.043579,876.140198 1877.021973,876.068481 1878.320801,876.165222 
	C1887.551514,876.193115 1896.463013,876.101929 1905.371826,875.894592 
	C1911.253662,875.757812 1917.131592,875.454163 1923.476807,875.283081 
	C1935.949341,875.192932 1947.956543,875.044983 1960.233398,874.956665 
	C1960.682007,874.972351 1960.860962,874.928528 1961.402832,874.970764 
	C1962.506714,875.026489 1963.247314,874.996033 1964.420898,875.050354 
	C1966.904541,875.078613 1968.955200,875.022034 1971.418457,875.029297 
	C1973.218628,875.050354 1974.606323,875.007629 1976.457031,875.046997 
	C1984.218628,875.185669 1991.516968,875.242371 1998.750732,875.621338 
	C1998.611328,876.596069 1998.536499,877.248413 1998.331787,878.274719 
	C1998.039429,880.079285 1997.876709,881.509888 1997.706421,883.007263 
	C1994.585083,883.007263 1991.791260,883.007263 1988.855713,882.678162 
	C1985.673340,879.270325 1984.336548,882.871521 1982.275024,883.911987 
	C1979.689819,885.216736 1978.772705,878.996399 1975.260742,882.117004 
	C1974.189941,881.456726 1973.434692,880.953735 1972.679565,880.450745 
	C1972.470825,880.660583 1972.262085,880.870422 1972.053467,881.080261 
	C1972.759521,882.413025 1973.465454,883.745789 1974.146729,885.446045 
	C1974.976196,890.344421 1977.614746,889.215027 1980.902588,887.831421 
	C1982.192993,887.845825 1982.931519,887.805603 1984.012695,887.803711 
	C1985.894043,886.871887 1987.432617,885.901794 1989.421753,884.818848 
	C1993.131592,884.705933 1996.390869,884.705933 2000.097656,884.705933 
	C2000.097656,882.031860 2000.097656,880.064209 2000.431396,877.925293 
	C2002.128296,876.833069 2003.473022,875.149658 2004.857544,875.116089 
	C2013.239136,874.913086 2021.629761,875.086731 2030.089600,875.298340 
	C2030.289673,875.670044 2030.462402,875.829773 2030.679688,875.941223 
	C2030.679688,883.697388 2030.679688,891.453491 2030.679688,899.293945 
	C2027.325073,899.889893 2024.046997,900.472229 2020.603760,901.444458 
	C2020.438599,901.834290 2020.424683,901.808594 2020.424683,901.808594 
	C2019.882935,901.812988 2019.341309,901.817383 2018.110840,901.616394 
	C2010.626221,901.561462 2003.830322,901.711914 1997.034546,901.862427 
	C1996.575562,901.851501 1996.116699,901.840637 1994.994873,901.564453 
	C1987.224121,901.519592 1980.116211,901.740112 1973.008301,901.960571 
	C1969.056274,901.381287 1965.115723,900.694519 1961.146973,900.271606 
	C1959.375977,900.082886 1957.548462,900.424622 1955.200684,900.556213 
	C1954.642944,897.562805 1954.073242,894.505920 1953.503540,891.449097 
	C1952.399048,900.195679 1946.730957,901.816345 1939.286865,901.698975 
	C1923.957886,902.023987 1908.919434,902.056091 1893.410400,902.155518 
	C1888.522461,901.858521 1884.096436,901.133362 1879.689819,901.236633 
	C1875.708496,901.329956 1873.233154,900.778809 1873.813110,896.000671 
	C1873.957642,894.809082 1873.185913,893.506348 1872.835327,892.254761 
	C1872.333984,892.303223 1871.832642,892.351685 1871.331421,892.400146 
	C1871.097656,895.043762 1870.863892,897.687378 1870.604370,900.624146 
	C1866.378296,901.099670 1862.188477,901.571106 1857.120850,902.053101 
	C1849.158936,902.349121 1842.075073,902.634644 1834.617310,902.928101 
	C1833.495728,902.957703 1832.747803,902.979431 1831.753784,902.729126 
	C1825.999756,902.643250 1820.491821,902.829285 1814.984009,903.015381 
M1953.923950,886.959290 
	C1953.923950,883.953796 1953.923950,880.948303 1953.923950,877.517639 
	C1950.024780,880.879028 1950.029175,885.983826 1953.923950,886.959290 
M1789.903809,882.748169 
	C1790.081665,885.035400 1790.259644,887.322632 1790.437500,889.609924 
	C1791.093872,889.611267 1791.750244,889.612671 1792.406616,889.614014 
	C1792.536011,886.846252 1792.665283,884.078552 1792.794678,881.310852 
	C1791.841553,881.504517 1790.888550,881.698181 1789.903809,882.748169 
M1917.334717,880.583069 
	C1917.469482,880.051086 1917.613037,879.520630 1917.706055,878.981384 
	C1917.709595,878.960266 1917.229004,878.855469 1916.973877,878.789673 
	C1916.908203,879.400269 1916.842529,880.010864 1917.334717,880.583069 
M1972.482422,888.596924 
	C1972.482422,888.596924 1972.408325,888.476501 1972.482422,888.596924 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1998.815430,875.299072 
	C1991.516968,875.242371 1984.218628,875.185669 1976.455688,874.805542 
	C1975.961670,874.160522 1975.958862,873.838623 1976.437988,873.404907 
	C1981.353271,873.195679 1985.829834,873.299805 1990.268066,872.936584 
	C1993.832031,872.644897 1997.349487,871.786682 2001.673584,871.045044 
	C2004.914673,873.859497 2010.653931,873.603821 2013.448120,869.071289 
	C2014.290039,867.705688 2012.836792,864.925232 2012.471802,862.988159 
	C2013.206421,861.231445 2014.089966,859.118530 2015.239502,857.003052 
	C2015.682861,856.998535 2015.860107,856.996399 2016.148193,857.377441 
	C2016.473999,859.176392 2016.688965,860.592224 2017.104126,863.325012 
	C2018.610352,860.938660 2019.241699,859.938293 2019.873169,858.937927 
	C2020.560791,860.796387 2020.590576,863.427307 2019.941528,863.607056 
	C2014.939575,864.992554 2017.111694,869.429626 2015.213501,872.543701 
	C2020.727783,872.543701 2025.591675,872.543701 2031.035767,872.543701 
	C2031.035767,867.498535 2031.035767,862.755493 2031.312256,858.316956 
	C2031.816895,863.008240 2032.044922,867.395081 2032.326782,872.815186 
	C2033.575684,871.309204 2034.082397,870.698181 2034.408813,870.304626 
	C2035.145996,871.211182 2035.891724,872.128113 2036.734985,873.390808 
	C2038.838867,878.369568 2045.163696,880.571594 2044.213379,887.252319 
	C2044.250000,888.156677 2044.295532,888.672607 2044.230957,889.628723 
	C2044.120605,894.637817 2044.120361,899.206787 2044.092163,904.211670 
	C2044.114990,905.227844 2044.165771,905.808044 2044.219727,906.828979 
	C2044.230835,912.024780 2044.238525,916.780029 2044.200195,921.922607 
	C2045.109497,922.868713 2046.064819,923.427429 2047.010010,923.993042 
	C2047.000000,924.000000 2047.013672,923.979553 2046.996948,924.278809 
	C2046.971436,924.977051 2046.962646,925.375916 2046.580566,925.917908 
	C2045.577148,927.134277 2044.495728,928.171387 2044.406250,929.288086 
	C2044.187378,932.017822 2044.427490,934.784302 2044.204346,937.808228 
	C2043.749878,938.271484 2043.574097,938.462402 2043.398315,938.653320 
	C2043.008301,936.612854 2042.641968,934.567566 2042.222412,932.533203 
	C2041.711426,930.056030 2041.148804,927.589539 2040.623291,925.115356 
	C2040.492188,924.498108 2040.278442,923.855286 2040.337891,923.247253 
	C2040.868408,917.816406 2041.451904,912.390686 2041.994751,906.499390 
	C2042.003784,905.583618 2042.037842,905.131836 2042.054443,903.876099 
	C2041.946777,898.449829 2041.856689,893.827515 2041.766479,889.205261 
	C2041.781128,888.974792 2041.795898,888.744324 2041.855713,887.783569 
	C2040.616943,884.335693 2039.333252,881.617981 2038.049561,878.900330 
	C2035.920410,877.613647 2033.791382,876.327026 2031.054565,875.012939 
	C2030.446899,874.985535 2030.017456,875.134521 2030.017456,875.134521 
	C2021.629761,875.086731 2013.239136,874.913086 2004.857544,875.116089 
	C2003.473022,875.149658 2002.128296,876.833069 2000.370117,877.863159 
	C1999.975098,877.972290 1999.853638,877.847229 1999.920776,877.549438 
	C1999.845215,876.095337 1999.702515,874.939026 1999.559814,873.782654 
	C1999.311646,874.288086 1999.063477,874.793579 1998.815430,875.299072 
M2013.863647,872.503906 
	C2013.863647,872.503906 2013.491333,872.371765 2013.491333,872.371765 
	C2013.491333,872.371765 2013.595581,872.697998 2013.863647,872.503906 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2034.282959,1008.705200 
	C2034.104614,1001.240906 2034.104614,994.107727 2034.104614,986.974487 
	C2033.620483,986.932983 2033.136475,986.891479 2032.652344,986.849976 
	C2032.652344,992.165222 2032.652344,997.480469 2032.652344,1002.795715 
	C2032.213501,1002.793335 2031.774536,1002.791016 2031.335693,1002.788635 
	C2031.335693,984.893677 2031.335693,966.998718 2031.335693,949.103699 
	C2031.515747,949.092957 2031.695679,949.082275 2031.875732,949.071533 
	C2031.875732,952.372864 2031.875732,955.674194 2031.875732,958.975525 
	C2032.220825,958.970825 2032.565796,958.966125 2032.910889,958.961426 
	C2032.910889,949.642090 2032.910889,940.322754 2032.910889,931.003357 
	C2033.282104,930.998596 2033.653198,930.993774 2034.024414,930.988953 
	C2034.024414,933.190491 2033.998657,935.392456 2034.028442,937.593628 
	C2034.326904,959.632263 2034.647095,981.670532 2034.915649,1003.709534 
	C2034.937256,1005.483032 2034.620728,1007.260559 2034.282959,1008.705200 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2198.978516,1012.908264 
	C2194.996582,1012.908936 2191.500244,1013.030273 2188.025635,1012.810669 
	C2186.994385,1012.745483 2186.023926,1011.721375 2185.025635,1011.136719 
	C2185.943848,1010.468323 2186.845703,1009.247803 2187.782471,1009.220276 
	C2194.254639,1009.030396 2200.735840,1009.009521 2207.209473,1009.158203 
	C2208.126465,1009.179260 2209.015381,1010.413635 2209.917480,1011.086426 
	C2208.927979,1011.680786 2207.980957,1012.678711 2206.940186,1012.788696 
	C2204.474609,1013.049377 2201.964600,1012.888733 2198.978516,1012.908264 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2100.356445,1012.973389 
	C2093.150146,1013.318176 2086.367676,1013.658813 2079.582031,1013.749207 
	C2078.400391,1013.764954 2077.204834,1012.725586 2076.015625,1012.173462 
	C2077.094238,1011.499695 2078.156494,1010.275391 2079.254395,1010.242493 
	C2085.720703,1010.048950 2092.195068,1010.100952 2098.666260,1010.144897 
	C2100.372559,1010.156494 2102.898682,1009.803284 2100.356445,1012.973389 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2143.987305,1012.968384 
	C2139.094727,1012.955261 2134.695557,1012.955261 2129.998779,1012.955261 
	C2131.586182,1009.726807 2147.783447,1008.654907 2155.554688,1010.635071 
	C2154.394531,1012.960083 2154.394531,1012.960083 2143.987305,1012.968384 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2020.328369,902.213318 
	C2020.424683,901.808594 2020.438599,901.834290 2020.433105,901.838623 
	C2022.233521,901.906006 2024.066650,902.200439 2025.839966,901.989990 
	C2031.988037,901.260315 2034.791626,903.808899 2033.950195,910.608276 
	C2032.903687,911.033325 2031.872314,911.020203 2030.454956,911.027954 
	C2030.068848,909.397583 2030.068848,907.746399 2030.068848,906.325928 
	C2026.860352,904.158203 2021.376099,909.307556 2020.328369,902.213318 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2041.359619,889.159058 
	C2041.856689,893.827515 2041.946777,898.449829 2042.019043,903.537659 
	C2040.752808,904.109863 2039.504517,904.216492 2037.839478,904.358704 
	C2037.839478,900.125549 2037.607788,896.181641 2037.982178,892.296143 
	C2038.092651,891.150330 2039.915161,890.169373 2041.359619,889.159058 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2250.559082,1008.823364 
	C2251.922607,1008.900940 2252.935791,1009.129761 2253.948730,1009.358582 
	C2253.507812,1010.165771 2253.066650,1010.972961 2252.357178,1012.016296 
	C2247.744385,1012.051453 2243.400391,1011.850525 2239.056152,1011.649597 
	C2239.058350,1010.929016 2239.060303,1010.208435 2239.062256,1009.487915 
	C2242.777588,1009.316833 2246.493164,1009.145691 2250.559082,1008.823364 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2037.752563,879.178223 
	C2039.333252,881.617981 2040.616943,884.335693 2041.842773,887.437378 
	C2036.697876,887.543579 2036.395996,886.960388 2037.752563,879.178223 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M2043.561035,1013.761414 
	C2041.701782,1013.841553 2040.231079,1013.841553 2038.760498,1013.841553 
	C2038.675171,1013.406494 2038.589844,1012.971375 2038.504517,1012.536316 
	C2041.158203,1011.925293 2043.811768,1011.314209 2046.465454,1010.703186 
	C2046.644775,1011.236816 2046.824219,1011.770386 2047.003662,1012.304016 
	C2045.985718,1012.763123 2044.967651,1013.222168 2043.561035,1013.761414 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2030.089600,875.298340 
	C2030.017456,875.134521 2030.446899,874.985535 2030.666504,875.043579 
	C2030.886108,875.101624 2030.837280,875.534790 2030.758545,875.738037 
	C2030.462402,875.829773 2030.289673,875.670044 2030.089600,875.298340 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1690.596191,531.849609 
	C1692.470459,531.567322 1693.876831,531.370789 1695.642822,531.156128 
	C1701.486084,531.267456 1706.969727,531.396912 1713.087280,531.336975 
	C1715.913208,531.614319 1718.101807,532.455444 1720.298218,532.476440 
	C1726.505371,532.535767 1732.715698,532.258850 1738.924805,532.114380 
	C1738.946045,546.213623 1738.994507,560.312866 1738.953613,574.411865 
	C1738.948608,576.158081 1738.468872,577.903015 1737.797241,579.468384 
	C1737.091919,578.301819 1736.839844,576.484802 1736.498169,576.467834 
	C1734.696533,576.378113 1732.872314,576.742371 1731.029175,576.557861 
	C1731.858276,575.431946 1732.635620,574.197632 1733.583374,574.049011 
	C1737.757568,573.394348 1737.156494,571.050354 1736.628296,567.534546 
	C1735.256958,569.582214 1734.479858,570.742493 1733.702759,571.902832 
	C1732.377686,570.910828 1731.052490,569.918823 1729.236816,568.559631 
	C1729.236816,571.089478 1729.236816,573.662781 1729.122070,576.563232 
	C1721.808228,582.364685 1721.255249,584.089722 1724.084961,591.924194 
	C1723.935791,592.071045 1723.642456,592.369629 1723.308594,592.301270 
	C1720.725342,592.607239 1718.484619,593.232422 1716.224731,593.311218 
	C1707.663696,593.609802 1699.096558,593.809448 1690.531250,593.828552 
	C1689.348633,593.831177 1688.163452,592.611633 1687.389893,591.989990 
	C1692.101074,591.027954 1696.401733,590.033936 1700.702515,589.039917 
	C1697.759888,589.046265 1694.729858,588.226868 1692.518921,586.410339 
	C1691.970825,585.960083 1694.077515,582.278259 1694.756836,580.595215 
	C1693.930054,579.543762 1692.912720,578.250000 1691.971191,576.575195 
	C1692.699951,573.465393 1693.352905,570.736633 1694.400757,568.014038 
	C1695.708252,567.093872 1697.301147,566.239319 1697.407959,565.227783 
	C1697.747559,562.012390 1697.533447,558.738464 1697.533447,555.306274 
	C1692.903687,555.306274 1689.038696,555.306274 1685.251709,555.306274 
	C1683.426636,564.029602 1684.238647,567.114075 1688.456909,568.701721 
	C1688.623535,570.195679 1688.780640,571.605164 1688.834229,573.371094 
	C1689.151978,574.819397 1689.573120,575.911255 1689.958984,577.353943 
	C1689.789795,578.381836 1689.655762,579.058899 1689.411377,580.294067 
	C1688.496704,578.996826 1687.990845,578.279419 1687.484985,577.561951 
	C1686.942993,578.942566 1686.777222,580.950317 1687.326782,581.169983 
	C1692.692627,583.316284 1689.893799,586.117798 1687.990356,588.482361 
	C1687.062134,587.991577 1686.291748,587.897522 1685.521240,587.803345 
	C1685.931152,588.561340 1686.341064,589.319336 1686.751465,590.414368 
	C1686.853760,591.176941 1686.955688,591.602417 1686.689209,592.143677 
	C1685.011230,592.810059 1683.703613,593.834167 1682.391479,593.839844 
	C1664.259277,593.918213 1646.126587,593.856628 1628.028076,593.387878 
	C1628.062134,590.380493 1628.062134,587.814636 1628.062134,585.362427 
	C1631.160400,584.943481 1633.393555,584.641541 1635.626709,584.339600 
	C1635.572266,583.906921 1635.517822,583.474182 1635.463379,583.041504 
	C1632.708008,583.041504 1629.615356,582.292480 1627.324585,583.324097 
	C1625.962646,583.937378 1625.862793,587.353210 1625.195679,589.509094 
	C1624.788818,589.486572 1624.381958,589.463989 1623.975098,589.441467 
	C1623.975098,571.644348 1623.975098,553.847168 1623.987061,535.714844 
	C1624.001831,534.932739 1624.004517,534.485962 1624.007080,534.039124 
	C1633.692627,533.358643 1643.378052,532.678223 1653.516846,531.957581 
	C1666.022705,531.923340 1678.075562,531.929382 1690.596191,531.849609 
M1678.080444,538.902771 
	C1677.299805,538.856750 1676.519287,538.810730 1675.738647,538.764648 
	C1676.571411,537.255310 1677.404175,535.745972 1678.237061,534.236633 
	C1675.971680,534.707031 1674.198120,535.936462 1672.912598,537.553833 
	C1672.484253,538.092896 1673.182739,540.497742 1673.578247,540.553833 
	C1675.322876,540.801086 1677.144653,540.502747 1679.444702,540.174622 
	C1679.840576,539.996338 1680.236572,539.817993 1680.632446,539.639709 
	C1682.045166,543.062439 1684.555420,541.722656 1686.195923,540.375854 
	C1687.527832,539.282410 1687.977539,537.114197 1688.818481,535.422607 
	C1687.085815,534.880127 1685.375122,534.239136 1683.609619,533.842773 
	C1682.884644,533.679932 1682.041260,534.044678 1682.142456,534.028687 
	C1680.752686,536.043579 1679.836792,537.371399 1678.080444,538.902771 
M1709.367065,544.580261 
	C1706.342773,544.031311 1703.180420,542.619324 1702.123047,547.577026 
	C1701.868164,548.772278 1699.298096,549.473755 1697.792725,550.402344 
	C1697.500488,549.997375 1697.208252,549.592407 1696.915894,549.187439 
	C1698.142700,547.897644 1699.369385,546.607849 1700.596191,545.318054 
	C1698.335571,545.120239 1695.649170,546.859009 1694.918213,546.250183 
	C1691.223877,543.172791 1688.892822,545.652588 1686.327515,547.687012 
	C1684.230591,544.126282 1678.422607,543.422913 1673.802490,546.411377 
	C1675.287476,546.818787 1676.465820,547.142090 1678.295410,547.644043 
	C1676.166016,549.296631 1674.688843,550.443054 1672.607544,552.058350 
	C1674.119873,552.343689 1674.877563,552.598694 1675.639526,552.612122 
	C1680.386475,552.696228 1684.943726,550.933228 1689.954102,552.186157 
	C1692.226074,552.754272 1695.111572,551.241699 1698.124390,552.164062 
	C1699.959106,552.725708 1703.118164,551.543518 1704.618530,550.065002 
	C1707.527954,547.198059 1709.064087,548.593567 1710.657104,551.075745 
	C1711.178345,550.232300 1711.595215,549.557861 1712.498779,549.293030 
	C1714.072021,549.563477 1715.645264,549.833984 1717.418701,550.138855 
	C1717.418701,548.401367 1717.418701,546.472839 1717.418701,546.043335 
	C1714.429688,545.622620 1712.214844,545.310913 1709.367065,544.580261 
M1717.519775,588.138000 
	C1717.235596,585.419006 1715.838501,584.028870 1713.253418,585.443115 
	C1712.142700,586.050842 1711.275635,587.669678 1710.989380,588.980103 
	C1710.846191,589.635681 1712.555176,591.457703 1712.911865,591.328735 
	C1714.458252,590.769897 1715.814819,589.686279 1717.519775,588.138000 
M1649.343872,585.986938 
	C1650.911621,585.414795 1652.671753,585.099487 1653.950439,584.141418 
	C1654.446167,583.770081 1654.199707,581.715149 1653.634644,580.862183 
	C1653.253296,580.286377 1651.007324,580.066956 1650.828003,580.384460 
	C1649.937744,581.960022 1649.457153,583.767029 1649.343872,585.986938 
M1735.499268,565.580627 
	C1735.499268,565.580627 1735.459229,565.502197 1735.499268,565.580627 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1734.993408,595.169312 
	C1736.007690,595.196045 1737.021973,595.222778 1738.482910,595.468262 
	C1738.929565,595.687012 1739.047241,596.031494 1739.047241,596.031494 
	C1738.671753,598.272949 1738.296387,600.514465 1737.531494,602.429688 
	C1734.771729,597.045288 1730.049072,602.111755 1726.874878,599.844421 
	C1726.471313,599.556213 1724.494995,601.469666 1723.247437,602.363037 
	C1724.314453,603.089966 1725.365234,603.842590 1726.453979,604.535400 
	C1727.276245,605.058594 1728.147095,605.505249 1729.080322,606.241577 
	C1729.759766,606.433716 1730.355103,606.370056 1731.366455,606.353577 
	C1733.258545,605.971008 1734.734741,605.541321 1736.211060,605.111694 
	C1740.722656,604.420349 1738.254150,608.739624 1739.606201,610.674500 
	C1737.698364,611.193420 1736.015381,611.192627 1734.151489,610.917358 
	C1732.246216,611.002563 1730.521973,611.362244 1728.343262,611.752808 
	C1717.885132,612.042175 1707.881104,612.300781 1697.430908,612.457520 
	C1675.539917,612.525085 1654.095337,612.692688 1632.650635,612.866211 
	C1630.509033,612.883484 1628.144409,613.348633 1627.805176,609.752441 
	C1628.014893,607.697693 1628.146851,606.089355 1628.292725,604.309204 
	C1630.908813,604.043091 1633.209106,603.809143 1636.885010,603.435181 
	C1627.456543,600.103149 1625.708374,601.497559 1625.933594,610.365601 
	C1625.124756,610.643250 1624.646240,610.754578 1624.198975,610.424500 
	C1624.484863,606.010986 1624.739746,602.038757 1625.420898,598.043945 
	C1627.917114,597.560608 1629.981567,596.724976 1632.057373,596.695984 
	C1658.261963,596.330017 1684.468262,596.074219 1710.674194,595.814941 
	C1712.423096,595.797607 1714.174072,595.979370 1715.962524,596.070435 
	C1714.827148,604.488770 1711.579224,603.338623 1704.270020,602.233948 
	C1702.745117,599.150818 1700.690430,599.394470 1699.245605,601.539001 
	C1698.512207,602.627380 1699.090942,604.599731 1699.076416,606.172485 
	C1700.733398,605.485718 1702.390259,604.798950 1704.443970,604.078003 
	C1709.525269,604.696289 1713.923706,605.852051 1717.208862,600.254150 
	C1719.392700,596.532715 1723.234253,593.478027 1728.529907,595.029602 
	C1728.979980,595.161499 1729.509766,595.021240 1730.223633,595.278564 
	C1731.960938,595.422974 1733.477173,595.296143 1734.993408,595.169312 
M1638.044189,603.277405 
	C1638.044189,603.932800 1638.044189,604.588196 1638.044189,604.963013 
	C1642.618286,604.963013 1647.034302,604.963013 1653.049927,604.963013 
	C1653.192139,604.242554 1654.627197,601.402344 1653.792847,600.332581 
	C1651.688843,597.635681 1650.038452,600.090759 1648.422485,601.848083 
	C1647.685913,602.649170 1646.238403,603.145508 1645.110352,603.150574 
	C1643.656738,603.157104 1642.200317,602.532349 1639.656372,601.915466 
	C1639.678589,601.968140 1639.169922,600.758850 1638.308960,598.712280 
	C1638.167236,600.624573 1638.096802,601.576111 1638.044189,603.277405 
M1694.854614,602.602295 
	C1693.527344,604.050720 1692.200195,605.499084 1689.843872,608.070618 
	C1692.952271,607.648499 1694.710571,607.409729 1697.118774,607.082703 
	C1696.374634,604.340942 1695.926147,602.077881 1695.000732,600.030334 
	C1694.881470,599.766479 1692.289062,600.620544 1690.842407,600.956726 
	C1690.935181,601.338379 1691.027954,601.720032 1691.120728,602.101685 
	C1692.199829,602.101685 1693.279053,602.101685 1694.854614,602.602295 
M1736.405029,609.505249 
	C1736.812866,608.965820 1737.220581,608.426453 1737.628418,607.887024 
	C1737.270874,607.687439 1736.913330,607.487915 1736.555786,607.288391 
	C1736.386963,607.855408 1736.218140,608.422424 1736.405029,609.505249 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1797.944824,581.046265 
	C1798.429321,577.961365 1798.913940,574.876465 1799.356201,572.061340 
	C1820.525635,572.061340 1841.719727,572.061340 1863.415039,572.593994 
	C1863.903564,579.304138 1863.890747,585.481689 1863.428467,591.765991 
	C1847.875366,591.872742 1832.771606,591.872742 1818.500122,591.872742 
	C1816.439819,589.887024 1814.800049,588.306702 1812.572754,586.160034 
	C1811.685059,588.787720 1811.231812,590.129395 1810.669189,591.794678 
	C1810.065918,590.506897 1809.592041,589.495239 1809.552002,589.409912 
	C1807.569580,590.473999 1806.071899,591.398560 1804.457275,592.018860 
	C1804.204834,592.115784 1803.420288,590.827759 1802.881714,590.180115 
	C1804.212036,589.881653 1805.542358,589.583191 1806.741211,589.314209 
	C1806.741211,587.411560 1806.741211,585.547546 1806.741211,583.001343 
	C1805.073608,584.313721 1803.985596,585.169922 1802.398560,586.418884 
	C1800.803833,584.494995 1799.374268,582.770630 1797.944824,581.046265 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1776.191162,573.418274 
	C1781.977173,569.602112 1788.453369,571.582153 1794.649170,571.360291 
	C1795.097534,571.344299 1795.888916,573.054932 1796.033691,574.034729 
	C1796.343384,576.129456 1796.333496,578.271484 1796.641846,581.062866 
	C1789.412231,584.706543 1796.446167,588.419800 1796.953979,592.319153 
	C1791.380615,592.319153 1786.432251,592.368652 1781.487549,592.258057 
	C1780.851318,592.243774 1780.233521,591.399048 1779.875732,590.718140 
	C1781.645508,589.554993 1783.146606,588.612610 1784.654663,587.665894 
	C1781.180542,582.523376 1780.049072,582.439880 1774.679077,586.916260 
	C1774.186401,585.974487 1773.722412,585.087524 1773.261963,584.207458 
	C1771.063110,585.741272 1769.104004,587.107910 1767.356201,588.106262 
	C1767.227417,586.488281 1767.137573,584.352783 1766.495483,584.171204 
	C1765.110474,583.779663 1763.441772,584.391907 1761.889160,584.593445 
	C1766.294434,587.229858 1762.850342,589.150269 1761.259888,591.790405 
	C1764.172852,590.842712 1768.132080,594.499023 1767.566895,588.432983 
	C1771.650513,589.273621 1775.315063,590.142822 1779.005127,591.430725 
	C1778.335449,592.173096 1777.642212,592.773315 1776.944458,592.778442 
	C1768.303101,592.842163 1759.661133,592.819214 1751.019287,592.818665 
	C1751.019287,586.505615 1751.019287,580.192566 1751.019287,573.413269 
	C1754.825317,573.413269 1758.389526,573.413269 1761.661011,573.413269 
	C1761.820557,575.620483 1761.998657,578.083679 1762.208130,580.979553 
	C1764.857788,580.610840 1766.916626,580.324341 1769.681030,580.199524 
	C1775.911621,579.634705 1777.826904,577.343750 1776.191162,573.418274 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1751.016113,592.916138 
	C1759.661133,592.819214 1768.303101,592.842163 1776.944458,592.778442 
	C1777.642212,592.773315 1778.335449,592.173096 1779.160278,591.396912 
	C1779.289795,590.944397 1779.607178,590.938965 1779.607178,590.938965 
	C1780.233521,591.399048 1780.851318,592.243774 1781.487549,592.258057 
	C1786.432251,592.368652 1791.380615,592.319153 1796.953979,592.319153 
	C1796.446167,588.419800 1789.412231,584.706543 1796.914062,581.365784 
	C1796.998901,581.000000 1797.471802,581.031189 1797.708252,581.038696 
	C1799.374268,582.770630 1800.803833,584.494995 1802.398560,586.418884 
	C1803.985596,585.169922 1805.073608,584.313721 1806.741211,583.001343 
	C1806.741211,585.547546 1806.741211,587.411560 1806.741211,589.314209 
	C1805.542358,589.583191 1804.212036,589.881653 1802.881714,590.180115 
	C1803.420288,590.827759 1804.204834,592.115784 1804.457275,592.018860 
	C1806.071899,591.398560 1807.569580,590.473999 1809.552002,589.409912 
	C1809.592041,589.495239 1810.065918,590.506897 1810.669189,591.794678 
	C1811.231812,590.129395 1811.685059,588.787720 1812.572754,586.160034 
	C1814.800049,588.306702 1816.439819,589.887024 1818.500122,591.872742 
	C1832.771606,591.872742 1847.875366,591.872742 1863.867554,591.799927 
	C1866.214600,591.692810 1867.673340,591.658447 1869.131958,591.624023 
	C1876.938477,592.957397 1874.744385,586.728516 1875.671143,582.460815 
	C1880.566162,582.460815 1885.413940,582.460815 1890.261719,582.460815 
	C1890.289062,582.737976 1890.316406,583.015137 1890.343750,583.292297 
	C1886.233521,583.495789 1882.123413,583.699280 1878.048828,583.900940 
	C1875.996460,590.192566 1877.380737,591.888062 1883.436279,591.673096 
	C1893.721802,591.308167 1904.034546,590.979553 1914.303833,591.406738 
	C1916.890381,591.514282 1919.355225,594.545227 1921.565430,596.613342 
	C1920.935181,597.086487 1920.615601,597.179077 1920.211060,597.336548 
	C1920.126221,597.401306 1919.957764,597.532471 1919.957764,597.532471 
	C1917.795898,594.345032 1914.881958,593.437744 1911.011719,593.599121 
	C1902.082031,593.971680 1893.130737,593.809204 1884.188721,593.910095 
	C1880.817871,593.948181 1876.896240,592.741089 1875.991333,597.569397 
	C1873.609497,596.175781 1871.744629,595.084717 1869.125732,594.013306 
	C1867.888428,594.054260 1867.405273,594.075500 1866.530029,594.062622 
	C1865.087891,594.054626 1864.037720,594.080566 1862.525513,594.025146 
	C1849.754883,594.078369 1837.446045,594.212952 1824.725342,594.240356 
	C1819.744629,594.273804 1815.147705,594.154480 1810.617432,594.648499 
	C1807.694580,594.967163 1804.869629,596.185059 1801.902100,596.673462 
	C1799.891235,595.916382 1797.970459,595.088013 1796.068481,595.128784 
	C1789.901245,595.260925 1783.740845,595.711853 1777.220703,595.942383 
	C1774.216431,595.986084 1771.569214,596.127319 1768.571167,596.190063 
	C1767.502930,596.097534 1766.785400,596.083618 1765.835938,595.716187 
	C1763.047852,595.221619 1760.491333,595.006897 1757.936646,595.028748 
	C1757.600830,595.031555 1757.275391,596.244507 1756.827637,597.212769 
	C1753.456055,598.896240 1751.437134,597.370972 1750.990356,593.207214 
	C1750.990356,593.207214 1751.012939,593.013550 1751.016113,592.916138 
M1798.277710,588.674805 
	C1798.587280,589.865967 1798.896851,591.057129 1799.206421,592.248352 
	C1799.673218,592.062073 1800.139893,591.875854 1800.606689,591.689636 
	C1800.006958,590.811340 1799.407104,589.932983 1798.277710,588.674805 
M1814.501587,589.607910 
	C1813.872192,589.441956 1813.242798,589.276001 1812.613525,589.109985 
	C1812.565430,589.333252 1812.517456,589.556519 1812.469360,589.779785 
	C1813.006104,589.868713 1813.542969,589.957642 1814.501587,589.607910 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1734.332397,611.191833 
	C1736.015381,611.192627 1737.698364,611.193420 1739.643188,611.043579 
	C1740.346069,610.939392 1740.787109,610.985901 1741.840332,611.328064 
	C1745.915039,612.447144 1748.444458,612.049377 1748.378296,607.558411 
	C1748.368164,606.862122 1749.503540,606.148926 1750.089111,605.902588 
	C1749.896729,610.193298 1751.595459,611.470947 1755.498291,611.308960 
	C1764.097412,610.951965 1772.720093,611.197083 1781.325806,610.944946 
	C1783.438843,610.882996 1785.536011,609.327820 1787.618774,609.376892 
	C1792.420532,609.489868 1797.209106,610.165955 1802.000000,610.729919 
	C1801.996948,610.846252 1801.979248,611.078308 1801.988892,611.320557 
	C1802.034790,611.883545 1802.054688,612.205383 1801.609985,612.664185 
	C1798.334229,612.877563 1795.503662,613.074524 1792.679810,613.004822 
	C1788.233643,612.895142 1786.758545,615.708313 1786.140869,619.388672 
	C1785.906738,620.783447 1785.928955,622.221252 1785.864258,623.190369 
	C1776.567505,624.886658 1767.848145,626.477661 1758.455811,628.191406 
	C1761.351196,618.479187 1761.540894,618.391418 1767.238525,622.980408 
	C1768.266113,622.002991 1769.314453,621.005737 1769.368408,620.954407 
	C1775.133789,621.620178 1779.842407,622.163940 1785.115356,622.772888 
	C1784.772949,618.768005 1784.539551,616.039429 1784.331421,613.605103 
	C1774.031006,613.605103 1763.894165,613.505615 1753.765625,613.754272 
	C1752.718994,613.780029 1750.856934,615.867432 1750.840942,617.018738 
	C1750.711304,626.327332 1750.932617,635.640747 1751.056152,645.222778 
	C1751.061646,645.672852 1751.057861,645.852966 1750.698730,646.047241 
	C1749.870972,646.096130 1749.398682,646.130981 1748.938599,645.740112 
	C1748.950928,634.997253 1748.950928,624.680054 1748.950928,614.170837 
	C1745.734741,614.170837 1743.935059,614.170837 1741.753174,614.144287 
	C1740.860474,614.145203 1740.350220,614.172607 1739.538574,614.119385 
	C1738.822876,614.023987 1738.408569,614.009155 1737.541870,613.992554 
	C1734.357056,614.032959 1731.624634,614.075256 1728.433350,614.071289 
	C1720.587891,613.060669 1713.247192,615.718811 1705.818237,614.217590 
	C1703.294678,613.707642 1700.612549,613.983154 1697.977295,613.675903 
	C1697.887817,613.157776 1697.862915,612.860229 1697.877197,612.559326 
	C1707.881104,612.300781 1717.885132,612.042175 1728.694092,611.806641 
	C1731.110107,611.617004 1732.721313,611.404419 1734.332397,611.191833 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1704.307129,602.585938 
	C1711.579224,603.338623 1714.827148,604.488770 1715.962524,596.070435 
	C1714.174072,595.979370 1712.423096,595.797607 1710.674194,595.814941 
	C1684.468262,596.074219 1658.261963,596.330017 1632.057373,596.695984 
	C1629.981567,596.724976 1627.917114,597.560608 1625.369629,597.826782 
	C1624.891846,597.632324 1624.751099,597.209167 1625.043213,597.060059 
	C1626.255493,596.055786 1627.175537,595.200623 1628.081421,594.214233 
	C1628.067139,594.083008 1627.993896,593.829346 1627.993896,593.829346 
	C1646.126587,593.856628 1664.259277,593.918213 1682.391479,593.839844 
	C1683.703613,593.834167 1685.011230,592.810059 1686.657227,592.139771 
	C1686.993652,592.020020 1686.979492,591.957886 1686.979492,591.957886 
	C1688.163452,592.611633 1689.348633,593.831177 1690.531250,593.828552 
	C1699.096558,593.809448 1707.663696,593.609802 1716.224731,593.311218 
	C1718.484619,593.232422 1720.725342,592.607239 1723.485352,592.477173 
	C1724.273804,592.655029 1724.551758,592.588684 1724.829590,592.522339 
	C1724.631104,592.273987 1724.432617,592.025696 1724.234131,591.777344 
	C1721.255249,584.089722 1721.808228,582.364685 1729.344482,576.666138 
	C1730.139771,576.610962 1730.597900,576.780029 1731.055908,576.949097 
	C1732.872314,576.742371 1734.696533,576.378113 1736.498169,576.467834 
	C1736.839844,576.484802 1737.091919,578.301819 1737.459961,579.635986 
	C1734.362061,579.686340 1731.189331,579.388977 1728.016602,579.091675 
	C1727.978271,579.414734 1727.939941,579.737793 1727.901733,580.060852 
	C1729.756836,580.916565 1731.611938,581.772278 1733.685547,583.152161 
	C1732.311890,586.058167 1730.719727,588.440063 1729.104736,590.433838 
	C1727.844849,587.576294 1729.913208,583.501587 1725.518066,582.474548 
	C1723.145630,587.515991 1723.871826,589.536011 1728.944580,592.573669 
	C1729.299805,593.587463 1729.651123,594.297424 1730.002441,595.007385 
	C1729.509766,595.021240 1728.979980,595.161499 1728.529907,595.029602 
	C1723.234253,593.478027 1719.392700,596.532715 1717.208862,600.254150 
	C1713.923706,605.852051 1709.525269,604.696289 1704.476318,603.690796 
	C1704.177124,603.087158 1704.242188,602.836548 1704.307129,602.585938 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1747.059814,537.342896 
	C1747.099976,545.951965 1747.330200,554.105652 1746.990601,562.235535 
	C1746.893066,564.569641 1745.149414,566.834900 1744.160400,569.131653 
	C1743.463501,568.951233 1742.766602,568.770752 1742.069702,568.590332 
	C1742.069702,562.094299 1742.072876,555.598328 1742.068359,549.102295 
	C1742.065674,545.445618 1741.927612,541.782654 1742.115601,538.136230 
	C1742.171631,537.050232 1743.126953,536.010620 1743.670166,534.949768 
	C1744.791138,535.592651 1745.912231,536.235535 1747.059814,537.342896 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1865.994019,569.006592 
	C1859.297607,569.183655 1852.601807,569.416016 1845.904419,569.468445 
	C1845.056763,569.475098 1844.201782,568.537720 1843.350220,568.037720 
	C1844.005493,567.406372 1844.664917,566.221008 1845.315430,566.225891 
	C1857.404541,566.315186 1869.492432,566.545715 1881.580322,566.767090 
	C1881.635254,566.768066 1881.684570,567.063965 1882.119507,568.388550 
	C1877.319580,569.400513 1872.546143,568.746521 1867.175293,568.968994 
	C1866.467773,568.971802 1865.994019,569.006592 1865.994019,569.006592 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1865.991455,569.376953 
	C1865.994019,569.006592 1866.467773,568.971802 1866.704590,568.962830 
	C1867.400269,569.943115 1867.859131,570.932312 1868.673462,571.974609 
	C1869.037964,578.267334 1869.047119,584.506958 1869.094238,591.185303 
	C1867.673340,591.658447 1866.214600,591.692810 1864.316895,591.693237 
	C1863.890747,585.481689 1863.903564,579.304138 1863.863525,572.684021 
	C1864.536743,571.410034 1865.262817,570.578674 1865.991455,569.376953 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1729.127563,590.821960 
	C1730.719727,588.440063 1732.311890,586.058167 1734.004639,583.333130 
	C1735.900879,583.502258 1737.696655,584.014587 1739.492310,584.526917 
	C1738.778320,586.084717 1738.074951,587.647583 1737.347534,589.199097 
	C1736.508423,590.988892 1735.647949,592.768616 1734.895020,594.861084 
	C1733.477173,595.296143 1731.960938,595.422974 1730.223633,595.278564 
	C1729.651123,594.297424 1729.299805,593.587463 1728.986328,592.210388 
	C1729.058716,591.302856 1729.093140,591.062378 1729.127563,590.821960 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1746.174194,593.243164 
	C1742.889526,593.152832 1741.734619,592.079468 1742.458252,588.342957 
	C1743.260010,584.201660 1742.619385,579.799377 1742.964355,575.539734 
	C1743.062622,574.327515 1744.406250,573.216125 1745.178711,572.058472 
	C1745.828735,573.177185 1746.996460,574.275635 1747.041382,575.418152 
	C1747.261719,581.023743 1747.183716,586.641113 1746.954224,592.753906 
	C1746.522949,593.250061 1746.348633,593.246643 1746.174194,593.243164 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1716.352295,527.129944 
	C1725.165161,527.129944 1733.978027,527.129944 1743.633789,527.129944 
	C1742.232056,528.125366 1741.309448,528.780579 1739.724487,529.693726 
	C1732.497070,529.988220 1725.931274,529.991272 1719.366821,530.080994 
	C1717.261108,530.109741 1715.667969,529.843872 1716.352295,527.129944 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1746.016602,593.446411 
	C1746.348633,593.246643 1746.522949,593.250061 1746.959229,593.235840 
	C1748.230713,593.140808 1749.240601,593.063416 1750.620361,593.096619 
	C1751.437134,597.370972 1753.456055,598.896240 1757.204346,597.219238 
	C1757.811523,598.102295 1757.924438,599.294739 1758.035156,600.867920 
	C1758.020386,601.502136 1758.007690,601.755615 1757.579346,602.052673 
	C1756.521851,603.233826 1755.880127,604.371399 1754.711792,606.442505 
	C1754.161255,604.769409 1753.706299,603.386902 1753.495850,601.817749 
	C1753.617554,601.089905 1753.494751,600.548767 1753.372070,600.007690 
	C1752.911865,600.294556 1752.451538,600.581421 1751.657593,601.047241 
	C1750.914307,602.597595 1750.504639,603.968994 1749.812500,605.211182 
	C1749.105225,604.630615 1748.680054,604.179199 1748.254883,603.727783 
	C1748.106934,603.529419 1747.959106,603.331055 1747.771729,602.573303 
	C1749.196533,598.770935 1748.545654,596.326172 1745.066162,594.948669 
	C1745.330444,594.515625 1745.594849,594.082642 1746.016602,593.446411 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1728.996338,605.985901 
	C1728.147095,605.505249 1727.276245,605.058594 1726.453979,604.535400 
	C1725.365234,603.842590 1724.314453,603.089966 1723.247437,602.363037 
	C1724.494995,601.469666 1726.471313,599.556213 1726.874878,599.844421 
	C1730.049072,602.111755 1734.771729,597.045288 1737.164062,602.510620 
	C1736.861938,603.442871 1736.537720,603.968018 1736.212280,604.802429 
	C1734.734741,605.541321 1733.258545,605.971008 1731.329834,606.010498 
	C1730.397461,604.743530 1729.917480,603.866882 1728.996338,602.183960 
	C1728.996338,604.064575 1728.996338,605.025208 1728.996338,605.985901 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1716.150391,526.961243 
	C1715.667969,529.843872 1717.261108,530.109741 1719.366821,530.080994 
	C1725.931274,529.991272 1732.497070,529.988220 1739.531006,529.956421 
	C1739.809204,530.550476 1739.618408,531.139709 1739.176270,531.921692 
	C1732.715698,532.258850 1726.505371,532.535767 1720.298218,532.476440 
	C1718.101807,532.455444 1715.913208,531.614319 1713.369263,531.028259 
	C1713.994385,529.536804 1714.971313,528.164673 1716.150391,526.961243 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1762.874023,570.031250 
	C1757.993042,570.036011 1753.590942,570.036011 1749.188843,570.036011 
	C1749.214355,569.293640 1749.239746,568.551331 1749.265259,567.808960 
	C1756.052368,567.808960 1762.839355,567.808960 1769.626465,567.808960 
	C1769.664795,568.363647 1769.703247,568.918396 1769.741577,569.473083 
	C1767.612061,569.657532 1765.482422,569.841980 1762.874023,570.031250 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1775.851074,573.298584 
	C1777.826904,577.343750 1775.911621,579.634705 1770.074951,580.250732 
	C1769.725342,578.589050 1769.687500,577.037781 1769.390869,574.990479 
	C1769.132080,574.494324 1769.140259,574.095520 1769.140259,574.095520 
	C1771.263794,573.789978 1773.387329,573.484436 1775.851074,573.298584 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1747.817627,603.852905 
	C1748.680054,604.179199 1749.105225,604.630615 1749.810059,605.237305 
	C1750.089722,605.392578 1750.109253,605.443604 1750.109131,605.443604 
	C1749.503540,606.148926 1748.368164,606.862122 1748.378296,607.558411 
	C1748.444458,612.049377 1745.915039,612.447144 1742.171143,611.358582 
	C1742.044067,608.986206 1742.198364,606.879028 1742.371216,604.518372 
	C1744.175171,604.323792 1745.777832,604.150940 1747.817627,603.852905 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1744.672363,595.028442 
	C1748.545654,596.326172 1749.196533,598.770935 1747.468506,602.359253 
	C1745.507812,601.971191 1742.851807,601.626831 1742.356445,600.405762 
	C1741.741455,598.890198 1740.565796,595.706909 1744.672363,595.028442 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1768.930664,573.848389 
	C1769.140259,574.095520 1769.132080,574.494324 1769.142090,574.693420 
	C1767.893433,576.067566 1766.635010,577.242676 1765.376465,578.417725 
	C1764.893555,577.841858 1764.410645,577.265991 1763.927856,576.690186 
	C1765.525635,575.660522 1767.123413,574.630859 1768.930664,573.848389 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1739.296021,596.006348 
	C1739.047241,596.031494 1738.929565,595.687012 1738.860352,595.520508 
	C1739.267090,595.370361 1739.743042,595.386780 1740.218994,595.403198 
	C1739.994263,595.595825 1739.769531,595.788513 1739.296021,596.006348 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M734.438660,934.045410 
	C709.419067,934.270325 684.869446,934.457520 660.319824,934.637878 
	C648.565613,934.724182 636.811218,934.797241 624.573486,934.526489 
	C624.073181,933.801453 624.056396,933.426331 624.039062,932.787842 
	C624.030823,932.349121 624.023254,932.173706 624.298523,931.701965 
	C624.369934,925.257080 624.158386,919.108582 623.762146,912.615662 
	C621.567932,911.874573 619.563293,911.168701 617.547974,911.136292 
	C609.987183,911.014832 604.117249,905.523010 603.401978,897.386414 
	C610.049377,896.978943 616.706177,896.570923 624.295166,896.105713 
	C623.768066,892.909058 623.363037,890.452759 623.086731,887.544434 
	C623.050171,882.091675 622.884888,877.091003 623.062134,871.831055 
	C623.404663,865.482300 623.404663,859.392761 623.404663,853.303284 
	C622.926758,853.299927 622.448914,853.296631 621.971008,853.293335 
	C621.971008,859.502319 621.971008,865.711365 621.650330,871.946594 
	C620.906494,871.914917 620.483276,871.857056 620.039368,871.343384 
	C619.711731,852.984497 619.404846,835.081360 619.305054,816.963745 
	C620.011230,816.437500 620.510315,816.125732 621.021790,816.296997 
	C621.315674,828.014893 620.566956,839.314636 622.631958,850.466064 
	C623.460449,850.031311 623.784241,849.602661 623.773376,849.182678 
	C623.533569,839.918274 623.199402,830.655884 623.044189,821.390320 
	C623.018677,819.868408 623.748291,818.333862 624.489197,816.885315 
	C626.218933,816.545349 627.588440,816.125183 629.351685,815.717651 
	C630.748291,815.447510 632.212524,815.489441 632.677734,814.828308 
	C635.361633,811.014404 636.806030,813.554199 638.413635,814.886108 
	C640.812439,814.886108 643.211304,814.886108 645.641541,814.886108 
	C645.641541,813.239746 645.641541,811.319885 645.641541,808.866882 
	C643.998962,809.831665 642.767517,810.555054 642.756226,810.561707 
	C638.101807,809.753845 634.559326,809.138977 630.828735,808.491455 
	C630.445190,810.995728 630.221802,812.453918 629.606689,813.860168 
	C627.779114,814.163269 626.343262,814.518372 624.907471,814.873474 
	C624.401489,814.188599 623.895569,813.503723 623.389587,812.818787 
	C625.284790,812.073242 627.179993,811.327637 629.075134,810.582031 
	C628.690247,810.121826 628.305298,809.661560 627.920349,809.201355 
	C626.166748,809.631714 624.413147,810.062012 622.767456,810.029907 
	C622.919983,807.719177 622.964661,805.870850 623.193420,803.741760 
	C624.518066,803.167969 625.658691,802.874939 626.442749,802.673584 
	C625.440063,801.415161 624.129883,799.770874 622.826782,797.651123 
	C622.833984,783.824829 622.833984,770.473938 622.833984,757.122986 
	C622.184387,757.131775 621.534729,757.140564 620.885132,757.149353 
	C620.885132,770.718933 620.885132,784.288574 620.590332,797.888855 
	C619.902039,797.900208 619.508484,797.880920 619.055176,797.404907 
	C618.995361,781.089661 618.995361,765.231018 618.995361,749.372375 
	C619.503235,749.356201 620.011047,749.340088 620.518921,749.323914 
	C620.823303,750.503174 621.127747,751.682373 621.432129,752.861633 
	C621.306885,748.863464 623.360962,747.994080 626.879883,748.039185 
	C638.730042,748.191040 650.583313,748.096924 663.663391,748.096924 
	C662.461426,749.916565 661.967896,751.013367 661.162842,751.785339 
	C660.334229,752.579834 659.201904,753.057617 658.202454,753.674011 
	C658.309509,754.053040 658.416565,754.432129 658.523621,754.811157 
	C661.058350,754.811157 663.593018,754.811157 666.486572,754.921021 
	C668.456421,754.566833 670.067322,754.102844 671.983704,753.457520 
	C673.221863,753.548462 674.174377,754.094238 675.082642,754.028503 
	C676.428711,753.931091 677.743347,753.400024 679.038940,753.029785 
	C679.006409,753.005920 679.040100,753.079224 679.419006,753.273315 
	C682.549500,753.327087 685.301147,753.186829 688.023071,753.029297 
	C687.993347,753.012085 688.028015,753.071533 688.278198,753.147827 
	C688.686096,753.149841 688.843811,753.075623 689.308411,753.141724 
	C690.468079,752.566345 691.320984,751.850647 692.563110,751.047241 
	C694.491089,751.617126 696.029907,752.274780 697.210999,752.779480 
	C699.748840,752.779480 702.133179,752.779480 704.846619,752.779480 
	C703.457092,747.578369 706.589905,747.166504 710.672607,747.389343 
	C713.422729,747.539368 716.205322,747.094910 719.416626,746.980713 
	C722.695496,747.045044 725.531189,747.045044 729.258728,747.045044 
	C723.786438,752.111511 722.676270,757.611877 725.547363,761.556091 
	C727.902100,764.790710 733.919922,766.266663 737.272888,763.956848 
	C739.083130,762.709717 741.436890,760.476624 741.416443,758.713196 
	C741.372620,754.937866 739.945374,751.178650 738.874023,746.491272 
	C741.334839,747.246704 744.078247,747.991821 746.742798,748.955078 
	C747.790955,749.333984 748.662842,750.200317 749.615051,750.844666 
	C749.672485,749.632874 749.729858,748.421021 749.800659,746.926636 
	C752.272888,746.926636 754.774658,746.753784 757.229736,747.016541 
	C758.137878,747.113708 758.936340,748.234985 759.479065,749.072510 
	C759.699219,752.683716 760.225159,756.111450 760.438599,759.708740 
	C757.943298,761.170959 756.439026,762.481445 760.191528,764.248047 
	C760.438232,766.296387 760.513306,768.021912 760.280273,769.727905 
	C759.241455,769.258972 758.510803,768.809387 756.692566,767.690552 
	C757.110229,775.343384 757.471130,781.955383 757.732727,788.927002 
	C756.023193,791.797852 754.909302,794.950867 752.686462,796.669922 
	C749.338379,799.259094 748.483521,794.293396 746.103333,793.151245 
	C743.682922,789.085815 741.430725,785.220276 738.787720,781.643494 
	C736.318909,778.302490 732.805237,778.116760 729.227051,780.681519 
	C738.179016,784.263733 738.190063,784.256226 742.657104,790.841248 
	C743.585022,792.209045 744.397705,793.654968 745.114624,795.460083 
	C739.848816,796.368164 734.736755,796.963806 729.607361,797.272766 
	C729.064575,797.305420 728.607239,795.309204 727.781433,794.863342 
	C726.384766,794.109314 724.730042,793.824829 723.166565,793.407410 
	C723.056763,793.378174 722.805115,793.880005 722.482239,794.323303 
	C723.330811,794.987610 724.174438,795.648132 725.236206,796.479431 
	C723.992065,797.076477 722.997009,797.553955 721.583618,797.987915 
	C716.732544,798.533447 715.483032,795.939453 715.312439,793.013672 
	C716.992249,790.671204 718.251160,788.915771 719.510010,787.160278 
	C719.086914,786.809082 718.663879,786.457886 718.240784,786.106689 
	C716.509399,787.660522 714.778015,789.214417 713.046570,790.768311 
	C713.061340,790.436646 713.076050,790.104919 713.090820,789.773193 
	C709.056580,790.204590 705.022339,790.635925 700.934326,790.718567 
	C699.308960,788.873901 697.737305,787.377991 696.165649,785.882080 
	C695.631897,786.508545 695.098145,787.135010 694.564392,787.761536 
	C696.343506,789.180969 698.122559,790.600403 699.922119,792.385986 
	C698.026367,796.205811 697.415833,799.594910 699.384521,803.395508 
	C700.013306,804.609070 699.192932,806.573303 699.086304,807.671570 
	C704.933472,807.712524 712.335693,804.149597 717.442505,808.827820 
	C717.080994,807.314453 715.895508,804.646301 715.938721,801.998169 
	C716.005005,797.942871 719.604126,799.253113 722.307861,799.095215 
	C724.014954,800.405334 725.419434,801.507202 726.681335,802.497192 
	C727.718689,801.599548 728.884644,799.718506 730.187866,799.617981 
	C735.126343,799.237305 740.422424,798.359863 744.994019,799.693481 
	C750.363403,801.259766 753.490295,798.812500 757.082764,796.751648 
	C757.232056,811.712158 757.232056,811.712158 759.452271,813.486572 
	C759.452271,807.124084 759.452271,800.917908 759.550354,794.609863 
	C759.648438,794.508057 759.906677,794.392761 760.135620,794.597717 
	C760.550964,794.887329 760.737488,794.972046 760.944885,795.521484 
	C761.031128,805.103027 761.196777,814.220947 761.091370,823.335754 
	C761.065002,825.614502 760.165955,827.883118 759.668030,830.156433 
	C761.934814,840.521362 761.491455,851.176514 761.305786,861.719604 
	C760.642334,855.967957 760.365906,850.279846 760.032654,844.595093 
	C759.950745,843.199524 759.683167,841.814819 759.500916,840.425110 
	C759.015076,840.481140 758.529297,840.537170 758.043457,840.593201 
	C758.043457,845.336975 757.932983,850.083984 758.073486,854.823547 
	C758.223511,859.882629 758.610474,864.934753 758.850159,870.266968 
	C758.802368,870.904541 758.798340,871.264893 758.795471,871.660889 
	C758.796631,871.696411 758.837585,871.756531 758.597351,871.659912 
	C758.216736,871.663696 758.076355,871.764160 757.632202,872.048096 
	C755.133545,873.474304 753.014282,875.592590 750.728882,875.790344 
	C744.643982,876.316711 738.485474,876.106812 732.361450,875.964600 
	C731.185608,875.937378 729.993347,874.951294 728.880188,874.287659 
	C727.305115,873.348572 725.796509,872.297791 724.259521,871.294800 
	C723.984741,871.736572 723.709961,872.178345 723.435181,872.620117 
	C724.231201,873.545654 725.027283,874.471191 726.260315,875.904724 
	C722.688293,875.904724 719.896301,875.904724 716.981567,875.557007 
	C716.479858,873.798523 716.100830,872.387878 716.026245,870.807800 
	C717.843323,869.052368 719.355957,867.466248 720.868591,865.880127 
	C720.228271,865.340332 719.587952,864.800537 718.947632,864.260681 
	C717.599121,866.001465 716.250671,867.742249 714.531982,869.618042 
	C710.207520,869.315002 706.253113,868.876831 701.283447,868.326233 
	C700.619080,867.804260 698.858521,866.421021 697.097900,865.037781 
	C696.734436,865.440369 696.370972,865.842957 696.007568,866.245544 
	C697.547852,867.916565 699.088135,869.587585 700.510803,871.131042 
	C700.109497,873.551819 698.747437,876.322144 699.514648,878.241150 
	C701.269714,882.630981 699.314270,885.548157 696.651062,888.375610 
	C698.968567,887.129333 701.803772,884.459473 703.193420,885.071594 
	C707.416138,886.931396 710.916687,885.477722 714.717590,884.730957 
	C716.352295,886.596008 717.934814,888.116150 719.517273,889.636353 
	C720.014526,889.012329 720.511841,888.388306 721.009155,887.764221 
	C719.353699,886.508118 717.698242,885.252014 715.928589,883.633728 
	C716.198669,881.836914 716.583008,880.402161 717.417358,878.896118 
	C721.348083,878.556213 725.124878,877.205322 726.734985,880.666565 
	C729.461731,879.866943 731.709534,879.008972 734.036377,878.561707 
	C742.032837,877.024536 750.593018,880.199158 758.163696,876.162964 
	C758.049622,878.577087 758.142029,880.525330 757.958557,882.447205 
	C757.691162,885.248413 759.013794,886.262756 761.673584,886.565918 
	C761.817505,889.844116 761.940979,892.668213 761.964355,895.493164 
	C762.018616,902.051697 768.595276,909.005127 775.223938,908.947632 
	C801.816833,908.716980 828.408447,908.326477 855.351074,908.188416 
	C861.178162,908.261047 866.654724,908.140381 872.131287,908.019714 
	C872.102539,907.437378 872.073730,906.855042 872.044983,906.272705 
	C866.346802,906.272705 860.648682,906.272705 854.489075,906.212402 
	C828.258484,906.413879 802.489319,906.682373 776.720032,906.931274 
	C771.671326,906.979980 764.357849,901.928894 764.177429,897.613037 
	C763.827515,889.239685 764.075134,880.841248 764.075134,872.174011 
	C772.663757,877.991150 772.663757,877.989258 783.935242,877.829895 
	C786.595520,877.792297 789.289856,877.499512 791.906128,877.825256 
	C794.179321,878.108154 796.364319,879.099365 798.971252,879.894348 
	C800.897278,876.102234 804.833557,877.433472 808.745239,878.603638 
	C809.112122,879.537598 809.387817,880.147400 809.619629,881.142517 
	C809.299377,882.245361 809.023071,882.962769 808.555359,883.752930 
	C808.364014,883.825562 808.065735,884.106018 807.737915,884.197083 
	C806.559082,885.492920 805.708069,886.697632 804.857056,887.902405 
	C805.288025,888.274841 805.718933,888.647339 806.149902,889.019775 
	C807.133911,888.047363 808.117920,887.074951 809.444885,886.009216 
	C810.057190,885.290649 810.326477,884.665405 810.671021,884.058472 
	C810.746216,884.076843 810.951721,883.873047 811.265991,883.752441 
	C813.635437,884.161987 815.909851,885.527222 817.702271,885.056580 
	C822.050049,883.914917 825.645020,884.560059 829.185120,887.694885 
	C825.556213,884.143433 825.691284,880.148254 826.359985,875.840698 
	C826.643921,874.011475 825.803223,872.007690 825.818115,869.853394 
	C827.444763,868.047119 828.726990,866.470642 830.009277,864.894226 
	C829.571533,864.508606 829.133850,864.122925 828.696106,863.737305 
	C826.848389,865.182556 825.000671,866.627808 823.417603,867.866089 
	C818.203430,867.630737 813.152710,867.402832 807.651855,867.154541 
	C808.023315,868.201355 808.745300,870.235718 809.322632,872.591675 
	C808.946838,873.810486 808.715759,874.707581 808.144287,875.493408 
	C805.283081,875.382019 802.762207,875.382019 799.439941,875.382019 
	C800.725403,873.618469 801.321106,872.801208 802.651489,870.975952 
	C800.362061,871.410034 798.280334,871.374207 798.126892,871.900513 
	C796.617798,877.077637 792.464783,875.365173 789.143127,875.487488 
	C784.318726,875.665161 779.482483,875.632324 774.653748,875.548096 
	C770.086426,875.468445 763.948608,869.511597 763.861206,865.027588 
	C763.676086,855.537903 763.787415,846.039246 763.420654,836.558044 
	C763.271484,832.700134 764.885010,831.524109 768.307495,831.590271 
	C771.172546,831.645630 774.039612,831.601685 776.905762,831.601685 
	C776.896545,831.100952 776.887390,830.600220 776.878174,830.099487 
	C774.489624,830.099487 772.100220,830.140076 769.712708,830.090576 
	C765.547363,830.004272 763.293457,828.171326 763.396973,823.671204 
	C763.507751,818.849487 763.209717,814.019531 763.164551,809.192505 
	C763.115906,803.991882 763.153564,798.790405 763.153564,793.589233 
	C763.469604,793.281372 763.785706,792.973450 764.101746,792.665588 
	C767.794800,800.153809 774.234131,799.087646 780.391296,798.958740 
	C783.888000,798.885498 787.416626,798.620605 790.874451,798.981628 
	C793.326355,799.237610 795.684021,800.396362 798.315247,801.225037 
	C799.206055,796.543213 803.756348,799.273499 807.396851,799.128906 
	C808.161743,800.978577 808.636780,802.504150 808.968384,803.569214 
	C807.196045,805.543152 805.699341,807.210083 804.202637,808.876953 
	C804.582092,809.300537 804.961548,809.724121 805.341003,810.147705 
	C807.302490,808.683472 809.264038,807.219238 810.958191,805.954590 
	C815.056091,805.954590 819.112854,805.954590 823.080078,805.954590 
	C824.665771,807.150513 826.413818,808.468811 828.161804,809.787109 
	C824.016846,801.447449 824.550964,793.767822 827.685547,785.435608 
	C825.994385,786.996399 824.303284,788.557129 823.298584,789.484375 
	C818.701843,789.484375 814.732239,789.484375 810.348450,789.484375 
	C809.022949,788.517456 807.051208,787.079041 805.079468,785.640625 
	C804.315125,786.478333 803.550842,787.316040 802.786499,788.153687 
	C804.598328,789.538696 806.410156,790.923706 808.659973,792.643494 
	C808.354004,793.345215 807.594421,795.087158 806.411499,796.830872 
	C803.748474,796.832642 801.508789,796.832642 798.277771,796.832642 
	C799.666138,795.095093 800.352844,794.235718 801.039551,793.376343 
	C800.697449,792.974976 800.355408,792.573608 800.013306,792.172241 
	C798.297485,793.467346 796.581665,794.762512 793.748779,796.900818 
	C788.061340,796.900818 780.766968,796.984985 773.475769,796.868591 
	C769.230103,796.800903 762.995605,790.322388 762.954529,786.093994 
	C762.885193,778.962097 762.903076,771.829346 763.315308,764.714844 
	C768.182556,764.337646 772.618713,763.942566 777.054871,763.547424 
	C777.034180,763.382446 777.013489,763.217407 776.992798,763.052368 
	C772.224854,762.078064 767.456909,761.103699 762.693420,759.663879 
	C763.083374,756.169922 763.468872,753.141541 764.285950,750.240601 
	C768.615295,750.046875 772.548706,749.942444 776.394592,749.307373 
	C778.154114,749.016724 779.696350,747.064636 781.415039,746.940002 
	C785.877930,746.616333 790.384338,746.937866 794.872803,746.913086 
	C813.648987,746.809448 832.426086,746.756958 851.200684,746.517761 
	C862.487915,746.373962 873.771118,745.920593 885.443237,745.726440 
	C889.315735,746.244812 890.505676,748.239258 890.330872,751.650330 
	C890.145325,755.273621 890.196960,758.938232 890.556885,762.543701 
	C890.673706,763.714478 892.088074,764.755676 893.311157,765.896240 
	C898.832703,765.766357 902.612610,763.382629 905.535217,759.124512 
	C915.245117,765.609131 924.601440,771.966797 933.957703,778.324463 
	C933.782166,778.808350 933.606567,779.292175 933.431030,779.776062 
	C928.727234,779.776062 924.023438,779.776062 919.319641,779.776062 
	C919.315918,780.307434 919.312195,780.838745 919.308472,781.370117 
	C923.306580,781.370117 927.306152,781.439575 931.302490,781.354980 
	C938.549744,781.201538 944.473755,784.368042 949.460510,789.143555 
	C951.050720,790.666382 951.748047,793.522644 951.999329,795.860657 
	C952.111877,796.907593 950.089783,798.157104 949.095154,799.363159 
	C948.695312,799.848083 948.067139,800.585693 948.198608,801.000671 
	C949.794983,806.041260 944.324097,812.698486 951.702148,816.467468 
	C952.182495,816.712830 952.319885,817.964661 952.325989,818.750610 
	C952.374634,825.030579 952.353027,831.311096 952.353027,837.848999 
	C949.403198,838.199829 946.299255,838.568970 943.195312,838.938110 
	C944.635315,840.151428 946.203857,839.954041 947.418457,840.518433 
	C949.707458,841.582092 953.102478,841.411865 952.959900,845.539124 
	C952.724609,852.347473 952.841675,859.167908 952.497498,865.986389 
	C951.467224,866.298218 950.749695,866.606995 949.674683,867.091675 
	C949.170898,871.858948 948.891235,876.453125 948.992920,881.038879 
	C949.012756,881.935364 950.362488,883.302673 951.352295,883.595398 
	C957.437317,885.394897 959.932068,883.457214 959.925537,877.207092 
	C959.923828,875.547607 959.638733,873.844666 959.918457,872.237305 
	C960.601135,868.315491 958.843079,866.386414 955.196594,865.290649 
	C955.169312,858.679626 954.996399,852.517517 955.150146,846.363647 
	C955.204895,844.171082 956.523865,841.977905 956.430481,839.821167 
	C956.277405,836.283569 954.681335,832.687805 955.074768,829.275818 
	C955.648987,824.296021 951.648071,817.918579 958.799622,814.523132 
	C959.117676,814.372131 959.056030,813.278137 959.055359,812.623474 
	C959.051758,809.128052 959.141479,805.623901 958.907776,802.142273 
	C958.833252,801.031189 957.924561,799.944763 957.294067,798.910156 
	C956.470337,797.558411 955.484619,796.304626 954.673523,794.946045 
	C952.946533,792.053284 951.861145,788.472900 949.468994,786.351379 
	C945.635437,782.951782 940.935486,780.536133 936.636536,777.652588 
	C931.390198,774.133484 926.179871,770.560669 921.156860,766.670654 
	C921.834106,766.216187 922.325134,765.957825 922.780212,766.010498 
	C926.934448,766.490906 929.829773,764.570007 931.533813,761.022583 
	C933.369507,757.201050 932.550964,753.726562 929.426331,750.753357 
	C928.867798,750.221863 928.825500,749.147766 928.943359,748.314392 
	C933.210144,749.606140 936.499146,741.476318 940.664062,747.609131 
	C941.036804,747.309448 941.398682,746.770508 941.784241,746.752930 
	C943.201782,746.688416 944.634705,746.897461 946.046448,746.794189 
	C946.678223,746.747986 947.273621,746.205933 948.351440,745.884033 
	C954.062561,745.879272 959.307678,745.879272 964.777039,745.879272 
	C964.879944,746.757874 965.043396,747.719299 965.097534,748.686829 
	C965.440613,754.816101 965.704468,760.950684 966.143555,767.072815 
	C966.212341,768.031372 966.928833,769.035767 967.600769,769.792603 
	C967.764282,769.976807 969.395325,769.427429 969.570557,768.936523 
	C970.488770,766.363892 971.877258,763.676575 971.786804,761.074829 
	C971.373779,749.194214 970.560608,737.326233 969.773987,725.461487 
	C969.681946,724.074280 968.864990,722.735107 968.384155,721.373657 
	C967.939270,721.482483 967.494446,721.591370 967.049561,721.700256 
	C967.049561,727.267151 967.049561,732.834045 967.049561,739.045349 
	C964.381287,739.045349 962.263428,738.933960 960.161499,739.069214 
	C956.099426,739.330811 952.046326,739.731445 947.671631,740.053223 
	C946.929626,740.049561 946.505432,740.067200 945.641968,740.031982 
	C943.730957,740.073914 942.259277,740.168579 940.331726,740.262878 
	C929.388794,740.650879 918.781128,739.568848 908.493652,741.701538 
	C906.544373,741.679199 904.515198,741.270203 902.469421,741.155090 
	C902.102417,741.134460 901.230164,742.795532 901.329956,742.888672 
	C902.526184,744.005188 903.862000,744.972290 905.392761,746.157776 
	C903.989807,746.585205 902.607788,747.006287 900.702148,747.586853 
	C903.336548,750.401306 911.059875,744.618408 908.715576,753.086731 
	C907.554810,754.082764 906.735962,755.052673 905.077759,757.016846 
	C900.568909,752.641724 896.221069,748.422852 891.910645,743.726929 
	C891.947998,739.700867 891.947998,736.151794 891.947998,732.804626 
	C894.415100,732.048767 896.256165,731.484741 898.247009,731.274658 
	C898.945618,737.685425 903.615234,735.489014 906.810181,735.503784 
	C908.504333,735.511658 911.740112,733.769531 911.657410,733.195129 
	C911.070679,729.121765 914.644165,723.734375 908.673706,721.461914 
	C909.180237,713.844116 913.365784,712.114014 920.481750,712.103699 
	C929.372681,712.090698 938.868713,714.097473 947.336182,708.443481 
	C954.157166,708.716431 960.651917,708.916870 967.130371,708.676575 
	C968.797363,708.614807 970.412964,707.165894 972.126526,706.317444 
	C976.479675,713.513000 981.355469,715.321045 988.179077,711.292664 
	C986.090393,711.136353 984.635254,710.740845 983.292114,710.978638 
	C978.523376,711.822571 975.940857,709.596069 974.082153,705.442261 
	C972.609497,702.151306 970.503723,699.143738 968.793518,695.658813 
	C970.946960,692.616943 972.985962,689.926636 975.424194,687.371582 
	C977.547607,686.796509 979.271790,686.086121 981.071228,685.784790 
	C982.122559,691.687500 986.421448,689.793091 989.467529,689.431458 
	C990.523804,689.306091 991.727173,686.417542 992.001892,684.656677 
	C992.406738,682.061340 992.205750,679.327515 991.914368,676.691223 
	C991.809509,675.742798 990.696411,674.905884 989.707642,673.978027 
	C988.538025,673.236328 987.704163,672.534729 987.035522,671.545898 
	C990.505310,667.318970 993.809875,663.379272 997.536377,659.393921 
	C1000.616882,658.989563 1003.266357,658.432251 1005.935547,658.312195 
	C1012.044373,658.037354 1017.939392,657.408630 1022.356567,651.976562 
	C1025.321411,652.695923 1028.775024,652.003906 1029.116455,656.419250 
	C1031.040039,659.153381 1033.068970,661.546753 1035.004883,664.220825 
	C1033.704590,671.115601 1039.931396,670.151184 1043.019653,672.135803 
	C1046.293213,674.239380 1046.694214,670.571289 1047.643188,668.775269 
	C1052.304932,659.952698 1051.682251,661.831604 1044.070068,657.772339 
	C1043.484985,657.460388 1042.848633,657.242065 1042.272827,656.915527 
	C1039.339233,655.251587 1037.912842,656.169128 1037.508423,659.559204 
	C1034.781982,657.574646 1032.391235,655.494995 1030.025146,653.062378 
	C1028.768066,646.990845 1024.415161,644.271545 1019.571594,642.100830 
	C1012.526428,638.943420 1005.443542,635.869873 998.236938,632.699097 
	C998.971741,630.902588 999.453064,629.725708 1000.291016,628.689331 
	C1003.256409,629.842407 1005.933167,630.709229 1008.463196,631.890198 
	C1030.770752,642.302856 1053.101807,652.667725 1075.298096,663.313538 
	C1079.567749,665.361389 1084.974854,666.135498 1087.037964,671.674927 
	C1087.361572,672.543640 1089.569946,672.710327 1090.945312,673.566772 
	C1092.988037,681.157349 1099.933228,683.084412 1105.011597,687.013306 
	C1105.011597,687.013306 1105.000000,687.000000 1105.035889,687.340088 
	C1107.053589,688.790588 1109.035400,689.901001 1111.017212,691.011414 
	C1111.017212,691.011414 1111.000122,691.000061 1110.979004,691.315918 
	C1111.300781,692.416992 1111.643555,693.202148 1111.547607,694.033569 
	C1105.157959,694.122070 1099.206787,694.154358 1093.255859,694.209045 
	C1084.444824,694.290039 1075.632935,694.496582 1066.823608,694.416260 
	C1063.824097,694.388855 1060.830933,693.656677 1057.833984,693.246399 
	C1055.796021,693.749695 1053.609741,694.706482 1051.401245,694.761047 
	C1043.008789,694.968689 1033.722656,703.284851 1032.325684,711.816284 
	C1031.074829,711.991028 1030.184082,712.171021 1028.229858,712.565979 
	C1030.296875,713.490723 1031.390869,713.980164 1032.616089,714.528381 
	C1031.034790,715.141541 1029.788940,715.624634 1027.372437,716.561646 
	C1030.098267,717.688599 1031.596558,718.308044 1032.186279,718.551880 
	C1032.186279,722.815430 1032.186279,726.449524 1031.930054,730.341370 
	C1032.172729,733.531250 1032.671631,736.463318 1033.170654,739.395447 
	C1033.878662,739.369629 1034.586670,739.343872 1035.294800,739.318115 
	C1035.443848,736.250000 1035.593018,733.181824 1035.600586,729.672485 
	C1036.514160,724.795654 1034.592651,719.417725 1040.354492,716.226135 
	C1038.626953,715.467285 1037.553101,714.995605 1037.126343,714.808167 
	C1037.938721,713.173157 1038.752808,711.534729 1039.695923,709.636658 
	C1037.414917,710.300110 1036.182739,710.658508 1034.995850,710.645752 
	C1037.011108,702.030151 1046.661743,695.934875 1055.848877,697.531982 
	C1055.824219,699.625305 1055.907104,701.265808 1055.938354,702.907166 
	C1056.065430,709.561829 1059.179932,711.214539 1065.868774,707.557312 
	C1063.052979,707.184570 1060.741577,706.878540 1057.278931,706.420105 
	C1058.381104,702.810913 1059.335083,699.686462 1060.733276,696.501465 
	C1074.685303,696.480591 1088.193115,696.520264 1101.759888,696.981445 
	C1101.818726,705.476013 1101.818726,713.549072 1101.818726,722.454651 
	C1095.636963,722.454651 1090.122925,722.454651 1084.608765,722.454651 
	C1089.125610,723.467346 1093.581055,723.765503 1097.972046,723.362671 
	C1100.907104,723.093323 1102.053223,723.895020 1101.957886,726.721680 
	C1101.913086,728.050598 1101.887939,729.380188 1101.560303,730.885864 
	C1099.951172,731.964172 1098.635620,732.866211 1097.376587,733.729431 
	C1096.746948,732.891785 1096.252686,732.234070 1095.225830,730.867798 
	C1095.225830,733.518311 1095.225830,735.159424 1095.225830,736.642761 
	C1097.050171,737.743408 1098.733276,738.758911 1100.403687,739.766724 
	C1102.333862,738.409180 1104.217407,737.084473 1106.100952,735.759766 
	C1106.229370,736.226501 1106.357788,736.693298 1106.486206,737.160034 
	C1108.283081,736.860474 1110.287720,736.936035 1111.789429,736.103821 
	C1112.545776,735.684631 1112.389038,733.618103 1112.645264,732.296570 
	C1110.676514,732.296570 1108.707764,732.296570 1106.739014,732.296570 
	C1106.525269,732.762268 1106.311646,733.227905 1106.098022,733.693604 
	C1105.440186,732.729126 1104.782227,731.764709 1104.108398,730.381958 
	C1104.245850,727.237976 1102.745728,723.613281 1107.656982,723.494568 
	C1113.074951,723.363525 1118.492310,723.205505 1124.011719,723.504211 
	C1124.113647,726.575439 1124.113647,729.201660 1124.113647,732.148438 
	C1121.331177,732.590454 1118.716431,733.005737 1114.638550,733.653442 
	C1119.644043,734.853394 1124.489868,733.943420 1124.501587,739.908325 
	C1124.530762,754.827209 1124.700806,769.745850 1124.547852,784.874878 
	C1118.875488,790.779358 1113.469604,796.473450 1107.661743,802.066040 
	C1106.479980,801.036804 1105.062256,800.136719 1105.020996,799.177307 
	C1104.686646,791.416016 1104.619263,783.643433 1104.452271,775.874634 
	C1104.371094,772.098511 1104.250977,768.323120 1104.148560,764.547424 
	C1103.899048,764.597351 1103.649414,764.647278 1103.399902,764.697144 
	C1103.399902,778.524658 1103.399902,792.352112 1103.261353,806.496338 
	C1098.454346,811.546265 1093.716675,816.214294 1089.160522,821.053345 
	C1087.835327,822.460815 1087.028931,824.356750 1085.984619,826.028748 
	C1085.984619,826.028748 1086.002197,826.003357 1085.692139,825.778381 
	C1084.609009,826.170288 1083.764648,826.716614 1083.073120,827.414307 
	C1069.020996,841.589294 1054.991333,855.786377 1040.938721,869.960938 
	C1039.940918,870.967407 1038.802734,871.834656 1037.729858,872.766724 
	C1037.239624,872.393188 1036.749268,872.019714 1036.259033,871.646240 
	C1037.369873,870.200012 1038.611450,868.832886 1039.550049,867.282349 
	C1040.295044,866.051270 1040.688843,864.602539 1041.171753,863.226929 
	C1041.211060,863.114990 1040.734497,862.822021 1040.273438,862.415405 
	C1039.770386,863.538635 1039.266968,864.662903 1038.763428,865.787109 
	C1038.072876,865.392334 1037.382324,864.997498 1036.691772,864.602722 
	C1037.161377,861.354492 1037.631104,858.106262 1038.249756,853.827637 
	C1037.982056,853.352905 1037.034790,851.672852 1036.137207,849.560303 
	C1036.914307,846.153992 1033.929077,841.472107 1040.185913,840.836731 
	C1040.250000,840.199585 1040.083496,839.661438 1039.577026,838.946655 
	C1038.478027,837.178833 1037.718994,835.587769 1037.107178,833.826477 
	C1037.165527,833.104614 1037.076416,832.552979 1036.988770,831.577026 
	C1036.816406,830.030518 1036.077148,828.421753 1036.558105,827.862976 
	C1040.437622,823.355591 1035.975220,820.745178 1035.290283,817.311523 
	C1034.796509,817.369019 1034.302856,817.426514 1033.809082,817.484009 
	C1033.809082,824.464966 1033.809082,831.445984 1033.809082,838.703247 
	C1033.173096,838.936401 1031.795410,839.441467 1029.015503,840.460510 
	C1031.931519,841.150146 1033.306519,841.475281 1035.149048,841.911072 
	C1035.149048,844.643982 1035.149048,847.281128 1035.039062,850.232544 
	C1034.888672,850.988831 1034.848511,851.430908 1034.411621,851.935547 
	C1031.157593,852.540283 1028.300171,853.082397 1024.682739,853.768677 
	C1028.719482,855.122375 1031.740967,856.135620 1034.811768,857.578613 
	C1034.714600,861.172485 1036.434448,865.112793 1030.857544,865.923096 
	C1035.777832,868.786255 1036.543579,874.311462 1033.006348,877.913025 
	C1025.420288,885.636902 1017.870605,893.398376 1010.159851,900.996033 
	C1008.714966,902.419800 1006.621948,903.989075 1004.770020,904.064148 
	C993.965881,904.502563 983.144592,904.515747 972.312317,904.303467 
	C972.161194,902.974731 972.026794,902.015137 971.878113,900.772827 
	C971.852844,900.301636 971.841980,900.113220 971.967163,899.568542 
	C972.625183,897.492981 973.147156,895.773682 973.930420,893.788696 
	C974.895508,891.354614 975.531128,889.161194 976.317749,887.023376 
	C977.782593,883.042603 979.839172,882.609741 983.261047,885.255676 
	C984.703430,886.370850 986.432739,887.114990 988.024170,888.003784 
	C988.016724,887.979675 987.966309,887.979553 987.881470,888.341797 
	C988.540771,889.146240 989.284851,889.588501 990.025024,890.004761 
	C990.020996,889.978821 989.969360,889.969177 989.928589,890.385986 
	C990.776306,891.208191 991.664856,891.613586 992.606934,892.107422 
	C992.660583,892.195801 992.975098,892.461304 992.832153,892.843018 
	C996.362732,896.749817 996.542236,893.564514 997.002319,890.972961 
	C996.977417,890.979980 996.973755,891.031616 997.325745,891.103516 
	C999.253174,889.774658 1000.898621,888.444275 1002.391113,886.960144 
	C1007.225159,882.152954 1007.115845,881.574890 1000.986450,877.987671 
	C1000.989868,878.013550 1001.041992,878.011841 1000.893127,877.715881 
	C1000.153076,877.270325 999.561951,877.120789 998.976929,876.996094 
	C998.983154,877.020874 999.034241,877.023926 999.049072,876.705444 
	C998.701233,876.246582 998.338501,876.106201 997.977661,875.991577 
	C997.979553,876.017395 998.029968,876.028809 998.117188,875.663147 
	C995.014099,869.956177 994.328430,869.842163 990.300049,874.146484 
	C989.769348,874.674255 989.388733,875.063660 988.718506,875.668396 
	C985.560364,877.918213 982.678833,879.934753 979.828125,881.993713 
	C978.153198,883.203552 975.927368,884.151245 975.018616,885.804565 
	C973.625366,888.339172 973.154114,891.380676 972.242554,894.218567 
	C972.193604,894.230103 972.110168,894.174133 971.783081,893.883301 
	C971.279541,891.799255 970.962341,890.006958 970.950073,888.212524 
	C970.885193,878.758911 970.988831,869.302856 970.795715,859.852539 
	C970.762146,858.209290 969.587769,856.589233 968.940308,854.958496 
	C968.230591,855.184021 967.520874,855.409485 966.811157,855.635010 
	C967.554199,868.779175 968.297302,881.923340 968.701782,895.335754 
	C967.993042,898.695557 967.622803,901.787170 967.266052,904.905518 
	C967.279480,904.932312 967.216980,904.908447 966.736328,904.951843 
	C951.433472,905.180725 936.611145,905.356018 921.789185,905.555664 
	C913.130676,905.672302 904.364380,906.702942 895.853943,905.648438 
	C890.475220,904.981934 885.797791,906.572632 880.678772,907.151672 
	C882.549500,907.755493 884.420166,908.359314 885.956299,909.105591 
	C883.033997,914.360718 878.790894,915.669434 873.355957,915.063660 
	C869.923340,914.681091 866.405823,915.060547 862.758667,914.770264 
	C859.274658,910.202026 857.469482,915.076477 855.151855,916.074890 
	C854.518555,914.846741 854.076416,913.989319 853.543579,912.955994 
	C850.540710,917.606323 849.838623,917.629761 846.158936,912.874451 
	C846.271973,915.450195 846.354919,917.339050 846.432678,919.111023 
	C850.055603,918.828857 853.760010,917.456116 856.345825,918.603699 
	C859.854614,920.160828 861.192688,918.998413 863.245361,916.664795 
	C867.813416,916.847656 871.946228,916.568665 875.985168,917.060059 
	C882.306030,917.829163 885.250977,914.391296 887.555176,909.143066 
	C891.034607,908.678040 894.086853,907.915039 897.149536,907.870667 
	C914.563782,907.617920 931.980347,907.517700 949.396423,907.408081 
	C955.500183,907.369629 961.604675,907.451416 967.685425,907.864258 
	C967.835449,909.775269 968.008850,911.300293 967.850586,913.105652 
	C967.336914,918.248474 967.155029,923.110962 966.973145,927.973450 
	C966.477112,928.026123 965.648132,927.918091 965.533936,928.154907 
	C962.912842,933.588806 957.959595,931.887268 953.723022,931.916382 
	C935.761475,932.039917 917.797791,931.890076 899.837036,932.066040 
	C880.236755,932.258118 860.638794,932.699829 840.177124,933.181458 
	C834.532959,933.241699 829.751526,933.153259 824.970032,933.064758 
	C824.970032,933.064758 824.507385,933.054382 823.838379,933.028137 
	C821.413452,933.051697 819.657654,933.101562 817.901794,933.151428 
	C817.495117,933.137146 817.088440,933.122864 815.902344,933.062378 
	C809.070923,933.042664 803.018921,933.069153 796.966858,933.095642 
	C796.966858,933.095642 796.498413,933.078918 795.906250,933.045776 
	C794.228760,933.279114 793.143372,933.545532 792.057983,933.812012 
	C790.316650,933.835693 788.575378,933.859375 786.212769,933.651611 
	C785.006409,933.359070 784.421387,933.298035 783.836365,933.236938 
	C776.458069,933.236938 769.079773,933.236938 760.918457,933.236938 
	C760.918457,926.761597 760.918457,920.148621 760.918457,913.535645 
	C760.391541,913.530090 759.864685,913.524597 759.337769,913.519043 
	C759.337769,920.071167 759.337769,926.623291 759.337769,934.004944 
	C750.674438,934.004944 742.791504,934.004944 734.438660,934.045410 
M913.011902,874.254150 
	C911.983643,873.567139 910.333618,872.094238 910.035461,872.330139 
	C908.790161,873.315247 907.950928,874.813660 906.411926,875.796997 
	C905.145203,873.443604 903.699707,871.577209 900.274841,874.010010 
	C900.682190,875.953003 901.128296,878.080688 901.574280,880.208313 
	C903.003418,879.717285 904.432556,879.226196 906.496399,879.180908 
	C908.734375,881.402466 910.929138,882.398132 913.642578,879.170227 
	C918.439697,879.360840 923.260010,879.905029 928.023987,879.591370 
	C930.368713,879.436951 932.597778,876.891846 934.899109,876.859985 
	C938.361572,876.811951 941.841370,878.019775 945.608093,878.750854 
	C945.608093,876.370544 945.608093,874.489014 945.608093,872.085388 
	C943.911255,872.996704 942.653259,873.672302 941.283508,874.407959 
	C940.756836,874.090149 940.211121,873.730957 939.639038,873.420288 
	C934.952087,870.875305 932.906555,871.504578 929.781677,875.746216 
	C928.945312,876.881409 927.062439,877.245728 925.659973,877.963928 
	C925.413452,877.533691 925.166931,877.103455 924.920349,876.673279 
	C926.263733,875.561707 927.607117,874.450195 928.950439,873.338623 
	C926.406799,872.790466 923.705750,874.299316 922.434326,873.508850 
	C918.704102,871.189697 916.551086,873.145081 913.567383,875.129700 
	C913.371033,875.092346 913.174744,875.054993 913.011902,874.254150 
M1034.162109,687.723877 
	C1034.400635,688.807800 1034.639282,689.891724 1034.965942,691.376160 
	C1036.231079,690.437012 1037.127197,689.771790 1038.737305,689.115845 
	C1039.786987,689.357971 1040.836792,689.600098 1042.052734,689.880615 
	C1042.052734,688.176819 1042.052734,686.418823 1042.052734,685.818115 
	C1039.032593,685.541138 1036.532471,685.311890 1033.278198,685.169434 
	C1031.473389,685.387268 1029.668701,685.605164 1029.021729,685.683228 
	C1026.253052,687.462402 1024.164185,688.804688 1022.075378,690.146912 
	C1021.747314,689.648865 1021.419250,689.150757 1021.091187,688.652710 
	C1022.452454,687.560242 1023.813782,686.467773 1025.033936,685.488586 
	C1021.477600,685.126038 1018.216858,684.538574 1014.959290,684.556030 
	C1012.965942,684.566772 1010.977600,685.505920 1008.721375,685.459839 
	C1007.867676,685.079163 1007.014038,684.698486 1006.160339,684.317810 
	C1006.067749,684.700562 1005.975098,685.083374 1005.882446,685.466125 
	C1006.934631,685.634644 1007.986755,685.803162 1009.138367,686.707947 
	C1007.085571,688.146240 1005.032776,689.584473 1002.663879,690.338562 
	C1001.677795,688.420898 1000.691711,686.503296 999.705627,684.585693 
	C998.992920,684.603760 998.280273,684.621765 997.567566,684.639832 
	C997.036560,686.851013 996.505554,689.062134 995.974426,691.273315 
	C998.013367,691.169434 1000.052124,691.063354 1002.091309,690.963928 
	C1002.406738,690.948547 1002.723572,690.963806 1003.340393,691.598389 
	C1005.822632,691.544312 1008.305176,691.437073 1010.787109,691.447388 
	C1015.656006,691.467590 1020.607666,692.113770 1025.363281,691.408997 
	C1028.389160,690.960571 1031.123779,688.547241 1033.998047,687.000244 
	C1033.998047,687.000244 1033.983154,687.009644 1034.162109,687.723877 
M633.089600,801.061218 
	C633.265625,801.198914 633.441711,801.336609 633.351685,802.124817 
	C632.305115,803.007507 631.258606,803.890137 630.212036,804.772827 
	C630.315735,805.163818 630.419434,805.554871 630.523132,805.945923 
	C632.268860,805.669312 634.665527,805.982971 635.590820,804.962463 
	C636.678467,803.763000 636.728516,801.403748 636.714111,799.551453 
	C636.709534,798.962036 635.064026,797.927734 634.163269,797.921082 
	C633.083496,797.913025 631.997253,798.786865 630.913818,799.278748 
	C631.479858,799.716003 632.045898,800.153320 633.089600,801.061218 
M1007.595825,679.245667 
	C1008.810852,679.707336 1010.025879,680.169006 1011.477966,680.720703 
	C1011.108032,678.912964 1011.094543,677.592773 1010.556030,676.541687 
	C1009.918701,675.297668 1008.955200,673.505493 1007.907837,673.335083 
	C1005.700684,672.976013 1002.998474,673.131653 1003.342468,677.341125 
	C1001.611511,677.952087 999.880554,678.562988 998.149597,679.173950 
	C997.976562,678.746460 997.803528,678.318909 997.630432,677.891418 
	C998.902161,676.716064 1000.173889,675.540710 1002.289856,673.585022 
	C998.108643,673.734131 995.746521,674.798462 995.646545,677.919861 
	C995.609924,679.061523 997.724976,681.338989 998.674744,681.244873 
	C1001.497192,680.965149 1004.228333,679.763916 1007.595825,679.245667 
M923.128662,733.956116 
	C921.537598,734.293762 919.946533,734.631348 918.355469,734.968994 
	C918.230896,734.635437 918.106262,734.301880 917.981689,733.968262 
	C919.280151,732.952026 920.578613,731.935730 922.377075,730.528137 
	C918.268005,729.348999 916.316040,731.253540 915.633606,734.022034 
	C915.405151,734.949097 917.672974,737.592651 918.560059,737.484619 
	C921.099854,737.175110 923.516724,735.856995 926.759338,735.160400 
	C931.016296,737.118530 931.374939,737.042969 935.690674,732.578552 
	C934.009521,733.057129 932.328369,733.535767 931.967590,733.638489 
	C930.203857,731.910156 929.151428,729.994751 927.733887,729.669250 
	C924.537476,728.935181 923.251709,730.812073 923.128662,733.956116 
M655.538330,876.560242 
	C654.830566,879.885864 652.618042,880.497620 649.012024,879.947327 
	C650.890564,878.437744 652.294128,877.309814 653.697693,876.181885 
	C653.407654,875.688904 653.117615,875.195923 652.827576,874.702942 
	C651.164612,875.764038 649.256897,876.594116 647.943787,877.985413 
	C647.306152,878.661194 647.406860,881.308472 647.818481,881.475769 
	C649.466553,882.145569 651.359924,882.290710 653.177917,882.430115 
	C654.104370,882.501160 655.063293,882.148132 656.485596,882.493835 
	C659.613708,881.599976 662.741760,880.706116 665.916199,879.799011 
	C661.219543,874.139771 661.219543,874.139771 655.538330,876.560242 
M690.217590,888.082947 
	C687.642090,887.317505 685.093323,886.441528 682.485168,885.811279 
	C678.001892,884.728088 679.322388,889.920471 676.203918,891.524292 
	C675.091003,891.524292 673.978088,891.524292 672.865173,891.524292 
	C672.787781,891.155273 672.710388,890.786194 672.632996,890.417175 
	C674.013794,889.280518 675.394592,888.143799 677.945618,886.043823 
	C674.467834,886.694397 672.385803,887.671387 670.564575,887.315002 
	C664.970703,886.220520 662.528625,887.064514 658.851746,893.335693 
	C665.278687,893.335693 670.955261,893.337708 676.631897,893.323730 
	C676.768372,893.323425 676.904480,893.144592 677.817810,892.993958 
	C678.746521,892.780945 679.988647,892.875122 680.550171,892.302246 
	C683.106384,889.694214 685.621033,889.034363 688.793335,891.343384 
	C689.964233,892.195618 691.643433,892.349670 694.707886,893.350098 
	C692.908691,890.762146 691.937073,889.364563 691.656494,887.978760 
	C692.286316,887.668030 692.916199,887.357300 693.546082,887.046570 
	C693.250732,886.773193 692.955383,886.499817 692.660095,886.226440 
	C692.116211,886.828308 691.572327,887.430176 690.217590,888.082947 
M716.547974,771.796082 
	C719.075806,773.931946 720.925293,772.691711 722.110718,770.352112 
	C722.559509,769.466370 722.423401,767.602234 721.780273,766.966125 
	C719.307373,764.519470 717.705811,766.819641 715.415344,767.996826 
	C711.864136,767.311279 708.312927,766.625732 704.358276,765.862305 
	C704.358276,768.451721 704.358276,770.503967 704.358276,772.295837 
	C708.783020,774.826233 712.517883,773.699524 716.547974,771.796082 
M1073.353516,811.031067 
	C1072.035889,810.961914 1070.718262,810.892761 1068.554565,810.779175 
	C1070.536865,808.941833 1071.764893,807.803650 1072.992920,806.665466 
	C1070.933960,806.480835 1069.439087,807.170715 1068.081177,808.067810 
	C1066.697632,808.981934 1065.448853,810.099915 1064.140991,811.128540 
	C1063.393799,809.639160 1062.646484,808.149841 1061.455078,805.775208 
	C1059.213257,806.080261 1056.298828,806.129639 1053.730591,807.097778 
	C1053.166748,807.310364 1053.834473,810.790283 1053.926514,812.598389 
	C1056.327515,811.455627 1058.172119,810.577698 1060.054688,809.681763 
	C1061.764893,814.365906 1068.845947,815.370178 1074.692749,812.392761 
	C1076.560791,812.830078 1078.465088,813.717529 1080.286133,813.570740 
	C1081.923096,813.438843 1084.208984,812.561646 1084.869751,811.307678 
	C1085.723022,809.688354 1085.244873,807.367554 1085.340088,805.348816 
	C1084.827637,805.346863 1084.315186,805.344971 1083.802734,805.343079 
	C1083.739014,806.772583 1083.675293,808.202087 1083.611572,809.631653 
	C1082.140503,809.373596 1081.700195,808.440430 1081.024902,807.731079 
	C1080.229370,806.895325 1078.903687,805.464417 1078.382935,805.680054 
	C1077.010986,806.248230 1075.795776,807.442383 1074.824463,808.636536 
	C1074.253662,809.338074 1074.267822,810.515564 1073.353516,811.031067 
M906.369202,811.117065 
	C911.453552,813.349609 916.434937,812.990723 921.706665,810.623047 
	C924.651672,814.821594 929.731140,812.517334 931.358093,810.573486 
	C934.814819,806.443420 935.790771,809.819824 936.857361,810.811584 
	C939.841675,810.328064 942.105286,809.961365 944.688232,809.542908 
	C944.688232,808.329529 944.688232,806.377686 944.688232,805.677429 
	C941.767334,805.204773 939.584778,804.685730 937.377136,804.535339 
	C934.456909,804.336548 930.767395,801.761841 929.469238,807.280090 
	C929.180054,808.509583 926.464355,809.168213 924.865845,810.089661 
	C924.609863,809.652466 924.353882,809.215271 924.097839,808.778076 
	C925.422241,807.627747 926.746643,806.477417 928.071045,805.327087 
	C925.807373,804.856079 924.111206,805.891052 922.411072,805.897583 
	C916.577393,805.919922 910.742310,805.581909 904.506348,804.804871 
	C903.083130,805.153076 901.659851,805.501282 900.236633,805.849548 
	C900.839478,807.166748 901.442322,808.483887 901.634155,808.902954 
	C900.844055,809.559448 900.098083,810.179321 899.352173,810.799133 
	C899.883789,811.514343 900.415405,812.229614 900.947021,812.944824 
	C902.618774,812.120911 904.290527,811.297058 906.369202,811.117065 
M1035.292358,795.228821 
	C1030.372314,795.677673 1025.452271,796.126526 1021.189697,796.515381 
	C1018.723267,795.902405 1016.552917,795.362976 1014.187744,794.775146 
	C1014.187744,798.205811 1014.187744,800.432434 1014.187744,803.004333 
	C1019.285828,802.575500 1024.047852,802.174927 1029.392822,801.725281 
	C1031.653076,806.802734 1036.554443,799.865845 1040.885254,802.420105 
	C1043.209961,803.791138 1048.747803,799.713928 1052.841064,798.093201 
	C1052.971558,798.956909 1053.166138,800.245300 1053.236328,800.709595 
	C1055.953247,800.709595 1058.352173,800.709595 1060.894043,800.709595 
	C1060.894043,799.016235 1060.894043,797.025452 1060.894043,794.594543 
	C1059.277588,795.793335 1058.145508,796.632935 1058.179321,796.607849 
	C1053.414795,795.663757 1049.855103,794.958374 1045.770996,794.149170 
	C1046.501343,799.606201 1044.015503,800.529053 1039.154419,799.921021 
	C1041.203003,798.185608 1042.561035,797.035095 1044.258911,795.596680 
	C1041.144531,795.596680 1038.604614,795.596680 1035.292358,795.228821 
M812.764709,847.995728 
	C808.069885,846.841492 805.400818,848.629517 804.508362,853.524963 
	C804.215515,851.983337 803.922729,850.441711 803.475342,848.085999 
	C797.571106,848.085999 791.568848,847.978394 785.575256,848.157654 
	C783.972839,848.205627 782.396606,849.130371 780.808411,849.652649 
	C781.400757,851.101013 781.993103,852.549377 782.572998,853.967346 
	C782.267822,854.257996 781.749207,854.751892 780.177673,856.248535 
	C782.817993,856.248535 784.141968,856.335938 785.451111,856.233337 
	C791.040344,855.795349 797.026306,853.838196 802.101501,855.229431 
	C807.429016,856.689819 808.188171,854.138489 809.591431,850.478821 
	C810.127563,852.414795 810.527222,853.858154 811.156006,856.128845 
	C812.110657,852.880310 812.771790,850.630371 812.764709,847.995728 
M1032.892944,768.386780 
	C1034.665283,767.080688 1036.437500,765.774536 1038.567871,764.204590 
	C1038.743286,764.388794 1039.621338,765.310608 1040.659058,766.400024 
	C1041.525513,764.171997 1042.348755,762.055298 1043.007812,760.360474 
	C1036.096069,760.837219 1029.319214,757.131836 1022.682617,761.049744 
	C1022.327087,760.762817 1021.562195,759.640930 1020.798706,759.641968 
	C1019.498230,759.643677 1017.275208,760.091858 1017.073120,760.811218 
	C1016.484192,762.907898 1016.426147,765.279114 1016.796326,767.433411 
	C1016.891357,767.986511 1019.697998,768.834961 1020.638916,768.348511 
	C1024.556763,766.323181 1028.365601,768.120911 1032.892944,768.386780 
M847.879089,850.389404 
	C846.663025,849.308167 845.371094,847.234192 844.244263,847.320374 
	C839.767212,847.662476 835.341736,848.679016 830.148010,849.574951 
	C828.746765,848.110535 822.375488,846.814941 821.717590,848.777588 
	C821.266968,850.121704 822.714111,852.101929 823.427551,854.122559 
	C823.566284,854.001831 822.949219,854.538879 822.332153,855.075867 
	C822.522705,855.504089 822.713257,855.932251 822.903809,856.360474 
	C825.666443,855.646362 828.429077,854.932312 831.639160,854.102600 
	C834.835571,857.409790 841.644287,856.478271 847.879089,850.389404 
M967.067444,796.013672 
	C967.175964,797.427002 967.389282,798.841431 967.375610,800.253540 
	C967.304077,807.619629 967.213501,814.986145 967.043030,822.350403 
	C966.989624,824.653748 966.663757,826.950623 966.467407,829.250854 
	C966.157715,832.880005 964.620667,836.913574 970.916870,839.560852 
	C971.292419,835.776062 972.012695,832.537170 971.844971,829.344910 
	C971.526245,823.278931 970.464783,817.251892 970.147766,811.185913 
	C969.810059,804.725830 970.050720,798.237244 969.869507,791.765869 
	C969.826965,790.247314 969.103149,788.747925 968.692749,787.239685 
	C968.106323,787.329773 967.519836,787.419861 966.933411,787.509949 
	C966.933411,790.053284 966.933411,792.596619 967.067444,796.013672 
M1021.079712,784.475952 
	C1018.821167,785.123047 1016.562622,785.770142 1014.270203,786.426941 
	C1014.140564,787.591125 1013.935913,789.429810 1013.676514,791.759644 
	C1015.472473,790.366333 1016.708740,789.407227 1016.927795,789.237244 
	C1020.414368,790.321899 1022.874878,791.736084 1025.310425,791.694092 
	C1029.033569,791.630066 1032.739502,790.560974 1036.691162,789.877258 
	C1036.170776,788.204956 1036.105835,784.940063 1034.570068,783.944702 
	C1033.115112,783.001831 1030.117676,784.438721 1027.798706,784.828979 
	C1027.786987,784.703735 1027.775391,784.578491 1027.763672,784.453247 
	C1025.771484,784.453247 1023.779358,784.453247 1021.079712,784.475952 
M792.083313,759.113525 
	C789.398621,759.682617 786.713867,760.251709 784.148071,760.795593 
	C782.766052,762.843872 781.267212,765.065186 779.760315,767.298523 
	C784.562256,766.821777 789.659729,766.315674 796.431580,765.643311 
	C797.109924,765.500610 799.421021,765.379639 801.305481,764.420349 
	C801.966553,764.083801 801.579529,761.688110 801.671082,760.232788 
	C799.553223,760.348572 797.435364,760.464355 794.306335,760.635437 
	C794.513367,760.816650 793.651245,760.062012 792.083313,759.113525 
M900.488770,865.207031 
	C900.770142,866.129578 900.932800,867.791870 901.351440,867.858948 
	C905.138123,868.466553 909.009827,869.281616 912.768616,868.970764 
	C914.318909,868.842590 915.654663,866.119934 917.087891,864.577209 
	C915.728088,863.448608 914.443848,861.551575 912.987061,861.408325 
	C911.540649,861.266052 909.920654,862.888611 908.376770,863.737183 
	C906.738770,862.108826 904.877747,861.148254 903.124512,861.315735 
	C902.124512,861.411377 901.300293,863.346741 900.488770,865.207031 
M901.733948,799.935974 
	C904.650574,800.390869 907.583374,801.262878 910.477905,801.149963 
	C912.324463,801.077942 914.331055,799.800293 915.809021,798.531128 
	C916.311646,798.099609 915.843140,795.378845 915.065125,794.880554 
	C913.844116,794.098755 911.987183,794.282715 910.393188,794.119690 
	C909.200684,793.997742 907.998535,793.925293 906.800049,793.916382 
	C904.769104,793.901306 902.737793,793.953735 900.706604,793.977783 
	C900.853333,796.055115 901.000000,798.132446 901.733948,799.935974 
M1018.084045,808.499146 
	C1022.740234,806.837280 1025.284302,809.298584 1026.781738,813.065369 
	C1027.945557,811.402832 1029.031738,809.851257 1030.567505,807.657410 
	C1031.147461,810.230408 1031.484253,811.724792 1031.818115,813.206116 
	C1033.196655,812.281616 1034.307495,811.684265 1035.235229,810.876648 
	C1036.370728,809.888306 1037.352905,808.723816 1038.401245,807.635498 
	C1037.045288,807.061340 1035.696899,806.017639 1034.332153,805.995911 
	C1029.016724,805.910950 1023.686646,806.007507 1018.383423,806.359863 
	C1016.886353,806.459290 1014.659668,807.322632 1014.191895,808.434692 
	C1013.478577,810.130737 1014.004944,812.348206 1014.004944,814.682434 
	C1015.346375,812.152466 1016.307861,810.338989 1018.084045,808.499146 
M665.337402,901.468689 
	C665.665588,902.458801 665.993835,903.448914 666.372986,904.592773 
	C667.573792,903.595337 668.432434,902.926697 669.239563,902.200867 
	C670.433228,901.127441 671.590454,900.013367 672.763184,898.916565 
	C671.182190,898.414490 669.587463,897.452087 668.022827,897.498657 
	C663.722290,897.626770 659.585266,899.432922 655.062561,897.760681 
	C653.332825,897.121094 650.860535,898.489807 649.219177,898.838623 
	C651.478577,900.610535 653.032349,901.829041 654.378601,902.884827 
	C654.927124,902.213989 656.345825,900.180237 656.538269,900.290283 
	C658.233154,901.259705 659.709839,902.610657 660.781982,903.471802 
	C662.147583,902.624207 663.629272,901.704651 665.337402,901.468689 
M788.075867,833.723572 
	C789.385742,831.641357 790.630005,832.424805 792.520447,833.192017 
	C793.609070,833.633789 795.765991,832.094910 797.062317,830.998596 
	C797.481812,830.643860 796.786987,827.930481 796.442932,827.896362 
	C795.051025,827.758057 793.545532,828.091980 792.185303,828.555298 
	C791.355469,828.838013 790.714233,829.674316 789.988342,830.261963 
	C789.951904,829.812256 789.915527,829.362610 789.879089,828.912903 
	C786.733215,828.490662 783.587280,828.068420 780.293701,827.626343 
	C780.293701,829.797729 780.293701,831.409607 780.293701,833.728149 
	C782.767456,833.866516 785.102722,833.997070 788.075867,833.723572 
M720.317017,776.050598 
	C714.930176,776.324585 709.543274,776.598633 704.013000,776.879944 
	C705.153259,779.307129 706.076050,781.271362 707.249146,783.768311 
	C707.819641,781.686707 708.190430,780.333984 708.561157,778.981323 
	C709.226318,778.444702 709.891418,777.908081 710.556519,777.371521 
	C711.590088,779.039795 712.623596,780.708008 713.411438,781.979614 
	C713.977905,781.100037 714.810913,779.806641 716.199951,777.649841 
	C717.587708,780.656372 718.083313,781.730103 718.629333,782.913025 
	C719.660645,782.172729 720.255981,781.594116 720.967712,781.276489 
	C721.706665,780.946716 722.639648,781.025330 723.346924,780.655762 
	C726.944153,778.776062 725.896790,776.540466 720.317017,776.050598 
M916.991943,781.264099 
	C915.174438,774.890320 912.250793,779.561035 909.771484,780.602234 
	C904.840759,776.021057 902.099121,776.998047 899.945618,785.423218 
	C903.741211,784.885376 906.934753,784.432861 911.059570,783.848328 
	C912.694702,785.912109 914.870361,785.059753 916.991943,781.264099 
M1004.741516,714.530151 
	C1005.497742,712.886353 1006.253906,711.242554 1007.010132,709.598755 
	C1005.316284,709.471985 1003.618713,709.380371 1001.929871,709.205261 
	C1000.895569,709.098022 999.879639,708.822021 998.846497,708.694153 
	C997.129761,708.481812 995.405762,708.327698 993.684753,708.149048 
	C993.622314,708.608948 993.559875,709.068848 993.497437,709.528748 
	C994.641479,709.988647 995.785522,710.448547 997.739502,711.234009 
	C995.007141,712.699097 993.061584,713.742310 990.936218,714.881958 
	C994.307068,715.039429 998.837952,718.695923 999.080322,712.103821 
	C1000.687683,712.994568 1002.410400,713.949280 1004.741516,714.530151 
M934.155090,842.989990 
	C937.231873,843.162964 939.141541,842.115845 938.801636,837.880249 
	C936.136902,838.442871 933.706726,838.955933 931.267639,839.470825 
	C930.797791,838.740417 930.238403,837.870850 929.646362,836.950500 
	C927.900024,838.208069 926.459839,839.245117 924.866638,840.392334 
	C924.137390,839.268860 923.603333,838.446167 922.551514,836.825745 
	C922.551514,839.562073 922.551514,841.299316 922.551514,843.047852 
	C926.415588,842.843811 930.074829,842.650574 934.155090,842.989990 
M720.635010,897.550415 
	C720.076233,897.159546 719.517456,896.768677 718.642212,896.156494 
	C718.642212,898.224426 718.642212,899.720093 718.642212,901.514404 
	C712.581360,895.166687 711.156677,895.369690 708.108582,903.982239 
	C711.849304,904.262085 715.336609,905.114319 718.508240,904.453003 
	C719.684875,904.207581 720.121094,900.411438 720.635010,897.550415 
M1075.422363,707.796326 
	C1075.188965,708.216858 1074.955566,708.637329 1074.722046,709.057678 
	C1074.563354,709.102966 1074.404663,709.148254 1074.245972,709.193542 
	C1074.056396,707.801575 1073.866943,706.409546 1073.464355,703.453857 
	C1071.064331,705.470093 1069.542358,706.748596 1068.020508,708.027161 
	C1069.801758,709.168640 1071.504150,710.477417 1073.401489,711.372986 
	C1074.214233,711.756592 1075.489746,711.460999 1076.454468,711.155823 
	C1078.243530,710.589966 1080.068604,709.958862 1081.615479,708.950806 
	C1081.939697,708.739624 1081.125854,706.781982 1080.836304,705.629089 
	C1079.176880,706.163940 1077.517334,706.698853 1075.422363,707.796326 
M1021.029175,851.635010 
	C1019.028076,852.124634 1017.026978,852.614258 1014.834656,853.150635 
	C1012.510742,849.761597 1010.471375,850.062805 1007.942627,855.343323 
	C1010.867188,855.885071 1013.642273,856.313110 1016.376099,856.925110 
	C1020.830566,857.922302 1021.851685,857.030212 1021.029175,851.635010 
M966.284485,774.037354 
	C966.224121,776.152283 965.862000,778.323608 966.241699,780.356384 
	C966.409668,781.255798 968.079834,781.874634 969.068054,782.620850 
	C969.365417,780.279053 969.953430,777.918091 969.801636,775.605774 
	C969.748901,774.803284 967.862793,774.121216 966.284485,774.037354 
M688.989868,899.404358 
	C689.287720,900.923767 689.585510,902.443176 689.875488,903.922546 
	C692.578552,902.375610 694.963806,901.010559 697.349060,899.645508 
	C697.127441,899.046143 696.905762,898.446777 696.684143,897.847412 
	C694.194397,898.097168 691.704651,898.346985 688.989868,899.404358 
M967.275269,849.802063 
	C968.051025,849.884827 968.826721,849.967590 969.602478,850.050354 
	C969.602478,846.919617 969.602478,843.788879 969.602478,840.658203 
	C968.769470,840.661499 967.936401,840.664856 967.103394,840.668152 
	C967.103394,843.444641 967.103394,846.221130 967.275269,849.802063 
M704.080627,903.555176 
	C704.277771,901.933777 704.474854,900.312317 704.671997,898.690918 
	C703.641846,900.465210 701.252869,902.143311 704.080627,903.555176 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1035.394043,915.908936 
	C1033.578003,926.599915 1026.955566,933.759155 1017.882446,933.687378 
	C1017.882446,930.157715 1017.947327,926.596680 1017.864014,923.039185 
	C1017.783264,919.591858 1017.550293,916.148071 1017.364136,912.286377 
	C1030.050903,912.286377 1042.718506,912.286377 1054.990234,912.286377 
	C1054.990234,919.536987 1054.990234,926.559753 1054.990234,933.591003 
	C1045.679932,933.240051 1039.913452,925.897766 1037.851929,919.365845 
	C1037.424561,918.011719 1036.356079,916.859924 1035.394043,915.908936 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M931.733521,936.861450 
	C914.364380,936.865906 897.470154,936.865906 880.575867,936.865906 
	C880.459656,936.286072 880.343445,935.706238 880.227234,935.126404 
	C881.991821,934.771057 883.753296,934.125732 885.521423,934.107300 
	C897.631958,933.980835 909.745117,934.086182 921.854858,933.921814 
	C935.395264,933.738037 948.932251,933.309875 962.472412,933.094727 
	C964.196716,933.067261 965.931213,933.682861 967.661011,934.000549 
	C967.610535,934.513367 967.560120,935.026123 967.509644,935.538879 
	C955.742554,935.978271 943.975464,936.417664 931.733521,936.861450 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M967.335571,928.043823 
	C967.155029,923.110962 967.336914,918.248474 967.847412,913.031128 
	C968.175964,912.676270 968.324036,912.658020 968.245483,912.929016 
	C968.317627,913.486328 968.468384,913.772705 968.619141,914.059021 
	C968.733765,913.650879 968.848450,913.242737 969.319702,912.770874 
	C970.396057,912.628418 971.115723,912.549622 971.835449,912.470825 
	C971.835449,920.279907 971.835449,928.088928 971.835449,935.898010 
	C971.408630,935.850525 970.981812,935.803101 970.554993,935.755615 
	C969.602661,933.208496 968.650269,930.661316 967.335571,928.043823 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M797.209839,933.369019 
	C803.018921,933.069153 809.070923,933.042664 815.597229,933.060181 
	C816.367310,937.019226 814.494568,938.486206 810.705994,938.016296 
	C809.245972,937.835205 807.725769,937.837219 806.265564,938.018433 
	C802.319946,938.508301 798.913330,938.082153 797.209839,933.369019 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M971.966736,912.134399 
	C971.115723,912.549622 970.396057,912.628418 969.179321,912.656738 
	C968.682373,912.606262 968.324036,912.658020 968.324036,912.658020 
	C968.324036,912.658020 968.175964,912.676270 968.179138,912.750793 
	C968.008850,911.300293 967.835449,909.775269 967.618835,907.270020 
	C967.456299,905.909729 967.336975,905.529663 967.258606,905.085999 
	C967.299561,905.022461 967.252625,904.878723 967.252625,904.878723 
	C967.622803,901.787170 967.993042,898.695557 969.053955,895.421692 
	C970.533142,894.884277 971.321655,894.529175 972.110168,894.174072 
	C972.110168,894.174133 972.193604,894.230103 972.476807,894.288696 
	C973.106506,894.384705 973.409607,894.287109 973.669189,894.054443 
	C973.147156,895.773682 972.625183,897.492981 971.734497,899.647583 
	C971.541321,900.407166 971.716858,900.731384 971.892456,901.055542 
	C972.026794,902.015137 972.161194,902.974731 972.289856,904.759766 
	C972.341187,906.189575 972.398254,906.793945 972.455383,907.398254 
	C972.438538,907.746948 972.421753,908.095642 972.464111,908.699341 
	C972.381531,909.902222 972.239746,910.850037 971.966736,912.134399 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M825.155823,933.401550 
	C829.751526,933.153259 834.532959,933.241699 839.716553,933.206299 
	C840.933777,934.183472 841.748962,935.284546 842.564087,936.385620 
	C841.146179,936.911926 839.761963,937.728027 838.302673,937.897705 
	C836.032471,938.161621 833.692383,937.803894 831.416016,938.039551 
	C828.017395,938.391418 825.718201,937.505127 825.155823,933.401550 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M783.772827,933.609131 
	C784.421387,933.298035 785.006409,933.359070 785.777100,933.653198 
	C786.658325,934.867920 787.353882,935.849670 788.429199,937.367310 
	C782.170959,937.367310 776.702148,937.367310 771.233276,937.367310 
	C771.187195,936.846375 771.141174,936.325439 771.095093,935.804504 
	C775.299866,935.196716 779.504578,934.588928 783.772827,933.609131 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M732.991943,936.715210 
	C727.880493,937.526306 722.737610,938.087097 717.581726,938.474609 
	C717.123413,938.509033 716.580383,937.416077 716.076721,936.846741 
	C716.878967,936.258728 717.664673,935.190857 718.486084,935.162598 
	C722.939636,935.009583 727.402161,935.113708 732.300049,935.190063 
	C732.811340,935.640015 732.884155,936.029175 732.991943,936.715210 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M752.936890,935.065186 
	C755.217407,935.239624 757.037415,935.411804 758.857483,935.584045 
	C758.849365,936.125305 758.841309,936.666504 758.833191,937.207764 
	C753.770691,937.207764 748.708130,937.207764 743.645630,937.207764 
	C743.565979,936.753418 743.486328,936.299072 743.406677,935.844727 
	C746.429932,935.584167 749.453125,935.323547 752.936890,935.065186 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M856.480835,937.054443 
	C855.155334,936.880920 854.267090,936.708618 853.083252,936.479004 
	C856.294067,933.373779 865.791565,933.365173 869.423340,937.055725 
	C864.647217,937.055725 860.782654,937.055725 856.480835,937.054443 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M817.937256,933.508301 
	C819.657654,933.101562 821.413452,933.051697 823.606934,933.031250 
	C822.863159,934.256226 821.681824,935.451721 820.500549,936.647217 
	C819.657959,935.719910 818.815308,934.792542 817.937256,933.508301 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M791.908691,934.096924 
	C793.143372,933.545532 794.228760,933.279114 795.671997,933.043091 
	C795.671021,934.216919 795.312256,935.360474 794.879028,936.741150 
	C794.130676,935.847168 790.216187,939.437927 791.908691,934.096924 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M763.594727,935.085815 
	C765.466370,935.096313 766.963928,935.174438 768.461426,935.252625 
	C768.288940,935.916443 768.116516,936.580200 767.944092,937.244019 
	C766.369629,936.547180 764.795166,935.850342 763.594727,935.085815 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M990.761963,912.050049 
	C990.416809,912.322021 989.776917,912.593994 989.136963,912.865967 
	C989.084656,912.688782 989.032288,912.511658 988.979919,912.334534 
	C989.475708,912.239685 989.971436,912.144836 990.761963,912.050049 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M2030.901978,628.009460 
	C2030.880859,633.300476 2030.880859,638.095520 2030.880859,643.676575 
	C2030.417847,642.861938 2030.232544,642.682617 2030.218872,642.491028 
	C2029.287720,629.433960 2028.323975,616.378967 2027.538086,603.313354 
	C2027.474609,602.259399 2028.640625,601.131592 2029.235352,600.038086 
	C2029.680420,601.113037 2030.483398,602.178101 2030.513428,603.264526 
	C2030.736084,611.345154 2030.805664,619.429993 2030.901978,628.009460 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2127.648438,606.178772 
	C2125.171631,605.470337 2123.101318,604.829773 2121.031250,604.189209 
	C2121.248047,603.432434 2121.464844,602.675598 2121.681396,601.918823 
	C2130.437988,604.095703 2139.194336,606.272583 2148.610840,608.613586 
	C2145.296631,611.396606 2139.111816,610.417969 2127.648438,606.178772 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2082.207031,597.882935 
	C2076.988281,597.107300 2072.214844,596.371460 2067.441162,595.635681 
	C2067.527100,595.011047 2067.613037,594.386475 2067.698730,593.761841 
	C2076.125244,594.681641 2084.551514,595.601440 2092.978027,596.521240 
	C2092.918213,597.276672 2092.858643,598.032104 2092.799072,598.787598 
	C2089.416748,598.499268 2086.034424,598.210999 2082.207031,597.882935 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2009.118652,565.555664 
	C2014.795166,565.089783 2020.452759,565.014893 2026.582275,564.899109 
	C2026.339233,565.664978 2025.663818,567.126282 2024.902832,567.172241 
	C2019.955322,567.470947 2014.989136,567.491211 2010.029175,567.484192 
	C2009.718628,567.483704 2009.409424,566.482300 2009.118652,565.555664 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M2027.851807,589.446228 
	C2027.833984,590.189636 2027.787354,590.645020 2027.740723,591.100403 
	C2027.593872,591.105469 2027.447144,591.110535 2027.300293,591.115601 
	C2027.300293,590.405945 2027.291992,589.696045 2027.312012,588.986938 
	C2027.314087,588.909790 2027.478760,588.837158 2027.567993,588.762451 
	C2027.652954,588.894287 2027.737915,589.026245 2027.851807,589.446228 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M681.639038,94.013115 
	C691.011169,96.267052 696.432312,101.941841 698.173645,110.966095 
	C699.405823,117.351799 695.427246,126.267479 688.514099,130.114395 
	C682.084045,133.692474 673.182129,133.135834 668.035217,128.581467 
	C661.469360,122.771469 658.533264,114.469360 660.834534,108.221062 
	C664.405029,98.526932 670.712769,94.125595 681.639038,94.013115 
M673.253723,99.042061 
	C673.255005,99.672401 673.242249,100.854630 673.259583,100.855072 
	C676.445068,100.936096 679.632751,100.933090 683.572449,100.933090 
	C680.371338,108.125481 677.705261,114.115837 675.039185,120.106194 
	C675.627686,120.356277 676.216248,120.606361 676.804749,120.856445 
	C680.069397,113.952538 683.334045,107.048630 687.134460,99.011620 
	C681.656311,99.011620 677.873108,99.011620 673.253723,99.042061 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2404.797852,1033.315308 
	C2387.204590,1033.315308 2369.611572,1033.315308 2351.594482,1033.315308 
	C2351.594482,1027.093750 2351.594482,1020.419678 2351.594482,1012.968628 
	C2355.470947,1012.968628 2358.750732,1012.968628 2362.479980,1012.877563 
	C2369.398682,1012.857605 2375.867920,1012.931702 2382.337402,1012.997375 
	C2383.500488,1013.009155 2384.918945,1012.559875 2385.772949,1013.070435 
	C2387.419189,1014.054504 2388.574219,1016.001526 2390.279541,1016.686768 
	C2391.128418,1017.027893 2392.839844,1015.591736 2393.994629,1014.735107 
	C2397.971191,1011.785583 2401.555664,1014.542175 2405.291748,1015.300232 
	C2405.308838,1015.303711 2405.560059,1014.152039 2405.745117,1013.353333 
	C2407.431885,1014.452393 2408.704346,1018.532593 2411.352051,1014.134949 
	C2411.736572,1013.496094 2415.135986,1015.118042 2417.018799,1014.847900 
	C2421.761230,1014.167297 2425.785400,1014.990234 2428.960205,1018.786682 
	C2429.268311,1019.155151 2429.747070,1019.579956 2430.177490,1019.616699 
	C2435.361572,1020.058838 2440.547363,1020.531616 2445.742920,1020.733826 
	C2446.681152,1020.770325 2447.665283,1019.623047 2448.905518,1018.849426 
	C2448.905518,1020.053833 2448.905518,1021.171875 2448.920654,1023.107666 
	C2448.935791,1026.824341 2448.935791,1029.723145 2448.935791,1033.315308 
	C2433.637207,1033.315308 2419.217529,1033.315308 2404.797852,1033.315308 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2405.519043,1010.839600 
	C2404.269287,1010.401001 2403.351562,1009.924011 2401.807373,1009.121277 
	C2398.579102,1009.679260 2394.085693,1010.455872 2389.592285,1011.232544 
	C2389.427490,1010.551392 2389.262695,1009.870300 2389.097900,1009.189148 
	C2391.639160,1008.470825 2394.166016,1007.198303 2396.723633,1007.132690 
	C2406.375488,1006.885193 2416.037598,1006.979065 2425.694824,1007.057068 
	C2428.852051,1007.082581 2432.006348,1007.448853 2435.654297,1008.699768 
	C2433.823975,1009.433899 2431.960693,1010.865601 2430.169678,1010.780579 
	C2422.676514,1010.424805 2415.208008,1009.570190 2407.719727,1009.055420 
	C2407.139648,1009.015564 2406.476074,1010.190735 2405.519043,1010.839600 
M2421.590332,1008.491943 
	C2421.814453,1008.544373 2422.038818,1008.596863 2422.262939,1008.649292 
	C2422.119873,1008.515747 2421.976807,1008.382141 2421.590332,1008.491943 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2431.017578,903.541077 
	C2426.891602,904.053589 2422.750244,904.124268 2418.615967,904.335571 
	C2414.770020,904.531982 2410.256348,903.721313 2407.270264,905.465698 
	C2403.914795,907.426025 2401.417236,910.276367 2396.524658,909.345581 
	C2398.808838,906.885376 2400.522949,905.038940 2402.237061,903.192505 
	C2410.088867,901.381775 2419.048340,903.529602 2427.435547,898.850952 
	C2428.874512,900.255676 2429.939697,901.708130 2431.017578,903.541077 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2444.809570,1002.510742 
	C2445.313965,1002.073242 2445.764160,1002.019287 2446.551270,1001.945801 
	C2447.241699,1003.666504 2448.105957,1005.463684 2444.914551,1005.482422 
	C2422.526367,1005.613586 2400.139160,1005.850891 2377.751465,1006.037170 
	C2376.793945,1006.045166 2375.834961,1005.885315 2374.876953,1005.803711 
	C2374.873779,1005.400330 2374.870605,1004.997009 2374.867188,1004.593628 
	C2381.382080,1004.593628 2387.897949,1004.668396 2394.411621,1004.578918 
	C2410.823486,1004.353455 2427.234131,1004.059753 2443.644531,1003.747742 
	C2444.020264,1003.740540 2444.385254,1003.190857 2444.809570,1002.510742 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2404.852539,1033.590820 
	C2419.217529,1033.315308 2433.637207,1033.315308 2448.935791,1033.315308 
	C2448.935791,1029.723145 2448.935791,1026.824341 2448.952637,1023.469604 
	C2449.351562,1022.998047 2449.733398,1022.982300 2450.501465,1023.403687 
	C2450.925049,1027.561523 2450.962402,1031.282104 2451.000000,1035.002686 
	C2435.974365,1035.030029 2420.948730,1035.057251 2405.455078,1035.035889 
	C2404.960693,1034.613647 2404.934082,1034.240112 2404.852539,1033.590820 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2450.171875,965.684448 
	C2450.349121,967.463440 2450.396729,968.851135 2450.630371,970.527100 
	C2450.109375,970.863770 2449.402832,970.912292 2448.339844,970.993774 
	C2446.730713,963.429626 2445.412354,955.842407 2444.269287,948.228821 
	C2443.972900,946.253662 2444.225098,944.196167 2444.225098,942.176575 
	C2445.020508,942.133789 2445.815674,942.091003 2446.611084,942.048218 
	C2447.384521,947.082764 2448.158203,952.117249 2448.975830,957.570923 
	C2449.360840,960.424438 2449.701904,962.858765 2450.171875,965.684448 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2413.641602,837.385315 
	C2414.000977,837.722229 2414.159424,837.909424 2414.498535,838.179688 
	C2416.888428,844.098755 2419.213135,849.876953 2421.325195,855.731873 
	C2422.009277,857.628723 2422.136230,859.726624 2422.230957,861.988220 
	C2419.644287,857.656982 2417.337158,853.074036 2415.048096,848.481934 
	C2413.451172,845.278320 2411.879150,842.062256 2410.279785,838.532166 
	C2410.947266,837.872559 2411.631592,837.532532 2412.603516,837.177368 
	C2413.063721,837.195923 2413.236084,837.229736 2413.641602,837.385315 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2451.000000,1035.001465 
	C2450.962402,1031.282104 2450.925049,1027.561523 2450.787109,1023.381653 
	C2450.910156,1019.445923 2451.133545,1015.969360 2451.667236,1012.207275 
	C2452.315430,1013.630249 2452.837402,1015.325745 2452.958740,1017.049377 
	C2453.384033,1023.097412 2453.671875,1029.155151 2454.069824,1035.655273 
	C2453.640137,1036.075684 2453.152588,1036.049927 2452.299316,1035.979980 
	C2451.933594,1035.935791 2451.965576,1035.967773 2451.915283,1035.730469 
	C2451.576660,1035.327759 2451.288330,1035.162476 2451.000000,1034.997192 
	C2451.000000,1034.997192 2451.000000,1035.000000 2451.000000,1035.001465 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2434.893066,901.885315 
	C2432.654541,897.856995 2430.710938,893.926331 2430.401855,889.647583 
	C2431.358887,889.471313 2432.315918,889.295044 2433.272949,889.118774 
	C2433.929932,893.402893 2434.586914,897.686951 2434.893066,901.885315 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2442.170410,1006.955627 
	C2444.548828,1006.954346 2446.491211,1006.954346 2448.575439,1006.954346 
	C2448.733398,1012.037231 2445.352051,1011.126648 2443.132568,1010.832642 
	C2441.123291,1010.566345 2439.260498,1009.191040 2436.376709,1007.864868 
	C2438.588867,1007.489929 2440.161377,1007.223389 2442.170410,1006.955627 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2449.934082,998.385620 
	C2450.927002,998.742859 2452.649902,999.500305 2452.720215,1000.388672 
	C2452.963867,1003.468628 2452.633301,1006.593994 2452.232666,1010.047363 
	C2451.264893,1006.543640 2450.585693,1002.694763 2449.934082,998.385620 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2435.040039,906.076294 
	C2435.802490,905.334656 2436.598877,904.987305 2437.395020,904.639954 
	C2438.294678,907.880066 2439.194336,911.120239 2440.094238,914.360413 
	C2439.275391,914.605286 2438.456543,914.850220 2437.637451,915.095093 
	C2436.782959,912.220276 2435.928223,909.345459 2435.040039,906.076294 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2441.538818,934.402954 
	C2441.595703,933.939880 2441.822510,933.659424 2442.049316,933.378906 
	C2442.225830,933.564026 2442.402588,933.749146 2442.579102,933.934204 
	C2442.289062,934.151306 2441.998779,934.368469 2441.538818,934.402954 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2426.347656,1014.062744 
	C2426.653564,1013.605408 2427.276367,1013.209473 2427.899170,1012.813599 
	C2428.062012,1013.132263 2428.224609,1013.450867 2428.387207,1013.769531 
	C2427.813232,1013.887756 2427.239014,1014.005920 2426.347656,1014.062744 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2440.568359,1017.775330 
	C2440.887451,1017.589355 2441.255127,1017.628113 2441.622559,1017.666870 
	C2441.534180,1017.936646 2441.445801,1018.206360 2441.357422,1018.476135 
	C2441.110352,1018.317505 2440.863525,1018.158813 2440.568359,1017.775330 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2389.594727,1014.601562 
	C2389.722656,1014.304016 2389.978027,1014.171692 2390.249756,1014.114624 
	C2390.312256,1014.101501 2390.428711,1014.346741 2390.520508,1014.473022 
	C2390.256592,1014.580078 2389.992432,1014.687073 2389.594727,1014.601562 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2422.368408,1013.378540 
	C2422.580078,1013.357727 2422.655029,1013.448547 2422.729980,1013.539307 
	C2422.563721,1013.522888 2422.397705,1013.506531 2422.368408,1013.378540 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M266.016815,373.786713 
	C268.205139,373.954620 270.393463,374.122528 272.682281,375.075165 
	C272.780304,378.157288 272.777832,380.454681 272.438538,382.829712 
	C271.438141,383.628326 270.774597,384.349274 270.180023,385.448425 
	C271.035309,387.675171 272.441437,389.502350 272.506042,391.375793 
	C272.887848,402.447296 273.091400,413.530060 273.048523,424.607849 
	C273.036255,427.771973 274.046448,429.456390 276.933594,430.597412 
	C280.464905,431.993011 283.926788,433.636536 287.217712,435.523376 
	C288.179535,436.074860 288.456268,437.821289 288.586273,438.987610 
	C284.755585,439.002014 281.385620,439.048798 277.550720,439.028992 
	C271.698761,439.048187 266.311768,439.133911 260.688446,438.915100 
	C260.724518,437.699768 260.996887,436.788971 261.273254,435.864746 
	C259.401825,435.250610 257.693024,434.689819 256.465973,434.287140 
	C256.326324,435.380371 256.110443,437.070160 255.824982,439.304871 
	C247.975830,437.328308 246.655029,445.507782 241.077454,448.030853 
	C236.485260,440.961273 228.691483,439.193024 221.849792,435.686432 
	C221.579407,436.141907 221.309021,436.597412 221.038635,437.052887 
	C221.840485,437.615234 222.642349,438.177582 223.558334,438.820007 
	C220.080688,441.558075 218.015213,439.429352 216.891190,437.519287 
	C205.916641,442.706329 195.177414,438.962036 184.583160,438.517639 
	C182.965363,433.827179 178.382782,432.732422 171.128342,435.599640 
	C171.128342,438.497589 171.026291,441.481110 171.184769,444.450745 
	C171.238815,445.463440 171.665848,446.872925 172.408005,447.357666 
	C175.129501,449.135284 182.209732,446.808502 184.417953,444.428528 
	C185.904114,442.826782 188.438629,441.394989 190.552734,441.323486 
	C198.003448,441.071472 205.479782,441.331146 212.935471,441.651123 
	C214.719467,441.727661 216.461243,442.788361 218.573380,443.517609 
	C221.611816,439.921143 223.899872,440.983246 224.727463,444.619232 
	C226.699814,443.501862 228.540314,441.581329 230.313446,441.641632 
	C233.759476,441.758881 240.449463,450.146576 240.646286,453.720062 
	C240.929184,458.856506 241.166595,463.995453 241.349625,469.565735 
	C241.296097,472.349915 241.316376,474.701691 241.125153,477.172668 
	C241.027878,477.846832 241.142136,478.401825 241.261551,479.389954 
	C241.351852,481.261444 241.436981,482.699799 241.182098,484.176758 
	C240.113846,484.871979 239.385620,485.528625 238.657379,486.185242 
	C239.501450,486.774231 240.345520,487.363190 241.254913,488.398010 
	C241.505325,497.178955 241.690414,505.514069 241.526596,513.925903 
	C235.156784,510.357483 229.135895,506.712433 223.114990,503.067352 
	C222.824066,503.537262 222.533127,504.007172 222.242203,504.477081 
	C222.993713,505.266449 223.745239,506.055786 225.625153,508.030365 
	C212.651352,508.030365 201.091431,508.129211 189.537308,507.915222 
	C187.951508,507.885834 185.944839,506.656158 184.920532,505.359924 
	C182.963821,502.883728 175.532211,500.778809 172.945236,502.572296 
	C172.381104,502.963379 171.921509,503.906830 171.899811,504.610840 
	C171.793564,508.058685 171.851639,511.511658 171.851639,515.600525 
	C175.494827,515.600525 178.624054,515.769653 181.716675,515.507263 
	C182.776093,515.417419 184.402557,514.424316 184.640778,513.539795 
	C185.742355,509.449615 189.422638,509.584625 192.076340,509.476868 
	C201.374878,509.099243 210.704224,509.210999 220.010605,509.499756 
	C222.138794,509.565796 224.221939,511.082672 226.652435,512.063660 
	C229.025513,508.068787 230.928696,507.900360 235.327225,512.198364 
	C237.097626,513.928284 239.014374,515.508484 241.002045,517.519348 
	C241.348358,520.155701 241.557114,522.429504 241.333908,524.865601 
	C238.767593,525.173340 236.633774,525.434937 234.498825,525.444519 
	C224.561829,525.489258 214.624527,525.464294 204.687347,525.464294 
	C204.694534,525.948364 204.701721,526.432495 204.708908,526.916565 
	C208.646332,526.916565 212.584198,526.878052 216.521072,526.924622 
	C224.937378,527.024170 233.353180,527.167725 241.774841,527.729248 
	C241.868622,531.111755 241.956757,534.058411 241.827957,537.347778 
	C240.462921,544.987671 243.576782,552.705017 240.767960,557.460693 
	C241.333435,566.886414 241.770813,574.176941 242.241379,582.020508 
	C238.232666,579.288025 235.208557,576.891846 231.875473,575.057007 
	C229.025024,573.487854 225.820206,572.562439 222.772125,571.352295 
	C222.623840,571.770386 222.475555,572.188477 222.327286,572.606567 
	C223.126846,573.222656 223.926422,573.838806 225.193390,574.815063 
	C222.018875,574.963806 219.542252,575.079895 216.598572,575.113281 
	C208.400375,575.046082 200.669235,575.061646 192.533813,574.996765 
	C189.669067,574.654297 187.208588,574.392273 184.894928,573.722839 
	C184.166122,572.210876 183.308212,570.183350 182.411667,570.166138 
	C179.049942,570.101501 175.673492,570.676880 172.313461,571.099304 
	C172.175720,571.116638 172.082733,572.010559 172.079910,572.496704 
	C172.060089,575.916626 172.069397,579.336792 172.069397,583.660400 
	C176.696228,583.102478 180.475815,582.646667 184.519684,581.737427 
	C184.558319,580.683228 184.332657,580.082336 184.065277,579.494873 
	C184.023575,579.508301 184.057709,579.588989 184.482849,579.608643 
	C187.536911,578.644836 190.165833,577.661438 193.249603,576.848389 
	C201.513794,576.864563 209.323151,576.710449 217.534973,576.634644 
	C220.237900,576.712891 222.538330,576.712891 224.721939,576.712891 
	C225.318237,578.454895 225.770020,579.774719 226.834915,582.885620 
	C227.489853,576.854614 229.775009,574.826355 233.591888,577.612183 
	C236.371841,579.641296 238.676422,582.418518 240.846451,585.138672 
	C241.714233,586.226379 241.651901,588.056152 241.992233,589.462585 
	C240.119446,590.007690 238.837463,590.380859 235.796921,591.265869 
	C242.347656,592.638428 242.108002,596.386902 242.015701,600.162781 
	C241.928848,603.716064 241.959244,607.277649 242.094162,610.829651 
	C242.367630,618.030212 242.411148,618.028564 235.153671,619.489563 
	C235.153671,622.251953 235.153671,625.024902 235.153671,628.432312 
	C238.029892,628.432312 240.639679,628.478149 243.247345,628.423767 
	C251.335419,628.255066 251.339325,628.240051 250.887238,620.172913 
	C250.870514,619.874146 250.608871,619.589172 250.326202,619.030762 
	C244.650558,620.043518 244.227020,616.228760 244.397079,612.039429 
	C244.463821,610.395325 244.843338,608.764038 245.523590,607.062134 
	C250.050629,607.283142 255.832245,605.006836 254.462906,613.952820 
	C257.559967,606.037048 257.032990,605.175964 249.956268,604.952820 
	C248.321625,604.901306 246.684494,604.929077 244.928421,604.537109 
	C244.585281,602.685547 244.177780,601.217712 244.174271,599.748901 
	C244.164322,595.583374 243.262680,591.002319 244.692474,587.362793 
	C246.275787,583.332520 249.773926,579.857178 253.088776,576.859619 
	C253.810883,576.206604 257.291382,578.604065 259.512543,579.601562 
	C259.797058,578.911438 260.398102,577.453674 261.316162,576.242676 
	C276.254517,576.700317 290.875854,576.911255 305.568665,577.569336 
	C306.309998,579.158447 306.871002,581.187622 307.669098,581.285522 
	C310.740784,581.662659 313.912323,581.623474 316.989349,581.257751 
	C317.689117,581.174683 318.159637,579.162537 318.830322,577.595520 
	C318.929504,567.370850 318.898804,567.341431 309.556915,568.534912 
	C307.460419,570.040405 305.764923,571.564392 303.681274,573.012024 
	C299.798492,573.236694 296.307495,573.735168 292.808441,573.800781 
	C283.479462,573.975952 274.146790,574.011658 264.816071,573.976318 
	C263.442047,573.971130 262.070618,573.278015 260.782410,572.535828 
	C259.980652,571.905273 258.758942,571.130920 258.260406,571.458130 
	C253.505905,574.579041 248.873901,577.886475 244.025787,581.266541 
	C244.025787,578.122498 244.007996,574.988770 244.029053,571.855347 
	C244.099503,561.369629 244.104919,561.371155 254.316544,560.678162 
	C254.399780,560.672485 254.456650,560.278381 254.576126,559.911072 
	C253.719559,559.840271 252.897568,559.683716 252.083847,559.718872 
	C244.881012,560.029602 244.070480,559.270569 243.891678,552.216003 
	C243.761734,547.088684 243.543823,541.963562 243.460129,536.401550 
	C243.593781,532.956726 243.632446,529.947815 244.003815,526.805298 
	C245.239441,526.483887 246.142380,526.295959 248.037918,525.901611 
	C245.863419,525.475830 244.790680,525.265747 243.673370,524.613403 
	C244.077637,521.430664 244.526459,518.690308 245.318253,515.933105 
	C246.877853,514.555237 248.093002,513.192993 249.311340,511.833527 
	C253.282578,507.402374 255.950668,507.686707 258.870789,513.363403 
	C259.390930,508.810303 262.156128,508.563934 265.641144,508.566589 
	C276.092804,508.574524 286.544769,508.205109 297.401855,508.098511 
	C301.068298,508.664978 305.047455,507.985962 305.314606,513.142517 
	C305.327515,513.391602 307.006714,513.554260 308.354553,513.887207 
	C318.632294,514.228027 318.872681,513.972290 317.879059,504.367645 
	C317.845215,504.040802 317.950836,503.536102 317.778381,503.413147 
	C316.510590,502.509094 315.139984,500.904938 313.893036,500.982819 
	C310.817230,501.175018 307.790314,502.150085 304.743744,502.810425 
	C304.979370,503.234344 305.214966,503.658295 305.212646,504.378571 
	C304.058136,505.023132 303.162140,505.585388 302.220795,505.675873 
	C300.133728,505.876526 298.023865,505.840485 295.484375,506.028992 
	C283.775818,506.157013 272.506256,506.157013 261.058136,506.157013 
	C261.106262,505.711700 261.223511,504.626434 261.499573,502.071136 
	C259.485870,503.717468 258.520599,504.535431 257.523590,505.312653 
	C256.716705,505.941681 255.846603,507.070923 255.047379,507.034637 
	C249.613007,506.787842 247.145798,510.367432 244.565002,514.359558 
	C243.847000,505.950287 243.530518,497.611328 243.579895,489.079651 
	C247.555298,488.252258 251.164810,487.617584 253.321335,487.238373 
	C250.797913,486.323547 247.066025,484.970612 243.326813,483.174988 
	C243.179855,478.169678 243.040268,473.607117 242.980713,468.664612 
	C243.079956,463.131653 243.024506,457.976990 243.138947,452.826050 
	C243.258163,447.459076 250.838409,440.953156 256.200592,442.082123 
	C256.200592,447.894989 256.200592,453.829498 256.200592,459.764008 
	C256.263733,456.297089 255.652878,452.671692 256.442688,449.382629 
	C257.172058,446.345154 259.403290,443.668335 261.401520,440.915527 
	C263.852173,441.050751 265.874390,441.103119 267.927734,441.551697 
	C268.028839,443.977570 267.577209,446.370056 271.068695,446.361725 
	C271.815826,447.033752 272.375916,447.493988 272.550049,448.032654 
	C271.659363,450.461792 271.154663,452.812500 270.582581,455.477173 
	C280.830170,455.477173 290.075317,455.477173 300.159332,455.477173 
	C299.689636,452.323822 299.389648,450.309875 298.890228,446.956970 
	C297.218475,449.308228 296.467926,450.363861 296.305359,450.592468 
	C294.045471,449.601044 292.394043,448.876556 290.815643,447.719788 
	C290.977081,445.070526 291.065521,442.853546 291.548096,440.459900 
	C295.498230,440.386078 300.028290,438.500977 300.666962,444.558044 
	C301.234772,444.266113 301.802582,443.974182 302.078796,443.832153 
	C303.601685,444.636200 305.091919,445.423035 306.761902,446.458191 
	C308.721161,450.436340 309.278412,446.969940 310.682312,446.167236 
	C312.680176,446.160034 314.278992,446.124573 316.075500,446.386047 
	C318.474396,448.713196 320.357239,451.374268 322.928253,452.671509 
	C339.923828,461.246674 357.067200,469.528870 374.077820,478.374542 
	C374.011993,491.989410 373.838745,505.140869 374.158142,518.280334 
	C374.264008,522.634216 372.344452,523.858521 368.684387,523.907471 
	C363.191895,523.981018 357.698334,523.975098 352.205780,524.046753 
	C350.623199,524.067322 349.042511,524.238220 347.460968,524.340210 
	C347.503967,524.832214 347.546967,525.324219 347.589966,525.816223 
	C354.678009,525.816223 361.776886,526.052124 368.850037,525.727661 
	C373.088470,525.533264 374.134979,526.996460 374.144470,531.104553 
	C374.177490,545.395264 374.795044,559.683777 374.909821,573.975342 
	C375.159332,605.045105 375.235931,636.116272 375.051849,667.119080 
	C374.503601,666.999084 374.286774,666.946899 373.897461,666.597534 
	C372.208374,665.079407 370.851379,663.527649 369.143341,662.703552 
	C365.112732,660.759094 360.106567,662.998291 358.159027,667.319641 
	C356.238251,671.581482 357.236664,675.029541 360.969421,677.009766 
	C360.977356,677.021667 361.000427,677.038452 360.904236,677.386658 
	C361.532501,678.292297 362.256927,678.849670 363.073486,679.710266 
	C364.431946,680.054688 365.698273,680.095825 367.397156,680.092712 
	C368.709381,680.027283 369.589081,680.006104 370.389343,680.383301 
	C370.138306,686.678345 370.077698,692.581787 369.719025,698.467041 
	C369.626434,699.986816 368.598175,701.449646 367.774017,702.670288 
	C357.439453,700.168030 352.787415,693.033325 351.600586,683.884155 
	C348.621857,689.355713 345.756958,694.618103 342.523193,699.896057 
	C339.186462,701.132263 336.218597,702.352905 333.250702,703.573608 
	C337.308929,704.273376 340.741180,703.527405 343.415222,700.038879 
	C346.296844,697.299011 348.836029,694.609070 351.469666,691.819092 
	C355.015564,698.244995 359.561523,703.793518 368.454346,703.980225 
	C369.935028,703.003479 371.804871,702.054810 371.886993,700.970764 
	C372.223419,696.529541 371.890076,692.041443 372.089294,687.583374 
	C372.143066,686.379089 373.276062,684.164917 373.789337,684.200684 
	C379.866577,684.623657 386.265137,682.137817 391.996002,686.261597 
	C392.005707,686.938843 392.008728,687.325806 391.711548,687.963989 
	C392.948792,691.481750 394.486176,694.748352 396.008728,698.009888 
	C395.993835,698.004822 395.987946,697.973816 395.943604,698.330566 
	C396.608643,699.129456 397.318054,699.571716 398.011108,700.009644 
	C397.994751,700.005310 397.982269,699.973816 398.009033,700.263367 
	C398.365875,700.707825 398.695953,700.862671 399.010437,701.011414 
	C398.994812,701.005310 398.986969,700.972656 398.939392,701.336792 
	C400.585938,702.928955 402.280090,704.156921 404.053223,705.685913 
	C405.115356,706.802979 406.098480,707.618958 407.116241,708.463684 
	C406.166534,708.942444 405.290344,709.384094 404.414185,709.825745 
	C404.463501,710.232117 404.512848,710.638489 404.562164,711.044861 
	C409.605560,711.044861 414.648956,711.044861 419.881287,711.411621 
	C421.929962,712.159363 423.791931,712.883240 425.649048,712.871033 
	C463.361938,712.623169 501.074219,712.287415 538.786682,711.979004 
	C545.339050,711.925415 546.987366,713.600220 545.709534,720.248535 
	C545.080200,723.522827 543.147461,726.546570 541.351440,729.718079 
	C532.596436,729.851624 524.294617,729.950745 515.643799,729.851807 
	C513.636597,729.583496 511.977722,729.439636 510.320129,729.453674 
	C478.029968,729.726196 445.739624,729.990173 413.450378,730.349304 
	C410.460541,730.382507 407.600281,730.047180 407.851532,726.525269 
	C408.300629,720.229675 404.996613,719.312744 399.962006,719.940735 
	C398.325226,720.144897 396.646515,720.013062 394.816498,719.749756 
	C392.408142,717.725037 390.431488,718.285461 389.559387,720.725098 
	C389.141235,721.894836 390.100037,723.556824 390.435181,724.995850 
	C391.969238,724.022583 393.503296,723.049377 395.474823,721.943848 
	C397.900146,721.789612 399.911652,721.574036 401.867523,721.813354 
	C403.178101,721.973694 405.352814,722.609863 405.482788,723.331604 
	C405.764191,724.894409 405.462769,727.047180 404.498627,728.214478 
	C403.511292,729.409729 401.462646,730.282166 399.853821,730.323059 
	C386.431183,730.664307 373.002808,730.780762 359.430603,730.654663 
	C358.544739,730.169922 357.804382,729.995728 357.029663,729.337402 
	C356.934021,728.535583 356.872772,728.217834 356.903717,727.591858 
	C356.993073,726.855286 356.990295,726.427002 357.291260,725.861084 
	C360.877533,721.962219 364.160095,718.201111 367.917877,713.895447 
	C363.453918,713.126892 360.185699,712.564209 357.393127,712.005005 
	C364.411407,711.787292 370.954346,711.573303 377.494202,711.288513 
	C377.612427,711.283386 377.693726,710.432007 377.733887,710.245972 
	C370.353516,710.245972 363.024475,710.245972 355.359680,710.178467 
	C352.347778,707.800293 349.718353,705.432434 346.980865,703.196838 
	C344.720917,701.351196 343.139557,701.609436 342.630402,704.891968 
	C342.159302,705.174194 341.948578,705.443359 341.507660,705.797852 
	C340.967529,706.043579 340.754242,706.288208 340.424683,706.740967 
	C339.943542,707.069519 339.738495,707.325012 339.288391,707.787903 
	C337.751587,708.720520 336.325531,709.303894 335.339172,710.315918 
	C333.702881,711.994812 331.490875,713.905212 335.101532,716.259399 
	C335.666107,716.810242 336.129700,717.101135 336.711975,717.600891 
	C337.007812,718.096375 337.253967,718.299255 337.697052,718.624023 
	C338.028076,719.089844 338.275330,719.297546 338.687805,719.749695 
	C340.112366,721.109009 341.415924,722.171387 342.784515,723.577148 
	C342.887451,724.610657 342.925354,725.300659 342.534912,725.962158 
	C339.855927,726.521484 336.443939,724.226562 335.626862,728.378479 
	C333.885925,737.225220 335.970551,739.622375 344.736481,739.008972 
	C345.217865,738.975281 345.687073,738.767029 346.540283,738.694458 
	C347.996582,737.250122 349.074524,735.751343 350.473755,733.805908 
	C352.559509,733.805908 355.207062,733.805908 358.312744,733.689941 
	C371.728607,733.391541 384.686188,733.123657 397.644287,733.095825 
	C400.392395,733.089905 403.142395,733.960022 406.011688,734.446899 
	C408.219635,733.912720 410.738129,732.820862 413.278564,732.767151 
	C429.391266,732.426514 445.508514,732.289490 461.624817,732.140198 
	C468.998901,732.071899 476.374146,732.128601 484.493195,732.128601 
	C481.871643,735.437012 479.882294,737.947510 477.577454,740.616821 
	C476.815826,741.506714 476.369690,742.237793 475.516541,743.008362 
	C467.539764,743.335571 459.972137,743.786438 452.399963,743.882507 
	C421.660583,744.272766 390.920288,744.612549 360.179413,744.854431 
	C340.390076,745.010071 320.598969,744.929138 300.809448,745.067017 
	C299.163452,745.078430 297.523499,745.953064 295.537354,746.345215 
	C293.143860,746.263855 291.093781,746.263855 288.840485,746.263855 
	C288.840485,747.732788 288.840485,748.858826 288.453522,749.950684 
	C286.207703,750.349060 284.348846,750.781616 282.489990,751.214233 
	C282.352631,750.835510 282.215271,750.456787 282.077911,750.078064 
	C283.367645,748.949707 284.657410,747.821350 285.947144,746.692993 
	C285.553711,746.267273 285.160309,745.841553 284.766876,745.415833 
	C283.154266,746.933899 281.299835,748.287537 280.084045,750.075439 
	C279.742615,750.577576 281.475342,753.450317 281.920166,753.370056 
	C284.666809,752.874695 287.317230,751.845947 290.328461,750.986694 
	C291.412109,751.397705 292.166901,751.816528 292.960144,752.630615 
	C293.415283,754.704956 293.831970,756.384155 294.164917,758.339844 
	C293.746124,758.775452 293.411072,758.934570 292.819489,758.871033 
	C291.107330,758.487000 289.651703,758.325500 287.909973,757.972839 
	C284.293488,754.602478 283.152740,757.302307 281.777893,760.185547 
	C280.449707,760.597534 279.313019,760.711670 278.135559,760.435608 
	C277.118439,755.596436 276.142090,751.147583 275.014923,746.011536 
	C263.218994,746.011536 250.934875,746.011536 238.945709,745.714233 
	C241.514313,744.773193 244.603043,744.851318 245.922134,743.362000 
	C252.009262,736.489685 252.203232,732.199768 247.808060,724.030273 
	C247.257706,723.007324 246.806427,721.914795 246.137268,720.977173 
	C245.602509,720.228027 244.915054,719.344055 244.108704,719.070740 
	C237.230804,716.739929 229.833466,719.452271 226.285339,725.777039 
	C224.321381,729.277893 222.406189,732.144714 217.609848,731.607300 
	C216.643829,731.499023 214.617477,733.831543 214.423584,735.225647 
	C213.924973,738.810913 214.180176,742.501038 213.653519,746.117554 
	C194.393051,746.353333 175.612869,746.603210 156.834137,746.934387 
	C155.781433,746.953003 154.740540,747.641541 153.465118,748.003784 
	C152.929764,747.979492 152.624054,747.988831 152.022339,747.695068 
	C149.918610,747.242676 147.992462,746.676697 146.343872,747.126892 
	C145.131104,747.458069 144.281372,749.118652 143.230682,750.358215 
	C143.191467,750.529541 143.259491,750.874512 143.209549,751.265137 
	C142.797424,753.076538 142.435242,754.497437 141.827759,755.681152 
	C140.418335,754.754822 139.254196,754.065613 137.824173,753.168945 
	C136.831940,752.131592 136.105606,751.301575 135.381393,750.474060 
	C133.982986,751.748291 132.418991,753.173401 130.534546,754.655273 
	C129.481613,755.149048 128.749130,755.586121 128.016647,756.023193 
	C127.022491,753.930054 126.028336,751.836853 125.260986,749.129395 
	C122.948013,748.051270 120.408234,747.587402 117.868454,747.123535 
	C116.486679,743.756897 116.867439,739.141907 111.566162,738.488403 
	C111.199188,738.443115 110.976448,736.138794 110.922546,734.875000 
	C110.817398,732.409912 110.982483,729.932129 110.847099,727.469971 
	C110.661438,724.093201 110.417442,720.878052 114.896812,719.907166 
	C115.498360,719.776855 115.751198,718.037659 116.625122,716.592896 
	C119.060913,716.085876 121.037086,716.029419 123.480103,715.996338 
	C151.965164,715.725891 179.983398,715.432129 208.336609,715.376587 
	C217.929596,715.438904 227.186996,715.215271 236.445740,715.096130 
	C264.974792,714.729126 293.504303,714.392639 322.033875,714.065979 
	C329.235291,713.983521 329.215424,714.018250 330.141083,706.652344 
	C330.238892,705.874084 330.750732,705.147766 331.417053,704.092163 
	C331.847992,697.645386 332.090942,691.500244 331.958282,685.363281 
	C331.876923,681.600037 330.204071,679.135742 325.746552,679.781860 
	C323.667236,680.083252 321.507965,679.833435 318.826233,679.833435 
	C318.570679,674.581177 318.245575,669.832275 318.131622,665.078369 
	C317.982178,658.844299 316.916473,658.191040 310.635132,661.001465 
	C301.472809,664.100891 296.962646,670.696472 295.453400,680.317505 
	C285.423248,680.317505 275.662964,680.317505 265.482788,680.267578 
	C260.429535,680.372375 255.796158,680.527161 251.097672,680.368958 
	C250.966339,679.629761 250.900131,679.203552 250.993378,678.474365 
	C254.727478,671.463074 259.440460,665.214417 253.071838,657.163940 
	C249.471878,652.613342 250.207153,652.031677 256.524963,650.658936 
	C256.305634,649.128052 256.080872,647.559265 256.141266,645.950562 
	C256.426392,643.995972 256.426392,642.081360 256.426392,640.166809 
	C256.029236,640.126465 255.632080,640.086182 255.234940,640.045837 
	C254.458862,641.425110 253.682800,642.804443 252.552719,644.045532 
	C250.929382,641.166992 250.050293,638.218628 245.818344,638.938843 
	C242.596588,639.487183 238.406387,638.147644 236.184555,639.762939 
	C233.663101,641.596008 233.048355,647.163635 230.074036,640.282532 
	C230.037308,640.197571 229.778503,640.208496 229.325607,640.109680 
	C229.073807,640.802917 228.808151,641.534302 228.735825,641.733398 
	C226.825531,641.073364 224.893478,640.405823 222.961426,639.738342 
	C222.806519,640.141418 222.651596,640.544556 222.496689,640.947693 
	C223.345078,641.657532 224.193466,642.367432 226.053467,643.923706 
	C218.171555,643.923706 211.615952,643.923706 204.837250,643.670288 
	C202.108582,643.250671 199.603012,643.084473 197.110046,642.518311 
	C196.125061,640.870972 195.191177,638.631104 194.117935,638.562256 
	C190.902008,638.355835 187.630203,638.985291 184.386490,639.345093 
	C184.219101,639.363708 183.984756,639.911682 183.992889,640.207336 
	C184.092621,643.835022 183.654785,647.643494 184.706512,650.979431 
	C185.029175,652.002869 189.457962,651.743042 192.009781,652.042358 
	C192.499496,652.099792 193.006241,651.992798 193.502640,652.015747 
	C196.403015,652.149536 197.373199,650.655273 197.178986,647.649292 
	C199.865784,646.679382 202.322525,646.027100 205.232895,645.544006 
	C209.984665,645.713989 214.282822,645.720398 218.580963,645.713745 
	C222.003494,645.708435 225.915131,644.688232 226.295898,648.921692 
	C228.595093,647.795837 230.657303,646.558105 232.889938,645.888489 
	C233.327759,645.757141 234.424759,647.823303 235.220154,649.176636 
	C235.204727,649.676880 235.186844,649.876465 234.863815,650.256348 
	C234.528168,652.484131 234.497650,654.531677 234.209915,656.789062 
	C228.718765,662.769043 228.098526,668.602356 233.540344,674.668274 
	C234.614838,675.866028 235.260605,677.448425 236.077301,679.186707 
	C236.002365,679.961792 235.954254,680.403625 235.443481,680.846313 
	C204.566940,680.847168 174.153076,680.847168 143.427582,680.847168 
	C143.026337,679.623657 142.457520,678.093872 142.022156,676.526978 
	C139.884933,668.834473 134.712860,664.137268 127.293884,661.799316 
	C122.454506,660.274292 121.244545,661.207825 121.182877,666.140747 
	C121.137115,669.801147 121.070091,673.468201 121.233536,677.122314 
	C121.385284,680.515137 120.219536,682.206726 115.989502,681.443420 
	C115.350388,645.672241 114.710228,609.842346 114.070068,574.012512 
	C114.367271,572.267761 115.327446,570.315430 114.856468,568.811218 
	C111.194366,557.115112 113.585518,545.148438 113.069534,533.330017 
	C112.657005,523.881714 111.723335,514.164246 113.380806,504.992706 
	C115.367050,494.001831 113.588715,483.269806 113.819946,472.448425 
	C114.058510,461.283478 112.708008,450.084595 112.041336,438.000977 
	C111.990211,435.362305 111.950485,433.623169 111.934082,431.427429 
	C112.010506,423.332764 112.063614,415.694763 112.116730,408.056732 
	C112.420006,404.488770 112.904785,400.924957 112.986511,397.351929 
	C113.129967,391.079926 113.026566,384.802307 113.026566,378.292328 
	C136.098083,377.846741 158.640045,377.411377 182.094681,376.927216 
	C196.672806,376.615082 210.338257,376.351776 224.323685,376.345642 
	C237.252869,376.407135 249.863693,376.270111 262.468658,375.921112 
	C263.670166,375.887848 264.834991,374.529785 266.016815,373.786713 
M146.267395,726.810486 
	C145.998260,727.199585 145.729141,727.588684 145.198563,728.009155 
	C145.032684,728.115356 144.959610,728.266418 144.398193,728.992920 
	C142.178574,730.052551 139.966614,731.128662 137.736450,732.165710 
	C136.375122,732.798706 133.844452,733.287659 133.793152,733.985046 
	C133.442307,738.755127 133.609192,743.563293 133.609192,748.359192 
	C134.118149,748.342163 134.627121,748.325073 135.136078,748.308044 
	C135.136078,743.713928 135.136078,739.119812 135.136078,734.633423 
	C140.221878,732.932556 143.458420,734.299255 145.630386,738.857361 
	C146.811188,741.335449 148.342773,745.038452 150.309387,745.467163 
	C154.734818,746.431702 159.813736,746.616760 164.063858,745.300964 
	C166.457657,744.559875 167.732941,740.205872 170.013947,737.008240 
	C172.169403,734.456238 171.993027,732.164795 170.580856,728.970947 
	C167.345337,721.653320 162.548065,718.199524 154.473526,719.880737 
	C153.042007,720.178833 151.513504,720.011169 149.400131,720.219727 
	C148.945129,721.151978 148.490128,722.084229 147.783478,723.667908 
	C147.839218,724.109375 147.894958,724.550781 148.000305,725.000732 
	C148.000305,725.000732 147.971344,724.959717 147.227432,725.023560 
	C147.134872,725.679626 147.042313,726.335754 147.000580,727.003723 
	C147.000580,727.003723 146.985016,726.953857 146.267395,726.810486 
M290.300018,641.874634 
	C286.883545,642.299866 283.467072,642.725159 279.185669,642.997986 
	C276.182159,642.585876 273.178680,642.173828 269.349731,641.648499 
	C266.541107,642.318481 262.775116,643.216919 259.009094,644.115295 
	C259.116547,644.605713 259.223999,645.096069 259.331421,645.586487 
	C262.018646,645.437378 264.705841,645.288208 267.337860,645.142090 
	C271.305237,659.242065 271.305237,659.242065 273.328430,659.056274 
	C269.454224,656.014709 268.570068,649.370911 272.275665,646.522034 
	C274.219574,645.027527 277.450165,645.206543 280.988983,644.746460 
	C284.558594,645.199280 288.128204,645.652100 291.850342,646.625610 
	C292.082336,647.110352 292.314362,647.595093 292.505707,648.925537 
	C296.565704,652.431458 300.916809,650.000732 305.528961,650.277039 
	C305.528961,646.757690 305.773407,643.933777 305.415802,641.188293 
	C305.259827,639.990601 303.869995,638.953613 302.296722,637.592468 
	C298.007446,637.759460 292.609467,635.155212 290.300018,641.874634 
M265.618134,489.710266 
	C267.062561,489.607880 268.535675,489.647308 269.943298,489.362823 
	C271.319855,489.084595 273.212555,488.847260 273.808868,487.910797 
	C274.435669,486.926422 273.793579,485.134033 273.705963,483.694733 
	C272.399750,483.800568 271.081360,483.828979 269.790344,484.031616 
	C268.141602,484.290405 266.514984,484.689911 264.714142,484.325653 
	C262.930817,484.630798 261.062225,484.728027 259.428528,485.397980 
	C259.054352,485.551453 259.096802,488.488800 259.625092,488.744354 
	C261.235779,489.523560 263.177185,489.619171 265.618134,489.710266 
M223.682755,588.889709 
	C220.604828,588.889709 217.526886,588.889709 214.448959,588.889709 
	C216.131165,590.855591 220.945587,590.827332 215.406433,594.307922 
	C215.555130,594.714600 215.703827,595.121277 215.852524,595.527954 
	C218.914413,595.378174 221.976303,595.228394 225.660904,594.811096 
	C226.550720,594.412170 227.959961,593.441589 228.248825,593.704102 
	C230.749908,595.976562 232.784378,595.420959 234.230698,592.759583 
	C234.681656,591.929810 234.473984,590.045044 233.824570,589.436584 
	C231.209488,586.986633 229.884369,589.750427 228.264496,591.166504 
	C228.028183,591.373047 227.753204,591.535461 227.541183,591.686279 
	C226.430222,590.947510 225.341843,590.223755 223.682755,588.889709 
M352.998077,637.997314 
	C352.998077,637.997314 353.021393,638.016785 352.904053,637.377258 
	C349.808533,633.718384 349.646240,638.379822 347.607178,639.537109 
	C347.164581,638.167908 346.721985,636.798767 346.626160,636.502319 
	C343.706268,636.367065 341.368561,636.258789 338.315643,635.938538 
	C335.763733,635.938538 333.211823,635.938538 329.212921,635.938538 
	C327.295105,633.341431 324.243530,635.473206 321.395508,638.007874 
	C316.689728,633.740173 316.595551,633.768372 313.391479,640.209167 
	C313.060577,638.880798 312.737762,637.584900 312.264496,635.684998 
	C310.579803,641.274536 312.729279,643.842346 317.563080,642.902161 
	C318.520782,642.715942 319.447144,641.812622 320.330688,641.890381 
	C326.463867,642.430176 332.585632,644.229248 338.610504,640.708984 
	C339.119476,640.411621 340.765717,641.446838 341.529510,639.741821 
	C344.043915,640.189819 346.558289,640.637817 349.849670,641.048767 
	C354.045135,641.396362 358.240631,641.744019 362.498962,642.096863 
	C362.375824,639.460144 362.278168,637.369873 362.186157,635.399841 
	C360.216248,635.728210 358.354950,636.038513 356.547180,636.339905 
	C356.547180,637.538147 356.547180,638.593201 356.547180,639.954773 
	C355.141693,639.174927 354.059692,638.574585 352.998077,637.997314 
M300.975616,735.243896 
	C303.255676,735.516052 305.531372,735.839233 307.817993,736.034790 
	C308.704346,736.110535 309.824310,735.517944 310.480682,735.879028 
	C314.309021,737.985291 317.174591,736.154480 320.176971,734.018982 
	C321.125244,733.344666 323.603546,732.998413 323.708130,733.236694 
	C325.101349,736.410461 327.245148,734.153198 329.056183,734.206848 
	C330.284851,734.243225 331.502686,734.646729 332.814667,734.903564 
	C332.814667,733.003479 332.814667,731.206848 332.814667,728.979431 
	C331.135498,729.952759 329.774078,730.741943 328.481781,731.491028 
	C323.393402,727.853394 316.075134,727.891418 310.338837,731.555054 
	C307.270477,726.440735 304.164368,730.612305 301.029053,731.970276 
	C300.981537,731.358521 300.934052,730.746826 300.886536,730.135071 
	C298.806854,730.316956 296.727142,730.498840 293.974243,730.781982 
	C291.631287,729.603882 289.227173,727.492798 286.769348,731.464966 
	C288.201813,731.859497 289.284332,732.157654 290.841492,732.586548 
	C289.113007,734.058472 287.756042,735.214050 286.399078,736.369629 
	C291.073456,736.323242 296.062653,739.181580 300.975616,735.243896 
M185.867950,601.902710 
	C187.465714,602.452515 189.063492,603.002319 189.672974,603.212036 
	C192.459457,603.212036 194.381760,603.212036 196.337250,603.212036 
	C196.039124,602.209900 195.599594,600.732361 195.181656,599.327393 
	C194.563522,599.700073 193.919067,600.402405 193.462616,600.296265 
	C192.597336,600.095154 191.829651,599.446716 191.039413,598.955200 
	C187.298965,596.628601 183.584885,597.603149 182.588486,601.565430 
	C180.929718,602.259888 179.270950,602.954346 177.612183,603.648804 
	C177.414017,603.297607 177.215866,602.946411 177.017700,602.595215 
	C178.188232,601.268555 179.358780,599.941895 180.874680,598.223877 
	C172.519135,598.745178 164.827881,599.225098 157.136627,599.704956 
	C156.868317,600.404785 156.599991,601.104553 156.331680,601.804321 
	C155.719894,600.439636 155.108124,599.074890 154.418259,597.536011 
	C153.139969,598.671265 152.007202,599.196228 151.768982,599.989685 
	C151.283295,601.607605 151.279541,603.370178 151.077255,605.073181 
	C152.716644,605.121399 154.355865,605.177185 155.995483,605.214844 
	C156.950577,605.236816 157.906433,605.223694 158.861786,605.237061 
	C163.145493,605.296936 167.429306,605.352356 171.712769,605.427429 
	C173.208832,605.453674 174.703812,605.538086 176.199631,605.584900 
	C179.671204,605.693604 183.141663,605.792297 185.867950,601.902710 
M316.721344,651.181274 
	C315.550262,651.415894 314.379150,651.650452 313.208038,651.885010 
	C313.125580,651.511292 313.043091,651.137573 312.960632,650.763855 
	C314.301300,649.613342 315.641968,648.462769 316.982635,647.312256 
	C316.589203,646.830811 316.195740,646.349426 315.802307,645.867981 
	C314.033722,647.394836 311.624268,648.626404 310.734528,650.558044 
	C310.191986,651.735901 311.989410,653.991638 312.735840,655.763245 
	C312.975647,655.406677 313.215454,655.050049 313.455261,654.693481 
	C318.027802,656.068787 321.098206,647.687256 326.622986,652.977112 
	C329.828033,648.392151 332.181122,654.879578 335.036285,654.298157 
	C335.355682,652.250854 335.670288,650.234192 335.984894,648.217529 
	C336.499878,648.160278 337.014862,648.103027 337.529846,648.045776 
	C338.436066,649.836426 339.342285,651.627136 340.379669,653.677063 
	C340.938568,651.743652 341.367798,650.258789 342.140259,647.586548 
	C344.317535,649.984253 345.790375,651.606201 347.032104,652.973633 
	C347.850006,651.586792 348.686218,650.169006 349.947784,648.030029 
	C350.933380,650.068726 351.452332,651.142029 351.738220,651.733459 
	C354.375122,650.529175 356.680664,649.476257 358.986206,648.423340 
	C358.801849,647.598938 358.617493,646.774597 358.433136,645.950256 
	C355.180634,646.263977 351.930725,646.609375 348.674377,646.876282 
	C347.317444,646.987549 345.883972,647.202698 344.593201,646.904114 
	C339.146210,645.644653 333.771454,644.473389 329.265350,648.992126 
	C323.262360,644.350647 319.652496,644.950012 316.721344,651.181274 
M208.618103,610.086060 
	C207.098145,611.408020 205.578171,612.729919 204.058182,614.051819 
	C203.136398,612.631531 202.214615,611.211304 200.711594,608.895447 
	C198.818283,609.119507 195.966812,609.000427 193.598373,610.012146 
	C192.877060,610.320312 193.486572,613.743835 193.486572,616.168518 
	C197.515121,613.736633 197.484665,613.774902 200.585251,616.243530 
	C201.160522,616.701538 202.092468,616.903320 202.858261,616.899719 
	C208.607605,616.872620 214.371521,616.967468 220.095688,616.546082 
	C221.624878,616.433472 223.653900,614.963135 224.321136,613.572754 
	C224.992859,612.173096 224.255112,610.097046 224.134933,608.317444 
	C223.675156,608.380737 223.215363,608.444031 222.755585,608.507324 
	C222.755585,609.895447 222.755585,611.283569 222.755585,612.913391 
	C222.042908,612.508179 221.502731,612.366699 221.223022,612.016968 
	C217.566986,607.445312 217.677216,607.521362 213.887833,612.107483 
	C212.978546,613.208008 211.154526,613.552673 209.751190,614.244995 
	C209.365280,613.704773 208.979355,613.164490 208.593445,612.624268 
	C210.162643,611.865479 211.731842,611.106750 213.301041,610.348022 
	C212.990112,609.920105 212.679184,609.492249 212.368256,609.064331 
	C211.323837,609.268188 210.279419,609.472046 208.618103,610.086060 
M282.307312,661.467773 
	C285.204498,661.215698 288.101715,660.963623 290.998901,660.711548 
	C291.004303,660.313538 291.009705,659.915527 291.015106,659.517517 
	C292.253479,660.244385 293.491852,660.971313 295.998810,662.442810 
	C296.631622,659.713867 297.110901,657.647156 297.590149,655.580444 
	C295.533295,655.580444 293.476471,655.580444 290.543854,655.580444 
	C288.447754,652.265991 285.475769,654.878723 282.556763,656.125061 
	C280.690125,655.293335 278.785095,655.117676 276.876801,655.066956 
	C276.694000,655.062073 276.490509,655.835510 276.256989,656.331909 
	C277.547089,656.646179 278.682190,656.922668 280.472565,657.358826 
	C278.504211,658.964783 277.086121,660.121765 275.668060,661.278809 
	C275.785461,661.629822 275.902863,661.980896 276.020264,662.331970 
	C277.877502,662.149597 279.734711,661.967285 282.307312,661.467773 
M163.132629,614.968201 
	C163.712524,615.079163 164.680130,615.449524 164.808105,615.258179 
	C165.530273,614.178711 166.050766,612.964478 167.148468,610.788025 
	C168.069244,613.322632 168.537445,614.611450 169.103928,616.170837 
	C170.169159,615.348633 170.989441,614.842468 171.650436,614.177917 
	C172.883331,612.938416 174.023636,611.606873 175.202499,610.313660 
	C173.545959,609.908142 171.657715,608.779419 170.273239,609.223022 
	C164.470932,611.082214 158.851242,609.512451 152.271851,608.640137 
	C151.794159,611.323547 151.346222,613.839783 150.898285,616.356018 
	C151.349991,616.663879 151.801697,616.971680 152.253403,617.279541 
	C152.654373,609.690186 154.701981,610.286865 157.527390,615.524780 
	C158.038254,614.132568 158.504486,612.862000 159.243057,610.849243 
	C160.891983,612.372864 161.928879,613.330994 163.132629,614.968201 
M293.504761,524.059753 
	C290.185364,524.075439 286.858856,523.962463 283.548645,524.144897 
	C280.527161,524.311462 277.523376,524.797974 274.511780,525.143555 
	C274.558777,525.412720 274.605743,525.681946 274.652710,525.951111 
	C289.558411,525.951111 304.464081,525.951111 319.369751,525.951111 
	C319.374664,525.311523 319.379547,524.671997 319.384460,524.032410 
	C311.090576,524.032410 302.796661,524.032410 293.504761,524.059753 
M159.659225,594.506409 
	C161.349197,594.087646 163.311844,594.059998 164.645569,593.130188 
	C165.776855,592.341492 166.181625,590.510620 166.907867,589.140930 
	C165.415970,588.390442 163.906448,587.029175 162.437424,587.071533 
	C161.127487,587.109314 159.880371,588.726257 158.572693,589.596924 
	C157.014618,590.634277 155.421555,591.619019 153.843643,592.626526 
	C153.567062,592.208923 153.290466,591.791321 153.013885,591.373718 
	C154.221176,590.242493 155.428482,589.111267 156.635788,587.980042 
	C156.395126,587.567688 156.154465,587.155273 155.913803,586.742859 
	C154.331451,587.688416 152.399780,588.346252 151.296417,589.686218 
	C150.623154,590.503845 150.756271,593.279541 151.347504,593.584290 
	C152.726013,594.294861 154.598434,594.078491 156.274490,594.167725 
	C157.168610,594.215332 158.070450,594.117859 159.659225,594.506409 
M178.235413,422.952087 
	C175.917267,422.952087 173.599121,422.952087 171.116394,422.952087 
	C173.237488,425.473083 174.728500,427.245178 176.213760,429.010468 
	C176.594604,428.074524 177.116928,426.790894 178.151031,424.249542 
	C180.068176,426.522308 181.431503,428.138519 182.762131,429.715973 
	C183.563583,428.295837 184.372223,426.862976 185.655121,424.589722 
	C186.793655,426.891113 187.443069,428.203796 188.162460,429.657928 
	C188.816223,428.837616 189.167953,428.265594 189.642365,427.825958 
	C190.972214,426.593658 192.352676,425.415955 193.713165,424.216736 
	C191.836395,423.751648 189.975403,422.990601 188.079239,422.889526 
	C185.097275,422.730591 182.091644,423.016174 178.235413,422.952087 
M304.635132,721.827698 
	C302.826691,722.535217 301.018250,723.242737 299.209808,723.950195 
	C298.955353,723.479736 298.700867,723.009277 298.446381,722.538818 
	C299.920135,721.317017 301.393890,720.095154 303.356995,718.467590 
	C299.356995,717.771790 297.086761,719.114014 296.732239,722.329712 
	C296.613129,723.409912 298.495697,725.570496 299.699310,725.763184 
	C302.458221,726.204773 305.560333,726.324036 308.143463,725.450989 
	C310.119202,724.783203 311.513336,722.394531 313.166199,720.771301 
	C312.210815,719.831482 311.333893,718.213684 310.283875,718.092224 
	C308.065643,717.835571 304.882141,716.903503 304.635132,721.827698 
M176.697632,527.796692 
	C175.728348,527.162170 174.763138,525.983154 173.789154,525.975952 
	C161.687225,525.885437 149.583939,525.951355 137.481247,526.021118 
	C136.150269,526.028809 134.820862,526.310425 133.490723,526.464966 
	C133.522141,526.949951 133.553558,527.434875 133.584976,527.919800 
	C147.673325,527.919800 161.761658,527.919800 176.697632,527.796692 
M170.272873,415.808228 
	C170.541489,416.359070 170.751877,417.349548 171.087555,417.393982 
	C175.315277,417.953888 179.558395,418.625061 183.804047,418.671448 
	C184.949860,418.683960 186.374725,416.851105 187.156433,415.554718 
	C187.498672,414.987152 186.733246,412.951050 186.143982,412.791901 
	C184.976807,412.476654 183.519379,412.820618 182.303772,413.242889 
	C181.463623,413.534760 180.827820,414.414948 179.896881,415.205231 
	C179.124100,413.909515 178.516586,412.890900 177.811310,411.708374 
	C175.305145,412.976776 173.555252,417.168213 170.411301,413.287079 
	C170.359833,413.901184 170.308380,414.515289 170.272873,415.808228 
M211.343658,428.904266 
	C209.957184,427.903198 208.704010,426.511719 207.156189,425.983185 
	C204.039505,424.919037 200.762375,424.324768 197.490341,423.521606 
	C196.897171,425.026764 195.846176,427.693695 194.767990,430.429565 
	C198.111374,429.630463 201.839523,427.618011 205.002243,428.285370 
	C208.460800,429.015106 211.396576,432.222626 215.349121,434.880798 
	C213.896988,432.645691 212.868134,431.062103 211.343658,428.904266 
M278.455078,557.384033 
	C275.634644,557.384033 272.814178,557.384033 269.865326,557.384033 
	C268.984100,558.781311 267.946808,560.426025 266.798737,562.246399 
	C268.756134,561.923767 270.947235,560.822388 272.236847,561.482361 
	C276.995056,563.917603 278.236664,563.452881 278.455078,557.384033 
M182.859299,616.445190 
	C184.634888,615.781311 186.410477,615.117432 188.186050,614.453552 
	C187.446594,612.559998 186.707153,610.666382 185.967697,608.772827 
	C185.258942,608.774292 184.550171,608.775757 183.841400,608.777222 
	C183.312057,611.161743 182.782700,613.546265 182.859299,616.445190 
M387.795105,724.515076 
	C387.795105,722.486450 387.795105,720.457886 387.795105,716.882751 
	C384.911224,718.762573 383.265900,719.835022 381.620575,720.907532 
	C382.950012,721.920776 384.268433,722.949036 385.613983,723.940430 
	C386.212280,724.381165 386.865295,724.747742 387.795105,724.515076 
M362.732300,645.404358 
	C361.977936,648.137268 361.223572,650.870239 360.329651,654.108704 
	C360.712158,654.014709 362.238220,654.106323 362.875580,653.398132 
	C365.033173,651.000671 366.030121,648.269226 362.732300,645.404358 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M113.755318,574.009888 
	C114.710228,609.842346 115.350388,645.672241 115.989502,681.443420 
	C120.219536,682.206726 121.385284,680.515137 121.233536,677.122314 
	C121.070091,673.468201 121.137115,669.801147 121.182877,666.140747 
	C121.244545,661.207825 122.454506,660.274292 127.293884,661.799316 
	C134.712860,664.137268 139.884933,668.834473 142.022156,676.526978 
	C142.457520,678.093872 143.026337,679.623657 143.427582,680.847168 
	C174.153076,680.847168 204.566940,680.847168 235.916107,680.904663 
	C241.621857,680.868774 246.392319,680.775330 251.162781,680.681885 
	C255.796158,680.527161 260.429535,680.372375 265.505249,680.531128 
	C265.947174,681.202393 265.946808,681.560059 265.483032,681.932129 
	C224.884430,682.425354 184.749222,682.904297 144.614029,683.383179 
	C144.583115,683.802490 144.552200,684.221802 144.521271,684.641113 
	C146.196060,684.748474 147.873398,684.978516 149.545212,684.943604 
	C161.869965,684.686462 174.192200,684.278320 186.517868,684.103210 
	C212.983444,683.727173 239.450623,683.462830 265.936066,683.451538 
	C265.963135,684.149231 265.971283,684.548157 265.517975,684.981201 
	C258.919342,685.255737 252.777359,685.415771 246.648056,685.785950 
	C244.821091,685.896240 243.029694,686.595459 241.222000,687.024902 
	C241.692307,688.915527 242.162628,690.806091 242.794266,693.345154 
	C241.323257,692.688599 239.702286,691.965149 238.081299,691.241699 
	C235.735504,692.348145 233.156021,692.522156 230.574295,692.567200 
	C226.274292,692.642334 221.724823,691.771240 217.744492,692.914429 
	C214.056152,693.973755 212.867905,693.257568 212.132065,690.005920 
	C211.919830,689.068054 211.540512,688.168030 211.237793,687.250610 
	C210.958801,687.301514 210.679810,687.352356 210.400818,687.403198 
	C210.151001,688.960388 209.901199,690.517578 209.640610,692.141907 
	C208.823196,690.253479 208.109940,688.605713 207.375885,686.909790 
	C206.191772,689.619019 205.429947,691.654541 204.332916,693.489746 
	C204.200623,693.711060 202.254868,692.848328 201.149628,692.488037 
	C201.271255,692.132751 201.392883,691.777527 201.514511,691.422241 
	C202.544464,691.422241 203.574402,691.422241 204.640762,691.422241 
	C200.953415,685.887451 195.655869,685.845215 189.943771,685.968994 
	C176.473740,686.260864 162.993683,686.084717 149.517715,686.111023 
	C142.408768,686.124878 142.408783,686.137329 141.119873,679.295654 
	C139.516174,670.783203 131.940582,663.655701 123.988037,663.589355 
	C123.988037,669.378845 123.780457,675.181458 124.056435,680.960999 
	C124.261856,685.262878 122.671814,687.184753 118.358208,686.857422 
	C117.205231,686.769897 116.035049,686.909241 114.653336,687.107117 
	C113.990089,687.819031 113.546257,688.368286 113.102425,688.917542 
	C112.749672,650.940125 112.396927,612.962646 112.116791,574.527588 
	C112.606461,574.049011 113.023514,574.028076 113.755318,574.009888 
M233.234390,689.139893 
	C234.681229,689.016724 236.128052,688.893494 237.574890,688.770325 
	C237.435120,688.117920 237.295364,687.465454 237.155594,686.813049 
	C235.882874,687.355286 234.610153,687.897522 233.234390,689.139893 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M54.971191,513.003784 
	C57.266445,515.292297 60.430202,517.183594 61.693069,519.944336 
	C65.387886,528.021484 62.056278,538.061401 54.179821,543.026855 
	C47.739975,547.086670 38.367027,547.990540 30.555532,540.279114 
	C23.857872,533.667297 23.621944,523.241882 28.506105,516.150085 
	C33.964966,508.223907 44.071758,505.549133 52.279411,509.696198 
	C53.351830,510.238037 54.108112,511.405579 54.604935,512.725037 
	C53.768639,513.785950 53.045738,514.364380 52.952866,515.030640 
	C51.977409,522.027466 52.005112,522.043457 44.796055,521.945496 
	C43.683182,521.930298 42.572624,521.745239 41.212994,521.614929 
	C41.052673,518.612122 40.906094,515.866638 40.759510,513.121216 
	C40.571323,513.127258 40.383133,513.133362 40.194946,513.139465 
	C40.194946,519.439209 40.194946,525.739014 40.194946,532.038757 
	C40.370384,532.045776 40.545822,532.052795 40.721260,532.059814 
	C40.905300,529.492249 41.089340,526.924744 41.265556,524.466370 
	C51.320496,522.823059 51.494057,522.936523 53.636452,532.200745 
	C54.081364,532.219177 54.526276,532.237671 54.971188,532.256104 
	C54.971188,525.838684 54.971188,519.421204 54.971191,513.003784 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M111.878159,485.269745 
	C111.879906,491.065369 111.879906,496.388306 111.879906,502.937164 
	C107.947212,498.640320 107.062546,476.033112 110.288040,471.044250 
	C110.894073,476.291565 111.385246,480.544312 111.878159,485.269745 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M111.910759,431.884064 
	C111.950485,433.623169 111.990211,435.362305 112.033325,437.534790 
	C110.790718,437.542419 109.544731,437.116669 107.820648,436.527527 
	C109.051788,428.567993 106.406441,420.004486 108.400215,411.050842 
	C108.813797,411.266479 108.102539,410.895660 107.192673,410.260895 
	C108.083862,409.693024 109.173660,409.389069 110.630768,409.540588 
	C111.302307,417.292053 111.606537,424.588074 111.910759,431.884064 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M108.217712,513.869690 
	C108.556351,511.048279 108.944870,508.636932 109.333382,506.225555 
	C109.702698,506.222168 110.072006,506.218811 110.441322,506.215424 
	C110.441322,515.108093 110.441322,524.000732 110.441322,532.893311 
	C109.919945,532.900574 109.398560,532.907837 108.877182,532.915039 
	C108.877182,527.827942 108.912941,522.740479 108.849449,517.654175 
	C108.835381,516.527100 108.470795,515.404419 108.217712,513.869690 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M110.736687,449.340485 
	C110.897430,452.422058 110.897430,455.191284 110.897430,457.960541 
	C110.581558,457.947296 110.265686,457.934052 109.949806,457.920807 
	C110.158524,454.956604 110.367233,451.992371 110.736687,449.340485 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M110.142151,443.510376 
	C110.459030,443.767365 110.741112,444.281799 111.023201,444.796265 
	C110.823303,444.882355 110.623405,444.968445 110.423500,445.054565 
	C110.318123,444.625641 110.212746,444.196747 110.142151,443.510376 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M110.090622,464.491058 
	C110.390839,464.344574 110.852043,464.428711 110.879341,464.587463 
	C110.955292,465.029266 110.851082,465.502075 110.727745,467.220703 
	C110.294792,465.637695 110.174179,465.196716 110.090622,464.491058 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M195.118912,932.163879 
	C193.173279,931.457092 191.243973,930.692261 189.256760,930.133362 
	C189.058014,930.077454 188.507462,931.272583 188.120209,931.887146 
	C188.797562,932.200989 189.474915,932.514771 190.572266,932.907776 
	C190.992249,932.987000 191.079361,933.068542 191.079361,933.068542 
	C189.359253,936.464355 186.634521,938.063293 182.792999,937.962036 
	C178.827469,937.857422 174.857010,937.938843 170.324203,937.938843 
	C170.186813,935.943359 169.985779,934.193726 169.958038,932.441345 
	C169.645737,912.713440 169.400970,892.984253 168.984421,873.258667 
	C168.953308,871.785828 167.743484,870.337891 166.574173,868.399902 
	C166.062302,861.967651 166.057877,856.013916 166.397125,849.979004 
	C167.475357,848.862610 168.832138,847.836853 168.848160,846.790527 
	C169.007172,836.406921 168.944458,826.019836 168.944458,815.074219 
	C174.682938,815.074219 179.909897,814.904846 185.114319,815.173218 
	C186.765427,815.258301 188.629105,816.197998 189.904327,817.309937 
	C191.428024,818.638611 192.424622,820.571716 193.391968,822.627930 
	C193.072617,826.143250 192.797638,829.292603 193.074097,832.392761 
	C193.161331,833.370911 194.650452,834.890930 195.626999,834.999268 
	C198.690292,835.338867 201.815048,835.124207 203.751968,835.124207 
	C205.536713,831.971741 206.749771,829.829041 208.331299,827.841064 
	C214.024124,828.861206 219.348495,829.726685 225.495850,830.725952 
	C225.511444,830.762634 226.072525,832.083435 226.229965,832.454041 
	C229.981018,829.967285 233.448822,827.668274 237.858521,824.744812 
	C237.858521,832.285217 237.858521,838.995239 237.472061,845.849487 
	C231.231689,845.611023 233.756470,850.065002 233.378616,852.822632 
	C233.750778,851.956116 234.122940,851.089600 234.662247,849.833984 
	C235.918137,851.342346 236.831223,852.439026 237.766388,853.970032 
	C237.294922,861.443481 240.081268,868.776855 236.291672,874.877258 
	C237.051636,877.298523 238.102646,879.202087 238.174683,881.142090 
	C238.409134,887.454407 238.366058,893.781250 238.272614,900.100159 
	C238.206116,904.597900 234.045135,908.330139 229.290863,908.409363 
	C229.121567,908.128845 228.907654,907.863647 228.797699,907.560791 
	C227.657166,904.418518 224.715927,902.935791 223.940872,905.152039 
	C224.735153,906.149048 225.538818,907.157837 226.790009,908.728394 
	C224.705521,908.728394 223.424545,908.728394 222.091797,908.362976 
	C219.769958,903.326111 218.317062,897.879578 214.909378,894.287292 
	C213.003860,892.278442 207.855774,893.339111 204.167496,893.031921 
	C203.188370,892.950317 202.204559,892.925171 201.034225,892.559570 
	C198.766937,889.908325 196.793137,890.965332 195.625076,892.942627 
	C195.030319,893.949524 195.784470,895.753174 195.928864,897.196655 
	C197.645508,896.483948 199.362167,895.771301 201.501282,895.051819 
	C204.536072,895.201050 207.228394,895.825134 209.740646,895.395691 
	C212.933426,894.849976 214.207260,896.375000 215.455093,898.761963 
	C217.152740,902.009460 218.782684,905.187439 216.627106,908.942200 
	C215.870377,908.973267 215.437576,908.982605 214.818161,908.699341 
	C211.933563,908.208496 209.235596,908.010193 207.428024,907.877380 
	C205.885056,906.287720 204.546951,904.909180 202.776154,903.364136 
	C200.532990,903.171631 198.696564,902.951660 196.921417,903.189453 
	C195.783875,903.341736 193.821976,904.129822 193.797424,904.700378 
	C193.637039,908.427063 193.299942,912.366882 194.401901,915.792725 
	C194.732361,916.820068 199.466278,916.446350 202.186844,916.664917 
	C202.449860,916.686035 202.741150,916.355347 203.419800,916.238770 
	C206.759003,912.796143 210.283600,910.346497 215.275497,910.223755 
	C219.671783,911.075317 223.790329,911.719604 228.793747,912.502319 
	C231.431686,910.783630 235.466690,908.154724 239.352325,905.623169 
	C243.455048,908.120361 247.458481,910.557129 250.695114,912.527161 
	C253.020157,911.699463 254.971451,910.619751 257.024902,910.365845 
	C259.959198,910.002991 262.968231,910.244568 266.238770,910.445923 
	C268.100006,911.715210 270.028320,912.479858 271.147858,913.887695 
	C272.677887,915.811707 283.680237,917.081421 284.208527,914.851318 
	C285.022797,911.413879 284.946533,907.572144 284.261566,904.096252 
	C284.067444,903.111267 280.453033,902.349426 278.388916,902.303345 
	C276.056335,902.251221 271.738251,902.965393 271.686951,903.615051 
	C271.412476,907.091064 268.987976,906.547241 266.570404,906.915283 
	C261.794403,907.398804 257.448212,907.968445 253.101990,908.538025 
	C253.007446,908.072632 252.912903,907.607178 252.818359,907.141724 
	C253.406082,906.294067 253.993805,905.446411 254.581528,904.598755 
	C254.284332,904.235474 253.987122,903.872131 253.689926,903.508850 
	C252.525482,904.386108 251.422943,905.366577 250.179474,906.112122 
	C248.890671,906.884888 246.553802,908.272034 246.246124,907.939087 
	C244.090118,905.605591 241.424545,903.131714 240.684662,900.250122 
	C239.678558,896.331787 240.113449,892.008606 240.159897,887.861694 
	C240.280197,877.119812 237.299881,876.962402 251.118195,877.102783 
	C252.592560,877.864197 253.891846,878.291565 255.483276,878.965576 
	C257.527588,879.169800 259.290375,879.230164 261.028473,879.051086 
	C262.333405,878.916748 263.606659,878.474731 265.086151,878.397888 
	C266.866302,878.054749 268.698395,877.776917 269.918335,876.762756 
	C270.384460,876.375183 269.449280,874.302063 269.152710,872.997070 
	C267.800659,873.450378 266.448639,873.903748 264.707764,874.379822 
	C260.808319,871.410400 257.733490,871.895691 255.002396,875.171387 
	C253.128403,872.409851 253.128403,872.409851 250.519699,876.140869 
	C246.542801,875.707886 241.367477,877.552856 240.551834,872.506409 
	C239.590118,866.556213 240.040054,860.314941 240.436691,854.236328 
	C240.617920,851.458923 240.292389,847.283630 240.237534,844.934204 
	C240.083557,838.340515 240.676285,831.729309 241.133575,825.434814 
	C244.479294,827.935364 247.665924,830.126221 250.817093,832.292664 
	C252.453201,828.905701 268.158752,827.207520 270.582397,830.525818 
	C270.677490,830.656006 270.734406,830.826538 270.768799,830.986877 
	C272.364685,838.433350 278.017883,833.677673 281.575256,834.114563 
	C285.376312,834.581421 283.824493,830.443848 283.867157,828.319336 
	C284.035065,819.954407 284.798462,820.898560 275.893890,820.951233 
	C273.452240,820.965637 270.261414,819.858032 269.835663,824.131592 
	C269.780365,824.686646 268.427734,825.421082 267.599792,825.539551 
	C262.902100,826.211487 258.181213,826.721252 253.051514,827.336243 
	C253.292664,825.643738 253.432724,824.660706 253.779190,822.228882 
	C249.035934,825.835632 244.760895,829.071960 240.862305,822.577148 
	C239.698120,820.267883 239.029022,818.369263 242.380127,817.390930 
	C248.298691,817.875122 253.975769,816.266357 259.142456,819.594238 
	C260.203949,820.277832 262.689423,820.183899 263.581696,819.392273 
	C265.325012,817.845825 266.343048,818.050415 268.218292,819.066162 
	C269.040771,819.511658 271.315735,818.443115 272.099915,817.422974 
	C272.729950,816.603455 272.107391,814.820984 272.040619,813.465820 
	C270.694824,813.588501 268.890656,813.201538 268.085663,813.925537 
	C265.208923,816.512695 263.576965,815.367737 262.193726,812.050781 
	C260.738068,813.337280 259.548889,814.388245 258.361786,815.437378 
	C257.965820,814.805115 257.552307,814.144897 257.566010,814.166809 
	C255.859238,814.871033 254.436676,815.884583 252.951675,815.986267 
	C249.312912,816.235535 245.645340,816.064087 241.702316,815.882324 
	C240.943527,812.135193 240.471756,808.567566 240.462311,804.967773 
	C241.521759,804.768555 242.118896,804.601624 243.008179,804.384155 
	C247.854034,805.058960 252.407761,805.784180 256.937347,806.895386 
	C260.440887,807.830505 263.975647,808.338074 267.494293,808.939941 
	C271.324066,809.595093 274.075928,808.041504 275.278412,804.532227 
	C276.755615,800.221375 279.853333,799.519531 284.102051,799.890869 
	C287.006195,799.940369 289.519196,799.956482 292.429260,800.017578 
	C296.685944,799.588562 296.575836,802.285095 296.605347,804.829041 
	C296.666473,810.098450 296.699066,815.368164 296.743591,820.637756 
	C296.871429,835.768616 296.970184,850.899841 297.155334,866.029968 
	C297.187256,868.638611 296.130249,868.806519 293.933167,867.531128 
	C290.990387,867.374268 288.306000,867.471252 285.266602,867.627869 
	C280.262268,870.682861 278.816620,875.103943 281.543549,879.381775 
	C282.624451,881.077515 284.596924,883.110291 286.336365,883.286926 
	C289.655792,883.624084 293.099335,882.739380 297.716522,882.225464 
	C297.716522,897.323730 297.716522,913.081909 297.571777,928.861145 
	C297.427002,928.882202 297.135437,928.937256 296.761108,928.940186 
	C295.245270,929.743896 294.103760,930.544739 293.126099,931.230591 
	C291.662628,930.454224 290.437531,929.804321 288.826355,929.095093 
	C286.061157,929.929871 281.337280,926.927673 281.673828,933.003174 
	C279.685059,933.670227 278.037933,934.236328 276.390808,934.802368 
	C275.947937,934.164185 275.505066,933.525940 275.062225,932.887756 
	C276.915497,932.054932 278.768768,931.222168 280.622070,930.389343 
	C277.476654,929.576782 275.358765,929.806458 273.238007,930.005859 
	C271.124603,930.204590 269.010773,930.442810 266.891998,930.516846 
	C266.143524,930.543030 265.354431,929.895996 264.628174,929.973267 
	C262.728394,930.175171 260.853058,930.607117 258.598450,930.934875 
	C255.965729,929.042847 253.696365,927.531372 251.018707,930.945862 
	C255.194260,933.104919 255.241333,935.367798 250.666412,936.087830 
	C245.076462,936.967590 239.355255,937.013306 233.754440,937.415100 
	C233.080460,935.291260 232.647644,933.927368 232.472321,933.374817 
	C229.481140,932.222656 227.255371,931.002747 224.884460,930.559998 
	C222.763718,930.164062 219.980225,929.858398 218.396515,930.896790 
	C216.282532,932.282898 215.045532,935.006470 213.173706,937.499512 
	C212.148010,935.647644 211.692886,934.825989 211.095734,933.657471 
	C209.525528,930.010071 207.515289,928.812866 204.186829,931.146790 
	C203.576187,931.574951 202.084015,931.500183 201.467926,931.035461 
	C198.834961,929.049744 196.946930,930.197144 195.118912,932.163879 
M202.776138,924.102600 
	C205.311279,924.654785 207.846420,925.206970 210.999512,925.893738 
	C210.825577,923.354370 210.680176,921.231567 210.595291,919.992188 
	C207.540207,920.623962 205.130188,921.122437 202.380951,920.913208 
	C199.368057,918.171814 196.758926,916.870972 194.482895,921.920837 
	C193.898254,923.218018 191.470581,923.684509 189.889618,924.532654 
	C189.680054,924.072083 189.470490,923.611572 189.260941,923.151001 
	C189.859085,922.648438 190.458817,922.147705 191.054977,921.642761 
	C191.722641,921.077209 192.387146,920.507935 193.053024,919.940308 
	C192.685822,919.494690 192.318634,919.049011 191.951431,918.603394 
	C190.389786,920.023071 188.534775,921.251465 187.419495,922.962219 
	C187.007751,923.593750 188.002975,925.819214 188.947327,926.510315 
	C190.655991,927.760925 200.304794,925.622986 202.776138,924.102600 
M186.213669,839.068420 
	C183.235153,835.333130 180.558243,835.625549 178.242966,839.283936 
	C177.225540,840.891541 174.784714,841.598267 172.990189,842.714050 
	C172.668915,842.224365 172.347641,841.734741 172.026352,841.245056 
	C173.467773,839.998718 174.909180,838.752380 176.350601,837.505981 
	C175.930603,837.038208 175.510605,836.570374 175.090607,836.102600 
	C173.479523,837.675964 171.634735,839.093140 170.410156,840.924927 
	C170.074326,841.427246 171.862015,844.267212 172.224487,844.179138 
	C176.835236,843.058960 182.544708,846.674011 186.796082,841.436035 
	C189.051407,842.077759 191.306732,842.719482 193.854950,843.444580 
	C193.854950,841.196045 193.854950,839.014465 193.854950,837.907532 
	C191.282852,838.551025 188.832108,839.164185 186.213669,839.068420 
M276.586914,852.218750 
	C281.562500,852.618652 286.538086,853.018555 292.236084,853.476501 
	C291.833618,849.893188 291.577789,847.615540 291.463837,846.601013 
	C288.798370,846.356567 287.251678,845.971191 285.761963,846.127808 
	C282.493042,846.471680 278.854340,843.158020 275.333710,846.704041 
	C272.451752,846.170227 269.585663,845.482849 266.679565,845.182678 
	C265.604309,845.071655 263.501038,845.810181 263.471527,846.265320 
	C263.348755,848.157104 263.739532,851.729614 264.118530,851.752502 
	C268.029083,851.988037 272.014282,855.143066 276.586914,852.218750 
M176.164658,847.783936 
	C170.819931,847.339783 170.819931,847.339783 171.502548,855.540039 
	C175.727600,856.482239 176.886093,853.408752 178.094025,848.916626 
	C179.625610,851.648987 180.455933,853.130310 181.573563,855.124146 
	C181.969543,853.268127 182.241013,851.995667 182.512466,850.723267 
	C182.808975,850.785950 183.105484,850.848572 183.401978,850.911255 
	C183.651627,852.493408 183.901291,854.075623 184.185257,855.875366 
	C186.289383,855.396973 188.414062,854.172119 189.830780,854.733521 
	C193.787842,856.301941 196.963959,856.343262 199.795380,852.763123 
	C200.266449,852.167542 202.311493,851.858582 202.458878,852.108154 
	C204.864182,856.181458 208.890594,852.059448 211.838043,854.848328 
	C211.838043,852.103333 211.838043,850.184265 211.838043,847.778687 
	C210.258713,848.657227 209.030624,849.340393 207.810913,850.018921 
	C201.623566,845.640259 195.437271,847.830139 189.126144,849.731079 
	C186.937317,847.370667 186.937317,847.370667 176.164658,847.783936 
M220.570129,866.122009 
	C223.274689,865.973083 225.984207,865.883179 228.682281,865.656982 
	C230.928558,865.468750 233.161469,865.120728 235.400299,864.843445 
	C234.929565,862.671570 234.458832,860.499695 234.159622,859.119141 
	C228.288101,857.016541 228.645996,866.511780 222.393082,863.330872 
	C224.207245,861.877930 225.559525,860.794922 226.911804,859.711914 
	C226.560867,859.271667 226.209915,858.831360 225.858963,858.391113 
	C223.162033,860.458923 220.465088,862.526672 217.483978,864.812317 
	C216.167297,857.901917 214.465714,857.472046 207.523422,862.580994 
	C209.738098,862.767517 211.952759,862.954041 214.627731,863.179321 
	C214.721573,863.763428 214.937683,865.108704 215.213089,866.823059 
	C216.915222,866.643372 218.356583,866.491211 220.570129,866.122009 
M272.377838,918.043518 
	C269.422974,918.082520 267.027832,918.604309 268.890228,922.716431 
	C266.788940,923.045654 265.032227,923.320862 263.275513,923.596069 
	C263.154358,923.287415 263.033203,922.978699 262.912048,922.670044 
	C264.179901,921.457825 265.447754,920.245667 267.317627,918.457886 
	C263.127136,918.227844 260.666962,919.728760 260.751434,922.890747 
	C260.835144,926.027405 263.161774,926.251160 266.199219,925.518066 
	C271.054840,924.346191 276.112305,924.010803 281.309906,923.290588 
	C278.386566,921.412781 275.778595,919.737488 272.377838,918.043518 
M175.608902,861.976990 
	C177.236542,862.974976 178.864197,863.973022 180.548981,865.006042 
	C181.156815,864.278259 182.222351,863.002380 183.760513,861.160583 
	C184.593536,863.537048 185.062637,864.875305 185.458954,866.005920 
	C187.126907,865.004761 188.814224,864.215698 190.205673,863.067871 
	C190.967392,862.439575 191.506180,861.224854 191.576874,860.233948 
	C191.604309,859.849182 190.061203,858.961304 189.240204,858.969604 
	C183.206436,859.030151 177.174103,859.252808 171.142929,859.469238 
	C170.960251,859.475769 170.795181,859.972961 170.627838,860.232239 
	C171.738602,862.036133 172.830353,863.809143 174.191742,866.020081 
	C174.580017,864.398621 174.808365,863.445129 175.608902,861.976990 
M254.437164,846.473206 
	C253.059509,846.473206 251.681839,846.473206 250.267059,846.473206 
	C250.206848,847.701599 250.161972,848.617126 250.101654,849.847412 
	C249.138504,848.326050 248.369537,847.111389 246.923080,844.826538 
	C246.030807,847.777222 245.501312,849.528198 244.696930,852.188293 
	C248.814087,852.591248 252.178055,852.920532 255.167007,853.213074 
	C255.063385,851.002991 254.968170,848.972168 254.437164,846.473206 
M191.945450,890.939514 
	C190.585480,891.728271 188.738068,892.214417 188.006180,893.393188 
	C187.310272,894.513855 187.780396,896.358582 187.737839,897.884888 
	C189.123795,897.855652 191.590332,898.149963 191.712341,897.742310 
	C192.303192,895.768860 192.264282,893.606873 191.945450,890.939514 
M197.720474,864.341797 
	C197.307861,864.950989 196.895233,865.560181 196.482605,866.169373 
	C198.236877,866.403381 199.991150,866.637390 201.641098,866.857483 
	C201.966522,864.745605 202.645050,863.288635 202.233948,862.272766 
	C201.707016,860.970703 200.448318,859.782898 199.209473,859.035889 
	C198.676147,858.714355 197.400879,859.623413 195.177704,860.452698 
	C197.758926,861.168579 198.944092,861.497253 200.726242,861.991516 
	C199.569809,862.963013 198.961090,863.474365 197.720474,864.341797 
M208.519791,866.487000 
	C208.519791,866.487000 208.485870,866.505981 208.519791,866.487000 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M108.586014,810.859558 
	C107.934082,814.611328 109.799019,815.878235 113.984436,815.889709 
	C116.172287,815.962708 117.585190,816.008118 119.038025,816.383789 
	C119.491890,817.200745 119.905807,817.687378 120.265526,818.600891 
	C120.362869,821.228455 120.514397,823.429138 120.694824,826.049500 
	C125.279213,825.380798 129.517776,824.730591 133.769562,824.183105 
	C134.296310,824.115234 134.970490,824.487976 135.444977,824.835693 
	C138.011627,826.716736 140.044876,825.834167 142.223480,823.932617 
	C143.152802,824.281555 144.183258,824.619507 144.253876,824.478088 
	C146.603912,819.772339 148.661102,821.903809 150.109955,824.341370 
	C152.043594,823.796509 153.494705,823.387634 155.154663,822.989136 
	C155.363541,822.999512 155.781708,823.008850 155.913513,823.307983 
	C156.633850,823.492676 157.728241,823.400940 157.734558,823.260376 
	C157.819901,821.357727 157.754105,819.448425 157.813339,817.110291 
	C157.949081,816.390991 157.997162,816.102173 158.483749,815.798462 
	C160.496948,815.905273 162.071625,816.026917 164.749359,816.233826 
	C164.749359,827.003174 164.749359,837.347534 164.301270,847.759277 
	C158.694580,847.887085 153.530029,848.124878 148.379303,847.951355 
	C144.344849,847.815430 143.442444,849.445679 144.342346,853.106445 
	C146.936646,863.660156 153.650162,869.395508 164.783646,870.340454 
	C164.841736,892.902283 164.899826,915.464111 164.500946,938.519409 
	C139.683472,939.278625 115.322983,939.544495 90.859962,939.636719 
	C90.579834,939.250366 90.358902,939.101929 90.057381,938.555908 
	C89.688309,922.379395 89.379845,906.664307 88.984322,890.951477 
	C88.949265,889.558899 88.346764,888.180664 88.007812,886.795776 
	C88.003677,885.109314 87.999542,883.422791 87.989418,881.308594 
	C88.293045,879.319092 88.866974,877.758118 88.871735,876.195435 
	C88.924271,858.953552 88.891762,841.711426 88.868782,824.469360 
	C88.867233,823.309998 89.041214,822.041504 88.645355,821.011353 
	C87.288918,817.481323 88.208984,815.113708 91.888412,813.106323 
	C91.985092,811.886841 91.987350,811.391907 91.989609,810.897034 
	C97.364143,810.883606 102.738686,810.870117 108.586014,810.859558 
M115.390373,876.937073 
	C114.266800,877.614197 113.143227,878.291321 111.869591,878.238220 
	C110.091652,877.740723 108.313721,877.243286 106.535782,876.745850 
	C106.542145,879.257629 106.548515,881.769409 106.554871,884.281189 
	C107.064804,883.848145 107.574738,883.415161 108.481720,882.774048 
	C108.727829,882.585938 108.897125,882.344116 109.625778,881.974365 
	C110.052589,881.968811 110.479393,881.963257 111.389206,881.991699 
	C111.389206,881.991699 111.871208,882.011475 111.987343,882.684265 
	C114.031319,883.131958 116.075294,883.579651 118.555038,884.008545 
	C118.555038,884.008545 118.991173,884.003052 119.740166,884.057739 
	C120.489616,884.036865 121.239067,884.015991 122.506645,883.995667 
	C122.679337,883.993530 122.852036,883.991455 123.740440,884.321228 
	C125.192902,884.558655 126.840614,885.339661 128.051605,884.904724 
	C129.820679,884.269287 132.674820,882.607727 132.570709,881.656860 
	C132.361893,879.749268 130.628143,876.654602 129.232117,876.487549 
	C125.210663,876.006226 121.044083,876.737488 116.513985,876.990234 
	C116.513985,876.990234 116.092293,876.975037 115.390373,876.937073 
M99.446869,814.677856 
	C98.154312,814.574463 96.861748,814.471130 95.569183,814.367737 
	C95.593918,814.883606 95.618652,815.399475 95.643387,815.915344 
	C96.907043,815.731018 98.170700,815.546692 99.446869,814.677856 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M304.985352,941.033936 
	C298.371094,941.373169 291.760162,941.904480 285.142029,942.017639 
	C259.719696,942.452759 234.295059,942.753784 208.399353,943.170654 
	C194.032272,943.236389 180.137115,943.236389 166.241959,943.236389 
	C166.154251,942.582703 166.066559,941.929016 165.978867,941.275330 
	C167.623001,940.882935 169.260849,940.183655 170.912323,940.149841 
	C179.374863,939.976624 187.840912,939.974915 197.019608,939.798889 
	C198.070145,939.666443 198.406830,939.648438 199.154419,939.768433 
	C200.709656,939.102905 201.853989,938.299377 203.229401,937.784912 
	C203.866852,938.346130 204.273239,938.618164 204.913788,939.172119 
	C205.933365,939.651611 206.722412,940.034851 207.503586,940.019470 
	C225.025452,939.672546 242.546432,939.281860 260.419769,938.909302 
	C263.761200,935.580688 266.851776,939.817017 270.310699,938.891968 
	C272.535980,938.936890 274.322662,938.887329 276.481262,938.840881 
	C277.838165,938.773926 278.823181,938.703857 280.273651,938.761475 
	C286.614075,938.919373 292.501678,939.172791 298.356049,938.840454 
	C300.056946,938.743958 301.662689,936.971985 303.489502,935.972534 
	C303.666931,935.977051 304.021423,935.996094 304.035828,936.294067 
	C304.366028,937.061401 304.681793,937.530701 304.998962,938.378967 
	C304.995361,939.516602 304.990356,940.275269 304.985352,941.033936 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M90.962494,939.810303 
	C115.322983,939.544495 139.683472,939.278625 164.509644,938.985474 
	C164.985153,939.426086 164.995010,939.894165 164.597580,940.677856 
	C162.466766,941.254944 160.743225,941.516357 158.580795,941.772034 
	C149.345993,941.511414 140.551514,941.135864 131.753830,941.034058 
	C122.096001,940.922180 112.434715,941.135010 102.775948,941.065369 
	C97.201775,941.025208 91.629608,940.706421 86.056541,940.513123 
	C86.135071,940.289612 86.160851,940.060181 86.437500,939.500610 
	C86.851654,936.997131 86.962204,934.817932 87.072762,932.638672 
	C87.089737,931.452026 87.106712,930.265381 87.139862,928.307861 
	C87.199440,926.726624 87.242821,925.916199 87.286209,925.105835 
	C87.461197,916.078552 87.682266,907.051758 87.788315,898.023682 
	C87.830215,894.456299 87.616066,890.886047 87.763161,887.056396 
	C88.346764,888.180664 88.949265,889.558899 88.984322,890.951477 
	C89.379845,906.664307 89.688309,922.379395 90.115295,938.768555 
	C90.380768,939.730164 90.631447,939.852478 90.962494,939.810303 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M85.847870,940.789673 
	C91.629608,940.706421 97.201775,941.025208 102.775948,941.065369 
	C112.434715,941.135010 122.096001,940.922180 131.753830,941.034058 
	C140.551514,941.135864 149.345993,941.511414 158.564545,942.144409 
	C158.974731,942.769165 158.962280,943.015686 158.949829,943.262146 
	C136.694901,943.489319 114.439987,943.716919 92.185043,943.942566 
	C90.365974,943.960999 88.546761,943.964172 86.264664,943.746399 
	C85.649361,943.300903 85.450722,943.140930 85.205795,943.038391 
	C85.350266,942.380981 85.494736,941.723572 85.847870,940.789673 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M165.084915,870.142761 
	C153.650162,869.395508 146.936646,863.660156 144.342346,853.106445 
	C143.442444,849.445679 144.344849,847.815430 148.379303,847.951355 
	C153.530029,848.124878 158.694580,847.887085 164.583466,847.973938 
	C165.560333,848.767639 165.806885,849.413879 166.053436,850.060181 
	C166.057877,856.013916 166.062302,861.967651 166.162750,868.367798 
	C165.967911,869.191223 165.677048,869.568176 165.084915,870.142761 
M153.261749,864.332642 
	C156.860931,865.846741 160.460098,867.360779 165.255814,869.378235 
	C165.255814,863.040710 165.439835,858.570679 165.125473,854.135986 
	C165.047607,853.037476 163.403946,851.218384 162.394089,851.157532 
	C157.306381,850.851074 152.190262,851.016846 147.096817,851.016846 
	C147.109085,856.651978 148.324661,859.279785 153.261749,864.332642 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M198.743500,939.630371 
	C198.406830,939.648438 198.070145,939.666443 197.483185,939.651794 
	C196.511292,937.389771 195.789688,935.160461 195.093491,932.547485 
	C196.946930,930.197144 198.834961,929.049744 201.467926,931.035461 
	C202.084015,931.500183 203.576187,931.574951 204.186829,931.146790 
	C207.515289,928.812866 209.525528,930.010071 210.762634,933.782471 
	C208.607574,935.799561 206.643616,937.344910 204.679626,938.890259 
	C204.273239,938.618164 203.866852,938.346130 203.225830,937.480042 
	C199.208801,934.137939 199.260086,937.323608 198.743500,939.630371 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M159.248779,943.440430 
	C158.962280,943.015686 158.974731,942.769165 159.003448,942.150146 
	C160.743225,941.516357 162.466766,941.254944 164.575073,941.028015 
	C164.460312,944.676636 161.886368,943.898560 159.248779,943.440430 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M190.951721,932.943237 
	C191.061508,932.912292 191.211838,932.925049 191.220764,933.003174 
	C191.079361,933.068542 190.992249,932.987000 190.951721,932.943237 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M85.154388,943.241943 
	C85.450722,943.140930 85.649361,943.300903 85.888580,943.691162 
	C85.975449,943.863953 85.530518,943.943604 85.308365,943.904297 
	C85.086220,943.864990 85.102989,943.445435 85.154388,943.241943 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2022.770264,89.216873 
	C2022.944702,94.376366 2022.846069,99.187149 2023.090942,103.980362 
	C2023.190186,105.919312 2024.039429,107.819916 2024.575195,109.843193 
	C2023.555420,109.843193 2021.938354,109.843193 2020.385254,109.843193 
	C2020.809814,103.723404 2022.411377,97.471779 2018.905518,90.540833 
	C2019.521729,90.263283 2021.078491,89.562187 2022.770264,89.216873 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2034.908936,97.905434 
	C2034.949341,97.521378 2035.142334,97.267838 2035.603760,96.935394 
	C2035.807373,96.807289 2035.986816,96.997192 2035.955322,97.295029 
	C2035.727539,97.848656 2035.478394,98.032867 2035.176147,98.145493 
	C2035.176147,98.145493 2034.996826,97.986748 2034.908936,97.905434 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1438.026855,533.938293 
	C1443.995605,534.319031 1449.964478,534.699707 1456.429932,535.564880 
	C1456.926636,549.218689 1456.926636,562.387939 1456.926636,575.466614 
	C1450.338379,576.272034 1447.377808,580.979736 1443.665283,585.315674 
	C1441.300537,586.643066 1440.318115,586.351257 1439.559814,583.804626 
	C1438.732910,581.027710 1438.088867,581.056396 1436.122437,582.887939 
	C1435.682007,583.314453 1435.512817,583.492310 1435.109741,583.585083 
	C1434.429321,583.317688 1433.982788,583.135437 1433.317139,582.652527 
	C1430.983154,578.844177 1428.941162,575.288330 1426.681274,571.876709 
	C1426.310425,571.316589 1424.982422,571.390564 1423.753174,571.052002 
	C1420.394653,570.932495 1417.379883,570.932495 1413.994263,570.932495 
	C1413.994263,574.835510 1413.828857,577.997498 1414.089355,581.124023 
	C1414.173706,582.137939 1415.468994,583.942932 1416.048706,583.880615 
	C1420.188843,583.435852 1424.287231,582.601746 1428.585205,582.153870 
	C1429.853149,582.919189 1430.934692,583.422119 1432.324951,584.142944 
	C1433.164795,586.143494 1433.695801,587.926331 1434.246338,589.774780 
	C1434.737183,589.621277 1435.727295,589.311646 1436.717407,589.002014 
	C1436.588013,589.460571 1436.458740,589.919128 1436.329346,590.377686 
	C1438.215576,591.323547 1440.101807,592.269470 1442.016357,593.497314 
	C1442.348389,594.465942 1442.651978,595.152649 1442.499023,595.910767 
	C1437.713257,596.042175 1433.384033,596.102112 1428.588135,596.082642 
	C1424.299805,595.998230 1420.477173,595.932129 1416.656982,595.999817 
	C1406.047729,596.187744 1395.439331,596.431396 1384.562256,596.525391 
	C1384.107788,596.329102 1383.921997,596.260376 1383.759277,595.771729 
	C1383.697876,592.932922 1382.678955,589.840027 1383.797729,588.289246 
	C1384.864990,586.809692 1388.160034,586.937134 1391.461304,586.111694 
	C1382.964966,583.933655 1382.101929,584.447144 1380.940430,590.882141 
	C1380.763184,591.864197 1379.768311,592.698608 1379.024658,593.787109 
	C1378.073486,591.113770 1377.375977,589.153564 1376.678467,587.193359 
	C1376.152832,587.356567 1375.627197,587.519836 1375.101562,587.683044 
	C1375.101562,590.484131 1375.101562,593.285278 1374.687988,596.220581 
	C1372.314331,596.397827 1370.346313,596.572876 1368.395630,596.456848 
	C1364.098389,596.201172 1361.456421,592.202209 1363.014648,587.957581 
	C1366.947754,584.686523 1368.941528,579.494141 1373.995605,578.125671 
	C1375.039307,580.481140 1375.813843,582.229004 1377.079346,585.084778 
	C1378.025635,581.721863 1378.573120,579.776184 1379.458252,577.989258 
	C1380.772339,578.169617 1381.748779,578.191345 1382.871826,578.506775 
	C1385.664917,579.117920 1388.342407,579.768921 1390.936279,579.525879 
	C1391.612549,579.462524 1392.027710,576.612732 1392.562744,575.042725 
	C1390.893921,574.974426 1389.213989,574.763977 1387.560913,574.894958 
	C1386.671997,574.965454 1385.829468,575.621582 1384.973999,575.602966 
	C1383.054321,571.704895 1381.070923,574.095337 1378.779541,575.018738 
	C1377.467651,574.498474 1375.947876,573.469421 1375.566772,573.790710 
	C1371.529053,577.195007 1367.603760,580.742676 1363.797363,584.406433 
	C1362.094238,586.045715 1360.733887,588.052063 1358.245605,585.778564 
	C1357.580078,584.200745 1356.754028,582.893860 1355.927979,581.586914 
	C1355.776489,582.243591 1355.625122,582.900208 1355.217529,583.815918 
	C1354.810669,584.265259 1354.660034,584.455566 1354.203369,584.508606 
	C1353.165039,584.211609 1352.432373,584.052002 1351.918945,583.541443 
	C1350.401611,580.809448 1348.664917,578.428345 1346.824951,575.669434 
	C1345.810425,568.246277 1340.579834,571.728882 1336.567505,571.234741 
	C1335.236694,571.708008 1333.529175,572.176331 1333.485718,572.767151 
	C1333.230957,576.228577 1333.272827,579.724182 1333.479614,583.192444 
	C1333.506836,583.650146 1335.046387,584.017700 1335.810547,584.760925 
	C1331.242798,585.199219 1326.713257,584.983704 1322.267090,585.494446 
	C1317.427856,586.050354 1315.075439,584.309265 1315.096802,579.512268 
	C1315.130005,572.069458 1315.070435,564.625732 1314.971191,557.183472 
	C1314.958984,556.263000 1314.463623,555.349060 1314.192139,554.432129 
	C1313.762207,554.489746 1313.332153,554.547302 1312.902222,554.604919 
	C1312.902222,568.684570 1312.902222,582.764282 1312.443848,596.880615 
	C1307.342896,596.913391 1302.700195,596.909607 1298.038452,596.560303 
	C1298.692505,595.104980 1299.365723,593.995117 1300.354980,592.894775 
	C1302.280396,592.549561 1303.889526,592.194885 1305.846924,591.763489 
	C1305.846924,590.638123 1305.846924,588.854797 1305.846924,586.291809 
	C1303.521362,592.177734 1302.359619,585.744385 1299.845703,586.676086 
	C1299.845703,588.288330 1299.845703,590.053406 1299.532349,591.687744 
	C1298.923218,589.596680 1298.627319,587.636353 1298.244385,585.098633 
	C1294.400024,585.569031 1290.326538,586.731384 1286.397583,586.360718 
	C1282.497803,585.992859 1279.797852,585.652649 1279.679565,590.654419 
	C1279.668579,591.121094 1277.940552,591.547180 1276.703857,591.803711 
	C1274.103149,589.285950 1271.864990,587.070129 1276.094360,584.299622 
	C1276.749756,583.870300 1276.371948,581.863708 1276.471924,580.586548 
	C1276.170898,580.543091 1275.869751,580.499634 1275.568604,580.456116 
	C1275.148804,581.168701 1274.729004,581.881226 1274.003418,583.112793 
	C1273.660400,581.479553 1273.436523,580.413025 1273.535400,579.143860 
	C1274.587158,576.086975 1275.315918,573.232605 1276.444824,570.345642 
	C1277.447876,566.375366 1278.050781,562.437683 1278.735229,557.966492 
	C1274.386597,557.966492 1271.118652,557.885071 1267.863281,558.050293 
	C1267.347778,558.076477 1266.888916,559.215149 1266.072144,559.996826 
	C1265.948975,563.548462 1265.304810,569.476807 1266.518188,569.885193 
	C1271.048340,571.409546 1270.433228,575.066162 1271.649414,578.476257 
	C1271.838013,582.649292 1271.998901,586.355591 1271.775269,590.084473 
	C1265.453979,588.830566 1259.516479,587.558838 1253.582031,586.271790 
	C1252.803833,586.102966 1251.668335,586.105530 1251.333374,585.604309 
	C1248.808594,581.826660 1246.456299,583.677856 1245.090210,585.066284 
	C1242.854492,584.371765 1241.371826,583.485596 1239.992310,583.624207 
	C1238.982666,583.725647 1238.108398,585.173645 1237.173828,586.021484 
	C1238.774536,587.481812 1240.375366,588.942017 1242.139282,590.551208 
	C1242.791870,589.010803 1243.132446,588.206787 1243.539062,587.246887 
	C1245.282959,589.890564 1246.926514,591.335327 1249.696533,588.799683 
	C1250.533081,588.033997 1252.148926,587.515808 1253.240356,587.731445 
	C1259.845459,589.036438 1266.401855,590.586975 1273.016357,592.419128 
	C1274.038086,593.913147 1275.018066,595.043457 1276.178955,596.382324 
	C1275.429810,596.718384 1275.028076,597.049255 1274.617065,597.061096 
	C1262.847778,597.400940 1251.077637,597.709106 1239.307617,598.021667 
	C1236.846069,598.086975 1234.623413,598.031311 1234.565308,594.532532 
	C1234.557373,594.049255 1232.205444,593.588074 1230.919800,593.175781 
	C1230.819458,593.143555 1230.568726,593.579590 1230.333862,593.863342 
	C1231.185913,594.652161 1232.040771,595.443665 1232.895630,596.235168 
	C1232.820923,596.690552 1232.746094,597.145996 1232.671387,597.601379 
	C1227.822144,597.601379 1222.973022,597.601379 1218.070801,597.160522 
	C1218.046753,576.837158 1218.075806,556.954773 1218.104858,537.072327 
	C1226.769287,536.404419 1235.433838,535.736450 1244.789307,535.076111 
	C1245.480469,535.083679 1245.937012,535.063232 1245.937012,535.063232 
	C1247.650879,535.093262 1249.364746,535.123291 1251.659668,535.407837 
	C1252.512939,536.141479 1252.785278,536.620789 1252.790283,537.330750 
	C1251.756104,539.365601 1250.799561,541.130005 1250.405151,543.012146 
	C1250.337646,543.334167 1252.749878,544.788879 1253.880615,544.645752 
	C1259.563354,543.926331 1265.201050,542.850159 1271.259277,541.915710 
	C1277.777222,541.725769 1273.848877,537.714844 1274.171509,535.314575 
	C1281.248657,535.640869 1288.325684,535.967224 1295.690918,536.306824 
	C1295.690918,538.246521 1295.690918,539.972778 1295.690918,541.699097 
	C1296.079102,541.808228 1296.467163,541.917358 1296.855347,542.026550 
	C1297.397949,540.281982 1297.940552,538.537415 1298.708496,536.068237 
	C1310.227661,535.665894 1322.129639,535.246033 1334.031982,534.835999 
	C1341.308350,534.585266 1348.586060,534.143127 1355.861572,534.174744 
	C1361.314331,534.198425 1366.783569,534.685120 1372.198730,535.347107 
	C1373.202271,535.469666 1374.230835,537.295349 1374.805054,538.539429 
	C1375.535522,540.121643 1375.814819,541.912170 1376.288574,543.612976 
	C1376.756226,543.532288 1377.223999,543.451660 1377.691650,543.370972 
	C1377.875366,540.844177 1378.059204,538.317444 1378.249390,535.702576 
	C1384.686523,535.153564 1390.927002,534.621338 1397.837158,534.072998 
	C1398.506958,534.056946 1398.987671,534.012268 1399.456055,534.031982 
	C1405.452759,533.717346 1410.980957,533.382935 1416.550781,533.122009 
	C1416.592407,533.195435 1416.756104,533.236572 1416.756104,533.236572 
	C1418.573608,533.513062 1420.390991,533.789612 1423.075195,534.063599 
	C1428.636841,534.020203 1433.331909,533.979248 1438.026855,533.938293 
M1268.832764,585.106384 
	C1269.355957,584.745850 1269.879272,584.385315 1270.402466,584.024780 
	C1269.618042,583.798584 1268.833740,583.572388 1267.526123,583.234802 
	C1267.243408,583.543762 1266.960815,583.852783 1266.678223,584.161804 
	C1267.118774,584.440735 1267.559448,584.719666 1268.832764,585.106384 
M1289.069214,550.229858 
	C1288.515869,549.838989 1288.008911,549.231567 1287.399658,549.100708 
	C1285.993530,548.798767 1284.537842,548.728027 1282.574707,548.172791 
	C1280.496094,548.033386 1278.417358,547.893982 1276.338745,547.754517 
	C1276.582397,548.215942 1276.826050,548.677307 1277.069824,549.138672 
	C1275.407349,550.706909 1273.744873,552.275146 1272.082397,553.843384 
	C1271.623901,553.374817 1271.165405,552.906311 1270.706909,552.437744 
	C1271.892212,551.183960 1273.077393,549.930115 1274.262695,548.676270 
	C1274.085205,548.378601 1273.907837,548.080933 1273.730469,547.783264 
	C1271.852783,548.693054 1269.975098,549.602783 1268.340820,550.394592 
	C1267.389404,549.501099 1265.854004,547.891602 1265.745850,547.982300 
	C1263.324097,550.015137 1260.630005,548.609375 1258.111816,548.680481 
	C1255.827271,548.744995 1253.531128,548.400635 1251.239990,548.236511 
	C1251.162109,548.667847 1251.084106,549.099121 1251.006104,549.530457 
	C1252.026733,550.155029 1253.047363,550.779541 1254.256226,551.519348 
	C1252.755249,552.870789 1251.640137,553.874695 1249.945679,555.400452 
	C1258.241333,555.400452 1265.645630,555.470398 1273.046387,555.339783 
	C1274.478638,555.314514 1276.640137,554.938782 1277.195068,553.985352 
	C1279.788940,549.530029 1281.464233,551.955444 1283.316406,555.300537 
	C1283.803101,553.777954 1284.046997,553.015015 1284.975952,552.502075 
	C1285.655518,552.319031 1286.335083,552.135986 1287.360107,552.389282 
	C1287.781128,552.960693 1288.202148,553.532166 1289.461670,555.241760 
	C1289.104736,552.949829 1288.954590,551.985291 1289.069214,550.229858 
M1332.340942,562.559753 
	C1330.303467,561.022949 1328.286255,558.892700 1325.734375,562.428711 
	C1327.181641,562.863281 1328.335815,563.209778 1330.124146,563.746704 
	C1327.968750,565.224487 1326.482422,566.243469 1324.996216,567.262512 
	C1325.170044,567.783569 1325.343872,568.304626 1325.517822,568.825684 
	C1328.375977,568.221558 1331.234131,567.617371 1334.057373,567.620667 
	C1336.031372,567.755066 1338.011841,568.061890 1339.978027,567.987427 
	C1343.915283,567.838440 1348.222046,568.408997 1351.691406,566.998291 
	C1357.303711,564.716187 1362.567993,565.372681 1368.471313,566.522400 
	C1368.355835,564.475647 1368.246582,562.539185 1368.088379,559.735291 
	C1366.269409,561.358276 1365.318237,562.206909 1364.409546,563.017761 
	C1358.059326,559.141724 1357.339600,559.324036 1352.660522,564.278442 
	C1351.652588,565.345581 1349.769409,565.586060 1348.289307,566.207275 
	C1348.103882,565.873047 1347.918457,565.538818 1347.733154,565.204590 
	C1348.890747,563.906555 1350.048462,562.608521 1351.206177,561.310486 
	C1350.970459,560.991943 1350.734741,560.673401 1350.499023,560.354858 
	C1348.719116,561.288940 1346.939087,562.223022 1345.144165,563.164978 
	C1341.924316,559.191345 1340.530029,559.347046 1337.766479,563.535034 
	C1337.293457,563.315247 1336.820557,563.095398 1335.908325,562.239197 
	C1334.967407,562.406128 1334.026611,562.573120 1332.340942,562.559753 
M1330.152344,554.691284 
	C1329.078857,554.691284 1328.005371,554.691284 1325.638672,554.691284 
	C1328.100464,552.569702 1329.397095,551.452148 1330.693848,550.334595 
	C1330.377808,549.922913 1330.061768,549.511230 1329.745728,549.099548 
	C1328.073364,550.498779 1326.235107,551.755249 1324.832764,553.386597 
	C1324.426636,553.858948 1325.180298,556.273254 1325.557739,556.312805 
	C1327.317505,556.497131 1329.131958,556.161438 1331.669189,556.243286 
	C1334.617310,557.556580 1342.330811,555.724731 1345.160034,553.154907 
	C1343.334717,552.129883 1341.547119,550.835083 1339.555054,550.077515 
	C1336.777466,549.021240 1333.655029,547.698914 1332.848145,552.569153 
	C1332.730469,553.280334 1331.588379,553.821838 1330.152344,554.691284 
M1424.254761,562.904968 
	C1418.806152,562.603455 1413.379272,562.393005 1408.250366,560.010681 
	C1407.320068,559.578491 1405.599609,560.847412 1404.243896,561.331116 
	C1404.312134,561.701172 1404.380371,562.071167 1404.448608,562.441223 
	C1405.724121,562.776123 1406.999634,563.111084 1408.529297,563.512756 
	C1407.663452,564.519043 1406.970459,565.324341 1406.277466,566.129639 
	C1406.346802,566.626160 1406.416016,567.122742 1406.485229,567.619263 
	C1412.736450,567.316772 1418.987671,567.014343 1425.799561,567.176758 
	C1430.508667,566.084839 1435.217896,564.992981 1439.867798,563.914795 
	C1439.881714,564.031921 1440.036865,565.333862 1440.113159,565.974487 
	C1442.656738,565.777588 1445.006592,565.595703 1447.425781,565.408447 
	C1447.425781,564.162964 1447.425781,562.281982 1447.425781,559.638977 
	C1445.742432,561.031067 1444.666260,561.921021 1444.721558,561.875305 
	C1440.393555,560.886475 1437.099609,560.133911 1433.845825,559.390564 
	C1432.281982,565.130127 1431.536011,565.683105 1426.673584,564.983643 
	C1428.279907,563.538391 1429.617432,562.335022 1430.954956,561.131653 
	C1430.667847,560.678955 1430.380737,560.226318 1430.093628,559.773621 
	C1428.403198,560.856201 1426.712646,561.938721 1424.254761,562.904968 
M1407.674438,549.269165 
	C1406.479126,551.144775 1403.972534,553.367676 1404.358398,554.824402 
	C1405.189453,557.960449 1408.028809,557.313904 1410.440430,555.910217 
	C1410.789551,555.707031 1411.428589,555.526794 1411.680298,555.701233 
	C1416.845093,559.280945 1420.125000,552.302490 1424.817993,553.385071 
	C1424.817993,553.064697 1424.817993,552.744324 1424.817993,553.027405 
	C1422.781982,551.832642 1421.138794,550.598022 1419.297607,549.851440 
	C1416.529297,548.728821 1413.616089,547.103882 1411.798828,551.595215 
	C1411.223633,553.016663 1409.071167,553.799927 1407.352539,555.091736 
	C1407.805786,552.392090 1408.086304,550.720764 1407.674438,549.269165 
M1408.817505,582.785645 
	C1406.926147,583.080078 1405.034668,583.374573 1403.143311,583.669067 
	C1403.217041,583.504700 1403.290771,583.340271 1403.364624,583.175903 
	C1401.066040,582.891907 1398.767456,582.607849 1396.468872,582.323792 
	C1396.393677,582.864197 1396.318481,583.404602 1396.243164,583.945007 
	C1397.490723,584.123535 1398.738281,584.302063 1401.520752,584.700195 
	C1398.611816,586.373596 1396.961060,587.323181 1395.015625,588.442261 
	C1394.523193,586.093140 1394.182007,584.465576 1393.840820,582.838074 
	C1392.846924,586.069458 1392.274414,589.513306 1396.704346,589.863220 
	C1398.644653,590.016479 1400.710938,588.573853 1402.720093,587.855957 
	C1402.539551,587.582336 1402.359131,587.308777 1402.178589,587.035156 
	C1404.030640,587.635193 1405.882690,588.235168 1407.734741,588.835144 
	C1408.281250,586.997375 1408.827637,585.159546 1408.817505,582.785645 
M1374.997437,565.396545 
	C1375.460449,566.920776 1375.923462,568.445068 1376.386475,569.969299 
	C1377.014893,569.792480 1377.643311,569.615723 1378.271729,569.438904 
	C1377.914795,566.409912 1377.557861,563.380920 1377.200928,560.351929 
	C1376.567505,560.397705 1375.934204,560.443542 1375.300781,560.489319 
	C1375.202271,561.844360 1375.103638,563.199341 1374.997437,565.396545 
M1374.926025,549.820557 
	C1375.229980,552.156250 1375.534058,554.492004 1375.838135,556.827698 
	C1376.509399,556.764954 1377.180542,556.702209 1377.851807,556.639465 
	C1377.851807,554.119873 1378.193970,551.536560 1377.741089,549.101196 
	C1377.392700,547.228516 1376.031860,545.875305 1374.926025,549.820557 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1164.238037,548.753540 
	C1165.660645,546.272156 1166.987427,544.112366 1168.447998,541.595459 
	C1170.567871,539.970337 1172.554199,538.702454 1174.462158,537.900513 
	C1173.748291,540.304871 1173.112915,542.243225 1172.477539,544.181641 
	C1174.379028,544.175537 1176.280640,544.184265 1178.181763,544.155029 
	C1178.793457,544.145630 1179.403687,544.035950 1180.181885,544.299805 
	C1184.015381,545.849915 1186.740723,543.455200 1187.256104,540.857971 
	C1187.897583,537.624146 1184.845093,536.910400 1181.925537,537.524658 
	C1183.509766,537.015747 1185.076416,536.142273 1186.681519,536.064148 
	C1192.223145,535.794617 1197.778076,535.802368 1203.660767,535.476501 
	C1206.598022,535.599121 1209.202637,535.949768 1212.323853,536.736084 
	C1212.891235,540.834839 1212.941895,544.497925 1212.615967,548.166016 
	C1211.089844,549.025024 1209.940186,549.879028 1208.790527,550.733032 
	C1208.700317,550.261902 1208.610107,549.790771 1208.519897,549.319580 
	C1205.752686,549.548401 1202.530518,548.947144 1200.305908,550.166138 
	C1194.326050,553.442993 1194.495239,553.751709 1192.078369,552.965332 
	C1193.526611,551.673157 1194.847046,550.495056 1196.327271,549.174438 
	C1193.535645,549.174438 1191.295898,549.064758 1189.071899,549.204895 
	C1186.781738,549.349182 1184.506958,549.737122 1181.755371,550.076477 
	C1179.700562,547.490784 1179.700562,547.490784 1172.976807,549.971619 
	C1173.062866,551.679260 1173.152588,553.459351 1173.264160,555.673157 
	C1175.727417,555.673157 1177.929688,555.739563 1180.126343,555.657959 
	C1183.676636,555.526184 1187.573120,554.285339 1190.684082,555.361267 
	C1194.701416,556.750610 1197.266235,555.450439 1200.435059,553.729980 
	C1201.814087,552.981140 1203.991821,553.703613 1205.806885,553.757874 
	C1208.285889,552.371704 1210.656616,553.249756 1212.998779,555.812927 
	C1213.029785,563.669861 1213.138794,571.082031 1213.045288,578.491699 
	C1213.021973,580.341492 1212.345703,582.182983 1211.683350,583.925903 
	C1209.872925,584.963989 1208.251465,586.007935 1206.925659,587.345093 
	C1206.718018,587.554565 1207.978394,589.932312 1208.347290,589.877502 
	C1209.946533,589.639465 1211.466797,588.871216 1213.025391,588.781616 
	C1212.984741,592.093689 1212.934692,594.919861 1212.427246,597.789062 
	C1208.477661,597.850342 1204.985107,597.881409 1201.492676,597.884155 
	C1194.579346,597.889709 1191.620605,594.450195 1193.313232,587.889282 
	C1195.479614,587.700317 1197.226562,587.531250 1198.973389,587.362183 
	C1198.880249,586.986023 1198.787109,586.609924 1198.693848,586.233765 
	C1196.782227,586.816162 1194.870483,587.398621 1192.604004,588.155762 
	C1190.950439,588.518188 1189.651611,588.705872 1188.059448,588.935913 
	C1188.059448,585.513428 1188.059448,582.776123 1188.342529,579.781982 
	C1188.629517,578.369141 1188.633301,577.213074 1188.639648,575.841370 
	C1188.779053,575.367065 1188.850830,575.091492 1188.817871,574.489258 
	C1188.559204,573.837463 1188.340332,573.495361 1188.397461,572.884033 
	C1192.893066,571.050476 1195.081299,566.738342 1192.645996,561.012146 
	C1190.277710,555.443237 1184.825439,560.331665 1180.454346,558.364014 
	C1180.454346,562.570557 1180.156250,565.880493 1180.621216,569.079590 
	C1180.776978,570.150513 1182.880005,570.938416 1184.367432,572.132202 
	C1185.090942,574.607483 1185.539917,576.806152 1185.932129,579.459106 
	C1186.043579,583.218323 1185.076172,587.233582 1186.609375,589.684875 
	C1190.123413,595.303162 1194.415405,600.726196 1202.443115,600.145447 
	C1205.917236,599.894104 1209.432251,600.209290 1212.956787,600.714844 
	C1213.369995,606.126343 1213.755127,611.090210 1214.068359,616.391724 
	C1210.791992,619.727600 1207.587402,622.725769 1204.382935,625.723938 
	C1204.294312,625.570190 1204.205811,625.416443 1204.117310,625.262756 
	C1202.403198,627.978455 1200.689087,630.694153 1198.587402,633.470215 
	C1196.404419,634.954895 1194.608765,636.379211 1192.516602,637.922058 
	C1192.053955,638.780029 1191.887939,639.519409 1191.612305,640.387512 
	C1191.502686,640.516174 1191.248169,640.738770 1190.800537,640.603638 
	C1187.858521,640.436646 1185.338745,640.188721 1182.882568,640.479248 
	C1181.895752,640.596008 1180.261597,641.905334 1180.271118,642.654480 
	C1180.319214,646.391357 1180.815796,650.122559 1181.126953,654.215942 
	C1179.781616,656.125366 1178.463867,657.674561 1176.660645,659.794556 
	C1173.078613,655.976990 1170.037109,652.690735 1166.945923,649.452026 
	C1162.645630,644.946838 1158.308350,640.477173 1154.327026,635.797729 
	C1156.467896,635.361328 1158.268555,635.119385 1160.372803,635.068542 
	C1163.236938,635.096619 1165.798218,634.946655 1168.357666,634.767212 
	C1171.347046,634.557617 1175.927246,635.442322 1177.021973,633.886047 
	C1180.198364,629.370422 1181.466187,632.435608 1183.336426,634.644348 
	C1183.966187,633.712952 1184.499512,632.924011 1185.321777,632.269653 
	C1186.028809,632.406555 1186.446655,632.408936 1187.183960,632.456787 
	C1188.322266,632.922668 1189.141357,633.343079 1190.655151,634.120178 
	C1190.470703,632.309875 1190.348999,631.114990 1190.248047,629.547363 
	C1188.521606,629.495667 1186.774292,629.816650 1184.723389,629.904724 
	C1180.640137,626.415649 1177.166016,626.556519 1175.995605,630.340210 
	C1175.959106,631.112671 1175.976562,631.556030 1175.607666,632.021423 
	C1173.574707,632.361572 1171.928101,632.679688 1170.281494,632.997803 
	C1170.155151,632.720154 1170.028931,632.442505 1169.902710,632.164856 
	C1171.204956,630.971252 1172.507324,629.777588 1174.055054,628.359070 
	C1171.079590,628.184814 1168.587769,628.038879 1166.001465,627.561768 
	C1165.078979,626.527954 1164.250732,625.825256 1163.420776,625.124756 
	C1167.842041,620.062500 1166.945801,607.578552 1162.953613,605.753479 
	C1163.871582,604.392395 1166.017578,602.551147 1165.684570,601.482239 
	C1163.785645,595.387512 1160.596558,603.331055 1157.999878,600.208984 
	C1157.999878,608.666626 1158.007324,616.301941 1157.996216,623.937317 
	C1157.991821,626.911743 1157.542725,629.456238 1154.015625,628.005798 
	C1154.015625,630.779602 1154.015625,633.397949 1153.667236,636.003662 
	C1151.226196,634.159851 1149.230713,632.200317 1147.019531,630.525574 
	C1143.142578,627.589294 1141.664185,623.947632 1141.945435,619.002319 
	C1142.348145,611.921204 1142.107544,604.796936 1141.969604,597.694458 
	C1141.900269,594.127686 1141.451172,590.568298 1141.173828,587.005615 
	C1142.721558,583.596680 1143.903320,579.956299 1145.885620,576.822205 
	C1151.802856,567.466736 1158.033813,558.309753 1164.238037,548.753540 
M1178.695190,622.209534 
	C1180.504395,622.639709 1182.313721,623.069946 1184.069824,623.487549 
	C1183.959229,621.342773 1184.592163,618.763306 1183.636230,617.854431 
	C1180.732788,615.093445 1177.582153,614.117310 1176.317993,620.257568 
	C1174.723511,620.795654 1173.129028,621.333740 1171.534668,621.871887 
	C1171.384277,621.508179 1171.234009,621.144531 1171.083618,620.780884 
	C1172.426025,619.622192 1173.768433,618.463562 1175.110840,617.304871 
	C1174.720337,616.794617 1174.329834,616.284302 1173.939331,615.774048 
	C1172.259766,617.105957 1170.286255,618.217712 1169.043091,619.876587 
	C1168.536499,620.552673 1169.453125,623.461365 1170.000610,623.545654 
	C1171.732788,623.812256 1173.610596,623.295593 1175.403320,622.937439 
	C1176.307129,622.756775 1177.148682,622.264404 1178.695190,622.209534 
M1164.842896,592.864624 
	C1164.842896,588.600159 1164.842896,584.335693 1164.842896,579.385925 
	C1164.766724,579.354858 1163.711670,578.924500 1162.418823,578.397095 
	C1163.784668,577.337524 1164.701294,576.626404 1166.804199,574.994995 
	C1164.217163,575.368164 1163.130371,575.491455 1162.057129,575.687683 
	C1160.516479,575.969421 1158.985596,576.304321 1157.450562,576.616577 
	C1158.324585,578.372925 1159.198608,580.129211 1160.274170,582.290405 
	C1160.229004,582.323120 1159.214722,583.056213 1158.200439,583.789307 
	C1158.580566,583.914124 1158.960571,584.038879 1159.340698,584.163635 
	C1158.846191,587.553345 1158.351807,590.943054 1157.796997,594.746704 
	C1160.598633,594.200256 1162.525879,593.824280 1164.842896,592.864624 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1444.000854,585.150024 
	C1447.377808,580.979736 1450.338379,576.272034 1456.926636,575.466614 
	C1456.926636,562.387939 1456.926636,549.218689 1456.886108,535.596069 
	C1457.220825,534.395935 1457.595947,533.649170 1458.301758,532.494629 
	C1459.108643,532.115906 1459.584717,532.144897 1460.060791,532.173828 
	C1460.507690,533.094177 1460.954712,534.014526 1461.671387,535.453430 
	C1461.960693,548.314636 1461.980347,560.657349 1461.715088,573.221680 
	C1459.407593,575.400146 1458.877197,577.138367 1462.076416,578.759766 
	C1462.344971,583.530762 1462.681763,587.862183 1462.830566,592.418823 
	C1458.926514,595.318481 1463.984253,594.549438 1463.460449,595.905762 
	C1461.009521,595.975037 1458.981445,595.943665 1457.005615,595.452637 
	C1457.066895,590.692993 1457.170654,586.389832 1457.028564,582.094849 
	C1456.984741,580.769226 1456.270630,579.465759 1455.863525,578.152222 
	C1454.436523,578.746765 1452.788330,579.065186 1451.629883,579.994385 
	C1449.361694,581.813721 1447.366943,583.973633 1444.981445,585.967590 
	C1444.469360,585.678406 1444.235107,585.414246 1444.000854,585.150024 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1212.884521,597.745972 
	C1212.934692,594.919861 1212.984741,592.093689 1213.108398,588.544922 
	C1212.777710,586.557617 1212.373657,585.292908 1211.969482,584.028259 
	C1212.345703,582.182983 1213.021973,580.341492 1213.045288,578.491699 
	C1213.138794,571.082031 1213.029785,563.669861 1212.985596,555.346985 
	C1212.981323,552.343872 1212.986938,550.252441 1212.992676,548.160950 
	C1212.941895,544.497925 1212.891235,540.834839 1212.721680,536.751831 
	C1214.172852,536.532593 1215.742920,536.733398 1217.708862,537.003235 
	C1218.075806,556.954773 1218.046753,576.837158 1217.636108,597.216003 
	C1215.797729,597.723572 1214.341187,597.734802 1212.884521,597.745972 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1460.514404,532.129028 
	C1459.584717,532.144897 1459.108643,532.115906 1458.280762,532.160278 
	C1453.735229,532.281799 1449.539673,532.411743 1445.349243,532.318848 
	C1444.336060,532.296509 1443.338989,531.538025 1442.334595,531.118042 
	C1443.299683,530.459778 1444.234009,529.293213 1445.234985,529.232788 
	C1449.173340,528.995117 1453.132568,529.105469 1457.551514,529.060913 
	C1462.057495,529.045715 1466.095947,529.056274 1470.558594,529.050537 
	C1472.953125,531.480469 1471.504028,532.047852 1469.266357,532.081116 
	C1466.500732,532.122192 1463.734131,532.086731 1460.514404,532.129028 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1375.005371,529.852844 
	C1380.765015,529.847717 1386.026978,529.847717 1391.611694,529.847717 
	C1389.737915,533.242249 1366.655762,534.191772 1359.842529,531.058167 
	C1365.379761,529.049011 1370.052612,530.237427 1375.005371,529.852844 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1301.544434,530.051270 
	C1302.458496,530.028992 1302.902954,530.031372 1303.680054,530.003723 
	C1306.298096,530.181213 1308.583618,530.388733 1310.869263,530.596252 
	C1310.819458,531.246033 1310.769653,531.895874 1310.719849,532.545654 
	C1300.881592,532.545654 1291.043213,532.545654 1281.204956,532.545654 
	C1281.169800,531.845642 1281.134766,531.145569 1281.099609,530.445557 
	C1287.710205,529.744446 1294.419067,530.230713 1301.544434,530.051270 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1416.818726,532.922485 
	C1416.756104,533.236572 1416.592407,533.195435 1416.037842,533.107788 
	C1409.984741,533.350769 1404.486206,533.681519 1398.987671,534.012207 
	C1398.987671,534.012268 1398.506958,534.056946 1398.266357,534.042847 
	C1396.687256,532.844849 1395.348877,531.660828 1394.010498,530.476868 
	C1394.170654,530.131714 1394.330811,529.786621 1394.490967,529.441467 
	C1401.845215,529.441467 1409.199463,529.441467 1416.526367,529.441467 
	C1416.613525,530.218628 1416.747437,531.413513 1416.818726,532.922485 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1257.910156,534.825684 
	C1257.590698,533.468079 1257.271118,532.110474 1256.776855,530.010254 
	C1260.941284,530.010254 1264.373291,530.010315 1267.805176,530.010315 
	C1271.260742,530.010315 1274.716309,530.010254 1278.911377,530.010254 
	C1276.025635,536.845520 1270.584229,532.678284 1266.253784,533.738464 
	C1265.091675,533.671997 1264.528931,533.845276 1263.966064,534.018555 
	C1263.705200,534.096436 1263.485107,534.237671 1262.772095,534.673828 
	C1260.795776,534.878723 1259.352905,534.852234 1257.910156,534.825684 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1246.207764,534.760620 
	C1245.937012,535.063232 1245.480469,535.083679 1245.253906,535.056152 
	C1241.967773,534.688843 1238.881836,534.489868 1235.863037,533.929504 
	C1234.867188,533.744690 1234.044678,532.626526 1233.142822,531.935059 
	C1233.929932,531.298218 1234.703979,530.128418 1235.506104,530.108948 
	C1241.791748,529.956421 1248.082886,530.024475 1254.633057,530.024475 
	C1253.967407,536.328003 1248.924194,532.784668 1246.207764,534.760620 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1438.055054,533.642822 
	C1433.331909,533.979248 1428.636841,534.020203 1423.477539,534.038513 
	C1422.026733,532.786011 1421.040405,531.556152 1418.987183,528.996094 
	C1425.942993,528.996094 1431.553101,528.911682 1437.145996,529.182922 
	C1437.524780,529.201355 1437.773804,531.896912 1438.055054,533.642822 
M1425.156860,530.554077 
	C1424.431030,530.452515 1423.705200,530.350891 1422.979492,530.249329 
	C1422.969116,530.464722 1422.958740,530.680054 1422.948364,530.895386 
	C1423.550171,530.895386 1424.151855,530.895386 1425.156860,530.554077 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1210.097290,530.677979 
	C1216.604858,530.626831 1222.829956,530.816223 1229.522217,531.011597 
	C1229.094360,532.003174 1228.244995,533.786987 1227.296875,533.841125 
	C1222.206665,534.131348 1217.092041,533.998840 1211.985840,533.995789 
	C1209.837891,533.994568 1208.157715,533.623108 1210.097290,530.677979 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1257.488525,534.880981 
	C1259.352905,534.852234 1260.795776,534.878723 1262.669678,534.843506 
	C1263.539307,534.850708 1263.977905,534.919617 1264.707520,535.385742 
	C1264.969727,536.231750 1264.940674,536.680481 1264.485840,537.055786 
	C1261.193359,536.808411 1258.240112,536.042175 1257.472168,540.465088 
	C1257.320557,541.338257 1254.236694,541.702271 1252.505005,542.301086 
	C1252.323364,541.824707 1252.141724,541.348328 1251.960083,540.871948 
	C1253.247070,539.886841 1254.534180,538.901733 1256.421387,537.457214 
	C1254.819824,537.287170 1253.938721,537.193604 1253.057617,537.100037 
	C1252.785278,536.620789 1252.512939,536.141479 1252.086792,535.433350 
	C1253.644287,535.115051 1255.355469,535.025696 1257.488525,534.880981 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1264.911621,537.129150 
	C1264.940674,536.680481 1264.969727,536.231750 1265.035034,535.449158 
	C1267.050415,535.145142 1269.029541,535.175049 1271.487915,535.575134 
	C1271.598022,537.331116 1271.228760,538.716919 1270.485596,540.020142 
	C1268.378296,539.001465 1266.645020,538.065308 1264.911621,537.129150 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1315.036865,532.969604 
	C1314.808228,532.637756 1314.957886,532.052917 1315.111450,532.051880 
	C1321.691650,532.006287 1328.272095,532.012695 1334.863647,532.988892 
	C1328.381470,532.988892 1321.899292,532.988892 1315.036865,532.969604 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1316.621094,529.947632 
	C1318.373535,529.946106 1319.794556,529.962891 1321.641113,529.933960 
	C1326.096924,529.888184 1330.126953,529.888184 1334.156860,529.888184 
	C1334.164917,530.234863 1334.172974,530.581543 1334.181030,530.928223 
	C1327.820068,530.928223 1321.459106,530.928650 1315.098267,530.908447 
	C1315.081421,530.908386 1315.008301,530.490112 1315.058350,530.465698 
	C1315.455444,530.271912 1315.876099,530.126343 1316.621094,529.947632 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1270.859497,540.102661 
	C1271.228760,538.716919 1271.598022,537.331116 1271.911377,535.606873 
	C1272.372437,535.224976 1272.889648,535.181580 1273.789062,535.226379 
	C1273.848877,537.714844 1277.777222,541.725769 1271.276978,541.616638 
	C1270.879639,540.901611 1270.869629,540.502136 1270.859497,540.102661 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1264.292725,534.009888 
	C1264.528931,533.845276 1265.091675,533.671997 1265.788086,533.721558 
	C1265.487671,533.963440 1265.053589,533.982361 1264.292725,534.009888 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2295.257812,1034.298340 
	C2288.004639,1034.417358 2280.906250,1034.419922 2273.462891,1034.458008 
	C2271.605225,1034.446899 2270.092529,1034.400146 2268.302490,1034.609131 
	C2267.858887,1034.864868 2267.683838,1034.898804 2267.526855,1034.859863 
	C2264.734375,1034.166138 2260.652588,1034.319946 2259.477783,1032.547729 
	C2257.980225,1030.289062 2258.652344,1026.468628 2258.706055,1023.326721 
	C2258.901855,1011.895264 2257.359375,1013.964417 2268.215576,1013.856445 
	C2286.695312,1013.672607 2305.176758,1013.639587 2323.657471,1013.593262 
	C2326.521973,1013.586060 2330.233398,1012.339355 2329.825684,1018.293091 
	C2331.867432,1012.612915 2333.349854,1012.244629 2344.238281,1014.237305 
	C2344.238281,1019.684570 2344.381836,1025.300781 2344.082031,1030.893188 
	C2344.037598,1031.724365 2341.927979,1033.068726 2340.734619,1033.112305 
	C2333.428711,1033.379639 2326.111084,1033.313477 2318.798584,1033.424194 
	C2317.157227,1033.448975 2315.519775,1033.735718 2313.495605,1034.034668 
	C2308.327393,1034.133179 2303.543945,1034.099121 2298.017578,1034.147217 
	C2296.907959,1034.238770 2296.541260,1034.248291 2296.174561,1034.257690 
	C2296.174561,1034.257690 2295.801514,1034.138306 2295.801514,1034.138306 
	C2295.801514,1034.138306 2295.412354,1034.181763 2295.257812,1034.298340 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2264.906006,1038.491333 
	C2261.790771,1038.927246 2258.726562,1038.963745 2255.181152,1038.993896 
	C2254.730957,1038.811890 2254.761719,1038.636353 2255.082520,1038.149780 
	C2255.455811,1037.715576 2255.539062,1037.592651 2255.622314,1037.469727 
	C2255.395752,1037.329468 2255.169434,1037.189209 2254.942871,1037.048950 
	C2254.322510,1033.949829 2253.420166,1030.874756 2253.154053,1027.745605 
	C2252.832764,1023.965942 2252.945312,1020.135681 2253.118652,1016.338684 
	C2253.156006,1015.522827 2254.256348,1014.755493 2254.866699,1013.965820 
	C2255.508789,1014.715759 2256.619141,1015.415100 2256.706787,1016.225098 
	C2256.990479,1018.847595 2256.927002,1021.510559 2256.945312,1024.158325 
	C2256.971680,1027.944702 2256.952148,1031.731323 2256.952148,1035.185669 
	C2259.408691,1036.077515 2262.182861,1037.084717 2264.906006,1038.491333 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2346.673340,1020.137451 
	C2346.711182,1017.582947 2346.711182,1015.506165 2346.711182,1013.058044 
	C2350.335693,1014.986389 2350.367432,1014.987183 2350.016846,1029.140381 
	C2349.975098,1030.814087 2349.351074,1032.473267 2348.996826,1034.139038 
	C2348.209717,1034.025146 2347.422363,1033.911133 2346.635254,1033.797119 
	C2346.635254,1029.403076 2346.635254,1025.009155 2346.673340,1020.137451 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2319.219727,1007.030640 
	C2318.090088,998.239685 2317.153809,989.465698 2316.217773,980.691772 
	C2316.822266,980.628113 2317.427002,980.564453 2318.031494,980.500793 
	C2318.652832,989.057556 2319.273926,997.614380 2319.848145,1006.623413 
	C2319.801025,1007.075623 2319.413330,1007.047607 2319.219727,1007.030640 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2254.932617,1037.273193 
	C2255.169434,1037.189209 2255.395752,1037.329468 2255.622314,1037.469727 
	C2255.539062,1037.592651 2255.455811,1037.715576 2255.154785,1037.892456 
	C2254.936768,1037.946289 2254.922363,1037.497437 2254.932617,1037.273193 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M2507.881592,541.502441 
	C2520.817383,535.814880 2537.539551,545.419739 2535.960938,560.296936 
	C2535.211426,567.362793 2528.093994,575.456116 2520.765625,576.890259 
	C2510.572998,578.884827 2502.756836,575.099243 2498.627441,566.168396 
	C2495.003906,558.331421 2497.085938,549.676514 2503.913818,544.302612 
	C2505.082031,543.383057 2506.323242,542.555969 2507.881592,541.502441 
M2510.037598,559.642578 
	C2509.861572,554.595520 2515.295410,558.552612 2516.800293,555.002197 
	C2514.540771,554.465027 2512.461914,553.970764 2510.457764,553.494263 
	C2509.443359,546.737549 2509.753906,546.500000 2515.938721,546.714478 
	C2518.132080,546.790466 2520.354492,546.030334 2522.563477,545.651917 
	C2522.495117,545.214600 2522.426758,544.777283 2522.358398,544.339966 
	C2521.278076,544.213013 2520.197754,543.975098 2519.117432,543.975952 
	C2505.958496,543.986938 2508.307861,543.103210 2508.136719,554.641541 
	C2508.083984,558.215332 2508.128662,561.790527 2508.128662,565.365112 
	C2508.677002,565.352539 2509.225586,565.339966 2509.773926,565.327393 
	C2509.858154,563.719482 2509.942139,562.111572 2510.037598,559.642578 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M2500.992188,503.007507 
	C2506.367432,501.637543 2511.705811,499.355652 2517.143311,499.087036 
	C2526.100586,498.644562 2534.212402,506.812775 2534.962646,515.685364 
	C2535.788574,525.454285 2529.206299,534.399902 2519.905518,536.128357 
	C2511.812988,537.632141 2504.437744,536.050537 2499.697998,529.050293 
	C2494.906738,521.973694 2494.795654,514.239624 2499.592529,506.782043 
	C2500.203857,505.831787 2500.611572,504.750458 2501.063965,503.356964 
	C2501.014160,502.984039 2501.000000,503.000031 2500.992188,503.007507 
M2519.185547,525.899353 
	C2526.038818,525.010559 2522.416748,519.723633 2522.823975,516.452393 
	C2523.281006,512.780518 2521.783447,512.333923 2519.309570,513.927490 
	C2520.084961,517.301331 2520.766846,520.268677 2521.472656,523.339844 
	C2510.922119,524.765503 2509.074951,523.278870 2509.020020,514.402466 
	C2508.968994,506.145752 2509.142822,506.022980 2517.284180,505.832397 
	C2519.088135,505.790192 2520.879639,505.207581 2522.676758,504.873810 
	C2522.576904,504.301453 2522.476807,503.729126 2522.376953,503.156769 
	C2519.123779,503.156769 2515.710449,502.522430 2512.651611,503.293152 
	C2506.876465,504.748016 2507.199707,509.631287 2507.068604,514.477905 
	C2506.815430,523.831665 2508.921875,526.912048 2519.185547,525.899353 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2456.406494,1038.586670 
	C2455.820557,1038.178711 2455.425537,1037.448242 2454.916016,1036.421631 
	C2454.801758,1028.351074 2454.801758,1020.576477 2454.801758,1012.085449 
	C2459.589111,1012.085449 2463.685059,1012.104126 2467.781006,1012.081726 
	C2477.260010,1012.029785 2486.742920,1012.086975 2496.216309,1011.826660 
	C2499.686768,1011.731384 2501.199707,1012.948425 2502.893066,1016.091736 
	C2504.781738,1019.597595 2508.411621,1022.165649 2511.641602,1025.071045 
	C2512.000000,1025.000000 2511.993408,1024.992798 2511.993408,1024.992798 
	C2513.994385,1026.583496 2515.995361,1028.174194 2517.516113,1029.382935 
	C2514.726562,1031.178223 2511.500977,1033.254028 2508.103516,1035.619141 
	C2505.881836,1039.322388 2503.476562,1042.581543 2501.902588,1046.202148 
	C2500.785645,1048.770996 2499.905273,1050.355469 2496.731689,1050.032593 
	C2493.477783,1049.701538 2490.164307,1049.959229 2486.146240,1049.959229 
	C2486.146240,1047.648804 2486.145508,1045.725220 2486.146240,1043.801514 
	C2486.149170,1036.923340 2486.149414,1036.915527 2479.049561,1036.936401 
	C2473.080811,1036.953857 2467.109863,1036.911499 2461.144775,1037.077271 
	C2459.619141,1037.119629 2458.112793,1037.849854 2456.406494,1038.586670 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2512.004395,1025.003418 
	C2510.218262,1023.072815 2507.941650,1021.391052 2506.772217,1019.135315 
	C2505.561035,1016.799438 2505.403809,1013.911133 2504.839844,1011.252930 
	C2504.764404,1010.897034 2505.199951,1010.212036 2505.074707,1010.119690 
	C2500.608643,1006.831848 2503.091309,1002.498108 2504.958740,1000.046265 
	C2508.149658,995.856689 2512.249023,991.232056 2516.954590,989.543762 
	C2528.955078,985.238220 2541.729980,995.588684 2542.190186,1008.178345 
	C2542.608887,1019.632690 2530.004639,1029.439819 2518.038574,1026.501465 
	C2516.273193,1026.067993 2514.541016,1025.499878 2512.393555,1024.993408 
	C2511.993408,1024.992798 2512.000000,1025.000000 2512.004395,1025.003418 
M2528.723877,1001.999451 
	C2530.289307,998.392151 2528.909912,994.613037 2525.385742,994.102295 
	C2522.138184,993.631653 2518.769043,994.002136 2515.943359,994.002136 
	C2516.790039,996.636902 2517.744629,998.416504 2517.873535,1000.254272 
	C2518.152344,1004.221802 2517.920654,1008.222900 2518.088623,1012.202148 
	C2518.138672,1013.388855 2518.845215,1015.567932 2519.211182,1015.559448 
	C2522.068604,1015.493469 2525.217041,1015.644165 2527.689941,1014.482117 
	C2530.647705,1013.092224 2530.483643,1009.999329 2529.348389,1007.069519 
	C2528.719238,1005.445374 2528.432617,1003.688477 2528.000000,1002.000000 
	C2528.000000,1002.000000 2527.991211,1001.991028 2528.723877,1001.999451 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2151.442383,1215.843506 
	C2149.575684,1216.012329 2148.117676,1216.119751 2146.435059,1216.243896 
	C2146.119629,1218.520874 2145.834229,1220.580200 2145.549072,1222.639648 
	C2144.977783,1222.706665 2144.406738,1222.773560 2143.835449,1222.840454 
	C2140.174805,1218.339722 2142.982422,1212.705688 2141.985107,1206.883423 
	C2145.030273,1206.883423 2147.565186,1206.439697 2149.844238,1207.013184 
	C2151.954834,1207.544067 2153.791260,1209.162964 2155.748535,1210.302734 
	C2154.449463,1212.129272 2153.150391,1213.955688 2151.442383,1215.843506 
M2150.053467,1213.889771 
	C2150.616455,1212.531372 2151.179443,1211.172852 2151.742432,1209.814331 
	C2150.388672,1209.334106 2149.004395,1208.449585 2147.694336,1208.548828 
	C2147.064941,1208.596558 2145.881592,1210.804199 2146.182861,1211.496460 
	C2146.660889,1212.594604 2148.174072,1213.242310 2150.053467,1213.889771 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2187.858643,1208.146973 
	C2189.642334,1212.784546 2191.319336,1217.051758 2193.580078,1222.804321 
	C2187.629395,1220.022461 2182.654541,1217.778076 2177.832031,1223.357178 
	C2177.603271,1222.747681 2177.374268,1222.138062 2177.145508,1221.528564 
	C2178.902344,1217.045898 2180.504883,1212.494263 2182.469727,1208.104736 
	C2183.810791,1205.108276 2185.831787,1205.391846 2187.858643,1208.146973 
M2184.645020,1209.472046 
	C2184.135254,1210.832397 2183.411377,1212.157227 2183.179199,1213.563477 
	C2182.970947,1214.824097 2183.304688,1216.174194 2183.399414,1217.484741 
	C2184.841553,1217.355347 2186.283936,1217.226074 2188.208740,1217.053345 
	C2187.056641,1213.978027 2186.205566,1211.707031 2184.645020,1209.472046 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2161.282227,1221.373047 
	C2161.242432,1216.044434 2160.245605,1210.995850 2161.554932,1206.234619 
	C2162.260010,1206.266235 2162.965088,1206.297852 2163.670410,1206.329590 
	C2163.806396,1208.836182 2164.240723,1211.370117 2164.014160,1213.843506 
	C2163.596680,1218.397461 2164.825684,1220.938477 2169.960205,1220.084473 
	C2170.798584,1219.945190 2171.726074,1220.341187 2172.608643,1221.058350 
	C2169.022217,1223.419800 2165.313965,1223.607788 2161.282227,1221.373047 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M493.073547,936.195496 
	C476.666779,936.195496 460.260010,936.195496 443.057098,936.195496 
	C443.057098,933.461609 443.057098,931.222595 443.559784,928.510559 
	C476.284210,927.703613 508.505585,927.322754 540.727844,927.053772 
	C559.515137,926.896912 578.303955,926.919312 597.553345,926.885010 
	C603.621643,926.686218 609.227722,926.383118 614.836670,926.313049 
	C615.872742,926.300171 617.566528,927.032227 617.792603,927.774841 
	C618.050720,928.622742 617.120422,929.938354 616.502075,930.922119 
	C615.609131,932.342896 614.532104,933.647949 613.533691,935.002441 
	C613.533691,935.002441 613.359497,934.958862 612.794922,934.977783 
	C609.101257,934.982117 605.972290,934.967468 602.843262,934.952820 
	C602.843262,934.952820 602.484680,934.949890 601.888306,934.973389 
	C594.152222,935.119812 587.012512,935.242676 579.416016,935.315674 
	C559.656372,935.552490 540.353577,935.839050 520.775635,936.079651 
	C520.317444,936.063721 520.134216,936.093750 519.488281,936.068359 
	C510.374817,936.073792 501.724182,936.134644 493.073547,936.195496 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M613.767639,935.052429 
	C614.532104,933.647949 615.609131,932.342896 616.502075,930.922119 
	C617.120422,929.938354 618.050720,928.622742 617.792603,927.774841 
	C617.566528,927.032227 615.872742,926.300171 614.836670,926.313049 
	C609.227722,926.383118 603.621643,926.686218 597.525635,926.575928 
	C597.023132,925.826416 597.009583,925.409973 597.464478,924.972046 
	C604.492615,924.950562 611.052307,924.950562 617.476807,924.950562 
	C618.810059,915.513672 618.785217,915.567078 611.082642,911.980713 
	C605.431396,909.349487 602.015930,904.843384 601.124146,898.693604 
	C600.928406,897.343140 601.657227,894.844727 602.544678,894.531616 
	C605.876953,893.355957 609.512146,893.068359 612.912537,892.045105 
	C615.059570,891.399048 616.991272,890.037720 619.373901,889.014282 
	C620.804810,888.686340 621.881409,888.341431 622.958008,887.996521 
	C623.363037,890.452759 623.768066,892.909058 624.295166,896.105713 
	C616.706177,896.570923 610.049377,896.978943 603.401978,897.386414 
	C604.117249,905.523010 609.987183,911.014832 617.547974,911.136292 
	C619.563293,911.168701 621.567932,911.874573 623.342407,912.635864 
	C620.258667,912.710510 619.910278,914.411865 619.947021,916.706909 
	C620.039612,922.496216 619.991882,928.287842 620.000488,934.078491 
	C618.451050,934.397217 616.901611,934.715942 614.914490,935.096802 
	C614.476807,935.158997 614.001648,935.102478 613.767639,935.052429 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M521.050720,936.125671 
	C540.353577,935.839050 559.656372,935.552490 579.435547,935.563965 
	C579.919922,936.196106 579.927979,936.530151 579.936035,936.864136 
	C570.120911,936.933167 560.303833,936.903992 550.491394,937.103455 
	C543.047180,937.254700 535.610840,937.780701 528.166687,937.956909 
	C526.063843,938.006775 523.945618,937.412537 521.430908,936.836548 
	C521.027405,936.559326 521.050720,936.125671 521.050720,936.125671 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M493.070984,936.427002 
	C501.724182,936.134644 510.374817,936.073792 519.506958,936.299194 
	C519.988403,936.585510 520.029114,937.048279 520.029114,937.048279 
	C511.366180,937.277527 502.703217,937.506714 493.588928,937.656006 
	C493.108521,937.270752 493.085449,936.964844 493.070984,936.427002 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M580.406616,936.927856 
	C579.927979,936.530151 579.919922,936.196106 579.892334,935.613770 
	C587.012512,935.242676 594.152222,935.119812 601.709106,934.979370 
	C600.373901,935.647644 598.645874,936.839050 596.864807,936.924438 
	C591.546753,937.179443 586.207886,937.000854 580.406616,936.927856 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M602.956909,935.306396 
	C605.972290,934.967468 609.101257,934.982117 612.705200,934.973022 
	C609.989075,937.729309 606.591064,937.565552 602.956909,935.306396 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M520.274658,937.066772 
	C520.029114,937.048279 519.988403,936.585510 519.969727,936.354675 
	C520.134216,936.093750 520.317444,936.063721 520.775635,936.079651 
	C521.050720,936.125671 521.027405,936.559326 521.015686,936.776062 
	C521.003967,936.992798 520.520203,937.085327 520.274658,937.066772 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M391.785950,935.585938 
	C391.352936,935.989685 391.065308,936.050171 390.546204,935.961426 
	C389.328552,935.858337 388.140350,935.451782 387.351776,935.845581 
	C382.695129,938.170776 381.124176,937.347046 381.056213,932.131226 
	C380.924255,922.010071 380.921997,911.887207 380.764923,901.766663 
	C380.746613,900.587341 380.090057,899.417969 379.051941,898.135620 
	C377.874817,898.058777 377.375092,898.090210 376.472778,898.051025 
	C367.226837,897.980286 358.383514,897.980286 348.748322,897.980286 
	C350.030151,892.907227 351.026215,888.965210 352.368011,885.023926 
	C358.759766,880.667297 364.805756,876.309937 369.957916,872.596741 
	C364.612457,872.170898 354.384277,878.630188 351.652100,885.134644 
	C345.909973,887.270874 348.703094,892.457764 347.273560,896.006409 
	C346.235474,898.583374 347.645142,900.265137 350.858246,900.177490 
	C357.456177,899.997375 364.064392,899.967346 370.661102,900.145447 
	C372.125946,900.184998 373.560638,901.341736 374.994385,902.436157 
	C374.949860,906.150024 374.920227,909.411743 374.436523,912.760742 
	C353.606842,913.206970 333.231720,913.606873 312.854889,913.879395 
	C309.899933,913.918884 306.936829,913.347717 303.984375,912.586914 
	C303.668976,905.134094 303.346649,898.153320 303.055908,890.687134 
	C303.060089,880.463684 303.032654,870.725708 303.007263,860.743896 
	C303.009369,860.500000 303.006775,860.012207 303.005310,859.542236 
	C302.824005,852.444519 302.644196,845.816772 302.415894,838.770142 
	C302.560303,836.214417 302.907990,834.078247 302.918579,831.940369 
	C302.958893,823.807556 303.054718,815.668823 302.788879,807.543457 
	C302.666992,803.818542 303.878662,802.545105 307.602509,802.715759 
	C311.384399,802.889099 315.213684,802.573181 318.991211,802.176392 
	C325.623901,801.479614 327.121033,799.681091 327.129242,792.970886 
	C327.137665,786.079285 327.131104,779.187683 327.131104,771.473450 
	C331.270416,772.674438 334.557831,773.628296 337.948639,774.874023 
	C347.640381,781.395569 351.464478,790.619385 351.103546,802.080627 
	C356.113220,802.080627 360.422333,802.057190 364.731110,802.085266 
	C375.030151,802.152344 373.817352,800.928223 373.997955,811.030823 
	C374.024078,812.494019 374.184387,813.954895 374.282623,815.416809 
	C374.510529,815.387329 374.738403,815.357849 374.966309,815.328369 
	C374.966309,808.910400 374.966309,802.492432 374.966309,796.006287 
	C368.778595,796.006287 363.436249,795.764038 358.128632,796.089294 
	C354.438080,796.315491 352.724335,795.018921 352.003937,791.447876 
	C350.319824,783.099487 345.318665,777.154846 337.610107,772.980042 
	C334.614807,771.963318 332.025513,770.853333 329.330688,770.144958 
	C325.211243,769.062195 323.663605,770.751770 323.928772,775.052307 
	C324.286652,780.856323 324.200348,786.696106 324.067749,792.515198 
	C324.040253,793.722046 323.016449,795.917480 322.382751,795.942993 
	C316.002106,796.200012 309.607056,796.099426 303.164673,796.099426 
	C303.164673,788.117065 303.164673,780.153870 303.443909,771.984253 
	C306.344330,769.239990 304.537018,768.315491 302.182495,767.221191 
	C302.097473,765.886536 302.025848,764.916260 302.103333,763.622131 
	C305.803711,763.143738 310.818665,764.390991 312.594879,762.536438 
	C316.057190,758.921204 317.381592,760.741089 318.711273,762.271057 
	C321.537018,762.271057 323.610474,762.271057 325.892090,762.271057 
	C325.967468,761.015808 326.055878,759.543823 326.358459,757.760742 
	C326.832581,755.635254 327.092499,753.820984 327.498566,750.986633 
	C327.902985,753.856873 328.159302,755.676086 328.471405,757.891113 
	C335.899872,749.883423 340.983948,748.692017 345.427460,752.915344 
	C347.109772,752.452820 348.403259,751.983765 349.737946,751.756470 
	C351.811066,751.403503 353.912201,751.215210 356.205383,751.195312 
	C355.842682,756.972961 356.752045,757.876953 362.559235,757.162109 
	C366.019501,756.736084 369.297821,757.261536 373.557739,758.881287 
	C373.557739,770.005005 373.557739,781.553894 373.557739,793.102783 
	C374.041046,793.091064 374.524353,793.079346 375.007660,793.067627 
	C375.007660,791.439636 374.996399,789.811523 375.009399,788.183533 
	C375.110382,775.538513 375.216125,762.893433 375.700592,750.250061 
	C376.659607,751.546570 377.694916,752.826172 377.738678,754.138977 
	C377.936768,760.076843 377.762787,766.026611 377.924286,771.966553 
	C377.979156,773.983704 378.625824,775.984741 378.875366,778.312866 
	C378.482391,796.588196 378.216034,814.543762 377.612305,832.612122 
	C376.830231,832.881348 376.385529,833.037781 376.107239,833.098633 
	C376.273712,833.002991 376.171539,832.632935 376.103455,832.200745 
	C376.035370,827.912598 376.035370,824.056702 376.035370,820.200745 
	C375.430023,820.198303 374.824707,820.195801 374.219360,820.193359 
	C374.219360,824.537964 374.219360,828.882568 374.112823,833.668518 
	C374.289368,839.138977 374.572449,844.168152 374.901459,849.651855 
	C374.947388,857.519531 374.947388,864.932678 374.947388,872.268494 
	C378.202606,873.331421 379.693085,872.723389 380.178864,869.497559 
	C380.425262,867.861328 381.742920,865.251953 382.867188,865.088928 
	C386.681335,864.535950 390.625244,864.877869 394.999969,864.877869 
	C394.999969,868.762085 394.999969,871.881042 394.772919,875.265991 
	C392.826599,877.022949 391.107330,878.513977 389.388062,880.005005 
	C388.619629,878.615906 387.851227,877.226807 386.597107,874.959656 
	C386.442230,877.842041 386.327881,879.970459 386.140137,883.464661 
	C393.400208,880.427307 401.178284,884.701355 407.240051,878.639221 
	C407.957581,879.565674 408.826752,880.687927 409.897552,882.070496 
	C411.265320,880.513000 412.395966,879.225586 414.310394,877.045654 
	C413.780884,882.714417 416.441772,882.881348 420.212860,881.806519 
	C421.721588,881.195251 422.879791,880.549927 424.387909,880.053955 
	C427.868530,880.564453 430.999207,880.925598 434.492615,881.315552 
	C437.501068,881.143982 440.146851,880.943665 443.257019,880.866577 
	C447.988525,880.989807 452.255676,880.989807 456.826019,880.989807 
	C456.487610,883.835632 456.239105,885.925232 455.995239,888.007568 
	C455.999878,888.000305 456.012146,887.988953 455.667664,887.982178 
	C453.557678,889.653992 451.792114,891.332581 449.964966,893.262573 
	C449.929871,893.684570 449.956360,893.855103 449.993011,894.013184 
	C450.003204,894.000671 450.028961,893.981140 450.028961,893.981140 
	C447.042419,894.570068 444.055878,895.158997 441.069336,895.747925 
	C440.917633,896.193848 440.765930,896.639832 440.614227,897.085754 
	C448.099335,899.149597 455.461304,901.349426 457.151154,911.151245 
	C452.776611,911.151245 448.862183,911.103027 444.949371,911.162170 
	C438.611359,911.257874 437.099396,912.813782 437.102234,919.063416 
	C437.104340,923.728638 437.176849,928.395935 437.043335,933.057312 
	C437.015503,934.029663 436.220795,934.980042 435.779480,935.940613 
	C434.942780,935.523621 433.737366,935.330444 433.327026,934.654541 
	C429.647705,928.594055 426.108246,922.448608 422.136414,915.659485 
	C424.726929,915.224487 426.433319,914.937927 428.106720,914.656982 
	C428.106720,913.373901 428.106720,912.085999 428.321106,910.392578 
	C428.505859,909.775452 428.476227,909.563965 428.446594,909.352417 
	C428.271667,909.538452 428.096741,909.724487 427.921814,909.910461 
	C426.878998,909.604614 425.836212,909.298706 424.793427,908.992859 
	C427.343567,907.773010 431.573364,908.399414 429.209106,902.869812 
	C429.080109,900.370178 429.254791,898.464783 429.429474,896.559387 
	C428.958679,896.507019 428.487885,896.454651 428.017090,896.402222 
	C427.289490,897.653076 426.561890,898.903870 425.414185,900.876953 
	C424.366699,898.799561 423.715759,897.508667 423.064819,896.217712 
	C422.550690,896.350403 422.036560,896.483093 421.522430,896.615845 
	C422.014008,898.753357 422.505615,900.890869 422.997223,903.028381 
	C422.622070,904.218689 422.181274,905.393127 421.887482,906.603210 
	C421.472229,908.313538 421.165558,910.050293 420.542969,911.647583 
	C419.180817,909.958252 418.088196,908.397095 417.077454,906.953003 
	C414.144623,908.752869 411.717163,910.242676 409.289703,911.732422 
	C409.350464,912.019836 409.411224,912.307251 409.471985,912.594666 
	C411.666656,912.048401 413.861298,911.502136 416.209900,910.917542 
	C416.931976,911.559570 417.883484,912.405579 418.834991,913.251587 
	C422.542297,919.864990 426.249603,926.478333 430.530060,934.114136 
	C423.535065,934.114136 417.479065,933.956970 411.434814,934.156860 
	C404.933685,934.371704 398.443634,934.921753 391.785950,935.585938 
M323.889862,872.042053 
	C323.889862,872.042053 324.020538,871.998535 324.472870,872.456482 
	C325.830994,872.165894 327.189117,871.875244 328.734833,871.544495 
	C327.349182,869.387817 326.471680,868.022034 325.594177,866.656250 
	C324.800720,864.302795 323.589691,862.376770 322.666199,860.321594 
	C320.466309,855.425964 315.209503,853.026184 310.887726,855.078186 
	C305.353271,857.705994 303.581085,862.840820 306.672333,868.690491 
	C309.504364,871.992615 317.272400,872.273926 322.012848,868.927917 
	C323.798157,869.348999 325.583496,869.770020 327.368835,870.191040 
	C327.264465,870.745544 327.160065,871.299988 327.055695,871.854492 
	C326.037903,871.908203 325.020081,871.961914 323.889862,872.042053 
M336.161926,861.407288 
	C342.623260,862.515686 344.625916,857.560242 346.959564,853.276062 
	C348.020966,851.327393 349.689301,848.567871 349.040436,847.047852 
	C347.252197,842.858521 345.237396,837.509277 341.715027,835.658325 
	C337.941803,833.675537 332.150391,834.353027 327.630798,835.462341 
	C325.325165,836.028198 323.576294,839.922974 322.136627,842.613159 
	C321.030518,844.680115 319.864716,847.435852 320.349396,849.504944 
	C322.160248,857.235413 327.335724,861.053467 336.161926,861.407288 
M412.570404,891.118469 
	C414.138062,889.722412 415.705719,888.326416 417.273346,886.930359 
	C417.075989,886.496338 416.878601,886.062378 416.681244,885.628357 
	C415.368286,886.099548 413.803070,887.284973 412.783722,886.924927 
	C406.588379,884.736755 400.514557,885.037598 394.375916,886.961426 
	C394.015411,887.074402 393.509247,886.607849 393.053253,886.549988 
	C390.747528,886.257568 387.672943,885.016052 386.345123,886.034058 
	C385.064423,887.015808 385.584717,890.346802 385.296204,892.795593 
	C394.740326,892.316223 403.293091,891.882080 412.570404,891.118469 
M333.023163,868.225891 
	C333.258270,868.158691 333.492462,868.051636 333.728943,868.046326 
	C333.809906,868.044434 333.967316,868.295898 333.970337,868.434204 
	C334.031494,871.239075 334.719330,873.428101 338.243439,873.024902 
	C341.788513,872.619202 345.337769,872.249756 348.781891,871.875610 
	C348.955811,872.448608 348.913635,872.318481 348.876801,872.186890 
	C348.272461,870.027527 348.295319,866.802002 346.903198,865.983887 
	C345.241638,865.007507 342.365479,866.278931 340.020966,866.240417 
	C336.648163,866.184937 333.280334,865.826111 329.075348,865.539917 
	C330.125000,868.715820 330.757263,870.628845 331.389496,872.541809 
	C331.815735,872.435120 332.241943,872.328430 332.668182,872.221741 
	C332.747650,871.115662 332.827148,870.009644 333.023163,868.225891 
M363.731049,888.416565 
	C367.718536,888.284912 371.711151,888.234314 375.691132,887.983521 
	C377.388763,887.876587 379.060028,887.351318 380.743286,887.016113 
	C373.259552,883.809021 365.990662,883.719910 358.561768,886.641052 
	C360.046082,887.186035 361.530396,887.731018 363.731049,888.416565 
M428.828705,886.607300 
	C427.799072,886.434814 426.769470,886.262329 424.534760,885.888000 
	C426.011963,887.518616 426.654053,888.227356 427.296112,888.936096 
	C427.110779,889.307678 426.925415,889.679199 426.740051,890.050781 
	C424.966431,889.249023 423.192810,888.447327 421.419189,887.645630 
	C421.134552,887.968872 420.849915,888.292114 420.565277,888.615356 
	C422.206116,889.945984 423.886658,892.399353 425.478455,892.343079 
	C427.844299,892.259338 430.700317,890.789490 428.828705,886.607300 
M417.438416,892.109375 
	C417.565216,891.918030 417.692047,891.726624 417.818817,891.535156 
	C417.657227,891.526245 417.444611,891.451172 417.347656,891.526062 
	C417.240570,891.608582 417.239624,891.828735 417.438416,892.109375 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M619.019592,888.997253 
	C616.991272,890.037720 615.059570,891.399048 612.912537,892.045105 
	C609.512146,893.068359 605.876953,893.355957 602.544678,894.531616 
	C601.657227,894.844727 600.928406,897.343140 601.124146,898.693604 
	C602.015930,904.843384 605.431396,909.349487 611.082642,911.980713 
	C618.785217,915.567078 618.810059,915.513672 617.476807,924.950562 
	C611.052307,924.950562 604.492615,924.950562 597.108154,924.819275 
	C593.696472,924.775635 591.109985,924.924255 588.522583,924.942139 
	C540.055847,925.277039 491.589020,925.596436 443.122192,925.919067 
	C443.122192,922.164917 443.122192,918.410767 443.122192,913.989014 
	C448.430603,913.989014 453.452606,913.989014 459.380280,913.989014 
	C458.774475,910.374756 458.302704,907.560181 458.314758,904.285522 
	C462.405853,905.962097 466.453125,907.350159 469.810547,904.276855 
	C472.997803,901.359192 475.274231,897.446533 478.315186,893.978027 
	C481.466064,892.355286 484.252899,890.725952 487.039734,889.096619 
	C486.702148,888.622803 486.364563,888.149048 486.026978,887.675293 
	C483.362122,889.801147 480.697235,891.926941 477.646606,893.894653 
	C475.067413,893.803955 473.508728,893.516541 471.538391,891.622498 
	C465.651367,885.963318 458.968353,886.534058 457.116730,892.712708 
	C456.113892,896.059021 457.658691,900.168823 458.054199,903.934204 
	C454.550598,901.503357 451.046967,899.072510 447.483795,896.600342 
	C448.348419,895.850830 449.081329,895.215454 449.921631,894.280640 
	C450.028961,893.981140 450.003204,894.000671 450.250732,893.939087 
	C450.703003,893.591919 450.907715,893.306274 451.470276,893.006470 
	C453.222778,891.324402 454.617462,889.656677 456.012146,887.988953 
	C456.012146,887.988953 455.999878,888.000305 456.336548,887.869629 
	C457.433563,885.618164 458.519104,883.539307 458.801178,881.356750 
	C458.921173,880.428040 457.392059,878.411499 456.557007,878.380432 
	C452.170471,878.217590 447.766174,878.531738 443.367889,878.342224 
	C443.216614,877.359375 443.065552,876.714478 442.995178,875.657593 
	C444.036499,874.796021 444.982178,873.993469 445.960144,873.952026 
	C454.857239,873.575073 463.758179,873.200134 472.661438,873.080627 
	C483.767609,872.931580 494.877075,873.025085 505.919189,873.355896 
	C504.804443,875.580750 503.455902,877.360718 502.767059,879.367188 
	C501.164459,884.035339 498.117126,885.670776 493.299683,885.151245 
	C491.670166,884.975464 489.902252,886.082581 488.197601,886.603455 
	C498.481750,885.290039 499.420410,885.720276 504.380829,894.676697 
	C506.589020,898.663818 519.133789,901.352051 521.886230,897.921997 
	C524.745972,894.358337 527.370178,890.125000 528.407410,885.781006 
	C528.930359,883.590881 525.713501,880.507751 524.125122,877.525208 
	C523.465576,876.396179 522.868591,875.570312 522.252808,874.473389 
	C522.203064,873.842285 522.172058,873.482117 522.596313,873.102112 
	C541.103210,872.977478 559.154724,872.872742 577.476196,872.993286 
	C579.528259,873.991028 581.662537,875.770081 583.009766,875.298828 
	C585.005005,874.601013 586.257141,874.296997 588.191406,875.402893 
	C589.150574,875.951233 590.922241,875.007324 592.338745,874.850281 
	C593.428650,874.729370 594.540649,874.722595 595.637817,874.773743 
	C597.183899,874.845642 598.825439,875.419128 600.247742,875.063843 
	C603.199341,874.326599 606.033569,873.119080 608.898926,872.455688 
	C609.160217,873.462830 609.438538,874.119202 609.716919,874.775513 
	C610.548035,873.996033 611.379211,873.216492 612.566711,872.467590 
	C614.283508,873.000061 615.643921,873.501892 617.246704,874.039062 
	C617.489014,874.074402 617.974365,874.009277 618.084961,874.430969 
	C618.034851,876.573669 617.874023,878.294678 617.759277,879.522461 
	C610.740906,879.446167 604.141113,875.951538 597.854065,880.697693 
	C597.794983,880.605774 597.735962,880.513855 597.676880,880.421936 
	C597.632996,880.501709 597.589050,880.581421 597.508423,880.727844 
	C599.794312,881.567261 602.075073,883.103638 604.346497,883.090027 
	C608.757202,883.063660 613.162781,882.166748 617.188049,881.677124 
	C617.931641,884.649109 618.475647,886.823181 619.019592,888.997253 
M530.499390,918.329529 
	C532.269653,918.754761 534.039978,919.179993 535.425354,919.512756 
	C535.726685,916.833679 535.966736,914.699768 536.145203,913.113647 
	C532.016663,913.113647 527.629822,913.113647 523.243042,913.113647 
	C518.576111,912.501709 513.837646,912.124939 509.097809,912.106384 
	C507.851868,912.101501 506.601593,913.205505 505.353271,913.800232 
	C506.087952,914.891541 506.935181,915.928772 507.498627,917.102234 
	C507.706329,917.534912 507.244720,918.288940 506.899567,918.607483 
	C506.777893,918.572205 506.656250,918.536926 506.534576,918.501709 
	C506.636200,918.715515 506.737854,918.929382 506.974426,919.817444 
	C510.842987,920.958679 510.942322,920.847351 512.351196,914.296875 
	C512.715515,915.875854 513.079834,917.454895 513.700317,920.144165 
	C514.469849,917.334778 514.895081,915.782288 515.320374,914.229797 
	C516.527466,919.057495 518.054626,922.518433 523.765442,919.387451 
	C523.899902,919.313782 524.305420,919.706848 524.569824,919.901978 
	C527.330139,921.938965 528.740845,920.153076 530.000549,917.999939 
	C530.000549,917.999939 529.983887,918.016602 530.499390,918.329529 
M571.035400,917.308411 
	C570.927185,915.670898 570.818970,914.033386 570.627869,911.142334 
	C569.005676,913.336548 568.298889,914.292603 568.319214,914.265137 
	C566.252258,913.582153 564.857971,912.753357 563.446106,912.722229 
	C558.691895,912.617371 553.918762,913.046936 549.176453,912.807007 
	C542.351929,912.461792 542.360718,912.287720 543.591736,918.844543 
	C545.126343,918.991699 546.543518,919.040894 547.927490,919.281433 
	C549.845947,919.614807 551.744751,920.462952 553.642639,920.435974 
	C558.726501,920.363708 563.809937,920.000244 568.881165,919.596497 
	C569.615784,919.538025 570.282227,918.623108 571.035400,917.308411 
M608.309143,887.978638 
	C608.741699,887.422424 609.174316,886.866211 609.606934,886.310059 
	C609.235779,886.061646 608.864563,885.813232 608.493408,885.564819 
	C608.387451,886.154724 608.281494,886.744629 608.309143,887.978638 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M443.101135,926.282654 
	C491.589020,925.596436 540.055847,925.277039 588.522583,924.942139 
	C591.109985,924.924255 593.696472,924.775635 596.639709,924.840820 
	C597.009583,925.409973 597.023132,925.826416 597.064331,926.551880 
	C578.303955,926.919312 559.515137,926.896912 540.727844,927.053772 
	C508.505585,927.322754 476.284210,927.703613 443.609894,928.068115 
	C443.131561,927.614563 443.105804,927.130371 443.101135,926.282654 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M440.996094,927.905151 
	C441.009003,930.748962 441.009003,933.114380 441.009003,935.479736 
	C440.509186,935.470581 440.009338,935.461426 439.509521,935.452271 
	C439.509521,928.527161 439.509521,921.602051 439.509521,914.676941 
	C440.000763,914.657410 440.491974,914.637817 440.983215,914.618286 
	C440.983215,918.887756 440.983215,923.157227 440.996094,927.905151 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M418.997986,913.168213 
	C417.883484,912.405579 416.931976,911.559570 416.209900,910.917542 
	C413.861298,911.502136 411.666656,912.048401 409.471985,912.594666 
	C409.411224,912.307251 409.350464,912.019836 409.289703,911.732422 
	C411.717163,910.242676 414.144623,908.752869 417.077454,906.953003 
	C418.088196,908.397095 419.180817,909.958252 420.251923,911.941589 
	C419.873932,912.604187 419.517426,912.844482 418.997986,913.168213 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M422.998566,903.012329 
	C422.505615,900.890869 422.014008,898.753357 421.522430,896.615845 
	C422.036560,896.483093 422.550690,896.350403 423.064819,896.217712 
	C423.715759,897.508667 424.366699,898.799561 425.414185,900.876953 
	C426.561890,898.903870 427.289490,897.653076 428.017090,896.402222 
	C428.487885,896.454651 428.958679,896.507019 429.429474,896.559387 
	C429.254791,898.464783 429.080109,900.370178 428.891663,902.669067 
	C428.877899,903.062500 428.674042,903.049622 428.106842,902.979553 
	C426.015930,902.941528 424.492188,902.973511 422.968475,903.005554 
	C422.968475,903.005554 422.999908,902.996338 422.998566,903.012329 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M417.405151,940.139526 
	C415.770569,938.963074 414.135986,937.786682 412.288574,936.351929 
	C417.035919,936.034546 422.000702,935.842529 426.952881,936.014160 
	C428.124756,936.054810 429.254272,937.316406 429.697449,938.106567 
	C425.129456,938.844360 421.267334,939.491943 417.405151,940.139526 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M417.643127,940.355957 
	C421.267334,939.491943 425.129456,938.844360 429.393433,938.347656 
	C429.813446,939.039185 429.831543,939.579834 429.825653,940.389038 
	C425.828156,940.629211 421.854614,940.600769 417.643127,940.355957 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M422.913696,903.344849 
	C424.492188,902.973511 426.015930,902.941528 428.008728,902.951172 
	C427.807983,903.839905 427.072754,905.431641 426.481964,905.379700 
	C425.234283,905.270020 424.062103,904.300781 422.913696,903.344849 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1040.843018,399.051575 
	C1043.809937,396.903137 1046.776978,394.754730 1049.865234,392.302063 
	C1049.986450,391.997833 1049.983887,392.029327 1050.234131,391.944885 
	C1050.663208,391.570831 1050.841919,391.281250 1051.007080,390.992432 
	C1050.993286,390.993225 1050.992432,391.020966 1051.254395,390.960968 
	C1051.683838,390.601562 1051.851196,390.302185 1052.008789,390.001373 
	C1051.998901,389.999969 1052.009521,390.018341 1052.009521,390.018341 
	C1053.675537,388.908752 1055.341675,387.799164 1057.003662,386.343811 
	C1056.999512,385.998016 1057.004028,386.019073 1057.004028,386.019073 
	C1062.538086,382.206329 1068.072144,378.393585 1073.045166,374.967407 
	C1075.470825,374.967407 1077.877319,375.608734 1079.363647,374.716766 
	C1080.198853,374.215485 1079.514038,371.181213 1079.514038,369.467346 
	C1081.960693,367.237244 1084.454834,364.963776 1086.982910,362.342346 
	C1087.016724,361.994385 1086.991455,361.996826 1087.344971,362.024902 
	C1088.474731,361.366119 1089.250732,360.679291 1090.026855,359.992432 
	C1090.026855,359.992432 1089.989502,359.989532 1090.246094,359.937561 
	C1090.674561,359.591064 1090.846558,359.296478 1091.018677,359.001953 
	C1091.018677,359.001953 1090.997314,358.997620 1091.267822,358.961609 
	C1091.698364,358.618652 1091.858154,358.311707 1092.017944,358.004700 
	C1092.017944,358.004700 1092.000000,357.998962 1092.269531,357.960419 
	C1092.699219,357.614655 1092.859375,357.307434 1093.019531,357.000183 
	C1093.019531,357.000214 1092.998291,356.998962 1093.001465,357.009338 
	C1093.699829,357.750061 1094.394897,358.480438 1095.101562,359.873230 
	C1095.508179,360.882843 1095.903198,361.230072 1096.298096,361.577332 
	C1096.518311,361.040192 1096.738525,360.503021 1096.958618,359.965851 
	C1096.958618,359.965851 1096.989380,359.999207 1097.253174,359.969543 
	C1097.696167,359.916534 1097.875366,359.893219 1098.054443,359.869873 
	C1098.326904,360.654083 1098.599243,361.438324 1098.495483,362.606812 
	C1097.119751,365.363556 1096.120239,367.736053 1095.120605,370.108521 
	C1095.117188,372.767212 1095.388794,375.466919 1095.000732,378.068268 
	C1094.789062,379.487610 1093.710083,381.027649 1092.582397,382.006897 
	C1090.423706,383.881439 1090.262329,385.083862 1092.674561,386.983368 
	C1094.093994,388.101044 1095.568237,390.181396 1095.605469,391.860077 
	C1095.924805,406.254211 1095.921387,420.655487 1095.614014,435.563568 
	C1092.466187,437.206757 1088.072754,435.790070 1087.843750,440.827637 
	C1087.463013,443.080902 1087.082397,445.334167 1086.623291,448.051727 
	C1083.157227,448.246521 1079.571411,448.448029 1075.985596,448.649536 
	C1075.995239,448.973785 1076.004883,449.298004 1076.014526,449.622253 
	C1079.530029,449.622253 1083.045654,449.622253 1086.080200,449.622253 
	C1087.483398,452.721497 1088.734375,455.484741 1090.347778,459.048187 
	C1089.278320,467.154572 1088.030151,476.615692 1086.642090,487.137268 
	C1084.294312,487.492432 1080.820190,488.017944 1077.635742,488.499634 
	C1087.898315,490.160553 1087.897949,490.160553 1087.896851,501.244904 
	C1087.896240,508.394745 1087.896729,515.544617 1087.896729,522.803162 
	C1086.462158,523.311523 1085.249634,523.741211 1083.976929,524.192200 
	C1083.844971,524.681641 1083.629150,525.137634 1083.610718,525.601501 
	C1083.322266,532.860168 1083.227051,532.870911 1076.245361,531.440125 
	C1074.678101,531.118896 1072.946777,531.597046 1071.291016,531.707214 
	C1071.308838,532.185730 1071.326660,532.664185 1071.344482,533.142639 
	C1073.319458,533.142639 1075.342773,533.421326 1077.260010,533.088745 
	C1082.357300,532.204590 1083.128296,532.873352 1083.827881,537.871704 
	C1084.049072,539.451538 1085.774536,540.820801 1086.811646,542.286438 
	C1087.200317,542.120300 1087.589111,541.954163 1087.977783,541.788025 
	C1087.977783,544.601318 1087.977783,547.414551 1088.082031,550.988159 
	C1088.150513,552.183899 1088.114868,552.619202 1087.673340,553.137085 
	C1083.977417,553.735229 1080.687378,554.250793 1076.632202,554.886292 
	C1081.055420,556.176147 1084.406006,557.153259 1087.920410,558.561584 
	C1088.281006,569.210876 1088.478149,579.428833 1088.278687,589.745361 
	C1081.421753,589.659851 1085.266968,595.364868 1083.436768,597.769104 
	C1080.487671,598.031982 1077.626221,598.287048 1074.764893,598.542114 
	C1074.796509,598.992493 1074.828247,599.442871 1074.859985,599.893188 
	C1077.719849,599.718445 1080.579834,599.543762 1083.693848,599.353455 
	C1083.893311,602.154419 1084.054565,604.416687 1084.211426,606.618652 
	C1085.808105,607.070312 1087.173096,607.456360 1089.245605,608.042542 
	C1089.245605,624.665283 1089.245605,641.210510 1089.150391,658.211304 
	C1088.592285,661.275696 1088.129272,663.884644 1087.304199,666.439636 
	C1086.465576,666.239563 1085.963013,666.148804 1085.516235,665.939270 
	C1052.710938,650.557129 1019.891235,635.205078 987.133179,619.722778 
	C983.181702,617.855225 979.565369,615.278442 976.169312,612.981689 
	C977.356812,611.637756 978.168823,610.342590 978.944031,609.106079 
	C982.760742,610.795227 986.391418,612.402039 990.010803,614.005005 
	C989.999573,614.001282 989.988831,613.980469 989.971985,614.338501 
	C993.405457,620.073120 999.197510,621.239380 1004.707886,622.947266 
	C1008.468750,624.112854 1012.449646,624.921082 1015.859070,626.764282 
	C1018.963867,628.442688 1020.375305,627.926941 1021.528748,624.925049 
	C1021.920349,623.905762 1022.488159,622.954163 1023.049683,621.821838 
	C1024.913818,622.450134 1026.529419,622.837097 1028.009155,623.518860 
	C1037.761597,628.011963 1047.349854,632.896790 1057.271118,636.974182 
	C1062.711914,639.210327 1068.783081,639.861145 1074.410156,641.713074 
	C1078.515991,643.064331 1082.360474,645.209778 1086.321533,647.001221 
	C1086.528564,646.625610 1086.735596,646.250000 1086.942749,645.874390 
	C1086.131104,645.255981 1085.400513,644.461975 1084.496094,644.045105 
	C1067.023071,635.989258 1049.540405,627.954224 1032.034912,619.969116 
	C1025.420044,616.951721 1024.523926,615.866150 1026.539673,608.996521 
	C1028.641846,601.831909 1027.608643,594.867859 1026.290161,587.882507 
	C1025.678955,584.644165 1025.898560,582.067871 1028.545532,579.820862 
	C1030.569458,578.102783 1032.217285,575.939575 1034.252075,574.237122 
	C1038.388306,570.776489 1039.941284,566.039673 1038.784790,561.282715 
	C1037.324585,555.276611 1038.581909,549.613708 1038.731323,543.758728 
	C1039.021118,532.407776 1038.292969,521.028503 1037.895142,509.664154 
	C1037.814575,507.361755 1037.330078,505.073517 1037.020264,502.625977 
	C1037.008911,502.473145 1037.033325,502.167603 1037.045410,501.692078 
	C1038.088623,494.074249 1039.220459,486.944122 1040.087158,479.781891 
	C1040.333496,477.747375 1039.807251,475.619385 1039.635132,473.534241 
	C1036.984253,474.468231 1032.990723,472.455597 1031.995728,477.007721 
	C1031.998901,476.998840 1032.015747,476.989349 1031.705566,477.084503 
	C1030.776489,477.794922 1030.157593,478.410187 1029.442871,479.099762 
	C1029.347168,479.174072 1029.023804,479.532593 1028.777588,479.781860 
	C1027.349609,481.359222 1026.168091,482.687286 1024.992676,484.007080 
	C1024.998901,483.998840 1025.015259,483.986359 1024.702515,484.108795 
	C1022.171509,486.482330 1019.976135,488.756561 1017.729126,490.978516 
	C1015.165894,493.513000 1012.971619,496.915955 1009.880676,498.317871 
	C1003.504883,501.209534 999.932068,506.185455 996.994385,512.008118 
	C996.998901,511.998749 997.015076,511.985321 996.706299,512.123474 
	C993.260681,516.847290 990.123901,521.432861 986.993042,526.008545 
	C986.998901,525.998657 987.015503,525.982483 986.706421,526.119812 
	C981.744324,532.013855 974.944458,533.505676 967.446838,536.191223 
	C967.884277,528.467102 968.697693,521.595093 968.475830,514.756592 
	C968.370239,511.501312 969.745911,507.159851 966.001587,505.085266 
	C964.774536,507.688416 964.131409,510.617401 962.350037,512.423950 
	C960.265259,514.538269 957.180115,515.660461 954.544495,517.238953 
	C951.554504,519.029785 948.962524,521.085266 949.688782,524.511780 
	C947.531311,524.726379 945.441101,524.934265 943.354980,525.141785 
	C944.597168,528.112549 945.445496,530.141296 946.284790,532.148438 
	C943.055786,531.755066 940.800964,531.457520 938.540710,531.210449 
	C935.198303,530.845215 933.429199,532.943176 934.462463,535.587463 
	C935.091980,537.198547 938.696167,539.205750 940.018555,538.675720 
	C943.142395,537.423645 945.765869,534.841248 948.473877,532.651306 
	C953.477844,528.604492 958.388489,524.442383 963.379822,520.379700 
	C963.619324,520.184814 964.261475,520.484558 965.124390,520.616211 
	C965.124390,525.646973 965.183594,530.621338 965.067200,535.591492 
	C965.047363,536.437744 964.480530,537.742676 963.817688,538.029236 
	C957.434143,540.789368 951.679016,544.273132 947.613220,550.162415 
	C946.927795,551.155212 945.467407,551.795959 944.234192,552.197754 
	C936.375427,554.758484 932.033997,560.730835 928.379395,567.848755 
	C923.944214,567.876282 919.923462,567.875305 915.637146,567.666870 
	C902.302979,561.268372 889.234314,555.077332 876.040039,548.452026 
	C876.527710,544.220337 875.818604,539.164490 878.006470,536.865295 
	C884.142273,530.417175 891.473450,525.106506 898.695679,519.042542 
	C902.036011,524.767029 904.322998,519.818604 906.387878,518.370605 
	C909.150818,516.433105 912.944702,513.562012 913.088867,510.916992 
	C913.260071,507.778076 914.558777,506.505524 916.350891,505.000763 
	C918.770447,502.969055 921.560120,501.340790 923.798096,499.139923 
	C926.437134,496.544647 929.172180,494.958679 933.412598,495.961548 
	C938.404358,495.990356 942.925598,495.990356 947.446899,495.990356 
	C947.423401,495.380432 947.399963,494.770538 947.376526,494.160614 
	C942.853149,494.160614 938.329773,494.160614 933.536255,493.944000 
	C934.242126,492.220490 934.982117,490.472626 936.233398,489.246704 
	C943.238586,482.383606 952.473267,478.212311 959.271362,470.626007 
	C961.962280,472.110901 964.252869,473.563019 966.198914,474.796753 
	C966.492432,473.583313 966.995361,471.504211 967.826294,468.069122 
	C971.249634,472.016693 973.758240,474.909454 976.266907,477.802216 
	C976.638306,477.470978 977.009705,477.139740 977.381165,476.808472 
	C974.958923,473.901215 972.536682,470.993958 970.074463,468.038696 
	C971.971741,466.053894 973.438538,464.519440 974.903076,462.987366 
	C974.296753,461.666199 973.764587,460.506439 973.513489,459.155853 
	C975.722778,457.396545 977.678833,455.860870 979.572449,454.251709 
	C982.312683,451.923218 984.692200,448.844666 989.462463,450.017731 
	C994.468567,450.039886 999.008057,450.039886 1003.547546,450.039886 
	C1003.527405,449.391602 1003.507263,448.743317 1003.487183,448.095032 
	C998.952759,448.095032 994.418396,448.095032 989.639221,447.875977 
	C990.861084,446.347076 992.297363,445.001282 993.799438,443.733490 
	C1001.658203,437.100433 1009.511230,430.460083 1017.428955,423.897827 
	C1018.541443,422.975830 1020.688660,421.545044 1021.186523,421.916870 
	C1025.131958,424.863831 1027.518555,421.001740 1030.633301,419.847290 
	C1033.346069,422.931702 1035.925781,425.865021 1038.505615,428.798309 
	C1038.996460,428.327728 1039.487305,427.857147 1039.978271,427.386566 
	C1038.339844,425.671448 1036.784790,423.865448 1035.041138,422.264862 
	C1032.994629,420.386078 1031.611938,418.555695 1034.381470,415.908630 
	C1037.085083,414.495697 1037.030884,412.832764 1035.445190,410.925385 
	C1039.116211,410.660004 1037.749268,408.320465 1037.528809,406.239807 
	C1037.371948,404.759552 1037.806152,403.216644 1037.988403,401.351532 
	C1038.000366,401.001404 1038.010132,401.017975 1038.355591,401.022949 
	C1039.277954,400.348175 1039.854736,399.668457 1040.534180,399.006470 
	C1040.636719,399.024200 1040.843018,399.051575 1040.843018,399.051575 
M911.018433,520.543884 
	C910.920593,520.278625 910.822754,520.013367 910.724976,519.748169 
	C910.560242,519.853210 910.395508,519.958252 910.230713,520.063232 
	C910.487793,520.378967 910.744812,520.694763 911.173706,521.587646 
	C911.561707,522.160095 911.949707,522.732544 912.337708,523.304932 
	C912.623840,522.954041 912.909973,522.603210 913.196106,522.252380 
	C912.442322,521.856079 911.688599,521.459839 911.018433,520.543884 
M1075.232910,554.806396 
	C1075.301880,554.705933 1075.444946,554.533142 1075.428467,554.516357 
	C1075.271118,554.355347 1075.086792,554.220703 1074.737305,553.366333 
	C1073.231201,553.155457 1071.623291,552.535522 1070.254272,552.875916 
	C1069.324585,553.107117 1068.113892,554.714600 1068.158447,555.652893 
	C1068.203369,556.594666 1070.132690,558.370605 1070.401001,558.226440 
	C1072.023193,557.355103 1073.405884,556.038025 1075.232910,554.806396 
M1065.762817,490.903931 
	C1066.863159,491.129303 1068.042969,491.718048 1069.046143,491.498779 
	C1070.750488,491.126343 1072.555054,490.511444 1073.886719,489.455200 
	C1074.476685,488.987305 1074.015259,487.193909 1074.031128,486.002228 
	C1072.478882,486.110046 1070.927002,486.294983 1069.374268,486.303314 
	C1067.913818,486.311157 1066.452148,486.132568 1064.903198,485.330414 
	C1062.465332,485.048431 1060.027466,484.766479 1057.657471,484.492371 
	C1056.688110,491.167084 1059.204102,493.174133 1065.762817,490.903931 
M1005.199707,481.055298 
	C1003.862122,480.865479 1002.524536,480.675659 1000.505737,480.389160 
	C1001.335449,482.991455 1001.935669,484.873901 1002.455811,486.505219 
	C1007.298706,483.564972 1011.613953,480.945068 1015.207764,478.763214 
	C1014.411438,475.904877 1013.735168,473.477570 1013.357361,470.937592 
	C1013.226196,470.943665 1013.094971,470.949738 1012.276306,471.033722 
	C1010.260498,472.496246 1008.302368,474.047821 1006.214905,475.399628 
	C1003.375244,477.238586 1004.452698,479.065491 1005.992065,481.008270 
	C1005.992065,481.008270 1005.952209,481.045593 1005.199707,481.055298 
M981.156189,498.067719 
	C979.169434,499.016113 977.182678,499.964478 975.189453,500.915955 
	C977.119202,503.386536 978.233459,504.813049 979.332703,506.220367 
	C982.655090,503.727692 985.433777,501.543854 988.316711,499.507629 
	C991.246033,497.438660 994.278137,495.515228 997.412292,493.430176 
	C994.502808,491.470123 992.477966,490.105988 989.785583,488.292206 
	C990.653259,490.940613 991.256409,492.781738 991.859619,494.622894 
	C989.706970,494.529083 988.621948,493.547607 987.347961,493.048096 
	C986.793762,492.830841 985.851379,493.603638 984.995972,494.010193 
	C984.995972,494.010193 984.913635,494.100525 984.178772,494.136078 
	C983.479370,495.400757 982.779968,496.665436 982.007324,497.997345 
	C982.007324,497.997345 981.939819,498.070435 981.156189,498.067719 
M1053.137085,561.241333 
	C1051.769531,564.822693 1050.401855,568.404053 1048.852295,572.462158 
	C1051.555786,573.157837 1053.724976,573.716064 1056.485352,574.426392 
	C1057.794678,569.963867 1058.934937,565.883118 1060.230469,561.852173 
	C1060.447754,561.175720 1061.309326,560.267639 1061.921387,560.228882 
	C1065.522217,560.000916 1065.207520,557.384521 1064.921631,555.160278 
	C1064.646484,553.017944 1062.997192,550.850037 1063.273438,548.878601 
	C1063.983398,543.810547 1065.425781,538.845215 1066.589844,533.776550 
	C1065.329102,533.045715 1064.020752,532.287292 1062.249634,531.260498 
	C1064.428833,530.944153 1065.951294,530.723206 1068.688599,530.325867 
	C1066.241455,529.182129 1064.916626,528.562988 1063.060181,527.695251 
	C1069.352783,526.511475 1070.891479,524.387817 1070.986206,518.328735 
	C1071.015625,516.453979 1071.782227,514.590637 1072.320923,512.245117 
	C1070.650269,511.832916 1068.685791,511.348236 1067.245361,510.992859 
	C1062.743774,519.719177 1060.484619,528.363647 1060.250488,538.039246 
	C1060.129395,538.146606 1058.822021,538.756897 1058.466431,539.718933 
	C1057.178345,543.204468 1055.934814,546.752380 1055.200439,550.383179 
	C1054.511230,553.790833 1054.487915,557.333252 1054.009521,560.984436 
	C1054.009521,560.984436 1053.839478,561.144348 1053.137085,561.241333 
M1046.240112,542.588623 
	C1046.408081,544.928345 1047.647583,547.423950 1044.734741,549.215210 
	C1044.193359,549.548157 1044.167725,552.098572 1044.494873,552.237793 
	C1046.405273,553.050903 1048.471680,553.683960 1050.529541,553.859436 
	C1051.219360,553.918213 1052.535156,552.553894 1052.712158,551.669373 
	C1053.436523,548.051453 1053.614258,544.320618 1054.395264,540.718506 
	C1055.786987,534.300537 1057.469971,527.945679 1059.467041,519.782837 
	C1055.718628,520.756592 1053.500122,521.332947 1051.281494,521.909363 
	C1052.573486,525.507629 1046.637085,529.062988 1051.183472,532.732849 
	C1051.236206,532.775452 1050.646240,533.548950 1050.420776,534.012756 
	C1049.137939,536.652832 1047.874756,539.302368 1046.240112,542.588623 
M958.426208,489.957214 
	C957.207886,490.895691 955.989563,491.834167 954.767395,492.771576 
	C954.203308,491.939270 953.639221,491.106964 952.405334,489.286407 
	C952.405334,492.519012 952.405334,494.528015 952.405334,496.670563 
	C954.091553,496.866425 955.542786,497.167358 956.995789,497.175934 
	C959.911194,497.193146 962.654297,494.139008 965.866638,496.690552 
	C966.065796,496.848816 968.340881,494.833405 969.176636,493.526489 
	C969.494751,493.028961 968.660156,491.033752 968.030151,490.855591 
	C966.869385,490.527374 965.405640,490.847382 964.195007,491.265076 
	C963.363770,491.551819 962.746826,492.459961 961.707397,493.379272 
	C960.698242,492.138885 959.908386,491.168091 958.426208,489.957214 
M1010.935303,448.968658 
	C1010.477844,447.660004 1010.020447,446.351349 1009.171265,443.921906 
	C1009.171265,446.924622 1009.171265,448.717773 1009.171265,450.461121 
	C1015.440552,452.919952 1016.577332,452.554504 1018.119446,448.630432 
	C1020.257568,449.145416 1022.247070,449.967560 1024.184570,449.859253 
	C1024.693237,449.830811 1025.040527,446.918182 1025.461914,445.330048 
	C1023.322815,445.330048 1021.183716,445.330048 1019.044617,445.330048 
	C1019.079712,445.250122 1019.114746,445.170166 1019.149841,445.090240 
	C1016.893677,444.833313 1014.637573,444.576385 1012.381470,444.319458 
	C1012.310608,444.880859 1012.239807,445.442230 1012.168945,446.003632 
	C1013.418457,446.188995 1014.667908,446.374329 1015.917358,446.559692 
	C1015.999207,446.913879 1016.081055,447.268066 1016.162842,447.622253 
	C1014.633972,448.239075 1013.105103,448.855865 1010.935303,448.968658 
M1019.339478,468.994781 
	C1018.437195,468.782745 1017.534851,468.570740 1016.632568,468.358704 
	C1016.770630,471.037231 1016.908691,473.715790 1017.063660,476.721130 
	C1019.327515,474.731567 1020.960815,473.296143 1022.920471,471.573914 
	C1021.926514,470.871704 1020.926697,470.165344 1019.339478,468.994781 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1091.965698,494.056396 
	C1093.331055,494.410736 1094.696533,494.765045 1096.492065,495.591431 
	C1096.950195,504.041534 1096.978149,512.019531 1096.649048,519.994995 
	C1094.311890,519.984619 1092.331665,519.976807 1090.351562,519.968933 
	C1090.323242,511.670319 1090.295044,503.371674 1090.565063,494.581146 
	C1091.230713,494.078278 1091.598267,494.067322 1091.965698,494.056396 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1095.560791,457.220764 
	C1095.957031,458.288452 1095.987671,459.311676 1096.065430,460.718628 
	C1096.159302,462.801544 1096.205811,464.500763 1096.157959,467.002289 
	C1096.074585,475.236908 1096.085815,482.669250 1096.097168,490.101593 
	C1094.366943,489.080841 1092.636597,488.060120 1090.575684,486.928589 
	C1090.121460,483.841797 1089.997925,480.865753 1090.072021,477.221130 
	C1090.129028,475.370850 1089.988770,474.189240 1089.848389,473.007599 
	C1091.630615,467.730499 1093.412842,462.453400 1095.560791,457.220764 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1090.265747,520.331787 
	C1092.331665,519.976807 1094.311890,519.984619 1096.968750,519.776611 
	C1098.072266,516.828491 1098.753052,514.107361 1098.873047,511.361694 
	C1099.124390,505.613037 1099.080322,499.851471 1099.157715,494.095184 
	C1099.458374,501.069244 1099.758911,508.043274 1100.125000,515.476807 
	C1100.114624,517.018799 1100.038696,518.101257 1099.979248,519.590881 
	C1099.250854,521.388916 1098.505859,522.779846 1097.694092,524.295288 
	C1098.360718,525.427795 1099.189087,526.834961 1099.993896,528.608337 
	C1097.437256,531.613586 1094.882080,534.191162 1099.531616,537.317871 
	C1099.946655,537.596985 1099.411011,539.290161 1099.156006,540.660645 
	C1097.996582,542.531555 1096.998047,544.068420 1095.506714,546.363770 
	C1094.961670,544.758179 1094.556885,543.565857 1094.132812,541.978882 
	C1094.113525,541.584290 1094.127808,541.578674 1094.127808,541.578674 
	C1094.365479,538.729004 1094.603027,535.879272 1094.878906,532.264648 
	C1094.875000,531.280151 1094.833008,531.060669 1094.791016,530.841187 
	C1094.819702,529.852234 1094.848267,528.863342 1094.927246,527.146484 
	C1094.310425,525.642822 1093.643188,524.867065 1092.976074,524.091309 
	C1092.546997,524.048279 1092.117920,524.005249 1091.255127,523.744507 
	C1090.666870,523.295532 1090.479126,523.095337 1090.258301,522.926086 
	C1090.232178,522.182251 1090.206055,521.438416 1090.265747,520.331787 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1088.675293,589.646851 
	C1088.478149,579.428833 1088.281006,569.210876 1088.015869,558.143311 
	C1087.991455,555.880737 1088.035278,554.467651 1088.079224,553.054565 
	C1088.114868,552.619202 1088.150513,552.183899 1088.226440,551.422852 
	C1088.601807,551.113098 1088.937134,551.128967 1089.588867,551.474854 
	C1090.090454,552.498962 1090.275391,553.192993 1090.460449,553.887085 
	C1090.297363,555.000549 1090.134399,556.113953 1090.027588,558.104980 
	C1090.385132,569.328003 1090.686646,579.673401 1090.802734,590.279297 
	C1089.969971,590.242188 1089.322632,589.944519 1088.675293,589.646851 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1090.831543,553.901794 
	C1090.275391,553.192993 1090.090454,552.498962 1089.840332,551.448303 
	C1089.906250,548.514954 1089.794556,545.888672 1090.304565,543.389221 
	C1090.475342,542.552551 1092.232910,542.039734 1093.696533,541.478760 
	C1094.127808,541.578674 1094.113525,541.584290 1094.124268,541.585327 
	C1093.670288,543.965027 1093.267578,546.358154 1092.719727,548.717590 
	C1092.311768,550.473267 1091.715210,552.185120 1090.831543,553.901794 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1088.218384,440.809814 
	C1088.072754,435.790070 1092.466187,437.206757 1095.600830,436.031525 
	C1096.309326,436.288086 1096.630615,436.585632 1097.070190,437.205688 
	C1094.323364,438.616150 1091.458130,439.704071 1088.218384,440.809814 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1098.172363,437.144287 
	C1097.865356,430.459259 1097.895630,423.741119 1097.953247,416.261475 
	C1097.978516,415.300995 1097.976440,415.101959 1097.974243,414.902924 
	C1097.997803,414.741089 1097.986816,414.456696 1098.051025,414.438599 
	C1098.342773,414.356140 1098.655640,414.348724 1098.960938,414.314392 
	C1098.966919,418.238831 1098.971069,422.163269 1098.979858,426.087677 
	C1098.986816,429.194580 1098.999268,432.301483 1099.026611,435.968262 
	C1099.044067,436.528168 1099.029175,436.675568 1099.029175,436.675568 
	C1098.856079,436.820770 1098.682983,436.965973 1098.172363,437.144287 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1092.840332,524.350647 
	C1093.643188,524.867065 1094.310425,525.642822 1094.899414,526.776367 
	C1093.513184,526.672241 1092.204956,526.210205 1090.684326,525.673157 
	C1091.459717,525.265076 1092.082275,524.937561 1092.840332,524.350647 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1091.982422,493.700500 
	C1091.598267,494.067322 1091.230713,494.078278 1090.588867,494.123016 
	C1090.295532,492.491821 1090.276611,490.826813 1090.414429,488.790894 
	C1091.047363,490.061523 1091.523315,491.703064 1091.982422,493.700500 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1089.637817,473.238647 
	C1089.988770,474.189240 1090.129028,475.370850 1090.033203,476.772156 
	C1089.673828,475.817749 1089.550537,474.643707 1089.637817,473.238647 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1098.676880,439.849426 
	C1098.885498,440.564209 1098.798950,441.483765 1098.449707,442.621368 
	C1098.251953,441.911011 1098.316772,440.982605 1098.676880,439.849426 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1094.493042,530.862671 
	C1094.833008,531.060669 1094.875000,531.280151 1094.854126,531.829224 
	C1094.083130,532.055664 1093.375122,531.952393 1091.366089,531.659424 
	C1093.164185,531.166626 1093.679565,531.025391 1094.493042,530.862671 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1090.248535,523.178101 
	C1090.479126,523.095337 1090.666870,523.295532 1090.934570,523.699707 
	C1090.778076,523.725098 1090.508545,523.577576 1090.248535,523.178101 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1099.187012,436.710876 
	C1099.029175,436.675568 1099.044067,436.528168 1099.027832,436.461670 
	C1099.011475,436.395172 1099.459106,436.527283 1099.557373,436.592163 
	C1099.655640,436.657074 1099.344727,436.746216 1099.187012,436.710876 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1462.000000,573.000000 
	C1461.980347,560.657349 1461.960693,548.314636 1462.011230,535.512573 
	C1469.058472,534.714233 1476.035522,534.375244 1483.465332,534.045654 
	C1486.875488,538.922424 1483.687500,543.844788 1484.049072,548.735657 
	C1484.300659,552.138977 1483.859253,555.868103 1488.144409,557.356934 
	C1487.158936,558.095825 1486.170166,558.833923 1484.671265,559.952820 
	C1486.080078,560.604858 1487.013550,561.036865 1488.044434,561.514038 
	C1487.009888,562.457825 1485.916992,563.454773 1484.917969,564.366150 
	C1487.156616,566.198669 1489.161987,567.840271 1491.398193,569.670776 
	C1487.760132,572.960693 1486.378784,571.976868 1485.232788,568.125488 
	C1484.983765,569.317139 1484.130737,571.018677 1484.614502,571.591797 
	C1485.555542,572.706787 1487.225220,573.707092 1488.633301,573.764099 
	C1489.699707,573.807251 1490.924683,572.409058 1491.878174,571.452026 
	C1492.223022,571.105957 1492.217041,570.071655 1491.972656,569.531982 
	C1490.289185,565.816284 1491.973511,562.104126 1491.789917,558.430542 
	C1491.655151,555.732727 1490.162476,553.127136 1489.572266,550.415771 
	C1489.337524,549.337158 1489.482422,547.572632 1490.178711,546.997925 
	C1493.380737,544.355347 1491.664551,542.694031 1489.314087,541.004822 
	C1492.297974,539.373962 1489.858398,531.384094 1496.838623,535.117188 
	C1495.801514,537.522583 1494.823608,539.396790 1493.845703,541.270935 
	C1495.865112,541.576294 1497.883545,541.888550 1499.904419,542.183350 
	C1500.640137,542.290588 1501.426636,542.558838 1502.113647,542.407593 
	C1506.261108,541.493896 1510.386475,540.479736 1514.271729,539.557983 
	C1511.987061,537.949646 1510.166626,536.668152 1508.346313,535.386719 
	C1511.005005,534.897400 1513.682129,533.914917 1516.318726,534.014099 
	C1523.354980,534.278687 1530.373901,535.008179 1538.545898,535.641724 
	C1538.763916,551.112793 1538.984741,566.784119 1539.208496,583.187500 
	C1539.126221,584.990723 1539.040894,586.061829 1538.952637,587.579712 
	C1538.928467,591.558838 1538.907471,595.091187 1538.561523,598.570557 
	C1534.275635,596.937195 1530.314697,595.356873 1526.353760,593.776550 
	C1525.272949,592.314331 1524.364380,589.154602 1525.545776,587.410828 
	C1526.585938,585.875366 1529.962524,585.922852 1532.558838,585.201416 
	C1525.567017,583.082153 1524.201904,583.851990 1523.268555,589.904053 
	C1523.051758,591.308655 1522.697876,593.294189 1521.741943,593.833923 
	C1520.476685,594.548218 1518.394653,594.555420 1516.959595,594.040955 
	C1515.182739,593.403931 1513.709229,591.920837 1511.889404,590.509521 
	C1510.286133,587.609985 1507.509521,584.923706 1512.563599,582.940369 
	C1513.234009,582.677246 1513.215576,580.659058 1513.796631,578.328796 
	C1512.047607,579.796631 1511.375488,580.360718 1510.292725,581.269409 
	C1510.292725,579.807495 1510.292725,579.016541 1510.605225,577.945190 
	C1512.017090,574.388184 1508.696533,569.187805 1514.749512,568.468994 
	C1514.749512,564.457581 1514.749512,560.731812 1514.749512,557.143433 
	C1510.246338,557.143433 1506.167725,557.143433 1501.206543,557.143433 
	C1502.440308,558.884949 1503.215942,559.979980 1504.143555,561.414978 
	C1504.564941,562.686707 1504.834351,563.618408 1504.719971,564.450195 
	C1503.370117,565.633057 1502.404053,566.915833 1500.768311,569.087952 
	C1506.180176,568.999573 1508.095703,570.912781 1507.525757,575.078735 
	C1507.403564,575.971863 1507.994385,576.962463 1508.136719,578.294983 
	C1506.974121,580.107849 1505.931641,581.533936 1505.490234,582.137817 
	C1506.698975,585.095764 1507.989014,588.252686 1508.912109,591.405762 
	C1507.732178,591.664124 1506.918823,591.926331 1505.888794,592.258423 
	C1507.012451,593.299377 1507.790283,594.019958 1509.133667,595.264343 
	C1506.227173,595.264343 1504.258545,595.264343 1502.499512,594.911987 
	C1502.854004,593.023743 1502.999023,591.487793 1503.144043,589.951904 
	C1500.869873,590.270874 1498.595581,590.589905 1496.135010,590.934998 
	C1494.736450,590.153381 1492.845703,589.096619 1490.974976,587.707886 
	C1491.110107,585.771301 1491.225220,584.166809 1491.340210,582.562256 
	C1489.015869,583.140686 1486.691528,583.719116 1484.097046,584.364746 
	C1483.960938,584.026672 1483.563110,583.038818 1483.070801,581.816040 
	C1481.434326,583.321228 1480.041748,584.602173 1478.095459,586.392395 
	C1482.534546,587.351379 1486.287964,588.162170 1490.093140,589.382690 
	C1489.604126,591.792908 1489.063354,593.793518 1488.491455,595.908997 
	C1480.835449,595.908997 1473.459839,595.908997 1466.018555,595.500122 
	C1465.662720,592.567932 1465.372681,590.044617 1465.055908,587.288574 
	C1467.985107,586.883179 1470.318970,586.560120 1472.652832,586.237122 
	C1463.432373,583.548767 1463.390991,583.578552 1463.018555,592.193665 
	C1462.681763,587.862183 1462.344971,583.530762 1462.405762,578.689819 
	C1463.532593,578.592163 1464.261963,579.003906 1465.372925,579.631042 
	C1465.370605,576.609009 1468.250244,572.648926 1462.000000,573.000000 
M1523.275146,547.079285 
	C1518.761963,546.090698 1516.542114,551.442383 1512.522461,551.818298 
	C1512.025024,551.161560 1511.527588,550.504883 1511.030151,549.848206 
	C1512.867920,549.159424 1514.705566,548.470703 1516.543213,547.781921 
	C1508.396484,545.961060 1501.508545,550.358826 1494.454834,546.755676 
	C1494.454834,549.140442 1494.454834,551.074402 1494.454834,551.912292 
	C1496.504150,551.912292 1498.462891,551.313171 1499.657227,552.014954 
	C1505.113403,555.220642 1510.726562,552.799255 1516.249756,552.923828 
	C1517.007812,552.940918 1518.267822,553.049011 1518.453369,552.685303 
	C1520.565796,548.546204 1522.870605,550.686035 1525.338989,552.862671 
	C1525.514526,551.767822 1525.613770,551.148987 1526.457764,550.979675 
	C1527.777222,551.351501 1529.096802,551.723389 1530.644531,552.159485 
	C1530.814087,550.200989 1530.970581,548.394348 1531.197388,545.774597 
	C1529.516968,547.220886 1528.574097,548.032410 1527.800171,548.698547 
	C1526.374756,548.005920 1525.167480,547.419189 1523.275146,547.079285 
M1474.829956,575.898193 
	C1481.426880,575.846680 1481.019653,575.834351 1481.209106,569.576416 
	C1481.333984,565.457520 1479.897095,561.390686 1481.747192,557.051514 
	C1482.869507,554.419006 1481.596680,550.765259 1481.407227,547.690369 
	C1479.776123,549.119202 1478.702759,550.059387 1477.629395,550.999512 
	C1476.658447,549.880066 1475.687500,548.760559 1473.691284,546.459167 
	C1474.298218,549.757141 1474.681885,551.841858 1475.014893,553.983215 
	C1475.014893,553.983215 1474.960938,554.036621 1474.330688,554.581421 
	C1474.240601,556.039612 1474.374634,557.554871 1474.020020,558.945618 
	C1472.752197,563.917358 1476.722412,569.230408 1472.346436,573.707520 
	C1470.566528,574.547913 1468.786621,575.388245 1467.006714,576.228516 
	C1467.536011,577.291687 1468.314209,579.323486 1468.554443,579.261780 
	C1470.188721,578.841858 1471.735352,578.029358 1473.248169,577.226624 
	C1473.606689,577.036499 1473.729126,576.401550 1474.829956,575.898193 
M1480.962769,540.379822 
	C1480.901489,538.776550 1481.412476,536.243469 1480.652344,535.775696 
	C1479.325439,534.959106 1476.125122,534.713074 1475.640869,535.467346 
	C1474.217407,537.684875 1473.741943,540.511169 1472.716675,543.645325 
	C1476.104248,542.393860 1478.219116,541.612549 1480.962769,540.379822 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1508.045898,535.308838 
	C1510.166626,536.668152 1511.987061,537.949646 1514.271729,539.557983 
	C1510.386475,540.479736 1506.261108,541.493896 1502.113647,542.407593 
	C1501.426636,542.558838 1500.640137,542.290588 1499.904419,542.183350 
	C1497.883545,541.888550 1495.865112,541.576294 1493.845703,541.270935 
	C1494.823608,539.396790 1495.801514,537.522583 1497.064941,535.459473 
	C1497.896240,535.246094 1498.442139,535.221741 1499.337036,535.513672 
	C1497.968262,536.839539 1496.250488,537.849121 1494.532715,538.858643 
	C1495.201660,539.670776 1495.870605,540.482910 1496.539551,541.295044 
	C1498.278442,540.225464 1501.469360,539.183899 1501.490601,538.081299 
	C1501.561035,534.424683 1504.031616,535.307800 1505.904663,534.865723 
	C1506.518188,534.987427 1507.131836,535.109192 1508.045898,535.308838 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1524.059082,528.798218 
	C1532.538208,528.380249 1540.954346,528.312988 1549.370361,528.268188 
	C1551.359985,528.257568 1553.862061,527.830444 1551.650635,531.647461 
	C1547.378418,532.048645 1543.738037,532.093994 1540.097656,532.139404 
	C1535.526245,532.008606 1530.944336,532.006226 1526.392700,531.633301 
	C1525.531982,531.562805 1524.791870,530.020325 1524.059082,528.798218 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1539.823120,583.266663 
	C1539.878296,567.140930 1539.933472,551.015259 1540.244385,534.428223 
	C1540.500000,533.966858 1540.997070,534.019409 1540.999878,534.486328 
	C1541.306274,548.923645 1541.609863,562.894104 1541.935059,577.267334 
	C1541.245483,579.535706 1540.534302,581.401184 1539.823120,583.266663 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1539.763428,583.318481 
	C1540.534302,581.401184 1541.245483,579.535706 1542.169678,577.350830 
	C1542.382812,577.031433 1542.868774,577.134583 1542.862305,577.526611 
	C1543.908691,580.301819 1544.961792,582.684998 1545.990723,585.256653 
	C1545.966553,585.445129 1546.022949,585.821045 1545.698242,585.803955 
	C1544.635986,586.557922 1543.898315,587.328918 1542.914795,588.039185 
	C1542.501343,587.960510 1542.333862,587.942566 1541.945190,587.643555 
	C1540.801270,587.286011 1539.878418,587.209534 1538.955688,587.132996 
	C1539.040894,586.061829 1539.126221,584.990723 1539.378906,583.654602 
	C1539.546509,583.389648 1539.703613,583.370361 1539.763428,583.318481 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1540.997070,534.019409 
	C1540.997070,534.019409 1540.500000,533.966858 1540.250244,533.956055 
	C1540.014160,533.543274 1540.027588,533.141296 1540.069336,532.439331 
	C1543.738037,532.093994 1547.378418,532.048645 1551.471924,531.976685 
	C1552.865356,532.164612 1553.805664,532.379028 1554.649902,533.198486 
	C1550.776733,533.979492 1546.999756,534.155579 1542.983887,534.195496 
	C1542.162231,534.046021 1541.579712,534.032715 1540.997070,534.019409 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1481.501953,529.961914 
	C1481.818481,529.635132 1482.594849,529.315125 1483.677734,528.987488 
	C1487.607178,528.992676 1491.239868,528.860657 1494.848145,529.098755 
	C1496.075684,529.179749 1497.241821,530.192993 1498.044922,531.794312 
	C1496.146606,531.794312 1494.248413,531.794312 1491.796265,531.794312 
	C1492.724243,531.228210 1493.301270,530.876160 1494.788940,529.968628 
	C1489.940430,529.968628 1485.951050,529.968628 1481.501953,529.961914 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1505.875244,534.521362 
	C1504.031616,535.307800 1501.561035,534.424683 1501.490601,538.081299 
	C1501.469360,539.183899 1498.278442,540.225464 1496.539551,541.295044 
	C1495.870605,540.482910 1495.201660,539.670776 1494.532715,538.858643 
	C1496.250488,537.849121 1497.968262,536.839539 1499.612061,535.503052 
	C1501.640625,534.843018 1503.743164,534.510010 1505.875244,534.521362 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M51.722496,252.574554 
	C48.154617,255.185959 45.065750,258.621735 41.347923,259.556793 
	C30.742941,262.224091 23.167643,257.377991 18.677881,248.134384 
	C14.689650,239.923340 18.787735,229.740036 27.306217,224.751968 
	C33.636295,221.045334 43.245068,222.215286 49.027752,227.396774 
	C55.093273,232.831711 57.045422,241.667496 53.719742,248.707275 
	C53.152950,249.907059 52.517841,251.074570 51.722496,252.574554 
M32.963940,243.379883 
	C34.281086,249.141388 39.984379,250.995026 44.261520,247.051743 
	C47.382133,244.174713 48.323498,233.661179 45.494026,227.625320 
	C45.030857,231.098175 45.070843,234.591537 45.018482,238.083527 
	C44.974865,240.992447 45.848656,244.447052 42.159252,245.820480 
	C38.926163,247.024017 35.965324,246.071289 32.963940,243.379883 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1543.222778,534.331665 
	C1546.999756,534.155579 1550.776733,533.979492 1554.853882,533.504517 
	C1555.154053,533.205688 1555.571045,533.082825 1556.262451,533.240173 
	C1557.556152,533.764282 1558.158325,534.131042 1558.385986,534.706299 
	C1556.925049,536.785156 1555.676147,538.592834 1554.906982,540.585815 
	C1554.773804,540.931091 1556.757568,542.775757 1557.615112,542.674316 
	C1559.247437,542.481018 1560.772705,541.382812 1562.342529,540.660339 
	C1562.187012,540.294189 1562.031494,539.928040 1561.876099,539.561890 
	C1560.292725,539.759583 1558.709473,539.957275 1557.126221,540.154907 
	C1556.959717,539.756165 1556.793213,539.357422 1556.626831,538.958618 
	C1557.728760,538.223877 1558.927612,537.587463 1559.854980,536.675354 
	C1560.051880,536.481689 1559.259644,535.282227 1559.204590,534.162109 
	C1559.658936,533.511597 1559.831787,533.245300 1560.004517,532.979065 
	C1561.305176,533.382507 1562.605835,533.785950 1563.639893,534.701904 
	C1563.268311,535.806213 1563.163208,536.398071 1563.020264,537.367065 
	C1563.001709,538.498718 1563.020874,539.253113 1562.986816,540.364014 
	C1565.950928,543.622803 1568.242676,542.820190 1570.306396,538.175049 
	C1570.343872,538.198486 1570.432495,538.195618 1570.689209,537.954712 
	C1569.688965,536.717896 1568.432129,535.721985 1567.175293,534.726074 
	C1568.622803,534.162354 1570.075806,533.095947 1571.516846,533.111938 
	C1587.033447,533.285156 1602.548096,533.628906 1618.525513,534.420166 
	C1618.987671,536.788208 1618.987671,538.661926 1618.987671,540.535706 
	C1619.350586,540.572083 1619.713623,540.608398 1620.076538,540.644775 
	C1620.702148,539.766663 1621.327881,538.888611 1621.994141,538.474976 
	C1622.303711,548.948730 1622.572632,558.958069 1622.539307,568.975647 
	C1621.832764,568.987244 1621.428345,568.990662 1621.003296,568.540405 
	C1620.990845,565.052368 1620.999146,562.017944 1621.011230,558.518188 
	C1621.015137,553.224243 1621.015137,548.395691 1621.015137,543.567200 
	C1620.248657,543.610962 1619.482056,543.654724 1618.715576,543.698486 
	C1619.095825,548.774292 1619.476074,553.850098 1619.890747,559.398010 
	C1619.882446,570.917053 1619.839844,581.963928 1619.677002,593.083130 
	C1619.333984,593.379456 1619.323853,593.618530 1619.880615,593.993958 
	C1620.482300,594.079895 1620.729614,594.044495 1620.986572,594.008667 
	C1620.996338,594.008240 1620.990967,594.031738 1620.894287,594.131348 
	C1620.797485,594.231018 1620.931885,594.474121 1620.464844,594.459900 
	C1604.066406,594.445679 1588.135010,594.445679 1571.309326,594.445679 
	C1576.923462,587.241638 1585.117676,591.670166 1591.653198,589.293762 
	C1586.172241,588.469604 1581.197876,587.721619 1576.200195,586.580933 
	C1576.907959,584.619812 1577.638916,583.051514 1578.137939,581.980957 
	C1577.359985,580.725952 1575.699097,579.265259 1575.732788,577.844727 
	C1575.801025,574.969116 1576.787842,572.115295 1577.779907,569.243652 
	C1579.272217,568.307983 1581.308960,567.393494 1581.321289,566.452148 
	C1581.365601,563.093994 1580.758545,559.721497 1580.261108,556.374634 
	C1580.226440,556.141724 1579.082153,556.021790 1578.442383,555.938416 
	C1568.587769,554.654175 1568.074707,555.490479 1567.801880,565.269958 
	C1567.683472,569.515869 1571.478516,568.829956 1572.503662,571.389404 
	C1572.881104,576.827148 1573.448486,581.914185 1573.668579,587.103760 
	C1572.515991,587.942993 1571.522217,588.556763 1570.944214,589.441956 
	C1569.708374,591.334717 1568.687744,593.368042 1567.578857,595.343872 
	C1567.036133,595.108826 1566.493408,594.873779 1565.950684,594.638794 
	C1565.950684,583.835571 1565.950684,573.032410 1565.950684,562.229248 
	C1565.608887,562.229980 1565.267090,562.230774 1564.925293,562.231506 
	C1564.925293,573.100281 1564.925293,583.969055 1564.925293,594.350769 
	C1558.972412,595.222717 1553.611084,596.007935 1548.325195,596.782227 
	C1547.825806,602.535706 1546.344971,603.774292 1543.023926,601.500977 
	C1543.000000,601.502075 1543.043579,601.522644 1543.065918,601.013672 
	C1543.112427,596.369812 1543.136597,592.234863 1543.160645,588.099915 
	C1543.898315,587.328918 1544.635986,586.557922 1545.781006,586.169556 
	C1548.943115,589.498596 1550.950317,587.888428 1552.118896,585.179016 
	C1552.495483,584.305603 1551.125854,582.678894 1550.555054,581.396729 
	C1549.041626,582.620483 1547.528198,583.844299 1546.014893,585.068115 
	C1544.961792,582.684998 1543.908691,580.301819 1542.964111,577.055664 
	C1543.122681,562.239014 1543.172729,548.285339 1543.222778,534.331665 
M1593.491699,550.411682 
	C1595.105713,550.798401 1596.719849,551.185181 1598.426758,551.594177 
	C1598.426758,549.790527 1598.426758,547.766418 1598.426758,545.742371 
	C1596.176758,550.820007 1592.567139,546.083801 1589.575317,546.613037 
	C1587.895752,546.138977 1586.216187,545.664856 1584.130127,545.076050 
	C1584.678223,547.862854 1585.031494,549.659180 1585.384766,551.455505 
	C1585.602051,550.941467 1585.819214,550.427429 1586.036499,549.913391 
	C1588.037598,550.460388 1590.038818,551.007324 1591.954224,551.530945 
	C1592.119019,551.291138 1592.558105,550.652344 1593.491699,550.411682 
M1568.640015,551.919617 
	C1572.728394,554.448669 1580.626221,553.693359 1582.862427,550.420166 
	C1581.275391,550.686401 1579.794922,550.934753 1578.314453,551.183105 
	C1577.857910,550.613281 1577.401245,550.043457 1576.944702,549.473633 
	C1578.792480,548.862732 1580.640259,548.251831 1582.488037,547.640930 
	C1581.950073,546.977905 1581.412231,546.314941 1580.874390,545.651917 
	C1579.009155,546.623047 1577.143921,547.594177 1575.251953,548.579224 
	C1572.394409,544.258850 1571.383301,544.284180 1567.928955,548.263489 
	C1567.210083,547.761475 1566.187500,546.674133 1565.826172,546.861511 
	C1564.463745,547.567993 1563.316406,548.689148 1563.109253,548.852051 
	C1560.695923,547.403259 1559.253540,546.276550 1557.617065,545.708801 
	C1557.104248,545.530945 1556.069336,546.858276 1555.273315,547.496704 
	C1556.598755,548.578796 1557.924072,549.660889 1559.340820,550.817566 
	C1558.217285,551.219604 1556.776123,551.735229 1555.335083,552.250916 
	C1555.407227,552.479980 1555.479248,552.709106 1555.551392,552.938171 
	C1559.677734,552.539429 1563.804199,552.140686 1568.640015,551.919617 
M1611.144653,587.458679 
	C1609.199707,582.018860 1606.686768,586.462036 1604.519409,587.231873 
	C1603.997437,586.778259 1603.623413,586.459534 1603.256226,586.133240 
	C1599.782227,583.045532 1597.552368,586.483765 1595.071167,587.898865 
	C1594.622681,588.154785 1594.660034,590.531982 1595.265381,591.136108 
	C1597.352783,593.219666 1599.717041,593.679688 1602.219116,591.365051 
	C1602.847778,590.783630 1604.529419,590.536072 1605.219116,590.960449 
	C1608.717407,593.112976 1609.872070,590.770996 1611.144653,587.458679 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1618.008545,527.944885 
	C1626.610718,527.946350 1634.714966,527.946350 1642.819214,527.946350 
	C1642.812622,528.854736 1642.806152,529.763123 1642.799683,530.671509 
	C1629.985718,531.215820 1617.155640,530.810242 1603.350830,530.976318 
	C1606.016724,528.087585 1606.016724,528.087585 1618.008545,527.944885 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1560.220703,532.685547 
	C1559.831787,533.245300 1559.658936,533.511597 1559.190186,534.138428 
	C1558.894165,534.498962 1558.760498,534.497864 1558.760498,534.497864 
	C1558.158325,534.131042 1557.556152,533.764282 1556.471191,533.179688 
	C1556.075562,531.508911 1556.162476,530.055908 1556.267334,528.303589 
	C1563.695190,528.303589 1570.843262,528.303589 1577.991455,528.303589 
	C1577.998291,528.546143 1578.005127,528.788635 1578.011963,529.031189 
	C1574.579590,529.031189 1571.129028,529.250488 1567.720093,528.967773 
	C1564.492432,528.700073 1561.986450,529.373962 1560.220703,532.685547 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1621.953491,538.010620 
	C1621.327881,538.888611 1620.702148,539.766663 1620.076538,540.644775 
	C1619.713623,540.608398 1619.350586,540.572083 1618.987671,540.535706 
	C1618.987671,538.661926 1618.987671,536.788208 1618.986084,534.502197 
	C1620.379028,534.066711 1621.773682,534.043335 1623.587646,534.029541 
	C1624.004517,534.485962 1624.001831,534.932739 1623.664551,535.731934 
	C1622.871094,536.726318 1622.412354,537.368469 1621.953491,538.010620 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1563.058228,536.989868 
	C1563.163208,536.398071 1563.268311,535.806213 1563.647583,535.064575 
	C1564.741577,534.923279 1565.561523,534.931824 1566.778320,534.833252 
	C1568.432129,535.721985 1569.688965,536.717896 1570.456787,537.642456 
	C1567.664551,537.377441 1565.361450,537.183655 1563.058228,536.989868 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M853.444214,539.771790 
	C853.100952,540.168274 852.822998,540.324158 852.545044,540.480103 
	C852.460327,540.188416 852.375671,539.896790 852.290955,539.605103 
	C852.697144,539.580444 853.103333,539.555847 853.444214,539.771790 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2463.927979,91.084000 
	C2461.611084,91.068321 2459.269775,91.241966 2456.988037,90.950348 
	C2455.975830,90.820992 2455.083252,89.751747 2454.136475,89.109207 
	C2454.996582,88.118454 2455.717285,86.476181 2456.742432,86.256790 
	C2460.063477,85.546211 2463.099609,86.062744 2463.972656,90.602188 
	C2464.000732,91.004547 2463.927979,91.084000 2463.927979,91.084000 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2464.040527,90.968124 
	C2464.188965,91.129616 2464.297607,91.327538 2464.406250,91.525436 
	C2464.293945,91.480820 2464.181396,91.436226 2463.998535,91.237808 
	C2463.927979,91.084000 2464.000732,91.004547 2464.040527,90.968124 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M2500.852539,719.852539 
	C2495.750488,706.538452 2501.277832,695.184509 2514.695557,692.375183 
	C2523.212402,690.591919 2537.418457,697.682373 2537.329346,711.289368 
	C2537.265137,721.051392 2530.002930,727.304871 2523.996582,729.014771 
	C2513.961914,731.871216 2505.345459,728.327881 2500.852539,719.852539 
M2525.126221,711.239685 
	C2525.121582,708.414001 2525.786865,705.356628 2524.913086,702.831787 
	C2524.133545,700.578979 2521.982910,697.978943 2519.841309,697.225098 
	C2517.099365,696.259827 2513.760010,696.990723 2510.763184,696.990723 
	C2514.516846,704.558472 2513.486572,711.675354 2511.705566,718.858826 
	C2519.296875,721.455566 2524.164062,719.046326 2525.126221,711.239685 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1102.984741,114.984810 
	C1105.597900,119.954529 1105.597900,119.954529 1113.175537,115.329247 
	C1114.090820,119.661499 1109.731812,126.364082 1103.722900,128.461456 
	C1095.728271,131.251984 1085.777832,129.744492 1080.298706,122.548210 
	C1074.911377,115.472282 1074.856323,103.697739 1080.340820,98.126877 
	C1087.556030,90.798157 1097.256836,87.679947 1107.374756,95.198883 
	C1100.377930,99.585991 1099.184814,104.225632 1102.698975,114.775551 
	C1103.000000,114.999695 1102.984741,114.984810 1102.984741,114.984810 
M1094.059204,114.792458 
	C1093.805664,108.876747 1093.552124,102.961044 1093.298584,97.045334 
	C1092.709351,96.939041 1092.119995,96.832748 1091.530762,96.726456 
	C1090.389038,97.701469 1089.247314,98.676483 1088.878784,98.991188 
	C1092.497192,105.753471 1091.395386,111.828537 1089.521606,118.950500 
	C1091.827881,118.584358 1093.743408,118.280266 1095.658936,117.976158 
	C1095.246216,117.168144 1094.833496,116.360130 1094.059204,114.792458 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1103.007080,115.007462 
	C1103.023926,111.755775 1102.893799,108.487442 1103.099976,105.240456 
	C1103.209839,103.508797 1103.546875,101.587975 1104.447510,100.177979 
	C1105.104126,99.150017 1107.134766,98.034485 1108.040649,98.364365 
	C1109.298218,98.822388 1110.684326,100.487045 1110.929443,101.832970 
	C1111.399170,104.411247 1111.151733,107.129486 1111.116577,109.788521 
	C1111.038940,115.666901 1109.319092,116.864891 1103.374268,114.984116 
	C1102.984741,114.984810 1103.000000,114.999695 1103.007080,115.007462 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1113.908691,111.576523 
	C1113.895508,110.130608 1113.895508,109.047211 1113.895508,107.963814 
	C1114.201416,107.956024 1114.507324,107.948235 1114.813232,107.940437 
	C1114.813232,109.541809 1114.820190,111.143272 1114.799683,112.744377 
	C1114.797974,112.868530 1114.603638,112.990227 1114.498901,113.113068 
	C1114.306519,112.721733 1114.114136,112.330383 1113.908691,111.576523 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M113.072037,689.393555 
	C113.546257,688.368286 113.990089,687.819031 114.677551,687.580566 
	C114.921188,695.133545 114.921188,702.375732 114.921188,710.324158 
	C179.516785,709.559753 243.208572,708.806091 306.903046,708.384766 
	C306.280243,709.744263 305.654785,710.771423 304.911682,711.991699 
	C311.316650,711.991699 317.429199,711.999390 323.541748,711.988770 
	C327.172333,711.982483 328.929749,710.101562 328.928772,706.512878 
	C328.928284,704.695374 328.911621,702.877930 329.276672,701.106445 
	C330.124207,702.234070 330.597351,703.315674 331.070496,704.397339 
	C330.750732,705.147766 330.238892,705.874084 330.141083,706.652344 
	C329.215424,714.018250 329.235291,713.983521 322.033875,714.065979 
	C293.504303,714.392639 264.974792,714.729126 236.445740,715.096130 
	C227.186996,715.215271 217.929596,715.438904 208.074554,715.038940 
	C201.266373,714.268433 195.055222,714.073608 188.817719,713.479614 
	C188.289017,712.722046 187.790131,712.057190 187.283768,712.051514 
	C180.287430,711.972107 173.288849,711.921570 166.294754,712.060486 
	C165.530304,712.075684 164.790009,713.307739 163.567596,713.989746 
	C151.354492,714.005615 139.611252,713.931763 127.871323,714.096191 
	C126.243530,714.119019 124.632210,715.318298 123.013268,715.973022 
	C121.037086,716.029419 119.060913,716.085876 116.675293,716.211304 
	C115.665314,715.823853 115.064766,715.367371 114.070419,714.842651 
	C113.464966,706.472839 113.253311,698.171204 113.072037,689.393555 
M250.438416,710.960754 
	C244.960007,711.088806 239.481598,711.216797 234.003189,711.344849 
	C234.029190,711.732605 234.055206,712.120422 234.081207,712.508179 
	C242.494003,712.508179 250.906784,712.508179 259.319580,712.508179 
	C259.341339,711.991150 259.363098,711.474182 259.384827,710.957153 
	C256.726746,710.957153 254.068634,710.957153 250.438416,710.960754 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M328.289001,948.211182 
	C327.773041,945.288818 328.317810,943.622742 331.735107,943.766968 
	C335.522217,943.926880 339.338623,943.262329 343.140137,943.287231 
	C344.360901,943.295166 346.608459,944.313171 346.631989,944.935486 
	C346.868256,951.181030 346.738678,957.440491 346.355743,963.734131 
	C337.631073,961.558533 331.637054,956.646912 328.289001,948.211182 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M325.426361,944.088135 
	C325.852173,945.208618 325.825287,946.255920 325.779633,947.700928 
	C322.245300,954.982117 318.603333,961.740295 310.060059,963.485168 
	C309.598450,963.579468 309.100800,963.497375 308.134949,963.497375 
	C307.826447,957.189636 307.518829,950.900391 307.182068,944.014954 
	C313.431274,944.014954 319.202454,944.014954 325.426361,944.088135 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M305.260254,941.314270 
	C304.990356,940.275269 304.995361,939.516602 305.227661,938.208984 
	C304.977081,937.105408 304.499268,936.550720 304.021423,935.996094 
	C304.021423,935.996094 303.666931,935.977051 303.409607,935.603149 
	C303.106384,934.475769 303.060516,933.722412 303.064331,932.560669 
	C303.413483,929.702087 303.554565,927.218567 304.061707,924.812134 
	C304.477783,922.837891 305.329315,920.955505 306.295441,919.088623 
	C307.086365,919.765320 307.569611,920.385803 307.759247,921.299316 
	C307.341766,924.878357 307.422668,928.188232 307.046387,931.445190 
	C306.472443,936.413086 308.285522,938.146118 313.397278,937.921265 
	C325.248962,937.399963 337.124237,937.414185 349.016052,937.486084 
	C349.072998,937.942200 349.103760,938.123596 349.134552,938.305054 
	C349.204987,940.950989 348.089966,942.050354 345.284454,941.972839 
	C339.178040,941.804077 333.063110,941.944519 326.487305,942.010864 
	C324.300720,942.041321 322.578522,942.019531 320.160522,941.711670 
	C316.985291,941.598938 314.505798,941.772278 312.026337,941.945679 
	C309.862610,941.828674 307.698883,941.711609 305.260254,941.314270 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M312.265961,942.255920 
	C314.505798,941.772278 316.985291,941.598938 319.730286,941.708740 
	C317.499054,942.183350 315.002319,942.374756 312.265961,942.255920 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1799.502563,91.145203 
	C1801.426636,92.135536 1802.997559,92.959305 1805.539795,94.292358 
	C1802.978394,96.126160 1801.033081,97.518967 1799.087646,98.911774 
	C1799.270508,99.540344 1799.453369,100.168907 1799.636230,100.797478 
	C1804.019165,97.054604 1808.183960,94.405952 1811.447632,100.972313 
	C1813.718872,105.541840 1808.735596,104.772873 1807.796509,106.102913 
	C1809.622925,108.237610 1812.922241,110.509377 1812.600342,111.998077 
	C1811.355103,117.756065 1805.797852,113.685318 1802.233398,116.802711 
	C1804.941772,117.361290 1807.650146,117.919876 1810.353882,118.477509 
	C1807.812744,126.188370 1797.059448,131.222778 1786.208496,127.845451 
	C1777.408569,125.106483 1771.835205,113.509895 1774.768799,104.134125 
	C1778.079224,93.553223 1788.762085,87.788872 1799.502563,91.145203 
M1789.003174,108.466301 
	C1788.611938,111.410744 1788.220703,114.355179 1787.761841,117.809608 
	C1789.090210,117.731125 1791.452148,117.591576 1793.667847,117.460655 
	C1788.358765,110.574501 1792.531250,102.701004 1789.854614,95.245682 
	C1788.244263,96.684769 1787.132812,97.677986 1786.021362,98.671204 
	C1786.572021,98.731842 1787.122681,98.792488 1787.673340,98.853127 
	C1788.110840,101.746101 1788.548340,104.639076 1789.003174,108.466301 
M1801.231689,113.854630 
	C1801.225220,113.637093 1801.235107,113.416298 1801.193726,113.205635 
	C1801.187622,113.175415 1800.933838,113.193962 1800.794800,113.189934 
	C1800.800903,113.406296 1800.806885,113.622650 1801.231689,113.854630 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M374.300720,937.127136 
	C374.296967,940.195312 372.973083,941.468018 369.720459,941.256653 
	C365.587555,940.987976 361.388580,941.554443 357.282532,941.132996 
	C355.331055,940.932678 353.528839,939.278320 351.332947,937.867065 
	C351.006866,937.451416 351.008911,937.036560 351.158356,936.800476 
	C352.560608,931.325134 353.813446,926.085815 355.206055,920.261902 
	C356.300049,920.204224 358.083191,920.054932 359.868286,920.025146 
	C369.904236,919.857910 369.904388,919.867737 369.668091,930.296997 
	C369.389282,932.605103 369.389282,934.635437 369.389282,936.750488 
	C371.362976,936.901855 372.831848,937.014526 374.300720,937.127136 
M363.798096,936.737000 
	C364.294189,936.600342 364.790314,936.463684 365.286407,936.327026 
	C365.004578,936.004456 364.722778,935.681946 364.440918,935.359375 
	C364.096039,935.685242 363.751160,936.011108 363.798096,936.737000 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M372.236725,947.156738 
	C372.232849,952.593445 372.232849,957.544556 372.232849,962.525452 
	C361.889099,961.760010 353.805450,953.373413 354.391418,943.777710 
	C359.436432,943.777710 364.555328,943.637451 369.652405,943.907898 
	C370.561432,943.956116 371.380157,945.706665 372.236725,947.156738 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M376.875427,898.121704 
	C377.375092,898.090210 377.874817,898.058777 378.749573,898.073547 
	C379.124603,910.903503 379.124603,923.687256 379.124603,936.706055 
	C378.179108,936.706055 376.833130,936.706055 375.237549,936.399170 
	C374.618866,933.546631 374.249786,931.000977 373.876465,928.294922 
	C373.864319,927.904968 373.881409,927.677124 374.185242,927.100159 
	C374.609955,924.187988 374.772949,921.626770 375.182373,918.903320 
	C375.258942,918.107056 375.089050,917.473022 374.936157,916.524780 
	C374.942596,915.581543 374.932037,914.952454 375.131653,914.144348 
	C375.191406,913.534668 375.040985,913.104065 374.890564,912.673462 
	C374.920227,909.411743 374.949860,906.150024 375.256256,902.256226 
	C375.980469,900.456665 376.427948,899.289185 376.875427,898.121704 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M373.880707,928.455261 
	C374.249786,931.000977 374.618866,933.546631 374.981262,936.528625 
	C374.974609,936.964905 374.644745,937.083984 374.472717,937.105591 
	C372.831848,937.014526 371.362976,936.901855 369.389282,936.750488 
	C369.389282,934.635437 369.389282,932.605103 369.999573,930.167114 
	C371.700165,929.324707 372.790436,928.889954 373.880707,928.455261 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M387.624573,937.949524 
	C388.381744,938.672485 388.675629,939.452393 388.907867,940.556946 
	C386.085876,941.230774 383.325928,941.813782 380.565002,941.818604 
	C379.395599,941.820618 378.224060,940.607849 377.053497,939.947144 
	C378.122864,939.320068 379.139984,938.299011 380.272614,938.148865 
	C382.535339,937.848816 384.861359,938.026306 387.624573,937.949524 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M351.008911,937.036560 
	C351.008911,937.036560 351.006866,937.451416 351.006836,937.658142 
	C350.589966,937.960632 350.173126,938.056396 349.445435,938.228638 
	C349.103760,938.123596 349.072998,937.942200 349.348083,937.415710 
	C350.105621,937.059265 350.557251,937.047913 351.008911,937.036560 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1095.500244,370.181213 
	C1096.120239,367.736053 1097.119751,365.363556 1098.491699,362.934906 
	C1098.864014,369.374268 1098.864014,375.869843 1098.864014,382.365387 
	C1098.467041,382.395691 1098.070190,382.425995 1097.673218,382.456299 
	C1097.075439,378.388824 1096.477661,374.321350 1095.500244,370.181213 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1098.623291,386.973633 
	C1098.807861,389.890259 1098.807861,392.468262 1098.807861,395.046295 
	C1098.251953,394.995483 1097.696045,394.944672 1097.140137,394.893860 
	C1097.572998,392.140900 1098.005859,389.387970 1098.623291,386.973633 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1098.942383,404.194275 
	C1098.943237,406.042389 1098.943237,407.487579 1098.943237,408.932800 
	C1098.710815,408.926697 1098.478516,408.920593 1098.246094,408.914490 
	C1098.246094,406.266693 1098.246094,403.618866 1098.246094,400.971039 
	C1098.412842,400.963593 1098.579590,400.956177 1098.746460,400.948730 
	C1098.811523,401.896271 1098.876587,402.843811 1098.942383,404.194275 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1096.652832,359.970276 
	C1096.738525,360.503021 1096.518311,361.040192 1096.298096,361.577332 
	C1095.903198,361.230072 1095.508179,360.882843 1095.120972,360.223694 
	C1095.534790,359.932800 1095.941040,359.953766 1096.652832,359.970276 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1097.808716,415.022675 
	C1097.976440,415.101959 1097.978516,415.300995 1097.977051,415.799561 
	C1097.863525,415.780243 1097.753418,415.461334 1097.808716,415.022675 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1097.945312,359.706055 
	C1097.875366,359.893219 1097.696167,359.916534 1097.246094,359.948639 
	C1097.262207,359.819031 1097.549316,359.680664 1097.945312,359.706055 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2086.855469,1221.423706 
	C2082.105225,1223.382568 2077.381104,1225.517578 2072.590332,1221.157104 
	C2072.196533,1217.592529 2072.094727,1214.191772 2072.079102,1210.397461 
	C2075.458740,1207.316772 2079.091553,1205.978760 2083.243164,1207.689697 
	C2087.894775,1209.606812 2089.006836,1213.875244 2086.855469,1221.423706 
M2081.868164,1220.893799 
	C2082.800293,1219.396851 2084.717773,1217.693115 2084.456543,1216.446533 
	C2083.936768,1213.968506 2082.485840,1211.512207 2080.817139,1209.562988 
	C2080.364258,1209.034180 2076.609375,1210.015137 2076.354980,1210.884155 
	C2075.620850,1213.391968 2075.347900,1216.347900 2076.027100,1218.815918 
	C2076.337646,1219.944214 2079.307129,1220.340942 2081.868164,1220.893799 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2123.005859,1221.010498 
	C2122.315430,1218.934448 2121.627686,1216.843384 2120.843994,1214.459473 
	C2121.126221,1214.362183 2122.122314,1214.324585 2122.523193,1213.816772 
	C2123.344482,1212.776733 2123.846436,1211.484497 2124.479980,1210.296143 
	C2123.153320,1209.816650 2121.758301,1208.842041 2120.520996,1209.012207 
	C2119.503906,1209.151855 2118.011963,1210.566406 2117.885010,1211.562866 
	C2117.516113,1214.459351 2117.845947,1217.437988 2117.638916,1220.365723 
	C2117.576172,1221.254395 2116.657227,1222.082520 2116.130127,1222.938354 
	C2115.472656,1222.186646 2114.280273,1221.458252 2114.246582,1220.679443 
	C2114.060791,1216.387573 2114.157715,1212.083496 2114.157715,1206.961792 
	C2116.710693,1206.961792 2119.063965,1206.541016 2121.179443,1207.070679 
	C2123.690918,1207.699585 2125.997803,1209.146118 2128.393311,1210.238159 
	C2127.451416,1212.115479 2126.509766,1213.992676 2125.330078,1216.343750 
	C2126.258057,1217.814087 2127.611084,1219.958008 2128.963867,1222.102051 
	C2128.690674,1222.461792 2128.417725,1222.821411 2128.144531,1223.181152 
	C2126.646973,1222.523315 2125.149170,1221.865479 2123.315186,1221.098267 
	C2122.978760,1220.988892 2123.008545,1220.995605 2123.005859,1221.010498 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2101.670898,1206.987427 
	C2108.310791,1208.989502 2110.598633,1212.917969 2108.837402,1218.601562 
	C2107.786621,1221.992432 2101.697754,1224.403931 2097.273926,1222.840698 
	C2093.253662,1221.420288 2091.998291,1217.989868 2092.323730,1214.051392 
	C2092.716309,1209.301147 2095.543701,1207.182495 2101.670898,1206.987427 
M2105.550537,1216.939941 
	C2105.879639,1211.005615 2103.466064,1207.600464 2099.814209,1209.294922 
	C2098.051270,1210.113281 2096.624756,1212.719482 2096.060059,1214.792358 
	C2095.324951,1217.490601 2096.227051,1220.195190 2099.497803,1220.969482 
	C2102.315674,1221.636597 2104.383545,1220.613525 2105.550537,1216.939941 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M961.033447,89.945419 
	C971.087891,90.497749 978.648499,97.750732 979.937500,107.667206 
	C980.932251,115.320290 974.680603,124.637405 966.766296,127.296791 
	C956.229248,130.837463 946.176819,125.540756 942.663696,114.597038 
	C939.494629,104.725021 945.089172,94.202080 955.275757,91.015877 
	C956.995300,90.478027 958.825745,90.294647 961.033447,89.945419 
M963.730042,107.141167 
	C964.073669,108.169395 965.014526,109.760834 964.663025,110.133553 
	C962.526062,112.399673 960.094788,114.388298 957.761780,116.469521 
	C959.564697,116.685989 961.378662,116.371017 962.815247,115.460556 
	C968.002563,112.173149 968.545654,107.093842 968.115601,101.505997 
	C967.811829,97.560349 966.142822,95.253624 962.183411,94.919479 
	C958.144043,94.578598 954.452454,94.888786 953.452942,100.012794 
	C952.738037,103.677673 954.610962,106.548378 958.440063,107.004829 
	C959.916687,107.180862 961.428223,107.065193 963.730042,107.141167 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M22.854109,370.959595 
	C23.392859,358.888672 30.902086,351.660767 42.253761,351.844757 
	C51.581871,351.995972 59.668583,360.492126 60.226917,369.810303 
	C60.929523,381.536194 52.153660,389.467163 42.192627,390.000580 
	C32.060543,390.543152 23.682230,382.480377 22.854109,370.959595 
M39.968483,359.183411 
	C39.790520,365.299774 41.417900,371.548615 38.625263,377.507660 
	C40.593338,377.507660 42.541008,377.507660 45.019962,377.507660 
	C40.937675,373.726166 40.928505,360.516846 45.009773,355.783997 
	C42.097965,355.783997 39.746792,355.783997 36.086517,355.783997 
	C37.907993,357.195221 38.725822,357.828827 39.968483,359.183411 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M46.561295,731.916504 
	C30.964056,731.859680 22.066963,719.321289 27.076080,705.079041 
	C30.731209,694.686523 45.201557,690.413086 53.985313,697.976379 
	C49.905331,697.976379 46.519218,698.110657 43.149117,697.936035 
	C40.177387,697.782104 38.908257,698.803650 39.043415,701.914795 
	C39.245495,706.566284 39.211025,711.233093 39.124081,715.890503 
	C39.067684,718.911438 40.386551,720.070312 43.352283,719.939209 
	C46.686012,719.791931 50.031269,719.905396 53.371506,719.905396 
	C53.374886,719.389221 53.378262,718.873108 53.381638,718.356934 
	C49.897991,718.356934 46.414345,718.356934 42.288982,718.356934 
	C42.150860,715.784607 42.041920,713.755737 41.928455,711.642578 
	C44.059437,710.981384 45.835743,710.430298 47.612049,709.879150 
	C47.611271,709.438477 47.610493,708.997864 47.609715,708.557251 
	C45.993004,708.258362 44.376293,707.959412 42.345894,707.583984 
	C41.928234,705.380737 41.482944,703.031677 41.007710,700.524719 
	C46.128265,699.985718 50.835819,699.278503 55.565388,699.069397 
	C58.543114,698.937744 62.561939,705.847473 63.109150,711.002136 
	C64.024506,719.624756 58.399590,729.280518 46.561295,731.916504 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2035.119873,98.534210 
	C2035.478394,98.032867 2035.727539,97.848656 2036.043457,97.391586 
	C2037.497437,96.049065 2038.831909,94.907829 2040.399292,93.567513 
	C2040.545288,96.374466 2042.876831,99.716087 2038.718018,101.656509 
	C2036.884155,102.512138 2034.562988,102.132874 2035.119873,98.534210 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1567.408081,114.421143 
	C1570.628906,115.357887 1573.640259,115.924644 1576.647461,116.490608 
	C1576.205933,120.888435 1571.597290,124.913399 1566.226074,126.027687 
	C1554.542603,128.451447 1547.642822,122.670761 1544.536377,116.983261 
	C1540.183472,109.013916 1542.114380,98.482399 1548.292969,93.187027 
	C1554.725098,87.674408 1564.520996,87.334534 1572.337769,92.396690 
	C1570.476196,94.090645 1568.629150,95.771538 1566.781982,97.452423 
	C1567.106567,97.864479 1567.431152,98.276543 1567.755615,98.688599 
	C1568.805786,97.977577 1569.773560,97.066528 1570.921875,96.594490 
	C1573.897217,95.371445 1576.682861,94.852676 1578.219482,98.857018 
	C1579.323242,101.733528 1578.349365,102.796890 1575.518433,103.160866 
	C1567.990112,104.128860 1566.611328,106.002586 1567.408081,114.421143 
M1557.585693,93.588539 
	C1556.083130,94.524986 1554.580688,95.461441 1553.634399,96.051186 
	C1557.637329,102.970551 1556.406982,109.087044 1554.214111,115.918518 
	C1556.831787,115.689293 1559.076050,115.492760 1560.593994,115.359840 
	C1559.898193,112.542839 1559.112183,110.493980 1558.929810,108.392700 
	C1558.515869,103.626595 1558.399170,98.834679 1557.585693,93.588539 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1574.893311,113.111099 
	C1572.830200,113.035652 1571.215820,112.963516 1569.448364,112.884537 
	C1567.575439,103.060501 1576.397705,107.499016 1579.558594,104.417130 
	C1580.654785,111.953278 1580.369141,112.513779 1574.893311,113.111099 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1578.400146,115.426201 
	C1578.486572,115.364441 1578.524536,115.598183 1578.524536,115.598183 
	C1578.524536,115.598183 1578.313599,115.487961 1578.400146,115.426201 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M211.932739,119.582809 
	C210.152710,105.185280 222.159821,96.526360 232.723755,97.922050 
	C239.803421,98.857414 244.586945,102.984421 246.873871,109.044304 
	C251.679291,121.777618 244.520355,136.791931 229.614731,136.033157 
	C220.193588,135.553589 214.841858,130.570023 211.932739,119.582809 
M235.088348,106.602211 
	C235.380402,106.745361 235.672470,106.888512 235.945099,107.828018 
	C235.967941,108.889763 235.990784,109.951508 235.239731,111.012634 
	C233.548828,111.650955 231.906357,112.606575 230.158737,112.873299 
	C222.082108,114.105965 220.672928,116.213478 222.727936,124.338631 
	C226.947083,124.338631 231.121872,124.338631 235.296661,124.338631 
	C235.278717,123.925934 235.260773,123.513229 235.242828,123.100533 
	C232.642181,123.100533 230.031403,122.955330 227.443405,123.136093 
	C223.956665,123.379623 223.038528,121.068405 224.150116,118.822891 
	C225.012192,117.081390 227.578049,116.147346 229.447845,114.950073 
	C231.593338,113.576263 233.806396,112.307999 236.524109,110.590912 
	C236.357574,109.384361 236.191040,108.177811 235.877579,106.500717 
	C235.694992,106.210533 235.442154,106.008102 234.921677,105.515404 
	C234.699051,105.553192 234.476425,105.590981 234.253799,105.628761 
	C234.451614,105.807983 234.649414,105.987206 235.088348,106.602211 
M225.901199,104.993469 
	C228.122437,104.823837 230.343674,104.654198 232.564911,104.484566 
	C232.382034,103.765785 232.199173,103.047012 232.016296,102.328232 
	C229.662628,103.203362 227.308945,104.078484 224.316589,104.978462 
	C223.628189,105.610847 222.939789,106.243240 222.251389,106.875626 
	C222.607834,107.166389 222.964294,107.457153 223.320755,107.747917 
	C223.890732,106.842514 224.460709,105.937103 225.901199,104.993469 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1962.458252,906.005005 
	C1955.972534,906.032837 1949.932373,906.038269 1943.420654,906.088318 
	C1940.487549,905.390747 1940.265747,903.463013 1942.654785,903.351196 
	C1951.437866,902.940063 1960.253296,903.200195 1969.055664,903.296021 
	C1969.408936,903.299805 1969.752563,904.198975 1970.737549,905.562866 
	C1967.592407,905.731445 1965.248047,905.857056 1962.458252,906.005005 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1997.086182,902.232422 
	C2003.830322,901.711914 2010.626221,901.561462 2017.713379,901.712402 
	C2017.450073,906.015442 2017.450073,906.015442 2012.696899,904.725220 
	C2011.824341,904.166992 2010.950195,903.132751 2010.079224,903.135559 
	C2006.850830,903.145813 2003.625122,903.607727 2000.394531,903.659851 
	C1999.314941,903.677246 1998.223755,902.975525 1997.086182,902.232422 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1973.099609,902.361450 
	C1980.116211,901.740112 1987.224121,901.519592 1994.650513,901.549927 
	C1994.226562,902.395142 1993.473389,903.516602 1992.743530,903.501709 
	C1986.223633,903.369263 1979.707764,903.036255 1973.099609,902.361450 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1987.738281,905.971802 
	C1982.870483,905.976562 1978.462891,905.976562 1974.055176,905.976562 
	C1974.057373,905.736877 1974.059692,905.497253 1974.062012,905.257568 
	C1980.354004,905.257568 1986.645996,905.257568 1992.937988,905.257568 
	C1992.940063,905.494080 1992.942261,905.730591 1992.944458,905.967102 
	C1991.362427,905.967102 1989.780273,905.967102 1987.738281,905.971802 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1298.785767,124.280991 
	C1289.001221,115.791748 1288.869873,105.637733 1294.442261,97.337479 
	C1299.221436,90.218742 1309.908936,87.428200 1318.572144,91.751884 
	C1328.009644,96.462135 1332.029297,108.981956 1326.858032,117.654907 
	C1320.141846,128.918869 1309.290039,131.602600 1298.785767,124.280991 
M1305.888550,105.660751 
	C1305.517944,109.255791 1305.147339,112.850830 1304.771362,116.497604 
	C1305.883789,116.497604 1308.145142,116.497604 1311.570435,116.497604 
	C1306.149292,113.458656 1308.328369,109.317886 1307.920654,105.850922 
	C1307.506470,102.328896 1307.380127,98.773041 1307.127686,95.231987 
	C1306.544434,95.143898 1305.961182,95.055809 1305.377808,94.967728 
	C1304.200317,96.043175 1303.022705,97.118629 1300.198853,99.697594 
	C1306.555664,99.087288 1305.927002,102.088310 1305.888550,105.660751 
M1319.037109,108.985054 
	C1318.514648,111.422752 1317.992065,113.860451 1317.424438,116.508698 
	C1318.892700,116.508698 1321.120483,116.508698 1322.638550,116.508698 
	C1321.997192,114.190460 1321.568848,112.217026 1320.877441,110.340492 
	C1320.576904,109.524498 1319.735962,108.907600 1319.037109,108.985054 
M1317.261841,99.022522 
	C1318.172974,100.820831 1319.083984,102.619133 1320.425293,105.266541 
	C1320.425293,101.420853 1320.425293,98.405426 1320.425293,95.389999 
	C1320.106079,95.258179 1319.786743,95.126350 1319.467529,94.994530 
	C1318.537842,96.211662 1317.608276,97.428787 1317.261841,99.022522 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M958.871094,470.593262 
	C952.473267,478.212311 943.238586,482.383606 936.233398,489.246704 
	C934.982117,490.472626 934.242126,492.220490 933.435425,494.168030 
	C933.383911,495.050018 933.163025,495.491394 932.942139,495.932739 
	C929.172180,494.958679 926.437134,496.544647 923.798096,499.139923 
	C921.560120,501.340790 918.770447,502.969055 916.350891,505.000763 
	C914.558777,506.505524 913.260071,507.778076 913.088867,510.916992 
	C912.944702,513.562012 909.150818,516.433105 906.387878,518.370605 
	C904.322998,519.818604 902.036011,524.767029 898.695679,519.042542 
	C891.473450,525.106506 884.142273,530.417175 878.006470,536.865295 
	C875.818604,539.164490 876.527710,544.220337 876.001953,548.798950 
	C876.049744,550.041931 876.010132,550.503723 875.889587,551.427612 
	C875.883972,556.258667 875.959412,560.627625 875.685486,565.128845 
	C874.379578,565.505066 873.423035,565.748962 872.304565,565.843384 
	C871.772156,565.466919 871.401733,565.239868 871.031311,565.012756 
	C871.641052,562.502258 872.474304,560.020020 872.795959,557.473083 
	C873.101379,555.055176 872.871460,552.569641 872.799194,549.671021 
	C872.775574,548.703491 872.829773,548.178772 872.904358,546.832031 
	C871.915161,540.050354 874.693115,536.105408 879.234680,532.520020 
	C885.410400,527.644470 891.123169,522.182617 897.034485,516.972229 
	C895.133484,513.957825 897.685608,512.767090 899.312012,511.040405 
	C901.173767,509.063751 902.756470,506.824341 904.770447,504.312561 
	C905.375854,504.312561 907.156738,504.312561 909.239990,504.575073 
	C909.633301,505.280060 909.724243,505.722534 909.825684,506.431091 
	C910.340759,506.622375 910.845276,506.547607 911.637939,506.294067 
	C914.895752,503.738861 918.005676,501.513489 920.785583,498.932648 
	C922.625977,497.223999 923.993347,495.005707 925.572510,493.015625 
	C925.572510,493.015625 925.789185,493.156464 926.256714,493.028137 
	C933.918579,487.801208 941.112915,482.702576 948.835693,477.229431 
	C947.002380,475.970337 946.093140,475.345856 945.183838,474.721405 
	C944.988464,473.699097 944.793091,472.676788 944.463379,471.419739 
	C944.951904,470.841370 945.574768,470.497803 946.462219,470.112366 
	C947.953125,470.720886 949.179382,471.371246 950.234619,472.448700 
	C949.894226,473.487976 949.724854,474.100159 949.555542,474.712341 
	C950.036072,474.460114 950.516602,474.207886 950.997131,473.955597 
	C953.130127,472.669708 955.263123,471.383881 957.908508,470.096985 
	C958.650818,470.189331 958.800903,470.355133 958.871094,470.593262 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M896.657959,516.948730 
	C891.123169,522.182617 885.410400,527.644470 879.234680,532.520020 
	C874.693115,536.105408 871.915161,540.050354 872.892456,546.436890 
	C870.546631,544.491272 868.233093,542.118835 865.981323,539.376343 
	C872.372681,534.156799 878.644287,529.228943 885.054565,524.488647 
	C888.679138,521.808289 892.531555,519.436157 896.657959,516.948730 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M944.833862,474.919312 
	C946.093140,475.345856 947.002380,475.970337 948.835693,477.229431 
	C941.112915,482.702576 933.918579,487.801208 926.360901,492.951752 
	C926.428284,490.951660 926.614624,488.799255 927.398071,486.892181 
	C927.762573,486.004913 929.286316,485.593872 930.620667,485.216095 
	C930.956665,485.465179 930.947449,485.955688 930.717896,486.049316 
	C930.186890,486.331421 930.010864,486.601685 929.785767,487.006775 
	C929.753784,487.050781 929.896301,487.041718 930.268921,486.941833 
	C930.801208,486.669342 930.985046,486.405273 931.319336,486.050842 
	C931.828613,485.899902 932.069092,485.756805 932.309570,485.613708 
	C931.865845,485.415985 931.422058,485.218231 930.978271,485.020477 
	C931.302795,483.907898 931.627258,482.795319 931.964661,481.330292 
	C932.642761,480.533417 933.307861,480.088959 933.975342,479.310486 
	C935.295837,478.589722 936.614014,478.202911 938.041748,477.472900 
	C938.443665,477.001587 938.688782,476.812164 939.009216,476.363007 
	C940.915894,475.815460 942.699951,475.466339 944.833862,474.919312 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M925.195618,493.071136 
	C923.993347,495.005707 922.625977,497.223999 920.785583,498.932648 
	C918.005676,501.513489 914.895752,503.738861 911.263611,506.166107 
	C910.339233,506.199646 910.077209,506.182312 909.815186,506.165009 
	C909.724243,505.722534 909.633301,505.280060 909.498657,504.499756 
	C914.576172,500.483459 919.697510,496.805084 925.195618,493.071136 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M90.220268,104.162857 
	C98.423141,98.294136 106.406746,96.966522 114.753334,103.228439 
	C121.791367,108.508614 124.033066,119.761467 119.322021,127.370064 
	C113.807205,136.276794 104.271393,138.817657 95.467194,135.325272 
	C83.711479,130.662125 80.932098,113.511932 90.220268,104.162857 
M103.860275,112.562340 
	C103.647278,109.475761 103.434288,106.389183 103.221298,103.302605 
	C102.395103,103.293724 101.568916,103.284836 100.742722,103.275955 
	C100.742722,110.375359 100.742722,117.474762 100.742722,125.219551 
	C101.619995,125.219551 103.641304,125.219551 104.790009,125.219551 
	C104.459076,121.058899 104.159126,117.287872 103.860275,112.562340 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2451.073242,1035.243530 
	C2451.288330,1035.162476 2451.576660,1035.327759 2451.932617,1035.744995 
	C2451.715576,1035.827881 2451.430908,1035.658936 2451.073242,1035.243530 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M768.835693,121.562019 
	C763.548218,130.113708 755.377930,134.330765 748.030273,132.548813 
	C739.145874,130.394150 732.169739,122.674332 731.101746,113.815590 
	C730.455322,108.453491 735.890869,99.743637 741.928833,96.466354 
	C748.859314,92.704620 757.142883,93.488670 762.874390,98.238243 
	C769.741455,103.928818 773.462036,113.288673 768.835693,121.562019 
M745.395813,101.259598 
	C745.255737,101.832161 745.115723,102.404716 744.236389,103.080612 
	C743.951782,107.871765 743.628601,112.661392 743.439392,117.456314 
	C743.419434,117.961586 744.257935,118.458138 744.578247,119.026344 
	C746.683044,122.760124 750.100586,121.086380 753.016418,120.953453 
	C757.647400,120.742355 759.831116,116.204567 757.613098,112.038605 
	C757.094055,111.063843 756.562561,109.497345 756.986267,108.721916 
	C758.884521,105.248192 758.605469,102.035057 755.312195,100.090317 
	C752.334717,98.332016 748.779724,97.822945 745.395813,101.259598 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M2536.000000,889.998779 
	C2537.039062,894.597168 2538.747070,899.165894 2538.960938,903.803528 
	C2539.282471,910.783813 2536.140869,916.290039 2529.458740,919.339233 
	C2522.150146,922.673889 2515.091553,922.930725 2508.317383,917.956970 
	C2497.990967,910.374512 2499.169189,897.036438 2504.622314,890.987610 
	C2511.030273,883.879761 2520.592285,880.296875 2531.826660,888.108521 
	C2532.908203,888.860535 2534.122803,889.421509 2535.637695,890.036743 
	C2536.000000,890.002441 2536.000000,890.000000 2536.000000,889.998779 
M2512.050293,900.463379 
	C2512.056152,902.289917 2511.796875,904.165344 2512.122803,905.932800 
	C2512.793945,909.570007 2515.206787,911.039856 2518.893066,911.010864 
	C2524.169678,910.969360 2525.235107,910.392334 2526.073975,906.166443 
	C2525.570557,906.386292 2525.156006,906.646851 2524.704834,906.751648 
	C2521.974854,907.386230 2518.694580,909.243774 2516.609619,908.345337 
	C2511.613281,906.192383 2514.330811,901.029053 2514.057373,897.184875 
	C2513.922119,895.284912 2515.415771,893.106140 2516.618896,891.361328 
	C2517.047607,890.739807 2518.881348,890.695312 2519.921143,890.968201 
	C2522.140625,891.550415 2524.260498,892.512268 2526.669434,893.411133 
	C2525.516846,888.891357 2522.722168,887.515259 2518.550537,888.202515 
	C2513.481445,889.037598 2511.923096,891.717285 2512.050293,900.463379 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M469.626068,958.117554 
	C468.621002,957.566528 467.482574,956.616089 467.547211,956.525574 
	C469.551270,953.721680 471.190857,949.369263 473.867828,948.578979 
	C481.535522,946.315186 484.853699,948.059387 487.594696,953.451355 
	C488.249237,954.738953 488.841431,956.058289 489.822021,958.118896 
	C482.802185,958.118896 476.427887,958.118896 469.626068,958.117554 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M476.552063,970.083984 
	C469.601685,971.059998 470.233948,964.730164 466.792694,961.208374 
	C474.517609,961.208374 481.435913,961.208374 488.911469,961.208374 
	C486.804047,964.687439 487.416382,970.252319 481.783569,969.789001 
	C480.369568,969.672668 479.298645,965.385376 477.909027,962.704346 
	C477.620026,964.897400 477.283356,967.452271 476.552063,970.083984 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M620.349792,934.113342 
	C619.991882,928.287842 620.039612,922.496216 619.947021,916.706909 
	C619.910278,914.411865 620.258667,912.710510 623.527100,912.980286 
	C624.158386,919.108582 624.369934,925.257080 624.123413,931.795593 
	C623.790222,932.474121 623.914917,932.762634 624.039612,933.051147 
	C624.056396,933.426331 624.073181,933.801453 624.119812,934.454956 
	C622.999451,934.538269 621.849243,934.343201 620.349792,934.113342 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M701.489502,938.851746 
	C697.116699,938.862793 693.186584,938.862793 689.256531,938.862793 
	C689.237244,938.081360 689.217957,937.299927 689.198669,936.518494 
	C694.154541,935.203613 699.300110,936.086487 704.353943,935.906372 
	C704.530518,936.328430 704.707092,936.750488 704.883667,937.172607 
	C703.899841,937.728638 702.916016,938.284668 701.489502,938.851746 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M540.780762,94.892296 
	C550.156433,96.689110 557.065552,103.273575 558.023926,110.925377 
	C559.175537,120.120285 555.001404,127.894089 546.891968,131.657516 
	C539.404724,135.132187 529.658630,132.102371 524.153076,124.588562 
	C519.362976,118.051186 520.155762,106.837326 525.803040,101.172546 
	C529.819397,97.143684 534.402588,94.434868 540.780762,94.892296 
M536.000610,120.000595 
	C536.000610,120.000595 535.984680,119.985458 536.071655,120.761047 
	C538.721985,120.636230 541.736572,121.223984 543.888367,120.123955 
	C545.216003,119.445251 546.353210,115.474335 545.576660,114.260872 
	C544.306274,112.275719 541.520081,111.068909 539.151550,110.048439 
	C537.677856,109.413498 535.795898,109.726189 534.097168,109.613571 
	C533.876160,109.143448 533.655151,108.673332 533.434143,108.203209 
	C536.027222,105.604698 538.620239,103.006187 541.299561,100.321251 
	C540.674744,100.245560 539.736572,99.890114 538.917297,100.071457 
	C533.097534,101.359581 528.677856,114.154495 532.576599,118.613640 
	C533.305054,119.446808 534.849609,119.566383 536.000610,120.000595 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2402.937500,825.123108 
	C2399.281494,819.534668 2395.617676,813.534668 2391.961182,807.530334 
	C2390.947021,805.864746 2389.953369,804.186646 2388.928223,802.477722 
	C2386.353516,803.753784 2384.014893,804.912842 2381.676514,806.071838 
	C2381.479492,805.776428 2381.282471,805.480957 2381.085449,805.185547 
	C2381.444824,804.533813 2381.642578,803.542969 2382.188477,803.282776 
	C2387.672119,800.669617 2386.410156,797.625488 2383.534912,793.549683 
	C2378.328369,786.169250 2373.680420,778.394714 2368.877930,770.419556 
	C2373.124023,775.653259 2377.287109,781.255798 2381.463623,786.848450 
	C2382.432617,788.145874 2383.447021,789.409485 2384.891357,791.144409 
	C2389.891602,798.664185 2394.441406,805.728333 2398.990967,812.792542 
	C2400.304932,816.764954 2401.618652,820.737366 2402.937500,825.123108 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2399.139160,812.505493 
	C2394.441406,805.728333 2389.891602,798.664185 2385.201172,791.287231 
	C2386.029541,790.675842 2387.739746,789.937561 2387.861084,790.142334 
	C2391.925293,796.986816 2395.848389,803.915405 2399.730713,810.865845 
	C2399.887451,811.146667 2399.448730,811.760071 2399.139160,812.505493 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2410.437988,835.552856 
	C2410.394287,835.479980 2410.575928,835.453674 2410.575928,835.453674 
	C2410.575928,835.453674 2410.481445,835.625671 2410.437988,835.552856 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1994.536133,1212.908569 
	C1994.901611,1218.018799 1994.899658,1222.914307 1994.799927,1228.245850 
	C1989.610840,1228.682007 1984.519531,1228.682007 1979.444824,1228.682007 
	C1978.244385,1220.689819 1985.305786,1213.077637 1994.536133,1212.908569 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1996.254150,1230.669434 
	C1996.270508,1230.216797 1996.641479,1230.113892 1996.799438,1230.004028 
	C2001.708374,1229.894165 2006.459229,1229.894165 2011.089600,1229.894165 
	C2012.204346,1238.616577 2005.528809,1245.664307 1996.237793,1245.717041 
	C1996.237793,1240.865112 1996.237793,1235.993530 1996.254150,1230.669434 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1994.939209,1232.435181 
	C1994.978516,1237.168945 1994.978516,1241.444336 1994.978516,1245.695435 
	C1986.253052,1246.052124 1978.695068,1238.562744 1979.646484,1230.171021 
	C1983.643921,1230.171021 1987.735718,1230.050171 1991.811401,1230.253662 
	C1992.866943,1230.306396 1993.871826,1231.374878 1994.939209,1232.435181 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1999.009766,1228.788818 
	C1998.506226,1223.946777 1998.376221,1219.048584 1998.241821,1213.700439 
	C2005.445679,1212.994629 2011.344727,1219.134888 2011.982544,1228.015991 
	C2007.789185,1228.254639 2003.586304,1228.493774 1999.009766,1228.788818 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M86.321121,813.151917 
	C86.321121,817.695374 86.321121,822.238831 86.321121,826.782288 
	C85.588722,826.756042 84.856316,826.729797 84.123917,826.703552 
	C84.329849,822.442627 84.535789,818.181763 85.129875,813.492798 
	C85.785721,813.093811 86.053421,813.122864 86.321121,813.151917 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M86.601227,837.249207 
	C82.755028,835.839661 84.104156,833.081543 84.532906,830.510315 
	C85.031357,830.415649 85.529808,830.321045 86.028259,830.226440 
	C86.284821,832.456116 86.541389,834.685852 86.601227,837.249207 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M86.711266,813.089478 
	C86.053421,813.122864 85.785721,813.093811 85.111572,813.062256 
	C85.109192,812.354187 85.428741,811.119812 85.932732,811.039246 
	C87.631432,810.767700 89.395706,810.906311 91.562256,810.892517 
	C91.987350,811.391907 91.985092,811.886841 91.964684,812.753296 
	C90.331497,813.092285 88.716454,813.059631 86.711266,813.089478 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M84.228050,853.159302 
	C84.008286,852.549255 84.013031,852.100037 83.982887,851.315430 
	C84.042229,850.431763 84.136467,849.883545 84.841919,849.123535 
	C85.763153,848.896973 86.073181,848.882263 86.383194,848.867493 
	C86.032722,850.220642 85.682266,851.573853 85.150444,853.221619 
	C84.796921,853.450928 84.624748,853.385620 84.228050,853.159302 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M86.354630,848.543335 
	C86.073181,848.882263 85.763153,848.896973 84.984863,848.884277 
	C84.530037,848.451843 84.543480,848.046753 84.523605,847.338196 
	C84.865807,846.382507 85.241325,845.730225 85.616852,845.077942 
	C85.899673,845.147217 86.182495,845.216431 86.465317,845.285645 
	C86.418900,846.263489 86.372475,847.241272 86.354630,848.543335 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M115.066612,753.104797 
	C116.043739,753.072754 117.039398,753.150696 117.994820,752.989685 
	C122.334549,752.258545 124.441597,753.976562 124.435738,759.154602 
	C124.741707,761.600098 124.762642,763.330811 124.500992,765.335327 
	C124.218391,768.008606 124.218391,770.407959 124.218391,773.923645 
	C127.309822,772.829224 129.658203,771.997803 132.411438,771.152954 
	C143.350952,768.456848 144.058533,767.624329 144.264404,757.803833 
	C145.123367,755.873108 145.699738,754.140503 146.721375,752.347412 
	C153.122971,752.510803 159.079330,752.734680 165.005783,753.430176 
	C164.826355,760.997192 164.676834,768.092590 164.508469,776.082092 
	C162.386871,774.488159 161.525467,773.841003 160.321289,772.936340 
	C160.048920,774.658325 159.856461,775.875183 159.595627,777.524231 
	C158.470978,775.834351 157.588150,774.507874 156.462860,772.817078 
	C155.453781,773.967041 154.694824,774.832031 153.954163,775.676147 
	C153.164337,774.727844 152.541138,773.979553 152.120102,773.474060 
	C150.777649,775.736572 149.635208,777.661987 148.113266,780.226990 
	C149.838104,780.476501 151.343094,780.963562 152.800491,780.848450 
	C155.761551,780.614563 158.692764,779.808105 161.645050,779.758362 
	C162.742645,779.739929 164.687103,781.058044 164.822693,781.976746 
	C165.241806,784.816101 164.975754,787.756531 164.975754,790.619568 
	C162.352402,790.899048 160.546524,791.091431 158.301849,791.226685 
	C145.155884,790.648499 144.964447,790.818298 146.463577,801.317322 
	C146.485535,801.471069 146.614975,801.609436 146.636108,802.176147 
	C147.045609,803.242737 147.513260,803.888123 147.966583,804.546631 
	C147.952240,804.559692 147.959854,804.596985 147.951813,804.981812 
	C147.439453,805.867188 146.958252,806.772339 146.427078,806.802673 
	C142.945618,807.001465 139.453339,807.011292 135.613281,806.969971 
	C130.922531,807.020508 126.583092,807.183899 121.815933,807.231873 
	C119.628952,807.022949 117.869698,806.929382 116.074585,806.378845 
	C115.714691,788.316162 115.390656,770.710510 115.066612,753.104797 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M108.912338,738.406189 
	C106.173325,738.318665 103.445793,737.775574 100.375473,737.088989 
	C95.049095,733.532227 91.616776,729.165710 91.137779,722.397339 
	C96.886475,722.397339 102.423302,722.397339 108.923805,722.397339 
	C108.923805,727.493286 108.923805,732.721924 108.912338,738.406189 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M111.926010,810.948120 
	C111.632584,802.945679 111.339157,794.943176 111.015579,786.473389 
	C111.036140,771.748840 111.086853,757.491638 111.257935,742.813538 
	C112.220856,743.202271 113.653801,743.923584 113.790810,744.838806 
	C114.145264,747.206482 113.928131,749.659729 113.694366,752.819336 
	C113.310509,755.356750 113.025177,757.156189 113.055367,758.950317 
	C113.330421,775.295166 113.660446,791.639099 113.836273,808.252075 
	C113.107437,809.329895 112.516724,810.139038 111.926010,810.948120 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M113.974411,807.983398 
	C113.660446,791.639099 113.330421,775.295166 113.055367,758.950317 
	C113.025177,757.156189 113.310509,755.356750 113.756302,753.270264 
	C114.231712,752.989014 114.400307,752.997314 114.817757,753.055237 
	C115.390656,770.710510 115.714691,788.316162 115.792664,806.497620 
	C115.022537,807.376709 114.498474,807.680054 113.974411,807.983398 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M117.851044,747.465881 
	C120.408234,747.587402 122.948013,748.051270 125.250977,748.754089 
	C121.983749,749.912231 118.953339,750.831421 115.922920,751.750610 
	C115.700859,751.196655 115.478790,750.642761 115.256721,750.088806 
	C116.115692,749.328613 116.974663,748.568420 117.851044,747.465881 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M127.926559,756.332275 
	C128.749130,755.586121 129.481613,755.149048 130.577789,755.038635 
	C130.954071,755.878174 130.966629,756.390991 130.745209,757.095947 
	C130.292755,757.849121 130.074280,758.410217 129.536987,758.984619 
	C128.792877,759.010681 128.367554,759.023499 127.706436,759.022827 
	C127.470634,759.009277 126.998413,758.997559 126.999207,758.998779 
	C127.000000,759.000000 127.002060,759.002441 127.002052,759.002441 
	C127.280190,758.215393 127.558327,757.428406 127.926559,756.332275 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M124.783585,765.061523 
	C124.762642,763.330811 124.741707,761.600098 124.687653,759.437744 
	C125.176437,758.993958 125.698326,758.981873 126.611145,758.986084 
	C127.002060,759.002441 127.000000,759.000000 127.010780,759.411011 
	C127.066551,761.189941 127.111542,762.557800 126.925362,764.028564 
	C126.461189,764.368225 126.228195,764.605042 125.684921,764.856079 
	C125.177620,764.934082 124.980598,764.997803 124.783585,765.061523 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M355.050140,117.516586 
	C352.544006,103.528244 366.416290,90.451401 382.030304,96.447540 
	C389.263153,99.225121 394.974091,108.763710 393.828491,115.959305 
	C392.138855,126.572052 385.704895,132.703857 375.827728,133.114731 
	C365.332642,133.551315 358.219360,128.358292 355.050140,117.516586 
M372.501953,106.160286 
	C372.340546,106.448341 372.179169,106.736389 371.412659,107.312431 
	C369.474365,109.385963 367.536041,111.459496 365.784180,113.333565 
	C369.053650,118.262939 376.706085,112.373787 377.331726,119.538628 
	C378.434631,117.809601 379.574066,116.023254 380.713501,114.236916 
	C380.236450,114.307884 379.759369,114.378860 379.282318,114.449829 
	C378.626740,110.092506 377.971191,105.735191 377.118835,100.069954 
	C376.040894,101.988731 375.490601,102.968330 374.465149,104.118202 
	C374.295197,104.399483 374.125214,104.680756 373.475861,105.127907 
	C373.308197,105.411224 373.140533,105.694542 372.501953,106.160286 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1986.232422,1202.531006 
	C1986.062866,1200.620972 1986.201904,1198.917358 1986.340942,1197.213867 
	C1987.390991,1197.777344 1988.441162,1198.340698 1989.491333,1198.904053 
	C1988.507812,1200.181885 1987.524292,1201.459717 1986.232422,1202.531006 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M159.909119,1037.073853 
	C160.871109,1035.544678 162.032990,1033.404419 162.760590,1033.543091 
	C164.218796,1033.820923 165.439575,1035.344849 166.758377,1036.354370 
	C165.847687,1037.519531 165.155853,1039.263184 163.973587,1039.710327 
	C162.320435,1040.335693 159.820953,1040.833008 159.909119,1037.073853 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M224.003693,376.088440 
	C210.338257,376.351776 196.672806,376.615082 182.551575,376.805511 
	C182.074707,376.354523 182.053604,375.976440 182.024063,375.313477 
	C192.391159,374.880188 202.766846,374.742157 213.142105,374.577240 
	C216.438324,374.524841 219.733429,374.400940 223.472321,374.648895 
	C223.944977,375.354828 223.974335,375.721619 224.003693,376.088440 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M111.934082,431.427429 
	C111.606537,424.588074 111.302307,417.292053 110.990921,409.535858 
	C111.190659,408.791351 111.397568,408.507050 111.860596,408.139740 
	C112.063614,415.694763 112.010506,423.332764 111.934082,431.427429 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1180.014526,543.972107 
	C1179.403687,544.035950 1178.793457,544.145630 1178.181763,544.155029 
	C1176.280640,544.184265 1174.379028,544.175537 1172.477539,544.181641 
	C1173.112915,542.243225 1173.748291,540.304871 1174.667725,538.152222 
	C1175.689209,537.948792 1176.426758,537.959595 1177.468018,538.345764 
	C1176.494507,539.752930 1175.217407,540.784729 1172.907349,542.651062 
	C1176.968506,542.508789 1179.725586,542.923401 1180.337036,539.072571 
	C1183.615112,541.186035 1181.229858,542.499146 1180.014526,543.972107 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1180.181885,544.299805 
	C1181.229858,542.499146 1183.615112,541.186035 1180.293091,538.828369 
	C1179.912109,538.511658 1180.029297,538.029602 1180.029297,538.029602 
	C1180.517456,537.939026 1181.005493,537.848450 1181.709595,537.641296 
	C1184.845093,536.910400 1187.897583,537.624146 1187.256104,540.857971 
	C1186.740723,543.455200 1184.015381,545.849915 1180.181885,544.299805 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1163.022705,540.338379 
	C1163.658936,538.572021 1164.320435,537.140442 1165.013184,535.371704 
	C1166.489624,534.637573 1167.934937,534.240601 1169.690186,533.921814 
	C1170.000000,534.000000 1170.006104,534.006165 1170.006104,534.006165 
	C1168.700684,536.157471 1167.395142,538.308838 1166.137329,540.813965 
	C1165.690796,541.675293 1165.196655,542.182800 1164.702393,542.690308 
	C1164.150879,542.017883 1163.599365,541.345520 1163.022705,540.338379 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1169.996948,533.996582 
	C1170.633057,533.052551 1171.193970,531.378235 1171.925049,531.300354 
	C1174.854004,530.988281 1177.835815,531.171448 1180.798218,531.171448 
	C1180.824463,531.974670 1180.850708,532.777954 1180.876953,533.581177 
	C1177.559082,533.703918 1174.241089,533.826721 1170.464600,533.977844 
	C1170.006104,534.006165 1170.000000,534.000000 1169.996948,533.996582 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1179.878906,537.792358 
	C1180.029297,538.029602 1179.912109,538.511658 1179.956055,538.755859 
	C1179.725586,542.923401 1176.968506,542.508789 1172.907349,542.651062 
	C1175.217407,540.784729 1176.494507,539.752930 1177.834106,538.398499 
	C1178.507324,537.902283 1179.117920,537.728760 1179.878906,537.792358 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1740.019775,904.512085 
	C1740.481079,904.166931 1740.902222,904.114563 1741.640869,904.098877 
	C1741.669434,905.080627 1741.380615,906.025757 1741.091797,906.970947 
	C1740.721191,906.248901 1740.350464,905.526917 1740.019775,904.512085 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1755.824341,906.982117 
	C1755.038940,906.939636 1754.550415,906.890259 1753.482666,906.782349 
	C1754.836426,905.953796 1755.770874,905.381775 1756.705444,904.809814 
	C1756.906128,904.977661 1757.106812,905.145569 1757.307495,905.313477 
	C1756.912109,905.867371 1756.516602,906.421326 1755.824341,906.982117 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1769.974854,906.348999 
	C1769.450562,906.677795 1768.756836,906.811951 1768.062988,906.946106 
	C1768.028809,906.738464 1767.994629,906.530762 1767.960327,906.323120 
	C1768.575439,906.266907 1769.190430,906.210632 1769.974854,906.348999 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1746.496338,906.083862 
	C1746.249146,906.432922 1745.741333,906.743042 1745.233521,907.053162 
	C1745.134033,906.835754 1745.034546,906.618347 1744.935059,906.401001 
	C1745.368530,906.282288 1745.802124,906.163635 1746.496338,906.083862 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1748.152100,904.287231 
	C1748.081909,904.147766 1748.496582,904.082764 1748.701416,904.126465 
	C1748.906250,904.170166 1748.621948,904.515869 1748.621948,904.515869 
	C1748.621948,904.515869 1748.222168,904.426758 1748.152100,904.287231 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1749.460938,906.447449 
	C1749.522827,906.410278 1749.399048,906.484619 1749.460938,906.447449 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1743.558960,908.471497 
	C1743.596313,908.531677 1743.521606,908.411316 1743.558960,908.471497 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M988.995850,449.995575 
	C984.692200,448.844666 982.312683,451.923218 979.572449,454.251709 
	C977.678833,455.860870 975.722778,457.396545 973.418457,458.714294 
	C972.992554,458.166779 972.942749,457.870026 973.061096,457.298798 
	C974.722473,455.745667 976.437866,454.646454 977.652649,453.142822 
	C979.163879,451.272247 980.261597,449.067596 981.537842,447.007233 
	C983.250916,446.676880 985.249207,446.791168 986.613037,445.916412 
	C988.899475,444.449738 990.781677,442.352844 993.255249,440.144897 
	C1001.956299,433.442932 1010.291382,427.186005 1018.402527,420.651093 
	C1019.001465,420.168579 1017.987488,417.684204 1017.863953,415.863281 
	C1018.097046,415.417236 1018.189514,415.238434 1018.281921,415.059662 
	C1018.498962,415.030426 1018.693054,414.947510 1019.281250,414.513855 
	C1022.457092,412.125854 1025.215942,410.034912 1028.128174,408.263611 
	C1030.217651,411.072906 1032.153809,413.562561 1034.090088,416.052216 
	C1031.611938,418.555695 1032.994629,420.386078 1035.041138,422.264862 
	C1036.784790,423.865448 1038.339844,425.671448 1039.978271,427.386566 
	C1039.487305,427.857147 1038.996460,428.327728 1038.505615,428.798309 
	C1035.925781,425.865021 1033.346069,422.931702 1030.633301,419.847290 
	C1027.518555,421.001740 1025.131958,424.863831 1021.186523,421.916870 
	C1020.688660,421.545044 1018.541443,422.975830 1017.428955,423.897827 
	C1009.511230,430.460083 1001.658203,437.100433 993.799438,443.733490 
	C992.297363,445.001282 990.861084,446.347076 989.548706,448.122467 
	C989.467224,449.057220 989.231567,449.526398 988.995850,449.995575 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1017.723389,416.130493 
	C1017.987488,417.684204 1019.001465,420.168579 1018.402527,420.651093 
	C1010.291382,427.186005 1001.956299,433.442932 993.337280,439.805603 
	C996.087585,436.494812 999.017273,432.979065 1002.309082,429.843536 
	C1006.042908,426.287048 1010.101013,423.071045 1014.006592,419.353088 
	C1013.995117,418.999207 1013.992126,419.039032 1014.312927,419.033173 
	C1015.152771,418.688904 1015.671875,418.350494 1016.401855,417.877106 
	C1016.983032,417.204926 1017.353210,416.667725 1017.723389,416.130493 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M981.198853,447.089783 
	C980.261597,449.067596 979.163879,451.272247 977.652649,453.142822 
	C976.437866,454.646454 974.722473,455.745667 972.785706,457.338928 
	C971.790466,457.248016 971.238770,456.842499 970.840027,456.133545 
	C971.329163,455.245483 971.665405,454.660889 972.296143,453.909119 
	C973.083130,452.773712 973.575562,451.805481 974.175964,450.666199 
	C974.459900,450.281464 974.677917,450.127045 975.287842,450.042938 
	C976.437805,449.707062 977.237793,449.360107 978.021484,449.012299 
	C978.005188,449.011444 978.021912,449.046265 978.021912,449.046265 
	C978.967896,448.421661 979.913818,447.796997 981.198853,447.089783 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M974.937866,450.031799 
	C974.677917,450.127045 974.459900,450.281464 973.919189,450.812500 
	C973.062500,451.440186 972.570496,451.750519 972.030273,452.018341 
	C971.982056,451.975830 971.890686,451.885284 971.890686,451.885284 
	C973.165466,450.120056 974.440186,448.354858 975.854431,446.290527 
	C976.725586,445.968048 977.457214,445.944672 978.593262,445.957397 
	C978.997681,445.993500 979.082214,446.070343 979.082214,446.070343 
	C978.733276,446.847015 978.384338,447.623688 978.028687,448.723328 
	C978.021912,449.046265 978.005188,449.011444 977.675293,449.002869 
	C976.542847,449.340118 975.740356,449.685974 974.937866,450.031799 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1017.863953,415.863281 
	C1017.353210,416.667725 1016.983032,417.204926 1016.075317,417.896423 
	C1015.022583,418.380127 1014.507385,418.709595 1013.992188,419.039062 
	C1013.992126,419.039032 1013.995117,418.999207 1014.015808,419.000366 
	C1013.329468,419.295532 1012.622375,419.589539 1010.488953,420.476654 
	C1012.535217,418.107513 1013.628906,416.841278 1014.858765,415.284149 
	C1015.817322,414.984192 1016.639648,414.975128 1017.871948,415.012848 
	C1018.189514,415.238434 1018.097046,415.417236 1017.863953,415.863281 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M985.973022,437.967529 
	C987.423462,439.504242 987.423462,439.504242 983.800842,441.754181 
	C984.723450,440.238342 985.239563,439.390289 985.860596,438.250183 
	C985.965515,437.958099 985.989136,437.988464 985.973022,437.967529 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M979.906494,444.919739 
	C980.453918,444.208191 981.001404,443.496674 981.548828,442.785156 
	C981.829102,443.036407 982.109314,443.287628 982.389587,443.538879 
	C981.825989,444.025696 981.262390,444.512512 980.345764,444.998199 
	C979.992737,444.997070 979.906494,444.919739 979.906494,444.919739 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M978.959229,445.951233 
	C979.008972,445.581818 979.191284,445.320312 979.687073,445.022064 
	C979.906494,444.919739 979.992737,444.997070 980.033203,445.041199 
	C979.979004,445.408905 979.794373,445.668549 979.301025,445.967285 
	C979.082214,446.070343 978.997681,445.993500 978.959229,445.951233 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1605.434814,905.015015 
	C1602.935669,905.179565 1600.813110,905.159790 1598.240479,905.232666 
	C1593.620483,897.081665 1587.512451,892.544861 1577.693481,895.791321 
	C1577.311890,893.409912 1577.069580,891.897583 1576.827271,890.385254 
	C1576.401855,890.320557 1575.976440,890.255859 1575.551147,890.191162 
	C1575.160645,892.256897 1574.770264,894.322571 1573.932983,896.696533 
	C1570.200439,897.356995 1566.914551,897.709167 1563.628784,898.061401 
	C1561.617798,897.012085 1561.578735,896.558044 1554.945312,897.128723 
	C1547.614014,897.759277 1546.283813,894.872192 1548.440674,887.852844 
	C1549.132812,886.216309 1549.405273,884.593445 1549.747070,882.985291 
	C1550.331665,880.234375 1551.455566,878.663269 1554.239014,881.458618 
	C1555.389771,882.486328 1556.568726,883.708435 1557.709229,883.673645 
	C1563.227783,883.505188 1569.651978,885.720520 1573.153076,878.958618 
	C1575.633057,879.376892 1577.875366,879.490784 1580.117554,879.604675 
	C1585.586304,880.101257 1579.659424,889.429321 1585.891602,889.033569 
	C1585.891602,886.099731 1585.204346,883.340027 1586.137329,881.333801 
	C1586.741577,880.034546 1589.670410,879.451904 1591.634644,879.229980 
	C1595.073730,878.841431 1598.572144,878.977051 1602.407227,878.925781 
	C1603.492310,878.915955 1604.215942,878.873413 1605.302612,878.919983 
	C1607.047974,878.865784 1608.430420,878.722534 1610.242676,878.522522 
	C1616.683838,878.268555 1618.261230,880.786255 1615.737183,887.161987 
	C1613.666748,886.986877 1612.013550,886.865356 1610.183472,886.442261 
	C1605.869019,882.739807 1605.155396,888.619568 1602.742676,888.839539 
	C1604.945190,890.950500 1607.440308,894.853943 1610.601318,889.112427 
	C1612.655640,888.701477 1614.326050,888.357788 1616.408691,888.039917 
	C1617.351196,885.805908 1617.881348,883.546082 1618.711670,881.204834 
	C1619.347290,880.445312 1619.682617,879.767151 1620.355957,878.948547 
	C1622.510010,878.833740 1624.326294,878.859436 1626.142578,878.885132 
	C1626.142578,886.957947 1626.142578,895.030701 1626.142578,903.443420 
	C1619.493286,903.897095 1612.652344,904.363892 1605.434814,905.015015 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1548.052124,887.873169 
	C1546.283813,894.872192 1547.614014,897.759277 1554.945312,897.128723 
	C1561.578735,896.558044 1561.617798,897.012085 1563.628784,898.061401 
	C1566.914551,897.709167 1570.200439,897.356995 1573.982788,897.018921 
	C1573.865723,898.764282 1573.252197,900.495483 1572.823242,902.606262 
	C1573.007935,902.985901 1572.971558,902.942139 1572.971558,902.942139 
	C1572.206543,903.582947 1571.441406,904.223694 1570.444092,905.120605 
	C1566.664062,905.609314 1563.116333,905.841919 1559.159668,906.245483 
	C1558.413208,906.401550 1558.075562,906.386658 1557.485840,906.355591 
	C1554.461182,906.212219 1551.688721,906.085083 1548.461182,905.480286 
	C1547.769897,902.758118 1547.533813,900.513611 1547.315430,898.437622 
	C1544.300537,900.334595 1542.174194,901.672424 1539.977783,902.652466 
	C1538.307373,898.162048 1534.813965,899.103027 1531.062256,899.177246 
	C1530.111206,899.168640 1529.730835,899.189819 1529.003418,899.205322 
	C1526.739746,900.495300 1524.837646,901.813477 1522.900879,903.078369 
	C1521.684814,903.872559 1520.416748,904.586975 1518.734619,905.206665 
	C1516.202026,904.971130 1514.106812,904.866028 1512.008301,904.409546 
	C1511.990112,903.351562 1511.975464,902.645081 1511.960693,901.938538 
	C1511.960693,901.938538 1511.991699,902.000671 1512.348877,901.926147 
	C1513.077881,900.716736 1513.449463,899.581787 1513.821289,898.446899 
	C1514.572632,897.553345 1515.323975,896.659729 1516.557373,895.450684 
	C1518.304321,894.463074 1519.569092,893.790955 1520.312866,893.395630 
	C1522.082275,893.898499 1523.485718,894.297424 1525.333252,894.838806 
	C1534.902832,895.162415 1534.917603,895.162354 1534.864624,886.023804 
	C1534.857178,884.726440 1534.623901,883.430359 1534.582520,881.729370 
	C1535.922729,881.144348 1537.175537,880.963806 1538.779175,880.846558 
	C1539.365723,880.939880 1539.601562,880.969910 1540.027222,881.252563 
	C1541.221436,881.417480 1542.532471,881.701538 1543.173096,881.173096 
	C1545.166016,879.528687 1546.747925,879.026611 1548.084229,882.175049 
	C1548.100586,882.852722 1548.123291,883.123230 1548.151367,883.768433 
	C1548.121826,885.386475 1548.087036,886.629822 1548.052124,887.873169 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1572.983398,902.975464 
	C1575.893311,900.914917 1576.372437,896.327698 1581.883301,896.747498 
	C1591.333252,897.467346 1591.496704,896.970764 1595.293335,906.826050 
	C1587.691772,906.826050 1580.219238,906.826050 1572.365356,906.826050 
	C1572.582886,905.791931 1572.799194,904.763611 1572.993530,903.338745 
	C1572.971558,902.942139 1573.007935,902.985901 1572.983398,902.975464 
M1582.342285,900.844849 
	C1579.292358,900.033691 1579.291626,900.034546 1578.384155,905.179260 
	C1581.420776,902.670776 1587.635986,907.750732 1589.681641,900.675415 
	C1586.820801,900.956665 1584.831421,901.152222 1582.342285,900.844849 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1594.357300,911.750366 
	C1592.184692,914.484802 1590.507324,918.768250 1587.742798,919.652222 
	C1580.543457,921.954529 1577.449707,919.915833 1573.656006,912.364136 
	C1578.223022,913.093506 1582.442383,914.186951 1586.733398,915.298950 
	C1587.254761,909.685303 1591.795898,912.906250 1594.357300,911.750366 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1594.500000,911.477539 
	C1591.795898,912.906250 1587.254761,909.685303 1586.733398,915.298950 
	C1582.442383,914.186951 1578.223022,913.093506 1573.572021,912.034912 
	C1573.454834,911.099854 1573.749878,909.291138 1574.086304,909.283386 
	C1581.195068,909.120178 1588.308228,909.159912 1595.705200,909.159912 
	C1595.229492,910.075195 1594.936035,910.639954 1594.500000,911.477539 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2355.630371,745.039551 
	C2355.983398,745.459961 2356.188232,745.705933 2356.685791,745.985840 
	C2359.520020,749.839172 2362.127441,753.603271 2364.742188,757.362122 
	C2366.461182,759.832764 2368.190918,762.295715 2369.915771,764.762085 
	C2369.426270,765.156433 2368.937012,765.550720 2368.447510,765.945007 
	C2366.764648,763.879028 2365.050781,761.837341 2363.405518,759.741821 
	C2360.159668,755.607666 2356.875977,751.498108 2353.812012,747.231934 
	C2353.370850,746.617737 2354.062500,745.190186 2354.581055,744.112793 
	C2354.978760,744.430542 2355.139160,744.709229 2355.630371,745.039551 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2343.696777,730.872192 
	C2344.079834,731.336853 2344.261230,731.607971 2344.778320,731.902222 
	C2344.693848,735.140686 2343.053955,734.848145 2341.134766,733.404602 
	C2339.880371,732.461182 2338.766357,731.331787 2337.255371,730.226746 
	C2336.921143,730.167786 2336.924561,730.101318 2336.863037,729.827454 
	C2336.522217,729.388672 2336.243164,729.223816 2335.963867,729.058960 
	C2335.963867,729.058960 2335.899170,729.096680 2335.862061,729.098572 
	C2337.042480,728.932190 2338.260010,728.763855 2339.397217,728.606628 
	C2339.202881,727.897766 2338.849609,726.609619 2338.749512,725.156189 
	C2339.103027,725.315247 2339.290039,725.577759 2339.783691,725.886963 
	C2340.091797,726.322937 2340.270020,726.590576 2340.754395,726.911499 
	C2341.777588,728.265686 2342.584717,729.506958 2343.696777,730.872192 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2348.775391,742.210938 
	C2348.632568,740.856995 2348.741211,739.676880 2348.945068,737.466064 
	C2351.790771,741.180847 2351.790771,741.180847 2348.775391,742.210938 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2344.947754,737.062927 
	C2344.834717,736.874329 2344.721924,736.685669 2344.608887,736.497009 
	C2344.719727,736.540283 2344.830322,736.583557 2344.977051,736.810303 
	C2345.012695,736.993774 2344.947754,737.062927 2344.947754,737.062927 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2345.047852,736.961914 
	C2345.404785,737.035522 2345.661865,737.227661 2345.927490,737.756348 
	C2346.000977,738.006104 2345.941406,738.073730 2345.941406,738.073730 
	C2345.614502,737.975037 2345.358154,737.782532 2345.059814,737.279602 
	C2344.947754,737.062927 2345.012695,736.993774 2345.047852,736.961914 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2346.034668,737.976196 
	C2346.177979,738.125427 2346.287842,738.304565 2346.397705,738.483643 
	C2346.295166,738.440186 2346.192383,738.396790 2346.015625,738.213501 
	C2345.941406,738.073730 2346.000977,738.006104 2346.034668,737.976196 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1128.866211,521.606323 
	C1129.647827,521.484436 1130.201416,521.604614 1130.755005,521.724792 
	C1130.546631,522.123352 1130.338257,522.521912 1130.129883,522.920532 
	C1129.632568,522.563110 1129.135376,522.205750 1128.866211,521.606323 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1115.318970,521.276001 
	C1114.974487,521.661804 1114.629883,522.047668 1114.285400,522.433472 
	C1114.277100,521.996887 1114.268799,521.560364 1114.626221,521.058472 
	C1114.991943,520.993042 1115.318970,521.275940 1115.318970,521.276001 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1114.826538,520.853882 
	C1114.978760,520.347534 1115.296265,519.980286 1115.613892,519.613098 
	C1115.630493,520.027832 1115.647217,520.442566 1115.491455,521.066650 
	C1115.318970,521.275940 1114.991943,520.993042 1114.826538,520.853882 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2322.605469,709.059448 
	C2322.937988,709.496155 2323.139893,709.743958 2323.632324,710.030823 
	C2324.649902,711.157898 2325.347412,712.287476 2326.260254,713.202515 
	C2329.354980,716.304199 2332.525391,719.329956 2335.666992,722.384766 
	C2335.156494,722.914490 2334.645996,723.444214 2334.135742,723.973938 
	C2332.532715,722.572021 2330.882568,721.220093 2329.335938,719.758423 
	C2325.401855,716.040466 2321.383545,712.393494 2317.725098,708.418213 
	C2317.048096,707.682495 2312.604492,706.149292 2317.416992,704.244751 
	C2317.911865,704.583435 2318.132080,704.799377 2318.586914,705.096741 
	C2318.930420,705.505249 2319.128662,705.752625 2319.582275,706.085938 
	C2319.929199,706.508301 2320.131592,706.754700 2320.598145,707.075317 
	C2320.957275,707.483337 2321.161377,707.726929 2321.624512,708.047058 
	C2321.960938,708.467102 2322.152344,708.720398 2322.605469,709.059448 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2317.912109,712.877075 
	C2314.365234,713.432251 2310.781494,713.670288 2307.197510,713.908264 
	C2307.184326,712.936523 2307.171143,711.964722 2307.157959,710.992920 
	C2309.876221,710.992920 2312.594238,710.992920 2315.792725,710.984558 
	C2316.806885,711.504089 2317.341064,712.031982 2317.912109,712.877075 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2335.442383,725.564209 
	C2335.398682,725.494629 2335.486084,725.633789 2335.442383,725.564209 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1709.836060,890.537354 
	C1709.888794,891.392273 1709.941528,892.247192 1709.997437,893.529907 
	C1709.473145,896.773621 1708.945923,899.589478 1708.397217,902.520264 
	C1704.325684,903.008789 1700.157959,903.508911 1695.108154,904.034668 
	C1687.472290,904.388184 1680.718384,904.716003 1673.964600,905.043823 
	C1673.226196,905.009277 1672.487915,904.974670 1671.383057,904.990845 
	C1664.306274,905.058655 1657.595825,905.075745 1650.413330,905.145813 
	C1645.639526,904.749329 1641.325806,903.856689 1637.039551,903.973267 
	C1632.645020,904.092896 1630.659180,903.129395 1630.961182,898.236267 
	C1631.338501,892.119873 1630.949951,885.956177 1631.289062,879.264893 
	C1651.449829,878.458008 1671.213379,878.199158 1691.439453,878.031189 
	C1699.771362,878.122009 1707.640747,878.122009 1715.703247,878.122009 
	C1716.180908,880.867798 1716.581909,883.172363 1717.072144,885.990662 
	C1714.905884,885.990662 1713.457275,885.990662 1711.762451,885.735657 
	C1711.029541,884.144897 1710.542969,882.809143 1710.131104,881.678528 
	C1709.124512,882.980835 1708.004761,884.429688 1706.499146,885.947021 
	C1698.922607,881.972900 1694.480957,886.775330 1689.706909,891.022278 
	C1695.365479,891.994080 1700.988159,893.608398 1705.170898,888.276550 
	C1705.461548,888.723511 1705.750610,888.901123 1706.019287,889.032349 
	C1705.998901,888.985962 1705.898560,889.001160 1706.036377,889.311646 
	C1707.394775,889.927246 1708.615356,890.232300 1709.836060,890.537354 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1704.489990,909.027649 
	C1703.562500,909.048401 1703.101196,909.043762 1702.295654,909.082458 
	C1700.632446,908.886597 1699.313354,908.647339 1697.484253,908.315552 
	C1700.417725,905.599976 1722.099243,904.917725 1727.451172,906.793518 
	C1725.689087,908.931152 1725.689087,908.919067 1716.895508,908.915710 
	C1712.915649,908.914124 1708.935791,908.971497 1704.489990,909.027649 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1674.057373,905.444580 
	C1680.718384,904.716003 1687.472290,904.388184 1694.709961,904.132874 
	C1694.792358,905.060425 1694.373413,906.667480 1693.991943,906.658569 
	C1687.375244,906.505432 1680.763184,906.153687 1674.057373,905.444580 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1666.251221,906.982666 
	C1666.000000,906.999207 1665.537231,906.945862 1665.305176,906.955872 
	C1666.208618,906.772583 1667.343994,906.579285 1668.651001,906.647217 
	C1668.418213,906.917053 1668.014038,906.925659 1667.307129,906.972046 
	C1666.837158,906.995239 1666.669922,906.980713 1666.251221,906.982666 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1711.727417,901.865112 
	C1711.906494,901.901367 1711.933594,902.066711 1711.984863,902.481201 
	C1711.864380,902.484985 1711.719971,902.239624 1711.727417,901.865112 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1050.001953,391.995270 
	C1046.445435,392.286133 1046.445435,392.286133 1046.876465,390.270386 
	C1048.626709,389.778015 1050.265747,389.571533 1051.957031,389.691711 
	C1052.009521,390.018341 1051.998901,389.999969 1051.760010,390.077820 
	C1051.344971,390.444122 1051.168701,390.732544 1050.992432,391.020966 
	C1050.992432,391.020966 1050.993286,390.993225 1050.765381,391.087097 
	C1050.352905,391.463776 1050.168335,391.746552 1049.983887,392.029327 
	C1049.983887,392.029327 1049.986450,391.997833 1050.001953,391.995270 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1078.991699,364.002441 
	C1080.171509,363.789886 1081.363647,363.577515 1082.555908,363.365173 
	C1082.593262,363.869843 1082.630493,364.374542 1082.667847,364.879242 
	C1081.479980,364.799927 1080.292236,364.720642 1079.051514,364.309692 
	C1078.998657,363.978027 1079.004028,364.002686 1078.991699,364.002441 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1060.392456,381.219971 
	C1061.021362,380.241882 1061.844360,379.491211 1062.667358,378.740570 
	C1062.950439,379.113647 1063.233398,379.486725 1063.516357,379.859802 
	C1062.539795,380.389008 1061.563232,380.918213 1060.392456,381.219971 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1057.010010,386.000122 
	C1056.257080,385.950958 1055.493530,385.899750 1054.730103,385.848511 
	C1054.773193,385.472229 1054.816162,385.095917 1054.859253,384.719604 
	C1055.556274,384.941315 1056.253296,385.162994 1056.977173,385.701874 
	C1057.004028,386.019073 1056.999512,385.998016 1057.010010,386.000122 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1066.901733,375.914825 
	C1067.693726,375.264374 1068.485718,374.613922 1069.638428,373.981140 
	C1069.999023,373.998840 1070.019287,374.015747 1070.019287,374.015747 
	C1069.249023,374.680756 1068.478882,375.345795 1067.348755,376.002563 
	C1066.988892,375.994324 1066.901733,375.914825 1066.901733,375.914825 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1072.069092,372.058502 
	C1071.572876,372.651276 1071.076660,373.244049 1070.299805,373.926270 
	C1070.019287,374.015747 1069.999023,373.998840 1069.988525,373.988708 
	C1069.988770,373.534088 1069.999512,373.089600 1070.003906,372.311096 
	C1069.997681,371.977081 1070.002075,372.000793 1069.990234,371.998718 
	C1070.413208,371.983704 1070.848267,371.970734 1071.641479,371.976227 
	C1071.999756,371.994720 1072.069092,372.058502 1072.069092,372.058502 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1065.958252,376.964508 
	C1066.002319,376.613556 1066.169067,376.336426 1066.680054,376.023987 
	C1066.901733,375.914825 1066.988892,375.994324 1067.030029,376.038635 
	C1066.961792,376.405579 1066.764038,376.659149 1066.235840,376.919739 
	C1065.994019,376.995758 1065.958252,376.964508 1065.958252,376.964508 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1066.009399,377.013794 
	C1065.894165,377.042419 1065.763672,377.053040 1065.795654,377.014069 
	C1065.958252,376.964508 1065.994019,376.995758 1066.009399,377.013794 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1071.967896,371.959961 
	C1072.087646,371.919800 1072.239380,371.914398 1072.229980,371.983734 
	C1072.069092,372.058502 1071.999756,371.994720 1071.967896,371.959961 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1077.048218,368.040955 
	C1076.912964,368.059601 1076.777832,368.078278 1076.824707,368.050476 
	C1077.006958,368.003998 1077.048218,368.040955 1077.048218,368.040955 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1076.988525,367.983154 
	C1077.106445,367.951721 1077.242554,367.941101 1077.213501,367.985718 
	C1077.048218,368.040955 1077.006958,368.003998 1076.988525,367.983154 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M219.088028,1039.095215 
	C219.492523,1039.442505 219.562897,1040.040283 219.633270,1040.638184 
	C219.340149,1040.207397 219.047028,1039.776489 219.088028,1039.095215 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1105.627930,559.128296 
	C1114.656860,562.336548 1120.743896,567.885925 1122.487305,577.723022 
	C1122.513062,579.119934 1122.332520,580.245850 1121.690430,581.743713 
	C1115.388306,582.104675 1109.547729,582.093689 1103.707031,582.082642 
	C1103.756348,581.612000 1103.805908,581.141418 1103.886719,580.317261 
	C1103.960449,574.695557 1103.912964,569.424316 1104.097778,564.161255 
	C1104.156006,562.503357 1104.836792,560.867371 1105.627930,559.128296 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1088.278687,589.745361 
	C1089.322632,589.944519 1089.969971,590.242188 1091.126343,590.276123 
	C1092.062744,589.960571 1092.490112,589.908691 1092.917480,589.856812 
	C1093.254639,589.947266 1093.591919,590.037659 1094.180176,590.200684 
	C1094.605347,595.466553 1094.779175,600.659851 1094.523804,606.414673 
	C1093.677979,607.128296 1093.261475,607.280518 1092.447632,607.413696 
	C1091.621460,608.024048 1090.809448,608.659973 1090.819946,609.281860 
	C1091.039062,622.107788 1091.351562,634.932129 1091.483154,648.181396 
	C1091.320435,650.120483 1091.320190,651.634888 1091.268799,653.551758 
	C1091.147705,655.306274 1091.077881,656.658386 1090.709961,658.004028 
	C1090.023193,657.916992 1089.634399,657.836365 1089.245605,657.755737 
	C1089.245605,641.210510 1089.245605,624.665283 1089.245605,608.042542 
	C1087.173096,607.456360 1085.808105,607.070312 1084.211426,606.618652 
	C1084.054565,604.416687 1083.893311,602.154419 1083.693848,599.353455 
	C1080.579834,599.543762 1077.719849,599.718445 1074.859985,599.893188 
	C1074.828247,599.442871 1074.796509,598.992493 1074.764893,598.542114 
	C1077.626221,598.287048 1080.487671,598.031982 1083.436768,597.769104 
	C1085.266968,595.364868 1081.421753,589.659851 1088.278687,589.745361 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1100.087891,552.563965 
	C1100.115479,554.410400 1100.111450,555.862061 1100.225098,557.736328 
	C1100.533936,565.150635 1100.725342,572.142395 1100.996704,579.551392 
	C1099.660767,580.920166 1098.244751,581.871521 1096.828857,582.822937 
	C1096.452881,581.432861 1095.805298,580.054443 1095.745483,578.650940 
	C1095.393921,570.405884 1095.168823,562.155334 1094.927368,553.905884 
	C1094.871948,552.012573 1094.919189,550.116272 1094.919189,548.221313 
	C1095.542725,547.970154 1096.166260,547.718994 1096.789795,547.467834 
	C1097.878662,549.034973 1098.967407,550.602112 1100.087891,552.563965 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1092.958252,589.400269 
	C1092.490112,589.908691 1092.062744,589.960571 1091.311768,590.015625 
	C1090.686646,579.673401 1090.385132,569.328003 1090.116699,558.518555 
	C1091.084229,559.630005 1092.775635,561.181152 1092.827271,562.785034 
	C1093.107788,571.497437 1092.983643,580.222839 1092.958252,589.400269 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1103.517090,582.275635 
	C1109.547729,582.093689 1115.388306,582.104675 1121.707520,582.087891 
	C1122.450439,582.501099 1122.714722,582.942017 1123.004272,583.650879 
	C1116.651001,583.976257 1110.272461,584.033752 1103.468140,584.081543 
	C1103.137207,583.537415 1103.232056,583.003052 1103.517090,582.275635 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M86.943420,925.072632 
	C87.242821,925.916199 87.199440,926.726624 87.113403,927.952637 
	C86.428185,927.491394 85.785622,926.614502 85.190392,925.378418 
	C85.692032,925.025879 86.146332,925.032593 86.943420,925.072632 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1834.991089,902.920166 
	C1842.075073,902.634644 1849.158936,902.349121 1856.726074,902.154907 
	C1858.164307,903.936218 1854.106934,904.480408 1856.236816,906.456848 
	C1856.246948,906.466187 1856.078369,906.727722 1855.952637,906.807434 
	C1855.824707,906.888367 1855.646484,906.889832 1855.233521,906.950256 
	C1849.999146,907.282654 1845.021362,907.590942 1839.645264,907.858337 
	C1838.128662,907.486267 1837.010376,907.155090 1835.548340,906.730957 
	C1835.204468,906.637939 1835.156494,906.469849 1835.467896,906.320862 
	C1836.567139,906.056885 1837.354980,905.941956 1838.079346,905.836243 
	C1837.004883,904.821594 1835.998047,903.870850 1834.991089,902.920166 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1865.214966,903.937622 
	C1873.778687,903.926270 1881.869629,903.926270 1889.960449,903.926270 
	C1887.138916,907.519409 1863.931519,907.805664 1858.972290,904.877258 
	C1861.606201,904.453491 1863.174072,904.201233 1865.214966,903.937622 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M2096.102051,1234.714844 
	C2096.636719,1235.193848 2096.764648,1235.717529 2096.645508,1236.177246 
	C2096.608154,1236.321777 2095.906738,1236.294189 2095.509033,1236.345215 
	C2095.539551,1235.808472 2095.569824,1235.271729 2096.102051,1234.714844 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1799.250244,907.616455 
	C1798.909912,907.840088 1798.476929,907.853943 1798.262085,907.876465 
	C1792.552124,907.866394 1787.054565,907.905518 1781.564575,907.721436 
	C1780.770752,907.694824 1780.012817,906.596130 1779.238281,905.993652 
	C1779.939697,905.460815 1780.633911,904.475159 1781.343506,904.464050 
	C1790.160156,904.325867 1798.979248,904.318176 1807.797607,904.318237 
	C1808.471436,904.318237 1809.145264,904.630920 1809.819214,904.798218 
	C1809.536865,905.409363 1809.254639,906.020508 1808.972412,906.631592 
	C1805.845093,906.885315 1802.717773,907.139099 1799.250244,907.616455 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1815.053833,903.423096 
	C1820.491821,902.829285 1825.999756,902.643250 1831.864502,902.978577 
	C1832.458740,903.984619 1832.696045,904.469238 1832.933350,904.953857 
	C1832.933228,904.953857 1832.448120,905.002625 1832.205444,905.028320 
	C1827.500000,905.033508 1823.034668,905.090088 1818.576172,904.940063 
	C1817.414185,904.901001 1816.273926,904.218018 1815.053833,903.423096 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1830.442993,906.066162 
	C1830.837402,906.377197 1830.683838,906.601440 1830.245483,906.844727 
	C1826.013306,906.938171 1821.971802,906.948059 1817.467529,906.947510 
	C1817.004883,906.937012 1817.021362,906.483826 1817.027100,906.257507 
	C1821.351685,906.035400 1825.670410,906.039612 1830.442993,906.066162 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2039.135254,1215.944824 
	C2039.112427,1215.313599 2039.102417,1215.147827 2039.103271,1214.982056 
	C2039.144165,1206.384033 2040.381104,1205.538574 2048.880371,1207.773438 
	C2046.826294,1209.231079 2044.496216,1210.884644 2042.166016,1212.538086 
	C2044.063843,1213.679199 2045.961792,1214.820435 2047.311523,1215.632080 
	C2043.507080,1216.432983 2043.507080,1216.432983 2039.936890,1223.759644 
	C2039.675659,1221.309814 2039.414429,1218.860107 2039.135254,1215.944824 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1140.896729,586.993408 
	C1141.451172,590.568298 1141.900269,594.127686 1141.969604,597.694458 
	C1142.107544,604.796936 1142.348145,611.921204 1141.945435,619.002319 
	C1141.664185,623.947632 1143.142578,627.589294 1147.019531,630.525574 
	C1149.230713,632.200317 1151.226196,634.159851 1153.660645,635.996460 
	C1154.002319,636.001770 1153.986816,635.992310 1153.986816,635.992310 
	C1158.308350,640.477173 1162.645630,644.946838 1166.945923,649.452026 
	C1170.037109,652.690735 1173.078613,655.976990 1176.660645,659.794556 
	C1178.463867,657.674561 1179.781616,656.125366 1181.537354,654.227478 
	C1183.052490,654.062622 1184.129395,654.246399 1185.095703,654.731750 
	C1183.681641,656.228699 1182.378296,657.424133 1180.954346,658.730225 
	C1181.385376,659.446228 1181.590332,660.154236 1182.061401,660.514404 
	C1189.785400,666.421143 1194.229736,674.991211 1199.906250,682.549316 
	C1200.548096,683.403809 1200.808594,685.091431 1200.425293,686.071777 
	C1199.005249,689.702942 1196.999146,693.110962 1195.662598,696.767090 
	C1193.569336,702.493164 1195.776001,707.798035 1197.679443,713.163025 
	C1197.264771,713.574158 1197.073975,713.858032 1196.883057,714.141846 
	C1191.158447,705.219421 1191.124146,696.481567 1197.978149,687.960510 
	C1196.552612,686.194946 1195.223633,684.549011 1193.891235,682.504028 
	C1193.887817,682.104980 1194.016113,681.980286 1194.327393,682.001221 
	C1195.053955,682.051758 1195.469116,682.081238 1195.940186,682.414062 
	C1196.599731,683.448547 1197.203369,684.179871 1197.807129,684.911133 
	C1198.045776,684.691223 1198.284546,684.471313 1198.523315,684.251404 
	C1197.711426,683.477478 1196.899536,682.703552 1195.921753,681.639648 
	C1193.199829,678.865967 1190.643677,676.382141 1187.976440,673.682617 
	C1187.673340,673.190063 1187.419434,672.993103 1187.007324,672.736145 
	C1186.791504,672.561462 1186.671997,672.526550 1186.552490,672.491638 
	C1186.654663,672.701050 1186.756836,672.910461 1186.975830,673.334839 
	C1187.303467,673.810120 1187.567749,673.993835 1187.902100,674.413208 
	C1187.958618,675.140259 1187.998535,675.554932 1187.967285,676.039062 
	C1187.895996,676.108521 1187.755493,676.249268 1187.755493,676.249268 
	C1182.684937,677.126587 1177.614380,678.003967 1171.645752,679.036682 
	C1171.409668,680.610413 1170.971313,683.533142 1170.532837,686.455811 
	C1170.040039,686.447388 1169.547241,686.438904 1169.054443,686.430481 
	C1169.054443,684.319763 1168.915161,682.197205 1169.093140,680.101624 
	C1169.277222,677.934265 1169.769531,675.793091 1169.026367,673.244690 
	C1168.409546,674.010803 1167.792725,674.776917 1167.002930,675.757812 
	C1166.439575,674.983826 1165.920898,674.271362 1165.535278,673.741638 
	C1163.879272,674.527710 1162.292480,675.280884 1160.339844,675.597473 
	C1159.953125,673.798218 1159.932251,672.435608 1159.911255,671.072998 
	C1166.317261,671.072998 1172.723267,671.072998 1179.129395,671.072998 
	C1179.140137,671.580994 1179.150879,672.088928 1179.161621,672.596924 
	C1179.820557,671.702332 1180.479492,670.807800 1181.799316,669.911133 
	C1182.937256,669.982483 1183.414185,670.055969 1183.981934,670.267090 
	C1184.190063,670.436707 1184.307373,670.468750 1184.424683,670.500793 
	C1184.317383,670.305054 1184.210205,670.109253 1183.964844,669.658691 
	C1183.238892,668.925415 1182.651123,668.446960 1182.063232,667.968506 
	C1182.063232,667.968506 1182.023438,667.978638 1181.928101,667.784668 
	C1181.547974,667.714722 1181.263062,667.838745 1180.978271,667.962769 
	C1179.349487,666.917725 1177.720581,665.872681 1175.988281,664.468506 
	C1175.884888,664.109314 1176.030518,663.960327 1176.339355,663.940063 
	C1175.203003,661.732239 1173.757935,659.544556 1172.312744,657.356934 
	C1171.914062,657.624207 1171.515259,657.891479 1171.116577,658.158691 
	C1172.076050,659.440491 1173.035645,660.722290 1173.973755,662.027832 
	C1173.952393,662.051575 1173.908447,662.097961 1173.908447,662.097961 
	C1169.550781,658.146301 1165.088989,654.302368 1160.858154,650.219299 
	C1153.352051,642.975098 1153.429565,642.933289 1145.917236,650.409180 
	C1140.287354,656.011902 1134.601074,661.557800 1128.490601,667.090210 
	C1128.041382,667.051392 1127.797607,666.817078 1127.797607,666.817078 
	C1133.317017,660.896545 1138.827148,654.967346 1144.362793,649.062134 
	C1145.610596,647.731079 1147.206299,646.650208 1148.171143,645.151184 
	C1149.175781,643.590393 1150.666504,640.955383 1150.061279,640.052673 
	C1149.008545,638.482361 1146.508423,637.882324 1144.046875,636.573425 
	C1143.306152,635.347046 1141.986450,633.247375 1140.755127,631.097229 
	C1139.728638,629.304810 1138.805664,627.453125 1138.270020,625.199890 
	C1139.139526,623.144348 1139.935181,621.520874 1139.954346,619.888245 
	C1140.083862,608.911499 1140.048096,597.932922 1140.066040,586.954895 
	C1140.250610,586.963623 1140.435181,586.972412 1140.896729,586.993408 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1156.983765,547.482117 
	C1158.378052,547.416565 1159.761841,547.696899 1161.489990,548.055664 
	C1155.190186,558.095825 1148.573608,568.076233 1141.863281,577.993225 
	C1141.104736,579.114258 1139.817627,579.877502 1138.441406,580.716553 
	C1140.223022,576.428833 1142.130737,572.109314 1144.501587,568.060974 
	C1148.504517,561.226196 1152.800537,554.563232 1156.983765,547.482117 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1140.018311,586.677002 
	C1140.048096,597.932922 1140.083862,608.911499 1139.954346,619.888245 
	C1139.935181,621.520874 1139.139526,623.144348 1138.288330,624.807495 
	C1137.882812,624.459778 1137.891846,624.076660 1138.097656,623.284119 
	C1138.139648,622.583923 1137.984863,622.293030 1137.830200,622.002197 
	C1137.561401,618.760498 1137.104614,615.521423 1137.060059,612.276672 
	C1136.943848,603.820251 1137.008423,595.361389 1137.017578,586.430786 
	C1138.013184,586.105042 1138.991821,586.252136 1140.018311,586.677002 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M265.123230,1044.633423 
	C264.490936,1042.070557 263.416687,1039.682129 263.901245,1037.668701 
	C264.348572,1035.810059 266.648682,1032.902710 267.810272,1033.047119 
	C269.744141,1033.288086 272.583710,1035.369141 272.924225,1037.093506 
	C273.400238,1039.504150 272.418243,1042.602661 271.059174,1044.782837 
	C270.499481,1045.680786 267.338806,1044.957275 265.123230,1044.633423 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2308.930908,702.696472 
	C2304.256104,699.286133 2299.577148,695.526917 2294.928223,691.416626 
	C2299.614502,694.826233 2304.270508,698.586914 2308.930908,702.696472 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1690.976929,877.940369 
	C1671.213379,878.199158 1651.449829,878.458008 1631.252930,878.788818 
	C1630.437134,878.848389 1630.054810,878.836060 1629.084473,878.800903 
	C1628.293823,878.821472 1628.091187,878.864807 1627.888672,878.908142 
	C1627.500122,878.877075 1627.111572,878.846008 1626.432861,878.850037 
	C1624.326294,878.859436 1622.510010,878.833740 1620.187500,878.657532 
	C1619.257935,879.433411 1618.834717,880.359802 1618.411377,881.286255 
	C1617.881348,883.546082 1617.351196,885.805908 1616.479980,887.846436 
	C1616.138794,887.627136 1616.154419,887.215454 1616.154419,887.215454 
	C1618.261230,880.786255 1616.683838,878.268555 1610.363647,878.185913 
	C1610.076416,877.670776 1610.098145,877.435303 1610.384766,877.183228 
	C1610.826538,877.163269 1611.003540,877.159851 1611.451660,877.220093 
	C1612.089355,877.242798 1612.455933,877.201904 1613.202148,877.145386 
	C1614.780640,876.497192 1615.979492,875.864685 1617.401855,875.447754 
	C1618.450928,876.073792 1619.276611,876.484192 1620.509521,876.937317 
	C1622.970093,876.320679 1625.023560,875.661255 1627.404785,874.938232 
	C1628.819336,873.976929 1629.905884,873.079163 1631.414185,871.833130 
	C1629.916992,871.020447 1628.966553,870.504517 1628.049072,869.699097 
	C1628.375366,868.923767 1628.668457,868.438049 1628.980713,867.975220 
	C1629.000000,867.998108 1628.958984,867.957153 1629.302002,867.911377 
	C1630.099121,867.920959 1630.553101,867.976257 1631.139404,868.321289 
	C1633.424194,870.954529 1634.435669,869.783997 1635.417236,867.353882 
	C1636.505981,867.191406 1637.225586,867.059998 1638.152222,867.243286 
	C1639.051514,867.859314 1639.743774,868.160645 1640.272949,868.832336 
	C1641.374268,870.280090 1642.533569,872.086670 1643.924805,872.287781 
	C1647.131226,872.751221 1650.450317,872.434875 1653.945435,872.434875 
	C1654.285400,870.852600 1654.651489,869.148865 1655.072266,867.190796 
	C1652.481445,866.763245 1650.239014,866.393250 1648.019531,865.745728 
	C1648.050171,865.282837 1648.057739,865.097412 1648.449219,864.902954 
	C1650.347778,864.893921 1651.862427,864.893921 1653.723022,864.893921 
	C1652.940552,863.557800 1652.472168,862.757935 1652.003052,861.591248 
	C1651.689575,860.592163 1651.376831,859.959900 1651.063965,859.327637 
	C1650.712158,860.244324 1650.360474,861.161011 1649.934814,862.351624 
	C1649.291260,863.086121 1648.721680,863.546570 1647.798584,864.015625 
	C1646.755371,863.412537 1645.676392,862.920715 1645.444824,862.168091 
	C1644.546265,859.247314 1643.635864,860.782654 1642.639160,861.570312 
	C1642.438110,860.574585 1642.243164,860.028320 1642.071777,859.463745 
	C1642.095337,859.445496 1642.094727,859.442078 1642.094727,859.442078 
	C1642.932861,857.909241 1643.770996,856.376343 1645.142700,854.430420 
	C1648.489990,855.393250 1651.356812,859.198853 1654.350830,853.733643 
	C1655.128174,853.549622 1655.577759,853.466431 1656.027222,853.383179 
	C1656.510132,854.370483 1656.993042,855.357849 1657.484741,857.076294 
	C1657.597656,858.870789 1657.701782,859.934143 1657.703613,861.383789 
	C1657.557251,863.585571 1657.513428,865.401062 1657.017456,867.293274 
	C1656.255859,870.118835 1655.946411,872.867737 1655.505371,876.786316 
	C1658.450073,874.819580 1660.308350,873.578430 1662.111572,872.374023 
	C1660.464478,871.169861 1659.354126,870.358093 1658.589355,869.437988 
	C1660.515015,869.583313 1662.094971,869.837036 1663.715454,870.467285 
	C1663.755859,872.521790 1663.755859,874.199646 1663.755859,875.877563 
	C1664.176025,875.873047 1664.596313,875.868469 1665.016479,875.863953 
	C1665.132568,874.472534 1665.248657,873.081055 1665.627075,871.447388 
	C1667.555664,870.810120 1669.221680,870.415161 1670.887817,870.020142 
	C1670.761108,872.108093 1670.634277,874.195984 1670.493286,876.517883 
	C1676.862061,876.517883 1683.912231,876.517883 1690.936157,876.754639 
	C1690.954468,877.306152 1690.976807,877.622498 1690.976929,877.940369 
M1654.135010,875.385620 
	C1653.689941,874.642700 1653.288452,873.287598 1652.793091,873.252502 
	C1648.251465,872.930054 1643.689453,872.680908 1639.143433,872.798218 
	C1638.145386,872.824036 1637.187256,874.391052 1636.210815,875.248352 
	C1636.421143,875.578491 1636.631470,875.908630 1636.841919,876.238770 
	C1642.405518,876.238770 1647.969238,876.238770 1654.135010,875.385620 
M1633.485840,876.319275 
	C1633.520874,875.572815 1633.555908,874.826355 1633.590942,874.079956 
	C1633.159668,874.086731 1632.728394,874.093567 1632.297241,874.100342 
	C1632.349243,874.843079 1632.401367,875.585754 1633.485840,876.319275 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1627.909424,879.383789 
	C1628.091187,878.864807 1628.293823,878.821472 1628.799805,878.846069 
	C1629.082153,886.182678 1629.243408,893.458069 1628.972534,900.717468 
	C1628.807739,905.134277 1630.537476,906.631104 1634.796265,906.207581 
	C1637.739868,905.914856 1640.750122,905.999451 1643.702515,906.248779 
	C1644.731934,906.335754 1645.677246,907.420776 1646.660889,908.050049 
	C1645.633545,908.491394 1644.640381,909.067566 1643.569946,909.338928 
	C1642.320435,909.655518 1641.003296,909.705750 1639.359863,909.939148 
	C1639.004272,910.006775 1638.527466,909.944214 1638.287598,909.932434 
	C1628.047852,909.865234 1618.047729,909.809814 1608.047729,909.754395 
	C1608.021851,908.935791 1607.995972,908.117126 1607.970093,907.298523 
	C1614.470581,906.780334 1620.971069,906.262207 1627.930176,905.707458 
	C1627.930176,896.980469 1627.930176,888.419983 1627.909424,879.383789 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1519.172119,905.337158 
	C1520.416748,904.586975 1521.684814,903.872559 1522.900879,903.078369 
	C1524.837646,901.813477 1526.739746,900.495300 1529.026123,899.519226 
	C1527.984253,901.097839 1526.572510,902.356934 1524.721313,904.008057 
	C1529.437134,904.922058 1531.169312,902.906677 1531.632935,899.206970 
	C1534.813965,899.103027 1538.307373,898.162048 1539.694092,902.748840 
	C1538.933350,903.764771 1538.385986,904.326477 1537.838623,904.888245 
	C1536.722900,905.633179 1535.607178,906.378113 1534.169189,907.239380 
	C1529.208618,907.273132 1524.570557,907.190430 1519.494873,906.772339 
	C1519.095703,906.070374 1519.133789,905.703796 1519.172119,905.337158 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1538.107178,905.219849 
	C1538.385986,904.326477 1538.933350,903.764771 1539.764404,903.106628 
	C1542.174194,901.672424 1544.300537,900.334595 1547.315430,898.437622 
	C1547.533813,900.513611 1547.769897,902.758118 1548.008789,905.413330 
	C1544.799683,905.733215 1541.587646,905.642334 1538.107178,905.219849 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1544.786865,912.824219 
	C1545.828857,911.805359 1547.000732,910.896851 1548.172607,909.988342 
	C1548.400146,910.363037 1548.627686,910.737732 1548.855347,911.112427 
	C1547.835571,911.724487 1546.815796,912.336487 1545.480469,913.058472 
	C1545.165039,913.168457 1544.916870,912.934631 1544.786865,912.824219 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1096.469604,490.116089 
	C1096.085815,482.669250 1096.074585,475.236908 1096.167480,467.331573 
	C1100.817749,467.563324 1099.978027,471.076569 1100.009277,474.094849 
	C1100.057129,478.719788 1099.945801,483.348602 1100.096436,487.969421 
	C1100.186523,490.731476 1099.006714,491.037506 1096.469604,490.116089 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1098.842163,494.167603 
	C1099.080322,499.851471 1099.124390,505.613037 1098.873047,511.361694 
	C1098.753052,514.107361 1098.072266,516.828491 1097.325806,519.779175 
	C1096.978149,512.019531 1096.950195,504.041534 1096.866943,495.612915 
	C1097.383301,494.854919 1097.954956,494.547485 1098.842163,494.167603 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M86.711670,932.804077 
	C86.962204,934.817932 86.851654,936.997131 86.397278,939.330994 
	C86.152489,937.313538 86.251534,935.141541 86.711670,932.804077 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1141.790894,544.747620 
	C1141.996948,544.635864 1142.457764,544.779968 1142.771484,545.179565 
	C1142.431519,545.291199 1142.238647,545.147278 1141.790894,544.747620 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2526.731934,1048.063965 
	C2524.132080,1049.062622 2521.331055,1049.768066 2518.529785,1050.473511 
	C2518.263916,1049.999390 2517.997803,1049.525391 2517.731934,1049.051392 
	C2519.110107,1046.195435 2520.488281,1043.339478 2522.392822,1039.392822 
	C2524.052002,1042.751953 2525.291260,1045.261353 2526.731934,1048.063965 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M403.544800,937.472290 
	C403.571594,937.519348 403.518005,937.425232 403.544800,937.472290 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2296.185059,1034.509521 
	C2296.541260,1034.248291 2296.907959,1034.238770 2297.548828,1034.244385 
	C2297.280518,1034.426758 2296.737793,1034.594116 2296.185059,1034.509521 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M970.687073,456.436981 
	C971.238770,456.842499 971.790466,457.248016 972.617554,457.613403 
	C972.942749,457.870026 972.992554,458.166779 973.137329,458.905090 
	C973.764587,460.506439 974.296753,461.666199 974.903076,462.987366 
	C973.438538,464.519440 971.971741,466.053894 970.074463,468.038696 
	C972.536682,470.993958 974.958923,473.901215 977.381165,476.808472 
	C977.009705,477.139740 976.638306,477.470978 976.266907,477.802216 
	C973.758240,474.909454 971.249634,472.016693 967.826294,468.069122 
	C966.995361,471.504211 966.492432,473.583313 966.198914,474.796753 
	C964.252869,473.563019 961.962280,472.110901 959.271362,470.626007 
	C958.800903,470.355133 958.650818,470.189331 958.252197,470.060669 
	C957.756470,468.507324 957.429382,466.989227 957.017151,465.076202 
	C959.411194,463.048248 962.035645,460.825104 964.842041,458.323120 
	C966.176147,457.184814 967.328308,456.325378 969.036743,455.554199 
	C969.957764,455.907288 970.322388,456.172150 970.687073,456.436981 
M963.331665,465.949127 
	C962.203186,465.741974 961.074707,465.534851 959.264404,465.202545 
	C960.655212,467.207581 961.502808,468.429596 962.889465,470.428711 
	C963.358582,468.553375 963.678040,467.276093 964.277893,465.902557 
	C964.185303,465.935455 964.092651,465.968323 963.331665,465.949127 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M970.840027,456.133545 
	C970.322388,456.172150 969.957764,455.907288 969.359985,455.389893 
	C969.080627,454.304138 969.034363,453.470978 968.990723,452.315643 
	C969.719482,451.972412 970.445679,451.951355 971.531250,451.907806 
	C971.890686,451.885284 971.982056,451.975830 972.006470,452.352600 
	C972.021179,453.178345 972.011414,453.627319 972.001648,454.076294 
	C971.665405,454.660889 971.329163,455.245483 970.840027,456.133545 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M950.938843,473.599213 
	C950.516602,474.207886 950.036072,474.460114 949.555542,474.712341 
	C949.724854,474.100159 949.894226,473.487976 950.323853,472.729980 
	C950.682861,472.803711 950.781738,473.023254 950.938843,473.599213 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1034.381470,415.908630 
	C1032.153809,413.562561 1030.217651,411.072906 1028.262573,407.985474 
	C1029.141357,406.887268 1030.039062,406.386871 1030.936646,405.886475 
	C1032.314087,407.439026 1033.691528,408.991608 1035.257080,410.734802 
	C1037.030884,412.832764 1037.085083,414.495697 1034.381470,415.908630 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1030.738770,405.618439 
	C1030.039062,406.386871 1029.141357,406.887268 1028.109375,407.665833 
	C1025.215942,410.034912 1022.457092,412.125854 1019.376831,414.385742 
	C1019.268066,413.597046 1019.480591,412.639343 1019.845276,411.338928 
	C1020.907898,410.528168 1021.818359,410.060028 1022.878052,409.309204 
	C1023.342102,408.551239 1023.656921,408.075958 1023.984375,407.298920 
	C1024.558472,406.835083 1025.120117,406.673035 1025.843872,406.260010 
	C1027.517700,405.789520 1029.029297,405.569946 1030.738770,405.618439 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1040.904053,398.731598 
	C1040.843018,399.051575 1040.636719,399.024200 1040.153564,399.010254 
	C1039.116943,399.670227 1038.563599,400.344116 1038.010132,401.018005 
	C1038.010132,401.017975 1038.000366,401.001404 1038.008911,401.007935 
	C1037.602905,400.212067 1037.188354,399.409668 1036.894531,398.312134 
	C1037.358521,397.947693 1037.629395,397.768707 1037.921143,397.250031 
	C1038.718018,396.968140 1039.421265,396.916199 1040.550171,396.921539 
	C1040.975708,396.978790 1041.116333,397.120575 1041.116455,397.120575 
	C1041.065918,397.550934 1041.015503,397.981293 1040.904053,398.731598 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1034.080200,402.068176 
	C1033.906372,402.056610 1033.732544,402.045044 1033.774170,402.008362 
	C1033.989502,401.983276 1034.080200,402.068176 1034.080200,402.068176 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1033.947632,401.937378 
	C1034.071533,401.894806 1034.237305,401.898102 1034.241699,401.984802 
	C1034.080200,402.068176 1033.989502,401.983276 1033.947632,401.937378 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2057.515381,1234.559326 
	C2057.541748,1234.615479 2057.489014,1234.503296 2057.515381,1234.559326 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M113.960747,814.092773 
	C113.652771,813.294617 113.344803,812.496399 113.435410,811.186951 
	C116.259834,810.114502 118.685677,809.553345 121.114105,809.384399 
	C121.109085,810.817444 121.101471,811.858154 120.833214,813.207886 
	C118.368629,813.708862 116.164688,813.900818 113.960747,814.092773 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M113.972900,814.369263 
	C116.164688,813.900818 118.368629,813.708862 121.229401,813.226318 
	C123.918404,813.565186 125.950577,814.194641 127.734085,815.144226 
	C124.656319,815.660767 121.827202,815.857178 118.998085,816.053528 
	C117.585190,816.008118 116.172287,815.962708 114.340530,815.831665 
	C113.942802,815.379211 113.963928,815.012451 113.972900,814.369263 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M121.111511,808.992188 
	C118.685677,809.553345 116.259834,810.114502 113.517242,810.842590 
	C112.988052,811.004089 112.775604,810.998596 112.244583,810.970581 
	C112.516724,810.139038 113.107437,809.329895 113.836288,808.252075 
	C114.498474,807.680054 115.022537,807.376709 115.828522,806.954590 
	C117.869698,806.929382 119.628952,807.022949 121.730042,807.475647 
	C121.751755,808.220581 121.431633,808.606384 121.111511,808.992188 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1040.903320,396.910004 
	C1041.629150,396.200531 1042.427368,395.559814 1043.609619,394.953735 
	C1043.993530,394.988342 1044.066895,395.055634 1044.066895,395.055634 
	C1043.290039,395.708740 1042.513184,396.361816 1041.426270,397.067749 
	C1041.116333,397.120575 1040.975708,396.978790 1040.903320,396.910004 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1043.959961,394.951660 
	C1044.076294,394.908936 1044.226318,394.902893 1044.221680,394.976257 
	C1044.066895,395.055634 1043.993530,394.988342 1043.959961,394.951660 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1089.699463,360.020386 
	C1089.250732,360.679291 1088.474731,361.366119 1087.343506,362.037689 
	C1087.783081,361.364410 1088.577637,360.706360 1089.699463,360.020386 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1090.775391,359.088715 
	C1090.846558,359.296478 1090.674561,359.591064 1090.244263,359.956177 
	C1090.168091,359.743011 1090.350098,359.459259 1090.775391,359.088715 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1091.770996,358.082886 
	C1091.858154,358.311707 1091.698364,358.618652 1091.270264,358.972351 
	C1091.176147,358.733093 1091.350098,358.447083 1091.770996,358.082886 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1092.773560,357.080017 
	C1092.859375,357.307434 1092.699219,357.614655 1092.271851,357.969971 
	C1092.178955,357.731995 1092.353271,357.445892 1092.773560,357.080017 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1518.226318,949.102295 
	C1518.431885,949.069763 1518.628418,949.315002 1518.873779,949.807617 
	C1518.719238,949.804626 1518.484009,949.579651 1518.226318,949.102295 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M2442.993896,920.992004 
	C2442.898682,921.021973 2442.809814,921.059875 2442.868164,921.055298 
	C2443.015625,921.012695 2443.000000,921.000000 2442.993896,920.992004 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1833.123901,905.227661 
	C1832.696045,904.469238 1832.458740,903.984619 1832.110718,903.250549 
	C1832.747803,902.979431 1833.495728,902.957703 1834.617310,902.928101 
	C1835.998047,903.870850 1837.004883,904.821594 1838.079346,905.836243 
	C1837.354980,905.941956 1836.567139,906.056885 1835.463135,906.233582 
	C1834.536255,906.030701 1833.925293,905.766052 1833.123901,905.227661 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M145.173950,1118.764893 
	C144.661469,1118.783813 144.336578,1118.643555 143.753143,1118.443115 
	C143.037613,1118.082764 142.580597,1117.782593 142.005676,1117.234131 
	C143.012009,1116.581665 144.136215,1116.177490 145.585281,1115.967773 
	C145.904404,1116.585815 145.898651,1117.009521 146.020050,1117.726929 
	C145.850922,1118.169556 145.589035,1118.364502 145.173950,1118.764893 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M377.020233,1195.017090 
	C377.431885,1193.972290 377.826019,1192.911499 378.220154,1191.850830 
	C378.722504,1192.116211 379.224792,1192.381714 379.727081,1192.647095 
	C379.069977,1193.460083 378.412872,1194.273071 377.362854,1195.026611 
	C376.969910,1194.967163 377.002747,1195.001099 377.020233,1195.017090 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M248.487137,1081.705566 
	C248.370636,1081.811890 248.309296,1081.445557 248.309296,1081.445557 
	C248.309296,1081.445557 248.603638,1081.599121 248.487137,1081.705566 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M118.843842,1152.707764 
	C119.805237,1152.355103 121.022102,1152.217407 122.238976,1152.079834 
	C122.257149,1152.293213 122.275322,1152.506592 122.293503,1152.719971 
	C121.228775,1152.787598 120.164047,1152.855103 118.843842,1152.707764 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M119.564705,1219.550415 
	C118.904785,1219.278931 118.244865,1219.007568 117.584938,1218.736084 
	C117.604218,1218.462769 117.623489,1218.189331 117.642769,1217.916016 
	C118.470314,1217.916016 119.297867,1217.916016 120.044701,1218.488037 
	C119.830894,1219.223389 119.697792,1219.386963 119.564705,1219.550415 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M120.186554,1218.835449 
	C120.262733,1219.003540 120.116348,1219.396240 119.767334,1219.669678 
	C119.697792,1219.386963 119.830894,1219.223389 120.186554,1218.835449 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M435.781372,1160.266113 
	C437.267426,1159.278809 438.690918,1158.649292 440.140350,1158.008179 
	C440.658478,1161.446777 440.658478,1161.446777 435.781372,1160.266113 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M422.302673,1159.197266 
	C421.965149,1158.989380 421.964539,1158.781860 421.974823,1158.263428 
	C422.203674,1158.367676 422.421631,1158.782715 422.302673,1159.197266 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M58.423248,1212.117798 
	C58.873901,1212.571411 59.059631,1212.830688 59.537342,1213.149658 
	C60.148193,1213.620483 60.547680,1213.973755 61.164742,1214.481812 
	C62.983730,1216.714600 64.585144,1218.792603 66.621788,1221.435181 
	C63.787556,1224.579956 60.861332,1227.826904 57.558289,1231.491943 
	C55.755161,1229.950928 54.183357,1228.607544 52.300842,1227.136230 
	C51.850803,1226.708618 51.711475,1226.409058 51.339142,1226.008057 
	C50.653297,1225.275757 50.200466,1224.644897 49.465179,1223.905762 
	C49.083282,1223.480713 48.898651,1223.225342 48.412781,1222.928711 
	C48.588978,1218.959229 53.951900,1213.073242 58.423248,1212.117798 
M59.212101,1219.919556 
	C58.393681,1218.872070 57.744556,1217.521118 56.674732,1216.924072 
	C56.237133,1216.679688 54.012470,1218.473022 54.093163,1218.646729 
	C54.805721,1220.181763 55.854099,1221.561035 56.974949,1223.244263 
	C56.457127,1223.494263 55.207241,1224.097778 53.507267,1224.918701 
	C58.111156,1226.402832 59.432755,1225.314331 59.212101,1219.919556 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M59.952194,1191.015869 
	C62.131756,1192.403442 64.311325,1193.791016 67.118599,1195.578125 
	C63.518135,1199.352905 60.592865,1202.419800 56.991688,1206.195435 
	C54.101887,1202.433960 51.419052,1198.941895 48.307888,1194.892212 
	C50.530045,1193.022461 53.793701,1190.276245 57.560261,1187.106934 
	C58.149548,1187.797852 59.239555,1189.075928 59.862007,1190.906006 
	C57.100044,1193.250732 54.805645,1195.043457 51.913639,1197.303101 
	C54.970436,1198.548706 56.829418,1199.306152 59.365505,1200.339478 
	C59.571331,1197.068481 59.761765,1194.042236 59.952194,1191.015869 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M147.275024,1096.287109 
	C147.092957,1096.310303 146.778580,1096.193481 146.665466,1095.977417 
	C146.866714,1095.878174 147.142700,1096.147095 147.275024,1096.287109 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M290.421753,1193.245117 
	C290.698120,1193.209717 290.801300,1193.318848 290.904480,1193.428223 
	C290.685852,1193.415405 290.467194,1193.402588 290.421753,1193.245117 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M57.946430,1246.416870 
	C61.332859,1249.311279 61.646358,1251.918335 58.419758,1253.521729 
	C57.426487,1254.015259 54.728977,1253.434204 54.675274,1253.070190 
	C54.390041,1251.136719 54.451206,1249.051147 54.945122,1247.162964 
	C55.099422,1246.572876 56.975391,1246.433228 57.946430,1246.416870 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M191.515381,1152.505493 
	C190.933182,1152.544189 190.589005,1152.357300 190.244827,1152.170410 
	C190.747681,1152.206909 191.250549,1152.243408 191.515381,1152.505493 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M47.552246,1082.437866 
	C50.166695,1079.471802 52.535507,1076.747681 54.673786,1074.288696 
	C56.155338,1074.948730 56.689312,1075.049683 57.034145,1075.360352 
	C66.443184,1083.839478 66.370308,1085.113647 55.640522,1093.528564 
	C52.661938,1090.649414 49.586273,1087.738281 46.636639,1084.704712 
	C46.407803,1084.469360 47.062576,1083.374878 47.552246,1082.437866 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M52.048859,1124.976318 
	C50.269108,1122.867432 48.475300,1120.741333 46.430866,1118.318237 
	C49.558903,1114.988770 52.565762,1111.788208 55.654942,1108.500122 
	C59.212109,1111.827026 62.409332,1114.817261 66.006439,1118.181396 
	C62.625546,1121.770264 59.712185,1124.862915 56.370590,1128.410034 
	C54.997196,1127.277466 53.786629,1126.279175 52.289307,1125.104980 
	C52.002556,1124.928955 52.034794,1124.959351 52.048859,1124.976318 
M54.057266,1122.867676 
	C55.628151,1122.783203 57.199032,1122.698608 58.530815,1122.627075 
	C58.787930,1120.187500 59.194778,1118.305542 59.139206,1116.437378 
	C59.027924,1112.696289 55.491451,1112.609253 53.428867,1116.288086 
	C54.789139,1116.288086 56.149406,1116.288086 58.115372,1116.288086 
	C56.268723,1118.702393 54.791168,1120.634033 54.057266,1122.867676 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M168.210083,1217.462891 
	C168.465302,1217.756226 168.472458,1218.279419 168.479630,1218.802490 
	C168.307083,1218.432495 168.134552,1218.062622 168.210083,1217.462891 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M311.871216,1247.267090 
	C310.091675,1247.105469 308.730194,1246.910767 307.368683,1246.716064 
	C307.414307,1246.213623 307.459900,1245.711182 307.505524,1245.208740 
	C310.101562,1245.436890 312.697601,1245.665161 315.293640,1245.893433 
	C315.310516,1246.181396 315.327393,1246.469482 315.344269,1246.757446 
	C314.325928,1246.916260 313.307587,1247.074951 311.871216,1247.267090 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M392.800720,1149.980469 
	C391.838318,1149.975098 391.225861,1149.927856 390.613434,1149.880615 
	C390.596832,1149.539307 390.580200,1149.197876 390.563599,1148.856567 
	C391.674255,1148.856567 392.784882,1148.856567 393.895508,1148.856567 
	C393.647247,1149.217285 393.398987,1149.577881 392.800720,1149.980469 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M133.320648,1085.433594 
	C133.298538,1085.219849 133.420746,1085.087158 133.523087,1085.101074 
	C133.624298,1085.114746 133.705475,1085.276489 133.795410,1085.373291 
	C133.682358,1085.441772 133.569305,1085.510254 133.320648,1085.433594 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M190.006943,1125.997314 
	C190.335449,1126.620605 190.652863,1127.250732 190.970276,1127.880859 
	C190.736038,1127.954102 190.501816,1128.027466 190.267578,1128.100708 
	C190.179718,1127.602905 190.091858,1127.105225 189.990143,1126.315430 
	C189.976273,1126.023438 189.995850,1126.004272 190.006943,1125.997314 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M65.865631,1152.501709 
	C65.500717,1152.934937 65.236755,1153.110718 64.907654,1153.576904 
	C64.476196,1153.907349 64.223274,1154.104614 63.914352,1154.591675 
	C63.429379,1155.186035 63.059399,1155.566162 62.523857,1156.158325 
	C56.364674,1162.205688 56.392094,1162.179077 50.474388,1156.081543 
	C49.327965,1154.900269 47.874950,1154.016357 46.326847,1152.811279 
	C48.560677,1150.020874 50.273453,1147.881348 52.031677,1145.402222 
	C52.470669,1144.686523 52.864220,1144.310547 53.335571,1143.674194 
	C53.896561,1143.177002 54.379749,1142.939941 55.025391,1142.474365 
	C58.762630,1145.551514 62.337414,1148.857178 65.865631,1152.501709 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M114.458908,1084.634399 
	C114.726501,1084.343140 115.185371,1084.330566 115.644257,1084.317871 
	C115.312904,1084.516235 114.981552,1084.714722 114.458908,1084.634399 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M131.624374,1096.142090 
	C130.739273,1096.016968 130.048477,1095.652832 129.357681,1095.288696 
	C129.624420,1094.938232 129.891159,1094.587769 130.157867,1094.237305 
	C130.711472,1094.792603 131.265076,1095.347900 131.624374,1096.142090 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M141.795761,1160.956055 
	C142.061234,1161.878052 141.970795,1162.769897 141.711639,1163.609497 
	C141.685410,1163.694458 140.641571,1163.465454 140.070007,1163.382080 
	C140.510696,1162.558472 140.951370,1161.734619 141.795761,1160.956055 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M404.722351,1123.029541 
	C404.991669,1123.382324 404.958313,1123.792725 404.874847,1124.504517 
	C404.307068,1124.597046 403.789398,1124.388062 403.271729,1124.179077 
	C403.654388,1123.815063 404.037018,1123.450928 404.722351,1123.029541 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M396.029236,1195.011230 
	C394.683533,1194.563354 393.310699,1194.103760 391.937805,1193.644043 
	C392.596466,1192.864990 393.255127,1192.085938 393.913818,1191.306885 
	C394.581726,1192.297485 395.249573,1193.288086 395.970001,1194.666748 
	C396.022583,1195.054688 396.002075,1194.999512 396.029236,1195.011230 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M339.029633,1193.021484 
	C338.185455,1192.845337 337.312653,1192.651611 336.439819,1192.457886 
	C336.581207,1192.104614 336.722595,1191.751343 336.863983,1191.398193 
	C337.542511,1191.729858 338.221069,1192.061646 338.968384,1192.727051 
	C339.037140,1193.060669 339.000977,1193.003784 339.029633,1193.021484 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1642.383423,1029.420166 
	C1641.389160,1030.632446 1640.141235,1031.534180 1638.893311,1032.435791 
	C1638.543823,1031.954712 1638.194336,1031.473511 1637.844971,1030.992432 
	C1638.869019,1030.242310 1639.893066,1029.492188 1641.248291,1028.634033 
	C1641.762817,1028.720459 1641.946167,1028.915039 1642.383423,1029.420166 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1656.939575,1032.261230 
	C1655.470093,1032.095825 1654.412476,1031.814087 1653.354858,1031.532471 
	C1653.375366,1031.323730 1653.395874,1031.115112 1653.416382,1030.906372 
	C1655.750977,1030.906372 1658.085571,1030.906372 1660.420166,1030.906372 
	C1660.436523,1031.135986 1660.452759,1031.365601 1660.469116,1031.595215 
	C1659.429810,1031.778564 1658.390625,1031.961914 1656.939575,1032.261230 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1583.763672,971.506409 
	C1583.509277,971.531921 1583.417603,971.429199 1583.325928,971.326416 
	C1583.526001,971.343689 1583.726196,971.360901 1583.763672,971.506409 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1586.268799,971.204163 
	C1586.581543,971.201782 1586.661011,971.427063 1586.740601,971.652405 
	C1586.505615,971.578918 1586.270752,971.505432 1586.268799,971.204163 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1604.610352,996.613525 
	C1604.338013,996.418396 1604.284912,996.034058 1604.231812,995.649658 
	C1604.431030,995.907898 1604.630249,996.166077 1604.610352,996.613525 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1839.508545,1028.118774 
	C1840.273193,1028.402222 1840.673584,1028.764404 1841.073853,1029.126465 
	C1839.995605,1029.444458 1838.917480,1029.762451 1837.839355,1030.080444 
	C1837.730835,1029.829956 1837.622437,1029.579468 1837.514038,1029.328979 
	C1838.057495,1028.951782 1838.600952,1028.574585 1839.508545,1028.118774 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1691.991943,1028.003906 
	C1692.088013,1028.149902 1692.192261,1028.290894 1692.296631,1028.431885 
	C1692.229126,1028.372070 1692.161499,1028.312256 1692.053101,1028.117798 
	C1692.012329,1027.983032 1692.000000,1027.998779 1691.991943,1028.003906 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1646.717041,1040.730225 
	C1646.936035,1041.422363 1646.882324,1041.843262 1646.885986,1042.578369 
	C1646.145996,1042.408325 1645.348511,1041.924194 1644.551147,1041.440186 
	C1645.182251,1041.113037 1645.813232,1040.786011 1646.717041,1040.730225 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M483.002777,487.873413 
	C487.031006,487.894714 491.059204,487.916046 495.932312,487.916473 
	C497.849396,487.926117 498.921600,487.956696 500.024445,488.254456 
	C500.037476,488.700073 500.019836,488.878540 499.737976,489.312256 
	C495.034698,489.765747 490.566010,489.719879 486.167755,490.254028 
	C483.544250,490.572693 481.031342,491.801636 478.121094,492.732574 
	C477.592285,492.367004 475.634003,490.101807 474.593048,490.465118 
	C471.522339,491.536713 468.814789,493.648895 465.223694,495.797485 
	C468.062744,497.498993 469.468414,498.341461 471.108887,499.448181 
	C472.907440,500.444611 474.424011,501.312225 476.045959,501.877411 
	C478.528503,502.742462 481.083740,503.398987 483.674561,504.149353 
	C483.741028,504.154694 483.824249,504.258881 483.862793,504.623230 
	C485.765778,505.688599 487.630280,506.389587 489.628784,507.477905 
	C491.171417,510.768005 493.141815,508.942505 494.847351,508.813293 
	C495.541931,510.342041 496.339233,511.539062 497.457672,512.893677 
	C500.170319,514.221313 502.561798,515.391296 504.946838,516.753784 
	C505.246063,517.132874 505.551758,517.319519 506.213470,517.654175 
	C508.598663,519.194824 510.627777,520.587463 512.252808,521.995239 
	C510.582458,521.652832 509.316223,521.295349 507.763641,520.734619 
	C503.028320,518.647888 498.579315,516.764221 494.046570,514.537720 
	C490.326843,512.462280 486.690857,510.729767 482.968658,508.660034 
	C481.933838,507.857971 480.985260,507.393097 480.052643,506.960510 
	C480.068634,506.992828 480.123993,506.946442 479.936371,506.586670 
	C476.132965,505.472168 472.517181,504.717499 469.154266,504.015594 
	C467.989563,502.144684 466.678436,500.038544 465.367310,497.932404 
	C462.235718,506.617981 464.602814,514.764465 464.137695,522.901245 
	C463.645630,531.509705 464.027283,540.168030 464.027283,549.401611 
	C466.414185,547.441895 468.068512,546.083679 469.921448,544.926575 
	C470.255157,545.258606 470.390259,545.389465 470.389709,545.827026 
	C468.038239,549.404053 465.822418,552.674438 463.338562,555.949036 
	C462.713531,555.968872 462.356476,555.984436 461.907471,555.657227 
	C459.799683,552.763733 457.955414,550.051025 455.731628,547.696777 
	C451.367493,543.076599 448.706635,543.352051 445.900177,547.923828 
	C444.578979,546.882446 443.214478,544.884338 441.844116,544.880310 
	C434.393555,544.858643 426.936768,545.252258 419.495422,545.716553 
	C418.446442,545.782043 417.157227,546.809814 416.522644,547.756470 
	C414.930786,550.131348 405.590759,552.206238 403.494965,550.340820 
	C402.976532,549.879456 402.718597,548.895752 402.699127,548.142883 
	C402.614746,544.877502 402.662964,541.608765 402.662964,536.878601 
	C407.730652,539.814453 413.767548,533.747375 416.950012,541.259949 
	C417.062714,541.525940 417.834076,541.599426 418.303375,541.614990 
	C425.852203,541.865845 433.401794,542.092651 441.324585,542.411987 
	C442.388123,542.168030 443.078339,541.837097 443.768555,541.506104 
	C443.289917,541.275879 442.811249,541.045654 442.218689,540.484985 
	C443.638306,540.144226 445.242859,539.858765 446.678101,540.229187 
	C447.505432,540.442688 448.180756,542.479797 448.717133,542.394348 
	C453.840637,541.578125 456.195953,545.716431 459.587494,548.363770 
	C459.779388,548.736145 459.892242,548.864929 460.124756,549.278687 
	C460.773682,549.768555 461.302948,549.973328 461.832214,550.178101 
	C461.815399,549.392822 461.798553,548.607605 461.784271,547.364624 
	C461.607117,539.947876 461.427368,532.988892 461.293091,525.687256 
	C461.277802,525.122498 461.217133,524.900391 461.145477,524.381470 
	C461.059204,523.696289 460.983917,523.307983 460.838074,522.596680 
	C460.224579,520.872864 459.681580,519.471985 459.562622,517.985718 
	C460.222382,511.500824 461.362366,504.947052 460.308075,498.767609 
	C459.789734,495.729370 455.459991,493.034058 452.393463,490.824097 
	C449.300476,488.595093 441.750000,490.519287 439.966614,493.278656 
	C438.909668,492.327484 437.898895,491.255859 436.699951,490.472137 
	C436.452667,490.310486 434.911346,491.413818 434.974121,491.607727 
	C435.402954,492.931915 435.848206,494.400452 436.757202,495.392761 
	C440.125610,499.069885 443.720306,502.538483 447.144562,506.166046 
	C450.517609,509.739258 453.787048,513.410278 456.752686,517.034912 
	C455.939362,517.001099 455.475250,516.970703 454.755798,516.698669 
	C442.561066,510.586029 430.621674,504.714996 418.682312,498.843994 
	C418.959167,498.287567 419.235992,497.731171 419.512848,497.174744 
	C427.297699,500.966705 435.082550,504.758636 443.207520,508.768860 
	C444.229095,508.812714 444.910492,508.638245 445.591919,508.463806 
	C444.901428,507.699249 444.210968,506.934662 443.472595,505.846252 
	C440.672150,502.640320 437.351685,500.111542 435.290588,496.799133 
	C431.515533,490.732208 426.694855,488.824463 420.167542,491.337341 
	C418.599365,491.941040 417.303864,493.294067 415.942444,494.377289 
	C415.216797,494.954681 414.737000,496.015991 413.943909,496.306305 
	C406.782349,498.928253 402.450409,496.176544 402.666016,488.930939 
	C402.723236,487.007629 404.376251,483.414368 404.785767,483.486542 
	C408.583923,484.155762 413.550262,480.249939 416.169312,486.374542 
	C419.113678,490.455780 422.827393,488.028656 426.132294,487.855194 
	C428.421295,487.735077 430.655151,486.563873 433.288574,485.937256 
	C435.312775,486.920074 435.894470,491.176483 439.478912,487.365234 
	C437.265656,485.472504 435.175873,483.685364 432.796692,481.708801 
	C430.189636,479.742767 427.872009,477.966156 425.554352,476.189575 
	C428.192352,477.170898 430.830322,478.152222 433.698547,478.766144 
	C433.928833,478.398743 433.989258,478.444061 433.989258,478.444061 
	C434.861572,479.465576 435.532928,480.908905 436.640503,481.436615 
	C441.826935,483.907806 447.204285,485.981354 452.368622,488.494476 
	C455.144409,489.845306 457.627197,491.797607 460.244049,493.475616 
	C462.903992,495.181213 464.826782,494.479431 467.166168,492.342712 
	C469.295624,490.397766 472.363495,489.488861 474.994232,488.079468 
	C475.900574,487.593842 477.260345,487.149323 477.511719,486.388275 
	C478.505707,483.379028 480.240692,483.581635 482.285553,485.212097 
	C481.576202,486.174622 481.018250,486.749481 480.460266,487.324341 
	C481.307770,487.507385 482.155273,487.690399 483.002777,487.873413 
M446.978607,509.463318 
	C446.741760,509.380066 446.504883,509.296814 446.268036,509.213562 
	C446.341736,509.446381 446.415436,509.679169 446.978607,509.463318 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M582.938843,508.475739 
	C586.024231,501.895081 589.077209,495.298767 592.235535,488.753326 
	C592.637085,487.921173 593.583069,486.766357 594.288330,486.758240 
	C600.202881,486.689850 602.622131,481.831573 605.953796,478.351807 
	C608.333557,475.866211 610.130859,475.027679 612.785522,478.061035 
	C615.616821,481.296234 618.902771,484.133514 622.419373,487.562988 
	C619.613403,490.619446 617.095459,493.362122 614.907837,495.744995 
	C618.121826,497.658081 622.425171,498.876953 624.255493,501.699524 
	C625.970642,504.344482 625.067749,508.687073 625.341675,512.639771 
	C632.382141,512.639771 640.279724,512.639771 648.958313,512.639771 
	C648.958313,507.936981 648.940918,503.358093 648.962646,498.779419 
	C648.991333,492.736084 649.002747,492.745483 655.159302,493.899506 
	C655.802795,494.020111 656.482300,493.948547 657.587097,494.340912 
	C657.907654,495.189667 657.786194,495.663696 657.283447,496.101837 
	C655.357056,495.832367 653.812134,495.598755 651.079285,495.185516 
	C651.811035,501.965881 652.467163,508.045746 653.014099,514.434448 
	C652.535706,515.527771 652.166504,516.312195 651.433655,517.106262 
	C648.869324,519.016479 646.668579,520.917175 644.469177,522.816650 
	C644.128174,522.136780 643.349060,520.583313 642.569946,519.029846 
	C641.927002,519.146179 641.283997,519.262512 640.640991,519.378784 
	C640.771362,521.552795 640.146362,524.271423 641.222351,525.763489 
	C642.581238,527.647827 645.289368,529.546326 647.482117,529.626892 
	C651.459839,529.773193 653.278992,526.419861 654.624146,522.925293 
	C655.367859,523.078003 655.791382,523.173889 656.171326,523.631836 
	C655.419434,528.497192 652.444275,531.664551 648.319946,531.507629 
	C645.195374,531.388855 641.512817,529.198425 639.331970,526.793274 
	C637.835693,525.143066 638.489807,521.543091 638.212036,518.492432 
	C639.914185,517.070801 641.587036,515.971375 644.111511,514.312256 
	C642.489746,514.147827 641.903137,514.030762 641.318054,514.037537 
	C627.170898,514.200745 613.023987,514.405945 598.876404,514.485901 
	C597.943665,514.491211 597.005737,513.558899 596.530090,513.060913 
	C600.887207,513.059082 604.784485,513.059082 609.136414,513.059082 
	C608.669373,510.903351 607.954651,509.547546 608.195923,508.391174 
	C609.426086,502.494659 607.762695,497.495605 604.354858,492.964294 
	C604.971558,492.898224 605.220093,492.892181 605.448486,492.893250 
	C605.428223,492.900330 605.405701,492.865143 605.632690,493.152649 
	C607.163513,494.646912 608.467346,495.853668 609.595886,496.898132 
	C613.058044,493.764282 616.190308,490.928986 619.297974,488.115997 
	C607.881470,475.789734 610.899231,477.089691 602.498413,485.057434 
	C602.377930,485.171692 602.177063,485.295258 602.171082,485.421875 
	C602.104187,486.848846 601.800537,488.337463 602.104065,489.687256 
	C602.371582,490.876801 603.326721,491.911652 603.616882,493.093933 
	C602.455872,492.604919 601.589905,492.110107 600.867493,491.456604 
	C596.500793,487.506226 595.091797,487.733307 592.418335,492.955566 
	C589.694275,498.276947 587.004578,503.615906 583.957153,508.836426 
	C583.389282,508.642365 583.164062,508.559052 582.938843,508.475739 
M616.601685,499.178436 
	C615.690796,499.447968 614.779907,499.717499 612.976807,500.251068 
	C614.817871,500.748169 615.771790,501.005768 616.755371,501.271332 
	C615.790527,503.595459 614.941345,505.640900 613.900940,508.146973 
	C613.137756,505.966339 612.608826,504.454987 611.824707,502.214447 
	C608.361145,508.856964 610.917114,512.579590 616.822205,512.735229 
	C617.180969,510.411072 617.521301,508.205963 617.861694,506.000824 
	C618.478699,505.812195 619.095703,505.623535 619.712708,505.434906 
	C620.606201,506.784302 621.499695,508.133667 622.466248,509.593445 
	C624.582642,504.543610 623.277710,502.079590 616.601685,499.178436 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M365.823975,363.990997 
	C365.890442,363.339294 365.728394,362.517426 366.064270,362.066315 
	C367.303955,360.401428 368.708405,358.859192 370.050903,357.270874 
	C371.366608,359.049042 373.412140,360.677765 373.814423,362.642914 
	C374.324646,365.135406 374.478424,366.850555 378.138245,365.558655 
	C379.143982,364.827423 379.571289,364.458679 379.998596,364.089935 
	C380.623596,366.754395 381.756866,369.412384 381.790710,372.084320 
	C382.001892,388.742950 381.967102,405.404694 382.031433,422.804016 
	C382.166046,423.809631 382.283081,424.076630 382.400116,424.343628 
	C382.759338,423.962921 383.118561,423.582245 383.477783,423.201569 
	C385.630096,421.428162 387.782410,419.654785 390.421204,417.904480 
	C390.907654,417.927582 391.273407,418.224030 391.160156,418.538818 
	C389.413300,420.686523 387.560730,422.375488 386.226105,424.405060 
	C385.389801,425.676819 385.332458,427.460846 384.524231,429.140411 
	C383.536163,429.408783 382.437622,429.548370 382.436646,429.695251 
	C382.399994,435.201416 382.442230,440.708801 382.587708,446.213043 
	C382.594299,446.463531 383.655579,446.686127 384.200378,447.299805 
	C383.723297,448.821747 383.271484,449.965607 382.496490,451.113495 
	C381.528046,451.118561 380.882782,451.119598 380.102478,450.657349 
	C379.710571,438.279602 379.370483,426.366180 379.218964,414.450378 
	C379.048676,401.055847 379.094452,387.658417 378.889069,374.264679 
	C378.872314,373.172089 377.646790,372.098022 376.928925,370.621216 
	C373.971527,369.814148 371.068176,369.401184 367.831360,368.901184 
	C366.619019,367.926514 365.740082,367.038879 365.132446,365.970825 
	C365.543793,365.190613 365.683868,364.590790 365.823975,363.990997 
M371.180756,366.931061 
	C372.916168,364.079132 372.843018,361.840973 369.926819,360.567719 
	C369.263428,360.829956 368.600037,361.092224 367.936676,361.354462 
	C368.776947,363.217072 369.617218,365.079651 371.180756,366.931061 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M520.961548,456.999878 
	C520.804138,456.434479 520.646667,455.869049 520.174744,454.883179 
	C519.819336,454.278107 519.778320,454.093475 519.855347,453.593872 
	C519.892273,448.750671 516.591980,452.332275 514.782349,451.225250 
	C513.650391,450.740723 512.840881,450.377228 512.125854,449.678040 
	C512.172424,448.883240 512.124573,448.424072 512.223999,447.775513 
	C512.567383,447.315979 512.657166,447.014282 512.640564,446.680969 
	C513.547913,446.580139 514.455322,446.479370 516.062927,446.369263 
	C517.558899,446.320374 518.354614,446.280792 519.469177,446.295654 
	C520.061401,446.042542 520.334778,445.734955 520.608154,445.427368 
	C520.080994,445.266052 519.553772,445.104767 518.682068,444.875366 
	C517.666992,444.612091 516.996399,444.416931 516.325867,444.221802 
	C522.610657,441.364197 518.211182,435.664093 519.562500,431.440460 
	C520.674561,427.964447 520.166504,423.970093 521.298645,420.184021 
	C524.638550,426.291107 522.968018,432.901276 523.680908,440.010132 
	C524.125793,441.063385 524.555664,441.335876 524.985596,441.608337 
	C526.368652,442.074707 527.751770,442.541077 529.858704,442.886292 
	C530.849792,442.816406 531.117004,442.867737 531.400940,443.290283 
	C531.432861,444.401855 531.448120,445.142181 531.279846,446.200562 
	C531.114197,447.004700 531.132019,447.490723 531.060120,448.325348 
	C530.914917,449.390381 530.859375,450.106842 530.474854,450.888702 
	C529.006226,451.599152 527.866638,452.244202 526.382568,452.857483 
	C525.022644,453.546448 524.007202,454.267120 522.995850,454.993073 
	C522.999939,454.998383 522.990906,454.993286 522.750366,455.160980 
	C521.993774,455.885742 521.477661,456.442780 520.961548,456.999878 
M524.565674,446.106049 
	C525.663147,445.902100 526.760620,445.698151 527.858093,445.494202 
	C527.723755,445.018311 527.589417,444.542419 527.455139,444.066528 
	C526.303406,444.498138 525.151672,444.929749 524.565674,446.106049 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M481.223694,447.928619 
	C480.862488,444.259705 480.501251,440.590790 480.040649,436.208313 
	C479.629181,435.337341 479.317078,435.179901 479.004944,435.022491 
	C478.297119,434.253815 477.589325,433.485107 476.896881,432.053528 
	C476.165710,431.547882 475.419189,431.705078 474.672668,431.862305 
	C474.300140,431.873657 473.927643,431.884979 473.021393,432.005066 
	C472.320526,432.410187 472.153351,432.706543 471.986206,433.002899 
	C470.225067,433.671051 468.463898,434.339233 465.946198,435.047791 
	C463.833160,435.306274 462.476654,435.524323 461.120178,435.742371 
	C461.323883,436.995636 461.527588,438.248901 461.769257,439.735779 
	C463.550720,438.118439 464.797180,436.986816 466.043579,435.855164 
	C465.022491,439.557526 464.001373,443.259888 462.980286,446.962250 
	C462.589478,447.002747 462.198639,447.043274 461.807831,447.083801 
	C460.828125,443.209229 459.848419,439.334625 459.196533,435.111023 
	C459.719177,434.502472 459.914032,434.242889 460.108887,433.983307 
	C460.269379,433.455505 460.429871,432.927673 461.174194,432.234528 
	C462.829132,431.973480 463.900177,431.877747 464.971252,431.782043 
	C464.958282,431.551270 464.945312,431.320496 464.932343,431.089752 
	C463.639923,431.014252 462.347504,430.938782 461.055084,430.863281 
	C459.784180,429.093231 459.977905,427.576294 462.182922,427.009033 
	C465.092896,426.260437 467.553528,426.638306 468.222565,430.778473 
	C468.630615,429.736298 469.038696,428.694122 469.875519,427.628906 
	C470.940063,427.099213 471.575836,426.592590 472.211639,426.085968 
	C476.249298,426.501801 480.280548,427.013245 484.327820,427.286346 
	C485.940552,427.395142 487.588806,426.977020 489.366699,427.243378 
	C488.146576,428.327911 486.780579,428.967834 485.268158,429.676392 
	C486.092529,431.327179 486.841248,432.826416 487.725067,434.596252 
	C485.890015,434.596252 484.556061,434.596252 483.143250,434.596252 
	C483.143250,438.538300 483.143250,442.166321 483.110596,446.160156 
	C483.048370,447.013794 483.018768,447.501648 482.693848,447.995911 
	C482.006897,447.977753 481.615295,447.953186 481.223694,447.928619 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M483.143250,445.794342 
	C483.143250,442.166321 483.143250,438.538300 483.143250,434.596252 
	C484.556061,434.596252 485.890015,434.596252 487.725067,434.596252 
	C486.841248,432.826416 486.092529,431.327179 485.268158,429.676392 
	C486.780579,428.967834 488.146576,428.327911 489.631836,427.381744 
	C490.033264,427.088135 490.315430,427.100769 490.842621,427.566010 
	C491.087616,429.948547 491.087616,431.878448 491.087616,433.971558 
	C493.038147,433.971558 494.510101,433.971558 496.301392,434.107300 
	C497.070557,434.443268 497.577667,434.893829 497.961639,434.806061 
	C503.611328,433.514435 502.334442,438.188141 502.906342,440.831818 
	C503.606445,444.068176 502.484985,446.223969 498.483582,446.017120 
	C493.379944,445.753326 488.257568,445.851959 483.143250,445.794342 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M375.644104,336.006409 
	C377.274689,336.505890 378.541504,336.996185 379.269714,337.278046 
	C379.699371,338.979248 380.081909,340.493866 380.622986,342.257843 
	C380.781525,342.507202 380.946320,342.841553 380.709351,342.962524 
	C380.287689,343.393585 380.103027,343.703644 379.918335,344.013733 
	C380.353485,344.773926 380.605133,345.828491 381.259766,346.236694 
	C382.929077,347.277557 384.778290,348.029907 386.839691,349.036438 
	C388.028473,348.543427 389.904205,347.765533 392.111633,347.396667 
	C391.009491,348.713959 389.575684,349.622253 388.141846,350.530548 
	C388.567444,350.966217 388.993042,351.401886 389.418640,351.837524 
	C392.569244,351.999420 394.346375,350.591461 394.313202,347.114380 
	C396.124146,346.847382 397.935120,346.580414 400.208282,346.587402 
	C401.432678,347.282074 402.194885,347.702759 402.957092,348.123474 
	C402.973175,348.464935 403.124084,348.729553 403.504120,349.303680 
	C403.319397,350.733521 403.040405,351.776978 402.761383,352.820435 
	C402.761383,352.820435 402.874939,352.872101 402.573608,352.777985 
	C401.904388,352.615936 401.536469,352.548035 401.168549,352.480133 
	C401.168549,352.480133 401.342438,352.289154 401.222900,351.834351 
	C400.719177,351.180176 400.335022,350.980743 399.950867,350.781342 
	C396.537964,352.352173 393.125031,353.923004 389.460907,355.833710 
	C388.916534,356.145813 388.627899,356.170685 388.343750,356.248199 
	C387.910095,353.142609 386.382965,352.344543 382.961975,354.117126 
	C380.807892,354.298828 379.367798,354.399658 377.927734,354.500519 
	C375.393250,353.881226 372.858795,353.261932 370.534760,352.223145 
	C371.149231,350.289886 371.553253,348.776123 371.957275,347.262360 
	C374.032867,345.865356 377.987640,348.045441 378.481537,343.053223 
	C378.111145,342.033142 377.821991,341.752075 377.532837,341.470978 
	C377.246033,341.938293 376.959229,342.405609 376.672424,342.872925 
	C376.291809,342.862244 375.911163,342.851532 375.209106,342.517212 
	C373.935699,340.921082 372.983734,339.648560 372.031769,338.376068 
	C373.114624,337.583130 374.197449,336.790192 375.644104,336.006409 
M375.875183,351.927521 
	C376.011139,351.832306 376.165436,351.753113 376.271301,351.631653 
	C376.301239,351.597290 376.195679,351.354828 376.142151,351.351044 
	C375.239349,351.287170 374.334900,351.246063 373.430847,351.199860 
	C373.414673,351.397827 373.398529,351.595795 373.382355,351.793762 
	C373.996033,351.880188 374.609711,351.966583 375.875183,351.927521 
M376.996521,338.161835 
	C376.569122,338.263214 376.141754,338.364594 375.714355,338.465942 
	C375.808838,338.593658 375.920227,338.840149 375.995056,338.829468 
	C376.429962,338.767761 376.856323,338.645538 376.996521,338.161835 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M531.149780,447.976746 
	C531.132019,447.490723 531.114197,447.004700 531.661438,446.211975 
	C533.503479,445.956360 534.780518,446.007416 536.326172,446.332947 
	C538.819397,446.826874 541.044006,447.046295 542.068115,447.147308 
	C543.778931,449.873138 545.031433,451.868774 546.283936,453.864410 
	C546.624756,458.422974 546.965576,462.981537 547.311951,467.614563 
	C550.700684,467.454315 553.789917,467.308228 557.370361,467.439453 
	C557.893188,468.121307 557.924805,468.525879 557.956421,468.930450 
	C547.002014,468.903442 547.007324,468.903473 546.956055,479.828217 
	C546.941223,482.983398 546.886780,486.138367 546.366577,489.715515 
	C544.412292,490.136871 542.942017,490.136139 541.330811,489.675995 
	C541.107849,476.828979 541.025818,464.441376 541.229370,451.812622 
	C540.628174,450.716522 539.867920,449.372772 538.829468,449.104004 
	C536.339111,448.459503 533.718140,448.320038 531.149780,447.976746 
M545.008667,473.716278 
	C544.841919,472.315216 544.675171,470.914185 544.508484,469.513123 
	C544.109802,469.569061 543.711182,469.625000 543.312500,469.680969 
	C543.312500,474.094574 543.312500,478.508179 543.312500,482.921783 
	C543.688782,482.953735 544.065063,482.985718 544.441345,483.017700 
	C544.631165,480.214935 544.820984,477.412140 545.008667,473.716278 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M416.967255,442.580994 
	C418.170685,442.519592 419.374084,442.458191 420.638275,442.393738 
	C420.638275,435.034393 420.638275,428.266266 420.638275,420.140686 
	C426.859619,426.755890 422.268066,433.376160 424.086792,439.864868 
	C424.719360,441.372375 425.361572,442.106110 426.003784,442.839844 
	C426.003784,442.839844 426.419312,443.003693 426.692505,443.353210 
	C427.027740,444.135895 427.089813,444.569061 427.151886,445.002197 
	C427.872284,445.692200 428.592651,446.382172 429.764832,447.370117 
	C429.807861,449.091980 429.399109,450.515869 428.990356,451.939758 
	C428.705231,452.115936 428.420105,452.292145 427.753082,452.770813 
	C426.950562,453.075378 426.529938,453.077423 425.749634,453.038696 
	C424.228729,454.348419 423.067505,455.698883 421.834808,456.817871 
	C421.763306,456.586426 421.554199,456.149384 421.541443,455.819092 
	C420.701569,453.347412 419.916229,451.146088 416.510925,452.200104 
	C415.954346,452.198242 415.731964,452.217529 415.290710,452.066772 
	C414.606567,451.601837 414.141296,451.306885 413.698608,450.691528 
	C413.685364,449.947632 413.649567,449.524139 413.748535,448.812927 
	C414.257721,448.018738 414.632172,447.512268 415.003296,447.002899 
	C415.000000,447.000000 415.004639,447.006744 415.285248,446.973419 
	C415.661133,446.614502 415.756439,446.288879 415.838867,445.680939 
	C415.285370,444.663513 414.744751,443.928436 414.488312,443.177826 
	C415.504089,442.968506 416.235687,442.774750 416.967255,442.580994 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M532.096680,436.019897 
	C531.225037,434.103668 529.740906,432.220154 529.636414,430.262939 
	C529.505066,427.804535 530.503540,425.285767 531.410767,422.284851 
	C533.235962,418.091644 534.542419,414.415466 531.041504,411.083466 
	C531.031433,410.608948 531.021362,410.134460 531.429749,409.309540 
	C533.232117,408.972748 534.616028,408.986389 536.000000,409.000000 
	C540.474854,410.340942 544.949646,411.681885 549.648438,413.324860 
	C549.169922,414.679230 548.467407,415.731567 547.453003,416.982300 
	C541.404602,418.279083 537.674316,421.376282 536.549683,427.365143 
	C536.420349,428.053986 534.876099,428.477142 533.926331,428.918823 
	C533.864746,428.814209 533.608032,428.785767 533.608032,428.785767 
	C533.608032,428.785767 534.025146,428.984955 534.051392,429.380798 
	C533.417297,431.857727 532.757019,433.938812 532.096680,436.019897 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M536.057495,446.058472 
	C534.780518,446.007416 533.503479,445.956360 531.844910,445.893890 
	C531.448120,445.142181 531.432861,444.401855 531.632507,443.059875 
	C533.760742,438.252106 535.840454,434.111755 537.535034,429.819366 
	C538.899475,426.363159 540.848083,424.247253 544.823730,424.856049 
	C544.879089,430.967499 544.934509,437.078949 544.591309,443.687958 
	C541.480957,444.809814 538.769226,445.434143 536.057495,446.058472 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M582.534668,508.654053 
	C583.164062,508.559052 583.389282,508.642365 583.972534,509.189697 
	C584.006226,510.746124 583.681702,511.838593 583.259766,513.328491 
	C583.078979,515.081055 582.995728,516.436157 582.602112,517.831787 
	C581.893250,517.994995 581.494751,518.117615 580.753418,518.251343 
	C578.641846,519.222473 576.873230,520.182617 574.831970,520.998169 
	C574.319397,520.519043 574.079407,520.184570 573.710327,519.508057 
	C572.710632,518.867920 571.835571,518.304626 570.970154,518.318848 
	C567.719421,518.372314 566.787354,517.032837 568.391846,514.019043 
	C569.117126,513.345825 569.559204,512.736511 570.394287,512.061523 
	C577.486206,511.404755 571.348999,507.894745 572.158875,505.964539 
	C572.430054,500.872559 572.701294,495.780609 572.972473,490.688629 
	C573.490845,490.670502 574.009155,490.652344 574.527527,490.634186 
	C575.638123,493.827484 578.047119,496.281708 576.434143,500.691956 
	C575.390015,503.546906 576.033142,507.936646 577.630798,510.620300 
	C579.905579,514.441345 580.890808,510.300446 582.534668,508.654053 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M651.797241,517.096680 
	C652.166504,516.312195 652.535706,515.527771 653.375610,514.352905 
	C655.236023,512.239258 657.587646,510.635315 657.818970,508.768524 
	C658.332092,504.628204 657.781616,500.356079 657.664795,496.137695 
	C657.786194,495.663696 657.907654,495.189667 658.002075,494.349609 
	C658.528503,493.038269 659.082031,492.093018 659.912354,490.732788 
	C660.497803,490.153046 660.806458,489.988251 661.115112,489.823486 
	C661.266235,490.123047 661.417358,490.422607 661.774170,491.231934 
	C662.030273,492.478668 662.080627,493.215729 662.024719,494.275696 
	C661.822815,496.298523 661.727112,497.998444 661.547974,500.132263 
	C661.817749,503.914032 662.170959,507.261841 662.660339,511.899780 
	C664.614380,510.796844 666.289978,509.851074 668.337097,508.896759 
	C669.201233,508.909576 669.693848,508.930969 670.190857,509.301453 
	C670.189941,510.114838 670.184509,510.579132 669.819519,511.181763 
	C668.956177,513.532715 668.452454,515.745239 667.914185,517.914551 
	C667.879700,517.871277 667.770020,517.856750 667.373413,517.922974 
	C663.639587,518.343323 660.302368,518.697449 656.585327,518.919128 
	C655.463013,518.868958 654.720520,518.951355 653.768433,518.823730 
	C652.971680,518.108032 652.384460,517.602356 651.797241,517.096680 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M444.601685,414.207581 
	C446.896515,414.091583 449.193512,414.006927 451.485596,413.850494 
	C453.618500,413.704926 455.746216,413.482971 457.773987,413.827789 
	C456.454224,415.243927 455.236694,416.126923 452.951385,417.784332 
	C457.299713,418.007660 459.765350,417.831421 459.835266,413.994690 
	C462.351562,413.671661 464.864044,413.137573 467.385345,413.091858 
	C469.433624,413.054688 471.492493,413.603302 474.139832,413.975555 
	C474.139832,414.995209 474.139832,416.892975 474.139832,418.666138 
	C472.030853,418.666138 469.816589,418.666138 466.862640,418.666138 
	C466.030884,417.028198 465.090576,414.797852 461.598816,418.285583 
	C459.788422,420.093933 455.938873,422.412109 452.164581,419.006409 
	C451.507294,418.675446 451.178955,418.807892 450.850616,418.940338 
	C448.445801,420.120697 446.079498,422.168915 443.309784,418.696014 
	C443.442322,417.196594 443.758514,416.268158 444.214172,415.060303 
	C444.436310,414.589783 444.519012,414.398682 444.601685,414.207581 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M520.810913,457.371338 
	C521.477661,456.442780 521.993774,455.885742 522.777527,455.630920 
	C522.943848,457.744507 522.773621,459.555115 522.753357,461.367340 
	C522.672668,468.619995 522.638489,475.873169 522.232056,483.119751 
	C521.074524,483.802185 519.670166,484.416901 519.574524,485.192810 
	C519.177673,488.414001 519.175781,491.683838 518.602173,495.066345 
	C516.106018,495.104828 514.036621,495.012817 511.919525,494.622620 
	C511.763336,492.572479 511.538635,490.817993 511.576538,489.069122 
	C511.621216,487.004272 511.892883,484.944336 512.066162,482.882263 
	C512.380493,482.785797 512.688965,482.635132 513.009949,482.600739 
	C520.708252,481.775360 520.708496,481.777679 520.673706,473.076477 
	C520.597839,471.894348 520.550659,471.482788 520.503540,471.071259 
	C520.555725,466.628448 520.607971,462.185638 520.810913,457.371338 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M432.035217,383.014587 
	C438.491882,384.806000 444.950562,382.663452 451.392792,383.073547 
	C452.846344,383.166077 454.232330,384.320190 455.649414,384.985596 
	C454.783325,386.616882 453.917206,388.248199 452.598663,389.626434 
	C451.937103,389.140167 451.727997,388.906952 451.518890,388.673737 
	C451.390961,389.117554 451.263031,389.561371 451.135101,390.005188 
	C450.590576,390.055237 450.046021,390.105316 449.290161,389.665527 
	C449.009491,387.023010 449.650299,384.259827 445.190765,385.345673 
	C447.816895,386.276642 443.060242,390.551575 448.087006,389.978577 
	C444.972229,393.200500 444.068848,392.866608 442.246826,388.214172 
	C439.298889,389.474731 436.439209,391.039001 433.379944,391.786713 
	C432.521118,391.996613 431.075623,389.806091 429.887726,387.989929 
	C430.595856,385.847046 431.315552,384.430817 432.035217,383.014587 
M438.086487,386.764618 
	C437.803558,387.286530 437.520630,387.808472 437.237732,388.330414 
	C437.509155,388.469025 437.780548,388.607605 438.052002,388.746185 
	C438.209900,388.226532 438.367798,387.706879 438.086487,386.764618 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M454.831604,471.321808 
	C459.979980,470.584900 465.112305,469.689270 470.282227,469.164978 
	C473.939056,468.794128 477.655640,468.740692 481.328033,468.914856 
	C483.051361,468.996613 484.733429,469.949219 486.434204,470.507202 
	C485.178955,471.617920 483.923706,472.728668 482.081055,473.945618 
	C481.392548,474.357971 481.291473,474.664154 481.190430,474.970337 
	C480.587280,475.141571 479.984131,475.312836 479.249847,474.882874 
	C478.970398,473.356384 478.822052,472.431091 478.416351,469.900391 
	C477.057312,472.436829 476.379028,473.702667 475.700806,474.968567 
	C474.945984,474.853577 473.976776,474.991730 473.480469,474.571442 
	C472.408813,473.664001 471.579926,472.470001 470.220917,470.893494 
	C469.313690,473.173126 468.777405,474.520691 468.107361,476.204315 
	C467.116119,474.155670 466.343018,472.557831 465.569885,470.960022 
	C465.134369,471.080048 464.698822,471.200043 464.263275,471.320068 
	C464.263275,472.839508 464.335541,474.366058 464.186768,475.870758 
	C464.163971,476.101379 463.121490,476.231201 462.286865,475.845520 
	C460.586060,473.689362 459.378113,473.164886 458.880737,475.956665 
	C457.862793,475.692535 456.844849,475.428375 455.634583,474.727234 
	C455.280243,473.353973 455.118164,472.417786 454.924866,471.464294 
	C454.893616,471.447021 454.831604,471.321808 454.831604,471.321808 
M460.740173,470.387207 
	C460.740173,470.387207 460.417572,470.210144 460.417572,470.210144 
	C460.417572,470.210144 460.471466,470.582855 460.740173,470.387207 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M427.058777,364.020386 
	C423.156097,364.588440 419.266449,365.368500 415.343658,365.610199 
	C413.958710,365.695557 412.492706,364.465424 411.063690,363.835907 
	C411.575378,363.265564 412.087067,362.695251 412.598755,362.124908 
	C411.024231,362.285339 409.449707,362.445740 407.417755,362.337708 
	C406.424591,361.382874 405.888885,360.696472 405.353180,360.010040 
	C409.801880,356.125183 413.138397,356.600189 415.107574,361.302551 
	C415.415161,362.037079 416.310455,362.963257 416.967468,362.990417 
	C417.622345,363.017456 418.294281,361.990021 419.002747,361.486816 
	C420.748901,360.246460 422.520416,359.041718 424.582794,358.241089 
	C423.745911,359.933319 422.607971,361.207153 421.315735,362.653717 
	C425.428497,363.684204 426.150208,359.984894 428.012482,358.004639 
	C429.452179,357.970917 430.891846,357.937164 432.632507,358.419189 
	C433.619476,360.332611 434.305481,361.730286 435.116486,363.382568 
	C433.844086,364.239471 432.701569,365.008881 431.559082,365.778320 
	C430.445221,365.359924 429.331329,364.941528 427.994965,364.056152 
	C427.534546,363.732910 427.296661,363.876648 427.058777,364.020386 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M546.591675,491.760376 
	C546.729858,492.251740 546.868042,492.743103 547.014282,494.082764 
	C547.021851,500.947235 547.021423,506.963470 546.709534,513.055481 
	C546.297546,513.696960 546.197083,514.262695 546.096558,514.828369 
	C547.602844,514.205505 549.109192,513.582642 550.911865,513.133362 
	C549.761536,517.211182 548.314758,521.115479 546.630737,524.933838 
	C546.240540,524.782776 546.092834,524.690186 545.933228,524.659912 
	C545.774231,524.629822 545.458191,524.660339 545.458130,524.667358 
	C545.437012,526.321106 545.437683,527.975037 545.437683,529.629028 
	C545.021362,529.634277 544.605042,529.639465 544.188721,529.644653 
	C544.188721,516.914429 544.188721,504.184113 544.188721,491.373444 
	C544.684937,491.453339 545.638306,491.606842 546.591675,491.760376 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M451.037201,404.991058 
	C452.256042,405.883484 453.474915,406.775940 454.628998,407.620941 
	C449.871338,408.259796 445.263397,409.191742 440.630127,409.341919 
	C438.851013,409.399536 437.014160,407.674774 434.743713,406.530396 
	C434.205139,407.039825 433.203766,407.987030 431.528625,409.571533 
	C431.528625,406.790588 431.528625,404.836548 431.528625,403.077240 
	C435.880585,402.552551 440.067017,402.047821 444.510254,402.090210 
	C444.994934,402.943542 445.222870,403.249725 445.450806,403.555908 
	C445.661865,403.047791 445.872894,402.539642 446.083923,402.031494 
	C447.149719,402.686432 448.215546,403.341339 449.738739,404.232452 
	C450.476440,404.642792 450.756836,404.816925 451.037201,404.991058 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M429.290955,452.220276 
	C429.399109,450.515869 429.807861,449.091980 430.101318,447.378845 
	C433.350739,446.569244 436.715424,446.048828 440.632385,445.443024 
	C440.809753,441.989838 441.001923,438.248535 441.721802,434.113220 
	C442.844482,433.830719 443.439514,433.942230 444.034546,434.053741 
	C443.696198,439.117432 443.628540,444.223572 442.845184,449.217438 
	C442.626251,450.613190 440.417603,452.497040 438.908295,452.721344 
	C435.880554,453.171448 432.705719,452.631866 429.290955,452.220276 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M547.764893,416.783905 
	C548.467407,415.731567 549.169922,414.679230 550.033691,413.348755 
	C552.491821,410.986755 554.788635,408.902893 557.507935,406.435760 
	C561.630859,410.894806 565.505005,415.084869 569.211121,419.875641 
	C568.339539,421.308441 567.635925,422.140533 566.932373,422.972595 
	C564.891113,424.885498 562.642700,426.624481 560.856140,428.751312 
	C558.041565,432.102020 555.679871,431.538971 553.227478,428.482147 
	C551.782959,426.681641 550.285217,424.923889 548.867188,422.622284 
	C549.042664,421.952606 549.162415,421.807587 549.601624,421.810791 
	C551.006714,422.378632 552.092407,422.798248 553.180908,423.236633 
	C553.183716,423.255402 553.149963,423.267273 553.168274,423.643311 
	C554.336121,425.717224 555.485657,427.415100 556.158569,428.409058 
	C560.101013,425.090698 563.585693,422.157593 567.309387,419.023315 
	C563.560364,415.658966 560.297791,412.731110 557.013916,409.784119 
	C553.941162,412.617218 551.265320,415.084320 548.383423,417.359558 
	C548.039795,417.039734 547.902344,416.911804 547.764893,416.783905 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M444.303833,433.806915 
	C443.439514,433.942230 442.844482,433.830719 441.959351,433.680786 
	C441.669281,433.642365 441.333679,433.639557 441.385437,433.408691 
	C441.174072,431.855438 440.910919,430.532990 440.647797,429.210571 
	C440.238220,429.189575 439.828674,429.168579 439.419128,429.147583 
	C439.197723,430.069824 438.976318,430.992096 438.754913,431.914337 
	C438.573853,432.361938 438.392792,432.809570 438.218781,433.239807 
	C437.135742,431.871368 436.073334,430.528992 434.406281,429.054321 
	C434.200867,428.225159 434.600067,427.528290 434.999268,426.831421 
	C440.539459,426.835541 446.087372,426.681183 451.614594,426.950745 
	C453.069244,427.021637 454.459991,428.403656 455.546570,429.673828 
	C455.125519,430.646667 455.037964,431.129333 454.950378,431.611969 
	C454.221039,431.594849 453.491730,431.577759 452.061462,431.671082 
	C451.120972,432.152527 450.881378,432.523560 450.641846,432.894592 
	C450.641846,432.894623 450.331360,432.915070 449.960571,432.936279 
	C449.589783,432.957489 449.095367,432.920502 449.095367,432.920502 
	C448.703979,431.757477 448.312592,430.594421 447.503571,428.190247 
	C446.146912,430.676239 445.360046,432.118164 444.303833,433.806915 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M483.110596,446.160156 
	C488.257568,445.851959 493.379944,445.753326 498.483582,446.017120 
	C502.484985,446.223969 503.606445,444.068176 502.906342,440.831818 
	C502.334442,438.188141 503.611328,433.514435 497.961639,434.806061 
	C497.577667,434.893829 497.070557,434.443268 496.450378,433.851807 
	C496.882812,432.969635 497.485626,432.478729 498.235352,431.910583 
	C498.382263,431.833344 498.529572,431.421051 498.529572,431.421051 
	C498.529572,431.421051 498.167938,431.161896 498.088165,430.913391 
	C497.908539,430.334259 497.808655,430.003662 498.033295,429.397400 
	C498.563538,427.742950 498.769226,426.364136 498.974915,424.985321 
	C499.970215,425.746857 501.694061,426.388916 501.841766,427.289490 
	C502.755737,432.862152 503.315308,438.492981 504.093933,444.774109 
	C504.382538,445.713348 504.640320,445.847870 504.968597,445.847290 
	C507.226593,445.978088 509.484589,446.108887 512.191589,446.460327 
	C512.657166,447.014282 512.567383,447.315979 511.749634,447.783234 
	C501.812927,448.044067 492.497833,448.107697 483.134338,448.125916 
	C483.085907,448.080475 482.989166,447.989502 482.989166,447.989502 
	C483.018768,447.501648 483.048370,447.013794 483.110596,446.160156 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M576.527100,475.431641 
	C573.381897,474.387238 570.510620,473.098541 567.542297,471.766296 
	C568.464539,468.762268 569.510742,465.354126 570.574036,461.890442 
	C577.817871,462.109985 580.645020,467.955750 576.527100,475.431641 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M546.639648,491.506256 
	C545.638306,491.606842 544.684937,491.453339 544.188721,491.373444 
	C544.188721,504.184113 544.188721,516.914429 544.188721,529.644653 
	C544.605042,529.639465 545.021362,529.634277 545.437683,529.629028 
	C545.437683,527.975037 545.437012,526.321106 545.458130,524.667358 
	C545.458191,524.660339 545.774231,524.629822 545.933228,524.659912 
	C546.092834,524.690186 546.240540,524.782776 546.673584,525.348694 
	C547.030945,527.515564 547.108154,529.181702 547.102417,531.231506 
	C547.029602,532.395813 547.039673,533.176453 546.696838,533.962402 
	C544.986145,533.667786 543.628357,533.367981 542.172119,532.713623 
	C542.075562,531.868347 542.077454,531.377502 542.105225,530.489380 
	C542.054932,529.033447 541.978760,527.974792 541.884766,526.452942 
	C541.735229,514.038269 541.603455,502.086853 541.471741,490.135437 
	C542.942017,490.136139 544.412292,490.136871 546.236877,490.182190 
	C546.623352,490.568573 546.655518,490.910370 546.639648,491.506256 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M416.888855,442.175842 
	C416.235687,442.774750 415.504089,442.968506 414.509888,442.743286 
	C413.461334,438.805817 412.824310,435.245636 411.852325,431.779327 
	C410.594055,427.291840 407.373932,426.431274 403.820312,429.611816 
	C401.368225,431.806488 399.213196,434.333618 396.754883,436.520508 
	C395.635651,437.516174 393.309631,438.969849 392.905731,438.621582 
	C391.591553,437.488373 390.911865,435.619385 390.330872,434.115906 
	C392.309723,435.943756 394.383728,437.233002 395.824310,434.512634 
	C397.742188,430.890869 405.619965,430.184235 401.602905,423.316162 
	C401.982361,422.995148 402.133484,422.865997 402.284637,422.736847 
	C404.747314,423.807159 407.311005,424.699127 409.634216,426.015533 
	C410.998627,426.788666 412.514587,428.036407 413.033569,429.419281 
	C414.543365,433.442261 415.585144,437.640900 416.888855,442.175842 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M498.667633,424.824677 
	C498.769226,426.364136 498.563538,427.742950 497.792908,429.114868 
	C496.249878,426.560944 494.736755,425.274872 491.993866,426.887299 
	C491.984039,425.568268 492.438110,424.000854 491.886993,422.971680 
	C488.947083,417.481628 493.796722,415.961304 496.663666,413.650421 
	C497.280640,413.153107 500.294006,414.220490 500.305176,414.620239 
	C500.357635,416.494141 500.342529,418.943390 499.227386,420.136108 
	C497.723358,421.744690 495.981171,422.573578 498.667633,424.824677 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M544.846924,424.867798 
	C540.848083,424.247253 538.899475,426.363159 537.535034,429.819366 
	C535.840454,434.111755 533.760742,438.252106 531.615845,442.688599 
	C531.117004,442.867737 530.849792,442.816406 530.188904,442.657471 
	C530.519836,440.604095 531.244446,438.658356 532.032837,436.366272 
	C532.757019,433.938812 533.417297,431.857727 534.039917,429.388428 
	C534.002258,429.000275 533.987915,429.023438 533.987915,429.023438 
	C534.876099,428.477142 536.420349,428.053986 536.549683,427.365143 
	C537.674316,421.376282 541.404602,418.279083 547.453003,416.982300 
	C547.902344,416.911804 548.039795,417.039734 548.247559,417.639893 
	C548.639282,419.295563 548.960754,420.479034 549.282166,421.662537 
	C549.162415,421.807587 549.042664,421.952606 548.736450,422.314514 
	C548.210205,422.445618 547.870422,422.359863 547.006836,422.028442 
	C545.951050,422.831268 545.419128,423.879761 544.887207,424.928253 
	C544.887207,424.928253 544.870117,424.879517 544.846924,424.867798 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M550.615479,512.959778 
	C549.109192,513.582642 547.602844,514.205505 546.096558,514.828369 
	C546.197083,514.262695 546.297546,513.696960 547.088379,512.844360 
	C548.016357,506.431641 548.253906,500.305756 548.491455,494.179901 
	C553.154846,493.922211 557.517639,494.548920 560.890869,498.687073 
	C560.984497,499.011597 561.013306,498.981415 560.683594,498.820007 
	C559.151978,498.091431 557.979797,497.447235 556.743042,496.970520 
	C551.395630,494.909393 550.438721,495.845398 550.870605,501.666229 
	C551.147949,505.403870 550.724670,509.193481 550.615479,512.959778 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M432.913635,485.862366 
	C430.655151,486.563873 428.421295,487.735077 426.132294,487.855194 
	C422.827393,488.028656 419.113678,490.455780 416.583405,486.375183 
	C418.694885,485.209930 420.429535,484.421875 422.456879,483.888214 
	C422.979919,484.309021 423.194641,484.510071 423.448425,484.624146 
	C423.545990,484.667999 423.858887,484.516602 423.860352,484.449493 
	C423.922791,481.633331 423.961029,478.816620 424.002289,476.000000 
	C424.261230,476.024231 424.520142,476.048462 425.166718,476.131104 
	C427.872009,477.966156 430.189636,479.742767 432.796143,482.106750 
	C433.027863,483.750214 432.970734,484.806274 432.913635,485.862366 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M423.908020,475.567413 
	C423.961029,478.816620 423.922791,481.633331 423.860352,484.449493 
	C423.858887,484.516602 423.545990,484.667999 423.448425,484.624146 
	C423.194641,484.510071 422.979919,484.309021 422.332184,483.476074 
	C421.775818,479.879822 421.636871,476.950134 421.576080,473.624725 
	C421.608948,471.889465 421.563629,470.549927 421.600159,468.911499 
	C421.430542,468.171112 421.179138,467.729645 420.927734,467.288147 
	C420.619476,467.540039 420.311218,467.791901 419.771301,467.938904 
	C419.309631,467.587250 419.079590,467.340454 419.057434,466.855164 
	C419.946075,465.867340 420.626892,465.118042 421.386963,463.961365 
	C421.612915,461.385773 421.759583,459.217560 421.906311,457.049316 
	C423.067505,455.698883 424.228729,454.348419 425.571136,453.237915 
	C425.945251,453.738770 426.138153,453.999695 426.331085,454.260620 
	C421.774597,458.300781 422.608459,462.533264 425.012024,465.962036 
	C424.525604,469.685699 424.169678,472.410248 423.908020,475.567413 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M535.844055,408.700165 
	C534.616028,408.986389 533.232117,408.972748 531.433716,408.953552 
	C525.380127,405.341766 519.855652,401.535248 514.054443,398.212097 
	C511.066528,396.500580 507.585754,395.649475 504.629639,393.736359 
	C516.171692,396.068756 526.401367,401.229218 535.844055,408.700165 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M444.074707,415.339722 
	C443.758514,416.268158 443.442322,417.196594 443.110626,418.419189 
	C441.217590,419.272003 439.340088,419.830658 437.252136,419.876068 
	C437.820038,418.719177 438.598358,418.075470 439.456207,417.365997 
	C438.344757,417.051788 437.334351,416.766144 435.943237,416.372864 
	C435.943237,417.776917 435.943237,418.877899 435.943237,419.978851 
	C434.456696,417.954865 432.970154,415.930908 430.592926,412.694244 
	C434.747650,412.948029 438.143494,413.155457 441.801727,414.033386 
	C442.734344,414.915833 443.404541,415.127777 444.074707,415.339722 
M439.831879,414.410034 
	C439.831879,414.410034 439.509399,414.247772 439.509399,414.247772 
	C439.509399,414.247772 439.576355,414.618103 439.831879,414.410034 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M377.941284,354.908386 
	C379.367798,354.399658 380.807892,354.298828 382.625977,354.226990 
	C382.420990,355.331238 381.838013,356.406464 381.130035,357.712250 
	C387.992676,357.341125 385.427002,362.459351 386.746216,365.348297 
	C383.449097,367.054596 384.077057,359.403137 380.184601,363.799927 
	C379.571289,364.458679 379.143982,364.827423 378.439728,365.301025 
	C378.093475,362.042664 378.024170,358.679474 377.941284,354.908386 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M511.695526,482.826294 
	C511.892883,484.944336 511.621216,487.004272 511.576538,489.069122 
	C511.538635,490.817993 511.763336,492.572479 511.535858,494.720947 
	C510.180664,493.950714 509.161499,492.783997 508.067871,491.303772 
	C507.247437,490.722504 506.501495,490.454773 505.381958,490.261475 
	C503.339661,489.909576 501.670929,489.483276 500.002197,489.056946 
	C500.019836,488.878540 500.037476,488.700073 500.164490,488.061890 
	C500.597443,487.255524 500.921021,486.908875 501.244568,486.562225 
	C502.535217,486.582031 503.825867,486.601868 505.736938,486.751129 
	C506.955078,486.254730 507.552795,485.628845 508.150543,485.002991 
	C509.208679,484.258789 510.266785,483.514587 511.695526,482.826294 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M481.175079,448.337036 
	C481.615295,447.953186 482.006897,447.977753 482.693848,447.995911 
	C482.989166,447.989502 483.085907,448.080475 483.177124,448.511230 
	C484.771973,449.286316 486.271759,449.907898 487.779846,449.928589 
	C495.862549,450.039612 503.947266,450.000793 512.031311,450.013733 
	C512.840881,450.377228 513.650391,450.740723 514.792114,451.445801 
	C515.124207,451.787415 515.032654,452.219391 515.032654,452.219391 
	C511.329193,452.186981 507.625763,452.154572 503.094360,452.151917 
	C501.507233,452.177399 500.748077,452.173096 499.988922,452.168762 
	C495.170319,452.130920 490.326904,452.355927 485.547302,451.904877 
	C483.988190,451.757751 482.595367,449.848663 481.175079,448.337036 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M670.186401,508.952362 
	C669.693848,508.930969 669.201233,508.909576 668.327637,508.533508 
	C667.237122,506.169464 666.527588,504.160126 665.937988,502.043701 
	C666.057861,501.936676 666.295166,501.719879 666.295166,501.719849 
	C667.106201,502.299072 667.917175,502.878296 668.947754,503.919617 
	C669.449585,504.256378 669.731750,504.131012 670.013916,504.005615 
	C672.697205,498.788544 675.380432,493.571472 678.749756,488.180084 
	C680.011292,488.053619 680.586792,488.101471 680.944580,488.417847 
	C677.213440,495.441711 673.699890,502.197052 670.186401,508.952362 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M396.303040,361.244080 
	C397.066132,360.082703 397.622284,358.279999 398.652893,357.947418 
	C399.609070,357.638824 401.071869,358.900269 402.464539,360.173920 
	C402.419464,362.363373 402.221436,363.849274 402.023438,365.335175 
	C400.124817,364.997345 398.226166,364.659546 396.679688,363.996887 
	C398.240662,363.427124 399.449493,363.182220 400.658356,362.937317 
	C400.609924,362.649384 400.561493,362.361481 400.513062,362.073547 
	C399.109711,361.797058 397.706390,361.520569 396.303040,361.244080 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M434.272217,478.065979 
	C433.989258,478.444061 433.928833,478.398743 433.924774,478.430786 
	C431.798584,472.487976 432.560303,471.174530 438.414093,471.180389 
	C441.015930,471.182983 443.617401,471.549011 446.595337,472.124512 
	C446.971649,472.498901 447.006378,472.993958 447.006378,472.993958 
	C444.320465,473.336700 441.618134,474.044769 438.953064,473.924652 
	C435.983337,473.790863 434.880096,475.100189 434.272217,478.065979 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M491.872314,426.999146 
	C494.736755,425.274872 496.249878,426.560944 497.468384,429.390533 
	C497.808655,430.003662 497.908539,430.334259 498.075684,431.120361 
	C498.142975,431.575867 498.088409,431.987793 498.088440,431.987793 
	C497.485626,432.478729 496.882812,432.969635 496.131012,433.716064 
	C494.510101,433.971558 493.038147,433.971558 491.087616,433.971558 
	C491.087616,431.878448 491.087616,429.948547 491.263000,427.618683 
	C491.438416,427.218719 491.750732,427.111023 491.872314,426.999146 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M501.075653,486.170776 
	C500.921021,486.908875 500.597443,487.255524 500.133820,487.794678 
	C498.921600,487.956696 497.849396,487.926117 496.384033,487.809601 
	C496.641388,482.307495 497.291870,476.891357 497.942383,471.475220 
	C498.101166,471.548767 498.259918,471.622345 498.418701,471.695892 
	C499.248047,476.390381 500.077393,481.084869 501.075653,486.170776 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M383.780029,336.298645 
	C390.944031,332.950043 389.779694,338.732941 390.677094,343.182220 
	C389.041595,342.410828 387.821167,341.835236 386.106506,340.821625 
	C385.001495,339.021942 384.390747,337.660309 383.780029,336.298645 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M486.902008,474.766724 
	C488.246704,472.211212 489.342896,469.912506 491.002106,466.433136 
	C491.741516,468.654633 493.119415,470.653961 492.765106,472.274628 
	C492.327271,474.277283 490.450348,475.965271 489.195190,477.789185 
	C488.347961,476.867279 487.500732,475.945404 486.902008,474.766724 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M403.132996,352.876221 
	C403.040405,351.776978 403.319397,350.733521 403.727722,349.378387 
	C405.480103,348.060577 407.103088,347.054413 409.581055,345.518280 
	C409.744781,348.593964 409.873077,351.003998 410.007294,353.525208 
	C407.447388,353.291687 405.476013,353.111847 403.132996,352.876221 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M447.310364,473.156067 
	C447.006378,472.993958 446.971649,472.498901 446.987793,472.251282 
	C449.197815,470.292877 451.185699,467.553406 454.575806,470.930115 
	C454.831604,471.321808 454.893616,471.447021 454.594666,471.675110 
	C454.248138,472.936584 454.200592,473.970032 454.153015,475.003448 
	C451.973450,474.441711 449.793884,473.879974 447.310364,473.156067 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M431.906616,365.925903 
	C432.701569,365.008881 433.844086,364.239471 435.116486,363.382568 
	C434.305481,361.730286 433.619476,360.332611 432.907257,358.593018 
	C434.073639,357.852295 435.305206,357.084412 436.442902,357.204651 
	C437.045288,357.268250 437.838440,358.839722 437.935974,359.792816 
	C438.348480,363.824463 435.918335,366.401581 431.906616,365.925903 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M567.340576,422.983063 
	C567.635925,422.140533 568.339539,421.308441 569.282593,420.242065 
	C572.953857,421.325043 576.385681,422.642365 579.817505,423.959686 
	C579.644470,424.599396 579.471436,425.239136 579.298401,425.878845 
	C575.448547,424.917084 571.598633,423.955292 567.340576,422.983063 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M519.990051,471.046387 
	C520.550659,471.482788 520.597839,471.894348 520.587646,472.611694 
	C514.577148,472.917480 508.623962,472.917480 502.670776,472.917480 
	C502.670837,472.285492 502.670929,471.653534 502.670990,471.021545 
	C508.272858,471.021545 513.874695,471.021545 519.990051,471.046387 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M536.326172,446.332947 
	C538.769226,445.434143 541.480957,444.809814 544.558716,444.164368 
	C545.483704,447.145721 546.042664,450.148193 546.442749,453.507538 
	C545.031433,451.868774 543.778931,449.873138 542.068115,447.147308 
	C541.044006,447.046295 538.819397,446.826874 536.326172,446.332947 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M570.001343,512.127197 
	C569.559204,512.736511 569.117126,513.345825 568.424683,513.612305 
	C566.453918,509.175415 564.733582,505.081238 562.998779,501.002625 
	C562.984253,501.018219 562.958496,501.052063 562.958496,501.052063 
	C564.616516,502.621887 566.274475,504.191742 568.279907,506.349060 
	C569.085266,508.666748 569.543274,510.396973 570.001343,512.127197 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M434.942505,426.519043 
	C434.600067,427.528290 434.200867,428.225159 434.007690,429.044983 
	C433.766357,431.186127 433.318970,433.204315 432.797028,435.558990 
	C430.531616,434.843903 429.321655,433.075043 430.443207,430.331787 
	C431.231079,428.404724 430.823029,424.758698 434.942505,426.519043 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M428.249664,387.066681 
	C427.280334,388.311676 426.311005,389.556702 425.208649,390.972534 
	C423.053284,385.280151 423.607330,383.931396 428.555054,383.307007 
	C428.267609,384.344940 427.593140,385.068512 426.918182,385.792572 
	C427.336212,386.192596 427.792938,386.629639 428.249664,387.066681 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M390.723328,417.781189 
	C391.603119,414.100037 393.500000,414.107117 395.893768,416.399261 
	C397.553589,417.988586 399.178284,419.614594 400.845581,421.698364 
	C400.756622,422.312592 400.640717,422.452667 400.221802,422.451324 
	C398.397003,421.119507 396.995819,419.697632 395.311279,418.819244 
	C394.171722,418.225006 392.633392,418.395569 391.273407,418.224030 
	C391.273407,418.224030 390.907654,417.927582 390.723328,417.781189 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M402.469513,365.537170 
	C402.221436,363.849274 402.419464,362.363373 402.572754,360.480347 
	C403.154724,360.058105 403.781403,360.032959 404.880615,360.008911 
	C405.888885,360.696472 406.424591,361.382874 407.031128,362.325562 
	C408.668579,367.561737 405.078644,365.704498 402.469513,365.537170 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M570.394348,512.061523 
	C569.543274,510.396973 569.085266,508.666748 568.545166,506.560059 
	C569.451172,506.118317 570.439331,506.053101 571.793152,505.976196 
	C571.348999,507.894745 577.486206,511.404755 570.394348,512.061523 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M499.992188,452.599091 
	C500.748077,452.173096 501.507233,452.177399 502.642761,452.244812 
	C502.510345,455.265625 502.001556,458.223419 501.492767,461.181213 
	C500.993683,461.174255 500.494568,461.167297 499.995453,461.160370 
	C499.995453,458.450043 499.995453,455.739746 499.992188,452.599091 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M489.823944,385.572113 
	C492.598236,387.123871 495.084076,388.515747 497.569916,389.907654 
	C497.260712,390.453735 496.951508,390.999817 496.642303,391.545898 
	C493.912537,389.874451 491.182739,388.202972 488.452942,386.531494 
	C488.813782,386.158417 489.174622,385.785339 489.823944,385.572113 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M531.045410,411.462708 
	C534.542419,414.415466 533.235962,418.091644 531.423096,421.848511 
	C531.042542,418.560913 531.045959,415.201447 531.045410,411.462708 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M548.147278,494.124542 
	C548.253906,500.305756 548.016357,506.431641 547.399902,512.768555 
	C547.021423,506.963470 547.021851,500.947235 547.065918,494.464111 
	C547.340759,494.021210 547.571960,494.045197 548.147278,494.124542 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M681.162231,488.149323 
	C680.586792,488.101471 680.011292,488.053619 678.999939,487.965790 
	C679.485046,487.353271 680.406128,486.780701 681.629639,486.091309 
	C682.813110,485.623810 683.694031,485.273102 684.922241,484.821411 
	C685.664673,484.710022 686.059753,484.699677 686.655640,485.171997 
	C686.621155,486.726471 686.385864,487.798279 685.782959,488.898376 
	C684.231628,488.657043 683.048096,488.387451 681.688782,488.122467 
	C681.513062,488.127075 681.162231,488.149323 681.162231,488.149323 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M383.306763,336.341614 
	C384.390747,337.660309 385.001495,339.021942 385.809021,340.628174 
	C385.195892,341.515930 384.385956,342.159088 383.247406,342.806458 
	C382.581573,340.874542 382.244385,338.938507 381.895569,336.674194 
	C382.200470,336.358856 382.516998,336.371735 383.306763,336.341614 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M427.643372,358.001831 
	C426.150208,359.984894 425.428497,363.684204 421.315735,362.653717 
	C422.607971,361.207153 423.745911,359.933319 424.972046,358.320343 
	C425.798248,357.987122 426.536255,357.993073 427.643372,358.001831 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M448.322205,389.986328 
	C443.060242,390.551575 447.816895,386.276642 445.190765,385.345673 
	C449.650299,384.259827 449.009491,387.023010 449.053467,389.582581 
	C449.028137,389.989441 448.557434,389.994080 448.322205,389.986328 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M515.021851,452.419220 
	C515.032654,452.219391 515.124207,451.787415 515.114502,451.566864 
	C516.591980,452.332275 519.892273,448.750671 519.478882,453.585938 
	C517.980652,453.924927 516.976929,453.956848 515.973145,453.988770 
	C515.973145,453.988770 516.002808,453.996613 515.917969,453.775818 
	C515.557983,453.376434 515.282959,453.197876 515.007874,453.019287 
	C515.007874,453.019287 515.011108,452.619049 515.021851,452.419220 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M388.241333,356.700928 
	C388.627899,356.170685 388.916534,356.145813 389.419922,356.206665 
	C390.384674,357.765961 391.139221,359.292145 392.345642,361.732330 
	C388.216797,361.867706 387.793060,359.879486 388.241333,356.700928 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M558.171753,469.194244 
	C557.924805,468.525879 557.893188,468.121307 557.796753,467.419495 
	C559.609863,467.122284 561.487671,467.122284 563.365540,467.122284 
	C563.428772,467.610138 563.492004,468.097992 563.555237,468.585846 
	C561.832520,468.876556 560.109741,469.167297 558.171753,469.194244 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M466.033447,435.714294 
	C464.797180,436.986816 463.550720,438.118439 461.769257,439.735779 
	C461.527588,438.248901 461.323883,436.995636 461.120178,435.742371 
	C462.476654,435.524323 463.833160,435.306274 465.603546,435.189606 
	C466.017456,435.290985 466.023285,435.573395 466.033447,435.714294 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M459.576202,413.993866 
	C459.765350,417.831421 457.299713,418.007660 452.951385,417.784332 
	C455.236694,416.126923 456.454224,415.243927 457.976685,414.183105 
	C458.626801,414.001160 458.971954,413.997101 459.576202,413.993866 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M428.442810,387.166107 
	C427.792938,386.629639 427.336212,386.192596 426.918182,385.792572 
	C427.593140,385.068512 428.267609,384.344940 428.961670,383.314178 
	C429.744507,383.005646 430.507812,383.004333 431.653137,383.008789 
	C431.315552,384.430817 430.595856,385.847046 429.811829,387.606598 
	C429.376953,387.721771 429.006439,387.493652 428.442810,387.166107 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M393.847107,347.108826 
	C394.346375,350.591461 392.569244,351.999420 389.418640,351.837524 
	C388.993042,351.401886 388.567444,350.966217 388.141846,350.530548 
	C389.575684,349.622253 391.009491,348.713959 392.447693,347.494049 
	C392.761719,347.156067 393.071350,347.129700 393.847107,347.108826 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M476.118103,474.979675 
	C476.379028,473.702667 477.057312,472.436829 478.416351,469.900391 
	C478.822052,472.431091 478.970398,473.356384 479.069885,474.640442 
	C478.192505,474.996429 477.363953,474.993622 476.118103,474.979675 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M661.631348,499.698334 
	C661.727112,497.998444 661.822815,496.298523 662.197205,494.323425 
	C662.475891,494.048187 662.828491,494.109283 662.828491,494.109283 
	C663.234924,495.752991 663.641418,497.396729 664.055786,499.490692 
	C664.063721,499.940948 663.884827,500.139130 663.508362,500.077332 
	C662.631714,499.909790 662.131531,499.804047 661.631348,499.698334 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M451.339172,404.994446 
	C450.756836,404.816925 450.476440,404.642792 450.079224,404.245453 
	C450.775818,403.310333 451.589264,402.598450 452.402679,401.886536 
	C452.831238,402.116211 453.259766,402.345856 453.688293,402.575531 
	C453.005890,403.382965 452.323517,404.190399 451.339172,404.994446 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M539.926941,399.019409 
	C539.417053,399.613251 538.905212,399.881805 538.393433,400.150391 
	C538.389709,399.401550 538.385986,398.652740 538.382202,397.903900 
	C538.896484,398.167328 539.410706,398.430756 539.926941,399.019409 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M500.300293,392.855774 
	C500.266266,392.220184 500.442200,391.474579 500.903015,391.189301 
	C501.156311,391.032501 501.889435,391.650726 502.406189,391.919556 
	C501.813843,392.258972 501.221497,392.598389 500.300293,392.855774 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M436.181091,419.987671 
	C435.943237,418.877899 435.943237,417.776917 435.943237,416.372864 
	C437.334351,416.766144 438.344757,417.051788 439.456207,417.365997 
	C438.598358,418.075470 437.820038,418.719177 436.968292,419.683167 
	C436.894867,420.003510 436.418915,419.996521 436.181091,419.987671 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M545.229858,424.911255 
	C545.419128,423.879761 545.951050,422.831268 546.742676,422.007996 
	C546.525757,423.120239 546.049133,424.007233 545.229858,424.911255 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M439.042236,432.068756 
	C438.976318,430.992096 439.197723,430.069824 439.419128,429.147583 
	C439.828674,429.168579 440.238220,429.189575 440.647797,429.210571 
	C440.910919,430.532990 441.174072,431.855438 441.315796,433.561401 
	C440.572723,433.371033 439.951141,432.797119 439.042236,432.068756 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M459.284210,475.974335 
	C459.378113,473.164886 460.586060,473.689362 462.065552,475.638733 
	C461.301178,475.993408 460.494446,475.992706 459.284210,475.974335 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M663.109253,493.911438 
	C662.828491,494.109283 662.475891,494.048187 662.303406,494.000488 
	C662.080627,493.215729 662.030273,492.478668 661.989990,491.370819 
	C662.463318,491.904510 662.926636,492.809052 663.109253,493.911438 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M454.423523,474.987793 
	C454.200592,473.970032 454.248138,472.936584 454.625916,471.692383 
	C455.118164,472.417786 455.280243,473.353973 455.337708,474.603699 
	C455.053406,474.935516 454.873718,474.953827 454.423523,474.987793 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M425.998718,442.515381 
	C425.361572,442.106110 424.719360,441.372375 424.161102,440.317078 
	C424.827942,440.727325 425.410828,441.459106 425.998718,442.515381 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M396.238892,361.857758 
	C397.706390,361.520569 399.109711,361.797058 400.513062,362.073547 
	C400.561493,362.361481 400.609924,362.649384 400.658356,362.937317 
	C399.449493,363.182220 398.240662,363.427124 396.630005,363.688171 
	C396.210419,363.293335 396.192566,362.882355 396.238892,361.857758 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M482.988220,487.454346 
	C482.155273,487.690399 481.307770,487.507385 480.460266,487.324341 
	C481.018250,486.749481 481.576202,486.174622 482.456299,485.480804 
	C482.843475,485.919678 482.908569,486.477478 482.988220,487.454346 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M461.036133,431.151306 
	C462.347504,430.938782 463.639923,431.014252 464.932343,431.089752 
	C464.945312,431.320496 464.958282,431.551270 464.971252,431.782043 
	C463.900177,431.877747 462.829132,431.973480 461.379395,432.042847 
	C461.006256,431.824097 461.011719,431.631714 461.036133,431.151306 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M507.792297,485.036804 
	C507.552795,485.628845 506.955078,486.254730 506.109100,486.628357 
	C506.385254,485.940948 506.909668,485.505798 507.792297,485.036804 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M402.917786,347.748230 
	C402.194885,347.702759 401.432678,347.282074 400.557617,346.520569 
	C401.255981,346.577484 402.067200,346.975220 402.917786,347.748230 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M661.114990,489.497101 
	C660.806458,489.988251 660.497803,490.153046 659.941284,490.401428 
	C659.637207,489.893982 659.581116,489.302948 659.525024,488.711914 
	C660.054993,488.864838 660.584900,489.017792 661.114990,489.497101 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M401.141907,352.830688 
	C401.536469,352.548035 401.904388,352.615936 402.547821,352.721191 
	C402.548187,353.336243 402.273071,353.913910 401.997925,354.491577 
	C401.703705,354.054810 401.409485,353.618011 401.141907,352.830688 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M516.161682,454.304047 
	C516.976929,453.956848 517.980652,453.924927 519.360901,453.900879 
	C519.778320,454.093475 519.819336,454.278107 519.914246,454.741241 
	C518.762207,454.886261 517.556213,454.752777 516.161682,454.304047 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M471.865723,426.077698 
	C471.575836,426.592590 470.940063,427.099213 470.110535,427.395142 
	C470.451172,426.812775 470.985474,426.441132 471.865723,426.077698 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M593.954468,430.606689 
	C593.712097,430.209503 593.713745,429.814362 593.744019,429.421417 
	C593.744873,429.410339 594.051208,429.422821 594.215088,429.424316 
	C594.210938,429.819244 594.206726,430.214111 593.954468,430.606689 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M455.013336,431.925598 
	C455.037964,431.129333 455.125519,430.646667 455.418457,430.009949 
	C455.820740,430.775299 456.017639,431.694702 456.214539,432.614075 
	C455.835144,432.489105 455.455719,432.364166 455.013336,431.925598 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M475.033478,431.906372 
	C475.419189,431.705078 476.165710,431.547882 476.874176,431.699280 
	C476.355499,431.988770 475.874878,431.969604 475.033478,431.906372 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M415.954437,397.606445 
	C415.712341,397.209717 415.714050,396.815033 415.744293,396.422577 
	C415.745148,396.411560 416.051147,396.424072 416.214813,396.425598 
	C416.210632,396.819977 416.206421,397.214417 415.954437,397.606445 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M427.445160,444.950684 
	C427.089813,444.569061 427.027740,444.135895 426.914551,443.377075 
	C427.155090,443.667328 427.446747,444.283234 427.445160,444.950684 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M516.223083,444.691895 
	C516.996399,444.416931 517.666992,444.612091 518.700073,445.201050 
	C519.091919,445.810303 519.121094,446.025757 519.150330,446.241211 
	C518.354614,446.280792 517.558899,446.320374 516.381226,446.238342 
	C516.039673,445.798492 516.079956,445.480255 516.223083,444.691895 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M560.967590,499.023804 
	C561.574524,499.504120 562.198303,499.972290 562.890320,500.746277 
	C562.958496,501.052063 562.984253,501.018219 562.783447,500.818909 
	C562.059509,500.073547 561.536377,499.527496 561.013306,498.981415 
	C561.013306,498.981415 560.984497,499.011597 560.967590,499.023804 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M451.433807,389.975800 
	C451.263031,389.561371 451.390961,389.117554 451.518890,388.673737 
	C451.727997,388.906952 451.937103,389.140167 452.234802,389.606873 
	C452.126434,389.875732 451.929474,389.911072 451.433807,389.975800 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M426.670197,454.131653 
	C426.138153,453.999695 425.945251,453.738770 425.930817,453.278656 
	C426.529938,453.077423 426.950562,453.075378 427.683746,453.030304 
	C427.667328,453.325775 427.338318,453.664246 426.670197,454.131653 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M445.758820,402.022339 
	C445.872894,402.539642 445.661865,403.047791 445.450806,403.555908 
	C445.222870,403.249725 444.994934,402.943542 444.775146,402.326416 
	C445.000092,402.014679 445.216888,402.013947 445.758820,402.022339 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M383.136719,423.139160 
	C383.118561,423.582245 382.759338,423.962921 382.400116,424.343628 
	C382.283081,424.076630 382.166046,423.809631 382.077820,423.270508 
	C382.336304,423.024506 382.565979,423.050629 383.136719,423.139160 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M444.214172,415.060303 
	C443.404541,415.127777 442.734344,414.915833 441.939209,414.355957 
	C442.434448,414.034332 443.054565,414.060669 444.138184,414.147278 
	C444.519012,414.398682 444.436310,414.589783 444.214172,415.060303 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M669.767334,504.003357 
	C669.731750,504.131012 669.449585,504.256378 669.097412,504.189972 
	C669.027466,503.998199 669.520691,504.001068 669.767334,504.003357 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M665.271912,500.722778 
	C665.576965,500.853699 665.825317,501.054688 666.156006,501.522827 
	C666.295166,501.719879 666.057861,501.936676 665.742432,501.912109 
	C665.178284,501.683197 664.988892,501.433746 664.962036,501.035156 
	C665.065308,500.931030 665.271912,500.722778 665.271912,500.722778 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M664.154785,499.843262 
	C664.531799,499.895538 664.779968,500.092926 665.131104,500.530273 
	C665.271912,500.722778 665.065308,500.931030 664.758301,500.912354 
	C664.193420,500.693970 664.004639,500.442474 663.884827,500.139130 
	C663.884827,500.139130 664.063721,499.940948 664.154785,499.843262 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M459.906433,434.046082 
	C459.914032,434.242889 459.719177,434.502472 459.275085,434.677917 
	C459.221588,434.389801 459.447601,434.228149 459.906433,434.046082 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M449.088684,433.177826 
	C449.095367,432.920502 449.589783,432.957489 449.829926,433.020721 
	C449.740692,433.201019 449.411316,433.318085 449.088684,433.177826 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M472.209320,432.893829 
	C472.153351,432.706543 472.320526,432.410187 472.745178,432.051056 
	C472.900940,432.317017 472.710876,432.582520 472.209320,432.893829 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M450.954346,432.813782 
	C450.881378,432.523560 451.120972,432.152527 451.706238,431.760376 
	C451.790253,432.070496 451.528564,432.401733 450.954346,432.813782 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M401.048157,421.963837 
	C401.533508,421.881714 401.794586,422.074219 402.145844,422.534760 
	C402.133484,422.865997 401.982361,422.995148 401.477600,423.148254 
	C400.946930,422.955597 400.747192,422.762451 400.524811,422.592712 
	C400.640717,422.452667 400.756622,422.312592 401.048157,421.963837 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M451.126099,418.961182 
	C451.178955,418.807892 451.507294,418.675446 451.894714,418.771973 
	C451.769745,418.994629 451.585663,418.988342 451.126099,418.961182 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M427.253296,364.016174 
	C427.296661,363.876648 427.534546,363.732910 427.804749,363.798126 
	C427.837006,364.007080 427.447845,364.011993 427.253296,364.016174 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M400.083893,351.065857 
	C400.335022,350.980743 400.719177,351.180176 401.169861,351.716614 
	C400.896545,351.819214 400.556732,351.584808 400.083893,351.065857 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M380.139648,343.931335 
	C380.103027,343.703644 380.287689,343.393585 380.753998,343.143463 
	C380.867737,343.478119 380.642822,343.693298 380.139648,343.931335 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M479.124664,435.246185 
	C479.317078,435.179901 479.629181,435.337341 480.013458,435.756805 
	C479.805206,435.835876 479.524780,435.652863 479.124664,435.246185 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M609.533020,464.550507 
	C609.477173,464.583954 609.588867,464.517059 609.533020,464.550507 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M481.373352,474.855316 
	C481.291473,474.664154 481.392548,474.357971 481.746216,474.030304 
	C481.925598,474.297577 481.778107,474.541412 481.373352,474.855316 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M504.863647,445.663422 
	C504.640320,445.847870 504.382538,445.713348 504.129059,445.228088 
	C504.342072,445.097809 504.574036,445.253510 504.863647,445.663422 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M524.906128,441.258972 
	C524.555664,441.335876 524.125793,441.063385 523.743774,440.381470 
	C524.136658,440.284576 524.481628,440.597076 524.906128,441.258972 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M515.098511,453.256042 
	C515.282959,453.197876 515.557983,453.376434 515.926270,453.788635 
	C515.742676,453.845795 515.465881,453.669312 515.098511,453.256042 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M311.261902,342.758606 
	C313.506561,344.439728 315.751190,346.120850 318.157288,347.922913 
	C312.876709,354.257568 305.230774,357.744354 308.915588,367.012634 
	C316.190826,367.012634 323.775757,366.872528 331.352448,367.061920 
	C336.384949,367.187744 341.405701,367.784607 346.218323,368.778870 
	C335.235687,369.587891 324.463593,369.706696 313.700317,370.080292 
	C312.135651,370.134613 310.613342,371.407898 309.071411,372.117126 
	C310.536774,372.719971 312.008545,373.857758 313.466553,373.840332 
	C324.309967,373.710846 335.150665,373.352112 346.157440,373.299744 
	C346.890472,374.033264 347.458282,374.532379 347.588135,375.054321 
	C335.383484,375.160522 323.616821,375.285431 311.850128,375.286011 
	C309.915619,375.286102 307.645325,376.064087 306.999176,372.617401 
	C306.074554,367.685181 304.364990,362.900116 303.374573,358.007355 
	C306.069824,356.233337 305.952759,352.252686 303.601746,349.439758 
	C302.558533,348.191559 301.691650,346.795929 300.330872,344.886444 
	C310.965759,342.524963 306.575439,351.046478 308.422302,354.157745 
	C310.496857,352.492310 312.394318,350.968994 314.285522,349.450745 
	C312.709412,347.112213 311.531189,345.364044 310.579834,343.401123 
	C310.958405,343.043793 311.110138,342.901215 311.261902,342.758606 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M325.967499,321.987549 
	C324.489899,322.387421 323.012299,322.787323 321.250946,323.264008 
	C319.739197,321.770203 318.293854,318.280426 315.549744,323.129120 
	C314.804749,324.445465 311.735199,324.907593 309.716919,324.946259 
	C305.478394,325.027374 301.231689,324.479095 296.983795,324.375122 
	C295.382172,324.335876 293.767975,324.810913 291.683350,324.623779 
	C291.306152,323.232605 291.405151,322.269196 291.504150,321.305756 
	C291.167419,321.271942 290.830719,321.238129 290.493988,321.204315 
	C290.154907,322.409790 289.815826,323.615295 289.476715,324.820770 
	C289.070496,323.773346 288.664276,322.725952 288.170837,321.039764 
	C287.711334,320.218018 287.339111,320.035034 286.966858,319.852051 
	C286.516541,319.081512 286.066193,318.311005 286.251160,317.358765 
	C289.882904,317.226868 292.879333,317.276642 295.875793,317.326416 
	C296.496490,317.943939 297.171967,319.140564 297.729309,319.087860 
	C302.439636,318.642334 307.128387,317.968689 311.882690,317.920837 
	C310.697296,319.424683 309.452850,320.371185 307.525909,321.836792 
	C312.344330,322.543427 314.210724,320.967316 314.172791,317.050720 
	C317.199249,317.500153 320.213928,318.135010 323.256653,318.328064 
	C325.021851,318.440002 326.832123,317.841827 329.161926,317.479218 
	C328.869202,319.361389 328.558838,321.363525 328.228302,323.362366 
	C328.225769,323.377625 327.894897,323.405548 327.809143,323.327148 
	C327.578522,323.116333 327.397675,322.851044 327.051941,322.083008 
	C326.593689,321.701630 326.280609,321.844574 325.967499,321.987549 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M271.424469,316.265045 
	C269.045685,314.857208 266.666901,313.449402 264.057556,311.905151 
	C265.021851,309.752502 266.194977,307.902527 266.685242,305.886475 
	C267.868988,301.018524 270.440521,300.635498 274.628479,302.474457 
	C281.842468,305.642151 281.915131,305.476654 276.366821,314.762329 
	C274.375610,315.217163 272.900024,315.741119 271.424469,316.265045 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M364.861145,366.151245 
	C365.740082,367.038879 366.619019,367.926514 367.794128,369.238647 
	C367.994446,370.101654 367.898590,370.540222 367.519348,371.132568 
	C366.836212,372.211365 366.436432,373.136322 365.685608,374.020599 
	C364.867004,374.024078 364.399414,374.068268 363.572937,374.086853 
	C362.610870,374.708191 362.007660,375.355103 360.958405,376.094208 
	C360.209198,376.204163 359.906036,376.221985 359.458435,375.903900 
	C356.865051,375.101013 354.416107,374.633972 351.889221,373.900513 
	C351.515900,373.425171 351.220520,373.216278 350.903717,372.606812 
	C350.875641,371.136810 350.869019,370.067474 351.197601,368.906189 
	C352.782440,367.733856 354.032074,366.653473 355.427429,365.535339 
	C355.791840,365.464630 355.946960,365.347534 356.038513,365.146271 
	C356.617950,363.971191 357.589386,362.824615 357.677429,361.613770 
	C357.787170,360.104919 357.168365,358.543060 357.430176,356.555054 
	C358.984833,356.076233 359.973480,356.046417 360.962097,356.016632 
	C360.976410,358.430817 360.990692,360.845032 361.127563,363.962769 
	C362.453796,365.161285 363.657471,365.656281 364.861145,366.151245 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M350.862366,368.998108 
	C350.869019,370.067474 350.875641,371.136810 350.492706,372.596802 
	C348.732819,373.013367 347.362518,373.039337 345.992218,373.065308 
	C335.150665,373.352112 324.309967,373.710846 313.466553,373.840332 
	C312.008545,373.857758 310.536774,372.719971 309.071411,372.117126 
	C310.613342,371.407898 312.135651,370.134613 313.700317,370.080292 
	C324.463593,369.706696 335.235687,369.587891 346.464600,369.154846 
	C346.924255,368.922546 347.278259,368.953766 347.796509,369.032288 
	C349.163940,369.073273 350.013153,369.035706 350.862366,368.998108 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M323.556122,355.066010 
	C321.623383,353.435211 319.690613,351.804382 318.010712,350.386902 
	C321.801453,346.354706 324.908203,342.695007 328.453766,339.526398 
	C329.349182,338.726166 332.180756,338.835876 333.201416,339.663452 
	C336.519257,342.353821 339.410126,345.570740 342.758026,349.083649 
	C343.029510,350.044006 343.008392,350.505676 342.987274,350.967316 
	C339.904388,353.983704 336.878723,357.062408 333.703308,359.978027 
	C332.762482,360.841888 331.399872,361.246307 329.681458,360.900726 
	C331.179443,359.151001 332.677429,357.401306 334.089172,355.302551 
	C334.002960,354.953522 333.954803,354.970581 334.308258,355.064148 
	C336.584991,353.531952 338.508270,351.906250 341.601959,349.291168 
	C338.670319,346.974365 336.334442,345.376221 334.294678,343.462494 
	C330.551910,339.951019 329.952606,340.028381 327.364136,345.166870 
	C326.820312,345.355774 326.434906,345.425812 325.730011,345.678101 
	C320.890472,348.530731 322.103455,351.470947 324.783386,354.631714 
	C324.227051,354.870544 323.891571,354.968262 323.556122,355.066010 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M274.361877,352.174805 
	C274.310669,351.638123 274.259460,351.101471 274.326019,350.172821 
	C276.143494,350.317932 277.825378,351.196777 279.548431,351.286591 
	C280.989716,351.361694 282.479126,350.514099 284.455566,350.104828 
	C285.075836,350.275177 285.187714,350.415802 285.237976,350.902802 
	C285.176361,354.296387 285.176361,357.343567 285.176361,360.463837 
	C288.601837,357.588806 291.325745,355.302612 294.020752,353.014099 
	C293.991852,353.011810 294.000946,352.954529 293.874390,353.278015 
	C291.257690,358.356476 288.767517,363.111450 286.038391,368.322754 
	C285.066101,365.178314 284.185883,362.331635 283.012665,358.537323 
	C281.872559,361.039215 281.201263,362.512390 280.119751,363.920990 
	C279.444702,363.776611 279.179871,363.696808 278.916748,363.252228 
	C281.070496,354.707214 280.835876,354.155609 274.361877,352.174805 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M286.119537,313.587799 
	C284.157959,308.501678 285.470123,306.479553 290.798767,306.918396 
	C289.512482,308.563354 288.222900,309.671783 286.933289,310.780182 
	C290.979889,311.250092 293.572296,310.172485 293.493866,306.141113 
	C296.289795,306.572723 300.380341,303.739502 301.113770,309.818481 
	C300.997955,310.614990 300.954071,310.784180 300.910187,310.953369 
	C299.462830,311.803314 298.015503,312.653259 295.889221,313.570618 
	C292.180023,313.621307 289.149780,313.604553 286.119537,313.587799 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M323.709656,355.297546 
	C323.891571,354.968262 324.227051,354.870544 325.124084,354.476318 
	C327.587341,352.422363 329.488983,350.664917 330.626648,349.613525 
	C328.840515,348.006683 327.445038,346.751251 326.049530,345.495819 
	C326.434906,345.425812 326.820312,345.355774 327.779785,345.303650 
	C331.055634,345.164520 333.932037,344.684601 333.838074,348.890778 
	C333.792877,350.915497 333.910767,352.943878 333.954803,354.970581 
	C333.954803,354.970581 334.002960,354.953522 333.991486,354.930542 
	C331.744354,355.015289 329.508728,355.123016 326.677368,355.570984 
	C325.621246,356.021057 325.160858,356.130859 324.700439,356.240631 
	C324.421356,356.003448 324.142273,355.766266 323.709656,355.297546 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M284.765015,349.952698 
	C287.635315,348.767700 290.704498,347.764587 294.324951,346.829895 
	C294.876160,346.898376 295.250824,347.205933 295.170288,347.547485 
	C297.374420,350.929352 299.659088,353.969696 301.593262,357.123230 
	C298.828827,355.809113 296.414886,354.381805 294.000946,352.954529 
	C294.000946,352.954529 293.991852,353.011810 293.938416,352.693115 
	C291.778595,348.200928 288.420410,349.939117 285.299591,350.556396 
	C285.187714,350.415802 285.075836,350.275177 284.765015,349.952698 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M271.522095,316.684692 
	C272.900024,315.741119 274.375610,315.217163 276.086060,314.958496 
	C276.129944,318.322174 275.938934,321.420593 275.463287,324.821594 
	C274.406555,324.803772 273.634430,324.483398 272.557373,324.055847 
	C272.041534,321.667206 271.830627,319.385803 271.522095,316.684692 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M371.733154,338.141235 
	C372.983734,339.648560 373.935699,340.921082 374.924011,342.498810 
	C372.473541,342.398468 369.986694,341.992920 366.778564,341.469757 
	C366.778564,340.763397 366.778564,338.792877 366.778564,336.650482 
	C368.436981,337.097839 369.935760,337.502136 371.733154,338.141235 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M301.265137,311.109863 
	C300.954071,310.784180 300.997955,310.614990 301.174194,310.219360 
	C303.814240,308.571320 306.321899,307.149750 309.178833,305.530151 
	C309.178833,307.923737 309.178833,310.705872 309.178833,313.721710 
	C306.676758,312.908936 304.148407,312.087646 301.265137,311.109863 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M343.371399,350.978821 
	C343.008392,350.505676 343.029510,350.044006 343.053833,349.234436 
	C348.317261,349.053497 351.459198,354.068756 350.367493,360.705292 
	C346.644958,358.446320 349.656006,351.639252 343.371399,350.978821 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M286.152649,313.896606 
	C289.149780,313.604553 292.180023,313.621307 295.595886,313.690002 
	C295.972137,314.638275 295.962769,315.534607 295.914612,316.878662 
	C292.879333,317.276642 289.882904,317.226868 286.431091,317.157013 
	C286.045715,316.159760 286.115753,315.182556 286.152649,313.896606 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M371.595581,347.123108 
	C371.553253,348.776123 371.149231,350.289886 370.398834,351.887878 
	C368.806152,351.346283 367.559814,350.720428 366.313507,350.094574 
	C367.953613,349.057678 369.593750,348.020782 371.595581,347.123108 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M357.744354,353.984131 
	C357.847565,351.570923 357.950775,349.157745 358.650940,346.410431 
	C359.926239,345.980652 360.604553,345.884949 361.282837,345.789246 
	C361.176971,348.202728 361.071136,350.616180 360.487488,353.543518 
	C359.254578,354.032928 358.499451,354.008514 357.744354,353.984131 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M351.197601,368.906189 
	C350.013153,369.035706 349.163940,369.073273 347.892090,369.057648 
	C347.556763,368.520355 347.644104,368.036285 348.199615,367.265991 
	C349.504852,366.638458 350.341919,366.297150 351.397705,365.765503 
	C351.326294,364.652100 351.036194,363.729034 350.746094,362.805969 
	C351.486877,362.837646 352.227692,362.869324 353.450378,363.365967 
	C354.382080,364.411652 354.831879,364.992371 355.281677,365.573090 
	C354.032074,366.653473 352.782440,367.733856 351.197601,368.906189 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M293.194031,306.157349 
	C293.572296,310.172485 290.979889,311.250092 286.933289,310.780182 
	C288.222900,309.671783 289.512482,308.563354 291.046631,307.196991 
	C291.825531,306.683868 292.359863,306.428711 293.194031,306.157349 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M313.927979,317.102966 
	C314.210724,320.967316 312.344330,322.543427 307.525909,321.836792 
	C309.452850,320.371185 310.697296,319.424683 312.170227,318.189087 
	C312.826874,317.651733 313.255035,317.403473 313.927979,317.102966 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M365.132446,365.970825 
	C363.657471,365.656281 362.453796,365.161285 361.204498,364.331238 
	C362.457184,363.996033 363.755554,363.995850 365.438934,363.993317 
	C365.683868,364.590790 365.543793,365.190613 365.132446,365.970825 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M351.178986,365.955872 
	C350.341919,366.297150 349.504852,366.638458 348.310913,366.913910 
	C348.391174,365.605865 348.828308,364.363678 349.641663,363.400757 
	C350.404938,364.438629 350.791962,365.197235 351.178986,365.955872 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M324.841553,356.559937 
	C325.160858,356.130859 325.621246,356.021057 326.277710,355.854675 
	C327.270721,356.699951 328.067719,357.601746 328.864716,358.503571 
	C328.568695,358.813293 328.272705,359.123016 327.976685,359.432739 
	C326.978668,358.581573 325.980652,357.730408 324.841553,356.559937 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M361.220398,345.439514 
	C360.604553,345.884949 359.926239,345.980652 358.909546,346.134521 
	C358.732910,345.140045 358.894623,344.087372 359.190765,342.159882 
	C360.178894,343.631622 360.668427,344.360718 361.220398,345.439514 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M357.685669,354.249695 
	C358.499451,354.008514 359.254578,354.032928 360.383362,353.984802 
	C360.858643,354.377228 360.960236,354.842194 361.011963,355.661865 
	C359.973480,356.046417 358.984833,356.076233 357.620453,356.103516 
	C357.372131,355.572388 357.499573,355.043854 357.685669,354.249695 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M302.663971,338.632233 
	C304.066528,338.482300 305.469055,338.332397 306.879639,338.654327 
	C306.772003,339.246887 306.656342,339.367554 306.227936,339.358215 
	C305.011444,339.232635 304.107635,339.237091 303.069305,339.088806 
	C302.934753,338.936127 302.663971,338.632233 302.663971,338.632233 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M289.922760,324.862396 
	C289.815826,323.615295 290.154907,322.409790 290.493988,321.204315 
	C290.830719,321.238129 291.167419,321.271942 291.504150,321.305756 
	C291.405151,322.269196 291.306152,323.232605 291.235962,324.548981 
	C290.966095,324.902649 290.667450,324.903320 289.922760,324.862396 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M351.397705,365.765503 
	C350.791962,365.197235 350.404938,364.438629 349.989624,363.333801 
	C349.961365,362.987610 350.361450,362.922668 350.553772,362.864319 
	C351.036194,363.729034 351.326294,364.652100 351.397705,365.765503 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M309.210571,340.780243 
	C309.814453,341.258606 310.418335,341.736969 311.142029,342.487000 
	C311.110138,342.901215 310.958405,343.043793 310.365509,343.211792 
	C309.449799,342.656036 308.975250,342.074860 308.678467,341.315613 
	C308.974335,341.018433 309.092438,340.899353 309.210571,340.780243 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M355.427429,365.535339 
	C354.831879,364.992371 354.382080,364.411652 353.825073,363.469971 
	C354.447937,363.494293 355.178009,363.879547 355.973297,364.705536 
	C355.946960,365.347534 355.791840,365.464630 355.427429,365.535339 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M307.061951,338.945923 
	C307.789520,339.279755 308.342773,339.793854 309.053284,340.544098 
	C309.092438,340.899353 308.974335,341.018433 308.440399,341.157898 
	C307.529938,340.614899 307.035309,340.051575 306.540649,339.488251 
	C306.656342,339.367554 306.772003,339.246887 307.061951,338.945923 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M294.692047,346.740723 
	C295.005859,345.973663 295.503784,345.364258 296.450012,344.827759 
	C296.898346,344.900665 297.203461,345.208160 297.053467,345.447235 
	C296.352570,346.192841 295.801697,346.699402 295.250824,347.205933 
	C295.250824,347.205933 294.876160,346.898376 294.692047,346.740723 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M297.564728,343.585693 
	C298.042511,342.970703 298.520264,342.355743 299.449768,341.822815 
	C299.901520,341.904877 300.226807,342.234924 300.069397,342.471680 
	C299.349976,343.213898 298.787933,343.719360 298.063110,344.062469 
	C297.900330,343.900085 297.564728,343.585693 297.564728,343.585693 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M377.107727,342.907532 
	C376.959229,342.405609 377.246033,341.938293 377.532837,341.470978 
	C377.821991,341.752075 378.111145,342.033142 378.408325,342.622803 
	C378.125214,342.934967 377.834137,342.938568 377.107727,342.907532 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M301.597382,339.597015 
	C301.746094,339.296631 301.963806,339.059662 302.457245,338.759155 
	C302.663971,338.632233 302.934753,338.936127 302.945679,339.286621 
	C302.763000,339.887390 302.530884,340.094482 302.095093,340.092468 
	C301.929993,339.926514 301.597382,339.597015 301.597382,339.597015 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M299.742004,341.736816 
	C299.731445,341.279724 299.925659,341.025269 300.541565,340.862061 
	C300.918030,340.918793 301.261810,341.267578 301.115845,341.455627 
	C300.756348,341.883636 300.508636,342.080750 300.226807,342.234924 
	C300.226807,342.234924 299.901520,341.904877 299.742004,341.736816 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M326.216370,321.993774 
	C326.280609,321.844574 326.593689,321.701630 326.934937,321.780121 
	C326.963074,322.001587 326.465210,321.999969 326.216370,321.993774 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M287.096130,320.118774 
	C287.339111,320.035034 287.711334,320.218018 288.138611,320.721375 
	C287.870850,320.822998 287.548096,320.604279 287.096130,320.118774 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M300.750977,340.739746 
	C300.734985,340.276855 300.941406,340.041656 301.400299,339.726044 
	C301.597382,339.597015 301.929993,339.926514 301.961426,340.290649 
	C301.788330,340.905792 301.544647,341.110046 301.261810,341.267578 
	C301.261810,341.267578 300.918030,340.918793 300.750977,340.739746 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M296.747711,344.745056 
	C296.746613,344.308350 296.940552,344.062225 297.371826,343.718384 
	C297.564728,343.585693 297.900330,343.900085 297.924561,344.257385 
	C297.740723,344.863190 297.492279,345.061005 297.203461,345.208160 
	C297.203461,345.208160 296.898346,344.900665 296.747711,344.745056 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M775.022339,596.920349 
	C775.022278,596.920349 775.004150,596.596680 775.249451,596.110107 
	C776.645996,594.724609 777.726379,593.161255 778.959534,593.029663 
	C791.384399,591.703735 791.391418,591.769653 792.069702,604.503174 
	C792.112305,606.868469 792.168884,608.813660 792.141785,611.201904 
	C792.016235,613.733337 791.974426,615.821716 791.490845,617.953552 
	C788.367065,618.118713 785.685059,618.240540 783.002991,618.362305 
	C783.031494,618.685974 783.059937,619.009583 783.088440,619.333191 
	C786.152405,619.806335 789.216431,620.279419 792.174316,621.205688 
	C791.933960,623.605774 791.799744,625.552856 791.668701,627.454651 
	C790.888489,627.671570 790.416443,627.933777 789.960144,627.908936 
	C786.466980,627.718445 784.842285,629.008057 784.937744,632.827759 
	C785.045898,637.150696 787.711487,637.039246 790.687012,636.982483 
	C793.340454,636.931946 795.995605,636.971863 798.871033,636.971863 
	C796.752808,644.484924 790.938354,646.230652 785.912048,649.302002 
	C785.915649,650.458801 785.931763,651.228760 785.685181,652.256836 
	C784.406189,656.005432 786.185181,657.007446 789.290894,656.952576 
	C792.455078,656.896606 795.621094,656.940186 799.032227,656.940186 
	C797.735413,663.736633 793.091431,665.978027 788.428467,668.628662 
	C785.489990,669.437073 782.975952,670.217712 780.164429,671.090576 
	C778.879272,673.074097 777.891602,674.965393 776.651794,677.101074 
	C774.891296,680.113464 773.111511,682.853027 775.126343,686.141785 
	C771.143677,687.875305 767.523132,689.495789 763.825500,690.671936 
	C763.227173,684.033691 762.705933,677.839722 762.052368,671.268555 
	C760.445923,664.809082 760.635498,658.540405 758.292786,652.419617 
	C756.404419,647.485901 757.381042,641.455688 757.096191,635.538818 
	C756.986938,634.096863 756.868042,633.028015 756.749084,631.959229 
	C756.444885,631.978943 756.140625,631.998657 755.836426,632.018372 
	C755.836426,633.284912 755.836426,634.551453 755.617004,636.172852 
	C755.397583,643.171631 755.397583,649.815552 755.397583,656.308594 
	C750.899414,656.308594 746.692383,656.308594 742.485413,656.308594 
	C742.497803,656.092957 742.510193,655.877319 742.522583,655.661682 
	C744.924927,655.029785 747.327209,654.397888 749.688538,653.776794 
	C749.201782,651.767700 748.871582,650.404968 748.551147,649.048340 
	C748.560791,649.054443 748.584534,649.065613 748.965454,649.041138 
	C749.678345,647.976746 750.619507,646.536438 750.242432,645.962585 
	C748.000916,642.552063 748.654236,639.860962 751.672546,637.428284 
	C751.788818,637.334473 751.579468,636.836792 751.474548,636.272705 
	C750.304382,636.046692 749.048950,635.804138 747.766663,635.556458 
	C748.626404,633.031250 749.917053,630.655457 750.144104,628.181885 
	C750.378174,625.632080 749.576599,622.987183 749.631104,620.294495 
	C751.685913,620.120667 753.337830,620.036255 755.456055,620.011230 
	C763.639343,619.911804 771.356262,619.752930 779.416321,619.497437 
	C779.518433,618.993835 779.277466,618.586792 778.571716,618.111084 
	C772.831543,617.965820 767.556091,617.889160 762.254700,617.635864 
	C762.228699,617.459229 762.212463,617.102417 762.243835,616.753662 
	C761.743042,615.644104 761.210815,614.883362 760.790405,613.797913 
	C765.198425,612.757629 766.879944,609.921509 767.504822,605.879517 
	C771.434937,606.615723 776.530640,604.293945 777.077698,610.715149 
	C777.105591,611.042908 778.146973,611.381409 778.761719,611.547363 
	C787.261047,613.842407 790.268188,611.522888 790.037903,602.877930 
	C790.015686,602.045959 790.225708,601.067200 789.861023,600.419373 
	C789.369202,599.545654 788.320984,598.220947 787.690674,598.313171 
	C783.496582,598.927002 777.740540,595.314758 775.734314,602.413757 
	C775.666931,602.652039 774.528748,602.587769 773.896423,602.238403 
	C774.276184,600.181641 774.649231,598.551025 775.022339,596.920349 
M755.140137,624.830200 
	C755.451294,625.448547 755.762451,626.066833 756.073669,626.685181 
	C756.393494,625.773865 756.713318,624.862549 756.739807,623.492432 
	C756.218079,623.702820 755.696411,623.913147 755.140137,624.830200 
M779.405212,644.028381 
	C780.456970,643.847534 781.508728,643.666748 781.823181,643.612671 
	C781.555420,641.420776 781.358398,639.807495 781.175781,638.312012 
	C779.287048,638.312012 777.420288,638.312012 776.642822,638.312012 
	C776.128113,640.151306 775.804565,641.048218 775.632446,641.973328 
	C775.193115,644.334900 774.747559,646.702820 774.551025,649.088562 
	C774.533752,649.298340 776.324158,649.656921 776.485962,649.706177 
	C777.350769,647.442505 777.992554,645.762695 779.405212,644.028381 
M778.748779,650.941467 
	C777.630066,651.121826 776.511292,651.302124 774.366211,651.647949 
	C776.370728,653.081360 777.390503,653.810669 778.500305,654.604248 
	C777.334351,655.555420 776.348999,656.359192 774.835999,657.593445 
	C778.842468,658.309204 781.902222,658.881470 782.726746,654.384949 
	C783.200989,651.798584 782.201599,650.618408 778.748779,650.941467 
M756.460205,629.488281 
	C756.582336,629.256409 756.729431,629.032593 756.807983,628.786804 
	C756.826355,628.729492 756.611694,628.597656 756.504272,628.500122 
	C756.381653,628.731750 756.259033,628.963379 756.460205,629.488281 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M870.845947,565.265015 
	C871.401733,565.239868 871.772156,565.466919 872.401978,566.228516 
	C875.568726,570.149231 876.144226,567.060608 877.436096,565.308594 
	C878.669312,566.337158 879.475281,567.467224 880.572388,568.032959 
	C888.645203,572.196106 896.680725,576.457764 904.952209,580.191772 
	C907.173828,581.194702 910.169556,580.482971 913.012939,580.741516 
	C913.391907,580.969543 913.567810,581.014343 913.778625,581.463989 
	C913.648682,586.634155 917.992615,586.061523 920.592834,587.525146 
	C920.861816,587.676514 921.450928,587.259216 922.166077,587.010193 
	C921.925232,585.649414 921.699707,584.375183 921.474121,583.100952 
	C922.038574,582.942749 922.602966,582.784485 923.167358,582.626221 
	C923.847046,584.828613 924.526672,587.031067 925.426575,589.947205 
	C932.684448,591.859436 937.352783,597.043640 937.458862,605.884705 
	C939.279236,605.752380 940.600647,605.816650 941.845398,605.538452 
	C947.516968,604.270935 953.131958,602.616577 958.785706,605.870667 
	C959.296753,606.164856 960.868896,604.615479 962.328369,603.871948 
	C963.950439,604.156616 965.192444,604.491577 967.014038,604.982910 
	C965.050415,605.924683 963.966919,606.444275 962.883423,606.963928 
	C963.849243,607.915955 964.749695,608.951416 965.810486,609.782227 
	C966.284546,610.153503 967.080444,610.113953 967.964539,610.489624 
	C969.753540,611.384521 971.308350,612.050659 973.218933,612.839966 
	C974.314453,612.985596 975.054077,613.007996 975.793762,613.030396 
	C979.565369,615.278442 983.181702,617.855225 987.133179,619.722778 
	C1019.891235,635.205078 1052.710938,650.557129 1085.516235,665.939270 
	C1085.963013,666.148804 1086.465576,666.239563 1087.412354,666.804321 
	C1088.722778,668.565552 1089.562866,669.908264 1090.402954,671.250916 
	C1091.307007,669.887085 1092.211182,668.523193 1093.367798,667.267029 
	C1093.852661,667.866394 1094.084961,668.358032 1094.226318,669.211121 
	C1093.059082,670.782227 1091.982910,671.991943 1090.906738,673.201660 
	C1089.569946,672.710327 1087.361572,672.543640 1087.037964,671.674927 
	C1084.974854,666.135498 1079.567749,665.361389 1075.298096,663.313538 
	C1053.101807,652.667725 1030.770752,642.302856 1008.463196,631.890198 
	C1005.933167,630.709229 1003.256409,629.842407 1000.280945,628.419434 
	C999.115845,627.662720 998.317505,627.316528 997.195557,626.754883 
	C989.581848,623.028259 982.291809,619.517212 974.793701,615.745361 
	C973.109192,614.981934 971.632751,614.479370 969.880005,613.876343 
	C969.071533,613.528320 968.539368,613.280579 967.862427,612.786133 
	C967.194275,612.350159 966.670898,612.160828 965.802612,611.799438 
	C963.310852,611.483459 961.164062,611.339539 959.980591,611.260254 
	C958.297913,609.544312 957.069641,607.181763 955.699158,607.096130 
	C951.034180,606.804749 946.314453,607.244202 941.624512,607.544128 
	C937.943115,607.779480 936.782227,610.392700 935.303650,613.457458 
	C933.596008,616.997253 930.471069,619.853271 927.850403,622.637329 
	C928.716736,620.359802 929.759583,618.481445 930.659546,616.536926 
	C933.455200,610.496826 932.851501,604.549500 930.766357,598.934631 
	C927.413147,599.889099 924.722778,601.054932 921.941956,601.340942 
	C918.765869,601.667542 915.509338,601.210938 912.287720,601.094727 
	C912.556396,601.154663 912.799377,601.100037 913.451538,600.575562 
	C914.273987,599.576050 914.661560,598.931824 915.049133,598.287598 
	C914.376953,598.070435 913.704834,597.853271 913.032654,597.636108 
	C913.764465,594.583740 914.510620,594.374451 917.105347,596.040283 
	C917.746033,596.451599 918.936584,596.006470 920.364746,595.622437 
	C924.480896,595.999817 928.106567,596.709778 931.732300,597.419739 
	C931.826172,596.922791 931.920044,596.425903 932.013916,595.928955 
	C928.200195,594.299011 925.264954,589.769958 920.002136,592.921936 
	C918.870728,588.091858 914.567871,585.914001 909.934875,587.241455 
	C907.761169,585.349487 906.148682,583.362610 904.069214,582.243530 
	C897.359741,578.633423 890.569092,575.140259 883.617249,572.032288 
	C880.968262,570.848022 877.812927,570.721069 874.860596,570.312012 
	C871.971680,569.911682 870.157349,568.786316 870.845947,565.265015 
M934.435730,603.226135 
	C934.307251,604.347168 934.178711,605.468140 934.050232,606.589172 
	C934.438843,606.596191 934.827393,606.603149 935.216003,606.610168 
	C935.125183,605.699036 935.034424,604.787903 934.435730,603.226135 
M934.110229,601.291748 
	C933.967957,600.592346 933.825623,599.893005 933.683350,599.193604 
	C933.447449,599.231689 933.211609,599.269836 932.975708,599.307922 
	C933.160217,600.142883 933.344788,600.977905 934.110229,601.291748 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M911.847290,601.118225 
	C915.509338,601.210938 918.765869,601.667542 921.941956,601.340942 
	C924.722778,601.054932 927.413147,599.889099 930.766357,598.934631 
	C932.851501,604.549500 933.455200,610.496826 930.659546,616.536926 
	C929.759583,618.481445 928.716736,620.359802 927.863159,622.623535 
	C927.986938,622.978699 928.025757,622.979492 927.662842,622.975952 
	C926.366516,625.896606 925.002991,628.763611 924.604858,631.759033 
	C924.063538,635.831787 924.259949,640.002625 923.669800,644.122314 
	C920.567871,644.106506 917.861023,643.726013 915.332764,644.234131 
	C913.831970,644.535706 911.475708,646.214172 911.453369,647.313171 
	C911.320007,653.858643 911.755188,660.415649 912.037720,667.328369 
	C912.675293,671.579102 913.274658,675.470215 913.509521,679.252686 
	C911.901062,678.224304 910.657104,677.304565 909.604919,675.912476 
	C909.880188,662.648132 909.963684,649.856201 910.250488,637.017944 
	C910.453857,636.971680 910.866211,636.907654 911.285522,636.975586 
	C917.831665,638.391235 919.221863,633.647461 921.048401,629.639526 
	C921.972229,627.612061 924.704041,625.167664 921.241394,623.441833 
	C920.469666,623.057251 918.185730,625.871277 916.508728,627.089722 
	C914.991638,628.192078 913.352661,629.126648 911.618774,629.955505 
	C911.258118,629.547852 911.033813,629.332764 910.971802,628.799316 
	C914.399719,620.512146 914.399719,620.512146 908.977600,618.435486 
	C908.930725,618.362549 908.702148,618.119080 908.425415,617.779907 
	C907.474304,614.001099 906.799927,610.561401 906.052551,606.749451 
	C909.931885,606.312012 911.797974,604.806824 910.533203,601.263184 
	C910.824463,601.222656 911.115723,601.182190 911.847290,601.118225 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M728.161499,549.015869 
	C729.533203,548.474182 731.436646,548.320923 732.171936,547.314331 
	C734.012756,544.794312 735.324158,541.887634 737.533630,539.052612 
	C738.471436,538.976074 738.723633,538.983032 738.942566,539.346558 
	C737.567078,542.339050 736.361755,545.057190 734.842712,547.586914 
	C733.424377,549.948853 733.705566,551.629944 736.203064,552.798706 
	C740.624695,554.867859 745.070740,556.884460 749.708435,559.015869 
	C751.170715,556.874451 752.457520,554.989929 754.086670,553.067505 
	C754.977661,552.665100 755.526245,552.300537 756.413452,552.019104 
	C756.432434,554.077209 756.112854,556.052185 755.515747,558.061584 
	C754.549683,558.665466 753.861023,559.234924 752.866699,560.029968 
	C753.349304,561.177124 754.119812,562.881714 754.928711,562.900085 
	C763.733521,563.100159 772.544739,563.075500 781.353149,562.998596 
	C782.560974,562.988098 783.763306,562.360474 785.205933,562.147949 
	C786.281982,561.856995 787.120361,561.436707 787.958740,561.016479 
	C788.613586,561.851746 789.268433,562.687012 789.923279,563.522278 
	C788.839722,564.026306 787.763550,564.947876 786.671265,564.967407 
	C778.702332,565.109863 770.730042,565.071594 762.758850,565.088806 
	C761.121277,565.092346 759.483765,565.089355 757.064941,565.089355 
	C757.064941,569.654846 757.064941,573.886963 756.713501,578.530151 
	C755.977905,577.491943 755.472473,576.061829 755.239014,574.588623 
	C754.916443,572.553101 754.798950,570.485168 754.593567,568.431091 
	C754.200806,568.457153 753.808044,568.483276 753.415283,568.509338 
	C753.184875,570.073608 752.954468,571.637817 752.401001,573.171936 
	C750.047119,573.100891 748.016418,573.060059 745.912964,572.780518 
	C745.573303,572.126160 745.306519,571.710449 745.069031,570.866272 
	C744.351624,569.249146 743.865356,567.385681 742.816284,566.980835 
	C735.799683,564.273254 735.761963,564.374573 737.811768,557.197388 
	C737.852173,557.055786 737.761169,556.876709 737.731506,556.715088 
	C732.662415,553.522278 731.526733,553.910583 729.779053,559.294373 
	C729.557495,559.976990 728.894287,560.516296 728.310303,560.805542 
	C726.787659,561.084106 725.390259,561.679077 723.882019,562.321289 
	C721.219238,557.847778 723.184631,550.603333 728.161499,549.015869 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M792.083618,604.083008 
	C791.391418,591.769653 791.384399,591.703735 778.959534,593.029663 
	C777.726379,593.161255 776.645996,594.724609 775.244141,595.948059 
	C772.607605,589.753357 773.183228,588.135010 779.538391,587.749634 
	C789.397827,587.151733 799.301514,587.152771 809.184021,587.224365 
	C810.440918,587.233521 811.683716,589.193726 813.040527,590.988892 
	C814.149658,593.460022 815.151245,595.192383 816.152771,596.924744 
	C815.633545,597.569031 815.114319,598.213257 814.231689,598.798096 
	C813.784973,598.683899 813.701599,598.629089 813.618164,598.574341 
	C813.754211,598.710327 813.890259,598.846313 814.026367,598.982300 
	C814.003784,600.589844 813.981140,602.197449 813.876343,604.529053 
	C810.218811,605.313721 806.643372,605.374329 802.858398,605.174805 
	C801.586853,604.529114 800.534241,603.851379 799.461121,603.817139 
	C797.007324,603.738586 794.543396,603.974670 792.083618,604.083008 
M800.082092,596.830444 
	C800.937683,597.870789 802.413208,599.893860 802.555725,599.803894 
	C804.651245,598.481812 806.569458,596.878479 808.529724,595.341980 
	C808.346741,594.626404 808.163757,593.910767 807.980774,593.195190 
	C805.852295,592.904846 803.692200,592.299927 801.613770,592.508606 
	C800.958313,592.574402 800.521729,594.819519 800.082092,596.830444 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M910.367737,601.334717 
	C911.797974,604.806824 909.931885,606.312012 906.052551,606.749451 
	C906.799927,610.561401 907.474304,614.001099 908.201660,618.121826 
	C907.528687,620.958069 906.387512,623.095459 906.293579,625.277893 
	C906.258972,626.080994 908.697449,626.990601 909.991089,628.136536 
	C910.248596,628.657776 910.522888,628.894165 910.797180,629.130554 
	C911.033813,629.332764 911.258118,629.547852 911.405640,630.119995 
	C910.096130,631.353333 906.896179,625.811890 907.117615,632.093384 
	C907.022461,632.947205 906.989258,633.436829 906.958374,634.066040 
	C906.960632,634.205627 906.994446,634.482788 906.918579,634.479370 
	C906.842651,634.476013 906.989624,634.520447 907.020264,634.846130 
	C907.070801,635.388977 907.090576,635.606140 907.062744,636.099121 
	C906.996948,636.560608 906.978882,636.746277 906.971558,636.952026 
	C906.982178,636.972046 906.934448,636.967468 906.631348,636.972412 
	C905.925598,636.947083 905.523010,636.916870 905.079468,636.554810 
	C905.004211,635.776428 904.969849,635.329895 904.846802,634.650818 
	C904.581665,634.128662 904.347961,633.896606 904.058899,633.569214 
	C904.060791,633.416199 904.050720,633.110352 904.049438,632.672729 
	C903.969482,630.519897 903.890747,628.804810 904.033691,626.770752 
	C904.079468,624.963013 903.903625,623.474121 903.765381,621.679932 
	C903.810425,620.965088 903.817932,620.555542 904.047729,619.816284 
	C904.433105,617.921692 904.596191,616.356750 904.795837,614.361816 
	C904.271484,610.726013 907.024475,605.897095 901.535400,605.815552 
	C902.214844,604.440002 902.929626,602.992798 903.644470,601.545532 
	C902.259888,601.972595 900.885742,602.438660 899.486938,602.812622 
	C898.597229,603.050598 897.356934,603.611816 896.812195,603.239746 
	C893.268494,600.819519 886.297485,601.385864 888.323975,593.866089 
	C888.445557,593.415161 887.731567,592.739014 887.666809,591.971008 
	C887.962646,591.196167 887.998169,590.617920 888.016846,590.021729 
	C888.000000,590.003784 888.040649,590.029907 888.464600,589.963806 
	C889.112122,587.991150 889.335693,586.084595 889.559326,584.178040 
	C892.648499,585.900696 895.666870,587.770691 898.848633,589.301025 
	C901.576782,590.613220 904.323730,591.328857 903.462219,595.580750 
	C903.170715,597.019592 905.189819,598.926575 906.400269,601.253052 
	C907.118652,602.542236 907.589783,603.197876 908.060913,603.853516 
	C908.554871,602.961304 909.048828,602.069092 909.542847,601.176880 
	C909.720642,601.374390 909.940430,601.450867 910.367737,601.334717 
M893.075684,595.246826 
	C895.283813,594.426941 897.492004,593.607056 901.086304,592.272522 
	C897.763428,590.979370 895.872986,590.243713 893.488098,589.315613 
	C892.729614,591.311646 892.110779,592.940125 891.210388,594.974976 
	C891.595276,595.109924 891.980103,595.244934 893.075684,595.246826 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M773.889709,602.664490 
	C774.528748,602.587769 775.666931,602.652039 775.734314,602.413757 
	C777.740540,595.314758 783.496582,598.927002 787.690674,598.313171 
	C788.320984,598.220947 789.369202,599.545654 789.861023,600.419373 
	C790.225708,601.067200 790.015686,602.045959 790.037903,602.877930 
	C790.268188,611.522888 787.261047,613.842407 778.761719,611.547363 
	C778.146973,611.381409 777.105591,611.042908 777.077698,610.715149 
	C776.530640,604.293945 771.434937,606.615723 767.482300,605.906494 
	C767.021240,605.926697 767.075378,605.971436 767.316040,605.747559 
	C767.590393,605.090698 767.624146,604.657654 767.657837,604.224609 
	C768.520142,603.755981 769.382446,603.287354 770.828918,602.845703 
	C772.238647,602.803223 773.064148,602.733887 773.889709,602.664490 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M803.067993,605.434937 
	C806.643372,605.374329 810.218811,605.313721 813.943359,605.008118 
	C815.318726,604.606934 816.544800,604.450745 817.604614,604.793091 
	C816.364502,606.225647 815.290833,607.159668 814.217102,608.093689 
	C817.849365,608.622070 820.454407,607.997742 820.072693,604.019836 
	C822.128174,603.767395 824.183594,603.514893 826.766724,603.463013 
	C827.785461,603.844177 828.276550,604.024658 828.960938,604.481934 
	C831.033875,604.645081 832.913513,604.531311 835.097168,604.399231 
	C834.960449,605.809570 834.795410,607.512085 834.654358,608.966919 
	C829.351318,608.710938 824.475098,607.614746 819.528137,610.103821 
	C817.062500,611.344482 813.900391,611.200806 811.035278,611.333069 
	C808.650391,611.040833 806.283386,611.098816 803.716309,610.942505 
	C803.335999,610.173096 803.155823,609.617981 802.987061,608.695740 
	C803.021606,607.364075 803.044800,606.399536 803.067993,605.434937 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M756.073486,597.037476 
	C756.044556,597.486877 756.015686,597.936218 755.543823,598.703613 
	C751.527466,598.679626 750.706299,600.744202 750.730957,603.922974 
	C750.988708,604.792053 751.479858,605.490540 751.979858,606.619995 
	C752.033569,609.070129 752.078491,611.089233 752.119873,613.387573 
	C752.159668,614.037598 752.203003,614.408264 752.270386,615.097473 
	C752.571716,615.990173 752.849060,616.564392 752.849487,617.427734 
	C747.029846,617.786926 741.487061,617.856873 735.971802,617.520447 
	C735.855042,611.455933 740.143066,613.225098 743.030273,613.108704 
	C748.654602,612.881897 749.437866,611.562805 746.741516,606.051636 
	C747.171997,604.542969 747.491028,603.304565 747.870239,601.546021 
	C748.018433,597.973206 748.106384,594.920593 748.492249,591.905090 
	C749.189392,591.958374 749.588623,591.974548 750.362854,592.061646 
	C752.756958,593.505859 751.461792,598.484497 756.073486,597.037476 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M866.872986,585.341187 
	C873.312744,587.509583 879.679016,585.304749 886.684570,585.398987 
	C887.541321,587.196106 887.791016,588.613037 888.040649,590.029907 
	C888.040649,590.029907 888.000000,590.003784 887.755615,589.706238 
	C887.007446,589.583252 886.503662,589.757874 885.630981,589.944519 
	C883.988525,590.760376 882.714966,591.564148 881.169983,592.539246 
	C880.715149,591.067505 880.267578,589.619202 879.547119,587.288025 
	C878.168640,589.396729 877.249329,590.803040 876.403015,592.097717 
	C874.943604,590.598450 873.438477,589.052307 871.521729,587.083313 
	C870.700684,589.647888 870.253967,591.043152 869.792603,592.484192 
	C868.522095,590.834229 867.523743,589.537781 866.525391,588.241272 
	C866.052795,589.751404 865.676453,591.301575 865.069885,592.755798 
	C864.732971,593.563660 864.012024,594.211304 863.405884,594.729980 
	C862.919250,594.200439 862.491089,593.871948 862.464539,593.451782 
	C862.500793,592.853821 862.135437,592.347473 861.452332,591.755371 
	C860.847839,590.227722 860.561096,588.785889 860.274414,587.344055 
	C861.650391,586.646667 863.026367,585.949280 865.005859,585.193237 
	C866.030518,585.203430 866.451721,585.272339 866.872986,585.341187 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M828.767639,604.205139 
	C828.276550,604.024658 827.785461,603.844177 827.153259,603.442749 
	C828.034058,598.839722 828.853149,594.393494 830.203064,590.114807 
	C830.601929,588.850525 832.386597,587.423584 833.733704,587.184570 
	C836.445679,586.703552 839.293335,586.987549 842.908081,586.961914 
	C845.839233,587.663513 847.947388,588.359192 850.010376,589.377808 
	C849.976868,590.133850 849.988403,590.566956 849.834106,591.152588 
	C850.130676,591.518127 850.593140,591.731079 851.035400,591.935303 
	C851.015198,591.926636 851.061218,591.918518 851.022827,592.274902 
	C850.966980,593.109375 850.949585,593.587463 850.529419,594.081299 
	C845.306030,593.414429 840.499268,592.548035 835.654724,592.186218 
	C834.526794,592.101929 832.713867,593.475952 832.181152,594.625977 
	C830.762451,597.688965 829.869568,600.995483 828.767639,604.205139 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M750.964294,603.752441 
	C750.706299,600.744202 751.527466,598.679626 755.478516,599.034973 
	C756.852600,600.003296 757.742126,601.119446 758.873901,601.870728 
	C760.159973,602.724426 761.662537,603.251953 763.558228,604.334839 
	C765.055481,605.156067 766.065430,605.563782 767.075378,605.971436 
	C767.075378,605.971436 767.021240,605.926697 767.043823,605.899719 
	C766.879944,609.921509 765.198425,612.757629 760.467041,613.856445 
	C758.675903,615.156616 757.319885,616.073547 756.082764,616.733765 
	C756.186096,615.715454 756.170654,614.953796 756.119019,613.830322 
	C756.050659,612.985657 756.018616,612.502869 756.248169,612.110840 
	C757.013062,611.786743 757.516357,611.371948 758.315369,610.826538 
	C759.512573,610.295837 760.414001,609.895752 761.108887,609.587280 
	C760.801819,607.692810 760.515259,605.924988 760.217651,604.088989 
	C754.332764,603.002441 756.410950,607.408936 755.630493,610.064026 
	C754.238586,611.109436 753.181030,612.108887 752.123413,613.108276 
	C752.078491,611.089233 752.033569,609.070129 752.303711,606.510620 
	C753.393982,605.260010 754.169189,604.549927 755.771729,603.081970 
	C753.177063,603.443848 752.070679,603.598145 750.964294,603.752441 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M787.953491,560.680054 
	C787.120361,561.436707 786.281982,561.856995 784.851807,562.086182 
	C776.175720,557.926270 768.091553,553.957336 760.028992,549.963318 
	C760.050659,549.938171 760.090515,549.885071 760.090515,549.885071 
	C761.656616,549.775940 763.222717,549.666809 764.896118,549.280029 
	C766.621826,551.334290 767.786865,550.956726 768.903687,548.609619 
	C769.859070,549.836304 770.257446,551.320129 771.177979,551.797974 
	C775.999084,554.300598 780.952515,556.548218 785.859985,558.884460 
	C786.333862,558.917297 786.807678,558.950134 787.637817,558.991089 
	C787.978760,559.447327 787.963501,559.895508 787.953491,560.680054 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M859.874146,587.281128 
	C860.561096,588.785889 860.847839,590.227722 861.516663,592.182739 
	C861.953491,592.978455 862.008179,593.260925 862.062927,593.543457 
	C862.491089,593.871948 862.919250,594.200439 863.679443,594.989258 
	C867.555420,597.118286 871.174133,598.647461 874.621826,600.495728 
	C877.950378,602.280029 881.102600,604.393372 883.905701,606.267273 
	C880.660095,605.438477 877.841003,604.704529 874.869385,603.665039 
	C873.493896,602.889465 872.270935,602.419556 870.884888,601.645386 
	C867.493713,599.877197 864.265564,598.413269 860.863403,596.642944 
	C859.170349,595.840393 857.651306,595.344238 856.078857,594.555847 
	C855.656982,593.816895 855.288513,593.370178 855.000427,592.574402 
	C855.028198,590.176636 854.975525,588.127930 854.922913,586.079224 
	C856.439880,586.458862 857.956848,586.838562 859.874146,587.281128 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M752.724121,573.202087 
	C752.954468,571.637817 753.184875,570.073608 753.415283,568.509338 
	C753.808044,568.483276 754.200806,568.457153 754.593567,568.431091 
	C754.798950,570.485168 754.916443,572.553101 755.239014,574.588623 
	C755.472473,576.061829 755.977905,577.491943 756.740479,578.999084 
	C757.297119,580.853699 757.475403,582.650513 757.122253,584.689453 
	C756.397522,584.951599 756.204163,584.971497 755.611755,584.993774 
	C754.520508,585.354675 753.828369,585.713318 753.124146,585.504028 
	C753.023254,581.914307 752.934448,578.892639 752.863281,575.535278 
	C752.828613,574.533813 752.776367,573.867981 752.724121,573.202087 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M872.330688,579.376343 
	C870.625610,580.221008 868.920593,581.065674 866.698975,582.164917 
	C865.801392,582.497620 865.420349,582.575684 865.039307,582.653809 
	C864.034973,581.133301 861.853333,578.961731 862.262085,578.222534 
	C863.226868,576.477600 865.424866,575.414612 867.533936,574.414551 
	C866.634216,576.196167 865.324646,577.639038 864.015137,579.081848 
	C864.196106,579.408875 864.377075,579.735901 864.558105,580.062927 
	C866.363464,579.374084 868.168823,578.685242 870.282471,578.143311 
	C871.170715,578.652222 871.750671,579.014282 872.330688,579.376343 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M872.622437,579.447815 
	C871.750671,579.014282 871.170715,578.652222 870.246338,577.752441 
	C870.118408,576.202698 870.334839,575.190735 870.551270,574.178772 
	C877.032654,572.110962 877.254700,576.693237 877.853577,581.732300 
	C875.976379,580.891296 874.445251,580.205261 872.622437,579.447815 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M753.136169,586.071899 
	C753.828369,585.713318 754.520508,585.354675 755.745972,585.326904 
	C756.421997,585.849792 756.564819,586.041931 756.632080,586.587463 
	C756.638794,587.639893 756.721069,588.338928 756.803345,589.037964 
	C756.670227,589.785278 756.537170,590.532654 755.978149,591.648560 
	C753.662537,591.643616 751.772888,591.270142 749.618103,590.915771 
	C748.996826,590.927856 748.640747,590.920776 748.195496,590.703613 
	C748.106384,590.493652 747.964355,590.060059 747.991577,589.663879 
	C748.011536,588.214050 748.004333,587.160461 748.357422,586.062378 
	C750.190552,586.035889 751.663330,586.053894 753.136169,586.071899 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M920.007812,593.333740 
	C925.264954,589.769958 928.200195,594.299011 932.013916,595.928955 
	C931.920044,596.425903 931.826172,596.922791 931.732300,597.419739 
	C928.106567,596.709778 924.480896,595.999817 920.497498,595.339355 
	C920.097717,594.841003 920.055603,594.293274 920.007812,593.333740 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M749.883240,590.896667 
	C751.772888,591.270142 753.662537,591.643616 755.925964,592.028931 
	C756.381592,593.542358 756.463440,595.044067 756.309387,596.791626 
	C751.461792,598.484497 752.756958,593.505859 750.352722,591.786621 
	C749.939453,591.259277 749.911316,591.077942 749.883240,590.896667 
M753.586304,594.512146 
	C753.586304,594.512146 753.477173,594.574890 753.586304,594.512146 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M854.583801,586.061523 
	C854.975525,588.127930 855.028198,590.176636 854.659485,592.549438 
	C853.179138,592.555237 852.120178,592.236877 851.061218,591.918457 
	C851.061218,591.918518 851.015198,591.926636 850.951233,591.697754 
	C850.591492,591.312622 850.295776,591.156311 850.000000,591.000000 
	C849.988403,590.566956 849.976868,590.133850 850.101257,589.311707 
	C850.237244,588.922607 850.347351,588.726501 850.347351,588.726501 
	C851.646484,587.832275 852.945557,586.938049 854.583801,586.061523 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M816.400024,596.671387 
	C815.151245,595.192383 814.149658,593.460022 813.174011,591.357666 
	C817.192810,590.992493 817.429382,593.381958 816.400024,596.671387 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M870.103027,574.128662 
	C870.334839,575.190735 870.118408,576.202698 869.938110,577.605530 
	C868.168823,578.685242 866.363464,579.374084 864.558105,580.062927 
	C864.377075,579.735901 864.196106,579.408875 864.015137,579.081848 
	C865.324646,577.639038 866.634216,576.196167 867.899414,574.404053 
	C868.455017,574.062744 869.054932,574.070679 870.103027,574.128662 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M850.203857,588.367065 
	C850.347351,588.726501 850.237244,588.922607 850.146362,588.988770 
	C847.947388,588.359192 845.839233,587.663513 843.364929,586.898499 
	C845.886597,584.022400 848.220398,584.535706 850.203857,588.367065 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M773.896423,602.238403 
	C773.064148,602.733887 772.238647,602.803223 771.117310,602.759888 
	C771.989441,600.763611 773.157288,598.880127 774.673706,596.958496 
	C774.649231,598.551025 774.276184,600.181641 773.896423,602.238403 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M819.817139,604.103821 
	C820.454407,607.997742 817.849365,608.622070 814.217102,608.093689 
	C815.290833,607.159668 816.364502,606.225647 817.798889,605.136353 
	C818.626892,604.716675 819.094177,604.452271 819.817139,604.103821 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M888.464600,589.963806 
	C887.791016,588.613037 887.541321,587.196106 887.122009,585.444824 
	C887.569214,584.775879 888.186157,584.441406 889.181213,584.142456 
	C889.335693,586.084595 889.112122,587.991150 888.464600,589.963806 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M909.066650,601.131104 
	C909.048828,602.069092 908.554871,602.961304 908.060913,603.853516 
	C907.589783,603.197876 907.118652,602.542236 906.664795,601.500610 
	C907.318176,601.104797 907.954285,601.095032 909.066650,601.131104 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M767.316040,605.747559 
	C766.065430,605.563782 765.055481,605.156067 763.911011,604.420776 
	C764.748108,604.094177 765.719849,604.095215 767.174683,604.160461 
	C767.624146,604.657654 767.590393,605.090698 767.316040,605.747559 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M792.920532,553.178162 
	C793.250427,553.665710 793.309021,554.078552 793.367676,554.491394 
	C792.834900,554.298157 792.302185,554.104980 791.769409,553.911804 
	C792.062744,553.642395 792.356079,553.372925 792.920532,553.178162 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M757.012573,588.947693 
	C756.721069,588.338928 756.638794,587.639893 756.721558,586.632446 
	C756.886658,586.324036 757.024048,586.472839 757.024048,586.472839 
	C757.089966,587.267700 757.155884,588.062561 757.012573,588.947693 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M913.031982,598.129639 
	C913.704834,597.853271 914.376953,598.070435 915.049133,598.287598 
	C914.661560,598.931824 914.273987,599.576050 913.539673,600.404785 
	C913.138977,599.933960 913.085144,599.278564 913.031982,598.129639 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M865.025391,583.036255 
	C865.420349,582.575684 865.801392,582.497620 866.472168,582.387512 
	C866.817078,583.018860 866.872314,583.682251 866.900269,584.843384 
	C866.451721,585.272339 866.030518,585.203430 865.303955,585.037842 
	C865.002869,584.433716 865.007141,583.926208 865.025391,583.036255 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M784.058350,537.842957 
	C783.544128,537.551392 783.123901,537.019653 782.703613,536.487854 
	C783.256470,536.381042 783.809326,536.274231 784.648682,536.233521 
	C784.674194,536.733948 784.413269,537.168335 784.058350,537.842957 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M757.066956,586.101074 
	C757.024048,586.472839 756.886658,586.324036 756.797119,586.279053 
	C756.564819,586.041931 756.421997,585.849792 756.145020,585.324585 
	C756.204163,584.971497 756.397522,584.951599 756.882324,584.957520 
	C757.152527,585.231995 757.131165,585.480652 757.066956,586.101074 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M681.864502,488.117859 
	C683.048096,488.387451 684.231628,488.657043 685.765869,488.922333 
	C686.116577,488.918060 686.061218,488.938110 685.999329,489.263367 
	C685.893555,489.804871 685.849609,490.021118 685.805725,490.237396 
	C685.595886,497.284821 684.951904,504.365723 685.491333,511.355316 
	C685.620850,513.033447 689.491943,514.977234 691.973511,515.801025 
	C696.761719,517.390564 698.032471,519.731689 695.139343,524.022888 
	C694.781921,524.553040 694.803772,525.336670 694.451904,525.872986 
	C691.810303,529.899902 692.672791,532.928650 697.011353,535.483643 
	C697.064331,535.925476 696.881104,536.165039 696.515747,536.122070 
	C694.382751,535.418152 692.615112,534.757324 690.860229,534.101196 
	C689.164490,537.543335 687.637085,540.643738 685.838989,543.715759 
	C684.689758,543.758789 683.811218,543.830139 682.973389,543.444519 
	C682.818054,535.553284 682.686890,528.116211 682.362610,520.687439 
	C682.308105,519.438354 681.418274,518.225708 680.914429,516.996216 
	C680.275208,518.108459 679.062622,519.234253 679.088440,520.330811 
	C679.278503,528.386047 679.716309,536.435547 679.736938,544.582703 
	C677.941223,544.854309 676.484497,545.030029 675.058655,544.724854 
	C675.089478,534.602478 675.089478,524.960999 675.089478,515.124146 
	C674.609924,515.124146 673.213806,515.124146 671.734070,515.124146 
	C672.078735,516.066772 672.339722,516.780396 672.770264,517.957825 
	C670.883118,517.957825 669.415955,517.957825 667.948730,517.957825 
	C668.452454,515.745239 668.956177,513.532715 670.186707,511.354858 
	C674.736450,511.656738 678.559387,511.923981 683.383118,512.261169 
	C682.836548,503.572052 682.350525,495.844971 681.864502,488.117859 
M685.034302,521.216431 
	C685.034302,524.787292 685.034302,528.358093 685.034302,531.928955 
	C685.404358,532.049316 685.774475,532.169739 686.144531,532.290100 
	C687.782837,528.778992 689.421082,525.267822 691.686096,520.413574 
	C688.678284,520.413574 686.894592,520.413574 685.034302,521.216431 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M697.154602,535.804626 
	C705.279419,539.096436 713.313965,542.509094 721.732788,546.347656 
	C721.594727,548.003540 721.072327,549.233582 720.306274,550.182556 
	C715.735657,546.365051 716.638977,554.573853 714.285950,553.487061 
	C713.335144,550.784851 712.668762,548.891235 711.951294,546.857544 
	C711.809021,546.673096 711.717957,546.628784 711.626892,546.584412 
	C711.750488,546.723755 711.874023,546.863098 711.941528,547.355469 
	C710.058289,550.165466 707.434814,551.509399 704.890442,549.786377 
	C703.006470,548.510559 702.048523,545.867432 700.771790,543.972534 
	C699.741821,545.618896 698.451111,547.681885 697.168701,549.731689 
	C698.643494,550.433044 700.364624,551.082947 701.910889,552.021118 
	C705.344604,554.104370 708.690674,556.332153 711.910156,558.687744 
	C711.312256,559.108398 710.876648,559.342285 710.116943,559.321289 
	C704.293823,556.488953 698.794861,553.911438 693.320251,551.345215 
	C694.631409,547.760376 694.906067,544.723877 690.974792,542.986511 
	C691.010925,542.988586 691.011780,543.061035 690.914673,542.864563 
	C690.661377,542.403625 690.435486,542.229858 690.209717,541.788086 
	C691.266785,534.495972 695.919128,538.451782 700.467468,539.059326 
	C698.563416,537.522705 697.722229,536.843872 696.881104,536.165039 
	C696.881104,536.165039 697.064331,535.925476 697.154602,535.804626 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M720.549927,550.463623 
	C721.072327,549.233582 721.594727,548.003540 722.095337,546.408813 
	C724.106628,543.755920 726.139648,541.467651 728.172729,539.179443 
	C728.565552,539.274292 728.958374,539.369141 729.351135,539.463989 
	C728.979858,542.393494 728.608582,545.322998 728.199341,548.634155 
	C723.184631,550.603333 721.219238,557.847778 723.882019,562.321289 
	C725.390259,561.679077 726.787659,561.084106 728.140015,561.093262 
	C727.760437,562.499512 727.425781,563.301636 726.780029,564.191406 
	C725.636414,565.512817 724.803833,566.746521 723.614746,568.086548 
	C722.274109,568.241028 721.290039,568.289246 720.000916,568.142273 
	C717.926270,566.924805 716.156677,565.902405 714.063477,564.726562 
	C712.454895,563.415527 711.169983,562.258057 710.037354,560.870483 
	C710.273499,560.285583 710.357300,559.930908 710.441101,559.576172 
	C710.876648,559.342285 711.312256,559.108398 712.370483,558.653809 
	C714.891418,558.172241 716.789795,557.911377 718.269958,557.707947 
	C719.053833,555.217407 719.801941,552.840515 720.549927,550.463623 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M686.133606,488.894104 
	C686.385864,487.798279 686.621155,486.726471 686.914185,485.320984 
	C686.971924,484.987305 686.995422,484.997375 687.362305,484.892334 
	C688.152832,483.681213 688.576599,482.575134 689.000366,481.469055 
	C689.000366,481.469055 689.288025,481.130707 689.773560,481.174622 
	C696.904053,477.487305 699.363892,478.512634 705.929016,489.003662 
	C703.868591,490.481171 701.274536,492.044739 699.036804,494.013275 
	C695.394409,497.217529 693.269409,496.272217 692.426514,491.920532 
	C694.455566,491.774261 696.058716,491.642487 698.225342,491.464386 
	C697.878906,488.468445 697.760376,485.759064 697.044617,483.217957 
	C696.946411,482.869385 693.877197,483.357727 692.183472,483.458588 
	C692.201416,483.892395 692.219299,484.326233 692.237244,484.760040 
	C693.543579,484.960510 694.849915,485.160980 696.505981,485.415100 
	C694.839600,487.871857 693.444519,489.928741 691.701294,491.968750 
	C689.589233,490.947327 687.825195,489.942719 686.061218,488.938110 
	C686.061218,488.938110 686.116577,488.918060 686.133606,488.894104 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M756.074890,551.936035 
	C755.526245,552.300537 754.977661,552.665100 754.152100,552.643677 
	C755.688416,546.822449 750.672668,544.937134 748.595825,541.407837 
	C748.990051,541.008545 749.147522,540.848633 749.610107,540.811462 
	C751.787476,542.522949 753.659729,544.111572 755.682251,545.827759 
	C756.653076,544.927551 757.475708,544.122925 758.341736,543.368225 
	C767.480164,535.404419 766.823242,537.688049 758.437866,528.768616 
	C756.399292,526.600281 754.758606,526.446777 752.666260,528.718994 
	C750.766602,530.781982 748.529907,532.534668 746.281372,534.271851 
	C746.120911,534.119507 745.799011,533.815979 745.799011,533.815979 
	C746.668640,532.632385 747.378601,531.276733 748.435974,530.295410 
	C752.619934,526.412354 756.874451,522.627563 755.508301,518.157837 
	C753.876587,526.102600 763.522583,529.186768 768.144836,536.418030 
	C766.102905,538.533508 763.908203,540.870789 761.641113,543.135620 
	C759.914246,544.860901 757.399780,546.276428 759.917114,549.562866 
	C760.090515,549.885071 760.050659,549.938171 759.652405,549.972534 
	C758.194397,550.649963 757.134644,551.293030 756.074890,551.936035 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M692.000610,491.935059 
	C693.269409,496.272217 695.394409,497.217529 699.036804,494.013275 
	C701.274536,492.044739 703.868591,490.481171 705.929016,489.003662 
	C699.363892,478.512634 696.904053,477.487305 689.864380,481.110229 
	C689.822815,480.441284 690.115417,479.829620 690.538330,479.327850 
	C695.223511,473.770477 695.255249,473.769012 700.340088,478.745117 
	C701.288269,479.672974 702.176147,480.662048 703.101746,481.613190 
	C709.004211,487.678009 709.005005,487.682831 702.852173,493.418762 
	C695.904724,499.895508 695.904541,499.895599 689.076294,493.506683 
	C688.107056,492.599792 687.139648,491.690979 685.988586,490.510193 
	C685.849609,490.021118 685.893555,489.804871 685.999329,489.263367 
	C687.825195,489.942719 689.589233,490.947327 691.671875,491.973022 
	C691.990601,491.994110 692.000610,491.935059 692.000610,491.935059 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M738.975769,538.989929 
	C738.723633,538.983032 738.471436,538.976074 737.840454,538.954102 
	C738.584778,537.907959 739.587585,536.679565 740.864624,535.900696 
	C742.166138,535.106873 743.737244,534.755066 745.494629,534.012878 
	C745.799011,533.815979 746.120911,534.119507 746.174438,534.574097 
	C747.253601,536.915405 748.279297,538.802002 749.304932,540.688660 
	C749.147522,540.848633 748.990051,541.008545 748.328613,541.190430 
	C745.219727,539.098938 742.921204,535.765625 738.975769,538.989929 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M688.710693,481.693542 
	C688.576599,482.575134 688.152832,483.681213 687.356445,484.882874 
	C687.462769,483.958313 687.941895,482.938171 688.710693,481.693542 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M755.432617,512.540894 
	C755.384766,512.465332 755.580322,512.439575 755.580322,512.439575 
	C755.580322,512.439575 755.480530,512.616516 755.432617,512.540894 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M224.323669,376.345612 
	C223.974335,375.721619 223.944977,375.354828 223.876526,374.718689 
	C235.117874,374.287628 246.398773,374.151001 257.678314,373.941803 
	C259.807220,373.902344 261.930725,373.574219 264.420441,373.205872 
	C265.100098,373.143494 265.416046,373.254578 265.874390,373.576172 
	C264.834991,374.529785 263.670166,375.887848 262.468658,375.921112 
	C249.863693,376.270111 237.252869,376.407135 224.323669,376.345612 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M785.941284,558.487549 
	C780.952515,556.548218 775.999084,554.300598 771.177979,551.797974 
	C770.257446,551.320129 769.859070,549.836304 769.176025,548.453247 
	C769.132263,548.089905 769.077454,548.033447 769.303589,548.104858 
	C769.696106,548.130188 769.862488,548.084045 770.028809,548.037964 
	C772.280823,544.086548 774.532837,540.135132 776.780945,536.190491 
	C784.626099,538.089600 789.170837,549.597839 785.941284,558.487549 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M769.928589,547.871094 
	C769.862488,548.084045 769.696106,548.130188 769.274536,548.131409 
	C769.289001,547.959045 769.558655,547.831604 769.928589,547.871094 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M274.332672,352.627838 
	C280.835876,354.155609 281.070496,354.707214 278.568237,363.424255 
	C277.829437,365.309784 277.440887,366.658447 276.862244,368.231567 
	C276.107300,368.594971 275.542480,368.733917 274.977661,368.872864 
	C274.779877,368.673645 274.606293,368.455231 274.326019,368.053772 
	C274.231262,362.953613 274.267365,358.017242 274.332672,352.627838 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M293.874390,353.278015 
	C296.414886,354.381805 298.828827,355.809113 301.608704,357.124847 
	C301.974609,357.013306 301.996033,357.031891 301.955383,357.336365 
	C302.266205,357.762054 302.617706,357.883301 302.979065,358.013733 
	C302.988953,358.022949 302.990601,358.052246 302.990601,358.052246 
	C304.364990,362.900116 306.074554,367.685181 306.999176,372.617401 
	C307.645325,376.064087 309.915619,375.286102 311.850128,375.286011 
	C323.616821,375.285431 335.383484,375.160522 347.944458,375.075043 
	C349.814911,374.770966 350.891022,374.468964 351.967163,374.166962 
	C354.416107,374.633972 356.865051,375.101013 359.176758,376.080719 
	C357.406555,377.005371 355.773621,377.417297 354.140686,377.829224 
	C354.136414,378.223480 354.132141,378.617706 354.127869,379.011932 
	C355.679077,379.601196 357.230255,380.190430 359.351227,380.996124 
	C359.351227,396.924042 359.989136,413.085480 359.132904,429.167328 
	C358.535767,440.383514 361.746552,447.911133 372.908447,451.631989 
	C372.952576,459.457153 372.920898,466.882751 372.549744,474.181396 
	C356.510773,466.528015 340.833557,458.954376 325.096985,451.506195 
	C320.838074,449.490417 317.039673,447.504852 318.851654,441.668701 
	C319.076935,440.943115 318.281342,439.900543 318.051392,438.756989 
	C318.096863,438.339142 318.049744,438.169159 318.035156,437.661621 
	C318.011810,436.877472 317.955963,436.430847 317.941040,435.566711 
	C317.111359,434.400696 316.284546,433.076721 315.362396,433.006592 
	C311.959686,432.747772 308.525421,432.903748 305.448853,432.903748 
	C304.867126,434.956757 304.469849,436.358826 303.742615,437.599091 
	C302.930176,437.363434 302.447723,437.289551 301.549377,437.157776 
	C299.280518,437.627899 297.427582,438.155884 295.454285,438.365509 
	C289.966370,435.173035 284.529236,432.418060 279.274963,429.349915 
	C277.760406,428.465515 275.680969,426.654724 275.711884,425.311279 
	C276.010864,412.327972 272.403229,399.190125 277.016388,386.353699 
	C277.203674,385.832489 276.781342,385.104370 276.683533,384.466461 
	C276.153107,381.006927 275.635376,377.545471 275.113281,374.084656 
	C277.046143,373.845734 279.010345,373.743591 280.898956,373.311249 
	C281.870667,373.088776 282.707703,372.277954 283.605560,371.732971 
	C282.751099,370.825043 281.896667,369.917114 280.566345,368.686310 
	C279.998474,368.218079 279.906494,368.072754 279.913513,367.536713 
	C280.184998,366.092529 280.357483,365.039032 280.529968,363.985535 
	C281.201263,362.512390 281.872559,361.039215 283.012665,358.537323 
	C284.185883,362.331635 285.066101,365.178314 286.038391,368.322754 
	C288.767517,363.111450 291.257690,358.356476 293.874390,353.278015 
M295.667725,387.763702 
	C298.533691,388.153961 301.410095,388.875427 304.260925,388.785431 
	C305.556061,388.744568 307.034790,387.280151 307.962158,386.095154 
	C308.382202,385.558411 307.790680,383.305115 307.435944,383.249603 
	C306.018524,383.027740 304.460602,383.172821 303.061646,383.564362 
	C302.231049,383.796844 301.600128,384.742645 300.660156,385.557953 
	C299.901825,384.201447 299.318207,383.157410 298.675781,382.008179 
	C296.331055,383.680695 294.248138,385.166473 291.950226,386.805573 
	C293.040955,387.142853 293.973450,387.431183 295.667725,387.763702 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M274.827332,374.152466 
	C275.635376,377.545471 276.153107,381.006927 276.683533,384.466461 
	C276.781342,385.104370 277.203674,385.832489 277.016388,386.353699 
	C272.403229,399.190125 276.010864,412.327972 275.711884,425.311279 
	C275.680969,426.654724 277.760406,428.465515 279.274963,429.349915 
	C284.529236,432.418060 289.966370,435.173035 295.169067,438.502625 
	C293.018463,438.978760 291.032745,438.999390 289.047028,439.020020 
	C288.456268,437.821289 288.179535,436.074860 287.217712,435.523376 
	C283.926788,433.636536 280.464905,431.993011 276.933594,430.597412 
	C274.046448,429.456390 273.036255,427.771973 273.048523,424.607849 
	C273.091400,413.530060 272.887848,402.447296 272.506042,391.375793 
	C272.441437,389.502350 271.035309,387.675171 270.509827,385.415771 
	C271.438873,384.253998 272.107117,383.503021 272.775391,382.752075 
	C272.777832,380.454681 272.780304,378.157288 272.882080,375.437256 
	C273.501373,374.749847 274.021393,374.485077 274.827332,374.152466 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M279.814514,367.927399 
	C279.906494,368.072754 279.998474,368.218079 280.131744,368.618103 
	C278.580505,369.054108 276.987976,369.235474 275.186554,369.144836 
	C275.542480,368.733917 276.107300,368.594971 277.206421,368.281555 
	C278.431976,368.047211 279.123260,367.987305 279.814514,367.927399 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1641.824951,859.001099 
	C1642.094727,859.442078 1642.095337,859.445496 1641.613770,859.531860 
	C1637.749146,859.741211 1634.366089,859.864197 1630.635254,859.967529 
	C1628.959106,860.033142 1627.630859,860.118408 1625.857422,860.179077 
	C1623.839966,860.434570 1621.800293,860.188416 1620.782227,861.092590 
	C1617.630371,863.891663 1614.772461,867.047241 1612.022339,870.254578 
	C1611.360229,871.026672 1611.555786,872.533997 1611.256836,874.103455 
	C1611.088867,874.770020 1611.020874,875.036255 1610.577393,875.169800 
	C1608.746338,875.740295 1607.290894,876.443481 1605.597900,877.560425 
	C1605.219971,878.259827 1605.079834,878.545349 1604.939575,878.830933 
	C1604.215942,878.873413 1603.492310,878.915955 1602.288086,878.699158 
	C1601.558228,877.994873 1601.308960,877.549927 1601.404053,876.944946 
	C1602.875610,874.947754 1607.171875,873.859863 1603.161499,870.807800 
	C1604.182617,869.889221 1605.080322,868.594666 1606.274292,868.203186 
	C1606.917236,867.992432 1608.067627,869.329346 1609.454712,870.290771 
	C1612.401001,867.185791 1615.241089,864.192749 1618.312256,861.083618 
	C1618.360474,860.717896 1618.177612,860.468323 1617.534424,860.136353 
	C1604.376221,860.261475 1591.678101,860.469055 1578.647583,860.632568 
	C1576.877686,860.699524 1575.440186,860.810669 1573.602783,860.817322 
	C1572.123047,861.903076 1571.170776,863.945435 1569.940308,864.129517 
	C1565.623657,864.775452 1561.201904,865.025024 1556.841309,864.861023 
	C1555.740967,864.819641 1554.830444,862.543457 1553.616211,861.561768 
	C1552.988403,861.054260 1551.886353,861.133179 1550.698853,860.977173 
	C1549.265869,861.015869 1548.132690,861.024536 1546.558838,860.930298 
	C1543.411987,860.882324 1540.706055,860.937195 1537.697021,860.928955 
	C1537.062256,861.055847 1536.730347,861.246033 1536.398438,861.436218 
	C1536.888916,861.735901 1537.379272,862.035522 1537.929810,862.537048 
	C1538.153809,862.968811 1538.285278,863.216003 1538.286133,863.848083 
	C1538.115723,864.972229 1538.043457,865.728699 1537.692871,866.780334 
	C1535.457886,867.250488 1533.501221,867.425537 1531.544678,867.600647 
	C1531.521729,867.241211 1531.498901,866.881836 1531.476074,866.522461 
	C1530.309570,867.294800 1529.143188,868.067078 1527.979248,868.560059 
	C1527.010498,867.902771 1526.039307,867.524780 1525.028442,866.873779 
	C1524.966675,866.418213 1524.944458,866.235657 1525.299316,865.886353 
	C1527.279785,865.426025 1528.883057,865.132507 1530.814331,864.922852 
	C1532.617188,864.404968 1534.092163,863.803223 1535.567139,863.201538 
	C1534.734619,862.265930 1533.902100,861.330383 1533.069702,860.394775 
	C1531.949463,861.274292 1530.829224,862.153748 1529.427979,862.869019 
	C1528.113403,862.475830 1527.080078,862.246887 1525.880615,861.638062 
	C1523.820068,861.159729 1521.925659,861.061401 1519.572266,860.951233 
	C1515.762329,860.985352 1512.411377,861.031311 1508.635010,861.013550 
	C1506.781128,860.999512 1505.352539,861.049255 1503.462158,861.073975 
	C1497.699951,861.076538 1492.399658,861.104004 1486.867920,861.117920 
	C1486.519043,861.203552 1486.285400,861.345947 1486.303467,861.394653 
	C1486.403076,861.662659 1486.564087,861.907715 1486.822510,862.526245 
	C1486.627930,867.459534 1484.011475,869.071594 1479.684937,868.705811 
	C1478.295410,868.588318 1476.871826,868.871399 1475.169800,868.937439 
	C1474.087646,868.923950 1473.299683,868.944275 1472.248535,868.623230 
	C1471.597168,866.268799 1471.208862,864.255615 1470.610474,861.153198 
	C1461.726685,861.153198 1452.333862,861.153198 1443.103027,860.809448 
	C1444.125000,858.413391 1444.984863,856.360962 1445.980225,854.062195 
	C1446.823730,853.380249 1447.531982,852.944702 1448.711426,852.219177 
	C1446.711548,857.749695 1447.751221,859.270569 1453.131592,859.248657 
	C1470.065186,859.179688 1486.998291,859.028992 1504.360352,858.950562 
	C1506.222290,858.952026 1507.655273,858.912903 1509.550049,858.910034 
	C1520.785278,858.924744 1531.559814,858.982666 1542.331055,858.817078 
	C1544.573120,858.782593 1546.802368,857.920593 1549.800781,857.275879 
	C1550.937988,858.189941 1553.166504,861.533203 1554.242432,855.773987 
	C1554.381592,855.028748 1556.223877,854.218140 1557.339722,854.115662 
	C1559.969238,853.874084 1562.636597,854.019287 1565.288208,854.050415 
	C1567.689209,854.078613 1569.901245,853.579956 1571.136230,857.029480 
	C1572.021606,859.502930 1574.283813,858.828857 1575.376709,856.119019 
	C1576.000366,856.169434 1576.355835,856.208008 1576.864624,856.645386 
	C1582.761719,857.279297 1588.517944,857.808716 1594.241333,857.565430 
	C1595.904541,857.494690 1597.987549,855.683411 1596.330811,852.715210 
	C1595.843506,851.842163 1597.487793,849.779175 1598.155151,848.261536 
	C1598.648193,848.489990 1599.141235,848.718506 1599.634399,848.947021 
	C1599.401489,850.210510 1599.168579,851.473999 1598.888062,852.995544 
	C1600.555908,852.588623 1601.749146,852.297485 1603.108398,852.385315 
	C1601.187378,856.635010 1601.752075,857.962585 1605.815186,858.039185 
	C1612.277954,858.161011 1618.744873,858.071655 1625.788452,858.071655 
	C1626.345581,848.953430 1626.882935,840.157654 1627.462036,830.678711 
	C1623.225342,831.286499 1620.156006,831.726807 1617.078125,831.752808 
	C1617.042358,830.234070 1617.014893,829.129700 1617.306396,827.921265 
	C1617.672485,827.135254 1617.719482,826.453247 1617.766479,825.771240 
	C1617.503418,825.777893 1617.240356,825.784546 1616.977295,825.791138 
	C1616.977295,826.532043 1616.977295,827.272949 1616.755981,828.012451 
	C1616.534668,828.011108 1616.091919,828.020264 1616.031250,827.664795 
	C1615.664795,825.921265 1615.359131,824.533142 1615.053345,823.145081 
	C1617.478394,823.143982 1619.903320,823.142883 1622.570557,823.488342 
	C1621.521240,824.569458 1620.229614,825.304077 1617.487427,826.863770 
	C1620.899658,828.147156 1623.101807,828.975342 1625.303955,829.803589 
	C1625.836182,827.847290 1626.410889,825.901062 1626.875977,823.929077 
	C1627.023193,823.304749 1626.878296,822.611633 1626.867065,821.950012 
	C1626.892090,818.311584 1627.136230,814.656555 1626.863892,811.040527 
	C1626.686035,808.679626 1625.758179,806.375305 1625.508667,805.393494 
	C1626.060669,800.617615 1627.309814,796.895203 1626.730225,793.483398 
	C1625.052612,783.608459 1630.284180,778.129272 1638.364502,774.052246 
	C1638.893188,774.557556 1638.946411,774.776367 1638.999634,774.995239 
	C1638.905029,775.131348 1638.841553,775.326294 1638.711304,775.395081 
	C1629.184814,780.433533 1627.756470,788.839294 1628.885986,798.473999 
	C1629.328735,802.251831 1631.036621,804.182434 1634.842773,804.177185 
	C1640.145874,804.169861 1645.448975,804.150696 1651.330566,804.406067 
	C1652.211182,804.449158 1652.513428,804.222839 1652.815796,803.996582 
	C1654.192871,800.096924 1656.132080,799.122925 1659.386108,803.347778 
	C1660.217773,804.767761 1660.631104,805.654602 1661.068604,806.560669 
	C1661.092651,806.579895 1661.071167,806.580750 1661.071167,806.580750 
	C1657.559814,807.865051 1654.401367,810.515259 1650.595947,806.077698 
	C1649.336548,804.609009 1644.892456,805.787720 1641.909912,805.900330 
	C1637.790649,806.055786 1633.676514,806.347839 1629.164795,806.603882 
	C1629.164795,811.742188 1628.975830,816.532471 1629.312744,821.285461 
	C1629.386597,822.324829 1631.191895,823.669128 1632.458618,824.085388 
	C1633.956543,824.577698 1635.735229,824.215881 1637.733887,824.538574 
	C1638.082642,825.353638 1638.089722,825.854370 1638.096680,826.355042 
	C1629.440674,827.065918 1629.305786,827.214355 1629.390015,836.120483 
	C1629.421387,839.447327 1629.467773,842.774048 1629.537964,846.892517 
	C1629.834961,848.498840 1630.101318,849.313477 1630.367798,850.128113 
	C1630.479248,852.555359 1630.590698,854.982605 1630.703979,857.449890 
	C1634.519409,857.840271 1638.037354,858.200256 1641.824951,859.001099 
M1563.305420,857.874878 
	C1563.301514,857.283813 1563.314697,856.175964 1563.291138,856.175110 
	C1560.916504,856.096619 1558.539185,856.101624 1555.729736,856.101624 
	C1555.897705,858.530334 1555.999390,859.998474 1556.141479,862.051331 
	C1558.749023,860.532898 1560.718506,859.386108 1563.305420,857.874878 
M1560.087280,862.140503 
	C1562.826782,862.501831 1565.566162,862.863159 1568.607544,863.264343 
	C1568.607544,860.975464 1568.607544,859.404480 1568.607544,857.225037 
	C1565.720947,858.843140 1563.156738,860.280457 1560.087280,862.140503 
M1475.517700,863.186401 
	C1474.520996,863.983215 1473.524292,864.780029 1472.527588,865.576843 
	C1472.813599,866.076782 1473.099487,866.576721 1473.385498,867.076599 
	C1475.209473,866.303345 1477.123413,865.677246 1478.795288,864.655151 
	C1479.223389,864.393494 1478.886597,862.880371 1478.901123,861.942139 
	C1478.000977,862.249207 1477.100830,862.556274 1475.517700,863.186401 
M1627.681030,856.493469 
	C1627.958008,856.839172 1628.234863,857.184937 1628.511841,857.530640 
	C1628.725952,857.127197 1628.940063,856.723755 1629.154053,856.320312 
	C1628.741699,856.152893 1628.329468,855.985474 1627.681030,856.493469 
M1606.526245,871.698181 
	C1606.041626,871.566101 1605.556885,871.434021 1605.072266,871.301941 
	C1605.382080,871.631775 1605.691895,871.961670 1606.526245,871.698181 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1177.439941,830.332703 
	C1176.670410,829.162048 1176.323242,827.925598 1175.927490,826.307800 
	C1176.608154,822.736206 1174.446289,822.813171 1172.357178,822.812683 
	C1167.090576,822.811401 1161.823975,822.812256 1156.668213,822.812256 
	C1154.846680,813.555847 1159.765869,804.762390 1169.287964,802.349915 
	C1175.547974,800.763977 1176.690918,797.676147 1175.901733,792.292480 
	C1175.639160,790.501404 1175.794312,788.649048 1176.119629,786.581055 
	C1176.781250,790.193176 1177.081665,794.048950 1177.268188,796.441467 
	C1178.776489,795.709717 1180.490601,794.248535 1182.298340,794.121643 
	C1187.272461,793.772705 1192.285889,793.975342 1197.283691,793.973694 
	C1210.851074,793.969360 1224.418335,793.972229 1237.985718,793.972229 
	C1237.987183,793.282288 1237.988647,792.592285 1237.989990,791.902344 
	C1218.127808,791.902344 1198.265503,791.902344 1177.919556,791.902344 
	C1178.132446,789.501892 1178.289062,787.734375 1178.753906,786.083191 
	C1179.379272,786.489563 1179.696411,786.779541 1179.989502,787.456543 
	C1180.179810,792.917603 1183.815796,790.221130 1185.895508,790.221802 
	C1202.870728,790.227295 1219.845825,790.034607 1237.239014,789.912109 
	C1238.442871,788.818359 1239.380127,787.789856 1239.975708,786.591492 
	C1240.672852,785.189087 1241.071777,783.638611 1241.678711,782.558594 
	C1241.757324,785.210815 1241.757324,787.456848 1241.757324,789.958740 
	C1254.674805,789.958740 1267.052002,789.971069 1279.428955,789.917358 
	C1279.968262,789.914978 1280.504883,789.306335 1281.310547,789.149658 
	C1284.176025,792.493103 1285.527954,789.490234 1287.398193,787.995117 
	C1287.601685,787.385437 1287.628296,786.961243 1287.714966,785.581787 
	C1287.155518,786.924133 1286.971558,787.365479 1286.493408,787.655334 
	C1285.539917,786.725342 1284.880737,785.946838 1284.221558,785.168274 
	C1285.172485,784.703979 1286.123413,784.239685 1287.236084,784.140381 
	C1289.109985,784.532837 1290.894653,784.278320 1292.507080,784.693176 
	C1293.481567,784.943909 1294.188721,786.233521 1295.008057,787.027954 
	C1295.000000,786.999573 1295.058838,787.003540 1294.801758,787.084839 
	C1294.369751,787.465942 1294.195068,787.765747 1294.015259,788.033325 
	C1294.010254,788.001038 1294.067017,788.033325 1293.656372,788.021912 
	C1291.915283,788.450012 1290.584839,788.889526 1289.254517,789.329102 
	C1290.195435,789.930908 1291.136230,790.532715 1292.153931,791.527832 
	C1298.277222,794.174377 1304.281616,792.760193 1310.310669,791.371033 
	C1304.775757,791.121155 1299.240723,790.871277 1293.742554,790.475647 
	C1293.779297,790.329895 1293.926025,790.067627 1294.382568,790.029785 
	C1297.422363,789.992004 1300.005615,789.992004 1302.488525,789.992004 
	C1302.815918,787.173706 1303.045044,785.201294 1303.274170,783.228882 
	C1303.664673,783.225098 1304.055176,783.221313 1304.445679,783.217529 
	C1304.814575,785.360962 1305.183350,787.504333 1305.723511,790.644348 
	C1307.431274,789.481506 1309.563843,788.742432 1309.616943,787.876038 
	C1309.934937,782.682678 1309.774658,777.460022 1309.774658,772.247253 
	C1309.296265,771.867310 1308.817871,771.487427 1308.339478,771.107544 
	C1304.030518,773.437744 1299.721680,775.768005 1295.535645,777.740417 
	C1293.885742,776.011414 1292.112793,774.640320 1290.166504,772.997864 
	C1289.997314,771.817688 1290.001465,770.908813 1290.394409,769.992554 
	C1295.224243,773.446472 1299.985107,770.531067 1304.582153,770.551331 
	C1305.866943,770.557007 1307.759644,769.561768 1308.341797,768.473633 
	C1310.946045,763.606995 1313.787964,758.601929 1310.163086,752.707764 
	C1311.536255,751.937683 1312.746582,751.451843 1313.971924,751.419434 
	C1314.325073,756.210938 1314.663208,760.549072 1314.920166,765.137695 
	C1314.569336,765.600647 1314.299561,765.813110 1313.802490,765.777771 
	C1312.720337,766.575500 1311.112183,767.636047 1311.132568,768.664124 
	C1311.266846,775.407166 1311.752808,782.143188 1312.220581,789.133545 
	C1312.833862,789.215271 1313.350586,789.044983 1313.878784,788.521973 
	C1313.646362,786.807800 1313.402588,785.446411 1313.453613,784.065063 
	C1314.141235,784.046570 1314.534058,784.047974 1314.946045,784.410034 
	C1315.769531,785.942261 1316.879517,787.009094 1317.323608,788.304199 
	C1318.980591,793.136902 1326.503296,794.723633 1330.479492,790.953918 
	C1332.248047,791.402771 1333.639038,791.791260 1335.030151,792.179688 
	C1333.099365,792.837646 1331.173462,794.051514 1329.237183,794.068298 
	C1307.951050,794.252747 1286.661621,794.111267 1265.376953,794.373962 
	C1257.783936,794.467712 1250.208984,795.624817 1242.611572,795.894653 
	C1238.522949,796.039978 1234.403687,795.056396 1230.298950,795.062134 
	C1213.761108,795.085266 1197.223633,795.295410 1180.331543,795.968262 
	C1179.976929,796.500061 1180.033081,796.993958 1180.033081,796.993958 
	C1180.622925,801.042114 1178.711548,802.537781 1174.793213,802.833618 
	C1165.778198,803.514282 1160.434082,808.820679 1157.566895,817.803162 
	C1158.231445,818.938110 1159.115723,819.457214 1160.000000,819.976379 
	C1165.162964,819.998169 1170.337280,819.835510 1175.481812,820.154785 
	C1177.015137,820.249939 1179.732300,821.716492 1179.719238,822.518799 
	C1179.676514,825.118469 1182.020752,828.619873 1177.439941,830.332703 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1785.986328,876.936157 
	C1768.311768,877.211060 1750.637329,877.485962 1732.509521,877.414551 
	C1732.035522,876.732727 1732.014648,876.397217 1732.457764,875.983948 
	C1744.885986,876.395996 1757.017212,877.360229 1764.385254,864.811279 
	C1764.634521,864.386780 1764.922852,863.985107 1765.507812,863.324585 
	C1766.756836,861.913269 1767.691040,860.750244 1769.376465,858.651917 
	C1766.404053,858.651917 1764.710815,858.651917 1762.551758,858.578491 
	C1746.940063,858.677368 1731.791626,858.748840 1716.650513,859.121094 
	C1714.126587,859.183105 1711.634888,860.552795 1709.219971,861.290283 
	C1707.885742,860.767151 1706.122437,859.501099 1704.356689,859.497620 
	C1700.079346,859.489197 1695.798218,860.382507 1691.523071,860.349548 
	C1681.074829,860.268921 1670.628906,859.869629 1660.158447,859.343262 
	C1660.101807,858.750244 1660.068726,858.412598 1660.035645,858.074951 
	C1665.497437,857.783813 1671.031006,856.806763 1676.403320,857.369629 
	C1683.630737,858.126831 1688.806152,855.282959 1693.490479,850.429504 
	C1695.098633,848.763306 1696.921753,847.304565 1698.863159,845.555908 
	C1698.530029,843.461914 1694.925537,840.392334 1700.294312,839.411377 
	C1700.516968,840.411438 1700.743042,841.427307 1701.102051,843.040039 
	C1702.936035,841.722900 1704.380615,840.685425 1706.324463,839.713623 
	C1706.978516,839.950195 1707.133301,840.121094 1707.212036,840.497070 
	C1707.136108,840.702026 1707.088989,841.136719 1707.088867,841.136719 
	C1705.512817,843.300659 1703.936768,845.464661 1701.990723,848.136597 
	C1696.418701,846.857239 1695.019653,854.102661 1690.163208,855.754028 
	C1690.286865,856.311462 1690.410522,856.868835 1690.534180,857.426270 
	C1696.436279,857.008057 1702.338501,856.589844 1708.931152,856.076050 
	C1710.416382,856.258484 1711.211060,856.536377 1712.005737,856.814270 
	C1731.767578,856.564514 1751.530396,856.368713 1771.288574,855.945984 
	C1772.629761,855.917297 1773.934570,854.183350 1775.635376,853.394897 
	C1776.036499,853.782715 1776.058472,854.018311 1776.065186,854.663452 
	C1780.093506,855.576538 1784.137085,856.080139 1788.180664,856.583740 
	C1786.425659,857.724304 1784.572998,858.088135 1782.775757,857.905640 
	C1775.793091,857.196594 1770.044678,858.480896 1766.151001,865.278748 
	C1764.182495,868.715515 1760.783325,871.332764 1758.031006,874.320557 
	C1758.223999,874.698059 1758.416992,875.075500 1758.609863,875.453003 
	C1765.829346,873.766907 1774.004150,877.980408 1780.588623,872.374084 
	C1780.527588,871.878906 1780.466553,871.383728 1780.405396,870.888550 
	C1779.265747,870.809570 1778.125977,870.730652 1776.986206,870.651672 
	C1776.968018,870.116577 1776.949829,869.581482 1776.931641,869.046326 
	C1778.889648,868.926880 1780.847656,868.807434 1783.128174,868.668335 
	C1783.170532,868.211121 1783.295410,866.863464 1783.417236,865.546875 
	C1785.243652,865.441223 1786.691772,865.357422 1788.199463,865.409424 
	C1788.259033,865.545166 1788.248657,865.841492 1787.950439,866.039124 
	C1785.204834,869.508667 1785.149658,873.152954 1785.986328,876.936157 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1614.765747,822.832153 
	C1615.359131,824.533142 1615.664795,825.921265 1615.760742,827.941895 
	C1614.035156,828.515503 1612.471436,828.677124 1611.011230,828.361084 
	C1603.030762,826.633728 1602.817017,826.749207 1602.714844,833.986023 
	C1602.210205,833.948853 1602.010010,833.915649 1601.835205,833.531372 
	C1598.035034,832.127869 1594.209473,831.075378 1590.383789,830.022888 
	C1590.127197,830.438416 1589.870483,830.854004 1589.613892,831.269531 
	C1590.429321,832.297791 1591.244751,833.325989 1591.932373,834.700073 
	C1588.822388,836.987671 1586.218140,836.286438 1583.932617,833.499939 
	C1584.159546,826.830444 1583.599365,826.338684 1577.755127,828.027100 
	C1568.761841,828.353638 1560.251831,828.674377 1551.469971,828.785034 
	C1547.596069,824.670715 1543.421875,826.025757 1538.780029,827.198120 
	C1514.116699,827.119873 1489.932251,827.119873 1465.747925,827.119873 
	C1465.726562,827.373413 1465.705200,827.626892 1465.683716,827.880432 
	C1466.782471,828.226440 1467.881226,828.572449 1468.739502,829.196106 
	C1467.023682,829.655701 1465.548218,829.837585 1463.773926,829.812988 
	C1461.022095,827.111877 1458.795898,827.304504 1456.501709,830.113037 
	C1455.808594,830.040833 1455.409058,830.026123 1455.101440,829.729614 
	C1454.996216,828.783386 1454.944336,827.651489 1454.576416,827.537415 
	C1453.166138,827.099670 1451.654297,826.988525 1450.079102,826.739563 
	C1452.372681,825.696594 1454.161621,824.883118 1455.950439,824.069641 
	C1456.138550,824.059753 1456.326538,824.049866 1457.063721,824.327148 
	C1460.744507,824.406494 1463.876221,824.198669 1467.008057,823.990845 
	C1467.437988,823.975647 1467.867798,823.960388 1468.827393,824.231873 
	C1470.584229,824.336487 1471.811401,824.154358 1473.038452,823.972290 
	C1473.038452,823.972229 1473.524780,823.971252 1474.057373,824.287598 
	C1475.733765,824.739563 1476.877563,824.993286 1478.021362,824.993591 
	C1500.458862,824.999390 1522.896484,824.975830 1545.333984,824.958252 
	C1545.333984,824.958252 1545.518555,824.916016 1546.110718,824.955994 
	C1557.317993,824.775513 1567.932983,824.555115 1578.877930,824.449219 
	C1584.586670,824.352905 1584.760010,824.221680 1584.139526,820.630371 
	C1584.533081,819.215210 1584.776978,818.111877 1585.020996,817.008545 
	C1585.216064,817.013794 1585.411133,817.019043 1585.865967,817.447571 
	C1586.125610,819.512512 1586.125610,821.154175 1586.125610,821.585571 
	C1588.239746,822.216309 1589.662842,822.765137 1591.136841,823.025452 
	C1591.818848,823.145874 1592.600342,822.703369 1593.336670,822.515991 
	C1600.383789,822.517029 1607.431030,822.518127 1614.765747,822.832153 
M1598.461670,827.963928 
	C1597.920776,828.440552 1597.380005,828.917175 1596.192017,829.964172 
	C1597.662476,829.858032 1598.449707,830.027588 1598.812988,829.710999 
	C1599.171631,829.398193 1599.101685,828.593994 1598.461670,827.963928 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1571.986450,747.917114 
	C1571.574585,747.987549 1571.162720,748.058044 1569.989258,748.120239 
	C1567.428223,748.201660 1565.628784,748.291260 1563.507690,748.375427 
	C1561.652710,750.331543 1559.988647,752.206970 1558.625122,754.280273 
	C1557.398193,756.145752 1557.223999,756.338562 1555.350708,755.151001 
	C1555.373535,754.705872 1555.182617,754.412964 1554.579834,754.052979 
	C1553.071289,753.860413 1551.974609,753.734924 1550.925537,753.229248 
	C1548.936646,752.197937 1546.900146,751.546875 1545.000732,750.483765 
	C1544.922363,749.531494 1544.714233,748.523010 1544.491089,748.519653 
	C1538.708984,748.433167 1532.925415,748.451233 1527.142090,748.451233 
	C1526.977783,749.116699 1526.813354,749.782227 1526.649048,750.447693 
	C1528.080200,751.091797 1529.511230,751.735840 1530.945557,752.629517 
	C1530.967163,753.211609 1530.977173,753.544373 1530.628418,753.939331 
	C1529.570312,754.056091 1528.862671,754.110901 1527.813965,754.215820 
	C1526.652954,755.181641 1525.832764,756.097412 1524.971069,757.409180 
	C1521.769653,761.905029 1516.370728,760.753052 1513.888550,759.342285 
	C1511.438721,757.949890 1511.267822,752.547913 1509.903442,748.301697 
	C1507.336304,748.588074 1504.254028,748.931824 1500.749023,749.173462 
	C1496.500244,748.708740 1492.672974,748.122559 1488.848877,748.142212 
	C1487.903809,748.147034 1486.968872,750.120178 1485.730225,751.225342 
	C1484.917969,751.836121 1484.404785,752.409912 1483.593262,752.981812 
	C1482.882812,753.339905 1482.470581,753.699829 1481.965088,754.235352 
	C1481.871948,754.410889 1481.713867,754.775513 1481.467041,754.941772 
	C1480.454224,755.692383 1479.688110,756.276733 1478.685425,757.101074 
	C1475.392212,761.781311 1470.849609,764.101135 1465.742920,766.005371 
	C1465.250366,765.987732 1465.053101,766.001770 1464.772949,765.639587 
	C1457.132446,765.513977 1449.574463,765.764526 1441.706543,766.019775 
	C1440.986084,765.976074 1440.575684,765.927734 1439.983887,765.619873 
	C1439.539185,765.038269 1439.276001,764.716248 1438.974854,764.148315 
	C1439.003784,763.576538 1439.042847,763.247375 1439.335205,762.889038 
	C1439.992432,762.853516 1440.368652,762.843872 1441.255005,762.705444 
	C1442.077881,762.417725 1442.390625,762.258789 1443.030273,762.384155 
	C1450.895020,762.573792 1458.432861,762.479126 1466.369385,762.406311 
	C1468.645996,761.836487 1470.835693,761.665955 1472.331421,760.558167 
	C1474.825439,758.711060 1476.875000,756.263977 1479.402100,753.913696 
	C1481.404541,751.595276 1483.309326,749.410583 1479.027344,747.723450 
	C1479.028198,747.513611 1479.046021,747.094360 1479.000244,747.077759 
	C1478.954468,747.061157 1478.965820,746.964478 1479.400879,746.944397 
	C1481.569580,746.936707 1483.303345,746.949097 1485.500732,746.989380 
	C1490.923828,746.983582 1495.883301,746.949951 1501.198486,746.947937 
	C1502.030151,746.989258 1502.506226,746.999023 1503.255493,747.020142 
	C1503.706177,746.988831 1503.883423,746.946167 1504.513672,746.930420 
	C1512.267822,746.589478 1519.569092,746.221619 1527.205811,745.940247 
	C1528.413696,746.282959 1529.286133,746.539124 1530.609375,746.831299 
	C1535.343140,746.859680 1539.625854,746.852051 1544.303467,746.865112 
	C1545.205322,746.744080 1545.712524,746.602295 1546.657104,746.589233 
	C1553.786743,746.536438 1560.478882,746.354858 1567.489136,746.120728 
	C1568.234863,746.020813 1568.662598,745.973450 1569.304688,746.042053 
	C1570.338257,746.260803 1571.157227,746.363525 1571.966675,746.708008 
	C1571.971924,747.272095 1571.981812,747.594543 1571.986450,747.917114 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1702.488770,750.867920 
	C1700.948975,751.161743 1699.409058,751.455627 1697.495361,752.161804 
	C1696.571655,751.686462 1695.496094,750.754456 1695.566528,749.919067 
	C1695.816528,746.955139 1694.000366,746.918396 1691.977661,746.639221 
	C1691.978638,745.933472 1691.976440,745.530212 1692.375244,745.127380 
	C1693.677490,744.850952 1694.740356,744.785828 1695.442749,744.248962 
	C1696.583130,743.377502 1697.487305,742.196899 1698.491089,741.146667 
	C1699.391724,742.339050 1700.292358,743.531433 1701.708252,745.406128 
	C1707.654907,743.310791 1714.257935,745.642334 1721.235840,744.769897 
	C1722.140259,744.809265 1722.656860,744.819946 1723.587158,744.830872 
	C1740.940186,744.506348 1757.879395,744.181702 1775.188965,743.848511 
	C1775.801025,743.790405 1776.042725,743.740662 1776.728882,743.763489 
	C1781.389893,743.863281 1785.620361,744.088196 1789.814209,743.791870 
	C1791.005493,743.707825 1792.089478,742.105713 1793.558838,741.228271 
	C1795.304443,742.089233 1796.713867,742.921631 1798.414307,743.792969 
	C1799.094360,743.802979 1799.483398,743.774048 1800.253662,743.785095 
	C1802.054565,742.898621 1803.474365,741.972168 1804.941895,741.453369 
	C1805.926758,742.501831 1806.851196,743.670166 1807.803345,743.692993 
	C1812.113403,743.796448 1816.431152,743.576355 1821.183105,743.590332 
	C1822.503906,743.636047 1823.387939,743.564270 1824.672607,743.531372 
	C1830.359863,743.515991 1835.646484,743.461853 1840.943848,743.670654 
	C1840.968872,744.284363 1840.983032,744.635193 1840.628052,745.192749 
	C1838.178467,745.602295 1836.101196,745.853149 1834.016846,745.998230 
	C1829.006226,746.346924 1823.992188,746.646179 1818.937866,746.884888 
	C1818.896118,746.804504 1818.749146,746.695740 1818.430908,746.332764 
	C1815.056396,745.825562 1811.991455,745.777893 1808.945801,745.511047 
	C1805.672485,745.224182 1804.507812,746.727783 1804.636719,749.572815 
	C1803.927124,746.956787 1803.959473,743.957947 1799.673340,745.698730 
	C1798.834229,745.724426 1798.356079,745.799072 1797.467773,745.853149 
	C1795.693726,745.916199 1794.329834,745.999817 1792.503906,746.033569 
	C1787.333984,746.081238 1782.625854,746.178772 1777.458862,746.216675 
	C1764.383545,746.487732 1751.767090,746.818420 1738.778564,747.084717 
	C1737.904175,747.056396 1737.402222,747.092407 1736.466064,747.090393 
	C1726.706299,747.058655 1717.380615,747.065002 1707.681641,747.060059 
	C1706.812622,747.094421 1706.316772,747.139893 1705.435303,747.148438 
	C1703.502197,747.180908 1701.955078,747.250244 1700.074585,747.334595 
	C1701.124512,748.871277 1701.806641,749.869629 1702.488770,750.867920 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1504.968262,812.683960 
	C1502.060547,813.829712 1499.226562,815.282715 1496.217163,816.003601 
	C1494.473511,816.421265 1492.447754,815.660889 1491.591187,815.557007 
	C1486.046021,815.797546 1481.385010,815.999817 1476.443604,815.681396 
	C1477.352539,814.610718 1478.541748,814.060608 1478.768066,813.955994 
	C1477.771362,811.997803 1476.807373,810.104004 1475.843384,808.210144 
	C1474.887207,806.931702 1473.930908,805.653259 1472.748291,803.756470 
	C1472.510010,802.478699 1472.497925,801.819214 1472.485962,801.159729 
	C1474.430908,800.173340 1476.375854,799.186951 1478.256836,798.698853 
	C1476.963257,800.154663 1475.733521,801.112183 1474.503906,802.069641 
	C1474.824829,802.452087 1475.145874,802.834534 1475.466797,803.216980 
	C1478.208618,802.733154 1480.857788,802.141235 1480.512451,798.055115 
	C1483.827759,797.304382 1487.056763,796.714722 1489.387939,800.247253 
	C1489.660278,800.659790 1490.936401,800.409851 1492.286743,800.565063 
	C1493.104248,801.223633 1493.382324,801.784363 1493.660278,802.345032 
	C1487.603516,803.783386 1481.546753,805.221680 1475.489990,806.660034 
	C1475.585205,807.190613 1475.680420,807.721191 1475.775757,808.251831 
	C1479.740967,806.850037 1482.867920,810.614929 1487.330444,809.162292 
	C1489.618042,808.417664 1493.056396,810.887634 1496.262695,808.685303 
	C1495.525635,810.434937 1494.384644,812.021545 1492.828979,814.184875 
	C1497.509033,814.003418 1499.244019,811.835938 1499.594604,808.196228 
	C1503.128174,808.765808 1506.661743,809.335327 1511.270752,810.078125 
	C1511.263672,810.084595 1512.292358,809.148193 1513.936768,807.651123 
	C1513.936768,810.553528 1513.936768,812.705566 1513.936768,814.909180 
	C1512.432617,814.512573 1511.054199,814.149109 1509.020752,813.818542 
	C1508.202148,814.243042 1508.038452,814.634705 1507.874756,815.026428 
	C1507.251587,814.850708 1506.628418,814.674988 1505.942993,813.922607 
	C1505.576660,813.125305 1505.272461,812.904602 1504.968262,812.683960 
M1482.510376,811.670349 
	C1482.594727,811.592834 1482.733765,811.524292 1482.748291,811.435364 
	C1482.762695,811.346680 1482.652344,811.237671 1482.596802,811.137573 
	C1482.512207,811.214966 1482.427734,811.292358 1482.510376,811.670349 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1691.974487,746.941650 
	C1694.000366,746.918396 1695.816528,746.955139 1695.566528,749.919067 
	C1695.496094,750.754456 1696.571655,751.686462 1697.472412,752.511475 
	C1698.094849,753.744385 1698.468628,755.028259 1698.618042,756.337769 
	C1698.995361,759.647156 1697.904541,761.957520 1694.163818,761.982300 
	C1686.698853,762.031799 1679.233032,761.971497 1671.767822,762.007446 
	C1668.968872,762.020935 1667.926147,760.597229 1667.983398,757.952271 
	C1668.052002,754.789429 1668.003418,751.623962 1668.385254,747.905762 
	C1671.209351,747.569641 1674.340942,745.352112 1675.314331,750.147461 
	C1675.788818,752.485229 1689.382080,751.681274 1690.823364,749.403259 
	C1690.867188,749.333984 1690.681396,749.119324 1690.861816,748.799927 
	C1691.392090,748.072021 1691.663086,747.516724 1691.952515,746.974121 
	C1691.970947,746.986694 1691.974487,746.941650 1691.974487,746.941650 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1335.041870,750.110962 
	C1335.035645,757.616394 1335.029297,765.121887 1334.670654,773.166260 
	C1332.529175,774.515198 1330.739746,775.325134 1328.870850,775.760986 
	C1325.160767,771.044678 1323.334229,776.577576 1320.347168,777.141052 
	C1319.805664,775.744690 1319.322388,774.498779 1318.880371,772.866272 
	C1318.962158,771.703308 1319.002930,770.926880 1319.313354,769.970276 
	C1320.293579,769.130188 1321.004150,768.470337 1322.070557,767.480103 
	C1319.793335,767.480103 1318.210815,767.480103 1316.093872,767.480103 
	C1317.971558,765.890869 1319.317871,764.751221 1320.635254,763.636230 
	C1319.444824,761.419189 1318.358276,759.395691 1317.176758,756.915649 
	C1317.135864,755.532837 1317.189941,754.606445 1317.534668,753.653076 
	C1318.125610,751.020020 1319.036987,749.423462 1322.216431,749.714844 
	C1326.467896,750.104431 1330.764648,750.002075 1335.041870,750.110962 
M1328.606323,762.558716 
	C1326.794312,762.670593 1324.982178,762.782532 1323.265503,762.888550 
	C1323.265503,765.594849 1323.265503,767.518311 1323.265503,770.700989 
	C1325.751343,769.453857 1327.968384,768.885376 1329.277954,767.474487 
	C1330.027954,766.666382 1329.303345,764.489258 1328.606323,762.558716 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1736.900146,747.128418 
	C1737.402222,747.092407 1737.904175,747.056396 1738.732910,747.467712 
	C1739.037476,748.945923 1739.015503,749.976746 1738.650879,751.152832 
	C1736.725342,752.396667 1733.354004,751.420837 1733.680542,755.093140 
	C1733.550415,755.701416 1733.694214,756.268250 1733.873535,757.169189 
	C1733.865967,757.723022 1733.822754,757.942749 1733.409668,758.135986 
	C1731.398682,758.617981 1729.757446,759.126526 1727.930176,759.692688 
	C1724.558716,756.916382 1725.026978,754.012878 1728.670044,750.988586 
	C1723.290161,750.988586 1718.648438,750.883667 1714.018311,751.068726 
	C1712.975708,751.110413 1711.087769,752.172424 1711.093750,752.749329 
	C1711.138184,757.118225 1711.557495,761.483276 1711.883057,766.201538 
	C1712.454834,767.696533 1713.003540,768.839539 1713.792358,770.033203 
	C1714.032471,770.083923 1714.499023,770.236389 1714.499023,770.236389 
	C1714.377319,775.217346 1714.415771,780.209656 1714.065796,785.174500 
	C1713.892578,787.631531 1714.164185,790.736084 1710.207642,790.976196 
	C1706.753052,791.185730 1705.999756,789.371582 1706.010254,786.373108 
	C1706.050537,774.885864 1705.959595,763.398071 1705.936890,751.065186 
	C1705.911133,749.208435 1705.866089,748.196899 1705.821045,747.185425 
	C1706.316772,747.139893 1706.812622,747.094421 1707.622314,747.430603 
	C1707.970093,760.605225 1707.958374,773.398682 1708.121216,786.190002 
	C1708.134766,787.244629 1709.332275,788.284241 1709.979736,789.330811 
	C1709.974609,786.315430 1709.213379,783.861023 1709.160645,781.391418 
	C1708.979370,772.904419 1709.026245,764.412170 1709.024414,755.921875 
	C1709.022949,749.164917 1709.129761,749.073303 1716.133057,749.053833 
	C1721.793335,749.038025 1727.457764,749.173584 1733.110718,748.973328 
	C1734.394043,748.927856 1735.638062,747.772766 1736.900146,747.128418 
M1728.710693,755.145935 
	C1728.850464,755.731140 1728.990234,756.316345 1729.130005,756.901611 
	C1730.113281,756.283752 1731.096558,755.665833 1732.079834,755.047974 
	C1731.858765,754.713989 1731.637695,754.380005 1731.416626,754.046021 
	C1730.714233,754.281006 1730.011963,754.515991 1728.710693,755.145935 
M1732.541138,751.327454 
	C1732.541138,751.327454 1732.384888,751.648804 1732.384888,751.648804 
	C1732.384888,751.648804 1732.740723,751.579590 1732.541138,751.327454 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1224.060425,879.818176 
	C1223.581787,879.854736 1223.103027,879.891296 1222.296143,879.539551 
	C1221.975098,878.434143 1221.982300,877.717041 1221.989502,877.000000 
	C1221.986206,860.655334 1221.982910,844.310608 1222.300049,827.630005 
	C1223.323364,828.267334 1224.026123,829.240479 1224.080200,829.315369 
	C1226.861084,829.315369 1228.875610,829.315369 1230.890137,829.315369 
	C1230.873169,829.423401 1230.856201,829.531433 1230.834839,829.667725 
	C1229.590698,829.930969 1228.333496,830.196899 1227.076416,830.462891 
	C1227.108154,830.641907 1227.139771,830.820984 1227.171387,831.000000 
	C1233.752563,831.000000 1240.333740,831.000000 1246.988770,831.440918 
	C1246.399780,835.894226 1245.736816,839.906677 1244.742065,843.954590 
	C1243.596680,844.316833 1242.783081,844.643555 1241.917725,844.611572 
	C1240.245728,844.173584 1238.625488,844.094238 1236.645996,843.966797 
	C1235.904175,842.826355 1235.521729,841.733948 1234.981323,840.190979 
	C1243.814087,841.858093 1244.735474,841.270691 1245.335205,833.948547 
	C1240.040527,833.948547 1234.768433,833.790405 1229.523438,834.097595 
	C1228.603027,834.151428 1227.156982,836.101746 1227.056763,837.270813 
	C1226.746826,840.886536 1227.170532,844.561401 1226.925537,848.187378 
	C1226.705811,851.436401 1228.173218,852.313416 1230.814331,852.297058 
	C1228.067017,853.243286 1226.840088,854.529968 1226.947144,857.484558 
	C1227.216187,864.922974 1227.084839,872.375793 1227.114136,879.822876 
	C1226.911133,879.838989 1226.708008,879.855103 1226.070679,879.513916 
	C1225.636475,870.818298 1225.636475,862.480103 1225.636475,854.141907 
	C1225.111084,854.150024 1224.585815,854.158142 1224.060425,854.166260 
	C1224.060425,862.716919 1224.060425,871.267517 1224.060425,879.818176 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1394.142700,793.106445 
	C1392.977783,793.732971 1391.800781,794.918457 1390.649902,794.893555 
	C1384.052490,794.751038 1377.461914,794.099243 1370.867676,794.080383 
	C1367.450684,794.070618 1365.987061,793.072510 1366.016724,789.578491 
	C1366.126709,776.609070 1366.105957,763.638489 1366.492188,750.176758 
	C1367.204590,749.757385 1367.559082,749.829590 1367.950439,750.356262 
	C1367.987305,753.543091 1367.987305,756.275391 1367.987305,759.007751 
	C1368.319458,759.007324 1368.651611,759.006897 1368.983765,759.006531 
	C1368.983765,755.930969 1368.983765,752.855347 1369.217896,749.602173 
	C1370.028198,749.424194 1370.604248,749.423950 1371.117920,749.812134 
	C1371.297974,752.386047 1371.540649,754.571594 1371.832153,757.098511 
	C1371.867554,757.669373 1371.854126,757.898865 1371.557861,758.419922 
	C1371.175049,767.682007 1370.983521,776.653687 1371.091553,785.621765 
	C1371.105103,786.743408 1372.657227,788.760498 1373.572388,788.805054 
	C1378.539551,789.046875 1383.699219,789.534119 1388.450073,788.444153 
	C1391.290283,787.792542 1392.574463,788.495239 1394.500244,790.005249 
	C1394.559082,791.029541 1394.350830,792.067993 1394.142700,793.106445 
M1368.082397,781.386475 
	C1368.268677,781.530945 1368.445557,781.693420 1368.651367,781.800049 
	C1368.681396,781.815613 1368.940063,781.565186 1368.940552,781.437256 
	C1368.956299,777.050049 1368.953613,772.662842 1368.953613,768.275574 
	C1368.640869,768.283142 1368.328003,768.290710 1368.015259,768.298279 
	C1368.015259,772.402527 1368.015259,776.506775 1368.082397,781.386475 
M1368.122314,763.767944 
	C1368.223389,764.198303 1368.324463,764.628601 1368.425537,765.058960 
	C1368.631592,764.989258 1368.837769,764.919556 1369.043945,764.849854 
	C1368.757080,764.316589 1368.470337,763.783386 1368.122314,763.767944 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1482.058350,754.059692 
	C1482.470581,753.699829 1482.882812,753.339905 1483.886719,753.277710 
	C1484.478271,764.958130 1484.478271,776.340881 1484.478271,788.418579 
	C1494.903198,788.418579 1504.012329,788.418579 1512.943115,788.701965 
	C1512.539429,790.268494 1512.314209,791.551636 1512.088867,792.834839 
	C1501.378418,792.877319 1490.667847,792.919861 1479.518555,792.643188 
	C1479.388672,790.884277 1479.697510,789.444580 1480.052856,787.537598 
	C1480.058594,779.020081 1480.084717,770.968567 1479.930176,762.920593 
	C1479.891357,760.895020 1479.273560,758.880554 1478.921875,756.861084 
	C1479.688110,756.276733 1480.454224,755.692383 1481.552246,755.423340 
	C1481.884033,766.491394 1481.884033,777.244080 1481.884033,787.996826 
	C1482.947021,778.578857 1482.995117,769.160278 1482.906982,759.742920 
	C1482.889282,757.846008 1482.354614,755.953979 1482.058350,754.059692 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1480.006348,788.004944 
	C1479.697510,789.444580 1479.388672,790.884277 1479.051880,792.622620 
	C1478.468872,793.244080 1477.912964,793.849976 1477.358887,793.848389 
	C1462.110352,793.804138 1446.861938,793.698059 1431.023560,793.014526 
	C1425.500732,792.160583 1420.567749,791.894470 1415.634888,791.628296 
	C1415.623047,791.223877 1415.611084,790.819458 1415.599243,790.415039 
	C1417.120239,790.308777 1418.640625,790.118774 1420.162231,790.109985 
	C1429.079346,790.058655 1437.997437,790.103699 1446.913452,789.979126 
	C1448.616943,789.955322 1450.312012,789.318970 1452.313599,788.683411 
	C1461.746216,788.269348 1470.876343,788.137146 1480.006348,788.004944 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1268.277954,826.832642 
	C1268.449829,825.851135 1268.723877,824.875000 1268.775879,823.887207 
	C1269.020874,819.234436 1268.250610,818.712524 1264.950317,820.021362 
	C1263.668701,817.854309 1262.550049,815.962830 1261.276611,813.391418 
	C1261.154419,812.390320 1261.187134,812.069153 1261.219849,811.748047 
	C1262.490845,812.463806 1263.761841,813.179626 1265.402344,814.103516 
	C1266.052002,813.026367 1266.628906,812.070007 1267.841919,810.058899 
	C1270.522705,815.069763 1269.808716,819.866882 1270.855713,824.958984 
	C1270.963257,826.143616 1271.028320,826.587097 1271.023193,827.336914 
	C1271.256226,828.404724 1271.559448,829.166199 1271.505615,830.179688 
	C1269.422852,840.592285 1270.714966,849.534119 1280.891724,855.004700 
	C1282.038940,855.621460 1282.818115,856.977905 1283.987671,857.487427 
	C1286.893677,858.753662 1289.862549,860.360352 1292.924072,860.672424 
	C1297.514893,861.140259 1302.206787,860.617432 1306.863281,860.748779 
	C1306.916748,861.289368 1306.959717,861.597534 1306.536865,861.985474 
	C1292.096924,861.854919 1278.086792,863.486206 1265.589966,861.234619 
	C1264.219238,858.819641 1263.412109,856.867065 1262.112549,855.331543 
	C1261.459106,854.559448 1259.910400,854.545105 1259.256348,854.161499 
	C1260.398560,854.055481 1261.050903,853.975952 1262.062622,853.714966 
	C1263.697144,853.651978 1264.971680,853.853088 1266.247437,853.861145 
	C1267.154297,853.866943 1268.518555,853.184509 1268.874878,853.563965 
	C1269.598633,854.334717 1270.412964,855.920654 1270.071045,856.634888 
	C1268.662231,859.577026 1270.684326,860.468811 1272.593628,860.683899 
	C1275.782837,861.043335 1279.042114,860.782043 1283.087036,860.782043 
	C1282.062256,859.312073 1281.769287,858.457703 1281.177856,858.107971 
	C1270.766113,851.950500 1266.969849,842.797668 1268.791992,830.624634 
	C1268.626709,829.072205 1268.452271,827.952454 1268.277954,826.832642 
M1266.437500,856.033020 
	C1266.817993,855.898743 1267.198608,855.764465 1267.579102,855.630249 
	C1267.366821,855.313293 1267.154541,854.996338 1266.942261,854.679382 
	C1266.624878,854.893433 1266.307373,855.107422 1266.437500,856.033020 
M1267.579346,814.298584 
	C1267.102417,814.082214 1266.625488,813.865845 1266.148560,813.649475 
	C1266.064331,813.910461 1265.850586,814.354004 1265.917603,814.401550 
	C1266.249268,814.636597 1266.654907,814.766907 1267.579346,814.298584 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1188.450317,853.818604 
	C1185.763550,854.439148 1183.076782,855.059692 1180.389893,855.680237 
	C1180.437256,856.428589 1180.484619,857.176941 1180.531982,857.925293 
	C1183.594727,858.413635 1186.657471,858.901978 1189.757568,859.985107 
	C1190.564453,860.400269 1191.334229,860.220581 1192.104004,860.040955 
	C1193.453979,859.680908 1194.804077,859.320801 1196.475586,859.339661 
	C1195.179565,860.625977 1193.561768,861.533325 1191.943970,862.440674 
	C1192.477173,863.071167 1193.010376,863.701660 1193.543457,864.332092 
	C1195.356812,863.886841 1197.170044,863.441589 1199.205078,862.925598 
	C1199.941895,862.877869 1200.457153,862.900879 1200.972412,862.923889 
	C1200.482910,863.865967 1200.029907,865.603149 1199.498047,865.627563 
	C1193.927612,865.883850 1188.344360,865.865723 1182.190308,865.649292 
	C1179.043457,864.898438 1176.471191,864.422058 1174.068848,863.686401 
	C1174.622192,862.939636 1175.005493,862.452087 1175.688721,861.960510 
	C1176.587891,861.958984 1177.187256,861.961487 1177.786621,861.963989 
	C1178.398682,860.431763 1179.408936,858.903625 1179.424927,857.365173 
	C1179.431152,856.757568 1177.276001,855.967896 1176.010132,855.544800 
	C1174.180664,854.933289 1172.265015,854.579834 1170.386108,854.116333 
	C1171.129028,852.286072 1171.872070,850.455872 1173.169067,848.296021 
	C1174.626953,848.171204 1175.530884,848.376038 1177.012695,848.711853 
	C1174.968018,850.297729 1173.631714,851.334229 1172.295410,852.370667 
	C1172.622314,852.838745 1172.949341,853.306824 1173.276367,853.774841 
	C1174.847534,852.635071 1176.914185,851.810303 1177.882812,850.287292 
	C1179.678467,847.464111 1183.630981,846.644897 1185.784912,849.294067 
	C1186.876709,850.636841 1187.574463,852.300171 1188.450317,853.818604 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1776.080566,854.253906 
	C1776.058472,854.018311 1776.036499,853.782715 1775.971802,853.194763 
	C1776.587524,850.696960 1777.768799,848.560730 1777.806641,846.404480 
	C1777.920288,839.920532 1777.588623,833.428772 1777.280762,826.159180 
	C1776.751709,825.241089 1776.372681,825.103943 1775.993530,824.966797 
	C1771.799072,824.635864 1767.604614,824.304993 1763.410156,823.974121 
	C1763.419067,823.580994 1763.427856,823.187866 1763.436768,822.794800 
	C1766.035400,822.917114 1768.678101,822.782227 1771.223511,823.216980 
	C1776.044067,824.040222 1777.387085,823.160278 1777.376465,818.422485 
	C1777.363037,812.424072 1777.789917,806.354919 1776.995361,800.455139 
	C1776.546997,797.126221 1776.261353,794.043640 1777.029785,790.090942 
	C1776.971313,788.881592 1776.943481,788.414490 1776.938965,787.509155 
	C1776.301025,783.765747 1775.639893,780.460571 1775.308350,776.992920 
	C1777.269287,776.748474 1778.900879,776.666321 1780.727295,776.769897 
	C1781.048340,777.083435 1781.174316,777.211365 1781.122559,777.666382 
	C1777.838989,781.078003 1779.216187,784.683838 1779.767578,788.456177 
	C1779.767944,789.070618 1779.759399,789.421448 1779.569336,790.069092 
	C1779.420288,791.505615 1779.452881,792.645447 1779.397461,794.060913 
	C1779.443726,794.644348 1779.578125,794.951965 1779.712402,795.259644 
	C1779.795532,794.826782 1779.878784,794.393921 1780.302979,794.077576 
	C1781.046265,795.054382 1781.907715,796.592529 1781.783936,796.676453 
	C1778.135132,799.148254 1779.351807,802.881409 1779.442383,806.131165 
	C1779.605835,811.994812 1780.522339,817.847290 1780.511597,823.703369 
	C1780.496948,831.752380 1780.139038,839.820557 1779.424561,847.835693 
	C1779.226562,850.056763 1777.244263,852.118835 1776.080566,854.253906 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1201.000488,879.998230 
	C1201.001099,880.726746 1201.001587,881.455261 1200.981812,882.547485 
	C1200.961426,882.911133 1200.973022,883.052429 1200.766357,883.110840 
	C1200.184814,883.333313 1200.081177,883.602051 1200.248901,883.975403 
	C1198.653442,884.267578 1197.057861,884.559753 1195.620850,884.439209 
	C1196.512939,883.592102 1197.246704,883.157654 1198.653076,882.324951 
	C1196.877441,882.041016 1195.946899,881.892273 1195.016357,881.743469 
	C1193.995239,881.778564 1192.973999,881.813721 1191.507202,881.557983 
	C1190.172852,881.446594 1189.284058,881.626038 1188.395386,881.805542 
	C1184.574341,882.391113 1180.763794,883.090759 1176.921387,883.444946 
	C1176.312378,883.501038 1174.983765,882.007690 1174.973145,881.217407 
	C1174.926758,877.777893 1175.244629,874.333557 1176.157471,870.663940 
	C1178.588501,870.330688 1180.292236,870.224487 1181.996094,870.118286 
	C1185.439697,870.021179 1188.331665,870.381042 1187.839600,875.128418 
	C1187.765503,875.842834 1189.563110,877.032288 1190.701294,877.527832 
	C1192.585205,878.348083 1194.637085,878.782837 1197.152832,879.613708 
	C1198.061035,879.564819 1198.434448,879.282166 1198.807861,878.999512 
	C1198.977661,878.068787 1199.147461,877.138123 1199.522095,875.084839 
	C1200.208130,876.805603 1200.501709,877.542175 1200.788940,878.893616 
	C1200.855103,879.671753 1200.927856,879.835022 1201.000488,879.998230 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1654.022827,853.834473 
	C1651.356812,859.198853 1648.489990,855.393250 1645.357666,854.008057 
	C1643.884521,853.176147 1642.730103,852.353638 1641.295166,850.978638 
	C1640.676392,850.283142 1640.338135,850.140076 1640.000000,849.997009 
	C1639.134399,849.698425 1638.268921,849.399780 1636.653687,848.842590 
	C1638.873535,846.937927 1640.559326,845.491516 1642.601929,844.410889 
	C1643.279053,845.844727 1643.599243,846.912842 1643.919556,847.980957 
	C1644.218994,847.928772 1644.518433,847.876648 1644.817871,847.824463 
	C1644.952881,846.905701 1645.087769,845.986877 1645.222656,845.068115 
	C1646.967773,845.010925 1648.712769,844.947632 1650.458130,844.899963 
	C1651.021851,844.884521 1651.586548,844.914734 1652.150879,844.915405 
	C1654.154663,844.917725 1656.158569,844.916321 1658.164429,846.111938 
	C1660.109131,845.390625 1662.053955,844.669312 1664.308838,844.352173 
	C1663.248901,845.888489 1661.878662,847.020630 1660.508545,848.152771 
	C1660.781982,848.527283 1661.055298,848.901794 1661.328613,849.276245 
	C1665.046265,848.759705 1668.715332,848.166260 1669.628174,843.208801 
	C1670.649292,843.507446 1671.670532,843.806091 1673.009521,844.611816 
	C1674.706787,846.214783 1676.086182,847.310608 1677.465454,848.406433 
	C1677.465454,848.406433 1677.215454,848.663513 1676.790771,848.801758 
	C1676.209351,849.300110 1676.052490,849.660156 1675.895630,850.020264 
	C1675.199341,849.882080 1674.419800,849.895996 1673.825806,849.571045 
	C1672.872192,849.049377 1672.041382,848.303406 1670.614746,847.486328 
	C1669.640015,847.544800 1669.209229,847.769287 1668.778442,847.993774 
	C1668.574341,848.272095 1668.504028,848.580933 1668.302734,849.165161 
	C1667.174927,849.852722 1666.311890,850.295410 1665.448975,850.738037 
	C1663.219116,850.580811 1660.990112,850.336975 1658.758789,850.311523 
	C1657.932861,850.302063 1657.101074,850.805786 1655.681152,851.435913 
	C1653.854248,851.796387 1652.618164,851.796387 1651.381958,851.796387 
	C1651.323120,852.052734 1651.264282,852.309082 1651.205444,852.565430 
	C1652.144531,852.988403 1653.083740,853.411438 1654.022827,853.834473 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1569.928589,786.026611 
	C1570.139648,792.741516 1570.139648,792.739014 1563.602905,792.743774 
	C1554.724121,792.750305 1545.845215,792.761230 1536.510498,792.579346 
	C1536.054443,792.388367 1536.000000,791.959961 1536.004272,791.562378 
	C1536.009521,790.105774 1536.010498,789.046631 1536.419800,788.024719 
	C1542.179321,789.357910 1541.262207,784.412354 1543.175537,782.011353 
	C1543.673950,782.048828 1543.873413,782.062622 1544.026978,782.434509 
	C1543.717773,784.439392 1543.454346,786.086121 1543.157227,788.168762 
	C1543.287964,789.210876 1543.443726,790.343872 1543.618042,790.346741 
	C1549.228882,790.439514 1554.841431,790.423157 1560.453613,790.423157 
	C1560.449463,790.129211 1560.445190,789.835205 1560.441040,789.541260 
	C1557.973267,789.065674 1555.505615,788.590149 1553.472534,788.059753 
	C1555.799683,788.004944 1557.692139,788.004944 1560.557495,788.004944 
	C1559.880615,785.418701 1559.428589,783.691895 1558.990723,781.573120 
	C1558.664307,779.381104 1558.323730,777.581055 1558.201416,775.481812 
	C1559.067749,773.346680 1559.715698,771.510803 1560.363770,769.674927 
	C1559.860718,769.314087 1559.357544,768.953186 1558.854492,768.592346 
	C1555.786865,770.306946 1552.719238,772.021484 1549.643311,773.319885 
	C1552.125000,771.206482 1554.534180,769.372742 1557.124023,767.845154 
	C1560.708862,765.730530 1562.748779,766.837219 1562.915283,770.890320 
	C1563.064575,774.525940 1563.045288,778.168518 1563.067993,782.143433 
	C1561.876831,785.780457 1564.445801,784.792175 1565.988281,785.381531 
	C1566.417236,787.066589 1566.816772,788.389160 1567.216309,789.711792 
	C1567.513672,789.619385 1567.811035,789.526917 1568.108398,789.434509 
	C1568.167236,788.315247 1568.225952,787.195984 1568.558838,786.061279 
	C1569.197998,786.039429 1569.563354,786.033020 1569.928589,786.026611 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1335.378662,750.015625 
	C1330.764648,750.002075 1326.467896,750.104431 1322.216431,749.714844 
	C1319.036987,749.423462 1318.125610,751.020020 1317.551025,753.284180 
	C1316.520752,751.997986 1315.764771,751.053650 1314.756836,750.133179 
	C1314.360840,750.448120 1314.216919,750.739319 1314.053223,750.997742 
	C1314.033447,750.964966 1313.956787,750.966003 1313.956787,750.966003 
	C1312.746582,751.451843 1311.536255,751.937683 1309.784668,752.692749 
	C1300.144775,755.608643 1295.175537,762.917664 1290.005615,770.000000 
	C1290.001465,770.908813 1289.997314,771.817688 1290.078735,773.320374 
	C1289.372559,774.647034 1288.580811,775.379883 1287.206543,776.651794 
	C1287.206543,774.937012 1287.206543,774.002747 1287.206543,772.824097 
	C1285.224487,774.019165 1283.603638,774.996460 1281.956421,775.627075 
	C1281.943115,775.049011 1281.956055,774.817627 1282.077271,774.254639 
	C1285.471802,772.020447 1283.989868,769.499817 1283.426270,767.042358 
	C1284.886353,767.330322 1285.996094,767.628723 1287.422119,767.845154 
	C1292.432983,762.897339 1297.127686,758.031555 1302.025269,753.081421 
	C1303.376587,752.526062 1304.525269,752.054932 1305.673828,751.583862 
	C1305.102661,750.871643 1304.531372,750.159485 1303.960205,749.447266 
	C1302.197510,750.195312 1300.434814,750.943359 1298.319702,751.620483 
	C1296.643066,751.021729 1295.318848,750.494019 1293.998535,749.643799 
	C1293.999878,748.891541 1293.997070,748.461609 1294.463013,748.013733 
	C1300.920288,747.942627 1306.909058,747.889526 1313.265381,747.854492 
	C1314.122925,747.854675 1314.612793,747.836792 1315.401123,747.884888 
	C1316.102173,747.915405 1316.504883,747.879822 1317.285522,747.848755 
	C1318.166260,747.818848 1318.668823,747.784485 1319.578613,747.818237 
	C1321.635132,747.864624 1323.284790,747.842834 1325.323975,747.872070 
	C1326.496704,747.890442 1327.279785,747.857788 1328.448486,747.868591 
	C1329.865967,747.876099 1330.898071,747.840149 1332.321777,747.848389 
	C1333.499878,747.860413 1334.286133,747.828125 1335.396851,747.845215 
	C1336.152222,747.961914 1336.583252,748.029358 1337.014648,748.330750 
	C1337.044556,748.875305 1337.054199,749.186523 1337.044067,749.498291 
	C1336.601196,749.638977 1336.158203,749.779663 1335.378662,750.015625 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1690.602661,748.972473 
	C1690.681396,749.119324 1690.867188,749.333984 1690.823364,749.403259 
	C1689.382080,751.681274 1675.788818,752.485229 1675.314331,750.147461 
	C1674.340942,745.352112 1671.209351,747.569641 1668.394531,747.408447 
	C1664.119751,751.311401 1664.119751,751.311401 1661.068481,750.656494 
	C1662.132202,749.877258 1663.065918,749.193298 1664.022461,747.808533 
	C1664.050049,746.461853 1664.054565,745.816040 1664.068848,744.811523 
	C1664.325317,743.525940 1664.571899,742.598999 1665.013550,740.938416 
	C1665.964600,742.997864 1666.516357,744.192383 1667.508057,746.339844 
	C1668.209717,744.210022 1668.588257,743.061035 1669.232788,741.890442 
	C1669.674561,741.895996 1669.850342,741.923157 1670.116455,742.340210 
	C1671.060059,745.981873 1673.363770,746.752502 1674.569458,743.714783 
	C1675.708984,740.843750 1677.229492,740.929688 1679.390991,740.925232 
	C1682.201172,740.919434 1685.010986,740.830933 1688.182983,740.810669 
	C1689.024780,740.901855 1689.504639,740.960510 1690.104858,741.295837 
	C1690.476074,742.332581 1690.666626,743.120422 1690.999023,743.842834 
	C1691.218506,744.319458 1691.642212,744.702148 1691.974365,745.126892 
	C1691.976440,745.530212 1691.978638,745.933472 1691.977661,746.639221 
	C1691.974487,746.941650 1691.970947,746.986694 1691.591431,746.963318 
	C1690.191406,747.482178 1689.170776,748.024414 1688.150269,748.566650 
	C1688.967773,748.701965 1689.785156,748.837219 1690.602661,748.972473 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1442.000000,805.999756 
	C1443.076782,806.032471 1444.153442,806.065125 1445.660645,806.352783 
	C1446.059082,806.963745 1446.027100,807.319763 1445.995239,807.675842 
	C1445.205933,807.982483 1444.416748,808.289062 1442.979004,808.643982 
	C1441.996582,808.933350 1441.662720,809.174438 1441.328857,809.415405 
	C1441.873291,809.643738 1442.417603,809.872131 1442.961914,810.100525 
	C1442.454590,810.881836 1441.728027,812.392578 1441.478149,812.317383 
	C1440.426636,812.001343 1439.517822,811.211670 1438.329346,809.990967 
	C1437.722168,809.200134 1437.342651,809.013550 1436.963135,808.826965 
	C1426.641357,808.820618 1416.301025,808.481384 1406.011719,809.056519 
	C1403.624390,809.189941 1401.393677,812.419800 1399.125366,814.267090 
	C1397.921509,815.247437 1396.791382,816.318604 1395.009888,817.606445 
	C1394.012085,817.988953 1393.632446,818.113831 1393.003174,818.020386 
	C1392.566162,817.685059 1392.378662,817.568054 1392.191040,817.450989 
	C1393.024414,816.187439 1393.857788,814.923828 1394.748779,813.033875 
	C1394.816650,811.985840 1394.826904,811.564087 1395.028320,810.792603 
	C1395.609253,810.080383 1395.999146,809.718079 1396.388916,809.355713 
	C1397.397461,809.419861 1399.009888,809.913269 1399.318237,809.479675 
	C1401.586914,806.288391 1404.789062,806.252136 1408.173706,806.272461 
	C1416.440918,806.322144 1424.715088,806.418091 1432.972412,806.114197 
	C1435.286865,806.029053 1437.552490,804.620422 1440.377441,803.630920 
	C1440.122803,802.935181 1440.374146,803.622070 1440.771484,804.939331 
	C1441.278198,805.713013 1441.639038,805.856384 1442.000000,805.999756 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1724.975952,771.480774 
	C1724.975952,771.480774 1725.010986,771.504333 1724.941406,771.081543 
	C1725.673584,770.280640 1726.449585,769.660034 1727.281860,769.572205 
	C1729.348267,769.354065 1731.440186,769.379150 1733.919312,769.445801 
	C1735.133423,769.351440 1736.588745,769.250671 1736.669312,768.864075 
	C1737.569336,764.537842 1740.139160,766.208374 1742.586426,767.440369 
	C1742.791870,768.479675 1742.928589,769.171570 1743.032227,770.133179 
	C1742.986816,770.583618 1742.974365,770.764404 1742.700195,771.270752 
	C1742.202026,776.246521 1741.965332,780.896729 1741.705078,786.010620 
	C1739.543213,786.010620 1737.779175,786.010620 1735.699951,785.997559 
	C1734.965942,785.961853 1734.546753,785.939209 1733.916016,785.609558 
	C1731.740356,780.098206 1731.788940,773.622986 1724.975952,771.480774 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1233.007324,815.043579 
	C1231.543945,815.483459 1230.080444,815.923340 1228.317261,816.453369 
	C1228.233765,816.056335 1228.090698,815.375916 1227.837524,814.078857 
	C1227.375000,813.597107 1227.022827,813.731934 1226.670532,813.866699 
	C1223.868042,815.076233 1221.135132,817.059753 1218.247070,817.319885 
	C1213.541992,817.743530 1208.735596,817.041565 1203.669434,816.411743 
	C1203.342529,814.476562 1203.318237,812.944275 1203.293945,811.411926 
	C1208.170776,810.961853 1213.047485,810.511841 1218.308350,810.429810 
	C1216.983765,811.821777 1215.275146,812.845703 1213.566528,813.869568 
	C1214.124146,814.443420 1214.681763,815.017212 1215.239258,815.591064 
	C1218.921997,815.788452 1221.229370,814.335876 1221.195435,810.074829 
	C1224.195557,810.514404 1227.184204,811.128174 1230.200195,811.326843 
	C1231.953125,811.442383 1233.749878,810.891846 1236.678833,810.478394 
	C1235.877930,812.731873 1235.207886,814.617188 1234.279785,815.942139 
	C1233.683472,815.268982 1233.345459,815.156250 1233.007324,815.043579 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1271.093506,827.030579 
	C1271.028320,826.587097 1270.963257,826.143616 1270.928955,825.365356 
	C1293.389404,824.986816 1315.819214,824.930115 1338.248901,824.904480 
	C1350.750488,824.890198 1363.252075,824.959656 1375.753540,824.925110 
	C1380.522217,824.911926 1385.290405,824.713989 1390.468018,825.026184 
	C1390.886841,825.646667 1390.896362,825.841248 1390.445679,826.013916 
	C1350.354736,826.338135 1310.724121,826.684387 1271.093506,827.030579 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1328.950439,776.135132 
	C1330.739746,775.325134 1332.529175,774.515198 1334.683594,773.603455 
	C1338.620850,774.241699 1342.173828,776.678284 1345.959473,774.193237 
	C1346.174561,775.690674 1346.218872,776.617676 1346.263184,777.544678 
	C1346.519043,777.558105 1346.774902,777.571533 1347.030762,777.584961 
	C1347.305664,776.565491 1347.580566,775.546021 1347.855469,774.526550 
	C1350.006470,772.422974 1351.289307,773.598389 1352.752563,776.211731 
	C1353.908325,777.227478 1354.635742,777.741455 1355.363159,778.255493 
	C1355.427368,777.463562 1355.491577,776.671570 1355.555908,775.879639 
	C1356.216797,775.426025 1356.822754,774.824341 1357.551636,774.554565 
	C1358.580933,774.173584 1359.701172,774.038086 1361.085449,773.730347 
	C1361.218384,775.760681 1361.350708,777.780701 1361.485596,779.840637 
	C1354.886719,779.341736 1348.183594,780.996277 1341.030029,778.777527 
	C1340.206909,779.227173 1340.051392,779.624695 1339.895874,780.022217 
	C1339.003662,779.813232 1338.111450,779.604248 1336.899658,779.320435 
	C1336.520508,779.721863 1335.838501,780.443848 1334.597168,781.449951 
	C1332.336304,780.686401 1330.634644,779.638611 1328.936279,778.285339 
	C1328.943115,777.364929 1328.946777,776.750061 1328.950439,776.135132 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1675.793579,783.472046 
	C1681.702271,782.632446 1687.715210,780.039062 1693.412231,784.583008 
	C1694.550049,783.809082 1695.725464,783.009521 1697.481079,781.815247 
	C1697.481079,784.372986 1697.481079,786.343384 1697.481079,788.752258 
	C1695.864380,788.362732 1694.375244,788.003906 1692.298828,787.452942 
	C1691.575684,787.821716 1691.439819,788.382568 1691.303833,788.943359 
	C1690.812134,788.505066 1690.320435,788.066772 1689.821289,787.022888 
	C1689.718750,786.109253 1689.623657,785.801147 1689.528564,785.493042 
	C1689.166504,785.926758 1688.804321,786.360474 1688.442139,786.794189 
	C1686.664673,787.547241 1684.949829,788.652954 1683.097046,788.982300 
	C1678.806885,789.744812 1674.449707,790.131897 1669.605713,790.493835 
	C1669.529907,789.245361 1669.968018,788.174133 1670.370239,787.190735 
	C1668.011963,786.752136 1667.940796,784.685913 1669.211426,782.083069 
	C1670.615601,782.217102 1672.019775,782.351074 1673.615967,783.098755 
	C1674.154175,784.076111 1674.500366,784.439636 1674.846558,784.803223 
	C1675.162231,784.359497 1675.477905,783.915771 1675.793579,783.472046 
M1681.851929,786.240112 
	C1682.128418,785.476318 1682.404907,784.712524 1682.681274,783.948730 
	C1682.064575,784.128967 1681.447754,784.309204 1680.106812,784.605652 
	C1679.054199,785.396973 1678.001587,786.188293 1676.949097,786.979614 
	C1677.266602,787.400330 1677.584229,787.821106 1677.901855,788.241882 
	C1678.971313,787.597839 1680.040894,786.953796 1681.851929,786.240112 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1682.198120,820.420776 
	C1682.598145,817.947021 1682.998169,815.473267 1683.578125,811.887085 
	C1685.780518,813.875305 1686.660645,814.669922 1687.963257,815.781006 
	C1688.743408,816.304504 1689.101196,816.511536 1689.458984,816.718628 
	C1689.528320,816.144775 1689.597656,815.570862 1689.666992,814.997009 
	C1692.417236,810.848755 1694.480225,813.425049 1696.444580,815.840515 
	C1700.853271,811.886963 1705.979736,812.953369 1711.012695,813.910522 
	C1710.930786,815.353455 1710.801392,816.092834 1710.317383,816.803162 
	C1707.264893,818.047729 1704.567017,819.321350 1701.462891,820.659607 
	C1697.980713,820.785706 1694.420654,821.855835 1691.944824,820.667419 
	C1688.405762,818.968567 1685.333862,822.057312 1682.198120,820.420776 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1268.091553,826.839233 
	C1268.452271,827.952454 1268.626709,829.072205 1268.344604,830.596191 
	C1260.897095,831.000244 1253.906006,831.000122 1246.914917,831.000000 
	C1240.333740,831.000000 1233.752563,831.000000 1227.171387,831.000000 
	C1227.139771,830.820984 1227.108154,830.641907 1227.076416,830.462891 
	C1228.333496,830.196899 1229.590698,829.930969 1230.834839,829.667725 
	C1230.856201,829.531433 1230.873169,829.423401 1230.890137,829.315369 
	C1228.875610,829.315369 1226.861084,829.315369 1224.080200,829.315369 
	C1224.026123,829.240479 1223.323364,828.267334 1222.321533,827.163818 
	C1223.407837,826.670593 1224.793213,826.307678 1226.717529,826.194824 
	C1227.859985,826.280212 1228.463745,826.115540 1229.067383,825.950928 
	C1229.067383,825.950989 1229.512451,825.940186 1230.032227,826.256470 
	C1242.755493,826.710205 1254.959229,826.847656 1267.162842,826.985107 
	C1267.407349,826.922791 1267.654663,826.876404 1268.091553,826.839233 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1639.326172,775.047058 
	C1638.946411,774.776367 1638.893188,774.557556 1638.705566,774.028809 
	C1640.383911,771.430298 1642.196655,769.141846 1644.732666,766.833374 
	C1646.941772,767.356506 1648.427612,767.899658 1650.104248,768.512634 
	C1651.472168,764.954712 1652.778687,761.556152 1654.085205,758.157654 
	C1654.775269,758.124512 1655.465454,758.091370 1656.580811,758.477783 
	C1657.034302,762.403564 1657.152588,765.913269 1657.016113,769.412903 
	C1656.987915,770.135193 1656.009399,770.820435 1655.072266,771.611450 
	C1653.743408,773.929321 1653.810547,775.391968 1656.729492,774.603821 
	C1656.395386,774.893738 1656.318237,775.170471 1656.358276,775.477905 
	C1655.331055,777.137512 1654.303955,778.797119 1653.135498,780.685059 
	C1653.135498,777.759338 1653.135498,774.786804 1653.135498,771.245361 
	C1650.406982,771.245361 1648.148926,771.245361 1645.064331,771.467896 
	C1643.671387,772.444885 1643.105103,773.199341 1642.538818,773.953735 
	C1641.576782,774.335510 1640.614746,774.717224 1639.326172,775.047058 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1569.966431,785.557739 
	C1569.563354,786.033020 1569.197998,786.039429 1568.466309,785.592590 
	C1568.099731,773.882446 1568.099731,762.625488 1568.099731,751.368591 
	C1567.386108,751.373718 1566.672363,751.378845 1565.958740,751.383972 
	C1565.958740,762.595642 1565.958740,773.807373 1565.958740,785.019043 
	C1564.445801,784.792175 1561.876831,785.780457 1563.386230,781.895142 
	C1563.767578,770.334473 1563.798462,759.357727 1563.829346,748.380920 
	C1565.628784,748.291260 1567.428223,748.201660 1569.678101,748.163818 
	C1570.105835,754.165283 1570.083130,760.114990 1570.053223,766.864258 
	C1570.072998,768.109863 1570.100098,768.555847 1570.127197,769.001831 
	C1570.086182,774.364197 1570.045166,779.726501 1569.966431,785.557739 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1328.933105,778.590820 
	C1330.634644,779.638611 1332.336304,780.686401 1334.372803,781.670044 
	C1334.822876,782.376099 1334.937988,783.146301 1334.768066,784.593140 
	C1332.352539,785.204956 1330.202026,785.301270 1328.095093,785.043335 
	C1322.813232,784.396484 1322.330200,784.950989 1323.929199,790.326111 
	C1326.090454,789.394592 1328.563843,785.087830 1330.101929,790.893494 
	C1326.503296,794.723633 1318.980591,793.136902 1317.323608,788.304199 
	C1316.879517,787.009094 1315.769531,785.942261 1314.970215,784.023438 
	C1314.986206,782.504700 1314.997437,781.733215 1315.340820,781.112671 
	C1317.336914,780.933899 1319.001099,780.604065 1321.482300,780.112244 
	C1323.682739,782.018066 1326.511841,781.900574 1328.933105,778.590820 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1179.077759,840.006348 
	C1180.466797,839.356140 1181.855713,838.705933 1183.632568,838.468628 
	C1184.020508,841.008850 1184.020508,843.136169 1184.020508,845.005066 
	C1180.049927,845.375854 1176.528320,845.704773 1173.006714,846.033691 
	C1169.614258,845.869568 1166.221802,845.705444 1162.138672,845.507874 
	C1162.846436,843.036926 1163.530762,840.648071 1164.096069,838.674683 
	C1167.641235,838.674683 1170.504639,838.674683 1173.768921,838.998169 
	C1174.353271,839.208252 1174.536865,839.094849 1174.720459,838.981445 
	C1175.609009,839.210083 1176.497681,839.438782 1177.660767,840.131104 
	C1178.082031,840.834961 1178.228760,841.075134 1178.375610,841.315247 
	C1178.609741,840.878967 1178.843750,840.442688 1179.077759,840.006348 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1712.536499,820.808838 
	C1714.049805,821.982544 1715.563232,823.156311 1717.745361,824.848877 
	C1715.164795,825.697815 1712.315430,825.792908 1711.417969,827.164917 
	C1710.503174,828.563416 1711.507690,831.217468 1711.764893,833.993530 
	C1710.528198,835.744202 1709.198364,836.823120 1707.868408,837.902100 
	C1707.868408,834.262573 1707.868408,830.622986 1707.868408,826.006287 
	C1704.703369,826.006287 1701.578003,825.995178 1698.452759,826.008789 
	C1693.409668,826.030701 1688.363403,826.159668 1683.327393,825.986572 
	C1682.689575,825.964661 1682.105103,824.395386 1682.194336,823.317505 
	C1689.264282,823.022034 1695.635864,822.951965 1702.341675,823.084106 
	C1705.953247,822.499756 1709.230591,821.713135 1712.509766,820.898743 
	C1712.511597,820.870972 1712.536499,820.808838 1712.536499,820.808838 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1725.046997,771.850708 
	C1731.788940,773.622986 1731.740356,780.098206 1733.908447,786.033447 
	C1735.620239,787.506165 1737.136841,788.905945 1738.634888,788.886353 
	C1751.396973,788.719482 1764.155762,788.294800 1776.915527,787.947327 
	C1776.943481,788.414490 1776.971313,788.881592 1776.975342,789.698975 
	C1763.938721,790.310364 1750.922852,790.471802 1737.915283,790.893372 
	C1734.010986,791.020020 1732.355103,790.232910 1731.464355,785.788879 
	C1730.511108,781.033203 1727.327881,776.724487 1725.046997,771.850708 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1173.005127,846.373535 
	C1176.528320,845.704773 1180.049927,845.375854 1184.020508,845.005066 
	C1184.020508,843.136169 1184.020508,841.008850 1183.996582,838.446106 
	C1184.147339,838.008667 1184.321899,838.006714 1184.752197,838.436707 
	C1185.007690,841.058594 1185.007690,843.248474 1185.007690,846.853088 
	C1186.516113,844.947510 1187.043823,844.280823 1187.021851,844.308594 
	C1189.612183,844.934082 1191.774902,845.456360 1193.937744,845.978638 
	C1194.296143,849.121094 1194.654541,852.263550 1195.061890,855.835022 
	C1192.233765,854.905945 1190.731689,854.412476 1188.839844,853.868774 
	C1187.574463,852.300171 1186.876709,850.636841 1185.784912,849.294067 
	C1183.630981,846.644897 1179.678467,847.464111 1177.882812,850.287292 
	C1176.914185,851.810303 1174.847534,852.635071 1173.276367,853.774841 
	C1172.949341,853.306824 1172.622314,852.838745 1172.295410,852.370667 
	C1173.631714,851.334229 1174.968018,850.297729 1177.012695,848.711853 
	C1175.530884,848.376038 1174.626953,848.171204 1173.375244,848.020020 
	C1173.019409,847.620239 1173.011353,847.166809 1173.005127,846.373535 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1733.521851,769.306580 
	C1731.440186,769.379150 1729.348267,769.354065 1727.281860,769.572205 
	C1726.449585,769.660034 1725.673584,770.280640 1724.960327,771.091553 
	C1722.301636,771.015930 1719.554565,770.507568 1716.398682,769.643860 
	C1715.177368,769.519897 1714.364868,769.751160 1713.552246,769.982483 
	C1713.003540,768.839539 1712.454834,767.696533 1712.025146,765.881348 
	C1714.529419,762.787659 1716.833252,761.545105 1718.174316,764.506348 
	C1723.293335,764.054138 1727.891968,763.647949 1733.179443,763.180847 
	C1731.429199,765.019287 1730.061890,766.455505 1728.694702,767.891663 
	C1729.001221,768.281189 1729.307739,768.670776 1729.614258,769.060303 
	C1731.023071,768.423157 1732.431885,767.786011 1733.853760,767.426025 
	C1733.751831,768.237671 1733.636841,768.772156 1733.521851,769.306580 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1421.946411,824.804199 
	C1423.044434,824.794006 1424.142578,824.783813 1425.645020,825.125916 
	C1426.028931,825.990723 1426.008423,826.503174 1425.983643,827.238770 
	C1425.979492,827.461853 1426.000000,827.907654 1425.677979,828.195129 
	C1425.220093,836.664795 1425.134033,844.848450 1424.907715,853.028076 
	C1424.860840,854.721313 1424.291138,856.400085 1423.952026,857.636780 
	C1423.941650,854.950928 1423.941650,852.713684 1423.941650,850.525696 
	C1420.239502,849.568359 1417.093750,848.754944 1413.934326,847.594788 
	C1413.996460,846.791748 1414.072388,846.335388 1414.544189,845.928589 
	C1416.771851,846.342651 1418.603638,846.707092 1420.898438,847.163696 
	C1420.898438,841.443848 1421.040161,836.467407 1420.768311,831.513611 
	C1420.719238,830.618408 1418.972046,829.816406 1418.250000,828.699585 
	C1419.642822,827.219971 1420.794678,826.012085 1421.946411,824.804199 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1446.392212,807.766357 
	C1446.027100,807.319763 1446.059082,806.963745 1446.066162,806.336121 
	C1448.993652,805.636719 1451.945923,805.208862 1455.481079,804.696472 
	C1455.609741,804.088074 1455.902588,802.703064 1456.279907,800.918945 
	C1460.132690,800.918945 1463.896362,800.918945 1467.957031,801.423645 
	C1468.328735,802.597168 1468.403442,803.265991 1468.478027,803.934814 
	C1468.411011,806.745544 1468.343872,809.556213 1467.809448,812.735352 
	C1465.969971,812.734863 1464.597656,812.365967 1463.594727,812.069580 
	C1466.703857,810.804626 1467.396851,805.996826 1465.051880,802.520935 
	C1462.794922,802.591492 1459.812012,799.850037 1458.999023,803.992554 
	C1459.019531,803.977112 1459.056763,803.941711 1458.666260,804.016541 
	C1458.159546,805.895203 1458.043335,807.699036 1457.697388,809.454468 
	C1457.301880,809.809753 1457.136108,810.213379 1456.752930,810.718872 
	C1456.535522,810.820740 1456.080688,810.974792 1456.080688,810.974792 
	C1452.983521,809.935547 1449.886353,808.896240 1446.392212,807.766357 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1188.833252,896.799133 
	C1188.119873,897.461426 1187.058838,898.832153 1186.745728,898.678589 
	C1182.768799,896.727783 1179.512329,899.873535 1175.328735,899.714966 
	C1173.860962,898.914551 1172.942261,898.432495 1172.023560,897.950500 
	C1172.302979,896.699158 1172.582397,895.447876 1172.919678,893.778931 
	C1172.977539,893.361267 1173.026123,893.393311 1173.026123,893.393311 
	C1176.201050,893.135132 1179.375854,892.876953 1182.661743,892.609802 
	C1185.202393,893.152405 1187.272705,893.594543 1189.301392,894.689697 
	C1189.117554,895.828186 1188.975342,896.313660 1188.833252,896.799133 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1645.060547,833.022339 
	C1647.374512,833.215881 1649.688354,833.409485 1652.185059,834.256653 
	C1652.118164,836.227539 1651.868408,837.544800 1651.618652,838.862061 
	C1647.435913,839.394531 1643.258423,840.084961 1639.062866,840.221436 
	C1638.630859,840.235474 1637.244385,836.543945 1637.833130,835.293762 
	C1638.457153,833.969116 1640.911499,833.506775 1642.767090,833.185547 
	C1641.571533,834.871338 1640.175537,836.039429 1638.156128,837.729004 
	C1642.870239,838.325134 1645.299438,837.367737 1645.060547,833.022339 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1512.456177,792.969482 
	C1512.314209,791.551636 1512.539429,790.268494 1513.240479,788.492676 
	C1518.438965,786.960876 1523.048706,784.896118 1528.042236,786.887939 
	C1528.026123,788.890137 1528.020996,790.428528 1528.015991,791.966919 
	C1524.678223,792.436279 1521.350464,793.011475 1517.998413,793.330872 
	C1516.296143,793.493103 1514.549805,793.194397 1512.456177,792.969482 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1220.388428,799.139832 
	C1222.503296,799.139832 1224.618042,799.139832 1227.033569,799.139832 
	C1227.201050,801.646057 1227.344482,803.791199 1227.452515,805.408386 
	C1224.464844,805.653564 1221.634766,805.667053 1218.895630,806.172119 
	C1216.164795,806.675781 1212.873901,808.512512 1212.100464,804.237244 
	C1211.416504,800.455994 1214.245972,799.080933 1218.074585,799.432312 
	C1217.037354,801.017029 1215.637939,802.230774 1213.380615,804.188660 
	C1218.101685,804.335266 1220.241211,803.028625 1220.388428,799.139832 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1638.520264,826.615906 
	C1638.089722,825.854370 1638.082642,825.353638 1638.127441,824.602051 
	C1645.206299,823.900696 1652.229614,823.351013 1659.263672,823.103455 
	C1660.356445,823.064941 1661.497681,824.407654 1662.616577,825.111633 
	C1661.594360,825.742249 1660.589722,826.863220 1659.546143,826.900879 
	C1657.412964,826.978027 1655.264160,826.403198 1653.110962,826.237244 
	C1652.072144,826.157043 1651.010620,826.437988 1649.956299,826.480957 
	C1646.286255,826.630554 1642.614746,826.747437 1638.520264,826.615906 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1421.634277,824.546387 
	C1420.794678,826.012085 1419.642822,827.219971 1418.022827,828.971436 
	C1413.720825,829.357300 1409.887207,829.199585 1405.906128,828.773315 
	C1405.176392,827.268066 1404.593994,826.031372 1404.011719,824.794678 
	C1409.781860,824.625977 1415.552002,824.457275 1421.634277,824.546387 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1254.948975,813.961670 
	C1251.216919,814.406860 1247.488647,814.896240 1243.746826,815.231018 
	C1243.357300,815.265869 1242.790161,814.439392 1242.483521,813.909485 
	C1242.252441,813.510071 1242.307983,812.945557 1242.232544,812.455078 
	C1240.706421,802.543091 1241.503540,801.699524 1252.510498,802.311523 
	C1252.525024,803.166321 1252.212036,803.633362 1251.461182,804.087219 
	C1248.812256,804.074036 1246.601318,804.074036 1244.587402,804.074036 
	C1244.587402,806.870850 1244.587402,808.815186 1244.587402,811.195923 
	C1246.458618,809.867920 1247.763306,808.942017 1249.283081,808.354614 
	C1248.785767,810.009827 1248.073364,811.326477 1247.224365,812.895813 
	C1249.746704,812.895813 1251.826172,812.895813 1254.016846,813.131836 
	C1254.401733,813.565796 1254.675293,813.763733 1254.948975,813.961670 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1429.948364,824.638916 
	C1435.653931,824.415833 1441.359497,824.192749 1447.512329,824.409668 
	C1447.918457,826.267761 1447.877319,827.685974 1447.405762,829.071777 
	C1442.971069,829.017151 1438.966797,828.994934 1434.825928,828.742310 
	C1434.126221,828.674622 1433.563110,828.837280 1432.712646,829.015747 
	C1432.046143,829.071655 1431.667236,829.111877 1431.199951,828.772461 
	C1430.723999,827.141479 1430.336182,825.890137 1429.948364,824.638916 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1189.311279,896.864929 
	C1188.975342,896.313660 1189.117554,895.828186 1189.463379,895.017334 
	C1195.593994,894.792358 1201.527954,895.075195 1207.443359,894.857361 
	C1208.817871,894.806702 1210.693848,893.332764 1211.350708,892.019348 
	C1212.654297,889.412964 1213.269897,886.462463 1214.185303,883.251770 
	C1214.664185,883.139465 1215.127686,883.429688 1215.815186,884.251831 
	C1216.010498,885.522095 1215.981934,886.260437 1215.953369,886.998779 
	C1215.922485,887.160400 1215.892334,887.322083 1215.860718,887.483521 
	C1214.241333,895.748962 1209.763794,899.187195 1201.101929,897.120178 
	C1197.520020,896.265320 1193.571289,896.947510 1189.311279,896.864929 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1651.682251,747.154968 
	C1650.443481,747.187805 1649.204834,747.220642 1647.139771,747.133301 
	C1645.080200,747.069519 1643.846802,747.125977 1642.613525,747.182373 
	C1629.354736,747.405640 1616.095825,747.628906 1602.214355,747.898438 
	C1600.945679,748.282715 1600.299927,748.620728 1599.496948,748.938110 
	C1599.130615,748.897705 1598.921143,748.888794 1598.711060,748.890808 
	C1597.424927,748.532288 1596.138794,748.173706 1594.455322,747.476807 
	C1594.040894,746.823608 1594.030029,746.508545 1594.488281,746.126831 
	C1611.742676,745.896118 1628.533936,745.731995 1645.824219,745.700928 
	C1647.348389,745.824646 1648.373413,745.815308 1649.799561,745.798340 
	C1651.806763,745.828064 1653.413208,745.865356 1654.802979,746.206909 
	C1653.618408,746.725708 1652.650269,746.940369 1651.682251,747.154968 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1403.665283,824.793457 
	C1404.593994,826.031372 1405.176392,827.268066 1405.561035,828.822083 
	C1405.898438,832.211609 1406.433472,835.283875 1406.968628,838.356079 
	C1407.654541,837.897034 1408.340454,837.437927 1409.013306,836.989502 
	C1409.000366,837.000244 1408.977661,837.025024 1409.302856,837.077393 
	C1409.378540,838.454102 1409.129150,839.778381 1408.536743,841.173706 
	C1407.053223,841.383911 1405.912720,841.523071 1404.805908,841.309814 
	C1404.255249,839.620850 1403.452026,838.335876 1403.122681,836.939209 
	C1401.345703,829.401123 1401.386230,829.391541 1393.928223,828.490662 
	C1394.060791,827.705994 1394.175171,827.264526 1394.699463,826.868591 
	C1397.400635,826.240051 1400.716797,828.783081 1401.934937,824.739868 
	C1402.396240,824.757324 1402.857422,824.774780 1403.665283,824.793457 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1337.398682,749.608521 
	C1337.054199,749.186523 1337.044556,748.875305 1337.462646,748.268494 
	C1340.914062,747.889587 1343.917969,747.806946 1347.357910,747.770142 
	C1349.538574,747.792053 1351.282959,747.768188 1353.484375,747.794128 
	C1357.900024,747.824402 1361.858887,747.804810 1366.265015,747.833008 
	C1368.207642,747.838684 1369.703003,747.796509 1371.652344,747.770752 
	C1377.068848,747.863831 1382.031372,747.940552 1386.781128,748.294922 
	C1385.987305,749.276489 1385.406372,749.980347 1384.417847,750.522522 
	C1381.721924,750.401001 1379.433350,750.441162 1377.000488,750.188049 
	C1376.502197,749.660217 1376.148071,749.425720 1375.544678,749.189697 
	C1375.139771,749.248840 1374.984497,749.309387 1374.465088,749.330566 
	C1373.127563,749.335388 1372.154053,749.379517 1371.180420,749.423584 
	C1370.604248,749.423950 1370.028198,749.424194 1368.944092,749.576660 
	C1368.261841,749.786499 1368.087769,749.844116 1367.913696,749.901794 
	C1367.559082,749.829590 1367.204590,749.757385 1366.580811,749.697144 
	C1362.570312,749.608582 1358.829224,749.508057 1354.247314,749.405884 
	C1350.895508,749.684753 1348.384155,749.965271 1345.872925,750.245789 
	C1343.166382,750.070068 1340.459961,749.894348 1337.398682,749.608521 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1653.640137,758.016357 
	C1652.778687,761.556152 1651.472168,764.954712 1650.104248,768.512634 
	C1648.427612,767.899658 1646.941772,767.356506 1645.079468,766.761047 
	C1644.745728,766.381531 1644.811157,766.055420 1644.827759,765.726807 
	C1645.235107,757.685242 1645.234131,757.685181 1653.640137,758.016357 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1545.014648,824.635193 
	C1522.896484,824.975830 1500.458862,824.999390 1478.021362,824.993591 
	C1476.877563,824.993286 1475.733765,824.739563 1474.299805,824.305298 
	C1496.433594,823.950867 1518.857422,823.888000 1541.281250,823.859680 
	C1542.419189,823.858215 1543.557251,824.154480 1545.014648,824.635193 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1626.977539,759.999329 
	C1624.144287,762.963562 1620.959351,762.476257 1617.112671,760.693787 
	C1617.347900,758.929138 1617.609497,756.967041 1617.871094,755.004883 
	C1618.024536,755.052734 1618.200928,755.068726 1618.325439,755.157288 
	C1618.452881,755.247742 1618.529419,755.409546 1618.664551,756.159729 
	C1618.822144,757.492859 1618.943604,758.206421 1619.065063,758.919983 
	C1619.305542,758.948181 1619.546143,758.976379 1619.786621,759.004578 
	C1620.062866,758.074707 1620.338989,757.144836 1620.615234,756.214966 
	C1622.933350,752.862671 1624.808105,753.811279 1626.685791,757.340454 
	C1626.932861,758.238525 1626.964478,758.614746 1626.994385,759.243164 
	C1626.987671,759.663330 1626.982544,759.831299 1626.977539,759.999329 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1452.010986,788.965210 
	C1450.312012,789.318970 1448.616943,789.955322 1446.913452,789.979126 
	C1437.997437,790.103699 1429.079346,790.058655 1420.162231,790.109985 
	C1418.640625,790.118774 1417.120239,790.308777 1415.599243,790.415039 
	C1415.611084,790.819458 1415.623047,791.223877 1415.634888,791.628296 
	C1420.567749,791.894470 1425.500732,792.160583 1430.721069,792.730347 
	C1424.970947,793.062988 1418.933228,793.092102 1412.382080,792.661865 
	C1411.877808,791.185364 1411.887085,790.168213 1412.368042,789.078247 
	C1425.896851,788.992065 1438.953857,788.978638 1452.010986,788.965210 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1330.479492,790.953918 
	C1328.563843,785.087830 1326.090454,789.394592 1323.929199,790.326111 
	C1322.330200,784.950989 1322.813232,784.396484 1328.095093,785.043335 
	C1330.202026,785.301270 1332.352539,785.204956 1334.767822,784.980896 
	C1335.265015,787.023499 1335.477417,789.355164 1335.359863,791.933228 
	C1333.639038,791.791260 1332.248047,791.402771 1330.479492,790.953918 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1626.996094,758.991028 
	C1626.964478,758.614746 1626.932861,758.238525 1626.884277,757.677307 
	C1629.778320,755.633850 1632.550781,752.615173 1635.954346,757.042664 
	C1636.252441,757.430542 1639.048584,756.316895 1640.322266,755.398499 
	C1641.281372,754.706970 1641.660645,753.211243 1642.572876,751.582642 
	C1641.830811,757.691284 1633.544678,762.494385 1628.293579,759.823730 
	C1627.609619,759.293457 1627.302856,759.142212 1626.996094,758.991028 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1726.120850,821.045715 
	C1726.053589,820.220947 1725.946289,819.397278 1725.925171,818.571289 
	C1725.731079,810.940674 1725.750610,811.222900 1733.216797,810.822327 
	C1737.974854,810.567139 1739.966431,811.974609 1739.215576,816.833130 
	C1738.965210,818.453735 1739.196045,820.148743 1738.999512,822.619873 
	C1737.684937,823.785095 1736.577759,824.140198 1735.470703,824.495239 
	C1735.143188,824.406006 1734.815674,824.316711 1734.236450,823.803345 
	C1733.991943,823.149780 1733.999023,822.920410 1734.398193,822.650208 
	C1739.094116,820.744446 1737.376099,817.900879 1735.838745,814.889893 
	C1735.374634,814.436340 1735.079834,814.207153 1734.704834,813.606934 
	C1732.229370,813.130554 1729.834229,813.025208 1727.212524,812.909973 
	C1727.212524,815.957275 1727.212524,818.392944 1726.945801,820.913574 
	C1726.492920,821.014221 1726.306885,821.029968 1726.120850,821.045715 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1672.913818,776.966248 
	C1670.766602,777.714722 1668.619385,778.463196 1666.155396,778.673645 
	C1665.765991,776.419495 1665.693359,774.703247 1665.620728,772.987061 
	C1668.473877,771.269226 1671.286987,770.375122 1674.019287,774.024048 
	C1673.723633,775.427368 1673.318726,776.196838 1672.913818,776.966248 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1429.619873,824.658020 
	C1430.336182,825.890137 1430.723999,827.141479 1431.105835,829.154968 
	C1430.964844,830.696167 1430.829834,831.475098 1430.348145,832.337524 
	C1429.725830,834.240784 1429.608765,836.103882 1429.126587,837.867188 
	C1428.712280,839.382446 1427.881104,840.783691 1427.043823,841.883179 
	C1426.568970,836.989868 1426.284546,832.448792 1426.000000,827.907654 
	C1426.000000,827.907654 1425.979492,827.461853 1426.337158,827.190552 
	C1427.121704,826.159485 1427.548462,825.399780 1427.975098,824.640015 
	C1428.413940,824.652405 1428.852661,824.664795 1429.619873,824.658020 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1251.899048,804.100403 
	C1252.212036,803.633362 1252.525024,803.166321 1252.953125,802.442566 
	C1255.697510,805.163940 1258.326538,808.142029 1261.087769,811.434082 
	C1261.187134,812.069153 1261.154419,812.390320 1261.021973,813.186646 
	C1259.772339,813.265198 1258.645996,812.698547 1257.463867,812.534851 
	C1256.735962,812.434082 1255.922852,812.948730 1255.048584,813.574341 
	C1254.675293,813.763733 1254.401733,813.565796 1254.069092,812.707275 
	C1253.111206,810.123535 1255.734741,806.972595 1251.812866,805.744324 
	C1251.766846,804.983398 1251.832886,804.541931 1251.899048,804.100403 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1411.896362,789.151062 
	C1411.887085,790.168213 1411.877808,791.185364 1411.917114,792.581665 
	C1409.943359,792.959045 1407.920898,792.957214 1405.123413,793.009583 
	C1402.520264,791.700928 1400.692383,790.338135 1397.990234,788.323486 
	C1400.522949,788.076233 1401.783325,787.953186 1403.428711,787.863342 
	C1405.761108,787.705627 1407.717896,787.323608 1409.651611,787.417847 
	C1410.426270,787.455627 1411.149658,788.541992 1411.896362,789.151062 
M1405.607422,791.603516 
	C1405.607422,791.603516 1405.501953,791.697449 1405.607422,791.603516 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1193.978271,845.644714 
	C1191.774902,845.456360 1189.612183,844.934082 1187.021851,844.308594 
	C1187.043823,844.280823 1186.516113,844.947510 1185.007690,846.853088 
	C1185.007690,843.248474 1185.007690,841.058594 1185.014038,838.438354 
	C1186.963867,837.681641 1188.764526,841.070923 1190.804688,838.230408 
	C1191.173462,839.235779 1191.542236,840.241211 1191.951416,841.963745 
	C1192.680908,843.109985 1193.370239,843.539001 1194.059448,843.968079 
	C1194.046021,844.415649 1194.032471,844.863281 1193.978271,845.644714 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1600.037598,782.929260 
	C1597.724731,781.507690 1597.123047,773.309998 1598.982666,772.445129 
	C1599.657593,772.131287 1601.070801,772.373352 1601.576050,772.904724 
	C1603.779541,775.223083 1603.105957,780.274048 1600.037598,782.929260 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1663.347656,790.777466 
	C1661.591919,790.628601 1659.836060,790.479675 1657.257935,790.261047 
	C1658.670898,788.718994 1659.637329,787.664246 1660.767090,786.431335 
	C1659.895386,785.704773 1659.089478,785.033264 1658.283691,784.361694 
	C1658.329346,783.865784 1658.374878,783.369934 1658.420532,782.874084 
	C1660.266724,783.053528 1662.113037,783.233032 1664.130493,784.009888 
	C1663.983643,786.664001 1663.665649,788.720703 1663.347656,790.777466 
M1661.987549,785.534790 
	C1661.694702,785.504150 1661.401978,785.473511 1661.109131,785.442810 
	C1661.239868,785.632141 1661.370483,785.821472 1661.987549,785.534790 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1578.548096,824.334595 
	C1567.932983,824.555115 1557.317993,824.775513 1546.202271,824.979858 
	C1549.819946,824.328552 1553.938232,823.693359 1558.589844,823.237061 
	C1559.456787,823.296204 1559.790161,823.176392 1560.123657,823.056580 
	C1565.470825,823.024536 1570.817871,822.992554 1576.818359,822.972412 
	C1577.471558,822.984192 1577.949097,822.960022 1578.032471,823.198608 
	C1578.259888,823.736389 1578.404053,824.035522 1578.548096,824.334595 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1702.007324,822.881897 
	C1695.635864,822.951965 1689.264282,823.022034 1682.447632,823.070435 
	C1682.030029,822.464417 1682.057617,821.880066 1682.141602,820.858276 
	C1685.333862,822.057312 1688.405762,818.968567 1691.944824,820.667419 
	C1694.420654,821.855835 1697.980713,820.785706 1701.500977,821.041870 
	C1701.966064,821.867004 1701.986694,822.374451 1702.007324,822.881897 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1600.508301,755.263428 
	C1601.000854,759.299622 1601.599609,763.328064 1601.893311,767.378662 
	C1601.950073,768.160217 1600.812622,769.028381 1600.225952,769.856628 
	C1599.496948,769.177917 1598.084717,768.424744 1598.149292,767.832458 
	C1598.579956,763.881775 1599.325928,759.965393 1600.175537,755.697876 
	C1600.381592,755.357910 1600.508301,755.263428 1600.508301,755.263428 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1725.918701,821.333801 
	C1726.306885,821.029968 1726.492920,821.014221 1727.251953,820.927490 
	C1728.204468,820.734375 1728.583984,820.612305 1729.020752,820.859802 
	C1729.346680,822.437012 1729.615112,823.644714 1729.883667,824.852356 
	C1728.355347,825.748962 1726.827148,826.645569 1724.823975,827.848267 
	C1723.898560,828.769226 1723.447754,829.384094 1722.997070,829.998901 
	C1722.251831,830.087036 1721.506470,830.175171 1720.286377,829.841187 
	C1719.754395,829.169067 1719.697510,828.919067 1719.640503,828.669128 
	C1721.665771,826.320068 1723.691162,823.970947 1725.918701,821.333801 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1394.289673,826.822998 
	C1394.175171,827.264526 1394.060791,827.705994 1393.650635,828.810547 
	C1393.153564,832.977478 1392.952148,836.481323 1392.750854,839.985229 
	C1392.082397,839.968872 1391.413940,839.952515 1390.745483,839.936157 
	C1390.745483,836.343872 1390.745483,832.751587 1390.786987,828.855835 
	C1390.833252,828.348328 1390.837891,828.144226 1390.930664,827.627930 
	C1390.981079,826.889038 1390.943604,826.462463 1390.906006,826.035828 
	C1390.896362,825.841248 1390.886841,825.646667 1390.835693,825.162720 
	C1391.081787,822.562317 1391.369263,820.251282 1391.923828,817.695679 
	C1392.378662,817.568054 1392.566162,817.685059 1392.976074,818.422485 
	C1393.525757,821.157288 1393.853027,823.271667 1394.194824,825.746338 
	C1394.236084,826.345276 1394.262939,826.584106 1394.289673,826.822998 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1707.071289,807.998291 
	C1705.648071,808.339783 1704.228271,808.938721 1702.800903,808.957275 
	C1701.044800,808.980103 1699.282471,808.518799 1697.522827,808.266724 
	C1698.170776,806.436340 1698.642090,804.509705 1699.559570,802.826294 
	C1699.894165,802.212341 1701.290405,802.177124 1702.584473,802.292114 
	C1701.437866,803.622437 1699.909058,804.538452 1698.380127,805.454529 
	C1698.830566,805.988220 1699.281006,806.521973 1699.731323,807.055664 
	C1701.568604,806.705688 1703.405884,806.355713 1705.496582,805.982056 
	C1706.162476,806.213135 1706.575073,806.467773 1707.005615,807.041626 
	C1707.039551,807.573303 1707.055420,807.785828 1707.071289,807.998291 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1706.987671,806.722473 
	C1706.575073,806.467773 1706.162476,806.213135 1705.460693,805.606995 
	C1705.122437,804.504822 1705.073242,803.754089 1705.024048,803.003418 
	C1707.145386,802.553406 1709.266602,802.103455 1711.454834,801.639282 
	C1711.773682,804.364502 1712.004761,806.339172 1712.278320,808.676819 
	C1711.334595,808.347290 1710.352783,808.004395 1708.825684,807.420471 
	C1707.849487,807.027100 1707.418579,806.874817 1706.987671,806.722473 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1593.063965,822.304199 
	C1592.600342,822.703369 1591.818848,823.145874 1591.136841,823.025452 
	C1589.662842,822.765137 1588.239746,822.216309 1586.125610,821.585571 
	C1586.125610,821.154175 1586.125610,819.512512 1586.156494,817.484070 
	C1588.228760,817.097290 1590.270386,817.097290 1592.076538,817.097290 
	C1592.347900,818.992981 1592.569580,820.542664 1593.063965,822.304199 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1626.506348,821.984497 
	C1626.878296,822.611633 1627.023193,823.304749 1626.875977,823.929077 
	C1626.410889,825.901062 1625.836182,827.847290 1625.303955,829.803589 
	C1623.101807,828.975342 1620.899658,828.147156 1617.487427,826.863770 
	C1620.229614,825.304077 1621.521240,824.569458 1622.882568,823.483032 
	C1624.016602,822.760498 1625.081055,822.389709 1626.506348,821.984497 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1656.598145,776.016479 
	C1656.318237,775.170471 1656.395386,774.893738 1657.097168,774.455078 
	C1657.926147,773.586731 1658.247559,772.911072 1658.630859,772.182617 
	C1658.692749,772.129822 1658.782959,771.897339 1658.782959,771.897339 
	C1660.296509,772.033691 1661.809937,772.170044 1663.148804,772.798096 
	C1661.689087,774.209839 1660.403931,775.130005 1659.118896,776.050110 
	C1659.322388,776.397827 1659.525879,776.745483 1659.729370,777.093140 
	C1661.381958,776.804443 1663.034546,776.515747 1665.036499,776.166016 
	C1664.731689,777.178345 1664.509277,777.916870 1664.286865,778.655457 
	C1661.305542,779.722900 1658.480713,780.235352 1656.598145,776.016479 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1652.060181,838.901489 
	C1651.868408,837.544800 1652.118164,836.227539 1652.337158,834.548767 
	C1654.544922,834.676575 1656.783447,835.165894 1659.374023,835.732117 
	C1659.592285,834.539734 1659.803589,833.385437 1660.002197,832.300537 
	C1660.416260,832.300537 1660.727295,832.300537 1661.038330,832.300537 
	C1660.951294,834.814453 1660.864380,837.328369 1660.388672,839.921509 
	C1660.000000,840.000732 1659.981201,839.985229 1659.981201,839.985229 
	C1657.488037,839.637146 1654.994873,839.289001 1652.060181,838.901489 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1447.836182,829.104126 
	C1447.877319,827.685974 1447.918457,826.267761 1447.979736,824.424072 
	C1450.357788,824.007568 1452.715698,824.016602 1455.511963,824.047607 
	C1454.161621,824.883118 1452.372681,825.696594 1450.079102,826.739563 
	C1451.654297,826.988525 1453.166138,827.099670 1454.576416,827.537415 
	C1454.944336,827.651489 1454.996216,828.783386 1455.054199,830.021240 
	C1454.516724,830.988892 1454.118530,831.382996 1453.558838,831.672607 
	C1453.198486,831.383240 1453.056641,831.161682 1452.824951,830.625488 
	C1451.240356,830.987915 1449.802490,831.628296 1448.283447,831.875244 
	C1448.080200,830.689270 1447.958130,829.896667 1447.836182,829.104126 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1396.232056,809.054321 
	C1395.999146,809.718079 1395.609253,810.080383 1394.881104,810.704834 
	C1394.542969,810.966919 1394.212769,810.875061 1394.212769,810.875061 
	C1394.103149,807.045044 1392.057373,805.785583 1388.376831,806.025635 
	C1384.912354,806.251648 1381.416626,805.998962 1377.465088,805.624756 
	C1377.034302,804.820618 1377.072998,804.348572 1377.111572,803.876526 
	C1380.861816,803.884277 1384.648071,804.219543 1388.352661,803.812683 
	C1392.508789,803.356323 1394.826172,805.034058 1396.232056,809.054321 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1594.025146,746.193237 
	C1594.030029,746.508545 1594.040894,746.823608 1594.057129,747.374390 
	C1587.008423,747.770203 1579.960327,747.930054 1572.449463,748.003540 
	C1571.981812,747.594543 1571.971924,747.272095 1572.423950,746.590576 
	C1579.935669,746.218628 1586.980347,746.205933 1594.025146,746.193237 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1207.156982,859.885620 
	C1209.014526,859.885620 1210.872070,859.885620 1213.177979,859.885620 
	C1213.177979,861.171326 1213.177979,863.185791 1213.177979,864.374512 
	C1210.891602,864.678772 1208.904907,865.008850 1206.905396,865.137512 
	C1206.609375,865.156555 1206.252075,864.223633 1205.978271,863.019897 
	C1206.408081,861.501038 1206.782593,860.693359 1207.156982,859.885620 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1712.837891,820.530762 
	C1712.536499,820.808838 1712.511597,820.870972 1712.474121,820.500000 
	C1712.236694,819.086365 1712.036865,818.043701 1711.867188,816.618774 
	C1711.765625,815.484680 1711.634155,814.732971 1711.502563,813.981201 
	C1712.969971,814.307251 1714.437378,814.633240 1716.515381,815.094910 
	C1717.060059,814.718018 1718.237061,813.903625 1719.867310,812.775574 
	C1719.867310,815.224365 1719.867310,817.124817 1719.867310,819.037598 
	C1718.485474,818.651794 1717.136475,818.275085 1715.112915,817.930176 
	C1714.336792,818.300842 1714.234985,818.639771 1714.133179,818.978638 
	C1713.801880,819.403259 1713.470581,819.827942 1712.837891,820.530762 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1372.619019,808.652405 
	C1371.066162,809.080750 1369.513428,809.509033 1366.989624,810.205200 
	C1367.595825,808.242554 1367.969360,807.033142 1368.438110,805.515198 
	C1367.163696,805.200012 1365.825806,804.869141 1364.271362,804.484680 
	C1364.319458,803.716614 1364.335815,802.574707 1364.399048,802.572205 
	C1366.706299,802.477051 1369.018066,802.487854 1371.434570,803.170959 
	C1371.899658,805.456055 1372.259277,807.054199 1372.619019,808.652405 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1661.435303,806.770752 
	C1661.071167,806.580750 1661.092651,806.579895 1660.985840,806.128601 
	C1661.090210,804.839355 1661.301514,804.001465 1661.512817,803.163574 
	C1663.201782,802.697693 1664.890747,802.231812 1666.579834,801.765869 
	C1666.784912,803.056335 1667.468872,805.294983 1667.113159,805.474609 
	C1665.502319,806.287842 1663.597900,806.519653 1661.435303,806.770752 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1403.043823,787.830139 
	C1401.783325,787.953186 1400.522949,788.076233 1397.990234,788.323486 
	C1400.692383,790.338135 1402.520264,791.700928 1404.708252,793.154358 
	C1402.964355,793.498291 1400.860229,793.751709 1398.243164,793.623657 
	C1397.487427,791.159119 1397.244873,789.076111 1397.236450,786.703003 
	C1399.328369,786.885315 1401.186035,787.357727 1403.043823,787.830139 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1663.856689,790.842407 
	C1663.665649,788.720703 1663.983643,786.664001 1664.340698,784.304810 
	C1665.680054,783.345642 1666.980469,782.688965 1668.746216,782.057617 
	C1667.940796,784.685913 1668.011963,786.752136 1670.370239,787.190735 
	C1669.968018,788.174133 1669.529907,789.245361 1669.280762,790.581543 
	C1667.768311,790.866821 1666.067139,790.887085 1663.856689,790.842407 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1578.878052,824.449158 
	C1578.404053,824.035522 1578.259888,823.736389 1577.996216,822.945007 
	C1577.599487,822.264465 1577.322266,822.076111 1577.045044,821.887817 
	C1579.063721,820.218445 1581.082397,818.549011 1583.386841,817.297119 
	C1582.300537,818.831055 1580.928589,819.947510 1579.556519,821.063965 
	C1579.798584,821.492676 1580.040649,821.921387 1580.282837,822.350037 
	C1581.518433,821.880798 1582.754150,821.411499 1583.989868,820.942261 
	C1584.760010,824.221680 1584.586670,824.352905 1578.878052,824.449158 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1528.000977,792.062622 
	C1528.020996,790.428528 1528.026123,788.890137 1528.453613,786.915161 
	C1530.464722,786.683350 1532.064819,786.825623 1533.637085,787.118591 
	C1534.454224,787.270874 1535.221558,787.689331 1536.011475,787.987549 
	C1536.010498,789.046631 1536.009521,790.105774 1535.669922,791.607544 
	C1534.886230,792.115845 1534.441162,792.181519 1533.996094,792.247192 
	C1532.921753,792.179810 1531.847290,792.112427 1530.040771,792.141724 
	C1528.841431,792.270569 1528.374146,792.302856 1527.906982,792.335205 
	C1527.906982,792.335144 1527.985962,792.158264 1528.000977,792.062622 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1266.870972,826.657532 
	C1254.959229,826.847656 1242.755493,826.710205 1230.254150,826.272339 
	C1242.163940,826.091309 1254.371582,826.210632 1266.870972,826.657532 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1372.912842,808.734985 
	C1372.259277,807.054199 1371.899658,805.456055 1371.669800,803.586182 
	C1373.440308,803.305176 1375.081177,803.295837 1376.916748,803.581543 
	C1377.072998,804.348572 1377.034302,804.820618 1376.998535,805.647827 
	C1375.736450,806.941162 1374.471436,807.879395 1372.912842,808.734985 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1673.292358,777.015259 
	C1673.318726,776.196838 1673.723633,775.427368 1674.190918,774.326416 
	C1676.932739,773.866333 1679.612305,773.737854 1682.673706,773.591125 
	C1682.673706,774.385620 1682.673706,776.100220 1682.673706,778.204712 
	C1679.491577,777.801636 1676.581299,777.432983 1673.292358,777.015259 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1598.020996,789.842407 
	C1598.858398,788.077454 1599.712036,786.710876 1600.565674,785.344299 
	C1601.365723,786.176147 1602.839478,787.021423 1602.823242,787.836914 
	C1602.790894,789.450134 1601.935059,791.046814 1601.419678,792.650391 
	C1600.292358,791.847168 1599.164795,791.043945 1598.020996,789.842407 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1719.306885,828.752136 
	C1719.697510,828.919067 1719.754395,829.169067 1719.910767,829.790405 
	C1717.149170,832.616760 1714.288208,835.071777 1710.881104,837.865479 
	C1709.319458,838.900085 1708.303711,839.596069 1707.287964,840.292053 
	C1707.133301,840.121094 1706.978516,839.950195 1706.621948,839.495178 
	C1706.767700,838.869629 1707.115479,838.528137 1707.665771,838.044312 
	C1709.198364,836.823120 1710.528198,835.744202 1711.948853,834.428711 
	C1714.350830,832.406555 1716.662109,830.620850 1719.306885,828.752136 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1730.237305,824.794312 
	C1729.615112,823.644714 1729.346680,822.437012 1729.085449,820.561157 
	C1730.990112,817.921265 1732.887573,815.949646 1734.785156,813.978027 
	C1735.079834,814.207153 1735.374634,814.436340 1735.671997,815.172485 
	C1734.051880,817.551819 1732.429077,819.424194 1730.628540,821.501648 
	C1732.222168,822.062805 1733.114258,822.376892 1734.006226,822.690979 
	C1733.999023,822.920410 1733.991943,823.149780 1733.994385,823.723389 
	C1732.866455,824.290527 1731.728638,824.513428 1730.237305,824.794312 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1677.728760,848.405640 
	C1676.086182,847.310608 1674.706787,846.214783 1673.153809,844.900574 
	C1673.550659,844.476196 1674.157959,844.027100 1674.685059,844.107056 
	C1676.767334,844.422852 1678.824585,844.904419 1681.589233,845.466370 
	C1681.529419,846.172424 1681.375122,848.048340 1681.195801,849.921875 
	C1681.188232,850.001099 1680.888672,850.149231 1680.830078,850.109680 
	C1680.585449,849.944763 1680.381226,849.720337 1680.060547,848.950562 
	C1679.649780,848.326721 1679.340454,848.270020 1679.031250,848.213379 
	C1678.684814,848.277161 1678.338501,848.340942 1677.728760,848.405640 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1206.979370,843.923157 
	C1208.207886,844.088440 1209.436279,844.253662 1210.664795,844.418884 
	C1210.649658,844.774231 1210.634521,845.129517 1210.619385,845.484863 
	C1207.302734,845.484863 1203.986084,845.484863 1200.669434,845.484863 
	C1200.601074,845.252136 1200.532837,845.019409 1200.464478,844.786682 
	C1201.067993,844.513489 1201.671631,844.240234 1202.693359,844.127869 
	C1203.202637,844.342224 1203.293823,844.395752 1203.384888,844.449280 
	C1203.250122,844.303955 1203.115356,844.158691 1202.980591,844.013367 
	C1203.283691,842.006287 1203.586670,839.999207 1203.889771,837.992126 
	C1204.169434,837.972168 1204.449097,837.952148 1204.728760,837.932190 
	C1204.875732,839.366150 1205.022583,840.800110 1205.309326,842.872314 
	C1205.959229,843.648132 1206.469238,843.785645 1206.979370,843.923157 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1707.212036,840.497070 
	C1708.303711,839.596069 1709.319458,838.900085 1710.626465,838.080566 
	C1710.917725,841.675293 1710.917725,845.393555 1710.917725,849.111816 
	C1710.196777,849.137695 1709.475708,849.163513 1708.754761,849.189392 
	C1708.402954,846.671936 1708.051147,844.154480 1707.394165,841.386841 
	C1707.088989,841.136719 1707.136108,840.702026 1707.212036,840.497070 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1658.857788,771.344238 
	C1658.782959,771.897339 1658.692749,772.129822 1657.856934,771.986938 
	C1656.503784,771.737000 1655.986328,771.629883 1655.468872,771.522766 
	C1656.009399,770.820435 1656.987915,770.135193 1657.016113,769.412903 
	C1657.152588,765.913269 1657.034302,762.403564 1656.926636,758.477173 
	C1657.565796,759.237000 1658.788940,760.370361 1658.903564,761.606506 
	C1659.185181,764.642822 1658.956177,767.726440 1658.857788,771.344238 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1199.862549,816.719910 
	C1198.040527,816.395325 1196.218628,816.070740 1193.295288,815.549988 
	C1195.572754,813.424011 1197.227417,811.879395 1199.258789,810.609497 
	C1199.711060,812.829468 1199.786743,814.774719 1199.862549,816.719910 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1475.370117,808.163086 
	C1476.807373,810.104004 1477.771362,811.997803 1478.768066,813.955994 
	C1478.541748,814.060608 1477.352539,814.610718 1476.115723,815.519592 
	C1475.221680,816.059753 1474.375122,816.241089 1473.528564,816.422363 
	C1473.344849,813.548157 1471.199219,810.350708 1475.370117,808.163086 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1345.887207,750.632202 
	C1348.384155,749.965271 1350.895508,749.684753 1353.842285,749.512329 
	C1352.073853,754.013367 1349.247559,754.485168 1345.887207,750.632202 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1198.958252,841.863403 
	C1198.466675,843.388123 1197.992310,844.522339 1197.518066,845.656494 
	C1197.189575,845.636353 1196.861084,845.616211 1196.532593,845.596069 
	C1196.135010,843.002563 1195.737549,840.408997 1195.277954,837.410767 
	C1196.763916,837.410767 1198.105957,837.410767 1199.675049,837.410767 
	C1199.409302,838.954346 1199.192505,840.213623 1198.958252,841.863403 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1651.826660,747.560181 
	C1652.650269,746.940369 1653.618408,746.725708 1655.050781,746.169128 
	C1655.675415,745.875366 1655.835449,745.923584 1655.994873,746.232483 
	C1655.997559,746.667053 1656.000610,746.840881 1656.003540,747.014648 
	C1656.003540,747.014648 1655.952637,747.290833 1655.584717,747.555542 
	C1655.030884,748.239502 1654.844971,748.658691 1654.658936,749.077881 
	C1654.459961,751.000122 1654.260864,752.922424 1654.061890,754.844666 
	C1653.572388,754.827271 1653.082886,754.809937 1652.593384,754.792542 
	C1652.385986,752.516846 1652.178589,750.241089 1651.826660,747.560181 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1493.765869,802.731506 
	C1493.382324,801.784363 1493.104248,801.223633 1492.663330,800.468506 
	C1493.398315,799.214111 1494.296143,798.154297 1496.186768,795.922424 
	C1496.186768,799.871033 1496.186768,802.528870 1496.186768,806.629211 
	C1494.930420,804.723694 1494.401001,803.920837 1493.765869,802.731506 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1652.839600,785.389832 
	C1656.394409,784.654541 1656.197144,787.012024 1655.903809,788.883667 
	C1655.712769,790.102783 1654.639893,791.183777 1653.962036,792.326660 
	C1653.486328,792.095154 1653.010498,791.863647 1652.534790,791.632202 
	C1652.534790,789.623718 1652.534790,787.615234 1652.839600,785.389832 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1669.206177,843.165405 
	C1668.715332,848.166260 1665.046265,848.759705 1661.328613,849.276245 
	C1661.055298,848.901794 1660.781982,848.527283 1660.508545,848.152771 
	C1661.878662,847.020630 1663.248901,845.888489 1664.691895,844.422852 
	C1666.104614,843.766907 1667.444336,843.444458 1669.206177,843.165405 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1173.898804,863.945618 
	C1176.471191,864.422058 1179.043457,864.898438 1181.797852,865.664795 
	C1181.669312,866.112610 1181.358765,866.270569 1180.592041,866.661865 
	C1178.756958,866.953857 1177.378052,867.012573 1175.999268,867.071289 
	C1174.662231,866.117676 1171.478882,868.997803 1172.193115,864.662598 
	C1173.140625,864.222229 1173.519653,864.083923 1173.898804,863.945618 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1394.699463,826.868591 
	C1394.262939,826.584106 1394.236084,826.345276 1394.273438,825.560974 
	C1394.556641,824.666443 1394.775757,824.317383 1394.994751,823.968384 
	C1397.107544,824.075806 1399.220337,824.183228 1401.634033,824.515259 
	C1400.716797,828.783081 1397.400635,826.240051 1394.699463,826.868591 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1200.050293,817.020020 
	C1199.786743,814.774719 1199.711060,812.829468 1199.555786,810.528687 
	C1200.574097,810.353271 1201.672119,810.533447 1203.031982,811.062744 
	C1203.318237,812.944275 1203.342529,814.476562 1203.278809,816.377808 
	C1202.206421,816.937866 1201.222290,817.129028 1200.050293,817.020020 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1735.735352,824.756653 
	C1736.577759,824.140198 1737.684937,823.785095 1739.030640,823.063110 
	C1740.753296,822.999512 1742.237305,823.302734 1743.721313,823.606018 
	C1743.667236,824.226440 1743.613281,824.846924 1743.559204,825.467407 
	C1741.039429,825.317566 1738.519653,825.167786 1735.735352,824.756653 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1664.676636,778.765991 
	C1664.509277,777.916870 1664.731689,777.178345 1665.036499,776.166016 
	C1663.034546,776.515747 1661.381958,776.804443 1659.729370,777.093140 
	C1659.525879,776.745483 1659.322388,776.397827 1659.118896,776.050110 
	C1660.403931,775.130005 1661.689087,774.209839 1663.332520,773.147461 
	C1664.172241,773.000061 1664.653564,772.994934 1665.377930,772.988403 
	C1665.693359,774.703247 1665.765991,776.419495 1665.853027,778.567383 
	C1665.600464,778.958191 1665.333374,778.917419 1664.676636,778.765991 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1463.225464,811.997070 
	C1464.597656,812.365967 1465.969971,812.734863 1467.671875,813.112488 
	C1465.362549,816.846313 1461.777588,814.508179 1458.296387,814.013550 
	C1458.012451,813.240356 1458.002686,813.046448 1458.379639,812.853271 
	C1460.252686,812.568359 1461.739014,812.282715 1463.225464,811.997070 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1175.999634,867.480347 
	C1177.378052,867.012573 1178.756958,866.953857 1180.461670,866.889099 
	C1181.189087,867.713440 1181.590576,868.543762 1181.994141,869.746155 
	C1180.292236,870.224487 1178.588501,870.330688 1176.460449,870.398438 
	C1176.024292,869.536377 1176.012085,868.712891 1175.999634,867.480347 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1177.844238,861.631104 
	C1177.187256,861.961487 1176.587891,861.958984 1175.486572,861.714172 
	C1173.992920,861.556702 1173.001221,861.641602 1172.009766,861.726440 
	C1172.140503,860.874756 1172.271240,860.023010 1172.401855,859.171265 
	C1174.235229,859.880249 1176.068604,860.589294 1177.844238,861.631104 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1220.770752,810.057129 
	C1221.229370,814.335876 1218.921997,815.788452 1215.239258,815.591064 
	C1214.681763,815.017212 1214.124146,814.443420 1213.566528,813.869568 
	C1215.275146,812.845703 1216.983765,811.821777 1218.669556,810.464233 
	C1219.213135,810.100220 1219.779541,810.069824 1220.770752,810.057129 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1201.351807,862.888062 
	C1200.457153,862.900879 1199.941895,862.877869 1199.164307,862.526489 
	C1198.928589,861.129944 1198.955322,860.061890 1198.982056,858.993774 
	C1200.383423,858.921448 1201.784668,858.849121 1203.590576,859.214722 
	C1203.957275,860.504333 1203.919434,861.355957 1203.881592,862.207581 
	C1203.164795,862.422424 1202.447998,862.637329 1201.351807,862.888062 
M1201.429077,860.539734 
	C1201.206787,860.486084 1200.984619,860.432434 1200.762329,860.378784 
	C1200.904907,860.513489 1201.047607,860.648193 1201.429077,860.539734 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1644.734375,833.014404 
	C1645.299438,837.367737 1642.870239,838.325134 1638.156128,837.729004 
	C1640.175537,836.039429 1641.571533,834.871338 1643.035400,833.350037 
	C1643.538208,833.000000 1643.973145,833.003235 1644.734375,833.014404 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1705.435303,747.148438 
	C1705.866089,748.196899 1705.911133,749.208435 1705.902222,750.596191 
	C1704.929443,750.924866 1704.010620,750.877197 1702.790283,750.848755 
	C1701.806641,749.869629 1701.124512,748.871277 1700.074585,747.334595 
	C1701.955078,747.250244 1703.502197,747.180908 1705.435303,747.148438 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1198.629395,858.996826 
	C1198.955322,860.061890 1198.928589,861.129944 1198.942627,862.597168 
	C1197.170044,863.441589 1195.356812,863.886841 1193.543457,864.332092 
	C1193.010376,863.701660 1192.477173,863.071167 1191.943970,862.440674 
	C1193.561768,861.533325 1195.179565,860.625977 1196.831787,859.384399 
	C1197.336426,859.033386 1197.806519,859.016663 1198.629395,858.996826 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1397.002319,786.993042 
	C1397.244873,789.076111 1397.487427,791.159119 1397.848389,793.611694 
	C1396.932251,793.806030 1395.897827,793.630737 1394.502930,793.281006 
	C1394.350830,792.067993 1394.559082,791.029541 1394.601807,789.717896 
	C1395.291748,788.627502 1396.146973,787.810242 1397.002319,786.993042 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1499.062256,808.147339 
	C1499.244019,811.835938 1497.509033,814.003418 1492.828979,814.184875 
	C1494.384644,812.021545 1495.525635,810.434937 1496.530029,808.523804 
	C1497.105591,808.165771 1497.817749,808.132080 1499.062256,808.147339 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1394.996826,823.528259 
	C1394.775757,824.317383 1394.556641,824.666443 1394.259033,825.200806 
	C1393.853027,823.271667 1393.525757,821.157288 1393.225586,818.640808 
	C1393.632446,818.113831 1394.012085,817.988953 1394.683228,817.791992 
	C1394.982788,819.509277 1394.990723,821.298706 1394.996826,823.528259 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1664.059082,745.170166 
	C1664.054565,745.816040 1664.050049,746.461853 1664.069458,747.429565 
	C1662.614014,747.607971 1661.134399,747.464417 1659.327515,746.896973 
	C1658.995850,746.287659 1658.991699,746.102295 1659.313599,745.761108 
	C1661.112915,745.460266 1662.586060,745.315186 1664.059082,745.170166 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1219.898682,799.127197 
	C1220.241211,803.028625 1218.101685,804.335266 1213.380615,804.188660 
	C1215.637939,802.230774 1217.037354,801.017029 1218.434326,799.495483 
	C1218.757568,799.163391 1219.083252,799.138977 1219.898682,799.127197 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1480.282471,798.052612 
	C1480.857788,802.141235 1478.208618,802.733154 1475.466797,803.216980 
	C1475.145874,802.834534 1474.824829,802.452087 1474.503906,802.069641 
	C1475.733521,801.112183 1476.963257,800.154663 1478.558594,798.932739 
	C1479.300415,798.462219 1479.676514,798.256104 1480.282471,798.052612 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1600.444824,755.096680 
	C1600.508301,755.263428 1600.381592,755.357910 1600.334473,755.290283 
	C1599.766846,753.374634 1599.246216,751.526550 1598.718384,749.284668 
	C1598.921143,748.888794 1599.130615,748.897705 1599.386230,749.329712 
	C1599.796021,751.237732 1600.159180,752.733582 1600.522217,754.229492 
	C1600.373169,754.442688 1600.326294,754.676208 1600.444824,755.096680 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1704.758301,802.895508 
	C1705.073242,803.754089 1705.122437,804.504822 1705.207275,805.630615 
	C1703.405884,806.355713 1701.568604,806.705688 1699.731323,807.055664 
	C1699.281006,806.521973 1698.830566,805.988220 1698.380127,805.454529 
	C1699.909058,804.538452 1701.437866,803.622437 1702.965088,802.357605 
	C1703.473145,802.268433 1703.982910,802.528015 1704.758301,802.895508 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1584.139526,820.630371 
	C1582.754150,821.411499 1581.518433,821.880798 1580.282837,822.350037 
	C1580.040649,821.921387 1579.798584,821.492676 1579.556519,821.063965 
	C1580.928589,819.947510 1582.300537,818.831055 1583.753418,817.388489 
	C1584.031738,817.049683 1584.229248,817.036987 1584.723877,817.016479 
	C1584.776978,818.111877 1584.533081,819.215210 1584.139526,820.630371 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1642.746582,747.517334 
	C1643.846802,747.125977 1645.080200,747.069519 1646.773926,747.063232 
	C1646.176636,748.360596 1645.118896,749.607727 1643.403076,751.630798 
	C1643.094604,749.403992 1642.987061,748.628052 1642.746582,747.517334 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1600.881836,754.002686 
	C1600.159180,752.733582 1599.796021,751.237732 1599.543457,749.350281 
	C1600.299927,748.620728 1600.945679,748.282715 1601.781494,748.049377 
	C1601.728149,750.028015 1601.484741,751.901978 1600.881836,754.002686 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1711.995361,856.471069 
	C1711.211060,856.536377 1710.416382,856.258484 1709.307129,855.866333 
	C1708.786011,854.712219 1708.579712,853.672363 1708.373291,852.632446 
	C1708.667358,852.474060 1708.961426,852.315674 1709.255493,852.157288 
	C1710.165283,853.480835 1711.075195,854.804321 1711.995361,856.471069 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1466.554077,823.986450 
	C1463.876221,824.198669 1460.744507,824.406494 1457.346069,824.337158 
	C1460.086304,824.034058 1463.093262,824.008057 1466.554077,823.986450 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1630.768066,850.083252 
	C1630.101318,849.313477 1629.834961,848.498840 1629.625122,847.355957 
	C1630.933105,847.119812 1632.184692,847.211914 1635.098633,847.426392 
	C1632.958984,848.848450 1632.063721,849.443481 1630.768066,850.083252 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1468.993652,803.954224 
	C1468.403442,803.265991 1468.328735,802.597168 1468.276123,801.590271 
	C1469.343140,801.192078 1470.388184,801.131958 1471.959473,801.115784 
	C1472.497925,801.819214 1472.510010,802.478699 1472.559570,803.468994 
	C1471.567871,803.857727 1470.538696,803.915649 1468.993652,803.954224 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1204.233887,862.342773 
	C1203.919434,861.355957 1203.957275,860.504333 1203.997559,859.328369 
	C1204.912354,859.112549 1205.824829,859.220947 1206.947266,859.607483 
	C1206.782593,860.693359 1206.408081,861.501038 1206.008545,862.671021 
	C1205.517822,862.848267 1205.052002,862.663147 1204.233887,862.342773 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1659.833984,857.797974 
	C1660.068726,858.412598 1660.101807,858.750244 1659.975830,859.666992 
	C1659.146606,860.496521 1658.476318,860.747009 1657.805908,860.997559 
	C1657.701782,859.934143 1657.597656,858.870789 1657.581299,857.415405 
	C1658.323486,857.189270 1658.977783,857.355164 1659.833984,857.797974 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1172.017090,862.198730 
	C1173.001221,861.641602 1173.992920,861.556702 1175.186646,861.718201 
	C1175.005493,862.452087 1174.622192,862.939636 1174.068848,863.686401 
	C1173.519653,864.083923 1173.140625,864.222229 1172.473389,864.456543 
	C1172.131714,863.925293 1172.078125,863.298157 1172.017090,862.198730 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1654.350830,853.733643 
	C1653.083740,853.411438 1652.144531,852.988403 1651.205444,852.565430 
	C1651.264282,852.309082 1651.323120,852.052734 1651.381958,851.796387 
	C1652.618164,851.796387 1653.854248,851.796387 1655.474487,851.711060 
	C1655.902954,852.016296 1655.947510,852.406982 1656.009521,853.090393 
	C1655.577759,853.466431 1655.128174,853.549622 1654.350830,853.733643 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1457.993042,812.852600 
	C1458.002686,813.046448 1458.012451,813.240356 1458.013550,813.725586 
	C1457.179810,813.105042 1456.354736,812.193298 1455.805176,811.128174 
	C1456.080688,810.974792 1456.535522,810.820740 1456.857422,811.010742 
	C1457.450684,811.751404 1457.721802,812.302002 1457.993042,812.852600 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1216.308105,886.946289 
	C1215.981934,886.260437 1216.010498,885.522095 1216.038330,884.414795 
	C1216.466064,884.002197 1216.894653,883.958618 1217.646484,883.915527 
	C1217.534180,884.908630 1217.098511,885.901184 1216.308105,886.946289 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1171.920898,898.245361 
	C1172.942261,898.432495 1173.860962,898.914551 1174.925415,899.684692 
	C1173.986816,899.495178 1172.902588,899.017700 1171.920898,898.245361 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1644.926025,844.955322 
	C1645.087769,845.986877 1644.952881,846.905701 1644.817871,847.824463 
	C1644.518433,847.876648 1644.218994,847.928772 1643.919556,847.980957 
	C1643.599243,846.912842 1643.279053,845.844727 1642.943115,844.397827 
	C1643.494873,844.293579 1644.062012,844.568115 1644.926025,844.955322 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1427.807617,824.484497 
	C1427.548462,825.399780 1427.121704,826.159485 1426.341431,826.967468 
	C1426.008423,826.503174 1426.028931,825.990723 1426.054932,825.090149 
	C1426.587036,824.577698 1427.113525,824.453308 1427.807617,824.484497 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1159.947021,819.637451 
	C1159.115723,819.457214 1158.231445,818.938110 1157.540161,818.178467 
	C1158.453491,818.391479 1159.173828,818.844971 1159.947021,819.637451 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1665.809570,850.887695 
	C1666.311890,850.295410 1667.174927,849.852722 1668.413330,849.381714 
	C1669.091187,851.742432 1668.077148,852.083923 1665.809570,850.887695 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1194.234375,843.778748 
	C1193.370239,843.539001 1192.680908,843.109985 1191.967041,842.342407 
	C1192.764771,842.532349 1193.587158,843.060852 1194.234375,843.778748 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1355.105591,775.913452 
	C1355.491577,776.671570 1355.427368,777.463562 1355.363159,778.255493 
	C1354.635742,777.741455 1353.908325,777.227478 1353.018921,776.384949 
	C1353.456421,776.020020 1354.055786,775.983643 1355.105591,775.913452 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1347.477051,774.354248 
	C1347.580566,775.546021 1347.305664,776.565491 1347.030762,777.584961 
	C1346.774902,777.571533 1346.519043,777.558105 1346.263184,777.544678 
	C1346.218872,776.617676 1346.174561,775.690674 1346.208496,774.382690 
	C1346.557373,774.061707 1346.827881,774.121826 1347.477051,774.354248 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1642.947998,773.935547 
	C1643.105103,773.199341 1643.671387,772.444885 1644.586914,771.593506 
	C1644.409912,772.303467 1643.883667,773.110413 1642.947998,773.935547 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1713.792358,770.033203 
	C1714.364868,769.751160 1715.177368,769.519897 1716.052368,769.622009 
	C1715.845337,769.992371 1715.576050,770.029236 1714.902832,770.151245 
	C1714.499023,770.236389 1714.032471,770.083923 1713.792358,770.033203 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1570.409180,768.957397 
	C1570.100098,768.555847 1570.072998,768.109863 1570.089844,767.330933 
	C1570.618042,767.318481 1571.102417,767.639038 1571.586792,767.959534 
	C1571.288208,768.277344 1570.989624,768.595154 1570.409180,768.957397 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1172.979492,893.054810 
	C1173.026123,893.393311 1172.977539,893.361267 1172.963257,893.380920 
	C1172.477539,892.899475 1172.006104,892.398376 1171.534668,891.897217 
	C1171.782959,891.782043 1172.211060,891.524536 1172.250610,891.574524 
	C1172.523438,891.919250 1172.715210,892.328125 1172.979492,893.054810 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1527.982910,792.594055 
	C1528.374146,792.302856 1528.841431,792.270569 1529.656982,792.286011 
	C1529.356567,792.506775 1528.707764,792.679810 1527.982910,792.594055 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1534.044067,792.531433 
	C1534.441162,792.181519 1534.886230,792.115845 1535.665649,792.005127 
	C1536.000000,791.959961 1536.054443,792.388367 1536.083252,792.601074 
	C1535.438843,792.814453 1534.765381,792.815063 1534.044067,792.531433 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1472.629150,823.960693 
	C1471.811401,824.154358 1470.584229,824.336487 1469.150146,824.239868 
	C1470.035278,823.957092 1471.127686,823.953064 1472.629150,823.960693 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1393.963867,811.019287 
	C1394.212769,810.875061 1394.542969,810.966919 1394.690063,811.054688 
	C1394.826904,811.564087 1394.816650,811.985840 1394.702637,812.706543 
	C1394.304443,812.391541 1394.009766,811.777466 1393.963867,811.019287 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1723.305054,829.965454 
	C1723.447754,829.384094 1723.898560,828.769226 1724.675659,828.160889 
	C1724.539062,828.755615 1724.076050,829.343750 1723.305054,829.965454 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1188.859619,881.861938 
	C1189.284058,881.626038 1190.172852,881.446594 1191.124023,881.598633 
	C1190.565674,881.926208 1189.944702,881.922302 1188.859619,881.861938 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1195.016357,882.175171 
	C1195.946899,881.892273 1196.877441,882.041016 1198.653076,882.324951 
	C1197.246704,883.157654 1196.512939,883.592102 1195.452393,884.177612 
	C1195.089233,883.754761 1195.052856,883.180847 1195.016357,882.175171 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1191.751953,860.026855 
	C1191.334229,860.220581 1190.564453,860.400269 1189.892700,860.289978 
	C1190.460449,860.004272 1190.930054,860.008545 1191.751953,860.026855 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1711.494141,813.982178 
	C1711.634155,814.732971 1711.765625,815.484680 1711.574219,816.587646 
	C1711.058105,816.903381 1710.864990,816.867859 1710.671875,816.832275 
	C1710.801392,816.092834 1710.930786,815.353455 1711.268066,814.298462 
	C1711.475952,813.982910 1711.485596,813.983215 1711.494141,813.982178 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1620.185059,756.148926 
	C1620.338989,757.144836 1620.062866,758.074707 1619.786621,759.004578 
	C1619.546143,758.976379 1619.305542,758.948181 1619.065063,758.919983 
	C1618.943604,758.206421 1618.822144,757.492859 1618.794678,756.389465 
	C1619.177490,756.027344 1619.466309,756.055115 1620.185059,756.148926 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1221.576416,877.048340 
	C1221.982300,877.717041 1221.975098,878.434143 1221.937500,879.508240 
	C1221.441772,879.214111 1220.976440,878.562988 1220.428711,877.582642 
	C1220.618774,877.201233 1220.890991,877.148987 1221.576416,877.048340 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1658.987427,745.916870 
	C1658.991699,746.102295 1658.995850,746.287659 1658.996338,746.751099 
	C1658.243530,747.064941 1657.494629,747.100769 1656.374634,747.075562 
	C1656.000610,746.840881 1655.997559,746.667053 1656.365479,746.161743 
	C1657.486816,745.859131 1658.237061,745.888000 1658.987427,745.916870 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1661.003174,803.142944 
	C1661.301514,804.001465 1661.090210,804.839355 1660.961670,806.109375 
	C1660.631104,805.654602 1660.217773,804.767761 1659.639282,803.522827 
	C1659.813843,803.150574 1660.153564,803.136414 1661.003174,803.142944 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1206.838013,843.710449 
	C1206.469238,843.785645 1205.959229,843.648132 1205.375000,843.249695 
	C1205.766113,843.158447 1206.231323,843.328125 1206.838013,843.710449 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1228.714844,825.933899 
	C1228.463745,826.115540 1227.859985,826.280212 1227.104492,826.204590 
	C1227.422485,825.948547 1227.892334,825.932739 1228.714844,825.933899 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1688.759033,786.857117 
	C1688.804321,786.360474 1689.166504,785.926758 1689.528564,785.493042 
	C1689.623657,785.801147 1689.718750,786.109253 1689.765381,786.711121 
	C1689.503174,786.976685 1689.289551,786.948425 1688.759033,786.857117 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1675.331421,783.313904 
	C1675.477905,783.915771 1675.162231,784.359497 1674.846558,784.803223 
	C1674.500366,784.439636 1674.154175,784.076111 1673.856445,783.355347 
	C1674.226562,783.050598 1674.547852,783.103149 1675.331421,783.313904 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1691.513184,788.745667 
	C1691.439819,788.382568 1691.575684,787.821716 1691.926758,787.366821 
	C1692.002197,787.831238 1691.862427,788.189575 1691.513184,788.745667 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1178.770630,840.013794 
	C1178.843750,840.442688 1178.609741,840.878967 1178.375610,841.315247 
	C1178.228760,841.075134 1178.082031,840.834961 1177.893066,840.330322 
	C1178.054932,840.050964 1178.259277,840.036072 1178.770630,840.013794 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1442.973145,809.771729 
	C1442.417603,809.872131 1441.873291,809.643738 1441.328857,809.415405 
	C1441.662720,809.174438 1441.996582,808.933350 1442.659424,808.738647 
	C1442.987183,809.004395 1442.985718,809.223694 1442.973145,809.771729 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1689.296509,815.083435 
	C1689.597656,815.570862 1689.528320,816.144775 1689.458984,816.718628 
	C1689.101196,816.511536 1688.743408,816.304504 1688.310547,815.793152 
	C1688.465698,815.382507 1688.695923,815.276184 1689.296509,815.083435 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1626.994385,759.243164 
	C1627.302856,759.142212 1627.609619,759.293457 1627.986816,759.711182 
	C1627.877563,759.984558 1627.697876,759.991394 1627.247925,759.998779 
	C1626.982544,759.831299 1626.987671,759.663330 1626.994385,759.243164 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1654.969971,748.923645 
	C1654.844971,748.658691 1655.030884,748.239502 1655.551147,747.691895 
	C1655.683960,747.965454 1655.482544,748.367432 1654.969971,748.923645 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1198.434448,879.067383 
	C1198.434448,879.282166 1198.061035,879.564819 1197.515137,879.613953 
	C1197.582153,879.298706 1197.821655,879.216919 1198.434448,879.067383 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1201.247803,879.978760 
	C1200.927856,879.835022 1200.855103,879.671753 1200.852539,879.249695 
	C1201.236206,879.057495 1201.502930,879.207581 1201.848267,879.636169 
	C1201.974121,879.831055 1201.494995,879.959229 1201.247803,879.978760 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1219.084351,882.547852 
	C1218.981934,882.262634 1219.178955,882.070801 1219.662842,881.934448 
	C1219.794922,882.224365 1219.606201,882.441467 1219.084351,882.547852 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1200.414062,883.877686 
	C1200.081177,883.602051 1200.184814,883.333313 1200.801758,883.172241 
	C1200.936279,883.413391 1200.781494,883.614929 1200.414062,883.877686 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1441.866455,805.762329 
	C1441.639038,805.856384 1441.278198,805.713013 1440.863403,805.268066 
	C1441.117310,805.152649 1441.425049,805.338806 1441.866455,805.762329 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1180.191406,796.915100 
	C1180.033081,796.993958 1179.976929,796.500061 1180.057983,796.265259 
	C1180.276367,796.204346 1180.431641,796.368286 1180.537476,796.559631 
	C1180.561890,796.603760 1180.416748,796.741577 1180.191406,796.915100 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1652.521729,804.023743 
	C1652.513428,804.222839 1652.211182,804.449158 1651.776367,804.408569 
	C1651.838379,804.111511 1652.032959,804.081238 1652.521729,804.023743 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1707.005615,807.041626 
	C1707.418579,806.874817 1707.849487,807.027100 1708.501465,807.446533 
	C1708.449341,807.773376 1708.176147,807.833069 1707.487183,807.945557 
	C1707.055420,807.785828 1707.039551,807.573303 1707.005615,807.041626 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1437.095215,809.101318 
	C1437.342651,809.013550 1437.722168,809.200134 1438.162842,809.716309 
	C1437.891846,809.822571 1437.559448,809.599121 1437.095215,809.101318 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1340.152588,779.895874 
	C1340.051392,779.624695 1340.206909,779.227173 1340.696289,778.828735 
	C1340.823242,779.141724 1340.616211,779.455627 1340.152588,779.895874 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1174.559082,838.982788 
	C1174.536865,839.094849 1174.353271,839.208252 1174.122559,839.147827 
	C1174.075317,838.973938 1174.397827,838.984070 1174.559082,838.982788 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1660.007812,840.010254 
	C1659.912720,840.048279 1659.810059,840.076843 1659.844238,840.045288 
	C1659.981201,839.985229 1660.000000,840.000732 1660.007812,840.010254 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1669.196289,847.969360 
	C1669.209229,847.769287 1669.640015,847.544800 1670.257935,847.581543 
	C1670.168091,847.876892 1669.891113,847.910950 1669.196289,847.969360 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1679.140381,848.389343 
	C1679.340454,848.270020 1679.649780,848.326721 1679.953369,848.688721 
	C1679.672729,848.919983 1679.439941,848.777039 1679.140381,848.389343 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1676.138794,849.909058 
	C1676.052490,849.660156 1676.209351,849.300110 1676.675537,848.939087 
	C1676.783936,849.224731 1676.583008,849.511292 1676.138794,849.909058 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1640.117676,850.225464 
	C1640.338135,850.140076 1640.676392,850.283142 1641.047852,850.720337 
	C1640.799194,850.827637 1640.517212,850.640808 1640.117676,850.225464 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1226.937622,813.910522 
	C1227.022827,813.731934 1227.375000,813.597107 1227.735352,813.734253 
	C1227.563721,813.988831 1227.384155,813.971558 1226.937622,813.910522 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1505.042236,812.945923 
	C1505.272461,812.904602 1505.576660,813.125305 1505.866455,813.661377 
	C1505.606689,813.720520 1505.361450,813.464233 1505.042236,812.945923 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1508.131714,814.904236 
	C1508.038452,814.634705 1508.202148,814.243042 1508.695190,813.855530 
	C1508.812622,814.167114 1508.600586,814.474548 1508.131714,814.904236 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1233.121582,815.260010 
	C1233.345459,815.156250 1233.683472,815.268982 1234.032715,815.694702 
	C1233.716309,815.919189 1233.447021,815.742126 1233.121582,815.260010 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1329.466675,816.448608 
	C1329.523193,816.414612 1329.410156,816.482605 1329.466675,816.448608 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1714.327393,818.867737 
	C1714.234985,818.639771 1714.336792,818.300842 1714.718750,817.987427 
	C1714.918335,818.311279 1714.759155,818.559265 1714.327393,818.867737 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1577.027832,822.147888 
	C1577.322266,822.076111 1577.599487,822.264465 1577.912842,822.706421 
	C1577.949097,822.960022 1577.471558,822.984192 1577.233765,822.956665 
	C1577.000732,822.755371 1577.005615,822.581665 1577.027832,822.147888 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1559.814941,823.044189 
	C1559.790161,823.176392 1559.456787,823.296204 1559.005859,823.233154 
	C1559.094482,823.044128 1559.300293,823.038025 1559.814941,823.044189 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1776.127441,825.213379 
	C1776.372681,825.103943 1776.751709,825.241089 1777.170898,825.706238 
	C1776.894409,825.842834 1776.577759,825.651428 1776.127441,825.213379 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M972.875061,907.258301 
	C972.398254,906.793945 972.341187,906.189575 972.306580,905.128906 
	C983.144592,904.515747 993.965881,904.502563 1004.770020,904.064148 
	C1006.621948,903.989075 1008.714966,902.419800 1010.159851,900.996033 
	C1017.870605,893.398376 1025.420288,885.636902 1033.006348,877.913025 
	C1036.543579,874.311462 1035.777832,868.786255 1030.857544,865.923096 
	C1036.434448,865.112793 1034.714600,861.172485 1034.835815,857.138916 
	C1034.809814,854.803955 1034.809082,853.338440 1034.808228,851.872925 
	C1034.848511,851.430908 1034.888672,850.988831 1035.275635,850.225342 
	C1035.622437,849.903931 1036.087524,849.992798 1036.087524,849.992798 
	C1037.034790,851.672852 1037.982056,853.352905 1038.249756,853.827637 
	C1037.631104,858.106262 1037.161377,861.354492 1036.691772,864.602722 
	C1037.382324,864.997498 1038.072876,865.392334 1038.763428,865.787109 
	C1039.266968,864.662903 1039.770386,863.538635 1040.273438,862.415405 
	C1040.734497,862.822021 1041.211060,863.114990 1041.171753,863.226929 
	C1040.688843,864.602539 1040.295044,866.051270 1039.550049,867.282349 
	C1038.611450,868.832886 1037.369873,870.200012 1036.259033,871.646240 
	C1036.749268,872.019714 1037.239624,872.393188 1037.729858,872.766724 
	C1038.802734,871.834656 1039.940918,870.967407 1040.938721,869.960938 
	C1054.991333,855.786377 1069.020996,841.589294 1083.073120,827.414307 
	C1083.764648,826.716614 1084.609009,826.170288 1085.704224,825.768494 
	C1085.723633,826.882324 1085.680420,828.038513 1085.080811,828.642395 
	C1066.834595,847.018250 1048.530151,865.336121 1030.243408,883.671631 
	C1023.681580,890.250916 1017.286438,897.007202 1010.496582,903.341003 
	C1008.653381,905.060364 1005.688904,906.312805 1003.169983,906.442993 
	C993.226501,906.956787 983.255493,906.939026 972.875061,907.258301 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1137.913330,688.782898 
	C1140.639648,691.699036 1143.365967,694.615112 1146.092285,697.531189 
	C1146.531250,697.277527 1146.970337,697.023865 1147.409302,696.770203 
	C1147.409302,694.672241 1147.409302,692.574280 1147.409302,689.584961 
	C1148.779419,690.495178 1149.583618,691.029358 1150.522461,692.104004 
	C1150.788208,694.423462 1150.919434,696.202454 1151.050659,697.981506 
	C1146.651978,700.313721 1150.681641,701.322998 1151.847900,703.406616 
	C1151.890747,705.863281 1151.890747,707.701843 1151.890747,708.178284 
	C1154.412109,709.454285 1155.959717,710.237488 1157.507324,711.020691 
	C1157.355713,711.385620 1157.204102,711.750610 1156.772217,712.566956 
	C1155.000122,715.017334 1153.508057,717.016357 1152.015991,719.015320 
	C1152.015991,719.015320 1152.000000,719.000000 1151.650513,719.040161 
	C1147.697510,723.033081 1145.056763,718.628357 1141.980225,718.006653 
	C1141.980225,718.006653 1142.000000,717.999695 1141.998413,717.657227 
	C1138.662354,714.883301 1135.327759,712.451721 1131.993286,710.020203 
	C1131.993286,710.020203 1131.996094,710.003601 1132.042725,709.692749 
	C1131.719604,708.921387 1131.349976,708.460815 1130.980347,708.000244 
	C1130.980347,708.000244 1131.002930,707.997559 1130.885010,707.710632 
	C1129.540649,706.885071 1128.314209,706.346436 1126.885498,705.501587 
	C1127.265259,703.226196 1123.118164,698.204102 1130.005615,700.476135 
	C1130.628784,701.245605 1131.135254,701.768250 1131.725830,702.565674 
	C1132.124512,703.311584 1132.438965,703.782776 1132.872314,704.459839 
	C1133.152832,704.947266 1133.369629,705.176575 1133.740723,705.701294 
	C1135.226929,706.621582 1136.591675,707.258484 1138.006348,707.752014 
	C1140.314697,708.557373 1142.655640,709.269104 1145.099854,710.322266 
	C1146.125488,711.104553 1147.034302,711.584839 1147.999756,712.246399 
	C1148.246826,712.416199 1148.437134,712.404663 1148.627441,712.393066 
	C1148.435059,712.247681 1148.242676,712.102356 1147.922119,711.657349 
	C1146.871216,710.896118 1145.948486,710.434570 1145.040039,709.621948 
	C1145.424438,708.268555 1145.794678,707.266357 1146.235229,706.074219 
	C1142.260742,705.619507 1138.274048,705.163391 1134.155762,704.509949 
	C1133.838257,704.049927 1133.607666,703.835083 1133.275757,703.392273 
	C1132.886230,702.679138 1132.553223,702.241943 1132.170532,701.486389 
	C1132.120850,700.368408 1132.120850,699.568787 1132.120850,698.312866 
	C1133.404663,699.142212 1134.156738,699.628052 1135.056885,700.333862 
	C1135.578735,700.596191 1135.952637,700.638611 1136.326416,700.680969 
	C1135.953979,700.372742 1135.581543,700.064514 1135.155273,699.452637 
	C1134.047852,698.062500 1132.994263,696.976074 1131.791016,695.605347 
	C1133.233032,694.584534 1134.824951,693.847961 1136.404663,693.117004 
	C1136.790527,692.008423 1137.351929,690.395691 1137.913330,688.782898 
M1138.796997,703.238037 
	C1138.697510,702.999023 1138.598022,702.760071 1138.498535,702.521118 
	C1138.481567,702.837769 1138.464600,703.154358 1138.796997,703.238037 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1090.945312,673.566772 
	C1091.982910,671.991943 1093.059082,670.782227 1094.573730,669.254272 
	C1098.633911,667.835205 1100.911865,665.677551 1101.020386,661.634949 
	C1101.156494,656.566406 1099.261963,654.867249 1091.319946,653.149353 
	C1091.320190,651.634888 1091.320435,650.120483 1091.825928,648.087524 
	C1092.567505,646.818420 1093.010254,646.067322 1093.008911,645.317078 
	C1092.986938,632.688904 1092.909790,620.060852 1092.844849,607.432739 
	C1093.261475,607.280518 1093.677979,607.128296 1094.403320,606.851257 
	C1094.903198,621.360901 1095.543579,636.014038 1094.275391,650.588074 
	C1093.753906,650.727417 1093.232544,650.866760 1092.711182,651.006104 
	C1095.231079,651.990906 1097.751099,652.975769 1101.135254,654.298340 
	C1101.135254,642.498840 1101.135254,631.530701 1101.135254,620.562500 
	C1101.601074,620.529175 1102.066895,620.495911 1102.532715,620.462585 
	C1102.664917,621.965332 1102.897583,623.467041 1102.912598,624.970947 
	C1102.998901,633.632385 1102.964478,642.295593 1103.109985,650.955811 
	C1103.207153,656.733521 1104.599243,657.702698 1109.975464,656.255127 
	C1110.866455,657.862793 1111.740967,659.440613 1112.727905,661.221375 
	C1102.991577,660.270142 1100.626221,669.731079 1094.675293,674.178162 
	C1094.422119,674.367310 1095.949707,677.631042 1097.182617,678.970032 
	C1099.603394,681.599304 1102.401367,683.881470 1105.027344,686.660278 
	C1099.933228,683.084412 1092.988037,681.157349 1090.945312,673.566772 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1129.888672,700.229370 
	C1123.118164,698.204102 1127.265259,703.226196 1126.721802,705.717529 
	C1126.667725,706.571960 1126.574951,706.904175 1126.482178,707.236450 
	C1126.682129,712.405212 1124.921997,717.872314 1127.802246,722.886780 
	C1125.103516,723.981995 1124.819702,730.150330 1128.612305,733.867615 
	C1128.932373,734.158386 1128.832642,734.107483 1128.832642,734.107483 
	C1124.631348,741.383423 1126.252563,749.070740 1127.553955,757.275757 
	C1128.392212,758.225037 1128.912476,758.611572 1129.480469,758.903320 
	C1129.528320,758.808533 1129.735229,758.855652 1129.835938,759.187988 
	C1130.086670,760.759338 1130.236938,761.998352 1130.387207,763.237366 
	C1127.361206,764.150269 1126.708862,766.174133 1126.740356,769.242859 
	C1126.798218,774.884094 1126.227051,780.531799 1125.466187,785.925903 
	C1124.947632,785.338196 1124.880981,785.001343 1124.814209,784.664551 
	C1124.700806,769.745850 1124.530762,754.827209 1124.501587,739.908325 
	C1124.489868,733.943420 1119.644043,734.853394 1114.638550,733.653442 
	C1118.716431,733.005737 1121.331177,732.590454 1124.113647,732.148438 
	C1124.113647,729.201660 1124.113647,726.575439 1124.054688,723.233398 
	C1123.983154,722.335449 1123.970459,722.153198 1124.058594,721.513672 
	C1124.019653,715.082092 1123.880127,709.107788 1123.816895,702.729736 
	C1123.893311,701.253052 1123.893311,700.180115 1123.953369,699.047119 
	C1124.013672,698.987061 1124.134521,698.867310 1124.134521,698.867310 
	C1125.122192,697.179382 1126.109985,695.491455 1127.386719,693.456238 
	C1127.675659,693.108948 1128.021118,692.964905 1128.021118,692.964966 
	C1129.210205,692.726379 1130.399292,692.487793 1131.046265,692.357971 
	C1131.046265,690.061768 1131.046265,688.375427 1131.426147,686.290283 
	C1131.928711,685.766357 1132.051270,685.641357 1132.173950,685.516357 
	C1133.247925,686.207886 1134.321899,686.899414 1135.423218,687.608643 
	C1136.845337,686.625854 1138.263306,685.645935 1139.681396,684.666016 
	C1139.878296,684.863220 1140.075195,685.060364 1140.272095,685.257568 
	C1139.580200,686.215820 1138.888184,687.174072 1138.054932,688.457642 
	C1137.351929,690.395691 1136.790527,692.008423 1136.404663,693.117004 
	C1134.824951,693.847961 1133.233032,694.584534 1131.420166,695.704346 
	C1130.762207,697.468201 1130.325439,698.848816 1129.888672,700.229370 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1175.189453,746.060364 
	C1175.691895,744.272400 1175.684204,742.042480 1176.784424,740.772156 
	C1181.834839,734.941162 1187.195190,729.378418 1193.292969,722.804382 
	C1180.949585,722.804382 1169.726807,722.804382 1158.504150,722.804382 
	C1158.503906,722.385437 1158.503784,721.966553 1158.503662,721.547607 
	C1172.592163,721.547607 1186.680542,721.547607 1200.879883,721.547607 
	C1199.704102,725.720032 1203.190674,726.667908 1206.648682,728.301025 
	C1207.011353,729.099670 1207.002197,729.535645 1206.619873,729.957031 
	C1205.466064,729.762146 1204.612305,729.727966 1203.916382,729.378967 
	C1197.187866,726.005005 1197.188110,725.982910 1191.962402,731.500671 
	C1191.181030,732.325806 1190.506714,733.252441 1189.418213,734.159668 
	C1187.984741,735.491028 1186.916016,736.794861 1185.528076,738.142578 
	C1183.555176,740.379211 1181.901367,742.571899 1179.876465,744.845825 
	C1178.066772,745.304871 1176.628174,745.682617 1175.189453,746.060364 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1206.993042,729.971558 
	C1207.002197,729.535645 1207.011353,729.099670 1206.998535,728.337646 
	C1211.356812,727.677734 1215.737305,727.343994 1220.644409,727.218689 
	C1221.558960,727.263672 1221.946655,727.100159 1222.334473,726.936646 
	C1222.334473,726.936646 1222.439209,726.960388 1222.885986,726.992432 
	C1227.286865,725.454285 1228.237793,723.389099 1224.718140,720.188477 
	C1221.765625,717.503723 1219.177368,714.408691 1216.152710,711.816101 
	C1213.720215,709.731079 1214.031372,708.037781 1216.385376,706.096985 
	C1222.654419,710.141663 1227.542847,715.060669 1232.121582,722.166870 
	C1236.101074,722.722473 1236.385864,723.166504 1235.435791,730.838989 
	C1225.670288,730.963989 1216.364502,731.053162 1207.038086,730.849976 
	C1207.009521,730.362183 1207.001221,730.166870 1206.993042,729.971558 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1151.372314,698.065308 
	C1150.919434,696.202454 1150.788208,694.423462 1150.787354,692.309692 
	C1153.209595,691.871033 1155.500977,691.740051 1157.794067,691.686523 
	C1158.237061,691.676147 1158.709961,692.146912 1159.130371,692.093140 
	C1163.486084,691.535339 1167.833984,690.915527 1173.306763,690.157471 
	C1171.339233,692.289307 1170.072632,693.661804 1168.805908,695.034302 
	C1169.229736,695.498230 1169.653564,695.962158 1170.077271,696.426086 
	C1172.033447,694.679932 1173.989502,692.933777 1175.945679,691.187622 
	C1175.739990,690.762756 1175.534302,690.337830 1175.328613,689.912903 
	C1179.826416,690.457703 1184.324219,691.002502 1189.344727,691.610657 
	C1189.344727,692.364929 1189.344727,694.213623 1189.344727,695.832397 
	C1187.129272,695.832397 1184.889038,695.832397 1181.821411,695.832397 
	C1180.961914,694.409180 1179.829590,692.502991 1176.412231,695.536133 
	C1174.473877,697.256409 1170.476318,696.683777 1167.397095,697.072571 
	C1166.602051,697.172913 1165.735229,696.833557 1164.969238,697.003235 
	C1164.021606,697.213135 1162.782471,698.337097 1162.295532,698.039917 
	C1158.671021,695.827759 1155.208618,698.260010 1151.372314,698.065308 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1032.685669,711.821472 
	C1033.722656,703.284851 1043.008789,694.968689 1051.401245,694.761047 
	C1053.609741,694.706482 1055.796021,693.749695 1057.833984,693.246399 
	C1060.830933,693.656677 1063.824097,694.388855 1066.823608,694.416260 
	C1075.632935,694.496582 1084.444824,694.290039 1093.255859,694.209045 
	C1099.206787,694.154358 1105.157959,694.122070 1111.917480,694.041260 
	C1113.208374,693.900757 1113.690918,693.798767 1114.173462,693.696777 
	C1116.323364,694.256165 1118.473267,694.815552 1120.494141,695.711426 
	C1120.139404,696.063599 1119.913574,696.079407 1119.300293,696.084778 
	C1117.867920,696.198608 1116.822998,696.322937 1115.341675,696.362671 
	C1111.332031,696.300659 1107.758911,696.323120 1103.768799,696.311157 
	C1102.801758,696.371216 1102.251343,696.465576 1101.701050,696.559937 
	C1088.193115,696.520264 1074.685303,696.480591 1060.371094,696.399536 
	C1058.354126,696.598694 1057.143433,696.839172 1055.932617,697.079590 
	C1046.661743,695.934875 1037.011108,702.030151 1034.712036,710.753174 
	C1033.817139,711.428223 1033.251465,711.624878 1032.685669,711.821472 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1159.599365,670.823364 
	C1159.932251,672.435608 1159.953125,673.798218 1159.977051,675.571289 
	C1159.980103,675.981812 1159.996826,675.995911 1159.650635,676.090942 
	C1157.811401,677.099060 1156.318604,678.012024 1154.603516,678.920471 
	C1154.381470,678.915955 1153.952026,679.030457 1153.644287,679.063965 
	C1152.924805,679.124390 1152.513062,679.151306 1151.860107,679.152954 
	C1151.499146,679.235901 1151.379517,679.344238 1151.259888,679.452515 
	C1151.485596,679.656128 1151.711426,679.859680 1151.923584,680.370972 
	C1151.066772,681.220703 1150.223389,681.762634 1149.099365,682.114868 
	C1148.527222,681.327881 1148.235718,680.730591 1148.234253,679.917236 
	C1147.651733,677.472046 1146.779053,675.242859 1145.906250,673.013672 
	C1146.211914,670.588562 1146.091553,666.247498 1146.896362,666.068604 
	C1150.461914,665.276001 1154.317627,665.460083 1158.003906,665.869934 
	C1158.598755,665.936035 1158.867676,668.932007 1159.599365,670.823364 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1141.089600,743.634949 
	C1143.404785,740.672485 1145.662720,738.512695 1149.799927,741.395691 
	C1150.856079,742.131653 1153.496460,740.593872 1155.409058,740.100769 
	C1155.783813,740.567139 1156.158569,741.033508 1156.533447,741.499878 
	C1154.676514,742.293823 1152.819580,743.087769 1150.962769,743.881714 
	C1151.479980,744.582092 1151.997314,745.282532 1152.514526,745.982910 
	C1154.237427,745.207520 1156.493896,744.879639 1157.587036,743.576111 
	C1161.466675,738.949768 1163.748413,738.493713 1168.771240,742.300110 
	C1169.867310,741.574402 1171.019287,740.811707 1172.663574,739.723083 
	C1172.663574,742.257324 1172.663574,744.154175 1172.663574,745.752075 
	C1170.314941,745.752075 1168.207642,745.752075 1166.100464,745.752075 
	C1166.070190,745.560791 1166.039917,745.369568 1166.009644,745.178284 
	C1164.146118,745.066406 1162.161255,744.496765 1160.447754,744.950989 
	C1157.941650,745.615356 1155.040649,748.476440 1153.357544,747.797852 
	C1149.758423,746.346802 1146.876221,747.761169 1143.682373,748.109436 
	C1143.064453,748.176880 1142.353271,747.389465 1141.422852,746.396851 
	C1141.136719,745.076599 1141.113159,744.355774 1141.089600,743.634949 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1124.547852,784.874878 
	C1124.880981,785.001343 1124.947632,785.338196 1125.125732,786.178894 
	C1120.730225,791.720215 1116.223022,796.757629 1110.743896,802.881470 
	C1115.151367,803.307495 1118.383057,803.619873 1121.614746,803.932251 
	C1121.626465,804.321289 1121.638184,804.710266 1121.649780,805.099243 
	C1118.079468,805.360168 1114.466675,805.373474 1110.955078,805.976562 
	C1108.911743,806.327515 1106.655151,807.206543 1105.142212,808.567749 
	C1098.851562,814.228027 1092.806885,820.161865 1086.328369,826.012024 
	C1087.028931,824.356750 1087.835327,822.460815 1089.160522,821.053345 
	C1093.716675,816.214294 1098.454346,811.546265 1103.549805,806.391602 
	C1105.339111,804.702698 1106.701538,803.435181 1108.063721,802.167603 
	C1113.469604,796.473450 1118.875488,790.779358 1124.547852,784.874878 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1140.744751,755.680054 
	C1142.038696,754.005554 1143.885620,750.705200 1144.534912,750.926025 
	C1148.179810,752.165649 1153.170410,753.490540 1154.478882,756.291809 
	C1155.546509,758.577271 1152.380249,763.150574 1150.424316,766.294617 
	C1149.998047,766.979797 1146.711060,766.012329 1144.790527,765.585815 
	C1143.867554,765.380859 1143.058472,764.662781 1142.100342,763.493469 
	C1142.195435,761.303223 1142.649536,759.767334 1142.485840,758.300354 
	C1142.382446,757.373718 1141.356689,756.549927 1140.744751,755.680054 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1175.025391,746.376099 
	C1176.628174,745.682617 1178.066772,745.304871 1180.226074,744.861084 
	C1182.528320,745.112122 1184.109985,745.429260 1185.727417,746.136719 
	C1185.817993,747.046509 1185.872803,747.566162 1185.536621,748.071777 
	C1179.105713,746.538086 1179.083496,746.538208 1179.117310,752.861755 
	C1179.136719,756.484924 1179.563965,760.138367 1179.221558,763.722229 
	C1178.848389,767.629150 1180.106323,769.433533 1183.950439,769.431152 
	C1183.511597,770.519958 1183.199097,771.310364 1182.472534,772.064270 
	C1177.728760,774.200134 1179.841309,777.195068 1180.866821,780.355591 
	C1179.959473,781.450806 1179.216675,782.262695 1178.169678,783.093811 
	C1177.259033,783.071655 1176.652222,783.030273 1176.045532,782.988892 
	C1175.944458,781.858398 1175.843384,780.728027 1176.166992,779.160828 
	C1179.099731,774.787476 1178.526611,771.241577 1175.156616,769.844116 
	C1168.864868,763.255005 1168.371216,758.888184 1172.934570,750.737976 
	C1173.662720,749.437317 1174.223389,748.042908 1175.025391,746.376099 
M1177.847778,761.243042 
	C1177.847778,757.032837 1177.847778,752.822632 1177.847778,748.612427 
	C1177.304443,748.639771 1176.761108,748.667175 1176.217773,748.694519 
	C1176.217773,751.418701 1176.217773,754.142822 1176.217773,756.867004 
	C1176.024048,756.881409 1175.830444,756.895752 1175.636719,756.910156 
	C1175.211060,755.670959 1174.785522,754.431824 1174.283325,752.969849 
	C1170.297363,757.569153 1172.066650,764.458130 1177.619507,766.528015 
	C1177.700439,765.008850 1177.776611,763.579102 1177.847778,761.243042 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1216.040283,706.089111 
	C1214.031372,708.037781 1213.720215,709.731079 1216.152710,711.816101 
	C1219.177368,714.408691 1221.765625,717.503723 1224.718140,720.188477 
	C1228.237793,723.389099 1227.286865,725.454285 1222.928711,727.024841 
	C1224.179077,724.673584 1224.220581,722.660828 1221.848145,720.558960 
	C1217.645752,716.835754 1213.656006,712.872559 1209.174805,708.628540 
	C1207.216675,710.490295 1205.161377,712.346436 1203.221802,714.316589 
	C1201.314331,716.253967 1199.423950,717.007812 1197.068604,714.452271 
	C1197.073975,713.858032 1197.264771,713.574158 1197.897461,713.387939 
	C1199.663452,712.698975 1201.175293,712.113892 1202.276978,711.088318 
	C1205.683472,707.916992 1208.794067,704.415771 1212.311157,701.380676 
	C1213.790161,700.104309 1215.999878,699.674805 1218.081299,699.058289 
	C1217.305542,700.418884 1216.325684,701.581543 1216.233887,701.690552 
	C1216.136841,703.894470 1216.088623,704.991821 1216.040283,706.089111 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1175.797852,783.153625 
	C1176.652222,783.030273 1177.259033,783.071655 1178.179688,783.456421 
	C1178.477661,784.522095 1178.461670,785.244446 1178.445801,785.966797 
	C1178.289062,787.734375 1178.132446,789.501892 1177.919556,791.902344 
	C1198.265503,791.902344 1218.127808,791.902344 1237.989990,791.902344 
	C1237.988647,792.592285 1237.987183,793.282288 1237.985718,793.972229 
	C1224.418335,793.972229 1210.851074,793.969360 1197.283691,793.973694 
	C1192.285889,793.975342 1187.272461,793.772705 1182.298340,794.121643 
	C1180.490601,794.248535 1178.776489,795.709717 1177.268188,796.441467 
	C1177.081665,794.048950 1176.781250,790.193176 1176.093750,786.109863 
	C1175.654419,785.027588 1175.602173,784.172974 1175.797852,783.153625 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1153.952026,679.030457 
	C1153.952026,679.030457 1154.381470,678.915955 1154.876221,679.233521 
	C1157.029053,679.489197 1158.687134,679.427246 1160.345337,679.365356 
	C1161.279663,680.629517 1162.214111,681.893677 1163.148560,683.157837 
	C1161.037231,684.424133 1159.046753,686.026672 1156.773926,686.843506 
	C1155.145996,687.428589 1153.025391,687.357788 1151.330688,686.867432 
	C1149.015747,686.197510 1146.896851,684.850830 1144.398193,683.522766 
	C1145.383057,682.211914 1146.663574,681.172607 1147.944092,680.133301 
	C1148.235718,680.730591 1148.527222,681.327881 1148.952759,682.458435 
	C1149.497925,683.615662 1150.048462,684.832275 1150.299316,684.773315 
	C1153.307007,684.067078 1155.396240,682.627563 1153.952026,679.030457 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1127.966797,692.624084 
	C1128.021118,692.964905 1127.675659,693.108948 1127.489868,693.087891 
	C1121.593994,687.542358 1115.768066,682.127869 1110.295776,676.377075 
	C1109.455200,675.493591 1109.558472,672.526306 1110.404175,671.444153 
	C1113.036255,668.076050 1116.235107,665.150818 1119.627197,662.051392 
	C1120.040161,662.048950 1120.217773,662.250305 1120.217773,662.250305 
	C1117.302124,666.145447 1114.386475,670.040588 1111.280029,674.190613 
	C1116.673218,680.057251 1122.292847,686.170288 1127.966797,692.624084 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1140.306641,755.761719 
	C1141.356689,756.549927 1142.382446,757.373718 1142.485840,758.300354 
	C1142.649536,759.767334 1142.195435,761.303223 1141.987915,763.203857 
	C1140.864502,762.778809 1139.509644,762.151428 1138.695068,761.103943 
	C1136.498535,758.279236 1134.613525,758.244690 1132.440430,761.147766 
	C1131.352173,760.279907 1130.543701,759.567810 1129.735229,758.855713 
	C1129.735229,758.855652 1129.528320,758.808533 1129.505371,758.777527 
	C1129.549194,758.575500 1129.541626,758.407715 1129.459839,758.243286 
	C1130.289185,756.917725 1131.016846,755.511658 1131.981445,754.293274 
	C1132.697388,753.389038 1133.721069,752.728638 1135.353149,752.708069 
	C1134.704590,753.679443 1134.056030,754.650818 1132.973267,756.272461 
	C1135.794922,756.096863 1137.831665,755.970093 1140.306641,755.761719 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1142.199463,680.029419 
	C1139.108643,679.212341 1136.017944,678.395264 1131.924683,677.313171 
	C1132.275024,675.799011 1132.119019,672.708740 1133.561646,671.448792 
	C1134.682007,670.470276 1138.398193,671.048584 1139.864258,672.260803 
	C1141.483398,673.599365 1141.852539,676.449646 1142.878418,679.216431 
	C1142.774170,680.027039 1142.510376,680.106445 1142.199463,680.029419 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1136.818237,733.873474 
	C1137.759399,732.392090 1138.543213,729.821106 1139.674927,729.657654 
	C1141.988647,729.323364 1144.607788,729.971436 1146.867065,730.830688 
	C1148.152710,731.319641 1148.976074,733.024536 1150.208862,734.407715 
	C1146.106812,734.874207 1141.932007,735.348938 1137.463379,735.593872 
	C1137.052612,734.867188 1136.935425,734.370361 1136.818237,733.873474 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1141.060181,743.194214 
	C1141.113159,744.355774 1141.136719,745.076599 1141.157471,746.066895 
	C1138.126465,747.369446 1135.098389,748.402527 1132.070190,749.435669 
	C1131.879395,749.020081 1131.688721,748.604492 1131.497925,748.188904 
	C1132.437988,746.736206 1133.378174,745.283508 1134.367920,743.754089 
	C1133.309692,743.092163 1132.286499,742.452209 1130.410645,741.278931 
	C1133.958496,740.797913 1136.432007,740.249634 1138.902954,740.261292 
	C1139.616089,740.264709 1140.321655,741.878174 1141.060181,743.194214 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1147.158936,777.294495 
	C1149.073853,772.299805 1149.645752,772.177917 1154.446533,775.194153 
	C1155.049438,775.572998 1156.165161,775.135925 1157.549072,775.217529 
	C1157.721191,776.535339 1157.387451,777.712646 1157.053467,778.889893 
	C1155.777832,779.282532 1154.516113,779.937866 1153.223145,780.006775 
	C1151.231689,780.112976 1149.218872,779.817078 1147.170166,779.047729 
	C1147.136353,778.033997 1147.147705,777.664246 1147.158936,777.294495 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1157.763428,711.216919 
	C1155.959717,710.237488 1154.412109,709.454285 1151.890747,708.178284 
	C1151.890747,707.701843 1151.890747,705.863281 1152.009766,703.734009 
	C1153.021729,704.148621 1153.914673,704.853943 1155.151489,705.830994 
	C1155.373657,705.684326 1155.975952,705.013489 1156.682007,704.875793 
	C1158.242065,704.571472 1159.854614,704.535522 1161.445557,704.390137 
	C1161.207153,705.954285 1161.240601,707.634888 1160.637451,709.042786 
	C1160.216064,710.026184 1158.923218,710.636292 1157.763428,711.216919 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1121.797363,659.787415 
	C1128.193726,652.876648 1134.590210,645.965881 1140.986572,639.055054 
	C1141.549072,639.587280 1142.111450,640.119507 1142.673950,640.651733 
	C1136.112793,647.137573 1129.551514,653.623474 1122.526733,660.078003 
	C1122.063110,660.046692 1121.797363,659.787415 1121.797363,659.787415 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1133.850220,804.247498 
	C1133.181885,804.439087 1131.936890,804.611145 1131.929688,804.825256 
	C1131.764771,809.679688 1128.504883,806.951111 1126.758789,807.836975 
	C1126.538330,807.487793 1126.317749,807.138611 1126.097290,806.789429 
	C1127.159912,805.791016 1128.222656,804.792542 1129.574829,803.522034 
	C1128.411865,802.871521 1127.418213,802.315613 1126.224976,801.648132 
	C1129.500732,798.388306 1131.534668,799.848328 1133.618774,803.249390 
	C1133.937744,803.831970 1133.893921,804.039734 1133.850220,804.247498 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1142.064331,680.411255 
	C1142.510376,680.106445 1142.774170,680.027039 1143.022461,679.578125 
	C1143.929565,677.477173 1144.804932,675.589355 1145.793335,673.357605 
	C1146.779053,675.242859 1147.651733,677.472046 1148.234253,679.917236 
	C1146.663574,681.172607 1145.383057,682.211914 1144.008545,683.557068 
	C1142.912598,684.379822 1141.910767,684.896729 1139.699951,686.037354 
	C1140.836060,683.364624 1141.382690,682.078857 1142.064331,680.411255 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1139.828369,831.708374 
	C1140.513672,833.246582 1141.001465,834.517151 1141.207275,835.979919 
	C1137.753418,831.087524 1134.094238,833.796265 1129.829468,837.051636 
	C1133.181885,829.356689 1135.437134,828.349487 1139.828369,831.708374 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1136.446289,733.924622 
	C1136.935425,734.370361 1137.052612,734.867188 1137.073486,735.581909 
	C1133.819458,738.596924 1131.811768,736.104614 1129.253174,734.159790 
	C1128.832642,734.107483 1128.932373,734.158386 1128.913330,734.177856 
	C1131.166992,732.353577 1133.439819,730.509888 1135.712646,728.666138 
	C1136.080322,729.118896 1136.447998,729.571594 1136.815796,730.024353 
	C1135.629761,731.078796 1134.443848,732.133240 1132.582275,733.788269 
	C1134.197876,733.875000 1135.135986,733.925354 1136.446289,733.924622 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1133.842529,804.601318 
	C1133.893921,804.039734 1133.937744,803.831970 1133.988770,803.305786 
	C1135.160034,800.731201 1136.735352,798.902649 1139.190308,801.081055 
	C1139.867798,801.682312 1139.688965,804.147705 1139.018677,805.188232 
	C1137.442139,807.635498 1135.441406,807.804199 1133.842529,804.601318 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1146.755859,777.124512 
	C1147.147705,777.664246 1147.136353,778.033997 1147.081299,778.678833 
	C1138.556519,778.723450 1136.523682,777.286682 1132.848145,767.847046 
	C1137.572632,772.151428 1139.656494,778.464966 1146.755859,777.124512 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1127.760376,682.224548 
	C1126.693726,681.484375 1125.512451,680.865356 1124.579346,679.983826 
	C1119.429077,675.118530 1119.552856,673.989075 1126.191040,669.913086 
	C1125.200928,671.576782 1124.210815,673.240540 1123.081909,675.137634 
	C1124.724243,677.045776 1126.420166,679.016235 1128.078613,681.463013 
	C1128.041138,681.939270 1127.760376,682.224548 1127.760376,682.224548 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1127.014648,639.015869 
	C1124.044556,642.643250 1121.074585,646.270630 1118.104492,649.898010 
	C1117.721924,649.570312 1117.339233,649.242554 1116.956665,648.914856 
	C1119.006226,644.719849 1122.460083,641.692200 1126.629883,638.975708 
	C1126.998291,638.997986 1127.014648,639.015869 1127.014648,639.015869 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1157.302734,779.155396 
	C1157.387451,777.712646 1157.721191,776.535339 1157.950195,775.049683 
	C1159.197388,774.496521 1160.594971,773.968079 1161.874512,774.173889 
	C1162.276611,774.238525 1162.871582,777.026245 1162.501831,777.277405 
	C1161.040894,778.270020 1159.231934,778.750427 1157.302734,779.155396 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1175.106201,770.282227 
	C1178.526611,771.241577 1179.099731,774.787476 1176.175781,778.734863 
	C1175.525146,776.070618 1175.290527,773.395508 1175.106201,770.282227 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1132.098877,710.339478 
	C1135.327759,712.451721 1138.662354,714.883301 1141.995117,717.667236 
	C1138.730469,715.566040 1135.467407,713.112427 1132.098877,710.339478 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1142.003662,718.364563 
	C1145.056763,718.628357 1147.697510,723.033081 1151.657715,719.047974 
	C1149.375854,724.750061 1146.276733,724.659119 1142.003662,718.364563 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1152.378418,719.064575 
	C1153.508057,717.016357 1155.000122,715.017334 1156.755859,712.767151 
	C1155.593506,714.715271 1154.167114,716.914551 1152.378418,719.064575 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1110.898438,690.687256 
	C1109.035400,689.901001 1107.053589,688.790588 1105.029053,687.334473 
	C1106.917480,688.113525 1108.848633,689.238281 1110.898438,690.687256 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1115.778320,651.123596 
	C1114.582275,652.708252 1113.397461,653.957397 1112.212646,655.206482 
	C1111.799194,654.745056 1111.385864,654.283630 1110.972534,653.822205 
	C1112.578125,652.810791 1114.183716,651.799438 1115.778320,651.123596 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1132.086060,685.218506 
	C1132.051270,685.641357 1131.928711,685.766357 1131.555908,685.970459 
	C1130.202515,684.979614 1129.099365,683.909607 1127.878296,682.532104 
	C1127.760376,682.224548 1128.041138,681.939270 1128.174805,681.785645 
	C1129.538452,682.728271 1130.768311,683.824402 1132.086060,685.218506 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1160.222168,679.048340 
	C1158.687134,679.427246 1157.029053,679.489197 1155.098267,679.238037 
	C1156.318604,678.012024 1157.811401,677.099060 1159.642700,676.084045 
	C1160.020508,676.898499 1160.059814,677.814880 1160.222168,679.048340 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1126.911377,707.439331 
	C1126.574951,706.904175 1126.667725,706.571960 1126.924072,706.023743 
	C1128.314209,706.346436 1129.540649,706.885071 1130.882812,707.721680 
	C1129.779175,707.893860 1128.559937,707.768066 1126.911377,707.439331 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1129.835938,759.187988 
	C1130.543701,759.567810 1131.352173,760.279907 1132.104980,761.373535 
	C1131.679321,762.182861 1131.309448,762.610657 1130.663330,763.137939 
	C1130.236938,761.998352 1130.086670,760.759338 1129.835938,759.187988 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1119.687744,696.095337 
	C1119.913574,696.079407 1120.139404,696.063599 1120.703003,696.014648 
	C1122.001709,696.746765 1122.962402,697.512024 1124.028809,698.572266 
	C1124.134521,698.867310 1124.013672,698.987061 1123.629395,699.054321 
	C1122.059326,698.112854 1120.873535,697.104065 1119.687744,696.095337 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1130.201904,765.883545 
	C1131.131470,765.932495 1132.107300,766.338684 1133.083008,766.744873 
	C1132.844971,767.148987 1132.607056,767.553101 1132.369019,767.957214 
	C1131.661987,767.385010 1130.954956,766.812866 1130.201904,765.883545 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1114.075439,693.390015 
	C1113.690918,693.798767 1113.208374,693.900757 1112.356079,693.994995 
	C1111.643555,693.202148 1111.300781,692.416992 1110.972412,691.308655 
	C1111.983765,691.684753 1112.980591,692.384033 1114.075439,693.390015 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1130.144409,636.126953 
	C1129.319214,637.061096 1128.494019,637.995239 1127.341675,638.972656 
	C1127.014648,639.015869 1126.998291,638.997986 1126.988770,638.988647 
	C1127.699585,637.961731 1128.419800,636.944092 1129.552612,635.941101 
	C1129.965088,635.955750 1130.144409,636.126892 1130.144409,636.126953 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1192.768799,681.244385 
	C1191.818848,680.446167 1190.868896,679.647888 1189.907227,678.473572 
	C1189.895386,678.097534 1190.020386,677.981262 1190.299805,678.112305 
	C1191.391479,679.156921 1192.203857,680.070374 1192.954590,681.049255 
	C1192.892944,681.114624 1192.768799,681.244385 1192.768799,681.244385 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1122.203247,660.168457 
	C1121.834961,660.894104 1121.326538,661.497925 1120.517944,662.176025 
	C1120.217773,662.250305 1120.040161,662.048950 1119.945312,661.951416 
	C1120.310547,661.236389 1120.770630,660.619019 1121.514160,659.894531 
	C1121.797363,659.787415 1122.063110,660.046692 1122.203247,660.168457 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1182.031128,668.254272 
	C1182.651123,668.446960 1183.238892,668.925415 1183.909668,669.710449 
	C1183.992432,670.016968 1183.891235,670.129456 1183.891235,670.129456 
	C1183.414185,670.055969 1182.937256,669.982483 1182.115601,669.781250 
	C1181.847046,669.282349 1181.923096,668.911133 1182.031128,668.254272 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1129.132812,757.872009 
	C1129.541626,758.407715 1129.549194,758.575500 1129.457520,758.872314 
	C1128.912476,758.611572 1128.392212,758.225037 1127.886841,757.468811 
	C1128.203125,757.232971 1128.504517,757.366882 1129.132812,757.872009 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1130.927002,708.313477 
	C1131.349976,708.460815 1131.719604,708.921387 1132.034668,709.693604 
	C1131.611328,709.545776 1131.242554,709.086243 1130.927002,708.313477 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1134.259399,631.312012 
	C1133.915771,631.672729 1133.572144,632.033386 1133.228516,632.394043 
	C1133.283203,631.980042 1133.337891,631.566101 1133.724365,631.108032 
	C1134.056152,631.063965 1134.259399,631.312012 1134.259399,631.312012 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1133.952271,630.941772 
	C1134.314331,630.644348 1134.780273,630.469116 1135.246216,630.293884 
	C1135.075317,630.580688 1134.904419,630.867554 1134.496460,631.233154 
	C1134.259399,631.312012 1134.056152,631.063965 1133.952271,630.941772 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1221.989014,726.943970 
	C1221.946655,727.100159 1221.558960,727.263672 1221.062012,727.205505 
	C1221.183105,726.973022 1221.413330,726.962158 1221.989014,726.943970 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1131.628662,633.656494 
	C1131.521240,633.747803 1131.450928,633.438599 1131.450928,633.438599 
	C1131.450928,633.438599 1131.736084,633.565186 1131.628662,633.656494 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1129.878906,635.866699 
	C1129.985962,635.607483 1130.179077,635.437378 1130.372192,635.267273 
	C1130.404785,635.477356 1130.437500,635.687500 1130.307251,636.012207 
	C1130.144409,636.126892 1129.965088,635.955750 1129.878906,635.866699 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1137.859253,622.280396 
	C1137.984863,622.293030 1138.139648,622.583923 1138.110596,622.995667 
	C1137.913818,622.930542 1137.901001,622.744507 1137.859253,622.280396 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1188.781738,677.234131 
	C1188.463379,677.125427 1188.207153,676.933655 1187.884277,676.454102 
	C1187.755493,676.249268 1187.895996,676.108521 1188.187500,676.144897 
	C1188.754639,676.381287 1188.937012,676.645691 1188.964966,677.039429 
	C1188.903809,677.104248 1188.781738,677.234131 1188.781738,677.234131 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1189.833252,678.156006 
	C1189.486572,678.069519 1189.244995,677.872131 1188.914062,677.428162 
	C1188.781738,677.234131 1188.903809,677.104248 1189.184570,677.147583 
	C1189.739990,677.391479 1189.924927,677.654968 1190.020386,677.981262 
	C1190.020386,677.981262 1189.895386,678.097534 1189.833252,678.156006 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1193.823242,682.166931 
	C1193.472656,682.084656 1193.229004,681.887634 1192.898438,681.441162 
	C1192.768799,681.244385 1192.892944,681.114624 1193.173340,681.156982 
	C1193.726685,681.398010 1193.914185,681.658325 1194.016113,681.980286 
	C1194.016113,681.980286 1193.887817,682.104980 1193.823242,682.166931 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1174.745361,663.264160 
	C1174.424805,663.123169 1174.192627,662.893005 1173.978760,662.335815 
	C1173.908447,662.097961 1173.952393,662.051575 1174.200439,662.123474 
	C1174.732544,662.386169 1174.926147,662.644592 1174.958740,663.044312 
	C1174.887939,663.117920 1174.745361,663.264160 1174.745361,663.264160 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1175.810669,664.182434 
	C1175.457397,664.100098 1175.216431,663.898926 1174.879517,663.458130 
	C1174.745361,663.264160 1174.887939,663.117920 1175.176514,663.151794 
	C1175.736694,663.383179 1175.925171,663.641418 1176.030518,663.960327 
	C1176.030518,663.960327 1175.884888,664.109314 1175.810669,664.182434 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1128.160645,667.171265 
	C1128.053345,667.459595 1127.827026,667.627991 1127.600586,667.796387 
	C1127.555176,667.562683 1127.509766,667.328979 1127.631104,666.956177 
	C1127.797607,666.817078 1128.041382,667.051392 1128.160645,667.171265 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1181.241455,667.965698 
	C1181.263062,667.838745 1181.547974,667.714722 1181.931519,667.764404 
	C1181.854980,667.948303 1181.679810,667.958435 1181.241455,667.965698 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1118.018799,831.985535 
	C1117.887329,831.832153 1117.739990,831.692505 1117.592773,831.552856 
	C1117.691528,831.604797 1117.790283,831.656738 1117.932861,831.870117 
	C1117.976562,832.031555 1118.003052,831.999268 1118.018799,831.985535 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1400.275879,877.839050 
	C1399.859741,878.436279 1399.443481,879.033447 1399.042480,880.019287 
	C1391.043701,882.425720 1382.958984,880.486572 1374.916382,880.847656 
	C1372.452515,880.958252 1369.994873,881.204224 1366.877441,881.668823 
	C1364.819702,881.566711 1363.418579,881.183838 1361.557861,880.815796 
	C1355.370361,881.210449 1349.642700,881.590393 1343.643555,881.836792 
	C1342.252808,881.442749 1341.133423,881.182190 1339.642090,880.943298 
	C1338.529541,880.896851 1337.789062,880.828796 1337.041748,880.495361 
	C1337.034058,879.875854 1337.033081,879.521729 1337.252441,879.096436 
	C1337.472900,879.025269 1337.910278,878.872559 1338.337769,878.903259 
	C1339.903564,878.860107 1341.041992,878.786194 1342.566650,878.545288 
	C1342.942993,872.194397 1342.932861,866.010498 1343.348145,859.872192 
	C1345.195801,859.906311 1346.618042,859.894775 1348.503784,859.933167 
	C1367.225708,859.819275 1385.484131,859.655457 1404.196289,859.604980 
	C1407.292114,859.718323 1409.933960,859.718323 1412.667480,859.718323 
	C1412.403931,857.842468 1412.203979,856.419678 1412.239868,854.824097 
	C1412.126221,853.945129 1411.776733,853.239075 1411.427368,852.532959 
	C1410.635132,853.184448 1409.843018,853.835938 1408.944580,854.777954 
	C1407.161377,855.148804 1405.484253,855.229126 1403.651733,855.168701 
	C1403.496216,855.027954 1403.176025,854.756897 1403.519897,854.521667 
	C1404.538696,852.829956 1405.213623,851.373474 1405.926514,849.940552 
	C1405.964355,849.964111 1405.915894,849.889221 1406.276123,849.916138 
	C1407.922241,850.056152 1409.208008,850.169312 1410.554565,850.287781 
	C1410.554565,849.403137 1410.554565,848.538879 1410.554565,848.408325 
	C1411.445923,849.110413 1412.632812,850.045288 1414.016235,851.274414 
	C1416.092041,852.846619 1417.971191,854.124451 1419.207031,854.964966 
	C1421.209351,856.625671 1422.605469,857.783508 1424.229492,859.152466 
	C1428.424561,859.409607 1426.840332,855.969666 1428.155518,854.072144 
	C1431.820312,853.848511 1433.118530,851.983521 1432.972412,848.869019 
	C1433.006592,848.872742 1432.946533,848.817505 1433.320068,848.893494 
	C1436.768188,851.604431 1439.843018,854.239380 1442.905273,856.863647 
	C1441.961060,857.929443 1441.422729,858.537231 1440.542236,859.047974 
	C1436.973389,854.222412 1436.973389,854.222412 1432.504761,856.266724 
	C1432.851807,857.253357 1433.164062,858.140991 1433.351318,859.287231 
	C1433.132935,860.109924 1433.039307,860.674011 1432.587524,861.287354 
	C1431.266968,861.351074 1430.304443,861.365601 1428.910889,861.300171 
	C1427.904175,861.316345 1427.328491,861.412476 1426.504272,861.432007 
	C1425.913452,861.382812 1425.571411,861.410217 1424.803467,861.359558 
	C1423.810669,861.347900 1423.243530,861.414368 1422.246948,861.477295 
	C1418.211060,861.647461 1414.604492,861.821167 1410.725830,861.982788 
	C1410.000122,862.263855 1409.546387,862.557007 1408.706299,862.826538 
	C1407.549194,862.850220 1406.778442,862.897461 1405.815918,862.720215 
	C1405.046753,862.427979 1404.469604,862.360352 1403.460693,862.112732 
	C1385.541504,861.939880 1368.053955,861.983887 1350.566772,861.920410 
	C1347.383057,861.908813 1346.487793,863.490295 1346.681030,866.287109 
	C1346.852051,868.760498 1347.368408,871.289734 1347.039429,873.699768 
	C1346.503784,877.624512 1347.637207,879.158203 1351.772949,879.047668 
	C1361.424316,878.789429 1371.091064,878.675598 1380.739624,878.940308 
	C1384.949219,879.055786 1386.567017,877.677917 1385.687256,873.009644 
	C1389.026611,875.660706 1390.556152,881.608582 1396.370605,877.205139 
	C1392.812866,873.645569 1390.365356,869.616394 1393.185669,863.988953 
	C1392.128296,870.587097 1396.589966,873.991516 1400.275879,877.839050 
M1344.373657,874.970642 
	C1344.373657,876.199585 1344.373657,877.428528 1344.373657,878.657471 
	C1344.838257,878.648071 1345.302979,878.638672 1345.767578,878.629211 
	C1345.767578,873.262634 1345.767578,867.896057 1345.767578,862.529419 
	C1345.315918,862.520508 1344.864380,862.511597 1344.412720,862.502625 
	C1344.412720,866.374634 1344.412720,870.246643 1344.373657,874.970642 
M1430.352539,858.421204 
	C1430.352539,858.421204 1430.606079,858.534363 1430.606079,858.534363 
	C1430.606079,858.534363 1430.550171,858.294434 1430.352539,858.421204 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1405.785278,903.613342 
	C1404.836914,902.820312 1403.888550,902.027222 1402.940186,901.234192 
	C1402.955566,901.637634 1402.970947,902.041138 1402.986450,902.444641 
	C1395.487183,902.080505 1387.987915,901.716370 1380.488647,901.352173 
	C1380.322998,900.820129 1380.157471,900.288086 1379.991943,899.755981 
	C1381.689453,898.434265 1383.386963,897.112488 1385.291016,895.629883 
	C1385.595215,896.358948 1386.003662,897.337891 1385.901367,897.092590 
	C1391.059204,896.454956 1395.815552,895.866943 1400.571899,895.278931 
	C1400.937988,895.742737 1401.304077,896.206482 1401.670288,896.670288 
	C1400.115479,897.257080 1398.560669,897.843872 1397.005859,898.430725 
	C1401.974609,900.254089 1402.427002,899.915833 1403.914673,895.074951 
	C1406.802124,892.167969 1408.672729,893.992920 1410.152588,895.654846 
	C1410.541626,896.091797 1409.456055,898.821472 1409.070557,898.816223 
	C1405.348755,898.765320 1406.193726,901.580566 1405.785278,903.613342 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1340.014038,880.921631 
	C1341.133423,881.182190 1342.252808,881.442749 1343.627930,882.240540 
	C1344.592163,884.887268 1345.300659,886.996765 1346.229736,889.621338 
	C1346.472046,891.249329 1346.493896,892.362183 1346.515747,893.474976 
	C1345.511108,894.224304 1344.506470,894.973694 1343.247314,895.889587 
	C1341.747437,895.024780 1340.502075,893.993469 1339.285767,892.986084 
	C1332.353149,899.002563 1326.516724,892.214722 1320.115112,891.343262 
	C1320.016235,890.971191 1320.002686,891.028992 1320.401123,890.997253 
	C1323.865356,891.030029 1327.511230,893.031982 1329.246582,887.791809 
	C1329.797241,889.293152 1330.140747,890.229675 1330.500122,891.209473 
	C1333.444824,888.341370 1336.298828,885.698730 1339.614136,890.322937 
	C1345.243408,887.232849 1343.573853,884.095032 1340.014038,880.921631 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1346.565186,893.892090 
	C1346.493896,892.362183 1346.472046,891.249329 1346.432861,889.286438 
	C1346.536865,886.702820 1346.658203,884.969238 1346.777588,883.260925 
	C1350.564941,883.260925 1353.824951,883.260925 1357.047485,883.656494 
	C1357.022217,887.999939 1357.034546,891.947876 1357.046753,895.895813 
	C1353.572388,895.347412 1349.568359,898.281006 1346.565186,893.892090 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1406.199585,903.726929 
	C1406.193726,901.580566 1405.348755,898.765320 1409.070557,898.816223 
	C1409.456055,898.821472 1410.541626,896.091797 1410.152588,895.654846 
	C1408.672729,893.992920 1406.802124,892.167969 1404.139526,894.769165 
	C1404.192627,892.827759 1404.581421,890.861511 1404.987305,888.443481 
	C1405.004272,887.991699 1405.149658,887.863831 1405.149658,887.863831 
	C1406.773315,887.934875 1408.396973,888.005859 1409.366943,888.048340 
	C1411.360596,891.984619 1413.343994,895.900696 1415.351929,899.865112 
	C1412.869873,900.994324 1409.741943,902.417419 1406.199585,903.726929 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1376.820312,891.548706 
	C1375.034790,890.557617 1373.616211,889.409546 1372.197510,888.261475 
	C1373.743164,886.599060 1375.288818,884.936646 1376.834351,883.274170 
	C1377.251587,883.723633 1377.668945,884.173096 1378.086182,884.622498 
	C1376.717163,885.795105 1375.348022,886.967651 1373.847290,888.253113 
	C1377.887451,890.125488 1380.509277,888.642456 1381.369751,884.379272 
	C1381.675537,884.264404 1382.004272,884.018066 1382.286377,884.063232 
	C1384.287598,884.383850 1386.279297,884.764099 1388.274048,885.125366 
	C1388.053711,886.334656 1388.220703,888.248779 1387.541138,888.622131 
	C1385.449585,889.771118 1383.060059,890.421448 1380.733887,891.080383 
	C1379.612183,891.398193 1378.373047,891.301025 1376.820312,891.548706 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1357.392822,895.915894 
	C1357.034546,891.947876 1357.022217,887.999939 1357.392334,883.664429 
	C1360.222900,886.057983 1360.222900,886.057983 1361.604980,884.137817 
	C1363.197266,884.349121 1364.456299,884.674744 1365.715576,885.000427 
	C1365.583252,885.632019 1365.450928,886.263550 1365.103027,887.395386 
	C1364.558594,888.345276 1364.229736,888.794983 1363.900879,889.244629 
	C1361.846924,891.475098 1359.792847,893.705505 1357.392822,895.915894 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1363.919189,889.610474 
	C1364.229736,888.794983 1364.558594,888.345276 1365.104736,887.721191 
	C1366.822388,892.211792 1368.322510,896.876892 1369.927612,901.868591 
	C1367.324707,901.868591 1365.077759,901.868591 1362.830688,901.868591 
	C1362.809448,901.696228 1362.788330,901.523865 1362.767090,901.351501 
	C1364.285034,901.094727 1365.802856,900.837891 1367.853638,900.490906 
	C1366.519165,896.907715 1365.228394,893.442017 1363.919189,889.610474 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1403.764648,880.853149 
	C1405.316040,880.874146 1406.952393,881.221313 1408.810547,881.867310 
	C1409.497437,883.150085 1409.962402,884.134094 1410.427246,885.118042 
	C1410.175659,885.420227 1409.923950,885.722412 1409.672363,886.024597 
	C1407.731323,884.409546 1405.790405,882.794434 1403.764648,880.853149 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1366.074463,885.010864 
	C1364.456299,884.674744 1363.197266,884.349121 1361.529785,883.783325 
	C1361.420166,882.629089 1361.718872,881.715027 1362.017578,880.801025 
	C1363.418579,881.183838 1364.819702,881.566711 1366.551270,881.939453 
	C1367.320923,882.623901 1367.759766,883.318481 1368.143311,884.450562 
	C1368.088013,884.888000 1367.866089,885.127747 1367.866089,885.127808 
	C1367.388550,885.092285 1366.911133,885.056824 1366.074463,885.010864 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1368.188599,884.759277 
	C1369.049438,884.826904 1369.809692,885.023193 1371.357544,885.422852 
	C1370.025879,886.857971 1369.071899,887.885986 1367.242432,889.857422 
	C1367.594604,887.689758 1367.748657,886.741394 1367.884399,885.460388 
	C1367.866089,885.127747 1368.088013,884.888000 1368.188599,884.759277 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1404.929932,888.053711 
	C1404.598511,887.346313 1404.341553,886.576904 1404.084473,885.807556 
	C1404.310425,885.761597 1404.536255,885.715637 1404.762207,885.669678 
	C1404.869629,886.202637 1404.977051,886.735657 1405.117188,887.566223 
	C1405.149658,887.863831 1405.004272,887.991699 1404.929932,888.053711 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1252.439819,903.857666 
	C1252.058838,903.640137 1252.038330,903.476318 1252.017822,903.312500 
	C1247.257568,903.996887 1246.806519,904.009888 1246.632690,897.994324 
	C1246.485718,892.900757 1247.542480,887.772400 1248.107056,882.253479 
	C1249.535889,881.923706 1250.923950,881.999939 1252.662354,882.453308 
	C1252.156860,883.845154 1251.301147,884.859863 1250.264282,886.089355 
	C1253.023071,886.630554 1255.344360,887.085876 1257.640869,887.536377 
	C1257.524170,888.695007 1257.422485,889.704834 1257.215332,891.762085 
	C1259.544678,890.145142 1261.083740,889.076782 1262.904785,888.001099 
	C1263.802490,887.683533 1264.418091,887.373291 1265.093018,886.745056 
	C1267.937988,885.145386 1269.574829,885.917908 1271.116699,889.392334 
	C1271.325439,889.835571 1271.401489,890.101685 1271.060791,890.535645 
	C1267.646729,892.787659 1265.101807,895.246887 1260.709106,892.339050 
	C1258.046265,890.576355 1255.059692,893.697021 1255.053467,897.889709 
	C1256.274170,899.496704 1257.463989,900.743164 1258.385010,901.707886 
	C1257.663452,902.196899 1256.326538,903.103088 1254.989624,904.009216 
	C1254.259888,903.976624 1253.530151,903.943970 1252.439819,903.857666 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1255.277832,904.221313 
	C1256.326538,903.103088 1257.663452,902.196899 1258.385010,901.707886 
	C1257.463989,900.743164 1256.274170,899.496704 1255.429932,897.711548 
	C1258.761353,897.429871 1261.746826,897.686768 1264.656006,897.937134 
	C1265.205811,898.521606 1266.048706,899.417664 1267.029053,900.459961 
	C1270.279663,895.665588 1271.699341,895.616638 1274.667358,899.252136 
	C1276.599731,898.456970 1278.493896,897.677551 1280.387939,896.898193 
	C1280.571167,897.270142 1280.754272,897.642090 1280.937378,898.014038 
	C1279.643799,899.075134 1278.350098,900.136230 1276.239258,901.867676 
	C1278.520386,901.867676 1279.750244,901.867676 1281.007080,902.201538 
	C1281.057495,902.757385 1281.081177,902.979431 1281.104736,903.201477 
	C1277.430542,903.663086 1273.766479,904.372498 1270.079468,904.524841 
	C1265.251099,904.724487 1260.404663,904.484375 1255.277832,904.221313 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1311.272949,893.277710 
	C1310.063965,894.396912 1308.975220,896.265015 1307.621582,896.482300 
	C1304.631958,896.962219 1301.509033,896.610840 1297.982544,896.782715 
	C1297.524658,896.966797 1297.547363,897.016846 1297.629639,896.557190 
	C1297.654419,893.445740 1297.283447,890.749634 1297.649170,888.157593 
	C1297.895508,886.412476 1299.394043,883.313843 1299.970581,883.394897 
	C1303.603516,883.905579 1308.288696,880.257874 1310.842529,885.920898 
	C1310.920898,886.434937 1310.956177,886.731079 1310.676270,887.256592 
	C1310.666504,889.416382 1310.969727,891.347046 1311.272949,893.277710 
M1302.049805,892.806641 
	C1303.337158,893.647461 1304.624390,894.488281 1305.911621,895.329102 
	C1306.255127,894.551331 1306.598755,893.773560 1306.942261,892.995728 
	C1305.286865,892.672119 1303.631592,892.348450 1302.079834,891.221436 
	C1301.613525,889.819519 1301.147095,888.417603 1300.680664,887.015686 
	C1300.254395,887.136536 1299.828125,887.257385 1299.401855,887.378174 
	C1299.401855,889.139954 1299.401855,890.901672 1299.401855,892.613220 
	C1299.924927,892.676880 1300.099731,892.752258 1300.228760,892.704529 
	C1300.833374,892.480530 1301.425537,892.222656 1302.049805,892.806641 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1297.547363,897.016846 
	C1297.547363,897.016846 1297.524658,896.966797 1297.512573,896.941956 
	C1297.500488,898.996887 1297.500488,901.076660 1297.500488,902.601440 
	C1295.450073,902.601440 1293.706421,902.603821 1291.962891,902.600403 
	C1291.088989,902.598694 1290.215210,902.586365 1289.145264,901.985352 
	C1288.444702,901.528687 1287.940186,901.665405 1287.435791,901.802185 
	C1285.545288,902.234253 1283.654907,902.666321 1281.434570,903.149902 
	C1281.081177,902.979431 1281.057495,902.757385 1281.213135,901.911377 
	C1283.042236,896.317322 1286.400635,895.217957 1290.976562,897.175598 
	C1291.964844,897.598328 1292.769287,898.451233 1293.488647,898.981079 
	C1295.022583,898.238708 1296.285034,897.627747 1297.547363,897.016846 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1311.556396,893.115479 
	C1310.969727,891.347046 1310.666504,889.416382 1310.966309,887.227234 
	C1311.798828,886.480896 1312.028320,885.993042 1312.299561,885.387024 
	C1312.341431,885.268921 1312.506592,885.080505 1312.506592,885.080505 
	C1313.617920,885.188660 1314.729248,885.296753 1316.282959,885.447937 
	C1315.400757,886.700745 1314.783691,887.577148 1314.136963,888.793823 
	C1313.351562,890.407227 1312.595825,891.680237 1311.556396,893.115479 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1287.854736,901.861450 
	C1287.940186,901.665405 1288.444702,901.528687 1289.032715,901.696289 
	C1288.835449,901.973999 1288.554688,901.947388 1287.854736,901.861450 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1337.910278,878.872559 
	C1337.910278,878.872559 1337.472900,879.025269 1336.807129,879.028809 
	C1333.738892,879.079407 1331.336182,879.126404 1328.933594,879.173401 
	C1303.963989,879.405334 1278.994385,879.637268 1253.474487,879.540649 
	C1252.548340,878.414429 1252.172607,877.616882 1252.056396,876.569824 
	C1253.675171,876.177429 1255.039795,876.071289 1256.392822,875.882629 
	C1258.266357,875.621399 1260.131348,875.298096 1262.317505,874.985962 
	C1263.463257,874.716309 1264.515625,874.700073 1265.081177,874.167175 
	C1267.718262,871.683044 1269.495605,872.335876 1270.380371,874.589966 
	C1272.962036,874.477234 1275.177979,874.380432 1277.642944,874.272766 
	C1277.642944,873.181091 1277.642944,871.110596 1277.642944,869.329651 
	C1275.681274,869.667908 1273.837402,870.613464 1272.633545,870.089172 
	C1265.894287,867.154968 1265.246338,867.251465 1261.539917,873.610596 
	C1260.068848,873.792236 1259.004028,873.869812 1257.939087,873.947449 
	C1257.557129,873.315430 1257.175171,872.683350 1256.793213,872.051331 
	C1258.367310,871.388306 1259.941284,870.725281 1261.622925,870.016968 
	C1258.561523,867.411438 1256.042480,873.449951 1253.952637,868.597778 
	C1253.956543,866.141724 1253.831787,864.140442 1253.966064,862.156677 
	C1254.257446,857.851379 1252.610840,856.963745 1248.893311,858.904175 
	C1248.235718,858.405518 1247.712891,858.173035 1247.095703,857.759521 
	C1247.001099,857.578430 1246.880127,857.188232 1246.943970,856.965820 
	C1247.007812,856.743347 1247.091187,856.288147 1247.468506,856.249939 
	C1251.278076,855.405579 1254.710205,854.599365 1258.283447,853.915588 
	C1258.424438,854.037964 1258.766479,854.188110 1258.766479,854.188110 
	C1259.910400,854.545105 1261.459106,854.559448 1262.112549,855.331543 
	C1263.412109,856.867065 1264.219238,858.819641 1265.589966,861.234619 
	C1278.086792,863.486206 1292.096924,861.854919 1306.874146,862.211914 
	C1318.437378,862.250732 1329.200195,862.142700 1339.963135,862.034668 
	C1339.943115,861.528442 1339.923218,861.022156 1339.903320,860.515930 
	C1328.887451,860.515930 1317.871582,860.515930 1306.855713,860.515930 
	C1302.206787,860.617432 1297.514893,861.140259 1292.924072,860.672424 
	C1289.862549,860.360352 1286.893677,858.753662 1283.987671,857.487427 
	C1282.818115,856.977905 1282.038940,855.621460 1280.891724,855.004700 
	C1270.714966,849.534119 1269.422852,840.592285 1271.913574,830.243774 
	C1276.688721,829.849731 1280.700195,829.448303 1284.708740,829.476013 
	C1294.744507,829.545593 1304.778442,829.866516 1314.814331,829.976929 
	C1322.472412,830.061157 1330.135620,830.099915 1337.790283,829.910217 
	C1341.040894,829.829712 1342.081421,830.915283 1342.106812,834.191406 
	C1342.173096,842.738098 1342.624756,851.281738 1342.922852,859.826599 
	C1342.932861,866.010498 1342.942993,872.194397 1342.536865,878.182007 
	C1340.401001,873.847229 1339.322388,868.986938 1332.958618,869.629639 
	C1330.783569,868.844482 1328.722412,868.378845 1326.661133,867.913147 
	C1326.541992,868.382751 1326.422852,868.852295 1326.303711,869.321899 
	C1326.013428,869.188721 1325.673706,869.113220 1325.440186,868.913513 
	C1322.714111,866.581909 1319.166260,866.613037 1319.547119,869.152649 
	C1320.680420,869.284546 1321.859985,869.421753 1323.039673,869.559021 
	C1323.359131,870.014954 1323.678711,870.470886 1323.998169,870.926819 
	C1322.159546,871.815735 1320.321045,872.704651 1318.482544,873.593567 
	C1318.806396,873.983704 1319.130249,874.373901 1319.454102,874.764038 
	C1321.153442,874.764038 1322.852661,874.764038 1324.661133,874.764038 
	C1324.754639,873.170959 1324.827515,871.929016 1324.916748,870.408264 
	C1328.754028,875.318420 1329.334229,875.334839 1333.247559,871.055176 
	C1337.591675,872.305542 1339.705078,874.487427 1337.910278,878.872559 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1297.629639,896.557129 
	C1296.285034,897.627747 1295.022583,898.238708 1293.488647,898.981079 
	C1292.769287,898.451233 1291.964844,897.598328 1290.976562,897.175598 
	C1286.400635,895.217957 1283.042236,896.317322 1281.186279,901.577515 
	C1279.750244,901.867676 1278.520386,901.867676 1276.239258,901.867676 
	C1278.350098,900.136230 1279.643799,899.075134 1280.937378,898.014038 
	C1280.754272,897.642090 1280.571167,897.270142 1280.387939,896.898193 
	C1278.493896,897.677551 1276.599731,898.456970 1274.667358,899.252136 
	C1271.699341,895.616638 1270.279663,895.665588 1267.029053,900.459961 
	C1266.048706,899.417664 1265.205811,898.521606 1264.656006,897.937134 
	C1261.746826,897.686768 1258.761353,897.429871 1255.399292,897.351074 
	C1255.059692,893.697021 1258.046265,890.576355 1260.709106,892.339050 
	C1265.101807,895.246887 1267.646729,892.787659 1271.386719,890.655457 
	C1273.963867,890.850281 1275.845947,891.079590 1277.743408,891.310669 
	C1277.743408,890.272949 1277.743408,888.415649 1277.743408,887.777405 
	C1275.427124,888.268860 1273.229370,888.735229 1271.031616,889.201538 
	C1269.574829,885.917908 1267.937988,885.145386 1264.782227,886.653748 
	C1263.815552,887.256409 1263.219238,887.632446 1262.622803,888.008423 
	C1261.083740,889.076782 1259.544678,890.145142 1257.215332,891.762085 
	C1257.422485,889.704834 1257.524170,888.695007 1257.640869,887.536377 
	C1255.344360,887.085876 1253.023071,886.630554 1250.264282,886.089355 
	C1251.301147,884.859863 1252.156860,883.845154 1253.075684,882.525513 
	C1271.952515,881.960388 1290.766235,881.661926 1309.581055,881.557190 
	C1310.487671,881.552124 1311.403687,883.253723 1312.410889,884.620544 
	C1312.506592,885.080505 1312.341431,885.268921 1311.938721,885.459778 
	C1311.291260,885.667969 1311.046387,885.685303 1310.801636,885.702637 
	C1308.288696,880.257874 1303.603516,883.905579 1299.970581,883.394897 
	C1299.394043,883.313843 1297.895508,886.412476 1297.649170,888.157593 
	C1297.283447,890.749634 1297.654419,893.445740 1297.629639,896.557129 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1249.028320,859.170410 
	C1252.610840,856.963745 1254.257446,857.851379 1253.966064,862.156677 
	C1253.831787,864.140442 1253.956543,866.141724 1253.558350,868.564331 
	C1251.054810,870.530640 1248.956909,872.067749 1246.858887,873.604797 
	C1248.504883,874.676331 1250.150879,875.747803 1251.796875,876.819275 
	C1252.172607,877.616882 1252.548340,878.414429 1253.012573,879.530029 
	C1248.999756,879.871094 1244.898560,879.894104 1240.263550,879.709290 
	C1239.514404,879.635010 1239.298950,879.768555 1239.083618,879.902100 
	C1238.296265,879.944641 1237.508911,879.987244 1236.394531,879.593933 
	C1236.050537,878.391785 1236.033691,877.625427 1236.230347,876.616943 
	C1237.826660,874.798523 1239.209351,873.222351 1241.204102,870.948364 
	C1242.044067,871.070679 1243.966187,871.350586 1245.888306,871.630493 
	C1245.315186,870.192810 1244.289429,869.286194 1243.144165,869.094849 
	C1240.762329,868.696899 1238.317383,868.678040 1235.868408,868.196411 
	C1235.846436,867.072205 1235.854370,866.255066 1236.230835,865.331055 
	C1240.328247,864.236267 1244.057129,863.248352 1248.066650,862.036499 
	C1248.574219,860.931885 1248.801270,860.051147 1249.028320,859.170410 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1236.016846,876.859131 
	C1236.033691,877.625427 1236.050537,878.391785 1236.002930,879.536011 
	C1233.288818,879.917053 1230.638916,879.920227 1227.551636,879.873169 
	C1227.084839,872.375793 1227.216187,864.922974 1226.947144,857.484558 
	C1226.840088,854.529968 1228.067017,853.243286 1230.801514,852.308960 
	C1230.991821,852.002747 1230.963501,852.022522 1231.339111,852.022339 
	C1233.129150,851.695923 1234.543579,851.369629 1235.974609,851.420654 
	C1236.075928,853.550720 1236.160889,855.303345 1236.079590,857.383484 
	C1231.746094,861.729187 1231.736572,863.181335 1235.862305,865.437927 
	C1235.854370,866.255066 1235.846436,867.072205 1235.487305,868.317444 
	C1232.457642,871.868774 1232.857666,875.552856 1236.016846,876.859131 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1339.642090,880.943298 
	C1343.573853,884.095032 1345.243408,887.232849 1339.614136,890.322937 
	C1336.298828,885.698730 1333.444824,888.341370 1330.500122,891.209473 
	C1330.140747,890.229675 1329.797241,889.293152 1329.246582,887.791809 
	C1327.511230,893.031982 1323.865356,891.030029 1320.371582,890.996460 
	C1320.638916,889.400208 1321.334473,887.772949 1322.109009,885.960754 
	C1321.171875,884.938599 1320.224609,883.905334 1318.406006,881.921570 
	C1321.911499,881.550049 1324.529907,881.272583 1328.020020,881.018127 
	C1331.610596,880.947632 1334.329590,880.854126 1337.048462,880.760681 
	C1337.789062,880.828796 1338.529541,880.896851 1339.642090,880.943298 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1224.364258,879.874756 
	C1224.060425,871.267517 1224.060425,862.716919 1224.060425,854.166260 
	C1224.585815,854.158142 1225.111084,854.150024 1225.636475,854.141907 
	C1225.636475,862.480103 1225.636475,870.818298 1225.766113,879.489685 
	C1225.486450,879.859070 1225.077148,879.895203 1224.364258,879.874756 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1337.041748,880.495361 
	C1334.329590,880.854126 1331.610596,880.947632 1328.444458,880.970886 
	C1328.243774,880.475342 1328.490356,880.050110 1328.835205,879.399109 
	C1331.336182,879.126404 1333.738892,879.079407 1336.586670,879.099976 
	C1337.033081,879.521729 1337.034058,879.875854 1337.041748,880.495361 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1239.278320,879.899780 
	C1239.298950,879.768555 1239.514404,879.635010 1239.792358,879.660889 
	C1239.854980,879.820251 1239.473022,879.897400 1239.278320,879.899780 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1537.971191,866.485229 
	C1538.043457,865.728699 1538.115723,864.972229 1538.600830,864.077881 
	C1540.287964,864.540039 1541.562500,865.139893 1542.311279,865.492310 
	C1543.117310,868.892395 1543.838257,871.933228 1544.451782,875.346863 
	C1545.729858,878.733582 1547.129395,878.664612 1549.176636,876.042664 
	C1550.449341,876.667725 1551.583740,877.224854 1552.718262,877.782043 
	C1553.194946,876.541138 1553.671631,875.300232 1554.354492,874.028748 
	C1554.560913,873.998230 1554.975586,873.952271 1554.996094,874.310852 
	C1556.282104,875.808228 1557.547607,876.946960 1559.751221,878.930054 
	C1557.427246,879.835510 1555.838379,880.454529 1554.249512,881.073608 
	C1551.455566,878.663269 1550.331665,880.234375 1549.747070,882.985291 
	C1549.405273,884.593445 1549.132812,886.216309 1548.440674,887.852844 
	C1548.087036,886.629822 1548.121826,885.386475 1548.374268,883.593750 
	C1548.424683,882.618896 1548.257568,882.193420 1548.090454,881.767883 
	C1546.747925,879.026611 1545.166016,879.528687 1543.173096,881.173096 
	C1542.532471,881.701538 1541.221436,881.417480 1539.967773,880.992737 
	C1539.288330,880.581299 1538.858398,880.682312 1538.428345,880.783325 
	C1537.175537,880.963806 1535.922729,881.144348 1534.600952,881.711548 
	C1534.531982,882.098206 1534.513794,882.056580 1534.434326,881.614136 
	C1530.814453,879.901367 1527.233276,878.038391 1524.012329,882.005005 
	C1523.979004,882.127625 1523.877808,882.360596 1523.706909,882.351807 
	C1523.536011,882.343018 1523.193848,882.331360 1522.958984,882.258301 
	C1522.724121,882.185181 1522.242554,882.084106 1522.102783,881.623901 
	C1518.416748,880.927124 1514.864990,880.491272 1511.328247,880.590576 
	C1510.414795,880.616272 1509.023438,882.203430 1508.781250,883.281921 
	C1508.400879,884.976624 1508.809570,886.848511 1508.551514,888.587769 
	C1507.784180,885.911865 1507.349487,883.293640 1506.910034,880.646484 
	C1505.975708,880.895447 1505.100220,881.128784 1503.900269,881.057190 
	C1500.108032,881.298157 1496.640259,881.843933 1492.941650,882.435425 
	C1492.241455,882.478882 1491.772461,882.476685 1490.866821,882.375977 
	C1488.352051,882.212463 1486.273682,882.147522 1484.169312,881.701294 
	C1483.640625,881.583801 1483.138184,881.847473 1482.449463,882.180603 
	C1481.978271,882.335510 1481.732422,882.271362 1481.313232,881.618225 
	C1475.664429,880.989502 1470.228516,880.708130 1464.791138,880.679810 
	C1463.535034,880.673279 1462.274414,881.532715 1460.761963,882.017578 
	C1460.168213,882.037292 1459.828491,882.033142 1459.257568,881.660034 
	C1457.988037,881.372070 1456.949829,881.453125 1455.911621,881.534241 
	C1456.281250,882.537415 1456.650757,883.540588 1457.041504,884.783142 
	C1457.031738,885.637817 1457.000488,886.253174 1456.756348,887.176270 
	C1455.417847,887.629883 1454.279663,887.957092 1453.169678,887.879456 
	C1451.454712,887.759460 1449.759033,887.362671 1448.055176,887.083191 
	C1448.880127,884.781677 1449.705078,882.480164 1450.684570,880.491394 
	C1451.287964,881.771179 1451.737061,882.738220 1452.186035,883.705261 
	C1452.532837,883.567444 1452.879517,883.429565 1453.226318,883.291748 
	C1453.146362,882.197266 1453.066284,881.102844 1452.986328,880.008301 
	C1456.017822,878.533569 1459.049438,877.058899 1462.222534,875.515320 
	C1460.866577,875.307312 1459.234863,875.057007 1457.598633,874.356812 
	C1461.959106,874.804871 1466.324341,875.702942 1471.591309,876.786499 
	C1471.813843,874.895142 1472.162720,871.929810 1472.511597,868.964539 
	C1473.299683,868.944275 1474.087646,868.923950 1475.337891,869.286987 
	C1477.114136,870.606934 1478.428101,871.543579 1479.742065,872.480225 
	C1479.833130,872.050354 1479.924316,871.620544 1480.015381,871.190674 
	C1481.677124,871.418579 1483.333618,871.715027 1485.003052,871.842651 
	C1485.728149,871.898010 1486.478271,871.499817 1487.219727,871.489075 
	C1492.184204,871.416870 1497.149292,871.393616 1502.122070,871.489136 
	C1502.138428,871.933105 1502.120605,872.240479 1501.664551,872.465759 
	C1499.741211,872.847717 1498.229980,873.309875 1496.718750,873.771973 
	C1496.822998,874.136414 1496.927246,874.500916 1497.031494,874.865356 
	C1498.403809,874.865356 1499.776123,874.865356 1501.075439,875.195312 
	C1500.645630,876.218567 1500.288818,876.911926 1499.791626,877.877808 
	C1503.710205,877.877808 1507.398193,877.877808 1511.484131,877.897461 
	C1514.517456,877.993652 1516.530762,877.684814 1514.245972,874.347168 
	C1515.185669,874.070984 1515.853394,873.990540 1516.668945,874.265747 
	C1516.681152,876.611816 1516.876831,878.116089 1519.588379,877.989929 
	C1525.504150,877.714966 1531.453003,877.933655 1537.344116,877.430603 
	C1541.093140,877.110352 1541.963501,874.013123 1542.091675,870.642639 
	C1542.205688,867.648132 1541.474731,865.764893 1537.971191,866.485229 
M1480.395264,876.582581 
	C1479.761230,876.748535 1479.127075,876.914551 1478.493042,877.080505 
	C1478.658203,877.419373 1478.823242,877.758240 1478.988403,878.097046 
	C1479.697632,877.643738 1480.406982,877.190430 1480.395264,876.582581 
M1475.071777,874.143982 
	C1474.958496,873.723694 1474.845215,873.303467 1474.731934,872.883240 
	C1474.252563,873.167175 1473.773071,873.451111 1473.293701,873.734985 
	C1473.654663,873.965576 1474.015503,874.196106 1475.071777,874.143982 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1472.248535,868.623230 
	C1472.162720,871.929810 1471.813843,874.895142 1471.591309,876.786499 
	C1466.324341,875.702942 1461.959106,874.804871 1457.295044,874.139771 
	C1455.766235,874.706848 1453.948853,874.644531 1453.409180,875.444336 
	C1451.208618,878.705933 1448.020386,878.234070 1444.093262,878.246826 
	C1440.025513,877.609741 1438.613281,876.180603 1440.405640,872.745667 
	C1440.858521,871.877930 1440.373413,870.390991 1440.043579,869.269714 
	C1439.183350,866.344238 1438.470703,863.515320 1441.695557,861.320312 
	C1442.278198,861.283813 1442.609619,861.218506 1442.941040,861.153198 
	C1452.333862,861.153198 1461.726685,861.153198 1470.610474,861.153198 
	C1471.208862,864.255615 1471.597168,866.268799 1472.248535,868.623230 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1448.086182,887.432251 
	C1449.759033,887.362671 1451.454712,887.759460 1453.169678,887.879456 
	C1454.279663,887.957092 1455.417847,887.629883 1456.981689,887.533813 
	C1458.843262,887.688904 1460.658936,888.413452 1461.626831,887.800171 
	C1468.582642,883.392517 1474.081055,885.828979 1475.305664,894.084839 
	C1471.331421,900.606812 1464.866943,899.223328 1458.853638,899.262878 
	C1456.529663,899.278198 1454.138672,899.594360 1451.910645,899.133057 
	C1450.851807,898.913879 1450.103760,897.193298 1449.214844,896.152832 
	C1453.436646,892.760742 1448.744751,890.537476 1448.086182,887.432251 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1493.172607,882.389771 
	C1496.640259,881.843933 1500.108032,881.298157 1503.832642,881.545898 
	C1504.486938,886.658020 1504.884399,890.976562 1505.279053,895.711670 
	C1503.551025,897.155090 1501.825684,898.181885 1499.398193,899.626648 
	C1499.739868,896.994568 1499.869141,895.998596 1499.998291,895.002625 
	C1499.036255,895.056335 1497.347900,894.849060 1497.220825,895.202759 
	C1495.176880,900.892334 1490.283569,898.692871 1486.488770,899.034851 
	C1482.465332,899.397461 1478.383789,899.116394 1473.598755,899.116394 
	C1474.690186,897.712097 1475.260864,896.977722 1476.199707,895.820068 
	C1476.943726,895.359131 1477.319580,895.321472 1477.855835,895.510986 
	C1479.337402,895.405396 1480.658569,895.072632 1482.414551,894.919739 
	C1486.449951,895.099670 1490.050415,895.099670 1494.428467,895.099670 
	C1493.966187,890.421448 1493.569458,886.405579 1493.172607,882.389771 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1441.444214,861.291565 
	C1438.470703,863.515320 1439.183350,866.344238 1440.043579,869.269714 
	C1440.373413,870.390991 1440.858521,871.877930 1440.405640,872.745667 
	C1438.613281,876.180603 1440.025513,877.609741 1443.674805,878.339966 
	C1444.046753,878.814697 1444.031860,879.167969 1443.715576,879.962158 
	C1442.324829,880.616272 1441.235352,880.829468 1441.161499,880.843872 
	C1440.514038,884.007141 1439.999390,886.521606 1439.484741,889.036133 
	C1438.287476,889.025879 1437.090210,889.015564 1435.534424,889.034668 
	C1434.818970,886.478027 1434.462036,883.892029 1434.381958,880.734619 
	C1436.496216,880.016418 1438.333496,879.869690 1440.576050,879.690613 
	C1439.756470,878.976562 1439.405396,878.670776 1439.019531,877.893311 
	C1437.978760,877.787048 1436.973022,878.152344 1435.708984,878.658081 
	C1434.330811,879.257019 1433.210693,879.715637 1432.090576,880.174316 
	C1431.314697,880.364441 1430.538818,880.554565 1429.368652,880.826416 
	C1429.276733,880.280640 1429.578979,879.653076 1430.320801,878.650879 
	C1432.224854,876.808411 1433.689331,875.340515 1435.153809,873.872559 
	C1434.830566,873.537903 1434.507446,873.203247 1434.184326,872.868591 
	C1435.887085,872.502014 1437.589722,872.135437 1440.246094,871.563538 
	C1432.874878,867.554871 1432.874878,867.554871 1435.917969,862.916321 
	C1435.098633,862.390381 1434.278931,861.865051 1433.956787,861.242310 
	C1436.784302,861.193787 1439.114258,861.242676 1441.444214,861.291565 
M1439.191162,885.438171 
	C1438.567017,884.126038 1437.942871,882.813965 1437.318604,881.501892 
	C1436.664429,882.272034 1436.010132,883.042175 1435.355835,883.812256 
	C1436.376465,884.583435 1437.397095,885.354614 1439.191162,885.438171 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1475.478638,894.066406 
	C1474.081055,885.828979 1468.582642,883.392517 1461.626831,887.800171 
	C1460.658936,888.413452 1458.843262,887.688904 1457.194580,887.226074 
	C1457.000488,886.253174 1457.031738,885.637817 1457.292969,884.536865 
	C1458.178345,883.377197 1458.833618,882.703064 1459.488770,882.028992 
	C1459.828491,882.033142 1460.168213,882.037292 1461.135986,882.104492 
	C1462.977539,882.824463 1464.253174,884.127686 1465.393066,884.018127 
	C1468.966919,883.674866 1471.484497,885.132446 1474.177124,887.238647 
	C1475.417969,888.209412 1477.863770,887.639709 1479.790405,888.152222 
	C1478.543823,890.356812 1477.270020,892.177734 1475.996216,893.998596 
	C1475.996216,893.998596 1475.651611,894.047913 1475.478638,894.066406 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1439.856934,889.318298 
	C1439.999390,886.521606 1440.514038,884.007141 1441.161499,880.843872 
	C1441.235352,880.829468 1442.324829,880.616272 1443.710449,880.227478 
	C1444.723511,880.099915 1445.440308,880.147888 1446.617798,880.431763 
	C1447.109985,881.107300 1447.141602,881.546936 1447.173340,881.986572 
	C1447.000610,884.609314 1446.827881,887.231995 1446.605347,890.611267 
	C1444.381348,890.258667 1442.305298,889.929565 1439.856934,889.318298 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1476.017822,894.398376 
	C1477.270020,892.177734 1478.543823,890.356812 1479.839722,887.861450 
	C1480.252197,886.796570 1480.642456,886.406189 1481.324951,886.265503 
	C1483.411621,890.825378 1480.235596,892.912354 1477.695435,895.283813 
	C1477.319580,895.321472 1476.943726,895.359131 1476.302734,895.497803 
	C1476.038208,895.331909 1476.038696,895.065063 1476.017822,894.398376 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1499.783203,895.317078 
	C1499.869141,895.998596 1499.739868,896.994568 1499.398193,899.626648 
	C1501.825684,898.181885 1503.551025,897.155090 1505.419678,895.975159 
	C1505.749512,896.853333 1505.936035,897.884583 1506.122559,898.915833 
	C1505.465576,899.360840 1504.808716,899.805786 1504.112793,900.564453 
	C1503.886963,900.923401 1503.699951,900.968567 1503.152344,900.809326 
	C1502.546997,900.702698 1502.302612,900.800476 1502.058105,900.898193 
	C1501.031860,900.991333 1500.005493,901.084473 1498.596924,901.236084 
	C1498.665894,899.406799 1499.117065,897.519165 1499.783203,895.317078 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1452.691162,880.071411 
	C1453.066284,881.102844 1453.146362,882.197266 1453.226318,883.291748 
	C1452.879517,883.429565 1452.532837,883.567444 1452.186035,883.705261 
	C1451.737061,882.738220 1451.287964,881.771179 1451.017090,880.476868 
	C1451.595581,880.144531 1451.995972,880.139465 1452.691162,880.071411 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1447.384277,881.810364 
	C1447.141602,881.546936 1447.109985,881.107300 1446.970703,880.354858 
	C1447.107178,880.572815 1447.351196,881.103455 1447.384277,881.810364 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1237.016602,889.356567 
	C1239.382202,889.386292 1241.396729,889.592590 1242.737305,889.729858 
	C1243.328491,893.644897 1244.100952,897.458130 1244.364258,901.306213 
	C1244.441895,902.441650 1243.018799,904.713501 1242.390869,904.675903 
	C1240.230225,904.546204 1236.348633,903.778137 1236.279297,902.979492 
	C1235.894287,898.541931 1236.439087,894.023682 1237.016602,889.356567 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1403.002441,909.997803 
	C1402.881226,909.857544 1402.757446,909.719543 1402.633667,909.581543 
	C1402.720337,909.629822 1402.806885,909.678040 1402.944336,909.865723 
	C1402.995117,910.005249 1403.000000,910.000000 1403.002441,909.997803 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1400.643799,877.961426 
	C1396.589966,873.991516 1392.128296,870.587097 1393.185669,863.988953 
	C1390.365356,869.616394 1392.812866,873.645569 1396.370605,877.205139 
	C1390.556152,881.608582 1389.026611,875.660706 1385.687256,873.009644 
	C1386.567017,877.677917 1384.949219,879.055786 1380.739624,878.940308 
	C1371.091064,878.675598 1361.424316,878.789429 1351.772949,879.047668 
	C1347.637207,879.158203 1346.503784,877.624512 1347.039429,873.699768 
	C1347.368408,871.289734 1346.852051,868.760498 1346.681030,866.287109 
	C1346.487793,863.490295 1347.383057,861.908813 1350.566772,861.920410 
	C1368.053955,861.983887 1385.541504,861.939880 1403.423584,862.444824 
	C1403.287231,863.930908 1402.771606,864.913696 1402.222046,865.877075 
	C1400.677002,868.584839 1401.934937,871.961121 1404.794800,873.429993 
	C1404.453491,875.200012 1404.237671,876.627930 1404.021973,878.055908 
	C1403.018555,878.065186 1402.015259,878.074524 1400.643799,877.961426 
M1370.794189,867.853821 
	C1368.811035,868.843323 1366.828003,869.832825 1364.844971,870.822327 
	C1364.998535,871.324463 1365.152100,871.826599 1365.305664,872.328735 
	C1368.347900,872.658386 1371.391479,873.233154 1374.432007,873.218384 
	C1376.045288,873.210510 1377.891113,872.476440 1379.150879,871.468628 
	C1379.675659,871.048706 1378.947021,869.062073 1378.788208,867.787781 
	C1377.229492,868.089783 1375.609985,868.225098 1374.137695,868.763550 
	C1373.391602,869.036377 1372.911499,870.036804 1372.046753,871.004089 
	C1371.573120,869.950439 1371.256470,869.245667 1370.794189,867.853821 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1433.459229,861.339783 
	C1434.278931,861.865051 1435.098633,862.390381 1435.917969,862.916321 
	C1432.874878,867.554871 1432.874878,867.554871 1440.246094,871.563538 
	C1437.589722,872.135437 1435.887085,872.502014 1434.184326,872.868591 
	C1434.507446,873.203247 1434.830566,873.537903 1435.153809,873.872559 
	C1433.689331,875.340515 1432.224854,876.808411 1430.370728,878.392334 
	C1429.330444,878.220215 1428.679810,877.932129 1428.154297,877.036072 
	C1428.633667,871.412109 1428.987793,866.396118 1429.342041,861.380066 
	C1430.304443,861.365601 1431.266968,861.351074 1432.707031,861.357666 
	C1433.184570,861.378662 1433.459229,861.339783 1433.459229,861.339783 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1424.119507,876.069336 
	C1422.947388,876.844299 1421.833008,878.152222 1420.591553,878.286804 
	C1417.721680,878.598083 1414.790039,878.340820 1411.436279,877.771240 
	C1410.506470,876.130371 1410.024048,875.032471 1409.622070,873.678528 
	C1409.788452,873.265686 1409.874390,873.108948 1410.249023,872.818909 
	C1412.019043,872.834290 1413.500610,872.982910 1415.526733,873.186157 
	C1415.815063,872.616516 1416.485962,871.291321 1417.113403,870.052002 
	C1420.846191,870.828247 1423.838135,871.935913 1424.119507,876.069336 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1424.331055,875.948486 
	C1423.838135,871.935913 1420.846191,870.828247 1417.113403,870.052002 
	C1416.485962,871.291321 1415.815063,872.616516 1415.526733,873.186157 
	C1413.500610,872.982910 1412.019043,872.834290 1410.292114,872.458496 
	C1410.733521,871.486694 1411.420288,870.742126 1412.386108,869.813477 
	C1413.232056,868.535461 1413.798828,867.441528 1414.018921,867.016968 
	C1417.467529,867.903809 1420.384033,868.653748 1424.732178,869.771851 
	C1423.791626,865.978760 1423.234009,863.729797 1422.676392,861.480835 
	C1423.243530,861.414368 1423.810669,861.347900 1424.717285,861.773315 
	C1425.394531,866.471436 1425.732422,870.677734 1426.012573,874.965027 
	C1425.954956,875.046021 1425.834717,875.204468 1425.834717,875.204468 
	C1425.404053,875.412231 1424.973267,875.619995 1424.331055,875.948486 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1428.910889,861.300171 
	C1428.987793,866.396118 1428.633667,871.412109 1427.980957,876.717712 
	C1427.163208,876.552429 1426.643799,876.097595 1425.979492,875.423584 
	C1425.834717,875.204468 1425.954956,875.046021 1426.305054,874.691528 
	C1426.687744,870.060913 1426.720337,865.784790 1426.752930,861.508606 
	C1427.328491,861.412476 1427.904175,861.316345 1428.910889,861.300171 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1409.541626,873.934631 
	C1410.024048,875.032471 1410.506470,876.130371 1410.988770,877.629883 
	C1410.818115,878.052612 1410.647583,878.073853 1410.019653,877.765747 
	C1408.368896,877.632812 1407.175659,877.829224 1405.982422,878.025635 
	C1405.546387,878.063660 1405.110474,878.101685 1404.348145,878.097778 
	C1404.237671,876.627930 1404.453491,875.200012 1404.896973,873.309753 
	C1405.124634,872.847473 1405.326416,872.607422 1405.605469,872.707031 
	C1407.103638,873.182678 1408.322632,873.558655 1409.541626,873.934631 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1406.374023,878.138428 
	C1407.175659,877.829224 1408.368896,877.632812 1409.769897,877.702026 
	C1408.906982,878.062134 1407.836182,878.156677 1406.374023,878.138428 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1518.734619,905.206726 
	C1519.133789,905.703796 1519.095703,906.070374 1519.032471,906.713135 
	C1516.963867,907.041260 1514.920532,907.093201 1512.443359,907.076965 
	C1511.734009,906.526611 1511.458252,906.044373 1511.331299,905.222900 
	C1511.657349,904.842773 1511.834473,904.801880 1512.011719,904.760986 
	C1514.106812,904.866028 1516.202026,904.971130 1518.734619,905.206726 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1508.884033,888.645386 
	C1508.809570,886.848511 1508.400879,884.976624 1508.781250,883.281921 
	C1509.023438,882.203430 1510.414795,880.616272 1511.328247,880.590576 
	C1514.864990,880.491272 1518.416748,880.927124 1521.918945,881.805054 
	C1521.886108,882.618225 1521.897339,882.789978 1521.829956,883.244873 
	C1521.772339,883.722961 1521.793335,883.917786 1521.698486,884.341553 
	C1521.565308,884.907166 1521.547852,885.243652 1521.201172,885.674072 
	C1518.805786,888.828796 1516.739380,891.889587 1514.673096,894.950378 
	C1514.071899,895.168762 1513.470703,895.387207 1512.359863,895.225586 
	C1510.867554,894.586487 1509.884766,894.327393 1508.932617,894.089905 
	C1508.963013,894.111572 1508.911621,894.166016 1508.898926,893.756714 
	C1508.858643,893.075684 1508.831055,892.804077 1508.741699,892.254333 
	C1508.744751,891.405457 1508.809692,890.834717 1508.895874,889.859497 
	C1508.906128,889.185120 1508.895142,888.915222 1508.884033,888.645386 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1515.065674,894.993225 
	C1516.739380,891.889587 1518.805786,888.828796 1521.568115,885.750854 
	C1523.139160,888.721252 1524.014282,891.708740 1524.889282,894.696289 
	C1523.485718,894.297424 1522.082275,893.898499 1520.312866,893.395630 
	C1519.569092,893.790955 1518.304321,894.463074 1516.634766,895.168457 
	C1515.972778,895.146484 1515.715454,895.091309 1515.065674,894.993225 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1508.803467,892.532471 
	C1508.831055,892.804077 1508.858643,893.075684 1508.684082,894.022583 
	C1508.274292,896.104797 1508.066528,897.511841 1507.858765,898.918823 
	C1507.472778,898.904724 1507.086792,898.890625 1506.411621,898.896179 
	C1505.936035,897.884583 1505.749512,896.853333 1505.422363,895.558594 
	C1504.884399,890.976562 1504.486938,886.658020 1504.157227,881.850708 
	C1505.100220,881.128784 1505.975708,880.895447 1506.910034,880.646484 
	C1507.349487,883.293640 1507.784180,885.911865 1508.551514,888.587769 
	C1508.895142,888.915222 1508.906128,889.185120 1508.695557,889.869873 
	C1508.583740,891.034058 1508.693604,891.783264 1508.803467,892.532471 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1508.140503,898.907471 
	C1508.066528,897.511841 1508.274292,896.104797 1508.696777,894.431885 
	C1508.911621,894.166016 1508.963013,894.111572 1509.005127,894.427002 
	C1509.358398,895.605530 1509.669678,896.468567 1509.980957,897.331604 
	C1509.461426,897.853149 1508.941895,898.374695 1508.140503,898.907471 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1510.182617,897.122681 
	C1509.669678,896.468567 1509.358398,895.605530 1508.974609,894.405334 
	C1509.884766,894.327393 1510.867554,894.586487 1512.032471,895.202271 
	C1511.604492,896.010559 1510.994385,896.462158 1510.182617,897.122681 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1513.482422,898.597839 
	C1513.449463,899.581787 1513.077881,900.716736 1512.319336,901.908203 
	C1512.336182,900.892761 1512.739990,899.820801 1513.482422,898.597839 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1512.008301,904.409546 
	C1511.834473,904.801880 1511.657349,904.842773 1511.220703,904.967224 
	C1511.104614,904.092529 1511.248169,903.134399 1511.676147,902.057373 
	C1511.975464,902.645081 1511.990112,903.351562 1512.008301,904.409546 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1439.054321,878.364929 
	C1439.405396,878.670776 1439.756470,878.976562 1440.576050,879.690613 
	C1438.333496,879.869690 1436.496216,880.016418 1434.411865,880.289490 
	C1433.813354,880.424316 1433.461914,880.432800 1432.600586,880.307739 
	C1433.210693,879.715637 1434.330811,879.257019 1436.088623,878.572021 
	C1437.502319,878.352051 1438.278320,878.358459 1439.054321,878.364929 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1502.279541,900.935913 
	C1502.302612,900.800476 1502.546997,900.702698 1502.868652,900.756714 
	C1502.945923,900.908447 1502.501099,900.973694 1502.279541,900.935913 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1528.517578,946.498535 
	C1527.164185,946.985474 1525.810913,947.472473 1524.457520,947.959473 
	C1524.324585,947.453125 1524.191650,946.946777 1524.058716,946.440430 
	C1525.037476,946.227844 1526.016235,946.015259 1527.558594,945.967773 
	C1528.254028,946.254761 1528.385864,946.376709 1528.517578,946.498535 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1529.266113,943.773560 
	C1529.292603,944.488586 1529.150269,945.336914 1528.762695,946.341919 
	C1528.385864,946.376709 1528.254028,946.254761 1527.939331,945.934204 
	C1528.046875,945.364929 1528.337524,944.994446 1528.695435,944.400513 
	C1528.762817,944.177063 1529.097412,943.906799 1529.266113,943.773560 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1882.054688,680.996948 
	C1882.430054,680.982422 1882.805298,680.967957 1883.958740,680.969910 
	C1893.001343,680.986328 1901.265625,680.986328 1908.587280,680.986328 
	C1908.060059,682.821777 1907.134888,684.687927 1907.054077,686.590027 
	C1906.849731,691.396240 1907.025391,696.218506 1907.025391,701.493530 
	C1907.012939,705.955078 1907.023926,709.957397 1907.012695,714.263794 
	C1906.946777,714.969666 1906.903320,715.371460 1906.584473,715.509521 
	C1905.684326,704.622559 1905.059814,693.999390 1904.331787,681.615417 
	C1902.694336,683.396912 1901.882080,684.280701 1900.971924,685.495605 
	C1896.564819,689.493652 1898.113647,684.337769 1896.918335,682.831299 
	C1892.950562,683.750671 1888.326172,682.454407 1885.624268,687.076965 
	C1885.505005,687.281250 1883.881226,686.606445 1882.955811,685.932495 
	C1881.796387,684.397949 1880.639526,683.271851 1879.482544,682.145813 
	C1878.677002,683.218567 1877.170898,684.297668 1877.180542,685.362915 
	C1877.240356,691.893494 1877.643188,698.420898 1877.927979,705.368530 
	C1877.796875,707.381104 1877.669556,708.974670 1877.542358,710.568176 
	C1877.301880,710.510193 1877.061523,710.452148 1876.821167,710.394165 
	C1875.986938,707.687195 1875.152588,704.980225 1874.229248,701.795959 
	C1874.103149,695.871033 1874.066040,690.423340 1874.342041,684.941406 
	C1874.947266,684.587158 1875.239502,684.267212 1875.531616,683.947266 
	C1874.881958,683.648499 1874.232422,683.349731 1873.528320,683.069092 
	C1873.473755,683.087219 1873.365479,683.048706 1872.863403,683.006165 
	C1866.504517,683.045288 1860.647705,683.126953 1854.523193,683.199585 
	C1853.896729,683.183167 1853.537964,683.175842 1852.717041,683.116089 
	C1848.529785,683.684753 1844.804810,684.305847 1840.621826,684.934265 
	C1837.786987,684.988342 1835.124756,684.451355 1834.104004,687.525024 
	C1832.247070,685.985657 1830.639282,684.703125 1828.686523,683.279541 
	C1826.328247,683.164307 1824.315186,683.190125 1821.847656,683.153687 
	C1819.869385,683.065247 1818.345459,683.039124 1816.541504,683.025513 
	C1815.888062,683.027954 1815.514526,683.018005 1814.828369,683.003540 
	C1814.307129,683.000854 1814.098633,683.002686 1813.599731,683.017334 
	C1812.924072,683.024414 1812.538818,683.018738 1811.748901,683.010620 
	C1810.092529,683.963379 1808.439575,684.676147 1807.682373,685.929810 
	C1806.527466,687.841980 1805.992188,690.128296 1804.921143,692.515625 
	C1802.071533,693.886292 1799.494629,694.997070 1796.953613,695.633789 
	C1797.001343,692.184692 1797.160767,689.199097 1796.946777,686.240479 
	C1796.869751,685.174377 1795.895874,684.173096 1795.331299,683.142212 
	C1794.220825,683.760742 1793.110474,684.379272 1791.733887,684.997925 
	C1791.291138,684.982544 1791.114380,684.967163 1790.731934,684.693848 
	C1788.275024,684.665894 1786.023804,684.895874 1783.484375,685.107117 
	C1782.811157,685.070129 1782.426392,685.051819 1781.942993,684.841980 
	C1781.537476,684.785767 1781.230469,684.921082 1780.614868,685.062256 
	C1779.894897,685.059509 1779.483521,685.051025 1778.781738,684.730469 
	C1773.643677,684.306213 1768.795898,684.193970 1763.626831,684.075684 
	C1762.789917,684.403503 1762.274292,684.737244 1761.225220,685.087891 
	C1759.643066,685.286804 1758.594482,685.468811 1757.293335,685.529663 
	C1751.542358,681.516296 1746.947632,685.644653 1741.786377,686.793152 
	C1738.964478,686.291809 1736.407715,686.094849 1732.987915,685.831421 
	C1734.106689,688.569763 1735.507080,690.481384 1735.501221,692.388672 
	C1735.494751,694.505310 1734.803833,697.306335 1733.340698,698.579590 
	C1730.817505,700.775391 1727.692017,699.579590 1726.510986,696.721191 
	C1725.915161,695.278870 1727.443726,692.958740 1728.026001,691.432617 
	C1728.043945,692.642578 1727.744995,693.647278 1728.113647,694.228760 
	C1728.906372,695.479248 1730.042847,696.511780 1731.038574,697.633545 
	C1731.965698,696.799011 1733.739502,695.842712 1733.640747,695.155640 
	C1733.444580,693.790833 1732.435913,692.313354 1731.336548,691.390137 
	C1730.629028,690.796021 1729.161987,691.106506 1727.999512,690.585815 
	C1728.077515,687.910950 1728.086304,685.356689 1724.886475,686.191467 
	C1723.691772,686.503113 1722.445068,688.601135 1722.179443,690.058960 
	C1721.685547,692.770203 1721.894165,695.609375 1721.586426,698.689941 
	C1721.289917,704.384216 1721.233643,709.784180 1721.134766,715.183350 
	C1721.105103,716.804993 1721.412964,718.958069 1718.701050,716.909302 
	C1718.848633,713.647888 1718.874512,710.858582 1718.854004,707.878845 
	C1718.551147,707.803345 1718.294556,707.918213 1717.791748,708.065002 
	C1717.545532,708.096924 1717.049194,708.074158 1716.829224,707.928589 
	C1716.428223,707.487610 1716.247314,707.192139 1716.364746,706.636108 
	C1717.163330,703.230042 1717.663330,700.084534 1718.396484,696.669678 
	C1718.404297,694.575684 1718.179077,692.750977 1717.951904,690.709473 
	C1717.949951,690.492615 1717.963135,690.059021 1718.251465,689.815674 
	C1718.347046,687.934204 1718.154297,686.296082 1717.961548,684.657837 
	C1716.267090,685.125122 1714.572754,685.592407 1712.562134,686.048950 
	C1711.824585,686.019531 1711.403198,686.000854 1710.975098,685.717529 
	C1710.325684,685.624756 1709.682739,685.796570 1708.672852,685.942871 
	C1706.196045,685.939758 1704.086304,685.962280 1701.777344,685.716187 
	C1700.388672,685.629578 1699.199219,685.811523 1697.765991,685.999390 
	C1697.522217,686.005371 1697.034790,686.000000 1696.793701,685.861816 
	C1695.637207,685.523865 1694.721802,685.324097 1693.441772,685.133179 
	C1692.070190,686.624512 1691.062988,688.107117 1689.755737,690.031555 
	C1689.269165,688.520996 1688.872314,687.289246 1688.526733,686.216797 
	C1688.054932,687.376160 1687.523682,688.681091 1686.561035,689.986694 
	C1683.457031,689.287109 1678.410034,691.871277 1679.281372,685.913696 
	C1678.226929,684.981934 1677.072266,683.961609 1676.391479,682.952271 
	C1694.255127,682.957886 1711.647095,683.093811 1729.033813,682.858093 
	C1734.029419,682.790344 1739.010376,681.650146 1743.998047,681.001648 
	C1746.100220,680.681274 1748.201172,680.092102 1750.304810,680.082214 
	C1770.072998,679.989258 1789.841797,680.041748 1809.609985,679.948303 
	C1816.866455,679.914001 1824.121704,679.601746 1831.966797,680.005432 
	C1845.096313,680.724304 1857.636475,680.854065 1870.176758,680.983765 
	C1870.514893,680.966492 1870.853149,680.949219 1871.859985,680.965698 
	C1872.803467,680.984131 1873.078125,680.968872 1873.352905,680.953613 
	C1873.651978,680.908813 1873.952393,680.895264 1874.950928,680.953247 
	C1877.783325,680.994568 1879.918945,680.995728 1882.054688,680.996948 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1751.053955,646.033142 
	C1751.057861,645.852966 1751.061646,645.672852 1751.520752,645.224365 
	C1766.835693,644.590820 1781.695068,644.225708 1797.327637,643.841614 
	C1797.327637,637.013367 1797.327637,630.797668 1797.327637,624.581909 
	C1797.853760,624.597595 1798.379883,624.613220 1798.906006,624.628906 
	C1798.906006,630.956177 1798.906006,637.283508 1798.906006,644.426025 
	C1801.501587,643.804565 1803.181763,643.402222 1805.290527,643.045532 
	C1807.427490,643.024963 1809.135742,642.958801 1810.992065,643.136963 
	C1811.743652,643.269653 1812.347290,643.158142 1813.362183,643.055237 
	C1814.869629,643.062439 1815.965576,643.060974 1817.338867,643.354187 
	C1821.355591,643.492493 1825.095215,643.336182 1829.228271,643.150146 
	C1830.408813,643.076050 1831.196167,643.031616 1832.264160,643.292603 
	C1836.294312,643.598022 1840.043701,643.598022 1843.448242,643.598022 
	C1841.445068,638.822388 1837.404419,641.005188 1833.822998,640.734924 
	C1833.531494,640.289001 1833.294312,640.111755 1833.171753,639.746704 
	C1833.910767,638.684753 1834.597900,637.938477 1835.022827,637.064697 
	C1835.538208,636.005066 1835.832031,634.837769 1836.547729,633.712769 
	C1837.521973,633.689575 1838.170044,633.670593 1839.273438,633.742737 
	C1842.192993,633.979553 1844.657349,634.125244 1847.171875,634.623840 
	C1849.936157,637.174316 1851.400757,641.239807 1856.029419,640.558899 
	C1858.747925,640.158875 1861.518555,640.113831 1864.609375,639.883545 
	C1864.338867,634.452759 1864.084351,629.344360 1863.796753,623.570129 
	C1861.235229,624.078003 1859.171143,624.073547 1857.529297,624.903809 
	C1854.628296,626.370544 1851.984497,628.345825 1849.128174,629.798889 
	C1848.937378,629.067200 1848.851318,628.644775 1849.072632,628.037048 
	C1853.246582,625.860962 1857.211792,624.033875 1860.927490,621.793091 
	C1862.276245,620.979736 1863.823364,619.228271 1863.912354,617.818542 
	C1864.243896,612.562256 1864.007812,607.270264 1864.398071,602.051208 
	C1865.203857,603.596802 1865.883667,605.073059 1865.934937,606.570740 
	C1866.086304,610.989990 1865.988770,615.417786 1865.988770,619.842163 
	C1866.299805,619.832092 1866.610962,619.821960 1866.921997,619.811829 
	C1866.921997,611.240112 1866.921997,602.668396 1866.921997,594.096680 
	C1867.405273,594.075500 1867.888428,594.054260 1868.733643,594.067993 
	C1869.368530,610.026978 1869.649902,625.950806 1869.910889,641.875000 
	C1870.025513,648.866150 1870.006836,648.873108 1862.763428,648.893860 
	C1843.282104,648.949585 1823.800049,649.109070 1804.320312,648.960999 
	C1800.280029,648.930298 1798.619141,649.840088 1798.935669,654.165588 
	C1799.305420,659.217041 1799.022095,664.316345 1799.022095,669.394958 
	C1798.498535,669.422119 1797.974976,669.449280 1797.451416,669.476379 
	C1797.451416,662.939453 1797.451416,656.402466 1797.451416,649.106323 
	C1793.311768,649.106323 1790.054565,649.106323 1786.564453,648.776001 
	C1789.485840,647.744751 1792.640137,647.043884 1797.189209,646.033142 
	C1780.870605,646.033142 1765.962280,646.033142 1751.053955,646.033142 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2284.061768,872.479431 
	C2285.651123,873.437500 2287.240723,874.395630 2288.179199,874.961365 
	C2294.350342,871.743408 2300.499268,868.537109 2306.647949,865.330811 
	C2307.015137,865.923218 2307.382324,866.515625 2307.749512,867.108032 
	C2305.441162,869.749207 2304.722656,873.958374 2299.248047,873.263672 
	C2295.948730,872.845032 2290.540771,879.104492 2289.689453,883.571228 
	C2289.454102,883.921387 2289.093994,883.916077 2288.793701,883.740967 
	C2287.450684,882.280579 2286.407959,880.995239 2285.365234,879.709961 
	C2284.784180,880.039551 2284.202881,880.369141 2283.621826,880.698730 
	C2284.332520,882.162842 2285.043213,883.626892 2285.702148,885.435181 
	C2284.781982,885.894226 2283.913574,886.009033 2282.745117,886.232788 
	C2281.670654,886.635681 2280.896240,886.929565 2279.810059,887.157959 
	C2279.287109,887.066956 2279.076172,887.041565 2278.865234,887.016113 
	C2278.886475,886.565369 2278.907715,886.114685 2279.371094,885.349609 
	C2280.144531,884.463745 2280.475098,883.892212 2280.805908,883.320740 
	C2279.921387,883.331665 2279.036865,883.342590 2278.152344,883.353516 
	C2268.872559,887.772583 2259.414307,891.860840 2250.376221,896.728699 
	C2244.496094,899.895874 2238.687256,900.949219 2232.252686,899.759399 
	C2230.770264,899.485229 2229.193848,899.718933 2226.881592,899.718933 
	C2232.440430,905.465210 2237.223877,910.613098 2242.246826,915.515198 
	C2244.686523,917.896057 2247.575684,919.816711 2250.620850,921.976318 
	C2250.980469,922.007019 2250.899658,922.033875 2250.899658,922.033875 
	C2254.890625,926.785767 2258.611084,931.800720 2262.928223,936.235291 
	C2272.201416,945.760681 2281.879639,954.891113 2291.176758,964.393982 
	C2293.961914,967.241150 2296.802002,968.724670 2300.794678,968.278259 
	C2303.260010,968.002563 2305.781006,968.221802 2308.276855,968.225403 
	C2310.677490,968.228821 2313.432861,968.849731 2313.642090,965.097717 
	C2314.212891,965.167419 2314.783691,965.237122 2315.720459,965.313599 
	C2316.086426,965.320251 2316.077637,965.451599 2315.748779,965.764465 
	C2315.913818,966.670471 2316.407471,967.263611 2316.947998,968.226685 
	C2316.977295,969.093323 2316.960205,969.590088 2316.942871,970.086792 
	C2315.735107,971.811768 2314.527588,973.536804 2313.320068,975.261841 
	C2312.803711,974.899292 2312.287109,974.536804 2311.770508,974.174316 
	C2312.497314,973.241089 2313.223877,972.307922 2314.767090,970.326416 
	C2309.108887,970.326416 2304.531494,970.070740 2299.997559,970.404602 
	C2296.137451,970.688721 2293.473145,969.292053 2290.822510,966.608704 
	C2277.158447,952.774963 2263.339111,939.094299 2249.552002,925.382324 
	C2248.615967,924.451294 2247.499268,923.701782 2246.104492,922.828369 
	C2245.742920,922.788757 2245.791016,922.765503 2245.791016,922.765503 
	C2240.427490,917.245117 2235.063721,911.724670 2229.247803,905.780518 
	C2227.951660,904.513489 2227.107910,903.670105 2226.033203,902.584351 
	C2223.567627,900.173401 2221.332764,898.004944 2218.950684,895.543396 
	C2217.510254,894.499390 2216.216797,893.748413 2214.948730,892.671753 
	C2214.632080,891.555176 2214.290039,890.764404 2214.338379,889.888184 
	C2216.304199,889.802795 2217.879883,889.802795 2219.848633,889.802795 
	C2219.214844,895.265564 2222.228271,897.001648 2227.076416,897.076050 
	C2230.216064,897.124207 2233.368164,897.991943 2236.478760,897.824280 
	C2239.981689,897.635437 2243.720459,897.332031 2246.872314,895.963623 
	C2256.271729,891.882874 2265.366455,887.104614 2274.727539,882.929199 
	C2277.806885,881.555481 2279.029053,879.747803 2278.968750,876.686523 
	C2280.702881,877.300659 2282.073730,877.806885 2283.444336,878.313110 
	C2283.565918,877.017761 2283.679932,875.721558 2283.812256,874.427307 
	C2283.878418,873.776306 2283.977539,873.128662 2284.061768,872.479431 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1972.839844,679.303040 
	C1973.519043,678.899536 1974.199463,678.143066 1974.877441,678.145203 
	C1985.737793,678.179321 1996.597656,678.310364 2007.591553,678.971130 
	C2007.360840,680.713379 2006.996338,681.903870 2006.228271,683.080444 
	C2003.089478,682.798950 2000.333008,682.180298 1997.624146,682.345337 
	C1993.439209,682.600342 1989.282471,683.360352 1985.123047,683.970032 
	C1983.414307,684.220520 1981.729614,684.636780 1979.702393,684.801514 
	C1975.884155,679.815186 1970.777588,681.930115 1966.595459,682.369080 
	C1963.043335,682.741943 1962.914795,686.842834 1962.636230,690.168945 
	C1961.546265,688.761169 1960.763062,687.240906 1959.988281,685.377686 
	C1960.082520,684.110229 1960.168335,683.185791 1960.321167,681.537964 
	C1956.915771,682.099060 1953.952637,682.587219 1950.707275,683.337036 
	C1948.595215,683.676208 1946.765381,683.753723 1945.052246,683.561035 
	C1945.210815,683.130859 1945.290527,682.969299 1945.283813,682.811401 
	C1945.277466,682.661926 1945.180420,682.516357 1945.123413,682.369019 
	C1944.430420,683.450134 1943.186401,684.507812 1943.139038,685.616455 
	C1942.923584,690.669373 1943.044556,695.736572 1943.044556,700.798645 
	C1942.738037,700.794006 1942.431519,700.789307 1942.125000,700.784668 
	C1942.023804,699.164368 1941.922607,697.544067 1941.799805,695.578186 
	C1940.395142,698.963074 1938.959839,701.726074 1938.130859,704.660217 
	C1937.562012,706.673462 1937.454956,709.160156 1940.891235,709.485352 
	C1940.935059,710.717590 1940.921265,711.618286 1940.516357,712.632446 
	C1939.077026,713.325317 1938.028809,713.904785 1936.980591,714.484253 
	C1937.824097,715.735840 1938.667480,716.987427 1939.537598,718.278748 
	C1938.996338,718.622192 1938.256714,719.091492 1936.867676,719.972839 
	C1938.751343,720.091492 1939.880981,720.162598 1940.989868,720.596436 
	C1940.944214,722.168518 1940.919434,723.377808 1940.567139,724.681274 
	C1939.133667,724.588745 1938.027588,724.402100 1936.936279,723.821045 
	C1935.885986,721.930664 1934.821045,720.434753 1933.853149,718.603271 
	C1934.199219,715.296753 1934.448242,712.325684 1934.791748,708.872192 
	C1934.633911,699.142212 1936.262695,689.802368 1933.835327,680.494019 
	C1934.985474,680.093262 1936.467896,679.576660 1938.335938,678.885620 
	C1943.994751,678.455444 1949.267822,678.199768 1954.894531,677.981628 
	C1961.111938,678.447144 1966.975830,678.875061 1972.839844,679.303040 
M1939.494873,685.816040 
	C1939.965210,688.029602 1940.435669,690.243225 1940.906006,692.456848 
	C1941.319214,692.373901 1941.732422,692.290955 1942.145508,692.208008 
	C1941.841431,688.892944 1941.537354,685.577942 1941.233276,682.262878 
	C1940.656982,682.310059 1940.080811,682.357300 1939.504517,682.404480 
	C1939.393433,683.318726 1939.282471,684.232971 1939.494873,685.816040 
M1937.768677,687.768555 
	C1937.580322,687.833252 1937.391968,687.898010 1937.203613,687.962708 
	C1937.391235,688.022583 1937.578979,688.082458 1937.768677,687.768555 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2278.612305,887.337036 
	C2279.076172,887.041565 2279.287109,887.066956 2279.771484,887.605103 
	C2280.036133,892.020996 2279.806641,895.943970 2280.153564,899.815125 
	C2280.256836,900.966736 2281.978760,901.973267 2283.192139,903.341980 
	C2286.078125,903.387085 2288.730957,903.136597 2291.686523,902.857483 
	C2290.270752,899.252258 2289.072998,896.202820 2288.261230,893.093018 
	C2288.906494,893.016052 2289.165771,892.999512 2289.703613,893.158264 
	C2290.876709,895.062683 2291.704102,896.831055 2292.682617,898.511536 
	C2294.016602,900.802551 2294.782227,900.087341 2295.477539,897.943115 
	C2296.007324,897.945374 2296.219238,897.946106 2296.431152,897.946838 
	C2297.412842,898.623535 2298.394531,899.300293 2299.702881,900.261597 
	C2300.051514,900.746277 2300.073730,900.946350 2300.096191,901.146423 
	C2300.594727,902.074097 2301.093262,903.001770 2301.763184,904.248474 
	C2301.942627,905.871948 2301.984863,908.122925 2302.489502,910.265015 
	C2302.941650,912.185303 2304.203613,913.926819 2304.579590,915.850464 
	C2305.602783,921.083618 2307.483398,924.579773 2314.406250,920.287170 
	C2314.355225,920.918701 2314.304199,921.550171 2314.253174,922.181641 
	C2313.387695,922.994507 2312.650391,924.252380 2311.635254,924.544128 
	C2306.894531,925.906860 2306.540771,928.932556 2307.705566,933.058777 
	C2309.462891,939.283997 2310.936279,945.589600 2312.498291,951.869080 
	C2312.628662,952.393921 2312.515381,952.979370 2311.465088,953.727600 
	C2309.925781,947.777710 2308.309814,941.846375 2306.865723,935.873413 
	C2304.913574,927.798706 2303.671387,926.776672 2295.295898,926.809326 
	C2290.004395,926.830017 2284.712891,926.813232 2279.024902,926.813232 
	C2278.723877,920.853699 2278.274902,915.444397 2278.239990,910.032349 
	C2278.217285,906.521179 2277.671387,905.014893 2273.424805,905.588745 
	C2268.195801,906.295227 2262.798340,905.757446 2257.473877,905.757446 
	C2257.461182,904.890625 2257.448730,904.023865 2257.436279,903.157043 
	C2261.378906,903.157043 2265.322021,903.177368 2269.264404,903.152893 
	C2278.240234,903.097168 2278.217773,903.088501 2278.118896,894.121460 
	C2278.095215,891.968079 2278.273926,889.812500 2278.612305,887.337036 
M2282.139160,906.005249 
	C2281.475098,906.381287 2280.244141,906.741028 2280.232910,907.135925 
	C2280.080566,912.434998 2280.032471,917.740479 2280.157471,923.039673 
	C2280.173096,923.693542 2281.322510,924.860596 2281.967773,924.875427 
	C2288.084229,925.016479 2294.205078,924.959412 2300.324463,924.959412 
	C2300.318604,924.308472 2300.312500,923.657532 2300.306396,923.006592 
	C2295.817627,923.006592 2291.326416,923.093567 2286.842041,922.951050 
	C2285.578857,922.910889 2284.336670,922.198303 2283.997314,921.937073 
	C2287.989990,921.942444 2291.983887,922.002075 2295.975342,921.933899 
	C2298.589844,921.889221 2300.136719,921.364502 2298.410400,918.090393 
	C2296.309326,914.104919 2294.807617,909.803406 2292.747803,904.932251 
	C2289.718750,905.257324 2286.351318,905.618713 2282.139160,906.005249 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1921.895264,659.747986 
	C1924.931274,662.458008 1927.967285,665.168091 1931.275391,668.251892 
	C1930.833984,669.491211 1929.619019,670.286255 1929.485962,671.233276 
	C1928.276001,679.849182 1927.254395,688.491455 1926.107178,697.513428 
	C1925.643677,699.384277 1925.394531,700.928955 1924.799561,702.326294 
	C1924.488770,703.055969 1923.569580,703.526428 1922.927490,703.755615 
	C1920.439575,699.376221 1916.678589,695.738525 1919.344971,690.229919 
	C1922.528320,685.533630 1922.594604,672.080627 1918.916870,667.578857 
	C1918.475708,674.396423 1918.102051,680.169128 1917.372437,685.942505 
	C1914.248047,687.737732 1911.572266,688.948914 1909.748047,684.790039 
	C1914.342041,681.642822 1915.094238,678.416992 1913.388672,670.460205 
	C1914.204468,670.363037 1915.057373,670.572388 1915.116699,670.436340 
	C1916.773438,666.628784 1914.447998,663.322021 1910.065674,663.171875 
	C1908.344360,662.451111 1906.658691,661.237122 1904.894653,661.110046 
	C1900.950073,660.825806 1900.841797,659.324951 1903.393677,656.338379 
	C1906.274414,656.631226 1909.322388,656.484314 1910.937622,657.933838 
	C1914.439941,661.076477 1917.785400,661.860718 1921.895264,659.747986 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2202.961182,787.043945 
	C2204.429932,787.188354 2205.898682,787.332764 2208.080566,787.245239 
	C2209.604736,787.508972 2210.415771,788.004578 2210.960449,788.708862 
	C2209.792236,789.562134 2208.890137,790.206726 2207.646484,790.987305 
	C2204.284180,794.458008 2205.012939,797.095886 2207.817383,800.565613 
	C2212.962891,806.931763 2222.549072,810.778137 2220.113770,821.391724 
	C2229.421387,823.245361 2229.426025,823.240662 2236.666504,830.187622 
	C2238.219727,831.677979 2239.519531,833.555054 2241.306396,834.649231 
	C2243.672852,836.098389 2246.403809,836.952820 2249.049805,838.341675 
	C2249.376709,842.130432 2249.632080,845.640198 2249.860352,849.389282 
	C2249.772217,849.950317 2249.783447,850.269470 2249.516602,850.585754 
	C2247.777100,850.403076 2246.388672,850.220703 2244.830566,849.730835 
	C2244.033447,848.202759 2243.406250,846.981995 2242.914551,845.425049 
	C2243.638428,843.459839 2244.227051,841.830872 2244.815674,840.201843 
	C2244.189697,840.763977 2243.563721,841.326111 2242.937744,841.888245 
	C2238.792236,836.892273 2234.788574,831.768860 2230.455811,826.941101 
	C2227.094971,823.196655 2222.885254,822.969971 2218.234131,825.688721 
	C2218.579102,827.132385 2218.921875,828.565674 2218.898438,830.040649 
	C2218.299561,830.158508 2218.066650,830.234680 2217.477539,830.205261 
	C2215.103760,827.706543 2213.719238,829.587708 2212.407471,830.901794 
	C2208.868896,834.446655 2205.394287,838.056335 2201.945557,841.689148 
	C2201.200928,842.473389 2200.672607,843.462891 2200.179199,844.166199 
	C2201.132812,847.012329 2206.899414,849.007935 2200.752686,851.775513 
	C2199.234375,846.047546 2194.130859,849.308838 2191.404053,847.355835 
	C2192.898682,849.406616 2194.393555,851.457336 2195.425537,853.693604 
	C2192.965088,853.934998 2190.967041,853.990967 2189.132324,853.687378 
	C2191.779297,850.956055 2189.921875,849.429749 2188.303223,847.723694 
	C2184.710693,843.937195 2184.935791,843.140625 2189.975098,841.357666 
	C2189.879639,847.345825 2194.152344,845.017944 2197.319092,846.220764 
	C2196.746582,844.303833 2196.390137,843.110779 2196.369629,841.741760 
	C2200.816650,840.876282 2205.200928,836.341370 2205.976562,832.002014 
	C2205.995850,832.007935 2205.996826,831.968750 2206.381348,831.993774 
	C2209.025146,830.298645 2211.436523,828.739136 2213.490723,826.801208 
	C2214.844971,825.523438 2215.685547,823.701111 2216.756104,822.122620 
	C2219.058838,817.236389 2217.957031,812.697998 2213.365234,808.971436 
	C2210.550049,806.686584 2208.470215,803.512634 2205.734619,801.107849 
	C2202.828125,798.552612 2202.542236,795.263733 2203.989502,792.527283 
	C2205.368652,789.919067 2204.514160,788.635864 2203.016357,786.988892 
	C2202.984863,786.985413 2202.961182,787.043945 2202.961182,787.043945 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2050.018555,682.982849 
	C2050.377441,685.666565 2050.938721,688.341614 2051.054199,691.035767 
	C2051.310059,697.018616 2051.386719,703.010498 2051.449707,708.999695 
	C2051.488281,712.654602 2053.217285,713.936829 2056.779053,713.477783 
	C2058.914062,713.202515 2061.113770,713.427246 2063.284668,713.427246 
	C2063.298584,713.937988 2063.312500,714.448792 2063.326660,714.959534 
	C2060.640625,714.959534 2057.950439,715.056396 2055.270264,714.931885 
	C2052.669922,714.811096 2051.572021,715.901001 2051.823975,718.470947 
	C2052.175781,722.058411 2052.490234,725.649475 2052.521973,729.451538 
	C2051.378662,728.604492 2050.534180,727.544983 2049.758057,726.073853 
	C2049.856445,723.862915 2050.363037,721.884827 2049.775146,720.317505 
	C2049.249512,718.917175 2047.636841,717.188843 2046.290649,716.980103 
	C2043.131714,716.490295 2039.845215,716.823303 2036.211426,716.823303 
	C2036.211426,719.474365 2036.211426,721.545288 2035.755127,723.685547 
	C2033.151001,723.774536 2031.003174,723.794189 2028.927002,723.399719 
	C2028.518677,720.192566 2028.038574,717.399536 2027.411987,713.753906 
	C2030.049561,711.121033 2029.716309,707.029968 2027.903076,702.338440 
	C2028.835938,698.885315 2029.773804,695.848694 2030.787109,693.286499 
	C2030.862671,699.998535 2030.862671,706.236145 2030.862671,712.473694 
	C2031.536133,712.447632 2032.209595,712.421509 2032.883057,712.395447 
	C2032.883057,704.302490 2032.970581,696.208191 2032.838867,688.117371 
	C2032.788208,685.007202 2032.238770,681.905212 2031.916626,678.799500 
	C2034.967651,682.463440 2039.119507,681.110107 2042.968262,681.298218 
	C2045.314331,681.412903 2047.642944,681.883179 2049.532227,682.622559 
	C2044.842163,683.051392 2040.599365,683.051392 2036.351196,683.051392 
	C2036.351196,688.460144 2036.351196,693.122131 2036.351196,697.637451 
	C2041.075806,697.637451 2045.284424,697.637451 2050.018555,697.637451 
	C2050.018555,692.469116 2050.018555,687.726013 2050.018555,682.982849 
M2038.794678,699.567627 
	C2037.859863,700.242737 2036.166992,700.865967 2036.116577,701.601624 
	C2035.848022,705.526306 2035.990234,709.479065 2035.990234,713.700134 
	C2040.145264,713.700134 2043.726440,713.824951 2047.287231,713.611145 
	C2048.162598,713.558594 2049.458008,712.563232 2049.736816,711.726868 
	C2052.817627,702.480591 2049.191650,698.174500 2038.794678,699.567627 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2207.988037,790.851318 
	C2208.890137,790.206726 2209.792236,789.562134 2211.035156,788.767090 
	C2211.375732,788.616699 2211.523926,788.728394 2211.385986,789.029175 
	C2211.468506,793.278442 2213.235596,789.900024 2214.275146,789.968994 
	C2216.607422,790.976135 2218.939697,791.983337 2221.618896,793.375977 
	C2222.984863,794.507874 2224.003662,795.254333 2225.022705,796.000732 
	C2227.128174,798.947571 2228.958984,802.146545 2231.390869,804.793396 
	C2238.229492,812.235962 2245.314697,819.451843 2252.305664,826.754272 
	C2252.762695,827.231628 2253.246826,827.683105 2253.799316,828.783264 
	C2253.829590,829.640991 2253.778809,829.862061 2253.402588,830.033813 
	C2250.721436,827.985352 2248.365479,825.986206 2245.905273,823.764404 
	C2245.602295,823.260010 2245.335449,823.079102 2244.893066,822.778015 
	C2244.579590,822.286438 2244.312500,822.104614 2243.922363,821.861450 
	C2243.761475,821.665100 2243.663086,821.618835 2243.564941,821.572510 
	C2243.714600,821.708923 2243.864258,821.845337 2244.087891,822.226379 
	C2244.441406,822.648132 2244.720703,822.825256 2245.069824,823.248291 
	C2245.419678,823.668274 2245.699951,823.842346 2245.958740,824.382568 
	C2248.756348,827.943237 2251.575439,831.137878 2254.287598,834.595215 
	C2254.091553,835.198792 2254.002686,835.539673 2253.556152,835.783813 
	C2252.618652,836.142334 2252.038330,836.597534 2251.262207,837.093262 
	C2251.065918,837.133789 2250.678223,837.032776 2250.762207,836.675781 
	C2249.663574,834.471313 2248.558105,832.568359 2247.282227,830.787292 
	C2244.214844,826.505127 2241.067383,822.280090 2238.248535,818.000183 
	C2238.092529,816.893494 2237.639893,815.818604 2237.187500,814.743774 
	C2236.786621,815.178955 2236.385498,815.614075 2235.665771,815.992188 
	C2234.555908,814.969788 2233.764893,814.004333 2233.267578,812.985962 
	C2233.906494,812.710022 2234.251709,812.486938 2234.596680,812.263916 
	C2234.321045,812.004883 2234.045654,811.745850 2233.770264,811.486816 
	C2233.500244,811.999939 2233.230225,812.513062 2232.612549,813.057251 
	C2224.172607,805.675964 2216.080322,798.263611 2207.988037,790.851318 
M2241.519043,820.578735 
	C2241.519043,820.578735 2241.625244,820.410767 2241.625244,820.410767 
	C2241.625244,820.410767 2241.427490,820.426758 2241.519043,820.578735 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2163.588623,756.984680 
	C2168.018066,758.980469 2172.447510,760.976318 2176.904785,762.984680 
	C2178.325439,761.213684 2179.826416,759.342285 2181.327637,757.470886 
	C2181.742920,757.736877 2182.157959,758.002808 2182.573242,758.268799 
	C2181.615967,760.393738 2180.658691,762.518738 2179.551514,764.976807 
	C2185.664062,769.034424 2191.753418,773.076477 2197.842773,777.118591 
	C2197.713867,777.436218 2197.584961,777.753906 2197.456055,778.071533 
	C2196.405518,778.071533 2195.354980,778.071533 2193.064209,778.071533 
	C2196.995850,781.315918 2200.041016,783.828735 2203.023682,786.692749 
	C2202.961182,787.043945 2202.984863,786.985413 2202.668457,786.929810 
	C2188.630615,777.928589 2175.258545,768.386780 2159.862793,761.734741 
	C2159.325439,759.924133 2158.928711,758.437561 2158.531738,756.950989 
	C2160.217285,756.962219 2161.903076,756.973450 2163.588623,756.984680 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2079.035156,747.928101 
	C2079.046387,747.458801 2079.057373,746.989441 2079.480469,746.171509 
	C2080.561279,746.251099 2081.229736,746.679321 2081.597900,747.283325 
	C2081.217041,748.647461 2081.136719,749.835754 2081.056396,751.024109 
	C2081.273926,751.037292 2081.491211,751.050415 2081.708740,751.063599 
	C2081.972412,750.325928 2082.236084,749.588257 2082.842285,748.861328 
	C2083.856201,748.856689 2084.527100,748.841370 2085.175293,748.864136 
	C2085.152344,748.902283 2085.072021,748.941345 2084.970703,749.240601 
	C2084.857178,749.750061 2084.844727,749.960327 2084.564941,750.382874 
	C2084.769531,751.995850 2085.240967,753.396667 2085.836914,755.124573 
	C2086.030518,756.144409 2086.099121,756.837097 2085.778320,757.665161 
	C2083.210938,759.722961 2083.735107,760.893677 2086.462646,761.056763 
	C2088.943115,761.205017 2091.442871,761.030579 2094.330078,761.039062 
	C2095.255615,761.048401 2095.785156,761.017029 2096.722656,760.969910 
	C2098.431641,761.616394 2100.922119,762.545715 2100.843018,762.897827 
	C2100.287842,765.369568 2099.202637,767.722229 2098.510742,769.740845 
	C2099.386963,766.363037 2098.250732,764.285889 2095.180664,764.036865 
	C2091.593994,763.745972 2087.965820,763.968933 2084.247559,763.968933 
	C2083.776611,767.393250 2083.395508,770.164429 2082.790771,773.220825 
	C2081.369385,773.663696 2080.171631,773.821350 2078.998535,773.596313 
	C2078.185791,771.562744 2077.729980,769.450806 2076.422119,768.366760 
	C2074.359863,766.657288 2071.617188,764.414062 2069.358643,764.614075 
	C2065.976318,764.913513 2061.448242,762.189392 2059.031982,767.203003 
	C2058.448486,768.413879 2056.892090,769.155701 2055.583496,770.088867 
	C2055.383789,770.064575 2054.990234,769.975830 2055.018555,769.623779 
	C2056.026855,767.489258 2057.007080,765.706848 2058.079102,763.603516 
	C2058.282715,763.090149 2058.394287,762.897705 2058.870605,762.722534 
	C2064.995605,761.297668 2070.720703,761.229797 2077.850342,762.804565 
	C2075.577393,763.674011 2074.696777,764.010864 2073.578613,764.438538 
	C2075.484863,765.104858 2077.138428,765.682800 2079.272949,766.429016 
	C2078.934570,763.268494 2078.656006,760.668823 2078.488525,757.785645 
	C2078.120850,757.054626 2077.642578,756.606995 2077.143555,755.743652 
	C2077.142090,754.496338 2077.160889,753.664734 2077.179688,752.833191 
	C2077.586426,752.867737 2077.992920,752.902283 2078.938965,752.864868 
	C2079.788574,752.622192 2079.936035,752.359741 2079.920410,752.005493 
	C2079.640869,751.248718 2079.361572,750.492004 2079.270020,749.314819 
	C2079.317139,748.572327 2079.176270,748.250183 2079.035156,747.928101 
M2080.664551,755.723877 
	C2081.006104,755.519897 2081.347412,755.315918 2081.688965,755.111877 
	C2081.422852,754.831726 2081.156494,754.551514 2080.890381,754.271362 
	C2080.681396,754.610046 2080.472168,754.948792 2080.664551,755.723877 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2226.264160,902.826782 
	C2227.107910,903.670105 2227.951660,904.513489 2228.964844,905.604736 
	C2215.670410,905.852539 2202.206543,905.852539 2188.252930,905.852539 
	C2187.672363,913.852051 2186.707031,921.090454 2189.747070,928.441833 
	C2191.076904,931.657776 2190.551025,935.641052 2190.612793,939.632385 
	C2189.579834,940.003418 2188.801514,940.017944 2188.049805,939.567627 
	C2188.076660,935.995178 2188.076660,932.887634 2188.076660,930.148010 
	C2185.876709,929.153503 2184.283203,928.433105 2182.726562,927.695312 
	C2182.763428,927.677856 2182.864990,927.549377 2183.153320,927.330933 
	C2183.537598,925.644287 2183.633789,924.176025 2183.729736,922.707764 
	C2183.233887,922.655396 2182.738037,922.603027 2182.242188,922.550720 
	C2181.118652,923.397644 2179.995117,924.244568 2178.666504,925.114868 
	C2178.461670,925.138184 2178.055664,925.064270 2177.905762,924.719116 
	C2177.485840,921.854431 2177.080322,919.333618 2177.105469,916.817078 
	C2177.108887,916.476990 2179.275391,916.158630 2180.440430,915.830139 
	C2180.486328,916.145508 2180.532227,916.460876 2180.578125,916.776245 
	C2179.505615,917.959595 2178.433350,919.142944 2177.360840,920.326294 
	C2177.977539,920.799927 2178.770264,921.790833 2179.178223,921.651794 
	C2180.476318,921.209595 2182.285156,920.619446 2182.713135,919.592957 
	C2183.813721,916.953308 2184.623047,914.083618 2184.913818,911.240906 
	C2185.337158,907.100403 2185.204590,902.903076 2185.611572,898.828735 
	C2186.613525,899.879150 2187.312744,900.830811 2188.087891,902.199890 
	C2190.755859,903.059692 2193.338379,903.793213 2195.942383,903.878601 
	C2200.200439,904.018066 2202.134277,900.952576 2200.937988,896.513000 
	C2200.947998,896.552795 2200.902100,896.620789 2200.967773,896.255676 
	C2201.949707,895.827759 2203.340332,895.369629 2203.685303,895.783386 
	C2204.430908,896.677429 2204.640381,898.018677 2205.069824,899.363159 
	C2205.078613,899.793701 2205.100342,900.036865 2204.781982,900.436768 
	C2203.694092,901.439209 2202.959229,902.283691 2201.527588,903.928955 
	C2206.254883,903.928955 2209.724365,904.068909 2213.177734,903.893799 
	C2217.546631,903.672180 2221.902588,903.194214 2226.264160,902.826782 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2058.505615,762.705322 
	C2058.394287,762.897705 2058.282715,763.090149 2057.669434,763.660400 
	C2054.804932,764.327942 2052.442139,764.617798 2049.948730,764.682861 
	C2049.526611,764.270020 2049.235107,764.081848 2048.919922,763.534668 
	C2048.216553,762.609497 2047.536987,762.043396 2046.699463,761.344849 
	C2046.441772,760.882202 2046.555054,760.661499 2047.171387,760.231812 
	C2047.972290,757.106689 2048.482910,754.300110 2049.212402,751.232178 
	C2049.501221,750.822388 2049.606689,750.679138 2049.629639,750.523682 
	C2049.648193,750.398254 2049.593018,750.156189 2049.526367,750.139099 
	C2045.172241,749.022766 2044.229248,749.877991 2044.091797,755.064331 
	C2044.134399,755.040405 2044.207642,755.105042 2043.869629,754.933594 
	C2043.018066,753.181641 2042.504272,751.601013 2041.994263,749.754150 
	C2041.990601,749.310425 2041.983276,749.133057 2041.918213,748.616089 
	C2039.899048,746.681030 2037.937622,745.085510 2035.617432,743.345337 
	C2033.401367,743.184265 2031.544067,743.167847 2029.258057,743.188721 
	C2026.222534,743.169067 2023.615601,743.112122 2021.011230,742.813538 
	C2021.013672,742.571899 2021.013916,742.088806 2021.443848,742.012878 
	C2024.192627,741.890015 2026.511230,741.843018 2029.230713,741.866821 
	C2030.441895,741.894958 2031.252563,741.852295 2032.267334,741.831909 
	C2032.471558,741.854187 2032.860107,741.721130 2033.290649,741.737244 
	C2034.578369,741.682251 2035.435669,741.611145 2036.637695,741.630554 
	C2038.895630,743.245911 2040.722778,744.898682 2042.746338,746.259521 
	C2044.751099,747.607727 2046.953735,748.661743 2050.202637,750.478149 
	C2050.202637,744.131042 2050.429688,739.666016 2050.113770,735.239624 
	C2049.936035,732.750732 2048.861816,730.325806 2048.484863,727.564880 
	C2048.975098,727.054382 2049.172363,726.851013 2049.449707,726.607422 
	C2049.530273,726.567261 2049.689941,726.485413 2049.689941,726.485413 
	C2050.534180,727.544983 2051.378662,728.604492 2052.481934,729.887024 
	C2052.741211,736.751465 2052.741211,743.393005 2052.741211,750.625488 
	C2054.036377,750.797791 2056.233154,751.090027 2058.710938,751.858398 
	C2058.992432,752.334595 2059.016113,752.818359 2058.755371,753.137695 
	C2058.562012,755.088684 2058.629639,756.720215 2058.654297,758.712280 
	C2058.576172,760.283630 2058.540771,761.494446 2058.505615,762.705322 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2084.754639,729.467529 
	C2089.910156,730.487000 2095.089355,731.401123 2100.211914,732.565308 
	C2103.312256,733.269775 2105.451172,732.844177 2108.576172,731.098267 
	C2113.077637,728.583740 2119.027344,728.662476 2124.639648,728.191650 
	C2124.947998,729.118347 2124.975830,729.487793 2124.626953,729.830078 
	C2119.393799,730.494751 2114.537109,731.186707 2109.680420,731.878662 
	C2109.704590,732.436951 2109.728516,732.995239 2109.752686,733.553589 
	C2110.973633,734.527588 2112.194580,735.501587 2113.107178,736.692505 
	C2111.491455,738.321716 2110.184326,739.734009 2108.465332,741.010498 
	C2103.499023,740.772705 2102.106689,742.343140 2102.803711,746.635498 
	C2103.190186,749.017090 2103.166016,751.492310 2103.793457,753.798340 
	C2104.217529,755.357666 2105.381104,756.747864 2106.352051,758.115479 
	C2107.062744,759.116455 2107.998779,759.957764 2108.588379,761.129272 
	C2108.515869,762.964172 2108.689697,764.540039 2108.854736,766.351807 
	C2108.846191,766.587769 2108.884033,767.060059 2108.564453,767.136597 
	C2107.397217,767.396545 2106.549805,767.579956 2105.900146,767.388184 
	C2105.162842,764.193970 2104.262451,761.362610 2103.274170,758.562073 
	C2102.900146,757.501953 2101.944580,756.527954 2101.903564,755.489258 
	C2101.688721,750.017029 2101.681396,744.536743 2101.956543,738.948242 
	C2103.112549,738.202148 2103.914307,737.566956 2105.135254,736.599365 
	C2101.658447,735.667725 2098.874756,734.624207 2096.008545,734.292542 
	C2094.768799,734.149109 2093.359131,735.475098 2091.688232,736.109009 
	C2090.898193,736.057617 2090.446533,736.030884 2090.119141,735.697937 
	C2090.585938,731.395935 2086.868896,732.251465 2085.002930,730.707214 
	C2084.956543,730.125061 2084.855713,729.796326 2084.754639,729.467529 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1918.973633,690.274963 
	C1916.678589,695.738525 1920.439575,699.376221 1922.768066,703.792725 
	C1922.606323,704.188293 1922.304077,704.060181 1921.945068,703.926453 
	C1918.576294,702.093506 1915.566284,700.394226 1912.090942,698.432190 
	C1913.781738,698.094482 1914.961060,697.858887 1915.116211,697.827881 
	C1914.290771,695.989319 1913.630737,694.103455 1912.636963,692.413940 
	C1911.471680,690.432617 1910.016235,688.621948 1907.928833,685.663147 
	C1907.928833,691.797485 1907.928833,696.414062 1907.708740,701.019043 
	C1907.488647,701.007507 1907.048706,701.034363 1907.048828,701.034363 
	C1907.025391,696.218506 1906.849731,691.396240 1907.054077,686.590027 
	C1907.134888,684.687927 1908.060059,682.821777 1908.587280,680.986328 
	C1901.265625,680.986328 1893.001343,680.986328 1884.240479,680.978271 
	C1885.229980,680.328613 1886.693848,679.201904 1888.206055,679.133179 
	C1893.475342,678.893372 1898.761719,679.045105 1904.041138,679.001038 
	C1911.039917,678.942688 1911.039795,678.923645 1911.216431,671.331726 
	C1912.063843,670.866943 1912.569336,670.926147 1913.006226,670.893616 
	C1912.937744,670.801880 1913.055786,670.605652 1913.055786,670.605652 
	C1915.094238,678.416992 1914.342041,681.642822 1909.748047,684.790039 
	C1911.572266,688.948914 1914.248047,687.737732 1917.570068,686.269165 
	C1918.406982,687.821716 1918.690308,689.048340 1918.973633,690.274963 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1908.975342,668.032166 
	C1905.964111,669.268066 1903.597534,674.698486 1900.746338,671.000732 
	C1896.877319,670.590637 1894.375000,670.285950 1891.864380,670.083923 
	C1891.137451,670.025391 1890.245605,670.036743 1889.669678,670.398499 
	C1885.734131,672.870178 1882.483154,671.768250 1879.617065,668.547363 
	C1879.071777,669.378845 1878.598511,670.100464 1877.386230,671.948792 
	C1877.207397,668.557251 1877.094971,666.423767 1876.930664,663.304077 
	C1878.815308,664.802795 1879.904297,665.668762 1879.998413,665.743530 
	C1889.882324,662.759705 1899.274658,665.424377 1908.748779,664.697754 
	C1909.074463,666.027222 1909.024902,667.029663 1908.975342,668.032166 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2235.934814,979.810364 
	C2234.334717,980.671814 2232.297119,982.611938 2231.223145,982.176331 
	C2228.774414,981.183411 2226.956299,981.665955 2224.523438,982.054382 
	C2220.513184,982.694580 2216.245605,981.730225 2212.087402,981.429260 
	C2211.509033,981.387512 2210.936768,981.211304 2210.360107,981.202576 
	C2208.492432,981.174194 2206.624023,981.194885 2204.755859,981.196655 
	C2205.334229,978.902527 2205.912842,976.608459 2206.491455,974.314331 
	C2207.056396,974.291992 2207.621094,974.269653 2208.185791,974.247314 
	C2208.704834,975.654541 2209.224121,977.061829 2209.743164,978.469116 
	C2210.158203,978.130249 2210.572998,977.791382 2210.988037,977.452576 
	C2211.416260,977.284424 2211.932129,977.216919 2212.258301,976.931885 
	C2214.493408,974.978638 2216.654785,973.201965 2219.596436,975.963379 
	C2220.005615,976.347351 2221.260742,975.783630 2222.128418,975.735168 
	C2224.369141,975.610046 2226.612061,975.527832 2228.854248,975.428467 
	C2228.885254,975.870239 2228.916504,976.311951 2228.947510,976.753723 
	C2230.554199,975.850159 2232.160889,974.946594 2233.767578,974.043030 
	C2234.068604,974.443359 2234.369629,974.843750 2234.670654,975.244141 
	C2233.232422,976.497192 2231.793945,977.750305 2230.355713,979.003357 
	C2230.454102,979.322021 2230.552490,979.640747 2230.650879,979.959412 
	C2232.215332,979.646790 2233.780029,979.334167 2235.641602,979.298340 
	C2235.938721,979.575134 2235.934814,979.810364 2235.934814,979.810364 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2188.023193,940.032471 
	C2188.801514,940.017944 2189.579834,940.003418 2190.601807,940.062988 
	C2191.617676,940.929260 2192.529785,942.501709 2193.140381,942.392151 
	C2198.837402,941.369812 2202.884766,945.052368 2207.884521,947.509033 
	C2208.255127,948.221680 2208.193604,948.518005 2208.088379,948.805298 
	C2206.531006,948.338745 2204.797607,948.164917 2203.447266,947.353699 
	C2198.342041,944.287231 2193.460693,943.659180 2188.329102,947.697144 
	C2188.229492,946.528870 2188.515869,945.622009 2188.917969,944.348877 
	C2187.815674,944.348877 2186.821289,944.348877 2185.826904,944.348877 
	C2167.941650,944.477234 2150.054932,944.534302 2132.173340,944.868469 
	C2130.735352,944.895386 2129.333252,946.828247 2127.799805,947.959961 
	C2127.756104,947.821777 2127.426758,946.780762 2127.006348,945.053223 
	C2126.903809,943.854065 2126.892822,943.341309 2126.962891,942.447021 
	C2127.052979,938.563049 2129.007080,938.409546 2132.479004,939.613403 
	C2130.655762,940.182312 2129.320557,940.598938 2127.985596,941.015625 
	C2129.855469,942.717773 2130.301270,943.001709 2130.744629,942.998413 
	C2148.337646,942.868835 2165.931641,942.773926 2183.521240,942.452759 
	C2185.036133,942.425049 2186.523193,940.875183 2188.023193,940.032471 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2301.974854,785.480347 
	C2301.910889,787.319153 2301.841064,788.787109 2301.739014,790.935913 
	C2300.110840,790.061035 2298.915283,789.418762 2297.696777,788.764099 
	C2293.130127,792.944824 2290.543213,792.648987 2288.321533,787.671021 
	C2285.915771,788.634399 2283.579346,789.906555 2281.084961,790.443176 
	C2279.577148,790.767700 2277.232666,790.481384 2276.348633,789.496338 
	C2275.485107,788.534424 2275.344482,785.871399 2276.095703,784.811340 
	C2276.833740,783.769287 2279.067139,783.588379 2280.686279,783.411072 
	C2283.795166,783.070740 2287.091309,783.541504 2290.022705,782.689209 
	C2294.288086,781.448914 2297.032715,782.962769 2298.313965,784.699280 
	C2300.383057,784.931458 2301.176270,785.020447 2301.974854,785.480347 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1713.999268,666.986084 
	C1711.740479,667.222473 1709.275513,666.972046 1707.275146,667.818848 
	C1704.791626,668.869995 1701.006836,670.590759 1700.745361,672.440857 
	C1699.979492,677.859009 1696.961914,678.224792 1692.483154,677.682739 
	C1692.392822,677.158752 1692.659912,676.714417 1693.365234,676.348267 
	C1697.996338,677.178345 1698.999268,674.470398 1699.208862,671.219421 
	C1699.462891,667.274597 1696.473511,667.774231 1694.026001,667.791321 
	C1688.044800,667.833130 1682.064209,667.952637 1675.852539,668.094360 
	C1674.919189,668.090027 1674.216919,668.032288 1673.358154,667.663513 
	C1671.959717,665.826294 1670.717651,664.300110 1669.912354,662.610352 
	C1670.616455,662.288086 1670.883789,662.129395 1671.151123,661.970642 
	C1672.735718,662.757324 1674.289673,663.615662 1675.914185,664.309082 
	C1677.329712,664.913391 1678.818481,665.737122 1680.285889,665.760132 
	C1685.898926,665.848328 1691.526489,665.816101 1697.126831,665.473389 
	C1699.068848,665.354553 1700.947754,664.202881 1701.510498,664.002319 
	C1706.238281,664.640503 1709.789795,665.119873 1713.809326,665.903381 
	C1714.210815,666.476379 1714.118164,666.735901 1713.999268,666.986084 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2283.978027,872.164673 
	C2283.977539,873.128662 2283.878418,873.776306 2283.812256,874.427307 
	C2283.679932,875.721558 2283.565918,877.017761 2283.444336,878.313110 
	C2282.073730,877.806885 2280.702881,877.300659 2278.850098,876.451416 
	C2277.928467,875.360352 2277.489014,874.612427 2277.035156,873.502075 
	C2277.479736,871.643860 2277.938721,870.148010 2278.611572,867.955078 
	C2276.352539,864.930786 2273.723389,861.410767 2270.978027,857.682983 
	C2270.666016,857.212524 2270.415527,857.022278 2270.001221,856.685913 
	C2269.690430,856.199036 2269.430420,856.010986 2269.081543,855.608154 
	C2268.706787,854.865601 2268.363281,854.418213 2267.923828,853.749695 
	C2267.647949,853.247498 2267.402344,853.046936 2267.081055,852.583130 
	C2266.684814,851.339600 2266.298828,850.439880 2265.507324,848.595581 
	C2265.145996,850.521606 2264.998047,851.310852 2264.533691,851.999756 
	C2263.480469,850.601562 2262.744141,849.303650 2262.336914,847.856079 
	C2262.603271,846.145508 2262.913818,844.442627 2262.387207,843.058228 
	C2261.668213,841.167419 2260.311768,839.519165 2259.475830,837.693542 
	C2260.039307,837.726685 2260.355469,837.833130 2260.671875,837.939575 
	C2268.412842,849.243042 2276.153564,860.546448 2283.978027,872.164673 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2097.069092,986.039734 
	C2097.281250,985.037292 2097.966309,983.819702 2097.624756,983.068970 
	C2096.001709,979.501404 2097.825439,977.513184 2100.341553,975.710876 
	C2100.415039,976.182861 2100.488525,976.654907 2100.561768,977.126892 
	C2102.475342,977.126892 2104.394043,977.204163 2106.299561,977.080750 
	C2106.887451,977.042603 2107.446289,976.157654 2108.007568,976.169556 
	C2112.653076,976.267700 2117.295898,976.485596 2122.217529,976.980225 
	C2121.969727,978.582947 2121.444092,979.872620 2120.918213,981.162292 
	C2118.074219,984.047241 2115.082275,982.810364 2111.550537,982.528748 
	C2107.084717,982.172607 2102.393311,984.639709 2097.416016,985.954956 
	C2097.032959,986.020874 2097.069092,986.039734 2097.069092,986.039734 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1954.957764,624.745483 
	C1957.696167,624.542908 1960.434448,624.340332 1963.788330,624.260376 
	C1964.799927,624.670654 1965.196167,624.958435 1965.592285,625.246216 
	C1965.581421,626.724426 1965.519287,628.204407 1965.568237,629.680664 
	C1965.836548,637.770264 1966.863647,637.210571 1957.564697,637.666565 
	C1952.790405,637.900696 1951.263916,636.191589 1952.494873,631.329285 
	C1953.592285,628.846375 1954.275024,626.795959 1954.957764,624.745483 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2336.860107,785.174377 
	C2335.281494,785.606201 2333.702881,786.038086 2332.124268,786.469971 
	C2333.263428,787.459900 2334.402832,788.449951 2336.212646,790.022766 
	C2333.473633,790.022766 2330.984375,790.022766 2328.710693,790.022766 
	C2328.051758,788.225098 2327.384033,786.403320 2326.281738,783.396057 
	C2325.069580,786.768616 2324.457031,788.473511 2323.907959,790.000671 
	C2322.615234,788.022949 2321.447510,786.236389 2320.279785,784.449829 
	C2319.511963,788.544861 2318.438477,789.817810 2314.641846,790.322998 
	C2310.378174,790.890259 2308.406250,790.272583 2308.905029,786.217224 
	C2309.390869,782.267090 2311.536133,781.612000 2314.850586,783.822205 
	C2316.867676,783.129211 2318.487305,782.052917 2320.049316,782.130371 
	C2325.483643,782.400024 2330.897949,783.069580 2336.628906,784.058594 
	C2336.911865,784.744873 2336.885986,784.959595 2336.860107,785.174377 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2163.601562,756.560303 
	C2161.903076,756.973450 2160.217285,756.962219 2158.531738,756.950989 
	C2158.928711,758.437561 2159.325439,759.924133 2159.498535,761.864319 
	C2158.528564,762.154907 2157.781982,761.992004 2156.900879,761.570801 
	C2156.011230,761.170776 2155.256836,761.028992 2154.328125,760.526245 
	C2152.775146,760.435730 2151.396240,760.706177 2149.978516,760.678101 
	C2150.038574,759.990601 2150.137695,759.601746 2150.564209,759.004150 
	C2151.599121,758.447083 2152.285156,758.042419 2153.019043,757.762878 
	C2154.275391,757.284241 2155.561768,756.883667 2156.835449,756.450317 
	C2156.232666,755.213440 2155.642334,753.970276 2155.021729,752.742432 
	C2154.725098,752.155823 2154.371338,751.598267 2154.243896,750.767212 
	C2155.424072,750.102539 2156.404053,749.698120 2157.699707,749.319214 
	C2158.737061,748.980408 2159.458008,748.616211 2160.179199,748.252075 
	C2164.056396,743.692139 2163.800537,736.017578 2171.506592,733.715210 
	C2170.541504,741.377930 2165.786133,746.540955 2161.518066,752.233398 
	C2162.132324,753.376831 2162.873291,754.756409 2163.601562,756.560303 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1972.503418,679.278442 
	C1966.975830,678.875061 1961.111938,678.447144 1954.721680,677.657715 
	C1951.851929,673.797058 1949.508301,670.297791 1947.160889,666.495605 
	C1947.400635,665.431274 1947.644165,664.669861 1947.887695,663.908447 
	C1948.303955,663.274902 1948.720337,662.641418 1949.516113,661.960693 
	C1949.895508,661.913391 1950.181152,662.141235 1950.195190,662.452332 
	C1951.669678,663.356262 1953.130127,663.949036 1954.670166,664.774170 
	C1954.861572,665.320801 1954.942627,665.642883 1954.610840,665.986694 
	C1952.997070,666.127563 1951.765137,666.254456 1949.502808,666.487427 
	C1953.150024,670.183167 1952.616333,676.196777 1958.611572,676.683716 
	C1960.197144,676.812561 1961.857788,676.014709 1964.343872,675.445618 
	C1966.858765,677.814148 1968.015503,677.656616 1970.484741,673.332153 
	C1971.468506,671.609192 1972.425903,669.871155 1973.623535,667.857849 
	C1973.873169,666.365051 1973.894165,665.154358 1973.850342,663.610229 
	C1972.121826,660.254944 1970.458008,657.233215 1968.991455,654.014587 
	C1969.308350,653.675598 1969.427856,653.533386 1969.547241,653.391235 
	C1971.770386,656.846619 1974.210083,660.190247 1976.086304,663.824707 
	C1976.657715,664.931824 1976.006714,667.002869 1975.342773,668.350220 
	C1973.607056,671.872498 1971.554810,675.238770 1969.446533,678.991272 
	C1970.568848,679.099609 1971.367920,679.176758 1972.503418,679.278442 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2218.869629,945.272583 
	C2221.967773,945.124878 2225.065918,944.977234 2228.038086,944.835571 
	C2228.038086,949.216125 2228.038086,953.178711 2228.038086,958.292969 
	C2225.125977,958.292969 2221.468018,958.486877 2217.863770,958.133667 
	C2217.120361,958.060791 2216.576904,955.949219 2216.276855,954.125366 
	C2217.365234,950.739868 2218.117432,948.006226 2218.869629,945.272583 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2081.604980,948.856750 
	C2079.134033,945.573853 2075.705078,945.209839 2071.927002,945.424561 
	C2067.642578,945.668152 2063.292236,945.209595 2059.055908,945.739441 
	C2052.521240,946.556885 2047.442383,944.671265 2043.567749,938.928833 
	C2043.574097,938.462402 2043.749878,938.271484 2044.494385,937.981934 
	C2047.891724,940.209290 2049.706299,943.696411 2054.379150,943.576111 
	C2070.248779,943.167236 2086.128662,943.146423 2102.005371,943.291138 
	C2101.991699,944.022583 2101.977783,944.440491 2101.498535,944.918457 
	C2094.568848,944.978638 2088.104980,944.978638 2080.694580,944.978638 
	C2081.942871,946.594055 2082.421875,947.213928 2082.588379,948.104248 
	C2082.052246,948.535400 2081.828613,948.696106 2081.604980,948.856750 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2120.988770,981.501709 
	C2121.444092,979.872620 2121.969727,978.582947 2122.580078,977.241150 
	C2122.891113,976.436401 2123.117676,975.683777 2123.732178,974.848633 
	C2124.120361,974.766174 2123.995117,974.765320 2123.995117,974.765259 
	C2126.639893,973.667419 2129.284912,972.569641 2130.624512,972.013550 
	C2128.547119,974.165161 2125.598389,977.219482 2122.422852,980.508545 
	C2127.675293,981.653564 2129.827148,980.252869 2130.138672,975.461914 
	C2134.479736,976.123535 2138.761475,976.776123 2144.194580,977.604187 
	C2144.194580,979.357727 2144.194580,982.794556 2144.194580,986.231445 
	C2143.923828,986.328186 2143.653076,986.424927 2143.382324,986.521729 
	C2142.943604,984.652222 2142.504639,982.782776 2141.923096,980.305359 
	C2140.245850,980.738464 2138.568604,981.171692 2136.559814,981.690430 
	C2136.393555,980.544189 2136.172119,979.019348 2135.956787,977.535522 
	C2132.661865,979.454285 2129.619385,981.632874 2126.246338,982.990295 
	C2124.891113,983.535767 2122.809814,982.278137 2120.988770,981.501709 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2002.006470,619.957886 
	C2001.393555,620.645813 2000.780640,621.333740 2001.130371,620.941223 
	C1998.307861,620.941223 1996.408691,620.803162 1994.536987,620.971436 
	C1991.182129,621.272949 1988.283936,626.069519 1984.390747,621.962769 
	C1984.158447,621.717773 1982.750366,622.880859 1981.894531,622.872498 
	C1980.140381,622.855469 1978.389526,622.487610 1976.390381,621.664673 
	C1976.410156,620.026611 1976.677002,618.988098 1976.943848,617.949585 
	C1979.444824,615.586792 1981.969849,613.850708 1984.616943,617.465637 
	C1986.541382,616.754639 1988.446533,616.050781 1990.351685,615.346985 
	C1990.551636,615.729126 1990.751465,616.111267 1990.951416,616.493469 
	C1989.591797,617.660522 1988.232178,618.827576 1986.872559,619.994690 
	C1987.026123,620.361633 1987.179688,620.728577 1987.333130,621.095520 
	C1988.662476,620.650635 1990.854004,620.538147 1991.175415,619.704590 
	C1992.767944,615.574097 1996.747681,616.867493 1999.672852,616.029541 
	C2000.547729,617.658691 2001.277100,618.808289 2002.006470,619.957886 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2205.954346,781.923584 
	C2206.683838,770.197876 2207.462646,758.474792 2208.109375,746.744568 
	C2208.278564,743.673462 2209.579102,742.139526 2212.689453,742.157288 
	C2215.349609,742.172485 2218.029297,742.292297 2220.663818,742.016113 
	C2222.334473,741.840881 2223.912842,740.859192 2225.588135,740.603271 
	C2226.060791,740.531067 2226.729736,741.743713 2227.560791,742.971558 
	C2227.884521,743.821411 2227.956299,744.066711 2228.028076,744.312012 
	C2227.237061,744.860962 2226.408936,745.928711 2225.661621,745.875000 
	C2220.668457,745.515564 2215.692627,744.916809 2209.763672,744.296570 
	C2209.763672,756.211487 2209.790527,766.911072 2209.728516,777.610107 
	C2209.721191,778.896851 2209.232666,780.180847 2208.511230,781.780396 
	C2207.355225,782.037659 2206.654785,781.980652 2205.954346,781.923584 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2309.538818,839.117432 
	C2306.465332,838.930786 2303.715820,839.097168 2301.055176,838.685913 
	C2299.788574,838.490112 2298.691895,837.194946 2297.306396,836.254089 
	C2296.485352,837.014282 2295.503662,837.922913 2294.061523,839.257812 
	C2294.061523,836.612061 2294.061523,834.688538 2294.061523,832.730896 
	C2298.601074,837.091980 2304.011475,825.864746 2307.756348,833.048218 
	C2310.123047,832.548950 2312.240234,831.668335 2314.207275,831.927490 
	C2314.877930,832.015747 2316.059082,835.799805 2315.456299,836.443481 
	C2314.127197,837.863281 2311.812012,838.359985 2309.538818,839.117432 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2225.008789,796.000366 
	C2224.003662,795.254333 2222.984863,794.507874 2221.991211,793.374023 
	C2222.016357,792.986633 2222.004639,793.016602 2222.252930,792.952759 
	C2222.669922,792.583740 2222.838867,792.278442 2223.008057,791.973267 
	C2223.008057,791.973267 2223.009033,792.008545 2223.261719,791.956787 
	C2223.677490,791.594604 2223.840332,791.284241 2224.003418,790.973877 
	C2224.003418,790.973877 2224.008789,791.006775 2224.283203,790.874390 
	C2224.706787,790.151123 2224.855957,789.560303 2225.004883,788.969421 
	C2225.004883,788.969421 2225.008057,789.007568 2225.308594,789.074707 
	C2226.072998,788.753052 2226.536621,788.364319 2227.000488,787.975586 
	C2227.000488,787.975525 2227.001709,788.002258 2227.251465,787.942749 
	C2227.665039,787.581299 2227.828613,787.279358 2227.992432,786.977539 
	C2227.992432,786.977539 2228.000000,787.000000 2228.251465,786.941528 
	C2228.665771,786.581421 2228.828369,786.279724 2228.990967,785.978027 
	C2228.990967,785.978027 2229.000000,786.000793 2229.296875,785.882080 
	C2229.727051,785.166870 2229.860596,784.570312 2229.994141,783.973755 
	C2229.994141,783.973755 2230.000000,784.000854 2230.307129,784.081299 
	C2231.079102,783.766174 2231.543945,783.370605 2232.008545,782.974976 
	C2232.008545,782.974915 2232.007324,783.008301 2232.257080,782.948547 
	C2232.675293,782.583435 2232.843750,782.278076 2233.012207,781.972656 
	C2233.012207,781.972656 2233.009766,782.009888 2233.257324,781.950073 
	C2233.673828,781.584412 2233.843262,781.278503 2234.012451,780.972595 
	C2234.012451,780.972595 2234.010010,781.009949 2234.256836,780.950806 
	C2234.671387,780.585693 2234.838867,780.279663 2235.006104,779.973633 
	C2235.006104,779.973572 2235.009033,780.007446 2235.296143,779.888428 
	C2235.722412,779.170044 2235.861328,778.570618 2236.000488,777.971252 
	C2236.000488,777.971252 2236.006836,778.005371 2236.344971,778.036499 
	C2238.796631,775.240784 2240.910400,772.414001 2243.023926,769.587158 
	C2243.104248,769.425476 2243.214600,769.288086 2243.802002,769.067627 
	C2244.505615,768.629639 2244.762695,768.299133 2245.020020,767.968628 
	C2245.020020,767.968628 2245.015137,768.015381 2245.265381,767.963867 
	C2245.683105,767.597168 2245.850586,767.281860 2246.018066,766.966553 
	C2246.018066,766.966553 2246.013184,767.013367 2246.333496,767.015747 
	C2247.105713,766.335510 2247.557617,765.652893 2248.009521,764.970215 
	C2248.009521,764.970276 2248.009033,765.009033 2248.303711,765.051025 
	C2249.075684,764.720337 2249.552490,764.347717 2250.029541,763.975098 
	C2250.029541,763.975159 2250.019287,764.019409 2250.256104,763.850342 
	C2250.663086,763.111145 2250.833496,762.540955 2251.003906,761.970764 
	C2251.003906,761.970764 2251.009033,762.006897 2251.272705,761.973511 
	C2251.693359,761.617493 2251.850342,761.294861 2252.007324,760.972290 
	C2252.007568,760.972290 2252.008057,761.008057 2252.343994,761.017090 
	C2254.873047,759.891418 2256.291016,760.040344 2255.995605,763.027771 
	C2255.995605,763.027771 2255.990967,762.992371 2255.739746,763.044678 
	C2255.326660,763.406189 2255.164551,763.715332 2255.002441,764.024475 
	C2255.002441,764.024475 2254.997803,763.997742 2254.742676,764.049255 
	C2254.324707,764.408997 2254.161865,764.717163 2253.999023,765.025391 
	C2253.999023,765.025391 2253.995361,764.995544 2253.744629,765.052246 
	C2253.331787,765.413452 2253.169678,765.718018 2253.007568,766.022583 
	C2253.007568,766.022583 2253.000000,766.000000 2252.745117,766.054443 
	C2252.323486,766.414856 2252.156738,766.720764 2251.990234,767.026733 
	C2251.990234,767.026733 2251.990967,766.990967 2251.738770,767.046082 
	C2251.324219,767.409363 2251.161621,767.717529 2250.999023,768.025635 
	C2250.999023,768.025635 2250.995361,767.995483 2250.737793,768.040710 
	C2250.317871,768.399475 2250.156006,768.713074 2249.993896,769.026611 
	C2249.993896,769.026611 2249.991699,768.992065 2249.738770,769.045410 
	C2249.325439,769.407227 2249.164795,769.715637 2249.004150,770.024048 
	C2249.004150,770.024048 2248.998779,769.999390 2248.710693,770.086670 
	C2248.119141,770.461853 2247.815430,770.749817 2247.511963,771.037781 
	C2247.511963,771.037781 2247.208008,771.380493 2246.930664,771.703003 
	C2246.425049,772.362854 2246.197266,772.700134 2245.969482,773.037476 
	C2245.969482,773.037476 2245.985352,772.986328 2245.765625,773.074768 
	C2245.357666,773.450745 2245.169434,773.738281 2244.981445,774.025757 
	C2244.981445,774.025757 2244.984619,773.986267 2244.694824,774.113525 
	C2242.609131,776.500488 2240.813232,778.760132 2239.017334,781.019775 
	C2239.017334,781.019775 2239.000000,781.000000 2238.739258,781.049316 
	C2238.317627,781.407776 2238.156982,781.716980 2237.996338,782.026184 
	C2237.996338,782.026184 2237.994873,781.994568 2237.681885,782.022339 
	C2236.916992,782.707397 2236.465088,783.364563 2236.013184,784.021729 
	C2236.013184,784.021790 2236.000000,784.001648 2235.742188,784.052490 
	C2235.325684,784.409729 2235.166992,784.716064 2235.008057,785.022461 
	C2235.008057,785.022461 2235.000000,785.000000 2234.744629,785.052673 
	C2234.322754,785.412476 2234.156006,785.719543 2233.989258,786.026611 
	C2233.989258,786.026611 2233.990479,785.991272 2233.724121,786.087158 
	C2232.965332,786.796570 2232.472900,787.410217 2231.980713,788.023804 
	C2231.980713,788.023804 2231.986084,787.985596 2231.725098,788.091187 
	C2230.969727,788.805908 2230.475586,789.415100 2229.981201,790.024292 
	C2229.981201,790.024292 2229.987549,789.984924 2229.714111,790.092468 
	C2229.127930,790.478333 2228.815430,790.756653 2228.502930,791.035034 
	C2228.502930,791.035034 2228.205322,791.374207 2227.922363,791.688843 
	C2227.420166,792.344238 2227.200439,792.685059 2226.980713,793.025879 
	C2226.980713,793.025879 2226.983643,792.986023 2226.699707,793.122681 
	C2225.944092,794.182190 2225.472656,795.104980 2225.000977,796.027771 
	C2225.000977,796.027771 2224.994873,795.999939 2225.008789,796.000366 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1901.106689,654.197571 
	C1900.070068,656.211914 1899.033325,658.226196 1897.717285,660.783447 
	C1896.411255,658.931885 1895.522339,657.671631 1894.927490,656.828369 
	C1889.094849,662.369385 1881.591919,658.485352 1875.166504,661.046814 
	C1875.166504,658.146240 1875.166504,655.719666 1875.166504,653.153442 
	C1875.302490,654.372437 1875.250122,655.558411 1875.608887,656.603149 
	C1875.902344,657.457275 1876.723389,658.789551 1877.245117,658.759949 
	C1878.154541,658.708313 1879.514160,657.960815 1879.812134,657.174316 
	C1881.712036,652.160645 1885.288574,652.139404 1889.743774,653.703979 
	C1889.946289,654.956604 1890.173584,656.362366 1890.400879,657.768127 
	C1890.701538,657.839966 1891.002319,657.911865 1891.302979,657.983704 
	C1891.910034,656.318237 1892.517212,654.652832 1893.358765,652.344543 
	C1894.795776,653.891479 1895.957886,655.142456 1897.266602,656.551331 
	C1898.292358,655.310791 1899.300293,654.091919 1900.666260,653.180908 
	C1901.051880,653.724976 1901.079224,653.961304 1901.106689,654.197571 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1743.529541,680.992920 
	C1739.010376,681.650146 1734.029419,682.790344 1729.033813,682.858093 
	C1711.647095,683.093811 1694.255127,682.957886 1676.416504,682.576843 
	C1676.459351,681.039062 1676.950928,679.887756 1677.719238,678.827148 
	C1678.376953,678.995544 1678.758179,679.073242 1679.157715,679.518677 
	C1680.961548,680.242432 1682.746948,680.598328 1684.928589,680.970337 
	C1685.958862,680.600952 1686.592773,680.215515 1687.585205,679.862793 
	C1688.875610,679.851318 1689.807739,679.807068 1690.739746,679.762817 
	C1692.562866,680.215454 1694.384521,681.053162 1696.209351,681.060120 
	C1711.826294,681.120178 1727.443726,681.029724 1743.529541,680.992920 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1712.916016,649.913452 
	C1710.905273,649.968689 1708.894531,650.023926 1706.447998,649.718506 
	C1706.056519,648.953552 1706.100830,648.549255 1706.480469,647.979370 
	C1706.910645,647.385071 1707.005371,646.956482 1707.112061,646.549927 
	C1707.123901,646.572083 1707.170532,646.553101 1707.579346,646.586426 
	C1708.999512,646.120422 1710.010864,645.621155 1711.022217,645.121887 
	C1710.913452,644.799805 1710.804810,644.477722 1710.696045,644.155640 
	C1709.324097,644.155640 1707.952026,644.155640 1706.673096,643.758972 
	C1709.375122,641.199341 1711.575684,640.782898 1712.551758,645.115967 
	C1712.446411,646.279053 1712.537964,647.087158 1712.629517,647.895203 
	C1712.924805,647.895386 1713.219971,647.895508 1713.515259,647.895630 
	C1713.640015,646.910278 1713.764648,645.924927 1713.932861,644.646729 
	C1714.227295,643.649109 1714.478271,642.944336 1714.779541,642.633667 
	C1715.123535,644.595337 1715.417236,646.162903 1715.793213,648.169312 
	C1717.555542,647.805115 1718.972778,647.512268 1720.390015,647.219360 
	C1716.225342,647.160095 1716.868164,644.506714 1717.517090,642.039917 
	C1719.616333,642.713440 1721.338379,643.460327 1723.296387,644.309509 
	C1724.541382,643.587524 1726.255859,642.593262 1727.751099,641.726196 
	C1728.608521,643.360107 1729.175903,644.441223 1729.786133,645.869385 
	C1729.776367,647.359009 1729.723633,648.501587 1729.670898,649.644165 
	C1724.812012,649.675415 1719.953369,649.706604 1714.499512,649.583862 
	C1713.575073,649.591125 1713.245483,649.752258 1712.916016,649.913452 
M1727.554565,645.098877 
	C1727.053833,645.056335 1726.553223,645.013794 1726.036621,645.376465 
	C1726.371826,645.488525 1726.707153,645.600586 1727.554565,645.098877 
M1722.376099,647.559875 
	C1722.376099,647.559875 1722.566650,647.693909 1722.566650,647.693909 
	C1722.566650,647.693909 1722.555420,647.422974 1722.376099,647.559875 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2084.402832,729.329773 
	C2084.855713,729.796326 2084.956543,730.125061 2084.698242,730.785095 
	C2083.522461,731.721924 2082.706055,732.327576 2081.699951,733.007141 
	C2080.949951,733.054749 2080.389404,733.028381 2079.829102,733.001953 
	C2078.224609,731.796692 2076.620361,730.591431 2074.335938,728.875305 
	C2076.326660,728.468567 2077.738281,728.180115 2079.009521,727.920410 
	C2080.223877,724.643799 2083.302002,720.290161 2082.201660,718.487915 
	C2079.875732,714.678589 2083.065918,712.689941 2083.218506,709.902649 
	C2083.260254,709.139771 2084.490234,708.441895 2085.905029,706.937866 
	C2087.621826,714.336914 2085.005859,720.103882 2083.885254,726.027161 
	C2083.694336,727.035522 2083.983398,728.134766 2084.402832,729.329773 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1778.976685,673.944092 
	C1778.693604,674.716064 1778.410522,675.488037 1778.004761,676.594421 
	C1776.077148,673.615906 1774.363892,671.092590 1771.670288,675.865601 
	C1771.021729,677.015137 1768.042847,677.634888 1766.403687,677.275574 
	C1763.077271,676.546204 1760.019287,676.064392 1756.739624,677.494019 
	C1756.336914,677.669556 1753.878540,674.610352 1754.052856,674.392273 
	C1755.392944,672.714539 1756.982544,670.465759 1758.813477,670.129456 
	C1761.616699,669.614685 1764.675171,670.488953 1768.873901,670.894043 
	C1766.823608,671.987488 1765.252441,672.825317 1763.681274,673.663147 
	C1764.111572,674.194641 1764.541870,674.726074 1764.972046,675.257568 
	C1767.107178,674.605408 1770.137817,675.510620 1770.102173,671.096924 
	C1770.097656,670.545166 1771.004150,669.985962 1771.908569,669.879028 
	C1773.265259,670.139282 1774.201904,669.950623 1775.138550,669.762024 
	C1775.554565,669.821960 1775.970459,669.881836 1776.705078,670.333252 
	C1777.674805,671.797852 1778.325806,672.870972 1778.976685,673.944092 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1901.427246,654.131958 
	C1901.079224,653.961304 1901.051880,653.724976 1901.000732,653.132080 
	C1905.804810,652.831238 1910.633667,652.961243 1915.460327,652.903198 
	C1917.169189,652.882690 1918.872559,652.403931 1921.772095,651.945679 
	C1921.918823,654.404602 1922.062134,656.805298 1922.050293,659.476990 
	C1917.785400,661.860718 1914.439941,661.076477 1910.937622,657.933838 
	C1909.322388,656.484314 1906.274414,656.631226 1903.451172,655.996826 
	C1902.600098,655.315002 1902.173950,654.690674 1901.427246,654.131958 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2159.892822,747.990417 
	C2159.458008,748.616211 2158.737061,748.980408 2157.562012,748.928467 
	C2155.489502,738.636353 2150.310059,735.966797 2140.889160,739.402039 
	C2138.268066,737.656494 2135.974121,734.854919 2133.962158,735.044678 
	C2129.287842,735.485535 2126.971436,733.331116 2125.003906,729.857239 
	C2124.975830,729.487793 2124.947998,729.118347 2124.910156,728.471680 
	C2129.833984,730.472412 2134.752686,732.784119 2139.714600,734.998596 
	C2140.672363,735.426147 2141.871094,735.831848 2142.838379,735.639954 
	C2151.865723,733.848450 2157.210449,737.318359 2159.112061,746.340393 
	C2159.212646,746.816833 2159.438721,747.266663 2159.892822,747.990417 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2175.020264,712.021851 
	C2186.496338,712.016907 2197.476562,712.016907 2208.456543,712.016907 
	C2208.452393,712.614014 2208.448242,713.211121 2208.444092,713.808167 
	C2193.394043,715.058716 2178.330322,715.126221 2163.259766,714.604858 
	C2163.229980,713.745483 2163.200195,712.886108 2163.170410,712.026733 
	C2166.955078,712.026733 2170.739746,712.026733 2175.020264,712.021851 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2260.786621,837.564148 
	C2260.355469,837.833130 2260.039307,837.726685 2259.202148,837.526245 
	C2258.506348,837.555237 2258.331543,837.678284 2258.114746,837.820435 
	C2258.072510,837.839600 2258.079346,837.932007 2258.264160,837.595337 
	C2257.574219,836.476013 2256.699951,835.693420 2255.556641,834.959717 
	C2254.853271,835.335632 2254.419189,835.662659 2253.970215,835.960266 
	C2253.955566,835.930908 2253.913574,835.880493 2253.913574,835.880493 
	C2254.002686,835.539673 2254.091553,835.198792 2254.587891,834.376587 
	C2254.572510,832.624512 2254.150146,831.353821 2253.728027,830.083130 
	C2253.778809,829.862061 2253.829590,829.640991 2253.938477,829.084839 
	C2255.315186,829.170959 2256.634033,829.592224 2258.296143,830.123108 
	C2259.074463,829.073425 2259.921143,827.931885 2260.921875,826.217834 
	C2261.054932,825.475708 2261.033936,825.306030 2261.316895,825.027039 
	C2262.419678,824.601746 2263.218994,824.285828 2264.041016,824.317505 
	C2264.705322,824.929749 2265.744141,825.580505 2265.923096,825.396362 
	C2267.044678,824.241333 2267.974609,822.900208 2268.968018,821.620605 
	C2270.409180,820.705627 2271.850586,819.790710 2273.291748,818.875732 
	C2273.778809,819.419495 2274.265869,819.963318 2274.752930,820.507080 
	C2272.968994,822.539429 2271.397461,824.826904 2269.344238,826.535767 
	C2267.080078,828.420105 2264.002686,829.417114 2262.005371,831.497498 
	C2260.881348,832.668579 2261.221191,835.245300 2260.786621,837.564148 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2249.773438,756.662476 
	C2247.759033,756.873718 2245.743896,757.077759 2243.730469,757.298035 
	C2240.950195,757.602112 2238.175781,757.968628 2235.390137,758.208435 
	C2233.987549,758.329224 2232.566162,758.229492 2229.987793,758.229492 
	C2232.190186,756.214478 2233.522705,754.995178 2235.380371,753.295654 
	C2233.677979,752.922363 2232.598145,752.685547 2230.937744,752.321472 
	C2231.931641,751.565552 2232.750244,750.596497 2233.085449,750.741943 
	C2236.117920,752.058044 2240.551270,747.683838 2242.579590,753.146484 
	C2244.422852,752.202881 2246.264648,751.259888 2248.106689,750.316956 
	C2248.552979,750.883789 2248.999268,751.450623 2249.445557,752.017395 
	C2247.747559,752.648010 2246.049561,753.278564 2244.351562,753.909119 
	C2244.788818,754.598999 2245.226074,755.288879 2245.663330,755.978699 
	C2246.845459,755.673157 2248.027832,755.367615 2249.567871,755.303467 
	C2249.875000,755.917480 2249.824219,756.289978 2249.773438,756.662476 
M2239.761475,755.524658 
	C2238.673340,755.267883 2237.585449,755.011108 2236.497314,754.754395 
	C2236.443604,755.110291 2236.389648,755.466187 2236.335693,755.822083 
	C2237.269043,755.884888 2238.202393,755.947693 2239.761475,755.524658 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2059.016113,752.818359 
	C2059.016113,752.818359 2058.992432,752.334595 2059.034424,752.094971 
	C2060.381348,752.043091 2061.681885,752.336304 2062.992676,752.393677 
	C2064.882080,752.476501 2066.780518,752.426208 2068.672363,752.356628 
	C2069.632324,752.321289 2070.585938,752.021301 2071.543701,752.014465 
	C2073.326172,752.001770 2075.110107,752.115906 2077.036621,752.505249 
	C2077.160889,753.664734 2077.142090,754.496338 2076.963867,755.955505 
	C2076.498291,757.038879 2076.192383,757.494690 2075.530273,758.087524 
	C2073.908691,758.426025 2072.639893,758.816528 2071.378174,758.794495 
	C2067.158936,758.720642 2062.942139,758.499268 2058.802246,757.877563 
	C2058.925537,755.886658 2058.970703,754.352539 2059.016113,752.818359 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2205.738770,782.146118 
	C2206.654785,781.980652 2207.355225,782.037659 2208.399902,782.174316 
	C2209.630127,781.796448 2210.784912,781.554749 2211.354980,780.843933 
	C2213.740723,777.869934 2215.751953,774.572998 2218.314453,771.773865 
	C2219.472168,770.509460 2221.599609,770.132935 2223.286865,769.353455 
	C2223.537842,769.808655 2223.789062,770.263916 2224.040039,770.719116 
	C2220.726074,775.060303 2217.280518,779.311523 2214.201416,783.813416 
	C2213.532471,784.791931 2214.176758,786.668762 2213.804688,788.593689 
	C2212.770264,788.946533 2212.146973,788.837463 2211.523926,788.728394 
	C2211.523926,788.728394 2211.375732,788.616699 2211.301270,788.558472 
	C2210.415771,788.004578 2209.604736,787.508972 2208.477295,787.015137 
	C2207.281494,785.467468 2206.402588,783.918030 2205.738770,782.146118 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2317.095703,970.404785 
	C2316.960205,969.590088 2316.977295,969.093323 2316.947266,967.874329 
	C2316.625977,966.585266 2316.351807,966.018433 2316.077637,965.451599 
	C2316.077637,965.451599 2316.086426,965.320251 2316.111328,965.353394 
	C2316.645264,964.631592 2317.154785,963.876648 2318.020508,962.593506 
	C2318.902344,964.670898 2319.561035,966.222168 2319.805420,966.797607 
	C2326.973633,965.574158 2333.694336,964.427124 2340.415283,963.280029 
	C2340.593262,964.144775 2340.771240,965.009460 2340.949219,965.874207 
	C2334.288086,967.381348 2327.626953,968.888489 2320.497070,970.501709 
	C2320.387695,971.177734 2320.176758,972.480469 2319.966064,973.783203 
	C2319.592529,973.898315 2319.218994,974.013428 2318.845459,974.128540 
	C2318.312988,972.993286 2317.780762,971.857971 2317.095703,970.404785 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1888.846191,606.784729 
	C1887.150757,606.405579 1884.702271,606.027466 1884.521606,605.171143 
	C1884.164551,603.478516 1884.812988,601.397095 1885.625854,599.765381 
	C1885.816162,599.383362 1888.316772,600.174927 1889.760620,600.382690 
	C1890.298950,600.460144 1890.865845,600.486267 1891.401611,600.409546 
	C1895.686890,599.796265 1899.967773,599.152771 1904.249878,598.518494 
	C1904.332642,599.094421 1904.415405,599.670410 1904.498169,600.246338 
	C1903.217163,600.637817 1901.936157,601.029358 1900.727417,601.398804 
	C1900.945068,603.204956 1901.153931,604.938416 1901.362793,606.671936 
	C1901.028809,606.937439 1900.694824,607.202942 1900.360840,607.468445 
	C1899.217163,606.193115 1898.073608,604.917847 1896.881348,603.588379 
	C1894.524048,604.493652 1891.821289,605.531616 1888.846191,606.784729 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2228.370361,744.358887 
	C2227.956299,744.066711 2227.884521,743.821411 2227.765625,743.195679 
	C2229.199707,742.041504 2230.606201,740.946899 2232.182617,740.577148 
	C2234.062988,740.136108 2236.192627,739.937317 2237.996582,740.430420 
	C2238.636475,740.605347 2238.637451,743.119202 2238.884766,744.342224 
	C2240.683594,742.841675 2242.761230,741.108459 2244.838867,739.375244 
	C2245.375000,739.544312 2245.910889,739.713318 2246.447021,739.882385 
	C2246.671143,741.899963 2247.798096,744.728577 2246.895264,745.733643 
	C2245.716064,747.046387 2242.949219,747.188049 2240.830322,747.337708 
	C2238.111328,747.529663 2235.305420,747.514404 2232.640381,747.025024 
	C2231.218750,746.763916 2230.014893,745.317261 2228.370361,744.358887 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2268.899902,821.151672 
	C2267.974609,822.900208 2267.044678,824.241333 2265.923096,825.396362 
	C2265.744141,825.580505 2264.705322,824.929749 2264.221191,824.008545 
	C2267.065674,820.827515 2268.365234,817.540466 2266.460693,814.433838 
	C2265.281006,812.509338 2262.437256,811.515991 2260.194824,810.395142 
	C2259.644775,810.120056 2258.525635,810.982483 2257.143066,811.529968 
	C2258.892578,811.832520 2259.986328,812.021667 2261.156006,812.223938 
	C2260.153564,814.368713 2259.274658,816.249512 2258.557373,817.784119 
	C2257.697754,816.544189 2256.672852,815.065796 2255.222656,812.973938 
	C2252.994629,820.080933 2254.009766,821.889526 2261.012939,825.136353 
	C2261.033936,825.306030 2261.054932,825.475708 2260.970703,825.879272 
	C2256.444824,826.205750 2253.447998,823.940857 2252.286377,819.914673 
	C2251.200928,816.152527 2252.395020,812.729187 2255.619385,810.160645 
	C2258.439941,807.913574 2261.476562,807.762146 2264.435059,809.460083 
	C2268.789307,811.958801 2269.581543,816.070862 2268.899902,821.151672 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1920.295898,597.271729 
	C1920.615601,597.179077 1920.935181,597.086487 1921.745850,596.904541 
	C1927.131958,598.088440 1932.026855,599.361755 1937.401123,600.759766 
	C1937.401123,601.514526 1937.401123,603.302795 1937.401123,606.365601 
	C1935.729370,603.289734 1937.834106,598.240967 1932.697388,600.018921 
	C1930.018921,606.883606 1936.132446,609.265930 1938.957764,613.579895 
	C1938.389038,614.011902 1938.087769,614.062988 1937.786621,614.114136 
	C1934.679810,611.240295 1931.572998,608.366455 1928.025635,605.123657 
	C1926.991577,605.144897 1926.398315,605.535095 1925.804932,605.925293 
	C1925.804932,605.925293 1925.490723,605.902771 1925.343506,605.560852 
	C1925.041626,604.321045 1924.886963,603.423157 1924.502563,601.190796 
	C1923.593872,603.566650 1923.151489,604.723511 1922.709106,605.880371 
	C1922.709106,605.880371 1922.303589,605.863098 1922.273682,605.562195 
	C1922.475464,602.701965 1923.238159,599.816650 1919.010498,599.995544 
	C1919.253296,599.828979 1919.474976,599.637878 1920.172607,599.214844 
	C1920.545044,598.428772 1920.420410,597.850281 1920.295898,597.271729 
M1930.505249,602.880615 
	C1929.803955,601.841980 1929.102661,600.803406 1928.401367,599.764832 
	C1927.899658,600.171082 1927.398071,600.577332 1926.896362,600.983582 
	C1927.943604,601.836304 1928.990723,602.689087 1930.505249,602.880615 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1926.177856,697.126099 
	C1927.254395,688.491455 1928.276001,679.849182 1929.485962,671.233276 
	C1929.619019,670.286255 1930.833984,669.491211 1931.596924,668.297729 
	C1931.721924,667.531128 1931.797363,667.092468 1932.208862,666.460327 
	C1932.773560,666.597656 1933.002197,666.928467 1933.188721,667.643188 
	C1932.963013,671.499146 1932.779541,674.971252 1932.584717,678.659607 
	C1935.052490,678.843811 1936.501343,678.951965 1937.950317,679.060059 
	C1936.467896,679.576660 1934.985474,680.093262 1933.835327,680.494019 
	C1936.262695,689.802368 1934.633911,699.142212 1934.593018,708.856079 
	C1934.299927,709.322510 1934.023315,709.035278 1933.993774,708.569702 
	C1933.038086,703.728760 1935.866821,698.680908 1931.599609,694.904053 
	C1935.010498,689.645142 1934.525146,685.008362 1930.650757,682.488892 
	C1929.451172,687.231323 1928.215454,692.117310 1926.778931,697.032471 
	C1926.578247,697.061646 1926.177856,697.126099 1926.177856,697.126099 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2108.985840,958.945984 
	C2108.825928,958.907227 2108.550293,958.906860 2108.522705,958.824097 
	C2106.425537,952.516846 2104.776367,951.370239 2097.156738,951.450806 
	C2095.887695,951.464233 2094.627441,952.315308 2093.363281,952.779175 
	C2093.973633,953.860291 2094.583984,954.941406 2095.217773,956.063965 
	C2091.004150,957.398010 2091.966553,953.524780 2090.914062,951.057983 
	C2090.979980,950.163879 2091.181152,949.739441 2091.624023,949.144043 
	C2092.326660,948.822083 2092.848145,948.450378 2093.238037,948.557739 
	C2095.057861,949.058655 2096.843262,950.173462 2098.637451,950.165771 
	C2101.392334,950.153931 2104.143799,949.402710 2106.503174,949.027771 
	C2108.052246,948.037964 2109.445068,947.147766 2110.844238,946.619385 
	C2107.993896,952.390808 2108.487061,955.250427 2112.519043,957.342285 
	C2111.298828,958.087097 2110.142334,958.516541 2108.985840,958.945984 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1722.085938,665.439209 
	C1723.490479,661.916992 1732.753296,658.881592 1734.575439,661.843689 
	C1736.246582,664.560547 1735.880859,668.731567 1735.711914,672.228088 
	C1735.673096,673.033325 1732.600220,674.539551 1731.225098,674.256897 
	C1728.329712,673.661377 1723.320679,677.447510 1723.229980,670.416504 
	C1723.778809,669.982056 1723.989380,669.976013 1724.545532,670.042236 
	C1725.933594,669.736633 1726.976074,669.358765 1728.233887,669.380493 
	C1727.557861,675.562561 1731.704346,671.705383 1733.674316,673.085022 
	C1733.674316,670.116943 1733.674316,667.837036 1733.674316,665.536804 
	C1732.192261,666.135132 1731.080566,666.583862 1729.964478,666.666992 
	C1730.541870,665.365234 1731.123657,664.429077 1731.877686,663.215698 
	C1727.959351,662.416321 1724.709717,661.988464 1723.956543,666.927002 
	C1723.161621,666.376038 1722.623779,665.907654 1722.085938,665.439209 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1706.145142,648.145020 
	C1706.100830,648.549255 1706.056519,648.953552 1706.019531,649.662842 
	C1694.365479,650.284729 1682.703979,650.601624 1670.539307,650.746948 
	C1670.261597,650.121887 1670.487061,649.668396 1671.185791,649.134521 
	C1673.972046,649.010803 1676.333130,649.231140 1678.578491,648.819580 
	C1679.812866,648.593201 1680.852173,647.302673 1681.907959,646.421997 
	C1681.836426,646.352051 1681.903198,646.163513 1682.302246,646.078735 
	C1684.179077,645.637329 1685.656982,645.280579 1687.473877,644.932190 
	C1688.262695,644.958618 1688.712402,644.976685 1689.532227,645.169495 
	C1695.265381,645.557312 1700.628784,645.770447 1706.299438,646.099609 
	C1706.794678,646.328186 1706.982666,646.440613 1707.170532,646.553101 
	C1707.170532,646.553101 1707.123901,646.572083 1706.658936,646.692383 
	C1699.134888,646.812683 1692.075684,646.812683 1685.016602,646.812683 
	C1685.007690,647.256775 1684.998901,647.700928 1684.989990,648.145020 
	C1692.041748,648.145020 1699.093384,648.145020 1706.145142,648.145020 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2211.496338,967.412476 
	C2212.090332,962.831848 2215.292236,963.322815 2217.929443,963.888428 
	C2218.879395,964.092163 2220.216797,967.120422 2220.029297,967.280518 
	C2218.177002,968.862183 2216.138672,970.322876 2213.933838,971.344788 
	C2213.125488,971.719543 2211.743652,970.630310 2210.603271,970.566345 
	C2208.811523,970.465820 2206.945801,970.934082 2205.238281,970.568787 
	C2204.767578,970.468140 2204.129639,967.791443 2204.644043,967.118958 
	C2205.846436,965.546326 2207.700439,964.472046 2209.290527,963.195923 
	C2209.618408,963.623413 2209.946289,964.050903 2210.274170,964.478394 
	C2208.825684,965.676086 2207.377197,966.873840 2205.928711,968.071533 
	C2206.165039,968.495483 2206.401367,968.919495 2206.637695,969.343445 
	C2208.159180,968.788879 2209.680420,968.234375 2211.496338,967.412476 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2102.769287,965.919922 
	C2101.384521,967.264709 2100.030273,968.228882 2097.934570,969.720764 
	C2103.443359,971.385254 2105.108643,968.787537 2105.941650,965.250000 
	C2106.650146,965.115967 2107.176758,964.853027 2107.607910,964.963257 
	C2109.389404,965.419128 2111.251709,965.790649 2112.823975,966.662598 
	C2113.187988,966.864563 2112.942871,969.531189 2112.255127,969.998230 
	C2110.475830,971.206055 2108.344238,972.004150 2106.248291,972.590820 
	C2105.223145,972.877808 2103.968994,972.282349 2102.810303,972.171692 
	C2101.160889,972.014221 2099.352539,972.283997 2097.920654,971.661438 
	C2097.294678,971.389221 2096.864990,968.616150 2097.326660,968.263794 
	C2098.917480,967.049744 2100.940918,966.402527 2102.769287,965.919922 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1744.255615,659.342834 
	C1745.213013,659.231934 1745.833008,659.231934 1747.626221,659.231934 
	C1745.310425,660.922546 1743.861328,661.980530 1742.412231,663.038452 
	C1742.705200,663.529602 1742.998169,664.020691 1743.291138,664.511780 
	C1745.151733,663.447876 1747.032471,662.417114 1748.866943,661.309998 
	C1750.462769,660.346924 1752.006226,659.297546 1753.572876,658.286316 
	C1754.315308,659.797546 1755.095093,661.291992 1755.785034,662.826721 
	C1756.151001,663.640991 1756.360596,664.525452 1756.533936,665.052979 
	C1753.158447,665.338379 1750.151733,665.755615 1747.140625,665.792480 
	C1744.967407,665.818970 1741.708984,666.131287 1740.826050,664.928162 
	C1738.699463,662.030396 1741.996582,660.850037 1744.255615,659.342834 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2278.491699,713.939209 
	C2263.947754,713.939087 2249.846191,713.939087 2235.744629,713.939087 
	C2235.741699,713.517761 2235.738525,713.096497 2235.735352,712.675171 
	C2250.913574,712.675171 2266.091797,712.675171 2281.269775,712.675171 
	C2281.317627,712.883484 2281.365479,713.091797 2281.413086,713.300049 
	C2280.586670,713.513184 2279.760254,713.726257 2278.491699,713.939209 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2250.162109,756.717896 
	C2249.824219,756.289978 2249.875000,755.917480 2249.962891,755.264709 
	C2250.210938,752.768311 2249.583496,749.692078 2253.177490,750.071655 
	C2255.395752,750.305786 2257.497070,751.649536 2259.651123,752.495178 
	C2259.638916,753.034363 2259.626953,753.573547 2259.614990,754.112793 
	C2260.695312,752.868958 2261.775391,751.625183 2263.574463,749.553406 
	C2263.574463,752.465942 2263.574463,754.483276 2263.287109,756.748535 
	C2262.999756,756.996460 2263.033203,757.022461 2263.033203,757.022461 
	C2262.337402,756.532532 2261.254395,755.480469 2261.010986,755.647888 
	C2259.827148,756.462708 2258.594482,757.449890 2257.905273,758.672546 
	C2257.208984,759.907471 2257.130127,761.490601 2256.388184,762.974487 
	C2256.291016,760.040344 2254.873047,759.891418 2252.326172,761.015198 
	C2253.390869,759.315918 2254.809082,757.627625 2256.227539,755.939392 
	C2256.001221,755.643738 2255.774658,755.348145 2255.548340,755.052551 
	C2253.882324,755.626160 2252.216553,756.199707 2250.162109,756.717896 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2120.993896,713.045776 
	C2126.103271,713.057800 2130.713867,713.057800 2135.324463,713.057800 
	C2135.324463,713.453796 2135.324219,713.849731 2135.324219,714.245728 
	C2120.441650,714.245728 2105.558838,714.245728 2090.676270,714.245728 
	C2090.666260,713.841736 2090.656250,713.437744 2090.646240,713.033752 
	C2100.595703,713.033752 2110.545410,713.033752 2120.993896,713.045776 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2333.320801,902.969849 
	C2346.989990,902.961121 2360.199951,902.961121 2373.409912,902.961121 
	C2373.411865,903.420288 2373.413818,903.879456 2373.415771,904.338623 
	C2358.781006,904.338623 2344.146240,904.338623 2329.511475,904.338623 
	C2329.467285,904.067078 2329.423096,903.795593 2329.378906,903.524048 
	C2330.539795,903.342224 2331.700928,903.160339 2333.320801,902.969849 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2337.069824,887.920410 
	C2339.827881,887.300720 2342.162354,886.435425 2344.553955,886.227478 
	C2345.659180,886.131287 2346.875732,887.318298 2348.041992,887.923584 
	C2347.243164,888.809387 2346.536621,889.808655 2345.621826,890.551270 
	C2344.562500,891.411011 2343.320068,892.045044 2342.044189,892.848206 
	C2341.771484,891.879944 2341.381592,890.494873 2340.645020,887.879150 
	C2339.031738,890.086060 2337.953613,891.560730 2336.934082,892.955261 
	C2335.879639,891.545105 2334.607666,889.843750 2332.699951,887.292603 
	C2331.762207,890.006409 2331.301270,891.340698 2330.854248,892.633911 
	C2329.948486,890.956787 2329.052002,889.297424 2327.273682,886.004639 
	C2331.134521,886.801392 2333.902100,887.372559 2337.069824,887.920410 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1950.839844,596.708496 
	C1950.902832,600.233093 1950.902832,603.344482 1950.902832,606.761597 
	C1947.198975,606.761597 1943.988525,607.049866 1940.896606,606.571533 
	C1940.058472,606.441833 1939.003296,603.953430 1939.100220,602.624390 
	C1939.192871,601.354248 1940.851440,599.049683 1941.483643,599.158691 
	C1944.928955,599.752869 1947.224731,598.834106 1948.609131,595.584534 
	C1948.634644,595.524536 1950.023804,596.045471 1950.839844,596.708496 
M1943.766479,604.105591 
	C1943.751953,603.147461 1943.737427,602.189270 1943.722778,601.231140 
	C1942.595947,601.635864 1941.469116,602.040649 1940.342285,602.445496 
	C1941.260010,603.101807 1942.177856,603.758118 1943.766479,604.105591 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1750.698730,646.047241 
	C1765.962280,646.033142 1780.870605,646.033142 1797.189209,646.033142 
	C1792.640137,647.043884 1789.485840,647.744751 1786.094238,648.766113 
	C1785.479614,649.087708 1785.101929,649.088867 1784.141602,648.761292 
	C1772.330811,648.311096 1761.102783,648.189636 1749.650269,647.932739 
	C1749.290527,647.485413 1749.155273,647.173523 1748.981689,646.689758 
	C1748.943237,646.517822 1748.926270,646.165833 1748.926270,646.165833 
	C1749.398682,646.130981 1749.870972,646.096130 1750.698730,646.047241 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2314.330566,844.992371 
	C2314.533936,847.629028 2314.533936,847.629028 2318.288330,846.629272 
	C2317.605469,847.705811 2317.165527,849.065369 2316.659912,849.090210 
	C2312.945801,849.273499 2309.185791,849.447693 2305.521973,848.982727 
	C2304.748291,848.884521 2303.812012,846.108215 2303.900635,844.622681 
	C2303.956055,843.696350 2305.783691,842.195984 2306.833252,842.181274 
	C2308.138184,842.162964 2309.460693,843.411804 2311.447266,844.471802 
	C2311.541748,844.400208 2312.338867,843.637146 2313.275146,843.134277 
	C2314.191650,842.642212 2315.250488,842.415527 2316.783203,842.908020 
	C2316.072021,843.514893 2315.360840,844.121826 2314.330566,844.992371 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2031.668091,678.558716 
	C2032.238770,681.905212 2032.788208,685.007202 2032.838867,688.117371 
	C2032.970581,696.208191 2032.883057,704.302490 2032.883057,712.395447 
	C2032.209595,712.421509 2031.536133,712.447632 2030.862671,712.473694 
	C2030.862671,706.236145 2030.862671,699.998535 2030.809326,693.130371 
	C2030.755981,692.499695 2030.748657,692.183777 2030.817383,691.727295 
	C2030.850830,689.126038 2030.815308,686.981262 2030.785645,684.668823 
	C2030.791260,684.501160 2030.786743,684.165771 2030.829834,683.828979 
	C2030.094360,682.892639 2029.315674,682.293091 2028.322876,681.441101 
	C2027.710815,681.023438 2027.312988,680.858154 2026.915039,680.692871 
	C2026.958496,677.135803 2029.281006,677.901367 2031.668091,678.558716 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2079.627197,733.349243 
	C2080.389404,733.028381 2080.949951,733.054749 2081.752197,733.367310 
	C2082.661377,734.437317 2083.328613,735.221191 2084.177490,736.328674 
	C2084.889893,737.898071 2086.072998,740.099609 2085.855713,740.247864 
	C2081.943604,742.915771 2084.436279,745.923279 2085.198242,748.826050 
	C2084.527100,748.841370 2083.856201,748.856689 2082.752441,748.566284 
	C2082.179199,747.876160 2082.038818,747.491821 2081.898438,747.107483 
	C2081.229736,746.679321 2080.561279,746.251099 2079.566406,745.829590 
	C2078.749512,743.158142 2078.258789,740.479980 2077.768311,737.801819 
	C2077.426514,737.914734 2077.084717,738.027588 2076.743164,738.140503 
	C2077.637207,736.659180 2078.531250,735.177856 2079.627197,733.349243 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1937.980469,614.389893 
	C1938.087769,614.062988 1938.389038,614.011902 1939.141479,613.877441 
	C1941.210938,615.138977 1942.828979,616.483887 1945.505859,618.708801 
	C1946.968628,615.153320 1948.256104,612.023987 1949.853271,608.141785 
	C1950.765869,612.950378 1952.093506,616.998779 1947.031616,620.346619 
	C1946.497681,621.269104 1946.243896,621.640320 1945.990234,622.011597 
	C1943.728394,621.657898 1940.427002,625.752930 1939.184204,620.096924 
	C1940.371094,620.388855 1941.352539,620.630249 1942.334106,620.871704 
	C1942.572144,620.622253 1942.810303,620.372742 1943.048340,620.123291 
	C1941.423706,618.304077 1939.799072,616.484802 1937.980469,614.389893 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2284.655273,836.789062 
	C2278.316650,836.789062 2272.441650,836.789062 2266.566895,836.789062 
	C2266.569580,836.021484 2266.572266,835.253967 2266.574707,834.486389 
	C2275.161377,833.677979 2283.788086,834.235413 2292.395264,834.125488 
	C2292.444336,834.624573 2292.493408,835.123657 2292.542480,835.622742 
	C2290.068115,836.011475 2287.593506,836.400269 2284.655273,836.789062 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1670.712402,649.214844 
	C1670.487061,649.668396 1670.261597,650.121887 1670.085571,650.825562 
	C1670.386719,654.442627 1670.638672,657.809570 1671.020874,661.573608 
	C1670.883789,662.129395 1670.616455,662.288086 1669.700195,662.204956 
	C1668.897217,658.359253 1668.743286,654.755371 1668.530518,650.642639 
	C1668.329834,649.822937 1668.188110,649.512207 1668.147705,649.232056 
	C1668.249023,649.262634 1668.254272,649.051086 1668.481567,648.700195 
	C1668.647705,647.514771 1668.586426,646.680298 1668.629883,645.411865 
	C1668.497925,641.301819 1668.261475,637.625671 1668.074463,633.659241 
	C1668.118042,632.978271 1668.111816,632.587708 1668.273682,631.761902 
	C1668.451538,628.571716 1668.461304,625.816711 1668.676270,623.151367 
	C1669.184692,623.249573 1669.476196,623.195312 1669.844727,623.508423 
	C1670.325684,627.514893 1668.645752,631.961487 1674.002808,633.505554 
	C1674.238159,633.573425 1674.032959,635.169373 1674.032959,636.201233 
	C1672.833130,635.265930 1671.961548,634.586487 1671.089966,633.907043 
	C1670.674316,634.072388 1670.258667,634.237732 1669.843018,634.403015 
	C1670.211426,638.276306 1670.579834,642.149597 1670.937744,646.333618 
	C1670.855713,647.501221 1670.784058,648.358032 1670.712402,649.214844 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2066.048096,746.898071 
	C2065.273682,746.898621 2064.494873,746.952087 2063.725830,746.889099 
	C2061.903076,746.739807 2060.041260,746.692383 2058.304932,746.209167 
	C2057.989990,746.121582 2057.727539,743.746887 2058.256836,743.140808 
	C2059.545898,741.664917 2061.313721,740.607300 2062.888916,739.381287 
	C2063.583496,740.161865 2064.278320,740.942444 2064.972900,741.722961 
	C2062.891357,742.410095 2060.809814,743.097168 2058.728271,743.784241 
	C2059.354736,744.508850 2059.981201,745.233459 2060.607666,745.958069 
	C2062.260010,745.146057 2063.912109,744.334045 2066.154297,743.202026 
	C2068.514160,742.227234 2070.284424,741.572510 2072.054688,740.917786 
	C2072.054688,740.917786 2072.027344,740.945251 2072.155273,741.203003 
	C2072.609375,741.688416 2072.935791,741.916138 2073.261963,742.143860 
	C2072.401367,743.708130 2071.540771,745.272400 2070.132324,746.644653 
	C2068.405762,746.601013 2067.227051,746.749512 2066.048096,746.898071 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2297.286133,744.270264 
	C2295.933594,745.913513 2294.589111,747.563599 2293.222900,749.195679 
	C2292.799072,749.701904 2292.144287,750.082947 2291.892578,750.652771 
	C2291.159668,752.309753 2290.577393,754.033447 2289.936279,755.731018 
	C2288.010010,753.965271 2286.057373,752.226868 2284.177002,750.413513 
	C2283.737793,749.989929 2283.543701,749.312073 2282.613525,747.611816 
	C2285.159180,748.003418 2286.784180,748.253418 2287.729980,748.398926 
	C2290.744141,746.739868 2293.389893,745.283508 2296.535645,743.929932 
	C2297.035400,744.032776 2297.286133,744.270264 2297.286133,744.270264 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2340.686279,879.486023 
	C2340.562500,876.001770 2343.537354,874.682068 2347.643066,876.134033 
	C2349.666992,876.849670 2351.873779,877.047546 2354.687256,877.619934 
	C2354.687256,878.121216 2354.687256,879.993958 2354.687256,881.297363 
	C2352.342041,881.297363 2349.944092,881.297363 2347.185791,881.297363 
	C2347.198242,881.340698 2346.927734,880.406677 2346.330322,878.343445 
	C2344.430908,879.967468 2342.962646,881.227173 2341.480225,882.469543 
	C2341.429443,882.511963 2341.193848,882.414062 2341.114258,882.321350 
	C2341.011230,882.201477 2340.978760,882.020935 2340.904541,881.097351 
	C2340.824219,880.047363 2340.755371,879.766724 2340.686279,879.486023 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1749.874756,648.068115 
	C1761.102783,648.189636 1772.330811,648.311096 1783.857178,648.754639 
	C1772.978760,649.281311 1761.801880,649.485901 1750.274048,649.148071 
	C1749.906982,648.426514 1749.890869,648.247314 1749.874756,648.068115 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2030.786743,684.165771 
	C2030.786743,684.165771 2030.791260,684.501160 2030.445435,684.815796 
	C2030.316040,687.481567 2030.532349,689.832703 2030.748657,692.183777 
	C2030.748657,692.183777 2030.755981,692.499695 2030.733765,692.655884 
	C2029.773804,695.848694 2028.835938,698.885315 2027.536133,702.415649 
	C2025.051392,702.360535 2022.928955,701.811707 2020.971680,701.051575 
	C2021.236328,700.691711 2021.335938,700.543152 2021.435425,700.394592 
	C2022.738770,700.522034 2024.042114,700.649475 2025.667236,700.808350 
	C2025.955322,694.223328 2026.234009,687.852539 2026.713867,681.087280 
	C2027.312988,680.858154 2027.710815,681.023438 2028.258057,681.759583 
	C2029.200562,682.942261 2029.993652,683.554016 2030.786743,684.165771 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1902.993164,580.987549 
	C1903.486450,581.042480 1903.979614,581.097412 1904.795654,581.510620 
	C1905.217773,582.712524 1905.317383,583.556152 1905.416870,584.399841 
	C1904.645630,585.016357 1903.913330,586.094543 1903.095947,586.163574 
	C1900.557495,586.377747 1897.531738,587.015015 1895.582642,585.915222 
	C1894.200195,585.135132 1894.254639,581.808411 1894.206665,579.165894 
	C1897.106079,581.971069 1900.539185,576.514893 1902.646240,580.723816 
	C1901.928223,581.365051 1901.438721,581.649048 1900.610352,581.894775 
	C1899.184570,582.105347 1898.097656,582.354370 1897.010620,582.603333 
	C1897.173950,583.180359 1897.337280,583.757446 1897.500488,584.334534 
	C1898.681641,583.570923 1899.862671,582.807251 1901.339722,582.012634 
	C1902.088135,581.650330 1902.540649,581.318909 1902.993164,580.987549 
M1902.656250,583.542419 
	C1902.656250,583.542419 1902.471802,583.447327 1902.471802,583.447327 
	C1902.471802,583.447327 1902.505005,583.645081 1902.656250,583.542419 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2389.460449,909.425354 
	C2391.024658,909.296143 2392.224854,909.296143 2394.936768,909.296143 
	C2390.154297,912.263428 2386.660400,914.431458 2382.308350,917.131714 
	C2380.682861,914.985168 2379.220703,911.894409 2381.394287,907.055664 
	C2383.159668,912.089417 2386.132568,910.799683 2389.460449,909.425354 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2091.382324,949.315063 
	C2091.181152,949.739441 2090.979980,950.163879 2090.689697,950.731323 
	C2086.754883,952.400696 2083.967529,954.804871 2083.102295,959.127014 
	C2082.373291,962.768616 2079.987549,963.370483 2077.148682,962.303467 
	C2078.790527,960.534729 2081.108643,959.036682 2081.844482,956.974243 
	C2082.638672,954.747498 2081.953125,951.992859 2081.759277,949.162598 
	C2081.828613,948.696106 2082.052246,948.535400 2082.900146,948.161743 
	C2086.143555,948.404297 2088.762939,948.859619 2091.382324,949.315063 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2325.075195,867.936218 
	C2325.734863,867.351746 2326.306152,866.471680 2327.071777,866.243530 
	C2328.723145,865.751343 2330.492676,865.350525 2332.171631,865.476379 
	C2332.576172,865.506653 2332.790283,867.742371 2333.139160,868.950195 
	C2333.473877,870.109253 2333.869873,871.250610 2334.119873,872.029358 
	C2331.341797,872.029358 2328.509277,872.029358 2325.370850,871.663330 
	C2325.068359,870.176941 2325.071777,869.056580 2325.075195,867.936218 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1730.085693,649.772217 
	C1729.723633,648.501587 1729.776367,647.359009 1730.119873,645.750244 
	C1731.034302,644.127136 1731.657715,642.970093 1732.774536,640.897705 
	C1733.898438,641.180115 1735.977539,641.702454 1738.127686,642.621338 
	C1737.458740,645.039917 1736.718750,647.061951 1735.978882,649.084045 
	C1734.152710,649.356079 1732.326538,649.628113 1730.085693,649.772217 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2300.474609,901.123840 
	C2300.073730,900.946350 2300.051514,900.746277 2300.013672,900.244324 
	C2304.090820,898.010925 2308.184082,896.079346 2311.378418,894.571960 
	C2312.018555,891.343201 2312.588867,888.467529 2313.158936,885.591858 
	C2313.702637,885.675415 2314.246094,885.759033 2314.789795,885.842590 
	C2314.789795,887.276733 2314.789795,888.710876 2315.095703,890.531006 
	C2315.361084,891.180725 2315.280518,891.431519 2314.955566,891.772949 
	C2314.742188,892.090698 2314.732422,892.304993 2314.722656,892.519287 
	C2314.911865,892.295715 2315.101074,892.072144 2315.576660,891.922607 
	C2316.242188,892.399475 2316.621338,892.802368 2317.000244,893.205322 
	C2311.617920,895.837280 2306.235352,898.469238 2300.474609,901.123840 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2109.260254,959.254639 
	C2110.142334,958.516541 2111.298828,958.087097 2112.913574,957.289612 
	C2115.336670,956.756836 2118.279541,959.488342 2119.017578,955.881897 
	C2119.584717,953.110046 2118.814209,950.027283 2118.375732,947.122498 
	C2118.299805,946.620178 2116.909668,946.316345 2116.357910,945.634827 
	C2118.085205,945.313293 2119.581055,945.277832 2121.077148,945.242310 
	C2121.060059,948.402893 2120.866943,951.574951 2121.070801,954.721191 
	C2121.318359,958.539734 2119.955566,960.415466 2115.951660,959.991638 
	C2113.821533,959.766235 2111.674316,959.700867 2109.260254,959.254639 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2121.148438,657.949219 
	C2126.938965,658.289124 2124.897949,652.115417 2128.124756,649.904907 
	C2128.597168,653.873230 2129.029785,657.507263 2129.490967,661.379700 
	C2125.830811,662.454773 2122.289795,661.367249 2121.148438,657.949219 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1950.995361,639.156006 
	C1952.378174,641.162231 1953.749756,642.755920 1955.121216,644.349670 
	C1955.568970,644.203125 1956.016724,644.056580 1956.464478,643.910034 
	C1956.538208,647.700745 1953.434937,646.749817 1951.087646,646.939331 
	C1948.260864,647.167542 1947.811401,645.437927 1947.978027,643.189880 
	C1948.170166,640.599121 1948.361328,638.008179 1948.552979,635.417358 
	C1949.140381,635.405884 1949.727661,635.394409 1950.314941,635.382996 
	C1950.537964,636.503174 1950.760864,637.623352 1950.995361,639.156006 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2350.229248,822.043335 
	C2351.849854,821.531616 2353.139893,821.172363 2355.512207,820.511658 
	C2352.013428,824.334656 2349.405762,827.184143 2346.527832,830.328796 
	C2345.243652,828.145447 2343.699707,825.520630 2341.833008,822.347046 
	C2344.823486,822.290955 2347.361084,822.243347 2350.229248,822.043335 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1971.062744,609.985474 
	C1970.673340,610.247925 1970.317993,610.628723 1969.888672,610.752197 
	C1968.155640,611.250244 1966.399658,611.668884 1964.652222,612.116821 
	C1964.282715,610.642273 1963.144531,608.652466 1963.711914,607.805969 
	C1964.735229,606.279480 1966.779175,605.437073 1968.396851,604.308960 
	C1968.671509,604.735657 1968.946289,605.162292 1969.221069,605.588989 
	C1967.850952,606.756714 1966.480835,607.924438 1965.110840,609.092224 
	C1965.384277,609.565491 1965.657715,610.038818 1965.931152,610.512146 
	C1967.601318,609.583801 1969.271362,608.655457 1971.324951,607.347900 
	C1973.562744,608.061523 1975.416992,609.154236 1977.271240,610.247009 
	C1977.271240,610.247009 1977.244385,610.299561 1976.974609,610.152649 
	C1974.824097,609.998962 1972.943481,609.992188 1971.062744,609.985474 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2006.631836,683.094360 
	C2006.996338,681.903870 2007.360840,680.713379 2007.850830,679.302490 
	C2008.253296,679.998230 2008.760498,680.911682 2008.771606,681.830994 
	C2008.876587,690.547485 2013.078369,696.476746 2021.177734,700.194763 
	C2021.335938,700.543152 2021.236328,700.691711 2020.603516,701.047974 
	C2011.462524,698.256470 2007.835815,691.571838 2006.631836,683.094360 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1736.363281,649.082031 
	C1736.718750,647.061951 1737.458740,645.039917 1738.440308,642.544922 
	C1739.105835,642.010376 1739.529907,641.948730 1740.187500,642.135010 
	C1741.641846,642.624390 1742.862427,642.865723 1744.375244,642.897522 
	C1745.423706,643.298157 1746.226929,643.859863 1746.924072,644.531677 
	C1747.674438,645.254578 1748.325562,646.080688 1749.020020,646.861694 
	C1749.155273,647.173523 1749.290527,647.485413 1749.650269,647.932739 
	C1749.890869,648.247314 1749.906982,648.426514 1749.966675,648.872253 
	C1748.280029,649.114014 1746.549805,649.089111 1744.568115,648.767944 
	C1744.794556,647.496338 1745.272217,646.521057 1745.750000,645.545776 
	C1745.211304,645.367615 1744.672485,645.189453 1744.133789,645.011292 
	C1743.809937,646.378174 1743.486084,647.745056 1743.162231,649.111938 
	C1741.024048,649.101257 1738.885742,649.090637 1736.363281,649.082031 
M1740.401367,647.497986 
	C1740.764282,647.513916 1741.127197,647.529846 1741.490112,647.545776 
	C1741.228149,647.400146 1740.966187,647.254578 1740.401367,647.497986 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1946.202881,622.296143 
	C1946.243896,621.640320 1946.497681,621.269104 1946.870361,620.765381 
	C1949.418823,621.958130 1951.848511,623.283325 1954.617920,624.677002 
	C1954.275024,626.795959 1953.592285,628.846375 1952.571655,630.902222 
	C1952.233765,630.907593 1952.136353,630.789062 1952.115112,630.447754 
	C1951.255371,629.440613 1950.416992,628.774719 1949.578613,628.108826 
	C1948.524414,626.266113 1947.469971,624.423401 1946.202881,622.296143 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2121.489746,945.173828 
	C2119.581055,945.277832 2118.085205,945.313293 2115.947266,945.674622 
	C2114.483643,945.965576 2113.661621,945.930664 2112.563965,945.614014 
	C2108.846924,945.174316 2105.405273,945.016357 2101.963867,944.858337 
	C2101.977783,944.440491 2101.991699,944.022583 2102.473633,943.310730 
	C2110.921387,942.953979 2118.901367,942.891296 2126.881592,942.828613 
	C2126.892822,943.341309 2126.903809,943.854065 2126.915527,944.751465 
	C2125.244629,945.125916 2123.573730,945.115601 2121.489746,945.173828 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2340.359131,879.695801 
	C2340.755371,879.766724 2340.824219,880.047363 2340.900879,880.761963 
	C2334.791992,885.263123 2332.860840,884.838440 2330.035400,878.761475 
	C2332.728760,877.768799 2335.374268,876.793579 2338.019775,875.818420 
	C2338.214111,876.204895 2338.408691,876.591370 2338.603271,876.977783 
	C2337.327881,878.115356 2336.052734,879.252991 2334.777344,880.390564 
	C2334.913330,880.679443 2335.049072,880.968262 2335.185059,881.257080 
	C2336.800537,880.806641 2338.416260,880.356140 2340.359131,879.695801 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2218.517090,945.250732 
	C2218.117432,948.006226 2217.365234,950.739868 2216.319824,953.723877 
	C2213.571045,952.372437 2211.115479,950.770569 2208.374023,948.986938 
	C2208.193604,948.518005 2208.255127,948.221680 2208.211914,947.696655 
	C2210.518066,947.697449 2212.885010,947.917786 2215.597656,948.170349 
	C2216.083252,947.613953 2217.123779,946.421448 2218.517090,945.250732 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2212.780273,690.512817 
	C2213.972412,688.808838 2214.990967,687.404297 2216.009521,685.999756 
	C2216.270508,686.106384 2216.531250,686.213013 2216.791992,686.319641 
	C2216.421143,689.663635 2216.050049,693.007629 2215.566162,697.368896 
	C2213.321777,696.222717 2210.645020,694.855713 2206.572998,692.776245 
	C2209.625244,691.782837 2211.115967,691.297546 2212.780273,690.512817 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1976.551025,618.002808 
	C1976.677002,618.988098 1976.410156,620.026611 1976.179688,621.389893 
	C1973.953857,622.124817 1971.662354,622.420349 1969.441284,622.990845 
	C1968.223022,623.303833 1967.123901,624.080811 1965.781738,624.947388 
	C1965.196167,624.958435 1964.799927,624.670654 1964.221680,624.218140 
	C1964.540527,623.013184 1964.809937,621.712280 1965.606567,621.005310 
	C1966.421753,620.281799 1967.753540,620.140381 1968.930664,619.370728 
	C1969.002075,618.997314 1969.020508,618.975037 1969.020508,618.975037 
	C1971.399780,618.668701 1973.778931,618.362366 1976.551025,618.002808 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2245.374268,922.870972 
	C2245.791016,922.765503 2245.742920,922.788757 2245.749756,922.760254 
	C2245.662842,923.340149 2245.749756,924.365112 2245.448730,924.495544 
	C2239.046387,927.271362 2232.599854,929.946716 2226.162109,932.641541 
	C2225.896484,931.994324 2225.631104,931.347107 2225.365723,930.699829 
	C2231.896240,928.125427 2238.427002,925.550964 2245.374268,922.870972 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2325.069336,867.549744 
	C2325.071777,869.056580 2325.068359,870.176941 2325.032227,871.628113 
	C2323.151123,872.307556 2321.302734,872.656250 2319.296143,872.374390 
	C2318.797607,870.204285 2318.457031,868.664856 2318.116699,867.125366 
	C2320.433350,866.917297 2322.772705,862.589172 2325.069336,867.549744 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2328.901855,847.115662 
	C2327.551025,847.601257 2326.564697,848.033936 2325.632568,848.442871 
	C2324.476562,846.559143 2323.260986,844.577698 2321.270508,841.333923 
	C2324.470215,842.478638 2326.471680,843.194702 2328.774902,844.018677 
	C2329.414062,843.598633 2330.465088,842.907776 2331.868164,841.985413 
	C2331.868164,844.393005 2331.868164,846.337585 2331.868164,848.686279 
	C2330.802734,848.021484 2330.034668,847.542114 2328.901855,847.115662 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2381.628418,866.566528 
	C2379.220947,867.565308 2377.168457,868.418213 2375.115967,869.271179 
	C2374.894775,868.753601 2374.673340,868.236084 2374.451904,867.718506 
	C2381.333984,864.823486 2388.216064,861.928406 2395.098389,859.033386 
	C2395.297607,859.483154 2395.496826,859.932861 2395.696045,860.382629 
	C2391.125244,862.395325 2386.554443,864.408020 2381.628418,866.566528 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1933.230835,667.259277 
	C1933.002197,666.928467 1932.773560,666.597656 1932.272217,666.132263 
	C1934.198853,665.356384 1936.362549,664.420410 1938.606567,664.149048 
	C1941.362061,663.815857 1944.184692,664.037842 1947.432983,663.964172 
	C1947.644165,664.669861 1947.400635,665.431274 1946.684937,666.521362 
	C1941.885498,666.986511 1937.558228,667.122925 1933.230835,667.259277 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2296.908447,743.915894 
	C2297.661377,738.215515 2301.082520,734.607910 2306.507568,733.917358 
	C2306.892334,733.886230 2307.012695,734.009888 2307.076416,734.072144 
	C2304.039307,737.394531 2300.938232,740.654602 2297.561523,744.092529 
	C2297.286133,744.270264 2297.035400,744.032776 2296.908447,743.915894 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1909.008789,668.327515 
	C1909.024902,667.029663 1909.074463,666.027222 1909.130005,664.647705 
	C1909.422485,664.098267 1909.679688,663.889465 1909.986450,663.408081 
	C1914.447998,663.322021 1916.773438,666.628784 1915.116699,670.436340 
	C1915.057373,670.572388 1914.204468,670.363037 1913.388672,670.460205 
	C1913.055786,670.605652 1912.937744,670.801880 1912.831299,670.586182 
	C1911.572998,669.697693 1910.102905,667.069824 1909.678101,670.866089 
	C1909.466187,670.118347 1909.254272,669.370544 1909.008789,668.327515 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2315.159668,891.669556 
	C2315.280518,891.431519 2315.361084,891.180725 2315.404297,890.719727 
	C2316.895020,889.249207 2318.383545,887.976013 2318.751709,887.661011 
	C2322.018555,888.754028 2324.319092,889.523804 2328.185791,890.817505 
	C2324.073486,891.697205 2320.969238,892.361206 2317.432617,893.115295 
	C2316.621338,892.802368 2316.242188,892.399475 2315.532715,891.886108 
	C2315.202393,891.775635 2315.159668,891.669556 2315.159668,891.669556 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2356.107178,843.119446 
	C2352.559814,842.348206 2349.825684,838.045654 2349.609863,833.508057 
	C2353.325928,834.059326 2354.768311,836.066345 2356.107178,843.119446 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2139.744629,661.428162 
	C2141.864746,662.355408 2143.624268,663.135498 2145.384033,663.915588 
	C2145.103516,664.670349 2144.822754,665.425049 2144.542236,666.179749 
	C2141.133301,665.310669 2137.724365,664.441589 2134.250732,663.556030 
	C2135.333496,660.246704 2135.333496,660.246704 2139.744629,661.428162 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2177.274658,952.336426 
	C2171.615479,955.110535 2165.956543,957.884705 2160.297607,960.658813 
	C2159.962158,959.899170 2159.626953,959.139587 2159.291748,958.379944 
	C2164.898193,956.230042 2170.504639,954.080139 2176.589844,952.004639 
	C2177.068604,952.078918 2177.274658,952.336426 2177.274658,952.336426 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2072.813477,959.094727 
	C2074.349365,962.559143 2072.754639,964.336365 2069.770264,964.672852 
	C2068.999512,964.759827 2067.058594,962.495422 2067.272949,961.840027 
	C2068.015625,959.568298 2069.181396,956.989258 2072.813477,959.094727 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2243.996338,974.975098 
	C2245.264404,975.629211 2246.532471,976.283447 2248.028076,977.054993 
	C2249.052979,976.344177 2250.206299,975.544128 2251.847656,974.405701 
	C2251.847656,976.904297 2251.847656,978.782776 2251.847656,979.864868 
	C2249.243408,980.085144 2246.830566,980.289124 2244.138184,980.516846 
	C2244.090820,979.948914 2243.969238,978.492554 2243.905762,976.330872 
	C2243.974854,975.408691 2243.985596,975.191895 2243.996338,974.975098 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2243.629883,974.837402 
	C2243.985596,975.191895 2243.974854,975.408691 2243.915771,975.947815 
	C2241.835693,978.032166 2239.803467,979.794189 2237.771484,981.556213 
	C2237.368164,981.060791 2236.965088,980.565369 2236.248291,979.940186 
	C2235.934814,979.810364 2235.938721,979.575134 2235.972412,979.463623 
	C2236.408203,977.802795 2236.810547,976.253540 2237.387451,974.031189 
	C2239.273193,974.245728 2241.268311,974.472717 2243.629883,974.837402 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1989.350220,646.851807 
	C1985.790649,645.453491 1986.283813,643.841858 1989.094971,643.160034 
	C1991.412598,642.597961 1993.975098,643.045288 1997.249756,643.045288 
	C1996.042847,648.296387 1992.317749,646.410156 1989.350220,646.851807 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2250.970215,922.048218 
	C2255.170898,916.669067 2261.661865,916.322205 2267.229736,913.921387 
	C2267.446289,914.408813 2267.662842,914.896179 2267.879639,915.383545 
	C2262.477051,917.580811 2257.074463,919.778076 2251.285889,922.004639 
	C2250.899658,922.033875 2250.980469,922.007019 2250.970215,922.048218 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1869.892334,680.647705 
	C1857.636475,680.854065 1845.096313,680.724304 1832.275757,680.280273 
	C1844.532959,680.081177 1857.070435,680.196350 1869.892334,680.647705 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1905.658936,584.636841 
	C1905.317383,583.556152 1905.217773,582.712524 1905.168335,581.546997 
	C1907.012695,580.715881 1908.806885,580.206543 1910.601074,579.697205 
	C1910.720703,580.864868 1911.296753,582.248657 1910.866943,583.156189 
	C1909.913940,585.168457 1908.416626,586.822693 1905.658936,584.636841 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1951.988403,657.992981 
	C1952.823730,654.060364 1955.006714,652.059509 1959.261475,652.471008 
	C1962.496094,652.783936 1965.767334,652.718628 1969.284668,653.106445 
	C1969.427856,653.533386 1969.308350,653.675598 1968.547852,653.949829 
	C1962.212158,653.813904 1956.369019,652.937317 1951.972290,657.974121 
	C1951.978638,657.980103 1951.988403,657.992981 1951.988403,657.992981 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2367.680176,872.675354 
	C2364.088135,874.175293 2360.832764,875.491760 2357.577637,876.808167 
	C2357.381836,876.248108 2357.186035,875.688049 2356.990234,875.128052 
	C2362.041748,872.988220 2367.093018,870.848389 2372.144531,868.708618 
	C2372.380615,869.201721 2372.616699,869.694824 2372.852783,870.187927 
	C2371.240723,870.955933 2369.628906,871.723877 2367.680176,872.675354 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2336.872559,785.575928 
	C2336.885986,784.959595 2336.911865,784.744873 2336.929443,784.205139 
	C2338.231934,783.115479 2339.542969,782.350769 2341.621826,781.138184 
	C2341.142090,784.947449 2340.824219,787.473633 2340.386963,790.945618 
	C2337.447510,791.754517 2336.776855,789.302002 2336.872559,785.575928 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2063.654297,961.679871 
	C2062.660400,960.821960 2061.484619,960.271912 2060.340820,959.736938 
	C2063.183838,957.100830 2065.994385,957.442444 2065.913330,960.507202 
	C2065.873535,962.019531 2065.412598,963.520752 2065.051758,965.530762 
	C2063.720459,965.589905 2062.037109,965.664734 2060.353760,965.739502 
	C2060.221191,965.399536 2060.088379,965.059509 2059.955811,964.719482 
	C2061.127686,963.808899 2062.299805,962.898254 2063.654297,961.679871 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1918.635620,600.060791 
	C1923.238159,599.816650 1922.475464,602.701965 1922.105469,605.675659 
	C1921.072021,609.173767 1918.898438,608.058228 1918.317017,606.522400 
	C1917.608521,604.651489 1918.220215,602.280640 1918.635620,600.060791 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2340.433105,818.603088 
	C2335.406006,812.156494 2335.030029,810.957520 2337.253906,807.858398 
	C2338.875732,810.719788 2340.505859,813.325623 2341.798584,816.089355 
	C2342.077148,816.685059 2341.139893,817.849609 2340.433105,818.603088 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1965.003418,646.807129 
	C1963.218994,646.620117 1961.834717,646.423218 1960.206787,646.191589 
	C1962.514648,642.493958 1965.467651,642.168640 1970.738770,645.295044 
	C1968.420410,645.947876 1966.911987,646.372620 1965.003418,646.807129 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2196.746094,687.104736 
	C2196.750488,685.978271 2197.025879,685.105774 2197.301025,684.233215 
	C2200.210693,685.633972 2204.022949,685.886414 2205.059814,689.669312 
	C2204.762207,690.191223 2204.464600,690.713074 2204.166992,691.234985 
	C2201.783691,689.942871 2199.400146,688.650757 2196.746094,687.104736 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2378.127441,902.827148 
	C2377.692871,899.432312 2374.265869,896.452271 2378.272461,892.539307 
	C2379.260254,895.857666 2380.220703,898.534485 2380.822998,901.289612 
	C2381.260498,903.292297 2380.614014,904.566650 2378.127441,902.827148 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2278.153076,738.728760 
	C2278.515869,739.113892 2278.653076,739.218384 2278.742920,739.354309 
	C2279.939453,741.169373 2281.129639,742.988831 2282.321533,744.807251 
	C2281.791260,745.130920 2280.896240,745.830627 2280.784912,745.722595 
	C2278.803467,743.800720 2276.844482,741.841553 2275.075684,739.727295 
	C2274.745850,739.333130 2275.261963,738.231323 2275.387695,737.456055 
	C2276.227051,737.791565 2277.066162,738.127075 2278.153076,738.728760 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2387.928711,926.139038 
	C2388.318359,927.467224 2388.822510,928.423096 2388.800537,929.366699 
	C2388.782227,930.167847 2388.156250,930.954895 2387.798828,931.748169 
	C2387.080078,931.245239 2385.816162,930.817932 2385.734131,930.226624 
	C2385.319336,927.239380 2382.268799,924.246216 2385.916016,920.579529 
	C2386.647461,922.575684 2387.231201,924.168518 2387.928711,926.139038 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2317.986816,867.424866 
	C2318.457031,868.664856 2318.797607,870.204285 2319.054932,872.159302 
	C2317.981689,871.738281 2316.991699,870.901794 2315.699707,869.810059 
	C2314.585205,870.867432 2313.458252,871.937012 2311.809570,873.501404 
	C2311.809570,870.889954 2311.809570,868.899841 2311.809570,867.299011 
	C2313.758789,867.436096 2315.807861,867.580200 2317.986816,867.424866 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1724.200073,669.969910 
	C1723.989380,669.976013 1723.778809,669.982056 1723.253174,670.011230 
	C1720.185547,669.092346 1717.432861,668.150330 1714.339844,667.097168 
	C1714.118164,666.735901 1714.210815,666.476379 1714.268555,666.000732 
	C1716.559448,665.635071 1718.859253,665.476318 1721.622437,665.378418 
	C1722.623779,665.907654 1723.161621,666.376038 1724.043579,667.294128 
	C1724.325073,668.485840 1724.262573,669.227844 1724.200073,669.969910 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2116.163086,657.762085 
	C2112.452148,657.832825 2108.878174,658.149902 2107.213135,654.134888 
	C2110.641113,654.428894 2114.612061,653.191528 2116.163086,657.762085 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1690.924683,679.569031 
	C1689.807739,679.807068 1688.875610,679.851318 1687.356445,679.581299 
	C1684.706665,676.328796 1687.240112,674.937195 1689.061646,674.064392 
	C1689.822876,673.699524 1691.607544,675.470337 1692.926880,676.270142 
	C1692.659912,676.714417 1692.392822,677.158752 1692.083008,677.859375 
	C1691.729858,678.535522 1691.419800,678.955383 1690.924683,679.569031 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2253.087158,977.536560 
	C2254.087158,977.333435 2255.780029,977.491455 2255.798340,977.762451 
	C2255.991455,980.627991 2255.918213,983.511475 2255.918213,987.290833 
	C2251.559082,984.349609 2253.489502,980.826721 2253.087158,977.536560 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2264.242676,776.264709 
	C2265.775879,774.784302 2267.064453,773.554688 2268.352783,772.325134 
	C2268.763184,772.724487 2269.173340,773.123779 2269.583496,773.523132 
	C2266.130127,777.109192 2262.676514,780.695251 2259.223145,784.281311 
	C2258.775391,783.877563 2258.327637,783.473877 2257.879883,783.070129 
	C2259.919189,780.885254 2261.958496,778.700439 2264.242676,776.264709 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2345.150146,913.628540 
	C2344.422363,912.805176 2344.058838,911.942383 2343.695312,911.079590 
	C2346.587891,910.317017 2349.480469,909.554382 2352.373291,908.791809 
	C2352.597168,909.515442 2352.821045,910.239136 2353.044922,910.962769 
	C2350.534668,911.838257 2348.024658,912.713684 2345.150146,913.628540 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1744.619629,675.867432 
	C1743.204468,675.569458 1742.141479,675.410645 1739.922241,675.079041 
	C1742.491455,672.928589 1744.257202,671.450623 1746.117798,669.893311 
	C1748.676758,675.870178 1748.676758,675.870178 1744.619629,675.867432 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1779.343018,674.135803 
	C1778.325806,672.870972 1777.674805,671.797852 1777.016602,670.389771 
	C1778.243164,670.732361 1779.477173,671.409851 1780.853882,672.165710 
	C1781.897949,671.307617 1782.979736,670.418579 1784.859497,668.873596 
	C1784.723267,671.859985 1784.630249,673.898193 1784.558838,675.462585 
	C1782.790405,675.048645 1781.249878,674.688049 1779.343018,674.135803 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1973.931152,646.119324 
	C1975.785156,640.090942 1979.682861,644.290588 1982.696533,643.433044 
	C1982.812988,644.143188 1982.929565,644.853271 1983.046143,645.563354 
	C1980.106201,645.823181 1977.166138,646.083008 1973.931152,646.119324 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2296.320801,897.577332 
	C2296.219238,897.946106 2296.007324,897.945374 2295.345947,897.616882 
	C2292.954102,893.707092 2291.011719,890.125000 2289.068848,886.214905 
	C2289.077148,885.229980 2289.085693,884.573059 2289.093994,883.916077 
	C2289.093994,883.916077 2289.454102,883.921387 2289.629883,883.960022 
	C2291.940430,888.401672 2294.075439,892.804749 2296.320801,897.577332 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2143.329834,963.693359 
	C2144.308105,966.750061 2144.927002,969.966370 2145.545654,973.182678 
	C2144.851562,973.284668 2144.157715,973.386597 2143.463623,973.488586 
	C2141.138184,970.587891 2141.757080,967.263184 2143.329834,963.693359 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2226.328125,700.732544 
	C2227.589844,701.797546 2228.521729,702.682922 2229.453613,703.568298 
	C2229.076660,704.073181 2228.699463,704.578003 2228.322266,705.082886 
	C2225.427246,703.362976 2222.531982,701.643127 2219.636719,699.923218 
	C2219.975342,699.289734 2220.313965,698.656250 2220.652588,698.022705 
	C2222.434326,698.866150 2224.216309,699.709534 2226.328125,700.732544 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2135.005859,629.909180 
	C2134.552734,631.411682 2134.157471,632.518738 2133.762207,633.625732 
	C2133.123047,633.461792 2132.483887,633.297913 2131.844727,633.133972 
	C2132.465576,630.028870 2133.086670,626.923767 2133.707764,623.818665 
	C2134.468018,623.959778 2135.228516,624.100891 2135.989014,624.241943 
	C2135.680420,625.999207 2135.372070,627.756470 2135.005859,629.909180 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2254.910156,971.782959 
	C2251.303711,968.688293 2253.148193,965.495667 2253.522461,962.303284 
	C2254.303223,962.304749 2255.083984,962.306213 2255.864502,962.307739 
	C2255.686279,965.433777 2255.508057,968.559875 2254.910156,971.782959 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2254.950195,990.191528 
	C2255.556396,993.445679 2255.751221,996.580139 2255.945801,999.714600 
	C2255.211426,999.710144 2254.476807,999.705688 2253.742432,999.701172 
	C2253.009766,996.496582 2251.701416,993.295044 2254.950195,990.191528 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2185.879395,944.714539 
	C2186.821289,944.348877 2187.815674,944.348877 2188.917969,944.348877 
	C2188.515869,945.622009 2188.229492,946.528870 2187.971436,947.731689 
	C2184.672852,949.395813 2181.345459,950.764038 2177.646484,952.234375 
	C2177.274658,952.336426 2177.068604,952.078918 2176.961670,951.951294 
	C2178.945557,950.881653 2181.204590,950.185547 2183.068359,948.911316 
	C2184.313721,948.059875 2184.997070,946.386047 2185.879395,944.714539 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2002.265869,620.199341 
	C2001.277100,618.808289 2000.547729,617.658691 1999.925537,616.213989 
	C2002.004517,616.391418 2003.976440,616.863831 2005.948242,617.336304 
	C2006.219238,616.883179 2006.490112,616.429993 2006.760986,615.976868 
	C2006.760986,617.803650 2006.760986,619.630371 2006.760986,621.449890 
	C2005.388916,621.123047 2003.957031,620.781921 2002.265869,620.199341 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2370.182861,810.022034 
	C2372.683838,808.586914 2374.867432,807.292114 2377.448242,805.761902 
	C2377.070312,808.892212 2372.282227,812.989868 2370.366943,812.338196 
	C2369.996094,812.212097 2370.022461,810.917786 2370.182861,810.022034 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2285.261719,810.384277 
	C2283.743164,813.610657 2281.641113,816.172546 2278.128906,816.651123 
	C2277.730713,816.045837 2277.332764,815.440552 2276.934570,814.835266 
	C2279.618408,813.281555 2282.302246,811.727783 2285.261719,810.384277 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2184.172363,753.982544 
	C2184.879883,750.921509 2185.941406,747.925476 2187.002686,744.929443 
	C2187.861572,745.359192 2188.720703,745.788940 2189.579590,746.218689 
	C2187.895020,748.828308 2186.210693,751.437927 2184.172363,753.982544 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2199.739014,723.892883 
	C2200.723389,723.213562 2201.475342,722.794556 2202.227051,722.375549 
	C2202.556396,722.688782 2202.885986,723.002075 2203.215332,723.315308 
	C2201.388428,725.976196 2199.561523,728.637146 2197.734619,731.298035 
	C2197.169922,730.937988 2196.605469,730.577942 2196.040771,730.217896 
	C2197.196045,728.196350 2198.351074,726.174744 2199.739014,723.892883 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2131.333496,643.326660 
	C2130.582764,644.521301 2129.961426,645.351379 2129.340088,646.181519 
	C2128.859863,645.915588 2128.379639,645.649719 2127.899170,645.383850 
	C2128.835938,642.480347 2129.772949,639.576843 2130.709717,636.673340 
	C2131.424805,636.881348 2132.140137,637.089355 2132.855225,637.297363 
	C2132.391113,639.185608 2131.927246,641.073853 2131.333496,643.326660 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1977.634155,610.127686 
	C1975.416992,609.154236 1973.562744,608.061523 1971.390503,606.978271 
	C1972.305542,603.181824 1975.267944,605.012817 1977.492065,605.350891 
	C1977.887329,605.411011 1977.839966,608.381653 1977.634155,610.127686 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2242.771973,769.842468 
	C2240.910400,772.414001 2238.796631,775.240784 2236.327637,778.035400 
	C2238.154785,775.367981 2240.337402,772.732849 2242.771973,769.842468 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2058.838135,985.848755 
	C2060.933350,983.325500 2063.028564,980.802124 2065.123779,978.278809 
	C2065.561279,978.675415 2065.998779,979.071960 2066.436523,979.468567 
	C2064.255859,981.665222 2062.075195,983.861877 2059.466309,986.049438 
	C2059.037842,986.040405 2058.838135,985.848755 2058.838135,985.848755 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2239.371338,781.094482 
	C2240.813232,778.760132 2242.609131,776.500488 2244.714355,774.110229 
	C2243.257324,776.376160 2241.491211,778.772705 2239.371338,781.094482 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1971.027588,610.333862 
	C1972.943481,609.992188 1974.824097,609.998962 1976.972656,610.182129 
	C1975.262329,612.482300 1973.255127,614.046692 1971.027588,610.333862 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1949.260742,628.207336 
	C1950.416992,628.774719 1951.255371,629.440613 1952.138062,630.374634 
	C1951.013184,631.015198 1949.844238,631.387634 1948.298828,631.880066 
	C1948.522705,630.637268 1948.732788,629.471497 1949.260742,628.207336 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2046.674316,998.231689 
	C2047.869629,996.897156 2048.956055,995.860107 2050.042480,994.823059 
	C2050.387207,995.328857 2050.731689,995.834717 2051.076416,996.340576 
	C2049.572754,997.070068 2048.069092,997.799622 2046.674316,998.231689 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2327.807617,879.797913 
	C2327.468506,881.119690 2327.085205,882.169128 2326.702148,883.218567 
	C2326.264893,883.036865 2325.827637,882.855164 2325.390381,882.673462 
	C2325.995361,881.757446 2326.610352,880.847656 2327.198975,879.921265 
	C2327.360596,879.667114 2327.444092,879.363525 2327.563721,879.082764 
	C2327.630371,879.230347 2327.697021,879.377930 2327.807617,879.797913 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2071.934326,740.540222 
	C2070.284424,741.572510 2068.514160,742.227234 2066.389404,742.944946 
	C2065.599854,737.263245 2069.640869,740.609741 2071.934326,740.540222 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2039.987305,1005.987244 
	C2041.020874,1004.377319 2042.070679,1002.784058 2043.120483,1001.190735 
	C2043.542847,1001.544434 2043.965210,1001.898132 2044.387573,1002.251831 
	C2043.165527,1003.492737 2041.943481,1004.733582 2040.378906,1006.005737 
	C2040.036499,1006.036926 2040.003662,1006.003845 2039.987305,1005.987244 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2242.694824,800.980957 
	C2241.737549,802.227661 2240.801514,803.132019 2239.865723,804.036316 
	C2239.504150,803.523499 2239.142822,803.010681 2238.781250,802.497864 
	C2240.092773,801.878113 2241.404541,801.258362 2242.694824,800.980957 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2066.275635,747.089844 
	C2067.227051,746.749512 2068.405762,746.601013 2069.785645,746.712891 
	C2068.825684,747.076111 2067.664307,747.178833 2066.275635,747.089844 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1743.365234,649.140991 
	C1743.486084,647.745056 1743.809937,646.378174 1744.133789,645.011292 
	C1744.672485,645.189453 1745.211304,645.367615 1745.750000,645.545776 
	C1745.272217,646.521057 1744.794556,647.496338 1744.135498,648.751343 
	C1743.954102,649.031067 1743.568115,649.169983 1743.365234,649.140991 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1881.840820,680.656799 
	C1879.918945,680.995728 1877.783325,680.994568 1875.175293,680.979126 
	C1877.010986,680.748779 1879.318970,680.532715 1881.840820,680.656799 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2278.177246,883.696899 
	C2279.036865,883.342590 2279.921387,883.331665 2280.805908,883.320740 
	C2280.475098,883.892212 2280.144531,884.463745 2279.465820,885.023560 
	C2278.812500,884.688049 2278.507568,884.364197 2278.177246,883.696899 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2211.385986,789.029175 
	C2212.146973,788.837463 2212.770264,788.946533 2213.717285,789.038452 
	C2214.041504,789.021362 2214.183105,789.489014 2214.229004,789.729004 
	C2213.235596,789.900024 2211.468506,793.278442 2211.385986,789.029175 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1949.751831,661.800537 
	C1950.214600,660.562439 1950.820923,659.437256 1951.707886,658.152466 
	C1951.988403,657.992981 1951.978638,657.980103 1952.079102,658.314697 
	C1951.513428,659.813293 1950.847290,660.977234 1950.181152,662.141235 
	C1950.181152,662.141235 1949.895508,661.913391 1949.751831,661.800537 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1923.119629,605.916992 
	C1923.151489,604.723511 1923.593872,603.566650 1924.502563,601.190796 
	C1924.886963,603.423157 1925.041626,604.321045 1925.186523,605.573669 
	C1924.627930,605.936768 1924.078979,605.945129 1923.119629,605.916992 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2366.514648,991.668335 
	C2365.715576,991.639160 2365.160156,991.414551 2364.604492,991.190002 
	C2364.729492,990.920044 2364.854492,990.650146 2364.979492,990.380249 
	C2365.572266,990.744507 2366.165283,991.108704 2366.514648,991.668335 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2087.511719,955.917969 
	C2088.074219,955.526001 2088.607178,955.373657 2089.140137,955.221313 
	C2089.188232,955.477173 2089.236572,955.733032 2089.284912,955.988831 
	C2088.684082,956.045105 2088.083252,956.101379 2087.511719,955.917969 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1909.956543,670.905884 
	C1910.102905,667.069824 1911.572998,669.697693 1912.899780,670.677856 
	C1912.569336,670.926147 1912.063843,670.866943 1911.177490,670.891968 
	C1910.609375,670.966064 1910.422119,670.955811 1909.956543,670.905884 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2236.359619,784.076721 
	C2236.465088,783.364563 2236.916992,782.707397 2237.697510,782.024658 
	C2237.586182,782.710022 2237.145996,783.420898 2236.359619,784.076721 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2225.322754,795.924927 
	C2225.472656,795.104980 2225.944092,794.182190 2226.719238,793.119202 
	C2226.563232,793.926758 2226.103760,794.874390 2225.322754,795.924927 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2245.661865,796.674805 
	C2246.207764,796.156555 2246.753662,795.638184 2247.299316,795.119751 
	C2247.139160,795.757080 2246.979004,796.394409 2246.388672,797.000000 
	C2245.958740,796.968262 2245.661865,796.674805 2245.661865,796.674805 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2255.657227,787.907959 
	C2255.223877,788.328918 2254.828857,788.445007 2254.433594,788.561157 
	C2254.361328,788.281128 2254.289062,788.001099 2254.217041,787.721069 
	C2254.709961,787.681702 2255.202637,787.642395 2255.657227,787.907959 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1774.763794,669.638733 
	C1774.201904,669.950623 1773.265259,670.139282 1772.185791,670.021851 
	C1772.825073,669.649109 1773.607056,669.582275 1774.763794,669.638733 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2094.923096,986.950562 
	C2094.411377,987.233826 2093.899658,987.517151 2094.441162,987.217285 
	C2093.673584,987.351135 2094.089111,987.278687 2094.731934,987.088257 
	C2094.959229,986.970337 2094.923096,986.950562 2094.923096,986.950562 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2247.733398,765.084717 
	C2247.557617,765.652893 2247.105713,766.335510 2246.310059,767.015381 
	C2246.462891,766.408081 2246.959961,765.803650 2247.733398,765.084717 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2232.338379,788.067566 
	C2232.472900,787.410217 2232.965332,786.796570 2233.741699,786.085083 
	C2233.582520,786.695190 2233.139404,787.403259 2232.338379,788.067566 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2230.332031,790.039795 
	C2230.475586,789.415100 2230.969727,788.805908 2231.744141,788.088806 
	C2231.577148,788.672424 2231.130127,789.363892 2230.332031,790.039795 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2249.708252,763.921509 
	C2249.552490,764.347717 2249.075684,764.720337 2248.284180,765.049744 
	C2248.442383,764.626953 2248.914551,764.247437 2249.708252,763.921509 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1926.326904,605.844482 
	C1926.398315,605.535095 1926.991577,605.144897 1927.703003,605.052795 
	C1927.496948,605.488464 1927.172852,605.626099 1926.326904,605.844482 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2249.807129,791.840027 
	C2250.327881,791.608337 2250.848633,791.376709 2251.369385,791.145020 
	C2251.232910,791.492676 2251.096191,791.840332 2250.532227,792.136230 
	C2250.104736,792.084412 2249.807129,791.840027 2249.807129,791.840027 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2224.734619,789.130005 
	C2224.855957,789.560303 2224.706787,790.151123 2224.266602,790.875610 
	C2224.138184,790.436340 2224.301270,789.863464 2224.734619,789.130005 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2250.707764,762.099243 
	C2250.833496,762.540955 2250.663086,763.111145 2250.234375,763.855896 
	C2250.120850,763.429565 2250.266357,762.828613 2250.707764,762.099243 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1920.211060,597.336548 
	C1920.420410,597.850281 1920.545044,598.428772 1920.345825,599.080078 
	C1920.006348,598.791687 1919.990601,598.430664 1919.966309,597.801025 
	C1919.957764,597.532471 1920.126221,597.401306 1920.211060,597.336548 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2094.967773,986.987244 
	C2095.501953,986.773865 2096.027588,986.543518 2096.811279,986.176514 
	C2097.069092,986.039734 2097.032959,986.020874 2097.025391,986.004333 
	C2096.498047,986.230225 2095.978271,986.472717 2095.190918,986.832886 
	C2094.923096,986.950562 2094.959229,986.970337 2094.967773,986.987244 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2226.686035,787.892822 
	C2226.536621,788.364319 2226.072998,788.753052 2225.289551,789.072876 
	C2225.437256,788.605957 2225.904541,788.207947 2226.686035,787.892822 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2231.692383,782.911133 
	C2231.543945,783.370605 2231.079102,783.766174 2230.293945,784.078003 
	C2230.441406,783.611938 2230.908936,783.229614 2231.692383,782.911133 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2052.319824,993.395508 
	C2052.279785,992.970276 2052.427734,992.705933 2052.575439,992.441589 
	C2052.552979,992.813171 2052.530273,993.184753 2052.319824,993.395508 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2229.688477,784.094727 
	C2229.860596,784.570312 2229.727051,785.166870 2229.284912,785.879395 
	C2229.111816,785.402222 2229.247314,784.809021 2229.688477,784.094727 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2235.699707,778.094604 
	C2235.861328,778.570618 2235.722412,779.170044 2235.279053,779.890076 
	C2235.116211,779.413208 2235.257568,778.815613 2235.699707,778.094604 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2250.256592,792.202515 
	C2250.034180,792.693359 2249.659912,793.066101 2249.285645,793.438782 
	C2249.316162,793.027466 2249.346436,792.616150 2249.592041,792.022461 
	C2249.807129,791.840027 2250.104736,792.084412 2250.256592,792.202515 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2063.593262,999.836548 
	C2063.619141,999.870422 2063.567383,999.802734 2063.593262,999.836548 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2055.180176,991.160645 
	C2054.936279,991.360840 2054.692383,991.560974 2054.448730,991.761108 
	C2054.387451,991.492371 2054.326660,991.223694 2054.597412,990.938232 
	C2054.928955,990.921387 2055.179932,991.160522 2055.180176,991.160645 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2045.362793,1000.540161 
	C2045.282349,1000.431213 2045.577515,1000.375000 2045.577515,1000.375000 
	C2045.577515,1000.375000 2045.443237,1000.649170 2045.362793,1000.540161 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2247.867188,771.036926 
	C2247.815430,770.749817 2248.119141,770.461853 2248.722900,770.087524 
	C2248.756104,770.346130 2248.489258,770.691101 2247.867188,771.036926 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2335.922363,729.372437 
	C2336.243164,729.223816 2336.522217,729.388672 2336.871094,729.795227 
	C2336.587402,729.919861 2336.233887,729.802856 2335.922363,729.372437 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2254.291260,765.012573 
	C2254.161865,764.717163 2254.324707,764.408997 2254.755859,764.051392 
	C2254.877441,764.334595 2254.730469,764.667175 2254.291260,765.012573 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2079.728027,752.101135 
	C2079.936035,752.359741 2079.788574,752.622192 2079.244385,752.827759 
	C2079.120117,752.588867 2079.295166,752.366943 2079.728027,752.101135 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2262.985840,756.980591 
	C2262.878174,757.019897 2262.784424,757.075073 2262.861816,757.076355 
	C2263.033203,757.022461 2262.999756,756.996460 2262.985840,756.980591 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2251.739502,761.027832 
	C2251.850342,761.294861 2251.693359,761.617493 2251.254395,761.974731 
	C2251.138672,761.700623 2251.305176,761.392029 2251.739502,761.027832 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2255.291992,764.006958 
	C2255.164551,763.715332 2255.326660,763.406189 2255.757812,763.043701 
	C2255.878662,763.323425 2255.729980,763.656433 2255.291992,764.006958 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2253.302246,766.018433 
	C2253.169678,765.718018 2253.331787,765.413452 2253.759766,765.053711 
	C2253.882324,765.337036 2253.739746,765.675659 2253.302246,766.018433 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2252.280273,767.001953 
	C2252.156738,766.720764 2252.323486,766.414856 2252.756104,766.057922 
	C2252.871826,766.330322 2252.721191,766.653748 2252.280273,767.001953 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2245.774170,767.062622 
	C2245.850586,767.281860 2245.683105,767.597168 2245.241943,767.964722 
	C2245.155518,767.730896 2245.343018,767.444763 2245.774170,767.062622 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2251.285156,767.998596 
	C2251.161621,767.717529 2251.324219,767.409363 2251.756836,767.045959 
	C2251.875000,767.317627 2251.723389,767.644531 2251.285156,767.998596 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2244.743652,768.087769 
	C2244.762695,768.299133 2244.505615,768.629639 2243.914551,768.996887 
	C2243.876221,768.758057 2244.171631,768.482544 2244.743652,768.087769 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2250.279785,769.001221 
	C2250.156006,768.713074 2250.317871,768.399475 2250.752686,768.042358 
	C2250.872314,768.324463 2250.718994,768.650146 2250.279785,769.001221 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2249.294678,770.011597 
	C2249.164795,769.715637 2249.325439,769.407227 2249.756348,769.046082 
	C2249.879150,769.328613 2249.732178,769.663879 2249.294678,770.011597 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2246.271484,772.896484 
	C2246.197266,772.700134 2246.425049,772.362854 2246.848633,771.783081 
	C2246.887207,771.945618 2246.730225,772.350586 2246.271484,772.896484 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2073.223389,741.791870 
	C2072.935791,741.916138 2072.609375,741.688416 2072.172363,741.182739 
	C2072.436035,741.083191 2072.810303,741.261536 2073.223389,741.791870 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2225.621094,747.432373 
	C2225.687988,747.517578 2225.443359,747.555176 2225.443359,747.555176 
	C2225.443359,747.555176 2225.554443,747.347107 2225.621094,747.432373 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2079.032715,748.226929 
	C2079.176270,748.250183 2079.317139,748.572327 2079.260742,749.008545 
	C2079.052246,748.923706 2079.041016,748.724731 2079.032715,748.226929 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1713.240845,649.938110 
	C1713.245483,649.752258 1713.575073,649.591125 1714.044189,649.593262 
	C1713.977783,649.825317 1713.771729,649.894043 1713.240845,649.938110 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1967.972778,618.034180 
	C1967.758911,617.893494 1967.545044,617.752808 1967.331177,617.612183 
	C1967.533447,617.598633 1967.735840,617.585083 1967.969604,617.785889 
	C1968.001099,618.000305 1967.972778,618.034180 1967.972778,618.034180 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1968.991943,619.006897 
	C1968.635620,618.956238 1968.362671,618.781555 1968.067871,618.263306 
	C1967.972778,618.034180 1968.001099,618.000305 1968.018311,617.985474 
	C1968.314209,618.145630 1968.592773,618.320618 1968.945801,618.735352 
	C1969.020508,618.975037 1969.002075,618.997314 1968.991943,619.006897 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1873.200317,680.700806 
	C1873.078125,680.968872 1872.803467,680.984131 1872.113770,680.979919 
	C1872.148438,680.789612 1872.598022,680.618835 1873.200317,680.700806 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2124.054443,974.783386 
	C2123.995117,974.765320 2124.120361,974.766174 2124.129150,974.796265 
	C2124.137939,974.826355 2124.113770,974.801453 2124.054443,974.783386 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2057.808594,986.820984 
	C2057.922363,986.502625 2058.119385,986.248901 2058.618896,985.954285 
	C2058.838135,985.848755 2059.037842,986.040405 2059.139648,986.139282 
	C2059.137451,986.549927 2058.972900,986.825562 2058.409668,987.068237 
	C2058.071777,987.071533 2057.808594,986.820984 2057.808594,986.820984 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2058.203613,987.196960 
	C2058.188965,987.622986 2057.985596,987.878296 2057.393066,988.078735 
	C2057.061035,988.069153 2056.847412,987.864685 2056.847412,987.864685 
	C2056.929199,987.532349 2057.108154,987.265320 2057.596680,986.942261 
	C2057.808594,986.820984 2058.071777,987.071533 2058.203613,987.196960 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2057.165283,988.173950 
	C2057.053467,988.458130 2056.837402,988.637512 2056.621094,988.816772 
	C2056.579834,988.583618 2056.538574,988.350403 2056.672363,987.990967 
	C2056.847412,987.864685 2057.061035,988.069153 2057.165283,988.173950 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2363.496094,988.506958 
	C2363.516846,988.493164 2363.475098,988.520752 2363.496094,988.506958 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2223.713379,790.989258 
	C2223.840332,791.284241 2223.677490,791.594604 2223.244141,791.956360 
	C2223.123291,791.673340 2223.273193,791.338989 2223.713379,790.989258 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2222.730225,792.011963 
	C2222.838867,792.278442 2222.669922,792.583740 2222.236328,792.953369 
	C2222.131836,792.695496 2222.292236,792.373108 2222.730225,792.011963 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2227.312012,792.921997 
	C2227.200439,792.685059 2227.420166,792.344238 2227.840332,791.766296 
	C2227.908447,791.958801 2227.775635,792.388489 2227.312012,792.921997 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2246.104492,797.117676 
	C2245.932861,797.172119 2245.615479,797.077209 2245.479980,796.828552 
	C2245.661865,796.674805 2245.958740,796.968262 2246.104492,797.117676 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2237.423828,804.635376 
	C2237.320312,804.506104 2237.686768,804.433533 2237.686768,804.433533 
	C2237.686768,804.433533 2237.527344,804.764587 2237.423828,804.635376 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2245.258301,773.978271 
	C2245.169434,773.738281 2245.357666,773.450745 2245.791016,773.066528 
	C2245.869385,773.290222 2245.702148,773.610535 2245.258301,773.978271 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2234.725830,780.007202 
	C2234.838867,780.279663 2234.671387,780.585693 2234.238281,780.952148 
	C2234.130127,780.688599 2234.287842,780.364685 2234.725830,780.007202 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2233.737793,781.020691 
	C2233.843262,781.278503 2233.673828,781.584412 2233.238525,781.951355 
	C2233.135986,781.697876 2233.299561,781.383362 2233.737793,781.020691 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2238.290039,782.015259 
	C2238.156982,781.716980 2238.317627,781.407776 2238.747803,781.057251 
	C2238.872803,781.345398 2238.728271,781.674866 2238.290039,782.015259 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2232.733398,782.017639 
	C2232.843750,782.278076 2232.675293,782.583435 2232.240479,782.946777 
	C2232.134277,782.690674 2232.294434,782.376648 2232.733398,782.017639 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2235.301270,785.016968 
	C2235.166992,784.716064 2235.325684,784.409729 2235.752441,784.057129 
	C2235.878418,784.344360 2235.736328,784.677856 2235.301270,785.016968 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2234.284424,786.017334 
	C2234.156006,785.719543 2234.322754,785.412476 2234.755859,785.056335 
	C2234.874756,785.340881 2234.727051,785.674438 2234.284424,786.017334 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2256.425293,785.406494 
	C2256.651367,785.390015 2256.728027,785.491577 2256.804688,785.593140 
	C2256.628418,785.570251 2256.452148,785.547424 2256.425293,785.406494 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2228.690674,785.972168 
	C2228.828369,786.279724 2228.665771,786.581421 2228.240479,786.937988 
	C2228.115234,786.650635 2228.252930,786.308472 2228.690674,785.972168 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2227.692139,786.973083 
	C2227.828613,787.279358 2227.665039,787.581299 2227.238770,787.939819 
	C2227.114746,787.653870 2227.253174,787.311279 2227.692139,786.973083 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2252.424561,789.685059 
	C2252.403076,789.467957 2252.495605,789.391174 2252.588379,789.314392 
	C2252.664307,789.407471 2252.807129,789.507751 2252.797607,789.591553 
	C2252.788330,789.677856 2252.631348,789.748108 2252.424561,789.685059 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2228.858398,791.034180 
	C2228.815430,790.756653 2229.127930,790.478333 2229.733887,790.091187 
	C2229.756104,790.332703 2229.485107,790.682983 2228.858398,791.034180 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2054.806641,990.798645 
	C2054.920166,990.500488 2055.156250,990.325012 2055.392090,990.149597 
	C2055.440186,990.394714 2055.488037,990.639893 2055.357910,991.022766 
	C2055.179932,991.160522 2054.928955,990.921387 2054.806641,990.798645 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1731.993896,876.061707 
	C1732.014648,876.397217 1732.035522,876.732727 1732.065430,877.318726 
	C1730.931763,877.713684 1729.791748,877.957886 1728.646118,877.987061 
	C1718.975342,878.232971 1718.940674,878.249695 1717.560913,887.453003 
	C1716.014038,887.588196 1714.393066,887.729858 1712.314697,887.557251 
	C1711.907593,886.825623 1711.958130,886.408142 1712.008667,885.990662 
	C1713.457275,885.990662 1714.905884,885.990662 1717.072144,885.990662 
	C1716.581909,883.172363 1716.180908,880.867798 1715.703247,878.122009 
	C1707.640747,878.122009 1699.771362,878.122009 1691.439453,878.031189 
	C1690.976807,877.622498 1690.954468,877.306152 1691.390869,876.627991 
	C1697.285522,876.264587 1702.699097,876.264587 1707.931152,876.264587 
	C1708.383667,872.483826 1708.703125,869.813660 1709.022705,867.143494 
	C1709.546997,867.177490 1710.071289,867.211548 1710.595581,867.245544 
	C1710.843506,870.064758 1711.091553,872.884033 1711.438110,876.823547 
	C1714.314209,875.486206 1716.481567,874.478394 1717.664429,873.928467 
	C1721.266602,874.524048 1724.188477,875.033508 1727.119751,875.481384 
	C1728.736328,875.728271 1730.368652,875.871704 1731.993896,876.061707 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1959.963745,874.897095 
	C1947.956543,875.044983 1935.949341,875.192932 1923.474121,874.997559 
	C1923.019287,874.274231 1923.032227,873.894287 1923.314697,873.282410 
	C1924.409546,872.023499 1925.235107,870.996582 1925.977051,870.073608 
	C1925.395752,869.448669 1925.287231,869.229004 1925.173950,869.226562 
	C1924.012085,869.201172 1922.849365,869.210938 1921.670288,869.210938 
	C1924.169800,866.456787 1924.169800,866.456787 1928.748291,868.900879 
	C1929.306152,868.841309 1929.532837,868.869995 1929.643921,869.321289 
	C1929.439331,873.031860 1931.363281,873.988342 1934.270508,873.930542 
	C1937.414795,873.868103 1940.561646,873.929565 1943.706421,873.884521 
	C1953.465698,873.744812 1952.202759,875.397522 1952.403320,865.152832 
	C1955.820557,865.316040 1958.430298,868.990906 1961.882202,866.744263 
	C1962.022339,867.535767 1962.071289,867.853577 1961.962158,868.521362 
	C1962.224731,870.203064 1962.645264,871.534729 1963.128174,873.148315 
	C1963.456299,873.942139 1963.722168,874.453857 1963.988037,874.965576 
	C1963.247314,874.996033 1962.506714,875.026489 1961.388428,874.720398 
	C1960.837524,874.127502 1960.664062,873.871033 1960.490479,873.614624 
	C1960.314941,874.042114 1960.139404,874.469604 1959.963745,874.897095 
M1955.517578,868.836304 
	C1954.785278,870.089233 1954.052979,871.342102 1953.320557,872.595032 
	C1954.054077,872.833069 1954.787476,873.071106 1955.520996,873.309204 
	C1955.757324,871.760681 1955.993774,870.212097 1955.517578,868.836304 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1852.942017,885.125793 
	C1854.170288,884.445251 1855.338745,883.594238 1856.645142,883.137085 
	C1857.727539,882.758240 1859.014404,882.587830 1860.137085,882.772644 
	C1863.230957,883.282227 1866.286255,884.026733 1871.036133,885.034668 
	C1869.926880,881.164795 1869.258667,878.833618 1868.292480,875.994385 
	C1867.960083,875.161438 1867.954590,874.835999 1868.309082,874.389648 
	C1869.106689,874.215332 1869.573120,874.161438 1870.105225,874.481140 
	C1871.558594,875.310364 1872.946533,875.765930 1874.334351,876.221497 
	C1873.954834,880.241760 1873.575439,884.262024 1873.215576,888.074829 
	C1870.072632,887.438049 1867.152954,886.695618 1864.186890,886.323364 
	C1863.075562,886.183838 1861.321411,886.528259 1860.777100,887.299194 
	C1858.249023,890.880798 1856.136353,889.068604 1853.460693,886.771606 
	C1852.988525,885.989868 1852.965332,885.557800 1852.942017,885.125793 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1867.978027,874.510132 
	C1867.954590,874.835999 1867.960083,875.161438 1868.000000,875.730530 
	C1866.935303,876.012573 1865.865356,876.050354 1864.307617,875.855713 
	C1863.546021,875.741882 1863.272339,875.860413 1862.998657,875.979004 
	C1846.648804,876.200867 1830.298828,876.422791 1813.628906,876.254761 
	C1814.310303,875.585449 1815.397949,875.453125 1816.264160,874.944092 
	C1816.552246,874.774719 1816.272461,873.639038 1816.024414,872.681152 
	C1815.542969,871.766846 1815.289307,871.116516 1815.007568,870.479126 
	C1814.979492,870.492004 1815.001831,870.549744 1815.335449,870.401123 
	C1817.246582,869.341675 1818.824219,868.430847 1820.401855,867.520142 
	C1821.105347,869.712036 1821.808960,871.903931 1822.723145,874.752136 
	C1823.761230,874.752136 1825.549561,874.761108 1827.337891,874.750793 
	C1840.884644,874.672729 1854.431274,874.590881 1867.978027,874.510132 
M1820.470093,874.367615 
	C1820.470093,874.367615 1820.711548,874.486694 1820.711548,874.486694 
	C1820.711548,874.486694 1820.665039,874.244812 1820.470093,874.367615 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1923.045288,873.514343 
	C1923.032227,873.894287 1923.019287,874.274231 1923.008789,874.939697 
	C1917.131592,875.454163 1911.253662,875.757812 1905.371826,875.894592 
	C1896.463013,876.101929 1887.551514,876.193115 1878.319336,875.894531 
	C1877.985718,875.094604 1877.973877,874.733887 1878.420654,874.252686 
	C1893.601196,873.926270 1908.323242,873.720337 1923.045288,873.514343 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1786.385376,877.010864 
	C1785.149658,873.152954 1785.204834,869.508667 1788.329102,866.100159 
	C1790.029297,866.001770 1791.052490,866.039978 1792.061523,866.499390 
	C1793.235474,868.045715 1794.250366,869.702820 1795.655762,870.160461 
	C1797.393188,870.726257 1799.454956,870.295837 1801.376587,870.295837 
	C1801.351562,870.864502 1801.326538,871.433105 1801.301636,872.001770 
	C1799.372070,872.001770 1797.363281,871.681091 1795.544678,872.130310 
	C1794.560669,872.373352 1793.901001,873.930176 1793.036743,874.957581 
	C1792.978394,875.020752 1792.860107,875.145508 1792.524292,875.114014 
	C1791.464111,875.699768 1790.739624,876.317017 1790.015259,876.934204 
	C1788.938354,876.984619 1787.861328,877.035095 1786.385376,877.010864 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1980.350586,887.776794 
	C1977.614746,889.215027 1974.976196,890.344421 1974.377930,885.227661 
	C1974.948242,883.852722 1975.262329,883.063477 1975.576416,882.274292 
	C1978.772705,878.996399 1979.689819,885.216736 1982.275024,883.911987 
	C1984.336548,882.871521 1985.673340,879.270325 1988.837402,882.998657 
	C1988.964233,884.075989 1988.967773,884.503784 1988.971191,884.931641 
	C1987.432617,885.901794 1985.894043,886.871887 1983.825439,887.479736 
	C1982.827637,886.375793 1982.359863,885.634155 1981.623047,884.465698 
	C1981.072144,885.899109 1980.711426,886.837952 1980.350586,887.776794 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1852.812256,884.792297 
	C1852.965332,885.557800 1852.988525,885.989868 1853.096191,886.556152 
	C1851.791138,890.202942 1849.163086,890.506409 1845.392578,889.322266 
	C1844.485718,889.140930 1844.199951,889.089966 1843.915039,889.034912 
	C1843.945190,888.611694 1843.975342,888.188477 1844.393677,887.380005 
	C1845.331787,885.891602 1845.881836,884.788452 1846.431885,883.685242 
	C1848.866089,881.109558 1850.942627,881.423828 1852.812256,884.792297 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1877.962036,874.373169 
	C1877.973877,874.733887 1877.985718,875.094604 1877.999023,875.726074 
	C1877.021973,876.068481 1876.043579,876.140198 1874.699707,876.216675 
	C1872.946533,875.765930 1871.558594,875.310364 1870.233643,874.047119 
	C1870.576416,870.600342 1870.856323,867.961243 1871.257446,864.180176 
	C1874.855469,868.009033 1871.163696,874.565491 1877.962036,874.373169 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1815.001831,870.549744 
	C1815.001831,870.549744 1814.979492,870.492004 1814.649048,870.683350 
	C1814.363037,871.838989 1814.407471,872.803345 1814.451904,873.767700 
	C1815.052124,873.493469 1815.652222,873.219299 1816.252441,872.945068 
	C1816.272461,873.639038 1816.552246,874.774719 1816.264160,874.944092 
	C1815.397949,875.453125 1814.310303,875.585449 1813.217529,876.162231 
	C1812.327026,876.584167 1811.528198,876.708557 1810.344727,876.422852 
	C1809.686890,875.352234 1809.413452,874.691833 1809.281494,873.730347 
	C1810.242065,872.459473 1811.061035,871.489624 1812.093872,870.266602 
	C1810.811035,869.896179 1809.828125,869.612427 1808.845215,869.328613 
	C1811.115112,869.062500 1814.191406,865.772278 1815.001831,870.549744 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1711.762451,885.735657 
	C1711.958130,886.408142 1711.907593,886.825623 1711.878906,887.557983 
	C1711.349121,888.629272 1710.797485,889.385620 1710.041016,890.339600 
	C1708.615356,890.232300 1707.394775,889.927246 1706.407104,889.379761 
	C1706.721680,888.051025 1706.803223,886.964722 1706.884888,885.878479 
	C1708.004761,884.429688 1709.124512,882.980835 1710.131104,881.678528 
	C1710.542969,882.809143 1711.029541,884.144897 1711.762451,885.735657 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1989.421631,884.818726 
	C1988.967773,884.503784 1988.964233,884.075989 1988.979004,883.327759 
	C1991.791260,883.007263 1994.585083,883.007263 1997.706421,883.007263 
	C1997.876709,881.509888 1998.039429,880.079285 1998.680908,878.277283 
	C1999.390869,877.886353 1999.622192,877.866821 1999.853638,877.847229 
	C1999.853638,877.847229 1999.975098,877.972290 2000.036377,878.034424 
	C2000.097656,880.064209 2000.097656,882.031860 2000.097656,884.705933 
	C1996.390869,884.705933 1993.131592,884.705933 1989.421631,884.818726 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1953.669800,887.296021 
	C1950.029175,885.983826 1950.024780,880.879028 1953.923950,877.517639 
	C1953.923950,880.948303 1953.923950,883.953796 1953.669800,887.296021 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1789.919678,882.320007 
	C1790.888550,881.698181 1791.841553,881.504517 1792.794678,881.310791 
	C1792.665283,884.078552 1792.536011,886.846252 1792.406616,889.614014 
	C1791.750244,889.612671 1791.093872,889.611267 1790.437500,889.609924 
	C1790.259644,887.322632 1790.081665,885.035400 1789.919678,882.320007 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1809.140015,874.031372 
	C1809.413452,874.691833 1809.686890,875.352234 1809.952881,876.298096 
	C1807.604004,876.669128 1805.262451,876.754761 1802.477051,876.484802 
	C1802.022217,875.752991 1802.011108,875.376770 1802.399170,874.936829 
	C1803.905640,872.604370 1805.013184,870.335693 1806.202148,867.900146 
	C1806.202148,869.506592 1805.916870,871.211853 1806.335693,872.721863 
	C1806.515015,873.367920 1808.160767,873.607239 1809.140015,874.031372 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1802.000000,875.000610 
	C1802.011108,875.376770 1802.022217,875.752991 1802.044067,876.409790 
	C1799.325317,876.808533 1796.595825,876.926697 1793.422852,876.666748 
	C1792.939697,875.907532 1792.899902,875.526550 1792.860107,875.145508 
	C1792.860107,875.145508 1792.978394,875.020752 1793.444946,874.941162 
	C1796.607666,874.907898 1799.303833,874.954224 1802.000000,875.000610 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1964.420898,875.050354 
	C1963.722168,874.453857 1963.456299,873.942139 1963.545410,873.314880 
	C1966.274292,873.305054 1968.647949,873.410706 1971.030518,873.757996 
	C1971.013428,874.321106 1971.002319,874.643005 1971.005981,874.965454 
	C1968.955200,875.022034 1966.904541,875.078613 1964.420898,875.050354 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1846.228516,883.345764 
	C1845.881836,884.788452 1845.331787,885.891602 1844.415405,887.062500 
	C1843.865356,886.054565 1843.388550,884.908020 1843.623901,883.933533 
	C1843.738525,883.458923 1845.182861,883.305237 1846.228516,883.345764 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1975.260742,882.116943 
	C1975.262329,883.063477 1974.948242,883.852722 1974.402832,884.860229 
	C1973.465454,883.745789 1972.759521,882.413025 1972.053467,881.080261 
	C1972.262085,880.870422 1972.470825,880.660583 1972.679565,880.450745 
	C1973.434692,880.953735 1974.189941,881.456726 1975.260742,882.116943 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1971.418457,875.029297 
	C1971.002319,874.643005 1971.013428,874.321106 1971.440430,873.689636 
	C1973.221924,873.425293 1974.602417,873.470886 1975.982788,873.516541 
	C1975.958862,873.838623 1975.961670,874.160522 1975.992554,874.723511 
	C1974.606323,875.007629 1973.218628,875.050354 1971.418457,875.029297 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1792.524292,875.114014 
	C1792.899902,875.526550 1792.939697,875.907532 1792.976807,876.577271 
	C1792.233521,876.924805 1791.493164,876.983582 1790.384033,876.988281 
	C1790.739624,876.317017 1791.464111,875.699768 1792.524292,875.114014 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1999.920776,877.549438 
	C1999.622192,877.866821 1999.390869,877.886353 1998.810547,877.903320 
	C1998.536499,877.248413 1998.611328,876.596069 1998.750732,875.621399 
	C1999.063477,874.793579 1999.311646,874.288086 1999.559814,873.782654 
	C1999.702515,874.939026 1999.845215,876.095337 1999.920776,877.549438 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1980.902588,887.831421 
	C1980.711426,886.837952 1981.072144,885.899109 1981.623047,884.465698 
	C1982.359863,885.634155 1982.827637,886.375793 1983.482666,887.441406 
	C1982.931519,887.805603 1982.192993,887.845825 1980.902588,887.831421 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1917.055908,880.602295 
	C1916.842529,880.010864 1916.908203,879.400269 1916.973877,878.789673 
	C1917.229004,878.855469 1917.709595,878.960266 1917.706055,878.981384 
	C1917.613037,879.520630 1917.469482,880.051086 1917.055908,880.602295 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1843.695557,889.224976 
	C1844.199951,889.089966 1844.485718,889.140930 1844.979736,889.251953 
	C1844.699097,889.725830 1844.210815,890.135559 1843.722534,890.545288 
	C1843.640259,890.168518 1843.558105,889.791809 1843.695557,889.224976 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1960.233398,874.956665 
	C1960.139404,874.469604 1960.314941,874.042114 1960.490479,873.614624 
	C1960.664062,873.871033 1960.837524,874.127502 1961.025391,874.634277 
	C1960.860962,874.928528 1960.682007,874.972351 1960.233398,874.956665 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1863.250244,876.024536 
	C1863.272339,875.860413 1863.546021,875.741882 1863.910400,875.790894 
	C1863.834595,875.995667 1863.668213,876.032898 1863.250244,876.024536 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1972.445312,888.536743 
	C1972.408325,888.476501 1972.482422,888.596924 1972.445312,888.536743 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2126.962891,942.447021 
	C2118.901367,942.891296 2110.921387,942.953979 2102.472900,942.997070 
	C2086.128662,943.146423 2070.248779,943.167236 2054.379150,943.576111 
	C2049.706299,943.696411 2047.891724,940.209290 2044.772949,937.709717 
	C2044.427490,934.784302 2044.187378,932.017822 2044.406250,929.288086 
	C2044.495728,928.171387 2045.577148,927.134277 2047.052246,925.931335 
	C2053.302490,925.801697 2058.707764,925.801697 2064.113037,925.801697 
	C2064.100098,925.194336 2064.086914,924.586914 2064.073975,923.979553 
	C2058.387207,923.979553 2052.700439,923.979553 2047.013672,923.979553 
	C2047.013672,923.979553 2047.000000,924.000000 2046.918701,923.679016 
	C2045.973633,922.750427 2045.109863,922.142822 2044.246094,921.535217 
	C2044.238525,916.780029 2044.230835,912.024780 2044.600098,906.609009 
	C2046.568970,905.859802 2048.190918,905.536011 2049.746338,905.734619 
	C2052.411621,906.074951 2053.126465,904.692810 2053.576172,902.395996 
	C2054.403320,902.107178 2054.856934,901.891602 2055.458984,901.602661 
	C2055.856201,901.454224 2055.998047,901.287292 2056.019287,901.003540 
	C2056.005615,900.978577 2055.954834,901.004089 2055.779785,901.412903 
	C2057.271484,902.188660 2058.958252,902.901123 2060.597656,902.804871 
	C2061.753174,902.737061 2063.834229,901.480957 2063.782471,900.912048 
	C2063.613037,899.054932 2062.655273,897.269714 2062.113281,895.227478 
	C2062.166748,894.503235 2062.107422,894.008301 2062.511719,893.464050 
	C2063.059570,892.553406 2063.143799,891.692078 2063.081299,890.522705 
	C2062.161133,890.117676 2061.388184,890.020813 2060.247314,889.625366 
	C2059.215576,880.773621 2058.566895,872.219177 2057.852539,863.670227 
	C2057.805176,863.104309 2057.258789,862.580200 2056.838623,861.703979 
	C2055.835205,860.889893 2054.937744,860.408447 2054.023193,859.958252 
	C2054.006104,859.989563 2054.073486,859.966309 2054.250488,859.651062 
	C2053.871094,858.567993 2053.314697,857.800171 2052.730469,856.563660 
	C2052.413818,848.392639 2052.125244,840.690247 2052.113281,832.761292 
	C2052.309326,829.399963 2052.115479,826.262817 2052.180908,823.131165 
	C2052.239502,820.332031 2050.960449,819.139099 2048.278320,819.229858 
	C2041.965698,819.443481 2035.650024,819.606689 2029.343628,819.940125 
	C2028.187500,820.001282 2027.068848,820.770142 2025.932861,821.212280 
	C2026.023804,821.557190 2026.114746,821.902100 2026.205566,822.246948 
	C2027.700073,822.683105 2029.194458,823.119263 2030.399536,823.471008 
	C2030.399536,830.180786 2030.333740,836.473389 2030.429810,842.763550 
	C2030.471802,845.511597 2030.844971,848.254517 2030.998291,851.435913 
	C2030.809814,853.251404 2030.690796,854.630859 2030.301270,855.932739 
	C2029.849121,855.902527 2029.667480,855.949768 2029.375488,856.006958 
	C2029.264893,856.016907 2029.042969,856.016418 2029.155151,855.587708 
	C2026.861694,854.819885 2024.455933,854.480835 2021.586670,854.047546 
	C2004.307373,854.002136 1987.491577,854.091614 1970.676270,854.041809 
	C1968.833008,854.036377 1966.992065,853.230835 1965.451904,852.429810 
	C1965.753784,842.443359 1965.753784,832.823486 1965.753784,823.260559 
	C1968.074097,822.857849 1969.743408,822.568176 1971.412598,822.278503 
	C1971.424194,821.974609 1971.435669,821.670715 1971.447266,821.366821 
	C1969.654663,820.955139 1967.861938,820.543457 1965.648438,820.035095 
	C1966.182495,817.781555 1967.079102,815.816650 1967.005981,813.888489 
	C1966.890869,810.853027 1965.678467,807.834412 1965.732300,804.822876 
	C1965.822632,799.778320 1964.618164,795.966064 1959.851440,793.284058 
	C1956.117676,791.183289 1952.837769,788.225952 1949.542603,785.429504 
	C1948.668091,784.687256 1948.427246,783.154541 1947.991089,781.950134 
	C1947.387817,780.284363 1946.875122,778.585815 1946.323608,776.901306 
	C1945.820312,777.102173 1945.317139,777.303101 1944.813965,777.504028 
	C1945.131226,778.781799 1945.448486,780.059570 1946.028687,782.396179 
	C1944.126221,781.170166 1943.111938,780.516541 1941.948975,779.545532 
	C1939.213013,775.849060 1936.625854,772.469971 1933.596436,768.979492 
	C1930.141357,768.813721 1927.128662,768.759338 1924.101562,768.255127 
	C1924.055176,759.915039 1924.023071,752.024841 1924.459229,744.177734 
	C1942.615967,744.220825 1960.304810,744.220825 1978.094604,744.220825 
	C1978.538330,746.840942 1978.931030,749.158752 1979.323608,751.476624 
	C1980.468750,750.218018 1980.407349,748.953491 1980.760376,747.817810 
	C1981.183228,746.457642 1981.693848,744.415100 1982.645874,744.095398 
	C1984.077759,743.614685 1986.826172,742.686035 1986.854126,746.162964 
	C1986.854614,746.218567 1988.353149,746.262085 1989.454590,746.180298 
	C1990.161255,745.362366 1990.566040,744.675293 1991.434326,743.907349 
	C1998.256836,743.604370 2004.615967,743.382263 2011.150879,743.307434 
	C2011.890747,743.340881 2012.454712,743.226929 2013.441162,743.189697 
	C2015.572632,743.190491 2017.281738,743.114624 2019.161987,743.188477 
	C2019.891724,743.243835 2020.450195,743.149475 2021.008667,743.055176 
	C2023.615601,743.112122 2026.222534,743.169067 2029.414795,743.577515 
	C2030.028687,745.870361 2030.057251,747.811768 2030.051636,750.168762 
	C2030.040405,751.139526 2030.063232,751.694824 2030.142212,752.679810 
	C2030.720703,756.268555 2029.600464,757.771057 2026.192749,757.486755 
	C2024.956177,757.383545 2023.246704,757.609131 2022.455444,758.393494 
	C2017.953613,762.856140 2013.068237,766.635437 2013.609619,774.544739 
	C2014.458496,786.947021 2013.834717,799.450134 2013.834717,813.303894 
	C2007.926636,812.680542 2002.402222,812.097778 1996.877808,811.514954 
	C1996.917847,811.069458 1996.958008,810.623962 1996.998169,810.178467 
	C1995.530884,811.511475 1994.063721,812.844482 1992.447510,813.761108 
	C1991.609863,812.295471 1990.921021,811.246155 1989.488770,809.064270 
	C1988.330566,811.728333 1987.376831,813.571289 1986.803101,815.525757 
	C1986.682861,815.935181 1988.290405,817.405945 1988.541870,817.278992 
	C1989.989746,816.547729 1991.269409,815.483276 1993.000244,814.680298 
	C1995.738770,816.410828 1997.469604,816.489502 2000.449463,814.954285 
	C2003.348389,813.460876 2007.786377,814.134766 2011.317139,814.910339 
	C2015.143188,815.750671 2013.372559,818.870361 2013.332397,821.703003 
	C2013.203979,830.777161 2013.958008,839.864990 2014.424561,848.944885 
	C2014.481689,850.058594 2014.859375,851.155884 2015.088501,852.260742 
	C2015.486572,852.215393 2015.884644,852.170044 2016.282715,852.124756 
	C2016.282715,842.454834 2016.282715,832.784912 2016.282715,823.269043 
	C2018.698120,822.558838 2020.566650,822.009460 2023.570923,821.126160 
	C2020.204834,820.184448 2018.278687,819.645508 2016.352539,819.106628 
	C2016.969238,817.556824 2017.586060,816.007019 2017.905762,815.203430 
	C2017.253784,812.073364 2016.309326,809.524292 2016.259521,806.957764 
	C2016.040039,795.641357 2016.080444,784.319885 2016.022339,773.000244 
	C2015.983521,765.434753 2021.908569,759.515137 2029.776245,759.816284 
	C2029.903564,778.321655 2029.903564,796.404968 2029.903564,814.488220 
	C2030.549438,814.488403 2031.195435,814.488647 2031.841309,814.488831 
	C2031.841309,807.999512 2031.869873,801.509949 2031.834595,795.020874 
	C2031.785889,786.058655 2033.652710,784.132202 2042.478516,784.616760 
	C2042.346680,792.296448 2041.958374,799.525818 2042.183716,806.736084 
	C2042.340332,811.743103 2048.435547,816.217468 2051.595459,815.212769 
	C2049.427246,812.931152 2045.178345,810.548767 2044.896973,807.765198 
	C2044.108154,799.966064 2044.957642,792.001160 2045.512939,784.117188 
	C2046.570190,784.102966 2047.275024,784.073181 2048.327148,784.085266 
	C2053.741699,789.232971 2058.497070,795.066162 2066.920410,792.350891 
	C2063.313477,795.973511 2063.090332,799.443542 2066.321533,799.636841 
	C2068.854004,799.788391 2071.501221,798.021667 2074.448730,797.159790 
	C2076.536621,797.998535 2078.273193,798.784729 2079.896484,799.875000 
	C2079.819824,801.038879 2079.855957,801.898804 2079.633789,802.985901 
	C2077.927979,803.160156 2076.480225,803.107178 2074.645020,802.970093 
	C2072.060303,802.479126 2071.203369,807.261780 2068.168945,804.078979 
	C2066.680420,805.163879 2065.219971,806.467163 2064.235840,808.063904 
	C2063.991211,808.460815 2065.368652,810.613403 2065.856445,810.562988 
	C2067.274414,810.416626 2068.619385,809.562256 2070.355469,809.024292 
	C2074.115479,812.135376 2076.572266,811.568787 2078.396729,807.232422 
	C2079.183838,809.563293 2079.613525,811.832703 2079.576172,814.038696 
	C2073.883789,813.975403 2068.658203,813.975403 2063.432617,813.975403 
	C2063.401611,814.536499 2063.370605,815.097534 2063.339844,815.658630 
	C2068.883057,815.892395 2074.426270,816.126221 2079.931152,816.804871 
	C2079.924316,832.506348 2079.955811,847.762939 2080.015625,863.393860 
	C2080.441895,866.565491 2080.839844,869.362854 2080.913574,872.361084 
	C2078.575928,877.160400 2083.127197,882.113037 2079.407471,886.628479 
	C2076.256348,886.625488 2073.703125,886.815735 2074.463135,890.900330 
	C2072.353760,891.605591 2070.563721,892.132996 2068.773682,892.660461 
	C2068.619141,892.272339 2068.464844,891.884216 2068.310303,891.496033 
	C2069.633545,890.244568 2070.956787,888.993103 2072.280029,887.741638 
	C2069.022705,887.278015 2066.488037,888.211365 2066.189941,891.486816 
	C2066.095703,892.521790 2068.139404,894.794189 2068.925049,894.673950 
	C2071.419678,894.292297 2073.782227,893.048950 2076.547363,892.203735 
	C2078.060791,892.853638 2079.180908,893.709961 2080.404053,893.897949 
	C2080.937744,893.979980 2081.682129,892.692871 2082.495605,891.730957 
	C2082.660400,886.088928 2082.660400,880.743408 2082.660400,874.994995 
	C2090.817871,874.994995 2097.908936,874.994995 2105.467773,875.028687 
	C2125.697754,875.062378 2145.459961,875.062378 2165.752930,875.062378 
	C2163.833496,877.692993 2162.329102,879.754639 2160.538086,881.908203 
	C2159.760986,882.600159 2159.270752,883.200195 2158.618652,883.942993 
	C2158.502686,884.318298 2158.548828,884.550842 2158.594727,884.783447 
	C2158.822266,884.619263 2159.049561,884.455078 2159.575439,884.208740 
	C2160.327881,883.489319 2160.781982,882.852051 2161.570801,882.128906 
	C2163.486816,880.588318 2165.068848,879.134094 2166.649658,877.678711 
	C2167.622070,876.783447 2168.557861,875.163757 2169.570068,875.115112 
	C2173.867920,874.908508 2178.195801,875.369080 2182.497803,875.201355 
	C2187.007568,875.025513 2191.500000,874.409973 2196.339111,874.041260 
	C2197.784180,875.048096 2198.921387,875.966492 2199.990479,876.958374 
	C2204.680664,881.309692 2209.351318,885.682434 2214.010742,890.027100 
	C2213.992188,890.006775 2213.948242,889.973633 2213.948242,889.973633 
	C2214.290039,890.764404 2214.632080,891.555176 2214.602783,892.709106 
	C2213.822510,894.046326 2213.413574,895.020386 2213.002441,895.997192 
	C2213.000000,896.000000 2212.996094,896.007324 2212.634277,896.092651 
	C2209.871338,897.177673 2207.470215,898.177368 2205.069092,899.177002 
	C2204.640381,898.018677 2204.430908,896.677429 2203.685303,895.783386 
	C2203.340332,895.369629 2201.949707,895.827759 2200.531006,896.308167 
	C2196.126953,896.336121 2192.225098,895.946472 2187.177246,895.442383 
	C2186.937012,890.801819 2186.635742,884.983887 2186.334473,879.165894 
	C2186.153320,879.172119 2185.972168,879.178406 2185.791016,879.184631 
	C2185.791016,884.305115 2185.791016,889.425598 2185.791016,894.780640 
	C2184.029541,895.127808 2182.005615,895.526794 2179.911377,895.939636 
	C2180.850830,898.082153 2181.640381,899.883057 2182.023193,900.755920 
	C2183.171143,900.048035 2184.239990,899.388977 2185.308838,898.729980 
	C2185.204590,902.903076 2185.337158,907.100403 2184.913818,911.240906 
	C2184.623047,914.083618 2183.813721,916.953308 2182.713135,919.592957 
	C2182.285156,920.619446 2180.476318,921.209595 2179.178223,921.651794 
	C2178.770264,921.790833 2177.977539,920.799927 2177.360840,920.326294 
	C2178.433350,919.142944 2179.505615,917.959595 2180.578125,916.776245 
	C2180.532227,916.460876 2180.486328,916.145508 2180.440430,915.830139 
	C2179.275391,916.158630 2177.108887,916.476990 2177.105469,916.817078 
	C2177.080322,919.333618 2177.485840,921.854431 2177.608887,924.864868 
	C2176.673584,926.112976 2175.885742,926.870178 2174.745605,927.501221 
	C2170.973145,923.673157 2170.498535,923.653381 2167.198975,927.353821 
	C2168.789062,927.353821 2170.142822,927.353821 2172.658936,927.353821 
	C2170.112549,929.141296 2168.480469,930.286926 2165.685059,932.249146 
	C2169.427002,932.333557 2172.566162,935.532837 2173.099365,929.877563 
	C2173.127686,929.575928 2174.290527,929.381226 2174.957520,929.489868 
	C2179.344727,935.598022 2180.154297,928.536377 2182.689453,927.712708 
	C2184.283203,928.433105 2185.876709,929.153503 2188.076660,930.148010 
	C2188.076660,932.887634 2188.076660,935.995178 2188.049805,939.567566 
	C2186.523193,940.875183 2185.036133,942.425049 2183.521240,942.452759 
	C2165.931641,942.773926 2148.337646,942.868835 2130.744629,942.998413 
	C2130.301270,943.001709 2129.855469,942.717773 2127.985596,941.015625 
	C2129.320557,940.598938 2130.655762,940.182312 2132.479004,939.613403 
	C2129.007080,938.409546 2127.052979,938.563049 2126.962891,942.447021 
M2148.620361,895.977783 
	C2148.864014,895.764648 2149.065918,895.517578 2149.581787,894.931946 
	C2149.524414,894.671631 2149.467041,894.411316 2149.409912,894.150879 
	C2149.164307,894.322021 2148.918701,894.493164 2148.271240,894.935364 
	C2147.999268,895.132202 2147.805420,895.388245 2147.352539,896.002258 
	C2147.401611,896.244751 2147.450928,896.487244 2147.500244,896.729614 
	C2147.737061,896.563110 2147.974121,896.396606 2148.620361,895.977783 
M2152.745605,891.052185 
	C2152.725342,890.821777 2152.704834,890.591309 2152.684326,890.360962 
	C2152.373779,890.498657 2152.063232,890.636292 2151.437256,891.099243 
	C2151.494873,891.347717 2151.552734,891.596191 2151.610352,891.844666 
	C2151.848633,891.675537 2152.086670,891.506409 2152.745605,891.052185 
M2054.654785,811.117798 
	C2055.331543,816.347778 2056.008057,821.577759 2056.684814,826.807678 
	C2056.994385,826.825439 2057.304199,826.843201 2057.613770,826.860962 
	C2058.240723,821.209045 2058.867920,815.557190 2060.375977,810.018250 
	C2061.101318,809.706116 2062.446777,809.412598 2062.456787,809.078979 
	C2062.568848,805.344849 2062.887695,801.515686 2062.116211,797.934937 
	C2061.945557,797.142700 2057.731934,797.111328 2055.364746,796.940918 
	C2053.786621,796.827393 2052.181641,797.088379 2050.009521,797.215698 
	C2050.009521,800.186951 2049.487793,802.934875 2050.175049,805.337158 
	C2050.722656,807.251465 2052.744873,808.743958 2054.654785,811.117798 
M2140.092285,902.038147 
	C2137.951416,902.720642 2135.848633,903.774597 2133.660889,903.998474 
	C2130.364746,904.335938 2127.007324,904.016357 2123.680420,904.117188 
	C2119.939209,904.230652 2116.202393,904.500854 2112.463867,904.702881 
	C2117.599854,908.002930 2155.050781,907.307373 2158.142090,904.048767 
	C2153.357666,904.048767 2149.291748,904.187012 2145.244629,903.970825 
	C2143.897461,903.898865 2142.598877,902.918213 2141.951904,902.192566 
	C2143.433838,900.925781 2144.916016,899.659058 2146.397949,898.392334 
	C2145.904053,897.893616 2145.410400,897.394958 2144.916748,896.896240 
	C2143.553711,898.532654 2142.190918,900.169006 2140.092285,902.038147 
M2072.556885,906.299072 
	C2075.499023,908.359802 2080.758057,903.720093 2082.441650,909.517639 
	C2083.865479,906.908997 2084.622803,903.565857 2089.453125,905.586731 
	C2091.357910,906.383606 2095.137207,905.419373 2096.647949,903.874023 
	C2099.814209,900.635681 2101.503174,902.228577 2102.664795,903.878540 
	C2105.559814,903.878540 2107.561523,903.878540 2109.869629,903.878540 
	C2109.869629,901.964844 2109.869629,900.130310 2109.869629,899.533691 
	C2107.234863,898.986450 2105.177002,898.846313 2103.351807,898.121216 
	C2099.326904,896.521912 2096.655518,897.487610 2094.726807,901.420349 
	C2094.527344,901.826843 2093.351074,901.754150 2092.518311,901.926514 
	C2092.697021,900.499634 2092.840820,899.352173 2093.130371,897.039734 
	C2090.418701,898.667603 2088.575439,899.774109 2087.397217,900.481262 
	C2085.654785,899.362671 2084.368896,898.536987 2082.934326,897.144470 
	C2081.954834,897.515381 2080.975586,897.886292 2079.367920,897.906067 
	C2075.967529,897.066467 2073.475586,897.221313 2074.037598,901.913269 
	C2074.146729,902.824829 2072.752930,903.916382 2072.144287,904.219482 
	C2071.758057,902.150330 2071.371826,900.081238 2070.985596,898.012146 
	C2070.330566,897.927307 2069.675537,897.842529 2069.020508,897.757690 
	C2067.722900,900.503479 2066.425293,903.249207 2064.795654,906.697266 
	C2067.841309,906.271790 2069.933838,905.979492 2072.556885,906.299072 
M1951.226318,758.796631 
	C1949.624146,759.330627 1947.221558,760.808533 1946.552979,760.241577 
	C1942.891724,757.136841 1940.815186,759.741272 1938.338013,760.507568 
	C1936.740845,760.665283 1935.143555,760.822998 1933.546265,760.980774 
	C1937.779663,763.852600 1940.936401,770.164246 1946.308105,764.556458 
	C1949.841797,769.367249 1954.677368,766.264648 1956.344238,764.629150 
	C1959.940430,761.100891 1961.246826,763.102051 1962.377563,764.539062 
	C1965.236816,764.539062 1967.242676,764.539062 1969.447510,764.539062 
	C1969.447510,763.034302 1969.447510,760.921631 1969.447510,758.031494 
	C1967.895508,759.476807 1966.981445,760.328125 1966.978271,760.330994 
	C1962.669312,759.382751 1959.198853,758.619019 1955.241821,757.748169 
	C1955.333740,763.171448 1953.146851,764.599548 1949.018555,763.926819 
	C1950.092773,762.084351 1951.001587,760.525635 1951.226318,758.796631 
M2142.433350,921.683533 
	C2143.449463,922.376526 2144.465332,923.069519 2146.254639,924.289917 
	C2146.254639,921.250671 2146.254639,918.887756 2146.254639,916.524841 
	C2146.000244,916.496277 2145.745850,916.467712 2145.491455,916.439148 
	C2145.149658,917.424500 2144.807617,918.409912 2144.144287,920.322632 
	C2143.129639,918.357300 2142.399414,916.942932 2141.669434,915.528564 
	C2141.236328,915.837036 2140.803223,916.145569 2140.369873,916.454102 
	C2140.102539,918.428406 2139.834961,920.402649 2139.378174,923.773254 
	C2137.715332,921.149048 2136.788574,919.686829 2135.806396,918.136902 
	C2136.671875,917.915894 2137.326416,917.748718 2137.980957,917.581604 
	C2133.452637,916.399902 2129.002686,916.207336 2124.585449,916.503357 
	C2123.019531,916.608276 2120.385254,917.747375 2120.257568,918.688354 
	C2120.015869,920.471130 2121.232666,922.451782 2121.843506,924.350220 
	C2123.370605,923.595398 2124.922119,922.883911 2126.409912,922.058228 
	C2126.837402,921.820862 2127.082520,921.254028 2127.607910,920.589539 
	C2127.875977,921.997742 2128.056396,922.945435 2128.104004,923.195618 
	C2129.989014,922.110779 2131.967041,920.972534 2134.271729,919.646240 
	C2134.920166,921.810425 2135.362061,923.286133 2136.080811,925.685303 
	C2138.375732,924.362915 2140.218018,923.301514 2142.433350,921.683533 
M1930.635254,755.857788 
	C1932.437744,755.485596 1934.229492,755.045410 1936.045532,754.759399 
	C1938.073975,754.439941 1940.666382,754.918701 1942.000366,753.820129 
	C1943.150635,752.872925 1942.878784,750.198792 1943.352417,747.662537 
	C1939.830933,748.426575 1936.464233,746.146057 1935.803101,751.078796 
	C1935.660034,752.146118 1932.562134,752.817383 1930.825317,753.671082 
	C1930.568481,753.231445 1930.311768,752.791809 1930.055054,752.352173 
	C1931.395264,751.165405 1932.735596,749.978638 1934.075806,748.791870 
	C1933.788208,748.365417 1933.500488,747.939026 1933.212769,747.512573 
	C1931.576416,748.664551 1929.434082,749.515015 1928.498901,751.084717 
	C1927.962402,751.985413 1929.384644,754.053162 1930.635254,755.857788 
M2165.478516,918.723022 
	C2165.442383,917.671875 2165.676025,915.836731 2165.320801,915.714722 
	C2164.196777,915.328369 2162.847900,915.517090 2161.595459,915.611084 
	C2160.685303,915.679321 2159.780762,915.911682 2158.891113,916.139343 
	C2157.565430,916.478760 2155.646729,916.484802 2155.087402,917.349121 
	C2154.333252,918.514526 2154.040527,920.675293 2154.651855,921.821045 
	C2155.204346,922.856262 2157.192627,923.433044 2158.626953,923.627380 
	C2161.854736,924.064819 2163.997803,922.370056 2165.478516,918.723022 
M2010.356934,750.494934 
	C2008.741943,751.452942 2007.126831,752.410950 2005.289673,753.500732 
	C2004.770264,752.499634 2004.230835,751.459656 2003.691284,750.419617 
	C2003.985596,750.832092 2004.279907,751.244629 2004.574219,751.657104 
	C2002.576050,752.714966 2000.578003,753.772827 1998.579834,754.830688 
	C2002.062012,756.211609 2004.746094,756.538147 2007.426147,756.506653 
	C2008.932739,756.489014 2011.369995,756.273743 2011.717529,755.446106 
	C2012.251953,754.173340 2011.349365,752.297302 2010.356934,750.494934 
M2169.185059,886.555420 
	C2168.221191,887.568420 2167.257324,888.581421 2165.746094,890.169617 
	C2170.254639,890.615234 2173.933594,890.978821 2178.997559,891.479309 
	C2179.297363,890.284668 2180.931641,887.182129 2180.141846,886.331848 
	C2176.683105,882.608948 2175.636475,887.711548 2173.717529,888.423706 
	C2170.356445,890.353821 2174.054443,882.751282 2169.185059,886.555420 
M2074.902832,923.797424 
	C2074.309570,930.308105 2081.411133,924.006287 2082.701660,928.182251 
	C2082.568604,925.924866 2082.435791,923.667480 2082.302979,921.410095 
	C2079.934082,922.014832 2076.683838,918.954285 2074.902832,923.797424 
M2071.745850,927.285645 
	C2075.346191,923.872620 2071.182129,922.657593 2069.011475,920.286926 
	C2068.223389,922.228088 2067.435303,924.169250 2066.647217,926.110413 
	C2068.135010,926.646973 2069.622559,927.183594 2071.745850,927.285645 
M2108.444092,936.943665 
	C2110.400146,934.735962 2112.356445,932.528259 2114.312500,930.320557 
	C2114.574951,930.626221 2114.837402,930.931885 2115.099854,931.237549 
	C2111.470947,934.605347 2107.842041,937.973083 2104.213135,941.340881 
	C2104.173096,941.264709 2104.132812,941.188538 2104.092773,941.112305 
	C2105.374268,939.890686 2106.656006,938.669006 2108.444092,936.943665 
M2115.348877,926.804016 
	C2115.520996,927.136047 2115.693115,927.468079 2115.865234,927.800171 
	C2116.146729,927.530334 2116.427979,927.260498 2116.709229,926.990601 
	C2116.391602,926.793823 2116.074219,926.597107 2115.348877,926.804016 
M2154.441650,889.545593 
	C2154.531738,889.210999 2154.621826,888.876465 2154.711914,888.541931 
	C2154.484131,888.767822 2154.256592,888.993652 2154.441650,889.545593 
M2156.507324,886.791382 
	C2156.599609,886.714783 2156.756104,886.645325 2156.765625,886.559570 
	C2156.774658,886.476196 2156.633057,886.376160 2156.557617,886.283386 
	C2156.465088,886.359680 2156.372559,886.435974 2156.507324,886.791382 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1976.437988,873.404907 
	C1974.602417,873.470886 1973.221924,873.425293 1971.431641,873.447998 
	C1968.647949,873.410706 1966.274292,873.305054 1963.483154,873.032837 
	C1962.645264,871.534729 1962.224731,870.203064 1962.289062,868.367065 
	C1964.391724,864.340820 1966.009521,860.818970 1968.017700,856.447205 
	C1977.282349,856.447205 1987.714966,856.385437 1998.146362,856.471985 
	C2003.756592,856.518555 2009.364502,856.819458 2014.973511,857.005554 
	C2014.089966,859.118530 2013.206421,861.231445 2012.471802,862.988159 
	C2012.836792,864.925232 2014.290039,867.705688 2013.448120,869.071289 
	C2010.653931,873.603821 2004.914673,873.859497 2001.673584,871.045044 
	C1997.349487,871.786682 1993.832031,872.644897 1990.268066,872.936584 
	C1985.829834,873.299805 1981.353271,873.195679 1976.437988,873.404907 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M2029.042969,856.016418 
	C2029.042969,856.016418 2029.264893,856.016907 2029.573486,856.262451 
	C2030.266602,857.009521 2030.651123,857.510986 2031.035767,858.012451 
	C2031.035767,862.755493 2031.035767,867.498535 2031.035767,872.543701 
	C2025.591675,872.543701 2020.727783,872.543701 2015.213501,872.543701 
	C2017.111694,869.429626 2014.939575,864.992554 2019.941528,863.607056 
	C2020.590576,863.427307 2020.560791,860.796387 2019.873169,858.937927 
	C2019.241699,859.938293 2018.610352,860.938660 2017.104126,863.325012 
	C2016.688965,860.592224 2016.473999,859.176392 2016.410400,857.082520 
	C2018.411255,856.121643 2020.260620,855.838867 2022.534424,855.640869 
	C2024.986816,855.822510 2027.014893,855.919495 2029.042969,856.016418 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2022.110107,855.556152 
	C2020.260620,855.838867 2018.411255,856.121643 2016.299561,856.699341 
	C2015.860107,856.996399 2015.682861,856.998535 2015.239502,857.003052 
	C2009.364502,856.819458 2003.756592,856.518555 1998.146362,856.471985 
	C1987.714966,856.385437 1977.282349,856.447205 1968.017700,856.447205 
	C1966.009521,860.818970 1964.391724,864.340820 1962.447021,868.017029 
	C1962.071289,867.853577 1962.022339,867.535767 1962.029785,866.434326 
	C1962.872681,862.893250 1963.658936,860.135681 1964.552612,857.001587 
	C1962.064697,856.848328 1960.911011,856.867249 1959.791382,856.681519 
	C1958.793579,856.516052 1957.833374,856.124207 1955.445190,855.410522 
	C1959.542236,854.269043 1962.188110,853.531860 1964.885254,852.770630 
	C1964.936768,852.746521 1965.150024,852.796448 1965.150024,852.796448 
	C1966.992065,853.230835 1968.833008,854.036377 1970.676270,854.041809 
	C1987.491577,854.091614 2004.307373,854.002136 2021.594482,854.283752 
	C2022.114136,854.926514 2022.128906,855.240540 2022.110107,855.556152 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2031.312256,858.316956 
	C2030.651123,857.510986 2030.266602,857.009521 2029.684082,856.252563 
	C2029.667480,855.949768 2029.849121,855.902527 2030.774780,855.920044 
	C2033.368408,856.024292 2035.217773,856.063721 2037.078491,856.560181 
	C2037.189087,861.117737 2035.760498,866.533325 2037.830200,868.938293 
	C2039.784912,871.209656 2045.416382,870.325012 2049.434570,870.794922 
	C2049.719482,870.828247 2050.036865,870.583008 2050.623535,870.358459 
	C2050.842773,866.914673 2051.070557,863.343262 2051.647461,859.765686 
	C2052.688965,859.828491 2053.381104,859.897400 2054.073486,859.966309 
	C2054.073486,859.966309 2054.006104,859.989563 2053.971680,860.231079 
	C2053.946777,860.657349 2053.956543,860.842163 2053.647949,861.174194 
	C2053.229492,865.189514 2053.128906,869.057495 2052.905762,873.093018 
	C2052.869873,873.755737 2052.957031,874.251038 2053.033691,875.229858 
	C2052.935303,880.213501 2052.847656,884.713623 2052.579590,889.371826 
	C2051.966553,889.730896 2051.533447,889.931824 2050.847656,889.835022 
	C2048.510254,889.421082 2046.425659,889.304810 2044.341187,889.188538 
	C2044.295532,888.672607 2044.250000,888.156677 2044.641357,887.317871 
	C2046.962769,886.906372 2048.847412,886.817749 2050.629883,886.733948 
	C2050.629883,881.567322 2050.629883,877.036011 2050.629883,871.709656 
	C2045.670166,872.182983 2041.153809,872.614014 2036.637451,873.045044 
	C2035.891724,872.128113 2035.145996,871.211182 2034.408813,870.304626 
	C2034.082397,870.698181 2033.575684,871.309204 2032.326782,872.815186 
	C2032.044922,867.395081 2031.816895,863.008240 2031.312256,858.316956 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2036.735107,873.390747 
	C2041.153809,872.614014 2045.670166,872.182983 2050.629883,871.709656 
	C2050.629883,877.036011 2050.629883,881.567322 2050.629883,886.733948 
	C2048.847412,886.817749 2046.962769,886.906372 2044.650269,886.929443 
	C2045.163696,880.571594 2038.838867,878.369568 2036.735107,873.390747 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2044.230957,889.628723 
	C2046.425659,889.304810 2048.510254,889.421082 2050.801270,890.283142 
	C2051.072998,894.639709 2051.138672,898.250427 2051.182373,902.245972 
	C2049.375000,906.463196 2046.581177,904.096252 2044.120117,903.775696 
	C2044.120361,899.206787 2044.120605,894.637817 2044.230957,889.628723 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2046.996948,924.278809 
	C2052.700439,923.979553 2058.387207,923.979553 2064.073975,923.979553 
	C2064.086914,924.586914 2064.100098,925.194336 2064.113037,925.801697 
	C2058.707764,925.801697 2053.302490,925.801697 2047.425537,925.788269 
	C2046.962646,925.375916 2046.971436,924.977051 2046.996948,924.278809 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2044.092163,904.211670 
	C2046.581177,904.096252 2049.375000,906.463196 2051.522705,902.321655 
	C2052.323975,902.164673 2052.763428,902.316895 2053.202637,902.469116 
	C2053.126465,904.692810 2052.411621,906.074951 2049.746338,905.734619 
	C2048.190918,905.536011 2046.568970,905.859802 2044.596680,906.168396 
	C2044.165771,905.808044 2044.114990,905.227844 2044.092163,904.211670 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2044.200195,921.922607 
	C2045.109863,922.142822 2045.973633,922.750427 2046.928711,923.672119 
	C2046.064819,923.427429 2045.109497,922.868713 2044.200195,921.922607 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2013.729614,872.600952 
	C2013.595581,872.697998 2013.491333,872.371765 2013.491333,872.371765 
	C2013.491333,872.371765 2013.863647,872.503906 2013.729614,872.600952 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1712.012085,548.883362 
	C1711.595215,549.557861 1711.178345,550.232300 1710.657104,551.075745 
	C1709.064087,548.593567 1707.527954,547.198059 1704.618530,550.065002 
	C1703.118164,551.543518 1699.959106,552.725708 1698.124390,552.164062 
	C1695.111572,551.241699 1692.226074,552.754272 1689.954102,552.186157 
	C1684.943726,550.933228 1680.386475,552.696228 1675.639526,552.612122 
	C1674.877563,552.598694 1674.119873,552.343689 1672.607544,552.058350 
	C1674.688843,550.443054 1676.166016,549.296631 1678.295410,547.644043 
	C1676.465820,547.142090 1675.287476,546.818787 1673.802490,546.411377 
	C1678.422607,543.422913 1684.230591,544.126282 1686.327515,547.687012 
	C1688.892822,545.652588 1691.223877,543.172791 1694.918213,546.250183 
	C1695.649170,546.859009 1698.335571,545.120239 1700.596191,545.318054 
	C1699.369385,546.607849 1698.142700,547.897644 1696.915894,549.187439 
	C1697.208252,549.592407 1697.500488,549.997375 1697.792725,550.402344 
	C1699.298096,549.473755 1701.868164,548.772278 1702.123047,547.577026 
	C1703.180420,542.619324 1706.342773,544.031311 1709.694824,545.129395 
	C1710.685669,546.746826 1711.348877,547.815063 1712.012085,548.883362 
M1679.759888,548.825806 
	C1680.805298,549.314087 1681.850830,549.802429 1682.896240,550.290710 
	C1683.046021,549.939453 1683.195801,549.588196 1683.345581,549.236938 
	C1682.416260,549.077148 1681.486816,548.917358 1679.759888,548.825806 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1688.937744,573.014709 
	C1688.780640,571.605164 1688.623535,570.195679 1688.456909,568.701721 
	C1684.238647,567.114075 1683.426636,564.029602 1685.251709,555.306274 
	C1689.038696,555.306274 1692.903687,555.306274 1697.533447,555.306274 
	C1697.533447,558.738464 1697.747559,562.012390 1697.407959,565.227783 
	C1697.301147,566.239319 1695.708252,567.093872 1694.170044,567.712036 
	C1691.156738,566.527832 1689.819092,567.075134 1689.793701,569.902344 
	C1689.784424,570.942261 1689.238770,571.977417 1688.937744,573.014709 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1621.994141,538.474976 
	C1622.412354,537.368469 1622.871094,536.726318 1623.652466,536.067139 
	C1623.975098,553.847168 1623.975098,571.644348 1623.975098,589.441467 
	C1624.381958,589.463989 1624.788818,589.486572 1625.195679,589.509094 
	C1625.862793,587.353210 1625.962646,583.937378 1627.324585,583.324097 
	C1629.615356,582.292480 1632.708008,583.041504 1635.463379,583.041504 
	C1635.517822,583.474182 1635.572266,583.906921 1635.626709,584.339600 
	C1633.393555,584.641541 1631.160400,584.943481 1628.062134,585.362427 
	C1628.062134,587.814636 1628.062134,590.380493 1628.028076,593.387878 
	C1627.993896,593.829346 1628.067139,594.083008 1627.693604,594.119446 
	C1626.036499,594.450134 1624.753174,594.744446 1623.320068,595.118958 
	C1622.929688,595.237732 1622.718384,595.175598 1622.124756,594.909912 
	C1621.452515,594.696045 1621.192261,594.585083 1620.931885,594.474121 
	C1620.931885,594.474121 1620.797485,594.231018 1621.289795,594.026550 
	C1622.171265,592.661987 1622.885010,591.505005 1622.896484,590.341187 
	C1622.966553,583.217529 1622.877563,576.092224 1622.841553,568.967468 
	C1622.572632,558.958069 1622.303711,548.948730 1621.994141,538.474976 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1688.148071,588.879089 
	C1689.893799,586.117798 1692.692627,583.316284 1687.326782,581.169983 
	C1686.777222,580.950317 1686.942993,578.942566 1687.484985,577.561951 
	C1687.990845,578.279419 1688.496704,578.996826 1689.411377,580.294067 
	C1689.655762,579.058899 1689.789795,578.381836 1690.275879,577.380371 
	C1691.050537,577.022705 1691.472900,576.989441 1691.895386,576.956177 
	C1692.912720,578.250000 1693.930054,579.543762 1694.756836,580.595215 
	C1694.077515,582.278259 1691.970825,585.960083 1692.518921,586.410339 
	C1694.729858,588.226868 1697.759888,589.046265 1700.702515,589.039917 
	C1696.401733,590.033936 1692.101074,591.027954 1687.389893,591.989929 
	C1686.979492,591.957886 1686.993652,592.020020 1687.025635,592.023926 
	C1686.955688,591.602417 1686.853760,591.176941 1686.943115,590.356201 
	C1687.472046,589.600281 1687.810059,589.239685 1688.148071,588.879089 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1678.920776,538.699219 
	C1679.836792,537.371399 1680.752686,536.043579 1682.142456,534.028687 
	C1682.041260,534.044678 1682.884644,533.679932 1683.609619,533.842773 
	C1685.375122,534.239136 1687.085815,534.880127 1688.818359,535.422607 
	C1687.977539,537.114197 1687.527832,539.282410 1686.195923,540.375854 
	C1684.555420,541.722656 1682.045166,543.062439 1680.632446,539.639709 
	C1680.236572,539.817993 1679.840576,539.996338 1679.190796,540.005676 
	C1678.931519,539.457581 1678.926147,539.078369 1678.920776,538.699219 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1717.380127,588.465759 
	C1715.814819,589.686279 1714.458252,590.769897 1712.911865,591.328735 
	C1712.555176,591.457703 1710.846191,589.635681 1710.989380,588.980103 
	C1711.275635,587.669678 1712.142700,586.050842 1713.253418,585.443115 
	C1715.838501,584.028870 1717.235596,585.419006 1717.380127,588.465759 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1649.088135,585.739746 
	C1649.457153,583.767029 1649.937744,581.960022 1650.828003,580.384460 
	C1651.007324,580.066956 1653.253296,580.286377 1653.634644,580.862183 
	C1654.199707,581.715149 1654.446167,583.770081 1653.950439,584.141418 
	C1652.671753,585.099487 1650.911621,585.414795 1649.088135,585.739746 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1678.500610,538.801025 
	C1678.926147,539.078369 1678.931519,539.457581 1678.937744,540.119385 
	C1677.144653,540.502747 1675.322876,540.801086 1673.578247,540.553833 
	C1673.182739,540.497742 1672.484253,538.092896 1672.912598,537.553833 
	C1674.198120,535.936462 1675.971680,534.707031 1678.237061,534.236633 
	C1677.404175,535.745972 1676.571411,537.255310 1675.738647,538.764648 
	C1676.519287,538.810730 1677.299805,538.856750 1678.500610,538.801025 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1712.255371,549.088196 
	C1711.348877,547.815063 1710.685669,546.746826 1710.011230,545.338867 
	C1712.214844,545.310913 1714.429688,545.622620 1717.418701,546.043335 
	C1717.418701,546.472839 1717.418701,548.401367 1717.418701,550.138855 
	C1715.645264,549.833984 1714.072021,549.563477 1712.255371,549.088196 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1731.029175,576.557800 
	C1730.597900,576.780029 1730.139771,576.610962 1729.459229,576.338989 
	C1729.236816,573.662781 1729.236816,571.089478 1729.236816,568.559631 
	C1731.052490,569.918823 1732.377686,570.910828 1733.702759,571.902832 
	C1734.479858,570.742493 1735.256958,569.582214 1736.628296,567.534546 
	C1737.156494,571.050354 1737.757568,573.394348 1733.583374,574.049011 
	C1732.635620,574.197632 1731.858276,575.431946 1731.029175,576.557800 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1691.971191,576.575195 
	C1691.472900,576.989441 1691.050537,577.022705 1690.311035,577.029541 
	C1689.573120,575.911255 1689.151978,574.819397 1688.834229,573.371094 
	C1689.238770,571.977417 1689.784424,570.942261 1689.793701,569.902344 
	C1689.819092,567.075134 1691.156738,566.527832 1693.775146,567.705872 
	C1693.352905,570.736633 1692.699951,573.465393 1691.971191,576.575195 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1687.990356,588.482361 
	C1687.810059,589.239685 1687.472046,589.600281 1686.942505,590.019043 
	C1686.341064,589.319336 1685.931152,588.561340 1685.521240,587.803345 
	C1686.291748,587.897522 1687.062134,587.991577 1687.990356,588.482361 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1735.479248,565.541382 
	C1735.459229,565.502197 1735.499268,565.580627 1735.479248,565.541382 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1669.755981,623.078247 
	C1669.476196,623.195312 1669.184692,623.249573 1668.497070,622.835449 
	C1664.702271,619.670837 1661.596191,615.194580 1657.815552,614.524597 
	C1650.811890,613.283447 1643.413818,614.268005 1635.882446,614.209900 
	C1635.023804,614.560120 1634.462769,615.026062 1633.543945,615.465942 
	C1631.490723,615.331848 1629.795410,615.223816 1627.789429,615.024902 
	C1626.330811,614.893188 1625.182861,614.852478 1623.637939,614.746643 
	C1622.173706,614.730530 1621.106445,614.779419 1619.776123,614.667358 
	C1617.791138,614.470642 1616.069214,614.434814 1614.299316,614.189209 
	C1614.160400,613.702942 1614.109985,613.419312 1614.508545,613.103577 
	C1616.700806,612.417542 1618.484497,611.756409 1620.404419,611.361206 
	C1621.749756,611.373474 1622.958740,611.119690 1624.167847,610.865845 
	C1624.646240,610.754578 1625.124756,610.643250 1626.176514,610.391296 
	C1627.077271,610.263062 1627.403076,610.245789 1627.727295,610.198914 
	C1628.144409,613.348633 1630.509033,612.883484 1632.650635,612.866211 
	C1654.095337,612.692688 1675.539917,612.525085 1697.430908,612.457520 
	C1697.862915,612.860229 1697.887817,613.157776 1697.519775,613.743164 
	C1692.615601,614.056335 1688.143677,614.078430 1683.499878,614.155518 
	C1683.274414,614.547180 1683.220825,614.883972 1683.192627,615.346680 
	C1683.217896,615.472595 1683.249634,615.727478 1683.013794,615.975952 
	C1682.620117,616.389587 1682.462402,616.554688 1681.999756,616.457764 
	C1676.568970,612.519348 1671.089478,614.693420 1665.527710,614.531738 
	C1667.146362,617.803467 1668.451172,620.440857 1669.755981,623.078247 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1624.198975,610.424561 
	C1622.958740,611.119690 1621.749756,611.373474 1620.079224,611.110413 
	C1619.813110,608.032898 1620.008545,605.472229 1620.371582,602.640259 
	C1620.382690,600.961914 1620.225952,599.554688 1620.012085,597.945557 
	C1619.896118,597.473206 1619.863037,597.199768 1620.229980,596.733154 
	C1621.248535,596.033142 1621.892578,595.523071 1622.536621,595.012939 
	C1622.718384,595.175598 1622.929688,595.237732 1623.258789,595.466797 
	C1623.815063,596.226013 1624.283081,596.717590 1624.751099,597.209167 
	C1624.751099,597.209167 1624.891846,597.632324 1624.943115,597.849487 
	C1624.739746,602.038757 1624.484863,606.010986 1624.198975,610.424561 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1638.035278,602.902527 
	C1638.096802,601.576111 1638.167236,600.624573 1638.308960,598.712280 
	C1639.169922,600.758850 1639.678589,601.968140 1639.656372,601.915466 
	C1642.200317,602.532349 1643.656738,603.157104 1645.110352,603.150574 
	C1646.238403,603.145508 1647.685913,602.649170 1648.422485,601.848083 
	C1650.038452,600.090759 1651.688843,597.635681 1653.792847,600.332581 
	C1654.627197,601.402344 1653.192139,604.242554 1653.049927,604.963013 
	C1647.034302,604.963013 1642.618286,604.963013 1638.044189,604.963013 
	C1638.044189,604.588196 1638.044189,603.932800 1638.035278,602.902527 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1694.606445,602.351990 
	C1693.279053,602.101685 1692.199829,602.101685 1691.120728,602.101685 
	C1691.027954,601.720032 1690.935181,601.338379 1690.842407,600.956726 
	C1692.289062,600.620544 1694.881470,599.766479 1695.000732,600.030334 
	C1695.926147,602.077881 1696.374634,604.340942 1697.118774,607.082703 
	C1694.710571,607.409729 1692.952271,607.648499 1689.843872,608.070618 
	C1692.200195,605.499084 1693.527344,604.050720 1694.606445,602.351990 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1704.270020,602.233948 
	C1704.242188,602.836548 1704.177124,603.087158 1704.079590,603.724976 
	C1702.390259,604.798950 1700.733398,605.485718 1699.076416,606.172485 
	C1699.090942,604.599731 1698.512207,602.627380 1699.245605,601.539001 
	C1700.690430,599.394470 1702.745117,599.150818 1704.270020,602.233948 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1627.805176,609.752441 
	C1627.403076,610.245789 1627.077271,610.263062 1626.506836,610.224854 
	C1625.708374,601.497559 1627.456543,600.103149 1636.885010,603.435181 
	C1633.209106,603.809143 1630.908813,604.043091 1628.292725,604.309204 
	C1628.146851,606.089355 1628.014893,607.697693 1627.805176,609.752441 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1734.151489,610.917358 
	C1732.721313,611.404419 1731.110107,611.617004 1729.148315,611.775757 
	C1730.521973,611.362244 1732.246216,611.002563 1734.151489,610.917358 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1729.080322,606.241577 
	C1728.996338,605.025208 1728.996338,604.064575 1728.996338,602.183960 
	C1729.917480,603.866882 1730.397461,604.743530 1730.913818,605.963379 
	C1730.355103,606.370056 1729.759766,606.433716 1729.080322,606.241577 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1736.227173,609.247314 
	C1736.218140,608.422424 1736.386963,607.855408 1736.555786,607.288391 
	C1736.913330,607.487915 1737.270874,607.687439 1737.628418,607.887024 
	C1737.220581,608.426453 1736.812866,608.965820 1736.227173,609.247314 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1779.875732,590.718140 
	C1779.607178,590.938965 1779.289795,590.944397 1779.134644,590.978210 
	C1775.315063,590.142822 1771.650513,589.273621 1767.579590,588.455933 
	C1767.173218,588.507446 1767.144775,588.474487 1767.144775,588.474487 
	C1769.104004,587.107910 1771.063110,585.741272 1773.261963,584.207458 
	C1773.722412,585.087524 1774.186401,585.974487 1774.679077,586.916260 
	C1780.049072,582.439880 1781.180542,582.523376 1784.654663,587.665894 
	C1783.146606,588.612610 1781.645508,589.554993 1779.875732,590.718140 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1767.356201,588.106262 
	C1767.144775,588.474487 1767.173218,588.507446 1767.160400,588.484497 
	C1768.132080,594.499023 1764.172852,590.842712 1761.259888,591.790405 
	C1762.850342,589.150269 1766.294434,587.229858 1761.889160,584.593445 
	C1763.441772,584.391907 1765.110474,583.779663 1766.495483,584.171204 
	C1767.137573,584.352783 1767.227417,586.488281 1767.356201,588.106262 
M1765.392822,586.272705 
	C1765.274414,586.357971 1765.156128,586.443298 1765.037720,586.528503 
	C1765.123413,586.646729 1765.208984,586.764893 1765.294556,586.883118 
	C1765.412964,586.797363 1765.531250,586.711609 1765.392822,586.272705 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1863.984741,601.991638 
	C1864.007812,607.270264 1864.243896,612.562256 1863.912354,617.818542 
	C1863.823364,619.228271 1862.276245,620.979736 1860.927490,621.793091 
	C1857.211792,624.033875 1853.246582,625.860962 1848.951660,627.644531 
	C1848.389893,625.518921 1848.256592,623.600403 1848.087158,621.161560 
	C1844.259888,621.161560 1840.597290,621.161560 1836.608643,621.032837 
	C1834.610229,619.754089 1832.937988,618.604126 1831.163086,617.122742 
	C1831.231689,615.605469 1831.403076,614.419617 1831.641968,612.767029 
	C1827.224121,615.483215 1825.544067,612.772949 1824.377075,609.412720 
	C1827.385620,604.347656 1829.582153,599.453857 1825.137329,594.347534 
	C1837.446045,594.212952 1849.754883,594.078369 1862.529297,594.445068 
	C1863.324951,597.294800 1863.654907,599.643250 1863.984741,601.991638 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1750.089111,605.902588 
	C1750.109253,605.443604 1750.089722,605.392578 1750.092285,605.366455 
	C1750.504639,603.968994 1750.914307,602.597595 1751.781494,601.317383 
	C1752.576538,601.607178 1752.913940,601.805786 1753.251465,602.004456 
	C1753.706299,603.386902 1754.161255,604.769409 1754.711792,606.442505 
	C1755.880127,604.371399 1756.521851,603.233826 1757.854492,602.246399 
	C1761.781616,604.344727 1765.017700,606.293030 1768.590820,608.422852 
	C1771.076172,608.639160 1773.284668,608.981995 1775.347656,608.579468 
	C1776.386841,608.376587 1777.870728,606.861938 1777.927246,605.870667 
	C1778.112793,602.610962 1777.737671,599.319397 1777.577881,596.040039 
	C1783.740845,595.711853 1789.901245,595.260925 1796.068481,595.128784 
	C1797.970459,595.088013 1799.891235,595.916382 1801.615234,596.934753 
	C1801.619019,601.886292 1801.811035,606.249939 1802.002930,610.613586 
	C1797.209106,610.165955 1792.420532,609.489868 1787.618774,609.376892 
	C1785.536011,609.327820 1783.438843,610.882996 1781.325806,610.944946 
	C1772.720093,611.197083 1764.097412,610.951965 1755.498291,611.308960 
	C1751.595459,611.470947 1749.896729,610.193298 1750.089111,605.902588 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1802.000000,610.729919 
	C1801.811035,606.249939 1801.619019,601.886292 1801.713867,597.261353 
	C1804.869629,596.185059 1807.694580,594.967163 1810.617432,594.648499 
	C1815.147705,594.154480 1819.744629,594.273804 1824.725342,594.240356 
	C1829.582153,599.453857 1827.385620,604.347656 1823.969971,609.569641 
	C1821.704956,610.173340 1820.137817,610.600708 1818.556763,610.660217 
	C1813.033447,610.868225 1807.505493,610.949097 1801.979248,611.078308 
	C1801.979248,611.078308 1801.996948,610.846252 1802.000000,610.729919 
M1815.075806,606.379639 
	C1815.126831,606.872498 1815.177734,607.365295 1815.225830,607.830505 
	C1815.343994,607.914124 1815.427734,607.973389 1815.511353,608.032593 
	C1816.219238,606.678772 1816.927246,605.324890 1817.635132,603.971069 
	C1817.460205,603.828247 1817.285400,603.685364 1817.110474,603.542542 
	C1816.438843,604.277039 1815.767212,605.011536 1815.075806,606.379639 
M1818.620117,608.544739 
	C1818.620117,608.544739 1818.427979,608.436829 1818.427979,608.436829 
	C1818.427979,608.436829 1818.458008,608.663208 1818.620117,608.544739 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1777.220825,595.942383 
	C1777.737671,599.319397 1778.112793,602.610962 1777.927246,605.870667 
	C1777.870728,606.861938 1776.386841,608.376587 1775.347656,608.579468 
	C1773.284668,608.981995 1771.076172,608.639160 1768.592773,608.023926 
	C1767.818115,604.780334 1767.378662,602.117310 1766.757446,599.333740 
	C1766.445312,598.866089 1766.554688,598.630554 1767.194824,598.451294 
	C1767.964600,597.686829 1768.443237,596.977722 1768.921997,596.268616 
	C1771.569214,596.127319 1774.216431,595.986084 1777.220825,595.942383 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1766.903687,598.506714 
	C1766.554688,598.630554 1766.445312,598.866089 1766.398315,599.523438 
	C1765.941528,600.871399 1765.662109,601.909302 1765.499634,602.512451 
	C1762.787476,601.776367 1760.412354,601.131775 1758.037354,600.487183 
	C1757.924438,599.294739 1757.811523,598.102295 1757.321777,596.903442 
	C1757.275391,596.244507 1757.600830,595.031555 1757.936646,595.028748 
	C1760.491333,595.006897 1763.047852,595.221619 1765.738037,596.096680 
	C1766.215942,597.389404 1766.559814,597.948059 1766.903687,598.506714 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1864.398071,602.051208 
	C1863.654907,599.643250 1863.324951,597.294800 1862.991211,594.526489 
	C1864.037720,594.080566 1865.087891,594.054626 1866.530029,594.062622 
	C1866.921997,602.668396 1866.921997,611.240112 1866.921997,619.811829 
	C1866.610962,619.821960 1866.299805,619.832092 1865.988770,619.842163 
	C1865.988770,615.417786 1866.086304,610.989990 1865.934937,606.570740 
	C1865.883667,605.073059 1865.203857,603.596802 1864.398071,602.051208 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1767.194824,598.451294 
	C1766.559814,597.948059 1766.215942,597.389404 1765.969971,596.450195 
	C1766.785400,596.083618 1767.502930,596.097534 1768.571167,596.190063 
	C1768.443237,596.977722 1767.964600,597.686829 1767.194824,598.451294 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1798.542480,588.864746 
	C1799.407104,589.932983 1800.006958,590.811340 1800.606689,591.689636 
	C1800.139893,591.875854 1799.673218,592.062073 1799.206421,592.248352 
	C1798.896851,591.057129 1798.587280,589.865967 1798.542480,588.864746 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1814.290649,589.827271 
	C1813.542969,589.957642 1813.006104,589.868713 1812.469360,589.779785 
	C1812.517456,589.556519 1812.565430,589.333252 1812.613525,589.109985 
	C1813.242798,589.276001 1813.872192,589.441956 1814.290649,589.827271 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1836.221680,633.716980 
	C1835.832031,634.837769 1835.538208,636.005066 1835.022827,637.064697 
	C1834.597900,637.938477 1833.910767,638.684753 1832.856201,639.927002 
	C1831.300293,640.506287 1830.228149,640.646179 1829.156006,640.786072 
	C1829.066162,640.441650 1828.976440,640.097168 1828.886597,639.752747 
	C1830.211060,638.727173 1831.535400,637.701599 1832.859863,636.676086 
	C1832.638306,636.281494 1832.416870,635.886902 1832.195312,635.492310 
	C1830.243652,636.483704 1828.292114,637.475037 1826.231567,638.521729 
	C1825.920410,637.700317 1825.542358,636.701965 1825.250000,635.930298 
	C1823.446167,636.480286 1821.886597,637.337463 1820.321045,637.348633 
	C1817.594604,637.368042 1814.864502,636.865784 1812.135864,636.582214 
	C1809.707153,636.843323 1807.494751,636.839111 1805.283325,636.881958 
	C1805.248779,636.882690 1805.225342,637.463135 1805.187012,637.878113 
	C1806.436035,638.122009 1807.671875,638.363281 1809.150879,638.652039 
	C1807.475952,640.350159 1806.168945,641.675110 1804.862061,643.000000 
	C1803.181763,643.402222 1801.501587,643.804565 1798.906006,644.426025 
	C1798.906006,637.283508 1798.906006,630.956177 1798.906006,624.628906 
	C1798.379883,624.613220 1797.853760,624.597595 1797.327637,624.581909 
	C1797.327637,630.797668 1797.327637,637.013367 1797.327637,643.841614 
	C1781.695068,644.225708 1766.835693,644.590820 1751.511597,644.954285 
	C1750.932617,635.640747 1750.711304,626.327332 1750.840942,617.018738 
	C1750.856934,615.867432 1752.718994,613.780029 1753.765625,613.754272 
	C1763.894165,613.505615 1774.031006,613.605103 1784.331421,613.605103 
	C1784.539551,616.039429 1784.772949,618.768005 1785.115356,622.772888 
	C1779.842407,622.163940 1775.133789,621.620178 1769.368408,620.954407 
	C1769.314453,621.005737 1768.266113,622.002991 1767.238525,622.980408 
	C1761.540894,618.391418 1761.351196,618.479187 1758.455811,628.191406 
	C1767.848145,626.477661 1776.567505,624.886658 1785.864258,623.190369 
	C1785.928955,622.221252 1785.906738,620.783447 1786.140869,619.388672 
	C1786.758545,615.708313 1788.233643,612.895142 1792.679810,613.004822 
	C1795.503662,613.074524 1798.334229,612.877563 1801.964111,612.787720 
	C1808.383301,612.635376 1814.021118,612.159180 1819.607666,612.506592 
	C1822.157349,612.665161 1824.605835,614.448853 1827.100342,615.495483 
	C1826.922852,615.964844 1826.745483,616.434204 1826.568115,616.903503 
	C1825.442871,617.012573 1824.317627,617.121704 1823.192383,617.230774 
	C1823.203979,617.835632 1823.215576,618.440552 1823.227051,619.045410 
	C1825.470703,619.045410 1827.714355,619.045410 1830.068115,619.381409 
	C1832.870728,624.091248 1836.679199,627.983337 1836.221680,633.716980 
M1812.959351,629.575928 
	C1815.236328,630.123230 1817.513184,630.670471 1819.490112,631.145630 
	C1819.337402,628.971619 1819.204346,627.077271 1819.032471,624.628967 
	C1816.755005,624.743652 1814.560669,624.854126 1812.742188,624.945679 
	C1812.543945,626.707581 1812.388672,628.088745 1811.548584,629.013611 
	C1809.829956,629.351257 1808.111328,629.688843 1806.392700,630.026489 
	C1806.240723,629.620361 1806.088623,629.214172 1805.936646,628.807983 
	C1807.325684,627.711670 1808.714600,626.615295 1810.103638,625.518982 
	C1809.809448,625.093201 1809.515259,624.667358 1809.221069,624.241577 
	C1807.518188,625.511230 1805.511230,626.545349 1804.258545,628.163452 
	C1803.738159,628.835876 1804.799438,631.903625 1805.191284,631.913513 
	C1807.144897,631.963074 1809.138428,631.444275 1811.062988,630.922607 
	C1811.549683,630.790771 1811.834473,629.914062 1812.959351,629.575928 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1739.953857,641.887024 
	C1739.529907,641.948730 1739.105835,642.010376 1738.369263,642.148438 
	C1735.977539,641.702454 1733.898438,641.180115 1732.774536,640.897705 
	C1731.657715,642.970093 1731.034302,644.127136 1730.077026,645.403198 
	C1729.175903,644.441223 1728.608521,643.360107 1727.751099,641.726196 
	C1726.255859,642.593262 1724.541382,643.587524 1723.296387,644.309509 
	C1721.338379,643.460327 1719.616333,642.713440 1717.114502,642.040955 
	C1715.799683,642.156738 1715.264526,642.198120 1714.729248,642.239502 
	C1714.478271,642.944336 1714.227295,643.649109 1713.738037,644.651611 
	C1713.248169,644.892273 1712.997803,644.829529 1712.748779,644.760986 
	C1711.575684,640.782898 1709.375122,641.199341 1706.500488,643.937622 
	C1706.153931,645.003174 1706.072998,645.493408 1705.992065,645.983643 
	C1700.628784,645.770447 1695.265381,645.557312 1689.635986,644.838623 
	C1690.658447,643.627747 1692.064331,642.172791 1693.214478,642.352722 
	C1697.225464,642.980225 1698.524048,641.414246 1698.839233,637.590881 
	C1699.417969,630.569519 1701.589722,628.299866 1708.448975,627.009888 
	C1709.693115,627.088379 1710.608032,626.869446 1711.323730,627.153137 
	C1715.564087,628.834167 1722.640503,625.383606 1723.359131,633.686523 
	C1723.376953,633.891602 1725.080322,633.950745 1726.299072,634.286255 
	C1729.482666,633.994141 1732.364746,633.493652 1735.538086,632.820923 
	C1738.993286,629.933960 1738.542480,627.467896 1735.651245,625.106628 
	C1736.615967,624.151123 1737.892944,622.617126 1737.781982,622.509094 
	C1734.728516,619.537109 1738.087646,616.802185 1737.994263,613.994385 
	C1738.408569,614.009155 1738.822876,614.023987 1739.522583,614.500122 
	C1739.941895,622.737427 1740.075928,630.513550 1740.196533,638.542236 
	C1740.166992,639.131958 1740.150757,639.469055 1740.063965,640.148621 
	C1739.980225,640.956421 1739.967041,641.421692 1739.953857,641.887024 
M1713.266968,635.175720 
	C1711.675293,635.593506 1710.083740,636.011230 1708.492065,636.429016 
	C1708.317505,636.066467 1708.142822,635.703918 1707.968262,635.341370 
	C1709.239258,634.130005 1710.510132,632.918640 1711.781128,631.707336 
	C1711.498535,631.317871 1711.215942,630.928406 1710.933350,630.538940 
	C1709.359009,631.653015 1707.239990,632.444519 1706.343018,633.959595 
	C1705.122437,636.021057 1705.658813,638.704407 1708.687012,638.474670 
	C1711.170410,638.286194 1713.553345,636.771362 1716.725464,636.159546 
	C1718.170654,636.775391 1719.615845,637.391296 1721.436523,638.167175 
	C1721.067993,635.542175 1720.774292,633.450684 1720.440308,631.071411 
	C1717.424194,631.146179 1713.355713,628.946045 1713.266968,635.175720 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1683.249634,615.727478 
	C1683.249634,615.727478 1683.217896,615.472595 1683.317993,615.066223 
	C1683.502686,614.473389 1683.587158,614.286987 1683.671631,614.100525 
	C1688.143677,614.078430 1692.615601,614.056335 1697.545166,613.966980 
	C1700.612549,613.983154 1703.294678,613.707642 1705.818237,614.217590 
	C1713.247192,615.718811 1720.587891,613.060669 1728.494385,614.443665 
	C1729.310425,616.846008 1729.606445,618.829712 1729.526123,620.919373 
	C1727.428345,621.289490 1725.706787,621.553650 1723.613647,621.932617 
	C1722.342651,623.179138 1721.563965,625.144897 1720.522827,625.295471 
	C1716.413208,625.890015 1712.221436,625.914856 1707.674805,626.046387 
	C1705.128540,625.667969 1702.974487,625.286255 1700.804932,625.148560 
	C1698.233521,624.985413 1695.195435,625.844666 1693.210205,624.736572 
	C1691.562256,623.816772 1691.147095,620.688293 1690.150635,618.459473 
	C1686.980835,622.058777 1685.919067,616.242310 1683.249634,615.727478 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1748.981689,646.689819 
	C1748.325562,646.080688 1747.674438,645.254578 1746.924072,644.531677 
	C1746.226929,643.859863 1745.423706,643.298157 1744.041016,642.622375 
	C1742.988403,633.094788 1742.562012,623.632812 1742.135498,614.170837 
	C1743.935059,614.170837 1745.734741,614.170837 1748.950928,614.170837 
	C1748.950928,624.680054 1748.950928,634.997253 1748.938599,645.740112 
	C1748.926270,646.165833 1748.943237,646.517822 1748.981689,646.689819 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1741.753174,614.144287 
	C1742.562012,623.632812 1742.988403,633.094788 1743.749023,642.831909 
	C1742.862427,642.865723 1741.641846,642.624390 1740.187500,642.135010 
	C1739.967041,641.421692 1739.980225,640.956421 1740.243896,640.058289 
	C1740.399414,639.180237 1740.304688,638.734924 1740.209961,638.289612 
	C1740.075928,630.513550 1739.941895,622.737427 1739.823975,614.580688 
	C1740.350220,614.172607 1740.860474,614.145203 1741.753174,614.144287 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1829.958008,619.045410 
	C1827.714355,619.045410 1825.470703,619.045410 1823.227051,619.045410 
	C1823.215576,618.440552 1823.203979,617.835632 1823.192383,617.230774 
	C1824.317627,617.121704 1825.442871,617.012573 1826.568115,616.903503 
	C1826.745483,616.434204 1826.922852,615.964844 1827.100342,615.495483 
	C1824.605835,614.448853 1822.157349,612.665161 1819.607666,612.506592 
	C1814.021118,612.159180 1808.383301,612.635376 1802.412354,612.651794 
	C1802.054688,612.205383 1802.034790,611.883545 1801.988892,611.320557 
	C1807.505493,610.949097 1813.033447,610.868225 1818.556763,610.660217 
	C1820.137817,610.600708 1821.704956,610.173340 1823.685669,609.757812 
	C1825.544067,612.772949 1827.224121,615.483215 1831.641968,612.767029 
	C1831.403076,614.419617 1831.231689,615.605469 1830.915527,617.364258 
	C1830.499756,618.306580 1830.228882,618.675964 1829.958008,619.045410 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1729.902466,620.813354 
	C1729.606445,618.829712 1729.310425,616.846008 1728.953369,614.489990 
	C1731.624634,614.075256 1734.357056,614.032959 1737.541870,613.992554 
	C1738.087646,616.802185 1734.728516,619.537109 1737.781982,622.509094 
	C1737.892944,622.617126 1736.615967,624.151123 1735.541504,625.038452 
	C1735.097168,625.056335 1734.892578,624.898621 1734.853394,624.520996 
	C1734.893921,620.500916 1732.009277,621.230774 1729.902466,620.813354 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1729.104736,590.433838 
	C1729.093140,591.062378 1729.058716,591.302856 1728.982422,591.906555 
	C1723.871826,589.536011 1723.145630,587.515991 1725.518066,582.474548 
	C1729.913208,583.501587 1727.844849,587.576294 1729.104736,590.433838 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1625.043213,597.060059 
	C1624.283081,596.717590 1623.815063,596.226013 1623.408447,595.386597 
	C1624.753174,594.744446 1626.036499,594.450134 1627.707764,594.250671 
	C1627.175537,595.200623 1626.255493,596.055786 1625.043213,597.060059 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1724.084961,591.924194 
	C1724.432617,592.025696 1724.631104,592.273987 1724.829590,592.522339 
	C1724.551758,592.588684 1724.273804,592.655029 1723.819214,592.545532 
	C1723.642456,592.369629 1723.935791,592.071045 1724.084961,591.924194 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1758.035156,600.867920 
	C1760.412354,601.131775 1762.787476,601.776367 1765.499634,602.512451 
	C1765.662109,601.909302 1765.941528,600.871399 1766.580078,599.643921 
	C1767.378662,602.117310 1767.818115,604.780334 1768.255737,607.842346 
	C1765.017700,606.293030 1761.781616,604.344727 1758.270264,602.202759 
	C1758.007690,601.755615 1758.020386,601.502136 1758.035156,600.867920 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1753.495850,601.817749 
	C1752.913940,601.805786 1752.576538,601.607178 1752.115112,601.138428 
	C1752.451538,600.581421 1752.911865,600.294556 1753.372070,600.007690 
	C1753.494751,600.548767 1753.617554,601.089905 1753.495850,601.817749 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M975.025024,687.236328 
	C972.985962,689.926636 970.946960,692.616943 968.527100,695.739868 
	C967.985718,696.266418 967.825195,696.360291 967.389465,696.316895 
	C966.526367,695.632812 965.484619,694.556091 965.415649,694.615234 
	C962.224609,697.347961 959.118469,700.179688 955.787964,702.933716 
	C955.573486,702.867432 955.124512,702.865967 954.774048,702.816895 
	C953.605103,703.167419 952.786682,703.567017 951.662598,704.095947 
	C949.905823,705.552429 948.454834,706.879639 947.003845,708.206909 
	C938.868713,714.097473 929.372681,712.090698 920.481750,712.103699 
	C913.365784,712.114014 909.180237,713.844116 908.673706,721.461914 
	C914.644165,723.734375 911.070679,729.121765 911.657410,733.195129 
	C911.740112,733.769531 908.504333,735.511658 906.810181,735.503784 
	C903.615234,735.489014 898.945618,737.685425 898.239380,730.940063 
	C897.992249,729.813416 897.902649,729.375244 897.895752,728.563782 
	C898.041077,727.431152 898.103699,726.671814 898.513428,725.976196 
	C900.530701,727.591736 902.200867,729.143494 903.931519,730.751465 
	C904.349670,730.396973 905.184814,729.689026 906.319702,729.120850 
	C907.556885,729.993347 908.494263,730.726074 910.114624,731.992676 
	C909.385437,728.213928 911.970642,723.806702 905.894348,723.540344 
	C905.899658,722.939575 905.941528,722.700684 906.080322,722.128540 
	C907.259155,719.010803 908.341125,716.226379 909.707886,712.708923 
	C905.526978,712.708923 902.551270,712.540283 899.612915,712.797363 
	C898.538452,712.891418 897.552429,713.996399 896.526062,714.640137 
	C897.160645,715.586731 897.856934,716.498535 898.413452,717.488892 
	C899.055603,718.631592 899.574524,719.843506 899.837036,720.868774 
	C897.013306,719.487183 896.291687,715.065674 891.864685,716.031189 
	C893.493164,719.000000 894.793152,721.369934 895.702515,723.709717 
	C894.056152,724.457458 892.880554,725.700317 891.531006,725.932434 
	C885.795654,726.919312 880.025024,727.894775 874.230530,728.219849 
	C872.585083,728.312073 870.793823,726.219543 869.162292,725.004456 
	C869.074097,724.938782 869.804749,723.161560 870.226501,723.129395 
	C872.075073,722.988647 873.949768,723.239380 875.815979,723.259766 
	C876.632568,723.268677 877.461426,722.927917 878.267090,722.987000 
	C879.993286,723.113708 882.098511,722.842773 883.351013,723.725037 
	C886.562500,725.987122 889.255920,725.388916 892.247498,723.629150 
	C891.309204,721.885681 889.792603,720.403870 889.956055,719.139282 
	C890.650146,713.771118 887.579712,712.726074 883.362793,712.739258 
	C872.891479,712.772095 862.420227,712.856079 851.948975,712.914185 
	C849.747253,712.926392 847.545410,712.915955 845.343628,712.915955 
	C845.222473,713.375916 845.101257,713.835876 844.980103,714.295898 
	C854.360474,720.753052 863.686462,727.291565 873.148621,733.626587 
	C876.831360,736.092285 880.820923,738.099487 884.787109,740.565186 
	C885.198853,741.274292 885.494141,741.733765 885.562378,742.424194 
	C885.183472,742.808472 885.031494,742.961792 884.614685,743.095581 
	C884.172852,743.076233 883.995789,743.076477 883.513000,742.932251 
	C881.947510,742.322327 880.687744,741.856934 879.304565,741.119690 
	C878.484924,740.210815 877.788635,739.573792 876.811035,738.745483 
	C865.037476,730.585754 853.561340,722.593872 842.028687,714.684326 
	C840.852295,713.877502 839.344238,713.120056 837.977112,713.096680 
	C829.525330,712.951843 821.069946,713.023865 813.088623,713.023865 
	C812.580872,717.041260 812.200317,720.052124 811.826538,723.009888 
	C814.289246,723.490356 815.867249,723.798218 817.445312,724.106079 
	C815.553162,725.004272 812.320435,725.535522 812.082642,724.916443 
	C810.730774,721.396790 810.094116,717.602417 809.171021,713.719849 
	C799.875916,713.719849 790.386780,713.911438 780.912292,713.622131 
	C777.302979,713.511902 775.400757,714.633789 775.305542,718.538391 
	C773.612488,716.500427 773.139587,711.422852 770.594116,714.878967 
	C768.132141,718.221741 765.818604,717.653564 763.082031,717.796753 
	C760.160828,717.949585 757.220215,717.912720 754.299744,717.749451 
	C753.675537,717.714539 752.756531,716.885925 752.559204,716.243103 
	C750.992493,711.140686 749.101257,715.322815 747.284668,716.139221 
	C746.700256,715.481689 746.139771,714.328674 745.622314,714.347656 
	C738.589233,714.606018 731.267395,711.522278 724.572083,716.067444 
	C723.981018,716.468689 722.676453,716.813477 722.387207,716.517090 
	C717.178406,711.178833 711.091248,715.347900 705.542542,714.058716 
	C705.279541,714.625854 705.016602,715.192932 704.753601,715.760071 
	C710.148499,719.955139 715.543457,724.150208 720.949951,728.618103 
	C720.973389,729.255859 720.985229,729.620789 720.536743,730.051880 
	C716.411926,730.136902 712.747009,730.129395 709.083008,730.182129 
	C705.253296,730.237183 701.424866,730.380188 697.595154,730.429077 
	C694.220947,730.472107 690.845764,730.438110 687.471008,730.438110 
	C687.500488,729.687988 687.530029,728.937805 687.559570,728.187683 
	C696.473022,728.187683 705.386475,728.187683 713.680115,728.187683 
	C710.073914,724.160217 706.703491,720.237671 703.128845,716.511108 
	C702.191223,715.533630 700.098938,714.288269 699.438354,714.681274 
	C697.537292,715.812195 694.428650,718.242737 694.741028,719.235474 
	C697.133301,726.838074 692.014282,723.246338 689.008789,722.932617 
	C687.366516,722.630310 685.922302,722.860046 684.654785,722.487976 
	C683.646790,722.192139 682.854797,721.160645 681.885010,720.108276 
	C681.896240,718.578247 681.989319,717.395691 682.436035,716.140991 
	C683.859070,716.401123 684.928528,716.733276 686.393555,717.098022 
	C689.793213,719.355957 692.161560,718.378052 694.303833,715.738281 
	C696.791016,712.673645 700.024048,711.703369 703.973267,711.872864 
	C709.592529,712.114075 715.233276,711.853149 721.164185,711.853271 
	C722.023865,711.623413 722.885010,711.486633 723.089722,711.045349 
	C723.782959,709.550964 724.243835,707.948669 724.849060,706.714355 
	C724.919067,707.702271 724.933350,708.363342 724.930359,709.337158 
	C725.034668,710.246765 725.156311,710.843750 725.278015,711.440796 
	C725.860474,710.996704 726.442932,710.552673 727.393311,710.065552 
	C728.324890,709.277771 729.315796,708.563416 729.371582,707.782288 
	C729.534424,705.503662 729.323669,703.198242 729.525391,700.752380 
	C730.865967,700.069946 731.940430,699.538513 732.963135,699.409119 
	C732.499390,702.528137 732.087463,705.245117 731.675476,707.962097 
	C731.935425,708.012390 732.195312,708.062744 732.455200,708.113037 
	C732.996704,707.009094 733.538208,705.905212 734.079651,704.801270 
	C734.628784,704.903137 735.177856,705.005005 735.726929,705.106934 
	C735.475098,707.278137 735.223206,709.449402 734.948730,711.815674 
	C740.041504,711.815674 746.774902,713.760376 750.864258,711.303711 
	C755.553528,708.486633 760.165283,708.483215 764.804626,707.329285 
	C766.844482,706.821960 769.340515,706.779053 769.435242,710.415771 
	C771.048523,710.841187 772.661133,710.953796 774.515381,711.071289 
	C774.757141,711.076172 775.240662,711.080505 775.240662,711.080505 
	C775.240662,711.080505 775.724243,711.080200 776.208984,711.176453 
	C787.789368,711.174316 798.885010,711.075989 810.294739,710.932983 
	C811.093567,710.284119 811.578369,709.679932 812.385010,709.060547 
	C813.136353,709.035583 813.565857,709.025879 813.985107,709.269409 
	C813.999512,709.689758 814.024292,709.856934 814.298157,710.366455 
	C815.514343,710.853210 816.481689,711.124756 817.448608,711.123169 
	C833.292603,711.097656 849.136658,711.037598 865.323730,711.021362 
	C866.124512,711.023071 866.582214,710.988831 867.505005,710.960205 
	C882.645569,710.646790 897.321045,710.327759 912.463379,710.057495 
	C920.735962,710.071594 928.550049,710.222107 936.341309,709.869263 
	C938.239258,709.783386 940.062378,708.044556 941.999634,706.686890 
	C942.048462,705.534790 942.017395,704.761597 942.088867,703.819946 
	C942.191406,703.651550 942.395386,703.314148 942.705688,703.266235 
	C943.645264,703.180298 944.274536,703.142334 945.297241,703.145142 
	C947.193115,702.888306 948.695679,702.590759 950.504150,702.196045 
	C951.867554,701.386963 952.925049,700.674988 954.233215,699.866943 
	C955.290039,699.115234 956.096130,698.459473 957.220337,697.614929 
	C959.694702,695.952515 961.851013,694.478943 964.316833,692.898499 
	C966.083862,691.530212 967.541382,690.268921 969.313354,688.889404 
	C971.358337,687.784058 973.088806,686.796936 974.891479,686.163452 
	C974.984070,686.756836 975.004517,686.996582 975.025024,687.236328 
M758.104736,712.257629 
	C757.109375,711.963989 756.114014,711.670410 754.895264,711.310913 
	C754.895264,712.994690 754.895264,714.248901 754.895264,715.729858 
	C757.765808,715.729858 760.414673,715.729858 764.396545,715.729858 
	C761.811279,714.228699 760.317383,713.361328 758.104736,712.257629 
M761.598877,711.895203 
	C763.455872,712.879089 765.312927,713.862976 767.169922,714.846863 
	C767.539734,714.614746 767.909485,714.382568 768.279297,714.150452 
	C767.532654,712.743103 767.009277,710.394714 765.986450,710.152039 
	C764.503357,709.800232 762.659180,710.970215 761.598877,711.895203 
M944.641296,705.753296 
	C945.014465,705.365662 945.389160,704.979492 945.745239,704.576721 
	C945.750488,704.570862 945.435791,704.248718 945.419312,704.261841 
	C945.001648,704.593018 944.604004,704.949463 944.641296,705.753296 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M808.654053,878.279358 
	C804.833557,877.433472 800.897278,876.102234 798.971252,879.894348 
	C796.364319,879.099365 794.179321,878.108154 791.906128,877.825256 
	C789.289856,877.499512 786.595520,877.792297 783.935242,877.829895 
	C772.663757,877.989258 772.663757,877.991150 764.075134,872.174011 
	C764.075134,880.841248 763.827515,889.239685 764.177429,897.613037 
	C764.357849,901.928894 771.671326,906.979980 776.720032,906.931274 
	C802.489319,906.682373 828.258484,906.413879 854.498718,906.499207 
	C854.979980,907.229187 854.990234,907.612122 855.000488,907.995056 
	C828.408447,908.326477 801.816833,908.716980 775.223938,908.947632 
	C768.595276,909.005127 762.018616,902.051697 761.964355,895.493164 
	C761.940979,892.668213 761.817505,889.844116 761.796143,886.106812 
	C761.890503,881.723694 762.057617,878.246826 761.880249,874.787598 
	C761.832520,873.856079 760.738159,872.978271 759.783264,871.988037 
	C759.226379,871.808594 759.010315,871.716919 758.794312,871.625305 
	C758.798340,871.264893 758.802368,870.904541 759.191650,870.106079 
	C760.275208,867.040222 760.973572,864.412354 761.671875,861.784485 
	C761.491455,851.176514 761.934814,840.521362 759.668030,830.156433 
	C760.165955,827.883118 761.065002,825.614502 761.091370,823.335754 
	C761.196777,814.220947 761.031128,805.103027 760.953674,795.296997 
	C760.596619,794.536072 760.251648,794.464417 759.906677,794.392761 
	C759.906677,794.392761 759.648438,794.508057 759.330933,794.689209 
	C758.371643,795.320129 757.729919,795.770020 757.088257,796.219849 
	C753.490295,798.812500 750.363403,801.259766 744.994019,799.693481 
	C740.422424,798.359863 735.126343,799.237305 730.187866,799.617981 
	C728.884644,799.718506 727.718689,801.599548 726.681335,802.497192 
	C725.419434,801.507202 724.014954,800.405334 722.269348,798.881470 
	C721.928284,798.459534 722.002014,798.031555 722.001953,798.031494 
	C722.997009,797.553955 723.992065,797.076477 725.236206,796.479431 
	C724.174438,795.648132 723.330811,794.987610 722.482239,794.323303 
	C722.805115,793.880005 723.056763,793.378174 723.166565,793.407410 
	C724.730042,793.824829 726.384766,794.109314 727.781433,794.863342 
	C728.607239,795.309204 729.064575,797.305420 729.607361,797.272766 
	C734.736755,796.963806 739.848816,796.368164 745.336060,795.248047 
	C745.837891,794.252991 745.969421,793.864990 746.101013,793.477051 
	C748.483521,794.293396 749.338379,799.259094 752.686462,796.669922 
	C754.909302,794.950867 756.023193,791.797852 757.987122,788.961182 
	C760.427490,790.936096 761.060608,789.547302 761.034729,787.445618 
	C760.962280,781.545288 760.747253,775.646667 760.588440,769.747375 
	C760.513306,768.021912 760.438232,766.296387 760.235291,763.877991 
	C760.322021,761.969788 760.536560,760.754517 760.751099,759.539246 
	C760.225159,756.111450 759.699219,752.683716 759.816772,749.084229 
	C761.591675,749.312744 762.722961,749.712891 763.854309,750.113098 
	C763.468872,753.141541 763.083374,756.169922 762.498657,759.883301 
	C762.494385,761.944458 762.689331,763.320679 762.884277,764.696960 
	C762.903076,771.829346 762.885193,778.962097 762.954529,786.093994 
	C762.995605,790.322388 769.230103,796.800903 773.475769,796.868591 
	C780.766968,796.984985 788.061340,796.900818 793.748779,796.900818 
	C796.581665,794.762512 798.297485,793.467346 800.013306,792.172241 
	C800.355408,792.573608 800.697449,792.974976 801.039551,793.376343 
	C800.352844,794.235718 799.666138,795.095093 798.277771,796.832642 
	C801.508789,796.832642 803.748474,796.832642 806.443481,797.162842 
	C806.968201,797.930298 807.037537,798.367554 807.106934,798.804871 
	C803.756348,799.273499 799.206055,796.543213 798.315247,801.225037 
	C795.684021,800.396362 793.326355,799.237610 790.874451,798.981628 
	C787.416626,798.620605 783.888000,798.885498 780.391296,798.958740 
	C774.234131,799.087646 767.794800,800.153809 764.101746,792.665588 
	C763.785706,792.973450 763.469604,793.281372 763.153564,793.589233 
	C763.153564,798.790405 763.115906,803.991882 763.164551,809.192505 
	C763.209717,814.019531 763.507751,818.849487 763.396973,823.671204 
	C763.293457,828.171326 765.547363,830.004272 769.712708,830.090576 
	C772.100220,830.140076 774.489624,830.099487 776.878174,830.099487 
	C776.887390,830.600220 776.896545,831.100952 776.905762,831.601685 
	C774.039612,831.601685 771.172546,831.645630 768.307495,831.590271 
	C764.885010,831.524109 763.271484,832.700134 763.420654,836.558044 
	C763.787415,846.039246 763.676086,855.537903 763.861206,865.027588 
	C763.948608,869.511597 770.086426,875.468445 774.653748,875.548096 
	C779.482483,875.632324 784.318726,875.665161 789.143127,875.487488 
	C792.464783,875.365173 796.617798,877.077637 798.126892,871.900513 
	C798.280334,871.374207 800.362061,871.410034 802.651489,870.975952 
	C801.321106,872.801208 800.725403,873.618469 799.439941,875.382019 
	C802.762207,875.382019 805.283081,875.382019 808.178467,875.943970 
	C808.586609,877.097046 808.620300,877.688171 808.654053,878.279358 
M746.371948,796.489502 
	C746.371948,796.489502 746.564819,796.584473 746.564819,796.584473 
	C746.564819,796.584473 746.529480,796.411011 746.371948,796.489502 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M764.285950,750.240601 
	C762.722961,749.712891 761.591675,749.312744 760.122559,748.900879 
	C758.936340,748.234985 758.137878,747.113708 757.229736,747.016541 
	C754.774658,746.753784 752.272888,746.926636 749.800659,746.926636 
	C749.729858,748.421021 749.672485,749.632874 749.615051,750.844666 
	C748.662842,750.200317 747.790955,749.333984 746.742798,748.955078 
	C744.078247,747.991821 741.334839,747.246704 738.874023,746.491272 
	C739.945374,751.178650 741.372620,754.937866 741.416443,758.713196 
	C741.436890,760.476624 739.083130,762.709717 737.272888,763.956848 
	C733.919922,766.266663 727.902100,764.790710 725.547363,761.556091 
	C722.676270,757.611877 723.786438,752.111511 729.258728,747.045044 
	C725.531189,747.045044 722.695496,747.045044 719.416138,746.779114 
	C718.972473,746.513184 718.976746,746.109680 719.443481,746.042908 
	C728.861267,745.761414 737.812195,745.546692 746.763184,745.331909 
	C746.782959,744.695312 746.802673,744.058716 746.822449,743.422119 
	C744.831604,743.261414 742.840027,742.952209 740.850159,742.963074 
	C721.297913,743.069824 701.745911,743.229797 682.159607,743.184814 
	C682.086426,742.736633 682.072937,742.476929 682.545959,742.168457 
	C702.071350,741.882751 721.135376,741.565247 740.200439,741.478455 
	C743.666443,741.462708 746.656372,741.270447 748.428040,737.880493 
	C748.401917,737.894226 748.481750,737.944336 748.605103,738.331665 
	C749.251404,744.588318 753.400085,740.684326 755.742798,741.691284 
	C753.415466,742.570557 751.194763,743.119263 748.974060,743.667969 
	C748.986023,744.121460 748.997925,744.575012 749.009888,745.028503 
	C752.813354,745.028503 756.616821,745.028503 760.673950,744.983276 
	C761.437195,744.960266 761.946655,744.982605 762.952576,745.093262 
	C769.215332,745.181519 774.981689,745.181519 780.748108,745.181519 
	C781.047363,744.622437 781.346619,744.063293 781.645813,743.504211 
	C779.148804,740.932495 776.651794,738.360718 774.116577,735.405762 
	C776.949890,730.458801 779.805176,725.884644 782.727356,721.353516 
	C782.934692,721.031982 783.625000,720.991455 784.104736,720.880676 
	C794.405823,718.500793 799.225281,720.866699 804.327209,729.979309 
	C805.996216,732.960388 805.499390,735.183533 804.062683,737.779968 
	C803.532837,738.737549 803.429626,739.931335 802.839417,741.203857 
	C801.831482,742.390015 801.116028,743.389771 800.108215,744.797791 
	C801.369873,744.959167 801.983459,745.111877 802.595276,745.104980 
	C828.483276,744.814819 854.371338,744.524780 880.258362,744.161499 
	C881.449951,744.144714 882.632080,743.453735 883.818665,743.076782 
	C883.995789,743.076477 884.172852,743.076233 884.659668,743.404358 
	C884.998291,744.357300 885.027222,744.981812 885.056152,745.606323 
	C873.771118,745.920593 862.487915,746.373962 851.200684,746.517761 
	C832.426086,746.756958 813.648987,746.809448 794.872803,746.913086 
	C790.384338,746.937866 785.877930,746.616333 781.415039,746.940002 
	C779.696350,747.064636 778.154114,749.016724 776.394592,749.307373 
	C772.548706,749.942444 768.615295,750.046875 764.285950,750.240601 
M795.676697,741.348572 
	C799.912964,741.465454 802.106201,739.589722 801.918823,735.147705 
	C794.205627,735.147705 786.724854,735.147705 779.078735,735.147705 
	C782.646301,745.064575 790.417908,748.316589 798.509766,743.103088 
	C797.057617,742.473633 795.950073,741.993591 794.830017,741.485840 
	C794.830017,741.485840 794.829468,741.455566 795.676697,741.348572 
M787.852905,722.185669 
	C783.115906,722.469849 779.591125,726.824219 779.429749,732.470215 
	C787.218811,732.470215 794.998230,732.470215 802.968689,732.470215 
	C800.318481,725.417908 796.974548,720.263916 787.852905,722.185669 
M731.766479,762.954407 
	C735.624817,763.447083 738.608093,761.677063 739.598938,758.250366 
	C740.167358,756.284546 739.337708,753.264099 738.035950,751.559753 
	C736.063782,748.977783 732.969666,748.168152 729.005737,750.180542 
	C730.844360,750.661255 731.963074,750.953735 732.945374,751.210571 
	C731.992371,753.265991 731.154724,755.072632 730.716125,756.018494 
	C729.715454,755.129211 728.474243,754.026184 727.233093,752.923157 
	C726.852173,754.703491 725.669922,756.855591 726.282104,758.175232 
	C727.154846,760.056580 729.343506,761.327515 731.766479,762.954407 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M997.519165,626.970337 
	C998.317505,627.316528 999.115845,627.662720 999.924316,628.278870 
	C999.453064,629.725708 998.971741,630.902588 998.236938,632.699097 
	C1005.443542,635.869873 1012.526428,638.943420 1019.571594,642.100830 
	C1024.415161,644.271545 1028.768066,646.990845 1030.105225,653.438110 
	C1029.847656,654.804016 1029.534790,655.441223 1029.221924,656.078491 
	C1028.775024,652.003906 1025.321411,652.695923 1022.370117,651.993774 
	C1021.993225,651.993408 1022.021362,652.027039 1022.164429,651.730713 
	C1023.163635,650.356323 1024.019897,649.278137 1025.545898,647.356445 
	C1016.279724,643.184937 1007.571411,639.264526 999.212769,635.501587 
	C997.963745,636.690918 997.136780,637.478333 996.309875,638.265808 
	C995.519348,637.206665 994.698486,636.168457 993.948120,635.081604 
	C993.362549,634.233459 993.071716,632.872131 992.294067,632.516113 
	C987.038452,630.110413 981.688049,627.911804 976.186157,625.438171 
	C975.749268,625.245117 975.494568,625.260071 975.239807,625.274963 
	C975.470032,625.537354 975.700256,625.799805 975.989380,626.454590 
	C973.865051,638.790527 971.328979,640.346863 962.998718,634.848572 
	C962.876221,634.767761 962.828430,634.573853 962.967590,634.200012 
	C963.170776,632.925903 963.152222,631.884399 963.358765,630.757324 
	C963.977539,630.550476 964.104187,630.296143 963.970215,629.947571 
	C963.976440,629.986206 963.907410,629.949280 964.278564,630.079590 
	C967.025330,629.304382 969.400940,628.398865 972.705078,627.139343 
	C969.032776,625.280640 966.521790,623.049561 963.646240,627.009094 
	C962.740295,626.883057 962.232239,626.746887 961.938049,626.335815 
	C963.209412,624.247192 964.266968,622.433472 965.309753,620.644958 
	C958.618530,616.008423 953.426880,617.286743 951.005493,623.933777 
	C949.683411,627.563354 951.355469,629.744446 955.081665,629.642334 
	C954.684448,630.708008 954.350586,631.464661 954.019165,632.551575 
	C953.694031,633.932190 953.366394,634.982666 952.679871,635.957886 
	C949.768921,635.511108 946.916382,632.333252 944.570923,636.943481 
	C944.200623,638.134766 944.129089,639.063232 943.913330,639.984009 
	C943.769104,639.976379 943.491516,640.056213 943.197937,640.151001 
	C942.691040,641.284119 942.306824,642.320312 942.294312,643.361023 
	C942.244202,647.525574 942.309448,651.691406 942.056274,655.678345 
	C941.139160,655.161255 940.117126,654.967346 939.923767,654.458496 
	C939.170105,652.475281 938.980957,650.222778 937.966309,648.416077 
	C937.438354,647.476074 935.336426,646.513977 934.450623,646.858948 
	C932.225098,647.725525 930.286987,649.330078 927.871521,650.869507 
	C929.378601,651.503418 930.798035,652.100403 931.389465,652.349182 
	C930.099854,653.564636 928.786438,654.802551 927.157837,656.040771 
	C926.212402,656.024170 925.582153,656.007141 924.926758,655.673462 
	C925.055664,654.549194 925.209717,653.741577 925.733765,652.834961 
	C926.384644,652.327515 926.665405,651.919189 926.740845,651.326111 
	C926.655945,650.642273 926.776367,650.143188 926.914551,649.246582 
	C927.001465,647.152466 927.070557,645.455994 927.480042,643.938110 
	C929.112122,644.469910 930.403687,644.823059 932.405762,645.370483 
	C931.349915,641.574158 929.855042,640.740417 927.135376,641.937012 
	C926.957031,637.050476 926.864563,632.573669 926.931396,628.448730 
	C926.394714,633.672241 929.841370,633.915955 931.750000,633.908691 
	C932.458130,638.017273 933.065002,641.538513 933.691711,645.174744 
	C936.653503,644.333069 938.843689,643.710632 940.952148,643.111511 
	C940.509827,637.684509 949.323853,619.312134 953.732361,617.015442 
	C955.908691,615.881531 958.704529,615.656006 961.234863,615.590149 
	C962.690247,615.552307 964.175232,616.655029 966.469055,617.573547 
	C966.328186,615.118896 966.237854,613.545166 966.147583,611.971436 
	C966.670898,612.160828 967.194275,612.350159 967.688477,613.184387 
	C968.137756,615.607910 968.079712,618.436890 969.179810,618.998779 
	C977.854248,623.430298 986.749817,627.429016 996.164307,631.835693 
	C996.765747,629.675903 997.142456,628.323120 997.519165,626.970337 
M970.963989,623.558044 
	C971.399048,623.641479 971.834167,623.724915 972.269226,623.808350 
	C971.830505,623.550903 971.391724,623.293457 970.631592,622.487732 
	C969.998413,622.346313 969.365234,622.204834 968.732117,622.063416 
	C968.697815,622.303650 968.663574,622.543823 968.629333,622.783997 
	C969.430725,622.843811 970.232178,622.903564 970.963989,623.558044 
M969.531799,635.454468 
	C970.577454,633.872986 971.623108,632.291443 972.668762,630.709961 
	C972.275940,630.339600 971.883057,629.969238 971.490234,629.598877 
	C969.279053,630.546570 967.067932,631.494263 963.759460,632.912292 
	C966.180969,634.106934 967.443298,634.729736 969.531799,635.454468 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M885.443237,745.726440 
	C885.027222,744.981812 884.998291,744.357300 884.924438,743.423950 
	C885.031494,742.961792 885.183472,742.808472 885.835388,742.655212 
	C888.181335,743.171570 890.027283,743.687744 891.873291,744.203979 
	C896.221069,748.422852 900.568909,752.641724 905.077759,757.016846 
	C906.735962,755.052673 907.554810,754.082764 908.697266,753.067749 
	C909.020813,753.022583 908.981445,752.987488 908.853760,753.337830 
	C907.205444,756.027893 907.563110,757.876343 910.007324,759.395325 
	C912.072693,760.678833 914.015625,762.159363 916.212891,763.777466 
	C917.926697,765.005066 919.440308,766.008057 920.953979,767.011047 
	C926.179871,770.560669 931.390198,774.133484 936.636536,777.652588 
	C940.935486,780.536133 945.635437,782.951782 949.468994,786.351379 
	C951.861145,788.472900 952.946533,792.053284 954.673523,794.946045 
	C955.484619,796.304626 956.470337,797.558411 957.294067,798.910156 
	C957.924561,799.944763 958.833252,801.031189 958.907776,802.142273 
	C959.141479,805.623901 959.051758,809.128052 959.055359,812.623474 
	C959.056030,813.278137 959.117676,814.372131 958.799622,814.523132 
	C951.648071,817.918579 955.648987,824.296021 955.074768,829.275818 
	C954.681335,832.687805 956.277405,836.283569 956.430481,839.821167 
	C956.523865,841.977905 955.204895,844.171082 955.150146,846.363647 
	C954.996399,852.517517 955.169312,858.679626 955.048340,865.549133 
	C954.362183,866.493286 953.841003,866.726746 953.161743,866.732056 
	C952.939087,866.330261 952.874634,866.156738 952.810181,865.983276 
	C952.841675,859.167908 952.724609,852.347473 952.959900,845.539124 
	C953.102478,841.411865 949.707458,841.582092 947.418457,840.518433 
	C946.203857,839.954041 944.635315,840.151428 943.195312,838.938110 
	C946.299255,838.568970 949.403198,838.199829 952.353027,837.848999 
	C952.353027,831.311096 952.374634,825.030579 952.325989,818.750610 
	C952.319885,817.964661 952.182495,816.712830 951.702148,816.467468 
	C944.324097,812.698486 949.794983,806.041260 948.198608,801.000671 
	C948.067139,800.585693 948.695312,799.848083 949.095154,799.363159 
	C950.089783,798.157104 952.111877,796.907593 951.999329,795.860657 
	C951.748047,793.522644 951.050720,790.666382 949.460510,789.143555 
	C944.473755,784.368042 938.549744,781.201538 931.302490,781.354980 
	C927.306152,781.439575 923.306580,781.370117 919.308472,781.370117 
	C919.312195,780.838745 919.315918,780.307434 919.319641,779.776062 
	C924.023438,779.776062 928.727234,779.776062 933.431030,779.776062 
	C933.606567,779.292175 933.782166,778.808350 933.957703,778.324463 
	C924.601440,771.966797 915.245117,765.609131 905.401489,758.936890 
	C904.329346,758.389404 903.744629,758.156494 902.968506,757.633911 
	C899.812500,755.199524 896.847900,753.054871 892.946289,750.232300 
	C892.946289,755.755798 892.946289,759.919983 892.935913,764.379639 
	C892.920044,765.068726 892.914551,765.462463 892.909058,765.856140 
	C892.088074,764.755676 890.673706,763.714478 890.556885,762.543701 
	C890.196960,758.938232 890.145325,755.273621 890.330872,751.650330 
	C890.505676,748.239258 889.315735,746.244812 885.443237,745.726440 
M955.353760,813.883301 
	C955.516968,813.874817 955.692749,813.900085 955.838989,813.845886 
	C955.937378,813.809387 956.080750,813.609192 956.057007,813.568787 
	C954.441772,810.826843 952.811646,808.093628 951.182800,805.359680 
	C950.939636,805.556824 950.696411,805.753967 950.453247,805.951111 
	C950.453247,808.507141 950.453247,811.063171 950.453247,813.912354 
	C952.067200,813.912354 953.353821,813.912354 955.353760,813.883301 
M954.929626,807.513672 
	C955.380188,808.124268 955.830750,808.734863 956.614990,809.797729 
	C956.614990,806.478027 956.614990,803.839478 956.614990,801.152161 
	C954.514832,801.152161 952.829773,801.152161 950.850830,801.152161 
	C952.242188,803.335144 953.387939,805.132751 954.929626,807.513672 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M617.974365,874.009277 
	C617.974365,874.009277 617.489014,874.074402 617.195923,873.594849 
	C616.935120,871.329163 616.967468,869.543091 617.011230,867.338623 
	C617.513733,863.977905 616.390686,862.715393 613.303955,862.906189 
	C609.886780,863.117432 606.449036,862.996399 603.124146,862.750244 
	C603.499695,861.976807 603.771484,861.473145 604.503296,860.965820 
	C608.824158,860.962219 612.684875,860.962219 616.545654,860.962219 
	C616.511597,860.316833 616.477539,859.671387 616.443481,859.026001 
	C611.780640,859.026001 607.117798,859.026001 602.312012,858.821045 
	C602.069092,858.127502 601.969177,857.638916 602.267761,857.097778 
	C604.040161,856.869507 605.414185,856.693787 607.420288,856.437317 
	C605.804016,854.446350 604.690308,853.074463 604.022827,852.252258 
	C601.504333,853.201050 599.277344,854.040100 596.714294,855.005676 
	C596.714294,852.637817 597.119934,851.167480 596.637573,850.097473 
	C595.238464,846.993774 596.777527,846.132629 599.395935,845.975586 
	C601.045837,845.876709 602.803467,846.358643 604.336121,845.937317 
	C605.405701,845.643250 606.956482,844.262329 606.958740,843.363525 
	C606.980408,834.593933 606.721130,825.823669 606.362061,816.808960 
	C606.136475,816.367188 606.090698,816.170044 606.412476,815.910400 
	C606.999695,814.238281 607.265198,812.633362 607.427246,811.018066 
	C607.628052,809.015930 607.743225,807.005188 608.140381,805.068970 
	C608.592285,805.396851 608.799255,805.653931 609.014404,806.305176 
	C609.807190,807.722534 610.884949,809.741028 611.329407,809.610046 
	C614.580566,808.652100 613.851746,810.959473 614.151611,812.837280 
	C614.245300,813.921265 614.235474,814.721802 613.920410,815.737915 
	C612.316345,815.207336 611.017578,814.461060 609.856201,813.793762 
	C610.901855,826.477539 607.130005,840.312561 614.016724,853.020813 
	C612.278076,853.904236 611.096436,854.504639 609.914795,855.105042 
	C609.978882,855.571045 610.042969,856.037109 610.107056,856.503174 
	C612.286133,856.609924 614.465210,856.716675 616.847961,856.833435 
	C616.847961,843.161377 616.847961,830.245117 617.221985,817.290833 
	C618.096680,817.227966 618.597290,817.203064 619.097961,817.178223 
	C619.404846,835.081360 619.711731,852.984497 619.763672,871.490112 
	C618.997314,872.731506 618.485840,873.370422 617.974365,874.009277 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M666.127686,754.811157 
	C663.593018,754.811157 661.058350,754.811157 658.523621,754.811157 
	C658.416565,754.432129 658.309509,754.053040 658.202454,753.674011 
	C659.201904,753.057617 660.334229,752.579834 661.162842,751.785339 
	C661.967896,751.013367 662.461426,749.916565 663.663391,748.096924 
	C650.583313,748.096924 638.730042,748.191040 626.879883,748.039185 
	C623.360962,747.994080 621.306885,748.863464 621.432129,752.861633 
	C621.127747,751.682373 620.823303,750.503174 620.518921,749.323914 
	C620.011047,749.340088 619.503235,749.356201 618.995361,749.372375 
	C618.995361,765.231018 618.995361,781.089661 618.702271,797.457764 
	C617.934326,797.973328 617.459473,797.979431 617.006592,797.518311 
	C616.677002,780.836548 616.325378,764.622131 615.974487,748.438904 
	C613.143127,748.438904 611.049438,748.438904 608.388794,748.438904 
	C608.388794,754.819885 608.388794,760.920410 608.035400,767.013794 
	C607.446411,767.008057 607.210815,767.009644 606.955872,766.542358 
	C606.936584,760.137390 606.936584,754.201172 606.936584,748.265015 
	C606.262878,748.164185 605.589172,748.063416 604.915527,747.962646 
	C604.238708,749.405090 603.728333,750.958557 602.853455,752.268738 
	C600.397400,755.946777 597.791077,759.524414 595.047974,762.883789 
	C594.594971,762.056763 594.338013,761.487549 594.437073,760.967896 
	C600.107056,758.350098 601.011841,752.917603 601.531067,748.125183 
	C602.013245,743.675171 603.413513,741.586304 607.657837,741.628235 
	C608.123535,737.253296 608.123535,737.253296 610.221558,737.304321 
	C610.216797,738.112915 610.264404,738.575012 610.230835,739.368530 
	C610.488403,740.446289 610.827148,741.192505 611.190063,742.113892 
	C611.374084,742.177124 611.533936,742.065186 611.859985,741.705688 
	C613.442505,741.074646 614.858765,740.691284 616.559204,740.296753 
	C617.199280,740.162842 617.555298,740.040100 617.966736,740.092285 
	C618.022217,740.267273 617.995789,740.633362 618.039978,740.919434 
	C618.366455,741.441833 618.648743,741.678162 618.989868,742.154236 
	C619.048767,742.393982 619.076050,742.887085 618.695435,743.076660 
	C613.670227,743.703491 609.025696,744.140808 604.381104,744.578064 
	C604.421509,745.055237 604.461853,745.532410 604.502258,746.009583 
	C613.270142,746.009583 622.038391,745.960571 630.805847,746.021423 
	C642.931152,746.105591 655.055908,746.272583 667.193604,746.799438 
	C666.846741,749.733643 666.487244,752.272400 666.127686,754.811157 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M807.396851,799.128906 
	C807.037537,798.367554 806.968201,797.930298 806.866882,797.161011 
	C807.594421,795.087158 808.354004,793.345215 808.659973,792.643494 
	C806.410156,790.923706 804.598328,789.538696 802.786499,788.153687 
	C803.550842,787.316040 804.315125,786.478333 805.079468,785.640625 
	C807.051208,787.079041 809.022949,788.517456 810.348450,789.484375 
	C814.732239,789.484375 818.701843,789.484375 823.298584,789.484375 
	C824.303284,788.557129 825.994385,786.996399 827.685547,785.435608 
	C824.550964,793.767822 824.016846,801.447449 828.161804,809.787109 
	C826.413818,808.468811 824.665771,807.150513 823.080078,805.954590 
	C819.112854,805.954590 815.056091,805.954590 810.958191,805.954590 
	C809.264038,807.219238 807.302490,808.683472 805.341003,810.147705 
	C804.961548,809.724121 804.582092,809.300537 804.202637,808.876953 
	C805.699341,807.210083 807.196045,805.543152 808.968384,803.569214 
	C808.636780,802.504150 808.161743,800.978577 807.396851,799.128906 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M721.583679,797.987915 
	C722.002014,798.031555 721.928284,798.459534 721.966797,798.673218 
	C719.604126,799.253113 716.005005,797.942871 715.938721,801.998169 
	C715.895508,804.646301 717.080994,807.314453 717.442505,808.827820 
	C712.335693,804.149597 704.933472,807.712524 699.086304,807.671570 
	C699.192932,806.573303 700.013306,804.609070 699.384521,803.395508 
	C697.415833,799.594910 698.026367,796.205811 700.170898,792.316406 
	C700.595581,791.609619 700.791870,791.338440 700.988159,791.067261 
	C705.022339,790.635925 709.056580,790.204590 713.090820,789.773193 
	C713.076050,790.104919 713.061340,790.436646 713.046570,790.768311 
	C714.778015,789.214417 716.509399,787.660522 718.240784,786.106689 
	C718.663879,786.457886 719.086914,786.809082 719.510010,787.160278 
	C718.251160,788.915771 716.992249,790.671204 715.312439,793.013672 
	C715.483032,795.939453 716.732544,798.533447 721.583679,797.987915 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M909.039124,753.041626 
	C911.059875,744.618408 903.336548,750.401306 900.702148,747.586853 
	C902.607788,747.006287 903.989807,746.585205 905.392761,746.157776 
	C903.862000,744.972290 902.526184,744.005188 901.329956,742.888672 
	C901.230164,742.795532 902.102417,741.134460 902.469421,741.155090 
	C904.515198,741.270203 906.544373,741.679199 908.493652,741.701538 
	C918.781128,739.568848 929.388794,740.650879 940.466431,740.596802 
	C943.040833,742.484131 944.871521,743.127380 946.081299,740.084778 
	C946.505432,740.067200 946.929626,740.049561 947.744019,740.350342 
	C948.224060,740.851868 948.313904,741.034973 948.319153,741.493103 
	C948.203613,742.146057 948.172607,742.523987 948.107910,743.276184 
	C948.011292,744.396545 947.948425,745.142700 947.885559,745.888855 
	C947.273621,746.205933 946.678223,746.747986 946.046448,746.794189 
	C944.634705,746.897461 943.201782,746.688416 941.784241,746.752930 
	C941.398682,746.770508 941.036804,747.309448 940.664062,747.609131 
	C936.499146,741.476318 933.210144,749.606140 928.540161,748.222656 
	C924.538513,745.664856 921.509033,747.625183 918.114685,748.426880 
	C913.004333,746.379700 910.697388,749.121521 908.981445,752.987488 
	C908.981445,752.987488 909.020813,753.022583 909.039124,753.041626 
M930.189880,743.417358 
	C930.833069,743.293945 931.476196,743.170593 932.119385,743.047241 
	C932.029968,742.598511 931.940491,742.149841 931.851074,741.701172 
	C931.268799,742.041016 930.686462,742.380859 930.189880,743.417358 
M925.558838,742.612427 
	C925.581360,742.088135 925.620789,741.562744 925.588074,741.041809 
	C925.585876,741.007446 925.033691,741.007629 924.737427,740.991821 
	C924.785339,741.503906 924.833313,742.016052 925.558838,742.612427 
M916.211548,742.426575 
	C916.403015,742.501831 916.594421,742.577026 916.785889,742.652283 
	C916.729553,742.448730 916.673218,742.245178 916.211548,742.426575 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M948.351440,745.884033 
	C947.948425,745.142700 948.011292,744.396545 948.563477,743.315430 
	C954.803894,742.980408 960.554993,742.980408 966.306091,742.980408 
	C966.271301,742.393005 966.236572,741.805542 966.201843,741.218140 
	C960.269104,741.218140 954.336426,741.218140 948.403748,741.218140 
	C948.313904,741.034973 948.224060,740.851868 948.061890,740.371582 
	C952.046326,739.731445 956.099426,739.330811 960.161499,739.069214 
	C962.263428,738.933960 964.381287,739.045349 967.049561,739.045349 
	C967.049561,732.834045 967.049561,727.267151 967.049561,721.700256 
	C967.494446,721.591370 967.939270,721.482483 968.384155,721.373657 
	C968.864990,722.735107 969.681946,724.074280 969.773987,725.461487 
	C970.560608,737.326233 971.373779,749.194214 971.786804,761.074829 
	C971.877258,763.676575 970.488770,766.363892 969.570557,768.936523 
	C969.395325,769.427429 967.764282,769.976807 967.600769,769.792603 
	C966.928833,769.035767 966.212341,768.031372 966.143555,767.072815 
	C965.704468,760.950684 965.440613,754.816101 965.097534,748.686829 
	C965.043396,747.719299 964.879944,746.757874 964.777039,745.879272 
	C959.307678,745.879272 954.062561,745.879272 948.351440,745.884033 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1035.678467,795.412720 
	C1038.604614,795.596680 1041.144531,795.596680 1044.258911,795.596680 
	C1042.561035,797.035095 1041.203003,798.185608 1039.154419,799.921021 
	C1044.015503,800.529053 1046.501343,799.606201 1045.770996,794.149170 
	C1049.855103,794.958374 1053.414795,795.663757 1058.179321,796.607849 
	C1058.145508,796.632935 1059.277588,795.793335 1060.894043,794.594543 
	C1060.894043,797.025452 1060.894043,799.016235 1060.894043,800.709595 
	C1058.352173,800.709595 1055.953247,800.709595 1053.236328,800.709595 
	C1053.166138,800.245300 1052.971558,798.956909 1052.841064,798.093201 
	C1048.747803,799.713928 1043.209961,803.791138 1040.885254,802.420105 
	C1036.554443,799.865845 1031.653076,806.802734 1029.392822,801.725281 
	C1024.047852,802.174927 1019.285828,802.575500 1014.187744,803.004333 
	C1014.187744,800.432434 1014.187744,798.205811 1014.187744,794.775146 
	C1016.552917,795.362976 1018.723267,795.902405 1021.189697,796.515381 
	C1025.452271,796.126526 1030.372314,795.677673 1035.678467,795.412720 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M904.907776,805.381287 
	C910.742310,805.581909 916.577393,805.919922 922.411072,805.897583 
	C924.111206,805.891052 925.807373,804.856079 928.071045,805.327087 
	C926.746643,806.477417 925.422241,807.627747 924.097839,808.778076 
	C924.353882,809.215271 924.609863,809.652466 924.865845,810.089661 
	C926.464355,809.168213 929.180054,808.509583 929.469238,807.280090 
	C930.767395,801.761841 934.456909,804.336548 937.377136,804.535339 
	C939.584778,804.685730 941.767334,805.204773 944.688232,805.677429 
	C944.688232,806.377686 944.688232,808.329529 944.688232,809.542908 
	C942.105286,809.961365 939.841675,810.328064 936.857361,810.811584 
	C935.790771,809.819824 934.814819,806.443420 931.358093,810.573486 
	C929.731140,812.517334 924.651672,814.821594 921.706665,810.623047 
	C916.434937,812.990723 911.453552,813.349609 906.159790,810.817505 
	C905.950317,810.517944 905.955078,810.520020 906.281494,810.517761 
	C908.039612,809.702087 909.471252,808.888733 910.902954,808.075378 
	C910.695129,807.715027 910.487305,807.354614 910.279480,806.994263 
	C908.837036,806.994263 907.394531,806.994263 905.981445,806.992310 
	C906.010864,806.990295 906.011719,807.049194 906.027344,806.708008 
	C905.666748,806.142029 905.290527,805.917236 904.901489,805.614868 
	C904.888794,805.537354 904.907776,805.381287 904.907776,805.381287 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M967.217651,905.149536 
	C967.336975,905.529663 967.456299,905.909729 967.642212,906.884033 
	C961.604675,907.451416 955.500183,907.369629 949.396423,907.408081 
	C931.980347,907.517700 914.563782,907.617920 897.149536,907.870667 
	C894.086853,907.915039 891.034607,908.678040 887.359863,909.028809 
	C886.742249,908.948547 886.290894,908.963135 886.290894,908.963135 
	C884.420166,908.359314 882.549500,907.755493 880.678772,907.151672 
	C885.797791,906.572632 890.475220,904.981934 895.853943,905.648438 
	C904.364380,906.702942 913.130676,905.672302 921.789185,905.555664 
	C936.611145,905.356018 951.433472,905.180725 966.803162,904.973877 
	C967.350708,904.952576 967.217651,905.149536 967.217651,905.149536 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M813.098877,848.188110 
	C812.771790,850.630371 812.110657,852.880310 811.156006,856.128845 
	C810.527222,853.858154 810.127563,852.414795 809.591431,850.478821 
	C808.188171,854.138489 807.429016,856.689819 802.101501,855.229431 
	C797.026306,853.838196 791.040344,855.795349 785.451111,856.233337 
	C784.141968,856.335938 782.817993,856.248535 780.177673,856.248535 
	C781.749207,854.751892 782.267822,854.257996 782.572998,853.967346 
	C781.993103,852.549377 781.400757,851.101013 780.808411,849.652588 
	C782.396606,849.130371 783.972839,848.205627 785.575256,848.157654 
	C791.568848,847.978394 797.571106,848.085999 803.475342,848.085999 
	C803.922729,850.441711 804.215515,851.983337 804.508362,853.524963 
	C805.400818,848.629517 808.069885,846.841492 813.098877,848.188110 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M714.665344,884.386047 
	C710.916687,885.477722 707.416138,886.931396 703.193420,885.071594 
	C701.803772,884.459473 698.968567,887.129333 696.651062,888.375610 
	C699.314270,885.548157 701.269714,882.630981 699.514648,878.241150 
	C698.747437,876.322144 700.109497,873.551819 700.510803,871.131042 
	C699.088135,869.587585 697.547852,867.916565 696.007568,866.245544 
	C696.370972,865.842957 696.734436,865.440369 697.097900,865.037781 
	C698.858521,866.421021 700.619080,867.804260 701.283447,868.326233 
	C706.253113,868.876831 710.207520,869.315002 714.569641,869.913330 
	C715.225525,870.374817 715.473633,870.676025 715.721802,870.977173 
	C716.100830,872.387878 716.479858,873.798523 716.841675,875.928101 
	C716.872009,877.420410 716.919678,878.193970 716.967285,878.967468 
	C716.583008,880.402161 716.198669,881.836914 715.562988,883.656372 
	C715.096252,884.156067 714.880798,884.271057 714.665344,884.386047 
M707.860901,878.515442 
	C707.652954,880.154297 707.445007,881.793152 707.227539,883.507141 
	C713.035461,882.271057 715.513550,879.192627 714.362793,873.680847 
	C708.637817,870.980042 702.286499,871.388367 701.728333,874.831665 
	C701.319092,877.356506 701.618958,880.861877 705.517334,881.433594 
	C706.292908,880.174438 706.965027,879.083252 707.860901,878.515442 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1032.547852,768.256958 
	C1028.365601,768.120911 1024.556763,766.323181 1020.638916,768.348511 
	C1019.697998,768.834961 1016.891357,767.986511 1016.796326,767.433411 
	C1016.426147,765.279114 1016.484192,762.907898 1017.073120,760.811218 
	C1017.275208,760.091858 1019.498230,759.643677 1020.798706,759.641968 
	C1021.562195,759.640930 1022.327087,760.762817 1022.682617,761.049744 
	C1029.319214,757.131836 1036.096069,760.837219 1043.007812,760.360474 
	C1042.348755,762.055298 1041.525513,764.171997 1040.659058,766.400024 
	C1039.621338,765.310608 1038.743286,764.388794 1038.567871,764.204590 
	C1036.437500,765.774536 1034.665283,767.080688 1032.547852,768.256958 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M914.143616,875.294861 
	C916.551086,873.145081 918.704102,871.189697 922.434326,873.508850 
	C923.705750,874.299316 926.406799,872.790466 928.950439,873.338623 
	C927.607117,874.450195 926.263733,875.561707 924.920349,876.673279 
	C925.166931,877.103455 925.413452,877.533691 925.659973,877.963928 
	C927.062439,877.245728 928.945312,876.881409 929.781677,875.746216 
	C932.906555,871.504578 934.952087,870.875305 939.639038,873.420288 
	C940.211121,873.730957 940.756836,874.090149 941.283508,874.407959 
	C942.653259,873.672302 943.911255,872.996704 945.608093,872.085388 
	C945.608093,874.489014 945.608093,876.370544 945.608093,878.750854 
	C941.841370,878.019775 938.361572,876.811951 934.899109,876.859985 
	C932.597778,876.891846 930.368713,879.436951 928.023987,879.591370 
	C923.260010,879.905029 918.439697,879.360840 913.147339,878.884766 
	C912.854736,877.816040 913.057495,877.032715 913.444153,876.125854 
	C913.871826,875.818970 914.043640,875.583130 914.143616,875.294861 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M847.818115,850.761719 
	C841.644287,856.478271 834.835571,857.409790 831.639160,854.102600 
	C828.429077,854.932312 825.666443,855.646362 822.903809,856.360474 
	C822.713257,855.932251 822.522705,855.504089 822.332153,855.075867 
	C822.949219,854.538879 823.566284,854.001831 823.427551,854.122559 
	C822.714111,852.101929 821.266968,850.121704 821.717590,848.777588 
	C822.375488,846.814941 828.746765,848.110535 830.148010,849.574951 
	C835.341736,848.679016 839.767212,847.662476 844.244263,847.320374 
	C845.371094,847.234192 846.663025,849.308167 847.818115,850.761719 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M967.000427,795.576782 
	C966.933411,792.596619 966.933411,790.053284 966.933411,787.509949 
	C967.519836,787.419861 968.106323,787.329773 968.692749,787.239685 
	C969.103149,788.747925 969.826965,790.247314 969.869507,791.765869 
	C970.050720,798.237244 969.810059,804.725830 970.147766,811.185913 
	C970.464783,817.251892 971.526245,823.278931 971.844971,829.344910 
	C972.012695,832.537170 971.292419,835.776062 970.916870,839.560852 
	C964.620667,836.913574 966.157715,832.880005 966.467407,829.250854 
	C966.663757,826.950623 966.989624,824.653748 967.043030,822.350403 
	C967.213501,814.986145 967.304077,807.619629 967.375610,800.253540 
	C967.389282,798.841431 967.175964,797.427002 967.000427,795.576782 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M989.008179,875.453003 
	C989.388733,875.063660 989.769348,874.674255 990.725220,874.278198 
	C993.543701,874.857239 995.786804,875.442993 998.029968,876.028809 
	C998.029968,876.028809 997.979553,876.017395 998.038818,876.263000 
	C998.410095,876.680420 998.722168,876.852173 999.034241,877.023926 
	C999.034241,877.023926 998.983154,877.020874 999.099243,877.302246 
	C999.824280,877.726379 1000.433105,877.869080 1001.041992,878.011841 
	C1001.041992,878.011841 1000.989868,878.013550 1000.947510,878.363770 
	C1001.788757,880.047485 1002.672302,881.380920 1003.487732,882.611511 
	C1001.180298,885.594177 999.077026,888.312866 996.973755,891.031616 
	C996.973755,891.031616 996.977417,890.979980 996.702637,891.120850 
	C995.276917,891.661560 994.126038,892.061401 992.975098,892.461304 
	C992.975098,892.461304 992.660583,892.195801 992.355408,891.886536 
	C991.356628,891.041199 990.663025,890.505188 989.969360,889.969177 
	C989.969360,889.969177 990.020996,889.978821 989.941406,889.723877 
	C989.562866,888.971863 989.263916,888.474731 989.344238,887.849365 
	C990.712341,887.671265 992.187317,888.015442 992.603821,887.501648 
	C994.206543,885.524109 995.631348,883.349976 996.739197,881.063416 
	C996.992371,880.540894 995.793091,878.566833 995.129578,878.495483 
	C993.485352,878.318665 991.764404,878.855713 990.074219,879.106018 
	C990.118652,879.582520 990.163025,880.059021 990.207397,880.535522 
	C991.487671,880.839844 992.767944,881.144226 995.419434,881.774597 
	C992.786011,884.344299 990.898865,886.185791 988.750610,888.009949 
	C988.315125,887.988342 988.140686,887.983948 987.966309,887.979553 
	C987.966309,887.979553 988.016724,887.979675 987.893677,887.692017 
	C986.479614,885.972595 985.188538,884.540771 984.145264,883.383667 
	C985.928955,880.474731 987.468506,877.963867 989.008179,875.453003 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M618.084961,874.430969 
	C618.485840,873.370422 618.997314,872.731506 619.784424,871.945923 
	C620.483276,871.857056 620.906494,871.914917 621.816284,872.082336 
	C622.302979,872.191895 622.719604,872.090332 622.719604,872.090332 
	C622.884888,877.091003 623.050171,882.091675 623.086731,887.544434 
	C621.881409,888.341431 620.804810,888.686340 619.373901,889.014282 
	C618.475647,886.823181 617.931641,884.649109 617.188049,881.677124 
	C613.162781,882.166748 608.757202,883.063660 604.346497,883.090027 
	C602.075073,883.103638 599.794312,881.567261 597.508423,880.727844 
	C597.589050,880.581421 597.632996,880.501709 597.676880,880.421936 
	C597.735962,880.513855 597.794983,880.605774 597.854065,880.697693 
	C604.141113,875.951538 610.740906,879.446167 617.759277,879.522461 
	C617.874023,878.294678 618.034851,876.573669 618.084961,874.430969 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1002.979919,691.022705 
	C1005.032776,689.584473 1007.085571,688.146240 1009.073364,686.352051 
	C1009.008362,685.996094 1008.987000,686.028137 1008.987000,686.028137 
	C1010.977600,685.505920 1012.965942,684.566772 1014.959290,684.556030 
	C1018.216858,684.538574 1021.477600,685.126038 1025.033936,685.488586 
	C1023.813782,686.467773 1022.452454,687.560242 1021.091187,688.652710 
	C1021.419250,689.150757 1021.747314,689.648865 1022.075378,690.146912 
	C1024.164185,688.804688 1026.253052,687.462402 1029.021729,685.683228 
	C1029.668701,685.605164 1031.473389,685.387268 1033.661133,685.448120 
	C1034.025635,686.156189 1034.007324,686.585571 1033.989014,687.015015 
	C1031.123779,688.547241 1028.389160,690.960571 1025.363281,691.408997 
	C1020.607666,692.113770 1015.656006,691.467590 1010.787109,691.447388 
	C1008.305176,691.437073 1005.822632,691.544312 1003.183655,691.305054 
	C1003.026917,691.011658 1002.979919,691.022705 1002.979919,691.022705 
M1009.220032,689.490784 
	C1009.404358,689.506897 1009.588684,689.523071 1009.773010,689.539185 
	C1009.689148,689.436523 1009.605286,689.333862 1009.220032,689.490784 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1021.433472,784.464600 
	C1023.779358,784.453247 1025.771484,784.453247 1027.763672,784.453247 
	C1027.775391,784.578491 1027.786987,784.703735 1027.798706,784.828979 
	C1030.117676,784.438721 1033.115112,783.001831 1034.570068,783.944702 
	C1036.105835,784.940063 1036.170776,788.204956 1036.691162,789.877258 
	C1032.739502,790.560974 1029.033569,791.630066 1025.310425,791.694092 
	C1022.874878,791.736084 1020.414368,790.321899 1016.927795,789.237244 
	C1016.708740,789.407227 1015.472473,790.366333 1013.676514,791.759644 
	C1013.935913,789.429810 1014.140564,787.591125 1014.270203,786.426941 
	C1016.562622,785.770142 1018.821167,785.123047 1021.433472,784.464600 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M808.745239,878.603638 
	C808.620300,877.688171 808.586609,877.097046 808.518799,876.055298 
	C808.715759,874.707581 808.946838,873.810486 809.643372,872.846313 
	C811.407410,874.164185 812.705994,875.549011 813.632446,876.978027 
	C812.887634,877.562866 812.514832,878.103577 812.142090,878.644287 
	C812.763855,878.794922 813.385620,878.945679 814.047119,879.300903 
	C814.209900,879.784790 814.422241,879.948059 814.693726,880.431030 
	C817.156250,883.200989 819.398560,883.698792 821.266663,879.989014 
	C821.749451,879.896973 821.916809,879.706970 822.350159,879.358582 
	C825.163879,876.886902 823.863281,874.892761 822.137695,872.677002 
	C823.359985,871.663696 822.229614,867.830750 825.473633,870.083252 
	C825.803223,872.007690 826.643921,874.011475 826.359985,875.840698 
	C825.691284,880.148254 825.556213,884.143433 829.185120,887.694885 
	C825.645020,884.560059 822.050049,883.914917 817.702271,885.056580 
	C815.909851,885.527222 813.635437,884.161987 811.286316,883.381592 
	C810.549377,882.340027 810.106445,881.548584 809.663513,880.757141 
	C809.387817,880.147400 809.112122,879.537598 808.745239,878.603638 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M967.664612,696.454224 
	C967.825195,696.360291 967.985718,696.266418 968.412659,696.091431 
	C970.503723,699.143738 972.609497,702.151306 974.082153,705.442261 
	C975.940857,709.596069 978.523376,711.822571 983.292114,710.978638 
	C984.635254,710.740845 986.090393,711.136353 988.179077,711.292664 
	C981.355469,715.321045 976.479675,713.513000 972.126526,706.317444 
	C970.412964,707.165894 968.797363,708.614807 967.130371,708.676575 
	C960.651917,708.916870 954.157166,708.716431 947.336182,708.443481 
	C948.454834,706.879639 949.905823,705.552429 951.650635,704.517212 
	C954.270020,707.645142 961.917969,708.036926 966.922668,704.814392 
	C964.356445,704.479248 962.724731,704.318054 961.112549,704.042358 
	C959.108093,703.699646 957.119812,703.262390 955.124512,702.865967 
	C955.124512,702.865967 955.573486,702.867432 956.242004,702.901489 
	C960.924438,702.643005 964.938293,702.350464 969.032715,702.212891 
	C969.234985,702.403198 969.356689,702.438538 969.478394,702.473877 
	C969.353455,702.284729 969.228516,702.095520 969.129395,701.541016 
	C968.658447,699.601807 968.161499,698.028015 967.664612,696.454224 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M953.319885,866.960205 
	C953.841003,866.726746 954.362183,866.493286 955.031555,866.001343 
	C958.843079,866.386414 960.601135,868.315491 959.918457,872.237305 
	C959.638733,873.844666 959.923828,875.547607 959.925537,877.207092 
	C959.932068,883.457214 957.437317,885.394897 951.352295,883.595398 
	C950.362488,883.302673 949.012756,881.935364 948.992920,881.038879 
	C948.891235,876.453125 949.170898,871.858948 949.810059,867.409119 
	C952.430786,870.427063 954.558777,873.303528 956.916626,876.490784 
	C956.916626,873.673096 956.916626,871.421570 956.916626,870.412292 
	C955.205017,868.769531 954.262451,867.864868 953.319885,866.960205 
M951.057739,876.907532 
	C950.695374,880.606384 951.990356,882.044373 956.102966,880.390442 
	C954.621277,877.928467 953.319885,875.766052 951.316223,872.436646 
	C951.170044,874.611328 951.118774,875.374084 951.057739,876.907532 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M792.436279,759.210449 
	C793.651245,760.062012 794.513367,760.816650 794.306335,760.635437 
	C797.435364,760.464355 799.553223,760.348572 801.671082,760.232788 
	C801.579529,761.688110 801.966553,764.083801 801.305481,764.420349 
	C799.421021,765.379639 797.109924,765.500610 796.431580,765.643311 
	C789.659729,766.315674 784.562256,766.821777 779.760315,767.298523 
	C781.267212,765.065186 782.766052,762.843872 784.148071,760.795593 
	C786.713867,760.251709 789.398621,759.682617 792.436279,759.210449 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M717.417358,878.896118 
	C716.919678,878.193970 716.872009,877.420410 716.964355,876.275818 
	C719.896301,875.904724 722.688293,875.904724 726.260315,875.904724 
	C725.027283,874.471191 724.231201,873.545654 723.435181,872.620117 
	C723.709961,872.178345 723.984741,871.736572 724.259521,871.294800 
	C725.796509,872.297791 727.305115,873.348572 728.880188,874.287659 
	C729.993347,874.951294 731.185608,875.937378 732.361450,875.964600 
	C738.485474,876.106812 744.643982,876.316711 750.728882,875.790344 
	C753.014282,875.592590 755.133545,873.474304 757.729858,872.248901 
	C758.366699,872.096252 758.602112,871.926392 758.837524,871.756470 
	C758.837585,871.756531 758.796631,871.696411 758.795471,871.660889 
	C759.010315,871.716919 759.226379,871.808594 759.708069,872.327881 
	C759.398132,873.732178 758.822510,874.708801 758.246826,875.685364 
	C750.593018,880.199158 742.032837,877.024536 734.036377,878.561707 
	C731.709534,879.008972 729.461731,879.866943 726.734985,880.666565 
	C725.124878,877.205322 721.348083,878.556213 717.417358,878.896118 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M918.437866,748.498047 
	C921.509033,747.625183 924.538513,745.664856 928.141602,748.232544 
	C928.825500,749.147766 928.867798,750.221863 929.426331,750.753357 
	C932.550964,753.726562 933.369507,757.201050 931.533813,761.022583 
	C929.829773,764.570007 926.934448,766.490906 922.780212,766.010498 
	C922.325134,765.957825 921.834106,766.216187 921.156860,766.670654 
	C919.440308,766.008057 917.926697,765.005066 916.265015,763.331543 
	C916.262817,759.109680 916.263123,755.543213 916.633911,752.015686 
	C916.762390,750.793457 917.810059,749.667847 918.437866,748.498047 
M928.437256,761.864807 
	C931.024292,758.239014 931.093201,754.841431 927.539062,751.756653 
	C925.114258,749.652100 922.716492,749.275574 920.868042,750.997253 
	C921.812500,753.832581 922.565796,756.094299 923.319153,758.356018 
	C923.072937,758.455688 922.826660,758.555359 922.580444,758.655029 
	C921.272339,756.909912 919.964172,755.164795 918.656006,753.419678 
	C917.866394,753.819153 917.076782,754.218689 916.287170,754.618225 
	C917.256165,757.303284 917.612305,760.509399 919.343689,762.546387 
	C921.702332,765.321289 925.038635,764.412842 928.437256,761.864807 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1101.759888,696.981506 
	C1102.251343,696.465576 1102.801758,696.371216 1103.689087,696.748291 
	C1104.018311,704.174377 1104.133789,711.132263 1103.946899,718.082092 
	C1103.862183,721.233582 1105.152344,722.061890 1108.060059,721.977356 
	C1113.354858,721.823608 1118.658203,721.957214 1123.957886,721.970947 
	C1123.970459,722.153198 1123.983154,722.335449 1123.952881,722.788452 
	C1118.492310,723.205505 1113.074951,723.363525 1107.656982,723.494568 
	C1102.745728,723.613281 1104.245850,727.237976 1103.729736,730.372620 
	C1102.862549,730.757568 1102.358276,730.733521 1101.853882,730.709473 
	C1101.887939,729.380188 1101.913086,728.050598 1101.957886,726.721680 
	C1102.053223,723.895020 1100.907104,723.093323 1097.972046,723.362671 
	C1093.581055,723.765503 1089.125610,723.467346 1084.608765,722.454651 
	C1090.122925,722.454651 1095.636963,722.454651 1101.818726,722.454651 
	C1101.818726,713.549072 1101.818726,705.476013 1101.759888,696.981506 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M971.783081,893.883301 
	C971.321655,894.529175 970.533142,894.884277 969.392517,895.153442 
	C968.297302,881.923340 967.554199,868.779175 966.811157,855.635010 
	C967.520874,855.409485 968.230591,855.184021 968.940308,854.958496 
	C969.587769,856.589233 970.762146,858.209290 970.795715,859.852539 
	C970.988831,869.302856 970.885193,878.758911 970.950073,888.212524 
	C970.962341,890.006958 971.279541,891.799255 971.783081,893.883301 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M814.004578,876.933899 
	C812.705994,875.549011 811.407410,874.164185 809.788086,872.524658 
	C808.745300,870.235718 808.023315,868.201355 807.651855,867.154541 
	C813.152710,867.402832 818.203430,867.630737 823.417603,867.866089 
	C825.000671,866.627808 826.848389,865.182556 828.696106,863.737305 
	C829.133850,864.122925 829.571533,864.508606 830.009277,864.894226 
	C828.726990,866.470642 827.444763,868.047119 825.818115,869.853394 
	C822.229614,867.830750 823.359985,871.663696 821.938721,872.883911 
	C821.461731,873.485229 821.305725,873.596191 821.033325,873.377686 
	C819.280029,873.345581 817.643250,873.642883 815.831787,874.237122 
	C815.771423,876.022217 815.885681,877.510376 815.848145,879.234985 
	C815.372131,879.645996 815.047974,879.820618 814.723816,879.995239 
	C814.422241,879.948059 814.209900,879.784790 814.075195,878.938599 
	C814.043884,877.892578 814.024231,877.413208 814.004578,876.933899 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M718.976746,746.109680 
	C718.976746,746.109680 718.972473,746.513184 718.972900,746.714844 
	C716.205322,747.094910 713.422729,747.539368 710.672607,747.389343 
	C706.589905,747.166504 703.457092,747.578369 704.846619,752.779480 
	C702.133179,752.779480 699.748840,752.779480 697.210999,752.779480 
	C696.029907,752.274780 694.491089,751.617126 692.279663,751.092896 
	C686.712585,750.872437 690.549805,747.919312 690.578918,746.183960 
	C700.349792,746.124512 709.663269,746.117126 718.976746,746.109680 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1036.137207,849.560303 
	C1036.087524,849.992798 1035.622437,849.903931 1035.385742,849.911133 
	C1035.149048,847.281128 1035.149048,844.643982 1035.149048,841.911072 
	C1033.306519,841.475281 1031.931519,841.150146 1029.015503,840.460510 
	C1031.795410,839.441467 1033.173096,838.936401 1033.809082,838.703247 
	C1033.809082,831.445984 1033.809082,824.464966 1033.809082,817.484009 
	C1034.302856,817.426514 1034.796509,817.369019 1035.290283,817.311523 
	C1035.975220,820.745178 1040.437622,823.355591 1036.558105,827.862976 
	C1036.077148,828.421753 1036.816406,830.030518 1036.784180,831.718994 
	C1036.705444,832.855713 1036.832642,833.426208 1036.959839,833.996704 
	C1037.718994,835.587769 1038.478027,837.178833 1039.538574,839.248169 
	C1039.878540,840.129578 1039.916992,840.532654 1039.955322,840.935791 
	C1033.929077,841.472107 1036.914307,846.153992 1036.137207,849.560303 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M900.442871,864.830383 
	C901.300293,863.346741 902.124512,861.411377 903.124512,861.315735 
	C904.877747,861.148254 906.738770,862.108826 908.376770,863.737183 
	C909.920654,862.888611 911.540649,861.266052 912.987061,861.408325 
	C914.443848,861.551575 915.728088,863.448608 917.087891,864.577271 
	C915.654663,866.119934 914.318909,868.842590 912.768616,868.970764 
	C909.009827,869.281616 905.138123,868.466553 901.351440,867.858948 
	C900.932800,867.791870 900.770142,866.129578 900.442871,864.830383 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1037.843994,659.464233 
	C1037.912842,656.169128 1039.339233,655.251587 1042.272827,656.915527 
	C1042.848633,657.242065 1043.484985,657.460388 1044.070068,657.772339 
	C1051.682251,661.831604 1052.304932,659.952698 1047.643188,668.775269 
	C1046.694214,670.571289 1046.293213,674.239380 1043.019653,672.135803 
	C1039.931396,670.151184 1033.704590,671.115601 1035.310547,664.234375 
	C1036.116699,663.965759 1036.524536,663.964233 1037.123535,664.247742 
	C1040.167969,663.748840 1043.020996,662.964905 1045.874023,662.180908 
	C1045.824097,661.456421 1045.774170,660.731873 1045.724243,660.007385 
	C1043.350952,660.007385 1040.977783,660.007385 1038.343018,659.971436 
	C1038.002563,659.778381 1037.923340,659.621277 1037.843994,659.464233 
M1040.850098,668.990112 
	C1045.120117,670.457092 1045.893433,669.779053 1046.312500,664.025574 
	C1044.092041,664.772156 1041.913452,665.435730 1039.828979,666.321167 
	C1039.638428,666.402100 1040.051392,667.903564 1040.850098,668.990112 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M901.440308,800.072876 
	C901.000000,798.132446 900.853333,796.055115 900.706604,793.977783 
	C902.737793,793.953735 904.769104,793.901306 906.800049,793.916382 
	C907.998535,793.925293 909.200684,793.997742 910.393188,794.119690 
	C911.987183,794.282715 913.844116,794.098755 915.065125,794.880554 
	C915.843140,795.378845 916.311646,798.099609 915.809021,798.531128 
	C914.331055,799.800293 912.324463,801.077942 910.477905,801.149963 
	C907.583374,801.262878 904.650574,800.390869 901.440308,800.072876 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1032.325684,711.816284 
	C1033.251465,711.624878 1033.817139,711.428223 1034.666748,711.124268 
	C1036.182739,710.658508 1037.414917,710.300110 1039.695923,709.636658 
	C1038.752808,711.534729 1037.938721,713.173157 1037.126343,714.808167 
	C1037.553101,714.995605 1038.626953,715.467285 1040.354492,716.226135 
	C1034.592651,719.417725 1036.514160,724.795654 1035.243164,729.650452 
	C1034.080200,730.074341 1033.133301,730.078979 1032.186279,730.083618 
	C1032.186279,726.449524 1032.186279,722.815430 1032.186279,718.551880 
	C1031.596558,718.308044 1030.098267,717.688599 1027.372437,716.561646 
	C1029.788940,715.624634 1031.034790,715.141541 1032.616089,714.528381 
	C1031.390869,713.980164 1030.296875,713.490723 1028.229858,712.565979 
	C1030.184082,712.171021 1031.074829,711.991028 1032.325684,711.816284 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1073.869507,812.042419 
	C1068.845947,815.370178 1061.764893,814.365906 1060.054688,809.681763 
	C1058.172119,810.577698 1056.327515,811.455627 1053.926514,812.598389 
	C1053.834473,810.790283 1053.166748,807.310364 1053.730591,807.097778 
	C1056.298828,806.129639 1059.213257,806.080261 1061.455078,805.775208 
	C1062.646484,808.149841 1063.393799,809.639160 1064.140991,811.128540 
	C1065.448853,810.099915 1066.697632,808.981934 1068.081177,808.067810 
	C1069.439087,807.170715 1070.933960,806.480835 1072.992920,806.665466 
	C1071.764893,807.803650 1070.536865,808.941833 1068.554565,810.779175 
	C1070.718262,810.892761 1072.035889,810.961914 1073.646484,811.345276 
	C1073.939453,811.659485 1073.869507,812.042419 1073.869507,812.042419 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1101.560303,730.885864 
	C1102.358276,730.733521 1102.862549,730.757568 1103.745605,730.790894 
	C1104.782227,731.764709 1105.440186,732.729126 1106.098022,733.693604 
	C1106.311646,733.227905 1106.525269,732.762268 1106.739014,732.296570 
	C1108.707764,732.296570 1110.676514,732.296570 1112.645264,732.296570 
	C1112.389038,733.618103 1112.545776,735.684631 1111.789429,736.103821 
	C1110.287720,736.936035 1108.283081,736.860474 1106.486206,737.160034 
	C1106.357788,736.693298 1106.229370,736.226501 1106.100952,735.759766 
	C1104.217407,737.084473 1102.333862,738.409180 1100.403687,739.766724 
	C1098.733276,738.758911 1097.050171,737.743408 1095.225830,736.642761 
	C1095.225830,735.159424 1095.225830,733.518311 1095.225830,730.867798 
	C1096.252686,732.234070 1096.746948,732.891785 1097.376587,733.729431 
	C1098.635620,732.866211 1099.951172,731.964172 1101.560303,730.885864 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1017.676758,808.512329 
	C1016.307861,810.338989 1015.346375,812.152466 1014.004944,814.682434 
	C1014.004944,812.348206 1013.478577,810.130737 1014.191895,808.434692 
	C1014.659668,807.322632 1016.886353,806.459290 1018.383423,806.359863 
	C1023.686646,806.007507 1029.016724,805.910950 1034.332153,805.995911 
	C1035.696899,806.017639 1037.045288,807.061340 1038.401245,807.635498 
	C1037.352905,808.723816 1036.370728,809.888306 1035.235229,810.876648 
	C1034.307495,811.684265 1033.196655,812.281616 1031.818115,813.206116 
	C1031.484253,811.724792 1031.147461,810.230408 1030.567505,807.657410 
	C1029.031738,809.851257 1027.945557,811.402832 1026.781738,813.065369 
	C1025.284302,809.298584 1022.740234,806.837280 1017.676758,808.512329 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M665.224121,901.126831 
	C663.629272,901.704651 662.147583,902.624207 660.781982,903.471802 
	C659.709839,902.610657 658.233154,901.259705 656.538269,900.290283 
	C656.345825,900.180237 654.927124,902.213989 654.378601,902.884827 
	C653.032349,901.829041 651.478577,900.610535 649.219177,898.838623 
	C650.860535,898.489807 653.332825,897.121094 655.062561,897.760681 
	C659.585266,899.432922 663.722290,897.626770 668.022827,897.498657 
	C669.587463,897.452087 671.182190,898.414490 672.763184,898.916565 
	C671.590454,900.013367 670.433228,901.127441 669.239563,902.200867 
	C668.432434,902.926697 667.573792,903.595337 666.372986,904.592773 
	C665.993835,903.448914 665.665588,902.458801 665.224121,901.126831 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1021.979736,651.976196 
	C1017.939392,657.408630 1012.044373,658.037354 1005.935547,658.312195 
	C1003.266357,658.432251 1000.616882,658.989563 997.524536,659.414795 
	C997.090698,659.481323 997.054321,659.506958 997.148132,659.120911 
	C996.984009,657.135437 996.726074,655.536011 996.582336,654.644775 
	C998.635132,653.850586 1000.265076,653.219910 1002.005859,652.546448 
	C1002.725464,653.409119 1003.605530,654.464233 1004.684326,655.757568 
	C1006.213928,654.446716 1007.517273,653.329712 1009.400696,651.715637 
	C1012.702942,658.297852 1016.429626,655.031921 1020.173767,652.699585 
	C1020.718079,652.360535 1021.402039,652.245667 1022.021362,652.027039 
	C1022.021362,652.027039 1021.993225,651.993408 1021.979736,651.976196 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M787.756958,833.925659 
	C785.102722,833.997070 782.767456,833.866516 780.293701,833.728149 
	C780.293701,831.409607 780.293701,829.797729 780.293701,827.626343 
	C783.587280,828.068420 786.733215,828.490662 789.879089,828.912903 
	C789.915527,829.362610 789.951904,829.812256 789.988342,830.261963 
	C790.714233,829.674316 791.355469,828.838013 792.185303,828.555298 
	C793.545532,828.091980 795.051025,827.758057 796.442932,827.896362 
	C796.786987,827.930481 797.481812,830.643860 797.062317,830.998596 
	C795.765991,832.094910 793.609070,833.633789 792.520447,833.192017 
	C790.630005,832.424805 789.385742,831.641357 787.756958,833.925659 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M690.121521,746.235962 
	C690.549805,747.919312 686.712585,750.872437 691.890503,751.180603 
	C691.320984,751.850647 690.468079,752.566345 689.197754,752.955811 
	C688.529480,752.776917 688.278748,752.924255 688.028015,753.071533 
	C688.028015,753.071533 687.993347,753.012085 687.636475,752.958740 
	C685.926636,752.509033 684.245361,752.486816 683.284241,751.643677 
	C680.762390,749.431396 679.714233,750.418518 679.040100,753.079224 
	C679.040100,753.079224 679.006409,753.005920 678.716736,752.969727 
	C676.294067,751.946716 674.161133,750.959839 672.041626,749.596558 
	C671.987488,748.221252 671.919983,747.222412 672.325928,746.144775 
	C678.573364,746.122681 684.347473,746.179321 690.121521,746.235962 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M720.741821,776.021118 
	C725.896790,776.540466 726.944153,778.776062 723.346924,780.655762 
	C722.639648,781.025330 721.706665,780.946716 720.967712,781.276489 
	C720.255981,781.594116 719.660645,782.172729 718.629333,782.913025 
	C718.083313,781.730103 717.587708,780.656372 716.199951,777.649841 
	C714.810913,779.806641 713.977905,781.100037 713.411438,781.979614 
	C712.623596,780.708008 711.590088,779.039795 710.556519,777.371521 
	C709.891418,777.908081 709.226318,778.444702 708.561157,778.981323 
	C708.190430,780.333984 707.819641,781.686707 707.249146,783.768311 
	C706.076050,781.271362 705.153259,779.307129 704.013000,776.879944 
	C709.543274,776.598633 714.930176,776.324585 720.741821,776.021118 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M677.040710,893.048950 
	C676.904480,893.144592 676.768372,893.323425 676.631897,893.323730 
	C670.955261,893.337708 665.278687,893.335693 658.851746,893.335693 
	C662.528625,887.064514 664.970703,886.220520 670.564575,887.315002 
	C672.385803,887.671387 674.467834,886.694397 677.945618,886.043823 
	C675.394592,888.143799 674.013794,889.280518 672.632996,890.417175 
	C672.710388,890.786194 672.787781,891.155273 672.865173,891.524292 
	C673.978088,891.524292 675.091003,891.524292 676.595032,891.804199 
	C677.004333,892.405640 677.022522,892.727295 677.040710,893.048950 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M916.858276,781.616821 
	C914.870361,785.059753 912.694702,785.912109 911.059570,783.848328 
	C906.934753,784.432861 903.741211,784.885376 899.945618,785.423218 
	C902.099121,776.998047 904.840759,776.021057 909.771484,780.602234 
	C912.250793,779.561035 915.174438,774.890320 916.858276,781.616821 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M619.305054,816.963745 
	C618.597290,817.203064 618.096680,817.227966 616.930908,817.160217 
	C615.585815,816.552551 614.905701,816.037415 614.225647,815.522339 
	C614.235474,814.721802 614.245300,813.921265 614.449097,812.606323 
	C616.800537,808.979004 619.423401,808.198730 622.659546,810.492371 
	C624.413147,810.062012 626.166748,809.631714 627.920349,809.201355 
	C628.305298,809.661560 628.690247,810.121826 629.075134,810.582031 
	C627.179993,811.327637 625.284790,812.073242 623.389587,812.818787 
	C623.895569,813.503723 624.401489,814.188599 624.907471,814.873474 
	C626.343262,814.518372 627.779114,814.163269 629.493774,814.095520 
	C629.501038,814.823608 629.229492,815.264343 628.957886,815.705078 
	C627.588440,816.125183 626.218933,816.545349 624.338074,816.632446 
	C622.887573,816.137512 621.948486,815.975708 621.009338,815.813904 
	C620.510315,816.125732 620.011230,816.437500 619.305054,816.963745 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M891.910645,743.726990 
	C890.027283,743.687744 888.181335,743.171570 886.062378,742.424316 
	C885.494141,741.733765 885.198853,741.274292 885.191040,740.396484 
	C885.853210,739.682861 886.589844,739.323486 886.550537,739.101379 
	C884.976746,730.203613 891.009644,729.439270 897.321777,728.959961 
	C897.485168,728.947571 897.649231,728.944519 897.812988,728.937134 
	C897.902649,729.375244 897.992249,729.813416 898.089478,730.586182 
	C896.256165,731.484741 894.415100,732.048767 891.947998,732.804626 
	C891.947998,736.151794 891.947998,739.700867 891.910645,743.726990 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M990.043335,674.018127 
	C990.696411,674.905884 991.809509,675.742798 991.914368,676.691223 
	C992.205750,679.327515 992.406738,682.061340 992.001892,684.656677 
	C991.727173,686.417542 990.523804,689.306091 989.467529,689.431458 
	C986.421448,689.793091 982.122559,691.687500 981.069946,685.511963 
	C981.640198,684.537048 982.286865,684.244019 983.003540,684.335449 
	C983.346924,688.961243 986.007996,687.867188 990.089172,687.346924 
	C987.151245,684.328247 985.057129,682.176575 982.619385,679.978333 
	C981.813538,679.923279 981.351440,679.914795 980.956177,679.530396 
	C981.119568,678.143860 981.216064,677.133362 981.652710,676.082642 
	C982.902893,676.071960 983.812866,676.101562 984.880737,676.444092 
	C986.377075,678.998413 987.715515,681.239746 989.054016,683.481079 
	C989.383789,683.310181 989.713562,683.139282 990.043335,682.968384 
	C990.043335,679.984924 990.043335,677.001526 990.043335,674.018127 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1107.661865,802.066040 
	C1106.701538,803.435181 1105.339111,804.702698 1103.688354,806.074890 
	C1103.399902,792.352112 1103.399902,778.524658 1103.399902,764.697144 
	C1103.649414,764.647278 1103.899048,764.597351 1104.148560,764.547424 
	C1104.250977,768.323120 1104.371094,772.098511 1104.452271,775.874634 
	C1104.619263,783.643433 1104.686646,791.416016 1105.020996,799.177307 
	C1105.062256,800.136719 1106.479980,801.036804 1107.661865,802.066040 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M629.351685,815.717651 
	C629.229492,815.264343 629.501038,814.823608 629.885498,814.147522 
	C630.221802,812.453918 630.445190,810.995728 630.828735,808.491455 
	C634.559326,809.138977 638.101807,809.753845 642.756226,810.561707 
	C642.767517,810.555054 643.998962,809.831665 645.641541,808.866882 
	C645.641541,811.319885 645.641541,813.239746 645.641541,814.886108 
	C643.211304,814.886108 640.812439,814.886108 638.413635,814.886108 
	C636.806030,813.554199 635.361633,811.014404 632.677734,814.828308 
	C632.212524,815.489441 630.748291,815.447510 629.351685,815.717651 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M862.768005,916.548218 
	C861.192688,918.998413 859.854614,920.160828 856.345825,918.603699 
	C853.760010,917.456116 850.055603,918.828857 846.432678,919.111023 
	C846.354919,917.339050 846.271973,915.450195 846.158936,912.874451 
	C849.838623,917.629761 850.540710,917.606323 853.543579,912.955994 
	C854.076416,913.989319 854.518555,914.846741 855.151855,916.074890 
	C857.469482,915.076477 859.274658,910.202026 862.713867,915.009888 
	C862.785583,915.899048 862.762634,916.222595 862.768005,916.548218 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1004.437317,714.717041 
	C1002.410400,713.949280 1000.687683,712.994568 999.080322,712.103821 
	C998.837952,718.695923 994.307068,715.039429 990.936218,714.881958 
	C993.061584,713.742310 995.007141,712.699097 997.739502,711.234009 
	C995.785522,710.448547 994.641479,709.988647 993.497437,709.528748 
	C993.559875,709.068848 993.622314,708.608948 993.684753,708.149048 
	C995.405762,708.327698 997.129761,708.481812 998.846497,708.694153 
	C999.879639,708.822021 1000.895569,709.098022 1001.929871,709.205261 
	C1003.618713,709.380371 1005.316284,709.471985 1007.010132,709.598755 
	C1006.253906,711.242554 1005.497742,712.886353 1004.437317,714.717041 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M933.944580,842.723633 
	C930.074829,842.650574 926.415588,842.843811 922.551514,843.047852 
	C922.551514,841.299316 922.551514,839.562073 922.551514,836.825745 
	C923.603333,838.446167 924.137390,839.268860 924.866638,840.392334 
	C926.459839,839.245117 927.900024,838.208069 929.646362,836.950500 
	C930.238403,837.870850 930.797791,838.740417 931.267639,839.470825 
	C933.706726,838.955933 936.136902,838.442871 938.801636,837.880249 
	C939.141541,842.115845 937.231873,843.162964 933.944580,842.723633 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M716.083008,771.171509 
	C712.517883,773.699524 708.783020,774.826233 704.358276,772.295837 
	C704.358276,770.503967 704.358276,768.451721 704.358276,765.862305 
	C708.312927,766.625732 711.864136,767.311279 715.741638,768.490234 
	C716.072937,769.712952 716.078003,770.442261 716.083008,771.171509 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1074.281128,812.217590 
	C1073.869507,812.042419 1073.939453,811.659485 1073.979248,811.569214 
	C1074.267822,810.515564 1074.253662,809.338074 1074.824463,808.636536 
	C1075.795776,807.442383 1077.010986,806.248230 1078.382935,805.680054 
	C1078.903687,805.464417 1080.229370,806.895325 1081.024902,807.731079 
	C1081.700195,808.440430 1082.140503,809.373596 1083.611572,809.631653 
	C1083.675293,808.202087 1083.739014,806.772583 1083.802734,805.343079 
	C1084.315186,805.344971 1084.827637,805.346863 1085.340088,805.348816 
	C1085.244873,807.367554 1085.723022,809.688354 1084.869751,811.307678 
	C1084.208984,812.561646 1081.923096,813.438843 1080.286133,813.570740 
	C1078.465088,813.717529 1076.560791,812.830078 1074.281128,812.217590 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M720.765015,897.892517 
	C720.121094,900.411438 719.684875,904.207581 718.508240,904.453003 
	C715.336609,905.114319 711.849304,904.262085 708.108582,903.982239 
	C711.156677,895.369690 712.581360,895.166687 718.642212,901.514404 
	C718.642212,899.720093 718.642212,898.224426 718.642212,896.156494 
	C719.517456,896.768677 720.076233,897.159546 720.765015,897.892517 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M760.280273,769.727905 
	C760.747253,775.646667 760.962280,781.545288 761.034729,787.445618 
	C761.060608,789.547302 760.427490,790.936096 758.086426,788.601440 
	C757.471130,781.955383 757.110229,775.343384 756.692566,767.690552 
	C758.510803,768.809387 759.241455,769.258972 760.280273,769.727905 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1075.640137,707.515015 
	C1077.517334,706.698853 1079.176880,706.163940 1080.836304,705.629028 
	C1081.125854,706.781982 1081.939697,708.739624 1081.615479,708.950806 
	C1080.068604,709.958862 1078.243530,710.589966 1076.454468,711.155823 
	C1075.489746,711.460999 1074.214233,711.756592 1073.401489,711.372986 
	C1071.504150,710.477417 1069.801758,709.168640 1068.020508,708.027100 
	C1069.542358,706.748596 1071.064331,705.470093 1073.464355,703.453857 
	C1073.866943,706.409546 1074.056396,707.801575 1074.245972,709.193542 
	C1074.404663,709.148254 1074.563354,709.102966 1074.722168,709.057739 
	C1074.955566,708.637329 1075.188965,708.216858 1075.640137,707.515015 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M620.885132,797.858154 
	C620.885132,784.288574 620.885132,770.718933 620.885132,757.149353 
	C621.534729,757.140564 622.184387,757.131775 622.833984,757.122986 
	C622.833984,770.473938 622.833984,783.824829 622.501587,797.630737 
	C621.741150,798.009888 621.313110,797.934021 620.885132,797.858154 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M621.021790,816.296997 
	C621.948486,815.975708 622.887573,816.137512 623.977844,816.552246 
	C623.748291,818.333862 623.018677,819.868408 623.044189,821.390320 
	C623.199402,830.655884 623.533569,839.918274 623.773376,849.182678 
	C623.784241,849.602661 623.460449,850.031311 622.631958,850.466064 
	C620.566956,839.314636 621.315674,828.014893 621.021790,816.296997 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M984.722900,676.131165 
	C983.812866,676.101562 982.902893,676.071960 981.544800,675.715698 
	C981.389832,673.930664 981.683044,672.472351 982.333618,670.915283 
	C986.634827,667.732727 990.578613,664.648926 994.368713,661.685181 
	C992.063110,658.772827 990.564453,656.879761 989.418884,654.876831 
	C992.199402,656.346924 994.626892,657.926941 997.054321,659.506958 
	C997.054321,659.506958 997.090698,659.481323 997.102539,659.460449 
	C993.809875,663.379272 990.505310,667.318970 986.779297,671.778625 
	C985.812744,673.576111 985.267822,674.853638 984.722900,676.131165 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1021.316162,851.886475 
	C1021.851685,857.030212 1020.830566,857.922302 1016.376099,856.925110 
	C1013.642273,856.313110 1010.867188,855.885071 1007.942627,855.343323 
	C1010.471375,850.062805 1012.510742,849.761597 1014.834656,853.150635 
	C1017.026978,852.614258 1019.028076,852.124634 1021.316162,851.886475 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M761.305786,861.719604 
	C760.973572,864.412354 760.275208,867.040222 759.235352,869.828979 
	C758.610474,864.934753 758.223511,859.882629 758.073486,854.823547 
	C757.932983,850.083984 758.043457,845.336975 758.043457,840.593201 
	C758.529297,840.537170 759.015076,840.481140 759.500916,840.425110 
	C759.683167,841.814819 759.950745,843.199524 760.032654,844.595093 
	C760.365906,850.279846 760.642334,855.967957 761.305786,861.719604 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M677.429260,893.021484 
	C677.022522,892.727295 677.004333,892.405640 676.946045,891.599609 
	C679.322388,889.920471 678.001892,884.728088 682.485168,885.811279 
	C685.093323,886.441528 687.642090,887.317505 690.607666,888.040894 
	C690.997803,887.998779 690.965393,887.966980 690.965454,887.966980 
	C691.937073,889.364563 692.908691,890.762146 694.707886,893.350098 
	C691.643433,892.349670 689.964233,892.195618 688.793335,891.343384 
	C685.621033,889.034363 683.106384,889.694214 680.550171,892.302246 
	C679.988647,892.875122 678.746521,892.780945 677.429260,893.021484 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M988.718506,875.668335 
	C987.468506,877.963867 985.928955,880.474731 984.145264,883.383667 
	C985.188538,884.540771 986.479614,885.972595 987.901123,887.716064 
	C986.432739,887.114990 984.703430,886.370850 983.261047,885.255676 
	C979.839172,882.609741 977.782593,883.042603 976.317749,887.023376 
	C975.531128,889.161194 974.895508,891.354614 973.930420,893.788696 
	C973.409607,894.287109 973.106506,894.384705 972.525757,894.277100 
	C973.154114,891.380676 973.625366,888.339172 975.018616,885.804565 
	C975.927368,884.151245 978.153198,883.203552 979.828125,881.993713 
	C982.678833,879.934753 985.560364,877.918213 988.718506,875.668335 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M620.590332,797.888855 
	C621.313110,797.934021 621.741150,798.009888 622.494385,798.106140 
	C624.129883,799.770874 625.440063,801.415161 626.442749,802.673584 
	C625.658691,802.874939 624.518066,803.167969 622.868591,803.890442 
	C619.706543,806.539124 617.276611,805.997375 615.015198,803.589050 
	C615.043518,803.454590 615.027283,803.177734 615.322632,802.941284 
	C616.073486,801.131775 616.528992,799.558594 616.984558,797.985474 
	C617.459473,797.979431 617.934326,797.973328 618.762085,797.914429 
	C619.508484,797.880920 619.902039,797.900208 620.590332,797.888855 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M614.986877,803.723511 
	C617.276611,805.997375 619.706543,806.539124 622.684570,804.171265 
	C622.964661,805.870850 622.919983,807.719177 622.767456,810.029907 
	C619.423401,808.198730 616.800537,808.979004 614.345581,812.322876 
	C613.851746,810.959473 614.580566,808.652100 611.329407,809.610046 
	C610.884949,809.741028 609.807190,807.722534 609.408447,806.321716 
	C611.525085,805.203918 613.255981,804.463745 614.986877,803.723511 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M656.007568,881.986755 
	C655.063293,882.148132 654.104370,882.501160 653.177917,882.430115 
	C651.359924,882.290710 649.466553,882.145569 647.818481,881.475769 
	C647.406860,881.308472 647.306152,878.661194 647.943787,877.985413 
	C649.256897,876.594116 651.164612,875.764038 652.827576,874.702942 
	C653.117615,875.195923 653.407654,875.688904 653.697693,876.181885 
	C652.294128,877.309814 650.890564,878.437744 649.012024,879.947327 
	C652.618042,880.497620 654.830566,879.885864 655.838196,876.635986 
	C657.366943,877.727417 658.595764,878.743042 660.013428,879.914673 
	C658.383118,880.757935 657.195374,881.372314 656.007568,881.986755 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M923.882324,733.976501 
	C923.251709,730.812073 924.537476,728.935181 927.733887,729.669250 
	C929.151428,729.994751 930.203857,731.910156 931.967590,733.638489 
	C932.328369,733.535767 934.009521,733.057129 935.690674,732.578552 
	C931.374939,737.042969 931.016296,737.118530 926.245483,734.842407 
	C925.115173,734.341736 924.498718,734.159119 923.882324,733.976501 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M746.103333,793.151245 
	C745.969421,793.864990 745.837891,794.252991 745.484863,794.852905 
	C744.397705,793.654968 743.585022,792.209045 742.657104,790.841248 
	C738.190063,784.256226 738.179016,784.263733 729.227051,780.681519 
	C732.805237,778.116760 736.318909,778.302490 738.787720,781.643494 
	C741.430725,785.220276 743.682922,789.085815 746.103333,793.151245 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M757.082703,796.751587 
	C757.729919,795.770020 758.371643,795.320129 759.232788,794.791016 
	C759.452271,800.917908 759.452271,807.124084 759.452271,813.486572 
	C757.232056,811.712158 757.232056,811.712158 757.082703,796.751587 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M863.245361,916.664795 
	C862.762634,916.222595 862.785583,915.899048 862.881592,915.337830 
	C866.405823,915.060547 869.923340,914.681091 873.355957,915.063660 
	C878.790894,915.669434 883.033997,914.360718 885.956299,909.105591 
	C886.290894,908.963135 886.742249,908.948547 886.937561,909.062805 
	C885.250977,914.391296 882.306030,917.829163 875.985168,917.060059 
	C871.946228,916.568665 867.813416,916.847656 863.245361,916.664795 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1006.995117,678.922852 
	C1004.228333,679.763916 1001.497192,680.965149 998.674744,681.244873 
	C997.724976,681.338989 995.609924,679.061523 995.646545,677.919861 
	C995.746521,674.798462 998.108643,673.734131 1002.289856,673.585022 
	C1000.173889,675.540710 998.902161,676.716064 997.630432,677.891418 
	C997.803528,678.318909 997.976562,678.746460 998.149597,679.173950 
	C999.880554,678.562988 1001.611511,677.952087 1003.791565,677.353394 
	C1005.158813,677.884766 1006.076965,678.403809 1006.995117,678.922852 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M656.246582,882.240295 
	C657.195374,881.372314 658.383118,880.757935 660.013428,879.914673 
	C658.595764,878.743042 657.366943,877.727417 656.075500,876.372742 
	C661.219543,874.139771 661.219543,874.139771 665.916199,879.799011 
	C662.741760,880.706116 659.613708,881.599976 656.246582,882.240295 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M923.505493,733.966309 
	C924.498718,734.159119 925.115173,734.341736 925.856689,734.729980 
	C923.516724,735.856995 921.099854,737.175110 918.560059,737.484619 
	C917.672974,737.592651 915.405151,734.949097 915.633606,734.022034 
	C916.316040,731.253540 918.268005,729.348999 922.377075,730.528137 
	C920.578613,731.935730 919.280151,732.952026 917.981689,733.968262 
	C918.106262,734.301880 918.230896,734.635437 918.355469,734.968994 
	C919.946533,734.631348 921.537598,734.293762 923.505493,733.966309 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M758.163696,876.162964 
	C758.822510,874.708801 759.398132,873.732178 760.048950,872.415649 
	C760.738159,872.978271 761.832520,873.856079 761.880249,874.787598 
	C762.057617,878.246826 761.890503,881.723694 761.730225,885.653076 
	C759.013794,886.262756 757.691162,885.248413 757.958557,882.447205 
	C758.142029,880.525330 758.049622,878.577087 758.163696,876.162964 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1055.848877,697.531982 
	C1057.143433,696.839172 1058.354126,696.598694 1059.927002,696.460144 
	C1059.335083,699.686462 1058.381104,702.810913 1057.278931,706.420105 
	C1060.741577,706.878540 1063.052979,707.184570 1065.868774,707.557312 
	C1059.179932,711.214539 1056.065430,709.561829 1055.938354,702.907166 
	C1055.907104,701.265808 1055.824219,699.625305 1055.848877,697.531982 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1003.033325,690.988281 
	C1002.723572,690.963806 1002.406738,690.948547 1002.091309,690.963928 
	C1000.052124,691.063354 998.013367,691.169434 995.974487,691.273315 
	C996.505554,689.062134 997.036560,686.851013 997.567566,684.639832 
	C998.280273,684.621765 998.992920,684.603760 999.705627,684.585693 
	C1000.691711,686.503296 1001.677795,688.420898 1002.821899,690.680664 
	C1002.979919,691.022705 1003.026917,691.011658 1003.033325,690.988281 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M855.351074,908.188416 
	C854.990234,907.612122 854.979980,907.229187 854.960144,906.559448 
	C860.648682,906.272705 866.346802,906.272705 872.044983,906.272705 
	C872.073730,906.855042 872.102539,907.437378 872.131287,908.019714 
	C866.654724,908.140381 861.178162,908.261047 855.351074,908.188416 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1007.295471,679.084229 
	C1006.076965,678.403809 1005.158813,677.884766 1004.054749,677.095886 
	C1002.998474,673.131653 1005.700684,672.976013 1007.907837,673.335083 
	C1008.955200,673.505493 1009.918701,675.297668 1010.556030,676.541687 
	C1011.094543,677.592773 1011.108032,678.912964 1011.477966,680.720703 
	C1010.025879,680.169006 1008.810852,679.707336 1007.295471,679.084229 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M905.861694,878.735168 
	C904.432556,879.226196 903.003418,879.717285 901.574341,880.208374 
	C901.128296,878.080688 900.682190,875.953003 900.274841,874.010010 
	C903.699707,871.577209 905.145203,873.443604 906.700073,875.925659 
	C906.988281,876.054321 906.919434,876.027100 906.906677,876.353149 
	C906.819214,877.105652 906.744507,877.532166 906.477051,878.016663 
	C906.045959,878.232605 905.905090,878.452820 905.861694,878.735168 
M903.605591,877.546387 
	C903.166199,877.524475 902.726868,877.502563 902.287476,877.480652 
	C902.583130,877.585876 902.878845,877.691101 903.605591,877.546387 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M904.707031,805.093079 
	C904.907776,805.381287 904.888794,805.537354 904.940918,805.940552 
	C905.332642,806.578918 905.672180,806.814087 906.011719,807.049194 
	C906.011719,807.049194 906.010864,806.990295 906.017639,807.433838 
	C906.001282,808.758240 905.978149,809.639099 905.955078,810.520020 
	C905.955078,810.520020 905.950317,810.517944 905.956299,810.495544 
	C904.290527,811.297058 902.618774,812.120911 900.947021,812.944824 
	C900.415405,812.229614 899.883789,811.514343 899.352173,810.799133 
	C900.098083,810.179321 900.844055,809.559448 901.634155,808.902954 
	C901.442322,808.483887 900.839478,807.166748 900.236633,805.849548 
	C901.659851,805.501282 903.083130,805.153076 904.707031,805.093079 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M623.062134,871.831055 
	C622.719604,872.090332 622.302979,872.191895 622.136963,872.056152 
	C621.971008,865.711365 621.971008,859.502319 621.971008,853.293335 
	C622.448914,853.296631 622.926758,853.299927 623.404663,853.303284 
	C623.404663,859.392761 623.404663,865.482300 623.062134,871.831055 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1031.930054,730.341370 
	C1033.133301,730.078979 1034.080200,730.074341 1035.384766,730.091675 
	C1035.593018,733.181824 1035.443848,736.250000 1035.294800,739.318115 
	C1034.586670,739.343872 1033.878662,739.369629 1033.170654,739.395447 
	C1032.671631,736.463318 1032.172729,733.531250 1031.930054,730.341370 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M632.611938,800.590576 
	C632.045898,800.153320 631.479858,799.716003 630.913818,799.278748 
	C631.997253,798.786865 633.083496,797.913025 634.163269,797.921082 
	C635.064026,797.927734 636.709534,798.962036 636.714111,799.551453 
	C636.728516,801.403748 636.678467,803.763000 635.590820,804.962463 
	C634.665527,805.982971 632.268860,805.669312 630.523132,805.945923 
	C630.419434,805.554871 630.315735,805.163818 630.212036,804.772827 
	C631.258606,803.890137 632.305115,803.007507 633.665161,801.598633 
	C633.523071,800.911804 633.067505,800.751221 632.611938,800.590576 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1036.932251,663.962769 
	C1036.524536,663.964233 1036.116699,663.965759 1035.403442,663.953735 
	C1033.068970,661.546753 1031.040039,659.153381 1029.116455,656.419189 
	C1029.534790,655.441223 1029.847656,654.804016 1030.080444,653.791077 
	C1032.391235,655.494995 1034.781982,657.574646 1037.508423,659.559204 
	C1037.923340,659.621277 1038.002563,659.778381 1038.207520,660.309509 
	C1037.866333,661.776672 1037.399292,662.869690 1036.932251,663.962769 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M763.315308,764.714844 
	C762.689331,763.320679 762.494385,761.944458 762.494202,760.348755 
	C767.456909,761.103699 772.224854,762.078064 776.992798,763.052368 
	C777.013489,763.217407 777.034180,763.382446 777.054871,763.547424 
	C772.618713,763.942566 768.182556,764.337646 763.315308,764.714844 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M716.315491,771.483765 
	C716.078003,770.442261 716.072937,769.712952 716.068665,768.708984 
	C717.705811,766.819641 719.307373,764.519470 721.780273,766.966125 
	C722.423401,767.602234 722.559509,769.466370 722.110718,770.352112 
	C720.925293,772.691711 719.075806,773.931946 716.315491,771.483765 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M966.549255,773.710754 
	C967.862793,774.121216 969.748901,774.803284 969.801636,775.605774 
	C969.953430,777.918091 969.365417,780.279053 969.068115,782.620850 
	C968.079834,781.874634 966.409668,781.255798 966.241699,780.356384 
	C965.862000,778.323608 966.224121,776.152283 966.549255,773.710754 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M984.880737,676.444092 
	C985.267822,674.853638 985.812744,673.576111 986.614014,672.065857 
	C987.704163,672.534729 988.538025,673.236328 989.707642,673.978027 
	C990.043335,677.001526 990.043335,679.984924 990.043335,682.968384 
	C989.713562,683.139282 989.383789,683.310181 989.054016,683.481079 
	C987.715515,681.239746 986.377075,678.998413 984.880737,676.444092 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M997.325745,891.103516 
	C999.077026,888.312866 1001.180298,885.594177 1003.487732,882.611511 
	C1002.672302,881.380920 1001.788757,880.047485 1000.944092,878.337891 
	C1007.115845,881.574890 1007.225159,882.152954 1002.391113,886.960144 
	C1000.898621,888.444275 999.253174,889.774658 997.325745,891.103516 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M689.102417,899.000549 
	C691.704651,898.346985 694.194397,898.097168 696.684143,897.847412 
	C696.905762,898.446777 697.127441,899.046143 697.349060,899.645508 
	C694.963806,901.010559 692.578552,902.375610 689.875488,903.922546 
	C689.585510,902.443176 689.287720,900.923767 689.102417,899.000549 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M671.852478,746.223511 
	C671.919983,747.222412 671.987488,748.221252 672.029297,749.608704 
	C672.003540,749.997253 671.981323,750.021729 671.651367,750.094360 
	C670.279114,750.799805 669.236755,751.432617 668.194397,752.065491 
	C668.326904,752.321777 668.459473,752.578003 668.591980,752.834290 
	C669.353149,752.834290 670.114380,752.834290 671.065247,753.046387 
	C671.396057,753.385254 671.537170,753.512085 671.678284,753.638855 
	C670.067322,754.102844 668.456421,754.566833 666.486572,754.921021 
	C666.487244,752.272400 666.846741,749.733643 667.581543,746.749390 
	C669.255371,746.277100 670.553894,746.250305 671.852478,746.223511 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M893.311157,765.896240 
	C892.914551,765.462463 892.920044,765.068726 893.315308,764.344116 
	C896.856628,761.983276 900.008240,759.953369 903.159790,757.923584 
	C903.744629,758.156494 904.329346,758.389404 905.047852,758.809937 
	C902.612610,763.382629 898.832703,765.766357 893.311157,765.896240 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1033.993530,687.007629 
	C1034.007324,686.585571 1034.025635,686.156189 1034.038086,685.404663 
	C1036.532471,685.311890 1039.032593,685.541138 1042.052734,685.818115 
	C1042.052734,686.418823 1042.052734,688.176819 1042.052734,689.880615 
	C1040.836792,689.600098 1039.786987,689.357971 1038.188354,688.841309 
	C1036.420654,688.047668 1035.201904,687.528687 1033.983154,687.009644 
	C1033.983154,687.009644 1033.998047,687.000244 1033.993530,687.007629 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M980.889282,679.906372 
	C981.351440,679.914795 981.813538,679.923279 982.669922,680.369141 
	C983.020691,681.854736 982.977112,682.902832 982.933594,683.950928 
	C982.286865,684.244019 981.640198,684.537048 980.994751,685.102905 
	C979.271790,686.086121 977.547607,686.796509 975.424194,687.371643 
	C975.004517,686.996582 974.984070,686.756836 975.051941,685.878906 
	C977.056580,683.462646 978.972961,681.684509 980.889282,679.906372 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M967.189331,849.399841 
	C967.103394,846.221130 967.103394,843.444641 967.103394,840.668152 
	C967.936401,840.664856 968.769470,840.661499 969.602478,840.658203 
	C969.602478,843.788879 969.602478,846.919617 969.602478,850.050354 
	C968.826721,849.967590 968.051025,849.884827 967.189331,849.399841 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M953.161743,866.732056 
	C954.262451,867.864868 955.205017,868.769531 956.916626,870.412292 
	C956.916626,871.421570 956.916626,873.673096 956.916626,876.490784 
	C954.558777,873.303528 952.430786,870.427063 950.167480,867.233154 
	C950.749695,866.606995 951.467224,866.298218 952.497559,865.986389 
	C952.874634,866.156738 952.939087,866.330261 953.161743,866.732056 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1034.411621,851.935547 
	C1034.809082,853.338440 1034.809814,854.803955 1034.786377,856.709106 
	C1031.740967,856.135620 1028.719482,855.122375 1024.682739,853.768677 
	C1028.300171,853.082397 1031.157593,852.540283 1034.411621,851.935547 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M716.026245,870.807800 
	C715.473633,870.676025 715.225525,870.374817 714.939819,869.778320 
	C716.250671,867.742249 717.599121,866.001465 718.947632,864.260681 
	C719.587952,864.800537 720.228271,865.340332 720.868591,865.880127 
	C719.355957,867.466248 717.843323,869.052368 716.026245,870.807800 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M700.934387,790.718506 
	C700.791870,791.338440 700.595581,791.609619 700.150513,791.950317 
	C698.122559,790.600403 696.343506,789.180969 694.564392,787.761536 
	C695.098145,787.135010 695.631897,786.508545 696.165649,785.882080 
	C697.737305,787.377991 699.308960,788.873901 700.934387,790.718506 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M714.717590,884.730957 
	C714.880798,884.271057 715.096252,884.156067 715.677246,884.018433 
	C717.698242,885.252014 719.353699,886.508118 721.009155,887.764221 
	C720.511841,888.388306 720.014526,889.012329 719.517273,889.636353 
	C717.934814,888.116150 716.352295,886.596008 714.717590,884.730957 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M672.015869,749.985107 
	C674.161133,750.959839 676.294067,751.946716 678.749268,752.993652 
	C677.743347,753.400024 676.428711,753.931091 675.082642,754.028503 
	C674.174377,754.094238 673.221863,753.548462 671.983704,753.457520 
	C671.537170,753.512085 671.396057,753.385254 671.259460,752.793213 
	C671.503113,751.559143 671.742249,750.790466 671.981323,750.021729 
	C671.981323,750.021729 672.003540,749.997253 672.015869,749.985107 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M906.179077,878.958069 
	C905.905090,878.452820 906.045959,878.232605 906.807007,878.105103 
	C908.564087,877.417053 909.798401,876.698547 911.016174,875.990234 
	C910.999573,876.000427 910.966919,876.021851 911.301514,876.001343 
	C912.082336,875.978271 912.528687,875.975647 913.046326,876.042053 
	C913.117676,876.111084 913.260132,876.249390 913.260132,876.249390 
	C913.057495,877.032715 912.854736,877.816040 912.809692,878.776123 
	C910.929138,882.398132 908.734375,881.402466 906.179077,878.958069 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M906.975586,876.089111 
	C907.950928,874.813660 908.790161,873.315247 910.035461,872.330139 
	C910.333618,872.094238 911.983643,873.567139 913.007019,874.625122 
	C913.002136,874.996094 913.022827,874.973206 912.722412,875.034607 
	C911.936951,875.404663 911.451965,875.713257 910.966919,876.021851 
	C910.966919,876.021851 910.999573,876.000427 910.605469,875.950562 
	C909.114075,875.942871 908.016724,875.984985 906.919434,876.027100 
	C906.919434,876.027100 906.988281,876.054321 906.975586,876.089111 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M998.117188,875.663147 
	C995.786804,875.442993 993.543701,874.857239 990.875366,874.139832 
	C994.328430,869.842163 995.014099,869.956177 998.117188,875.663147 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M679.419006,753.273315 
	C679.714233,750.418518 680.762390,749.431396 683.284241,751.643677 
	C684.245361,752.486816 685.926636,752.509033 687.666199,752.975952 
	C685.301147,753.186829 682.549500,753.327087 679.419006,753.273315 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M809.101929,886.102539 
	C808.117920,887.074951 807.133911,888.047363 806.149902,889.019775 
	C805.718933,888.647339 805.288025,888.274841 804.857056,887.902405 
	C805.708069,886.697632 806.559082,885.492920 807.785889,884.491577 
	C808.475098,885.164185 808.788513,885.633423 809.101929,886.102539 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M703.917358,903.830078 
	C701.252869,902.143311 703.641846,900.465210 704.671997,898.690918 
	C704.474854,900.312317 704.277771,901.933777 703.917358,903.830078 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1034.072632,687.366760 
	C1035.201904,687.528687 1036.420654,688.047668 1037.831299,688.836670 
	C1037.127197,689.771790 1036.231079,690.437012 1034.965942,691.376160 
	C1034.639282,689.891724 1034.400635,688.807800 1034.072632,687.366760 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M992.832153,892.843018 
	C994.126038,892.061401 995.276917,891.661560 996.727539,891.113831 
	C996.542236,893.564514 996.362732,896.749817 992.832153,892.843018 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M945.641968,740.031982 
	C944.871521,743.127380 943.040833,742.484131 940.922241,740.597168 
	C942.259277,740.168579 943.730957,740.073914 945.641968,740.031982 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M760.438599,759.708740 
	C760.536560,760.754517 760.322021,761.969788 760.063721,763.555176 
	C756.439026,762.481445 757.943298,761.170959 760.438599,759.708740 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1009.023682,685.983887 
	C1007.986755,685.803162 1006.934631,685.634644 1005.882446,685.466125 
	C1005.975098,685.083374 1006.067749,684.700562 1006.160339,684.317810 
	C1007.014038,684.698486 1007.867676,685.079163 1008.854187,685.744019 
	C1008.987000,686.028137 1009.008362,685.996094 1009.023682,685.983887 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M691.013123,888.015381 
	C691.572327,887.430176 692.116211,886.828308 692.660095,886.226440 
	C692.955383,886.499817 693.250732,886.773193 693.546082,887.046509 
	C692.916199,887.357300 692.286316,887.668030 691.310913,887.972900 
	C690.965393,887.966980 690.997803,887.998779 691.013123,888.015381 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M809.619629,881.142517 
	C810.106445,881.548584 810.549377,882.340027 810.972046,883.502258 
	C810.951721,883.873047 810.746216,884.076843 810.205200,883.986084 
	C809.358337,883.823547 809.052551,883.751892 808.746704,883.680237 
	C809.023071,882.962769 809.299377,882.245361 809.619629,881.142517 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1040.185913,840.836731 
	C1039.916992,840.532654 1039.878540,840.129578 1039.878662,839.424927 
	C1040.083496,839.661438 1040.250000,840.199585 1040.185913,840.836731 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1037.107178,833.826477 
	C1036.832642,833.426208 1036.705444,832.855713 1036.782837,832.143311 
	C1037.076416,832.552979 1037.165527,833.104614 1037.107178,833.826477 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M808.555359,883.752930 
	C809.052551,883.751892 809.358337,883.823547 810.130005,883.967712 
	C810.326477,884.665405 810.057190,885.290649 809.444946,886.009277 
	C808.788513,885.633423 808.475098,885.164185 808.113708,884.400513 
	C808.065735,884.106018 808.364014,883.825562 808.555359,883.752930 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M988.964966,887.977600 
	C989.263916,888.474731 989.562866,888.971863 989.945374,889.749878 
	C989.284851,889.588501 988.540771,889.146240 987.881470,888.341797 
	C988.140686,887.983948 988.315125,887.988342 988.741577,887.995300 
	C988.993652,887.997925 988.964966,887.977600 988.964966,887.977600 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M989.928589,890.385986 
	C990.663025,890.505188 991.356628,891.041199 992.301758,891.798096 
	C991.664856,891.613586 990.776306,891.208191 989.928589,890.385986 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1000.893127,877.715881 
	C1000.433105,877.869080 999.824280,877.726379 999.093079,877.277466 
	C999.561951,877.120789 1000.153076,877.270325 1000.893127,877.715881 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M688.278198,753.147827 
	C688.278748,752.924255 688.529480,752.776917 688.890869,752.815430 
	C688.843811,753.075623 688.686096,753.149841 688.278198,753.147827 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M913.855469,875.212280 
	C914.043640,875.583130 913.871826,875.818970 913.444092,876.125854 
	C913.260132,876.249390 913.117676,876.111084 913.082275,875.793335 
	C913.038879,875.308167 913.030823,875.140686 913.022827,874.973206 
	C913.022827,874.973206 913.002136,874.996094 912.990234,875.006897 
	C913.174744,875.054993 913.371033,875.092346 913.855469,875.212280 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M999.049072,876.705444 
	C998.722168,876.852173 998.410095,876.680420 998.036926,876.237183 
	C998.338501,876.106201 998.701233,876.246582 999.049072,876.705444 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M758.597290,871.659851 
	C758.602112,871.926392 758.366699,872.096252 758.033569,872.065430 
	C758.076355,871.764160 758.216736,871.663696 758.597290,871.659851 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M624.039062,932.787842 
	C623.914917,932.762634 623.790222,932.474121 623.840576,932.091980 
	C624.023254,932.173706 624.030823,932.349121 624.039062,932.787842 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M971.878113,900.772827 
	C971.716858,900.731384 971.541321,900.407166 971.598389,900.003906 
	C971.841980,900.113220 971.852844,900.301636 971.878113,900.772827 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M967.258606,905.085999 
	C967.217651,905.149536 967.350708,904.952576 967.283813,904.930542 
	C967.216980,904.908447 967.279480,904.932312 967.266052,904.905518 
	C967.252625,904.878723 967.299561,905.022461 967.258606,905.085999 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M760.135620,794.597717 
	C760.251648,794.464417 760.596619,794.536072 760.932800,794.832275 
	C760.737488,794.972046 760.550964,794.887329 760.135620,794.597717 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M968.245483,912.929016 
	C968.324036,912.658020 968.682373,912.606262 968.822754,912.720459 
	C968.848450,913.242737 968.733765,913.650879 968.619141,914.059021 
	C968.468384,913.772705 968.317627,913.486328 968.245483,912.929016 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M673.671875,99.026840 
	C677.873108,99.011620 681.656311,99.011620 687.134460,99.011620 
	C683.334045,107.048630 680.069397,113.952538 676.804749,120.856445 
	C676.216248,120.606361 675.627686,120.356277 675.039185,120.106194 
	C677.705261,114.115837 680.371338,108.125481 683.572449,100.933090 
	C679.632751,100.933090 676.445068,100.936096 673.259583,100.855072 
	C673.242249,100.854630 673.255005,99.672401 673.671875,99.026840 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2421.711914,1008.370239 
	C2421.976807,1008.382141 2422.119873,1008.515747 2422.262939,1008.649292 
	C2422.038818,1008.596863 2421.814453,1008.544373 2421.711914,1008.370239 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M421.497925,474.020416 
	C421.636871,476.950134 421.775818,479.879822 422.039459,483.221680 
	C420.429535,484.421875 418.694885,485.209930 416.546173,485.997375 
	C413.550262,480.249939 408.583923,484.155762 404.785767,483.486542 
	C404.376251,483.414368 402.723236,487.007629 402.666016,488.930939 
	C402.450409,496.176544 406.782349,498.928253 413.943909,496.306305 
	C414.737000,496.015991 415.216797,494.954681 415.942444,494.377289 
	C417.303864,493.294067 418.599365,491.941040 420.167542,491.337341 
	C426.694855,488.824463 431.515533,490.732208 435.290588,496.799133 
	C437.351685,500.111542 440.672150,502.640320 443.267029,506.432587 
	C443.028687,507.745331 442.948059,508.147980 442.867401,508.550598 
	C435.082550,504.758636 427.297699,500.966705 419.512848,497.174744 
	C419.235992,497.731171 418.959167,498.287567 418.682312,498.843994 
	C430.621674,504.714996 442.561066,510.586029 454.683105,517.075806 
	C453.471924,518.171021 452.078094,518.647461 450.390747,518.951294 
	C445.072266,515.149414 439.542358,516.101074 433.830750,517.001038 
	C433.643982,516.995789 433.270355,516.986694 433.019928,516.742981 
	C432.151520,518.338074 431.533508,520.176880 430.728729,522.104492 
	C430.306732,522.353394 430.136841,522.565796 429.575867,522.885376 
	C425.947266,522.940308 422.775085,522.940308 419.602875,522.940308 
	C419.617493,523.623718 419.632111,524.307068 419.646729,524.990479 
	C433.097168,524.990479 446.547607,524.990479 460.138458,525.234741 
	C460.601807,525.662659 460.924744,525.846252 461.247681,526.029907 
	C461.427368,532.988892 461.607117,539.947876 461.581787,547.400330 
	C461.376709,547.893799 460.976379,547.991516 460.608215,548.010498 
	C459.996185,548.059692 459.752289,548.089966 459.508423,548.120239 
	C456.195953,545.716431 453.840637,541.578125 448.717133,542.394348 
	C448.180756,542.479797 447.505432,540.442688 446.678101,540.229187 
	C445.242859,539.858765 443.638306,540.144226 441.910736,540.657959 
	C441.461517,541.549194 441.206390,541.937073 440.951233,542.324951 
	C433.401794,542.092651 425.852203,541.865845 418.303375,541.614990 
	C417.834076,541.599426 417.062714,541.525940 416.950012,541.259949 
	C413.767548,533.747375 407.730652,539.814453 402.662964,536.878601 
	C402.662964,541.608765 402.614746,544.877502 402.699127,548.142883 
	C402.718597,548.895752 402.976532,549.879456 403.494965,550.340820 
	C405.590759,552.206238 414.930786,550.131348 416.522644,547.756470 
	C417.157227,546.809814 418.446442,545.782043 419.495422,545.716553 
	C426.936768,545.252258 434.393555,544.858643 441.844116,544.880310 
	C443.214478,544.884338 444.578979,546.882446 445.900177,547.923828 
	C448.706635,543.352051 451.367493,543.076599 455.731628,547.696777 
	C457.955414,550.051025 459.799683,552.763733 461.737000,556.016602 
	C461.737030,565.479065 461.877716,574.239502 461.831757,582.999084 
	C461.824280,584.429443 460.992889,585.855469 460.049622,587.204224 
	C455.941132,587.118591 452.325317,587.112183 448.369995,586.985474 
	C445.192169,584.494385 443.740479,587.702515 441.496094,587.851990 
	C441.176880,586.089600 441.037445,584.919678 441.073669,583.432983 
	C441.791992,580.774475 442.696655,578.437439 442.726776,576.089172 
	C442.742218,574.888062 441.233307,572.784668 439.868469,575.976074 
	C439.344696,575.994202 439.135162,575.999939 438.717590,575.849854 
	C436.856110,575.531616 435.202667,575.369080 433.016541,575.171753 
	C432.128387,575.153503 431.772888,575.170044 431.149536,575.303345 
	C429.403900,576.331970 427.926147,577.243835 425.972443,578.109863 
	C424.537140,578.057068 423.577789,578.050110 422.504028,577.718384 
	C419.949921,576.633850 417.510223,575.874207 414.210022,574.846619 
	C414.446106,578.373779 414.571838,580.252075 414.699585,582.160767 
	C416.473389,581.169250 417.935791,580.351746 419.409973,579.527710 
	C421.652557,583.804443 425.497162,584.599792 432.003693,582.922424 
	C432.199860,583.774780 432.246643,584.122559 432.103027,584.879517 
	C431.640045,587.995239 431.367432,590.701721 431.094818,593.408142 
	C431.591187,593.527344 432.087555,593.646545 432.583954,593.765808 
	C433.611664,593.067444 434.639343,592.369141 435.965942,591.582153 
	C437.179474,591.879578 438.094116,592.265625 439.004944,593.132751 
	C439.026703,594.579163 439.052277,595.544434 438.630829,596.598999 
	C433.463165,596.835693 428.742462,596.983154 423.648315,597.148987 
	C421.186371,596.759644 419.097931,596.351990 416.820984,595.649048 
	C416.183289,593.350281 416.511871,590.651550 412.665100,591.243164 
	C410.504395,591.233643 408.726105,591.324097 406.509521,591.392700 
	C405.083710,592.546570 403.292114,593.703735 403.264496,594.901550 
	C403.191467,598.066528 402.790771,602.830750 404.537720,604.037048 
	C406.887238,605.659363 411.177124,604.881104 414.528961,604.400208 
	C415.574036,604.250183 416.278168,601.724670 417.490601,600.119507 
	C419.910980,599.394836 421.979126,598.837280 424.509949,598.339905 
	C431.541595,598.400085 438.110657,598.400085 444.323334,598.400085 
	C446.068634,602.701416 446.879944,606.273560 442.691650,609.083374 
	C440.227783,607.424988 438.626953,603.992371 434.931885,607.057251 
	C434.459961,607.018616 434.270813,607.008362 433.823273,606.765320 
	C432.916473,608.629089 432.268066,610.725708 431.619629,612.822266 
	C432.089355,613.336792 432.559052,613.851257 433.028748,614.365784 
	C436.396942,613.440186 439.765137,612.514587 443.342896,611.827637 
	C444.820587,612.346802 446.088715,612.627197 447.510315,613.425720 
	C447.663727,623.696533 447.663727,633.449219 447.663727,643.201904 
	C448.319641,643.275085 448.975555,643.348328 449.631470,643.421570 
	C449.940918,642.301025 449.889618,640.734192 450.624603,640.140015 
	C453.293213,637.982849 448.371582,632.236206 454.662537,632.363525 
	C454.385590,635.861694 455.445496,637.536011 458.892914,637.133118 
	C460.702850,636.921692 462.550720,637.013855 464.381622,637.006409 
	C471.386017,636.977844 471.523590,636.560608 471.179504,629.727234 
	C470.919220,624.558777 466.348663,627.661682 463.233307,625.365356 
	C464.234100,622.031677 465.234894,618.698059 466.548889,615.708008 
	C467.452606,616.331055 468.089722,616.921631 468.626465,616.841370 
	C473.758972,616.073730 474.032806,619.037292 474.086609,623.048584 
	C474.205475,631.911072 474.805267,640.767151 474.878845,649.816833 
	C473.731018,649.986694 472.259674,650.154419 472.191010,649.916809 
	C470.507080,644.089600 465.758179,646.159058 462.074738,646.135132 
	C459.212952,646.116577 455.047394,644.353271 454.506165,649.773132 
	C454.488129,649.953857 452.220062,649.909851 451.000854,649.598022 
	C450.579224,647.862671 450.157623,646.499878 449.434448,644.162415 
	C448.165405,646.167908 447.576691,647.098206 446.651886,647.928955 
	C444.745331,647.829285 443.174835,647.829285 441.354248,647.829285 
	C441.057495,643.985596 440.794006,640.573120 440.510742,636.903931 
	C437.856018,636.754517 435.413605,636.617004 432.868835,636.106934 
	C431.226013,635.347290 429.685577,634.960327 428.107361,634.100464 
	C427.975250,631.578857 427.880890,629.530090 427.784698,627.442078 
	C424.572266,628.312561 422.031128,629.001160 419.981079,629.556641 
	C421.059937,631.127258 422.029053,632.538147 422.808655,634.223511 
	C421.443390,635.467346 420.267700,636.436707 419.065369,636.998291 
	C418.648468,634.757263 418.450806,631.645691 415.855347,634.308899 
	C414.718445,635.475525 415.761871,638.767029 415.815247,641.093628 
	C416.904510,640.619141 417.993805,640.144714 419.490051,639.677124 
	C421.996582,639.917908 424.096161,640.151917 426.138245,640.758850 
	C426.440216,641.873596 426.799683,642.615356 427.756714,644.590454 
	C427.895630,642.405762 427.945526,641.620728 428.361176,640.833862 
	C430.152679,639.634827 431.578461,638.437622 433.437195,637.335632 
	C439.774719,637.113464 440.530243,641.101562 439.996582,645.409241 
	C439.209595,651.761353 433.767609,647.934387 430.733063,649.590515 
	C429.878082,648.066589 429.186066,646.833069 428.117065,645.373413 
	C415.579926,644.456909 415.356384,644.660400 416.828735,655.467651 
	C417.861053,656.745544 418.855988,658.004150 420.084900,658.304138 
	C421.962616,658.762451 424.001953,658.558777 425.992584,658.922363 
	C426.300720,659.683472 426.588928,660.158386 426.877136,660.633301 
	C427.258972,659.935486 427.640808,659.237671 428.356995,658.335327 
	C429.782166,653.641968 432.625824,652.159668 437.048706,653.621094 
	C439.252930,657.229553 440.837952,656.657288 442.387604,653.140747 
	C443.846954,654.446838 444.924377,655.846375 446.063049,657.557434 
	C446.747040,658.930420 447.369781,659.992004 447.580566,661.052979 
	C444.665161,661.905823 442.161774,662.759277 439.347565,663.780273 
	C436.200470,667.143860 435.286896,670.681335 437.085327,675.127869 
	C437.031250,676.387329 437.017822,677.226562 436.560486,678.104858 
	C433.740112,678.150391 431.363647,678.156677 428.979065,677.735962 
	C427.466339,672.701355 423.720337,675.788696 421.073883,675.217590 
	C420.646118,675.125244 419.693420,677.465393 418.596436,678.766846 
	C417.171692,681.168030 415.386200,683.436829 415.238434,685.807739 
	C414.820618,692.511169 415.074036,699.256409 414.828613,706.097229 
	C414.403229,706.490784 414.212219,706.772278 414.046936,707.036987 
	C414.072693,707.020142 414.094635,707.077576 413.708923,707.004028 
	C410.573883,706.291016 407.824554,705.651428 404.950836,704.710327 
	C402.879944,703.263367 400.933472,702.118042 398.986969,700.972656 
	C398.986969,700.972656 398.994812,701.005310 398.943756,700.748230 
	C398.589233,700.318726 398.285736,700.146240 397.982269,699.973816 
	C397.982269,699.973816 397.994751,700.005310 397.926514,699.716248 
	C397.234802,698.942749 396.611359,698.458252 395.987946,697.973816 
	C395.987946,697.973816 395.993835,698.004822 396.007812,697.625488 
	C394.685089,694.068359 393.348389,690.890625 392.011719,687.712830 
	C392.008728,687.325806 392.005707,686.938843 391.968475,685.846069 
	C392.378815,680.739319 390.817535,678.251404 385.840179,678.682007 
	C385.404999,678.334900 385.105103,678.198303 384.562500,677.724121 
	C384.259155,676.230408 384.150635,675.074463 384.144897,673.918030 
	C383.994110,643.554016 383.853973,613.189880 383.804626,582.383301 
	C384.465027,580.932007 385.032837,579.923157 385.600647,578.914307 
	C386.946625,579.934021 388.292572,580.953796 389.566376,581.918884 
	C389.906403,581.271912 390.274139,580.572266 391.141724,579.873657 
	C391.973480,579.844849 392.305389,579.815063 392.787781,580.058594 
	C393.078369,580.956787 393.218414,581.581543 393.364044,582.231018 
	C398.987274,577.858215 398.987274,577.858215 397.952637,574.809082 
	C399.107269,573.805603 400.218628,572.859192 401.408264,572.747620 
	C406.412506,572.278503 411.469391,572.283203 416.444489,571.634766 
	C418.375824,571.383057 420.141663,569.861816 422.117920,569.176758 
	C422.578918,569.638489 422.904877,569.842163 423.303406,570.312012 
	C423.751801,571.008118 424.202515,571.853760 424.491394,571.801697 
	C426.569305,571.427124 428.614380,570.870544 431.333527,570.210632 
	C431.333527,569.423401 431.333527,567.456726 431.333527,565.311401 
	C429.284851,565.962402 427.643005,566.484131 425.894562,566.786072 
	C425.590485,566.291382 425.330231,566.103333 424.747192,565.760681 
	C421.871765,565.423401 419.256470,565.327576 416.119080,565.181396 
	C415.247406,565.137268 414.897766,565.143494 414.204407,565.177307 
	C411.032806,565.509583 407.709930,568.879150 405.350281,562.910339 
	C408.443756,562.185852 411.429504,561.486572 414.824463,560.876160 
	C416.454132,561.621094 417.674622,562.277161 419.341095,563.172974 
	C419.341095,559.796875 419.341095,557.002991 419.341095,554.783997 
	C416.593231,555.621460 414.336761,556.309204 411.853149,556.690247 
	C408.930054,555.981873 406.234100,555.580200 403.058746,555.144165 
	C402.260712,555.117737 401.942047,555.125549 401.265991,555.084106 
	C394.936523,556.563782 393.874115,558.688538 397.099579,563.101501 
	C396.136261,564.874329 395.087341,566.369446 394.038422,567.864563 
	C393.864563,567.343994 393.690704,566.823425 393.516876,566.302856 
	C391.216370,565.811340 388.915833,565.319824 386.486145,564.800720 
	C386.205261,568.180115 386.001709,570.629395 385.690552,574.372986 
	C389.583008,573.456482 393.068756,572.635742 396.844971,572.064392 
	C397.422058,573.272156 397.708710,574.230530 397.659851,575.433228 
	C392.608368,575.700989 387.892365,575.724365 383.140869,575.296509 
	C383.105377,558.638245 383.105377,542.431274 383.105377,526.230469 
	C386.740265,525.837830 389.772461,525.510254 392.804657,525.182739 
	C392.789703,524.644043 392.774750,524.105408 392.759796,523.566711 
	C389.629974,523.299683 386.500153,523.032654 382.891785,522.724792 
	C382.891785,508.792664 382.891785,495.213043 383.261597,481.733398 
	C386.712311,483.289032 389.793152,484.744720 392.874023,486.200439 
	C393.122498,485.695496 393.371002,485.190552 393.619476,484.685608 
	C391.068054,483.344513 388.253082,482.330566 386.050446,480.556396 
	C384.357727,479.192902 383.436157,476.872070 382.417511,474.654175 
	C382.520813,468.346344 382.378265,462.361298 382.656738,456.423126 
	C386.287781,458.179993 389.497772,459.890045 392.297485,461.805847 
	C391.266479,464.210693 390.501587,466.384918 390.115631,468.624420 
	C390.004211,469.270996 390.982513,470.105377 391.461212,470.853638 
	C392.328857,470.161621 393.196503,469.469574 394.461151,468.805420 
	C399.178467,468.443726 403.718781,471.470825 407.957672,468.385101 
	C412.034027,470.509186 415.993195,472.245361 420.210144,473.989319 
	C420.811249,474.004883 421.154572,474.012665 421.497925,474.020416 
M403.474701,580.512939 
	C404.379181,581.408020 405.220093,582.915466 406.206848,583.017944 
	C407.108582,583.111511 409.087219,581.523010 408.987793,580.957764 
	C408.675140,579.179382 407.607819,577.533752 406.412201,574.916321 
	C405.167206,577.131042 404.399506,578.496704 403.474701,580.512939 
M403.320862,625.587097 
	C401.972778,625.587097 400.624695,625.587097 399.276611,625.587097 
	C399.265045,626.509094 399.253448,627.431091 399.241852,628.353149 
	C403.247223,628.904663 407.252594,629.456177 411.257965,630.007751 
	C411.360657,629.470032 411.463379,628.932373 411.566071,628.394653 
	C408.975067,627.642761 406.384033,626.890808 403.320862,625.587097 
M394.947662,674.617859 
	C394.010620,673.707397 393.073608,672.796875 392.136566,671.886414 
	C391.668365,672.297729 391.200134,672.709045 390.731934,673.120361 
	C391.707794,674.605286 392.683655,676.090271 393.659546,677.575256 
	C394.100830,676.840088 394.542114,676.104919 394.947662,674.617859 
M399.497437,655.825745 
	C399.868256,655.137024 400.239075,654.448242 400.609863,653.759521 
	C400.043091,653.609192 399.476288,653.458923 398.909485,653.308655 
	C398.879700,654.095276 398.849915,654.881958 399.497437,655.825745 
M414.431793,676.703735 
	C414.704437,676.989319 414.977081,677.274902 415.249756,677.560547 
	C415.367950,677.447510 415.595154,677.242432 415.588593,677.234619 
	C415.334167,676.933350 415.058411,676.650146 414.431793,676.703735 
M410.608826,675.797791 
	C410.214294,675.793579 409.819794,675.789429 409.425262,675.785217 
	C409.423767,675.948914 409.411285,676.254883 409.422333,676.255737 
	C409.814911,676.286072 410.209656,676.287720 410.608826,675.797791 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M383.712006,582.825806 
	C383.853973,613.189880 383.994110,643.554016 384.144897,673.918030 
	C384.150635,675.074463 384.259155,676.230408 384.688629,677.950439 
	C385.363464,678.640381 385.669434,678.766541 385.975433,678.892639 
	C390.817535,678.251404 392.378815,680.739319 391.961792,685.555908 
	C386.265137,682.137817 379.866577,684.623657 373.789337,684.200684 
	C373.276062,684.164917 372.143066,686.379089 372.089294,687.583374 
	C371.890076,692.041443 372.223419,696.529541 371.886993,700.970764 
	C371.804871,702.054810 369.935028,703.003479 368.450348,703.728577 
	C368.008972,703.274719 368.004364,703.106689 367.999725,702.938599 
	C368.598175,701.449646 369.626434,699.986816 369.719025,698.467041 
	C370.077698,692.581787 370.138306,686.678345 370.776062,680.399048 
	C373.133087,679.699890 376.014648,680.960388 375.975037,677.413513 
	C375.936981,674.002808 375.592865,670.595581 375.383240,667.186829 
	C375.235931,636.116272 375.159332,605.045105 374.909821,573.975342 
	C374.795044,559.683777 374.177490,545.395264 374.144470,531.104553 
	C374.134979,526.996460 373.088470,525.533264 368.850037,525.727661 
	C361.776886,526.052124 354.678009,525.816223 347.589966,525.816223 
	C347.546967,525.324219 347.503967,524.832214 347.460968,524.340210 
	C349.042511,524.238220 350.623199,524.067322 352.205780,524.046753 
	C357.698334,523.975098 363.191895,523.981018 368.684387,523.907471 
	C372.344452,523.858521 374.264008,522.634216 374.158142,518.280334 
	C373.838745,505.140869 374.011993,491.989410 374.377228,478.403015 
	C375.511902,478.456055 376.262512,478.948761 376.974915,479.948242 
	C377.008392,494.640137 377.080017,508.825287 377.151642,523.010437 
	C377.886322,523.003723 378.621002,522.997070 379.355682,522.990356 
	C382.132935,508.800537 379.679535,494.449097 380.120392,480.253784 
	C381.230164,480.763885 382.060974,481.198669 382.891785,481.633453 
	C382.891785,495.213043 382.891785,508.792664 382.891785,522.724792 
	C386.500153,523.032654 389.629974,523.299683 392.759796,523.566711 
	C392.774750,524.105408 392.789703,524.644043 392.804657,525.182739 
	C389.772461,525.510254 386.740265,525.837830 383.105377,526.230469 
	C383.105377,542.431274 383.105377,558.638245 382.766113,575.381348 
	C381.874725,576.667419 381.322601,577.417297 380.400299,578.219849 
	C379.306702,581.549744 377.966339,584.825195 377.956879,588.104553 
	C377.876312,616.035645 378.002991,643.967590 378.181335,671.898560 
	C378.198578,674.601868 378.984711,677.300354 379.413910,680.001038 
	C380.062561,679.948730 380.711212,679.896484 381.359833,679.844177 
	C381.539642,677.854614 381.894073,675.863220 381.872589,673.875854 
	C381.614410,649.987183 381.255676,626.099548 381.030914,602.210632 
	C380.970093,595.748657 381.270844,589.283264 381.694641,582.818787 
	C382.559753,582.820679 383.135895,582.823242 383.712006,582.825806 
M377.024658,560.494141 
	C377.024658,565.525085 377.024658,570.556030 377.024658,575.586975 
	C377.702911,575.730347 378.381165,575.873779 379.059418,576.017212 
	C379.678802,574.490784 380.786133,572.978760 380.831604,571.435364 
	C381.011902,565.320007 380.931183,559.193481 380.813751,553.073608 
	C380.655212,544.811218 380.434998,536.548523 380.068024,528.293640 
	C380.021698,527.251221 378.927185,526.255432 378.318268,525.238098 
	C377.902191,525.395630 377.486145,525.553223 377.070068,525.710754 
	C377.070068,536.972839 377.070068,548.234924 377.024658,560.494141 
M373.012634,681.148071 
	C373.168304,681.408325 373.276001,681.812805 373.494080,681.885193 
	C373.684296,681.948486 373.998901,681.637329 374.258545,681.491638 
	C373.999146,681.347778 373.739746,681.203857 373.012634,681.148071 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M428.987152,678.163025 
	C431.363647,678.156677 433.740112,678.150391 436.894531,678.105347 
	C437.490295,676.947021 437.308136,675.827271 437.125977,674.707581 
	C435.286896,670.681335 436.200470,667.143860 439.655792,664.004395 
	C440.530151,664.739563 440.785492,665.418213 440.672974,666.219360 
	C440.162811,669.089783 439.094360,672.302185 440.166931,674.440857 
	C441.048584,676.198792 444.472412,676.681763 447.887421,678.239868 
	C445.748749,674.965576 444.539703,673.114563 443.186096,671.042175 
	C445.519836,670.544983 446.759888,670.280762 448.024109,670.391724 
	C447.861176,675.618835 450.004211,675.270996 453.060730,673.380737 
	C452.920776,675.116394 452.732880,676.462891 452.423065,678.683472 
	C454.781097,677.963074 456.502930,677.437073 458.452087,677.106567 
	C458.848969,678.075928 459.018524,678.849792 458.714417,679.731323 
	C447.031952,679.838989 435.823151,679.838989 424.614319,679.838989 
	C424.598053,680.362671 424.581757,680.886292 424.565460,681.409973 
	C425.128296,681.577454 425.694153,681.899902 426.253510,681.888977 
	C433.637146,681.744385 441.025116,681.666931 448.400635,681.326599 
	C452.259613,681.148499 456.096344,680.488220 460.211365,680.215698 
	C460.687561,681.196289 460.895569,682.006897 460.632690,682.886353 
	C448.243286,682.955200 436.324829,682.955200 424.081207,682.955200 
	C424.081207,689.570251 424.329773,695.228333 424.003662,700.853088 
	C423.736389,705.463135 424.917236,707.222534 430.024597,707.149048 
	C466.475586,706.624512 502.931366,706.433533 539.385376,706.109009 
	C540.472900,706.099243 541.557678,705.786499 543.137451,705.537048 
	C542.698364,699.899780 542.281494,694.548279 541.907043,688.912964 
	C541.960205,688.437988 541.970947,688.246948 542.321655,687.989502 
	C544.293274,686.968933 545.924988,686.014832 547.892578,685.234741 
	C551.969360,691.680115 550.789490,698.607117 550.679810,705.541870 
	C549.852844,704.646057 549.233215,703.572144 548.631958,702.530029 
	C545.443359,705.657227 545.502014,708.996704 547.788452,712.813110 
	C547.386169,715.100769 547.251404,717.050964 547.057617,719.450928 
	C547.082947,723.146301 547.167175,726.391846 546.807861,729.744385 
	C544.844360,729.795471 543.324402,729.739563 541.804504,729.683716 
	C543.147461,726.546570 545.080200,723.522827 545.709534,720.248535 
	C546.987366,713.600220 545.339050,711.925415 538.786682,711.979004 
	C501.074219,712.287415 463.361938,712.623169 425.649048,712.871033 
	C423.791931,712.883240 421.929962,712.159363 419.965393,711.077271 
	C420.631927,709.544006 422.054169,708.726196 422.073120,707.877136 
	C422.246033,700.133179 422.174713,692.383789 422.174713,684.635986 
	C421.904541,684.626831 421.634369,684.617676 421.364197,684.608521 
	C421.152191,687.095276 420.940186,689.582031 420.728180,692.068787 
	C420.555939,692.064758 420.383698,692.060669 420.211456,692.056580 
	C420.073151,689.187134 419.934845,686.317688 419.796539,683.448242 
	C419.249420,683.407043 418.702332,683.365845 418.155212,683.324646 
	C417.981628,684.651489 417.650055,685.979675 417.661316,687.304932 
	C417.714966,693.627808 417.926331,699.950073 417.901855,706.271851 
	C417.898407,707.164124 416.905243,708.052490 416.370178,708.942688 
	C415.611694,708.320984 414.853180,707.699280 414.094666,707.077576 
	C414.094635,707.077576 414.072693,707.020142 414.307739,706.960449 
	C414.716187,706.595520 414.889587,706.290344 415.063019,705.985168 
	C415.074036,699.256409 414.820618,692.511169 415.238434,685.807739 
	C415.386200,683.436829 417.171692,681.168030 418.947266,678.829895 
	C422.785767,678.592468 425.886475,678.377747 428.987152,678.163025 
M536.336670,708.965271 
	C531.522278,708.965271 526.707886,708.965271 521.893494,708.965271 
	C521.899536,709.220459 521.905579,709.475586 521.911560,709.730774 
	C528.296265,709.730774 534.680969,709.730774 541.065613,709.730774 
	C541.070190,709.475830 541.074768,709.220947 541.079285,708.966003 
	C539.792542,708.966003 538.505737,708.966003 536.336670,708.965271 
M464.937805,709.076233 
	C463.963684,709.184814 462.989563,709.293396 462.015442,709.401978 
	C462.035156,709.547180 462.054901,709.692322 462.074615,709.837524 
	C466.088409,709.837524 470.102173,709.837524 474.115936,709.837524 
	C474.117096,709.582153 474.118256,709.326843 474.119415,709.071472 
	C471.348053,709.071472 468.576660,709.071472 464.937805,709.076233 
M458.081299,709.248596 
	C457.167694,709.383667 456.254120,709.518677 455.340515,709.653687 
	C455.466492,710.087402 455.592468,710.521118 455.718445,710.954834 
	C456.690857,710.520325 457.663300,710.085815 458.081299,709.248596 
M450.564087,709.397949 
	C450.564087,709.397949 450.443329,709.617249 450.443329,709.617249 
	C450.443329,709.617249 450.704376,709.580261 450.564087,709.397949 
M421.504395,681.657349 
	C421.504395,681.657349 421.609131,681.455933 421.609131,681.455933 
	C421.609131,681.455933 421.404449,681.490417 421.504395,681.657349 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M245.080414,607.126892 
	C244.843338,608.764038 244.463821,610.395325 244.397079,612.039429 
	C244.227020,616.228760 244.650558,620.043518 250.326202,619.030762 
	C250.608871,619.589172 250.870514,619.874146 250.887238,620.172913 
	C251.339325,628.240051 251.335419,628.255066 243.247345,628.423767 
	C240.639679,628.478149 238.029892,628.432312 235.153671,628.432312 
	C235.153671,625.024902 235.153671,622.251953 235.153671,619.489563 
	C242.411148,618.028564 242.367630,618.030212 242.094162,610.829651 
	C241.959244,607.277649 241.928848,603.716064 242.015701,600.162781 
	C242.108002,596.386902 242.347656,592.638428 235.796921,591.265869 
	C238.837463,590.380859 240.119446,590.007690 241.992233,589.462585 
	C241.651901,588.056152 241.714233,586.226379 240.846451,585.138672 
	C238.676422,582.418518 236.371841,579.641296 233.591888,577.612183 
	C229.775009,574.826355 227.489853,576.854614 226.834915,582.885620 
	C225.770020,579.774719 225.318237,578.454895 224.721939,576.712891 
	C222.538330,576.712891 220.237900,576.712891 217.531281,576.408142 
	C217.086685,575.802124 217.066864,575.499634 217.065628,575.195984 
	C219.542252,575.079895 222.018875,574.963806 225.193390,574.815063 
	C223.926422,573.838806 223.126846,573.222656 222.327286,572.606567 
	C222.475555,572.188477 222.623840,571.770386 222.772125,571.352295 
	C225.820206,572.562439 229.025024,573.487854 231.875473,575.057007 
	C235.208557,576.891846 238.232666,579.288025 242.241379,582.020508 
	C241.770813,574.176941 241.333435,566.886414 240.767960,557.460693 
	C243.576782,552.705017 240.462921,544.987671 242.045471,537.288086 
	C242.479889,536.885742 242.917877,536.776794 242.917877,536.776794 
	C242.917877,536.776794 243.365128,536.837463 243.365128,536.837463 
	C243.543823,541.963562 243.761734,547.088684 243.891678,552.216003 
	C244.070480,559.270569 244.881012,560.029602 252.083847,559.718872 
	C252.897568,559.683716 253.719559,559.840271 254.576126,559.911072 
	C254.456650,560.278381 254.399780,560.672485 254.316544,560.678162 
	C244.104919,561.371155 244.099503,561.369629 244.029053,571.855347 
	C244.007996,574.988770 244.025787,578.122498 244.025787,581.266541 
	C248.873901,577.886475 253.505905,574.579041 258.260406,571.458130 
	C258.758942,571.130920 259.980652,571.905273 260.574219,572.733154 
	C260.520782,574.197205 260.759979,575.096619 260.999146,575.995972 
	C260.398102,577.453674 259.797058,578.911438 259.512543,579.601562 
	C257.291382,578.604065 253.810883,576.206604 253.088776,576.859619 
	C249.773926,579.857178 246.275787,583.332520 244.692474,587.362793 
	C243.262680,591.002319 244.164322,595.583374 244.174271,599.748901 
	C244.177780,601.217712 244.585281,602.685547 244.919037,604.904968 
	C245.046616,606.146362 245.063522,606.636597 245.080414,607.126892 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M260.973083,440.832642 
	C259.403290,443.668335 257.172058,446.345154 256.442688,449.382629 
	C255.652878,452.671692 256.263733,456.297089 256.200592,459.764008 
	C256.200592,453.829498 256.200592,447.894989 256.200592,442.082123 
	C250.838409,440.953156 243.258163,447.459076 243.138947,452.826050 
	C243.024506,457.976990 243.079956,463.131653 242.734192,468.697144 
	C242.082001,469.217896 241.753937,469.225800 241.423431,469.133331 
	C241.166595,463.995453 240.929184,458.856506 240.646286,453.720062 
	C240.449463,450.146576 233.759476,441.758881 230.313446,441.641632 
	C228.540314,441.581329 226.699814,443.501862 224.727463,444.619232 
	C223.899872,440.983246 221.611816,439.921143 218.573380,443.517609 
	C216.461243,442.788361 214.719467,441.727661 212.935471,441.651123 
	C205.479782,441.331146 198.003448,441.071472 190.552734,441.323486 
	C188.438629,441.394989 185.904114,442.826782 184.417953,444.428528 
	C182.209732,446.808502 175.129501,449.135284 172.408005,447.357666 
	C171.665848,446.872925 171.238815,445.463440 171.184769,444.450745 
	C171.026291,441.481110 171.128342,438.497589 171.128342,435.599640 
	C178.382782,432.732422 182.965363,433.827179 184.583160,438.517639 
	C195.177414,438.962036 205.916641,442.706329 216.891190,437.519287 
	C218.015213,439.429352 220.080688,441.558075 223.558334,438.820007 
	C222.642349,438.177582 221.840485,437.615234 221.038635,437.052887 
	C221.309021,436.597412 221.579407,436.141907 221.849792,435.686432 
	C228.691483,439.193024 236.485260,440.961273 241.077454,448.030853 
	C246.655029,445.507782 247.975830,437.328308 255.824982,439.304871 
	C256.110443,437.070160 256.326324,435.380371 256.465973,434.287140 
	C257.693024,434.689819 259.401825,435.250610 261.273254,435.864746 
	C260.996887,436.788971 260.724518,437.699768 260.697388,439.183929 
	C260.952789,440.115753 260.962921,440.474182 260.973083,440.832642 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M359.576172,730.965088 
	C373.002808,730.780762 386.431183,730.664307 399.853821,730.323059 
	C401.462646,730.282166 403.511292,729.409729 404.498627,728.214478 
	C405.462769,727.047180 405.764191,724.894409 405.482788,723.331604 
	C405.352814,722.609863 403.178101,721.973694 401.867523,721.813354 
	C399.911652,721.574036 397.900146,721.789612 395.446686,721.604126 
	C394.983063,720.942749 394.985046,720.488770 394.987000,720.034912 
	C396.646515,720.013062 398.325226,720.144897 399.962006,719.940735 
	C404.996613,719.312744 408.300629,720.229675 407.851532,726.525269 
	C407.600281,730.047180 410.460541,730.382507 413.450378,730.349304 
	C445.739624,729.990173 478.029968,729.726196 510.320129,729.453674 
	C511.977722,729.439636 513.636597,729.583496 515.661133,730.091248 
	C516.104919,730.845947 516.108398,731.164001 515.579956,731.597290 
	C511.992157,731.776611 508.845642,732.078674 505.735077,731.862854 
	C495.528381,731.154663 485.721832,731.393433 478.778015,740.873230 
	C478.281830,740.652405 478.087372,740.555237 477.892944,740.458069 
	C479.882294,737.947510 481.871643,735.437012 484.493195,732.128601 
	C476.374146,732.128601 468.998901,732.071899 461.624817,732.140198 
	C445.508514,732.289490 429.391266,732.426514 413.278564,732.767151 
	C410.738129,732.820862 408.219635,733.912720 406.011688,734.446899 
	C403.142395,733.960022 400.392395,733.089905 397.644287,733.095825 
	C384.686188,733.123657 371.728607,733.391541 358.279022,733.311646 
	C358.383514,732.354614 358.979828,731.659912 359.576172,730.965088 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M238.650757,746.011536 
	C250.934875,746.011536 263.218994,746.011536 275.014923,746.011536 
	C276.142090,751.147583 277.118439,755.596436 278.062073,760.504761 
	C278.029358,760.964233 277.878906,761.090027 277.588440,760.894653 
	C276.360840,758.419800 275.851288,755.787048 274.351593,753.972534 
	C273.146057,752.514038 270.775604,751.506042 268.853882,751.385559 
	C267.997528,751.331848 266.412079,753.853638 266.150757,755.376892 
	C265.700195,758.003052 266.025940,760.762451 266.025940,764.703796 
	C264.416351,762.971130 263.933563,762.451416 263.160522,761.619324 
	C261.129547,767.226379 259.271301,772.356567 257.126221,777.437073 
	C256.591278,778.335632 256.343201,779.283875 256.014923,780.597412 
	C254.759735,781.394287 253.584778,781.825867 252.429596,781.896729 
	C252.467010,779.331299 252.484650,777.126587 252.867035,774.830200 
	C254.429657,774.263733 256.313477,774.103333 256.701233,773.257324 
	C258.048828,770.317017 258.877472,767.138855 260.079895,763.741943 
	C263.177277,760.143066 261.927002,758.555115 258.039429,757.702942 
	C258.001831,756.924316 257.979767,756.479492 258.382446,756.036255 
	C259.303864,756.044189 259.888641,756.224915 260.281708,756.026367 
	C261.834869,755.241699 263.327942,754.338074 264.843323,753.478577 
	C263.296692,752.678589 261.702698,751.121643 260.212006,751.214966 
	C254.915207,751.546631 249.653610,752.440735 243.573929,753.225708 
	C244.189392,756.298828 244.675949,758.728271 245.135986,761.480835 
	C245.073441,762.235168 245.037415,762.666504 244.699982,763.370239 
	C240.947601,763.715637 237.496658,763.788513 234.004730,763.574768 
	C234.333359,762.510071 234.702972,761.731995 235.399536,761.134277 
	C237.778015,760.954102 239.829559,760.593506 241.881088,760.232849 
	C240.899521,759.245178 239.917969,758.257507 239.120178,756.965088 
	C240.136002,756.044373 240.968048,755.428284 241.947815,754.702881 
	C236.799835,751.774597 235.294205,752.606384 235.048981,758.060303 
	C235.038528,758.057556 235.066452,758.067993 234.720001,758.057007 
	C233.681351,758.008179 232.989166,757.970337 232.189850,757.556641 
	C232.252777,755.839417 232.422836,754.498108 232.737198,752.018555 
	C228.819427,752.803406 225.640594,753.440186 222.118164,754.126160 
	C220.243820,752.734436 218.713074,751.293579 217.637146,749.887634 
	C236.548264,749.643738 255.004562,749.364929 273.460876,749.086121 
	C273.444977,748.748230 273.429077,748.410278 273.413177,748.072388 
	C254.634705,748.072388 235.856201,748.072388 216.915848,747.874634 
	C216.518127,747.431702 216.282272,747.186523 216.040054,746.684448 
	C216.042419,746.256348 216.051147,746.085205 216.518036,745.985413 
	C221.826508,746.041626 226.676819,746.026550 231.923523,745.932983 
	C234.430222,745.906860 236.540482,745.959229 238.650757,746.011536 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M477.577454,740.616821 
	C478.087372,740.555237 478.281830,740.652405 478.500366,741.278320 
	C478.505188,742.764404 478.485931,743.721741 478.425537,744.862793 
	C478.427399,745.297668 478.518066,745.530334 478.439087,746.022217 
	C478.302734,747.221008 478.383759,748.141968 478.335419,749.272217 
	C478.206055,749.481445 478.197571,749.973389 478.086243,750.317871 
	C477.931458,752.797791 480.961426,754.860840 478.052551,757.483521 
	C478.283997,760.295532 478.588776,762.686157 478.953094,765.376526 
	C479.764954,766.449219 480.517303,767.222290 481.217255,768.221313 
	C481.065979,768.894592 480.967133,769.341980 480.483398,769.715942 
	C479.446442,770.446899 478.794373,771.251282 478.100494,772.515320 
	C478.248688,777.736145 478.438690,782.497375 478.569214,787.405701 
	C478.509735,787.552856 478.691376,787.813782 478.593445,788.148560 
	C478.600830,788.690674 478.706116,788.898010 478.677612,789.391724 
	C478.507721,790.298584 478.471588,790.919189 478.382446,792.030518 
	C478.356750,794.834351 478.557678,797.161316 478.373688,799.457458 
	C478.034271,803.694336 479.702789,806.075562 484.203735,806.523804 
	C484.810242,806.815491 485.274292,806.912292 485.744324,807.426697 
	C485.895386,811.195679 485.970093,814.552429 486.223846,817.895569 
	C486.312195,819.059448 486.753174,820.225952 487.195312,821.325989 
	C487.705627,822.595642 488.395172,823.793213 488.618042,825.055542 
	C486.772095,823.933960 485.315002,822.779053 484.733551,822.318176 
	C480.308350,822.119568 476.670044,821.956299 472.658936,821.701172 
	C472.022827,822.027649 471.759491,822.445984 471.295990,823.098022 
	C467.963928,826.076599 467.733063,826.125366 466.735596,824.127808 
	C467.497833,822.857361 468.010132,821.765625 468.878357,820.625244 
	C469.541199,820.458984 470.114838,820.344666 470.116364,820.223206 
	C470.173187,815.677734 470.218048,811.130737 470.117920,806.587158 
	C470.109070,806.184692 469.108093,805.804016 468.459412,805.334717 
	C469.123718,805.027466 469.651215,804.554260 470.003906,804.660645 
	C471.424896,805.089233 473.711914,806.469849 474.019226,806.104126 
	C475.332977,804.540527 476.607330,802.554260 476.868622,800.585754 
	C477.276886,797.509888 476.853302,794.323669 476.866302,790.752075 
	C476.902802,789.158386 476.855164,787.996765 476.871277,786.383911 
	C476.844360,781.093018 476.753723,776.253296 476.795624,770.973022 
	C476.881927,769.618408 476.835693,768.704285 476.816711,767.489258 
	C476.678436,766.206299 476.512970,765.224365 476.486267,763.807129 
	C476.544434,762.165039 476.463837,760.958191 476.480072,759.380981 
	C476.209045,757.600281 475.841156,756.189941 475.419312,754.399536 
	C475.237732,753.009338 475.110077,751.999268 475.042175,750.626587 
	C475.375793,747.832214 475.649658,745.400574 475.923553,742.968872 
	C476.369690,742.237793 476.815826,741.506714 477.577454,740.616821 
M476.231384,808.019897 
	C476.417816,808.930908 476.604248,809.841858 477.352966,810.768372 
	C477.896759,810.640930 478.440521,810.513489 479.761719,810.595276 
	C480.193787,810.018188 480.625824,809.441162 481.057861,808.864075 
	C480.397186,808.595581 479.736511,808.327087 478.345032,808.024902 
	C477.858673,808.053223 477.372345,808.081604 476.231384,808.019897 
M481.246002,815.166992 
	C482.461212,814.841492 483.676392,814.515991 484.891571,814.190491 
	C483.925720,813.118347 482.959839,812.046143 481.993988,810.973999 
	C481.655548,811.187622 481.317108,811.401306 480.978668,811.614929 
	C480.978668,812.553955 480.978668,813.492981 481.246002,815.166992 
M478.610748,819.940613 
	C479.567200,819.409790 480.523621,818.878906 481.480072,818.348083 
	C481.277374,817.743408 481.074646,817.138672 480.871918,816.533997 
	C478.969757,816.893433 477.067596,817.252869 475.165436,817.612244 
	C475.180084,818.079163 475.194733,818.546082 475.209412,819.013000 
	C476.069122,819.326172 476.928864,819.639343 478.610748,819.940613 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M475.516510,743.008362 
	C475.649658,745.400574 475.375793,747.832214 474.767456,750.365112 
	C473.066833,749.638489 471.746307,748.203308 470.326019,748.096680 
	C466.071655,747.777161 461.772095,748.115479 457.502289,747.925598 
	C454.666779,747.799500 451.852020,747.207825 449.304688,746.581787 
	C451.350128,746.093994 453.118805,745.848877 453.854156,745.746948 
	C419.940826,745.746948 385.075714,745.746948 350.210571,745.746948 
	C350.209625,746.176025 350.208649,746.605164 350.207703,747.034241 
	C351.813629,747.034241 353.419586,747.034241 354.922607,747.426270 
	C350.433228,748.137451 346.046692,748.456726 341.521973,748.580872 
	C340.677887,748.533936 339.971954,748.682068 338.869019,748.843140 
	C338.207642,748.841064 337.943237,748.826050 337.402649,748.549561 
	C323.395355,747.848572 309.664307,747.408997 295.813538,746.844116 
	C295.693817,746.718689 295.880768,746.426636 295.880768,746.426636 
	C297.523499,745.953064 299.163452,745.078430 300.809448,745.067017 
	C320.598969,744.929138 340.390076,745.010071 360.179413,744.854431 
	C390.920288,744.612549 421.660583,744.272766 452.399963,743.882507 
	C459.972137,743.786438 467.539764,743.335571 475.516510,743.008362 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M240.864532,517.156799 
	C239.014374,515.508484 237.097626,513.928284 235.327225,512.198364 
	C230.928696,507.900360 229.025513,508.068787 226.652435,512.063660 
	C224.221939,511.082672 222.138794,509.565796 220.010605,509.499756 
	C210.704224,509.210999 201.374878,509.099243 192.076340,509.476868 
	C189.422638,509.584625 185.742355,509.449615 184.640778,513.539795 
	C184.402557,514.424316 182.776093,515.417419 181.716675,515.507263 
	C178.624054,515.769653 175.494827,515.600525 171.851639,515.600525 
	C171.851639,511.511658 171.793564,508.058685 171.899811,504.610840 
	C171.921509,503.906830 172.381104,502.963379 172.945236,502.572296 
	C175.532211,500.778809 182.963821,502.883728 184.920532,505.359924 
	C185.944839,506.656158 187.951508,507.885834 189.537308,507.915222 
	C201.091431,508.129211 212.651352,508.030365 225.625153,508.030365 
	C223.745239,506.055786 222.993713,505.266449 222.242203,504.477081 
	C222.533127,504.007172 222.824066,503.537262 223.114990,503.067352 
	C229.135895,506.712433 235.156784,510.357483 241.631180,514.276978 
	C241.677948,515.419922 241.271240,516.288330 240.864532,517.156799 
M175.498093,507.295441 
	C172.358978,506.691193 172.268631,506.884003 174.523788,511.951965 
	C175.005783,510.602234 175.487778,509.252472 175.498093,507.295441 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M238.945709,745.714233 
	C236.540482,745.959229 234.430222,745.906860 231.722794,745.622070 
	C229.329910,742.818909 227.185013,740.419373 225.819931,737.637268 
	C223.755219,733.429138 220.600754,732.654602 216.059875,733.476318 
	C216.059875,737.701172 216.059875,741.807617 216.059875,745.914062 
	C216.051147,746.085205 216.042419,746.256348 215.736130,746.685913 
	C215.039688,747.005493 214.640808,747.066589 214.216644,746.882935 
	C214.191345,746.638123 214.134125,746.149231 214.134125,746.149231 
	C214.180176,742.501038 213.924973,738.810913 214.423584,735.225647 
	C214.617477,733.831543 216.643829,731.499023 217.609848,731.607300 
	C222.406189,732.144714 224.321381,729.277893 226.285339,725.777039 
	C229.833466,719.452271 237.230804,716.739929 244.108704,719.070740 
	C244.915054,719.344055 245.602509,720.228027 246.137268,720.977173 
	C246.806427,721.914795 247.257706,723.007324 247.808060,724.030273 
	C252.203232,732.199768 252.009262,736.489685 245.922134,743.362000 
	C244.603043,744.851318 241.514313,744.773193 238.945709,745.714233 
M240.834686,739.966858 
	C240.232788,740.941345 239.630890,741.915771 239.053848,742.850037 
	C245.254532,743.436035 246.158051,742.523621 247.980179,733.435486 
	C240.777588,733.435486 233.646820,733.435486 224.814026,733.435486 
	C228.109299,737.438049 230.346024,740.550110 233.150528,743.016479 
	C233.469040,743.296570 236.978958,739.947632 239.119980,738.190430 
	C237.804855,737.296997 236.996841,736.748047 235.226715,735.545532 
	C239.761749,734.473572 241.832642,734.761292 240.834686,739.966858 
M229.171494,724.660522 
	C228.295700,726.526733 227.419907,728.392944 226.294373,730.791260 
	C234.212021,730.791260 241.329391,730.791260 249.065491,730.791260 
	C247.827850,728.348450 246.888519,726.478516 245.935196,724.615784 
	C243.263092,719.394836 233.001251,718.977051 229.171494,724.660522 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M377.013092,479.441528 
	C376.262512,478.948761 375.511902,478.456055 374.461853,477.934875 
	C357.067200,469.528870 339.923828,461.246674 322.928253,452.671509 
	C320.357239,451.374268 318.474396,448.713196 316.231506,446.125671 
	C313.824554,445.134979 311.459320,444.701569 309.035339,444.257385 
	C311.295990,441.395081 313.085297,438.929535 315.120728,436.687439 
	C315.655975,436.097870 316.952942,436.199860 317.900116,435.984253 
	C317.955963,436.430847 318.011810,436.877472 317.860474,437.741547 
	C317.582336,438.264282 317.428680,438.430115 317.454071,438.464752 
	C317.598694,438.662109 317.785980,438.828186 317.958801,439.004852 
	C318.281342,439.900543 319.076935,440.943115 318.851654,441.668701 
	C317.039673,447.504852 320.838074,449.490417 325.096985,451.506195 
	C340.833557,458.954376 356.510773,466.528015 372.807892,474.384644 
	C374.501831,474.886719 375.598206,475.058655 376.881897,475.521301 
	C378.150513,475.586884 379.231720,475.361786 380.622986,475.115723 
	C381.345856,475.055481 381.758759,475.016235 382.171631,474.976990 
	C383.436157,476.872070 384.357727,479.192902 386.050446,480.556396 
	C388.253082,482.330566 391.068054,483.344513 393.619476,484.685608 
	C393.371002,485.190552 393.122498,485.695496 392.874023,486.200439 
	C389.793152,484.744720 386.712311,483.289032 383.261597,481.733398 
	C382.060974,481.198669 381.230164,480.763885 379.786804,480.088562 
	C378.453857,479.712524 377.733459,479.577026 377.013092,479.441528 
M315.724670,442.486053 
	C316.128784,442.614136 316.528168,442.764740 316.940948,442.851746 
	C317.025848,442.869659 317.160736,442.650391 317.272583,442.540497 
	C316.868317,442.412811 316.464081,442.285126 315.724670,442.486053 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M317.060028,650.963318 
	C319.652496,644.950012 323.262360,644.350647 329.265350,648.992126 
	C333.771454,644.473389 339.146210,645.644653 344.593201,646.904114 
	C345.883972,647.202698 347.317444,646.987549 348.674377,646.876282 
	C351.930725,646.609375 355.180634,646.263977 358.433136,645.950256 
	C358.617493,646.774597 358.801849,647.598938 358.986206,648.423340 
	C356.680664,649.476257 354.375122,650.529175 351.738220,651.733459 
	C351.452332,651.142029 350.933380,650.068726 349.947784,648.030029 
	C348.686218,650.169006 347.850006,651.586792 347.032104,652.973633 
	C345.790375,651.606201 344.317535,649.984253 342.140259,647.586548 
	C341.367798,650.258789 340.938568,651.743652 340.379669,653.677063 
	C339.342285,651.627136 338.436066,649.836426 337.529846,648.045776 
	C337.014862,648.103027 336.499878,648.160278 335.984894,648.217529 
	C335.670288,650.234192 335.355682,652.250854 335.036285,654.298157 
	C332.181122,654.879578 329.828033,648.392151 326.622986,652.977112 
	C321.098206,647.687256 318.027802,656.068787 313.455261,654.693481 
	C313.215454,655.050049 312.975647,655.406677 312.735840,655.763245 
	C311.989410,653.991638 310.191986,651.735901 310.734528,650.558044 
	C311.624268,648.626404 314.033722,647.394836 315.802307,645.867981 
	C316.195740,646.349426 316.589203,646.830811 316.982635,647.312256 
	C315.641968,648.462769 314.301300,649.613342 312.960632,650.763855 
	C313.043091,651.137573 313.125580,651.511292 313.208038,651.885010 
	C314.379150,651.650452 315.550262,651.415894 317.060028,650.963318 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M236.104095,678.853394 
	C235.260605,677.448425 234.614838,675.866028 233.540344,674.668274 
	C228.098526,668.602356 228.718765,662.769043 234.456070,656.986694 
	C235.121384,657.106384 235.283310,657.238220 235.304169,657.691162 
	C234.119415,660.156921 233.075729,662.301514 231.979691,664.553711 
	C239.768250,664.553711 246.889603,664.553711 255.534103,664.553711 
	C251.174103,660.194519 251.647797,653.060059 243.784836,653.794922 
	C242.827179,652.658691 242.079147,651.803223 241.331116,650.947693 
	C243.155350,651.196167 244.979568,651.444702 246.980576,651.717224 
	C246.949844,651.105896 246.901550,650.144897 247.244110,649.157104 
	C249.298874,647.725708 250.962738,646.321045 252.838501,645.228760 
	C253.985641,645.690918 254.920883,645.840698 255.856125,645.990479 
	C256.080872,647.559265 256.305634,649.128052 256.524963,650.658936 
	C250.207153,652.031677 249.471878,652.613342 253.071838,657.163940 
	C259.440460,665.214417 254.727478,671.463074 250.545074,678.541016 
	C245.326233,678.891541 240.715149,678.872437 236.104095,678.853394 
M233.100204,670.180481 
	C234.911194,672.689575 236.194672,676.434937 238.632446,677.475098 
	C245.842316,680.551575 252.805161,675.354065 253.523819,666.854736 
	C247.201401,666.854736 240.899246,666.778870 234.605209,666.969849 
	C233.962677,666.989380 233.368759,668.610352 233.100204,670.180481 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M516.037781,731.482849 
	C516.108398,731.164001 516.104919,730.845947 516.010071,730.289307 
	C524.294617,729.950745 532.596436,729.851624 541.351440,729.718018 
	C543.324402,729.739563 544.844360,729.795471 547.257812,729.761414 
	C573.402527,729.548340 598.653748,729.425110 623.905273,729.566650 
	C623.923523,730.184326 623.941345,730.537231 623.496521,730.947754 
	C615.706604,731.682495 608.095886,729.434875 601.043945,732.789246 
	C600.632324,732.013916 600.238403,731.584656 599.385742,731.155884 
	C582.784790,731.176636 566.641235,731.105835 550.502197,731.343689 
	C548.689941,731.370422 546.903442,733.144226 544.744629,734.082336 
	C543.903198,734.057495 543.422058,734.058105 542.651794,733.759644 
	C541.848938,732.853333 541.335632,731.720093 540.821411,731.719666 
	C535.230713,731.715393 529.621887,731.689026 524.064453,732.186951 
	C522.990662,732.283142 522.117432,734.618408 520.963379,736.230408 
	C519.480408,738.644897 518.185608,740.749878 516.945435,742.926147 
	C517.000000,742.997559 516.887512,742.857849 516.667725,742.708557 
	C516.018250,742.036255 515.588562,741.513306 515.170410,740.629883 
	C516.540100,737.918091 517.898193,735.566711 519.492371,733.090271 
	C519.604553,732.558472 519.480713,732.151672 518.944092,731.685059 
	C517.700195,731.577698 516.868958,731.530273 516.037781,731.482849 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M185.070404,602.038696 
	C183.141663,605.792297 179.671204,605.693604 176.199631,605.584900 
	C174.703812,605.538086 173.208832,605.453674 171.712769,605.427429 
	C167.429306,605.352356 163.145493,605.296936 158.861786,605.237061 
	C157.906433,605.223694 156.950577,605.236816 155.995483,605.214844 
	C154.355865,605.177185 152.716644,605.121399 151.077240,605.073181 
	C151.279541,603.370178 151.283295,601.607605 151.768982,599.989685 
	C152.007202,599.196228 153.139969,598.671265 154.418259,597.536011 
	C155.108124,599.074890 155.719894,600.439636 156.331680,601.804321 
	C156.599991,601.104553 156.868317,600.404785 157.136627,599.704956 
	C164.827881,599.225098 172.519135,598.745178 180.874680,598.223877 
	C179.358780,599.941895 178.188232,601.268555 177.017700,602.595215 
	C177.215866,602.946411 177.414017,603.297607 177.612183,603.648804 
	C179.270950,602.954346 180.929718,602.259888 183.104523,601.416016 
	C184.103836,601.523987 184.587128,601.781311 185.070404,602.038696 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M294.647400,730.680725 
	C296.727142,730.498840 298.806854,730.316956 300.886536,730.135071 
	C300.934052,730.746826 300.981537,731.358521 301.029053,731.970276 
	C304.164368,730.612305 307.270477,726.440735 310.338837,731.555054 
	C316.075134,727.891418 323.393402,727.853394 328.481781,731.491028 
	C329.774078,730.741943 331.135498,729.952759 332.814667,728.979431 
	C332.814667,731.206848 332.814667,733.003479 332.814667,734.903564 
	C331.502686,734.646729 330.284851,734.243225 329.056183,734.206848 
	C327.245148,734.153198 325.101349,736.410461 323.708130,733.236694 
	C323.603546,732.998413 321.125244,733.344666 320.176971,734.018982 
	C317.174591,736.154480 314.309021,737.985291 310.480682,735.879028 
	C309.824310,735.517944 308.704346,736.110535 307.817993,736.034790 
	C305.531372,735.839233 303.255676,735.516052 300.501160,734.779419 
	C298.500427,733.923096 296.974091,733.531311 295.060608,733.040222 
	C294.985321,732.610229 294.816376,731.645447 294.647400,730.680725 
M315.118774,731.245728 
	C314.149139,731.992981 313.179474,732.740234 312.209839,733.487488 
	C312.477478,733.878967 312.745117,734.270447 313.012756,734.661926 
	C314.476990,734.084412 315.941193,733.506836 317.405396,732.929260 
	C316.894867,732.279358 316.384338,731.629456 315.118774,731.245728 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M252.626602,644.916443 
	C250.962738,646.321045 249.298874,647.725708 246.799988,649.111145 
	C242.382584,649.019958 238.800156,648.947937 235.217743,648.875977 
	C234.424759,647.823303 233.327759,645.757141 232.889938,645.888489 
	C230.657303,646.558105 228.595093,647.795837 226.295898,648.921692 
	C225.915131,644.688232 222.003494,645.708435 218.580963,645.713745 
	C214.282822,645.720398 209.984665,645.713989 205.273895,645.299927 
	C205.001831,644.580994 205.068192,644.260071 205.060349,643.923706 
	C211.615952,643.923706 218.171555,643.923706 226.053467,643.923706 
	C224.193466,642.367432 223.345078,641.657532 222.496689,640.947693 
	C222.651596,640.544556 222.806519,640.141418 222.961426,639.738342 
	C224.893478,640.405823 226.825531,641.073364 228.735825,641.733398 
	C228.808151,641.534302 229.073807,640.802917 229.325607,640.109680 
	C229.778503,640.208496 230.037308,640.197571 230.074036,640.282532 
	C233.048355,647.163635 233.663101,641.596008 236.184555,639.762939 
	C238.406387,638.147644 242.596588,639.487183 245.818344,638.938843 
	C250.050293,638.218628 250.929382,641.166992 252.514709,644.240967 
	C252.830704,644.574524 252.626602,644.916443 252.626602,644.916443 
M245.290909,646.405090 
	C242.648575,645.027649 240.006226,643.650269 237.399887,642.291626 
	C237.399887,644.069702 237.399887,645.814941 237.399887,648.851379 
	C240.709702,647.752563 243.330750,646.882385 246.654190,645.989380 
	C251.392975,645.719299 248.008957,642.970581 248.521774,641.358398 
	C245.942749,641.358398 243.802460,641.358398 241.029312,641.358398 
	C243.044662,643.259827 244.515045,644.647034 245.290909,646.405090 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M331.417023,704.092163 
	C330.597351,703.315674 330.124207,702.234070 329.023438,700.776001 
	C328.140564,694.369568 327.885315,688.339478 327.630066,682.309448 
	C327.266174,682.299622 326.902283,682.289734 326.538391,682.279907 
	C326.312256,683.167725 326.086121,684.055481 325.543640,684.962585 
	C324.803741,684.996460 324.380157,685.011047 323.527985,684.958984 
	C315.984680,684.705811 315.995117,684.705688 315.908966,677.717102 
	C315.862488,673.946716 315.730927,670.177002 315.715912,666.406677 
	C315.703278,663.232605 314.542084,661.372803 311.022766,661.690186 
	C311.021362,661.463379 311.005676,661.009949 311.005676,661.009949 
	C316.916473,658.191040 317.982178,658.844299 318.131622,665.078369 
	C318.245575,669.832275 318.570679,674.581177 318.826233,679.833435 
	C321.507965,679.833435 323.667236,680.083252 325.746552,679.781860 
	C330.204071,679.135742 331.876923,681.600037 331.958282,685.363281 
	C332.090942,691.500244 331.847992,697.645386 331.417023,704.092163 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M290.742615,448.152100 
	C292.394043,448.876556 294.045471,449.601044 296.305359,450.592468 
	C296.467926,450.363861 297.218475,449.308228 298.890228,446.956970 
	C299.389648,450.309875 299.689636,452.323822 300.159332,455.477173 
	C290.075317,455.477173 280.830170,455.477173 270.582581,455.477173 
	C271.154663,452.812500 271.659363,450.461792 272.923889,448.059875 
	C277.560364,448.570831 281.436981,449.133026 285.232422,449.683472 
	C286.957794,449.203979 288.850220,448.678040 290.742615,448.152100 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M208.926544,609.880981 
	C210.279419,609.472046 211.323837,609.268188 212.368256,609.064331 
	C212.679184,609.492249 212.990112,609.920105 213.301041,610.348022 
	C211.731842,611.106750 210.162643,611.865479 208.593445,612.624268 
	C208.979355,613.164490 209.365280,613.704773 209.751190,614.244995 
	C211.154526,613.552673 212.978546,613.208008 213.887833,612.107483 
	C217.677216,607.521362 217.566986,607.445312 221.223022,612.016968 
	C221.502731,612.366699 222.042908,612.508179 222.755585,612.913391 
	C222.755585,611.283569 222.755585,609.895447 222.755585,608.507324 
	C223.215363,608.444031 223.675156,608.380737 224.134933,608.317444 
	C224.255112,610.097046 224.992859,612.173096 224.321136,613.572754 
	C223.653900,614.963135 221.624878,616.433472 220.095688,616.546082 
	C214.371521,616.967468 208.607605,616.872620 202.858261,616.899719 
	C202.092468,616.903320 201.160522,616.701538 200.585251,616.243530 
	C197.484665,613.774902 197.515121,613.736633 193.486572,616.168518 
	C193.486572,613.743835 192.877060,610.320312 193.598373,610.012146 
	C195.966812,609.000427 198.818283,609.119507 200.711594,608.895447 
	C202.214615,611.211304 203.136398,612.631531 204.058197,614.051819 
	C205.578171,612.729919 207.098145,611.408020 208.926544,609.880981 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M317.941040,435.566711 
	C316.952942,436.199860 315.655975,436.097870 315.120728,436.687439 
	C313.085297,438.929535 311.295990,441.395081 309.035339,444.257385 
	C311.459320,444.701569 313.824554,445.134979 316.033813,445.828735 
	C314.278992,446.124573 312.680176,446.160034 310.219910,446.132019 
	C308.433044,446.115692 307.507599,446.162781 306.582153,446.209900 
	C305.091919,445.423035 303.601685,444.636200 302.078796,443.832153 
	C301.802582,443.974182 301.234772,444.266113 300.666962,444.558044 
	C300.028290,438.500977 295.498230,440.386078 291.155609,440.382385 
	C289.316650,440.358856 288.264282,440.236084 286.929993,440.082306 
	C286.146057,440.242523 285.644012,440.433746 284.694641,440.639984 
	C282.172363,440.736237 280.097473,440.817413 278.021667,440.598602 
	C278.019073,439.897583 278.017395,439.496582 278.015686,439.095551 
	C281.385620,439.048798 284.755585,439.002014 288.586273,438.987610 
	C291.032745,438.999390 293.018463,438.978760 295.289398,438.820984 
	C297.427582,438.155884 299.280518,437.627899 301.549866,437.507141 
	C302.013519,438.979675 302.060760,440.044983 302.154846,442.165924 
	C303.078735,440.043701 303.575653,438.902283 304.072571,437.760864 
	C304.469849,436.358826 304.867126,434.956757 305.448853,432.903748 
	C308.525421,432.903748 311.959686,432.747772 315.362396,433.006592 
	C316.284546,433.076721 317.111359,434.400696 317.941040,435.566711 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M340.755554,639.372925 
	C340.765717,641.446838 339.119476,640.411621 338.610504,640.708984 
	C332.585632,644.229248 326.463867,642.430176 320.330688,641.890381 
	C319.447144,641.812622 318.520782,642.715942 317.563080,642.902161 
	C312.729279,643.842346 310.579803,641.274536 312.264496,635.684998 
	C312.737762,637.584900 313.060577,638.880798 313.391479,640.209167 
	C316.595551,633.768372 316.689728,633.740173 321.395508,638.007874 
	C324.243530,635.473206 327.295105,633.341431 329.212921,635.938538 
	C333.211823,635.938538 335.763733,635.938538 338.689972,636.321716 
	C339.628052,637.594238 340.191803,638.483582 340.755554,639.372925 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M165.035675,752.958496 
	C159.079330,752.734680 153.122971,752.510803 146.401443,752.212158 
	C144.844009,751.716431 144.051758,751.295471 143.259491,750.874512 
	C143.259491,750.874512 143.191467,750.529541 143.523315,750.323242 
	C144.247040,750.070129 144.638901,750.023254 145.318222,750.279541 
	C147.736908,750.721436 149.869858,751.001953 151.999100,750.976318 
	C169.604584,750.764343 187.209381,750.495667 204.889130,750.606079 
	C204.327148,752.296570 203.690399,753.625183 203.035767,754.541687 
	C201.409866,750.064819 197.994980,752.629517 195.441757,752.139160 
	C192.384781,751.552246 191.224518,753.107605 191.905014,756.401672 
	C191.915497,757.121826 191.931259,757.533203 191.677765,758.216309 
	C191.385468,760.287415 191.362442,762.086792 190.978088,763.903564 
	C190.135315,763.903809 189.653870,763.886536 189.102753,763.423706 
	C189.006119,760.830688 188.817047,758.670166 188.988708,756.538757 
	C189.273712,753.000488 187.691116,751.755432 184.317902,752.068481 
	C179.927856,752.476074 175.524567,752.740601 171.064148,753.071411 
	C170.716232,758.445312 170.403748,763.271790 169.776978,768.060120 
	C169.044159,767.966919 168.625641,767.911804 168.194305,767.402832 
	C168.129379,762.285889 168.077286,757.622864 167.878571,752.709351 
	C166.833191,752.625305 165.934433,752.791931 165.035675,752.958496 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M144.979324,728.462341 
	C144.959610,728.266418 145.032684,728.115356 145.689117,728.033447 
	C146.448105,727.689758 146.716568,727.321777 146.985016,726.953857 
	C146.985016,726.953857 147.000580,727.003723 147.262817,726.866577 
	C147.673813,726.139465 147.822571,725.549622 147.971344,724.959717 
	C147.971344,724.959717 148.000305,725.000732 148.268860,724.884949 
	C148.760330,724.185852 148.983231,723.602539 149.395996,722.922546 
	C149.777115,722.469238 149.968384,722.112610 150.577423,721.575928 
	C153.300339,721.318604 155.605194,721.186951 157.910675,721.174500 
	C164.138519,721.140991 167.840073,724.820740 168.468628,731.846191 
	C167.009872,731.950867 165.553238,732.055359 163.622406,732.105469 
	C157.334091,732.061951 151.519989,732.072815 145.685211,731.707520 
	C145.436127,730.375000 145.207733,729.418701 144.979324,728.462341 
M156.015549,723.822144 
	C154.090683,723.799194 151.612930,723.207092 152.029251,726.414368 
	C152.220200,727.885498 153.162796,729.302917 153.997864,730.593689 
	C154.108917,730.765381 155.600266,730.044128 156.670883,729.657471 
	C155.610489,729.029785 154.859055,728.584900 153.018738,727.495483 
	C155.690491,727.278503 157.144760,727.160339 159.607574,726.960327 
	C158.353577,725.644958 157.578857,724.832275 156.015549,723.822144 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M413.708923,707.004028 
	C414.853180,707.699280 415.611694,708.320984 416.370178,708.942688 
	C416.905243,708.052490 417.898407,707.164124 417.901855,706.271851 
	C417.926331,699.950073 417.714966,693.627808 417.661316,687.304932 
	C417.650055,685.979675 417.981628,684.651489 418.155212,683.324646 
	C418.702332,683.365845 419.249420,683.407043 419.796539,683.448242 
	C419.934845,686.317688 420.073151,689.187134 420.211456,692.056580 
	C420.383698,692.060669 420.555939,692.064758 420.728180,692.068787 
	C420.940186,689.582031 421.152191,687.095276 421.364197,684.608521 
	C421.634369,684.617676 421.904541,684.626831 422.174713,684.635986 
	C422.174713,692.383789 422.246033,700.133179 422.073120,707.877136 
	C422.054169,708.726196 420.631927,709.544006 419.776428,710.710571 
	C414.648956,711.044861 409.605560,711.044861 404.562164,711.044861 
	C404.512848,710.638489 404.463501,710.232117 404.414185,709.825745 
	C405.290344,709.384094 406.166534,708.942444 407.116241,708.463684 
	C406.098480,707.618958 405.115356,706.802979 404.319275,705.564087 
	C404.695923,705.098145 404.885559,705.055054 405.075226,705.011902 
	C407.824554,705.651428 410.573883,706.291016 413.708923,707.004028 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M310.635132,661.001465 
	C311.005676,661.009949 311.021362,661.463379 310.721741,661.853760 
	C303.771484,666.913269 297.530609,671.728455 297.644501,681.164246 
	C297.659424,682.398865 294.645905,684.686646 292.964325,684.755432 
	C283.981781,685.122620 274.976715,684.939758 265.979462,684.947144 
	C265.971283,684.548157 265.963135,684.149231 266.411438,683.403625 
	C276.276825,683.057007 285.685699,683.057007 295.094604,683.057007 
	C295.083557,682.677246 295.072510,682.297546 295.061462,681.917786 
	C285.356476,681.917786 275.651459,681.917786 265.946472,681.917786 
	C265.946808,681.560059 265.947174,681.202393 265.925110,680.581055 
	C275.662964,680.317505 285.423248,680.317505 295.453400,680.317505 
	C296.962646,670.696472 301.472809,664.100891 310.635132,661.001465 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M326.144287,758.071899 
	C326.055878,759.543823 325.967468,761.015808 325.892090,762.271057 
	C323.610474,762.271057 321.537018,762.271057 318.711273,762.271057 
	C317.381592,760.741089 316.057190,758.921204 312.594879,762.536438 
	C310.818665,764.390991 305.803711,763.143738 301.707642,763.626587 
	C298.870392,764.216980 296.577911,764.479065 294.008972,764.475586 
	C292.733887,764.353088 291.735352,764.496094 290.896271,764.283875 
	C291.729187,762.316956 292.402618,760.705322 293.076050,759.093628 
	C293.411072,758.934570 293.746124,758.775452 294.527588,758.356201 
	C297.241364,757.157166 299.508698,756.218384 301.771729,755.643921 
	C302.805115,757.557312 303.750488,759.179260 304.902039,760.638489 
	C306.693817,762.909302 310.476379,762.671387 310.651794,760.111450 
	C310.879120,756.794312 313.422760,757.165649 315.173645,756.007568 
	C315.389252,756.076904 315.838593,756.134216 316.126465,756.388550 
	C317.930603,756.785645 319.446838,756.928345 320.979553,757.039612 
	C320.996002,757.008179 320.929535,757.033081 320.853882,757.366638 
	C321.570251,757.841125 322.362213,757.981995 323.423340,757.983765 
	C324.509735,757.920288 325.327026,757.996094 326.144287,758.071899 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M204.814377,750.244141 
	C187.209381,750.495667 169.604584,750.764343 151.999100,750.976318 
	C149.869858,751.001953 147.736908,750.721436 145.517014,749.957520 
	C147.725204,748.893982 150.022064,748.455627 152.318924,748.017334 
	C152.624054,747.988831 152.929764,747.979492 153.966675,748.022644 
	C174.316879,748.065002 193.936478,748.074097 213.595520,748.374451 
	C213.633743,749.055847 213.632568,749.445984 213.204987,749.869629 
	C210.123840,750.016724 207.469116,750.130432 204.814377,750.244141 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M305.450562,504.082245 
	C305.214966,503.658295 304.979370,503.234344 304.743744,502.810425 
	C307.790314,502.150085 310.817230,501.175018 313.893036,500.982819 
	C315.139984,500.904938 316.510590,502.509094 317.778381,503.413147 
	C317.950836,503.536102 317.845215,504.040802 317.879059,504.367645 
	C318.872681,513.972290 318.632294,514.228027 308.380615,513.508789 
	C307.124939,510.027618 306.287750,507.054932 305.450562,504.082245 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M244.966507,514.429871 
	C247.145798,510.367432 249.613007,506.787842 255.047379,507.034637 
	C255.846603,507.070923 256.716705,505.941681 257.523590,505.312653 
	C258.520599,504.535431 259.485870,503.717468 261.499573,502.071136 
	C261.223511,504.626434 261.106262,505.711700 261.058136,506.157013 
	C272.506256,506.157013 283.775818,506.157013 295.595245,506.301483 
	C296.428925,506.958282 296.712769,507.470612 296.996613,507.982971 
	C286.544769,508.205109 276.092804,508.574524 265.641144,508.566589 
	C262.156128,508.563934 259.390930,508.810303 258.870789,513.363403 
	C255.950668,507.686707 253.282578,507.402374 249.311340,511.833527 
	C248.093002,513.192993 246.877853,514.555237 245.272232,515.553345 
	C244.910980,514.936890 244.938751,514.683350 244.966507,514.429871 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M366.964600,680.137024 
	C365.698273,680.095825 364.431946,680.054688 363.310486,679.493042 
	C362.637024,678.327881 361.818726,677.683167 361.000427,677.038452 
	C361.000427,677.038452 360.977356,677.021667 360.981384,676.635132 
	C359.466492,673.057251 357.464691,669.824219 361.851013,666.721741 
	C362.716736,668.226624 363.420380,669.449768 363.860321,670.214600 
	C365.273041,668.343323 366.564850,666.632202 367.856659,664.921021 
	C369.539886,666.312805 371.223145,667.704529 372.906342,669.096375 
	C373.270874,669.397766 373.635437,669.699036 374.018188,670.380676 
	C371.679108,673.886353 369.321869,677.011658 366.964600,680.137024 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M281.949615,661.626343 
	C279.734711,661.967285 277.877502,662.149597 276.020264,662.331970 
	C275.902863,661.980896 275.785461,661.629822 275.668060,661.278809 
	C277.086121,660.121765 278.504211,658.964783 280.472565,657.358826 
	C278.682190,656.922668 277.547089,656.646179 276.256989,656.331909 
	C276.490509,655.835510 276.694000,655.062073 276.876801,655.066956 
	C278.785095,655.117676 280.690125,655.293335 282.556763,656.125061 
	C285.475769,654.878723 288.447754,652.265991 290.543854,655.580444 
	C293.476471,655.580444 295.533295,655.580444 297.590149,655.580444 
	C297.110901,657.647156 296.631622,659.713867 295.998810,662.442810 
	C293.491852,660.971313 292.253479,660.244385 291.015106,659.517517 
	C291.009705,659.915527 291.004303,660.313538 290.998901,660.711548 
	C288.101715,660.963623 285.204498,661.215698 281.949615,661.626343 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M144.688751,728.727661 
	C145.207733,729.418701 145.436127,730.375000 145.519562,731.907715 
	C145.475937,733.318359 145.577301,734.152649 145.849579,735.301514 
	C147.039337,737.328430 147.934494,739.132568 149.116943,740.723267 
	C150.026978,741.947449 151.220825,743.711548 152.423447,743.826782 
	C156.036713,744.173157 159.916977,744.498413 163.288559,743.485474 
	C165.091187,742.943909 166.049927,739.623413 167.416412,737.574951 
	C167.689423,737.165710 168.084427,736.837830 168.692413,736.727417 
	C169.145111,737.148071 169.328583,737.313721 169.512085,737.479431 
	C167.732941,740.205872 166.457657,744.559875 164.063858,745.300964 
	C159.813736,746.616760 154.734818,746.431702 150.309387,745.467163 
	C148.342773,745.038452 146.811188,741.335449 145.630386,738.857361 
	C143.458420,734.299255 140.221878,732.932556 135.136078,734.633423 
	C135.136078,739.119812 135.136078,743.713928 135.136078,748.308044 
	C134.627121,748.325073 134.118149,748.342163 133.609192,748.359192 
	C133.609192,743.563293 133.442307,738.755127 133.793152,733.985046 
	C133.844452,733.287659 136.375122,732.798706 137.736450,732.165710 
	C139.966614,731.128662 142.178574,730.052551 144.688751,728.727661 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M188.844055,713.878784 
	C195.055222,714.073608 201.266373,714.268433 207.739594,714.800781 
	C179.983398,715.432129 151.965164,715.725891 123.480103,715.996338 
	C124.632210,715.318298 126.243530,714.119019 127.871323,714.096191 
	C139.611252,713.931763 151.354492,714.005615 164.032410,713.998108 
	C172.926758,713.954407 180.885406,713.916626 188.844055,713.878784 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M278.022552,440.898621 
	C280.097473,440.817413 282.172363,440.736237 284.721771,441.022034 
	C285.196259,442.511139 285.196259,443.633240 285.196259,445.121338 
	C285.951355,443.245270 286.581635,441.679291 287.211914,440.113312 
	C288.264282,440.236084 289.316650,440.358856 290.761475,440.559113 
	C291.065521,442.853546 290.977081,445.070526 290.815643,447.719788 
	C288.850220,448.678040 286.957794,449.203979 285.232422,449.683472 
	C281.436981,449.133026 277.560364,448.570831 273.309875,447.981445 
	C272.375916,447.493988 271.815826,447.033752 271.480225,446.314209 
	C275.690674,446.364014 274.931671,443.450897 275.410980,441.033997 
	C276.523621,440.923523 277.273102,440.911072 278.022552,440.898621 
M277.467316,443.856689 
	C277.173218,443.913177 276.879089,443.969666 276.584991,444.026184 
	C276.876556,444.091553 277.168121,444.156952 277.467316,443.856689 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M244.565002,514.359558 
	C244.938751,514.683350 244.910980,514.936890 244.929245,515.570129 
	C244.526459,518.690308 244.077637,521.430664 243.475937,524.746338 
	C243.439056,525.860718 243.555069,526.399780 243.671112,526.938904 
	C243.632446,529.947815 243.593781,532.956726 243.460129,536.401550 
	C243.365128,536.837463 242.917877,536.776794 242.917877,536.776794 
	C242.917877,536.776794 242.479889,536.885742 242.262390,536.945374 
	C241.956757,534.058411 241.868622,531.111755 241.868866,527.301636 
	C241.893463,525.859863 241.829666,525.281616 241.765869,524.703308 
	C241.557114,522.429504 241.348358,520.155701 241.002060,517.519348 
	C241.271240,516.288330 241.677948,515.419922 241.980087,514.200317 
	C241.690414,505.514069 241.505325,497.178955 241.488068,488.202972 
	C241.611313,486.420776 241.566727,485.279480 241.522125,484.138153 
	C241.436981,482.699799 241.351852,481.261444 241.478821,479.267303 
	C241.572830,478.158844 241.454742,477.606171 241.336670,477.053497 
	C241.316376,474.701691 241.296097,472.349915 241.349640,469.565735 
	C241.753937,469.225800 242.082001,469.217896 242.654144,469.077026 
	C243.040268,473.607117 243.179855,478.169678 243.180573,483.630981 
	C243.099136,486.110596 243.156586,487.691467 243.214020,489.272369 
	C243.530518,497.611328 243.847000,505.950287 244.565002,514.359558 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M356.917450,712.001465 
	C360.185699,712.564209 363.453918,713.126892 367.917877,713.895447 
	C364.160095,718.201111 360.877533,721.962219 356.936707,725.773438 
	C356.077515,725.686035 355.876556,725.548584 355.848083,725.138367 
	C358.497162,721.913330 360.973755,718.960938 363.450317,716.008606 
	C363.090912,715.421570 362.731506,714.834534 362.372101,714.247437 
	C359.913269,715.265503 357.207336,715.922302 355.058044,717.392822 
	C352.362305,719.237244 350.123047,721.748840 347.402191,724.001465 
	C346.736664,724.015808 346.355682,724.001282 346.139526,723.698364 
	C349.359589,720.284180 352.414917,717.158386 355.046661,714.465881 
	C352.102631,711.200562 349.838257,708.362610 347.195648,705.936829 
	C346.282806,705.098816 344.428925,705.286011 342.975830,704.973877 
	C342.946777,704.942810 342.890747,704.878967 342.890747,704.878967 
	C343.139557,701.609436 344.720917,701.351196 346.980865,703.196838 
	C349.718353,705.432434 352.347778,707.800293 355.465271,710.434143 
	C356.243561,711.172058 356.580505,711.586792 356.917450,712.001465 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M345.974731,723.986816 
	C346.355682,724.001282 346.736664,724.015808 347.328918,724.360474 
	C347.800140,725.335999 348.060089,725.981323 347.948425,726.724854 
	C346.252228,727.949158 344.793182,728.955750 343.650177,730.243042 
	C342.882355,731.107727 341.848267,732.872070 342.186249,733.352417 
	C342.952820,734.442017 344.493439,734.986877 345.872986,735.900391 
	C346.026642,736.047668 346.315125,736.360657 346.250427,736.644165 
	C346.177795,737.498474 346.169861,738.069275 346.161926,738.640076 
	C345.687073,738.767029 345.217865,738.975281 344.736481,739.008972 
	C335.970551,739.622375 333.885925,737.225220 335.626862,728.378479 
	C336.443939,724.226562 339.855927,726.521484 342.833008,725.805542 
	C343.721252,725.040833 343.883026,724.404114 344.364868,723.819824 
	C345.114868,723.910400 345.544800,723.948608 345.974731,723.986816 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M184.255386,582.190857 
	C180.475815,582.646667 176.696228,583.102478 172.069397,583.660400 
	C172.069397,579.336792 172.060089,575.916626 172.079910,572.496704 
	C172.082733,572.010559 172.175720,571.116638 172.313461,571.099304 
	C175.673492,570.676880 179.049942,570.101501 182.411667,570.166138 
	C183.308212,570.183350 184.166122,572.210876 184.869339,573.773315 
	C184.696915,574.231262 184.654648,574.336731 184.390152,574.096436 
	C181.398483,576.023132 178.671310,578.190063 174.737381,581.315857 
	C178.030182,581.632263 179.962814,581.817993 182.287506,582.050110 
	C183.204834,582.127930 183.730103,582.159363 184.255386,582.190857 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M163.049194,614.628662 
	C161.928879,613.330994 160.891983,612.372864 159.243057,610.849243 
	C158.504486,612.862000 158.038254,614.132568 157.527390,615.524780 
	C154.701981,610.286865 152.654373,609.690186 152.253403,617.279541 
	C151.801697,616.971680 151.349991,616.663879 150.898285,616.356018 
	C151.346222,613.839783 151.794159,611.323547 152.271851,608.640137 
	C158.851242,609.512451 164.470932,611.082214 170.273239,609.223022 
	C171.657715,608.779419 173.545959,609.908142 175.202484,610.313660 
	C174.023636,611.606873 172.883331,612.938416 171.650436,614.177917 
	C170.989441,614.842468 170.169159,615.348633 169.103928,616.170837 
	C168.537445,614.611450 168.069244,613.322632 167.148468,610.788025 
	C166.050766,612.964478 165.530273,614.178711 164.808105,615.258179 
	C164.680130,615.449524 163.712524,615.079163 163.049194,614.628662 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M261.316162,576.242676 
	C260.759979,575.096619 260.520782,574.197205 260.489807,573.100464 
	C262.070618,573.278015 263.442047,573.971130 264.816071,573.976318 
	C274.146790,574.011658 283.479462,573.975952 292.808441,573.800781 
	C296.307495,573.735168 299.798492,573.236694 303.672791,573.334229 
	C304.533997,574.862549 305.015594,575.992432 305.497223,577.122253 
	C290.875854,576.911255 276.254517,576.700317 261.316162,576.242676 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M143.981812,758.002014 
	C144.058533,767.624329 143.350952,768.456848 132.202759,770.838013 
	C127.893288,770.559326 127.416756,767.719177 127.104630,765.040527 
	C128.335617,764.641907 129.188843,764.271484 130.364502,763.957764 
	C131.117691,763.967712 131.548462,763.921021 131.992050,764.233887 
	C132.455368,768.791382 135.875656,765.051880 137.323730,766.583557 
	C137.825516,765.158691 138.730209,763.738037 138.745224,762.308105 
	C138.776459,759.333435 138.336411,756.353943 138.090057,753.376404 
	C139.254196,754.065613 140.418335,754.754822 141.908875,755.963623 
	C142.817459,756.989441 143.399628,757.495728 143.981812,758.002014 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M305.568665,577.569336 
	C305.015594,575.992432 304.533997,574.862549 304.060913,573.410522 
	C305.764923,571.564392 307.460419,570.040405 309.586914,568.897461 
	C311.822235,571.956177 313.498932,574.736511 315.514587,577.244385 
	C316.065216,577.929443 317.634125,577.796082 318.735657,578.038330 
	C318.159637,579.162537 317.689117,581.174683 316.989349,581.257751 
	C313.912323,581.623474 310.740784,581.662659 307.669098,581.285522 
	C306.871002,581.187622 306.309998,579.158447 305.568665,577.569336 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M294.003784,524.046082 
	C302.796661,524.032410 311.090576,524.032410 319.384460,524.032410 
	C319.379547,524.671997 319.374664,525.311523 319.369751,525.951111 
	C304.464081,525.951111 289.558411,525.951111 274.652710,525.951111 
	C274.605743,525.681946 274.558777,525.412720 274.511780,525.143555 
	C277.523376,524.797974 280.527161,524.311462 283.548645,524.144897 
	C286.858856,523.962463 290.185364,524.075439 294.003784,524.046082 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M345.719360,735.753174 
	C344.493439,734.986877 342.952820,734.442017 342.186249,733.352417 
	C341.848267,732.872070 342.882355,731.107727 343.650177,730.243042 
	C344.793182,728.955750 346.252228,727.949158 348.210510,727.024536 
	C349.366150,729.886841 350.565186,730.743774 352.750153,728.613220 
	C353.783508,727.605530 354.703979,726.482117 355.675598,725.411072 
	C355.876556,725.548584 356.077515,725.686035 356.632996,725.911133 
	C356.990295,726.427002 356.993073,726.855286 356.627747,727.743225 
	C356.292358,729.028564 356.325104,729.854309 356.357880,730.679993 
	C356.593292,730.393799 356.828674,730.107666 357.064056,729.821472 
	C357.804382,729.995728 358.544739,730.169922 359.430603,730.654663 
	C358.979828,731.659912 358.383514,732.354614 357.820923,733.427612 
	C355.207062,733.805908 352.559509,733.805908 350.473755,733.805908 
	C349.074524,735.751343 347.996582,737.250122 346.540283,738.694458 
	C346.169861,738.069275 346.177795,737.498474 346.580688,736.516235 
	C347.351837,735.003479 347.728058,733.902039 348.104279,732.800659 
	C347.662659,732.663330 347.221008,732.526001 346.779358,732.388611 
	C346.426025,733.510132 346.072693,734.631653 345.719360,735.753174 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M196.948944,647.966919 
	C197.373199,650.655273 196.403015,652.149536 193.502640,652.015747 
	C193.006241,651.992798 192.499496,652.099792 192.009781,652.042358 
	C189.457962,651.743042 185.029175,652.002869 184.706512,650.979431 
	C183.654785,647.643494 184.092621,643.835022 183.992889,640.207336 
	C183.984756,639.911682 184.219101,639.363708 184.386490,639.345093 
	C187.630203,638.985291 190.902008,638.355835 194.117935,638.562256 
	C195.191177,638.631104 196.125061,640.870972 196.775055,642.516724 
	C195.982971,642.941711 195.538467,642.968384 194.760040,643.000916 
	C193.621399,643.352600 192.816681,643.698364 191.905991,643.611450 
	C191.125565,638.546936 188.325485,641.190002 186.040909,641.194763 
	C186.040909,643.476379 186.040909,645.411682 186.040909,647.475037 
	C187.499374,646.943787 188.774094,646.479431 190.047729,646.359253 
	C189.561813,647.661682 189.076965,648.619934 188.592117,649.578247 
	C188.827866,649.969421 189.063614,650.360657 189.299362,650.751892 
	C191.285370,649.796753 193.271393,648.841614 195.540604,647.885498 
	C196.198853,647.911987 196.573898,647.939453 196.948944,647.966919 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M159.314026,594.296143 
	C158.070450,594.117859 157.168610,594.215332 156.274490,594.167725 
	C154.598434,594.078491 152.726013,594.294861 151.347504,593.584290 
	C150.756271,593.279541 150.623154,590.503845 151.296417,589.686218 
	C152.399780,588.346252 154.331451,587.688416 155.913803,586.742859 
	C156.154465,587.155273 156.395126,587.567688 156.635788,587.980042 
	C155.428482,589.111267 154.221176,590.242493 153.013885,591.373718 
	C153.290466,591.791321 153.567062,592.208923 153.843643,592.626526 
	C155.421555,591.619019 157.014618,590.634277 158.572693,589.596924 
	C159.880371,588.726257 161.127487,587.109314 162.437424,587.071533 
	C163.906448,587.029175 165.415970,588.390442 166.907867,589.140930 
	C166.181625,590.510620 165.776855,592.341492 164.645569,593.130188 
	C163.311844,594.059998 161.349197,594.087646 159.314026,594.296143 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M280.099670,644.629761 
	C277.450165,645.206543 274.219574,645.027527 272.275665,646.522034 
	C268.570068,649.370911 269.454224,656.014709 273.328430,659.056274 
	C271.305237,659.242065 271.305237,659.242065 267.337860,645.142090 
	C264.705841,645.288208 262.018646,645.437378 259.331421,645.586487 
	C259.223999,645.096069 259.116547,644.605713 259.009094,644.115295 
	C262.775116,643.216919 266.541107,642.318481 269.349731,641.648499 
	C273.178680,642.173828 276.182159,642.585876 279.616180,643.321289 
	C280.088776,643.971619 280.106415,644.300049 280.099670,644.629761 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M178.665710,423.032806 
	C182.091644,423.016174 185.097275,422.730591 188.079239,422.889526 
	C189.975403,422.990601 191.836395,423.751648 193.713165,424.216736 
	C192.352676,425.415955 190.972214,426.593658 189.642365,427.825958 
	C189.167953,428.265594 188.816223,428.837616 188.162460,429.657928 
	C187.443069,428.203796 186.793655,426.891113 185.655121,424.589722 
	C184.372223,426.862976 183.563583,428.295837 182.762131,429.715973 
	C181.431503,428.138519 180.068176,426.522308 178.151031,424.249542 
	C177.116928,426.790894 176.594604,428.074524 176.213760,429.010468 
	C174.728500,427.245178 173.237488,425.473083 171.116394,422.952087 
	C173.599121,422.952087 175.917267,422.952087 178.665710,423.032806 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M304.826843,721.474365 
	C304.882141,716.903503 308.065643,717.835571 310.283875,718.092224 
	C311.333893,718.213684 312.210815,719.831482 313.166199,720.771301 
	C311.513336,722.394531 310.119202,724.783203 308.143463,725.450989 
	C305.560333,726.324036 302.458221,726.204773 299.699310,725.763184 
	C298.495697,725.570496 296.613129,723.409912 296.732239,722.329712 
	C297.086761,719.114014 299.356995,717.771790 303.356995,718.467590 
	C301.393890,720.095154 299.920135,721.317017 298.446381,722.538818 
	C298.700867,723.009277 298.955353,723.479736 299.209808,723.950195 
	C301.018250,723.242737 302.826691,722.535217 304.826843,721.474365 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M176.273819,527.858276 
	C161.761658,527.919800 147.673325,527.919800 133.584976,527.919800 
	C133.553558,527.434875 133.522141,526.949951 133.490723,526.464966 
	C134.820862,526.310425 136.150269,526.028809 137.481247,526.021118 
	C149.583939,525.951355 161.687225,525.885437 173.789154,525.975952 
	C174.763138,525.983154 175.728348,527.162170 176.273819,527.858276 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M367.774017,702.670288 
	C368.004364,703.106689 368.008972,703.274719 368.017548,703.694336 
	C359.561523,703.793518 355.015564,698.244995 351.469666,691.819092 
	C348.836029,694.609070 346.296844,697.299011 343.370544,699.986328 
	C342.983459,699.983765 342.892059,699.880554 342.892059,699.880554 
	C345.756958,694.618103 348.621857,689.355713 351.600586,683.884155 
	C352.787415,693.033325 357.439453,700.168030 367.774017,702.670288 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M170.264893,415.468811 
	C170.308380,414.515289 170.359833,413.901184 170.411301,413.287079 
	C173.555252,417.168213 175.305145,412.976776 177.811310,411.708374 
	C178.516586,412.890900 179.124100,413.909515 179.896881,415.205231 
	C180.827820,414.414948 181.463623,413.534760 182.303772,413.242889 
	C183.519379,412.820618 184.976807,412.476654 186.143982,412.791901 
	C186.733246,412.951050 187.498672,414.987152 187.156433,415.554718 
	C186.374725,416.851105 184.949860,418.683960 183.804047,418.671448 
	C179.558395,418.625061 175.315277,417.953888 171.087555,417.393982 
	C170.751877,417.349548 170.541489,416.359070 170.264893,415.468811 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M213.556091,748.083130 
	C193.936478,748.074097 174.316879,748.065002 154.195709,748.037109 
	C154.740540,747.641541 155.781433,746.953003 156.834137,746.934387 
	C175.612869,746.603210 194.393051,746.353333 213.653519,746.117554 
	C214.134125,746.149231 214.191345,746.638123 214.050888,747.125977 
	C213.792313,747.770325 213.674210,747.926697 213.556091,748.083130 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M137.824173,753.168945 
	C138.336411,756.353943 138.776459,759.333435 138.745224,762.308105 
	C138.730209,763.738037 137.825516,765.158691 137.323730,766.583557 
	C135.875656,765.051880 132.455368,768.791382 132.129669,763.934692 
	C133.789108,761.393188 135.323776,759.510437 137.448441,756.903870 
	C134.494690,756.903870 132.736954,756.903870 130.979187,756.903870 
	C130.966629,756.390991 130.954071,755.878174 130.898239,754.981934 
	C132.418991,753.173401 133.982986,751.748291 135.381393,750.474060 
	C136.105606,751.301575 136.831940,752.131592 137.824173,753.168945 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M374.000000,670.000366 
	C373.635437,669.699036 373.270874,669.397766 372.906342,669.096375 
	C371.223145,667.704529 369.539886,666.312805 367.856659,664.921021 
	C366.564850,666.632202 365.273041,668.343323 363.860321,670.214600 
	C363.420380,669.449768 362.716736,668.226624 361.851013,666.721741 
	C357.464691,669.824219 359.466492,673.057251 360.973450,676.623291 
	C357.236664,675.029541 356.238251,671.581482 358.159027,667.319641 
	C360.106567,662.998291 365.112732,660.759094 369.143341,662.703552 
	C370.851379,663.527649 372.208374,665.079407 373.876434,666.986328 
	C374.018616,668.448303 374.009308,669.224365 374.000000,670.000366 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M241.333893,524.865601 
	C241.829666,525.281616 241.893463,525.859863 241.863220,526.865723 
	C233.353180,527.167725 224.937378,527.024170 216.521072,526.924622 
	C212.584198,526.878052 208.646332,526.916565 204.708908,526.916565 
	C204.701721,526.432495 204.694534,525.948364 204.687347,525.464294 
	C214.624527,525.464294 224.561829,525.489258 234.498825,525.444519 
	C236.633774,525.434937 238.767593,525.173340 241.333893,524.865601 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M301.776062,755.279541 
	C299.508698,756.218384 297.241364,757.157166 294.611328,758.079651 
	C293.831970,756.384155 293.415283,754.704956 293.285156,752.620239 
	C294.212433,751.864502 295.089966,751.662659 295.428253,751.122864 
	C295.775970,750.568237 295.597260,749.683594 296.137756,748.958130 
	C300.758728,748.998962 304.890869,749.024963 308.902222,749.289429 
	C308.534790,749.998901 308.288116,750.469910 307.610046,750.954712 
	C303.918091,750.576233 301.490570,751.227966 301.776062,755.279541 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M294.310822,730.731323 
	C294.816376,731.645447 294.985321,732.610229 295.060608,733.040222 
	C296.974091,733.531311 298.500427,733.923096 300.140869,734.578613 
	C296.062653,739.181580 291.073456,736.323242 286.399078,736.369629 
	C287.756042,735.214050 289.113007,734.058472 290.841492,732.586548 
	C289.284332,732.157654 288.201813,731.859497 286.769348,731.464966 
	C289.227173,727.492798 291.631287,729.603882 294.310822,730.731323 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M348.085968,639.075806 
	C349.646240,638.379822 349.808533,633.718384 352.795410,637.742798 
	C352.783722,638.063660 352.880707,638.018921 352.977692,637.974243 
	C354.059692,638.574585 355.141693,639.174927 356.547180,639.954773 
	C356.547180,638.593201 356.547180,637.538147 356.547180,636.339905 
	C358.354950,636.038513 360.216248,635.728210 362.186157,635.399841 
	C362.278168,637.369873 362.375824,639.460144 362.498962,642.096863 
	C358.240631,641.744019 354.045135,641.396362 349.438080,640.766357 
	C348.712982,640.014587 348.399475,639.545166 348.085968,639.075806 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M211.591461,429.191376 
	C212.868134,431.062103 213.896988,432.645691 215.349121,434.880798 
	C211.396576,432.222626 208.460800,429.015106 205.002243,428.285370 
	C201.839523,427.618011 198.111374,429.630463 194.767990,430.429565 
	C195.846176,427.693695 196.897171,425.026764 197.490341,423.521606 
	C200.762375,424.324768 204.039505,424.919037 207.156189,425.983185 
	C208.704010,426.511719 209.957184,427.903198 211.591461,429.191376 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M225.038193,595.078552 
	C221.976303,595.228394 218.914413,595.378174 215.852524,595.527954 
	C215.703827,595.121277 215.555130,594.714600 215.406433,594.307922 
	C220.945587,590.827332 216.131165,590.855591 214.448959,588.889709 
	C217.526886,588.889709 220.604828,588.889709 223.889999,589.564636 
	C223.732498,591.160400 223.367752,592.081299 222.874451,593.061401 
	C222.829849,593.079468 222.913818,593.038330 223.327408,593.045898 
	C224.117416,593.755981 224.577805,594.417236 225.038193,595.078552 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M318.830353,577.595520 
	C317.634125,577.796082 316.065216,577.929443 315.514587,577.244385 
	C313.498932,574.736511 311.822235,571.956177 309.987915,568.915894 
	C318.898804,567.341431 318.929504,567.370850 318.830353,577.595520 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M185.469177,601.970703 
	C184.587128,601.781311 184.103836,601.523987 183.369934,601.154175 
	C183.584885,597.603149 187.298965,596.628601 191.039413,598.955200 
	C191.829651,599.446716 192.597336,600.095154 193.462616,600.296265 
	C193.919067,600.402405 194.563522,599.700073 195.181656,599.327393 
	C195.599594,600.732361 196.039124,602.209900 196.337250,603.212036 
	C194.381760,603.212036 192.459457,603.212036 189.672974,603.212036 
	C189.063492,603.002319 187.465714,602.452515 185.469177,601.970703 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M164.096619,732.159912 
	C165.553238,732.055359 167.009872,731.950867 168.468628,731.846191 
	C167.840073,724.820740 164.138519,721.140991 157.910675,721.174500 
	C155.605194,721.186951 153.300339,721.318604 150.517548,721.151062 
	C150.036530,720.623718 150.033173,720.341125 150.029800,720.058533 
	C151.513504,720.011169 153.042007,720.178833 154.473526,719.880737 
	C162.548065,718.199524 167.345337,721.653320 170.580856,728.970947 
	C171.993027,732.164795 172.169403,734.456238 169.763000,737.243835 
	C169.328583,737.313721 169.145111,737.148071 168.773376,736.481812 
	C168.707535,735.657227 168.829956,735.333130 169.177231,734.876282 
	C169.364441,734.261414 169.326797,733.779297 169.289154,733.297241 
	C168.904846,733.378540 168.520554,733.459839 167.735504,733.636108 
	C166.243820,733.670349 165.152878,733.609497 164.071350,733.316528 
	C164.051697,732.775574 164.056992,732.467407 164.096619,732.159912 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M278.681305,557.694641 
	C278.236664,563.452881 276.995056,563.917603 272.236847,561.482361 
	C270.947235,560.822388 268.756134,561.923767 266.798737,562.246399 
	C267.946808,560.426025 268.984100,558.781311 269.865326,557.384033 
	C272.814178,557.384033 275.634644,557.384033 278.681305,557.694641 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M264.878815,485.028748 
	C266.514984,484.689911 268.141602,484.290405 269.790344,484.031616 
	C271.081360,483.828979 272.399750,483.800568 273.705963,483.694733 
	C273.793579,485.134033 274.435669,486.926422 273.808868,487.910797 
	C273.212555,488.847260 271.319855,489.084595 269.943298,489.362823 
	C268.535675,489.647308 267.062561,489.607880 265.143311,489.596741 
	C264.738617,487.998383 264.808716,486.513580 264.878815,485.028748 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M303.040527,637.843628 
	C303.869995,638.953613 305.259827,639.990601 305.415802,641.188293 
	C305.773407,643.933777 305.528961,646.757690 305.528961,650.277039 
	C300.916809,650.000732 296.565704,652.431458 292.966919,648.452881 
	C294.311920,647.991150 295.195740,648.002075 296.294983,648.358276 
	C298.856873,648.703613 301.203400,648.703613 305.089325,648.703613 
	C303.585693,645.438293 302.526947,643.139160 301.650238,640.458008 
	C302.234985,639.331848 302.637756,638.587708 303.040527,637.843628 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M374.018188,670.380676 
	C374.009308,669.224365 374.018616,668.448303 374.048950,667.283569 
	C374.286774,666.946899 374.503601,666.999084 375.051849,667.119080 
	C375.592865,670.595581 375.936981,674.002808 375.975037,677.413513 
	C376.014648,680.960388 373.133087,679.699890 370.855530,680.000671 
	C369.589081,680.006104 368.709381,680.027283 367.397156,680.092712 
	C369.321869,677.011658 371.679108,673.886353 374.018188,670.380676 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M225.349548,594.944824 
	C224.577805,594.417236 224.117416,593.755981 223.328522,593.047363 
	C223.000000,593.000000 223.003006,593.002136 223.003006,593.002136 
	C223.367752,592.081299 223.732498,591.160400 224.175354,589.869812 
	C225.341843,590.223755 226.430222,590.947510 227.541183,591.686279 
	C227.753204,591.535461 228.028183,591.373047 228.264496,591.166504 
	C229.884369,589.750427 231.209488,586.986633 233.824570,589.436584 
	C234.473984,590.045044 234.681656,591.929810 234.230698,592.759583 
	C232.784378,595.420959 230.749908,595.976562 228.248825,593.704102 
	C227.959961,593.441589 226.550720,594.412170 225.349548,594.944824 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M216.598572,575.113281 
	C217.066864,575.499634 217.086685,575.802124 217.128799,576.329834 
	C209.323151,576.710449 201.513794,576.864563 193.256317,576.578735 
	C192.851486,575.784912 192.894791,575.431091 192.938080,575.077209 
	C200.669235,575.061646 208.400375,575.046082 216.598572,575.113281 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M357.393127,712.005005 
	C356.580505,711.586792 356.243561,711.172058 355.800995,710.501648 
	C363.024475,710.245972 370.353516,710.245972 377.733887,710.245972 
	C377.693726,710.432007 377.612427,711.283386 377.494202,711.288513 
	C370.954346,711.573303 364.411407,711.787292 357.393127,712.005005 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M335.000549,715.999451 
	C331.490875,713.905212 333.702881,711.994812 335.339172,710.315918 
	C336.325531,709.303894 337.751587,708.720520 339.423767,707.910400 
	C339.867523,707.876221 340.139923,708.119324 340.107727,708.495239 
	C341.450928,709.062317 342.834503,709.208374 344.199677,709.455994 
	C345.861572,709.757385 347.507965,710.144165 349.504761,710.566711 
	C348.419678,711.985107 347.666779,712.969238 346.899109,713.972778 
	C341.590912,709.215881 336.429718,710.232605 334.970764,716.029785 
	C334.980682,716.019653 335.000549,715.999451 335.000549,715.999451 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M289.999603,750.994446 
	C287.317230,751.845947 284.666809,752.874695 281.920166,753.370056 
	C281.475342,753.450317 279.742615,750.577576 280.084045,750.075439 
	C281.299835,748.287537 283.154266,746.933899 284.766876,745.415833 
	C285.160309,745.841553 285.553711,746.267273 285.947144,746.692993 
	C284.657410,747.821350 283.367645,748.949707 282.077911,750.078064 
	C282.215271,750.456787 282.352631,750.835510 282.489990,751.214233 
	C284.348846,750.781616 286.207703,750.349060 288.717590,750.032776 
	C289.578949,750.430908 289.789276,750.712646 289.999603,750.994446 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M182.556335,616.187988 
	C182.782700,613.546265 183.312057,611.161743 183.841400,608.777222 
	C184.550171,608.775757 185.258942,608.774292 185.967697,608.772827 
	C186.707153,610.666382 187.446594,612.559998 188.186050,614.453552 
	C186.410477,615.117432 184.634888,615.781311 182.556335,616.187988 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M280.544312,644.688110 
	C280.106415,644.300049 280.088776,643.971619 280.048645,643.397461 
	C283.467072,642.725159 286.883545,642.299866 290.860107,642.211426 
	C292.190277,642.709595 292.960358,642.870972 293.821533,643.395996 
	C293.933441,644.505310 293.954285,645.250916 293.596313,646.023071 
	C292.710968,646.068054 292.204376,646.086487 291.697815,646.104919 
	C288.128204,645.652100 284.558594,645.199280 280.544312,644.688110 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M197.178986,647.649292 
	C196.573898,647.939453 196.198853,647.911987 195.483429,647.480835 
	C195.126709,645.716492 195.110352,644.355774 195.093979,642.995056 
	C195.538467,642.968384 195.982971,642.941711 196.762451,642.916626 
	C199.603012,643.084473 202.108582,643.250671 204.837250,643.670288 
	C205.068192,644.260071 205.001831,644.580994 204.820282,645.130737 
	C202.322525,646.027100 199.865784,646.679382 197.178986,647.649292 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M297.401855,508.098511 
	C296.712769,507.470612 296.428925,506.958282 296.034180,506.173462 
	C298.023865,505.840485 300.133728,505.876526 302.220795,505.675873 
	C303.162140,505.585388 304.058136,505.023132 305.212646,504.378571 
	C306.287750,507.054932 307.124939,510.027618 307.936035,513.378784 
	C307.006714,513.554260 305.327515,513.391602 305.314606,513.142517 
	C305.047455,507.985962 301.068298,508.664978 297.401855,508.098511 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M387.644287,724.831543 
	C386.865295,724.747742 386.212280,724.381165 385.613983,723.940430 
	C384.268433,722.949036 382.950012,721.920776 381.620544,720.907532 
	C383.265900,719.835022 384.911224,718.762573 387.795105,716.882751 
	C387.795105,720.457886 387.795105,722.486450 387.644287,724.831543 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M290.328461,750.986694 
	C289.789276,750.712646 289.578949,750.430908 289.104553,750.067017 
	C288.840485,748.858826 288.840485,747.732788 288.840485,746.263855 
	C291.093781,746.263855 293.143860,746.263855 295.537354,746.345215 
	C295.880768,746.426636 295.693817,746.718689 295.733032,747.169067 
	C295.731171,748.060730 295.690063,748.502075 295.648926,748.943359 
	C295.597260,749.683594 295.775970,750.568237 295.428253,751.122864 
	C295.089966,751.662659 294.212433,751.864502 293.246704,752.225037 
	C292.166901,751.816528 291.412109,751.397705 290.328461,750.986694 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M181.895447,582.003723 
	C179.962814,581.817993 178.030182,581.632263 174.737381,581.315857 
	C178.671310,578.190063 181.398483,576.023132 184.232086,574.520081 
	C184.244919,576.652283 184.151306,578.120667 184.057709,579.588989 
	C184.057709,579.588989 184.023575,579.508301 183.766357,579.701721 
	C182.971237,580.598022 182.433350,581.300842 181.895447,582.003723 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M235.220169,649.176575 
	C238.800156,648.947937 242.382584,649.019958 246.409119,649.137939 
	C246.901550,650.144897 246.949844,651.105896 246.980576,651.717224 
	C244.979568,651.444702 243.155350,651.196167 241.331116,650.947693 
	C242.079147,651.803223 242.827179,652.658691 243.493835,654.043579 
	C241.292267,654.740234 239.172089,654.907593 237.034760,654.712280 
	C237.482407,653.104675 237.947220,651.859741 238.523514,650.316162 
	C237.303650,650.228882 236.236313,650.152466 235.168961,650.076111 
	C235.186844,649.876465 235.204727,649.676880 235.220169,649.176575 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M394.816528,719.749756 
	C394.985046,720.488770 394.983063,720.942749 395.009216,721.736389 
	C393.503296,723.049377 391.969238,724.022583 390.435181,724.995850 
	C390.100037,723.556824 389.141235,721.894836 389.559387,720.725098 
	C390.431488,718.285461 392.408142,717.725037 394.816528,719.749756 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M347.846558,639.306458 
	C348.399475,639.545166 348.712982,640.014587 349.049561,640.784851 
	C346.558289,640.637817 344.043915,640.189819 341.142517,639.557373 
	C340.191803,638.483582 339.628052,637.594238 339.047546,636.427673 
	C341.368561,636.258789 343.706268,636.367065 346.626160,636.502319 
	C346.721985,636.798767 347.164581,638.167908 347.846558,639.306458 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M275.047791,441.132019 
	C274.931671,443.450897 275.690674,446.364014 271.293213,446.102417 
	C267.577209,446.370056 268.028839,443.977570 268.376740,441.519958 
	C270.878998,441.105377 272.963379,441.118713 275.047791,441.132019 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M292.819519,758.871033 
	C292.402618,760.705322 291.729187,762.316956 290.654236,764.433350 
	C289.169067,763.953979 288.085388,762.969788 286.990234,761.768799 
	C286.978790,761.551880 286.952667,761.118408 287.214844,760.913574 
	C287.716736,759.860535 287.956421,759.012268 288.196075,758.164062 
	C289.651703,758.325500 291.107330,758.487000 292.819519,758.871033 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M184.482834,579.608643 
	C184.151306,578.120667 184.244919,576.652283 184.496582,574.760376 
	C184.654648,574.336731 184.696915,574.231262 184.722504,574.180786 
	C187.208588,574.392273 189.669067,574.654297 192.533813,574.996765 
	C192.894791,575.431091 192.851486,575.784912 192.801483,576.408447 
	C190.165833,577.661438 187.536911,578.644836 184.482834,579.608643 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M245.523590,607.062134 
	C245.063522,606.636597 245.046616,606.146362 245.039124,605.288208 
	C246.684494,604.929077 248.321625,604.901306 249.956268,604.952820 
	C257.032990,605.175964 257.559967,606.037048 254.462906,613.952820 
	C255.832245,605.006836 250.050629,607.283142 245.523590,607.062134 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M275.410980,441.033997 
	C272.963379,441.118713 270.878998,441.105377 268.345581,441.123779 
	C265.874390,441.103119 263.852173,441.050751 261.401489,440.915527 
	C260.962921,440.474182 260.952789,440.115753 260.933716,439.488464 
	C266.311768,439.133911 271.698761,439.048187 277.550751,439.028992 
	C278.017395,439.496582 278.019073,439.897583 278.021667,440.598633 
	C277.273102,440.911072 276.523621,440.923523 275.410980,441.033997 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M243.579910,489.079651 
	C243.156586,487.691467 243.099136,486.110596 243.187927,484.073669 
	C247.066025,484.970612 250.797913,486.323547 253.321335,487.238373 
	C251.164810,487.617584 247.555298,488.252258 243.579910,489.079651 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M264.796478,484.677185 
	C264.808716,486.513580 264.738617,487.998383 264.829193,489.731995 
	C263.177185,489.619171 261.235779,489.523560 259.625092,488.744354 
	C259.096802,488.488800 259.054352,485.551453 259.428528,485.397980 
	C261.062225,484.728027 262.930817,484.630798 264.796478,484.677185 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M293.730438,643.032349 
	C292.960358,642.870972 292.190277,642.709595 291.225830,642.323730 
	C292.609467,635.155212 298.007446,637.759460 302.668640,637.718018 
	C302.637756,638.587708 302.234985,639.331848 301.458313,640.078369 
	C295.649078,637.808899 294.618958,638.222351 293.730438,643.032349 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M236.077286,679.186646 
	C240.715149,678.872437 245.326233,678.891541 250.385620,678.843994 
	C250.900131,679.203552 250.966339,679.629761 251.097672,680.368896 
	C246.392319,680.775330 241.621857,680.868774 236.378769,680.903809 
	C235.954254,680.403625 236.002365,679.961792 236.077286,679.186646 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M363.081909,645.413940 
	C366.030121,648.269226 365.033173,651.000671 362.875580,653.398132 
	C362.238220,654.106323 360.712158,654.014709 360.329651,654.108704 
	C361.223572,650.870239 361.977936,648.137268 363.081909,645.413940 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M391.711548,687.963989 
	C393.348389,690.890625 394.685089,694.068359 396.022675,697.630493 
	C394.486176,694.748352 392.948792,691.481750 391.711548,687.963989 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M287.909973,757.972839 
	C287.956421,759.012268 287.716736,759.860535 286.856140,760.826050 
	C284.813263,760.591492 283.391327,760.239624 281.969391,759.887695 
	C283.152740,757.302307 284.293488,754.602478 287.909973,757.972839 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M343.028137,700.036255 
	C340.741180,703.527405 337.308929,704.273376 333.250702,703.573608 
	C336.218597,702.352905 339.186462,701.132263 342.523193,699.896057 
	C342.892059,699.880554 342.983459,699.983765 343.028137,700.036255 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M234.863815,650.256348 
	C236.236313,650.152466 237.303650,650.228882 238.523514,650.316162 
	C237.947220,651.859741 237.482407,653.104675 236.908890,654.963501 
	C236.348541,656.174988 235.896896,656.772522 235.445251,657.370056 
	C235.283310,657.238220 235.121384,657.106384 234.713303,656.776855 
	C234.497650,654.531677 234.528168,652.484131 234.863815,650.256348 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M281.777893,760.185547 
	C283.391327,760.239624 284.813263,760.591492 286.593933,761.030884 
	C286.952667,761.118408 286.978790,761.551880 286.596802,761.859619 
	C283.517365,762.364929 280.819946,762.562439 278.076965,762.481323 
	C277.980621,761.831787 277.929779,761.460876 277.878906,761.090027 
	C277.878906,761.090027 278.029358,760.964233 278.102844,760.895020 
	C279.313019,760.711670 280.449707,760.597534 281.777893,760.185547 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M252.838501,645.228760 
	C252.626602,644.916443 252.830704,644.574524 252.868713,644.379150 
	C253.682800,642.804443 254.458862,641.425110 255.234940,640.045837 
	C255.632080,640.086182 256.029236,640.126465 256.426392,640.166809 
	C256.426392,642.081360 256.426392,643.995972 256.141266,645.950500 
	C254.920883,645.840698 253.985641,645.690918 252.838501,645.228760 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M144.264404,757.803833 
	C143.399628,757.495728 142.817459,756.989441 142.154175,756.200684 
	C142.435242,754.497437 142.797424,753.076538 143.209564,751.265137 
	C144.051758,751.295471 144.844009,751.716431 145.956177,752.272705 
	C145.699738,754.140503 145.123367,755.873108 144.264404,757.803833 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M404.950836,704.710327 
	C404.885559,705.055054 404.695923,705.098145 404.240234,705.263062 
	C402.280090,704.156921 400.585938,702.928955 398.939392,701.336792 
	C400.933472,702.118042 402.879944,703.263367 404.950836,704.710327 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M152.022339,747.695129 
	C150.022064,748.455627 147.725204,748.893982 145.229553,749.654297 
	C144.638901,750.023254 144.247040,750.070129 143.562531,750.151978 
	C144.281372,749.118652 145.131104,747.458069 146.343872,747.126892 
	C147.992462,746.676697 149.918610,747.242676 152.022339,747.695129 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M241.182098,484.176758 
	C241.566727,485.279480 241.611313,486.420776 241.422760,487.757141 
	C240.345520,487.363190 239.501450,486.774231 238.657379,486.185242 
	C239.385620,485.528625 240.113846,484.871979 241.182098,484.176758 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M291.774078,646.365234 
	C292.204376,646.086487 292.710968,646.068054 293.776794,646.198853 
	C295.361847,645.696106 296.387604,645.044128 297.615265,644.613159 
	C297.962982,644.966003 298.108795,645.097900 298.141479,645.506287 
	C297.378754,646.526123 296.729156,647.269531 296.079559,648.012939 
	C295.195740,648.002075 294.311920,647.991150 292.987244,648.030029 
	C292.314362,647.595093 292.082336,647.110352 291.774078,646.365234 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M244.003815,526.805298 
	C243.555069,526.399780 243.439056,525.860718 243.520477,525.188660 
	C244.790680,525.265747 245.863419,525.475830 248.037918,525.901611 
	C246.142380,526.295959 245.239441,526.483887 244.003815,526.805298 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M342.719452,723.233704 
	C341.415924,722.171387 340.112366,721.109009 338.844116,719.585815 
	C338.879425,719.124939 339.182709,718.788452 339.486267,718.862854 
	C340.199188,718.966919 340.608551,718.996582 341.008728,719.013977 
	C340.999542,719.001709 340.974823,718.984131 341.013947,719.314331 
	C341.727295,720.740234 342.401489,721.835938 342.987823,723.008545 
	C342.899933,723.085449 342.719452,723.233704 342.719452,723.233704 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M306.761902,446.458221 
	C307.507599,446.162781 308.433044,446.115692 309.820892,446.103790 
	C309.278412,446.969940 308.721161,450.436340 306.761902,446.458221 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M360.904236,677.386658 
	C361.818726,677.683167 362.637024,678.327881 363.218353,679.189819 
	C362.256927,678.849670 361.532501,678.292297 360.904236,677.386658 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M149.714966,720.139160 
	C150.033173,720.341125 150.036530,720.623718 150.099777,721.331116 
	C149.968384,722.112610 149.777115,722.469238 149.102264,722.923340 
	C148.424179,723.019409 148.229660,723.017944 148.035126,723.016479 
	C148.490128,722.084229 148.945129,721.151978 149.714966,720.139160 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M272.438538,382.829712 
	C272.107117,383.503021 271.438873,384.253998 270.440796,385.037598 
	C270.774597,384.349274 271.438141,383.628326 272.438538,382.829712 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M182.287506,582.050110 
	C182.433350,581.300842 182.971237,580.598022 183.808060,579.688354 
	C184.332657,580.082336 184.558319,580.683228 184.519684,581.737427 
	C183.730103,582.159363 183.204834,582.127930 182.287506,582.050110 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M241.125153,477.172668 
	C241.454742,477.606171 241.572830,478.158844 241.473663,478.834167 
	C241.142136,478.401825 241.027878,477.846832 241.125153,477.172668 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M395.943604,698.330566 
	C396.611359,698.458252 397.234802,698.942749 397.942841,699.720581 
	C397.318054,699.571716 396.608643,699.129456 395.943604,698.330566 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M357.029663,729.337402 
	C356.828674,730.107666 356.593292,730.393799 356.357880,730.679993 
	C356.325104,729.854309 356.292358,729.028564 356.535583,728.051514 
	C356.872772,728.217834 356.934021,728.535583 357.029663,729.337402 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M344.044830,723.767456 
	C343.883026,724.404114 343.721252,725.040833 343.261353,725.834106 
	C342.925354,725.300659 342.887451,724.610657 342.784515,723.577148 
	C342.719452,723.233704 342.899933,723.085449 343.212646,723.062622 
	C343.827911,723.190002 344.001068,723.432495 344.044830,723.767456 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M336.593323,717.392029 
	C336.129700,717.101135 335.666107,716.810242 335.101532,716.259399 
	C335.000549,715.999451 334.980682,716.019653 335.281342,715.907837 
	C336.100494,716.148254 336.618988,716.500549 337.002319,716.988525 
	C336.867096,717.124146 336.593323,717.392029 336.593323,717.392029 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M147.599380,724.991638 
	C147.822571,725.549622 147.673813,726.139465 147.237396,726.860596 
	C147.042313,726.335754 147.134872,725.679626 147.599380,724.991638 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M147.909302,723.342163 
	C148.229660,723.017944 148.424179,723.019409 148.912399,723.020020 
	C148.983231,723.602539 148.760330,724.185852 148.244049,724.880737 
	C147.894958,724.550781 147.839218,724.109375 147.909302,723.342163 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M337.569122,718.418457 
	C337.253967,718.299255 337.007812,718.096375 336.711975,717.600891 
	C336.593323,717.392029 336.867096,717.124146 337.226196,717.090149 
	C337.864990,717.252808 338.055817,717.514343 338.010376,717.984741 
	C337.862854,718.128845 337.569122,718.418457 337.569122,718.418457 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M338.723083,719.288818 
	C338.275330,719.297546 338.028076,719.089844 337.697052,718.624023 
	C337.569122,718.418457 337.862854,718.128845 338.231934,718.080200 
	C338.875549,718.221863 339.069427,718.474182 339.182709,718.788452 
	C339.182709,718.788452 338.879425,719.124939 338.723083,719.288818 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M146.626221,726.882202 
	C146.716568,727.321777 146.448105,727.689758 145.819824,728.017761 
	C145.729141,727.588684 145.998260,727.199585 146.626221,726.882202 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M398.009033,700.263367 
	C398.285736,700.146240 398.589233,700.318726 398.959351,700.754333 
	C398.695953,700.862671 398.365875,700.707825 398.009033,700.263367 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M341.737854,705.712463 
	C341.948578,705.443359 342.159302,705.174194 342.630402,704.891968 
	C342.890747,704.878967 342.946777,704.942810 342.920380,705.226135 
	C342.615601,705.692627 342.337189,705.875732 341.977173,705.973511 
	C341.895538,705.888184 341.737823,705.712463 341.737854,705.712463 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M340.637543,706.617126 
	C340.754242,706.288208 340.967529,706.043579 341.507629,705.797852 
	C341.737823,705.712463 341.895538,705.888184 341.903809,706.213440 
	C341.643951,706.727966 341.375824,706.917236 340.989319,706.984985 
	C340.870972,706.863403 340.637543,706.617126 340.637543,706.617126 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M339.732147,707.753784 
	C339.738495,707.325012 339.943542,707.069519 340.424683,706.740967 
	C340.637543,706.617126 340.870972,706.863403 340.902771,707.215454 
	C340.742920,707.856934 340.478027,708.040894 340.139923,708.119324 
	C340.139923,708.119324 339.867523,707.876221 339.732147,707.753784 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M265.517975,684.981201 
	C274.976715,684.939758 283.981781,685.122620 292.964325,684.755432 
	C294.645905,684.686646 297.659424,682.398865 297.644501,681.164246 
	C297.530609,671.728455 303.771484,666.913269 310.723145,662.080627 
	C314.542084,661.372803 315.703278,663.232605 315.715912,666.406677 
	C315.730927,670.177002 315.862488,673.946716 315.908966,677.717102 
	C315.995117,684.705688 315.984680,684.705811 323.535248,685.424072 
	C323.990417,691.284546 324.009735,696.613281 324.014648,702.331238 
	C324.009644,703.499207 324.019043,704.278015 324.023254,705.474731 
	C322.820374,706.614197 321.665802,707.853943 320.416687,707.957947 
	C316.995117,708.242920 313.533752,708.050171 309.690857,708.028809 
	C308.495850,708.024719 307.698090,708.038513 306.900360,708.052368 
	C243.208572,708.806091 179.516785,709.559753 114.921188,710.324158 
	C114.921188,702.375732 114.921188,695.133545 114.896973,687.417847 
	C116.035049,686.909241 117.205231,686.769897 118.358208,686.857422 
	C122.671814,687.184753 124.261856,685.262878 124.056435,680.960999 
	C123.780457,675.181458 123.988037,669.378845 123.988037,663.589355 
	C131.940582,663.655701 139.516174,670.783203 141.119873,679.295654 
	C142.408783,686.137329 142.408768,686.124878 149.517715,686.111023 
	C162.993683,686.084717 176.473740,686.260864 189.943771,685.968994 
	C195.655869,685.845215 200.953415,685.887451 204.640762,691.422241 
	C203.574402,691.422241 202.544464,691.422241 201.514511,691.422241 
	C201.392883,691.777527 201.271255,692.132751 201.149628,692.488037 
	C202.254868,692.848328 204.200623,693.711060 204.332916,693.489746 
	C205.429947,691.654541 206.191772,689.619019 207.375885,686.909790 
	C208.109940,688.605713 208.823196,690.253479 209.640610,692.141907 
	C209.901199,690.517578 210.151001,688.960388 210.400818,687.403198 
	C210.679810,687.352356 210.958801,687.301514 211.237793,687.250610 
	C211.540512,688.168030 211.919830,689.068054 212.132065,690.005920 
	C212.867905,693.257568 214.056152,693.973755 217.744492,692.914429 
	C221.724823,691.771240 226.274292,692.642334 230.574295,692.567200 
	C233.156021,692.522156 235.735504,692.348145 238.081299,691.241699 
	C239.702286,691.965149 241.323257,692.688599 242.794266,693.345154 
	C242.162628,690.806091 241.692307,688.915527 241.222000,687.024902 
	C243.029694,686.595459 244.821091,685.896240 246.648056,685.785950 
	C252.777359,685.415771 258.919342,685.255737 265.517975,684.981201 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M265.483032,681.932129 
	C275.651459,681.917786 285.356476,681.917786 295.061462,681.917786 
	C295.072510,682.297546 295.083557,682.677246 295.094604,683.057007 
	C285.685699,683.057007 276.276825,683.057007 266.392517,683.104858 
	C239.450623,683.462830 212.983444,683.727173 186.517868,684.103210 
	C174.192200,684.278320 161.869965,684.686462 149.545212,684.943604 
	C147.873398,684.978516 146.196060,684.748474 144.521271,684.641113 
	C144.552200,684.221802 144.583115,683.802490 144.614029,683.383179 
	C184.749222,682.904297 224.884430,682.425354 265.483032,681.932129 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M233.285919,688.789856 
	C234.610153,687.897522 235.882874,687.355286 237.155594,686.813049 
	C237.295364,687.465454 237.435120,688.117920 237.574890,688.770325 
	C236.128052,688.893494 234.681229,689.016724 233.285919,688.789856 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M54.987309,513.000732 
	C54.971188,519.421204 54.971188,525.838684 54.971188,532.256104 
	C54.526276,532.237671 54.081364,532.219177 53.636452,532.200745 
	C51.494057,522.936523 51.320496,522.823059 41.265556,524.466370 
	C41.089340,526.924744 40.905300,529.492249 40.721260,532.059814 
	C40.545822,532.052795 40.370384,532.045776 40.194946,532.038757 
	C40.194946,525.739014 40.194946,519.439209 40.194946,513.139465 
	C40.383133,513.133362 40.571323,513.127258 40.759510,513.121216 
	C40.906094,515.866638 41.052673,518.612122 41.212994,521.614929 
	C42.572624,521.745239 43.683182,521.930298 44.796055,521.945496 
	C52.005112,522.043457 51.977409,522.027466 52.952866,515.030640 
	C53.045738,514.364380 53.768639,513.785950 54.597931,513.098328 
	C54.996807,513.029907 55.003429,512.997681 54.987309,513.000732 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M135.964600,807.082764 
	C139.453339,807.011292 142.945618,807.001465 146.427078,806.802673 
	C146.958252,806.772339 147.439453,805.867188 148.313873,805.179260 
	C152.254486,805.652222 155.807083,806.727112 159.399384,806.882080 
	C165.865448,807.161133 165.875122,806.930847 165.881943,800.342773 
	C165.882629,799.679932 165.888840,799.017029 166.294861,798.200684 
	C167.829147,798.106262 168.961060,798.165405 170.067963,798.667847 
	C170.040726,800.893921 170.038498,802.676697 170.075287,804.800049 
	C170.001511,808.543518 171.030609,810.530579 175.276825,809.011963 
	C177.032654,808.754517 178.409485,808.483887 180.281464,808.288818 
	C181.093124,808.470947 181.409637,808.577454 182.034180,808.813660 
	C185.087372,808.943420 187.832489,808.943420 190.577621,808.943420 
	C190.622986,808.334473 190.668335,807.725464 190.713684,807.116516 
	C189.104614,806.566406 187.495544,806.016296 185.857941,805.462219 
	C185.829391,805.458252 185.783325,805.422607 186.077606,805.102295 
	C190.227615,804.728394 191.201828,802.277649 191.661652,798.745728 
	C192.495697,798.418213 192.850128,798.345032 193.153320,798.678345 
	C193.469040,800.701172 193.835999,802.317505 194.202957,803.933960 
	C195.523285,803.040833 196.834930,802.134460 198.171448,801.266296 
	C198.426987,801.100281 198.780487,801.085083 199.270508,800.932007 
	C199.452240,800.863770 199.840454,800.868469 199.843094,801.250610 
	C197.829697,803.734680 195.813660,805.836609 193.166489,808.596619 
	C199.215744,809.490601 205.301544,809.063721 211.821304,808.873291 
	C211.821304,804.938293 211.821304,801.644226 212.202377,798.288086 
	C213.097046,798.231689 213.610641,798.237427 214.072617,798.688354 
	C214.020996,803.218323 214.020996,807.303101 214.020996,812.187622 
	C199.154816,812.187622 185.012466,812.187622 170.870102,812.187622 
	C177.593994,813.019714 184.283707,812.912903 190.967834,813.059814 
	C194.983917,813.148010 198.991287,813.631836 202.899216,814.296997 
	C202.532776,816.101624 202.269699,817.547241 201.648041,818.970825 
	C200.550827,819.943542 199.812195,820.938232 198.681885,821.996338 
	C196.743317,822.120911 195.196426,822.181946 193.649536,822.242981 
	C192.424622,820.571716 191.428024,818.638611 189.904327,817.309937 
	C188.629105,816.197998 186.765427,815.258301 185.114319,815.173218 
	C179.909897,814.904846 174.682938,815.074219 168.944458,815.074219 
	C168.944458,826.019836 169.007172,836.406921 168.848160,846.790527 
	C168.832138,847.836853 167.475357,848.862610 166.397125,849.979004 
	C165.806885,849.413879 165.560333,848.767639 165.031555,847.906616 
	C164.749359,837.347534 164.749359,827.003174 164.749359,816.233826 
	C162.071625,816.026917 160.496948,815.905273 158.138123,815.584229 
	C150.199005,815.521362 143.044037,815.657898 135.903442,815.522949 
	C135.903793,814.889099 135.889771,814.526672 136.321014,814.101868 
	C138.702942,813.930664 140.639618,813.821960 142.475006,813.718872 
	C144.124008,808.040466 140.816925,807.847351 136.929382,807.906006 
	C136.447815,807.566589 136.206223,807.324646 135.964600,807.082764 
M163.312256,808.840759 
	C158.511932,808.854126 153.704636,808.730103 148.915909,808.972412 
	C147.635483,809.037292 146.411713,810.221497 145.161911,810.891846 
	C146.474350,811.668640 147.758926,813.052612 149.104218,813.114319 
	C154.221863,813.349365 159.363586,813.262878 164.485779,813.047119 
	C165.691940,812.996216 166.857040,811.971191 168.041016,811.394348 
	C166.779480,810.543396 165.517929,809.692505 163.312256,808.840759 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M241.912491,817.367920 
	C239.029022,818.369263 239.698120,820.267883 240.852203,822.946594 
	C241.007462,824.140015 240.990967,824.632568 240.974487,825.125061 
	C240.676285,831.729309 240.083557,838.340515 240.237534,844.934204 
	C240.292389,847.283630 240.617920,851.458923 240.436691,854.236328 
	C240.040054,860.314941 239.590118,866.556213 240.551834,872.506409 
	C241.367477,877.552856 246.542801,875.707886 250.473328,876.290100 
	C250.865585,876.465271 250.943130,876.768677 250.943130,876.768677 
	C237.299881,876.962402 240.280197,877.119812 240.159897,887.861694 
	C240.113449,892.008606 239.678558,896.331787 240.684662,900.250122 
	C241.424545,903.131714 244.090118,905.605591 246.246124,907.939087 
	C246.553802,908.272034 248.890671,906.884888 250.179474,906.112122 
	C251.422943,905.366577 252.525482,904.386108 253.689926,903.508850 
	C253.987122,903.872131 254.284332,904.235474 254.581528,904.598755 
	C253.993805,905.446411 253.406082,906.294067 252.818359,907.141724 
	C252.912903,907.607178 253.007446,908.072632 253.101990,908.538025 
	C257.448212,907.968445 261.794403,907.398804 266.543213,907.255859 
	C266.612305,908.531067 266.278839,909.379517 265.945374,910.227966 
	C262.968231,910.244568 259.959198,910.002991 257.024902,910.365845 
	C254.971451,910.619751 253.020157,911.699463 250.695114,912.527161 
	C247.458481,910.557129 243.455048,908.120361 239.352325,905.623169 
	C235.466690,908.154724 231.431686,910.783630 228.793747,912.502319 
	C223.790329,911.719604 219.671783,911.075317 215.267151,909.967590 
	C214.988968,909.333374 214.996872,909.162659 215.004791,908.991943 
	C215.437576,908.982605 215.870377,908.973267 217.056671,909.012695 
	C219.254639,908.950500 220.699097,908.839417 222.143555,908.728394 
	C223.424545,908.728394 224.705521,908.728394 226.790009,908.728394 
	C225.538818,907.157837 224.735153,906.149048 223.940872,905.152039 
	C224.715927,902.935791 227.657166,904.418518 228.797699,907.560791 
	C228.907654,907.863647 229.121567,908.128845 229.290863,908.409363 
	C234.045135,908.330139 238.206116,904.597900 238.272614,900.100159 
	C238.366058,893.781250 238.409134,887.454407 238.174683,881.142090 
	C238.102646,879.202087 237.051636,877.298523 236.291672,874.877258 
	C240.081268,868.776855 237.294922,861.443481 237.854019,853.476929 
	C237.899231,850.268127 237.878876,847.986694 237.858521,845.705322 
	C237.858521,838.995239 237.858521,832.285217 237.858521,824.744812 
	C233.448822,827.668274 229.981018,829.967285 226.229965,832.454041 
	C226.072525,832.083435 225.511444,830.762634 225.495850,830.725952 
	C219.348495,829.726685 214.024124,828.861206 208.365845,827.609741 
	C208.055771,826.911926 208.055954,826.600037 208.419449,826.180908 
	C211.120529,826.599609 213.434662,827.125427 216.133423,827.752075 
	C218.019547,827.559570 219.521057,827.266235 221.394073,827.075256 
	C222.795929,827.177673 223.826263,827.177673 225.451996,827.177673 
	C224.220673,825.861145 223.559311,825.153992 222.897964,824.446899 
	C223.181747,824.032715 223.465530,823.618530 223.749313,823.204346 
	C225.156677,824.101074 226.539993,825.038940 227.980240,825.879211 
	C228.870056,826.398254 230.320938,827.394836 230.706406,827.102905 
	C232.999435,825.366150 235.394836,823.551514 236.997940,821.237854 
	C237.526337,820.475220 235.770538,818.163208 235.122513,816.534546 
	C234.887680,815.944397 234.837463,815.280884 234.968811,814.604370 
	C235.786057,814.937500 236.337463,815.317017 237.508026,816.122620 
	C237.041031,813.653992 236.720551,811.959900 236.710114,809.999756 
	C237.039825,808.122131 237.059494,806.510437 237.108398,804.581787 
	C237.092026,803.843140 237.046432,803.421570 236.944748,802.734314 
	C236.247406,802.162415 235.606155,801.856201 234.969452,801.121521 
	C234.976608,800.122314 234.979233,799.551514 235.373810,798.959351 
	C237.570419,799.762878 239.375061,800.587646 240.889191,801.684692 
	C240.399124,802.971252 240.199570,803.985596 240.000000,805.000000 
	C240.471756,808.567566 240.943527,812.135193 241.704132,816.100830 
	C241.961578,816.788147 241.934753,817.077820 241.912491,817.367920 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M303.014618,932.968994 
	C303.060516,933.722412 303.106384,934.475769 303.232178,935.598633 
	C301.662689,936.971985 300.056946,938.743958 298.356049,938.840454 
	C292.501678,939.172791 286.614075,938.919373 280.292908,938.506958 
	C279.869873,937.782410 279.893097,937.439941 280.390930,937.060181 
	C286.436768,937.022888 292.007965,937.022888 297.716522,937.022888 
	C297.716522,933.749329 297.716522,931.294739 297.716522,928.840088 
	C297.716522,913.081909 297.716522,897.323730 297.716522,882.225464 
	C293.099335,882.739380 289.655792,883.624084 286.336365,883.286926 
	C284.596924,883.110291 282.624451,881.077515 281.543549,879.381775 
	C278.816620,875.103943 280.262268,870.682861 285.444794,867.831421 
	C286.101410,868.106812 286.224823,868.238281 286.192871,868.674683 
	C285.768799,869.398071 285.595764,869.960999 285.216370,870.212463 
	C281.875183,872.426697 280.892487,875.352173 283.313965,878.621277 
	C285.423767,881.469604 288.487427,883.113037 292.015045,881.206116 
	C295.431305,879.359497 296.940460,876.243835 295.773224,872.366760 
	C295.360718,870.996582 294.241608,869.839111 293.632141,868.385071 
	C293.943268,868.052063 294.067413,867.918518 294.191528,867.785034 
	C296.130249,868.806519 297.187256,868.638611 297.155334,866.029968 
	C296.970184,850.899841 296.871429,835.768616 296.743591,820.637756 
	C296.699066,815.368164 296.666473,810.098450 296.605347,804.829041 
	C296.575836,802.285095 296.685944,799.588562 292.413757,799.584351 
	C292.052277,796.798218 292.103363,794.490234 292.570648,792.119751 
	C295.658997,792.275940 296.171539,790.838501 296.205170,788.454712 
	C296.277832,783.304932 296.663177,778.159607 297.211792,773.216919 
	C297.672668,774.110840 297.842377,774.800171 298.043396,775.926636 
	C298.614868,828.302368 299.154968,880.241028 299.699677,932.621704 
	C300.500641,932.705627 301.757629,932.837280 303.014618,932.968994 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M283.710876,799.857483 
	C279.853333,799.519531 276.755615,800.221375 275.278412,804.532227 
	C274.075928,808.041504 271.324066,809.595093 267.494293,808.939941 
	C263.975647,808.338074 260.440887,807.830505 256.918457,806.496887 
	C256.966797,805.452148 257.009888,805.191895 257.329041,805.209961 
	C262.048889,805.882324 266.533600,806.762146 270.913239,806.393494 
	C272.348419,806.272705 274.131653,803.036377 274.548950,800.979858 
	C274.774048,799.870300 272.448822,798.243286 271.274231,796.849731 
	C270.148956,797.947083 268.672302,798.863953 267.991333,800.189514 
	C267.217163,801.696533 267.154053,803.568726 267.025879,804.158936 
	C263.234711,804.158936 260.174164,804.158936 257.038635,803.944580 
	C256.963715,803.730164 256.905121,803.279724 257.336182,803.210449 
	C259.801910,803.019592 261.836548,802.898010 264.082642,802.763855 
	C262.471497,797.068054 258.314484,797.327942 254.339661,796.678406 
	C253.809753,794.915710 253.421463,793.464966 252.835022,791.672119 
	C248.344254,790.727234 244.051651,790.124451 239.788483,789.528381 
	C239.817902,789.535095 239.868301,789.507874 240.310867,789.394104 
	C244.216263,788.717896 248.496994,790.542480 250.603943,785.883972 
	C250.640045,785.850403 250.630554,785.855469 251.034363,786.120178 
	C252.339752,787.251953 253.241302,788.119019 254.118500,789.233521 
	C254.436203,789.753723 254.778290,790.026489 255.120361,790.299194 
	C255.097382,789.544067 255.074402,788.788879 255.128998,787.676025 
	C256.566284,782.700195 260.265533,783.174744 263.972046,782.909668 
	C269.545563,782.510925 274.018524,783.321411 276.264832,789.291870 
	C276.722595,790.508484 278.586426,791.195984 279.810852,792.553772 
	C279.791931,796.022156 279.380859,799.281128 283.710876,799.857483 
M260.873810,789.531372 
	C262.300690,790.685669 263.727539,791.839966 264.326721,792.324646 
	C267.051910,791.733826 268.801849,791.354431 270.964996,791.473267 
	C272.267914,792.773499 273.570801,794.073730 274.873688,795.373962 
	C275.228851,789.153381 272.163788,784.580811 268.588135,784.958862 
	C264.733704,785.366211 259.455170,782.330261 256.636963,789.001465 
	C258.582520,788.892822 259.679596,788.831604 260.873810,789.531372 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M204.913818,939.172119 
	C206.643616,937.344910 208.607574,935.799561 210.904648,934.129272 
	C211.692886,934.825989 212.148010,935.647644 213.173706,937.499512 
	C215.045532,935.006470 216.282532,932.282898 218.396515,930.896790 
	C219.980225,929.858398 222.763718,930.164062 224.884460,930.559998 
	C227.255371,931.002747 229.481140,932.222656 232.472321,933.374817 
	C232.647644,933.927368 233.080460,935.291260 233.754440,937.415100 
	C239.355255,937.013306 245.076462,936.967590 250.666412,936.087830 
	C255.241333,935.367798 255.194260,933.104919 251.018707,930.945862 
	C253.696365,927.531372 255.965729,929.042847 258.491211,931.284790 
	C259.191498,934.064636 259.629547,936.481995 260.067596,938.899414 
	C242.546432,939.281860 225.025452,939.672546 207.503586,940.019470 
	C206.722412,940.034851 205.933365,939.651611 204.913818,939.172119 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M241.133575,825.434814 
	C240.990967,824.632568 241.007462,824.140015 241.034042,823.278076 
	C244.760895,829.071960 249.035934,825.835632 253.779190,822.228882 
	C253.432724,824.660706 253.292664,825.643738 253.051514,827.336243 
	C258.181213,826.721252 262.902100,826.211487 267.599792,825.539551 
	C268.427734,825.421082 269.780365,824.686646 269.835663,824.131592 
	C270.261414,819.858032 273.452240,820.965637 275.893890,820.951233 
	C284.798462,820.898560 284.035065,819.954407 283.867157,828.319336 
	C283.824493,830.443848 285.376312,834.581421 281.575256,834.114563 
	C278.017883,833.677673 272.364685,838.433350 270.768799,830.986877 
	C270.734406,830.826538 270.677490,830.656006 270.582397,830.525818 
	C268.158752,827.207520 252.453201,828.905701 250.817093,832.292664 
	C247.665924,830.126221 244.479294,827.935364 241.133575,825.434814 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M176.582153,847.884888 
	C186.937317,847.370667 186.937317,847.370667 189.126144,849.731079 
	C195.437271,847.830139 201.623566,845.640259 207.810913,850.018921 
	C209.030624,849.340393 210.258713,848.657227 211.838043,847.778687 
	C211.838043,850.184265 211.838043,852.103333 211.838043,854.848328 
	C208.890594,852.059448 204.864182,856.181458 202.458878,852.108154 
	C202.311493,851.858582 200.266449,852.167542 199.795380,852.763123 
	C196.963959,856.343262 193.787842,856.301941 189.830780,854.733521 
	C188.414062,854.172119 186.289383,855.396973 184.185257,855.875366 
	C183.901291,854.075623 183.651627,852.493408 183.401978,850.911255 
	C183.105484,850.848572 182.808975,850.785950 182.512466,850.723267 
	C182.241013,851.995667 181.969543,853.268127 181.573563,855.124146 
	C180.455933,853.130310 179.625610,851.648987 178.094025,848.916626 
	C176.886093,853.408752 175.727600,856.482239 171.502548,855.540039 
	C170.819931,847.339783 170.819931,847.339783 176.582153,847.884888 
M195.584290,852.083252 
	C195.534409,851.433411 195.484543,850.783508 195.434677,850.133667 
	C194.251221,850.747498 193.067764,851.361389 191.884308,851.975220 
	C192.049240,852.346497 192.214172,852.717834 192.379120,853.089172 
	C193.246109,852.920166 194.113098,852.751160 195.584290,852.083252 
M174.192917,852.915100 
	C174.191452,852.687805 174.189987,852.460449 174.188507,852.233215 
	C174.024445,852.238586 173.725021,852.218201 173.718323,852.253479 
	C173.675690,852.477661 173.697617,852.714050 174.192917,852.915100 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M266.238770,910.445923 
	C266.278839,909.379517 266.612305,908.531067 266.972961,907.341919 
	C268.987976,906.547241 271.412476,907.091064 271.686951,903.615051 
	C271.738251,902.965393 276.056335,902.251221 278.388916,902.303345 
	C280.453033,902.349426 284.067444,903.111267 284.261566,904.096252 
	C284.946533,907.572144 285.022797,911.413879 284.208527,914.851318 
	C283.680237,917.081421 272.677887,915.811707 271.147858,913.887695 
	C270.028320,912.479858 268.100006,911.715210 266.238770,910.445923 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M208.032501,826.288086 
	C208.055954,826.600037 208.055771,826.911926 207.997406,827.455078 
	C206.749771,829.829041 205.536713,831.971741 203.751968,835.124207 
	C201.815048,835.124207 198.690292,835.338867 195.626999,834.999268 
	C194.650452,834.890930 193.161331,833.370911 193.074097,832.392761 
	C192.797638,829.292603 193.072617,826.143250 193.391968,822.627930 
	C195.196426,822.181946 196.743317,822.120911 199.096909,821.979492 
	C202.241348,822.372131 206.385773,820.160889 205.480789,825.621521 
	C205.467697,825.700623 207.144440,826.059692 208.032501,826.288086 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M260.419769,938.909302 
	C259.629547,936.481995 259.191498,934.064636 258.860718,931.297302 
	C260.853058,930.607117 262.728394,930.175171 264.628174,929.973267 
	C265.354431,929.895996 266.143524,930.543030 266.891998,930.516846 
	C269.010773,930.442810 271.124603,930.204590 273.238007,930.005859 
	C275.358765,929.806458 277.476654,929.576782 280.622070,930.389343 
	C278.768768,931.222168 276.915497,932.054932 275.062225,932.887756 
	C275.505066,933.525940 275.947937,934.164185 276.390808,934.802368 
	C278.037933,934.236328 279.685059,933.670227 281.615234,933.339355 
	C281.237610,934.748840 280.576965,935.923218 279.916321,937.097534 
	C279.893097,937.439941 279.869873,937.782410 279.827423,938.379333 
	C278.823181,938.703857 277.838165,938.773926 276.465698,938.470398 
	C275.061249,937.048767 274.044281,936.000732 273.027344,934.952698 
	C271.975616,936.234314 270.923859,937.515930 269.872070,938.797546 
	C266.851776,939.817017 263.761200,935.580688 260.419769,938.909302 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M220.184036,866.230591 
	C218.356583,866.491211 216.915222,866.643372 215.213089,866.823059 
	C214.937683,865.108704 214.721573,863.763428 214.627731,863.179321 
	C211.952759,862.954041 209.738098,862.767517 207.523422,862.580994 
	C214.465714,857.472046 216.167297,857.901917 217.483978,864.812317 
	C220.465088,862.526672 223.162033,860.458923 225.858963,858.391113 
	C226.209915,858.831360 226.560867,859.271667 226.911804,859.711914 
	C225.559525,860.794922 224.207245,861.877930 222.393082,863.330872 
	C228.645996,866.511780 228.288101,857.016541 234.159622,859.119141 
	C234.458832,860.499695 234.929565,862.671570 235.400299,864.843445 
	C233.161469,865.120728 230.928558,865.468750 228.682281,865.656982 
	C225.984207,865.883179 223.274689,865.973083 220.184036,866.230591 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M203.019318,916.187866 
	C202.741150,916.355347 202.449860,916.686035 202.186844,916.664917 
	C199.466278,916.446350 194.732361,916.820068 194.401901,915.792725 
	C193.299942,912.366882 193.637039,908.427063 193.797424,904.700378 
	C193.821976,904.129822 195.783875,903.341736 196.921417,903.189453 
	C198.696564,902.951660 200.532990,903.171631 202.773315,903.688538 
	C203.632736,905.308716 204.498520,906.474548 204.407440,907.560120 
	C204.164597,910.454468 203.510147,913.314331 203.019318,916.187866 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M242.380127,817.390930 
	C241.934753,817.077820 241.961578,816.788147 241.991180,816.280396 
	C245.645340,816.064087 249.312912,816.235535 252.951675,815.986267 
	C254.436676,815.884583 255.859238,814.871033 257.566010,814.166809 
	C257.552307,814.144897 257.965820,814.805115 258.361786,815.437378 
	C259.548889,814.388245 260.738068,813.337280 262.193726,812.050781 
	C263.576965,815.367737 265.208923,816.512695 268.085663,813.925537 
	C268.890656,813.201538 270.694824,813.588501 272.040619,813.465820 
	C272.107391,814.820984 272.729950,816.603455 272.099915,817.422974 
	C271.315735,818.443115 269.040771,819.511658 268.218292,819.066162 
	C266.343048,818.050415 265.325012,817.845825 263.581696,819.392273 
	C262.689423,820.183899 260.203949,820.277832 259.142456,819.594238 
	C253.975769,816.266357 248.298691,817.875122 242.380127,817.390930 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M275.947968,847.072021 
	C278.854340,843.158020 282.493042,846.471680 285.761963,846.127808 
	C287.251678,845.971191 288.798370,846.356567 291.463837,846.601013 
	C291.577789,847.615540 291.833618,849.893188 292.236084,853.476501 
	C286.538086,853.018555 281.562500,852.618652 276.275208,851.647522 
	C275.958313,849.741516 275.953156,848.406799 275.947968,847.072021 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M272.774231,918.052856 
	C275.778595,919.737488 278.386566,921.412781 281.309906,923.290588 
	C276.112305,924.010803 271.054840,924.346191 266.199219,925.518066 
	C263.161774,926.251160 260.835144,926.027405 260.751434,922.890747 
	C260.666962,919.728760 263.127136,918.227844 267.317627,918.457886 
	C265.447754,920.245667 264.179901,921.457825 262.912048,922.670044 
	C263.033203,922.978699 263.154358,923.287415 263.275513,923.596069 
	C265.032227,923.320862 266.788940,923.045654 268.890228,922.716431 
	C267.027832,918.604309 269.422974,918.082520 272.774231,918.052856 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M186.120956,841.120300 
	C182.544708,846.674011 176.835236,843.058960 172.224487,844.179138 
	C171.862015,844.267212 170.074326,841.427246 170.410156,840.924927 
	C171.634735,839.093140 173.479523,837.675964 175.090607,836.102600 
	C175.510605,836.570374 175.930603,837.038208 176.350601,837.505981 
	C174.909180,838.752380 173.467773,839.998718 172.026352,841.245056 
	C172.347641,841.734741 172.668915,842.224365 172.990189,842.714050 
	C174.784714,841.598267 177.225540,840.891541 178.242966,839.283936 
	C180.558243,835.625549 183.235153,835.333130 186.174133,839.747803 
	C186.130051,840.658325 186.125504,840.889343 186.120956,841.120300 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M175.322800,862.234253 
	C174.808365,863.445129 174.580017,864.398621 174.191742,866.020081 
	C172.830353,863.809143 171.738602,862.036133 170.627838,860.232239 
	C170.795181,859.972961 170.960251,859.475769 171.142929,859.469238 
	C177.174103,859.252808 183.206436,859.030151 189.240204,858.969604 
	C190.061203,858.961304 191.604309,859.849182 191.576874,860.233948 
	C191.506180,861.224854 190.967392,862.439575 190.205673,863.067871 
	C188.814224,864.215698 187.126907,865.004761 185.458954,866.005920 
	C185.062637,864.875305 184.593536,863.537048 183.760513,861.160583 
	C182.222351,863.002380 181.156815,864.278259 180.548981,865.006042 
	C178.864197,863.973022 177.236542,862.974976 175.322800,862.234253 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M275.640839,846.888062 
	C275.953156,848.406799 275.958313,849.741516 275.938782,851.472900 
	C272.014282,855.143066 268.029083,851.988037 264.118530,851.752502 
	C263.739532,851.729614 263.348755,848.157104 263.471527,846.265320 
	C263.501038,845.810181 265.604309,845.071655 266.679565,845.182678 
	C269.585663,845.482849 272.451752,846.170227 275.640839,846.888062 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M201.997116,923.996826 
	C200.304794,925.622986 190.655991,927.760925 188.947327,926.510315 
	C188.002975,925.819214 187.007751,923.593750 187.419495,922.962219 
	C188.534775,921.251465 190.389786,920.023071 191.951431,918.603394 
	C192.318634,919.049011 192.685822,919.494690 193.053024,919.940308 
	C192.387146,920.507935 191.722641,921.077209 191.054977,921.642761 
	C190.458817,922.147705 189.859085,922.648438 189.260941,923.151001 
	C189.470490,923.611572 189.680054,924.072083 189.889618,924.532654 
	C191.470581,923.684509 193.898254,923.218018 194.482895,921.920837 
	C196.758926,916.870972 199.368057,918.171814 202.337738,921.633667 
	C202.195404,922.901672 202.096268,923.449280 201.997116,923.996826 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M280.390930,937.060181 
	C280.576965,935.923218 281.237610,934.748840 281.956848,933.238342 
	C281.337280,926.927673 286.061157,929.929871 288.792389,929.452759 
	C289.480316,931.584900 289.816193,933.300049 289.997559,934.226257 
	C292.405212,933.935852 294.581146,933.673340 296.917572,933.391479 
	C296.973480,932.248535 297.054474,930.592896 297.135437,928.937256 
	C297.135437,928.937256 297.427002,928.882202 297.571777,928.861145 
	C297.716522,931.294739 297.716522,933.749329 297.716522,937.022888 
	C292.007965,937.022888 286.436768,937.022888 280.390930,937.060181 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M284.102051,799.890869 
	C279.380859,799.281128 279.791931,796.022156 280.173645,792.614258 
	C283.023804,792.578613 285.519897,792.908813 288.015991,793.239014 
	C287.952118,793.513611 287.888275,793.788147 287.824432,794.062744 
	C289.267761,793.435913 290.711090,792.809143 292.154419,792.182373 
	C292.103363,794.490234 292.052277,796.798218 292.016724,799.539368 
	C289.519196,799.956482 287.006195,799.940369 284.102051,799.890869 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M203.419769,916.238770 
	C203.510147,913.314331 204.164597,910.454468 204.407440,907.560120 
	C204.498520,906.474548 203.632736,905.308716 203.206024,903.854980 
	C204.546951,904.909180 205.885056,906.287720 207.428024,907.877380 
	C209.235596,908.010193 211.933563,908.208496 214.818161,908.699402 
	C214.996872,909.162659 214.988968,909.333374 214.989410,909.760315 
	C210.283600,910.346497 206.759003,912.796143 203.419769,916.238770 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M253.033157,792.014221 
	C253.421463,793.464966 253.809753,794.915710 254.306519,797.078003 
	C255.245041,799.619629 256.075073,801.449646 256.905121,803.279724 
	C256.905121,803.279724 256.963715,803.730164 257.039398,804.138794 
	C257.115082,804.547424 257.052948,804.931641 257.052948,804.931641 
	C257.009888,805.191895 256.966797,805.452148 256.942627,806.110962 
	C252.407761,805.784180 247.854034,805.058960 243.042175,804.095337 
	C243.212326,803.675232 243.672791,803.283691 244.063980,803.343689 
	C249.092255,804.115112 251.066620,802.320251 250.312057,797.027039 
	C250.096664,795.516113 252.065765,793.693726 253.033157,792.014221 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M222.091797,908.362915 
	C220.699097,908.839417 219.254639,908.950500 217.380615,908.991028 
	C218.782684,905.187439 217.152740,902.009460 215.455093,898.761963 
	C214.207260,896.375000 212.933426,894.849976 209.740646,895.395691 
	C207.228394,895.825134 204.536072,895.201050 201.481964,894.685181 
	C201.101105,893.841553 201.162003,893.357849 201.222900,892.874084 
	C202.204559,892.925171 203.188370,892.950317 204.167496,893.031921 
	C207.855774,893.339111 213.003860,892.278442 214.909378,894.287292 
	C218.317062,897.879578 219.769958,903.326111 222.091797,908.362915 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M252.834991,791.672119 
	C252.065765,793.693726 250.096664,795.516113 250.312057,797.027039 
	C251.066620,802.320251 249.092255,804.115112 244.063980,803.343689 
	C243.672791,803.283691 243.212326,803.675232 242.750046,804.145813 
	C242.118896,804.601624 241.521759,804.768555 240.462326,804.967773 
	C240.199570,803.985596 240.399124,802.971252 241.268356,801.759155 
	C245.058136,801.921204 247.076447,801.049683 246.935776,797.398315 
	C246.825912,794.546326 247.110703,791.700928 242.831024,791.707275 
	C241.808121,791.708801 240.783112,790.288147 239.759064,789.521606 
	C244.051651,790.124451 248.344254,790.727234 252.834991,791.672119 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M255.057404,875.563110 
	C257.733490,871.895691 260.808319,871.410400 264.739838,874.647827 
	C265.071838,875.985291 264.982941,877.077454 264.894043,878.169678 
	C263.606659,878.474731 262.333405,878.916748 261.028473,879.051086 
	C259.290375,879.230164 257.527588,879.169800 255.452972,878.648621 
	C255.106155,877.244324 255.081787,876.403687 255.057404,875.563110 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M254.655060,846.707275 
	C254.968170,848.972168 255.063385,851.002991 255.167007,853.213074 
	C252.178055,852.920532 248.814087,852.591248 244.696930,852.188293 
	C245.501312,849.528198 246.030807,847.777222 246.923080,844.826538 
	C248.369537,847.111389 249.138504,848.326050 250.101654,849.847412 
	C250.161972,848.617126 250.206848,847.701599 250.267059,846.473206 
	C251.681839,846.473206 253.059509,846.473206 254.655060,846.707275 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M192.197006,891.225586 
	C192.264282,893.606873 192.303192,895.768860 191.712341,897.742310 
	C191.590332,898.149963 189.123795,897.855652 187.737839,897.884888 
	C187.780396,896.358582 187.310272,894.513855 188.006180,893.393188 
	C188.738068,892.214417 190.585480,891.728271 192.197006,891.225586 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M198.036438,864.163757 
	C198.961090,863.474365 199.569809,862.963013 200.726242,861.991516 
	C198.944092,861.497253 197.758926,861.168579 195.177704,860.452698 
	C197.400879,859.623413 198.676147,858.714355 199.209473,859.035889 
	C200.448318,859.782898 201.707016,860.970703 202.233948,862.272766 
	C202.645050,863.288635 201.966522,864.745605 201.641098,866.857483 
	C199.991150,866.637390 198.236877,866.403381 196.482605,866.169373 
	C196.895233,865.560181 197.307861,864.950989 198.036438,864.163757 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M202.386627,924.049683 
	C202.096268,923.449280 202.195404,922.901672 202.507355,921.987488 
	C205.130188,921.122437 207.540207,920.623962 210.595291,919.992188 
	C210.680176,921.231567 210.825577,923.354370 210.999512,925.893738 
	C207.846420,925.206970 205.311279,924.654785 202.386627,924.049683 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M265.086151,878.397888 
	C264.982941,877.077454 265.071838,875.985291 265.128662,874.625061 
	C266.448639,873.903748 267.800659,873.450378 269.152710,872.997070 
	C269.449280,874.302063 270.384460,876.375183 269.918335,876.762756 
	C268.698395,877.776917 266.866302,878.054749 265.086151,878.397888 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M201.034225,892.559570 
	C201.162003,893.357849 201.101105,893.841553 201.059509,894.692017 
	C199.362167,895.771301 197.645508,896.483948 195.928864,897.196655 
	C195.784470,895.753174 195.030319,893.949524 195.625076,892.942627 
	C196.793137,890.965332 198.766937,889.908325 201.034225,892.559570 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M296.761108,928.940186 
	C297.054474,930.592896 296.973480,932.248535 296.917572,933.391479 
	C294.581146,933.673340 292.405212,933.935852 289.997559,934.226257 
	C289.816193,933.300049 289.480316,931.584900 289.178406,929.512085 
	C290.437531,929.804321 291.662628,930.454224 293.126099,931.230591 
	C294.103760,930.544739 295.245270,929.743896 296.761108,928.940186 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M186.458527,841.278198 
	C186.125504,840.889343 186.130051,840.658325 186.257996,840.102295 
	C188.832108,839.164185 191.282852,838.551025 193.854950,837.907532 
	C193.854950,839.014465 193.854950,841.196045 193.854950,843.444580 
	C191.306732,842.719482 189.051407,842.077759 186.458527,841.278198 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M237.472046,845.849487 
	C237.878876,847.986694 237.899231,850.268127 237.831955,853.042603 
	C236.831223,852.439026 235.918137,851.342346 234.662247,849.833984 
	C234.122940,851.089600 233.750778,851.956116 233.378616,852.822632 
	C233.756470,850.065002 231.231689,845.611023 237.472046,845.849487 
M234.134140,848.433350 
	C234.819473,848.596069 235.504791,848.758789 236.190125,848.921509 
	C236.281631,848.510498 236.373123,848.099487 236.464630,847.688538 
	C235.681915,847.687988 234.899216,847.687439 234.134140,848.433350 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M255.002396,875.171387 
	C255.081787,876.403687 255.106155,877.244324 255.160828,878.401855 
	C253.891846,878.291565 252.592560,877.864197 251.118195,877.102783 
	C250.943130,876.768677 250.865585,876.465271 250.911957,876.316040 
	C253.128403,872.409851 253.128403,872.409851 255.002396,875.171387 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M285.799805,867.771729 
	C288.306000,867.471252 290.990387,867.374268 293.933167,867.531128 
	C294.067413,867.918518 293.943268,868.052063 293.277710,868.237122 
	C290.606934,868.315674 288.477570,868.342712 286.348236,868.369812 
	C286.224823,868.238281 286.101410,868.106812 285.799805,867.771729 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M208.502838,866.496460 
	C208.485870,866.505981 208.519791,866.487000 208.502838,866.496460 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M141.877106,823.698181 
	C140.044876,825.834167 138.011627,826.716736 135.444977,824.835693 
	C134.970490,824.487976 134.296310,824.115234 133.769562,824.183105 
	C129.517776,824.730591 125.279213,825.380798 120.694824,826.049500 
	C120.514397,823.429138 120.362869,821.228455 120.652023,818.525574 
	C122.864487,818.710754 124.636253,819.398132 125.390480,819.690735 
	C130.884354,819.068604 135.689697,818.524414 141.942795,817.816284 
	C139.467606,819.978088 138.138626,821.138794 136.075760,822.940430 
	C138.849945,823.081726 140.430969,823.162292 141.973877,823.311646 
	C141.935760,823.380432 141.877106,823.698181 141.877106,823.698181 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M142.011993,823.242798 
	C140.430969,823.162292 138.849945,823.081726 136.075760,822.940430 
	C138.138626,821.138794 139.467606,819.978088 141.942795,817.816284 
	C135.689697,818.524414 130.884354,819.068604 125.390480,819.690735 
	C124.636253,819.398132 122.864487,818.710754 120.706223,818.098694 
	C119.905807,817.687378 119.491890,817.200745 119.038025,816.383789 
	C121.827202,815.857178 124.656319,815.660767 128.125427,815.254822 
	C131.139969,815.294922 133.514511,815.544678 135.889069,815.794373 
	C143.044037,815.657898 150.199005,815.521362 157.699600,815.599121 
	C157.997162,816.102173 157.949081,816.390991 157.503540,817.104492 
	C156.001648,818.432983 154.897186,819.336792 153.792725,820.240601 
	C153.734039,819.853027 153.675369,819.465393 153.616699,819.077820 
	C150.743423,819.077820 147.870132,819.077820 144.687714,818.923340 
	C143.943985,819.847351 143.509399,820.925720 142.795593,822.115845 
	C142.348267,822.565979 142.180145,822.904358 142.011993,823.242798 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M121.988518,883.995117 
	C121.239067,884.015991 120.489616,884.036865 119.369751,883.632690 
	C118.328697,881.800903 117.658066,880.394104 116.993988,878.994080 
	C117.000526,879.000854 117.010826,879.016968 117.005028,878.679321 
	C116.977661,877.891907 116.956085,877.442139 116.934509,876.992432 
	C121.044083,876.737488 125.210663,876.006226 129.232117,876.487549 
	C130.628143,876.654602 132.361893,879.749268 132.570709,881.656860 
	C132.674820,882.607727 129.820679,884.269287 128.051605,884.904724 
	C126.840614,885.339661 125.192902,884.558655 123.382675,883.781128 
	C122.933105,882.199036 122.841293,881.157166 122.749481,880.115234 
	C122.495827,880.134583 122.242172,880.153870 121.988518,880.173157 
	C121.988518,881.447144 121.988518,882.721130 121.988518,883.995117 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M144.996857,819.077820 
	C147.870132,819.077820 150.743423,819.077820 153.616699,819.077820 
	C153.675369,819.465393 153.734039,819.853027 153.792725,820.240601 
	C154.897186,819.336792 156.001648,818.432983 157.415894,817.534912 
	C157.754105,819.448425 157.819901,821.357727 157.734558,823.260376 
	C157.728241,823.400940 156.633850,823.492676 155.919708,823.095276 
	C155.511322,822.715210 155.228546,822.846985 154.945801,822.978760 
	C153.494705,823.387634 152.043594,823.796509 150.109955,824.341370 
	C148.661102,821.903809 146.603912,819.772339 144.253876,824.478088 
	C144.183258,824.619507 143.152802,824.281555 142.223480,823.932617 
	C141.877106,823.698181 141.935760,823.380432 141.973877,823.311646 
	C142.180145,822.904358 142.348267,822.565979 143.042038,821.988403 
	C144.044067,820.858826 144.520477,819.968323 144.996857,819.077820 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M110.906181,881.957703 
	C110.479393,881.963257 110.052589,881.968811 109.083618,882.083984 
	C108.251862,882.376892 108.099609,882.639709 108.084671,882.982178 
	C107.574738,883.415161 107.064804,883.848145 106.554871,884.281189 
	C106.548515,881.769409 106.542145,879.257629 106.535782,876.745850 
	C108.313721,877.243286 110.091652,877.740723 111.930252,878.926270 
	C111.994461,880.044373 111.998001,880.474548 111.768250,881.022644 
	C111.325363,881.412964 111.115761,881.685303 110.906181,881.957703 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M112.001534,880.904663 
	C111.998001,880.474548 111.994461,880.044373 112.005295,879.291382 
	C113.143227,878.291321 114.266800,877.614197 115.750717,877.254272 
	C116.410980,878.053345 116.710899,878.535156 117.010818,879.016968 
	C117.010826,879.016968 117.000526,879.000854 117.041901,879.362061 
	C117.428604,881.157959 117.773933,882.592651 118.119270,884.027283 
	C116.075294,883.579651 114.031319,883.131958 111.972061,882.072266 
	C111.971695,881.275024 111.986618,881.089844 112.001534,880.904663 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M99.440613,815.020142 
	C98.170700,815.546692 96.907043,815.731018 95.643387,815.915344 
	C95.618652,815.399475 95.593918,814.883606 95.569183,814.367737 
	C96.861748,814.471130 98.154312,814.574463 99.440613,815.020142 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M118.337158,884.017944 
	C117.773933,882.592651 117.428604,881.157959 117.035355,879.355347 
	C117.658066,880.394104 118.328697,881.800903 118.995255,883.605347 
	C118.991173,884.003052 118.555038,884.008545 118.337158,884.017944 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M122.247581,883.995361 
	C121.988518,882.721130 121.988518,881.447144 121.988518,880.173157 
	C122.242172,880.153870 122.495827,880.134583 122.749481,880.115234 
	C122.841293,881.157166 122.933105,882.199036 123.024826,883.615112 
	C122.852036,883.991455 122.679337,883.993530 122.247581,883.995361 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M117.005020,878.679321 
	C116.710899,878.535156 116.410980,878.053345 116.101677,877.273315 
	C116.092293,876.975037 116.513985,876.990234 116.724243,876.991333 
	C116.956085,877.442139 116.977661,877.891907 117.005020,878.679321 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M111.768250,881.022644 
	C111.986618,881.089844 111.971695,881.275024 111.913994,881.735840 
	C111.871208,882.011475 111.389206,881.991699 111.147682,881.974670 
	C111.115761,881.685303 111.325363,881.412964 111.768250,881.022644 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M108.283203,882.878113 
	C108.099609,882.639709 108.251862,882.376892 108.765533,882.121094 
	C108.897125,882.344116 108.727829,882.585938 108.283203,882.878113 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M90.859962,939.636719 
	C90.631447,939.852478 90.380768,939.730164 90.152542,939.230591 
	C90.358902,939.101929 90.579834,939.250366 90.859962,939.636719 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M155.154663,822.989136 
	C155.228546,822.846985 155.511322,822.715210 155.787903,822.796143 
	C155.781708,823.008850 155.363541,822.999512 155.154663,822.989136 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M270.310730,938.891968 
	C270.923859,937.515930 271.975616,936.234314 273.027344,934.952698 
	C274.044281,936.000732 275.061249,937.048767 276.093750,938.467285 
	C274.322662,938.887329 272.535980,938.936890 270.310730,938.891968 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M199.154419,939.768433 
	C199.260086,937.323608 199.208801,934.137939 202.994751,937.190918 
	C201.853989,938.299377 200.709656,939.102905 199.154419,939.768433 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M304.035828,936.294067 
	C304.499268,936.550720 304.977081,937.105408 305.226227,937.830078 
	C304.681793,937.530701 304.366028,937.061401 304.035828,936.294067 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M152.977234,864.097778 
	C148.324661,859.279785 147.109085,856.651978 147.096817,851.016846 
	C152.190262,851.016846 157.306381,850.851074 162.394089,851.157532 
	C163.403946,851.218384 165.047607,853.037476 165.125473,854.135986 
	C165.439835,858.570679 165.255814,863.040710 165.255814,869.378235 
	C160.460098,867.360779 156.860931,865.846741 152.977234,864.097778 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1212.427246,597.789062 
	C1214.341187,597.734802 1215.797729,597.723572 1217.689087,597.656860 
	C1222.973022,597.601379 1227.822144,597.601379 1232.671387,597.601379 
	C1232.746094,597.145996 1232.820923,596.690552 1232.895630,596.235168 
	C1232.040771,595.443665 1231.185913,594.652161 1230.333862,593.863342 
	C1230.568726,593.579590 1230.819458,593.143555 1230.919800,593.175781 
	C1232.205444,593.588074 1234.557373,594.049255 1234.565308,594.532532 
	C1234.623413,598.031311 1236.846069,598.086975 1239.307617,598.021667 
	C1251.077637,597.709106 1262.847778,597.400940 1274.617065,597.061096 
	C1275.028076,597.049255 1275.429810,596.718384 1276.178955,596.382324 
	C1275.018066,595.043457 1274.038086,593.913147 1273.015625,592.133301 
	C1272.702148,591.009766 1272.431030,590.535828 1272.159912,590.061890 
	C1271.998901,586.355591 1271.838013,582.649292 1271.986084,578.605347 
	C1272.600830,578.627258 1272.906616,578.986877 1273.212402,579.346436 
	C1273.436523,580.413025 1273.660400,581.479553 1274.003418,583.112793 
	C1274.729004,581.881226 1275.148804,581.168701 1275.568604,580.456116 
	C1275.869751,580.499634 1276.170898,580.543091 1276.471924,580.586548 
	C1276.371948,581.863708 1276.749756,583.870300 1276.094360,584.299622 
	C1271.864990,587.070129 1274.103149,589.285950 1276.781006,592.158203 
	C1277.976074,593.463501 1278.788208,594.225952 1279.539795,595.388977 
	C1280.710815,596.232361 1281.933838,597.024353 1283.175171,597.054321 
	C1287.432129,597.157166 1291.695435,596.992798 1296.305176,596.993042 
	C1297.121704,597.005737 1297.589722,596.955688 1298.057617,596.905701 
	C1302.700195,596.909607 1307.342896,596.913391 1312.761719,596.875427 
	C1315.716675,596.847778 1317.895508,596.862000 1320.489990,596.892090 
	C1334.562744,596.908020 1348.219727,596.908020 1361.876709,596.908020 
	C1361.991699,596.175720 1362.106689,595.443420 1362.221680,594.711121 
	C1358.746460,593.297607 1355.271240,591.884094 1351.478149,590.341370 
	C1355.445068,585.364929 1351.114746,585.445374 1348.105469,584.475708 
	C1348.160767,584.246155 1348.360962,583.818481 1348.770508,583.884888 
	C1350.019897,583.931702 1350.859863,583.912048 1351.699829,583.892395 
	C1352.432373,584.052002 1353.165039,584.211609 1354.440430,584.675110 
	C1355.146851,584.504944 1355.310181,584.030884 1355.473633,583.556885 
	C1355.625122,582.900208 1355.776489,582.243591 1355.927979,581.586914 
	C1356.754028,582.893860 1357.580078,584.200745 1357.971680,585.962036 
	C1357.736328,591.802490 1361.240234,586.791748 1362.744507,588.071716 
	C1361.456421,592.202209 1364.098389,596.201172 1368.395630,596.456848 
	C1370.346313,596.572876 1372.314331,596.397827 1375.063477,596.239014 
	C1378.480347,596.145996 1381.108276,596.168823 1383.736084,596.191650 
	C1383.921997,596.260376 1384.107788,596.329102 1384.593628,596.751343 
	C1384.960327,597.405823 1384.995483,597.710449 1384.737061,598.080505 
	C1384.120972,598.199890 1383.767090,598.257446 1382.931030,598.246094 
	C1381.159058,598.220642 1379.869019,598.263977 1378.137939,598.288635 
	C1354.139404,598.452515 1330.581665,598.624756 1307.024292,598.820740 
	C1284.719604,599.006348 1262.414307,599.160278 1240.111938,599.505493 
	C1237.910156,599.539490 1235.727661,600.811646 1233.780518,601.431335 
	C1232.165649,600.793640 1230.308105,599.524780 1228.385986,599.418274 
	C1224.931030,599.226990 1221.437622,599.733215 1217.632080,599.916565 
	C1216.866821,599.929749 1216.429932,599.974365 1215.607666,600.020264 
	C1214.457886,600.103455 1213.693237,600.185303 1212.928711,600.267090 
	C1209.432251,600.209290 1205.917236,599.894104 1202.443115,600.145447 
	C1194.415405,600.726196 1190.123413,595.303162 1186.609375,589.684875 
	C1185.076172,587.233582 1186.043579,583.218323 1186.198975,579.574097 
	C1187.035034,579.502869 1187.547241,579.770813 1188.059448,580.038696 
	C1188.059448,582.776123 1188.059448,585.513428 1188.059448,588.935913 
	C1189.651611,588.705872 1190.950439,588.518188 1192.591064,588.135010 
	C1192.932739,587.939453 1192.893555,587.909180 1192.893555,587.909180 
	C1191.620605,594.450195 1194.579346,597.889709 1201.492676,597.884155 
	C1204.985107,597.881409 1208.477661,597.850342 1212.427246,597.789062 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1348.360962,583.818481 
	C1348.360962,583.818481 1348.160767,584.246155 1347.939697,584.783325 
	C1346.691284,586.081238 1345.663940,586.841980 1344.443359,587.745667 
	C1345.098145,589.352600 1345.796875,591.067688 1346.492432,592.774780 
	C1340.324463,594.999817 1333.831665,593.889526 1330.686401,590.174805 
	C1329.497314,591.110657 1328.309204,592.045776 1326.741577,593.279602 
	C1326.260132,591.400940 1325.925903,590.096863 1325.629395,588.939819 
	C1323.674438,590.624512 1321.793701,592.245239 1319.558350,594.171509 
	C1319.642578,594.612976 1319.858398,595.744568 1320.074341,596.876160 
	C1317.895508,596.862000 1315.716675,596.847778 1313.219971,596.838745 
	C1312.902222,582.764282 1312.902222,568.684570 1312.902222,554.604919 
	C1313.332153,554.547302 1313.762207,554.489746 1314.192139,554.432129 
	C1314.463623,555.349060 1314.958984,556.263000 1314.971191,557.183472 
	C1315.070435,564.625732 1315.130005,572.069458 1315.096802,579.512268 
	C1315.075439,584.309265 1317.427856,586.050354 1322.267090,585.494446 
	C1326.713257,584.983704 1331.242798,585.199219 1336.251221,584.652100 
	C1338.878784,584.079956 1340.990479,583.950623 1343.456055,583.869995 
	C1345.326904,583.885315 1346.843994,583.851868 1348.360962,583.818481 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1284.290771,552.252014 
	C1284.046997,553.015015 1283.803101,553.777954 1283.316406,555.300537 
	C1281.464233,551.955444 1279.788940,549.530029 1277.195068,553.985352 
	C1276.640137,554.938782 1274.478638,555.314514 1273.046387,555.339783 
	C1265.645630,555.470398 1258.241333,555.400452 1249.945679,555.400452 
	C1251.640137,553.874695 1252.755249,552.870789 1254.256226,551.519348 
	C1253.047363,550.779541 1252.026733,550.155029 1251.006104,549.530457 
	C1251.084106,549.099121 1251.162109,548.667847 1251.239990,548.236511 
	C1253.531128,548.400635 1255.827271,548.744995 1258.111816,548.680481 
	C1260.630005,548.609375 1263.324097,550.015137 1265.745850,547.982300 
	C1265.854004,547.891602 1267.389404,549.501099 1268.340820,550.394592 
	C1269.975098,549.602783 1271.852783,548.693054 1273.730469,547.783264 
	C1273.907837,548.080933 1274.085205,548.378601 1274.262695,548.676270 
	C1273.077393,549.930115 1271.892212,551.183960 1270.706909,552.437744 
	C1271.165405,552.906311 1271.623901,553.374817 1272.082397,553.843384 
	C1273.744873,552.275146 1275.407349,550.706909 1277.069824,549.138672 
	C1276.826050,548.677307 1276.582397,548.215942 1276.338745,547.754517 
	C1278.417358,547.893982 1280.496094,548.033386 1282.804932,548.784302 
	C1283.453735,550.347839 1283.872314,551.299927 1284.290771,552.252014 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1490.954956,588.039856 
	C1492.845703,589.096619 1494.736450,590.153381 1496.135010,590.934998 
	C1498.595581,590.589905 1500.869873,590.270874 1503.144043,589.951904 
	C1502.999023,591.487793 1502.854004,593.023743 1502.387451,595.130371 
	C1502.040771,596.195435 1502.015625,596.689697 1501.617920,597.232788 
	C1500.498901,597.217834 1499.752075,597.154053 1498.982300,596.771362 
	C1498.930908,596.241150 1498.902588,596.029907 1498.746460,595.486816 
	C1496.811401,592.286682 1495.748657,593.095520 1495.045410,596.151367 
	C1495.004517,596.621338 1495.002319,596.810425 1494.538818,597.094604 
	C1484.097778,597.488586 1474.118164,597.787476 1463.858154,598.095398 
	C1463.385132,598.401855 1463.192139,598.699280 1462.912842,599.306763 
	C1462.223877,599.810303 1461.621460,600.003784 1460.998535,599.820984 
	C1460.721558,596.411133 1459.103516,596.599731 1456.608154,597.751099 
	C1447.126465,597.705627 1438.098877,597.699158 1429.075928,597.437378 
	C1429.072021,596.842041 1429.063354,596.502014 1429.054810,596.162048 
	C1433.384033,596.102112 1437.713257,596.042175 1442.880615,595.827942 
	C1448.130371,595.753235 1452.541870,595.832825 1456.953369,595.912354 
	C1458.981445,595.943665 1461.009521,595.975037 1463.829102,595.908752 
	C1465.108521,595.843689 1465.596436,595.876343 1466.084351,595.908997 
	C1473.459839,595.908997 1480.835449,595.908997 1488.491455,595.908997 
	C1489.063354,593.793518 1489.604126,591.792908 1490.072632,589.397095 
	C1490.000366,589.001770 1489.961548,589.033447 1490.315552,589.089050 
	C1490.790039,588.749512 1490.910522,588.354431 1491.012451,587.979858 
	C1490.993896,588.000427 1490.954956,588.039856 1490.954956,588.039856 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1457.005615,595.452698 
	C1452.541870,595.832825 1448.130371,595.753235 1443.337158,595.756470 
	C1442.651978,595.152649 1442.348389,594.465942 1442.371704,593.348145 
	C1443.552124,590.608826 1444.405640,588.300720 1445.259033,585.992554 
	C1447.366943,583.973633 1449.361694,581.813721 1451.629883,579.994385 
	C1452.788330,579.065186 1454.436523,578.746765 1455.863525,578.152222 
	C1456.270630,579.465759 1456.984741,580.769226 1457.028564,582.094849 
	C1457.170654,586.389832 1457.066895,590.692993 1457.005615,595.452698 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1337.936523,564.045410 
	C1340.530029,559.347046 1341.924316,559.191345 1345.144165,563.164978 
	C1346.939087,562.223022 1348.719116,561.288940 1350.499023,560.354858 
	C1350.734741,560.673401 1350.970459,560.991943 1351.206177,561.310486 
	C1350.048462,562.608521 1348.890747,563.906555 1347.733154,565.204590 
	C1347.918457,565.538818 1348.103882,565.873047 1348.289307,566.207275 
	C1349.769409,565.586060 1351.652588,565.345581 1352.660522,564.278442 
	C1357.339600,559.324036 1358.059326,559.141724 1364.409546,563.017761 
	C1365.318237,562.206909 1366.269409,561.358276 1368.088379,559.735291 
	C1368.246582,562.539185 1368.355835,564.475647 1368.471313,566.522400 
	C1362.567993,565.372681 1357.303711,564.716187 1351.691406,566.998291 
	C1348.222046,568.408997 1343.915283,567.838440 1339.978027,567.987427 
	C1338.011841,568.061890 1336.031372,567.755066 1334.395996,567.283569 
	C1335.801880,565.979370 1336.869141,565.012390 1337.936523,564.045410 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1428.398804,581.891541 
	C1424.287231,582.601746 1420.188843,583.435852 1416.048706,583.880615 
	C1415.468994,583.942932 1414.173706,582.137939 1414.089355,581.124023 
	C1413.828857,577.997498 1413.994263,574.835510 1413.994263,570.932495 
	C1417.379883,570.932495 1420.394653,570.932495 1423.785278,571.427490 
	C1424.751709,574.040894 1425.184814,576.221680 1425.989258,578.255493 
	C1426.512695,579.578186 1427.576904,580.686951 1428.398804,581.891541 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1298.038452,596.560242 
	C1297.589722,596.955688 1297.121704,597.005737 1296.348022,596.618042 
	C1295.092285,594.206116 1294.142212,592.231934 1293.261841,590.402344 
	C1288.501099,592.000427 1284.050659,593.494385 1279.600220,594.988342 
	C1278.788208,594.225952 1277.976074,593.463501 1277.086914,592.346558 
	C1277.940552,591.547180 1279.668579,591.121094 1279.679565,590.654419 
	C1279.797852,585.652649 1282.497803,585.992859 1286.397583,586.360718 
	C1290.326538,586.731384 1294.400024,585.569031 1298.244385,585.098633 
	C1298.627319,587.636353 1298.923218,589.596680 1299.597168,591.952087 
	C1299.996582,592.526611 1300.017822,592.705994 1300.038940,592.885315 
	C1299.365723,593.995117 1298.692505,595.104980 1298.038452,596.560242 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1273.535400,579.143860 
	C1272.906616,578.986877 1272.600830,578.627258 1271.958496,578.138611 
	C1270.433228,575.066162 1271.048340,571.409546 1266.518188,569.885193 
	C1265.304810,569.476807 1265.948975,563.548462 1266.266846,560.231140 
	C1266.862793,560.497986 1266.932251,560.687805 1267.027588,561.296265 
	C1267.165039,563.369690 1267.276733,565.024475 1267.388428,566.679260 
	C1268.012817,566.713074 1268.637085,566.746887 1269.261475,566.780701 
	C1269.505493,565.158813 1269.749512,563.536987 1269.992188,561.952454 
	C1269.990967,561.989807 1269.916626,561.982849 1270.239258,562.015015 
	C1273.570190,563.354065 1274.294312,561.291687 1275.240967,559.180420 
	C1275.720215,562.973389 1275.882446,566.675842 1276.044678,570.378235 
	C1275.315918,573.232605 1274.587158,576.086975 1273.535400,579.143860 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1271.775269,590.084473 
	C1272.431030,590.535828 1272.702148,591.009766 1272.973877,591.769592 
	C1266.401855,590.586975 1259.845459,589.036438 1253.240356,587.731445 
	C1252.148926,587.515808 1250.533081,588.033997 1249.696533,588.799683 
	C1246.926514,591.335327 1245.282959,589.890564 1243.539062,587.246887 
	C1243.132446,588.206787 1242.791870,589.010803 1242.139282,590.551208 
	C1240.375366,588.942017 1238.774536,587.481812 1237.173828,586.021484 
	C1238.108398,585.173645 1238.982666,583.725647 1239.992310,583.624207 
	C1241.371826,583.485596 1242.854492,584.371765 1245.090210,585.066284 
	C1246.456299,583.677856 1248.808594,581.826660 1251.333374,585.604309 
	C1251.668335,586.105530 1252.803833,586.102966 1253.582031,586.271790 
	C1259.516479,587.558838 1265.453979,588.830566 1271.775269,590.084473 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1428.588135,596.082642 
	C1429.063354,596.502014 1429.072021,596.842041 1428.619385,597.563782 
	C1423.353516,598.127441 1418.548096,598.475769 1413.744385,598.456177 
	C1404.162109,598.417175 1394.580811,598.176208 1384.999023,598.018677 
	C1384.995483,597.710449 1384.960327,597.405823 1384.862061,596.878906 
	C1395.439331,596.431396 1406.047729,596.187744 1416.656982,595.999817 
	C1420.477173,595.932129 1424.299805,595.998230 1428.588135,596.082642 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1425.021973,563.021301 
	C1426.712646,561.938721 1428.403198,560.856201 1430.093628,559.773621 
	C1430.380737,560.226318 1430.667847,560.678955 1430.954956,561.131653 
	C1429.617432,562.335022 1428.279907,563.538391 1426.673584,564.983643 
	C1431.536011,565.683105 1432.281982,565.130127 1433.845825,559.390564 
	C1437.099609,560.133911 1440.393555,560.886475 1444.721558,561.875305 
	C1444.666260,561.921021 1445.742432,561.031067 1447.425781,559.638977 
	C1447.425781,562.281982 1447.425781,564.162964 1447.425781,565.408447 
	C1445.006592,565.595703 1442.656738,565.777588 1440.113159,565.974487 
	C1440.036865,565.333862 1439.881714,564.031921 1439.867798,563.914795 
	C1435.217896,564.992981 1430.508667,566.084839 1425.422485,566.583130 
	C1425.037598,565.000122 1425.029785,564.010742 1425.021973,563.021301 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1408.020752,549.159302 
	C1408.086304,550.720764 1407.805786,552.392090 1407.352539,555.091736 
	C1409.071167,553.799927 1411.223633,553.016663 1411.798828,551.595215 
	C1413.616089,547.103882 1416.529297,548.728821 1419.297607,549.851440 
	C1421.138794,550.598022 1422.781982,551.832642 1424.817993,553.027405 
	C1424.817993,552.744324 1424.817993,553.064697 1424.817993,553.385071 
	C1420.125000,552.302490 1416.845093,559.280945 1411.680298,555.701233 
	C1411.428589,555.526794 1410.789551,555.707031 1410.440430,555.910217 
	C1408.028809,557.313904 1405.189453,557.960449 1404.358398,554.824402 
	C1403.972534,553.367676 1406.479126,551.144775 1408.020752,549.159302 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1270.485596,540.020142 
	C1270.869629,540.502136 1270.879639,540.901611 1270.872070,541.600159 
	C1265.201050,542.850159 1259.563354,543.926331 1253.880615,544.645752 
	C1252.749878,544.788879 1250.337646,543.334167 1250.405151,543.012146 
	C1250.799561,541.130005 1251.756104,539.365601 1252.790283,537.330750 
	C1253.938721,537.193604 1254.819824,537.287170 1256.421387,537.457214 
	C1254.534180,538.901733 1253.247070,539.886841 1251.960083,540.871948 
	C1252.141724,541.348328 1252.323364,541.824707 1252.505005,542.301086 
	C1254.236694,541.702271 1257.320557,541.338257 1257.472168,540.465088 
	C1258.240112,536.042175 1261.193359,536.808411 1264.485840,537.055786 
	C1266.645020,538.065308 1268.378296,539.001465 1270.485596,540.020142 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1424.638428,562.963135 
	C1425.029785,564.010742 1425.037598,565.000122 1425.142090,566.350708 
	C1418.987671,567.014343 1412.736450,567.316772 1406.485229,567.619263 
	C1406.416016,567.122742 1406.346802,566.626160 1406.277466,566.129639 
	C1406.970459,565.324341 1407.663452,564.519043 1408.529297,563.512756 
	C1406.999634,563.111084 1405.724121,562.776123 1404.448608,562.441223 
	C1404.380371,562.071167 1404.312134,561.701172 1404.243896,561.331116 
	C1405.599609,560.847412 1407.320068,559.578491 1408.250366,560.010681 
	C1413.379272,562.393005 1418.806152,562.603455 1424.638428,562.963135 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1343.102295,583.821289 
	C1340.990479,583.950623 1338.878784,584.079956 1336.326172,584.318115 
	C1335.046387,584.017700 1333.506836,583.650146 1333.479614,583.192444 
	C1333.272827,579.724182 1333.230957,576.228577 1333.485718,572.767151 
	C1333.529175,572.176331 1335.236694,571.708008 1336.591553,571.579712 
	C1338.418091,573.135498 1339.828735,574.294800 1340.563232,574.898499 
	C1341.934082,577.301270 1342.964966,579.108215 1343.881592,581.204285 
	C1343.545654,582.269409 1343.323975,583.045349 1343.102295,583.821289 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1409.095825,583.053711 
	C1408.827637,585.159546 1408.281250,586.997375 1407.734741,588.835144 
	C1405.882690,588.235168 1404.030640,587.635193 1402.178589,587.035156 
	C1402.359131,587.308777 1402.539551,587.582336 1402.720093,587.855957 
	C1400.710938,588.573853 1398.644653,590.016479 1396.704346,589.863220 
	C1392.274414,589.513306 1392.846924,586.069458 1393.840820,582.838074 
	C1394.182007,584.465576 1394.523193,586.093140 1395.015625,588.442261 
	C1396.961060,587.323181 1398.611816,586.373596 1401.520752,584.700195 
	C1398.738281,584.302063 1397.490723,584.123535 1396.243164,583.945007 
	C1396.318481,583.404602 1396.393677,582.864197 1396.468872,582.323792 
	C1398.767456,582.607849 1401.066040,582.891907 1403.364624,583.175903 
	C1403.290771,583.340271 1403.217041,583.504700 1403.143311,583.669067 
	C1405.034668,583.374573 1406.926147,583.080078 1409.095825,583.053711 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1444.981445,585.967651 
	C1444.405640,588.300720 1443.552124,590.608826 1442.343262,593.066162 
	C1440.101807,592.269470 1438.215576,591.323547 1436.329346,590.377686 
	C1436.458740,589.919128 1436.588013,589.460571 1436.717407,589.002014 
	C1435.727295,589.311646 1434.737183,589.621277 1434.246338,589.774780 
	C1433.695801,587.926331 1433.164795,586.143494 1432.503296,583.878113 
	C1432.760620,583.248108 1433.148560,583.100647 1433.536377,582.953186 
	C1433.982788,583.135437 1434.429321,583.317688 1435.245850,583.845947 
	C1436.103760,584.199585 1436.591309,584.207275 1437.078979,584.214905 
	C1436.850586,583.689697 1436.622192,583.164490 1436.393799,582.639282 
	C1438.088867,581.056396 1438.732910,581.027710 1439.559814,583.804626 
	C1440.318115,586.351257 1441.300537,586.643066 1443.665283,585.315674 
	C1444.235107,585.414246 1444.469360,585.678406 1444.981445,585.967651 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1383.759277,595.771729 
	C1381.108276,596.168823 1378.480347,596.145996 1375.477051,596.104736 
	C1375.101562,593.285278 1375.101562,590.484131 1375.101562,587.683044 
	C1375.627197,587.519836 1376.152832,587.356567 1376.678467,587.193359 
	C1377.375977,589.153564 1378.073486,591.113770 1379.024658,593.787109 
	C1379.768311,592.698608 1380.763184,591.864197 1380.940430,590.882141 
	C1382.101929,584.447144 1382.964966,583.933655 1391.461304,586.111694 
	C1388.160034,586.937134 1384.864990,586.809692 1383.797729,588.289246 
	C1382.678955,589.840027 1383.697876,592.932922 1383.759277,595.771729 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1330.920288,554.441895 
	C1331.588379,553.821838 1332.730469,553.280334 1332.848145,552.569153 
	C1333.655029,547.698914 1336.777466,549.021240 1339.555054,550.077515 
	C1341.547119,550.835083 1343.334717,552.129883 1345.160034,553.154907 
	C1342.330811,555.724731 1334.617310,557.556580 1331.306885,555.867432 
	C1330.936646,555.141663 1330.928467,554.791809 1330.920288,554.441895 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1363.014648,587.957581 
	C1361.240234,586.791748 1357.736328,591.802490 1357.811035,586.233032 
	C1360.733887,588.052063 1362.094238,586.045715 1363.797363,584.406433 
	C1367.603760,580.742676 1371.529053,577.195007 1375.566772,573.790710 
	C1375.947876,573.469421 1377.467651,574.498474 1378.747925,575.356018 
	C1379.064941,576.484497 1379.092651,577.157532 1379.120605,577.830566 
	C1378.573120,579.776184 1378.025635,581.721863 1377.079346,585.084778 
	C1375.813843,582.229004 1375.039307,580.481140 1373.995605,578.125671 
	C1368.941528,579.494141 1366.947754,584.686523 1363.014648,587.957581 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1337.851440,563.790161 
	C1336.869141,565.012390 1335.801880,565.979370 1334.413330,566.979858 
	C1331.234131,567.617371 1328.375977,568.221558 1325.517822,568.825684 
	C1325.343872,568.304626 1325.170044,567.783569 1324.996216,567.262512 
	C1326.482422,566.243469 1327.968750,565.224487 1330.124146,563.746704 
	C1328.335815,563.209778 1327.181641,562.863281 1325.734375,562.428711 
	C1328.286255,558.892700 1330.303467,561.022949 1332.725586,562.924805 
	C1334.189453,563.151733 1335.268433,563.013672 1336.347534,562.875610 
	C1336.820557,563.095398 1337.293457,563.315247 1337.851440,563.790161 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1343.995850,580.915161 
	C1342.964966,579.108215 1341.934082,577.301270 1340.563232,574.898499 
	C1339.828735,574.294800 1338.418091,573.135498 1336.983643,571.631348 
	C1340.579834,571.728882 1345.810425,568.246277 1346.455566,575.702515 
	C1345.458130,577.714111 1344.726929,579.314636 1343.995850,580.915161 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1384.965942,576.013428 
	C1385.829468,575.621582 1386.671997,574.965454 1387.560913,574.894958 
	C1389.213989,574.763977 1390.893921,574.974426 1392.562744,575.042725 
	C1392.027710,576.612732 1391.612549,579.462524 1390.936279,579.525879 
	C1388.342407,579.768921 1385.664917,579.117920 1383.147583,578.343262 
	C1383.839722,577.261841 1384.402832,576.637573 1384.965942,576.013428 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1433.317139,582.652527 
	C1433.148560,583.100647 1432.760620,583.248108 1432.194580,583.660278 
	C1430.934692,583.422119 1429.853149,582.919189 1428.585205,582.153870 
	C1427.576904,580.686951 1426.512695,579.578186 1425.989258,578.255493 
	C1425.184814,576.221680 1424.751709,574.040894 1424.128906,571.546936 
	C1424.982422,571.390564 1426.310425,571.316589 1426.681274,571.876709 
	C1428.941162,575.288330 1430.983154,578.844177 1433.317139,582.652527 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1276.444824,570.345642 
	C1275.882446,566.675842 1275.720215,562.973389 1274.812500,559.106079 
	C1272.201538,559.297241 1268.622314,557.315125 1269.916626,561.982849 
	C1269.916626,561.982849 1269.990967,561.989807 1269.687744,561.853882 
	C1268.590332,561.437927 1267.796143,561.157776 1267.001831,560.877686 
	C1266.932251,560.687805 1266.862793,560.497986 1266.598633,560.073792 
	C1266.888916,559.215149 1267.347778,558.076477 1267.863281,558.050293 
	C1271.118652,557.885071 1274.386597,557.966492 1278.735229,557.966492 
	C1278.050781,562.437683 1277.447876,566.375366 1276.444824,570.345642 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1343.881592,581.204285 
	C1344.726929,579.314636 1345.458130,577.714111 1346.558838,576.080444 
	C1348.664917,578.428345 1350.401611,580.809448 1351.918945,583.541443 
	C1350.859863,583.912048 1350.019897,583.931702 1348.770508,583.884888 
	C1346.843994,583.851868 1345.326904,583.885315 1343.456055,583.869995 
	C1343.323975,583.045349 1343.545654,582.269409 1343.881592,581.204285 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1330.536377,554.566589 
	C1330.928467,554.791809 1330.936646,555.141663 1330.935791,555.751648 
	C1329.131958,556.161438 1327.317505,556.497131 1325.557739,556.312805 
	C1325.180298,556.273254 1324.426636,553.858948 1324.832764,553.386597 
	C1326.235107,551.755249 1328.073364,550.498779 1329.745728,549.099548 
	C1330.061768,549.511230 1330.377808,549.922913 1330.693848,550.334595 
	C1329.397095,551.452148 1328.100464,552.569702 1325.638672,554.691284 
	C1328.005371,554.691284 1329.078857,554.691284 1330.536377,554.566589 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1300.354980,592.894775 
	C1300.017822,592.705994 1299.996582,592.526611 1299.910522,592.082825 
	C1299.845703,590.053406 1299.845703,588.288330 1299.845703,586.676086 
	C1302.359619,585.744385 1303.521362,592.177734 1305.846924,586.291809 
	C1305.846924,588.854797 1305.846924,590.638123 1305.846924,591.763489 
	C1303.889526,592.194885 1302.280396,592.549561 1300.354980,592.894775 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1375.001221,564.975464 
	C1375.103638,563.199341 1375.202271,561.844360 1375.300781,560.489319 
	C1375.934204,560.443542 1376.567505,560.397705 1377.200928,560.351929 
	C1377.557861,563.380920 1377.914795,566.409912 1378.271729,569.438904 
	C1377.643311,569.615723 1377.014893,569.792480 1376.386475,569.969299 
	C1375.923462,568.445068 1375.460449,566.920776 1375.001221,564.975464 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1374.940186,549.399048 
	C1376.031860,545.875305 1377.392700,547.228516 1377.741089,549.101196 
	C1378.193970,551.536560 1377.851807,554.119873 1377.851807,556.639465 
	C1377.180542,556.702209 1376.509399,556.764954 1375.838135,556.827698 
	C1375.534058,554.492004 1375.229980,552.156250 1374.940186,549.399048 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1384.973999,575.602905 
	C1384.402832,576.637573 1383.839722,577.261841 1383.000854,578.049561 
	C1381.748779,578.191345 1380.772339,578.169617 1379.458252,577.989258 
	C1379.092651,577.157532 1379.064941,576.484497 1379.068726,575.474182 
	C1381.070923,574.095337 1383.054321,571.704895 1384.973999,575.602905 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1399.456055,534.031982 
	C1404.486206,533.681519 1409.984741,533.350769 1415.996338,533.034302 
	C1410.980957,533.382935 1405.452759,533.717346 1399.456055,534.031982 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1284.633301,552.377075 
	C1283.872314,551.299927 1283.453735,550.347839 1283.068726,548.979614 
	C1284.537842,548.728027 1285.993530,548.798767 1287.399658,549.100708 
	C1288.008911,549.231567 1288.515869,549.838989 1288.663574,550.630920 
	C1287.843384,551.338989 1287.429077,551.645996 1287.014648,551.953003 
	C1286.335083,552.135986 1285.655518,552.319031 1284.633301,552.377075 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1287.187378,552.171143 
	C1287.429077,551.645996 1287.843384,551.338989 1288.531128,551.026367 
	C1288.954590,551.985291 1289.104736,552.949829 1289.461670,555.241760 
	C1288.202148,553.532166 1287.781128,552.960693 1287.187378,552.171143 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1336.127930,562.557434 
	C1335.268433,563.013672 1334.189453,563.151733 1333.098022,563.014893 
	C1334.026611,562.573120 1334.967407,562.406128 1336.127930,562.557434 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1268.049316,583.346130 
	C1268.833740,583.572388 1269.618042,583.798584 1270.402466,584.024780 
	C1269.879272,584.385315 1269.355957,584.745850 1268.417236,584.776794 
	C1268.017700,584.080200 1268.033569,583.713135 1268.049316,583.346130 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1267.787720,583.290466 
	C1268.033569,583.713135 1268.017700,584.080200 1268.000977,584.722900 
	C1267.559448,584.719666 1267.118774,584.440735 1266.678223,584.161804 
	C1266.960815,583.852783 1267.243408,583.543762 1267.787720,583.290466 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1212.616089,548.166016 
	C1212.986938,550.252441 1212.981323,552.343872 1212.988892,554.901306 
	C1210.656616,553.249756 1208.285889,552.371704 1205.806885,553.757874 
	C1203.991821,553.703613 1201.814087,552.981140 1200.435059,553.729980 
	C1197.266235,555.450439 1194.701416,556.750610 1190.684082,555.361267 
	C1187.573120,554.285339 1183.676636,555.526184 1180.126343,555.657959 
	C1177.929688,555.739563 1175.727417,555.673157 1173.264160,555.673157 
	C1173.152588,553.459351 1173.062866,551.679260 1172.976807,549.971619 
	C1179.700562,547.490784 1179.700562,547.490784 1181.755371,550.076477 
	C1184.506958,549.737122 1186.781738,549.349182 1189.071899,549.204895 
	C1191.295898,549.064758 1193.535645,549.174438 1196.327271,549.174438 
	C1194.847046,550.495056 1193.526611,551.673157 1192.078369,552.965332 
	C1194.495239,553.751709 1194.326050,553.442993 1200.305908,550.166138 
	C1202.530518,548.947144 1205.752686,549.548401 1208.519897,549.319580 
	C1208.610107,549.790771 1208.700317,550.261902 1208.790527,550.733032 
	C1209.940186,549.879028 1211.089844,549.025024 1212.616089,548.166016 
M1179.469238,553.373230 
	C1178.120972,553.110413 1176.772583,552.847595 1175.424316,552.584778 
	C1175.386719,553.020996 1175.348999,553.457214 1175.311401,553.893433 
	C1176.503418,553.893433 1177.695557,553.893433 1179.469238,553.373230 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1160.069214,634.877441 
	C1158.268555,635.119385 1156.467896,635.361328 1154.327026,635.797729 
	C1153.986816,635.992310 1154.002319,636.001770 1154.009033,636.009033 
	C1154.015625,633.397949 1154.015625,630.779602 1154.015625,628.005798 
	C1157.542725,629.456238 1157.991821,626.911743 1157.996216,623.937317 
	C1158.007324,616.301941 1157.999878,608.666626 1157.999878,600.208984 
	C1160.596558,603.331055 1163.785645,595.387512 1165.684570,601.482239 
	C1166.017578,602.551147 1163.871582,604.392395 1162.953613,605.753479 
	C1166.945801,607.578552 1167.842041,620.062500 1163.420776,625.124756 
	C1164.250732,625.825256 1165.078979,626.527954 1165.610718,627.578979 
	C1159.981689,629.008484 1158.845825,630.513611 1160.069214,634.877441 
M1164.077881,616.839111 
	C1161.432251,614.298401 1161.096436,614.316650 1159.170654,617.299316 
	C1160.866211,617.374573 1162.469727,617.445801 1164.077881,616.839111 
M1163.437256,603.395935 
	C1163.502808,603.140503 1163.568359,602.885010 1163.633911,602.629517 
	C1163.477661,602.793884 1163.321411,602.958252 1163.437256,603.395935 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1198.974976,633.409851 
	C1200.689087,630.694153 1202.403198,627.978455 1204.117310,625.262756 
	C1204.205811,625.416443 1204.294312,625.570190 1204.382935,625.723938 
	C1207.587402,622.725769 1210.791992,619.727600 1214.369141,616.374756 
	C1215.131592,615.925293 1215.521729,615.830505 1216.299805,615.752075 
	C1217.201294,615.713257 1217.714844,615.658203 1218.676392,615.671875 
	C1228.095703,615.585815 1237.066772,615.430908 1246.267822,615.477661 
	C1247.243408,615.509338 1247.989258,615.339417 1248.735107,615.169434 
	C1248.716431,614.903992 1248.697754,614.638550 1248.679199,614.373169 
	C1247.815063,614.218689 1246.950928,614.064209 1245.913452,613.704956 
	C1247.105103,613.215698 1249.544800,612.440308 1249.672852,612.720764 
	C1251.430908,616.570068 1254.757324,614.943726 1257.484131,615.148254 
	C1259.961304,615.334045 1262.457397,615.265564 1264.956055,615.596558 
	C1264.976807,616.267944 1264.986694,616.651917 1264.534668,617.095703 
	C1259.949463,617.155640 1255.826050,617.155640 1251.796509,617.155640 
	C1251.072144,619.023376 1250.531372,620.417725 1250.281616,621.061646 
	C1248.735352,619.815979 1247.368896,617.765381 1245.982422,617.751709 
	C1236.705811,617.660706 1227.425049,618.003601 1217.798340,618.176025 
	C1216.682373,618.465576 1215.913574,618.784851 1214.977539,619.391357 
	C1212.217773,621.881226 1209.624878,624.083862 1206.951294,626.355164 
	C1207.750366,628.135925 1208.407104,629.599548 1208.690186,631.104675 
	C1204.113159,628.421143 1202.192505,632.626404 1199.172363,633.979187 
	C1198.974854,633.865540 1198.974976,633.409851 1198.974976,633.409851 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1184.092773,571.855713 
	C1182.880005,570.938416 1180.776978,570.150513 1180.621216,569.079590 
	C1180.156250,565.880493 1180.454346,562.570557 1180.454346,558.364014 
	C1184.825439,560.331665 1190.277710,555.443237 1192.645996,561.012146 
	C1195.081299,566.738342 1192.893066,571.050476 1188.340088,572.507874 
	C1186.701904,572.219238 1185.397339,572.037476 1184.092773,571.855713 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1160.372803,635.068542 
	C1158.845825,630.513611 1159.981689,629.008484 1165.705078,627.910095 
	C1168.587769,628.038879 1171.079590,628.184814 1174.055054,628.359070 
	C1172.507324,629.777588 1171.204956,630.971252 1169.902710,632.164856 
	C1170.028931,632.442505 1170.155151,632.720154 1170.281494,632.997803 
	C1171.928101,632.679688 1173.574707,632.361572 1175.861450,631.911133 
	C1176.772217,631.312805 1177.042847,630.846741 1177.313477,630.380737 
	C1176.892212,630.257507 1176.470825,630.134277 1176.049561,630.011108 
	C1177.166016,626.556519 1180.640137,626.415649 1184.716064,630.237366 
	C1185.019165,631.246948 1185.026001,631.691040 1185.032959,632.135071 
	C1184.499512,632.924011 1183.966187,633.712952 1183.336426,634.644348 
	C1181.466187,632.435608 1180.198364,629.370422 1177.021973,633.886047 
	C1175.927246,635.442322 1171.347046,634.557617 1168.357666,634.767212 
	C1165.798218,634.946655 1163.236938,635.096619 1160.372803,635.068542 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1164.648071,593.156494 
	C1162.525879,593.824280 1160.598633,594.200256 1157.796997,594.746704 
	C1158.351807,590.943054 1158.846191,587.553345 1159.340698,584.163635 
	C1158.960571,584.038879 1158.580566,583.914124 1158.200439,583.789307 
	C1159.214722,583.056213 1160.229004,582.323120 1160.274170,582.290405 
	C1159.198608,580.129211 1158.324585,578.372925 1157.450562,576.616577 
	C1158.985596,576.304321 1160.516479,575.969421 1162.057129,575.687683 
	C1163.130371,575.491455 1164.217163,575.368164 1166.804199,574.994995 
	C1164.701294,576.626404 1163.784668,577.337524 1162.418823,578.397095 
	C1163.711670,578.924500 1164.766724,579.354858 1164.842896,579.385925 
	C1164.842896,584.335693 1164.842896,588.600159 1164.648071,593.156494 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1185.206421,654.430237 
	C1184.129395,654.246399 1183.052490,654.062622 1181.564941,653.867310 
	C1180.815796,650.122559 1180.319214,646.391357 1180.271118,642.654480 
	C1180.261597,641.905334 1181.895752,640.596008 1182.882568,640.479248 
	C1185.338745,640.188721 1187.858521,640.436646 1190.725098,640.939331 
	C1191.792480,641.999207 1192.487671,642.588135 1193.127319,643.600769 
	C1193.320435,646.738159 1193.568970,649.451904 1193.748047,652.397217 
	C1193.413208,653.033691 1193.148071,653.438538 1192.458496,653.915283 
	C1189.758301,654.134827 1187.482300,654.282532 1185.206421,654.430237 
M1186.275269,648.797241 
	C1185.875122,649.638062 1185.475098,650.478882 1184.905640,651.675415 
	C1187.113770,651.675415 1188.931519,651.675415 1190.730957,651.675415 
	C1190.730957,649.442200 1190.730957,647.500244 1190.730957,645.569641 
	C1188.946655,646.507874 1187.486938,647.275513 1185.981689,647.320923 
	C1186.905396,646.042419 1187.829102,644.763855 1188.822510,643.388916 
	C1181.911621,641.779907 1181.015137,642.743835 1182.700195,649.656494 
	C1183.823242,649.109375 1184.932373,648.568970 1186.275269,648.797241 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1215.911865,615.735718 
	C1215.521729,615.830505 1215.131592,615.925293 1214.440918,616.037048 
	C1213.755127,611.090210 1213.369995,606.126343 1212.956787,600.714844 
	C1213.693237,600.185303 1214.457886,600.103455 1215.604004,600.485107 
	C1215.960938,605.877625 1215.936401,610.806641 1215.911865,615.735718 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1176.933472,619.914429 
	C1177.582153,614.117310 1180.732788,615.093445 1183.636230,617.854431 
	C1184.592163,618.763306 1183.959229,621.342773 1184.069824,623.487549 
	C1182.313721,623.069946 1180.504395,622.639709 1178.260010,621.792542 
	C1177.527588,620.888489 1177.230591,620.401489 1176.933472,619.914429 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1176.625732,620.085999 
	C1177.230591,620.401489 1177.527588,620.888489 1177.921509,621.644287 
	C1177.148682,622.264404 1176.307129,622.756775 1175.403320,622.937439 
	C1173.610596,623.295593 1171.732788,623.812256 1170.000610,623.545654 
	C1169.453125,623.461365 1168.536499,620.552673 1169.043091,619.876587 
	C1170.286255,618.217712 1172.259766,617.105957 1173.939331,615.774048 
	C1174.329834,616.284302 1174.720337,616.794617 1175.110840,617.304871 
	C1173.768433,618.463562 1172.426025,619.622192 1171.083618,620.780884 
	C1171.234009,621.144531 1171.384277,621.508179 1171.534668,621.871887 
	C1173.129028,621.333740 1174.723511,620.795654 1176.625732,620.085999 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1211.683350,583.925903 
	C1212.373657,585.292908 1212.777710,586.557617 1213.098877,588.058960 
	C1211.466797,588.871216 1209.946533,589.639465 1208.347290,589.877502 
	C1207.978394,589.932312 1206.718018,587.554565 1206.925659,587.345093 
	C1208.251465,586.007935 1209.872925,584.963989 1211.683350,583.925903 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1193.182739,643.177063 
	C1192.487671,642.588135 1191.792480,641.999207 1191.172852,641.074524 
	C1191.248169,640.738770 1191.502686,640.516174 1191.862427,640.233154 
	C1192.419189,639.234619 1192.616211,638.519043 1192.813232,637.803528 
	C1194.608765,636.379211 1196.404419,634.954895 1198.587402,633.470215 
	C1198.974976,633.409851 1198.974854,633.865540 1199.005371,634.292480 
	C1197.084717,637.538696 1195.133789,640.357910 1193.182739,643.177063 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1184.367432,572.132202 
	C1185.397339,572.037476 1186.701904,572.219238 1188.063965,572.777161 
	C1188.340332,573.495361 1188.559204,573.837463 1188.559814,574.627258 
	C1188.440186,575.402344 1188.538574,575.729675 1188.637085,576.057007 
	C1188.633301,577.213074 1188.629517,578.369141 1188.342529,579.781982 
	C1187.547241,579.770813 1187.035034,579.502869 1186.255859,579.119873 
	C1185.539917,576.806152 1185.090942,574.607483 1184.367432,572.132202 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1192.945801,587.960205 
	C1194.870483,587.398621 1196.782227,586.816162 1198.693848,586.233765 
	C1198.787109,586.609924 1198.880249,586.986023 1198.973389,587.362183 
	C1197.226562,587.531250 1195.479614,587.700317 1193.313232,587.889282 
	C1192.893555,587.909180 1192.932739,587.939453 1192.945801,587.960205 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1190.227295,629.920166 
	C1190.348999,631.114990 1190.470703,632.309875 1190.655151,634.120178 
	C1189.141357,633.343079 1188.322266,632.922668 1187.348389,632.137695 
	C1188.204834,631.155518 1189.216064,630.537842 1190.227295,629.920166 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1190.248047,629.547424 
	C1189.216064,630.537842 1188.204834,631.155518 1187.029053,632.092285 
	C1186.446655,632.408936 1186.028809,632.406555 1185.321899,632.269653 
	C1185.026001,631.691040 1185.019165,631.246948 1185.019653,630.470276 
	C1186.774292,629.816650 1188.521606,629.495667 1190.248047,629.547424 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1192.516602,637.922058 
	C1192.616211,638.519043 1192.419189,639.234619 1191.972046,640.104492 
	C1191.887939,639.519409 1192.053955,638.780029 1192.516602,637.922058 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1175.995605,630.340210 
	C1176.470825,630.134277 1176.892212,630.257507 1177.313477,630.380737 
	C1177.042847,630.846741 1176.772217,631.312805 1176.247803,631.889099 
	C1175.976562,631.556030 1175.959106,631.112671 1175.995605,630.340210 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1188.639648,575.841370 
	C1188.538574,575.729675 1188.440186,575.402344 1188.599609,574.937012 
	C1188.850830,575.091492 1188.779053,575.367065 1188.639648,575.841370 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1466.018555,595.500122 
	C1465.596436,595.876343 1465.108521,595.843689 1464.251953,595.808105 
	C1463.984253,594.549438 1458.926514,595.318481 1462.830566,592.418823 
	C1463.390991,583.578552 1463.432373,583.548767 1472.652832,586.237122 
	C1470.318970,586.560120 1467.985107,586.883179 1465.055908,587.288574 
	C1465.372681,590.044617 1465.662720,592.567932 1466.018555,595.500122 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1461.715088,573.221680 
	C1468.250244,572.648926 1465.370605,576.609009 1465.372925,579.631042 
	C1464.261963,579.003906 1463.532593,578.592163 1462.473877,578.250305 
	C1458.877197,577.138367 1459.407593,575.400146 1461.715088,573.221680 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1424.955322,530.724731 
	C1424.151855,530.895386 1423.550171,530.895386 1422.948364,530.895386 
	C1422.958740,530.680054 1422.969116,530.464722 1422.979492,530.249329 
	C1423.705200,530.350891 1424.431030,530.452515 1424.955322,530.724731 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2510.031738,560.073120 
	C2509.942139,562.111572 2509.858154,563.719482 2509.773926,565.327393 
	C2509.225586,565.339966 2508.677002,565.352539 2508.128662,565.365112 
	C2508.128662,561.790527 2508.083984,558.215332 2508.136719,554.641541 
	C2508.307861,543.103210 2505.958496,543.986938 2519.117432,543.975952 
	C2520.197754,543.975098 2521.278076,544.213013 2522.358398,544.339966 
	C2522.426758,544.777283 2522.495117,545.214600 2522.563477,545.651917 
	C2520.354492,546.030334 2518.132080,546.790466 2515.938721,546.714478 
	C2509.753906,546.500000 2509.443359,546.737549 2510.457764,553.494263 
	C2512.461914,553.970764 2514.540771,554.465027 2516.800293,555.002197 
	C2515.295410,558.552612 2509.861572,554.595520 2510.031738,560.073120 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2518.734131,525.906250 
	C2508.921875,526.912048 2506.815430,523.831665 2507.068604,514.477905 
	C2507.199707,509.631287 2506.876465,504.748016 2512.651611,503.293152 
	C2515.710449,502.522430 2519.123779,503.156769 2522.376953,503.156769 
	C2522.476807,503.729126 2522.576904,504.301453 2522.676758,504.873810 
	C2520.879639,505.207581 2519.088135,505.790192 2517.284180,505.832397 
	C2509.142822,506.022980 2508.968994,506.145752 2509.020020,514.402466 
	C2509.074951,523.278870 2510.922119,524.765503 2521.472656,523.339844 
	C2520.766846,520.268677 2520.084961,517.301331 2519.309570,513.927490 
	C2521.783447,512.333923 2523.281006,512.780518 2522.823975,516.452393 
	C2522.416748,519.723633 2526.038818,525.010559 2518.734131,525.906250 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2527.991455,1001.991394 
	C2528.432617,1003.688477 2528.719238,1005.445374 2529.348389,1007.069519 
	C2530.483643,1009.999329 2530.647705,1013.092224 2527.689941,1014.482117 
	C2525.217041,1015.644165 2522.068604,1015.493469 2519.211182,1015.559448 
	C2518.845215,1015.567932 2518.138672,1013.388855 2518.088623,1012.202148 
	C2517.920654,1008.222900 2518.152344,1004.221802 2517.873535,1000.254272 
	C2517.744629,998.416504 2516.790039,996.636902 2515.943359,994.002136 
	C2518.769043,994.002136 2522.138184,993.631653 2525.385742,994.102295 
	C2528.909912,994.613037 2530.289307,998.392151 2528.329102,1001.618225 
	C2527.511230,997.803589 2525.564941,995.206299 2522.212646,996.419739 
	C2520.640625,996.988770 2520.156982,1000.565491 2518.608154,1004.075256 
	C2522.985107,1003.103210 2525.488281,1002.547302 2527.991455,1001.991394 
M2520.012939,1007.739319 
	C2520.131104,1009.925049 2520.249023,1012.110718 2520.446777,1015.773071 
	C2523.579834,1013.891113 2525.848633,1012.875000 2527.581055,1011.277039 
	C2528.103516,1010.794983 2527.434082,1007.714233 2526.795410,1007.520142 
	C2524.732422,1006.893311 2522.429688,1007.055908 2520.012939,1007.739319 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2149.651367,1213.985107 
	C2148.174072,1213.242310 2146.660889,1212.594604 2146.182861,1211.496460 
	C2145.881592,1210.804199 2147.064941,1208.596558 2147.694336,1208.548828 
	C2149.004395,1208.449585 2150.388672,1209.334106 2151.742432,1209.814331 
	C2151.179443,1211.172852 2150.616455,1212.531372 2149.651367,1213.985107 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2185.000000,1209.454102 
	C2186.205566,1211.707031 2187.056641,1213.978027 2188.208740,1217.053345 
	C2186.283936,1217.226074 2184.841553,1217.355347 2183.399414,1217.484741 
	C2183.304688,1216.174194 2182.970947,1214.824097 2183.179199,1213.563477 
	C2183.411377,1212.157227 2184.135254,1210.832397 2185.000000,1209.454102 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M303.064331,932.560669 
	C301.757629,932.837280 300.500641,932.705627 299.699677,932.621704 
	C299.154968,880.241028 298.614868,828.302368 298.315582,775.727173 
	C299.318542,774.516602 300.080658,773.942627 300.839478,773.813965 
	C300.910797,795.813782 300.985382,817.368286 301.026367,839.397766 
	C300.995239,845.319336 300.872955,850.770935 301.091675,856.208801 
	C301.143646,857.500183 302.337921,858.745605 303.006775,860.012207 
	C303.006775,860.012207 303.009369,860.500000 302.724030,860.973267 
	C301.977722,867.091736 301.137848,872.739685 301.179565,878.381104 
	C301.211090,882.649292 302.368164,886.909058 303.024292,891.172607 
	C303.346649,898.153320 303.668976,905.134094 303.700623,912.464966 
	C302.949890,913.191589 302.489838,913.568176 302.029755,913.944702 
	C303.033142,914.313904 304.039093,915.011963 305.039551,915.004211 
	C328.333893,914.823547 351.627594,914.566406 374.921448,914.323425 
	C374.932037,914.952454 374.942596,915.581543 374.478455,916.588745 
	C364.309784,917.052979 354.615845,917.138977 344.486572,917.188049 
	C332.551147,917.416626 321.050140,917.652710 309.552155,917.991577 
	C308.354858,918.026855 307.175507,918.670837 305.987793,919.032410 
	C305.329315,920.955505 304.477783,922.837891 304.061707,924.812134 
	C303.554565,927.218567 303.413483,929.702087 303.064331,932.560669 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M308.041443,750.940857 
	C308.288116,750.469910 308.534790,749.998901 309.368134,749.263062 
	C319.196167,748.935913 328.437500,748.873474 337.678864,748.811096 
	C337.943237,748.826050 338.207642,748.841064 339.167633,748.855469 
	C340.462219,748.828552 341.061218,748.802246 341.660187,748.775940 
	C346.046692,748.456726 350.433228,748.137451 355.162720,747.414429 
	C355.505676,747.010681 355.986816,747.018494 356.058411,747.358032 
	C357.799530,749.635620 359.138031,749.657898 360.474030,747.030396 
	C364.066956,747.232178 367.201599,747.478882 370.340973,747.617310 
	C379.097107,748.003418 387.863129,748.773682 396.610931,748.612976 
	C414.088318,748.291931 431.556183,747.450806 449.027954,746.824463 
	C451.852020,747.207825 454.666779,747.799500 457.502289,747.925598 
	C461.772095,748.115479 466.071655,747.777161 470.326019,748.096680 
	C471.746307,748.203308 473.066833,749.638489 474.707703,750.727783 
	C475.110077,751.999268 475.237732,753.009338 475.004822,754.465576 
	C473.055298,754.780701 470.768524,755.771362 471.549377,752.345337 
	C471.726501,751.568298 469.723022,750.294189 468.281189,749.216187 
	C438.371277,749.504761 408.903503,749.825989 379.159851,750.150208 
	C379.159851,759.261902 379.159851,767.550598 379.109619,776.196350 
	C379.040283,777.033264 379.021149,777.513245 379.002014,777.993164 
	C378.625824,775.984741 377.979156,773.983704 377.924286,771.966553 
	C377.762787,766.026611 377.936768,760.076843 377.738678,754.138977 
	C377.694916,752.826172 376.659607,751.546570 375.340790,750.191162 
	C371.293976,750.912109 367.987183,751.693604 364.265808,752.572998 
	C364.202820,752.430359 363.723328,751.345032 363.326874,750.447571 
	C360.618347,750.635986 358.309906,750.796570 356.001465,750.957153 
	C353.912201,751.215210 351.811066,751.403503 349.737946,751.756470 
	C348.403259,751.983765 347.109772,752.452820 345.427460,752.915344 
	C340.983948,748.692017 335.899872,749.883423 328.471405,757.891113 
	C328.159302,755.676086 327.902985,753.856873 327.498566,750.986633 
	C327.092499,753.820984 326.832581,755.635254 326.358459,757.760742 
	C325.327026,757.996094 324.509735,757.920288 323.247101,757.699585 
	C322.177673,757.380737 321.553619,757.206909 320.929535,757.033081 
	C320.929535,757.033081 320.996002,757.008179 321.001404,756.616821 
	C320.902649,754.629700 320.798523,753.033875 320.705811,751.613037 
	C315.187103,749.535339 317.408539,754.652710 315.838593,756.134216 
	C315.838593,756.134216 315.389252,756.076904 315.168091,755.643982 
	C314.488922,754.229370 314.030914,753.247620 313.572906,752.265930 
	C311.036896,754.713684 309.929962,751.195862 308.041443,750.940857 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M301.059998,838.922729 
	C300.985382,817.368286 300.910797,795.813782 301.124756,773.647827 
	C301.997131,772.754456 302.580902,772.472595 303.164673,772.190796 
	C303.164673,780.153870 303.164673,788.117065 303.164673,796.099426 
	C309.607056,796.099426 316.002106,796.200012 322.382751,795.942993 
	C323.016449,795.917480 324.040253,793.722046 324.067749,792.515198 
	C324.200348,786.696106 324.286652,780.856323 323.928772,775.052307 
	C323.663605,770.751770 325.211243,769.062195 329.330688,770.144958 
	C332.025513,770.853333 334.614807,771.963318 337.594055,773.359924 
	C337.906219,774.078369 337.875702,774.330261 337.845215,774.582092 
	C334.557831,773.628296 331.270416,772.674438 327.131104,771.473450 
	C327.131104,779.187683 327.137665,786.079285 327.129242,792.970886 
	C327.121033,799.681091 325.623901,801.479614 318.991211,802.176392 
	C315.213684,802.573181 311.384399,802.889099 307.602509,802.715759 
	C303.878662,802.545105 302.666992,803.818542 302.788879,807.543457 
	C303.054718,815.668823 302.958893,823.807556 302.918579,831.940369 
	C302.907990,834.078247 302.560303,836.214417 302.064331,838.691284 
	C301.527466,838.994995 301.293701,838.958862 301.059998,838.922729 
M314.468658,800.995056 
	C317.609711,800.808228 320.750763,800.621460 323.891815,800.434631 
	C318.345184,796.802185 304.227203,797.676208 302.398041,800.953918 
	C306.274750,800.953918 309.886108,800.953918 314.468658,800.995056 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M335.753845,861.443970 
	C327.335724,861.053467 322.160248,857.235413 320.349396,849.504944 
	C319.864716,847.435852 321.030518,844.680115 322.136627,842.613159 
	C323.576294,839.922974 325.325165,836.028198 327.630798,835.462341 
	C332.150391,834.353027 337.941803,833.675537 341.715027,835.658325 
	C345.237396,837.509277 347.252197,842.858521 349.040436,847.047852 
	C349.689301,848.567871 348.020966,851.327393 346.959564,853.276062 
	C344.625916,857.560242 342.623260,862.515686 335.753845,861.443970 
M341.841400,837.589355 
	C332.857452,833.331055 324.491669,837.578857 323.166931,846.808533 
	C330.757416,846.808533 338.375671,846.808533 346.881592,846.808533 
	C345.022430,843.365356 343.636993,840.799438 341.841400,837.589355 
M326.676941,857.090515 
	C330.915558,861.212280 336.323914,859.288391 340.710052,858.512451 
	C343.132812,858.083862 344.725098,852.960999 347.077087,849.365234 
	C338.176117,849.365234 330.691040,849.365234 322.306427,849.365234 
	C323.895721,852.191467 325.080933,854.299133 326.676941,857.090515 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M412.208130,891.283203 
	C403.293091,891.882080 394.740326,892.316223 385.296204,892.795593 
	C385.584717,890.346802 385.064423,887.015808 386.345123,886.034058 
	C387.672943,885.016052 390.747528,886.257568 393.053253,886.549988 
	C393.509247,886.607849 394.015411,887.074402 394.375916,886.961426 
	C400.514557,885.037598 406.588379,884.736755 412.783722,886.924927 
	C413.803070,887.284973 415.368286,886.099548 416.681244,885.628357 
	C416.878601,886.062378 417.075989,886.496338 417.273346,886.930359 
	C415.705719,888.326416 414.138062,889.722412 412.208130,891.283203 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M324.002258,872.015625 
	C325.020081,871.961914 326.037903,871.908203 327.055695,871.854492 
	C327.160065,871.299988 327.264465,870.745544 327.368835,870.191040 
	C325.583496,869.770020 323.798157,869.348999 321.541901,868.722778 
	C320.100403,868.400024 319.094971,868.071472 318.169098,868.224365 
	C317.247162,868.376587 311.564240,867.526917 311.084717,866.519043 
	C313.024170,864.020752 314.884827,861.840271 316.989929,859.373413 
	C308.850037,858.400635 308.526337,858.736572 309.731293,866.083557 
	C309.784760,866.409668 309.865417,866.731384 309.583527,867.163025 
	C308.294891,867.524109 307.356171,867.777283 306.417480,868.030518 
	C303.581085,862.840820 305.353271,857.705994 310.887726,855.078186 
	C315.209503,853.026184 320.466309,855.425964 322.666199,860.321594 
	C323.589691,862.376770 324.800720,864.302795 325.594177,866.656250 
	C326.471680,868.022034 327.349182,869.387817 328.734833,871.544495 
	C327.189117,871.875244 325.830994,872.165894 324.240387,872.229980 
	C324.007904,872.003540 324.002258,872.015564 324.002258,872.015625 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M419.862335,881.772461 
	C416.441772,882.881348 413.780884,882.714417 414.310394,877.045654 
	C412.395966,879.225586 411.265320,880.513000 409.897552,882.070496 
	C408.826752,880.687927 407.957581,879.565674 407.240051,878.639221 
	C401.178284,884.701355 393.400208,880.427307 386.140137,883.464661 
	C386.327881,879.970459 386.442230,877.842041 386.597107,874.959656 
	C387.851227,877.226807 388.619629,878.615906 389.388062,880.005005 
	C391.107330,878.513977 392.826599,877.022949 395.158966,875.216187 
	C396.282654,874.807495 396.793274,874.714600 397.732117,874.733521 
	C401.052124,874.792419 403.943939,874.739563 407.049133,874.825684 
	C407.430542,874.989807 407.598511,875.014832 407.830963,875.395081 
	C408.637512,877.729370 409.450623,879.183899 411.475250,876.716125 
	C412.268066,875.749695 413.374786,875.040833 414.643890,874.212646 
	C415.336517,874.086731 415.723785,873.963013 416.444366,873.929016 
	C417.893829,874.297058 419.009979,874.575317 420.062134,875.221680 
	C419.028656,876.653259 418.059204,877.716736 417.203888,878.654907 
	C417.979614,879.564636 418.920959,880.668518 419.862335,881.772461 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M337.948639,774.874023 
	C337.875702,774.330261 337.906219,774.078369 337.952759,773.446533 
	C345.318665,777.154846 350.319824,783.099487 352.003937,791.447876 
	C352.724335,795.018921 354.438080,796.315491 358.128632,796.089294 
	C363.436249,795.764038 368.778595,796.006287 374.966309,796.006287 
	C374.966309,802.492432 374.966309,808.910400 374.966309,815.328369 
	C374.738403,815.357849 374.510529,815.387329 374.282623,815.416809 
	C374.184387,813.954895 374.024078,812.494019 373.997955,811.030823 
	C373.817352,800.928223 375.030151,802.152344 364.731110,802.085266 
	C360.422333,802.057190 356.113220,802.080627 351.103546,802.080627 
	C351.464478,790.619385 347.640381,781.395569 337.948639,774.874023 
M370.963440,798.021484 
	C365.166229,798.021484 359.369019,798.021484 353.571808,798.021484 
	C353.587952,798.502869 353.604095,798.984253 353.620239,799.465637 
	C360.194427,799.465637 366.768585,799.465637 373.342773,799.465637 
	C373.376587,799.172485 373.410400,798.879333 373.444214,798.586182 
	C372.901245,798.400635 372.358276,798.215088 370.963440,798.021484 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M356.205383,751.195312 
	C358.309906,750.796570 360.618347,750.635986 363.326874,750.447571 
	C363.723328,751.345032 364.202820,752.430359 364.265808,752.572998 
	C367.987183,751.693604 371.293976,750.912109 374.960541,750.189575 
	C375.216125,762.893433 375.110382,775.538513 375.009399,788.183533 
	C374.996399,789.811523 375.007660,791.439636 375.007660,793.067627 
	C374.524353,793.079346 374.041046,793.091064 373.557739,793.102783 
	C373.557739,781.553894 373.557739,770.005005 373.557739,758.881287 
	C369.297821,757.261536 366.019501,756.736084 362.559235,757.162109 
	C356.752045,757.876953 355.842682,756.972961 356.205383,751.195312 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M397.303864,874.621704 
	C396.793274,874.714600 396.282654,874.807495 395.386017,874.950195 
	C394.999969,871.881042 394.999969,868.762085 394.999969,864.877869 
	C390.625244,864.877869 386.681335,864.535950 382.867188,865.088928 
	C381.742920,865.251953 380.425262,867.861328 380.178864,869.497559 
	C379.693085,872.723389 378.202606,873.331421 374.947388,872.268494 
	C374.947388,864.932678 374.947388,857.519531 375.210266,849.563232 
	C375.680176,848.965454 375.887207,848.910828 376.139160,848.973022 
	C376.184082,849.089905 375.989441,849.247498 375.993317,849.700073 
	C375.512360,867.945740 375.588104,868.746582 378.953247,871.558533 
	C378.563202,863.557190 378.215240,856.419739 378.177948,849.168701 
	C378.920197,848.964844 379.351837,848.874573 379.871704,849.243164 
	C379.993439,850.697266 380.179108,851.715271 380.034851,852.684143 
	C379.287872,857.701050 381.241730,859.734619 386.376068,859.042297 
	C389.226257,858.657959 392.161438,858.903687 395.106995,859.229736 
	C395.165405,859.838867 395.175110,860.083679 394.705200,860.239136 
	C389.513214,860.183716 384.720856,859.450195 380.117981,860.533752 
	C380.150024,861.388916 380.182098,862.244080 380.214142,863.099243 
	C385.233643,862.849182 390.253174,862.599182 395.639648,862.570190 
	C396.439026,866.734680 396.871460,870.678223 397.303864,874.621704 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M375.131653,914.144348 
	C351.627594,914.566406 328.333893,914.823547 305.039551,915.004211 
	C304.039093,915.011963 303.033142,914.313904 302.029755,913.944702 
	C302.489838,913.568176 302.949890,913.191589 303.693726,912.937073 
	C306.936829,913.347717 309.899933,913.918884 312.854889,913.879395 
	C333.231720,913.606873 353.606842,913.206970 374.436523,912.760742 
	C375.040985,913.104065 375.191406,913.534668 375.131653,914.144348 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M332.964905,868.564697 
	C332.827148,870.009644 332.747650,871.115662 332.668182,872.221741 
	C332.241943,872.328430 331.815735,872.435120 331.389496,872.541809 
	C330.757263,870.628845 330.125000,868.715820 329.075348,865.539917 
	C333.280334,865.826111 336.648163,866.184937 340.020966,866.240417 
	C342.365479,866.278931 345.241638,865.007507 346.903198,865.983887 
	C348.295319,866.802002 348.272461,870.027527 348.876801,872.186890 
	C348.913635,872.318481 348.955811,872.448608 348.781891,871.875610 
	C345.337769,872.249756 341.788513,872.619202 338.243439,873.024902 
	C334.719330,873.428101 334.031494,871.239075 333.970337,868.434204 
	C333.967316,868.295898 333.809906,868.044434 333.728943,868.046326 
	C333.492462,868.051636 333.258270,868.158691 332.964905,868.564697 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M376.472778,898.051025 
	C376.427948,899.289185 375.980469,900.456665 375.271118,901.804077 
	C373.560638,901.341736 372.125946,900.184998 370.661102,900.145447 
	C364.064392,899.967346 357.456177,899.997375 350.858246,900.177490 
	C347.645142,900.265137 346.235474,898.583374 347.273560,896.006409 
	C348.703094,892.457764 345.909973,887.270874 351.660034,885.142822 
	C352.004272,885.004333 352.022217,885.023193 352.022217,885.023193 
	C351.026215,888.965210 350.030151,892.907227 348.748322,897.980286 
	C358.383514,897.980286 367.226837,897.980286 376.472778,898.051025 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M378.875366,778.312866 
	C379.021149,777.513245 379.040283,777.033264 379.359070,776.538940 
	C379.668793,795.458435 379.678894,814.392456 379.375793,833.243286 
	C378.691589,832.939880 378.320648,832.719604 377.949707,832.499268 
	C378.216034,814.543762 378.482391,796.588196 378.875366,778.312866 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M363.372864,888.346313 
	C361.530396,887.731018 360.046082,887.186035 358.561768,886.641052 
	C365.990662,883.719910 373.259552,883.809021 380.743286,887.016113 
	C379.060028,887.351318 377.388763,887.876587 375.691132,887.983521 
	C371.711151,888.234314 367.718536,888.284912 363.372864,888.346313 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M303.443909,771.984314 
	C302.580902,772.472595 301.997131,772.754456 301.128052,773.202393 
	C300.080658,773.942627 299.318542,774.516602 298.284241,775.290039 
	C297.842377,774.800171 297.672668,774.110840 297.179138,772.911560 
	C296.276550,771.988647 295.697754,771.575623 294.845886,771.164062 
	C294.399536,771.458374 294.226288,771.751221 294.027130,772.028320 
	C294.001221,772.012573 294.052948,772.052734 293.722351,772.065186 
	C292.953613,772.029968 292.515533,771.982361 291.752594,771.678772 
	C286.224182,768.257385 288.873779,774.261719 287.255127,775.199951 
	C286.267426,773.416321 285.348877,771.757629 284.448853,769.821289 
	C285.089203,769.109619 285.711090,768.675537 286.714203,768.306641 
	C289.477539,768.323486 291.859619,768.275024 294.674744,768.143066 
	C297.470428,767.901550 299.833130,767.743530 302.195831,767.585510 
	C304.537018,768.315491 306.344330,769.239990 303.443909,771.984314 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M377.612335,832.612061 
	C378.320648,832.719604 378.691589,832.939880 379.398407,833.633240 
	C379.868805,835.401550 380.003387,836.696777 380.076721,838.373657 
	C379.938141,842.098328 379.860779,845.441345 379.783447,848.784302 
	C379.351837,848.874573 378.920197,848.964844 377.866577,849.127075 
	C376.826172,849.215271 376.407806,849.231384 375.989441,849.247498 
	C375.989441,849.247498 376.184082,849.089905 376.040955,848.525391 
	C375.912109,843.038635 375.926453,838.116455 375.940796,833.194214 
	C376.385529,833.037781 376.830231,832.881348 377.612335,832.612061 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M443.368103,878.680237 
	C447.766174,878.531738 452.170471,878.217590 456.557007,878.380432 
	C457.392059,878.411499 458.921173,880.428040 458.801178,881.356750 
	C458.519104,883.539307 457.433563,885.618164 456.331909,887.876892 
	C456.239105,885.925232 456.487610,883.835632 456.826019,880.989807 
	C452.255676,880.989807 447.988525,880.989807 443.294983,880.651428 
	C443.035065,879.768799 443.201599,879.224487 443.368103,878.680237 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M443.367920,878.342224 
	C443.201599,879.224487 443.035065,879.768799 442.830566,880.528198 
	C440.146851,880.943665 437.501068,881.143982 434.437805,881.051270 
	C433.993896,880.580627 433.967529,880.403137 434.317261,880.139221 
	C435.150238,878.207825 435.607056,876.362732 436.323883,874.415771 
	C436.755035,874.240784 436.926239,874.167664 437.454651,874.087769 
	C439.512756,874.743896 441.213623,875.406738 442.914490,876.069580 
	C443.065552,876.714478 443.216614,877.359375 443.367920,878.342224 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M306.544922,868.360474 
	C307.356171,867.777283 308.294891,867.524109 309.859955,867.148926 
	C310.659515,866.963562 310.832733,866.900208 311.005981,866.836792 
	C311.564240,867.526917 317.247162,868.376587 318.169098,868.224365 
	C319.094971,868.071472 320.100403,868.400024 321.154358,868.867798 
	C317.272400,872.273926 309.504364,871.992615 306.544922,868.360474 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M351.996338,884.996094 
	C354.384277,878.630188 364.612457,872.170898 369.957916,872.596741 
	C364.805756,876.309937 358.759766,880.667297 352.367981,885.023926 
	C352.022217,885.023193 352.004272,885.004333 351.996338,884.996094 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M433.941132,880.225586 
	C433.967529,880.403137 433.993896,880.580627 434.075073,881.022461 
	C430.999207,880.925598 427.868530,880.564453 424.143860,879.790649 
	C422.676178,877.894226 421.802460,876.410400 421.313324,874.827637 
	C423.001801,874.601562 424.305695,874.474548 425.992096,874.574951 
	C428.310455,877.428223 429.225342,881.478455 433.941132,880.225586 
M424.666229,876.993347 
	C424.930145,876.849976 425.194031,876.706604 425.457947,876.563232 
	C425.122040,876.380310 424.786133,876.197449 424.450195,876.014587 
	C424.345734,876.304688 424.241241,876.594788 424.666229,876.993347 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M303.055939,890.687134 
	C302.368164,886.909058 301.211090,882.649292 301.179565,878.381104 
	C301.137848,872.739685 301.977722,867.091736 302.721924,861.217163 
	C303.032654,870.725708 303.060089,880.463684 303.055939,890.687134 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M301.026367,839.397766 
	C301.293701,838.958862 301.527466,838.994995 302.112793,839.110107 
	C302.644196,845.816772 302.824005,852.444519 303.005310,859.542236 
	C302.337921,858.745605 301.143646,857.500183 301.091675,856.208801 
	C300.872955,850.770935 300.995239,845.319336 301.026367,839.397766 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M429.092712,886.855591 
	C430.700317,890.789490 427.844299,892.259338 425.478455,892.343079 
	C423.886658,892.399353 422.206116,889.945984 420.565277,888.615356 
	C420.849915,888.292114 421.134552,887.968872 421.419189,887.645630 
	C423.192810,888.447327 424.966431,889.249023 426.740051,890.050781 
	C426.925415,889.679199 427.110779,889.307678 427.296112,888.936096 
	C426.654053,888.227356 426.011963,887.518616 424.534760,885.888000 
	C426.769470,886.262329 427.799072,886.434814 429.092712,886.855591 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M376.107239,833.098633 
	C375.926453,838.116455 375.912109,843.038635 375.996033,848.408508 
	C375.887207,848.910828 375.680176,848.965454 375.164368,849.108643 
	C374.572449,844.168152 374.289368,839.138977 374.457489,833.634949 
	C375.329651,832.984314 375.750610,832.808655 376.171570,832.632935 
	C376.171539,832.632935 376.273712,833.002991 376.107239,833.098633 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M302.182495,767.221191 
	C299.833130,767.743530 297.470428,767.901550 294.566071,767.711975 
	C294.111450,766.489990 294.198456,765.615601 294.285461,764.741150 
	C296.577911,764.479065 298.870392,764.216980 301.558533,763.950439 
	C302.025848,764.916260 302.097473,765.886536 302.182495,767.221191 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M376.103455,832.200806 
	C375.750610,832.808655 375.329651,832.984314 374.564026,833.193604 
	C374.219360,828.882568 374.219360,824.537964 374.219360,820.193359 
	C374.824707,820.195801 375.430023,820.198303 376.035370,820.200745 
	C376.035370,824.056702 376.035370,827.912598 376.103455,832.200806 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M420.928772,874.926575 
	C421.802460,876.410400 422.676178,877.894226 423.793945,879.641235 
	C422.879791,880.549927 421.721588,881.195251 420.212860,881.806519 
	C418.920959,880.668518 417.979614,879.564636 417.203888,878.654907 
	C418.059204,877.716736 419.028656,876.653259 420.264160,875.222290 
	C420.530182,874.854675 420.928772,874.926575 420.928772,874.926575 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M455.667664,887.982178 
	C454.617462,889.656677 453.222778,891.324402 451.198120,893.006470 
	C450.387543,893.017517 450.207031,893.014343 450.026550,893.011108 
	C451.792114,891.332581 453.557678,889.653992 455.667664,887.982178 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M417.315430,892.049133 
	C417.239624,891.828735 417.240570,891.608582 417.347656,891.526062 
	C417.444611,891.451172 417.657227,891.526245 417.818848,891.535156 
	C417.692047,891.726624 417.565216,891.918030 417.315430,892.049133 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M324.014221,872.001038 
	C324.020538,871.998535 323.889862,872.042053 323.946045,872.028809 
	C324.002258,872.015564 324.007904,872.003540 324.014221,872.001038 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M449.964966,893.262573 
	C450.207031,893.014343 450.387543,893.017517 450.840271,893.020752 
	C450.907715,893.306274 450.703003,893.591919 450.240540,893.951538 
	C449.956360,893.855103 449.929871,893.684570 449.964966,893.262573 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M427.985840,909.977173 
	C428.096741,909.724487 428.271667,909.538452 428.446594,909.352417 
	C428.476227,909.563965 428.505859,909.775452 428.359406,910.079468 
	C428.183319,910.171875 428.049896,910.043823 427.985840,909.977173 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M442.995178,875.657593 
	C441.213623,875.406738 439.512756,874.743896 437.430725,873.659668 
	C435.992004,869.142822 437.998657,862.719666 429.947449,863.603333 
	C429.560120,861.805908 429.195953,860.324402 429.065125,858.496704 
	C429.690582,849.495056 430.082703,840.839722 430.533966,830.878601 
	C433.469757,832.228394 435.780121,832.902283 437.613495,834.233215 
	C439.845276,835.853149 441.334381,836.159424 443.595215,834.087341 
	C445.330780,832.496521 447.929352,831.847229 451.259430,830.241394 
	C451.259430,834.482056 451.259430,837.266846 451.276428,840.249756 
	C451.442017,840.706848 451.665314,840.825806 452.429626,840.854614 
	C457.629059,840.980591 462.362091,841.056702 467.088684,841.360962 
	C467.126190,841.887695 467.182831,842.183960 466.791870,842.626587 
	C463.339539,842.846313 460.338928,843.088440 457.357819,842.939697 
	C454.383575,842.791443 452.727264,843.334351 452.743408,846.914673 
	C452.758545,850.274414 453.977448,851.353394 457.163116,851.106201 
	C458.939819,850.968323 460.756042,851.338989 462.554443,851.480530 
	C462.556915,852.004089 462.559357,852.527710 462.561829,853.051331 
	C460.896240,853.316284 459.168640,853.388977 457.582428,853.900208 
	C455.984344,854.415405 454.526733,855.366272 453.007538,856.126221 
	C454.488220,856.737793 455.974213,857.891663 457.448730,857.877136 
	C472.335205,857.730408 487.218903,857.315979 502.105286,857.132507 
	C513.751892,856.988953 525.402039,857.125793 537.048096,856.958374 
	C538.332825,856.939941 539.600586,855.739136 540.876221,855.086670 
	C539.662354,854.364502 538.526062,853.396118 537.214844,852.983032 
	C535.600952,852.474609 533.841370,852.428894 532.144714,852.183289 
	C532.172546,851.748901 532.200378,851.314514 532.228210,850.880066 
	C533.098694,850.630554 534.016357,850.062988 534.831848,850.184021 
	C539.448669,850.869141 540.838745,848.703064 540.178162,844.496582 
	C540.049561,843.677856 540.049805,842.838806 540.236511,841.960876 
	C540.663452,841.340088 540.844604,840.767639 540.787842,839.837769 
	C536.512268,839.161011 532.474487,838.841614 528.508911,838.527954 
	C527.582764,831.749390 529.514099,828.307190 535.584595,825.212585 
	C537.279602,824.348572 539.835022,823.506653 540.257690,822.123474 
	C541.551270,817.890808 541.999756,813.399780 542.823486,808.692383 
	C542.564514,807.852356 542.258911,807.329285 541.966187,806.400635 
	C542.274841,803.898926 540.992615,800.846375 545.006836,801.470703 
	C545.018921,806.239746 544.852295,810.553650 545.159485,814.833618 
	C545.239807,815.952576 546.793518,816.965759 547.670593,818.027527 
	C548.417664,816.997864 549.806396,815.965515 549.802063,814.939087 
	C549.781738,810.168884 549.510620,805.386414 549.019226,800.640442 
	C548.884399,799.338074 547.677002,798.146851 546.963135,796.606812 
	C546.963257,795.912048 546.962036,795.515076 547.370361,795.013184 
	C548.205017,793.931946 548.974670,792.963562 548.997498,791.977844 
	C549.116211,786.845703 549.188904,781.701904 548.933044,776.579407 
	C548.872925,775.376282 547.479553,774.239807 546.699524,773.072632 
	C545.807434,774.298584 544.167969,775.515930 544.153748,776.751953 
	C544.095154,781.852173 544.529358,786.958069 544.500977,792.076904 
	C543.547485,792.400024 542.875549,792.708191 542.179565,792.547546 
	C542.262573,778.972229 542.369629,765.865662 542.742554,752.812134 
	C543.370361,752.915588 543.732361,752.966064 544.365234,753.324402 
	C544.750732,758.257874 544.745239,762.891785 545.065552,767.503113 
	C545.144714,768.641968 546.282654,769.707336 546.933899,770.806458 
	C547.618591,769.754089 548.898987,768.696655 548.890259,767.650085 
	C548.837891,761.385559 548.488647,755.123535 548.578308,748.881958 
	C549.594727,748.919739 550.271851,748.936218 550.980225,749.424805 
	C551.011475,756.683655 551.011475,763.470459 551.011475,770.202759 
	C554.700500,771.500916 556.127258,770.680847 556.015686,767.088013 
	C555.829834,761.099854 555.929932,755.102844 556.232300,749.073853 
	C556.983093,748.981506 557.411133,748.924866 557.882751,749.338745 
	C557.926270,774.847229 557.926270,799.885254 557.926270,826.551575 
	C556.069702,820.341736 553.200012,822.608093 550.246765,823.495850 
	C545.531921,824.913147 540.481689,825.378723 535.952026,827.192566 
	C532.700439,828.494629 530.100586,831.360718 530.357605,836.091797 
	C533.604370,836.091797 536.574341,836.075134 539.544128,836.095825 
	C543.801514,836.125488 545.919739,838.279724 545.906006,842.545898 
	C545.895264,845.874634 546.181702,849.229492 545.861389,852.526672 
	C545.242615,858.895264 549.892212,856.479797 552.844177,856.983582 
	C556.767639,857.653198 557.128113,855.423157 557.072144,852.297241 
	C556.926758,844.188538 557.023315,836.075562 557.023315,827.964294 
	C557.445374,827.959534 557.867493,827.954773 558.289551,827.950073 
	C558.289551,837.454163 558.289551,846.958191 558.289551,856.462280 
	C558.865723,856.445740 559.441833,856.429199 560.018005,856.412659 
	C560.018005,820.602173 560.018005,784.791748 560.343994,748.967896 
	C560.887146,748.966919 561.104309,748.979370 561.522583,749.349121 
	C562.124817,781.464966 562.525940,813.223511 563.019775,845.241089 
	C563.414368,845.690308 563.716248,845.880615 564.485107,846.069458 
	C570.151489,846.068054 575.350891,846.068054 579.496094,846.068054 
	C580.994080,843.068359 581.972351,841.109314 582.900940,839.462280 
	C582.759583,841.664917 582.667847,843.555542 582.638550,844.160339 
	C586.281433,846.195190 590.999268,847.289978 591.493103,849.381775 
	C593.312988,857.091431 585.166382,852.776245 582.021423,855.409058 
	C585.166748,856.792480 587.694824,857.904480 589.734741,859.057861 
	C564.671326,859.375671 540.096680,859.742249 515.520630,859.902466 
	C492.078461,860.055298 468.634888,859.988770 445.191956,860.035522 
	C443.739655,860.038391 442.287628,860.189026 440.835480,860.270996 
	C440.855835,860.748840 440.876190,861.226746 440.896515,861.704651 
	C442.623657,861.807007 444.350189,861.985718 446.078033,861.999268 
	C456.407562,862.079956 466.737396,862.122681 477.056335,862.433228 
	C477.058716,863.026917 477.071899,863.365479 476.620422,863.801453 
	C466.999481,863.939453 457.843231,863.986023 448.686920,864.019043 
	C441.293335,864.045654 440.903717,864.657959 444.013550,871.987488 
	C446.523651,871.987488 449.171906,871.981201 451.820068,871.988464 
	C469.913330,872.038391 488.006622,872.091064 506.095490,872.363770 
	C506.091095,872.584778 505.985107,873.013916 505.985107,873.013916 
	C494.877075,873.025085 483.767609,872.931580 472.661438,873.080627 
	C463.758179,873.200134 454.857239,873.575073 445.960144,873.952026 
	C444.982178,873.993469 444.036499,874.796021 442.995178,875.657593 
M449.073273,857.714294 
	C449.363556,853.360535 449.748871,849.008301 449.822937,844.650879 
	C449.832123,844.110657 448.201599,843.090820 447.306244,843.062073 
	C442.394043,842.904724 437.474213,842.986938 432.391785,842.986938 
	C432.391785,848.336670 432.391785,852.994141 432.391785,857.927979 
	C437.938416,857.927979 443.051636,857.927979 449.073273,857.714294 
M556.014587,785.447266 
	C556.008850,782.458740 556.231506,779.443604 555.888245,776.494385 
	C555.745178,775.265137 554.396790,774.176086 553.596497,773.023315 
	C552.747681,774.288330 551.202759,775.536255 551.171265,776.821411 
	C551.039062,782.213867 551.273254,787.622559 551.593811,793.012268 
	C551.652222,793.993958 552.740417,794.914490 553.353210,795.863281 
	C554.216248,795.037598 555.660767,794.313416 555.826111,793.365967 
	C556.220581,791.105835 556.011414,788.740295 556.014587,785.447266 
M551.929138,800.777588 
	C551.949402,805.588989 551.863159,810.405212 552.081970,815.207581 
	C552.124573,816.142151 553.353943,817.022522 554.035950,817.927917 
	C554.709290,817.009277 555.931763,816.109802 555.964600,815.168823 
	C556.144104,810.032166 556.106934,804.882812 555.947144,799.744019 
	C555.918945,798.837097 554.957764,797.959167 554.427490,797.067871 
	C553.597717,797.995483 552.768005,798.923157 551.929138,800.777588 
M447.849274,833.999939 
	C445.508148,835.865601 443.167053,837.731323 440.825928,839.596985 
	C441.129608,839.955566 441.433289,840.314148 441.736969,840.672729 
	C444.290863,840.672729 446.844727,840.672729 450.341492,840.672729 
	C449.683624,838.125427 449.159515,836.096008 447.849274,833.999939 
M569.624939,856.978333 
	C573.277588,856.978333 576.930237,856.978333 580.582886,856.978333 
	C577.167419,853.673279 564.689819,853.703064 562.677124,856.598450 
	C564.967834,856.741455 566.831055,856.857727 569.624939,856.978333 
M438.544434,840.471863 
	C438.722778,836.138489 436.267181,835.059631 432.499756,835.120789 
	C432.499756,837.308167 432.499756,839.006226 432.499756,840.847351 
	C434.558136,840.847351 436.192780,840.847351 438.544434,840.471863 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M477.951172,893.971436 
	C475.274231,897.446533 472.997803,901.359192 469.810547,904.276855 
	C466.453125,907.350159 462.405853,905.962097 458.415955,903.869568 
	C458.033325,903.913696 457.992523,903.978882 458.023376,903.956543 
	C457.658691,900.168823 456.113892,896.059021 457.116730,892.712708 
	C458.968353,886.534058 465.651367,885.963318 471.538391,891.622498 
	C473.508728,893.516541 475.067413,893.803955 477.640106,893.860535 
	C478.019348,893.984558 477.951172,893.971436 477.951172,893.971436 
M465.972076,894.268860 
	C466.092712,891.584961 466.092712,891.584961 460.662628,892.731567 
	C460.754486,894.719238 460.848633,896.756775 460.994019,899.902832 
	C463.108582,897.513184 464.292633,896.175049 465.972076,894.268860 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M505.919189,873.355957 
	C505.985107,873.013916 506.091095,872.584778 506.550415,872.305420 
	C512.004333,872.028198 516.998962,872.030396 521.996460,872.309448 
	C522.046692,872.765015 522.093872,872.943542 522.140991,873.122009 
	C522.172058,873.482117 522.203064,873.842285 522.042358,874.670471 
	C521.709961,875.269592 521.569336,875.400696 521.127686,875.285156 
	C510.143707,871.925964 506.114044,874.034729 502.914032,884.284546 
	C510.327118,884.284546 517.773010,884.284546 525.644714,884.284546 
	C524.717957,881.927856 524.056213,880.245056 523.592529,878.378601 
	C523.923035,878.072754 524.055359,877.950562 524.187683,877.828430 
	C525.713501,880.507751 528.930359,883.590881 528.407410,885.781006 
	C527.370178,890.125000 524.745972,894.358337 521.886230,897.921997 
	C519.133789,901.352051 506.589020,898.663818 504.380829,894.676697 
	C499.420410,885.720276 498.481750,885.290039 488.197601,886.603455 
	C489.902252,886.082581 491.670166,884.975464 493.299683,885.151245 
	C498.117126,885.670776 501.164459,884.035339 502.767059,879.367188 
	C503.455902,877.360718 504.804443,875.580750 505.919189,873.355957 
M508.715759,886.687744 
	C506.945740,886.687744 505.175690,886.687744 503.276001,886.687744 
	C505.273743,895.705688 507.380402,897.264160 516.658752,897.100098 
	C521.438354,897.015564 523.553772,894.525146 525.004028,886.687622 
	C519.814392,886.687622 514.737000,886.687622 508.715759,886.687744 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M616.999817,867.756958 
	C616.967468,869.543091 616.935120,871.329163 616.953552,873.559509 
	C615.643921,873.501892 614.283508,873.000061 612.189941,872.267700 
	C610.609924,872.059692 609.762939,872.082275 608.916016,872.104858 
	C606.033569,873.119080 603.199341,874.326599 600.247742,875.063843 
	C598.825439,875.419128 597.183899,874.845642 595.637817,874.773743 
	C594.540649,874.722595 593.428650,874.729370 592.338745,874.850281 
	C590.922241,875.007324 589.150574,875.951233 588.191406,875.402893 
	C586.257141,874.296997 585.005005,874.601013 583.009766,875.298828 
	C581.662537,875.770081 579.528259,873.991028 577.437256,872.681885 
	C577.046265,871.734619 576.963928,871.323975 577.088318,870.608887 
	C580.209717,867.275574 583.154968,866.229187 585.150391,869.177856 
	C590.859131,868.842712 595.570923,868.566162 600.644531,868.268311 
	C600.191711,869.253784 599.800781,870.104675 599.409851,870.955566 
	C599.574280,871.634216 599.738770,872.312866 599.903259,872.991577 
	C603.884216,866.883789 608.262878,865.228394 612.969910,870.041992 
	C614.475708,869.188171 615.737732,868.472595 616.999817,867.756958 
M606.204468,869.934387 
	C606.943726,869.934387 607.682983,869.934387 608.422302,869.934387 
	C608.408325,869.697388 608.394287,869.460327 608.380310,869.223328 
	C607.517212,869.328796 606.654053,869.434265 606.204468,869.934387 
M599.637756,872.425354 
	C599.088196,872.234863 598.547546,871.998169 597.982239,871.889709 
	C597.857117,871.865723 597.645081,872.294739 597.473083,872.515198 
	C598.033630,872.650940 598.594116,872.786682 599.637756,872.425354 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M571.007812,917.706421 
	C570.282227,918.623108 569.615784,919.538025 568.881165,919.596497 
	C563.809937,920.000244 558.726501,920.363708 553.642639,920.435974 
	C551.744751,920.462952 549.845947,919.614807 547.927490,919.281433 
	C546.543518,919.040894 545.126343,918.991699 543.591736,918.844543 
	C542.360718,912.287720 542.351929,912.461792 549.176453,912.807007 
	C553.918762,913.046936 558.691895,912.617371 563.446106,912.722229 
	C564.857971,912.753357 566.252258,913.582153 568.319214,914.265137 
	C568.298889,914.292603 569.005676,913.336548 570.627869,911.142334 
	C570.818970,914.033386 570.927185,915.670898 571.007812,917.706421 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M530.017944,917.983704 
	C528.740845,920.153076 527.330139,921.938965 524.569824,919.901978 
	C524.305420,919.706848 523.899902,919.313782 523.765442,919.387451 
	C518.054626,922.518433 516.527466,919.057495 515.320374,914.229797 
	C514.895081,915.782288 514.469849,917.334778 513.700317,920.144165 
	C513.079834,917.454895 512.715515,915.875854 512.351196,914.296875 
	C510.942322,920.847351 510.842987,920.958679 506.971191,919.420654 
	C506.967987,919.023865 507.088348,918.896362 507.088379,918.896362 
	C507.244720,918.288940 507.706329,917.534912 507.498627,917.102234 
	C506.935181,915.928772 506.087952,914.891541 505.353302,913.800293 
	C506.601593,913.205505 507.851868,912.101501 509.097809,912.106384 
	C513.837646,912.124939 518.576111,912.501709 523.243042,913.113647 
	C527.629822,913.113647 532.016663,913.113647 536.145203,913.113647 
	C535.966736,914.699768 535.726685,916.833679 535.425354,919.512756 
	C534.039978,919.179993 532.269653,918.754761 530.240417,917.794250 
	C529.084900,916.122009 528.188293,914.985046 526.847717,913.284973 
	C525.044067,918.389282 527.721069,917.991272 530.017944,917.983704 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M576.881592,870.913391 
	C576.963928,871.323975 577.046265,871.734619 577.167419,872.456604 
	C559.154724,872.872742 541.103210,872.977478 522.596313,873.102112 
	C522.093872,872.943542 522.046692,872.765015 522.289307,872.012329 
	C540.679993,871.263245 558.780823,871.088318 576.881592,870.913391 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M478.025848,894.018677 
	C480.697235,891.926941 483.362122,889.801147 486.026978,887.675293 
	C486.364563,888.149048 486.702148,888.622803 487.039734,889.096619 
	C484.252899,890.725952 481.466064,892.355286 478.315186,893.978027 
	C477.951172,893.971436 478.019348,893.984558 478.025848,894.018677 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M608.898926,872.455627 
	C609.762939,872.082275 610.609924,872.059692 611.833618,872.237061 
	C611.379211,873.216492 610.548035,873.996033 609.716919,874.775513 
	C609.438538,874.119202 609.160217,873.462830 608.898926,872.455627 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M608.242310,887.656616 
	C608.281494,886.744629 608.387451,886.154724 608.493408,885.564819 
	C608.864563,885.813232 609.235779,886.061646 609.606934,886.310059 
	C609.174316,886.866211 608.741699,887.422424 608.242310,887.656616 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M522.061035,874.941528 
	C522.868591,875.570312 523.465576,876.396179 524.125122,877.525208 
	C524.055359,877.950562 523.923035,878.072754 523.364868,878.172729 
	C522.435669,877.277588 521.932190,876.404724 521.428711,875.531799 
	C521.569336,875.400696 521.709961,875.269592 522.061035,874.941528 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M506.903748,919.083496 
	C506.737854,918.929382 506.636200,918.715515 506.534576,918.501709 
	C506.656250,918.536926 506.777893,918.572205 506.993958,918.751953 
	C507.088348,918.896362 506.967987,919.023865 506.903748,919.083496 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1037.033325,502.167603 
	C1037.033325,502.167603 1037.008911,502.473145 1036.693481,502.911743 
	C1036.223999,505.156708 1035.888672,506.968842 1035.946045,508.768524 
	C1036.276611,519.136108 1036.727539,529.499939 1037.067871,539.867310 
	C1037.133179,541.851074 1036.822021,543.844299 1036.798462,545.834534 
	C1036.750122,549.918274 1036.447021,554.036316 1036.854004,558.079346 
	C1037.463867,564.135742 1037.327026,569.652649 1031.874146,573.797913 
	C1029.511597,575.593872 1027.321899,577.772827 1025.568115,580.156433 
	C1024.483398,581.630554 1023.559326,584.004944 1023.942871,585.654480 
	C1027.186646,599.606873 1025.273193,612.766479 1018.656433,625.771912 
	C1008.974304,621.789490 999.481567,617.885010 989.988831,613.980469 
	C989.988831,613.980469 989.999573,614.001282 989.861938,613.700378 
	C986.274353,610.942505 982.824463,608.485535 978.982788,605.749573 
	C982.353210,602.104919 985.460938,604.709290 988.363831,605.824280 
	C991.429138,607.001648 994.333313,608.598389 997.307861,610.012024 
	C997.588684,609.426086 997.869507,608.840149 998.150269,608.254272 
	C993.406189,605.895813 988.662170,603.537415 983.945679,600.861511 
	C983.979431,600.331726 983.985718,600.119385 984.407227,599.888489 
	C987.725342,599.435364 990.628174,599.000732 993.531067,598.566101 
	C989.185730,597.391174 984.816772,596.624390 980.862183,597.577332 
	C975.410217,598.891052 967.950500,594.384949 967.924194,588.782959 
	C967.906616,585.051880 967.920776,581.320740 967.920776,577.589600 
	C967.254272,577.619385 966.587769,577.649231 965.921265,577.679077 
	C965.921265,582.117798 965.921265,586.556580 965.574768,590.999512 
	C964.764771,591.028503 964.301208,591.053345 963.483948,591.033447 
	C961.305359,590.466248 959.480530,589.943909 956.433044,589.071594 
	C959.870239,593.866028 968.060059,593.289429 965.380371,601.007751 
	C963.449341,600.641663 961.720642,600.313965 959.995605,599.995667 
	C959.999207,600.005066 960.019287,600.007446 959.923645,599.722290 
	C959.215759,598.951294 958.603516,598.465515 957.994141,597.991394 
	C957.997070,598.002991 958.020752,598.007141 957.890808,597.690430 
	C952.302795,593.661804 946.813660,589.994324 941.402222,586.215637 
	C939.259460,584.719421 935.610168,583.029297 935.556091,581.334534 
	C935.394714,576.275635 932.212036,576.167114 928.825012,575.383301 
	C927.120422,574.988831 925.585632,573.860779 923.981079,572.697144 
	C923.973511,571.839905 923.959045,571.349915 924.237549,570.625427 
	C926.498779,569.620850 928.467102,568.850830 930.614319,568.099365 
	C930.793213,568.117981 931.151978,568.091125 931.457397,568.258667 
	C933.636047,570.306824 932.672546,575.254639 937.933594,573.761963 
	C939.845947,573.135681 941.428650,572.575623 943.410217,571.915771 
	C945.038879,572.111145 946.379272,572.859375 947.472046,572.592712 
	C949.028198,572.212952 950.671143,571.337036 951.744385,570.179382 
	C952.302246,569.577637 951.955688,566.986450 951.605896,566.896484 
	C950.210022,566.537903 948.639587,566.701111 947.158569,566.878235 
	C945.825439,567.037598 944.524719,567.467773 943.100952,567.502930 
	C941.471191,566.869934 939.950073,566.512573 938.031372,566.090576 
	C936.346375,565.651978 935.059021,565.278015 933.805298,564.538208 
	C936.474854,561.427917 938.861328,558.369995 941.812073,556.020935 
	C945.228333,553.301270 950.697205,552.027405 952.481567,548.650085 
	C955.317444,543.282532 959.487610,542.284424 964.579102,540.941040 
	C965.007324,544.159668 965.388550,547.025391 965.769836,549.891052 
	C967.243347,549.218079 967.928223,548.475037 967.818481,547.878113 
	C966.432800,540.339111 969.945679,536.899902 977.018188,534.936707 
	C981.312744,533.744629 986.506042,532.077637 987.015503,525.982483 
	C987.015503,525.982483 986.998901,525.998657 987.338867,526.005615 
	C989.203064,524.364990 991.002136,522.892395 992.187988,521.029358 
	C994.017578,518.155090 995.427246,515.013489 997.015076,511.985321 
	C997.015076,511.985321 996.998901,511.998749 997.350708,512.036499 
	C998.273682,511.756195 999.076416,511.583252 999.380554,511.097870 
	C1002.906067,505.473053 1008.396362,502.232025 1013.506775,498.295898 
	C1018.220398,494.665283 1021.246094,488.843201 1025.015259,483.986389 
	C1025.015259,483.986359 1024.998901,483.998840 1025.377930,484.060913 
	C1026.845947,482.592834 1027.934814,481.062683 1029.023804,479.532593 
	C1029.023804,479.532593 1029.347168,479.174072 1029.843628,479.149475 
	C1030.898560,478.413025 1031.457153,477.701172 1032.015747,476.989319 
	C1032.015747,476.989349 1031.998901,476.998840 1032.368896,477.027344 
	C1034.160400,476.709900 1035.582031,476.363922 1037.114136,476.236023 
	C1037.428589,476.724609 1037.692627,476.908569 1038.014648,477.428589 
	C1037.116577,482.707153 1035.880615,487.532806 1035.445190,492.429657 
	C1035.157959,495.661377 1032.859741,499.549683 1037.033325,502.167603 
M998.836609,599.563599 
	C998.547485,600.046692 998.258362,600.529724 997.532166,601.326721 
	C997.542664,601.727234 997.553162,602.127747 997.563660,602.528259 
	C998.045410,602.345337 998.527161,602.162415 999.785461,602.042053 
	C1004.095825,601.964844 1008.435486,602.139832 1012.696960,601.643433 
	C1013.844727,601.509766 1014.960999,599.441040 1015.667297,598.045837 
	C1015.929504,597.527832 1015.103577,595.782288 1014.549255,595.665771 
	C1013.319092,595.407288 1011.901428,595.665649 1010.648315,596.022522 
	C1009.805664,596.262451 1009.113892,597.032043 1008.354553,597.564758 
	C1008.310547,597.082947 1008.266479,596.601135 1008.222412,596.119324 
	C1005.977234,595.821960 1003.732056,595.524597 1000.774231,594.930237 
	C999.710510,594.669006 998.646790,594.407837 996.545227,593.891724 
	C997.728943,596.317322 998.389221,597.670288 998.836609,599.563599 
M1015.744507,615.042114 
	C1014.900818,616.035095 1014.057129,617.028015 1013.213440,618.020996 
	C1014.243591,618.657654 1015.249390,619.729187 1016.311890,619.788635 
	C1017.112122,619.833435 1018.787109,618.348938 1018.671875,617.951050 
	C1018.355835,616.860168 1017.300781,615.983398 1015.744507,615.042114 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M989.971985,614.338501 
	C999.481567,617.885010 1008.974304,621.789490 1018.656433,625.771912 
	C1025.273193,612.766479 1027.186646,599.606873 1023.942871,585.654480 
	C1023.559326,584.004944 1024.483398,581.630554 1025.568115,580.156433 
	C1027.321899,577.772827 1029.511597,575.593872 1031.874146,573.797913 
	C1037.327026,569.652649 1037.463867,564.135742 1036.854004,558.079346 
	C1036.447021,554.036316 1036.750122,549.918274 1036.798462,545.834534 
	C1036.822021,543.844299 1037.133179,541.851074 1037.067871,539.867310 
	C1036.727539,529.499939 1036.276611,519.136108 1035.946045,508.768524 
	C1035.888672,506.968842 1036.223999,505.156708 1036.704834,503.064575 
	C1037.330078,505.073517 1037.814575,507.361755 1037.895142,509.664154 
	C1038.292969,521.028503 1039.021118,532.407776 1038.731323,543.758728 
	C1038.581909,549.613708 1037.324585,555.276611 1038.784790,561.282715 
	C1039.941284,566.039673 1038.388306,570.776489 1034.252075,574.237122 
	C1032.217285,575.939575 1030.569458,578.102783 1028.545532,579.820862 
	C1025.898560,582.067871 1025.678955,584.644165 1026.290161,587.882507 
	C1027.608643,594.867859 1028.641846,601.831909 1026.539673,608.996521 
	C1024.523926,615.866150 1025.420044,616.951721 1032.034912,619.969116 
	C1049.540405,627.954224 1067.023071,635.989258 1084.496094,644.045105 
	C1085.400513,644.461975 1086.131104,645.255981 1086.942749,645.874390 
	C1086.735596,646.250000 1086.528564,646.625610 1086.321533,647.001221 
	C1082.360474,645.209778 1078.515991,643.064331 1074.410156,641.713074 
	C1068.783081,639.861145 1062.711914,639.210327 1057.271118,636.974182 
	C1047.349854,632.896790 1037.761597,628.011963 1028.009155,623.518860 
	C1026.529419,622.837097 1024.913818,622.450134 1023.049683,621.821838 
	C1022.488159,622.954163 1021.920349,623.905762 1021.528748,624.925049 
	C1020.375305,627.926941 1018.963867,628.442688 1015.859070,626.764282 
	C1012.449646,624.921082 1008.468750,624.112854 1004.707886,622.947266 
	C999.197510,621.239380 993.405457,620.073120 989.971985,614.338501 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M986.706421,526.119812 
	C986.506042,532.077637 981.312744,533.744629 977.018188,534.936707 
	C969.945679,536.899902 966.432800,540.339111 967.818481,547.878113 
	C967.928223,548.475037 967.243347,549.218079 965.769836,549.891052 
	C965.388550,547.025391 965.007324,544.159668 964.579102,540.941040 
	C959.487610,542.284424 955.317444,543.282532 952.481567,548.650085 
	C950.697205,552.027405 945.228333,553.301270 941.812073,556.020935 
	C938.861328,558.369995 936.474854,561.427917 933.505676,564.602966 
	C932.498962,566.052673 931.825439,567.071899 931.151978,568.091125 
	C931.151978,568.091125 930.793213,568.117981 930.200623,568.045166 
	C929.336609,567.921631 929.065247,567.870911 928.793823,567.820251 
	C932.033997,560.730835 936.375427,554.758484 944.234192,552.197754 
	C945.467407,551.795959 946.927795,551.155212 947.613220,550.162415 
	C951.679016,544.273132 957.434143,540.789368 963.817688,538.029236 
	C964.480530,537.742676 965.047363,536.437744 965.067200,535.591492 
	C965.183594,530.621338 965.124390,525.646973 965.124390,520.616211 
	C964.261475,520.484558 963.619324,520.184814 963.379822,520.379700 
	C958.388489,524.442383 953.477844,528.604492 948.473877,532.651306 
	C945.765869,534.841248 943.142395,537.423645 940.018555,538.675720 
	C938.696167,539.205750 935.091980,537.198547 934.462463,535.587463 
	C933.429199,532.943176 935.198303,530.845215 938.540710,531.210449 
	C940.800964,531.457520 943.055786,531.755066 946.284790,532.148438 
	C945.445496,530.141296 944.597168,528.112549 943.354980,525.141785 
	C945.441101,524.934265 947.531311,524.726379 949.688782,524.511780 
	C948.962524,521.085266 951.554504,519.029785 954.544495,517.238953 
	C957.180115,515.660461 960.265259,514.538269 962.350037,512.423950 
	C964.131409,510.617401 964.774536,507.688416 966.001587,505.085266 
	C969.745911,507.159851 968.370239,511.501312 968.475830,514.756592 
	C968.697693,521.595093 967.884277,528.467102 967.446838,536.191223 
	C974.944458,533.505676 981.744324,532.013855 986.706421,526.119812 
M964.484924,517.684448 
	C964.484924,517.684448 964.609070,517.435364 964.609070,517.435364 
	C964.609070,517.435364 964.343689,517.483948 964.484924,517.684448 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1054.171509,560.816345 
	C1054.487915,557.333252 1054.511230,553.790833 1055.200439,550.383179 
	C1055.934814,546.752380 1057.178345,543.204468 1058.466431,539.718933 
	C1058.822021,538.756897 1060.129395,538.146606 1060.250488,538.039246 
	C1060.484619,528.363647 1062.743774,519.719177 1067.245361,510.992859 
	C1068.685791,511.348236 1070.650269,511.832916 1072.320923,512.245117 
	C1071.782227,514.590637 1071.015625,516.453979 1070.986206,518.328735 
	C1070.891479,524.387817 1069.352783,526.511475 1063.060181,527.695251 
	C1064.916626,528.562988 1066.241455,529.182129 1068.688599,530.325867 
	C1065.951294,530.723206 1064.428833,530.944153 1062.249634,531.260498 
	C1064.020752,532.287292 1065.329102,533.045715 1066.589844,533.776550 
	C1065.425781,538.845215 1063.983398,543.810547 1063.273438,548.878601 
	C1062.997192,550.850037 1064.646484,553.017944 1064.921631,555.160278 
	C1065.207520,557.384521 1065.522217,560.000916 1061.921387,560.228882 
	C1061.309326,560.267639 1060.447754,561.175720 1060.230469,561.852173 
	C1058.934937,565.883118 1057.794678,569.963867 1056.485352,574.426392 
	C1053.724976,573.716064 1051.555786,573.157837 1048.852295,572.462158 
	C1050.401855,568.404053 1051.769531,564.822693 1053.500732,561.519409 
	C1054.955200,563.100586 1056.046021,564.403809 1057.445068,566.075195 
	C1060.146118,560.865356 1055.677246,562.021545 1054.171509,560.816345 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M965.921265,590.995361 
	C965.921265,586.556580 965.921265,582.117798 965.921265,577.679077 
	C966.587769,577.649231 967.254272,577.619385 967.920776,577.589600 
	C967.920776,581.320740 967.906616,585.051880 967.924194,588.782959 
	C967.950500,594.384949 975.410217,598.891052 980.862183,597.577332 
	C984.816772,596.624390 989.185730,597.391174 993.531067,598.566101 
	C990.628174,599.000732 987.725342,599.435364 984.136719,599.895996 
	C983.200378,600.049988 982.949768,600.177979 982.699219,600.306030 
	C983.105469,600.597046 983.511780,600.888000 983.918091,601.178955 
	C988.662170,603.537415 993.406189,605.895813 998.150269,608.254272 
	C997.869507,608.840149 997.588684,609.426086 997.307861,610.012024 
	C994.333313,608.598389 991.429138,607.001648 988.363831,605.824280 
	C985.460938,604.709290 982.353210,602.104919 978.982788,605.749573 
	C982.824463,608.485535 986.274353,610.942505 989.873169,613.704102 
	C986.391418,612.402039 982.760742,610.795227 978.944031,609.106079 
	C978.168823,610.342590 977.356812,611.637756 976.169312,612.981628 
	C975.054077,613.007996 974.314453,612.985596 973.220581,612.481812 
	C973.594543,610.679199 974.322693,609.357971 975.286865,607.608276 
	C972.892151,606.706848 970.838562,605.933838 968.387024,605.010986 
	C968.125000,607.105591 967.927612,608.683167 967.730225,610.260803 
	C967.080444,610.113953 966.284546,610.153503 965.810486,609.782227 
	C964.749695,608.951416 963.849243,607.915955 962.883423,606.963928 
	C963.966919,606.444275 965.050415,605.924683 967.014038,604.982910 
	C965.192444,604.491577 963.950439,604.156616 962.288086,603.544556 
	C961.242432,602.173767 960.617188,601.080017 959.991943,599.986267 
	C961.720642,600.313965 963.449341,600.641663 965.380371,601.007751 
	C968.060059,593.289429 959.870239,593.866028 956.433044,589.071594 
	C959.480530,589.943909 961.305359,590.466248 963.664856,591.257629 
	C964.773438,591.349548 965.347351,591.172485 965.921265,590.995361 
M971.330566,597.996826 
	C970.422058,597.721741 969.513550,597.446655 968.565918,597.159729 
	C966.682434,602.049194 969.142334,603.410278 973.173950,604.425537 
	C976.250732,605.200378 977.485046,604.614929 977.759644,601.353149 
	C975.799011,600.332947 973.901978,599.345825 971.330566,597.996826 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1046.422485,542.268677 
	C1047.874756,539.302368 1049.137939,536.652832 1050.420776,534.012756 
	C1050.646240,533.548950 1051.236206,532.775452 1051.183472,532.732849 
	C1046.637085,529.062988 1052.573486,525.507629 1051.281494,521.909302 
	C1053.500122,521.332947 1055.718628,520.756592 1059.467041,519.782837 
	C1057.469971,527.945679 1055.786987,534.300537 1054.395264,540.718506 
	C1053.614258,544.320618 1053.436523,548.051453 1052.712158,551.669373 
	C1052.535156,552.553894 1051.219360,553.918213 1050.529541,553.859436 
	C1048.471680,553.683960 1046.405273,553.050903 1044.494873,552.237793 
	C1044.167725,552.098572 1044.193359,549.548157 1044.734741,549.215210 
	C1047.647583,547.423950 1046.408081,544.928345 1046.422485,542.268677 
M1053.369751,526.456665 
	C1053.369751,526.456665 1053.508423,526.381226 1053.369751,526.456665 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M875.970581,550.965515 
	C876.010132,550.503723 876.049744,550.041931 876.127502,549.233215 
	C889.234314,555.077332 902.302979,561.268372 915.658081,568.114380 
	C915.823853,569.648254 915.703186,570.527222 915.239380,571.300964 
	C914.096863,570.987549 913.176819,570.966858 912.516663,570.542236 
	C905.315491,565.910583 896.488281,564.529236 890.085327,557.899353 
	C886.659851,554.352478 880.755981,553.199097 875.970581,550.965515 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M985.085327,493.926727 
	C985.851379,493.603638 986.793762,492.830841 987.347961,493.048096 
	C988.621948,493.547607 989.706970,494.529083 991.859619,494.622894 
	C991.256409,492.781738 990.653259,490.940613 989.785583,488.292206 
	C992.477966,490.105988 994.502808,491.470123 997.412292,493.430176 
	C994.278137,495.515228 991.246033,497.438660 988.316711,499.507629 
	C985.433777,501.543854 982.655090,503.727692 979.332703,506.220367 
	C978.233459,504.813049 977.119202,503.386536 975.189453,500.915955 
	C977.182678,499.964478 979.169434,499.016113 981.616211,498.228607 
	C982.192383,498.433075 982.308594,498.476624 982.424805,498.520203 
	C982.310059,498.323486 982.195312,498.126801 982.080566,497.930115 
	C982.779968,496.665436 983.479370,495.400757 984.624512,494.271637 
	C985.192505,494.442383 985.314636,494.477600 985.436890,494.512817 
	C985.319702,494.317444 985.202515,494.122070 985.085327,493.926727 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1013.058960,471.050262 
	C1013.735168,473.477570 1014.411438,475.904877 1015.207764,478.763214 
	C1011.613953,480.945068 1007.298706,483.564972 1002.455811,486.505219 
	C1001.935669,484.873901 1001.335449,482.991455 1000.505737,480.389160 
	C1002.524536,480.675659 1003.862122,480.865479 1005.577087,481.294373 
	C1006.104553,481.802979 1006.254639,482.072479 1006.404724,482.341949 
	C1006.281311,481.885956 1006.157959,481.429993 1006.034607,480.973999 
	C1004.452698,479.065491 1003.375244,477.238586 1006.214905,475.399628 
	C1008.302368,474.047821 1010.260498,472.496246 1012.642578,471.019653 
	C1013.008789,471.005615 1013.058960,471.050262 1013.058960,471.050262 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M958.772400,490.077271 
	C959.908386,491.168091 960.698242,492.138885 961.707397,493.379272 
	C962.746826,492.459961 963.363770,491.551819 964.195007,491.265076 
	C965.405640,490.847382 966.869385,490.527374 968.030151,490.855591 
	C968.660156,491.033752 969.494751,493.028961 969.176636,493.526489 
	C968.340881,494.833405 966.065796,496.848816 965.866638,496.690552 
	C962.654297,494.139008 959.911194,497.193146 956.995789,497.175934 
	C955.542786,497.167358 954.091553,496.866425 952.405334,496.670563 
	C952.405334,494.528015 952.405334,492.519012 952.405334,489.286407 
	C953.639221,491.106964 954.203308,491.939270 954.769287,492.772125 
	C955.989563,491.834167 957.207886,490.895691 958.772400,490.077271 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1011.255737,449.220642 
	C1013.105103,448.855865 1014.633972,448.239075 1016.162842,447.622253 
	C1016.081055,447.268066 1015.999207,446.913879 1015.917358,446.559692 
	C1014.667908,446.374329 1013.418457,446.188995 1012.168945,446.003632 
	C1012.239807,445.442230 1012.310608,444.880859 1012.381470,444.319458 
	C1014.637573,444.576385 1016.893677,444.833313 1019.149841,445.090240 
	C1019.114746,445.170166 1019.079712,445.250122 1019.044617,445.330048 
	C1021.183716,445.330048 1023.322815,445.330048 1025.461914,445.330048 
	C1025.040527,446.918182 1024.693237,449.830811 1024.184570,449.859253 
	C1022.247070,449.967560 1020.257568,449.145416 1018.119446,448.630432 
	C1016.577332,452.554504 1015.440552,452.919952 1009.171265,450.461121 
	C1009.171265,448.717773 1009.171265,446.924622 1009.171265,443.921906 
	C1010.020447,446.351349 1010.477844,447.660004 1011.255737,449.220642 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1024.702515,484.108826 
	C1021.246094,488.843201 1018.220398,494.665283 1013.506775,498.295898 
	C1008.396362,502.232025 1002.906067,505.473053 999.380554,511.097870 
	C999.076416,511.583252 998.273682,511.756195 997.346191,512.045898 
	C999.932068,506.185455 1003.504883,501.209534 1009.880676,498.317871 
	C1012.971619,496.915955 1015.165894,493.513000 1017.729126,490.978516 
	C1019.976135,488.756561 1022.171509,486.482330 1024.702515,484.108826 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1037.045410,501.692078 
	C1032.859741,499.549683 1035.157959,495.661377 1035.445190,492.429657 
	C1035.880615,487.532806 1037.116577,482.707153 1038.177734,477.279449 
	C1037.896484,476.477753 1037.450073,476.247864 1037.003540,476.017975 
	C1035.582031,476.363922 1034.160400,476.709900 1032.365723,477.036224 
	C1032.990723,472.455597 1036.984253,474.468231 1039.635132,473.534241 
	C1039.807251,475.619385 1040.333496,477.747375 1040.087158,479.781891 
	C1039.220459,486.944122 1038.088623,494.074249 1037.045410,501.692078 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1064.991089,486.036011 
	C1066.452148,486.132568 1067.913818,486.311157 1069.374268,486.303314 
	C1070.927002,486.294983 1072.478882,486.110046 1074.031128,486.002228 
	C1074.015259,487.193909 1074.476685,488.987305 1073.886719,489.455200 
	C1072.555054,490.511444 1070.750488,491.126343 1069.046143,491.498779 
	C1068.042969,491.718048 1066.863159,491.129303 1065.380859,490.655914 
	C1064.996460,488.950592 1064.993774,487.493286 1064.991089,486.036011 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1064.947144,485.683197 
	C1064.993774,487.493286 1064.996460,488.950592 1064.978516,490.755798 
	C1059.204102,493.174133 1056.688110,491.167084 1057.657471,484.492371 
	C1060.027466,484.766479 1062.465332,485.048431 1064.947144,485.683197 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1093.115234,667.159363 
	C1092.211182,668.523193 1091.307007,669.887085 1090.402954,671.250916 
	C1089.562866,669.908264 1088.722778,668.565552 1087.774414,666.858215 
	C1088.129272,663.884644 1088.592285,661.275696 1089.150391,658.211304 
	C1089.634399,657.836365 1090.023193,657.916992 1090.795654,658.392090 
	C1091.824707,661.577454 1092.469971,664.368408 1093.115234,667.159363 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1074.868774,554.869995 
	C1073.405884,556.038025 1072.023193,557.355103 1070.401001,558.226440 
	C1070.132690,558.370605 1068.203369,556.594666 1068.158447,555.652893 
	C1068.113892,554.714600 1069.324585,553.107117 1070.254272,552.875916 
	C1071.623291,552.535522 1073.231201,553.155457 1074.765503,553.917725 
	C1074.793701,554.469055 1074.868774,554.869995 1074.868774,554.869995 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M915.582520,571.406189 
	C915.703186,570.527222 915.823853,569.648254 915.923584,568.321777 
	C919.923462,567.875305 923.944214,567.876282 928.379395,567.848755 
	C929.065247,567.870911 929.336609,567.921631 930.021729,568.026611 
	C928.467102,568.850830 926.498779,569.620850 923.910034,570.646973 
	C922.618530,571.576965 921.947449,572.250916 920.813232,572.954346 
	C918.775269,573.050415 917.200317,573.116943 915.631958,572.885254 
	C915.619873,572.193420 915.601196,571.799805 915.582520,571.406189 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1019.633179,469.226868 
	C1020.926697,470.165344 1021.926514,470.871704 1022.920471,471.573914 
	C1020.960815,473.296143 1019.327515,474.731567 1017.063660,476.721130 
	C1016.908691,473.715790 1016.770630,471.037231 1016.632568,468.358704 
	C1017.534851,468.570740 1018.437195,468.782745 1019.633179,469.226868 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M996.706299,512.123474 
	C995.427246,515.013489 994.017578,518.155090 992.187988,521.029358 
	C991.002136,522.892395 989.203064,524.364990 987.333008,526.015503 
	C990.123901,521.432861 993.260681,516.847290 996.706299,512.123474 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M933.412659,495.961548 
	C933.163025,495.491394 933.383911,495.050018 933.705566,494.384644 
	C938.329773,494.160614 942.853149,494.160614 947.376526,494.160614 
	C947.399963,494.770538 947.423401,495.380432 947.446899,495.990356 
	C942.925598,495.990356 938.404358,495.990356 933.412659,495.961548 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M989.462463,450.017731 
	C989.231567,449.526398 989.467224,449.057220 989.793457,448.341522 
	C994.418396,448.095032 998.952759,448.095032 1003.487183,448.095032 
	C1003.507263,448.743317 1003.527405,449.391602 1003.547546,450.039886 
	C999.008057,450.039886 994.468567,450.039886 989.462463,450.017731 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1087.673340,553.137085 
	C1088.035278,554.467651 1087.991455,555.880737 1087.852173,557.712036 
	C1084.406006,557.153259 1081.055420,556.176147 1076.632202,554.886292 
	C1080.687378,554.250793 1083.977417,553.735229 1087.673340,553.137085 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1028.777588,479.781860 
	C1027.934814,481.062683 1026.845947,482.592834 1025.371826,484.069153 
	C1026.168091,482.687286 1027.349609,481.359222 1028.777588,479.781860 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M910.934814,521.063538 
	C911.688599,521.459839 912.442322,521.856079 913.196045,522.252380 
	C912.909973,522.603210 912.623840,522.954041 912.337769,523.304932 
	C911.949707,522.732544 911.561707,522.160095 911.069946,521.310364 
	C910.966248,521.033081 910.934814,521.063538 910.934814,521.063538 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1038.355591,401.022949 
	C1038.563599,400.344116 1039.116943,399.670227 1040.051025,398.992523 
	C1039.854736,399.668457 1039.277954,400.348175 1038.355591,401.022949 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1031.705566,477.084473 
	C1031.457153,477.701172 1030.898560,478.413025 1029.939453,479.075134 
	C1030.157593,478.410187 1030.776489,477.794922 1031.705566,477.084473 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M910.984070,521.021790 
	C910.744812,520.694763 910.487793,520.378967 910.230713,520.063232 
	C910.395508,519.958252 910.560242,519.853210 910.724976,519.748169 
	C910.822754,520.013367 910.920593,520.278625 910.976624,520.803711 
	C910.934814,521.063538 910.966248,521.033081 910.984070,521.021790 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1075.050781,554.838196 
	C1074.868774,554.869995 1074.793701,554.469055 1074.851929,554.273804 
	C1075.086792,554.220703 1075.271118,554.355347 1075.428467,554.516357 
	C1075.444946,554.533142 1075.301880,554.705933 1075.050781,554.838196 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1050.234131,391.944885 
	C1050.168335,391.746552 1050.352905,391.463776 1050.779053,391.086334 
	C1050.841919,391.281250 1050.663208,391.570831 1050.234131,391.944885 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1051.254395,390.960968 
	C1051.168701,390.732544 1051.344971,390.444122 1051.769897,390.079224 
	C1051.851196,390.302185 1051.683838,390.601562 1051.254395,390.960968 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1012.986328,470.980713 
	C1013.094971,470.949738 1013.226196,470.943665 1013.208130,470.993927 
	C1013.058960,471.050262 1013.008789,471.005615 1012.986328,470.980713 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1501.990479,597.184021 
	C1502.015625,596.689697 1502.040771,596.195435 1502.177979,595.482727 
	C1504.258545,595.264343 1506.227173,595.264343 1509.133667,595.264343 
	C1507.790283,594.019958 1507.012451,593.299377 1505.888794,592.258423 
	C1506.918823,591.926331 1507.732178,591.664124 1509.053467,591.648926 
	C1510.526489,592.505737 1511.491577,593.115540 1513.353027,594.291809 
	C1512.692993,592.447571 1512.398804,591.625366 1512.104614,590.803223 
	C1513.709229,591.920837 1515.182739,593.403931 1516.959595,594.040955 
	C1518.394653,594.555420 1520.476685,594.548218 1521.741943,593.833923 
	C1522.697876,593.294189 1523.051758,591.308655 1523.268555,589.904053 
	C1524.201904,583.851990 1525.567017,583.082153 1532.558838,585.201416 
	C1529.962524,585.922852 1526.585938,585.875366 1525.545776,587.410828 
	C1524.364380,589.154602 1525.272949,592.314331 1526.353760,593.776550 
	C1530.314697,595.356873 1534.275635,596.937195 1538.808105,598.763428 
	C1539.942383,599.823303 1540.505127,600.637268 1541.106445,601.835815 
	C1541.861694,603.250854 1542.578247,604.281494 1543.198486,605.747681 
	C1543.031616,617.131958 1542.960938,628.080688 1542.714600,639.032715 
	C1542.539062,639.036072 1542.187988,639.032288 1542.118164,638.595581 
	C1542.039185,636.094482 1542.030151,634.030029 1542.061401,631.585693 
	C1542.040894,630.445862 1541.979980,629.685913 1541.955078,628.507874 
	C1541.975464,626.411743 1541.959717,624.733704 1541.977783,622.778137 
	C1541.991943,622.315308 1541.972168,622.129944 1541.966187,621.714783 
	C1541.979980,621.485046 1541.979858,621.024780 1542.029053,620.611694 
	C1542.062256,618.813599 1542.046387,617.428650 1542.027588,615.639404 
	C1541.352905,613.973145 1540.681274,612.711182 1540.070068,611.204102 
	C1539.789185,610.618225 1539.447754,610.277588 1539.108032,609.503174 
	C1540.810913,600.201538 1534.194092,595.999023 1527.958496,597.874207 
	C1523.160156,599.317078 1519.314331,597.518066 1515.034180,596.748657 
	C1513.681274,596.612000 1512.341675,596.823792 1510.686523,597.251953 
	C1507.577637,597.373596 1504.784058,597.278809 1501.990479,597.184021 
M1541.160522,608.019897 
	C1541.160522,606.861755 1541.160522,605.703613 1541.160522,604.545410 
	C1540.726196,604.587341 1540.291870,604.629333 1539.857544,604.671265 
	C1540.100098,605.950439 1540.342773,607.229553 1541.160522,608.019897 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1475.065552,553.926575 
	C1474.681885,551.841858 1474.298218,549.757141 1473.691284,546.459167 
	C1475.687500,548.760559 1476.658447,549.880066 1477.629395,550.999512 
	C1478.702759,550.059387 1479.776123,549.119202 1481.407227,547.690369 
	C1481.596680,550.765259 1482.869507,554.419006 1481.747192,557.051514 
	C1479.897095,561.390686 1481.333984,565.457520 1481.209106,569.576416 
	C1481.019653,575.834351 1481.426880,575.846680 1474.283569,575.684265 
	C1473.488892,574.983093 1473.240601,574.495789 1472.992310,574.008484 
	C1476.722412,569.230408 1472.752197,563.917358 1474.020020,558.945618 
	C1474.374634,557.554871 1474.240601,556.039612 1474.660645,554.641235 
	C1476.470703,555.409668 1477.950684,556.118225 1479.953247,557.077026 
	C1480.063477,551.294495 1476.574219,554.712280 1475.065552,553.926575 
M1477.916992,559.706177 
	C1477.821289,559.651794 1477.725464,559.597412 1477.629761,559.543030 
	C1477.759766,559.690369 1477.889771,559.837769 1478.131226,560.302246 
	C1478.234009,560.349487 1478.336792,560.396667 1478.439575,560.443909 
	C1478.286865,560.303467 1478.134277,560.163025 1477.916992,559.706177 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1525.713013,550.530151 
	C1525.613770,551.148987 1525.514526,551.767822 1525.338989,552.862671 
	C1522.870605,550.686035 1520.565796,548.546204 1518.453369,552.685303 
	C1518.267822,553.049011 1517.007812,552.940918 1516.249756,552.923828 
	C1510.726562,552.799255 1505.113403,555.220642 1499.657227,552.014954 
	C1498.462891,551.313171 1496.504150,551.912292 1494.454834,551.912292 
	C1494.454834,551.074402 1494.454834,549.140442 1494.454834,546.755676 
	C1501.508545,550.358826 1508.396484,545.961060 1516.543213,547.781921 
	C1514.705566,548.470703 1512.867920,549.159424 1511.030151,549.848206 
	C1511.527588,550.504883 1512.025024,551.161560 1512.522461,551.818298 
	C1516.542114,551.442383 1518.761963,546.090698 1523.643066,547.271118 
	C1524.578369,548.485413 1525.145752,549.507812 1525.713013,550.530151 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1503.991699,561.074951 
	C1503.215942,559.979980 1502.440308,558.884949 1501.206543,557.143433 
	C1506.167725,557.143433 1510.246338,557.143433 1514.749512,557.143433 
	C1514.749512,560.731812 1514.749512,564.457581 1514.749512,568.468994 
	C1508.696533,569.187805 1512.017090,574.388184 1510.261963,577.920166 
	C1509.156494,578.086365 1508.706543,577.997314 1508.256714,577.908203 
	C1507.994385,576.962463 1507.403564,575.971863 1507.525757,575.078735 
	C1508.095703,570.912781 1506.180176,568.999573 1500.768311,569.087952 
	C1502.404053,566.915833 1503.370117,565.633057 1504.861572,564.720459 
	C1507.026367,568.291809 1510.680664,568.602295 1513.631836,565.970520 
	C1512.791870,563.496948 1511.989502,561.134216 1511.100830,558.517273 
	C1508.472046,559.463013 1506.231934,560.268982 1503.991699,561.074951 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1490.020874,588.987427 
	C1486.287964,588.162170 1482.534546,587.351379 1478.095459,586.392395 
	C1480.041748,584.602173 1481.434326,583.321228 1483.070801,581.816040 
	C1483.563110,583.038818 1483.960938,584.026672 1484.097046,584.364746 
	C1486.691528,583.719116 1489.015869,583.140686 1491.340210,582.562256 
	C1491.225220,584.166809 1491.110107,585.771301 1490.974976,587.707886 
	C1490.954956,588.039856 1490.993896,588.000427 1490.701904,587.995300 
	C1490.260498,588.337952 1490.111084,588.685730 1489.961548,589.033447 
	C1489.961548,589.033447 1490.000366,589.001770 1490.020874,588.987427 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1508.136719,578.294983 
	C1508.706543,577.997314 1509.156494,578.086365 1509.949463,578.200562 
	C1510.292725,579.016541 1510.292725,579.807495 1510.292725,581.269409 
	C1511.375488,580.360718 1512.047607,579.796631 1513.796631,578.328796 
	C1513.215576,580.659058 1513.234009,582.677246 1512.563599,582.940369 
	C1507.509521,584.923706 1510.286133,587.609985 1511.889404,590.509521 
	C1512.398804,591.625366 1512.692993,592.447571 1513.353027,594.291809 
	C1511.491577,593.115540 1510.526489,592.505737 1509.420166,591.652710 
	C1507.989014,588.252686 1506.698975,585.095764 1505.490234,582.137817 
	C1505.931641,581.533936 1506.974121,580.107849 1508.136719,578.294983 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1504.143555,561.414978 
	C1506.231934,560.268982 1508.472046,559.463013 1511.100830,558.517273 
	C1511.989502,561.134216 1512.791870,563.496948 1513.631836,565.970520 
	C1510.680664,568.602295 1507.026367,568.291809 1505.245361,564.820435 
	C1504.834351,563.618408 1504.564941,562.686707 1504.143555,561.414978 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1480.648438,540.605469 
	C1478.219116,541.612549 1476.104248,542.393860 1472.716675,543.645325 
	C1473.741943,540.511169 1474.217407,537.684875 1475.640869,535.467346 
	C1476.125122,534.713074 1479.325439,534.959106 1480.652344,535.775696 
	C1481.412476,536.243469 1480.901489,538.776550 1480.648438,540.605469 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1541.067993,601.451294 
	C1540.505127,600.637268 1539.942383,599.823303 1539.133057,598.816406 
	C1538.907471,595.091187 1538.928467,591.558838 1538.952637,587.579712 
	C1539.878418,587.209534 1540.801270,587.286011 1541.889038,588.109924 
	C1542.383911,593.079102 1542.713745,597.300842 1543.043579,601.522644 
	C1543.043579,601.522644 1543.000000,601.502075 1542.700806,601.408264 
	C1541.957031,601.360046 1541.512573,601.405701 1541.067993,601.451294 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1472.669434,573.858032 
	C1473.240601,574.495789 1473.488892,574.983093 1473.848633,575.720825 
	C1473.729126,576.401550 1473.606689,577.036499 1473.248169,577.226624 
	C1471.735352,578.029358 1470.188721,578.841858 1468.554443,579.261780 
	C1468.314209,579.323486 1467.536011,577.291687 1467.006592,576.228577 
	C1468.786621,575.388245 1470.566528,574.547913 1472.669434,573.858032 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1526.085449,550.754883 
	C1525.145752,549.507812 1524.578369,548.485413 1523.985596,547.147766 
	C1525.167480,547.419189 1526.374756,548.005920 1527.800171,548.698547 
	C1528.574097,548.032410 1529.516968,547.220886 1531.197388,545.774597 
	C1530.970581,548.394348 1530.814087,550.200989 1530.644531,552.159485 
	C1529.096802,551.723389 1527.777222,551.351501 1526.085449,550.754883 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1542.983887,534.195435 
	C1543.172729,548.285339 1543.122681,562.239014 1542.970703,576.663696 
	C1542.868774,577.134583 1542.382812,577.031433 1542.148193,576.947998 
	C1541.609863,562.894104 1541.306274,548.923645 1540.999878,534.486328 
	C1541.579712,534.032715 1542.162231,534.046021 1542.983887,534.195435 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1545.990723,585.256592 
	C1547.528198,583.844299 1549.041626,582.620483 1550.555054,581.396729 
	C1551.125854,582.678894 1552.495483,584.305603 1552.118896,585.179016 
	C1550.950317,587.888428 1548.943115,589.498596 1546.105713,586.186584 
	C1546.022949,585.821045 1545.966553,585.445129 1545.990723,585.256592 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1543.065918,601.013672 
	C1542.713745,597.300842 1542.383911,593.079102 1542.110229,588.390991 
	C1542.333862,587.942566 1542.501343,587.960510 1542.914795,588.039185 
	C1543.136597,592.234863 1543.112427,596.369812 1543.065918,601.013672 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M33.293606,243.419464 
	C35.965324,246.071289 38.926163,247.024017 42.159252,245.820480 
	C45.848656,244.447052 44.974865,240.992447 45.018482,238.083527 
	C45.070843,234.591537 45.030857,231.098175 45.494026,227.625320 
	C48.323498,233.661179 47.382133,244.174713 44.261520,247.051743 
	C39.984379,250.995026 34.281086,249.141388 33.293606,243.419464 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1541.106445,601.835815 
	C1541.512573,601.405701 1541.957031,601.360046 1542.724731,601.407104 
	C1546.344971,603.774292 1547.825806,602.535706 1548.325195,596.782227 
	C1553.611084,596.007935 1558.972412,595.222717 1564.925293,594.350769 
	C1564.925293,583.969055 1564.925293,573.100281 1564.925293,562.231506 
	C1565.267090,562.230774 1565.608887,562.229980 1565.950684,562.229248 
	C1565.950684,573.032410 1565.950684,583.835571 1565.950684,594.638794 
	C1566.493408,594.873779 1567.036133,595.108826 1567.578857,595.343872 
	C1568.687744,593.368042 1569.708374,591.334717 1570.944214,589.441956 
	C1571.522217,588.556763 1572.515991,587.942993 1574.035889,587.158752 
	C1575.241455,587.065369 1575.732422,587.019531 1576.223511,586.973694 
	C1581.197876,587.721619 1586.172241,588.469604 1591.653198,589.293762 
	C1585.117676,591.670166 1576.923462,587.241638 1571.309326,594.445679 
	C1588.135010,594.445679 1604.066406,594.445679 1620.464844,594.459900 
	C1621.192261,594.585083 1621.452515,594.696045 1622.124756,594.909973 
	C1621.892578,595.523071 1621.248535,596.033142 1619.831055,596.508789 
	C1600.016113,596.640869 1580.974976,596.842163 1561.933105,596.957397 
	C1553.924438,597.005920 1553.923950,596.923401 1552.972656,605.027344 
	C1552.990112,605.007812 1553.021729,604.966003 1552.688232,604.833618 
	C1548.007446,602.225586 1545.642700,603.447021 1545.670166,608.529968 
	C1545.726318,618.940247 1546.043213,629.349121 1546.159180,640.199890 
	C1546.119385,641.837646 1546.173340,643.034058 1546.206177,644.684204 
	C1546.039307,652.488647 1545.893555,659.839294 1545.564941,667.527710 
	C1545.166748,670.325562 1544.951660,672.785828 1544.402344,675.078979 
	C1543.363159,674.958130 1542.658203,675.004333 1541.851807,674.619141 
	C1541.984619,666.012146 1538.762817,662.829773 1531.362793,663.926819 
	C1531.483887,663.470337 1531.604980,663.013794 1531.726196,662.557312 
	C1529.251343,661.855896 1526.776611,661.154480 1524.221924,659.963501 
	C1524.203247,658.160400 1524.264648,656.846802 1524.480469,655.662109 
	C1524.635010,655.791016 1524.974976,656.006042 1525.006104,656.292908 
	C1525.361206,656.735046 1525.685181,656.890320 1526.327637,657.190247 
	C1532.316650,660.109192 1537.987183,662.883606 1543.644775,665.651611 
	C1543.644775,660.495728 1543.644775,655.848755 1543.700195,650.938110 
	C1543.608887,650.394592 1543.462158,650.114624 1543.315430,649.834717 
	C1543.219116,650.232422 1543.122681,650.630127 1542.757202,651.071716 
	C1542.308716,651.145752 1542.129517,651.175781 1541.948486,650.879333 
	C1541.934937,650.335327 1541.923462,650.117920 1541.984619,649.480591 
	C1542.057129,647.344299 1542.057129,645.627991 1542.032349,643.501831 
	C1541.948242,642.006958 1541.888794,640.921997 1541.922363,639.637329 
	C1542.015503,639.437683 1542.187988,639.032288 1542.187988,639.032288 
	C1542.187988,639.032288 1542.539062,639.036072 1542.802490,639.322998 
	C1543.259888,639.966736 1543.453979,640.323608 1543.648193,640.680542 
	C1543.723267,640.583435 1543.865479,640.485291 1543.863892,640.389343 
	C1543.681152,628.696838 1543.487671,617.004517 1543.294800,605.312134 
	C1542.578247,604.281494 1541.861694,603.250854 1541.106445,601.835815 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1572.693604,571.038818 
	C1571.478516,568.829956 1567.683472,569.515869 1567.801880,565.269958 
	C1568.074707,555.490479 1568.587769,554.654175 1578.442383,555.938416 
	C1579.082153,556.021790 1580.226440,556.141724 1580.261108,556.374634 
	C1580.758545,559.721497 1581.365601,563.093994 1581.321289,566.452148 
	C1581.308960,567.393494 1579.272217,568.307983 1577.589844,568.902466 
	C1574.219727,567.020508 1573.094238,568.395142 1572.693604,571.038818 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1611.093994,587.850464 
	C1609.872070,590.770996 1608.717407,593.112976 1605.219116,590.960449 
	C1604.529419,590.536072 1602.847778,590.783630 1602.219116,591.365051 
	C1599.717041,593.679688 1597.352783,593.219666 1595.265381,591.136108 
	C1594.660034,590.531982 1594.622681,588.154785 1595.071167,587.898865 
	C1597.552368,586.483765 1599.782227,583.045532 1603.256226,586.133240 
	C1603.623413,586.459534 1603.997437,586.778259 1604.519409,587.231873 
	C1606.686768,586.462036 1609.199707,582.018860 1611.093994,587.850464 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1568.001099,548.996033 
	C1571.383301,544.284180 1572.394409,544.258850 1575.251953,548.579224 
	C1577.143921,547.594177 1579.009155,546.623047 1580.874390,545.651917 
	C1581.412231,546.314941 1581.950073,546.977905 1582.488037,547.640930 
	C1580.640259,548.251831 1578.792480,548.862732 1576.944702,549.473633 
	C1577.401245,550.043457 1577.857910,550.613281 1578.314453,551.183105 
	C1579.794922,550.934753 1581.275391,550.686401 1582.862427,550.420166 
	C1580.626221,553.693359 1572.728394,554.448669 1568.308350,551.488525 
	C1567.984985,550.370239 1567.993042,549.683167 1568.001099,548.996033 
M1571.333862,550.060974 
	C1570.747559,550.096558 1570.161377,550.132141 1569.575073,550.167725 
	C1569.690674,550.497314 1569.806274,550.826843 1569.921875,551.156433 
	C1570.618652,550.816650 1571.315308,550.476807 1571.333862,550.060974 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1567.965088,548.629761 
	C1567.993042,549.683167 1567.984985,550.370239 1567.953735,551.399658 
	C1563.804199,552.140686 1559.677734,552.539429 1555.551392,552.938171 
	C1555.479248,552.709106 1555.407227,552.479980 1555.335083,552.250916 
	C1556.776123,551.735229 1558.217285,551.219604 1559.340820,550.817566 
	C1557.924072,549.660889 1556.598755,548.578796 1555.273315,547.496704 
	C1556.069336,546.858276 1557.104248,545.530945 1557.617065,545.708801 
	C1559.253540,546.276550 1560.695923,547.403259 1563.109253,548.852051 
	C1563.316406,548.689148 1564.463745,547.567993 1565.826172,546.861511 
	C1566.187500,546.674133 1567.210083,547.761475 1567.965088,548.629761 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1572.503662,571.389404 
	C1573.094238,568.395142 1574.219727,567.020508 1577.205811,568.911377 
	C1576.787842,572.115295 1575.801025,574.969116 1575.732788,577.844727 
	C1575.699097,579.265259 1577.359985,580.725952 1578.137939,581.980957 
	C1577.638916,583.051514 1576.907959,584.619812 1576.200195,586.580933 
	C1575.732422,587.019531 1575.241455,587.065369 1574.383057,587.056274 
	C1573.448486,581.914185 1572.881104,576.827148 1572.503662,571.389404 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1622.539307,568.975647 
	C1622.877563,576.092224 1622.966553,583.217529 1622.896484,590.341187 
	C1622.885010,591.505005 1622.171265,592.661987 1621.386475,593.926880 
	C1620.990967,594.031738 1620.996338,594.008240 1621.016968,593.539978 
	C1621.033081,585.045837 1621.028442,577.019958 1621.023926,568.994141 
	C1621.428345,568.990662 1621.832764,568.987244 1622.539307,568.975647 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1558.385986,534.706299 
	C1558.760498,534.497864 1558.894165,534.498962 1558.908691,534.522583 
	C1559.259644,535.282227 1560.051880,536.481689 1559.854980,536.675354 
	C1558.927612,537.587463 1557.728760,538.223877 1556.626831,538.958618 
	C1556.793213,539.357422 1556.959717,539.756165 1557.126221,540.154907 
	C1558.709473,539.957275 1560.292725,539.759583 1561.876099,539.561890 
	C1562.031494,539.928040 1562.187012,540.294189 1562.342529,540.660339 
	C1560.772705,541.382812 1559.247437,542.481018 1557.615112,542.674316 
	C1556.757568,542.775757 1554.773804,540.931091 1554.906982,540.585815 
	C1555.676147,538.592834 1556.925049,536.785156 1558.385986,534.706299 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1592.997314,550.013550 
	C1592.558105,550.652344 1592.119019,551.291138 1591.954224,551.530945 
	C1590.038818,551.007324 1588.037598,550.460388 1586.036499,549.913391 
	C1585.819214,550.427429 1585.602051,550.941467 1585.384766,551.455505 
	C1585.031494,549.659180 1584.678223,547.862854 1584.130127,545.076050 
	C1586.216187,545.664856 1587.895752,546.138977 1589.860840,547.190430 
	C1591.096558,548.516418 1592.046997,549.265015 1592.997314,550.013550 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1621.003296,568.540405 
	C1621.028442,577.019958 1621.033081,585.045837 1621.007202,593.540405 
	C1620.729614,594.044495 1620.482300,594.079895 1620.019775,593.800903 
	C1619.804443,593.486511 1619.797241,593.010864 1619.797241,593.010864 
	C1619.839844,581.963928 1619.882446,570.917053 1620.179199,559.409180 
	C1620.624756,558.960083 1620.816162,558.971802 1621.007446,558.983582 
	C1620.999146,562.017944 1620.990845,565.052368 1621.003296,568.540405 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1593.244507,550.212646 
	C1592.046997,549.265015 1591.096558,548.516418 1590.058350,547.467041 
	C1592.567139,546.083801 1596.176758,550.820007 1598.426758,545.742371 
	C1598.426758,547.766418 1598.426758,549.790527 1598.426758,551.594177 
	C1596.719849,551.185181 1595.105713,550.798401 1593.244507,550.212646 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1621.011230,558.518188 
	C1620.816162,558.971802 1620.624756,558.960083 1620.145020,558.937134 
	C1619.476074,553.850098 1619.095825,548.774292 1618.715576,543.698486 
	C1619.482056,543.654724 1620.248657,543.610962 1621.015137,543.567200 
	C1621.015137,548.395691 1621.015137,553.224243 1621.011230,558.518188 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1563.020264,537.367126 
	C1565.361450,537.183655 1567.664551,537.377441 1570.200195,537.883423 
	C1570.432495,538.195618 1570.343872,538.198486 1569.957397,538.234253 
	C1567.393921,538.849121 1565.217041,539.428284 1563.040039,540.007507 
	C1563.020874,539.253113 1563.001709,538.498718 1563.020264,537.367126 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1562.986816,540.364014 
	C1565.217041,539.428284 1567.393921,538.849121 1569.919922,538.210754 
	C1568.242676,542.820190 1565.950928,543.622803 1562.986816,540.364014 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1619.677002,593.083130 
	C1619.797241,593.010864 1619.804443,593.486511 1619.665283,593.679565 
	C1619.323853,593.618530 1619.333984,593.379456 1619.677002,593.083130 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2525.117188,711.694824 
	C2524.164062,719.046326 2519.296875,721.455566 2511.705566,718.858826 
	C2513.486572,711.675354 2514.516846,704.558472 2510.763184,696.990723 
	C2513.760010,696.990723 2517.099365,696.259827 2519.841309,697.225098 
	C2521.982910,697.978943 2524.133545,700.578979 2524.913086,702.831787 
	C2525.786865,705.356628 2525.121582,708.414001 2525.117188,711.694824 
M2523.043457,706.639832 
	C2522.884521,699.443848 2521.693359,698.327332 2515.477539,699.676575 
	C2515.477539,705.322510 2515.477539,710.987793 2515.477539,716.603394 
	C2521.700928,717.252319 2522.926758,716.295593 2523.051025,711.013123 
	C2523.078125,709.859009 2523.056152,708.703796 2523.043457,706.639832 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1094.239990,115.172287 
	C1094.833496,116.360130 1095.246216,117.168144 1095.658936,117.976166 
	C1093.743408,118.280266 1091.827881,118.584358 1089.521606,118.950500 
	C1091.395386,111.828537 1092.497192,105.753471 1088.878784,98.991188 
	C1089.247314,98.676483 1090.389038,97.701469 1091.530762,96.726456 
	C1092.119995,96.832748 1092.709351,96.939041 1093.298584,97.045334 
	C1093.552124,102.961044 1093.805664,108.876747 1094.239990,115.172287 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M324.028442,705.056763 
	C324.019043,704.278015 324.009644,703.499207 324.390503,702.277466 
	C325.140472,696.204102 325.500244,690.573669 325.859985,684.943298 
	C326.086121,684.055481 326.312256,683.167725 326.538391,682.279907 
	C326.902283,682.289734 327.266174,682.299622 327.630066,682.309448 
	C327.885315,688.339478 328.140564,694.369568 328.649048,700.729980 
	C328.911621,702.877930 328.928284,704.695374 328.928772,706.512878 
	C328.929749,710.101562 327.172333,711.982483 323.541748,711.988770 
	C317.429199,711.999390 311.316650,711.991699 304.911682,711.991699 
	C305.654785,710.771423 306.280243,709.744263 306.903046,708.384766 
	C307.698090,708.038513 308.495850,708.024719 309.746246,708.424927 
	C315.332458,709.362732 320.465973,709.886597 325.599518,710.410461 
	C325.918854,709.841797 326.238220,709.273071 326.557587,708.704407 
	C325.714539,707.488525 324.871460,706.272644 324.028442,705.056763 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M250.924469,710.958984 
	C254.068634,710.957153 256.726746,710.957153 259.384827,710.957153 
	C259.363098,711.474182 259.341339,711.991150 259.319580,712.508179 
	C250.906784,712.508179 242.494003,712.508179 234.081207,712.508179 
	C234.055206,712.120422 234.029190,711.732605 234.003189,711.344849 
	C239.481598,711.216797 244.960007,711.088806 250.924469,710.958984 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M188.817719,713.479614 
	C180.885406,713.916626 172.926758,713.954407 164.503311,713.983826 
	C164.790009,713.307739 165.530304,712.075684 166.294754,712.060486 
	C173.288849,711.921570 180.287430,711.972107 187.283768,712.051514 
	C187.790131,712.057190 188.289017,712.722046 188.817719,713.479614 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M344.921906,917.224976 
	C354.615845,917.138977 364.309784,917.052979 374.461426,916.903015 
	C375.089050,917.473022 375.258942,918.107056 374.771729,918.881958 
	C373.083801,920.028931 371.382751,920.909973 371.166534,922.068237 
	C370.759918,924.246216 370.174347,927.139893 373.923492,927.451172 
	C373.881409,927.677124 373.864319,927.904968 373.876465,928.294922 
	C372.790436,928.889954 371.700165,929.324707 370.278381,929.889343 
	C369.904388,919.867737 369.904236,919.857910 359.868286,920.025146 
	C358.083191,920.054932 356.300049,920.204224 355.206055,920.261902 
	C353.813446,926.085815 352.560608,931.325134 351.158356,936.800476 
	C350.557251,937.047913 350.105621,937.059265 349.321960,937.140991 
	C337.124237,937.414185 325.248962,937.399963 313.397278,937.921265 
	C308.285522,938.146118 306.472443,936.413086 307.046387,931.445190 
	C307.422668,928.188232 307.341766,924.878357 307.953491,921.277771 
	C308.441376,920.963318 308.832733,920.945557 308.879211,921.414917 
	C308.925659,926.134888 308.925659,930.385437 308.925659,935.011719 
	C321.391418,935.011719 333.000305,935.105469 344.601288,934.844666 
	C345.594452,934.822205 346.534668,932.443665 347.527802,931.121948 
	C338.438110,931.121948 330.501801,931.121948 322.347900,931.121948 
	C322.262543,930.648987 321.923950,929.957947 322.075043,929.399231 
	C322.648438,927.279114 323.064178,923.617432 324.112671,923.408203 
	C327.152008,922.801819 330.553986,923.399231 330.208862,928.135742 
	C330.169098,928.681885 331.413544,929.803528 332.032928,929.782532 
	C332.673950,929.760925 333.640167,928.762878 333.824219,928.031006 
	C334.205688,926.514099 334.163147,924.890564 334.285950,923.418945 
	C338.149811,923.418945 341.401764,923.497498 344.644714,923.361755 
	C345.421417,923.329163 346.168274,922.583313 346.928864,922.165466 
	C346.276459,921.417297 345.624084,920.669067 344.982788,919.581909 
	C344.969849,918.570312 344.945862,917.897644 344.921906,917.224976 
M345.609283,928.073486 
	C345.800293,927.489380 345.991333,926.905334 346.182343,926.321228 
	C344.945557,926.367920 343.630402,926.192749 342.516205,926.586487 
	C342.147064,926.716858 342.295105,928.311096 342.169281,929.626953 
	C343.497803,928.953857 344.209045,928.593506 345.609283,928.073486 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M308.832733,920.945557 
	C308.832733,920.945557 308.441376,920.963318 308.247131,920.984863 
	C307.569611,920.385803 307.086365,919.765320 306.295471,919.088623 
	C307.175507,918.670837 308.354858,918.026855 309.552155,917.991577 
	C321.050140,917.652710 332.551147,917.416626 344.486572,917.188049 
	C344.945862,917.897644 344.969849,918.570312 344.517700,919.615601 
	C340.216248,919.997620 336.388092,919.916199 332.566162,920.035461 
	C324.653259,920.282227 316.743683,920.635864 308.832733,920.945557 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1788.994507,107.999176 
	C1788.548340,104.639076 1788.110840,101.746101 1787.673340,98.853127 
	C1787.122681,98.792488 1786.572021,98.731842 1786.021362,98.671204 
	C1787.132812,97.677986 1788.244263,96.684769 1789.854614,95.245682 
	C1792.531250,102.701004 1788.358765,110.574501 1793.667847,117.460655 
	C1791.452148,117.591576 1789.090210,117.731125 1787.761841,117.809608 
	C1788.220703,114.355179 1788.611938,111.410744 1788.994507,107.999176 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1801.022217,113.846817 
	C1800.806885,113.622650 1800.800903,113.406296 1800.794800,113.189934 
	C1800.933838,113.193962 1801.187622,113.175415 1801.193726,113.205635 
	C1801.235107,113.416298 1801.225220,113.637093 1801.022217,113.846817 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M363.602173,936.536987 
	C363.751160,936.011108 364.096039,935.685242 364.440918,935.359375 
	C364.722778,935.681946 365.004578,936.004456 365.286407,936.327026 
	C364.790314,936.463684 364.294189,936.600342 363.602173,936.536987 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M374.185242,927.100159 
	C370.174347,927.139893 370.759918,924.246216 371.166534,922.068237 
	C371.382751,920.909973 373.083801,920.028931 374.525269,919.044189 
	C374.772949,921.626770 374.609955,924.187988 374.185242,927.100159 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2081.469482,1220.981689 
	C2079.307129,1220.340942 2076.337646,1219.944214 2076.027100,1218.815918 
	C2075.347900,1216.347900 2075.620850,1213.391968 2076.354980,1210.884155 
	C2076.609375,1210.015137 2080.364258,1209.034180 2080.817139,1209.562988 
	C2082.485840,1211.512207 2083.936768,1213.968506 2084.456543,1216.446533 
	C2084.717773,1217.693115 2082.800293,1219.396851 2081.469482,1220.981689 
M2077.016357,1214.540771 
	C2077.875244,1216.025391 2078.734375,1217.510010 2079.593506,1218.994629 
	C2080.411621,1218.415283 2081.914062,1217.840332 2081.917725,1217.255859 
	C2081.927979,1215.594238 2081.327393,1213.928711 2080.967041,1212.264771 
	C2079.679688,1212.744751 2078.392334,1213.224854 2077.016357,1214.540771 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2105.398926,1217.308838 
	C2104.383545,1220.613525 2102.315674,1221.636597 2099.497803,1220.969482 
	C2096.227051,1220.195190 2095.324951,1217.490601 2096.060059,1214.792358 
	C2096.624756,1212.719482 2098.051270,1210.113281 2099.814209,1209.294922 
	C2103.466064,1207.600464 2105.879639,1211.005615 2105.398926,1217.308838 
M2103.377686,1213.199951 
	C2101.439453,1210.609497 2099.273682,1209.757812 2098.331299,1213.620728 
	C2097.964844,1215.121948 2099.629395,1217.118530 2100.368896,1218.889526 
	C2101.461426,1217.228394 2102.553955,1215.567261 2103.377686,1213.199951 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M963.326904,107.111794 
	C961.428223,107.065193 959.916687,107.180862 958.440063,107.004829 
	C954.610962,106.548378 952.738037,103.677673 953.452942,100.012794 
	C954.452454,94.888786 958.144043,94.578598 962.183411,94.919479 
	C966.142822,95.253624 967.811829,97.560349 968.115601,101.505997 
	C968.545654,107.093842 968.002563,112.173149 962.815247,115.460556 
	C961.378662,116.371017 959.564697,116.685989 957.761780,116.469521 
	C960.094788,114.388298 962.526062,112.399673 964.663025,110.133553 
	C965.014526,109.760834 964.073669,108.169395 963.326904,107.111794 
M959.695984,96.934364 
	C956.563599,97.213196 954.052429,98.973846 955.296631,102.001518 
	C957.369019,107.044510 961.871338,104.762054 965.650940,104.628204 
	C966.452637,100.015877 965.891785,96.793343 959.695984,96.934364 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M39.756065,358.822937 
	C38.725822,357.828827 37.907993,357.195221 36.086517,355.783997 
	C39.746792,355.783997 42.097965,355.783997 45.009773,355.783997 
	C40.928505,360.516846 40.937675,373.726166 45.019962,377.507660 
	C42.541008,377.507660 40.593338,377.507660 38.625263,377.507660 
	C41.417900,371.548615 39.790520,365.299774 39.756065,358.822937 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1557.873535,93.820900 
	C1558.399170,98.834679 1558.515869,103.626595 1558.929810,108.392700 
	C1559.112183,110.493980 1559.898193,112.542839 1560.593994,115.359840 
	C1559.076050,115.492760 1556.831787,115.689293 1554.214111,115.918518 
	C1556.406982,109.087044 1557.637329,102.970551 1553.634399,96.051186 
	C1554.580688,95.461441 1556.083130,94.524986 1557.873535,93.820900 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M235.990112,110.993881 
	C233.806396,112.307999 231.593338,113.576263 229.447845,114.950073 
	C227.578049,116.147346 225.012192,117.081390 224.150116,118.822891 
	C223.038528,121.068405 223.956665,123.379623 227.443405,123.136093 
	C230.031403,122.955330 232.642181,123.100533 235.242828,123.100533 
	C235.260773,123.513229 235.278717,123.925934 235.296661,124.338631 
	C231.121872,124.338631 226.947083,124.338631 222.727936,124.338631 
	C220.672928,116.213478 222.082108,114.105965 230.158737,112.873299 
	C231.906357,112.606575 233.548828,111.650955 235.620575,111.006958 
	C236.001434,111.001282 235.990112,110.993881 235.990112,110.993881 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M224.955261,104.953613 
	C227.308945,104.078484 229.662628,103.203362 232.016296,102.328232 
	C232.199173,103.047012 232.382034,103.765785 232.564911,104.484566 
	C230.343674,104.654198 228.122437,104.823837 225.447083,104.993088 
	C224.992950,104.992699 224.955261,104.953606 224.955261,104.953613 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M225.011810,105.012199 
	C224.460709,105.937103 223.890732,106.842514 223.320755,107.747917 
	C222.964294,107.457153 222.607834,107.166389 222.251389,106.875626 
	C222.939789,106.243240 223.628189,105.610847 224.635925,104.966034 
	C224.955261,104.953606 224.992950,104.992699 225.011810,105.012199 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M236.024506,106.971260 
	C236.191040,108.177811 236.357574,109.384361 236.257111,110.792397 
	C235.990112,110.993881 236.001434,111.001282 236.007538,111.007263 
	C235.990784,109.951508 235.967941,108.889763 235.971085,107.415146 
	C235.997070,107.002281 236.024506,106.971260 236.024506,106.971260 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M234.847229,106.166428 
	C234.649414,105.987206 234.451614,105.807983 234.253799,105.628761 
	C234.476425,105.590981 234.699051,105.553192 234.950531,105.770790 
	C234.979370,106.026176 234.847229,106.166428 234.847229,106.166428 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M235.049210,105.959801 
	C235.442154,106.008102 235.694992,106.210533 235.951050,106.735992 
	C236.024506,106.971260 235.997070,107.002281 235.980804,107.016968 
	C235.672470,106.888512 235.380402,106.745361 234.967789,106.384323 
	C234.847229,106.166428 234.979370,106.026176 235.049210,105.959801 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1305.887939,105.205238 
	C1305.927002,102.088310 1306.555664,99.087288 1300.198853,99.697594 
	C1303.022705,97.118629 1304.200317,96.043175 1305.377808,94.967728 
	C1305.961182,95.055809 1306.544434,95.143898 1307.127686,95.231987 
	C1307.380127,98.773041 1307.506470,102.328896 1307.920654,105.850922 
	C1308.328369,109.317886 1306.149292,113.458656 1311.570435,116.497604 
	C1308.145142,116.497604 1305.883789,116.497604 1304.771362,116.497604 
	C1305.147339,112.850830 1305.517944,109.255791 1305.887939,105.205238 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1319.088745,108.592682 
	C1319.735962,108.907600 1320.576904,109.524498 1320.877441,110.340492 
	C1321.568848,112.217026 1321.997192,114.190460 1322.638550,116.508698 
	C1321.120483,116.508698 1318.892700,116.508698 1317.424438,116.508698 
	C1317.992065,113.860451 1318.514648,111.422752 1319.088745,108.592682 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1316.970215,98.834213 
	C1317.608276,97.428787 1318.537842,96.211662 1319.467529,94.994530 
	C1319.786743,95.126350 1320.106079,95.258179 1320.425293,95.389999 
	C1320.425293,98.405426 1320.425293,101.420853 1320.425293,105.266541 
	C1319.083984,102.619133 1318.172974,100.820831 1316.970215,98.834213 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M915.239380,571.300964 
	C915.601196,571.799805 915.619873,572.193420 915.493042,573.159302 
	C914.501709,576.007202 913.655823,578.282776 912.809937,580.558350 
	C910.169556,580.482971 907.173828,581.194702 904.952209,580.191772 
	C896.680725,576.457764 888.645203,572.196106 880.572388,568.032959 
	C879.475281,567.467224 878.669312,566.337158 877.164917,565.226196 
	C876.412476,564.988220 876.223633,564.992371 876.034851,564.996521 
	C875.959412,560.627625 875.883972,556.258667 875.889587,551.427612 
	C880.755981,553.199097 886.659851,554.352478 890.085327,557.899353 
	C896.488281,564.529236 905.315491,565.910583 912.516663,570.542236 
	C913.176819,570.966858 914.096863,570.987549 915.239380,571.300964 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M875.685486,565.128845 
	C876.223633,564.992371 876.412476,564.988220 876.872437,565.066467 
	C876.144226,567.060608 875.568726,570.149231 872.563965,566.377930 
	C873.423035,565.748962 874.379578,565.505066 875.685486,565.128845 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M909.825684,506.431091 
	C910.077209,506.182312 910.339233,506.199646 910.975525,506.344910 
	C910.845276,506.547607 910.340759,506.622375 909.825684,506.431091 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M930.947449,485.955688 
	C930.947449,485.955688 930.956665,485.465179 930.989624,485.222015 
	C931.022522,484.978851 931.000183,484.999359 930.989258,485.009918 
	C931.422058,485.218231 931.865845,485.415985 932.309570,485.613708 
	C932.069092,485.756805 931.828613,485.899902 931.294373,486.023254 
	C931.000610,486.003479 930.947449,485.955688 930.947449,485.955688 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M930.038879,487.032654 
	C929.896301,487.041718 929.753784,487.050781 929.804321,487.027649 
	C929.997375,486.995422 930.038879,487.032623 930.038879,487.032654 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M929.978882,486.974548 
	C930.010864,486.601685 930.186890,486.331421 930.717896,486.049316 
	C930.947449,485.955688 931.000610,486.003479 931.025513,486.031097 
	C930.985046,486.405273 930.801208,486.669342 930.268921,486.941803 
	C930.038879,487.032623 929.997375,486.995422 929.978882,486.974548 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M103.859726,113.039597 
	C104.159126,117.287872 104.459076,121.058899 104.790009,125.219551 
	C103.641304,125.219551 101.619995,125.219551 100.742722,125.219551 
	C100.742722,117.474762 100.742722,110.375359 100.742722,103.275955 
	C101.568916,103.284836 102.395103,103.293724 103.221298,103.302605 
	C103.434288,106.389183 103.647278,109.475761 103.859726,113.039597 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M745.879700,100.891838 
	C748.779724,97.822945 752.334717,98.332016 755.312195,100.090317 
	C758.605469,102.035057 758.884521,105.248192 756.986267,108.721916 
	C756.562561,109.497345 757.094055,111.063843 757.613098,112.038605 
	C759.831116,116.204567 757.647400,120.742355 753.016418,120.953453 
	C750.100586,121.086380 746.683044,122.760124 744.578247,119.026344 
	C744.257935,118.458138 743.419434,117.961586 743.439392,117.456314 
	C743.628601,112.661392 743.951782,107.871765 744.616455,103.038589 
	C744.996582,102.996559 745.016357,103.011955 745.006226,103.413177 
	C745.128235,104.791534 744.987244,106.451614 745.438110,106.631943 
	C747.870789,107.604973 750.473450,108.800354 752.969360,108.701500 
	C754.267578,108.650085 755.466431,106.090599 756.710205,104.664047 
	C754.556030,103.449173 752.487488,102.019485 750.210999,101.111168 
	C749.093018,100.665062 747.589294,101.185585 746.165771,101.173706 
	C746.071411,101.078751 745.879700,100.891838 745.879700,100.891838 
M748.990662,119.845642 
	C749.815063,119.879333 750.702942,120.128601 751.452454,119.907928 
	C754.143921,119.115532 757.613281,118.296883 756.609741,114.649132 
	C755.638306,111.117920 752.239685,110.260094 748.867493,111.214172 
	C744.407043,112.476112 744.220764,115.907692 748.990662,119.845642 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M744.986145,102.986923 
	C745.115723,102.404716 745.255737,101.832161 745.637756,101.075714 
	C745.879700,100.891838 746.071411,101.078751 746.088379,101.437805 
	C745.742371,102.201897 745.379395,102.606926 745.016357,103.011955 
	C745.016357,103.011955 744.996582,102.996559 744.986145,102.986923 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2512.051270,900.001831 
	C2511.923096,891.717285 2513.481445,889.037598 2518.550537,888.202515 
	C2522.722168,887.515259 2525.516846,888.891357 2526.669434,893.411133 
	C2524.260498,892.512268 2522.140625,891.550415 2519.921143,890.968201 
	C2518.881348,890.695312 2517.047607,890.739807 2516.618896,891.361328 
	C2515.415771,893.106140 2513.922119,895.284912 2514.057373,897.184875 
	C2514.330811,901.029053 2511.613281,906.192383 2516.609619,908.345337 
	C2518.694580,909.243774 2521.974854,907.386230 2524.704834,906.751648 
	C2525.156006,906.646851 2525.570557,906.386292 2526.073975,906.166443 
	C2525.235107,910.392334 2524.169678,910.969360 2518.893066,911.010864 
	C2515.206787,911.039856 2512.793945,909.570007 2512.122803,905.932800 
	C2511.796875,904.165344 2512.056152,902.289917 2512.051270,900.001831 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M536.017578,120.015266 
	C534.849609,119.566383 533.305054,119.446808 532.576599,118.613640 
	C528.677856,114.154495 533.097534,101.359581 538.917297,100.071457 
	C539.736572,99.890114 540.674744,100.245560 541.299561,100.321251 
	C538.620239,103.006187 536.027222,105.604698 533.434143,108.203209 
	C533.655151,108.673332 533.876160,109.143448 534.097168,109.613571 
	C535.795898,109.726189 537.677856,109.413498 539.151550,110.048439 
	C541.520081,111.068909 544.306274,112.275719 545.576660,114.260872 
	C546.353210,115.474335 545.216003,119.445251 543.888367,120.123955 
	C541.736572,121.223984 538.721985,120.636230 536.448059,120.362778 
	C538.294250,119.900398 539.850281,120.123268 541.216125,119.712997 
	C544.138672,118.835037 545.282898,114.963921 542.828064,113.640694 
	C540.553101,112.414459 537.395447,112.825607 534.626587,112.515747 
	C534.924866,114.555748 535.205566,116.598602 535.534485,118.633644 
	C535.611145,119.107826 535.852173,119.555428 536.017578,120.015266 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M168.207123,767.856750 
	C168.625641,767.911804 169.044159,767.966919 169.751556,768.519897 
	C170.040451,777.602600 170.040451,786.187439 170.040451,794.772278 
	C169.629150,788.950928 173.155655,788.793762 177.491013,788.853943 
	C190.928314,789.040649 204.371567,788.828064 217.807526,789.059875 
	C221.313797,789.120361 223.440491,788.159424 224.393616,784.715637 
	C224.512314,784.699219 224.724838,784.811279 224.914551,785.172607 
	C225.854507,788.360352 227.653702,789.404602 230.546936,789.007690 
	C232.328339,788.763306 234.159607,788.882690 236.260834,788.894287 
	C236.747314,788.990662 236.941437,789.031433 237.235565,789.500488 
	C236.048416,794.289734 232.382828,790.945740 229.907318,791.835327 
	C228.352570,792.136108 227.013397,792.756775 225.442719,793.441406 
	C224.885239,793.518005 224.572739,793.459167 224.166595,792.922852 
	C221.959473,791.892212 219.859482,791.267517 217.058197,790.434204 
	C217.058197,793.061462 217.058197,794.475952 216.647202,795.902222 
	C215.142502,795.030457 213.982162,794.215027 212.981064,793.236633 
	C212.416962,792.685364 212.115356,791.865540 211.253571,791.125854 
	C205.065094,791.084961 199.318558,791.084961 193.298096,791.084961 
	C193.203049,793.003906 193.131042,794.457642 192.676987,795.922729 
	C191.533173,795.960388 190.771408,795.986755 190.030716,795.576111 
	C189.330902,793.762756 188.698349,791.260071 187.874588,791.195435 
	C183.055008,790.817444 178.190674,791.009949 173.056808,791.009949 
	C173.056808,793.280151 173.056808,794.738892 173.042969,796.420044 
	C173.029129,796.642395 173.005310,797.087402 172.562378,797.039734 
	C168.633713,796.560852 164.212814,798.618286 162.699951,793.205444 
	C161.611847,793.909119 160.726486,794.481689 159.567871,795.230957 
	C159.235489,793.959229 158.996643,793.045288 158.767441,791.919250 
	C158.777100,791.707092 158.740631,791.283813 158.740631,791.283813 
	C160.546524,791.091431 162.352402,790.899048 164.975754,790.619568 
	C164.975754,787.756531 165.241806,784.816101 164.822693,781.976746 
	C164.687103,781.058044 162.742645,779.739929 161.645050,779.758362 
	C158.692764,779.808105 155.761551,780.614563 152.800491,780.848450 
	C151.343094,780.963562 149.838104,780.476501 148.113266,780.226990 
	C149.635208,777.661987 150.777649,775.736572 152.120102,773.474060 
	C152.541138,773.979553 153.164337,774.727844 153.954163,775.676147 
	C154.694824,774.832031 155.453781,773.967041 156.462860,772.817078 
	C157.588150,774.507874 158.470978,775.834351 159.595627,777.524231 
	C159.856461,775.875183 160.048920,774.658325 160.321289,772.936340 
	C161.525467,773.841003 162.386871,774.488159 164.508469,776.082092 
	C164.676834,768.092590 164.826355,760.997192 165.005783,753.430176 
	C165.934433,752.791931 166.833191,752.625305 167.567749,752.996948 
	C167.403564,761.599121 167.403564,769.663147 167.403564,777.727173 
	C167.671417,777.728638 167.939270,777.730042 168.207123,777.731506 
	C168.207123,774.439941 168.207123,771.148315 168.207123,767.856750 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M158.301849,791.226685 
	C158.740631,791.283813 158.777100,791.707092 158.453217,791.905762 
	C157.741714,793.452576 157.751724,795.187012 156.889648,796.073975 
	C154.583450,798.446777 154.167725,801.025269 154.600525,804.164429 
	C152.573273,804.313232 150.933380,804.318909 149.248871,803.908325 
	C148.367584,802.913025 147.530914,802.334045 146.694244,801.755005 
	C146.614975,801.609436 146.485535,801.471069 146.463577,801.317322 
	C144.964447,790.818298 145.155884,790.648499 158.301849,791.226685 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M135.903442,815.522949 
	C133.514511,815.544678 131.139969,815.294922 128.374084,814.934631 
	C125.950577,814.194641 123.918404,813.565186 121.490051,812.917358 
	C121.101471,811.858154 121.109085,810.817444 121.114105,809.384399 
	C121.431633,808.606384 121.751755,808.220581 122.157761,807.591003 
	C126.583092,807.183899 130.922531,807.020508 135.613297,806.969971 
	C136.206223,807.324646 136.447815,807.566589 136.890411,808.304504 
	C136.686203,810.588440 136.280975,812.376343 135.875748,814.164307 
	C135.889771,814.526672 135.903793,814.889099 135.903442,815.522949 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M149.293488,804.324585 
	C150.933380,804.318909 152.573273,804.313232 155.023010,804.233765 
	C157.297211,802.915222 158.761581,801.670471 160.225952,800.425781 
	C159.947861,799.982666 159.669754,799.539551 159.391647,799.096436 
	C161.558594,798.848999 163.725555,798.601562 165.892502,798.354187 
	C165.888840,799.017029 165.882629,799.679932 165.881943,800.342773 
	C165.875122,806.930847 165.865448,807.161133 159.399384,806.882080 
	C155.807083,806.727112 152.254486,805.652222 148.321930,804.794434 
	C147.959854,804.596985 147.952240,804.559692 148.301270,804.535278 
	C148.864670,804.448792 149.079071,804.386658 149.293488,804.324585 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M126.726868,765.068726 
	C127.416756,767.719177 127.893288,770.559326 131.797913,770.851440 
	C129.658203,771.997803 127.309822,772.829224 124.218391,773.923645 
	C124.218391,770.407959 124.218391,768.008606 124.500992,765.335327 
	C124.980598,764.997803 125.177620,764.934082 125.859192,764.941284 
	C126.343742,765.012268 126.726868,765.068726 126.726868,765.068726 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M149.248871,803.908325 
	C149.079071,804.386658 148.864670,804.448792 148.315613,804.522217 
	C147.513260,803.888123 147.045609,803.242737 146.636108,802.176147 
	C147.530914,802.334045 148.367584,802.913025 149.248871,803.908325 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M130.745224,757.095947 
	C132.736954,756.903870 134.494690,756.903870 137.448441,756.903870 
	C135.323776,759.510437 133.789108,761.393188 132.116852,763.575073 
	C131.548462,763.921021 131.117691,763.967712 130.353302,763.547119 
	C129.965042,761.710327 129.910400,760.340820 129.855774,758.971313 
	C130.074280,758.410217 130.292755,757.849121 130.745224,757.095947 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M129.536987,758.984619 
	C129.910400,760.340820 129.965042,761.710327 130.030884,763.490479 
	C129.188843,764.271484 128.335617,764.641907 127.104630,765.040527 
	C126.726868,765.068726 126.343742,765.012268 126.169472,764.927002 
	C126.228195,764.605042 126.461189,764.368225 127.191116,763.783569 
	C127.772781,761.969299 127.857513,760.502808 127.942245,759.036316 
	C128.367554,759.023499 128.792877,759.010681 129.536987,758.984619 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M127.706436,759.022827 
	C127.857513,760.502808 127.772781,761.969299 127.422302,763.680786 
	C127.111542,762.557800 127.066551,761.189941 127.009987,759.409790 
	C126.998413,758.997559 127.470634,759.009277 127.706436,759.022827 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M374.940277,103.947929 
	C375.490601,102.968330 376.040894,101.988731 377.118835,100.069954 
	C377.971191,105.735191 378.626740,110.092506 379.282318,114.449829 
	C379.759369,114.378860 380.236450,114.307884 380.713501,114.236916 
	C379.574066,116.023254 378.434631,117.809601 377.331726,119.538628 
	C376.706085,112.373787 369.053650,118.262939 365.784180,113.333565 
	C367.536041,111.459496 369.474365,109.385963 371.706055,107.156960 
	C371.999420,107.001488 371.977875,106.983063 371.974213,107.350739 
	C371.216370,109.395164 370.462189,111.071930 369.217072,113.840195 
	C372.345642,112.990585 376.050232,112.893936 376.466522,111.674606 
	C377.238068,109.414810 378.759430,105.888603 374.997314,104.001633 
	C374.979675,103.982330 374.940277,103.947929 374.940277,103.947929 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M373.955231,104.962036 
	C374.125214,104.680756 374.295197,104.399483 374.702698,104.033066 
	C374.940277,103.947929 374.979675,103.982330 374.900085,104.229111 
	C374.620148,104.760925 374.349243,104.938263 373.995422,104.996460 
	C373.982971,104.985008 373.955231,104.962029 373.955231,104.962036 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M372.972870,105.977859 
	C373.140533,105.694542 373.308197,105.411224 373.715546,105.044968 
	C373.955231,104.962029 373.982971,104.985008 373.900024,105.226379 
	C373.616608,105.756119 373.343842,105.933121 372.994812,105.995628 
	C372.990875,105.992493 372.972870,105.977859 372.972870,105.977859 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M372.008606,107.012962 
	C372.179169,106.736389 372.340546,106.448341 372.737427,106.069077 
	C372.972870,105.977859 372.990875,105.992493 372.895447,106.223953 
	C372.595459,106.739586 372.321411,106.915466 371.977875,106.983063 
	C371.977875,106.983063 371.999420,107.001488 372.008606,107.012962 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1014.312988,419.033203 
	C1014.507385,418.709595 1015.022583,418.380127 1015.864380,418.031372 
	C1015.671875,418.350494 1015.152771,418.688904 1014.312988,419.033203 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M972.296143,453.909119 
	C972.011414,453.627319 972.021179,453.178345 972.054688,452.395111 
	C972.570496,451.750519 973.062500,451.440186 973.811279,450.983551 
	C973.575562,451.805481 973.083130,452.773712 972.296143,453.909119 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M975.287842,450.042908 
	C975.740356,449.685974 976.542847,449.340118 977.691528,449.003723 
	C977.237793,449.360107 976.437805,449.707062 975.287842,450.042908 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1554.239014,881.458618 
	C1555.838379,880.454529 1557.427246,879.835510 1559.751221,878.930054 
	C1557.547607,876.946960 1556.282104,875.808228 1555.355713,874.120544 
	C1559.507935,873.501282 1563.332275,873.584839 1567.130615,873.310547 
	C1570.021851,873.101746 1570.989380,874.558044 1571.231445,877.268677 
	C1571.216187,877.508179 1571.505615,877.890930 1571.505615,877.890930 
	C1571.505615,877.890930 1571.985107,877.873718 1572.200439,877.984009 
	C1572.415649,878.094238 1572.842896,878.320984 1572.822266,878.412720 
	C1572.801636,878.504517 1572.915283,878.654297 1572.915283,878.654297 
	C1569.651978,885.720520 1563.227783,883.505188 1557.709229,883.673645 
	C1556.568726,883.708435 1555.389771,882.486328 1554.239014,881.458618 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1573.153076,878.958618 
	C1572.915283,878.654297 1572.801636,878.504517 1573.171387,878.361450 
	C1574.073975,877.558533 1574.606567,876.898743 1575.427490,876.183228 
	C1576.106079,876.109314 1576.496582,876.091064 1577.119751,876.356323 
	C1579.330811,876.052734 1581.309082,875.465698 1582.328369,875.163269 
	C1585.540649,875.862915 1588.038330,876.673706 1590.587524,876.897034 
	C1594.057739,877.201172 1597.567139,877.056946 1601.059814,877.104980 
	C1601.308960,877.549927 1601.558228,877.994873 1601.926514,878.666382 
	C1598.572144,878.977051 1595.073730,878.841431 1591.634644,879.229980 
	C1589.670410,879.451904 1586.741577,880.034546 1586.137329,881.333801 
	C1585.204346,883.340027 1585.891602,886.099731 1585.891602,889.033569 
	C1579.659424,889.429321 1585.586304,880.101257 1580.117554,879.604675 
	C1577.875366,879.490784 1575.633057,879.376892 1573.153076,878.958618 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1610.217407,889.179688 
	C1607.440308,894.853943 1604.945190,890.950500 1602.742676,888.839539 
	C1605.155396,888.619568 1605.869019,882.739807 1610.088379,886.842163 
	C1610.185913,888.088989 1610.201660,888.634338 1610.217407,889.179688 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1610.601318,889.112427 
	C1610.201660,888.634338 1610.185913,888.088989 1610.265259,887.143677 
	C1612.013550,886.865356 1613.666748,886.986877 1615.737183,887.161987 
	C1616.154419,887.215454 1616.138794,887.627136 1616.067627,887.820557 
	C1614.326050,888.357788 1612.655640,888.701477 1610.601318,889.112427 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1605.302612,878.919983 
	C1605.079834,878.545349 1605.219971,878.259827 1605.876221,877.500122 
	C1607.634644,877.083984 1608.877197,877.141907 1610.119751,877.199829 
	C1610.098145,877.435303 1610.076416,877.670776 1609.933838,878.242798 
	C1608.430420,878.722534 1607.047974,878.865784 1605.302612,878.919983 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1618.711670,881.204834 
	C1618.834717,880.359802 1619.257935,879.433411 1619.849609,878.798035 
	C1619.682617,879.767151 1619.347290,880.445312 1618.711670,881.204834 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1525.333252,894.838806 
	C1524.014282,891.708740 1523.139160,888.721252 1521.897217,885.656982 
	C1521.547852,885.243652 1521.565308,884.907166 1522.040527,884.419922 
	C1522.958252,883.632996 1523.417969,882.996765 1523.877808,882.360596 
	C1523.877808,882.360596 1523.979004,882.127625 1524.469971,881.973633 
	C1528.145264,881.898560 1531.329468,881.977539 1534.513794,882.056580 
	C1534.513794,882.056580 1534.531982,882.098206 1534.513550,882.115967 
	C1534.623901,883.430359 1534.857178,884.726440 1534.864624,886.023804 
	C1534.917603,895.162354 1534.902832,895.162415 1525.333252,894.838806 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1531.062256,899.177246 
	C1531.169312,902.906677 1529.437134,904.922058 1524.721313,904.008057 
	C1526.572510,902.356934 1527.984253,901.097839 1529.373169,899.524902 
	C1529.730835,899.189819 1530.111206,899.168640 1531.062256,899.177246 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1538.779175,880.846558 
	C1538.858398,880.682312 1539.288330,880.581299 1539.777832,880.740112 
	C1539.601562,880.969910 1539.365723,880.939880 1538.779175,880.846558 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1548.084229,882.175049 
	C1548.257568,882.193420 1548.424683,882.618896 1548.368896,883.219055 
	C1548.123291,883.123230 1548.100586,882.852722 1548.084229,882.175049 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1582.592041,901.096313 
	C1584.831421,901.152222 1586.820801,900.956665 1589.681641,900.675415 
	C1587.635986,907.750732 1581.420776,902.670776 1578.383667,905.178101 
	C1579.291626,900.034546 1579.292358,900.033691 1582.592041,901.096313 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1706.499146,885.947021 
	C1706.803223,886.964722 1706.721680,888.051025 1706.269287,889.069214 
	C1705.898560,889.001160 1705.998901,888.985962 1705.855591,888.867676 
	C1705.581787,888.465088 1705.400879,888.217712 1705.169556,888.007263 
	C1700.988159,893.608398 1695.365479,891.994080 1689.706909,891.022278 
	C1694.480957,886.775330 1698.922607,881.972900 1706.499146,885.947021 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1705.170898,888.276550 
	C1705.400879,888.217712 1705.581787,888.465088 1705.875977,888.914124 
	C1705.750610,888.901123 1705.461548,888.723511 1705.170898,888.276550 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1093.367798,667.267029 
	C1092.469971,664.368408 1091.824707,661.577454 1091.093750,658.398438 
	C1091.077881,656.658386 1091.147705,655.306274 1091.268799,653.551758 
	C1099.261963,654.867249 1101.156494,656.566406 1101.020386,661.634949 
	C1100.911865,665.677551 1098.633911,667.835205 1094.664673,668.892822 
	C1094.084961,668.358032 1093.852661,667.866394 1093.367798,667.267029 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1092.447632,607.413696 
	C1092.909790,620.060852 1092.986938,632.688904 1093.008911,645.317078 
	C1093.010254,646.067322 1092.567505,646.818420 1091.988525,647.662964 
	C1091.351562,634.932129 1091.039062,622.107788 1090.819946,609.281860 
	C1090.809448,608.659973 1091.621460,608.024048 1092.447632,607.413696 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1185.095703,654.731750 
	C1187.482300,654.282532 1189.758301,654.134827 1192.866333,653.995422 
	C1197.131470,655.001465 1200.564575,655.999207 1203.998779,656.998474 
	C1204.000000,657.000000 1204.003662,657.002563 1204.098145,657.342529 
	C1205.975708,658.674011 1208.210083,660.902222 1209.464111,660.444458 
	C1215.097290,658.388062 1219.800659,650.183777 1218.737915,644.564453 
	C1217.860107,639.922852 1216.878540,635.716003 1224.226685,634.893372 
	C1217.393311,634.613037 1217.817261,630.345581 1218.006958,626.159485 
	C1218.126953,623.511597 1218.104248,620.857239 1218.145630,618.205750 
	C1227.425049,618.003601 1236.705811,617.660706 1245.982422,617.751709 
	C1247.368896,617.765381 1248.735352,619.815979 1250.281616,621.061646 
	C1250.531372,620.417725 1251.072144,619.023376 1251.796509,617.155640 
	C1255.826050,617.155640 1259.949463,617.155640 1264.875732,617.288025 
	C1267.094482,617.665344 1268.510254,617.910339 1270.927246,618.328430 
	C1270.285767,624.689026 1269.638672,630.798584 1269.085083,636.916626 
	C1268.999634,637.859619 1269.190186,638.897644 1269.519287,639.793945 
	C1270.296631,641.910400 1271.234375,643.968018 1271.845215,646.263794 
	C1266.746216,651.062561 1268.910645,656.607178 1268.865356,662.338989 
	C1272.240723,662.338989 1275.030396,662.237488 1277.809204,662.367798 
	C1280.520874,662.495117 1281.835571,661.482300 1281.631348,658.659058 
	C1281.524414,657.182068 1281.517334,655.679565 1281.656738,654.206726 
	C1281.994385,650.638977 1281.057739,648.612244 1276.859619,648.783264 
	C1275.567383,648.835876 1274.198608,647.012634 1272.967529,645.706726 
	C1273.938477,644.357605 1275.083862,643.476318 1275.613281,642.314331 
	C1276.521973,640.319580 1277.046387,638.149658 1277.899292,635.542297 
	C1277.706177,635.402405 1276.760376,634.717590 1275.203125,633.589966 
	C1278.115845,632.606445 1280.279663,631.875793 1281.812866,631.358093 
	C1279.727051,629.693542 1276.415649,628.324402 1276.034180,626.380371 
	C1275.669312,624.520325 1277.940674,621.576843 1279.807495,620.005371 
	C1281.650635,618.453735 1284.382080,617.470459 1286.823730,617.222839 
	C1291.273071,616.771606 1295.796143,617.034058 1300.288452,617.025330 
	C1307.052979,617.012207 1313.817383,617.021912 1320.747803,617.021912 
	C1320.992188,620.655212 1321.200195,623.747986 1321.408203,626.840759 
	C1321.915649,626.820557 1322.422974,626.800293 1322.930420,626.780090 
	C1322.930420,623.666992 1322.930420,620.553894 1322.930420,617.561768 
	C1328.997803,616.558411 1329.590088,616.944580 1330.107788,621.712708 
	C1330.783447,627.932983 1334.113647,629.461426 1340.472046,626.162720 
	C1337.569214,625.997070 1334.968262,625.848633 1332.447388,625.704834 
	C1331.366455,618.842407 1333.038818,616.854919 1339.585449,616.868469 
	C1351.394897,616.893005 1363.204224,616.937378 1375.019775,617.418396 
	C1375.329590,628.044678 1375.832520,638.225952 1375.836182,648.407349 
	C1375.837524,652.317139 1377.287231,653.122803 1380.806152,653.045654 
	C1389.424072,652.856628 1398.049561,653.014221 1406.717529,653.464722 
	C1406.732788,655.913269 1406.702515,657.929504 1406.269775,659.943054 
	C1404.888672,660.935730 1403.202881,661.845947 1403.070923,662.943115 
	C1402.740845,665.686157 1403.115723,668.514160 1403.349487,671.634399 
	C1405.870117,672.590698 1408.227539,673.610657 1410.633667,673.742676 
	C1413.115601,673.878906 1416.776001,675.127380 1416.763184,670.359558 
	C1416.741333,662.216370 1417.339966,660.129456 1411.724609,659.774292 
	C1411.371460,656.641113 1411.232666,653.828369 1411.383545,651.078125 
	C1412.391479,651.367249 1413.109619,651.593872 1414.296631,651.894775 
	C1416.908447,651.990356 1419.070312,651.838135 1421.189941,652.070618 
	C1425.111694,652.500793 1426.320557,650.948853 1426.187622,647.028198 
	C1425.846802,636.980469 1425.920898,626.918701 1426.142090,616.603210 
	C1429.212158,616.339600 1431.969360,616.335388 1434.731934,616.410034 
	C1434.737305,616.488953 1434.720337,616.646179 1434.503906,616.897644 
	C1435.281250,617.381897 1436.350464,617.961670 1437.250854,617.765564 
	C1438.573853,617.477539 1439.766357,616.141113 1441.031860,616.115479 
	C1446.339233,616.007935 1451.653320,616.222290 1456.973145,616.689209 
	C1456.979370,618.304810 1456.977539,619.550049 1456.734741,621.062439 
	C1455.901733,623.143555 1455.309570,624.957397 1454.717407,626.771301 
	C1456.486572,626.985718 1458.277466,627.480835 1460.019531,627.345703 
	C1462.369629,627.163513 1464.682007,626.496277 1467.074463,626.400391 
	C1469.435425,627.506653 1471.732544,628.249512 1474.312500,629.277100 
	C1474.750610,632.000854 1474.905884,634.439880 1475.092285,637.369263 
	C1479.222290,637.369263 1483.174805,637.369263 1487.783325,637.369263 
	C1487.509521,634.096558 1487.295776,631.542603 1487.183472,628.633667 
	C1488.740479,624.844055 1490.332275,621.654785 1495.067139,623.970581 
	C1495.139038,623.939514 1495.073608,624.176208 1495.073608,624.176208 
	C1495.073608,624.176208 1495.056519,624.015076 1494.651123,623.995605 
	C1493.742310,622.667542 1493.238892,621.359070 1493.138428,619.964722 
	C1495.681030,618.894043 1497.820679,617.909180 1500.404663,616.719849 
	C1500.710571,617.003052 1501.707642,617.926025 1502.704712,618.848999 
	C1502.747681,618.313538 1502.790649,617.778076 1502.833618,617.242615 
	C1506.301147,616.851624 1510.114624,615.392395 1513.138672,616.371399 
	C1516.135620,617.341736 1518.337036,620.769653 1520.886475,623.511230 
	C1520.875122,624.163696 1520.864624,624.424683 1520.518799,624.516663 
	C1518.521240,625.294495 1516.858887,626.241272 1515.196533,627.187988 
	C1515.785645,628.210815 1516.290283,630.011475 1516.982300,630.086609 
	C1518.242188,630.223511 1519.613159,629.336121 1521.226562,628.872314 
	C1521.824951,629.093506 1522.134644,629.318420 1522.467896,629.973877 
	C1522.519775,635.870422 1522.548096,641.336487 1522.139648,646.857666 
	C1501.470459,647.160889 1481.238159,647.408875 1461.043213,647.374634 
	C1461.092407,646.523254 1461.104248,645.954041 1461.572876,645.311707 
	C1467.653320,645.148987 1473.276733,645.016479 1478.900635,644.986633 
	C1482.110107,644.969604 1483.453979,643.785217 1482.416016,640.887024 
	C1480.122437,641.328979 1478.172607,641.940796 1476.197876,642.035522 
	C1472.234009,642.225586 1468.224121,641.844666 1464.298828,642.284363 
	C1462.454346,642.490967 1460.761597,644.052490 1458.990356,645.348083 
	C1458.680908,647.913452 1458.380981,650.130737 1457.947510,653.335449 
	C1461.106079,652.792969 1463.595093,652.365479 1466.546265,651.976074 
	C1473.625000,652.098389 1480.241455,652.182617 1486.900024,652.538269 
	C1486.938110,653.175781 1486.934204,653.541870 1486.566406,653.950928 
	C1481.432373,655.843750 1476.126465,667.048401 1477.115601,672.673584 
	C1477.725830,676.143799 1477.867554,679.696350 1478.193970,682.954407 
	C1475.645508,683.358276 1473.813965,683.648560 1471.982544,683.938782 
	C1472.006104,684.229553 1472.029541,684.520264 1472.052979,684.810974 
	C1474.075195,684.930115 1476.097534,685.049255 1478.119751,685.168396 
	C1478.104248,685.359192 1478.088623,685.550049 1478.072998,685.740845 
	C1459.782349,685.740845 1441.491699,685.740845 1423.201050,685.740845 
	C1423.198364,685.402283 1423.195557,685.063782 1423.192749,684.725220 
	C1431.319580,684.725220 1439.446411,684.725220 1447.573120,684.725220 
	C1447.565430,684.208740 1447.557739,683.692261 1447.549927,683.175781 
	C1437.862305,683.175781 1428.173584,683.083740 1418.487305,683.196594 
	C1402.565674,683.381958 1386.645142,683.879944 1370.724976,683.849304 
	C1366.137817,683.840515 1364.902466,685.566467 1365.107544,689.692505 
	C1365.501221,697.610901 1365.775635,705.537659 1365.942383,713.464294 
	C1366.193970,725.420471 1366.136719,725.421692 1378.527100,724.897461 
	C1381.826416,724.747559 1384.742676,724.418884 1387.668091,724.280640 
	C1388.375977,724.247192 1389.113525,724.842407 1389.780029,725.478760 
	C1389.389404,727.106567 1389.056030,728.404236 1388.272827,729.726440 
	C1380.218384,729.848389 1372.614014,729.945740 1364.554199,729.971313 
	C1358.785156,729.276062 1353.471313,728.653320 1348.157837,728.027466 
	C1347.766479,727.981323 1347.179199,727.717407 1347.016846,727.882629 
	C1342.975220,731.995422 1338.366699,729.805969 1333.949463,728.662842 
	C1334.103149,726.272278 1334.202759,724.335327 1334.309692,722.256226 
	C1338.226807,721.928772 1341.699707,721.638489 1345.172485,721.348206 
	C1345.144897,720.859436 1345.117310,720.370605 1345.089844,719.881836 
	C1341.573975,719.881836 1338.058105,719.881836 1333.949585,719.881836 
	C1333.949585,714.528503 1333.949585,709.754150 1334.233398,704.698975 
	C1337.816650,703.622925 1341.098633,702.744324 1344.419556,702.052307 
	C1349.228271,701.050232 1353.458496,695.614624 1352.881836,691.007385 
	C1352.331665,686.611389 1346.383179,683.557983 1340.967041,683.645813 
	C1336.530273,683.717712 1330.759766,683.722900 1327.960449,681.159119 
	C1324.688965,678.162842 1321.107910,677.343079 1317.708008,675.427002 
	C1310.294067,671.248718 1303.487915,672.038696 1296.733887,675.812073 
	C1294.661255,676.970032 1293.567993,679.881042 1291.842651,682.310730 
	C1289.717651,683.393616 1287.812866,684.672119 1285.827637,684.812195 
	C1281.247070,685.135559 1276.627319,684.886169 1272.023682,684.905457 
	C1259.882080,684.956299 1247.740112,685.002808 1235.599121,685.120728 
	C1234.175171,685.134521 1232.318481,685.096741 1231.407471,685.913757 
	C1226.762695,690.078979 1222.362915,694.517456 1217.877441,698.860229 
	C1215.999878,699.674805 1213.790161,700.104309 1212.311157,701.380676 
	C1208.794067,704.415771 1205.683472,707.916992 1202.276978,711.088318 
	C1201.175293,712.113892 1199.663452,712.698975 1198.121338,713.260620 
	C1195.776001,707.798035 1193.569336,702.493164 1195.662598,696.767090 
	C1196.999146,693.110962 1199.005249,689.702942 1200.425293,686.071777 
	C1200.808594,685.091431 1200.548096,683.403809 1199.906250,682.549316 
	C1194.229736,674.991211 1189.785400,666.421143 1182.061401,660.514404 
	C1181.590332,660.154236 1181.385376,659.446228 1180.954346,658.730225 
	C1182.378296,657.424133 1183.681641,656.228699 1185.095703,654.731750 
M1499.328613,626.500854 
	C1500.010864,626.602844 1500.693237,626.704773 1501.991699,627.086060 
	C1503.285156,627.372498 1504.578613,627.658936 1506.349609,628.531677 
	C1506.094971,631.672791 1508.241333,631.923340 1510.226318,631.170105 
	C1511.702759,630.609985 1513.753052,629.043762 1513.791626,627.868164 
	C1513.836792,626.483337 1511.987427,625.036621 1510.959717,623.616699 
	C1509.763306,624.650757 1508.568481,625.686707 1507.369873,626.718079 
	C1506.853271,627.162537 1506.328735,627.597595 1505.099121,628.038696 
	C1503.828857,627.621033 1502.558594,627.203308 1500.620239,626.998840 
	C1500.072876,626.654419 1499.525391,626.309998 1498.716309,625.991455 
	C1498.834595,625.949524 1498.952759,625.907593 1499.328613,626.500854 
M1293.393066,652.225037 
	C1291.989380,652.755127 1290.585693,653.285217 1289.181885,653.815308 
	C1288.963867,653.499451 1288.745850,653.183655 1288.527710,652.867798 
	C1289.645264,651.485046 1290.762817,650.102295 1291.880371,648.719482 
	C1288.473145,648.911865 1285.389893,650.014526 1286.204468,653.616272 
	C1286.932251,656.833679 1289.822632,656.150330 1292.341309,655.164062 
	C1293.831909,654.580444 1295.451782,654.326721 1297.722534,654.170288 
	C1298.929077,654.432983 1300.135498,654.695679 1301.355225,654.961182 
	C1301.083496,652.622253 1300.850586,650.616455 1300.577393,648.263855 
	C1297.615845,648.231934 1293.640503,645.916992 1293.393066,652.225037 
M1432.799438,663.627563 
	C1433.963745,664.241394 1435.128052,664.855286 1436.667114,665.666748 
	C1436.360962,662.981384 1436.140869,661.051453 1435.829102,658.316895 
	C1433.719727,658.975098 1431.907593,659.540649 1429.639282,660.598206 
	C1428.076172,661.844910 1426.513062,663.091614 1424.949951,664.338318 
	C1424.616699,663.900452 1424.283447,663.462646 1423.950073,663.024780 
	C1425.234741,661.862976 1426.519287,660.701172 1427.803833,659.539368 
	C1427.448853,659.060120 1427.093750,658.580872 1426.738647,658.101685 
	C1425.175903,659.260193 1423.159302,660.140869 1422.217163,661.679382 
	C1421.634888,662.630432 1422.476685,665.787903 1423.040894,665.886536 
	C1426.321533,666.460388 1429.681763,666.331116 1432.799438,663.627563 
M1316.542114,658.672058 
	C1316.344727,649.429565 1316.197876,640.185364 1315.900879,630.946106 
	C1315.850586,629.383972 1315.155029,627.842651 1314.757446,626.291687 
	C1314.549194,626.427063 1314.340820,626.562500 1314.132568,626.697876 
	C1314.132568,637.186890 1314.132568,647.675842 1313.580566,658.672791 
	C1311.893188,659.176514 1310.232666,659.970642 1308.513428,660.128601 
	C1303.665527,660.574036 1298.793579,660.758240 1293.575928,661.066345 
	C1292.452148,660.528076 1289.997314,658.445129 1288.954956,659.002686 
	C1282.843628,662.271912 1289.119751,662.406006 1290.852783,663.955078 
	C1289.353516,664.615662 1288.182251,665.131775 1285.858643,666.155518 
	C1288.018799,666.606873 1289.206543,667.254028 1289.742310,666.903809 
	C1294.019653,664.108582 1298.183472,665.199463 1302.437988,666.853333 
	C1303.145752,667.128418 1304.110962,667.020630 1304.881104,666.810669 
	C1310.310669,665.330139 1313.263672,666.542786 1315.179932,671.134644 
	C1315.793579,669.071228 1316.407227,667.007812 1317.302246,665.537231 
	C1319.082886,664.636658 1320.863525,663.736023 1322.644287,662.835510 
	C1324.075439,665.768372 1326.155273,664.281128 1328.229736,663.817566 
	C1329.003052,663.644775 1329.992920,664.441589 1330.979126,664.834106 
	C1331.109863,662.991577 1331.239990,661.159424 1331.430664,658.474304 
	C1329.444458,659.829041 1328.395996,660.544128 1327.347656,661.259155 
	C1327.355103,660.844788 1327.362671,660.430481 1327.370117,660.016113 
	C1323.982178,659.761292 1320.594116,659.506409 1316.542114,658.672058 
M1427.815918,675.093018 
	C1430.498535,675.262878 1433.816772,674.492615 1435.736450,675.790649 
	C1440.277344,678.860962 1445.076050,674.379944 1449.377930,677.010193 
	C1449.591064,677.140564 1450.396973,676.301575 1451.614258,676.132568 
	C1452.878540,675.472473 1454.142944,674.812378 1456.464478,673.600403 
	C1456.920898,673.948059 1458.425537,675.094543 1459.123291,675.626221 
	C1461.996094,675.626221 1464.368774,675.626221 1466.807617,675.626221 
	C1466.807617,673.992310 1466.807617,671.789612 1466.807617,668.753723 
	C1465.178467,670.143127 1464.149780,671.020569 1463.331543,671.718445 
	C1461.254883,670.732239 1459.559448,669.780762 1457.761475,669.100647 
	C1452.439453,667.087402 1452.198120,672.415833 1449.111572,674.908630 
	C1448.140259,674.844421 1447.168945,674.780212 1445.306885,674.657043 
	C1447.319336,672.836853 1448.574463,671.701660 1450.917725,669.582275 
	C1442.603882,670.828308 1435.648315,671.870850 1428.634155,672.922119 
	C1428.539307,672.772095 1428.420654,672.438232 1428.187012,672.239258 
	C1427.023804,671.248047 1425.925171,670.094116 1424.585571,669.454041 
	C1424.218872,669.278809 1423.097778,670.682739 1422.322876,671.362061 
	C1423.447754,672.122559 1424.595825,672.851379 1425.686890,673.657776 
	C1426.179932,674.022156 1426.558960,674.540710 1426.210938,674.964539 
	C1424.868530,675.186340 1423.526123,675.408142 1421.226318,675.788208 
	C1424.328491,677.828430 1426.100464,678.282410 1427.815918,675.093018 
M1439.513062,632.411072 
	C1437.800781,633.330444 1434.489746,633.602661 1436.802490,636.594177 
	C1437.874146,637.980347 1440.262085,638.818115 1442.143921,639.008423 
	C1445.750854,639.373108 1449.534180,638.534729 1453.038818,639.230530 
	C1457.408569,640.098022 1464.788330,635.949463 1465.036255,631.537598 
	C1463.120361,631.537598 1461.223877,631.537598 1459.222778,631.537598 
	C1458.893311,634.800232 1458.443848,637.683044 1453.646606,636.357361 
	C1455.133179,634.774902 1456.229858,633.607422 1458.282715,631.422241 
	C1455.260986,632.223511 1452.897461,633.581116 1452.067627,632.943359 
	C1448.562622,630.248840 1446.393188,632.293457 1444.548828,634.284851 
	C1442.793579,633.379089 1441.389526,632.654602 1439.513062,632.411072 
M1290.479248,635.061951 
	C1292.236938,633.989990 1293.994507,632.918030 1295.844849,631.789612 
	C1295.933228,631.899475 1296.291504,632.780029 1296.747681,632.833984 
	C1298.282349,633.015320 1300.265747,633.472900 1301.285156,632.733032 
	C1302.206421,632.064331 1302.088867,629.964172 1302.429321,628.495178 
	C1300.853882,628.443970 1299.276855,628.329102 1297.703735,628.369690 
	C1296.971436,628.388611 1295.878418,629.111572 1295.573853,628.832764 
	C1290.194702,623.908325 1288.594116,630.594177 1283.782471,631.623230 
	C1286.677856,633.263123 1288.203247,634.127075 1290.479248,635.061951 
M1348.435181,630.685120 
	C1349.721802,629.716858 1351.008545,628.748596 1351.636841,628.275757 
	C1353.926636,628.492676 1356.108032,629.136292 1358.002441,628.653198 
	C1358.711792,628.472290 1358.752441,625.668701 1359.097900,624.060852 
	C1357.110840,624.060852 1355.123901,624.060852 1353.136841,624.060852 
	C1353.142090,623.988647 1353.147339,623.916382 1353.152588,623.844177 
	C1349.883057,623.649536 1346.613647,623.454834 1343.275024,623.256104 
	C1343.275024,625.740356 1343.275024,627.526062 1343.275024,629.291931 
	C1344.844238,629.807068 1346.228394,630.261414 1348.435181,630.685120 
M1236.794189,634.027588 
	C1236.024170,632.680420 1235.254150,631.333252 1234.484131,629.986084 
	C1233.242676,630.909668 1231.664795,631.618713 1230.886841,632.838013 
	C1230.427246,633.558472 1231.233643,635.086426 1231.470703,636.251282 
	C1233.110474,635.726746 1234.750366,635.202271 1236.794189,634.027588 
M1460.601440,684.862549 
	C1461.176880,684.750305 1461.752319,684.638062 1462.327759,684.525818 
	C1462.295044,684.380493 1462.262329,684.235168 1462.229614,684.089844 
	C1458.471680,684.089844 1454.713745,684.089844 1450.955688,684.089844 
	C1450.961792,684.362976 1450.967896,684.636108 1450.973877,684.909241 
	C1453.920288,684.909241 1456.866699,684.909241 1460.601440,684.862549 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1194.327393,682.001221 
	C1193.914185,681.658325 1193.726685,681.398010 1193.234863,681.091675 
	C1192.203857,680.070374 1191.391479,679.156921 1190.299805,678.112305 
	C1189.924927,677.654968 1189.739990,677.391479 1189.245850,677.082764 
	C1188.937012,676.645691 1188.754639,676.381287 1188.258789,676.075439 
	C1187.998535,675.554932 1187.958618,675.140259 1187.954590,674.364624 
	C1187.990601,674.003662 1188.087646,673.898376 1188.087646,673.898376 
	C1190.643677,676.382141 1193.199829,678.865967 1195.870117,681.683960 
	C1195.984253,682.018250 1195.884277,682.110779 1195.884277,682.110779 
	C1195.469116,682.081238 1195.053955,682.051758 1194.327393,682.001221 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1176.339355,663.940063 
	C1175.925171,663.641418 1175.736694,663.383179 1175.247314,663.078186 
	C1174.926147,662.644592 1174.732544,662.386169 1174.221924,662.099731 
	C1173.035645,660.722290 1172.076050,659.440491 1171.116577,658.158691 
	C1171.515259,657.891479 1171.914062,657.624207 1172.312744,657.356934 
	C1173.757935,659.544556 1175.203003,661.732239 1176.339355,663.940063 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1196.035889,681.973938 
	C1196.899536,682.703552 1197.711426,683.477478 1198.523315,684.251404 
	C1198.284546,684.471313 1198.045776,684.691223 1197.807129,684.911133 
	C1197.203369,684.179871 1196.599731,683.448547 1195.940186,682.414062 
	C1195.884277,682.110779 1195.984253,682.018250 1196.035889,681.973938 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1187.937988,674.052246 
	C1187.567749,673.993835 1187.303467,673.810120 1187.039429,673.276306 
	C1186.986328,673.002808 1187.103638,672.875916 1187.103638,672.875916 
	C1187.419434,672.993103 1187.673340,673.190063 1187.976440,673.682617 
	C1188.087646,673.898376 1187.990601,674.003662 1187.937988,674.052246 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1184.047607,669.965210 
	C1184.210205,670.109253 1184.317383,670.305054 1184.424683,670.500793 
	C1184.307373,670.468750 1184.190063,670.436707 1183.981934,670.267090 
	C1183.891235,670.129456 1183.992432,670.016968 1184.047607,669.965210 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1186.922729,673.061279 
	C1186.756836,672.910461 1186.654663,672.701050 1186.552490,672.491638 
	C1186.671997,672.526550 1186.791504,672.561462 1187.007324,672.736145 
	C1187.103638,672.875916 1186.986328,673.002808 1186.922729,673.061279 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1732.457764,875.983948 
	C1730.368652,875.871704 1728.736328,875.728271 1727.119751,875.481384 
	C1724.188477,875.033508 1721.266602,874.524048 1717.664429,873.928467 
	C1716.481567,874.478394 1714.314209,875.486206 1711.438110,876.823547 
	C1711.091553,872.884033 1710.843506,870.064758 1710.595581,867.245544 
	C1710.071289,867.211548 1709.546997,867.177490 1709.022705,867.143494 
	C1708.703125,869.813660 1708.383667,872.483826 1707.931152,876.264587 
	C1702.699097,876.264587 1697.285522,876.264587 1691.417236,876.391235 
	C1683.912231,876.517883 1676.862061,876.517883 1670.493286,876.517883 
	C1670.634277,874.195984 1670.761108,872.108093 1670.887817,870.020142 
	C1669.221680,870.415161 1667.555664,870.810120 1665.437866,871.087585 
	C1664.549194,870.676941 1664.112061,870.383850 1663.675049,870.090698 
	C1662.094971,869.837036 1660.515015,869.583313 1658.430420,868.843567 
	C1657.773804,867.977234 1657.621704,867.596924 1657.469482,867.216675 
	C1657.513428,865.401062 1657.557251,863.585571 1657.703613,861.383789 
	C1658.476318,860.747009 1659.146606,860.496521 1659.999512,859.922302 
	C1670.628906,859.869629 1681.074829,860.268921 1691.523071,860.349548 
	C1695.798218,860.382507 1700.079346,859.489197 1704.356689,859.497620 
	C1706.122437,859.501099 1707.885742,860.767151 1709.219971,861.290283 
	C1711.634888,860.552795 1714.126587,859.183105 1716.650513,859.121094 
	C1731.791626,858.748840 1746.940063,858.677368 1762.585571,858.976807 
	C1763.787720,860.823303 1764.490356,862.198120 1765.192993,863.572876 
	C1764.922852,863.985107 1764.634521,864.386780 1764.385254,864.811279 
	C1757.017212,877.360229 1744.885986,876.395996 1732.457764,875.983948 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1611.356689,873.703125 
	C1611.555786,872.533997 1611.360229,871.026672 1612.022339,870.254578 
	C1614.772461,867.047241 1617.630371,863.891663 1620.782227,861.092590 
	C1621.800293,860.188416 1623.839966,860.434570 1625.787842,860.662415 
	C1626.399414,864.064575 1626.635254,866.958801 1626.846191,870.141235 
	C1626.848267,870.619873 1626.875488,870.810425 1626.675781,871.235718 
	C1626.658203,872.647522 1626.867554,873.824707 1627.076904,875.001892 
	C1625.023560,875.661255 1622.970093,876.320679 1620.505859,876.583496 
	C1619.677002,874.175781 1618.953125,873.099304 1617.178467,875.232178 
	C1615.979492,875.864685 1614.780640,876.497192 1613.162354,876.809204 
	C1612.280884,875.560120 1611.818726,874.631653 1611.356689,873.703125 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1647.996460,866.023193 
	C1650.239014,866.393250 1652.481445,866.763245 1655.072266,867.190796 
	C1654.651489,869.148865 1654.285400,870.852600 1653.945435,872.434875 
	C1650.450317,872.434875 1647.131226,872.751221 1643.924805,872.287781 
	C1642.533569,872.086670 1641.374268,870.280090 1640.589844,868.625488 
	C1642.624268,864.792542 1645.560669,866.264038 1647.996460,866.023193 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1630.983154,859.987122 
	C1634.366089,859.864197 1637.749146,859.741211 1641.590210,859.550171 
	C1642.243164,860.028320 1642.438110,860.574585 1642.359131,861.792725 
	C1640.705322,863.952576 1639.325317,865.440552 1637.945312,866.928528 
	C1637.225586,867.059998 1636.505981,867.191406 1635.021240,867.244141 
	C1633.431519,867.175537 1632.606812,867.185608 1631.790527,866.790161 
	C1631.526855,864.252197 1631.255005,862.119690 1630.983154,859.987122 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1638.152222,867.243286 
	C1639.325317,865.440552 1640.705322,863.952576 1642.365234,862.242188 
	C1643.635864,860.782654 1644.546265,859.247314 1645.444824,862.168091 
	C1645.676392,862.920715 1646.755371,863.412537 1647.690674,864.233643 
	C1647.936401,864.443054 1648.065308,864.911926 1648.065308,864.911926 
	C1648.057739,865.097412 1648.050171,865.282837 1648.019531,865.745728 
	C1645.560669,866.264038 1642.624268,864.792542 1640.753052,868.255127 
	C1639.743774,868.160645 1639.051514,867.859314 1638.152222,867.243286 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1630.635254,859.967529 
	C1631.255005,862.119690 1631.526855,864.252197 1631.643677,866.911804 
	C1631.275757,867.593872 1631.115234,867.791443 1631.007080,868.031555 
	C1630.553101,867.976257 1630.099121,867.920959 1629.298828,867.493103 
	C1628.952393,865.415161 1628.952393,863.709839 1628.952393,862.004456 
	C1628.511353,862.054504 1628.070312,862.104553 1627.629150,862.154602 
	C1628.073242,864.087158 1628.517456,866.019714 1628.961548,867.952271 
	C1628.668457,868.438049 1628.375366,868.923767 1628.040527,869.704956 
	C1627.998901,870.000488 1627.981689,870.013123 1627.708740,869.941040 
	C1627.247681,869.863770 1627.059448,869.858459 1626.871216,869.853088 
	C1626.635254,866.958801 1626.399414,864.064575 1626.233032,860.687012 
	C1627.630859,860.118408 1628.959106,860.033142 1630.635254,859.967529 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1653.833984,875.812195 
	C1647.969238,876.238770 1642.405518,876.238770 1636.841919,876.238770 
	C1636.631470,875.908630 1636.421143,875.578491 1636.210815,875.248352 
	C1637.187256,874.391052 1638.145386,872.824036 1639.143433,872.798218 
	C1643.689453,872.680908 1648.251465,872.930054 1652.793091,873.252502 
	C1653.288452,873.287598 1653.689941,874.642700 1653.833984,875.812195 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1657.017334,867.293335 
	C1657.621704,867.596924 1657.773804,867.977234 1658.084961,868.951965 
	C1659.354126,870.358093 1660.464478,871.169861 1662.111572,872.374023 
	C1660.308350,873.578430 1658.450073,874.819580 1655.505371,876.786316 
	C1655.946411,872.867737 1656.255859,870.118835 1657.017334,867.293335 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1627.404785,874.938232 
	C1626.867554,873.824707 1626.658203,872.647522 1626.949341,871.176636 
	C1627.627197,870.592957 1627.804443,870.303040 1627.981689,870.013184 
	C1627.981689,870.013123 1627.998901,870.000488 1628.007568,869.994568 
	C1628.966553,870.504517 1629.916992,871.020447 1631.414185,871.833130 
	C1629.905884,873.079163 1628.819336,873.976929 1627.404785,874.938232 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1648.449219,864.902954 
	C1648.065308,864.911926 1647.936401,864.443054 1648.044189,864.225037 
	C1648.721680,863.546570 1649.291260,863.086121 1650.257080,862.241699 
	C1651.103271,861.891174 1651.553589,861.924622 1652.003784,861.958069 
	C1652.472168,862.757935 1652.940552,863.557800 1653.723022,864.893921 
	C1651.862427,864.893921 1650.347778,864.893921 1648.449219,864.902954 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1610.384766,877.183228 
	C1608.877197,877.141907 1607.634644,877.083984 1606.113770,877.086304 
	C1607.290894,876.443481 1608.746338,875.740295 1610.612305,875.479370 
	C1611.075562,876.333191 1611.128052,876.744812 1611.180420,877.156433 
	C1611.003540,877.159851 1610.826538,877.163269 1610.384766,877.183228 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1631.139404,868.321289 
	C1631.115234,867.791443 1631.275757,867.593872 1631.635498,867.317261 
	C1632.606812,867.185608 1633.431519,867.175537 1634.652100,867.275146 
	C1634.435669,869.783997 1633.424194,870.954529 1631.139404,868.321289 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1663.715454,870.467285 
	C1664.112061,870.383850 1664.549194,870.676941 1665.175415,871.329834 
	C1665.248657,873.081055 1665.132568,874.472534 1665.016479,875.863953 
	C1664.596313,875.868469 1664.176025,875.873047 1663.755859,875.877563 
	C1663.755859,874.199646 1663.755859,872.521790 1663.715454,870.467285 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1617.401855,875.447754 
	C1618.953125,873.099304 1619.677002,874.175781 1620.098633,876.540771 
	C1619.276611,876.484192 1618.450928,876.073792 1617.401855,875.447754 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1611.451660,877.220093 
	C1611.128052,876.744812 1611.075562,876.333191 1610.988037,875.612000 
	C1611.020874,875.036255 1611.088867,874.770020 1611.256836,874.103455 
	C1611.818726,874.631653 1612.280884,875.560120 1612.782715,876.824829 
	C1612.455933,877.201904 1612.089355,877.242798 1611.451660,877.220093 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1652.003052,861.591248 
	C1651.553589,861.924622 1651.103271,861.891174 1650.330933,861.967712 
	C1650.360474,861.161011 1650.712158,860.244324 1651.063965,859.327637 
	C1651.376831,859.959900 1651.689575,860.592163 1652.003052,861.591248 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1632.969727,876.323853 
	C1632.401367,875.585754 1632.349243,874.843079 1632.297119,874.100342 
	C1632.728394,874.093567 1633.159668,874.086731 1633.590942,874.079956 
	C1633.555908,874.826355 1633.520874,875.572815 1632.969727,876.323853 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M963.997559,465.998779 
	C963.678040,467.276093 963.358582,468.553375 962.889465,470.428711 
	C961.502808,468.429596 960.655212,467.207581 959.264404,465.202545 
	C961.074707,465.534851 962.203186,465.741974 963.665833,465.974548 
	C964.000000,466.000000 963.997559,465.998779 963.997559,465.998779 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M964.000000,466.000610 
	C964.092651,465.968323 964.185303,465.935455 964.137695,465.950684 
	C963.997559,465.998779 964.000000,466.000000 964.000000,466.000610 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M59.464325,1220.254272 
	C59.432755,1225.314331 58.111156,1226.402832 53.507267,1224.918701 
	C55.207241,1224.097778 56.457127,1223.494263 56.974949,1223.244263 
	C55.854099,1221.561035 54.805721,1220.181763 54.093163,1218.646729 
	C54.012470,1218.473022 56.237133,1216.679688 56.674732,1216.924072 
	C57.744556,1217.521118 58.393681,1218.872070 59.464325,1220.254272 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M59.976097,1191.007812 
	C59.761765,1194.042236 59.571331,1197.068481 59.365505,1200.339478 
	C56.829418,1199.306152 54.970436,1198.548706 51.913639,1197.303101 
	C54.805645,1195.043457 57.100044,1193.250732 59.688786,1191.252563 
	C59.983124,1191.046997 59.999992,1190.999634 59.976097,1191.007812 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M53.685440,1122.716797 
	C54.791168,1120.634033 56.268723,1118.702393 58.115372,1116.288086 
	C56.149406,1116.288086 54.789139,1116.288086 53.430325,1116.286621 
	C55.491451,1112.609253 59.027924,1112.696289 59.139206,1116.437378 
	C59.194778,1118.305542 58.787930,1120.187500 58.530815,1122.627075 
	C57.199032,1122.698608 55.628151,1122.783203 53.685440,1122.716797 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M475.208862,649.625854 
	C474.805267,640.767151 474.205475,631.911072 474.086609,623.048584 
	C474.032806,619.037292 473.758972,616.073730 468.626465,616.841370 
	C468.089722,616.921631 467.452606,616.331055 466.238922,615.755249 
	C464.089294,612.382690 462.318207,612.356934 461.500488,614.947693 
	C461.775269,618.631775 462.050049,622.315857 462.334076,626.124207 
	C459.281494,626.528931 457.073700,626.821655 454.852814,627.116089 
	C454.750824,629.017822 454.671631,630.494446 454.592407,631.971130 
	C448.371582,632.236206 453.293213,637.982849 450.624603,640.140015 
	C449.889618,640.734192 449.940918,642.301025 449.631470,643.421570 
	C448.975555,643.348328 448.319641,643.275085 447.663727,643.201904 
	C447.663727,633.449219 447.663727,623.696533 447.604797,613.189575 
	C447.818634,612.008667 448.091461,611.581909 448.717865,610.978760 
	C452.727722,610.517090 456.384033,610.231812 460.037811,609.966248 
	C460.035309,609.985962 460.065399,609.963806 460.137390,610.269653 
	C460.784241,611.130554 461.359131,611.685547 462.260773,612.556152 
	C463.084229,612.659119 463.580933,612.446472 464.369873,612.083008 
	C468.697449,610.039246 465.390808,608.908630 464.277496,607.372192 
	C464.391724,606.921021 464.367126,606.661621 464.594421,606.285767 
	C466.468811,604.688477 468.043060,603.235596 469.571411,601.735901 
	C474.511932,596.887878 476.617706,597.105164 479.789062,603.162170 
	C480.488159,601.485291 481.087433,600.047974 481.613434,598.786377 
	C488.291718,598.409241 494.719055,598.046204 501.523834,597.840454 
	C504.496490,598.928711 507.091705,599.859741 509.709381,600.767273 
	C509.731812,600.743835 509.662140,600.785767 509.541687,601.165771 
	C510.558746,602.191040 511.696259,602.836304 513.260864,603.654785 
	C515.503784,603.723267 517.319641,603.618408 519.465332,603.580811 
	C520.520447,602.769775 521.245789,601.891541 522.064453,600.569702 
	C522.163452,597.885193 522.520447,595.564270 522.035339,593.435059 
	C521.768433,592.263306 520.085205,591.414185 518.680237,590.314941 
	C516.846252,590.309692 515.372559,590.409180 513.478516,590.473389 
	C509.424652,589.963684 508.720184,592.049744 508.613831,595.141663 
	C505.747955,595.452209 503.309967,595.653809 500.412750,595.894653 
	C493.872375,595.933838 487.791199,595.933838 481.300964,595.933838 
	C482.121674,594.809753 482.633240,594.109131 483.772552,592.548706 
	C481.685486,592.752563 479.570648,592.603577 479.449005,593.027100 
	C478.432159,596.566528 475.615570,595.863037 473.095154,596.324341 
	C471.794128,596.562500 470.631866,597.853699 469.528168,598.809204 
	C467.665039,600.422241 465.898285,602.146667 464.064880,603.446045 
	C463.640381,602.572205 463.242035,602.076660 462.545898,601.731079 
	C461.586121,602.230774 460.545929,603.042847 460.320557,602.858521 
	C458.112030,601.052307 456.039276,599.080078 453.622711,596.974365 
	C451.381927,596.707886 449.446350,596.622192 446.681793,596.499695 
	C447.382477,593.253601 448.045990,590.179626 448.709503,587.105713 
	C452.325317,587.112183 455.941132,587.118591 459.783997,587.450745 
	C460.939850,591.982544 461.868652,596.188660 463.199860,600.391418 
	C463.663910,599.620605 463.725555,598.853149 463.865387,597.650146 
	C463.831238,583.458069 463.718872,569.701416 463.606567,555.944763 
	C465.822418,552.674438 468.038239,549.404053 470.587250,545.712524 
	C471.175293,545.112671 471.380676,544.888672 471.911957,544.546631 
	C474.084198,544.390503 475.881104,544.307007 477.764221,544.557800 
	C478.157227,546.017212 478.464020,547.142456 478.898712,548.736755 
	C479.891296,546.743835 480.540253,545.440857 481.494354,544.131775 
	C482.201843,544.068726 482.604218,544.011719 483.345490,544.176147 
	C489.455994,544.239258 495.227570,544.081055 501.401428,544.052246 
	C505.667847,544.213440 508.938507,545.152100 510.310089,549.745483 
	C512.814148,549.953979 515.020264,550.209717 517.114868,549.837585 
	C518.789185,549.540222 521.660400,548.540466 521.697021,547.746338 
	C521.858948,544.230347 522.402039,539.503418 520.454712,537.489380 
	C518.754517,535.730896 513.840637,536.605835 510.494476,537.179626 
	C509.382599,537.370300 508.729401,540.235962 507.494171,541.817139 
	C505.007935,541.781006 502.893982,541.806396 500.326477,541.864319 
	C494.213928,542.070007 488.554901,542.243225 482.550354,542.352722 
	C481.742035,542.220886 481.279205,542.152710 480.877625,541.760071 
	C481.501709,540.671692 482.064636,539.907715 482.627533,539.143799 
	C482.242035,538.765076 481.856537,538.386353 481.471039,538.007629 
	C480.322784,539.343201 479.174530,540.678833 477.625946,542.118286 
	C475.060791,542.723877 472.895935,543.225586 470.501465,543.822510 
	C469.984344,544.115906 469.801331,544.385193 469.722839,544.725464 
	C468.068512,546.083679 466.414185,547.441895 464.027283,549.401611 
	C464.027283,540.168030 463.645630,531.509705 464.137695,522.901245 
	C464.602814,514.764465 462.235718,506.617981 465.367310,497.932404 
	C466.678436,500.038544 467.989563,502.144684 469.154266,504.015594 
	C472.517181,504.717499 476.132965,505.472168 479.517792,506.635681 
	C477.183655,507.308594 475.030731,507.996918 472.986694,507.757050 
	C466.926178,507.045898 465.896912,508.357147 468.316040,514.880615 
	C476.833923,514.880615 485.482117,514.880615 494.130310,514.880615 
	C498.579315,516.764221 503.028320,518.647888 507.763855,521.130737 
	C508.039856,521.993652 508.029266,522.257446 507.640869,522.392456 
	C504.556396,522.313110 501.849701,522.362549 498.700745,522.294617 
	C493.690308,521.308167 490.251556,522.285095 489.446716,527.572144 
	C492.543640,527.400269 495.677765,527.439819 498.714233,526.914856 
	C499.620667,526.758118 500.253876,525.020874 501.364014,524.128601 
	C502.275452,526.026123 502.835175,527.802734 503.551422,530.076233 
	C506.258759,529.674011 509.101135,529.251770 512.264954,528.958008 
	C515.488403,531.109924 517.985718,531.666870 519.458984,527.182861 
	C523.302002,528.889343 526.698120,530.964600 530.341003,532.424072 
	C533.636169,533.744263 534.754883,538.628845 539.318054,537.723999 
	C539.531250,537.681763 540.101562,538.407471 540.170227,538.829956 
	C540.827148,542.873413 541.411743,546.928650 541.812134,551.233276 
	C541.005981,551.710632 540.404785,551.935242 539.498291,552.070190 
	C536.059021,548.241272 534.615540,554.016602 531.785645,552.956055 
	C531.738831,550.233765 531.917236,547.812073 532.200256,543.970093 
	C530.445435,546.953918 529.399048,548.733215 528.318604,550.570312 
	C526.976807,550.158752 525.494507,549.704102 524.153137,549.292664 
	C525.060486,552.138306 525.894592,554.754272 526.459351,556.525452 
	C528.243286,556.001831 530.165771,555.437561 532.256714,555.189758 
	C531.173767,558.303162 532.028259,559.882629 534.912659,561.109741 
	C537.549255,562.231506 539.855103,564.130493 542.165527,566.152344 
	C542.024292,570.442993 542.024292,574.268250 542.024292,578.172363 
	C539.300903,577.239075 537.220642,576.526184 533.883911,575.382751 
	C536.267334,577.931335 537.685913,579.448242 538.805054,581.272705 
	C530.858093,581.852844 523.210571,582.125427 515.563110,582.398010 
	C523.619629,585.581421 532.237549,581.757629 540.359436,585.968018 
	C541.199036,585.912903 541.679199,585.887573 542.161377,586.256958 
	C542.217285,587.700134 542.271301,588.748474 542.180786,590.224609 
	C542.010071,592.441345 541.983765,594.230347 541.947327,596.486816 
	C541.937195,616.829224 541.937195,636.704163 541.937195,656.653320 
	C534.575134,657.840820 529.044556,661.061157 525.216553,667.062927 
	C524.466858,668.238281 522.984802,668.946472 521.664490,669.876404 
	C521.488403,669.882629 521.136108,669.876709 520.774902,669.853394 
	C519.933594,669.877625 519.453613,669.925110 518.654663,670.005249 
	C518.125732,670.077881 517.915649,670.117920 517.338928,670.079895 
	C516.014648,670.195862 515.056946,670.389832 513.947266,670.226807 
	C512.504822,670.010620 511.214447,670.151428 509.616394,670.193054 
	C508.891815,669.984375 508.474945,669.874878 507.972961,669.436096 
	C507.145050,668.248718 506.402283,667.390625 505.702698,666.383057 
	C505.745911,666.233643 505.915588,665.972900 506.145447,665.889221 
	C506.674683,665.643799 506.887939,665.405090 507.329224,665.074280 
	C508.060669,665.021423 508.477875,664.983704 509.265381,664.917236 
	C513.227417,662.160278 509.955017,660.118713 509.190979,657.538818 
	C509.611389,654.104492 510.052246,650.960266 509.796295,647.873840 
	C509.694000,646.640320 507.862457,644.428589 507.147461,644.553101 
	C503.113983,645.255737 497.535736,641.486633 495.346680,648.228638 
	C495.197449,648.688232 493.854614,649.117004 493.175293,648.988220 
	C489.602814,648.311035 485.455078,651.860901 482.323883,647.523193 
	C482.070984,647.172791 480.738708,647.386841 480.010925,647.637451 
	C478.375854,648.200439 476.805542,648.951416 475.208862,649.625854 
M482.225311,527.925842 
	C477.818329,525.717224 473.411377,523.508606 469.004395,521.300049 
	C468.795105,521.705688 468.585815,522.111328 468.376526,522.516968 
	C473.194366,525.562988 478.012207,528.609009 481.796356,531.001526 
	C487.706482,533.438965 492.618347,535.464783 497.530212,537.490601 
	C497.606384,537.296875 497.682526,537.103149 497.758698,536.909424 
	C493.612457,533.706177 489.466187,530.502991 484.651093,527.258362 
	C484.058380,527.567505 483.465668,527.876648 482.225311,527.925842 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M508.142334,491.617279 
	C509.161499,492.783997 510.180664,493.950714 511.583527,495.019104 
	C514.036621,495.012817 516.106018,495.104828 518.888367,495.138306 
	C519.993774,495.107422 520.386292,495.135101 520.842896,495.617035 
	C520.963196,503.535675 521.104675,511.001312 521.000366,518.463501 
	C520.980469,519.884705 519.989075,521.292297 519.049561,522.507568 
	C516.654541,522.199280 514.655701,522.089661 512.656921,521.980042 
	C510.627777,520.587463 508.598663,519.194824 506.577637,517.346680 
	C512.792786,516.105286 511.833496,509.992950 515.402039,506.633301 
	C511.483459,506.814117 508.597473,506.947266 505.711517,507.080414 
	C506.191071,506.814331 506.670624,506.548248 507.150146,506.282166 
	C506.851227,503.449036 506.552307,500.615875 506.253418,497.782745 
	C503.565399,498.175140 500.877380,498.567535 497.737946,499.025848 
	C497.504608,498.683228 496.592041,497.343170 496.039978,496.532562 
	C493.824677,496.716400 492.115601,496.820282 490.415436,497.005981 
	C483.900085,497.717682 477.387634,498.455688 470.874084,499.183899 
	C469.468414,498.341461 468.062744,497.498993 465.223694,495.797485 
	C468.814789,493.648895 471.522339,491.536713 474.593048,490.465118 
	C475.634003,490.101807 477.592285,492.367004 478.121094,492.732574 
	C481.031342,491.801636 483.544250,490.572693 486.167755,490.254028 
	C490.566010,489.719879 495.034698,489.765747 499.737976,489.312256 
	C501.670929,489.483276 503.339661,489.909576 505.532837,490.505676 
	C506.752319,490.989410 507.447327,491.303345 508.142334,491.617279 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M457.101959,517.038391 
	C453.787048,513.410278 450.517609,509.739258 447.144562,506.166046 
	C443.720306,502.538483 440.125610,499.069885 436.757202,495.392761 
	C435.848206,494.400452 435.402954,492.931915 434.974121,491.607727 
	C434.911346,491.413818 436.452667,490.310486 436.699951,490.472137 
	C437.898895,491.255859 438.909668,492.327484 439.966614,493.278656 
	C441.750000,490.519287 449.300476,488.595093 452.393463,490.824097 
	C455.459991,493.034058 459.789734,495.729370 460.308075,498.767609 
	C461.362366,504.947052 460.222382,511.500824 459.296875,517.878723 
	C458.105377,517.584229 457.603668,517.311340 457.101959,517.038391 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M471.108887,499.448181 
	C477.387634,498.455688 483.900085,497.717682 490.415436,497.005981 
	C492.115601,496.820282 493.824677,496.716400 496.039978,496.532562 
	C496.592041,497.343170 497.504608,498.683228 497.737946,499.025848 
	C500.877380,498.567535 503.565399,498.175140 506.253418,497.782745 
	C506.552307,500.615875 506.851227,503.449036 507.150146,506.282166 
	C506.670624,506.548248 506.191071,506.814331 505.711517,507.080414 
	C508.597473,506.947266 511.483459,506.814117 515.402039,506.633301 
	C511.833496,509.992950 512.792786,516.105286 506.221558,517.198608 
	C505.551758,517.319519 505.246063,517.132874 505.026520,516.299561 
	C505.092224,509.077240 502.627167,505.468781 497.136536,504.439362 
	C497.136536,507.247040 497.136536,509.991577 497.136536,512.736084 
	C496.339233,511.539062 495.541931,510.342041 494.963684,508.464050 
	C493.900085,505.498047 492.617401,503.213013 491.385834,501.018982 
	C490.756256,503.040314 490.125488,505.065460 489.494751,507.090576 
	C487.630280,506.389587 485.765778,505.688599 484.265350,504.519470 
	C487.959595,503.373657 487.959595,503.373657 486.954651,500.289307 
	C485.819916,501.596313 484.714020,502.870148 483.608124,504.143982 
	C481.083740,503.398987 478.528503,502.742462 476.045959,501.877411 
	C474.424011,501.312225 472.907440,500.444611 471.108887,499.448181 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M434.017548,517.006287 
	C439.542358,516.101074 445.072266,515.149414 450.212097,519.195923 
	C449.088867,520.479248 447.850708,521.345398 445.600403,522.919617 
	C451.744446,522.919617 456.326508,522.919617 460.908600,522.919617 
	C460.983917,523.307983 461.059204,523.696289 460.859009,524.472534 
	C460.388397,524.903748 460.193237,524.947083 459.998047,524.990479 
	C446.547607,524.990479 433.097168,524.990479 419.646729,524.990479 
	C419.632111,524.307068 419.617493,523.623718 419.602875,522.940308 
	C422.775085,522.940308 425.947266,522.940308 429.804810,522.859985 
	C430.803589,522.620667 430.945374,522.365967 430.915497,522.015686 
	C431.533508,520.176880 432.151520,518.338074 432.973450,517.126221 
	C433.177399,519.190857 433.177399,520.628479 433.177399,523.004517 
	C434.934937,521.947449 436.053345,521.274780 437.171783,520.602112 
	C436.285767,519.860962 435.352905,519.166931 434.537567,518.354858 
	C434.225800,518.044312 434.182953,517.463745 434.017548,517.006287 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M494.046570,514.537720 
	C485.482117,514.880615 476.833923,514.880615 468.316040,514.880615 
	C465.896912,508.357147 466.926178,507.045898 472.986694,507.757050 
	C475.030731,507.996918 477.183655,507.308594 479.705414,506.995483 
	C480.123993,506.946442 480.068634,506.992828 480.050232,507.327026 
	C481.039520,508.106567 482.047211,508.551910 483.054871,508.997253 
	C486.690857,510.729767 490.326843,512.462280 494.046570,514.537720 
M473.465149,512.664429 
	C473.465149,512.664429 473.576874,512.439941 473.576874,512.439941 
	C473.576874,512.439941 473.340881,512.483337 473.465149,512.664429 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M463.338562,555.949036 
	C463.718872,569.701416 463.831238,583.458069 463.640533,597.876831 
	C463.157501,599.157471 462.977478,599.776123 462.797485,600.394714 
	C461.868652,596.188660 460.939850,591.982544 460.276672,587.530029 
	C460.992889,585.855469 461.824280,584.429443 461.831757,582.999084 
	C461.877716,574.239502 461.737030,565.479065 461.828979,556.359436 
	C462.356476,555.984436 462.713531,555.968872 463.338562,555.949036 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M497.457672,512.893677 
	C497.136536,509.991577 497.136536,507.247040 497.136536,504.439362 
	C502.627167,505.468781 505.092224,509.077240 505.032990,516.107178 
	C502.561798,515.391296 500.170319,514.221313 497.457672,512.893677 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M512.252808,521.995239 
	C514.655701,522.089661 516.654541,522.199280 518.930542,522.779663 
	C518.333374,524.275818 517.458923,525.301331 516.434082,526.503174 
	C517.614990,526.846252 518.367310,527.064880 519.119629,527.283447 
	C517.985718,531.666870 515.488403,531.109924 512.281738,528.634521 
	C511.608185,527.486816 511.239258,526.791077 510.893616,526.072937 
	C510.916931,526.050537 510.932983,526.112427 510.843109,525.839966 
	C510.418365,525.413513 510.083496,525.259583 509.635132,525.151733 
	C509.521698,525.197815 509.277344,525.181885 508.999207,525.014160 
	C508.486908,524.071411 508.252777,523.296326 508.018646,522.521240 
	C508.029266,522.257446 508.039856,521.993652 508.050171,521.333862 
	C509.316223,521.295349 510.582458,521.652832 512.252808,521.995239 
M515.329346,523.901245 
	C515.384155,523.849426 515.438965,523.797668 515.329346,523.901245 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M460.838074,522.596680 
	C456.326508,522.919617 451.744446,522.919617 445.600403,522.919617 
	C447.850708,521.345398 449.088867,520.479248 450.505646,519.368469 
	C452.078094,518.647461 453.471924,518.171021 454.938477,517.317444 
	C455.475250,516.970703 455.939362,517.001099 456.752686,517.034912 
	C457.603668,517.311340 458.105377,517.584229 458.872864,517.964111 
	C459.681580,519.471985 460.224579,520.872864 460.838074,522.596680 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M489.628784,507.477905 
	C490.125488,505.065460 490.756256,503.040314 491.385834,501.018982 
	C492.617401,503.213013 493.900085,505.498047 495.066406,508.132324 
	C493.141815,508.942505 491.171417,510.768005 489.628784,507.477905 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M433.288544,485.937256 
	C432.970734,484.806274 433.027863,483.750214 433.085541,482.296204 
	C435.175873,483.685364 437.265656,485.472504 439.478912,487.365234 
	C435.894470,491.176483 435.312775,486.920074 433.288544,485.937256 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M482.968658,508.660034 
	C482.047211,508.551910 481.039520,508.106567 480.034241,507.294739 
	C480.985260,507.393097 481.933838,507.857971 482.968658,508.660034 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M443.207520,508.768860 
	C442.948059,508.147980 443.028687,507.745331 443.314941,506.756409 
	C444.210968,506.934662 444.901428,507.699249 445.591919,508.463806 
	C444.910492,508.638245 444.229095,508.812714 443.207520,508.768860 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M460.976379,547.991516 
	C460.976379,547.991516 461.376709,547.893799 461.579224,547.858032 
	C461.798553,548.607605 461.815399,549.392822 461.832214,550.178101 
	C461.302948,549.973328 460.773682,549.768555 460.368958,549.202881 
	C460.654480,548.558533 460.815430,548.275024 460.976379,547.991516 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M441.324585,542.411987 
	C441.206390,541.937073 441.461517,541.549194 442.024658,540.988403 
	C442.811249,541.045654 443.289917,541.275879 443.768555,541.506104 
	C443.078339,541.837097 442.388123,542.168030 441.324585,542.411987 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M460.608215,548.010498 
	C460.815430,548.275024 460.654480,548.558533 460.249298,548.917847 
	C459.892242,548.864929 459.779388,548.736145 459.587494,548.363770 
	C459.752289,548.089966 459.996185,548.059692 460.608215,548.010498 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M460.138458,525.234741 
	C460.193237,524.947083 460.388397,524.903748 460.869995,524.769409 
	C461.217133,524.900391 461.277802,525.122498 461.293091,525.687256 
	C460.924744,525.846252 460.601807,525.662659 460.138458,525.234741 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M446.733856,509.687653 
	C446.415436,509.679169 446.341736,509.446381 446.268036,509.213562 
	C446.504883,509.296814 446.741760,509.380066 446.733856,509.687653 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M550.887146,705.363892 
	C550.789490,698.607117 551.969360,691.680115 547.903870,684.867310 
	C547.558594,683.351562 547.537903,682.377197 547.701111,680.926880 
	C547.903198,669.577148 547.861145,658.702698 547.993652,647.830444 
	C548.018311,645.807678 548.744751,643.793518 549.439514,641.944092 
	C551.559937,642.869446 553.452148,644.744202 555.206665,642.130371 
	C557.035583,644.541077 558.877808,644.457764 559.963867,642.151489 
	C560.521240,640.968079 559.907898,639.233398 559.823608,637.747803 
	C558.241516,638.573303 556.659363,639.398743 554.768311,640.013062 
	C553.733154,635.871765 551.458984,636.757080 548.724731,637.786377 
	C548.263977,625.267944 548.115601,613.026123 548.033508,600.783875 
	C548.029846,600.244202 548.631409,599.700378 549.071411,599.211548 
	C549.191772,599.264587 549.449402,599.207397 549.663330,599.565552 
	C560.418823,602.992981 564.832764,601.318909 569.563293,592.457397 
	C572.527100,586.905457 569.183044,577.699524 562.685425,574.355896 
	C558.285278,574.197205 554.252380,574.216675 550.083801,574.096985 
	C549.948120,573.957825 549.670593,573.685608 549.686279,573.327332 
	C550.052856,571.588501 550.403748,570.207886 551.020508,568.573914 
	C553.288391,565.121887 555.447205,567.436829 557.262146,568.372986 
	C570.724792,575.317688 584.104797,582.422241 597.544006,589.412537 
	C601.060242,591.241577 604.693665,592.845337 608.273254,594.552490 
	C608.530090,594.018555 608.786926,593.484619 609.043762,592.950684 
	C595.953857,586.113647 582.863953,579.276611 568.798218,571.929932 
	C574.566406,567.428894 576.475525,562.336121 575.985046,555.842896 
	C575.384338,547.889099 575.699890,539.853699 575.900513,531.860657 
	C575.949463,529.909058 577.095032,527.984863 578.031006,525.885620 
	C578.892700,525.798279 579.948730,525.847900 579.954224,525.952393 
	C580.053650,527.852417 580.026794,529.759094 580.026794,531.779541 
	C586.715576,532.213135 592.553833,532.591614 598.083069,532.950073 
	C597.506592,530.274780 597.072266,528.258972 596.675293,526.416504 
	C602.009033,524.044861 600.168274,529.981140 602.628784,531.115540 
	C603.184082,529.533691 603.666504,528.159424 604.603271,525.491150 
	C604.957275,528.424377 605.142517,529.959778 605.339478,531.591797 
	C609.131226,531.591797 612.639526,531.686096 616.138611,531.547180 
	C617.617065,531.488403 619.097473,531.003906 620.518433,530.535217 
	C620.710205,530.472046 620.558533,529.367249 620.565857,528.744751 
	C619.209656,528.835266 617.853394,528.925781 614.916138,529.121887 
	C617.138916,527.106812 618.358826,526.000854 620.524658,524.037415 
	C618.032043,524.282532 616.165710,523.907654 615.150085,524.668457 
	C611.824036,527.160034 609.140259,524.856384 606.194519,524.062012 
	C603.137268,523.237610 600.058105,522.252808 596.932800,521.982788 
	C592.843079,521.629395 588.688538,521.734802 584.581177,521.973755 
	C581.612549,522.146484 580.630188,521.006226 581.096252,518.240295 
	C581.494751,518.117615 581.893250,517.994995 582.864502,518.174805 
	C584.070251,518.642456 584.703430,518.952637 585.336060,518.951660 
	C602.951660,518.925293 620.567139,518.867371 638.182678,518.814636 
	C638.489807,521.543091 637.835693,525.143066 639.331970,526.793274 
	C641.512817,529.198425 645.195374,531.388855 648.319946,531.507629 
	C652.444275,531.664551 655.419434,528.497192 656.577087,523.524292 
	C657.720459,524.393555 658.973999,525.715637 659.014587,527.073975 
	C659.219482,533.936829 659.108765,540.809021 659.108765,547.735046 
	C656.750366,547.853088 654.880798,547.946716 652.947754,547.669434 
	C652.225708,543.535706 649.434814,542.736572 646.329102,543.049133 
	C643.187622,543.365295 642.689880,545.820679 642.571228,548.629395 
	C640.705383,549.156738 639.188782,549.556213 637.672119,549.955688 
	C637.293030,549.358337 636.913940,548.760925 636.534790,548.163513 
	C638.284241,547.430847 640.033630,546.698181 642.637756,545.607544 
	C637.720764,545.104126 634.888428,544.612366 632.050171,544.575439 
	C628.634888,544.530884 625.066284,544.339600 621.848083,545.233948 
	C619.679993,545.836426 618.416870,546.484558 616.410461,544.762756 
	C615.559143,544.032227 613.175537,545.087341 611.488342,545.330872 
	C611.550842,545.778381 611.613342,546.225891 611.675903,546.673401 
	C612.969971,546.894348 614.264099,547.115234 616.215332,547.448364 
	C614.402588,549.346558 613.064819,550.747437 611.809509,552.061890 
	C618.493896,552.061890 625.246338,552.061890 631.997925,552.438171 
	C632.013184,553.567566 632.029175,554.320679 631.903809,555.398438 
	C631.819885,556.295044 631.877319,556.867065 631.508179,557.603027 
	C628.344482,558.132874 625.607361,558.498657 622.495972,558.925293 
	C617.878540,560.778687 612.278015,560.632019 611.861572,568.052673 
	C610.091919,564.852356 609.506592,562.332031 607.944214,561.320007 
	C605.743103,559.894165 602.818115,559.585938 599.947815,558.519836 
	C598.456909,558.218567 597.220825,558.197266 595.815186,557.999390 
	C595.117554,558.030151 594.589417,558.237366 593.698364,558.432617 
	C593.107056,558.340515 592.878601,558.260315 592.581482,557.789551 
	C591.624451,556.210266 590.843201,554.116333 589.828308,553.996216 
	C586.266846,553.574768 582.624939,553.832764 578.858276,553.832764 
	C578.726135,555.400757 578.568787,556.372375 578.573730,557.343201 
	C578.635925,569.545105 577.437134,566.526794 588.931885,566.728516 
	C589.992493,566.747131 591.094360,564.410889 592.452881,562.911011 
	C595.153442,561.981445 597.578186,561.308960 600.255981,560.879639 
	C602.200500,562.424927 604.046570,563.578552 605.509460,565.100403 
	C606.116943,565.732361 605.906250,567.150818 606.067627,568.211609 
	C605.038330,568.215027 603.928894,568.467407 602.997070,568.167786 
	C601.286133,567.617432 599.679749,566.741821 598.029785,566.001831 
	C597.768127,566.518677 597.506409,567.035522 597.244751,567.552368 
	C600.070007,569.087830 602.774353,570.939087 605.751099,572.078979 
	C609.479980,573.506958 610.064819,575.971741 610.534180,579.667603 
	C611.124817,584.317932 609.125732,588.417114 609.757690,593.326843 
	C611.244080,604.875000 610.233765,616.744629 610.233765,627.653687 
	C607.551819,627.515503 605.210999,627.394958 602.521362,627.172363 
	C598.895203,626.515381 595.617859,625.960388 592.448120,625.250732 
	C592.619690,624.806763 592.520813,624.593018 592.301147,624.082764 
	C591.618652,622.938416 590.894165,622.166199 589.731323,621.249878 
	C586.354309,621.105835 583.415771,621.105835 581.007141,621.105835 
	C581.007141,624.105042 581.007141,626.623657 580.667175,629.265991 
	C579.853882,630.798767 579.380554,632.207886 578.722656,634.166382 
	C581.875244,634.166382 584.306458,634.245667 586.729553,634.139954 
	C588.486389,634.063293 591.634460,634.061768 591.767273,633.479858 
	C593.135803,627.485657 598.108215,629.094849 601.873169,628.616699 
	C599.724365,632.893799 597.609070,636.819824 595.235107,641.225891 
	C596.543701,640.506287 598.302612,639.927246 599.525635,638.777893 
	C601.494141,636.927979 603.108887,634.701599 605.207397,632.546021 
	C606.056763,634.330566 606.571777,636.203308 607.129517,638.231262 
	C607.929016,637.652344 608.888062,636.957886 610.594727,635.722046 
	C610.594727,641.272766 610.594727,646.007141 610.324585,650.740234 
	C609.716858,650.857056 609.379211,650.975159 608.588013,651.074829 
	C605.323975,651.012451 601.484924,652.114441 599.906738,650.697815 
	C596.039062,647.226196 593.897766,649.265747 591.240601,652.432251 
	C590.576538,650.689453 590.118164,649.486389 589.605835,648.141968 
	C587.646362,649.605103 586.021545,650.818359 584.307129,652.098572 
	C583.709534,650.694153 583.328430,649.798584 582.496216,647.842834 
	C582.496216,651.133240 582.496216,653.161255 582.496216,654.769287 
	C587.185974,654.769287 591.528198,654.769287 596.619324,654.769287 
	C599.801025,651.611267 605.675354,651.122192 610.950562,654.641357 
	C611.420837,655.464478 611.803101,655.904541 612.185303,656.344604 
	C612.453796,655.556519 612.722351,654.768433 613.322632,653.827026 
	C614.584412,653.284973 615.514404,652.896301 616.444397,652.507629 
	C615.365784,651.930298 614.287109,651.352966 613.196045,650.776123 
	C613.183655,650.776550 613.208252,650.758484 613.178101,650.311829 
	C613.147949,645.379700 613.147949,640.894287 613.147949,635.460693 
	C615.162964,637.069092 616.094482,637.812683 616.075256,637.797302 
	C617.786194,634.599121 619.397949,631.586426 621.406311,628.415894 
	C625.200500,628.839844 628.598022,629.421631 632.006714,630.418701 
	C633.077820,636.956665 637.827454,633.337219 640.825928,633.965027 
	C644.459412,634.725769 646.090759,632.744873 645.091553,628.751221 
	C645.125061,628.344116 645.081970,628.179932 645.104614,627.675537 
	C645.143738,626.876648 645.117065,626.417908 645.164246,625.638550 
	C645.177063,624.886047 645.116028,624.454163 645.120972,623.585632 
	C644.340820,622.396667 643.541138,621.067749 642.640381,620.995300 
	C639.376587,620.732727 636.078857,620.891846 632.619324,620.891846 
	C632.250427,622.767944 631.971191,624.188354 631.317627,625.670227 
	C627.655212,626.200439 624.367126,626.669312 620.689087,627.152588 
	C618.057556,627.889893 615.815918,628.612732 612.863892,629.564636 
	C612.863892,617.296936 612.800354,606.004822 612.964233,594.716064 
	C612.980286,593.612000 614.177551,591.998474 615.217957,591.568298 
	C616.541504,591.021240 618.635986,590.850098 619.747620,591.549988 
	C623.551636,593.945068 632.580261,595.131042 635.232483,592.201294 
	C638.483093,588.610718 640.878479,590.489014 643.851929,591.900452 
	C645.560303,592.711365 647.754333,593.929504 649.267944,593.463684 
	C654.739380,591.779724 661.947571,580.166931 661.883301,574.299805 
	C661.710327,558.508179 661.579102,542.715271 661.621643,526.923340 
	C661.625305,525.561401 662.984436,523.074890 663.803284,523.036865 
	C667.529663,522.863892 667.465637,520.332825 667.770020,517.856750 
	C667.770020,517.856750 667.879700,517.871277 667.914246,517.914551 
	C669.415955,517.957825 670.883118,517.957825 672.770264,517.957825 
	C672.339722,516.780396 672.078735,516.066772 671.734070,515.124146 
	C673.213806,515.124146 674.609924,515.124146 675.089478,515.124146 
	C675.089478,524.960999 675.089478,534.602478 674.682495,544.589355 
	C672.494019,544.683289 670.719421,544.354675 668.929749,544.195068 
	C663.705444,543.729248 662.129395,546.214478 664.182678,550.988159 
	C664.896057,549.636719 665.723450,548.069397 666.550781,546.502075 
	C667.862000,547.937988 669.173218,549.373840 670.451965,550.774170 
	C670.719238,549.574768 671.031067,548.175293 671.339966,546.789001 
	C675.297180,546.581055 675.681152,549.316406 675.870972,552.204346 
	C675.705627,559.258850 676.057373,566.077148 675.906433,572.884277 
	C675.705383,581.947998 677.322510,591.166931 673.879150,600.308838 
	C674.554199,601.727600 675.300354,602.844482 676.014771,604.432434 
	C675.951416,612.222168 675.919739,619.540833 675.541138,626.860107 
	C663.194885,620.804626 651.152283,614.831238 639.241089,608.606384 
	C637.386414,607.637207 636.131104,605.521057 633.985962,603.301331 
	C627.137756,606.040894 622.219482,598.096252 614.823914,597.898071 
	C619.817810,601.074341 624.811646,604.250610 628.900879,606.851501 
	C645.605530,613.449402 660.230286,622.456421 675.835938,629.847534 
	C676.213928,646.066772 676.531982,661.850159 676.507141,677.813721 
	C672.330200,677.993958 668.496216,677.993958 664.868103,677.993958 
	C665.988098,680.159363 667.074158,682.259277 668.273254,684.577637 
	C668.758240,682.816223 669.158691,681.362061 669.869446,678.780823 
	C671.935303,681.377258 673.329712,683.129761 675.110596,684.921204 
	C676.013367,684.971436 676.529663,684.982666 677.125000,685.456055 
	C677.154297,690.905579 677.104614,695.892883 677.055786,701.220703 
	C677.076721,701.787109 677.096741,702.013184 677.158447,702.405640 
	C677.200073,702.572083 677.183350,702.914734 676.721008,703.014404 
	C675.961731,703.220093 675.664734,703.326111 675.037598,703.399292 
	C673.137085,702.237305 671.566895,701.108154 669.999878,699.998169 
	C670.003052,700.017395 670.041260,700.004883 669.903687,699.698303 
	C664.657288,694.118286 664.657288,694.118286 661.932251,698.705750 
	C661.777100,699.266174 661.656006,699.634644 661.497070,699.575195 
	C661.081421,696.590637 661.022156,693.925781 660.204773,691.518311 
	C659.724426,690.103760 658.129272,688.197083 656.878906,688.071472 
	C652.416077,687.623169 647.487671,686.832275 645.072510,692.217041 
	C644.287781,692.203125 643.560974,692.360229 643.070190,692.788025 
	C642.344177,693.420959 641.785889,694.246277 641.023438,694.660889 
	C639.244324,692.504333 636.856506,690.914062 636.172363,688.778015 
	C635.561462,686.870483 636.831909,684.360474 637.272156,682.116394 
	C635.872070,682.911194 635.046631,684.266541 634.480408,685.722656 
	C633.863342,687.309509 633.982422,689.822815 632.906311,690.463318 
	C630.326111,691.998962 627.270203,692.807800 624.324951,693.611816 
	C623.473145,693.844421 621.532471,693.180481 621.469360,692.733154 
	C620.808655,688.048645 617.231079,689.269348 614.485535,689.257446 
	C611.364868,689.243958 607.437439,688.590027 605.283752,690.162903 
	C600.421448,693.713745 595.753235,691.497131 591.081299,691.996704 
	C590.735352,689.753479 590.493408,688.184875 590.254639,686.636230 
	C589.219788,686.520325 588.566467,686.436462 587.911011,686.375488 
	C584.475220,686.055725 580.165100,687.970276 578.654175,682.744202 
	C577.424194,683.106934 576.194275,683.469604 574.659180,683.616577 
	C572.828369,682.630859 571.302612,681.860840 569.759338,680.686829 
	C568.828918,678.029907 570.516602,673.866089 565.841980,674.353821 
	C562.514465,674.700989 557.299744,674.756287 556.418335,676.605652 
	C555.076843,679.420471 556.106567,683.933105 557.404541,687.221252 
	C558.160950,689.137146 567.465393,687.325195 569.571045,685.290283 
	C571.206604,685.604004 572.508301,685.924377 573.862305,686.666504 
	C573.919922,688.792297 573.925354,690.496338 573.940063,692.501038 
	C573.967163,693.203186 573.984863,693.604614 574.001282,694.003052 
	C574.000000,694.000000 574.004883,694.002808 573.667664,694.038208 
	C572.889526,694.767578 572.448608,695.461548 571.838623,696.302246 
	C571.304138,696.698303 570.938660,696.947571 570.042725,697.169006 
	C569.160461,697.164185 568.808655,697.187256 568.296509,696.915161 
	C567.368408,696.551208 566.398804,696.151489 565.869934,696.474121 
	C564.125244,697.538330 562.539856,698.863647 560.893311,700.088745 
	C560.531128,699.549072 560.168945,699.009399 559.806763,698.469727 
	C561.163818,697.422791 562.520874,696.375854 563.877991,695.328918 
	C563.576538,694.888794 563.275146,694.448730 562.973755,694.008606 
	C561.324219,695.186157 559.438843,696.158691 558.145691,697.646057 
	C557.653992,698.211548 558.345093,700.914612 558.804871,701.000732 
	C560.550720,701.327698 562.428284,700.854858 564.244873,700.937622 
	C565.318970,700.986633 566.371033,701.517273 567.814514,701.795532 
	C569.168579,701.500366 570.141174,701.241028 571.141357,701.363281 
	C568.699463,703.108398 566.338989,705.304932 563.735718,705.645447 
	C559.536987,706.194702 555.178284,705.520996 550.887146,705.363892 
M651.230591,679.968811 
	C648.436646,679.135437 645.642639,678.302124 642.848694,677.468750 
	C642.350464,677.809937 641.852295,678.151123 641.354126,678.492249 
	C641.922852,680.688782 641.803772,683.925476 643.210083,684.855591 
	C645.782288,686.556763 649.422791,687.687378 651.934875,683.993103 
	C653.691467,683.509094 655.579407,683.254822 657.112061,682.378906 
	C657.493835,682.160767 656.728271,679.935181 656.490051,678.632324 
	C654.971924,679.069214 653.453735,679.506104 651.230591,679.968811 
M617.984192,659.743286 
	C623.650818,656.883667 624.882812,661.004883 625.935669,665.828308 
	C626.593567,663.799622 626.973511,662.627991 627.604797,660.681458 
	C629.515991,663.897095 630.943909,666.484985 632.567444,668.943726 
	C633.254456,669.984314 634.313354,671.470032 635.270691,671.522461 
	C639.578735,671.758362 644.141174,672.348328 648.143555,671.190979 
	C650.349548,670.553162 651.630798,666.713562 653.324951,664.314514 
	C653.516846,664.042725 653.668701,663.742615 653.862427,663.472229 
	C655.998718,660.491699 657.510315,657.625488 654.433472,654.159119 
	C652.040100,651.462830 650.660767,646.920837 647.811951,645.808472 
	C644.069580,644.347290 639.182495,644.804932 635.078003,645.728088 
	C633.139648,646.164001 631.551941,649.436279 630.377380,651.717957 
	C628.105957,656.130615 625.293152,658.917664 619.840881,657.598267 
	C619.204224,657.444214 618.165833,658.949829 617.984192,659.743286 
M613.194153,663.603394 
	C613.797607,656.269958 613.797607,656.269958 610.185242,657.939209 
	C610.660828,662.084229 611.091553,665.838684 611.440430,668.879578 
	C608.276062,670.030273 605.187500,670.190002 604.418335,671.607971 
	C601.761597,676.505432 604.537964,680.420471 610.090881,680.408875 
	C611.089600,680.406799 612.088318,680.405396 613.087036,680.402527 
	C621.601257,680.378052 622.004333,679.886841 620.515991,671.268799 
	C620.463196,670.963196 620.203552,670.693298 619.926514,670.206909 
	C617.969055,670.025024 615.882996,669.831177 613.481445,669.608032 
	C613.380920,667.763367 613.292236,666.135986 613.194153,663.603394 
M589.594482,535.007996 
	C588.176086,535.254089 586.695374,535.893066 585.349976,535.679443 
	C579.041016,534.677429 578.222961,535.604004 579.711182,542.146851 
	C588.982361,542.146851 598.179688,542.146851 607.988464,542.146851 
	C607.735657,539.195190 607.560059,537.145020 607.269714,533.755066 
	C605.504456,535.868408 604.747986,536.774109 603.991455,537.679810 
	C603.964111,537.135376 603.936768,536.591003 603.909424,536.046631 
	C601.297119,535.741516 598.690369,535.369873 596.070801,535.153809 
	C594.197693,534.999329 592.307312,535.053589 589.594482,535.007996 
M581.003967,643.104065 
	C582.070679,642.455750 583.280823,641.958801 584.166565,641.119507 
	C585.203430,640.136963 585.957764,638.856140 586.834595,637.704651 
	C585.350098,637.253662 583.871948,636.445862 582.379883,636.419373 
	C578.084167,636.343018 573.766663,636.406006 569.492676,636.796509 
	C568.066467,636.926758 565.952087,637.931763 565.562134,639.035095 
	C564.970520,640.708923 565.610596,642.818054 565.727478,644.742188 
	C566.097229,644.673584 566.467041,644.604980 566.836792,644.536316 
	C567.233215,642.681824 567.629578,640.827332 568.026001,638.972900 
	C568.502808,639.044861 568.979553,639.116821 569.456360,639.188782 
	C569.943359,640.669861 570.430359,642.150940 571.288757,644.761353 
	C571.832458,642.064270 572.112793,640.673401 572.623840,638.138428 
	C574.556335,640.416992 575.886475,641.985413 577.198486,643.532410 
	C577.802429,642.305969 578.396973,641.098633 579.346191,639.171021 
	C579.987793,640.794800 580.325073,641.648376 581.003967,643.104065 
M637.646423,540.733704 
	C639.242737,540.958069 641.114990,541.812195 642.371643,541.261475 
	C644.333679,540.401733 646.025635,538.722473 647.496826,537.088623 
	C647.864441,536.680298 647.147034,534.370117 646.591370,534.225098 
	C644.765747,533.748657 642.770935,533.693909 640.872620,533.852905 
	C640.254700,533.904663 639.804565,535.189758 639.153259,535.771301 
	C637.765442,537.010437 636.296265,538.158325 634.860413,539.343628 
	C634.473145,538.858765 634.085938,538.373901 633.698669,537.889038 
	C634.972961,536.723877 636.247314,535.558716 637.521606,534.393555 
	C637.253479,533.955872 636.985352,533.518250 636.717163,533.080566 
	C635.022461,534.132324 633.095093,534.955688 631.737366,536.338318 
	C631.116943,536.970093 631.203735,539.543640 631.688660,539.783569 
	C633.250732,540.556580 635.147217,540.653748 637.646423,540.733704 
M655.890015,601.347839 
	C655.591370,600.378174 655.292664,599.408508 654.754211,597.660278 
	C652.593445,599.989563 650.964966,601.745117 648.832031,604.044373 
	C652.614197,604.469543 655.852905,605.070740 659.095093,605.090332 
	C660.550049,605.099182 662.264038,604.219666 663.370667,603.207642 
	C663.974915,602.655029 663.556030,600.983765 663.598572,599.816956 
	C661.993958,599.902771 660.350403,599.809021 658.799988,600.144958 
	C657.934204,600.332520 657.223083,601.233887 655.890015,601.347839 
M618.618530,541.748169 
	C620.333374,541.969543 622.048157,542.190918 623.763000,542.412292 
	C623.713989,541.971802 623.665039,541.531311 623.616028,541.090820 
	C622.408936,540.445068 621.201843,539.799377 619.994751,539.153625 
	C621.108459,538.052612 622.222229,536.951599 623.895081,535.297852 
	C622.368896,535.118896 620.208557,534.555115 620.052917,534.898804 
	C619.178955,536.829407 618.787964,538.978699 618.618530,541.748169 
M638.450378,603.486023 
	C638.450378,603.486023 638.502197,603.508362 638.450378,603.486023 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M621.009705,628.573669 
	C619.397949,631.586426 617.786194,634.599121 616.075256,637.797302 
	C616.094482,637.812683 615.162964,637.069092 613.147949,635.460693 
	C613.147949,640.894287 613.147949,645.379700 612.743042,650.356445 
	C611.757019,650.812317 611.175903,650.776917 610.594727,650.741455 
	C610.594727,646.007141 610.594727,641.272766 610.594727,635.722046 
	C608.888062,636.957886 607.929016,637.652344 607.129517,638.231262 
	C606.571777,636.203308 606.056763,634.330566 605.176697,632.165039 
	C604.207764,630.584229 603.603882,629.296326 602.903076,627.836243 
	C602.806213,627.664124 602.870178,627.274353 602.870178,627.274353 
	C605.210999,627.394958 607.551819,627.515503 610.233765,627.653687 
	C610.233765,616.744629 611.244080,604.875000 609.757690,593.326843 
	C609.125732,588.417114 611.124817,584.317932 610.534180,579.667603 
	C610.064819,575.971741 609.479980,573.506958 605.751099,572.078979 
	C602.774353,570.939087 600.070007,569.087830 597.244751,567.552368 
	C597.506409,567.035522 597.768127,566.518677 598.029785,566.001831 
	C599.679749,566.741821 601.286133,567.617432 602.997070,568.167786 
	C603.928894,568.467407 605.038330,568.215027 606.067627,568.211609 
	C605.906250,567.150818 606.116943,565.732361 605.509460,565.100403 
	C604.046570,563.578552 602.200500,562.424927 600.232422,560.570068 
	C600.038147,559.611450 600.120361,559.205627 600.202637,558.799805 
	C602.818115,559.585938 605.743103,559.894165 607.944214,561.320007 
	C609.506592,562.332031 610.091919,564.852356 611.861572,568.052673 
	C612.278015,560.632019 617.878540,560.778687 622.589966,559.183960 
	C623.057861,559.740967 623.057434,560.100220 622.750183,560.680420 
	C618.112549,562.670959 616.068665,565.847046 616.902344,571.084900 
	C615.529541,570.147705 614.676270,569.565186 613.822937,568.982666 
	C613.336365,569.244202 612.849731,569.505737 612.363159,569.767273 
	C613.162842,571.421936 613.520935,573.877258 614.833862,574.601440 
	C623.420166,579.337158 632.217712,583.689331 640.937195,588.184326 
	C648.669861,592.170532 648.337830,591.841919 654.625122,585.770630 
	C658.535522,581.994629 659.700623,578.078674 659.383484,572.961975 
	C659.013062,566.988220 659.136475,560.977112 659.220398,554.984619 
	C659.266724,551.678162 657.929199,549.871216 654.622375,549.396606 
	C654.041199,549.313232 653.569031,548.469849 653.020081,547.983337 
	C652.993591,547.989258 653.011169,548.040344 653.011169,548.040344 
	C654.880798,547.946716 656.750366,547.853088 659.108765,547.735046 
	C659.108765,540.809021 659.219482,533.936829 659.014587,527.073975 
	C658.973999,525.715637 657.720459,524.393555 656.620605,523.162292 
	C655.791382,523.173889 655.367859,523.078003 654.584473,522.636719 
	C654.223083,521.903015 654.221619,521.514709 654.541626,521.061523 
	C655.563721,520.348267 656.264465,519.699951 656.965210,519.051636 
	C660.302368,518.697449 663.639587,518.343323 667.373413,517.922974 
	C667.465637,520.332825 667.529663,522.863892 663.803284,523.036865 
	C662.984436,523.074890 661.625305,525.561401 661.621643,526.923340 
	C661.579102,542.715271 661.710327,558.508179 661.883301,574.299805 
	C661.947571,580.166931 654.739380,591.779724 649.267944,593.463684 
	C647.754333,593.929504 645.560303,592.711365 643.851929,591.900452 
	C640.878479,590.489014 638.483093,588.610718 635.232483,592.201294 
	C632.580261,595.131042 623.551636,593.945068 619.747620,591.549988 
	C618.635986,590.850098 616.541504,591.021240 615.217957,591.568298 
	C614.177551,591.998474 612.980286,593.612000 612.964233,594.716064 
	C612.800354,606.004822 612.863892,617.296936 612.863892,629.564636 
	C615.815918,628.612732 618.057556,627.889893 620.720520,627.513855 
	C621.097839,628.098328 621.053772,628.335999 621.009705,628.573669 
M624.833679,588.884277 
	C624.526794,588.269958 624.219849,587.655640 623.912964,587.041321 
	C625.607117,587.083191 627.304565,587.074646 628.994202,587.185852 
	C629.998596,587.252014 630.990662,587.506653 631.988342,587.676514 
	C632.124023,587.165344 632.259766,586.654114 632.395508,586.142883 
	C626.163696,582.911316 619.931824,579.679749 612.521912,575.837280 
	C613.574341,581.411499 610.274963,586.665405 616.269043,589.268127 
	C617.552612,589.825439 619.681519,588.435791 621.591003,587.894836 
	C621.743958,588.785461 622.036499,590.488770 622.329102,592.192078 
	C622.807556,592.386475 623.286072,592.580811 623.764526,592.775208 
	C624.367371,591.439697 624.970215,590.104187 624.833679,588.884277 
M628.346008,590.623169 
	C628.190247,591.070984 628.034485,591.518738 627.878784,591.966553 
	C628.575439,591.906067 629.272156,591.845642 629.968811,591.785156 
	C629.647949,591.296631 629.327087,590.808167 628.346008,590.623169 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M583.357239,512.931030 
	C583.681702,511.838593 584.006226,510.746124 584.315308,509.300415 
	C587.004578,503.615906 589.694275,498.276947 592.418335,492.955566 
	C595.091797,487.733307 596.500793,487.506226 600.867493,491.456604 
	C601.589905,492.110107 602.455872,492.604919 603.627747,493.087341 
	C604.000000,493.001617 603.986816,493.024261 603.986816,493.024261 
	C607.762695,497.495605 609.426086,502.494659 608.195923,508.391174 
	C607.954651,509.547546 608.669373,510.903351 609.136414,513.059082 
	C604.784485,513.059082 600.887207,513.059082 596.064392,513.017395 
	C591.211609,512.960815 587.284424,512.945923 583.357239,512.931030 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M583.259766,513.328430 
	C587.284424,512.945923 591.211609,512.960815 595.604492,513.019165 
	C597.005737,513.558899 597.943665,514.491211 598.876404,514.485901 
	C613.023987,514.405945 627.170898,514.200745 641.318054,514.037537 
	C641.903137,514.030762 642.489746,514.147827 644.111511,514.312256 
	C641.587036,515.971375 639.914185,517.070801 638.212036,518.492432 
	C620.567139,518.867371 602.951660,518.925293 585.336060,518.951660 
	C584.703430,518.952637 584.070251,518.642456 583.174927,518.134277 
	C582.995728,516.436157 583.078979,515.081055 583.259766,513.328430 
M590.481445,514.580322 
	C590.481445,514.580322 590.580750,514.427490 590.580750,514.427490 
	C590.580750,514.427490 590.384949,514.441162 590.481445,514.580322 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M605.468689,492.886108 
	C605.220093,492.892181 604.971558,492.898224 604.354858,492.964294 
	C603.986816,493.024261 604.000000,493.001617 603.989136,493.008240 
	C603.326721,491.911652 602.371582,490.876801 602.104065,489.687256 
	C601.800537,488.337463 602.104187,486.848846 602.171082,485.421875 
	C602.177063,485.295258 602.377930,485.171692 602.498413,485.057434 
	C610.899231,477.089691 607.881470,475.789734 619.297974,488.115997 
	C616.190308,490.928986 613.058044,493.764282 609.595886,496.898132 
	C608.467346,495.853668 607.163513,494.646912 606.005493,493.077423 
	C607.974060,492.355774 609.796753,491.996857 611.370361,491.686981 
	C611.519226,490.163696 611.834534,488.774933 611.736694,487.415894 
	C611.640808,486.084473 611.453857,483.944580 610.719055,483.655914 
	C609.463318,483.162567 607.748413,483.837708 606.226379,484.022095 
	C606.252808,484.459412 606.279175,484.896759 606.305542,485.334076 
	C607.522156,485.685333 608.738770,486.036621 610.455078,486.532135 
	C608.549866,488.959839 607.009277,490.922974 605.468689,492.886108 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M654.220215,521.126465 
	C654.221619,521.514709 654.223083,521.903015 654.264282,522.579834 
	C653.278992,526.419861 651.459839,529.773193 647.482117,529.626892 
	C645.289368,529.546326 642.581238,527.647827 641.222351,525.763489 
	C640.146362,524.271423 640.771362,521.552795 640.640991,519.378784 
	C641.283997,519.262512 641.927002,519.146179 642.569946,519.029846 
	C643.349060,520.583313 644.128174,522.136780 644.469177,522.816650 
	C646.668579,520.917175 648.869324,519.016479 651.433716,517.106262 
	C652.384460,517.602356 652.971680,518.108032 653.732910,519.178040 
	C654.011475,520.203735 654.115845,520.665100 654.220215,521.126465 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M657.283447,496.101837 
	C657.781616,500.356079 658.332092,504.628204 657.818970,508.768524 
	C657.587646,510.635315 655.236023,512.239258 653.484802,514.044067 
	C652.467163,508.045746 651.811035,501.965881 651.079285,495.185516 
	C653.812134,495.598755 655.357056,495.832367 657.283447,496.101837 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M616.950195,499.221375 
	C623.277710,502.079590 624.582642,504.543610 622.466248,509.593445 
	C621.499695,508.133667 620.606201,506.784302 619.712708,505.434906 
	C619.095703,505.623535 618.478699,505.812195 617.861694,506.000824 
	C617.521301,508.205963 617.180969,510.411072 616.822205,512.735229 
	C610.917114,512.579590 608.361145,508.856964 611.824707,502.214447 
	C612.608826,504.454987 613.137756,505.966339 613.900940,508.146973 
	C614.941345,505.640900 615.790527,503.595459 616.755371,501.271332 
	C615.771790,501.005768 614.817871,500.748169 612.976807,500.251068 
	C614.779907,499.717499 615.690796,499.447968 616.950195,499.221375 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M605.448486,492.893219 
	C607.009277,490.922974 608.549866,488.959839 610.455078,486.532135 
	C608.738770,486.036621 607.522156,485.685333 606.305542,485.334076 
	C606.279175,484.896759 606.252808,484.459412 606.226379,484.022095 
	C607.748413,483.837708 609.463318,483.162567 610.719055,483.655914 
	C611.453857,483.944580 611.640808,486.084473 611.736694,487.415894 
	C611.834534,488.774933 611.519226,490.163696 611.370361,491.686981 
	C609.796753,491.996857 607.974060,492.355774 605.778564,492.789917 
	C605.405701,492.865143 605.428223,492.900330 605.448486,492.893219 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M389.987946,434.033600 
	C390.911865,435.619385 391.591553,437.488373 392.905731,438.621582 
	C393.309631,438.969849 395.635651,437.516174 396.754883,436.520508 
	C399.213196,434.333618 401.368225,431.806488 403.820312,429.611816 
	C407.373932,426.431274 410.594055,427.291840 411.852325,431.779327 
	C412.824310,435.245636 413.461334,438.805817 414.225708,442.758820 
	C414.744751,443.928436 415.285370,444.663513 415.670166,445.834045 
	C415.257172,446.454956 415.087280,446.700684 415.004639,447.006744 
	C415.004639,447.006744 415.000000,447.000000 414.588135,446.951111 
	C412.783203,446.936981 411.390198,446.971710 409.529419,446.978271 
	C401.309296,446.949646 393.556976,446.949219 385.540314,446.949097 
	C384.925873,446.940216 384.575745,446.930969 384.225616,446.921692 
	C383.655579,446.686127 382.594299,446.463531 382.587708,446.213043 
	C382.442230,440.708801 382.399994,435.201416 382.436646,429.695251 
	C382.437622,429.548370 383.536163,429.408783 384.542847,429.148132 
	C384.962830,429.030426 385.002167,429.037964 384.947601,429.348877 
	C385.249451,429.772552 385.605865,429.885284 385.970520,430.007996 
	C385.978790,430.018005 385.996704,430.036713 385.886230,430.373230 
	C386.841492,431.476105 387.907196,432.242493 388.983704,433.006561 
	C388.994476,433.004272 388.990051,433.025604 388.967529,433.326782 
	C389.288116,433.755249 389.631195,433.882538 389.984894,434.009521 
	C389.995483,434.009186 389.987946,434.033600 389.987946,434.033600 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M392.707794,461.600098 
	C389.497772,459.890045 386.287781,458.179993 382.481995,456.342834 
	C381.349701,456.099945 380.813171,455.984161 380.124390,455.573242 
	C378.700134,454.615570 377.428131,453.953064 375.866364,453.130249 
	C374.661957,452.390778 373.747284,451.811584 372.832611,451.232391 
	C361.746552,447.911133 358.535767,440.383514 359.132904,429.167328 
	C359.989136,413.085480 359.351227,396.924042 359.351227,380.996124 
	C357.230255,380.190430 355.679077,379.601196 354.127869,379.011932 
	C354.132141,378.617706 354.136414,378.223480 354.140686,377.829224 
	C355.773621,377.417297 357.406555,377.005371 359.321198,376.416595 
	C359.906036,376.221985 360.209198,376.204163 361.275391,376.082581 
	C362.669586,375.356659 363.300690,374.734558 363.931793,374.112427 
	C364.399414,374.068268 364.867004,374.024078 365.640472,374.257599 
	C365.946320,374.535309 366.010651,375.013611 365.984192,375.391235 
	C366.338531,379.421722 363.501434,379.522675 361.067139,379.800720 
	C360.832977,379.065735 360.664215,378.670441 360.495453,378.275116 
	C360.646301,379.169006 360.797150,380.062927 361.019165,381.423584 
	C361.264526,398.306671 361.903442,414.736542 361.422882,431.133667 
	C361.170715,439.738800 363.309692,445.681244 372.155273,448.542175 
	C373.529053,448.892670 374.644287,449.021149 375.933594,449.416046 
	C377.484253,450.161865 378.860901,450.641235 380.237518,451.120636 
	C380.882782,451.119598 381.528046,451.118561 382.767395,451.163147 
	C383.543060,451.231964 383.724701,451.255219 384.031128,451.448517 
	C384.607880,451.909576 385.059784,452.200592 385.194397,452.608582 
	C385.579742,453.752136 386.062531,455.118927 387.026154,455.741760 
	C389.964355,457.640747 393.087769,459.253113 396.139130,460.977020 
	C396.025787,461.279938 395.912445,461.582855 395.799103,461.885773 
	C394.768646,461.790558 393.738220,461.695312 392.707794,461.600098 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M380.102478,450.657349 
	C378.860901,450.641235 377.484253,450.161865 376.054352,448.945557 
	C376.009033,423.132019 376.016968,398.055359 376.012939,372.991882 
	C376.000916,373.005035 375.981415,373.034882 376.237122,372.860046 
	C376.668732,372.111908 376.844666,371.538574 377.012024,370.978394 
	C377.003448,370.991547 376.982941,371.015350 376.982941,371.015350 
	C377.646790,372.098022 378.872314,373.172089 378.889069,374.264679 
	C379.094452,387.658417 379.048676,401.055847 379.218964,414.450378 
	C379.370483,426.366180 379.710571,438.279602 380.102478,450.657349 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M400.221802,422.451324 
	C400.747192,422.762451 400.946930,422.955597 401.249298,423.340088 
	C405.619965,430.184235 397.742188,430.890869 395.824310,434.512634 
	C394.383728,437.233002 392.309723,435.943756 390.330872,434.115906 
	C389.987946,434.033600 389.995483,434.009186 389.954224,433.739868 
	C389.605347,433.322266 389.297699,433.173950 388.990051,433.025604 
	C388.990051,433.025604 388.994476,433.004272 389.044250,432.697205 
	C389.407898,431.910248 389.721741,431.430359 390.416992,431.081787 
	C392.367645,431.129395 393.936859,431.045746 395.006134,430.988739 
	C395.251160,429.127655 395.618042,427.851501 395.530945,426.607056 
	C395.429932,425.163086 395.358521,423.017914 394.492126,422.509186 
	C393.479767,421.914764 391.622925,422.758728 390.132446,422.978668 
	C390.178955,423.407104 390.225494,423.835510 390.272034,424.263947 
	C391.558655,424.376709 392.845276,424.489471 395.383087,424.711884 
	C393.030426,426.933228 391.461456,428.414642 389.509888,429.998169 
	C388.083801,430.079132 387.040253,430.057922 385.996704,430.036713 
	C385.996704,430.036713 385.978790,430.018005 385.956604,429.735046 
	C385.623688,429.314056 385.312927,429.175995 385.002167,429.037964 
	C385.002167,429.037964 384.962830,429.030426 384.944214,429.022705 
	C385.332458,427.460846 385.389801,425.676819 386.226105,424.405060 
	C387.560730,422.375488 389.413300,420.686523 391.160156,418.538818 
	C392.633392,418.395569 394.171722,418.225006 395.311279,418.819244 
	C396.995819,419.697632 398.397003,421.119507 400.221802,422.451324 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M383.906311,451.278473 
	C383.724701,451.255219 383.543060,451.231964 383.090576,451.159119 
	C383.271484,449.965607 383.723297,448.821747 384.200378,447.299805 
	C384.575745,446.930969 384.925873,446.940216 385.740082,447.300995 
	C394.098083,447.969696 401.992035,448.286865 410.250946,448.697052 
	C411.615204,448.893585 412.614502,448.997101 413.613770,449.100616 
	C413.649567,449.524139 413.685364,449.947632 413.274475,450.656006 
	C410.533600,451.006012 408.239380,451.071198 405.483917,451.082581 
	C397.983887,451.112030 390.945099,451.195251 383.906311,451.278473 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M367.802734,370.978790 
	C367.898590,370.540222 367.994446,370.101654 368.127563,369.325623 
	C371.068176,369.401184 373.971527,369.814148 376.928925,370.621216 
	C376.982941,371.015350 377.003448,370.991547 376.739014,371.136139 
	C376.310181,371.865448 376.145813,372.450165 375.981415,373.034882 
	C375.981415,373.034882 376.000916,373.005035 375.588135,372.946716 
	C372.717834,372.251892 370.260284,371.615326 367.802734,370.978790 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M370.819122,366.936646 
	C369.617218,365.079651 368.776947,363.217072 367.936676,361.354462 
	C368.600037,361.092224 369.263428,360.829956 369.926819,360.567719 
	C372.843018,361.840973 372.916168,364.079132 370.819122,366.936646 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M541.330811,489.675995 
	C541.603455,502.086853 541.735229,514.038269 541.545166,526.527100 
	C539.837280,529.279663 538.451233,531.494873 536.709229,533.582031 
	C533.034729,530.740051 529.716125,528.026123 526.397583,525.312256 
	C526.660461,524.860413 526.923340,524.408508 527.186218,523.956665 
	C529.463806,523.764526 531.741394,523.572449 534.019043,523.380310 
	C532.400146,522.528870 530.812195,522.353333 529.276245,522.561401 
	C524.490906,523.209839 522.841553,521.259399 523.022217,516.456299 
	C523.289490,509.348236 522.927612,502.216461 522.885376,494.685730 
	C522.820374,490.559845 522.703735,486.842987 522.587097,483.126129 
	C522.638489,475.873169 522.672668,468.619995 522.753357,461.367340 
	C522.773621,459.555115 522.943848,457.744507 523.018066,455.463226 
	C522.990906,454.993286 522.999939,454.998383 523.302490,454.824219 
	C524.645630,454.063110 525.686279,453.476135 526.726990,452.889221 
	C527.866638,452.244202 529.006226,451.599152 530.888367,451.013397 
	C534.735168,451.399719 537.839539,451.726776 540.943848,452.053833 
	C541.025818,464.441376 541.107849,476.828979 541.330811,489.675995 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M512.125854,449.678070 
	C503.947266,450.000793 495.862549,450.039612 487.779846,449.928589 
	C486.271759,449.907898 484.771973,449.286316 483.225525,448.556671 
	C492.497833,448.107697 501.812927,448.044067 511.602356,447.972656 
	C512.124573,448.424072 512.172424,448.883240 512.125854,449.678070 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M541.229370,451.812653 
	C537.839539,451.726776 534.735168,451.399719 531.217407,450.947968 
	C530.859375,450.106842 530.914917,449.390381 531.060181,448.325348 
	C533.718140,448.320038 536.339111,448.459503 538.829468,449.104004 
	C539.867920,449.372772 540.628174,450.716522 541.229370,451.812653 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M526.382568,452.857483 
	C525.686279,453.476135 524.645630,454.063110 523.298340,454.818909 
	C524.007202,454.267120 525.022644,453.546448 526.382568,452.857483 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M524.282837,445.733704 
	C525.151672,444.929749 526.303406,444.498138 527.455139,444.066528 
	C527.589417,444.542419 527.723755,445.018311 527.858093,445.494202 
	C526.760620,445.698151 525.663147,445.902100 524.282837,445.733704 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M519.469177,446.295654 
	C519.121094,446.025757 519.091919,445.810303 519.044678,445.269135 
	C519.553772,445.104767 520.080994,445.266052 520.608154,445.427368 
	C520.334778,445.734955 520.061401,446.042542 519.469177,446.295654 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M375.549286,351.990234 
	C374.609711,351.966583 373.996033,351.880188 373.382355,351.793762 
	C373.398529,351.595795 373.414673,351.397827 373.430847,351.199860 
	C374.334900,351.246063 375.239349,351.287170 376.142151,351.351044 
	C376.195679,351.354828 376.301239,351.597290 376.271301,351.631653 
	C376.165436,351.753113 376.011139,351.832306 375.549286,351.990234 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M377.141052,338.352814 
	C376.856323,338.645538 376.429962,338.767761 375.995056,338.829468 
	C375.920227,338.840149 375.808838,338.593658 375.714355,338.465942 
	C376.141754,338.364594 376.569122,338.263214 377.141052,338.352814 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M545.009705,474.162842 
	C544.820984,477.412140 544.631165,480.214935 544.441345,483.017700 
	C544.065063,482.985718 543.688782,482.953735 543.312500,482.921783 
	C543.312500,478.508179 543.312500,474.094574 543.312500,469.680969 
	C543.711182,469.625000 544.109802,469.569061 544.508484,469.513123 
	C544.675171,470.914185 544.841919,472.315216 545.009705,474.162842 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M392.297485,461.805847 
	C393.738220,461.695312 394.768646,461.790558 395.799103,461.885773 
	C395.912445,461.582855 396.025787,461.279938 396.139130,460.977020 
	C393.087769,459.253113 389.964355,457.640747 387.026154,455.741760 
	C386.062531,455.118927 385.579742,453.752136 385.577332,452.751038 
	C392.813690,452.686066 399.349792,452.595581 406.348816,452.628113 
	C409.711029,452.579681 412.610321,452.408234 415.509613,452.236786 
	C415.731964,452.217529 415.954346,452.198242 416.326874,452.602173 
	C415.822540,457.880829 419.801147,455.206635 421.554199,456.149384 
	C421.554199,456.149384 421.763306,456.586426 421.834778,456.817871 
	C421.759583,459.217560 421.612915,461.385773 420.953064,463.853363 
	C416.406311,464.384125 412.372681,464.615540 408.114929,464.562378 
	C405.506897,464.445984 403.123016,464.614105 400.020721,464.832916 
	C400.091919,464.962738 399.522400,463.924408 398.302032,461.699554 
	C396.565857,464.599274 395.315002,466.688416 394.064148,468.777557 
	C393.196503,469.469574 392.328857,470.161621 391.461212,470.853638 
	C390.982513,470.105377 390.004211,469.270996 390.115631,468.624420 
	C390.501587,466.384918 391.266479,464.210693 392.297485,461.805847 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M421.541443,455.819092 
	C419.801147,455.206635 415.822540,457.880829 416.661011,452.623291 
	C419.916229,451.146088 420.701569,453.347412 421.541443,455.819092 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M415.290710,452.066772 
	C412.610321,452.408234 409.711029,452.579681 406.371765,452.404724 
	C405.960938,451.751343 405.965393,451.444031 405.945160,451.136353 
	C408.239380,451.071198 410.533600,451.006012 413.251953,450.976379 
	C414.141296,451.306885 414.606567,451.601837 415.290710,452.066772 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M413.748535,448.812927 
	C412.614502,448.997101 411.615204,448.893585 410.281006,448.435516 
	C409.963104,447.722809 409.980133,447.364624 409.997162,447.006470 
	C411.390198,446.971710 412.783203,446.936981 414.591431,446.954010 
	C414.632172,447.512268 414.257721,448.018738 413.748535,448.812927 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M415.285248,446.973419 
	C415.087280,446.700684 415.257172,446.454956 415.682983,446.116394 
	C415.756439,446.288879 415.661133,446.614502 415.285248,446.973419 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M534.013672,428.992615 
	C534.025146,428.984955 533.608032,428.785767 533.608032,428.785767 
	C533.608032,428.785767 533.864746,428.814209 533.926331,428.918823 
	C533.987915,429.023438 534.002258,429.000275 534.013672,428.992615 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M560.683594,498.820007 
	C561.536377,499.527496 562.059509,500.073547 562.797913,500.803345 
	C564.733582,505.081238 566.453918,509.175415 568.141479,513.676270 
	C566.787354,517.032837 567.719421,518.372314 570.970154,518.318848 
	C571.835571,518.304626 572.710632,518.867920 573.369873,519.707275 
	C568.522400,521.278625 563.886292,522.308655 558.796509,523.439514 
	C558.836304,523.514709 558.241333,522.389038 557.485229,520.958374 
	C554.630066,523.384338 552.224792,525.427979 549.119019,528.066895 
	C552.569946,527.862183 554.355103,527.713501 556.143127,527.659729 
	C559.208313,527.567505 562.286560,527.672974 565.337036,527.422913 
	C567.061340,527.281494 569.301331,525.719421 570.308716,526.362976 
	C571.792725,527.311157 573.100159,529.608398 573.222229,531.416077 
	C573.602905,537.054260 573.537476,542.738647 573.297180,548.390320 
	C573.243164,549.659363 571.774597,550.868225 570.672363,551.912598 
	C567.561951,550.078125 564.736450,548.435791 561.873840,546.771973 
	C562.824890,544.370972 563.596375,542.423157 563.983459,541.445801 
	C562.821777,542.355164 560.970825,543.803955 559.119873,545.252808 
	C558.467224,543.878052 557.814697,542.503296 557.427124,540.905090 
	C560.867859,538.614807 558.714172,536.920349 557.011597,535.877380 
	C553.883057,533.961060 550.476196,532.499146 547.185425,530.847839 
	C547.108154,529.181702 547.030945,527.515564 546.910828,525.434570 
	C548.314758,521.115479 549.761536,517.211182 550.911865,513.133362 
	C550.724670,509.193481 551.147949,505.403870 550.870605,501.666229 
	C550.438721,495.845398 551.395630,494.909393 556.743042,496.970520 
	C557.979797,497.447235 559.151978,498.091431 560.683594,498.820007 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M570.957275,552.104858 
	C571.774597,550.868225 573.243164,549.659363 573.297180,548.390320 
	C573.537476,542.738647 573.602905,537.054260 573.222229,531.416077 
	C573.100159,529.608398 571.792725,527.311157 570.308716,526.362976 
	C569.301331,525.719421 567.061340,527.281494 565.337036,527.422913 
	C562.286560,527.672974 559.208313,527.567505 556.143127,527.659729 
	C554.355103,527.713501 552.569946,527.862183 549.119019,528.066895 
	C552.224792,525.427979 554.630066,523.384338 557.485229,520.958374 
	C558.241333,522.389038 558.836304,523.514709 558.796509,523.439514 
	C563.886292,522.308655 568.522400,521.278625 573.498901,520.049316 
	C574.079407,520.184570 574.319397,520.519043 574.799255,521.349854 
	C575.938171,523.246826 576.837280,524.647400 577.736328,526.048035 
	C577.095032,527.984863 575.949463,529.909058 575.900513,531.860657 
	C575.699890,539.853699 575.384338,547.889099 575.985046,555.842896 
	C576.475525,562.336121 574.566406,567.428894 568.798218,571.929932 
	C582.863953,579.276611 595.953857,586.113647 609.043762,592.950684 
	C608.786926,593.484619 608.530090,594.018555 608.273254,594.552490 
	C604.693665,592.845337 601.060242,591.241577 597.544006,589.412537 
	C584.104797,582.422241 570.724792,575.317688 557.262146,568.372986 
	C555.447205,567.436829 553.288391,565.121887 550.810791,568.566650 
	C550.049805,568.786926 549.773499,568.826050 549.403748,568.631592 
	C549.204163,566.824280 549.107117,565.315552 549.014282,563.328247 
	C549.041687,562.531311 549.065002,562.213074 549.464233,561.947998 
	C551.780396,560.339661 553.720581,558.678223 555.608948,557.383423 
	C555.084778,559.798462 554.612488,561.846802 554.089600,564.114319 
	C557.535645,565.973145 560.862976,567.813354 564.239868,569.557617 
	C565.089417,569.996460 566.643188,570.641663 566.945984,570.319763 
	C569.264160,567.855042 572.241150,565.480713 573.275513,562.479370 
	C574.421753,559.153625 574.668396,554.818115 570.001831,553.022095 
	C570.485229,552.639954 570.721252,552.372375 570.957275,552.104858 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M578.031006,525.885620 
	C576.837280,524.647400 575.938171,523.246826 575.071838,521.494507 
	C576.873230,520.182617 578.641846,519.222473 580.753418,518.251343 
	C580.630188,521.006226 581.612549,522.146484 584.581177,521.973755 
	C588.688538,521.734802 592.843079,521.629395 596.932800,521.982788 
	C600.058105,522.252808 603.137268,523.237610 606.194519,524.062012 
	C609.140259,524.856384 611.824036,527.160034 615.150085,524.668457 
	C616.165710,523.907654 618.032043,524.282532 620.524658,524.037415 
	C618.358826,526.000854 617.138916,527.106812 614.916138,529.121887 
	C617.853394,528.925781 619.209656,528.835266 620.565857,528.744751 
	C620.558533,529.367249 620.710205,530.472046 620.518433,530.535217 
	C619.097473,531.003906 617.617065,531.488403 616.138611,531.547180 
	C612.639526,531.686096 609.131226,531.591797 605.339478,531.591797 
	C605.142517,529.959778 604.957275,528.424377 604.603271,525.491150 
	C603.666504,528.159424 603.184082,529.533691 602.628784,531.115540 
	C600.168274,529.981140 602.009033,524.044861 596.675293,526.416504 
	C597.072266,528.258972 597.506592,530.274780 598.083069,532.950073 
	C592.553833,532.591614 586.715576,532.213135 580.026794,531.779541 
	C580.026794,529.759094 580.053650,527.852417 579.954224,525.952393 
	C579.948730,525.847900 578.892700,525.798279 578.031006,525.885620 
M582.403259,525.289001 
	C583.077209,525.340515 583.751160,525.392090 584.425049,525.443604 
	C584.439819,525.150085 584.454590,524.856628 584.469421,524.563110 
	C583.769348,524.591248 583.069214,524.619385 582.403259,525.289001 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M681.688721,488.122467 
	C682.350525,495.844971 682.836548,503.572052 683.383118,512.261169 
	C678.559387,511.923981 674.736450,511.656738 670.546326,511.216461 
	C670.184509,510.579132 670.189941,510.114838 670.190857,509.301453 
	C673.699890,502.197052 677.213440,495.441711 680.944580,488.417847 
	C681.162231,488.149323 681.513062,488.127075 681.688721,488.122467 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M663.508362,500.077332 
	C664.004639,500.442474 664.193420,500.693970 664.654968,501.016479 
	C664.988892,501.433746 665.178284,501.683197 665.622498,502.019165 
	C666.527588,504.160126 667.237122,506.169464 667.956055,508.542084 
	C666.289978,509.851074 664.614380,510.796844 662.660339,511.899780 
	C662.170959,507.261841 661.817749,503.914032 661.547913,500.132263 
	C662.131531,499.804047 662.631714,499.909790 663.508362,500.077332 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M654.541626,521.061523 
	C654.115845,520.665100 654.011475,520.203735 653.942505,519.388062 
	C654.720520,518.951355 655.463013,518.868958 656.585327,518.919128 
	C656.264465,519.699951 655.563721,520.348267 654.541626,521.061523 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M522.232056,483.119751 
	C522.703735,486.842987 522.820374,490.559845 522.370483,494.669678 
	C521.462219,495.096069 521.120483,495.129425 520.778748,495.162781 
	C520.386292,495.135101 519.993774,495.107422 519.315125,495.007782 
	C519.175781,491.683838 519.177673,488.414001 519.574524,485.192810 
	C519.670166,484.416901 521.074524,483.802185 522.232056,483.119751 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M438.306091,386.975891 
	C438.367798,387.706879 438.209900,388.226532 438.052002,388.746185 
	C437.780548,388.607605 437.509155,388.469025 437.237732,388.330414 
	C437.520630,387.808472 437.803558,387.286530 438.306091,386.975891 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M460.605835,470.485046 
	C460.471466,470.582855 460.417572,470.210144 460.417572,470.210144 
	C460.417572,470.210144 460.740173,470.387207 460.605835,470.485046 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M549.601624,421.810791 
	C548.960754,420.479034 548.639282,419.295563 548.453674,417.831757 
	C551.265320,415.084320 553.941162,412.617218 557.013916,409.784119 
	C560.297791,412.731110 563.560364,415.658966 567.309387,419.023315 
	C563.585693,422.157593 560.101013,425.090698 556.158569,428.409058 
	C555.485657,427.415100 554.336121,425.717224 553.519043,423.877838 
	C555.729248,423.736359 557.607056,423.736359 558.909180,423.736359 
	C559.425415,421.086823 560.090393,419.267609 560.061829,417.459351 
	C560.014832,414.487213 556.719482,414.254364 553.161499,417.293427 
	C554.940063,417.429901 556.359497,417.538788 558.973206,417.739349 
	C556.508789,420.069061 554.843445,421.643463 553.178040,423.217865 
	C552.092407,422.798248 551.006714,422.378632 549.601624,421.810791 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M553.180908,423.236633 
	C554.843445,421.643463 556.508789,420.069061 558.973206,417.739349 
	C556.359497,417.538788 554.940063,417.429901 553.161499,417.293427 
	C556.719482,414.254364 560.014832,414.487213 560.061829,417.459351 
	C560.090393,419.267609 559.425415,421.086823 558.909180,423.736359 
	C557.607056,423.736359 555.729248,423.736359 553.500732,423.501831 
	C553.149963,423.267273 553.183716,423.255402 553.180908,423.236633 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M498.235352,431.910583 
	C498.088409,431.987793 498.142975,431.575867 498.155457,431.368896 
	C498.167938,431.161896 498.529572,431.421051 498.529572,431.421051 
	C498.529572,431.421051 498.382263,431.833344 498.235352,431.910583 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M520.842896,495.617004 
	C521.120483,495.129425 521.462219,495.096069 522.318848,495.078735 
	C522.927612,502.216461 523.289490,509.348236 523.022217,516.456299 
	C522.841553,521.259399 524.490906,523.209839 529.276245,522.561401 
	C530.812195,522.353333 532.400146,522.528870 534.019043,523.380310 
	C531.741394,523.572449 529.463806,523.764526 527.186218,523.956665 
	C526.923340,524.408508 526.660461,524.860413 526.397583,525.312256 
	C529.716125,528.026123 533.034729,530.740051 536.864258,533.701843 
	C537.753296,534.042297 538.131348,534.134827 538.702332,534.474060 
	C539.589966,535.134399 540.284790,535.548035 541.262329,535.962585 
	C541.643372,535.620300 541.741638,535.277039 541.886047,534.616577 
	C542.044983,533.888916 542.157837,533.478516 542.270630,533.068115 
	C543.628357,533.367981 544.986145,533.667786 546.949646,534.183716 
	C550.757690,536.642700 553.959961,538.885620 557.162170,541.128540 
	C557.814697,542.503296 558.467224,543.878052 559.119873,545.252808 
	C560.970825,543.803955 562.821777,542.355164 563.983459,541.445801 
	C563.596375,542.423157 562.824890,544.370972 561.873840,546.771973 
	C564.736450,548.435791 567.561951,550.078125 570.672363,551.912598 
	C570.721252,552.372375 570.485229,552.639954 569.634033,552.936890 
	C565.708435,552.224487 562.978027,548.088074 558.715454,550.970825 
	C557.915649,549.006958 558.362061,544.965149 555.434937,547.340210 
	C552.203491,549.962097 550.455627,547.668030 547.724365,546.947083 
	C546.196289,546.153809 545.005798,545.510864 543.601929,544.752747 
	C542.995667,547.135254 542.506348,549.057861 542.017090,550.980469 
	C541.411743,546.928650 540.827148,542.873413 540.170227,538.829956 
	C540.101562,538.407471 539.531250,537.681763 539.318054,537.723999 
	C534.754883,538.628845 533.636169,533.744263 530.341003,532.424072 
	C526.698120,530.964600 523.302002,528.889343 519.458984,527.182861 
	C518.367310,527.064880 517.614990,526.846252 516.434082,526.503174 
	C517.458923,525.301331 518.333374,524.275818 519.326843,522.978271 
	C519.989075,521.292297 520.980469,519.884705 521.000366,518.463501 
	C521.104675,511.001312 520.963196,503.535675 520.842896,495.617004 
M522.379700,524.104614 
	C521.663635,524.188599 520.947632,524.272522 520.231567,524.356506 
	C520.448792,524.862061 520.666077,525.367615 520.883301,525.873169 
	C521.599121,525.473267 522.314880,525.073425 522.379700,524.104614 
M524.478516,526.105652 
	C524.408813,526.272644 524.339050,526.439575 524.269287,526.606567 
	C524.461304,526.557434 524.653320,526.508301 524.478516,526.105652 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M557.427124,540.905090 
	C553.959961,538.885620 550.757690,536.642700 547.302612,534.178467 
	C547.039673,533.176453 547.029602,532.395813 547.102417,531.231506 
	C550.476196,532.499146 553.883057,533.961060 557.011597,535.877380 
	C558.714172,536.920349 560.867859,538.614807 557.427124,540.905090 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M538.509460,534.227356 
	C538.131348,534.134827 537.753296,534.042297 537.220215,533.829956 
	C538.451233,531.494873 539.837280,529.279663 541.562988,526.990356 
	C541.978760,527.974792 542.054932,529.033447 541.756592,530.588135 
	C540.424500,532.131958 539.466980,533.179626 538.509460,534.227356 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M538.702332,534.474060 
	C539.466980,533.179626 540.424500,532.131958 541.730713,530.985474 
	C542.077454,531.377502 542.075562,531.868347 542.172119,532.713623 
	C542.157837,533.478516 542.044983,533.888916 541.713135,534.762329 
	C541.265381,535.430725 541.093811,535.676208 540.979553,535.961670 
	C540.284790,535.548035 539.589966,535.134399 538.702332,534.474060 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M408.339111,464.846954 
	C412.372681,464.615540 416.406311,464.384125 420.873840,464.260742 
	C420.626892,465.118042 419.946075,465.867340 418.650238,466.859283 
	C415.990204,467.101929 413.945190,467.101929 410.844360,467.101929 
	C414.482147,469.849670 417.217255,471.915588 419.952362,473.981537 
	C415.993195,472.245361 412.034027,470.509186 407.979980,468.075989 
	C408.036469,466.535004 408.187775,465.690979 408.339111,464.846954 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M420.210144,473.989319 
	C417.217255,471.915588 414.482147,469.849670 410.844360,467.101929 
	C413.945190,467.101929 415.990204,467.101929 418.442383,467.097809 
	C419.079590,467.340454 419.309631,467.587250 419.857727,468.181091 
	C420.623322,468.755554 421.070831,468.982971 421.518341,469.210358 
	C421.563629,470.549927 421.608948,471.889465 421.576080,473.624725 
	C421.154572,474.012665 420.811249,474.004883 420.210144,473.989319 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M421.600159,468.911499 
	C421.070831,468.982971 420.623322,468.755554 420.089355,468.285950 
	C420.311218,467.791901 420.619476,467.540039 420.927734,467.288147 
	C421.179138,467.729645 421.430542,468.171112 421.600159,468.911499 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M439.704102,414.514069 
	C439.576355,414.618103 439.509399,414.247772 439.509399,414.247772 
	C439.509399,414.247772 439.831879,414.410034 439.704102,414.514069 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M508.067871,491.303772 
	C507.447327,491.303345 506.752319,490.989410 505.906433,490.431274 
	C506.501495,490.454773 507.247437,490.722504 508.067871,491.303772 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M297.053467,345.447235 
	C297.492279,345.061005 297.740723,344.863190 298.087341,344.419769 
	C298.787933,343.719360 299.349976,343.213898 300.069397,342.471680 
	C300.508636,342.080750 300.756348,341.883636 301.115845,341.455627 
	C301.544647,341.110046 301.788330,340.905792 302.126526,340.456604 
	C302.530884,340.094482 302.763000,339.887390 303.080200,339.439301 
	C304.107635,339.237091 305.011444,339.232635 306.227936,339.358215 
	C307.035309,340.051575 307.529938,340.614899 308.262634,341.335938 
	C308.975250,342.074860 309.449799,342.656036 310.138672,343.426514 
	C311.531189,345.364044 312.709412,347.112213 314.285522,349.450745 
	C312.394318,350.968994 310.496857,352.492310 308.422302,354.157745 
	C306.575439,351.046478 310.965759,342.524963 300.330872,344.886444 
	C301.691650,346.795929 302.558533,348.191559 303.601746,349.439758 
	C305.952759,352.252686 306.069824,356.233337 303.374542,358.007355 
	C302.990601,358.052246 302.988953,358.022949 302.957275,357.741821 
	C302.615723,357.317749 302.305878,357.174835 301.996033,357.031891 
	C301.996033,357.031891 301.974609,357.013306 301.959167,357.011658 
	C299.659088,353.969696 297.374420,350.929352 295.170288,347.547485 
	C295.801697,346.699402 296.352570,346.192841 297.053467,345.447235 
M298.823181,349.728943 
	C298.982178,349.622314 299.141174,349.515717 299.300171,349.409088 
	C299.076782,349.429352 298.853424,349.449615 298.823181,349.728943 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M351.889221,373.900513 
	C350.891022,374.468964 349.814911,374.770966 348.382446,375.052246 
	C347.458282,374.532379 346.890472,374.033264 346.157440,373.299713 
	C347.362518,373.039337 348.732819,373.013367 350.514130,372.997406 
	C351.220520,373.216278 351.515900,373.425171 351.889221,373.900513 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M367.519348,371.132568 
	C370.260284,371.615326 372.717834,372.251892 375.600159,372.933594 
	C376.016968,398.055359 376.009033,423.132019 375.880310,448.679138 
	C374.644287,449.021149 373.529053,448.892670 372.498901,448.256714 
	C372.800873,445.795959 373.207947,443.842377 373.204285,441.889557 
	C373.176544,427.017487 373.055908,412.145630 372.991089,397.273590 
	C372.959534,390.033600 372.985626,382.793304 372.985626,375.013611 
	C370.227142,375.013611 368.118896,375.013611 366.010651,375.013611 
	C366.010651,375.013611 365.946320,374.535309 365.991455,374.298309 
	C366.436432,373.136322 366.836212,372.211365 367.519348,371.132568 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M363.572937,374.086853 
	C363.300690,374.734558 362.669586,375.356659 361.721497,375.990417 
	C362.007660,375.355103 362.610870,374.708191 363.572937,374.086853 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M334.308258,355.064148 
	C333.910767,352.943878 333.792877,350.915497 333.838074,348.890778 
	C333.932037,344.684601 331.055634,345.164520 327.938232,345.184753 
	C329.952606,340.028381 330.551910,339.951019 334.294678,343.462494 
	C336.334442,345.376221 338.670319,346.974365 341.601959,349.291168 
	C338.508270,351.906250 336.584991,353.531952 334.308258,355.064148 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M325.730011,345.678101 
	C327.445038,346.751251 328.840515,348.006683 330.626648,349.613525 
	C329.488983,350.664917 327.587341,352.422363 325.344971,354.335205 
	C322.103455,351.470947 320.890472,348.530731 325.730011,345.678101 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M285.237976,350.902802 
	C288.420410,349.939117 291.778595,348.200928 293.967285,352.695404 
	C291.325745,355.302612 288.601837,357.588806 285.176361,360.463837 
	C285.176361,357.343567 285.176361,354.296387 285.237976,350.902802 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M279.913513,367.536713 
	C279.123260,367.987305 278.431976,368.047211 277.396545,368.057129 
	C277.440887,366.658447 277.829437,365.309784 278.566528,363.789062 
	C279.179871,363.696808 279.444702,363.776611 280.119751,363.920990 
	C280.357483,365.039032 280.184998,366.092529 279.913513,367.536713 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M675.776001,629.411621 
	C660.230286,622.456421 645.605530,613.449402 628.900879,606.851501 
	C624.811646,604.250610 619.817810,601.074341 614.823914,597.898071 
	C622.219482,598.096252 627.137756,606.040894 633.985962,603.301331 
	C636.131104,605.521057 637.386414,607.637207 639.241089,608.606384 
	C651.152283,614.831238 663.194885,620.804626 675.831909,626.839172 
	C676.980774,626.542908 677.491943,626.268127 678.308228,626.046875 
	C679.141724,625.821655 679.670166,625.542908 680.514954,625.209229 
	C681.249329,625.061829 681.667358,624.969360 682.437805,624.834717 
	C690.027283,621.778259 695.390686,613.968750 694.088013,607.886047 
	C693.793762,606.512207 692.229004,605.440857 691.374878,604.147278 
	C690.644165,603.040588 690.093567,601.814941 689.589905,600.297485 
	C689.819031,599.474976 689.921570,598.996887 690.346436,598.411133 
	C691.111389,598.231140 691.554016,598.158936 692.073975,598.240234 
	C692.430542,598.350830 692.709839,598.307861 693.298706,598.069763 
	C693.994202,597.250549 694.380127,596.626587 694.890686,595.801025 
	C696.132080,597.579834 697.143738,599.029480 698.155396,600.479126 
	C698.788330,600.452454 699.421326,600.425842 700.054260,600.399170 
	C700.688538,596.793762 701.322876,593.188354 702.025452,589.288452 
	C702.327026,588.673035 702.560303,588.352051 703.181946,588.161987 
	C706.638672,582.456787 709.860657,576.691650 712.619507,570.712830 
	C713.063538,569.750610 711.442627,567.835571 710.936890,566.716492 
	C712.102539,566.096008 713.244812,565.488037 714.387085,564.880066 
	C716.156677,565.902405 717.926270,566.924805 719.920349,568.497375 
	C718.270020,570.553162 716.395264,572.058716 714.376038,573.759705 
	C714.611816,574.099731 714.991943,574.244263 715.682007,574.309692 
	C716.776489,574.394348 717.561096,574.558044 718.780029,574.812378 
	C716.828735,576.387756 715.399475,577.541626 713.970276,578.695496 
	C714.318298,579.183899 714.666260,579.672302 715.014282,580.160645 
	C716.779541,578.999023 719.065735,578.204834 720.199463,576.597717 
	C722.422241,573.446899 726.383118,572.894836 729.031921,575.932068 
	C729.985046,577.024902 730.912048,578.140503 731.414062,578.731384 
	C725.100952,581.332336 718.338989,583.257202 711.175720,580.381104 
	C710.863953,580.933350 710.416382,581.466980 710.241516,582.078552 
	C709.175110,585.806824 708.662598,589.785461 707.029541,593.237976 
	C704.622742,598.326477 701.778198,603.293335 698.491089,607.859131 
	C694.066101,614.005432 696.788208,620.272034 697.322754,626.995728 
	C701.823975,619.883850 709.301086,618.348938 716.207092,616.565552 
	C718.663452,616.565552 721.119812,616.565552 723.576172,616.565552 
	C725.147522,617.377930 728.202576,618.384644 728.529358,617.818054 
	C729.778503,615.652100 730.149780,612.979858 730.848816,610.496643 
	C729.282715,610.593323 727.716370,610.685913 726.150574,610.787842 
	C724.180359,610.916138 722.210632,611.052063 720.240662,611.184753 
	C720.257568,611.002380 720.274414,610.820007 720.291321,610.637634 
	C717.570862,611.331604 714.850403,612.025574 711.532776,612.871948 
	C713.481201,603.197632 714.056335,602.913269 720.346558,607.620667 
	C724.688965,601.400879 728.196167,608.389221 732.872986,608.146545 
	C732.872986,610.749817 732.560120,613.116638 733.011963,615.327087 
	C733.221252,616.350708 734.920532,617.069702 735.944336,617.926758 
	C741.487061,617.856873 747.029846,617.786926 753.125977,617.365967 
	C754.052185,616.949280 754.424866,616.883545 754.874146,616.887024 
	C754.950745,616.956238 755.100403,617.098511 755.089844,617.284546 
	C755.079346,617.470520 755.143005,617.837646 755.117432,618.190063 
	C755.057800,619.012329 755.023743,619.482117 754.989685,619.951904 
	C753.337830,620.036255 751.685913,620.120667 749.296387,620.203247 
	C747.700989,620.409180 746.843262,620.617004 745.612061,620.804077 
	C744.497314,620.862183 743.756104,620.941040 742.631042,621.007202 
	C741.480591,621.037231 740.713928,621.079895 739.619141,620.992676 
	C738.826294,620.852356 738.361572,620.841919 737.609375,620.819885 
	C736.939697,620.815369 736.557495,620.822449 735.787842,620.739624 
	C734.631287,620.806335 733.862183,620.962952 732.780762,621.068604 
	C731.347046,620.852234 730.225586,620.686890 728.667969,620.550110 
	C726.777405,620.680298 725.323181,620.781921 723.497498,620.951965 
	C720.502563,620.938904 717.879089,620.857422 714.876221,620.691528 
	C707.726624,618.697327 704.139221,623.506836 700.142944,627.424561 
	C694.926331,632.538696 695.400085,635.114197 701.031433,637.602478 
	C699.695740,639.226929 696.904053,641.046753 696.883911,642.896729 
	C696.728149,657.195618 697.108459,671.500366 697.261597,686.182983 
	C697.184814,687.073059 697.169067,687.583435 697.115112,688.554382 
	C697.316406,695.087097 697.555908,701.159119 697.821472,707.564453 
	C697.985474,708.320251 698.123352,708.742859 698.110352,709.435242 
	C696.973999,709.479065 695.988647,709.253052 695.010559,708.603271 
	C694.516724,704.444702 694.015503,700.709900 693.486206,696.647888 
	C693.558838,695.903320 693.659546,695.486023 693.986572,694.960632 
	C694.077759,693.212891 693.942749,691.573303 693.653992,689.594482 
	C693.924622,686.880310 694.721252,684.503723 694.710876,682.130615 
	C694.656494,669.692932 694.491882,657.253479 694.136230,644.821167 
	C694.058350,642.098633 693.046936,639.402893 692.371826,636.258850 
	C696.215515,631.777161 693.406189,626.011230 693.960876,621.427124 
	C689.885315,623.425598 686.025818,625.318054 681.854675,627.296387 
	C680.993286,627.723511 680.443665,628.064636 679.628296,628.359131 
	C678.924011,628.576660 678.485352,628.840942 677.667175,629.147644 
	C676.783752,629.263916 676.279846,629.337769 675.776001,629.411621 
M691.690308,601.440002 
	C692.350281,601.387634 693.010315,601.335266 693.670227,601.282959 
	C693.596558,600.996765 693.522888,600.710571 693.449280,600.424316 
	C692.912231,600.580933 692.375183,600.737488 691.690308,601.440002 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M739.947266,621.122620 
	C740.713928,621.079895 741.480591,621.037231 742.626953,621.466736 
	C743.542175,625.527161 741.643982,629.610107 744.328918,633.007080 
	C748.991028,628.913757 746.477417,624.799438 745.985474,620.824890 
	C746.843262,620.617004 747.700989,620.409180 748.893494,620.292603 
	C749.576599,622.987183 750.378174,625.632080 750.144104,628.181885 
	C749.917053,630.655457 748.626404,633.031250 747.766663,635.556458 
	C749.048950,635.804138 750.304382,636.046692 751.474548,636.272705 
	C751.579468,636.836792 751.788818,637.334473 751.672546,637.428284 
	C748.654236,639.860962 748.000916,642.552063 750.242432,645.962585 
	C750.619507,646.536438 749.678345,647.976746 749.080811,648.671509 
	C747.501099,645.909302 746.186829,643.492126 744.782410,640.909180 
	C742.200989,644.686035 742.376221,650.195801 744.622070,652.471985 
	C745.942078,651.316956 747.241760,650.179626 748.541443,649.042297 
	C748.871582,650.404968 749.201782,651.767700 749.688538,653.776794 
	C747.327209,654.397888 744.924927,655.029785 742.522583,655.661682 
	C742.510193,655.877319 742.497803,656.092957 742.485413,656.308594 
	C746.692383,656.308594 750.899414,656.308594 755.397583,656.308594 
	C755.397583,649.815552 755.397583,643.171631 755.928955,636.217712 
	C756.669067,635.909180 756.877808,635.910522 757.086548,635.911926 
	C757.381042,641.455688 756.404419,647.485901 758.292786,652.419617 
	C760.635498,658.540405 760.445923,664.809082 761.683228,671.439941 
	C761.003174,674.546509 760.559753,677.104370 759.821045,679.954102 
	C759.055054,682.491028 758.584229,684.736206 757.886047,686.931030 
	C757.344849,686.766296 757.136475,686.550293 757.038086,685.773499 
	C757.058472,678.781799 757.273865,672.239319 756.950012,665.723633 
	C756.869202,664.096985 755.050476,662.556641 754.221069,660.699097 
	C754.233521,659.621704 754.095093,658.138794 753.871277,658.125793 
	C751.053284,657.962097 748.209595,657.834534 745.398865,658.046753 
	C741.365173,658.351318 739.695374,656.933105 739.889709,652.662659 
	C740.168945,646.525146 739.855469,640.362000 739.844360,634.209656 
	C739.836487,629.847351 739.910034,625.484985 739.947266,621.122620 
M743.047607,635.716675 
	C743.453979,635.678467 743.860352,635.640259 744.266724,635.602051 
	C744.249390,635.454468 744.232056,635.306824 744.214661,635.159241 
	C743.730164,635.238098 743.245605,635.317017 743.047607,635.716675 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M792.280457,620.752563 
	C789.216431,620.279419 786.152405,619.806335 783.088440,619.333191 
	C783.059937,619.009583 783.031494,618.685974 783.002991,618.362305 
	C785.685059,618.240540 788.367065,618.118713 791.963562,617.984375 
	C798.622559,617.924011 804.367065,617.876282 810.548584,617.870605 
	C814.251221,617.550842 817.967773,619.297791 820.605774,615.725403 
	C820.848206,615.397095 822.653870,615.924988 823.532776,616.415527 
	C827.837341,618.817749 834.012756,617.837585 837.571350,614.323059 
	C838.315613,613.588013 839.805603,613.608093 841.907593,613.006897 
	C840.669556,614.891785 840.056641,615.825012 839.251465,617.050842 
	C842.905640,616.528015 846.047058,616.078552 849.304138,615.612488 
	C850.581421,612.521851 859.519958,610.141724 862.189575,612.415833 
	C862.633484,612.793945 863.031982,613.550049 863.002136,614.107361 
	C862.801392,617.848328 862.479126,621.582764 861.723389,625.273682 
	C857.580688,625.078003 853.910889,624.927856 850.148438,624.374512 
	C849.894592,622.521545 849.733521,621.071838 849.539185,619.322510 
	C845.238525,619.264771 840.917664,617.994812 838.189270,622.646606 
	C836.339722,620.001770 827.943481,618.453735 824.551453,620.239563 
	C824.130188,620.461426 824.083496,621.394653 823.587769,622.102417 
	C821.340942,621.719788 821.529175,616.961914 817.583008,619.626709 
	C814.811462,619.880066 812.471313,620.034546 809.733704,620.156006 
	C803.651001,620.332825 797.965759,620.542725 792.280457,620.752563 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M792.174316,621.205688 
	C797.965759,620.542725 803.651001,620.332825 809.707886,620.543396 
	C809.947327,623.323181 809.632874,625.788635 813.044373,626.325928 
	C813.031799,626.574524 813.106201,627.066711 813.004150,627.390625 
	C811.247559,630.299133 809.297424,629.966187 807.012817,628.512268 
	C806.173035,627.977844 804.955688,628.036743 803.903320,627.456482 
	C803.145020,626.292969 802.488403,624.957214 801.624878,624.806458 
	C799.221497,624.386841 796.732178,624.459290 794.276794,624.337463 
	C794.003418,624.655151 793.730103,624.972778 793.456787,625.290466 
	C794.760498,626.192017 795.953064,627.495483 797.395752,627.894531 
	C799.245056,628.405945 801.295593,628.189697 803.177979,628.596069 
	C802.950195,629.305542 802.803772,629.705933 802.212646,630.105103 
	C786.473206,630.113220 788.230774,629.533203 787.495911,633.993530 
	C790.524841,633.993530 793.462952,634.048035 796.398132,633.979858 
	C800.117432,633.893555 801.620667,634.994690 800.187012,639.066284 
	C797.971497,645.358337 793.519226,648.297852 786.878052,648.996948 
	C786.347473,648.963501 786.136047,648.939331 785.924561,648.915161 
	C790.938354,646.230652 796.752808,644.484924 798.871033,636.971863 
	C795.995605,636.971863 793.340454,636.931946 790.687012,636.982483 
	C787.711487,637.039246 785.045898,637.150696 784.937744,632.827759 
	C784.842285,629.008057 786.466980,627.718445 789.960144,627.908936 
	C790.416443,627.933777 790.888489,627.671570 791.668701,627.454651 
	C791.799744,625.552856 791.933960,623.605774 792.174316,621.205688 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M775.488525,686.028809 
	C773.111511,682.853027 774.891296,680.113464 776.928528,677.309021 
	C777.381531,678.659973 777.305725,680.047363 777.195129,682.069458 
	C778.971375,681.713196 780.347656,681.437195 782.146118,681.263672 
	C787.983582,681.326294 793.403320,681.385986 798.809509,681.144348 
	C799.666992,681.106018 800.463806,679.710632 801.590454,678.929810 
	C802.094177,678.918884 802.296082,678.920776 802.692688,678.928650 
	C803.116394,679.061523 803.360596,679.141968 803.709351,679.505920 
	C804.452637,683.281494 799.913696,694.172913 796.970764,694.599548 
	C792.295898,695.277283 787.286011,695.685303 782.781006,694.640747 
	C780.489380,694.109497 778.989929,690.161560 777.017212,687.541870 
	C776.430847,686.893311 775.959656,686.461060 775.488525,686.028809 
M798.235046,689.839783 
	C799.275024,687.888306 800.315002,685.936890 801.457947,683.792114 
	C793.293823,683.792114 785.853088,683.792114 778.412354,683.792114 
	C778.127625,684.224304 777.842957,684.656433 777.558228,685.088623 
	C779.550598,687.754211 781.246704,690.774536 783.731750,692.850220 
	C784.400574,693.408875 787.467590,691.096313 789.433716,690.101685 
	C788.933899,689.863831 788.434143,689.625977 787.934326,689.388062 
	C787.934326,688.151489 787.627747,686.781372 788.080383,685.741882 
	C788.269958,685.306458 790.069458,685.572021 791.137024,685.518860 
	C791.137024,687.984741 791.137024,690.450684 791.137024,693.861267 
	C793.075928,692.905945 795.479248,691.721863 798.235046,689.839783 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M781.723877,681.161194 
	C780.347656,681.437195 778.971375,681.713196 777.195129,682.069458 
	C777.305725,680.047363 777.381531,678.659973 777.180664,677.064697 
	C777.891602,674.965393 778.879272,673.074097 780.583984,671.075195 
	C785.410706,670.648132 789.523682,670.098389 793.627808,670.158325 
	C794.768677,670.174988 795.883118,671.998169 796.703247,672.995605 
	C795.784790,673.002808 795.172791,673.007019 794.068665,672.914917 
	C789.968506,673.939819 783.342712,669.475647 783.449768,678.146729 
	C782.646729,679.179138 782.185303,680.170166 781.723877,681.161194 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M810.111633,617.828491 
	C804.367065,617.876282 798.622559,617.924011 792.405273,617.940918 
	C791.974426,615.821716 792.016235,613.733337 792.358398,611.113037 
	C793.075500,610.360352 793.492249,610.139465 793.941406,610.336121 
	C793.973816,612.604431 793.973816,614.455200 793.973816,616.192749 
	C796.304443,615.397156 798.223755,614.001953 799.482727,614.452454 
	C803.105774,615.748840 803.459900,613.600891 803.916443,611.156738 
	C806.283386,611.098816 808.650391,611.040833 810.902771,611.712036 
	C810.562683,614.237000 810.337158,616.032715 810.111633,617.828491 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M788.853027,668.600952 
	C793.091431,665.978027 797.735413,663.736633 799.032227,656.940186 
	C795.621094,656.940186 792.455078,656.896606 789.290894,656.952576 
	C786.185181,657.007446 784.406189,656.005432 786.064026,652.265381 
	C787.204102,652.087219 787.702637,652.158752 788.256042,652.606445 
	C791.325256,653.302673 794.334534,653.813599 797.355286,653.893494 
	C800.975464,653.989258 802.612915,655.666748 800.924683,658.866638 
	C799.036377,662.445679 796.369202,665.613831 793.608032,669.025757 
	C791.734436,668.927429 790.293762,668.764160 788.853027,668.600952 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M793.908997,609.918579 
	C793.492249,610.139465 793.075500,610.360352 792.442078,610.670044 
	C792.168884,608.813660 792.112305,606.868469 792.069702,604.503174 
	C794.543396,603.974670 797.007324,603.738586 799.461121,603.817139 
	C800.534241,603.851379 801.586853,604.529114 802.858398,605.174805 
	C803.044800,606.399536 803.021606,607.364075 802.680908,608.830933 
	C800.036682,611.032471 797.762512,613.299377 794.987183,609.686035 
	C794.869080,608.786255 794.737549,608.218933 794.606079,607.651550 
	C794.420776,607.681519 794.235535,607.711426 794.050232,607.741394 
	C794.004456,608.465149 793.958679,609.188843 793.912903,609.923279 
	C793.912842,609.933960 793.908997,609.918579 793.908997,609.918579 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M755.456055,620.011292 
	C755.023743,619.482117 755.057800,619.012329 755.330139,618.128357 
	C755.828918,617.529480 755.974487,617.278076 756.004150,616.979248 
	C756.003235,616.998596 755.963928,616.990479 755.963928,616.990479 
	C757.319885,616.073547 758.675903,615.156616 760.355286,614.181152 
	C761.210815,614.883362 761.743042,615.644104 762.030518,616.800049 
	C761.950745,617.401062 762.115723,617.606812 762.280701,617.812561 
	C767.556091,617.889160 772.831543,617.965820 778.582520,618.464600 
	C779.063232,619.122498 779.068237,619.358276 779.073242,619.593994 
	C771.356262,619.752930 763.639343,619.911804 755.456055,620.011292 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M760.116272,679.662292 
	C760.559753,677.104370 761.003174,674.546509 761.815674,671.817200 
	C762.705933,677.839722 763.227173,684.033691 763.809448,690.907959 
	C763.817017,691.904114 763.815979,692.220154 763.546631,692.620117 
	C762.793030,692.781189 762.360352,692.858459 761.742981,692.915527 
	C761.558350,692.895264 761.192139,692.957336 761.077148,692.513794 
	C760.680115,687.934326 760.398193,683.798279 760.116272,679.662292 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M788.201233,652.230225 
	C787.702637,652.158752 787.204102,652.087219 786.326660,652.007202 
	C785.931763,651.228760 785.915649,650.458801 785.912048,649.302002 
	C786.136047,648.939331 786.347473,648.963501 786.921509,649.388794 
	C788.774414,650.190125 790.272034,650.957153 791.753662,650.927246 
	C798.180847,650.797607 804.603271,650.435486 811.213196,650.377319 
	C812.033569,649.888550 812.668213,649.180298 813.652588,648.420410 
	C816.065125,648.965820 818.127991,649.562988 820.403687,650.140991 
	C820.616516,650.121948 821.025330,649.988953 821.067200,650.323669 
	C821.085876,651.104248 821.062744,651.550110 820.728943,652.030396 
	C820.217102,652.119263 820.015930,652.173706 819.359253,652.195801 
	C808.669556,652.185730 798.435364,652.207947 788.201233,652.230225 
M814.381409,650.454529 
	C814.381409,650.454529 814.565369,650.549194 814.565369,650.549194 
	C814.565369,650.549194 814.533081,650.362549 814.381409,650.454529 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M779.019775,644.055664 
	C777.992554,645.762695 777.350769,647.442505 776.485962,649.706177 
	C776.324158,649.656921 774.533752,649.298340 774.551025,649.088562 
	C774.747559,646.702820 775.193115,644.334900 775.632446,641.973328 
	C775.804565,641.048218 776.128113,640.151306 776.642822,638.312012 
	C777.420288,638.312012 779.287048,638.312012 781.175781,638.312012 
	C781.358398,639.807495 781.555420,641.420776 781.823181,643.612671 
	C781.508728,643.666748 780.456970,643.847534 779.019775,644.055664 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M748.551147,649.048340 
	C747.241760,650.179626 745.942078,651.316956 744.622070,652.471985 
	C742.376221,650.195801 742.200989,644.686035 744.782410,640.909180 
	C746.186829,643.492126 747.501099,645.909302 748.699951,648.696045 
	C748.584534,649.065613 748.560791,649.054443 748.551147,649.048340 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M779.151245,650.929810 
	C782.201599,650.618408 783.200989,651.798584 782.726746,654.384949 
	C781.902222,658.881470 778.842468,658.309204 774.835999,657.593445 
	C776.348999,656.359192 777.334351,655.555420 778.500305,654.604248 
	C777.390503,653.810669 776.370728,653.081360 774.366211,651.647949 
	C776.511292,651.302124 777.630066,651.121826 779.151245,650.929810 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M763.867493,692.536377 
	C763.815979,692.220154 763.817017,691.904114 763.886597,691.352295 
	C767.523132,689.495789 771.143677,687.875305 775.126343,686.141785 
	C775.959656,686.461060 776.430847,686.893311 776.662659,687.647278 
	C772.754761,690.912048 769.023804,693.683472 763.867493,692.536377 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M788.428467,668.628662 
	C790.293762,668.764160 791.734436,668.927429 793.957642,669.044983 
	C796.137634,669.690308 797.535156,670.381287 799.002380,671.356201 
	C799.394043,672.094727 799.715942,672.549316 800.022583,673.390381 
	C800.007568,674.527161 800.007751,675.277405 799.698730,675.969543 
	C798.596191,674.938477 797.802979,673.965515 797.009705,672.992615 
	C795.883118,671.998169 794.768677,670.174988 793.627808,670.158325 
	C789.523682,670.098389 785.410706,670.648132 780.881470,670.982910 
	C782.975952,670.217712 785.489990,669.437073 788.428467,668.628662 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M757.033142,623.951233 
	C756.713318,624.862549 756.393494,625.773865 756.073669,626.685181 
	C755.762451,626.066833 755.451294,625.448547 755.464233,624.437073 
	C756.203247,624.013062 756.618225,623.982117 757.033142,623.951233 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M757.096191,635.538818 
	C756.877808,635.910522 756.669067,635.909180 756.148376,635.862915 
	C755.836426,634.551453 755.836426,633.284912 755.836426,632.018372 
	C756.140625,631.998657 756.444885,631.978943 756.749084,631.959229 
	C756.868042,633.028015 756.986938,634.096863 757.096191,635.538818 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M756.298279,629.341675 
	C756.259033,628.963379 756.381653,628.731750 756.504272,628.500122 
	C756.611694,628.597656 756.826355,628.729492 756.807983,628.786804 
	C756.729431,629.032593 756.582336,629.256409 756.298279,629.341675 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M756.886475,623.721802 
	C756.618225,623.982117 756.203247,624.013062 755.481506,624.083740 
	C755.696411,623.913147 756.218079,623.702820 756.886475,623.721802 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M762.254700,617.635864 
	C762.115723,617.606812 761.950745,617.401062 761.999146,617.148865 
	C762.212463,617.102417 762.228699,617.459229 762.254700,617.635864 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M779.416321,619.497437 
	C779.068237,619.358276 779.063232,619.122498 779.047363,618.533203 
	C779.277466,618.586792 779.518433,618.993835 779.416321,619.497437 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M965.802612,611.799438 
	C966.237854,613.545166 966.328186,615.118896 966.469055,617.573547 
	C964.175232,616.655029 962.690247,615.552307 961.234863,615.590149 
	C958.704529,615.656006 955.908691,615.881531 953.732361,617.015442 
	C949.323853,619.312134 940.509827,637.684509 940.952148,643.111511 
	C938.843689,643.710632 936.653503,644.333069 933.691711,645.174744 
	C933.065002,641.538513 932.458130,638.017273 931.750000,633.908691 
	C929.841370,633.915955 926.394714,633.672241 927.110229,628.093018 
	C927.428467,625.916748 927.727112,624.448120 928.025757,622.979492 
	C928.025757,622.979492 927.986938,622.978699 927.974243,622.992432 
	C930.471069,619.853271 933.596008,616.997253 935.303650,613.457458 
	C936.782227,610.392700 937.943115,607.779480 941.624512,607.544128 
	C946.314453,607.244202 951.034180,606.804749 955.699158,607.096130 
	C957.069641,607.181763 958.297913,609.544312 959.980591,611.260254 
	C961.164062,611.339539 963.310852,611.483459 965.802612,611.799438 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M959.995605,599.995667 
	C960.617188,601.080017 961.242432,602.173767 961.907959,603.594849 
	C960.868896,604.615479 959.296753,606.164856 958.785706,605.870667 
	C953.131958,602.616577 947.516968,604.270935 941.845398,605.538452 
	C940.600647,605.816650 939.279236,605.752380 937.458862,605.884705 
	C937.352783,597.043640 932.684448,591.859436 925.426575,589.947205 
	C924.526672,587.031067 923.847046,584.828613 923.167358,582.626221 
	C922.602966,582.784485 922.038574,582.942749 921.474121,583.100952 
	C921.699707,584.375183 921.925232,585.649414 922.166077,587.010193 
	C921.450928,587.259216 920.861816,587.676514 920.592834,587.525146 
	C917.992615,586.061523 913.648682,586.634155 914.089844,581.234741 
	C915.392029,579.709412 916.634216,577.894836 917.408997,578.076294 
	C922.610168,579.294556 928.193787,580.084229 932.730164,582.647034 
	C939.115967,586.254578 944.583374,591.452942 950.695312,595.599915 
	C952.740173,596.987305 955.556885,597.237244 958.020752,598.007141 
	C958.020752,598.007141 957.997070,598.002991 957.982605,598.334595 
	C958.651855,599.113281 959.335571,599.560364 960.019287,600.007446 
	C960.019287,600.007446 959.999207,600.005066 959.995605,599.995667 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M957.890808,597.690430 
	C955.556885,597.237244 952.740173,596.987305 950.695312,595.599915 
	C944.583374,591.452942 939.115967,586.254578 932.730164,582.647034 
	C928.193787,580.084229 922.610168,579.294556 917.408997,578.076294 
	C916.634216,577.894836 915.392029,579.709412 914.054932,580.829834 
	C913.567810,581.014343 913.391907,580.969543 913.012939,580.741516 
	C913.655823,578.282776 914.501709,576.007202 915.486511,573.457581 
	C917.200317,573.116943 918.775269,573.050415 921.147095,572.945923 
	C922.620728,572.960083 923.297424,573.012207 923.974121,573.064392 
	C925.585632,573.860779 927.120422,574.988831 928.825012,575.383301 
	C932.212036,576.167114 935.394714,576.275635 935.556091,581.334534 
	C935.610168,583.029297 939.259460,584.719421 941.402222,586.215637 
	C946.813660,589.994324 952.302795,593.661804 957.890808,597.690430 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M997.195557,626.754883 
	C997.142456,628.323120 996.765747,629.675903 996.164307,631.835693 
	C986.749817,627.429016 977.854248,623.430298 969.179810,618.998779 
	C968.079712,618.436890 968.137756,615.607910 967.833252,613.431030 
	C968.539368,613.280579 969.071533,613.528320 970.002319,614.152039 
	C971.439392,616.562012 972.564880,618.325012 975.001770,616.006165 
	C982.291809,619.517212 989.581848,623.028259 997.195557,626.754883 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M967.964478,610.489624 
	C967.927612,608.683167 968.125000,607.105591 968.387024,605.010986 
	C970.838562,605.933838 972.892151,606.706848 975.286865,607.608276 
	C974.322693,609.357971 973.594543,610.679199 972.864746,612.358643 
	C971.308350,612.050659 969.753540,611.384521 967.964478,610.489624 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M974.793701,615.745361 
	C972.564880,618.325012 971.439392,616.562012 970.278625,614.252441 
	C971.632751,614.479370 973.109192,614.981934 974.793701,615.745361 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M934.689697,603.551453 
	C935.034424,604.787903 935.125183,605.699036 935.216003,606.610168 
	C934.827393,606.603149 934.438843,606.596191 934.050232,606.589172 
	C934.178711,605.468140 934.307251,604.347168 934.689697,603.551453 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M933.819824,601.552307 
	C933.344788,600.977905 933.160217,600.142883 932.975708,599.307922 
	C933.211609,599.269836 933.447449,599.231689 933.683350,599.193604 
	C933.825623,599.893005 933.967957,600.592346 933.819824,601.552307 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M926.896729,649.644165 
	C926.776367,650.143188 926.655945,650.642273 926.297241,651.292480 
	C925.827209,651.940369 925.595520,652.437195 925.363831,652.934021 
	C925.209717,653.741577 925.055664,654.549194 924.640015,655.916992 
	C922.218079,657.312378 920.057678,658.147461 917.761353,659.284668 
	C917.696472,661.379883 917.767395,663.173096 917.846680,665.381104 
	C919.566101,666.213257 921.277100,666.630676 923.324341,667.214722 
	C924.593811,668.014221 926.188538,668.520874 926.337769,669.303162 
	C926.809082,671.774475 926.739197,674.348938 926.444153,676.891846 
	C923.517151,678.033630 919.747253,674.428223 917.929199,679.297607 
	C916.407410,679.510742 915.140747,679.436035 913.874023,679.361328 
	C913.274658,675.470215 912.675293,671.579102 912.408081,667.226562 
	C913.122986,665.932190 913.831848,665.099854 913.833374,664.266113 
	C913.840820,660.212463 913.748413,656.153320 913.515564,652.106201 
	C913.330200,648.885132 913.757324,646.600281 917.897156,646.934753 
	C919.804016,647.088745 921.777039,646.424622 924.011475,646.365295 
	C925.167542,647.614136 926.032166,648.629150 926.896729,649.644165 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M913.509521,679.252686 
	C915.140747,679.436035 916.407410,679.510742 918.277771,679.323181 
	C919.337097,679.159119 919.792725,679.257385 920.162720,679.764648 
	C920.077087,681.716248 920.077087,683.258972 920.077087,684.755371 
	C922.929443,684.755371 925.101318,684.755371 928.178589,684.755371 
	C925.601074,682.723511 923.757446,681.270203 922.010010,679.462036 
	C922.441101,678.775574 922.776001,678.444092 923.246582,678.416382 
	C926.629272,680.751221 929.876282,682.782349 933.400879,684.916748 
	C933.612488,686.214294 933.546509,687.408691 933.470581,688.782532 
	C933.620605,688.831421 934.245239,689.035156 934.911865,689.563354 
	C934.975037,690.322754 934.996216,690.757629 934.695312,691.264343 
	C933.716858,691.396545 933.060425,691.456970 931.924683,691.393799 
	C929.497925,691.343689 927.550537,691.417175 925.322021,691.425049 
	C924.698975,691.186218 924.357178,691.013000 924.072510,690.619263 
	C924.232605,690.091736 924.208740,689.795105 924.473267,689.454224 
	C925.850403,689.084351 926.812256,688.768982 928.877075,688.091980 
	C923.732300,686.565735 918.355896,688.701782 915.934082,683.359070 
	C915.865173,683.207031 914.562256,683.614380 913.506470,683.798950 
	C911.497009,682.496399 909.815796,681.155762 907.946960,679.512207 
	C906.891785,676.451294 905.367188,673.715759 905.272034,670.931274 
	C904.887634,659.681702 904.943787,648.417053 904.907776,637.089844 
	C904.978271,637.021790 905.120361,636.886658 905.120361,636.886658 
	C905.523010,636.916870 905.925598,636.947083 906.597534,637.443115 
	C906.905090,648.946960 906.835205,659.987610 907.089722,671.020691 
	C907.131348,672.825684 908.602600,674.597595 909.413086,676.384827 
	C910.657104,677.304565 911.901062,678.224304 913.509521,679.252686 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M909.604919,675.912476 
	C908.602600,674.597595 907.131348,672.825684 907.089722,671.020691 
	C906.835205,659.987610 906.905090,648.946960 906.900635,637.438171 
	C906.934448,636.967468 906.982178,636.972046 907.358276,636.917053 
	C908.505310,636.929443 909.276184,636.996765 910.047119,637.064209 
	C909.963684,649.856201 909.880188,662.648132 909.604919,675.912476 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M907.179626,631.729187 
	C906.896179,625.811890 910.096130,631.353333 911.554321,630.299683 
	C913.352661,629.126648 914.991638,628.192078 916.508728,627.089722 
	C918.185730,625.871277 920.469666,623.057251 921.241394,623.441833 
	C924.704041,625.167664 921.972229,627.612061 921.048401,629.639526 
	C919.221863,633.647461 917.831665,638.391235 911.067993,636.725342 
	C909.324280,636.212585 908.217346,636.018005 907.110413,635.823364 
	C907.090576,635.606140 907.070801,635.388977 906.999756,634.836304 
	C906.948547,634.500854 906.994446,634.482788 906.994446,634.482788 
	C906.994446,634.482788 906.960632,634.205627 907.246704,634.018799 
	C908.068481,633.669983 908.604065,633.508057 909.139709,633.346130 
	C908.486389,632.807129 907.833008,632.268127 907.179626,631.729187 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M923.720032,646.131470 
	C921.777039,646.424622 919.804016,647.088745 917.897156,646.934753 
	C913.757324,646.600281 913.330200,648.885132 913.515564,652.106201 
	C913.748413,656.153320 913.840820,660.212463 913.833374,664.266113 
	C913.831848,665.099854 913.122986,665.932190 912.369812,666.867004 
	C911.755188,660.415649 911.320007,653.858643 911.453369,647.313171 
	C911.475708,646.214172 913.831970,644.535706 915.332764,644.234131 
	C917.861023,643.726013 920.567871,644.106506 923.607971,644.457642 
	C923.917542,645.245178 923.818787,645.688293 923.720032,646.131470 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M924.011475,646.365234 
	C923.818787,645.688293 923.917542,645.245178 924.078125,644.466736 
	C924.259949,640.002625 924.063538,635.831787 924.604858,631.759033 
	C925.002991,628.763611 926.366516,625.896606 927.662842,622.975952 
	C927.727112,624.448120 927.428467,625.916748 926.950928,627.741089 
	C926.864563,632.573669 926.957031,637.050476 927.156860,642.172607 
	C927.227051,643.132324 927.185547,643.446167 927.139587,643.759460 
	C927.070557,645.455994 927.001465,647.152466 926.914612,649.246582 
	C926.032166,648.629150 925.167542,647.614136 924.011475,646.365234 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M910.007874,627.851685 
	C908.697449,626.990601 906.258972,626.080994 906.293579,625.277893 
	C906.387512,623.095459 907.528687,620.958069 908.478394,618.460938 
	C908.702148,618.119080 908.930725,618.362549 908.938721,618.835693 
	C911.894653,621.834229 910.404968,624.910828 910.007874,627.851685 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M909.991089,628.136536 
	C910.404968,624.910828 911.894653,621.834229 908.985596,618.908630 
	C914.399719,620.512146 914.399719,620.512146 910.971802,628.799316 
	C910.522888,628.894165 910.248596,628.657776 909.991089,628.136536 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M907.062744,636.099121 
	C908.217346,636.018005 909.324280,636.212585 910.648682,636.657410 
	C910.866211,636.907654 910.453857,636.971680 910.250488,637.017944 
	C909.276184,636.996765 908.505310,636.929443 907.347656,636.897034 
	C906.978882,636.746277 906.996948,636.560608 907.062744,636.099121 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M738.942566,539.346558 
	C742.921204,535.765625 745.219727,539.098938 748.091797,541.429749 
	C750.672668,544.937134 755.688416,546.822449 753.809814,552.681641 
	C752.457520,554.989929 751.170715,556.874451 749.708435,559.015869 
	C745.070740,556.884460 740.624695,554.867859 736.203064,552.798706 
	C733.705566,551.629944 733.424377,549.948853 734.842712,547.586914 
	C736.361755,545.057190 737.567078,542.339050 738.942566,539.346558 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M756.413452,552.019165 
	C757.134644,551.293030 758.194397,550.649963 759.630737,549.997681 
	C768.091553,553.957336 776.175720,557.926270 784.614075,561.956848 
	C783.763306,562.360474 782.560974,562.988098 781.353149,562.998596 
	C772.544739,563.075500 763.733521,563.100159 754.928711,562.900085 
	C754.119812,562.881714 753.349304,561.177124 752.909485,560.446533 
	C755.714050,561.076477 758.159424,561.804077 760.628296,561.894958 
	C765.908691,562.089417 771.201172,561.954529 776.488708,561.954529 
	C776.518005,561.391235 776.547302,560.827881 776.576599,560.264587 
	C775.046692,559.849731 773.516724,559.434875 771.882141,558.799927 
	C771.414795,558.594971 771.052185,558.610168 770.689514,558.625366 
	C771.133972,558.743042 771.578430,558.860657 771.972656,559.337585 
	C767.183655,560.036316 762.447449,560.477905 757.703308,560.590454 
	C757.087280,560.605042 756.430969,558.923218 755.793274,558.027222 
	C756.112854,556.052185 756.432434,554.077209 756.413452,552.019165 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M727.091125,564.103821 
	C727.425781,563.301636 727.760437,562.499512 728.265381,561.409668 
	C728.894287,560.516296 729.557495,559.976990 729.779053,559.294373 
	C731.526733,553.910583 732.662415,553.522278 737.731506,556.715088 
	C737.761169,556.876709 737.852173,557.055786 737.811768,557.197388 
	C735.761963,564.374573 735.799683,564.273254 742.816284,566.980835 
	C743.865356,567.385681 744.351624,569.249146 744.662231,570.831299 
	C743.158997,571.114319 742.091858,571.003723 741.014404,570.923462 
	C741.004028,570.953857 741.063232,570.978455 741.009644,570.638123 
	C736.970642,568.844727 732.985291,567.391663 728.994446,565.968506 
	C728.988953,565.998352 729.049622,566.000549 728.965942,565.676758 
	C728.471802,565.191345 728.061279,565.029846 727.596680,564.713989 
	C727.455872,564.344543 727.305420,564.192566 727.091125,564.103821 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M771.986816,559.020081 
	C773.516724,559.434875 775.046692,559.849731 776.576599,560.264587 
	C776.547302,560.827881 776.518005,561.391235 776.488708,561.954529 
	C771.201172,561.954529 765.908691,562.089417 760.628296,561.894958 
	C758.159424,561.804077 755.714050,561.076477 753.215210,560.220947 
	C753.861023,559.234924 754.549683,558.665466 755.515747,558.061584 
	C756.430969,558.923218 757.087280,560.605042 757.703308,560.590454 
	C762.447449,560.477905 767.183655,560.036316 771.962524,559.347290 
	C772.002747,558.997803 771.986816,559.020081 771.986816,559.020081 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M752.400940,573.171936 
	C752.776367,573.867981 752.828613,574.533813 752.510620,575.587769 
	C751.425476,575.987976 750.710693,576.000061 749.781738,575.740906 
	C748.385437,574.638367 747.203247,573.807129 746.012085,572.986267 
	C746.003113,572.996643 745.985718,573.019165 745.985718,573.019165 
	C748.016418,573.060059 750.047119,573.100891 752.400940,573.171936 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M741.024719,570.893188 
	C742.091858,571.003723 743.158997,571.114319 744.632935,571.259766 
	C745.306519,571.710449 745.573303,572.126160 745.912964,572.780518 
	C745.985718,573.019165 746.003113,572.996643 745.612183,573.011108 
	C743.910950,572.884399 742.600647,572.743286 741.242798,572.317383 
	C741.138489,571.652832 741.081604,571.273010 741.024719,570.893188 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M800.033569,596.448853 
	C800.521729,594.819519 800.958313,592.574402 801.613770,592.508606 
	C803.692200,592.299927 805.852295,592.904846 807.980774,593.195190 
	C808.163757,593.910767 808.346741,594.626404 808.529724,595.341980 
	C806.569458,596.878479 804.651245,598.481812 802.555725,599.803894 
	C802.413208,599.893860 800.937683,597.870789 800.033569,596.448853 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M814.012146,598.989868 
	C813.890259,598.846313 813.754211,598.710327 813.618164,598.574341 
	C813.701599,598.629089 813.784973,598.683899 813.926636,598.882874 
	C813.984802,599.027039 813.997925,598.997498 814.012146,598.989868 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M884.332275,606.362122 
	C881.102600,604.393372 877.950378,602.280029 874.621826,600.495728 
	C871.174133,598.647461 867.555420,597.118286 863.737854,595.190308 
	C864.012024,594.211304 864.732971,593.563660 865.069885,592.755798 
	C865.676453,591.301575 866.052795,589.751404 866.525391,588.241272 
	C867.523743,589.537781 868.522095,590.834229 869.792603,592.484192 
	C870.253967,591.043152 870.700684,589.647888 871.521729,587.083313 
	C873.438477,589.052307 874.943604,590.598450 876.403015,592.097717 
	C877.249329,590.803040 878.168640,589.396729 879.547119,587.288025 
	C880.267578,589.619202 880.715149,591.067505 881.169983,592.539246 
	C882.714966,591.564148 883.988525,590.760376 885.669189,590.297852 
	C886.519592,591.148560 886.963013,591.658142 887.406433,592.167664 
	C887.731567,592.739014 888.445557,593.415161 888.323975,593.866089 
	C886.297485,601.385864 893.268494,600.819519 896.812195,603.239746 
	C897.356934,603.611816 898.597229,603.050598 899.486938,602.812622 
	C900.885742,602.438660 902.259888,601.972595 903.644470,601.545532 
	C902.929626,602.992798 902.214844,604.440002 901.535400,605.815552 
	C907.024475,605.897095 904.271484,610.726013 904.440918,614.403564 
	C898.555176,615.110474 893.060913,615.345703 887.642578,615.223999 
	C887.741394,614.626953 887.764343,614.386963 888.000244,613.868835 
	C890.316956,611.333313 892.631592,609.065796 887.517456,606.969849 
	C886.289612,606.838684 885.450989,606.870117 884.574219,606.803162 
	C884.536133,606.704773 884.332275,606.362122 884.332275,606.362122 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M904.057007,633.722229 
	C904.347961,633.896606 904.581665,634.128662 904.582275,634.841797 
	C902.721924,635.412231 901.037476,635.559204 899.353088,635.706116 
	C899.354309,635.975830 899.355469,636.245605 899.356689,636.515320 
	C899.922424,636.729248 900.488159,636.943176 901.033325,637.612183 
	C900.971008,651.749817 900.929260,665.432373 900.468811,679.075806 
	C898.653687,678.945435 897.257202,678.854126 895.891235,678.364990 
	C895.604919,674.653809 895.288147,671.340454 895.313354,667.953369 
	C895.389343,667.251526 895.149292,666.090759 894.853333,666.076294 
	C891.195862,665.897827 887.530334,665.886475 883.895142,665.438721 
	C883.916260,662.339783 883.909058,659.634155 884.044861,657.234375 
	C885.112061,658.375488 886.036316,659.210876 887.126404,660.338196 
	C889.944580,663.116943 892.479736,666.057739 896.347168,663.347534 
	C895.518127,661.792603 894.785645,660.418823 894.303345,658.938843 
	C895.260925,658.887817 895.968384,658.943054 898.248779,659.120972 
	C896.450745,656.865112 895.275269,655.390381 894.080933,653.608459 
	C894.023438,652.893494 893.984924,652.485657 894.258057,651.792969 
	C894.713257,647.637878 894.856628,643.767639 894.995789,639.556335 
	C895.392395,637.922974 895.793213,636.630737 896.190796,635.348694 
	C894.738647,634.503662 893.395752,633.722229 891.986633,632.603027 
	C890.255249,632.480408 888.590088,632.695557 886.591003,632.886902 
	C885.215210,631.939209 884.173279,631.015259 883.092651,629.712769 
	C883.023315,628.574707 882.992737,627.815186 883.427063,627.060120 
	C890.531982,627.072937 897.171997,627.081299 903.812012,627.089600 
	C903.890747,628.804810 903.969482,630.519897 903.848572,632.702148 
	C903.784973,633.353577 903.921021,633.537903 904.057007,633.722229 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M904.033691,626.770752 
	C897.171997,627.081299 890.531982,627.072937 883.146301,626.702454 
	C882.222534,623.786072 882.044434,621.231873 881.870728,618.430115 
	C883.471741,617.944275 885.068359,617.705994 886.895508,617.433289 
	C887.013916,617.106628 887.290283,616.343811 887.566711,615.580994 
	C893.060913,615.345703 898.555176,615.110474 904.404297,614.833496 
	C904.596191,616.356750 904.433105,617.921692 903.686707,619.700806 
	C898.779358,619.036316 894.455322,618.157715 890.131226,617.279114 
	C890.023926,617.921753 889.916626,618.564392 889.809326,619.207031 
	C894.586975,619.557617 897.802673,626.412598 903.727783,621.985229 
	C903.903625,623.474121 904.079468,624.963013 904.033691,626.770752 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M903.765381,621.679932 
	C897.802673,626.412598 894.586975,619.557617 889.809326,619.207031 
	C889.916626,618.564392 890.023926,617.921753 890.131226,617.279114 
	C894.455322,618.157715 898.779358,619.036316 903.464417,620.030396 
	C903.817932,620.555542 903.810425,620.965088 903.765381,621.679932 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M891.491943,594.568604 
	C892.110779,592.940125 892.729614,591.311646 893.488098,589.315613 
	C895.872986,590.243713 897.763428,590.979370 901.086304,592.272522 
	C897.492004,593.607056 895.283813,594.426941 892.508667,595.103394 
	C891.791809,594.829590 891.641907,594.699097 891.491943,594.568604 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M901.053894,637.157104 
	C900.488159,636.943176 899.922424,636.729248 899.356689,636.515320 
	C899.355469,636.245605 899.354309,635.975830 899.353088,635.706116 
	C901.037476,635.559204 902.721924,635.412231 904.670898,635.074341 
	C904.969849,635.329895 905.004211,635.776428 905.079468,636.554810 
	C905.120361,636.886658 904.978271,637.021790 904.533325,637.012817 
	C903.076904,637.054932 902.065369,637.106018 901.053894,637.157104 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M907.117615,632.093384 
	C907.833008,632.268127 908.486389,632.807129 909.139709,633.346130 
	C908.604065,633.508057 908.068481,633.669983 907.244446,633.879211 
	C906.989258,633.436829 907.022461,632.947205 907.117615,632.093384 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M887.666809,591.971008 
	C886.963013,591.658142 886.519592,591.148560 886.038025,590.285767 
	C886.503662,589.757874 887.007446,589.583252 887.772461,589.724243 
	C887.998169,590.617920 887.962646,591.196167 887.666809,591.971008 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M904.058899,633.569214 
	C903.921021,633.537903 903.784973,633.353577 903.849854,633.139832 
	C904.050720,633.110352 904.060791,633.416199 904.058899,633.569214 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M906.918579,634.479370 
	C906.994446,634.482788 906.948547,634.500854 906.969116,634.510620 
	C906.989624,634.520447 906.842651,634.476013 906.918579,634.479370 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M892.153320,595.169922 
	C891.980103,595.244934 891.595276,595.109924 891.351196,594.771790 
	C891.641907,594.699097 891.791809,594.829590 892.153320,595.169922 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M881.866333,618.677612 
	C882.044434,621.231873 882.222534,623.786072 882.681396,626.697998 
	C882.992737,627.815186 883.023315,628.574707 883.063721,630.140442 
	C883.073547,633.262756 883.073547,635.578857 883.073547,639.208313 
	C879.438232,630.642456 878.775330,630.450806 872.026062,634.610596 
	C871.200867,635.119141 870.173401,635.287170 869.283997,635.706726 
	C867.783569,636.414673 866.318481,637.197632 864.673584,637.622131 
	C863.148071,631.746887 860.292297,638.308044 858.189392,636.242676 
	C858.448669,635.623291 858.778076,634.836365 859.401001,633.945679 
	C860.762512,632.859558 861.830505,631.877258 863.264526,630.972961 
	C866.221924,631.502930 868.813232,631.954834 871.962524,632.504150 
	C871.856384,630.413513 871.751709,628.351013 871.675171,626.843384 
	C868.675659,627.688904 866.326599,628.351074 863.894897,628.708130 
	C863.249695,627.515442 862.687012,626.627747 862.162292,625.638306 
	C862.200195,625.536438 862.196289,625.319275 862.196289,625.319275 
	C862.479126,621.582764 862.801392,617.848328 863.002136,614.107361 
	C863.031982,613.550049 862.633484,612.793945 862.189575,612.415833 
	C859.519958,610.141724 850.581421,612.521851 849.304138,615.612488 
	C846.047058,616.078552 842.905640,616.528015 839.251465,617.050842 
	C840.056641,615.825012 840.669556,614.891785 841.907593,613.006897 
	C839.805603,613.608093 838.315613,613.588013 837.571350,614.323059 
	C834.012756,617.837585 827.837341,618.817749 823.532776,616.415527 
	C822.653870,615.924988 820.848206,615.397095 820.605774,615.725403 
	C817.967773,619.297791 814.251221,617.550842 810.548584,617.870605 
	C810.337158,616.032715 810.562683,614.237000 810.920776,612.062256 
	C813.900391,611.200806 817.062500,611.344482 819.528137,610.103821 
	C824.475098,607.614746 829.351318,608.710938 834.654358,608.966919 
	C834.795410,607.512085 834.960449,605.809570 835.097168,604.399231 
	C832.913513,604.531311 831.033875,604.645081 828.960938,604.481934 
	C829.869568,600.995483 830.762451,597.688965 832.181152,594.625977 
	C832.713867,593.475952 834.526794,592.101929 835.654724,592.186218 
	C840.499268,592.548035 845.306030,593.414429 850.541748,594.069214 
	C850.956909,594.041382 850.971497,594.034180 851.101196,594.318054 
	C851.813538,594.747070 852.396057,594.892212 852.978760,595.020386 
	C852.978943,595.003479 852.963928,595.040283 852.990112,595.394409 
	C862.296631,600.230591 871.576965,604.712585 881.009155,609.451294 
	C881.796082,610.463684 882.431091,611.219360 883.027161,612.325195 
	C883.012939,613.143555 883.037659,613.611816 882.848450,614.358765 
	C879.977417,614.825684 877.320312,615.013855 874.663269,615.202026 
	C874.432800,615.684509 874.202332,616.167053 873.971863,616.649536 
	C873.388123,615.465149 872.804443,614.280823 872.313477,613.284668 
	C871.254639,613.776062 870.724731,613.888245 870.382263,614.203796 
	C868.847473,615.617981 865.605591,616.457214 867.568787,619.370605 
	C869.081543,621.615479 871.424561,622.176331 873.966003,620.196228 
	C874.663818,619.652527 876.050537,620.068115 877.086060,619.864990 
	C878.695190,619.549561 880.274414,619.081177 881.866333,618.677612 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M794.973877,610.018494 
	C797.762512,613.299377 800.036682,611.032471 802.669556,609.197998 
	C803.155823,609.617981 803.335999,610.173096 803.716309,610.942505 
	C803.459900,613.600891 803.105774,615.748840 799.482727,614.452454 
	C798.223755,614.001953 796.304443,615.397156 793.973816,616.192749 
	C793.973816,614.455200 793.973816,612.604431 793.941406,610.336121 
	C793.908997,609.918579 793.912842,609.933960 794.178284,609.950500 
	C794.620422,609.984253 794.797180,610.001404 794.973877,610.018494 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M735.971802,617.520508 
	C734.920532,617.069702 733.221252,616.350708 733.011963,615.327087 
	C732.560120,613.116638 732.872986,610.749817 732.872986,608.146545 
	C728.196167,608.389221 724.688965,601.400879 720.346558,607.620667 
	C714.056335,602.913269 713.481201,603.197632 711.532776,612.871948 
	C714.850403,612.025574 717.570862,611.331604 720.291321,610.637634 
	C720.274414,610.820007 720.257568,611.002380 720.240662,611.184753 
	C722.210632,611.052063 724.180359,610.916138 726.150574,610.787842 
	C727.716370,610.685913 729.282715,610.593323 730.848816,610.496643 
	C730.149780,612.979858 729.778503,615.652100 728.529358,617.818054 
	C728.202576,618.384644 725.147522,617.377930 723.576172,616.565552 
	C721.119812,616.565552 718.663452,616.565552 716.207092,616.565552 
	C709.301086,618.348938 701.823975,619.883850 697.322754,626.995728 
	C696.788208,620.272034 694.066101,614.005432 698.491089,607.859131 
	C701.778198,603.293335 704.622742,598.326477 707.029541,593.237976 
	C708.662598,589.785461 709.175110,585.806824 710.241516,582.078552 
	C710.416382,581.466980 710.863953,580.933350 711.175720,580.381104 
	C718.338989,583.257202 725.100952,581.332336 731.414062,578.731384 
	C730.912048,578.140503 729.985046,577.024902 729.031921,575.932068 
	C726.383118,572.894836 722.422241,573.446899 720.199463,576.597717 
	C719.065735,578.204834 716.779541,578.999023 715.014282,580.160645 
	C714.666260,579.672302 714.318298,579.183899 713.970276,578.695496 
	C715.399475,577.541626 716.828735,576.387756 718.780029,574.812378 
	C717.561096,574.558044 716.776489,574.394348 715.473755,574.098755 
	C714.810486,573.832703 714.665466,573.698425 714.520447,573.564209 
	C716.395264,572.058716 718.270020,570.553162 720.225403,568.692505 
	C721.290039,568.289246 722.274109,568.241028 723.972534,568.155029 
	C728.769470,570.056091 732.851990,571.995056 736.659302,574.119629 
	C736.670837,575.587585 736.957703,576.869934 737.385254,578.781494 
	C737.171631,578.946350 736.164124,579.724243 735.018372,580.608887 
	C737.088623,580.888916 738.965271,581.142822 740.641052,581.369507 
	C741.125000,579.620483 741.507690,578.237549 742.290894,576.851196 
	C743.489502,576.877563 744.287659,576.907227 745.396484,577.052246 
	C746.679626,577.990540 747.652100,578.813477 748.453247,580.058472 
	C748.188049,581.994080 748.093994,583.507629 747.563782,584.992065 
	C744.564514,585.105042 741.315979,583.535583 740.711365,588.177307 
	C740.594177,589.076904 738.018127,589.656250 736.576294,590.383301 
	C736.365112,589.973206 736.153931,589.563171 735.942749,589.153137 
	C737.075500,588.210999 738.208252,587.268860 739.601013,586.110474 
	C730.488647,586.110474 721.925720,586.110474 713.177979,586.110474 
	C713.177979,588.440125 713.177979,590.221008 713.177979,591.854248 
	C718.259644,594.828735 723.633484,590.236694 728.582397,592.965149 
	C728.979065,593.183838 729.961609,592.206726 730.724854,591.933044 
	C731.408936,591.687683 732.541870,591.275269 732.833496,591.570923 
	C736.151917,594.936523 739.345276,591.703430 742.548218,591.435425 
	C744.379150,591.282227 746.160645,590.538086 747.964355,590.060059 
	C747.964355,590.060059 748.106384,590.493652 748.115906,590.936890 
	C748.125427,591.380188 748.194397,591.867920 748.194397,591.867920 
	C748.106384,594.920593 748.018433,597.973206 747.542969,601.363281 
	C746.405701,600.906128 745.655945,600.111572 743.793152,598.137512 
	C742.828674,601.267700 742.139771,603.503601 741.450806,605.739502 
	C743.177246,605.933655 744.903687,606.127869 746.630066,606.322021 
	C749.437866,611.562805 748.654602,612.881897 743.030273,613.108704 
	C740.143066,613.225098 735.855042,611.455933 735.971802,617.520508 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M746.741516,606.051636 
	C744.903687,606.127869 743.177246,605.933655 741.450806,605.739502 
	C742.139771,603.503601 742.828674,601.267700 743.793152,598.137512 
	C745.655945,600.111572 746.405701,600.906128 747.482788,601.883423 
	C747.491028,603.304565 747.171997,604.542969 746.741516,606.051636 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M752.119873,613.387573 
	C753.181030,612.108887 754.238586,611.109436 755.844849,610.257202 
	C756.935486,610.588623 757.477539,610.772888 758.019592,610.957153 
	C757.516357,611.371948 757.013062,611.786743 755.950806,612.226990 
	C754.777039,613.145691 754.162231,614.038940 753.328613,614.911011 
	C752.815735,614.900696 752.527954,614.863708 752.246338,614.778931 
	C752.203003,614.408264 752.159668,614.037598 752.119873,613.387573 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M750.730957,603.922974 
	C752.070679,603.598145 753.177063,603.443848 755.771729,603.081970 
	C754.169189,604.549927 753.393982,605.260010 752.294922,606.079590 
	C751.479858,605.490540 750.988708,604.792053 750.730957,603.922974 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M752.270386,615.097473 
	C752.527954,614.863708 752.815735,614.900696 753.746460,614.977539 
	C754.660339,615.106689 754.937561,615.148071 755.139893,615.460449 
	C754.975891,616.093567 754.886719,616.455688 754.797607,616.817810 
	C754.424866,616.883545 754.052185,616.949280 753.402954,617.076782 
	C752.849060,616.564392 752.571716,615.990173 752.270386,615.097473 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M748.492249,591.905090 
	C748.194397,591.867920 748.125427,591.380188 748.205017,591.146912 
	C748.640747,590.920776 748.996826,590.927856 749.618103,590.915771 
	C749.911316,591.077942 749.939453,591.259277 749.977783,591.715698 
	C749.588623,591.974548 749.189392,591.958374 748.492249,591.905090 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M862.464539,593.451782 
	C862.008179,593.260925 861.953491,592.978455 861.834412,592.268555 
	C862.135437,592.347473 862.500793,592.853821 862.464539,593.451782 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M851.022827,592.274902 
	C852.120178,592.236877 853.179138,592.555237 854.579041,592.898560 
	C855.288513,593.370178 855.656982,593.816895 856.028564,594.617126 
	C856.031616,594.970642 855.933838,595.095520 855.564453,595.064331 
	C854.451294,595.035583 853.707642,595.037903 852.963928,595.040283 
	C852.963928,595.040283 852.978943,595.003479 852.827271,594.757080 
	C852.107544,594.351868 851.539551,594.193054 850.971497,594.034180 
	C850.971497,594.034180 850.956909,594.041382 850.944580,594.053467 
	C850.949585,593.587463 850.966980,593.109375 851.022827,592.274902 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M849.834106,591.152588 
	C850.295776,591.156311 850.591492,591.312622 850.971436,591.706482 
	C850.593140,591.731079 850.130676,591.518127 849.834106,591.152588 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M758.315369,610.826538 
	C757.477539,610.772888 756.935486,610.588623 756.179077,610.211182 
	C756.410950,607.408936 754.332764,603.002441 760.217651,604.088989 
	C760.515259,605.924988 760.801819,607.692810 761.108887,609.587280 
	C760.414001,609.895752 759.512573,610.295837 758.315369,610.826538 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M754.874146,616.887024 
	C754.886719,616.455688 754.975891,616.093567 755.327393,615.324585 
	C755.835693,614.720703 756.024231,614.478821 756.155212,614.192139 
	C756.170654,614.953796 756.186096,615.715454 756.082764,616.733765 
	C755.963928,616.990479 756.003235,616.998596 755.778320,617.016602 
	C755.553406,617.034668 755.100403,617.098511 755.100403,617.098511 
	C755.100403,617.098511 754.950745,616.956238 754.874146,616.887024 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M756.119019,613.830322 
	C756.024231,614.478821 755.835693,614.720703 755.402222,615.053589 
	C754.937561,615.148071 754.660339,615.106689 753.965332,614.998779 
	C754.162231,614.038940 754.777039,613.145691 755.689148,612.136230 
	C756.018616,612.502869 756.050659,612.985657 756.119019,613.830322 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M861.037415,596.949341 
	C864.265564,598.413269 867.493713,599.877197 870.864502,601.668823 
	C871.007141,601.996521 870.969055,602.045837 870.596802,602.034058 
	C867.139282,600.362122 864.054138,598.702026 860.986206,597.018921 
	C861.003540,596.995911 861.037415,596.949341 861.037415,596.949341 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M883.905701,606.267273 
	C884.332275,606.362122 884.536133,606.704773 884.504211,607.143188 
	C883.310059,607.662109 882.147766,607.742615 880.785339,607.573730 
	C878.718445,606.223145 876.851685,605.122009 874.993896,604.008606 
	C875.002869,603.996338 875.021973,603.970581 875.021973,603.970581 
	C877.841003,604.704529 880.660095,605.438477 883.905701,606.267273 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M856.081909,594.909363 
	C857.651306,595.344238 859.170349,595.840393 860.863403,596.642944 
	C861.037415,596.949341 861.003540,596.995911 860.634033,597.002380 
	C858.820984,596.371033 857.377380,595.733276 855.933838,595.095520 
	C855.933838,595.095520 856.031616,594.970642 856.081909,594.909363 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M871.027588,601.973022 
	C872.270935,602.419556 873.493896,602.889465 874.869385,603.665039 
	C875.021973,603.970581 875.002869,603.996338 874.664429,603.969910 
	C873.206970,603.310913 872.088013,602.678345 870.969055,602.045837 
	C870.969055,602.045837 871.007141,601.996521 871.027588,601.973022 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M748.000000,585.021179 
	C748.093994,583.507629 748.188049,581.994080 748.728027,580.008911 
	C749.456177,578.351379 749.738281,577.165466 750.013062,575.986938 
	C750.005859,575.994385 749.995850,576.012085 749.995850,576.012085 
	C750.710693,576.000061 751.425476,575.987976 752.492981,575.923401 
	C752.934448,578.892639 753.023254,581.914307 753.124146,585.503967 
	C751.663330,586.053894 750.190552,586.035889 748.361206,585.790588 
	C748.003113,585.382568 748.001526,585.201904 748.000000,585.021179 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M747.563843,584.992065 
	C748.001526,585.201904 748.003113,585.382568 748.000854,585.835022 
	C748.004333,587.160461 748.011536,588.214050 747.991577,589.663879 
	C746.160645,590.538086 744.379150,591.282227 742.548218,591.435425 
	C739.345276,591.703430 736.151917,594.936523 732.833496,591.570923 
	C732.541870,591.275269 731.408936,591.687683 730.724854,591.933044 
	C729.961609,592.206726 728.979065,593.183838 728.582397,592.965149 
	C723.633484,590.236694 718.259644,594.828735 713.177979,591.854248 
	C713.177979,590.221008 713.177979,588.440125 713.177979,586.110474 
	C721.925720,586.110474 730.488647,586.110474 739.601013,586.110474 
	C738.208252,587.268860 737.075500,588.210999 735.942749,589.153137 
	C736.153931,589.563171 736.365112,589.973206 736.576294,590.383301 
	C738.018127,589.656250 740.594177,589.076904 740.711365,588.177307 
	C741.315979,583.535583 744.564514,585.105042 747.563843,584.992065 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M753.531738,594.543518 
	C753.477173,594.574890 753.586304,594.512146 753.531738,594.543518 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M680.076050,544.486816 
	C679.716309,536.435547 679.278503,528.386047 679.088440,520.330811 
	C679.062622,519.234253 680.275208,518.108459 680.914429,516.996216 
	C681.418274,518.225708 682.308105,519.438354 682.362610,520.687439 
	C682.686890,528.116211 682.818054,535.553284 682.662109,543.559875 
	C681.701111,544.394897 681.092163,544.657593 680.498108,544.943359 
	C680.513000,544.966492 680.543579,544.922974 680.463745,544.774231 
	C680.383850,544.625488 680.075989,544.486816 680.076050,544.486816 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M676.164246,551.963196 
	C675.681152,549.316406 675.297180,546.581055 671.339966,546.789001 
	C671.031067,548.175293 670.719238,549.574768 670.451965,550.774170 
	C669.173218,549.373840 667.862000,547.937988 666.550781,546.502075 
	C665.723450,548.069397 664.896057,549.636719 664.182678,550.988159 
	C662.129395,546.214478 663.705444,543.729248 668.929749,544.195068 
	C670.719421,544.354675 672.494019,544.683289 674.651672,545.070190 
	C676.484497,545.030029 677.941223,544.854309 679.736938,544.582703 
	C680.075989,544.486816 680.383850,544.625488 680.418335,545.164185 
	C679.695251,547.821533 678.937683,549.940125 677.844971,552.019409 
	C677.061279,551.974426 676.612793,551.968811 676.164246,551.963196 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M696.515747,536.122070 
	C697.722229,536.843872 698.563416,537.522705 700.467468,539.059326 
	C695.919128,538.451782 691.266785,534.495972 690.250610,542.025513 
	C690.370667,542.973450 690.634033,543.119934 691.011780,543.061035 
	C691.011780,543.061035 691.010925,542.988586 690.941040,543.376587 
	C690.939270,544.542969 691.007263,545.321350 690.705139,546.063477 
	C689.354675,545.960876 688.374390,545.894531 687.259216,545.549927 
	C686.786194,544.762390 686.447937,544.253235 686.109741,543.744080 
	C687.637085,540.643738 689.164490,537.543335 690.860229,534.101196 
	C692.615112,534.757324 694.382751,535.418152 696.515747,536.122070 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M685.072632,520.815002 
	C686.894592,520.413574 688.678284,520.413574 691.686096,520.413574 
	C689.421082,525.267822 687.782837,528.778992 686.144531,532.290100 
	C685.774475,532.169739 685.404358,532.049316 685.034302,531.928955 
	C685.034302,528.358093 685.034302,524.787292 685.072632,520.815002 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M685.838989,543.715759 
	C686.447937,544.253235 686.786194,544.762390 687.218506,545.972778 
	C686.505127,550.773560 683.220581,548.191895 681.087402,548.549805 
	C680.828186,547.073364 680.655701,545.996826 680.483215,544.920288 
	C681.092163,544.657593 681.701111,544.394897 682.621399,544.016785 
	C683.811218,543.830139 684.689758,543.758789 685.838989,543.715759 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M720.306274,550.182556 
	C719.801941,552.840515 719.053833,555.217407 718.269958,557.707947 
	C716.789795,557.911377 714.891418,558.172241 712.532715,558.467041 
	C708.690674,556.332153 705.344604,554.104370 701.910889,552.021118 
	C700.364624,551.082947 698.643494,550.433044 697.168701,549.731689 
	C698.451111,547.681885 699.741821,545.618896 700.771790,543.972534 
	C702.048523,545.867432 703.006470,548.510559 704.890442,549.786377 
	C707.434814,551.509399 710.058289,550.165466 711.942749,547.354248 
	C712.000000,547.000000 712.002441,546.997559 712.002441,546.997559 
	C712.668762,548.891235 713.335144,550.784851 714.285950,553.487061 
	C716.638977,554.573853 715.735657,546.365051 720.306274,550.182556 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M691.075317,546.099792 
	C691.007263,545.321350 690.939270,544.542969 690.904907,543.374512 
	C694.906067,544.723877 694.631409,547.760376 693.320251,551.345215 
	C698.794861,553.911438 704.293823,556.488953 710.116943,559.321350 
	C710.357300,559.930908 710.273499,560.285583 709.704224,560.749512 
	C702.136963,557.577698 695.055115,554.296753 688.141968,550.742188 
	C689.232178,549.012390 690.153748,547.556091 691.075317,546.099792 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M690.914673,542.864563 
	C690.634033,543.119934 690.370667,542.973450 690.180786,542.384216 
	C690.435486,542.229858 690.661377,542.403625 690.914673,542.864563 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M711.998779,547.001221 
	C711.874023,546.863098 711.750488,546.723755 711.626892,546.584412 
	C711.717957,546.628784 711.809021,546.673096 711.951294,546.857544 
	C712.002441,546.997559 712.000000,547.000000 711.998779,547.001221 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M687.973267,551.015808 
	C695.055115,554.296753 702.136963,557.577698 709.551941,560.979614 
	C711.169983,562.258057 712.454895,563.415527 714.063477,564.726562 
	C713.244812,565.488037 712.102539,566.096008 710.936890,566.716492 
	C711.442627,567.835571 713.063538,569.750610 712.619507,570.712830 
	C709.860657,576.691650 706.638672,582.456787 703.179443,587.824463 
	C702.843994,586.909729 702.899353,586.463379 703.249023,585.701050 
	C705.109619,580.408875 702.294678,574.648193 707.426025,570.310181 
	C706.881531,570.046692 706.337036,569.783264 705.792480,569.519836 
	C704.025330,570.999390 702.258179,572.479004 700.981689,573.547729 
	C696.868774,573.547729 693.053650,573.547729 688.848145,573.547729 
	C687.986816,572.796692 686.643921,571.625793 685.457031,569.980835 
	C685.705261,564.122681 685.712524,558.734131 685.980957,553.358765 
	C686.021484,552.546631 687.278748,551.795227 687.973267,551.015808 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M736.934570,573.933960 
	C732.851990,571.995056 728.769470,570.056091 724.329102,568.048706 
	C724.803833,566.746521 725.636414,565.512817 726.780029,564.191406 
	C727.305420,564.192566 727.455872,564.344543 727.808594,564.935913 
	C728.399658,565.541504 728.724670,565.771057 729.049622,566.000549 
	C729.049622,566.000549 728.988953,565.998352 729.147461,566.292236 
	C733.225037,568.050232 737.144104,569.514343 741.063232,570.978455 
	C741.063232,570.978455 741.004028,570.953857 741.014404,570.923523 
	C741.081604,571.273010 741.138489,571.652832 741.119873,572.489990 
	C741.044373,572.947388 740.793823,573.280212 740.470093,573.258789 
	C739.075745,573.469543 738.005188,573.701782 736.934570,573.933960 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M692.020020,491.989868 
	C693.444519,489.928741 694.839600,487.871857 696.505981,485.415100 
	C694.849915,485.160980 693.543579,484.960510 692.237244,484.760040 
	C692.219299,484.326233 692.201416,483.892395 692.183472,483.458588 
	C693.877197,483.357727 696.946411,482.869385 697.044617,483.217957 
	C697.760376,485.759064 697.878906,488.468445 698.225342,491.464386 
	C696.058716,491.642487 694.455566,491.774261 692.426514,491.920532 
	C692.000610,491.935059 691.990601,491.994110 692.020020,491.989868 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M749.610107,540.811462 
	C748.279297,538.802002 747.253601,536.915405 746.334839,534.726440 
	C748.529907,532.534668 750.766602,530.781982 752.666260,528.718994 
	C754.758606,526.446777 756.399292,526.600281 758.437866,528.768616 
	C766.823242,537.688049 767.480164,535.404419 758.341736,543.368225 
	C757.475708,544.122925 756.653076,544.927551 755.682251,545.827759 
	C753.659729,544.111572 751.787476,542.522949 749.610107,540.811462 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M295.286804,387.741608 
	C293.973450,387.431183 293.040955,387.142853 291.950226,386.805573 
	C294.248138,385.166473 296.331055,383.680695 298.675781,382.008179 
	C299.318207,383.157410 299.901825,384.201447 300.660156,385.557953 
	C301.600128,384.742645 302.231049,383.796844 303.061646,383.564362 
	C304.460602,383.172821 306.018524,383.027740 307.435944,383.249603 
	C307.790680,383.305115 308.382202,385.558411 307.962158,386.095154 
	C307.034790,387.280151 305.556061,388.744568 304.260925,388.785431 
	C301.410095,388.875427 298.533691,388.153961 295.286804,387.741608 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M372.908447,451.631989 
	C373.747284,451.811584 374.661957,452.390778 375.842102,453.554138 
	C376.303253,461.169037 376.498901,468.199799 376.694550,475.230560 
	C375.598206,475.058655 374.501831,474.886719 373.147339,474.511566 
	C372.920898,466.882751 372.952576,459.457153 372.908447,451.631989 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M303.742615,437.599091 
	C303.575653,438.902283 303.078735,440.043701 302.154846,442.165924 
	C302.060760,440.044983 302.013519,438.979675 301.965759,437.565002 
	C302.447723,437.289551 302.930176,437.363434 303.742615,437.599091 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M301.955383,357.336365 
	C302.305878,357.174835 302.615723,357.317749 302.947388,357.732605 
	C302.617706,357.883301 302.266205,357.762054 301.955383,357.336365 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M318.051392,438.756989 
	C317.785980,438.828186 317.598694,438.662109 317.454071,438.464752 
	C317.428680,438.430115 317.582336,438.264282 317.828003,438.079102 
	C318.049744,438.169159 318.096863,438.339142 318.051392,438.756989 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1601.809814,833.882507 
	C1602.010010,833.915649 1602.210205,833.948853 1602.952393,834.023315 
	C1603.494141,834.064636 1603.967529,833.981689 1604.320068,834.051392 
	C1606.111450,837.399719 1607.550049,840.678406 1608.726562,844.178711 
	C1606.624268,846.936096 1604.783813,849.471924 1602.964111,852.005127 
	C1602.984863,852.002625 1602.942261,852.006348 1602.942261,852.006348 
	C1601.749146,852.297485 1600.555908,852.588623 1598.888062,852.995544 
	C1599.168579,851.473999 1599.401489,850.210510 1599.634399,848.947021 
	C1599.141235,848.718506 1598.648193,848.489990 1598.155151,848.261536 
	C1597.487793,849.779175 1595.843506,851.842163 1596.330811,852.715210 
	C1597.987549,855.683411 1595.904541,857.494690 1594.241333,857.565430 
	C1588.517944,857.808716 1582.761719,857.279297 1576.925903,856.260620 
	C1576.057617,851.699524 1576.327881,849.820190 1581.405273,850.535461 
	C1584.433960,850.962097 1587.868530,848.507690 1591.355347,847.264771 
	C1591.689087,847.710266 1592.484741,848.772766 1593.847656,850.592529 
	C1594.829346,847.468567 1595.538696,845.211121 1596.248047,842.953674 
	C1595.969604,842.818787 1595.691162,842.683899 1595.412720,842.549011 
	C1594.693481,843.479675 1593.974243,844.410339 1594.052368,844.309082 
	C1591.177246,843.425049 1588.869629,842.313354 1586.478027,842.086182 
	C1583.746948,841.826843 1580.937378,842.312256 1578.169312,842.557983 
	C1576.663940,842.691589 1575.173828,842.999329 1573.676880,843.228516 
	C1574.359009,844.720703 1575.041260,846.212891 1575.898926,848.088745 
	C1571.609497,849.728149 1574.636230,853.095215 1575.108521,856.107239 
	C1574.283813,858.828857 1572.021606,859.502930 1571.136230,857.029480 
	C1569.901245,853.579956 1567.689209,854.078613 1565.288208,854.050415 
	C1562.636597,854.019287 1559.969238,853.874084 1557.339722,854.115662 
	C1556.223877,854.218140 1554.381592,855.028748 1554.242432,855.773987 
	C1553.166504,861.533203 1550.937988,858.189941 1549.800781,857.275879 
	C1546.802368,857.920593 1544.573120,858.782593 1542.331055,858.817078 
	C1531.559814,858.982666 1520.785278,858.924744 1509.484009,858.488770 
	C1508.875122,851.309753 1514.939697,847.221558 1520.343872,850.188721 
	C1520.509521,848.822510 1521.227905,847.150085 1520.717896,846.044373 
	C1520.057129,844.611877 1518.385620,842.588135 1517.203247,842.623474 
	C1508.889038,842.872131 1508.894531,843.020569 1508.920532,834.674988 
	C1508.926025,832.898621 1508.921265,831.122192 1508.921265,828.047485 
	C1515.009277,828.047485 1520.418823,827.927917 1525.820068,828.086670 
	C1529.885376,828.206177 1533.940430,828.682617 1537.733887,829.263306 
	C1534.715210,833.802063 1535.451904,837.681885 1538.800293,841.575439 
	C1537.600220,842.295959 1536.399292,842.706665 1535.221924,843.109253 
	C1535.472290,844.870056 1535.430176,846.612610 1536.031250,848.094177 
	C1536.442261,849.107178 1537.750366,849.756287 1538.657471,850.568115 
	C1541.314209,847.443481 1544.694702,851.675720 1547.557861,848.962463 
	C1547.557861,850.923401 1547.557861,852.144531 1547.557861,853.390869 
	C1549.556519,852.461731 1551.305176,851.648804 1553.506958,850.950134 
	C1559.880249,851.064453 1565.800537,851.064453 1571.720825,851.064453 
	C1571.726074,850.304321 1571.731323,849.544250 1571.736572,848.784119 
	C1569.150635,848.614441 1566.564575,848.444824 1563.458008,848.241028 
	C1564.514648,846.204041 1565.476318,844.350281 1566.511230,842.355103 
	C1563.784546,841.683167 1562.088745,842.177795 1562.012207,845.489197 
	C1561.945312,848.382507 1560.374268,849.180725 1557.792725,847.640259 
	C1558.781372,846.002930 1559.740112,844.746155 1560.698730,843.489380 
	C1560.596069,843.069214 1560.493286,842.649109 1560.390625,842.228943 
	C1557.773804,842.854736 1555.156982,843.480530 1552.431152,844.132385 
	C1552.006226,843.266663 1551.434570,842.101746 1551.113647,840.689575 
	C1554.501953,836.725891 1554.452026,832.904358 1551.741821,828.995117 
	C1560.251831,828.674377 1568.761841,828.353638 1577.723267,828.394287 
	C1579.066650,830.142761 1579.958496,831.529785 1580.800171,832.838745 
	C1580.271118,833.016052 1579.264771,833.353333 1578.258423,833.690613 
	C1578.377441,834.250122 1578.496338,834.809570 1578.615234,835.369019 
	C1580.361206,834.882690 1582.107178,834.396362 1583.853149,833.910034 
	C1586.218140,836.286438 1588.822388,836.987671 1592.290283,834.638672 
	C1595.830078,835.218018 1598.904541,836.740356 1601.809814,833.882507 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1464.072754,830.019470 
	C1465.548218,829.837585 1467.023682,829.655701 1469.210449,829.207031 
	C1479.878418,828.922119 1489.836670,829.003540 1499.790649,828.827942 
	C1502.974609,828.771790 1504.232666,829.693420 1504.144287,833.034607 
	C1503.916138,841.655518 1503.982788,850.284302 1503.931641,858.909912 
	C1486.998291,859.028992 1470.065186,859.179688 1453.131592,859.248657 
	C1447.751221,859.270569 1446.711548,857.749695 1448.711426,852.219177 
	C1447.531982,852.944702 1446.823730,853.380249 1445.631104,854.155151 
	C1440.791870,852.798645 1439.935303,850.352051 1442.127197,845.050354 
	C1442.938477,844.374207 1443.473755,843.730713 1444.174805,842.837036 
	C1444.575684,842.049133 1444.810669,841.511475 1445.010986,840.978027 
	C1444.976318,840.982300 1444.975464,841.052002 1445.259644,840.896973 
	C1446.049194,839.813171 1446.554565,838.884338 1447.017944,837.968506 
	C1446.975952,837.981506 1446.971802,838.069275 1447.263306,837.835571 
	C1450.641357,836.987000 1453.727905,836.372131 1457.140015,835.856689 
	C1459.383301,835.956116 1461.301025,835.956116 1463.176270,835.956116 
	C1463.501099,833.804688 1463.786987,831.912109 1464.072754,830.019470 
M1455.716675,847.959900 
	C1457.197754,847.934692 1458.678955,847.909485 1460.757202,848.179749 
	C1464.343994,848.665710 1467.788940,846.087708 1471.655518,848.140991 
	C1472.881592,848.792053 1476.115723,847.399780 1477.329468,845.992554 
	C1480.337280,842.505676 1481.562866,845.217529 1482.603516,846.419250 
	C1485.391602,845.952393 1487.682617,845.568726 1490.118164,845.160828 
	C1490.118164,844.147339 1490.118164,842.257385 1490.118164,839.731140 
	C1488.504272,840.969604 1487.394409,841.821411 1487.185425,841.981750 
	C1483.118164,840.995605 1479.897705,840.214722 1476.365356,839.358154 
	C1475.505859,843.575928 1473.965698,846.128540 1470.082520,845.553711 
	C1469.616455,845.058350 1469.150269,844.562988 1468.684204,844.067627 
	C1470.510986,843.259644 1472.337769,842.451599 1474.164551,841.643616 
	C1473.736328,841.082764 1473.307983,840.521912 1472.879761,839.961060 
	C1471.231079,840.696960 1469.582520,841.432800 1467.585938,842.323975 
	C1465.373169,839.060608 1462.770142,840.071777 1459.510498,841.717712 
	C1457.855347,841.266418 1456.200195,840.815063 1453.857788,840.135498 
	C1452.393066,840.452087 1450.928467,840.768677 1449.463745,841.085327 
	C1449.474854,841.432678 1449.486084,841.780029 1449.497192,842.127380 
	C1450.676758,842.516357 1451.856323,842.905334 1453.610962,843.483948 
	C1451.641235,845.010681 1450.226440,846.107239 1448.811646,847.203796 
	C1448.941162,847.581116 1449.070557,847.958374 1449.200073,848.335632 
	C1451.076050,848.189880 1452.952148,848.044067 1455.716675,847.959900 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1601.404053,876.944946 
	C1597.567139,877.056946 1594.057739,877.201172 1590.587524,876.897034 
	C1588.038330,876.673706 1585.540649,875.862915 1582.328369,875.163269 
	C1581.309082,875.465698 1579.330811,876.052734 1577.420166,876.100098 
	C1577.985229,870.599121 1578.482666,865.637878 1578.979980,860.676636 
	C1591.678101,860.469055 1604.376221,860.261475 1617.549072,860.382019 
	C1618.023926,860.710144 1618.081177,861.199768 1618.081177,861.199768 
	C1615.241089,864.192749 1612.401001,867.185791 1609.454712,870.290771 
	C1608.067627,869.329346 1606.917236,867.992432 1606.274292,868.203186 
	C1605.080322,868.594666 1604.182617,869.889221 1603.161499,870.807800 
	C1607.171875,873.859863 1602.875610,874.947754 1601.404053,876.944946 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1504.360352,858.950500 
	C1503.982788,850.284302 1503.916138,841.655518 1504.144287,833.034607 
	C1504.232666,829.693420 1502.974609,828.771790 1499.790649,828.827942 
	C1489.836670,829.003540 1479.878418,828.922119 1469.450928,828.929321 
	C1467.881226,828.572449 1466.782471,828.226440 1465.683716,827.880432 
	C1465.705200,827.626892 1465.726562,827.373413 1465.747925,827.119873 
	C1489.932251,827.119873 1514.116699,827.119873 1538.684448,827.458618 
	C1538.711914,828.198242 1538.355957,828.599182 1538.000000,829.000122 
	C1533.940430,828.682617 1529.885376,828.206177 1525.820068,828.086670 
	C1520.418823,827.927917 1515.009277,828.047485 1508.921265,828.047485 
	C1508.921265,831.122192 1508.926025,832.898621 1508.920532,834.674988 
	C1508.894531,843.020569 1508.889038,842.872131 1517.203247,842.623474 
	C1518.385620,842.588135 1520.057129,844.611877 1520.717896,846.044373 
	C1521.227905,847.150085 1520.509521,848.822510 1520.343872,850.188721 
	C1514.939697,847.221558 1508.875122,851.309753 1509.022217,858.452515 
	C1507.655273,858.912903 1506.222290,858.952026 1504.360352,858.950500 
M1512.477905,848.637390 
	C1512.477905,848.637390 1512.399048,848.493652 1512.477905,848.637390 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1537.692871,866.780334 
	C1541.474731,865.764893 1542.205688,867.648132 1542.091675,870.642639 
	C1541.963501,874.013123 1541.093140,877.110352 1537.344116,877.430603 
	C1531.453003,877.933655 1525.504150,877.714966 1519.588379,877.989929 
	C1516.876831,878.116089 1516.681152,876.611816 1516.696289,873.822388 
	C1516.058228,872.342102 1515.540405,871.660828 1515.022705,870.979614 
	C1512.507935,873.994263 1510.876709,870.790100 1508.895508,869.653137 
	C1508.955933,866.491333 1509.008057,863.784302 1509.060303,861.077271 
	C1512.411377,861.031311 1515.762329,860.985352 1519.611206,861.379517 
	C1520.142090,863.605774 1519.880493,865.472778 1520.338623,867.142090 
	C1520.552124,867.919800 1522.160645,868.314575 1523.535400,868.974487 
	C1525.281616,868.990540 1526.629272,868.914978 1527.976807,868.839417 
	C1529.143188,868.067078 1530.309570,867.294800 1531.476074,866.522461 
	C1531.498901,866.881836 1531.521729,867.241211 1531.544678,867.600647 
	C1533.501221,867.425537 1535.457886,867.250488 1537.692871,866.780334 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1617.086548,832.167175 
	C1620.156006,831.726807 1623.225342,831.286499 1627.462036,830.678711 
	C1626.882935,840.157654 1626.345581,848.953430 1625.788452,858.071655 
	C1618.744873,858.071655 1612.277954,858.161011 1605.815186,858.039185 
	C1601.752075,857.962585 1601.187378,856.635010 1603.108398,852.385315 
	C1602.942261,852.006348 1602.984863,852.002625 1603.295410,852.300049 
	C1605.600586,851.786560 1607.595215,850.975769 1608.747559,850.507324 
	C1610.473145,851.188721 1611.738770,851.688538 1613.317139,852.427002 
	C1614.783813,852.700684 1615.945801,852.660339 1617.090210,852.783691 
	C1621.801392,853.291321 1623.602417,851.713806 1622.964111,846.578491 
	C1622.031250,839.076050 1622.399414,839.028320 1614.675171,839.071411 
	C1613.517212,839.077881 1612.359497,839.112671 1610.872559,838.959717 
	C1609.674805,836.481750 1608.806030,834.178406 1608.165527,831.703491 
	C1611.158691,833.559326 1614.209473,831.675537 1617.086548,832.167175 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1575.139282,876.238953 
	C1574.606567,876.898743 1574.073975,877.558533 1573.192139,878.269653 
	C1572.842896,878.320984 1572.415649,878.094238 1572.120972,877.808533 
	C1571.682983,877.299683 1571.489746,877.135132 1571.246582,877.029175 
	C1570.989380,874.558044 1570.021851,873.101746 1567.130615,873.310547 
	C1563.332275,873.584839 1559.507935,873.501282 1555.335205,873.761963 
	C1554.975586,873.952271 1554.560913,873.998230 1553.951660,873.964600 
	C1552.562622,872.523743 1551.292114,871.177673 1551.107300,869.696228 
	C1550.748657,866.820068 1551.000000,863.867859 1550.998535,860.947144 
	C1551.886353,861.133179 1552.988403,861.054260 1553.616211,861.561768 
	C1554.830444,862.543457 1555.740967,864.819641 1556.841309,864.861023 
	C1561.201904,865.025024 1565.623657,864.775452 1569.940308,864.129517 
	C1571.170776,863.945435 1572.123047,861.903076 1573.601318,861.288208 
	C1574.379639,866.655396 1574.759521,871.447144 1575.139282,876.238953 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1441.851440,845.082947 
	C1439.935303,850.352051 1440.791870,852.798645 1445.495728,854.401489 
	C1444.984863,856.360962 1444.125000,858.413391 1443.103027,860.809448 
	C1442.609619,861.218506 1442.278198,861.283813 1441.695557,861.320312 
	C1439.114258,861.242676 1436.784302,861.193787 1433.956787,861.242310 
	C1433.459229,861.339783 1433.184570,861.378662 1433.065186,861.308350 
	C1433.039307,860.674011 1433.132935,860.109924 1433.733398,859.450134 
	C1436.454956,859.284607 1438.669556,859.214783 1440.884277,859.145020 
	C1441.422729,858.537231 1441.961060,857.929443 1442.905273,856.863647 
	C1439.843018,854.239380 1436.768188,851.604431 1433.330078,848.565430 
	C1434.227783,846.676147 1435.488892,845.190918 1437.124390,843.264709 
	C1429.938110,843.153931 1429.938110,843.153931 1430.237427,850.997742 
	C1431.283813,850.171631 1432.111084,849.518494 1432.938354,848.865295 
	C1433.118530,851.983521 1431.820312,853.848511 1427.949219,853.722046 
	C1427.310303,849.680176 1427.272217,845.957764 1427.234131,842.235352 
	C1427.881104,840.783691 1428.712280,839.382446 1429.126587,837.867188 
	C1429.608765,836.103882 1429.725830,834.240784 1430.350830,832.752136 
	C1430.700195,835.765137 1430.700195,838.446899 1430.700195,840.805847 
	C1434.732910,842.574463 1440.473511,838.009216 1441.851440,845.082947 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1502.114258,871.354492 
	C1497.149292,871.393616 1492.184204,871.416870 1487.219727,871.489075 
	C1486.478271,871.499817 1485.728149,871.898010 1485.003052,871.842651 
	C1483.333618,871.715027 1481.677124,871.418579 1480.015381,871.190674 
	C1479.924316,871.620544 1479.833130,872.050354 1479.742065,872.480225 
	C1478.428101,871.543579 1477.114136,870.606934 1475.632080,869.320679 
	C1476.871826,868.871399 1478.295410,868.588318 1479.684937,868.705811 
	C1484.011475,869.071594 1486.627930,867.459534 1486.950073,862.279541 
	C1487.006104,861.488647 1487.052734,861.310059 1487.099365,861.131470 
	C1492.399658,861.104004 1497.699951,861.076538 1503.506104,861.511780 
	C1503.990479,864.627441 1503.968994,867.280334 1503.552490,870.044067 
	C1502.809814,870.554810 1502.462036,870.954712 1502.114258,871.354492 
M1489.089355,865.263672 
	C1488.961914,865.361572 1488.807861,865.439575 1488.714966,865.563354 
	C1488.632446,865.673523 1488.617432,865.834351 1488.572510,865.972778 
	C1490.203735,865.972778 1491.834961,865.972778 1493.466187,865.972778 
	C1493.421753,865.491516 1493.377319,865.010254 1493.332886,864.528992 
	C1492.134644,864.730957 1490.936279,864.932861 1489.089355,865.263672 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1575.376709,856.119019 
	C1574.636230,853.095215 1571.609497,849.728149 1575.898926,848.088745 
	C1575.041260,846.212891 1574.359009,844.720703 1573.676880,843.228516 
	C1575.173828,842.999329 1576.663940,842.691589 1578.169312,842.557983 
	C1580.937378,842.312256 1583.746948,841.826843 1586.478027,842.086182 
	C1588.869629,842.313354 1591.177246,843.425049 1594.052368,844.309082 
	C1593.974243,844.410339 1594.693481,843.479675 1595.412720,842.549011 
	C1595.691162,842.683899 1595.969604,842.818787 1596.248047,842.953674 
	C1595.538696,845.211121 1594.829346,847.468567 1593.847656,850.592529 
	C1592.484741,848.772766 1591.689087,847.710266 1591.355347,847.264771 
	C1587.868530,848.507690 1584.433960,850.962097 1581.405273,850.535461 
	C1576.327881,849.820190 1576.057617,851.699524 1576.772583,855.861755 
	C1576.355835,856.208008 1576.000366,856.169434 1575.376709,856.119019 
M1585.054077,846.330627 
	C1584.821289,845.445435 1584.588501,844.560181 1584.355591,843.674988 
	C1583.797485,843.930115 1582.737915,844.250671 1582.761353,844.429932 
	C1582.932007,845.738098 1583.320312,847.017883 1583.639893,848.306763 
	C1584.106445,847.865967 1584.572998,847.425232 1585.054077,846.330627 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1550.698730,860.977173 
	C1551.000000,863.867859 1550.748657,866.820068 1551.107300,869.696228 
	C1551.292114,871.177673 1552.562622,872.523743 1553.745361,873.995117 
	C1553.671631,875.300232 1553.194946,876.541138 1552.718262,877.782043 
	C1551.583740,877.224854 1550.449341,876.667725 1549.176636,876.042664 
	C1547.129395,878.664612 1545.729858,878.733582 1544.816772,875.322266 
	C1545.858765,873.752075 1546.841675,872.606079 1546.919922,871.401428 
	C1547.143311,867.958130 1546.999023,864.490906 1546.999512,861.033203 
	C1548.132690,861.024536 1549.265869,861.015869 1550.698730,860.977173 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1546.558838,860.930298 
	C1546.999023,864.490906 1547.143311,867.958130 1546.919922,871.401428 
	C1546.841675,872.606079 1545.858765,873.752075 1544.924072,874.949463 
	C1543.838257,871.933228 1543.117310,868.892395 1542.311279,865.492310 
	C1541.562500,865.139893 1540.287964,864.540039 1538.698853,863.710327 
	C1538.285278,863.216003 1538.153809,862.968811 1537.972168,862.201782 
	C1537.969604,861.440369 1537.984741,861.216248 1538.000000,860.992126 
	C1540.706055,860.937195 1543.411987,860.882324 1546.558838,860.930298 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1617.078125,831.752869 
	C1614.209473,831.675537 1611.158691,833.559326 1607.780029,831.715454 
	C1606.100098,832.593262 1605.033813,833.287415 1603.967529,833.981689 
	C1603.967529,833.981689 1603.494141,834.064636 1603.256714,834.027344 
	C1602.817017,826.749207 1603.030762,826.633728 1611.011230,828.361084 
	C1612.471436,828.677124 1614.035156,828.515503 1615.821533,828.297363 
	C1616.091919,828.020264 1616.534668,828.011108 1616.767334,828.005981 
	C1617.000000,828.000916 1616.987305,828.025269 1616.987305,828.025269 
	C1617.014893,829.129700 1617.042358,830.234070 1617.078125,831.752869 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1575.427490,876.183228 
	C1574.759521,871.447144 1574.379639,866.655396 1574.001343,861.392700 
	C1575.440186,860.810669 1576.877686,860.699524 1578.647461,860.632507 
	C1578.482666,865.637878 1577.985229,870.599121 1577.187378,875.816589 
	C1576.496582,876.091064 1576.106079,876.109314 1575.427490,876.183228 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1523.136841,868.882874 
	C1522.160645,868.314575 1520.552124,867.919800 1520.338623,867.142090 
	C1519.880493,865.472778 1520.142090,863.605774 1520.070068,861.391357 
	C1521.925659,861.061401 1523.820068,861.159729 1525.578857,861.854248 
	C1525.269653,863.651367 1525.096069,864.852295 1524.922363,866.053162 
	C1524.944458,866.235657 1524.966675,866.418213 1524.739014,866.845337 
	C1524.038330,867.687561 1523.587524,868.285217 1523.136841,868.882874 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1503.947510,869.933228 
	C1503.968994,867.280334 1503.990479,864.627441 1503.968018,861.536743 
	C1505.352539,861.049255 1506.781128,860.999512 1508.635010,861.013550 
	C1509.008057,863.784302 1508.955933,866.491333 1508.485107,869.649658 
	C1506.693481,870.045044 1505.320435,869.989136 1503.947510,869.933228 
M1507.013916,865.501282 
	C1506.902954,864.659668 1506.792114,863.817993 1506.681152,862.976379 
	C1506.554932,862.996521 1506.428589,863.016602 1506.302246,863.036743 
	C1506.302246,864.722839 1506.302246,866.408997 1506.302246,868.095093 
	C1506.471558,868.101501 1506.640869,868.107849 1506.810059,868.114258 
	C1506.889404,867.483459 1506.968750,866.852661 1507.013916,865.501282 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1562.996704,858.057129 
	C1560.718506,859.386108 1558.749023,860.532898 1556.141479,862.051331 
	C1555.999390,859.998474 1555.897705,858.530334 1555.729736,856.101624 
	C1558.539185,856.101624 1560.916504,856.096619 1563.291138,856.175110 
	C1563.314697,856.175964 1563.301514,857.283813 1562.996704,858.057129 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1560.339966,861.929199 
	C1563.156738,860.280457 1565.720947,858.843140 1568.607544,857.225037 
	C1568.607544,859.404480 1568.607544,860.975464 1568.607544,863.264343 
	C1565.566162,862.863159 1562.826782,862.501831 1560.339966,861.929199 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1475.859131,863.024902 
	C1477.100830,862.556274 1478.000977,862.249207 1478.901123,861.942139 
	C1478.886597,862.880371 1479.223389,864.393494 1478.795288,864.655151 
	C1477.123413,865.677246 1475.209473,866.303345 1473.385498,867.076599 
	C1473.099487,866.576721 1472.813599,866.076782 1472.527588,865.576843 
	C1473.524292,864.780029 1474.520996,863.983215 1475.859131,863.024902 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1525.299438,865.886353 
	C1525.096069,864.852295 1525.269653,863.651367 1525.744995,862.234192 
	C1527.080078,862.246887 1528.113403,862.475830 1529.598999,863.150757 
	C1530.196289,864.010803 1530.341431,864.424866 1530.486450,864.838989 
	C1528.883057,865.132507 1527.279785,865.426025 1525.299438,865.886353 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1530.814331,864.922852 
	C1530.341431,864.424866 1530.196289,864.010803 1529.880127,863.315002 
	C1530.829224,862.153748 1531.949463,861.274292 1533.069702,860.394775 
	C1533.902100,861.330383 1534.734619,862.265930 1535.567139,863.201538 
	C1534.092163,863.803223 1532.617188,864.404968 1530.814331,864.922852 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1523.535400,868.974487 
	C1523.587524,868.285217 1524.038330,867.687561 1524.778564,867.118347 
	C1526.039307,867.524780 1527.010498,867.902771 1527.979248,868.560059 
	C1526.629272,868.914978 1525.281616,868.990540 1523.535400,868.974487 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1616.988647,828.007385 
	C1616.977295,827.272949 1616.977295,826.532043 1616.977295,825.791138 
	C1617.240356,825.784546 1617.503418,825.777893 1617.766479,825.771240 
	C1617.719482,826.453247 1617.672485,827.135254 1617.306396,827.921265 
	C1616.987305,828.025269 1617.000000,828.000916 1616.988647,828.007385 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1627.799072,856.155762 
	C1628.329468,855.985474 1628.741699,856.152893 1629.154053,856.320312 
	C1628.940063,856.723755 1628.725952,857.127197 1628.511841,857.530640 
	C1628.234863,857.184937 1627.958008,856.839172 1627.799072,856.155762 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1537.697021,860.928955 
	C1537.984741,861.216248 1537.969604,861.440369 1537.912109,861.999878 
	C1537.379272,862.035522 1536.888916,861.735901 1536.398438,861.436218 
	C1536.730347,861.246033 1537.062256,861.055847 1537.697021,860.928955 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1606.263916,871.994873 
	C1605.691895,871.961670 1605.382080,871.631775 1605.072266,871.301941 
	C1605.556885,871.434021 1606.041626,871.566101 1606.263916,871.994873 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1618.312256,861.083618 
	C1618.081177,861.199768 1618.023926,860.710144 1618.009277,860.464478 
	C1618.177612,860.468323 1618.360474,860.717896 1618.312256,861.083618 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1486.867920,861.117920 
	C1487.052734,861.310059 1487.006104,861.488647 1486.831787,861.913940 
	C1486.564087,861.907715 1486.403076,861.662659 1486.303467,861.394653 
	C1486.285400,861.345947 1486.519043,861.203552 1486.867920,861.117920 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1281.982666,775.973755 
	C1283.603638,774.996460 1285.224487,774.019165 1287.206543,772.824097 
	C1287.206543,774.002747 1287.206543,774.937012 1287.206543,776.651794 
	C1288.580811,775.379883 1289.372559,774.647034 1290.252075,773.591736 
	C1292.112793,774.640320 1293.885742,776.011414 1295.262207,777.756836 
	C1293.907837,779.388733 1292.949829,780.646423 1291.654907,782.070679 
	C1289.863403,782.590637 1288.408813,782.944092 1286.782715,783.006958 
	C1282.162842,782.499817 1279.974731,784.292603 1281.042847,788.980103 
	C1280.504883,789.306335 1279.968262,789.914978 1279.428955,789.917358 
	C1267.052002,789.971069 1254.674805,789.958740 1241.757324,789.958740 
	C1241.757324,787.456848 1241.757324,785.210815 1241.783936,782.264221 
	C1241.576538,781.044922 1241.342773,780.526062 1241.186523,779.679932 
	C1243.521118,779.352661 1245.777832,779.352661 1248.034668,779.352661 
	C1248.414795,779.007080 1248.795044,778.661438 1249.175171,778.315857 
	C1246.788452,776.433105 1244.401611,774.550415 1242.008545,772.301514 
	C1241.627930,770.624451 1241.253662,769.313416 1240.955078,767.675232 
	C1242.351440,766.298828 1243.672241,765.249512 1245.328979,764.444763 
	C1250.309814,764.689270 1254.954712,764.689270 1259.599731,764.689270 
	C1259.571655,764.289978 1259.543579,763.890747 1259.515625,763.491455 
	C1255.364258,762.992981 1251.213013,762.494507 1247.187012,761.682129 
	C1249.836304,758.504272 1252.359985,755.640381 1255.242920,752.840027 
	C1255.750366,752.973145 1255.961914,753.001770 1256.037476,753.118469 
	C1257.696533,755.675354 1259.277710,758.285156 1261.001465,760.797241 
	C1264.141724,765.373596 1266.188477,758.145386 1268.966064,760.331909 
	C1268.322876,762.252136 1267.632935,763.812561 1267.448608,765.430481 
	C1267.401245,765.846313 1269.387939,767.061768 1269.684692,766.850159 
	C1270.948853,765.948853 1272.074951,764.746887 1272.941528,763.448059 
	C1273.282593,762.936829 1272.813721,761.885010 1272.896973,760.758179 
	C1276.060181,758.283142 1279.040039,756.129395 1282.070068,754.354248 
	C1282.438843,758.839539 1282.757446,762.946167 1283.075928,767.052856 
	C1283.989868,769.499817 1285.471802,772.020447 1281.627808,774.318115 
	C1278.634644,775.435547 1276.338867,777.458313 1273.277588,775.362427 
	C1272.111206,775.171448 1271.284424,775.104004 1270.457031,775.096558 
	C1263.271484,775.031799 1263.271484,775.034973 1263.357910,782.036438 
	C1263.441284,788.800842 1263.442261,788.828796 1270.282593,788.607300 
	C1271.567627,788.565735 1272.842163,788.202271 1274.531006,787.960815 
	C1277.287842,783.947449 1279.635254,779.960571 1281.982666,775.973755 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1181.031372,780.072266 
	C1179.841309,777.195068 1177.728760,774.200134 1182.558105,772.396362 
	C1183.973511,773.824585 1184.886230,775.781433 1185.805054,775.784302 
	C1189.302124,775.795288 1192.794189,775.079712 1196.302002,774.825928 
	C1198.501953,774.666809 1200.726318,774.847168 1203.110352,775.145569 
	C1204.180786,775.291016 1205.080933,775.166138 1206.395508,775.071167 
	C1207.917114,775.081421 1209.024048,775.061768 1210.357910,775.027710 
	C1210.584595,775.013367 1211.037842,774.980347 1211.358643,775.007202 
	C1212.103638,775.019287 1212.527954,775.004517 1212.958862,774.969727 
	C1212.965576,774.949768 1212.918701,774.942993 1212.802246,775.339111 
	C1212.609375,778.867126 1212.425537,782.002258 1212.518799,785.129028 
	C1212.548828,786.132935 1213.254883,787.942017 1213.745850,787.974854 
	C1217.344360,788.215820 1221.035034,788.482361 1224.539673,787.858887 
	C1225.577271,787.674316 1226.111938,784.663147 1227.067383,782.727661 
	C1227.490967,781.976501 1227.724243,781.444946 1228.211670,780.771240 
	C1228.947388,780.072754 1229.428955,779.516357 1230.097168,778.718506 
	C1230.609009,777.743164 1230.934204,777.009216 1231.563232,776.149048 
	C1233.081909,775.097107 1234.296753,774.171326 1235.725464,773.561523 
	C1236.113770,774.062866 1236.287964,774.248291 1236.398560,774.678223 
	C1236.258545,775.244690 1236.182129,775.566589 1236.083252,776.355957 
	C1236.314331,781.182983 1236.567627,785.542542 1236.821045,789.902222 
	C1219.845825,790.034607 1202.870728,790.227295 1185.895508,790.221802 
	C1183.815796,790.221130 1180.179810,792.917603 1180.396973,787.471680 
	C1183.691162,786.782166 1186.467529,784.297668 1189.515259,787.011108 
	C1189.964233,787.410828 1191.778931,786.276733 1193.308105,785.810059 
	C1194.656372,785.695557 1196.276733,785.985901 1196.564331,785.495117 
	C1199.405029,780.647278 1200.827881,783.839722 1201.709351,785.632690 
	C1204.681519,785.401978 1206.925415,785.227783 1209.202759,785.050964 
	C1209.202759,783.278259 1209.202759,781.280273 1209.202759,778.721497 
	C1207.459717,779.839111 1206.286743,780.591248 1204.946777,781.450378 
	C1201.124512,776.639038 1196.355469,779.289856 1191.552734,779.257690 
	C1188.972412,779.280029 1186.779419,779.281860 1184.596680,779.433044 
	C1183.399292,779.515991 1182.219238,779.850952 1181.031372,780.072266 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1290.394409,769.992554 
	C1295.175537,762.917664 1300.144775,755.608643 1309.621582,752.977051 
	C1313.787964,758.601929 1310.946045,763.606995 1308.341797,768.473633 
	C1307.759644,769.561768 1305.866943,770.557007 1304.582153,770.551331 
	C1299.985107,770.531067 1295.224243,773.446472 1290.394409,769.992554 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1291.991699,781.904053 
	C1292.949829,780.646423 1293.907837,779.388733 1295.139282,778.114685 
	C1299.721680,775.768005 1304.030518,773.437744 1308.339478,771.107544 
	C1308.817871,771.487427 1309.296265,771.867310 1309.774658,772.247253 
	C1309.774658,777.460022 1309.934937,782.682678 1309.616943,787.876038 
	C1309.563843,788.742432 1307.431274,789.481506 1305.723511,790.644348 
	C1305.183350,787.504333 1304.814575,785.360962 1304.445679,783.217529 
	C1304.055176,783.221313 1303.664673,783.225098 1303.274170,783.228882 
	C1303.045044,785.201294 1302.815918,787.173706 1302.488525,789.992004 
	C1300.005615,789.992004 1297.422363,789.992004 1294.429199,789.676880 
	C1294.035034,788.918884 1294.051025,788.476135 1294.067017,788.033325 
	C1294.067017,788.033325 1294.010254,788.001038 1294.351074,788.095093 
	C1294.814331,787.793945 1294.936646,787.398743 1295.058838,787.003540 
	C1295.058838,787.003540 1295.000000,786.999573 1295.344238,786.968323 
	C1298.101562,782.581909 1294.879395,782.365845 1291.991699,781.904053 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1192.961670,785.852295 
	C1191.778931,786.276733 1189.964233,787.410828 1189.515259,787.011108 
	C1186.467529,784.297668 1183.691162,786.782166 1180.421143,787.084656 
	C1179.696411,786.779541 1179.379272,786.489563 1178.753906,786.083191 
	C1178.461670,785.244446 1178.477661,784.522095 1178.483643,783.437134 
	C1179.216675,782.262695 1179.959473,781.450806 1180.866821,780.355591 
	C1182.219238,779.850952 1183.399292,779.515991 1184.596680,779.433044 
	C1186.779419,779.281860 1188.972412,779.280029 1191.564209,779.546753 
	C1191.976685,780.263733 1191.986084,780.650024 1191.678711,781.196045 
	C1190.549072,781.984802 1189.736084,782.613708 1187.907959,784.028076 
	C1190.131226,784.028076 1191.099365,784.028076 1192.312256,784.047791 
	C1192.691895,784.662415 1192.826782,785.257385 1192.961670,785.852295 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1237.239014,789.912109 
	C1236.567627,785.542542 1236.314331,781.182983 1236.473145,776.385010 
	C1237.615967,774.593262 1238.376465,773.254883 1239.068237,771.881958 
	C1239.710205,770.608032 1240.278687,769.297058 1240.879395,768.002380 
	C1241.253662,769.313416 1241.627930,770.624451 1241.882080,772.646240 
	C1241.309448,775.550659 1237.079956,777.373413 1241.108887,780.007263 
	C1241.342773,780.526062 1241.576538,781.044922 1241.705322,781.858032 
	C1241.071777,783.638611 1240.672852,785.189087 1239.975708,786.591492 
	C1239.380127,787.789856 1238.442871,788.818359 1237.239014,789.912109 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1315.008667,780.961670 
	C1314.997437,781.733215 1314.986206,782.504700 1314.950928,783.662842 
	C1314.534058,784.047974 1314.141235,784.046570 1313.408081,783.590454 
	C1313.219849,778.262939 1313.372070,773.390076 1313.875000,768.601440 
	C1315.831787,769.173950 1317.437744,769.662231 1319.043823,770.150452 
	C1319.002930,770.926880 1318.962158,771.703308 1318.521484,772.851196 
	C1317.088501,774.043213 1315.221558,774.863403 1315.221069,775.684631 
	C1315.220459,776.873840 1316.569946,778.063721 1317.165527,778.979431 
	C1316.960327,779.168030 1315.984497,780.064819 1315.008667,780.961670 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1319.313354,769.970276 
	C1317.437744,769.662231 1315.831787,769.173950 1313.946289,768.364990 
	C1313.787964,767.371399 1313.908936,766.698486 1314.030029,766.025574 
	C1314.299561,765.813110 1314.569336,765.600647 1315.215088,764.997620 
	C1316.151367,762.195435 1316.711548,759.783813 1317.271729,757.372192 
	C1318.358276,759.395691 1319.444824,761.419189 1320.635254,763.636230 
	C1319.317871,764.751221 1317.971558,765.890869 1316.093872,767.480103 
	C1318.210815,767.480103 1319.793335,767.480103 1322.070557,767.480103 
	C1321.004150,768.470337 1320.293579,769.130188 1319.313354,769.970276 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1317.176758,756.915649 
	C1316.711548,759.783813 1316.151367,762.195435 1315.296387,764.747131 
	C1314.663208,760.549072 1314.325073,756.210938 1313.971924,751.419434 
	C1313.956787,750.966003 1314.033447,750.964966 1314.247559,750.886169 
	C1314.707520,750.624512 1314.889893,750.391846 1315.008911,750.109375 
	C1315.764771,751.053650 1316.520752,751.997986 1317.260254,753.311157 
	C1317.189941,754.606445 1317.135864,755.532837 1317.176758,756.915649 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1293.705811,790.621460 
	C1299.240723,790.871277 1304.775757,791.121155 1310.310669,791.371033 
	C1304.281616,792.760193 1298.277222,794.174377 1292.422363,791.432007 
	C1292.978027,790.835693 1293.341919,790.728577 1293.705811,790.621460 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1281.310547,789.149658 
	C1279.974731,784.292603 1282.162842,782.499817 1286.822754,783.123718 
	C1287.034424,783.531006 1287.074341,783.775391 1287.074341,783.775391 
	C1286.123413,784.239685 1285.172485,784.703979 1284.221558,785.168274 
	C1284.880737,785.946838 1285.539917,786.725342 1286.601074,787.749756 
	C1287.002930,787.995667 1287.221436,788.180725 1287.221436,788.180725 
	C1285.527954,789.490234 1284.176025,792.493103 1281.310547,789.149658 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1313.802612,765.777771 
	C1313.908936,766.698486 1313.787964,767.371399 1313.595703,768.280762 
	C1313.372070,773.390076 1313.219849,778.262939 1313.113281,783.610352 
	C1313.402588,785.446411 1313.646362,786.807800 1313.589844,788.564392 
	C1312.901123,788.933533 1312.512573,788.907532 1312.124023,788.881531 
	C1311.752808,782.143188 1311.266846,775.407166 1311.132568,768.664124 
	C1311.112183,767.636047 1312.720337,766.575500 1313.802612,765.777771 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1287.236084,784.140381 
	C1287.074341,783.775391 1287.034424,783.531006 1286.994263,783.414246 
	C1288.408813,782.944092 1289.863403,782.590637 1291.654907,782.070679 
	C1294.879395,782.365845 1298.101562,782.581909 1295.352173,786.996704 
	C1294.188721,786.233521 1293.481567,784.943909 1292.507080,784.693176 
	C1290.894653,784.278320 1289.109985,784.532837 1287.236084,784.140381 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1293.742554,790.475708 
	C1293.341919,790.728577 1292.978027,790.835693 1292.345703,791.038696 
	C1291.136230,790.532715 1290.195435,789.930908 1289.254517,789.329102 
	C1290.584839,788.889526 1291.915283,788.450012 1293.656372,788.021912 
	C1294.051025,788.476135 1294.035034,788.918884 1293.972534,789.714661 
	C1293.926025,790.067627 1293.779297,790.329895 1293.742554,790.475708 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1312.220703,789.133545 
	C1312.512573,788.907532 1312.901123,788.933533 1313.578369,788.917114 
	C1313.350586,789.044983 1312.833862,789.215271 1312.220703,789.133545 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1286.895264,787.901245 
	C1286.971558,787.365479 1287.155518,786.924133 1287.714966,785.581787 
	C1287.628296,786.961243 1287.601685,787.385437 1287.398193,787.995117 
	C1287.221436,788.180725 1287.002930,787.995667 1286.895264,787.901245 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1294.801758,787.084839 
	C1294.936646,787.398743 1294.814331,787.793945 1294.356201,788.127380 
	C1294.195068,787.765747 1294.369751,787.465942 1294.801758,787.084839 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1964.833862,852.794678 
	C1962.188110,853.531860 1959.542236,854.269043 1955.445190,855.410522 
	C1957.833374,856.124207 1958.793579,856.516052 1959.791382,856.681519 
	C1960.911011,856.867249 1962.064697,856.848328 1964.552612,857.001587 
	C1963.658936,860.135681 1962.872681,862.893250 1961.938721,865.960693 
	C1958.430298,868.990906 1955.820557,865.316040 1952.546631,864.799988 
	C1952.823608,862.174255 1953.345215,859.962158 1953.762817,857.353210 
	C1953.862061,852.755371 1953.306885,849.661011 1947.686035,849.882080 
	C1939.458008,850.205811 1939.446167,849.903625 1938.617676,856.077515 
	C1935.857422,859.119263 1933.428223,862.038208 1930.996338,864.617188 
	C1930.117432,863.731750 1929.241577,863.186279 1928.236572,862.439331 
	C1927.922852,861.968201 1927.686401,861.756531 1927.209717,861.239868 
	C1923.521973,860.501770 1920.022339,860.126709 1916.522583,859.751709 
	C1916.469849,860.359924 1916.416992,860.968201 1916.364136,861.576416 
	C1919.823364,861.805969 1923.282471,862.035461 1926.863525,862.469849 
	C1927.183594,862.931274 1927.427612,863.132446 1927.791748,863.658936 
	C1928.367065,865.041992 1928.868164,866.044250 1929.203369,867.366638 
	C1928.830933,868.120911 1928.624023,868.554993 1928.417236,868.989014 
	C1924.169800,866.456787 1924.169800,866.456787 1921.670288,869.210938 
	C1922.849365,869.210938 1924.012085,869.201172 1925.173950,869.226562 
	C1925.287231,869.229004 1925.395752,869.448669 1925.977051,870.073608 
	C1925.235107,870.996582 1924.409546,872.023499 1923.314697,873.282410 
	C1908.323242,873.720337 1893.601196,873.926270 1878.420654,874.252686 
	C1871.163696,874.565491 1874.855469,868.009033 1871.257446,864.180176 
	C1870.856323,867.961243 1870.576416,870.600342 1870.168091,873.673462 
	C1869.573120,874.161438 1869.106689,874.215332 1868.309082,874.389709 
	C1854.431274,874.590881 1840.884644,874.672729 1827.337891,874.750793 
	C1825.549561,874.761108 1823.761230,874.752136 1822.723145,874.752136 
	C1821.808960,871.903931 1821.105347,869.712036 1820.401855,867.520142 
	C1818.824219,868.430847 1817.246582,869.341675 1815.335449,870.401123 
	C1814.191406,865.772278 1811.115112,869.062500 1808.845215,869.328613 
	C1809.828125,869.612427 1810.811035,869.896179 1812.093872,870.266602 
	C1811.061035,871.489624 1810.242065,872.459473 1809.281494,873.730347 
	C1808.160767,873.607239 1806.515015,873.367920 1806.335693,872.721863 
	C1805.916870,871.211853 1806.202148,869.506592 1806.202148,867.900146 
	C1805.013184,870.335693 1803.905640,872.604370 1802.399170,874.936829 
	C1799.303833,874.954224 1796.607666,874.907898 1793.503296,874.877930 
	C1793.901001,873.930176 1794.560669,872.373352 1795.544678,872.130310 
	C1797.363281,871.681091 1799.372070,872.001770 1801.301636,872.001770 
	C1801.326538,871.433105 1801.351562,870.864502 1801.376587,870.295837 
	C1799.454956,870.295837 1797.393188,870.726257 1795.655762,870.160461 
	C1794.250366,869.702820 1793.235474,868.045715 1792.300781,866.183716 
	C1794.414795,865.049438 1796.275513,864.652039 1798.484375,864.421387 
	C1801.762695,864.125122 1806.659180,864.445618 1807.219849,863.038452 
	C1808.446045,859.961243 1808.775024,853.887390 1807.114746,852.865784 
	C1803.965942,850.928162 1799.161865,851.680725 1794.902222,851.303101 
	C1794.653442,853.895142 1794.498047,855.514343 1794.097900,857.374512 
	C1791.948608,860.168274 1790.044189,862.721008 1788.139771,865.273682 
	C1786.691772,865.357422 1785.243652,865.441223 1783.417236,865.546875 
	C1783.295410,866.863464 1783.170532,868.211121 1783.128174,868.668335 
	C1780.847656,868.807434 1778.889648,868.926880 1776.931641,869.046326 
	C1776.949829,869.581482 1776.968018,870.116577 1776.986206,870.651672 
	C1778.125977,870.730652 1779.265747,870.809570 1780.405396,870.888550 
	C1780.466553,871.383728 1780.527588,871.878906 1780.588623,872.374084 
	C1774.004150,877.980408 1765.829346,873.766907 1758.609863,875.453003 
	C1758.416992,875.075500 1758.223999,874.698059 1758.031006,874.320557 
	C1760.783325,871.332764 1764.182495,868.715515 1766.151001,865.278748 
	C1770.044678,858.480896 1775.793091,857.196594 1782.775757,857.905640 
	C1784.572998,858.088135 1786.425659,857.724304 1788.180664,856.583740 
	C1784.137085,856.080139 1780.093506,855.576538 1776.065186,854.663452 
	C1777.244263,852.118835 1779.226562,850.056763 1779.424561,847.835693 
	C1780.139038,839.820557 1780.496948,831.752380 1780.511597,823.703369 
	C1780.522339,817.847290 1779.605835,811.994812 1779.442383,806.131165 
	C1779.351807,802.881409 1778.135132,799.148254 1781.783936,796.676453 
	C1781.907715,796.592529 1781.046265,795.054382 1780.198730,793.993530 
	C1779.753540,793.793030 1779.485474,793.785217 1779.485474,793.785217 
	C1779.452881,792.645447 1779.420288,791.505615 1780.055664,790.128174 
	C1797.009033,789.896423 1813.295410,789.995667 1829.579956,789.872009 
	C1838.816650,789.801819 1848.049438,789.257385 1857.286499,789.155518 
	C1860.425171,789.120850 1861.253174,787.857910 1861.190918,784.949158 
	C1861.078491,779.694397 1863.331909,775.265137 1867.044189,771.712463 
	C1869.131348,769.715027 1871.690186,768.210693 1874.396851,766.381226 
	C1880.579102,767.343262 1878.832397,771.863831 1878.642456,775.349915 
	C1878.041870,786.369629 1878.588623,787.552246 1886.563232,789.785339 
	C1886.994385,792.550049 1887.420410,795.281921 1887.861572,798.329102 
	C1887.892944,799.064758 1887.909302,799.485291 1887.699219,799.933350 
	C1887.472778,799.960876 1887.016479,799.971802 1886.644165,800.032593 
	C1886.191162,801.068787 1886.110474,802.044128 1885.677979,803.147339 
	C1883.085693,803.992126 1880.845093,804.709167 1878.604492,805.426208 
	C1878.452271,804.999146 1878.300049,804.572083 1878.147827,804.145020 
	C1879.503174,803.047363 1880.858521,801.949707 1882.213745,800.852112 
	C1881.774170,800.345581 1881.334595,799.839111 1880.895020,799.332642 
	C1879.261719,800.911377 1877.378662,802.326965 1876.167358,804.181519 
	C1875.858032,804.655151 1877.756592,807.608337 1878.503052,807.553223 
	C1881.611816,807.324158 1885.219727,807.236755 1886.166992,803.039429 
	C1886.372681,803.139587 1886.811157,803.269043 1886.831787,803.651611 
	C1887.450439,808.897949 1887.450439,808.897949 1891.257080,808.120850 
	C1891.504883,808.116455 1891.978882,807.971619 1892.373779,807.995972 
	C1898.510010,807.626160 1902.183105,804.460022 1904.607910,799.132507 
	C1904.974243,798.359314 1905.132935,797.796082 1905.577637,796.998291 
	C1906.861450,789.986267 1910.788452,788.076355 1917.397461,788.718689 
	C1924.271729,789.386780 1924.320557,788.884216 1925.604736,782.187073 
	C1928.397217,782.017822 1930.749512,781.869263 1933.466553,781.782104 
	C1934.870728,780.917175 1935.910400,779.990906 1936.931519,779.081055 
	C1938.529541,779.883362 1940.246582,780.745422 1942.058960,781.916321 
	C1942.378540,783.696594 1942.602661,785.167908 1942.825073,786.627625 
	C1946.299683,785.279480 1949.786865,786.906189 1961.121826,796.702332 
	C1966.183838,801.077087 1962.525757,807.191895 1963.563721,812.681274 
	C1958.849487,812.942322 1954.771362,813.168152 1950.693237,813.393982 
	C1953.179321,814.522217 1955.822266,813.916687 1957.963135,814.693970 
	C1960.067871,815.458252 1962.987549,817.442017 1963.233643,819.212524 
	C1963.975342,824.548767 1963.397461,830.052917 1963.712646,835.469604 
	C1963.919067,839.018860 1963.047729,841.660339 1959.644775,843.282715 
	C1958.462891,842.629395 1957.220459,841.942627 1955.668701,841.077637 
	C1954.357056,839.752747 1953.354980,838.606079 1952.447876,837.568176 
	C1948.741333,839.827026 1949.334229,846.539246 1942.310059,845.805115 
	C1943.877075,844.004700 1944.993652,842.721741 1946.110229,841.438843 
	C1945.880615,841.145386 1945.651123,840.851990 1945.421509,840.558594 
	C1943.713379,841.546997 1942.005371,842.535461 1940.100952,843.637573 
	C1938.013916,837.843445 1935.483276,841.143982 1932.822754,843.084229 
	C1931.565186,842.141357 1930.462646,841.502319 1929.360229,840.863281 
	C1928.594238,842.302734 1927.828247,843.742188 1926.695557,845.113953 
	C1925.558960,843.730957 1924.788818,842.415649 1924.236572,841.472412 
	C1922.490601,842.787842 1920.863892,844.013428 1918.194824,846.024353 
	C1922.048828,846.670349 1924.407349,847.065735 1926.946777,847.751343 
	C1929.757324,848.003113 1932.386963,847.964661 1935.232422,848.172974 
	C1937.242798,848.028809 1939.521362,846.741211 1940.747925,847.403137 
	C1945.863892,850.164062 1948.504028,846.669067 1951.761841,844.011292 
	C1952.729858,843.221558 1954.507080,843.423950 1955.912720,843.536499 
	C1956.000244,845.048706 1956.088501,846.196045 1956.103149,846.386353 
	C1958.052002,845.883850 1959.896729,845.408203 1962.011475,844.862976 
	C1962.994629,847.626099 1963.914307,850.210449 1964.833862,852.794678 
M1878.014038,812.144348 
	C1877.708618,814.049072 1877.403076,815.953857 1877.097656,817.858582 
	C1878.694580,817.514099 1880.291382,817.169556 1882.617310,816.906494 
	C1887.396729,819.408691 1892.273438,817.731201 1897.842896,816.953308 
	C1900.546509,817.105591 1903.274170,820.490601 1906.659546,816.689819 
	C1908.614258,815.265686 1910.568970,813.841553 1913.224365,811.906860 
	C1913.817139,814.897827 1914.090332,816.276794 1914.168701,816.672180 
	C1916.937134,816.482178 1919.234863,816.324463 1921.646484,816.158936 
	C1921.646484,815.054016 1921.646484,813.080322 1921.646484,812.402344 
	C1919.067871,811.915039 1917.262573,811.743408 1915.565186,811.221069 
	C1910.590332,809.689941 1907.776978,812.866455 1904.139893,816.143066 
	C1903.225586,816.059937 1902.311279,815.976746 1900.609375,815.821899 
	C1902.660156,813.960022 1903.950317,812.788635 1905.240479,811.617188 
	C1905.019897,811.289612 1904.799194,810.962036 1904.578491,810.634460 
	C1902.904541,811.359436 1901.230591,812.084412 1899.556641,812.809448 
	C1899.531250,812.388855 1899.505859,811.968262 1899.480469,811.547668 
	C1897.572388,811.665588 1895.664429,811.783569 1893.462769,812.250183 
	C1893.462769,812.250183 1893.626221,812.675659 1893.559692,812.691956 
	C1893.559692,812.691956 1893.508545,812.737366 1892.809692,812.750549 
	C1891.967041,813.035461 1890.490723,813.728394 1890.373535,813.546265 
	C1886.184082,807.038269 1885.289795,812.242310 1884.161499,814.794922 
	C1882.565430,814.358948 1881.167603,813.977051 1879.773315,812.832214 
	C1879.784058,812.578125 1879.794800,812.323975 1880.215942,811.551025 
	C1879.709351,811.594604 1879.202759,811.638184 1878.014038,812.144348 
M1798.386230,849.639709 
	C1800.275635,848.787598 1802.165161,847.935486 1804.690430,847.364136 
	C1806.671753,850.627747 1809.110840,850.808899 1812.562866,848.462036 
	C1813.259766,848.426636 1814.399902,848.644348 1814.586304,848.317627 
	C1817.072266,843.961548 1819.252197,845.807922 1821.505493,848.645264 
	C1823.910767,844.811035 1825.884399,848.152039 1828.375854,849.703735 
	C1824.906372,858.415283 1816.248535,853.897522 1810.314819,856.833069 
	C1811.892334,857.591919 1813.415527,857.830322 1814.937378,857.822815 
	C1831.086670,857.743042 1847.236572,857.521667 1863.384644,857.608521 
	C1866.366943,857.624634 1869.343018,858.843506 1872.534302,859.554504 
	C1873.485718,859.064636 1875.034546,857.925049 1876.764160,857.464783 
	C1878.845947,856.910889 1881.081543,856.934509 1883.250488,856.708069 
	C1883.219482,856.122253 1883.188599,855.536377 1883.157593,854.950500 
	C1864.851318,854.950500 1846.545044,854.950500 1828.238770,854.950500 
	C1831.806396,851.182800 1832.663818,844.459351 1838.414795,845.552490 
	C1839.396973,843.418945 1839.867065,841.345886 1841.092529,839.923401 
	C1845.598022,834.693481 1850.354248,829.679443 1855.820068,824.477783 
	C1856.863403,824.227173 1857.906616,823.976624 1859.887085,823.676025 
	C1863.307617,823.676025 1866.728271,823.676025 1870.106445,823.676025 
	C1870.370605,827.597656 1870.580078,830.706665 1870.789429,833.815613 
	C1875.057617,823.965393 1875.057617,823.965393 1879.978394,822.527832 
	C1874.188721,821.329895 1875.364868,817.262573 1875.188354,813.699768 
	C1875.122070,812.361938 1874.520142,809.887268 1874.237427,809.898010 
	C1870.020630,810.058472 1865.816284,810.548889 1860.752075,810.915405 
	C1855.912109,810.951904 1851.038208,810.688293 1846.255371,811.229919 
	C1845.015625,811.370239 1843.261108,814.009033 1843.219238,815.540527 
	C1843.183105,816.857300 1845.963745,819.547363 1846.279297,819.376587 
	C1849.307129,817.737793 1854.654785,818.867676 1854.953735,812.952820 
	C1855.803223,815.251831 1856.652832,817.550903 1857.174561,820.456116 
	C1856.476807,821.350525 1855.779053,822.244873 1854.343384,822.944885 
	C1850.848877,821.467346 1847.360962,820.378601 1842.703125,823.531128 
	C1846.778198,823.793152 1849.701538,823.981079 1852.625000,824.169067 
	C1852.834473,824.574951 1853.043945,824.980896 1853.253540,825.386841 
	C1849.054199,829.367249 1844.896484,833.393005 1840.635376,837.306030 
	C1838.905396,838.894592 1837.590820,842.222473 1834.263428,839.170898 
	C1833.911377,838.847900 1832.046509,840.174255 1829.908081,841.206055 
	C1832.404663,841.739258 1833.619019,841.998596 1836.407837,842.594116 
	C1832.869385,845.302612 1830.425293,847.173462 1827.966797,848.181091 
	C1827.966797,846.198181 1827.966797,844.215332 1827.966797,842.299561 
	C1825.810791,842.829285 1824.159424,843.824829 1822.990723,843.421509 
	C1817.472168,841.517212 1814.799194,842.140442 1811.104004,846.775696 
	C1809.855347,846.928345 1808.606812,847.080994 1807.358154,847.233704 
	C1807.277710,846.870483 1807.197144,846.507263 1807.116699,846.144104 
	C1808.554565,845.075500 1809.992432,844.006897 1812.636841,842.041687 
	C1808.733887,842.532471 1806.341187,842.833374 1803.411743,842.675415 
	C1801.231689,843.402222 1799.051636,844.129089 1796.300659,844.517029 
	C1796.001587,843.791504 1795.888916,842.633118 1795.373047,842.410950 
	C1792.097168,841.000488 1785.116455,843.999268 1783.296753,847.703613 
	C1787.270630,848.064758 1791.115967,848.414246 1795.504761,848.859131 
	C1796.310669,848.946655 1797.116699,849.034180 1798.386230,849.639709 
M1912.865967,857.363586 
	C1912.872192,857.147156 1912.878296,856.930725 1913.829590,856.809753 
	C1919.923950,856.607605 1926.018311,856.405518 1932.582520,855.986328 
	C1932.390015,855.650635 1932.197510,855.315002 1931.076416,854.832825 
	C1916.350952,854.832825 1901.625488,854.832825 1886.900024,854.832825 
	C1886.907471,855.466858 1886.914917,856.100952 1886.922363,856.734985 
	C1890.654541,856.734985 1894.386597,856.734985 1898.099609,857.658630 
	C1898.577026,860.259338 1895.708374,864.261719 1901.601440,865.161194 
	C1903.129028,865.086426 1904.656494,865.011719 1906.602905,864.916443 
	C1906.174927,861.893433 1905.823486,859.411926 1906.399658,856.940186 
	C1907.632935,856.871338 1908.866089,856.802490 1910.198242,857.400635 
	C1910.205444,857.625000 1910.212524,857.849365 1909.740479,858.613892 
	C1909.442871,860.245483 1909.145264,861.877136 1908.847656,863.508728 
	C1909.979370,863.594849 1912.042236,863.932373 1912.097534,863.727722 
	C1912.598755,861.871826 1912.749390,859.921326 1912.865967,857.363586 
M1940.845703,811.329834 
	C1938.984619,810.983704 1937.123535,810.637634 1934.625488,810.173035 
	C1936.712769,813.498352 1937.930420,815.438232 1939.300049,817.620361 
	C1939.919434,815.610046 1940.470947,813.820251 1941.427856,812.672791 
	C1941.406738,813.329651 1941.105347,814.330627 1941.414307,814.582397 
	C1942.598022,815.546875 1943.977783,816.270630 1945.284424,817.084290 
	C1946.074097,815.448730 1946.863647,813.813171 1947.653198,812.177551 
	C1946.011475,811.985596 1944.373291,811.735901 1942.726074,811.633545 
	C1942.165894,811.598755 1941.582886,811.935181 1940.845703,811.329834 
M1787.375000,830.844910 
	C1781.741943,833.037537 1781.114746,834.670471 1784.500366,839.446960 
	C1785.762329,838.740356 1787.081299,838.001831 1788.990845,837.047607 
	C1789.284546,836.896851 1789.826904,836.599060 1789.837036,836.616028 
	C1792.402222,840.957275 1795.077148,837.976013 1797.442383,836.518921 
	C1798.291504,835.995850 1799.054565,834.013428 1798.703979,833.184875 
	C1798.227783,832.059509 1796.754272,831.111572 1795.500488,830.638489 
	C1794.528809,830.271912 1793.238647,830.749939 1792.047852,830.005554 
	C1792.145874,828.433289 1792.244019,826.861023 1792.335938,825.387146 
	C1794.844727,825.033875 1796.815308,824.756348 1799.308228,824.405334 
	C1795.655518,820.854797 1789.407349,821.136597 1785.443726,824.461853 
	C1789.689209,825.239075 1789.848633,827.689697 1787.375000,830.844910 
M1829.340332,811.675598 
	C1828.232788,811.443481 1826.940674,810.724731 1826.049438,811.062805 
	C1822.061768,812.575378 1818.123535,811.260986 1814.172974,811.323425 
	C1812.936890,811.342896 1811.113892,812.204285 1810.642334,813.195374 
	C1810.025024,814.493286 1809.934570,816.633545 1810.637451,817.793213 
	C1811.275757,818.846191 1813.482544,819.826111 1814.642822,819.514954 
	C1817.722046,818.689026 1820.609497,817.148071 1822.558594,816.321777 
	C1824.486938,817.814026 1826.085693,819.051331 1827.684448,820.288635 
	C1828.037598,819.830383 1828.390747,819.372131 1828.743896,818.913818 
	C1827.946777,818.325623 1827.149658,817.737366 1825.867065,816.790833 
	C1827.383911,815.790771 1828.646118,814.958496 1829.919556,814.865479 
	C1829.919556,816.017639 1829.919556,817.169800 1829.919556,817.893860 
	C1832.029541,818.087402 1833.819092,818.251526 1835.397461,818.396240 
	C1835.397461,816.302246 1835.397461,814.297607 1835.397461,811.177429 
	C1834.276245,812.941772 1833.816040,813.665955 1833.399658,814.321167 
	C1832.156860,813.478943 1831.083740,812.751770 1829.340332,811.675598 
M1927.651978,830.268005 
	C1926.395264,834.649536 1925.427734,835.239319 1920.609253,834.149780 
	C1922.233276,832.839417 1923.616089,831.723633 1924.998779,830.607849 
	C1924.712280,830.164734 1924.425781,829.721619 1924.139160,829.278503 
	C1922.559692,830.297058 1920.607544,831.032166 1919.537842,832.438538 
	C1918.876343,833.308228 1919.244995,836.308960 1919.690796,836.434265 
	C1921.389404,836.911865 1923.307129,836.615173 1925.143066,836.596680 
	C1925.802490,836.590027 1926.465210,836.587708 1927.120361,836.522156 
	C1929.175415,836.316406 1931.448975,836.536499 1933.197266,835.685913 
	C1934.234375,835.181335 1934.441528,832.970764 1935.021973,831.527283 
	C1933.137573,830.964905 1931.257935,830.385864 1929.364014,829.857605 
	C1929.068848,829.775269 1928.708862,829.925293 1927.651978,830.268005 
M1947.741699,821.700439 
	C1947.496948,821.904175 1947.234741,822.091370 1947.016846,822.320557 
	C1946.939941,822.401184 1946.939819,822.699768 1946.957642,822.704651 
	C1948.197021,823.042053 1949.442017,823.359680 1950.713501,823.685913 
	C1950.979858,826.844421 1951.229614,829.805237 1951.479248,832.766052 
	C1952.037964,832.774902 1952.596680,832.783813 1953.155396,832.792664 
	C1953.526367,829.701111 1953.897461,826.609558 1954.252808,823.648438 
	C1955.979248,823.146362 1957.394775,822.734680 1958.810303,822.322998 
	C1958.712402,821.644775 1958.614502,820.966553 1958.516724,820.288269 
	C1955.129639,820.630859 1951.742554,820.973389 1947.741699,821.700439 
M1811.042358,795.710083 
	C1811.956665,796.992859 1812.870972,798.275635 1813.785278,799.558289 
	C1815.029419,798.604004 1816.591431,797.852600 1817.384521,796.610291 
	C1817.783691,795.985046 1816.786743,794.468384 1816.421265,793.354980 
	C1814.758667,793.916504 1813.096069,794.478027 1811.042358,795.710083 
M1870.008667,843.487183 
	C1870.329346,844.982178 1870.649902,846.477234 1870.970581,847.972229 
	C1871.459595,847.887939 1871.948730,847.803650 1872.437744,847.719360 
	C1872.437744,844.319885 1872.437744,840.920410 1872.437744,837.520935 
	C1871.916382,837.467773 1871.394897,837.414673 1870.873535,837.361511 
	C1870.583008,839.111938 1870.292603,840.862305 1870.008667,843.487183 
M1805.863281,821.860291 
	C1804.722168,822.110962 1803.581055,822.361633 1802.440063,822.612305 
	C1802.489868,823.081848 1802.539673,823.551331 1802.589478,824.020874 
	C1805.960449,824.020874 1809.331543,824.020874 1812.702515,824.020874 
	C1812.737915,823.487000 1812.773438,822.953064 1812.808838,822.419189 
	C1810.778076,822.239624 1808.747192,822.059998 1805.863281,821.860291 
M1885.171875,823.865234 
	C1887.791748,823.865234 1890.411621,823.865234 1893.031372,823.865234 
	C1893.043823,823.091492 1893.056152,822.317810 1893.068481,821.544067 
	C1889.908936,821.544067 1886.749390,821.544067 1883.589844,821.544067 
	C1883.861694,822.216675 1884.133545,822.889282 1885.171875,823.865234 
M1898.909302,820.982971 
	C1898.355591,821.776917 1897.801880,822.570862 1897.248169,823.364807 
	C1899.993164,823.957458 1902.835815,824.938904 1905.419189,820.974365 
	C1902.962036,820.974365 1901.346924,820.974365 1898.909302,820.982971 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1788.199463,865.409424 
	C1790.044189,862.721008 1791.948608,860.168274 1794.357788,857.397583 
	C1795.207642,857.137085 1795.552612,857.094421 1795.966919,857.391846 
	C1796.103394,858.421936 1796.170654,859.111877 1796.164429,860.165039 
	C1796.772705,861.770386 1797.454468,863.012573 1798.136230,864.254639 
	C1796.275513,864.652039 1794.414795,865.049438 1792.314819,865.762451 
	C1791.052490,866.039978 1790.029297,866.001770 1788.627441,865.902527 
	C1788.248657,865.841492 1788.259033,865.545166 1788.199463,865.409424 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1765.507812,863.324585 
	C1764.490356,862.198120 1763.787720,860.823303 1763.051270,859.050232 
	C1764.710815,858.651917 1766.404053,858.651917 1769.376465,858.651917 
	C1767.691040,860.750244 1766.756836,861.913269 1765.507812,863.324585 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1537.733765,829.263306 
	C1538.355957,828.599182 1538.711914,828.198242 1539.163452,827.536804 
	C1543.421875,826.025757 1547.596069,824.670715 1551.469971,828.785034 
	C1554.452026,832.904358 1554.501953,836.725891 1550.824463,840.818115 
	C1549.502441,841.452881 1548.720703,841.711853 1547.973633,841.616089 
	C1550.617432,838.798096 1553.179688,836.111145 1551.431274,832.232971 
	C1549.806274,828.628601 1546.550293,827.487427 1542.701904,828.094299 
	C1542.478516,828.129578 1542.289673,828.384705 1541.968750,828.623901 
	C1542.968994,828.923096 1543.862671,829.190430 1544.759521,829.458679 
	C1543.967651,831.637939 1543.231445,833.664307 1542.356445,836.072815 
	C1542.169067,835.143738 1542.206909,834.453003 1541.900635,833.989807 
	C1541.196655,832.924988 1540.325317,831.970947 1539.521973,830.971924 
	C1538.920532,832.173828 1537.683350,833.474243 1537.851074,834.556946 
	C1538.191040,836.751953 1539.291870,838.829102 1539.760986,841.040405 
	C1539.228516,841.172424 1539.013916,841.218933 1538.799438,841.265503 
	C1535.451904,837.681885 1534.715210,833.802063 1537.733765,829.263306 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1463.773926,829.812988 
	C1463.786987,831.912109 1463.501099,833.804688 1463.176270,835.956116 
	C1461.301025,835.956116 1459.383301,835.956116 1457.211060,835.638733 
	C1457.271973,834.991455 1457.587402,834.661499 1457.964600,833.962036 
	C1457.615967,832.451782 1457.205688,831.311157 1456.795410,830.170532 
	C1458.795898,827.304504 1461.022095,827.111877 1463.773926,829.812988 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1583.932617,833.499939 
	C1582.107178,834.396362 1580.361206,834.882690 1578.615234,835.369019 
	C1578.496338,834.809570 1578.377441,834.250122 1578.258423,833.690613 
	C1579.264771,833.353333 1580.271118,833.016052 1580.800171,832.838745 
	C1579.958496,831.529785 1579.066650,830.142761 1578.206543,828.388550 
	C1583.599365,826.338684 1584.159546,826.830444 1583.932617,833.499939 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1601.835205,833.531372 
	C1598.904541,836.740356 1595.830078,835.218018 1592.418213,834.292786 
	C1591.244751,833.325989 1590.429321,832.297791 1589.613892,831.269531 
	C1589.870483,830.854004 1590.127197,830.438416 1590.383789,830.022888 
	C1594.209473,831.075378 1598.035034,832.127869 1601.835205,833.531372 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1456.501709,830.113037 
	C1457.205688,831.311157 1457.615967,832.451782 1457.613037,833.854126 
	C1456.474487,834.002197 1455.749023,833.888672 1454.873535,833.521484 
	C1454.389038,832.770874 1454.054688,832.273987 1453.720215,831.777161 
	C1454.118530,831.382996 1454.516724,830.988892 1454.962280,830.303101 
	C1455.409058,830.026123 1455.808594,830.040833 1456.501709,830.113037 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1598.840698,827.984680 
	C1599.101685,828.593994 1599.171631,829.398193 1598.812988,829.710999 
	C1598.449707,830.027588 1597.662476,829.858032 1596.192017,829.964172 
	C1597.380005,828.917175 1597.920776,828.440552 1598.840698,827.984680 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1397.236450,786.703003 
	C1396.146973,787.810242 1395.291748,788.627502 1394.334961,789.732117 
	C1392.574463,788.495239 1391.290283,787.792542 1388.450073,788.444153 
	C1383.699219,789.534119 1378.539551,789.046875 1373.572388,788.805054 
	C1372.657227,788.760498 1371.105103,786.743408 1371.091553,785.621765 
	C1370.983521,776.653687 1371.175049,767.682007 1371.600708,758.780151 
	C1374.245605,764.800171 1379.176758,767.464355 1384.177979,765.468323 
	C1389.202271,763.463074 1390.882324,758.151611 1388.468994,752.251831 
	C1388.068481,751.272888 1388.145630,750.098755 1388.416016,749.062012 
	C1390.761353,749.150574 1392.692871,749.191895 1394.740723,749.514465 
	C1395.761597,752.077576 1396.842163,752.092102 1398.291260,749.867920 
	C1398.998535,749.465027 1399.497437,749.240479 1400.368896,749.166504 
	C1413.805908,749.317139 1426.870239,749.317139 1439.934570,749.317139 
	C1439.927002,748.854553 1439.919556,748.391968 1439.911987,747.929382 
	C1426.612793,747.929382 1413.313477,747.929382 1399.652588,747.843506 
	C1398.555786,747.668518 1397.820557,747.579407 1397.169556,747.120605 
	C1395.627441,742.723022 1395.245483,737.617981 1388.963501,737.713013 
	C1388.347778,737.722290 1387.705811,735.996826 1387.394775,735.018066 
	C1388.612305,733.823669 1389.511230,732.687866 1390.842285,731.473267 
	C1396.695068,731.345276 1402.115601,731.296082 1407.670654,731.532593 
	C1409.519531,735.421875 1410.464233,732.671143 1412.014404,731.365295 
	C1433.257935,731.282776 1454.070679,731.195618 1474.984619,731.508789 
	C1475.825195,735.613220 1476.564697,739.317261 1477.361694,743.313354 
	C1477.942627,744.183594 1478.466064,744.761719 1478.969482,745.611450 
	C1478.954956,746.243469 1478.960327,746.604004 1478.965820,746.964478 
	C1478.965820,746.964478 1478.954468,747.061157 1478.535889,747.043762 
	C1474.107056,747.047058 1470.096924,747.067749 1465.694824,747.020203 
	C1464.513550,747.004517 1463.724243,747.057068 1462.470825,747.091675 
	C1456.088623,747.095032 1450.169556,747.074036 1444.253296,747.194275 
	C1443.559937,747.208313 1440.437744,746.393372 1443.094238,749.353149 
	C1441.750977,753.869202 1440.352295,758.016235 1438.589355,762.077637 
	C1435.902344,761.992065 1433.579712,761.992065 1432.207642,761.992065 
	C1430.427734,760.275269 1429.217529,759.107849 1427.659180,757.759399 
	C1424.992920,757.553833 1422.651978,757.330444 1420.366821,757.593201 
	C1419.310181,757.714722 1417.780029,758.572510 1417.457275,759.458252 
	C1414.706665,767.007202 1417.946045,771.258911 1425.917603,770.787598 
	C1426.559082,770.749695 1427.187012,770.481995 1428.222900,770.362610 
	C1430.781738,766.144165 1434.805054,765.109802 1439.012695,764.394165 
	C1439.276001,764.716248 1439.539185,765.038269 1439.920288,765.994629 
	C1442.116333,773.159668 1444.925903,775.978088 1449.316040,775.935852 
	C1460.465698,775.828613 1463.137817,774.246887 1466.037964,766.036926 
	C1470.849609,764.101135 1475.392212,761.781311 1478.685425,757.101013 
	C1479.273560,758.880554 1479.891357,760.895020 1479.930176,762.920593 
	C1480.084717,770.968567 1480.058594,779.020081 1480.052856,787.537598 
	C1470.876343,788.137146 1461.746216,788.269348 1452.313599,788.683411 
	C1438.953857,788.978638 1425.896851,788.992065 1412.368042,789.078247 
	C1411.149658,788.541992 1410.426270,787.455627 1409.651611,787.417847 
	C1407.717896,787.323608 1405.761108,787.705627 1403.428711,787.863342 
	C1401.186035,787.357727 1399.328369,786.885315 1397.236450,786.703003 
M1405.407349,769.299255 
	C1407.474365,769.805298 1409.541504,770.311340 1412.450928,771.023621 
	C1412.286621,769.059143 1412.098999,766.816284 1411.983154,765.431458 
	C1409.121826,766.568115 1406.994629,767.413147 1404.501221,767.528137 
	C1401.525146,763.255432 1398.419434,763.337158 1395.835083,767.380371 
	C1394.956665,768.754883 1392.928711,769.394775 1391.426025,770.370300 
	C1390.772095,769.563171 1390.118042,768.755981 1389.463989,767.948792 
	C1391.288574,767.334229 1393.113037,766.719666 1394.937622,766.105103 
	C1394.242798,765.391113 1393.548096,764.677124 1392.853271,763.963135 
	C1391.250000,765.608948 1389.483276,767.143616 1388.210815,769.014465 
	C1388.008179,769.312500 1390.289429,772.218079 1390.975342,772.053650 
	C1395.612183,770.942017 1401.254761,773.699829 1405.407349,769.299255 
M1412.069580,781.422668 
	C1414.087280,780.839355 1416.104858,780.256042 1418.122559,779.672729 
	C1412.970459,777.982971 1408.251587,773.033203 1403.159912,778.428162 
	C1398.880737,774.254761 1398.368164,774.309204 1394.541382,780.218933 
	C1393.466553,778.635986 1392.575195,777.323059 1391.663940,775.980774 
	C1391.629150,775.994507 1391.390381,776.013611 1391.342407,776.119446 
	C1390.543701,777.879272 1388.911865,779.793457 1389.178711,781.386353 
	C1389.641602,784.151245 1391.498169,782.830200 1393.574707,782.317505 
	C1396.649536,781.558289 1400.431885,781.417542 1403.282959,782.570740 
	C1406.798706,783.992981 1408.979126,782.777405 1412.069580,781.422668 
M1446.198364,743.255005 
	C1446.669556,741.641113 1447.140747,740.027222 1448.034424,736.966553 
	C1448.707642,740.101562 1449.062866,741.756042 1449.418213,743.410522 
	C1449.678101,743.387268 1449.937988,743.364014 1450.197876,743.340759 
	C1450.600708,741.833496 1451.003540,740.326233 1451.562012,738.236511 
	C1451.987305,740.480469 1452.301025,742.135498 1452.562134,743.513367 
	C1454.820435,743.365784 1456.662109,743.245483 1458.436279,743.129517 
	C1458.563965,742.107361 1458.699341,741.023804 1458.965210,738.895020 
	C1460.454834,740.806152 1461.227539,741.797607 1462.144531,742.974182 
	C1463.142944,740.595276 1463.941406,738.692749 1464.597778,737.128845 
	C1462.599609,736.763428 1460.759155,736.211914 1458.898193,736.131042 
	C1454.927979,735.958435 1450.930054,735.819702 1446.982178,736.141418 
	C1445.927612,736.227356 1445.012817,738.026367 1443.985107,739.089417 
	C1443.316772,738.134033 1442.621704,737.140442 1441.523682,735.570862 
	C1440.478394,738.237854 1439.704468,740.212463 1438.930542,742.187073 
	C1438.537231,742.068848 1438.143921,741.950623 1437.750488,741.832336 
	C1437.896118,739.962830 1438.041870,738.093262 1438.294678,734.848938 
	C1436.425415,736.449585 1434.581055,737.284546 1434.520142,738.234009 
	C1434.386963,740.308960 1435.159668,742.442017 1435.562134,744.551392 
	C1438.209595,743.967041 1440.856934,743.382690 1444.249146,742.633972 
	C1444.012817,742.455017 1444.827759,743.072205 1446.198364,743.255005 
M1405.091919,744.305725 
	C1408.992188,743.970032 1412.892456,743.634399 1417.785645,743.213257 
	C1416.143311,740.793945 1414.840088,738.874329 1413.536865,736.954651 
	C1412.784912,737.650452 1412.032837,738.346191 1411.897705,738.471313 
	C1409.718872,737.614807 1407.886719,736.894531 1406.054443,736.174316 
	C1405.411621,738.042297 1404.694702,739.889832 1404.171753,741.790833 
	C1404.018066,742.349365 1404.413696,743.059021 1405.091919,744.305725 
M1434.553345,779.976990 
	C1434.805176,778.636353 1435.056885,777.295715 1435.312866,775.963379 
	C1433.415649,776.258240 1431.518555,776.553101 1429.540771,776.860474 
	C1429.540771,778.133362 1429.540771,779.497314 1429.540771,782.095764 
	C1427.119751,779.197144 1425.581543,777.355530 1423.559082,774.934143 
	C1422.709229,777.596802 1422.039185,779.695984 1421.296875,782.021362 
	C1422.592651,781.864197 1423.696045,781.557800 1424.773315,781.632080 
	C1428.027466,781.856689 1431.270020,782.248352 1434.524902,782.456177 
	C1434.628052,782.462769 1434.814209,781.170471 1434.553345,779.976990 
M1423.394287,737.333923 
	C1422.849121,739.161133 1422.303955,740.988281 1421.651489,743.174805 
	C1423.746094,743.174805 1425.251831,743.174805 1428.468506,743.174805 
	C1426.524292,740.489258 1425.179932,738.632263 1423.394287,737.333923 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1536.419922,788.024719 
	C1535.221558,787.689331 1534.454224,787.270874 1533.637085,787.118591 
	C1532.064819,786.825623 1530.464722,786.683350 1528.464722,786.451416 
	C1523.048706,784.896118 1518.438965,786.960876 1513.418945,788.209351 
	C1504.012329,788.418579 1494.903198,788.418579 1484.478271,788.418579 
	C1484.478271,776.340881 1484.478271,764.958130 1484.184814,753.279541 
	C1484.404785,752.409912 1484.917969,751.836121 1485.949463,751.482056 
	C1487.572266,751.902283 1488.880005,751.790344 1489.747803,752.354797 
	C1494.779419,755.627686 1498.158691,753.233215 1501.171753,749.275574 
	C1504.254028,748.931824 1507.336304,748.588074 1509.903442,748.301697 
	C1511.267822,752.547913 1511.438721,757.949890 1513.888550,759.342285 
	C1516.370728,760.753052 1521.769653,761.905029 1525.330078,757.410828 
	C1529.267456,761.425049 1532.804077,765.833496 1535.670532,769.406433 
	C1537.318237,767.864319 1539.406616,765.909668 1541.471436,764.322144 
	C1541.392822,765.172119 1541.337769,765.654968 1541.282837,766.137756 
	C1543.998901,766.137756 1546.714966,766.137756 1548.930176,766.137756 
	C1544.174805,761.452515 1544.720093,756.544128 1550.877930,753.609497 
	C1551.974609,753.734924 1553.071289,753.860413 1554.602051,754.350342 
	C1555.069702,754.910889 1555.103394,755.107056 1555.136963,755.303223 
	C1557.223999,756.338562 1557.398193,756.145752 1558.625122,754.280273 
	C1559.988647,752.206970 1561.652710,750.331543 1563.507690,748.375427 
	C1563.798462,759.357727 1563.767578,770.334473 1563.418457,781.559631 
	C1563.045288,778.168518 1563.064575,774.525940 1562.915283,770.890320 
	C1562.748779,766.837219 1560.708862,765.730530 1557.124023,767.845154 
	C1554.534180,769.372742 1552.125000,771.206482 1549.581177,773.403809 
	C1549.344849,774.079102 1549.175537,774.265991 1548.655151,774.412598 
	C1547.844482,774.556213 1547.397827,774.751892 1546.692627,775.041626 
	C1545.613647,775.101807 1544.793213,775.067993 1543.601685,775.019897 
	C1542.207642,775.797180 1541.184814,776.588745 1540.161865,777.380249 
	C1540.134155,776.926941 1540.106445,776.473572 1540.078735,776.020264 
	C1536.901733,775.821777 1533.725220,775.505249 1530.547607,775.495239 
	C1529.567261,775.492126 1527.926392,776.929871 1527.700562,776.684326 
	C1523.975952,772.635681 1521.231567,776.221924 1518.610840,776.520813 
	C1516.703369,775.918640 1514.795898,775.316467 1512.520630,774.598206 
	C1512.705322,777.988342 1512.823486,780.157715 1512.993286,783.274597 
	C1515.118042,782.402100 1516.989258,781.633789 1518.860474,780.865417 
	C1518.841064,781.273438 1518.821655,781.681519 1518.802246,782.089539 
	C1526.753784,782.562012 1534.671265,782.269592 1542.675903,779.383362 
	C1542.751709,780.366150 1542.814209,781.176880 1542.876709,781.987610 
	C1541.262207,784.412354 1542.179321,789.357910 1536.419922,788.024719 
M1516.466675,769.145142 
	C1516.018188,768.478210 1515.083008,767.337219 1515.197998,767.219116 
	C1516.139771,766.252136 1517.277222,765.475769 1518.355103,764.641418 
	C1518.044922,764.185730 1517.734619,763.730103 1517.424316,763.274475 
	C1515.750366,764.492798 1513.898438,765.541992 1512.495728,767.018127 
	C1511.984009,767.556641 1512.262573,769.924683 1512.657837,770.059570 
	C1514.350342,770.637329 1516.221069,770.692566 1518.026489,770.940491 
	C1519.222412,771.104553 1520.533569,771.666870 1521.589355,771.356812 
	C1523.833740,770.697754 1526.085083,769.800415 1528.029907,768.534424 
	C1528.647705,768.132385 1528.659546,765.325500 1528.342896,765.204529 
	C1526.474976,764.490906 1524.437622,764.182068 1522.435791,763.881775 
	C1521.871094,763.796997 1521.233521,764.198425 1520.632080,764.376038 
	C1520.632080,766.187500 1520.632080,767.766724 1520.632080,768.481018 
	C1519.251221,768.683105 1518.207275,768.835876 1516.466675,769.145142 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1659.313599,745.761108 
	C1658.237061,745.888000 1657.486816,745.859131 1656.365967,745.901001 
	C1655.835449,745.923584 1655.675415,745.875366 1655.267334,745.864990 
	C1653.413208,745.865356 1651.806763,745.828064 1649.671265,745.468262 
	C1648.563477,744.470276 1647.984863,743.794739 1647.406250,743.119263 
	C1646.712524,743.935486 1646.018799,744.751648 1645.325073,745.567871 
	C1628.533936,745.731995 1611.742676,745.896118 1594.488281,746.126770 
	C1586.980347,746.205933 1579.935669,746.218628 1572.433594,746.348755 
	C1571.157227,746.363525 1570.338257,746.260803 1569.415894,745.664429 
	C1569.315430,740.236328 1569.318237,735.301758 1569.747559,730.292358 
	C1573.107788,730.127319 1576.041504,730.037170 1579.441895,730.015137 
	C1585.954102,730.083191 1591.999756,730.083191 1598.056274,730.083191 
	C1598.322388,734.533691 1598.536743,738.117493 1598.800537,742.527100 
	C1600.752197,740.126099 1601.902710,738.710876 1603.288574,737.539612 
	C1604.065430,739.212524 1604.531860,740.679504 1605.218628,742.034851 
	C1605.341064,742.276489 1606.377686,742.055054 1607.314941,742.176086 
	C1612.421387,744.713745 1613.287109,744.344727 1614.904297,739.066650 
	C1615.328735,738.903809 1615.650513,738.925476 1615.937378,739.325012 
	C1617.143555,743.919434 1619.256592,743.873291 1622.389404,740.884399 
	C1624.630493,740.789185 1627.305664,741.506836 1628.109863,740.587646 
	C1632.427856,735.652588 1638.092529,737.655151 1643.218750,737.110229 
	C1645.959351,736.818909 1648.756470,737.058594 1651.280029,737.058594 
	C1651.904175,738.985840 1652.322754,740.278137 1653.272339,743.210266 
	C1654.675171,736.054626 1658.958862,736.889709 1662.940796,737.034119 
	C1664.596436,737.094238 1666.253296,737.116760 1667.936768,737.507812 
	C1668.298218,739.210205 1668.632568,740.561157 1668.966797,741.912109 
	C1668.588257,743.061035 1668.209717,744.210022 1667.508057,746.339844 
	C1666.516357,744.192383 1665.964600,742.997864 1665.013550,740.938416 
	C1664.571899,742.598999 1664.325317,743.525940 1664.068970,744.811523 
	C1662.586060,745.315186 1661.112915,745.460266 1659.313599,745.761108 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1578.975220,729.947021 
	C1576.041504,730.037170 1573.107788,730.127319 1569.457031,730.237122 
	C1568.186035,730.380310 1567.632202,730.503845 1566.772949,730.593689 
	C1566.062134,730.598511 1565.656860,730.637085 1564.809204,730.569824 
	C1556.757080,730.398071 1549.144897,730.423035 1541.538696,730.220337 
	C1538.615967,730.142517 1537.562988,731.198059 1537.817139,734.073486 
	C1537.176025,734.222473 1536.925293,734.304871 1536.334717,734.222046 
	C1535.696045,733.388245 1535.397217,732.719604 1535.497559,732.036377 
	C1536.045288,731.486816 1536.194092,730.951721 1536.342896,730.416626 
	C1535.868408,730.879395 1535.393799,731.342163 1534.494385,731.581299 
	C1526.075195,731.142639 1518.082153,730.832703 1510.086426,730.743896 
	C1504.112305,730.677490 1504.110840,730.804504 1505.055908,737.263062 
	C1504.932495,738.370300 1504.894897,739.114258 1504.722656,740.125122 
	C1504.538330,740.585083 1504.488770,740.778076 1504.103638,741.129272 
	C1503.505981,743.194519 1503.244019,745.101685 1502.982178,747.008789 
	C1502.506226,746.999023 1502.030151,746.989258 1501.323975,746.566895 
	C1501.388184,741.177368 1501.682617,736.200500 1501.984497,731.098511 
	C1495.954102,731.098511 1490.531738,731.098511 1484.645386,731.065674 
	C1481.082153,731.058044 1477.982788,731.083252 1474.883423,731.108398 
	C1454.070679,731.195618 1433.257935,731.282776 1411.510498,731.280029 
	C1409.562500,731.209045 1408.549438,731.227966 1407.536255,731.246948 
	C1402.115601,731.296082 1396.695068,731.345276 1390.412354,731.368408 
	C1388.964966,731.347656 1388.379639,731.352844 1387.455322,731.313477 
	C1386.686646,731.404541 1386.256958,731.540100 1385.437988,731.574646 
	C1378.395874,731.466370 1371.742676,731.459106 1365.073730,731.218811 
	C1365.022095,730.672546 1365.005981,730.358337 1365.009521,730.043091 
	C1372.614014,729.945740 1380.218384,729.848389 1388.691772,729.775879 
	C1390.119873,729.762634 1390.678955,729.724426 1391.684082,729.725586 
	C1396.670898,729.736816 1401.211670,729.708740 1406.116821,729.765442 
	C1407.664185,729.558777 1408.847046,729.267273 1410.481934,729.109802 
	C1417.253906,729.303589 1423.575317,729.478394 1429.893677,729.400635 
	C1445.862305,729.204285 1461.829346,728.894287 1478.246216,728.691040 
	C1481.245483,728.876404 1483.795776,729.000610 1486.345947,729.124817 
	C1486.525269,728.715759 1486.704590,728.306702 1486.883911,727.897583 
	C1484.625366,726.230896 1482.366821,724.564209 1480.045776,722.612061 
	C1479.692871,721.836609 1479.402466,721.346680 1478.963013,720.615662 
	C1478.222412,719.917480 1477.630737,719.460388 1476.944092,718.683105 
	C1474.895142,716.292236 1472.941528,714.221619 1470.993408,711.790833 
	C1471.097534,711.211731 1471.195923,710.992859 1471.605469,710.882690 
	C1473.977905,712.945740 1476.039307,714.899902 1478.249756,717.154297 
	C1480.926514,719.302734 1483.454224,721.151123 1486.011230,723.361572 
	C1488.670654,730.743103 1494.654175,728.622498 1500.242676,728.674561 
	C1500.939575,728.423584 1501.197876,728.271667 1501.862793,728.296509 
	C1508.605713,728.473145 1514.941895,728.473145 1520.473145,728.473145 
	C1515.423706,723.049500 1510.156006,717.391602 1505.030518,711.498169 
	C1505.306152,711.137146 1505.439697,711.011475 1505.880127,710.709412 
	C1509.749634,713.431458 1513.312134,716.329834 1516.916992,719.565369 
	C1521.130859,725.327942 1525.094849,731.195679 1533.471313,727.401611 
	C1533.858154,727.226379 1534.404419,727.392944 1534.875610,727.416260 
	C1544.553223,727.893494 1554.230591,728.373047 1564.293091,728.883850 
	C1566.148926,728.561279 1567.619873,728.207092 1569.392334,728.005371 
	C1572.140259,728.168701 1574.586426,728.179443 1577.189697,728.408447 
	C1577.889648,729.066772 1578.432373,729.506897 1578.975220,729.947021 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1535.098389,732.050903 
	C1535.397217,732.719604 1535.696045,733.388245 1536.474121,734.578369 
	C1537.236084,736.851990 1537.518799,738.604309 1537.822021,740.484497 
	C1535.190796,741.272339 1532.648071,742.777222 1530.258057,742.564453 
	C1527.146729,742.287537 1527.215088,743.973450 1526.870361,745.853699 
	C1519.569092,746.221619 1512.267822,746.589478 1504.522461,746.570679 
	C1508.651001,742.902649 1513.932861,744.948486 1518.817139,744.578857 
	C1523.442627,744.228760 1525.805908,742.777222 1525.278076,737.385376 
	C1522.959106,737.385376 1520.650269,737.385376 1518.341431,737.385376 
	C1515.099731,736.006348 1511.776367,734.862000 1507.920776,737.273926 
	C1507.311035,737.655334 1506.083374,737.049255 1505.141479,736.899719 
	C1504.110840,730.804504 1504.112305,730.677490 1510.086426,730.743896 
	C1518.082153,730.832703 1526.075195,731.142639 1534.504761,731.632202 
	C1534.940063,731.906677 1535.098389,732.050903 1535.098389,732.050903 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1485.109131,731.098511 
	C1490.531738,731.098511 1495.954102,731.098511 1501.984497,731.098511 
	C1501.682617,736.200500 1501.388184,741.177368 1500.968384,746.535278 
	C1495.883301,746.949951 1490.923828,746.983582 1485.411499,746.532166 
	C1484.942261,741.064270 1485.025757,736.081360 1485.109131,731.098511 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1465.970703,762.384399 
	C1458.432861,762.479126 1450.895020,762.573792 1442.906494,762.029907 
	C1440.805054,755.775940 1442.970825,752.579895 1448.562622,751.960571 
	C1449.789185,751.824646 1450.906982,750.704346 1452.494263,750.043396 
	C1454.591431,750.106750 1456.269287,750.164062 1457.968262,750.560547 
	C1459.550781,752.894897 1462.432983,754.928284 1462.376709,756.876831 
	C1462.282715,760.132935 1463.937988,761.164246 1465.989258,762.435303 
	C1465.976807,762.423462 1465.970703,762.384399 1465.970703,762.384399 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1565.251465,730.675659 
	C1565.656860,730.637085 1566.062134,730.598511 1566.785889,731.014771 
	C1567.126465,736.370850 1567.148804,741.272034 1567.171021,746.173279 
	C1560.478882,746.354858 1553.786743,746.536438 1546.564697,746.277710 
	C1544.925415,744.408325 1543.815918,742.979309 1543.012451,741.944336 
	C1544.337646,740.969299 1545.668823,739.989746 1547.015625,738.625732 
	C1547.699463,737.551941 1548.641113,736.150696 1548.992310,736.285706 
	C1551.879883,737.395752 1553.916626,737.235901 1556.084106,734.457825 
	C1556.933472,733.369263 1560.626587,732.623657 1560.720459,736.695679 
	C1559.653198,738.599182 1558.778809,740.240967 1558.272583,741.989319 
	C1558.207642,742.213440 1560.601074,743.663574 1560.927734,743.399170 
	C1562.443237,742.172363 1563.649170,740.555847 1564.916504,739.037720 
	C1565.084106,738.836853 1564.968994,738.399902 1564.986328,737.615906 
	C1565.075806,734.998230 1565.163574,732.836975 1565.251465,730.675659 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1505.055908,737.263062 
	C1506.083374,737.049255 1507.311035,737.655334 1507.920776,737.273926 
	C1511.776367,734.862000 1515.099731,736.006348 1518.341431,737.385376 
	C1520.650269,737.385376 1522.959106,737.385376 1525.278076,737.385376 
	C1525.805908,742.777222 1523.442627,744.228760 1518.817139,744.578857 
	C1513.932861,744.948486 1508.651001,742.902649 1504.069580,746.543762 
	C1503.883423,746.946167 1503.706177,746.988831 1503.255493,747.020142 
	C1503.244019,745.101685 1503.505981,743.194519 1504.429199,741.166748 
	C1505.598267,740.634399 1506.106201,740.222656 1506.614136,739.810913 
	C1506.028442,739.826660 1505.442871,739.842407 1504.857178,739.858154 
	C1504.894897,739.114258 1504.932495,738.370300 1505.055908,737.263062 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1442.016602,766.015137 
	C1449.574463,765.764526 1457.132446,765.513977 1464.470215,765.792725 
	C1462.053345,770.623962 1458.892578,772.062317 1453.758179,772.395020 
	C1445.173096,772.951416 1445.222046,773.706238 1442.016602,766.015137 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1466.369385,762.406311 
	C1465.970703,762.384399 1465.976807,762.423462 1466.236084,762.161743 
	C1466.399048,760.946106 1466.553955,759.871399 1466.163086,759.059265 
	C1464.526489,755.659546 1462.726562,752.338562 1461.321777,749.003235 
	C1462.102417,748.983276 1462.547729,748.948853 1463.314209,749.150146 
	C1467.071655,749.147522 1470.508301,748.909241 1474.335938,748.713928 
	C1475.512573,748.724060 1476.297974,748.691223 1477.305908,748.966919 
	C1478.056030,750.873474 1478.583862,752.471497 1479.111694,754.069458 
	C1476.875000,756.263977 1474.825439,758.711060 1472.331421,760.558167 
	C1470.835693,761.665955 1468.645996,761.836487 1466.369385,762.406311 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1484.645264,731.065674 
	C1485.025757,736.081360 1484.942261,741.064270 1484.947998,746.504272 
	C1483.303345,746.949097 1481.569580,746.936707 1479.400879,746.944397 
	C1478.960327,746.604004 1478.954956,746.243469 1478.945557,745.287598 
	C1478.395752,744.135254 1477.849976,743.578247 1477.304199,743.021240 
	C1476.564697,739.317261 1475.825195,735.613220 1474.984619,731.508789 
	C1477.982788,731.083252 1481.082153,731.058044 1484.645264,731.065674 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1528.154907,754.165649 
	C1528.862671,754.110901 1529.570312,754.056091 1531.079346,754.023926 
	C1535.212280,754.071045 1538.544067,754.095581 1542.107422,754.472168 
	C1543.658813,754.910339 1544.978638,754.996399 1547.691040,755.173218 
	C1545.336792,757.992859 1543.665649,759.994324 1541.588867,761.908813 
	C1537.308594,762.831360 1533.218994,764.665894 1530.492920,759.865967 
	C1529.486938,758.094604 1528.921387,756.073059 1528.154907,754.165649 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1547.000000,739.010193 
	C1545.668823,739.989746 1544.337646,740.969299 1543.012451,741.944336 
	C1543.815918,742.979309 1544.925415,744.408325 1546.127197,746.148926 
	C1545.712524,746.602295 1545.205322,746.744080 1544.301392,746.535767 
	C1542.512329,745.821289 1540.798828,745.850708 1539.784302,745.023193 
	C1535.739502,741.724426 1532.977661,744.144775 1530.158569,746.795288 
	C1529.286133,746.539124 1528.413696,746.282959 1527.205811,745.940247 
	C1527.215088,743.973450 1527.146729,742.287537 1530.258057,742.564453 
	C1532.648071,742.777222 1535.190796,741.272339 1537.822021,740.484497 
	C1537.518799,738.604309 1537.236084,736.851990 1536.813965,734.743469 
	C1536.925293,734.304871 1537.176025,734.222473 1538.123779,734.214233 
	C1541.360718,736.185425 1542.710449,740.144043 1547.000000,739.010193 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1441.706543,766.019775 
	C1445.222046,773.706238 1445.173096,772.951416 1453.758179,772.395020 
	C1458.892578,772.062317 1462.053345,770.623962 1464.552979,766.168884 
	C1465.053101,766.001770 1465.250366,765.987732 1465.742920,766.005371 
	C1463.137817,774.246887 1460.465698,775.828613 1449.316040,775.935852 
	C1444.925903,775.978088 1442.116333,773.159668 1440.101807,766.254150 
	C1440.575684,765.927734 1440.986084,765.976074 1441.706543,766.019775 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1541.994629,761.995728 
	C1543.665649,759.994324 1545.336792,757.992859 1547.691040,755.173218 
	C1544.978638,754.996399 1543.658813,754.910339 1542.251709,754.209473 
	C1542.455200,753.082764 1542.746094,752.570984 1543.199219,751.831116 
	C1543.862183,751.367249 1544.363037,751.131531 1544.863770,750.895813 
	C1546.900146,751.546875 1548.936646,752.197937 1550.925537,753.229248 
	C1544.720093,756.544128 1544.174805,761.452515 1548.930176,766.137756 
	C1546.714966,766.137756 1543.998901,766.137756 1541.282837,766.137756 
	C1541.337769,765.654968 1541.392822,765.172119 1541.621338,764.112610 
	C1541.861328,763.022522 1541.927979,762.509155 1541.994629,761.995728 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1428.007202,757.940430 
	C1429.217529,759.107849 1430.427734,760.275269 1432.207642,761.992065 
	C1433.579712,761.992065 1435.902344,761.992065 1438.609131,762.041260 
	C1438.993408,762.090393 1438.921509,762.048706 1438.865234,762.278931 
	C1438.808838,762.509155 1439.053955,762.914856 1439.053955,762.914856 
	C1439.042847,763.247375 1439.003784,763.576538 1438.974854,764.148315 
	C1434.805054,765.109802 1430.781738,766.144165 1428.229736,769.982666 
	C1427.826782,768.153198 1427.792114,766.744751 1427.817139,765.337463 
	C1427.861084,762.871460 1427.941772,760.406067 1428.007202,757.940430 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1545.000732,750.483765 
	C1544.363037,751.131531 1543.862183,751.367249 1542.735352,751.849426 
	C1538.386841,752.190552 1534.664673,752.285217 1530.942383,752.379883 
	C1529.511230,751.735840 1528.080200,751.091797 1526.649048,750.447693 
	C1526.813354,749.782227 1526.977783,749.116699 1527.142090,748.451233 
	C1532.925415,748.451233 1538.708984,748.433167 1544.491089,748.519653 
	C1544.714233,748.523010 1544.922363,749.531494 1545.000732,750.483765 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1500.749023,749.173462 
	C1498.158691,753.233215 1494.779419,755.627686 1489.747803,752.354797 
	C1488.880005,751.790344 1487.572266,751.902283 1486.248535,751.445129 
	C1486.968872,750.120178 1487.903809,748.147034 1488.848877,748.142212 
	C1492.672974,748.122559 1496.500244,748.708740 1500.749023,749.173462 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1541.588867,761.908752 
	C1541.927979,762.509155 1541.861328,763.022522 1541.644897,763.745483 
	C1539.406616,765.909668 1537.318237,767.864319 1535.670532,769.406433 
	C1532.804077,765.833496 1529.267456,761.425049 1525.371582,757.014893 
	C1525.832764,756.097412 1526.652954,755.181641 1527.813965,754.215759 
	C1528.921387,756.073059 1529.486938,758.094604 1530.492920,759.865967 
	C1533.218994,764.665894 1537.308594,762.831360 1541.588867,761.908752 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1452.074951,750.037415 
	C1450.906982,750.704346 1449.789185,751.824646 1448.562622,751.960571 
	C1442.970825,752.579895 1440.805054,755.775940 1442.579590,761.745544 
	C1442.390625,762.258789 1442.077881,762.417725 1441.128174,762.449097 
	C1439.967896,762.230591 1439.444702,762.139648 1438.921509,762.048706 
	C1438.921509,762.048706 1438.993408,762.090393 1438.973511,762.126831 
	C1440.352295,758.016235 1441.750977,753.869202 1443.510742,749.325439 
	C1446.606201,749.298340 1449.340576,749.667908 1452.074951,750.037415 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1567.489136,746.120728 
	C1567.148804,741.272034 1567.126465,736.370850 1567.091309,731.048523 
	C1567.632202,730.503845 1568.186035,730.380310 1569.030518,730.312012 
	C1569.318237,735.301758 1569.315430,740.236328 1569.201416,745.548462 
	C1568.662598,745.973450 1568.234863,746.020813 1567.489136,746.120728 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1481.965088,754.235291 
	C1482.354614,755.953979 1482.889282,757.846008 1482.906982,759.742920 
	C1482.995117,769.160278 1482.947021,778.578857 1481.884033,787.996826 
	C1481.884033,777.244080 1481.884033,766.491394 1481.798950,755.257080 
	C1481.713867,754.775513 1481.871948,754.410889 1481.965088,754.235291 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1530.609375,746.831299 
	C1532.977661,744.144775 1535.739502,741.724426 1539.784302,745.023193 
	C1540.798828,745.850708 1542.512329,745.821289 1543.906616,746.515076 
	C1539.625854,746.852051 1535.343140,746.859680 1530.609375,746.831299 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1530.945557,752.629517 
	C1534.664673,752.285217 1538.386841,752.190552 1542.572998,752.077515 
	C1542.746094,752.570984 1542.455200,753.082764 1542.020020,753.857300 
	C1538.544067,754.095581 1535.212280,754.071045 1531.429688,753.961975 
	C1530.977173,753.544373 1530.967163,753.211609 1530.945557,752.629517 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1479.402100,753.913696 
	C1478.583862,752.471497 1478.056030,750.873474 1477.485596,748.767456 
	C1477.970703,748.150818 1478.498535,748.042053 1479.026489,747.933289 
	C1483.309326,749.410583 1481.404541,751.595276 1479.402100,753.913696 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1479.027344,747.723450 
	C1478.498535,748.042053 1477.970703,748.150818 1477.263184,748.458984 
	C1476.297974,748.691223 1475.512573,748.724060 1474.035278,748.493774 
	C1470.924561,747.849915 1468.505737,747.469238 1466.086792,747.088501 
	C1470.096924,747.067749 1474.107056,747.047058 1478.581543,747.060364 
	C1479.046021,747.094360 1479.028198,747.513611 1479.027344,747.723450 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1438.865234,762.278931 
	C1439.444702,762.139648 1439.967896,762.230591 1440.617920,762.577881 
	C1440.368652,762.843872 1439.992432,762.853516 1439.335205,762.889038 
	C1439.053955,762.914856 1438.808838,762.509155 1438.865234,762.278931 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1555.350708,755.151001 
	C1555.103394,755.107056 1555.069702,754.910889 1555.013916,754.417358 
	C1555.182617,754.412964 1555.373535,754.705872 1555.350708,755.151001 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1733.840698,767.148804 
	C1732.431885,767.786011 1731.023071,768.423157 1729.614258,769.060303 
	C1729.307739,768.670776 1729.001221,768.281189 1728.694702,767.891663 
	C1730.061890,766.455505 1731.429199,765.019287 1733.179443,763.180847 
	C1727.891968,763.647949 1723.293335,764.054138 1718.174316,764.506348 
	C1716.833252,761.545105 1714.529419,762.787659 1712.002197,765.529297 
	C1711.557495,761.483276 1711.138184,757.118225 1711.093750,752.749329 
	C1711.087769,752.172424 1712.975708,751.110413 1714.018311,751.068726 
	C1718.648438,750.883667 1723.290161,750.988586 1728.670044,750.988586 
	C1725.026978,754.012878 1724.558716,756.916382 1727.930176,759.692688 
	C1729.757446,759.126526 1731.398682,758.617981 1733.654297,758.439575 
	C1736.724365,757.783813 1739.180054,756.797852 1741.635620,755.811951 
	C1740.754883,754.210510 1739.874268,752.609070 1738.993652,751.007629 
	C1739.015503,749.976746 1739.037476,748.945923 1739.105103,747.532104 
	C1751.767090,746.818420 1764.383545,746.487732 1777.517822,746.541748 
	C1779.765869,749.637085 1781.495728,752.347717 1783.697510,755.797791 
	C1772.531128,754.533569 1770.557495,756.662292 1772.654663,766.807800 
	C1773.243042,765.281006 1773.888184,764.283447 1773.987549,763.234253 
	C1774.445190,758.397827 1776.557617,757.170715 1781.285156,758.769531 
	C1783.708618,759.588989 1786.695068,758.743225 1789.717773,758.738708 
	C1791.689453,758.229797 1793.370483,757.632141 1795.348877,757.321899 
	C1795.726074,762.321533 1795.805786,767.033691 1795.433960,771.775391 
	C1791.333618,771.880066 1787.684814,771.955200 1783.597534,772.014893 
	C1781.114014,771.996643 1779.069092,771.994019 1776.833984,771.698364 
	C1775.042114,770.315308 1773.256104,768.131348 1771.877075,768.363525 
	C1769.036377,768.842041 1767.851318,767.427002 1767.444580,765.518433 
	C1766.651611,761.797058 1766.639648,757.909973 1765.859375,754.184692 
	C1765.552856,752.721436 1764.053589,751.507996 1762.648193,750.061157 
	C1760.222656,749.933228 1758.220215,749.730408 1756.280396,749.991882 
	C1755.172241,750.141235 1753.308838,750.978271 1753.257202,751.623413 
	C1752.983765,755.044556 1752.559082,758.688049 1753.508667,761.858765 
	C1753.894653,763.147949 1757.619019,763.437378 1760.052246,764.437866 
	C1762.950439,767.160095 1765.621094,769.626221 1767.806641,772.048706 
	C1762.196167,772.004272 1757.065796,771.866760 1751.947021,772.049927 
	C1748.398193,772.176819 1746.521606,771.046997 1747.408203,767.155090 
	C1748.555542,767.587830 1749.353149,768.103088 1750.318481,768.726685 
	C1750.318481,766.520569 1750.318481,764.681274 1750.318481,762.375122 
	C1748.738403,763.686462 1747.634277,764.602966 1746.592163,765.467834 
	C1744.957275,764.492798 1743.474365,763.608398 1741.688721,762.494995 
	C1739.440063,762.265930 1737.494263,762.265930 1736.613159,762.265930 
	C1735.432861,764.344727 1734.636719,765.746765 1733.840698,767.148804 
M1741.252319,760.241028 
	C1741.643921,760.371338 1742.035522,760.501587 1742.427124,760.631897 
	C1742.496338,760.342773 1742.565674,760.053589 1742.634888,759.764465 
	C1742.154175,759.713562 1741.673584,759.662659 1741.252319,760.241028 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1774.818726,743.856995 
	C1757.879395,744.181702 1740.940186,744.506348 1723.486816,744.386108 
	C1722.972656,740.016296 1722.972656,736.091431 1722.972656,732.035950 
	C1725.134888,731.892883 1726.973511,732.314941 1728.030273,731.592712 
	C1730.557861,729.865234 1734.607300,732.028320 1736.632568,728.248535 
	C1742.723755,728.161072 1748.360107,728.075745 1754.440308,728.070435 
	C1757.312744,728.150452 1759.741333,728.150452 1761.233765,728.150452 
	C1762.752808,730.838074 1763.815796,732.718811 1765.204102,734.789551 
	C1767.907471,735.109070 1770.285645,735.238464 1772.478394,735.357849 
	C1773.326172,738.436462 1774.072388,741.146729 1774.818726,743.856995 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1818.979614,746.965332 
	C1823.992188,746.646179 1829.006226,746.346924 1834.016846,745.998230 
	C1836.101196,745.853149 1838.178467,745.602295 1841.084106,745.262085 
	C1845.785034,745.256226 1849.660645,745.387695 1853.708496,745.734192 
	C1854.224487,746.080933 1854.568237,746.212646 1854.905762,746.440613 
	C1854.899536,746.536804 1854.961792,746.719421 1854.644531,746.786011 
	C1853.571045,747.544678 1852.814941,748.236816 1851.678101,748.919678 
	C1848.228882,749.874512 1844.916504,751.099670 1846.409912,755.121155 
	C1847.716553,758.639526 1850.516968,757.178040 1853.184570,755.893433 
	C1853.900269,756.005249 1854.306396,756.084778 1854.999268,756.354553 
	C1857.362061,755.760376 1859.438110,754.975952 1861.955078,754.331055 
	C1865.057251,754.950989 1867.718506,755.431396 1870.639771,755.958679 
	C1870.895630,754.233582 1871.197144,752.200195 1871.462646,750.410034 
	C1873.899780,749.884033 1875.949829,749.441467 1878.413574,749.042480 
	C1879.794800,749.506348 1881.578979,749.885254 1881.602783,750.353638 
	C1881.819824,754.620483 1881.730225,758.902954 1881.730225,764.175598 
	C1880.090698,762.723083 1879.616211,762.302734 1879.007812,761.572632 
	C1875.129517,757.687927 1871.842773,760.376648 1868.347168,761.573730 
	C1866.065918,761.125000 1863.917236,761.125000 1861.768555,761.125000 
	C1859.431763,760.873047 1857.237183,761.122681 1855.047974,761.080994 
	C1853.735107,761.056091 1852.431030,760.576355 1849.820557,760.026978 
	C1844.693237,762.378296 1844.668213,762.538330 1850.028442,766.511597 
	C1850.121094,766.580261 1850.081421,766.827332 1849.780029,767.173706 
	C1847.198608,768.563904 1845.024048,770.572815 1842.666382,770.817566 
	C1837.238892,771.381042 1831.722412,771.086670 1825.930542,770.944946 
	C1824.936157,770.086304 1824.253174,769.423157 1823.572388,768.349976 
	C1824.049194,766.400146 1824.523926,764.860413 1825.297241,763.344727 
	C1826.426758,762.955383 1827.257568,762.541870 1828.425903,761.967529 
	C1829.217896,761.756958 1829.672485,761.707214 1830.471191,761.862610 
	C1831.872559,762.075134 1832.929688,762.082520 1834.324341,762.282349 
	C1842.956787,762.720581 1842.955566,762.720581 1842.900024,754.554443 
	C1842.895508,753.896606 1843.197754,753.016602 1842.881104,752.621338 
	C1841.858276,751.344360 1840.802490,749.788025 1839.399902,749.206909 
	C1837.999390,748.626709 1836.160034,749.183472 1834.523315,749.080017 
	C1830.944458,748.853821 1829.237183,750.258606 1829.764893,754.233826 
	C1827.780273,756.937805 1826.010254,759.393066 1823.954590,761.608765 
	C1823.443726,761.264648 1823.218140,761.160156 1822.969727,760.736755 
	C1822.580322,759.939453 1822.213867,759.460938 1821.633057,758.745117 
	C1820.444214,757.437988 1819.469604,756.368225 1818.061035,755.211670 
	C1817.333984,755.091980 1817.040894,755.059204 1816.638916,754.791870 
	C1816.264648,754.114929 1815.999268,753.672485 1815.873169,752.890137 
	C1817.001465,750.688538 1817.990479,748.826965 1818.979614,746.965332 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1840.933105,743.407715 
	C1835.646484,743.461853 1830.359863,743.515991 1824.561523,743.167358 
	C1824.485352,740.248291 1824.921021,737.732056 1825.326416,735.390869 
	C1835.487427,734.167908 1835.487427,734.167908 1835.245361,727.657959 
	C1843.661621,727.351135 1851.746948,727.301147 1859.865601,727.649048 
	C1863.444946,731.690674 1872.970093,731.445374 1877.348022,727.819092 
	C1877.759277,728.627808 1877.938843,729.220947 1878.051636,730.233032 
	C1877.870850,732.258972 1877.756836,733.866028 1877.696045,734.722839 
	C1874.442017,735.620056 1871.720337,736.370483 1868.910889,737.361145 
	C1868.653442,738.948364 1868.483887,740.295349 1868.186401,741.930542 
	C1868.014282,742.424072 1867.970093,742.629517 1867.617676,742.966248 
	C1862.901611,743.174866 1858.493652,743.252136 1853.712891,743.222290 
	C1849.204224,743.212769 1845.068726,743.310242 1840.933105,743.407715 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1820.746216,743.472778 
	C1816.431152,743.576355 1812.113403,743.796448 1807.803345,743.692993 
	C1806.851196,743.670166 1805.926758,742.501831 1805.225098,741.185669 
	C1805.097778,738.046326 1804.955933,735.521545 1804.300903,733.137817 
	C1803.786743,731.266907 1802.637695,729.570557 1802.164429,727.637939 
	C1812.734619,727.623413 1822.911011,727.767456 1833.036621,728.337402 
	C1832.132080,729.852478 1831.075928,731.990295 1830.455933,731.870483 
	C1823.369141,730.500732 1822.259155,734.864685 1821.869507,740.233643 
	C1821.789551,741.334717 1821.135620,742.394165 1820.746216,743.472778 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1793.222778,741.199829 
	C1792.089478,742.105713 1791.005493,743.707825 1789.814209,743.791870 
	C1785.620361,744.088196 1781.389893,743.863281 1776.650635,743.361023 
	C1774.932129,732.981873 1774.932129,732.981873 1764.921021,732.578735 
	C1764.465942,729.525818 1765.528564,728.186401 1768.271118,728.141846 
	C1770.861450,728.099854 1773.449341,727.902161 1776.499023,727.812500 
	C1782.984131,727.861206 1789.008423,727.870483 1795.112061,728.113770 
	C1795.359619,728.646851 1795.603516,728.858948 1795.950195,729.370361 
	C1794.616821,733.464661 1791.375488,736.719727 1793.222778,741.199829 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1816.747925,755.026489 
	C1817.040894,755.059204 1817.333984,755.091980 1818.073975,755.528503 
	C1821.529297,759.272705 1821.152222,762.894897 1819.462646,767.063477 
	C1817.174194,765.684021 1814.732910,763.046326 1812.910522,767.397522 
	C1814.370605,767.628357 1815.618042,767.825562 1817.968872,768.197205 
	C1812.718506,773.144775 1807.269043,771.673950 1802.101440,771.235718 
	C1802.038574,767.066528 1802.018433,763.336243 1802.382324,759.716797 
	C1804.494507,758.925720 1806.222534,758.023804 1807.950684,757.121887 
	C1806.633789,755.276794 1805.316895,753.431702 1804.238770,751.455688 
	C1804.611450,750.814758 1804.745239,750.304688 1804.879028,749.794678 
	C1804.507812,746.727783 1805.672485,745.224182 1808.945801,745.511047 
	C1811.991455,745.777893 1815.056396,745.825562 1818.120605,746.549683 
	C1816.751343,748.568604 1815.374390,750.007629 1813.631104,751.513306 
	C1812.275513,752.567261 1810.895020,753.397278 1810.415161,754.589661 
	C1809.999023,755.623657 1810.166138,757.601074 1810.877319,758.184509 
	C1811.619385,758.793579 1813.535278,758.627258 1814.540527,758.075684 
	C1815.534668,757.530029 1816.032471,756.079895 1816.747925,755.026489 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1736.177734,728.250610 
	C1734.607300,732.028320 1730.557861,729.865234 1728.030273,731.592712 
	C1726.973511,732.314941 1725.134888,731.892883 1722.972656,732.035950 
	C1722.972656,736.091431 1722.972656,740.016296 1723.073120,744.385925 
	C1722.656860,744.819946 1722.140259,744.809265 1721.283447,744.358643 
	C1721.390259,740.881836 1720.104492,739.836792 1717.047363,739.909546 
	C1709.254150,740.095032 1701.454590,740.023315 1693.657593,740.002869 
	C1692.893677,740.000854 1692.130859,739.640808 1691.241699,739.414917 
	C1691.241699,737.760620 1691.241699,736.309265 1691.241699,735.890503 
	C1693.228516,734.674194 1694.625244,733.819092 1696.307861,732.800659 
	C1696.855347,731.718323 1697.117310,730.799194 1697.406982,729.782104 
	C1697.919067,730.456848 1698.400635,731.091492 1698.940674,731.803040 
	C1701.236938,728.401794 1701.959229,728.256531 1705.973633,730.501160 
	C1703.318726,732.824036 1700.695679,734.814392 1698.072632,736.804749 
	C1698.219238,737.111572 1698.365845,737.418396 1698.512451,737.725220 
	C1705.048828,737.725220 1711.585083,737.725220 1718.576538,737.725220 
	C1717.684692,736.511719 1717.117676,735.740112 1716.968018,734.978760 
	C1718.418213,735.222839 1719.451172,735.456604 1720.881836,735.780334 
	C1720.881836,733.340576 1720.881836,731.275452 1721.287109,729.072510 
	C1726.520874,728.706665 1731.349243,728.478638 1736.177734,728.250610 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1789.427124,758.650024 
	C1786.695068,758.743225 1783.708618,759.588989 1781.285156,758.769531 
	C1776.557617,757.170715 1774.445190,758.397827 1773.987549,763.234253 
	C1773.888184,764.283447 1773.243042,765.281006 1772.654663,766.807800 
	C1770.557495,756.662292 1772.531128,754.533569 1783.697510,755.797791 
	C1781.495728,752.347717 1779.765869,749.637085 1777.976807,746.601379 
	C1782.625854,746.178772 1787.333984,746.081238 1792.516357,746.503723 
	C1793.182129,748.574646 1793.373291,750.125610 1793.289917,751.895752 
	C1790.226685,753.418884 1787.678467,754.854797 1789.427124,758.650024 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1696.022095,732.963989 
	C1694.625244,733.819092 1693.228516,734.674194 1691.241699,735.890503 
	C1691.241699,736.309265 1691.241699,737.760620 1691.241699,739.414917 
	C1692.130859,739.640808 1692.893677,740.000854 1693.657593,740.002869 
	C1701.454590,740.023315 1709.254150,740.095032 1717.047363,739.909546 
	C1720.104492,739.836792 1721.390259,740.881836 1720.895752,744.329956 
	C1714.257935,745.642334 1707.654907,743.310791 1701.708252,745.406128 
	C1700.292358,743.531433 1699.391724,742.339050 1698.491089,741.146667 
	C1697.487305,742.196899 1696.583130,743.377502 1695.442749,744.248962 
	C1694.740356,744.785828 1693.677490,744.850952 1692.375366,745.127380 
	C1691.642212,744.702148 1691.218506,744.319458 1690.999023,743.842834 
	C1690.666626,743.120422 1690.476074,742.332581 1690.091797,740.914429 
	C1688.963501,738.488342 1687.968506,736.720337 1686.969360,734.715210 
	C1686.903809,734.161560 1686.908691,733.846008 1687.217407,733.442017 
	C1687.623291,733.368896 1687.791626,733.385132 1688.072876,733.543579 
	C1688.472534,733.552429 1688.759155,733.419067 1689.384277,733.169373 
	C1690.483521,731.704895 1691.244385,730.356750 1692.392456,729.022949 
	C1696.258179,728.366333 1695.412231,731.266113 1696.022095,732.963989 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1793.558838,741.228333 
	C1791.375488,736.719727 1794.616821,733.464661 1795.995483,729.103088 
	C1796.039917,728.270508 1796.066284,728.091553 1796.500244,727.872437 
	C1798.528931,727.820312 1800.149780,727.808472 1801.770752,727.796631 
	C1802.637695,729.570557 1803.786743,731.266907 1804.300903,733.137817 
	C1804.955933,735.521545 1805.097778,738.046326 1805.177490,740.778076 
	C1803.474365,741.972168 1802.054565,742.898621 1800.310669,743.342590 
	C1799.986572,738.845947 1799.986572,734.831787 1799.986572,730.817688 
	C1799.365479,730.830383 1798.744507,730.843079 1798.123413,730.855774 
	C1798.123413,735.155212 1798.123413,739.454590 1798.123413,743.754028 
	C1796.713867,742.921631 1795.304443,742.089233 1793.558838,741.228333 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1736.466064,747.090393 
	C1735.638062,747.772766 1734.394043,748.927856 1733.110718,748.973328 
	C1727.457764,749.173584 1721.793335,749.038025 1716.133057,749.053833 
	C1709.129761,749.073303 1709.022949,749.164917 1709.024414,755.921875 
	C1709.026245,764.412170 1708.979370,772.904419 1709.160645,781.391418 
	C1709.213379,783.861023 1709.974609,786.315430 1709.979736,789.330811 
	C1709.332275,788.284241 1708.134766,787.244629 1708.121216,786.190002 
	C1707.958374,773.398682 1707.970093,760.605225 1707.995728,747.441772 
	C1717.380615,747.065002 1726.706299,747.058655 1736.466064,747.090393 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1793.564453,751.676514 
	C1793.373291,750.125610 1793.182129,748.574646 1792.978394,746.553589 
	C1794.329834,745.999817 1795.693726,745.916199 1797.493408,746.332581 
	C1797.967041,750.656982 1798.148804,754.486877 1798.004761,758.304504 
	C1797.838989,762.701111 1797.385254,767.086853 1796.780762,771.618286 
	C1796.298462,771.754761 1796.091919,771.750366 1795.885498,771.745911 
	C1795.805786,767.033691 1795.726074,762.321533 1795.353271,756.934326 
	C1794.561523,754.731750 1794.063110,753.204102 1793.564453,751.676514 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1821.183105,743.590332 
	C1821.135620,742.394165 1821.789551,741.334717 1821.869507,740.233643 
	C1822.259155,734.864685 1823.369141,730.500732 1830.455933,731.870483 
	C1831.075928,731.990295 1832.132080,729.852478 1833.341064,728.344543 
	C1834.102417,727.922119 1834.508423,727.918457 1834.914551,727.914795 
	C1835.487427,734.167908 1835.487427,734.167908 1825.326416,735.390869 
	C1824.921021,737.732056 1824.485352,740.248291 1824.160767,743.128540 
	C1823.387939,743.564270 1822.503906,743.636047 1821.183105,743.590332 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1797.056641,771.477356 
	C1797.385254,767.086853 1797.838989,762.701111 1798.004761,758.304504 
	C1798.148804,754.486877 1797.967041,750.656982 1797.903564,746.353149 
	C1798.356079,745.799072 1798.834229,745.724426 1799.666870,746.170593 
	C1800.277588,750.130432 1800.533813,753.569458 1800.834473,757.343079 
	C1800.889038,758.127747 1800.899414,758.577698 1800.627441,759.270142 
	C1800.199097,763.612305 1800.052979,767.712097 1799.546875,771.814453 
	C1798.476685,771.703796 1797.766724,771.590576 1797.056641,771.477356 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1765.001465,732.996216 
	C1774.932129,732.981873 1774.932129,732.981873 1776.206299,743.288391 
	C1776.042725,743.740662 1775.801025,743.790405 1775.188965,743.848511 
	C1774.072388,741.146729 1773.326172,738.436462 1772.478394,735.357849 
	C1770.285645,735.238464 1767.907471,735.109070 1765.222412,734.524292 
	C1764.944092,733.711365 1764.972778,733.353760 1765.001465,732.996216 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1800.790039,757.008423 
	C1800.533813,753.569458 1800.277588,750.130432 1800.027954,746.219604 
	C1803.959473,743.957947 1803.927124,746.956787 1804.636719,749.572815 
	C1804.745239,750.304688 1804.611450,750.814758 1804.180420,751.826294 
	C1802.852051,753.888062 1801.821045,755.448242 1800.790039,757.008423 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1798.414307,743.792969 
	C1798.123413,739.454590 1798.123413,735.155212 1798.123413,730.855774 
	C1798.744507,730.843079 1799.365479,730.830383 1799.986572,730.817688 
	C1799.986572,734.831787 1799.986572,738.845947 1799.929565,743.302612 
	C1799.483398,743.774048 1799.094360,743.802979 1798.414307,743.792969 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1840.943848,743.670654 
	C1845.068726,743.310242 1849.204224,743.212769 1853.660156,743.594604 
	C1853.832397,744.555664 1853.684326,745.037415 1853.536377,745.519165 
	C1849.660645,745.387695 1845.785034,745.256226 1841.453247,745.055298 
	C1840.983032,744.635193 1840.968872,744.284363 1840.943848,743.670654 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1813.997314,751.446655 
	C1815.374390,750.007629 1816.751343,748.568604 1818.438721,746.912720 
	C1818.749146,746.695740 1818.896118,746.804504 1818.937866,746.884888 
	C1817.990479,748.826965 1817.001465,750.688538 1815.552734,752.784973 
	C1814.728027,752.495361 1814.362793,751.971008 1813.997314,751.446655 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1482.426758,811.520020 
	C1482.427734,811.292358 1482.512207,811.214966 1482.596802,811.137573 
	C1482.652344,811.237671 1482.762695,811.346680 1482.748291,811.435364 
	C1482.733765,811.524292 1482.594727,811.592834 1482.426758,811.520020 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1690.861816,748.799927 
	C1689.785156,748.837219 1688.967773,748.701965 1688.150269,748.566650 
	C1689.170776,748.024414 1690.191406,747.482178 1691.572998,746.950684 
	C1691.663086,747.516724 1691.392090,748.072021 1690.861816,748.799927 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1315.340820,781.112671 
	C1315.984497,780.064819 1316.960327,779.168030 1317.165527,778.979431 
	C1316.569946,778.063721 1315.220459,776.873840 1315.221069,775.684631 
	C1315.221558,774.863403 1317.088501,774.043213 1318.480347,773.237671 
	C1319.322388,774.498779 1319.805664,775.744690 1320.347168,777.141052 
	C1323.334229,776.577576 1325.160767,771.044678 1328.870728,775.760986 
	C1328.946777,776.750061 1328.943115,777.364929 1328.936279,778.285339 
	C1326.511841,781.900574 1323.682739,782.018066 1321.482300,780.112244 
	C1319.001099,780.604065 1317.336914,780.933899 1315.340820,781.112671 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1328.924561,762.743591 
	C1329.303345,764.489258 1330.027954,766.666382 1329.277954,767.474487 
	C1327.968384,768.885376 1325.751343,769.453857 1323.265503,770.700989 
	C1323.265503,767.518311 1323.265503,765.594849 1323.265503,762.888550 
	C1324.982178,762.782532 1326.794312,762.670593 1328.924561,762.743591 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1738.650879,751.152832 
	C1739.874268,752.609070 1740.754883,754.210510 1741.635620,755.811951 
	C1739.180054,756.797852 1736.724365,757.783813 1734.024170,758.466125 
	C1733.822754,757.942749 1733.865967,757.723022 1733.901123,756.872559 
	C1733.913574,755.845093 1733.933960,755.448425 1733.954468,755.051697 
	C1733.354004,751.420837 1736.725342,752.396667 1738.650879,751.152832 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1729.010132,754.948486 
	C1730.011963,754.515991 1730.714233,754.281006 1731.416626,754.046021 
	C1731.637695,754.380005 1731.858765,754.713989 1732.079834,755.047974 
	C1731.096558,755.665833 1730.113281,756.283752 1729.130005,756.901611 
	C1728.990234,756.316345 1728.850464,755.731140 1729.010132,754.948486 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1733.680542,755.093140 
	C1733.933960,755.448425 1733.913574,755.845093 1733.865601,756.538452 
	C1733.694214,756.268250 1733.550415,755.701416 1733.680542,755.093140 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1732.640869,751.453491 
	C1732.740723,751.579590 1732.384888,751.648804 1732.384888,751.648804 
	C1732.384888,751.648804 1732.541138,751.327454 1732.640869,751.453491 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1246.988770,831.440918 
	C1253.906006,831.000122 1260.897095,831.000244 1268.335449,831.028748 
	C1266.969849,842.797668 1270.766113,851.950500 1281.177856,858.107971 
	C1281.769287,858.457703 1282.062256,859.312073 1283.087036,860.782043 
	C1279.042114,860.782043 1275.782837,861.043335 1272.593628,860.683899 
	C1270.684326,860.468811 1268.662231,859.577026 1270.071045,856.634888 
	C1270.412964,855.920654 1269.598633,854.334717 1268.874878,853.563965 
	C1268.518555,853.184509 1267.154297,853.866943 1266.247437,853.861145 
	C1264.971680,853.853088 1263.697144,853.651978 1262.157715,853.339478 
	C1259.895264,850.794189 1257.896973,848.442810 1255.786133,845.690552 
	C1252.140381,844.832886 1248.607056,844.375977 1245.073853,843.919067 
	C1245.736816,839.906677 1246.399780,835.894226 1246.988770,831.440918 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1231.004639,851.990845 
	C1228.173218,852.313416 1226.705811,851.436401 1226.925537,848.187378 
	C1227.170532,844.561401 1226.746826,840.886536 1227.056763,837.270813 
	C1227.156982,836.101746 1228.603027,834.151428 1229.523438,834.097595 
	C1234.768433,833.790405 1240.040527,833.948547 1245.335205,833.948547 
	C1244.735474,841.270691 1243.814087,841.858093 1234.981323,840.190979 
	C1235.521729,841.733948 1235.904175,842.826355 1236.639893,843.955688 
	C1236.992920,843.992737 1236.977051,843.972473 1236.948975,844.330933 
	C1234.934937,847.133789 1232.949219,849.578125 1230.963501,852.022461 
	C1230.963501,852.022522 1230.991821,852.002747 1231.004639,851.990845 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1244.742065,843.954590 
	C1248.607056,844.375977 1252.140381,844.832886 1255.542725,845.727661 
	C1255.015137,846.443604 1254.618408,846.721619 1253.868774,847.027954 
	C1251.422729,849.023315 1249.329590,850.990356 1247.236572,852.957336 
	C1250.387085,853.644653 1253.711182,855.609314 1254.585938,850.943359 
	C1255.978271,851.963135 1257.060303,852.878174 1258.142334,853.793213 
	C1254.710205,854.599365 1251.278076,855.405579 1247.067139,856.167969 
	C1244.675659,855.841125 1242.619873,856.595703 1243.228149,853.259033 
	C1243.645996,850.967712 1242.477417,848.387207 1242.017822,845.694214 
	C1242.017700,845.452515 1241.969360,844.971497 1241.977539,844.977539 
	C1241.985718,844.983643 1241.969482,844.970276 1241.969482,844.970215 
	C1242.783081,844.643555 1243.596680,844.316833 1244.742065,843.954590 
M1246.978638,850.554504 
	C1248.544312,848.894714 1250.109863,847.234924 1252.101929,845.123047 
	C1249.138306,845.123047 1247.252075,845.123047 1244.126587,845.123047 
	C1245.080811,847.542969 1245.775146,849.303955 1246.978638,850.554504 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1236.999146,844.003784 
	C1238.625488,844.094238 1240.245728,844.173584 1241.917725,844.611572 
	C1241.969482,844.970276 1241.985718,844.983643 1241.607056,845.004395 
	C1239.811279,844.674255 1238.394165,844.323364 1236.977051,843.972473 
	C1236.977051,843.972473 1236.992920,843.992737 1236.999146,844.003784 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1388.002197,749.014771 
	C1388.145630,750.098755 1388.068481,751.272888 1388.468994,752.251831 
	C1390.882324,758.151611 1389.202271,763.463074 1384.177979,765.468323 
	C1379.176758,767.464355 1374.245605,764.800171 1371.883545,758.488647 
	C1371.854126,757.898865 1371.867554,757.669373 1371.867188,756.739624 
	C1372.840088,753.965027 1373.826904,751.890686 1375.031250,749.812500 
	C1375.558594,749.715576 1375.740234,749.509766 1375.794067,749.191162 
	C1376.148071,749.425720 1376.502197,749.660217 1377.107666,750.480591 
	C1378.728394,751.418945 1380.097534,751.771545 1381.065308,752.020691 
	C1380.375122,754.304016 1379.705322,756.520386 1378.998535,758.858765 
	C1377.950684,756.837036 1376.910156,754.829468 1375.515381,752.138306 
	C1372.790161,757.522400 1373.411255,761.013855 1377.038330,762.861938 
	C1380.318481,764.533203 1382.506348,764.259583 1385.661865,760.804016 
	C1389.947998,756.110535 1386.635864,753.877136 1384.825317,750.684204 
	C1385.406372,749.980347 1385.987305,749.276489 1387.031738,748.227661 
	C1387.663696,747.922607 1387.832153,747.962402 1387.993774,748.255371 
	C1387.992065,748.677307 1387.997192,748.846069 1388.002197,749.014771 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1368.048828,780.998779 
	C1368.015259,776.506775 1368.015259,772.402527 1368.015259,768.298279 
	C1368.328003,768.290710 1368.640869,768.283142 1368.953613,768.275574 
	C1368.953613,772.662842 1368.956299,777.050049 1368.940552,781.437256 
	C1368.940063,781.565186 1368.681396,781.815613 1368.651367,781.800049 
	C1368.445557,781.693420 1368.268677,781.530945 1368.048828,780.998779 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1374.813599,749.816406 
	C1373.826904,751.890686 1372.840088,753.965027 1371.818359,756.398193 
	C1371.540649,754.571594 1371.297974,752.386047 1371.117920,749.812073 
	C1372.154053,749.379517 1373.127563,749.335388 1374.442139,749.441467 
	C1374.783081,749.591675 1374.813599,749.816406 1374.813599,749.816406 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1367.950439,750.356262 
	C1368.087769,749.844116 1368.261841,749.786499 1368.709961,749.754272 
	C1368.983765,752.855347 1368.983765,755.930969 1368.983765,759.006531 
	C1368.651611,759.006897 1368.319458,759.007324 1367.987305,759.007751 
	C1367.987305,756.275391 1367.987305,753.543091 1367.950439,750.356262 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1368.152832,763.509033 
	C1368.470337,763.783386 1368.757080,764.316589 1369.043945,764.849854 
	C1368.837769,764.919556 1368.631592,764.989258 1368.425537,765.058960 
	C1368.324463,764.628601 1368.223389,764.198303 1368.152832,763.509033 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1343.348145,859.872192 
	C1342.624756,851.281738 1342.173096,842.738098 1342.106812,834.191406 
	C1342.081421,830.915283 1341.040894,829.829712 1337.790283,829.910217 
	C1330.135620,830.099915 1322.472412,830.061157 1314.814331,829.976929 
	C1304.778442,829.866516 1294.744507,829.545593 1284.708740,829.476013 
	C1280.700195,829.448303 1276.688721,829.849731 1272.270752,829.991821 
	C1271.559448,829.166199 1271.256226,828.404724 1271.023193,827.336914 
	C1310.724121,826.684387 1350.354736,826.338135 1390.445679,826.013916 
	C1390.943604,826.462463 1390.981079,826.889038 1390.475586,827.650635 
	C1378.314697,827.997314 1366.696655,827.967712 1355.079590,828.065918 
	C1353.363159,828.080444 1351.651733,828.699036 1349.699585,829.033936 
	C1349.461182,829.029724 1348.985962,828.990417 1348.801514,828.745667 
	C1347.630737,828.547913 1346.644531,828.594910 1345.658325,828.641907 
	C1346.073975,829.427124 1346.489624,830.212341 1347.208252,831.285828 
	C1347.687378,841.010498 1347.863770,850.446838 1348.040283,859.883240 
	C1346.618042,859.894775 1345.195801,859.906311 1343.348145,859.872192 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1306.863281,860.748779 
	C1317.871582,860.515930 1328.887451,860.515930 1339.903320,860.515930 
	C1339.923218,861.022156 1339.943115,861.528442 1339.963135,862.034668 
	C1329.200195,862.142700 1318.437378,862.250732 1307.337158,862.132446 
	C1306.959717,861.597534 1306.916748,861.289368 1306.863281,860.748779 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1258.283447,853.915588 
	C1257.060303,852.878174 1255.978271,851.963135 1254.574463,850.559509 
	C1254.242310,849.047241 1254.232056,848.023438 1254.221802,846.999634 
	C1254.618408,846.721619 1255.015137,846.443604 1255.655273,846.128479 
	C1257.896973,848.442810 1259.895264,850.794189 1261.798340,853.521057 
	C1261.050903,853.975952 1260.398560,854.055481 1259.256348,854.161499 
	C1258.766479,854.188110 1258.424438,854.037964 1258.283447,853.915588 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1266.213745,855.677246 
	C1266.307373,855.107422 1266.624878,854.893433 1266.942261,854.679443 
	C1267.154541,854.996338 1267.366821,855.313293 1267.579102,855.630188 
	C1267.198608,855.764465 1266.817993,855.898743 1266.213745,855.677246 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1267.306885,814.616333 
	C1266.654907,814.766907 1266.249268,814.636597 1265.917603,814.401550 
	C1265.850586,814.354004 1266.064331,813.910461 1266.148560,813.649475 
	C1266.625488,813.865845 1267.102417,814.082214 1267.306885,814.616333 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1868.479736,762.022522 
	C1871.842773,760.376648 1875.129517,757.687927 1878.687256,761.714722 
	C1877.012695,763.605774 1875.524658,765.045044 1874.036621,766.484253 
	C1871.690186,768.210693 1869.131348,769.715027 1867.044189,771.712463 
	C1863.331909,775.265137 1861.078491,779.694397 1861.190918,784.949158 
	C1861.253174,787.857910 1860.425171,789.120850 1857.286499,789.155518 
	C1848.049438,789.257385 1838.816650,789.801819 1829.579956,789.872009 
	C1813.295410,789.995667 1797.009033,789.896423 1780.237183,789.831421 
	C1779.759399,789.421448 1779.767944,789.070618 1779.871582,788.375427 
	C1779.966919,788.031067 1780.174194,787.871704 1780.625244,787.902649 
	C1787.178833,787.927490 1793.284424,788.030640 1799.383301,787.875610 
	C1803.254028,787.777222 1807.114990,787.294617 1811.129395,786.943604 
	C1811.190674,786.941650 1811.102783,786.980408 1810.669434,786.988159 
	C1809.175293,786.289673 1808.026611,785.622253 1807.238037,784.968506 
	C1808.494385,784.875793 1809.390625,784.769470 1810.978760,784.580933 
	C1806.127197,780.418274 1808.961426,776.879211 1811.276978,773.187317 
	C1813.151001,773.075256 1814.601196,773.064087 1816.224243,773.311890 
	C1817.193970,773.755066 1817.990845,773.939392 1818.899414,774.508667 
	C1819.271118,777.562378 1819.531250,780.231079 1819.535767,783.040527 
	C1818.791992,783.633850 1818.303833,784.086487 1817.323120,784.995850 
	C1819.070190,784.907715 1820.038696,784.858887 1821.470581,784.858765 
	C1826.462524,784.907471 1830.990967,784.907471 1835.409424,784.907471 
	C1834.720947,780.678833 1834.134521,777.077576 1833.510742,773.247253 
	C1831.295288,773.120483 1829.185547,772.999695 1826.984375,772.651367 
	C1826.675903,771.995911 1826.458984,771.568115 1826.242188,771.140381 
	C1831.722412,771.086670 1837.238892,771.381042 1842.666382,770.817566 
	C1845.024048,770.572815 1847.198608,768.563904 1850.119995,767.165405 
	C1851.229370,767.059937 1851.675293,767.145325 1852.003662,767.620117 
	C1851.557983,771.912109 1851.161987,775.810669 1850.928223,779.718811 
	C1850.800781,781.850647 1850.537109,784.111023 1853.939575,784.121338 
	C1856.744873,784.129822 1858.729126,784.036621 1859.871216,780.607300 
	C1861.127563,776.834534 1863.601929,773.467224 1865.643066,769.734375 
	C1866.135864,769.534851 1866.531250,769.524170 1867.128174,769.285522 
	C1867.173828,768.531616 1867.018066,768.005737 1867.047607,767.260925 
	C1867.814575,766.112976 1868.396362,765.183899 1869.000610,763.795532 
	C1869.001953,763.030518 1868.980713,762.724731 1868.843262,762.403931 
	C1868.727051,762.389038 1868.479736,762.022522 1868.479736,762.022522 
M1838.034424,781.823914 
	C1839.873291,781.248413 1841.712036,780.672913 1843.550903,780.097473 
	C1842.927246,778.726501 1842.625244,776.958984 1841.578247,776.110229 
	C1840.743652,775.433716 1837.900146,775.611084 1837.838013,775.867249 
	C1837.406250,777.649414 1837.467773,779.551147 1838.034424,781.823914 
M1812.275024,776.293823 
	C1811.800171,777.804565 1810.488159,779.985168 1811.039795,780.674561 
	C1811.946167,781.807251 1814.186279,782.610474 1815.623291,782.328003 
	C1816.620605,782.131958 1817.213867,779.880676 1817.987549,778.547546 
	C1816.328369,777.693970 1814.669312,776.840454 1812.275024,776.293823 
M1848.959106,777.664673 
	C1848.959106,776.076416 1848.959106,774.488159 1848.959106,772.899902 
	C1848.797363,772.906189 1848.635620,772.912476 1848.473877,772.918823 
	C1848.473877,777.412720 1848.473877,781.906555 1848.473877,786.400452 
	C1848.638672,786.384277 1848.803467,786.368164 1848.968262,786.351990 
	C1848.968262,783.750977 1848.968262,781.149963 1848.959106,777.664673 
M1813.467407,786.685120 
	C1814.001831,787.003113 1814.536377,787.321045 1815.070923,787.639099 
	C1815.201904,787.393433 1815.332886,787.147766 1815.463745,786.902100 
	C1814.944214,786.704346 1814.424805,786.506592 1813.467407,786.685120 
M1836.451782,773.627869 
	C1836.451782,773.627869 1836.562378,773.433228 1836.562378,773.433228 
	C1836.562378,773.433228 1836.322510,773.464050 1836.451782,773.627869 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1747.058472,767.237549 
	C1746.521606,771.046997 1748.398193,772.176819 1751.947021,772.049927 
	C1757.065796,771.866760 1762.196167,772.004272 1767.904053,772.338318 
	C1768.442505,773.075012 1768.398315,773.478455 1767.871826,773.918091 
	C1763.882812,773.954285 1760.375977,773.954285 1757.738037,773.954285 
	C1758.041992,778.004578 1758.293823,781.359802 1758.548828,784.757629 
	C1763.400146,784.757629 1768.758179,784.757629 1774.558960,784.757629 
	C1773.377319,782.406250 1772.382690,780.426880 1771.561035,778.137207 
	C1772.814819,777.569214 1773.895508,777.311646 1774.982910,777.079285 
	C1774.989502,777.104431 1774.978760,777.155334 1774.978760,777.155334 
	C1775.639893,780.460571 1776.301025,783.765747 1776.938965,787.509155 
	C1764.155762,788.294800 1751.396973,788.719482 1738.634888,788.886353 
	C1737.136841,788.905945 1735.620239,787.506165 1734.119995,786.340454 
	C1734.546753,785.939209 1734.965942,785.961853 1735.966553,786.304138 
	C1738.889404,786.739563 1741.230835,786.855286 1744.544067,787.019104 
	C1743.958130,781.066284 1743.459961,776.005737 1742.961914,770.945129 
	C1742.974365,770.764404 1742.986816,770.583618 1743.296387,769.971924 
	C1744.748535,768.773193 1745.903564,768.005371 1747.058472,767.237549 
M1756.852051,779.692078 
	C1755.375610,778.489441 1754.040649,776.873291 1752.350586,776.295349 
	C1751.708008,776.075623 1749.752686,778.085876 1749.345825,779.378662 
	C1749.095337,780.175232 1750.407104,782.110229 1751.427979,782.525452 
	C1753.573242,783.398071 1756.034058,783.719238 1756.852051,779.692078 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1777.024048,771.991333 
	C1779.069092,771.994019 1781.114014,771.996643 1783.565186,772.342041 
	C1783.980835,773.120667 1783.990356,773.556641 1784.041992,774.431641 
	C1783.701416,778.252014 1783.318726,781.633301 1782.921875,785.335083 
	C1781.996460,786.394287 1781.085327,787.132996 1780.174194,787.871704 
	C1780.174194,787.871704 1779.966919,788.031067 1779.862915,788.111816 
	C1779.216187,784.683838 1777.838989,781.078003 1781.437744,777.567444 
	C1782.174194,776.536377 1782.417847,775.931335 1782.661377,775.326294 
	C1781.951660,775.745605 1781.242065,776.164917 1780.532349,776.584229 
	C1778.900879,776.666321 1777.269287,776.748474 1775.308350,776.992920 
	C1774.978760,777.155334 1774.989502,777.104431 1775.214600,776.787231 
	C1775.967773,774.977173 1776.495972,773.484314 1777.024048,771.991333 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1779.397461,794.060913 
	C1779.485474,793.785217 1779.753540,793.793030 1779.857788,793.877075 
	C1779.878784,794.393921 1779.795532,794.826782 1779.712402,795.259644 
	C1779.578125,794.951965 1779.443726,794.644348 1779.397461,794.060913 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1543.175537,782.011353 
	C1542.814209,781.176880 1542.751709,780.366150 1542.675903,779.383362 
	C1534.671265,782.269592 1526.753784,782.562012 1518.802246,782.089539 
	C1518.821655,781.681519 1518.841064,781.273438 1518.860474,780.865417 
	C1516.989258,781.633789 1515.118042,782.402100 1512.993286,783.274597 
	C1512.823486,780.157715 1512.705322,777.988342 1512.520630,774.598206 
	C1514.795898,775.316467 1516.703369,775.918640 1518.610840,776.520813 
	C1521.231567,776.221924 1523.975952,772.635681 1527.700562,776.684326 
	C1527.926392,776.929871 1529.567261,775.492126 1530.547607,775.495239 
	C1533.725220,775.505249 1536.901733,775.821777 1540.078735,776.020264 
	C1540.106445,776.473572 1540.134155,776.926941 1540.161865,777.380249 
	C1541.184814,776.588745 1542.207642,775.797180 1543.627441,775.457642 
	C1544.040161,777.964294 1544.056274,780.018921 1544.062134,782.070129 
	C1544.051880,782.066711 1544.072754,782.076355 1544.072754,782.076355 
	C1543.873413,782.062622 1543.673950,782.048828 1543.175537,782.011353 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1558.976562,781.965088 
	C1559.428589,783.691895 1559.880615,785.418701 1560.557495,788.004944 
	C1557.692139,788.004944 1555.799683,788.004944 1553.193604,787.803833 
	C1549.383667,787.646057 1546.287354,787.689453 1543.190918,787.732849 
	C1543.454346,786.086121 1543.717773,784.439392 1544.026978,782.434509 
	C1544.072754,782.076355 1544.051880,782.066711 1544.310669,782.050110 
	C1544.569458,782.033508 1545.053467,781.912537 1545.398682,781.835083 
	C1547.998291,781.518860 1550.252563,781.280090 1552.515625,781.029358 
	C1552.524536,781.017395 1552.528320,781.049683 1552.532471,781.398682 
	C1553.385620,781.587708 1554.234741,781.427612 1555.486206,781.188965 
	C1556.917847,781.395386 1557.947266,781.680237 1558.976562,781.965088 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1565.988281,785.381531 
	C1565.958740,773.807373 1565.958740,762.595642 1565.958740,751.383972 
	C1566.672363,751.378845 1567.386108,751.373718 1568.099731,751.368591 
	C1568.099731,762.625488 1568.099731,773.882446 1568.192261,785.608032 
	C1568.225952,787.195984 1568.167236,788.315247 1568.108398,789.434509 
	C1567.811035,789.526917 1567.513672,789.619385 1567.216309,789.711792 
	C1566.816772,788.389160 1566.417236,787.066589 1565.988281,785.381531 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1552.528320,781.049683 
	C1552.528320,781.049683 1552.524536,781.017395 1552.504395,780.645874 
	C1551.329224,778.337830 1550.174316,776.401184 1549.019287,774.464600 
	C1549.175537,774.265991 1549.344849,774.079102 1549.589355,773.819946 
	C1552.719238,772.021484 1555.786865,770.306946 1558.854492,768.592346 
	C1559.357544,768.953186 1559.860718,769.314087 1560.363770,769.674927 
	C1559.715698,771.510803 1559.067749,773.346680 1557.854248,775.666809 
	C1556.298218,776.226868 1555.307495,776.302612 1554.405151,776.371643 
	C1554.545654,777.677307 1554.662109,778.758545 1554.487427,779.970337 
	C1553.640381,780.417114 1553.084351,780.733459 1552.528320,781.049683 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1543.157349,788.168762 
	C1546.287354,787.689453 1549.383667,787.646057 1552.758911,787.858643 
	C1555.505615,788.590149 1557.973267,789.065674 1560.441040,789.541260 
	C1560.445190,789.835205 1560.449463,790.129211 1560.453613,790.423157 
	C1554.841431,790.423157 1549.228882,790.439514 1543.618042,790.346741 
	C1543.443726,790.343872 1543.287964,789.210876 1543.157349,788.168762 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1554.778564,779.839783 
	C1554.662109,778.758545 1554.545654,777.677307 1554.405151,776.371643 
	C1555.307495,776.302612 1556.298218,776.226868 1557.636108,775.966064 
	C1558.323730,777.581055 1558.664307,779.381104 1558.990723,781.573120 
	C1557.947266,781.680237 1556.917847,781.395386 1555.497559,780.946777 
	C1554.997314,780.468628 1554.887939,780.154236 1554.778564,779.839783 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1312.897705,747.836426 
	C1306.909058,747.889526 1300.920288,747.942627 1294.131104,747.929016 
	C1290.309448,748.122559 1287.288330,748.382812 1284.266357,748.303284 
	C1283.811279,746.941345 1283.553833,745.742798 1282.843628,744.949585 
	C1282.209839,744.241577 1280.721069,744.224121 1280.216553,743.486694 
	C1273.990845,734.384216 1270.434570,733.284668 1261.627930,737.631409 
	C1260.340454,736.522156 1259.159302,735.504395 1257.841797,734.369507 
	C1257.488892,733.972656 1257.272095,733.692932 1257.511963,733.401550 
	C1269.101318,733.000549 1280.234009,732.606628 1291.367188,732.233398 
	C1292.023193,732.211426 1293.139526,732.155090 1293.271606,732.454407 
	C1294.323975,734.840942 1294.957520,733.728882 1296.575928,732.761719 
	C1298.048218,731.881897 1300.460693,732.575378 1303.317383,732.575378 
	C1301.625000,733.885986 1300.823242,734.506897 1300.021484,735.127808 
	C1301.527222,735.905640 1303.568115,736.063293 1303.747192,735.559387 
	C1305.525146,730.555176 1309.409790,732.162537 1312.885742,732.820435 
	C1312.900146,738.120667 1312.898926,742.978577 1312.897705,747.836426 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1346.921753,747.724304 
	C1343.917969,747.806946 1340.914062,747.889587 1337.462280,748.034546 
	C1336.583252,748.029358 1336.152222,747.961914 1335.366211,747.394531 
	C1335.033936,741.993958 1335.056763,737.093323 1335.527832,732.148682 
	C1339.262817,732.219482 1342.549561,732.334351 1345.863525,732.691772 
	C1345.939209,733.259338 1345.954102,733.585876 1345.683472,734.191650 
	C1346.150513,738.271118 1343.529785,742.551270 1347.060791,746.244629 
	C1347.004028,746.935791 1346.962891,747.330078 1346.921753,747.724304 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1284.267090,748.643066 
	C1287.288330,748.382812 1290.309448,748.122559 1293.662598,747.947021 
	C1293.997070,748.461609 1293.999878,748.891541 1293.619873,749.713013 
	C1291.524048,750.448792 1289.811157,750.793030 1287.779541,750.928589 
	C1286.263916,750.530945 1285.066772,750.341980 1283.601807,750.182251 
	C1282.983887,750.292725 1282.633789,750.373962 1281.854736,750.360840 
	C1280.560181,750.250854 1279.694336,750.235291 1278.372559,750.219604 
	C1271.690308,750.265015 1265.463989,750.310547 1258.910889,750.336731 
	C1257.350586,751.137085 1256.117065,751.956787 1254.883667,752.776489 
	C1252.359985,755.640381 1249.836304,758.504272 1246.830322,761.703003 
	C1245.896484,762.758667 1245.444702,763.479431 1244.993042,764.200256 
	C1243.672241,765.249512 1242.351440,766.298828 1240.955078,767.675232 
	C1240.278687,769.297058 1239.710205,770.608032 1239.068237,771.881958 
	C1238.376465,773.254883 1237.615967,774.593262 1236.495483,775.917603 
	C1236.182129,775.566589 1236.258545,775.244690 1236.571777,774.437988 
	C1236.376221,773.717285 1235.943848,773.481384 1235.511475,773.245483 
	C1234.296753,774.171326 1233.081909,775.097107 1231.464478,775.858154 
	C1231.045532,775.288635 1231.029053,774.883789 1231.070557,774.122009 
	C1232.051758,772.663391 1232.974976,771.561707 1233.898926,770.459167 
	C1233.448608,769.394836 1233.007935,768.353455 1232.691162,767.604736 
	C1234.463501,767.471497 1235.948975,767.359741 1237.757812,767.125610 
	C1239.054077,765.002136 1240.026978,763.001038 1241.378418,761.076416 
	C1244.556030,759.140686 1247.355103,757.128479 1250.434448,754.957336 
	C1251.229858,753.666504 1251.744751,752.534607 1252.446777,751.217285 
	C1252.835815,750.744568 1253.037598,750.457336 1253.627075,750.107666 
	C1256.081299,749.598816 1258.147705,749.152283 1260.660034,748.724121 
	C1265.003296,748.740417 1268.900757,748.738281 1273.219971,748.840027 
	C1274.499878,748.857544 1275.358154,748.771301 1276.572388,748.706787 
	C1277.876099,748.703369 1278.823730,748.678223 1280.219482,748.705078 
	C1281.867554,748.718994 1283.067261,748.681030 1284.267090,748.643066 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1288.098145,751.137329 
	C1289.811157,750.793030 1291.524048,750.448792 1293.615845,750.035400 
	C1295.318848,750.494019 1296.643066,751.021729 1298.622314,751.926758 
	C1300.125854,752.591248 1300.974121,752.878479 1301.822510,753.165710 
	C1297.127686,758.031555 1292.432983,762.897339 1287.349365,767.457886 
	C1286.742310,765.862732 1286.760132,764.472839 1286.263794,763.300720 
	C1284.368774,758.825073 1284.200684,754.698853 1288.098145,751.137329 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1324.934326,747.821045 
	C1323.284790,747.842834 1321.635132,747.864624 1319.559814,747.371582 
	C1319.142944,742.014771 1319.151978,737.172791 1319.367920,732.240723 
	C1319.574829,732.150696 1319.982910,731.958191 1320.391846,732.018311 
	C1322.170044,732.172485 1323.539062,732.266479 1324.930298,732.803833 
	C1324.946411,738.105103 1324.940430,742.963074 1324.934326,747.821045 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1319.161011,732.330811 
	C1319.151978,737.172791 1319.142944,742.014771 1319.152710,747.303467 
	C1318.668823,747.784485 1318.166260,747.818848 1317.308960,747.397217 
	C1316.954346,743.135620 1316.954346,739.330139 1316.954346,735.524658 
	C1316.337158,735.560120 1315.719849,735.595642 1315.102661,735.631165 
	C1315.102661,739.693726 1315.102661,743.756348 1315.102661,747.818909 
	C1314.612793,747.836792 1314.122925,747.854675 1313.265381,747.854492 
	C1312.898926,742.978577 1312.900146,738.120667 1313.332764,732.769714 
	C1315.563232,732.294678 1317.362061,732.312744 1319.161011,732.330811 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1331.930176,747.804199 
	C1330.898071,747.840149 1329.865967,747.876099 1328.414307,747.416626 
	C1328.026855,742.050537 1328.058960,737.179871 1328.470459,732.252563 
	C1329.868042,732.224976 1330.886475,732.254028 1331.937256,732.730774 
	C1331.956543,738.053650 1331.943359,742.928955 1331.930176,747.804199 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1287.779541,750.928589 
	C1284.200684,754.698853 1284.368774,758.825073 1286.263794,763.300720 
	C1286.760132,764.472839 1286.742310,765.862732 1287.033203,767.539856 
	C1285.996094,767.628723 1284.886353,767.330322 1283.426270,767.042358 
	C1282.757446,762.946167 1282.438843,758.839539 1282.060425,754.367004 
	C1282.000610,754.001160 1281.984009,754.028259 1282.263794,753.866333 
	C1282.985596,752.520569 1283.427612,751.336792 1283.869629,750.153015 
	C1285.066772,750.341980 1286.263916,750.530945 1287.779541,750.928589 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1281.956421,775.627014 
	C1279.635254,779.960571 1277.287842,783.947449 1274.440674,787.499634 
	C1273.832886,783.214539 1273.724976,779.364136 1273.616943,775.513672 
	C1276.338867,777.458313 1278.634644,775.435547 1281.519531,774.649658 
	C1281.956055,774.817627 1281.943115,775.049011 1281.956421,775.627014 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1328.091187,732.309265 
	C1328.058960,737.179871 1328.026855,742.050537 1328.028809,747.373169 
	C1327.279785,747.857788 1326.496704,747.890442 1325.323975,747.872070 
	C1324.940430,742.963074 1324.946411,738.105103 1325.324829,732.730896 
	C1326.495239,732.246155 1327.293213,732.277710 1328.091187,732.309265 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1332.321777,747.848389 
	C1331.943359,742.928955 1331.956543,738.053650 1332.210205,732.708374 
	C1333.327026,732.223083 1334.203247,732.207886 1335.079590,732.192627 
	C1335.056763,737.093323 1335.033936,741.993958 1335.041870,747.345276 
	C1334.286133,747.828125 1333.499878,747.860413 1332.321777,747.848389 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1315.401123,747.884888 
	C1315.102661,743.756348 1315.102661,739.693726 1315.102661,735.631165 
	C1315.719849,735.595642 1316.337158,735.560120 1316.954346,735.524658 
	C1316.954346,739.330139 1316.954346,743.135620 1316.930908,747.392700 
	C1316.504883,747.879822 1316.102173,747.915405 1315.401123,747.884888 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1302.025269,753.081421 
	C1300.974121,752.878479 1300.125854,752.591248 1298.974854,751.997681 
	C1300.434814,750.943359 1302.197510,750.195312 1303.960205,749.447266 
	C1304.531372,750.159485 1305.102661,750.871643 1305.673828,751.583862 
	C1304.525269,752.054932 1303.376587,752.526062 1302.025269,753.081421 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1314.756836,750.133179 
	C1314.889893,750.391846 1314.707520,750.624512 1314.267334,750.918945 
	C1314.216919,750.739319 1314.360840,750.448120 1314.756836,750.133179 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1687.821045,740.778015 
	C1685.010986,740.830933 1682.201172,740.919434 1679.390991,740.925232 
	C1677.229492,740.929688 1675.708984,740.843750 1674.569458,743.714783 
	C1673.363770,746.752502 1671.060059,745.981873 1670.156006,742.014893 
	C1670.356689,740.468445 1670.608032,739.637329 1670.929688,738.470215 
	C1671.424805,737.076233 1671.849609,736.018188 1672.514526,734.987732 
	C1673.077759,735.033142 1673.398682,735.014282 1673.790771,735.366699 
	C1678.413330,736.292236 1682.962646,736.809875 1687.821045,737.362671 
	C1687.821045,738.237244 1687.821045,739.507629 1687.821045,740.778015 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1688.182983,740.810669 
	C1687.821045,739.507629 1687.821045,738.237244 1687.821045,737.362671 
	C1682.962646,736.809875 1678.413330,736.292236 1674.284668,735.379761 
	C1678.794678,734.973999 1682.884155,734.963196 1686.973511,734.952332 
	C1687.968506,736.720337 1688.963501,738.488342 1689.971436,740.637695 
	C1689.504639,740.960510 1689.024780,740.901855 1688.182983,740.810669 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1670.859375,738.806152 
	C1670.608032,739.637329 1670.356689,740.468445 1670.065674,741.624939 
	C1669.850342,741.923157 1669.674561,741.895996 1669.232910,741.890442 
	C1668.632568,740.561157 1668.298218,739.210205 1668.243408,737.497681 
	C1669.301758,737.692749 1670.080566,738.249451 1670.859375,738.806152 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1733.853760,767.426025 
	C1734.636719,765.746765 1735.432861,764.344727 1736.613159,762.265930 
	C1737.494263,762.265930 1739.440063,762.265930 1741.691406,762.872131 
	C1742.170410,764.683167 1742.343994,765.888062 1742.517578,767.092896 
	C1740.139160,766.208374 1737.569336,764.537842 1736.669312,768.864075 
	C1736.588745,769.250671 1735.133423,769.351440 1733.919312,769.445801 
	C1733.636841,768.772156 1733.751831,768.237671 1733.853760,767.426025 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1742.700195,771.270752 
	C1743.459961,776.005737 1743.958130,781.066284 1744.544067,787.019104 
	C1741.230835,786.855286 1738.889404,786.739563 1736.281494,786.317200 
	C1737.779175,786.010620 1739.543213,786.010620 1741.705078,786.010620 
	C1741.965332,780.896729 1742.202026,776.246521 1742.700195,771.270752 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1742.586426,767.440369 
	C1742.343994,765.888062 1742.170410,764.683167 1741.994141,763.101196 
	C1743.474365,763.608398 1744.957275,764.492798 1746.592163,765.467834 
	C1747.634277,764.602966 1748.738403,763.686462 1750.318481,762.375122 
	C1750.318481,764.681274 1750.318481,766.520569 1750.318481,768.726685 
	C1749.353149,768.103088 1748.555542,767.587830 1747.408203,767.155090 
	C1745.903564,768.005371 1744.748535,768.773193 1743.329468,769.702271 
	C1742.928589,769.171570 1742.791870,768.479675 1742.586426,767.440369 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1681.110474,786.309692 
	C1680.040894,786.953796 1678.971313,787.597839 1677.901855,788.241882 
	C1677.584229,787.821106 1677.266602,787.400330 1676.949097,786.979614 
	C1678.001587,786.188293 1679.054199,785.396973 1680.541748,785.003418 
	C1681.021240,785.704041 1681.065918,786.006897 1681.110474,786.309692 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1681.481201,786.274902 
	C1681.065918,786.006897 1681.021240,785.704041 1680.903809,784.945312 
	C1681.447754,784.309204 1682.064575,784.128967 1682.681274,783.948730 
	C1682.404907,784.712524 1682.128418,785.476318 1681.481201,786.274902 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1710.317383,816.803162 
	C1710.864990,816.867859 1711.058105,816.903381 1711.544189,816.969971 
	C1712.036865,818.043701 1712.236694,819.086365 1712.472290,820.527771 
	C1709.230591,821.713135 1705.953247,822.499756 1702.341553,823.084106 
	C1701.986694,822.374451 1701.966064,821.867004 1701.907227,820.977295 
	C1704.567017,819.321350 1707.264893,818.047729 1710.317383,816.803162 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1655.072266,771.611450 
	C1655.986328,771.629883 1656.503784,771.737000 1657.795166,772.039795 
	C1658.247559,772.911072 1657.926147,773.586731 1657.237061,774.411133 
	C1653.810547,775.391968 1653.743408,773.929321 1655.072266,771.611450 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1409.026245,836.978760 
	C1408.340454,837.437927 1407.654541,837.897034 1406.968628,838.356079 
	C1406.433472,835.283875 1405.898438,832.211609 1405.708496,829.090637 
	C1409.887207,829.199585 1413.720825,829.357300 1417.781738,829.243164 
	C1418.972046,829.816406 1420.719238,830.618408 1420.768311,831.513611 
	C1421.040161,836.467407 1420.898438,841.443848 1420.898438,847.163696 
	C1418.603638,846.707092 1416.771851,846.342651 1414.447510,845.564331 
	C1414.219971,841.347534 1411.115967,841.769104 1408.879639,841.102722 
	C1409.129150,839.778381 1409.378540,838.454102 1409.286621,836.833496 
	C1408.664185,836.279358 1408.383179,836.021545 1408.102295,835.763672 
	C1407.971924,836.024231 1407.841553,836.284790 1407.711182,836.545410 
	C1408.149536,836.689880 1408.587891,836.834351 1409.026245,836.978760 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1408.536743,841.173706 
	C1411.115967,841.769104 1414.219971,841.347534 1414.051514,845.514709 
	C1414.072388,846.335388 1413.996460,846.791748 1413.877319,847.972473 
	C1413.829224,849.457947 1413.824463,850.218994 1413.819580,850.980103 
	C1412.632812,850.045288 1411.445923,849.110413 1410.554565,848.408325 
	C1410.554565,848.538879 1410.554565,849.403137 1410.554565,850.287781 
	C1409.208008,850.169312 1407.922241,850.056152 1406.166626,849.563110 
	C1406.802734,847.612549 1407.908569,846.041870 1409.857666,843.273376 
	C1406.755005,843.451111 1403.644531,842.867615 1403.058350,843.826782 
	C1401.953857,845.634277 1402.303955,848.330750 1402.024780,850.882080 
	C1403.813843,850.435242 1404.851196,850.176147 1405.888672,849.916992 
	C1405.213623,851.373474 1404.538696,852.829956 1403.144531,854.513062 
	C1401.552979,850.687988 1400.680542,846.636292 1399.743164,842.282715 
	C1402.207397,841.978760 1403.489746,841.820557 1404.772095,841.662292 
	C1405.912720,841.523071 1407.053223,841.383911 1408.536743,841.173706 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1414.016235,851.274414 
	C1413.824463,850.218994 1413.829224,849.457947 1413.891113,848.319153 
	C1417.093750,848.754944 1420.239502,849.568359 1423.941650,850.525696 
	C1423.941650,852.713684 1423.941650,854.950928 1423.954224,857.850708 
	C1423.966797,858.513367 1424.001465,858.941345 1424.001465,858.941345 
	C1422.605469,857.783508 1421.209351,856.625671 1419.207031,854.964966 
	C1417.971191,854.124451 1416.092041,852.846619 1414.016235,851.274414 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1424.229492,859.152466 
	C1424.001465,858.941345 1423.966797,858.513367 1423.964600,858.299438 
	C1424.291138,856.400085 1424.860840,854.721313 1424.907715,853.028076 
	C1425.134033,844.848450 1425.220093,836.664795 1425.677979,828.195129 
	C1426.284546,832.448792 1426.568970,836.989868 1427.043823,841.883179 
	C1427.272217,845.957764 1427.310303,849.680176 1427.554688,853.752686 
	C1426.840332,855.969666 1428.424561,859.409607 1424.229492,859.152466 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1458.379639,812.853271 
	C1457.721802,812.302002 1457.450684,811.751404 1457.074951,810.908936 
	C1457.136108,810.213379 1457.301880,809.809753 1458.038208,809.324402 
	C1460.840332,807.110901 1463.072021,804.979126 1465.303711,802.847412 
	C1467.396851,805.996826 1466.703857,810.804626 1463.594727,812.069580 
	C1461.739014,812.282715 1460.252686,812.568359 1458.379639,812.853271 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1465.051880,802.520935 
	C1463.072021,804.979126 1460.840332,807.110901 1458.267944,809.372803 
	C1458.043335,807.699036 1458.159546,805.895203 1458.634277,804.227661 
	C1459.194824,804.553406 1459.396729,804.742920 1459.598633,804.932434 
	C1459.646240,804.800964 1459.768066,804.586121 1459.729370,804.551758 
	C1459.499634,804.347351 1459.232788,804.184631 1458.978394,804.007935 
	C1459.812012,799.850037 1462.794922,802.591492 1465.051880,802.520935 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1251.461182,804.087219 
	C1251.832886,804.541931 1251.766846,804.983398 1251.833008,805.714111 
	C1251.965454,806.003357 1252.029297,806.038147 1251.721436,805.868042 
	C1250.944580,806.148376 1250.475464,806.598755 1250.001709,807.024048 
	C1249.997192,806.998840 1250.044922,807.016724 1249.766113,807.053223 
	C1249.330322,807.408142 1249.173218,807.726624 1249.018921,808.019775 
	C1249.021606,807.994507 1249.067993,808.016113 1249.067993,808.016113 
	C1247.763306,808.942017 1246.458618,809.867920 1244.587402,811.195923 
	C1244.587402,808.815186 1244.587402,806.870850 1244.587402,804.074036 
	C1246.601318,804.074036 1248.812256,804.074036 1251.461182,804.087219 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1251.945312,806.033569 
	C1255.734741,806.972595 1253.111206,810.123535 1253.958008,812.471313 
	C1251.826172,812.895813 1249.746704,812.895813 1247.224365,812.895813 
	C1248.073364,811.326477 1248.785767,810.009827 1249.283081,808.354614 
	C1249.067993,808.016113 1249.021606,807.994507 1249.386230,808.150330 
	C1249.848877,807.876343 1249.946899,807.446533 1250.044922,807.016724 
	C1250.044922,807.016724 1249.997192,806.998840 1250.289795,807.269836 
	C1251.064819,807.039917 1251.547119,806.539062 1252.029297,806.038208 
	C1252.029297,806.038147 1251.965454,806.003357 1251.945312,806.033569 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1442.127197,845.050354 
	C1440.473511,838.009216 1434.732910,842.574463 1430.700195,840.805847 
	C1430.700195,838.446899 1430.700195,835.765137 1430.697510,832.668701 
	C1430.829834,831.475098 1430.964844,830.696167 1431.194092,829.534668 
	C1431.667236,829.111877 1432.046143,829.071655 1433.040039,829.019409 
	C1434.090698,828.995789 1434.526611,828.984253 1434.962524,828.972717 
	C1438.966797,828.994934 1442.971069,829.017151 1447.405762,829.071777 
	C1447.958130,829.896667 1448.080200,830.689270 1448.153320,832.224854 
	C1447.727051,834.668335 1447.349487,836.368835 1446.971924,838.069275 
	C1446.971802,838.069275 1446.975952,837.981506 1446.693359,838.069885 
	C1445.932495,839.122864 1445.453979,840.087463 1444.975464,841.052002 
	C1444.975464,841.052002 1444.976318,840.982300 1444.720215,840.962036 
	C1444.312378,841.656921 1444.160767,842.372070 1444.009033,843.087158 
	C1443.473755,843.730713 1442.938477,844.374207 1442.127197,845.050354 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1434.825928,828.742310 
	C1434.526611,828.984253 1434.090698,828.995789 1433.327393,829.003662 
	C1433.563110,828.837280 1434.126221,828.674622 1434.825928,828.742310 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1645.824219,745.700928 
	C1646.018799,744.751648 1646.712524,743.935486 1647.406250,743.119263 
	C1647.984863,743.794739 1648.563477,744.470276 1649.270264,745.475891 
	C1648.373413,745.815308 1647.348389,745.824646 1645.824219,745.700928 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1404.805908,841.309875 
	C1403.489746,841.820557 1402.207397,841.978760 1399.743164,842.282715 
	C1400.680542,846.636292 1401.552979,850.687988 1402.800659,854.748291 
	C1403.176025,854.756897 1403.496216,855.027954 1403.687012,855.516968 
	C1403.832886,857.167847 1403.787720,858.329773 1403.742554,859.491577 
	C1385.484131,859.655457 1367.225708,859.819275 1348.503784,859.933167 
	C1347.863770,850.446838 1347.687378,841.010498 1347.283691,830.970886 
	C1347.699707,829.908569 1348.342773,829.449463 1348.985962,828.990417 
	C1348.985962,828.990417 1349.461182,829.029724 1350.174683,829.057129 
	C1364.173950,829.109436 1377.459717,829.134399 1390.745483,829.159302 
	C1390.745483,832.751587 1390.745483,836.343872 1390.745483,839.936157 
	C1391.413940,839.952515 1392.082397,839.968872 1392.750854,839.985229 
	C1392.952148,836.481323 1393.153564,832.977478 1393.632568,829.153687 
	C1401.386230,829.391541 1401.345703,829.401123 1403.122681,836.939209 
	C1403.452026,838.335876 1404.255249,839.620850 1404.805908,841.309875 
M1386.283691,852.557922 
	C1388.281982,852.022217 1390.280273,851.486572 1392.278442,850.950867 
	C1392.572754,851.402344 1392.866943,851.853882 1393.161133,852.305359 
	C1393.161133,850.167053 1393.161133,848.028809 1393.161133,845.267334 
	C1391.352051,846.386414 1390.162720,847.122131 1388.949219,847.872803 
	C1388.470703,847.564331 1388.057251,847.313782 1387.660278,847.039490 
	C1383.171875,843.937866 1380.873901,844.527222 1377.667114,848.756775 
	C1376.621704,850.135498 1374.342407,850.578613 1372.627563,851.449646 
	C1372.344238,850.973022 1372.060913,850.496460 1371.777710,850.019836 
	C1373.187988,848.797913 1374.598267,847.576050 1376.822754,845.648743 
	C1369.730225,846.375610 1363.791504,846.984192 1357.809692,847.597290 
	C1360.722290,852.172119 1363.272095,857.326965 1369.002930,851.589844 
	C1375.239380,856.841492 1379.504883,850.775635 1384.468750,849.064026 
	C1384.903687,850.416443 1385.248535,851.488770 1386.283691,852.557922 
M1358.857178,836.245178 
	C1358.053345,839.406189 1356.017090,840.273132 1352.038574,839.389160 
	C1354.105713,837.686462 1355.568115,836.481934 1357.030518,835.277344 
	C1356.696411,834.809875 1356.362305,834.342346 1356.028198,833.874817 
	C1354.432495,835.081909 1352.578003,836.079590 1351.356812,837.589478 
	C1350.795288,838.283875 1351.177246,840.905579 1351.646729,841.057190 
	C1353.228394,841.567932 1355.048706,841.496338 1356.764160,841.401611 
	C1359.978394,841.223999 1363.183105,840.874268 1366.227173,840.610413 
	C1366.065186,838.995728 1366.497803,835.601074 1365.504028,835.113464 
	C1363.794678,834.274719 1361.196289,835.247742 1358.857178,836.245178 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1365.089600,731.451843 
	C1371.742676,731.459106 1378.395874,731.466370 1385.498047,731.895996 
	C1385.957642,733.203369 1385.968140,734.088440 1385.739258,735.216614 
	C1384.016602,736.289368 1382.533691,737.118896 1380.854614,737.669922 
	C1378.349609,734.869324 1376.734253,736.315002 1374.885742,738.272339 
	C1374.331421,738.140930 1374.112183,738.079163 1373.845947,737.701172 
	C1371.996460,735.070557 1370.097412,734.952637 1367.782837,737.049133 
	C1366.711914,737.046631 1365.955322,737.129333 1365.169678,737.596619 
	C1365.427979,739.592163 1365.715332,741.203125 1366.020508,743.230103 
	C1365.964844,745.025818 1365.891235,746.405579 1365.817749,747.785278 
	C1361.858887,747.804810 1357.900024,747.824402 1353.357422,747.586548 
	C1352.539917,746.908752 1352.306274,746.488281 1352.371582,745.865967 
	C1353.053345,744.355408 1353.987549,742.911926 1353.707275,741.765076 
	C1353.065186,739.136292 1351.830566,736.652283 1350.868896,733.631714 
	C1350.879150,732.835327 1350.856934,732.516846 1351.290283,732.230042 
	C1356.193726,731.991821 1360.641602,731.721863 1365.089600,731.451843 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1384.417847,750.522522 
	C1386.635864,753.877136 1389.947998,756.110535 1385.661865,760.804016 
	C1382.506348,764.259583 1380.318481,764.533203 1377.038330,762.861938 
	C1373.411255,761.013855 1372.790161,757.522400 1375.515381,752.138306 
	C1376.910156,754.829468 1377.950684,756.837036 1378.998535,758.858765 
	C1379.705322,756.520386 1380.375122,754.304016 1381.065308,752.020691 
	C1380.097534,751.771545 1378.728394,751.418945 1377.251953,750.773865 
	C1379.433350,750.441162 1381.721924,750.401001 1384.417847,750.522522 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1388.000488,748.002197 
	C1387.832153,747.962402 1387.663696,747.922607 1387.244629,747.949951 
	C1382.031372,747.940552 1377.068848,747.863831 1371.548340,747.425537 
	C1371.049316,746.118896 1371.108032,745.173828 1371.473267,744.187195 
	C1372.183960,744.061951 1372.587891,743.978271 1373.171875,744.101746 
	C1374.233643,743.850464 1375.115356,743.392029 1376.347168,742.997803 
	C1378.120239,742.361450 1379.543335,741.660889 1381.381836,740.956543 
	C1391.567993,738.331665 1393.729858,739.652771 1392.400879,747.703674 
	C1390.667847,747.821289 1389.334229,747.911743 1388.000488,748.002197 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1371.166748,744.228760 
	C1371.108032,745.173828 1371.049316,746.118896 1371.094482,747.409180 
	C1369.703003,747.796509 1368.207642,747.838684 1366.265015,747.833008 
	C1365.891235,746.405579 1365.964844,745.025818 1366.017090,743.055725 
	C1366.053589,742.206726 1366.212769,742.051697 1366.858154,741.987793 
	C1367.499390,741.988098 1367.755249,742.000854 1368.019287,742.397827 
	C1369.073853,743.264282 1370.120361,743.746521 1371.166748,744.228760 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1352.072632,746.067810 
	C1352.306274,746.488281 1352.539917,746.908752 1352.900513,747.536743 
	C1351.282959,747.768188 1349.538574,747.792053 1347.357910,747.770142 
	C1346.962891,747.330078 1347.004028,746.935791 1347.473877,746.254639 
	C1349.292480,746.001099 1350.682617,746.034424 1352.072632,746.067810 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1375.031250,749.812500 
	C1374.813599,749.816406 1374.783081,749.591675 1374.806152,749.480774 
	C1374.984497,749.309387 1375.139771,749.248840 1375.544678,749.189697 
	C1375.740234,749.509766 1375.558594,749.715576 1375.031250,749.812500 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1734.704834,813.606934 
	C1732.887573,815.949646 1730.990112,817.921265 1729.027954,820.191528 
	C1728.583984,820.612305 1728.204468,820.734375 1727.518799,820.842529 
	C1727.212524,818.392944 1727.212524,815.957275 1727.212524,812.909973 
	C1729.834229,813.025208 1732.229370,813.130554 1734.704834,813.606934 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1734.398193,822.650208 
	C1733.114258,822.376892 1732.222168,822.062805 1730.628540,821.501648 
	C1732.429077,819.424194 1734.051880,817.551819 1735.841431,815.396851 
	C1737.376099,817.900879 1739.094116,820.744446 1734.398193,822.650208 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1405.554688,791.650513 
	C1405.501953,791.697449 1405.607422,791.603516 1405.554688,791.650513 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1661.744385,785.772827 
	C1661.370483,785.821472 1661.239868,785.632141 1661.109131,785.442810 
	C1661.401978,785.473511 1661.694702,785.504150 1661.744385,785.772827 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1390.786987,828.855835 
	C1377.459717,829.134399 1364.173950,829.109436 1350.413086,829.061279 
	C1351.651733,828.699036 1353.363159,828.080444 1355.079590,828.065918 
	C1366.696655,827.967712 1378.314697,827.997314 1390.387451,827.962830 
	C1390.837891,828.144226 1390.833252,828.348328 1390.786987,828.855835 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1447.263428,837.835571 
	C1447.349487,836.368835 1447.727051,834.668335 1448.234619,832.618286 
	C1449.802490,831.628296 1451.240356,830.987915 1452.512695,830.712646 
	C1451.716064,831.919189 1451.084961,832.760559 1450.346924,833.799072 
	C1450.609863,834.201111 1450.979858,834.405945 1451.734375,834.680237 
	C1453.087036,834.424805 1454.055298,834.099976 1455.023682,833.775146 
	C1455.749023,833.888672 1456.474487,834.002197 1457.551392,834.223694 
	C1457.587402,834.661499 1457.271973,834.991455 1456.885498,835.539307 
	C1453.727905,836.372131 1450.641357,836.987000 1447.263428,837.835571 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1450.453979,833.601929 
	C1451.084961,832.760559 1451.716064,831.919189 1452.659424,830.990723 
	C1453.056641,831.161682 1453.198486,831.383240 1453.558838,831.672607 
	C1454.054688,832.273987 1454.389038,832.770874 1454.873535,833.521484 
	C1454.055298,834.099976 1453.087036,834.424805 1451.542480,834.399414 
	C1450.795654,833.900085 1450.624756,833.751038 1450.453979,833.601929 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1202.990234,844.007812 
	C1203.115356,844.158691 1203.250122,844.303955 1203.384888,844.449280 
	C1203.293823,844.395752 1203.202637,844.342224 1203.060791,844.136108 
	C1203.010010,843.983521 1203.000000,844.002319 1202.990234,844.007812 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1201.309692,860.661316 
	C1201.047607,860.648193 1200.904907,860.513489 1200.762329,860.378784 
	C1200.984619,860.432434 1201.206787,860.486084 1201.309692,860.661316 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1144.982910,710.020325 
	C1142.655640,709.269104 1140.314697,708.557373 1138.006348,707.752014 
	C1136.591675,707.258484 1135.226929,706.621582 1133.903809,705.539307 
	C1133.967651,705.029480 1134.287354,704.707214 1134.287354,704.707214 
	C1138.274048,705.163391 1142.260742,705.619507 1146.235229,706.074219 
	C1145.794678,707.266357 1145.424438,708.268555 1145.028687,709.633911 
	C1145.003174,709.997131 1144.982910,710.020325 1144.982910,710.020325 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1130.005615,700.476135 
	C1130.325439,698.848816 1130.762207,697.468201 1131.569824,695.988647 
	C1132.994263,696.976074 1134.047852,698.062500 1135.080078,699.542114 
	C1135.058838,699.935242 1134.908813,700.113953 1134.908813,700.113892 
	C1134.156738,699.628052 1133.404663,699.142212 1132.120850,698.312866 
	C1132.120850,699.568787 1132.120850,700.368408 1132.027832,701.610229 
	C1131.934814,702.052429 1131.641724,702.290894 1131.641724,702.290894 
	C1131.135254,701.768250 1130.628784,701.245605 1130.005615,700.476135 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1147.943115,712.065063 
	C1147.034302,711.584839 1146.125488,711.104553 1145.099854,710.322266 
	C1144.982910,710.020325 1145.003174,709.997131 1145.014404,709.985107 
	C1145.948486,710.434570 1146.871216,710.896118 1147.894287,711.683350 
	C1147.994751,712.009094 1147.943115,712.065063 1147.943115,712.065063 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1138.622314,703.354492 
	C1138.464600,703.154358 1138.481567,702.837769 1138.498535,702.521118 
	C1138.598022,702.760071 1138.697510,702.999023 1138.622314,703.354492 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1132.077515,701.928589 
	C1132.553223,702.241943 1132.886230,702.679138 1133.130859,703.538574 
	C1133.042603,703.960815 1132.753418,704.253967 1132.753418,704.253967 
	C1132.438965,703.782776 1132.124512,703.311584 1131.725830,702.565674 
	C1131.641724,702.290894 1131.934814,702.052429 1132.077515,701.928589 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1135.133911,699.845764 
	C1135.581543,700.064514 1135.953979,700.372742 1136.326416,700.680969 
	C1135.952637,700.638611 1135.578735,700.596191 1135.056885,700.333862 
	C1134.908813,700.113953 1135.058838,699.935242 1135.133911,699.845764 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1148.022461,711.983032 
	C1148.242676,712.102356 1148.435059,712.247681 1148.627441,712.393066 
	C1148.437134,712.404663 1148.246826,712.416199 1147.999756,712.246399 
	C1147.943115,712.065063 1147.994751,712.009094 1148.022461,711.983032 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1133.187500,703.814453 
	C1133.607666,703.835083 1133.838257,704.049927 1134.155762,704.509949 
	C1134.287354,704.707214 1133.967651,705.029480 1133.804688,705.191467 
	C1133.369629,705.176575 1133.152832,704.947266 1132.872314,704.459839 
	C1132.753418,704.253967 1133.042603,703.960815 1133.187500,703.814453 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1124.058594,721.513672 
	C1118.658203,721.957214 1113.354858,721.823608 1108.060059,721.977356 
	C1105.152344,722.061890 1103.862183,721.233582 1103.946899,718.082092 
	C1104.133789,711.132263 1104.018311,704.174377 1104.105957,696.782654 
	C1107.758911,696.323120 1111.332031,696.300659 1115.482422,696.645264 
	C1118.619751,699.052795 1121.180176,701.093140 1123.740479,703.133484 
	C1123.880127,709.107788 1124.019653,715.082092 1124.058594,721.513672 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1123.816895,702.729736 
	C1121.180176,701.093140 1118.619751,699.052795 1115.918701,696.729858 
	C1116.822998,696.322937 1117.867920,696.198608 1119.300293,696.084839 
	C1120.873535,697.104065 1122.059326,698.112854 1123.569092,699.114380 
	C1123.893311,700.180115 1123.893311,701.253052 1123.816895,702.729736 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1185.927612,748.085754 
	C1185.872803,747.566162 1185.817993,747.046509 1185.830322,745.725769 
	C1185.880615,742.649292 1185.863892,740.373962 1185.847168,738.098633 
	C1186.916016,736.794861 1187.984741,735.491028 1189.824219,734.225830 
	C1193.410889,735.033142 1196.226685,735.801880 1198.985352,736.898743 
	C1198.362061,737.814941 1197.796143,738.403015 1196.866577,738.962402 
	C1194.622925,740.942078 1192.742798,742.950378 1190.314209,745.544617 
	C1194.239624,746.092041 1197.075073,747.080383 1197.669312,742.939087 
	C1198.676880,743.008728 1199.351318,743.013550 1199.929443,743.363647 
	C1199.193481,745.118042 1198.553589,746.527222 1197.470947,747.914246 
	C1195.564575,748.090637 1194.100830,748.289124 1192.228149,748.407959 
	C1189.855347,748.247498 1187.891479,748.166626 1185.927612,748.085754 
M1191.962402,736.790466 
	C1187.670898,737.410339 1187.670898,737.410339 1188.834473,743.843689 
	C1190.653198,741.895569 1192.313354,740.170532 1193.853271,738.344177 
	C1193.952637,738.226318 1193.125488,737.327087 1191.962402,736.790466 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1199.042603,736.570618 
	C1196.226685,735.801880 1193.410889,735.033142 1190.188965,734.198303 
	C1190.506714,733.252441 1191.181030,732.325806 1191.962402,731.500671 
	C1197.188110,725.982910 1197.187866,726.005005 1203.916382,729.378967 
	C1204.612305,729.727966 1205.466064,729.762146 1206.619873,729.957031 
	C1207.001221,730.166870 1207.009521,730.362183 1206.898804,731.182861 
	C1206.721436,731.958984 1206.582886,732.134888 1206.620728,732.255371 
	C1206.705322,732.524231 1206.878052,732.765381 1207.008057,733.010254 
	C1207.001221,733.002686 1207.010498,733.018982 1206.710693,732.790649 
	C1205.588013,733.101685 1204.765137,733.641113 1203.604980,734.160156 
	C1201.859375,734.950134 1200.450928,735.760376 1199.042603,736.570618 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1185.528076,738.142578 
	C1185.863892,740.373962 1185.880615,742.649292 1185.794678,745.335571 
	C1184.109985,745.429260 1182.528320,745.112122 1180.597046,744.779785 
	C1181.901367,742.571899 1183.555176,740.379211 1185.528076,738.142578 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1218.081299,699.058228 
	C1222.362915,694.517456 1226.762695,690.078979 1231.407471,685.913757 
	C1232.318481,685.096741 1234.175171,685.134521 1235.599121,685.120728 
	C1247.740112,685.002808 1259.882080,684.956299 1272.023682,684.905457 
	C1276.627319,684.886169 1281.247070,685.135559 1285.827637,684.812195 
	C1287.812866,684.672119 1289.717651,683.393616 1292.174805,682.324158 
	C1293.134277,682.014404 1293.578003,682.012695 1294.031006,682.404053 
	C1295.657104,686.016663 1297.650757,685.796509 1299.972168,683.517029 
	C1301.268433,682.244263 1302.685059,681.094299 1304.047485,679.888977 
	C1304.419556,680.346436 1304.791748,680.803894 1305.163940,681.261292 
	C1303.851196,682.505859 1302.538330,683.750366 1301.225586,684.994934 
	C1301.462280,685.421631 1301.698975,685.848328 1301.935547,686.275024 
	C1303.859619,685.494385 1305.783691,684.713684 1307.867310,684.258301 
	C1310.030640,685.520142 1312.016113,686.503540 1314.071655,687.307983 
	C1314.243652,687.375366 1314.887329,686.237732 1315.721924,685.435120 
	C1318.079712,685.105408 1320.027466,685.000427 1320.883301,684.954346 
	C1320.282471,686.875610 1319.684448,688.787842 1318.817261,690.935608 
	C1317.449341,692.099426 1316.350708,693.027710 1316.187012,693.165955 
	C1313.243408,692.348145 1311.219360,691.785767 1309.027588,690.832642 
	C1303.703979,687.456726 1298.456421,689.302734 1293.242676,689.359131 
	C1293.066772,689.804138 1292.890747,690.249207 1292.714722,690.694214 
	C1294.271729,692.145569 1295.828735,693.596985 1297.632080,695.278015 
	C1294.156494,699.056946 1296.140015,702.113464 1299.713379,705.227600 
	C1299.488525,707.075684 1299.216797,708.539062 1298.737305,710.100464 
	C1298.278198,710.394836 1298.113770,710.647766 1297.702148,710.832642 
	C1296.689209,710.499817 1296.010742,710.291687 1295.234375,710.077515 
	C1295.136597,710.071472 1294.940063,709.752808 1294.940918,709.295776 
	C1293.670532,704.663025 1292.438354,700.474182 1291.048340,696.338440 
	C1290.945190,696.031494 1289.708740,696.105469 1289.003662,695.609985 
	C1290.658936,689.854736 1290.066162,688.952454 1284.664429,688.962280 
	C1275.582397,688.978760 1266.498657,688.982727 1257.419189,689.166809 
	C1254.747192,689.220947 1251.179443,688.900513 1249.579346,690.406372 
	C1242.742920,696.839722 1236.489136,703.892029 1229.595703,711.169434 
	C1234.970825,714.959534 1237.263428,723.272888 1245.972900,720.339966 
	C1244.438110,722.633850 1238.485596,721.754883 1242.009399,726.791626 
	C1244.266724,726.323669 1246.473145,725.866211 1248.980225,725.346375 
	C1248.583130,723.737976 1248.239014,722.344421 1248.095947,720.703735 
	C1249.197632,720.625061 1250.098633,720.793335 1251.008789,721.345703 
	C1252.376831,723.199524 1253.735474,724.669373 1255.059570,726.495361 
	C1255.018188,727.565796 1255.011353,728.280090 1254.712402,729.008118 
	C1254.225586,729.006042 1254.031006,728.990234 1253.450073,728.939453 
	C1249.166016,728.060364 1245.115234,726.325439 1241.783691,730.757690 
	C1239.590820,730.755371 1237.743042,730.779236 1235.895264,730.803101 
	C1236.385864,723.166504 1236.101074,722.722473 1232.121582,722.166870 
	C1227.542847,715.060669 1222.654419,710.141663 1216.385254,706.096985 
	C1216.088623,704.991821 1216.136841,703.894470 1216.233887,701.690552 
	C1216.325684,701.581543 1217.305542,700.418884 1218.081299,699.058228 
M1228.960938,702.421692 
	C1227.395020,703.402527 1225.828979,704.383362 1224.263062,705.364136 
	C1224.755859,706.253662 1225.248535,707.143188 1225.741333,708.032715 
	C1227.281250,707.732178 1229.184204,707.896851 1230.287354,707.036316 
	C1232.742798,705.120667 1234.865479,702.754822 1237.000122,700.457642 
	C1239.971924,697.259583 1242.830566,693.956421 1246.120850,690.268738 
	C1241.134644,690.368225 1241.134644,690.368225 1228.960938,702.421692 
M1233.020508,695.520569 
	C1235.022095,694.361328 1237.023682,693.202087 1239.025269,692.042847 
	C1238.429199,691.453552 1237.833130,690.864258 1237.237061,690.274963 
	C1232.191650,689.882690 1222.316040,698.939575 1222.687988,705.106628 
	C1226.393066,701.682861 1229.456177,698.852173 1233.020508,695.520569 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1235.435791,730.838989 
	C1237.743042,730.779236 1239.590820,730.755371 1242.228027,730.816284 
	C1245.784058,730.912720 1248.564087,731.081970 1251.309326,730.845398 
	C1252.198975,730.768616 1252.996704,729.628296 1253.836548,728.974487 
	C1254.031006,728.990234 1254.225586,729.006042 1255.005127,729.319946 
	C1266.648804,729.828918 1277.707153,730.084045 1288.766724,730.148743 
	C1289.486084,730.152893 1290.214111,728.694824 1291.347412,727.892456 
	C1296.919434,731.591553 1300.802124,731.308838 1303.023682,728.027832 
	C1305.553589,728.981445 1307.815186,729.833923 1310.076904,730.686401 
	C1310.300293,730.163330 1310.523804,729.640198 1310.747314,729.117065 
	C1307.475342,726.869995 1306.318604,720.966003 1301.421387,723.092041 
	C1300.335449,720.820251 1299.413574,718.677002 1298.296387,716.640869 
	C1297.687500,715.531006 1296.747314,714.603027 1296.029053,713.165161 
	C1296.125000,712.448975 1296.148193,712.160767 1296.459961,712.033569 
	C1298.967773,714.381653 1301.186890,716.568787 1303.406006,718.755859 
	C1303.682495,718.529663 1303.958984,718.303406 1304.235474,718.077209 
	C1303.968994,717.001038 1303.702515,715.924927 1303.906250,714.878052 
	C1305.168213,715.375183 1305.959961,715.843018 1306.697021,716.714844 
	C1305.146973,721.265808 1306.242432,723.474731 1311.036377,723.224426 
	C1311.103149,723.690674 1311.133423,723.876648 1311.268799,724.390137 
	C1312.442871,727.459717 1313.086914,730.782227 1317.453857,729.109619 
	C1317.525024,729.102356 1317.667236,729.119995 1317.900513,729.183105 
	C1318.133789,729.246155 1318.612549,729.312378 1318.704956,729.494568 
	C1318.797363,729.676758 1318.984985,730.039734 1319.088867,730.290527 
	C1319.456177,731.013611 1319.719482,731.485901 1319.982910,731.958191 
	C1319.982910,731.958191 1319.574829,732.150696 1319.367920,732.240723 
	C1317.362061,732.312744 1315.563232,732.294678 1313.317139,732.327271 
	C1309.409790,732.162537 1305.525146,730.555176 1303.747192,735.559387 
	C1303.568115,736.063293 1301.527222,735.905640 1300.021484,735.127808 
	C1300.823242,734.506897 1301.625000,733.885986 1303.317383,732.575378 
	C1300.460693,732.575378 1298.048218,731.881897 1296.575928,732.761719 
	C1294.957520,733.728882 1294.323975,734.840942 1293.271606,732.454407 
	C1293.139526,732.155090 1292.023193,732.211426 1291.367188,732.233398 
	C1280.234009,732.606628 1269.101318,733.000549 1257.069336,733.217773 
	C1251.164429,733.104309 1246.158569,733.163025 1240.768799,733.156250 
	C1239.610962,733.120911 1238.837036,733.151062 1237.713867,733.179810 
	C1237.133789,733.211121 1236.903076,733.243958 1236.235352,733.200317 
	C1226.203735,733.088501 1216.609375,733.053162 1207.014893,733.017822 
	C1206.878052,732.765381 1206.705322,732.524231 1206.620728,732.255371 
	C1206.582886,732.134888 1206.721436,731.958984 1206.919189,731.475342 
	C1216.364502,731.053162 1225.670288,730.963989 1235.435791,730.838989 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1153.644287,679.063965 
	C1155.396240,682.627563 1153.307007,684.067078 1150.299316,684.773315 
	C1150.048462,684.832275 1149.497925,683.615662 1149.233398,682.648132 
	C1150.223389,681.762634 1151.066772,681.220703 1151.971436,680.151001 
	C1152.032837,679.623230 1152.101318,679.178223 1152.101318,679.178223 
	C1152.513062,679.151306 1152.924805,679.124390 1153.644287,679.063965 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1151.860107,679.152954 
	C1152.101318,679.178223 1152.032837,679.623230 1151.984985,679.843262 
	C1151.711426,679.859680 1151.485596,679.656128 1151.259888,679.452515 
	C1151.379517,679.344238 1151.499146,679.235901 1151.860107,679.152954 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1185.536621,748.071777 
	C1187.891479,748.166626 1189.855347,748.247498 1192.384888,748.722473 
	C1193.817261,751.317383 1194.684082,753.518127 1195.550781,755.718872 
	C1195.102539,755.954590 1194.654175,756.190247 1194.205933,756.425903 
	C1193.583008,755.206604 1192.960083,753.987244 1192.337158,752.767944 
	C1191.990112,753.076843 1191.642944,753.385681 1191.295898,753.694580 
	C1191.295898,755.570312 1191.295898,757.446106 1191.295898,759.415283 
	C1192.188110,759.626160 1192.829224,759.720398 1193.429443,759.931213 
	C1194.861328,760.434082 1196.273926,760.991882 1197.694214,761.527527 
	C1196.789795,762.654358 1195.821655,763.737183 1195.003540,764.923462 
	C1194.456787,765.716187 1194.123535,766.656067 1193.333374,767.605591 
	C1190.006958,768.165100 1187.041748,768.648987 1184.076660,769.132935 
	C1180.106323,769.433533 1178.848389,767.629150 1179.221558,763.722229 
	C1179.563965,760.138367 1179.136719,756.484924 1179.117310,752.861755 
	C1179.083496,746.538208 1179.105713,746.538086 1185.536621,748.071777 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1183.950439,769.431213 
	C1187.041748,768.648987 1190.006958,768.165100 1193.726562,767.684448 
	C1195.270508,767.824890 1196.059814,767.961975 1197.045654,768.353210 
	C1198.833984,768.723572 1200.425781,768.839844 1202.156494,769.266968 
	C1202.510132,771.343567 1202.724976,773.109436 1202.939819,774.875244 
	C1200.726318,774.847168 1198.501953,774.666809 1196.302002,774.825928 
	C1192.794189,775.079712 1189.302124,775.795288 1185.805054,775.784302 
	C1184.886230,775.781433 1183.973511,773.824585 1182.972168,772.432983 
	C1183.199097,771.310364 1183.511597,770.519958 1183.950439,769.431213 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1177.850342,761.696228 
	C1177.776611,763.579102 1177.700439,765.008850 1177.619507,766.528015 
	C1172.066650,764.458130 1170.297363,757.569153 1174.283325,752.969849 
	C1174.785522,754.431824 1175.211060,755.670959 1175.636719,756.910156 
	C1175.830444,756.895752 1176.024048,756.881409 1176.217773,756.867004 
	C1176.217773,754.142822 1176.217773,751.418701 1176.217773,748.694519 
	C1176.761108,748.667175 1177.304443,748.639771 1177.847778,748.612427 
	C1177.847778,752.822632 1177.847778,757.032837 1177.850342,761.696228 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1422.246948,861.477295 
	C1423.234009,863.729797 1423.791626,865.978760 1424.732178,869.771851 
	C1420.384033,868.653748 1417.467529,867.903809 1414.018921,867.016968 
	C1413.798828,867.441528 1413.232056,868.535461 1412.219727,869.550598 
	C1411.455200,868.733704 1411.136108,867.995605 1410.847656,866.819641 
	C1410.918213,864.919495 1410.958130,863.457153 1410.998047,861.994812 
	C1414.604492,861.821167 1418.211060,861.647461 1422.246948,861.477295 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1361.557861,880.815796 
	C1361.718872,881.715027 1361.420166,882.629089 1361.196777,883.897583 
	C1360.222900,886.057983 1360.222900,886.057983 1357.429688,883.268921 
	C1353.824951,883.260925 1350.564941,883.260925 1346.777588,883.260925 
	C1346.658203,884.969238 1346.536865,886.702820 1346.212402,888.771240 
	C1345.300659,886.996765 1344.592163,884.887268 1343.899170,882.374084 
	C1349.642700,881.590393 1355.370361,881.210449 1361.557861,880.815796 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1410.725830,861.982788 
	C1410.958130,863.457153 1410.918213,864.919495 1410.484619,866.805054 
	C1406.853271,867.560364 1403.815918,868.069824 1405.326416,872.607422 
	C1405.326416,872.607422 1405.124634,872.847473 1405.022461,872.967712 
	C1401.934937,871.961121 1400.677002,868.584839 1402.222046,865.877075 
	C1402.771606,864.913696 1403.287231,863.930908 1403.855469,862.624817 
	C1404.469604,862.360352 1405.046753,862.427979 1405.841064,863.049316 
	C1406.506836,864.221802 1406.955688,864.840698 1407.404541,865.459534 
	C1407.967163,864.589661 1408.529907,863.719849 1409.092773,862.850098 
	C1409.546387,862.557007 1410.000122,862.263855 1410.725830,861.982788 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1404.196289,859.604980 
	C1403.787720,858.329773 1403.832886,857.167847 1403.842529,855.657715 
	C1405.484253,855.229126 1407.161377,855.148804 1409.246582,854.728455 
	C1410.437866,854.591248 1411.220947,854.794128 1412.004150,854.996948 
	C1412.203979,856.419678 1412.403931,857.842468 1412.667480,859.718323 
	C1409.933960,859.718323 1407.292114,859.718323 1404.196289,859.604980 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1333.072388,869.949158 
	C1339.322388,868.986938 1340.401001,873.847229 1342.150391,878.348999 
	C1341.041992,878.786194 1339.903564,878.860107 1338.337769,878.903259 
	C1339.705078,874.487427 1337.591675,872.305542 1333.255127,870.808105 
	C1332.929321,870.422974 1333.072388,869.949158 1333.072388,869.949158 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1440.542236,859.047974 
	C1438.669556,859.214783 1436.454956,859.284607 1433.858154,859.191528 
	C1433.164062,858.140991 1432.851807,857.253357 1432.504761,856.266724 
	C1436.973389,854.222412 1436.973389,854.222412 1440.542236,859.047974 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1426.504150,861.432007 
	C1426.720337,865.784790 1426.687744,870.060913 1426.362671,874.610596 
	C1425.732422,870.677734 1425.394531,866.471436 1425.142944,861.851379 
	C1425.571411,861.410217 1425.913452,861.382812 1426.504150,861.432007 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1344.393188,874.544678 
	C1344.412720,870.246643 1344.412720,866.374634 1344.412720,862.502625 
	C1344.864380,862.511597 1345.315918,862.520508 1345.767578,862.529419 
	C1345.767578,867.896057 1345.767578,873.262634 1345.767578,878.629211 
	C1345.302979,878.638672 1344.838257,878.648071 1344.373657,878.657471 
	C1344.373657,877.428528 1344.373657,876.199585 1344.393188,874.544678 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1408.706299,862.826538 
	C1408.529907,863.719849 1407.967163,864.589661 1407.404541,865.459534 
	C1406.955688,864.840698 1406.506836,864.221802 1406.032959,863.273804 
	C1406.778442,862.897461 1407.549194,862.850220 1408.706299,862.826538 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1412.239868,854.824097 
	C1411.220947,854.794128 1410.437866,854.591248 1409.352783,854.437927 
	C1409.843018,853.835938 1410.635132,853.184448 1411.427368,852.532959 
	C1411.776733,853.239075 1412.126221,853.945129 1412.239868,854.824097 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1430.451416,858.357788 
	C1430.550171,858.294434 1430.606079,858.534363 1430.606079,858.534363 
	C1430.606079,858.534363 1430.352539,858.421204 1430.451416,858.357788 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1271.116699,889.392334 
	C1273.229370,888.735229 1275.427124,888.268860 1277.743408,887.777405 
	C1277.743408,888.415649 1277.743408,890.272949 1277.743408,891.310669 
	C1275.845947,891.079590 1273.963867,890.850281 1271.755737,890.501221 
	C1271.401489,890.101685 1271.325439,889.835571 1271.116699,889.392334 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1262.904785,888.001160 
	C1263.219238,887.632446 1263.815552,887.256409 1264.722778,886.971741 
	C1264.418091,887.373291 1263.802490,887.683533 1262.904785,888.001160 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1302.022217,891.976929 
	C1301.425537,892.222656 1300.833374,892.480530 1300.228760,892.704529 
	C1300.099731,892.752258 1299.924927,892.676880 1299.401855,892.613220 
	C1299.401855,890.901672 1299.401855,889.139954 1299.401855,887.378174 
	C1299.828125,887.257385 1300.254395,887.136536 1300.680664,887.015686 
	C1301.147095,888.417603 1301.613525,889.819519 1302.039795,891.611328 
	C1301.999756,892.001160 1302.022217,891.976929 1302.022217,891.976929 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1301.988037,892.012939 
	C1303.631592,892.348450 1305.286865,892.672119 1306.942261,892.995728 
	C1306.598755,893.773560 1306.255127,894.551331 1305.911621,895.329102 
	C1304.624390,894.488281 1303.337158,893.647461 1302.036011,892.391785 
	C1302.022217,891.976929 1301.999756,892.001160 1301.988037,892.012939 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1310.842529,885.920898 
	C1311.046387,885.685303 1311.291260,885.667969 1311.896973,885.577881 
	C1312.028320,885.993042 1311.798828,886.480896 1311.279297,886.998108 
	C1310.956177,886.731079 1310.920898,886.434937 1310.842529,885.920898 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1252.056396,876.569824 
	C1250.150879,875.747803 1248.504883,874.676331 1246.858887,873.604797 
	C1248.956909,872.067749 1251.054810,870.530640 1253.547119,869.027100 
	C1256.042480,873.449951 1258.561523,867.411438 1261.622925,870.016968 
	C1259.941284,870.725281 1258.367310,871.388306 1256.793213,872.051331 
	C1257.175171,872.683350 1257.557129,873.315430 1257.939087,873.947449 
	C1259.004028,873.869812 1260.068848,873.792236 1261.548340,873.985840 
	C1261.975220,874.505249 1261.987671,874.753296 1262.000000,875.001343 
	C1260.131348,875.298096 1258.266357,875.621399 1256.392822,875.882629 
	C1255.039795,876.071289 1253.675171,876.177429 1252.056396,876.569824 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1242.017944,845.935974 
	C1242.477417,848.387207 1243.645996,850.967712 1243.228149,853.259033 
	C1242.619873,856.595703 1244.675659,855.841125 1246.689697,856.206177 
	C1247.091187,856.288147 1247.007812,856.743347 1246.524780,856.958557 
	C1244.580566,857.173767 1243.119385,857.173767 1241.744629,857.173767 
	C1240.180420,862.925049 1239.733643,863.235657 1234.273438,862.290894 
	C1236.126221,861.030701 1237.760376,859.919250 1240.307495,858.186829 
	C1238.221802,857.606201 1237.233765,857.331116 1236.245728,857.056030 
	C1236.160889,855.303345 1236.075928,853.550720 1236.349487,851.398071 
	C1241.360352,852.334351 1239.905151,847.263733 1242.017944,845.935974 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1262.317505,874.985962 
	C1261.987671,874.753296 1261.975220,874.505249 1261.954468,873.881836 
	C1265.246338,867.251465 1265.894287,867.154968 1272.633545,870.089172 
	C1273.837402,870.613464 1275.681274,869.667908 1277.642944,869.329651 
	C1277.642944,871.110596 1277.642944,873.181091 1277.642944,874.272766 
	C1275.177979,874.380432 1272.962036,874.477234 1270.380371,874.589966 
	C1269.495605,872.335876 1267.718262,871.683044 1265.081177,874.167175 
	C1264.515625,874.700073 1263.463257,874.716309 1262.317505,874.985962 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1332.958618,869.629639 
	C1333.072388,869.949158 1332.929321,870.422974 1332.921753,870.670044 
	C1329.334229,875.334839 1328.754028,875.318420 1324.916748,870.408264 
	C1324.827515,871.929016 1324.754639,873.170959 1324.661133,874.764038 
	C1322.852661,874.764038 1321.153442,874.764038 1319.454102,874.764038 
	C1319.130249,874.373901 1318.806396,873.983704 1318.482544,873.593567 
	C1320.321045,872.704651 1322.159546,871.815735 1323.998169,870.926819 
	C1323.678711,870.470886 1323.359131,870.014954 1323.039673,869.559021 
	C1321.859985,869.421753 1320.680420,869.284546 1319.547119,869.152649 
	C1319.166260,866.613037 1322.714111,866.581909 1325.440186,868.913513 
	C1325.673706,869.113220 1326.013428,869.188721 1326.303711,869.321899 
	C1326.422852,868.852295 1326.541992,868.382751 1326.661133,867.913147 
	C1328.722412,868.378845 1330.783569,868.844482 1332.958618,869.629639 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1236.079590,857.383484 
	C1237.233765,857.331116 1238.221802,857.606201 1240.307495,858.186829 
	C1237.760376,859.919250 1236.126221,861.030701 1234.273438,862.290894 
	C1239.733643,863.235657 1240.180420,862.925049 1241.744629,857.173767 
	C1243.119385,857.173767 1244.580566,857.173767 1246.460938,857.181030 
	C1246.880127,857.188232 1247.001099,857.578430 1247.027344,858.001038 
	C1247.017212,858.586914 1246.980591,858.750122 1246.627197,858.960999 
	C1245.740723,859.122925 1245.171387,859.237244 1243.144165,859.643982 
	C1245.405273,859.874390 1246.192139,859.954590 1247.024170,860.275696 
	C1247.069336,860.516602 1247.000122,861.001831 1246.792725,861.087769 
	C1246.500122,861.296204 1246.318115,861.493286 1246.345459,861.528992 
	C1246.511353,861.744385 1246.724976,861.922791 1247.137573,862.155151 
	C1247.352539,862.196777 1247.786011,862.260376 1247.786011,862.260376 
	C1244.057129,863.248352 1240.328247,864.236267 1236.230835,865.331055 
	C1231.736572,863.181335 1231.746094,861.729187 1236.079590,857.383484 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1247.000122,861.001831 
	C1247.000122,861.001831 1247.069336,860.516602 1247.017090,859.995239 
	C1246.957886,859.287048 1246.951050,859.100159 1246.944092,858.913330 
	C1246.980591,858.750122 1247.017212,858.586914 1247.121948,858.182129 
	C1247.712891,858.173035 1248.235718,858.405518 1248.893311,858.904175 
	C1248.801270,860.051147 1248.574219,860.931885 1248.066650,862.036499 
	C1247.786011,862.260376 1247.352539,862.196777 1247.166992,861.877441 
	C1246.987793,861.372681 1246.993896,861.187256 1247.000122,861.001831 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1236.230469,876.616943 
	C1232.857666,875.552856 1232.457642,871.868774 1235.517090,868.624512 
	C1238.317383,868.678040 1240.762329,868.696899 1243.144165,869.094849 
	C1244.289429,869.286194 1245.315186,870.192810 1245.888306,871.630493 
	C1243.966187,871.350586 1242.044067,871.070679 1241.204102,870.948364 
	C1239.209351,873.222351 1237.826660,874.798523 1236.230469,876.616943 
M1235.481079,873.278320 
	C1235.351685,873.370605 1235.222290,873.462952 1235.092896,873.555298 
	C1235.201416,873.671448 1235.302124,873.869446 1235.420654,873.880798 
	C1235.540527,873.892273 1235.678223,873.718872 1235.481079,873.278320 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1242.017822,845.694214 
	C1239.905151,847.263733 1241.360352,852.334351 1236.333008,851.020691 
	C1234.543579,851.369629 1233.129150,851.695923 1231.339111,852.022339 
	C1232.949219,849.578125 1234.934937,847.133789 1236.948975,844.330933 
	C1238.394165,844.323364 1239.811279,844.674255 1241.598877,844.998291 
	C1241.969360,844.971497 1242.017700,845.452515 1242.017822,845.694214 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1477.855835,895.510986 
	C1480.235596,892.912354 1483.411621,890.825378 1481.404785,885.873535 
	C1481.303345,884.173706 1481.414429,883.115662 1481.525391,882.057617 
	C1481.732422,882.271362 1481.978271,882.335510 1482.840088,882.144165 
	C1483.676514,882.053040 1483.936035,882.067810 1484.195435,882.082581 
	C1486.273682,882.147522 1488.352051,882.212463 1490.773682,882.647217 
	C1490.752808,883.683777 1490.388306,884.350586 1489.694092,885.106445 
	C1487.895630,887.315857 1485.111450,889.584229 1490.148682,891.282532 
	C1489.683716,892.050720 1489.155273,892.737732 1488.475708,892.956909 
	C1486.340942,893.645752 1484.149292,894.158691 1481.979736,894.739807 
	C1480.658569,895.072632 1479.337402,895.405396 1477.855835,895.510986 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1481.313232,881.618225 
	C1481.414429,883.115662 1481.303345,884.173706 1481.112549,885.623779 
	C1480.642456,886.406189 1480.252197,886.796570 1479.812622,887.477783 
	C1477.863770,887.639709 1475.417969,888.209412 1474.177124,887.238647 
	C1471.484497,885.132446 1468.966919,883.674866 1465.393066,884.018127 
	C1464.253174,884.127686 1462.977539,882.824463 1461.390015,882.080688 
	C1462.274414,881.532715 1463.535034,880.673279 1464.791138,880.679810 
	C1470.228516,880.708130 1475.664429,880.989502 1481.313232,881.618225 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1482.414551,894.919739 
	C1484.149292,894.158691 1486.340942,893.645752 1488.475708,892.956909 
	C1489.155273,892.737732 1489.683716,892.050720 1490.141357,891.288574 
	C1490.001465,890.999390 1489.990845,891.014099 1490.315063,891.006897 
	C1491.324219,889.178223 1492.009277,887.356750 1492.785645,885.292603 
	C1491.809570,885.182251 1490.893799,885.078674 1489.988770,884.986328 
	C1489.999512,884.997559 1490.023926,885.017395 1490.023926,885.017395 
	C1490.388306,884.350586 1490.752808,883.683777 1491.210205,882.745728 
	C1491.772461,882.476685 1492.241455,882.478882 1492.941650,882.435425 
	C1493.569458,886.405579 1493.966187,890.421448 1494.428467,895.099670 
	C1490.050415,895.099670 1486.449951,895.099670 1482.414551,894.919739 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1503.552490,870.044067 
	C1505.320435,869.989136 1506.693481,870.045044 1508.476807,870.104492 
	C1510.876709,870.790100 1512.507935,873.994263 1515.022705,870.979614 
	C1515.540405,871.660828 1516.058228,872.342102 1516.548584,873.466675 
	C1515.853394,873.990540 1515.185669,874.070984 1513.931396,874.572388 
	C1512.250122,875.664490 1511.155151,876.335632 1509.759521,876.778015 
	C1508.725464,874.395142 1509.438477,870.709351 1504.901733,872.442322 
	C1505.701538,872.816040 1506.378662,873.132446 1507.252930,873.540894 
	C1505.377563,874.063354 1503.696411,874.531738 1502.006836,874.592651 
	C1502.024292,873.638855 1502.050415,873.092346 1502.076416,872.545898 
	C1502.120605,872.240479 1502.138428,871.933105 1502.122070,871.489136 
	C1502.462036,870.954712 1502.809814,870.554810 1503.552490,870.044067 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1502.015259,875.000000 
	C1503.696411,874.531738 1505.377563,874.063354 1507.252930,873.540894 
	C1506.378662,873.132446 1505.701538,872.816040 1504.901733,872.442322 
	C1509.438477,870.709351 1508.725464,874.395142 1509.968018,876.871765 
	C1510.695557,877.408264 1510.898682,877.636108 1511.086060,877.877808 
	C1507.398193,877.877808 1503.710205,877.877808 1499.791626,877.877808 
	C1500.288818,876.911926 1500.645630,876.218567 1501.295410,875.205933 
	C1501.588257,874.886536 1502.015259,875.000000 1502.015259,875.000000 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1534.434326,881.614136 
	C1531.329468,881.977539 1528.145264,881.898560 1524.503296,881.851013 
	C1527.233276,878.038391 1530.814453,879.901367 1534.434326,881.614136 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1511.484131,877.897461 
	C1510.898682,877.636108 1510.695557,877.408264 1510.268555,877.100525 
	C1511.155151,876.335632 1512.250122,875.664490 1513.659668,874.768066 
	C1516.530762,877.684814 1514.517456,877.993652 1511.484131,877.897461 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1502.006836,874.592651 
	C1502.015259,875.000000 1501.588257,874.886536 1501.368408,874.875977 
	C1499.776123,874.865356 1498.403809,874.865356 1497.031494,874.865356 
	C1496.927246,874.500916 1496.822998,874.136414 1496.718750,873.771973 
	C1498.229980,873.309875 1499.741211,872.847717 1501.664551,872.465759 
	C1502.050415,873.092346 1502.024292,873.638855 1502.006836,874.592651 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1459.257568,881.660034 
	C1458.833618,882.703064 1458.178345,883.377197 1457.271729,884.297485 
	C1456.650757,883.540588 1456.281250,882.537415 1455.911621,881.534241 
	C1456.949829,881.453125 1457.988037,881.372070 1459.257568,881.660034 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1480.755737,876.659851 
	C1480.406982,877.190430 1479.697632,877.643738 1478.988403,878.097046 
	C1478.823242,877.758240 1478.658203,877.419373 1478.493042,877.080505 
	C1479.127075,876.914551 1479.761230,876.748535 1480.755737,876.659851 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1474.724121,874.285339 
	C1474.015503,874.196106 1473.654663,873.965576 1473.293701,873.734985 
	C1473.773071,873.451111 1474.252563,873.167175 1474.731934,872.883179 
	C1474.845215,873.303467 1474.958496,873.723694 1474.724121,874.285339 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1523.706909,882.351807 
	C1523.417969,882.996765 1522.958252,883.632996 1522.156250,884.190857 
	C1521.793335,883.917786 1521.772339,883.722961 1522.059814,883.175110 
	C1522.656128,882.679749 1522.931274,882.516174 1523.193848,882.331360 
	C1523.193848,882.331360 1523.536011,882.343018 1523.706909,882.351807 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1484.169312,881.701294 
	C1483.936035,882.067810 1483.676514,882.053040 1483.026367,882.074707 
	C1483.138184,881.847473 1483.640625,881.583801 1484.169312,881.701294 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1522.958984,882.258301 
	C1522.931274,882.516174 1522.656128,882.679749 1522.138428,882.891846 
	C1521.897339,882.789978 1521.886108,882.618225 1522.058716,882.265320 
	C1522.242554,882.084106 1522.724121,882.185181 1522.958984,882.258301 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1438.804443,885.781982 
	C1437.397095,885.354614 1436.376465,884.583435 1435.355835,883.812256 
	C1436.010132,883.042175 1436.664429,882.272034 1437.318604,881.501892 
	C1437.942871,882.813965 1438.567017,884.126038 1438.804443,885.781982 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1439.019531,877.893311 
	C1438.278320,878.358459 1437.502319,878.352051 1436.346680,878.431641 
	C1436.973022,878.152344 1437.978760,877.787048 1439.019531,877.893311 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1370.866943,868.197388 
	C1371.256470,869.245667 1371.573120,869.950439 1372.046753,871.004089 
	C1372.911499,870.036804 1373.391602,869.036377 1374.137695,868.763550 
	C1375.609985,868.225098 1377.229492,868.089783 1378.788330,867.787842 
	C1378.947021,869.062073 1379.675659,871.048706 1379.150879,871.468628 
	C1377.891113,872.476440 1376.045288,873.210510 1374.432007,873.218384 
	C1371.391479,873.233154 1368.347900,872.658386 1365.305664,872.328735 
	C1365.152100,871.826599 1364.998535,871.324463 1364.844971,870.822327 
	C1366.828003,869.832825 1368.811035,868.843323 1370.866943,868.197388 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1405.605469,872.707031 
	C1403.815918,868.069824 1406.853271,867.560364 1410.453857,867.242798 
	C1411.136108,867.995605 1411.455200,868.733704 1411.940674,869.734680 
	C1411.420288,870.742126 1410.733521,871.486694 1410.003418,872.591675 
	C1409.874390,873.108948 1409.788452,873.265686 1409.622070,873.678528 
	C1408.322632,873.558655 1407.103638,873.182678 1405.605469,872.707031 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1508.741699,892.254333 
	C1508.693604,891.783264 1508.583740,891.034058 1508.674316,890.274414 
	C1508.809692,890.834717 1508.744751,891.405457 1508.741699,892.254333 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1757.545898,685.650879 
	C1758.594482,685.468811 1759.643066,685.286804 1761.283936,685.532471 
	C1761.975464,688.663574 1762.074829,691.366821 1762.152832,694.459045 
	C1761.208252,697.300232 1760.682983,700.016907 1759.270142,702.143921 
	C1757.046997,705.490723 1757.407471,707.888000 1759.346558,711.491211 
	C1761.531372,715.551453 1761.835938,720.623474 1763.043579,725.607056 
	C1759.051025,726.005676 1756.448242,726.265503 1753.431885,726.468262 
	C1750.907104,726.577942 1748.774780,727.018005 1746.688721,726.857788 
	C1741.418457,726.452942 1736.168945,725.778137 1730.884521,724.799072 
	C1727.890991,723.928284 1724.923828,723.469849 1721.906250,722.639404 
	C1722.210571,721.166565 1722.565552,720.065796 1723.253418,718.957886 
	C1723.925659,718.533203 1724.265015,718.115662 1724.604370,717.698120 
	C1723.766968,717.456726 1722.929443,717.215332 1722.000000,716.514954 
	C1721.875488,710.169067 1721.842896,704.282227 1721.810425,698.395325 
	C1721.894165,695.609375 1721.685547,692.770203 1722.179443,690.058960 
	C1722.445068,688.601135 1723.691772,686.503113 1724.886475,686.191467 
	C1728.086304,685.356689 1728.077515,687.910950 1727.985474,690.583679 
	C1728.007324,691.007202 1728.011597,691.035461 1728.011719,691.035461 
	C1727.443726,692.958740 1725.915161,695.278870 1726.510986,696.721191 
	C1727.692017,699.579590 1730.817505,700.775391 1733.340698,698.579590 
	C1734.803833,697.306335 1735.494751,694.505310 1735.501221,692.388672 
	C1735.507080,690.481384 1734.106689,688.569763 1732.987915,685.831421 
	C1736.407715,686.094849 1738.964478,686.291809 1741.728027,687.194458 
	C1741.914917,691.275269 1741.895020,694.650391 1741.593994,698.317139 
	C1741.312744,704.626587 1741.312744,710.644470 1741.312744,717.022705 
	C1737.959839,717.022705 1735.009399,717.022705 1732.058960,717.022705 
	C1732.055664,717.293457 1732.052246,717.564209 1732.048950,717.834961 
	C1736.866943,718.208374 1741.685059,718.581787 1746.503052,718.955200 
	C1743.553345,715.531067 1742.093872,704.299805 1744.376221,698.969727 
	C1750.505981,703.340271 1757.647827,702.322815 1759.514893,694.831604 
	C1760.194580,692.104370 1758.277954,688.730042 1757.545898,685.650879 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1834.353149,687.781982 
	C1835.124756,684.451355 1837.786987,684.988342 1840.583008,685.332642 
	C1840.595215,686.787354 1839.817993,687.863281 1839.852905,688.912170 
	C1839.943481,691.632874 1839.904297,694.562805 1840.977539,696.927979 
	C1841.324097,697.691772 1846.343384,697.687439 1846.831421,696.766296 
	C1848.014771,694.532898 1847.987183,691.558716 1848.049438,688.873718 
	C1848.079224,687.589844 1847.204468,686.284973 1846.549072,684.459534 
	C1849.213501,684.238953 1851.140381,684.079407 1853.204224,684.331299 
	C1853.489868,692.439148 1853.638672,700.135559 1853.427246,707.868958 
	C1847.455200,711.248535 1847.435303,711.909180 1853.272095,716.761902 
	C1854.163452,714.999207 1854.991577,713.361816 1856.251221,711.776794 
	C1859.778687,712.149719 1862.871826,712.707581 1865.971313,712.746033 
	C1874.943115,712.857544 1878.145020,716.019226 1877.597534,724.879028 
	C1876.929321,724.817993 1876.550537,724.744324 1876.122803,724.279785 
	C1872.728149,720.779907 1866.619385,721.225159 1864.496094,724.807739 
	C1863.438232,724.936462 1862.724365,724.965149 1861.875977,724.706909 
	C1860.072632,724.632324 1858.405273,725.013855 1856.734863,725.027588 
	C1849.476318,725.087402 1842.216919,725.040039 1834.959229,724.596802 
	C1834.960693,722.359497 1834.960693,720.556946 1834.960693,719.616333 
	C1837.439453,717.672852 1839.253540,716.250488 1841.067627,714.828186 
	C1840.772583,714.371033 1840.477539,713.913879 1840.182495,713.456726 
	C1838.647339,713.962158 1837.112061,714.467590 1834.975708,715.170959 
	C1834.975708,712.489929 1835.102905,710.515076 1834.948608,708.562561 
	C1834.643066,704.692505 1834.182129,700.834656 1833.865479,696.585083 
	C1834.081543,693.392944 1834.217285,690.587463 1834.353149,687.781982 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1790.937622,684.951721 
	C1791.114380,684.967163 1791.291138,684.982544 1792.149658,685.022461 
	C1793.590088,686.368591 1794.656494,687.608093 1795.036499,689.030945 
	C1795.642456,691.298767 1795.791504,693.688599 1796.139404,696.426880 
	C1794.699341,702.502441 1790.864868,708.196533 1796.174927,714.206543 
	C1796.135010,715.119995 1796.197998,715.645142 1796.242432,716.501465 
	C1796.234375,717.495056 1796.244873,718.157410 1796.155396,719.250916 
	C1795.976074,721.689880 1795.896851,723.697693 1795.363403,725.754028 
	C1788.613037,725.901855 1782.316772,726.001221 1775.564941,726.029663 
	C1773.127197,725.954285 1771.130737,725.795959 1769.165405,725.976624 
	C1764.677856,726.389343 1763.035156,724.689697 1764.475708,720.356750 
	C1767.869385,719.931030 1771.102295,719.475952 1774.346924,719.128418 
	C1781.986572,718.310120 1787.215332,708.725342 1784.095703,701.925720 
	C1791.180420,701.193665 1793.208496,697.745117 1791.409546,691.329407 
	C1790.874146,689.419983 1787.357056,687.281616 1790.937622,684.951721 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1873.365479,683.048706 
	C1873.365479,683.048706 1873.473755,683.087219 1873.604980,683.390747 
	C1873.833740,684.121460 1873.931274,684.548584 1874.029053,684.975708 
	C1874.066040,690.423340 1874.103149,695.871033 1874.183594,702.173218 
	C1874.442993,705.328064 1874.659180,707.628418 1874.567017,710.032532 
	C1874.074463,710.250549 1873.890137,710.364746 1873.268188,710.379089 
	C1867.172974,710.254150 1861.515503,710.229004 1855.639160,709.992065 
	C1855.250366,709.196899 1855.080444,708.613403 1854.962891,707.561829 
	C1855.079712,699.778748 1855.144287,692.463623 1855.569702,685.174438 
	C1856.260620,685.529724 1856.799072,685.811096 1856.887939,686.196106 
	C1857.743896,689.907227 1863.221069,692.895447 1866.607666,691.378479 
	C1866.885620,691.253967 1866.881836,690.500244 1867.010742,690.025208 
	C1867.012329,690.011597 1867.039917,690.007568 1867.403809,690.092896 
	C1871.166504,691.319763 1873.325439,690.760681 1873.027954,686.512024 
	C1872.948120,685.371033 1873.242676,684.203857 1873.365479,683.048706 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1796.255371,718.819824 
	C1796.244873,718.157410 1796.234375,717.495056 1796.534668,716.438965 
	C1798.563477,716.363647 1800.281738,716.681946 1802.457642,717.031128 
	C1804.402100,716.991028 1805.888184,716.901794 1807.375732,716.852051 
	C1815.919067,716.566589 1816.479614,716.137817 1819.750244,706.576111 
	C1813.244873,707.067505 1807.119019,707.530273 1800.605103,708.031128 
	C1799.762817,709.181458 1799.470337,710.404114 1798.808105,711.374634 
	C1798.161011,712.323120 1797.136597,713.014221 1796.277832,713.818359 
	C1790.864868,708.196533 1794.699341,702.502441 1796.334839,696.449463 
	C1796.524780,696.071838 1796.917603,696.107788 1796.917603,696.107788 
	C1799.494629,694.997070 1802.071533,693.886292 1805.281982,692.422668 
	C1808.989380,692.315674 1812.062988,692.561523 1815.280884,693.076965 
	C1815.969238,693.899597 1816.513306,694.452637 1817.027344,695.004517 
	C1816.997192,695.003418 1817.013428,694.945312 1816.992798,695.261597 
	C1817.330444,696.060913 1817.688721,696.543945 1818.012695,697.023438 
	C1817.978516,697.019897 1817.976440,696.951172 1818.124878,697.272156 
	C1818.700439,697.728394 1819.127441,697.863708 1819.687988,697.997437 
	C1820.010742,698.093079 1820.076782,698.244141 1819.853027,698.856201 
	C1824.189453,705.038879 1821.016724,710.367004 1818.054321,715.395386 
	C1821.415649,715.977600 1824.077515,716.438660 1826.301758,716.918457 
	C1818.325684,717.668091 1810.779053,718.331665 1803.262573,719.242615 
	C1802.572388,719.326233 1802.073730,720.989441 1801.187988,721.790894 
	C1799.344971,720.715027 1797.800171,719.767395 1796.255371,718.819824 
M1804.962769,696.130432 
	C1803.613525,696.242249 1802.264282,696.354004 1800.915039,696.465820 
	C1801.929321,699.006592 1797.051147,702.530396 1802.239624,704.357056 
	C1802.287598,704.373901 1802.433228,704.113159 1803.156860,703.935669 
	C1803.781494,703.961365 1804.406250,703.987000 1805.436279,704.774475 
	C1809.933960,704.774475 1814.431641,704.774475 1818.929443,704.774475 
	C1819.141602,704.422791 1819.353882,704.071106 1819.566040,703.719421 
	C1816.854248,702.498840 1819.172485,696.516724 1812.874756,697.630615 
	C1810.197266,697.389404 1807.519775,697.148132 1804.962769,696.130432 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1686.992554,689.986023 
	C1687.523682,688.681091 1688.054932,687.376160 1688.526733,686.216797 
	C1688.872314,687.289246 1689.269165,688.520996 1689.755737,690.031555 
	C1691.062988,688.107117 1692.070190,686.624512 1693.277344,685.383667 
	C1692.965210,686.397827 1692.453003,687.170227 1691.938232,688.360840 
	C1693.301025,692.510315 1694.666626,696.241577 1696.136353,700.331177 
	C1696.611938,701.852844 1696.983398,703.016235 1697.201416,704.487061 
	C1696.010376,706.527222 1694.972778,708.260010 1693.673340,710.012207 
	C1693.156250,710.137451 1692.901123,710.243286 1692.646118,710.349121 
	C1693.026367,710.634033 1693.406738,710.918884 1693.834839,711.408936 
	C1693.882690,711.614014 1694.014771,712.013977 1693.956421,712.249878 
	C1693.898071,712.485840 1693.853027,712.970032 1693.495972,712.953552 
	C1692.304932,714.183533 1691.519531,715.466858 1690.614746,716.659607 
	C1690.213623,717.188293 1689.570923,717.533813 1688.979492,717.708252 
	C1688.877930,717.285645 1688.834717,717.117310 1688.688965,716.552246 
	C1688.307617,711.989807 1688.028564,707.824036 1687.730713,703.376099 
	C1685.631836,703.376099 1683.718140,703.277588 1681.818726,703.400085 
	C1679.222656,703.567383 1678.351074,704.918396 1679.480469,707.429932 
	C1679.510132,707.491272 1679.550293,707.625305 1679.425293,707.942017 
	C1679.277344,709.151978 1679.254272,710.045105 1679.143311,711.288208 
	C1679.104980,712.112549 1679.154785,712.587036 1679.118530,713.370117 
	C1678.981567,714.102478 1678.930542,714.526367 1678.683838,714.761414 
	C1678.333374,714.050964 1678.178711,713.529358 1678.057495,712.548584 
	C1678.090820,709.468994 1678.090820,706.848511 1678.090820,703.983643 
	C1675.530396,703.983643 1673.511963,703.983643 1670.402222,703.983643 
	C1672.576538,707.492493 1674.283691,710.247681 1675.983887,713.347229 
	C1675.839478,713.976746 1675.702148,714.261902 1675.564819,714.547119 
	C1674.954102,714.042847 1674.307251,713.576050 1673.738770,713.028137 
	C1670.543823,709.949036 1670.877563,703.157593 1663.925903,703.551392 
	C1663.632080,703.047424 1663.496948,702.856384 1663.793945,702.798462 
	C1665.515625,702.182556 1666.805054,701.433655 1668.392212,700.566895 
	C1668.973755,699.640137 1669.257568,698.831116 1669.839355,698.077271 
	C1670.538574,698.176880 1670.939819,698.221436 1671.327637,698.622009 
	C1671.113525,699.901733 1670.912720,700.825439 1670.716431,701.728149 
	C1673.032715,701.728149 1675.253784,701.728149 1676.798950,701.728149 
	C1677.368774,698.560730 1677.866333,695.794434 1678.532593,692.938354 
	C1678.956787,692.570068 1679.212280,692.291565 1679.516357,692.391235 
	C1679.708862,693.515442 1679.852661,694.261597 1679.988770,695.349121 
	C1679.997681,696.145752 1680.014282,696.601013 1679.987305,697.443176 
	C1679.619873,698.996948 1679.296143,700.163818 1678.888794,701.631958 
	C1682.230957,701.631958 1685.011230,701.631958 1688.283447,701.631958 
	C1687.528076,700.128235 1687.041138,699.158691 1686.405762,697.893799 
	C1684.345215,699.253479 1681.855347,701.925232 1681.405762,697.007690 
	C1681.560669,697.039978 1681.876831,697.057434 1682.182251,696.957458 
	C1683.629883,696.159485 1684.772217,695.461487 1685.914429,694.763489 
	C1686.292358,695.055908 1686.670166,695.348267 1687.048096,695.640686 
	C1687.048096,694.046753 1687.048096,692.452881 1687.041992,690.640259 
	C1687.035767,690.421509 1686.992554,689.986023 1686.992554,689.986023 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1882.958252,686.341064 
	C1883.881226,686.606445 1885.505005,687.281250 1885.624268,687.076965 
	C1888.326172,682.454407 1892.950562,683.750671 1896.918335,682.831299 
	C1898.113647,684.337769 1896.564819,689.493652 1901.286499,685.464172 
	C1902.120850,685.090759 1902.542480,685.079895 1903.080200,685.261597 
	C1903.384521,685.690857 1903.604736,685.892517 1903.852051,686.524048 
	C1903.847168,693.101746 1903.847168,699.214783 1903.847168,706.622925 
	C1902.363892,704.869995 1901.833862,704.243591 1901.204834,703.301208 
	C1898.836792,699.910706 1896.567871,696.836182 1894.230713,693.411926 
	C1894.036743,692.352051 1893.910645,691.641907 1893.784180,690.595215 
	C1893.826782,689.813232 1893.869629,689.367859 1894.217407,688.904419 
	C1894.850342,688.343567 1895.200195,687.811157 1895.470337,687.240967 
	C1895.495117,687.188416 1895.012817,686.895508 1894.765869,686.714233 
	C1894.503296,687.195496 1894.240723,687.676697 1893.612183,688.087524 
	C1892.503296,688.055359 1891.760498,688.093567 1890.819092,687.847351 
	C1889.541748,687.487549 1888.463135,687.412109 1886.697876,687.288635 
	C1887.874268,689.141113 1888.482300,690.098633 1888.938232,691.356934 
	C1888.464478,692.715759 1888.142700,693.773865 1887.612183,694.956543 
	C1887.142700,695.295288 1886.971802,695.566223 1886.591064,696.045654 
	C1885.743652,697.371826 1885.195801,698.546265 1884.340088,699.834534 
	C1882.977295,701.585327 1881.922363,703.222229 1880.657593,704.956909 
	C1879.968262,705.035095 1879.488892,705.015503 1879.020874,704.527893 
	C1879.032349,697.974609 1879.032349,691.889282 1879.032349,685.465881 
	C1880.715088,685.841003 1881.836670,686.091003 1882.958252,686.341064 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1902.964233,685.068970 
	C1902.542480,685.079895 1902.120850,685.090759 1901.384521,685.133057 
	C1901.882080,684.280701 1902.694336,683.396912 1904.331787,681.615417 
	C1905.059814,693.999390 1905.684326,704.622559 1906.802490,715.707214 
	C1906.204834,717.442200 1905.113525,718.715759 1903.991211,719.614502 
	C1903.270752,716.033203 1905.275269,710.992310 1900.363281,710.412720 
	C1893.682617,709.624451 1886.839111,710.216309 1879.591431,710.216309 
	C1881.329468,716.249146 1885.703247,714.706299 1889.171265,715.196838 
	C1887.746948,716.080933 1886.543335,716.827942 1885.212158,717.734619 
	C1885.084595,717.894226 1884.822021,718.207397 1884.467041,718.104553 
	C1882.662720,717.603577 1881.213257,717.205444 1878.797607,716.541931 
	C1880.737305,719.803650 1882.056885,722.022705 1883.116943,724.479370 
	C1881.200439,724.766724 1879.543701,724.816528 1877.886963,724.866333 
	C1878.145020,716.019226 1874.943115,712.857544 1865.971313,712.746033 
	C1862.871826,712.707581 1859.778687,712.149719 1856.242432,711.523193 
	C1855.820801,710.879517 1855.839478,710.541626 1855.858032,710.203796 
	C1861.515503,710.229004 1867.172974,710.254150 1873.367920,710.588135 
	C1874.157837,711.045837 1874.410400,711.194641 1874.663086,711.343384 
	C1874.733765,710.871826 1874.804443,710.400330 1874.875244,709.928772 
	C1874.659180,707.628418 1874.442993,705.328064 1874.272705,702.650513 
	C1875.152588,704.980225 1875.986938,707.687195 1876.821167,710.394165 
	C1877.061523,710.452148 1877.301880,710.510193 1877.542358,710.568176 
	C1877.669556,708.974670 1877.796875,707.381104 1878.197754,705.380127 
	C1878.650879,704.980408 1878.830200,704.988159 1879.009399,704.995850 
	C1879.488892,705.015503 1879.968262,705.035095 1880.776733,705.077332 
	C1881.105713,705.099915 1881.345337,705.339294 1881.256470,705.718628 
	C1881.593384,707.011841 1881.997925,708.709045 1882.448364,708.721375 
	C1888.802368,708.894897 1895.162598,708.839355 1901.879639,708.839355 
	C1901.240356,706.324707 1900.954956,705.202209 1900.842529,703.983582 
	C1901.015381,703.887512 1901.304077,703.616943 1901.303955,703.617065 
	C1901.833862,704.243591 1902.363892,704.869995 1903.847168,706.622925 
	C1903.847168,699.214783 1903.847168,693.101746 1903.821289,686.272217 
	C1903.518433,685.393433 1903.241333,685.231201 1902.964233,685.068970 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1741.875122,698.025574 
	C1741.895020,694.650391 1741.914917,691.275269 1741.993164,687.498779 
	C1746.947632,685.644653 1751.542358,681.516296 1757.293335,685.529663 
	C1758.277954,688.730042 1760.194580,692.104370 1759.514893,694.831604 
	C1757.647827,702.322815 1750.505981,703.340271 1744.178711,698.733032 
	C1743.088379,698.197144 1742.481689,698.111389 1741.875122,698.025574 
M1748.947388,695.120605 
	C1751.478149,694.959045 1754.008911,694.797485 1756.539673,694.635925 
	C1755.636719,692.167786 1758.236328,688.320557 1752.823853,687.236145 
	C1751.055054,687.236145 1749.286133,687.236145 1746.144775,687.236145 
	C1747.227173,690.155518 1748.015625,692.282104 1748.947388,695.120605 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1721.586426,698.689941 
	C1721.842896,704.282227 1721.875488,710.169067 1722.066650,716.779175 
	C1722.457031,717.989929 1722.688843,718.477417 1722.920654,718.964966 
	C1722.565552,720.065796 1722.210571,721.166565 1721.813232,723.056458 
	C1722.764526,727.542114 1726.710205,728.131836 1730.911133,725.211487 
	C1736.168945,725.778137 1741.418457,726.452942 1746.688721,726.857788 
	C1748.774780,727.018005 1750.907104,726.577942 1753.461670,726.711182 
	C1753.979004,727.333618 1754.009521,727.659973 1753.996460,727.990417 
	C1748.360107,728.075745 1742.723755,728.161072 1736.632568,728.248535 
	C1731.349243,728.478638 1726.520874,728.706665 1720.956299,729.094360 
	C1718.171997,729.211182 1716.124023,729.168457 1713.701660,729.083862 
	C1712.553589,729.715576 1711.779663,730.389038 1710.637207,730.967102 
	C1708.847534,730.637390 1707.426514,730.403015 1706.005615,730.168701 
	C1701.959229,728.256531 1701.236938,728.401794 1698.940674,731.803040 
	C1698.400635,731.091492 1697.919067,730.456848 1697.406982,729.782104 
	C1697.117310,730.799194 1696.855347,731.718323 1696.307861,732.800720 
	C1695.412231,731.266113 1696.258179,728.366333 1692.389526,728.686401 
	C1692.001587,727.887085 1692.003662,727.438538 1692.385742,726.984009 
	C1694.067139,726.067871 1695.368652,725.157593 1696.670166,724.247253 
	C1696.492432,723.941895 1696.314697,723.636536 1696.136963,723.331177 
	C1697.923584,723.198914 1699.710205,723.066650 1701.693970,722.919800 
	C1701.222168,724.326965 1700.850464,725.434937 1700.247437,727.233154 
	C1707.415161,726.692749 1713.965942,726.198853 1720.784058,725.684753 
	C1721.125122,723.109619 1722.855835,719.948608 1717.946777,719.790405 
	C1717.894043,718.723267 1717.674805,717.937744 1717.635254,717.226562 
	C1718.068481,717.338684 1718.323242,717.365540 1718.579346,717.381409 
	C1721.412964,718.958069 1721.105103,716.804993 1721.134766,715.183350 
	C1721.233643,709.784180 1721.289917,704.384216 1721.586426,698.689941 
M1697.365601,725.386841 
	C1697.353638,725.737366 1697.341797,726.087830 1697.329834,726.438293 
	C1697.434082,726.196655 1697.538330,725.954956 1697.365601,725.386841 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1820.019409,698.449097 
	C1820.076782,698.244141 1820.010742,698.093079 1819.524048,697.681030 
	C1818.809937,697.227966 1818.393188,697.089600 1817.976440,696.951172 
	C1817.976440,696.951172 1817.978516,697.019897 1818.133545,696.691528 
	C1817.863525,695.890503 1817.438477,695.417908 1817.013428,694.945312 
	C1817.013428,694.945312 1816.997192,695.003418 1817.217285,694.628540 
	C1818.314087,691.188293 1819.190918,688.122925 1820.346924,684.960327 
	C1821.184570,684.314087 1821.743286,683.765076 1822.302002,683.216003 
	C1824.315186,683.190125 1826.328247,683.164307 1828.690186,683.688110 
	C1830.787476,688.020142 1831.105713,692.066589 1830.126953,697.576660 
	C1830.011963,698.224915 1830.053711,698.901001 1829.804932,699.824158 
	C1826.397949,699.539124 1823.208740,698.994141 1820.019409,698.449097 
M1822.101196,691.749939 
	C1822.607178,692.228943 1823.113159,692.707947 1823.619263,693.186890 
	C1825.177490,691.388000 1826.735718,689.589172 1828.719727,687.298645 
	C1824.528687,686.360840 1821.625244,685.936829 1822.101196,691.749939 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1779.072144,685.042480 
	C1779.483521,685.051025 1779.894897,685.059509 1780.895020,685.068604 
	C1781.669800,685.057312 1781.855591,685.045349 1782.041504,685.033447 
	C1782.426392,685.051819 1782.811157,685.070129 1783.510986,685.465637 
	C1782.565308,689.202393 1781.304688,692.561890 1779.869995,695.645996 
	C1779.150757,695.236328 1778.605591,695.102112 1778.045288,694.962280 
	C1778.030151,694.956787 1778.008545,694.933228 1778.060059,694.513916 
	C1773.788818,692.804199 1769.468262,691.543579 1765.462646,694.959290 
	C1765.451660,694.945068 1765.476318,694.914490 1765.453857,694.592102 
	C1765.064697,694.061340 1764.697876,693.852905 1764.263306,693.230957 
	C1764.183228,690.284180 1764.170898,687.751099 1764.608765,685.169678 
	C1769.729980,685.095032 1774.401001,685.068726 1779.072144,685.042480 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1697.354858,704.179626 
	C1696.983398,703.016235 1696.611938,701.852844 1696.485596,700.327393 
	C1698.593750,697.621033 1700.457031,695.276794 1703.112305,691.936096 
	C1698.744629,690.374817 1695.342651,689.158691 1691.940674,687.942627 
	C1692.453003,687.170227 1692.965210,686.397827 1693.641846,685.374878 
	C1694.721802,685.324097 1695.637207,685.523865 1696.975952,686.153137 
	C1701.923096,690.652832 1706.967163,689.356018 1711.981812,688.352417 
	C1710.260010,689.841980 1708.573364,690.978577 1706.536255,692.351379 
	C1709.835938,697.387634 1712.951172,702.142151 1716.066406,706.896729 
	C1716.247314,707.192139 1716.428223,707.487610 1716.797241,708.288574 
	C1716.240479,709.461609 1715.495728,710.129150 1714.819580,710.459167 
	C1711.813965,704.903687 1708.739502,699.685852 1705.182007,693.648132 
	C1701.959351,697.984253 1699.657104,701.081909 1697.354858,704.179626 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1716.364746,706.636169 
	C1712.951172,702.142151 1709.835938,697.387634 1706.536255,692.351379 
	C1708.573364,690.978577 1710.260010,689.841980 1711.970825,688.351929 
	C1711.994995,687.998413 1711.994507,688.020996 1712.382080,688.038391 
	C1714.693604,688.223694 1718.006348,684.791138 1717.963135,690.059021 
	C1717.963135,690.059021 1717.949951,690.492615 1717.914795,691.143188 
	C1717.974243,693.508850 1718.068848,695.223938 1718.163452,696.938965 
	C1717.663330,700.084534 1717.163330,703.230042 1716.364746,706.636169 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1780.044067,695.921448 
	C1781.304688,692.561890 1782.565308,689.202393 1783.799316,685.484314 
	C1786.023804,684.895874 1788.275024,684.665894 1790.731934,684.693848 
	C1787.357056,687.281616 1790.874146,689.419983 1791.409546,691.329407 
	C1793.208496,697.745117 1791.180420,701.193665 1783.870972,701.813354 
	C1782.800415,701.501953 1782.365967,701.272522 1781.943604,701.030029 
	C1781.955688,701.016846 1781.940063,700.988586 1781.884033,700.746948 
	C1781.644653,700.183472 1781.367798,700.031128 1780.980469,700.038879 
	C1780.963135,700.029480 1780.953735,699.991333 1780.977539,699.689209 
	C1780.665039,698.942444 1780.328857,698.497742 1779.972900,698.042542 
	C1779.953125,698.032043 1779.946899,697.987732 1779.989746,697.645386 
	C1780.036377,696.842468 1780.040283,696.381958 1780.044067,695.921448 
M1787.022339,690.416138 
	C1781.645020,690.972961 1784.331055,694.943542 1784.233154,697.560608 
	C1784.217163,697.988220 1786.876831,699.239563 1787.669189,698.844666 
	C1790.641968,697.363403 1790.493652,694.767273 1789.070068,692.155334 
	C1788.774048,691.612183 1788.176025,691.233521 1787.022339,690.416138 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1671.341064,698.265991 
	C1670.939819,698.221436 1670.538574,698.176880 1669.554688,697.872681 
	C1668.744141,697.560303 1668.516479,697.507568 1668.294678,697.241089 
	C1668.211792,696.751282 1668.195435,696.471008 1668.589600,696.127136 
	C1669.678589,695.179016 1670.320679,694.158386 1671.203979,693.430359 
	C1672.820190,692.097961 1674.749878,691.114929 1676.242798,689.670044 
	C1679.335571,686.676941 1675.028198,686.292297 1674.328247,684.609436 
	C1673.831055,683.414001 1673.415649,682.084656 1673.396240,680.808533 
	C1673.331055,676.532166 1673.459961,672.252747 1673.514526,667.974548 
	C1674.216919,668.032288 1674.919189,668.090027 1675.791748,668.489624 
	C1676.455566,672.133057 1676.948975,675.434753 1677.442505,678.736450 
	C1676.950928,679.887756 1676.459351,681.039062 1675.942749,682.565796 
	C1677.072266,683.961609 1678.226929,684.981934 1679.281372,685.913696 
	C1678.410034,691.871277 1683.457031,689.287109 1686.561035,689.986694 
	C1686.992554,689.986023 1687.035767,690.421509 1686.608154,690.699463 
	C1683.942871,691.322632 1681.705322,691.667786 1679.467773,692.013062 
	C1679.212280,692.291565 1678.956787,692.570068 1678.282104,692.691772 
	C1676.002075,693.557190 1674.141357,694.579407 1672.006836,695.846802 
	C1671.516602,696.761047 1671.299927,697.430054 1671.167480,698.110535 
	C1671.251465,698.121948 1671.341064,698.265991 1671.341064,698.265991 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1872.863403,683.006165 
	C1873.242676,684.203857 1872.948120,685.371033 1873.027954,686.512024 
	C1873.325439,690.760681 1871.166504,691.319763 1867.365112,689.711182 
	C1868.124634,688.031982 1869.286865,686.819763 1870.536011,685.517029 
	C1862.427856,684.163086 1860.299683,684.613281 1859.441406,687.149658 
	C1861.023315,687.742310 1862.502930,688.290100 1863.977417,688.851440 
	C1864.991577,689.237610 1865.998657,689.642395 1867.009033,690.038818 
	C1866.881836,690.500244 1866.885620,691.253967 1866.607666,691.378479 
	C1863.221069,692.895447 1857.743896,689.907227 1856.887939,686.196106 
	C1856.799072,685.811096 1856.260620,685.529724 1855.492188,684.851196 
	C1854.966187,684.070984 1854.878540,683.639832 1854.790894,683.208618 
	C1860.647705,683.126953 1866.504517,683.045288 1872.863403,683.006165 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1853.067383,683.919922 
	C1851.140381,684.079407 1849.213501,684.238953 1846.549072,684.459534 
	C1847.204468,686.284973 1848.079224,687.589844 1848.049438,688.873718 
	C1847.987183,691.558716 1848.014771,694.532898 1846.831421,696.766296 
	C1846.343384,697.687439 1841.324097,697.691772 1840.977539,696.927979 
	C1839.904297,694.562805 1839.943481,691.632874 1839.852905,688.912170 
	C1839.817993,687.863281 1840.595215,686.787354 1841.041016,685.325317 
	C1844.804810,684.305847 1848.529785,683.684753 1852.678833,683.301758 
	C1853.102905,683.539978 1853.067383,683.919922 1853.067383,683.919922 
M1846.809448,691.767334 
	C1845.105957,691.300781 1843.415894,690.638062 1841.688599,690.520081 
	C1841.307007,690.494019 1840.146973,692.791016 1840.475220,693.328003 
	C1841.165283,694.457458 1842.870117,696.023926 1843.675659,695.784790 
	C1844.973877,695.399475 1845.882080,693.699707 1846.809448,691.767334 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1904.022217,719.989258 
	C1905.113525,718.715759 1906.204834,717.442200 1907.078003,715.970947 
	C1906.903320,715.371460 1906.946777,714.969666 1907.275269,714.107422 
	C1907.729004,713.067566 1907.897705,712.488159 1908.467163,711.950684 
	C1910.685059,711.992615 1912.502319,711.992615 1913.721924,711.992615 
	C1912.285034,714.306458 1910.757812,715.885620 1910.301514,717.729736 
	C1909.765381,719.897339 1910.158813,722.294983 1909.700439,724.614868 
	C1907.460327,724.658936 1905.669189,724.680969 1903.900391,724.412048 
	C1903.976440,723.735535 1904.030151,723.350037 1904.419067,722.982544 
	C1905.493042,722.872437 1906.231567,722.744324 1906.970215,722.616211 
	C1906.948853,722.422974 1906.927490,722.229797 1906.906128,722.036560 
	C1905.966431,722.036560 1905.026611,722.036560 1904.054932,721.696289 
	C1904.022705,720.900513 1904.022461,720.444885 1904.022217,719.989258 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1714.750977,710.796753 
	C1715.495728,710.129150 1716.240479,709.461609 1717.017212,708.434082 
	C1717.049194,708.074158 1717.545532,708.096924 1718.007812,708.061279 
	C1718.469971,708.025696 1718.900391,708.069214 1718.900391,708.069214 
	C1718.874512,710.858582 1718.848633,713.647888 1718.701050,716.909302 
	C1718.323242,717.365540 1718.068481,717.338684 1717.527588,716.854858 
	C1717.343384,715.388672 1717.446289,714.368591 1717.603760,712.808838 
	C1713.450928,713.205750 1709.770386,713.557556 1706.004395,713.603149 
	C1704.951050,713.246704 1703.983521,713.196411 1702.600220,713.069031 
	C1700.779297,713.055908 1699.374023,713.119812 1697.757935,712.894897 
	C1696.369629,712.408691 1695.192261,712.211304 1694.014771,712.013916 
	C1694.014771,712.013977 1693.882690,711.614014 1694.156006,711.339233 
	C1694.643799,711.024719 1694.858276,710.984924 1695.535645,710.912903 
	C1702.249390,710.852722 1708.500122,710.824707 1714.750977,710.796753 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1815.136719,692.807312 
	C1812.062988,692.561523 1808.989380,692.315674 1805.554688,692.162781 
	C1805.992188,690.128296 1806.527466,687.841980 1807.682373,685.929810 
	C1808.439575,684.676147 1810.092529,683.963379 1811.746826,683.385010 
	C1813.145264,686.776917 1814.140991,689.792114 1815.136719,692.807312 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1879.020874,704.527832 
	C1878.830200,704.988159 1878.650879,704.980408 1878.201660,704.960999 
	C1877.643188,698.420898 1877.240356,691.893494 1877.180542,685.362915 
	C1877.170898,684.297668 1878.677002,683.218567 1879.482544,682.145813 
	C1880.639526,683.271851 1881.796387,684.397949 1882.955811,685.932495 
	C1881.836670,686.091003 1880.715088,685.841003 1879.032349,685.465881 
	C1879.032349,691.889282 1879.032349,697.974609 1879.020874,704.527832 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1815.280884,693.076965 
	C1814.140991,689.792114 1813.145264,686.776917 1812.151489,683.387390 
	C1812.538818,683.018738 1812.924072,683.024414 1813.703613,683.230652 
	C1814.445557,683.290100 1814.793335,683.149048 1815.141113,683.007996 
	C1815.514526,683.018005 1815.888062,683.027954 1816.731201,683.306152 
	C1818.156494,684.068848 1819.112183,684.563232 1820.067871,685.057617 
	C1819.190918,688.122925 1818.314087,691.188293 1817.247314,694.629639 
	C1816.513306,694.452637 1815.969238,693.899597 1815.280884,693.076965 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1712.005981,687.998901 
	C1706.967163,689.356018 1701.923096,690.652832 1697.217041,686.291260 
	C1697.034790,686.000000 1697.522217,686.005371 1698.162720,686.005859 
	C1699.860962,685.999084 1700.918701,685.991882 1701.976440,685.984741 
	C1704.086304,685.962280 1706.196045,685.939758 1708.995972,685.960815 
	C1710.117920,685.997009 1710.549927,685.989563 1710.981812,685.982117 
	C1711.403198,686.000854 1711.824585,686.019531 1712.482910,686.306458 
	C1712.477905,687.056763 1712.236206,687.538879 1711.994507,688.020996 
	C1711.994507,688.020996 1711.994995,687.998413 1712.005981,687.998901 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1830.022705,699.564087 
	C1830.053711,698.901001 1830.011963,698.224915 1830.126953,697.576660 
	C1831.105713,692.066589 1830.787476,688.020142 1829.035278,683.829224 
	C1830.639282,684.703125 1832.247070,685.985657 1834.104004,687.525024 
	C1834.217285,690.587463 1834.081543,693.392944 1833.634521,696.656616 
	C1832.997314,697.310913 1832.888428,697.591370 1832.657959,698.008911 
	C1831.553711,698.562561 1830.788208,699.063293 1830.022705,699.564087 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1853.204224,684.331299 
	C1853.067383,683.919922 1853.102905,683.539978 1853.141113,683.354248 
	C1853.537964,683.175842 1853.896729,683.183167 1854.523193,683.199585 
	C1854.878540,683.639832 1854.966187,684.070984 1855.131348,684.825317 
	C1855.144287,692.463623 1855.079712,699.778748 1854.681152,707.517639 
	C1854.160645,707.904968 1853.974121,707.868469 1853.787476,707.831970 
	C1853.638672,700.135559 1853.489868,692.439148 1853.204224,684.331299 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1728.021362,691.009399 
	C1729.161987,691.106506 1730.629028,690.796021 1731.336548,691.390137 
	C1732.435913,692.313354 1733.444580,693.790833 1733.640747,695.155640 
	C1733.739502,695.842712 1731.965698,696.799011 1731.038574,697.633545 
	C1730.042847,696.511780 1728.906372,695.479248 1728.113647,694.228760 
	C1727.744995,693.647278 1728.043945,692.642578 1728.025879,691.432617 
	C1728.011597,691.035461 1728.007324,691.007202 1728.021362,691.009399 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1796.953613,695.633728 
	C1796.917603,696.107788 1796.524780,696.071838 1796.329346,696.049316 
	C1795.791504,693.688599 1795.642456,691.298767 1795.036499,689.030945 
	C1794.656494,687.608093 1793.590088,686.368591 1792.415771,685.022339 
	C1793.110474,684.379272 1794.220825,683.760742 1795.331299,683.142212 
	C1795.895874,684.173096 1796.869751,685.174377 1796.946777,686.240479 
	C1797.160767,689.199097 1797.001343,692.184692 1796.953613,695.633728 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1712.382080,688.038391 
	C1712.236206,687.538879 1712.477905,687.056763 1712.799072,686.317139 
	C1714.572754,685.592407 1716.267090,685.125122 1717.961548,684.657837 
	C1718.154297,686.296082 1718.347046,687.934204 1718.251465,689.815674 
	C1718.006348,684.791138 1714.693604,688.223694 1712.382080,688.038391 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1764.158691,685.217957 
	C1764.170898,687.751099 1764.183228,690.284180 1763.906616,693.322754 
	C1763.136597,693.908813 1762.655518,693.989502 1762.174316,694.070129 
	C1762.074829,691.366821 1761.975464,688.663574 1761.817383,685.515625 
	C1762.274292,684.737244 1762.789917,684.403503 1763.673340,684.360596 
	C1764.080444,684.840271 1764.119629,685.029114 1764.158691,685.217957 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1764.608765,685.169678 
	C1764.119629,685.029114 1764.080444,684.840271 1763.994629,684.366577 
	C1768.795898,684.193970 1773.643677,684.306213 1778.781738,684.730469 
	C1774.401001,685.068726 1769.729980,685.095032 1764.608765,685.169678 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1908.066528,711.908752 
	C1907.897705,712.488159 1907.729004,713.067566 1907.297607,713.803345 
	C1907.023926,709.957397 1907.012939,705.955078 1907.025391,701.493530 
	C1907.048706,701.034363 1907.488647,701.007507 1907.748047,701.472168 
	C1908.027100,705.260864 1908.046875,708.584778 1908.066528,711.908752 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1820.346924,684.960327 
	C1819.112183,684.563232 1818.156494,684.068848 1817.011108,683.293701 
	C1818.345459,683.039124 1819.869385,683.065247 1821.847656,683.153687 
	C1821.743286,683.765076 1821.184570,684.314087 1820.346924,684.960327 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1718.396484,696.669678 
	C1718.068848,695.223938 1717.974243,693.508850 1717.916748,691.360046 
	C1718.179077,692.750977 1718.404297,694.575684 1718.396484,696.669678 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1874.342041,684.941406 
	C1873.931274,684.548584 1873.833740,684.121460 1873.659424,683.372620 
	C1874.232422,683.349731 1874.881958,683.648499 1875.531616,683.947266 
	C1875.239502,684.267212 1874.947266,684.587158 1874.342041,684.941406 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1701.777344,685.716187 
	C1700.918701,685.991882 1699.860962,685.999084 1698.406494,685.999878 
	C1699.199219,685.811523 1700.388672,685.629578 1701.777344,685.716187 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1710.975098,685.717529 
	C1710.549927,685.989563 1710.117920,685.997009 1709.363037,685.986389 
	C1709.682739,685.796570 1710.325684,685.624756 1710.975098,685.717529 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1718.854004,707.878845 
	C1718.900391,708.069214 1718.469971,708.025696 1718.254028,708.029419 
	C1718.294556,707.918213 1718.551147,707.803345 1718.854004,707.878845 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1814.828369,683.003540 
	C1814.793335,683.149048 1814.445557,683.290100 1813.993896,683.217773 
	C1814.098633,683.002686 1814.307129,683.000854 1814.828369,683.003540 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1781.942993,684.841919 
	C1781.855591,685.045349 1781.669800,685.057312 1781.203613,685.062805 
	C1781.230469,684.921082 1781.537476,684.785767 1781.942993,684.841919 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1849.233032,630.108276 
	C1851.984497,628.345825 1854.628296,626.370544 1857.529297,624.903809 
	C1859.171143,624.073547 1861.235229,624.078003 1863.796753,623.570129 
	C1864.084351,629.344360 1864.338867,634.452759 1864.609375,639.883545 
	C1861.518555,640.113831 1858.747925,640.158875 1856.029419,640.558899 
	C1851.400757,641.239807 1849.936157,637.174316 1847.432739,634.396606 
	C1848.173462,632.580383 1848.703247,631.344299 1849.233032,630.108276 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1805.290649,643.045654 
	C1806.168945,641.675110 1807.475952,640.350159 1809.150879,638.652039 
	C1807.671875,638.363281 1806.436035,638.122009 1805.187012,637.878113 
	C1805.225342,637.463135 1805.248779,636.882690 1805.283325,636.881958 
	C1807.494751,636.839111 1809.707153,636.843323 1812.135864,636.582214 
	C1814.864502,636.865784 1817.594604,637.368042 1820.321045,637.348633 
	C1821.886597,637.337463 1823.446167,636.480286 1825.250000,635.930298 
	C1825.542358,636.701965 1825.920410,637.700317 1826.231567,638.521729 
	C1828.292114,637.475037 1830.243652,636.483704 1832.195312,635.492310 
	C1832.416870,635.886902 1832.638306,636.281494 1832.859863,636.676086 
	C1831.535400,637.701599 1830.211060,638.727173 1828.886597,639.752747 
	C1828.976440,640.097168 1829.066162,640.441650 1829.156006,640.786072 
	C1830.228149,640.646179 1831.300293,640.506287 1832.687988,640.186157 
	C1833.294312,640.111755 1833.531494,640.289001 1833.680176,640.984741 
	C1833.091064,641.950317 1832.537231,642.468811 1831.983398,642.987183 
	C1831.196167,643.031616 1830.408813,643.076050 1828.964600,642.981689 
	C1824.536255,641.732849 1820.739136,639.289551 1817.061646,643.059570 
	C1815.965576,643.060974 1814.869629,643.062439 1813.012451,642.889282 
	C1811.782227,642.774109 1811.313110,642.833435 1810.843994,642.892700 
	C1809.135742,642.958801 1807.427490,643.024963 1805.290649,643.045654 
M1811.225830,640.598083 
	C1810.899536,640.644165 1810.571655,640.682434 1810.251343,640.755005 
	C1810.231445,640.759583 1810.254639,641.158325 1810.288330,641.163147 
	C1810.616089,641.210571 1810.950439,641.213074 1811.225830,640.598083 
M1820.511963,639.281860 
	C1820.511963,639.281860 1820.427856,639.560181 1820.427856,639.560181 
	C1820.427856,639.560181 1820.567383,639.483826 1820.511963,639.281860 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1830.068115,619.381409 
	C1830.228882,618.675964 1830.499756,618.306580 1831.018311,617.695679 
	C1832.937988,618.604126 1834.610229,619.754089 1836.639038,621.372314 
	C1837.058105,622.290771 1837.120728,622.740906 1837.152466,623.615112 
	C1837.828613,626.324280 1834.931763,629.636902 1839.083252,631.189331 
	C1839.069214,631.607605 1839.060669,631.774841 1838.971069,632.219910 
	C1838.865967,632.882385 1838.842163,633.266968 1838.818237,633.651611 
	C1838.170044,633.670593 1837.521973,633.689575 1836.547729,633.712769 
	C1836.679199,627.983337 1832.870728,624.091248 1830.068115,619.381409 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1839.273438,633.742737 
	C1838.842163,633.266968 1838.865967,632.882385 1839.236572,632.409363 
	C1840.717773,632.386902 1841.975708,632.793213 1842.956787,632.436890 
	C1844.338501,631.935059 1846.391602,631.036560 1846.589722,630.006409 
	C1846.955078,628.104736 1847.704956,625.129700 1844.011719,624.581055 
	C1841.764771,623.820068 1839.473999,623.505554 1837.183228,623.191040 
	C1837.120728,622.740906 1837.058105,622.290771 1836.965088,621.501099 
	C1840.597290,621.161560 1844.259888,621.161560 1848.087158,621.161560 
	C1848.256592,623.600403 1848.389893,625.518921 1848.644165,627.829895 
	C1848.851318,628.644775 1848.937378,629.067200 1849.128174,629.798950 
	C1848.703247,631.344299 1848.173462,632.580383 1847.382812,634.043640 
	C1844.657349,634.125244 1842.192993,633.979553 1839.273438,633.742737 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1832.264282,643.292603 
	C1832.537231,642.468811 1833.091064,641.950317 1833.787720,641.182068 
	C1837.404419,641.005188 1841.445068,638.822388 1843.448242,643.598022 
	C1840.043701,643.598022 1836.294312,643.598022 1832.264282,643.292603 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1817.338867,643.354187 
	C1820.739136,639.289551 1824.536255,641.732849 1828.571289,643.011353 
	C1825.095215,643.336182 1821.355591,643.492493 1817.338867,643.354187 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1810.992065,643.136963 
	C1811.313110,642.833435 1811.782227,642.774109 1812.601074,642.880737 
	C1812.347290,643.158142 1811.743652,643.269653 1810.992065,643.136963 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2277.049561,873.864380 
	C2277.489014,874.612427 2277.928467,875.360352 2278.486328,876.343445 
	C2279.029053,879.747803 2277.806885,881.555481 2274.727539,882.929199 
	C2265.366455,887.104614 2256.271729,891.882874 2246.872314,895.963623 
	C2243.720459,897.332031 2239.981689,897.635437 2236.478760,897.824280 
	C2233.368164,897.991943 2230.216064,897.124207 2227.076416,897.076050 
	C2222.228271,897.001648 2219.214844,895.265564 2219.848633,889.802795 
	C2217.879883,889.802795 2216.304199,889.802795 2214.338379,889.888184 
	C2213.948242,889.973633 2213.992188,890.006775 2214.109375,889.672363 
	C2210.129639,884.909302 2206.033203,880.480652 2202.179199,875.714478 
	C2206.625977,874.907104 2210.830322,874.437317 2215.411865,874.056763 
	C2220.497559,874.045349 2225.205811,873.944580 2230.260254,873.943726 
	C2231.054199,874.481995 2231.676025,874.839111 2231.923828,875.370605 
	C2236.793213,885.811707 2242.996338,887.782715 2252.459717,881.876038 
	C2253.002197,881.537476 2253.798340,881.532898 2254.242432,881.120850 
	C2256.628174,878.906860 2258.940186,876.613342 2261.560059,874.261658 
	C2262.231934,874.109497 2262.621338,874.042908 2263.380127,873.999023 
	C2264.486816,873.985474 2265.224609,873.949280 2266.420410,873.966675 
	C2270.269043,873.968262 2273.659424,873.916321 2277.049561,873.864380 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2226.033203,902.584351 
	C2221.902588,903.194214 2217.546631,903.672180 2213.177734,903.893799 
	C2209.724365,904.068909 2206.254883,903.928955 2201.527588,903.928955 
	C2202.959229,902.283691 2203.694092,901.439209 2205.150391,900.624756 
	C2209.515381,901.050476 2212.909668,901.063965 2212.996094,896.007324 
	C2212.996094,896.007324 2213.000000,896.000000 2213.433105,896.074158 
	C2215.610352,896.044373 2217.354248,895.940491 2219.098145,895.836548 
	C2221.332764,898.004944 2223.567627,900.173401 2226.033203,902.584351 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2287.875488,893.153381 
	C2289.072998,896.202820 2290.270752,899.252258 2291.686523,902.857483 
	C2288.730957,903.136597 2286.078125,903.387085 2283.216309,902.870605 
	C2283.020020,896.777039 2283.032471,891.450378 2283.045166,886.123779 
	C2283.913574,886.009033 2284.781982,885.894226 2285.923340,885.281006 
	C2286.460938,885.524292 2286.725342,886.266113 2286.999756,886.996094 
	C2287.010010,886.984253 2287.027588,886.958618 2286.642578,887.066406 
	C2286.844971,888.797180 2287.432373,890.420227 2288.007812,892.023071 
	C2287.996094,892.002869 2287.968018,891.965271 2287.885986,892.257690 
	C2287.827637,892.751221 2287.851562,892.952271 2287.875488,893.153381 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2282.745117,886.232788 
	C2283.032471,891.450378 2283.020020,896.777039 2282.983154,902.574951 
	C2281.978760,901.973267 2280.256836,900.966736 2280.153564,899.815125 
	C2279.806641,895.943970 2280.036133,892.020996 2280.083496,887.670654 
	C2280.896240,886.929565 2281.670654,886.635681 2282.745117,886.232788 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2286.989502,887.007935 
	C2286.725342,886.266113 2286.460938,885.524292 2285.975098,884.936707 
	C2285.043213,883.626892 2284.332520,882.162842 2283.621826,880.698730 
	C2284.202881,880.369141 2284.784180,880.039551 2285.365234,879.709961 
	C2286.407959,880.995239 2287.450684,882.280579 2288.793701,883.740967 
	C2289.085693,884.573059 2289.077148,885.229980 2288.723389,886.297607 
	C2287.915283,886.808167 2287.452393,886.908081 2286.989502,887.007935 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2218.950684,895.543457 
	C2217.354248,895.940491 2215.610352,896.044373 2213.435547,896.071411 
	C2213.413574,895.020386 2213.822510,894.046326 2214.577637,893.034912 
	C2216.216797,893.748413 2217.510254,894.499390 2218.950684,895.543457 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2315.748779,965.764465 
	C2316.351807,966.018433 2316.625977,966.585266 2316.900879,967.504395 
	C2316.407471,967.263611 2315.913818,966.670471 2315.748779,965.764465 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2006.228271,683.080444 
	C2007.835815,691.571838 2011.462524,698.256470 2020.438232,701.259277 
	C2022.928955,701.811707 2025.051392,702.360535 2027.540894,702.832092 
	C2029.716309,707.029968 2030.049561,711.121033 2027.411987,713.753906 
	C2028.038574,717.399536 2028.518677,720.192566 2028.470947,723.435852 
	C2022.664795,722.391174 2016.287964,727.233948 2011.744629,721.104065 
	C2011.573730,720.873596 2010.297974,721.372070 2009.584106,721.651367 
	C2007.708008,722.385315 2005.899536,723.703003 2003.989014,723.857056 
	C1999.379639,724.228821 1994.727173,724.068970 1989.638916,724.029541 
	C1986.901001,723.909241 1984.522583,724.277954 1982.353882,723.763306 
	C1979.154297,723.003967 1974.793823,723.854187 1973.965576,718.572144 
	C1974.022827,716.518738 1974.022827,714.819519 1974.022827,714.354980 
	C1971.108887,713.868958 1968.818970,713.818909 1966.779297,713.078918 
	C1962.271973,711.443848 1960.049316,712.444641 1958.614258,717.055054 
	C1956.692993,717.505432 1955.157959,717.939819 1953.622803,718.374207 
	C1953.532837,717.952698 1953.442871,717.531128 1953.352905,717.109558 
	C1954.859253,716.093872 1956.365723,715.078125 1957.872070,714.062378 
	C1957.710938,713.735229 1957.549805,713.408020 1957.388672,713.080872 
	C1953.612427,713.080872 1949.836304,713.080872 1945.774536,712.908569 
	C1945.345825,711.664246 1945.202637,710.592163 1945.479980,709.569946 
	C1948.954590,709.013184 1952.535767,708.826111 1950.176270,703.846558 
	C1949.536377,703.046448 1949.060425,702.441162 1948.394043,701.593628 
	C1947.531128,702.778992 1946.742920,703.861877 1945.723877,705.005127 
	C1945.493042,705.065552 1945.028320,704.957703 1945.004639,704.537292 
	C1944.965698,697.355042 1944.950684,690.593140 1944.935669,683.831299 
	C1946.765381,683.753723 1948.595215,683.676208 1951.119263,683.336792 
	C1957.713745,682.931946 1953.211426,688.359131 1955.479736,690.086487 
	C1957.006226,688.605408 1958.493042,687.162964 1959.979736,685.720642 
	C1960.763062,687.240906 1961.546265,688.761169 1962.736328,690.527222 
	C1965.035522,691.069336 1966.927734,691.365723 1969.267090,691.787048 
	C1971.246948,691.903137 1972.779663,691.894348 1974.170898,692.210144 
	C1973.538818,693.738708 1973.048218,694.942810 1973.015869,695.021912 
	C1969.180420,694.258423 1965.499634,693.525757 1961.029541,692.635986 
	C1961.843018,696.219971 1962.393677,698.646240 1962.944336,701.072571 
	C1963.307861,701.010742 1963.671265,700.948914 1964.034668,700.887085 
	C1964.301758,699.137329 1964.568970,697.387573 1964.836182,695.637817 
	C1965.628296,700.834229 1966.869995,703.685486 1972.668701,700.737549 
	C1974.284668,699.916016 1976.542236,700.356628 1979.421021,700.153687 
	C1978.581543,697.395874 1977.736206,694.619202 1976.787720,691.502930 
	C1979.593018,690.746033 1981.840210,688.926270 1980.034058,684.977600 
	C1981.729614,684.636780 1983.414307,684.220520 1985.123047,683.970032 
	C1989.282471,683.360352 1993.439209,682.600342 1997.624146,682.345337 
	C2000.333008,682.180298 2003.089478,682.798950 2006.228271,683.080444 
M1957.060181,701.947693 
	C1958.311768,701.197144 1960.609619,700.449036 1960.611328,699.695557 
	C1960.615967,697.720032 1959.988281,695.470947 1958.835205,693.902161 
	C1958.390137,693.296875 1955.846313,694.234131 1953.369751,694.599854 
	C1955.447266,696.877319 1956.730835,698.284302 1957.572876,699.207458 
	C1957.195435,700.019775 1956.807495,700.854736 1957.060181,701.947693 
M2022.217041,714.064453 
	C2021.093384,714.179016 2019.969727,714.293579 2018.846069,714.408142 
	C2019.043335,714.942139 2019.240601,715.476074 2019.437744,716.010010 
	C2020.594849,715.402527 2021.751953,714.794983 2022.217041,714.064453 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1903.877930,724.703064 
	C1905.669189,724.680969 1907.460327,724.658936 1910.145996,724.719482 
	C1915.687256,724.802124 1920.334106,724.802124 1924.980835,724.802124 
	C1924.219360,723.098633 1923.384766,722.647949 1922.530273,722.238831 
	C1915.547729,718.895752 1914.383423,711.818848 1919.937134,706.518311 
	C1920.759399,705.733704 1921.516724,704.881287 1922.304077,704.060181 
	C1922.304077,704.060181 1922.606323,704.188293 1922.765869,704.151123 
	C1923.569580,703.526428 1924.488770,703.055969 1924.799561,702.326294 
	C1925.394531,700.928955 1925.643677,699.384277 1926.107178,697.513428 
	C1926.177856,697.126099 1926.578247,697.061646 1926.816772,697.411987 
	C1927.271484,699.871216 1927.487549,701.979980 1927.746948,704.510559 
	C1928.372070,704.869812 1929.612915,705.348022 1930.537842,706.178101 
	C1931.539673,707.077209 1932.247070,708.304382 1933.035400,709.740601 
	C1931.651611,713.570435 1929.647949,711.671082 1927.921265,710.476807 
	C1928.368530,710.055359 1928.668335,709.911804 1928.942993,709.730347 
	C1929.005737,709.688904 1929.007812,709.421692 1928.994507,709.418152 
	C1922.470093,707.652222 1920.205688,709.709290 1921.657471,716.364502 
	C1923.101196,715.142822 1924.457031,713.995544 1925.798706,713.252258 
	C1926.644409,715.170654 1927.504272,716.685120 1928.674194,718.745728 
	C1928.558594,719.553467 1928.332886,721.130676 1927.813721,722.962341 
	C1927.936279,723.798096 1928.369629,724.891418 1928.765747,724.878113 
	C1931.488647,724.786682 1934.203979,724.463867 1936.921631,724.215515 
	C1938.027588,724.402100 1939.133667,724.588745 1940.998901,724.766724 
	C1942.909180,724.654297 1944.060059,724.550415 1945.573853,724.539795 
	C1949.619873,724.324219 1953.303223,724.015503 1956.989258,724.079590 
	C1956.971191,724.947266 1956.950317,725.442139 1956.464111,725.968750 
	C1946.363037,726.100342 1936.727173,726.200256 1926.642578,726.387207 
	C1925.930908,726.628540 1925.667969,726.782715 1925.069824,726.656494 
	C1919.875610,726.393005 1915.016479,726.409912 1909.785645,726.369751 
	C1908.255981,726.500854 1907.097900,726.689087 1905.965698,726.921875 
	C1905.991455,726.966370 1905.914185,726.898682 1905.626221,726.902466 
	C1904.955688,726.870422 1904.572876,726.834717 1903.812012,726.645569 
	C1901.020630,726.493042 1898.607666,726.493896 1895.923828,726.435181 
	C1895.095459,726.832092 1894.537964,727.288574 1893.651123,727.616211 
	C1891.233887,727.934082 1889.146362,728.380920 1887.128418,728.496460 
	C1886.454102,728.101440 1885.710327,728.037781 1884.589355,727.986816 
	C1881.547729,726.207458 1879.729126,726.657410 1878.773193,729.926392 
	C1878.556030,729.883240 1878.118530,729.814087 1878.118408,729.814087 
	C1877.938843,729.220947 1877.759277,728.627808 1877.011963,727.572998 
	C1871.310181,727.002441 1866.176025,726.893555 1861.058594,726.511353 
	C1861.386963,725.823181 1861.698730,725.408508 1862.010376,724.993835 
	C1862.724365,724.965149 1863.438232,724.936462 1864.960938,724.912109 
	C1869.236938,724.834534 1872.704346,724.752563 1876.171753,724.670654 
	C1876.550537,724.744324 1876.929321,724.817993 1877.597534,724.878967 
	C1879.543701,724.816528 1881.200439,724.766724 1883.484131,724.662231 
	C1885.857178,724.278809 1887.631226,724.051819 1889.340576,723.587402 
	C1890.593750,723.246948 1891.762329,722.594788 1892.982422,722.045532 
	C1892.996216,722.009460 1892.921143,722.025818 1892.927734,722.446228 
	C1893.803589,723.559753 1894.635620,724.782715 1895.549194,724.846802 
	C1898.308472,725.040466 1901.099365,724.784058 1903.877930,724.703064 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1933.188721,667.643188 
	C1937.558228,667.122925 1941.885498,666.986511 1946.688721,666.824341 
	C1949.508301,670.297791 1951.851929,673.797058 1954.368286,677.620178 
	C1949.267822,678.199768 1943.994751,678.455444 1938.335938,678.885620 
	C1936.501343,678.951965 1935.052490,678.843811 1932.584717,678.659607 
	C1932.779541,674.971252 1932.963013,671.499146 1933.188721,667.643188 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1979.702393,684.801514 
	C1981.840210,688.926270 1979.593018,690.746033 1976.787720,691.502930 
	C1977.736206,694.619202 1978.581543,697.395874 1979.421021,700.153687 
	C1976.542236,700.356628 1974.284668,699.916016 1972.668701,700.737549 
	C1966.869995,703.685486 1965.628296,700.834229 1964.836182,695.637817 
	C1964.568970,697.387573 1964.301758,699.137329 1964.034668,700.887085 
	C1963.671265,700.948914 1963.307861,701.010742 1962.944336,701.072571 
	C1962.393677,698.646240 1961.843018,696.219971 1961.029541,692.635986 
	C1965.499634,693.525757 1969.180420,694.258423 1973.015869,695.021912 
	C1973.048218,694.942810 1973.538818,693.738708 1974.271362,691.925293 
	C1974.513184,688.723267 1974.513184,686.130676 1974.513184,683.337585 
	C1970.524170,682.687378 1967.006836,682.607117 1965.339111,687.584717 
	C1966.477661,688.541565 1967.719849,689.152527 1968.958008,690.083374 
	C1968.909424,690.822876 1968.864746,691.242493 1968.820068,691.662109 
	C1966.927734,691.365723 1965.035522,691.069336 1963.043091,690.414734 
	C1962.914795,686.842834 1963.043335,682.741943 1966.595459,682.369080 
	C1970.777588,681.930115 1975.884155,679.815186 1979.702393,684.801514 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1945.052246,683.561035 
	C1944.950684,690.593140 1944.965698,697.355042 1944.663330,704.549255 
	C1943.257446,706.292236 1942.169189,707.602905 1941.020020,708.974487 
	C1940.958984,709.035400 1940.833618,709.153931 1940.833618,709.153931 
	C1937.454956,709.160156 1937.562012,706.673462 1938.130859,704.660217 
	C1938.959839,701.726074 1940.395142,698.963074 1941.799805,695.578186 
	C1941.922607,697.544067 1942.023804,699.164368 1942.125000,700.784668 
	C1942.431519,700.789307 1942.738037,700.794006 1943.044556,700.798645 
	C1943.044556,695.736572 1942.923584,690.669373 1943.139038,685.616455 
	C1943.186401,684.507812 1944.430420,683.450134 1945.123413,682.369019 
	C1945.180420,682.516357 1945.277466,682.661926 1945.283813,682.811401 
	C1945.290527,682.969299 1945.210815,683.130859 1945.052246,683.561035 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1927.764648,710.740967 
	C1929.647949,711.671082 1931.651611,713.570435 1933.275391,709.665039 
	C1933.716187,709.170044 1933.869751,709.102661 1934.023315,709.035278 
	C1934.023315,709.035278 1934.299927,709.322510 1934.498535,709.338623 
	C1934.448242,712.325684 1934.199219,715.296753 1933.550415,718.708130 
	C1931.469482,720.335022 1929.788330,721.521484 1928.107178,722.707886 
	C1928.332886,721.130676 1928.558594,719.553467 1928.674194,718.745728 
	C1927.504272,716.685120 1926.644409,715.170654 1925.810791,713.235046 
	C1925.837158,712.813904 1925.872681,712.791321 1926.203125,712.917725 
	C1927.345337,714.005249 1928.157227,714.966309 1928.968994,715.927307 
	C1929.338989,715.571655 1929.708984,715.215942 1930.078979,714.860291 
	C1928.993408,713.898193 1927.907837,712.936096 1926.932129,711.733093 
	C1927.282959,711.241760 1927.523804,710.991333 1927.764648,710.740967 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1927.813721,722.962341 
	C1929.788330,721.521484 1931.469482,720.335022 1933.453369,719.043701 
	C1934.821045,720.434753 1935.885986,721.930664 1936.936279,723.821045 
	C1934.203979,724.463867 1931.488647,724.786682 1928.765747,724.878113 
	C1928.369629,724.891418 1927.936279,723.798096 1927.813721,722.962341 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1959.988281,685.377625 
	C1958.493042,687.162964 1957.006226,688.605408 1955.479736,690.086487 
	C1953.211426,688.359131 1957.713745,682.931946 1951.401611,683.075195 
	C1953.952637,682.587219 1956.915771,682.099060 1960.321167,681.537964 
	C1960.168335,683.185791 1960.082520,684.110229 1959.988281,685.377625 
M1956.828979,685.770691 
	C1956.999512,685.647949 1957.170044,685.525208 1957.340698,685.402405 
	C1957.102417,685.427856 1956.864258,685.453308 1956.828979,685.770691 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1941.010742,720.233765 
	C1939.880981,720.162598 1938.751343,720.091492 1936.867676,719.972839 
	C1938.256714,719.091492 1938.996338,718.622192 1939.537598,718.278748 
	C1938.667480,716.987427 1937.824097,715.735840 1936.980591,714.484253 
	C1938.028809,713.904785 1939.077026,713.325317 1940.789795,712.859741 
	C1942.497803,713.504822 1940.427856,717.116089 1943.867554,715.891724 
	C1943.944214,717.535583 1943.952759,718.631775 1943.710938,719.546814 
	C1942.643921,719.655029 1941.827393,719.944397 1941.010742,720.233765 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1943.799316,715.343872 
	C1940.427856,717.116089 1942.497803,713.504822 1941.180908,712.746399 
	C1940.921265,711.618286 1940.935059,710.717590 1940.891235,709.485352 
	C1940.833618,709.153931 1940.958984,709.035400 1941.426758,708.956604 
	C1942.949463,709.091858 1944.004395,709.305969 1945.059448,709.520081 
	C1945.202637,710.592163 1945.345825,711.664246 1945.605713,713.139282 
	C1945.081299,714.142761 1944.440308,714.743347 1943.799316,715.343872 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1940.989868,720.596436 
	C1941.827393,719.944397 1942.643921,719.655029 1943.895142,719.722412 
	C1944.636230,720.244995 1944.942627,720.411011 1945.175537,720.894043 
	C1945.138184,722.289612 1945.174683,723.368042 1945.211060,724.446533 
	C1944.060059,724.550415 1942.909180,724.654297 1941.326416,724.672607 
	C1940.919434,723.377808 1940.944214,722.168518 1940.989868,720.596436 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1939.333252,685.481628 
	C1939.282471,684.232971 1939.393433,683.318726 1939.504517,682.404480 
	C1940.080811,682.357300 1940.656982,682.310059 1941.233276,682.262878 
	C1941.537354,685.577942 1941.841431,688.892944 1942.145508,692.208008 
	C1941.732422,692.290955 1941.319214,692.373901 1940.906006,692.456848 
	C1940.435669,690.243225 1939.965210,688.029602 1939.333252,685.481628 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1937.767578,687.955444 
	C1937.578979,688.082458 1937.391235,688.022583 1937.203613,687.962769 
	C1937.391968,687.898010 1937.580322,687.833252 1937.767578,687.955444 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2282.983887,905.980103 
	C2286.351318,905.618713 2289.718750,905.257324 2292.747803,904.932251 
	C2294.807617,909.803406 2296.309326,914.104919 2298.410400,918.090393 
	C2300.136719,921.364502 2298.589844,921.889221 2295.975342,921.933899 
	C2291.983887,922.002075 2287.989990,921.942444 2283.527588,921.420593 
	C2283.033203,915.929443 2283.008545,910.954773 2282.983887,905.980103 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2282.561523,905.992676 
	C2283.008545,910.954773 2283.033203,915.929443 2283.071289,921.349121 
	C2284.336670,922.198303 2285.578857,922.910889 2286.842041,922.951050 
	C2291.326416,923.093567 2295.817627,923.006592 2300.306396,923.006592 
	C2300.312500,923.657532 2300.318604,924.308472 2300.324463,924.959412 
	C2294.205078,924.959412 2288.084229,925.016479 2281.967773,924.875427 
	C2281.322510,924.860596 2280.173096,923.693542 2280.157471,923.039673 
	C2280.032471,917.740479 2280.080566,912.434998 2280.232910,907.135925 
	C2280.244141,906.741028 2281.475098,906.381287 2282.561523,905.992676 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2286.999756,886.996094 
	C2287.452393,886.908081 2287.915283,886.808167 2288.723633,886.625549 
	C2291.011719,890.125000 2292.954102,893.707092 2295.028076,897.615356 
	C2294.782227,900.087341 2294.016602,900.802551 2292.682617,898.511536 
	C2291.704102,896.831055 2290.876709,895.062683 2289.515625,892.868164 
	C2288.688721,892.257019 2288.328369,892.111145 2287.968018,891.965271 
	C2287.968018,891.965271 2287.996094,892.002869 2288.233398,891.693054 
	C2287.989746,889.908386 2287.508545,888.433472 2287.027588,886.958618 
	C2287.027588,886.958618 2287.010010,886.984253 2286.999756,886.996094 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2287.885986,892.257690 
	C2288.328369,892.111145 2288.688721,892.257019 2289.237305,892.692993 
	C2289.165771,892.999512 2288.906494,893.016052 2288.261230,893.093018 
	C2287.851562,892.952271 2287.827637,892.751221 2287.885986,892.257690 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1919.344971,690.229919 
	C1918.690308,689.048340 1918.406982,687.821716 1917.926025,686.268433 
	C1918.102051,680.169128 1918.475708,674.396423 1918.916870,667.578857 
	C1922.594604,672.080627 1922.528320,685.533630 1919.344971,690.229919 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2157.035645,761.829041 
	C2157.781982,761.992004 2158.528564,762.154907 2159.639160,762.188354 
	C2175.258545,768.386780 2188.630615,777.928589 2202.699951,786.933289 
	C2204.514160,788.635864 2205.368652,789.919067 2203.989502,792.527283 
	C2202.542236,795.263733 2202.828125,798.552612 2205.734619,801.107849 
	C2208.470215,803.512634 2210.550049,806.686584 2213.365234,808.971436 
	C2217.957031,812.697998 2219.058838,817.236389 2216.756104,822.122620 
	C2215.685547,823.701111 2214.844971,825.523438 2213.490723,826.801208 
	C2211.436523,828.739136 2209.025146,830.298645 2206.359619,831.640869 
	C2201.415527,826.857788 2198.786377,821.768188 2200.664062,815.233337 
	C2197.942139,815.233337 2195.686279,815.233337 2193.280762,815.233337 
	C2193.058105,817.933777 2192.858154,820.357239 2192.636719,823.042114 
	C2193.468262,823.459534 2194.469971,823.962219 2195.381592,824.419861 
	C2194.938965,825.720337 2194.507812,826.987305 2193.568359,829.748230 
	C2197.754395,826.407532 2200.189941,826.565674 2202.134033,830.263855 
	C2202.647949,831.241150 2204.642822,831.439880 2205.957520,831.996094 
	C2205.200928,836.341370 2200.816650,840.876282 2196.353271,841.765015 
	C2196.000977,841.964233 2196.056885,841.972778 2196.171387,841.608032 
	C2195.867676,841.143433 2195.449463,841.043640 2195.015625,840.971802 
	C2195.000000,840.999756 2195.056152,840.968506 2195.181885,840.601074 
	C2194.881348,840.136780 2194.455322,840.039917 2194.016113,839.972412 
	C2194.003418,840.001770 2194.058105,839.967529 2194.246094,839.607056 
	C2191.573975,836.294556 2190.664307,838.395142 2189.970215,840.980347 
	C2189.998779,840.979858 2189.975098,840.927673 2189.975098,840.927673 
	C2184.935791,843.140625 2184.710693,843.937195 2188.303223,847.723694 
	C2189.921875,849.429749 2191.779297,850.956055 2189.151855,853.661255 
	C2189.008301,853.994812 2189.051270,853.946655 2188.736328,854.104736 
	C2185.034912,856.190308 2184.536133,852.331421 2182.580811,851.429810 
	C2180.551025,850.493958 2177.865234,850.680908 2176.148926,849.436096 
	C2173.683594,847.647644 2171.438721,845.258118 2169.809814,842.677124 
	C2166.751953,837.832153 2163.865234,834.529236 2160.002930,832.452698 
	C2158.750488,830.734009 2157.507568,829.358093 2156.113281,827.705811 
	C2155.313721,826.940247 2154.665771,826.450989 2154.007324,825.980225 
	C2153.997314,825.998779 2154.026367,825.973877 2154.008301,825.625854 
	C2151.203369,821.515259 2148.416504,817.752686 2145.993164,813.995422 
	C2147.945312,813.920044 2149.538330,813.884216 2151.122559,813.749023 
	C2154.118896,813.493286 2157.170410,812.666748 2160.090332,813.008118 
	C2164.829590,813.562073 2165.085693,809.736206 2166.371826,806.924316 
	C2167.891113,809.505859 2168.772461,813.002258 2170.599609,813.597656 
	C2172.829102,814.324219 2175.852783,812.614136 2178.529297,811.958862 
	C2178.580566,811.035461 2178.988281,809.673950 2178.630859,808.557495 
	C2178.225830,807.293701 2177.188721,805.317383 2176.332520,805.267578 
	C2172.048828,805.018738 2167.720215,805.110901 2163.435303,805.445557 
	C2158.716797,805.814209 2152.927490,808.585815 2149.562500,806.740417 
	C2143.014404,803.149719 2143.734375,808.359985 2141.678223,810.845825 
	C2136.794678,806.383911 2135.646729,800.400391 2138.467529,795.009033 
	C2140.923340,790.315002 2143.501221,785.684814 2146.415039,780.985229 
	C2149.383545,781.950500 2151.895020,783.220154 2154.557617,783.891663 
	C2157.380615,784.603577 2160.346680,784.747070 2163.250244,785.138855 
	C2163.396729,784.466431 2163.543213,783.794006 2163.689697,783.121643 
	C2157.330811,783.408752 2151.716309,782.301941 2148.929199,775.649719 
	C2151.670410,770.802551 2154.353027,766.315796 2157.035645,761.829041 
M2174.243896,816.055298 
	C2171.870605,815.998413 2169.490723,816.034363 2167.125244,815.867004 
	C2160.298828,815.383728 2160.301025,815.351562 2161.650879,822.896545 
	C2168.461426,822.896545 2175.343750,823.018066 2182.216309,822.805969 
	C2183.814453,822.756592 2186.127441,821.976379 2186.761475,820.802917 
	C2187.501221,819.434326 2186.760254,817.265320 2186.661865,815.443665 
	C2186.337646,815.448547 2186.013428,815.453430 2185.689209,815.458313 
	C2185.143066,816.508728 2184.596680,817.559143 2183.859863,818.976379 
	C2183.183594,817.444641 2182.741699,816.444336 2182.789551,816.552368 
	C2180.817383,816.263123 2179.690674,816.037659 2178.553467,815.947815 
	C2177.371094,815.854370 2176.178467,815.892029 2174.243896,816.055298 
M2190.384521,806.654724 
	C2188.895752,806.303955 2187.419434,805.743591 2185.915283,805.655823 
	C2184.067871,805.547913 2180.722900,805.477356 2180.593262,806.012878 
	C2180.042725,808.285217 2180.133545,810.871826 2180.748291,813.138000 
	C2180.876465,813.610046 2184.229980,813.249939 2186.094238,813.190430 
	C2187.084961,813.158752 2188.079834,812.789307 2189.056396,812.847839 
	C2193.355225,813.105408 2197.647705,813.463379 2201.942627,813.784973 
	C2199.110352,810.210083 2199.271240,807.891113 2202.236084,804.698669 
	C2198.494629,805.378601 2194.753174,806.058533 2190.384521,806.654724 
M2178.819824,793.898132 
	C2179.059082,795.119080 2179.298340,796.340027 2179.538574,797.565613 
	C2180.942627,796.680542 2182.226807,796.094727 2183.213379,795.185974 
	C2184.188477,794.288086 2184.863037,793.063965 2185.669434,791.982910 
	C2184.428711,791.532166 2183.195801,790.723999 2181.945557,790.695801 
	C2177.645508,790.598633 2173.338623,790.746765 2169.035889,790.862000 
	C2167.633301,790.899475 2166.235840,791.121948 2164.937012,791.249817 
	C2165.970947,793.514771 2166.843994,795.427124 2167.419189,796.686951 
	C2168.177246,795.701599 2169.253418,794.302307 2170.329590,792.902893 
	C2171.890137,794.252747 2173.450684,795.602661 2174.481201,796.494202 
	C2175.619385,795.566589 2177.071533,794.382751 2178.819824,793.898132 
M2172.861084,787.758789 
	C2175.173584,786.239441 2177.946777,784.029358 2175.206055,781.693115 
	C2173.760986,780.461243 2169.980957,781.968262 2167.255127,782.238708 
	C2167.304688,782.694641 2167.354248,783.150635 2167.403809,783.606567 
	C2166.803955,782.848816 2166.204102,782.091003 2165.088379,780.681396 
	C2165.088379,783.247559 2165.088379,784.861145 2165.088379,786.629944 
	C2167.482422,787.097900 2169.772949,787.545654 2172.861084,787.758789 
M2182.708984,781.441040 
	C2179.630371,779.716309 2177.904785,781.564697 2177.195068,784.088196 
	C2176.983887,784.840698 2179.260498,787.291138 2180.034180,787.137390 
	C2182.490967,786.649170 2184.667725,785.234924 2182.708984,781.441040 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2219.264404,829.998962 
	C2218.921875,828.565674 2218.579102,827.132385 2218.234131,825.688721 
	C2222.885254,822.969971 2227.094971,823.196655 2230.455811,826.941101 
	C2234.788574,831.768860 2238.792236,836.892273 2242.937744,841.888245 
	C2243.563721,841.326111 2244.189697,840.763977 2244.815674,840.201843 
	C2244.227051,841.830872 2243.638428,843.459839 2242.634766,845.256165 
	C2241.989014,844.382080 2241.758057,843.340637 2241.527344,842.299194 
	C2242.666260,848.081055 2239.433838,852.093994 2235.940186,856.321289 
	C2231.937988,848.686462 2225.260010,846.460327 2217.415039,847.032898 
	C2217.155029,845.294067 2216.897461,843.571106 2216.644287,841.878540 
	C2213.585449,845.552429 2211.064697,848.580139 2208.100342,852.140564 
	C2213.160645,852.403442 2210.394775,854.985352 2209.455566,856.289307 
	C2207.017334,859.674377 2207.368408,862.630737 2209.749023,865.749634 
	C2210.439941,866.655029 2210.817871,867.799438 2211.021973,869.137573 
	C2206.358643,869.740234 2202.013184,870.039490 2197.315430,870.278809 
	C2190.015381,867.584106 2190.898682,860.478333 2188.483887,855.263123 
	C2188.350098,854.973816 2188.847656,854.392212 2189.051270,853.946655 
	C2189.051270,853.946655 2189.008301,853.994812 2188.988770,854.020874 
	C2190.967041,853.990967 2192.965088,853.934998 2195.411133,853.669922 
	C2195.859619,853.460815 2195.944580,853.482788 2195.696533,853.801147 
	C2195.955322,854.621216 2196.462158,855.122925 2196.968994,855.624634 
	C2197.330322,855.094482 2197.691650,854.564453 2198.027832,854.016724 
	C2198.002441,853.999084 2198.020020,854.057861 2198.389160,854.150024 
	C2198.857666,853.838928 2198.957275,853.435608 2199.028320,853.017639 
	C2198.999756,853.002991 2199.016357,853.064636 2199.292969,853.322388 
	C2200.061035,853.066467 2200.552246,852.552734 2201.015137,852.037354 
	C2200.986816,852.035767 2201.024902,852.079285 2201.024902,852.079285 
	C2206.899414,849.007935 2201.132812,847.012329 2200.179199,844.166199 
	C2200.672607,843.462891 2201.200928,842.473389 2201.945557,841.689148 
	C2205.394287,838.056335 2208.868896,834.446655 2212.407471,830.901794 
	C2213.719238,829.587708 2215.103760,827.706543 2217.638672,830.426270 
	C2218.643311,830.981018 2219.130615,831.209045 2219.777344,831.642395 
	C2220.172607,831.822998 2220.408447,831.798218 2220.644531,831.773438 
	C2220.521240,831.429321 2220.397705,831.085205 2220.159668,830.549805 
	C2219.848389,830.100525 2219.588135,829.980652 2219.264404,829.998962 
M2221.972656,834.630066 
	C2224.743896,837.692810 2227.215576,841.134338 2230.409668,843.662659 
	C2232.072754,844.978882 2235.010742,844.684265 2236.911377,845.032715 
	C2233.290527,842.287048 2229.961426,839.732971 2226.597656,837.225098 
	C2225.070557,836.086609 2223.475342,835.039795 2221.972656,834.630066 
M2211.288574,838.484924 
	C2211.288574,838.484924 2210.834717,838.458313 2210.834717,838.458313 
	C2210.834717,838.458313 2211.120605,838.408875 2211.288574,838.484924 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2232.973633,813.038940 
	C2233.764893,814.004333 2234.555908,814.969788 2235.676514,815.973328 
	C2236.005859,816.011475 2235.963867,816.003845 2236.011230,816.275940 
	C2236.373291,816.712646 2236.687500,816.877197 2237.004395,817.016724 
	C2237.006836,816.991577 2236.956787,816.998718 2237.002197,817.278809 
	C2237.359131,817.716431 2237.670410,817.873901 2237.979492,818.016479 
	C2237.977295,818.001465 2237.952148,818.032043 2237.952148,818.032043 
	C2241.067383,822.280090 2244.214844,826.505127 2247.282227,830.787292 
	C2248.558105,832.568359 2249.663574,834.471313 2250.496094,836.695984 
	C2249.756592,837.403015 2249.367188,837.732910 2248.978027,838.062744 
	C2246.403809,836.952820 2243.672852,836.098389 2241.306396,834.649231 
	C2239.519531,833.555054 2238.219727,831.677979 2236.666504,830.187622 
	C2229.426025,823.240662 2229.421387,823.245361 2220.113770,821.391724 
	C2222.549072,810.778137 2212.962891,806.931763 2207.817383,800.565613 
	C2205.012939,797.095886 2204.284180,794.458008 2207.646484,790.987244 
	C2216.080322,798.263611 2224.172607,805.675964 2232.631836,813.045044 
	C2232.998535,813.001831 2232.973633,813.038940 2232.973633,813.038940 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2261.277588,874.347168 
	C2258.940186,876.613342 2256.628174,878.906860 2254.242432,881.120850 
	C2253.798340,881.532898 2253.002197,881.537476 2252.459717,881.876038 
	C2242.996338,887.782715 2236.793213,885.811707 2231.923828,875.370605 
	C2231.676025,874.839111 2231.054199,874.481995 2230.345215,873.588989 
	C2230.708740,871.304382 2231.333740,869.474426 2231.966797,867.333862 
	C2232.356201,866.345154 2232.737549,865.667114 2233.393555,864.953796 
	C2234.036865,864.905640 2234.405762,864.892883 2234.835938,865.314148 
	C2236.079834,866.903870 2237.208008,868.984253 2238.453369,869.056946 
	C2244.215576,869.393433 2250.013428,869.278442 2255.792725,869.114380 
	C2259.871582,868.998535 2261.962891,865.783264 2259.733154,862.512207 
	C2256.839600,858.267456 2253.190918,854.537537 2249.867188,850.586060 
	C2249.783447,850.269470 2249.772217,849.950317 2250.222656,849.349060 
	C2254.130859,852.657043 2257.749023,856.153625 2261.135010,859.862305 
	C2263.606934,862.570007 2263.748779,865.743774 2261.968750,869.365479 
	C2261.708740,871.283569 2261.493164,872.815369 2261.277588,874.347168 
M2248.448730,874.699341 
	C2244.051270,874.699341 2239.654053,874.699341 2235.134033,874.699341 
	C2236.236572,881.123352 2243.631592,884.447937 2248.423096,881.568420 
	C2250.765137,880.160950 2253.375732,879.207092 2255.791260,877.911194 
	C2256.635010,877.458435 2257.258545,876.594971 2257.982666,875.919067 
	C2257.865479,875.512878 2257.748535,875.106628 2257.631348,874.700439 
	C2254.895020,874.700439 2252.158691,874.700439 2248.448730,874.699341 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2249.516602,850.585754 
	C2253.190918,854.537537 2256.839600,858.267456 2259.733154,862.512207 
	C2261.962891,865.783264 2259.871582,868.998535 2255.792725,869.114380 
	C2250.013428,869.278442 2244.215576,869.393433 2238.453369,869.056946 
	C2237.208008,868.984253 2236.079834,866.903870 2234.998535,864.995605 
	C2236.066650,861.495300 2237.033936,858.747620 2238.320801,855.894409 
	C2240.760254,853.871948 2242.880127,851.955078 2245.000000,850.038208 
	C2246.388672,850.220703 2247.777100,850.403076 2249.516602,850.585754 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2262.012939,868.979187 
	C2263.748779,865.743774 2263.606934,862.570007 2261.135010,859.862305 
	C2257.749023,856.153625 2254.130859,852.657043 2250.250000,849.109802 
	C2249.632080,845.640198 2249.376709,842.130432 2249.049805,838.341675 
	C2249.367188,837.732910 2249.756592,837.403015 2250.412109,837.052979 
	C2250.678223,837.032776 2251.065918,837.133789 2251.469238,837.406921 
	C2252.580078,837.691406 2253.288086,837.702759 2254.206299,837.983765 
	C2254.879639,838.915955 2255.342529,839.578369 2255.772949,840.194336 
	C2254.405762,841.263245 2252.885010,842.023682 2252.061035,843.251709 
	C2251.538086,844.030884 2251.615723,846.097046 2252.202881,846.530151 
	C2253.086182,847.181885 2254.720947,847.203247 2255.902832,846.925537 
	C2256.683594,846.742004 2257.230469,845.564209 2258.189941,844.960266 
	C2259.663330,846.055847 2260.828613,847.022644 2261.997070,847.994263 
	C2262.000000,847.998962 2262.007568,848.005676 2262.007568,848.005676 
	C2262.744141,849.303650 2263.480469,850.601562 2264.562012,851.990723 
	C2264.906982,852.081848 2264.953369,852.043640 2264.955322,852.379639 
	C2265.618896,853.161926 2266.280273,853.608093 2266.970947,854.029602 
	C2267.000000,854.004883 2266.940674,854.058716 2266.876709,854.375244 
	C2267.190918,855.142700 2267.569092,855.593689 2267.973633,856.023560 
	C2268.000000,856.002441 2267.951660,856.050842 2268.002930,856.313232 
	C2268.355957,856.735474 2268.657715,856.895325 2268.957275,857.023743 
	C2268.955078,856.992371 2268.923340,857.047119 2268.865723,857.405151 
	C2269.517090,858.977356 2270.226074,860.191467 2270.935547,861.759766 
	C2272.010742,864.123230 2273.085449,866.132568 2274.607910,868.979187 
	C2269.724854,868.979187 2265.868896,868.979187 2262.012939,868.979187 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2244.830566,849.730835 
	C2242.880127,851.955078 2240.760254,853.871948 2238.012695,855.919495 
	C2236.972900,856.062561 2236.560791,856.074829 2236.148926,856.087097 
	C2239.433838,852.093994 2242.666260,848.081055 2241.527344,842.299194 
	C2241.758057,843.340637 2241.989014,844.382080 2242.499268,845.592407 
	C2243.406250,846.981995 2244.033447,848.202759 2244.830566,849.730835 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2195.944580,853.482788 
	C2195.944580,853.482788 2195.859619,853.460815 2195.874023,853.484497 
	C2194.393555,851.457336 2192.898682,849.406616 2191.404053,847.355835 
	C2194.130859,849.308838 2199.234375,846.047546 2200.752686,851.775513 
	C2201.024902,852.079285 2200.986816,852.035767 2200.725342,851.774414 
	C2199.981445,852.030273 2199.499023,852.547485 2199.016357,853.064697 
	C2199.016357,853.064636 2198.999756,853.002991 2198.723145,853.010254 
	C2198.304199,853.364319 2198.162109,853.711060 2198.020020,854.057861 
	C2198.020020,854.057861 2198.002441,853.999084 2197.681152,853.904297 
	C2196.888184,853.700562 2196.416504,853.591675 2195.944580,853.482788 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2196.017334,841.940979 
	C2196.390137,843.110779 2196.746582,844.303833 2197.319092,846.220764 
	C2194.152344,845.017944 2189.879639,847.345825 2189.975098,841.357666 
	C2189.975098,840.927673 2189.998779,840.979858 2190.332520,840.960693 
	C2191.796875,840.616882 2192.927490,840.292236 2194.058105,839.967529 
	C2194.058105,839.967529 2194.003418,840.001770 2194.025391,840.272461 
	C2194.383545,840.684937 2194.719971,840.826721 2195.056152,840.968506 
	C2195.056152,840.968506 2195.000000,840.999756 2195.008545,841.272461 
	C2195.363770,841.687744 2195.710205,841.830261 2196.056885,841.972778 
	C2196.056885,841.972778 2196.000977,841.964233 2196.017334,841.940979 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2219.617920,831.437073 
	C2219.130615,831.209045 2218.643311,830.981018 2217.994873,830.531982 
	C2218.066650,830.234680 2218.299561,830.158508 2218.898438,830.040649 
	C2219.588135,829.980652 2219.848389,830.100525 2219.989746,830.718262 
	C2219.934570,831.078064 2219.617920,831.437073 2219.617920,831.437073 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2050.009277,682.991455 
	C2050.018555,687.726013 2050.018555,692.469116 2050.018555,697.637451 
	C2045.284424,697.637451 2041.075806,697.637451 2036.351196,697.637451 
	C2036.351196,693.122131 2036.351196,688.460144 2036.351196,683.051392 
	C2040.599365,683.051392 2044.842163,683.051392 2049.551270,683.016663 
	C2050.017334,682.981934 2050.000000,683.000000 2050.009277,682.991455 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2039.195679,699.540894 
	C2049.191650,698.174500 2052.817627,702.480591 2049.736816,711.726868 
	C2049.458008,712.563232 2048.162598,713.558594 2047.287231,713.611145 
	C2043.726440,713.824951 2040.145264,713.700134 2035.990234,713.700134 
	C2035.990234,709.479065 2035.848022,705.526306 2036.116577,701.601624 
	C2036.166992,700.865967 2037.859863,700.242737 2039.195679,699.540894 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M2049.758057,726.073853 
	C2049.689941,726.485413 2049.530273,726.567261 2049.238770,726.295166 
	C2044.702148,725.220825 2040.456787,724.418518 2036.211426,723.616272 
	C2036.211426,721.545288 2036.211426,719.474365 2036.211426,716.823303 
	C2039.845215,716.823303 2043.131714,716.490295 2046.290649,716.980103 
	C2047.636841,717.188843 2049.249512,718.917175 2049.775146,720.317505 
	C2050.363037,721.884827 2049.856445,723.862915 2049.758057,726.073853 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2035.755127,723.685547 
	C2040.456787,724.418518 2044.702148,725.220825 2049.158447,726.335327 
	C2049.172363,726.851013 2048.975098,727.054382 2048.197754,727.393433 
	C2046.127197,726.753784 2044.637085,725.978333 2042.758301,725.118530 
	C2041.846924,725.098816 2041.324097,725.163574 2040.652222,725.225098 
	C2040.503052,725.221863 2040.204712,725.231506 2039.737305,725.132446 
	C2035.775269,725.131714 2032.280640,725.230042 2028.336060,725.280884 
	C2022.722290,725.431030 2017.558594,725.628601 2011.937744,725.709656 
	C2010.564575,725.520874 2009.648438,725.448669 2008.299316,725.340149 
	C2001.969360,725.428162 1996.072266,725.552490 1990.159912,725.417114 
	C1990.127319,724.812866 1990.109741,724.468140 1990.092163,724.123474 
	C1994.727173,724.068970 1999.379639,724.228821 2003.989014,723.857056 
	C2005.899536,723.703003 2007.708008,722.385315 2009.584106,721.651367 
	C2010.297974,721.372070 2011.573730,720.873596 2011.744629,721.104065 
	C2016.287964,727.233948 2022.664795,722.391174 2028.399170,723.850037 
	C2031.003174,723.794189 2033.151001,723.774536 2035.755127,723.685547 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2253.402588,830.033813 
	C2254.150146,831.353821 2254.572510,832.624512 2254.694824,834.113892 
	C2251.575439,831.137878 2248.756348,827.943237 2245.967285,824.375488 
	C2245.997070,824.002380 2246.009766,823.987061 2246.009766,823.987061 
	C2248.365479,825.986206 2250.721436,827.985352 2253.402588,830.033813 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2235.995117,816.030273 
	C2236.385498,815.614075 2236.786621,815.178955 2237.187500,814.743774 
	C2237.639893,815.818604 2238.092529,816.893494 2238.248535,818.000183 
	C2237.952148,818.032043 2237.977295,818.001465 2237.895996,817.770874 
	C2237.528809,817.359802 2237.242676,817.179260 2236.956787,816.998779 
	C2236.956787,816.998718 2237.006836,816.991577 2236.906494,816.777222 
	C2236.525391,816.376465 2236.244629,816.190125 2235.963867,816.003784 
	C2235.963867,816.003845 2236.005859,816.011475 2235.995117,816.030273 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2232.979492,813.014038 
	C2233.230225,812.513062 2233.500244,811.999939 2233.770264,811.486816 
	C2234.045654,811.745850 2234.321045,812.004883 2234.596680,812.263916 
	C2234.251709,812.486938 2233.906494,812.710022 2233.267578,812.986023 
	C2232.973633,813.038940 2232.998535,813.001831 2232.979492,813.014038 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2253.995850,837.714111 
	C2253.288086,837.702759 2252.580078,837.691406 2251.665283,837.366394 
	C2252.038330,836.597534 2252.618652,836.142334 2253.556152,835.783813 
	C2253.913574,835.880493 2253.955566,835.930908 2253.917969,836.246338 
	C2253.918945,836.945923 2253.957275,837.330017 2253.995850,837.714111 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2241.473145,820.502747 
	C2241.427490,820.426758 2241.625244,820.410767 2241.625244,820.410767 
	C2241.625244,820.410767 2241.519043,820.578735 2241.473145,820.502747 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2244.013916,821.981812 
	C2243.864258,821.845337 2243.714600,821.708923 2243.564941,821.572510 
	C2243.663086,821.618835 2243.761475,821.665100 2243.929688,821.854858 
	C2244.000000,821.998230 2244.013916,821.981812 2244.013916,821.981812 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2243.992676,822.004883 
	C2244.312500,822.104614 2244.579590,822.286438 2244.893066,822.778442 
	C2245.000000,823.000000 2245.000000,823.002441 2245.000000,823.002441 
	C2244.720703,822.825256 2244.441406,822.648132 2244.087891,822.226440 
	C2244.013916,821.981812 2244.000000,821.998230 2243.992676,822.004883 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2245.988770,824.009399 
	C2245.699951,823.842346 2245.419678,823.668274 2245.069824,823.248291 
	C2245.000000,823.002441 2245.000000,823.000000 2245.000000,822.999512 
	C2245.335449,823.079102 2245.602295,823.260010 2245.905273,823.764404 
	C2246.009766,823.987061 2245.997070,824.002380 2245.988770,824.009399 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2085.175293,748.864136 
	C2084.436279,745.923279 2081.943604,742.915771 2085.855713,740.247864 
	C2086.072998,740.099609 2084.889893,737.898071 2084.383545,736.041504 
	C2086.270508,735.621887 2088.132568,735.812988 2089.994873,736.004150 
	C2090.446533,736.030884 2090.898193,736.057617 2091.919922,736.370117 
	C2095.527344,737.456970 2098.564697,738.258118 2101.601807,739.059204 
	C2101.681396,744.536743 2101.688721,750.017029 2101.903564,755.489258 
	C2101.944580,756.527954 2102.900146,757.501953 2103.274170,758.562073 
	C2104.262451,761.362610 2105.162842,764.193970 2105.695312,767.402954 
	C2105.292480,767.792847 2104.974121,768.054443 2104.624268,768.194153 
	C2104.072510,773.343323 2100.794189,773.583252 2096.993652,772.708618 
	C2097.419678,771.632568 2097.853516,770.872742 2098.287109,770.112915 
	C2099.202637,767.722229 2100.287842,765.369568 2100.843018,762.897827 
	C2100.922119,762.545715 2098.431641,761.616394 2096.602539,760.667175 
	C2095.380371,759.913818 2094.686279,759.447449 2093.997559,758.991089 
	C2094.002930,759.001099 2094.023438,759.012085 2094.106934,758.635254 
	C2095.787109,757.176331 2097.384033,756.094299 2098.983643,755.337524 
	C2099.339600,756.281738 2099.692871,756.900696 2100.045898,757.519592 
	C2100.148682,756.679260 2100.251465,755.838989 2100.416504,754.541809 
	C2100.413330,753.310242 2100.347900,752.535522 2100.276855,751.696289 
	C2098.522217,752.231323 2096.892822,753.333008 2095.681152,752.984314 
	C2092.057129,751.941284 2088.596436,750.330444 2085.072021,748.941345 
	C2085.072021,748.941345 2085.152344,748.902283 2085.175293,748.864136 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2055.782959,770.113281 
	C2056.892090,769.155701 2058.448486,768.413879 2059.031982,767.203003 
	C2061.448242,762.189392 2065.976318,764.913513 2069.358643,764.614075 
	C2071.617188,764.414062 2074.359863,766.657288 2076.422119,768.366760 
	C2077.729980,769.450806 2078.185791,771.562744 2078.547852,773.668396 
	C2074.270020,774.249695 2070.459717,774.588074 2066.668457,774.439392 
	C2063.310059,774.307678 2059.972656,773.634338 2056.472168,772.923950 
	C2056.139893,771.802917 2055.961426,770.958069 2055.782959,770.113281 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2098.510742,769.740906 
	C2097.853516,770.872742 2097.419678,771.632568 2096.717773,772.876099 
	C2095.707031,773.763916 2094.964600,774.168030 2093.785645,774.413818 
	C2089.904297,773.815491 2086.459473,773.375549 2083.014160,772.935608 
	C2083.395508,770.164429 2083.776611,767.393250 2084.247559,763.968933 
	C2087.965820,763.968933 2091.593994,763.745972 2095.180664,764.036865 
	C2098.250732,764.285889 2099.386963,766.363037 2098.510742,769.740906 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2058.724365,758.334351 
	C2062.942139,758.499268 2067.158936,758.720642 2071.378174,758.794495 
	C2072.639893,758.816528 2073.908691,758.426025 2075.844971,758.102905 
	C2077.136230,758.010620 2077.757080,758.039917 2078.377686,758.069153 
	C2078.656006,760.668823 2078.934570,763.268494 2079.272949,766.429016 
	C2077.138428,765.682800 2075.484863,765.104858 2073.578613,764.438538 
	C2074.696777,764.010864 2075.577393,763.674011 2077.850342,762.804565 
	C2070.720703,761.229797 2064.995605,761.297668 2058.870605,762.722534 
	C2058.540771,761.494446 2058.576172,760.283630 2058.651855,758.682617 
	C2058.692627,758.292542 2058.724365,758.334351 2058.724365,758.334351 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2098.980713,755.012268 
	C2097.384033,756.094299 2095.787109,757.176331 2093.787109,758.859436 
	C2091.889160,758.185913 2090.394287,756.911255 2088.785400,755.539124 
	C2087.902100,756.210754 2087.034912,756.870300 2086.167725,757.529907 
	C2086.099121,756.837097 2086.030518,756.144409 2085.885742,754.777893 
	C2085.483887,752.792969 2085.157959,751.481812 2084.832031,750.170654 
	C2084.844727,749.960327 2084.857178,749.750061 2084.970703,749.240601 
	C2088.596436,750.330444 2092.057129,751.941284 2095.681152,752.984314 
	C2096.892822,753.333008 2098.522217,752.231323 2100.276855,751.696289 
	C2100.347900,752.535522 2100.413330,753.310242 2100.072754,754.542664 
	C2099.437988,755.004333 2099.209473,755.008240 2098.980713,755.012268 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2082.791016,773.220825 
	C2086.459473,773.375549 2089.904297,773.815491 2093.818359,774.624390 
	C2094.195312,775.265381 2094.157959,775.544800 2093.700195,775.896973 
	C2088.402588,776.069092 2083.579834,776.175842 2078.308105,776.239258 
	C2074.078125,776.221008 2070.288086,776.416870 2066.518311,776.221558 
	C2062.995361,776.039062 2059.494141,775.441528 2055.952148,774.822449 
	C2055.921143,774.618225 2055.781250,774.229431 2056.007812,773.984619 
	C2056.364990,773.559937 2056.495361,773.380005 2056.625977,773.200073 
	C2059.972656,773.634338 2063.310059,774.307678 2066.668457,774.439392 
	C2070.459717,774.588074 2074.270020,774.249695 2078.522949,774.051025 
	C2080.171631,773.821350 2081.369385,773.663696 2082.791016,773.220825 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2085.778320,757.665161 
	C2087.034912,756.870300 2087.902100,756.210754 2088.785400,755.539124 
	C2090.394287,756.911255 2091.889160,758.185913 2093.703613,759.236328 
	C2094.023438,759.012085 2094.002930,759.001099 2093.742920,759.275574 
	C2093.633301,760.032837 2093.783691,760.515625 2093.934082,760.998413 
	C2091.442871,761.030579 2088.943115,761.205017 2086.462646,761.056763 
	C2083.735107,760.893677 2083.210938,759.722961 2085.778320,757.665161 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2050.079346,764.907593 
	C2052.442139,764.617798 2054.804932,764.327942 2057.577637,763.981262 
	C2057.007080,765.706848 2056.026855,767.489258 2054.721680,769.437195 
	C2054.169434,768.563354 2053.942383,767.523987 2053.719482,766.504944 
	C2052.355957,766.682068 2051.133789,766.840820 2049.508301,767.036926 
	C2048.012695,767.093262 2046.920166,767.112244 2046.036621,766.837769 
	C2047.523315,765.998657 2048.801270,765.453125 2050.079346,764.907593 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2049.911621,766.999573 
	C2051.133789,766.840820 2052.355957,766.682068 2053.719482,766.504944 
	C2053.942383,767.523987 2054.169434,768.563354 2054.693359,769.789246 
	C2054.990234,769.975830 2055.383789,770.064575 2055.583496,770.088928 
	C2055.961426,770.958069 2056.139893,771.802917 2056.472168,772.923950 
	C2056.495361,773.380005 2056.364990,773.559937 2055.693848,773.923218 
	C2054.725586,774.084167 2054.298340,774.061890 2053.891846,773.696289 
	C2052.735596,772.140137 2051.558350,770.927246 2050.343262,769.371216 
	C2050.174072,768.351929 2050.042969,767.675720 2049.911621,766.999573 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2084.564941,750.382874 
	C2085.157959,751.481812 2085.483887,752.792969 2085.760742,754.450806 
	C2085.240967,753.396667 2084.769531,751.995850 2084.564941,750.382874 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2078.488525,757.785645 
	C2077.757080,758.039917 2077.136230,758.010620 2076.201172,757.965942 
	C2076.192383,757.494690 2076.498291,757.038879 2076.984131,756.371277 
	C2077.642578,756.606995 2078.120850,757.054626 2078.488525,757.785645 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2081.597900,747.283325 
	C2082.038818,747.491821 2082.179199,747.876160 2082.409668,748.555542 
	C2082.236084,749.588257 2081.972412,750.325928 2081.708740,751.063599 
	C2081.491211,751.050415 2081.273926,751.037292 2081.056396,751.024109 
	C2081.136719,749.835754 2081.217041,748.647461 2081.597900,747.283325 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2080.463867,755.505676 
	C2080.472168,754.948792 2080.681396,754.610046 2080.890381,754.271362 
	C2081.156494,754.551514 2081.422852,754.831726 2081.688965,755.111877 
	C2081.347412,755.315918 2081.006104,755.519897 2080.463867,755.505676 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2094.330078,761.039062 
	C2093.783691,760.515625 2093.633301,760.032837 2093.737549,759.265503 
	C2094.686279,759.447449 2095.380371,759.913818 2096.194580,760.682922 
	C2095.785156,761.017029 2095.255615,761.048401 2094.330078,761.039062 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2185.611572,898.828674 
	C2184.239990,899.388977 2183.171143,900.048035 2182.023193,900.755920 
	C2181.640381,899.883057 2180.850830,898.082153 2179.911377,895.939636 
	C2182.005615,895.526794 2184.029541,895.127808 2185.791016,894.780640 
	C2185.791016,889.425598 2185.791016,884.305115 2185.791016,879.184631 
	C2185.972168,879.178406 2186.153320,879.172119 2186.334473,879.165894 
	C2186.635742,884.983887 2186.937012,890.801819 2187.177246,895.442383 
	C2192.225098,895.946472 2196.126953,896.336121 2200.465332,896.673279 
	C2200.902100,896.620789 2200.947998,896.552795 2200.630371,896.727783 
	C2198.276611,898.507263 2196.240967,900.111755 2193.861816,901.986877 
	C2193.414795,900.101685 2193.089600,898.730103 2192.601074,896.669617 
	C2190.822510,898.651123 2189.417236,900.216797 2188.011963,901.782471 
	C2187.312744,900.830811 2186.613525,899.879150 2185.611572,898.828674 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2188.087891,902.199890 
	C2189.417236,900.216797 2190.822510,898.651123 2192.601074,896.669617 
	C2193.089600,898.730103 2193.414795,900.101685 2193.861816,901.986877 
	C2196.240967,900.111755 2198.276611,898.507263 2200.620361,896.687988 
	C2202.134277,900.952576 2200.200439,904.018066 2195.942383,903.878601 
	C2193.338379,903.793213 2190.755859,903.059692 2188.087891,902.199890 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2182.726562,927.695312 
	C2180.154297,928.536377 2179.344727,935.598022 2175.003418,929.239441 
	C2175.044189,928.299805 2175.070801,927.963562 2175.097656,927.627319 
	C2175.885742,926.870178 2176.673584,926.112976 2177.758789,925.210022 
	C2178.055664,925.064270 2178.461670,925.138184 2178.958496,925.290283 
	C2180.591797,926.144714 2181.728271,926.847046 2182.864990,927.549316 
	C2182.864990,927.549377 2182.763428,927.677856 2182.726562,927.695312 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2212.634277,896.092651 
	C2212.909668,901.063965 2209.515381,901.050476 2205.503418,900.466858 
	C2205.100342,900.036865 2205.078613,899.793701 2205.069824,899.363159 
	C2207.470215,898.177368 2209.871338,897.177673 2212.634277,896.092651 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2183.153320,927.330933 
	C2181.728271,926.847046 2180.591797,926.144714 2179.163330,925.266968 
	C2179.995117,924.244568 2181.118652,923.397644 2182.242188,922.550720 
	C2182.738037,922.603027 2183.233887,922.655396 2183.729736,922.707764 
	C2183.633789,924.176025 2183.537598,925.644287 2183.153320,927.330933 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1992.596436,814.177490 
	C1994.063721,812.844482 1995.530884,811.511475 1996.998169,810.178467 
	C1996.958008,810.623962 1996.917847,811.069458 1996.877808,811.514954 
	C2002.402222,812.097778 2007.926636,812.680542 2013.834717,813.303894 
	C2013.834717,799.450134 2014.458496,786.947021 2013.609619,774.544739 
	C2013.068237,766.635437 2017.953613,762.856140 2022.455444,758.393494 
	C2023.246704,757.609131 2024.956177,757.383545 2026.192749,757.486755 
	C2029.600464,757.771057 2030.720703,756.268555 2030.423096,752.859314 
	C2031.179077,753.970947 2031.710205,755.332886 2032.241333,756.694824 
	C2032.460083,756.598633 2032.678711,756.502502 2032.897461,756.406372 
	C2032.897461,752.432251 2032.897461,748.458130 2032.897461,744.484009 
	C2032.604614,744.422791 2032.311768,744.361572 2032.018921,744.300354 
	C2031.374512,746.117981 2030.730103,747.935608 2030.085693,749.753174 
	C2030.057251,747.811768 2030.028687,745.870361 2029.843506,743.540161 
	C2031.544067,743.167847 2033.401367,743.184265 2035.532471,743.819641 
	C2034.884277,750.012512 2038.403076,749.520996 2041.975952,748.955688 
	C2041.983276,749.133057 2041.990601,749.310425 2041.562378,749.658264 
	C2035.149292,749.960083 2034.298584,751.677979 2037.028320,758.385193 
	C2037.056763,758.607117 2036.957764,759.043518 2036.702881,758.934143 
	C2036.265259,758.851562 2036.082397,758.878418 2035.457397,758.920959 
	C2033.226562,759.089172 2031.437744,759.241699 2029.648926,759.394165 
	C2021.908569,759.515137 2015.983521,765.434753 2016.022339,773.000244 
	C2016.080444,784.319885 2016.040039,795.641357 2016.259521,806.957764 
	C2016.309326,809.524292 2017.253784,812.073364 2017.905762,815.203430 
	C2017.586060,816.007019 2016.969238,817.556824 2016.352539,819.106628 
	C2018.278687,819.645508 2020.204834,820.184448 2023.570923,821.126160 
	C2020.566650,822.009460 2018.698120,822.558838 2016.282715,823.269043 
	C2016.282715,832.784912 2016.282715,842.454834 2016.282715,852.124756 
	C2015.884644,852.170044 2015.486572,852.215393 2015.088501,852.260742 
	C2014.859375,851.155884 2014.481689,850.058594 2014.424561,848.944885 
	C2013.958008,839.864990 2013.203979,830.777161 2013.332397,821.703003 
	C2013.372559,818.870361 2015.143188,815.750671 2011.317139,814.910339 
	C2007.786377,814.134766 2003.348389,813.460876 2000.449463,814.954285 
	C1997.469604,816.489502 1995.738770,816.410828 1992.981201,814.643372 
	C1992.568115,814.457825 1992.596436,814.177490 1992.596436,814.177490 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2028.829956,741.796021 
	C2026.511230,741.843018 2024.192627,741.890015 2021.107178,741.949646 
	C2019.888550,742.007629 2019.436646,742.052917 2018.564209,742.016541 
	C2016.434814,741.993713 2014.726074,742.052490 2012.678833,742.048889 
	C2011.886353,742.039856 2011.432129,742.093201 2010.514893,742.082214 
	C2005.394897,742.017334 2000.727417,742.202515 1996.085327,741.940369 
	C1993.650635,741.802917 1991.260132,740.881348 1988.281616,740.180237 
	C1985.765625,743.655334 1981.333618,741.974609 1977.344360,741.520508 
	C1979.647705,738.816162 1981.817261,736.399414 1984.456055,734.005432 
	C1991.306641,733.967529 1997.687866,733.906860 2004.532227,733.876465 
	C2012.847412,733.906616 2020.699341,733.906616 2028.829956,733.906616 
	C2028.829956,736.985718 2028.829956,739.390869 2028.829956,741.796021 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2043.146973,725.203003 
	C2044.637085,725.978333 2046.127197,726.753784 2047.904297,727.700623 
	C2048.861816,730.325806 2049.936035,732.750732 2050.113770,735.239624 
	C2050.429688,739.666016 2050.202637,744.131042 2050.202637,750.478149 
	C2046.953735,748.661743 2044.751099,747.607727 2042.746338,746.259521 
	C2040.722778,744.898682 2038.895630,743.245911 2036.545166,741.206421 
	C2036.107788,736.288086 2036.107788,731.884460 2036.107788,728.433289 
	C2039.046631,727.084595 2041.096802,726.143799 2043.146973,725.203003 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2042.758301,725.118530 
	C2041.096802,726.143799 2039.046631,727.084595 2036.107788,728.433289 
	C2036.107788,731.884460 2036.107788,736.288086 2036.200317,741.115845 
	C2035.435669,741.611145 2034.578369,741.682251 2033.328613,741.294434 
	C2032.936035,736.507507 2032.936035,732.179504 2032.936035,727.851440 
	C2032.259644,727.861023 2031.583252,727.870544 2030.906860,727.880066 
	C2031.292236,732.523315 2031.677734,737.166504 2032.063232,741.809692 
	C2031.252563,741.852295 2030.441895,741.894958 2029.230713,741.866821 
	C2028.829956,739.390869 2028.829956,736.985718 2028.829956,733.906616 
	C2020.699341,733.906616 2012.847412,733.906616 2004.621216,733.499573 
	C2005.769653,732.740479 2007.286011,732.120605 2008.816040,732.084656 
	C2014.284058,731.956177 2019.756958,731.995239 2025.227417,732.035950 
	C2027.392334,732.052002 2029.357910,731.994629 2028.989136,728.608154 
	C2028.925293,727.270935 2028.855713,726.299683 2028.786133,725.328369 
	C2032.280640,725.230042 2035.775269,725.131714 2039.786743,725.239868 
	C2040.303711,725.446289 2040.604614,725.505066 2040.604614,725.505066 
	C2040.604614,725.505066 2040.801392,725.228394 2040.801392,725.228394 
	C2041.324097,725.163574 2041.846924,725.098816 2042.758301,725.118530 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2035.899414,758.905273 
	C2036.082397,758.878418 2036.265259,758.851562 2036.920898,759.304321 
	C2040.556274,760.039368 2043.718750,760.294861 2046.881226,760.550415 
	C2046.555054,760.661499 2046.441772,760.882202 2046.360840,761.371826 
	C2047.101318,762.318726 2048.022705,763.106262 2048.943848,763.893738 
	C2049.235107,764.081848 2049.526611,764.270020 2049.948730,764.682861 
	C2048.801270,765.453125 2047.523315,765.998657 2045.900269,766.837524 
	C2045.371582,767.158142 2045.188599,767.155029 2044.841675,766.755737 
	C2044.276245,765.294678 2043.875488,764.199463 2043.058350,761.966797 
	C2042.419800,764.060059 2042.136475,764.989319 2041.443115,765.939209 
	C2034.941650,766.796082 2036.095825,762.359253 2035.899414,758.905273 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2037.000000,758.163208 
	C2034.298584,751.677979 2035.149292,749.960083 2041.558716,749.924561 
	C2042.504272,751.601013 2043.018066,753.181641 2043.989136,755.328186 
	C2041.964355,756.650513 2039.482178,757.406860 2037.000000,758.163208 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2037.028320,758.385132 
	C2039.482178,757.406860 2041.964355,756.650513 2044.327148,755.499634 
	C2044.207642,755.105042 2044.134399,755.040405 2044.386719,755.334900 
	C2046.690552,754.882568 2045.049316,750.247742 2048.993652,751.493530 
	C2048.482910,754.300110 2047.972290,757.106689 2047.171387,760.231812 
	C2043.718750,760.294861 2040.556274,760.039368 2037.175781,759.413696 
	C2036.957764,759.043518 2037.056763,758.607117 2037.028320,758.385132 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2032.267334,741.831909 
	C2031.677734,737.166504 2031.292236,732.523315 2030.906860,727.880066 
	C2031.583252,727.870544 2032.259644,727.861023 2032.936035,727.851440 
	C2032.936035,732.179504 2032.936035,736.507507 2032.898071,741.278320 
	C2032.860107,741.721130 2032.471558,741.854187 2032.267334,741.831909 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2041.918213,748.616089 
	C2038.403076,749.520996 2034.884277,750.012512 2035.891235,743.964233 
	C2037.937622,745.085510 2039.899048,746.681030 2041.918213,748.616089 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2049.212402,751.232178 
	C2045.049316,750.247742 2046.690552,754.882568 2044.344116,755.358826 
	C2044.229248,749.877991 2045.172241,749.022766 2049.526367,750.139099 
	C2049.593018,750.156189 2049.648193,750.398254 2049.629639,750.523682 
	C2049.606689,750.679138 2049.501221,750.822388 2049.212402,751.232178 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2048.919922,763.534729 
	C2048.022705,763.106262 2047.101318,762.318726 2046.518799,761.504211 
	C2047.536987,762.043396 2048.216553,762.609497 2048.919922,763.534729 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2058.802246,757.877563 
	C2058.724365,758.334351 2058.692627,758.292542 2058.694824,758.322144 
	C2058.629639,756.720215 2058.562012,755.088684 2058.755371,753.137695 
	C2058.970703,754.352539 2058.925537,755.886658 2058.802246,757.877563 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2018.984619,742.098145 
	C2019.436646,742.052917 2019.888550,742.007629 2020.677246,742.025574 
	C2021.013916,742.088806 2021.013672,742.571899 2021.011230,742.813477 
	C2020.450195,743.149475 2019.891724,743.243835 2019.158691,742.953247 
	C2018.984009,742.568359 2018.984619,742.098145 2018.984619,742.098145 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2150.236816,759.212891 
	C2150.137695,759.601746 2150.038574,759.990601 2149.917969,761.033447 
	C2148.263428,765.707275 2145.296387,767.374939 2140.965088,767.169312 
	C2139.494873,767.099426 2137.511719,767.450562 2136.563965,768.405029 
	C2128.876221,776.146423 2127.019287,776.548218 2117.217773,771.466675 
	C2116.213379,769.958679 2115.358643,768.750305 2114.314209,767.245728 
	C2114.698730,766.409363 2115.284424,765.881104 2115.844238,765.326233 
	C2116.601807,764.575500 2117.339355,763.804443 2118.205566,762.932190 
	C2118.325684,762.822266 2118.442627,762.518250 2118.767090,762.519287 
	C2120.372559,762.705383 2121.653564,762.890381 2122.968994,763.043762 
	C2123.003418,763.012146 2122.922607,763.058044 2122.845947,763.396240 
	C2122.949463,764.305481 2123.129883,764.876526 2123.310059,765.447632 
	C2123.509521,765.406921 2123.708984,765.366272 2123.908447,765.325623 
	C2123.964844,764.565674 2124.020996,763.805786 2124.435547,763.124268 
	C2126.406982,763.615112 2128.020752,764.027588 2129.502441,764.406372 
	C2129.502441,762.586121 2129.502441,760.786133 2129.502441,758.986084 
	C2127.121582,764.039734 2124.433350,758.532043 2121.953125,759.559387 
	C2121.469238,759.799011 2121.185059,759.925598 2121.040527,759.664185 
	C2119.718506,758.927063 2118.240479,758.540833 2116.423340,758.065979 
	C2116.423340,760.323303 2116.423340,761.635132 2116.066895,762.967346 
	C2114.053467,763.402954 2112.396484,763.818176 2109.666260,764.502258 
	C2111.875488,761.984497 2113.182617,760.494751 2114.489746,759.005066 
	C2114.258545,758.724365 2114.027588,758.443604 2113.796631,758.162903 
	C2112.290527,759.183289 2110.784424,760.203735 2109.167480,761.135498 
	C2109.056396,761.046814 2108.834229,760.870239 2108.834229,760.870239 
	C2107.998779,759.957764 2107.062744,759.116455 2106.352051,758.115479 
	C2105.381104,756.747864 2104.217529,755.357666 2103.793457,753.798340 
	C2103.166016,751.492310 2103.190186,749.017090 2102.803711,746.635498 
	C2102.106689,742.343140 2103.499023,740.772705 2108.502197,741.371094 
	C2110.181396,742.828247 2111.401123,744.576477 2112.645752,744.594604 
	C2115.744629,744.639709 2118.854736,743.897095 2121.995361,743.863220 
	C2123.422607,745.540833 2124.814941,746.825989 2125.904297,748.385620 
	C2121.942139,749.067322 2118.282715,749.474609 2113.720215,749.982361 
	C2113.770508,750.044189 2113.016357,749.116821 2111.185059,746.864990 
	C2110.371338,750.389709 2109.860352,752.602722 2109.349365,754.815735 
	C2111.022217,754.886658 2112.695557,754.949585 2114.367676,755.033691 
	C2114.822998,755.056580 2115.274902,755.189514 2115.728760,755.192810 
	C2118.063477,755.209595 2120.398193,755.200562 2122.732910,755.200562 
	C2123.992920,755.076294 2125.550537,756.213257 2126.788818,755.933960 
	C2129.945068,755.222168 2132.958252,753.877991 2136.054443,753.217468 
	C2137.112793,755.891113 2138.291016,757.183777 2140.517334,754.188599 
	C2143.076904,754.455444 2145.233154,754.799011 2147.622559,755.361633 
	C2148.649414,756.791382 2149.443115,758.002136 2150.236816,759.212891 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2114.503906,767.541992 
	C2115.358643,768.750305 2116.213379,769.958679 2117.037109,771.799011 
	C2116.739990,773.341492 2116.473877,774.252014 2116.218506,775.594360 
	C2114.523438,781.003113 2117.055664,784.425781 2120.351562,787.677185 
	C2122.242676,789.542725 2123.860352,791.682495 2125.642334,793.661987 
	C2128.544434,796.885620 2131.480225,800.078979 2133.880371,802.713135 
	C2135.867676,797.891479 2137.918945,793.629089 2139.380127,789.173096 
	C2140.910645,784.504944 2146.791992,781.104919 2142.974854,774.732239 
	C2148.743164,775.461914 2148.711670,770.810425 2150.764160,767.764893 
	C2152.142090,765.301758 2153.322266,763.094482 2154.502197,760.887207 
	C2155.256836,761.028992 2156.011230,761.170776 2156.900879,761.570801 
	C2154.353027,766.315796 2151.670410,770.802551 2148.694824,775.876709 
	C2147.609619,777.984802 2146.817139,779.505554 2146.024902,781.026367 
	C2143.501221,785.684814 2140.923340,790.315002 2138.467529,795.009033 
	C2135.646729,800.400391 2136.794678,806.383911 2141.831055,811.109863 
	C2143.416260,812.345581 2144.522949,813.167847 2145.629883,813.990112 
	C2148.416504,817.752686 2151.203369,821.515259 2153.874512,825.940735 
	C2153.325439,826.552429 2152.891846,826.501160 2152.142090,826.295654 
	C2151.600830,826.077881 2151.375732,826.014404 2150.962158,825.669556 
	C2146.613525,820.517578 2143.267090,815.392822 2136.995117,812.010132 
	C2129.843262,808.152954 2127.766602,806.434082 2121.042480,809.681030 
	C2123.883789,804.406616 2128.079346,806.440674 2132.377930,807.194702 
	C2133.152588,807.090088 2133.557373,806.924194 2133.961914,806.758301 
	C2133.529785,806.233521 2133.097900,805.708679 2132.610596,804.858032 
	C2127.097168,798.369934 2121.394531,792.401550 2116.283447,785.963379 
	C2113.716309,782.729553 2111.314453,780.188293 2106.895020,780.214966 
	C2106.910645,778.834595 2106.950684,777.830750 2107.055176,776.566650 
	C2107.438477,776.210571 2107.757812,776.114868 2108.279541,776.401611 
	C2109.296143,781.365417 2112.050537,777.980591 2113.978271,778.537109 
	C2113.978271,776.286072 2113.978271,774.461670 2113.978271,772.456299 
	C2112.084473,773.452271 2110.548340,774.260071 2109.056152,774.669800 
	C2111.333984,770.084290 2110.792725,769.384644 2105.104492,768.919556 
	C2105.010498,768.442688 2104.992188,768.248596 2104.974121,768.054443 
	C2104.974121,768.054443 2105.292480,767.792847 2105.497314,767.778076 
	C2106.549805,767.579956 2107.397217,767.396545 2108.598633,767.118958 
	C2108.952148,767.024780 2108.969727,767.099365 2109.274658,767.367676 
	C2111.220947,767.604614 2112.862549,767.573303 2114.503906,767.541992 
M2137.143799,810.501282 
	C2137.334229,810.513489 2137.524658,810.525696 2137.715088,810.537903 
	C2137.625977,810.438416 2137.537109,810.338989 2137.143799,810.501282 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2124.627197,729.830078 
	C2126.971436,733.331116 2129.287842,735.485535 2133.962158,735.044678 
	C2135.974121,734.854919 2138.268066,737.656494 2140.889160,739.402039 
	C2150.310059,735.966797 2155.489502,738.636353 2157.245850,748.903076 
	C2156.404053,749.698120 2155.424072,750.102539 2153.938232,750.765747 
	C2153.068115,750.139771 2152.704102,749.254883 2152.572754,748.936035 
	C2149.526123,748.603394 2146.762939,748.301697 2143.689697,747.881104 
	C2142.944092,747.684326 2142.508545,747.606506 2142.038574,747.188293 
	C2135.720703,744.193726 2129.437256,741.539551 2122.975098,738.529419 
	C2119.669434,737.607483 2116.542480,737.041565 2113.415527,736.475586 
	C2112.194580,735.501587 2110.973633,734.527588 2109.752686,733.553589 
	C2109.728516,732.995239 2109.704590,732.436951 2109.680420,731.878662 
	C2114.537109,731.186707 2119.393799,730.494751 2124.627197,729.830078 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2113.107178,736.692505 
	C2116.542480,737.041565 2119.669434,737.607483 2122.859863,738.865601 
	C2122.602051,740.862061 2122.281250,742.166382 2121.960449,743.470703 
	C2118.854736,743.897095 2115.744629,744.639709 2112.645752,744.594604 
	C2111.401123,744.576477 2110.181396,742.828247 2108.913574,741.506836 
	C2110.184326,739.734009 2111.491455,738.321716 2113.107178,736.692505 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2101.956543,738.948303 
	C2098.564697,738.258118 2095.527344,737.456970 2092.258301,736.394714 
	C2093.359131,735.475098 2094.768799,734.149109 2096.008545,734.292542 
	C2098.874756,734.624207 2101.658447,735.667725 2105.135254,736.599365 
	C2103.914307,737.566956 2103.112549,738.202148 2101.956543,738.948303 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2090.119141,735.697937 
	C2088.132568,735.812988 2086.270508,735.621887 2084.202148,735.717896 
	C2083.328613,735.221191 2082.661377,734.437317 2081.941895,733.293335 
	C2082.706055,732.327576 2083.522461,731.721924 2084.643555,731.038452 
	C2086.868896,732.251465 2090.585938,731.395935 2090.119141,735.697937 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2108.863281,766.115845 
	C2108.689697,764.540039 2108.515869,762.964172 2108.588379,761.129272 
	C2108.834229,760.870239 2109.056396,761.046814 2109.127930,761.509888 
	C2109.233398,763.231140 2109.267578,764.489197 2109.192139,765.839478 
	C2109.082520,765.931641 2108.863281,766.115845 2108.863281,766.115845 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2108.917969,767.042419 
	C2108.884033,767.060059 2108.846191,766.587769 2108.854736,766.351807 
	C2108.863281,766.115845 2109.082520,765.931641 2109.429199,765.975952 
	C2109.507080,766.380005 2109.238281,766.739685 2108.969727,767.099365 
	C2108.969727,767.099365 2108.952148,767.024780 2108.917969,767.042419 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1921.945068,703.926453 
	C1921.516724,704.881287 1920.759399,705.733704 1919.937134,706.518311 
	C1914.383423,711.818848 1915.547729,718.895752 1922.530273,722.238831 
	C1923.384766,722.647949 1924.219360,723.098633 1924.980835,724.802124 
	C1920.334106,724.802124 1915.687256,724.802124 1910.594727,724.697510 
	C1910.158813,722.294983 1909.765381,719.897339 1910.301514,717.729736 
	C1910.757812,715.885620 1912.285034,714.306458 1913.721924,711.992615 
	C1912.502319,711.992615 1910.685059,711.992615 1908.467163,711.950684 
	C1908.046875,708.584778 1908.027100,705.260864 1907.968140,701.483765 
	C1907.928833,696.414062 1907.928833,691.797485 1907.928833,685.663147 
	C1910.016235,688.621948 1911.471680,690.432617 1912.636963,692.413940 
	C1913.630737,694.103455 1914.290771,695.989319 1915.116211,697.827881 
	C1914.961060,697.858887 1913.781738,698.094482 1912.090942,698.432190 
	C1915.566284,700.394226 1918.576294,702.093506 1921.945068,703.926453 
M1913.481689,715.670715 
	C1913.481689,715.670715 1913.606079,715.435974 1913.606079,715.435974 
	C1913.606079,715.435974 1913.337524,715.478455 1913.481689,715.670715 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1673.358154,667.663513 
	C1673.459961,672.252747 1673.331055,676.532166 1673.396240,680.808533 
	C1673.415649,682.084656 1673.831055,683.414001 1674.328247,684.609436 
	C1675.028198,686.292297 1679.335571,686.676941 1676.242798,689.670044 
	C1674.749878,691.114929 1672.820190,692.097961 1671.203979,693.430359 
	C1670.320679,694.158386 1669.678589,695.179016 1668.217896,696.085815 
	C1664.621338,697.340332 1662.180664,698.858521 1663.361694,702.665405 
	C1663.496948,702.856384 1663.632080,703.047424 1663.858154,703.961060 
	C1663.643799,706.457031 1662.726807,708.396301 1663.153931,709.971069 
	C1664.197754,713.820496 1664.949707,717.189575 1660.020386,718.944519 
	C1661.327637,719.027039 1662.635010,719.109497 1663.954346,719.358826 
	C1663.966309,719.525635 1663.965820,719.860046 1663.889893,720.220825 
	C1662.202026,721.417664 1660.589966,722.253784 1658.554443,723.059875 
	C1656.437744,722.956848 1654.744507,722.883850 1653.043091,722.367188 
	C1652.952148,718.908569 1652.869629,715.893738 1652.830322,712.499207 
	C1652.701660,709.228088 1652.529541,706.336731 1652.357422,703.445374 
	C1651.908691,703.453125 1651.460083,703.460938 1651.011353,703.468750 
	C1651.011353,706.642761 1651.011353,709.816711 1650.996094,713.439148 
	C1651.343506,716.934814 1651.706299,719.981995 1652.239624,723.346985 
	C1653.723755,724.566284 1655.037476,725.467834 1656.351074,726.369446 
	C1656.238647,726.746521 1656.126221,727.123596 1656.013794,727.500671 
	C1651.369873,727.500671 1646.725952,727.500671 1642.098389,727.063232 
	C1642.072754,714.804993 1642.082886,702.983704 1641.959961,691.163757 
	C1641.919189,687.238953 1643.239868,685.453735 1647.347046,686.794800 
	C1648.392944,687.136414 1649.519409,687.231506 1651.075562,687.529114 
	C1650.829224,690.077637 1650.450928,692.345459 1650.436768,694.615601 
	C1650.423340,696.762268 1650.792480,698.911255 1651.112549,702.334961 
	C1653.518799,687.044067 1654.084961,672.572205 1668.499756,663.447571 
	C1661.698120,664.175781 1655.311646,670.436279 1654.257568,677.550476 
	C1653.694214,681.352722 1652.087158,682.156799 1648.710815,682.043274 
	C1642.223267,681.825134 1635.725586,681.912170 1629.136475,681.428894 
	C1629.476440,675.869751 1628.015015,670.561340 1630.054810,665.167419 
	C1631.026245,666.306519 1631.521973,666.887878 1632.453369,667.980103 
	C1632.453369,665.213257 1632.453369,663.310425 1632.453369,661.971252 
	C1630.324829,661.730164 1628.574463,661.609436 1626.852539,661.320374 
	C1623.537109,660.763916 1619.998047,659.306213 1618.564575,664.253479 
	C1618.404663,664.805725 1617.187012,665.051514 1616.185913,665.162598 
	C1615.719971,664.711243 1615.527832,664.536682 1615.480103,664.093506 
	C1616.245117,663.175903 1616.865479,662.526855 1617.187378,662.190063 
	C1613.403564,662.190063 1609.679443,662.190063 1605.697998,661.930298 
	C1601.774292,661.670593 1598.108032,661.670593 1594.441772,661.670593 
	C1594.449585,662.206360 1594.457275,662.742126 1594.465088,663.277954 
	C1595.626709,663.503906 1596.788452,663.729858 1598.603394,664.082886 
	C1596.696533,665.723511 1595.409058,666.830994 1594.121704,667.938538 
	C1594.347656,668.402893 1594.573486,668.867188 1594.799316,669.331482 
	C1596.696899,668.701965 1598.594360,668.072449 1600.491943,667.442932 
	C1600.341919,667.033813 1600.191895,666.624695 1600.041870,666.215576 
	C1602.000244,666.215576 1603.958740,666.215576 1605.931274,666.624939 
	C1607.384155,667.348694 1608.836060,667.614319 1610.257080,667.994873 
	C1611.188843,668.244446 1612.076294,668.660156 1612.744019,669.147583 
	C1612.254150,669.788208 1612.003662,670.282715 1611.464600,670.983765 
	C1609.783569,674.792725 1608.390991,678.395020 1606.534180,682.000977 
	C1603.891602,682.054871 1601.713623,682.105164 1599.688965,681.794556 
	C1601.246460,679.590942 1601.687012,677.584900 1598.997192,676.929016 
	C1597.744995,676.623718 1596.094238,677.953125 1594.624146,678.541504 
	C1595.350342,680.143799 1596.076416,681.746155 1596.954590,683.720642 
	C1597.400513,690.755127 1597.807251,697.415466 1597.927002,704.080933 
	C1597.968872,706.411377 1597.303955,708.754456 1596.762939,710.811279 
	C1594.630493,707.648376 1593.285278,703.951660 1590.646606,702.044617 
	C1584.180420,697.371460 1573.782837,702.650513 1571.994141,711.026001 
	C1572.013916,711.018250 1572.016846,710.976135 1571.697266,710.868042 
	C1570.842529,711.459534 1570.307373,712.159119 1569.492798,712.656250 
	C1568.915894,712.103210 1568.618286,711.752563 1568.319336,711.006104 
	C1568.328979,707.942627 1568.339966,705.275085 1568.581299,702.169189 
	C1568.883301,699.505737 1568.955078,697.280579 1569.345459,694.746826 
	C1571.865234,694.181824 1574.066528,693.925537 1575.241089,693.788757 
	C1573.679810,693.277832 1570.530884,693.172546 1569.023560,691.546021 
	C1566.503540,688.826538 1568.021118,682.167908 1571.117920,679.116699 
	C1571.198242,679.192200 1571.396973,679.096375 1571.501709,679.414185 
	C1574.443481,682.634705 1575.594604,681.265320 1576.263428,677.809082 
	C1577.926880,677.846191 1579.323730,678.073608 1582.096436,678.524902 
	C1579.961182,677.251892 1578.929688,676.637024 1577.983398,675.641357 
	C1578.001099,674.488098 1577.933838,673.715515 1577.957764,672.587036 
	C1578.808350,669.907532 1579.567871,667.583984 1580.760620,665.222778 
	C1583.779053,664.616333 1588.241211,664.587158 1588.589355,663.375610 
	C1589.543579,660.055664 1590.047974,654.041260 1588.405273,653.084045 
	C1585.167969,651.197937 1580.359985,652.007568 1575.703125,651.665649 
	C1575.936035,655.884644 1576.108521,659.010864 1576.232788,662.536743 
	C1575.750977,664.908081 1575.317505,666.879761 1574.501831,668.936157 
	C1573.496582,670.298584 1572.298950,671.607849 1572.367188,672.847412 
	C1572.519775,675.620178 1571.397217,676.443665 1568.637817,675.908752 
	C1567.877563,673.113159 1567.394897,670.582703 1567.021484,667.591064 
	C1567.114990,656.422485 1567.099121,645.715088 1567.468994,635.006409 
	C1569.819946,634.689758 1571.785034,634.374390 1574.091064,634.056458 
	C1577.746948,634.053894 1581.062012,634.053894 1583.779419,634.053894 
	C1583.461304,632.048828 1583.177612,630.261963 1582.894043,628.475098 
	C1583.934204,628.327271 1584.974487,628.179382 1586.010986,628.011963 
	C1586.007446,627.992432 1585.967896,627.994873 1585.959839,628.390259 
	C1586.806274,630.441711 1587.442505,632.290710 1588.639282,633.644287 
	C1589.033936,634.090759 1590.875977,633.257629 1592.411377,632.934998 
	C1590.381592,631.817200 1589.180420,631.155762 1587.979248,630.494263 
	C1589.015137,629.598694 1590.097778,628.750000 1591.064575,627.785217 
	C1591.465454,627.385437 1591.618164,626.737122 1592.027100,625.876587 
	C1590.797852,622.132629 1589.425171,618.713806 1588.513184,615.239380 
	C1593.285278,615.135864 1597.607300,614.894897 1601.902710,615.134216 
	C1603.647827,615.231445 1605.334717,616.373718 1606.704590,617.101929 
	C1605.005859,617.933167 1603.650513,618.703186 1602.295166,619.473145 
	C1599.348633,623.894836 1599.721802,624.554871 1605.020508,625.097107 
	C1604.995239,625.524963 1604.985962,625.696106 1604.715088,626.129639 
	C1603.924683,628.016968 1603.395752,629.641907 1602.628296,632.000122 
	C1605.672485,632.000122 1608.030762,632.000122 1610.658203,632.009094 
	C1611.465332,632.021301 1612.003418,632.024536 1612.730713,632.296448 
	C1613.286255,632.795471 1613.652710,633.025879 1614.301636,633.477783 
	C1616.018555,633.814270 1617.453247,633.929199 1619.214844,634.306213 
	C1619.690186,638.528381 1620.129395,642.505127 1619.903931,646.443787 
	C1619.685913,650.252869 1621.036987,651.203796 1624.738892,651.156799 
	C1639.353394,650.971313 1653.972046,651.124207 1668.589355,651.151611 
	C1668.743286,654.755371 1668.897217,658.359253 1669.263428,662.368530 
	C1670.717651,664.300110 1671.959717,665.826294 1673.358154,667.663513 
M1587.693481,681.245728 
	C1589.135986,681.719238 1590.578491,682.192810 1592.257690,682.743958 
	C1592.398438,680.667114 1592.543213,678.531555 1592.687988,676.395996 
	C1592.179321,676.097900 1591.670654,675.799805 1591.162109,675.501709 
	C1589.698853,676.857544 1588.235474,678.213379 1586.156128,679.898743 
	C1584.927002,679.818542 1583.697876,679.738281 1582.468750,679.658020 
	C1582.465454,680.105347 1582.462158,680.552734 1582.458984,681.000061 
	C1583.972656,681.000061 1585.486328,681.000061 1587.693481,681.245728 
M1578.929565,638.444763 
	C1578.082520,638.999268 1577.235596,639.553772 1575.764526,640.516846 
	C1577.757812,640.977295 1578.868042,641.233704 1580.674805,641.651062 
	C1578.719727,643.315491 1577.355835,644.476624 1575.401733,646.140259 
	C1579.302612,646.903381 1582.400024,647.994141 1582.463745,642.359192 
	C1582.635986,646.321594 1584.906006,647.760498 1587.424072,646.225891 
	C1589.143921,645.177917 1589.674072,642.177612 1590.736084,640.050232 
	C1588.345337,639.780029 1585.954590,639.509888 1583.563843,639.239685 
	C1583.525146,639.655701 1583.486450,640.071655 1583.447754,640.487671 
	C1582.177734,639.777405 1580.907593,639.067078 1578.929565,638.444763 
M1599.180664,657.454041 
	C1602.510376,656.949951 1605.840210,656.445862 1609.408691,655.905640 
	C1609.404053,655.894836 1609.191040,655.341858 1608.939331,654.806946 
	C1608.167358,653.166382 1607.757812,650.587830 1606.520630,650.093933 
	C1603.977417,649.078491 1602.061279,650.164917 1601.391602,653.484375 
	C1601.176514,654.551331 1598.152954,655.051636 1596.414185,655.811218 
	C1596.199707,655.416931 1595.985352,655.022705 1595.770874,654.628418 
	C1597.122681,653.371399 1598.474487,652.114380 1600.180054,650.528381 
	C1595.507324,650.611633 1593.546875,651.897705 1593.645752,654.570312 
	C1593.762939,657.733826 1595.720093,658.385193 1599.180664,657.454041 
M1582.819458,697.457947 
	C1588.710693,696.685547 1589.016479,695.448425 1584.513550,689.371094 
	C1583.254272,691.981995 1575.893677,692.237976 1582.819458,697.457947 
M1589.719727,692.287292 
	C1590.208984,693.672302 1590.698120,695.057312 1591.187256,696.442261 
	C1592.467773,695.836792 1594.211182,695.541626 1594.888306,694.531616 
	C1595.471558,693.661438 1594.839478,691.976685 1594.744873,690.651978 
	C1593.249512,691.014099 1591.754028,691.376282 1589.719727,692.287292 
M1591.903687,643.306580 
	C1593.124634,645.799072 1594.831421,647.239319 1596.892944,644.382996 
	C1597.733154,643.218994 1597.423462,641.225159 1597.636841,639.608826 
	C1595.898315,640.134888 1594.140747,640.609192 1592.438843,641.234924 
	C1592.134521,641.346802 1592.060181,642.084534 1591.903687,643.306580 
M1604.573120,668.517700 
	C1604.573120,668.517700 1604.525391,668.519409 1604.573120,668.517700 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1677.719238,678.827148 
	C1676.948975,675.434753 1676.455566,672.133057 1676.022705,668.436157 
	C1682.064209,667.952637 1688.044800,667.833130 1694.026001,667.791321 
	C1696.473511,667.774231 1699.462891,667.274597 1699.208862,671.219421 
	C1698.999268,674.470398 1697.996338,677.178345 1693.365234,676.348267 
	C1691.607544,675.470337 1689.822876,673.699524 1689.061646,674.064392 
	C1687.240112,674.937195 1684.706665,676.328796 1686.998047,679.548584 
	C1686.592773,680.215515 1685.958862,680.600952 1685.026733,680.538452 
	C1684.817749,678.485046 1685.317017,676.745667 1684.851807,675.321350 
	C1684.541870,674.372498 1682.923706,673.518799 1681.762329,673.297058 
	C1680.990356,673.149719 1679.991211,674.192932 1678.163818,675.231995 
	C1680.401489,675.622375 1681.543579,675.821533 1683.934448,676.238586 
	C1681.724609,677.580688 1680.432007,678.365784 1679.139282,679.150879 
	C1678.758179,679.073242 1678.376953,678.995544 1677.719238,678.827148 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2261.968750,869.365479 
	C2265.868896,868.979187 2269.724854,868.979187 2274.607910,868.979187 
	C2273.085449,866.132568 2272.010742,864.123230 2270.953125,861.336670 
	C2270.944336,859.733887 2270.917969,858.908325 2270.943848,858.036621 
	C2270.996094,857.990417 2271.093994,857.890747 2271.093994,857.890747 
	C2273.723389,861.410767 2276.352539,864.930786 2278.611572,867.955078 
	C2277.938721,870.148010 2277.479736,871.643860 2277.035156,873.502075 
	C2273.659424,873.916321 2270.269043,873.968262 2266.375977,873.644897 
	C2264.918945,873.505188 2263.964844,873.740784 2263.010986,873.976318 
	C2262.621338,874.042908 2262.231934,874.109497 2261.560059,874.261597 
	C2261.493164,872.815369 2261.708740,871.283569 2261.968750,869.365479 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2258.156738,837.801331 
	C2258.331543,837.678284 2258.506348,837.555237 2258.955078,837.599609 
	C2260.311768,839.519165 2261.668213,841.167419 2262.387207,843.058228 
	C2262.913818,844.442627 2262.603271,846.145508 2262.336914,847.856079 
	C2262.007568,848.005676 2262.000000,847.998962 2262.109375,847.648804 
	C2261.035400,845.753662 2259.852051,844.208679 2258.435547,842.289917 
	C2258.187256,840.544556 2258.172119,839.172974 2258.156738,837.801331 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2264.878418,852.090942 
	C2264.998047,851.310852 2265.145996,850.521606 2265.507324,848.595581 
	C2266.298828,850.439880 2266.684814,851.339600 2267.041992,852.613647 
	C2267.012695,852.988037 2266.935059,853.049988 2266.684814,852.926147 
	C2265.940918,852.549377 2265.447021,852.296509 2264.953369,852.043579 
	C2264.953369,852.043640 2264.906982,852.081848 2264.878418,852.090942 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2268.019531,853.970825 
	C2268.363281,854.418213 2268.706787,854.865601 2269.037598,855.646606 
	C2269.024414,855.980286 2268.939453,856.060547 2268.692627,856.043335 
	C2268.445801,856.026062 2267.951660,856.050842 2267.951660,856.050842 
	C2267.951660,856.050842 2268.000000,856.002441 2267.992676,855.686401 
	C2267.982178,854.920532 2267.978516,854.470642 2267.986816,854.009338 
	C2267.998535,853.997925 2268.019531,853.970825 2268.019531,853.970825 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2267.051758,852.957520 
	C2267.402344,853.046936 2267.647949,853.247498 2267.923828,853.749695 
	C2268.019531,853.970825 2267.998535,853.997925 2267.728027,854.010010 
	C2267.285400,854.034241 2267.113037,854.046448 2266.940674,854.058716 
	C2266.940674,854.058716 2267.000000,854.004883 2266.979248,853.778442 
	C2266.950684,853.384644 2266.942871,853.217285 2266.935059,853.049988 
	C2266.935059,853.049988 2267.012695,852.988037 2267.051758,852.957520 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2269.068359,855.941772 
	C2269.430420,856.010986 2269.690430,856.199036 2269.952637,856.732788 
	C2270.013184,856.998047 2269.922607,857.097839 2269.673340,857.074646 
	C2269.257080,857.050049 2269.090088,857.048584 2268.923340,857.047119 
	C2268.923340,857.047119 2268.955078,856.992371 2268.963135,856.774658 
	C2268.971191,856.557007 2268.939453,856.060547 2268.939453,856.060547 
	C2268.939453,856.060547 2269.024414,855.980286 2269.068359,855.941772 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2270.061523,856.951233 
	C2270.415527,857.022278 2270.666016,857.212524 2270.978027,857.682983 
	C2271.093994,857.890747 2270.996094,857.990417 2270.744141,857.914062 
	C2270.243164,857.636597 2270.053467,857.389954 2269.922607,857.097839 
	C2269.922607,857.097839 2270.013184,856.998047 2270.061523,856.951233 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2154.328125,760.526245 
	C2153.322266,763.094482 2152.142090,765.301758 2150.328613,767.680420 
	C2145.394287,769.177917 2140.240479,769.443298 2136.981201,772.064148 
	C2131.255371,776.668396 2125.988525,778.969421 2119.105957,775.308044 
	C2118.333008,774.896851 2117.182861,775.193970 2116.207764,775.162537 
	C2116.473877,774.252014 2116.739990,773.341492 2117.187012,772.098633 
	C2127.019287,776.548218 2128.876221,776.146423 2136.563965,768.405029 
	C2137.511719,767.450562 2139.494873,767.099426 2140.965088,767.169312 
	C2145.296387,767.374939 2148.263428,765.707275 2149.957031,761.332031 
	C2151.396240,760.706177 2152.775146,760.435730 2154.328125,760.526245 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2144.000000,748.000000 
	C2146.762939,748.301697 2149.526123,748.603394 2152.572754,748.936035 
	C2152.704102,749.254883 2153.068115,750.139771 2153.737793,751.026062 
	C2154.371338,751.598267 2154.725098,752.155823 2155.021729,752.742432 
	C2155.642334,753.970276 2156.232666,755.213440 2156.835449,756.450317 
	C2155.561768,756.883667 2154.275391,757.284241 2153.019043,757.762878 
	C2152.285156,758.042419 2151.599121,758.447083 2150.564209,759.004150 
	C2149.443115,758.002136 2148.649414,756.791382 2147.539551,754.992188 
	C2146.149170,752.269104 2145.074463,750.134583 2144.000000,748.000000 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1950.195190,662.452271 
	C1950.847290,660.977234 1951.513428,659.813293 1952.072754,658.308716 
	C1956.369019,652.937317 1962.212158,653.813904 1968.350586,654.146729 
	C1970.458008,657.233215 1972.121826,660.254944 1973.389404,663.630676 
	C1966.859131,664.170349 1960.724854,664.356079 1954.590576,664.541809 
	C1953.130127,663.949036 1951.669678,663.356262 1950.195190,662.452271 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1973.395142,668.140015 
	C1972.425903,669.871155 1971.468506,671.609192 1970.484741,673.332153 
	C1968.015503,677.656616 1966.858765,677.814148 1964.343872,675.445618 
	C1961.857788,676.014709 1960.197144,676.812561 1958.611572,676.683716 
	C1952.616333,676.196777 1953.150024,670.183167 1949.502808,666.487427 
	C1951.765137,666.254456 1952.997070,666.127563 1954.906982,666.279053 
	C1958.036499,666.673096 1960.496582,666.697876 1962.937622,666.926147 
	C1966.430786,667.252808 1969.910034,667.728149 1973.395142,668.140015 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1973.623535,667.857788 
	C1969.910034,667.728149 1966.430786,667.252808 1962.937622,666.926147 
	C1960.496582,666.697876 1958.036499,666.673096 1955.288818,666.265015 
	C1954.942627,665.642883 1954.861572,665.320801 1954.670166,664.774170 
	C1960.724854,664.356079 1966.859131,664.170349 1973.454346,663.964172 
	C1973.894165,665.154358 1973.873169,666.365051 1973.623535,667.857788 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2101.498535,944.918457 
	C2105.405273,945.016357 2108.846924,945.174316 2112.221191,945.614075 
	C2111.715332,946.016418 2111.276855,946.136902 2110.838135,946.257446 
	C2109.445068,947.147766 2108.052246,948.037964 2106.503174,949.027771 
	C2104.143799,949.402710 2101.392334,950.153931 2098.637451,950.165771 
	C2096.843262,950.173462 2095.057861,949.058655 2093.238037,948.557739 
	C2092.848145,948.450378 2092.326660,948.822083 2091.624023,949.144043 
	C2088.762939,948.859619 2086.143555,948.404297 2083.212402,947.891357 
	C2082.421875,947.213928 2081.942871,946.594055 2080.694580,944.978638 
	C2088.104980,944.978638 2094.568848,944.978638 2101.498535,944.918457 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1679.157715,679.518677 
	C1680.432007,678.365784 1681.724609,677.580688 1683.934448,676.238586 
	C1681.543579,675.821533 1680.401489,675.622375 1678.163818,675.231995 
	C1679.991211,674.192932 1680.990356,673.149719 1681.762329,673.297058 
	C1682.923706,673.518799 1684.541870,674.372498 1684.851807,675.321350 
	C1685.317017,676.745667 1684.817749,678.485046 1684.630371,680.522339 
	C1682.746948,680.598328 1680.961548,680.242432 1679.157715,679.518677 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1706.480469,647.979370 
	C1699.093384,648.145020 1692.041748,648.145020 1684.989990,648.145020 
	C1684.998901,647.700928 1685.007690,647.256775 1685.016602,646.812683 
	C1692.075684,646.812683 1699.134888,646.812683 1706.647095,646.670288 
	C1707.005371,646.956482 1706.910645,647.385071 1706.480469,647.979370 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1714.779541,642.633667 
	C1715.264526,642.198120 1715.799683,642.156738 1716.737549,642.114258 
	C1716.868164,644.506714 1716.225342,647.160095 1720.390015,647.219360 
	C1718.972778,647.512268 1717.555542,647.805115 1715.793213,648.169312 
	C1715.417236,646.162903 1715.123535,644.595337 1714.779541,642.633667 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1706.299438,646.099670 
	C1706.072998,645.493408 1706.153931,645.003174 1706.407471,644.334290 
	C1707.952026,644.155640 1709.324097,644.155640 1710.696045,644.155640 
	C1710.804810,644.477722 1710.913452,644.799805 1711.022217,645.121887 
	C1710.010864,645.621155 1708.999512,646.120422 1707.579346,646.586426 
	C1706.982666,646.440613 1706.794678,646.328186 1706.299438,646.099670 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1712.551758,645.115967 
	C1712.997803,644.829529 1713.248169,644.892273 1713.694580,644.944397 
	C1713.764648,645.924927 1713.640015,646.910278 1713.515259,647.895630 
	C1713.219971,647.895508 1712.924805,647.895386 1712.629517,647.895203 
	C1712.537964,647.087158 1712.446411,646.279053 1712.551758,645.115967 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1726.052490,644.971252 
	C1726.553223,645.013794 1727.053833,645.056335 1727.082275,645.177734 
	C1726.424194,645.161438 1726.238403,645.066345 1726.052490,644.971252 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1726.044556,645.173828 
	C1726.238403,645.066345 1726.424194,645.161438 1726.826172,645.484619 
	C1726.707153,645.600586 1726.371826,645.488525 1726.044556,645.173828 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1722.465820,647.491455 
	C1722.555420,647.422974 1722.566650,647.693909 1722.566650,647.693909 
	C1722.566650,647.693909 1722.376099,647.559875 1722.465820,647.491455 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2261.316895,825.027039 
	C2254.009766,821.889526 2252.994629,820.080933 2255.222656,812.973938 
	C2256.672852,815.065796 2257.697754,816.544189 2258.557373,817.784119 
	C2259.274658,816.249512 2260.153564,814.368713 2261.156006,812.223938 
	C2259.986328,812.021667 2258.892578,811.832520 2257.143066,811.529968 
	C2258.525635,810.982483 2259.644775,810.120056 2260.194824,810.395142 
	C2262.437256,811.515991 2265.281006,812.509338 2266.460693,814.433838 
	C2268.365234,817.540466 2267.065674,820.827515 2264.197998,823.661011 
	C2263.218994,824.285828 2262.419678,824.601746 2261.316895,825.027039 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2258.114746,837.820435 
	C2258.172119,839.172974 2258.187256,840.544556 2258.244141,842.629517 
	C2258.151123,843.839050 2258.016602,844.335266 2257.882080,844.831482 
	C2257.230469,845.564209 2256.683594,846.742004 2255.902832,846.925537 
	C2254.720947,847.203247 2253.086182,847.181885 2252.202881,846.530151 
	C2251.615723,846.097046 2251.538086,844.030884 2252.061035,843.251709 
	C2252.885010,842.023682 2254.405762,841.263245 2255.772949,840.194336 
	C2255.342529,839.578369 2254.879639,838.915955 2254.206299,837.983765 
	C2253.957275,837.330017 2253.918945,836.945923 2253.932617,836.275757 
	C2254.419189,835.662659 2254.853271,835.335632 2255.497070,835.388062 
	C2256.497314,836.488953 2257.288330,837.210449 2258.079346,837.932007 
	C2258.079346,837.932007 2258.072510,837.839600 2258.114746,837.820435 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2258.264160,837.595337 
	C2257.288330,837.210449 2256.497314,836.488953 2255.765869,835.339111 
	C2256.699951,835.693420 2257.574219,836.476013 2258.264160,837.595337 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2239.448730,755.767578 
	C2238.202393,755.947693 2237.269043,755.884888 2236.335693,755.822083 
	C2236.389648,755.466187 2236.443604,755.110291 2236.497314,754.754395 
	C2237.585449,755.011108 2238.673340,755.267883 2239.448730,755.767578 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1930.271606,603.211182 
	C1928.990723,602.689087 1927.943604,601.836304 1926.896362,600.983582 
	C1927.398071,600.577332 1927.899658,600.171082 1928.401367,599.764832 
	C1929.102661,600.803406 1929.803955,601.841980 1930.271606,603.211182 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1933.993774,708.569702 
	C1933.869751,709.102661 1933.716187,709.170044 1933.322754,709.312866 
	C1932.247070,708.304382 1931.539673,707.077209 1930.537842,706.178101 
	C1929.612915,705.348022 1928.372070,704.869812 1927.746948,704.510559 
	C1927.487549,701.979980 1927.271484,699.871216 1927.017456,697.382812 
	C1928.215454,692.117310 1929.451172,687.231323 1930.650757,682.488892 
	C1934.525146,685.008362 1935.010498,689.645142 1931.599609,694.904053 
	C1935.866821,698.680908 1933.038086,703.728760 1933.993774,708.569702 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2110.844238,946.619324 
	C2111.276855,946.136902 2111.715332,946.016418 2112.496826,945.895874 
	C2113.661621,945.930664 2114.483643,945.965576 2115.716309,945.960632 
	C2116.909668,946.316345 2118.299805,946.620178 2118.375732,947.122498 
	C2118.814209,950.027283 2119.584717,953.110046 2119.017578,955.881897 
	C2118.279541,959.488342 2115.336670,956.756836 2112.977539,956.974243 
	C2108.487061,955.250427 2107.993896,952.390808 2110.844238,946.619324 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1729.968872,667.032593 
	C1731.080566,666.583862 1732.192261,666.135132 1733.674316,665.536804 
	C1733.674316,667.837036 1733.674316,670.116943 1733.674316,673.085022 
	C1731.704346,671.705383 1727.557861,675.562561 1728.225220,669.388916 
	C1728.001465,668.997620 1727.983398,669.012756 1728.359863,669.176697 
	C1728.830200,668.885925 1728.924194,668.431152 1729.009033,667.988159 
	C1729.000000,667.999939 1728.976318,668.017944 1729.342163,668.152344 
	C1729.813232,667.847900 1729.918457,667.408997 1730.010254,666.986084 
	C1729.997070,667.002014 1729.968872,667.032593 1729.968872,667.032593 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1724.545532,670.042236 
	C1724.262573,669.227844 1724.325073,668.485840 1724.300781,667.376709 
	C1724.709717,661.988464 1727.959351,662.416321 1731.877686,663.215698 
	C1731.123657,664.429077 1730.541870,665.365234 1729.964478,666.666992 
	C1729.968872,667.032593 1729.997070,667.002014 1729.695801,666.972778 
	C1729.255249,667.301758 1729.115845,667.659851 1728.976318,668.017944 
	C1728.976318,668.017944 1729.000000,667.999939 1728.692383,667.964966 
	C1728.250854,668.290833 1728.117065,668.651794 1727.983398,669.012756 
	C1727.983398,669.012756 1728.001465,668.997620 1728.010010,668.989258 
	C1726.976074,669.358765 1725.933594,669.736633 1724.545532,670.042236 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1683.013794,615.975952 
	C1685.919067,616.242310 1686.980835,622.058777 1690.150635,618.459473 
	C1691.147095,620.688293 1691.562256,623.816772 1693.210205,624.736572 
	C1695.195435,625.844666 1698.233521,624.985413 1700.804932,625.148560 
	C1702.974487,625.286255 1705.128540,625.667969 1707.681519,626.245422 
	C1708.073608,626.549194 1708.027832,626.944824 1708.027832,626.944824 
	C1701.589722,628.299866 1699.417969,630.569519 1698.839233,637.590881 
	C1698.524048,641.414246 1697.225464,642.980225 1693.214478,642.352722 
	C1692.064331,642.172791 1690.658447,643.627747 1689.265991,644.663940 
	C1688.712402,644.976685 1688.262695,644.958618 1687.197266,644.771729 
	C1686.065186,644.228760 1685.548706,643.854614 1685.046875,643.182251 
	C1685.055542,642.088623 1685.049438,641.293274 1685.371704,640.346558 
	C1687.693237,638.123352 1689.258301,637.537781 1691.700317,640.176636 
	C1693.513916,642.136597 1696.568726,642.520264 1697.406006,638.616638 
	C1698.085571,635.448303 1698.378174,632.197021 1698.947876,628.675659 
	C1698.699219,628.177673 1698.344971,627.986389 1697.600098,627.719238 
	C1695.239868,627.048096 1693.263550,626.474365 1691.302734,625.851624 
	C1689.329834,625.225220 1687.225464,624.808838 1685.460815,623.804443 
	C1684.303589,623.145752 1683.647583,621.606201 1682.767090,620.461182 
	C1686.750977,618.702454 1682.074463,618.014404 1682.304565,616.719727 
	C1682.462402,616.554688 1682.620117,616.389587 1683.013794,615.975952 
M1685.743286,621.405029 
	C1685.570923,621.320374 1685.398560,621.235718 1685.226196,621.151062 
	C1685.261963,621.339355 1685.297852,621.527710 1685.743286,621.405029 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1685.043457,640.497864 
	C1685.049438,641.293274 1685.055542,642.088623 1684.674316,643.163818 
	C1683.492554,644.350281 1682.697876,645.256897 1681.903198,646.163513 
	C1681.903198,646.163513 1681.836426,646.352051 1681.531738,646.517456 
	C1680.203735,646.721619 1679.180298,646.760376 1678.077881,646.529419 
	C1677.450684,645.793701 1676.902466,645.327698 1676.295410,644.493652 
	C1678.566040,637.260803 1678.565308,637.262207 1684.578735,640.598450 
	C1684.682861,640.656311 1684.886353,640.535400 1685.043457,640.497864 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1678.156982,646.799072 
	C1679.180298,646.760376 1680.203735,646.721619 1681.603271,646.587463 
	C1680.852173,647.302673 1679.812866,648.593201 1678.578491,648.819580 
	C1676.333130,649.231140 1673.972046,649.010803 1671.185791,649.134521 
	C1670.784058,648.358032 1670.855713,647.501221 1671.325439,646.307861 
	C1672.499878,645.991638 1673.276367,646.011902 1674.270996,646.326172 
	C1675.711914,646.679749 1676.934448,646.739380 1678.156982,646.799072 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1682.302246,646.078735 
	C1682.697876,645.256897 1683.492554,644.350281 1684.659668,643.462036 
	C1685.548706,643.854614 1686.065186,644.228760 1686.858398,644.763428 
	C1685.656982,645.280579 1684.179077,645.637329 1682.302246,646.078735 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1943.431152,604.260010 
	C1942.177856,603.758118 1941.260010,603.101807 1940.342285,602.445435 
	C1941.469116,602.040649 1942.595947,601.635864 1943.722778,601.231140 
	C1943.737427,602.189270 1943.751953,603.147461 1943.431152,604.260010 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2030.817383,691.727295 
	C2030.532349,689.832703 2030.316040,687.481567 2030.439697,684.983521 
	C2030.815308,686.981262 2030.850830,689.126038 2030.817383,691.727295 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2030.829834,683.828979 
	C2029.993652,683.554016 2029.200562,682.942261 2028.472168,682.012024 
	C2029.315674,682.293091 2030.094360,682.892639 2030.829834,683.828979 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1636.179932,614.325623 
	C1643.413818,614.268005 1650.811890,613.283447 1657.815552,614.524597 
	C1661.596191,615.194580 1664.702271,619.670837 1668.291870,622.745789 
	C1668.461304,625.816711 1668.451538,628.571716 1667.908569,631.758423 
	C1666.421631,632.370056 1665.467896,632.549927 1664.514160,632.729858 
	C1664.525146,633.038574 1664.536133,633.347229 1664.546997,633.655884 
	C1665.706421,633.753784 1666.865723,633.851685 1668.024902,633.949585 
	C1668.261475,637.625671 1668.497925,641.301819 1668.192871,645.432495 
	C1653.610107,645.887085 1639.568848,645.887085 1625.045410,645.887085 
	C1625.045410,641.837341 1625.045410,638.305725 1625.045410,634.022705 
	C1631.527222,634.022705 1638.059082,634.022705 1644.461304,634.022705 
	C1645.368652,627.475464 1643.070068,621.989868 1638.996338,619.996765 
	C1639.000000,620.000610 1639.006470,620.007568 1639.016602,619.662598 
	C1638.356812,618.883423 1637.686890,618.449158 1637.008545,618.007446 
	C1637.000122,618.000061 1636.986206,617.983093 1637.056152,617.663208 
	C1636.810791,616.337463 1636.495361,615.331543 1636.179932,614.325623 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1674.052734,646.032227 
	C1673.276367,646.011902 1672.499878,645.991638 1671.335938,645.997070 
	C1670.579834,642.149597 1670.211426,638.276306 1669.843018,634.403015 
	C1670.258667,634.237732 1670.674316,634.072388 1671.089966,633.907043 
	C1671.961548,634.586487 1672.833130,635.265930 1674.032959,636.201233 
	C1674.032959,635.169373 1674.238159,633.573425 1674.002808,633.505554 
	C1668.645752,631.961487 1670.325684,627.514893 1669.844727,623.508423 
	C1668.451172,620.440857 1667.146362,617.803467 1665.527710,614.531738 
	C1671.089478,614.693420 1676.568970,612.519348 1681.999756,616.457764 
	C1682.074463,618.014404 1686.750977,618.702454 1682.767090,620.461182 
	C1683.647583,621.606201 1684.303589,623.145752 1685.460815,623.804443 
	C1687.225464,624.808838 1689.329834,625.225220 1691.302734,625.851624 
	C1693.263550,626.474365 1695.239868,627.048096 1697.671997,627.963501 
	C1698.370483,628.516602 1698.606445,628.749512 1698.842407,628.982422 
	C1698.378174,632.197021 1698.085571,635.448303 1697.406006,638.616638 
	C1696.568726,642.520264 1693.513916,642.136597 1691.700317,640.176636 
	C1689.258301,637.537781 1687.693237,638.123352 1685.371704,640.346558 
	C1684.886353,640.535400 1684.682861,640.656311 1684.578735,640.598450 
	C1678.565308,637.262207 1678.566040,637.260803 1676.102295,644.776611 
	C1675.329712,645.629028 1674.691162,645.830627 1674.052734,646.032227 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1638.992676,619.992920 
	C1643.070068,621.989868 1645.368652,627.475464 1644.461304,634.022705 
	C1638.059082,634.022705 1631.527222,634.022705 1625.045410,634.022705 
	C1625.045410,638.305725 1625.045410,641.837341 1625.045410,645.887085 
	C1639.568848,645.887085 1653.610107,645.887085 1668.088257,645.866455 
	C1668.586426,646.680298 1668.647705,647.514771 1668.414307,648.710205 
	C1668.119873,649.071045 1668.021973,648.974426 1667.954224,648.653625 
	C1653.648682,648.332825 1639.410889,648.332825 1625.173096,648.332825 
	C1625.173706,648.567322 1625.174438,648.801758 1625.175171,649.036255 
	C1639.437988,649.036255 1653.700928,649.036255 1668.007324,649.052002 
	C1668.050903,649.067810 1668.046387,649.201416 1668.046387,649.201416 
	C1668.188110,649.512207 1668.329834,649.822937 1668.530518,650.642700 
	C1653.972046,651.124207 1639.353394,650.971313 1624.738892,651.156799 
	C1621.036987,651.203796 1619.685913,650.252869 1619.903931,646.443787 
	C1620.129395,642.505127 1619.690186,638.528381 1619.224854,633.914246 
	C1617.721191,631.412781 1616.534424,629.565369 1614.926392,627.062195 
	C1618.042725,626.340271 1621.015747,625.651489 1624.350342,625.039368 
	C1625.064697,626.966003 1625.417480,628.816040 1625.795044,630.795715 
	C1631.347900,630.795715 1636.912354,630.795715 1643.515991,630.795715 
	C1641.832764,626.775818 1640.412720,623.384338 1638.992676,619.992920 
M1622.095581,636.367432 
	C1622.095581,637.923401 1622.095581,639.479370 1622.095581,641.035400 
	C1622.410767,641.020874 1622.725952,641.006409 1623.041260,640.991882 
	C1622.875000,639.288513 1622.708740,637.585205 1622.095581,636.367432 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1668.074585,633.659241 
	C1666.865723,633.851685 1665.706421,633.753784 1664.546997,633.655884 
	C1664.536133,633.347229 1664.525146,633.038574 1664.514160,632.729858 
	C1665.467896,632.549927 1666.421631,632.370056 1667.740479,632.193604 
	C1668.111816,632.587708 1668.118042,632.978271 1668.074585,633.659241 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1668.147705,649.232056 
	C1668.046387,649.201416 1668.050903,649.067810 1668.022217,649.036865 
	C1667.993652,649.005920 1668.021973,648.974426 1668.021973,648.974426 
	C1668.021973,648.974426 1668.119873,649.071045 1668.187012,649.061035 
	C1668.254272,649.051086 1668.249023,649.262634 1668.147705,649.232056 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1901.043823,582.043579 
	C1899.862671,582.807251 1898.681641,583.570923 1897.500488,584.334534 
	C1897.337280,583.757446 1897.173950,583.180359 1897.010620,582.603333 
	C1898.097656,582.354370 1899.184570,582.105347 1900.633057,581.923340 
	C1900.994385,581.990295 1901.043823,582.043579 1901.043823,582.043579 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1900.971802,581.961670 
	C1901.438721,581.649048 1901.928223,581.365051 1902.717285,581.047974 
	C1903.016846,581.014893 1903.002930,581.002075 1902.998047,580.994751 
	C1902.540649,581.318909 1902.088135,581.650330 1901.339722,582.012634 
	C1901.043823,582.043579 1900.994385,581.990295 1900.971802,581.961670 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1902.580566,583.593750 
	C1902.505005,583.645081 1902.471802,583.447327 1902.471802,583.447327 
	C1902.471802,583.447327 1902.656250,583.542419 1902.580566,583.593750 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2315.246338,891.812134 
	C2315.101074,892.072144 2314.911865,892.295715 2314.722656,892.519287 
	C2314.732422,892.304993 2314.742188,892.090698 2314.955566,891.772949 
	C2315.159668,891.669556 2315.202393,891.775635 2315.246338,891.812134 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1740.552734,647.303467 
	C1740.966187,647.254578 1741.228149,647.400146 1741.490112,647.545776 
	C1741.127197,647.529846 1740.764282,647.513916 1740.552734,647.303467 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1953.866699,857.750061 
	C1953.345215,859.962158 1952.823608,862.174255 1952.158691,864.739197 
	C1952.202759,875.397522 1953.465698,873.744812 1943.706421,873.884521 
	C1940.561646,873.929565 1937.414795,873.868103 1934.270508,873.930542 
	C1931.363281,873.988342 1929.439331,873.031860 1929.953125,869.117188 
	C1933.678223,865.436584 1937.061157,862.559204 1942.448364,863.117310 
	C1948.060669,864.442200 1951.423950,862.023010 1953.866699,857.750061 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1942.015015,863.028809 
	C1937.061157,862.559204 1933.678223,865.436584 1930.068848,868.694580 
	C1929.532837,868.869995 1929.306152,868.841309 1928.748413,868.900879 
	C1928.624023,868.554993 1928.830933,868.120911 1929.480591,867.328491 
	C1930.281982,866.299133 1930.640503,865.628174 1930.999023,864.957153 
	C1933.428223,862.038208 1935.857422,859.119263 1938.896729,856.307251 
	C1940.343018,858.619141 1941.178955,860.823975 1942.015015,863.028809 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1955.873901,868.749939 
	C1955.993774,870.212097 1955.757324,871.760681 1955.520996,873.309204 
	C1954.787476,873.071106 1954.054077,872.833069 1953.320557,872.595032 
	C1954.052979,871.342102 1954.785278,870.089233 1955.873901,868.749939 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1816.024414,872.681091 
	C1815.652222,873.219299 1815.052124,873.493469 1814.451904,873.767700 
	C1814.407471,872.803345 1814.363037,871.838989 1814.677246,870.670410 
	C1815.289307,871.116516 1815.542969,871.766846 1816.024414,872.681091 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1820.567627,874.306213 
	C1820.665039,874.244812 1820.711548,874.486694 1820.711548,874.486694 
	C1820.711548,874.486694 1820.470093,874.367615 1820.567627,874.306213 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2196.000000,873.986694 
	C2191.500000,874.409973 2187.007568,875.025513 2182.497803,875.201355 
	C2178.195801,875.369080 2173.867920,874.908508 2169.570068,875.115112 
	C2168.557861,875.163757 2167.622070,876.783447 2166.649658,877.678711 
	C2165.068848,879.134094 2163.486816,880.588318 2161.464600,882.030273 
	C2161.023926,882.017456 2160.824707,881.816162 2160.824707,881.816162 
	C2162.329102,879.754639 2163.833496,877.692993 2165.752930,875.062378 
	C2145.459961,875.062378 2125.697754,875.062378 2105.361572,874.806519 
	C2104.581543,874.279846 2104.316650,874.093079 2104.294922,873.703247 
	C2106.074707,873.277893 2107.552002,873.021301 2109.029785,873.018921 
	C2130.672852,872.983887 2152.315918,872.982788 2173.958984,872.973755 
	C2175.583252,872.973083 2177.207520,872.973694 2178.831787,872.973694 
	C2178.821533,872.649109 2178.811523,872.324585 2178.801270,872.000000 
	C2146.867432,872.000000 2114.933838,872.000000 2083.000000,872.000000 
	C2083.000000,872.000000 2083.000000,871.997314 2082.992188,871.640991 
	C2083.306885,868.868530 2083.629150,866.452393 2083.990723,864.391846 
	C2084.318115,866.636536 2084.605713,868.525574 2084.806641,869.845703 
	C2097.110596,869.845703 2109.056152,869.854065 2121.001709,869.806152 
	C2121.366699,869.804688 2121.729736,869.218079 2122.437500,868.891541 
	C2123.469482,868.870789 2124.157715,868.862793 2125.105957,869.207886 
	C2125.832031,869.708923 2126.297607,869.985901 2126.763428,869.986145 
	C2142.780029,869.993591 2158.796875,870.005859 2174.812744,869.885559 
	C2175.812012,869.878052 2176.803711,868.802429 2177.879883,868.133789 
	C2177.961182,868.043884 2178.128662,867.868774 2178.502930,867.940186 
	C2182.198975,868.646545 2185.521240,869.281433 2188.932373,870.189575 
	C2189.330322,870.285461 2189.639404,870.108093 2189.974365,869.962280 
	C2190.000488,869.993896 2189.940674,869.940063 2190.262695,870.013916 
	C2190.703369,869.705017 2190.822021,869.322205 2190.897949,868.947266 
	C2190.854980,868.955017 2190.787354,868.901001 2191.092773,869.080139 
	C2192.932373,870.835083 2194.466064,872.410889 2196.000000,873.986694 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2196.339111,874.041260 
	C2194.466064,872.410889 2192.932373,870.835083 2190.960938,868.753784 
	C2189.001953,863.025208 2186.120605,867.874878 2183.945068,867.497498 
	C2184.286865,867.113464 2184.628662,866.729431 2184.970459,866.345398 
	C2180.952148,863.162170 2176.933594,859.978943 2173.644531,857.373413 
	C2174.352539,854.666748 2174.752197,853.138306 2175.151855,851.609863 
	C2173.439697,851.734802 2171.727539,851.859680 2170.028320,852.006470 
	C2170.041504,852.028381 2170.083252,852.057861 2170.137207,851.648560 
	C2170.442627,849.786316 2170.693848,848.333435 2170.945312,846.880493 
	C2169.711914,847.021912 2168.479980,847.267273 2167.244873,847.285278 
	C2163.536377,847.339417 2159.826660,847.304749 2155.795166,847.304749 
	C2155.795166,845.185059 2155.795166,843.721008 2155.853516,841.864319 
	C2155.921631,840.942627 2155.931396,840.413696 2156.230957,840.130249 
	C2157.463379,850.703918 2163.584961,841.161804 2166.791992,843.222656 
	C2166.605957,842.225220 2166.336426,841.203491 2165.781494,841.012878 
	C2162.547852,839.902283 2159.247314,838.986633 2156.025391,837.669067 
	C2157.391846,835.820435 2158.702148,834.307983 2160.012451,832.795532 
	C2163.865234,834.529236 2166.751953,837.832153 2169.809814,842.677124 
	C2171.438721,845.258118 2173.683594,847.647644 2176.148926,849.436096 
	C2177.865234,850.680908 2180.551025,850.493958 2182.580811,851.429810 
	C2184.536133,852.331421 2185.034912,856.190308 2188.736328,854.104797 
	C2188.847656,854.392212 2188.350098,854.973816 2188.483887,855.263123 
	C2190.898682,860.478333 2190.015381,867.584106 2197.341064,870.616821 
	C2199.124512,872.693909 2200.530518,874.372986 2201.936523,876.052002 
	C2206.033203,880.480652 2210.129639,884.909302 2214.127686,889.692688 
	C2209.351318,885.682434 2204.680664,881.309692 2199.990479,876.958374 
	C2198.921387,875.966492 2197.784180,875.048096 2196.339111,874.041260 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1964.885254,852.770630 
	C1963.914307,850.210449 1962.994629,847.626099 1962.011475,844.862976 
	C1959.896729,845.408203 1958.052002,845.883850 1956.103149,846.386353 
	C1956.088501,846.196045 1956.000244,845.048706 1955.950684,843.218384 
	C1955.985596,842.108887 1955.981812,841.682373 1955.978027,841.255859 
	C1957.220459,841.942627 1958.462891,842.629395 1959.644775,843.282715 
	C1963.047729,841.660339 1963.919067,839.018860 1963.712646,835.469604 
	C1963.397461,830.052917 1963.975342,824.548767 1963.233643,819.212524 
	C1962.987549,817.442017 1960.067871,815.458252 1957.963135,814.693970 
	C1955.822266,813.916687 1953.179321,814.522217 1950.693237,813.393982 
	C1954.771362,813.168152 1958.849487,812.942322 1963.563721,812.681274 
	C1962.525757,807.191895 1966.183838,801.077087 1961.121826,796.702332 
	C1949.786865,786.906189 1946.299683,785.279480 1942.825073,786.627625 
	C1942.602661,785.167908 1942.378540,783.696594 1942.056641,781.477173 
	C1942.005005,780.440491 1942.051270,780.151733 1942.097656,779.862915 
	C1943.111938,780.516541 1944.126221,781.170166 1946.028687,782.396179 
	C1945.448486,780.059570 1945.131226,778.781799 1944.813965,777.504028 
	C1945.317139,777.303101 1945.820312,777.102173 1946.323608,776.901306 
	C1946.875122,778.585815 1947.387817,780.284363 1947.991089,781.950134 
	C1948.427246,783.154541 1948.668091,784.687256 1949.542603,785.429504 
	C1952.837769,788.225952 1956.117676,791.183289 1959.851440,793.284058 
	C1964.618164,795.966064 1965.822632,799.778320 1965.732300,804.822876 
	C1965.678467,807.834412 1966.890869,810.853027 1967.005981,813.888489 
	C1967.079102,815.816650 1966.182495,817.781555 1965.648438,820.035095 
	C1967.861938,820.543457 1969.654663,820.955139 1971.447266,821.366821 
	C1971.435669,821.670715 1971.424194,821.974609 1971.412598,822.278503 
	C1969.743408,822.568176 1968.074097,822.857849 1965.753784,823.260559 
	C1965.753784,832.823486 1965.753784,842.443359 1965.451904,852.429810 
	C1965.150024,852.796448 1964.936768,852.746521 1964.885254,852.770630 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2053.870850,774.039673 
	C2054.298340,774.061890 2054.725586,774.084167 2055.467285,774.167969 
	C2055.781250,774.229431 2055.921143,774.618225 2055.617676,774.930176 
	C2051.112061,779.654785 2051.070557,781.804993 2055.080078,787.304993 
	C2057.429932,790.528564 2061.147217,790.869934 2065.666504,788.389160 
	C2066.801758,787.765930 2068.063721,787.160461 2069.322754,787.010376 
	C2076.853760,786.112427 2078.776367,783.987793 2078.756836,776.282593 
	C2083.579834,776.175842 2088.402588,776.069092 2093.923340,776.009277 
	C2094.784180,776.337891 2094.947266,776.619507 2094.741943,776.968567 
	C2092.146973,778.025879 2090.004639,779.420410 2087.673096,779.905884 
	C2084.580078,780.549988 2083.790039,782.075317 2083.844971,785.042664 
	C2083.957275,791.124634 2083.686035,797.213745 2083.210449,803.207764 
	C2081.862549,802.996704 2080.877197,802.877686 2079.891846,802.758667 
	C2079.855957,801.898804 2079.819824,801.038879 2079.871094,799.466553 
	C2079.627930,796.691528 2079.297363,794.628906 2079.020996,792.110718 
	C2079.088379,790.136841 2079.101562,788.618591 2079.262939,787.005127 
	C2079.222656,786.884521 2079.034668,786.859131 2078.486816,786.892334 
	C2075.432861,787.906067 2072.697266,788.762451 2070.063477,789.862366 
	C2068.939941,790.331543 2068.028809,791.309082 2067.017090,792.028381 
	C2067.013184,792.001221 2067.063477,792.023193 2067.063477,792.023193 
	C2058.497070,795.066162 2053.741699,789.232971 2048.333984,783.778076 
	C2048.015137,783.020752 2048.036621,782.612488 2048.438477,782.084900 
	C2049.253906,779.658447 2049.689453,777.351257 2050.454102,775.020081 
	C2051.812500,774.677246 2052.841797,774.358459 2053.870850,774.039673 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2079.987305,863.019592 
	C2079.955811,847.762939 2079.924316,832.506348 2079.958984,816.591675 
	C2080.025146,815.933594 2080.045654,815.504211 2080.453369,815.421143 
	C2081.960693,815.320740 2083.060059,815.303406 2084.115479,815.733521 
	C2084.045166,823.484497 2084.119629,830.790466 2083.946045,838.090515 
	C2083.871338,841.236694 2084.483398,842.738281 2088.180420,843.135376 
	C2095.263916,843.896057 2104.415039,852.621948 2101.204102,861.819458 
	C2099.266113,861.819458 2097.131592,861.695068 2095.016846,861.846863 
	C2091.310791,862.112915 2087.616943,862.548218 2083.632812,862.790405 
	C2082.227295,862.785278 2081.107422,862.902405 2079.987305,863.019592 
M2097.583008,851.066833 
	C2094.115479,847.109985 2090.019287,844.432251 2084.466797,844.342041 
	C2084.466797,849.332397 2084.466797,854.012573 2084.466797,858.784302 
	C2090.028076,858.784302 2095.120850,858.784302 2101.010498,858.784302 
	C2099.871094,856.110535 2098.928711,853.899231 2097.583008,851.066833 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2031.067749,850.999756 
	C2030.844971,848.254517 2030.471802,845.511597 2030.429810,842.763550 
	C2030.333740,836.473389 2030.399536,830.180786 2030.399536,823.471008 
	C2029.194458,823.119263 2027.700073,822.683105 2026.205566,822.246948 
	C2026.114746,821.902100 2026.023804,821.557190 2025.932861,821.212280 
	C2027.068848,820.770142 2028.187500,820.001282 2029.343628,819.940125 
	C2035.650024,819.606689 2041.965698,819.443481 2048.278320,819.229858 
	C2050.960449,819.139099 2052.239502,820.332031 2052.180908,823.131165 
	C2052.115479,826.262817 2052.309326,829.399963 2051.927246,832.791260 
	C2051.464355,833.047852 2051.085205,833.043274 2051.064453,832.577881 
	C2051.043701,828.854004 2051.043701,825.595520 2051.043701,821.962524 
	C2045.958252,821.962524 2041.667847,821.962524 2037.245117,821.962524 
	C2037.245117,827.161560 2037.245117,831.712158 2037.245117,835.924377 
	C2040.329102,836.264526 2043.085938,836.568604 2045.834473,837.084961 
	C2045.826172,837.297180 2045.732910,837.711670 2045.532715,837.843506 
	C2045.068726,838.171387 2044.871826,838.420227 2044.546997,838.796631 
	C2044.352539,838.871460 2043.939453,838.934387 2043.666992,838.655212 
	C2041.377930,838.376038 2039.361206,838.376038 2037.000488,838.376038 
	C2037.000488,841.277527 2037.000488,843.681030 2036.853516,846.379639 
	C2034.827026,848.116333 2032.947388,849.558044 2031.067749,850.999756 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2035.457397,758.920959 
	C2036.095825,762.359253 2034.941650,766.796082 2041.503174,766.283630 
	C2041.981812,767.072144 2041.990479,767.536865 2041.557983,768.001709 
	C2039.692139,768.823425 2037.172729,769.497498 2037.038330,770.493347 
	C2036.544556,774.151855 2036.842529,777.917236 2036.842529,782.071472 
	C2039.147949,781.902039 2040.742188,781.784912 2042.448486,782.074097 
	C2042.535034,783.039673 2042.509399,783.598999 2042.483887,784.158325 
	C2033.652710,784.132202 2031.785889,786.058655 2031.834595,795.020874 
	C2031.869873,801.509949 2031.841309,807.999512 2031.841309,814.488831 
	C2031.195435,814.488647 2030.549438,814.488403 2029.903564,814.488220 
	C2029.903564,796.404968 2029.903564,778.321655 2029.776245,759.816284 
	C2031.437744,759.241699 2033.226562,759.089172 2035.457397,758.920959 
M2033.082031,783.604004 
	C2033.082031,776.049011 2033.082031,768.494019 2033.082031,760.939026 
	C2032.761475,760.938721 2032.441040,760.938416 2032.120483,760.938049 
	C2032.120483,768.767395 2032.120483,776.596802 2032.120483,784.426147 
	C2032.424438,784.408875 2032.728271,784.391541 2033.082031,783.604004 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2083.082764,897.711365 
	C2084.368896,898.536987 2085.654785,899.362671 2087.397217,900.481262 
	C2088.575439,899.774109 2090.418701,898.667603 2093.130371,897.039734 
	C2092.840820,899.352173 2092.697021,900.499634 2092.518311,901.926514 
	C2093.351074,901.754150 2094.527344,901.826843 2094.726807,901.420349 
	C2096.655518,897.487610 2099.326904,896.521912 2103.351807,898.121216 
	C2105.177002,898.846313 2107.234863,898.986450 2109.869629,899.533691 
	C2109.869629,900.130310 2109.869629,901.964844 2109.869629,903.878540 
	C2107.561523,903.878540 2105.559814,903.878540 2102.664795,903.878540 
	C2101.503174,902.228577 2099.814209,900.635681 2096.647949,903.874023 
	C2095.137207,905.419373 2091.357910,906.383606 2089.453125,905.586731 
	C2084.622803,903.565857 2083.865479,906.908997 2082.441650,909.517639 
	C2080.758057,903.720093 2075.499023,908.359802 2072.257812,905.801575 
	C2071.958740,905.304077 2072.052246,904.924805 2072.052246,904.924805 
	C2072.752930,903.916382 2074.146729,902.824829 2074.037598,901.913269 
	C2073.475586,897.221313 2075.967529,897.066467 2079.668945,898.429810 
	C2080.210938,899.857056 2080.452148,900.760559 2080.950684,902.629333 
	C2081.963623,900.293152 2082.523193,899.002258 2083.082764,897.711365 
M2084.427734,902.556091 
	C2084.209229,902.443665 2083.998291,902.305969 2083.767090,902.236023 
	C2083.706787,902.217896 2083.582764,902.411133 2083.487793,902.507263 
	C2083.704590,902.618958 2083.921387,902.730652 2084.427734,902.556091 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1923.991089,744.134583 
	C1924.023071,752.024841 1924.055176,759.915039 1923.832764,768.350220 
	C1923.395752,768.948303 1923.213379,769.001587 1923.018311,768.611145 
	C1922.670410,758.598389 1922.254150,749.031311 1922.027710,739.459778 
	C1921.874878,733.003601 1921.994629,732.987915 1928.381592,732.972717 
	C1935.039185,732.956848 1941.696777,732.969177 1948.354248,732.969177 
	C1948.349609,732.440735 1948.344849,731.912354 1948.340088,731.383911 
	C1946.722412,731.050110 1945.104614,730.716248 1942.407349,730.159607 
	C1946.734375,729.688110 1949.851807,729.348450 1952.981934,729.002686 
	C1952.994507,728.996582 1952.996216,729.015930 1952.959106,729.350464 
	C1952.460449,730.596252 1951.998901,731.507446 1951.268433,732.949707 
	C1957.701660,732.656372 1963.498413,732.271729 1969.300049,732.178162 
	C1972.201294,732.131409 1975.111328,732.615906 1978.012451,733.146057 
	C1978.027832,733.811462 1978.048096,734.190857 1977.602539,734.663269 
	C1969.105591,734.866699 1961.074463,734.977173 1952.630859,735.093384 
	C1954.558716,737.809082 1955.729980,739.459106 1956.658813,741.123779 
	C1956.416382,741.138489 1955.951050,740.998047 1955.578369,741.026123 
	C1952.915527,739.044434 1950.625244,737.034668 1947.356079,734.165771 
	C1942.024536,734.165771 1935.122437,734.162781 1928.220337,734.167297 
	C1925.447998,734.169067 1923.803101,735.242554 1924.054077,738.342590 
	C1924.173950,739.824036 1924.022217,741.327515 1924.010498,743.149414 
	C1924.015503,743.696777 1924.003296,743.915649 1923.991089,744.134583 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1924.459229,744.177734 
	C1924.003296,743.915649 1924.015503,743.696777 1924.478516,743.123169 
	C1933.824097,742.608215 1942.720215,742.502258 1951.611694,742.233398 
	C1953.069214,742.189392 1954.505127,741.427979 1955.951050,740.998047 
	C1955.951050,740.998047 1956.416382,741.138489 1957.003784,741.370605 
	C1963.020264,741.685852 1968.449219,741.768921 1974.294678,741.866821 
	C1975.544312,741.857117 1976.377319,741.832642 1977.210449,741.808105 
	C1981.333618,741.974609 1985.765625,743.655334 1988.281616,740.180237 
	C1991.260132,740.881348 1993.650635,741.802917 1996.085327,741.940369 
	C2000.727417,742.202515 2005.394897,742.017334 2010.514160,742.335571 
	C2010.976074,742.822205 2010.975586,742.991150 2010.974976,743.160095 
	C2004.615967,743.382263 1998.256836,743.604370 1991.085815,743.951416 
	C1989.900024,744.821350 1989.526245,745.566284 1989.152588,746.311218 
	C1988.353149,746.262085 1986.854614,746.218567 1986.854126,746.162964 
	C1986.826172,742.686035 1984.077759,743.614685 1982.645874,744.095398 
	C1981.693848,744.415100 1981.183228,746.457642 1980.760376,747.817810 
	C1980.407349,748.953491 1980.468750,750.218018 1979.323608,751.476624 
	C1978.931030,749.158752 1978.538330,746.840942 1978.094604,744.220825 
	C1960.304810,744.220825 1942.615967,744.220825 1924.459229,744.177734 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1951.568359,758.881775 
	C1951.001587,760.525635 1950.092773,762.084351 1949.018555,763.926819 
	C1953.146851,764.599548 1955.333740,763.171448 1955.241821,757.748169 
	C1959.198853,758.619019 1962.669312,759.382751 1966.978271,760.330994 
	C1966.981445,760.328125 1967.895508,759.476807 1969.447510,758.031494 
	C1969.447510,760.921631 1969.447510,763.034302 1969.447510,764.539062 
	C1967.242676,764.539062 1965.236816,764.539062 1962.377563,764.539062 
	C1961.246826,763.102051 1959.940430,761.100891 1956.344238,764.629150 
	C1954.677368,766.264648 1949.841797,769.367249 1946.308105,764.556458 
	C1940.936401,770.164246 1937.779663,763.852600 1933.546265,760.980774 
	C1935.143555,760.822998 1936.740845,760.665283 1938.338013,760.507568 
	C1940.815186,759.741272 1942.891724,757.136841 1946.552979,760.241577 
	C1947.221558,760.808533 1949.624146,759.330627 1951.568359,758.881775 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2142.246582,921.961792 
	C2140.218018,923.301514 2138.375732,924.362915 2136.080811,925.685303 
	C2135.362061,923.286133 2134.920166,921.810425 2134.271729,919.646240 
	C2131.967041,920.972534 2129.989014,922.110779 2128.104004,923.195618 
	C2128.056396,922.945435 2127.875977,921.997742 2127.607910,920.589539 
	C2127.082520,921.254028 2126.837402,921.820862 2126.409912,922.058228 
	C2124.922119,922.883911 2123.370605,923.595398 2121.843506,924.350220 
	C2121.232666,922.451782 2120.015869,920.471130 2120.257568,918.688354 
	C2120.385254,917.747375 2123.019531,916.608276 2124.585449,916.503357 
	C2129.002686,916.207336 2133.452637,916.399902 2137.980957,917.581604 
	C2137.326416,917.748718 2136.671875,917.915894 2135.806396,918.136902 
	C2136.788574,919.686829 2137.715332,921.149048 2139.378174,923.773254 
	C2139.834961,920.402649 2140.102539,918.428406 2140.369873,916.454102 
	C2140.803223,916.145569 2141.236328,915.837036 2141.669434,915.528564 
	C2142.399414,916.942932 2143.129639,918.357300 2144.144287,920.322632 
	C2144.807617,918.409912 2145.149658,917.424500 2145.491455,916.439148 
	C2145.745850,916.467712 2146.000244,916.496277 2146.254639,916.524841 
	C2146.254639,918.887756 2146.254639,921.250671 2146.254639,924.289917 
	C2144.465332,923.069519 2143.449463,922.376526 2142.246582,921.961792 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2141.278076,902.352905 
	C2142.598877,902.918213 2143.897461,903.898865 2145.244629,903.970825 
	C2149.291748,904.187012 2153.357666,904.048767 2158.142090,904.048767 
	C2155.050781,907.307373 2117.599854,908.002930 2112.463867,904.702881 
	C2116.202393,904.500854 2119.939209,904.230652 2123.680420,904.117188 
	C2127.007324,904.016357 2130.364746,904.335938 2133.660889,903.998474 
	C2135.848633,903.774597 2137.951416,902.720642 2140.575684,902.056091 
	C2141.058838,902.074036 2141.278076,902.352905 2141.278076,902.352905 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1923.031006,769.054932 
	C1923.213379,769.001587 1923.395752,768.948303 1923.847046,768.800049 
	C1927.128662,768.759338 1930.141357,768.813721 1933.525146,769.337524 
	C1934.195312,771.098450 1934.494629,772.389893 1934.487671,772.359741 
	C1932.839111,773.633179 1931.933594,774.332581 1930.735229,774.962463 
	C1930.232300,775.557068 1930.022339,776.221252 1929.812256,776.885376 
	C1930.125977,776.970154 1930.439697,777.054932 1930.753296,777.139709 
	C1930.828735,776.419128 1930.904053,775.698486 1931.339844,774.950378 
	C1932.817139,775.730713 1933.933960,776.538513 1934.887329,777.228210 
	C1934.192993,778.975098 1933.647461,780.347900 1933.101807,781.720703 
	C1930.749512,781.869263 1928.397217,782.017822 1925.277344,782.100769 
	C1924.063721,781.971130 1923.617432,781.907104 1923.114746,781.395203 
	C1923.049194,776.983215 1923.040039,773.019104 1923.031006,769.054932 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2054.112793,810.423706 
	C2052.744873,808.743958 2050.722656,807.251465 2050.175049,805.337158 
	C2049.487793,802.934875 2050.009521,800.186951 2050.009521,797.215698 
	C2052.181641,797.088379 2053.786621,796.827393 2055.364746,796.940918 
	C2057.731934,797.111328 2061.945557,797.142700 2062.116211,797.934937 
	C2062.887695,801.515686 2062.568848,805.344849 2062.456787,809.078979 
	C2062.446777,809.412598 2061.101318,809.706116 2059.749756,809.861450 
	C2059.052734,809.301025 2058.981689,808.897339 2059.284180,808.300049 
	C2060.292725,806.038147 2060.928223,803.969849 2061.563721,801.901489 
	C2061.106934,801.677917 2060.650146,801.454346 2060.193359,801.230774 
	C2058.128906,803.487610 2056.064453,805.744507 2053.985352,808.238647 
	C2054.017822,809.125122 2054.065186,809.774353 2054.112793,810.423706 
M2057.690430,801.088745 
	C2057.811035,798.448059 2057.643799,798.405212 2052.440918,799.689026 
	C2052.440918,801.699585 2052.440918,803.669434 2052.440918,805.639221 
	C2052.772949,805.817505 2053.104980,805.995789 2053.437256,806.174011 
	C2054.686768,804.655090 2055.936279,803.136169 2057.690430,801.088745 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2042.478516,784.616760 
	C2042.509399,783.598999 2042.535034,783.039673 2042.509644,781.682434 
	C2042.305420,776.590210 2042.152222,772.295959 2041.999023,768.001648 
	C2041.990479,767.536865 2041.981812,767.072144 2041.913086,766.262939 
	C2042.136475,764.989319 2042.419800,764.060059 2043.058350,761.966797 
	C2043.875488,764.199463 2044.276245,765.294678 2044.581055,767.146240 
	C2044.620972,772.633362 2044.757080,777.364075 2044.905518,782.432678 
	C2044.998657,783.214233 2045.079712,783.657959 2045.160645,784.101685 
	C2044.957642,792.001160 2044.108154,799.966064 2044.896973,807.765198 
	C2045.178345,810.548767 2049.427246,812.931152 2051.595459,815.212769 
	C2048.435547,816.217468 2042.340332,811.743103 2042.183716,806.736084 
	C2041.958374,799.525818 2042.346680,792.296448 2042.478516,784.616760 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2030.998291,851.435913 
	C2032.947388,849.558044 2034.827026,848.116333 2037.176880,846.288391 
	C2039.101685,844.590210 2040.556274,843.278259 2042.252930,842.284424 
	C2041.129639,846.417175 2035.380249,848.105896 2037.057129,853.596985 
	C2041.815674,853.906494 2046.418823,854.205933 2051.061035,854.767639 
	C2051.652832,855.697388 2052.205566,856.364868 2052.758301,857.032349 
	C2053.314697,857.800171 2053.871094,858.567993 2054.250488,859.651001 
	C2053.381104,859.897400 2052.688965,859.828491 2051.599121,859.378418 
	C2049.912109,857.909424 2048.705322,856.036804 2047.315918,855.889954 
	C2043.944824,855.533569 2040.489014,855.980103 2037.067261,856.103149 
	C2035.217773,856.063721 2033.368408,856.024292 2031.045410,855.997559 
	C2030.690796,854.630859 2030.809814,853.251404 2030.998291,851.435913 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2053.044189,874.746399 
	C2052.957031,874.251038 2052.869873,873.755737 2053.338135,873.188843 
	C2054.606201,872.343628 2055.877686,871.606812 2055.931396,870.790039 
	C2056.122559,867.884094 2055.899414,864.950867 2056.110840,862.075378 
	C2056.573486,862.094055 2056.759033,862.065369 2056.944580,862.036682 
	C2057.258789,862.580200 2057.805176,863.104309 2057.852539,863.670227 
	C2058.566895,872.219177 2059.215576,880.773621 2060.236572,889.802002 
	C2060.663330,890.521973 2060.624512,890.747925 2060.369629,890.899719 
	C2060.262451,890.844299 2060.053955,890.965698 2059.608887,890.951477 
	C2057.683350,890.998779 2056.202881,891.060303 2054.551025,890.771362 
	C2054.549072,889.932617 2054.718018,889.444336 2055.191406,888.653564 
	C2055.670898,884.640869 2056.037109,880.922485 2055.880371,877.226257 
	C2055.843750,876.361084 2054.036621,875.570984 2053.044189,874.746399 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1930.280273,755.726685 
	C1929.384644,754.053162 1927.962402,751.985413 1928.498901,751.084717 
	C1929.434082,749.515015 1931.576416,748.664551 1933.212769,747.512573 
	C1933.500488,747.939026 1933.788208,748.365417 1934.075806,748.791870 
	C1932.735596,749.978638 1931.395264,751.165405 1930.055054,752.352173 
	C1930.311768,752.791809 1930.568481,753.231445 1930.825317,753.671082 
	C1932.562134,752.817383 1935.660034,752.146118 1935.803101,751.078796 
	C1936.464233,746.146057 1939.830933,748.426575 1943.352417,747.662537 
	C1942.878784,750.198792 1943.150635,752.872925 1942.000366,753.820129 
	C1940.666382,754.918701 1938.073975,754.439941 1936.045532,754.759399 
	C1934.229492,755.045410 1932.437744,755.485596 1930.280273,755.726685 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2165.360352,919.087158 
	C2163.997803,922.370056 2161.854736,924.064819 2158.626953,923.627380 
	C2157.192627,923.433044 2155.204346,922.856262 2154.651855,921.821045 
	C2154.040527,920.675293 2154.333252,918.514526 2155.087402,917.349121 
	C2155.646729,916.484802 2157.565430,916.478760 2158.891113,916.139343 
	C2159.780762,915.911682 2160.685303,915.679321 2161.595459,915.611084 
	C2162.847900,915.517090 2164.196777,915.328369 2165.320801,915.714722 
	C2165.676025,915.836731 2165.442383,917.671875 2165.360352,919.087158 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2103.992432,873.990295 
	C2104.316650,874.093079 2104.581543,874.279846 2104.893799,874.772827 
	C2097.908936,874.994995 2090.817871,874.994995 2082.660400,874.994995 
	C2082.660400,880.743408 2082.660400,886.088928 2082.148926,891.640869 
	C2081.372803,891.186462 2081.078857,890.535461 2080.849609,889.862305 
	C2080.487061,888.796753 2080.167725,887.716370 2079.829834,886.642334 
	C2083.127197,882.113037 2078.575928,877.160400 2081.205078,872.351074 
	C2082.213867,872.092590 2082.606934,872.044922 2083.000000,871.997314 
	C2083.000000,871.997314 2083.000000,872.000000 2083.081543,872.374756 
	C2084.117432,873.146973 2085.065186,873.869324 2086.026855,873.888855 
	C2092.013672,874.010254 2098.003662,873.974121 2103.992432,873.990295 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2010.706787,750.586060 
	C2011.349365,752.297302 2012.251953,754.173340 2011.717529,755.446106 
	C2011.369995,756.273743 2008.932739,756.489014 2007.426147,756.506653 
	C2004.746094,756.538147 2002.062012,756.211609 1998.579834,754.830688 
	C2000.578003,753.772827 2002.576050,752.714966 2004.574219,751.657104 
	C2004.279907,751.244629 2003.985596,750.832092 2003.691284,750.419617 
	C2004.230835,751.459656 2004.770264,752.499634 2005.289673,753.500732 
	C2007.126831,752.410950 2008.741943,751.452942 2010.706787,750.586060 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2058.910889,808.493652 
	C2058.981689,808.897339 2059.052734,809.301025 2059.309082,809.804993 
	C2058.867920,815.557190 2058.240723,821.209045 2057.613770,826.860962 
	C2057.304199,826.843201 2056.994385,826.825439 2056.684814,826.807678 
	C2056.008057,821.577759 2055.331543,816.347778 2054.383789,810.770752 
	C2054.065186,809.774353 2054.017822,809.125122 2054.370117,808.394470 
	C2056.149902,808.373291 2057.530518,808.433472 2058.910889,808.493652 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2169.447021,886.315186 
	C2174.054443,882.751282 2170.356445,890.353821 2173.717529,888.423706 
	C2175.636475,887.711548 2176.683105,882.608948 2180.141846,886.331848 
	C2180.931641,887.182129 2179.297363,890.284668 2178.997559,891.479309 
	C2173.933594,890.978821 2170.254639,890.615234 2165.746094,890.169617 
	C2167.257324,888.581421 2168.221191,887.568420 2169.447021,886.315186 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1931.028076,775.032104 
	C1931.933594,774.332581 1932.839111,773.633179 1934.487671,772.359741 
	C1934.494629,772.389893 1934.195312,771.098450 1933.967285,769.448914 
	C1936.625854,772.469971 1939.213013,775.849060 1941.948975,779.545532 
	C1942.051270,780.151733 1942.005005,780.440491 1941.961182,781.168335 
	C1940.246582,780.745422 1938.529541,779.883362 1936.931519,779.081055 
	C1935.910400,779.990906 1934.870728,780.917175 1933.466553,781.782104 
	C1933.647461,780.347900 1934.192993,778.975098 1934.887329,777.228210 
	C1933.933960,776.538513 1932.817139,775.730713 1931.352051,774.963806 
	C1931.003662,775.004761 1931.028076,775.032104 1931.028076,775.032104 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2084.159668,815.286133 
	C2083.060059,815.303406 2081.960693,815.320740 2080.446777,815.069580 
	C2080.036133,814.568054 2080.039551,814.335022 2080.042969,814.102051 
	C2079.613525,811.832703 2079.183838,809.563293 2078.243652,806.916260 
	C2076.832764,805.377014 2075.932861,804.215576 2075.032715,803.054138 
	C2076.480225,803.107178 2077.927979,803.160156 2079.633789,802.985901 
	C2080.877197,802.877686 2081.862549,802.996704 2083.224609,803.557495 
	C2083.718994,804.688171 2083.836426,805.377014 2083.895508,806.388733 
	C2083.841553,807.366455 2083.845459,808.021240 2083.584473,808.904907 
	C2082.467041,810.940613 2080.871826,812.838318 2083.942383,814.394165 
	C2083.949463,814.622314 2084.052734,815.066956 2084.099609,815.111816 
	C2084.146729,815.156738 2084.159668,815.286072 2084.159668,815.286133 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2075.037598,923.461060 
	C2076.683838,918.954285 2079.934082,922.014832 2082.302979,921.410095 
	C2082.435791,923.667480 2082.568604,925.924866 2082.701660,928.182251 
	C2081.411133,924.006287 2074.309570,930.308105 2075.037598,923.461060 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2074.097412,797.107178 
	C2071.501221,798.021667 2068.854004,799.788391 2066.321533,799.636841 
	C2063.090332,799.443542 2063.313477,795.973511 2066.920410,792.350891 
	C2067.063477,792.023193 2067.013184,792.001221 2067.368164,792.049438 
	C2068.630371,792.281738 2069.537354,792.465698 2071.081055,792.778931 
	C2068.876465,794.327637 2067.431152,795.342773 2065.986084,796.357971 
	C2066.193604,796.802307 2066.401367,797.246643 2066.609131,797.690918 
	C2068.631592,797.034485 2070.654053,796.377991 2072.916992,795.948853 
	C2073.470703,796.486511 2073.784180,796.796875 2074.097412,797.107178 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2052.730469,856.563660 
	C2052.205566,856.364868 2051.652832,855.697388 2051.066895,854.256348 
	C2051.052979,849.587036 2051.266846,845.677673 2050.993164,841.802734 
	C2050.888916,840.329773 2049.663818,838.936096 2048.930664,837.182983 
	C2048.919434,836.214417 2048.927734,835.569092 2048.967285,834.941040 
	C2048.998779,834.958191 2048.927734,834.966125 2049.208984,834.857605 
	C2049.643066,834.386169 2049.796143,834.023315 2050.103516,833.502808 
	C2050.520996,833.210266 2050.796875,833.109619 2051.085205,833.043274 
	C2051.085205,833.043274 2051.464355,833.047852 2051.650391,833.017883 
	C2052.125244,840.690247 2052.413818,848.392639 2052.730469,856.563660 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2072.098145,904.572144 
	C2072.052246,904.924805 2071.958740,905.304077 2071.992432,905.495605 
	C2069.933838,905.979492 2067.841309,906.271790 2064.795654,906.697266 
	C2066.425293,903.249207 2067.722900,900.503479 2069.020508,897.757690 
	C2069.675537,897.842529 2070.330566,897.927307 2070.985596,898.012146 
	C2071.371826,900.081238 2071.758057,902.150330 2072.098145,904.572144 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2074.645020,802.970093 
	C2075.932861,804.215576 2076.832764,805.377014 2077.885986,806.854675 
	C2076.572266,811.568787 2074.115479,812.135376 2070.148926,808.767212 
	C2068.722900,808.317993 2067.866455,808.164551 2067.004883,808.005859 
	C2067.000000,808.000610 2066.990234,807.993958 2067.057861,807.618408 
	C2067.541504,806.297180 2067.957520,805.351501 2068.373535,804.405823 
	C2071.203369,807.261780 2072.060303,802.479126 2074.645020,802.970093 
M2073.528076,806.921875 
	C2072.854980,807.024109 2072.181885,807.126404 2071.509033,807.228699 
	C2071.550049,807.636658 2071.591309,808.044617 2071.632324,808.452637 
	C2072.935791,808.233093 2074.239014,808.013550 2075.542480,807.793945 
	C2075.108887,807.495056 2074.675537,807.196228 2073.528076,806.921875 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2174.745605,927.501221 
	C2175.070801,927.963562 2175.044189,928.299805 2174.971436,928.886353 
	C2174.290527,929.381226 2173.127686,929.575928 2173.099365,929.877563 
	C2172.566162,935.532837 2169.427002,932.333557 2165.685059,932.249146 
	C2168.480469,930.286926 2170.112549,929.141296 2172.658936,927.353821 
	C2170.142822,927.353821 2168.789062,927.353821 2167.198975,927.353821 
	C2170.498535,923.653381 2170.973145,923.673157 2174.745605,927.501221 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1992.447510,813.761108 
	C1992.596436,814.177490 1992.568115,814.457825 1992.587158,814.494751 
	C1991.269409,815.483276 1989.989746,816.547729 1988.541870,817.278992 
	C1988.290405,817.405945 1986.682861,815.935181 1986.803101,815.525757 
	C1987.376831,813.571289 1988.330566,811.728333 1989.488770,809.064270 
	C1990.921021,811.246155 1991.609863,812.295471 1992.447510,813.761108 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2076.195801,892.138672 
	C2073.782227,893.048950 2071.419678,894.292297 2068.925049,894.673950 
	C2068.139404,894.794189 2066.095703,892.521790 2066.189941,891.486816 
	C2066.488037,888.211365 2069.022705,887.278015 2072.280029,887.741638 
	C2070.956787,888.993103 2069.633545,890.244568 2068.310303,891.496033 
	C2068.464844,891.884216 2068.619141,892.272339 2068.773682,892.660461 
	C2070.563721,892.132996 2072.353760,891.605591 2074.645508,891.187622 
	C2075.496826,891.577576 2075.846436,891.858093 2076.195801,892.138672 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2071.428223,927.502930 
	C2069.622559,927.183594 2068.135010,926.646973 2066.647217,926.110474 
	C2067.435303,924.169250 2068.223389,922.228088 2069.011475,920.286926 
	C2071.182129,922.657593 2075.346191,923.872620 2071.428223,927.502930 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2074.448730,797.159790 
	C2073.784180,796.796875 2073.470703,796.486511 2072.878906,795.619446 
	C2072.967285,789.755066 2076.514160,792.555542 2078.966797,792.566223 
	C2079.297363,794.628906 2079.627930,796.691528 2079.983887,799.162537 
	C2078.273193,798.784729 2076.536621,797.998535 2074.448730,797.159790 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2062.048340,893.513367 
	C2062.107422,894.008301 2062.166748,894.503235 2062.104004,895.690369 
	C2061.872314,897.797180 2061.762451,899.211853 2061.623291,901.004089 
	C2059.489258,901.004089 2057.722168,901.004089 2055.954834,901.004089 
	C2055.954834,901.004089 2056.005615,900.978577 2055.803223,900.662598 
	C2055.743896,899.561157 2055.886963,898.775696 2056.016113,897.997803 
	C2056.002441,898.005493 2055.986572,898.033020 2056.338867,897.911499 
	C2056.796875,897.185425 2056.902588,896.580872 2057.003906,895.988159 
	C2056.999756,896.000061 2056.978271,896.014282 2057.342773,896.145996 
	C2057.810547,895.843628 2057.913574,895.409546 2058.008301,894.987549 
	C2058.000000,894.999695 2057.975830,895.016479 2058.339355,895.130554 
	C2058.807129,894.822327 2058.911377,894.400024 2059.007812,893.988708 
	C2059.000000,893.999695 2058.978027,894.015442 2059.334961,894.127930 
	C2059.800049,893.819214 2059.908447,893.398010 2060.008545,892.988464 
	C2060.000244,893.000061 2059.980957,893.021729 2060.276855,893.252747 
	C2061.064697,893.493591 2061.556396,893.503479 2062.048340,893.513367 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2076.547363,892.203674 
	C2075.846436,891.858093 2075.496826,891.577576 2074.964844,891.009766 
	C2073.703125,886.815735 2076.256348,886.625488 2079.407471,886.628418 
	C2080.167725,887.716370 2080.487061,888.796753 2080.849609,889.862305 
	C2081.078857,890.535461 2081.372803,891.186462 2081.984131,891.937439 
	C2081.682129,892.692871 2080.937744,893.979980 2080.404053,893.897949 
	C2079.180908,893.709961 2078.060791,892.853638 2076.547363,892.203674 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2079.576172,814.038696 
	C2080.039551,814.335022 2080.036133,814.568054 2080.039062,815.152588 
	C2080.045654,815.504211 2080.025146,815.933594 2079.997314,816.146851 
	C2074.426270,816.126221 2068.883057,815.892395 2063.339844,815.658630 
	C2063.370605,815.097534 2063.401611,814.536499 2063.432617,813.975403 
	C2068.658203,813.975403 2073.883789,813.975403 2079.576172,814.038696 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2108.190918,937.195557 
	C2106.656006,938.669006 2105.374268,939.890686 2104.092773,941.112305 
	C2104.132812,941.188538 2104.173096,941.264709 2104.213135,941.340881 
	C2107.842041,937.973083 2111.470947,934.605347 2115.099854,931.237549 
	C2114.837402,930.931885 2114.574951,930.626221 2114.312500,930.320557 
	C2112.356445,932.528259 2110.400146,934.735962 2108.190918,937.195557 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2053.576172,902.395996 
	C2052.763428,902.316895 2052.323975,902.164673 2051.544434,901.936768 
	C2051.138672,898.250427 2051.072998,894.639709 2051.053955,890.580872 
	C2051.533447,889.931824 2051.966553,889.730896 2052.592285,889.372131 
	C2052.784912,889.214355 2052.791260,889.236084 2052.970947,889.561768 
	C2053.353271,890.310547 2053.555908,890.733643 2053.393799,891.427612 
	C2053.352051,894.085449 2053.674805,896.472412 2054.097656,899.384338 
	C2054.568359,900.498230 2054.939453,901.087158 2055.310547,901.676025 
	C2054.856934,901.891602 2054.403320,902.107178 2053.576172,902.395996 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2067.009766,808.011108 
	C2067.866455,808.164551 2068.722900,808.317993 2069.786133,808.728516 
	C2068.619385,809.562256 2067.274414,810.416626 2065.856445,810.562988 
	C2065.368652,810.613403 2063.991211,808.460815 2064.235840,808.063904 
	C2065.219971,806.467163 2066.680420,805.163879 2068.168945,804.078979 
	C2067.957520,805.351501 2067.541504,806.297180 2066.921387,807.681152 
	C2066.814697,808.083313 2066.912109,808.047180 2067.009766,808.011108 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2055.779785,901.412903 
	C2057.722168,901.004089 2059.489258,901.004089 2061.623291,901.004089 
	C2061.762451,899.211853 2061.872314,897.797180 2061.991699,895.919678 
	C2062.655273,897.269714 2063.613037,899.054932 2063.782471,900.912048 
	C2063.834229,901.480957 2061.753174,902.737061 2060.597656,902.804871 
	C2058.958252,902.901123 2057.271484,902.188660 2055.779785,901.412903 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2030.051514,750.168701 
	C2030.730103,747.935608 2031.374512,746.117981 2032.018921,744.300354 
	C2032.311768,744.361572 2032.604614,744.422791 2032.897461,744.484009 
	C2032.897461,748.458130 2032.897461,752.432251 2032.897461,756.406372 
	C2032.678711,756.502502 2032.460083,756.598633 2032.241333,756.694824 
	C2031.710205,755.332886 2031.179077,753.970947 2030.366943,752.429565 
	C2030.063232,751.694824 2030.040405,751.139526 2030.051514,750.168701 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2082.992188,871.640991 
	C2082.606934,872.044922 2082.213867,872.092590 2081.529297,872.150208 
	C2080.839844,869.362854 2080.441895,866.565491 2080.015625,863.393860 
	C2081.107422,862.902405 2082.227295,862.785278 2083.656006,863.071167 
	C2083.960205,863.661499 2083.955811,863.848877 2083.951172,864.036255 
	C2083.629150,866.452393 2083.306885,868.868530 2082.992188,871.640991 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2083.008545,897.427917 
	C2082.523193,899.002258 2081.963623,900.293152 2080.950684,902.629333 
	C2080.452148,900.760559 2080.210938,899.857056 2079.982910,898.605408 
	C2080.975586,897.886292 2081.954834,897.515381 2083.008545,897.427917 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2022.534424,855.640869 
	C2022.128906,855.240540 2022.114136,854.926514 2022.058105,854.377930 
	C2024.455933,854.480835 2026.861694,854.819885 2029.155151,855.587708 
	C2027.014893,855.919495 2024.986816,855.822510 2022.534424,855.640869 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2140.943359,901.939697 
	C2142.190918,900.169006 2143.553711,898.532654 2144.916748,896.896240 
	C2145.410400,897.394958 2145.904053,897.893616 2146.397949,898.392334 
	C2144.916016,899.659058 2143.433838,900.925781 2141.614990,902.272705 
	C2141.278076,902.352905 2141.058838,902.074036 2140.943359,901.939697 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2055.458984,901.602661 
	C2054.939453,901.087158 2054.568359,900.498230 2054.244873,899.082031 
	C2054.871582,898.166443 2055.450928,898.078247 2056.030029,897.990112 
	C2055.886963,898.775696 2055.743896,899.561157 2055.816895,900.687622 
	C2055.998047,901.287292 2055.856201,901.454224 2055.458984,901.602661 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2062.511719,893.464050 
	C2061.556396,893.503479 2061.064697,893.493591 2060.248779,892.909180 
	C2059.968018,891.878296 2060.010986,891.421997 2060.053955,890.965698 
	C2060.053955,890.965698 2060.262451,890.844299 2060.830078,890.959290 
	C2062.007812,890.993103 2062.618164,890.911926 2063.228271,890.830811 
	C2063.143799,891.692078 2063.059570,892.553406 2062.511719,893.464050 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2045.512939,784.117188 
	C2045.079712,783.657959 2044.998657,783.214233 2045.302002,782.405396 
	C2046.477051,782.094971 2047.267700,782.149597 2048.058350,782.204224 
	C2048.036621,782.612488 2048.015137,783.020752 2047.986816,783.736206 
	C2047.275024,784.073181 2046.570190,784.102966 2045.512939,784.117188 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2018.564209,742.016541 
	C2018.984619,742.098145 2018.984009,742.568359 2018.987305,742.803589 
	C2017.281738,743.114624 2015.572632,743.190491 2013.440674,742.939209 
	C2013.017334,742.445129 2013.017212,742.278137 2013.017212,742.111206 
	C2014.726074,742.052490 2016.434814,741.993713 2018.564209,742.016541 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2056.838623,861.703979 
	C2056.759033,862.065369 2056.573486,862.094055 2055.836426,862.069031 
	C2054.844971,861.685852 2054.405518,861.356384 2053.966064,861.026855 
	C2053.956543,860.842163 2053.946777,860.657349 2053.988770,860.199768 
	C2054.937744,860.408447 2055.835205,860.889893 2056.838623,861.703979 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2012.678833,742.048828 
	C2013.017212,742.278137 2013.017334,742.445129 2013.018066,742.862549 
	C2012.454712,743.226929 2011.890747,743.340881 2011.150879,743.307434 
	C2010.975586,742.991150 2010.976074,742.822205 2010.977295,742.399902 
	C2011.432129,742.093201 2011.886353,742.039856 2012.678833,742.048828 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1989.454468,746.180298 
	C1989.526245,745.566284 1989.900024,744.821350 1990.622314,744.032288 
	C1990.566040,744.675293 1990.161255,745.362366 1989.454468,746.180298 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2115.552734,926.602173 
	C2116.074219,926.597107 2116.391602,926.793823 2116.709229,926.990601 
	C2116.427979,927.260498 2116.146729,927.530334 2115.865234,927.800171 
	C2115.693115,927.468079 2115.520996,927.136047 2115.552734,926.602173 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2063.081299,890.522705 
	C2062.618164,890.911926 2062.007812,890.993103 2060.937256,891.014709 
	C2060.624512,890.747925 2060.663330,890.521973 2060.604248,890.100586 
	C2061.388184,890.020813 2062.161133,890.117676 2063.081299,890.522705 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2158.780273,883.800293 
	C2159.270752,883.200195 2159.760986,882.600159 2160.538086,881.908142 
	C2160.824707,881.816162 2161.023926,882.017456 2161.129883,882.116089 
	C2160.781982,882.852051 2160.327881,883.489319 2159.452637,884.084473 
	C2159.031738,884.042480 2158.780273,883.800232 2158.780273,883.800293 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2154.235352,889.382568 
	C2154.256592,888.993652 2154.484131,888.767822 2154.711914,888.541931 
	C2154.621826,888.876465 2154.531738,889.210999 2154.235352,889.382568 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2159.154297,884.166687 
	C2159.049561,884.455078 2158.822266,884.619263 2158.594727,884.783447 
	C2158.548828,884.550842 2158.502686,884.318298 2158.618652,883.942993 
	C2158.780273,883.800232 2159.031738,884.042480 2159.154297,884.166687 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2156.393799,886.651855 
	C2156.372559,886.435974 2156.465088,886.359680 2156.557617,886.283447 
	C2156.633057,886.376160 2156.774658,886.476196 2156.765625,886.559570 
	C2156.756104,886.645325 2156.599609,886.714783 2156.393799,886.651855 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2151.752686,890.773926 
	C2152.063232,890.636292 2152.373779,890.498657 2152.684326,890.360962 
	C2152.704834,890.591309 2152.725342,890.821777 2152.393799,891.052246 
	C2152.041992,891.052307 2151.752686,890.773926 2151.752686,890.773926 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2152.183594,891.194763 
	C2152.086670,891.506409 2151.848633,891.675537 2151.610352,891.844666 
	C2151.552734,891.596191 2151.494873,891.347717 2151.594971,890.936584 
	C2151.752686,890.773926 2152.041992,891.052307 2152.183594,891.194763 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2148.673096,894.664307 
	C2148.918701,894.493164 2149.164307,894.322021 2149.409912,894.150879 
	C2149.467041,894.411316 2149.524414,894.671631 2149.265625,894.941345 
	C2148.949463,894.950745 2148.673096,894.664307 2148.673096,894.664307 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2149.087891,895.093628 
	C2149.065918,895.517578 2148.864014,895.764648 2148.286865,895.970764 
	C2147.953369,895.963745 2147.689697,895.703491 2147.689697,895.703491 
	C2147.805420,895.388245 2147.999268,895.132202 2148.472168,894.799805 
	C2148.673096,894.664307 2148.949463,894.950745 2149.087891,895.093628 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2148.082031,896.096924 
	C2147.974121,896.396606 2147.737061,896.563110 2147.500244,896.729614 
	C2147.450928,896.487244 2147.401611,896.244751 2147.520996,895.852905 
	C2147.689697,895.703491 2147.953369,895.963745 2148.082031,896.096924 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2037.078491,856.560181 
	C2040.489014,855.980103 2043.944824,855.533569 2047.315918,855.889954 
	C2048.705322,856.036804 2049.912109,857.909424 2051.250000,859.384521 
	C2051.070557,863.343262 2050.842773,866.914673 2050.623535,870.358459 
	C2050.036865,870.583008 2049.719482,870.828247 2049.434570,870.794922 
	C2045.416382,870.325012 2039.784912,871.209656 2037.830200,868.938293 
	C2035.760498,866.533325 2037.189087,861.117737 2037.078491,856.560181 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2053.033691,875.229858 
	C2054.036621,875.570984 2055.843750,876.361084 2055.880371,877.226257 
	C2056.037109,880.922485 2055.670898,884.640869 2054.837891,888.663330 
	C2053.717285,889.062378 2053.254395,889.149231 2052.791260,889.236084 
	C2052.791260,889.236084 2052.784912,889.214355 2052.772461,889.214111 
	C2052.847656,884.713623 2052.935303,880.213501 2053.033691,875.229858 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2053.647949,861.174194 
	C2054.405518,861.356384 2054.844971,861.685852 2055.558838,862.021667 
	C2055.899414,864.950867 2056.122559,867.884094 2055.931396,870.790039 
	C2055.877686,871.606812 2054.606201,872.343628 2053.460938,873.021362 
	C2053.128906,869.057495 2053.229492,865.189514 2053.647949,861.174194 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1680.158691,548.791687 
	C1681.486816,548.917358 1682.416260,549.077148 1683.345581,549.236938 
	C1683.195801,549.588196 1683.046021,549.939453 1682.896240,550.290710 
	C1681.850830,549.802429 1680.805298,549.314087 1680.158691,548.791687 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1552.955200,605.046936 
	C1553.923950,596.923401 1553.924438,597.005920 1561.933105,596.957397 
	C1580.974976,596.842163 1600.016113,596.640869 1619.456665,596.698730 
	C1619.863037,597.199768 1619.896118,597.473206 1620.033691,598.339844 
	C1620.143066,600.261230 1620.173462,601.586365 1620.203857,602.911499 
	C1620.008545,605.472229 1619.813110,608.032898 1619.942993,610.844482 
	C1618.484497,611.756409 1616.700806,612.417542 1614.040039,613.004883 
	C1602.394409,613.051025 1591.625732,613.170959 1580.459473,613.257080 
	C1574.357178,613.318665 1565.032227,619.703186 1565.012329,624.378967 
	C1564.950439,638.928467 1565.418579,653.480103 1565.690674,668.494263 
	C1565.724609,673.577576 1565.749146,678.197571 1565.372314,682.884155 
	C1562.545898,683.361328 1560.863770,683.075989 1560.748169,679.909180 
	C1560.714844,678.998718 1558.943115,678.151794 1557.968506,677.275696 
	C1557.732300,677.594238 1557.496094,677.912842 1557.260010,678.231384 
	C1557.758301,679.757690 1558.256714,681.284058 1558.309937,682.850403 
	C1554.126709,683.010254 1550.388550,683.130066 1546.423096,682.965637 
	C1546.784668,681.137390 1547.373535,679.593445 1547.871826,678.286926 
	C1544.072632,675.323242 1546.985840,670.762817 1545.747803,667.190002 
	C1545.893555,659.839294 1546.039307,652.488647 1546.656738,644.666016 
	C1551.583984,643.982849 1556.039429,643.771667 1560.494995,643.560486 
	C1560.465820,643.349487 1560.436768,643.138489 1560.407593,642.927490 
	C1555.689331,641.871216 1550.970947,640.814941 1546.252686,639.758667 
	C1546.043213,629.349121 1545.726318,618.940247 1545.670166,608.529968 
	C1545.642700,603.447021 1548.007446,602.225586 1552.782959,605.155457 
	C1554.191406,606.028198 1555.171509,606.446655 1556.151489,606.865112 
	C1556.261719,606.504578 1556.372070,606.144043 1556.482300,605.783508 
	C1555.306519,605.537964 1554.130859,605.292480 1552.955200,605.046936 
M1568.232544,604.963196 
	C1567.844238,604.964233 1567.455811,604.965271 1566.488525,604.623352 
	C1566.197876,603.797729 1565.907227,602.972168 1566.214478,601.808228 
	C1566.295532,601.026489 1566.376709,600.244812 1566.457764,599.463074 
	C1566.116089,599.430298 1565.774414,599.397583 1565.432861,599.364868 
	C1565.278687,600.205017 1565.124634,601.045227 1564.261597,601.958618 
	C1563.197754,602.821594 1562.133911,603.684570 1560.307617,604.420532 
	C1559.765381,603.741638 1559.223022,603.062744 1558.143677,601.711426 
	C1558.143677,604.718140 1558.143677,606.797852 1558.143677,608.964417 
	C1559.173096,608.964417 1560.093750,608.964417 1561.491211,609.487122 
	C1563.296997,608.978516 1565.102661,608.469910 1567.605713,607.975159 
	C1568.069824,607.983704 1568.533813,607.992249 1569.539185,608.303589 
	C1571.658813,610.096558 1573.323120,609.281860 1574.181152,607.093567 
	C1574.621826,605.969727 1574.143066,604.485352 1574.079712,603.163879 
	C1572.324707,603.757629 1570.569702,604.351440 1568.232544,604.963196 
M1552.557251,678.920776 
	C1552.581909,678.969177 1552.608521,679.060547 1552.630981,679.059570 
	C1553.009399,679.042725 1553.387329,679.014343 1553.765381,678.988525 
	C1553.762451,678.949585 1553.759644,678.910645 1553.756836,678.871643 
	C1553.460571,678.895203 1553.164429,678.918701 1552.557251,678.920776 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1638.996338,619.996765 
	C1640.412720,623.384338 1641.832764,626.775818 1643.515991,630.795715 
	C1636.912354,630.795715 1631.347900,630.795715 1625.795044,630.795715 
	C1625.417480,628.816040 1625.064697,626.966003 1624.160889,624.788330 
	C1622.795654,624.301697 1621.981445,624.142700 1621.028076,623.754150 
	C1620.591431,623.339844 1620.293823,623.155090 1619.993774,622.988403 
	C1619.991211,623.006531 1620.027710,623.007019 1620.035645,622.634705 
	C1619.984375,620.271179 1619.924927,618.279907 1619.913574,615.924194 
	C1619.987427,615.315918 1620.013306,615.072144 1620.039062,614.828369 
	C1621.106445,614.779419 1622.173706,614.730530 1623.640259,615.098389 
	C1624.763672,616.248047 1625.487915,616.981018 1626.212158,617.713989 
	C1626.841431,616.847961 1627.470825,615.981873 1628.100098,615.115784 
	C1629.795410,615.223816 1631.490723,615.331848 1633.646729,615.818970 
	C1635.067017,616.793030 1636.026611,617.388062 1636.986206,617.983093 
	C1636.986206,617.983093 1637.000122,618.000061 1637.009399,618.333862 
	C1637.681274,619.114258 1638.343872,619.560913 1639.006470,620.007568 
	C1639.006470,620.007568 1639.000000,620.000610 1638.996338,619.996765 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1619.776123,614.667419 
	C1620.013306,615.072144 1619.987427,615.315918 1619.535522,615.871582 
	C1613.472778,616.322937 1615.062378,620.156311 1615.070190,622.617371 
	C1617.105103,622.777344 1618.566406,622.892151 1620.027710,623.007019 
	C1620.027710,623.007019 1619.991211,623.006531 1619.924561,623.246094 
	C1620.294434,623.651611 1620.730835,623.817627 1621.167236,623.983704 
	C1621.981445,624.142700 1622.795654,624.301697 1623.799316,624.711670 
	C1621.015747,625.651489 1618.042725,626.340271 1614.926392,627.062195 
	C1616.534424,629.565369 1617.721191,631.412781 1618.897949,633.652161 
	C1617.453247,633.929199 1616.018555,633.814270 1614.348877,633.064819 
	C1613.137817,631.231445 1612.161987,630.032471 1611.177856,628.527344 
	C1611.564087,627.562622 1611.958618,626.904175 1613.156738,624.904846 
	C1610.966919,626.173035 1609.994385,626.736267 1609.021729,627.299561 
	C1609.596069,627.964844 1610.170532,628.630127 1610.912109,629.535645 
	C1611.566895,630.526489 1612.054199,631.277161 1612.541504,632.027832 
	C1612.003418,632.024536 1611.465332,632.021301 1610.304077,631.883667 
	C1608.112915,629.788574 1606.544800,627.827942 1604.976562,625.867310 
	C1604.985962,625.696106 1604.995239,625.524963 1605.394531,625.068848 
	C1606.224487,622.922424 1606.664551,621.060974 1607.368164,619.411804 
	C1608.580078,621.391113 1609.528564,623.158142 1610.477051,624.925171 
	C1611.644653,624.035889 1612.819702,623.155701 1613.954468,622.226379 
	C1614.003418,622.186340 1613.682129,621.694214 1613.427979,621.213196 
	C1612.482422,621.696289 1611.563721,622.165588 1610.645020,622.634888 
	C1610.319214,622.215027 1609.993408,621.795166 1609.667480,621.375305 
	C1610.921631,620.342224 1612.175659,619.309143 1613.842773,617.935791 
	C1612.405396,617.614807 1610.965820,617.293457 1609.638672,616.568359 
	C1611.283203,615.576172 1612.815186,614.987610 1614.347168,614.399048 
	C1616.069214,614.434814 1617.791138,614.470642 1619.776123,614.667419 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1614.299316,614.189209 
	C1612.815186,614.987610 1611.283203,615.576172 1609.343384,616.519592 
	C1608.305542,616.929871 1607.675537,616.985291 1607.036133,617.031982 
	C1607.026611,617.023254 1607.048096,617.040588 1607.048096,617.040588 
	C1605.334717,616.373718 1603.647827,615.231445 1601.902710,615.134216 
	C1597.607300,614.894897 1593.285278,615.135864 1588.070801,615.157593 
	C1585.085083,615.047607 1583.002197,614.963806 1580.925537,614.614746 
	C1580.906738,613.996582 1580.881836,613.643738 1580.856934,613.290894 
	C1591.625732,613.170959 1602.394409,613.051025 1613.631714,613.029785 
	C1614.109985,613.419312 1614.160400,613.702942 1614.299316,614.189209 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1627.789429,615.024902 
	C1627.470825,615.981873 1626.841431,616.847961 1626.212158,617.713989 
	C1625.487915,616.981018 1624.763672,616.248047 1624.037109,615.163452 
	C1625.182861,614.852478 1626.330811,614.893188 1627.789429,615.024902 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1637.056152,617.663208 
	C1636.026611,617.388062 1635.067017,616.793030 1634.004639,615.844971 
	C1634.462769,615.026062 1635.023804,614.560120 1635.882446,614.209900 
	C1636.495361,615.331543 1636.810791,616.337463 1637.056152,617.663208 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1683.499878,614.155518 
	C1683.587158,614.286987 1683.502686,614.473389 1683.292725,614.940308 
	C1683.220825,614.883972 1683.274414,614.547180 1683.499878,614.155518 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1620.371582,602.640259 
	C1620.173462,601.586365 1620.143066,600.261230 1620.091064,598.541809 
	C1620.225952,599.554688 1620.382690,600.961914 1620.371582,602.640259 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1765.521240,586.449280 
	C1765.531250,586.711609 1765.412964,586.797363 1765.294556,586.883057 
	C1765.208984,586.764893 1765.123413,586.646729 1765.037720,586.528564 
	C1765.156128,586.443298 1765.274414,586.357971 1765.521240,586.449280 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1815.085693,606.062866 
	C1815.767212,605.011536 1816.438843,604.277039 1817.110474,603.542542 
	C1817.285400,603.685364 1817.460205,603.828247 1817.635132,603.971069 
	C1816.927246,605.324890 1816.219238,606.678772 1815.511353,608.032593 
	C1815.427734,607.973389 1815.343994,607.914124 1815.225830,607.830505 
	C1815.177734,607.365295 1815.126831,606.872498 1815.085693,606.062866 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1818.539062,608.604004 
	C1818.458008,608.663208 1818.427979,608.436829 1818.427979,608.436829 
	C1818.427979,608.436829 1818.620117,608.544739 1818.539062,608.604004 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1812.233276,629.469910 
	C1812.388672,628.088745 1812.543945,626.707581 1812.742188,624.945679 
	C1814.560669,624.854126 1816.755005,624.743652 1819.032471,624.628967 
	C1819.204346,627.077271 1819.337402,628.971619 1819.490112,631.145630 
	C1817.513184,630.670471 1815.236328,630.123230 1812.560547,629.507996 
	C1812.161743,629.440063 1812.233276,629.469910 1812.233276,629.469910 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1811.890869,629.241760 
	C1812.233276,629.469910 1812.161743,629.440063 1812.186768,629.409790 
	C1811.834473,629.914062 1811.549683,630.790771 1811.062988,630.922607 
	C1809.138428,631.444275 1807.144897,631.963074 1805.191284,631.913513 
	C1804.799438,631.903625 1803.738159,628.835876 1804.258545,628.163452 
	C1805.511230,626.545349 1807.518188,625.511230 1809.221069,624.241577 
	C1809.515259,624.667358 1809.809448,625.093201 1810.103638,625.518982 
	C1808.714600,626.615295 1807.325684,627.711670 1805.936646,628.807983 
	C1806.088623,629.214172 1806.240723,629.620361 1806.392700,630.026489 
	C1808.111328,629.688843 1809.829956,629.351257 1811.890869,629.241760 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1735.206909,625.124512 
	C1738.542480,627.467896 1738.993286,629.933960 1735.075439,632.762939 
	C1731.593018,632.870422 1728.864746,632.863708 1726.139160,632.574463 
	C1726.293701,631.947815 1726.445557,631.603882 1726.876343,631.098511 
	C1729.734253,628.924194 1732.313477,626.911377 1734.892578,624.898621 
	C1734.892578,624.898621 1735.097168,625.056335 1735.206909,625.124512 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1726.597412,631.259888 
	C1726.445557,631.603882 1726.293701,631.947815 1726.086182,632.877686 
	C1726.019531,633.668274 1726.008545,633.873108 1725.997559,634.077881 
	C1725.080322,633.950745 1723.376953,633.891602 1723.359131,633.686523 
	C1722.640503,625.383606 1715.564087,628.834167 1711.323730,627.153137 
	C1710.608032,626.869446 1709.693115,627.088379 1708.448975,627.009888 
	C1708.027832,626.944824 1708.073608,626.549194 1708.066895,626.350098 
	C1712.221436,625.914856 1716.413208,625.890015 1720.522827,625.295471 
	C1721.563965,625.144897 1722.342651,623.179138 1723.671021,622.309448 
	C1724.170410,622.815674 1724.240723,623.059814 1724.231567,623.700928 
	C1724.104126,625.990112 1723.996094,627.885254 1724.087769,629.770691 
	C1724.098511,629.993835 1725.202515,630.163879 1725.953247,630.624390 
	C1726.270386,631.013550 1726.433960,631.136719 1726.597412,631.259888 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1714.040527,635.008789 
	C1713.355713,628.946045 1717.424194,631.146179 1720.440308,631.071411 
	C1720.774292,633.450684 1721.067993,635.542175 1721.436523,638.167175 
	C1719.615845,637.391296 1718.170654,636.775391 1716.223755,635.825684 
	C1715.161499,635.330872 1714.601074,635.169861 1714.040527,635.008789 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1713.653809,635.092285 
	C1714.601074,635.169861 1715.161499,635.330872 1715.851440,635.668213 
	C1713.553345,636.771362 1711.170410,638.286194 1708.687012,638.474670 
	C1705.658813,638.704407 1705.122437,636.021057 1706.343018,633.959595 
	C1707.239990,632.444519 1709.359009,631.653015 1710.933350,630.538940 
	C1711.215942,630.928406 1711.498535,631.317871 1711.781128,631.707336 
	C1710.510132,632.918640 1709.239258,634.130005 1707.968262,635.341370 
	C1708.142822,635.703918 1708.317505,636.066467 1708.492065,636.429016 
	C1710.083740,636.011230 1711.675293,635.593506 1713.653809,635.092285 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1726.299072,634.286255 
	C1726.008545,633.873108 1726.019531,633.668274 1726.083374,633.160278 
	C1728.864746,632.863708 1731.593018,632.870422 1734.784058,632.935181 
	C1732.364746,633.493652 1729.482666,633.994141 1726.299072,634.286255 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1740.196533,638.542236 
	C1740.304688,638.734924 1740.399414,639.180237 1740.314453,639.715820 
	C1740.150757,639.469055 1740.166992,639.131958 1740.196533,638.542236 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1724.311035,623.304016 
	C1724.240723,623.059814 1724.170410,622.815674 1724.042603,622.194580 
	C1725.706787,621.553650 1727.428345,621.289490 1729.526123,620.919373 
	C1732.009277,621.230774 1734.893921,620.500916 1734.853394,624.520996 
	C1732.313477,626.911377 1729.734253,628.924194 1726.876221,631.098450 
	C1726.433960,631.136719 1726.270386,631.013550 1726.081665,630.337036 
	C1727.523438,628.113037 1728.990356,626.442322 1730.457397,624.771606 
	C1730.337280,624.282410 1730.217285,623.793213 1730.097290,623.304016 
	C1728.168579,623.304016 1726.239746,623.304016 1724.311035,623.304016 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M803.131836,741.017334 
	C803.429626,739.931335 803.532837,738.737549 804.062683,737.779968 
	C805.499390,735.183533 805.996216,732.960388 804.327209,729.979309 
	C799.225281,720.866699 794.405823,718.500793 784.104736,720.880676 
	C783.625000,720.991455 782.934692,721.031982 782.727356,721.353516 
	C779.805176,725.884644 776.949890,730.458801 774.074402,735.806580 
	C774.070435,737.980103 774.070435,739.369629 774.070435,741.070557 
	C770.073364,741.070557 766.465942,741.070557 762.610352,740.748535 
	C762.254395,738.773071 762.146545,737.119690 762.056641,735.441650 
	C762.074524,735.417114 762.069397,735.408936 762.390930,735.072876 
	C765.076233,734.383301 767.440063,734.029724 769.709961,733.690186 
	C770.185852,724.344238 770.182800,724.397644 761.332092,723.887207 
	C759.944824,723.807190 758.600037,722.990967 757.414612,722.577576 
	C755.214661,724.108887 752.389465,726.079651 749.552307,728.032959 
	C749.451660,728.102234 749.231995,727.998718 749.003784,727.621155 
	C748.583252,726.492310 748.226807,725.718079 747.571472,724.294739 
	C746.814880,725.981079 746.366516,726.980347 745.455200,727.952026 
	C736.974365,728.064697 728.956360,728.205017 720.938416,728.345337 
	C715.543457,724.150208 710.148499,719.955139 704.753601,715.760071 
	C705.016602,715.192932 705.279541,714.625854 705.542542,714.058716 
	C711.091248,715.347900 717.178406,711.178833 722.387207,716.517090 
	C722.676453,716.813477 723.981018,716.468689 724.572083,716.067444 
	C731.267395,711.522278 738.589233,714.606018 745.622314,714.347656 
	C746.139771,714.328674 746.700256,715.481689 747.284668,716.139221 
	C749.101257,715.322815 750.992493,711.140686 752.559204,716.243103 
	C752.756531,716.885925 753.675537,717.714539 754.299744,717.749451 
	C757.220215,717.912720 760.160828,717.949585 763.082031,717.796753 
	C765.818604,717.653564 768.132141,718.221741 770.594116,714.878967 
	C773.139587,711.422852 773.612488,716.500427 775.305542,718.538391 
	C775.400757,714.633789 777.302979,713.511902 780.912292,713.622131 
	C790.386780,713.911438 799.875916,713.719849 809.171021,713.719849 
	C810.094116,717.602417 810.730774,721.396790 812.082642,724.916443 
	C812.320435,725.535522 815.553162,725.004272 817.445312,724.106079 
	C815.867249,723.798218 814.289246,723.490356 811.826538,723.009888 
	C812.200317,720.052124 812.580872,717.041260 813.088623,713.023865 
	C821.069946,713.023865 829.525330,712.951843 837.977112,713.096680 
	C839.344238,713.120056 840.852295,713.877502 842.028687,714.684326 
	C853.561340,722.593872 865.037476,730.585754 876.534729,739.076904 
	C875.731262,739.740784 874.924316,739.995178 874.113892,740.006775 
	C851.023071,740.338684 827.931824,740.646179 804.555298,740.962219 
	C803.890503,740.983643 803.511169,741.000488 803.131836,741.017334 
M835.165161,721.455994 
	C834.746155,721.215332 834.306519,721.004333 833.911438,720.729370 
	C830.263672,718.191528 820.263733,718.834534 820.706726,721.695679 
	C821.946472,721.919922 823.220154,722.150269 825.358765,722.537048 
	C823.188232,724.277344 821.776123,725.409546 820.376160,726.531982 
	C825.096985,728.671570 832.017517,727.912659 835.571899,725.500610 
	C837.850220,727.812378 839.539978,726.303711 840.605835,724.356445 
	C841.123352,723.410950 840.516479,721.849976 840.418640,720.567688 
	C838.929626,720.874695 837.440674,721.181702 835.165161,721.455994 
M746.874023,718.403870 
	C746.874023,719.932922 746.874023,721.462036 746.874023,722.991089 
	C747.514526,722.902039 748.155029,722.813049 748.795471,722.723999 
	C748.376404,721.174561 747.957336,719.625122 746.874023,718.403870 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M623.904968,729.301880 
	C598.653748,729.425110 573.402527,729.548340 547.701416,729.654480 
	C547.167175,726.391846 547.082947,723.146301 547.398926,719.399597 
	C548.272522,718.425171 548.745911,717.951843 549.650757,717.463562 
	C555.918396,715.868896 552.531738,722.919312 555.889587,723.857361 
	C556.073547,722.180725 556.236206,720.698059 556.398865,719.215393 
	C556.792847,719.085571 557.186890,718.955750 557.580933,718.825928 
	C558.372314,720.073364 559.163696,721.320740 560.164673,722.898499 
	C560.475891,722.114197 560.697510,721.555542 561.320312,720.999329 
	C562.786865,721.002136 563.852173,721.002502 565.011963,721.287598 
	C565.710754,721.717651 566.812195,722.058899 566.843872,721.978638 
	C567.243652,720.964111 567.489319,719.888855 567.821411,718.653870 
	C569.404968,720.408936 570.511353,721.635193 572.079346,722.903564 
	C573.466980,722.988647 574.393005,723.031555 575.493408,723.324158 
	C576.976990,721.522827 578.422607,719.537415 579.469849,717.360413 
	C579.658508,716.968262 578.210205,715.092896 577.512878,715.090088 
	C574.389099,715.077454 571.267212,715.558777 568.137451,715.732971 
	C566.129578,715.844666 564.109253,715.732178 562.106934,715.416870 
	C562.385559,714.352417 562.651733,713.591309 563.220581,712.562683 
	C566.693237,711.863647 569.863098,711.432129 573.435181,711.001709 
	C574.912354,711.001465 575.987488,711.000061 577.229919,711.146362 
	C579.818726,711.153076 582.240234,711.012085 585.075256,710.908875 
	C586.041809,710.945374 586.594788,710.944092 587.597168,710.998230 
	C591.693909,711.021912 595.341187,710.990051 599.269714,711.270874 
	C612.727783,711.638489 625.904602,711.693420 639.455750,711.841553 
	C641.537781,711.604431 643.245605,711.274048 645.410034,710.984558 
	C648.623047,710.947998 651.379333,710.870544 654.476196,710.858643 
	C658.266296,713.833923 661.715942,716.743530 664.917236,719.443665 
	C668.837891,716.410889 672.395752,713.658691 676.217041,711.212097 
	C678.409546,711.584839 680.338623,711.652039 682.325439,711.668579 
	C682.383301,711.617981 682.532654,711.581482 682.385559,712.026489 
	C682.186462,713.718750 682.134460,714.965881 682.082458,716.213074 
	C681.989319,717.395691 681.896240,718.578247 681.546631,720.145386 
	C680.838257,720.502808 680.386353,720.475769 679.581665,720.283752 
	C668.170166,719.921204 669.478333,718.965881 669.611267,729.859253 
	C669.625000,730.984558 669.932129,732.106384 670.074951,733.709229 
	C670.033325,734.508545 670.019897,734.828491 669.717285,735.259644 
	C668.461182,735.622437 667.494263,735.874023 666.076050,736.094971 
	C665.323425,736.077454 665.022156,736.090515 664.345581,735.999573 
	C659.745361,735.678528 657.901917,737.857300 657.459229,741.943604 
	C655.034058,741.989563 653.062073,742.118835 651.107361,741.989136 
	C646.645325,741.692871 642.191772,741.269958 637.990967,740.597290 
	C638.591736,737.266296 638.936401,734.236511 639.378540,730.349976 
	C648.509949,730.349976 657.056702,730.349976 665.603455,730.349976 
	C665.058655,728.919373 664.503113,728.602356 663.913208,728.513184 
	C654.776123,727.132324 645.163513,731.218872 636.334778,726.122803 
	C636.121094,725.999390 635.546448,726.111511 635.407593,726.305359 
	C632.396423,730.510437 627.690613,728.142273 623.904968,729.301880 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M670.006409,735.148376 
	C670.019897,734.828491 670.033325,734.508545 670.515991,733.804077 
	C671.316101,733.450623 671.647095,733.481873 671.978088,733.513062 
	C677.844177,734.066650 683.589966,734.418945 684.546753,726.147095 
	C686.349548,725.131592 687.816284,724.168274 689.283020,723.205017 
	C692.014282,723.246338 697.133301,726.838074 694.741028,719.235474 
	C694.428650,718.242737 697.537292,715.812195 699.438354,714.681274 
	C700.098938,714.288269 702.191223,715.533630 703.128845,716.511108 
	C706.703491,720.237671 710.073914,724.160217 713.680115,728.187683 
	C705.386475,728.187683 696.473022,728.187683 687.559570,728.187683 
	C687.530029,728.937805 687.500488,729.687988 687.471008,730.438110 
	C690.845764,730.438110 694.220947,730.472107 697.595154,730.429077 
	C701.424866,730.380188 705.253296,730.237183 709.083008,730.182129 
	C712.747009,730.129395 716.411926,730.136902 721.002319,730.113342 
	C730.277222,730.087769 738.626160,730.066772 747.184692,730.376587 
	C747.747559,733.093933 748.100830,735.480347 748.454163,737.866821 
	C746.656372,741.270447 743.666443,741.462708 740.200439,741.478455 
	C721.135376,741.565247 702.071350,741.882751 682.101257,742.088257 
	C679.100464,742.091675 677.005371,742.127869 674.597107,741.898254 
	C672.492371,739.712402 675.682800,734.505676 670.006409,735.148376 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M895.860779,678.762817 
	C897.257202,678.854126 898.653687,678.945435 900.734619,679.319458 
	C903.303223,680.370544 905.187317,681.139038 907.175659,682.237793 
	C908.610474,684.510254 909.941162,686.452454 911.088074,688.126404 
	C912.063354,687.424744 912.979065,686.765930 913.962891,686.523804 
	C915.143066,687.697449 916.171143,688.877747 917.385620,689.118652 
	C919.550354,689.547974 921.827759,689.409607 924.058044,689.508667 
	C924.208740,689.795105 924.232605,690.091736 923.825928,690.884155 
	C922.575500,691.499512 921.602539,691.839600 920.686218,691.725403 
	C914.080200,690.902466 911.794373,692.425232 909.694946,699.156616 
	C908.592041,700.593018 907.794128,701.872742 906.642761,703.719421 
	C908.737915,703.854309 909.869019,703.927124 911.076660,704.169434 
	C911.153259,704.338928 911.214355,704.705750 911.157471,705.025269 
	C911.372131,706.139893 911.643738,706.935120 911.867432,708.005371 
	C911.763306,708.456482 911.707214,708.632507 911.208008,708.866943 
	C897.802063,708.966248 884.838257,708.940857 871.877625,709.131287 
	C870.256531,709.155090 868.652100,710.318970 867.039978,710.954590 
	C866.582214,710.988831 866.124512,711.023071 865.123657,710.715149 
	C858.412781,710.202942 852.245117,710.033020 846.151001,709.470947 
	C847.903809,708.713440 849.578430,708.057922 851.263184,708.030762 
	C860.887939,707.875427 870.515137,707.869873 880.593628,707.849609 
	C886.764832,707.141357 892.997070,709.835266 898.643677,705.676392 
	C903.484375,709.086975 906.868835,709.135071 908.705139,706.038147 
	C907.107605,705.005249 905.526184,703.982727 904.171814,702.700012 
	C906.004150,699.690796 907.609497,696.941772 909.667480,693.417664 
	C906.011475,693.417664 903.601624,693.417664 900.806641,693.357788 
	C898.671021,693.487915 896.920410,693.677856 894.758179,693.848999 
	C892.030151,693.679016 889.713806,693.527771 887.171387,693.361755 
	C888.708130,697.066528 889.938599,700.032776 890.803955,703.018311 
	C889.951233,703.034973 889.463623,703.032288 888.919800,702.678833 
	C886.427246,691.563171 876.535339,691.269958 868.584961,687.833923 
	C867.841064,688.980530 867.096680,690.061279 866.420227,691.182983 
	C864.304688,694.690857 851.578918,697.090332 849.004578,693.946350 
	C846.199890,690.520996 843.861084,686.468811 842.395996,682.310547 
	C841.793945,680.601685 843.850464,677.819397 844.979614,675.673706 
	C846.236938,673.284363 845.654663,672.598633 843.084961,672.759827 
	C842.973389,671.690247 842.913269,670.976379 843.283325,670.247803 
	C846.422485,669.782959 849.131470,669.332825 852.089355,668.833618 
	C852.554810,668.536438 852.771301,668.288330 853.440674,667.972656 
	C857.530945,668.500000 862.839783,667.832214 864.447266,669.960388 
	C867.512756,674.018555 873.079773,677.967773 870.335144,684.689636 
	C870.163208,685.110779 870.586853,686.162476 871.009460,686.376953 
	C873.981567,687.885498 877.026123,689.251404 881.134583,691.170654 
	C881.134583,687.521362 881.035095,685.399841 881.155334,683.290833 
	C881.407410,678.869446 881.774902,674.454651 882.428467,670.026428 
	C883.208435,670.010498 883.655029,670.005249 884.167480,670.338257 
	C885.207214,671.721680 886.181152,672.766846 887.156921,673.810486 
	C883.690369,675.641296 882.877258,680.503662 885.085876,688.441345 
	C888.265442,684.336121 889.756226,678.676331 895.860779,678.762817 
M859.536804,691.403015 
	C864.242493,693.500244 864.899170,689.452393 865.802795,686.773865 
	C866.105774,685.875854 864.085938,683.090027 862.996216,683.012207 
	C857.473206,682.617981 851.906921,682.830750 845.314209,682.830750 
	C847.346130,686.459229 848.543396,689.168518 850.261658,691.492554 
	C851.751465,693.507690 853.587830,693.668457 855.237915,690.928955 
	C856.492737,690.861084 857.747620,690.793274 859.536804,691.403015 
M867.396790,680.819397 
	C866.588745,678.208496 865.780701,675.597534 864.928955,672.845520 
	C859.782104,675.142761 857.279602,680.838501 851.456970,678.470459 
	C851.109009,676.280151 850.793396,674.293274 850.477783,672.306396 
	C850.072632,672.230530 849.667542,672.154663 849.262451,672.078796 
	C847.851257,674.719299 846.440063,677.359802 844.552673,680.891357 
	C850.344055,680.891357 855.111755,680.907227 859.879333,680.884460 
	C862.167419,680.873535 864.455078,680.789490 867.396790,680.819397 
M896.515259,690.857422 
	C896.953369,690.656067 897.391479,690.454773 897.829590,690.253418 
	C896.585327,687.612793 895.341064,684.972168 893.664917,681.414795 
	C889.170349,681.810059 889.922241,687.493713 886.230042,690.943481 
	C890.307739,690.943481 893.034363,690.943481 896.515259,690.857422 
M908.548584,689.129578 
	C907.304688,686.968384 906.468201,684.325989 904.677795,682.810303 
	C903.425049,681.749756 900.837769,681.947449 898.910583,682.161194 
	C898.161804,682.244263 897.589294,683.916138 896.688416,685.224487 
	C899.578186,684.539490 901.615051,684.056641 903.651855,683.573853 
	C903.991699,683.919739 904.331482,684.265625 904.671326,684.611511 
	C903.633789,686.420349 902.596313,688.229187 900.915527,691.159607 
	C903.690979,690.702454 906.186218,690.291443 908.548584,689.129578 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M880.141479,707.813416 
	C870.515137,707.869873 860.887939,707.875427 851.263184,708.030762 
	C849.578430,708.057922 847.903809,708.713440 845.675537,709.506592 
	C834.767395,709.964294 824.408203,709.994202 814.049072,710.024170 
	C814.024292,709.856934 813.999512,709.689758 814.275269,708.969360 
	C821.825623,708.146179 829.075439,707.876343 836.325256,707.606445 
	C836.306213,707.234558 836.287170,706.862610 836.268127,706.490723 
	C827.343323,706.490723 818.418518,706.490723 808.278564,706.490723 
	C810.232910,707.825623 811.148010,708.450623 812.063110,709.075684 
	C811.578369,709.679932 811.093567,710.284119 810.012573,710.625366 
	C798.275513,710.240479 787.134705,710.118469 776.016846,709.588745 
	C776.980652,708.100525 777.740967,706.708862 778.913879,706.028748 
	C780.043335,705.373901 781.599365,705.454529 783.229736,704.871948 
	C790.084045,704.365967 796.678406,704.247009 803.267395,703.991150 
	C804.473145,703.944275 805.657227,703.339355 806.851318,702.992004 
	C806.267090,701.789978 805.908325,700.389404 805.056946,699.422607 
	C802.302856,696.295044 802.251465,693.536133 806.028625,691.926025 
	C808.550476,690.851013 811.871948,691.389893 814.801025,691.628906 
	C816.596191,691.775391 818.326172,692.720032 820.942993,693.591980 
	C820.476196,688.440308 822.447815,683.919495 817.841797,680.436401 
	C819.409363,678.902344 820.961243,677.728516 822.634949,677.037354 
	C822.921997,686.147278 823.087097,694.774475 823.252258,703.401611 
	C823.506165,703.341675 823.760010,703.281799 824.013916,703.221863 
	C825.028137,699.795959 826.042297,696.369995 827.435364,692.963623 
	C830.127014,692.097412 832.437805,690.513550 834.752991,690.507141 
	C837.493652,690.499512 840.479248,691.441589 842.852661,692.834290 
	C843.707031,693.335571 843.812439,697.482239 843.013428,697.985107 
	C840.674500,699.457031 837.747070,700.122803 834.965637,700.688538 
	C834.066895,700.871277 832.933838,699.696289 831.858154,699.267456 
	C830.559326,698.749634 829.207458,698.364929 827.400024,697.763306 
	C827.400024,699.835632 827.400024,701.193787 827.400024,703.764038 
	C848.163940,703.764038 868.580078,703.764038 888.792847,704.133850 
	C886.708435,704.694824 884.734802,704.603760 882.979736,705.179321 
	C881.865540,705.544739 881.076416,706.901367 880.141479,707.813416 
M812.695007,693.321350 
	C811.899353,693.181702 810.980835,692.719971 810.332703,692.967163 
	C808.821838,693.543457 807.441040,694.460754 806.007568,695.239929 
	C806.644409,696.294495 807.167542,698.136108 807.940674,698.247925 
	C809.840210,698.522522 811.854919,698.000916 813.823303,697.799500 
	C813.672729,696.447327 813.522156,695.095093 812.695007,693.321350 
M816.122742,703.137207 
	C817.616333,703.363281 819.109924,703.589355 821.046021,703.882385 
	C820.906738,701.490967 820.814575,699.908813 820.686401,697.709106 
	C816.380066,699.171448 812.628845,700.445251 808.877686,701.719116 
	C808.835754,701.979370 808.793884,702.239624 808.751953,702.499878 
	C810.931091,702.674561 813.110168,702.849304 816.122742,703.137207 
M841.058289,696.297058 
	C840.986938,692.491028 838.345825,692.334900 835.851807,693.199951 
	C834.687683,693.603821 834.064758,695.567993 833.196472,696.824890 
	C835.255676,697.329407 837.300842,697.918213 839.385986,698.267517 
	C839.806213,698.337830 840.378601,697.499817 841.058289,696.297058 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M897.895752,728.563843 
	C897.649231,728.944519 897.485168,728.947571 897.321777,728.959961 
	C891.009644,729.439270 884.976746,730.203613 886.550537,739.101379 
	C886.589844,739.323486 885.853210,739.682861 885.074585,740.146851 
	C880.820923,738.099487 876.831360,736.092285 873.148621,733.626587 
	C863.686462,727.291565 854.360474,720.753052 844.980103,714.295898 
	C845.101257,713.835876 845.222473,713.375916 845.343628,712.915955 
	C847.545410,712.915955 849.747253,712.926392 851.948975,712.914185 
	C862.420227,712.856079 872.891479,712.772095 883.362793,712.739258 
	C887.579712,712.726074 890.650146,713.771118 889.956055,719.139282 
	C889.792603,720.403870 891.309204,721.885681 892.247498,723.629150 
	C889.255920,725.388916 886.562500,725.987122 883.351013,723.725037 
	C882.098511,722.842773 879.993286,723.113708 878.267090,722.987000 
	C877.461426,722.927917 876.632568,723.268677 875.815979,723.259766 
	C873.949768,723.239380 872.075073,722.988647 870.226501,723.129395 
	C869.804749,723.161560 869.074097,724.938782 869.162292,725.004456 
	C870.793823,726.219543 872.585083,728.312073 874.230530,728.219849 
	C880.025024,727.894775 885.795654,726.919312 891.531006,725.932434 
	C892.880554,725.700317 894.056152,724.457458 895.959106,723.888733 
	C897.126282,724.702759 897.646301,725.307617 898.166260,725.912476 
	C898.103699,726.671814 898.041077,727.431152 897.895752,728.563843 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M911.214355,704.705688 
	C911.214355,704.705750 911.153259,704.338928 911.058899,703.786621 
	C910.643066,701.822876 910.321533,700.411438 910.000000,699.000000 
	C911.794373,692.425232 914.080200,690.902466 920.686218,691.725403 
	C921.602539,691.839600 922.575500,691.499512 923.768799,691.104736 
	C924.357178,691.013000 924.698975,691.186218 925.492065,691.791992 
	C926.164551,693.968994 926.385803,695.713318 926.588501,697.311279 
	C929.235535,697.311279 931.443176,697.311279 934.330383,697.311279 
	C933.548645,694.960022 932.976379,693.238708 932.404053,691.517456 
	C933.060425,691.456970 933.716858,691.396545 935.002563,691.451172 
	C936.891968,691.566284 938.151978,691.566284 940.035339,691.566284 
	C940.035339,688.958313 940.035339,686.478638 940.194092,683.830261 
	C940.352905,683.661560 940.447815,683.208069 940.584595,682.952759 
	C940.810608,682.128906 940.899841,681.560425 941.314697,681.262085 
	C941.892029,688.792908 942.143738,696.053528 942.395386,703.314148 
	C942.395386,703.314148 942.191406,703.651550 941.658691,703.851318 
	C931.155457,704.269287 921.184875,704.487488 911.214355,704.705688 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M769.434509,710.102844 
	C769.340515,706.779053 766.844482,706.821960 764.804626,707.329285 
	C760.165283,708.483215 755.553528,708.486633 750.864258,711.303711 
	C746.774902,713.760376 740.041504,711.815674 734.948730,711.815674 
	C735.223206,709.449402 735.475098,707.278137 735.726929,705.106934 
	C735.177856,705.005005 734.628784,704.903137 734.079651,704.801270 
	C733.538208,705.905212 732.996704,707.009094 732.455200,708.113037 
	C732.195312,708.062744 731.935425,708.012390 731.675476,707.962097 
	C732.087463,705.245117 732.499390,702.528137 733.301331,699.328125 
	C734.386108,698.831299 735.080933,698.817566 735.898682,699.190796 
	C736.797180,701.464661 737.572815,703.351440 738.612183,705.410522 
	C742.196106,706.189331 744.591125,705.389832 745.103271,701.394897 
	C745.431030,701.050964 745.678589,700.974731 746.274902,700.936340 
	C746.992126,699.996643 747.392578,699.122986 748.136353,698.174622 
	C749.983398,697.886108 751.487244,697.672302 753.017944,697.857178 
	C753.875427,702.031738 756.551392,702.186768 759.973267,701.379395 
	C759.684265,702.937805 759.447937,704.212097 759.147217,705.833740 
	C760.654663,705.767883 761.754883,705.719849 763.314819,705.706238 
	C765.861328,705.814270 767.951477,705.998047 770.034302,705.935913 
	C773.268250,705.839355 773.739136,707.176331 771.881592,709.820190 
	C770.835999,709.969910 770.135254,710.036377 769.434509,710.102844 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M802.839417,741.203857 
	C803.511169,741.000488 803.890503,740.983643 804.771606,741.288330 
	C807.023499,742.024780 808.778198,742.815247 810.522949,742.793823 
	C821.926331,742.653931 833.327209,742.325562 844.729431,742.083557 
	C856.295288,741.838013 867.861755,741.621033 879.427917,741.391541 
	C880.687744,741.856934 881.947510,742.322327 883.513000,742.932251 
	C882.632080,743.453735 881.449951,744.144714 880.258362,744.161499 
	C854.371338,744.524780 828.483276,744.814819 802.595276,745.104980 
	C801.983459,745.111877 801.369873,744.959167 800.108215,744.797791 
	C801.116028,743.389771 801.831482,742.390015 802.839417,741.203857 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M950.198242,702.293213 
	C948.695679,702.590759 947.193115,702.888306 945.313232,702.666992 
	C944.918640,695.541077 944.986694,688.931763 944.817383,682.328552 
	C944.780334,680.879761 943.898438,679.452637 943.549072,677.574890 
	C944.215027,673.478699 944.737488,669.823242 945.555298,666.112000 
	C946.573730,665.377380 947.296875,664.698547 948.418457,664.098145 
	C949.209290,665.831421 949.917786,667.481506 949.942871,669.141968 
	C950.109558,680.191101 950.130188,691.242554 950.198242,702.293213 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M980.956177,679.530396 
	C978.972961,681.684509 977.056580,683.462646 974.979736,685.525269 
	C973.088806,686.796936 971.358337,687.784058 969.263916,688.472900 
	C969.515686,685.889587 969.676636,683.336670 970.834656,681.371216 
	C973.961304,676.064758 973.759399,673.399170 969.036499,668.528809 
	C968.889099,666.368530 968.796204,664.635742 968.999023,662.689941 
	C971.191284,662.658264 973.087891,662.839600 974.992310,663.011108 
	C975.000244,663.001282 974.980103,663.013000 974.976562,663.309326 
	C975.308289,663.741699 975.643494,663.877686 975.989380,664.006836 
	C976.000000,664.000061 975.986023,664.021484 975.967163,664.322021 
	C976.290771,664.752014 976.633179,664.881531 976.986572,665.006714 
	C976.997559,665.002441 976.988770,665.024353 976.966064,665.327637 
	C977.286194,665.756470 977.629028,665.882019 977.983521,666.006104 
	C977.995117,666.004700 977.986450,666.026245 977.865051,666.370789 
	C979.158203,668.150818 980.572815,669.586243 981.992920,671.011353 
	C981.998474,671.001038 981.976196,671.013977 981.976196,671.013977 
	C981.683044,672.472351 981.389832,673.930664 981.204590,675.755981 
	C981.216064,677.133362 981.119568,678.143860 980.956177,679.530396 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M950.504150,702.196045 
	C950.130188,691.242554 950.109558,680.191101 949.942871,669.141968 
	C949.917786,667.481506 949.209290,665.831421 948.655151,663.782959 
	C950.344116,663.193054 952.194824,662.996826 954.037720,663.259644 
	C954.372131,666.479736 954.714417,669.240845 954.992920,672.436279 
	C954.949097,674.915222 954.969116,676.959717 954.831055,679.389343 
	C954.442749,686.503967 954.212585,693.233459 953.982422,699.962952 
	C952.925049,700.674988 951.867554,701.386963 950.504150,702.196045 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M954.989136,679.004211 
	C954.969116,676.959717 954.949097,674.915222 955.253357,672.318726 
	C956.052856,671.181580 956.528076,670.596558 957.306152,669.818542 
	C960.740173,668.756226 963.871399,667.886841 967.126770,667.284180 
	C967.864258,668.019409 968.477600,668.487854 969.090942,668.956299 
	C973.759399,673.399170 973.961304,676.064758 970.834656,681.371216 
	C969.676636,683.336670 969.515686,685.889587 968.949463,688.591064 
	C967.541382,690.268921 966.083862,691.530212 964.393555,692.520081 
	C965.061401,690.045349 965.962097,687.842163 966.490906,686.548706 
	C963.409668,685.187683 960.736572,684.006958 958.054810,682.609314 
	C958.046143,682.392456 958.037659,681.958557 958.437866,681.903259 
	C961.293457,682.263184 963.748840,682.678406 966.204163,683.093628 
	C966.320251,682.679077 966.436340,682.264587 966.552429,681.850037 
	C965.608765,681.356567 964.665100,680.863159 963.721375,680.369690 
	C963.779175,680.082092 963.837036,679.794495 963.894836,679.506897 
	C965.828247,679.506897 967.761597,679.506897 969.503540,679.506897 
	C972.920471,674.609131 969.311951,672.265320 966.370422,669.955078 
	C963.538635,667.731079 961.683655,669.536011 959.215210,671.511047 
	C954.924744,674.944092 958.272461,678.503235 957.756348,681.833740 
	C956.644409,680.804932 955.816772,679.904541 954.989136,679.004211 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M695.003235,709.027039 
	C695.988647,709.253052 696.973999,709.479065 698.542419,709.380615 
	C705.290955,709.024475 711.456848,709.019531 717.621887,708.945618 
	C719.642944,708.921387 721.330505,709.095764 720.864868,711.806763 
	C715.233276,711.853149 709.592529,712.114075 703.973267,711.872864 
	C700.024048,711.703369 696.791016,712.673645 694.303833,715.738281 
	C692.161560,718.378052 689.793213,719.355957 686.394714,716.706787 
	C685.679260,714.859192 685.358215,713.435486 685.053040,711.796387 
	C685.068848,711.580994 685.178406,711.163147 685.552795,711.125000 
	C688.276672,710.801086 690.626221,710.515259 693.282593,710.183350 
	C694.060669,709.767090 694.531921,709.397034 695.003235,709.027039 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M724.947693,709.024475 
	C724.933350,708.363342 724.919067,707.702271 724.910828,706.265686 
	C725.908325,700.467346 722.933472,699.717896 718.797546,699.737183 
	C717.412903,699.041260 716.232117,698.607910 715.026978,698.040161 
	C715.002686,697.905823 714.917847,697.646179 714.945190,697.304077 
	C717.614258,694.298279 720.255981,691.634521 723.080444,689.305298 
	C723.604553,691.110901 723.946045,692.581970 724.235229,694.426636 
	C724.932007,695.815308 725.635193,696.868713 726.442505,697.835205 
	C727.331543,698.899597 728.316284,699.884094 729.259338,700.903381 
	C729.323669,703.198242 729.534424,705.503662 729.371582,707.782288 
	C729.315796,708.563416 728.324890,709.277771 727.230469,709.795776 
	C726.115784,709.387512 725.531738,709.205994 724.947693,709.024475 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M879.304565,741.119690 
	C867.861755,741.621033 856.295288,741.838013 844.729431,742.083557 
	C833.327209,742.325562 821.926331,742.653931 810.522949,742.793823 
	C808.778198,742.815247 807.023499,742.024780 805.057068,741.283691 
	C827.931824,740.646179 851.023071,740.338684 874.113892,740.006775 
	C874.924316,739.995178 875.731262,739.740784 876.816040,739.268188 
	C877.788635,739.573792 878.484924,740.210815 879.304565,741.119690 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M719.001343,699.999817 
	C722.933472,699.717896 725.908325,700.467346 724.855164,705.938965 
	C724.243835,707.948669 723.782959,709.550964 723.089722,711.045349 
	C722.885010,711.486633 722.023865,711.623413 721.164185,711.853210 
	C721.330505,709.095764 719.642944,708.921387 717.621887,708.945618 
	C711.456848,709.019531 705.290955,709.024475 698.693359,709.110840 
	C698.123352,708.742859 697.985474,708.320251 698.303772,707.510132 
	C702.245667,707.093140 705.755676,707.292908 709.205505,706.926392 
	C710.672852,706.770508 712.014221,705.429199 713.718750,704.709290 
	C714.658447,704.828552 715.292236,704.865173 715.993774,705.304321 
	C717.334045,706.151611 718.611755,706.948242 719.877441,706.929626 
	C720.887390,706.914856 721.882690,705.906738 722.884521,705.339478 
	C722.119873,704.500488 721.403198,703.609375 720.571777,702.842896 
	C720.105591,702.413086 719.449280,702.189636 718.885620,701.560181 
	C718.928772,700.831421 718.965088,700.415588 719.001343,699.999817 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M911.157471,705.025208 
	C921.184875,704.487488 931.155457,704.269287 941.556152,704.019775 
	C942.017395,704.761597 942.048462,705.534790 941.562134,706.677734 
	C931.334961,707.275024 921.625183,707.502686 911.915344,707.730286 
	C911.643738,706.935120 911.372131,706.139893 911.157471,705.025208 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M900.146912,721.025391 
	C899.574524,719.843506 899.055603,718.631592 898.413452,717.488892 
	C897.856934,716.498535 897.160645,715.586731 896.526062,714.640137 
	C897.552429,713.996399 898.538452,712.891418 899.612915,712.797363 
	C902.551270,712.540283 905.526978,712.708923 909.707886,712.708923 
	C908.341125,716.226379 907.259155,719.010803 905.704712,722.217529 
	C903.537109,722.101624 901.841980,721.563538 900.146912,721.025391 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M958.063477,682.826172 
	C960.736572,684.006958 963.409668,685.187683 966.490906,686.548706 
	C965.962097,687.842163 965.061401,690.045349 964.083984,692.627014 
	C961.851013,694.478943 959.694702,695.952515 957.202393,697.087280 
	C957.265442,692.107727 957.664490,687.466919 958.063477,682.826172 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M899.837036,720.868774 
	C901.841980,721.563538 903.537109,722.101624 905.607788,722.550781 
	C905.941528,722.700684 905.899658,722.939575 905.639893,723.752075 
	C905.621277,725.877502 905.820557,727.429260 906.019897,728.981079 
	C905.184814,729.689026 904.349670,730.396973 903.931519,730.751465 
	C902.200867,729.143494 900.530701,727.591736 898.513428,725.976196 
	C897.646301,725.307617 897.126282,724.702759 896.349731,723.918884 
	C894.793152,721.369934 893.493164,719.000000 891.864685,716.031189 
	C896.291687,715.065674 897.013306,719.487183 899.837036,720.868774 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M729.525391,700.752380 
	C728.316284,699.884094 727.331543,698.899597 726.442505,697.835205 
	C725.635193,696.868713 724.932007,695.815308 724.494019,694.423157 
	C725.151001,694.048889 725.496887,694.051697 726.196289,694.094482 
	C727.340027,693.617920 728.130188,693.101379 729.097778,692.913086 
	C731.190613,694.207703 733.105957,695.174011 735.646301,696.455688 
	C735.646301,693.571289 735.646301,691.579285 735.646301,689.451111 
	C734.125977,689.613892 732.863892,689.748962 731.752563,689.620728 
	C732.316467,688.917175 732.729614,688.477051 733.367676,687.843506 
	C733.745117,686.883606 733.897705,686.117188 734.360107,685.280029 
	C735.446777,685.472473 736.223511,685.735718 737.028076,686.415588 
	C737.184998,687.372192 737.314270,687.912170 737.475769,688.705078 
	C737.510620,689.464905 737.513184,689.971802 737.372803,690.786133 
	C737.375854,691.717285 737.521912,692.341064 737.717590,693.317139 
	C737.103394,695.380859 736.439575,697.092346 735.775757,698.803833 
	C735.080933,698.817566 734.386108,698.831299 733.353027,698.926025 
	C731.940430,699.538513 730.865967,700.069946 729.525391,700.752380 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M943.405518,678.015503 
	C943.898438,679.452637 944.780334,680.879761 944.817383,682.328552 
	C944.986694,688.931763 944.918640,695.541077 944.919922,702.626282 
	C944.274536,703.142334 943.645264,703.180298 942.705688,703.266235 
	C942.143738,696.053528 941.892029,688.792908 941.287476,680.959167 
	C941.758301,679.595886 942.581909,678.805725 943.405518,678.015503 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M968.952209,702.057861 
	C964.938293,702.350464 960.924438,702.643005 956.456482,702.967773 
	C959.118469,700.179688 962.224609,697.347961 965.415649,694.615234 
	C965.484619,694.556091 966.526367,695.632812 967.389465,696.316895 
	C968.161499,698.028015 968.658447,699.601807 969.090210,701.577515 
	C969.025146,701.979431 968.952209,702.057861 968.952209,702.057861 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M867.505005,710.960205 
	C868.652100,710.318970 870.256531,709.155090 871.877625,709.131287 
	C884.838257,708.940857 897.802063,708.966248 911.314453,709.161926 
	C911.908203,709.601807 911.952393,709.805298 911.996521,710.008728 
	C897.321045,710.327759 882.645569,710.646790 867.505005,710.960205 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M912.463379,710.057495 
	C911.952393,709.805298 911.908203,709.601807 911.757629,709.103394 
	C911.707214,708.632507 911.763306,708.456482 911.867432,708.005371 
	C921.625183,707.502686 931.334961,707.275024 941.482300,707.056580 
	C940.062378,708.044556 938.239258,709.783386 936.341309,709.869263 
	C928.550049,710.222107 920.735962,710.071594 912.463379,710.057495 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M812.384949,709.060486 
	C811.148010,708.450623 810.232910,707.825623 808.278564,706.490723 
	C818.418518,706.490723 827.343323,706.490723 836.268127,706.490723 
	C836.287170,706.862610 836.306213,707.234558 836.325256,707.606445 
	C829.075439,707.876343 821.825623,708.146179 814.285645,708.716064 
	C813.565857,709.025879 813.136353,709.035583 812.384949,709.060486 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M958.054810,682.609314 
	C957.664490,687.466919 957.265442,692.107727 956.884338,697.276123 
	C956.096130,698.459473 955.290039,699.115234 954.233154,699.866943 
	C954.212585,693.233459 954.442749,686.503967 954.831055,679.389343 
	C955.816772,679.904541 956.644409,680.804932 957.736023,681.852661 
	C958.000000,682.000000 958.037659,681.958557 958.037659,681.958557 
	C958.037659,681.958557 958.046143,682.392456 958.054810,682.609314 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M814.298157,710.366455 
	C824.408203,709.994202 834.767395,709.964294 845.601929,709.898682 
	C852.245117,710.033020 858.412781,710.202942 864.780579,710.679138 
	C849.136658,711.037598 833.292603,711.097656 817.448608,711.123169 
	C816.481689,711.124756 815.514343,710.853210 814.298157,710.366455 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M746.975098,730.045776 
	C738.626160,730.066772 730.277222,730.087769 721.462646,730.047241 
	C720.985229,729.620789 720.973389,729.255859 720.949951,728.618042 
	C728.956360,728.205017 736.974365,728.064697 745.690308,727.959412 
	C746.388245,727.994385 746.850159,728.081543 746.843994,728.410095 
	C746.883545,729.174377 746.929321,729.610046 746.975098,730.045776 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M772.226379,709.736816 
	C773.739136,707.176331 773.268250,705.839355 770.034302,705.935913 
	C767.951477,705.998047 765.861328,705.814270 763.353699,705.513550 
	C762.932922,705.286438 763.053772,704.910889 763.338013,704.912231 
	C763.901672,704.666138 764.181152,704.418701 764.892090,704.103516 
	C771.204956,704.427795 777.086487,704.819946 782.967957,705.212036 
	C781.599365,705.454529 780.043335,705.373901 778.913879,706.028748 
	C777.740967,706.708862 776.980652,708.100525 775.905823,709.848755 
	C775.755920,710.704468 775.740051,710.892334 775.724243,711.080200 
	C775.724243,711.080200 775.240662,711.080505 775.240662,711.080505 
	C775.240662,711.080505 774.757141,711.076172 774.380859,710.768555 
	C773.411804,710.219482 772.819092,709.978149 772.226379,709.736816 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M689.008789,722.932617 
	C687.816284,724.168274 686.349548,725.131592 684.136353,726.105957 
	C680.481140,726.738281 677.572388,727.359619 674.837524,727.675781 
	C676.469238,725.580322 677.927063,723.789978 679.580322,721.783325 
	C679.828674,721.194275 679.881592,720.821472 679.934448,720.448730 
	C680.386353,720.475769 680.838257,720.502808 681.628540,720.492798 
	C682.854797,721.160645 683.646790,722.192139 684.654785,722.487976 
	C685.922302,722.860046 687.366516,722.630310 689.008789,722.932617 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M954.774048,702.816895 
	C957.119812,703.262390 959.108093,703.699646 961.112549,704.042358 
	C962.724731,704.318054 964.356445,704.479248 966.922668,704.814392 
	C961.917969,708.036926 954.270020,707.645142 951.956299,704.387939 
	C952.786682,703.567017 953.605103,703.167419 954.774048,702.816895 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M776.208984,711.176453 
	C775.740051,710.892334 775.755920,710.704468 775.882812,710.256531 
	C787.134705,710.118469 798.275513,710.240479 809.698547,710.670044 
	C798.885010,711.075989 787.789368,711.174316 776.208984,711.176453 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M906.319702,729.120850 
	C905.820557,727.429260 905.621277,725.877502 905.676453,724.114014 
	C911.970642,723.806702 909.385437,728.213928 910.114624,731.992676 
	C908.494263,730.726074 907.556885,729.993347 906.319702,729.120850 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M758.464111,712.375793 
	C760.317383,713.361328 761.811279,714.228699 764.396545,715.729858 
	C760.414673,715.729858 757.765808,715.729858 754.895264,715.729858 
	C754.895264,714.248901 754.895264,712.994690 754.895264,711.310913 
	C756.114014,711.670410 757.109375,711.963989 758.464111,712.375793 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M761.283936,711.693115 
	C762.659180,710.970215 764.503357,709.800232 765.986450,710.152039 
	C767.009277,710.394714 767.532654,712.743103 768.279297,714.150452 
	C767.909485,714.382568 767.539734,714.614746 767.169922,714.846863 
	C765.312927,713.862976 763.455872,712.879089 761.283936,711.693115 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M685.037231,712.011841 
	C685.358215,713.435486 685.679260,714.859192 685.999146,716.674194 
	C684.928528,716.733276 683.859070,716.401123 682.436035,716.140991 
	C682.134460,714.965881 682.186462,713.718750 682.687439,712.114624 
	C683.770020,711.842407 684.403625,711.927124 685.037231,712.011841 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M771.881592,709.820190 
	C772.819092,709.978149 773.411804,710.219482 774.139099,710.763611 
	C772.661133,710.953796 771.048523,710.841187 769.435242,710.415771 
	C770.135254,710.036377 770.835999,709.969910 771.881592,709.820190 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M724.930359,709.337158 
	C725.531738,709.205994 726.115784,709.387512 726.862671,709.838806 
	C726.442932,710.552673 725.860474,710.996704 725.278015,711.440796 
	C725.156311,710.843750 725.034668,710.246765 724.930359,709.337158 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M944.422058,705.527344 
	C944.604004,704.949463 945.001648,704.593018 945.419312,704.261841 
	C945.435791,704.248718 945.750488,704.570862 945.745239,704.576721 
	C945.389160,704.979492 945.014465,705.365662 944.422058,705.527344 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M746.450684,796.450256 
	C746.529480,796.411011 746.564819,796.584473 746.564819,796.584473 
	C746.564819,796.584473 746.371948,796.489502 746.450684,796.450256 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M672.325928,746.144775 
	C670.553894,746.250305 669.255371,746.277100 667.568848,746.353943 
	C655.055908,746.272583 642.931152,746.105591 630.805847,746.021423 
	C622.038391,745.960571 613.270142,746.009583 604.502258,746.009583 
	C604.461853,745.532410 604.421509,745.055237 604.381104,744.578064 
	C609.025696,744.140808 613.670227,743.703491 619.092590,743.191528 
	C620.959900,743.276978 622.049438,743.437073 623.490723,743.750916 
	C631.992188,743.955872 640.143066,744.108887 648.290833,744.008240 
	C651.529663,743.968262 654.761597,743.366455 658.426270,743.147949 
	C662.131958,743.318420 665.408142,743.361511 669.019897,743.546021 
	C670.238770,743.502625 671.121887,743.317749 672.376709,743.189270 
	C673.496399,743.216980 674.244507,743.188232 675.228149,743.388916 
	C677.707092,743.537048 679.950500,743.455872 682.193848,743.374695 
	C701.745911,743.229797 721.297913,743.069824 740.850159,742.963074 
	C742.840027,742.952209 744.831604,743.261414 746.822449,743.422119 
	C746.802673,744.058716 746.782959,744.695312 746.763184,745.331909 
	C737.812195,745.546692 728.861267,745.761414 719.443481,746.042908 
	C709.663269,746.117126 700.349792,746.124512 690.578918,746.183960 
	C684.347473,746.179321 678.573364,746.122681 672.325928,746.144775 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M788.292297,722.187988 
	C796.974548,720.263916 800.318481,725.417908 802.968689,732.470215 
	C794.998230,732.470215 787.218811,732.470215 779.429749,732.470215 
	C779.591125,726.824219 783.115906,722.469849 788.292297,722.187988 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M747.184692,730.376587 
	C746.929321,729.610046 746.883545,729.174377 747.007324,728.213257 
	C747.807129,727.783813 748.437500,727.879761 749.067810,727.975708 
	C749.231995,727.998718 749.451660,728.102234 749.552307,728.032959 
	C752.389465,726.079651 755.214661,724.108887 757.414612,722.577576 
	C758.600037,722.990967 759.944824,723.807190 761.332092,723.887207 
	C770.182800,724.397644 770.185852,724.344238 769.709961,733.690186 
	C767.440063,734.029724 765.076233,734.383301 761.937866,734.987305 
	C759.429443,734.989502 757.695496,734.741211 755.569824,734.424377 
	C754.338196,733.553833 753.563782,732.664551 752.640869,731.973450 
	C751.628357,731.215332 750.268677,729.930298 749.445618,730.174316 
	C748.528870,730.446228 747.569946,732.149109 747.491760,733.288269 
	C747.387268,734.810425 748.112732,736.389648 748.481750,737.944336 
	C748.481750,737.944336 748.401917,737.894226 748.428040,737.880493 
	C748.100830,735.480347 747.747559,733.093933 747.184692,730.376587 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M731.368286,762.906799 
	C729.343506,761.327515 727.154846,760.056580 726.282104,758.175232 
	C725.669922,756.855591 726.852173,754.703491 727.233032,752.923157 
	C728.474243,754.026184 729.715454,755.129211 730.716125,756.018494 
	C731.154724,755.072632 731.992371,753.265991 732.945374,751.210571 
	C731.963074,750.953735 730.844360,750.661255 729.005737,750.180542 
	C732.969666,748.168152 736.063782,748.977783 738.035950,751.559753 
	C739.337708,753.264099 740.167358,756.284546 739.598938,758.250366 
	C738.608093,761.677063 735.624817,763.447083 731.368286,762.906799 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M794.842529,741.513489 
	C795.950073,741.993591 797.057617,742.473633 798.509766,743.103088 
	C790.417908,748.316589 782.646301,745.064575 779.078735,735.147705 
	C786.724854,735.147705 794.205627,735.147705 801.918823,735.147705 
	C802.106201,739.589722 799.912964,741.465454 795.187134,741.044556 
	C793.635071,739.187134 792.572449,737.633850 790.938416,735.245300 
	C789.237915,738.180969 788.030212,740.265930 786.838257,742.323792 
	C790.095459,745.641418 792.368286,742.791565 794.842529,741.513489 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M762.858521,741.070557 
	C766.465942,741.070557 770.073364,741.070557 774.070435,741.070557 
	C774.070435,739.369629 774.070435,737.980103 774.112610,736.189819 
	C776.651794,738.360718 779.148804,740.932495 781.645813,743.504211 
	C781.346619,744.063293 781.047363,744.622437 780.748108,745.181519 
	C774.981689,745.181519 769.215332,745.181519 762.977295,744.696777 
	C762.623291,743.164917 762.740906,742.117737 762.858521,741.070557 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M748.605103,738.331665 
	C748.112732,736.389648 747.387268,734.810425 747.491760,733.288269 
	C747.569946,732.149109 748.528870,730.446228 749.445618,730.174316 
	C750.268677,729.930298 751.628357,731.215332 752.640869,731.973450 
	C753.563782,732.664551 754.338196,733.553833 755.578857,734.841736 
	C755.936218,737.338684 755.892822,739.349670 755.849487,741.360657 
	C753.400085,740.684326 749.251404,744.588318 748.605103,738.331665 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M755.742798,741.691284 
	C755.892822,739.349670 755.936218,737.338684 755.970581,734.910339 
	C757.695496,734.741211 759.429443,734.989502 761.616333,735.323364 
	C762.069397,735.408936 762.074524,735.417114 761.669678,735.451172 
	C760.983337,738.666321 760.701843,741.847412 760.420288,745.028503 
	C756.616821,745.028503 752.813354,745.028503 749.009888,745.028503 
	C748.997925,744.575012 748.986023,744.121460 748.974060,743.667969 
	C751.194763,743.119263 753.415466,742.570557 755.742798,741.691284 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M794.836304,741.499634 
	C792.368286,742.791565 790.095459,745.641418 786.838257,742.323792 
	C788.030212,740.265930 789.237915,738.180969 790.938416,735.245300 
	C792.572449,737.633850 793.635071,739.187134 794.763550,741.098022 
	C794.829468,741.455566 794.830017,741.485840 794.836304,741.499634 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M760.673950,744.983276 
	C760.701843,741.847412 760.983337,738.666321 761.651794,735.475708 
	C762.146545,737.119690 762.254395,738.773071 762.610352,740.748535 
	C762.740906,742.117737 762.623291,743.164917 762.480896,744.608521 
	C761.946655,744.982605 761.437195,744.960266 760.673950,744.983276 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M682.159607,743.184814 
	C679.950500,743.455872 677.707092,743.537048 675.204834,743.139893 
	C674.945984,742.661560 674.910278,742.164062 674.910278,742.164062 
	C677.005371,742.127869 679.100464,742.091675 681.640259,742.135742 
	C682.072937,742.476929 682.086426,742.736633 682.159607,743.184814 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M997.148132,659.120911 
	C994.626892,657.926941 992.199402,656.346924 989.401855,654.870972 
	C989.031738,654.975037 988.997681,654.964478 989.023865,654.668823 
	C988.709351,654.246460 988.368591,654.119751 988.017700,653.992859 
	C988.007446,653.992676 988.006226,653.972351 988.009583,653.683105 
	C987.683594,653.259338 987.354187,653.124756 987.014771,652.992798 
	C987.004700,652.995300 987.009766,652.975159 987.012573,652.687622 
	C986.688049,652.267212 986.360718,652.134399 986.024963,651.993713 
	C986.016602,651.985840 986.004272,651.966492 986.079102,651.629883 
	C984.235657,648.245361 982.860657,648.538940 981.992798,651.979431 
	C981.989807,651.989380 981.969849,651.995422 981.672668,651.973267 
	C981.247314,652.292114 981.119141,652.632996 980.993042,652.984497 
	C980.995117,652.995117 980.973938,652.990906 980.675293,652.974487 
	C980.249390,653.295349 980.122192,653.632629 979.993530,653.980652 
	C979.992065,653.991333 979.970642,653.990356 979.622742,653.902588 
	C977.702393,655.205566 976.278748,656.839844 974.514893,657.917114 
	C972.813232,658.956482 970.760437,659.421082 968.904419,659.686462 
	C968.634338,656.160156 968.321838,653.085510 968.235352,649.716980 
	C970.377747,649.108765 972.328979,648.927673 974.199158,648.437195 
	C975.641052,648.059021 977.422974,647.634216 978.228943,646.592163 
	C978.895264,645.730652 978.704529,643.787720 978.206909,642.615295 
	C977.944885,641.997925 975.995483,641.479187 975.180542,641.816406 
	C970.610107,643.707520 966.189270,642.243713 961.682983,641.742737 
	C960.295471,641.588501 958.785217,642.472473 957.351929,642.944275 
	C957.257019,642.975525 957.339539,643.545898 957.339539,644.077515 
	C958.508667,644.204163 959.688904,644.332031 961.009155,644.475037 
	C960.407104,645.883850 959.961365,646.926880 959.202271,647.941162 
	C957.933289,647.613953 956.977600,647.315552 956.022705,646.607727 
	C955.028503,642.809875 954.033630,639.421509 953.038757,636.033081 
	C953.366394,634.982666 953.694031,633.932190 954.420776,632.753662 
	C956.237549,632.261292 957.655334,631.897095 959.201416,631.820557 
	C959.832825,632.464050 960.335815,632.819763 961.028687,633.394714 
	C961.727722,633.886902 962.236755,634.159729 962.745850,634.432617 
	C962.828430,634.573853 962.876221,634.767761 962.998718,634.848572 
	C971.328979,640.346863 973.865051,638.790527 976.099121,626.350891 
	C976.149963,625.854919 976.368286,625.646179 976.368286,625.646179 
	C981.688049,627.911804 987.038452,630.110413 992.294067,632.516113 
	C993.071716,632.872131 993.362549,634.233459 993.948120,635.081604 
	C994.698486,636.168457 995.519348,637.206665 996.309875,638.265808 
	C997.136780,637.478333 997.963745,636.690918 999.212769,635.501587 
	C1007.571411,639.264526 1016.279724,643.184937 1025.545898,647.356445 
	C1024.019897,649.278137 1023.163635,650.356323 1022.164429,651.730713 
	C1021.402039,652.245667 1020.718079,652.360535 1020.173767,652.699585 
	C1016.429626,655.031921 1012.702942,658.297852 1009.400696,651.715637 
	C1007.517273,653.329712 1006.213928,654.446716 1004.684326,655.757568 
	C1003.605530,654.464233 1002.725464,653.409119 1002.005859,652.546448 
	C1000.265076,653.219910 998.635132,653.850586 996.582336,654.644775 
	C996.726074,655.536011 996.984009,657.135437 997.148132,659.120911 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M917.897278,658.982605 
	C920.057678,658.147461 922.218079,657.312378 924.665222,656.233643 
	C925.582153,656.007141 926.212402,656.024170 927.550293,656.212891 
	C932.077271,655.324463 934.872009,656.227051 935.525024,660.437378 
	C937.335632,660.184570 938.717468,659.991577 940.047546,660.177002 
	C939.055054,661.116943 938.073425,661.621094 937.184509,662.255310 
	C936.177551,662.973694 935.253723,663.808594 934.355225,664.917358 
	C934.133423,665.818420 933.850159,666.395020 933.172729,666.976624 
	C930.870178,666.981567 928.961792,666.981567 927.621704,666.981567 
	C927.621704,665.688049 927.993530,664.016174 927.562195,663.773865 
	C924.431519,662.014954 921.138733,660.544373 917.897278,658.982605 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M955.144958,629.333435 
	C951.355469,629.744446 949.683411,627.563354 951.005493,623.933777 
	C953.426880,617.286743 958.618530,616.008423 965.309753,620.644958 
	C964.266968,622.433472 963.209412,624.247192 961.571655,626.386719 
	C959.042664,627.586243 957.093811,628.459839 955.144958,629.333435 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M952.679871,635.957886 
	C954.033630,639.421509 955.028503,642.809875 955.577393,646.634399 
	C953.055908,647.095520 950.980469,647.120605 948.557251,647.144287 
	C947.094360,647.271912 945.979187,647.401062 944.665161,647.220825 
	C944.141357,644.626404 943.816467,642.341309 943.491516,640.056213 
	C943.491516,640.056213 943.769104,639.976379 944.332092,640.023926 
	C947.141968,640.387634 949.388916,640.703735 951.635803,641.019897 
	C951.540222,641.594055 951.444641,642.168274 951.349060,642.742432 
	C949.489868,642.559814 947.630676,642.377136 945.771484,642.194458 
	C945.709717,642.780029 945.647949,643.365540 945.586243,643.951111 
	C947.770142,644.216797 949.953857,644.628601 952.138123,644.631531 
	C952.605225,644.632141 953.661987,642.721130 953.444031,642.422058 
	C952.315125,640.873474 951.031189,639.311462 949.466370,638.246277 
	C948.176697,637.368164 946.419617,637.176514 944.869690,636.680664 
	C946.916382,632.333252 949.768921,635.511108 952.679871,635.957886 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M940.099304,659.798645 
	C938.717468,659.991577 937.335632,660.184570 935.525024,660.437378 
	C934.872009,656.227051 932.077271,655.324463 927.865540,656.212463 
	C928.786438,654.802551 930.099854,653.564636 931.389465,652.349182 
	C930.798035,652.100403 929.378601,651.503418 927.871521,650.869507 
	C930.286987,649.330078 932.225098,647.725525 934.450623,646.858948 
	C935.336426,646.513977 937.438354,647.476074 937.966309,648.416077 
	C938.980957,650.222778 939.170105,652.475281 939.923767,654.458496 
	C940.117126,654.967346 941.139160,655.161255 941.995544,656.171997 
	C942.202820,658.169556 942.195068,659.494995 941.785339,660.763062 
	C940.955383,660.403381 940.527344,660.101013 940.099304,659.798645 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M942.187317,660.820374 
	C942.195068,659.494995 942.202820,658.169556 942.271301,656.350464 
	C942.309448,651.691406 942.244202,647.525574 942.294312,643.361023 
	C942.306824,642.320312 942.691040,641.284119 943.197937,640.151001 
	C943.816467,642.341309 944.141357,644.626404 944.496338,647.637085 
	C944.494751,652.148193 944.463074,655.933777 944.322205,660.129639 
	C944.562012,662.415955 944.911011,664.291809 945.260010,666.167725 
	C944.737488,669.823242 944.215027,673.478699 943.548950,677.574829 
	C942.581909,678.805725 941.758301,679.595886 940.961914,680.688965 
	C940.899841,681.560425 940.810608,682.128906 940.209717,682.887573 
	C939.446594,683.063416 939.195129,683.049072 938.975464,683.027588 
	C939.007263,683.020447 938.955933,683.061096 938.958008,682.720337 
	C939.282898,680.944458 939.119141,678.863220 940.014832,678.188660 
	C943.449951,675.601501 943.259460,672.536499 942.083252,668.622498 
	C942.102783,666.255493 942.073181,664.356140 942.060425,662.250000 
	C942.114014,661.635498 942.150635,661.227966 942.187317,660.820374 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M955.081665,629.642334 
	C957.093811,628.459839 959.042664,627.586243 961.357910,626.661621 
	C962.232239,626.746887 962.740295,626.883057 963.544617,627.394958 
	C963.863098,628.496887 963.885254,629.223083 963.907410,629.949280 
	C963.907410,629.949280 963.976440,629.986206 963.682434,629.850464 
	C963.303467,630.090820 963.218567,630.466858 963.133667,630.842896 
	C963.152222,631.884399 963.170776,632.925903 962.967590,634.200012 
	C962.236755,634.159729 961.727722,633.886902 961.044189,633.096863 
	C960.270874,632.230774 959.671936,631.881836 959.073059,631.532837 
	C957.655334,631.897095 956.237549,632.261292 954.418274,632.423462 
	C954.350586,631.464661 954.684448,630.708008 955.081665,629.642334 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M944.570923,636.943481 
	C946.419617,637.176514 948.176697,637.368164 949.466370,638.246277 
	C951.031189,639.311462 952.315125,640.873474 953.444031,642.422058 
	C953.661987,642.721130 952.605225,644.632141 952.138123,644.631531 
	C949.953857,644.628601 947.770142,644.216797 945.586243,643.951111 
	C945.647949,643.365540 945.709717,642.780029 945.771484,642.194458 
	C947.630676,642.377136 949.489868,642.559814 951.349060,642.742432 
	C951.444641,642.168274 951.540222,641.594055 951.635803,641.019897 
	C949.388916,640.703735 947.141968,640.387634 944.476318,640.031616 
	C944.129089,639.063232 944.200623,638.134766 944.570923,636.943481 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M964.278564,630.079590 
	C963.885254,629.223083 963.863098,628.496887 963.942566,627.384827 
	C966.521790,623.049561 969.032776,625.280640 972.705078,627.139343 
	C969.400940,628.398865 967.025330,629.304382 964.278564,630.079590 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M969.118713,635.403442 
	C967.443298,634.729736 966.180969,634.106934 963.759460,632.912292 
	C967.067932,631.494263 969.279053,630.546570 971.490234,629.598877 
	C971.883057,629.969238 972.275940,630.339600 972.668762,630.709961 
	C971.623108,632.291443 970.577454,633.872986 969.118713,635.403442 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M927.480042,643.938110 
	C927.185547,643.446167 927.227051,643.132324 927.242676,642.582397 
	C929.855042,640.740417 931.349915,641.574158 932.405762,645.370483 
	C930.403687,644.823059 929.112122,644.469910 927.480042,643.938110 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M971.033569,622.963379 
	C970.232178,622.903564 969.430725,622.843811 968.629333,622.783997 
	C968.663574,622.543823 968.697815,622.303650 968.732117,622.063416 
	C969.365234,622.204834 969.998413,622.346313 970.813049,622.744385 
	C970.994507,623.001038 971.033569,622.963318 971.033569,622.963379 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M970.973755,623.018555 
	C971.391724,623.293457 971.830505,623.550903 972.269226,623.808350 
	C971.834167,623.724915 971.399048,623.641479 970.998779,623.260681 
	C971.033569,622.963318 970.994507,623.001038 970.973755,623.018555 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M976.040161,625.958618 
	C975.700256,625.799805 975.470032,625.537354 975.239807,625.274963 
	C975.494568,625.260071 975.749268,625.245117 976.186157,625.438171 
	C976.368286,625.646179 976.149963,625.854919 976.040161,625.958618 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M963.358765,630.757324 
	C963.218567,630.466858 963.303467,630.090820 963.676147,629.811890 
	C964.104187,630.296143 963.977539,630.550476 963.358765,630.757324 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M925.733887,652.834961 
	C925.595520,652.437195 925.827209,651.940369 926.502563,651.477173 
	C926.665405,651.919189 926.384644,652.327515 925.733887,652.834961 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M918.114746,748.426880 
	C917.810059,749.667847 916.762390,750.793457 916.633911,752.015686 
	C916.263123,755.543213 916.262817,759.109680 916.064819,763.106812 
	C914.015625,762.159363 912.072693,760.678833 910.007324,759.395325 
	C907.563110,757.876343 907.205444,756.027893 908.853760,753.337830 
	C910.697388,749.121521 913.004333,746.379700 918.114746,748.426880 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M902.968445,757.633850 
	C900.008240,759.953369 896.856628,761.983276 893.325684,764.048645 
	C892.946289,759.919983 892.946289,755.755798 892.946289,750.232300 
	C896.847900,753.054871 899.812500,755.199524 902.968445,757.633850 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M954.997070,813.897827 
	C953.353821,813.912354 952.067200,813.912354 950.453247,813.912354 
	C950.453247,811.063171 950.453247,808.507141 950.453247,805.951111 
	C950.696411,805.753967 950.939636,805.556824 951.182800,805.359680 
	C952.811646,808.093628 954.441772,810.826843 956.057007,813.568787 
	C956.080750,813.609192 955.937378,813.809387 955.838989,813.845886 
	C955.692749,813.900085 955.516968,813.874817 954.997070,813.897827 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M954.731628,807.222046 
	C953.387939,805.132751 952.242188,803.335144 950.850830,801.152161 
	C952.829773,801.152161 954.514832,801.152161 956.614990,801.152161 
	C956.614990,803.839478 956.614990,806.478027 956.614990,809.797729 
	C955.830750,808.734863 955.380188,808.124268 954.731628,807.222046 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M577.088379,870.608887 
	C558.780823,871.088318 540.679993,871.263245 522.286377,871.735352 
	C516.998962,872.030396 512.004333,872.028198 506.554810,872.084412 
	C488.006622,872.091064 469.913330,872.038391 451.820068,871.988464 
	C449.171906,871.981201 446.523651,871.987488 444.013550,871.987488 
	C440.903717,864.657959 441.293335,864.045654 448.686920,864.019043 
	C457.843231,863.986023 466.999481,863.939453 477.045441,863.858032 
	C485.702332,863.610657 493.469910,863.221680 501.236725,863.236450 
	C516.346313,863.265198 531.455688,863.665894 546.564697,863.637451 
	C559.516907,863.613037 572.468140,863.189331 585.420410,862.977234 
	C588.604187,862.925171 591.790222,863.009583 595.161499,863.346924 
	C598.165894,866.560547 600.593445,864.794739 603.020264,863.020020 
	C606.449036,862.996399 609.886780,863.117432 613.303955,862.906189 
	C616.390686,862.715393 617.513733,863.977905 617.011230,867.338623 
	C615.737732,868.472595 614.475708,869.188171 612.969910,870.041992 
	C608.262878,865.228394 603.884216,866.883789 599.903259,872.991577 
	C599.738770,872.312866 599.574280,871.634216 599.409851,870.955566 
	C599.800781,870.104675 600.191711,869.253784 600.644531,868.268311 
	C595.570923,868.566162 590.859131,868.842712 585.150391,869.177856 
	C583.154968,866.229187 580.209717,867.275574 577.088379,870.608887 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M590.222900,859.016418 
	C587.694824,857.904480 585.166748,856.792480 582.021423,855.409058 
	C585.166382,852.776245 593.312988,857.091431 591.493103,849.381775 
	C590.999268,847.289978 586.281433,846.195190 582.638550,844.160339 
	C582.667847,843.555542 582.759583,841.664917 582.906738,839.136963 
	C582.956787,838.283875 582.951294,838.068115 582.918762,837.640381 
	C582.542236,837.286804 582.192749,837.145325 581.458496,836.897278 
	C579.452637,830.512085 578.752625,824.274780 581.661072,818.147339 
	C584.152100,815.792419 586.772644,813.175171 591.066223,814.875732 
	C593.176941,814.950195 595.099915,814.785339 597.439819,814.551270 
	C598.922607,814.239258 599.988586,813.996521 601.076172,814.125488 
	C603.263672,818.046570 603.855591,818.324524 606.541809,817.053589 
	C606.721130,825.823669 606.980408,834.593933 606.958740,843.363525 
	C606.956482,844.262329 605.405701,845.643250 604.336121,845.937317 
	C602.803467,846.358643 601.045837,845.876709 599.395935,845.975586 
	C596.777527,846.132629 595.238464,846.993774 596.637573,850.097473 
	C597.119934,851.167480 596.714294,852.637817 596.714294,855.005676 
	C599.277344,854.040100 601.504333,853.201050 604.022827,852.252258 
	C604.690308,853.074463 605.804016,854.446350 607.420288,856.437317 
	C605.414185,856.693787 604.040161,856.869507 601.876587,857.045471 
	C599.789246,857.186218 598.491516,857.326599 596.814575,857.319580 
	C594.539917,857.770325 592.644592,858.368530 590.617249,858.974609 
	C590.485229,858.982483 590.222900,859.016418 590.222900,859.016418 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M613.920410,815.737915 
	C614.905701,816.037415 615.585815,816.552551 616.556885,817.198242 
	C616.847961,830.245117 616.847961,843.161377 616.847961,856.833435 
	C614.465210,856.716675 612.286133,856.609924 610.107056,856.503174 
	C610.042969,856.037109 609.978882,855.571045 609.914795,855.105042 
	C611.096436,854.504639 612.278076,853.904236 614.016724,853.020813 
	C607.130005,840.312561 610.901855,826.477539 609.856201,813.793762 
	C611.017578,814.461060 612.316345,815.207336 613.920410,815.737915 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M607.895447,804.998169 
	C607.743225,807.005188 607.628052,809.015930 607.427246,811.018066 
	C607.265198,812.633362 606.999695,814.238281 606.218994,815.665527 
	C604.545654,814.638794 603.433472,813.794373 602.224121,812.584534 
	C602.351685,810.997559 602.576477,809.776062 603.015625,808.353882 
	C603.128174,807.106873 603.026306,806.060547 602.901733,804.720703 
	C602.847595,804.037109 602.816162,803.646973 602.868774,802.888672 
	C603.801819,800.452942 604.650940,798.385498 605.819275,795.540588 
	C606.993286,797.874390 607.532898,798.947021 608.026672,800.433960 
	C607.952393,802.231506 607.923889,803.614868 607.895447,804.998169 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M597.193848,857.467041 
	C598.491516,857.326599 599.789246,857.186218 601.478149,857.098083 
	C601.969177,857.638916 602.069092,858.127502 602.456177,859.104980 
	C603.176575,860.052490 603.609924,860.510925 604.043213,860.969421 
	C603.771484,861.473145 603.499695,861.976807 603.124146,862.750244 
	C600.593445,864.794739 598.165894,866.560547 595.172607,862.994507 
	C595.729614,860.706604 596.461731,859.086792 597.193848,857.467041 
M599.806519,859.247192 
	C599.805542,859.449707 599.804504,859.652283 599.803467,859.854797 
	C599.954651,859.853821 600.229736,859.879578 600.236694,859.847473 
	C600.278687,859.652893 600.259521,859.445129 599.806519,859.247192 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M608.140381,805.068970 
	C607.923889,803.614868 607.952393,802.231506 608.305847,800.444336 
	C609.082764,799.758240 609.534729,799.476135 610.312134,799.060303 
	C611.809937,798.669800 612.982422,798.412903 614.154846,798.156006 
	C614.264526,798.511169 614.374207,798.866394 614.483948,799.221619 
	C613.241150,800.240845 611.998352,801.260132 609.660278,803.177734 
	C612.359619,803.177734 613.693420,803.177734 615.027283,803.177734 
	C615.027283,803.177734 615.043518,803.454590 615.015198,803.589111 
	C613.255981,804.463745 611.525085,805.203918 609.400208,805.927612 
	C608.799255,805.653931 608.592285,805.396851 608.140381,805.068970 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M604.503296,860.965820 
	C603.609924,860.510925 603.176575,860.052490 602.599121,859.309998 
	C607.117798,859.026001 611.780640,859.026001 616.443481,859.026001 
	C616.477539,859.671387 616.511597,860.316833 616.545654,860.962219 
	C612.684875,860.962219 608.824158,860.962219 604.503296,860.965820 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M602.321289,812.949951 
	C603.433472,813.794373 604.545654,814.638794 605.851318,815.728027 
	C606.090698,816.170044 606.136475,816.367188 606.362061,816.808960 
	C603.855591,818.324524 603.263672,818.046570 601.207520,813.889160 
	C601.651917,813.170837 601.986633,813.060425 602.321289,812.949951 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M595.244019,763.141663 
	C597.791077,759.524414 600.397400,755.946777 602.853455,752.268738 
	C603.728333,750.958557 604.238708,749.405090 604.915527,747.962646 
	C605.589172,748.063416 606.262878,748.164185 606.936584,748.265015 
	C606.936584,754.201172 606.936584,760.137390 606.927979,767.009277 
	C606.850708,776.278503 606.782104,784.612061 606.403442,792.963196 
	C605.072754,793.569885 604.052124,794.159058 602.775696,794.901245 
	C602.271118,795.358887 602.022339,795.663391 601.487610,796.026855 
	C600.794067,796.682861 600.386475,797.279907 599.658569,797.961548 
	C599.129333,798.118103 598.920532,798.190125 598.409790,798.163696 
	C597.048523,797.389221 595.989258,796.713074 594.995911,795.577515 
	C595.061829,787.499451 595.061829,779.880737 595.061829,770.970825 
	C596.771851,773.059082 597.473633,773.916077 598.175415,774.773010 
	C598.717224,773.049622 598.612244,770.687561 598.019531,770.558044 
	C593.000305,769.461243 595.290161,765.904785 595.244019,763.141663 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M601.061707,733.135254 
	C608.095886,729.434875 615.706604,731.682495 623.943481,731.039673 
	C626.328064,731.080750 627.803162,731.089661 629.278198,731.094116 
	C635.791199,731.113770 637.141235,732.948181 635.555786,739.505432 
	C632.755920,739.983337 629.992676,740.455017 627.014893,741.246277 
	C625.799744,741.725037 624.799072,741.884277 623.912354,741.732300 
	C626.462402,738.699097 625.565796,736.679260 622.338013,736.182251 
	C621.129700,735.996216 619.617737,737.782776 618.160950,738.266357 
	C616.059631,732.532227 612.894897,737.089722 610.273926,736.957825 
	C608.123535,737.253296 608.123535,737.253296 607.657837,741.628235 
	C603.413513,741.586304 602.013245,743.675171 601.531067,748.125183 
	C601.011841,752.917603 600.107056,758.350098 594.421631,760.505615 
	C594.052795,753.653625 594.055298,747.313293 594.036865,740.987854 
	C594.015808,741.002747 593.982239,741.041809 594.320068,740.991028 
	C596.796570,738.306274 598.935303,735.672363 601.044373,733.059204 
	C601.014771,733.080078 601.061707,733.135254 601.061707,733.135254 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M615.322632,802.941284 
	C613.693420,803.177734 612.359619,803.177734 609.660278,803.177734 
	C611.998352,801.260132 613.241150,800.240845 614.483948,799.221619 
	C614.374207,798.866394 614.264526,798.511169 614.154846,798.156006 
	C612.982422,798.412903 611.809937,798.669800 610.248230,798.669250 
	C609.589294,791.872131 609.339905,785.331543 609.042297,778.793152 
	C608.863525,774.867310 608.609192,770.944946 608.388794,767.020996 
	C608.388794,760.920410 608.388794,754.819885 608.388794,748.438904 
	C611.049438,748.438904 613.143127,748.438904 615.974487,748.438904 
	C616.325378,764.622131 616.677002,780.836548 617.006592,797.518250 
	C616.528992,799.558594 616.073486,801.131775 615.322632,802.941284 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M594.057861,740.972961 
	C594.055298,747.313293 594.052795,753.653625 594.065674,760.456177 
	C594.338013,761.487549 594.594971,762.056763 595.047974,762.883789 
	C595.290161,765.904785 593.000305,769.461243 598.019531,770.558044 
	C598.612244,770.687561 598.717224,773.049622 598.175415,774.773010 
	C597.473633,773.916077 596.771851,773.059082 595.061829,770.970825 
	C595.061829,779.880737 595.061829,787.499451 594.734497,795.609619 
	C594.058472,796.107178 593.709717,796.113342 593.208984,795.677856 
	C591.935852,791.392090 595.245178,786.541870 590.334717,783.212891 
	C590.144287,782.221313 590.020752,781.559998 590.307739,780.849854 
	C595.061401,780.120972 592.293152,776.843994 592.637451,774.856812 
	C592.817383,773.818481 591.179810,772.465271 590.852905,771.980469 
	C591.308655,769.821777 591.737183,767.791748 592.141357,765.522705 
	C592.117004,765.283569 591.850952,764.883301 591.998108,764.437622 
	C592.245667,757.548157 592.264893,751.101196 592.516541,744.663269 
	C592.565247,743.414978 593.520691,742.202148 594.057861,740.972961 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M608.035400,767.013794 
	C608.609192,770.944946 608.863525,774.867310 609.042297,778.793152 
	C609.339905,785.331543 609.589294,791.872131 609.922852,798.802856 
	C609.534729,799.476135 609.082764,799.758240 608.351624,800.030029 
	C607.532898,798.947021 606.993286,797.874390 605.819275,795.540588 
	C604.650940,798.385498 603.801819,800.452942 602.647156,802.714111 
	C601.905640,802.582886 601.469727,802.257996 601.057617,801.967651 
	C601.081360,802.002197 601.152100,801.957275 601.123352,801.594849 
	C600.300354,800.242371 599.506042,799.252197 598.711792,798.262085 
	C598.920532,798.190125 599.129333,798.118103 599.979614,797.951416 
	C601.005249,797.227112 601.389404,796.597534 601.773560,795.967957 
	C602.022339,795.663391 602.271118,795.358887 603.107361,794.950806 
	C604.701050,794.213379 605.707275,793.579529 606.713562,792.945679 
	C606.782104,784.612061 606.850708,776.278503 606.947266,767.478027 
	C607.210815,767.009644 607.446411,767.008057 608.035400,767.013794 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M618.244202,738.669922 
	C619.617737,737.782776 621.129700,735.996216 622.338013,736.182251 
	C625.565796,736.679260 626.462402,738.699097 623.679688,741.922363 
	C623.268433,742.814819 623.203735,743.206055 623.138977,743.597229 
	C622.049438,743.437073 620.959900,743.276978 619.473206,743.001953 
	C619.076050,742.887085 619.048767,742.393982 618.894287,741.903503 
	C618.491821,741.153137 618.243835,740.893250 617.995789,740.633362 
	C617.995789,740.633362 618.022217,740.267273 617.976013,739.873657 
	C618.040039,739.212097 618.144836,738.942017 618.244202,738.669922 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M618.160950,738.266357 
	C618.144836,738.942017 618.040039,739.212097 617.920532,739.698730 
	C617.555298,740.040100 617.199280,740.162842 616.292236,739.977600 
	C613.931458,739.458801 612.121704,739.247925 610.312012,739.037048 
	C610.264404,738.575012 610.216797,738.112915 610.221558,737.304321 
	C612.894897,737.089722 616.059631,732.532227 618.160950,738.266357 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M610.230835,739.368530 
	C612.121704,739.247925 613.931458,739.458801 616.008057,739.988770 
	C614.858765,740.691284 613.442505,741.074646 611.728821,741.707031 
	C611.431396,741.956055 611.165894,741.938782 611.165894,741.938782 
	C610.827148,741.192505 610.488403,740.446289 610.230835,739.368530 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M618.039978,740.919434 
	C618.243835,740.893250 618.491821,741.153137 618.835449,741.663757 
	C618.648743,741.678162 618.366455,741.441833 618.039978,740.919434 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M611.190063,742.113892 
	C611.165894,741.938782 611.431396,741.956055 611.562622,741.954651 
	C611.533936,742.065186 611.374084,742.177124 611.190063,742.113892 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M948.319153,741.493103 
	C954.336426,741.218140 960.269104,741.218140 966.201843,741.218140 
	C966.236572,741.805542 966.271301,742.393005 966.306091,742.980408 
	C960.554993,742.980408 954.803894,742.980408 948.597168,742.941162 
	C948.172607,742.523987 948.203613,742.146057 948.319153,741.493103 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M930.146973,743.069031 
	C930.686462,742.380859 931.268799,742.041016 931.851074,741.701172 
	C931.940491,742.149841 932.029968,742.598511 932.119385,743.047241 
	C931.476196,743.170593 930.833069,743.293945 930.146973,743.069031 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M925.220032,742.570312 
	C924.833313,742.016052 924.785339,741.503906 924.737427,740.991821 
	C925.033691,741.007629 925.585876,741.007446 925.588074,741.041809 
	C925.620789,741.562744 925.581360,742.088135 925.220032,742.570312 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M916.414246,742.234131 
	C916.673218,742.245178 916.729553,742.448730 916.785889,742.652283 
	C916.594421,742.577026 916.403015,742.501831 916.414246,742.234131 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M906.281494,810.517761 
	C905.978149,809.639099 906.001282,808.758240 905.988281,807.435791 
	C907.394531,806.994263 908.837036,806.994263 910.279480,806.994263 
	C910.487305,807.354614 910.695129,807.715027 910.902954,808.075378 
	C909.471252,808.888733 908.039612,809.702087 906.281494,810.517761 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M906.027344,806.708008 
	C905.672180,806.814087 905.332642,806.578918 904.953674,806.018066 
	C905.290527,805.917236 905.666748,806.142029 906.027344,806.708008 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M707.749023,878.253784 
	C706.965027,879.083252 706.292908,880.174438 705.517334,881.433594 
	C701.618958,880.861877 701.319092,877.356506 701.728333,874.831665 
	C702.286499,871.388367 708.637817,870.980042 714.362305,873.680542 
	C715.513550,879.192627 713.035461,882.271057 707.227539,883.507141 
	C707.445007,881.793152 707.652954,880.154297 707.749023,878.253784 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M989.002686,888.012573 
	C990.898865,886.185791 992.786011,884.344299 995.419434,881.774597 
	C992.767944,881.144226 991.487671,880.839844 990.207397,880.535522 
	C990.163025,880.059021 990.118652,879.582520 990.074219,879.106018 
	C991.764404,878.855713 993.485352,878.318665 995.129578,878.495483 
	C995.793091,878.566833 996.992371,880.540894 996.739197,881.063416 
	C995.631348,883.349976 994.206543,885.524109 992.603821,887.501648 
	C992.187317,888.015442 990.712341,887.671265 989.344238,887.849365 
	C988.964966,887.977600 988.993652,887.997925 989.002686,888.012573 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1009.370728,689.360962 
	C1009.605286,689.333862 1009.689148,689.436523 1009.773010,689.539185 
	C1009.588684,689.523071 1009.404358,689.506897 1009.370728,689.360962 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M814.693726,880.431030 
	C815.047974,879.820618 815.372131,879.645996 816.247375,879.220337 
	C817.861328,879.006165 818.924133,879.043091 820.071655,879.326111 
	C820.460144,879.712769 820.764099,879.853333 821.067993,879.993896 
	C819.398560,883.698792 817.156250,883.200989 814.693726,880.431030 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M821.149780,873.707153 
	C821.305725,873.596191 821.461731,873.485229 821.816650,873.167358 
	C823.863281,874.892761 825.163879,876.886902 822.215454,879.074463 
	C821.380920,878.577026 821.063904,878.308228 820.707520,877.657837 
	C820.664490,876.255554 820.660828,875.234802 820.818237,874.124146 
	C820.979309,874.034241 821.149780,873.707153 821.149780,873.707153 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M813.632446,876.978027 
	C814.024231,877.413208 814.043884,877.892578 814.035522,878.734131 
	C813.385620,878.945679 812.763855,878.794922 812.142090,878.644287 
	C812.514832,878.103577 812.887634,877.562866 813.632446,876.978027 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M820.746887,878.039368 
	C821.063904,878.308228 821.380920,878.577026 821.832764,879.130005 
	C821.916809,879.706970 821.749451,879.896973 821.266663,879.989014 
	C820.764099,879.853333 820.460144,879.712769 820.118286,879.102051 
	C820.213196,878.333862 820.435425,878.136414 820.746887,878.039368 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M969.064331,701.942871 
	C969.228516,702.095520 969.353455,702.284729 969.478394,702.473877 
	C969.356689,702.438538 969.234985,702.403198 969.032715,702.212891 
	C968.952209,702.057861 969.025146,701.979431 969.064331,701.942871 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M951.062622,876.522217 
	C951.118774,875.374084 951.170044,874.611328 951.316223,872.436646 
	C953.319885,875.766052 954.621277,877.928467 956.102966,880.390442 
	C951.990356,882.044373 950.695374,880.606384 951.062622,876.522217 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M928.162659,762.157471 
	C925.038635,764.412842 921.702332,765.321289 919.343689,762.546387 
	C917.612305,760.509399 917.256165,757.303284 916.287170,754.618225 
	C917.076782,754.218689 917.866394,753.819153 918.656006,753.419678 
	C919.964172,755.164795 921.272339,756.909912 922.580444,758.655029 
	C922.826660,758.555359 923.072937,758.455688 923.319153,758.356018 
	C922.565796,756.094299 921.812500,753.832581 920.868042,750.997253 
	C922.716492,749.275574 925.114258,749.652100 927.539062,751.756653 
	C931.093201,754.841431 931.024292,758.239014 928.162659,762.157471 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M820.707520,877.657837 
	C820.435425,878.136414 820.213196,878.333862 820.033630,878.855957 
	C818.924133,879.043091 817.861328,879.006165 816.399170,878.983887 
	C815.885681,877.510376 815.771423,876.022217 816.222534,874.244690 
	C818.077698,874.041565 819.367493,874.127747 820.657227,874.213989 
	C820.660828,875.234802 820.664490,876.255554 820.707520,877.657837 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M820.818237,874.124084 
	C819.367493,874.127747 818.077698,874.041565 816.397217,873.947815 
	C817.643250,873.642883 819.280029,873.345581 821.033325,873.377686 
	C821.149780,873.707153 820.979309,874.034241 820.818237,874.124084 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1037.123535,664.247803 
	C1037.399292,662.869690 1037.866333,661.776672 1038.468994,660.345459 
	C1040.977783,660.007385 1043.350952,660.007385 1045.724243,660.007385 
	C1045.774170,660.731873 1045.824097,661.456421 1045.874023,662.180908 
	C1043.020996,662.964905 1040.167969,663.748840 1037.123535,664.247803 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1040.517090,668.867798 
	C1040.051392,667.903564 1039.638428,666.402100 1039.828979,666.321167 
	C1041.913452,665.435730 1044.092041,664.772156 1046.312500,664.025574 
	C1045.893433,669.779053 1045.120117,670.457092 1040.517090,668.867798 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M983.003540,684.335449 
	C982.977112,682.902832 983.020691,681.854736 983.013672,680.415771 
	C985.057129,682.176575 987.151245,684.328247 990.089172,687.346924 
	C986.007996,687.867188 983.346924,688.961243 983.003540,684.335449 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M989.048767,654.980896 
	C990.564453,656.879761 992.063110,658.772827 994.368713,661.685181 
	C990.578613,664.648926 986.634827,667.732727 982.333618,670.915283 
	C981.976196,671.013977 981.998474,671.001038 982.085144,670.668823 
	C980.776672,668.899780 979.381592,667.463013 977.986450,666.026245 
	C977.986450,666.026245 977.995117,666.004700 977.951843,665.737427 
	C977.601990,665.321594 977.295349,665.172974 976.988770,665.024353 
	C976.988770,665.024353 976.997559,665.002441 976.954163,664.736694 
	C976.602478,664.321106 976.294250,664.171326 975.986023,664.021484 
	C975.986023,664.021484 976.000000,664.000061 975.950317,663.738342 
	C975.593811,663.322083 975.286926,663.167542 974.980103,663.013000 
	C974.980103,663.013000 975.000244,663.001282 975.017700,662.641052 
	C976.680298,659.517334 978.325500,656.753845 979.970703,653.990356 
	C979.970642,653.990356 979.992065,653.991333 980.265564,653.956421 
	C980.684021,653.611267 980.828979,653.301086 980.973938,652.990906 
	C980.973938,652.990906 980.995117,652.995117 981.265076,652.960205 
	C981.679993,652.615417 981.824951,652.305420 981.969849,651.995422 
	C981.969849,651.995422 981.989807,651.989380 982.395874,652.000549 
	C983.869385,651.996643 984.936829,651.981567 986.004272,651.966431 
	C986.004272,651.966492 986.016602,651.985840 986.038940,652.270020 
	C986.377502,652.694458 986.693604,652.834839 987.009766,652.975159 
	C987.009766,652.975159 987.004700,652.995300 987.034180,653.268677 
	C987.377930,653.685486 987.692078,653.828918 988.006226,653.972351 
	C988.006226,653.972351 988.007446,653.992676 988.030701,654.270020 
	C988.368530,654.686401 988.683105,654.825439 988.997681,654.964478 
	C988.997681,654.964478 989.031738,654.975037 989.048767,654.980896 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M906.906677,876.353149 
	C908.016724,875.984985 909.114075,875.942871 910.622070,875.940430 
	C909.798401,876.698547 908.564087,877.417053 906.999817,878.047119 
	C906.744507,877.532166 906.819214,877.105652 906.906677,876.353149 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M903.390076,877.671387 
	C902.878845,877.691101 902.583130,877.585876 902.287476,877.480652 
	C902.726868,877.502563 903.166199,877.524475 903.390076,877.671387 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M632.850769,800.825928 
	C633.067505,800.751221 633.523071,800.911804 633.798218,801.273376 
	C633.441711,801.336609 633.265625,801.198914 632.850769,800.825928 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M671.651367,750.094360 
	C671.742249,750.790466 671.503113,751.559143 671.069763,752.581055 
	C670.114380,752.834290 669.353149,752.834290 668.591980,752.834290 
	C668.459473,752.578003 668.326904,752.321777 668.194397,752.065491 
	C669.236755,751.432617 670.279114,750.799805 671.651367,750.094360 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M912.722412,875.034607 
	C913.030823,875.140686 913.038879,875.308167 913.010925,875.724365 
	C912.528687,875.975647 912.082336,875.978271 911.301514,876.001404 
	C911.451965,875.713257 911.936951,875.404663 912.722412,875.034607 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M474.878845,649.816772 
	C476.805542,648.951416 478.375854,648.200439 480.010925,647.637451 
	C480.738708,647.386841 482.070984,647.172791 482.323883,647.523193 
	C485.455078,651.860901 489.602814,648.311035 493.175293,648.988220 
	C493.854614,649.117004 495.197449,648.688232 495.346680,648.228638 
	C497.535736,641.486633 503.113983,645.255737 507.147461,644.553101 
	C507.862457,644.428589 509.694000,646.640320 509.796295,647.873840 
	C510.052246,650.960266 509.611389,654.104492 508.739380,657.531738 
	C504.450928,657.742615 500.873169,657.647217 497.152039,657.205078 
	C496.521790,655.686340 496.189972,653.605347 495.522522,653.491455 
	C491.530579,652.810364 487.432007,652.164185 483.434875,652.443970 
	C482.145996,652.534180 481.055756,655.461243 479.878113,656.711792 
	C479.583496,654.885986 479.285370,653.438904 478.897369,651.555542 
	C477.294342,652.660400 476.398315,653.277954 475.322937,653.455444 
	C474.014679,653.107422 471.981079,652.972656 471.892609,653.325562 
	C470.557465,658.651001 466.181824,656.369812 463.328461,656.370483 
	C460.308899,656.371216 455.782532,658.901672 454.308105,653.320679 
	C454.212830,652.960022 452.149445,653.119385 450.605652,653.053589 
	C446.129425,651.763855 447.841919,656.294922 446.001801,657.245972 
	C444.924377,655.846375 443.846954,654.446838 441.969299,653.109863 
	C439.777100,653.211792 438.385101,653.251099 436.993103,653.290405 
	C432.625824,652.159668 429.782166,653.641968 428.014099,658.253784 
	C426.882172,658.463379 426.427429,658.549805 425.972656,658.636230 
	C424.001953,658.558777 421.962616,658.762451 420.084900,658.304138 
	C418.855988,658.004150 417.861053,656.745544 417.186768,655.431335 
	C417.851288,654.925415 418.090271,654.904541 418.450989,654.947876 
	C418.937317,654.854309 419.301880,654.696472 419.927734,654.780701 
	C420.386993,655.152710 420.584991,655.282715 420.798401,655.735596 
	C423.013336,656.367493 425.212769,656.676453 428.782715,657.177979 
	C427.635895,653.160400 426.885925,650.533142 426.455078,647.450073 
	C427.347443,646.529358 427.920746,646.064453 428.494049,645.599609 
	C429.186066,646.833069 429.878082,648.066589 430.733063,649.590515 
	C433.767609,647.934387 439.209595,651.761353 439.996582,645.409241 
	C440.530243,641.101562 439.774719,637.113464 433.481995,637.217651 
	C433.105347,636.817261 433.064453,636.642334 432.971191,636.479553 
	C435.413605,636.617004 437.856018,636.754517 440.510742,636.903931 
	C440.794006,640.573120 441.057495,643.985596 441.354248,647.829285 
	C443.174835,647.829285 444.745331,647.829285 446.706238,648.299683 
	C448.398071,649.170349 449.699432,649.570496 451.000824,649.970642 
	C452.220062,649.909851 454.488129,649.953857 454.506165,649.773132 
	C455.047394,644.353271 459.212952,646.116577 462.074738,646.135132 
	C465.758179,646.159058 470.507080,644.089600 472.191010,649.916809 
	C472.259674,650.154419 473.731018,649.986694 474.878845,649.816772 
M462.310913,651.340027 
	C460.664703,650.716248 459.018463,650.092407 456.958801,649.312012 
	C457.073853,651.307190 457.165375,652.894409 457.265961,654.638611 
	C460.099976,654.638611 462.617310,654.638611 465.305054,654.638611 
	C464.405457,653.239380 463.681305,652.112976 463.674194,650.986633 
	C465.523834,651.948303 467.373444,652.910034 469.402191,653.964844 
	C469.794373,647.427856 468.092926,646.430664 461.306885,648.686523 
	C461.879730,649.469604 462.452576,650.252686 462.310913,651.340027 
M505.058167,648.167847 
	C503.335938,647.301819 501.613708,646.435730 499.234344,645.239258 
	C499.234344,649.006104 499.234344,651.187927 499.234344,653.429565 
	C500.759277,652.845459 501.898895,652.408997 502.906799,652.663269 
	C502.382996,653.616699 501.859161,654.570068 501.335327,655.523438 
	C501.636383,655.958130 501.937439,656.392822 502.238495,656.827515 
	C504.115692,655.739746 506.695648,655.068115 507.659851,653.439697 
	C508.515656,651.994507 508.928833,648.624695 505.058167,648.167847 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M403.538086,555.178467 
	C406.234100,555.580200 408.930054,555.981873 411.890137,557.005249 
	C412.907898,558.680359 413.661591,559.733826 414.415283,560.787292 
	C411.429504,561.486572 408.443756,562.185852 405.350281,562.910339 
	C407.709930,568.879150 411.032806,565.509583 414.220337,565.500732 
	C413.279114,567.135376 411.978149,568.474182 410.677216,569.813049 
	C414.821350,570.468018 416.706024,568.854858 416.641144,565.231750 
	C419.256470,565.327576 421.871765,565.423401 424.787964,566.030518 
	C425.392944,566.696533 425.697052,566.851196 426.001160,567.005859 
	C427.643005,566.484131 429.284851,565.962402 431.333527,565.311401 
	C431.333527,567.456726 431.333527,569.423401 431.333527,570.210632 
	C428.614380,570.870544 426.569305,571.427124 424.491394,571.801697 
	C424.202515,571.853760 423.751801,571.008118 423.246063,569.986145 
	C422.738342,569.235779 422.360596,569.077332 421.982849,568.918823 
	C420.141663,569.861816 418.375824,571.383057 416.444489,571.634766 
	C411.469391,572.283203 406.412506,572.278503 401.408264,572.747620 
	C400.218628,572.859192 399.107269,573.805603 397.966675,574.781860 
	C397.973480,575.195862 397.995392,575.188904 397.995392,575.188904 
	C397.708710,574.230530 397.422058,573.272156 396.793579,571.726074 
	C397.680023,569.943970 398.908234,568.749512 400.468689,567.358887 
	C401.296387,565.499817 406.602783,566.048035 402.836548,562.211670 
	C402.465637,561.207642 402.211456,560.471924 402.162018,559.443726 
	C402.757202,557.827026 403.147644,556.502747 403.538086,555.178467 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M454.662537,632.363525 
	C454.671631,630.494446 454.750824,629.017822 454.852814,627.116089 
	C457.073700,626.821655 459.281494,626.528931 462.334076,626.124207 
	C462.050049,622.315857 461.775269,618.631775 461.500488,614.947693 
	C462.318207,612.356934 464.089294,612.382690 465.925751,615.411621 
	C465.234894,618.698059 464.234100,622.031677 463.233307,625.365356 
	C466.348663,627.661682 470.919220,624.558777 471.179504,629.727234 
	C471.523590,636.560608 471.386017,636.977844 464.381622,637.006409 
	C462.550720,637.013855 460.702850,636.921692 458.892914,637.133118 
	C455.445496,637.536011 454.385590,635.861694 454.662537,632.363525 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M448.369995,586.985413 
	C448.045990,590.179626 447.382477,593.253601 446.681793,596.499695 
	C449.446350,596.622192 451.381927,596.707886 453.568726,597.384033 
	C453.775787,598.246399 453.731598,598.518372 453.309143,598.662781 
	C451.622589,598.576233 450.314331,598.617188 448.732178,598.532593 
	C448.079865,598.940186 447.701416,599.473206 447.244629,600.443848 
	C446.751373,602.264771 446.336487,603.648193 445.883179,605.159729 
	C446.961609,606.074280 448.067383,607.012085 448.813019,608.006775 
	C448.423340,609.094177 448.393799,610.124695 448.364258,611.155151 
	C448.091461,611.581909 447.818634,612.008667 447.451355,612.671509 
	C446.088715,612.627197 444.820587,612.346802 443.347168,611.517700 
	C443.113159,610.348267 443.084412,609.727478 443.055664,609.106750 
	C446.879944,606.273560 446.068634,602.701416 444.323334,598.400085 
	C438.110657,598.400085 431.541595,598.400085 424.506104,598.052246 
	C424.033661,597.513123 424.027710,597.321838 424.021790,597.130615 
	C428.742462,596.983154 433.463165,596.835693 439.034180,596.671143 
	C441.442841,596.543640 443.001190,596.433289 444.559570,596.322937 
	C441.682465,596.491394 443.766968,588.543701 439.008759,592.651672 
	C438.094116,592.265625 437.179474,591.879578 435.980865,591.356995 
	C435.910278,590.633728 436.123657,590.046875 436.666809,589.551147 
	C438.825775,590.295837 440.935974,592.046936 441.675842,588.444519 
	C443.740479,587.702515 445.192169,584.494385 448.369995,586.985413 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M417.138397,600.286560 
	C416.278168,601.724670 415.574036,604.250183 414.528961,604.400208 
	C411.177124,604.881104 406.887238,605.659363 404.537720,604.037048 
	C402.790771,602.830750 403.191467,598.066528 403.264496,594.901550 
	C403.292114,593.703735 405.083710,592.546570 406.516876,591.762512 
	C404.702759,597.593506 406.226044,600.293823 411.999237,601.321777 
	C412.693237,601.745483 413.357758,601.877930 414.245361,601.831665 
	C414.504303,601.119629 414.540222,600.586304 414.576141,600.052917 
	C414.431244,600.049500 414.286316,600.046143 414.141418,600.042725 
	C414.083588,600.684326 414.025757,601.325928 413.710205,601.875000 
	C412.976898,601.514709 412.501282,601.247070 411.953888,600.646851 
	C410.983521,599.525085 410.084930,598.735901 409.942474,598.610779 
	C411.124298,595.844604 412.085907,593.593872 413.047546,591.343140 
	C416.511871,590.651550 416.183289,593.350281 416.824280,596.082703 
	C417.056854,597.969971 417.097626,599.128235 417.138397,600.286560 
M407.597839,602.507385 
	C407.597839,602.507385 407.487335,602.575134 407.597839,602.507385 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M431.854309,582.417786 
	C425.497162,584.599792 421.652557,583.804443 419.409973,579.527710 
	C417.935791,580.351746 416.473389,581.169250 414.699585,582.160767 
	C414.571838,580.252075 414.446106,578.373779 414.210022,574.846619 
	C417.510223,575.874207 419.949921,576.633850 422.449707,578.124756 
	C423.153870,579.560181 423.797913,580.264404 424.441956,580.968689 
	C425.110809,580.031067 425.779602,579.093384 426.448425,578.155701 
	C427.926147,577.243835 429.403900,576.331970 431.245239,575.627136 
	C430.429108,577.282288 429.249390,578.730347 427.142670,581.316284 
	C429.622528,580.698364 430.743561,580.419006 431.916809,580.427612 
	C431.930786,581.282898 431.892548,581.850342 431.854309,582.417786 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M397.659851,575.433228 
	C397.995392,575.188904 397.973480,575.195862 397.959442,575.223022 
	C398.987274,577.858215 398.987274,577.858215 393.364044,582.231018 
	C393.218414,581.581543 393.078369,580.956787 392.643860,579.699463 
	C391.780273,579.335510 391.211060,579.604065 390.641876,579.872620 
	C390.274139,580.572266 389.906403,581.271912 389.566376,581.918884 
	C388.292572,580.953796 386.946625,579.934021 385.600647,578.914307 
	C385.032837,579.923157 384.465027,580.932007 383.804626,582.383301 
	C383.135895,582.823242 382.559753,582.820679 381.640259,582.428833 
	C381.121429,580.748718 380.945923,579.457947 380.770447,578.167175 
	C381.322601,577.417297 381.874725,576.667419 382.801636,575.832642 
	C387.892365,575.724365 392.608368,575.700989 397.659851,575.433228 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M400.136444,567.555054 
	C398.908234,568.749512 397.680023,569.943970 396.503174,571.476685 
	C393.068756,572.635742 389.583008,573.456482 385.690552,574.372986 
	C386.001709,570.629395 386.205261,568.180115 386.486145,564.800720 
	C388.915833,565.319824 391.216370,565.811340 393.516876,566.302856 
	C393.690704,566.823425 393.864563,567.343994 394.038422,567.864563 
	C395.087341,566.369446 396.136261,564.874329 397.471802,563.096558 
	C398.503021,562.814148 399.247650,562.814453 399.995483,563.286926 
	C400.044586,565.024414 400.090515,566.289734 400.136444,567.555054 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M432.003693,582.922424 
	C431.892548,581.850342 431.930786,581.282898 432.056213,580.146729 
	C432.611969,578.120789 433.080566,576.663696 433.549194,575.206543 
	C435.202667,575.369080 436.856110,575.531616 438.686432,576.182983 
	C439.237885,577.328003 439.612488,577.984192 439.987061,578.640442 
	C440.237427,578.539978 440.487762,578.439514 440.738098,578.339111 
	C440.552979,577.547302 440.367828,576.755432 440.182678,575.963623 
	C441.233307,572.784668 442.742218,574.888062 442.726776,576.089172 
	C442.696655,578.437439 441.791992,580.774475 440.767639,583.471680 
	C439.873840,583.839722 439.461731,583.852295 438.658325,583.818298 
	C436.795288,584.022278 435.323547,584.272888 433.589783,584.511353 
	C432.982971,584.489502 432.638214,584.479919 432.293427,584.470337 
	C432.246643,584.122559 432.199860,583.774780 432.003693,582.922424 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M432.868835,636.106934 
	C433.064453,636.642334 433.105347,636.817261 433.049042,637.122437 
	C431.578461,638.437622 430.152679,639.634827 428.085846,640.667847 
	C427.028442,640.464417 426.612061,640.425171 426.195709,640.385925 
	C424.096161,640.151917 421.996582,639.917908 419.298706,639.482666 
	C418.279785,639.081360 417.859192,638.881287 417.438599,638.681274 
	C417.989716,638.256226 418.540863,637.831116 419.091980,637.406067 
	C420.267700,636.436707 421.443390,635.467346 423.141785,634.285278 
	C424.197906,635.069702 424.731323,636.066772 425.459015,637.426880 
	C426.500732,636.320251 427.322937,635.446777 428.145142,634.573303 
	C429.685577,634.960327 431.226013,635.347290 432.868835,636.106934 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M408.114929,464.562378 
	C408.187775,465.690979 408.036469,466.535004 407.862793,467.688110 
	C403.718781,471.470825 399.178467,468.443726 394.461151,468.805420 
	C395.315002,466.688416 396.565857,464.599274 398.302032,461.699554 
	C399.522400,463.924408 400.091919,464.962738 400.020721,464.832916 
	C403.123016,464.614105 405.506897,464.445984 408.114929,464.562378 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M442.691650,609.083374 
	C443.084412,609.727478 443.113159,610.348267 443.137634,611.278992 
	C439.765137,612.514587 436.396942,613.440186 433.028748,614.365784 
	C432.559052,613.851257 432.089355,613.336792 431.619629,612.822266 
	C432.268066,610.725708 432.916473,608.629089 433.788818,607.124146 
	C434.110107,608.588867 434.207489,609.461853 434.304901,610.334900 
	C434.474701,610.336670 434.644501,610.338440 434.814331,610.340271 
	C434.947754,609.255371 435.081177,608.170471 435.214600,607.085571 
	C438.626953,603.992371 440.227783,607.424988 442.691650,609.083374 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M380.276642,455.868378 
	C380.813171,455.984161 381.349701,456.099945 382.060974,456.296021 
	C382.378265,462.361298 382.520813,468.346344 382.417511,474.654175 
	C381.758759,475.016235 381.345856,475.055481 380.491882,474.650757 
	C380.126099,468.093994 380.201355,461.981201 380.276642,455.868378 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M411.969788,601.030579 
	C406.226044,600.293823 404.702759,597.593506 406.955170,591.784302 
	C408.726105,591.324097 410.504395,591.233643 412.665100,591.243164 
	C412.085907,593.593872 411.124298,595.844604 409.942474,598.610779 
	C410.084930,598.735901 410.983521,599.525085 411.939423,600.658936 
	C411.996735,601.003662 411.969788,601.030579 411.969788,601.030579 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M441.040833,666.096802 
	C440.785492,665.418213 440.530151,664.739563 439.966614,663.836853 
	C442.161774,662.759277 444.665161,661.905823 447.782318,661.292969 
	C449.210114,661.739075 450.024200,661.944641 451.009888,662.429810 
	C451.892578,664.039001 452.603668,665.368530 452.974487,666.790405 
	C452.404236,666.925232 452.174255,666.967896 451.658020,666.836792 
	C449.603699,665.404724 447.835632,664.146484 446.180756,662.968872 
	C444.968353,664.734497 444.166077,665.902832 443.000488,667.035217 
	C442.394470,666.979797 442.151825,666.960388 441.803925,666.763306 
	C441.513031,666.377380 441.293762,666.214478 441.040833,666.096802 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M403.553284,580.187622 
	C404.399506,578.496704 405.167206,577.131042 406.412201,574.916321 
	C407.607819,577.533752 408.675140,579.179382 408.987793,580.957764 
	C409.087219,581.523010 407.108582,583.111511 406.206848,583.017944 
	C405.220093,582.915466 404.379181,581.408020 403.553284,580.187622 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M428.107391,634.100464 
	C427.322937,635.446777 426.500732,636.320251 425.459015,637.426880 
	C424.731323,636.066772 424.197906,635.069702 423.331329,634.010864 
	C422.029053,632.538147 421.059937,631.127258 419.981079,629.556641 
	C422.031128,629.001160 424.572266,628.312561 427.784698,627.442078 
	C427.880890,629.530090 427.975250,631.578857 428.107391,634.100464 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M399.992279,562.814758 
	C399.247650,562.814453 398.503021,562.814148 397.386169,562.818848 
	C393.874115,558.688538 394.936523,556.563782 401.233215,555.413086 
	C400.270660,556.896851 398.983459,558.002441 397.696289,559.107971 
	C397.790863,559.446960 397.885437,559.785889 397.980011,560.124878 
	C399.305756,559.995300 400.631531,559.865723 401.957275,559.736145 
	C402.211456,560.471924 402.465637,561.207642 402.476440,562.046814 
	C401.486176,562.371704 400.739227,562.593262 399.992279,562.814758 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M414.824463,560.876160 
	C413.661591,559.733826 412.907898,558.680359 412.117249,557.311890 
	C414.336761,556.309204 416.593231,555.621460 419.341095,554.783997 
	C419.341095,557.002991 419.341095,559.796875 419.341095,563.172974 
	C417.674622,562.277161 416.454132,561.621094 414.824463,560.876160 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M450.838287,662.150146 
	C450.024200,661.944641 449.210114,661.739075 448.194275,661.293518 
	C447.369781,659.992004 446.747040,658.930420 446.063049,657.557434 
	C447.841919,656.294922 446.129425,651.763855 450.614563,653.451172 
	C451.372131,655.958557 451.724884,658.085083 451.977722,660.433838 
	C451.628967,661.064087 451.380157,661.472107 451.058929,661.948486 
	C450.986481,662.016907 450.838287,662.150146 450.838287,662.150146 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M418.329224,654.883667 
	C418.090271,654.904541 417.851288,654.925415 417.254211,654.982666 
	C415.356384,644.660400 415.579926,644.456909 428.117065,645.373413 
	C427.920746,646.064453 427.347443,646.529358 426.202332,647.136230 
	C417.953186,646.482056 417.903564,646.526062 418.250427,653.890625 
	C418.266052,654.222107 418.302490,654.552673 418.329224,654.883667 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M403.556946,625.862976 
	C406.384033,626.890808 408.975067,627.642761 411.566071,628.394653 
	C411.463379,628.932373 411.360657,629.470032 411.257965,630.007751 
	C407.252594,629.456177 403.247223,628.904663 399.241852,628.353149 
	C399.253448,627.431091 399.265045,626.509094 399.276611,625.587097 
	C400.624695,625.587097 401.972778,625.587097 403.556946,625.862976 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M428.979065,677.735962 
	C425.886475,678.377747 422.785767,678.592468 419.334229,678.744141 
	C419.693420,677.465393 420.646118,675.125244 421.073883,675.217590 
	C423.720337,675.788696 427.466339,672.701355 428.979065,677.735962 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M419.065369,636.998291 
	C418.540863,637.831116 417.989716,638.256226 417.438599,638.681274 
	C417.859192,638.881287 418.279785,639.081360 418.891724,639.475830 
	C417.993805,640.144714 416.904510,640.619141 415.815247,641.093628 
	C415.761871,638.767029 414.718445,635.475525 415.855347,634.308899 
	C418.450806,631.645691 418.648468,634.757263 419.065369,636.998291 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M432.103027,584.879517 
	C432.638214,584.479919 432.982971,584.489502 433.632324,584.931030 
	C434.600800,586.547424 435.264771,587.732056 436.034180,589.049927 
	C436.139648,589.183289 436.337067,589.460083 436.337067,589.460083 
	C436.123657,590.046875 435.910278,590.633728 435.681976,591.445679 
	C434.639343,592.369141 433.611664,593.067444 432.583954,593.765808 
	C432.087555,593.646545 431.591187,593.527344 431.094818,593.408142 
	C431.367432,590.701721 431.640045,587.995239 432.103027,584.879517 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M417.490601,600.119507 
	C417.097626,599.128235 417.056854,597.969971 417.012787,596.377930 
	C419.097931,596.351990 421.186371,596.759644 423.648315,597.148926 
	C424.027710,597.321838 424.033661,597.513123 424.043457,597.992065 
	C421.979126,598.837280 419.910980,599.394836 417.490601,600.119507 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M439.049622,583.864868 
	C439.461731,583.852295 439.873840,583.839722 440.591980,583.788452 
	C441.037445,584.919678 441.176880,586.089600 441.496094,587.851990 
	C440.935974,592.046936 438.825775,590.295837 436.666809,589.551147 
	C436.337067,589.460083 436.139648,589.183289 436.091675,588.724365 
	C437.045685,586.798645 438.047668,585.331787 439.049622,583.864868 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M394.965515,674.993774 
	C394.542114,676.104919 394.100830,676.840088 393.659546,677.575256 
	C392.683655,676.090271 391.707794,674.605286 390.731934,673.120361 
	C391.200134,672.709045 391.668365,672.297729 392.136566,671.886414 
	C393.073608,672.796875 394.010620,673.707397 394.965515,674.993774 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M433.830750,517.001038 
	C434.182953,517.463745 434.225800,518.044312 434.537567,518.354858 
	C435.352905,519.166931 436.285767,519.860962 437.171783,520.602112 
	C436.053345,521.274780 434.934937,521.947449 433.177399,523.004517 
	C433.177399,520.628479 433.177399,519.190857 433.223877,517.369995 
	C433.270355,516.986694 433.643982,516.995789 433.830750,517.001038 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M451.000854,649.598022 
	C449.699432,649.570496 448.398071,649.170349 447.042328,648.399353 
	C447.576691,647.098206 448.165405,646.167908 449.434448,644.162415 
	C450.157623,646.499878 450.579224,647.862671 451.000854,649.598022 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M416.119080,565.181396 
	C416.706024,568.854858 414.821350,570.468018 410.677216,569.813049 
	C411.978149,568.474182 413.279114,567.135376 414.564087,565.473145 
	C414.897766,565.143494 415.247406,565.137268 416.119080,565.181396 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M433.016510,575.171753 
	C433.080566,576.663696 432.611969,578.120789 432.003967,579.858826 
	C430.743561,580.419006 429.622528,580.698364 427.142670,581.316284 
	C429.249390,578.730347 430.429108,577.282288 431.513123,575.510376 
	C431.772888,575.170044 432.128387,575.153503 433.016510,575.171753 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M402.162018,559.443726 
	C400.631531,559.865723 399.305756,559.995300 397.980011,560.124878 
	C397.885437,559.785889 397.790863,559.446960 397.696289,559.107971 
	C398.983459,558.002441 400.270660,556.896851 401.590637,555.462280 
	C401.942047,555.125549 402.260712,555.117737 403.058716,555.144165 
	C403.147644,556.502747 402.757202,557.827026 402.162018,559.443726 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M439.004944,593.132751 
	C443.766968,588.543701 441.682465,596.491394 444.559570,596.322937 
	C443.001190,596.433289 441.442841,596.543640 439.481140,596.581909 
	C439.052277,595.544434 439.026703,594.579163 439.004944,593.132751 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M437.048706,653.621094 
	C438.385101,653.251099 439.777100,653.211792 441.587402,653.203369 
	C440.837952,656.657288 439.252930,657.229553 437.048706,653.621094 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M399.158783,655.747192 
	C398.849915,654.881958 398.879700,654.095276 398.909485,653.308655 
	C399.476288,653.458923 400.043091,653.609192 400.609863,653.759521 
	C400.239075,654.448242 399.868256,655.137024 399.158783,655.747192 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M425.972473,578.109863 
	C425.779602,579.093384 425.110809,580.031067 424.441956,580.968689 
	C423.797913,580.264404 423.153870,579.560181 422.564117,578.449524 
	C423.577789,578.050110 424.537140,578.057068 425.972473,578.109863 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M439.868469,575.976074 
	C440.367828,576.755432 440.552979,577.547302 440.738098,578.339111 
	C440.487762,578.439514 440.237427,578.539978 439.987061,578.640442 
	C439.612488,577.984192 439.237885,577.328003 438.894440,576.338745 
	C439.135162,575.999939 439.344696,575.994202 439.868469,575.976074 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M425.992584,658.922363 
	C426.427429,658.549805 426.882172,658.463379 427.679779,658.458374 
	C427.640808,659.237671 427.258972,659.935486 426.877136,660.633301 
	C426.588928,660.158386 426.300720,659.683472 425.992584,658.922363 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M426.138245,640.758850 
	C426.612061,640.425171 427.028442,640.464417 427.720123,640.669678 
	C427.945526,641.620728 427.895630,642.405762 427.756714,644.590454 
	C426.799683,642.615356 426.440216,641.873596 426.138245,640.758850 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M437.085327,675.127869 
	C437.308136,675.827271 437.490295,676.947021 437.338440,678.066223 
	C437.017822,677.226562 437.031250,676.387329 437.085327,675.127869 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M434.931885,607.057251 
	C435.081177,608.170471 434.947754,609.255371 434.814331,610.340271 
	C434.644501,610.338440 434.474701,610.336670 434.304901,610.334900 
	C434.207489,609.461853 434.110107,608.588867 434.047180,607.356934 
	C434.270813,607.008362 434.459961,607.018616 434.931885,607.057251 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M414.608612,676.534119 
	C415.058411,676.650146 415.334167,676.933350 415.588593,677.234619 
	C415.595154,677.242432 415.367950,677.447510 415.249756,677.560547 
	C414.977081,677.274902 414.704437,676.989319 414.608612,676.534119 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M410.606476,676.045593 
	C410.209656,676.287720 409.814911,676.286072 409.422333,676.255737 
	C409.411285,676.254883 409.423767,675.948914 409.425262,675.785217 
	C409.819794,675.789429 410.214294,675.793579 410.606476,676.045593 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M414.828613,706.097229 
	C414.889587,706.290344 414.716187,706.595520 414.281982,706.977234 
	C414.212219,706.772278 414.403229,706.490784 414.828613,706.097229 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M430.728729,522.104492 
	C430.945374,522.365967 430.803589,522.620667 430.261230,522.805054 
	C430.136841,522.565796 430.306732,522.353394 430.728729,522.104492 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M391.141724,579.873657 
	C391.211060,579.604065 391.780273,579.335510 392.493378,579.426086 
	C392.305389,579.815063 391.973480,579.844849 391.141724,579.873657 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M385.840179,678.682007 
	C385.669434,678.766541 385.363464,678.640381 384.931335,678.288025 
	C385.105103,678.198303 385.404999,678.334900 385.840179,678.682007 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M425.894562,566.786072 
	C425.697052,566.851196 425.392944,566.696533 425.048035,566.271973 
	C425.330231,566.103333 425.590485,566.291382 425.894562,566.786072 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M422.117920,569.176758 
	C422.360596,569.077332 422.738342,569.235779 423.173462,569.720093 
	C422.904877,569.842163 422.578918,569.638489 422.117920,569.176758 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M380.400269,578.219849 
	C380.945923,579.457947 381.121429,580.748718 381.351257,582.429443 
	C381.270844,589.283264 380.970093,595.748657 381.030914,602.210632 
	C381.255676,626.099548 381.614410,649.987183 381.872589,673.875854 
	C381.894073,675.863220 381.539642,677.854614 381.359833,679.844177 
	C380.711212,679.896484 380.062561,679.948730 379.413910,680.001038 
	C378.984711,677.300354 378.198578,674.601868 378.181335,671.898560 
	C378.002991,643.967590 377.876312,616.035645 377.956879,588.104553 
	C377.966339,584.825195 379.306702,581.549744 380.400269,578.219849 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M377.047363,559.995605 
	C377.070068,548.234924 377.070068,536.972839 377.070068,525.710754 
	C377.486145,525.553223 377.902191,525.395630 378.318268,525.238098 
	C378.927185,526.255432 380.021698,527.251221 380.068024,528.293640 
	C380.434998,536.548523 380.655212,544.811218 380.813751,553.073608 
	C380.931183,559.193481 381.011902,565.320007 380.831604,571.435364 
	C380.786133,572.978760 379.678802,574.490784 379.059418,576.017212 
	C378.381165,575.873779 377.702911,575.730347 377.024658,575.586975 
	C377.024658,570.556030 377.024658,565.525085 377.047363,559.995605 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M376.974915,479.948242 
	C377.733459,479.577026 378.453857,479.712524 379.507812,480.013245 
	C379.679535,494.449097 382.132935,508.800537 379.355682,522.990356 
	C378.621002,522.997070 377.886322,523.003723 377.151642,523.010437 
	C377.080017,508.825287 377.008392,494.640137 376.974915,479.948242 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M373.246490,681.104004 
	C373.739746,681.203857 373.999146,681.347778 374.258545,681.491638 
	C373.998901,681.637329 373.684296,681.948486 373.494080,681.885193 
	C373.276001,681.812805 373.168304,681.408325 373.246490,681.104004 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M541.864685,689.196838 
	C542.281494,694.548279 542.698364,699.899780 543.137451,705.537048 
	C541.557678,705.786499 540.472900,706.099243 539.385376,706.109009 
	C502.931366,706.433533 466.475586,706.624512 430.024597,707.149048 
	C424.917236,707.222534 423.736389,705.463135 424.003662,700.853088 
	C424.329773,695.228333 424.081207,689.570251 424.081207,682.955200 
	C436.324829,682.955200 448.243286,682.955200 460.904846,683.116699 
	C465.492615,689.008118 476.659576,689.703308 481.492371,684.509888 
	C482.147461,685.819580 482.797882,687.132751 483.193237,687.930908 
	C484.160706,686.227722 485.084656,684.601135 486.182892,683.277100 
	C489.532440,682.879272 492.672302,682.259644 493.326111,687.107178 
	C494.362976,685.599304 495.158081,684.442993 496.240662,682.868652 
	C497.200531,684.350586 497.889374,685.414062 498.594177,686.502258 
	C500.501587,685.245605 502.229675,684.107117 504.345825,682.958862 
	C505.767883,682.990356 506.801849,683.031555 507.839294,683.486450 
	C508.742218,685.271118 509.641663,686.642029 510.541077,688.013000 
	C511.509766,686.331787 512.478455,684.650635 513.880005,682.975342 
	C519.104553,681.909302 517.563232,688.344727 521.126404,688.729126 
	C519.430847,691.452087 521.562195,692.259888 523.276672,691.815308 
	C524.432190,691.515625 525.110046,689.373779 526.326050,688.077026 
	C527.206299,688.375000 527.762146,688.652039 528.210083,689.134766 
	C528.392456,689.794678 528.682739,690.248962 528.973022,690.703247 
	C529.278320,689.770813 529.583618,688.838318 529.898682,687.907349 
	C529.908447,687.908875 529.892822,687.888000 530.187500,688.079590 
	C531.706177,692.119324 533.722656,693.800476 537.635071,691.133484 
	C538.898132,690.272522 540.446716,689.830505 541.864685,689.196838 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M584.661682,710.871033 
	C582.240234,711.012085 579.818726,711.153076 577.110657,710.838989 
	C575.191895,708.324646 573.991333,708.918030 573.033020,711.000610 
	C569.863098,711.432129 566.693237,711.863647 562.854614,712.534546 
	C560.901245,712.459595 559.190063,712.559143 558.410217,711.755249 
	C555.846375,709.112427 553.939941,711.629883 551.889099,712.355652 
	C551.350830,712.546082 550.964905,713.962646 551.037354,714.768555 
	C551.077271,715.212524 552.210449,715.882263 552.859375,715.892334 
	C555.936157,715.940186 559.015808,715.796021 562.094482,715.720215 
	C564.109253,715.732178 566.129578,715.844666 568.137451,715.732971 
	C571.267212,715.558777 574.389099,715.077454 577.512878,715.090088 
	C578.210205,715.092896 579.658508,716.968262 579.469849,717.360413 
	C578.422607,719.537415 576.976990,721.522827 575.709473,723.116577 
	C576.055359,721.920166 576.359558,721.180908 576.877930,720.186829 
	C577.053528,719.391785 577.014954,718.851624 576.976379,718.311401 
	C576.483459,718.723328 575.990540,719.135254 575.173035,719.666504 
	C573.771606,720.811035 572.694702,721.836243 571.617737,722.861450 
	C570.511353,721.635193 569.404968,720.408936 567.821411,718.653870 
	C567.489319,719.888855 567.243652,720.964111 566.843872,721.978638 
	C566.812195,722.058899 565.710754,721.717651 564.992432,720.929565 
	C563.874817,719.575439 562.871094,718.863953 561.867371,718.152466 
	C561.551331,719.100586 561.235291,720.048767 560.919189,720.996887 
	C560.697510,721.555542 560.475891,722.114197 560.164673,722.898499 
	C559.163696,721.320740 558.372314,720.073364 557.580933,718.825928 
	C557.186890,718.955750 556.792847,719.085571 556.398865,719.215393 
	C556.236206,720.698059 556.073547,722.180725 555.889587,723.857361 
	C552.531738,722.919312 555.918396,715.868896 549.503418,717.244751 
	C548.635071,715.519165 548.345520,713.997375 548.055969,712.475586 
	C545.502014,708.996704 545.443359,705.657227 548.631958,702.530029 
	C549.233215,703.572144 549.852844,704.646057 550.679810,705.541870 
	C555.178284,705.520996 559.536987,706.194702 563.735718,705.645447 
	C566.338989,705.304932 568.699463,703.108398 571.184448,701.040344 
	C570.991089,699.289490 570.782166,698.243164 570.573242,697.196899 
	C570.938660,696.947571 571.304138,696.698303 572.106567,696.503662 
	C572.711975,698.930054 572.880432,701.301758 573.053101,703.731873 
	C575.202087,704.470703 578.030212,705.443115 581.199524,706.532776 
	C581.892822,705.782288 582.870422,704.724121 584.144714,703.880981 
	C585.713501,705.336975 586.783630,707.022949 588.305481,707.713562 
	C590.538025,708.726624 593.093750,709.027588 595.511780,709.632019 
	C595.943726,706.600586 600.633911,704.900269 598.221863,700.580627 
	C596.784668,698.432129 595.342651,696.681213 593.900635,694.930298 
	C593.332458,695.208130 592.764343,695.485962 592.196167,695.763794 
	C591.625916,698.436218 591.055603,701.108704 590.220215,703.615723 
	C587.722778,702.587891 588.776062,697.841736 584.903564,698.726135 
	C588.129028,695.769287 587.237427,694.066345 584.033813,692.233337 
	C580.528992,690.227966 577.304871,690.304321 573.930786,692.200317 
	C573.925354,690.496338 573.919922,688.792297 574.104980,686.507812 
	C574.518494,685.228943 574.741394,684.530640 574.964355,683.832336 
	C576.194275,683.469604 577.424194,683.106934 578.654175,682.744202 
	C580.165100,687.970276 584.475220,686.055725 587.911011,686.375488 
	C588.566467,686.436462 589.219788,686.520325 590.254639,686.636230 
	C590.493408,688.184875 590.735352,689.753479 591.081299,691.996704 
	C595.753235,691.497131 600.421448,693.713745 605.283752,690.162903 
	C607.437439,688.590027 611.364868,689.243958 614.485535,689.257446 
	C617.231079,689.269348 620.808655,688.048645 621.469360,692.733154 
	C621.532471,693.180481 623.473145,693.844421 624.324951,693.611816 
	C627.270203,692.807800 630.326111,691.998962 632.906311,690.463318 
	C633.982422,689.822815 633.863342,687.309509 634.480408,685.722656 
	C635.046631,684.266541 635.872070,682.911194 637.272156,682.116394 
	C636.831909,684.360474 635.561462,686.870483 636.172363,688.778015 
	C636.856506,690.914062 639.244324,692.504333 640.719971,694.883423 
	C637.556274,695.805420 634.563354,696.176147 631.656738,696.536255 
	C630.988831,697.765991 630.204712,699.209778 629.310974,700.325439 
	C629.067017,699.315430 628.932739,698.633484 628.828491,697.647339 
	C628.213196,697.191223 627.567810,697.039368 626.682068,696.911865 
	C626.441650,696.936218 625.972656,697.053162 625.651855,696.827698 
	C623.953369,696.743164 622.468079,696.607666 621.224487,697.092468 
	C619.536133,697.750427 618.126953,699.363281 616.427368,699.696777 
	C614.034363,700.166260 611.484863,699.833130 609.002380,699.852722 
	C606.619934,699.871521 604.221313,700.055115 604.080505,696.550781 
	C604.055054,695.916809 602.811035,694.742676 602.368164,694.845459 
	C601.603638,695.023010 600.403687,696.094299 600.480103,696.636353 
	C600.782288,698.779663 601.509033,700.863037 602.062317,703.295288 
	C601.700134,704.412231 601.353943,705.202515 600.968567,706.212341 
	C600.929321,706.431824 600.823303,706.864929 600.526428,707.061523 
	C599.815857,708.491516 599.402222,709.724854 598.988525,710.958252 
	C595.341187,710.990051 591.693909,711.021912 587.418579,710.694580 
	C586.080994,710.513977 585.371338,710.692505 584.661682,710.871033 
M613.104065,691.073975 
	C612.245789,691.204895 611.387512,691.335815 609.016418,691.697632 
	C613.001343,693.871399 615.678589,695.331848 619.157532,697.229614 
	C619.045410,694.715088 618.971802,693.065186 618.883606,691.087891 
	C616.857056,691.087891 615.383179,691.087891 613.104065,691.073975 
M614.116577,697.896118 
	C614.356018,697.756348 614.615662,697.640198 614.822693,697.462585 
	C614.884949,697.409302 614.851318,697.094238 614.775879,697.052063 
	C612.388916,695.715454 609.991394,694.397766 607.140076,692.826233 
	C607.140076,694.819824 607.140076,696.257019 607.140076,697.924561 
	C609.494141,697.924561 611.450745,697.924561 614.116577,697.896118 
M585.403381,689.229797 
	C585.811218,689.008911 586.219055,688.788025 586.626953,688.567200 
	C585.314758,688.260864 584.002563,687.954468 582.690369,687.648071 
	C582.620300,687.959534 582.550232,688.270996 582.480164,688.582458 
	C583.191162,688.828491 583.902222,689.074524 585.403381,689.229797 
M585.362488,708.582703 
	C585.362488,708.582703 585.489990,708.481445 585.362488,708.582703 
M548.503784,710.427490 
	C548.503784,710.427490 548.584534,710.560364 548.503784,710.427490 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M486.008606,682.974609 
	C485.084656,684.601135 484.160706,686.227722 483.193237,687.930908 
	C482.797882,687.132751 482.147461,685.819580 481.492371,684.509888 
	C476.659576,689.703308 465.492615,689.008118 461.375732,683.047852 
	C460.895569,682.006897 460.687561,681.196289 460.028442,680.099792 
	C459.577301,679.813904 459.188080,679.623718 459.188080,679.623718 
	C459.018524,678.849792 458.848969,678.075928 458.254883,676.849731 
	C458.557953,674.923584 459.285553,673.449768 460.118561,672.388672 
	C461.459991,673.229370 462.686615,673.991455 463.933716,674.026672 
	C469.029480,674.170593 474.145691,674.312256 479.224091,673.985046 
	C481.179199,673.859009 483.053619,672.480408 485.109619,672.030151 
	C485.328949,673.848816 485.403442,675.309814 485.349548,676.914551 
	C485.180145,677.178406 485.086945,677.309387 485.108978,677.416626 
	C485.157928,677.655151 485.264587,677.881897 485.425293,678.258423 
	C485.502777,678.403381 485.818726,678.494202 485.802368,678.808594 
	C485.857483,679.747864 485.928955,680.372681 486.001709,681.327148 
	C486.004852,682.096008 486.006744,682.535339 486.008606,682.974609 
M466.325806,681.109802 
	C465.953705,680.364197 465.581573,679.618652 465.061218,678.575989 
	C468.709106,678.575989 472.675323,677.522095 475.629181,678.802917 
	C481.190460,681.214417 481.088562,681.719910 482.969696,675.520020 
	C475.388153,675.520020 467.903778,675.520020 460.419434,675.520020 
	C460.220032,675.954285 460.020660,676.388550 459.821259,676.822815 
	C461.790039,678.341553 463.758789,679.860291 466.325806,681.109802 
M465.593536,682.748230 
	C465.256958,683.457397 464.437347,684.476685 464.661530,684.825928 
	C466.233307,687.274841 477.488312,686.715332 479.040527,684.139709 
	C479.277740,683.746155 478.868683,682.963135 478.603394,681.511475 
	C474.543396,684.512695 470.539856,685.875122 465.593536,682.748230 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M460.013123,671.975891 
	C459.285553,673.449768 458.557953,674.923584 458.027588,676.654297 
	C456.502930,677.437073 454.781097,677.963074 452.423065,678.683472 
	C452.732880,676.462891 452.920776,675.116394 453.106689,673.172607 
	C453.104767,672.575256 453.224884,672.166016 453.525940,671.936035 
	C453.656219,670.036743 453.485474,668.367432 453.314758,666.698120 
	C452.603668,665.368530 451.892578,664.039001 451.009888,662.429810 
	C450.838287,662.150146 450.986481,662.016907 451.406311,661.917786 
	C455.328186,661.818665 458.830261,661.818665 462.655762,661.818665 
	C461.583466,665.011719 460.761261,667.460144 459.856476,670.265015 
	C459.835815,671.087769 459.897705,671.553955 459.983521,672.021545 
	C460.007446,672.022888 460.013123,671.975891 460.013123,671.975891 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M541.907043,688.912964 
	C540.446716,689.830505 538.898132,690.272522 537.635071,691.133484 
	C533.722656,693.800476 531.706177,692.119324 530.170715,687.778809 
	C529.307312,686.271851 528.755371,685.257385 528.356445,683.913757 
	C531.714111,682.760498 534.918762,681.936340 538.021851,681.405884 
	C538.873291,683.134399 539.826294,684.569336 540.659668,685.824097 
	C540.428894,685.896973 539.443726,686.208313 538.458618,686.519592 
	C538.459778,686.804626 538.460999,687.089600 538.462219,687.374634 
	C539.635376,687.601685 540.808594,687.828735 541.981750,688.055847 
	C541.970947,688.246948 541.960205,688.437988 541.907043,688.912964 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M440.672974,666.219360 
	C441.293762,666.214478 441.513031,666.377380 441.782501,667.128784 
	C442.018250,668.321838 442.170105,668.971741 442.663147,671.081848 
	C443.067535,668.767151 443.215698,667.919189 443.363831,667.071167 
	C444.166077,665.902832 444.968353,664.734497 446.180756,662.968872 
	C447.835632,664.146484 449.603699,665.404724 451.677673,667.168030 
	C451.344391,668.147339 450.705170,668.621521 449.762909,669.105957 
	C448.973236,669.416321 448.486603,669.716431 447.999969,670.016541 
	C446.759888,670.280762 445.519836,670.544983 443.186096,671.042175 
	C444.539703,673.114563 445.748749,674.965576 447.887421,678.239868 
	C444.472412,676.681763 441.048584,676.198792 440.166931,674.440857 
	C439.094360,672.302185 440.162811,669.089783 440.672974,666.219360 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M542.321655,687.989502 
	C540.808594,687.828735 539.635376,687.601685 538.462219,687.374634 
	C538.460999,687.089600 538.459778,686.804626 538.458618,686.519592 
	C539.443726,686.208313 540.428894,685.896973 540.659668,685.824097 
	C539.826294,684.569336 538.873291,683.134399 538.285156,681.244019 
	C539.404114,680.479919 540.158142,680.171326 541.197510,679.997192 
	C542.289673,680.782654 543.096375,681.433716 543.880737,682.371094 
	C544.016418,683.149963 544.174377,683.642456 544.332397,684.134888 
	C544.555664,684.039917 544.778992,683.944946 545.002258,683.850037 
	C544.689880,683.216858 544.377502,682.583740 544.106262,681.658325 
	C545.310303,677.756042 546.385559,682.154785 547.517212,681.402893 
	C547.537903,682.377197 547.558594,683.351562 547.567993,684.693298 
	C545.924988,686.014832 544.293274,686.968933 542.321655,687.989502 
M540.735046,682.178040 
	C540.737610,682.365601 540.740112,682.553101 540.742676,682.740723 
	C540.883728,682.735291 541.137146,682.757629 541.146606,682.719543 
	C541.189270,682.547485 541.162781,682.358276 540.735046,682.178040 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M458.714417,679.731323 
	C459.188080,679.623718 459.577301,679.813904 459.760193,679.929810 
	C456.096344,680.488220 452.259613,681.148499 448.400635,681.326599 
	C441.025116,681.666931 433.637146,681.744385 426.253510,681.888977 
	C425.694153,681.899902 425.128296,681.577454 424.565460,681.409973 
	C424.581757,680.886292 424.598053,680.362671 424.614319,679.838989 
	C435.823151,679.838989 447.031952,679.838989 458.714417,679.731323 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M536.777832,708.965637 
	C538.505737,708.966003 539.792542,708.966003 541.079285,708.966003 
	C541.074768,709.220947 541.070190,709.475830 541.065613,709.730774 
	C534.680969,709.730774 528.296265,709.730774 521.911560,709.730774 
	C521.905579,709.475586 521.899536,709.220459 521.893494,708.965271 
	C526.707886,708.965271 531.522278,708.965271 536.777832,708.965637 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M448.024109,670.391724 
	C448.486603,669.716431 448.973236,669.416321 449.884827,669.414429 
	C451.281494,670.530518 452.253174,671.348267 453.224884,672.166016 
	C453.224884,672.166016 453.104767,672.575256 453.058777,672.783447 
	C450.004211,675.270996 447.861176,675.618835 448.024109,670.391724 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M465.371552,709.073853 
	C468.576660,709.071472 471.348053,709.071472 474.119415,709.071472 
	C474.118256,709.326843 474.117096,709.582153 474.115936,709.837524 
	C470.102173,709.837524 466.088409,709.837524 462.074615,709.837524 
	C462.054901,709.692322 462.035156,709.547180 462.015442,709.401978 
	C462.989563,709.293396 463.963684,709.184814 465.371552,709.073853 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M547.788452,712.813110 
	C548.345520,713.997375 548.635071,715.519165 549.071960,717.259766 
	C548.745911,717.951843 548.272522,718.425171 547.457886,718.949829 
	C547.251404,717.050964 547.386169,715.100769 547.788452,712.813110 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M458.358521,709.449951 
	C457.663300,710.085815 456.690857,710.520325 455.718445,710.954834 
	C455.592468,710.521118 455.466492,710.087402 455.340515,709.653687 
	C456.254120,709.518677 457.167694,709.383667 458.358521,709.449951 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M450.634216,709.489136 
	C450.704376,709.580261 450.443329,709.617249 450.443329,709.617249 
	C450.443329,709.617249 450.564087,709.397949 450.634216,709.489136 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M421.454407,681.573853 
	C421.404449,681.490417 421.609131,681.455933 421.609131,681.455933 
	C421.609131,681.455933 421.504395,681.657349 421.454407,681.573853 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M515.579956,731.597290 
	C516.868958,731.530273 517.700195,731.577698 518.877319,732.050720 
	C519.234192,732.722656 519.245239,732.968994 519.256348,733.215332 
	C517.898193,735.566711 516.540100,737.918091 514.898071,740.685913 
	C514.358093,741.559204 514.102051,742.015930 513.395142,742.568237 
	C510.138641,742.801697 507.331421,742.914307 504.527527,743.082397 
	C495.839905,743.603271 487.153503,744.145264 478.466644,744.679077 
	C478.485931,743.721741 478.505188,742.764404 478.802124,741.401978 
	C485.721832,731.393433 495.528381,731.154663 505.735077,731.862854 
	C508.845642,732.078674 511.992157,731.776611 515.579956,731.597290 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M284.430359,770.098938 
	C285.348877,771.757629 286.267426,773.416321 287.255127,775.199951 
	C288.873779,774.261719 286.224182,768.257385 291.760193,772.036133 
	C292.411011,773.377747 292.729462,774.105957 293.528839,775.934204 
	C293.814453,773.818970 293.933685,772.935852 294.052948,772.052734 
	C294.052948,772.052734 294.001221,772.012573 294.219788,771.900635 
	C294.674591,771.590088 294.901459,771.381409 295.118958,771.162598 
	C295.697754,771.575623 296.276550,771.988647 296.888000,772.707031 
	C296.663177,778.159607 296.277832,783.304932 296.205170,788.454712 
	C296.171539,790.838501 295.658997,792.275940 292.570648,792.119751 
	C290.711090,792.809143 289.267761,793.435913 287.824432,794.062744 
	C287.888275,793.788147 287.952118,793.513611 288.015991,793.239014 
	C285.519897,792.908813 283.023804,792.578613 280.164886,792.187866 
	C278.586426,791.195984 276.722595,790.508484 276.264832,789.291870 
	C274.018524,783.321411 269.545563,782.510925 263.972046,782.909668 
	C260.265533,783.174744 256.566284,782.700195 254.902008,787.914185 
	C254.445892,788.668884 254.294373,788.827515 254.142853,788.986084 
	C253.241302,788.119019 252.339752,787.251953 251.000671,785.694946 
	C251.178711,784.089172 251.794250,783.173279 252.409805,782.257446 
	C253.584778,781.825867 254.759735,781.394287 256.358948,780.451294 
	C258.865753,778.973267 260.948303,778.006592 263.422241,777.012817 
	C267.764038,780.344360 272.404816,778.949768 276.468597,777.916809 
	C277.558807,777.639587 277.606201,772.764587 277.880096,769.975464 
	C278.022156,768.529053 277.612976,767.028381 277.589111,765.414612 
	C277.725403,765.276978 277.998688,765.002441 278.345520,765.031250 
	C279.798584,765.372986 280.904846,765.685852 282.044861,766.384521 
	C281.039307,770.028625 277.908813,773.282043 281.312866,776.316895 
	C282.412567,774.123474 283.421448,772.111206 284.430359,770.098938 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M234.045700,763.861450 
	C237.496658,763.788513 240.947601,763.715637 245.026352,763.423035 
	C247.428925,763.100952 249.203690,762.998535 250.964874,763.286560 
	C250.963806,764.456543 250.976364,765.236145 250.994064,766.008362 
	C250.999207,766.001038 251.011490,765.988403 250.676941,765.982727 
	C249.152237,765.890381 247.962097,765.803650 246.931152,765.728516 
	C248.088013,768.427063 249.128677,770.854553 250.122040,773.644714 
	C249.767075,775.437439 249.459396,776.867493 248.748276,778.296265 
	C245.943008,774.496033 242.265106,774.103455 238.342758,774.734131 
	C234.925751,775.283630 230.224823,772.909302 228.854324,778.673157 
	C228.786926,778.956665 227.027878,778.837830 226.032303,778.480652 
	C225.971207,776.727051 226.099380,775.369934 225.870636,774.075928 
	C224.438751,765.975891 225.789856,764.254517 234.045700,763.861450 
M241.479980,765.076599 
	C241.203659,765.198792 240.927322,765.321045 240.651016,765.443298 
	C241.001953,765.616760 241.352875,765.790222 241.703781,765.963684 
	C241.811279,765.688110 241.918777,765.412476 241.479980,765.076599 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M213.595520,748.374390 
	C213.674210,747.926697 213.792313,747.770325 214.076172,747.370850 
	C214.640808,747.066589 215.039688,747.005493 215.742493,746.942871 
	C216.282272,747.186523 216.518127,747.431702 216.918961,748.172241 
	C217.116730,749.062683 217.149536,749.457703 217.182343,749.852722 
	C218.713074,751.293579 220.243820,752.734436 222.067505,754.473633 
	C222.847977,755.237488 223.335526,755.703125 223.796326,756.450806 
	C223.250580,757.218140 222.731583,757.703430 222.038757,757.943604 
	C221.321426,757.274780 220.777893,756.851074 220.415878,756.568848 
	C219.975067,759.848694 219.568420,762.874329 219.130463,766.132935 
	C216.947220,765.282837 215.728683,764.808350 214.510147,764.333923 
	C214.309067,764.704346 214.107971,765.074829 213.906891,765.445251 
	C215.972656,766.505554 218.038406,767.565796 219.942200,768.892090 
	C217.515030,769.197998 215.249832,769.237854 212.863983,768.910034 
	C212.069778,765.980835 211.583939,763.065979 207.686584,765.811646 
	C205.397705,759.177979 206.439865,756.346069 211.993637,752.426636 
	C212.764816,751.882385 213.096313,750.715027 213.631378,749.836121 
	C213.632568,749.445984 213.633743,749.055847 213.595520,748.374390 
M213.050354,762.287354 
	C213.782623,760.789978 214.761475,759.340332 215.067276,757.760437 
	C215.140305,757.383057 212.968872,756.571167 211.829758,755.959167 
	C211.352707,756.237183 210.875656,756.515198 210.398605,756.793213 
	C211.110657,758.801331 211.822708,760.809509 213.050354,762.287354 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M277.588440,760.894653 
	C277.929779,761.460876 277.980621,761.831787 278.037903,762.855713 
	C278.029083,764.006714 278.013885,764.504578 277.998657,765.002441 
	C277.998688,765.002441 277.725403,765.276978 277.193665,765.260986 
	C265.125092,764.489502 264.912231,764.593323 262.180084,772.420532 
	C260.428192,774.263306 258.920624,775.875061 257.413055,777.486755 
	C259.271301,772.356567 261.129547,767.226379 263.160522,761.619324 
	C263.933563,762.451416 264.416351,762.971130 266.025940,764.703796 
	C266.025940,760.762451 265.700195,758.003052 266.150757,755.376892 
	C266.412079,753.853638 267.997528,751.331848 268.853882,751.385559 
	C270.775604,751.506042 273.146057,752.514038 274.351593,753.972534 
	C275.851288,755.787048 276.360840,758.419800 277.588440,760.894653 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M216.518036,745.985413 
	C216.059875,741.807617 216.059875,737.701172 216.059875,733.476318 
	C220.600754,732.654602 223.755219,733.429138 225.819931,737.637268 
	C227.185013,740.419373 229.329910,742.818909 231.326385,745.700562 
	C226.676819,746.026550 221.826508,746.041626 216.518036,745.985413 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M217.637146,749.887634 
	C217.149536,749.457703 217.116730,749.062683 217.080826,748.369995 
	C235.856201,748.072388 254.634705,748.072388 273.413177,748.072388 
	C273.429077,748.410278 273.444977,748.748230 273.460876,749.086121 
	C255.004562,749.364929 236.548264,749.643738 217.637146,749.887634 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M245.162521,761.157776 
	C244.675949,758.728271 244.189392,756.298828 243.573929,753.225708 
	C249.653610,752.440735 254.915207,751.546631 260.212006,751.214966 
	C261.702698,751.121643 263.296692,752.678589 264.843323,753.478577 
	C263.327942,754.338074 261.834869,755.241699 260.281708,756.026367 
	C259.888641,756.224915 259.303864,756.044189 258.050720,755.863281 
	C253.477005,755.688843 249.659775,755.688843 245.842560,755.688843 
	C245.796051,755.950562 245.749542,756.212341 245.703033,756.474060 
	C246.719284,756.880127 247.735519,757.286255 249.234177,757.885071 
	C247.626999,759.176880 246.394760,760.167358 245.162521,761.157776 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M234.004730,763.574768 
	C225.789856,764.254517 224.438751,765.975891 225.870636,774.075928 
	C226.099380,775.369934 225.971207,776.727051 225.646149,778.549316 
	C222.189743,779.417542 222.057373,777.242798 222.184814,774.914673 
	C224.130844,772.035461 224.523331,769.610718 221.084930,767.690552 
	C221.800598,766.246948 222.467789,765.133301 223.427979,764.008484 
	C224.112213,763.981750 224.503433,763.966248 224.976105,764.108765 
	C225.167007,764.318665 225.276489,764.370544 225.385971,764.422424 
	C225.240753,764.246826 225.095520,764.071167 224.961151,763.668701 
	C224.972000,763.441772 225.006592,762.988831 225.382965,762.879761 
	C228.229858,761.551514 233.050934,763.508606 232.296982,757.932495 
	C232.989166,757.970337 233.681351,758.008179 234.700958,758.417114 
	C235.043091,759.510010 235.057816,760.231995 235.072571,760.953918 
	C234.702972,761.731995 234.333359,762.510071 234.004730,763.574768 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M259.903503,764.051147 
	C258.877472,767.138855 258.048828,770.317017 256.701233,773.257324 
	C256.313477,774.103333 254.429657,774.263733 252.776672,774.564636 
	C252.142761,774.064209 251.963959,773.737671 251.841888,772.991821 
	C251.602921,770.377808 251.307205,768.183105 251.011490,765.988403 
	C251.011490,765.988403 250.999207,766.001038 251.338776,765.858398 
	C254.420044,765.160889 257.161774,764.606018 259.903503,764.051147 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M245.135986,761.480835 
	C246.394760,760.167358 247.626999,759.176880 249.234177,757.885071 
	C247.735519,757.286255 246.719284,756.880127 245.703033,756.474060 
	C245.749542,756.212341 245.796051,755.950562 245.842560,755.688843 
	C249.659775,755.688843 253.477005,755.688843 257.625946,755.861816 
	C257.979767,756.479492 258.001831,756.924316 257.860992,758.015015 
	C255.092911,763.086731 253.112030,760.969849 250.978470,757.216858 
	C250.978470,759.659424 250.978470,761.277771 250.978470,762.896118 
	C249.203690,762.998535 247.428925,763.100952 245.327759,763.150574 
	C245.037415,762.666504 245.073441,762.235168 245.135986,761.480835 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M232.189850,757.556580 
	C233.050934,763.508606 228.229858,761.551514 225.381042,762.509155 
	C224.975296,761.507812 224.947845,760.767944 225.117935,759.727417 
	C226.114288,758.622437 226.913116,757.818054 227.711945,757.013611 
	C226.415649,756.731934 225.119370,756.450317 223.823074,756.168701 
	C223.335526,755.703125 222.847977,755.237488 222.411102,754.424438 
	C225.640594,753.440186 228.819427,752.803406 232.737198,752.018555 
	C232.422836,754.498108 232.252777,755.839417 232.189850,757.556580 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M250.964874,763.286560 
	C250.978470,761.277771 250.978470,759.659424 250.978470,757.216858 
	C253.112030,760.969849 255.092911,763.086731 257.876495,758.348877 
	C261.927002,758.555115 263.177277,760.143066 260.079895,763.741943 
	C257.161774,764.606018 254.420044,765.160889 251.333618,765.865723 
	C250.976364,765.236145 250.963806,764.456543 250.964874,763.286560 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M257.126221,777.437073 
	C258.920624,775.875061 260.428192,774.263306 262.478210,772.280396 
	C264.177032,770.922791 265.333374,769.936401 266.498230,769.329834 
	C266.735321,771.454285 266.963959,773.198914 266.802246,774.934937 
	C265.284851,775.630859 264.157867,776.335388 263.030884,777.039917 
	C260.948303,778.006592 258.865753,778.973267 256.439148,780.086060 
	C256.343201,779.283875 256.591278,778.335632 257.126221,777.437073 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M251.785156,773.411133 
	C251.963959,773.737671 252.142761,774.064209 252.411911,774.656250 
	C252.484650,777.126587 252.467010,779.331299 252.429581,781.896729 
	C251.794250,783.173279 251.178711,784.089172 250.596863,785.430298 
	C250.630554,785.855469 250.640045,785.850403 250.816895,785.511230 
	C250.327560,783.783691 249.661362,782.395325 249.075104,780.674805 
	C249.153931,779.660889 249.152832,778.979248 249.151733,778.297546 
	C249.459396,776.867493 249.767075,775.437439 250.388306,773.699585 
	C251.062973,773.398254 251.424072,773.404724 251.785156,773.411133 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M235.399506,761.134277 
	C235.057816,760.231995 235.043091,759.510010 235.047409,758.428040 
	C235.066452,758.067993 235.038528,758.057556 235.395477,758.038391 
	C236.813751,757.769409 237.875092,757.519592 238.936417,757.269775 
	C239.917969,758.257507 240.899521,759.245178 241.881088,760.232849 
	C239.829559,760.593506 237.778015,760.954102 235.399506,761.134277 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M239.120193,756.965088 
	C237.875092,757.519592 236.813751,757.769409 235.405930,758.041138 
	C235.294205,752.606384 236.799835,751.774597 241.947815,754.702881 
	C240.968048,755.428284 240.136002,756.044373 239.120193,756.965088 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M539.990662,842.009155 
	C540.049805,842.838806 540.049561,843.677856 540.178162,844.496582 
	C540.838745,848.703064 539.448669,850.869141 534.831848,850.184021 
	C534.016357,850.062988 533.098694,850.630554 532.228210,850.880066 
	C532.200378,851.314514 532.172546,851.748901 532.144714,852.183289 
	C533.841370,852.428894 535.600952,852.474609 537.214844,852.983032 
	C538.526062,853.396118 539.662354,854.364502 540.876221,855.086670 
	C539.600586,855.739136 538.332825,856.939941 537.048096,856.958374 
	C525.402039,857.125793 513.751892,856.988953 502.105286,857.132507 
	C487.218903,857.315979 472.335205,857.730408 457.448730,857.877136 
	C455.974213,857.891663 454.488220,856.737793 453.007538,856.126221 
	C454.526733,855.366272 455.984344,854.415405 457.582428,853.900208 
	C459.168640,853.388977 460.896240,853.316284 462.561829,853.051331 
	C462.559357,852.527710 462.556915,852.004089 462.554443,851.480530 
	C460.756042,851.338989 458.939819,850.968323 457.163116,851.106201 
	C453.977448,851.353394 452.758545,850.274414 452.743408,846.914673 
	C452.727264,843.334351 454.383575,842.791443 457.357819,842.939697 
	C460.338928,843.088440 463.339539,842.846313 467.121796,842.685913 
	C486.935944,842.596375 505.959930,842.596375 524.983887,842.596375 
	C524.979431,842.108521 524.974915,841.620667 524.970398,841.132812 
	C505.678650,841.132812 486.386902,841.132812 467.095154,841.132812 
	C462.362091,841.056702 457.629059,840.980591 452.348328,840.698792 
	C451.661407,840.295532 451.480988,840.148376 451.259430,840.051636 
	C451.259430,837.266846 451.259430,834.482056 451.259430,830.241394 
	C447.929352,831.847229 445.330780,832.496521 443.595215,834.087341 
	C441.334381,836.159424 439.845276,835.853149 437.613495,834.233215 
	C435.780121,832.902283 433.469757,832.228394 430.533966,830.878601 
	C430.082703,840.839722 429.690582,849.495056 428.787964,858.742676 
	C427.388184,864.339111 426.498871,869.343323 425.609589,874.347473 
	C424.305695,874.474548 423.001801,874.601562 421.313354,874.827637 
	C420.928772,874.926575 420.530182,874.854675 420.328186,874.854126 
	C419.009979,874.575317 417.893829,874.297058 416.408295,873.560669 
	C414.777313,869.843445 413.515686,866.584229 412.114716,862.965088 
	C410.181305,867.258057 408.508514,870.972351 406.835724,874.686646 
	C403.943939,874.739563 401.052124,874.792419 397.732117,874.733521 
	C396.871460,870.678223 396.439026,866.734680 395.580872,862.327881 
	C395.165070,861.352539 395.174957,860.840454 395.184845,860.328430 
	C395.175110,860.083679 395.165405,859.838867 395.088623,858.777466 
	C394.685486,852.713013 394.349457,847.465027 394.399933,842.034912 
	C397.076965,841.684998 399.367493,841.517273 402.713226,841.272278 
	C400.070129,839.915527 398.523163,839.121460 396.984467,837.956116 
	C396.999847,836.845337 397.006958,836.105713 397.351135,835.277954 
	C398.608063,835.064392 399.527863,834.939148 400.958191,834.860840 
	C402.151489,834.880493 402.834229,834.853149 403.911316,834.895996 
	C407.708832,834.621521 411.321106,835.048157 413.676178,831.505615 
	C414.044128,830.952087 416.944031,831.129761 417.087952,831.533813 
	C418.018494,834.147095 419.202454,833.369507 421.038116,832.560974 
	C422.005737,832.134766 423.539673,832.994263 424.938660,833.299622 
	C425.080322,831.961243 425.218842,830.652466 425.514832,829.146851 
	C425.741150,828.628235 425.809967,828.306335 425.939880,827.742310 
	C426.000946,827.500122 426.007233,827.000732 426.393005,827.000977 
	C432.290039,825.315491 437.702423,822.941467 443.339478,822.131714 
	C449.318695,821.272888 455.518585,821.728943 461.605896,821.989441 
	C463.262482,822.060303 464.860657,823.496887 466.485657,824.306641 
	C467.733063,826.125366 467.963928,826.076599 471.623047,823.037476 
	C472.444092,822.426514 472.737946,822.109802 473.031799,821.793091 
	C476.670044,821.956299 480.308350,822.119568 484.733551,822.318176 
	C485.315002,822.779053 486.772095,823.933960 488.786041,825.290649 
	C489.890533,825.004517 490.438171,824.516785 490.991272,823.775940 
	C490.997803,823.354248 490.998901,823.185669 491.200500,823.028076 
	C491.401001,823.039124 491.801727,823.065552 492.078613,823.315430 
	C493.328705,823.644104 494.301971,823.722900 495.644043,823.831970 
	C498.954346,823.604431 501.829041,821.556030 504.906097,823.725708 
	C505.340729,824.032166 506.628174,822.995850 507.566071,822.762207 
	C508.385834,822.557922 509.271057,822.538452 510.124054,822.561279 
	C512.099670,822.614136 514.114502,823.035767 516.037598,822.766418 
	C518.540955,822.415833 522.117493,820.211426 523.207825,821.151917 
	C525.982361,823.544922 527.632202,821.635010 530.023987,820.929199 
	C530.511169,820.977722 530.706726,820.989807 531.032104,821.270386 
	C531.243591,821.683350 531.300903,821.852112 531.413940,821.965088 
	C531.521606,822.072632 531.687500,822.121887 531.827759,822.196777 
	C532.234863,820.166931 532.641968,818.137085 532.910095,816.800171 
	C530.837891,816.454346 529.442200,816.221375 528.321045,815.725098 
	C528.350403,811.706238 528.188293,807.941284 527.761475,804.206604 
	C527.703979,803.702881 526.315125,803.351196 525.478271,802.541138 
	C524.277161,801.727600 523.140564,801.301941 522.002014,800.545898 
	C521.985046,799.773804 521.970032,799.332031 522.134766,798.559021 
	C523.030762,795.076050 523.747131,791.924377 524.627869,788.049561 
	C520.904724,788.049561 518.506348,788.049561 515.848083,788.031128 
	C515.414612,788.007202 515.240967,788.001648 515.034180,787.534668 
	C515.325195,777.714661 515.649292,768.356140 516.295227,759.009644 
	C516.831360,759.034363 517.045715,759.047058 517.456177,759.300659 
	C519.435364,760.698547 521.218384,761.855591 523.001038,763.004883 
	C523.000610,762.997192 522.985229,762.999329 522.973938,763.362671 
	C525.922729,765.198303 528.882812,766.670593 532.105835,768.392822 
	C536.711487,769.627441 535.839905,766.305603 536.477783,763.954407 
	C537.038452,763.586182 537.322815,763.265747 537.607239,762.945312 
	C537.129700,762.629639 536.652161,762.314026 536.107788,761.626587 
	C536.088257,760.503784 536.135498,759.752686 536.461731,758.954468 
	C537.027222,758.584778 537.313782,758.262207 537.600342,757.939575 
	C537.114990,757.626160 536.629700,757.312744 536.074707,756.564209 
	C536.076355,754.075378 536.147644,752.021729 536.396118,749.852539 
	C536.787354,749.539978 536.931152,749.300659 537.218018,748.823486 
	C538.615906,748.726318 539.800354,748.824768 541.113586,749.177185 
	C541.553162,749.641296 541.863953,749.851379 542.243530,750.396179 
	C542.367188,751.406982 542.421936,752.083008 542.476746,752.759094 
	C542.369629,765.865662 542.262573,778.972229 542.026062,792.758545 
	C541.616760,793.632812 541.336853,793.827393 540.663208,794.015015 
	C538.848083,794.612305 537.426758,795.216492 535.673828,796.010010 
	C532.517883,799.248840 532.151733,806.006226 534.773865,808.859863 
	C534.966797,809.069885 536.077576,808.436523 536.897644,808.067749 
	C537.033691,807.940247 537.279480,807.659729 537.652222,807.691162 
	C539.015808,807.813354 540.006714,807.904114 541.141357,808.227661 
	C541.782349,808.643494 542.279602,808.826416 542.776855,809.009277 
	C541.999756,813.399780 541.551270,817.890808 540.257690,822.123474 
	C539.835022,823.506653 537.279602,824.348572 535.584595,825.212585 
	C529.514099,828.307190 527.582764,831.749390 528.508911,838.527954 
	C532.474487,838.841614 536.512268,839.161011 540.346436,839.871582 
	C535.931213,840.451050 531.719666,840.639343 527.508118,840.827637 
	C527.517334,841.221497 527.526611,841.615295 527.535828,842.009155 
	C531.687439,842.009155 535.839050,842.009155 539.990662,842.009155 
M509.608826,833.797546 
	C509.214355,833.793396 508.819885,833.789185 508.425415,833.784973 
	C508.423889,833.948669 508.411407,834.254578 508.422485,834.255432 
	C508.815002,834.285706 509.209686,834.287476 509.608826,833.797546 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M476.663086,771.413574 
	C476.753723,776.253296 476.844360,781.093018 476.459656,786.463135 
	C465.671509,787.650085 455.358673,788.306702 445.190552,788.644165 
	C446.229004,786.432617 448.211334,784.198914 447.751099,782.731018 
	C447.124268,780.731812 444.748779,779.165771 442.876068,777.738037 
	C442.264771,777.271851 440.049591,777.639099 439.986725,777.930969 
	C439.592133,779.762268 439.096954,781.829224 439.603424,783.529785 
	C440.186462,785.487610 441.771973,787.146973 442.497314,788.979492 
	C440.081207,789.029846 438.089966,789.036621 435.710968,789.018799 
	C434.547821,788.996460 433.772430,788.998718 432.603790,789.100952 
	C431.798828,789.838318 430.935913,790.566345 431.042969,791.099670 
	C432.688171,799.292542 429.601898,803.712158 421.027130,803.574158 
	C419.818604,802.161682 418.649567,801.131409 417.480530,800.101135 
	C416.333557,801.668335 415.186554,803.235474 413.896484,804.998169 
	C410.539032,802.040649 409.284119,805.835754 407.161346,808.303467 
	C408.870941,811.540466 410.585968,814.787720 412.263947,817.964844 
	C416.808502,815.853333 418.592010,812.976196 418.004913,808.646179 
	C417.893982,807.828003 418.643585,806.893188 419.307983,806.296143 
	C420.073242,813.745667 420.526672,820.911194 420.569031,828.162231 
	C416.533661,827.535156 412.352203,823.934692 410.272827,830.658997 
	C409.913757,831.820190 407.138214,832.234131 405.478851,832.993225 
	C405.271790,832.643433 405.064728,832.293579 404.857666,831.943787 
	C406.210571,830.699768 407.563477,829.455750 408.916382,828.211731 
	C408.696320,827.846924 408.476227,827.482117 408.256134,827.117310 
	C406.622650,827.841736 404.989197,828.566101 403.085388,829.410400 
	C400.341034,825.532288 397.338898,827.753723 393.468201,830.612793 
	C393.660858,827.993286 393.780426,826.367432 394.282715,824.797180 
	C396.465240,823.542542 399.691254,822.315613 399.773651,820.905090 
	C399.955719,817.787537 397.310883,816.405701 394.010956,816.399597 
	C393.884003,801.121033 394.108337,786.286926 393.607208,771.477295 
	C393.475098,767.573669 394.428101,766.931152 397.767639,766.944519 
	C412.425568,767.003357 427.085602,766.793884 441.741180,766.976562 
	C444.837891,767.015137 447.919159,768.293152 450.991699,769.395081 
	C451.969299,770.828552 453.095306,772.847412 453.932953,772.734131 
	C457.364502,772.270081 461.774872,772.415405 464.003815,771.295715 
	C468.820770,768.876038 472.485718,770.221069 476.663086,771.413574 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M476.782166,791.184204 
	C476.853302,794.323669 477.276886,797.509888 476.868622,800.585754 
	C476.607330,802.554260 475.332977,804.540527 474.019226,806.104126 
	C473.711914,806.469849 471.424896,805.089233 470.003906,804.660645 
	C469.651215,804.554260 469.123718,805.027466 468.459412,805.334717 
	C469.108093,805.804016 470.109070,806.184692 470.117920,806.587158 
	C470.218048,811.130737 470.173187,815.677734 470.116364,820.223206 
	C470.114838,820.344666 469.541199,820.458984 468.415039,820.395813 
	C459.782501,820.132507 451.964142,819.854736 444.158569,820.061279 
	C440.927765,820.146912 439.443237,818.871155 439.146820,816.023926 
	C438.348206,808.353271 437.708038,800.666199 437.012939,792.736450 
	C437.032013,792.156006 437.038574,791.824707 437.483826,791.346313 
	C450.878815,791.194275 463.830475,791.189209 476.782166,791.184204 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M480.868286,769.789307 
	C480.967133,769.341980 481.065979,768.894592 481.568146,768.332214 
	C482.933624,768.706787 483.895813,769.196350 485.105835,769.812012 
	C485.465790,768.350220 485.727997,767.285461 486.293060,764.990784 
	C488.655090,766.539856 490.609985,767.821899 492.509888,769.067932 
	C492.756470,768.501221 493.162903,767.567261 492.998779,767.944458 
	C496.399170,768.738708 499.188904,769.390320 502.180237,770.259277 
	C502.896637,770.765076 503.411438,771.053528 504.017548,771.666626 
	C505.071747,771.673706 506.176727,771.548584 506.951538,770.976318 
	C507.547516,770.536255 507.612061,768.970947 508.111145,768.868164 
	C513.015686,767.857727 510.667572,765.167847 509.997101,762.481140 
	C509.040283,763.776184 508.344238,764.718262 507.499268,765.236938 
	C507.350342,759.761536 507.350342,754.709534 507.350342,749.174622 
	C509.268677,749.174622 511.028412,749.174622 512.873840,749.625610 
	C512.959534,760.930664 512.959534,771.784851 512.959534,782.999390 
	C509.848419,783.778137 507.352722,784.724915 504.787140,784.986572 
	C496.080963,785.874512 487.350098,786.520264 478.628662,787.258545 
	C478.438690,782.497375 478.248688,777.736145 478.456940,772.477661 
	C479.526245,771.250000 480.197266,770.519653 480.868286,769.789307 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M437.005005,792.985596 
	C437.708038,800.666199 438.348206,808.353271 439.146820,816.023926 
	C439.443237,818.871155 440.927765,820.146912 444.158569,820.061279 
	C451.964142,819.854736 459.782501,820.132507 468.059052,820.444458 
	C468.010132,821.765625 467.497833,822.857361 466.735596,824.127808 
	C464.860657,823.496887 463.262482,822.060303 461.605896,821.989441 
	C455.518585,821.728943 449.318695,821.272888 443.339478,822.131714 
	C437.702423,822.941467 432.290039,825.315491 426.082153,827.027954 
	C424.387482,827.733337 423.389435,828.411926 422.226135,828.819336 
	C421.693115,828.372070 421.325348,828.195984 420.982574,828.026245 
	C421.007568,828.032715 420.980103,828.076660 420.980103,828.076660 
	C420.526672,820.911194 420.073242,813.745667 419.352997,805.968384 
	C419.746338,804.889832 420.406494,804.423096 421.066620,803.956360 
	C429.601898,803.712158 432.688171,799.292542 431.042969,791.099670 
	C430.935913,790.566345 431.798828,789.838318 432.635864,789.524536 
	C432.622711,793.753601 432.184296,797.659058 431.745880,801.564575 
	C432.425751,801.710510 433.105591,801.856445 433.785461,802.002380 
	C434.858643,798.996826 435.931824,795.991211 437.005005,792.985596 
M426.996918,816.994995 
	C426.996918,816.994995 427.053497,817.040649 427.188904,817.829407 
	C429.314484,818.278320 431.440063,818.727234 434.021240,819.272400 
	C434.361908,814.249329 434.623840,810.387207 434.885773,806.525146 
	C434.335876,806.442017 433.785950,806.358887 433.236053,806.275818 
	C432.458191,808.198120 431.680328,810.120361 430.834381,812.210999 
	C428.839569,810.141052 427.126678,808.363647 425.413788,806.586243 
	C425.026398,806.836914 424.638977,807.087585 424.251587,807.338196 
	C424.251587,810.986938 424.251587,814.635620 424.251587,818.633972 
	C425.348022,817.946350 426.145630,817.446106 426.996918,816.994995 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M478.811401,789.105408 
	C478.706116,788.898010 478.600830,788.690674 479.082092,788.227295 
	C489.582367,787.419373 499.493500,786.802917 509.413391,786.404785 
	C510.546936,786.359253 512.755066,787.580688 512.730774,788.139099 
	C512.488342,793.702576 516.869446,800.183533 509.780640,804.568176 
	C509.327820,804.848206 509.629364,806.347961 509.140106,807.312012 
	C508.145050,807.205261 507.589874,807.066772 506.938721,806.608398 
	C503.027039,803.876343 494.810730,804.583130 490.995911,807.262939 
	C490.071045,806.561340 489.334625,806.232178 488.780090,805.586914 
	C489.242462,803.913818 489.523010,802.556885 489.866241,800.727722 
	C489.807831,787.300659 492.649323,788.741821 478.811401,789.105408 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M451.007538,769.000122 
	C447.919159,768.293152 444.837891,767.015137 441.741180,766.976562 
	C427.085602,766.793884 412.425568,767.003357 397.767639,766.944519 
	C394.428101,766.931152 393.475098,767.573669 393.607208,771.477295 
	C394.108337,786.286926 393.884003,801.121033 393.719788,816.463074 
	C393.312744,816.947266 393.116821,816.914429 392.787964,816.541504 
	C392.402893,812.626526 392.027954,809.055298 391.921204,805.476074 
	C391.569702,793.687683 391.412262,781.892822 390.966614,770.108459 
	C390.826355,766.399231 391.640778,765.008301 395.838318,764.880066 
	C416.387756,764.252380 437.100006,767.220032 457.478638,762.139404 
	C460.498413,761.386597 463.855316,761.986145 467.276917,762.225159 
	C470.447540,763.074341 473.397522,763.658386 476.347504,764.242432 
	C476.512970,765.224365 476.678436,766.206299 476.374695,767.529419 
	C471.790894,767.445435 467.676239,767.020325 463.561615,766.595215 
	C463.364044,765.963684 463.166504,765.332153 462.968933,764.700623 
	C461.987823,766.166016 461.006714,767.631409 459.977661,769.168396 
	C455.957062,763.472656 454.121338,763.534363 451.007538,769.000122 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M509.579987,807.280273 
	C509.629364,806.347961 509.327820,804.848206 509.780640,804.568176 
	C516.869446,800.183533 512.488342,793.702576 512.730774,788.139099 
	C512.755066,787.580688 510.546936,786.359253 509.413391,786.404785 
	C499.493500,786.802917 489.582367,787.419373 479.179993,787.892456 
	C478.691376,787.813782 478.509735,787.552856 478.569214,787.405701 
	C487.350098,786.520264 496.080963,785.874512 504.787140,784.986572 
	C507.352722,784.724915 509.848419,783.778137 512.959534,782.999390 
	C512.959534,771.784851 512.959534,760.930664 513.299683,749.579712 
	C514.494995,749.041687 515.350037,749.000549 516.437134,749.235535 
	C518.779907,754.011963 520.890686,758.512329 523.001465,763.012634 
	C521.218384,761.855591 519.435364,760.698547 517.582153,759.101196 
	C517.254578,758.281616 516.997131,757.902405 516.739685,757.523132 
	C516.484253,758.014587 516.228882,758.506104 515.973389,758.997559 
	C515.649292,768.356140 515.325195,777.714661 515.096191,787.729126 
	C515.496826,788.273132 515.802368,788.161377 516.107971,788.049561 
	C518.506348,788.049561 520.904724,788.049561 524.627869,788.049561 
	C523.747131,791.924377 523.030762,795.076050 521.797119,798.539551 
	C519.531921,797.288208 517.783997,795.724976 516.036072,794.161743 
	C515.626282,794.476807 515.216553,794.791931 514.806763,795.106995 
	C515.893494,797.245056 516.980225,799.383179 518.091187,801.879761 
	C517.896484,802.995789 517.677551,803.753296 517.458618,804.510864 
	C513.769348,802.409729 512.478699,807.132385 509.579987,807.280273 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M468.723267,749.248901 
	C469.723022,750.294189 471.726501,751.568298 471.549377,752.345337 
	C470.768524,755.771362 473.055298,754.780701 475.058777,754.845764 
	C475.841156,756.189941 476.209045,757.600281 476.005066,759.443176 
	C472.952728,759.927917 470.472260,759.980042 467.646698,759.947388 
	C463.293518,759.349243 459.285400,758.835754 455.295593,758.324646 
	C455.295593,756.328735 455.295593,754.489685 455.295593,752.650696 
	C455.595673,752.491211 455.895752,752.331726 456.195831,752.172302 
	C456.943970,753.217468 457.692108,754.262634 458.372192,755.212708 
	C459.253632,754.096863 460.076874,753.054688 461.266754,751.548401 
	C461.786255,753.257690 462.065399,754.176208 462.115265,754.340332 
	C463.588837,753.806091 465.253754,753.549805 466.451660,752.662842 
	C467.484955,751.897705 467.986572,750.414612 468.723267,749.248901 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M501.978638,770.041992 
	C499.188904,769.390320 496.399170,768.738708 492.998779,767.944458 
	C493.162903,767.567261 492.756470,768.501221 492.509888,769.067932 
	C490.609985,767.821899 488.655090,766.539856 486.293060,764.990784 
	C485.727997,767.285461 485.465790,768.350220 485.105835,769.812012 
	C483.895813,769.196350 482.933624,768.706787 481.620575,768.106201 
	C480.517303,767.222290 479.764954,766.449219 479.307434,765.224670 
	C485.607147,761.777588 491.736908,760.031128 498.673645,762.953979 
	C500.005615,765.351807 500.992126,767.696838 501.978638,770.041992 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M478.125916,757.062195 
	C480.961426,754.860840 477.931458,752.797791 478.341614,750.504944 
	C479.998566,751.331116 481.288849,752.314636 482.579102,753.298157 
	C482.556549,752.891052 482.533997,752.484009 482.511444,752.076904 
	C487.352966,752.076904 492.194458,752.076904 497.025391,752.504272 
	C496.979065,754.665283 496.943237,756.398926 496.558228,758.115967 
	C493.987335,758.435181 491.765625,758.770874 489.543915,759.106628 
	C489.417908,758.489502 489.291931,757.872314 489.165955,757.255188 
	C485.176453,762.731750 481.832367,756.653137 478.125916,757.062195 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M450.991699,769.395020 
	C454.121338,763.534363 455.957062,763.472656 459.977661,769.168396 
	C461.006714,767.631409 461.987823,766.166016 462.968933,764.700623 
	C463.166504,765.332153 463.364044,765.963684 463.561615,766.595215 
	C467.676239,767.020325 471.790894,767.445435 476.347504,767.830383 
	C476.835693,768.704285 476.881927,769.618408 476.795624,770.973022 
	C472.485718,770.221069 468.820770,768.876038 464.003815,771.295715 
	C461.774872,772.415405 457.364502,772.270081 453.932953,772.734131 
	C453.095306,772.847412 451.969299,770.828552 450.991699,769.395020 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M484.061310,806.328979 
	C479.702789,806.075562 478.034271,803.694336 478.373688,799.457458 
	C478.557678,797.161316 478.356750,794.834351 478.881531,792.112000 
	C487.926514,792.048645 487.926514,792.048645 488.227783,800.742188 
	C488.233398,800.905029 488.241058,801.067688 488.014648,801.469971 
	C486.541412,803.249268 485.301361,804.789124 484.061310,806.328979 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M476.866302,790.752075 
	C463.830475,791.189209 450.878815,791.194275 437.166840,791.145264 
	C436.303955,790.408569 436.201355,789.725952 436.098755,789.043335 
	C438.089966,789.036621 440.081207,789.029846 442.850250,789.027466 
	C444.100647,789.008972 444.573242,788.986084 445.045837,788.963257 
	C455.358673,788.306702 465.671509,787.650085 476.395935,786.914307 
	C476.855164,787.996765 476.902802,789.158386 476.866302,790.752075 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M478.052551,757.483521 
	C481.832367,756.653137 485.176453,762.731750 489.165955,757.255188 
	C489.291931,757.872314 489.417908,758.489502 489.543915,759.106628 
	C491.765625,758.770874 493.987335,758.435181 496.718048,758.365723 
	C497.594086,760.055115 497.961121,761.478149 498.328186,762.901245 
	C491.736908,760.031128 485.607147,761.777588 479.247925,764.924988 
	C478.588776,762.686157 478.283997,760.295532 478.052551,757.483521 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M478.425537,744.862793 
	C487.153503,744.145264 495.839905,743.603271 504.527527,743.082397 
	C507.331421,742.914307 510.138641,742.801697 513.446045,742.957031 
	C513.598450,743.883606 513.249268,744.516907 512.441711,745.084961 
	C500.874359,745.261292 489.765411,745.502869 478.656433,745.744446 
	C478.518066,745.530334 478.427399,745.297668 478.425537,744.862793 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M497.036011,752.076904 
	C492.194458,752.076904 487.352966,752.076904 482.511444,752.076904 
	C482.533997,752.484009 482.556549,752.891052 482.579102,753.298157 
	C481.288849,752.314636 479.998566,751.331116 478.452942,750.160522 
	C478.197571,749.973389 478.206055,749.481445 478.571045,749.293152 
	C479.251312,749.094788 479.566162,749.077026 480.361877,749.060181 
	C487.856567,749.068787 494.869904,749.068787 502.701904,749.068787 
	C501.532745,751.810730 500.867218,753.371460 500.030212,755.334412 
	C498.762299,753.954956 497.899170,753.015869 497.036011,752.076904 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M488.598206,805.902954 
	C489.334625,806.232178 490.071045,806.561340 490.981842,807.609253 
	C491.159851,809.518860 491.163483,810.709778 490.855804,812.175781 
	C490.391815,815.323059 490.239105,818.195312 490.051819,821.396606 
	C490.340118,822.493530 490.662964,823.261292 490.985809,824.029053 
	C490.438171,824.516785 489.890533,825.004517 489.174896,825.257202 
	C488.395172,823.793213 487.705627,822.595642 487.195312,821.325989 
	C486.753174,820.225952 486.312195,819.059448 486.223846,817.895569 
	C485.970093,814.552429 485.895386,811.195679 486.024597,807.289551 
	C487.065308,806.457520 487.831787,806.180237 488.598206,805.902954 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M479.880554,749.051514 
	C479.566162,749.077026 479.251312,749.094788 478.700409,749.083923 
	C478.383759,748.141968 478.302734,747.221008 478.439087,746.022217 
	C489.765411,745.502869 500.874359,745.261292 512.467285,745.365234 
	C512.929260,746.087524 512.907288,746.464417 512.429932,746.903625 
	C503.172729,746.996887 494.367859,746.914001 485.571411,747.148254 
	C483.658936,747.199280 481.776703,748.387207 479.880554,749.051514 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M488.247803,801.230469 
	C488.241058,801.067688 488.233398,800.905029 488.227783,800.742188 
	C487.926514,792.048645 487.926514,792.048645 478.934570,791.621216 
	C478.471588,790.919189 478.507721,790.298584 478.677612,789.391724 
	C492.649323,788.741821 489.807831,787.300659 489.607361,800.744507 
	C488.939789,801.232483 488.593781,801.231445 488.247803,801.230469 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M488.014648,801.469910 
	C488.593781,801.231445 488.939789,801.232483 489.544678,801.216736 
	C489.523010,802.556885 489.242462,803.913818 488.780090,805.586853 
	C487.831787,806.180237 487.065308,806.457520 486.018585,806.871948 
	C485.274292,806.912292 484.810242,806.815491 484.203735,806.523804 
	C485.301361,804.789124 486.541412,803.249268 488.014648,801.469910 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M467.991760,760.032104 
	C470.472260,759.980042 472.952728,759.927917 475.908203,759.813599 
	C476.463837,760.958191 476.544434,762.165039 476.486267,763.807129 
	C473.397522,763.658386 470.447540,763.074341 467.510376,762.063599 
	C467.679382,761.101990 467.835541,760.567078 467.991760,760.032104 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M481.112335,814.799500 
	C480.978668,813.492981 480.978668,812.553955 480.978668,811.614929 
	C481.317108,811.401306 481.655548,811.187622 481.993988,810.973999 
	C482.959839,812.046143 483.925720,813.118347 484.891571,814.190552 
	C483.676392,814.515991 482.461212,814.841492 481.112335,814.799500 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M478.199677,819.946533 
	C476.928864,819.639343 476.069122,819.326172 475.209412,819.013000 
	C475.194733,818.546082 475.180084,818.079163 475.165436,817.612244 
	C477.067596,817.252869 478.969757,816.893433 480.871918,816.533997 
	C481.074646,817.138672 481.277374,817.743408 481.480072,818.348083 
	C480.523621,818.878906 479.567200,819.409790 478.199677,819.946533 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M480.483398,769.715942 
	C480.197266,770.519653 479.526245,771.250000 478.498749,772.018005 
	C478.794373,771.251282 479.446442,770.446899 480.483398,769.715942 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M478.984314,810.386047 
	C478.440521,810.513489 477.896759,810.640930 477.121826,810.431519 
	C476.889099,809.433044 476.887543,808.771545 476.885986,808.109985 
	C477.372345,808.081604 477.858673,808.053223 478.667603,808.427856 
	C478.988251,809.349243 478.986298,809.867676 478.984314,810.386047 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M476.558685,808.064941 
	C476.887543,808.771545 476.889099,809.433044 476.840698,810.423706 
	C476.604248,809.841858 476.417816,808.930908 476.558685,808.064941 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M479.373047,810.490662 
	C478.986298,809.867676 478.988251,809.349243 479.033020,808.444702 
	C479.736511,808.327087 480.397186,808.595581 481.057861,808.864075 
	C480.625824,809.441162 480.193787,810.018188 479.373047,810.490662 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M472.658966,821.701172 
	C472.737946,822.109802 472.444092,822.426514 471.823212,822.803711 
	C471.759491,822.445984 472.022827,822.027649 472.658966,821.701172 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M449.304688,746.581787 
	C431.556183,747.450806 414.088318,748.291931 396.610931,748.612976 
	C387.863129,748.773682 379.097107,748.003418 370.340973,747.617310 
	C367.201599,747.478882 364.066956,747.232178 360.070923,747.014648 
	C358.136688,747.002441 357.061768,747.010498 355.986816,747.018494 
	C355.986816,747.018494 355.505676,747.010681 355.265594,747.022461 
	C353.419586,747.034241 351.813629,747.034241 350.207703,747.034241 
	C350.208649,746.605164 350.209625,746.176025 350.210571,745.746948 
	C385.075714,745.746948 419.940826,745.746948 453.854156,745.746948 
	C453.118805,745.848877 451.350128,746.093994 449.304688,746.581787 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M337.402649,748.549561 
	C328.437500,748.873474 319.196167,748.935913 309.488892,749.024658 
	C304.890869,749.024963 300.758728,748.998962 296.137756,748.958130 
	C295.690063,748.502075 295.731171,748.060730 295.852783,747.294434 
	C309.664307,747.408997 323.395355,747.848572 337.402649,748.549561 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M341.521973,748.580872 
	C341.061218,748.802246 340.462219,748.828552 339.564636,748.842529 
	C339.971954,748.682068 340.677887,748.533936 341.521973,748.580872 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M175.733932,507.599091 
	C175.487778,509.252472 175.005783,510.602234 174.523788,511.951965 
	C172.268631,506.884003 172.358978,506.691193 175.733932,507.599091 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M240.813934,739.561279 
	C241.832642,734.761292 239.761749,734.473572 235.226715,735.545532 
	C236.996841,736.748047 237.804855,737.296997 239.119980,738.190430 
	C236.978958,739.947632 233.469040,743.296570 233.150528,743.016479 
	C230.346024,740.550110 228.109299,737.438049 224.814026,733.435486 
	C233.646820,733.435486 240.777588,733.435486 247.980179,733.435486 
	C246.158051,742.523621 245.254532,743.436035 239.053848,742.850037 
	C239.630890,741.915771 240.232788,740.941345 240.813934,739.561279 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M229.368042,724.303589 
	C233.001251,718.977051 243.263092,719.394836 245.935196,724.615784 
	C246.888519,726.478516 247.827850,728.348450 249.065491,730.791260 
	C241.329391,730.791260 234.212021,730.791260 226.294373,730.791260 
	C227.419907,728.392944 228.295700,726.526733 229.368042,724.303589 
M239.191895,722.843018 
	C237.109756,722.940979 235.027634,723.038879 233.202850,723.124756 
	C233.202850,725.656189 233.202850,727.708252 233.202850,731.530518 
	C236.870300,729.182556 239.724976,727.882996 241.863922,725.835571 
	C243.960449,723.828857 242.542679,722.733765 239.191895,722.843018 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M380.124390,455.573242 
	C380.201355,461.981201 380.126099,468.093994 380.181885,474.671753 
	C379.231720,475.361786 378.150513,475.586884 376.881897,475.521301 
	C376.498901,468.199799 376.303253,461.169037 376.131836,453.714417 
	C377.428131,453.953064 378.700134,454.615570 380.124390,455.573242 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M315.892273,442.321747 
	C316.464081,442.285126 316.868317,442.412811 317.272583,442.540466 
	C317.160736,442.650391 317.025848,442.869659 316.940948,442.851746 
	C316.528168,442.764740 316.128784,442.614136 315.892273,442.321747 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M232.926239,669.834351 
	C233.368759,668.610352 233.962677,666.989380 234.605209,666.969849 
	C240.899246,666.778870 247.201401,666.854736 253.523819,666.854736 
	C252.805161,675.354065 245.842316,680.551575 238.632446,677.475098 
	C236.194672,676.434937 234.911194,672.689575 232.926239,669.834351 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M235.304184,657.691162 
	C235.896896,656.772522 236.348541,656.174988 236.926056,655.326172 
	C239.172089,654.907593 241.292267,654.740234 243.703461,654.324280 
	C251.647797,653.060059 251.174103,660.194519 255.534103,664.553711 
	C246.889603,664.553711 239.768250,664.553711 231.979691,664.553711 
	C233.075729,662.301514 234.119415,660.156921 235.304184,657.691162 
M246.169174,658.135437 
	C244.069687,658.300781 241.970200,658.466125 239.826797,658.634888 
	C241.385345,662.107788 243.778427,664.047363 247.670547,661.915039 
	C248.456924,661.484131 248.583267,659.848389 249.013947,658.768188 
	C248.249374,658.595886 247.484802,658.423645 246.169174,658.135437 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M594.036804,740.987854 
	C593.520691,742.202148 592.565247,743.414978 592.516541,744.663269 
	C592.264893,751.101196 592.245667,757.548157 591.606934,764.450684 
	C589.174805,765.209106 587.281006,765.508911 584.731384,765.912537 
	C584.731384,763.194092 584.914612,760.931274 584.692200,758.708984 
	C584.265503,754.445679 583.608582,750.205505 583.038635,745.960449 
	C583.030151,745.965271 583.048523,745.943542 583.034668,745.524902 
	C581.470093,744.387634 579.929016,743.071167 578.367188,743.046082 
	C567.184631,742.866760 555.997925,742.950317 545.105042,742.950317 
	C545.105042,739.683044 545.105042,736.895447 545.104980,734.107910 
	C546.903442,733.144226 548.689941,731.370422 550.502197,731.343689 
	C566.641235,731.105835 582.784790,731.176636 599.435181,731.461792 
	C599.964844,732.180420 599.986267,732.593689 599.776672,733.246948 
	C597.691101,736.005249 595.836670,738.523499 593.982239,741.041809 
	C593.982239,741.041809 594.015808,741.002747 594.036804,740.987854 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M523.001038,763.004883 
	C520.890686,758.512329 518.779907,754.011963 516.434875,748.911499 
	C516.169373,747.880615 516.138123,747.450012 516.338867,746.743042 
	C516.676514,745.263794 516.781982,744.060852 516.887512,742.857849 
	C516.887512,742.857849 517.000000,742.997559 517.316772,743.098633 
	C520.816895,744.751404 523.721497,744.614929 526.492004,741.966858 
	C529.544006,744.124878 531.127991,742.392883 532.137634,740.173279 
	C532.535583,739.298401 531.865967,737.937866 531.682495,736.798462 
	C529.860107,737.285767 528.037781,737.773071 525.891968,738.075867 
	C524.096191,737.234497 522.623901,736.577698 521.151611,735.920837 
	C522.117432,734.618408 522.990662,732.283142 524.064453,732.186951 
	C529.621887,731.689026 535.230713,731.715393 540.821411,731.719666 
	C541.335632,731.720093 541.848938,732.853333 542.675537,734.198608 
	C542.988403,736.715332 542.988403,738.494080 542.988403,740.486938 
	C540.099304,740.722595 537.691528,740.918945 535.317078,741.112549 
	C535.145691,743.302673 535.004456,745.106995 534.571228,747.218384 
	C534.172546,752.766846 534.183838,758.015137 533.872131,763.244202 
	C533.798889,764.473389 532.635315,765.637573 531.753296,766.574951 
	C528.683899,765.211975 525.834534,764.105652 522.985229,762.999329 
	C522.985229,762.999329 523.000610,762.997192 523.001038,763.004883 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M627.229370,740.926636 
	C629.992676,740.455017 632.755920,739.983337 635.555786,739.505432 
	C637.141235,732.948181 635.791199,731.113770 629.278198,731.094116 
	C627.803162,731.089661 626.328064,731.080750 624.406128,730.982056 
	C623.941345,730.537231 623.923523,730.184326 623.905273,729.566650 
	C627.690613,728.142273 632.396423,730.510437 635.407593,726.305359 
	C635.546448,726.111511 636.121094,725.999390 636.334778,726.122803 
	C645.163513,731.218872 654.776123,727.132324 663.913208,728.513184 
	C664.503113,728.602356 665.058655,728.919373 665.603455,730.349976 
	C657.056702,730.349976 648.509949,730.349976 639.378540,730.349976 
	C638.936401,734.236511 638.591736,737.266296 637.558044,740.613037 
	C633.655762,740.928894 630.442566,740.927795 627.229370,740.926636 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M534.863281,746.911255 
	C535.004456,745.106995 535.145691,743.302673 535.317078,741.112549 
	C537.691528,740.918945 540.099304,740.722595 542.988403,740.486938 
	C542.988403,738.494080 542.988403,736.715332 542.964661,734.497681 
	C543.422058,734.058105 543.903198,734.057495 544.744690,734.082397 
	C545.105042,736.895447 545.105042,739.683044 545.105042,742.950317 
	C555.997925,742.950317 567.184631,742.866760 578.367188,743.046082 
	C579.929016,743.071167 581.470093,744.387634 582.750671,745.651367 
	C581.900940,746.384338 581.321350,746.572205 580.807251,746.370850 
	C579.612976,745.342346 578.347290,744.138245 577.094177,744.151062 
	C564.842163,744.276550 552.590637,744.555481 540.344604,744.960999 
	C539.251221,744.997253 538.197571,746.230225 536.748840,746.927551 
	C535.869263,746.935547 535.366211,746.923401 534.863281,746.911255 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M526.181702,741.787781 
	C523.721497,744.614929 520.816895,744.751404 517.262146,743.027222 
	C518.185608,740.749878 519.480408,738.644897 520.963379,736.230408 
	C522.623901,736.577698 524.096191,737.234497 525.884521,738.428345 
	C526.194275,739.906128 526.187988,740.846924 526.181702,741.787781 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M516.667725,742.708557 
	C516.781982,744.060852 516.676514,745.263794 515.934814,746.732178 
	C514.494202,746.945435 513.689697,746.893372 512.885254,746.841248 
	C512.907288,746.464417 512.929260,746.087524 512.925659,745.430420 
	C513.249268,744.516907 513.598450,743.883606 513.896851,742.861511 
	C514.102051,742.015930 514.358093,741.559204 514.886536,741.046387 
	C515.588562,741.513306 516.018250,742.036255 516.667725,742.708557 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M600.007751,733.006958 
	C599.986267,732.593689 599.964844,732.180420 599.893921,731.461243 
	C600.238403,731.584656 600.632324,732.013916 601.043945,732.789246 
	C601.061707,733.135254 601.014771,733.080078 600.777954,733.043823 
	C600.363403,733.007385 600.185547,733.007141 600.007751,733.006958 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M519.492371,733.090271 
	C519.245239,732.968994 519.234192,732.722656 519.290039,732.110596 
	C519.480713,732.151672 519.604553,732.558472 519.492371,733.090271 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M315.496277,731.112671 
	C316.384338,731.629456 316.894867,732.279358 317.405396,732.929321 
	C315.941193,733.506836 314.476990,734.084412 313.012756,734.661926 
	C312.745117,734.270447 312.477478,733.878967 312.209839,733.487488 
	C313.179474,732.740234 314.149139,731.992981 315.496277,731.112671 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M245.951782,646.012207 
	C243.330750,646.882385 240.709702,647.752563 237.399887,648.851379 
	C237.399887,645.814941 237.399887,644.069702 237.399887,642.291626 
	C240.006226,643.650269 242.648575,645.027649 245.641357,646.198364 
	C245.991806,645.991577 245.951797,646.012268 245.951782,646.012207 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M245.988617,646.012939 
	C244.515045,644.647034 243.044662,643.259827 241.029312,641.358398 
	C243.802460,641.358398 245.942749,641.358398 248.521774,641.358398 
	C248.008957,642.970581 251.392975,645.719299 246.302994,646.000854 
	C245.951797,646.012268 245.991806,645.991577 245.988617,646.012939 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M325.543640,684.962585 
	C325.500244,690.573669 325.140472,696.204102 324.404907,701.888245 
	C324.009735,696.613281 323.990417,691.284546 323.963837,685.490723 
	C324.380157,685.011047 324.803741,684.996460 325.543640,684.962585 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M286.930023,440.082306 
	C286.581635,441.679291 285.951355,443.245270 285.196259,445.121338 
	C285.196259,443.633240 285.196259,442.511139 285.169128,441.007019 
	C285.644012,440.433746 286.146057,440.242523 286.930023,440.082306 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M221.036469,768.020508 
	C224.523331,769.610718 224.130844,772.035461 221.893738,774.817383 
	C220.272232,774.972595 219.236908,775.041199 217.871704,775.131653 
	C218.803757,776.466431 219.447830,777.388855 220.495010,778.888489 
	C217.865891,778.888489 215.972458,778.888489 213.915833,778.585327 
	C212.645050,778.180359 211.464905,777.781128 210.443039,778.030457 
	C206.887024,778.898254 204.745285,777.869751 204.655899,774.272705 
	C200.574432,774.272705 196.850479,774.272705 193.148102,774.272705 
	C193.148102,778.437012 193.148102,782.188477 193.148102,786.604248 
	C197.177811,786.369873 203.598923,787.181519 204.143341,785.753174 
	C206.159988,780.462280 210.610306,782.090088 214.288055,780.813232 
	C217.888779,782.226501 221.081848,783.479248 224.274933,784.732056 
	C223.440491,788.159424 221.313797,789.120361 217.807526,789.059875 
	C204.371567,788.828064 190.928314,789.040649 177.491013,788.853943 
	C173.155655,788.793762 169.629150,788.950928 170.040451,794.772278 
	C170.040451,786.187439 170.040451,777.602600 170.065857,768.557983 
	C170.403748,763.271790 170.716232,758.445312 171.064148,753.071411 
	C175.524567,752.740601 179.927856,752.476074 184.317902,752.068481 
	C187.691116,751.755432 189.273712,753.000488 188.988708,756.538757 
	C188.817047,758.670166 189.006119,760.830688 189.077042,763.801147 
	C189.230759,765.545166 189.340500,766.466187 189.356995,766.604553 
	C191.716324,765.999817 193.847839,764.911499 195.902390,765.041199 
	C200.016449,765.301147 204.088409,766.227478 208.462219,767.074341 
	C210.159332,767.939148 211.571991,768.608459 212.984634,769.277710 
	C215.249832,769.237854 217.515030,769.197998 220.209686,768.794434 
	C220.771576,768.294006 220.904022,768.157288 221.036469,768.020508 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M208.177750,766.878723 
	C204.088409,766.227478 200.016449,765.301147 195.902390,765.041199 
	C193.847839,764.911499 191.716324,765.999817 189.356995,766.604553 
	C189.340500,766.466187 189.230759,765.545166 189.146729,764.246704 
	C189.653870,763.886536 190.135315,763.903809 191.280869,763.678650 
	C194.355087,763.300110 196.783478,762.914490 199.170746,763.089600 
	C202.299759,763.319031 203.240692,761.920654 203.178253,758.928833 
	C203.238693,758.034729 203.052032,757.329163 202.861328,756.342285 
	C202.922745,755.691895 202.988190,755.322815 203.053650,754.953735 
	C203.690399,753.625183 204.327148,752.296570 204.889130,750.606079 
	C207.469116,750.130432 210.123840,750.016724 213.204971,749.869629 
	C213.096313,750.715027 212.764816,751.882385 211.993637,752.426636 
	C206.439865,756.346069 205.397705,759.177979 207.773773,766.018921 
	C208.158524,766.429382 208.177750,766.878723 208.177750,766.878723 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M202.931152,759.117371 
	C203.240692,761.920654 202.299759,763.319031 199.170746,763.089600 
	C196.783478,762.914490 194.355087,763.300110 191.642212,763.661194 
	C191.362442,762.086792 191.385468,760.287415 192.069885,758.260071 
	C193.515717,757.989929 194.300186,757.947815 195.497086,757.925720 
	C198.250061,758.336304 200.590607,758.726807 202.931152,759.117371 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M203.035736,754.541687 
	C202.988190,755.322815 202.922745,755.691895 202.490295,756.409790 
	C199.853210,756.712891 197.583130,756.667175 194.908630,756.464478 
	C193.639557,756.236023 192.774918,756.164490 191.910294,756.092896 
	C191.224518,753.107605 192.384781,751.552246 195.441757,752.139160 
	C197.994980,752.629517 201.409866,750.064819 203.035736,754.541687 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M168.194305,767.402832 
	C168.207123,771.148315 168.207123,774.439941 168.207123,777.731506 
	C167.939270,777.730042 167.671417,777.728638 167.403564,777.727173 
	C167.403564,769.663147 167.403564,761.599121 167.714371,753.247498 
	C168.077286,757.622864 168.129379,762.285889 168.194305,767.402832 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M191.905014,756.401733 
	C192.774918,756.164490 193.639557,756.236023 194.838333,756.668579 
	C195.086166,757.315857 195.056885,757.607910 195.084656,757.905701 
	C194.300186,757.947815 193.515717,757.989929 192.339127,757.988281 
	C191.931259,757.533203 191.915497,757.121826 191.905014,756.401733 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M163.622406,732.105469 
	C164.056992,732.467407 164.051697,732.775574 163.672165,733.471191 
	C156.181427,733.273743 160.592438,740.317261 157.026367,743.164185 
	C156.519989,740.098877 156.114761,737.645935 155.675491,734.986877 
	C152.189590,734.986877 148.934128,734.986877 145.678665,734.986877 
	C145.577301,734.152649 145.475937,733.318359 145.540222,732.283875 
	C151.519989,732.072815 157.334091,732.061951 163.622406,732.105469 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M156.409851,723.920898 
	C157.578857,724.832275 158.353577,725.644958 159.607574,726.960327 
	C157.144760,727.160339 155.690491,727.278503 153.018738,727.495483 
	C154.859055,728.584900 155.610489,729.029785 156.670883,729.657471 
	C155.600266,730.044128 154.108917,730.765381 153.997864,730.593689 
	C153.162796,729.302917 152.220200,727.885498 152.029251,726.414368 
	C151.612930,723.207092 154.090683,723.799194 156.409851,723.920898 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M307.610016,750.954712 
	C309.929962,751.195862 311.036896,754.713684 313.572906,752.265930 
	C314.030914,753.247620 314.488922,754.229370 314.952484,755.574585 
	C313.422760,757.165649 310.879120,756.794312 310.651794,760.111450 
	C310.476379,762.671387 306.693817,762.909302 304.902039,760.638489 
	C303.750488,759.179260 302.805115,757.557312 301.771698,755.643921 
	C301.490570,751.227966 303.918091,750.576233 307.610016,750.954712 
M305.700409,758.070496 
	C305.623871,758.217163 305.509705,758.357544 305.483612,758.512695 
	C305.465240,758.621643 305.591827,758.859863 305.611298,758.855408 
	C306.648621,758.617554 307.681396,758.359924 308.714691,758.104431 
	C308.606140,757.752747 308.497559,757.401062 308.389008,757.049438 
	C307.679108,757.263733 306.969177,757.478088 305.700409,758.070496 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M278.345490,765.031250 
	C278.013885,764.504578 278.029083,764.006714 278.083405,763.134399 
	C280.819946,762.562439 283.517365,762.364929 286.608276,762.076538 
	C288.085388,762.969788 289.169067,763.953979 290.494751,764.788635 
	C291.735352,764.496094 292.733887,764.353088 294.008972,764.475586 
	C294.198456,765.615601 294.111450,766.489990 294.133057,767.795532 
	C291.859619,768.275024 289.477539,768.323486 286.574707,768.055908 
	C284.706360,767.159485 283.358734,766.579102 282.011078,765.998657 
	C280.904846,765.685852 279.798584,765.372986 278.345490,765.031250 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M316.126465,756.388550 
	C317.408539,754.652710 315.187103,749.535339 320.705811,751.613037 
	C320.798523,753.033875 320.902649,754.629700 320.984924,756.648315 
	C319.446838,756.928345 317.930603,756.785645 316.126465,756.388550 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M320.853882,757.366577 
	C321.553619,757.206909 322.177673,757.380737 322.977966,757.838745 
	C322.362213,757.981995 321.570251,757.841125 320.853882,757.366577 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M145.849579,735.301514 
	C148.934128,734.986877 152.189590,734.986877 155.675491,734.986877 
	C156.114761,737.645935 156.519989,740.098877 157.026367,743.164185 
	C160.592438,740.317261 156.181427,733.273743 163.662750,733.703369 
	C165.152878,733.609497 166.243820,733.670349 167.794891,733.918945 
	C168.487457,734.407471 168.719910,734.708313 168.952377,735.009094 
	C168.829956,735.333130 168.707535,735.657227 168.504150,736.226807 
	C168.084427,736.837830 167.689423,737.165710 167.416412,737.574951 
	C166.049927,739.623413 165.091187,742.943909 163.288559,743.485474 
	C159.916977,744.498413 156.036713,744.173157 152.423447,743.826782 
	C151.220825,743.711548 150.026978,741.947449 149.116943,740.723267 
	C147.934494,739.132568 147.039337,737.328430 145.849579,735.301514 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M277.463501,444.039520 
	C277.168121,444.156952 276.876556,444.091553 276.584991,444.026184 
	C276.879089,443.969666 277.173218,443.913177 277.463501,444.039520 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M340.107727,708.495239 
	C340.478027,708.040894 340.742920,707.856934 341.021118,707.337036 
	C341.375824,706.917236 341.643951,706.727966 341.985443,706.298767 
	C342.337189,705.875732 342.615601,705.692627 342.949463,705.257202 
	C344.428925,705.286011 346.282806,705.098816 347.195648,705.936829 
	C349.838257,708.362610 352.102631,711.200562 355.046661,714.465881 
	C352.414917,717.158386 349.359589,720.284180 346.139526,723.698425 
	C345.544800,723.948608 345.114868,723.910400 344.364868,723.819824 
	C344.001068,723.432495 343.827911,723.190002 343.300537,722.985779 
	C342.401489,721.835938 341.727295,720.740234 341.356079,719.186279 
	C343.480377,718.023743 345.301666,717.319336 348.071625,716.248047 
	C343.625397,714.256592 341.686188,715.267029 341.017883,719.026245 
	C340.608551,718.996582 340.199188,718.966919 339.486267,718.862854 
	C339.069427,718.474182 338.875549,718.221863 338.379456,717.936096 
	C338.055817,717.514343 337.864990,717.252808 337.361420,716.954468 
	C336.618988,716.500549 336.100494,716.148254 335.271423,715.917969 
	C336.429718,710.232605 341.590912,709.215881 346.899109,713.972778 
	C347.666779,712.969238 348.419678,711.985107 349.504761,710.566711 
	C347.507965,710.144165 345.861572,709.757385 344.199677,709.455994 
	C342.834503,709.208374 341.450928,709.062317 340.107727,708.495239 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M355.848083,725.138367 
	C354.703979,726.482117 353.783508,727.605530 352.750153,728.613220 
	C350.565186,730.743774 349.366150,729.886841 348.582123,726.926270 
	C348.060089,725.981323 347.800140,725.335999 347.613464,724.331604 
	C350.123047,721.748840 352.362305,719.237244 355.058044,717.392822 
	C357.207336,715.922302 359.913269,715.265503 362.372101,714.247437 
	C362.731506,714.834534 363.090912,715.421570 363.450317,716.008606 
	C360.973755,718.960938 358.497162,721.913330 355.848083,725.138367 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M345.872986,735.900391 
	C346.072693,734.631653 346.426025,733.510132 346.779358,732.388611 
	C347.221008,732.526001 347.662659,732.663330 348.104279,732.800659 
	C347.728058,733.902039 347.351837,735.003479 346.645386,736.232788 
	C346.315125,736.360657 346.026642,736.047668 345.872986,735.900391 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M194.760040,643.000916 
	C195.110352,644.355774 195.126709,645.716492 195.200226,647.481812 
	C193.271393,648.841614 191.285370,649.796753 189.299362,650.751892 
	C189.063614,650.360657 188.827866,649.969421 188.592117,649.578247 
	C189.076965,648.619934 189.561813,647.661682 190.025970,646.350708 
	C190.005280,645.997925 190.010849,646.044678 190.297180,646.015259 
	C190.737701,645.658875 190.891907,645.331909 191.020874,645.000122 
	C190.995636,644.995422 191.003601,645.046082 191.284256,645.007202 
	C191.725235,644.647217 191.885559,644.326111 192.022369,644.002075 
	C191.998856,643.999084 192.011948,644.044128 192.011963,644.044128 
	C192.816681,643.698364 193.621399,643.352600 194.760040,643.000916 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M190.027039,646.006531 
	C188.774094,646.479431 187.499374,646.943787 186.040909,647.475037 
	C186.040909,645.411682 186.040909,643.476379 186.040909,641.194763 
	C188.325485,641.190002 191.125565,638.546936 191.905975,643.611450 
	C192.011948,644.044128 191.998856,643.999084 191.709747,643.985962 
	C191.281631,644.330566 191.142624,644.688354 191.003601,645.046082 
	C191.003601,645.046082 190.995636,644.995422 190.703430,644.977417 
	C190.277771,645.321106 190.144302,645.682922 190.010849,646.044678 
	C190.010849,646.044678 190.005280,645.997925 190.027039,646.006531 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M352.987885,637.985779 
	C352.880707,638.018921 352.783722,638.063660 352.854065,638.062622 
	C353.021393,638.016785 352.998077,637.997314 352.987885,637.985779 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M222.998901,592.998535 
	C222.913818,593.038330 222.829849,593.079468 222.874451,593.061401 
	C223.003006,593.002136 223.000000,593.000000 222.998901,592.998535 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M169.177231,734.876282 
	C168.719910,734.708313 168.487457,734.407471 168.195633,733.823914 
	C168.520554,733.459839 168.904846,733.378540 169.289154,733.297241 
	C169.326797,733.779297 169.364441,734.261414 169.177231,734.876282 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M297.413330,644.392151 
	C296.387604,645.044128 295.361847,645.696106 294.155579,646.172302 
	C293.954285,645.250916 293.933441,644.505310 293.821533,643.395996 
	C294.618958,638.222351 295.649078,637.808899 301.276306,640.460449 
	C302.526947,643.139160 303.585693,645.438293 305.089325,648.703613 
	C301.203400,648.703613 298.856873,648.703613 296.294983,648.358276 
	C296.729156,647.269531 297.378754,646.526123 298.391235,645.357605 
	C299.047943,644.442810 299.341797,643.953125 299.857239,643.269287 
	C300.031372,642.803772 299.983917,642.532471 299.936462,642.261230 
	C299.568939,642.447937 299.201416,642.634644 298.596710,642.957336 
	C298.044159,643.526245 297.728760,643.959167 297.413330,644.392151 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M324.023254,705.474731 
	C324.871460,706.272644 325.714539,707.488525 326.557587,708.704407 
	C326.238220,709.273071 325.918854,709.841797 325.599518,710.410461 
	C320.465973,709.886597 315.332458,709.362732 310.143555,708.442749 
	C313.533752,708.050171 316.995117,708.242920 320.416687,707.957947 
	C321.665802,707.853943 322.820374,706.614197 324.023254,705.474731 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M211.821304,798.350098 
	C211.821304,801.644226 211.821304,804.938293 211.821304,808.873291 
	C205.301544,809.063721 199.215744,809.490601 193.166489,808.596619 
	C195.813660,805.836609 197.829697,803.734680 200.144333,801.037964 
	C203.844315,797.617798 207.785065,797.724365 211.821304,798.350098 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M212.202377,798.288086 
	C207.785065,797.724365 203.844315,797.617798 200.141693,800.655884 
	C199.840454,800.868469 199.452240,800.863770 199.198730,800.565552 
	C197.031677,799.602112 195.118118,798.936951 193.204559,798.271851 
	C192.850128,798.345032 192.495697,798.418213 191.347977,798.462097 
	C184.694809,798.271912 178.834946,798.111023 172.967468,797.733948 
	C172.959854,797.517761 173.005310,797.087402 173.005310,797.087402 
	C173.005310,797.087402 173.029129,796.642395 173.468201,796.527710 
	C179.274734,796.279663 184.642197,796.146362 190.009644,796.013062 
	C190.771408,795.986755 191.533173,795.960388 193.101013,796.017212 
	C197.063049,795.544983 200.219025,794.989563 203.537628,794.405518 
	C205.737793,795.004700 208.440399,795.740723 211.695511,796.627258 
	C211.695511,794.044861 211.695511,792.605774 211.695496,791.166687 
	C212.115356,791.865540 212.416962,792.685364 212.981064,793.236633 
	C213.982162,794.215027 215.142502,795.030457 217.067780,795.987793 
	C219.895996,795.992432 221.892624,795.923340 223.917694,796.188599 
	C223.987320,796.968079 224.028488,797.413391 223.628937,797.856079 
	C221.140808,797.926697 219.093414,798.000000 216.684082,798.055176 
	C215.842758,798.094360 215.363342,798.151672 214.692459,798.182983 
	C214.500992,798.157043 214.124237,798.243042 214.124237,798.243042 
	C213.610641,798.237427 213.097046,798.231689 212.202377,798.288086 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M172.975082,797.950073 
	C178.834946,798.111023 184.694809,798.271912 190.868347,798.716370 
	C191.201828,802.277649 190.227615,804.728394 185.759003,804.819641 
	C182.304993,799.788452 179.716187,802.645630 177.214691,805.516052 
	C175.629349,804.185425 174.305206,803.074036 172.036102,801.169495 
	C171.401245,802.213867 170.718719,803.336609 170.036224,804.459473 
	C170.038498,802.676697 170.040726,800.893921 170.428223,798.614563 
	C171.534027,798.062012 172.254547,798.006042 172.975082,797.950073 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M163.784332,808.841187 
	C165.517929,809.692505 166.779480,810.543396 168.041016,811.394348 
	C166.857040,811.971191 165.691940,812.996216 164.485779,813.047119 
	C159.363586,813.262878 154.221863,813.349365 149.104218,813.114319 
	C147.758926,813.052612 146.474350,811.668640 145.161926,810.891846 
	C146.411713,810.221497 147.635483,809.037292 148.915909,808.972412 
	C153.704636,808.730103 158.511932,808.854126 163.784332,808.841187 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M208.419464,826.180908 
	C207.144440,826.059692 205.467697,825.700623 205.480789,825.621521 
	C206.385773,820.160889 202.241348,822.372131 199.488586,821.916016 
	C199.812195,820.938232 200.550827,819.943542 201.860535,819.175659 
	C205.084579,821.477234 206.384628,819.394775 208.169708,817.801514 
	C209.304092,818.210693 210.046341,818.579590 210.829788,818.722168 
	C216.074875,819.676819 217.786606,822.755432 215.748795,827.651306 
	C213.434662,827.125427 211.120529,826.599609 208.419464,826.180908 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M170.075256,804.800049 
	C170.718719,803.336609 171.401245,802.213867 172.036102,801.169495 
	C174.305206,803.074036 175.629349,804.185425 177.214691,805.516052 
	C179.716187,802.645630 182.304993,799.788452 185.464722,805.140015 
	C185.783325,805.422607 185.829391,805.458252 185.498215,805.458313 
	C184.020065,806.533508 182.873093,807.608704 181.726135,808.683899 
	C181.409637,808.577454 181.093124,808.470947 179.963867,808.121826 
	C177.029999,805.580261 175.942184,807.206909 174.897842,808.998779 
	C171.030609,810.530579 170.001511,808.543518 170.075256,804.800049 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M172.967468,797.733887 
	C172.254547,798.006042 171.534027,798.062012 170.453247,798.171265 
	C168.961060,798.165405 167.829147,798.106262 166.294861,798.200684 
	C163.725555,798.601562 161.558594,798.848999 159.391647,799.096436 
	C159.669754,799.539551 159.947861,799.982666 160.225952,800.425781 
	C158.761581,801.670471 157.297211,802.915222 155.410370,804.090637 
	C154.167725,801.025269 154.583450,798.446777 156.889648,796.073975 
	C157.751724,795.187012 157.741714,793.452576 158.443542,792.117920 
	C158.996643,793.045288 159.235489,793.959229 159.567871,795.230957 
	C160.726486,794.481689 161.611847,793.909119 162.699951,793.205444 
	C164.212814,798.618286 168.633713,796.560852 172.562378,797.039734 
	C173.005310,797.087402 172.959854,797.517761 172.967468,797.733887 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M214.072617,798.688354 
	C214.124237,798.243042 214.500992,798.157043 214.757141,798.630005 
	C215.013275,803.531372 215.013275,807.959717 215.013275,812.593323 
	C213.424988,812.768188 212.300797,812.816467 211.210129,813.033386 
	C210.128204,813.248596 209.080078,813.633545 207.603668,813.848877 
	C205.794342,813.815063 204.398453,813.876465 203.002579,813.937866 
	C198.991287,813.631836 194.983917,813.148010 190.967834,813.059814 
	C184.283707,812.912903 177.593994,813.019714 170.870102,812.187622 
	C185.012466,812.187622 199.154816,812.187622 214.020996,812.187622 
	C214.020996,807.303101 214.020996,803.218323 214.072617,798.688354 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M136.321014,814.101868 
	C136.280975,812.376343 136.686203,810.588440 137.130386,808.401978 
	C140.816925,807.847351 144.124008,808.040466 142.475006,813.718872 
	C140.639618,813.821960 138.702942,813.930664 136.321014,814.101868 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M202.899216,814.296997 
	C204.398453,813.876465 205.794342,813.815063 207.572739,814.221069 
	C207.902390,815.677795 207.849518,816.667114 207.796661,817.656433 
	C206.384628,819.394775 205.084579,821.477234 202.219116,819.197571 
	C202.269699,817.547241 202.532776,816.101624 202.899216,814.296997 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M193.153320,798.678284 
	C195.118118,798.936951 197.031677,799.602112 199.017014,800.633789 
	C198.780487,801.085083 198.426987,801.100281 198.171448,801.266296 
	C196.834930,802.134460 195.523285,803.040833 194.202957,803.933960 
	C193.835999,802.317505 193.469040,800.701172 193.153320,798.678284 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M182.034180,808.813660 
	C182.873093,807.608704 184.020065,806.533508 185.526764,805.462280 
	C187.495544,806.016296 189.104614,806.566406 190.713684,807.116516 
	C190.668335,807.725464 190.622986,808.334473 190.577621,808.943420 
	C187.832489,808.943420 185.087372,808.943420 182.034180,808.813660 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M175.276825,809.011963 
	C175.942184,807.206909 177.029999,805.580261 179.468719,808.046265 
	C178.409485,808.483887 177.032654,808.754517 175.276825,809.011963 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M234.702988,814.650818 
	C234.837463,815.280884 234.887680,815.944397 235.122513,816.534546 
	C235.770538,818.163208 237.526337,820.475220 236.997940,821.237854 
	C235.394836,823.551514 232.999435,825.366150 230.706406,827.102905 
	C230.320938,827.394836 228.870056,826.398254 227.980240,825.879211 
	C226.539993,825.038940 225.156677,824.101074 223.749313,823.204346 
	C223.465530,823.618530 223.181747,824.032715 222.897964,824.446899 
	C223.559311,825.153992 224.220673,825.861145 225.451996,827.177673 
	C223.826263,827.177673 222.795929,827.177673 221.370667,826.713257 
	C218.407761,822.606995 215.839813,818.965027 213.331375,815.407532 
	C220.473175,815.285217 215.199936,808.552612 217.951385,805.092834 
	C219.381836,805.092834 221.165558,805.092834 222.975403,805.522522 
	C223.032013,812.781616 223.037628,812.686707 229.741440,813.083191 
	C231.420319,813.182495 233.050446,814.106079 234.702988,814.650818 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M224.069672,797.858643 
	C224.028488,797.413391 223.987320,796.968079 223.972717,795.873413 
	C224.090790,794.592224 224.182266,793.960510 224.273743,793.328735 
	C224.572739,793.459167 224.885239,793.518005 225.548767,793.804443 
	C226.807693,794.689514 227.729080,795.275574 228.650482,795.861633 
	C229.141296,794.626221 229.632095,793.390747 230.122894,792.155273 
	C232.382828,790.945740 236.048416,794.289734 237.434204,789.265625 
	C238.163559,788.024658 238.794266,787.446838 239.397705,787.208618 
	C239.536407,788.201416 239.702347,788.854675 239.868301,789.507874 
	C239.868301,789.507874 239.817902,789.535095 239.788483,789.528320 
	C240.783112,790.288147 241.808121,791.708801 242.831024,791.707275 
	C247.110703,791.700928 246.825912,794.546326 246.935776,797.398315 
	C247.076447,801.049683 245.058136,801.921204 241.558868,801.487000 
	C239.375061,800.587646 237.570419,799.762878 235.383942,798.969727 
	C235.002121,799.001465 235.022736,799.020874 234.992859,798.637329 
	C232.998138,798.062744 231.033295,797.871582 228.751907,797.659790 
	C226.980148,797.712341 225.524902,797.785461 224.069672,797.858643 
M233.360626,794.535522 
	C233.360626,794.535522 233.498596,794.450745 233.360626,794.535522 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M222.949280,805.092834 
	C221.165558,805.092834 219.381836,805.092834 217.951385,805.092834 
	C215.199936,808.552612 220.473175,815.285217 213.331375,815.407532 
	C215.839813,818.965027 218.407761,822.606995 220.999146,826.610840 
	C219.521057,827.266235 218.019547,827.559570 216.133423,827.752075 
	C217.786606,822.755432 216.074875,819.676819 210.829788,818.722168 
	C210.046341,818.579590 209.304092,818.210693 208.169708,817.801514 
	C207.849518,816.667114 207.902390,815.677795 207.986191,814.316284 
	C209.080078,813.633545 210.128204,813.248596 211.210129,813.033386 
	C212.300797,812.816467 213.424988,812.768188 215.013275,812.593323 
	C215.013275,807.959717 215.013275,803.531372 214.948608,798.656006 
	C215.363342,798.151672 215.842758,798.094360 216.626999,798.487061 
	C216.359375,802.295166 217.896149,803.259399 221.103424,803.119812 
	C226.394272,802.889587 231.700882,803.020996 237.000839,803.000000 
	C237.046432,803.421570 237.092026,803.843140 236.643921,804.588867 
	C232.770981,804.883057 229.391769,804.853088 225.830017,804.599792 
	C224.748077,804.615234 223.848679,804.854004 222.949280,805.092834 
M209.501740,815.719788 
	C209.501740,815.719788 209.629700,815.485107 209.629700,815.485107 
	C209.629700,815.485107 209.340927,815.527832 209.501740,815.719788 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M236.944748,802.734314 
	C231.700882,803.020996 226.394272,802.889587 221.103424,803.119812 
	C217.896149,803.259399 216.359375,802.295166 216.988922,798.505249 
	C219.093414,798.000000 221.140808,797.926697 223.628937,797.856079 
	C225.524902,797.785461 226.980148,797.712341 228.802139,797.960266 
	C229.540604,798.987488 229.912323,799.693604 230.554138,800.737976 
	C232.204483,801.234131 233.584686,801.392029 234.964905,801.549927 
	C235.606155,801.856201 236.247406,802.162415 236.944748,802.734314 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M222.975403,805.522522 
	C223.848679,804.854004 224.748077,804.615234 225.864700,805.007202 
	C226.609360,808.724121 227.930771,810.629028 231.568176,809.989502 
	C233.114807,809.717529 234.785675,810.152222 236.400085,810.265808 
	C236.720551,811.959900 237.041031,813.653992 237.508026,816.122620 
	C236.337463,815.317017 235.786057,814.937500 234.968811,814.604370 
	C233.050446,814.106079 231.420319,813.182495 229.741440,813.083191 
	C223.037628,812.686707 223.032013,812.781616 222.975403,805.522522 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M236.710114,809.999756 
	C234.785675,810.152222 233.114807,809.717529 231.568176,809.989502 
	C227.930771,810.629028 226.609360,808.724121 226.047241,805.230591 
	C229.391769,804.853088 232.770981,804.883057 236.614685,804.905884 
	C237.059494,806.510437 237.039825,808.122131 236.710114,809.999756 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M234.969437,801.121521 
	C233.584686,801.392029 232.204483,801.234131 230.873016,800.447937 
	C232.288757,799.553406 233.655746,799.287109 235.022736,799.020874 
	C235.022736,799.020874 235.002121,799.001465 234.991989,798.991089 
	C234.979233,799.551514 234.976608,800.122314 234.969437,801.121521 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M286.192871,868.674683 
	C288.477570,868.342712 290.606934,868.315674 293.090698,868.436584 
	C294.241608,869.839111 295.360718,870.996582 295.773224,872.366760 
	C296.940460,876.243835 295.431305,879.359497 292.015045,881.206116 
	C288.487427,883.113037 285.423767,881.469604 283.313965,878.621277 
	C280.892487,875.352173 281.875183,872.426697 285.216370,870.212463 
	C285.595764,869.960999 285.768799,869.398071 286.192871,868.674683 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M257.329041,805.209961 
	C257.052948,804.931641 257.115082,804.547424 257.114319,804.353149 
	C260.174164,804.158936 263.234711,804.158936 267.025879,804.158936 
	C267.154053,803.568726 267.217163,801.696533 267.991333,800.189514 
	C268.672302,798.863953 270.148956,797.947083 271.274231,796.849731 
	C272.448822,798.243286 274.774048,799.870300 274.548950,800.979858 
	C274.131653,803.036377 272.348419,806.272705 270.913239,806.393494 
	C266.533600,806.762146 262.048889,805.882324 257.329041,805.209961 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M260.776672,788.770386 
	C259.679596,788.831604 258.582520,788.892822 256.636963,789.001465 
	C259.455170,782.330261 264.733704,785.366211 268.588135,784.958862 
	C272.163788,784.580811 275.228851,789.153381 274.873688,795.373962 
	C273.570801,794.073730 272.267914,792.773499 270.644440,790.809570 
	C267.327362,789.236755 264.332916,788.320190 261.317993,787.476929 
	C261.262482,787.461365 260.962555,788.319702 260.776672,788.770386 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M240.310867,789.394104 
	C239.702347,788.854675 239.536407,788.201416 239.584686,786.784058 
	C239.867325,785.720093 239.935730,785.420288 240.409729,785.034241 
	C243.542526,784.872375 246.570740,785.331848 247.000916,781.278198 
	C247.017822,781.119019 248.301651,781.094299 248.995163,781.006958 
	C249.661362,782.395325 250.327560,783.783691 250.780807,785.544800 
	C248.496994,790.542480 244.216263,788.717896 240.310867,789.394104 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M257.336182,803.210449 
	C256.075073,801.449646 255.245041,799.619629 254.448151,797.389954 
	C258.314484,797.327942 262.471497,797.068054 264.082642,802.763855 
	C261.836548,802.898010 259.801910,803.019592 257.336182,803.210449 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M260.825256,789.150879 
	C260.962555,788.319702 261.262482,787.461365 261.317993,787.476929 
	C264.332916,788.320190 267.327362,789.236755 270.437866,790.560425 
	C268.801849,791.354431 267.051910,791.733826 264.326721,792.324646 
	C263.727539,791.839966 262.300690,790.685669 260.825256,789.150879 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M254.118500,789.233521 
	C254.294373,788.827515 254.445892,788.668884 254.824417,788.271973 
	C255.074402,788.788879 255.097382,789.544067 255.120361,790.299194 
	C254.778290,790.026489 254.436203,789.753723 254.118500,789.233521 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M195.282196,852.332764 
	C194.113098,852.751160 193.246109,852.920166 192.379120,853.089172 
	C192.214172,852.717834 192.049240,852.346497 191.884308,851.975220 
	C193.067764,851.361389 194.251221,850.747498 195.434662,850.133667 
	C195.484543,850.783508 195.534409,851.433411 195.282196,852.332764 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M173.945587,852.930786 
	C173.697617,852.714050 173.675690,852.477661 173.718323,852.253479 
	C173.725021,852.218201 174.024445,852.238586 174.188507,852.233154 
	C174.189987,852.460449 174.191452,852.687805 173.945587,852.930786 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M234.125320,848.060181 
	C234.899216,847.687439 235.681915,847.687988 236.464630,847.688477 
	C236.373123,848.099487 236.281631,848.510498 236.190125,848.921509 
	C235.504791,848.758789 234.819473,848.596069 234.125320,848.060181 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M144.687714,818.923340 
	C144.520477,819.968323 144.044067,820.858826 143.321228,821.876709 
	C143.509399,820.925720 143.943985,819.847351 144.687714,818.923340 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1501.618042,597.232788 
	C1504.784058,597.278809 1507.577637,597.373596 1511.088989,597.309082 
	C1512.886963,597.132202 1513.967285,597.114685 1515.047485,597.097168 
	C1519.314331,597.518066 1523.160156,599.317078 1527.958496,597.874207 
	C1534.194092,595.999023 1540.810913,600.201538 1539.065918,609.975342 
	C1539.022095,622.769165 1539.022095,634.656860 1539.022095,646.877441 
	C1533.640747,646.877441 1529.019165,646.877441 1524.340820,646.419800 
	C1524.180176,638.648682 1524.076172,631.335144 1523.965576,623.753418 
	C1523.584595,623.142700 1523.210449,622.800232 1522.663818,622.160156 
	C1518.531250,618.643066 1516.339111,612.950928 1509.783081,613.308777 
	C1508.257690,613.392029 1505.911499,612.152222 1505.297363,610.871643 
	C1504.807861,609.850830 1506.379272,607.841614 1507.034912,606.273315 
	C1506.260620,606.273315 1504.910645,606.273315 1503.404053,606.273315 
	C1503.285645,610.840271 1501.963135,614.068359 1496.849854,613.875854 
	C1495.533936,613.826294 1494.207397,614.057373 1492.506226,614.134583 
	C1491.112793,614.107788 1490.098877,614.106079 1488.622559,613.990967 
	C1479.440063,613.918213 1470.719971,613.958801 1461.964966,613.583618 
	C1462.059814,611.564331 1462.187012,609.960510 1462.320679,608.357300 
	C1462.453613,606.760498 1462.592896,605.164246 1462.729492,603.567749 
	C1460.814575,603.761475 1458.899780,603.955200 1456.671753,603.944336 
	C1456.131348,602.791199 1455.904053,601.842712 1455.527588,600.271301 
	C1453.530518,602.092041 1451.851440,603.622864 1450.172485,605.153748 
	C1450.351562,605.623657 1450.530640,606.093628 1450.709839,606.563599 
	C1452.743774,606.395996 1454.777832,606.228394 1456.871826,606.556396 
	C1456.933105,609.345032 1456.934204,611.638184 1456.471680,613.952332 
	C1451.512207,613.982849 1447.003174,613.785889 1442.524292,614.067444 
	C1438.983276,614.289978 1437.883057,612.771912 1437.676880,609.508240 
	C1437.446411,605.862427 1439.388184,605.375305 1442.282593,605.514221 
	C1443.257080,605.560913 1444.257446,605.070007 1445.245972,604.824768 
	C1443.670410,603.938721 1442.215942,603.900879 1440.764160,603.949402 
	C1439.326172,603.997375 1437.890991,604.132324 1436.256714,604.242432 
	C1436.157959,606.104248 1436.116699,607.577454 1435.994873,609.044067 
	C1435.609497,613.681091 1434.362793,614.283020 1426.061523,613.461792 
	C1424.815674,607.202881 1420.265137,610.847595 1417.322144,610.097778 
	C1414.334106,609.336304 1413.806274,611.528625 1413.473877,613.982178 
	C1404.611328,614.053345 1396.211304,614.077637 1387.778198,613.701843 
	C1386.611084,612.260010 1385.383423,610.243652 1384.359009,610.342102 
	C1379.404053,610.818176 1377.706543,608.749268 1378.038208,604.038879 
	C1378.118530,602.897217 1377.094849,601.677734 1376.578979,600.494080 
	C1376.059814,605.585938 1372.139282,605.488159 1369.354492,605.739441 
	C1364.336182,606.192261 1359.247925,605.849121 1354.189087,605.877747 
	C1345.868896,605.924744 1337.549072,605.999695 1329.229004,606.070190 
	C1326.378052,606.094360 1323.527466,606.140625 1320.676636,606.176819 
	C1320.766113,609.105347 1320.855591,612.033813 1320.479980,614.949646 
	C1301.658203,615.061035 1283.301758,615.185120 1264.945190,615.309143 
	C1262.457397,615.265564 1259.961304,615.334045 1257.484131,615.148254 
	C1254.757324,614.943726 1251.430908,616.570068 1249.672852,612.720764 
	C1249.544800,612.440308 1247.105103,613.215698 1245.897461,614.046509 
	C1246.049194,614.820557 1246.043579,615.048279 1246.037964,615.276062 
	C1237.066772,615.430908 1228.095703,615.585815 1218.611816,615.271973 
	C1216.567383,610.510559 1220.186279,608.981445 1223.407227,606.695923 
	C1221.126465,606.176025 1219.762695,605.865112 1218.240845,605.518250 
	C1218.139404,603.505310 1218.049805,601.726624 1217.960205,599.947998 
	C1221.437622,599.733215 1224.931030,599.226990 1228.385986,599.418274 
	C1230.308105,599.524780 1232.165649,600.793640 1233.780518,601.431335 
	C1235.727661,600.811646 1237.910156,599.539490 1240.111938,599.505493 
	C1262.414307,599.160278 1284.719604,599.006348 1307.024292,598.820740 
	C1330.581665,598.624756 1354.139404,598.452515 1378.246948,598.588318 
	C1380.739258,601.857910 1382.115967,600.397217 1383.413086,598.315002 
	C1383.767090,598.257446 1384.120972,598.199890 1384.737061,598.080505 
	C1394.580811,598.176208 1404.162109,598.417175 1413.744385,598.456177 
	C1418.548096,598.475769 1423.353516,598.127441 1428.614624,597.819092 
	C1438.098877,597.699158 1447.126465,597.705627 1456.663818,598.067261 
	C1458.455200,599.014038 1459.737061,599.605652 1461.018921,600.197266 
	C1461.621460,600.003784 1462.223877,599.810303 1463.091553,599.141785 
	C1463.607544,598.460083 1463.868042,598.266602 1464.138428,598.086365 
	C1474.118164,597.787476 1484.097778,597.488586 1494.771240,597.303162 
	C1496.645142,597.307800 1497.825317,597.199036 1499.005371,597.090271 
	C1499.752075,597.154053 1500.498901,597.217834 1501.618042,597.232788 
M1295.265015,603.882812 
	C1291.974487,598.764221 1287.722046,605.258789 1284.197021,602.434692 
	C1284.197021,604.740356 1284.197021,606.511536 1284.197021,607.929688 
	C1288.673340,611.338501 1292.264282,608.518860 1296.383301,607.531250 
	C1299.420898,609.953735 1301.121338,607.849426 1301.711182,605.378113 
	C1302.727661,601.118103 1298.843506,601.952087 1296.725586,600.742615 
	C1296.418823,602.130859 1296.211304,603.069763 1295.265015,603.882812 
M1512.445068,605.750732 
	C1512.436890,605.692383 1512.428833,605.634033 1512.420654,605.575684 
	C1512.332520,605.714966 1512.135010,605.915894 1512.173462,605.984009 
	C1512.534912,606.625183 1512.873901,607.732422 1513.336426,607.791565 
	C1517.075562,608.270203 1520.839966,608.550659 1525.631592,608.985535 
	C1525.407104,609.177368 1526.564209,608.665527 1526.809082,607.872131 
	C1527.222290,606.533081 1527.057617,605.015625 1527.135864,603.573242 
	C1525.683105,603.759705 1523.422852,603.425964 1522.911743,604.219177 
	C1520.103027,608.578430 1516.470459,606.922546 1512.445068,605.750732 
M1270.648560,605.863403 
	C1268.966675,606.272766 1267.284790,606.682129 1265.602905,607.091431 
	C1265.279297,607.761230 1264.955811,608.430969 1264.632202,609.100708 
	C1271.099976,606.748657 1269.015259,612.458191 1272.043091,614.572449 
	C1272.962769,611.241150 1274.266846,608.596863 1270.648560,605.863403 
M1233.123169,605.987854 
	C1230.573853,606.310974 1228.024414,606.634155 1225.475098,606.957336 
	C1225.549683,607.577942 1225.624268,608.198547 1225.698853,608.819153 
	C1228.419312,608.819153 1231.194946,609.150574 1233.841919,608.709595 
	C1235.801025,608.383179 1236.993652,607.032288 1233.123169,605.987854 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1320.489990,596.892090 
	C1319.858398,595.744568 1319.642578,594.612976 1319.558350,594.171509 
	C1321.793701,592.245239 1323.674438,590.624512 1325.629395,588.939819 
	C1325.925903,590.096863 1326.260132,591.400940 1326.741577,593.279602 
	C1328.309204,592.045776 1329.497314,591.110657 1330.686401,590.174805 
	C1333.831665,593.889526 1340.324463,594.999817 1346.492432,592.774780 
	C1345.796875,591.067688 1345.098145,589.352600 1344.443359,587.745667 
	C1345.663940,586.841980 1346.691284,586.081238 1347.884277,585.012878 
	C1351.114746,585.445374 1355.445068,585.364929 1351.478149,590.341370 
	C1355.271240,591.884094 1358.746460,593.297607 1362.221680,594.711121 
	C1362.106689,595.443420 1361.991699,596.175720 1361.876709,596.908020 
	C1348.219727,596.908020 1334.562744,596.908020 1320.489990,596.892090 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1279.539795,595.388977 
	C1284.050659,593.494385 1288.501099,592.000427 1293.261841,590.402344 
	C1294.142212,592.231934 1295.092285,594.206116 1295.999268,596.555298 
	C1291.695435,596.992798 1287.432129,597.157166 1283.175171,597.054321 
	C1281.933838,597.024353 1280.710815,596.232361 1279.539795,595.388977 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1217.631958,599.916504 
	C1218.049805,601.726624 1218.139404,603.505310 1218.240845,605.518250 
	C1219.762695,605.865112 1221.126465,606.176025 1223.407227,606.695923 
	C1220.186279,608.981445 1216.567383,610.510559 1218.163696,615.203186 
	C1217.714844,615.658203 1217.201294,615.713257 1216.299805,615.752075 
	C1215.936401,610.806641 1215.960938,605.877625 1215.989258,600.483765 
	C1216.429932,599.974365 1216.866821,599.929749 1217.631958,599.916504 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1382.931030,598.246094 
	C1382.115967,600.397217 1380.739258,601.857910 1378.687988,598.607056 
	C1379.869019,598.263977 1381.159058,598.220642 1382.931030,598.246094 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1355.217529,583.815918 
	C1355.310181,584.030884 1355.146851,584.504944 1354.746338,584.812439 
	C1354.660034,584.455566 1354.810669,584.265259 1355.217529,583.815918 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1460.998535,599.820984 
	C1459.737061,599.605652 1458.455200,599.014038 1457.117798,598.106201 
	C1459.103516,596.599731 1460.721558,596.411133 1460.998535,599.820984 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1498.982300,596.771362 
	C1497.825317,597.199036 1496.645142,597.307800 1495.232544,597.208008 
	C1495.002319,596.810425 1495.004517,596.621338 1495.424438,596.142456 
	C1496.852783,595.841370 1497.863525,595.830078 1498.874268,595.818726 
	C1498.902588,596.029907 1498.930908,596.241150 1498.982300,596.771362 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1498.746460,595.486816 
	C1497.863525,595.830078 1496.852783,595.841370 1495.462891,595.861572 
	C1495.748657,593.095520 1496.811401,592.286682 1498.746460,595.486816 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1463.858154,598.095398 
	C1463.868042,598.266602 1463.607544,598.460083 1463.177979,598.831665 
	C1463.192139,598.699280 1463.385132,598.401855 1463.858154,598.095398 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1490.315552,589.089050 
	C1490.111084,588.685730 1490.260498,588.337952 1490.720459,587.974731 
	C1490.910522,588.354431 1490.790039,588.749512 1490.315552,589.089050 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1270.239258,562.015015 
	C1268.622314,557.315125 1272.201538,559.297241 1274.495361,559.015503 
	C1274.294312,561.291687 1273.570190,563.354065 1270.239258,562.015015 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1267.027588,561.296265 
	C1267.796143,561.157776 1268.590332,561.437927 1269.689087,561.816528 
	C1269.749512,563.536987 1269.505493,565.158813 1269.261475,566.780701 
	C1268.637085,566.746887 1268.012817,566.713074 1267.388428,566.679260 
	C1267.276733,565.024475 1267.165039,563.369690 1267.027588,561.296265 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1436.122437,582.887939 
	C1436.622192,583.164490 1436.850586,583.689697 1437.078979,584.214905 
	C1436.591309,584.207275 1436.103760,584.199585 1435.479980,583.931030 
	C1435.512817,583.492310 1435.682007,583.314453 1436.122437,582.887939 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1179.178467,553.633301 
	C1177.695557,553.893433 1176.503418,553.893433 1175.311401,553.893433 
	C1175.348999,553.457214 1175.386719,553.020996 1175.424316,552.584778 
	C1176.772583,552.847595 1178.120972,553.110413 1179.178467,553.633301 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1164.075562,617.178040 
	C1162.469727,617.445801 1160.866211,617.374573 1159.170654,617.299316 
	C1161.096436,614.316650 1161.432251,614.298401 1164.075562,617.178040 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1163.301270,603.259277 
	C1163.321411,602.958252 1163.477661,602.793884 1163.634033,602.629517 
	C1163.568359,602.885010 1163.502808,603.140503 1163.301270,603.259277 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1462.000000,613.999390 
	C1470.719971,613.958801 1479.440063,613.918213 1488.674072,614.231750 
	C1489.359619,614.887695 1489.399780,615.205994 1488.833862,615.655273 
	C1477.894165,615.952759 1467.429443,616.135742 1456.964844,616.318787 
	C1451.653320,616.222290 1446.339233,616.007935 1441.031860,616.115479 
	C1439.766357,616.141113 1438.573853,617.477539 1437.250854,617.765564 
	C1436.350464,617.961670 1435.281250,617.381897 1434.740967,616.983887 
	C1435.320068,616.714294 1435.445679,616.609863 1435.571411,616.505371 
	C1435.446899,616.399353 1435.334717,616.232910 1435.194458,616.204224 
	C1435.052979,616.175354 1434.883789,616.283203 1434.726685,616.331116 
	C1431.969360,616.335388 1429.212158,616.339600 1425.783447,616.572632 
	C1423.680786,616.612671 1422.249634,616.423950 1420.461060,616.179260 
	C1418.711182,617.078003 1417.318604,618.032776 1415.637939,618.990601 
	C1414.877197,619.271851 1414.404663,619.549866 1413.635864,620.033447 
	C1408.437622,623.905518 1410.408447,629.256470 1409.659912,633.999390 
	C1408.951538,634.004211 1408.546631,634.025024 1408.045532,633.591248 
	C1407.621094,627.132080 1406.990845,621.032288 1413.221924,616.956116 
	C1413.244873,616.673157 1413.072754,616.468689 1412.440186,616.225891 
	C1403.642090,616.439209 1395.304077,616.690857 1386.598633,616.898682 
	C1382.492065,616.894287 1378.752808,616.933838 1375.013672,616.973389 
	C1363.204224,616.937378 1351.394897,616.893005 1339.585449,616.868469 
	C1333.038818,616.854919 1331.366455,618.842407 1332.447388,625.704834 
	C1334.968262,625.848633 1337.569214,625.997070 1340.472046,626.162720 
	C1334.113647,629.461426 1330.783447,627.932983 1330.107788,621.712708 
	C1329.590088,616.944580 1328.997803,616.558411 1322.930420,617.561768 
	C1322.930420,620.553894 1322.930420,623.666992 1322.930420,626.780090 
	C1322.422974,626.800293 1321.915649,626.820557 1321.408203,626.840759 
	C1321.200195,623.747986 1320.992188,620.655212 1320.747803,617.021912 
	C1313.817383,617.021912 1307.052979,617.012207 1300.288452,617.025330 
	C1295.796143,617.034058 1291.273071,616.771606 1286.823730,617.222839 
	C1284.382080,617.470459 1281.650635,618.453735 1279.807495,620.005371 
	C1277.940674,621.576843 1275.669312,624.520325 1276.034180,626.380371 
	C1276.415649,628.324402 1279.727051,629.693542 1281.812866,631.358093 
	C1280.279663,631.875793 1278.115845,632.606445 1275.203125,633.589966 
	C1276.760376,634.717590 1277.706177,635.402405 1277.899292,635.542297 
	C1277.046387,638.149658 1276.521973,640.319580 1275.613281,642.314331 
	C1275.083862,643.476318 1273.938477,644.357605 1272.778198,645.713257 
	C1272.486084,646.061279 1272.107178,646.049377 1272.107178,646.049377 
	C1271.234375,643.968018 1270.296631,641.910400 1269.519287,639.793945 
	C1269.190186,638.897644 1268.999634,637.859619 1269.085083,636.916626 
	C1269.638672,630.798584 1270.285767,624.689026 1270.927246,618.328430 
	C1268.510254,617.910339 1267.094482,617.665344 1265.337646,617.228149 
	C1264.986694,616.651917 1264.976807,616.267944 1264.956055,615.596558 
	C1283.301758,615.185120 1301.658203,615.061035 1320.870483,614.990356 
	C1322.508789,615.011719 1323.291260,614.979736 1324.483887,614.937988 
	C1326.904663,614.250488 1328.915527,613.572754 1331.202881,613.101440 
	C1332.404907,613.809143 1333.330444,614.310486 1334.708252,614.815063 
	C1348.632080,614.726379 1362.103638,614.634399 1376.010010,614.660156 
	C1380.233643,614.552673 1384.022461,614.327332 1387.811401,614.101990 
	C1396.211304,614.077637 1404.611328,614.053345 1413.941040,613.996582 
	C1418.619995,613.932373 1422.369263,613.900635 1426.118408,613.868958 
	C1434.362793,614.283020 1435.609497,613.681091 1435.994873,609.044067 
	C1436.116699,607.577454 1436.157959,606.104248 1436.256714,604.242432 
	C1437.890991,604.132324 1439.326172,603.997375 1440.764160,603.949402 
	C1442.215942,603.900879 1443.670410,603.938721 1445.245972,604.824768 
	C1444.257446,605.070007 1443.257080,605.560913 1442.282593,605.514221 
	C1439.388184,605.375305 1437.446411,605.862427 1437.676880,609.508240 
	C1437.883057,612.771912 1438.983276,614.289978 1442.524292,614.067444 
	C1447.003174,613.785889 1451.512207,613.982849 1456.894287,613.978027 
	C1459.186890,613.988159 1460.593384,613.993774 1462.000000,613.999390 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1193.127319,643.600769 
	C1195.133789,640.357910 1197.084717,637.538696 1199.202881,634.406128 
	C1202.192505,632.626404 1204.113159,628.421143 1208.675415,631.082031 
	C1209.034546,631.017944 1208.990112,631.047485 1208.996826,631.327393 
	C1209.319824,631.741699 1209.635864,631.876099 1209.963989,632.017334 
	C1209.975952,632.024231 1209.989624,632.048035 1209.989746,632.328491 
	C1210.311279,632.743408 1210.632935,632.877930 1210.957275,633.035767 
	C1210.960083,633.059082 1210.959229,633.106140 1210.937378,633.476074 
	C1212.320557,636.818237 1213.725464,639.790527 1214.856323,642.182922 
	C1214.914062,641.430115 1215.044556,639.727478 1215.175171,638.024841 
	C1215.372925,638.037598 1215.570801,638.050354 1215.768555,638.063110 
	C1215.768555,641.678162 1215.768555,645.293274 1215.768555,648.908386 
	C1215.629272,648.929321 1215.489990,648.950256 1215.350708,648.971191 
	C1215.099976,647.950806 1214.849243,646.930420 1214.400757,645.105103 
	C1213.591064,651.046875 1208.865601,656.094177 1204.003662,657.002563 
	C1204.003662,657.002563 1204.000000,657.000000 1204.009277,656.636597 
	C1200.618164,654.903992 1197.217773,653.534790 1193.817383,652.165588 
	C1193.568970,649.451904 1193.320435,646.738159 1193.127319,643.600769 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1204.098145,657.342529 
	C1208.865601,656.094177 1213.591064,651.046875 1214.400757,645.105103 
	C1214.849243,646.930420 1215.099976,647.950806 1215.350708,648.971191 
	C1215.489990,648.950256 1215.629272,648.929321 1215.768555,648.908386 
	C1215.768555,645.293274 1215.768555,641.678162 1215.768555,638.063110 
	C1215.570801,638.050354 1215.372925,638.037598 1215.175171,638.024841 
	C1215.044556,639.727478 1214.914062,641.430115 1214.856323,642.182922 
	C1213.725464,639.790527 1212.320557,636.818237 1211.272949,633.432495 
	C1213.061279,632.812683 1214.492065,632.606262 1216.387085,632.332825 
	C1215.998901,628.199890 1215.571777,623.651978 1215.144653,619.104126 
	C1215.913574,618.784851 1216.682373,618.465576 1217.798340,618.176025 
	C1218.104248,620.857239 1218.126953,623.511597 1218.006958,626.159485 
	C1217.817261,630.345581 1217.393311,634.613037 1224.226685,634.893372 
	C1216.878540,635.716003 1217.860107,639.922852 1218.737915,644.564453 
	C1219.800659,650.183777 1215.097290,658.388062 1209.464111,660.444458 
	C1208.210083,660.902222 1205.975708,658.674011 1204.098145,657.342529 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1214.977539,619.391357 
	C1215.571777,623.651978 1215.998901,628.199890 1216.387085,632.332825 
	C1214.492065,632.606262 1213.061279,632.812683 1211.294922,633.062622 
	C1210.959229,633.106140 1210.960083,633.059082 1210.888184,632.797607 
	C1210.624512,632.231567 1210.348999,632.068848 1209.989624,632.048035 
	C1209.989624,632.048035 1209.975952,632.024231 1209.895264,631.779663 
	C1209.623291,631.231384 1209.348389,631.068909 1208.990112,631.047485 
	C1208.990112,631.047485 1209.034546,631.017944 1209.049316,631.040527 
	C1208.407104,629.599548 1207.750366,628.135925 1206.951294,626.355164 
	C1209.624878,624.083862 1212.217773,621.881226 1214.977539,619.391357 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1246.267822,615.477661 
	C1246.043579,615.048279 1246.049194,614.820557 1246.070801,614.251343 
	C1246.950928,614.064209 1247.815063,614.218689 1248.679199,614.373169 
	C1248.697754,614.638550 1248.716431,614.903992 1248.735107,615.169434 
	C1247.989258,615.339417 1247.243408,615.509338 1246.267822,615.477661 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1186.041504,648.028564 
	C1184.932373,648.568970 1183.823242,649.109375 1182.700195,649.656494 
	C1181.015137,642.743835 1181.911621,641.779907 1188.822510,643.388916 
	C1187.829102,644.763855 1186.905396,646.042419 1185.990845,647.660706 
	C1186.000000,648.000488 1186.041504,648.028503 1186.041504,648.028564 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1186.013672,648.021851 
	C1187.486938,647.275513 1188.946655,646.507874 1190.730957,645.569641 
	C1190.730957,647.500244 1190.730957,649.442200 1190.730957,651.675415 
	C1188.931519,651.675415 1187.113770,651.675415 1184.905640,651.675415 
	C1185.475098,650.478882 1185.875122,649.638062 1186.158447,648.412842 
	C1186.041504,648.028503 1186.000000,648.000488 1186.013672,648.021851 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1193.747925,652.397217 
	C1197.217773,653.534790 1200.618164,654.903992 1204.008057,656.635071 
	C1200.564575,655.999207 1197.131470,655.001465 1193.290771,653.923584 
	C1193.148071,653.438538 1193.413208,653.033691 1193.747925,652.397217 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2520.116211,1007.326782 
	C2522.429688,1007.055908 2524.732422,1006.893311 2526.795410,1007.520142 
	C2527.434082,1007.714233 2528.103516,1010.794983 2527.581055,1011.277039 
	C2525.848633,1012.875000 2523.579834,1013.891113 2520.446777,1015.773071 
	C2520.249023,1012.110718 2520.131104,1009.925049 2520.116211,1007.326782 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M2527.995605,1001.995728 
	C2525.488281,1002.547302 2522.985107,1003.103210 2518.608154,1004.075256 
	C2520.156982,1000.565491 2520.640625,996.988770 2522.212646,996.419739 
	C2525.564941,995.206299 2527.511230,997.803589 2527.962891,1001.614014 
	C2527.991211,1001.991028 2528.000000,1002.000000 2527.995605,1001.995728 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M467.646667,759.947388 
	C467.835541,760.567078 467.679382,761.101990 467.289734,761.798462 
	C463.855316,761.986145 460.498413,761.386597 457.478638,762.139404 
	C437.100006,767.220032 416.387756,764.252380 395.838318,764.880066 
	C391.640778,765.008301 390.826355,766.399231 390.966614,770.108459 
	C391.412262,781.892822 391.569702,793.687683 391.921204,805.476074 
	C392.027954,809.055298 392.402893,812.626526 392.444611,816.704956 
	C392.207550,819.457275 392.180878,821.706116 392.163513,824.358704 
	C390.734253,827.947510 389.295624,831.132507 388.424164,833.061890 
	C390.082642,835.330933 391.088409,836.706970 391.687469,838.159668 
	C389.128448,838.821167 386.976166,839.405945 384.499268,839.924438 
	C382.829102,839.236023 381.483521,838.614014 380.137939,837.992004 
	C380.003387,836.696777 379.868805,835.401550 379.711609,833.716370 
	C379.678894,814.392456 379.668793,795.458435 379.409271,776.181885 
	C379.159851,767.550598 379.159851,759.261902 379.159851,750.150208 
	C408.903503,749.825989 438.371277,749.504761 468.281189,749.216187 
	C467.986572,750.414612 467.484955,751.897705 466.451660,752.662842 
	C465.253754,753.549805 463.588837,753.806091 462.115265,754.340332 
	C462.065399,754.176208 461.786255,753.257690 461.266754,751.548401 
	C460.076874,753.054688 459.253632,754.096863 458.372192,755.212708 
	C457.692108,754.262634 456.943970,753.217468 456.195831,752.172302 
	C455.895752,752.331726 455.595673,752.491211 455.295593,752.650696 
	C455.295593,754.489685 455.295593,756.328735 455.295593,758.324646 
	C459.285400,758.835754 463.293518,759.349243 467.646667,759.947388 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M356.058411,747.358032 
	C357.061768,747.010498 358.136688,747.002441 359.614746,747.010132 
	C359.138031,749.657898 357.799530,749.635620 356.058411,747.358032 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M313.983093,800.974487 
	C309.886108,800.953918 306.274750,800.953918 302.398041,800.953918 
	C304.227203,797.676208 318.345184,796.802185 323.891815,800.434631 
	C320.750763,800.621460 317.609711,800.808228 313.983093,800.974487 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M342.046448,837.911438 
	C343.636993,840.799438 345.022430,843.365356 346.881592,846.808533 
	C338.375671,846.808533 330.757416,846.808533 323.166931,846.808533 
	C324.491669,837.578857 332.857452,833.331055 342.046448,837.911438 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M326.471558,856.748657 
	C325.080933,854.299133 323.895721,852.191467 322.306427,849.365234 
	C330.691040,849.365234 338.176117,849.365234 347.077087,849.365234 
	C344.725098,852.960999 343.132812,858.083862 340.710052,858.512451 
	C336.323914,859.288391 330.915558,861.212280 326.471558,856.748657 
M336.263611,850.985779 
	C335.171021,851.446411 334.078430,851.907104 332.985840,852.367737 
	C333.619598,853.474426 334.253326,854.581177 334.778961,855.499023 
	C334.256134,855.972595 333.574493,856.590027 332.506866,857.557007 
	C338.037994,857.801025 338.611664,856.846252 336.263611,850.985779 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M311.084717,866.519043 
	C310.832733,866.900208 310.659515,866.963562 310.209869,867.041016 
	C309.865417,866.731384 309.784760,866.409668 309.731293,866.083557 
	C308.526337,858.736572 308.850037,858.400635 316.989929,859.373413 
	C314.884827,861.840271 313.024170,864.020752 311.084717,866.519043 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M407.049133,874.825684 
	C408.508514,870.972351 410.181305,867.258057 412.114716,862.965088 
	C413.515686,866.584229 414.777313,869.843445 416.074982,873.470947 
	C415.723785,873.963013 415.336517,874.086731 414.522156,873.861206 
	C413.588837,872.225342 413.082672,870.938843 412.603088,869.719849 
	C410.867798,871.628479 409.317108,873.334167 407.766418,875.039795 
	C407.598511,875.014832 407.430542,874.989807 407.049133,874.825684 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M407.830933,875.395081 
	C409.317108,873.334167 410.867798,871.628479 412.603088,869.719849 
	C413.082672,870.938843 413.588837,872.225342 414.216797,873.863281 
	C413.374786,875.040833 412.268066,875.749695 411.475250,876.716125 
	C409.450623,879.183899 408.637512,877.729370 407.830933,875.395081 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M371.389404,798.025513 
	C372.358276,798.215088 372.901245,798.400635 373.444214,798.586182 
	C373.410400,798.879333 373.376587,799.172485 373.342773,799.465637 
	C366.768585,799.465637 360.194427,799.465637 353.620239,799.465637 
	C353.604095,798.984253 353.587952,798.502869 353.571808,798.021484 
	C359.369019,798.021484 365.166229,798.021484 371.389404,798.025513 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M380.076721,838.373657 
	C381.483521,838.614014 382.829102,839.236023 384.701599,840.238281 
	C386.492554,841.225342 387.705994,842.143188 389.033691,842.359375 
	C390.643158,842.621704 392.349152,842.291138 394.013428,842.217102 
	C394.349457,847.465027 394.685486,852.713013 395.039917,858.413208 
	C392.161438,858.903687 389.226257,858.657959 386.376068,859.042297 
	C381.241730,859.734619 379.287872,857.701050 380.034851,852.684143 
	C380.179108,851.715271 379.993439,850.697266 379.871704,849.243164 
	C379.860779,845.441345 379.938141,842.098328 380.076721,838.373657 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M375.993317,849.700073 
	C376.407806,849.231384 376.826172,849.215271 377.555908,849.240723 
	C378.215240,856.419739 378.563202,863.557190 378.953247,871.558533 
	C375.588104,868.746582 375.512360,867.945740 375.993317,849.700073 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M394.705200,860.239136 
	C395.174957,860.840454 395.165070,861.352539 395.213928,862.106873 
	C390.253174,862.599182 385.233643,862.849182 380.214142,863.099243 
	C380.182098,862.244080 380.150024,861.388916 380.117981,860.533752 
	C384.720856,859.450195 389.513214,860.183716 394.705200,860.239136 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M282.044861,766.384521 
	C283.358734,766.579102 284.706360,767.159485 286.193481,767.990601 
	C285.711090,768.675537 285.089203,769.109619 284.448853,769.821289 
	C283.421448,772.111206 282.412567,774.123474 281.312866,776.316895 
	C277.908813,773.282043 281.039307,770.028625 282.044861,766.384521 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M293.722351,772.065186 
	C293.933685,772.935852 293.814453,773.818970 293.528839,775.934204 
	C292.729462,774.105957 292.411011,773.377747 292.085022,772.292114 
	C292.515533,771.982361 292.953613,772.029968 293.722351,772.065186 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M294.845886,771.164062 
	C294.901459,771.381409 294.674591,771.590088 294.245697,771.916382 
	C294.226288,771.751221 294.399536,771.458374 294.845886,771.164062 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M425.992096,874.574951 
	C426.498871,869.343323 427.388184,864.339111 428.554626,859.088989 
	C429.195953,860.324402 429.560120,861.805908 429.637726,863.891663 
	C429.212433,867.527710 429.073730,870.559448 428.939758,873.487671 
	C431.844574,873.907654 433.954224,874.212646 436.063904,874.517700 
	C435.607056,876.362732 435.150238,878.207825 434.317261,880.139221 
	C429.225342,881.478455 428.310455,877.428223 425.992096,874.574951 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M436.323883,874.415771 
	C433.954224,874.212646 431.844574,873.907654 428.939758,873.487671 
	C429.073730,870.559448 429.212433,867.527710 429.660858,864.207642 
	C437.998657,862.719666 435.992004,869.142822 437.073486,873.666382 
	C436.926239,874.167664 436.755035,874.240784 436.323883,874.415771 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M424.401489,876.939087 
	C424.241241,876.594788 424.345734,876.304688 424.450195,876.014587 
	C424.786133,876.197449 425.122040,876.380310 425.457947,876.563232 
	C425.194031,876.706604 424.930145,876.849976 424.401489,876.939087 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M581.909363,818.210999 
	C578.752625,824.274780 579.452637,830.512085 581.600708,837.094238 
	C582.334961,837.667175 582.607666,837.818787 582.945862,837.852417 
	C582.951294,838.068115 582.956787,838.283875 582.956421,838.824951 
	C581.972351,841.109314 580.994080,843.068359 579.496094,846.068054 
	C575.350891,846.068054 570.151489,846.068054 564.368652,845.828918 
	C563.499146,845.387207 563.213074,845.184631 562.927002,844.982056 
	C562.525940,813.223511 562.124817,781.464966 561.817749,749.109680 
	C568.023315,748.617493 574.134766,748.722046 580.192627,749.202148 
	C580.194702,750.076660 580.250366,750.575562 580.230347,751.455078 
	C580.227051,752.862305 580.299500,753.888916 580.257324,755.380859 
	C580.075562,762.901367 580.008362,769.956421 579.747559,777.102600 
	C579.310120,777.358826 579.135315,777.578857 578.760620,777.888916 
	C578.315552,777.965637 578.139526,778.007263 577.598755,778.053589 
	C576.191040,779.103088 575.148071,780.147705 573.738403,781.048340 
	C572.108093,779.797607 570.844360,778.690796 568.395020,776.545593 
	C567.617188,779.743713 565.762939,783.006042 566.695740,784.390381 
	C569.035400,787.862488 571.477112,784.682434 573.670959,783.119873 
	C573.803833,783.025208 573.971375,782.979187 574.326965,783.344116 
	C576.247559,783.655396 577.964050,783.533020 579.575073,783.979431 
	C579.366150,784.919739 579.262573,785.291199 579.123657,786.009521 
	C579.415466,786.914429 579.742676,787.472473 580.083984,788.458008 
	C580.084656,790.877930 580.071289,792.870422 580.036011,795.344604 
	C580.009155,801.235718 579.848816,806.652405 580.101379,812.049744 
	C580.169983,813.516357 581.484131,814.924744 582.275635,816.584290 
	C582.186279,817.276123 582.047852,817.743591 581.909363,818.210999 
M576.591492,840.517944 
	C576.591492,840.517944 576.476562,840.587280 576.591492,840.517944 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M560.018005,748.981201 
	C560.018005,784.791748 560.018005,820.602173 560.018005,856.412659 
	C559.441833,856.429199 558.865723,856.445740 558.289551,856.462280 
	C558.289551,846.958191 558.289551,837.454163 558.289551,827.950073 
	C557.867493,827.954773 557.445374,827.959534 557.023315,827.964294 
	C557.023315,836.075562 556.926758,844.188538 557.072144,852.297241 
	C557.128113,855.423157 556.767639,857.653198 552.844177,856.983582 
	C549.892212,856.479797 545.242615,858.895264 545.861389,852.526672 
	C546.181702,849.229492 545.895264,845.874634 545.906006,842.545898 
	C545.919739,838.279724 543.801514,836.125488 539.544128,836.095825 
	C536.574341,836.075134 533.604370,836.091797 530.357605,836.091797 
	C530.100586,831.360718 532.700439,828.494629 535.952026,827.192566 
	C540.481689,825.378723 545.531921,824.913147 550.246765,823.495850 
	C553.200012,822.608093 556.069702,820.341736 557.926270,826.551575 
	C557.926270,799.885254 557.926270,774.847229 558.075928,749.165161 
	C558.823059,748.674377 559.420532,748.827820 560.018005,748.981201 
M551.798584,853.865479 
	C551.798584,852.413574 551.798584,850.961609 551.798584,849.509705 
	C551.155151,849.624390 550.511780,849.739075 549.868347,849.853821 
	C550.373901,851.382812 550.879456,852.911804 551.798584,853.865479 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M589.734741,859.057861 
	C590.222900,859.016418 590.485229,858.982483 590.595520,859.378479 
	C589.569885,860.196777 588.439270,860.972107 587.297241,860.989258 
	C563.862671,861.340637 540.427063,861.627014 516.991150,861.879150 
	C503.683563,862.022339 490.375153,862.081238 477.067108,862.178162 
	C466.737396,862.122681 456.407562,862.079956 446.078033,861.999268 
	C444.350189,861.985718 442.623657,861.807007 440.896515,861.704651 
	C440.876190,861.226746 440.855835,860.748840 440.835480,860.270996 
	C442.287628,860.189026 443.739655,860.038391 445.191956,860.035522 
	C468.634888,859.988770 492.078461,860.055298 515.520630,859.902466 
	C540.096680,859.742249 564.671326,859.375671 589.734741,859.057861 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M448.619080,857.821167 
	C443.051636,857.927979 437.938416,857.927979 432.391785,857.927979 
	C432.391785,852.994141 432.391785,848.336670 432.391785,842.986938 
	C437.474213,842.986938 442.394043,842.904724 447.306244,843.062073 
	C448.201599,843.090820 449.832123,844.110657 449.822937,844.650879 
	C449.748871,849.008301 449.363556,853.360535 448.619080,857.821167 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M477.056335,862.433228 
	C490.375153,862.081238 503.683563,862.022339 516.991150,861.879150 
	C540.427063,861.627014 563.862671,861.340637 587.297241,860.989258 
	C588.439270,860.972107 589.569885,860.196777 590.727539,859.370605 
	C592.644592,858.368530 594.539917,857.770325 596.814575,857.319580 
	C596.461731,859.086792 595.729614,860.706604 594.986328,862.678772 
	C591.790222,863.009583 588.604187,862.925171 585.420410,862.977234 
	C572.468140,863.189331 559.516907,863.613037 546.564697,863.637451 
	C531.455688,863.665894 516.346313,863.265198 501.236725,863.236450 
	C493.469910,863.221680 485.702332,863.610657 477.510101,863.760620 
	C477.071899,863.365479 477.058716,863.026917 477.056335,862.433228 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M536.761597,808.195190 
	C536.077576,808.436523 534.966797,809.069885 534.773865,808.859863 
	C532.151733,806.006226 532.517883,799.248840 535.959290,796.215027 
	C540.508911,800.442383 539.962830,795.568542 541.056946,794.021912 
	C541.336853,793.827393 541.616760,793.632812 542.050171,793.227295 
	C542.875549,792.708191 543.547485,792.400024 544.591431,792.412109 
	C545.629272,793.527588 546.295044,794.322815 546.960815,795.118042 
	C546.962036,795.515076 546.963257,795.912048 546.732056,796.839600 
	C546.001465,798.581116 545.503235,799.791992 545.005005,801.002869 
	C540.992615,800.846375 542.274841,803.898926 541.775024,806.575317 
	C541.379761,807.435242 541.188660,807.715027 540.997559,807.994812 
	C540.006714,807.904114 539.015808,807.813354 537.509033,807.526184 
	C536.759827,807.385803 536.526489,807.441833 536.293091,807.497925 
	C536.449219,807.730286 536.605408,807.962769 536.761597,808.195190 
M535.519226,805.590088 
	C535.637451,805.348755 535.784668,805.115295 535.854309,804.860596 
	C535.872803,804.793152 535.652649,804.660339 535.541931,804.557556 
	C535.421814,804.799255 535.301697,805.041016 535.519226,805.590088 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M560.343994,748.967834 
	C559.420532,748.827820 558.823059,748.674377 558.032410,748.694580 
	C557.411133,748.924866 556.983093,748.981506 555.832764,748.954407 
	C553.723328,748.898071 552.336182,748.925415 550.948975,748.952759 
	C550.271851,748.936218 549.594727,748.919739 548.152588,748.864929 
	C544.051636,748.464172 543.927185,750.625793 544.094360,753.016602 
	C543.732361,752.966064 543.370361,752.915588 542.742554,752.812134 
	C542.421936,752.083008 542.367188,751.406982 542.352417,750.200378 
	C541.923218,749.420959 541.453979,749.172058 540.984802,748.923157 
	C539.800354,748.824768 538.615906,748.726318 536.978149,748.866333 
	C536.214294,749.318665 536.112305,749.606445 536.218933,749.968079 
	C536.147644,752.021729 536.076355,754.075378 536.022461,756.897400 
	C536.087524,758.111023 536.135132,758.556335 536.182800,759.001587 
	C536.135498,759.752686 536.088257,760.503784 536.051880,761.959717 
	C536.108948,763.110413 536.155212,763.556274 536.201416,764.002136 
	C535.839905,766.305603 536.711487,769.627441 532.158875,768.066772 
	C531.957153,767.270935 531.965210,767.051270 531.973328,766.831665 
	C532.635315,765.637573 533.798889,764.473389 533.872131,763.244202 
	C534.183838,758.015137 534.172546,752.766846 534.571167,747.218384 
	C535.366211,746.923401 535.869263,746.935547 537.203308,746.990845 
	C552.270142,746.942627 566.505981,746.851318 580.741760,746.760010 
	C581.321350,746.572205 581.900940,746.384338 582.764526,746.070068 
	C583.048523,745.943542 583.030151,745.965271 582.999634,746.289429 
	C582.646423,747.425720 582.323669,748.237915 581.708923,749.003845 
	C581.026733,748.913940 580.636475,748.870239 580.246277,748.826538 
	C574.134766,748.722046 568.023315,748.617493 561.616638,748.752380 
	C561.104309,748.979370 560.887146,748.966919 560.343994,748.967834 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M550.980225,749.424805 
	C552.336182,748.925415 553.723328,748.898071 555.510132,748.990112 
	C555.929932,755.102844 555.829834,761.099854 556.015686,767.088013 
	C556.127258,770.680847 554.700500,771.500916 551.011475,770.202759 
	C551.011475,763.470459 551.011475,756.683655 550.980225,749.424805 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M556.030273,785.932312 
	C556.011414,788.740295 556.220581,791.105835 555.826111,793.365967 
	C555.660767,794.313416 554.216248,795.037598 553.353210,795.863281 
	C552.740417,794.914490 551.652222,793.993958 551.593811,793.012268 
	C551.273254,787.622559 551.039062,782.213867 551.171265,776.821411 
	C551.202759,775.536255 552.747681,774.288330 553.596497,773.023315 
	C554.396790,774.176086 555.745178,775.265137 555.888245,776.494385 
	C556.231506,779.443604 556.008850,782.458740 556.030273,785.932312 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M547.370361,795.013184 
	C546.295044,794.322815 545.629272,793.527588 544.872986,792.397095 
	C544.529358,786.958069 544.095154,781.852173 544.153748,776.751953 
	C544.167969,775.515930 545.807434,774.298584 546.699524,773.072632 
	C547.479553,774.239807 548.872925,775.376282 548.933044,776.579407 
	C549.188904,781.701904 549.116211,786.845703 548.997498,791.977844 
	C548.974670,792.963562 548.205017,793.931946 547.370361,795.013184 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M545.006836,801.470703 
	C545.503235,799.791992 546.001465,798.581116 546.730713,797.137390 
	C547.677002,798.146851 548.884399,799.338074 549.019226,800.640442 
	C549.510620,805.386414 549.781738,810.168884 549.802063,814.939087 
	C549.806396,815.965515 548.417664,816.997864 547.670593,818.027527 
	C546.793518,816.965759 545.239807,815.952576 545.159485,814.833618 
	C544.852295,810.553650 545.018921,806.239746 545.006836,801.470703 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M544.365234,753.324463 
	C543.927185,750.625793 544.051636,748.464172 547.813232,748.843628 
	C548.488647,755.123535 548.837891,761.385559 548.890259,767.650085 
	C548.898987,768.696655 547.618591,769.754089 546.933899,770.806458 
	C546.282654,769.707336 545.144714,768.641968 545.065552,767.503113 
	C544.745239,762.891785 544.750732,758.257874 544.365234,753.324463 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M551.933716,800.314209 
	C552.768005,798.923157 553.597717,797.995483 554.427490,797.067871 
	C554.957764,797.959167 555.918945,798.837097 555.947144,799.744019 
	C556.106934,804.882812 556.144104,810.032166 555.964600,815.168823 
	C555.931763,816.109802 554.709290,817.009277 554.035950,817.927917 
	C553.353943,817.022522 552.124573,816.142151 552.081970,815.207581 
	C551.863159,810.405212 551.949402,805.588989 551.933716,800.314209 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M467.088684,841.360962 
	C486.386902,841.132812 505.678650,841.132812 524.970398,841.132812 
	C524.974915,841.620667 524.979431,842.108521 524.983887,842.596375 
	C505.959930,842.596375 486.935944,842.596375 467.582092,842.537048 
	C467.182831,842.183960 467.126190,841.887695 467.088684,841.360962 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M448.242340,834.033264 
	C449.159515,836.096008 449.683624,838.125427 450.341492,840.672729 
	C446.844727,840.672729 444.290863,840.672729 441.736969,840.672729 
	C441.433289,840.314148 441.129608,839.955566 440.825928,839.596985 
	C443.167053,837.731323 445.508148,835.865601 448.242340,834.033264 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M569.159546,856.976196 
	C566.831055,856.857727 564.967834,856.741455 562.677124,856.598450 
	C564.689819,853.703064 577.167419,853.673279 580.582886,856.978333 
	C576.930237,856.978333 573.277588,856.978333 569.159546,856.976196 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M438.185913,840.659607 
	C436.192780,840.847351 434.558136,840.847351 432.499756,840.847351 
	C432.499756,839.006226 432.499756,837.308167 432.499756,835.120789 
	C436.267181,835.059631 438.722778,836.138489 438.185913,840.659607 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M540.236511,841.960876 
	C535.839050,842.009155 531.687439,842.009155 527.535828,842.009155 
	C527.526611,841.615295 527.517334,841.221497 527.508118,840.827637 
	C531.719666,840.639343 535.931213,840.451050 540.584229,840.228943 
	C540.844604,840.767639 540.663452,841.340088 540.236511,841.960876 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M541.141357,808.227661 
	C541.188660,807.715027 541.379761,807.435242 541.762085,806.980835 
	C542.258911,807.329285 542.564514,807.852356 542.823486,808.692383 
	C542.279602,808.826416 541.782349,808.643494 541.141357,808.227661 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M451.276428,840.249756 
	C451.480988,840.148376 451.661407,840.295532 451.881989,840.648926 
	C451.665314,840.825806 451.442017,840.706848 451.276428,840.249756 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M563.019775,845.241089 
	C563.213074,845.184631 563.499146,845.387207 563.901672,845.830322 
	C563.716248,845.880615 563.414368,845.690308 563.019775,845.241089 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M465.724396,894.552856 
	C464.292633,896.175049 463.108582,897.513184 460.994019,899.902832 
	C460.848633,896.756775 460.754486,894.719238 460.662628,892.731567 
	C466.092712,891.584961 466.092712,891.584961 465.724396,894.552856 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M509.187683,886.687683 
	C514.737000,886.687622 519.814392,886.687622 525.004028,886.687622 
	C523.553772,894.525146 521.438354,897.015564 516.658752,897.100098 
	C507.380402,897.264160 505.273743,895.705688 503.276001,886.687744 
	C505.175690,886.687744 506.945740,886.687744 509.187683,886.687683 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M521.127686,875.285156 
	C521.932190,876.404724 522.435669,877.277588 523.166809,878.356445 
	C524.056213,880.245056 524.717957,881.927856 525.644714,884.284546 
	C517.773010,884.284546 510.327118,884.284546 502.914032,884.284546 
	C506.114044,874.034729 510.143707,871.925964 521.127686,875.285156 
M517.427856,876.138428 
	C516.290771,876.296692 515.154663,876.583801 514.016541,876.591797 
	C507.936584,876.634583 507.865570,876.711975 509.648834,882.874390 
	C515.907288,883.265808 519.069885,880.839233 517.427856,876.138428 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M605.997681,869.737061 
	C606.654053,869.434265 607.517212,869.328796 608.380310,869.223328 
	C608.394287,869.460327 608.408325,869.697388 608.422302,869.934387 
	C607.682983,869.934387 606.943726,869.934387 605.997681,869.737061 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M599.396240,872.673889 
	C598.594116,872.786682 598.033630,872.650940 597.473022,872.515198 
	C597.645081,872.294739 597.857117,871.865723 597.982239,871.889709 
	C598.547546,871.998169 599.088196,872.234863 599.396240,872.673889 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M530.009277,917.991821 
	C527.721069,917.991272 525.044067,918.389282 526.847717,913.284973 
	C528.188293,914.985046 529.084900,916.122009 529.982666,917.637817 
	C529.983887,918.016602 530.000549,917.999939 530.009277,917.991821 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M997.969238,601.012817 
	C998.258362,600.529724 998.547485,600.046692 999.310913,599.241333 
	C1000.352417,597.688477 1000.919678,596.457886 1001.486877,595.227234 
	C1003.732056,595.524597 1005.977234,595.821960 1008.222412,596.119324 
	C1008.266479,596.601135 1008.310547,597.082947 1008.354553,597.564758 
	C1009.113892,597.032043 1009.805664,596.262451 1010.648315,596.022522 
	C1011.901428,595.665649 1013.319092,595.407288 1014.549255,595.665771 
	C1015.103577,595.782288 1015.929504,597.527832 1015.667297,598.045837 
	C1014.960999,599.441040 1013.844727,601.509766 1012.696960,601.643433 
	C1008.435486,602.139832 1004.095825,601.964844 999.311096,601.772766 
	C998.547546,601.339966 998.258423,601.176392 997.969238,601.012817 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M943.209717,567.778564 
	C944.524719,567.467773 945.825439,567.037598 947.158569,566.878235 
	C948.639587,566.701111 950.210022,566.537903 951.605896,566.896484 
	C951.955688,566.986450 952.302246,569.577637 951.744385,570.179382 
	C950.671143,571.337036 949.028198,572.212952 947.472046,572.592712 
	C946.379272,572.859375 945.038879,572.111145 943.223999,571.669556 
	C941.495300,571.465393 940.351501,571.407898 938.016357,571.290527 
	C940.412048,569.670471 941.810913,568.724487 943.209717,567.778564 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M943.100952,567.502930 
	C941.810913,568.724487 940.412048,569.670471 938.016357,571.290527 
	C940.351501,571.407898 941.495300,571.465393 942.825195,571.769226 
	C941.428650,572.575623 939.845947,573.135681 937.691284,573.488403 
	C936.672424,572.509583 935.684509,571.543823 935.878906,571.002686 
	C936.490051,569.301147 937.545593,567.759094 938.429016,566.155273 
	C939.950073,566.512573 941.471191,566.869934 943.100952,567.502930 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M938.031372,566.090576 
	C937.545593,567.759094 936.490051,569.301147 935.878906,571.002686 
	C935.684509,571.543823 936.672424,572.509583 937.361694,573.554565 
	C932.672546,575.254639 933.636047,570.306824 931.457397,568.258667 
	C931.825439,567.071899 932.498962,566.052673 933.472046,564.968750 
	C935.059021,565.278015 936.346375,565.651978 938.031372,566.090576 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1016.141418,615.031982 
	C1017.300781,615.983398 1018.355835,616.860168 1018.671875,617.951050 
	C1018.787109,618.348938 1017.112122,619.833435 1016.311890,619.788635 
	C1015.249390,619.729187 1014.243591,618.657654 1013.213440,618.020996 
	C1014.057129,617.028015 1014.900818,616.035095 1016.141418,615.031982 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1001.130554,595.078735 
	C1000.919678,596.457886 1000.352417,597.688477 999.417358,598.971191 
	C998.389221,597.670288 997.728943,596.317322 996.545227,593.891724 
	C998.646790,594.407837 999.710510,594.669006 1001.130554,595.078735 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M923.981079,572.697144 
	C923.297424,573.012207 922.620728,572.960083 921.610168,572.916382 
	C921.947449,572.250916 922.618530,571.576965 923.617126,570.881470 
	C923.959045,571.349915 923.973511,571.839905 923.981079,572.697144 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M997.750732,601.169800 
	C998.258423,601.176392 998.547546,601.339966 998.922852,601.741455 
	C998.527161,602.162415 998.045410,602.345337 997.563660,602.528259 
	C997.553162,602.127747 997.542664,601.727234 997.750732,601.169800 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M959.923645,599.722290 
	C959.335571,599.560364 958.651855,599.113281 957.979736,598.322998 
	C958.603516,598.465515 959.215759,598.951294 959.923645,599.722290 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M965.574768,590.999512 
	C965.347351,591.172485 964.773438,591.349548 964.018616,591.302368 
	C964.301208,591.053345 964.764771,591.028503 965.574768,590.999512 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M983.945679,600.861450 
	C983.511780,600.888000 983.105469,600.597046 982.699219,600.306030 
	C982.949768,600.177979 983.200378,600.049988 983.721558,599.914429 
	C983.985718,600.119385 983.979431,600.331726 983.945679,600.861450 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1037.114136,476.236023 
	C1037.450073,476.247864 1037.896484,476.477753 1038.179932,476.856812 
	C1037.692627,476.908569 1037.428589,476.724609 1037.114136,476.236023 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M964.414307,517.584229 
	C964.343689,517.483948 964.609070,517.435364 964.609070,517.435364 
	C964.609070,517.435364 964.484924,517.684448 964.414307,517.584229 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1054.090576,560.900391 
	C1055.677246,562.021545 1060.146118,560.865356 1057.445068,566.075195 
	C1056.046021,564.403809 1054.955200,563.100586 1053.851929,561.470886 
	C1053.839478,561.144348 1054.009521,560.984436 1054.090576,560.900391 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M971.667725,598.177734 
	C973.901978,599.345825 975.799011,600.332947 977.759644,601.353149 
	C977.485046,604.614929 976.250732,605.200378 973.173950,604.425537 
	C969.142334,603.410278 966.682434,602.049194 968.565918,597.159729 
	C969.513550,597.446655 970.422058,597.721741 971.667725,598.177734 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1053.439087,526.418945 
	C1053.508423,526.381226 1053.369751,526.456665 1053.439087,526.418945 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M982.043945,497.963715 
	C982.195312,498.126801 982.310059,498.323486 982.424805,498.520203 
	C982.308594,498.476624 982.192383,498.433075 982.007996,498.229980 
	C981.939819,498.070435 982.007324,497.997345 982.043945,497.963715 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M985.040649,493.968445 
	C985.202515,494.122070 985.319702,494.317444 985.436890,494.512817 
	C985.314636,494.477600 985.192505,494.442383 984.991943,494.253845 
	C984.913635,494.100525 984.995972,494.010193 985.040649,493.968445 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1006.013306,480.991150 
	C1006.157959,481.429993 1006.281311,481.885956 1006.404724,482.341949 
	C1006.254639,482.072479 1006.104553,481.802979 1005.953369,481.289551 
	C1005.952209,481.045593 1005.992065,481.008270 1006.013306,480.991150 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1524.397583,646.877441 
	C1529.019165,646.877441 1533.640747,646.877441 1539.022095,646.877441 
	C1539.022095,634.656860 1539.022095,622.769165 1539.064209,610.409241 
	C1539.447754,610.277588 1539.789185,610.618225 1540.102783,611.606018 
	C1540.726685,613.516663 1541.378662,614.780212 1542.030518,616.043701 
	C1542.046387,617.428650 1542.062256,618.813599 1541.839600,620.671753 
	C1541.526978,621.253418 1541.371948,621.438354 1541.390991,621.458252 
	C1541.560669,621.636902 1541.761108,621.786438 1541.952393,621.944519 
	C1541.972168,622.129944 1541.991943,622.315308 1541.646484,622.795776 
	C1541.494019,625.035889 1541.706543,626.980896 1541.919189,628.925964 
	C1541.979980,629.685913 1542.040894,630.445862 1541.744141,631.821045 
	C1541.534180,634.903259 1541.681885,637.370117 1541.829468,639.837036 
	C1541.888794,640.921997 1541.948242,642.006958 1541.735474,643.735962 
	C1541.612793,646.220154 1541.762329,648.060303 1541.911987,649.900513 
	C1541.923462,650.117920 1541.934937,650.335327 1541.701416,651.136841 
	C1540.526978,651.655945 1539.597778,651.591003 1538.664551,651.269897 
	C1537.873657,651.210144 1537.086914,651.406555 1535.866943,651.735107 
	C1534.845581,651.756653 1534.257446,651.646057 1533.655640,651.277161 
	C1532.853271,651.225464 1532.064453,651.432068 1530.867188,651.776001 
	C1528.471191,651.963867 1526.483643,652.014465 1524.533203,651.632202 
	C1524.512573,649.758789 1524.455078,648.318115 1524.397583,646.877441 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1543.198486,605.747681 
	C1543.487671,617.004517 1543.681152,628.696838 1543.863892,640.389343 
	C1543.865479,640.485291 1543.723267,640.583435 1543.648193,640.680542 
	C1543.453979,640.323608 1543.259888,639.966736 1542.978027,639.319641 
	C1542.960938,628.080688 1543.031616,617.131958 1543.198486,605.747681 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1541.955200,628.507874 
	C1541.706543,626.980896 1541.494019,625.035889 1541.612671,623.073242 
	C1541.959717,624.733704 1541.975464,626.411743 1541.955200,628.507874 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1541.922607,639.637329 
	C1541.681885,637.370117 1541.534180,634.903259 1541.703735,632.200989 
	C1542.030151,634.030029 1542.039185,636.094482 1542.118164,638.595581 
	C1542.187988,639.032288 1542.015503,639.437683 1541.922607,639.637329 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1542.027588,615.639404 
	C1541.378662,614.780212 1540.726685,613.516663 1540.042236,611.851196 
	C1540.681274,612.711182 1541.352905,613.973145 1542.027588,615.639404 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1515.034180,596.748657 
	C1513.967285,597.114685 1512.886963,597.132202 1511.404297,597.092651 
	C1512.341675,596.823792 1513.681274,596.612000 1515.034180,596.748657 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1540.872925,608.264282 
	C1540.342773,607.229553 1540.100098,605.950439 1539.857544,604.671265 
	C1540.291870,604.629333 1540.726196,604.587341 1541.160522,604.545410 
	C1541.160522,605.703613 1541.160522,606.861755 1540.872925,608.264282 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1541.966187,621.714783 
	C1541.761108,621.786438 1541.560669,621.636902 1541.390991,621.458252 
	C1541.371948,621.438354 1541.526978,621.253418 1541.790527,621.084900 
	C1541.979858,621.024780 1541.979980,621.485046 1541.966187,621.714783 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1475.040283,553.954895 
	C1476.574219,554.712280 1480.063477,551.294495 1479.953247,557.077026 
	C1477.950684,556.118225 1476.470703,555.409668 1474.975830,554.368835 
	C1474.960938,554.036621 1475.014893,553.983215 1475.040283,553.954895 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1477.981567,560.022644 
	C1478.134277,560.163025 1478.286865,560.303467 1478.439575,560.443909 
	C1478.336792,560.396667 1478.234009,560.349487 1478.065186,560.152039 
	C1477.999146,560.001831 1477.981567,560.022644 1477.981567,560.022644 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1478.009521,559.993469 
	C1477.889771,559.837769 1477.759766,559.690369 1477.629761,559.543030 
	C1477.725464,559.597412 1477.821289,559.651794 1477.949219,559.864380 
	C1477.981567,560.022644 1477.999146,560.001831 1478.009521,559.993469 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1545.564941,667.527710 
	C1546.985840,670.762817 1544.072632,675.323242 1547.871826,678.286926 
	C1547.373535,679.593445 1546.784668,681.137390 1546.355957,683.283386 
	C1546.452148,684.952209 1546.388184,686.018982 1546.250854,687.493042 
	C1545.621094,691.232544 1547.128052,692.544861 1550.348755,692.109863 
	C1553.028687,691.747925 1554.047607,693.088867 1553.798462,695.546570 
	C1553.587036,697.631409 1553.176147,699.695923 1552.433594,701.782227 
	C1550.012695,702.525879 1548.011353,703.256775 1545.948486,704.336548 
	C1545.890381,706.357117 1545.893677,708.028687 1545.695312,709.734009 
	C1545.493408,709.767822 1545.108643,709.627930 1545.014648,709.415649 
	C1544.959351,708.492126 1544.998291,707.780762 1545.035645,706.600342 
	C1544.896729,704.860962 1544.759644,703.590637 1544.614502,702.245728 
	C1542.077881,702.096069 1539.810425,701.962341 1536.173584,701.747742 
	C1539.178223,698.421326 1533.156006,692.717651 1541.340576,691.958740 
	C1544.654419,689.560120 1545.541748,686.479492 1544.667969,682.521912 
	C1544.577393,681.414001 1544.416870,680.612549 1544.286865,679.510620 
	C1544.230225,678.817017 1544.143188,678.423950 1544.252197,677.808105 
	C1544.544189,676.805542 1544.640381,676.025757 1544.736572,675.246033 
	C1544.951660,672.785828 1545.166748,670.325562 1545.564941,667.527710 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1524.301880,660.453125 
	C1526.776611,661.154480 1529.251343,661.855896 1531.726196,662.557312 
	C1531.604980,663.013794 1531.483887,663.470337 1531.362793,663.926819 
	C1538.762817,662.829773 1541.984619,666.012146 1541.869995,674.600037 
	C1541.989380,675.012329 1542.027588,674.976074 1541.744995,674.827332 
	C1541.265259,674.759216 1541.068237,674.839905 1540.520020,675.000732 
	C1537.705933,676.467346 1535.243042,677.853882 1532.405029,679.223389 
	C1529.973389,679.432007 1527.916626,679.657654 1524.639648,680.017151 
	C1527.242310,675.883728 1528.962036,674.054871 1531.856812,677.859497 
	C1531.708252,675.058960 1531.588623,672.805969 1531.489624,670.939270 
	C1529.883911,671.025757 1528.240967,671.114258 1526.367676,671.215149 
	C1526.367676,672.516724 1526.367676,673.937378 1526.367676,675.358093 
	C1525.793335,675.488770 1525.219116,675.619446 1524.644775,675.750122 
	C1524.065063,673.686035 1523.485352,671.621887 1523.153320,669.469849 
	C1523.699585,668.893250 1523.998169,668.404602 1524.442383,667.551392 
	C1524.492676,664.942322 1524.397339,662.697754 1524.301880,660.453125 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1524.496094,652.065063 
	C1526.483643,652.014465 1528.471191,651.963867 1531.166504,651.810059 
	C1532.472656,651.649719 1533.070923,651.592590 1533.669189,651.535522 
	C1534.257446,651.646057 1534.845581,651.756653 1536.162109,651.752625 
	C1537.483154,651.600708 1538.075928,651.563354 1538.668579,651.526001 
	C1539.597778,651.591003 1540.526978,651.655945 1541.703247,651.463379 
	C1542.129517,651.175781 1542.308716,651.145752 1542.904541,651.140869 
	C1543.320923,651.166016 1543.644775,651.201721 1543.644775,651.201721 
	C1543.644775,655.848755 1543.644775,660.495728 1543.644775,665.651611 
	C1537.987183,662.883606 1532.316650,660.109192 1526.219116,656.959839 
	C1525.519775,656.391846 1525.247314,656.198914 1524.974976,656.006042 
	C1524.974976,656.006042 1524.635010,655.791016 1524.531006,655.320190 
	C1524.450073,653.921265 1524.473145,652.993164 1524.496094,652.065063 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1524.533203,651.632263 
	C1524.473145,652.993164 1524.450073,653.921265 1524.376587,655.191284 
	C1524.264648,656.846802 1524.203247,658.160400 1524.221924,659.963501 
	C1524.397339,662.697754 1524.492676,664.942322 1524.229492,667.572998 
	C1523.586914,668.002075 1523.301514,668.014893 1522.946777,667.565796 
	C1522.833008,664.819946 1522.787354,662.505676 1522.771729,659.765381 
	C1522.740967,658.488098 1522.680420,657.636841 1522.668091,656.407288 
	C1522.678833,654.761108 1522.641357,653.493103 1522.618286,651.827393 
	C1522.618652,650.899353 1522.604614,650.369019 1522.607422,649.585815 
	C1522.449829,649.056458 1522.275391,648.779907 1522.299805,648.100586 
	C1522.524658,647.399292 1522.550537,647.100891 1522.576416,646.802551 
	C1522.548096,641.336487 1522.519775,635.870422 1522.635254,629.564453 
	C1523.176636,627.156921 1523.574463,625.589294 1523.972168,624.021667 
	C1524.076172,631.335144 1524.180176,638.648682 1524.340820,646.419800 
	C1524.455078,648.318115 1524.512573,649.758789 1524.533203,651.632263 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1546.159180,640.199951 
	C1550.970947,640.814941 1555.689331,641.871216 1560.407593,642.927490 
	C1560.436768,643.138489 1560.465820,643.349487 1560.494995,643.560486 
	C1556.039429,643.771667 1551.583984,643.982849 1546.677856,644.212280 
	C1546.173340,643.034058 1546.119385,641.837646 1546.159180,640.199951 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1544.402344,675.078979 
	C1544.640381,676.025757 1544.544189,676.805542 1543.882446,677.739624 
	C1542.922607,677.322632 1542.528442,676.751404 1542.131592,676.263916 
	C1542.128784,676.347656 1541.942749,675.965576 1541.963379,676.005249 
	C1541.983887,676.044861 1542.068970,676.017456 1542.140747,675.753845 
	C1542.150757,675.318848 1542.089111,675.147461 1542.027588,674.976074 
	C1542.027588,674.976074 1541.989380,675.012329 1541.971191,675.031372 
	C1542.658203,675.004333 1543.363159,674.958130 1544.402344,675.078979 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1541.984619,649.480591 
	C1541.762329,648.060303 1541.612793,646.220154 1541.760132,644.145813 
	C1542.057129,645.627991 1542.057129,647.344299 1541.984619,649.480591 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1543.700195,650.938110 
	C1543.644775,651.201721 1543.320923,651.166016 1543.173584,651.096924 
	C1543.122681,650.630127 1543.219116,650.232422 1543.315430,649.834717 
	C1543.462158,650.114624 1543.608887,650.394592 1543.700195,650.938110 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1525.006104,656.292908 
	C1525.247314,656.198914 1525.519775,656.391846 1525.900635,656.815186 
	C1525.685181,656.890320 1525.361206,656.735046 1525.006104,656.292908 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1571.672974,550.098999 
	C1571.315308,550.476807 1570.618652,550.816650 1569.921875,551.156433 
	C1569.806274,550.826843 1569.690674,550.497314 1569.575073,550.167725 
	C1570.161377,550.132141 1570.747559,550.096558 1571.672974,550.098999 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2523.050293,707.094482 
	C2523.056152,708.703796 2523.078125,709.859009 2523.051025,711.013123 
	C2522.926758,716.295593 2521.700928,717.252319 2515.477539,716.603394 
	C2515.477539,710.987793 2515.477539,705.322510 2515.477539,699.676575 
	C2521.693359,698.327332 2522.884521,699.443848 2523.050293,707.094482 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M308.879211,921.414917 
	C316.743683,920.635864 324.653259,920.282227 332.566162,920.035461 
	C336.388092,919.916199 340.216248,919.997620 344.506653,919.954529 
	C345.624084,920.669067 346.276459,921.417297 346.928864,922.165466 
	C346.168274,922.583313 345.421417,923.329163 344.644714,923.361755 
	C341.401764,923.497498 338.149811,923.418945 334.285950,923.418945 
	C334.163147,924.890564 334.205688,926.514099 333.824219,928.031006 
	C333.640167,928.762878 332.673950,929.760925 332.032928,929.782532 
	C331.413544,929.803528 330.169098,928.681885 330.208862,928.135742 
	C330.553986,923.399231 327.152008,922.801819 324.112671,923.408203 
	C323.064178,923.617432 322.648438,927.279114 322.075043,929.399231 
	C321.923950,929.957947 322.262543,930.648987 322.347900,931.121948 
	C330.501801,931.121948 338.438110,931.121948 347.527802,931.121948 
	C346.534668,932.443665 345.594452,934.822205 344.601288,934.844666 
	C333.000305,935.105469 321.391418,935.011719 308.925659,935.011719 
	C308.925659,930.385437 308.925659,926.134888 308.879211,921.414917 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M345.264801,928.153320 
	C344.209045,928.593506 343.497803,928.953857 342.169281,929.626953 
	C342.295105,928.311096 342.147064,926.716858 342.516205,926.586487 
	C343.630402,926.192749 344.945557,926.367920 346.182343,926.321228 
	C345.991333,926.905334 345.800293,927.489380 345.264801,928.153320 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2077.060547,1214.122803 
	C2078.392334,1213.224854 2079.679688,1212.744751 2080.967041,1212.264771 
	C2081.327393,1213.928711 2081.927979,1215.594238 2081.917725,1217.255859 
	C2081.914062,1217.840332 2080.411621,1218.415283 2079.593506,1218.994629 
	C2078.734375,1217.510010 2077.875244,1216.025391 2077.060547,1214.122803 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2103.512207,1213.553101 
	C2102.553955,1215.567261 2101.461426,1217.228394 2100.368896,1218.889526 
	C2099.629395,1217.118530 2097.964844,1215.121948 2098.331299,1213.620728 
	C2099.273682,1209.757812 2101.439453,1210.609497 2103.512207,1213.553101 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M960.113892,96.932281 
	C965.891785,96.793343 966.452637,100.015877 965.650940,104.628204 
	C961.871338,104.762054 957.369019,107.044510 955.296631,102.001518 
	C954.052429,98.973846 956.563599,97.213196 960.113892,96.932281 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M748.628418,119.711288 
	C744.220764,115.907692 744.407043,112.476112 748.867493,111.214172 
	C752.239685,110.260094 755.638306,111.117920 756.609741,114.649132 
	C757.613281,118.296883 754.143921,119.115532 751.452454,119.907928 
	C750.702942,120.128601 749.815063,119.879333 748.628418,119.711288 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M745.006226,103.413177 
	C745.379395,102.606926 745.742371,102.201897 746.182739,101.532768 
	C747.589294,101.185585 749.093018,100.665062 750.210999,101.111168 
	C752.487488,102.019485 754.556030,103.449173 756.710205,104.664047 
	C755.466431,106.090599 754.267578,108.650085 752.969360,108.701500 
	C750.473450,108.800354 747.870789,107.604973 745.438110,106.631943 
	C744.987244,106.451614 745.128235,104.791534 745.006226,103.413177 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M536.009094,120.007927 
	C535.852173,119.555428 535.611145,119.107826 535.534485,118.633644 
	C535.205566,116.598602 534.924866,114.555748 534.626587,112.515747 
	C537.395447,112.825607 540.553101,112.414459 542.828064,113.640694 
	C545.282898,114.963921 544.138672,118.835037 541.216125,119.712997 
	C539.850281,120.123268 538.294250,119.900398 536.404541,119.974976 
	C535.984680,119.985458 536.000610,120.000595 536.009094,120.007927 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M249.075104,780.674805 
	C248.301651,781.094299 247.017822,781.119019 247.000916,781.278198 
	C246.570740,785.331848 243.542526,784.872375 240.012939,784.957153 
	C238.137878,785.006653 237.065186,785.046814 235.575623,785.008850 
	C233.490448,784.603394 230.750961,784.778748 230.323914,783.868835 
	C227.017914,776.824890 226.265808,782.832642 224.724838,784.811279 
	C224.724838,784.811279 224.512314,784.699219 224.393616,784.715576 
	C221.081848,783.479248 217.888779,782.226501 214.277557,780.515869 
	C213.932617,779.668152 214.005814,779.278320 214.079010,778.888489 
	C215.972458,778.888489 217.865891,778.888489 220.495010,778.888489 
	C219.447830,777.388855 218.803757,776.466431 217.871704,775.131653 
	C219.236908,775.041199 220.272232,774.972595 221.598633,775.001221 
	C222.057373,777.242798 222.189743,779.417542 225.670380,778.974976 
	C227.027878,778.837830 228.786926,778.956665 228.854324,778.673157 
	C230.224823,772.909302 234.925751,775.283630 238.342758,774.734131 
	C242.265106,774.103455 245.943008,774.496033 248.748291,778.296265 
	C249.152832,778.979248 249.153931,779.660889 249.075104,780.674805 
M240.262634,776.312866 
	C239.114227,776.307129 237.965103,776.325439 236.817520,776.291565 
	C231.608826,776.137878 231.291428,776.558716 232.961273,782.477112 
	C235.700668,780.470581 238.254028,778.600342 240.262634,776.312866 
M244.890793,780.621399 
	C244.954391,778.557373 244.486526,777.245117 242.138687,778.742371 
	C240.278198,779.928833 238.441803,781.153076 236.594696,782.360474 
	C236.765869,782.798584 236.937042,783.236633 237.108200,783.674683 
	C239.675812,782.927063 242.243408,782.179443 244.890793,780.621399 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M190.030716,795.576111 
	C184.642197,796.146362 179.274734,796.279663 173.482056,796.305298 
	C173.056808,794.738892 173.056808,793.280151 173.056808,791.009949 
	C178.190674,791.009949 183.055008,790.817444 187.874588,791.195435 
	C188.698349,791.260071 189.330902,793.762756 190.030716,795.576111 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M211.253571,791.125854 
	C211.695511,792.605774 211.695511,794.044861 211.695511,796.627258 
	C208.440399,795.740723 205.737793,795.004700 203.537628,794.405518 
	C200.219025,794.989563 197.063049,795.544983 193.483063,796.005859 
	C193.131042,794.457642 193.203049,793.003906 193.298096,791.084961 
	C199.318558,791.084961 205.065094,791.084961 211.253571,791.125854 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M224.914551,785.172607 
	C226.265808,782.832642 227.017914,776.824890 230.323914,783.868835 
	C230.750961,784.778748 233.490448,784.603394 235.576569,785.385376 
	C235.985764,786.839539 235.977127,787.839172 235.968491,788.838745 
	C234.159607,788.882690 232.328339,788.763306 230.546936,789.007690 
	C227.653702,789.404602 225.854507,788.360352 224.914551,785.172607 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M224.166595,792.922852 
	C224.182266,793.960510 224.090790,794.592224 223.944275,795.539185 
	C221.892624,795.923340 219.895996,795.992432 217.478790,795.975952 
	C217.058197,794.475952 217.058197,793.061462 217.058197,790.434204 
	C219.859482,791.267517 221.959473,791.892212 224.166595,792.922852 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M229.907333,791.835327 
	C229.632095,793.390747 229.141296,794.626221 228.650482,795.861633 
	C227.729080,795.275574 226.807693,794.689514 225.780273,793.740479 
	C227.013397,792.756775 228.352570,792.136108 229.907333,791.835327 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M236.260834,788.894287 
	C235.977127,787.839172 235.985764,786.839539 235.993439,785.463501 
	C237.065186,785.046814 238.137878,785.006653 239.607330,785.043457 
	C239.935730,785.420288 239.867325,785.720093 239.611938,786.444458 
	C238.794266,787.446838 238.163559,788.024658 237.334229,788.837402 
	C236.941437,789.031433 236.747314,788.990662 236.260834,788.894287 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M371.974213,107.350739 
	C372.321411,106.915466 372.595459,106.739586 372.899414,106.227089 
	C373.343842,105.933121 373.616608,105.756119 373.912476,105.237831 
	C374.349243,104.938263 374.620148,104.760925 374.917725,104.248413 
	C378.759430,105.888603 377.238068,109.414810 376.466522,111.674606 
	C376.050232,112.893936 372.345642,112.990585 369.217072,113.840195 
	C370.462189,111.071930 371.216370,109.395164 371.974213,107.350739 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1571.231445,877.268677 
	C1571.489746,877.135132 1571.682983,877.299683 1571.905762,877.698242 
	C1571.985107,877.873718 1571.505615,877.890930 1571.505615,877.890930 
	C1571.505615,877.890930 1571.216187,877.508179 1571.231445,877.268677 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1522.590332,649.838745 
	C1522.604614,650.369019 1522.618652,650.899353 1522.180176,651.774536 
	C1516.732788,652.119446 1511.738037,652.119446 1506.743286,652.119446 
	C1506.651123,652.606384 1506.559082,653.093323 1506.467041,653.580200 
	C1510.201660,655.245789 1513.936279,656.911377 1517.924561,658.690063 
	C1519.276367,658.141724 1520.948120,657.463562 1522.619873,656.785522 
	C1522.680420,657.636841 1522.740967,658.488098 1522.433350,659.726135 
	C1520.289917,660.890747 1518.514771,661.668640 1516.739624,662.446533 
	C1514.739502,660.508545 1512.180786,660.001343 1510.159668,658.675049 
	C1506.078491,655.996643 1502.195190,653.358643 1497.140747,656.201416 
	C1497.081421,656.234680 1496.897095,655.990112 1496.748901,655.928406 
	C1492.970703,654.354797 1486.317139,655.640503 1483.591553,658.611328 
	C1482.854736,659.414429 1481.932129,660.695007 1482.096313,661.568176 
	C1482.948975,666.104553 1477.256104,670.378906 1481.584717,675.015076 
	C1481.746338,675.188232 1481.382324,675.947815 1481.153564,676.375427 
	C1478.760620,680.847290 1480.713745,682.847717 1484.968506,683.737915 
	C1483.047241,688.316895 1485.637573,688.961060 1488.847168,689.214233 
	C1488.854858,690.239624 1488.988525,691.027283 1488.720703,691.863281 
	C1487.780029,691.944336 1487.240723,691.976990 1486.295654,692.052490 
	C1485.232178,692.668457 1484.010986,693.233093 1484.002197,693.815979 
	C1483.877563,702.077271 1483.917358,710.341064 1483.917358,718.419556 
	C1487.629150,719.458435 1490.562622,720.792542 1493.574951,721.004700 
	C1498.332153,721.339722 1500.995850,723.341431 1501.456177,728.119812 
	C1501.197876,728.271667 1500.939575,728.423584 1500.236450,728.320190 
	C1498.571533,726.695557 1497.565918,724.513733 1496.087036,724.123230 
	C1492.845825,723.267090 1489.364868,723.318604 1485.981812,722.999512 
	C1483.454224,721.151123 1480.926514,719.302734 1478.599609,716.924316 
	C1478.900635,707.792480 1479.081909,699.189819 1478.983032,690.590271 
	C1478.972534,689.674011 1477.334839,687.998657 1476.435059,687.987122 
	C1463.235596,687.817200 1450.033325,687.870239 1436.831665,687.870239 
	C1436.651245,688.282288 1436.470703,688.694336 1436.290283,689.106445 
	C1437.970093,690.103943 1439.788330,692.115051 1441.303833,691.908203 
	C1445.779907,691.297424 1448.998779,692.958313 1451.944092,696.235474 
	C1451.639282,697.032715 1451.307739,697.518066 1450.654785,697.878784 
	C1447.309570,693.934021 1443.490112,693.773682 1439.280762,695.747986 
	C1435.636353,689.179138 1430.260986,689.877075 1424.526611,692.010559 
	C1424.645264,691.444946 1424.763916,690.879333 1424.882690,690.313721 
	C1422.607056,689.860657 1420.331543,689.407593 1417.682373,688.964233 
	C1416.811890,688.935608 1416.315308,688.897217 1415.644287,688.583374 
	C1414.307861,688.554993 1413.145996,688.802002 1411.988770,689.018860 
	C1411.993652,688.988708 1412.053345,689.001831 1411.761230,689.050720 
	C1410.935303,689.343567 1410.401367,689.587585 1409.839966,689.722900 
	C1409.812378,689.614258 1410.015259,689.518738 1409.892944,689.149170 
	C1408.847290,689.038818 1407.924072,689.298035 1406.894043,689.759460 
	C1406.369507,689.994873 1405.951660,690.028076 1405.285889,689.831543 
	C1404.703247,690.252869 1404.368652,690.903931 1404.061768,691.554260 
	C1404.089600,691.553467 1404.144775,691.546143 1403.781372,691.657471 
	C1397.556763,695.847229 1397.329590,703.757385 1402.879883,710.505920 
	C1400.631592,714.890381 1400.631592,714.890381 1404.002441,717.221313 
	C1407.160156,719.688782 1409.738647,720.269470 1411.157227,715.488342 
	C1411.686157,713.971069 1412.048828,712.744507 1412.091553,712.600037 
	C1414.893555,712.092896 1417.043213,711.703857 1419.177002,711.639282 
	C1419.785034,713.332520 1420.409058,714.701233 1421.047241,716.444458 
	C1420.322632,716.874084 1419.559570,717.071777 1418.851074,716.950195 
	C1417.864258,716.780945 1416.931030,716.255493 1415.946167,716.150757 
	C1415.704834,716.125061 1414.969727,717.462097 1415.109985,717.629395 
	C1416.868896,719.728210 1416.879395,724.115784 1421.224731,722.931274 
	C1423.543823,722.299133 1425.678345,720.989380 1428.204834,719.987183 
	C1428.715698,720.023743 1428.917847,720.058655 1429.028198,720.435974 
	C1424.385742,725.655212 1420.470581,726.421021 1414.536377,723.963257 
	C1412.993896,723.324402 1411.129517,723.463013 1408.885620,723.177490 
	C1409.414429,725.856812 1409.722290,727.416260 1410.030029,728.975769 
	C1408.847046,729.267273 1407.664185,729.558777 1406.185791,729.364380 
	C1405.948608,727.232910 1406.006592,725.587341 1406.342041,723.726196 
	C1406.734497,717.959595 1402.395142,720.798645 1400.035889,719.580933 
	C1398.868042,714.666748 1396.688232,710.162354 1396.692871,705.660156 
	C1396.698364,700.274719 1394.201172,694.010864 1400.340088,689.368713 
	C1390.848267,688.830383 1381.978271,688.720886 1373.120850,689.006409 
	C1372.271851,689.033813 1370.839233,691.505310 1370.804810,692.870605 
	C1370.604370,700.826965 1370.696655,708.792236 1370.780151,716.753540 
	C1370.828003,721.315918 1372.746704,722.293701 1377.049072,720.423340 
	C1378.597168,719.750366 1380.441040,719.757507 1381.949463,719.708618 
	C1380.548584,721.571533 1379.347900,723.180054 1378.147339,724.788574 
	C1366.136719,725.421692 1366.193970,725.420471 1365.942383,713.464294 
	C1365.775635,705.537659 1365.501221,697.610901 1365.107544,689.692505 
	C1364.902466,685.566467 1366.137817,683.840515 1370.724976,683.849304 
	C1386.645142,683.879944 1402.565674,683.381958 1418.487305,683.196594 
	C1428.173584,683.083740 1437.862305,683.175781 1447.549927,683.175781 
	C1447.557739,683.692261 1447.565430,684.208740 1447.573120,684.725220 
	C1439.446411,684.725220 1431.319580,684.725220 1423.192749,684.725220 
	C1423.195557,685.063782 1423.198364,685.402283 1423.201050,685.740845 
	C1441.491699,685.740845 1459.782349,685.740845 1478.072998,685.740845 
	C1478.088623,685.550049 1478.104248,685.359192 1478.119751,685.168396 
	C1476.097534,685.049255 1474.075195,684.930115 1472.052979,684.810974 
	C1472.029541,684.520264 1472.006104,684.229553 1471.982544,683.938782 
	C1473.813965,683.648560 1475.645508,683.358276 1478.193970,682.954407 
	C1477.867554,679.696350 1477.725830,676.143799 1477.115601,672.673584 
	C1476.126465,667.048401 1481.432373,655.843750 1486.841797,653.890076 
	C1487.721436,653.315002 1487.962158,652.843872 1488.202881,652.372681 
	C1487.754639,652.337402 1487.306274,652.302124 1486.858032,652.266785 
	C1480.241455,652.182617 1473.625000,652.098389 1466.837402,651.698425 
	C1471.261475,651.057434 1475.852417,650.538147 1480.452148,650.443359 
	C1494.496338,650.153992 1508.544067,650.028198 1522.590332,649.838745 
M1497.795898,652.189514 
	C1497.863770,652.385864 1497.931763,652.582214 1497.999756,652.778503 
	C1498.062134,652.583130 1498.124512,652.387695 1497.795898,652.189514 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1375.019775,617.418335 
	C1378.752808,616.933838 1382.492065,616.894287 1386.617676,617.332275 
	C1387.010498,622.843628 1387.016846,627.877380 1386.783447,633.123901 
	C1385.595093,634.063110 1384.646362,634.789551 1383.697754,635.515991 
	C1381.594360,639.688049 1384.887451,641.003967 1389.213623,641.903259 
	C1395.750610,642.320740 1401.837891,642.671936 1407.970215,643.390076 
	C1406.703857,645.215088 1405.392822,646.673096 1403.606567,648.104553 
	C1395.550293,648.077942 1387.969116,648.077942 1380.388062,648.077942 
	C1380.391724,648.276733 1380.395508,648.475464 1380.399170,648.674255 
	C1388.310059,648.674255 1396.220825,648.674255 1404.523315,648.735718 
	C1407.026978,648.763062 1408.692017,649.151794 1407.646362,652.080200 
	C1407.180908,652.513550 1406.926514,652.772949 1406.671997,653.032349 
	C1398.049561,653.014221 1389.424072,652.856628 1380.806152,653.045654 
	C1377.287231,653.122803 1375.837524,652.317139 1375.836182,648.407349 
	C1375.832520,638.225952 1375.329590,628.044678 1375.019775,617.418335 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1294.021606,682.010925 
	C1293.578003,682.012695 1293.134277,682.014404 1292.358398,682.002686 
	C1293.567993,679.881042 1294.661255,676.970032 1296.733887,675.812073 
	C1303.487915,672.038696 1310.294067,671.248718 1317.708008,675.427002 
	C1321.107910,677.343079 1324.688965,678.162842 1327.960449,681.159119 
	C1330.759766,683.722900 1336.530273,683.717712 1340.967041,683.645813 
	C1346.383179,683.557983 1352.331665,686.611389 1352.881836,691.007385 
	C1353.458496,695.614624 1349.228271,701.050232 1344.419556,702.052307 
	C1341.098633,702.744324 1337.816650,703.622925 1333.871094,704.712769 
	C1332.456909,706.228638 1331.688721,707.449890 1330.828857,708.958130 
	C1330.345215,709.678955 1329.953125,710.112732 1329.237915,710.234497 
	C1328.698730,709.715027 1328.482666,709.507629 1328.457275,708.987854 
	C1328.539307,706.625977 1328.430664,704.576538 1328.309448,702.289307 
	C1329.417603,702.402283 1330.468872,702.509460 1332.011108,702.666748 
	C1331.913818,700.970398 1331.832031,699.544495 1331.849609,698.067139 
	C1331.948975,698.015686 1332.146118,697.915100 1332.358154,697.832947 
	C1332.838257,697.560486 1332.979004,697.301086 1332.994629,696.988403 
	C1332.996826,697.004150 1332.971069,696.984253 1333.247559,697.023560 
	C1333.709961,697.068115 1333.895996,697.073303 1334.038818,697.471252 
	C1334.988403,698.930542 1336.172729,701.025635 1336.940918,700.882690 
	C1340.017456,700.310425 1342.965088,699.043823 1346.183594,697.987671 
	C1346.406982,697.941406 1346.862183,697.911011 1347.063232,698.135254 
	C1348.096680,695.610352 1350.389526,692.167603 1349.477417,690.246826 
	C1347.085815,685.210571 1343.288086,684.854736 1336.829590,687.632324 
	C1335.883667,686.733521 1334.934692,685.838440 1333.798096,684.675903 
	C1331.196411,682.804626 1329.165283,682.716370 1327.557007,685.871338 
	C1326.478760,685.073364 1325.768555,684.259705 1325.103638,683.410461 
	C1323.973145,681.966431 1323.161011,679.751587 1321.701904,679.186707 
	C1317.727661,677.648254 1313.470947,676.852234 1309.352173,675.671570 
	C1303.771362,674.071716 1296.697144,676.960388 1294.021606,682.010925 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1456.973145,616.689209 
	C1467.429443,616.135742 1477.894165,615.952759 1488.826660,616.069946 
	C1487.928833,619.168884 1486.563232,621.967529 1484.777588,624.575806 
	C1480.413574,624.796997 1474.869385,621.621521 1474.029541,628.992371 
	C1471.732544,628.249512 1469.435425,627.506653 1467.019043,626.052673 
	C1465.633911,623.949646 1464.368042,622.557617 1463.077393,621.138367 
	C1459.855713,625.654236 1459.232544,625.982361 1456.355469,624.751953 
	C1457.923462,623.527222 1459.461060,622.326294 1460.998779,621.125305 
	C1460.815552,620.756897 1460.632324,620.388428 1460.449097,620.020020 
	C1459.291260,620.278442 1458.133423,620.536865 1456.975586,620.795227 
	C1456.977539,619.550049 1456.979370,618.304810 1456.973145,616.689209 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1420.818359,616.235291 
	C1422.249634,616.423950 1423.680786,616.612671 1425.470703,616.831970 
	C1425.920898,626.918701 1425.846802,636.980469 1426.187622,647.028198 
	C1426.320557,650.948853 1425.111694,652.500793 1421.189941,652.070618 
	C1419.070312,651.838135 1416.908447,651.990356 1414.389648,651.589966 
	C1414.994995,650.210388 1415.976318,649.209900 1417.220703,647.910645 
	C1419.608032,647.052673 1421.315430,646.526855 1421.205933,643.506897 
	C1420.877197,634.423584 1420.920410,625.326782 1420.818359,616.235291 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1317.020752,664.944397 
	C1316.407227,667.007812 1315.793579,669.071228 1315.178101,671.134094 
	C1313.263672,666.542786 1310.310669,665.330139 1304.881104,666.810669 
	C1304.110962,667.020630 1303.145752,667.128418 1302.437988,666.853333 
	C1298.183472,665.199463 1294.019653,664.108582 1289.742310,666.903809 
	C1289.206543,667.254028 1288.018799,666.606873 1285.858643,666.155518 
	C1288.182251,665.131775 1289.353516,664.615662 1290.852783,663.955078 
	C1289.119751,662.406006 1282.843628,662.271912 1288.954956,659.002686 
	C1289.997314,658.445129 1292.452148,660.528076 1293.575928,661.066345 
	C1298.793579,660.758240 1303.665527,660.574036 1308.513428,660.128601 
	C1310.232666,659.970642 1311.893188,659.176514 1314.100708,658.634277 
	C1313.401489,660.430908 1312.182251,662.266113 1310.139404,665.340637 
	C1313.334106,664.304688 1315.191895,663.702209 1317.051392,663.407104 
	C1317.042236,664.124451 1317.031494,664.534424 1317.020752,664.944397 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1439.749268,632.170532 
	C1441.389526,632.654602 1442.793579,633.379089 1444.548828,634.284851 
	C1446.393188,632.293457 1448.562622,630.248840 1452.067627,632.943359 
	C1452.897461,633.581116 1455.260986,632.223511 1458.282715,631.422241 
	C1456.229858,633.607422 1455.133179,634.774902 1453.646606,636.357361 
	C1458.443848,637.683044 1458.893311,634.800232 1459.222778,631.537598 
	C1461.223877,631.537598 1463.120361,631.537598 1465.036255,631.537598 
	C1464.788330,635.949463 1457.408569,640.098022 1453.038818,639.230530 
	C1449.534180,638.534729 1445.750854,639.373108 1442.143921,639.008423 
	C1440.262085,638.818115 1437.874146,637.980347 1436.802490,636.594177 
	C1434.489746,633.602661 1437.800781,633.330444 1439.749268,632.170532 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1450.926880,675.913940 
	C1450.396973,676.301575 1449.591064,677.140564 1449.377930,677.010193 
	C1445.076050,674.379944 1440.277344,678.860962 1435.736450,675.790649 
	C1433.816772,674.492615 1430.498535,675.262878 1427.418213,675.055176 
	C1427.020630,675.017273 1426.989868,674.989258 1426.989868,674.989258 
	C1426.558960,674.540710 1426.179932,674.022156 1425.686890,673.657776 
	C1424.595825,672.851379 1423.447754,672.122559 1422.322876,671.362061 
	C1423.097778,670.682739 1424.218872,669.278809 1424.585571,669.454041 
	C1425.925171,670.094116 1427.023804,671.248047 1428.187012,672.239258 
	C1428.420654,672.438232 1428.539307,672.772095 1428.634155,672.922119 
	C1435.648315,671.870850 1442.603882,670.828308 1450.917725,669.582275 
	C1448.574463,671.701660 1447.319336,672.836853 1445.306885,674.657043 
	C1447.168945,674.780212 1448.140259,674.844421 1449.722412,674.941406 
	C1450.531128,675.287415 1450.729004,675.600708 1450.926880,675.913940 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1492.885742,614.159607 
	C1494.207397,614.057373 1495.533936,613.826294 1496.849854,613.875854 
	C1501.963135,614.068359 1503.285645,610.840271 1503.404053,606.273315 
	C1504.910645,606.273315 1506.260620,606.273315 1507.034912,606.273315 
	C1506.379272,607.841614 1504.807861,609.850830 1505.297363,610.871643 
	C1505.911499,612.152222 1508.257690,613.392029 1509.783081,613.308777 
	C1516.339111,612.950928 1518.531250,618.643066 1522.357910,622.344604 
	C1522.049561,622.982117 1521.874512,623.137634 1521.502319,623.222412 
	C1521.305176,623.151672 1520.887329,623.119629 1520.887329,623.119629 
	C1518.337036,620.769653 1516.135620,617.341736 1513.138672,616.371399 
	C1510.114624,615.392395 1506.301147,616.851624 1502.833618,617.242615 
	C1502.790649,617.778076 1502.747681,618.313538 1502.704712,618.848999 
	C1501.707642,617.926025 1500.710571,617.003052 1500.404663,616.719849 
	C1497.820679,617.909180 1495.681030,618.894043 1493.196289,619.943115 
	C1492.851196,620.007385 1492.941528,620.093628 1492.982910,619.726074 
	C1493.013306,619.112183 1493.002563,618.865845 1493.032593,618.247803 
	C1493.010864,616.637268 1492.948364,615.398438 1492.885742,614.159607 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1271.845215,646.263794 
	C1272.107178,646.049377 1272.486084,646.061279 1272.675537,646.054810 
	C1274.198608,647.012634 1275.567383,648.835876 1276.859619,648.783264 
	C1281.057739,648.612244 1281.994385,650.638977 1281.656738,654.206726 
	C1281.517334,655.679565 1281.524414,657.182068 1281.631348,658.659058 
	C1281.835571,661.482300 1280.520874,662.495117 1277.809204,662.367798 
	C1275.030396,662.237488 1272.240723,662.338989 1268.865356,662.338989 
	C1268.910645,656.607178 1266.746216,651.062561 1271.845215,646.263794 
M1277.584839,652.314270 
	C1275.485962,652.136841 1272.839844,651.124939 1271.453491,652.034119 
	C1270.247070,652.825317 1270.463379,655.786377 1270.051025,657.788513 
	C1270.466553,657.907349 1270.882080,658.026123 1271.297729,658.144958 
	C1272.869873,656.749756 1274.442139,655.354553 1276.325562,654.674988 
	C1275.260864,656.377991 1274.196167,658.080933 1272.909790,660.138428 
	C1275.504639,660.138428 1277.512207,660.138428 1281.006714,660.138428 
	C1279.767700,657.240601 1278.869019,655.138794 1277.584839,652.314270 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1522.607422,649.585815 
	C1508.544067,650.028198 1494.496338,650.153992 1480.452148,650.443359 
	C1475.852417,650.538147 1471.261475,651.057434 1466.375244,651.660278 
	C1463.595093,652.365479 1461.106079,652.792969 1457.947510,653.335449 
	C1458.380981,650.130737 1458.680908,647.913452 1459.354492,645.341919 
	C1460.190796,645.119995 1460.653442,645.252441 1461.115967,645.384888 
	C1461.104248,645.954041 1461.092407,646.523254 1461.266113,647.762695 
	C1470.055542,648.632019 1478.659668,648.997498 1487.263428,648.989136 
	C1498.875977,648.977844 1510.488403,648.678955 1522.100830,648.503418 
	C1522.275391,648.779907 1522.449829,649.056458 1522.607422,649.585815 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1378.527100,724.897461 
	C1379.347900,723.180054 1380.548584,721.571533 1382.282959,719.520142 
	C1383.751953,718.810608 1384.716187,718.241028 1385.617065,718.328125 
	C1390.417236,718.791992 1395.201538,719.420288 1399.991333,719.991699 
	C1402.395142,720.798645 1406.734497,717.959595 1406.112305,723.398743 
	C1400.185913,719.653320 1395.568115,721.562073 1390.886841,725.252136 
	C1390.312134,725.141907 1390.074829,725.145203 1389.837524,725.148560 
	C1389.113525,724.842407 1388.375977,724.247192 1387.668091,724.280640 
	C1384.742676,724.418884 1381.826416,724.747559 1378.527100,724.897461 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1320.391846,732.018311 
	C1319.719482,731.485901 1319.456177,731.013611 1319.511230,730.265381 
	C1321.519897,729.957825 1323.209839,729.926208 1325.277466,729.932983 
	C1326.156250,729.917053 1326.657471,729.862610 1327.552246,729.848450 
	C1329.266479,729.892395 1330.587158,729.896118 1332.179932,729.880615 
	C1332.933228,729.613098 1333.414307,729.364746 1333.895508,729.116394 
	C1338.366699,729.805969 1342.975220,731.995422 1347.016846,727.882629 
	C1347.179199,727.717407 1347.766479,727.981323 1348.157837,728.027466 
	C1353.471313,728.653320 1358.785156,729.276062 1364.554199,729.971313 
	C1365.005981,730.358337 1365.022095,730.672546 1365.073730,731.218811 
	C1360.641602,731.721863 1356.193726,731.991821 1350.968750,732.112976 
	C1348.739990,732.125854 1347.288208,732.287476 1345.836304,732.449158 
	C1342.549561,732.334351 1339.262817,732.219482 1335.527832,732.148682 
	C1334.203247,732.207886 1333.327026,732.223083 1332.177734,732.260742 
	C1330.886475,732.254028 1329.868042,732.224976 1328.470459,732.252563 
	C1327.293213,732.277710 1326.495239,732.246155 1325.302734,732.287598 
	C1323.539062,732.266479 1322.170044,732.172485 1320.391846,732.018311 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1474.312500,629.277100 
	C1474.869385,621.621521 1480.413574,624.796997 1484.760254,624.860168 
	C1485.448120,626.212585 1485.733398,627.090332 1485.666504,627.989136 
	C1483.346069,630.065674 1481.377930,632.121155 1478.381714,635.250244 
	C1481.836914,635.547791 1483.724854,635.710388 1485.734375,635.883423 
	C1485.920410,633.115417 1486.061523,631.014771 1486.424683,628.908508 
	C1486.646606,628.902893 1487.082153,628.988647 1487.082153,628.988647 
	C1487.295776,631.542603 1487.509521,634.096558 1487.783325,637.369263 
	C1483.174805,637.369263 1479.222290,637.369263 1475.092285,637.369263 
	C1474.905884,634.439880 1474.750610,632.000854 1474.312500,629.277100 
M1480.963867,625.879028 
	C1475.970947,626.389893 1475.714233,626.910950 1479.314575,633.020996 
	C1480.753418,630.904419 1481.979492,629.215027 1483.017456,627.416931 
	C1483.136108,627.211365 1482.201904,626.398010 1480.963867,625.879028 
M1477.506104,633.000488 
	C1477.292969,632.696960 1477.079834,632.393494 1476.866699,632.089905 
	C1476.693604,632.308777 1476.334106,632.657776 1476.380127,632.723755 
	C1476.571655,632.998230 1476.885376,633.187256 1477.506104,633.000488 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1522.299805,648.100586 
	C1510.488403,648.678955 1498.875977,648.977844 1487.263428,648.989136 
	C1478.659668,648.997498 1470.055542,648.632019 1461.228760,648.044983 
	C1481.238159,647.408875 1501.470459,647.160889 1522.139648,646.857666 
	C1522.550537,647.100891 1522.524658,647.399292 1522.299805,648.100586 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1290.104004,635.026489 
	C1288.203247,634.127075 1286.677856,633.263123 1283.782471,631.623230 
	C1288.594116,630.594177 1290.194702,623.908325 1295.573853,628.832764 
	C1295.878418,629.111572 1296.971436,628.388611 1297.703735,628.369690 
	C1299.276855,628.329102 1300.853882,628.443970 1302.429321,628.495178 
	C1302.088867,629.964172 1302.206421,632.064331 1301.285156,632.733032 
	C1300.265747,633.472900 1298.282349,633.015320 1296.747681,632.833984 
	C1296.291504,632.780029 1295.933228,631.899475 1295.844849,631.789612 
	C1293.994507,632.918030 1292.236938,633.989990 1290.104004,635.026489 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1317.049805,663.099731 
	C1315.191895,663.702209 1313.334106,664.304688 1310.139404,665.340637 
	C1312.182251,662.266113 1313.401489,660.430908 1314.376709,658.380310 
	C1314.132568,647.675842 1314.132568,637.186890 1314.132568,626.697876 
	C1314.340820,626.562500 1314.549194,626.427063 1314.757446,626.291687 
	C1315.155029,627.842651 1315.850586,629.383972 1315.900879,630.946106 
	C1316.197876,640.185364 1316.344727,649.429565 1316.833130,659.342651 
	C1317.099365,661.042053 1317.074585,662.070923 1317.049805,663.099731 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1348.023926,630.700439 
	C1346.228394,630.261414 1344.844238,629.807068 1343.275024,629.291931 
	C1343.275024,627.526062 1343.275024,625.740356 1343.275024,623.256104 
	C1346.613647,623.454834 1349.883057,623.649536 1353.152588,623.844177 
	C1353.147339,623.916382 1353.142090,623.988647 1353.136841,624.060852 
	C1355.123901,624.060852 1357.110840,624.060852 1359.097900,624.060852 
	C1358.752441,625.668701 1358.711792,628.472290 1358.002441,628.653198 
	C1356.108032,629.136292 1353.926636,628.492676 1351.636841,628.275757 
	C1351.008545,628.748596 1349.721802,629.716858 1348.023926,630.700439 
M1346.027588,625.419617 
	C1345.498169,626.321411 1344.968628,627.223206 1344.439209,628.125000 
	C1344.792480,628.437805 1345.145752,628.750610 1345.499146,629.063416 
	C1347.038330,628.097229 1348.577515,627.131104 1350.116699,626.164917 
	C1349.957520,625.814453 1349.798340,625.463928 1349.639038,625.113464 
	C1348.702271,625.196472 1347.765503,625.279480 1346.027588,625.419617 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1451.270508,676.023315 
	C1450.729004,675.600708 1450.531128,675.287415 1450.086914,674.832397 
	C1452.198120,672.415833 1452.439453,667.087402 1457.761475,669.100647 
	C1459.559448,669.780762 1461.254883,670.732239 1463.331543,671.718445 
	C1464.149780,671.020569 1465.178467,670.143127 1466.807617,668.753723 
	C1466.807617,671.789612 1466.807617,673.992310 1466.807617,675.626221 
	C1464.368774,675.626221 1461.996094,675.626221 1459.123291,675.626221 
	C1458.425537,675.094543 1456.920898,673.948059 1456.464478,673.600403 
	C1454.142944,674.812378 1452.878540,675.472473 1451.270508,676.023315 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1416.957642,648.209412 
	C1415.976318,649.209900 1414.994995,650.210388 1413.920654,651.515747 
	C1413.109619,651.593872 1412.391479,651.367249 1411.040039,651.040527 
	C1409.557007,651.262329 1408.707275,651.584290 1407.857422,651.906250 
	C1408.692017,649.151794 1407.026978,648.763062 1404.517700,648.599854 
	C1404.120361,648.402466 1404.081787,648.131165 1404.081787,648.131165 
	C1405.392822,646.673096 1406.703857,645.215088 1407.975220,642.985229 
	C1407.293945,640.153381 1406.652344,638.093384 1406.315430,635.921753 
	C1407.127441,635.221985 1407.634644,634.633911 1408.141846,634.045898 
	C1408.546631,634.025024 1408.951538,634.004211 1409.727295,634.347107 
	C1410.357788,636.810974 1410.617188,638.911072 1410.650757,641.171021 
	C1410.449463,642.213745 1410.474121,643.096680 1410.563354,644.364014 
	C1412.737793,645.902039 1414.847656,647.055725 1416.957642,648.209412 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1411.938843,660.094666 
	C1417.339966,660.129456 1416.741333,662.216370 1416.763184,670.359558 
	C1416.776001,675.127380 1413.115601,673.878906 1410.633667,673.742676 
	C1408.227539,673.610657 1405.870117,672.590698 1403.723877,671.570068 
	C1404.973877,671.113525 1405.991211,671.047546 1407.221558,671.321594 
	C1409.673340,671.661560 1411.912231,671.661560 1414.250732,671.661560 
	C1414.250732,669.264587 1414.250732,667.178345 1414.250732,664.926941 
	C1412.164429,666.130310 1410.554077,667.059204 1408.934692,667.634460 
	C1409.951172,665.508301 1410.976562,663.735840 1411.968018,661.652100 
	C1411.935669,660.925537 1411.937256,660.510071 1411.938843,660.094666 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1333.949463,728.662842 
	C1333.414307,729.364746 1332.933228,729.613098 1332.234131,729.462769 
	C1331.671509,726.408020 1331.326904,723.751831 1330.980957,720.852844 
	C1330.979614,720.609985 1330.958618,720.124817 1331.281738,719.864990 
	C1331.282959,715.966370 1334.263306,712.120911 1330.920654,708.671204 
	C1331.688721,707.449890 1332.456909,706.228638 1333.587402,704.993652 
	C1333.949585,709.754150 1333.949585,714.528503 1333.949585,719.881836 
	C1338.058105,719.881836 1341.573975,719.881836 1345.089844,719.881836 
	C1345.117310,720.370605 1345.144897,720.859436 1345.172485,721.348206 
	C1341.699707,721.638489 1338.226807,721.928772 1334.309692,722.256226 
	C1334.202759,724.335327 1334.103149,726.272278 1333.949463,728.662842 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1317.051392,663.407104 
	C1317.074585,662.070923 1317.099365,661.042053 1317.165039,659.632324 
	C1320.594116,659.506409 1323.982178,659.761292 1327.370117,660.016113 
	C1327.362671,660.430481 1327.355103,660.844788 1327.347656,661.259155 
	C1328.395996,660.544128 1329.444458,659.829041 1331.430664,658.474304 
	C1331.239990,661.159424 1331.109863,662.991577 1330.979126,664.834106 
	C1329.992920,664.441589 1329.003052,663.644775 1328.229736,663.817566 
	C1326.155273,664.281128 1324.075439,665.768372 1322.644287,662.835449 
	C1320.863525,663.736023 1319.082886,664.636658 1317.161499,665.240845 
	C1317.031494,664.534424 1317.042236,664.124451 1317.051392,663.407104 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1461.572876,645.311707 
	C1460.653442,645.252441 1460.190796,645.119995 1459.364136,644.993774 
	C1460.761597,644.052490 1462.454346,642.490967 1464.298828,642.284363 
	C1468.224121,641.844666 1472.234009,642.225586 1476.197876,642.035522 
	C1478.172607,641.940796 1480.122437,641.328979 1482.416016,640.887024 
	C1483.453979,643.785217 1482.110107,644.969604 1478.900635,644.986633 
	C1473.276733,645.016479 1467.653320,645.148987 1461.572876,645.311707 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1487.183472,628.633667 
	C1487.082153,628.988647 1486.646606,628.902893 1486.375488,628.672607 
	C1486.104492,628.442322 1486.018799,627.968079 1486.018799,627.968079 
	C1485.733398,627.090332 1485.448120,626.212585 1485.180176,625.050476 
	C1486.563232,621.967529 1487.928833,619.168884 1489.301758,615.955566 
	C1489.399780,615.205994 1489.359619,614.887695 1489.136597,614.345093 
	C1490.098877,614.106079 1491.112793,614.107788 1492.506226,614.134583 
	C1492.948364,615.398438 1493.010864,616.637268 1492.696045,618.337646 
	C1492.526245,619.230713 1492.733887,619.662170 1492.941528,620.093628 
	C1492.941528,620.093628 1492.851196,620.007385 1492.793457,620.028931 
	C1493.238892,621.359070 1493.742310,622.667542 1494.629883,623.986084 
	C1495.014282,623.996033 1494.995117,624.001648 1494.995117,624.001648 
	C1490.332275,621.654785 1488.740479,624.844055 1487.183472,628.633667 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1456.734741,621.062439 
	C1458.133423,620.536865 1459.291260,620.278442 1460.449097,620.020020 
	C1460.632324,620.388428 1460.815552,620.756897 1460.998779,621.125305 
	C1459.461060,622.326294 1457.923462,623.527222 1456.355469,624.751953 
	C1459.232544,625.982361 1459.855713,625.654236 1463.077393,621.138367 
	C1464.368042,622.557617 1465.633911,623.949646 1466.955078,625.689331 
	C1464.682007,626.496277 1462.369629,627.163513 1460.019531,627.345703 
	C1458.277466,627.480835 1456.486572,626.985718 1454.717407,626.771301 
	C1455.309570,624.957397 1455.901733,623.143555 1456.734741,621.062439 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1505.807861,628.036987 
	C1506.328735,627.597595 1506.853271,627.162537 1507.369873,626.718079 
	C1508.568481,625.686707 1509.763306,624.650757 1510.959717,623.616699 
	C1511.987427,625.036621 1513.836792,626.483337 1513.791626,627.868164 
	C1513.753052,629.043762 1511.702759,630.609985 1510.226318,631.170105 
	C1508.241333,631.923340 1506.094971,631.672791 1506.126099,628.283325 
	C1505.902588,628.034912 1505.807861,628.036987 1505.807861,628.036987 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1432.047852,663.299622 
	C1429.681763,666.331116 1426.321533,666.460388 1423.040894,665.886536 
	C1422.476685,665.787903 1421.634888,662.630432 1422.217163,661.679382 
	C1423.159302,660.140869 1425.175903,659.260193 1426.738647,658.101685 
	C1427.093750,658.580872 1427.448853,659.060120 1427.803833,659.539368 
	C1426.519287,660.701172 1425.234741,661.862976 1423.950073,663.024780 
	C1424.283447,663.462646 1424.616699,663.900452 1424.949951,664.338318 
	C1426.513062,663.091614 1428.076172,661.844910 1429.969482,660.594360 
	C1430.882568,661.493591 1431.465210,662.396606 1432.047852,663.299622 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1432.423584,663.463623 
	C1431.465210,662.396606 1430.882568,661.493591 1430.197510,660.348389 
	C1431.907593,659.540649 1433.719727,658.975098 1435.829102,658.316895 
	C1436.140869,661.051453 1436.360962,662.981384 1436.667114,665.666748 
	C1435.128052,664.855286 1433.963745,664.241394 1432.423584,663.463623 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1297.013428,653.924622 
	C1295.451782,654.326721 1293.831909,654.580444 1292.341309,655.164062 
	C1289.822632,656.150330 1286.932251,656.833679 1286.204468,653.616272 
	C1285.389893,650.014526 1288.473145,648.911865 1291.880371,648.719482 
	C1290.762817,650.102295 1289.645264,651.485046 1288.527710,652.867798 
	C1288.745850,653.183655 1288.963867,653.499451 1289.181885,653.815308 
	C1290.585693,653.285217 1291.989380,652.755127 1294.011475,652.245728 
	C1295.424316,652.819214 1296.218872,653.371887 1297.013428,653.924622 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1297.367920,654.047485 
	C1296.218872,653.371887 1295.424316,652.819214 1294.355957,652.144714 
	C1293.640503,645.916992 1297.615845,648.231934 1300.577393,648.263855 
	C1300.850586,650.616455 1301.083496,652.622253 1301.355225,654.961182 
	C1300.135498,654.695679 1298.929077,654.432983 1297.367920,654.047485 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1411.724609,659.774292 
	C1411.937256,660.510071 1411.935669,660.925537 1411.568237,661.690430 
	C1410.132446,662.033142 1409.062378,662.026428 1407.905273,661.715454 
	C1407.436157,660.922729 1407.054199,660.434204 1406.672363,659.945679 
	C1406.702515,657.929504 1406.732788,655.913269 1406.717529,653.464722 
	C1406.926514,652.772949 1407.180908,652.513550 1407.646362,652.080200 
	C1408.707275,651.584290 1409.557007,651.262329 1410.750244,650.978027 
	C1411.232666,653.828369 1411.371460,656.641113 1411.724609,659.774292 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1236.592163,634.352661 
	C1234.750366,635.202271 1233.110474,635.726746 1231.470703,636.251282 
	C1231.233643,635.086426 1230.427246,633.558472 1230.886841,632.838013 
	C1231.664795,631.618713 1233.242676,630.909668 1234.484131,629.986084 
	C1235.254150,631.333252 1236.024170,632.680420 1236.592163,634.352661 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1520.937866,628.876038 
	C1519.613159,629.336121 1518.242188,630.223511 1516.982300,630.086609 
	C1516.290283,630.011475 1515.785645,628.210815 1515.196533,627.187988 
	C1516.858887,626.241272 1518.521240,625.294495 1520.505371,624.633301 
	C1520.827026,624.918884 1520.933350,625.129211 1520.974365,625.502563 
	C1520.989502,626.875977 1520.963745,627.875977 1520.937866,628.876038 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1406.269775,659.943054 
	C1407.054199,660.434204 1407.436157,660.922729 1407.683105,661.997620 
	C1404.204224,664.380249 1403.615967,666.465576 1406.842529,669.302856 
	C1406.862183,670.111389 1406.918945,670.572205 1406.984863,671.020569 
	C1406.993774,671.008179 1407.008545,670.981628 1407.008545,670.981628 
	C1405.991211,671.047546 1404.973877,671.113525 1403.582031,671.243774 
	C1403.115723,668.514160 1402.740845,665.686157 1403.070923,662.943115 
	C1403.202881,661.845947 1404.888672,660.935730 1406.269775,659.943054 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1460.207275,684.885864 
	C1456.866699,684.909241 1453.920288,684.909241 1450.973877,684.909241 
	C1450.967896,684.636108 1450.961792,684.362976 1450.955688,684.089844 
	C1454.713745,684.089844 1458.471680,684.089844 1462.229614,684.089844 
	C1462.262329,684.235168 1462.295044,684.380493 1462.327759,684.525818 
	C1461.752319,684.638062 1461.176880,684.750305 1460.207275,684.885864 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1427.037842,675.030396 
	C1426.100464,678.282410 1424.328491,677.828430 1421.226318,675.788208 
	C1423.526123,675.408142 1424.868530,675.186340 1426.600342,674.976929 
	C1426.989868,674.989258 1427.020630,675.017273 1427.037842,675.030396 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1521.226562,628.872314 
	C1520.963745,627.875977 1520.989502,626.875977 1521.243042,625.386597 
	C1521.546997,624.362549 1521.623291,623.827881 1521.699463,623.293152 
	C1521.874512,623.137634 1522.049561,622.982117 1522.530396,622.642212 
	C1523.210449,622.800232 1523.584595,623.142700 1523.965576,623.753418 
	C1523.574463,625.589294 1523.176636,627.156921 1522.611572,629.133911 
	C1522.134644,629.318420 1521.824951,629.093506 1521.226562,628.872314 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1389.780029,725.478760 
	C1390.074829,725.145203 1390.312134,725.141907 1390.838379,725.611755 
	C1391.164185,727.285400 1391.201172,728.485840 1391.238037,729.686279 
	C1390.678955,729.724426 1390.119873,729.762634 1389.141724,729.751343 
	C1389.056030,728.404236 1389.389404,727.106567 1389.780029,725.478760 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1501.288330,626.785645 
	C1502.558594,627.203308 1503.828857,627.621033 1505.453491,628.037842 
	C1505.807861,628.036987 1505.902588,628.034912 1505.887329,627.990112 
	C1504.578613,627.658936 1503.285156,627.372498 1501.655518,626.962769 
	C1501.319214,626.839417 1501.288330,626.785645 1501.288330,626.785645 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1486.900024,652.538269 
	C1487.306274,652.302124 1487.754639,652.337402 1488.202881,652.372681 
	C1487.962158,652.843872 1487.721436,653.315002 1487.205444,653.847046 
	C1486.934204,653.541870 1486.938110,653.175781 1486.900024,652.538269 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1499.071045,625.865601 
	C1498.952759,625.907593 1498.834595,625.949524 1498.876831,625.960571 
	C1499.037354,625.929626 1499.071045,625.865601 1499.071045,625.865601 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1501.347412,626.823120 
	C1500.693237,626.704773 1500.010864,626.602844 1499.199829,626.183228 
	C1499.071045,625.865601 1499.037354,625.929626 1499.007690,625.947632 
	C1499.525391,626.309998 1500.072876,626.654419 1500.954346,626.892212 
	C1501.288330,626.785645 1501.319214,626.839417 1501.347412,626.823120 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1521.502319,623.222412 
	C1521.623291,623.827881 1521.546997,624.362549 1521.202148,625.013245 
	C1520.933350,625.129211 1520.827026,624.918884 1520.840576,624.802246 
	C1520.864624,624.424683 1520.875122,624.163696 1520.886475,623.511230 
	C1520.887329,623.119629 1521.305176,623.151672 1521.502319,623.222412 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1495.035400,624.005554 
	C1495.056519,624.015076 1495.073608,624.176208 1495.073608,624.176208 
	C1495.073608,624.176208 1495.139038,623.939514 1495.067139,623.970581 
	C1494.995117,624.001648 1495.014282,623.996033 1495.035400,624.005554 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1434.731934,616.410034 
	C1434.883789,616.283203 1435.052979,616.175354 1435.194458,616.204224 
	C1435.334717,616.232910 1435.446899,616.399353 1435.571411,616.505371 
	C1435.445679,616.609863 1435.320068,616.714294 1434.957397,616.732422 
	C1434.720337,616.646179 1434.737305,616.488953 1434.731934,616.410034 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1627.708740,869.941101 
	C1627.804443,870.303040 1627.627197,870.592957 1627.176270,870.941956 
	C1626.875488,870.810425 1626.848267,870.619873 1626.846191,870.141235 
	C1627.059448,869.858459 1627.247681,869.863770 1627.708740,869.941101 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1628.980713,867.975220 
	C1628.517456,866.019714 1628.073242,864.087158 1627.629150,862.154602 
	C1628.070312,862.104553 1628.511353,862.054504 1628.952393,862.004456 
	C1628.952393,863.709839 1628.952393,865.415161 1628.955688,867.538818 
	C1628.958984,867.957153 1629.000000,867.998108 1628.980713,867.975220 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M547.701050,680.926880 
	C546.385559,682.154785 545.310303,677.756042 544.065308,681.691284 
	C543.983154,682.016479 543.903076,682.084717 543.903076,682.084717 
	C543.096375,681.433716 542.289673,680.782654 541.146912,679.594849 
	C540.416443,672.827515 540.022095,666.597046 539.509583,658.500732 
	C534.509338,661.822388 530.493469,664.490112 526.216248,667.331421 
	C527.823792,669.527527 528.672607,670.687073 529.647034,672.018311 
	C531.135437,670.961975 532.356750,670.095154 534.680481,668.445923 
	C534.355103,671.919189 534.139526,674.220581 533.937439,676.377869 
	C530.506592,675.898926 527.491028,675.477966 524.253540,675.026001 
	C523.923096,676.383850 523.496094,678.138672 522.716858,679.983521 
	C521.472717,681.364502 520.580750,682.655457 520.081604,683.377869 
	C522.515625,685.301270 524.258606,686.678650 526.001587,688.055969 
	C525.110046,689.373779 524.432190,691.515625 523.276672,691.815308 
	C521.562195,692.259888 519.430847,691.452087 521.126404,688.729126 
	C517.563232,688.344727 519.104553,681.909302 513.624390,682.794922 
	C510.943512,679.550964 509.345490,680.826416 507.835815,683.072754 
	C506.801849,683.031555 505.767883,682.990356 504.294067,682.553467 
	C502.581543,681.717590 501.316650,680.928406 500.034729,680.899658 
	C495.359711,680.794861 490.678986,680.944031 486.000427,680.997559 
	C485.928955,680.372681 485.857483,679.747864 486.205444,678.634766 
	C488.629333,677.395142 490.633759,676.643860 492.638184,675.892456 
	C492.086243,674.463196 491.534302,673.033875 490.790924,671.108826 
	C493.118866,671.108826 495.534607,671.108826 497.964661,671.420044 
	C497.980438,672.146606 497.981812,672.561890 497.634766,673.024780 
	C495.251678,674.564514 495.546570,675.515442 498.057648,676.268982 
	C498.442108,676.888245 498.729279,677.212708 499.016449,677.537170 
	C499.302765,676.556885 499.589081,675.576599 499.852325,674.427612 
	C499.829254,674.258972 499.887360,673.923523 500.101135,673.937378 
	C500.314941,673.951172 500.737183,674.024353 501.119690,674.047302 
	C504.399994,676.189819 506.997192,680.604675 510.631073,675.073242 
	C512.821411,676.347351 513.880188,680.337769 517.305481,677.416504 
	C518.192627,677.553223 518.884277,677.470642 519.575928,677.388000 
	C519.122314,676.947144 518.668701,676.506348 518.199463,675.691040 
	C518.172424,675.067017 518.160950,674.817383 518.298950,674.274475 
	C518.623413,672.644958 518.798523,671.308838 518.973633,669.972656 
	C519.453613,669.925110 519.933594,669.877625 520.804810,670.040527 
	C521.410828,670.124023 521.625732,669.997131 521.840576,669.870178 
	C522.984802,668.946472 524.466858,668.238281 525.216553,667.062927 
	C529.044556,661.061157 534.575134,657.840820 541.937195,656.653320 
	C541.937195,636.704163 541.937195,616.829224 542.326416,596.500488 
	C544.794067,597.083984 546.872559,598.121216 548.950989,599.158447 
	C548.631409,599.700378 548.029846,600.244202 548.033508,600.783875 
	C548.115601,613.026123 548.263977,625.267944 548.666382,638.158264 
	C549.004333,639.796265 549.075867,640.785828 549.147339,641.775391 
	C548.744751,643.793518 548.018311,645.807678 547.993652,647.830444 
	C547.861145,658.702698 547.903198,669.577148 547.701050,680.926880 
M543.962158,608.704651 
	C543.962158,614.615906 543.962158,620.527100 543.962158,626.438354 
	C544.610657,626.403809 545.259094,626.369202 545.907593,626.334656 
	C546.053833,617.943787 546.022461,609.558777 545.877441,601.177551 
	C545.244568,601.186096 544.611694,601.194641 543.978821,601.203125 
	C543.978821,603.394043 543.978821,605.585022 543.962158,608.704651 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M549.088257,561.894836 
	C549.065002,562.213074 549.041687,562.531311 548.711182,563.221375 
	C547.653564,564.406921 546.903137,565.220642 545.770691,565.969543 
	C544.361389,565.832153 543.334106,565.759521 542.306763,565.686890 
	C539.855103,564.130493 537.549255,562.231506 534.912659,561.109741 
	C532.028259,559.882629 531.173767,558.303162 532.314697,554.918518 
	C532.139709,553.972778 532.075256,553.614685 532.010864,553.256653 
	C534.615540,554.016602 536.059021,548.241272 539.700134,552.367432 
	C542.762207,553.897522 545.317139,555.040710 548.063416,556.507324 
	C548.532532,558.518799 548.810364,560.206848 549.088257,561.894836 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M542.165527,566.152344 
	C543.334106,565.759521 544.361389,565.832153 546.051636,566.128418 
	C547.645203,567.211426 548.575745,568.070801 549.506348,568.930176 
	C549.773499,568.826050 550.049805,568.786926 550.544983,568.820068 
	C550.403748,570.207886 550.052856,571.588501 549.540771,573.476807 
	C549.659485,574.068420 549.939514,574.152283 550.219482,574.236145 
	C554.252380,574.216675 558.285278,574.197205 562.683411,574.703735 
	C563.049561,575.459961 563.050537,575.690186 562.588074,575.937012 
	C557.288269,576.615295 551.454468,573.918640 548.045532,579.877197 
	C545.947327,581.740967 544.053345,583.801575 542.159363,585.862244 
	C541.679199,585.887573 541.199036,585.912903 540.372864,585.613525 
	C539.719421,583.847534 539.411926,582.406372 539.104492,580.965149 
	C537.685913,579.448242 536.267334,577.931335 533.883911,575.382751 
	C537.220642,576.526184 539.300903,577.239075 542.024292,578.172363 
	C542.024292,574.268250 542.024292,570.442993 542.165527,566.152344 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M507.866516,541.878662 
	C508.729401,540.235962 509.382599,537.370300 510.494476,537.179626 
	C513.840637,536.605835 518.754517,535.730896 520.454712,537.489380 
	C522.402039,539.503418 521.858948,544.230347 521.697021,547.746338 
	C521.660400,548.540466 518.789185,549.540222 517.114868,549.837585 
	C515.020264,550.209717 512.814148,549.953979 510.288513,549.295959 
	C509.236115,546.374695 508.551331,544.126648 507.866516,541.878662 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M464.091003,603.824280 
	C465.898285,602.146667 467.665039,600.422241 469.528168,598.809204 
	C470.631866,597.853699 471.794128,596.562500 473.095154,596.324341 
	C475.615570,595.863037 478.432159,596.566528 479.449005,593.027100 
	C479.570648,592.603577 481.685486,592.752563 483.772552,592.548706 
	C482.633240,594.109131 482.121674,594.809753 481.300964,595.933838 
	C487.791199,595.933838 493.872375,595.933838 500.421753,596.204285 
	C500.975464,596.877563 501.060913,597.280396 501.146393,597.683228 
	C494.719055,598.046204 488.291718,598.409241 481.613434,598.786377 
	C481.087433,600.047974 480.488159,601.485291 479.789062,603.162170 
	C476.617706,597.105164 474.511932,596.887878 469.571411,601.735901 
	C468.043060,603.235596 466.468811,604.688477 464.510895,605.859192 
	C464.101166,604.978516 464.096100,604.401367 464.091003,603.824280 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M485.319946,527.299805 
	C489.466187,530.502991 493.612457,533.706177 497.758698,536.909424 
	C497.682526,537.103149 497.606384,537.296875 497.530212,537.490601 
	C492.618347,535.464783 487.706482,533.438965 481.796356,531.001526 
	C478.012207,528.609009 473.194366,525.562988 468.376526,522.516968 
	C468.585815,522.111328 468.795105,521.705688 469.004395,521.300049 
	C473.411377,523.508606 477.818329,525.717224 482.768433,528.292480 
	C483.981018,528.205994 484.650482,527.752930 485.319946,527.299805 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M517.109985,677.197144 
	C513.880188,680.337769 512.821411,676.347351 510.631073,675.073242 
	C506.997192,680.604675 504.399994,676.189819 501.265350,673.786377 
	C503.371674,672.256836 505.714874,671.011108 508.058075,669.765320 
	C508.474945,669.874878 508.891815,669.984375 509.755859,670.478333 
	C511.501740,670.769836 512.800537,670.676880 514.099304,670.583862 
	C515.056946,670.389832 516.014648,670.195862 517.208862,670.380615 
	C516.094360,672.067871 514.743286,673.376343 513.392273,674.684814 
	C513.663879,675.084229 513.935486,675.483643 514.207092,675.883057 
	C515.521240,675.444641 516.835327,675.006226 518.149475,674.567810 
	C518.160950,674.817383 518.172424,675.067017 518.029480,675.893372 
	C517.619995,676.712463 517.364990,676.954773 517.109985,677.197144 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M501.523834,597.840454 
	C501.060913,597.280396 500.975464,596.877563 500.880981,596.165100 
	C503.309967,595.653809 505.747955,595.452209 508.926453,595.317993 
	C511.116974,595.108948 512.566956,594.832581 514.340576,594.701843 
	C516.060120,595.936279 517.456116,597.025146 518.755127,598.404358 
	C518.445129,599.475220 518.231995,600.255737 517.692444,600.861450 
	C514.265198,599.039673 512.866882,600.153625 512.833801,603.481506 
	C511.696259,602.836304 510.558746,602.191040 509.886597,601.160828 
	C510.770355,600.660156 511.188782,600.544556 511.607178,600.428955 
	C511.504639,600.174255 511.402130,599.919556 511.299591,599.664856 
	C510.762024,600.040100 510.224487,600.415405 509.686951,600.790710 
	C507.091705,599.859741 504.496490,598.928711 501.523834,597.840454 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M497.295380,657.551819 
	C500.873169,657.647217 504.450928,657.742615 508.480316,657.845093 
	C509.955017,660.118713 513.227417,662.160278 509.129700,664.637329 
	C506.784271,664.149597 504.931030,663.655212 503.109436,663.752563 
	C501.455017,663.841003 499.835022,664.573242 498.199829,665.021606 
	C498.050781,664.613159 497.901733,664.204651 497.752716,663.796143 
	C499.015991,662.719421 500.279297,661.642639 502.165466,660.034973 
	C500.332092,660.117493 499.259369,660.165833 498.044006,659.886780 
	C497.699371,658.890259 497.497375,658.221008 497.295380,657.551819 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M453.687439,598.790283 
	C453.731598,598.518372 453.775787,598.246399 453.873932,597.564758 
	C456.039276,599.080078 458.112030,601.052307 460.320557,602.858521 
	C460.545929,603.042847 461.586121,602.230774 462.595337,602.166016 
	C461.975128,604.949524 461.007690,607.448059 460.040283,609.946533 
	C456.384033,610.231812 452.727722,610.517090 448.717834,610.978760 
	C448.393799,610.124695 448.423340,609.094177 449.243134,608.109741 
	C452.695740,608.191223 455.358063,608.226685 458.416382,608.282349 
	C462.366394,606.140503 459.236206,605.267334 457.863342,603.735596 
	C456.387817,601.890137 455.037628,600.340210 453.687439,598.790283 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M538.804993,581.272705 
	C539.411926,582.406372 539.719421,583.847534 540.013428,585.643188 
	C532.237549,581.757629 523.619629,585.581421 515.563110,582.398010 
	C523.210571,582.125427 530.858093,581.852844 538.804993,581.272705 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M498.186646,660.214111 
	C499.259369,660.165833 500.332092,660.117493 502.165466,660.034973 
	C500.279297,661.642639 499.015991,662.719421 497.752716,663.796143 
	C497.901733,664.204651 498.050781,664.613159 498.199829,665.021606 
	C499.835022,664.573242 501.455017,663.841003 503.109436,663.752563 
	C504.931030,663.655212 506.784271,664.149597 508.759399,664.666138 
	C508.477875,664.983704 508.060669,665.021423 507.048737,665.050110 
	C506.274536,665.351685 506.095062,665.662292 505.915588,665.972900 
	C505.915588,665.972900 505.745911,666.233643 505.517761,666.302490 
	C504.929596,666.173401 504.569550,665.975525 504.070709,665.555664 
	C503.702759,665.349243 503.473602,665.364746 503.244507,665.380249 
	C503.334351,665.721497 503.424194,666.062683 503.207672,666.377441 
	C501.581696,666.957397 500.262085,667.563904 498.598083,668.118347 
	C494.548248,665.480408 494.916016,662.859680 498.186646,660.214111 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M510.870300,526.095337 
	C511.239258,526.791077 511.608185,527.486816 511.960327,528.506042 
	C509.101135,529.251770 506.258759,529.674011 503.551422,530.076233 
	C502.835175,527.802734 502.275452,526.026123 501.080505,524.081726 
	C500.011230,523.413330 499.577118,522.912598 499.143036,522.411926 
	C501.849701,522.362549 504.556396,522.313110 507.640869,522.392456 
	C508.252777,523.296326 508.486908,524.071411 508.839844,525.233826 
	C508.790955,526.114014 508.623230,526.606934 508.455505,527.099792 
	C509.260437,526.764954 510.065369,526.430176 510.870300,526.095337 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M531.785645,552.956055 
	C532.075256,553.614685 532.139709,553.972778 532.146179,554.602051 
	C530.165771,555.437561 528.243286,556.001831 526.459351,556.525452 
	C525.894592,554.754272 525.060486,552.138306 524.153137,549.292664 
	C525.494507,549.704102 526.976807,550.158752 528.318604,550.570312 
	C529.399048,548.733215 530.445435,546.953918 532.200256,543.970093 
	C531.917236,547.812073 531.738831,550.233765 531.785645,552.956055 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M500.999146,543.922852 
	C495.227570,544.081055 489.455994,544.239258 483.321167,543.919922 
	C482.937256,543.100281 482.916565,542.758362 482.895905,542.416382 
	C488.554901,542.243225 494.213928,542.070007 500.319031,542.216248 
	C500.843109,542.998047 500.921112,543.460388 500.999146,543.922852 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M498.700745,522.294617 
	C499.577118,522.912598 500.011230,523.413330 500.728790,523.960876 
	C500.253876,525.020874 499.620667,526.758118 498.714233,526.914856 
	C495.677765,527.439819 492.543640,527.400269 489.446716,527.572144 
	C490.251556,522.285095 493.690308,521.308167 498.700745,522.294617 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M542.161377,586.256958 
	C544.053345,583.801575 545.947327,581.740967 547.912354,580.200134 
	C547.969849,582.856323 547.956360,584.992676 547.960144,587.369385 
	C547.977417,587.609680 548.047363,588.086426 547.809326,588.361938 
	C548.010681,591.086426 548.450134,593.535461 548.546448,595.945190 
	C546.243958,593.869568 544.284668,591.833252 542.325317,589.796875 
	C542.271301,588.748474 542.217285,587.700134 542.161377,586.256958 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M547.872192,556.183838 
	C545.317139,555.040710 542.762207,553.897522 540.005371,552.457153 
	C540.404785,551.935242 541.005981,551.710632 541.812134,551.233276 
	C542.506348,549.057861 542.995667,547.135254 543.601929,544.752747 
	C545.005798,545.510864 546.196289,546.153809 547.685181,547.393921 
	C547.946533,550.722046 547.909363,553.452942 547.872192,556.183838 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M498.942444,668.170349 
	C500.262085,667.563904 501.581696,666.957397 503.389954,666.230164 
	C503.878571,666.109436 504.209534,665.777588 504.209534,665.777588 
	C504.569550,665.975525 504.929596,666.173401 505.474548,666.451904 
	C506.402283,667.390625 507.145050,668.248718 507.972961,669.436096 
	C505.714874,671.011108 503.371674,672.256836 500.882812,673.763428 
	C500.737183,674.024353 500.314941,673.951172 499.961731,673.689941 
	C499.066742,673.278137 498.524994,673.127686 497.983215,672.977173 
	C497.981812,672.561890 497.980438,672.146606 498.226440,671.214844 
	C498.630035,669.855713 498.786224,669.013000 498.942444,668.170349 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M518.852051,598.113953 
	C517.456116,597.025146 516.060120,595.936279 514.357910,594.309326 
	C514.000671,592.683655 513.949707,591.596130 513.898804,590.508667 
	C515.372559,590.409180 516.846252,590.309692 518.661316,590.686401 
	C518.952454,593.479736 518.902283,595.796814 518.852051,598.113953 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M518.755127,598.404358 
	C518.902283,595.796814 518.952454,593.479736 519.021545,590.791138 
	C520.085205,591.414185 521.768433,592.263306 522.035339,593.435059 
	C522.520447,595.564270 522.163452,597.885193 521.667114,600.510071 
	C520.104858,600.920959 519.033447,600.947937 517.977905,600.988464 
	C517.993896,601.002075 518.018860,601.036194 518.018860,601.036194 
	C518.231995,600.255737 518.445129,599.475220 518.755127,598.404358 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M542.180786,590.224609 
	C544.284668,591.833252 546.243958,593.869568 548.801147,596.071350 
	C549.701782,596.900696 550.004700,597.564636 550.108459,598.486816 
	C549.756042,598.899231 549.602722,599.053284 549.449402,599.207397 
	C549.449402,599.207397 549.191772,599.264587 549.071411,599.211548 
	C546.872559,598.121216 544.794067,597.083984 542.336548,596.033081 
	C541.983765,594.230347 542.010071,592.441345 542.180786,590.224609 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M501.401428,544.052246 
	C500.921112,543.460388 500.843109,542.998047 500.772583,542.183716 
	C502.893982,541.806396 505.007935,541.781006 507.494202,541.817139 
	C508.551331,544.126648 509.236115,546.374695 509.942474,549.072144 
	C508.938507,545.152100 505.667847,544.213440 501.401428,544.052246 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M482.550354,542.352722 
	C482.916565,542.758362 482.937256,543.100281 482.982239,543.698486 
	C482.604218,544.011719 482.201843,544.068726 481.056610,544.063965 
	C479.435150,544.076050 478.556549,544.149841 477.677979,544.223572 
	C475.881104,544.307007 474.084198,544.390503 471.717957,544.316895 
	C471.009399,544.015747 470.870239,543.871521 470.731079,543.727295 
	C472.895935,543.225586 475.060791,542.723877 477.974823,542.131470 
	C479.421478,542.055359 480.118958,542.069946 480.816406,542.084534 
	C481.279205,542.152710 481.742035,542.220886 482.550354,542.352722 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M513.478516,590.473389 
	C513.949707,591.596130 514.000671,592.683655 514.034302,594.163696 
	C512.566956,594.832581 511.116974,595.108948 509.354370,595.209106 
	C508.720184,592.049744 509.424652,589.963684 513.478516,590.473389 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M513.260864,603.654785 
	C512.866882,600.153625 514.265198,599.039673 517.692444,600.861450 
	C518.018860,601.036194 517.993896,601.002075 518.105835,601.241516 
	C518.523743,602.158508 518.829651,602.835999 519.135559,603.513550 
	C517.319641,603.618408 515.503784,603.723267 513.260864,603.654785 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M518.298950,674.274475 
	C516.835327,675.006226 515.521240,675.444641 514.207092,675.883057 
	C513.935486,675.483643 513.663879,675.084229 513.392273,674.684814 
	C514.743286,673.376343 516.094360,672.067871 517.575439,670.458679 
	C517.915649,670.117920 518.125732,670.077881 518.654724,670.005249 
	C518.798523,671.308838 518.623413,672.644958 518.298950,674.274475 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M460.037781,609.966309 
	C461.007690,607.448059 461.975128,604.949524 462.893097,602.016113 
	C463.242035,602.076660 463.640381,602.572205 464.064880,603.446045 
	C464.096100,604.401367 464.101166,604.978516 464.189758,605.982178 
	C464.367126,606.661621 464.391724,606.921021 464.205688,607.838623 
	C464.068787,609.738159 464.073212,610.986023 464.077637,612.233826 
	C463.580933,612.446472 463.084229,612.659119 462.251587,612.210938 
	C461.298889,611.021362 460.682159,610.492554 460.065399,609.963806 
	C460.065399,609.963806 460.035309,609.985962 460.037781,609.966309 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M480.877625,541.760071 
	C480.118958,542.069946 479.421478,542.055359 478.375153,542.027588 
	C479.174530,540.678833 480.322784,539.343201 481.471039,538.007629 
	C481.856537,538.386353 482.242035,538.765076 482.627533,539.143799 
	C482.064636,539.907715 481.501709,540.671692 480.877625,541.760071 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M464.369873,612.083008 
	C464.073212,610.986023 464.068787,609.738159 464.136108,608.023926 
	C465.390808,608.908630 468.697449,610.039246 464.369873,612.083008 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M513.947266,670.226807 
	C512.800537,670.676880 511.501740,670.769836 510.063538,670.577515 
	C511.214447,670.151428 512.504822,670.010620 513.947266,670.226807 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M477.764221,544.557800 
	C478.556549,544.149841 479.435150,544.076050 480.751465,544.070068 
	C480.540253,545.440857 479.891296,546.743835 478.898712,548.736755 
	C478.464020,547.142456 478.157227,546.017212 477.764221,544.557800 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M519.465332,603.580811 
	C518.829651,602.835999 518.523743,602.158508 518.089905,601.227905 
	C519.033447,600.947937 520.104858,600.920959 521.573730,600.953613 
	C521.245789,601.891541 520.520447,602.769775 519.465332,603.580811 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M509.709381,600.767273 
	C510.224487,600.415405 510.762024,600.040100 511.299591,599.664856 
	C511.402130,599.919556 511.504639,600.174255 511.607178,600.428955 
	C511.188782,600.544556 510.770355,600.660156 510.007050,600.780762 
	C509.662140,600.785767 509.731812,600.743835 509.709381,600.767273 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M460.137390,610.269653 
	C460.682159,610.492554 461.298889,611.021362 461.924805,611.895325 
	C461.359131,611.685547 460.784241,611.130554 460.137390,610.269653 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M484.985535,527.279053 
	C484.650482,527.752930 483.981018,528.205994 483.092255,528.422485 
	C483.465668,527.876648 484.058380,527.567505 484.985535,527.279053 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M463.199890,600.391418 
	C462.977478,599.776123 463.157501,599.157471 463.562347,598.312256 
	C463.725555,598.853149 463.663910,599.620605 463.199890,600.391418 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M471.342590,544.389587 
	C471.380676,544.888672 471.175293,545.112671 470.722900,545.405823 
	C470.390259,545.389465 470.255157,545.258606 469.921448,544.926575 
	C469.801331,544.385193 469.984344,544.115906 470.501465,543.822510 
	C470.870239,543.871521 471.009399,544.015747 471.342590,544.389587 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M506.145447,665.889221 
	C506.095062,665.662292 506.274536,665.351685 506.734497,665.065247 
	C506.887939,665.405090 506.674683,665.643799 506.145447,665.889221 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M521.664490,669.876404 
	C521.625732,669.997131 521.410828,670.124023 521.166016,670.063843 
	C521.136108,669.876709 521.488403,669.882629 521.664490,669.876404 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M483.674561,504.149353 
	C484.714020,502.870148 485.819916,501.596313 486.954651,500.289307 
	C487.959595,503.373657 487.959595,503.373657 484.226807,504.155090 
	C483.824249,504.258881 483.741028,504.154694 483.674561,504.149353 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M473.403015,512.573853 
	C473.340881,512.483337 473.576874,512.439941 473.576874,512.439941 
	C473.576874,512.439941 473.465149,512.664429 473.403015,512.573853 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M510.893616,526.072937 
	C510.065369,526.430176 509.260437,526.764954 508.455505,527.099792 
	C508.623230,526.606934 508.790955,526.114014 509.118011,525.401489 
	C509.277344,525.181885 509.521698,525.197815 509.809418,525.348633 
	C510.375763,525.703796 510.654358,525.908081 510.932983,526.112427 
	C510.932983,526.112427 510.916931,526.050537 510.893616,526.072937 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M515.411560,523.823608 
	C515.438965,523.797668 515.384155,523.849426 515.411560,523.823608 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M510.843109,525.839966 
	C510.654358,525.908081 510.375763,525.703796 509.922852,525.302551 
	C510.083496,525.259583 510.418365,525.413513 510.843109,525.839966 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M685.053040,711.796387 
	C684.403625,711.927124 683.770020,711.842407 682.834534,711.669617 
	C682.532654,711.581482 682.383301,711.617981 682.278931,711.326294 
	C680.157532,708.239075 678.082214,708.275757 675.953613,710.906616 
	C672.395752,713.658691 668.837891,716.410889 664.917236,719.443665 
	C661.715942,716.743530 658.266296,713.833923 654.387512,710.514648 
	C651.670776,705.837402 647.580688,707.215088 644.025574,706.714844 
	C644.030273,706.497620 644.032104,706.063049 644.477417,706.027466 
	C648.841797,705.553101 652.760864,705.114380 655.619019,704.794434 
	C657.820923,704.941772 659.686707,705.625916 660.341125,705.014160 
	C663.250000,702.294739 665.693176,701.518066 668.655884,704.979370 
	C669.327148,705.763672 671.270203,705.459473 672.803284,705.681702 
	C671.615906,703.269165 670.806335,701.624084 669.996704,699.979004 
	C671.566895,701.108154 673.137085,702.237305 675.195190,703.778442 
	C677.291016,704.526550 678.898987,704.862671 680.659241,705.230591 
	C681.115051,697.712341 681.528198,690.898254 681.877502,684.527222 
	C682.048706,689.274597 682.407898,693.576416 682.491516,697.883545 
	C682.640503,705.561218 682.571716,705.579651 690.142944,705.208496 
	C691.087830,705.162170 692.018250,704.820496 693.024170,704.600586 
	C693.196350,701.922119 693.355347,699.448669 693.514343,696.975159 
	C694.015503,700.709900 694.516724,704.444702 695.010559,708.603271 
	C694.531921,709.397034 694.060669,709.767090 693.288452,709.749023 
	C692.371582,705.236877 689.120728,707.170166 687.042419,707.129089 
	C686.447876,707.117371 685.801208,709.745483 685.178467,711.163147 
	C685.178406,711.163147 685.068848,711.580994 685.053040,711.796387 
M656.845520,707.442139 
	C656.441956,707.472839 656.038391,707.503540 655.634827,707.534302 
	C656.041748,707.742188 656.448608,707.950073 656.944092,708.798157 
	C657.911926,709.907410 658.879761,711.016663 660.168579,712.719849 
	C661.366882,714.509216 662.565186,716.298645 664.127747,718.631958 
	C668.362732,714.160828 671.290100,711.070129 674.217529,707.979431 
	C671.729614,708.356750 666.601440,703.406799 667.984924,710.546509 
	C668.029358,710.775696 666.641968,711.544128 665.880249,711.615479 
	C664.073181,711.784790 662.240967,711.685974 660.348450,711.135315 
	C661.753662,710.020508 663.158875,708.905701 665.743591,706.855225 
	C661.860352,707.305481 659.489502,707.580322 656.845520,707.442139 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M669.999878,699.998169 
	C670.806335,701.624084 671.615906,703.269165 672.803284,705.681702 
	C671.270203,705.459473 669.327148,705.763672 668.655884,704.979370 
	C665.693176,701.518066 663.250000,702.294739 660.341125,705.014160 
	C659.686707,705.625916 657.820923,704.941772 655.619019,704.794434 
	C652.760864,705.114380 648.841797,705.553101 644.009888,705.966675 
	C637.756470,705.911987 632.415894,705.882507 627.094543,705.493408 
	C627.882751,703.640442 628.651672,702.146973 629.420593,700.653564 
	C630.204712,699.209778 630.988831,697.765991 631.656738,696.536255 
	C634.563354,696.176147 637.556274,695.805420 640.852661,695.212158 
	C641.785889,694.246277 642.344177,693.420959 643.070190,692.788025 
	C643.560974,692.360229 644.287781,692.203125 645.114563,692.487244 
	C646.290649,695.669739 646.920288,700.302979 648.294983,700.535645 
	C652.556091,701.257202 657.100464,700.305420 661.534912,700.003052 
	C661.656006,699.634644 661.777100,699.266174 662.336243,698.623657 
	C665.196594,698.901306 667.618958,699.453125 670.041260,700.004883 
	C670.041260,700.004883 670.003052,700.017395 669.999878,699.998169 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M617.648926,659.714661 
	C618.165833,658.949829 619.204224,657.444214 619.840881,657.598267 
	C625.293152,658.917664 628.105957,656.130615 630.377380,651.717957 
	C631.551941,649.436279 633.139648,646.164001 635.078003,645.728088 
	C639.182495,644.804932 644.069580,644.347290 647.811951,645.808472 
	C650.660767,646.920837 652.040100,651.462830 654.433472,654.159119 
	C657.510315,657.625488 655.998718,660.491699 653.862427,663.472229 
	C653.668701,663.742615 653.516846,664.042725 653.324951,664.314514 
	C651.630798,666.713562 650.349548,670.553162 648.143555,671.190979 
	C644.141174,672.348328 639.578735,671.758362 635.270691,671.522461 
	C634.313354,671.470032 633.254456,669.984314 632.567444,668.943726 
	C630.943909,666.484985 629.515991,663.897095 627.604797,660.681458 
	C626.973511,662.627991 626.593567,663.799622 625.935669,665.828308 
	C624.882812,661.004883 623.650818,656.883667 617.648926,659.714661 
M640.749268,670.041565 
	C641.850159,670.009705 642.951111,669.977844 644.829773,669.937805 
	C650.797607,669.405823 650.087830,663.927612 653.118896,659.936035 
	C644.809021,659.936035 637.673157,659.936035 629.237793,659.936035 
	C633.408081,663.609863 632.399170,670.845459 640.749268,670.041565 
M649.100098,649.162659 
	C645.320068,644.774536 639.934937,646.546692 636.182739,647.633057 
	C633.278015,648.474182 631.621643,653.626526 629.027771,657.421997 
	C637.956360,657.421997 645.453430,657.421997 653.713501,657.421997 
	C652.036316,654.415039 650.761719,652.129883 649.100098,649.162659 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M613.198853,664.056030 
	C613.292236,666.135986 613.380920,667.763367 613.481445,669.608032 
	C615.882996,669.831177 617.969055,670.025024 619.926514,670.206909 
	C620.203552,670.693298 620.463196,670.963196 620.515991,671.268799 
	C622.004333,679.886841 621.601257,680.378052 613.087036,680.402527 
	C612.088318,680.405396 611.089600,680.406799 610.090881,680.408875 
	C604.537964,680.420471 601.761597,676.505432 604.418335,671.607971 
	C605.187500,670.190002 608.276062,670.030273 611.440430,668.879578 
	C611.091553,665.838684 610.660828,662.084229 610.185242,657.939209 
	C613.797607,656.269958 613.797607,656.269958 613.198853,664.056030 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M590.009583,535.011353 
	C592.307312,535.053589 594.197693,534.999329 596.070801,535.153809 
	C598.690369,535.369873 601.297119,535.741516 603.909424,536.046631 
	C603.936768,536.591003 603.964111,537.135376 603.991455,537.679810 
	C604.747986,536.774109 605.504456,535.868408 607.269714,533.755066 
	C607.560059,537.145020 607.735657,539.195190 607.988464,542.146851 
	C598.179688,542.146851 588.982361,542.146851 579.711182,542.146851 
	C578.222961,535.604004 579.041016,534.677429 585.349976,535.679443 
	C586.695374,535.893066 588.176086,535.254089 590.009583,535.011353 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M631.998718,552.061890 
	C625.246338,552.061890 618.493896,552.061890 611.809509,552.061890 
	C613.064819,550.747437 614.402588,549.346558 616.215332,547.448364 
	C614.264099,547.115234 612.969971,546.894348 611.675903,546.673401 
	C611.613342,546.225891 611.550842,545.778381 611.488342,545.330872 
	C613.175537,545.087341 615.559143,544.032227 616.410461,544.762756 
	C618.416870,546.484558 619.679993,545.836426 621.848083,545.233948 
	C625.066284,544.339600 628.634888,544.530884 632.050171,544.575439 
	C634.888428,544.612366 637.720764,545.104126 642.637756,545.607544 
	C640.033630,546.698181 638.284241,547.430847 636.534790,548.163513 
	C636.913940,548.760925 637.293030,549.358337 637.672119,549.955688 
	C639.188782,549.556213 640.705383,549.156738 642.525635,548.972778 
	C642.563782,549.813354 642.298401,550.438477 641.816650,551.251038 
	C641.093384,551.702148 640.586548,551.965759 639.670410,552.193726 
	C636.840332,552.125977 634.419556,552.093933 631.998718,552.061890 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M675.870972,552.204346 
	C676.612793,551.968811 677.061279,551.974426 678.115295,552.320190 
	C678.841858,565.223511 678.959961,577.786621 679.086792,590.349670 
	C679.111450,592.794434 678.497803,595.589783 682.041748,596.507080 
	C680.845947,597.480652 679.783142,598.257690 678.596558,598.710449 
	C677.100586,599.281067 675.503601,599.586975 673.950195,600.007080 
	C677.322510,591.166931 675.705383,581.947998 675.906433,572.884277 
	C676.057373,566.077148 675.705627,559.258850 675.870972,552.204346 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M631.934814,557.439087 
	C631.877319,556.867065 631.819885,556.295044 632.269714,555.332153 
	C635.177124,554.309143 637.577209,553.677002 640.384155,553.113525 
	C641.703430,552.806396 642.615845,552.430603 643.656616,552.225342 
	C644.125793,552.507141 644.355347,552.393860 644.387573,552.524658 
	C644.542847,556.729248 644.932556,560.466064 644.909607,564.200256 
	C644.904602,565.020264 643.466370,566.476929 642.629272,566.521057 
	C640.435852,566.636414 638.209900,566.133484 635.767944,565.608765 
	C634.337585,562.706726 633.136169,560.072937 631.934814,557.439087 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M573.940063,692.501038 
	C577.304871,690.304321 580.528992,690.227966 584.033813,692.233337 
	C587.237427,694.066345 588.129028,695.769287 584.654297,698.956177 
	C584.134399,700.908020 583.991211,702.286926 583.848022,703.665894 
	C582.870422,704.724121 581.892822,705.782288 581.199524,706.532776 
	C578.030212,705.443115 575.202087,704.470703 573.053101,703.731873 
	C572.880432,701.301758 572.711975,698.930054 572.275635,696.356934 
	C572.448608,695.461548 572.889526,694.767578 573.739014,694.222656 
	C574.171936,694.515991 574.180359,694.665588 574.226501,694.802551 
	C574.254883,694.886841 574.341003,694.951721 574.401245,695.025330 
	C574.268372,694.685547 574.135498,694.345764 574.002563,694.006042 
	C573.984863,693.604614 573.967163,693.203186 573.940063,692.501038 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M645.014832,628.994141 
	C646.090759,632.744873 644.459412,634.725769 640.825928,633.965027 
	C637.827454,633.337219 633.077820,636.956665 632.284546,630.186035 
	C632.264832,628.228271 631.978333,626.918518 631.691895,625.608826 
	C631.971191,624.188354 632.250427,622.767944 632.619324,620.891846 
	C636.078857,620.891846 639.376587,620.732727 642.640381,620.995300 
	C643.541138,621.067749 644.340820,622.396667 644.790649,623.610840 
	C643.486145,624.153137 641.788147,624.343933 641.781799,624.298584 
	C641.129150,619.623169 638.421875,622.662048 636.081360,623.284363 
	C637.693237,624.771606 639.305176,626.258789 640.613708,627.466125 
	C642.400330,626.864685 643.745361,626.411865 645.090393,625.959106 
	C645.117065,626.417908 645.143738,626.876648 644.840698,627.676819 
	C644.282959,628.244446 644.054993,628.470703 643.827026,628.696960 
	C644.222961,628.796021 644.618896,628.895081 645.014832,628.994141 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M592.177063,563.168152 
	C591.094360,564.410889 589.992493,566.747131 588.931885,566.728516 
	C577.437134,566.526794 578.635925,569.545105 578.573730,557.343201 
	C578.568787,556.372375 578.726135,555.400757 578.858276,553.832764 
	C582.624939,553.832764 586.266846,553.574768 589.828308,553.996216 
	C590.843201,554.116333 591.624451,556.210266 592.313782,557.809814 
	C591.583618,558.175781 591.052490,558.130981 590.289795,557.876343 
	C589.032288,558.118652 588.006470,558.570801 586.915833,558.644897 
	C587.222961,557.436035 587.594971,556.605347 588.013062,555.671692 
	C582.495911,555.158813 580.207947,557.757812 581.592834,561.801392 
	C583.353333,560.885864 585.183655,559.934082 587.092346,559.305420 
	C588.261230,560.729248 589.351746,561.829834 590.874023,563.003906 
	C591.596252,563.107605 591.886658,563.137878 592.177063,563.168152 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M675.835938,629.847534 
	C676.279846,629.337769 676.783752,629.263916 677.659363,629.596069 
	C678.373657,645.852295 678.716248,661.702515 678.788208,677.621765 
	C677.961670,677.671692 677.405884,677.652588 676.850037,677.633545 
	C676.531982,661.850159 676.213928,646.066772 675.835938,629.847534 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M610.862427,654.258240 
	C605.675354,651.122192 599.801025,651.611267 596.619324,654.769287 
	C591.528198,654.769287 587.185974,654.769287 582.496216,654.769287 
	C582.496216,653.161255 582.496216,651.133240 582.496216,647.842834 
	C583.328430,649.798584 583.709534,650.694153 584.307129,652.098572 
	C586.021545,650.818359 587.646362,649.605103 589.605835,648.141968 
	C590.118164,649.486389 590.576538,650.689453 591.240601,652.432251 
	C593.897766,649.265747 596.039062,647.226196 599.906738,650.697815 
	C601.484924,652.114441 605.323975,651.012451 608.792114,651.328857 
	C609.920715,652.487061 610.391541,653.372620 610.862427,654.258240 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M661.497070,699.575195 
	C657.100464,700.305420 652.556091,701.257202 648.294983,700.535645 
	C646.920288,700.302979 646.290649,695.669739 645.278076,692.779358 
	C647.487671,686.832275 652.416077,687.623169 656.878906,688.071472 
	C658.129272,688.197083 659.724426,690.103760 660.204773,691.518311 
	C661.022156,693.925781 661.081421,696.590637 661.497070,699.575195 
M648.978027,691.593872 
	C648.978027,693.174988 648.978027,694.756104 648.978027,696.673889 
	C650.675354,695.980286 651.779419,695.529175 653.540344,694.809570 
	C652.610901,696.612366 652.053955,697.692749 651.500305,698.766663 
	C653.831848,698.766663 655.890747,698.766663 658.045166,698.766663 
	C658.045166,696.533936 658.045166,694.730286 658.045166,692.622742 
	C656.713745,693.239807 655.644287,693.735474 653.761169,694.608215 
	C654.627563,692.686035 655.121887,691.589355 655.979126,689.687500 
	C653.264832,690.139465 651.238770,690.476807 648.978027,691.593872 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M548.047363,588.086426 
	C548.047363,588.086426 547.977417,587.609680 548.429199,587.299500 
	C555.512634,586.989319 562.144287,586.989319 570.086975,586.989319 
	C567.211060,582.464722 565.131287,579.192566 563.051514,575.920471 
	C563.050537,575.690186 563.049561,575.459961 563.050659,574.881836 
	C569.183044,577.699524 572.527100,586.905457 569.563293,592.457397 
	C564.832764,601.318909 560.418823,602.992981 549.663330,599.565552 
	C549.602722,599.053284 549.756042,598.899231 550.498291,598.722046 
	C563.846558,599.688782 565.326965,598.847717 568.805481,588.086426 
	C561.751587,588.086426 554.899475,588.086426 548.047363,588.086426 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M580.833130,642.802979 
	C580.325073,641.648376 579.987793,640.794800 579.346191,639.171021 
	C578.396973,641.098633 577.802429,642.305969 577.198486,643.532410 
	C575.886475,641.985413 574.556335,640.416992 572.623840,638.138428 
	C572.112793,640.673401 571.832458,642.064270 571.288757,644.761353 
	C570.430359,642.150940 569.943359,640.669861 569.456360,639.188782 
	C568.979553,639.116821 568.502808,639.044861 568.026001,638.972900 
	C567.629578,640.827332 567.233215,642.681824 566.836792,644.536316 
	C566.467041,644.604980 566.097229,644.673584 565.727478,644.742188 
	C565.610596,642.818054 564.970520,640.708923 565.562134,639.035095 
	C565.952087,637.931763 568.066467,636.926758 569.492676,636.796509 
	C573.766663,636.406006 578.084167,636.343018 582.379883,636.419373 
	C583.871948,636.445862 585.350098,637.253662 586.834595,637.704651 
	C585.957764,638.856140 585.203430,640.136963 584.166565,641.119507 
	C583.280823,641.958801 582.070679,642.455750 580.833130,642.802979 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M637.280640,540.872559 
	C635.147217,540.653748 633.250732,540.556580 631.688660,539.783569 
	C631.203735,539.543640 631.116943,536.970093 631.737366,536.338318 
	C633.095093,534.955688 635.022461,534.132324 636.717163,533.080566 
	C636.985352,533.518250 637.253479,533.955872 637.521606,534.393555 
	C636.247314,535.558716 634.972961,536.723877 633.698669,537.889038 
	C634.085938,538.373901 634.473145,538.858765 634.860413,539.343628 
	C636.296265,538.158325 637.765442,537.010437 639.153259,535.771301 
	C639.804565,535.189758 640.254700,533.904663 640.872620,533.852905 
	C642.770935,533.693909 644.765747,533.748657 646.591370,534.225098 
	C647.147034,534.370117 647.864441,536.680298 647.496826,537.088623 
	C646.025635,538.722473 644.333679,540.401733 642.371643,541.261475 
	C641.114990,541.812195 639.242737,540.958069 637.280640,540.872559 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M569.237305,685.296936 
	C567.465393,687.325195 558.160950,689.137146 557.404541,687.221252 
	C556.106567,683.933105 555.076843,679.420471 556.418335,676.605652 
	C557.299744,674.756287 562.514465,674.700989 565.841980,674.353821 
	C570.516602,673.866089 568.828918,678.029907 569.508423,680.629028 
	C568.538391,681.000366 567.801758,681.025391 567.072266,680.686035 
	C566.380737,679.550659 565.682068,678.779663 564.910278,677.626099 
	C562.816528,677.091980 560.146973,676.109558 558.964966,677.032410 
	C557.873047,677.884827 558.375977,680.779907 558.087708,683.662781 
	C560.476807,681.878662 561.741699,680.934021 563.172119,680.351318 
	C562.528564,682.195679 561.719421,683.678101 560.190491,686.479248 
	C563.362122,685.766541 565.350464,685.319702 567.663391,684.900513 
	C568.404480,685.051025 568.820862,685.173950 569.237305,685.296936 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M581.007141,629.142334 
	C581.007141,626.623657 581.007141,624.105042 581.007141,621.105835 
	C583.415771,621.105835 586.354309,621.105835 589.693604,621.612061 
	C590.815918,622.897095 591.537537,623.675964 592.259155,624.454773 
	C592.520813,624.593018 592.619690,624.806763 592.109436,625.440430 
	C590.639282,628.053223 589.615479,630.321533 588.484497,632.827332 
	C586.420898,630.847839 581.694641,634.442871 581.007141,629.142334 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M580.667175,629.265991 
	C581.694641,634.442871 586.420898,630.847839 588.484497,632.827332 
	C589.615479,630.321533 590.639282,628.053223 592.001831,625.595215 
	C595.617859,625.960388 598.895203,626.515381 602.521362,627.172363 
	C602.870178,627.274353 602.806213,627.664124 602.613770,627.832336 
	C602.249756,628.088928 602.078247,628.177246 601.906738,628.265503 
	C598.108215,629.094849 593.135803,627.485657 591.767273,633.479858 
	C591.634460,634.061768 588.486389,634.063293 586.729553,634.139954 
	C584.306458,634.245667 581.875244,634.166382 578.722656,634.166382 
	C579.380554,632.207886 579.853882,630.798767 580.667175,629.265991 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M656.166138,601.580688 
	C657.223083,601.233887 657.934204,600.332520 658.799988,600.144958 
	C660.350403,599.809021 661.993958,599.902771 663.598572,599.816956 
	C663.556030,600.983765 663.974915,602.655029 663.370667,603.207642 
	C662.264038,604.219666 660.550049,605.099182 659.095093,605.090332 
	C655.852905,605.070740 652.614197,604.469543 648.832031,604.044373 
	C650.964966,601.745117 652.593445,599.989563 654.754211,597.660278 
	C655.292664,599.408508 655.591370,600.378174 656.166138,601.580688 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M676.507141,677.813721 
	C677.405884,677.652588 677.961670,677.671692 679.089844,677.592590 
	C679.849487,677.419312 680.036865,677.344238 680.523193,677.288635 
	C681.588257,677.486023 682.354370,677.664001 683.377441,677.955933 
	C683.421875,679.333679 683.209290,680.597412 682.677002,681.932861 
	C681.715637,682.086487 681.074036,682.168457 680.023193,682.168945 
	C679.062988,682.057129 678.511902,682.026733 677.847717,681.713318 
	C675.219116,680.603455 675.219116,680.603455 674.724121,684.882263 
	C673.329712,683.129761 671.935303,681.377258 669.869446,678.780823 
	C669.158691,681.362061 668.758240,682.816223 668.273254,684.577637 
	C667.074158,682.259277 665.988098,680.159363 664.868103,677.993958 
	C668.496216,677.993958 672.330200,677.993958 676.507141,677.813721 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M673.879150,600.308838 
	C675.503601,599.586975 677.100586,599.281067 678.596558,598.710449 
	C679.783142,598.257690 680.845947,597.480652 682.333740,596.452759 
	C683.096863,596.033325 683.490051,596.008606 684.161194,595.958740 
	C684.624023,595.955872 684.808838,595.978210 685.336182,596.099243 
	C687.127197,596.971497 688.575623,597.745178 690.024048,598.518799 
	C689.921570,598.996887 689.819031,599.474976 689.307739,600.134277 
	C686.932678,601.210266 684.966370,602.105042 682.830994,602.761597 
	C681.295166,601.727844 679.753418,600.493835 679.621887,603.660400 
	C678.878845,603.835083 678.460022,603.956238 677.707825,604.077759 
	C676.931763,604.039124 676.489136,604.000305 676.046509,603.961426 
	C675.300354,602.844482 674.554199,601.727600 673.879150,600.308838 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M651.416138,683.328796 
	C649.422791,687.687378 645.782288,686.556763 643.210083,684.855591 
	C641.803772,683.925476 641.922852,680.688782 641.354126,678.492249 
	C641.852295,678.151123 642.350464,677.809937 642.848694,677.468750 
	C645.642639,678.302124 648.436646,679.135437 651.539795,680.236084 
	C651.704712,681.445129 651.560425,682.386963 651.416138,683.328796 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M644.473694,552.055908 
	C644.355347,552.393860 644.125793,552.507141 643.449097,552.000610 
	C642.753113,551.424805 642.393066,551.244141 642.033020,551.063538 
	C642.298401,550.438477 642.563782,549.813354 642.874817,548.844910 
	C642.689880,545.820679 643.187622,543.365295 646.329102,543.049133 
	C649.434814,542.736572 652.225708,543.535706 652.947754,547.669434 
	C653.011169,548.040344 652.993591,547.989258 652.743347,548.198730 
	C652.088318,549.498535 651.683533,550.588867 651.171143,551.969055 
	C648.038635,547.781677 647.138855,547.861267 644.473694,552.055908 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M567.433105,701.831299 
	C566.371033,701.517273 565.318970,700.986633 564.244873,700.937622 
	C562.428284,700.854858 560.550720,701.327698 558.804871,701.000732 
	C558.345093,700.914612 557.653992,698.211548 558.145691,697.646057 
	C559.438843,696.158691 561.324219,695.186157 562.973755,694.008606 
	C563.275146,694.448730 563.576538,694.888794 563.877991,695.328918 
	C562.520874,696.375854 561.163818,697.422791 559.806763,698.469727 
	C560.168945,699.009399 560.531128,699.549072 560.893311,700.088745 
	C562.539856,698.863647 564.125244,697.538330 565.869934,696.474121 
	C566.398804,696.151489 567.368408,696.551208 568.333984,697.251953 
	C568.165649,699.199707 567.799377,700.515503 567.433105,701.831299 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M680.432373,682.250427 
	C681.074036,682.168457 681.715637,682.086487 682.587280,682.228638 
	C682.525208,682.996521 682.233215,683.540283 681.941284,684.084106 
	C681.528198,690.898254 681.115051,697.712341 680.659241,705.230591 
	C678.898987,704.862671 677.291016,704.526550 675.525391,703.811279 
	C675.664734,703.326111 675.961731,703.220093 676.818237,702.927612 
	C677.377747,702.741089 677.437012,702.424805 677.437012,702.424805 
	C677.437012,702.424805 677.116760,702.239258 677.116760,702.239258 
	C677.096741,702.013184 677.076721,701.787109 677.312988,701.214355 
	C678.014282,700.394836 678.459412,699.922058 678.911255,698.963379 
	C678.915955,698.153687 678.913879,697.829834 678.805786,697.109497 
	C678.652710,691.817322 677.330383,686.768860 680.432373,682.250427 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M631.508179,557.603027 
	C633.136169,560.072937 634.337585,562.706726 635.409180,565.684204 
	C634.836548,565.876465 634.393677,565.724915 633.897949,565.312622 
	C633.346313,564.969727 632.847534,564.887695 632.176758,564.450867 
	C630.338562,559.644958 625.876038,562.074036 623.057007,560.459412 
	C623.057434,560.100220 623.057861,559.740967 622.964233,559.123169 
	C625.607361,558.498657 628.344482,558.132874 631.508179,557.603027 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M676.014771,604.432434 
	C676.489136,604.000305 676.931763,604.039124 677.711792,604.538574 
	C678.033875,611.997192 678.018494,618.995239 678.003113,625.993408 
	C677.491943,626.268127 676.980774,626.542908 676.178833,626.838623 
	C675.919739,619.540833 675.951416,612.222168 676.014771,604.432434 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M651.675537,683.660950 
	C651.560425,682.386963 651.704712,681.445129 651.892273,680.223145 
	C653.453735,679.506104 654.971924,679.069214 656.490051,678.632324 
	C656.728271,679.935181 657.493835,682.160767 657.112061,682.378906 
	C655.579407,683.254822 653.691467,683.509094 651.675537,683.660950 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M601.873169,628.616699 
	C602.078247,628.177246 602.249756,628.088928 602.710632,628.004456 
	C603.603882,629.296326 604.207764,630.584229 604.842407,632.253174 
	C603.108887,634.701599 601.494141,636.927979 599.525635,638.777893 
	C598.302612,639.927246 596.543701,640.506287 595.235107,641.225891 
	C597.609070,636.819824 599.724365,632.893799 601.873169,628.616699 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M678.904480,699.449219 
	C678.459412,699.922058 678.014282,700.394836 677.312073,700.873962 
	C677.104614,695.892883 677.154297,690.905579 677.184814,685.134766 
	C677.430664,683.566345 677.695740,682.781311 677.960815,681.996338 
	C678.511902,682.026733 679.062988,682.057129 680.023193,682.168945 
	C677.330383,686.768860 678.652710,691.817322 678.594482,697.323120 
	C678.627502,698.438599 678.765991,698.943909 678.904480,699.449219 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M631.317627,625.670227 
	C631.978333,626.918518 632.264832,628.228271 632.273438,629.770691 
	C628.598022,629.421631 625.200500,628.839844 621.406311,628.415894 
	C621.053772,628.335999 621.097839,628.098328 621.110474,627.499390 
	C624.367126,626.669312 627.655212,626.200439 631.317627,625.670227 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M590.521423,558.086121 
	C591.052490,558.130981 591.583618,558.175781 592.382385,558.200317 
	C592.878601,558.260315 593.107056,558.340515 593.862793,558.591187 
	C594.921692,558.566406 595.453186,558.371216 595.984741,558.175903 
	C597.220825,558.197266 598.456909,558.218567 599.947815,558.519836 
	C600.120361,559.205627 600.038147,559.611450 599.979370,560.326904 
	C597.578186,561.308960 595.153442,561.981445 592.452881,562.911011 
	C591.886658,563.137878 591.596252,563.107605 590.898315,562.660828 
	C590.500916,560.858276 590.511169,559.472168 590.521423,558.086121 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M618.425354,541.400757 
	C618.787964,538.978699 619.178955,536.829407 620.052917,534.898804 
	C620.208557,534.555115 622.368896,535.118896 623.895081,535.297852 
	C622.222229,536.951599 621.108459,538.052612 619.994751,539.153625 
	C621.201843,539.799377 622.408936,540.445068 623.616028,541.090820 
	C623.665039,541.531311 623.713989,541.971802 623.763000,542.412292 
	C622.048157,542.190918 620.333374,541.969543 618.425354,541.400757 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M555.077209,640.224182 
	C556.659363,639.398743 558.241516,638.573303 559.823608,637.747803 
	C559.907898,639.233398 560.521240,640.968079 559.963867,642.151489 
	C558.877808,644.457764 557.035583,644.541077 555.215393,641.866394 
	C555.083496,640.927856 555.080322,640.575989 555.077209,640.224182 
M557.519714,640.400940 
	C557.519714,640.400940 557.613098,640.537781 557.519714,640.400940 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M567.065125,681.050415 
	C567.801758,681.025391 568.538391,681.000366 569.525940,681.033081 
	C571.302612,681.860840 572.828369,682.630859 574.659241,683.616577 
	C574.741394,684.530640 574.518494,685.228943 574.052795,686.085999 
	C572.508301,685.924377 571.206604,685.604004 569.571045,685.290283 
	C568.820862,685.173950 568.404480,685.051025 567.474854,684.628174 
	C566.996155,683.235596 567.030640,682.142944 567.065125,681.050415 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M554.768311,640.013062 
	C555.080322,640.575989 555.083496,640.927856 555.077942,641.543640 
	C553.452148,644.744202 551.559937,642.869446 549.439514,641.944092 
	C549.075867,640.785828 549.004333,639.796265 548.991211,638.434814 
	C551.458984,636.757080 553.733154,635.871765 554.768311,640.013062 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M645.164246,625.638550 
	C643.745361,626.411865 642.400330,626.864685 640.613708,627.466125 
	C639.305176,626.258789 637.693237,624.771606 636.081360,623.284363 
	C638.421875,622.662048 641.129150,619.623169 641.781799,624.298584 
	C641.788147,624.343933 643.486145,624.153137 644.724731,624.047363 
	C645.116028,624.454163 645.177063,624.886047 645.164246,625.638550 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M669.903687,699.698364 
	C667.618958,699.453125 665.196594,698.901306 662.370361,698.431641 
	C664.657288,694.118286 664.657288,694.118286 669.903687,699.698364 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M639.977356,553.044922 
	C637.577209,553.677002 635.177124,554.309143 632.411133,555.007568 
	C632.029175,554.320679 632.013184,553.567566 631.997925,552.438171 
	C634.419556,552.093933 636.840332,552.125977 639.652893,552.398376 
	C640.044617,552.638733 639.977356,553.044922 639.977356,553.044922 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M610.950562,654.641357 
	C610.391541,653.372620 609.920715,652.487061 609.245728,651.347351 
	C609.379211,650.975159 609.716858,650.857056 610.324585,650.740234 
	C611.175903,650.776917 611.757019,650.812317 612.773193,650.803101 
	C613.208252,650.758484 613.183655,650.776550 613.136475,651.175659 
	C613.056519,652.376648 613.023743,653.178528 612.990967,653.980347 
	C612.722351,654.768433 612.453796,655.556519 612.185303,656.344604 
	C611.803101,655.904541 611.420837,655.464478 610.950562,654.641357 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M613.322754,653.827026 
	C613.023743,653.178528 613.056519,652.376648 613.148865,651.175293 
	C614.287109,651.352966 615.365784,651.930298 616.444397,652.507629 
	C615.514404,652.896301 614.584412,653.284973 613.322754,653.827026 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M677.847717,681.713318 
	C677.695740,682.781311 677.430664,683.566345 677.105774,684.672607 
	C676.529663,684.982666 676.013367,684.971436 675.110596,684.921204 
	C675.219116,680.603455 675.219116,680.603455 677.847717,681.713318 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M567.814575,701.795532 
	C567.799377,700.515503 568.165649,699.199707 568.494385,697.547119 
	C568.808655,697.187256 569.160461,697.164185 570.042725,697.169006 
	C570.782166,698.243164 570.991089,699.289490 571.156860,700.658691 
	C570.141174,701.241028 569.168579,701.500366 567.814575,701.795532 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M592.301147,624.082764 
	C591.537537,623.675964 590.815918,622.897095 590.132080,621.756104 
	C590.894165,622.166199 591.618652,622.938416 592.301147,624.082764 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M595.815186,557.999390 
	C595.453186,558.371216 594.921692,558.566406 594.225708,558.603149 
	C594.589417,558.237366 595.117554,558.030151 595.815186,557.999390 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M645.091553,628.751221 
	C644.618896,628.895081 644.222961,628.796021 643.827026,628.696960 
	C644.054993,628.470703 644.282959,628.244446 644.774902,628.016968 
	C645.081970,628.179932 645.125061,628.344116 645.091553,628.751221 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M677.158447,702.405640 
	C677.116760,702.239258 677.437012,702.424805 677.437012,702.424805 
	C677.437012,702.424805 677.377747,702.741089 677.280518,702.827881 
	C677.183350,702.914734 677.200073,702.572083 677.158447,702.405640 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M638.476318,603.497192 
	C638.502197,603.508362 638.450378,603.486023 638.476318,603.497192 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M622.750183,560.680420 
	C625.876038,562.074036 630.338562,559.644958 632.167297,564.747131 
	C632.870178,565.456604 633.410461,565.515015 633.950806,565.573425 
	C634.393677,565.724915 634.836548,565.876465 635.638184,565.952454 
	C638.209900,566.133484 640.435852,566.636414 642.629272,566.521057 
	C643.466370,566.476929 644.904602,565.020264 644.909607,564.200256 
	C644.932556,560.466064 644.542847,556.729248 644.387573,552.524658 
	C647.138855,547.861267 648.038635,547.781677 651.171143,551.969055 
	C651.683533,550.588867 652.088318,549.498535 652.769836,548.192871 
	C653.569031,548.469849 654.041199,549.313232 654.622375,549.396606 
	C657.929199,549.871216 659.266724,551.678162 659.220398,554.984619 
	C659.136475,560.977112 659.013062,566.988220 659.383484,572.961975 
	C659.700623,578.078674 658.535522,581.994629 654.625122,585.770630 
	C648.337830,591.841919 648.669861,592.170532 640.937195,588.184326 
	C632.217712,583.689331 623.420166,579.337158 614.833862,574.601440 
	C613.520935,573.877258 613.162842,571.421936 612.363159,569.767273 
	C612.849731,569.505737 613.336365,569.244202 613.822937,568.982666 
	C614.676270,569.565186 615.529541,570.147705 616.902344,571.084900 
	C616.068665,565.847046 618.112549,562.670959 622.750183,560.680420 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M625.203369,588.826477 
	C624.970215,590.104187 624.367371,591.439697 623.764526,592.775208 
	C623.286072,592.580811 622.807556,592.386475 622.329102,592.192078 
	C622.036499,590.488770 621.743958,588.785461 621.591003,587.894836 
	C619.681519,588.435791 617.552612,589.825439 616.269043,589.268127 
	C610.274963,586.665405 613.574341,581.411499 612.521912,575.837280 
	C619.931824,579.679749 626.163696,582.911316 632.395508,586.142883 
	C632.259766,586.654114 632.124023,587.165344 631.988342,587.676514 
	C630.990662,587.506653 629.998596,587.252014 628.994202,587.185852 
	C627.304565,587.074646 625.607117,587.083191 623.912964,587.041321 
	C624.219849,587.655640 624.526794,588.269958 625.203369,588.826477 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M628.676147,590.471436 
	C629.327087,590.808167 629.647949,591.296631 629.968811,591.785156 
	C629.272156,591.845642 628.575439,591.906067 627.878784,591.966553 
	C628.034485,591.518738 628.190247,591.070984 628.676147,590.471436 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M590.433228,514.510742 
	C590.384949,514.441162 590.580750,514.427490 590.580750,514.427490 
	C590.580750,514.427490 590.481445,514.580322 590.433228,514.510742 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M409.529419,446.978271 
	C409.980133,447.364624 409.963104,447.722809 409.916016,448.342499 
	C401.992035,448.286865 394.098083,447.969696 386.004395,447.300659 
	C393.556976,446.949219 401.309296,446.949646 409.529419,446.978271 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M390.035583,430.950500 
	C389.721741,431.430359 389.407898,431.910248 389.033447,432.699493 
	C387.907196,432.242493 386.841492,431.476105 385.886230,430.373230 
	C387.040253,430.057922 388.083801,430.079132 389.557922,430.260254 
	C390.004181,430.596954 390.019867,430.773712 390.035583,430.950500 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M388.967529,433.326782 
	C389.297699,433.173950 389.605347,433.322266 389.943665,433.740234 
	C389.631195,433.882538 389.288116,433.755249 388.967529,433.326782 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M384.947601,429.348877 
	C385.312927,429.175995 385.623688,429.314056 385.948364,429.725067 
	C385.605865,429.885284 385.249451,429.772552 384.947601,429.348877 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M365.984192,375.391235 
	C368.118896,375.013611 370.227142,375.013611 372.985626,375.013611 
	C372.985626,382.793304 372.959534,390.033600 372.991089,397.273590 
	C373.055908,412.145630 373.176544,427.017487 373.204285,441.889557 
	C373.207947,443.842377 372.800873,445.795959 372.240417,448.034668 
	C363.309692,445.681244 361.170715,439.738800 361.422882,431.133667 
	C361.903442,414.736542 361.264526,398.306671 361.078552,381.222473 
	C361.066772,380.554565 361.132507,380.140350 361.132507,380.140350 
	C363.501434,379.522675 366.338531,379.421722 365.984192,375.391235 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M405.483917,451.082581 
	C405.965393,451.444031 405.960938,451.751343 405.908875,452.281708 
	C399.349792,452.595581 392.813690,452.686066 385.894623,452.634064 
	C385.059784,452.200592 384.607880,451.909576 384.031128,451.448517 
	C390.945099,451.195251 397.983887,451.112030 405.483917,451.082581 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M361.067139,379.800720 
	C361.132507,380.140350 361.066772,380.554565 361.007385,380.755676 
	C360.797150,380.062927 360.646301,379.169006 360.495453,378.275116 
	C360.664215,378.670441 360.832977,379.065735 361.067139,379.800720 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M376.237122,372.860046 
	C376.145813,372.450165 376.310181,371.865448 376.747620,371.122986 
	C376.844666,371.538574 376.668732,372.111908 376.237122,372.860046 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M390.416992,431.081757 
	C390.019867,430.773712 390.004181,430.596954 389.940460,430.158142 
	C391.461456,428.414642 393.030426,426.933228 395.383087,424.711884 
	C392.845276,424.489471 391.558655,424.376709 390.272034,424.263947 
	C390.225494,423.835510 390.178955,423.407104 390.132446,422.978668 
	C391.622925,422.758728 393.479767,421.914764 394.492126,422.509186 
	C395.358521,423.017914 395.429932,425.163086 395.530945,426.607056 
	C395.618042,427.851501 395.251160,429.127655 395.006134,430.988739 
	C393.936859,431.045746 392.367645,431.129395 390.416992,431.081757 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M558.999878,551.254395 
	C562.978027,548.088074 565.708435,552.224487 569.386658,553.051453 
	C574.668396,554.818115 574.421753,559.153625 573.275513,562.479370 
	C572.241150,565.480713 569.264160,567.855042 566.945984,570.319763 
	C566.643188,570.641663 565.089417,569.996460 564.239868,569.557617 
	C560.862976,567.813354 557.535645,565.973145 554.089600,564.114319 
	C554.612488,561.846802 555.084778,559.798462 555.999207,557.343262 
	C557.294189,555.042419 558.147034,553.148376 558.999878,551.254395 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M558.715454,550.970825 
	C558.147034,553.148376 557.294189,555.042419 556.051025,556.976562 
	C553.720581,558.678223 551.780396,560.339661 549.464233,561.947998 
	C548.810364,560.206848 548.532532,558.518799 548.063477,556.507324 
	C547.909363,553.452942 547.946533,550.722046 548.022827,547.544312 
	C550.455627,547.668030 552.203491,549.962097 555.434937,547.340210 
	C558.362061,544.965149 557.915649,549.006958 558.715454,550.970825 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M549.403748,568.631592 
	C548.575745,568.070801 547.645203,567.211426 546.433594,566.193176 
	C546.903137,565.220642 547.653564,564.406921 548.707031,563.700073 
	C549.107117,565.315552 549.204163,566.824280 549.403748,568.631592 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M582.386230,524.968262 
	C583.069214,524.619385 583.769348,524.591248 584.469360,524.563110 
	C584.454590,524.856628 584.439819,525.150085 584.425049,525.443604 
	C583.751160,525.392090 583.077209,525.340515 582.386230,524.968262 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M522.705200,524.389099 
	C522.314880,525.073425 521.599121,525.473267 520.883301,525.873169 
	C520.666077,525.367615 520.448792,524.862061 520.231567,524.356445 
	C520.947632,524.272522 521.663635,524.188599 522.705200,524.389099 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M541.262329,535.962585 
	C541.093811,535.676208 541.265381,535.430725 541.666992,535.079590 
	C541.741638,535.277039 541.643372,535.620300 541.262329,535.962585 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M524.661926,526.282410 
	C524.653320,526.508301 524.461304,526.557434 524.269287,526.606567 
	C524.339050,526.439575 524.408813,526.272644 524.661926,526.282410 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M298.726624,349.599396 
	C298.853424,349.449615 299.076782,349.429352 299.300171,349.409119 
	C299.141174,349.515717 298.982178,349.622314 298.726624,349.599396 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M737.000305,685.998962 
	C736.223511,685.735718 735.446777,685.472473 733.917786,685.170044 
	C731.029480,685.124207 728.727905,684.623779 726.809448,685.266785 
	C725.265137,685.784180 724.184204,687.684143 722.897705,688.970764 
	C720.255981,691.634521 717.614258,694.298279 714.614624,697.386353 
	C713.553955,696.980957 712.674866,696.244629 712.177002,695.306091 
	C709.002808,689.322083 704.069885,686.163818 697.322632,685.803284 
	C697.108459,671.500366 696.728149,657.195618 696.883911,642.896729 
	C696.904053,641.046753 699.695740,639.226929 701.031433,637.602478 
	C695.400085,635.114197 694.926331,632.538696 700.142944,627.424561 
	C704.139221,623.506836 707.726624,618.697327 714.812744,621.070923 
	C716.070435,622.699646 717.012146,623.864746 717.973328,625.014282 
	C717.992737,624.998779 717.970215,625.037354 717.788940,625.274048 
	C716.700317,628.275391 715.793030,631.039978 714.571167,633.955872 
	C713.396057,635.054077 712.535522,636.000977 711.622925,637.005127 
	C708.963928,633.085693 708.180603,633.095764 703.300293,637.631348 
	C706.056213,638.595093 708.346741,639.286194 710.545715,640.198120 
	C712.474854,640.998230 714.301147,642.046143 715.866150,643.198059 
	C715.360535,647.909180 714.440979,652.504395 717.469482,656.549011 
	C717.765564,656.944336 717.909729,657.600647 717.842163,658.097473 
	C717.357117,661.663513 716.502014,665.211914 716.409424,668.782654 
	C716.372070,670.222168 717.654907,672.107117 718.899963,673.028198 
	C719.772522,673.673645 722.007202,673.535950 722.888672,672.824646 
	C723.842407,672.055176 724.362061,670.197327 724.312317,668.841980 
	C724.183777,665.336914 722.641663,661.583984 723.480469,658.410095 
	C724.911316,652.996277 722.437256,651.428101 717.742981,650.322083 
	C719.383484,649.441040 720.930420,649.479736 722.188599,648.896057 
	C723.265381,648.396484 724.944946,646.768372 724.759094,646.350281 
	C722.881226,642.126404 723.959473,638.138428 724.759949,633.911865 
	C725.216248,631.502686 723.791931,628.785461 723.529236,626.175232 
	C723.354553,624.440308 723.736267,622.649414 723.868896,620.883545 
	C725.323181,620.781921 726.777405,620.680298 728.629028,620.976807 
	C729.750122,622.260864 730.473816,623.146912 731.197449,624.032959 
	C731.829285,623.061829 732.461182,622.090698 733.093079,621.119568 
	C733.862183,620.962952 734.631287,620.806335 735.738525,621.139282 
	C735.564270,623.957581 735.051941,626.286377 734.544739,628.591980 
	C732.239685,627.763611 730.394348,627.100464 727.382629,626.018127 
	C728.450989,631.919556 729.350159,636.886597 730.309387,642.185181 
	C727.974426,643.652466 726.203552,645.750244 730.146851,648.644836 
	C730.255371,648.724426 729.848755,649.505798 729.373474,650.036499 
	C729.016724,652.046875 728.968994,653.980774 728.866211,656.268433 
	C728.774353,657.098267 728.737610,657.574280 728.488159,658.337280 
	C728.911987,660.804138 729.548462,662.983948 730.184937,665.163696 
	C731.456604,664.775818 732.728333,664.387878 734.217529,664.264648 
	C735.200500,664.523743 736.541870,664.743591 736.645569,664.478882 
	C738.301453,660.250244 742.121155,661.464111 745.179382,661.120300 
	C748.098267,660.792053 751.077698,661.002808 754.030518,660.976624 
	C755.050476,662.556641 756.869202,664.096985 756.950012,665.723633 
	C757.273865,672.239319 757.058472,678.781799 756.621582,685.662964 
	C751.216309,686.481140 746.231873,686.950623 741.043762,687.439270 
	C740.030090,687.078186 738.515198,686.538574 737.000305,685.998962 
M740.019348,680.239380 
	C738.952026,676.154358 735.776184,678.788513 733.777710,677.473633 
	C732.223938,676.733032 730.695618,675.543884 729.104980,675.453735 
	C728.381592,675.412720 727.534973,677.546509 726.219177,679.195984 
	C724.336487,679.852356 722.453796,680.508667 720.571106,681.164978 
	C720.371277,680.772522 720.171448,680.380066 719.971619,679.987610 
	C721.375977,678.744568 722.780334,677.501465 724.184692,676.258362 
	C723.749084,675.817932 723.313477,675.377502 722.877869,674.937073 
	C721.239624,676.532898 719.383728,677.982605 718.137207,679.841125 
	C717.870850,680.238281 719.957764,683.217346 720.718262,683.119690 
	C723.478943,682.765259 726.136658,681.608887 729.587219,681.020508 
	C731.340820,681.377319 733.094421,681.734131 735.406006,681.543762 
	C736.960083,681.372681 738.514099,681.201599 740.239380,681.682129 
	C742.196838,682.317627 744.154297,682.953125 746.441467,683.695740 
	C746.577515,681.296509 747.010132,680.025330 746.619446,679.102966 
	C745.996704,677.632996 744.960388,676.250732 743.790710,675.162720 
	C743.504456,674.896484 742.023132,675.915039 740.453918,676.635498 
	C742.376526,677.351868 743.551636,677.789673 746.025391,678.711426 
	C743.204346,679.818420 741.618103,680.440918 740.019348,680.239380 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M681.877502,684.527222 
	C682.233215,683.540283 682.525208,682.996521 682.906921,682.156860 
	C683.209290,680.597412 683.421875,679.333679 683.285461,677.506958 
	C682.679749,660.366089 682.423035,643.788269 682.166382,627.210510 
	C686.025818,625.318054 689.885315,623.425598 693.960876,621.427124 
	C693.406189,626.011230 696.215515,631.777161 692.371826,636.258850 
	C693.046936,639.402893 694.058350,642.098633 694.136230,644.821167 
	C694.491882,657.253479 694.656494,669.692932 694.710876,682.130615 
	C694.721252,684.503723 693.924622,686.880310 693.472778,689.998413 
	C693.550232,692.183960 693.655212,693.626343 693.760254,695.068726 
	C693.659546,695.486023 693.558838,695.903320 693.486206,696.647888 
	C693.355347,699.448669 693.196350,701.922119 693.024170,704.600586 
	C692.018250,704.820496 691.087830,705.162170 690.142944,705.208496 
	C682.571716,705.579651 682.640503,705.561218 682.491516,697.883545 
	C682.407898,693.576416 682.048706,689.274597 681.877502,684.527222 
M685.863892,685.924072 
	C690.727417,683.975525 691.557434,680.180664 688.332214,677.105408 
	C687.075134,679.453857 685.741089,681.876587 684.514526,684.352600 
	C684.400940,684.581848 684.970398,685.149536 685.863892,685.924072 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M714.885742,633.804565 
	C715.793030,631.039978 716.700317,628.275391 718.020386,625.208801 
	C718.714844,624.798035 718.996521,624.689331 719.278198,624.580627 
	C719.182861,624.465027 719.013794,624.242065 719.002808,624.249573 
	C718.643799,624.495911 718.299988,624.764526 717.953918,625.029785 
	C717.012146,623.864746 716.070435,622.699646 715.192139,621.155273 
	C717.879089,620.857422 720.502563,620.938904 723.497437,620.951965 
	C723.736267,622.649414 723.354553,624.440308 723.529236,626.175232 
	C723.791931,628.785461 725.216248,631.502686 724.759949,633.911865 
	C723.959473,638.138428 722.881226,642.126404 724.759094,646.350281 
	C724.944946,646.768372 723.265381,648.396484 722.188599,648.896057 
	C720.930420,649.479736 719.383484,649.441040 717.742981,650.322083 
	C722.437256,651.428101 724.911316,652.996277 723.480469,658.410095 
	C722.641663,661.583984 724.183777,665.336914 724.312317,668.841980 
	C724.362061,670.197327 723.842407,672.055176 722.888672,672.824646 
	C722.007202,673.535950 719.772522,673.673645 718.899963,673.028198 
	C717.654907,672.107117 716.372070,670.222168 716.409424,668.782654 
	C716.502014,665.211914 717.357117,661.663513 717.842163,658.097473 
	C717.909729,657.600647 717.765564,656.944336 717.469482,656.549011 
	C714.440979,652.504395 715.360535,647.909180 716.171875,643.146729 
	C717.430481,642.832581 718.076538,642.783142 718.385925,642.759460 
	C718.112976,641.686401 717.277527,640.277588 717.674683,639.654358 
	C719.993835,636.015686 718.014954,634.696167 714.885742,633.804565 
M722.198303,636.307312 
	C722.117859,636.426025 722.037415,636.544739 721.957031,636.663452 
	C722.104187,636.616394 722.251404,636.569336 722.198303,636.307312 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M729.682434,649.959900 
	C729.848755,649.505798 730.255371,648.724426 730.146851,648.644836 
	C726.203552,645.750244 727.974426,643.652466 730.309387,642.185181 
	C729.350159,636.886597 728.450989,631.919556 727.382629,626.018127 
	C730.394348,627.100464 732.239685,627.763611 734.544739,628.591980 
	C735.051941,626.286377 735.564270,623.957581 736.125916,621.229187 
	C736.557495,620.822449 736.939697,620.815369 737.639709,621.304382 
	C737.957458,634.159912 737.957458,646.519348 737.957458,658.050232 
	C734.718994,658.050232 731.709900,658.050232 728.700806,658.050232 
	C728.737610,657.574280 728.774353,657.098267 729.275757,656.291321 
	C731.283630,655.960327 732.826782,655.960327 734.484497,655.960327 
	C733.942688,653.638245 733.546448,651.939941 733.128662,650.149353 
	C731.925049,650.083191 730.803711,650.021545 729.682434,649.959900 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M683.000000,602.999817 
	C684.966370,602.105042 686.932678,601.210266 689.181152,600.478638 
	C690.093567,601.814941 690.644165,603.040588 691.374878,604.147278 
	C692.229004,605.440857 693.793762,606.512207 694.088013,607.886047 
	C695.390686,613.968750 690.027283,621.778259 682.387024,624.377930 
	C681.969666,621.149170 681.819397,618.327515 681.973389,615.522522 
	C682.202881,611.342102 682.647156,607.173462 683.000000,602.999817 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M728.488159,658.337280 
	C731.709900,658.050232 734.718994,658.050232 737.957458,658.050232 
	C737.957458,646.519348 737.957458,634.159912 737.927124,621.315918 
	C738.361572,620.841919 738.826294,620.852356 739.619141,620.992676 
	C739.910034,625.484985 739.836487,629.847351 739.844360,634.209656 
	C739.855469,640.362000 740.168945,646.525146 739.889709,652.662659 
	C739.695374,656.933105 741.365173,658.351318 745.398865,658.046753 
	C748.209595,657.834534 751.053284,657.962097 753.871277,658.125793 
	C754.095093,658.138794 754.233521,659.621704 754.221069,660.699097 
	C751.077698,661.002808 748.098267,660.792053 745.179382,661.120300 
	C742.121155,661.464111 738.301453,660.250244 736.645569,664.478882 
	C736.541870,664.743591 735.200500,664.523743 734.505188,664.228943 
	C734.880066,663.526123 735.184814,663.123657 735.489502,662.721252 
	C735.228882,662.632019 734.767212,662.423950 734.738831,662.471863 
	C734.452026,662.957275 734.235962,663.484497 734.000000,664.000000 
	C732.728333,664.387878 731.456604,664.775818 730.184937,665.163696 
	C729.548462,662.983948 728.911987,660.804138 728.488159,658.337280 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M713.412842,704.626648 
	C712.014221,705.429199 710.672852,706.770508 709.205505,706.926392 
	C705.755676,707.292908 702.245667,707.093140 698.277710,707.176880 
	C697.555908,701.159119 697.316406,695.087097 697.493164,688.510071 
	C704.621033,688.995178 709.462708,693.034668 709.577148,698.004395 
	C707.197205,698.741882 705.192139,699.527405 703.220093,700.299927 
	C702.787354,699.767639 702.288147,699.153625 701.423401,698.089966 
	C701.423401,700.610168 701.423401,702.362000 701.423401,703.811279 
	C705.480530,706.781982 708.828918,705.226440 712.113770,703.243103 
	C712.556702,703.892944 712.984802,704.259766 713.412842,704.626648 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M690.346436,598.411133 
	C688.575623,597.745178 687.127197,596.971497 685.253540,595.746887 
	C686.198120,593.335876 687.567932,591.375854 689.318726,589.483521 
	C693.775208,593.581543 697.871887,589.374023 701.957153,589.582947 
	C701.322876,593.188354 700.688538,596.793762 700.054260,600.399170 
	C699.421326,600.425842 698.788330,600.452454 698.155396,600.479126 
	C697.143738,599.029480 696.132080,597.579834 694.890686,595.801025 
	C694.380127,596.626587 693.994202,597.250549 693.064209,597.949219 
	C692.345520,598.044861 692.171021,598.065796 691.996582,598.086670 
	C691.554016,598.158936 691.111389,598.231140 690.346436,598.411133 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M681.854675,627.296387 
	C682.423035,643.788269 682.679749,660.366089 683.028442,677.392883 
	C682.354370,677.664001 681.588257,677.486023 680.470215,676.795959 
	C680.043518,660.324463 679.968750,644.365173 679.893982,628.405823 
	C680.443665,628.064636 680.993286,627.723511 681.854675,627.296387 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M679.628296,628.359131 
	C679.968750,644.365173 680.043518,660.324463 680.171265,676.776489 
	C680.036865,677.344238 679.849487,677.419312 679.360474,677.523621 
	C678.716248,661.702515 678.373657,645.852295 678.038940,629.553589 
	C678.485352,628.840942 678.924011,628.576660 679.628296,628.359131 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M709.952026,698.052429 
	C709.462708,693.034668 704.621033,688.995178 697.531372,688.049438 
	C697.169067,687.583435 697.184814,687.073059 697.261597,686.182983 
	C704.069885,686.163818 709.002808,689.322083 712.177002,695.306091 
	C712.674866,696.244629 713.553955,696.980957 714.587280,697.728455 
	C714.917847,697.646179 715.002686,697.905823 714.894653,698.296631 
	C714.174683,699.423218 713.562683,700.159058 712.667236,700.797180 
	C711.573242,699.817139 710.762634,698.934814 709.952026,698.052429 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M702.025513,589.288452 
	C697.871887,589.374023 693.775208,593.581543 689.266846,589.327515 
	C688.643738,588.951782 688.435120,588.919495 688.178955,588.670044 
	C688.084045,587.887512 688.018250,587.442017 688.227234,586.830383 
	C689.032043,586.301025 689.562073,585.937744 690.455078,585.679321 
	C691.559204,585.728638 692.300537,585.673218 693.056885,585.941406 
	C693.152100,586.837341 693.232178,587.409607 693.248474,587.526245 
	C694.321289,586.988220 695.333618,586.480530 696.353760,586.255066 
	C697.197083,586.352051 698.032471,586.166809 699.276245,585.948364 
	C700.774719,585.949158 701.864685,585.983093 702.954712,586.017029 
	C702.899353,586.463379 702.843994,586.909729 702.791138,587.693604 
	C702.560303,588.352051 702.327026,588.673035 702.025513,589.288452 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M682.831055,602.761597 
	C682.647156,607.173462 682.202881,611.342102 681.973389,615.522522 
	C681.819397,618.327515 681.969666,621.149170 682.034546,624.420166 
	C681.667358,624.969360 681.249329,625.061829 680.447876,624.719849 
	C680.024963,617.392639 679.985596,610.499756 679.946167,603.606934 
	C679.753418,600.493835 681.295166,601.727844 682.831055,602.761597 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M679.621887,603.660400 
	C679.985596,610.499756 680.024963,617.392639 680.131470,624.774841 
	C679.670166,625.542908 679.141724,625.821655 678.308228,626.046875 
	C678.018494,618.995239 678.033875,611.997192 678.045288,604.538269 
	C678.460022,603.956238 678.878845,603.835083 679.621887,603.660400 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M745.612061,620.804077 
	C746.477417,624.799438 748.991028,628.913757 744.328918,633.007080 
	C741.643982,629.610107 743.542175,625.527161 743.010742,621.479492 
	C743.756104,620.941040 744.497314,620.862183 745.612061,620.804077 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M732.780762,621.068604 
	C732.461182,622.090698 731.829285,623.061829 731.197449,624.032959 
	C730.473816,623.146912 729.750122,622.260864 729.065308,620.948120 
	C730.225586,620.686890 731.347046,620.852234 732.780762,621.068604 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M693.986572,694.960632 
	C693.655212,693.626343 693.550232,692.183960 693.626465,690.337646 
	C693.942749,691.573303 694.077759,693.212891 693.986572,694.960632 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M691.764221,601.166992 
	C692.375183,600.737488 692.912231,600.580933 693.449219,600.424316 
	C693.522888,600.710571 693.596558,600.996765 693.670288,601.282959 
	C693.010315,601.335266 692.350281,601.387634 691.764221,601.166992 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M692.073975,598.240234 
	C692.171021,598.065796 692.345520,598.044861 692.754578,598.144409 
	C692.709839,598.307861 692.430542,598.350830 692.073975,598.240234 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M755.089844,617.284546 
	C755.100403,617.098511 755.553406,617.034668 755.779297,616.997314 
	C755.974487,617.278076 755.828918,617.529480 755.355713,617.775879 
	C755.143005,617.837646 755.079346,617.470520 755.089844,617.284546 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M715.163818,574.177856 
	C714.991943,574.244263 714.611816,574.099731 714.376038,573.759705 
	C714.665466,573.698425 714.810486,573.832703 715.163818,574.177856 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M737.028076,686.415588 
	C738.515198,686.538574 740.030090,687.078186 741.043762,687.439270 
	C746.231873,686.950623 751.216309,686.481140 756.617188,686.122192 
	C757.136475,686.550293 757.344849,686.766296 757.985168,687.327393 
	C758.303833,688.858765 758.295898,689.943481 757.987549,691.106201 
	C757.101318,691.731140 756.515564,692.277954 755.614380,693.007263 
	C749.421997,693.114807 743.544983,693.039856 737.667969,692.964905 
	C737.521912,692.341064 737.375854,691.717285 737.646973,690.902710 
	C738.353943,690.126892 738.643616,689.541870 738.933289,688.956848 
	C738.436707,688.788635 737.940063,688.620361 737.443481,688.452148 
	C737.314270,687.912170 737.184998,687.372192 737.028076,686.415588 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M758.288025,691.028137 
	C758.295898,689.943481 758.303833,688.858765 758.212585,687.377686 
	C758.584229,684.736206 759.055054,682.491028 759.821045,679.954102 
	C760.398193,683.798279 760.680115,687.934326 760.760376,692.722473 
	C760.392151,694.604309 760.225525,695.833984 760.048828,697.061523 
	C760.038757,697.059387 760.041748,697.076965 759.751343,696.844238 
	C759.070007,694.750427 758.679016,692.889282 758.288025,691.028137 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M742.904297,635.556274 
	C743.245605,635.317017 743.730164,635.238098 744.214661,635.159241 
	C744.232056,635.306824 744.249390,635.454468 744.266724,635.602051 
	C743.860352,635.640259 743.453979,635.678467 742.904297,635.556274 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M823.863281,621.998230 
	C824.083496,621.394653 824.130188,620.461426 824.551453,620.239563 
	C827.943481,618.453735 836.339722,620.001770 838.189270,622.646606 
	C840.917664,617.994812 845.238525,619.264771 849.539185,619.322510 
	C849.733521,621.071838 849.894592,622.521545 850.063232,624.673706 
	C850.044739,625.581421 850.018738,625.786621 849.524780,626.015503 
	C842.009277,626.031738 834.957397,625.879578 827.915771,626.082764 
	C824.749939,626.174072 823.220764,625.346313 823.863281,621.998230 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M823.587769,622.102417 
	C823.220764,625.346313 824.749939,626.174072 827.915771,626.082764 
	C834.957397,625.879578 842.009277,626.031738 849.890869,626.028748 
	C851.689453,626.191284 852.654114,626.364197 853.618774,626.537048 
	C853.915222,627.033569 854.211609,627.530090 854.508057,628.026611 
	C852.743530,629.282043 850.979004,630.537476 848.948975,631.867493 
	C848.503052,631.972229 848.322693,632.002380 847.934937,631.675842 
	C846.737061,630.870544 845.752441,630.049683 844.755493,630.034424 
	C838.854553,629.944397 832.950745,630.063049 827.047913,630.067810 
	C823.153259,630.070984 821.252319,631.801208 821.612427,635.859375 
	C821.040894,635.872864 820.712463,635.860657 820.339233,635.537781 
	C819.506287,633.700317 818.717773,632.181641 817.925110,630.309448 
	C816.316040,628.992981 814.711121,628.029846 813.106201,627.066711 
	C813.106201,627.066711 813.031799,626.574524 813.486633,626.293701 
	C816.401123,625.587952 818.860718,625.163086 821.320374,624.738159 
	C820.218384,623.001465 819.116333,621.264648 818.014343,619.527893 
	C821.529175,616.961914 821.340942,621.719788 823.587769,622.102417 
M824.341675,628.552551 
	C832.350952,628.552551 840.360229,628.552551 848.369507,628.552551 
	C848.351562,628.262085 848.333618,627.971558 848.315735,627.681091 
	C840.353210,627.681091 832.390747,627.681091 824.341675,628.552551 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M817.583008,619.626709 
	C819.116333,621.264648 820.218384,623.001465 821.320374,624.738159 
	C818.860718,625.163086 816.401123,625.587952 813.499207,626.045044 
	C809.632874,625.788635 809.947327,623.323181 810.105347,620.576416 
	C812.471313,620.034546 814.811462,619.880066 817.583008,619.626709 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M849.214478,631.792908 
	C850.979004,630.537476 852.743530,629.282043 854.508057,628.026611 
	C854.211609,627.530090 853.915222,627.033569 853.618774,626.537048 
	C852.654114,626.364197 851.689453,626.191284 850.358765,626.005127 
	C850.018738,625.786621 850.044739,625.581421 850.156006,625.076965 
	C853.910889,624.927856 857.580688,625.078003 861.723389,625.273682 
	C862.196289,625.319275 862.200195,625.536438 861.713440,625.673584 
	C855.251709,626.054504 855.251709,626.054504 856.075378,634.176392 
	C855.236572,634.614258 854.618225,634.811646 853.760132,634.842163 
	C853.380615,634.132874 853.240784,633.590515 853.262695,632.795593 
	C852.021179,632.293030 850.617859,632.042969 849.214478,631.792908 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M820.190796,650.160095 
	C818.127991,649.562988 816.065125,648.965820 813.307068,648.298462 
	C812.083679,648.871399 811.555603,649.514648 811.027466,650.157837 
	C804.603271,650.435486 798.180847,650.797607 791.753662,650.927246 
	C790.272034,650.957153 788.774414,650.190125 787.240662,649.397949 
	C793.519226,648.297852 797.971497,645.358337 800.187012,639.066284 
	C801.620667,634.994690 800.117432,633.893555 796.398132,633.979858 
	C793.462952,634.048035 790.524841,633.993530 787.495911,633.993530 
	C788.230774,629.533203 786.473206,630.113220 802.282227,630.493347 
	C802.379395,634.980286 805.374023,634.688416 807.839966,635.258423 
	C807.334412,638.055054 805.782898,641.288818 806.797607,642.803528 
	C810.674805,648.591492 815.254639,641.930786 820.190796,642.671692 
	C820.190796,645.254883 820.190796,647.707458 820.190796,650.160095 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M807.972595,634.906128 
	C805.374023,634.688416 802.379395,634.980286 802.726929,630.494568 
	C802.803772,629.705933 802.950195,629.305542 803.358643,628.505981 
	C803.620789,628.106812 803.910522,627.825012 803.910522,627.825012 
	C804.955688,628.036743 806.173035,627.977844 807.012817,628.512268 
	C809.297424,629.966187 811.247559,630.299133 813.004150,627.390625 
	C814.711121,628.029846 816.316040,628.992981 817.652100,630.524048 
	C816.292419,632.057068 815.201721,633.022095 813.978821,634.104004 
	C812.589478,633.131897 811.394409,632.295715 809.884460,631.343140 
	C808.300293,631.383179 807.030945,631.539612 805.761597,631.695984 
	C805.799866,632.026367 805.838196,632.356689 805.876465,632.687073 
	C806.806763,632.748962 807.737000,632.810913 808.444397,633.188477 
	C808.125061,633.988464 808.028625,634.472900 807.962830,634.960693 
	C807.993530,634.964172 807.972595,634.906128 807.972595,634.906128 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M803.903320,627.456482 
	C803.910522,627.825012 803.620789,628.106812 803.440125,628.196899 
	C801.295593,628.189697 799.245056,628.405945 797.395752,627.894531 
	C795.953064,627.495483 794.760498,626.192017 793.456787,625.290466 
	C793.730103,624.972778 794.003418,624.655151 794.276794,624.337463 
	C796.732178,624.459290 799.221497,624.386841 801.624878,624.806458 
	C802.488403,624.957214 803.145020,626.292969 803.903320,627.456482 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M783.229736,704.871948 
	C777.086487,704.819946 771.204956,704.427795 764.531189,704.048950 
	C763.498352,704.039612 763.257751,704.017029 762.717163,703.855835 
	C762.132202,701.592468 761.847290,699.467712 761.696472,696.919067 
	C761.862976,695.308716 761.895325,694.122192 761.927612,692.935730 
	C762.360352,692.858459 762.793030,692.781189 763.546631,692.620117 
	C769.023804,693.683472 772.754761,690.912048 776.777832,687.863647 
	C778.989929,690.161560 780.489380,694.109497 782.781006,694.640747 
	C787.286011,695.685303 792.295898,695.277283 796.970764,694.599548 
	C799.913696,694.172913 804.452637,683.281494 804.199341,679.398804 
	C804.925842,678.892029 805.251770,678.822266 805.902832,678.838318 
	C808.132507,679.724060 810.037109,680.523987 812.233887,681.500610 
	C814.292786,681.383728 816.059448,681.090149 817.826172,680.796631 
	C822.447815,683.919495 820.476196,688.440308 820.942993,693.591980 
	C818.326172,692.720032 816.596191,691.775391 814.801025,691.628906 
	C811.871948,691.389893 808.550476,690.851013 806.028625,691.926025 
	C802.251465,693.536133 802.302856,696.295044 805.056946,699.422607 
	C805.908325,700.389404 806.267090,701.789978 806.851318,702.992004 
	C805.657227,703.339355 804.473145,703.944275 803.267395,703.991150 
	C796.678406,704.247009 790.084045,704.365967 783.229736,704.871948 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M798.058838,690.188782 
	C795.479248,691.721863 793.075928,692.905945 791.137024,693.861267 
	C791.137024,690.450684 791.137024,687.984741 791.137024,685.518860 
	C790.069458,685.572021 788.269958,685.306458 788.080383,685.741882 
	C787.627747,686.781372 787.934326,688.151489 787.934326,689.388062 
	C788.434143,689.625977 788.933899,689.863831 789.433716,690.101685 
	C787.467590,691.096313 784.400574,693.408875 783.731750,692.850220 
	C781.246704,690.774536 779.550598,687.754211 777.558228,685.088623 
	C777.842957,684.656433 778.127625,684.224304 778.412354,683.792114 
	C785.853088,683.792114 793.293823,683.792114 801.457947,683.792114 
	C800.315002,685.936890 799.275024,687.888306 798.058838,690.188782 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M796.703247,672.995605 
	C797.802979,673.965515 798.596191,674.938477 799.679565,675.958069 
	C799.969788,676.004700 799.979187,675.959717 799.971863,676.294434 
	C800.405945,677.400391 800.847351,678.171509 801.288696,678.942627 
	C800.463806,679.710632 799.666992,681.106018 798.809509,681.144348 
	C793.403320,681.385986 787.983582,681.326294 782.146118,681.263672 
	C782.185303,680.170166 782.646729,679.179138 783.548218,678.506714 
	C788.486938,680.703430 791.096008,679.268677 794.560730,673.011230 
	C795.172791,673.007019 795.784790,673.002808 796.703247,672.995605 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M805.577698,678.752441 
	C805.251770,678.822266 804.925842,678.892029 804.109985,679.068848 
	C803.360596,679.141968 803.116394,679.061523 802.576538,678.578735 
	C801.503479,677.468445 800.741333,676.714111 799.979187,675.959717 
	C799.979187,675.959717 799.969788,676.004700 799.988892,676.016174 
	C800.007751,675.277405 800.007568,674.527161 800.395874,673.410950 
	C801.532349,673.023071 802.280273,673.001160 803.149170,673.315186 
	C804.039307,675.351562 804.808472,677.052002 805.577698,678.752441 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M799.971863,676.294434 
	C800.741333,676.714111 801.503479,677.468445 802.381775,678.572754 
	C802.296082,678.920776 802.094177,678.918884 801.590515,678.929810 
	C800.847351,678.171509 800.405945,677.400391 799.971863,676.294434 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M794.068665,672.914917 
	C791.096008,679.268677 788.486938,680.703430 783.889832,678.465332 
	C783.342712,669.475647 789.968506,673.939819 794.068665,672.914917 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M798.932617,671.072266 
	C797.535156,670.381287 796.137634,669.690308 794.390564,668.979980 
	C796.369202,665.613831 799.036377,662.445679 800.924683,658.866638 
	C802.612915,655.666748 800.975464,653.989258 797.355286,653.893494 
	C794.334534,653.813599 791.325256,653.302673 788.256042,652.606445 
	C798.435364,652.207947 808.669556,652.185730 819.433655,652.600281 
	C819.963623,654.614685 819.963623,656.192261 819.963623,658.442200 
	C817.893799,657.852173 816.616516,657.638733 815.466370,657.135071 
	C809.329102,654.447021 806.521301,656.108948 805.970642,662.736450 
	C805.848999,664.201050 805.381958,665.636963 804.901611,667.349976 
	C804.140991,668.734314 803.552673,669.854614 802.561401,670.946289 
	C801.083191,670.969177 800.007874,671.020691 798.932617,671.072266 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M794.987183,609.686035 
	C794.797180,610.001404 794.620422,609.984253 794.178345,609.939819 
	C793.958679,609.188843 794.004456,608.465149 794.050232,607.741394 
	C794.235535,607.711426 794.420776,607.681519 794.606079,607.651550 
	C794.737549,608.218933 794.869080,608.786255 794.987183,609.686035 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M761.742981,692.915527 
	C761.895325,694.122192 761.862976,695.308716 761.444824,696.883301 
	C760.725647,697.202148 760.392334,697.132935 760.058960,697.063721 
	C760.225525,695.833984 760.392151,694.604309 760.875427,693.166016 
	C761.192139,692.957336 761.558350,692.895264 761.742981,692.915527 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M821.855774,635.825623 
	C821.252319,631.801208 823.153259,630.070984 827.047913,630.067810 
	C832.950745,630.063049 838.854553,629.944397 844.755493,630.034424 
	C845.752441,630.049683 846.737061,630.870544 847.901855,631.995850 
	C848.118225,633.098755 848.160217,633.525024 848.133118,634.187744 
	C848.064026,634.424255 847.960938,634.906189 847.597351,634.929810 
	C845.522156,636.093628 843.810547,637.233826 842.044434,638.023560 
	C840.908447,632.799805 838.399109,639.037170 836.978638,637.016724 
	C837.007263,637.008179 836.979431,637.062378 836.933960,636.683594 
	C833.297241,632.531433 830.832275,637.152710 827.846863,637.664734 
	C824.038452,638.318054 823.679138,640.466858 826.898193,643.286560 
	C827.289124,645.480103 827.654297,647.258789 827.766235,649.324158 
	C823.616760,652.275879 823.908447,662.146240 827.630920,665.072632 
	C823.655701,669.534363 824.045166,676.211609 827.837158,680.153442 
	C827.331848,682.281921 827.141174,684.161682 826.590332,686.107056 
	C824.669678,688.362793 823.681213,690.476318 827.027100,692.244507 
	C827.042175,692.477417 827.056519,692.944092 827.056519,692.944092 
	C826.042297,696.369995 825.028137,699.795959 824.013916,703.221863 
	C823.760010,703.281799 823.506165,703.341675 823.252258,703.401611 
	C823.087097,694.774475 822.921997,686.147278 822.740967,676.641113 
	C822.560181,673.031250 822.395325,670.300354 822.389709,667.162598 
	C822.335938,662.128113 822.122925,657.500610 821.825684,652.655518 
	C821.587158,652.124268 821.335022,651.964844 820.989502,651.982788 
	C820.993835,652.005737 821.039612,651.995972 821.039612,651.995972 
	C821.062744,651.550110 821.085876,651.104248 821.050781,650.326538 
	C820.992432,649.994690 820.985474,650.025146 821.184265,649.969482 
	C821.616821,649.744751 821.758728,649.518127 821.868530,648.758789 
	C821.904114,644.130859 821.879944,639.978271 821.855774,635.825623 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M821.909912,652.873047 
	C822.122925,657.500610 822.335938,662.128113 822.146484,666.959778 
	C819.448303,665.674988 817.152649,664.186096 814.903564,662.344360 
	C814.470947,659.195618 813.019348,657.181152 810.099426,658.648071 
	C808.040283,659.682495 806.660522,661.840149 809.434692,664.237305 
	C809.120972,664.664917 808.754639,664.878784 808.417542,665.084656 
	C808.446838,665.076660 808.504822,665.093567 808.173645,665.172058 
	C807.526428,665.813843 807.210449,666.377136 806.592590,666.981873 
	C805.885132,667.044250 805.479553,667.065125 805.073975,667.086060 
	C805.381958,665.636963 805.848999,664.201050 805.970642,662.736450 
	C806.521301,656.108948 809.329102,654.447021 815.466370,657.135071 
	C816.616516,657.638733 817.893799,657.852173 819.963623,658.442200 
	C819.963623,656.192261 819.963623,654.614685 819.889160,652.632629 
	C820.015930,652.173706 820.217102,652.119263 820.728943,652.030396 
	C821.039612,651.995972 820.993835,652.005737 821.093506,652.184082 
	C821.357727,652.637024 821.596680,652.807251 821.909912,652.873047 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M821.612427,635.859375 
	C821.879944,639.978271 821.904114,644.130859 821.687256,648.831360 
	C821.259216,649.570618 821.105652,649.785950 820.985474,650.025146 
	C820.985474,650.025146 820.992432,649.994690 821.008911,649.991821 
	C821.025330,649.988953 820.616516,650.121948 820.403687,650.140991 
	C820.190796,647.707458 820.190796,645.254883 820.190796,642.671692 
	C815.254639,641.930786 810.674805,648.591492 806.797607,642.803528 
	C805.782898,641.288818 807.334412,638.055054 807.839966,635.258423 
	C807.972595,634.906128 807.993530,634.964172 808.380066,634.966797 
	C810.513000,635.648193 812.259338,636.326965 813.585510,637.006409 
	C812.502319,636.991882 811.545654,636.689026 811.228760,637.012756 
	C810.033936,638.233032 809.047180,639.657104 807.982544,641.004944 
	C809.103577,641.659485 810.786743,643.128235 811.239807,642.815369 
	C812.582458,641.888306 813.531494,640.315125 814.426025,638.865906 
	C814.665161,638.478516 814.158386,637.630676 814.414001,636.941772 
	C816.683838,636.545044 818.533813,636.200806 820.383728,635.856506 
	C820.712463,635.860657 821.040894,635.872864 821.612427,635.859375 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M811.213257,650.377319 
	C811.555603,649.514648 812.083679,648.871399 812.957336,648.350098 
	C812.668213,649.180298 812.033569,649.888550 811.213257,650.377319 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M814.457275,650.408569 
	C814.533081,650.362549 814.565369,650.549194 814.565369,650.549194 
	C814.565369,650.549194 814.381409,650.454529 814.457275,650.408569 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M799.002380,671.356201 
	C800.007874,671.020691 801.083191,670.969177 802.580811,671.280701 
	C803.011475,672.088928 803.019775,672.534058 803.028137,672.979248 
	C802.280273,673.001160 801.532349,673.023071 800.411133,673.024414 
	C799.715942,672.549316 799.394043,672.094727 799.002380,671.356201 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M917.761353,659.284668 
	C921.138733,660.544373 924.431519,662.014954 927.562195,663.773865 
	C927.993530,664.016174 927.621704,665.688049 927.621704,666.981567 
	C928.961792,666.981567 930.870178,666.981567 932.984314,667.234131 
	C931.899231,670.714417 934.985168,669.222229 935.950684,670.025452 
	C935.965515,670.018127 935.997803,670.011414 935.666870,669.777588 
	C930.368774,670.565552 933.744202,674.443359 932.784058,676.972412 
	C932.574585,677.162415 932.407410,677.188110 931.810181,677.110596 
	C930.535400,677.072266 929.683716,677.091309 928.499512,677.132324 
	C927.737610,677.064026 927.308167,676.973755 926.878662,676.883545 
	C926.739197,674.348938 926.809082,671.774475 926.337769,669.303162 
	C926.188538,668.520874 924.593811,668.014221 923.261658,666.829102 
	C921.187988,665.839844 919.513123,665.403076 917.838318,664.966309 
	C917.767395,663.173096 917.696472,661.379883 917.761353,659.284668 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M926.444214,676.891846 
	C927.308167,676.973755 927.737610,677.064026 928.549805,677.310303 
	C928.932495,677.466248 928.907593,677.835938 928.492798,677.872803 
	C926.422302,677.977234 924.766602,678.044861 923.110962,678.112549 
	C922.776001,678.444092 922.441101,678.775574 921.737061,679.363770 
	C920.994812,679.532166 920.621582,679.443909 920.248413,679.355713 
	C919.792725,679.257385 919.337097,679.159119 918.532837,679.035278 
	C919.747253,674.428223 923.517151,678.033630 926.444214,676.891846 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M917.846680,665.381104 
	C919.513123,665.403076 921.187988,665.839844 922.925476,666.662354 
	C921.277100,666.630676 919.566101,666.213257 917.846680,665.381104 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M907.071411,681.907471 
	C905.187317,681.139038 903.303223,680.370544 901.153320,679.358521 
	C900.929260,665.432373 900.971008,651.749817 901.033325,637.612183 
	C902.065369,637.106018 903.076904,637.054932 904.462830,637.080933 
	C904.943787,648.417053 904.887634,659.681702 905.272034,670.931274 
	C905.367188,673.715759 906.891785,676.451294 907.898682,679.821411 
	C907.715881,680.924866 907.393677,681.416199 907.071411,681.907471 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M931.924683,691.393799 
	C932.976379,693.238708 933.548645,694.960022 934.330383,697.311279 
	C931.443176,697.311279 929.235535,697.311279 926.588501,697.311279 
	C926.385803,695.713318 926.164551,693.968994 925.773254,691.857666 
	C927.550537,691.417175 929.497925,691.343689 931.924683,691.393799 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M923.246582,678.416321 
	C924.766602,678.044861 926.422302,677.977234 928.879333,677.951660 
	C930.468323,677.974426 931.255859,677.955078 932.330261,678.179810 
	C932.749023,679.921814 932.880981,681.419800 933.067200,683.230835 
	C933.122009,683.967163 933.122620,684.390320 933.123230,684.813477 
	C929.876282,682.782349 926.629272,680.751221 923.246582,678.416321 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M933.400879,684.916748 
	C933.122620,684.390320 933.122009,683.967163 933.354370,683.230225 
	C934.089783,682.651855 934.592285,682.387390 935.423523,682.208130 
	C936.820190,682.549316 937.888062,682.805176 938.955933,683.061096 
	C938.955933,683.061096 939.007263,683.020447 938.965149,683.261597 
	C938.923035,683.502686 939.152100,683.911316 939.119690,684.301270 
	C938.322815,686.801880 938.390503,689.684204 934.869934,689.238831 
	C934.245239,689.035156 933.620605,688.831421 933.470581,688.782532 
	C933.546509,687.408691 933.612488,686.214294 933.400879,684.916748 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M924.473267,689.454224 
	C921.827759,689.409607 919.550354,689.547974 917.385620,689.118652 
	C916.171143,688.877747 915.143066,687.697449 913.988037,686.131775 
	C913.908203,684.802429 913.871460,684.281677 913.834656,683.760986 
	C914.562256,683.614380 915.865173,683.207031 915.934082,683.359070 
	C918.355896,688.701782 923.732300,686.565735 928.877075,688.091980 
	C926.812256,688.768982 925.850403,689.084351 924.473267,689.454224 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M920.162720,679.764587 
	C920.621582,679.443909 920.994812,679.532166 921.640869,679.718689 
	C923.757446,681.270203 925.601074,682.723511 928.178589,684.755371 
	C925.101318,684.755371 922.929443,684.755371 920.077087,684.755371 
	C920.077087,683.258972 920.077087,681.716248 920.162720,679.764587 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M913.506470,683.798950 
	C913.871460,684.281677 913.908203,684.802429 913.919922,685.715088 
	C912.979065,686.765930 912.063354,687.424744 911.088074,688.126404 
	C909.941162,686.452454 908.610474,684.510254 907.175659,682.237793 
	C907.393677,681.416199 907.715881,680.924866 908.086304,680.124329 
	C909.815796,681.155762 911.497009,682.496399 913.506470,683.798950 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M934.911865,689.563354 
	C938.390503,689.684204 938.322815,686.801880 939.342407,684.302490 
	C939.597595,683.913757 940.035339,683.998962 940.035339,683.998962 
	C940.035339,686.478638 940.035339,688.958313 940.035339,691.566284 
	C938.151978,691.566284 936.891968,691.566284 935.324707,691.379395 
	C934.996216,690.757629 934.975037,690.322754 934.911865,689.563354 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M772.012817,558.988037 
	C771.578430,558.860657 771.133972,558.743042 770.689514,558.625366 
	C771.052185,558.610168 771.414795,558.594971 771.882141,558.799927 
	C771.986816,559.020081 772.002747,558.997803 772.012817,558.988037 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M741.009644,570.638123 
	C737.144104,569.514343 733.225037,568.050232 729.152954,566.262329 
	C732.985291,567.391663 736.970642,568.844727 741.009644,570.638123 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M728.965942,565.676758 
	C728.724670,565.771057 728.399658,565.541504 727.862793,565.090149 
	C728.061279,565.029846 728.471802,565.191345 728.965942,565.676758 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M745.085815,576.936890 
	C744.287659,576.907227 743.489502,576.877563 742.294495,576.526367 
	C741.529663,575.229980 741.161743,574.255127 740.793823,573.280212 
	C740.793823,573.280212 741.044373,572.947388 741.167358,572.774780 
	C742.600647,572.743286 743.910950,572.884399 745.621216,573.000732 
	C747.203247,573.807129 748.385437,574.638367 749.781738,575.740845 
	C749.995850,576.012085 750.005859,575.994385 749.632446,576.006714 
	C747.867920,576.325012 746.476868,576.630981 745.085815,576.936890 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M881.870728,618.430176 
	C880.274414,619.081177 878.695190,619.549561 877.086060,619.864990 
	C876.050537,620.068115 874.663818,619.652527 873.966003,620.196228 
	C871.424561,622.176331 869.081543,621.615479 867.568787,619.370605 
	C865.605591,616.457214 868.847473,615.617981 870.382263,614.203796 
	C870.724731,613.888245 871.254639,613.776062 872.313477,613.284668 
	C872.804443,614.280823 873.388123,615.465149 873.971863,616.649536 
	C874.202332,616.167053 874.432800,615.684509 874.663269,615.202026 
	C877.320312,615.013855 879.977417,614.825684 883.146851,614.468384 
	C885.035278,614.248474 886.411255,614.197693 887.787292,614.146912 
	C887.764343,614.386963 887.741394,614.626953 887.642578,615.223999 
	C887.290283,616.343811 887.013916,617.106628 886.895508,617.433289 
	C885.068359,617.705994 883.471741,617.944275 881.870728,618.430176 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M888.000244,613.868835 
	C886.411255,614.197693 885.035278,614.248474 883.360840,614.189636 
	C883.037659,613.611816 883.012939,613.143555 883.385986,612.335205 
	C884.887512,611.995117 885.991272,611.995117 885.669312,611.995117 
	C886.720764,609.710022 887.313782,608.421204 887.906738,607.132385 
	C892.631592,609.065796 890.316956,611.333313 888.000244,613.868835 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M887.517517,606.969849 
	C887.313782,608.421204 886.720764,609.710022 885.669312,611.995117 
	C885.991272,611.995117 884.887512,611.995117 883.424927,611.985107 
	C882.431091,611.219360 881.796082,610.463684 881.052734,609.108887 
	C880.958130,608.281006 880.971802,608.052063 880.985535,607.823120 
	C882.147766,607.742615 883.310059,607.662109 884.542358,607.241577 
	C885.450989,606.870117 886.289612,606.838684 887.517517,606.969849 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M882.095093,670.037109 
	C881.774902,674.454651 881.407410,678.869446 881.155334,683.290833 
	C881.035095,685.399841 881.134583,687.521362 881.134583,691.170654 
	C877.026123,689.251404 873.981567,687.885498 871.009460,686.376953 
	C870.586853,686.162476 870.163208,685.110779 870.335144,684.689636 
	C873.079773,677.967773 867.512756,674.018555 864.447266,669.960388 
	C862.839783,667.832214 857.530945,668.500000 853.418884,667.605835 
	C851.015503,664.019409 849.086792,660.732239 847.158142,657.445007 
	C847.580994,657.011658 848.003906,656.578247 848.426758,656.144897 
	C850.712463,656.144897 853.071960,656.494263 855.260193,656.032471 
	C856.956116,655.674438 859.480896,654.567444 859.796204,653.325867 
	C860.434753,650.811707 860.836792,647.746460 856.786560,646.612671 
	C854.864258,646.074585 853.341187,644.109741 852.044189,642.846375 
	C854.652954,642.508240 857.443359,641.085999 858.933044,641.937988 
	C861.950806,643.664124 863.782166,642.479614 866.198059,641.091064 
	C867.331543,640.522461 868.186890,639.786621 869.021362,639.026062 
	C869.000427,639.001343 869.050598,639.041504 869.457397,639.049377 
	C870.883850,640.148254 872.267456,641.076965 872.842651,642.366272 
	C873.842468,644.607239 874.342957,647.070862 875.070862,649.501587 
	C870.418518,654.949463 876.051331,661.630127 873.472778,667.598267 
	C878.555237,667.908752 880.967529,666.267517 880.555603,660.907593 
	C880.203247,656.322388 880.727966,651.664551 880.943542,647.042480 
	C881.138977,642.851379 883.474182,641.446472 887.635437,641.713867 
	C890.041443,641.868469 892.541992,640.551880 895.000000,639.897339 
	C894.856628,643.767639 894.713257,647.637878 893.921265,651.619263 
	C890.954407,651.533691 888.636108,651.336975 886.017395,651.114807 
	C885.751221,649.299194 885.439636,647.173645 885.128052,645.048096 
	C884.719360,645.094910 884.310608,645.141724 883.901917,645.188538 
	C883.901917,649.101868 883.901917,653.015259 883.901917,656.928589 
	C883.909058,659.634155 883.916260,662.339783 883.630249,665.659424 
	C882.923096,667.528076 882.509094,668.782593 882.095093,670.037109 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M894.995789,639.556274 
	C892.541992,640.551880 890.041443,641.868469 887.635437,641.713867 
	C883.474182,641.446472 881.138977,642.851379 880.943542,647.042480 
	C880.727966,651.664551 880.203247,656.322388 880.555603,660.907593 
	C880.967529,666.267517 878.555237,667.908752 873.472778,667.598267 
	C876.051331,661.630127 870.418518,654.949463 875.070862,649.501587 
	C874.342957,647.070862 873.842468,644.607239 872.842651,642.366272 
	C872.267456,641.076965 870.883850,640.148254 869.428589,638.776367 
	C868.739380,638.213074 868.485840,637.930664 868.232300,637.648315 
	C868.028015,637.873047 867.823730,638.097778 867.619385,638.322510 
	C868.093689,638.565247 868.567932,638.808044 869.042236,639.050781 
	C868.186890,639.786621 867.331543,640.522461 866.187256,640.818787 
	C865.631104,639.950623 865.363831,639.521912 865.078125,638.800537 
	C865.026611,638.315186 864.993469,638.122498 864.933777,637.959473 
	C864.907288,637.989258 864.838501,637.948975 864.838501,637.948975 
	C866.318481,637.197632 867.783569,636.414673 869.283997,635.706726 
	C870.173401,635.287170 871.200867,635.119141 872.026062,634.610596 
	C878.775330,630.450806 879.438232,630.642456 883.073547,639.208313 
	C883.073547,635.578857 883.073547,633.262756 883.102417,630.518982 
	C884.173279,631.015259 885.215210,631.939209 886.691772,632.994263 
	C887.126465,633.125488 887.330811,633.337708 887.229736,633.716919 
	C887.561218,635.905640 887.808167,637.799683 888.564392,639.462036 
	C888.740784,639.849792 891.307983,639.854187 891.625732,639.333557 
	C892.397461,638.068848 892.607422,636.406067 892.800110,634.873047 
	C892.867188,634.339905 892.218506,633.716797 891.935303,633.085938 
	C891.973877,633.037109 892.052795,632.940796 892.052795,632.940796 
	C893.395752,633.722229 894.738647,634.503662 896.190796,635.348694 
	C895.793213,636.630737 895.392395,637.922974 894.995789,639.556274 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M895.891235,678.364990 
	C889.756226,678.676331 888.265442,684.336121 885.085876,688.441345 
	C882.877258,680.503662 883.690369,675.641296 887.156921,673.810486 
	C886.181152,672.766846 885.207214,671.721680 884.434875,670.068420 
	C888.081421,668.982605 891.526428,668.504822 894.971375,668.027100 
	C895.288147,671.340454 895.604919,674.653809 895.891235,678.364990 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M884.044861,657.234375 
	C883.901917,653.015259 883.901917,649.101868 883.901917,645.188538 
	C884.310608,645.141724 884.719360,645.094910 885.128052,645.048096 
	C885.439636,647.173645 885.751221,649.299194 886.017395,651.114807 
	C888.636108,651.336975 890.954407,651.533691 893.609497,651.904175 
	C893.984924,652.485657 894.023438,652.893494 893.976501,653.705078 
	C893.890991,654.108826 893.684631,654.304565 893.342957,654.167664 
	C887.649414,652.077026 886.718689,653.001953 887.155640,659.842468 
	C887.090027,659.909851 886.960571,660.046265 886.960571,660.046265 
	C886.036316,659.210876 885.112061,658.375488 884.044861,657.234375 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M895.313354,667.953369 
	C891.526428,668.504822 888.081421,668.982605 884.369019,669.730103 
	C883.655029,670.005249 883.208435,670.010498 882.428467,670.026428 
	C882.509094,668.782593 882.923096,667.528076 883.601929,666.052795 
	C887.530334,665.886475 891.195862,665.897827 894.853333,666.076294 
	C895.149292,666.090759 895.389343,667.251526 895.313354,667.953369 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M894.053223,659.045105 
	C894.785645,660.418823 895.518127,661.792603 896.347168,663.347534 
	C892.479736,666.057739 889.944580,663.116943 887.126404,660.338196 
	C886.960571,660.046265 887.090027,659.909851 887.526184,659.871216 
	C889.902405,659.487732 891.842529,659.143005 893.849976,658.860229 
	C893.917358,658.922241 894.053223,659.045105 894.053223,659.045105 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M893.995361,654.012207 
	C895.275269,655.390381 896.450745,656.865112 898.248779,659.120972 
	C895.968384,658.943054 895.260925,658.887817 894.303345,658.938843 
	C894.053223,659.045105 893.917358,658.922241 893.889893,658.487061 
	C893.803223,656.802795 893.743958,655.553711 893.684631,654.304565 
	C893.684631,654.304565 893.890991,654.108826 893.995361,654.012207 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M887.025635,633.018066 
	C888.590088,632.695557 890.255249,632.480408 891.986633,632.603027 
	C892.052795,632.940796 891.973877,633.037109 891.550964,633.046997 
	C889.862305,633.150452 888.596558,633.244080 887.330811,633.337708 
	C887.330811,633.337708 887.126465,633.125488 887.025635,633.018066 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M865.096558,639.093201 
	C865.363831,639.521912 865.631104,639.950623 865.909180,640.651611 
	C863.782166,642.479614 861.950806,643.664124 858.933044,641.937988 
	C857.443359,641.085999 854.652954,642.508240 852.013794,642.886108 
	C851.576477,642.870850 851.554260,642.973816 851.383301,642.668396 
	C850.306702,642.569275 849.401062,642.775574 848.393677,642.896851 
	C848.291870,642.811768 848.059082,642.938721 847.656738,642.954163 
	C845.536438,642.499329 843.818359,642.029114 842.124939,641.240967 
	C842.132690,640.073364 842.115845,639.223694 842.098999,638.374023 
	C843.810547,637.233826 845.522156,636.093628 847.837708,635.217041 
	C850.294373,635.323486 852.147095,635.166321 853.999817,635.009094 
	C854.618225,634.811646 855.236572,634.614258 856.423584,634.193909 
	C857.697327,633.997131 858.402405,634.023315 859.107483,634.049500 
	C858.778076,634.836365 858.448669,635.623291 858.189392,636.242676 
	C860.292297,638.308044 863.148071,631.746887 864.673584,637.622131 
	C864.838501,637.948975 864.907288,637.989258 864.585083,637.721924 
	C863.132996,637.916382 862.002991,638.378113 859.849792,639.257996 
	C861.583740,639.919861 862.064758,640.296570 862.412842,640.199036 
	C863.338074,639.939758 864.205872,639.475769 865.096558,639.093201 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M880.785339,607.573669 
	C880.971802,608.052063 880.958130,608.281006 880.900879,608.852234 
	C871.576965,604.712585 862.296631,600.230591 852.990112,595.394409 
	C853.707642,595.037903 854.451294,595.035583 855.564453,595.064331 
	C857.377380,595.733276 858.820984,596.371033 860.616699,597.025391 
	C864.054138,598.702026 867.139282,600.362122 870.596802,602.034058 
	C872.088013,602.678345 873.206970,603.310913 874.655457,603.982178 
	C876.851685,605.122009 878.718445,606.223145 880.785339,607.573669 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M859.401001,633.945679 
	C858.402405,634.023315 857.697327,633.997131 856.644043,633.953491 
	C855.251709,626.054504 855.251709,626.054504 861.675537,625.775391 
	C862.687012,626.627747 863.249695,627.515442 863.737793,628.930847 
	C863.408386,629.937439 863.153442,630.416199 862.898499,630.894958 
	C861.830505,631.877258 860.762512,632.859558 859.401001,633.945679 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M863.264526,630.972961 
	C863.153442,630.416199 863.408386,629.937439 863.820435,629.235901 
	C866.326599,628.351074 868.675659,627.688904 871.675171,626.843384 
	C871.751709,628.351013 871.856384,630.413513 871.962524,632.504150 
	C868.813232,631.954834 866.221924,631.502930 863.264526,630.972961 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M851.101196,594.318054 
	C851.539551,594.193054 852.107544,594.351868 852.827148,594.774048 
	C852.396057,594.892212 851.813538,594.747070 851.101196,594.318054 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M740.470093,573.258789 
	C741.161743,574.255127 741.529663,575.229980 741.894043,576.529724 
	C741.507690,578.237549 741.125000,579.620483 740.641052,581.369507 
	C738.965271,581.142822 737.088623,580.888916 735.018372,580.608887 
	C736.164124,579.724243 737.171631,578.946350 737.385254,578.781494 
	C736.957703,576.869934 736.670837,575.587585 736.659302,574.119629 
	C738.005188,573.701782 739.075745,573.469543 740.470093,573.258789 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M745.396484,577.052246 
	C746.476868,576.630981 747.867920,576.325012 749.639648,575.999268 
	C749.738281,577.165466 749.456177,578.351379 748.899292,579.586914 
	C747.652100,578.813477 746.679626,577.990540 745.396484,577.052246 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M683.883179,595.983948 
	C683.490051,596.008606 683.096863,596.033325 682.411743,596.112305 
	C678.497803,595.589783 679.111450,592.794434 679.086792,590.349670 
	C678.959961,577.786621 678.841858,565.223511 678.450439,552.359619 
	C678.937683,549.940125 679.695251,547.821533 680.498169,545.312927 
	C680.543579,544.922974 680.513000,544.966492 680.498108,544.943359 
	C680.655701,545.996826 680.828186,547.073364 680.831909,548.708252 
	C683.678772,551.447937 683.007751,554.674805 683.023560,557.688782 
	C683.045105,561.793335 683.011780,565.898132 682.934570,570.367554 
	C683.305603,572.135315 683.744080,573.538391 683.863647,575.220459 
	C683.700867,580.333618 683.857056,585.167786 683.935425,590.353271 
	C683.866211,592.464417 683.874695,594.224182 683.883179,595.983948 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M683.002014,570.002930 
	C683.011780,565.898132 683.045105,561.793335 683.023560,557.688782 
	C683.007751,554.674805 683.678772,551.447937 680.918640,549.108154 
	C683.220581,548.191895 686.505127,550.773560 687.353394,546.251099 
	C688.374390,545.894531 689.354675,545.960876 690.705200,546.063477 
	C690.153748,547.556091 689.232178,549.012390 688.141968,550.742188 
	C687.278748,551.795227 686.021484,552.546631 685.980957,553.358765 
	C685.712524,558.734131 685.705261,564.122681 685.186035,569.865356 
	C684.173401,570.150208 683.587708,570.076538 683.002014,570.002930 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M682.934570,570.367615 
	C683.587708,570.076538 684.173401,570.150208 685.030029,570.339355 
	C686.643921,571.625793 687.986816,572.796692 688.848145,573.547729 
	C693.053650,573.547729 696.868774,573.547729 700.981689,573.547729 
	C702.258179,572.479004 704.025330,570.999390 705.792480,569.519836 
	C706.337036,569.783264 706.881531,570.046692 707.426025,570.310181 
	C702.294678,574.648193 705.109619,580.408875 703.249023,585.701050 
	C701.864685,585.983093 700.774719,585.949158 698.960083,585.965210 
	C697.605591,586.001099 696.975769,585.986877 696.345886,585.972778 
	C695.333618,586.480530 694.321289,586.988220 693.248474,587.526245 
	C693.232178,587.409607 693.152100,586.837341 693.074341,585.616150 
	C694.150391,580.630554 693.952820,580.352539 689.468689,579.830139 
	C689.675781,581.738525 689.883972,583.656494 690.092163,585.574463 
	C689.562073,585.937744 689.032043,586.301025 687.933472,586.609863 
	C687.125244,583.064575 686.885498,579.573853 686.591431,575.292053 
	C686.547852,575.285706 685.365234,575.113586 684.182617,574.941406 
	C683.744080,573.538391 683.305603,572.135315 682.934570,570.367615 
M694.703918,577.053406 
	C696.166138,578.198914 697.628296,579.344482 699.090515,580.489990 
	C699.434204,580.246216 699.777954,580.002502 700.121643,579.758728 
	C699.306763,578.624390 698.712708,577.063782 697.606995,576.490845 
	C696.724731,576.033752 695.218689,576.780396 693.511719,577.226990 
	C693.236084,577.577637 692.960449,577.928284 692.684753,578.278870 
	C692.895264,578.385498 693.105835,578.492188 693.316345,578.598816 
	C693.547668,578.069397 693.778931,577.540039 694.703918,577.053406 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1538.800293,841.575439 
	C1539.013916,841.218933 1539.228516,841.172424 1539.744385,841.060791 
	C1540.045776,840.995605 1540.020752,840.951355 1540.137695,841.244019 
	C1540.853027,841.685364 1541.451294,841.834106 1542.024292,841.995117 
	C1541.998901,842.007324 1541.987427,841.952087 1542.210693,842.238403 
	C1544.103516,843.398926 1545.773071,844.273193 1547.601685,845.230774 
	C1547.720093,844.201416 1547.849731,843.076172 1547.979370,841.975220 
	C1547.979492,841.999512 1547.938843,841.970764 1547.938843,841.970825 
	C1548.720703,841.711853 1549.502441,841.452881 1550.573486,841.065369 
	C1551.434570,842.101746 1552.006226,843.266663 1552.431152,844.132385 
	C1555.156982,843.480530 1557.773804,842.854736 1560.390625,842.228943 
	C1560.493286,842.649109 1560.596069,843.069214 1560.698730,843.489380 
	C1559.740112,844.746155 1558.781372,846.002930 1557.575684,847.539124 
	C1555.946045,847.563293 1554.563354,847.308044 1553.053955,847.029358 
	C1553.053955,848.289185 1553.053955,849.562561 1553.053955,850.835815 
	C1551.305176,851.648804 1549.556519,852.461731 1547.557861,853.390869 
	C1547.557861,852.144531 1547.557861,850.923401 1547.557861,848.962463 
	C1544.694702,851.675720 1541.314209,847.443481 1538.657471,850.568115 
	C1537.750366,849.756287 1536.442261,849.107178 1536.031250,848.094177 
	C1535.430176,846.612610 1535.472290,844.870056 1535.221924,843.109253 
	C1536.399292,842.706665 1537.600220,842.295959 1538.800293,841.575439 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1553.506958,850.950195 
	C1553.053955,849.562561 1553.053955,848.289185 1553.053955,847.029358 
	C1554.563354,847.308044 1555.946045,847.563293 1557.545776,847.919678 
	C1560.374268,849.180725 1561.945312,848.382507 1562.012207,845.489197 
	C1562.088745,842.177795 1563.784546,841.683167 1566.511230,842.355103 
	C1565.476318,844.350281 1564.514648,846.204041 1563.458008,848.241028 
	C1566.564575,848.444824 1569.150635,848.614441 1571.736572,848.784119 
	C1571.731323,849.544250 1571.726074,850.304321 1571.720825,851.064453 
	C1565.800537,851.064453 1559.880249,851.064453 1553.506958,850.950195 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1613.004517,852.188354 
	C1611.738770,851.688538 1610.473145,851.188721 1608.747559,850.507324 
	C1607.595215,850.975769 1605.600586,851.786560 1603.274780,852.302551 
	C1604.783813,849.471924 1606.624268,846.936096 1609.089844,844.093445 
	C1610.142090,842.510193 1610.568848,841.233765 1611.093750,840.311951 
	C1611.295654,840.888794 1611.399414,841.111084 1611.509521,841.751709 
	C1611.997314,844.411743 1609.160889,847.428833 1613.226196,849.160217 
	C1613.350586,849.888367 1613.329834,850.322998 1613.228027,851.114258 
	C1613.099365,851.710022 1613.052002,851.949158 1613.004517,852.188354 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1610.995605,839.957336 
	C1610.568848,841.233765 1610.142090,842.510193 1609.351929,843.871826 
	C1607.550049,840.678406 1606.111450,837.399719 1604.320068,834.051392 
	C1605.033813,833.287415 1606.100098,832.593262 1607.551758,831.887085 
	C1608.806030,834.178406 1609.674805,836.481750 1610.791260,839.157959 
	C1611.039062,839.530762 1610.995605,839.957336 1610.995605,839.957336 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1460.160889,842.162231 
	C1462.770142,840.071777 1465.373169,839.060608 1467.585938,842.323975 
	C1469.582520,841.432800 1471.231079,840.696960 1472.879761,839.961060 
	C1473.307983,840.521912 1473.736328,841.082764 1474.164551,841.643616 
	C1472.337769,842.451599 1470.510986,843.259644 1468.684204,844.067627 
	C1469.150269,844.562988 1469.616455,845.058350 1470.082520,845.553711 
	C1473.965698,846.128540 1475.505859,843.575928 1476.365356,839.358154 
	C1479.897705,840.214722 1483.118164,840.995605 1487.185425,841.981750 
	C1487.394409,841.821411 1488.504272,840.969604 1490.118164,839.731140 
	C1490.118164,842.257385 1490.118164,844.147339 1490.118164,845.160828 
	C1487.682617,845.568726 1485.391602,845.952393 1482.603516,846.419250 
	C1481.562866,845.217529 1480.337280,842.505676 1477.329468,845.992554 
	C1476.115723,847.399780 1472.881592,848.792053 1471.655518,848.140991 
	C1467.788940,846.087708 1464.343994,848.665710 1460.334473,847.709656 
	C1459.994751,845.547119 1460.077759,843.854675 1460.160889,842.162231 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1454.828125,847.898315 
	C1452.952148,848.044067 1451.076050,848.189880 1449.200073,848.335632 
	C1449.070557,847.958374 1448.941162,847.581116 1448.811646,847.203796 
	C1450.226440,846.107239 1451.641235,845.010681 1453.610962,843.483948 
	C1451.856323,842.905334 1450.676758,842.516357 1449.497192,842.127380 
	C1449.486084,841.780029 1449.474854,841.432678 1449.463745,841.085327 
	C1450.928467,840.768677 1452.393066,840.452087 1454.364990,840.636292 
	C1454.857544,843.390808 1454.842896,845.644592 1454.828125,847.898315 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1455.272461,847.929077 
	C1454.842896,845.644592 1454.857544,843.390808 1454.708618,840.750427 
	C1456.200195,840.815063 1457.855347,841.266418 1459.835693,841.939941 
	C1460.077759,843.854675 1459.994751,845.547119 1460.035889,847.561951 
	C1458.678955,847.909485 1457.197754,847.934692 1455.272461,847.929077 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1445.259644,840.896973 
	C1445.453979,840.087463 1445.932495,839.122864 1446.735352,838.056885 
	C1446.554565,838.884338 1446.049194,839.813171 1445.259644,840.896973 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1444.174927,842.836975 
	C1444.160767,842.372070 1444.312378,841.656921 1444.754883,840.957764 
	C1444.810669,841.511475 1444.575684,842.049133 1444.174927,842.836975 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1512.438477,848.565552 
	C1512.399048,848.493652 1512.477905,848.637390 1512.438477,848.565552 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1611.093750,840.311951 
	C1610.995605,839.957336 1611.039062,839.530762 1611.120361,839.332581 
	C1612.359497,839.112671 1613.517212,839.077881 1614.675171,839.071411 
	C1622.399414,839.028320 1622.031250,839.076050 1622.964111,846.578491 
	C1623.602417,851.713806 1621.801392,853.291321 1617.090210,852.783691 
	C1615.945801,852.660339 1614.783813,852.700684 1613.317139,852.427002 
	C1613.052002,851.949158 1613.099365,851.710022 1613.639160,851.163818 
	C1621.129272,850.986389 1621.689575,850.321228 1620.309570,843.493225 
	C1619.536865,843.664734 1618.764404,843.836060 1617.908691,843.658936 
	C1617.535400,842.531555 1617.285156,841.102661 1616.949097,841.082214 
	C1615.145508,840.972473 1613.320801,841.213440 1611.503296,841.333313 
	C1611.399414,841.111084 1611.295654,840.888794 1611.093750,840.311951 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1432.972412,848.869019 
	C1432.111084,849.518494 1431.283813,850.171631 1430.237427,850.997742 
	C1429.938110,843.153931 1429.938110,843.153931 1437.124390,843.264709 
	C1435.488892,845.190918 1434.227783,846.676147 1432.956543,848.489441 
	C1432.946533,848.817505 1433.006592,848.872742 1432.972412,848.869019 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1489.413696,865.199219 
	C1490.936279,864.932861 1492.134644,864.730957 1493.332886,864.528992 
	C1493.377319,865.010254 1493.421753,865.491516 1493.466187,865.972778 
	C1491.834961,865.972778 1490.203735,865.972778 1488.572510,865.972778 
	C1488.617432,865.834351 1488.632446,865.673523 1488.714966,865.563354 
	C1488.807861,865.439575 1488.961914,865.361572 1489.413696,865.199219 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1585.046875,846.657593 
	C1584.572998,847.425232 1584.106445,847.865967 1583.639893,848.306702 
	C1583.320312,847.017883 1582.932007,845.738098 1582.761353,844.429932 
	C1582.737915,844.250671 1583.797485,843.930115 1584.355591,843.674988 
	C1584.588501,844.560181 1584.821289,845.445435 1585.046875,846.657593 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1507.031006,865.861572 
	C1506.968750,866.852661 1506.889404,867.483459 1506.810059,868.114258 
	C1506.640869,868.107849 1506.471558,868.101501 1506.302246,868.095093 
	C1506.302246,866.408997 1506.302246,864.722839 1506.302246,863.036743 
	C1506.428589,863.016602 1506.554932,862.996521 1506.681152,862.976379 
	C1506.792114,863.817993 1506.902954,864.659668 1507.031006,865.861572 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1273.277588,775.362427 
	C1273.724976,779.364136 1273.832886,783.214539 1274.031250,787.526184 
	C1272.842163,788.202271 1271.567627,788.565735 1270.282593,788.607300 
	C1263.442261,788.828796 1263.441284,788.800842 1263.357910,782.036438 
	C1263.271484,775.034973 1263.271484,775.031799 1270.457031,775.096558 
	C1271.284424,775.104004 1272.111206,775.171448 1273.277588,775.362427 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1255.242920,752.840027 
	C1256.117065,751.956787 1257.350586,751.137085 1258.808350,750.683472 
	C1260.850708,756.744202 1267.225586,759.462036 1272.923950,756.485291 
	C1275.410034,755.186584 1280.188354,755.380920 1278.828613,750.219727 
	C1279.694336,750.235291 1280.560181,750.250854 1281.797974,750.715088 
	C1282.108032,752.118652 1282.046021,753.073425 1281.984009,754.028259 
	C1281.984009,754.028259 1282.000610,754.001160 1282.010254,753.988403 
	C1279.040039,756.129395 1276.060181,758.283142 1272.621582,760.580811 
	C1271.144287,760.480957 1270.125854,760.237244 1269.107300,759.993530 
	C1266.188477,758.145386 1264.141724,765.373596 1261.001465,760.797241 
	C1259.277710,758.285156 1257.696533,755.675354 1256.037476,753.118469 
	C1255.961914,753.001770 1255.750366,752.973145 1255.242920,752.840027 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1241.186523,779.679932 
	C1237.079956,777.373413 1241.309448,775.550659 1241.888428,773.012329 
	C1244.401611,774.550415 1246.788452,776.433105 1249.175171,778.315857 
	C1248.795044,778.661438 1248.414795,779.007080 1248.034668,779.352661 
	C1245.777832,779.352661 1243.521118,779.352661 1241.186523,779.679932 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1268.966064,760.331909 
	C1270.125854,760.237244 1271.144287,760.480957 1272.438232,760.902100 
	C1272.813721,761.885010 1273.282593,762.936829 1272.941528,763.448059 
	C1272.074951,764.746887 1270.948853,765.948853 1269.684692,766.850159 
	C1269.387939,767.061768 1267.401245,765.846313 1267.448608,765.430481 
	C1267.632935,763.812561 1268.322876,762.252136 1268.966064,760.331909 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1245.328979,764.444763 
	C1245.444702,763.479431 1245.896484,762.758667 1246.704834,762.016968 
	C1251.213013,762.494507 1255.364258,762.992981 1259.515625,763.491455 
	C1259.543579,763.890747 1259.571655,764.289978 1259.599731,764.689270 
	C1254.954712,764.689270 1250.309814,764.689270 1245.328979,764.444763 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1226.877075,782.947266 
	C1226.111938,784.663147 1225.577271,787.674316 1224.539673,787.858887 
	C1221.035034,788.482361 1217.344360,788.215820 1213.745850,787.974854 
	C1213.254883,787.942017 1212.548828,786.132935 1212.518799,785.129028 
	C1212.425537,782.002258 1212.609375,778.867126 1213.104004,775.221375 
	C1213.945068,774.623352 1214.367798,774.539307 1215.206055,774.636841 
	C1217.107300,774.832825 1218.592896,774.847168 1220.457886,774.907104 
	C1221.586548,775.073914 1222.335693,775.195068 1223.086914,775.680908 
	C1224.351685,778.346130 1225.614380,780.646667 1226.877075,782.947266 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1193.308105,785.810059 
	C1192.826782,785.257385 1192.691895,784.662415 1192.530884,783.824585 
	C1193.056519,782.900818 1193.608276,782.220032 1194.159912,781.539185 
	C1193.438354,781.371521 1192.716919,781.203918 1191.995361,781.036255 
	C1191.986084,780.650024 1191.976685,780.263733 1191.955811,779.588501 
	C1196.355469,779.289856 1201.124512,776.639038 1204.946777,781.450378 
	C1206.286743,780.591248 1207.459717,779.839111 1209.202759,778.721497 
	C1209.202759,781.280273 1209.202759,783.278259 1209.202759,785.050964 
	C1206.925415,785.227783 1204.681519,785.401978 1201.709351,785.632690 
	C1200.827881,783.839722 1199.405029,780.647278 1196.564331,785.495117 
	C1196.276733,785.985901 1194.656372,785.695557 1193.308105,785.810059 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1210.131104,775.042114 
	C1209.024048,775.061768 1207.917114,775.081421 1206.419922,774.625488 
	C1205.936157,772.083252 1205.842529,770.016663 1206.047363,767.633667 
	C1209.712280,767.317261 1213.078735,767.317261 1215.336060,767.317261 
	C1215.202759,769.281494 1215.110718,770.635803 1214.639893,771.985107 
	C1212.521118,771.001160 1210.781128,770.022217 1209.041260,769.043335 
	C1208.614014,769.283081 1208.186768,769.522827 1207.759521,769.762573 
	C1208.550049,771.522461 1209.340576,773.282288 1210.131104,775.042114 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1227.067383,782.727661 
	C1225.614380,780.646667 1224.351685,778.346130 1223.426270,775.627258 
	C1224.703735,775.785706 1225.643799,776.362488 1225.661499,776.373413 
	C1227.905396,775.579041 1229.458984,775.028992 1231.012573,774.479004 
	C1231.029053,774.883789 1231.045532,775.288635 1231.160645,775.984375 
	C1230.934204,777.009216 1230.609009,777.743164 1229.786743,778.762939 
	C1228.845581,779.670288 1228.401611,780.291870 1227.957642,780.913452 
	C1227.724243,781.444946 1227.490967,781.976501 1227.067383,782.727661 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1205.748779,767.950134 
	C1205.842529,770.016663 1205.936157,772.083252 1206.005371,774.595520 
	C1205.080933,775.166138 1204.180786,775.291016 1203.110352,775.145569 
	C1202.724976,773.109436 1202.510132,771.343567 1202.275513,769.006409 
	C1203.420044,768.273438 1204.584473,768.111816 1205.748779,767.950134 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1210.357910,775.027710 
	C1209.340576,773.282288 1208.550049,771.522461 1207.759521,769.762573 
	C1208.186768,769.522827 1208.614014,769.283081 1209.041260,769.043335 
	C1210.781128,770.022217 1212.521118,771.001160 1214.630371,771.989441 
	C1214.999634,771.998840 1215.008423,771.980591 1214.989746,772.292297 
	C1214.910889,773.221069 1214.850708,773.838135 1214.790527,774.455200 
	C1214.367798,774.539307 1213.945068,774.623352 1213.220459,774.825195 
	C1212.918701,774.942993 1212.965576,774.949768 1212.995117,774.663696 
	C1212.595215,774.055176 1212.165894,773.732727 1211.736572,773.410278 
	C1211.503540,773.933594 1211.270630,774.456970 1211.037842,774.980347 
	C1211.037842,774.980347 1210.584595,775.013367 1210.357910,775.027710 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1211.358643,775.007202 
	C1211.270630,774.456970 1211.503540,773.933594 1211.736572,773.410278 
	C1212.165894,773.732727 1212.595215,774.055176 1212.988281,774.683655 
	C1212.527954,775.004517 1212.103638,775.019287 1211.358643,775.007202 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1228.211670,780.771301 
	C1228.401611,780.291870 1228.845581,779.670288 1229.600098,779.004333 
	C1229.428955,779.516357 1228.947388,780.072754 1228.211670,780.771301 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1191.678711,781.196045 
	C1192.716919,781.203918 1193.438354,781.371521 1194.159912,781.539185 
	C1193.608276,782.220032 1193.056519,782.900818 1192.286133,783.804871 
	C1191.099365,784.028076 1190.131226,784.028076 1187.907959,784.028076 
	C1189.736084,782.613708 1190.549072,781.984802 1191.678711,781.196045 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1855.015991,824.584106 
	C1850.354248,829.679443 1845.598022,834.693481 1841.092529,839.923401 
	C1839.867065,841.345886 1839.396973,843.418945 1838.414795,845.552490 
	C1832.663818,844.459351 1831.806396,851.182800 1828.238770,854.950500 
	C1846.545044,854.950500 1864.851318,854.950500 1883.157593,854.950500 
	C1883.188599,855.536377 1883.219482,856.122253 1883.250488,856.708069 
	C1881.081543,856.934509 1878.845947,856.910889 1876.764160,857.464783 
	C1875.034546,857.925049 1873.485718,859.064636 1872.534302,859.554504 
	C1869.343018,858.843506 1866.366943,857.624634 1863.384644,857.608521 
	C1847.236572,857.521667 1831.086670,857.743042 1814.937378,857.822815 
	C1813.415527,857.830322 1811.892334,857.591919 1810.314819,856.833069 
	C1816.248535,853.897522 1824.906372,858.415283 1828.180542,849.349731 
	C1827.985229,848.995789 1827.981201,849.044250 1827.981201,849.044250 
	C1830.425293,847.173462 1832.869385,845.302612 1836.407837,842.594116 
	C1833.619019,841.998596 1832.404663,841.739258 1829.908081,841.206055 
	C1832.046509,840.174255 1833.911377,838.847900 1834.263428,839.170898 
	C1837.590820,842.222473 1838.905396,838.894592 1840.635376,837.306030 
	C1844.896484,833.393005 1849.054199,829.367249 1853.253540,825.386841 
	C1853.043945,824.980896 1852.834473,824.574951 1852.625000,824.169067 
	C1849.701538,823.981079 1846.778198,823.793152 1842.703125,823.531128 
	C1847.360962,820.378601 1850.848877,821.467346 1854.733643,823.405029 
	C1855.088135,824.104797 1855.052124,824.344421 1855.015991,824.584106 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1874.396851,766.381287 
	C1875.524658,765.045044 1877.012695,763.605774 1878.821045,762.024414 
	C1879.616211,762.302734 1880.090698,762.723083 1881.730225,764.175598 
	C1881.730225,758.902954 1881.819824,754.620483 1881.602783,750.353638 
	C1881.578979,749.885254 1879.794800,749.506348 1878.402954,748.735046 
	C1877.973877,748.179138 1877.968994,747.974060 1878.352173,747.821167 
	C1880.126465,747.873352 1881.512817,747.873352 1882.850464,747.873352 
	C1883.057495,751.747559 1883.238403,755.133240 1883.419312,758.518921 
	C1883.922729,758.489502 1884.426025,758.460022 1884.929443,758.430603 
	C1884.929443,753.992004 1884.929443,749.553467 1885.216064,745.104126 
	C1885.693970,745.088806 1885.885132,745.084167 1886.060303,745.542114 
	C1886.033447,755.335022 1885.971313,764.665833 1886.044556,773.995605 
	C1886.065308,776.640442 1885.591187,779.879089 1886.886841,781.810486 
	C1890.403198,787.052490 1889.124390,792.527771 1888.689941,798.014282 
	C1888.221313,798.010742 1888.033813,798.012268 1887.846436,798.013855 
	C1887.420410,795.281921 1886.994385,792.550049 1886.563232,789.785339 
	C1878.588623,787.552246 1878.041870,786.369629 1878.642456,775.349915 
	C1878.832397,771.863831 1880.579102,767.343262 1874.396851,766.381287 
M1884.423462,787.026245 
	C1888.599731,786.422913 1884.564575,784.535889 1884.705933,783.375549 
	C1885.320435,778.332336 1884.969604,773.171570 1884.969604,768.053528 
	C1884.507324,768.036499 1884.045044,768.019531 1883.582764,768.002502 
	C1883.401367,770.200806 1883.382935,772.427673 1883.003174,774.591187 
	C1882.303589,778.578125 1886.115356,782.992798 1881.855103,786.619629 
	C1882.482788,786.759521 1883.110352,786.899475 1884.423462,787.026245 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1942.448364,863.117310 
	C1941.178955,860.823975 1940.343018,858.619141 1939.227783,856.184509 
	C1939.446167,849.903625 1939.458008,850.205811 1947.686035,849.882080 
	C1953.306885,849.661011 1953.862061,852.755371 1953.762817,857.353210 
	C1951.423950,862.023010 1948.060669,864.442200 1942.448364,863.117310 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1923.171265,781.843018 
	C1923.617432,781.907104 1924.063721,781.971130 1924.837158,782.121521 
	C1924.320557,788.884216 1924.271729,789.386780 1917.397461,788.718689 
	C1910.788452,788.076355 1906.861450,789.986267 1905.456299,796.902954 
	C1905.048706,797.042114 1904.807983,796.848511 1904.899658,796.458679 
	C1905.383057,793.632996 1905.842529,791.205566 1906.149780,788.759094 
	C1906.549805,785.572510 1907.725464,783.540344 1911.487915,783.981812 
	C1913.864014,784.260620 1916.299438,784.033813 1919.096680,784.033813 
	C1919.096680,770.521057 1919.096680,757.806702 1919.493164,745.150208 
	C1920.252563,747.388000 1920.907593,749.565063 1920.929199,751.748413 
	C1921.036865,762.669678 1920.978394,773.592590 1920.978394,784.416931 
	C1916.867065,785.015076 1913.361450,785.525085 1909.855835,786.035095 
	C1909.887451,786.228271 1909.919189,786.421448 1909.950806,786.614624 
	C1914.138306,786.614624 1918.325806,786.614624 1922.619263,786.614624 
	C1922.831055,784.784546 1923.001099,783.313782 1923.171265,781.843018 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1861.609009,810.961487 
	C1865.816284,810.548889 1870.020630,810.058472 1874.237427,809.898010 
	C1874.520142,809.887268 1875.122070,812.361938 1875.188354,813.699768 
	C1875.364868,817.262573 1874.188721,821.329895 1879.978394,822.527832 
	C1875.057617,823.965393 1875.057617,823.965393 1870.789429,833.815613 
	C1870.580078,830.706665 1870.370605,827.597656 1870.106445,823.676025 
	C1866.728271,823.676025 1863.307617,823.676025 1859.557373,823.420654 
	C1861.387573,822.450134 1863.547485,821.734985 1866.175171,821.006714 
	C1868.691772,820.993469 1870.740601,820.993469 1873.624146,820.993469 
	C1871.896729,818.546082 1875.027710,814.621521 1869.806885,814.690552 
	C1869.344727,810.248962 1865.635498,812.461975 1863.397705,811.151855 
	C1862.703491,811.080322 1862.426880,811.041138 1862.014404,810.999390 
	C1861.878540,810.996765 1861.609009,810.961487 1861.609009,810.961487 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1798.484375,864.421387 
	C1797.454468,863.012573 1796.772705,861.770386 1796.480225,860.327515 
	C1799.976196,859.965027 1800.025635,858.250183 1799.377197,855.990234 
	C1801.170288,855.566284 1802.563354,855.129639 1803.956421,854.693054 
	C1803.789551,854.258362 1803.622559,853.823669 1803.455688,853.388977 
	C1801.413940,853.144409 1798.816650,851.793579 1798.655762,856.015930 
	C1797.492676,856.356567 1796.695190,856.704224 1795.897705,857.051880 
	C1795.552612,857.094421 1795.207642,857.137085 1794.602539,857.156616 
	C1794.498047,855.514343 1794.653442,853.895142 1794.902222,851.303101 
	C1799.161865,851.680725 1803.965942,850.928162 1807.114746,852.865784 
	C1808.775024,853.887390 1808.446045,859.961243 1807.219849,863.038452 
	C1806.659180,864.445618 1801.762695,864.125122 1798.484375,864.421387 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1829.908447,814.126282 
	C1828.646118,814.958496 1827.383911,815.790771 1825.867065,816.790833 
	C1827.149658,817.737366 1827.946777,818.325623 1828.743896,818.913818 
	C1828.390747,819.372131 1828.037598,819.830383 1827.684448,820.288635 
	C1826.085693,819.051331 1824.486938,817.814026 1822.558594,816.321777 
	C1820.609497,817.148071 1817.722046,818.689026 1814.642822,819.514954 
	C1813.482544,819.826111 1811.275757,818.846191 1810.637451,817.793213 
	C1809.934570,816.633545 1810.025024,814.493286 1810.642334,813.195374 
	C1811.113892,812.204285 1812.936890,811.342896 1814.172974,811.323425 
	C1818.123535,811.260986 1822.061768,812.575378 1826.049438,811.062805 
	C1826.940674,810.724731 1828.232788,811.443481 1829.672729,812.200439 
	C1829.972900,813.192322 1829.940674,813.659302 1829.908447,814.126282 
M1826.546021,813.431519 
	C1826.546021,813.431519 1826.631104,813.540649 1826.546021,813.431519 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1955.668701,841.077637 
	C1955.981812,841.682373 1955.985596,842.108887 1955.951416,842.853516 
	C1954.507080,843.423950 1952.729858,843.221558 1951.761841,844.011292 
	C1948.504028,846.669067 1945.863892,850.164062 1940.747925,847.403137 
	C1939.521362,846.741211 1937.242798,848.028809 1935.205444,847.939941 
	C1934.901001,847.148743 1934.902344,846.837463 1935.318848,846.430176 
	C1936.256104,846.072449 1936.841431,845.811035 1938.118042,845.240906 
	C1936.176758,845.065552 1935.061157,844.964783 1933.839233,844.669556 
	C1933.481201,844.112732 1933.229614,843.750366 1932.978027,843.388000 
	C1935.483276,841.143982 1938.013916,837.843445 1940.100952,843.637573 
	C1942.005371,842.535461 1943.713379,841.546997 1945.421509,840.558594 
	C1945.651123,840.851990 1945.880615,841.145386 1946.110229,841.438843 
	C1944.993652,842.721741 1943.877075,844.004700 1942.310059,845.805115 
	C1949.334229,846.539246 1948.741333,839.827026 1952.447876,837.568176 
	C1953.354980,838.606079 1954.357056,839.752747 1955.668701,841.077637 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1857.502441,819.849915 
	C1856.652832,817.550903 1855.803223,815.251831 1854.953735,812.952820 
	C1854.654785,818.867676 1849.307129,817.737793 1846.279297,819.376587 
	C1845.963745,819.547363 1843.183105,816.857300 1843.219238,815.540527 
	C1843.261108,814.009033 1845.015625,811.370239 1846.255371,811.229919 
	C1851.038208,810.688293 1855.912109,810.951904 1861.180542,810.938477 
	C1861.609009,810.961487 1861.878540,810.996765 1861.962402,811.394653 
	C1860.531616,814.478271 1859.017090,817.164124 1857.502441,819.849915 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1928.015381,830.117920 
	C1928.708862,829.925293 1929.068848,829.775269 1929.364014,829.857605 
	C1931.257935,830.385864 1933.137573,830.964905 1935.021973,831.527283 
	C1934.441528,832.970764 1934.234375,835.181335 1933.197266,835.685913 
	C1931.448975,836.536499 1929.175415,836.316406 1927.120361,836.522156 
	C1926.465210,836.587708 1925.802490,836.590027 1925.143066,836.596680 
	C1923.307129,836.615173 1921.389404,836.911865 1919.690796,836.434265 
	C1919.244995,836.308960 1918.876343,833.308228 1919.537842,832.438538 
	C1920.607544,831.032166 1922.559692,830.297058 1924.139160,829.278503 
	C1924.425781,829.721619 1924.712280,830.164734 1924.998779,830.607849 
	C1923.616089,831.723633 1922.233276,832.839417 1920.609253,834.149780 
	C1925.427734,835.239319 1926.395264,834.649536 1928.015381,830.117920 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1898.118774,856.734985 
	C1894.386597,856.734985 1890.654541,856.734985 1886.922363,856.734985 
	C1886.914917,856.100952 1886.907471,855.466858 1886.900024,854.832825 
	C1901.625488,854.832825 1916.350952,854.832825 1931.555054,855.213257 
	C1932.059937,855.796875 1932.086304,856.000122 1932.112671,856.203369 
	C1926.018311,856.405518 1919.923950,856.607605 1913.177979,856.558716 
	C1911.717407,856.449585 1910.908325,856.591553 1910.099365,856.733582 
	C1908.866089,856.802490 1907.632935,856.871338 1905.705322,856.988770 
	C1904.689575,857.044861 1904.368408,857.040527 1903.812256,856.741333 
	C1901.757568,856.550537 1899.938110,856.642761 1898.118774,856.734985 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1879.769775,813.595215 
	C1881.167603,813.977051 1882.565430,814.358948 1884.161499,814.794922 
	C1885.289795,812.242310 1886.184082,807.038269 1890.373535,813.546265 
	C1890.490723,813.728394 1891.967041,813.035461 1893.328125,813.098083 
	C1894.938599,814.711670 1896.030518,815.977783 1897.122437,817.243835 
	C1892.273438,817.731201 1887.396729,819.408691 1882.044189,816.640381 
	C1880.903931,815.447937 1880.336792,814.521606 1879.769775,813.595215 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1811.905640,846.616516 
	C1814.799194,842.140442 1817.472168,841.517212 1822.990723,843.421509 
	C1824.159424,843.824829 1825.810791,842.829285 1827.966797,842.299561 
	C1827.966797,844.215332 1827.966797,846.198181 1827.973999,848.612671 
	C1827.981201,849.044250 1827.985229,848.995789 1828.008789,848.999939 
	C1825.884399,848.152039 1823.910767,844.811035 1821.505493,848.645264 
	C1819.252197,845.807922 1817.072266,843.961548 1814.586304,848.317627 
	C1814.399902,848.644348 1813.259766,848.426636 1812.260742,848.147827 
	C1811.941040,847.427917 1811.923340,847.022217 1811.905640,846.616516 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1792.089966,830.852539 
	C1793.238647,830.749939 1794.528809,830.271912 1795.500488,830.638489 
	C1796.754272,831.111572 1798.227783,832.059509 1798.703979,833.184875 
	C1799.054565,834.013428 1798.291504,835.995850 1797.442383,836.518921 
	C1795.077148,837.976013 1792.402222,840.957275 1789.837036,836.616028 
	C1789.826904,836.599060 1789.284546,836.896851 1788.598145,836.607666 
	C1788.134888,835.462708 1788.064087,834.757690 1788.334473,834.005615 
	C1789.813599,832.923279 1790.951782,831.887939 1792.089966,830.852539 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1792.068848,830.429077 
	C1790.951782,831.887939 1789.813599,832.923279 1788.344482,833.607788 
	C1788.058350,832.463928 1788.103149,831.670959 1788.147949,830.877991 
	C1789.848633,827.689697 1789.689209,825.239075 1785.443726,824.461853 
	C1789.407349,821.136597 1795.655518,820.854797 1799.308228,824.405334 
	C1796.815308,824.756348 1794.844727,825.033875 1792.335938,825.387146 
	C1792.244019,826.861023 1792.145874,828.433289 1792.068848,830.429077 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1794.961182,848.763672 
	C1791.115967,848.414246 1787.270630,848.064758 1783.296753,847.703613 
	C1785.116455,843.999268 1792.097168,841.000488 1795.373047,842.410950 
	C1795.888916,842.633118 1796.001587,843.791504 1796.621338,844.726807 
	C1796.941895,844.936523 1797.010742,845.018311 1796.832642,845.289856 
	C1796.090088,846.628784 1795.525635,847.696228 1794.961182,848.763672 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1904.869995,815.921753 
	C1907.776978,812.866455 1910.590332,809.689941 1915.565186,811.221069 
	C1917.262573,811.743408 1919.067871,811.915039 1921.646484,812.402344 
	C1921.646484,813.080322 1921.646484,815.054016 1921.646484,816.158936 
	C1919.234863,816.324463 1916.937134,816.482178 1914.168701,816.672180 
	C1914.090332,816.276794 1913.817139,814.897827 1913.224365,811.906860 
	C1910.568970,813.841553 1908.614258,815.265686 1906.187744,816.568481 
	C1905.433960,816.272034 1905.151978,816.096924 1904.869995,815.921753 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1948.048584,821.508179 
	C1951.742554,820.973389 1955.129639,820.630859 1958.516724,820.288269 
	C1958.614502,820.966553 1958.712402,821.644775 1958.810303,822.322998 
	C1957.394775,822.734680 1955.979248,823.146362 1954.252808,823.648438 
	C1953.897461,826.609558 1953.526367,829.701111 1953.155396,832.792664 
	C1952.596680,832.783813 1952.037964,832.774902 1951.479248,832.766052 
	C1951.229614,829.805237 1950.979858,826.844421 1950.713501,823.685913 
	C1949.442017,823.359680 1948.197021,823.042053 1946.957642,822.704651 
	C1946.939819,822.699768 1946.939941,822.401184 1947.016846,822.320557 
	C1947.234741,822.091370 1947.496948,821.904175 1948.048584,821.508179 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1904.504883,816.032410 
	C1905.151978,816.096924 1905.433960,816.272034 1905.833008,816.670166 
	C1903.274170,820.490601 1900.546509,817.105591 1897.482666,817.098572 
	C1896.030518,815.977783 1894.938599,814.711670 1893.677612,813.091492 
	C1893.508545,812.737366 1893.559692,812.691956 1893.836548,812.732239 
	C1894.232178,812.669678 1894.350952,812.566772 1894.469727,812.463867 
	C1894.231934,812.276367 1893.994141,812.088928 1893.756348,811.901489 
	C1895.664429,811.783569 1897.572388,811.665588 1899.480469,811.547668 
	C1899.505859,811.968262 1899.531250,812.388855 1899.556641,812.809448 
	C1901.230591,812.084412 1902.904541,811.359436 1904.578491,810.634460 
	C1904.799194,810.962036 1905.019897,811.289612 1905.240479,811.617188 
	C1903.950317,812.788635 1902.660156,813.960022 1900.609375,815.821899 
	C1902.311279,815.976746 1903.225586,816.059937 1904.504883,816.032410 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1934.967041,846.526489 
	C1934.902344,846.837463 1934.901001,847.148743 1934.989746,847.693176 
	C1932.386963,847.964661 1929.757324,848.003113 1927.024170,847.374023 
	C1926.967896,846.198181 1927.015015,845.689941 1927.062256,845.181641 
	C1927.828247,843.742188 1928.594238,842.302734 1929.360229,840.863281 
	C1930.462646,841.502319 1931.565186,842.141357 1932.822754,843.084229 
	C1933.229614,843.750366 1933.481201,844.112732 1933.886475,845.042847 
	C1934.349121,845.915955 1934.658081,846.221252 1934.967041,846.526489 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1857.338501,820.153015 
	C1859.017090,817.164124 1860.531616,814.478271 1862.098389,811.397217 
	C1862.426880,811.041138 1862.703491,811.080322 1863.292969,811.546265 
	C1864.753662,814.173462 1860.799561,818.206116 1865.979736,819.262756 
	C1865.809326,820.079956 1865.758423,820.549866 1865.707397,821.019836 
	C1863.547485,821.734985 1861.387573,822.450134 1859.088745,823.445679 
	C1857.906616,823.976624 1856.863403,824.227173 1855.417969,824.530945 
	C1855.052124,824.344421 1855.088135,824.104797 1855.102661,823.502197 
	C1855.779053,822.244873 1856.476807,821.350525 1857.338501,820.153015 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1811.504883,846.696106 
	C1811.923340,847.022217 1811.941040,847.427917 1811.943726,848.136353 
	C1809.110840,850.808899 1806.671753,850.627747 1804.197266,846.973999 
	C1802.956909,846.187988 1802.209473,845.792114 1801.256470,845.287354 
	C1802.429321,844.349304 1803.188965,843.741760 1803.948608,843.134216 
	C1806.341187,842.833374 1808.733887,842.532471 1812.636841,842.041687 
	C1809.992432,844.006897 1808.554565,845.075500 1807.116699,846.144104 
	C1807.197144,846.507263 1807.277710,846.870483 1807.358154,847.233704 
	C1808.606812,847.080994 1809.855347,846.928345 1811.504883,846.696106 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1885.961182,802.939270 
	C1885.219727,807.236755 1881.611816,807.324158 1878.503052,807.553223 
	C1877.756592,807.608337 1875.858032,804.655151 1876.167358,804.181519 
	C1877.378662,802.326965 1879.261719,800.911377 1880.895020,799.332642 
	C1881.334595,799.839111 1881.774170,800.345581 1882.213745,800.852112 
	C1880.858521,801.949707 1879.503174,803.047363 1878.147827,804.145020 
	C1878.300049,804.572083 1878.452271,804.999146 1878.604492,805.426208 
	C1880.845093,804.709167 1883.085693,803.992126 1885.659058,803.129028 
	C1885.991821,802.982910 1885.961182,802.939270 1885.961182,802.939270 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1941.010254,812.101929 
	C1941.582886,811.935181 1942.165894,811.598755 1942.726074,811.633545 
	C1944.373291,811.735901 1946.011475,811.985596 1947.653198,812.177551 
	C1946.863647,813.813171 1946.074097,815.448730 1945.284424,817.084290 
	C1943.977783,816.270630 1942.598022,815.546875 1941.414307,814.582397 
	C1941.105347,814.330627 1941.406738,813.329651 1941.202148,812.365112 
	C1940.976318,812.057373 1941.010254,812.101990 1941.010254,812.101929 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1904.047485,857.024292 
	C1904.368408,857.040527 1904.689575,857.044861 1905.241455,856.983826 
	C1905.823486,859.411926 1906.174927,861.893433 1906.602905,864.916443 
	C1904.656494,865.011719 1903.129028,865.086426 1901.359619,864.769287 
	C1901.149902,864.087830 1901.124023,863.803589 1901.341309,863.307861 
	C1902.757690,862.275696 1903.873047,861.460388 1905.847534,860.017029 
	C1903.468384,859.747070 1902.250854,859.608948 1901.105957,859.299805 
	C1901.213013,858.877808 1901.186768,858.633362 1901.131104,858.296387 
	C1901.162109,858.197449 1901.343018,858.096069 1901.634521,858.057800 
	C1902.633179,857.687744 1903.340332,857.355957 1904.047485,857.024292 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1787.761475,830.861450 
	C1788.103149,831.670959 1788.058350,832.463928 1788.003418,833.654785 
	C1788.064087,834.757690 1788.134888,835.462708 1788.302979,836.715515 
	C1787.081299,838.001831 1785.762329,838.740356 1784.500366,839.446960 
	C1781.114746,834.670471 1781.741943,833.037537 1787.761475,830.861450 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1811.237915,795.374817 
	C1813.096069,794.478027 1814.758667,793.916504 1816.421265,793.354980 
	C1816.786743,794.468384 1817.783691,795.985046 1817.384521,796.610291 
	C1816.591431,797.852600 1815.029419,798.604004 1813.785278,799.558350 
	C1812.870972,798.275635 1811.956665,796.992859 1811.237915,795.374817 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1913.002686,857.998474 
	C1912.749390,859.921326 1912.598755,861.871826 1912.097534,863.727722 
	C1912.042236,863.932373 1909.979370,863.594849 1908.847656,863.508728 
	C1909.145264,861.877136 1909.442871,860.245483 1910.327271,858.314209 
	C1911.610229,858.009216 1912.306519,858.003845 1913.002686,857.998474 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1803.680176,842.904785 
	C1803.188965,843.741760 1802.429321,844.349304 1801.256470,845.287354 
	C1802.209473,845.792114 1802.956909,846.187988 1803.879395,846.833557 
	C1802.165161,847.935486 1800.275635,848.787598 1798.290405,849.054504 
	C1797.800049,847.318970 1797.405396,846.168640 1797.010742,845.018311 
	C1797.010742,845.018311 1796.941895,844.936523 1796.906738,844.896240 
	C1799.051636,844.129089 1801.231689,843.402222 1803.680176,842.904785 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1829.914062,814.495850 
	C1829.940674,813.659302 1829.972900,813.192322 1830.007935,812.375000 
	C1831.083740,812.751770 1832.156860,813.478943 1833.399658,814.321167 
	C1833.816040,813.665955 1834.276245,812.941772 1835.397461,811.177429 
	C1835.397461,814.297607 1835.397461,816.302246 1835.397461,818.396240 
	C1833.819092,818.251526 1832.029541,818.087402 1829.919556,817.893860 
	C1829.919556,817.169800 1829.919556,816.017639 1829.914062,814.495850 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1870.005371,843.049927 
	C1870.292603,840.862305 1870.583008,839.111938 1870.873535,837.361511 
	C1871.394897,837.414673 1871.916382,837.467773 1872.437744,837.520935 
	C1872.437744,840.920410 1872.437744,844.319885 1872.437744,847.719360 
	C1871.948730,847.803650 1871.459595,847.887939 1870.970581,847.972229 
	C1870.649902,846.477234 1870.329346,844.982178 1870.005371,843.049927 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1806.289795,821.870361 
	C1808.747192,822.059998 1810.778076,822.239624 1812.808838,822.419189 
	C1812.773438,822.953064 1812.737915,823.487000 1812.702515,824.020874 
	C1809.331543,824.020874 1805.960449,824.020874 1802.589478,824.020874 
	C1802.539673,823.551331 1802.489868,823.081848 1802.440063,822.612305 
	C1803.581055,822.361633 1804.722168,822.110962 1806.289795,821.870361 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1926.695557,845.113953 
	C1927.015015,845.689941 1926.967896,846.198181 1926.843262,847.083740 
	C1924.407349,847.065735 1922.048828,846.670349 1918.194824,846.024353 
	C1920.863892,844.013428 1922.490601,842.787842 1924.236572,841.472412 
	C1924.788818,842.415649 1925.558960,843.730957 1926.695557,845.113953 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1904.400269,799.342468 
	C1902.183105,804.460022 1898.510010,807.626160 1892.347168,807.704956 
	C1891.892090,807.002502 1891.858398,806.615417 1891.895996,806.138733 
	C1891.967163,806.049133 1892.111816,805.871643 1892.482666,805.969727 
	C1898.199463,806.220703 1900.387451,801.612976 1903.933716,798.951050 
	C1904.088379,799.082458 1904.400269,799.342468 1904.400269,799.342468 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1884.788696,823.713501 
	C1884.133545,822.889282 1883.861694,822.216675 1883.589844,821.544067 
	C1886.749390,821.544067 1889.908936,821.544067 1893.068481,821.544067 
	C1893.056152,822.317810 1893.043823,823.091492 1893.031372,823.865234 
	C1890.411621,823.865234 1887.791748,823.865234 1884.788696,823.713501 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1901.100220,858.395386 
	C1901.186768,858.633362 1901.213013,858.877808 1900.718018,859.496887 
	C1900.518188,861.084839 1900.779175,862.304749 1901.040161,863.524597 
	C1901.124023,863.803589 1901.149902,864.087830 1901.105713,864.591980 
	C1895.708374,864.261719 1898.577026,860.259338 1898.109131,857.196838 
	C1899.938110,856.642761 1901.757568,856.550537 1903.812256,856.741272 
	C1903.340332,857.355957 1902.633179,857.687744 1901.513428,857.797729 
	C1900.845459,857.597900 1900.590332,857.619934 1900.335205,857.641968 
	C1900.590210,857.893127 1900.845215,858.144226 1901.100220,858.395386 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1940.999390,812.043945 
	C1940.470947,813.820251 1939.919434,815.610046 1939.300049,817.620361 
	C1937.930420,815.438232 1936.712769,813.498352 1934.625488,810.173035 
	C1937.123535,810.637634 1938.984619,810.983704 1940.927979,811.715942 
	C1941.010254,812.101990 1940.976318,812.057373 1940.999390,812.043945 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1899.320557,820.978638 
	C1901.346924,820.974365 1902.962036,820.974365 1905.419189,820.974365 
	C1902.835815,824.938904 1899.993164,823.957458 1897.248169,823.364807 
	C1897.801880,822.570862 1898.355591,821.776917 1899.320557,820.978638 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1891.009277,808.125305 
	C1887.450439,808.897949 1887.450439,808.897949 1887.122803,803.517761 
	C1888.604736,803.007812 1889.816406,803.014343 1891.061035,803.330322 
	C1891.433228,804.383667 1891.772583,805.127686 1892.111816,805.871643 
	C1892.111816,805.871643 1891.967163,806.049133 1891.641846,806.257446 
	C1891.214233,807.018982 1891.111816,807.572144 1891.009277,808.125305 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1926.741699,862.265015 
	C1923.282471,862.035461 1919.823364,861.805969 1916.364136,861.576416 
	C1916.416992,860.968201 1916.469849,860.359924 1916.522583,859.751709 
	C1920.022339,860.126709 1923.521973,860.501770 1927.044678,861.404419 
	C1927.067871,861.932068 1926.741699,862.265015 1926.741699,862.265015 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1879.771484,813.213745 
	C1880.336792,814.521606 1880.903931,815.447937 1881.679688,816.599670 
	C1880.291382,817.169556 1878.694580,817.514099 1877.097656,817.858643 
	C1877.403076,815.953857 1877.708618,814.049072 1878.609375,812.076172 
	C1879.405029,812.028625 1879.605225,812.049255 1879.805542,812.069885 
	C1879.794800,812.323975 1879.784058,812.578125 1879.771484,813.213745 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1891.027954,803.020935 
	C1889.816406,803.014343 1888.604736,803.007812 1887.102173,803.135132 
	C1886.811157,803.269043 1886.372681,803.139587 1886.166992,803.039429 
	C1885.961182,802.939270 1885.991821,802.982910 1886.010742,803.001221 
	C1886.110474,802.044128 1886.191162,801.068787 1886.720215,800.252197 
	C1887.420898,800.242554 1887.673340,800.074158 1887.925781,799.905762 
	C1887.909302,799.485291 1887.892944,799.064758 1887.861450,798.329102 
	C1888.033813,798.012268 1888.221313,798.010742 1888.723877,798.341309 
	C1889.702026,800.122559 1890.364990,801.571716 1891.027954,803.020935 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1796.832642,845.289856 
	C1797.405396,846.168640 1797.800049,847.318970 1798.058594,848.795532 
	C1797.116699,849.034180 1796.310669,848.946655 1795.232910,848.811401 
	C1795.525635,847.696228 1796.090088,846.628784 1796.832642,845.289856 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1930.996216,864.617188 
	C1930.640503,865.628174 1930.281982,866.299133 1929.646240,867.008301 
	C1928.868164,866.044250 1928.367065,865.041992 1927.954224,863.498779 
	C1928.042358,862.957764 1928.365601,862.640747 1928.365601,862.640747 
	C1929.241577,863.186279 1930.117432,863.731750 1930.996216,864.617188 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1912.934326,857.681030 
	C1912.306519,858.003845 1911.610229,858.009216 1910.566895,858.044189 
	C1910.212524,857.849365 1910.205444,857.625000 1910.148804,857.067139 
	C1910.908325,856.591553 1911.717407,856.449585 1912.705444,856.510986 
	C1912.878296,856.930725 1912.872192,857.147156 1912.934326,857.681030 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1891.257080,808.120850 
	C1891.111816,807.572144 1891.214233,807.018982 1891.570679,806.347046 
	C1891.858398,806.615417 1891.892090,807.002502 1891.952271,807.680603 
	C1891.978882,807.971619 1891.504883,808.116455 1891.257080,808.120850 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1905.170166,797.137451 
	C1905.132935,797.796082 1904.974243,798.359314 1904.607910,799.132507 
	C1904.400269,799.342468 1904.088379,799.082458 1903.994629,798.668213 
	C1904.203369,797.785461 1904.505615,797.316956 1904.807983,796.848511 
	C1904.807983,796.848511 1905.048706,797.042114 1905.170166,797.137451 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1932.347656,856.094849 
	C1932.086304,856.000122 1932.059937,855.796875 1932.019287,855.286499 
	C1932.197510,855.315002 1932.390015,855.650635 1932.347656,856.094849 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1927.232910,861.767456 
	C1927.686401,861.756531 1927.922852,861.968201 1928.236572,862.439331 
	C1928.365601,862.640747 1928.042358,862.957764 1927.879883,863.117920 
	C1927.427612,863.132446 1927.183594,862.931274 1926.863525,862.469849 
	C1926.741699,862.265015 1927.067871,861.932068 1927.232910,861.767456 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1887.699219,799.933350 
	C1887.673340,800.074158 1887.420898,800.242554 1887.092529,800.191406 
	C1887.016479,799.971802 1887.472778,799.960876 1887.699219,799.933350 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1880.010742,811.810425 
	C1879.605225,812.049255 1879.405029,812.028625 1878.950439,811.844910 
	C1879.202759,811.638184 1879.709351,811.594604 1880.010742,811.810425 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1893.609619,812.075806 
	C1893.994141,812.088928 1894.231934,812.276367 1894.469727,812.463867 
	C1894.350952,812.566772 1894.232178,812.669678 1893.869873,812.724121 
	C1893.626221,812.675659 1893.462769,812.250183 1893.609619,812.075806 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1798.977295,855.977661 
	C1800.025635,858.250183 1799.976196,859.965027 1796.553711,859.964355 
	C1796.170654,859.111877 1796.103394,858.421936 1795.966919,857.391907 
	C1796.695190,856.704224 1797.492676,856.356567 1798.645020,856.004456 
	C1799.000000,856.000000 1798.977295,855.977661 1798.977295,855.977661 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1540.062256,840.975220 
	C1539.291870,838.829102 1538.191040,836.751953 1537.851074,834.556946 
	C1537.683350,833.474243 1538.920532,832.173828 1539.521973,830.971924 
	C1540.325317,831.970947 1541.196655,832.924988 1541.900635,833.989807 
	C1542.206909,834.453003 1542.169067,835.143738 1542.356445,836.072815 
	C1543.231445,833.664307 1543.967651,831.637939 1544.759521,829.458679 
	C1543.862671,829.190430 1542.968994,828.923096 1541.968750,828.623901 
	C1542.289673,828.384705 1542.478516,828.129578 1542.701904,828.094299 
	C1546.550293,827.487427 1549.806274,828.628601 1551.431274,832.232971 
	C1553.179688,836.111145 1550.617432,838.798096 1547.973633,841.616028 
	C1547.938843,841.970764 1547.979492,841.999512 1547.560059,841.871643 
	C1545.422852,841.813232 1543.705078,841.882629 1541.987427,841.952087 
	C1541.987427,841.952087 1541.998901,842.007324 1541.918091,841.686768 
	C1541.231812,841.227966 1540.626221,841.089661 1540.020752,840.951355 
	C1540.020752,840.951355 1540.045776,840.995605 1540.062256,840.975220 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1411.767578,781.229736 
	C1408.979126,782.777405 1406.798706,783.992981 1403.282959,782.570740 
	C1400.431885,781.417542 1396.649536,781.558289 1393.574707,782.317505 
	C1391.498169,782.830200 1389.641602,784.151245 1389.178711,781.386353 
	C1388.911865,779.793457 1390.543701,777.879272 1391.342407,776.119446 
	C1391.390381,776.013611 1391.629150,775.994507 1391.663940,775.980774 
	C1392.575195,777.323059 1393.466553,778.635986 1394.541382,780.218933 
	C1398.368164,774.309204 1398.880737,774.254761 1403.159912,778.428162 
	C1408.251587,773.033203 1412.970459,777.982971 1418.122559,779.672729 
	C1416.104858,780.256042 1414.087280,780.839355 1411.767578,781.229736 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1445.920654,743.472168 
	C1444.827759,743.072205 1444.012817,742.455017 1444.249146,742.633972 
	C1440.856934,743.382690 1438.209595,743.967041 1435.562256,744.551392 
	C1435.159668,742.442017 1434.386963,740.308960 1434.520142,738.234009 
	C1434.581055,737.284546 1436.425415,736.449585 1438.294678,734.848938 
	C1438.041870,738.093262 1437.896118,739.962830 1437.750488,741.832336 
	C1438.143921,741.950623 1438.537231,742.068848 1438.930542,742.187073 
	C1439.704468,740.212463 1440.478394,738.237854 1441.523682,735.570862 
	C1442.621704,737.140442 1443.316772,738.134033 1443.985107,739.089417 
	C1445.012817,738.026367 1445.927612,736.227356 1446.982178,736.141418 
	C1450.930054,735.819702 1454.927979,735.958435 1458.898193,736.131042 
	C1460.759155,736.211914 1462.599609,736.763428 1464.597778,737.128845 
	C1463.941406,738.692749 1463.142944,740.595276 1462.144531,742.974182 
	C1461.227539,741.797607 1460.454834,740.806152 1458.965210,738.895020 
	C1458.699341,741.023804 1458.563965,742.107361 1458.436279,743.129517 
	C1456.662109,743.245483 1454.820435,743.365784 1452.562134,743.513367 
	C1452.301025,742.135498 1451.987305,740.480469 1451.562012,738.236511 
	C1451.003540,740.326233 1450.600708,741.833496 1450.197876,743.340759 
	C1449.937988,743.364014 1449.678101,743.387268 1449.418213,743.410522 
	C1449.062866,741.756042 1448.707642,740.101562 1448.034424,736.966553 
	C1447.140747,740.027222 1446.669556,741.641113 1445.920654,743.472168 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1427.659180,757.759399 
	C1427.941772,760.406067 1427.861084,762.871460 1427.817139,765.337463 
	C1427.792114,766.744751 1427.826782,768.153198 1427.827881,769.941040 
	C1427.187012,770.481995 1426.559082,770.749695 1425.917603,770.787598 
	C1417.946045,771.258911 1414.706665,767.007202 1417.457275,759.458252 
	C1417.780029,758.572510 1419.310181,757.714722 1420.366821,757.593201 
	C1422.651978,757.330444 1424.992920,757.553833 1427.659180,757.759399 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1404.885010,768.658020 
	C1401.254761,773.699829 1395.612183,770.942017 1390.975342,772.053650 
	C1390.289429,772.218079 1388.008179,769.312500 1388.210815,769.014465 
	C1389.483276,767.143616 1391.250000,765.608948 1392.853271,763.963135 
	C1393.548096,764.677124 1394.242798,765.391113 1394.937622,766.105103 
	C1393.113037,766.719666 1391.288574,767.334229 1389.463989,767.948792 
	C1390.118042,768.755981 1390.772095,769.563171 1391.426025,770.370300 
	C1392.928711,769.394775 1394.956665,768.754883 1395.835083,767.380371 
	C1398.419434,763.337158 1401.525146,763.255432 1404.698486,767.935913 
	C1404.895874,768.343750 1404.885010,768.658020 1404.885010,768.658020 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1400.014282,747.929382 
	C1413.313477,747.929382 1426.612793,747.929382 1439.911987,747.929382 
	C1439.919556,748.391968 1439.927002,748.854553 1439.934570,749.317139 
	C1426.870239,749.317139 1413.805908,749.317139 1400.376587,748.894958 
	C1400.012451,748.291626 1400.013428,748.110474 1400.014282,747.929382 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1404.824829,744.002563 
	C1404.413696,743.059021 1404.018066,742.349365 1404.171753,741.790833 
	C1404.694702,739.889832 1405.411621,738.042297 1406.054443,736.174316 
	C1407.886719,736.894531 1409.718872,737.614807 1411.897705,738.471313 
	C1412.032837,738.346191 1412.784912,737.650452 1413.536865,736.954651 
	C1414.840088,738.874329 1416.143311,740.793945 1417.785645,743.213257 
	C1412.892456,743.634399 1408.992188,743.970032 1404.824829,744.002563 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1434.757568,780.230347 
	C1434.814209,781.170471 1434.628052,782.462769 1434.524902,782.456177 
	C1431.270020,782.248352 1428.027466,781.856689 1424.773315,781.632080 
	C1423.696045,781.557800 1422.592651,781.864197 1421.296875,782.021362 
	C1422.039185,779.695984 1422.709229,777.596802 1423.559082,774.934143 
	C1425.581543,777.355530 1427.119751,779.197144 1429.540771,782.095764 
	C1429.540771,779.497314 1429.540771,778.133362 1429.540771,776.860474 
	C1431.518555,776.553101 1433.415649,776.258240 1435.310791,775.959229 
	C1435.056885,777.295715 1434.805176,778.636353 1434.757568,780.230347 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1392.800293,747.676514 
	C1393.729858,739.652771 1391.567993,738.331665 1381.378174,740.579834 
	C1380.989624,739.454224 1381.020142,738.701355 1381.050781,737.948486 
	C1382.533691,737.118896 1384.016602,736.289368 1385.999268,735.219727 
	C1386.671753,734.992920 1386.844604,735.006042 1387.032471,735.032593 
	C1387.047363,735.046143 1387.076050,735.076538 1387.076050,735.076538 
	C1387.705811,735.996826 1388.347778,737.722290 1388.963501,737.713013 
	C1395.245483,737.617981 1395.627441,742.723022 1396.739380,747.195435 
	C1395.083374,747.652100 1393.941895,747.664307 1392.800293,747.676514 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1452.494263,750.043457 
	C1449.340576,749.667908 1446.606201,749.298340 1443.455322,748.956421 
	C1440.437744,746.393372 1443.559937,747.208313 1444.253296,747.194275 
	C1450.169556,747.074036 1456.088623,747.095032 1462.503174,747.391113 
	C1462.997559,748.110535 1462.995361,748.512451 1462.993164,748.914429 
	C1462.547729,748.948853 1462.102417,748.983276 1460.958740,748.943481 
	C1459.489258,749.319946 1458.718262,749.770691 1457.947144,750.221436 
	C1456.269287,750.164062 1454.591431,750.106750 1452.494263,750.043457 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1405.146240,768.978638 
	C1404.885010,768.658020 1404.895874,768.343750 1404.881592,768.300964 
	C1406.994629,767.413147 1409.121826,766.568115 1411.983154,765.431458 
	C1412.098999,766.816284 1412.286621,769.059143 1412.450928,771.023621 
	C1409.541504,770.311340 1407.474365,769.805298 1405.146240,768.978638 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1423.614990,737.054626 
	C1425.179932,738.632263 1426.524292,740.489258 1428.468506,743.174805 
	C1425.251831,743.174805 1423.746094,743.174805 1421.651489,743.174805 
	C1422.303955,740.988281 1422.849121,739.161133 1423.614990,737.054626 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1463.314209,749.150146 
	C1462.995361,748.512451 1462.997559,748.110535 1462.967285,747.409058 
	C1463.724243,747.057068 1464.513550,747.004517 1465.694824,747.020264 
	C1468.505737,747.469238 1470.924561,747.849915 1473.644165,748.450806 
	C1470.508301,748.909241 1467.071655,749.147522 1463.314209,749.150146 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1392.400879,747.703674 
	C1393.941895,747.664307 1395.083374,747.652100 1396.655151,747.565063 
	C1397.820557,747.579407 1398.555786,747.668518 1399.652588,747.843506 
	C1400.013428,748.110474 1400.012451,748.291626 1400.003906,748.744385 
	C1399.497437,749.240479 1398.998535,749.465027 1397.931152,749.843262 
	C1396.449829,749.742371 1395.537109,749.487793 1394.624268,749.233154 
	C1392.692871,749.191895 1390.761353,749.150574 1388.416016,749.062012 
	C1387.997192,748.846069 1387.992065,748.677307 1387.993774,748.255371 
	C1389.334229,747.911743 1390.667847,747.821289 1392.400879,747.703674 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1387.794189,731.358032 
	C1388.379639,731.352844 1388.964966,731.347656 1389.980225,731.447205 
	C1389.511230,732.687866 1388.612305,733.823669 1387.394775,735.018066 
	C1387.076050,735.076538 1387.047363,735.046143 1387.058960,734.712158 
	C1387.311768,733.371460 1387.552979,732.364746 1387.794189,731.358032 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1407.670654,731.532593 
	C1408.549438,731.227966 1409.562500,731.209045 1411.079590,731.275391 
	C1410.464233,732.671143 1409.519531,735.421875 1407.670654,731.532593 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1394.740723,749.514404 
	C1395.537109,749.487793 1396.449829,749.742371 1397.722656,750.021606 
	C1396.842163,752.092102 1395.761597,752.077576 1394.740723,749.514404 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1477.361694,743.313354 
	C1477.849976,743.578247 1478.395752,744.135254 1478.965454,745.015991 
	C1478.466064,744.761719 1477.942627,744.183594 1477.361694,743.313354 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1516.814941,769.066895 
	C1518.207275,768.835876 1519.251221,768.683105 1520.632080,768.481018 
	C1520.632080,767.766724 1520.632080,766.187500 1520.632080,764.376038 
	C1521.233521,764.198425 1521.871094,763.796997 1522.435791,763.881775 
	C1524.437622,764.182068 1526.474976,764.490906 1528.342896,765.204529 
	C1528.659546,765.325500 1528.647705,768.132385 1528.029907,768.534424 
	C1526.085083,769.800415 1523.833740,770.697754 1521.589355,771.356812 
	C1520.533569,771.666870 1519.222412,771.104553 1518.026489,770.940491 
	C1516.221069,770.692566 1514.350342,770.637329 1512.657837,770.059570 
	C1512.262573,769.924683 1511.984009,767.556641 1512.495728,767.018127 
	C1513.898438,765.541992 1515.750366,764.492798 1517.424316,763.274475 
	C1517.734619,763.730103 1518.044922,764.185730 1518.355103,764.641418 
	C1517.277222,765.475769 1516.139771,766.252136 1515.197998,767.219116 
	C1515.083008,767.337219 1516.018188,768.478210 1516.814941,769.066895 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1548.655151,774.412598 
	C1550.174316,776.401184 1551.329224,778.337830 1552.495605,780.657837 
	C1550.252563,781.280090 1547.998291,781.518860 1545.394653,781.466675 
	C1545.680664,779.099792 1546.316040,777.023682 1546.951416,774.947571 
	C1547.397827,774.751892 1547.844482,774.556213 1548.655151,774.412598 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1546.692749,775.041626 
	C1546.316040,777.023682 1545.680664,779.099792 1545.049316,781.544189 
	C1545.053467,781.912537 1544.569458,782.033508 1544.320923,782.053528 
	C1544.056274,780.018921 1544.040161,777.964294 1543.998535,775.471924 
	C1544.793213,775.067993 1545.613647,775.101807 1546.692749,775.041626 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1670.929688,738.470215 
	C1670.080566,738.249451 1669.301758,737.692749 1668.216309,737.146240 
	C1666.253296,737.116760 1664.596436,737.094238 1662.940796,737.034119 
	C1658.958862,736.889709 1654.675171,736.054626 1653.272339,743.210266 
	C1652.322754,740.278137 1651.904175,738.985840 1651.280029,737.058594 
	C1648.756470,737.058594 1645.959351,736.818909 1643.218750,737.110229 
	C1638.092529,737.655151 1632.427856,735.652588 1628.109863,740.587646 
	C1627.305664,741.506836 1624.630493,740.789185 1622.456787,740.624512 
	C1622.379883,739.967651 1622.665039,739.513184 1623.256348,739.076416 
	C1623.986450,738.742859 1624.410645,738.391602 1624.834839,738.040283 
	C1624.186646,738.029602 1623.538452,738.018860 1622.669922,737.843079 
	C1622.253662,737.165527 1622.057739,736.653137 1622.291504,736.096069 
	C1624.889893,735.851807 1627.058838,735.652222 1629.227661,735.452576 
	C1629.194458,735.187683 1629.161133,734.922852 1629.127930,734.658020 
	C1626.711914,734.085999 1624.296021,733.514038 1621.938477,732.588379 
	C1621.919556,731.286499 1621.842407,730.338196 1622.218262,729.283325 
	C1627.364502,729.379700 1632.057373,729.582581 1636.788330,730.148804 
	C1637.197876,731.553711 1637.569702,732.595337 1637.973755,733.727295 
	C1636.656860,733.927490 1635.269653,734.138428 1633.882446,734.349304 
	C1633.904663,734.521057 1633.926880,734.692871 1633.949219,734.864624 
	C1646.321167,734.864624 1658.693237,734.864624 1671.365356,734.914490 
	C1671.868530,734.962952 1672.071411,734.961609 1672.274292,734.960205 
	C1671.849609,736.018188 1671.424805,737.076233 1670.929688,738.470215 
M1627.752197,737.793335 
	C1627.541260,737.795288 1627.328003,737.782471 1627.122559,737.817200 
	C1627.096069,737.821655 1627.110962,738.071594 1627.106567,738.207764 
	C1627.318359,738.205872 1627.530029,738.203918 1627.752197,737.793335 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1621.880005,732.942017 
	C1624.296021,733.514038 1626.711914,734.085999 1629.127930,734.658020 
	C1629.161133,734.922852 1629.194458,735.187683 1629.227661,735.452576 
	C1627.058838,735.652222 1624.889893,735.851807 1621.930176,736.044678 
	C1619.416870,737.007629 1617.694580,737.977356 1615.972412,738.947144 
	C1615.650513,738.925476 1615.328735,738.903809 1614.645020,738.913330 
	C1610.917480,735.181274 1609.069458,735.599731 1607.117798,740.601685 
	C1606.949097,741.034058 1607.028198,741.563171 1606.992188,742.047363 
	C1606.377686,742.055054 1605.341064,742.276489 1605.218628,742.034851 
	C1604.531860,740.679504 1604.065430,739.212524 1603.092773,737.196289 
	C1599.266357,734.644104 1599.266357,734.644104 1600.853027,730.372192 
	C1601.606445,731.257751 1602.337646,732.117249 1603.521484,732.986633 
	C1609.942627,732.978333 1615.911377,732.960205 1621.880005,732.942017 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1603.068848,732.976746 
	C1602.337646,732.117249 1601.606445,731.257751 1600.853027,730.372192 
	C1599.266357,734.644104 1599.266357,734.644104 1602.857422,736.952271 
	C1601.902710,738.710876 1600.752197,740.126099 1598.800537,742.527100 
	C1598.536743,738.117493 1598.322388,734.533691 1598.056274,730.083191 
	C1591.999756,730.083191 1585.954102,730.083191 1579.441895,730.015137 
	C1578.432373,729.506897 1577.889648,729.066772 1577.183960,728.117554 
	C1576.987793,727.417908 1576.954590,727.227234 1577.204346,726.995972 
	C1577.964966,726.691223 1578.442505,726.427185 1579.208008,726.111755 
	C1579.689575,726.084106 1579.883179,726.107910 1580.363159,726.383423 
	C1583.401123,726.477112 1586.152588,726.318970 1589.173218,726.089355 
	C1589.627808,726.010803 1589.813232,726.003601 1590.093750,726.215698 
	C1590.387939,726.698792 1590.644775,726.880432 1591.201904,727.286987 
	C1593.564941,727.670959 1595.685913,727.747742 1598.219482,727.783936 
	C1598.901001,727.679932 1599.170044,727.616455 1599.716919,727.655640 
	C1600.683594,727.481262 1601.372314,727.204285 1602.077759,726.641479 
	C1602.090332,725.974060 1602.086060,725.592468 1602.094604,725.158813 
	C1602.107300,725.106689 1602.211426,725.081909 1602.316650,725.102112 
	C1602.421997,725.122314 1602.632568,725.081421 1602.717407,725.441650 
	C1603.551025,726.586548 1604.299683,727.371277 1604.985107,728.472046 
	C1604.862671,728.994507 1604.803589,729.201050 1604.446777,729.505005 
	C1603.789062,730.727173 1603.428955,731.851990 1603.068848,732.976746 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1607.314941,742.176086 
	C1607.028198,741.563171 1606.949097,741.034058 1607.117798,740.601685 
	C1609.069458,735.599731 1610.917480,735.181274 1614.542603,739.097778 
	C1613.287109,744.344727 1612.421387,744.713745 1607.314941,742.176086 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1615.937378,739.324951 
	C1617.694580,737.977356 1619.416870,737.007629 1621.500488,736.089294 
	C1622.057739,736.653137 1622.253662,737.165527 1622.693481,738.105225 
	C1622.941772,738.707886 1622.946045,738.883301 1622.950195,739.058716 
	C1622.665039,739.513184 1622.379883,739.967651 1622.027344,740.682007 
	C1619.256592,743.873291 1617.143555,743.919434 1615.937378,739.324951 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1410.481934,729.109802 
	C1409.722290,727.416260 1409.414429,725.856812 1408.885620,723.177490 
	C1411.129517,723.463013 1412.993896,723.324402 1414.536377,723.963257 
	C1420.470581,726.421021 1424.385742,725.655212 1429.420166,720.454590 
	C1434.605225,717.904541 1439.423340,715.888855 1443.958374,713.364380 
	C1446.276489,712.074097 1448.087158,709.871948 1450.443359,707.845093 
	C1454.771973,703.838440 1459.389771,705.447083 1463.901367,706.487244 
	C1466.262329,708.614014 1468.625122,710.382507 1470.987915,712.151001 
	C1472.941528,714.221619 1474.895142,716.292236 1476.472168,718.663208 
	C1465.248047,718.963562 1454.400513,718.963562 1443.552856,718.963562 
	C1443.562256,719.594604 1443.571655,720.225647 1443.581055,720.856689 
	C1455.424683,720.856689 1467.268433,720.856689 1479.112061,720.856689 
	C1479.402466,721.346680 1479.692871,721.836609 1479.972900,722.967468 
	C1479.240601,725.282227 1478.518799,726.956055 1477.797119,728.629883 
	C1461.829346,728.894287 1445.862305,729.204285 1429.893677,729.400635 
	C1423.575317,729.478394 1417.253906,729.303589 1410.481934,729.109802 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1516.874512,719.228271 
	C1513.312134,716.329834 1509.749634,713.431458 1505.923706,710.328247 
	C1503.841064,707.693359 1502.022095,705.263367 1500.164551,702.401855 
	C1500.171021,699.378601 1500.215820,696.786804 1500.630615,694.052490 
	C1502.752930,693.600342 1504.543701,693.426575 1506.247925,692.945923 
	C1508.259766,692.378235 1511.090942,692.124084 1511.999634,690.719727 
	C1514.806763,686.381714 1518.637451,686.744080 1522.802490,687.670654 
	C1522.973267,692.764954 1523.370605,697.426514 1523.103394,702.049622 
	C1522.735229,708.416748 1524.275757,713.101562 1531.373779,714.334534 
	C1531.819092,714.411926 1532.061035,715.659668 1532.129639,716.738037 
	C1529.493652,717.408752 1527.121094,717.673340 1524.758545,718.008362 
	C1522.125854,718.381592 1519.502075,718.818848 1516.874512,719.228271 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1501.862793,728.296509 
	C1500.995850,723.341431 1498.332153,721.339722 1493.574951,721.004700 
	C1490.562622,720.792542 1487.629150,719.458435 1483.917358,718.419556 
	C1483.917358,710.341064 1483.877563,702.077271 1484.002197,693.815979 
	C1484.010986,693.233093 1485.232178,692.668457 1486.366333,692.512939 
	C1486.989502,696.692200 1485.711182,701.908569 1487.666382,703.823425 
	C1489.799683,705.912842 1494.928589,704.943909 1498.746094,705.665710 
	C1500.239746,708.518921 1500.935059,711.880188 1504.888428,711.733643 
	C1510.156006,717.391602 1515.423706,723.049500 1520.473145,728.473145 
	C1514.941895,728.473145 1508.605713,728.473145 1501.862793,728.296509 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1516.916992,719.565430 
	C1519.502075,718.818848 1522.125854,718.381592 1524.758545,718.008362 
	C1527.121094,717.673340 1529.493652,717.408752 1532.368042,716.849182 
	C1533.243896,716.377136 1533.613403,716.169006 1534.374023,715.917480 
	C1537.827515,716.484070 1540.890015,717.093933 1544.370972,717.800293 
	C1545.360718,717.881165 1545.931763,717.865540 1546.369385,718.076416 
	C1546.159546,718.719055 1546.083130,719.135193 1546.006714,719.551392 
	C1546.318481,719.543335 1546.630127,719.535278 1546.941895,719.527222 
	C1546.793213,718.967712 1546.644531,718.408203 1546.959351,717.884277 
	C1548.966187,717.839111 1550.509521,717.758362 1552.102539,717.933411 
	C1552.333130,718.443726 1552.514038,718.698242 1552.694824,718.952820 
	C1552.780640,718.827698 1552.852417,718.689453 1552.954712,718.579590 
	C1554.379272,717.046997 1555.808105,715.518433 1557.235840,713.988831 
	C1559.423218,716.309937 1561.686890,714.835388 1563.941895,714.581665 
	C1563.935181,719.643799 1563.921631,724.248047 1563.908081,728.852234 
	C1554.230591,728.373047 1544.553223,727.893494 1534.875610,727.416260 
	C1534.404419,727.392944 1533.858154,727.226379 1533.471313,727.401611 
	C1525.094849,731.195679 1521.130859,725.327942 1516.916992,719.565430 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1451.917358,695.923645 
	C1448.998779,692.958313 1445.779907,691.297424 1441.303833,691.908203 
	C1439.788330,692.115051 1437.970093,690.103943 1436.290283,689.106445 
	C1436.470703,688.694336 1436.651245,688.282288 1436.831665,687.870239 
	C1450.033325,687.870239 1463.235596,687.817200 1476.435059,687.987122 
	C1477.334839,687.998657 1478.972534,689.674011 1478.983032,690.590271 
	C1479.081909,699.189819 1478.900635,707.792480 1478.450439,716.624207 
	C1476.039307,714.899902 1473.977905,712.945740 1471.491211,710.558105 
	C1469.309692,707.769897 1467.553589,705.415161 1465.633545,702.646790 
	C1465.281128,698.979919 1465.092773,695.726746 1464.882446,692.092957 
	C1461.704468,692.092957 1459.049805,692.281860 1456.433960,692.043396 
	C1453.411621,691.767883 1452.134277,693.130737 1451.917358,695.923645 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1547.015625,738.625732 
	C1542.710449,740.144043 1541.360718,736.185425 1538.514160,734.147583 
	C1537.562988,731.198059 1538.615967,730.142517 1541.538696,730.220337 
	C1549.144897,730.423035 1556.757080,730.398071 1564.809204,730.569824 
	C1565.163574,732.836975 1565.075806,734.998230 1564.625732,737.562622 
	C1563.184570,737.466003 1562.105591,736.966431 1561.026733,736.466797 
	C1560.626587,732.623657 1556.933472,733.369263 1556.084106,734.457825 
	C1553.916626,737.235901 1551.879883,737.395752 1548.992310,736.285706 
	C1548.641113,736.150696 1547.699463,737.551941 1547.015625,738.625732 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1391.684082,729.725586 
	C1391.201172,728.485840 1391.164185,727.285400 1391.175781,725.725342 
	C1395.568115,721.562073 1400.185913,719.653320 1405.834717,723.614319 
	C1406.006592,725.587341 1405.948608,727.232910 1405.821533,729.279541 
	C1401.211670,729.708740 1396.670898,729.736816 1391.684082,729.725586 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1564.293091,728.883850 
	C1563.921631,724.248047 1563.935181,719.643799 1564.173584,714.463562 
	C1564.596313,713.616760 1564.793823,713.346069 1565.325684,713.110840 
	C1566.546753,712.564819 1567.433716,711.983337 1568.320679,711.401855 
	C1568.618286,711.752563 1568.915894,712.103210 1569.434082,712.984314 
	C1569.434814,714.366699 1569.214966,715.218628 1569.026611,716.524780 
	C1569.069092,720.603638 1569.079956,724.228271 1569.090698,727.852905 
	C1567.619873,728.207092 1566.148926,728.561279 1564.293091,728.883850 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1478.963135,720.615601 
	C1467.268433,720.856689 1455.424683,720.856689 1443.581055,720.856689 
	C1443.571655,720.225647 1443.562256,719.594604 1443.552856,718.963562 
	C1454.400513,718.963562 1465.248047,718.963562 1476.567383,718.983398 
	C1477.630737,719.460388 1478.222412,719.917480 1478.963135,720.615601 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1486.011230,723.361572 
	C1489.364868,723.318604 1492.845825,723.267090 1496.087036,724.123230 
	C1497.565918,724.513733 1498.571533,726.695557 1499.797852,728.419250 
	C1494.654175,728.622498 1488.670654,730.743103 1486.011230,723.361572 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1569.392334,728.005371 
	C1569.079956,724.228271 1569.069092,720.603638 1569.329346,716.658447 
	C1570.409058,716.901367 1571.217529,717.464844 1572.014771,718.013916 
	C1572.003418,717.999451 1571.984985,717.967651 1571.809082,718.312744 
	C1572.766846,720.115662 1573.900269,721.573425 1575.019287,723.013550 
	C1575.004639,722.995911 1574.978394,722.958191 1574.881226,723.299927 
	C1575.496460,724.773315 1576.208862,725.904968 1576.921265,727.036621 
	C1576.954590,727.227234 1576.987793,727.417908 1577.026855,727.899414 
	C1574.586426,728.179443 1572.140259,728.168701 1569.392334,728.005371 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1465.797485,703.060486 
	C1467.553589,705.415161 1469.309692,707.769897 1471.179932,710.449280 
	C1471.195923,710.992859 1471.097534,711.211731 1470.993408,711.790771 
	C1468.625122,710.382507 1466.262329,708.614014 1463.984741,706.095886 
	C1464.033569,704.548889 1463.997192,703.751587 1463.974121,702.715210 
	C1463.987305,702.476257 1464.051270,702.001770 1464.282715,702.121826 
	C1464.942017,702.514709 1465.369751,702.787598 1465.797485,703.060486 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1505.030640,711.498169 
	C1500.935059,711.880188 1500.239746,708.518921 1498.807739,705.281250 
	C1499.317627,703.970642 1499.760376,703.401978 1500.203003,702.833313 
	C1502.022095,705.263367 1503.841064,707.693359 1505.616699,710.504578 
	C1505.439697,711.011475 1505.306152,711.137146 1505.030640,711.498169 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1478.246216,728.691040 
	C1478.518799,726.956055 1479.240601,725.282227 1480.035400,723.252930 
	C1482.366821,724.564209 1484.625366,726.230896 1486.883911,727.897583 
	C1486.704590,728.306702 1486.525269,728.715759 1486.345947,729.124817 
	C1483.795776,729.000610 1481.245483,728.876404 1478.246216,728.691040 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1387.455322,731.313477 
	C1387.552979,732.364746 1387.311768,733.371460 1387.043945,734.698669 
	C1386.844604,735.006042 1386.671753,734.992920 1386.238770,734.976562 
	C1385.968140,734.088440 1385.957642,733.203369 1385.887207,731.997070 
	C1386.256958,731.540100 1386.686646,731.404541 1387.455322,731.313477 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1535.497559,732.036377 
	C1535.098389,732.050903 1534.940063,731.906677 1534.929688,731.855774 
	C1535.393799,731.342163 1535.868408,730.879395 1536.342896,730.416626 
	C1536.194092,730.951721 1536.045288,731.486816 1535.497559,732.036377 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1504.722534,740.125122 
	C1505.442871,739.842407 1506.028442,739.826660 1506.614136,739.810913 
	C1506.106201,740.222656 1505.598267,740.634399 1504.764893,741.008545 
	C1504.488770,740.778076 1504.538330,740.585083 1504.722534,740.125122 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1457.968262,750.560547 
	C1458.718262,749.770691 1459.489258,749.319946 1460.623413,748.928955 
	C1462.726562,752.338562 1464.526489,755.659546 1466.163086,759.059265 
	C1466.553955,759.871399 1466.399048,760.946106 1466.248413,762.173584 
	C1463.937988,761.164246 1462.282715,760.132935 1462.376709,756.876831 
	C1462.432983,754.928284 1459.550781,752.894897 1457.968262,750.560547 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1560.720459,736.695679 
	C1562.105591,736.966431 1563.184570,737.466003 1564.624023,738.018921 
	C1564.968994,738.399902 1565.084106,738.836853 1564.916504,739.037720 
	C1563.649170,740.555847 1562.443237,742.172363 1560.927734,743.399170 
	C1560.601074,743.663574 1558.207642,742.213440 1558.272583,741.989319 
	C1558.778809,740.240967 1559.653198,738.599182 1560.720459,736.695679 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1759.824463,764.181641 
	C1757.619019,763.437378 1753.894653,763.147949 1753.508667,761.858765 
	C1752.559082,758.688049 1752.983765,755.044556 1753.257202,751.623413 
	C1753.308838,750.978271 1755.172241,750.141235 1756.280396,749.991882 
	C1758.220215,749.730408 1760.222656,749.933228 1762.641968,750.445679 
	C1766.525024,757.325317 1766.353149,757.981262 1760.202637,762.791992 
	C1759.899536,763.028931 1759.942627,763.708252 1759.824463,764.181641 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1784.000000,773.992615 
	C1783.990356,773.556641 1783.980835,773.120667 1784.003662,772.357544 
	C1787.684814,771.955200 1791.333618,771.880066 1795.433960,771.775391 
	C1796.091919,771.750366 1796.298462,771.754761 1796.780762,771.618286 
	C1797.766724,771.590576 1798.476685,771.703796 1799.921143,771.846558 
	C1801.151855,771.808838 1801.647949,771.741699 1802.144165,771.674561 
	C1807.269043,771.673950 1812.718506,773.144775 1817.968872,768.197205 
	C1815.618042,767.825562 1814.370605,767.628357 1812.910522,767.397522 
	C1814.732910,763.046326 1817.174194,765.684021 1819.462646,767.063477 
	C1821.152222,762.894897 1821.529297,759.272705 1818.507812,755.615356 
	C1819.469604,756.368225 1820.444214,757.437988 1821.604248,759.093201 
	C1822.190674,760.137634 1822.591675,760.596558 1822.992676,761.055542 
	C1823.218140,761.160156 1823.443726,761.264648 1824.012451,761.768616 
	C1824.570068,762.552307 1824.784302,762.936462 1824.998535,763.320618 
	C1824.523926,764.860413 1824.049194,766.400146 1823.243652,768.398682 
	C1822.596069,770.212646 1822.279297,771.567810 1821.636597,772.964294 
	C1820.873657,773.004211 1820.436768,773.002869 1819.867432,772.693604 
	C1818.507324,772.608032 1817.279297,772.830444 1816.051392,773.052979 
	C1814.601196,773.064087 1813.151001,773.075256 1810.859131,773.161011 
	C1807.327148,773.467712 1804.636597,773.699829 1801.694824,773.923950 
	C1801.283569,773.965759 1801.123657,774.015442 1800.592041,773.922546 
	C1799.456421,773.819031 1798.692261,773.858093 1797.637207,773.849915 
	C1796.954346,773.803040 1796.562378,773.803406 1795.770508,773.705078 
	C1791.580322,773.735046 1787.790161,773.863831 1784.000000,773.992615 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1760.052246,764.437805 
	C1759.942627,763.708252 1759.899536,763.028931 1760.202637,762.791992 
	C1766.353149,757.981262 1766.525024,757.325317 1763.091309,750.565308 
	C1764.053589,751.507996 1765.552856,752.721436 1765.859375,754.184692 
	C1766.639648,757.909973 1766.651611,761.797058 1767.444580,765.518433 
	C1767.851318,767.427002 1769.036377,768.842041 1771.877075,768.363525 
	C1773.256104,768.131348 1775.042114,770.315308 1776.833984,771.698425 
	C1776.495972,773.484314 1775.967773,774.977173 1775.208008,776.762085 
	C1773.895508,777.311646 1772.814819,777.569214 1771.312256,778.061279 
	C1770.045044,776.824463 1769.199585,775.353149 1768.354126,773.881836 
	C1768.398315,773.478455 1768.442505,773.075012 1768.389160,772.381958 
	C1765.621094,769.626221 1762.950439,767.160095 1760.052246,764.437805 
M1771.868042,771.684387 
	C1772.505859,771.929749 1773.143799,772.175110 1773.781616,772.420471 
	C1773.802979,771.852417 1773.824219,771.284424 1773.845581,770.716370 
	C1773.115112,770.702881 1772.384521,770.689392 1771.868042,771.684387 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1793.290039,751.895752 
	C1794.063110,753.204102 1794.561523,754.731750 1795.055664,756.646973 
	C1793.370483,757.632141 1791.689453,758.229797 1789.717773,758.738708 
	C1787.678467,754.854797 1790.226685,753.418884 1793.290039,751.895752 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1741.222656,759.926392 
	C1741.673584,759.662659 1742.154175,759.713562 1742.634888,759.764465 
	C1742.565674,760.053589 1742.496338,760.342773 1742.427124,760.631897 
	C1742.035522,760.501587 1741.643921,760.371338 1741.222656,759.926392 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1762.152832,694.459045 
	C1762.655518,693.989502 1763.136597,693.908813 1763.974487,693.736328 
	C1764.697876,693.852905 1765.064697,694.061340 1765.180786,694.932007 
	C1764.572632,698.154541 1763.997925,700.713196 1763.977783,703.276123 
	C1763.970825,704.149414 1765.329224,705.763977 1766.113892,705.795593 
	C1771.620728,706.016968 1777.140625,705.912903 1783.108154,705.912903 
	C1782.598877,703.804932 1782.265259,702.424011 1781.931519,701.043091 
	C1782.365967,701.272522 1782.800415,701.501953 1783.459473,701.843750 
	C1787.215332,708.725342 1781.986572,718.310120 1774.346924,719.128418 
	C1771.102295,719.475952 1767.869385,719.931030 1764.475708,720.356750 
	C1763.035156,724.689697 1764.677856,726.389343 1769.165405,725.976624 
	C1771.130737,725.795959 1773.127197,725.954285 1775.562256,726.308472 
	C1776.022827,727.029785 1776.030518,727.401367 1776.038330,727.772949 
	C1773.449341,727.902161 1770.861450,728.099854 1768.271118,728.141846 
	C1765.528564,728.186401 1764.465942,729.525818 1764.921021,732.578735 
	C1764.972778,733.353760 1764.944092,733.711365 1764.897095,734.334229 
	C1763.815796,732.718811 1762.752808,730.838074 1761.233765,728.150452 
	C1759.741333,728.150452 1757.312744,728.150452 1754.440308,728.070435 
	C1754.009521,727.659973 1753.979004,727.333618 1753.875244,726.768311 
	C1756.448242,726.265503 1759.051025,726.005676 1763.043579,725.607056 
	C1761.835938,720.623474 1761.531372,715.551453 1759.346558,711.491211 
	C1757.407471,707.888000 1757.046997,705.490723 1759.270142,702.143921 
	C1760.682983,700.016907 1761.208252,697.300232 1762.152832,694.459045 
M1774.201782,715.673706 
	C1781.484375,718.199707 1779.751099,710.722107 1782.772095,708.244080 
	C1777.917358,707.640930 1773.329468,707.783875 1768.755371,707.592773 
	C1765.492188,707.456299 1764.417725,708.622437 1764.505005,711.925049 
	C1764.595581,715.348938 1765.485229,717.048828 1769.719360,716.234314 
	C1771.078369,715.808472 1772.437500,715.382568 1774.201782,715.673706 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1829.979492,753.985168 
	C1829.237183,750.258606 1830.944458,748.853821 1834.523315,749.080017 
	C1836.160034,749.183472 1837.999390,748.626709 1839.399902,749.206909 
	C1840.802490,749.788025 1841.858276,751.344360 1842.881104,752.621338 
	C1843.197754,753.016602 1842.895508,753.896606 1842.900024,754.554443 
	C1842.955566,762.720581 1842.956787,762.720581 1834.279419,761.916870 
	C1832.591064,758.901001 1831.285278,756.443115 1829.979492,753.985168 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1886.076294,745.079590 
	C1885.885132,745.084167 1885.693970,745.088806 1884.813232,744.988525 
	C1881.035645,745.156250 1877.952881,745.542603 1874.859009,745.677917 
	C1868.213013,745.968689 1861.561401,746.130066 1854.912109,746.344421 
	C1854.568237,746.212646 1854.224487,746.080933 1853.708496,745.734192 
	C1853.684326,745.037415 1853.832397,744.555664 1854.033203,743.701660 
	C1858.493652,743.252136 1862.901611,743.174866 1867.875000,742.943359 
	C1868.398438,742.406860 1868.356323,742.024597 1868.314209,741.642334 
	C1868.483887,740.295349 1868.653442,738.948364 1869.169922,737.631592 
	C1870.381958,739.609375 1867.915527,743.811279 1873.483643,742.932800 
	C1876.920776,742.653076 1879.917847,742.315247 1882.931519,742.024048 
	C1882.947998,742.070740 1883.046753,742.064880 1883.370361,742.100342 
	C1884.493286,742.362793 1885.292603,742.589783 1886.550415,742.884705 
	C1897.295898,743.020996 1907.582886,743.089355 1917.874023,743.345825 
	C1917.878174,743.533997 1917.921997,743.908020 1917.574219,744.102112 
	C1906.843018,744.557312 1896.459595,744.818420 1886.076294,745.079590 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1868.978149,764.254822 
	C1868.396362,765.183899 1867.814575,766.112976 1866.751587,767.780334 
	C1866.489136,768.850220 1866.708008,769.181824 1866.926758,769.513428 
	C1866.531250,769.524170 1866.135864,769.534851 1865.684814,769.273804 
	C1863.709229,768.148926 1861.843628,766.783447 1859.855103,766.571289 
	C1857.329224,766.301941 1854.703613,766.967957 1852.121094,767.230652 
	C1851.675293,767.145325 1851.229370,767.059937 1850.443604,766.982849 
	C1850.081421,766.827332 1850.121094,766.580261 1850.028442,766.511597 
	C1844.668213,762.538330 1844.693237,762.378296 1849.820557,760.026978 
	C1852.431030,760.576355 1853.735107,761.056091 1855.047974,761.080994 
	C1857.237183,761.122681 1859.431763,760.873047 1861.768555,761.125000 
	C1863.917236,761.125000 1866.065918,761.125000 1868.347168,761.573730 
	C1868.479736,762.022522 1868.727051,762.389038 1868.463867,762.174805 
	C1866.590942,762.726685 1864.981323,763.492737 1863.371582,764.258789 
	C1863.534302,764.747314 1863.697021,765.235901 1863.859741,765.724426 
	C1864.937378,765.523254 1866.015015,765.322083 1867.340088,765.089722 
	C1867.925415,764.944702 1868.161255,764.722473 1868.464478,764.351440 
	C1868.634033,764.311035 1868.978149,764.254822 1868.978149,764.254822 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1877.964111,747.768982 
	C1877.968994,747.974060 1877.973877,748.179138 1877.989380,748.691528 
	C1875.949829,749.441467 1873.899780,749.884033 1871.462646,750.410034 
	C1871.197144,752.200195 1870.895630,754.233582 1870.639771,755.958679 
	C1867.718506,755.431396 1865.057251,754.950989 1862.095215,753.989258 
	C1861.708984,751.797913 1861.623291,750.087830 1861.974854,748.257935 
	C1867.596069,748.015015 1872.780151,747.891968 1877.964111,747.768982 
M1864.162109,750.960205 
	C1865.193970,750.960205 1866.225708,750.960205 1867.257568,750.960205 
	C1867.226562,750.597290 1867.195435,750.234436 1867.164429,749.871521 
	C1866.023193,750.065247 1864.881958,750.258972 1864.162109,750.960205 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1878.352173,747.821167 
	C1872.780151,747.891968 1867.596069,748.015015 1861.590210,748.202698 
	C1858.887329,748.324097 1857.006226,748.380798 1855.078613,748.007080 
	C1855.008667,747.290955 1854.985229,747.005188 1854.961792,746.719421 
	C1854.961792,746.719421 1854.899536,746.536804 1854.905762,746.440613 
	C1861.561401,746.130066 1868.213013,745.968689 1874.859009,745.677917 
	C1877.952881,745.542603 1881.035645,745.156250 1884.526489,744.999268 
	C1884.929443,749.553467 1884.929443,753.992004 1884.929443,758.430603 
	C1884.426025,758.460022 1883.922729,758.489502 1883.419312,758.518921 
	C1883.238403,755.133240 1883.057495,751.747559 1882.850464,747.873352 
	C1881.512817,747.873352 1880.126465,747.873352 1878.352173,747.821167 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1855.125244,748.437500 
	C1857.006226,748.380798 1858.887329,748.324097 1861.152832,748.322632 
	C1861.623291,750.087830 1861.708984,751.797913 1861.654541,753.849731 
	C1859.438110,754.975952 1857.362061,755.760376 1855.056396,755.911560 
	C1854.926392,752.998047 1855.025879,750.717773 1855.125244,748.437500 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1829.764893,754.233826 
	C1831.285278,756.443115 1832.591064,758.901001 1833.941895,761.724365 
	C1832.929688,762.082520 1831.872559,762.075134 1830.453857,761.554688 
	C1829.424194,761.403870 1828.756348,761.766113 1828.088379,762.128296 
	C1827.257568,762.541870 1826.426758,762.955383 1825.297241,763.344727 
	C1824.784302,762.936462 1824.570068,762.552307 1824.297974,762.008240 
	C1826.010254,759.393066 1827.780273,756.937805 1829.764893,754.233826 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1813.631104,751.513306 
	C1814.362793,751.971008 1814.728027,752.495361 1815.413574,753.124878 
	C1815.999268,753.672485 1816.264648,754.114929 1816.638916,754.791870 
	C1816.032471,756.079895 1815.534668,757.530029 1814.540527,758.075684 
	C1813.535278,758.627258 1811.619385,758.793579 1810.877319,758.184509 
	C1810.166138,757.601074 1809.999023,755.623657 1810.415161,754.589661 
	C1810.895020,753.397278 1812.275513,752.567261 1813.631104,751.513306 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1852.875000,755.861023 
	C1850.516968,757.178040 1847.716553,758.639526 1846.409912,755.121155 
	C1844.916504,751.099670 1848.228882,749.874512 1851.656250,749.283875 
	C1850.557861,750.943481 1849.100586,752.229553 1847.643311,753.515625 
	C1847.882935,753.964233 1848.122559,754.412842 1848.362183,754.861450 
	C1849.872925,754.392700 1851.383545,753.924011 1853.025146,753.414612 
	C1852.990112,753.984985 1852.932617,754.922974 1852.875000,755.861023 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1853.184570,755.893433 
	C1852.932617,754.922974 1852.990112,753.984985 1853.025146,753.414612 
	C1851.383545,753.924011 1849.872925,754.392700 1848.362183,754.861450 
	C1848.122559,754.412842 1847.882935,753.964233 1847.643311,753.515625 
	C1849.100586,752.229553 1850.557861,750.943481 1852.036865,749.293213 
	C1852.814941,748.236816 1853.571045,747.544678 1854.644531,746.786011 
	C1854.985229,747.005188 1855.008667,747.290955 1855.078613,748.007080 
	C1855.025879,750.717773 1854.926392,752.998047 1854.769775,755.721313 
	C1854.306396,756.084778 1853.900269,756.005249 1853.184570,755.893433 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1821.962524,772.922974 
	C1822.279297,771.567810 1822.596069,770.212646 1823.241455,768.808777 
	C1824.253174,769.423157 1824.936157,770.086304 1825.930664,770.944885 
	C1826.458984,771.568115 1826.675903,771.995911 1826.752930,772.944214 
	C1825.062866,773.284119 1823.512695,773.103516 1821.962524,772.922974 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1828.425903,761.967529 
	C1828.756348,761.766113 1829.424194,761.403870 1830.109619,761.349548 
	C1829.672485,761.707214 1829.217896,761.756958 1828.425903,761.967529 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1822.969727,760.736694 
	C1822.591675,760.596558 1822.190674,760.137634 1821.818481,759.330566 
	C1822.213867,759.460938 1822.580322,759.939453 1822.969727,760.736694 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1873.043701,742.874695 
	C1867.915527,743.811279 1870.381958,739.609375 1869.257690,737.391357 
	C1871.720337,736.370483 1874.442017,735.620056 1877.696045,734.722839 
	C1877.756836,733.866028 1877.870850,732.258972 1878.051758,730.233032 
	C1878.118530,729.814087 1878.556030,729.883240 1878.800781,730.292114 
	C1879.254150,731.691528 1879.462769,732.682129 1879.677246,733.700989 
	C1881.720703,732.693176 1883.375854,731.876953 1885.000732,731.421814 
	C1884.414062,732.998413 1883.857788,734.213867 1883.049805,735.979614 
	C1889.144775,735.979614 1894.526855,735.979614 1900.281982,736.137085 
	C1902.291016,736.477234 1903.927246,736.659973 1905.914185,736.881897 
	C1905.914185,733.059204 1905.914185,729.978943 1905.914185,726.898682 
	C1905.914185,726.898682 1905.991455,726.966370 1906.267334,727.184448 
	C1907.557495,728.611816 1908.509277,730.796387 1909.596924,730.866394 
	C1915.129150,731.222229 1920.696655,731.030396 1926.532227,731.030396 
	C1926.755127,729.144592 1926.923340,727.722351 1927.091431,726.300110 
	C1936.727173,726.200256 1946.363037,726.100342 1956.936768,726.015991 
	C1965.119019,726.031433 1972.363159,726.031433 1979.467285,726.031433 
	C1980.538940,732.077454 1980.538940,732.077454 1978.017334,732.860107 
	C1975.111328,732.615906 1972.201294,732.131409 1969.300049,732.178162 
	C1963.498413,732.271729 1957.701660,732.656372 1951.268433,732.949707 
	C1951.998901,731.507446 1952.460449,730.596252 1953.105591,729.241150 
	C1953.182617,728.867737 1953.075928,728.938171 1952.969238,729.008667 
	C1949.851807,729.348450 1946.734375,729.688110 1942.407349,730.159607 
	C1945.104614,730.716248 1946.722412,731.050110 1948.340088,731.383911 
	C1948.344849,731.912354 1948.349609,732.440735 1948.354248,732.969177 
	C1941.696777,732.969177 1935.039185,732.956848 1928.381592,732.972717 
	C1921.994629,732.987915 1921.874878,733.003601 1922.027710,739.459778 
	C1922.254150,749.031311 1922.670410,758.598389 1923.018433,768.611145 
	C1923.040039,773.019104 1923.049194,776.983215 1923.114746,781.395203 
	C1923.001099,783.313782 1922.831055,784.784546 1922.619263,786.614624 
	C1918.325806,786.614624 1914.138306,786.614624 1909.950806,786.614624 
	C1909.919189,786.421448 1909.887451,786.228271 1909.855835,786.035095 
	C1913.361450,785.525085 1916.867065,785.015076 1920.978394,784.416931 
	C1920.978394,773.592590 1921.036865,762.669678 1920.929199,751.748413 
	C1920.907593,749.565063 1920.252563,747.388000 1919.446289,744.878662 
	C1919.001953,744.366089 1919.000977,744.182922 1919.195801,743.882324 
	C1919.650146,743.577209 1919.809448,743.326599 1920.200195,742.768433 
	C1920.138916,739.450195 1920.803223,734.883850 1919.092407,733.672974 
	C1916.853882,732.088440 1912.702759,732.860291 1909.451782,733.255737 
	C1908.428955,733.380066 1907.763062,735.657349 1906.726318,736.752747 
	C1906.137329,737.374817 1905.141846,737.913147 1904.305420,737.950378 
	C1899.386230,738.169678 1894.399170,737.817566 1889.562988,738.528625 
	C1887.337402,738.855835 1882.903076,736.801331 1883.046753,742.064880 
	C1883.046753,742.064880 1882.947998,742.070740 1882.537354,741.965576 
	C1878.988892,741.212891 1875.716675,739.360840 1873.043701,742.874695 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1776.499023,727.812500 
	C1776.030518,727.401367 1776.022827,727.029785 1776.017822,726.379395 
	C1782.316772,726.001221 1788.613037,725.901855 1795.816650,725.796265 
	C1798.535156,725.764587 1800.346436,725.739136 1802.614502,725.737793 
	C1813.038086,725.500732 1823.004761,725.239624 1833.302612,724.989502 
	C1834.075073,725.010864 1834.516479,725.021240 1834.957764,725.031616 
	C1842.216919,725.040039 1849.476318,725.087402 1856.734863,725.027588 
	C1858.405273,725.013855 1860.072632,724.632324 1861.875977,724.706909 
	C1861.698730,725.408508 1861.386963,725.823181 1860.762939,726.646118 
	C1860.244507,727.119934 1860.038452,727.185608 1859.832397,727.251221 
	C1851.746948,727.301147 1843.661621,727.351135 1835.245361,727.657959 
	C1834.508423,727.918457 1834.102417,727.922119 1833.391846,727.918701 
	C1822.911011,727.767456 1812.734619,727.623413 1802.164429,727.637939 
	C1800.149780,727.808472 1798.528931,727.820312 1796.236816,727.810547 
	C1795.388184,727.819214 1795.210449,727.849487 1795.032837,727.879700 
	C1789.008423,727.870483 1782.984131,727.861206 1776.499023,727.812500 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1859.865601,727.649048 
	C1860.038452,727.185608 1860.244507,727.119934 1860.746338,726.919495 
	C1866.176025,726.893555 1871.310181,727.002441 1876.780273,727.357422 
	C1872.970093,731.445374 1863.444946,731.690674 1859.865601,727.649048 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1868.186401,741.930542 
	C1868.356323,742.024597 1868.398438,742.406860 1868.183105,742.812012 
	C1867.970093,742.629517 1868.014282,742.424072 1868.186401,741.930542 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1795.112061,728.113770 
	C1795.210449,727.849487 1795.388184,727.819214 1795.829224,727.850830 
	C1796.066284,728.091553 1796.039917,728.270508 1795.968262,728.716675 
	C1795.603516,728.858948 1795.359619,728.646851 1795.112061,728.113770 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1800.834473,757.343079 
	C1801.821045,755.448242 1802.852051,753.888062 1803.941650,751.957275 
	C1805.316895,753.431702 1806.633789,755.276794 1807.950684,757.121887 
	C1806.222534,758.023804 1804.494507,758.925720 1802.266357,759.523438 
	C1801.518555,758.987183 1801.233032,758.923340 1800.909790,759.027710 
	C1800.899414,758.577698 1800.889038,758.127747 1800.834473,757.343079 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1800.627441,759.270142 
	C1801.233032,758.923340 1801.518555,758.987183 1801.882324,759.412598 
	C1802.018433,763.336243 1802.038574,767.066528 1802.101440,771.235718 
	C1801.647949,771.741699 1801.151855,771.808838 1800.281250,771.843872 
	C1800.052979,767.712097 1800.199097,763.612305 1800.627441,759.270142 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1705.973633,730.501160 
	C1707.426514,730.403015 1708.847534,730.637390 1710.798462,731.260864 
	C1712.544800,732.111572 1713.761230,732.573181 1714.991211,733.020447 
	C1715.004883,733.006104 1714.971558,733.023132 1714.999512,733.340454 
	C1715.351074,733.933350 1715.674683,734.208984 1716.063232,734.596802 
	C1716.235840,734.849548 1716.376709,734.936035 1716.550659,734.968506 
	C1717.117676,735.740112 1717.684692,736.511719 1718.576538,737.725220 
	C1711.585083,737.725220 1705.048828,737.725220 1698.512451,737.725220 
	C1698.365845,737.418396 1698.219238,737.111572 1698.072632,736.804749 
	C1700.695679,734.814392 1703.318726,732.824036 1705.973633,730.501160 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1716.968018,734.978760 
	C1716.376709,734.936035 1716.235840,734.849548 1716.018311,734.270996 
	C1715.596069,733.563110 1715.283813,733.293152 1714.971558,733.023193 
	C1714.971558,733.023132 1715.004883,733.006104 1714.885620,732.691406 
	C1714.536255,731.293091 1714.306030,730.209351 1714.075928,729.125671 
	C1716.124023,729.168457 1718.171997,729.211182 1720.551025,729.232178 
	C1720.881836,731.275452 1720.881836,733.340576 1720.881836,735.780334 
	C1719.451172,735.456604 1718.418213,735.222839 1716.968018,734.978760 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1672.514526,734.987732 
	C1672.071411,734.961609 1671.868530,734.962952 1671.327637,734.514404 
	C1669.777222,733.387756 1668.610962,732.253845 1667.342896,732.125488 
	C1664.314575,731.819214 1661.238037,731.986938 1658.163330,731.576965 
	C1660.038452,730.384949 1661.931030,729.579285 1663.925903,728.730103 
	C1662.430908,726.622925 1656.488403,729.743958 1659.341187,723.997925 
	C1663.279541,724.346191 1667.420410,722.663757 1668.886597,726.628540 
	C1670.960938,725.660706 1672.721313,724.839233 1674.584473,724.450012 
	C1674.843506,726.944702 1674.999878,729.007202 1675.120361,731.470703 
	C1676.062378,732.459839 1677.011353,733.486389 1678.023926,733.553528 
	C1680.995728,733.750671 1683.992798,733.566406 1686.979980,733.531494 
	C1686.908691,733.846008 1686.903809,734.161560 1686.969360,734.715210 
	C1682.884155,734.963196 1678.794678,734.973999 1674.211426,734.971802 
	C1673.398682,735.014282 1673.077759,735.033142 1672.514526,734.987732 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1687.217407,733.442017 
	C1683.992798,733.566406 1680.995728,733.750671 1678.023926,733.553528 
	C1677.011353,733.486389 1676.062378,732.459839 1675.441772,731.497803 
	C1676.561768,730.863770 1677.324341,730.603821 1678.343506,730.137146 
	C1679.370117,729.596008 1680.140381,729.261536 1681.171387,729.130737 
	C1686.313721,733.455200 1686.436890,725.915588 1689.843994,726.489197 
	C1689.089111,725.068542 1688.590576,724.130432 1688.331665,723.107056 
	C1688.764282,722.752991 1688.957397,722.484314 1689.409424,722.186951 
	C1690.011475,722.143250 1690.354370,722.128235 1690.769043,722.479126 
	C1691.229004,724.226624 1691.617432,725.608276 1692.005859,726.989929 
	C1692.003662,727.438538 1692.001587,727.887085 1692.002319,728.672180 
	C1691.244385,730.356750 1690.483521,731.704895 1689.103516,733.110718 
	C1688.309326,733.246094 1688.134521,733.323792 1687.959839,733.401428 
	C1687.791626,733.385132 1687.623291,733.368896 1687.217407,733.442017 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1688.072876,733.543579 
	C1688.134521,733.323792 1688.309326,733.246094 1688.764893,733.227051 
	C1688.759155,733.419067 1688.472534,733.552429 1688.072876,733.543579 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1253.868774,847.028015 
	C1254.232056,848.023438 1254.242310,849.047241 1254.264160,850.454834 
	C1253.711182,855.609314 1250.387085,853.644653 1247.236572,852.957336 
	C1249.329590,850.990356 1251.422729,849.023315 1253.868774,847.028015 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1246.724121,850.809692 
	C1245.775146,849.303955 1245.080811,847.542969 1244.126587,845.123047 
	C1247.252075,845.123047 1249.138306,845.123047 1252.101929,845.123047 
	C1250.109863,847.234924 1248.544312,848.894714 1246.724121,850.809692 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1348.801514,828.745667 
	C1348.342773,829.449463 1347.699707,829.908569 1346.980957,830.682617 
	C1346.489624,830.212341 1346.073975,829.427124 1345.658325,828.641907 
	C1346.644531,828.594910 1347.630737,828.547913 1348.801514,828.745667 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1852.003662,767.620117 
	C1854.703613,766.967957 1857.329224,766.301941 1859.855103,766.571289 
	C1861.843628,766.783447 1863.709229,768.148926 1865.587524,769.462646 
	C1863.601929,773.467224 1861.127563,776.834534 1859.871216,780.607300 
	C1858.729126,784.036621 1856.744873,784.129822 1853.939575,784.121338 
	C1850.537109,784.111023 1850.800781,781.850647 1850.928223,779.718811 
	C1851.161987,775.810669 1851.557983,771.912109 1852.003662,767.620117 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1821.636597,772.964294 
	C1823.512695,773.103516 1825.062866,773.284119 1826.844482,773.171814 
	C1829.185547,772.999695 1831.295288,773.120483 1833.510742,773.247253 
	C1834.134521,777.077576 1834.720947,780.678833 1835.409424,784.907471 
	C1830.990967,784.907471 1826.462524,784.907471 1821.431641,784.562134 
	C1820.549927,783.777771 1820.170654,783.338806 1819.791260,782.899841 
	C1819.531250,780.231079 1819.271118,777.562378 1819.157104,774.381958 
	C1819.535278,773.580688 1819.767578,773.291077 1819.999756,773.001526 
	C1820.436768,773.002869 1820.873657,773.004211 1821.636597,772.964294 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1797.928223,773.897156 
	C1798.692261,773.858093 1799.456421,773.819031 1800.582764,774.331177 
	C1800.906982,778.115479 1800.400635,781.432068 1800.958008,784.559021 
	C1801.551025,787.885864 1803.388550,788.327209 1805.419189,784.960449 
	C1806.100342,784.960205 1806.489136,784.957520 1806.877808,784.954834 
	C1808.026611,785.622253 1809.175293,786.289673 1810.661865,786.978455 
	C1810.999756,786.999817 1810.980347,786.984375 1810.980347,786.984375 
	C1807.114990,787.294617 1803.254028,787.777222 1799.383301,787.875610 
	C1793.284424,788.030640 1787.178833,787.927490 1780.625244,787.902649 
	C1781.085327,787.132996 1781.996460,786.394287 1783.237549,785.433105 
	C1787.401733,785.149719 1791.236084,785.088806 1795.105835,785.395142 
	C1797.754883,788.171143 1797.998657,785.966797 1798.045166,784.133423 
	C1798.131836,780.724548 1797.980469,777.309570 1797.928223,773.897156 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1807.238037,784.968506 
	C1806.489136,784.957520 1806.100342,784.960205 1805.022705,784.918701 
	C1803.537964,781.226929 1802.742065,777.579407 1801.946289,773.931885 
	C1804.636597,773.699829 1807.327148,773.467712 1810.435303,773.261963 
	C1808.961426,776.879211 1806.127197,780.418274 1810.978760,784.580933 
	C1809.390625,784.769470 1808.494385,784.875793 1807.238037,784.968506 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1837.702881,781.619263 
	C1837.467773,779.551147 1837.406250,777.649414 1837.838013,775.867249 
	C1837.900146,775.611084 1840.743652,775.433716 1841.578247,776.110229 
	C1842.625244,776.958984 1842.927246,778.726501 1843.550903,780.097473 
	C1841.712036,780.672913 1839.873291,781.248413 1837.702881,781.619263 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1812.642578,776.140381 
	C1814.669312,776.840454 1816.328369,777.693970 1817.987549,778.547546 
	C1817.213867,779.880676 1816.620605,782.131958 1815.623291,782.328003 
	C1814.186279,782.610474 1811.946167,781.807251 1811.039795,780.674561 
	C1810.488159,779.985168 1811.800171,777.804565 1812.642578,776.140381 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1848.963623,778.106812 
	C1848.968262,781.149963 1848.968262,783.750977 1848.968262,786.351990 
	C1848.803467,786.368164 1848.638672,786.384277 1848.473877,786.400452 
	C1848.473877,781.906555 1848.473877,777.412720 1848.473877,772.918823 
	C1848.635620,772.912476 1848.797363,772.906189 1848.959106,772.899902 
	C1848.959106,774.488159 1848.959106,776.076416 1848.963623,778.106812 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1869.000610,763.795532 
	C1868.978149,764.254822 1868.634033,764.311035 1868.203003,764.341553 
	C1867.545532,764.621704 1867.319092,764.871277 1867.092651,765.120850 
	C1866.015015,765.322083 1864.937378,765.523254 1863.859741,765.724426 
	C1863.697021,765.235901 1863.534302,764.747314 1863.371582,764.258789 
	C1864.981323,763.492737 1866.590942,762.726685 1868.580078,762.189758 
	C1868.980713,762.724731 1869.001953,763.030518 1869.000610,763.795532 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1819.867432,772.693604 
	C1819.767578,773.291077 1819.535278,773.580688 1819.045410,773.996948 
	C1817.990845,773.939392 1817.193970,773.755066 1816.224243,773.311829 
	C1817.279297,772.830444 1818.507324,772.608032 1819.867432,772.693604 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1813.686279,786.496948 
	C1814.424805,786.506592 1814.944214,786.704346 1815.463867,786.902100 
	C1815.332886,787.147766 1815.201904,787.393433 1815.070801,787.639038 
	C1814.536377,787.321045 1814.001831,787.003113 1813.686279,786.496948 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1819.535645,783.040527 
	C1820.170654,783.338806 1820.549927,783.777771 1820.968262,784.513428 
	C1820.038696,784.858887 1819.070190,784.907715 1817.323120,784.995850 
	C1818.303833,784.086487 1818.791992,783.633850 1819.535645,783.040527 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1867.128174,769.285461 
	C1866.708008,769.181824 1866.489136,768.850220 1866.566406,767.999207 
	C1867.018066,768.005737 1867.173828,768.531616 1867.128174,769.285461 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1836.387207,773.545959 
	C1836.322510,773.464050 1836.562378,773.433228 1836.562378,773.433228 
	C1836.562378,773.433228 1836.451782,773.627869 1836.387207,773.545959 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1811.007324,787.009521 
	C1811.102783,786.980408 1811.190674,786.941650 1811.129395,786.943604 
	C1810.980347,786.984375 1810.999756,786.999817 1811.007324,787.009521 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1767.871826,773.918091 
	C1769.199585,775.353149 1770.045044,776.824463 1771.139282,778.371704 
	C1772.382690,780.426880 1773.377319,782.406250 1774.558960,784.757629 
	C1768.758179,784.757629 1763.400146,784.757629 1758.548828,784.757629 
	C1758.293823,781.359802 1758.041992,778.004578 1757.738037,773.954285 
	C1760.375977,773.954285 1763.882812,773.954285 1767.871826,773.918091 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1756.851074,780.078247 
	C1756.034058,783.719238 1753.573242,783.398071 1751.427979,782.525452 
	C1750.407104,782.110229 1749.095337,780.175232 1749.345825,779.378662 
	C1749.752686,778.085876 1751.708008,776.075623 1752.350586,776.295349 
	C1754.040649,776.873291 1755.375610,778.489441 1756.851074,780.078247 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1795.070312,785.027893 
	C1791.236084,785.088806 1787.401733,785.149719 1783.251709,785.112610 
	C1783.318726,781.633301 1783.701416,778.252014 1784.041992,774.431641 
	C1787.790161,773.863831 1791.580322,773.735046 1795.747803,774.174561 
	C1795.773560,778.171143 1795.421997,781.599548 1795.070312,785.027893 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1780.727295,776.769897 
	C1781.242065,776.164917 1781.951660,775.745605 1782.661377,775.326294 
	C1782.417847,775.931335 1782.174194,776.536377 1781.615479,777.240356 
	C1781.174316,777.211365 1781.048340,777.083435 1780.727295,776.769897 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1554.487549,779.970337 
	C1554.887939,780.154236 1554.997314,780.468628 1555.095215,781.025269 
	C1554.234741,781.427612 1553.385620,781.587708 1552.532471,781.398743 
	C1553.084351,780.733459 1553.640381,780.417114 1554.487549,779.970337 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1241.152832,733.221680 
	C1246.158569,733.163025 1251.164429,733.104309 1256.612671,733.229431 
	C1257.272095,733.692932 1257.488892,733.972656 1257.603027,734.728577 
	C1256.079834,740.193176 1254.659546,745.181580 1253.239258,750.170044 
	C1253.037598,750.457336 1252.835815,750.744568 1252.054077,751.101196 
	C1248.687866,751.089355 1245.901733,751.008118 1243.083740,750.508423 
	C1242.418945,744.467285 1241.785889,738.844482 1241.152832,733.221680 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1253.626953,750.107666 
	C1254.659546,745.181580 1256.079834,740.193176 1257.739136,734.845703 
	C1259.159302,735.504395 1260.340454,736.522156 1261.627930,737.631409 
	C1270.434570,733.284668 1273.990845,734.384216 1280.216553,743.486694 
	C1280.721069,744.224121 1282.209839,744.241577 1282.843628,744.949585 
	C1283.553833,745.742798 1283.811279,746.941345 1284.266357,748.303345 
	C1283.067261,748.681030 1281.867554,748.718994 1280.200684,748.371582 
	C1278.366333,745.779114 1276.999023,743.572144 1275.498413,741.150208 
	C1274.552246,741.824524 1273.539062,742.546509 1272.575928,742.871399 
	C1272.862061,739.463074 1271.426880,737.226562 1268.487427,737.881409 
	C1264.721680,738.720337 1260.257324,739.501648 1259.748047,744.993164 
	C1259.709717,745.395813 1259.858521,745.690369 1260.051025,746.324097 
	C1260.134644,747.344116 1260.174438,748.024902 1260.214233,748.705811 
	C1258.147705,749.152283 1256.081299,749.598816 1253.626953,750.107666 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1352.371582,745.865967 
	C1350.682617,746.034424 1349.292480,746.001099 1347.489502,745.957764 
	C1343.529785,742.551270 1346.150513,738.271118 1345.960205,734.386597 
	C1347.007568,735.121765 1347.526611,735.939636 1347.914307,736.550537 
	C1349.085327,735.572388 1349.960938,734.840942 1350.836548,734.109497 
	C1351.830566,736.652283 1353.065186,739.136292 1353.707275,741.765076 
	C1353.987549,742.911926 1353.053345,744.355408 1352.371582,745.865967 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1350.868896,733.631714 
	C1349.960938,734.840942 1349.085327,735.572388 1347.914307,736.550537 
	C1347.526611,735.939636 1347.007568,735.121765 1346.211914,734.108887 
	C1345.954102,733.585876 1345.939209,733.259338 1345.863525,732.691772 
	C1347.288208,732.287476 1348.739990,732.125854 1350.513306,732.081238 
	C1350.856934,732.516846 1350.879150,732.835327 1350.868896,733.631714 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1206.047363,767.633667 
	C1204.584473,768.111816 1203.420044,768.273438 1202.136719,768.695557 
	C1200.425781,768.839844 1198.833984,768.723572 1197.155273,768.004639 
	C1199.016602,765.177185 1200.964722,762.952332 1203.331909,760.788391 
	C1207.112549,760.688293 1210.497559,760.719727 1213.828979,760.312195 
	C1217.150757,759.905762 1218.598389,757.948975 1217.657471,755.559570 
	C1218.617432,755.107361 1219.586670,754.696655 1220.299927,754.965332 
	C1223.772339,756.273254 1228.511353,754.375916 1230.350830,750.970581 
	C1232.059570,751.042725 1233.369995,751.089600 1234.692627,751.505859 
	C1235.136597,752.917786 1235.568359,753.960327 1236.228149,755.032166 
	C1236.456299,755.061401 1236.910767,755.133789 1236.910645,755.588867 
	C1237.085083,759.778564 1237.259766,763.513306 1237.434570,767.248047 
	C1235.948975,767.359741 1234.463501,767.471497 1232.691162,767.604736 
	C1233.007935,768.353455 1233.448608,769.394836 1233.898926,770.459167 
	C1232.974976,771.561707 1232.051758,772.663391 1231.070557,774.122070 
	C1229.458984,775.028992 1227.905396,775.579041 1225.661499,776.373413 
	C1225.643799,776.362488 1224.703735,775.785706 1223.424316,775.262573 
	C1222.335693,775.195068 1221.586548,775.073914 1220.380859,774.628723 
	C1218.285645,773.530029 1216.647095,772.755310 1215.008423,771.980591 
	C1215.008423,771.980591 1214.999634,771.998840 1215.009277,771.994507 
	C1215.110718,770.635803 1215.202759,769.281494 1215.336060,767.317261 
	C1213.078735,767.317261 1209.712280,767.317261 1206.047363,767.633667 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1237.757812,767.125610 
	C1237.259766,763.513306 1237.085083,759.778564 1236.944580,755.271057 
	C1236.903198,753.937561 1236.827637,753.376892 1236.752075,752.816223 
	C1236.573486,752.815247 1236.394897,752.814209 1236.216187,752.813171 
	C1236.144165,753.543091 1236.072021,754.273010 1236.000000,755.002930 
	C1235.568359,753.960327 1235.136597,752.917786 1234.923828,751.348145 
	C1234.402832,749.715698 1233.662964,748.610291 1233.275146,747.561340 
	C1234.092285,747.718079 1234.557129,747.818359 1235.091919,748.110779 
	C1235.439819,748.194458 1235.717651,748.085938 1236.137451,747.741211 
	C1236.410400,742.762329 1236.541382,738.019531 1236.672363,733.276794 
	C1236.903076,733.243958 1237.133789,733.211121 1237.681885,733.629028 
	C1237.992676,737.572021 1237.919067,741.065918 1237.998535,744.556335 
	C1238.075195,747.929016 1240.523560,749.512878 1243.115479,750.926880 
	C1245.901733,751.008118 1248.687866,751.089355 1251.866943,751.286621 
	C1251.744751,752.534607 1251.229858,753.666504 1250.367310,754.569275 
	C1244.399658,751.359253 1241.243652,753.689514 1241.000000,760.999939 
	C1240.026978,763.001038 1239.054077,765.002136 1237.757812,767.125610 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1260.660034,748.724121 
	C1260.174438,748.024902 1260.134644,747.344116 1260.037964,746.048828 
	C1259.965942,745.251343 1259.950562,745.068237 1259.935303,744.885132 
	C1260.257324,739.501648 1264.721680,738.720337 1268.487427,737.881409 
	C1271.426880,737.226562 1272.862061,739.463074 1272.399170,743.104858 
	C1272.448364,745.156372 1272.724243,746.577209 1272.949219,748.182556 
	C1272.898438,748.367004 1272.798218,748.736206 1272.798218,748.736206 
	C1268.900757,748.738281 1265.003296,748.740417 1260.660034,748.724121 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1278.372559,750.219604 
	C1280.188354,755.380920 1275.410034,755.186584 1272.923950,756.485291 
	C1267.225586,759.462036 1260.850708,756.744202 1259.135132,750.702759 
	C1265.463989,750.310547 1271.690308,750.265015 1278.372559,750.219604 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1241.378418,761.076416 
	C1241.243652,753.689514 1244.399658,751.359253 1250.086914,754.728271 
	C1247.355103,757.128479 1244.556030,759.140686 1241.378418,761.076416 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1273.000000,747.998108 
	C1272.724243,746.577209 1272.448364,745.156372 1272.349243,743.502014 
	C1273.539062,742.546509 1274.552246,741.824524 1275.498413,741.150208 
	C1276.999023,743.572144 1278.366333,745.779114 1279.752563,748.319641 
	C1278.823730,748.678223 1277.876099,748.703369 1276.499268,748.409180 
	C1275.046753,748.059265 1274.023438,748.028687 1273.000000,747.998108 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1282.263794,753.866333 
	C1282.046021,753.073425 1282.108032,752.118652 1282.226807,750.809570 
	C1282.633789,750.373962 1282.983887,750.292725 1283.601807,750.182251 
	C1283.427612,751.336792 1282.985596,752.520569 1282.263794,753.866333 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1272.949219,748.182556 
	C1274.023438,748.028687 1275.046753,748.059265 1276.143311,748.387451 
	C1275.358154,748.771301 1274.499878,748.857544 1273.219971,748.840027 
	C1272.798218,748.736206 1272.898438,748.367004 1272.949219,748.182556 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1235.725464,773.561462 
	C1235.943848,773.481384 1236.376221,773.717285 1236.635254,774.193420 
	C1236.287964,774.248291 1236.113770,774.062866 1235.725464,773.561462 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1409.013306,836.989502 
	C1408.587891,836.834351 1408.149536,836.689880 1407.711182,836.545410 
	C1407.841553,836.284790 1407.971924,836.024231 1408.102295,835.763672 
	C1408.383179,836.021545 1408.664185,836.279358 1408.961426,836.781128 
	C1408.977661,837.025024 1409.000366,837.000244 1409.013306,836.989502 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1405.926514,849.940552 
	C1404.851196,850.176147 1403.813843,850.435242 1402.024780,850.882080 
	C1402.303955,848.330750 1401.953857,845.634277 1403.058350,843.826782 
	C1403.644531,842.867615 1406.755005,843.451111 1409.857666,843.273376 
	C1407.908569,846.041870 1406.802734,847.612549 1405.806396,849.536255 
	C1405.915894,849.889221 1405.964355,849.964111 1405.926514,849.940552 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1458.999023,803.992554 
	C1459.232788,804.184631 1459.499634,804.347351 1459.729370,804.551758 
	C1459.768066,804.586121 1459.646240,804.800964 1459.598633,804.932434 
	C1459.396729,804.742920 1459.194824,804.553406 1459.024780,804.152832 
	C1459.056763,803.941711 1459.019531,803.977112 1458.999023,803.992554 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1251.721436,805.868042 
	C1251.547119,806.539062 1251.064819,807.039917 1250.294434,807.295044 
	C1250.475464,806.598755 1250.944580,806.148376 1251.721436,805.868042 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1249.766113,807.053223 
	C1249.946899,807.446533 1249.848877,807.876343 1249.383545,808.175659 
	C1249.173218,807.726624 1249.330322,807.408142 1249.766113,807.053223 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1385.938477,852.559448 
	C1385.248535,851.488770 1384.903687,850.416443 1384.468750,849.064026 
	C1379.504883,850.775635 1375.239380,856.841492 1369.002930,851.589844 
	C1363.272095,857.326965 1360.722290,852.172119 1357.809692,847.597290 
	C1363.791504,846.984192 1369.730225,846.375610 1376.822754,845.648743 
	C1374.598267,847.576050 1373.187988,848.797913 1371.777710,850.019836 
	C1372.060913,850.496460 1372.344238,850.973022 1372.627563,851.449646 
	C1374.342407,850.578613 1376.621704,850.135498 1377.667114,848.756775 
	C1380.873901,844.527222 1383.171875,843.937866 1387.660278,847.039490 
	C1388.057251,847.313782 1388.470703,847.564331 1388.949219,847.872803 
	C1390.162720,847.122131 1391.352051,846.386414 1393.161133,845.267334 
	C1393.161133,848.028809 1393.161133,850.167053 1393.161133,852.305359 
	C1392.866943,851.853882 1392.572754,851.402344 1392.278442,850.950867 
	C1390.280273,851.486572 1388.281982,852.022217 1385.938477,852.559448 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1358.914062,835.852722 
	C1361.196289,835.247742 1363.794678,834.274719 1365.504028,835.113464 
	C1366.497803,835.601074 1366.065186,838.995728 1366.227173,840.610413 
	C1363.183105,840.874268 1359.978394,841.223999 1356.764160,841.401611 
	C1355.048706,841.496338 1353.228394,841.567932 1351.646729,841.057190 
	C1351.177246,840.905579 1350.795288,838.283875 1351.356812,837.589478 
	C1352.578003,836.079590 1354.432495,835.081909 1356.028198,833.874817 
	C1356.362305,834.342346 1356.696411,834.809875 1357.030518,835.277344 
	C1355.568115,836.481934 1354.105713,837.686462 1352.038574,839.389160 
	C1356.017090,840.273132 1358.053345,839.406189 1358.914062,835.852722 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1380.854492,737.669922 
	C1381.020142,738.701355 1380.989624,739.454224 1380.962646,740.583740 
	C1379.543335,741.660889 1378.120239,742.361450 1376.227783,742.677856 
	C1375.578979,740.976501 1375.399780,739.659180 1375.220581,738.341919 
	C1376.734253,736.315002 1378.349609,734.869324 1380.854492,737.669922 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1371.473389,744.187195 
	C1370.120361,743.746521 1369.073853,743.264282 1368.279541,742.236084 
	C1369.397583,741.148132 1370.263428,740.606262 1371.408569,739.900513 
	C1371.656494,738.998901 1371.625244,738.261108 1371.593994,737.523315 
	C1371.062378,737.692444 1370.530640,737.861572 1369.649658,738.100342 
	C1368.875610,738.005371 1368.450928,737.840820 1368.087891,737.546509 
	C1368.149414,737.416748 1368.097290,737.134277 1368.097290,737.134277 
	C1370.097412,734.952637 1371.996460,735.070557 1373.576050,737.973450 
	C1373.232666,740.339478 1373.112305,742.117004 1372.991943,743.894592 
	C1372.587891,743.978271 1372.183960,744.061951 1371.473389,744.187195 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1373.171875,744.101746 
	C1373.112305,742.117004 1373.232666,740.339478 1373.623047,738.289673 
	C1374.112183,738.079163 1374.331421,738.140930 1374.885742,738.272339 
	C1375.399780,739.659180 1375.578979,740.976501 1375.877686,742.613647 
	C1375.115356,743.392029 1374.233643,743.850464 1373.171875,744.101746 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1366.472900,742.000183 
	C1366.212769,742.051697 1366.053589,742.206726 1365.999146,742.639709 
	C1365.715332,741.203125 1365.427979,739.592163 1365.356934,737.720825 
	C1365.871094,737.981567 1366.168945,738.502563 1366.512939,739.394409 
	C1366.530396,740.510132 1366.501709,741.255188 1366.472900,742.000183 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1366.466919,739.023560 
	C1366.168945,738.502563 1365.871094,737.981567 1365.385986,737.336304 
	C1365.955322,737.129333 1366.711914,737.046631 1367.782837,737.049133 
	C1368.097290,737.134277 1368.149414,737.416748 1367.905762,737.851685 
	C1367.263550,738.532288 1366.865234,738.777954 1366.466919,739.023560 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1366.512939,739.394348 
	C1366.865234,738.777954 1367.263550,738.532288 1367.844116,737.981445 
	C1368.450928,737.840820 1368.875610,738.005371 1369.836670,738.329041 
	C1370.625122,739.013611 1370.877197,739.539001 1371.129395,740.064331 
	C1370.263428,740.606262 1369.397583,741.148132 1368.271484,741.851868 
	C1367.755249,742.000854 1367.499390,741.988098 1366.858154,741.987793 
	C1366.501709,741.255188 1366.530396,740.510132 1366.512939,739.394348 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1451.158203,834.330017 
	C1450.979858,834.405945 1450.609863,834.201111 1450.346924,833.799072 
	C1450.624756,833.751038 1450.795654,833.900085 1451.158203,834.330017 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1235.021973,747.918579 
	C1234.557129,747.818359 1234.092285,747.718079 1233.010742,747.383179 
	C1230.966309,747.833191 1227.993530,744.930176 1227.918213,749.369385 
	C1223.897217,753.177673 1226.909302,746.193604 1224.782104,747.376709 
	C1222.772583,748.001465 1220.394409,749.488708 1219.558350,748.875061 
	C1215.275146,745.730774 1212.904175,749.246277 1209.840576,750.840576 
	C1209.473145,750.425354 1209.251221,750.198975 1209.021240,749.730286 
	C1209.016602,749.484741 1208.993042,748.994080 1208.883301,748.567871 
	C1204.929810,746.970093 1203.542847,748.069397 1204.754883,752.003906 
	C1201.607788,751.533142 1196.855591,754.282837 1197.913696,747.936340 
	C1198.553589,746.527222 1199.193481,745.118042 1200.224609,743.093750 
	C1201.631348,740.902039 1202.646729,739.325439 1203.825684,738.270996 
	C1204.989990,740.675842 1205.981567,744.138184 1206.993652,744.144104 
	C1209.991699,744.161926 1212.997681,742.816101 1216.275391,742.154053 
	C1216.928467,742.481934 1217.306763,742.659302 1217.941162,743.061401 
	C1218.778687,743.214600 1219.359863,743.142883 1219.941162,743.071228 
	C1219.764038,742.708557 1219.587036,742.345886 1219.166016,741.657227 
	C1218.878784,741.063293 1218.835571,740.795227 1219.191528,740.407959 
	C1220.116577,740.436707 1220.642700,740.584778 1221.483398,740.925293 
	C1222.518921,741.058716 1223.239746,740.999573 1224.341064,740.941406 
	C1225.765137,741.310425 1226.808594,741.678467 1227.943848,742.078857 
	C1227.943848,741.360840 1227.943848,739.877625 1227.943848,738.787842 
	C1226.613159,739.222107 1225.308594,739.647888 1223.647705,739.973022 
	C1222.585571,740.022583 1221.879883,740.172729 1220.769775,740.396851 
	C1219.840576,740.460815 1219.315552,740.450928 1218.745728,740.097168 
	C1218.745728,739.526489 1218.790405,739.299683 1219.095215,738.846375 
	C1219.263550,734.958252 1216.895020,735.978516 1214.471436,736.426636 
	C1213.656372,737.562805 1213.182495,738.682983 1212.393311,739.955811 
	C1210.234619,740.802673 1208.390991,741.496765 1206.547485,742.190918 
	C1206.345215,741.769470 1206.143066,741.348022 1205.940796,740.926636 
	C1207.474854,739.758301 1209.008911,738.590027 1209.734985,738.037109 
	C1208.632935,736.007324 1207.821777,734.513123 1207.010498,733.018982 
	C1207.010498,733.018982 1207.001221,733.002686 1207.008057,733.010254 
	C1216.609375,733.053162 1226.203735,733.088501 1236.235352,733.200317 
	C1236.541382,738.019531 1236.410400,742.762329 1235.893921,747.729492 
	C1235.508423,747.953918 1235.021973,747.918579 1235.021973,747.918579 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1197.470947,747.914246 
	C1196.855591,754.282837 1201.607788,751.533142 1205.087891,751.949097 
	C1206.623047,751.940491 1207.367065,751.996277 1208.763672,752.101135 
	C1206.949219,753.044128 1205.776611,753.653625 1204.263672,754.178345 
	C1198.290771,754.421875 1202.421997,756.854004 1202.968018,758.793457 
	C1202.947510,759.624268 1202.930176,760.175903 1202.912842,760.727539 
	C1200.964722,762.952332 1199.016602,765.177185 1196.958740,767.750610 
	C1196.059814,767.961975 1195.270508,767.824890 1194.087891,767.608887 
	C1194.123535,766.656067 1194.456787,765.716187 1195.003540,764.923462 
	C1195.821655,763.737183 1196.789795,762.654358 1197.694214,761.527527 
	C1196.273926,760.991882 1194.861328,760.434082 1193.429443,759.931213 
	C1192.829224,759.720398 1192.188110,759.626160 1191.295898,759.415283 
	C1191.295898,757.446106 1191.295898,755.570312 1191.295898,753.694580 
	C1191.642944,753.385681 1191.990112,753.076843 1192.337158,752.767944 
	C1192.960083,753.987244 1193.583008,755.206604 1194.205933,756.425903 
	C1194.654175,756.190247 1195.102539,755.954590 1195.550781,755.718872 
	C1194.684082,753.518127 1193.817261,751.317383 1192.793823,748.802124 
	C1194.100830,748.289124 1195.564575,748.090637 1197.470947,747.914246 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1203.662231,737.748901 
	C1202.646729,739.325439 1201.631348,740.902039 1200.320801,742.748535 
	C1199.351318,743.013550 1198.676880,743.008728 1197.658813,742.550781 
	C1197.286743,741.062134 1197.258423,740.026611 1197.230225,738.991089 
	C1197.796143,738.403015 1198.362061,737.814941 1198.985352,736.898743 
	C1200.450928,735.760376 1201.859375,734.950134 1203.715088,734.372559 
	C1203.995605,735.653076 1203.828979,736.700989 1203.662231,737.748901 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1192.345947,736.790894 
	C1193.125488,737.327087 1193.952637,738.226318 1193.853271,738.344177 
	C1192.313354,740.170532 1190.653198,741.895569 1188.834473,743.843689 
	C1187.670898,737.410339 1187.670898,737.410339 1192.345947,736.790894 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1196.866699,738.962402 
	C1197.258423,740.026611 1197.286743,741.062134 1197.325562,742.485962 
	C1197.075073,747.080383 1194.239624,746.092041 1190.314209,745.544617 
	C1192.742798,742.950378 1194.622925,740.942078 1196.866699,738.962402 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1203.825684,738.270996 
	C1203.828979,736.700989 1203.995605,735.653076 1204.052246,734.392822 
	C1204.765137,733.641113 1205.588013,733.101685 1206.710693,732.790649 
	C1207.821777,734.513123 1208.632935,736.007324 1209.734985,738.037109 
	C1209.008911,738.590027 1207.474854,739.758301 1205.940796,740.926636 
	C1206.143066,741.348022 1206.345215,741.769470 1206.547485,742.190918 
	C1208.390991,741.496765 1210.234619,740.802673 1212.648682,740.181885 
	C1214.146484,740.838013 1215.073486,741.420776 1216.000610,742.003540 
	C1212.997681,742.816101 1209.991699,744.161926 1206.993652,744.144104 
	C1205.981567,744.138184 1204.989990,740.675842 1203.825684,738.270996 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1295.956421,713.593018 
	C1296.747314,714.603027 1297.687500,715.531006 1298.296387,716.640869 
	C1299.413574,718.677002 1300.335449,720.820251 1301.421387,723.092041 
	C1306.318604,720.966003 1307.475342,726.869995 1310.747314,729.117065 
	C1310.523804,729.640198 1310.300293,730.163330 1310.076904,730.686401 
	C1307.815186,729.833923 1305.553589,728.981445 1303.023682,728.027832 
	C1300.802124,731.308838 1296.919434,731.591553 1291.360352,727.912231 
	C1290.963867,727.956421 1290.993408,727.993164 1291.012695,727.640015 
	C1288.310547,725.161987 1285.738647,721.486511 1282.839355,721.206970 
	C1274.617188,720.414307 1266.260986,721.010071 1257.699707,720.850403 
	C1255.294312,720.748352 1253.146973,720.855042 1250.999512,720.961670 
	C1250.098633,720.793335 1249.197632,720.625061 1247.867188,720.612305 
	C1247.013672,720.515320 1246.589600,720.262878 1246.165649,720.010376 
	C1237.263428,723.272888 1234.970825,714.959534 1229.595703,711.169434 
	C1236.489136,703.892029 1242.742920,696.839722 1249.579346,690.406372 
	C1251.179443,688.900513 1254.747192,689.220947 1257.419189,689.166809 
	C1266.498657,688.982727 1275.582397,688.978760 1284.664429,688.962280 
	C1290.066162,688.952454 1290.658936,689.854736 1289.014893,695.608154 
	C1289.021606,695.998840 1289.028320,695.975525 1288.628052,695.868774 
	C1277.804932,693.687561 1276.764771,694.479553 1276.838379,703.354065 
	C1276.864014,706.448120 1277.921265,707.469299 1280.889038,707.472839 
	C1283.492065,707.475952 1286.094360,707.993530 1288.872925,708.584595 
	C1291.351318,710.454529 1293.653931,712.023804 1295.956421,713.593018 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1294.031006,682.404053 
	C1296.697144,676.960388 1303.771362,674.071716 1309.352173,675.671570 
	C1313.470947,676.852234 1317.727661,677.648254 1321.701904,679.186707 
	C1323.161011,679.751587 1323.973145,681.966431 1325.103638,683.410461 
	C1325.768555,684.259705 1326.478760,685.073364 1327.965820,685.923950 
	C1329.805054,686.651306 1330.846924,687.357666 1331.888672,688.064026 
	C1330.785400,688.703247 1329.750488,689.660522 1328.564575,689.915222 
	C1326.090088,690.446716 1323.535889,690.607056 1320.691528,690.896484 
	C1319.941284,690.815369 1319.513794,690.757751 1319.086426,690.700073 
	C1319.684448,688.787842 1320.282471,686.875610 1320.883301,684.954346 
	C1320.027466,685.000427 1318.079712,685.105408 1315.373535,685.277771 
	C1314.230591,683.857666 1313.845947,682.370178 1313.421631,680.729614 
	C1310.869141,680.998474 1307.539185,679.237610 1307.707764,683.933044 
	C1305.783691,684.713684 1303.859619,685.494385 1301.935547,686.275024 
	C1301.698975,685.848328 1301.462280,685.421631 1301.225586,684.994934 
	C1302.538330,683.750366 1303.851196,682.505859 1305.163940,681.261292 
	C1304.791748,680.803894 1304.419556,680.346436 1304.047485,679.888977 
	C1302.685059,681.094299 1301.268433,682.244263 1299.972168,683.517029 
	C1297.650757,685.796509 1295.657104,686.016663 1294.031006,682.404053 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1290.950928,727.936646 
	C1290.214111,728.694824 1289.486084,730.152893 1288.766724,730.148743 
	C1277.707153,730.084045 1266.648804,729.828918 1255.297363,729.306152 
	C1255.011353,728.280090 1255.018188,727.565796 1255.278564,726.273315 
	C1256.316528,724.734314 1257.100830,723.773560 1258.360107,722.876099 
	C1261.463379,722.985046 1264.092651,723.001343 1266.719971,723.081787 
	C1275.088623,723.337830 1284.019165,720.840576 1290.993408,727.993164 
	C1290.993408,727.993164 1290.963867,727.956421 1290.950928,727.936646 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1311.163818,724.062683 
	C1311.133423,723.876648 1311.103149,723.690674 1311.073364,722.855103 
	C1311.756348,720.353271 1312.438965,718.501160 1313.361450,715.997803 
	C1310.267822,716.144287 1308.509766,716.227539 1306.751709,716.310791 
	C1305.959961,715.843018 1305.168213,715.375183 1303.749512,714.637085 
	C1301.427124,713.230225 1299.731934,712.093750 1298.036621,710.957275 
	C1298.113770,710.647766 1298.278198,710.394836 1299.071411,710.086121 
	C1300.595703,709.179321 1301.578125,708.384949 1302.960205,707.429565 
	C1303.932007,707.240601 1304.504272,707.212769 1305.054932,707.482727 
	C1305.026367,708.179504 1305.019409,708.578552 1304.760620,709.205566 
	C1305.797729,710.951416 1306.844238,712.913574 1308.475586,713.803528 
	C1309.317993,714.263062 1312.349243,712.884705 1312.387939,712.220276 
	C1312.488892,710.483948 1311.486206,708.683533 1310.989380,706.578369 
	C1312.214722,705.885925 1313.444458,705.089905 1314.517212,705.263550 
	C1316.103394,705.520264 1317.578125,706.465881 1319.067627,707.546875 
	C1319.034790,709.871094 1319.034790,711.766541 1319.034790,715.189880 
	C1321.839355,713.545593 1323.419067,712.679504 1324.933716,711.711853 
	C1326.087402,710.974854 1327.158691,710.108887 1328.266602,709.300293 
	C1328.482666,709.507629 1328.698730,709.715027 1329.048096,710.514954 
	C1328.301270,712.697205 1327.421143,714.286865 1326.287842,715.902527 
	C1325.692505,715.950867 1325.350464,715.973267 1324.689941,715.989441 
	C1323.948364,716.010681 1323.525146,716.038147 1322.820557,716.053223 
	C1322.033691,716.243408 1321.528564,716.445923 1320.670776,716.761475 
	C1319.900146,717.876709 1319.481934,718.879089 1319.052246,720.238281 
	C1319.031494,721.071106 1319.022339,721.547180 1318.975098,722.338989 
	C1318.867798,723.073242 1318.798950,723.491577 1318.451416,723.904907 
	C1315.836548,723.954163 1313.500122,724.008423 1311.163818,724.062683 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1305.076660,707.184937 
	C1304.504272,707.212769 1303.932007,707.240601 1303.114014,707.000732 
	C1303.593140,705.600098 1304.317749,704.467102 1305.042358,703.334106 
	C1304.881714,703.074219 1304.720947,702.814270 1304.560303,702.554321 
	C1302.929077,703.317200 1301.297729,704.080139 1299.666504,704.843018 
	C1296.140015,702.113464 1294.156494,699.056946 1297.632080,695.278015 
	C1295.828735,693.596985 1294.271729,692.145569 1292.714722,690.694214 
	C1292.890747,690.249207 1293.066772,689.804138 1293.242676,689.359131 
	C1298.456421,689.302734 1303.703979,687.456726 1308.825073,691.105103 
	C1307.984131,692.843018 1307.177979,693.917664 1306.086670,695.372437 
	C1305.327881,693.777832 1304.853149,692.780151 1304.212524,691.433838 
	C1302.536011,693.218201 1301.262573,694.573547 1299.914307,696.008728 
	C1303.162109,697.142761 1306.021362,698.141174 1308.898438,699.483948 
	C1308.913818,700.517395 1308.911499,701.206543 1308.624512,702.096375 
	C1307.823364,703.770935 1307.306885,705.244690 1306.501831,706.786377 
	C1305.834351,706.964539 1305.455566,707.074768 1305.076660,707.184937 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1308.880737,699.139587 
	C1306.021362,698.141174 1303.162109,697.142761 1299.914307,696.008728 
	C1301.262573,694.573547 1302.536011,693.218201 1304.212524,691.433838 
	C1304.853149,692.780151 1305.327881,693.777832 1306.086670,695.372437 
	C1307.177979,693.917664 1307.984131,692.843018 1308.992676,691.495850 
	C1311.219360,691.785767 1313.243408,692.348145 1316.187012,693.165955 
	C1316.350708,693.027710 1317.449341,692.099426 1318.817261,690.935669 
	C1319.513794,690.757751 1319.941284,690.815369 1320.828857,691.216187 
	C1321.737671,693.211975 1322.186279,694.864563 1322.411133,696.741455 
	C1321.132080,697.651184 1320.076538,698.336670 1318.671997,698.999634 
	C1316.974976,697.971069 1315.626953,696.964966 1315.133667,696.596802 
	C1312.765747,697.559692 1310.823242,698.349670 1308.880737,699.139587 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1229.209229,702.174377 
	C1241.134644,690.368225 1241.134644,690.368225 1246.120850,690.268738 
	C1242.830566,693.956421 1239.971924,697.259583 1237.000122,700.457642 
	C1234.865479,702.754822 1232.742798,705.120667 1230.287354,707.036316 
	C1229.184204,707.896851 1227.281250,707.732178 1225.741333,708.032715 
	C1225.248535,707.143188 1224.755859,706.253662 1224.263062,705.364136 
	C1225.828979,704.383362 1227.395020,703.402527 1229.209229,702.174377 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1289.010254,696.000610 
	C1289.708740,696.105469 1290.945190,696.031494 1291.048340,696.338440 
	C1292.438354,700.474182 1293.670532,704.663025 1294.630371,709.080688 
	C1293.459717,708.166443 1292.796387,706.736938 1291.681274,705.937561 
	C1290.730103,705.255615 1289.259521,705.298157 1287.612549,705.080200 
	C1285.374268,705.140076 1283.542114,705.140076 1280.583984,705.140076 
	C1284.038940,701.390442 1286.533569,698.682983 1289.028320,695.975525 
	C1289.028320,695.975525 1289.021606,695.998840 1289.010254,696.000610 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1232.770020,695.770996 
	C1229.456177,698.852173 1226.393066,701.682861 1222.687988,705.106628 
	C1222.316040,698.939575 1232.191650,689.882690 1237.237061,690.274963 
	C1237.833130,690.864258 1238.429199,691.453552 1239.025269,692.042847 
	C1237.023682,693.202087 1235.022095,694.361328 1232.770020,695.770996 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1307.867310,684.258301 
	C1307.539185,679.237610 1310.869141,680.998474 1313.421631,680.729614 
	C1313.845947,682.370178 1314.230591,683.857666 1314.963623,685.502563 
	C1314.887329,686.237732 1314.243652,687.375366 1314.071655,687.307983 
	C1312.016113,686.503540 1310.030640,685.520142 1307.867310,684.258301 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1288.018555,705.020264 
	C1289.259521,705.298157 1290.730103,705.255615 1291.681274,705.937561 
	C1292.796387,706.736938 1293.459717,708.166443 1294.629639,709.537720 
	C1294.940063,709.752808 1295.136597,710.071472 1295.319580,710.398804 
	C1295.725464,711.108337 1295.948364,711.490417 1296.171387,711.872498 
	C1296.148193,712.160767 1296.125000,712.448975 1296.029053,713.165161 
	C1293.653931,712.023804 1291.351318,710.454529 1288.994751,708.256226 
	C1288.633301,706.758179 1288.325928,705.889221 1288.018555,705.020264 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1245.972900,720.339966 
	C1246.589600,720.262878 1247.013672,720.515320 1247.666260,720.859314 
	C1248.239014,722.344421 1248.583130,723.737976 1248.980225,725.346375 
	C1246.473145,725.866211 1244.266724,726.323669 1242.009399,726.791626 
	C1238.485596,721.754883 1244.438110,722.633850 1245.972900,720.339966 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1257.885010,722.812805 
	C1257.100830,723.773560 1256.316528,724.734314 1255.313232,725.917114 
	C1253.735474,724.669373 1252.376831,723.199524 1251.008789,721.345703 
	C1253.146973,720.855042 1255.294312,720.748352 1257.694336,721.143188 
	C1257.926270,722.034058 1257.905640,722.423462 1257.885010,722.812805 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1253.450073,728.939453 
	C1252.996704,729.628296 1252.198975,730.768616 1251.309326,730.845398 
	C1248.564087,731.081970 1245.784058,730.912720 1242.573242,730.842468 
	C1245.115234,726.325439 1249.166016,728.060364 1253.450073,728.939453 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1296.459961,712.033569 
	C1295.948364,711.490417 1295.725464,711.108337 1295.417480,710.404907 
	C1296.010742,710.291687 1296.689209,710.499817 1297.702148,710.832642 
	C1299.731934,712.093750 1301.427124,713.230225 1303.279297,714.607727 
	C1303.702515,715.924927 1303.968994,717.001038 1304.235474,718.077209 
	C1303.958984,718.303406 1303.682495,718.529663 1303.406006,718.755859 
	C1301.186890,716.568787 1298.967773,714.381653 1296.459961,712.033569 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1299.713379,705.227661 
	C1301.297729,704.080139 1302.929077,703.317200 1304.560303,702.554321 
	C1304.720947,702.814270 1304.881714,703.074219 1305.042358,703.334106 
	C1304.317749,704.467102 1303.593140,705.600098 1302.714600,707.161804 
	C1301.578125,708.384949 1300.595703,709.179321 1299.279053,709.988037 
	C1299.216797,708.539062 1299.488525,707.075684 1299.713379,705.227661 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1243.083740,750.508423 
	C1240.523560,749.512878 1238.075195,747.929016 1237.998535,744.556335 
	C1237.919067,741.065918 1237.992676,737.572021 1238.031250,733.630493 
	C1238.837036,733.151062 1239.610962,733.120911 1240.768799,733.156250 
	C1241.785889,738.844482 1242.418945,744.467285 1243.083740,750.508423 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1318.729980,723.909973 
	C1318.798950,723.491577 1318.867798,723.073242 1319.409912,722.312866 
	C1321.444702,722.050354 1323.006226,722.129883 1324.899902,722.226257 
	C1324.899902,725.087524 1324.899902,727.491089 1324.899902,729.894592 
	C1323.209839,729.926208 1321.519897,729.957825 1319.407471,730.014587 
	C1318.984985,730.039734 1318.797363,729.676758 1318.846680,729.055054 
	C1318.840698,726.925537 1318.785400,725.417786 1318.729980,723.909973 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1306.697021,716.714844 
	C1308.509766,716.227539 1310.267822,716.144287 1313.361450,715.997803 
	C1312.438965,718.501160 1311.756348,720.353271 1311.036865,722.574768 
	C1306.242432,723.474731 1305.146973,721.265808 1306.697021,716.714844 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1318.451416,723.904907 
	C1318.785400,725.417786 1318.840698,726.925537 1318.754395,728.872864 
	C1318.612549,729.312378 1318.133789,729.246155 1317.936279,729.012146 
	C1317.620117,728.891052 1317.501343,729.004028 1317.382690,729.116943 
	C1313.086914,730.782227 1312.442871,727.459717 1311.268799,724.390137 
	C1313.500122,724.008423 1315.836548,723.954163 1318.451416,723.904907 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1317.453857,729.109619 
	C1317.501343,729.004028 1317.620117,728.891052 1317.703003,728.949097 
	C1317.667236,729.119995 1317.525024,729.102356 1317.453857,729.109619 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1246.627197,858.960999 
	C1246.951050,859.100159 1246.957886,859.287048 1246.971924,859.754333 
	C1246.192139,859.954590 1245.405273,859.874390 1243.144165,859.643982 
	C1245.171387,859.237244 1245.740723,859.122925 1246.627197,858.960999 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1246.792725,861.087769 
	C1246.993896,861.187256 1246.987793,861.372681 1246.952148,861.835815 
	C1246.724976,861.922791 1246.511353,861.744385 1246.345459,861.528992 
	C1246.318115,861.493286 1246.500122,861.296204 1246.792725,861.087769 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1235.644531,873.452393 
	C1235.678223,873.718872 1235.540527,873.892273 1235.420654,873.880798 
	C1235.302124,873.869446 1235.201416,873.671448 1235.092896,873.555298 
	C1235.222290,873.462952 1235.351685,873.370605 1235.644531,873.452393 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1490.008789,890.993347 
	C1485.111450,889.584229 1487.895630,887.315857 1489.694092,885.106445 
	C1490.023926,885.017395 1489.999512,884.997559 1489.947021,885.338318 
	C1489.926636,887.457397 1489.958740,889.235779 1489.990845,891.014099 
	C1489.990845,891.014099 1490.001465,890.999390 1490.008789,890.993347 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1490.315063,891.006897 
	C1489.958740,889.235779 1489.926636,887.457397 1489.936279,885.327148 
	C1490.893799,885.078674 1491.809570,885.182251 1492.785645,885.292603 
	C1492.009277,887.356750 1491.324219,889.178223 1490.315063,891.006897 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1741.593994,698.317139 
	C1742.481689,698.111389 1743.088379,698.197144 1743.892334,698.519653 
	C1742.093872,704.299805 1743.553345,715.531067 1746.503052,718.955200 
	C1741.685059,718.581787 1736.866943,718.208374 1732.048950,717.834961 
	C1732.052246,717.564209 1732.055664,717.293457 1732.058960,717.022705 
	C1735.009399,717.022705 1737.959839,717.022705 1741.312744,717.022705 
	C1741.312744,710.644470 1741.312744,704.626587 1741.593994,698.317139 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1730.884521,724.799072 
	C1726.710205,728.131836 1722.764526,727.542114 1721.863892,723.428467 
	C1724.923828,723.469849 1727.890991,723.928284 1730.884521,724.799072 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1723.253418,718.957886 
	C1722.688843,718.477417 1722.457031,717.989929 1722.158691,717.238159 
	C1722.929443,717.215332 1723.766968,717.456726 1724.604370,717.698120 
	C1724.265015,718.115662 1723.925659,718.533203 1723.253418,718.957886 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1834.959229,724.596802 
	C1834.516479,725.021240 1834.075073,725.010864 1833.322510,724.546143 
	C1833.011230,721.865784 1833.011230,719.639709 1833.011230,717.032593 
	C1830.790405,717.032593 1829.343994,717.032593 1828.084106,716.694885 
	C1829.720459,715.689270 1831.170166,715.021301 1832.996704,714.179810 
	C1832.996704,708.890015 1832.996704,703.423035 1832.996704,697.956116 
	C1832.888428,697.591370 1832.997314,697.310913 1833.554321,697.043274 
	C1834.182129,700.834656 1834.643066,704.692505 1834.948608,708.562561 
	C1835.102905,710.515076 1834.975708,712.489929 1834.975708,715.170959 
	C1837.112061,714.467590 1838.647339,713.962158 1840.182495,713.456726 
	C1840.477539,713.913879 1840.772583,714.371033 1841.067627,714.828186 
	C1839.253540,716.250488 1837.439453,717.672852 1834.960693,719.616333 
	C1834.960693,720.556946 1834.960693,722.359497 1834.959229,724.596802 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1853.427246,707.868958 
	C1853.974121,707.868469 1854.160645,707.904968 1854.628906,707.985657 
	C1855.080444,708.613403 1855.250366,709.196899 1855.639160,709.992065 
	C1855.839478,710.541626 1855.820801,710.879517 1855.810913,711.470947 
	C1854.991577,713.361816 1854.163452,714.999207 1853.272095,716.761902 
	C1847.435303,711.909180 1847.455200,711.248535 1853.427246,707.868958 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1876.122803,724.279785 
	C1872.704346,724.752563 1869.236938,724.834534 1865.304688,724.812073 
	C1866.619385,721.225159 1872.728149,720.779907 1876.122803,724.279785 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1802.157715,725.713684 
	C1800.346436,725.739136 1798.535156,725.764587 1796.270752,725.747803 
	C1795.896851,723.697693 1795.976074,721.689880 1796.155396,719.250854 
	C1797.800171,719.767395 1799.344971,720.715027 1801.311035,722.155273 
	C1801.874023,723.669861 1802.015869,724.691772 1802.157715,725.713684 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1802.000000,717.000305 
	C1800.281738,716.681946 1798.563477,716.363647 1796.553101,716.107788 
	C1796.197998,715.645142 1796.135010,715.119995 1796.174927,714.206604 
	C1797.136597,713.014221 1798.161011,712.323120 1798.808105,711.374634 
	C1799.470337,710.404114 1799.762817,709.181458 1800.651855,708.435242 
	C1801.390991,711.534302 1801.695557,714.267273 1802.000000,717.000305 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1874.567017,710.032532 
	C1874.804443,710.400330 1874.733765,710.871826 1874.663086,711.343384 
	C1874.410400,711.194641 1874.157837,711.045837 1873.805664,710.687988 
	C1873.890137,710.364746 1874.074463,710.250549 1874.567017,710.032532 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1802.614502,725.737793 
	C1802.015869,724.691772 1801.874023,723.669861 1801.609131,722.283569 
	C1802.073730,720.989441 1802.572388,719.326233 1803.262573,719.242615 
	C1810.779053,718.331665 1818.325684,717.668091 1826.591187,716.962341 
	C1827.511230,717.002502 1827.704346,717.017517 1827.897461,717.032593 
	C1829.343994,717.032593 1830.790405,717.032593 1833.011230,717.032593 
	C1833.011230,719.639709 1833.011230,721.865784 1832.991333,724.535156 
	C1823.004761,725.239624 1813.038086,725.500732 1802.614502,725.737793 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1828.084106,716.694885 
	C1827.704346,717.017517 1827.511230,717.002502 1827.028809,716.943604 
	C1824.077515,716.438660 1821.415649,715.977600 1818.054321,715.395386 
	C1821.016724,710.367004 1824.189453,705.038879 1819.853027,698.856201 
	C1823.208740,698.994141 1826.397949,699.539124 1829.804932,699.824097 
	C1830.788208,699.063293 1831.553711,698.562561 1832.657959,698.008911 
	C1832.996704,703.423035 1832.996704,708.890015 1832.996704,714.179810 
	C1831.170166,715.021301 1829.720459,715.689270 1828.084106,716.694885 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1802.457764,717.031128 
	C1801.695557,714.267273 1801.390991,711.534302 1801.039795,708.397156 
	C1807.119019,707.530273 1813.244873,707.067505 1819.750244,706.576111 
	C1816.479614,716.137817 1815.919067,716.566589 1807.375732,716.852051 
	C1805.888184,716.901794 1804.402100,716.991028 1802.457764,717.031128 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1805.030884,704.012695 
	C1804.406250,703.987000 1803.781494,703.961365 1802.687134,703.554077 
	C1802.740723,700.936584 1799.702515,697.208740 1804.842285,696.906921 
	C1807.519775,697.148132 1810.197266,697.389404 1813.025391,698.335938 
	C1810.460938,700.698364 1807.745972,702.355591 1805.030884,704.012695 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1805.233643,704.393616 
	C1807.745972,702.355591 1810.460938,700.698364 1813.209473,698.691528 
	C1819.172485,696.516724 1816.854248,702.498840 1819.566040,703.719421 
	C1819.353882,704.071106 1819.141602,704.422791 1818.929443,704.774475 
	C1814.431641,704.774475 1809.933960,704.774475 1805.233643,704.393616 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1804.902588,696.518677 
	C1799.702515,697.208740 1802.740723,700.936584 1802.375244,703.577148 
	C1802.433228,704.113159 1802.287598,704.373901 1802.239624,704.357056 
	C1797.051147,702.530396 1801.929321,699.006592 1800.915039,696.465820 
	C1802.264282,696.354004 1803.613525,696.242249 1804.902588,696.518677 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1816.992798,695.261597 
	C1817.438477,695.417908 1817.863525,695.890503 1818.167725,696.695068 
	C1817.688721,696.543945 1817.330444,696.060913 1816.992798,695.261597 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1818.124878,697.272156 
	C1818.393188,697.089600 1818.809937,697.227966 1819.390625,697.682678 
	C1819.127441,697.863708 1818.700439,697.728394 1818.124878,697.272156 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1689.150391,722.215576 
	C1688.957397,722.484314 1688.764282,722.752991 1687.866089,723.043823 
	C1682.934570,723.383240 1678.708252,723.700562 1674.481812,724.017822 
	C1672.721313,724.839233 1670.960938,725.660706 1668.886597,726.628540 
	C1667.420410,722.663757 1663.279541,724.346191 1659.330078,723.775269 
	C1658.866577,723.522034 1658.977905,723.089844 1658.977905,723.089844 
	C1660.589966,722.253784 1662.202026,721.417664 1664.177856,720.361816 
	C1665.216309,720.535950 1665.891113,720.929810 1666.565796,721.323608 
	C1666.715210,720.926208 1666.864624,720.528809 1667.014038,720.131409 
	C1665.990112,719.818298 1664.966187,719.505188 1663.942261,719.192017 
	C1662.635010,719.109497 1661.327637,719.027039 1660.020386,718.944519 
	C1664.949707,717.189575 1664.197754,713.820496 1663.153931,709.971069 
	C1662.726807,708.396301 1663.643799,706.457031 1664.016968,704.273987 
	C1670.877563,703.157593 1670.543823,709.949036 1673.738770,713.028137 
	C1674.307251,713.576050 1674.954102,714.042847 1675.564819,714.547119 
	C1675.702148,714.261902 1675.839478,713.976746 1676.322754,713.351746 
	C1677.120483,713.010498 1677.572266,713.009094 1678.024170,713.007690 
	C1678.178711,713.529358 1678.333374,714.050964 1678.995117,714.937012 
	C1680.701294,715.830444 1681.900513,716.359436 1683.099609,716.888428 
	C1683.269409,716.475098 1683.439209,716.061707 1683.609009,715.648376 
	C1682.140747,714.786072 1680.672607,713.923828 1679.204346,713.061523 
	C1679.154785,712.587036 1679.104980,712.112549 1679.426514,711.261902 
	C1681.319214,710.197327 1682.840942,709.508911 1684.362671,708.820557 
	C1683.572998,708.215698 1682.783325,707.610901 1682.047607,706.645081 
	C1684.496094,703.625366 1685.509277,705.684753 1685.877197,707.673584 
	C1686.187012,709.347839 1685.741455,711.187683 1685.489868,712.934021 
	C1685.114258,715.542480 1685.967896,717.026428 1688.791382,716.948975 
	C1688.834717,717.117310 1688.877930,717.285645 1688.974121,718.134155 
	C1689.067993,719.948059 1689.109131,721.081787 1689.150391,722.215576 
M1666.768311,714.384399 
	C1666.717163,714.743896 1666.666138,715.103455 1666.615112,715.462952 
	C1666.876343,715.465820 1667.137573,715.468689 1667.398682,715.471558 
	C1667.359497,715.108154 1667.320312,714.744812 1666.768311,714.384399 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1714.819580,710.459167 
	C1708.500122,710.824707 1702.249390,710.852722 1695.373291,710.722229 
	C1694.477173,710.373474 1694.206177,710.183105 1693.935181,709.992737 
	C1694.972778,708.260010 1696.010376,706.527222 1697.201416,704.487061 
	C1699.657104,701.081909 1701.959351,697.984253 1705.182007,693.648132 
	C1708.739502,699.685852 1711.813965,704.903687 1714.819580,710.459167 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1692.385742,726.984009 
	C1691.617432,725.608276 1691.229004,724.226624 1691.108398,722.305603 
	C1692.668823,719.732361 1694.119995,717.773682 1695.144897,715.612854 
	C1695.406860,715.060364 1694.320190,713.868225 1693.853027,712.970032 
	C1693.853027,712.970032 1693.898071,712.485840 1693.956421,712.249878 
	C1695.192261,712.211304 1696.369629,712.408691 1697.658203,713.179565 
	C1699.871826,715.325928 1701.974121,716.898743 1704.097534,718.487305 
	C1703.693848,718.669739 1702.760620,719.091736 1700.199463,720.249573 
	C1703.890869,721.457520 1706.354004,723.009888 1708.696167,722.844910 
	C1711.774048,722.628113 1714.756714,721.059326 1717.780396,720.071899 
	C1722.855835,719.948608 1721.125122,723.109619 1720.784058,725.684753 
	C1713.965942,726.198853 1707.415161,726.692749 1700.247437,727.233154 
	C1700.850464,725.434937 1701.222168,724.326965 1701.693970,722.919800 
	C1699.710205,723.066650 1697.923584,723.198914 1696.136963,723.331177 
	C1696.314697,723.636536 1696.492432,723.941895 1696.670166,724.247253 
	C1695.368652,725.157593 1694.067139,726.067871 1692.385742,726.984009 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1691.938110,688.360840 
	C1695.342651,689.158691 1698.744629,690.374817 1703.112305,691.936096 
	C1700.457031,695.276794 1698.593750,697.621033 1696.381348,699.969116 
	C1694.666626,696.241577 1693.301025,692.510315 1691.938110,688.360840 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1672.280640,695.601685 
	C1674.141357,694.579407 1676.002075,693.557190 1678.113403,692.781494 
	C1677.866333,695.794434 1677.368774,698.560730 1676.798950,701.728149 
	C1675.253784,701.728149 1673.032715,701.728149 1670.716431,701.728149 
	C1670.912720,700.825439 1671.113525,699.901733 1671.327637,698.622070 
	C1671.341064,698.265991 1671.251465,698.121948 1671.571411,698.004944 
	C1672.021118,697.125854 1672.150879,696.363831 1672.280640,695.601685 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1688.688965,716.552246 
	C1685.967896,717.026428 1685.114258,715.542480 1685.489868,712.934021 
	C1685.741455,711.187683 1686.187012,709.347839 1685.877197,707.673584 
	C1685.509277,705.684753 1684.496094,703.625366 1682.050781,706.641907 
	C1682.000000,706.999756 1682.006348,706.993408 1681.575317,707.004272 
	C1680.579834,707.132935 1680.015259,707.250732 1679.450806,707.368530 
	C1678.351074,704.918396 1679.222656,703.567383 1681.818726,703.400085 
	C1683.718140,703.277588 1685.631836,703.376099 1687.730713,703.376099 
	C1688.028564,707.824036 1688.307617,711.989807 1688.688965,716.552246 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1678.057495,712.548584 
	C1677.572266,713.009094 1677.120483,713.010498 1676.329834,713.007385 
	C1674.283691,710.247681 1672.576538,707.492493 1670.402222,703.983643 
	C1673.511963,703.983643 1675.530396,703.983643 1678.090820,703.983643 
	C1678.090820,706.848511 1678.090820,709.468994 1678.057495,712.548584 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1693.495972,712.953552 
	C1694.320190,713.868225 1695.406860,715.060364 1695.144897,715.612854 
	C1694.119995,717.773682 1692.668823,719.732361 1691.036865,721.939697 
	C1690.354370,722.128235 1690.011475,722.143250 1689.409424,722.186890 
	C1689.109131,721.081787 1689.067993,719.948059 1689.032349,718.388367 
	C1689.570923,717.533813 1690.213623,717.188293 1690.614746,716.659607 
	C1691.519531,715.466858 1692.304932,714.183533 1693.495972,712.953552 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1679.516357,692.391174 
	C1681.705322,691.667786 1683.942871,691.322632 1686.614258,690.918213 
	C1687.048096,692.452881 1687.048096,694.046753 1687.048096,695.640686 
	C1686.670166,695.348267 1686.292358,695.055908 1685.914429,694.763489 
	C1684.772217,695.461487 1683.629883,696.159485 1682.129395,696.650757 
	C1681.179565,695.965271 1680.588013,695.486450 1679.996460,695.007690 
	C1679.852661,694.261597 1679.708862,693.515442 1679.516357,692.391174 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1681.250732,696.975464 
	C1681.855347,701.925232 1684.345215,699.253479 1686.405762,697.893799 
	C1687.041138,699.158691 1687.528076,700.128235 1688.283447,701.631958 
	C1685.011230,701.631958 1682.230957,701.631958 1678.888794,701.631958 
	C1679.296143,700.163818 1679.619873,698.996948 1680.291138,697.407227 
	C1680.842651,696.981384 1681.046753,696.978455 1681.250732,696.975464 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1663.793945,702.798462 
	C1662.180664,698.858521 1664.621338,697.340332 1667.879761,696.145264 
	C1668.195435,696.471008 1668.211792,696.751282 1668.081421,697.461182 
	C1667.939819,698.824890 1668.017090,699.754822 1668.094360,700.684753 
	C1666.805054,701.433655 1665.515625,702.182556 1663.793945,702.798462 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1681.996826,707.002930 
	C1682.783325,707.610901 1683.572998,708.215698 1684.362671,708.820557 
	C1682.840942,709.508911 1681.319214,710.197327 1679.514404,710.911987 
	C1679.254272,710.045105 1679.277344,709.151978 1679.711914,708.117920 
	C1680.751099,707.649231 1681.378662,707.321289 1682.006348,706.993408 
	C1682.006348,706.993408 1682.000000,706.999756 1681.996826,707.002930 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1679.118408,713.370117 
	C1680.672607,713.923828 1682.140747,714.786072 1683.609009,715.648376 
	C1683.439209,716.061707 1683.269409,716.475098 1683.099609,716.888428 
	C1681.900513,716.359436 1680.701294,715.830444 1679.190796,715.125854 
	C1678.930542,714.526367 1678.981567,714.102478 1679.118408,713.370117 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1668.392212,700.566895 
	C1668.017090,699.754822 1667.939819,698.824890 1668.075684,697.674866 
	C1668.516479,697.507568 1668.744141,697.560303 1669.256592,697.817627 
	C1669.257568,698.831116 1668.973755,699.640137 1668.392212,700.566895 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1681.405762,697.007690 
	C1681.046753,696.978455 1680.842651,696.981384 1680.334717,697.020325 
	C1680.014282,696.601013 1679.997681,696.145752 1679.988770,695.349060 
	C1680.588013,695.486450 1681.179565,695.965271 1681.823975,696.750732 
	C1681.876831,697.057434 1681.560669,697.039978 1681.405762,697.007690 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1681.575317,707.004272 
	C1681.378662,707.321289 1680.751099,707.649231 1679.836914,707.801208 
	C1679.550293,707.625305 1679.510132,707.491272 1679.480469,707.429871 
	C1680.015259,707.250732 1680.579834,707.132935 1681.575317,707.004272 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1693.673340,710.012207 
	C1694.206177,710.183105 1694.477173,710.373474 1694.910400,710.754456 
	C1694.858276,710.984924 1694.643799,711.024719 1694.108154,711.134155 
	C1693.406738,710.918884 1693.026367,710.634033 1692.646118,710.349121 
	C1692.901123,710.243286 1693.156250,710.137451 1693.673340,710.012207 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1894.298828,693.761719 
	C1896.567871,696.836182 1898.836792,699.910706 1901.204834,703.301025 
	C1901.304077,703.616943 1901.015381,703.887512 1900.571289,703.817688 
	C1898.034058,700.538696 1895.940918,697.329590 1893.959595,694.029663 
	C1894.071533,693.938904 1894.298950,693.761658 1894.298828,693.761719 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1887.820801,694.831970 
	C1888.142700,693.773865 1888.464478,692.715759 1889.179932,691.186035 
	C1890.055054,689.853516 1890.536377,688.992615 1891.017700,688.131714 
	C1891.760498,688.093567 1892.503296,688.055359 1893.590088,688.277588 
	C1893.933960,688.538086 1893.912476,688.922485 1893.912476,688.922485 
	C1893.869629,689.367859 1893.826782,689.813232 1893.478516,690.620361 
	C1892.432129,691.258728 1891.690918,691.535339 1890.726807,692.039307 
	C1889.752563,693.258606 1889.001221,694.250549 1888.142822,695.139648 
	C1888.035767,695.036804 1887.820801,694.831970 1887.820801,694.831970 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1890.819092,687.847351 
	C1890.536377,688.992615 1890.055054,689.853516 1889.332031,690.885254 
	C1888.482300,690.098633 1887.874268,689.141113 1886.697876,687.288635 
	C1888.463135,687.412109 1889.541748,687.487549 1890.819092,687.847351 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1880.986572,704.979492 
	C1881.922363,703.222229 1882.977295,701.585327 1884.473267,699.938782 
	C1884.914307,699.929138 1885.175537,700.143738 1885.080078,700.471558 
	C1883.771484,702.312683 1882.558350,703.825989 1881.345215,705.339233 
	C1881.345337,705.339294 1881.105713,705.099915 1880.986572,704.979492 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1890.949707,691.811890 
	C1891.690918,691.535339 1892.432129,691.258728 1893.478882,690.956970 
	C1893.910645,691.641907 1894.036743,692.352051 1894.230835,693.411865 
	C1894.298950,693.761658 1894.071533,693.938904 1893.704590,693.857788 
	C1892.541626,693.121765 1891.745605,692.466797 1890.949707,691.811890 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1884.781250,699.824890 
	C1885.195801,698.546265 1885.743652,697.371826 1886.674561,696.120605 
	C1887.057617,696.043701 1887.227295,696.190308 1887.173828,696.512024 
	C1886.472046,697.937073 1885.823730,699.040405 1885.175537,700.143738 
	C1885.175537,700.143738 1884.914307,699.929138 1884.781250,699.824890 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1894.217407,688.904419 
	C1893.912476,688.922485 1893.933960,688.538086 1893.956055,688.348022 
	C1894.240723,687.676697 1894.503296,687.195496 1894.765869,686.714233 
	C1895.012817,686.895508 1895.495117,687.188416 1895.470337,687.240967 
	C1895.200195,687.811157 1894.850342,688.343567 1894.217407,688.904419 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1903.080200,685.261597 
	C1903.241333,685.231201 1903.518433,685.393433 1903.826172,685.807495 
	C1903.604736,685.892517 1903.384521,685.690857 1903.080200,685.261597 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1886.974121,695.968811 
	C1886.971802,695.566223 1887.142700,695.295288 1887.612061,694.956543 
	C1887.820801,694.831970 1888.035767,695.036804 1888.014160,695.340088 
	C1887.794678,695.905884 1887.539551,696.088196 1887.227295,696.190308 
	C1887.227295,696.190308 1887.057617,696.043701 1886.974121,695.968811 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1903.900391,724.412048 
	C1901.099365,724.784058 1898.308472,725.040466 1895.549194,724.846802 
	C1894.635620,724.782715 1893.803589,723.559753 1893.122559,722.422546 
	C1893.321289,721.799438 1893.331421,721.620483 1893.341553,721.441467 
	C1893.217285,721.654785 1893.092896,721.868103 1892.968628,722.081543 
	C1891.762329,722.594788 1890.593750,723.246948 1889.340576,723.587402 
	C1887.631226,724.051819 1885.857178,724.278809 1883.743774,724.424683 
	C1882.056885,722.022705 1880.737305,719.803650 1878.797607,716.541931 
	C1881.213257,717.205444 1882.662720,717.603577 1884.653442,718.341187 
	C1885.762451,718.822571 1886.330078,718.964539 1886.961670,719.347656 
	C1887.268677,719.811462 1887.511963,720.034119 1887.886230,720.429260 
	C1888.249878,720.558472 1888.482422,720.515198 1888.714966,720.471924 
	C1888.533691,720.262695 1888.352417,720.053467 1888.041748,719.633423 
	C1887.678711,719.174194 1887.394775,719.015564 1886.930420,718.691162 
	C1886.313354,718.148804 1885.826660,717.861877 1885.339844,717.574951 
	C1886.543335,716.827942 1887.746948,716.080933 1889.171265,715.196838 
	C1885.703247,714.706299 1881.329468,716.249146 1879.591431,710.216309 
	C1886.839111,710.216309 1893.682617,709.624451 1900.363281,710.412720 
	C1905.275269,710.992310 1903.270752,716.033203 1903.991211,719.614502 
	C1904.022461,720.444885 1904.022705,720.900513 1904.036743,721.928223 
	C1904.050659,722.500305 1904.083740,722.964539 1904.083740,722.964539 
	C1904.030151,723.350037 1903.976440,723.735535 1903.900391,724.412048 
M1898.821289,716.712769 
	C1899.701172,716.249207 1900.581177,715.785645 1901.461060,715.322083 
	C1901.368652,715.012146 1901.276245,714.702148 1901.183838,714.392212 
	C1898.133789,714.715637 1895.083740,715.039124 1891.200073,715.450928 
	C1894.083252,716.083618 1896.097168,716.525513 1898.821289,716.712769 
M1896.283447,718.456787 
	C1896.283447,718.456787 1896.587158,718.600342 1896.587158,718.600342 
	C1896.587158,718.600342 1896.517212,718.258484 1896.283447,718.456787 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1887.173828,696.512024 
	C1887.539551,696.088196 1887.794678,695.905884 1888.121094,695.442993 
	C1889.001221,694.250549 1889.752563,693.258606 1890.726807,692.039307 
	C1891.745605,692.466797 1892.541626,693.121765 1893.592651,693.948608 
	C1895.940918,697.329590 1898.034058,700.538696 1900.398438,703.913757 
	C1900.954956,705.202209 1901.240356,706.324707 1901.879639,708.839355 
	C1895.162598,708.839355 1888.802368,708.894897 1882.448364,708.721375 
	C1881.997925,708.709045 1881.593384,707.011841 1881.256348,705.718628 
	C1882.558350,703.825989 1883.771484,702.312683 1885.080078,700.471558 
	C1885.823730,699.040405 1886.472046,697.937073 1887.173828,696.512024 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1753.652100,687.301819 
	C1758.236328,688.320557 1755.636719,692.167786 1756.539673,694.635864 
	C1754.008911,694.797485 1751.478149,694.959045 1748.998779,694.492249 
	C1750.583984,691.676514 1752.118042,689.489136 1753.652100,687.301819 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1753.238037,687.268982 
	C1752.118042,689.489136 1750.583984,691.676514 1748.927002,694.136230 
	C1748.015625,692.282104 1747.227173,690.155518 1746.144775,687.236145 
	C1749.286133,687.236145 1751.055054,687.236145 1753.238037,687.268982 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1717.946777,719.790344 
	C1714.756714,721.059326 1711.774048,722.628113 1708.696167,722.844910 
	C1706.354004,723.009888 1703.890869,721.457520 1700.199463,720.249573 
	C1702.760620,719.091736 1703.693848,718.669739 1704.097534,718.487305 
	C1701.974121,716.898743 1699.871826,715.325928 1697.869141,713.468384 
	C1699.374023,713.119812 1700.779297,713.055908 1702.812256,713.326416 
	C1704.323364,713.743713 1705.206665,713.826538 1706.089966,713.909363 
	C1709.770386,713.557556 1713.450928,713.205750 1717.603760,712.808838 
	C1717.446289,714.368591 1717.343384,715.388672 1717.348022,716.780518 
	C1717.674805,717.937744 1717.894043,718.723267 1717.946777,719.790344 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1713.701660,729.083862 
	C1714.306030,730.209351 1714.536255,731.293091 1714.871948,732.705811 
	C1713.761230,732.573181 1712.544800,732.111572 1711.167236,731.356201 
	C1711.779663,730.389038 1712.553589,729.715576 1713.701660,729.083862 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1697.504150,725.550049 
	C1697.538330,725.954956 1697.434082,726.196655 1697.329834,726.438354 
	C1697.341797,726.087830 1697.353638,725.737366 1697.504150,725.550049 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1822.090576,691.358154 
	C1821.625244,685.936829 1824.528687,686.360840 1828.719727,687.298645 
	C1826.735718,689.589172 1825.177490,691.388000 1823.619263,693.186890 
	C1823.113159,692.707947 1822.607178,692.228943 1822.090576,691.358154 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1781.943604,701.029968 
	C1782.265259,702.424011 1782.598877,703.804932 1783.108154,705.912903 
	C1777.140625,705.912903 1771.620728,706.016968 1766.113892,705.795593 
	C1765.329224,705.763977 1763.970825,704.149414 1763.977783,703.276123 
	C1763.997925,700.713196 1764.572632,698.154541 1765.203247,695.254395 
	C1765.476318,694.914490 1765.451660,694.945068 1765.964600,694.974243 
	C1770.321167,694.979980 1774.164795,694.956604 1778.008545,694.933228 
	C1778.008545,694.933228 1778.030151,694.956787 1778.004883,695.287720 
	C1778.635498,696.408325 1779.291138,697.197998 1779.946899,697.987732 
	C1779.946899,697.987732 1779.953125,698.032043 1779.951050,698.339966 
	C1780.283813,699.095642 1780.618774,699.543518 1780.953735,699.991333 
	C1780.953735,699.991333 1780.963135,700.029480 1781.026489,700.291870 
	C1781.373291,700.699036 1781.656616,700.843811 1781.940063,700.988647 
	C1781.940063,700.988586 1781.955688,701.016846 1781.943604,701.029968 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1778.060059,694.513916 
	C1774.164795,694.956604 1770.321167,694.979980 1765.975586,694.988403 
	C1769.468262,691.543579 1773.788818,692.804199 1778.060059,694.513916 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1779.989746,697.645386 
	C1779.291138,697.197998 1778.635498,696.408325 1778.020020,695.293213 
	C1778.605591,695.102112 1779.150757,695.236328 1779.869995,695.645996 
	C1780.040283,696.381958 1780.036377,696.842468 1779.989746,697.645386 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1787.369873,690.597595 
	C1788.176025,691.233521 1788.774048,691.612183 1789.070068,692.155334 
	C1790.493652,694.767273 1790.641968,697.363403 1787.669189,698.844666 
	C1786.876831,699.239563 1784.217163,697.988220 1784.233154,697.560608 
	C1784.331055,694.943542 1781.645020,690.972961 1787.369873,690.597595 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1780.977539,699.689209 
	C1780.618774,699.543518 1780.283813,699.095642 1779.970825,698.350464 
	C1780.328857,698.497742 1780.665039,698.942444 1780.977539,699.689209 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1781.884033,700.746948 
	C1781.656616,700.843811 1781.373291,700.699036 1781.043701,700.301270 
	C1781.367798,700.031128 1781.644653,700.183472 1781.884033,700.746948 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1672.006836,695.846863 
	C1672.150879,696.363831 1672.021118,697.125854 1671.487305,697.993530 
	C1671.299927,697.430054 1671.516602,696.761047 1672.006836,695.846863 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1867.010620,690.025269 
	C1865.998657,689.642395 1864.991577,689.237610 1863.977417,688.851440 
	C1862.502930,688.290100 1861.023315,687.742310 1859.441406,687.149658 
	C1860.299683,684.613281 1862.427856,684.163086 1870.536011,685.517029 
	C1869.286865,686.819763 1868.124634,688.031982 1867.001221,689.625854 
	C1867.039917,690.007568 1867.012329,690.011597 1867.010620,690.025269 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1846.882202,692.161255 
	C1845.882080,693.699707 1844.973877,695.399475 1843.675659,695.784790 
	C1842.870117,696.023926 1841.165283,694.457458 1840.475220,693.328003 
	C1840.146973,692.791016 1841.307007,690.494019 1841.688599,690.520081 
	C1843.415894,690.638062 1845.105957,691.300781 1846.882202,692.161255 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1904.419067,722.982544 
	C1904.083740,722.964539 1904.050659,722.500305 1904.068848,722.268433 
	C1905.026611,722.036560 1905.966431,722.036560 1906.906128,722.036560 
	C1906.927490,722.229797 1906.948853,722.422974 1906.970215,722.616211 
	C1906.231567,722.744324 1905.493042,722.872437 1904.419067,722.982544 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1706.004395,713.603149 
	C1705.206665,713.826538 1704.323364,713.743713 1703.228027,713.403442 
	C1703.983521,713.196411 1704.951050,713.246704 1706.004395,713.603149 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1811.254395,640.913208 
	C1810.950439,641.213074 1810.616089,641.210571 1810.288330,641.163147 
	C1810.254639,641.158325 1810.231445,640.759583 1810.251343,640.755005 
	C1810.571655,640.682434 1810.899536,640.644165 1811.254395,640.913208 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1820.539673,639.382812 
	C1820.567383,639.483826 1820.427856,639.560181 1820.427856,639.560181 
	C1820.427856,639.560181 1820.511963,639.281860 1820.539673,639.382812 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1843.967896,625.027527 
	C1847.704956,625.129700 1846.955078,628.104736 1846.589722,630.006409 
	C1846.391602,631.036560 1844.338501,631.935059 1842.956787,632.436890 
	C1841.975708,632.793213 1840.717773,632.386902 1839.317871,632.131531 
	C1839.060669,631.774841 1839.069214,631.607605 1839.056152,631.207581 
	C1839.034790,630.974731 1838.986328,631.018555 1839.305664,631.004517 
	C1840.093750,630.320312 1840.562500,629.650208 1841.016846,628.991821 
	C1841.002563,629.003479 1840.977539,629.030701 1841.261841,628.990234 
	C1841.706421,628.626892 1841.866699,628.303955 1842.015503,627.991882 
	C1842.004028,628.002686 1841.988281,628.029968 1842.330322,627.919556 
	C1842.785767,627.195435 1842.899048,626.581665 1843.003784,625.982361 
	C1842.995239,625.996826 1842.970459,626.019653 1843.323486,626.115601 
	C1843.791626,625.797058 1843.906860,625.382507 1844.009399,624.983582 
	C1843.996704,624.999268 1843.967896,625.027527 1843.967896,625.027527 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1837.152466,623.615112 
	C1839.473999,623.505554 1841.764771,623.820068 1844.011719,624.581055 
	C1843.967896,625.027527 1843.996704,624.999268 1843.729492,625.028748 
	C1843.298462,625.378723 1843.134521,625.699219 1842.970459,626.019653 
	C1842.970459,626.019653 1842.995239,625.996826 1842.662109,626.094788 
	C1842.215454,626.805176 1842.101807,627.417542 1841.988281,628.029968 
	C1841.988281,628.029968 1842.004028,628.002686 1841.668335,627.899841 
	C1841.214233,628.208191 1841.095825,628.619446 1840.977539,629.030701 
	C1840.977539,629.030701 1841.002563,629.003479 1840.653931,628.880249 
	C1839.865601,629.510803 1839.425903,630.264648 1838.986328,631.018555 
	C1838.986328,631.018555 1839.034790,630.974731 1839.061768,630.956543 
	C1834.931763,629.636902 1837.828613,626.324280 1837.152466,623.615112 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2202.179199,875.714478 
	C2200.530518,874.372986 2199.124512,872.693909 2197.692871,870.676758 
	C2202.013184,870.039490 2206.358643,869.740234 2211.234863,869.367798 
	C2212.803955,870.566162 2213.842529,871.837585 2214.922363,873.323120 
	C2214.963623,873.537170 2215.034424,873.967468 2215.034424,873.967468 
	C2210.830322,874.437317 2206.625977,874.907104 2202.179199,875.714478 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2215.411865,874.056763 
	C2215.034424,873.967468 2214.963623,873.537170 2214.997559,873.251831 
	C2215.031494,872.966492 2215.181396,872.823425 2215.573975,872.857178 
	C2219.315430,872.878113 2222.701904,873.149231 2225.995605,872.716248 
	C2227.357910,872.537109 2228.522217,870.849487 2229.980469,869.608398 
	C2230.775879,868.794067 2231.367188,868.219177 2231.958496,867.644409 
	C2231.333740,869.474426 2230.708740,871.304382 2229.999023,873.489136 
	C2225.205811,873.944580 2220.497559,874.045349 2215.411865,874.056763 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2263.380127,873.999023 
	C2263.964844,873.740784 2264.918945,873.505188 2265.917480,873.591309 
	C2265.224609,873.949280 2264.486816,873.985474 2263.380127,873.999023 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2286.642578,887.066406 
	C2287.508545,888.433472 2287.989746,889.908386 2288.245117,891.713257 
	C2287.432373,890.420227 2286.844971,888.797180 2286.642578,887.066406 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1978.012451,733.146057 
	C1980.538940,732.077454 1980.538940,732.077454 1979.467285,726.031433 
	C1972.363159,726.031433 1965.119019,726.031433 1957.402100,725.984253 
	C1956.950317,725.442139 1956.971191,724.947266 1957.040771,723.737000 
	C1958.834717,722.390381 1961.126587,720.726196 1962.228516,721.309570 
	C1966.612671,723.630676 1969.374390,720.818542 1972.753662,718.865417 
	C1973.282959,718.871826 1973.593140,718.916687 1973.908447,718.926331 
	C1974.793823,723.854187 1979.154297,723.003967 1982.353882,723.763306 
	C1984.522583,724.277954 1986.901001,723.909241 1989.638916,724.029541 
	C1990.109741,724.468140 1990.127319,724.812866 1989.723022,725.549316 
	C1987.639404,725.980164 1985.952637,725.865051 1984.326416,726.125916 
	C1983.558838,726.249023 1982.247803,727.257202 1982.328003,727.578613 
	C1982.553711,728.483337 1983.373413,729.239929 1983.844849,730.333984 
	C1983.816040,731.736267 1983.901367,732.859497 1983.986694,733.982666 
	C1981.817261,736.399414 1979.647705,738.816162 1977.344360,741.520508 
	C1976.377319,741.832642 1975.544312,741.857117 1974.425293,741.555725 
	C1975.449097,739.009949 1976.758789,736.790161 1978.068359,734.570312 
	C1978.048096,734.190857 1978.027832,733.811462 1978.012451,733.146057 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1945.249146,720.577026 
	C1944.942627,720.411011 1944.636230,720.244995 1944.145508,719.903442 
	C1943.952759,718.631775 1943.944214,717.535583 1943.867554,715.891663 
	C1944.440308,714.743347 1945.081299,714.142761 1945.891113,713.311523 
	C1949.836304,713.080872 1953.612427,713.080872 1957.388672,713.080872 
	C1957.549805,713.408020 1957.710938,713.735229 1957.872070,714.062378 
	C1956.365723,715.078125 1954.859253,716.093872 1953.352905,717.109558 
	C1953.442871,717.531128 1953.532837,717.952698 1953.622803,718.374207 
	C1955.157959,717.939819 1956.692993,717.505432 1958.595215,717.041138 
	C1958.962402,717.011230 1958.971558,716.963684 1959.001465,717.318970 
	C1956.471802,721.567993 1953.610229,721.417542 1950.819824,718.559570 
	C1948.840698,719.276306 1947.044922,719.926697 1945.249146,720.577026 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1973.965576,718.572144 
	C1973.593140,718.916687 1973.282959,718.871826 1972.618652,718.446533 
	C1970.544556,718.183228 1968.829834,718.265015 1968.099854,718.299805 
	C1966.035522,717.624390 1964.658691,716.959412 1963.218262,716.764221 
	C1961.837036,716.577209 1960.389771,716.877563 1958.971558,716.963684 
	C1958.971558,716.963684 1958.962402,717.011230 1958.981323,717.025146 
	C1960.049316,712.444641 1962.271973,711.443848 1966.779297,713.078918 
	C1968.818970,713.818909 1971.108887,713.868958 1974.022827,714.354980 
	C1974.022827,714.819519 1974.022827,716.518738 1973.965576,718.572144 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1965.442749,687.238770 
	C1967.006836,682.607117 1970.524170,682.687378 1974.513184,683.337585 
	C1974.513184,686.130676 1974.513184,688.723267 1974.412842,691.600708 
	C1972.779663,691.894348 1971.246948,691.903137 1969.267090,691.786987 
	C1968.864746,691.242493 1968.909424,690.822876 1968.941895,689.746826 
	C1969.239014,685.701904 1967.551025,686.074524 1965.442749,687.238770 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1956.739746,701.818726 
	C1956.807495,700.854736 1957.195435,700.019775 1957.572876,699.207458 
	C1956.730835,698.284302 1955.447266,696.877319 1953.369751,694.599854 
	C1955.846313,694.234131 1958.390137,693.296875 1958.835205,693.902161 
	C1959.988281,695.470947 1960.615967,697.720032 1960.611328,699.695557 
	C1960.609619,700.449036 1958.311768,701.197144 1956.739746,701.818726 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1945.479980,709.569946 
	C1944.004395,709.305969 1942.949463,709.091858 1941.487793,708.895630 
	C1942.169189,707.602905 1943.257446,706.292236 1944.687012,704.969666 
	C1945.028320,704.957703 1945.493042,705.065552 1946.188965,705.052368 
	C1948.036621,704.706604 1949.188477,704.374023 1950.340332,704.041382 
	C1952.535767,708.826111 1948.954590,709.013184 1945.479980,709.569946 
M1948.338623,707.079712 
	C1947.386719,706.715027 1946.434937,706.350281 1945.483032,705.985535 
	C1945.360596,706.447449 1945.238037,706.909363 1945.115479,707.371277 
	C1946.018311,707.438965 1946.921143,707.506653 1948.338623,707.079712 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2022.562988,714.125977 
	C2021.751953,714.794983 2020.594849,715.402527 2019.437744,716.010010 
	C2019.240601,715.476074 2019.043335,714.942139 2018.846069,714.408142 
	C2019.969727,714.293579 2021.093384,714.179016 2022.562988,714.125977 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1950.176270,703.846619 
	C1949.188477,704.374023 1948.036621,704.706604 1946.419678,704.992004 
	C1946.742920,703.861877 1947.531128,702.778992 1948.394043,701.593628 
	C1949.060425,702.441162 1949.536377,703.046448 1950.176270,703.846619 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1899.909058,735.979614 
	C1894.526855,735.979614 1889.144775,735.979614 1883.049805,735.979614 
	C1883.857788,734.213867 1884.414062,732.998413 1885.012451,731.400513 
	C1885.054565,731.018005 1885.041748,730.970886 1885.339600,730.881897 
	C1886.111206,730.137878 1886.584961,729.482849 1887.058716,728.827759 
	C1889.146362,728.380920 1891.233887,727.934082 1893.808350,727.930420 
	C1896.166504,730.908936 1898.037842,733.444275 1899.909058,735.979614 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1959.001465,717.318970 
	C1960.389771,716.877563 1961.837036,716.577209 1963.218262,716.764221 
	C1964.658691,716.959412 1966.035522,717.624390 1968.099854,718.299805 
	C1968.829834,718.265015 1970.544556,718.183228 1972.394287,718.520264 
	C1969.374390,720.818542 1966.612671,723.630676 1962.228516,721.309570 
	C1961.126587,720.726196 1958.834717,722.390381 1957.038086,723.364136 
	C1953.303223,724.015503 1949.619873,724.324219 1945.573853,724.539795 
	C1945.174683,723.368042 1945.138184,722.289612 1945.175537,720.894043 
	C1947.044922,719.926697 1948.840698,719.276306 1950.819824,718.559570 
	C1953.610229,721.417542 1956.471802,721.567993 1959.001465,717.318970 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1926.642578,726.387207 
	C1926.923340,727.722351 1926.755127,729.144592 1926.532227,731.030396 
	C1920.696655,731.030396 1915.129150,731.222229 1909.596924,730.866394 
	C1908.509277,730.796387 1907.557495,728.611816 1906.241455,727.139893 
	C1907.097900,726.689087 1908.255981,726.500854 1909.770874,726.751343 
	C1914.570068,727.754578 1919.005371,728.398865 1923.463013,728.787354 
	C1924.052124,728.838562 1924.754883,727.585754 1925.405029,726.936890 
	C1925.667969,726.782715 1925.930908,726.628540 1926.642578,726.387207 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1900.281982,736.137085 
	C1898.037842,733.444275 1896.166504,730.908936 1894.137939,728.059326 
	C1894.537964,727.288574 1895.095459,726.832092 1895.960938,726.736084 
	C1898.572021,729.269287 1900.875366,731.442078 1903.746582,734.150757 
	C1903.937134,730.992126 1904.063599,728.895569 1904.190186,726.798950 
	C1904.572876,726.834717 1904.955688,726.870422 1905.626221,726.902466 
	C1905.914185,729.978943 1905.914185,733.059204 1905.914185,736.881897 
	C1903.927246,736.659973 1902.291016,736.477234 1900.281982,736.137085 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1925.069824,726.656494 
	C1924.754883,727.585754 1924.052124,728.838562 1923.463013,728.787354 
	C1919.005371,728.398865 1914.570068,727.754578 1910.142578,726.808472 
	C1915.016479,726.409912 1919.875610,726.393005 1925.069824,726.656494 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1903.811890,726.645569 
	C1904.063599,728.895569 1903.937134,730.992126 1903.746582,734.150757 
	C1900.875366,731.442078 1898.572021,729.269287 1896.231689,726.795593 
	C1898.607666,726.493896 1901.020630,726.493042 1903.811890,726.645569 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1927.921265,710.476807 
	C1927.523804,710.991333 1927.282959,711.241760 1926.707275,711.774170 
	C1926.085693,712.219604 1925.919189,712.464661 1925.872681,712.791321 
	C1925.872681,712.791321 1925.837158,712.813904 1925.824951,712.831116 
	C1924.457031,713.995544 1923.101196,715.142822 1921.657471,716.364502 
	C1920.205688,709.709290 1922.470093,707.652222 1928.994507,709.418152 
	C1929.007812,709.421692 1929.005737,709.688904 1928.942993,709.730347 
	C1928.668335,709.911804 1928.368530,710.055359 1927.921265,710.476807 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1885.042725,731.039307 
	C1883.375854,731.876953 1881.720703,732.693176 1879.677246,733.700989 
	C1879.462769,732.682129 1879.254150,731.691528 1879.018066,730.335266 
	C1879.729126,726.657410 1881.547729,726.207458 1884.777100,728.170471 
	C1885.241943,729.217834 1885.141724,730.094360 1885.041748,730.970886 
	C1885.041748,730.970886 1885.054565,731.018005 1885.042725,731.039307 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1885.339600,730.881897 
	C1885.141724,730.094360 1885.241943,729.217834 1885.154297,728.157715 
	C1885.710327,728.037781 1886.454102,728.101440 1887.128418,728.496460 
	C1886.584961,729.482849 1886.111206,730.137878 1885.339600,730.881897 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1965.339111,687.584717 
	C1967.551025,686.074524 1969.239014,685.701904 1968.945801,689.426941 
	C1967.719849,689.152527 1966.477661,688.541565 1965.339111,687.584717 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1926.203125,712.917725 
	C1925.919189,712.464661 1926.085693,712.219604 1926.597290,712.015076 
	C1927.907837,712.936096 1928.993408,713.898193 1930.078979,714.860291 
	C1929.708984,715.215942 1929.338989,715.571655 1928.968994,715.927307 
	C1928.157227,714.966309 1927.345337,714.005249 1926.203125,712.917725 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1956.727539,685.624695 
	C1956.864258,685.453308 1957.102417,685.427856 1957.340698,685.402405 
	C1957.170044,685.525208 1956.999512,685.647949 1956.727539,685.624695 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2145.993164,813.995483 
	C2144.522949,813.167847 2143.416260,812.345581 2142.156250,811.259277 
	C2143.734375,808.359985 2143.014404,803.149719 2149.562500,806.740417 
	C2152.927490,808.585815 2158.716797,805.814209 2163.435303,805.445557 
	C2167.720215,805.110901 2172.048828,805.018738 2176.332520,805.267578 
	C2177.188721,805.317383 2178.225830,807.293701 2178.630859,808.557495 
	C2178.988281,809.673950 2178.580566,811.035461 2178.529297,811.958862 
	C2175.852783,812.614136 2172.829102,814.324219 2170.599609,813.597656 
	C2168.772461,813.002258 2167.891113,809.505859 2166.371826,806.924316 
	C2165.085693,809.736206 2164.829590,813.562073 2160.090332,813.008118 
	C2157.170410,812.666748 2154.118896,813.493286 2151.122559,813.749023 
	C2149.538330,813.884216 2147.945312,813.920044 2145.993164,813.995483 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2174.617188,815.963867 
	C2176.178467,815.892029 2177.371094,815.854370 2178.553467,815.947815 
	C2179.690674,816.037659 2180.817383,816.263123 2182.789551,816.552368 
	C2182.741699,816.444336 2183.183594,817.444641 2183.859863,818.976379 
	C2184.596680,817.559143 2185.143066,816.508728 2185.689209,815.458313 
	C2186.013428,815.453430 2186.337646,815.448547 2186.661865,815.443665 
	C2186.760254,817.265320 2187.501221,819.434326 2186.761475,820.802917 
	C2186.127441,821.976379 2183.814453,822.756592 2182.216309,822.805969 
	C2175.343750,823.018066 2168.461426,822.896545 2161.650879,822.896545 
	C2160.301025,815.351562 2160.298828,815.383728 2167.125244,815.867004 
	C2169.490723,816.034363 2171.870605,815.998413 2174.617188,815.963867 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2190.698242,806.696594 
	C2194.753174,806.058533 2198.494629,805.378601 2202.236084,804.698669 
	C2199.271240,807.891113 2199.110352,810.210083 2201.942627,813.784973 
	C2197.647705,813.463379 2193.355225,813.105408 2189.056396,812.847839 
	C2188.079834,812.789307 2187.084961,813.158752 2186.094238,813.190430 
	C2184.229980,813.249939 2180.876465,813.610046 2180.748291,813.138000 
	C2180.133545,810.871826 2180.042725,808.285217 2180.593262,806.012878 
	C2180.722900,805.477356 2184.067871,805.547913 2185.915283,805.655823 
	C2187.419434,805.743591 2188.895752,806.303955 2190.698242,806.696594 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2205.976562,832.002014 
	C2204.642822,831.439880 2202.647949,831.241150 2202.134033,830.263855 
	C2200.189941,826.565674 2197.754395,826.407532 2193.568359,829.748230 
	C2194.507812,826.987305 2194.938965,825.720337 2195.381592,824.419861 
	C2194.469971,823.962219 2193.468262,823.459534 2192.636719,823.042114 
	C2192.858154,820.357239 2193.058105,817.933777 2193.280762,815.233337 
	C2195.686279,815.233337 2197.942139,815.233337 2200.664062,815.233337 
	C2198.786377,821.768188 2201.415527,826.857788 2205.975098,831.615784 
	C2205.996826,831.968750 2205.995850,832.007935 2205.976562,832.002014 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2178.671875,793.548584 
	C2177.071533,794.382751 2175.619385,795.566589 2174.481201,796.494202 
	C2173.450684,795.602661 2171.890137,794.252747 2170.329590,792.902954 
	C2169.253418,794.302307 2168.177246,795.701599 2167.419189,796.686951 
	C2166.843994,795.427124 2165.970947,793.514771 2164.937012,791.249817 
	C2166.235840,791.121948 2167.633301,790.899475 2169.035889,790.862000 
	C2173.338623,790.746765 2177.645508,790.598633 2181.945557,790.695801 
	C2183.195801,790.723999 2184.428711,791.532166 2185.669434,791.982910 
	C2184.863037,793.063965 2184.188477,794.288086 2183.213379,795.185974 
	C2182.226807,796.094727 2180.942627,796.680542 2179.538574,797.565613 
	C2179.298340,796.340027 2179.059082,795.119080 2178.671875,793.548584 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2160.002930,832.452759 
	C2158.702148,834.307983 2157.391846,835.820435 2155.954834,837.831604 
	C2155.828125,838.330261 2155.770996,838.680054 2155.430420,838.726196 
	C2153.135986,837.124390 2151.182129,835.476440 2149.577148,834.122681 
	C2150.577637,831.458191 2151.518066,828.954041 2152.458496,826.449951 
	C2152.891846,826.501160 2153.325439,826.552429 2153.892578,826.288757 
	C2154.026367,825.973877 2153.997314,825.998779 2153.681641,826.233154 
	C2154.114014,827.516418 2154.862061,828.565369 2155.610352,829.614258 
	C2155.828613,829.070251 2156.046631,828.526184 2156.264648,827.982178 
	C2157.507568,829.358093 2158.750488,830.734009 2160.002930,832.452759 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2172.462402,787.876099 
	C2169.772949,787.545654 2167.482422,787.097900 2165.088379,786.629944 
	C2165.088379,784.861145 2165.088379,783.247559 2165.088379,780.681396 
	C2166.204102,782.091003 2166.803955,782.848816 2167.403809,783.606567 
	C2167.354248,783.150635 2167.304688,782.694641 2167.255127,782.238708 
	C2169.980957,781.968262 2173.760986,780.461243 2175.206055,781.693115 
	C2177.946777,784.029358 2175.173584,786.239441 2172.462402,787.876099 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2146.415039,780.985168 
	C2146.817139,779.505554 2147.609619,777.984802 2148.636230,776.237061 
	C2151.716309,782.301941 2157.330811,783.408752 2163.689697,783.121643 
	C2163.543213,783.794006 2163.396729,784.466431 2163.250244,785.138855 
	C2160.346680,784.747070 2157.380615,784.603577 2154.557617,783.891663 
	C2151.895020,783.220154 2149.383545,781.950500 2146.415039,780.985168 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2182.976074,781.711182 
	C2184.667725,785.234924 2182.490967,786.649170 2180.034180,787.137390 
	C2179.260498,787.291138 2176.983887,784.840698 2177.195068,784.088196 
	C2177.904785,781.564697 2179.630371,779.716309 2182.976074,781.711182 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2194.246094,839.607056 
	C2192.927490,840.292236 2191.796875,840.616882 2190.303955,840.961182 
	C2190.664307,838.395142 2191.573975,836.294556 2194.246094,839.607056 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2156.113281,827.705811 
	C2156.046631,828.526184 2155.828613,829.070251 2155.610352,829.614258 
	C2154.862061,828.565369 2154.114014,827.516418 2153.691895,826.214600 
	C2154.665771,826.450989 2155.313721,826.940247 2156.113281,827.705811 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2195.181885,840.601074 
	C2194.719971,840.826721 2194.383545,840.684937 2194.038086,840.243164 
	C2194.455322,840.039917 2194.881348,840.136780 2195.181885,840.601074 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2196.171387,841.608032 
	C2195.710205,841.830261 2195.363770,841.687744 2195.024414,841.244507 
	C2195.449463,841.043640 2195.867676,841.143433 2196.171387,841.608032 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2214.956543,873.037720 
	C2213.842529,871.837585 2212.803955,870.566162 2211.552246,869.064453 
	C2210.817871,867.799438 2210.439941,866.655029 2209.749023,865.749634 
	C2207.368408,862.630737 2207.017334,859.674377 2209.455566,856.289307 
	C2210.394775,854.985352 2213.160645,852.403442 2208.100342,852.140564 
	C2211.064697,848.580139 2213.585449,845.552429 2216.644287,841.878540 
	C2216.897461,843.571106 2217.155029,845.294067 2217.415039,847.032898 
	C2225.260010,846.460327 2231.937988,848.686462 2235.940186,856.321289 
	C2236.560791,856.074829 2236.972900,856.062561 2237.692871,856.025146 
	C2237.033936,858.747620 2236.066650,861.495300 2234.937012,864.561523 
	C2234.405762,864.892883 2234.036865,864.905640 2233.358398,864.504150 
	C2232.625732,863.313843 2232.215820,861.869995 2231.777832,861.861450 
	C2224.622070,861.720886 2217.463135,861.761719 2208.908203,861.761719 
	C2211.522949,865.360901 2213.228760,867.708984 2214.967773,870.405151 
	C2215.061279,871.443298 2215.121338,872.133362 2215.181396,872.823425 
	C2215.181396,872.823425 2215.031494,872.966492 2214.956543,873.037720 
M2228.855469,850.395752 
	C2220.166992,846.311462 2212.941895,849.877075 2210.367188,859.645386 
	C2218.215332,859.645386 2226.020264,859.645386 2234.640869,859.645386 
	C2232.564453,856.334106 2230.909424,853.694885 2228.855469,850.395752 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2221.941895,834.290710 
	C2223.475342,835.039795 2225.070557,836.086609 2226.597656,837.225098 
	C2229.961426,839.732971 2233.290527,842.287048 2236.911377,845.032715 
	C2235.010742,844.684265 2232.072754,844.978882 2230.409668,843.662659 
	C2227.215576,841.134338 2224.743896,837.692810 2221.941895,834.290710 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2195.696533,853.801147 
	C2196.416504,853.591675 2196.888184,853.700562 2197.706543,853.921875 
	C2197.691650,854.564453 2197.330322,855.094482 2196.968994,855.624634 
	C2196.462158,855.122925 2195.955322,854.621216 2195.696533,853.801147 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2199.292969,853.322388 
	C2199.499023,852.547485 2199.981445,852.030273 2200.753662,851.776001 
	C2200.552246,852.552734 2200.061035,853.066467 2199.292969,853.322388 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2211.204590,838.446899 
	C2211.120605,838.408875 2210.834717,838.458313 2210.834717,838.458313 
	C2210.834717,838.458313 2211.288574,838.484924 2211.204590,838.446899 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2198.389160,854.150024 
	C2198.162109,853.711060 2198.304199,853.364319 2198.751465,853.024902 
	C2198.957275,853.435608 2198.857666,853.838928 2198.389160,854.150024 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2220.104492,830.909546 
	C2220.397705,831.085205 2220.521240,831.429321 2220.644531,831.773438 
	C2220.408447,831.798218 2220.172607,831.822998 2219.777344,831.642395 
	C2219.617920,831.437073 2219.934570,831.078064 2220.104492,830.909546 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2236.011230,816.275879 
	C2236.244629,816.190125 2236.525391,816.376465 2236.904053,816.802307 
	C2236.687500,816.877197 2236.373291,816.712646 2236.011230,816.275879 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2237.002197,817.278809 
	C2237.242676,817.179260 2237.528809,817.359802 2237.898438,817.785889 
	C2237.670410,817.873901 2237.359131,817.716431 2237.002197,817.278809 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2214.934570,870.057068 
	C2213.228760,867.708984 2211.522949,865.360901 2208.908203,861.761719 
	C2217.463135,861.761719 2224.622070,861.720886 2231.777832,861.861450 
	C2232.215820,861.869995 2232.625732,863.313843 2233.083740,864.539551 
	C2232.737549,865.667114 2232.356201,866.345154 2231.966797,867.333740 
	C2231.367188,868.219177 2230.775879,868.794067 2229.592773,869.627319 
	C2224.288086,867.982849 2219.585449,866.886414 2214.934570,870.057068 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M2248.935547,874.699890 
	C2252.158691,874.700439 2254.895020,874.700439 2257.631348,874.700439 
	C2257.748535,875.106628 2257.865479,875.512878 2257.982666,875.919067 
	C2257.258545,876.594971 2256.635010,877.458435 2255.791260,877.911194 
	C2253.375732,879.207092 2250.765137,880.160950 2248.423096,881.568420 
	C2243.631592,884.447937 2236.236572,881.123352 2235.134033,874.699341 
	C2239.654053,874.699341 2244.051270,874.699341 2248.935547,874.699890 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2258.189941,844.960266 
	C2258.016602,844.335266 2258.151123,843.839050 2258.477051,843.003235 
	C2259.852051,844.208679 2261.035400,845.753662 2262.106201,847.644043 
	C2260.828613,847.022644 2259.663330,846.055847 2258.189941,844.960266 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2269.673340,857.074646 
	C2270.053467,857.389954 2270.243164,857.636597 2270.691895,857.960205 
	C2270.917969,858.908325 2270.944336,859.733887 2270.952881,860.982544 
	C2270.226074,860.191467 2269.517090,858.977356 2268.865723,857.405151 
	C2269.090088,857.048584 2269.257080,857.050049 2269.673340,857.074646 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2266.684814,852.926147 
	C2266.942871,853.217285 2266.950684,853.384644 2266.950195,853.803162 
	C2266.280273,853.608093 2265.618896,853.161926 2264.955322,852.379639 
	C2265.447021,852.296509 2265.940918,852.549377 2266.684814,852.926147 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2266.876709,854.375244 
	C2267.113037,854.046448 2267.285400,854.034241 2267.716309,854.021362 
	C2267.978516,854.470642 2267.982178,854.920532 2267.966309,855.707520 
	C2267.569092,855.593689 2267.190918,855.142700 2266.876709,854.375244 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2268.692627,856.043335 
	C2268.939453,856.060547 2268.971191,856.557007 2268.965332,856.806030 
	C2268.657715,856.895325 2268.355957,856.735474 2268.002930,856.313232 
	C2267.951660,856.050842 2268.445801,856.026062 2268.692627,856.043335 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1983.958984,730.054932 
	C1983.373413,729.239929 1982.553711,728.483337 1982.328003,727.578613 
	C1982.247803,727.257202 1983.558838,726.249023 1984.326416,726.125916 
	C1985.952637,725.865051 1987.639404,725.980164 1989.738281,725.808960 
	C1996.072266,725.552490 2001.969360,725.428162 2008.325684,725.668152 
	C2008.842651,726.690918 2008.900635,727.349426 2008.693970,728.331543 
	C2006.640503,728.792297 2004.853271,728.963257 2003.062134,729.060303 
	C1996.695190,729.405457 1990.326782,729.725464 1983.958984,730.054932 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2028.336182,725.280884 
	C2028.855713,726.299683 2028.925293,727.270935 2028.523193,728.615112 
	C2022.670410,728.655212 2017.289185,728.322388 2011.977417,727.719666 
	C2012.162842,726.908569 2012.278809,726.367432 2012.394897,725.826233 
	C2017.558594,725.628601 2022.722290,725.431030 2028.336182,725.280884 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2011.937744,725.709656 
	C2012.278809,726.367432 2012.162842,726.908569 2011.736572,727.820923 
	C2010.603760,728.130676 2009.781128,728.069275 2008.958496,728.007935 
	C2008.900635,727.349426 2008.842651,726.690918 2008.758545,725.704468 
	C2009.648438,725.448669 2010.564575,725.520874 2011.937744,725.709656 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2040.652222,725.225098 
	C2040.801392,725.228394 2040.604614,725.505066 2040.604614,725.505066 
	C2040.604614,725.505066 2040.303711,725.446289 2040.254150,725.338867 
	C2040.204712,725.231506 2040.503052,725.221863 2040.652222,725.225098 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2095.110352,776.901184 
	C2094.947266,776.619507 2094.784180,776.337891 2094.397949,775.943909 
	C2094.157959,775.544800 2094.195312,775.265381 2094.254639,774.782715 
	C2094.964600,774.168030 2095.707031,773.763916 2096.725342,773.192383 
	C2100.794189,773.583252 2104.072510,773.343323 2104.624268,768.194153 
	C2104.992188,768.248596 2105.010498,768.442688 2105.041016,769.343018 
	C2105.042969,771.792725 2104.864990,773.560669 2105.113281,775.266602 
	C2105.200439,775.865112 2106.334473,776.311279 2106.990723,776.826965 
	C2106.950684,777.830750 2106.910645,778.834595 2106.488770,780.278320 
	C2104.996094,779.448364 2103.885010,778.178406 2102.767334,776.901184 
	C2100.525879,776.901184 2097.818115,776.901184 2095.110352,776.901184 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2098.983643,755.337524 
	C2099.209473,755.008240 2099.437988,755.004333 2100.010254,754.999512 
	C2100.251465,755.838989 2100.148682,756.679260 2100.045898,757.519592 
	C2099.692871,756.900696 2099.339600,756.281738 2098.983643,755.337524 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2078.308105,776.239258 
	C2078.776367,783.987793 2076.853760,786.112427 2069.322754,787.010376 
	C2068.063721,787.160461 2066.801758,787.765930 2065.666504,788.389160 
	C2061.147217,790.869934 2057.429932,790.528564 2055.080078,787.304993 
	C2051.070557,781.804993 2051.112061,779.654785 2055.648926,775.134399 
	C2059.494141,775.441528 2062.995361,776.039062 2066.518311,776.221558 
	C2070.288086,776.416870 2074.078125,776.221008 2078.308105,776.239258 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2048.438477,782.084839 
	C2047.267700,782.149597 2046.477051,782.094971 2045.289795,782.067627 
	C2044.757080,777.364075 2044.620972,772.633362 2044.745605,767.512085 
	C2045.188599,767.155029 2045.371582,767.158142 2045.691406,767.131042 
	C2046.920166,767.112244 2048.012695,767.093262 2049.508301,767.036926 
	C2050.042969,767.675720 2050.174072,768.351929 2050.201416,769.801758 
	C2050.106689,772.065063 2050.115723,773.554565 2050.125000,775.044128 
	C2049.689453,777.351257 2049.253906,779.658447 2048.438477,782.084839 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2050.454102,775.020081 
	C2050.115723,773.554565 2050.106689,772.065063 2050.239258,770.144958 
	C2051.558350,770.927246 2052.735596,772.140137 2053.891846,773.696350 
	C2052.841797,774.358459 2051.812500,774.677246 2050.454102,775.020081 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2008.693970,728.331543 
	C2009.781128,728.069275 2010.603760,728.130676 2011.667236,728.090820 
	C2017.289185,728.322388 2022.670410,728.655212 2028.517578,728.980957 
	C2029.357910,731.994629 2027.392334,732.052002 2025.227417,732.035950 
	C2019.756958,731.995239 2014.284058,731.956177 2008.816040,732.084656 
	C2007.286011,732.120605 2005.769653,732.740479 2004.157959,733.469360 
	C1997.687866,733.906860 1991.306641,733.967529 1984.456055,734.005432 
	C1983.901367,732.859497 1983.816040,731.736267 1983.844849,730.333984 
	C1990.326782,729.725464 1996.695190,729.405457 2003.062134,729.060303 
	C2004.853271,728.963257 2006.640503,728.792297 2008.693970,728.331543 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2136.028809,752.786987 
	C2132.958252,753.877991 2129.945068,755.222168 2126.788818,755.933960 
	C2125.550537,756.213257 2123.992920,755.076294 2122.732910,755.200562 
	C2120.398193,755.200562 2118.063477,755.209595 2115.728760,755.192810 
	C2115.274902,755.189514 2114.822998,755.056580 2114.367676,755.033691 
	C2112.695557,754.949585 2111.022217,754.886658 2109.349365,754.815735 
	C2109.860352,752.602722 2110.371338,750.389709 2111.185059,746.864990 
	C2113.016357,749.116821 2113.770508,750.044189 2113.720215,749.982361 
	C2118.282715,749.474609 2121.942139,749.067322 2126.248535,748.389893 
	C2129.479248,748.772583 2132.063232,749.425354 2135.026855,750.174133 
	C2136.349365,747.252197 2138.308594,747.909302 2140.278076,750.695374 
	C2139.998535,751.286560 2139.777344,751.664551 2139.224609,751.968445 
	C2137.938232,752.191895 2136.983643,752.489502 2136.028809,752.786987 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2140.336670,750.482178 
	C2138.308594,747.909302 2136.349365,747.252197 2135.026855,750.174133 
	C2132.063232,749.425354 2129.479248,748.772583 2126.551270,748.115479 
	C2124.814941,746.825989 2123.422607,745.540833 2121.995361,743.863220 
	C2122.281250,742.166382 2122.602051,740.862061 2123.038574,739.221558 
	C2129.437256,741.539551 2135.720703,744.193726 2141.876221,747.493286 
	C2141.277832,748.919922 2140.807129,749.701050 2140.336670,750.482178 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2109.274658,767.367676 
	C2109.238281,766.739685 2109.507080,766.380005 2109.538574,765.883789 
	C2109.267578,764.489197 2109.233398,763.231140 2109.238770,761.598572 
	C2110.784424,760.203735 2112.290527,759.183289 2113.796631,758.162903 
	C2114.027588,758.443604 2114.258545,758.724365 2114.489746,759.005066 
	C2113.182617,760.494751 2111.875488,761.984497 2109.666260,764.502258 
	C2112.396484,763.818176 2114.053467,763.402954 2116.483887,762.976929 
	C2117.533447,762.991516 2117.809326,763.016785 2118.085449,763.042114 
	C2117.339355,763.804443 2116.601807,764.575500 2115.844238,765.326233 
	C2115.284424,765.881104 2114.698730,766.409363 2114.314209,767.245728 
	C2112.862549,767.573303 2111.220947,767.604614 2109.274658,767.367676 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M2140.278076,750.695374 
	C2140.807129,749.701050 2141.277832,748.919922 2141.910645,747.833740 
	C2142.508545,747.606506 2142.944092,747.684326 2143.689697,747.881104 
	C2145.074463,750.134583 2146.149170,752.269104 2147.306396,754.773071 
	C2145.233154,754.799011 2143.076904,754.455444 2140.305420,753.967468 
	C2139.645264,753.229553 2139.600830,752.636047 2139.556396,752.042542 
	C2139.777344,751.664551 2139.998535,751.286560 2140.278076,750.695374 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2122.169922,759.483521 
	C2124.433350,758.532043 2127.121582,764.039734 2129.502441,758.986084 
	C2129.502441,760.786133 2129.502441,762.586121 2129.502441,764.406372 
	C2128.020752,764.027588 2126.406982,763.615112 2124.146484,763.115356 
	C2123.307373,763.038086 2123.114990,763.048096 2122.922607,763.058044 
	C2122.922607,763.058044 2123.003418,763.012146 2122.998047,762.579224 
	C2122.718506,761.258728 2122.444092,760.371094 2122.169922,759.483521 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2139.224609,751.968445 
	C2139.600830,752.636047 2139.645264,753.229553 2139.901855,754.044189 
	C2138.291016,757.183777 2137.112793,755.891113 2136.054443,753.217529 
	C2136.983643,752.489502 2137.938232,752.191895 2139.224609,751.968445 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2121.953125,759.559387 
	C2122.444092,760.371094 2122.718506,761.258728 2122.963623,762.610840 
	C2121.653564,762.890381 2120.372559,762.705383 2118.700195,762.137451 
	C2119.167480,761.174744 2120.026123,760.594910 2120.884521,760.015015 
	C2121.185059,759.925598 2121.469238,759.799011 2121.953125,759.559387 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2121.040527,759.664185 
	C2120.026123,760.594910 2119.167480,761.174744 2118.375977,762.136414 
	C2118.442627,762.518250 2118.325684,762.822266 2118.205566,762.932190 
	C2117.809326,763.016785 2117.533447,762.991516 2116.840332,762.956543 
	C2116.423340,761.635132 2116.423340,760.323303 2116.423340,758.065979 
	C2118.240479,758.540833 2119.718506,758.927063 2121.040527,759.664185 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2122.845947,763.396240 
	C2123.114990,763.048096 2123.307373,763.038086 2123.788574,763.036987 
	C2124.020996,763.805786 2123.964844,764.565674 2123.908447,765.325623 
	C2123.708984,765.366272 2123.509521,765.406921 2123.310059,765.447632 
	C2123.129883,764.876526 2122.949463,764.305481 2122.845947,763.396240 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2094.741943,776.968567 
	C2097.818115,776.901184 2100.525879,776.901184 2102.767334,776.901184 
	C2103.885010,778.178406 2104.996094,779.448364 2106.513184,780.654907 
	C2111.314453,780.188293 2113.716309,782.729553 2116.283447,785.963379 
	C2121.394531,792.401550 2127.097168,798.369934 2132.385254,805.328064 
	C2132.145996,806.460327 2132.076904,806.796875 2132.007812,807.133423 
	C2128.079346,806.440674 2123.883789,804.406616 2121.060547,809.658997 
	C2121.049316,810.005005 2121.026611,809.953064 2120.666748,810.088379 
	C2118.553467,811.295105 2116.842773,813.206970 2115.039307,813.298950 
	C2104.683594,813.826904 2094.305420,813.923340 2083.935059,814.166077 
	C2080.871826,812.838318 2082.467041,810.940613 2083.954590,808.990234 
	C2086.483398,809.296692 2088.604492,810.515503 2090.221924,810.035645 
	C2093.508789,809.060425 2096.523193,807.167358 2099.721191,805.617920 
	C2099.937988,806.881958 2100.157959,808.163757 2100.233643,808.605530 
	C2102.977539,808.605530 2105.365479,808.605530 2107.908691,808.605530 
	C2107.908691,807.139648 2107.908691,805.208618 2107.908691,802.774841 
	C2106.324463,803.777832 2105.134521,804.531128 2105.002441,804.614746 
	C2100.608154,803.693909 2097.209229,802.981567 2093.578125,802.220642 
	C2093.100830,804.422791 2092.809326,805.767822 2092.541992,807.000793 
	C2090.661865,807.500732 2089.114258,807.912231 2087.566650,808.323730 
	C2087.777344,806.746704 2088.397705,806.277527 2088.949463,805.738281 
	C2089.551758,805.149658 2090.090576,804.495972 2090.657227,803.870667 
	C2090.428955,803.538757 2090.200928,803.206909 2089.972656,802.875061 
	C2087.966309,803.938660 2085.959961,805.002258 2083.953613,806.065857 
	C2083.836426,805.377014 2083.718994,804.688171 2083.587402,803.649658 
	C2083.686035,797.213745 2083.957275,791.124634 2083.844971,785.042664 
	C2083.790039,782.075317 2084.580078,780.549988 2087.673096,779.905884 
	C2090.004639,779.420410 2092.146973,778.025879 2094.741943,776.968567 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2116.218506,775.594360 
	C2117.182861,775.193970 2118.333008,774.896851 2119.105957,775.308044 
	C2125.988525,778.969421 2131.255371,776.668396 2136.981201,772.064148 
	C2140.240479,769.443298 2145.394287,769.177917 2150.130371,767.936279 
	C2148.711670,770.810425 2148.743164,775.461914 2142.974854,774.732239 
	C2146.791992,781.104919 2140.910645,784.504944 2139.380127,789.173096 
	C2137.918945,793.629089 2135.867676,797.891479 2133.880371,802.713135 
	C2131.480225,800.078979 2128.544434,796.885620 2125.642334,793.661987 
	C2123.860352,791.682495 2122.242676,789.542725 2120.351562,787.677185 
	C2117.055664,784.425781 2114.523438,781.003113 2116.218506,775.594360 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2121.031494,810.026978 
	C2127.766602,806.434082 2129.843262,808.152954 2136.995117,812.010132 
	C2143.267090,815.392822 2146.613525,820.517578 2150.897949,826.035217 
	C2149.948242,828.585571 2148.874756,830.488892 2147.476807,832.967163 
	C2143.196533,829.244751 2139.600830,826.117737 2135.766602,822.760010 
	C2131.075684,819.394165 2128.564697,813.470398 2122.046875,813.171570 
	C2121.658447,813.153748 2121.364502,811.073608 2121.026611,809.953064 
	C2121.026611,809.953064 2121.049316,810.005005 2121.031494,810.026978 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2136.005127,822.990723 
	C2139.600830,826.117737 2143.196533,829.244751 2147.476807,832.967163 
	C2148.874756,830.488892 2149.948242,828.585571 2151.085938,826.316650 
	C2151.375732,826.014404 2151.600830,826.077881 2152.142090,826.295593 
	C2151.518066,828.954041 2150.577637,831.458191 2149.577148,834.122681 
	C2151.182129,835.476440 2153.135986,837.124390 2155.478271,839.026733 
	C2155.891602,839.482361 2155.916504,839.683533 2155.941406,839.884705 
	C2155.931396,840.413696 2155.921631,840.942627 2155.504883,841.783203 
	C2152.053955,840.077148 2149.009521,838.059631 2145.912598,835.695435 
	C2142.194824,831.596191 2138.529541,827.843628 2135.122314,823.984619 
	C2135.588623,823.582336 2135.796875,823.286560 2136.005127,822.990723 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2107.054932,776.566650 
	C2106.334473,776.311279 2105.200439,775.865112 2105.113281,775.266602 
	C2104.864990,773.560669 2105.042969,771.792725 2105.116455,769.625671 
	C2110.792725,769.384644 2111.333984,770.084290 2109.048096,774.643799 
	C2108.995850,775.015869 2109.050293,775.013428 2108.759277,775.033691 
	C2108.318848,775.385559 2108.169434,775.717224 2108.023926,776.021851 
	C2108.027588,775.994812 2108.076904,776.019165 2108.076904,776.019165 
	C2107.757812,776.114868 2107.438477,776.210571 2107.054932,776.566650 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2109.003906,775.041870 
	C2110.548340,774.260071 2112.084473,773.452271 2113.978271,772.456299 
	C2113.978271,774.461670 2113.978271,776.286072 2113.978271,778.537109 
	C2112.050537,777.980591 2109.296143,781.365417 2108.279541,776.401611 
	C2108.076904,776.019165 2108.027588,775.994812 2108.397949,776.167603 
	C2108.862549,775.898071 2108.956299,775.455750 2109.050293,775.013428 
	C2109.050293,775.013428 2108.995850,775.015869 2109.003906,775.041870 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2132.377930,807.194702 
	C2132.076904,806.796875 2132.145996,806.460327 2132.440430,805.653809 
	C2133.097900,805.708679 2133.529785,806.233521 2133.961914,806.758301 
	C2133.557373,806.924194 2133.152588,807.090088 2132.377930,807.194702 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2137.295898,810.370422 
	C2137.537109,810.338989 2137.625977,810.438416 2137.715088,810.537903 
	C2137.524658,810.525696 2137.334229,810.513489 2137.295898,810.370422 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1913.409668,715.574585 
	C1913.337524,715.478455 1913.606079,715.435974 1913.606079,715.435974 
	C1913.606079,715.435974 1913.481689,715.670715 1913.409668,715.574585 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1629.232056,681.872681 
	C1635.725586,681.912170 1642.223267,681.825134 1648.710815,682.043274 
	C1652.087158,682.156799 1653.694214,681.352722 1654.257568,677.550476 
	C1655.311646,670.436279 1661.698120,664.175781 1668.499756,663.447571 
	C1654.084961,672.572205 1653.518799,687.044067 1651.112549,702.334961 
	C1650.792480,698.911255 1650.423340,696.762268 1650.436768,694.615601 
	C1650.450928,692.345459 1650.829224,690.077637 1651.075562,687.529114 
	C1649.519409,687.231506 1648.392944,687.136414 1647.347046,686.794800 
	C1643.239868,685.453735 1641.919189,687.238953 1641.959961,691.163757 
	C1642.082886,702.983704 1642.072754,714.804993 1641.707031,727.184875 
	C1639.228760,726.861023 1637.158081,725.978088 1635.053833,724.632935 
	C1634.996216,722.022583 1635.193481,719.840271 1634.869873,717.738220 
	C1634.655518,716.346436 1633.694092,715.069580 1633.067139,713.741333 
	C1632.126221,714.216736 1631.185181,714.692139 1630.244263,715.167542 
	C1631.017334,716.430786 1631.790527,717.694031 1632.359985,718.624512 
	C1631.912354,721.341187 1631.454956,724.118164 1630.758911,727.216858 
	C1624.658325,727.654663 1618.796143,727.770691 1613.011475,727.517456 
	C1615.284302,726.184814 1617.479858,725.221558 1619.746826,724.226929 
	C1617.110596,722.971924 1615.085083,722.007751 1613.483887,721.022461 
	C1616.912842,721.668274 1619.917480,722.335083 1623.514771,723.133423 
	C1624.479614,722.502075 1626.225098,721.359924 1627.970459,720.217773 
	C1627.845703,719.846985 1627.721069,719.476196 1627.596313,719.105469 
	C1625.732056,719.718933 1623.867920,720.332397 1621.917725,720.974182 
	C1620.096313,716.813110 1616.283936,719.668274 1612.596436,719.976929 
	C1610.814697,719.667603 1609.408081,719.408569 1607.998291,718.874146 
	C1607.990601,718.415100 1607.985962,718.231506 1608.389282,718.021179 
	C1610.832031,717.903870 1613.346191,718.501160 1613.342285,715.250732 
	C1618.642456,718.731628 1623.514893,718.375366 1628.016113,713.723633 
	C1626.067505,714.423340 1624.119019,715.123047 1622.000000,715.883972 
	C1622.000000,714.590271 1622.000000,713.291382 1622.301514,711.809631 
	C1623.506470,711.284912 1624.435425,710.596619 1625.308960,710.661072 
	C1629.169312,710.945740 1633.013672,711.447205 1637.104736,712.144409 
	C1637.786011,712.348938 1638.611694,712.284851 1638.612671,712.208801 
	C1638.728271,704.218445 1638.790039,696.227173 1638.725952,688.236938 
	C1638.725342,688.155029 1637.016968,688.086853 1635.851440,687.692017 
	C1633.804688,687.203125 1631.884399,687.370117 1630.263428,686.752014 
	C1629.119019,686.315735 1628.380615,684.814697 1627.737549,683.722168 
	C1628.420044,683.061096 1628.826050,682.466858 1629.232056,681.872681 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1580.919434,614.880005 
	C1583.002197,614.963806 1585.085083,615.047607 1587.610107,615.213135 
	C1589.425171,618.713806 1590.797852,622.132629 1591.654785,625.812195 
	C1589.415283,626.713562 1587.691528,627.354187 1585.967896,627.994873 
	C1585.967896,627.994873 1586.007446,627.992432 1585.934937,627.623657 
	C1582.323730,627.414612 1578.785034,627.574341 1575.094482,627.573181 
	C1574.704224,627.464783 1574.465576,627.517212 1574.227051,627.569641 
	C1574.387329,627.806274 1574.547729,628.042908 1574.771118,628.646423 
	C1574.472900,630.695190 1574.111572,632.377136 1573.750244,634.059021 
	C1571.785034,634.374390 1569.819946,634.689758 1567.388306,634.552368 
	C1566.903442,632.614807 1566.893433,631.129883 1566.864990,629.645325 
	C1566.716064,621.878845 1573.093140,615.176819 1580.919434,614.880005 
M1586.277832,620.878723 
	C1586.469971,621.424622 1586.662109,621.970520 1586.854248,622.516418 
	C1587.132935,622.387451 1587.411499,622.258484 1587.690063,622.129639 
	C1587.364380,621.568542 1587.038574,621.007446 1586.277832,620.878723 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1629.136475,681.428833 
	C1628.826050,682.466858 1628.420044,683.061096 1627.663086,683.340454 
	C1626.746216,677.584167 1626.294312,672.124939 1625.457031,666.725525 
	C1625.361328,666.108337 1622.871826,665.166504 1621.900024,665.514404 
	C1619.214600,666.476196 1616.745117,668.041443 1613.892578,669.263062 
	C1613.391235,669.105896 1613.187378,669.053650 1612.983521,669.001465 
	C1612.076294,668.660156 1611.188843,668.244446 1610.257080,667.994873 
	C1608.836060,667.614319 1607.384155,667.348694 1605.884033,666.309692 
	C1605.866699,664.453430 1605.911011,663.321716 1605.955322,662.190063 
	C1609.679443,662.190063 1613.403564,662.190063 1617.187378,662.190063 
	C1616.865479,662.526855 1616.245117,663.175903 1615.211182,664.198486 
	C1614.746704,665.244263 1614.695679,665.916565 1614.644653,666.588867 
	C1615.249634,666.205688 1615.854614,665.822510 1616.459717,665.439392 
	C1617.187012,665.051514 1618.404663,664.805725 1618.564575,664.253479 
	C1619.998047,659.306213 1623.537109,660.763916 1626.852539,661.320374 
	C1628.574463,661.609436 1630.324829,661.730164 1632.453369,661.971252 
	C1632.453369,663.310425 1632.453369,665.213257 1632.453369,667.980103 
	C1631.521973,666.887878 1631.026245,666.306519 1630.054810,665.167419 
	C1628.015015,670.561340 1629.476440,675.869751 1629.136475,681.428833 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1572.026123,718.028320 
	C1571.217529,717.464844 1570.409058,716.901367 1569.297852,716.204224 
	C1569.214966,715.218628 1569.434814,714.366699 1569.713379,713.186646 
	C1570.307373,712.159119 1570.842529,711.459534 1571.776123,711.289368 
	C1572.466797,712.491699 1572.750854,713.747681 1573.052979,713.752136 
	C1580.559692,713.860901 1588.068237,713.837463 1595.928711,713.837463 
	C1592.902588,706.938782 1590.268677,701.397644 1581.696655,702.893616 
	C1578.376099,703.473145 1576.203247,704.084717 1574.874268,707.253845 
	C1574.280273,708.670166 1572.963135,709.783142 1571.974243,711.033813 
	C1573.782837,702.650513 1584.180420,697.371460 1590.646606,702.044617 
	C1593.285278,703.951660 1594.630493,707.648376 1596.839233,711.084229 
	C1597.677368,712.099670 1598.240479,712.561768 1598.894043,713.353027 
	C1599.062378,714.129944 1599.140259,714.577759 1598.893921,715.149658 
	C1598.019897,716.452759 1597.469971,717.631836 1596.603149,718.940063 
	C1595.182129,720.391418 1594.078125,721.713623 1592.847656,722.648560 
	C1593.661621,719.997131 1594.602295,717.732971 1595.470947,715.641785 
	C1588.336182,715.641785 1581.388916,715.562134 1574.448486,715.746887 
	C1573.621338,715.768982 1572.832642,717.232727 1572.026123,718.028320 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1580.925537,614.614746 
	C1573.093140,615.176819 1566.716064,621.878845 1566.864990,629.645325 
	C1566.893433,631.129883 1566.903442,632.614807 1567.002686,634.553589 
	C1567.099121,645.715088 1567.114990,656.422485 1566.713623,667.598145 
	C1566.091309,668.054626 1565.886230,668.042786 1565.681274,668.030945 
	C1565.418579,653.480103 1564.950439,638.928467 1565.012329,624.378967 
	C1565.032227,619.703186 1574.357178,613.318665 1580.459473,613.257080 
	C1580.881836,613.643738 1580.906738,613.996582 1580.925537,614.614746 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1565.690674,668.494263 
	C1565.886230,668.042786 1566.091309,668.054626 1566.604248,668.059326 
	C1567.394897,670.582703 1567.877563,673.113159 1568.726562,676.196899 
	C1569.741211,677.513855 1570.389526,678.277466 1571.037720,679.041138 
	C1568.021118,682.167908 1566.503540,688.826538 1569.023560,691.546021 
	C1570.530884,693.172546 1573.679810,693.277832 1575.241089,693.788757 
	C1574.066528,693.925537 1571.865234,694.181824 1568.960205,694.766113 
	C1568.288086,697.598511 1568.319580,700.103027 1568.350952,702.607483 
	C1568.339966,705.275085 1568.328979,707.942627 1568.319336,711.005981 
	C1567.433716,711.983337 1566.546753,712.564819 1565.352417,712.862244 
	C1565.044922,712.578186 1565.056641,712.078552 1565.430664,711.849609 
	C1565.826782,706.109070 1565.848877,700.597351 1565.887817,694.616638 
	C1565.860962,690.370911 1565.817261,686.594299 1565.773682,682.817627 
	C1565.749146,678.197571 1565.724609,673.577576 1565.690674,668.494263 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1612.934082,727.886719 
	C1618.796143,727.770691 1624.658325,727.654663 1631.048706,727.315002 
	C1632.747192,726.426025 1633.917236,725.760620 1635.087402,725.095215 
	C1637.158081,725.978088 1639.228760,726.861023 1641.690674,727.622314 
	C1646.725952,727.500671 1651.369873,727.500671 1656.013794,727.500671 
	C1656.126221,727.123596 1656.238647,726.746521 1656.351074,726.369446 
	C1655.037476,725.467834 1653.723755,724.566284 1652.498657,723.353394 
	C1652.741943,722.964966 1652.896729,722.887939 1653.051392,722.810913 
	C1654.744507,722.883850 1656.437744,722.956848 1658.554443,723.059814 
	C1658.977905,723.089844 1658.866577,723.522034 1658.877686,723.744751 
	C1656.488403,729.743958 1662.430908,726.622925 1663.925903,728.730103 
	C1661.931030,729.579285 1660.038452,730.384949 1658.092285,731.848694 
	C1657.763428,732.958923 1657.487915,733.411072 1657.089844,733.596741 
	C1656.853882,733.170776 1656.740601,733.011230 1656.634644,732.462036 
	C1655.700317,731.118286 1654.793335,729.388611 1653.811157,729.344727 
	C1649.571289,729.155273 1645.309326,729.459473 1640.711548,729.438965 
	C1639.161865,729.457825 1637.956177,729.621643 1636.750366,729.785522 
	C1632.057373,729.582581 1627.364502,729.379700 1621.815430,729.247253 
	C1615.554443,729.347656 1610.149414,729.377625 1604.744507,729.407532 
	C1604.803589,729.201050 1604.862671,728.994507 1605.421997,728.432861 
	C1608.259399,728.014099 1610.596802,727.950439 1612.934082,727.886719 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1576.281128,662.137146 
	C1576.108521,659.010864 1575.936035,655.884644 1575.703125,651.665649 
	C1580.359985,652.007568 1585.167969,651.197937 1588.405273,653.084045 
	C1590.047974,654.041260 1589.543579,660.055664 1588.589355,663.375610 
	C1588.241211,664.587158 1583.779053,664.616333 1580.676270,664.844177 
	C1580.114502,663.990967 1580.070557,663.478638 1580.471191,662.983398 
	C1582.846313,663.000488 1584.776855,663.000488 1586.627197,663.000488 
	C1586.627197,660.417786 1586.627197,658.424866 1586.627197,655.922607 
	C1584.099243,658.247192 1582.058472,660.123718 1579.647705,662.065674 
	C1578.278809,662.133118 1577.279907,662.135132 1576.281128,662.137146 
M1578.032104,656.413696 
	C1578.114258,657.768372 1578.196533,659.123108 1578.248779,659.985718 
	C1580.387939,658.089417 1582.493896,656.222656 1584.599854,654.355896 
	C1584.407349,653.960938 1584.214844,653.565979 1584.022339,653.171082 
	C1582.039185,653.979919 1580.056030,654.788818 1578.032104,656.413696 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1579.283569,638.400757 
	C1580.907593,639.067078 1582.177734,639.777405 1583.447754,640.487671 
	C1583.486450,640.071655 1583.525146,639.655701 1583.563843,639.239685 
	C1585.954590,639.509888 1588.345337,639.780029 1590.736084,640.050171 
	C1589.674072,642.177612 1589.143921,645.177917 1587.424072,646.225891 
	C1584.906006,647.760498 1582.635986,646.321594 1582.463745,642.359192 
	C1582.400024,647.994141 1579.302612,646.903381 1575.401733,646.140259 
	C1577.355835,644.476624 1578.719727,643.315491 1580.674805,641.651062 
	C1578.868042,641.233704 1577.757812,640.977295 1575.764526,640.516846 
	C1577.235596,639.553772 1578.082520,638.999268 1579.283569,638.400757 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1598.811279,657.616516 
	C1595.720093,658.385193 1593.762939,657.733826 1593.645752,654.570312 
	C1593.546875,651.897705 1595.507324,650.611633 1600.180054,650.528381 
	C1598.474487,652.114380 1597.122681,653.371399 1595.770874,654.628418 
	C1595.985352,655.022705 1596.199707,655.416931 1596.414185,655.811218 
	C1598.152954,655.051636 1601.176514,654.551331 1601.391602,653.484375 
	C1602.061279,650.164917 1603.977417,649.078491 1606.520630,650.093933 
	C1607.757812,650.587830 1608.167358,653.166382 1608.939331,654.806946 
	C1609.191040,655.341858 1609.404053,655.894836 1609.408691,655.905640 
	C1605.840210,656.445862 1602.510376,656.949951 1598.811279,657.616516 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1599.218140,715.025574 
	C1599.140259,714.577759 1599.062378,714.129944 1599.244263,713.105103 
	C1599.302368,702.648376 1599.100586,692.768738 1598.998657,682.757812 
	C1599.193359,682.422546 1599.339111,682.265503 1599.535522,682.155396 
	C1601.713623,682.105164 1603.891602,682.054871 1606.525635,682.413208 
	C1606.999146,684.195374 1607.016846,685.568848 1606.828125,687.050171 
	C1606.350464,687.337280 1606.174316,687.584351 1605.713745,687.917053 
	C1604.047119,687.466370 1602.760010,686.997864 1601.051025,686.375854 
	C1601.051025,692.835266 1601.051025,698.929688 1601.028320,705.485779 
	C1601.005493,709.936707 1601.005493,713.926025 1601.005493,719.275024 
	C1600.036865,716.972229 1599.627563,715.998901 1599.218140,715.025574 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1574.091064,634.056458 
	C1574.111572,632.377136 1574.472900,630.695190 1574.906982,628.511230 
	C1574.979614,628.009216 1575.246338,627.734070 1575.246338,627.734070 
	C1578.785034,627.574341 1582.323730,627.414612 1585.938477,627.643250 
	C1584.974487,628.179382 1583.934204,628.327271 1582.894043,628.475098 
	C1583.177612,630.261963 1583.461304,632.048828 1583.779419,634.053894 
	C1581.062012,634.053894 1577.746948,634.053894 1574.091064,634.056458 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1605.697998,661.930298 
	C1605.911011,663.321716 1605.866699,664.453430 1605.869873,665.900330 
	C1603.958740,666.215576 1602.000244,666.215576 1600.041870,666.215576 
	C1600.191895,666.624695 1600.341919,667.033813 1600.491943,667.442932 
	C1598.594360,668.072449 1596.696899,668.701965 1594.799316,669.331482 
	C1594.573486,668.867188 1594.347656,668.402893 1594.121704,667.938538 
	C1595.409058,666.830994 1596.696533,665.723511 1598.603394,664.082886 
	C1596.788452,663.729858 1595.626709,663.503906 1594.465088,663.277954 
	C1594.457275,662.742126 1594.449585,662.206360 1594.441772,661.670593 
	C1598.108032,661.670593 1601.774292,661.670593 1605.697998,661.930298 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1582.465576,697.342041 
	C1575.893677,692.237976 1583.254272,691.981995 1584.513550,689.371094 
	C1589.016479,695.448425 1588.710693,696.685547 1582.465576,697.342041 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1580.026489,662.966248 
	C1580.070557,663.478638 1580.114502,663.990967 1580.242920,664.881958 
	C1579.567871,667.583984 1578.808350,669.907532 1577.638672,672.599243 
	C1576.805176,673.018555 1576.382080,673.069702 1575.708740,673.167297 
	C1575.324951,673.509949 1575.191162,673.806213 1574.773193,674.135132 
	C1574.324219,674.071411 1574.159668,673.974976 1573.927246,673.587830 
	C1574.201172,671.815186 1574.542603,670.333313 1574.884033,668.851440 
	C1575.317505,666.879761 1575.750977,664.908081 1576.232788,662.536743 
	C1577.279907,662.135132 1578.278809,662.133118 1579.584229,662.307800 
	C1579.936035,662.645142 1579.981201,662.805725 1580.026489,662.966248 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1598.898926,682.889038 
	C1599.100586,692.768738 1599.302368,702.648376 1599.153809,712.776001 
	C1598.240479,712.561768 1597.677368,712.099670 1597.038086,711.364685 
	C1597.303955,708.754456 1597.968872,706.411377 1597.927002,704.080933 
	C1597.807251,697.415466 1597.400513,690.755127 1597.307861,683.660156 
	C1597.972534,683.114746 1598.435669,683.001892 1598.898926,682.889038 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1607.104736,619.199524 
	C1606.664551,621.060974 1606.224487,622.922424 1605.410400,624.812134 
	C1599.721802,624.554871 1599.348633,623.894836 1602.295166,619.473145 
	C1603.650513,618.703186 1605.005859,617.933167 1606.704590,617.101929 
	C1607.048096,617.040588 1607.026611,617.023254 1607.004883,617.390991 
	C1607.023682,618.239014 1607.064209,618.719238 1607.104736,619.199524 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1598.998657,682.757812 
	C1598.435669,683.001892 1597.972534,683.114746 1597.156006,683.288086 
	C1596.076416,681.746155 1595.350342,680.143799 1594.624146,678.541504 
	C1596.094238,677.953125 1597.744995,676.623718 1598.997192,676.929016 
	C1601.687012,677.584900 1601.246460,679.590942 1599.688965,681.794556 
	C1599.339111,682.265503 1599.193359,682.422546 1598.998657,682.757812 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1589.989258,692.012817 
	C1591.754028,691.376282 1593.249512,691.014099 1594.744873,690.651978 
	C1594.839478,691.976685 1595.471558,693.661438 1594.888306,694.531616 
	C1594.211182,695.541626 1592.467773,695.836792 1591.187256,696.442261 
	C1590.698120,695.057312 1590.208984,693.672302 1589.989258,692.012817 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1591.891724,642.919922 
	C1592.060181,642.084534 1592.134521,641.346802 1592.438843,641.234924 
	C1594.140747,640.609192 1595.898315,640.134888 1597.636841,639.608826 
	C1597.423462,641.225159 1597.733154,643.218994 1596.892944,644.382996 
	C1594.831421,647.239319 1593.124634,645.799072 1591.891724,642.919922 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1586.772217,679.569214 
	C1588.235474,678.213379 1589.698853,676.857544 1591.162109,675.501709 
	C1591.670654,675.799805 1592.179321,676.097900 1592.687988,676.395996 
	C1592.543213,678.531555 1592.398438,680.667114 1592.257690,682.743958 
	C1590.578491,682.192810 1589.135986,681.719238 1587.308594,680.762207 
	C1586.873169,680.042175 1586.822632,679.805725 1586.772217,679.569214 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1607.034424,686.942383 
	C1607.016846,685.568848 1606.999146,684.195374 1606.989990,682.409607 
	C1608.390991,678.395020 1609.783569,674.792725 1611.621948,671.128052 
	C1612.067871,671.065735 1612.377319,671.354248 1612.263672,671.704224 
	C1611.136963,676.066833 1610.210693,680.104858 1609.052979,684.075317 
	C1608.743408,685.136902 1607.726685,685.992371 1607.034424,686.942383 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1604.715088,626.129639 
	C1606.544800,627.827942 1608.112915,629.788574 1610.035156,631.874634 
	C1608.030762,632.000122 1605.672485,632.000122 1602.628296,632.000122 
	C1603.395752,629.641907 1603.924683,628.016968 1604.715088,626.129639 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1585.959839,628.390259 
	C1587.691528,627.354187 1589.415283,626.713562 1591.511230,626.137329 
	C1591.618164,626.737122 1591.465454,627.385437 1591.064575,627.785217 
	C1590.097778,628.750000 1589.015137,629.598694 1587.979248,630.494263 
	C1589.180420,631.155762 1590.381592,631.817200 1592.411377,632.934998 
	C1590.875977,633.257629 1589.033936,634.090759 1588.639282,633.644287 
	C1587.442505,632.290710 1586.806274,630.441711 1585.959839,628.390259 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1574.501831,668.936157 
	C1574.542603,670.333313 1574.201172,671.815186 1573.792969,673.949219 
	C1573.522705,676.045959 1573.318848,677.490540 1572.686523,678.984802 
	C1571.970825,679.055054 1571.683960,679.075684 1571.396973,679.096375 
	C1571.396973,679.096375 1571.198242,679.192200 1571.117920,679.116638 
	C1570.389526,678.277466 1569.741211,677.513855 1569.004150,676.462036 
	C1571.397217,676.443665 1572.519775,675.620178 1572.367188,672.847412 
	C1572.298950,671.607849 1573.496582,670.298584 1574.501831,668.936157 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1653.043091,722.367188 
	C1652.896729,722.887939 1652.741943,722.964966 1652.328125,723.035645 
	C1651.706299,719.981995 1651.343506,716.934814 1651.293945,713.437012 
	C1652.000244,712.950623 1652.393677,712.914795 1652.786987,712.878906 
	C1652.869629,715.893738 1652.952148,718.908569 1653.043091,722.367188 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1652.830322,712.499268 
	C1652.393677,712.914795 1652.000244,712.950623 1651.309082,712.988586 
	C1651.011353,709.816711 1651.011353,706.642761 1651.011353,703.468750 
	C1651.460083,703.460938 1651.908691,703.453125 1652.357422,703.445374 
	C1652.529541,706.336731 1652.701660,709.228088 1652.830322,712.499268 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1568.581299,702.169189 
	C1568.319580,700.103027 1568.288086,697.598511 1568.641724,695.074768 
	C1568.955078,697.280579 1568.883301,699.505737 1568.581299,702.169189 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1577.898315,676.022095 
	C1578.929688,676.637024 1579.961182,677.251892 1582.096436,678.524902 
	C1579.323730,678.073608 1577.926880,677.846191 1576.028320,677.820068 
	C1575.526733,678.021301 1575.055908,678.019287 1575.128174,677.740479 
	C1575.510254,676.978760 1575.819824,676.495850 1576.425293,675.997437 
	C1577.113525,675.995361 1577.505859,676.008728 1577.898315,676.022095 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1575.055908,678.019287 
	C1575.055908,678.019287 1575.526733,678.021301 1575.761963,678.010254 
	C1575.594604,681.265320 1574.443481,682.634705 1571.501709,679.414185 
	C1571.683960,679.075684 1571.970825,679.055054 1572.953369,678.933960 
	C1574.117920,678.562073 1574.586914,678.290710 1575.055908,678.019287 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1586.464111,679.734009 
	C1586.822632,679.805725 1586.873169,680.042175 1586.961792,680.639404 
	C1585.486328,681.000061 1583.972656,681.000061 1582.458984,681.000061 
	C1582.462158,680.552734 1582.465454,680.105347 1582.468750,679.658020 
	C1583.697876,679.738281 1584.927002,679.818542 1586.464111,679.734009 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1611.186157,628.833496 
	C1612.161987,630.032471 1613.137817,631.231445 1614.066528,632.843384 
	C1613.652710,633.025879 1613.286255,632.795471 1612.730591,632.296509 
	C1612.054199,631.277161 1611.566895,630.526489 1611.018311,629.416077 
	C1610.957031,629.056274 1611.186157,628.833435 1611.186157,628.833496 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1663.954346,719.358887 
	C1664.966187,719.505188 1665.990112,719.818298 1667.014038,720.131409 
	C1666.864624,720.528809 1666.715210,720.926208 1666.565796,721.323608 
	C1665.891113,720.929810 1665.216309,720.535950 1664.253662,720.001099 
	C1663.965820,719.860046 1663.966309,719.525635 1663.954346,719.358887 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1577.983398,675.641357 
	C1577.505859,676.008728 1577.113525,675.995361 1576.250244,675.805298 
	C1575.726562,675.290588 1575.673828,674.952454 1575.746582,674.250244 
	C1575.901123,673.631104 1575.929932,673.375977 1575.958862,673.120850 
	C1576.382080,673.069702 1576.805176,673.018555 1577.547485,672.955139 
	C1577.933838,673.715515 1578.001099,674.488098 1577.983398,675.641357 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1612.744019,669.147583 
	C1613.187378,669.053650 1613.391235,669.105896 1613.811035,669.532837 
	C1613.477295,670.389832 1612.927246,670.872009 1612.377319,671.354248 
	C1612.377319,671.354248 1612.067871,671.065735 1611.910522,670.921509 
	C1612.003662,670.282715 1612.254150,669.788208 1612.744019,669.147583 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1604.549316,668.518555 
	C1604.525391,668.519409 1604.573120,668.517700 1604.549316,668.518555 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1729.342285,668.152344 
	C1729.115845,667.659851 1729.255249,667.301758 1729.709106,666.956909 
	C1729.918457,667.408997 1729.813232,667.847900 1729.342285,668.152344 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1728.359863,669.176697 
	C1728.117065,668.651794 1728.250854,668.290833 1728.701416,667.953186 
	C1728.924194,668.431152 1728.830200,668.885925 1728.359863,669.176697 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1698.947876,628.675659 
	C1698.606445,628.749512 1698.370483,628.516602 1698.062744,628.039429 
	C1698.344971,627.986389 1698.699219,628.177673 1698.947876,628.675659 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1685.538574,621.560547 
	C1685.297852,621.527710 1685.261963,621.339355 1685.226196,621.151062 
	C1685.398560,621.235718 1685.570923,621.320374 1685.538574,621.560547 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1674.270996,646.326172 
	C1674.691162,645.830627 1675.329712,645.629028 1676.161133,645.144531 
	C1676.902466,645.327698 1677.450684,645.793701 1678.077881,646.529419 
	C1676.934448,646.739380 1675.711914,646.679749 1674.270996,646.326172 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1639.016602,619.662598 
	C1638.343872,619.560913 1637.681274,619.114258 1637.017822,618.341248 
	C1637.686890,618.449158 1638.356812,618.883423 1639.016602,619.662598 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1667.978760,649.021118 
	C1653.700928,649.036255 1639.437988,649.036255 1625.175171,649.036255 
	C1625.174438,648.801758 1625.173706,648.567322 1625.173096,648.332825 
	C1639.410889,648.332825 1653.648682,648.332825 1667.954224,648.653625 
	C1668.021973,648.974426 1667.993652,649.005920 1667.978760,649.021118 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1622.319092,636.124634 
	C1622.708740,637.585205 1622.875000,639.288513 1623.041260,640.991882 
	C1622.725952,641.006409 1622.410767,641.020874 1622.095581,641.035400 
	C1622.095581,639.479370 1622.095581,637.923401 1622.319092,636.124634 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2145.965332,836.042053 
	C2149.009521,838.059631 2152.053955,840.077148 2155.446777,842.175903 
	C2155.795166,843.721008 2155.795166,845.185059 2155.795166,847.304749 
	C2159.826660,847.304749 2163.536377,847.339417 2167.244873,847.285278 
	C2168.479980,847.267273 2169.711914,847.021912 2170.945312,846.880493 
	C2170.693848,848.333435 2170.442627,849.786316 2169.783203,851.718750 
	C2168.131592,852.723328 2166.888428,853.248413 2165.413086,853.484009 
	C2165.003418,853.021973 2164.826172,852.849487 2164.687500,852.364014 
	C2164.716553,851.886292 2164.747559,851.703857 2164.685059,851.562805 
	C2164.635498,851.450928 2164.456787,851.303406 2164.359131,851.319153 
	C2162.806885,851.569275 2161.258301,851.843262 2159.727783,852.108459 
	C2159.988037,853.026245 2160.248779,853.945862 2160.221191,854.986206 
	C2157.675781,853.868347 2155.418213,852.629822 2153.335938,851.487366 
	C2152.794434,852.148132 2151.721680,853.456604 2150.295654,855.196106 
	C2149.785400,853.005249 2149.483643,851.710083 2149.120605,850.151367 
	C2147.573975,851.381287 2146.451660,852.273743 2145.391357,853.116882 
	C2141.548340,850.371460 2137.967285,849.042175 2133.942139,852.398621 
	C2133.129883,853.075928 2131.434082,852.693848 2130.308105,852.497620 
	C2129.318115,851.817932 2128.165283,851.440796 2126.683350,851.069336 
	C2125.918945,851.016479 2125.483643,850.957947 2124.770264,850.740967 
	C2123.059326,850.830994 2121.626709,851.079529 2121.162109,851.160095 
	C2122.068604,853.494080 2122.656006,855.089600 2123.360840,856.631348 
	C2123.406738,856.731567 2124.354004,856.419556 2125.229980,856.520752 
	C2126.686279,857.330200 2128.196777,858.694519 2128.830322,858.366211 
	C2131.052002,857.214783 2132.878418,856.739319 2135.055176,858.444702 
	C2135.555176,858.836365 2136.925293,858.252502 2137.840820,857.951965 
	C2140.111084,857.206543 2142.313232,856.225525 2144.617920,855.627930 
	C2145.614014,855.369568 2147.510498,855.469055 2147.778320,856.010010 
	C2149.934570,860.363953 2151.898193,855.646729 2153.948242,855.984558 
	C2156.199707,856.355652 2158.519775,856.317444 2160.812256,856.400574 
	C2160.902100,856.403870 2161.013428,855.816345 2161.434326,855.509155 
	C2163.417969,856.353271 2165.081787,857.192139 2166.724854,858.020569 
	C2168.018555,855.647278 2169.016846,853.815979 2170.015137,851.984619 
	C2171.727539,851.859680 2173.439697,851.734802 2175.151855,851.609863 
	C2174.752197,853.138306 2174.352539,854.666748 2173.644531,857.373413 
	C2176.933594,859.978943 2180.952148,863.162170 2184.970459,866.345398 
	C2184.628662,866.729431 2184.286865,867.113464 2183.945068,867.497498 
	C2186.120605,867.874878 2189.001953,863.025208 2190.655273,868.574646 
	C2190.787354,868.901001 2190.854980,868.955017 2190.571533,868.883667 
	C2190.172119,869.188232 2190.056396,869.564148 2189.940674,869.940063 
	C2189.940674,869.940063 2190.000488,869.993896 2189.699707,869.841919 
	C2189.213379,869.765442 2189.028320,869.840881 2188.843262,869.916321 
	C2185.521240,869.281433 2182.198975,868.646545 2178.395020,867.618774 
	C2176.615967,865.768372 2175.318359,864.310852 2174.020996,862.853271 
	C2173.543701,863.407227 2173.066406,863.961121 2172.589111,864.515076 
	C2174.325684,865.751282 2176.062256,866.987549 2177.798828,868.223755 
	C2176.803711,868.802429 2175.812012,869.878052 2174.812744,869.885559 
	C2158.796875,870.005859 2142.780029,869.993591 2126.763428,869.986145 
	C2126.297607,869.985901 2125.832031,869.708923 2125.370605,869.056213 
	C2126.034180,867.841431 2126.693604,867.131409 2127.691406,866.226929 
	C2130.948242,865.406250 2134.260742,862.628357 2135.850830,866.726074 
	C2138.317627,866.485229 2140.548828,866.267395 2143.062988,866.021973 
	C2143.062988,865.226990 2143.062988,863.258972 2143.062988,860.981201 
	C2141.434814,862.117737 2140.272461,862.929077 2139.110107,863.740417 
	C2139.032959,863.306396 2138.955566,862.872314 2138.878418,862.438232 
	C2135.470703,862.438232 2132.062988,862.438232 2129.129883,862.438232 
	C2128.275391,863.808899 2127.611816,864.873169 2126.593262,866.007263 
	C2124.864746,866.221863 2123.491699,866.366638 2122.118408,866.511414 
	C2122.066162,866.140808 2122.013916,865.770203 2121.961914,865.399536 
	C2123.492432,864.281555 2125.023193,863.163513 2126.553711,862.045532 
	C2126.193359,861.547791 2125.832764,861.050049 2125.472412,860.552307 
	C2123.811279,861.798218 2121.673340,862.756836 2120.680176,864.405151 
	C2120.142822,865.297302 2121.558838,867.366211 2122.093506,868.904297 
	C2121.729736,869.218079 2121.366699,869.804688 2121.001709,869.806152 
	C2109.056152,869.854065 2097.110596,869.845703 2084.806641,869.845703 
	C2084.605713,868.525574 2084.318115,866.636536 2083.990967,864.391846 
	C2083.955811,863.848877 2083.960205,863.661499 2083.941406,863.193420 
	C2087.616943,862.548218 2091.310791,862.112915 2095.016846,861.846863 
	C2097.131592,861.695068 2099.266113,861.819458 2101.204102,861.819458 
	C2104.415039,852.621948 2095.263916,843.896057 2088.180420,843.135376 
	C2084.483398,842.738281 2083.871338,841.236694 2083.946045,838.090515 
	C2084.119629,830.790466 2084.045166,823.484497 2084.115479,815.733521 
	C2084.159668,815.286072 2084.146729,815.156738 2084.551758,815.233643 
	C2094.333984,815.353333 2103.712891,815.518494 2113.086914,815.365356 
	C2115.953369,815.318542 2117.722900,815.917297 2118.015625,819.340454 
	C2121.536377,824.247803 2120.740479,831.025879 2126.206299,834.732666 
	C2128.820801,836.505798 2141.731201,839.940613 2144.535400,838.377441 
	C2145.252441,837.977783 2145.536377,836.801575 2146.005127,835.996460 
	C2145.990723,836.010803 2145.965332,836.042053 2145.965332,836.042053 
M2128.483887,840.971252 
	C2127.999756,845.101868 2125.189209,844.891724 2120.967041,844.632263 
	C2123.113037,842.703674 2124.449951,841.502380 2125.786621,840.301086 
	C2125.525391,839.923584 2125.263916,839.546143 2125.002441,839.168640 
	C2123.388428,840.383118 2121.532959,841.398315 2120.282959,842.913147 
	C2119.804443,843.492920 2120.432373,846.050293 2121.038818,846.251526 
	C2122.565918,846.758179 2124.370605,846.381104 2126.058350,846.467834 
	C2127.092529,846.520935 2128.199219,847.105957 2129.128418,846.873657 
	C2131.237061,846.346497 2133.403076,845.706665 2135.242432,844.606140 
	C2136.019531,844.141113 2136.032959,842.399780 2136.393555,841.238647 
	C2134.482178,840.722351 2132.585693,840.131958 2130.649658,839.736450 
	C2130.091553,839.622437 2129.419189,840.069275 2128.483887,840.971252 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2104.294922,873.703247 
	C2098.003662,873.974121 2092.013672,874.010254 2086.026855,873.888855 
	C2085.065186,873.869324 2084.117432,873.146973 2083.081543,872.374756 
	C2114.933838,872.000000 2146.867432,872.000000 2178.801270,872.000000 
	C2178.811523,872.324585 2178.821533,872.649109 2178.831787,872.973694 
	C2177.207520,872.973694 2175.583252,872.973083 2173.958984,872.973755 
	C2152.315918,872.982788 2130.672852,872.983887 2109.029785,873.018921 
	C2107.552002,873.021301 2106.074707,873.277893 2104.294922,873.703247 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2127.352783,866.421326 
	C2126.693604,867.131409 2126.034180,867.841431 2125.110352,868.703125 
	C2124.157715,868.862793 2123.469482,868.870789 2122.437500,868.891541 
	C2121.558838,867.366211 2120.142822,865.297302 2120.680176,864.405151 
	C2121.673340,862.756836 2123.811279,861.798218 2125.472412,860.552307 
	C2125.832764,861.050049 2126.193359,861.547791 2126.553711,862.045532 
	C2125.023193,863.163513 2123.492432,864.281555 2121.961914,865.399536 
	C2122.013916,865.770203 2122.066162,866.140808 2122.118408,866.511414 
	C2123.491699,866.366638 2124.864746,866.221863 2126.694824,866.127625 
	C2127.151855,866.178162 2127.352783,866.421326 2127.352783,866.421326 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2190.262695,870.013916 
	C2190.056396,869.564148 2190.172119,869.188232 2190.614258,868.875854 
	C2190.822021,869.322205 2190.703369,869.705017 2190.262695,870.013916 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2188.932373,870.189575 
	C2189.028320,869.840881 2189.213379,869.765442 2189.673340,869.810364 
	C2189.639404,870.108093 2189.330322,870.285461 2188.932373,870.189575 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2156.230957,840.130249 
	C2155.916504,839.683533 2155.891602,839.482361 2155.818848,838.980591 
	C2155.770996,838.680054 2155.828125,838.330261 2155.898682,838.167725 
	C2159.247314,838.986633 2162.547852,839.902283 2165.781494,841.012878 
	C2166.336426,841.203491 2166.605957,842.225220 2166.791992,843.222656 
	C2163.584961,841.161804 2157.463379,850.703918 2156.230957,840.130249 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2079.020996,792.110718 
	C2076.514160,792.555542 2072.967285,789.755066 2072.638184,795.392090 
	C2070.654053,796.377991 2068.631592,797.034485 2066.609131,797.690918 
	C2066.401367,797.246643 2066.193604,796.802307 2065.986084,796.357971 
	C2067.431152,795.342773 2068.876465,794.327637 2071.081055,792.778931 
	C2069.537354,792.465698 2068.630371,792.281738 2067.372070,792.076660 
	C2068.028809,791.309082 2068.939941,790.331543 2070.063477,789.862366 
	C2072.697266,788.762451 2075.432861,787.906067 2078.552246,786.960571 
	C2078.977539,786.970154 2079.114990,787.100281 2079.114990,787.100281 
	C2079.101562,788.618591 2079.088379,790.136841 2079.020996,792.110718 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2078.912109,786.901917 
	C2079.034668,786.859131 2079.222656,786.884521 2079.262939,787.005127 
	C2079.114990,787.100281 2078.977539,786.970154 2078.912109,786.901917 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2097.784668,851.377380 
	C2098.928711,853.899231 2099.871094,856.110535 2101.010498,858.784302 
	C2095.120850,858.784302 2090.028076,858.784302 2084.466797,858.784302 
	C2084.466797,854.012573 2084.466797,849.332397 2084.466797,844.342041 
	C2090.019287,844.432251 2094.115479,847.109985 2097.784668,851.377380 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2051.064453,832.577881 
	C2050.796875,833.109619 2050.520996,833.210266 2049.786621,833.586792 
	C2049.186279,834.207581 2049.056885,834.586853 2048.927734,834.966064 
	C2048.927734,834.966125 2048.998779,834.958191 2048.650391,834.909668 
	C2047.482056,835.531677 2046.662476,836.202209 2045.842773,836.872681 
	C2043.085938,836.568604 2040.329102,836.264526 2037.245117,835.924377 
	C2037.245117,831.712158 2037.245117,827.161560 2037.245117,821.962524 
	C2041.667847,821.962524 2045.958252,821.962524 2051.043701,821.962524 
	C2051.043701,825.595520 2051.043701,828.854004 2051.064453,832.577881 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2042.010986,841.966309 
	C2040.556274,843.278259 2039.101685,844.590210 2037.323730,845.993408 
	C2037.000488,843.681030 2037.000488,841.277527 2037.000488,838.376038 
	C2039.361206,838.376038 2041.377930,838.376038 2043.599854,838.926758 
	C2043.859131,839.655884 2043.913330,839.834351 2043.983032,840.004272 
	C2043.998657,839.995789 2044.023438,839.969910 2043.716064,839.970947 
	C2043.262573,840.321655 2043.116455,840.671387 2042.984131,841.009399 
	C2042.997925,840.997620 2043.021240,840.970154 2042.719482,840.974609 
	C2042.267700,841.326599 2042.117676,841.674194 2041.981201,842.009827 
	C2041.994629,841.997925 2042.010986,841.966309 2042.010986,841.966309 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2045.834473,837.084961 
	C2046.662476,836.202209 2047.482056,835.531677 2048.618896,834.892456 
	C2048.927734,835.569092 2048.919434,836.214417 2048.631836,837.329712 
	C2047.758301,838.091675 2047.164429,838.383667 2046.361816,838.434082 
	C2046.013062,838.032227 2045.872925,837.871948 2045.732910,837.711670 
	C2045.732910,837.711670 2045.826172,837.297180 2045.834473,837.084961 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2044.741455,838.721863 
	C2044.871826,838.420227 2045.068726,838.171387 2045.532715,837.843506 
	C2045.872925,837.871948 2046.013062,838.032227 2046.231689,838.616211 
	C2046.127808,839.279297 2045.916382,839.489685 2045.441406,839.434814 
	C2045.051636,839.039551 2044.896606,838.880676 2044.741455,838.721863 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2043.967407,840.012756 
	C2043.913330,839.834351 2043.859131,839.655884 2043.872314,839.205933 
	C2043.939453,838.934387 2044.352539,838.871460 2044.546997,838.796631 
	C2044.896606,838.880676 2045.051636,839.039551 2045.180786,839.656616 
	C2044.759033,840.080811 2044.363159,840.046753 2043.967407,840.012756 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2041.557983,768.001709 
	C2042.152222,772.295959 2042.305420,776.590210 2042.397461,781.276123 
	C2040.742188,781.784912 2039.147949,781.902039 2036.842529,782.071472 
	C2036.842529,777.917236 2036.544556,774.151855 2037.038330,770.493347 
	C2037.172729,769.497498 2039.692139,768.823425 2041.557983,768.001709 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2033.057129,783.989136 
	C2032.728271,784.391541 2032.424438,784.408875 2032.120483,784.426147 
	C2032.120483,776.596802 2032.120483,768.767395 2032.120483,760.938049 
	C2032.441040,760.938416 2032.761475,760.938721 2033.082031,760.939026 
	C2033.082031,768.494019 2033.082031,776.049011 2033.057129,783.989136 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2084.282959,902.699219 
	C2083.921387,902.730652 2083.704590,902.618958 2083.487793,902.507324 
	C2083.582764,902.411133 2083.706787,902.217896 2083.767090,902.236023 
	C2083.998291,902.305969 2084.209229,902.443665 2084.282959,902.699219 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1955.578369,741.026123 
	C1954.505127,741.427979 1953.069214,742.189392 1951.611694,742.233398 
	C1942.720215,742.502258 1933.824097,742.608215 1924.461426,742.794678 
	C1924.022217,741.327515 1924.173950,739.824036 1924.054077,738.342590 
	C1923.803101,735.242554 1925.447998,734.169067 1928.220337,734.167297 
	C1935.122437,734.162781 1942.024536,734.165771 1947.356079,734.165771 
	C1950.625244,737.034668 1952.915527,739.044434 1955.578369,741.026123 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1977.602661,734.663269 
	C1976.758789,736.790161 1975.449097,739.009949 1974.008789,741.540894 
	C1968.449219,741.768921 1963.020264,741.685852 1957.246216,741.355957 
	C1955.729980,739.459106 1954.558716,737.809082 1952.630859,735.093384 
	C1961.074463,734.977173 1969.105591,734.866699 1977.602661,734.663269 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1930.991455,774.991333 
	C1930.904053,775.698486 1930.828735,776.419128 1930.753296,777.139709 
	C1930.439697,777.054932 1930.125977,776.970154 1929.812256,776.885376 
	C1930.022339,776.221252 1930.232300,775.557068 1930.735229,774.962524 
	C1931.028076,775.032104 1931.003662,775.004761 1930.991455,774.991333 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2059.284180,808.300049 
	C2057.530518,808.433472 2056.149902,808.373291 2054.384766,808.157227 
	C2056.064453,805.744507 2058.128906,803.487610 2060.193359,801.230774 
	C2060.650146,801.454346 2061.106934,801.677917 2061.563721,801.901489 
	C2060.928223,803.969849 2060.292725,806.038147 2059.284180,808.300049 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2057.437988,801.353027 
	C2055.936279,803.136169 2054.686768,804.655090 2053.437256,806.174011 
	C2053.104980,805.995789 2052.772949,805.817505 2052.440918,805.639221 
	C2052.440918,803.669434 2052.440918,801.699585 2052.440918,799.689026 
	C2057.643799,798.405212 2057.811035,798.448059 2057.437988,801.353027 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2043.983032,840.004272 
	C2044.363159,840.046753 2044.759033,840.080811 2045.415527,839.893005 
	C2045.916382,839.489685 2046.127808,839.279297 2046.440552,838.857788 
	C2047.164429,838.383667 2047.758301,838.091675 2048.650879,837.653015 
	C2049.663818,838.936096 2050.888916,840.329773 2050.993164,841.802734 
	C2051.266846,845.677673 2051.052979,849.587036 2051.027832,853.994019 
	C2046.418823,854.205933 2041.815674,853.906494 2037.057129,853.596985 
	C2035.380249,848.105896 2041.129639,846.417175 2042.252930,842.284424 
	C2042.010986,841.966309 2041.994629,841.997925 2042.357422,842.143005 
	C2042.820557,841.848755 2042.920898,841.409424 2043.021240,840.970154 
	C2043.021240,840.970154 2042.997925,840.997620 2043.347168,841.143555 
	C2043.805542,840.849670 2043.914551,840.409790 2044.023438,839.969910 
	C2044.023438,839.969910 2043.998657,839.995789 2043.983032,840.004272 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M2059.608887,890.951477 
	C2060.010986,891.421997 2059.968018,891.878296 2059.952881,892.678162 
	C2059.980957,893.021729 2060.000244,893.000061 2059.721924,893.017822 
	C2059.288330,893.362183 2059.133301,893.688782 2058.978027,894.015442 
	C2058.978027,894.015442 2059.000000,893.999695 2058.723145,894.018799 
	C2058.289307,894.364075 2058.132568,894.690308 2057.975830,895.016479 
	C2057.975830,895.016479 2058.000000,894.999695 2057.726074,895.023926 
	C2057.294189,895.370239 2057.136230,895.692261 2056.978271,896.014282 
	C2056.978271,896.014282 2056.999756,896.000061 2056.666016,896.091919 
	C2056.217285,896.800232 2056.101807,897.416626 2055.986572,898.033020 
	C2055.986572,898.033020 2056.002441,898.005493 2056.016113,897.997803 
	C2055.450928,898.078247 2054.871582,898.166443 2054.145020,898.557007 
	C2053.674805,896.472412 2053.352051,894.085449 2053.553223,891.445190 
	C2054.295654,891.201599 2054.510742,891.178223 2054.722168,891.121765 
	C2056.202881,891.060303 2057.683350,890.998779 2059.608887,890.951477 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2054.551025,890.771362 
	C2054.510742,891.178223 2054.295654,891.201599 2053.917969,891.174255 
	C2053.555908,890.733643 2053.353271,890.310547 2052.970947,889.561768 
	C2053.254395,889.149231 2053.717285,889.062378 2054.533691,888.965759 
	C2054.718018,889.444336 2054.549072,889.932617 2054.551025,890.771362 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2118.038086,818.966187 
	C2117.722900,815.917297 2115.953369,815.318542 2113.086914,815.365356 
	C2103.712891,815.518494 2094.333984,815.353333 2084.504883,815.188782 
	C2084.052734,815.066956 2083.949463,814.622314 2083.942383,814.394165 
	C2094.305420,813.923340 2104.683594,813.826904 2115.039307,813.298950 
	C2116.842773,813.206970 2118.553467,811.295105 2120.666748,810.088379 
	C2121.364502,811.073608 2121.658447,813.153748 2122.046875,813.171570 
	C2128.564697,813.470398 2131.075684,819.394165 2135.766602,822.760010 
	C2135.796875,823.286560 2135.588623,823.582336 2134.836426,823.818237 
	C2132.019287,821.654785 2129.812500,819.473267 2127.457031,817.466125 
	C2124.141602,814.640991 2119.795166,815.500000 2118.842285,818.972046 
	C2118.396484,818.944153 2118.217285,818.955139 2118.038086,818.966187 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2083.895508,806.388733 
	C2085.959961,805.002258 2087.966309,803.938660 2089.972656,802.875061 
	C2090.200928,803.206909 2090.428955,803.538757 2090.657227,803.870667 
	C2090.090576,804.495972 2089.551758,805.149658 2088.949463,805.738281 
	C2088.397705,806.277527 2087.777344,806.746704 2087.566650,808.323730 
	C2089.114258,807.912231 2090.661865,807.500732 2092.541992,807.000793 
	C2092.809326,805.767822 2093.100830,804.422791 2093.578125,802.220642 
	C2097.209229,802.981567 2100.608154,803.693909 2105.002441,804.614746 
	C2105.134521,804.531128 2106.324463,803.777832 2107.908691,802.774841 
	C2107.908691,805.208618 2107.908691,807.139648 2107.908691,808.605530 
	C2105.365479,808.605530 2102.977539,808.605530 2100.233643,808.605530 
	C2100.157959,808.163757 2099.937988,806.881958 2099.721191,805.617920 
	C2096.523193,807.167358 2093.508789,809.060425 2090.221924,810.035645 
	C2088.604492,810.515503 2086.483398,809.296692 2084.219727,808.761353 
	C2083.845459,808.021240 2083.841553,807.366455 2083.895508,806.388733 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2049.208740,834.857544 
	C2049.056885,834.586853 2049.186279,834.207581 2049.632324,833.744385 
	C2049.796143,834.023315 2049.643066,834.386169 2049.208740,834.857544 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2073.885010,806.909607 
	C2074.675537,807.196228 2075.108887,807.495056 2075.542480,807.793945 
	C2074.239014,808.013550 2072.935791,808.233093 2071.632324,808.452637 
	C2071.591309,808.044617 2071.550049,807.636658 2071.509033,807.228699 
	C2072.181885,807.126404 2072.854980,807.024109 2073.885010,806.909607 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2056.338867,897.911499 
	C2056.101807,897.416626 2056.217285,896.800232 2056.670410,896.080078 
	C2056.902588,896.580872 2056.796875,897.185425 2056.338867,897.911499 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2059.334961,894.127930 
	C2059.133301,893.688782 2059.288330,893.362183 2059.730225,893.006226 
	C2059.908447,893.398010 2059.800049,893.819214 2059.334961,894.127930 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2058.339355,895.130554 
	C2058.132568,894.690308 2058.289307,894.364075 2058.730957,894.007812 
	C2058.911377,894.400024 2058.807129,894.822327 2058.339355,895.130554 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2057.342773,896.145996 
	C2057.136230,895.692261 2057.294189,895.370239 2057.734375,895.011841 
	C2057.913574,895.409546 2057.810547,895.843628 2057.342773,896.145996 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2067.004883,808.005859 
	C2066.912109,808.047180 2066.814697,808.083313 2066.853516,808.056641 
	C2066.990234,807.993958 2067.000000,808.000610 2067.004883,808.005859 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1565.372192,682.884155 
	C1565.817261,686.594299 1565.860962,690.370911 1565.519287,694.544434 
	C1564.223999,694.741394 1563.313965,694.541443 1562.026245,694.258545 
	C1562.026245,698.110962 1562.141357,701.579468 1561.987793,705.036011 
	C1561.876465,707.541321 1561.634155,710.213623 1557.908936,709.681030 
	C1557.300903,708.026001 1556.777344,706.694641 1556.692871,705.363647 
	C1557.707275,705.120850 1558.780762,704.886780 1558.785400,704.633118 
	C1558.904785,698.108765 1558.912720,691.582397 1558.960938,684.679443 
	C1558.906128,683.805176 1558.830566,683.307739 1558.755005,682.810364 
	C1558.256714,681.284058 1557.758301,679.757690 1557.260010,678.231384 
	C1557.496094,677.912842 1557.732300,677.594238 1557.968506,677.275696 
	C1558.943115,678.151794 1560.714844,678.998718 1560.748169,679.909180 
	C1560.863770,683.075989 1562.545898,683.361328 1565.372192,682.884155 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1558.309937,682.850403 
	C1558.830566,683.307739 1558.906128,683.805176 1558.589111,684.740051 
	C1554.238892,685.813660 1550.281616,686.449707 1546.324219,687.085754 
	C1546.388184,686.018982 1546.452148,684.952209 1546.583252,683.567688 
	C1550.388550,683.130066 1554.126709,683.010254 1558.309937,682.850403 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1565.616577,602.146606 
	C1565.907227,602.972168 1566.197876,603.797729 1566.791626,605.170288 
	C1567.032715,606.465271 1566.970581,607.213257 1566.908447,607.961304 
	C1565.102661,608.469910 1563.296997,608.978516 1561.238281,608.855591 
	C1561.013672,606.998596 1561.041992,605.773071 1561.070190,604.547485 
	C1562.133911,603.684570 1563.197754,602.821594 1564.754639,602.044067 
	C1565.247681,602.129517 1565.616577,602.146606 1565.616577,602.146606 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1568.814697,604.945251 
	C1570.569702,604.351440 1572.324707,603.757629 1574.079712,603.163879 
	C1574.143066,604.485352 1574.621826,605.969727 1574.181152,607.093567 
	C1573.323120,609.281860 1571.658813,610.096558 1569.214111,607.772278 
	C1568.864136,606.475708 1568.839355,605.710510 1568.814697,604.945251 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1560.688965,604.484009 
	C1561.041992,605.773071 1561.013672,606.998596 1560.999878,608.594238 
	C1560.093750,608.964417 1559.173096,608.964417 1558.143677,608.964417 
	C1558.143677,606.797852 1558.143677,604.718140 1558.143677,601.711426 
	C1559.223022,603.062744 1559.765381,603.741638 1560.688965,604.484009 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1552.712646,678.931519 
	C1553.164429,678.918701 1553.460571,678.895203 1553.756836,678.871643 
	C1553.759644,678.910645 1553.762451,678.949585 1553.765381,678.988525 
	C1553.387329,679.014343 1553.009399,679.042725 1552.630981,679.059570 
	C1552.608521,679.060547 1552.581909,678.969177 1552.712646,678.931519 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1552.972656,605.027344 
	C1554.130859,605.292480 1555.306519,605.537964 1556.482300,605.783508 
	C1556.372070,606.144043 1556.261719,606.504578 1556.151489,606.865112 
	C1555.171509,606.446655 1554.191406,606.028198 1553.116577,605.287842 
	C1553.021729,604.966003 1552.990112,605.007812 1552.972656,605.027344 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1568.523560,604.954224 
	C1568.839355,605.710510 1568.864136,606.475708 1568.943359,607.620850 
	C1568.533813,607.992249 1568.069824,607.983704 1567.257080,607.968201 
	C1566.970581,607.213257 1567.032715,606.465271 1567.081055,605.341797 
	C1567.455811,604.965271 1567.844238,604.964233 1568.523560,604.954224 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1565.915527,601.977417 
	C1565.616577,602.146606 1565.247681,602.129517 1565.109131,602.007446 
	C1565.124634,601.045227 1565.278687,600.205017 1565.432861,599.364868 
	C1565.774414,599.397583 1566.116089,599.430298 1566.457764,599.463074 
	C1566.376709,600.244812 1566.295532,601.026489 1565.915527,601.977417 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1620.035645,622.634705 
	C1618.566406,622.892151 1617.105103,622.777344 1615.070190,622.617371 
	C1615.062378,620.156311 1613.472778,616.322937 1619.487427,616.236084 
	C1619.924927,618.279907 1619.984375,620.271179 1620.035645,622.634705 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1621.028076,623.754150 
	C1620.730835,623.817627 1620.294434,623.651611 1619.927246,623.227966 
	C1620.293823,623.155090 1620.591431,623.339844 1621.028076,623.754150 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1607.368164,619.411804 
	C1607.064209,618.719238 1607.023682,618.239014 1607.014404,617.399719 
	C1607.675537,616.985291 1608.305542,616.929871 1609.230957,616.923218 
	C1610.965820,617.293457 1612.405396,617.614807 1613.842773,617.935791 
	C1612.175659,619.309143 1610.921631,620.342224 1609.667480,621.375305 
	C1609.993408,621.795166 1610.319214,622.215027 1610.645020,622.634888 
	C1611.563721,622.165588 1612.482422,621.696289 1613.427979,621.213196 
	C1613.682129,621.694214 1614.003418,622.186340 1613.954468,622.226379 
	C1612.819702,623.155701 1611.644653,624.035889 1610.477051,624.925171 
	C1609.528564,623.158142 1608.580078,621.391113 1607.368164,619.411804 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1610.850952,629.175842 
	C1610.170532,628.630127 1609.596069,627.964844 1609.021729,627.299561 
	C1609.994385,626.736267 1610.966919,626.173035 1613.156738,624.904846 
	C1611.958618,626.904175 1611.564087,627.562622 1611.177856,628.527283 
	C1611.186157,628.833435 1610.957031,629.056274 1610.850952,629.175842 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1724.231567,623.700928 
	C1726.239746,623.304016 1728.168579,623.304016 1730.097290,623.304016 
	C1730.217285,623.793213 1730.337280,624.282410 1730.457397,624.771606 
	C1728.990356,626.442322 1727.523438,628.113037 1725.928101,630.071106 
	C1725.202515,630.163879 1724.098511,629.993835 1724.087769,629.770691 
	C1723.996094,627.885254 1724.104126,625.990112 1724.231567,623.700928 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M835.077271,724.921021 
	C832.017517,727.912659 825.096985,728.671570 820.376160,726.531982 
	C821.776123,725.409546 823.188232,724.277344 825.358765,722.537048 
	C823.220154,722.150269 821.946472,721.919922 820.706726,721.695679 
	C820.263733,718.834534 830.263672,718.191528 833.911438,720.729370 
	C834.306519,721.004333 834.746155,721.215332 835.427795,721.699707 
	C835.486023,722.935974 835.281616,723.928467 835.077271,724.921021 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M835.324585,725.210815 
	C835.281616,723.928467 835.486023,722.935974 835.821045,721.716064 
	C837.440674,721.181702 838.929626,720.874695 840.418640,720.567688 
	C840.516479,721.849976 841.123352,723.410950 840.605835,724.356445 
	C839.539978,726.303711 837.850220,727.812378 835.324585,725.210815 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M747.206116,718.239746 
	C747.957336,719.625122 748.376404,721.174561 748.795471,722.723999 
	C748.155029,722.813049 747.514526,722.902039 746.874023,722.991089 
	C746.874023,721.462036 746.874023,719.932922 747.206116,718.239746 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M749.003784,727.621094 
	C748.437500,727.879761 747.807129,727.783813 747.013489,727.884705 
	C746.850159,728.081543 746.388245,727.994385 746.153198,727.987000 
	C746.366516,726.980347 746.814880,725.981079 747.571472,724.294739 
	C748.226807,725.718079 748.583252,726.492310 749.003784,727.621094 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M599.269714,711.270874 
	C599.402222,709.724854 599.815857,708.491516 601.010620,707.098145 
	C614.331421,706.938171 626.871155,706.938171 639.942566,706.938171 
	C639.577820,708.975647 639.329651,710.362000 639.081482,711.748291 
	C625.904602,711.693420 612.727783,711.638489 599.269714,711.270874 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M679.581665,720.283752 
	C679.881592,720.821472 679.828674,721.194275 679.103271,721.769531 
	C676.372375,721.971985 674.314087,721.971985 672.087769,721.971985 
	C672.087769,724.576782 672.087769,726.567627 672.087769,728.724426 
	C673.175720,728.410400 673.919739,728.195679 674.663696,727.980957 
	C677.572388,727.359619 680.481140,726.738281 683.800293,726.158203 
	C683.589966,734.418945 677.844177,734.066650 671.978088,733.513062 
	C671.647095,733.481873 671.316101,733.450623 670.544067,733.324585 
	C669.932129,732.106384 669.625000,730.984558 669.611267,729.859253 
	C669.478333,718.965881 668.170166,719.921204 679.581665,720.283752 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M674.597107,741.898315 
	C674.910278,742.164062 674.945984,742.661560 674.969238,742.910522 
	C674.244507,743.188232 673.496399,743.216980 672.190857,742.881958 
	C670.094299,741.849609 668.555298,741.180969 665.722473,739.950378 
	C667.391296,741.896667 668.037781,742.650574 668.684265,743.404541 
	C665.408142,743.361511 662.131958,743.318420 658.435059,742.868164 
	C657.983032,742.277161 657.951660,742.093262 657.920349,741.909302 
	C657.901917,737.857300 659.745361,735.678528 664.272766,736.328125 
	C663.220825,737.913147 661.866455,739.065552 659.703430,740.906128 
	C664.621521,741.402893 666.685608,740.120178 666.527405,736.125610 
	C667.494263,735.874023 668.461182,735.622437 669.717285,735.259644 
	C675.682800,734.505676 672.492371,739.712402 674.597107,741.898315 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M657.459229,741.943604 
	C657.951660,742.093262 657.983032,742.277161 658.005493,742.740723 
	C654.761597,743.366455 651.529663,743.968262 648.290833,744.008240 
	C640.143066,744.108887 631.992188,743.955872 623.490723,743.750916 
	C623.203735,743.206055 623.268433,742.814819 623.565735,742.233582 
	C624.799072,741.884277 625.799744,741.725037 627.014893,741.246216 
	C630.442566,740.927795 633.655762,740.928894 637.301880,740.914246 
	C642.191772,741.269958 646.645325,741.692871 651.107361,741.989136 
	C653.062073,742.118835 655.034058,741.989563 657.459229,741.943604 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M639.455750,711.841553 
	C639.329651,710.362000 639.577820,708.975647 639.942566,706.938171 
	C626.871155,706.938171 614.331421,706.938171 601.307495,706.901550 
	C600.823303,706.864929 600.929321,706.431824 601.436401,706.222534 
	C610.320801,705.959778 618.697998,705.906372 627.075256,705.853027 
	C632.415894,705.882507 637.756470,705.911987 643.564575,706.002258 
	C644.032104,706.063049 644.030273,706.497620 643.856934,706.984375 
	C640.101562,710.093323 644.715637,709.718323 644.953369,710.943665 
	C643.245605,711.274048 641.537781,711.604431 639.455750,711.841553 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M562.106934,715.416870 
	C559.015808,715.796021 555.936157,715.940186 552.859375,715.892334 
	C552.210449,715.882263 551.077271,715.212524 551.037354,714.768555 
	C550.964905,713.962646 551.350830,712.546082 551.889099,712.355652 
	C553.939941,711.629883 555.846375,709.112427 558.410217,711.755249 
	C559.190063,712.559143 560.901245,712.459595 562.551880,712.802002 
	C562.651733,713.591309 562.385559,714.352417 562.106934,715.416870 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M645.410034,710.984558 
	C644.715637,709.718323 640.101562,710.093323 643.852173,707.201660 
	C647.580688,707.215088 651.670776,705.837402 654.046997,710.449036 
	C651.379333,710.870544 648.623047,710.947998 645.410034,710.984558 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M676.217041,711.212158 
	C678.082214,708.275757 680.157532,708.239075 682.221069,711.376892 
	C680.338623,711.652039 678.409546,711.584839 676.217041,711.212158 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M666.076050,736.095032 
	C666.685608,740.120178 664.621521,741.402893 659.703430,740.906128 
	C661.866455,739.065552 663.220825,737.913147 664.648010,736.432129 
	C665.022156,736.090515 665.323425,736.077454 666.076050,736.095032 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M572.079346,722.903564 
	C572.694702,721.836243 573.771606,720.811035 575.451538,719.907104 
	C576.257690,720.166077 576.460693,720.303894 576.663696,720.441650 
	C576.359558,721.180908 576.055359,721.920166 575.535156,722.866943 
	C574.393005,723.031555 573.466980,722.988647 572.079346,722.903564 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M561.320312,720.999329 
	C561.235291,720.048767 561.551331,719.100586 561.867371,718.152466 
	C562.871094,718.863953 563.874817,719.575439 564.898010,720.644897 
	C563.852173,721.002502 562.786865,721.002136 561.320312,720.999329 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M573.435181,711.001709 
	C573.991333,708.918030 575.191895,708.324646 576.943298,710.691284 
	C575.987488,711.000061 574.912354,711.001465 573.435181,711.001709 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M585.075256,710.908875 
	C585.371338,710.692505 586.080994,710.513977 586.969238,710.639038 
	C586.594788,710.944092 586.041809,710.945374 585.075256,710.908875 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M827.435364,692.963623 
	C827.056519,692.944092 827.042175,692.477417 827.036438,691.816650 
	C827.003967,689.451111 826.977234,687.746338 826.950439,686.041504 
	C827.141174,684.161682 827.331848,682.281921 828.166504,680.293823 
	C836.332886,683.198059 839.459229,681.655151 843.136292,673.115479 
	C845.654663,672.598633 846.236938,673.284363 844.979614,675.673706 
	C843.850464,677.819397 841.793945,680.601685 842.395996,682.310547 
	C843.861084,686.468811 846.199890,690.520996 849.004578,693.946350 
	C851.578918,697.090332 864.304688,694.690857 866.420227,691.182983 
	C867.096680,690.061279 867.841064,688.980530 868.584961,687.833923 
	C876.535339,691.269958 886.427246,691.563171 888.915466,702.862549 
	C888.967285,703.397156 888.996216,703.764038 888.996216,703.764038 
	C868.580078,703.764038 848.163940,703.764038 827.400024,703.764038 
	C827.400024,701.193787 827.400024,699.835632 827.400024,697.763306 
	C829.207458,698.364929 830.559326,698.749634 831.858154,699.267456 
	C832.933838,699.696289 834.066895,700.871277 834.965637,700.688538 
	C837.747070,700.122803 840.674500,699.457031 843.013428,697.985107 
	C843.812439,697.482239 843.707031,693.335571 842.852661,692.834290 
	C840.479248,691.441589 837.493652,690.499512 834.752991,690.507141 
	C832.437805,690.513550 830.127014,692.097412 827.435364,692.963623 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M848.059082,642.938721 
	C848.059082,642.938721 848.291870,642.811768 848.906250,642.906372 
	C850.198486,642.991943 850.876343,642.982849 851.554260,642.973816 
	C851.554260,642.973816 851.576477,642.870850 851.606873,642.831055 
	C853.341187,644.109741 854.864258,646.074585 856.786560,646.612671 
	C860.836792,647.746460 860.434753,650.811707 859.796204,653.325867 
	C859.480896,654.567444 856.956116,655.674438 855.260193,656.032471 
	C853.071960,656.494263 850.712463,656.144897 848.426758,656.144897 
	C848.003906,656.578247 847.580994,657.011658 847.158142,657.445007 
	C849.086792,660.732239 851.015503,664.019409 852.965942,667.673401 
	C852.771301,668.288330 852.554810,668.536438 852.132568,668.498047 
	C847.379517,664.328308 844.041870,659.828186 845.017578,652.867249 
	C840.133606,652.867249 835.855103,652.842163 831.577026,652.873779 
	C826.561707,652.910828 826.449280,653.087463 827.641907,658.291931 
	C840.194397,655.000244 842.062988,656.023438 839.719727,664.900879 
	C839.319397,664.424377 839.165894,664.087524 838.974487,663.367981 
	C838.938110,660.303345 838.557434,657.835266 835.040222,658.000061 
	C832.018188,658.141602 831.774536,660.225464 831.800903,662.674927 
	C830.291199,663.461914 829.127930,664.177307 827.964661,664.892700 
	C823.908447,662.146240 823.616760,652.275879 828.006836,649.041809 
	C829.454102,648.409424 830.564636,647.993103 831.336670,648.336975 
	C839.544617,651.992310 847.914429,648.939575 855.871521,649.977356 
	C853.077271,647.459839 850.568176,645.199280 848.059082,642.938721 
M849.518372,653.323181 
	C849.518372,653.323181 849.408264,653.502075 849.408264,653.502075 
	C849.408264,653.502075 849.652710,653.477600 849.518372,653.323181 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M839.012451,663.750732 
	C839.165894,664.087524 839.319397,664.424377 839.915344,665.175415 
	C841.189575,667.147278 842.021362,668.704834 842.853149,670.262451 
	C842.913269,670.976379 842.973389,671.690247 843.084961,672.759827 
	C839.459229,681.655151 836.332886,683.198059 828.481079,680.045166 
	C824.045166,676.211609 823.655701,669.534363 827.630920,665.072693 
	C829.127930,664.177307 830.291199,663.461914 832.187012,662.745300 
	C834.950500,663.079651 836.981445,663.415222 839.012451,663.750732 
M833.198608,666.936584 
	C827.258423,667.379517 831.170593,672.101135 830.193176,674.840393 
	C832.344727,672.483154 834.496277,670.125854 837.096375,667.277100 
	C835.523254,667.112122 834.752075,667.031250 833.198608,666.936584 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M843.283325,670.247803 
	C842.021362,668.704834 841.189575,667.147278 840.162109,665.315186 
	C842.062988,656.023438 840.194397,655.000244 827.641907,658.291931 
	C826.449280,653.087463 826.561707,652.910828 831.577026,652.873779 
	C835.855103,652.842163 840.133606,652.867249 845.017578,652.867249 
	C844.041870,659.828186 847.379517,664.328308 851.883667,668.547119 
	C849.131470,669.332825 846.422485,669.782959 843.283325,670.247803 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M888.792847,704.133850 
	C888.996216,703.764038 888.967285,703.397156 888.971680,703.213379 
	C889.463623,703.032288 889.951233,703.034973 891.151001,703.007690 
	C893.194763,702.651489 894.526306,702.325134 896.207764,702.015808 
	C896.952332,701.663330 897.346985,701.293823 897.741577,700.924316 
	C897.142273,700.589905 896.542908,700.255554 895.885071,699.546082 
	C895.607666,697.403259 895.388733,695.635559 895.169861,693.867798 
	C896.920410,693.677856 898.671021,693.487915 900.745422,693.682922 
	C901.022095,694.736145 900.974854,695.404480 900.634155,696.053345 
	C899.862976,696.159119 899.385315,696.284241 898.907654,696.409363 
	C898.943115,696.581543 898.978577,696.753784 899.014038,696.925964 
	C899.651123,696.925964 900.288208,696.925964 900.975952,697.159668 
	C901.026672,697.393433 901.084229,697.868347 900.719849,697.909729 
	C899.943359,698.636841 899.531189,699.322571 899.118958,700.008301 
	C899.734863,700.326172 900.350708,700.644104 900.987549,701.287842 
	C901.024109,702.048767 901.039734,702.483887 900.778625,702.970459 
	C900.339722,703.293701 900.177673,703.565430 900.015625,703.837158 
	C900.970032,703.585449 901.924500,703.333740 903.144226,703.041199 
	C903.587952,702.987000 903.766418,702.973572 903.944824,702.960205 
	C905.526184,703.982727 907.107605,705.005249 908.705139,706.038147 
	C906.868835,709.135071 903.484375,709.086975 898.643677,705.676392 
	C892.997070,709.835266 886.764832,707.141357 880.593628,707.849609 
	C881.076416,706.901367 881.865540,705.544739 882.979736,705.179321 
	C884.734802,704.603760 886.708435,704.694824 888.792847,704.133850 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M854.606689,690.784485 
	C853.587830,693.668457 851.751465,693.507690 850.261658,691.492554 
	C848.543396,689.168518 847.346130,686.459229 845.314209,682.830750 
	C851.906921,682.830750 857.473206,682.617981 862.996216,683.012207 
	C864.085938,683.090027 866.105774,685.875854 865.802795,686.773865 
	C864.899170,689.452393 864.242493,693.500244 859.530518,690.841675 
	C859.129211,688.304260 858.734314,686.328186 858.339355,684.352112 
	C857.433167,684.392578 856.527039,684.433044 855.620850,684.473450 
	C855.282776,686.577148 854.944763,688.680786 854.606689,690.784485 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M867.069824,680.778931 
	C864.455078,680.789490 862.167419,680.873535 859.879333,680.884460 
	C855.111755,680.907227 850.344055,680.891357 844.552673,680.891357 
	C846.440063,677.359802 847.851257,674.719299 849.262451,672.078796 
	C849.667542,672.154663 850.072632,672.230530 850.477783,672.306396 
	C850.793396,674.293274 851.109009,676.280151 851.456970,678.470459 
	C857.279602,680.838501 859.782104,675.142761 864.928955,672.845520 
	C865.780701,675.597534 866.588745,678.208496 867.069824,680.778931 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M896.138184,690.900452 
	C893.034363,690.943481 890.307739,690.943481 886.230042,690.943481 
	C889.922241,687.493713 889.170349,681.810059 893.664917,681.414795 
	C895.341064,684.972168 896.585327,687.612793 897.829590,690.253418 
	C897.391479,690.454773 896.953369,690.656067 896.138184,690.900452 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M895.857788,701.998779 
	C894.526306,702.325134 893.194763,702.651489 891.516113,702.988403 
	C889.938599,700.032776 888.708130,697.066528 887.171387,693.361755 
	C889.713806,693.527771 892.030151,693.679016 894.758179,693.848999 
	C895.388733,695.635559 895.607666,697.403259 895.859009,699.892517 
	C895.880249,701.075623 895.869019,701.537170 895.857788,701.998779 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M901.084229,697.868347 
	C901.084229,697.868347 901.026672,697.393433 900.980103,696.946655 
	C900.933533,696.499878 900.927673,696.072754 900.927673,696.072754 
	C900.974854,695.404480 901.022095,694.736145 901.130493,693.742798 
	C903.601624,693.417664 906.011475,693.417664 909.667480,693.417664 
	C907.609497,696.941772 906.004150,699.690796 904.171814,702.700012 
	C903.766418,702.973572 903.587952,702.987000 902.840210,703.024048 
	C901.865784,703.004822 901.460632,702.961914 901.055420,702.918945 
	C901.039734,702.483887 901.024109,702.048767 901.085205,701.018921 
	C901.135986,699.572205 901.110107,698.720276 901.084229,697.868347 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M908.614990,689.505005 
	C906.186218,690.291443 903.690979,690.702454 900.915527,691.159607 
	C902.596313,688.229187 903.633789,686.420349 904.671326,684.611511 
	C904.331482,684.265625 903.991699,683.919739 903.651855,683.573853 
	C901.615051,684.056641 899.578186,684.539490 896.688416,685.224487 
	C897.589294,683.916138 898.161804,682.244263 898.910583,682.161194 
	C900.837769,681.947449 903.425049,681.749756 904.677795,682.810303 
	C906.468201,684.325989 907.304688,686.968384 908.614990,689.505005 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M854.922302,690.856689 
	C854.944763,688.680786 855.282776,686.577148 855.620850,684.473450 
	C856.527039,684.433044 857.433167,684.392578 858.339355,684.352112 
	C858.734314,686.328186 859.129211,688.304260 859.263306,690.502930 
	C857.747620,690.793274 856.492737,690.861084 854.922302,690.856689 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M909.694946,699.156616 
	C910.321533,700.411438 910.643066,701.822876 910.982300,703.617126 
	C909.869019,703.927124 908.737915,703.854309 906.642761,703.719421 
	C907.794128,701.872742 908.592041,700.593018 909.694946,699.156616 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M814.856934,662.697205 
	C817.152649,664.186096 819.448303,665.674988 821.987183,667.366699 
	C822.395325,670.300354 822.560181,673.031250 822.619080,676.158386 
	C820.961243,677.728516 819.409363,678.902344 817.841797,680.436401 
	C816.059448,681.090149 814.292786,681.383728 812.136963,681.120544 
	C811.148621,678.691284 810.549438,676.818787 810.130432,674.663696 
	C812.068054,672.183044 813.825439,669.984924 815.636597,667.719604 
	C813.075989,667.046997 810.757263,666.437866 808.419556,665.645142 
	C808.400696,665.461548 808.388245,665.092651 808.388245,665.092651 
	C808.754639,664.878784 809.120972,664.664917 809.688110,664.010498 
	C811.544983,663.279053 813.200928,662.988098 814.856934,662.697205 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M813.033325,693.532104 
	C813.522156,695.095093 813.672729,696.447327 813.823303,697.799500 
	C811.854919,698.000916 809.840210,698.522522 807.940674,698.247925 
	C807.167542,698.136108 806.644409,696.294495 806.007568,695.239929 
	C807.441040,694.460754 808.821838,693.543457 810.332703,692.967163 
	C810.980835,692.719971 811.899353,693.181702 813.033325,693.532104 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M815.706055,703.080627 
	C813.110168,702.849304 810.931091,702.674561 808.751953,702.499878 
	C808.793884,702.239624 808.835754,701.979370 808.877686,701.719116 
	C812.628845,700.445251 816.380066,699.171448 820.686401,697.709106 
	C820.814575,699.908813 820.906738,701.490967 821.046021,703.882385 
	C819.109924,703.589355 817.616333,703.363281 815.706055,703.080627 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M840.969543,696.688843 
	C840.378601,697.499817 839.806213,698.337830 839.385986,698.267517 
	C837.300842,697.918213 835.255676,697.329407 833.196533,696.824951 
	C834.064758,695.567993 834.687683,693.603821 835.851807,693.199951 
	C838.345825,692.334900 840.986938,692.491028 840.969543,696.688843 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M940.194092,683.830261 
	C940.035339,683.998962 939.597595,683.913757 939.374878,683.912537 
	C939.152100,683.911316 938.923035,683.502686 938.933350,683.268738 
	C939.195129,683.049072 939.446594,683.063416 940.072998,683.142944 
	C940.447815,683.208069 940.352905,683.661560 940.194092,683.830261 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M737.717590,693.317139 
	C743.544983,693.039856 749.421997,693.114807 755.890686,693.221069 
	C755.940552,694.900940 755.398682,696.549622 754.554016,698.055847 
	C753.831116,697.761841 753.411072,697.610168 752.991028,697.458496 
	C751.487244,697.672302 749.983398,697.886108 747.883179,698.073853 
	C746.843872,699.032776 746.401001,700.017639 745.958130,701.002441 
	C745.678589,700.974731 745.431030,701.050964 744.752686,701.561890 
	C743.094177,702.125793 741.898376,702.358826 740.702576,702.591858 
	C740.594055,702.235046 740.485474,701.878235 740.376953,701.521423 
	C741.723816,700.342163 743.070618,699.162964 744.848938,697.605957 
	C738.834412,698.099670 738.079529,699.089905 738.348389,705.238281 
	C737.572815,703.351440 736.797180,701.464661 735.898682,699.190796 
	C736.439575,697.092346 737.103394,695.380859 737.717590,693.317139 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M738.612183,705.410522 
	C738.079529,699.089905 738.834412,698.099670 744.848938,697.605957 
	C743.070618,699.162964 741.723816,700.342163 740.376953,701.521423 
	C740.485474,701.878235 740.594055,702.235046 740.702576,702.591858 
	C741.898376,702.358826 743.094177,702.125793 744.640564,701.725708 
	C744.591125,705.389832 742.196106,706.189331 738.612183,705.410522 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M753.017944,697.857239 
	C753.411072,697.610168 753.831116,697.761841 754.679810,698.331970 
	C756.752930,698.192627 758.397339,697.634827 760.041748,697.076965 
	C760.041748,697.076965 760.038757,697.059387 760.048828,697.061523 
	C760.392334,697.132935 760.725647,697.202148 761.310669,697.307190 
	C761.847290,699.467712 762.132202,701.592468 762.724976,704.084961 
	C763.032776,704.452759 763.053772,704.910889 763.053772,704.910889 
	C763.053772,704.910889 762.932922,705.286438 762.894043,705.479126 
	C761.754883,705.719849 760.654663,705.767883 759.147217,705.833740 
	C759.447937,704.212097 759.684265,702.937805 759.973267,701.379395 
	C756.551392,702.186768 753.875427,702.031738 753.017944,697.857239 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M746.274902,700.936340 
	C746.401001,700.017639 746.843872,699.032776 747.539856,698.148682 
	C747.392578,699.122986 746.992126,699.996643 746.274902,700.936340 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M979.622803,653.902649 
	C978.325500,656.753845 976.680298,659.517334 975.009766,662.650879 
	C973.087891,662.839600 971.191284,662.658264 968.717285,662.707703 
	C967.563538,662.934692 966.987183,662.930969 965.938721,662.862915 
	C962.391907,662.599731 959.317261,662.400879 955.940308,662.169678 
	C955.107239,662.358398 954.576416,662.579468 954.045593,662.800537 
	C952.194824,662.996826 950.344116,663.193054 948.256714,663.704529 
	C947.296875,664.698547 946.573730,665.377380 945.555298,666.112000 
	C944.911011,664.291809 944.562012,662.415955 944.693481,660.275757 
	C946.771973,659.994873 948.369995,659.978210 950.317078,659.989197 
	C952.433289,660.023315 954.200500,660.029785 956.265381,660.302856 
	C959.741516,660.412354 962.919800,660.255249 966.445435,660.075439 
	C967.482483,660.081238 968.172241,660.109741 968.861938,660.138184 
	C970.760437,659.421082 972.813232,658.956482 974.514893,657.917114 
	C976.278748,656.839844 977.702393,655.205566 979.622803,653.902649 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M966.410828,662.927307 
	C966.987183,662.930969 967.563538,662.934692 968.421631,662.920654 
	C968.796204,664.635742 968.889099,666.368530 969.036499,668.528809 
	C968.477600,668.487854 967.864258,668.019409 967.086792,666.934326 
	C966.752014,665.187622 966.581421,664.057434 966.410828,662.927307 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M977.865051,666.370789 
	C979.381592,667.463013 980.776672,668.899780 982.079590,670.679077 
	C980.572815,669.586243 979.158203,668.150818 977.865051,666.370789 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M974.976562,663.309326 
	C975.286926,663.167542 975.593811,663.322083 975.939697,663.745117 
	C975.643494,663.877686 975.308289,663.741699 974.976562,663.309326 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M975.967163,664.322021 
	C976.294250,664.171326 976.602478,664.321106 976.943176,664.740967 
	C976.633179,664.881531 976.290771,664.752014 975.967163,664.322021 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M976.966064,665.327637 
	C977.295349,665.172974 977.601990,665.321594 977.940186,665.738892 
	C977.629028,665.882019 977.286194,665.756470 976.966064,665.327637 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M954.037720,663.259644 
	C954.576416,662.579468 955.107239,662.358398 955.861755,662.575562 
	C956.391357,665.346375 956.697327,667.678894 957.003296,670.011475 
	C956.528076,670.596558 956.052856,671.181580 955.317200,671.884277 
	C954.714417,669.240845 954.372131,666.479736 954.037720,663.259644 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M958.020386,681.981079 
	C958.272461,678.503235 954.924744,674.944092 959.215210,671.511047 
	C961.683655,669.536011 963.538635,667.731079 966.370422,669.955078 
	C969.311951,672.265320 972.920471,674.609131 969.503540,679.506897 
	C967.761597,679.506897 965.828247,679.506897 963.894836,679.506897 
	C963.837036,679.794495 963.779175,680.082092 963.721375,680.369690 
	C964.665100,680.863159 965.608765,681.356567 966.552429,681.850037 
	C966.436340,682.264587 966.320251,682.679077 966.204163,683.093628 
	C963.748840,682.678406 961.293457,682.263184 958.437866,681.903259 
	C958.037659,681.958557 958.000000,682.000000 958.020386,681.981079 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M957.306152,669.818542 
	C956.697327,667.678894 956.391357,665.346375 956.164062,662.607910 
	C959.317261,662.400879 962.391907,662.599731 965.938721,662.862915 
	C966.581421,664.057434 966.752014,665.187622 966.962646,666.667603 
	C963.871399,667.886841 960.740173,668.756226 957.306152,669.818542 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M685.552856,711.125000 
	C685.801208,709.745483 686.447876,707.117371 687.042419,707.129089 
	C689.120728,707.170166 692.371582,705.236877 692.981628,709.795227 
	C690.626221,710.515259 688.276672,710.801086 685.552856,711.125000 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M723.080444,689.305298 
	C724.184204,687.684143 725.265137,685.784180 726.809448,685.266785 
	C728.727905,684.623779 731.029480,685.124207 733.607910,685.240784 
	C733.897705,686.117188 733.745117,686.883606 733.175781,687.492920 
	C726.501587,686.820679 726.050232,687.259155 725.842834,694.054443 
	C725.496887,694.051697 725.151001,694.048889 724.546265,694.049561 
	C723.946045,692.581970 723.604553,691.110901 723.080444,689.305298 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M712.950684,700.894836 
	C713.562683,700.159058 714.174683,699.423218 714.918945,698.430969 
	C716.232117,698.607910 717.412903,699.041260 718.797546,699.737183 
	C718.965088,700.415588 718.928772,700.831421 718.619873,701.719238 
	C717.540161,703.094788 716.733093,703.998291 715.926025,704.901855 
	C715.292236,704.865173 714.658447,704.828552 713.718750,704.709290 
	C712.984802,704.259766 712.556702,703.892944 712.357483,703.071228 
	C712.707764,702.042542 712.829224,701.468689 712.950684,700.894836 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M715.993774,705.304321 
	C716.733093,703.998291 717.540161,703.094788 718.612915,702.032227 
	C719.449280,702.189636 720.105591,702.413086 720.571777,702.842896 
	C721.403198,703.609375 722.119873,704.500488 722.884521,705.339478 
	C721.882690,705.906738 720.887390,706.914856 719.877441,706.929626 
	C718.611755,706.948242 717.334045,706.151611 715.993774,705.304321 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M731.601807,689.884094 
	C732.863892,689.748962 734.125977,689.613892 735.646301,689.451111 
	C735.646301,691.579285 735.646301,693.571289 735.646301,696.455688 
	C733.105957,695.174011 731.190613,694.207703 729.230347,692.670410 
	C729.990845,691.360962 730.796326,690.622498 731.601807,689.884094 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M731.752563,689.620728 
	C730.796326,690.622498 729.990845,691.360962 729.052856,692.342102 
	C728.130188,693.101379 727.340027,693.617920 726.196289,694.094482 
	C726.050232,687.259155 726.501587,686.820679 732.950806,687.686401 
	C732.729614,688.477051 732.316467,688.917175 731.752563,689.620728 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M737.475769,688.705078 
	C737.940063,688.620361 738.436707,688.788635 738.933289,688.956848 
	C738.643616,689.541870 738.353943,690.126892 737.790039,690.595337 
	C737.513184,689.971802 737.510620,689.464905 737.475769,688.705078 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M763.338013,704.912231 
	C763.053772,704.910889 763.032776,704.452759 763.024963,704.223633 
	C763.257751,704.017029 763.498352,704.039612 764.099731,704.116699 
	C764.181152,704.418701 763.901672,704.666138 763.338013,704.912231 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M674.837524,727.675781 
	C673.919739,728.195679 673.175720,728.410400 672.087769,728.724426 
	C672.087769,726.567627 672.087769,724.576782 672.087769,721.971985 
	C674.314087,721.971985 676.372375,721.971985 678.907776,721.985840 
	C677.927063,723.789978 676.469238,725.580322 674.837524,727.675781 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M669.019897,743.546021 
	C668.037781,742.650574 667.391296,741.896667 665.722473,739.950378 
	C668.555298,741.180969 670.094299,741.849609 671.819214,742.825562 
	C671.121887,743.317749 670.238770,743.502625 669.019897,743.546021 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M959.515686,647.969910 
	C959.961365,646.926880 960.407104,645.883850 961.009155,644.475037 
	C959.688904,644.332031 958.508667,644.204163 957.339539,644.077515 
	C957.339539,643.545898 957.257019,642.975525 957.351929,642.944275 
	C958.785217,642.472473 960.295471,641.588501 961.682983,641.742737 
	C966.189270,642.243713 970.610107,643.707520 975.180542,641.816406 
	C975.995483,641.479187 977.944885,641.997925 978.206909,642.615295 
	C978.704529,643.787720 978.895264,645.730652 978.228943,646.592163 
	C977.422974,647.634216 975.641052,648.059021 974.199158,648.437195 
	C972.328979,648.927673 970.377747,649.108765 967.890259,649.702148 
	C966.861877,650.025269 966.404541,650.069275 965.572998,650.037781 
	C963.710449,649.830688 962.222168,649.699158 960.676758,649.210571 
	C960.251648,648.558960 959.883667,648.264465 959.515686,647.969910 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M959.202271,647.941162 
	C959.883667,648.264465 960.251648,648.558960 960.387634,649.389038 
	C957.155029,649.736694 955.573425,650.796814 955.991699,654.142029 
	C956.233459,656.075500 955.991699,658.069519 955.967651,660.036255 
	C954.200500,660.029785 952.433289,660.023315 950.270142,659.582397 
	C949.551086,655.147156 949.228027,651.146423 948.905029,647.145752 
	C950.980469,647.120605 953.055908,647.095520 955.576660,647.043823 
	C956.977600,647.315552 957.933289,647.613953 959.202271,647.941162 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M965.947144,650.113281 
	C966.404541,650.069275 966.861877,650.025269 967.664307,649.996094 
	C968.321838,653.085510 968.634338,656.160156 968.904419,659.686462 
	C968.172241,660.109741 967.482483,660.081238 966.420166,659.614136 
	C966.014099,656.154724 965.980652,653.134033 965.947144,650.113281 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M986.079102,651.629883 
	C984.936829,651.981567 983.869385,651.996643 982.398804,651.990601 
	C982.860657,648.538940 984.235657,648.245361 986.079102,651.629883 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M959.201416,631.820557 
	C959.671936,631.881836 960.270874,632.230774 960.854248,632.877563 
	C960.335815,632.819763 959.832825,632.464050 959.201416,631.820557 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M981.672668,651.973267 
	C981.824951,652.305420 981.679993,652.615417 981.263000,652.949646 
	C981.119141,652.632996 981.247314,652.292114 981.672668,651.973267 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M987.012573,652.687622 
	C986.693604,652.834839 986.377502,652.694458 986.047363,652.277832 
	C986.360718,652.134399 986.688049,652.267212 987.012573,652.687622 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M980.675293,652.974487 
	C980.828979,653.301086 980.684021,653.611267 980.267029,653.945679 
	C980.122192,653.632629 980.249390,653.295349 980.675293,652.974487 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M988.009583,653.683105 
	C987.692078,653.828918 987.377930,653.685486 987.044312,653.266174 
	C987.354187,653.124756 987.683594,653.259338 988.009583,653.683105 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M989.023865,654.668823 
	C988.683105,654.825439 988.368530,654.686401 988.040894,654.270203 
	C988.368591,654.119751 988.709351,654.246460 989.023865,654.668823 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M935.935791,670.032776 
	C934.985168,669.222229 931.899231,670.714417 933.378418,667.229187 
	C933.850159,666.395020 934.133423,665.818420 934.671204,664.599609 
	C937.126465,662.941833 938.977478,662.620605 938.833069,666.393372 
	C938.896973,667.863403 939.095764,668.933533 938.893250,670.215332 
	C937.639893,670.295593 936.787842,670.164185 935.935791,670.032776 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M938.967957,665.993408 
	C938.977478,662.620605 937.126465,662.941833 934.609741,664.275146 
	C935.253723,663.808594 936.177551,662.973694 937.184509,662.255310 
	C938.073425,661.621094 939.055054,661.116943 940.047546,660.177002 
	C940.527344,660.101013 940.955383,660.403381 941.785339,660.763062 
	C942.150635,661.227966 942.114014,661.635498 941.667114,662.445740 
	C940.911438,663.914978 940.566040,664.981567 940.012085,666.049866 
	C939.524780,666.035217 939.246216,666.015869 938.967957,665.993408 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M948.557251,647.144287 
	C949.228027,651.146423 949.551086,655.147156 949.921021,659.554688 
	C948.369995,659.978210 946.771973,659.994873 944.802673,659.865417 
	C944.463074,655.933777 944.494751,652.148193 944.695312,647.946411 
	C945.979187,647.401062 947.094360,647.271912 948.557251,647.144287 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M935.950684,670.025452 
	C936.787842,670.164185 937.639893,670.295593 939.040039,670.102905 
	C939.892029,669.577759 940.195923,669.376770 940.885620,669.193909 
	C941.525574,669.171509 941.779846,669.130859 942.034058,669.090210 
	C943.259460,672.536499 943.449951,675.601501 940.014832,678.188660 
	C939.119141,678.863220 939.282898,680.944458 938.958008,682.720337 
	C937.888062,682.805176 936.820190,682.549316 935.474304,681.847656 
	C935.800354,679.648621 936.404419,677.895386 937.063477,675.877869 
	C937.406372,675.406921 937.694336,675.200195 938.253906,675.083618 
	C938.788391,674.164612 939.051086,673.155518 939.276184,671.772217 
	C938.158325,670.935791 937.078064,670.473633 935.997803,670.011414 
	C935.997803,670.011414 935.965515,670.018127 935.950684,670.025452 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M942.083252,668.622498 
	C941.779846,669.130859 941.525574,669.171509 940.984985,668.796997 
	C940.539246,667.603943 940.379883,666.826050 940.220581,666.048157 
	C940.566040,664.981567 940.911438,663.914978 941.650269,662.652588 
	C942.073181,664.356140 942.102783,666.255493 942.083252,668.622498 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M581.661072,818.147339 
	C582.047852,817.743591 582.186279,817.276123 582.530212,816.474426 
	C583.994507,815.096558 585.253357,814.052979 585.722717,813.663940 
	C585.722717,808.616394 585.722717,804.314392 585.722717,799.665405 
	C584.551697,798.370605 582.920959,796.567566 581.492310,794.653198 
	C582.123291,794.374023 582.552246,794.206177 583.289307,794.202271 
	C585.255798,795.781677 586.914185,797.197083 588.667786,798.693665 
	C589.759033,798.210693 590.927734,797.693420 592.399963,797.145752 
	C592.842285,796.771545 592.981140,796.427734 593.180542,796.090820 
	C593.241028,796.097778 593.361023,796.119507 593.361023,796.119507 
	C593.709717,796.113342 594.058472,796.107178 594.668579,796.068970 
	C595.989258,796.713074 597.048523,797.389221 598.409790,798.163696 
	C599.506042,799.252197 600.300354,800.242371 600.759644,801.689087 
	C594.746399,799.893372 592.794556,800.441406 590.590576,804.609619 
	C590.075562,804.213379 589.135010,803.232361 588.920288,803.371460 
	C588.187195,803.846619 587.136047,804.822937 587.246582,805.413452 
	C587.527649,806.916321 588.395142,808.304321 588.984497,809.755981 
	C589.640869,811.372620 590.249268,813.008789 590.878540,814.636414 
	C586.772644,813.175171 584.152100,815.792419 581.661072,818.147339 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M591.066223,814.875732 
	C590.249268,813.008789 589.640869,811.372620 588.984497,809.755981 
	C588.395142,808.304321 587.527649,806.916321 587.246582,805.413452 
	C587.136047,804.822937 588.187195,803.846619 588.920288,803.371460 
	C589.135010,803.232361 590.075562,804.213379 590.590576,804.609619 
	C592.794556,800.441406 594.746399,799.893372 600.788391,802.051453 
	C601.152100,801.957275 601.081360,802.002197 601.020081,802.401978 
	C602.842651,807.805603 597.604675,810.437744 597.022949,814.620483 
	C595.099915,814.785339 593.176941,814.950195 591.066223,814.875732 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M597.439819,814.551270 
	C597.604675,810.437744 602.842651,807.805603 600.996338,802.367432 
	C601.469727,802.257996 601.905640,802.582886 602.563110,803.082336 
	C602.816162,803.646973 602.847595,804.037109 602.625671,804.999207 
	C602.515320,806.565674 602.658264,807.560059 602.801208,808.554504 
	C602.576477,809.776062 602.351685,810.997559 602.224121,812.584534 
	C601.986633,813.060425 601.651917,813.170837 601.185913,813.517517 
	C599.988586,813.996521 598.922607,814.239258 597.439819,814.551270 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M582.918762,837.640381 
	C582.607666,837.818787 582.334961,837.667175 581.985535,837.200684 
	C582.192749,837.145325 582.542236,837.286804 582.918762,837.640381 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M603.015625,808.353882 
	C602.658264,807.560059 602.515320,806.565674 602.648376,805.292725 
	C603.026306,806.060547 603.128174,807.106873 603.015625,808.353882 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M600.033630,859.244507 
	C600.259521,859.445129 600.278687,859.652893 600.236694,859.847473 
	C600.229736,859.879578 599.954651,859.853821 599.803467,859.854797 
	C599.804504,859.652283 599.805542,859.449707 600.033630,859.244507 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M606.403442,792.963196 
	C605.707275,793.579529 604.701050,794.213379 603.363159,794.797729 
	C604.052124,794.159058 605.072754,793.569885 606.403442,792.963196 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M601.487610,796.026855 
	C601.389404,796.597534 601.005249,797.227112 600.299988,797.866821 
	C600.386475,797.279907 600.794067,796.682861 601.487610,796.026855 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M599.776672,733.246948 
	C600.185547,733.007141 600.363403,733.007385 600.807617,733.023010 
	C598.935303,735.672363 596.796570,738.306274 594.320068,740.991028 
	C595.836670,738.523499 597.691101,736.005249 599.776672,733.246948 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M592.165710,765.761780 
	C591.737183,767.791748 591.308655,769.821777 590.852905,771.980469 
	C591.179810,772.465271 592.817383,773.818481 592.637451,774.856812 
	C592.293152,776.843994 595.061401,780.120972 589.967163,780.720947 
	C586.552490,780.723999 583.888977,780.807190 581.200439,780.565430 
	C581.029541,779.833862 580.883667,779.427246 581.075562,778.895752 
	C581.623169,775.241638 581.832886,771.712402 582.371033,768.155518 
	C583.135498,768.074158 583.571533,768.020630 584.179382,768.167236 
	C585.026001,768.019043 585.700928,767.670715 586.747498,767.290894 
	C588.801331,766.760132 590.483521,766.260986 592.165710,765.761780 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M592.096436,797.176147 
	C590.927734,797.693420 589.759033,798.210693 588.667786,798.693665 
	C586.914185,797.197083 585.255798,795.781677 583.246704,793.740540 
	C582.895996,789.878723 582.895996,786.642639 582.895996,783.197632 
	C585.874390,783.334717 588.138000,783.438904 590.401550,783.543091 
	C595.245178,786.541870 591.935852,791.392090 593.208984,795.677856 
	C593.361023,796.119507 593.241028,796.097778 592.942932,796.202576 
	C592.462036,796.596985 592.279236,796.886597 592.096436,797.176147 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M592.141357,765.522705 
	C590.483521,766.260986 588.801331,766.760132 586.414673,767.203308 
	C585.142761,767.420532 584.575195,767.693848 584.007629,767.967163 
	C583.571533,768.020630 583.135498,768.074158 582.337585,767.812866 
	C581.910767,765.446655 582.001587,763.375000 581.738464,761.349365 
	C581.456787,759.182129 580.841614,757.058289 580.372070,754.915466 
	C580.299500,753.888916 580.227051,752.862305 580.508545,751.380127 
	C581.241943,750.299683 581.621399,749.674866 582.000854,749.050049 
	C582.323669,748.237915 582.646423,747.425720 583.008179,746.284668 
	C583.608582,750.205505 584.265503,754.445679 584.692200,758.708984 
	C584.914612,760.931274 584.731384,763.194092 584.731384,765.912537 
	C587.281006,765.508911 589.174805,765.209106 591.459778,764.896301 
	C591.850952,764.883301 592.117004,765.283569 592.141357,765.522705 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M590.334717,783.212891 
	C588.138000,783.438904 585.874390,783.334717 582.895996,783.197632 
	C582.895996,786.642639 582.895996,789.878723 582.938599,793.576538 
	C582.552246,794.206177 582.123291,794.374023 581.187378,794.721313 
	C580.472900,794.888062 580.265381,794.875488 580.057861,794.862915 
	C580.071289,792.870422 580.084656,790.877930 580.304443,788.271667 
	C580.060242,786.992859 579.609680,786.327759 579.159058,785.662720 
	C579.262573,785.291199 579.366150,784.919739 579.577209,783.829468 
	C580.198303,782.370605 580.711853,781.630432 581.225464,780.890381 
	C583.888977,780.807190 586.552490,780.723999 589.556580,780.769714 
	C590.020752,781.559998 590.144287,782.221313 590.334717,783.212891 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M498.598083,668.118347 
	C498.786224,669.013000 498.630035,669.855713 498.212067,670.903564 
	C495.534607,671.108826 493.118866,671.108826 490.790924,671.108826 
	C491.534302,673.033875 492.086243,674.463196 492.638184,675.892456 
	C490.633759,676.643860 488.629333,677.395142 486.221802,678.320312 
	C485.818726,678.494202 485.502777,678.403381 485.546234,677.930786 
	C485.552460,677.229126 485.515228,677.000000 485.477966,676.770813 
	C485.403442,675.309814 485.328949,673.848816 485.012878,671.838745 
	C484.363556,670.865723 483.955780,670.441772 483.438660,669.692566 
	C482.883789,668.895020 482.438263,668.422668 481.988708,667.980103 
	C481.984650,668.009949 482.044220,668.018433 482.067261,667.764648 
	C482.056671,667.344299 482.023102,667.177734 481.967194,666.658569 
	C480.613037,664.247009 479.214478,662.227539 477.968170,660.118042 
	C476.727051,658.017273 476.893280,656.621033 479.874634,657.090576 
	C481.055756,655.461243 482.145996,652.534180 483.434875,652.443970 
	C487.432007,652.164185 491.530579,652.810364 495.522522,653.491455 
	C496.189972,653.605347 496.521790,655.686340 497.152039,657.205078 
	C497.497375,658.221008 497.699371,658.890259 498.044006,659.886780 
	C494.916016,662.859680 494.548248,665.480408 498.598083,668.118347 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M479.878113,656.711792 
	C476.893280,656.621033 476.727051,658.017273 477.968170,660.118042 
	C479.214478,662.227539 480.613037,664.247009 481.583282,666.636841 
	C480.965790,666.983521 480.709930,666.999268 480.124695,666.934204 
	C478.414948,665.585571 477.199432,663.508240 475.626099,663.187988 
	C468.204071,661.676697 465.848816,662.975769 462.285309,669.493958 
	C461.975494,670.060669 461.497986,670.535645 460.966370,670.804321 
	C460.535889,670.339783 460.237457,670.124146 459.939026,669.908508 
	C460.761261,667.460144 461.583466,665.011719 462.655762,661.818665 
	C458.830261,661.818665 455.328186,661.818665 451.478760,661.849365 
	C451.380157,661.472107 451.628967,661.064087 452.383362,660.330872 
	C460.025482,659.497803 467.162018,658.989929 474.591431,658.461243 
	C474.870972,657.060120 475.186646,655.477844 475.502319,653.895569 
	C476.398315,653.277954 477.294342,652.660400 478.897369,651.555542 
	C479.285370,653.438904 479.583496,654.885986 479.878113,656.711792 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M418.450989,654.947876 
	C418.302490,654.552673 418.266052,654.222107 418.250427,653.890625 
	C417.903564,646.526062 417.953186,646.482056 425.883240,647.592102 
	C426.885925,650.533142 427.635895,653.160400 428.782715,657.177979 
	C425.212769,656.676453 423.013336,656.367493 421.027222,655.554626 
	C422.580536,653.449036 423.920471,651.847229 425.482605,650.067505 
	C425.692566,649.626221 425.680298,649.362854 425.668030,649.099487 
	C425.310364,649.257751 424.952698,649.416016 424.304962,649.720398 
	C422.565399,651.423889 421.115906,652.981262 419.666443,654.538635 
	C419.301880,654.696472 418.937317,654.854309 418.450989,654.947876 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M475.322937,653.455444 
	C475.186646,655.477844 474.870972,657.060120 474.591431,658.461243 
	C467.162018,658.989929 460.025482,659.497803 452.483307,660.108643 
	C451.724884,658.085083 451.372131,655.958557 451.010498,653.434448 
	C452.149445,653.119385 454.212830,652.960022 454.308105,653.320679 
	C455.782532,658.901672 460.308899,656.371216 463.328461,656.370483 
	C466.181824,656.369812 470.557465,658.651001 471.892609,653.325562 
	C471.981079,652.972656 474.014679,653.107422 475.322937,653.455444 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M503.038483,651.972473 
	C501.898895,652.408997 500.759277,652.845459 499.234344,653.429565 
	C499.234344,651.187927 499.234344,649.006104 499.234344,645.239258 
	C501.613708,646.435730 503.335938,647.301819 505.028351,648.582520 
	C504.998535,648.997131 505.022888,648.969727 504.739624,649.007629 
	C504.292877,649.371948 504.129425,649.698303 503.979980,650.010254 
	C503.994049,649.995728 504.016235,649.961975 503.690582,650.088562 
	C503.238037,650.821960 503.111115,651.428711 502.995850,652.018127 
	C503.007507,652.000732 503.038483,651.972473 503.038483,651.972473 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M462.957153,650.986633 
	C463.681305,652.112976 464.405457,653.239380 465.305054,654.638611 
	C462.617310,654.638611 460.099976,654.638611 457.265961,654.638611 
	C457.165375,652.894409 457.073853,651.307190 456.958801,649.312012 
	C459.018463,650.092407 460.664703,650.716248 462.660278,651.162842 
	C463.009674,650.985718 462.957184,650.986633 462.957153,650.986633 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M504.984070,649.008423 
	C508.928833,648.624695 508.515656,651.994507 507.659851,653.439697 
	C506.695648,655.068115 504.115692,655.739746 502.238495,656.827515 
	C501.937439,656.392822 501.636383,655.958130 501.335327,655.523438 
	C501.859161,654.570068 502.382996,653.616699 502.972656,652.317871 
	C503.038483,651.972473 503.007507,652.000732 503.331909,651.916626 
	C503.776306,651.208984 503.896301,650.585449 504.016235,649.961975 
	C504.016235,649.961975 503.994049,649.995728 504.319550,650.064209 
	C504.770996,649.745056 504.896942,649.357361 505.022888,648.969727 
	C505.022888,648.969727 504.998535,648.997131 504.984070,649.008423 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M463.017548,651.010742 
	C462.452576,650.252686 461.879730,649.469604 461.307312,648.686157 
	C468.092926,646.430664 469.794373,647.427856 469.402191,653.964844 
	C467.373444,652.910034 465.523834,651.948303 463.315674,650.986633 
	C462.957184,650.986633 463.009674,650.985718 463.017548,651.010742 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M399.995483,563.286926 
	C400.739227,562.593262 401.486176,562.371704 402.593231,562.315063 
	C406.602783,566.048035 401.296387,565.499817 400.468689,567.358948 
	C400.090515,566.289734 400.044586,565.024414 399.995483,563.286926 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M458.020386,608.262146 
	C455.358063,608.226685 452.695740,608.191223 449.603271,608.052856 
	C448.067383,607.012085 446.961609,606.074280 445.883179,605.159729 
	C446.336487,603.648193 446.751373,602.264771 447.482605,600.412842 
	C448.201355,599.515625 448.603729,599.086914 449.006104,598.658142 
	C450.314331,598.617188 451.622589,598.576233 453.309143,598.662781 
	C455.037628,600.340210 456.387817,601.890137 457.866058,604.156128 
	C458.002869,606.002136 458.011627,607.132141 458.020386,608.262146 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M448.732178,598.532593 
	C448.603729,599.086914 448.201355,599.515625 447.561005,599.975342 
	C447.701416,599.473206 448.079865,598.940186 448.732178,598.532593 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M412.011200,600.991577 
	C412.501282,601.247070 412.976898,601.514709 413.722809,601.887329 
	C413.993134,601.992249 414.022308,602.010376 414.022308,602.010376 
	C413.357758,601.877930 412.693237,601.745483 411.999237,601.321777 
	C411.969788,601.030579 411.996735,601.003662 412.011200,600.991577 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M413.980530,601.979858 
	C414.025757,601.325928 414.083588,600.684326 414.141418,600.042725 
	C414.286316,600.046143 414.431244,600.049500 414.576141,600.052917 
	C414.540222,600.586304 414.504303,601.119629 414.245361,601.831665 
	C414.022308,602.010376 413.993134,601.992249 413.980530,601.979858 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M407.542603,602.541260 
	C407.487335,602.575134 407.597839,602.507385 407.542603,602.541260 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M438.658325,583.818298 
	C438.047668,585.331787 437.045685,586.798645 435.986206,588.591064 
	C435.264771,587.732056 434.600800,586.547424 433.894348,584.943176 
	C435.323547,584.272888 436.795288,584.022278 438.658325,583.818298 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M453.525940,671.936035 
	C452.253174,671.348267 451.281494,670.530518 450.187866,669.404175 
	C450.705170,668.621521 451.344391,668.147339 451.963928,667.341919 
	C452.174255,666.967896 452.404236,666.925232 452.974457,666.790405 
	C453.485474,668.367432 453.656219,670.036743 453.525940,671.936035 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M443.000488,667.035217 
	C443.215698,667.919189 443.067535,668.767151 442.663147,671.081848 
	C442.170105,668.971741 442.018250,668.321838 441.887787,667.306396 
	C442.151825,666.960388 442.394470,666.979797 443.000488,667.035217 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M486.001709,681.327148 
	C490.678986,680.944031 495.359711,680.794861 500.034729,680.899658 
	C501.316650,680.928406 502.581543,681.717590 503.906006,682.563232 
	C502.229675,684.107117 500.501587,685.245605 498.594177,686.502258 
	C497.889374,685.414062 497.200531,684.350586 496.240662,682.868652 
	C495.158081,684.442993 494.362976,685.599304 493.326111,687.107178 
	C492.672302,682.259644 489.532440,682.879272 486.182922,683.277100 
	C486.006744,682.535339 486.004852,682.096008 486.001709,681.327148 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M526.326050,688.077026 
	C524.258606,686.678650 522.515625,685.301270 520.081604,683.377869 
	C520.580750,682.655457 521.472717,681.364502 522.963074,680.123779 
	C524.061218,680.433472 524.560974,680.692993 525.061218,681.315186 
	C525.061707,682.522278 525.061707,683.366821 525.061707,684.211365 
	C525.302307,684.217163 525.542908,684.222961 525.783508,684.228760 
	C525.853943,683.151062 525.924438,682.073303 526.297119,681.179382 
	C526.828979,682.526184 527.058655,683.689148 527.245911,685.086670 
	C527.453064,685.200134 527.702698,685.079163 528.012573,684.778442 
	C528.072815,684.598694 528.203491,684.242859 528.203491,684.242859 
	C528.755371,685.257385 529.307312,686.271851 529.875977,687.587158 
	C529.892822,687.888000 529.908447,687.908875 529.465576,688.101013 
	C528.787781,688.505188 528.552917,688.717163 528.317993,688.929077 
	C527.762146,688.652039 527.206299,688.375000 526.326050,688.077026 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M507.839294,683.486450 
	C509.345490,680.826416 510.943512,679.550964 513.191528,682.789001 
	C512.478455,684.650635 511.509766,686.331787 510.541077,688.013000 
	C509.641663,686.642029 508.742218,685.271118 507.839294,683.486450 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M528.210083,689.134766 
	C528.552917,688.717163 528.787781,688.505188 529.455811,688.099487 
	C529.583618,688.838318 529.278320,689.770813 528.973022,690.703247 
	C528.682739,690.248962 528.392456,689.794678 528.210083,689.134766 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M627.094543,705.493408 
	C618.697998,705.906372 610.320801,705.959778 601.475708,706.003052 
	C601.353943,705.202515 601.700134,704.412231 602.467468,703.243042 
	C606.768860,702.679382 610.695435,702.025635 614.519897,702.403992 
	C623.428284,703.285156 624.715637,702.886047 625.972656,697.053162 
	C625.972656,697.053162 626.441650,696.936218 626.828430,697.163208 
	C627.742920,697.577393 628.270691,697.764526 628.798401,697.951599 
	C628.932739,698.633484 629.067017,699.315430 629.310974,700.325439 
	C628.651672,702.146973 627.882751,703.640442 627.094543,705.493408 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M625.651855,696.827698 
	C624.715637,702.886047 623.428284,703.285156 614.519897,702.403992 
	C610.695435,702.025635 606.768860,702.679382 602.483521,702.916382 
	C601.509033,700.863037 600.782288,698.779663 600.480103,696.636353 
	C600.403687,696.094299 601.603638,695.023010 602.368164,694.845459 
	C602.811035,694.742676 604.055054,695.916809 604.080505,696.550781 
	C604.221313,700.055115 606.619934,699.871521 609.002380,699.852722 
	C611.484863,699.833130 614.034363,700.166260 616.427368,699.696777 
	C618.126953,699.363281 619.536133,697.750427 621.224487,697.092468 
	C622.468079,696.607666 623.953369,696.743164 625.651855,696.827698 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M584.144775,703.880981 
	C583.991211,702.286926 584.134399,700.908020 584.526855,699.298950 
	C588.776062,697.841736 587.722778,702.587891 590.387329,703.921509 
	C591.502075,704.904541 592.184570,705.416260 593.027283,706.218201 
	C593.776672,706.027039 594.365906,705.545654 595.157593,704.934204 
	C595.448181,704.471863 595.536377,704.139648 595.624634,703.807495 
	C595.521484,703.780212 595.418274,703.752930 595.315125,703.725647 
	C595.193054,704.166199 595.071045,704.606812 594.692078,705.148743 
	C593.928589,705.462341 593.422058,705.674622 592.929688,705.499390 
	C592.675476,701.149231 595.673157,701.353149 598.217041,700.978271 
	C600.633911,704.900269 595.943726,706.600586 595.511780,709.632019 
	C593.093750,709.027588 590.538025,708.726624 588.305481,707.713562 
	C586.783630,707.022949 585.713501,705.336975 584.144775,703.880981 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M592.867065,705.927979 
	C592.184570,705.416260 591.502075,704.904541 590.652466,704.087036 
	C591.055603,701.108704 591.625916,698.436218 592.196167,695.763794 
	C592.764343,695.485962 593.332458,695.208130 593.900635,694.930298 
	C595.342651,696.681213 596.784668,698.432129 598.221863,700.580627 
	C595.673157,701.353149 592.675476,701.149231 592.942444,705.539917 
	C592.940918,705.968018 592.867065,705.927979 592.867065,705.927979 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M613.506653,691.080933 
	C615.383179,691.087891 616.857056,691.087891 618.883606,691.087891 
	C618.971802,693.065186 619.045410,694.715088 619.157532,697.229614 
	C615.678589,695.331848 613.001343,693.871399 609.016418,691.697632 
	C611.387512,691.335815 612.245789,691.204895 613.506653,691.080933 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M613.761963,697.910339 
	C611.450745,697.924561 609.494141,697.924561 607.140076,697.924561 
	C607.140076,696.257019 607.140076,694.819824 607.140076,692.826233 
	C609.991394,694.397766 612.388916,695.715454 614.775879,697.052063 
	C614.851318,697.094238 614.884949,697.409302 614.822693,697.462585 
	C614.615662,697.640198 614.356018,697.756348 613.761963,697.910339 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M585.008301,689.275146 
	C583.902222,689.074524 583.191162,688.828491 582.480164,688.582458 
	C582.550232,688.270996 582.620300,687.959534 582.690369,687.648071 
	C584.002563,687.954468 585.314758,688.260864 586.626953,688.567200 
	C586.219055,688.788025 585.811218,689.008911 585.008301,689.275146 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M628.828491,697.647339 
	C628.270691,697.764526 627.742920,697.577393 627.068848,697.138916 
	C627.567810,697.039368 628.213196,697.191223 628.828491,697.647339 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M575.776123,719.787720 
	C575.990540,719.135254 576.483459,718.723328 576.976379,718.311401 
	C577.014954,718.851624 577.053528,719.391785 576.877930,720.186829 
	C576.460693,720.303894 576.257690,720.166077 575.776123,719.787720 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M585.426270,708.532104 
	C585.489990,708.481445 585.362488,708.582703 585.426270,708.532104 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M548.544189,710.493896 
	C548.584534,710.560364 548.503784,710.427490 548.544189,710.493896 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M461.098328,671.053162 
	C461.497986,670.535645 461.975494,670.060669 462.285309,669.493958 
	C465.848816,662.975769 468.204071,661.676697 475.626099,663.187988 
	C477.199432,663.508240 478.414948,665.585571 480.308777,667.230713 
	C481.229523,667.744873 481.636871,667.881653 482.044220,668.018433 
	C482.044220,668.018433 481.984650,668.009949 481.865784,668.392212 
	C482.347321,669.188904 482.947662,669.603333 483.548004,670.017761 
	C483.955780,670.441772 484.363556,670.865723 484.868042,671.481140 
	C483.053619,672.480408 481.179199,673.859009 479.224091,673.985046 
	C474.145691,674.312256 469.029480,674.170593 463.933716,674.026672 
	C462.686615,673.991455 461.459991,673.229370 460.118530,672.388672 
	C460.013123,671.975891 460.007446,672.022888 460.252075,671.985718 
	C460.697266,671.650024 460.897797,671.351624 461.098328,671.053162 
M474.699341,667.499146 
	C472.520325,667.120605 470.341309,666.742126 467.927948,666.322876 
	C467.265991,667.691406 466.498138,669.278870 465.778687,670.766235 
	C467.963654,671.134460 469.773743,671.752075 471.542908,671.651306 
	C473.378174,671.546936 475.173859,670.746826 476.986847,670.250977 
	C476.419159,669.206665 475.851471,668.162415 474.699341,667.499146 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M466.026672,681.244385 
	C463.758789,679.860291 461.790039,678.341553 459.821259,676.822815 
	C460.020660,676.388550 460.220032,675.954285 460.419434,675.520020 
	C467.903778,675.520020 475.388153,675.520020 482.969696,675.520020 
	C481.088562,681.719910 481.190460,681.214417 475.629181,678.802917 
	C472.675323,677.522095 468.709106,678.575989 465.061218,678.575989 
	C465.581573,679.618652 465.953705,680.364197 466.026672,681.244385 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M465.998657,682.754822 
	C470.539856,685.875122 474.543396,684.512695 478.603394,681.511475 
	C478.868683,682.963135 479.277740,683.746155 479.040527,684.139709 
	C477.488312,686.715332 466.233307,687.274841 464.661530,684.825928 
	C464.437347,684.476685 465.256958,683.457397 465.998657,682.754822 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M485.349548,676.914551 
	C485.515228,677.000000 485.552460,677.229126 485.468750,677.785828 
	C485.264587,677.881897 485.157928,677.655151 485.108978,677.416626 
	C485.086945,677.309387 485.180145,677.178406 485.349548,676.914551 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M460.966339,670.804321 
	C460.897797,671.351624 460.697266,671.650024 460.228149,671.984375 
	C459.897705,671.553955 459.835815,671.087769 459.856445,670.265015 
	C460.237457,670.124146 460.535889,670.339783 460.966339,670.804321 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M528.356445,683.913757 
	C528.203491,684.242859 528.072815,684.598694 527.850220,684.729980 
	C527.627563,684.861206 527.288330,684.852173 527.288330,684.852173 
	C527.058655,683.689148 526.828979,682.526184 526.065308,681.130127 
	C525.531372,680.897156 525.060730,680.952576 525.060730,680.952576 
	C524.560974,680.692993 524.061218,680.433472 523.315247,680.033691 
	C523.496094,678.138672 523.923096,676.383850 524.253540,675.026001 
	C527.491028,675.477966 530.506592,675.898926 533.937439,676.377869 
	C534.139526,674.220581 534.355103,671.919189 534.680481,668.445923 
	C532.356750,670.095154 531.135437,670.961975 529.647034,672.018311 
	C528.672607,670.687073 527.823792,669.527527 526.216248,667.331421 
	C530.493469,664.490112 534.509338,661.822388 539.509583,658.500732 
	C540.022095,666.597046 540.416443,672.827515 540.861450,679.460327 
	C540.158142,680.171326 539.404114,680.479919 538.386780,680.950317 
	C534.918762,681.936340 531.714111,682.760498 528.356445,683.913757 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M544.024170,681.983521 
	C544.377502,682.583740 544.689880,683.216858 545.002258,683.850037 
	C544.778992,683.944946 544.555664,684.039917 544.332397,684.134888 
	C544.174377,683.642456 544.016418,683.149963 543.880737,682.371094 
	C543.903076,682.084717 543.983154,682.016479 544.024170,681.983521 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M540.948120,682.176636 
	C541.162781,682.358276 541.189270,682.547485 541.146606,682.719543 
	C541.137146,682.757629 540.883728,682.735291 540.742676,682.740723 
	C540.740112,682.553101 540.737610,682.365601 540.948120,682.176636 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M266.489746,768.950073 
	C265.333374,769.936401 264.177032,770.922791 262.722534,772.049316 
	C264.912231,764.593323 265.125092,764.489502 277.057373,765.398682 
	C277.612976,767.028381 278.022156,768.529053 277.880096,769.975464 
	C277.606201,772.764587 277.558807,777.639587 276.468597,777.916809 
	C272.404816,778.949768 267.764038,780.344360 263.422241,777.012817 
	C264.157867,776.335388 265.284851,775.630859 267.031799,774.713867 
	C269.577484,772.203735 271.503174,769.906128 273.428864,767.608459 
	C273.181580,767.199463 272.934265,766.790466 272.686981,766.381470 
	C270.621246,767.237671 268.555511,768.093872 266.489746,768.950073 
M274.122833,770.187500 
	C272.526031,772.041992 270.929230,773.896484 269.332428,775.750977 
	C269.632538,776.151062 269.932617,776.551086 270.232697,776.951172 
	C271.919098,776.702881 274.853058,776.819458 275.052917,776.136292 
	C275.605164,774.248413 275.015472,772.026489 274.122833,770.187500 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M251.841888,772.991821 
	C251.424072,773.404724 251.062973,773.398254 250.435608,773.336914 
	C249.128677,770.854553 248.088013,768.427063 246.931152,765.728516 
	C247.962097,765.803650 249.152237,765.890381 250.676941,765.982727 
	C251.307205,768.183105 251.602921,770.377808 251.841888,772.991821 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M241.753113,765.106750 
	C241.918777,765.412476 241.811279,765.688110 241.703796,765.963684 
	C241.352875,765.790222 241.001953,765.616760 240.651001,765.443298 
	C240.927322,765.321045 241.203659,765.198792 241.753113,765.106750 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M221.084930,767.690552 
	C220.904022,768.157288 220.771576,768.294006 220.371658,768.528442 
	C218.038406,767.565796 215.972656,766.505554 213.906891,765.445251 
	C214.107971,765.074829 214.309067,764.704346 214.510147,764.333923 
	C215.728683,764.808350 216.947220,765.282837 219.130463,766.132935 
	C219.568420,762.874329 219.975067,759.848694 220.415878,756.568848 
	C220.777893,756.851074 221.321426,757.274780 222.027924,758.240845 
	C222.184479,759.179138 222.178070,759.575073 222.081100,760.325317 
	C222.372040,761.792908 222.753525,762.906311 223.135010,764.019653 
	C222.467789,765.133301 221.800598,766.246948 221.084930,767.690552 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M212.792557,762.552490 
	C211.822708,760.809509 211.110657,758.801331 210.398605,756.793213 
	C210.875656,756.515198 211.352707,756.237183 211.829758,755.959167 
	C212.968872,756.571167 215.140305,757.383057 215.067276,757.760437 
	C214.761475,759.340332 213.782623,760.789978 212.792557,762.552490 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M222.171661,759.971069 
	C222.178070,759.575073 222.184479,759.179138 222.201736,758.485901 
	C222.731583,757.703430 223.250580,757.218140 223.796326,756.450806 
	C225.119370,756.450317 226.415649,756.731934 227.711945,757.013611 
	C226.913116,757.818054 226.114288,758.622437 224.775742,759.713745 
	C223.547897,759.990845 222.859772,759.980957 222.171661,759.971069 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M208.462219,767.074341 
	C208.177750,766.878723 208.158524,766.429382 208.071335,766.222168 
	C211.583939,763.065979 212.069778,765.980835 212.863983,768.909973 
	C211.571991,768.608459 210.159332,767.939148 208.462219,767.074341 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M222.081100,760.325317 
	C222.859772,759.980957 223.547897,759.990845 224.578201,760.014404 
	C224.947845,760.767944 224.975296,761.507812 225.004669,762.618286 
	C225.006592,762.988831 224.972000,763.441772 224.974579,763.710022 
	C224.977173,763.978271 224.894669,763.950684 224.894669,763.950684 
	C224.503433,763.966248 224.112213,763.981750 223.427994,764.008484 
	C222.753525,762.906311 222.372040,761.792908 222.081100,760.325317 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M224.963730,763.936890 
	C225.095520,764.071167 225.240753,764.246826 225.385971,764.422424 
	C225.276489,764.370544 225.167007,764.318665 224.976105,764.108765 
	C224.894669,763.950684 224.977173,763.978271 224.963730,763.936890 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M266.498230,769.329834 
	C268.555511,768.093872 270.621246,767.237671 272.686981,766.381470 
	C272.934265,766.790466 273.181580,767.199463 273.428864,767.608459 
	C271.503174,769.906128 269.577484,772.203735 267.422211,774.722534 
	C266.963959,773.198914 266.735321,771.454285 266.498230,769.329834 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M528.046509,815.988464 
	C529.442200,816.221375 530.837891,816.454346 532.910095,816.800171 
	C532.641968,818.137085 532.234863,820.166931 531.827759,822.196777 
	C531.687500,822.121887 531.521606,822.072632 531.413940,821.965088 
	C531.300903,821.852112 531.243591,821.683350 531.024048,821.011475 
	C530.501526,820.620239 530.116943,820.756470 529.732361,820.892700 
	C527.632202,821.635010 525.982361,823.544922 523.207825,821.151917 
	C522.117493,820.211426 518.540955,822.415833 516.037598,822.766418 
	C514.114502,823.035767 512.099670,822.614136 510.124054,822.561279 
	C509.271057,822.538452 508.385834,822.557922 507.566071,822.762207 
	C506.628174,822.995850 505.340729,824.032166 504.906097,823.725708 
	C501.829041,821.556030 498.954346,823.604431 495.508972,823.437134 
	C495.162567,821.142090 495.320038,819.272034 495.755676,817.169922 
	C500.242096,818.981934 504.264557,816.124695 508.443054,816.413940 
	C509.888458,816.514038 511.415771,815.430847 513.252380,814.791992 
	C514.782166,815.934509 515.965271,817.173096 516.882996,818.133911 
	C518.455200,817.108582 520.301208,815.904663 522.447754,814.866699 
	C523.804077,815.310852 524.859863,815.589050 526.262451,815.964844 
	C527.088257,816.037781 527.567383,816.013123 528.046509,815.988464 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M392.094177,838.083008 
	C391.088409,836.706970 390.082642,835.330933 388.424164,833.061890 
	C389.295624,831.132507 390.734253,827.947510 392.414612,824.381836 
	C393.070923,824.247986 393.485443,824.494812 393.900024,824.741577 
	C393.780426,826.367432 393.660858,827.993286 393.468201,830.612793 
	C397.338898,827.753723 400.341034,825.532288 403.085388,829.410400 
	C404.989197,828.566101 406.622650,827.841736 408.256134,827.117310 
	C408.476227,827.482117 408.696320,827.846924 408.916382,828.211731 
	C407.563477,829.455750 406.210571,830.699768 404.857666,831.943787 
	C405.064728,832.293579 405.271790,832.643433 405.478851,832.993225 
	C407.138214,832.234131 409.913757,831.820190 410.272827,830.658997 
	C412.352203,823.934692 416.533661,827.535156 420.569031,828.162231 
	C420.980103,828.076660 421.007568,828.032715 420.992615,828.410522 
	C421.448914,828.889038 421.920166,828.989746 422.391418,829.090576 
	C423.389435,828.411926 424.387482,827.733337 425.696350,827.027710 
	C426.007233,827.000732 426.000946,827.500122 425.749725,827.917908 
	C425.451447,828.671692 425.404419,829.007629 425.357361,829.343628 
	C425.218842,830.652466 425.080322,831.961243 424.938660,833.299622 
	C423.539673,832.994263 422.005737,832.134766 421.038116,832.560974 
	C419.202454,833.369507 418.018494,834.147095 417.087952,831.533813 
	C416.944031,831.129761 414.044128,830.952087 413.676178,831.505615 
	C411.321106,835.048157 407.708832,834.621521 403.756348,834.594727 
	C402.287292,834.420166 401.367493,834.617065 400.447693,834.813904 
	C399.527863,834.939148 398.608063,835.064392 397.067871,835.168762 
	C396.054077,835.057495 395.660645,834.967102 395.108734,834.637451 
	C394.510986,834.544373 394.071716,834.690613 393.472412,835.078918 
	C393.432373,836.272461 393.552368,837.223877 393.411011,838.259033 
	C392.797821,838.256226 392.445984,838.169617 392.094177,838.083008 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M512.905640,814.888062 
	C511.415771,815.430847 509.888458,816.514038 508.443054,816.413940 
	C504.264557,816.124695 500.242096,818.981934 495.391968,816.978027 
	C493.807220,817.434387 492.236053,817.665283 492.047913,818.303650 
	C491.611053,819.786438 491.841675,821.465820 491.801727,823.065552 
	C491.801727,823.065552 491.401001,823.039124 491.181702,822.739990 
	C490.670410,821.983032 490.378387,821.525269 490.086395,821.067505 
	C490.239105,818.195312 490.391815,815.323059 491.212524,812.240845 
	C497.059784,811.667419 502.939819,814.573364 507.291504,808.968872 
	C509.412598,810.259033 511.201385,811.379089 512.928589,812.793701 
	C512.879883,813.688293 512.892761,814.288147 512.905640,814.888062 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M513.252380,814.791992 
	C512.892761,814.288147 512.879883,813.688293 512.926758,812.381287 
	C513.821228,809.884155 514.655884,808.094116 515.490540,806.304016 
	C515.890686,806.514160 516.290833,806.724243 516.690979,806.934326 
	C516.214600,808.042419 515.738220,809.150452 515.031555,810.794128 
	C518.947144,807.864319 522.244934,805.396667 525.542786,802.929077 
	C526.315125,803.351196 527.703979,803.702881 527.761475,804.206604 
	C528.188293,807.941284 528.350403,811.706238 528.321045,815.725098 
	C527.567383,816.013123 527.088257,816.037781 526.274902,815.549927 
	C525.538025,812.149536 525.135376,809.261658 524.732666,806.373779 
	C523.986145,806.412720 523.239685,806.451599 522.493164,806.490540 
	C522.377869,809.227295 522.262573,811.963989 522.147217,814.700745 
	C520.301208,815.904663 518.455200,817.108582 516.882996,818.133911 
	C515.965271,817.173096 514.782166,815.934509 513.252380,814.791992 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M525.478271,802.541138 
	C522.244934,805.396667 518.947144,807.864319 515.031555,810.794128 
	C515.738220,809.150452 516.214600,808.042419 516.690979,806.934326 
	C516.290833,806.724243 515.890686,806.514160 515.490540,806.304016 
	C514.655884,808.094116 513.821228,809.884155 512.988403,812.086670 
	C511.201385,811.379089 509.412598,810.259033 507.296692,808.657104 
	C506.991272,807.759521 507.012970,807.343933 507.034668,806.928284 
	C507.589874,807.066772 508.145050,807.205261 509.140106,807.312012 
	C512.478699,807.132385 513.769348,802.409729 517.458618,804.510864 
	C517.677551,803.753296 517.896484,802.995789 518.490967,801.857666 
	C519.912415,801.276855 520.958191,801.076599 522.004028,800.876282 
	C523.140564,801.301941 524.277161,801.727600 525.478271,802.541138 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M391.687469,838.159668 
	C392.445984,838.169617 392.797821,838.256226 393.818848,838.359741 
	C395.317413,838.360229 396.146790,838.343811 396.976196,838.327393 
	C398.523163,839.121460 400.070129,839.915527 402.713226,841.272278 
	C399.367493,841.517273 397.076965,841.684998 394.399902,842.034912 
	C392.349152,842.291138 390.643158,842.621704 389.033691,842.359375 
	C387.705994,842.143188 386.492554,841.225342 385.026184,840.304565 
	C386.976166,839.405945 389.128448,838.821167 391.687469,838.159668 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M522.002014,800.545898 
	C520.958191,801.076599 519.912415,801.276855 518.466797,801.499207 
	C516.980225,799.383179 515.893494,797.245056 514.806763,795.106995 
	C515.216553,794.791931 515.626282,794.476807 516.036072,794.161743 
	C517.783997,795.724976 519.531921,797.288208 521.617493,798.870850 
	C521.970032,799.332031 521.985046,799.773804 522.002014,800.545898 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M492.078613,823.315430 
	C491.841675,821.465820 491.611053,819.786438 492.047913,818.303650 
	C492.236053,817.665283 493.807220,817.434387 495.113800,817.210205 
	C495.320038,819.272034 495.162567,821.142090 495.140137,823.406860 
	C494.301971,823.722900 493.328705,823.644104 492.078613,823.315430 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M531.753296,766.574951 
	C531.965210,767.051270 531.957153,767.270935 531.895996,767.816772 
	C528.882812,766.670593 525.922729,765.198303 522.973938,763.362671 
	C525.834534,764.105652 528.683899,765.211975 531.753296,766.574951 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M540.663208,794.015015 
	C539.962830,795.568542 540.508911,800.442383 536.290771,796.025635 
	C537.426758,795.216492 538.848083,794.612305 540.663208,794.015015 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M396.984436,837.956177 
	C396.146790,838.343811 395.317413,838.360229 394.080200,838.276001 
	C393.552368,837.223877 393.432373,836.272461 393.881683,835.114136 
	C394.723053,834.897095 394.995148,834.886902 395.267212,834.876709 
	C395.660645,834.967102 396.054077,835.057495 396.730774,835.256958 
	C397.006958,836.105713 396.999847,836.845337 396.984436,837.956177 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M536.477783,763.954407 
	C536.155212,763.556274 536.108948,763.110413 536.118652,762.331421 
	C536.652161,762.314026 537.129700,762.629639 537.607239,762.945312 
	C537.322815,763.265747 537.038452,763.586182 536.477783,763.954407 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M536.461731,758.954468 
	C536.135132,758.556335 536.087524,758.111023 536.092163,757.332520 
	C536.629700,757.312744 537.114990,757.626160 537.600342,757.939575 
	C537.313782,758.262207 537.027222,758.584778 536.461731,758.954468 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M400.958191,834.860840 
	C401.367493,834.617065 402.287292,834.420166 403.362061,834.524536 
	C402.834229,834.853149 402.151489,834.880493 400.958191,834.860840 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M509.606445,834.045410 
	C509.209686,834.287476 508.815002,834.285706 508.422485,834.255432 
	C508.411407,834.254578 508.423889,833.948669 508.425415,833.784973 
	C508.819885,833.789185 509.214355,833.793396 509.606445,834.045410 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M490.051819,821.396606 
	C490.378387,821.525269 490.670410,821.983032 490.981201,822.728943 
	C490.998901,823.185669 490.997803,823.354248 490.991272,823.775940 
	C490.662964,823.261292 490.340118,822.493530 490.051819,821.396606 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M516.295288,759.009644 
	C516.228882,758.506104 516.484253,758.014587 516.739685,757.523132 
	C516.997131,757.902405 517.254578,758.281616 517.385986,758.860352 
	C517.045715,759.047058 516.831360,759.034363 516.295288,759.009644 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M541.113586,749.177185 
	C541.453979,749.172058 541.923218,749.420959 542.283569,749.865662 
	C541.863953,749.851379 541.553162,749.641296 541.113586,749.177185 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M536.396118,749.852539 
	C536.112305,749.606445 536.214294,749.318665 536.764771,749.061890 
	C536.931152,749.300659 536.787354,749.539978 536.396118,749.852539 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M515.848083,788.031128 
	C515.802368,788.161377 515.496826,788.273132 515.129272,788.190491 
	C515.240967,788.001648 515.414612,788.007202 515.848083,788.031128 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M530.023987,820.929199 
	C530.116943,820.756470 530.501526,820.620239 530.894165,820.742920 
	C530.706726,820.989807 530.511169,820.977722 530.023987,820.929199 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M425.514832,829.146851 
	C425.404419,829.007629 425.451447,828.671692 425.688660,828.160034 
	C425.809967,828.306335 425.741150,828.628235 425.514832,829.146851 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M421.027130,803.574158 
	C420.406494,804.423096 419.746338,804.889832 419.041138,805.684326 
	C418.643585,806.893188 417.893982,807.828003 418.004913,808.646179 
	C418.592010,812.976196 416.808502,815.853333 412.263947,817.964844 
	C410.585968,814.787720 408.870941,811.540466 407.161346,808.303467 
	C409.284119,805.835754 410.539032,802.040649 413.896484,804.998169 
	C415.186554,803.235474 416.333557,801.668335 417.480530,800.101135 
	C418.649567,801.131409 419.818604,802.161682 421.027130,803.574158 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M445.190552,788.644165 
	C444.573242,788.986084 444.100647,789.008972 443.275085,788.983887 
	C441.771973,787.146973 440.186462,785.487610 439.603424,783.529785 
	C439.096954,781.829224 439.592133,779.762268 439.986725,777.930969 
	C440.049591,777.639099 442.264771,777.271851 442.876068,777.738037 
	C444.748779,779.165771 447.124268,780.731812 447.751099,782.731018 
	C448.211334,784.198914 446.229004,786.432617 445.190552,788.644165 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M394.282715,824.797180 
	C393.485443,824.494812 393.070923,824.247986 392.405273,823.978088 
	C392.180878,821.706116 392.207550,819.457275 392.577576,817.044983 
	C393.116821,816.914429 393.312744,816.947266 393.799805,816.916687 
	C397.310883,816.405701 399.955719,817.787537 399.773651,820.905090 
	C399.691254,822.315613 396.465240,823.542542 394.282715,824.797180 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M435.710999,789.018799 
	C436.201355,789.725952 436.303955,790.408569 436.723541,791.292236 
	C437.038574,791.824707 437.032013,792.156006 437.012939,792.736450 
	C435.931824,795.991211 434.858643,798.996826 433.785461,802.002380 
	C433.105591,801.856445 432.425751,801.710510 431.745880,801.564575 
	C432.184296,797.659058 432.622711,793.753601 433.029114,789.424561 
	C433.772430,788.998718 434.547821,788.996460 435.710999,789.018799 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M512.429932,746.903625 
	C513.689697,746.893372 514.494202,746.945435 515.702759,747.008423 
	C516.138123,747.450012 516.169373,747.880615 516.202881,748.635376 
	C515.350037,749.000549 514.494995,749.041687 513.213989,749.128784 
	C511.028412,749.174622 509.268677,749.174622 507.350342,749.174622 
	C507.350342,754.709534 507.350342,759.761536 507.151123,765.439331 
	C505.972504,767.037598 504.993073,768.010071 503.958496,768.844421 
	C503.814362,768.658997 503.725403,768.611694 503.636414,768.564392 
	C503.752045,768.713928 503.867676,768.863525 503.981995,769.399841 
	C503.962524,770.305115 503.944397,770.823547 503.926270,771.342041 
	C503.411438,771.053528 502.896637,770.765076 502.180237,770.259277 
	C500.992126,767.696838 500.005615,765.351807 498.673645,762.953979 
	C497.961121,761.478149 497.594086,760.055115 497.067230,758.382324 
	C496.943237,756.398926 496.979065,754.665283 497.025452,752.504272 
	C497.899170,753.015869 498.762299,753.954956 500.030212,755.334412 
	C500.867218,753.371460 501.532745,751.810730 502.701904,749.068787 
	C494.869904,749.068787 487.856567,749.068787 480.361877,749.060181 
	C481.776703,748.387207 483.658936,747.199280 485.571411,747.148254 
	C494.367859,746.914001 503.172729,746.996887 512.429932,746.903625 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M504.017578,771.666626 
	C503.944397,770.823547 503.962524,770.305115 503.990356,769.393066 
	C504.000092,768.999573 504.013672,768.982544 504.013672,768.982544 
	C504.993073,768.010071 505.972504,767.037598 507.300049,765.862671 
	C508.344238,764.718262 509.040283,763.776184 509.997101,762.481140 
	C510.667572,765.167847 513.015686,767.857727 508.111145,768.868164 
	C507.612061,768.970947 507.547516,770.536255 506.951538,770.976318 
	C506.176727,771.548584 505.071747,771.673706 504.017578,771.666626 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M426.943237,816.945923 
	C426.145630,817.446106 425.348022,817.946350 424.251587,818.633972 
	C424.251587,814.635620 424.251587,810.986938 424.251587,807.338196 
	C424.638977,807.087585 425.026398,806.836914 425.413788,806.586243 
	C427.126678,808.363647 428.839569,810.141052 430.834381,812.210999 
	C431.680328,810.120361 432.458191,808.198120 433.236053,806.275818 
	C433.785950,806.358887 434.335876,806.442017 434.885773,806.525146 
	C434.623840,810.387207 434.361908,814.249329 434.021240,819.272400 
	C431.440063,818.727234 429.314484,818.278320 427.342621,817.320557 
	C427.463104,816.417542 427.429871,816.023438 427.396606,815.629333 
	C427.245514,816.068176 427.094391,816.507080 426.943237,816.945923 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M422.226135,828.819336 
	C421.920166,828.989746 421.448914,828.889038 420.967590,828.404053 
	C421.325348,828.195984 421.693115,828.372070 422.226135,828.819336 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M506.938721,806.608398 
	C507.012970,807.343933 506.991272,807.759521 506.964355,808.486938 
	C502.939819,814.573364 497.059784,811.667419 491.523834,811.965759 
	C491.163483,810.709778 491.159851,809.518860 491.170288,807.981689 
	C494.810730,804.583130 503.027039,803.876343 506.938721,806.608398 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M239.632187,722.819702 
	C242.542679,722.733765 243.960449,723.828857 241.863922,725.835571 
	C239.724976,727.882996 236.870300,729.182556 233.202850,731.530518 
	C233.202850,727.708252 233.202850,725.656189 233.202850,723.124756 
	C235.027634,723.038879 237.109756,722.940979 239.632187,722.819702 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M246.444702,658.193359 
	C247.484802,658.423645 248.249374,658.595886 249.013947,658.768188 
	C248.583267,659.848389 248.456924,661.484131 247.670547,661.915039 
	C243.778427,664.047363 241.385345,662.107788 239.826797,658.634888 
	C241.970200,658.466125 244.069687,658.300781 246.444702,658.193359 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M526.492004,741.966858 
	C526.187988,740.846924 526.194275,739.906128 526.208008,738.612793 
	C528.037781,737.773071 529.860107,737.285767 531.682495,736.798462 
	C531.865967,737.937866 532.535583,739.298401 532.137634,740.173279 
	C531.127991,742.392883 529.544006,744.124878 526.492004,741.966858 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M580.807251,746.370850 
	C566.505981,746.851318 552.270142,746.942627 537.579895,746.970703 
	C538.197571,746.230225 539.251221,744.997253 540.344604,744.960999 
	C552.590637,744.555481 564.842163,744.276550 577.094177,744.151062 
	C578.347290,744.138245 579.612976,745.342346 580.807251,746.370850 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M213.915833,778.585327 
	C214.005814,779.278320 213.932617,779.668152 213.869904,780.355347 
	C210.610306,782.090088 206.159988,780.462280 204.143341,785.753174 
	C203.598923,787.181519 197.177811,786.369873 193.148102,786.604248 
	C193.148102,782.188477 193.148102,778.437012 193.148102,774.272705 
	C196.850479,774.272705 200.574432,774.272705 204.655899,774.272705 
	C204.745285,777.869751 206.887024,778.898254 210.443039,778.030457 
	C211.464905,777.781128 212.645050,778.180359 213.915833,778.585327 
M196.968094,782.837952 
	C198.315216,787.713318 201.396118,784.078003 203.541962,784.592346 
	C203.541962,782.005005 203.541962,779.836060 203.541962,777.358521 
	C201.697754,778.431091 200.319580,779.232605 199.231262,779.296692 
	C200.029419,778.158142 200.827560,777.019592 202.078812,775.234680 
	C199.721771,775.123352 197.620163,774.389038 196.669144,775.130493 
	C195.298889,776.198914 194.620239,778.291870 194.032028,780.074829 
	C193.945511,780.337036 195.981216,781.299377 196.968094,782.837952 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M195.497086,757.925720 
	C195.056885,757.607910 195.086166,757.315857 195.242767,756.825439 
	C197.583130,756.667175 199.853210,756.712891 202.494324,756.691040 
	C203.052032,757.329163 203.238693,758.034729 203.178253,758.928833 
	C200.590607,758.726807 198.250061,758.336304 195.497086,757.925720 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M305.979828,757.881470 
	C306.969177,757.478088 307.679108,757.263733 308.389008,757.049438 
	C308.497559,757.401062 308.606140,757.752747 308.714691,758.104431 
	C307.681396,758.359924 306.648621,758.617554 305.611298,758.855408 
	C305.591827,758.859863 305.465240,758.621643 305.483612,758.512695 
	C305.509705,758.357544 305.623871,758.217163 305.979828,757.881470 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M341.008728,719.013916 
	C341.686188,715.267029 343.625397,714.256592 348.071625,716.248047 
	C345.301666,717.319336 343.480377,718.023743 341.316956,718.856079 
	C340.974823,718.984131 340.999542,719.001709 341.008728,719.013916 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M191.284271,645.007202 
	C191.142624,644.688354 191.281631,644.330566 191.733246,643.988953 
	C191.885559,644.326111 191.725235,644.647217 191.284271,645.007202 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M190.297180,646.015259 
	C190.144302,645.682922 190.277771,645.321106 190.728668,644.982117 
	C190.891907,645.331909 190.737701,645.658875 190.297180,646.015259 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M299.635620,643.463440 
	C299.341797,643.953125 299.047943,644.442810 298.504364,645.081177 
	C298.108795,645.097900 297.962982,644.966003 297.615234,644.613159 
	C297.728760,643.959167 298.044159,643.526245 298.782776,643.135742 
	C299.349213,643.273254 299.492401,643.368347 299.635620,643.463440 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M299.019958,642.999756 
	C299.201416,642.634644 299.568939,642.447937 299.936462,642.261230 
	C299.983917,642.532471 300.031372,642.803772 299.857239,643.269287 
	C299.492401,643.368347 299.349213,643.273254 299.019958,642.999756 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M234.992859,798.637329 
	C233.655746,799.287109 232.288757,799.553406 230.602905,800.109680 
	C229.912323,799.693604 229.540604,798.987488 229.118668,797.980957 
	C231.033295,797.871582 232.998138,798.062744 234.992859,798.637329 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M233.429611,794.493164 
	C233.498596,794.450745 233.360626,794.535522 233.429611,794.493164 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M209.421326,815.623779 
	C209.340927,815.527832 209.629700,815.485107 209.629700,815.485107 
	C209.629700,815.485107 209.501740,815.719788 209.421326,815.623779 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1324.073853,614.947693 
	C1323.291260,614.979736 1322.508789,615.011719 1321.335693,615.003052 
	C1320.855591,612.033813 1320.766113,609.105347 1320.676636,606.176819 
	C1323.527466,606.140625 1326.378052,606.094360 1329.229004,606.070190 
	C1337.549072,605.999695 1345.868896,605.924744 1354.189087,605.877747 
	C1359.247925,605.849121 1364.336182,606.192261 1369.354492,605.739441 
	C1372.139282,605.488159 1376.059814,605.585938 1376.578979,600.494080 
	C1377.094849,601.677734 1378.118530,602.897217 1378.038208,604.038879 
	C1377.706543,608.749268 1379.404053,610.818176 1384.359009,610.342102 
	C1385.383423,610.243652 1386.611084,612.260010 1387.778198,613.701843 
	C1384.022461,614.327332 1380.233643,614.552673 1376.165771,614.275146 
	C1375.467041,611.690918 1377.840210,608.436279 1373.595459,607.955994 
	C1369.848511,607.532104 1366.072266,607.008789 1362.318237,607.073853 
	C1351.433228,607.262573 1340.554565,607.787354 1329.670166,608.058044 
	C1322.660278,608.232422 1322.657837,608.135864 1324.073853,614.947693 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1295.999146,607.002563 
	C1292.264282,608.518860 1288.673340,611.338501 1284.197021,607.929688 
	C1284.197021,606.511536 1284.197021,604.740356 1284.197021,602.434692 
	C1287.722046,605.258789 1291.974487,598.764221 1295.637451,604.319946 
	C1296.006348,605.505554 1296.002686,606.254089 1295.999146,607.002563 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1512.660645,605.926880 
	C1516.470459,606.922546 1520.103027,608.578430 1522.911743,604.219177 
	C1523.422852,603.425964 1525.683105,603.759705 1527.135864,603.573242 
	C1527.057617,605.015625 1527.222290,606.533081 1526.809082,607.872131 
	C1526.564209,608.665527 1525.407104,609.177368 1525.631592,608.985535 
	C1520.839966,608.550659 1517.075562,608.270203 1513.336426,607.791565 
	C1512.873901,607.732422 1512.534912,606.625183 1512.173462,605.984009 
	C1512.135010,605.915894 1512.332520,605.714966 1512.420654,605.575684 
	C1512.428833,605.634033 1512.436890,605.692383 1512.660645,605.926880 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1461.964966,613.583618 
	C1460.593384,613.993774 1459.186890,613.988159 1457.357788,613.956909 
	C1456.934204,611.638184 1456.933105,609.345032 1456.887207,606.319458 
	C1456.890015,605.107605 1456.937378,604.628296 1456.984863,604.148926 
	C1458.899780,603.955200 1460.814575,603.761475 1462.729492,603.567749 
	C1462.592896,605.164246 1462.453613,606.760498 1462.320679,608.357300 
	C1462.187012,609.960510 1462.059814,611.564331 1461.964966,613.583618 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1426.061523,613.461792 
	C1422.369263,613.900635 1418.619995,613.932373 1414.403687,613.949768 
	C1413.806274,611.528625 1414.334106,609.336304 1417.322144,610.097778 
	C1420.265137,610.847595 1424.815674,607.202881 1426.061523,613.461792 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1296.191162,607.266907 
	C1296.002686,606.254089 1296.006348,605.505554 1296.006836,604.382812 
	C1296.211304,603.069763 1296.418823,602.130859 1296.725586,600.742615 
	C1298.843506,601.952087 1302.727661,601.118103 1301.711182,605.378113 
	C1301.121338,607.849426 1299.420898,609.953735 1296.191162,607.266907 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1271.063477,605.906738 
	C1274.266846,608.596863 1272.962769,611.241150 1272.043091,614.572449 
	C1269.015259,612.458191 1271.099976,606.748657 1264.632202,609.100708 
	C1264.955811,608.430969 1265.279297,607.761230 1265.602905,607.091431 
	C1267.284790,606.682129 1268.966675,606.272766 1271.063477,605.906738 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1233.537354,605.989075 
	C1236.993652,607.032288 1235.801025,608.383179 1233.841919,608.709595 
	C1231.194946,609.150574 1228.419312,608.819153 1225.698853,608.819153 
	C1225.624268,608.198547 1225.549683,607.577942 1225.475098,606.957336 
	C1228.024414,606.634155 1230.573853,606.310974 1233.537354,605.989075 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1456.671631,603.944336 
	C1456.937378,604.628296 1456.890015,605.107605 1456.827148,605.823853 
	C1454.777832,606.228394 1452.743774,606.395996 1450.709839,606.563599 
	C1450.530640,606.093628 1450.351562,605.623657 1450.172485,605.153748 
	C1451.851440,603.622864 1453.530518,602.092041 1455.527588,600.271301 
	C1455.904053,601.842712 1456.131348,602.791199 1456.671631,603.944336 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1408.045532,633.591248 
	C1407.634644,634.633911 1407.127441,635.221985 1406.108887,635.670288 
	C1403.394043,635.687073 1401.190674,635.843567 1398.891968,635.652283 
	C1397.822388,634.547302 1396.166992,632.989075 1395.975220,633.152222 
	C1392.842163,635.815857 1389.977051,632.674438 1387.023071,632.911194 
	C1387.016846,627.877380 1387.010498,622.843628 1386.985229,617.376221 
	C1395.304077,616.690857 1403.642090,616.439209 1412.463379,616.419434 
	C1412.946899,616.651367 1413.026855,617.034607 1413.026855,617.034607 
	C1406.990845,621.032288 1407.621094,627.132080 1408.045532,633.591248 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1324.483887,614.937988 
	C1322.657837,608.135864 1322.660278,608.232422 1329.670166,608.058044 
	C1340.554565,607.787354 1351.433228,607.262573 1362.318237,607.073853 
	C1366.072266,607.008789 1369.848511,607.532104 1373.595459,607.955994 
	C1377.840210,608.436279 1375.467041,611.690918 1375.730957,614.157410 
	C1362.103638,614.634399 1348.632080,614.726379 1334.642090,614.472229 
	C1333.057861,613.715698 1331.992065,613.305359 1330.926270,612.895020 
	C1328.915527,613.572754 1326.904663,614.250488 1324.483887,614.937988 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1417.220703,647.910645 
	C1414.847656,647.055725 1412.737793,645.902039 1410.957153,644.233459 
	C1413.134277,642.639038 1416.543945,641.603210 1416.575562,640.472900 
	C1416.775635,633.322754 1416.227417,626.151733 1415.926147,618.987549 
	C1417.318604,618.032776 1418.711182,617.078003 1420.461060,616.179260 
	C1420.920410,625.326782 1420.877197,634.423584 1421.205933,643.506897 
	C1421.315430,646.526855 1419.608032,647.052673 1417.220703,647.910645 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1410.876709,641.011169 
	C1410.617188,638.911072 1410.357788,636.810974 1410.031006,634.363159 
	C1410.408447,629.256470 1408.437622,623.905518 1413.898926,620.298401 
	C1414.607666,625.601807 1414.630859,630.853516 1414.954468,636.086670 
	C1415.147949,639.214050 1413.946777,640.784546 1410.876709,641.011169 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1410.650757,641.171021 
	C1413.946777,640.784546 1415.147949,639.214050 1414.954468,636.086670 
	C1414.630859,630.853516 1414.607666,625.601807 1414.195068,620.092896 
	C1414.404663,619.549866 1414.877197,619.271851 1415.637939,618.990662 
	C1416.227417,626.151733 1416.775635,633.322754 1416.575562,640.472900 
	C1416.543945,641.603210 1413.134277,642.639038 1410.892700,643.849121 
	C1410.474121,643.096680 1410.449463,642.213745 1410.650757,641.171021 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1331.202881,613.101379 
	C1331.992065,613.305359 1333.057861,613.715698 1334.189819,614.468994 
	C1333.330444,614.310486 1332.404907,613.809143 1331.202881,613.101379 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1413.221924,616.956116 
	C1413.026855,617.034607 1412.946899,616.651367 1412.923706,616.457764 
	C1413.072754,616.468689 1413.244873,616.673157 1413.221924,616.956116 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1208.996826,631.327393 
	C1209.348389,631.068909 1209.623291,631.231384 1209.883301,631.772705 
	C1209.635864,631.876099 1209.319824,631.741699 1208.996826,631.327393 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1209.989746,632.328491 
	C1210.348999,632.068848 1210.624512,632.231567 1210.885376,632.774292 
	C1210.632935,632.877930 1210.311279,632.743408 1209.989746,632.328491 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M336.613953,851.187256 
	C338.611664,856.846252 338.037994,857.801025 332.506866,857.557007 
	C333.574493,856.590027 334.256134,855.972595 334.778961,855.499023 
	C334.253326,854.581177 333.619598,853.474426 332.985840,852.367798 
	C334.078430,851.907104 335.171021,851.446411 336.613953,851.187256 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M580.036011,795.344604 
	C580.265381,794.875488 580.472900,794.888062 580.985352,794.832642 
	C582.920959,796.567566 584.551697,798.370605 585.722717,799.665405 
	C585.722717,804.314392 585.722717,808.616394 585.722717,813.663940 
	C585.253357,814.052979 583.994507,815.096558 582.481201,816.250000 
	C581.484131,814.924744 580.169983,813.516357 580.101379,812.049744 
	C579.848816,806.652405 580.009155,801.235718 580.036011,795.344604 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M574.122803,782.910522 
	C573.971375,782.979187 573.803833,783.025208 573.670959,783.119873 
	C571.477112,784.682434 569.035400,787.862488 566.695740,784.390381 
	C565.762939,783.006042 567.617188,779.743713 568.395020,776.545593 
	C570.844360,778.690796 572.108093,779.797607 573.738647,781.214355 
	C574.111267,781.986328 574.117004,782.448425 574.122803,782.910522 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M580.257385,755.380859 
	C580.841614,757.058289 581.456787,759.182129 581.738464,761.349365 
	C582.001587,763.375000 581.910767,765.446655 582.009216,767.840637 
	C581.832886,771.712402 581.623169,775.241638 580.770020,778.916809 
	C579.405579,778.724854 578.684570,778.386841 577.963501,778.048828 
	C578.139526,778.007263 578.315552,777.965637 578.987671,777.851074 
	C579.772461,777.604004 579.924927,777.348450 579.941162,777.011536 
	C580.008362,769.956421 580.075562,762.901367 580.257385,755.380859 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M577.598755,778.053589 
	C578.684570,778.386841 579.405579,778.724854 580.432190,779.041748 
	C580.883667,779.427246 581.029541,779.833862 581.200439,780.565430 
	C580.711853,781.630432 580.198303,782.370605 579.682617,783.260681 
	C577.964050,783.533020 576.247559,783.655396 574.326965,783.344116 
	C574.117004,782.448425 574.111267,781.986328 574.105225,781.358276 
	C575.148071,780.147705 576.191040,779.103088 577.598755,778.053589 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M581.708862,749.003845 
	C581.621399,749.674866 581.241943,750.299683 580.584229,750.999512 
	C580.250366,750.575562 580.194702,750.076660 580.192627,749.202148 
	C580.636475,748.870239 581.026733,748.913940 581.708862,749.003845 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M579.123657,786.009521 
	C579.609680,786.327759 580.060242,786.992859 580.290405,787.844238 
	C579.742676,787.472473 579.415466,786.914429 579.123657,786.009521 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M579.747559,777.102600 
	C579.924927,777.348450 579.772461,777.604004 579.256653,777.815918 
	C579.135315,777.578857 579.310120,777.358826 579.747559,777.102600 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M576.534058,840.552612 
	C576.476562,840.587280 576.591492,840.517944 576.534058,840.552612 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M551.591797,854.153137 
	C550.879456,852.911804 550.373901,851.382812 549.868347,849.853821 
	C550.511780,849.739075 551.155151,849.624390 551.798584,849.509705 
	C551.798584,850.961609 551.798584,852.413574 551.591797,854.153137 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M535.350403,805.436401 
	C535.301697,805.041016 535.421814,804.799255 535.541992,804.557556 
	C535.652649,804.660339 535.872803,804.793152 535.854309,804.860596 
	C535.784668,805.115295 535.637451,805.348755 535.350403,805.436401 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M536.897644,808.067749 
	C536.605408,807.962769 536.449219,807.730286 536.293091,807.497925 
	C536.526489,807.441833 536.759827,807.385803 537.136353,807.494751 
	C537.279480,807.659729 537.033691,807.940247 536.897644,808.067749 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M517.803589,876.233765 
	C519.069885,880.839233 515.907288,883.265808 509.648834,882.874390 
	C507.865570,876.711975 507.936584,876.634583 514.016541,876.591797 
	C515.154663,876.583801 516.290771,876.296692 517.803589,876.233765 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1533.655640,651.277161 
	C1533.070923,651.592590 1532.472656,651.649719 1531.574951,651.672729 
	C1532.064453,651.432068 1532.853271,651.225464 1533.655640,651.277161 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1538.664551,651.269897 
	C1538.075928,651.563354 1537.483154,651.600708 1536.595337,651.620483 
	C1537.086914,651.406555 1537.873657,651.210144 1538.664551,651.269897 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1544.598145,682.828430 
	C1545.541748,686.479492 1544.654419,689.560120 1541.314941,691.577271 
	C1539.565430,690.014221 1538.238892,688.882385 1536.912354,687.750549 
	C1535.978760,688.919006 1534.239014,690.091003 1534.244019,691.255432 
	C1534.273682,698.169434 1534.707642,705.081726 1534.622925,712.025696 
	C1533.473877,712.017212 1532.707520,711.977661 1532.048340,711.565674 
	C1532.155273,703.284546 1532.155273,695.375793 1532.155273,687.273254 
	C1530.001587,687.535095 1527.576416,687.829956 1525.143555,687.789062 
	C1525.280884,686.407043 1525.425781,685.360779 1526.007080,684.232178 
	C1530.628540,683.780762 1534.813721,683.411560 1539.399658,683.015198 
	C1541.399780,682.934814 1542.998901,682.881653 1544.598145,682.828430 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1546.250854,687.493042 
	C1550.281616,686.449707 1554.238892,685.813660 1558.568115,685.116943 
	C1558.912720,691.582397 1558.904785,698.108765 1558.785400,704.633118 
	C1558.780762,704.886780 1557.707275,705.120850 1556.333984,705.256226 
	C1555.287598,705.149292 1555.039307,705.150146 1554.495605,705.109680 
	C1553.805054,705.078430 1553.409790,705.088440 1553.029785,704.681396 
	C1552.981323,703.432739 1552.917358,702.601074 1552.853394,701.769470 
	C1553.176147,699.695923 1553.587036,697.631409 1553.798462,695.546570 
	C1554.047607,693.088867 1553.028687,691.747925 1550.348755,692.109863 
	C1547.128052,692.544861 1545.621094,691.232544 1546.250854,687.493042 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1535.005737,711.994568 
	C1534.707642,705.081726 1534.273682,698.169434 1534.244019,691.255432 
	C1534.239014,690.091003 1535.978760,688.919006 1536.912354,687.750549 
	C1538.238892,688.882385 1539.565430,690.014221 1540.917480,691.527527 
	C1533.156006,692.717651 1539.178223,698.421326 1536.173584,701.747742 
	C1539.810425,701.962341 1542.077881,702.096069 1544.614502,702.245728 
	C1544.759644,703.590637 1544.896729,704.860962 1544.640015,706.775940 
	C1541.474365,708.343506 1536.527344,705.641968 1536.666748,711.902588 
	C1536.263062,712.044861 1536.103638,712.107239 1535.712402,712.109924 
	C1535.480835,712.050171 1535.005737,711.994568 1535.005737,711.994568 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1538.998779,683.042358 
	C1534.813721,683.411560 1530.628540,683.780762 1525.850830,683.905212 
	C1524.616089,681.333740 1525.462158,680.913513 1527.787964,681.007996 
	C1529.425415,681.074402 1531.114258,679.874756 1532.780029,679.240356 
	C1535.243042,677.853882 1537.705933,676.467346 1540.646973,675.255432 
	C1541.439697,675.625793 1541.754395,675.821655 1542.068970,676.017456 
	C1542.068970,676.017456 1541.983887,676.044861 1542.051636,676.023193 
	C1542.119385,676.001587 1542.134277,676.180237 1542.134277,676.180237 
	C1542.528442,676.751404 1542.922607,677.322632 1543.686523,677.962341 
	C1544.143188,678.423950 1544.230225,678.817017 1543.848389,679.521179 
	C1540.802368,680.172668 1538.225464,680.513062 1534.615356,680.989929 
	C1536.759399,681.993835 1537.879028,682.518066 1538.998779,683.042358 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1545.897095,709.700256 
	C1545.893677,708.028687 1545.890381,706.357117 1546.267822,704.459717 
	C1548.451172,705.458679 1550.253662,706.683533 1551.936035,708.194336 
	C1551.534424,709.240356 1551.253174,710.000427 1550.702881,710.980042 
	C1548.042603,712.516663 1545.651367,713.833679 1543.005127,715.039490 
	C1542.515259,714.651184 1542.280640,714.373962 1542.328613,713.917480 
	C1544.239380,712.667969 1545.867676,711.597839 1547.780151,710.340759 
	C1546.796021,710.006042 1546.346558,709.853149 1545.897095,709.700256 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1536.911011,711.822693 
	C1536.527344,705.641968 1541.474365,708.343506 1544.641602,707.244995 
	C1544.998291,707.780762 1544.959351,708.492126 1544.574951,709.468018 
	C1542.546265,709.841431 1540.863037,709.950134 1539.040405,710.067871 
	C1539.040405,712.078125 1539.040405,713.508972 1538.833496,715.147095 
	C1538.054688,714.177063 1537.482910,712.999878 1536.911011,711.822693 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1552.056152,707.908325 
	C1550.253662,706.683533 1548.451172,705.458679 1546.329468,704.110718 
	C1548.011353,703.256775 1550.012695,702.525879 1552.433594,701.782227 
	C1552.917358,702.601074 1552.981323,703.432739 1553.107910,705.011658 
	C1552.798950,706.475403 1552.427490,707.191833 1552.056152,707.908325 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1539.040405,714.939880 
	C1539.040405,713.508972 1539.040405,712.078125 1539.040405,710.067871 
	C1540.863037,709.950134 1542.546265,709.841431 1544.669067,709.680298 
	C1545.108643,709.627930 1545.493408,709.767822 1545.695312,709.734009 
	C1546.346558,709.853149 1546.796021,710.006042 1547.780151,710.340759 
	C1545.867676,711.597839 1544.239380,712.667969 1542.025391,714.016602 
	C1540.639771,714.509949 1539.840088,714.724915 1539.040405,714.939880 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1539.399658,683.015198 
	C1537.879028,682.518066 1536.759399,681.993835 1534.615356,680.989929 
	C1538.225464,680.513062 1540.802368,680.172668 1543.817871,679.821716 
	C1544.416870,680.612549 1544.577393,681.414001 1544.667969,682.521912 
	C1542.998901,682.881653 1541.399780,682.934814 1539.399658,683.015198 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1532.405029,679.223389 
	C1531.114258,679.874756 1529.425415,681.074402 1527.787964,681.007996 
	C1525.462158,680.913513 1524.616089,681.333740 1525.414551,683.987488 
	C1525.425781,685.360779 1525.280884,686.407043 1525.134766,688.248901 
	C1525.144531,694.315002 1525.204468,699.585938 1525.151367,704.855835 
	C1525.104614,709.489990 1527.359985,711.799622 1531.941284,711.938049 
	C1532.707520,711.977661 1533.473877,712.017212 1534.622925,712.025696 
	C1535.005737,711.994568 1535.480835,712.050171 1535.562012,712.383911 
	C1535.089722,713.798767 1534.536377,714.879822 1533.983032,715.960876 
	C1533.613403,716.169006 1533.243896,716.377136 1532.635986,716.474121 
	C1532.061035,715.659668 1531.819092,714.411926 1531.373779,714.334534 
	C1524.275757,713.101562 1522.735229,708.416748 1523.103394,702.049622 
	C1523.370605,697.426514 1522.973267,692.764954 1522.866455,687.194824 
	C1522.659058,684.375122 1522.457520,682.481018 1522.444214,680.192017 
	C1522.718628,678.612732 1522.804932,677.428528 1522.891113,676.244263 
	C1521.518677,676.234558 1520.146118,676.206482 1518.773926,676.220520 
	C1517.551514,676.233093 1516.299683,676.476257 1515.115112,676.283508 
	C1513.678467,676.049805 1512.194946,674.915283 1510.917969,675.156555 
	C1508.799927,675.556702 1506.894531,677.078247 1504.776367,677.483521 
	C1503.332886,677.759766 1501.679321,676.938782 1500.098389,676.272217 
	C1500.394409,674.662231 1500.711670,673.388367 1501.260010,671.822754 
	C1505.182983,671.531006 1508.874756,671.531006 1512.566406,671.531006 
	C1512.603027,671.976990 1512.639648,672.422913 1512.676270,672.868896 
	C1513.609009,671.986511 1514.541626,671.104126 1515.658081,670.048035 
	C1516.221313,671.320679 1516.697632,672.397095 1517.053589,673.201233 
	C1519.186768,671.892639 1521.096313,670.721252 1522.980347,669.544678 
	C1522.954712,669.539551 1522.905518,669.557800 1522.905518,669.557800 
	C1523.485352,671.621887 1524.065063,673.686035 1524.644775,675.750122 
	C1525.219116,675.619446 1525.793335,675.488770 1526.367676,675.358093 
	C1526.367676,673.937378 1526.367676,672.516724 1526.367676,671.215149 
	C1528.240967,671.114258 1529.883911,671.025757 1531.489624,670.939270 
	C1531.588623,672.805969 1531.708252,675.058960 1531.856812,677.859497 
	C1528.962036,674.054871 1527.242310,675.883728 1524.639648,680.017151 
	C1527.916626,679.657654 1529.973389,679.432007 1532.405029,679.223389 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1523.153198,669.469849 
	C1522.905518,669.557800 1522.954712,669.539551 1522.979370,669.156616 
	C1523.007568,668.514832 1523.011230,668.256104 1523.014771,667.997437 
	C1523.301514,668.014893 1523.586914,668.002075 1524.083740,667.937439 
	C1523.998169,668.404602 1523.699585,668.893250 1523.153198,669.469849 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1542.140747,675.753845 
	C1541.754395,675.821655 1541.439697,675.625793 1540.998047,675.175293 
	C1541.068237,674.839905 1541.265259,674.759216 1541.744995,674.827332 
	C1542.089111,675.147461 1542.150757,675.318848 1542.140747,675.753845 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1522.946777,667.565796 
	C1523.011230,668.256104 1523.007568,668.514832 1523.005005,669.161743 
	C1521.096313,670.721252 1519.186768,671.892639 1517.053589,673.201233 
	C1516.697632,672.397095 1516.221313,671.320679 1515.658081,670.048035 
	C1514.541626,671.104126 1513.609009,671.986511 1512.676270,672.868896 
	C1512.639648,672.422913 1512.603027,671.976990 1512.566406,671.531006 
	C1508.874756,671.531006 1505.182983,671.531006 1500.881592,671.899414 
	C1497.155151,669.610291 1494.141968,669.711548 1491.384644,672.934998 
	C1490.283325,671.932922 1489.425781,671.152832 1489.213013,670.959229 
	C1487.018677,672.332825 1485.234375,673.449707 1482.955933,674.875916 
	C1489.394165,677.983032 1494.650513,678.678772 1500.119751,676.608398 
	C1501.679321,676.938782 1503.332886,677.759766 1504.776367,677.483521 
	C1506.894531,677.078247 1508.799927,675.556702 1510.917969,675.156555 
	C1512.194946,674.915283 1513.678467,676.049805 1515.115112,676.283508 
	C1516.299683,676.476257 1517.551514,676.233093 1518.773926,676.220520 
	C1520.146118,676.206482 1521.518677,676.234558 1522.891113,676.244263 
	C1522.804932,677.428528 1522.718628,678.612732 1522.130493,680.329468 
	C1518.922119,681.057251 1516.215576,681.252625 1513.509155,681.447998 
	C1513.518555,681.526306 1513.528076,681.604614 1513.537598,681.682922 
	C1511.413452,682.075867 1509.289307,682.468811 1506.935425,682.567871 
	C1504.133789,682.166687 1501.562012,682.059509 1498.754883,681.677734 
	C1496.677124,681.468506 1494.834473,681.533691 1492.779297,681.803955 
	C1492.403198,682.359497 1492.239746,682.709961 1491.740479,683.064453 
	C1490.958496,683.044678 1490.512207,683.020752 1489.925903,682.657043 
	C1488.239990,682.697571 1486.693970,683.077942 1485.147949,683.458252 
	C1480.713745,682.847717 1478.760620,680.847290 1481.153564,676.375427 
	C1481.382324,675.947815 1481.746338,675.188232 1481.584717,675.015076 
	C1477.256104,670.378906 1482.948975,666.104553 1482.096313,661.568176 
	C1481.932129,660.695007 1482.854736,659.414429 1483.591553,658.611328 
	C1486.317139,655.640503 1492.970703,654.354797 1496.748901,655.928406 
	C1496.897095,655.990112 1497.081421,656.234680 1497.140747,656.201416 
	C1502.195190,653.358643 1506.078491,655.996643 1510.159668,658.675049 
	C1512.180786,660.001343 1514.739502,660.508545 1516.739624,662.446533 
	C1518.514771,661.668640 1520.289917,660.890747 1522.403442,660.152100 
	C1522.787354,662.505676 1522.833008,664.819946 1522.946777,667.565796 
M1494.671753,664.251587 
	C1495.985107,664.828491 1497.298462,665.405457 1498.611938,665.982361 
	C1496.352295,664.147583 1501.457397,658.772400 1495.251587,659.020020 
	C1492.352905,659.135742 1491.599365,660.549683 1491.357422,663.310791 
	C1489.710449,663.609924 1488.063477,663.909058 1486.416382,664.208252 
	C1486.318848,663.957581 1486.221191,663.706970 1486.123657,663.456299 
	C1487.381348,662.407043 1488.639160,661.357788 1489.896851,660.308594 
	C1489.600464,659.821472 1489.304077,659.334351 1489.007690,658.847229 
	C1487.261719,659.963745 1485.233398,660.827026 1483.900269,662.313538 
	C1483.315308,662.965881 1483.721924,665.197327 1484.425659,666.171997 
	C1485.903564,668.218750 1492.449585,666.647766 1494.671753,664.251587 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1522.668213,656.407227 
	C1520.948120,657.463562 1519.276367,658.141724 1517.924561,658.690063 
	C1513.936279,656.911377 1510.201660,655.245789 1506.467041,653.580200 
	C1506.559082,653.093323 1506.651123,652.606384 1506.743286,652.119446 
	C1511.738037,652.119446 1516.732788,652.119446 1522.165649,652.172302 
	C1522.641357,653.493103 1522.678833,654.761108 1522.668213,656.407227 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1542.131592,676.263916 
	C1542.134277,676.180237 1542.119385,676.001587 1542.031006,675.983582 
	C1541.942749,675.965576 1542.128784,676.347656 1542.131592,676.263916 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M240.535004,776.521484 
	C238.254028,778.600342 235.700668,780.470581 232.961273,782.477112 
	C231.291428,776.558716 231.608826,776.137878 236.817520,776.291565 
	C237.965103,776.325439 239.114227,776.307129 240.535004,776.521484 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M244.850906,781.026611 
	C242.243408,782.179443 239.675812,782.927063 237.108200,783.674683 
	C236.937042,783.236633 236.765869,782.798584 236.594696,782.360474 
	C238.441803,781.153076 240.278198,779.928833 242.138687,778.742371 
	C244.486526,777.245117 244.954391,778.557373 244.850906,781.026611 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1403.827759,716.881592 
	C1400.631592,714.890381 1400.631592,714.890381 1402.879883,710.505920 
	C1397.329590,703.757385 1397.556763,695.847229 1403.723389,692.103271 
	C1403.784424,696.264893 1405.770386,697.040527 1409.369629,696.040527 
	C1412.899170,695.703308 1416.079956,695.411926 1419.582642,695.091003 
	C1419.172485,693.442200 1418.614258,691.198364 1418.056030,688.954529 
	C1420.331543,689.407593 1422.607056,689.860657 1424.882690,690.313721 
	C1424.763916,690.879333 1424.645264,691.444946 1424.526611,692.010559 
	C1430.260986,689.877075 1435.636353,689.179138 1439.280762,695.747986 
	C1443.490112,693.773682 1447.309570,693.934021 1450.755981,698.278870 
	C1451.394897,699.305481 1451.611206,699.807312 1451.405762,700.340637 
	C1449.235107,701.494446 1447.486450,702.616821 1445.279053,704.033569 
	C1447.187256,704.700439 1447.763428,704.901794 1448.200684,705.442566 
	C1448.240479,706.480164 1448.419067,707.178284 1448.306396,708.154480 
	C1443.870728,710.190918 1439.726196,711.949280 1435.172363,713.881348 
	C1433.110840,715.610779 1430.503418,717.798157 1427.896118,719.985474 
	C1425.678345,720.989380 1423.543823,722.299133 1421.224731,722.931274 
	C1416.879395,724.115784 1416.868896,719.728210 1415.109985,717.629395 
	C1414.969727,717.462097 1415.704834,716.125061 1415.946167,716.150757 
	C1416.931030,716.255493 1417.864258,716.780945 1418.851074,716.950195 
	C1419.559570,717.071777 1420.322632,716.874084 1421.471924,716.510620 
	C1423.493408,716.559570 1425.104370,716.916809 1426.878174,717.310181 
	C1426.878174,715.353821 1426.878174,713.428406 1426.878174,710.968994 
	C1425.347778,712.124023 1424.224365,712.971863 1423.261475,713.698608 
	C1421.714111,712.791992 1420.453491,712.053406 1419.192871,711.314758 
	C1417.043213,711.703857 1414.893555,712.092896 1412.091553,712.600037 
	C1412.048828,712.744507 1411.686157,713.971069 1410.805664,715.592163 
	C1409.256592,716.251892 1408.225586,716.517029 1407.194580,716.782227 
	C1406.619507,715.992859 1406.044434,715.203491 1405.469360,714.414124 
	C1407.360107,713.866394 1409.250854,713.318665 1411.141602,712.770935 
	C1410.730835,712.373657 1410.320190,711.976379 1409.909424,711.579102 
	C1405.623901,712.259460 1404.673096,713.088501 1403.827759,716.881592 
M1434.737793,704.443054 
	C1434.467041,703.963867 1434.196289,703.484619 1434.095825,702.346008 
	C1434.071167,701.895447 1434.046387,701.444885 1433.945557,700.521729 
	C1433.598877,700.664734 1433.252319,700.807678 1432.249878,701.373779 
	C1431.101929,701.486755 1429.673950,702.093567 1428.853638,701.628540 
	C1424.973999,699.429932 1421.483398,699.445496 1418.176025,702.620422 
	C1418.009766,702.780090 1417.620483,702.707397 1417.339111,702.742371 
	C1416.903076,701.892334 1416.478149,701.063965 1416.085693,700.298889 
	C1413.508667,701.895081 1411.086060,703.395630 1408.733765,704.852722 
	C1410.623535,705.372803 1412.863525,706.585388 1415.013672,706.442566 
	C1418.314819,706.223267 1421.922241,710.570068 1425.600708,705.796753 
	C1425.814697,705.717529 1426.028809,705.638306 1426.525391,706.051025 
	C1426.661987,706.403687 1426.798584,706.756348 1427.130859,707.508484 
	C1427.405396,707.321472 1427.679810,707.134521 1428.082520,706.224731 
	C1429.055420,705.778931 1430.028320,705.333130 1431.700073,704.914917 
	C1432.804077,705.593140 1433.908203,706.271362 1435.615967,707.071350 
	C1436.063721,706.674011 1436.511475,706.276611 1437.640137,705.789795 
	C1438.649536,705.376099 1439.659058,704.962402 1440.567749,704.589966 
	C1441.249512,705.720032 1441.921875,706.834778 1442.594360,707.949524 
	C1443.128540,707.525391 1443.662720,707.101196 1444.196899,706.677002 
	C1442.140259,704.582336 1440.083618,702.487671 1437.392456,699.746765 
	C1437.236938,702.064453 1437.150269,703.357483 1436.280518,704.398682 
	C1435.846924,704.676575 1435.413330,704.954468 1434.737793,704.443054 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1400.035889,719.580933 
	C1395.201538,719.420288 1390.417236,718.791992 1385.617065,718.328125 
	C1384.716187,718.241028 1383.751953,718.810608 1382.483398,719.265747 
	C1380.441040,719.757507 1378.597168,719.750366 1377.049072,720.423340 
	C1372.746704,722.293701 1370.828003,721.315918 1370.780151,716.753540 
	C1370.696655,708.792236 1370.604370,700.826965 1370.804810,692.870605 
	C1370.839233,691.505310 1372.271851,689.033813 1373.120850,689.006409 
	C1381.978271,688.720886 1390.848267,688.830383 1400.340088,689.368713 
	C1394.201172,694.010864 1396.698364,700.274719 1396.692871,705.660156 
	C1396.688232,710.162354 1398.868042,714.666748 1400.035889,719.580933 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1448.597656,707.876404 
	C1448.419067,707.178284 1448.240479,706.480164 1448.326416,705.462463 
	C1448.590942,705.142883 1448.836914,705.074341 1449.203613,705.088379 
	C1450.360352,704.097107 1451.150635,703.091858 1451.996338,701.860901 
	C1451.977051,701.193176 1451.902344,700.751160 1451.827637,700.309143 
	C1451.611206,699.807312 1451.394897,699.305481 1451.077393,698.403564 
	C1451.307739,697.518066 1451.639282,697.032715 1451.944092,696.235474 
	C1452.134277,693.130737 1453.411621,691.767883 1456.433960,692.043396 
	C1459.049805,692.281860 1461.704468,692.092957 1464.882446,692.092957 
	C1465.092773,695.726746 1465.281128,698.979919 1465.633545,702.646790 
	C1465.369751,702.787598 1464.942017,702.514709 1464.075684,701.809082 
	C1462.719482,699.607056 1464.882812,696.301758 1461.003174,695.687500 
	C1459.047363,694.983337 1455.637451,693.830505 1455.325562,694.393677 
	C1454.229248,696.372864 1454.198120,698.942139 1453.663940,701.819336 
	C1455.789551,700.924377 1456.913086,700.451355 1458.013428,700.322876 
	C1457.489014,701.629150 1456.987671,702.590759 1455.987305,704.509949 
	C1459.258301,703.871704 1461.609497,703.412964 1463.960815,702.954224 
	C1463.997192,703.751587 1464.033569,704.548889 1463.986572,705.737610 
	C1459.389771,705.447083 1454.771973,703.838440 1450.188232,707.807617 
	C1449.277100,707.963440 1448.937378,707.919922 1448.597656,707.876404 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1500.164551,702.401855 
	C1499.760376,703.401978 1499.317627,703.970642 1498.813232,704.923706 
	C1494.928589,704.943909 1489.799683,705.912842 1487.666382,703.823425 
	C1485.711182,701.908569 1486.989502,696.692200 1486.772217,692.470093 
	C1487.240723,691.976990 1487.780029,691.944336 1489.166870,691.924438 
	C1493.336914,692.026550 1496.659424,692.115967 1500.005859,692.539673 
	C1500.106812,693.314270 1500.183838,693.754639 1500.260742,694.195007 
	C1500.215820,696.786804 1500.171021,699.378601 1500.164551,702.401855 
M1497.735474,696.511108 
	C1495.506958,698.316162 1493.278320,700.121277 1490.644287,702.254761 
	C1497.501953,704.986816 1498.180542,704.401855 1497.735474,696.511108 
M1492.346436,698.445862 
	C1494.776001,693.817261 1491.288330,695.326477 1489.022217,695.008606 
	C1489.022217,697.170349 1489.022217,698.930725 1489.022217,701.485718 
	C1490.357910,700.305115 1491.061890,699.682922 1492.346436,698.445862 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1448.306396,708.154480 
	C1448.937378,707.919922 1449.277100,707.963440 1449.872070,708.044434 
	C1448.087158,709.871948 1446.276489,712.074097 1443.958374,713.364380 
	C1439.423340,715.888855 1434.605225,717.904541 1429.512207,720.112183 
	C1428.917847,720.058655 1428.715698,720.023743 1428.204834,719.987122 
	C1430.503418,717.798157 1433.110840,715.610779 1435.172363,713.881348 
	C1439.726196,711.949280 1443.870728,710.190918 1448.306396,708.154480 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1417.682373,688.964233 
	C1418.614258,691.198364 1419.172485,693.442200 1419.582642,695.091003 
	C1416.079956,695.411926 1412.899170,695.703308 1409.344727,695.652649 
	C1408.946655,695.052612 1408.922485,694.794678 1409.143921,694.299194 
	C1410.277344,692.375061 1411.165405,690.688477 1412.053467,689.001831 
	C1412.053345,689.001831 1411.993652,688.988708 1412.373779,689.040955 
	C1413.775513,689.015076 1414.796997,688.936951 1415.818604,688.858887 
	C1416.315308,688.897217 1416.811890,688.935608 1417.682373,688.964233 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1499.981689,692.205322 
	C1496.659424,692.115967 1493.336914,692.026550 1489.568359,691.876038 
	C1488.988525,691.027283 1488.854858,690.239624 1489.103760,689.129517 
	C1489.931274,688.500977 1490.376221,688.194763 1491.186279,687.883789 
	C1491.788330,687.824036 1492.025391,687.769104 1492.512695,687.773621 
	C1493.101562,687.813293 1493.440063,687.793457 1494.218750,687.816650 
	C1496.134033,687.883240 1497.609253,687.906921 1499.288086,688.097412 
	C1499.984131,688.455688 1500.476562,688.647095 1501.063477,689.193604 
	C1500.765869,690.434326 1500.373779,691.319824 1499.981689,692.205322 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1419.177002,711.639282 
	C1420.453491,712.053406 1421.714111,712.791992 1423.261475,713.698608 
	C1424.224365,712.971863 1425.347778,712.124023 1426.878174,710.968994 
	C1426.878174,713.428406 1426.878174,715.353821 1426.878174,717.310181 
	C1425.104370,716.916809 1423.493408,716.559570 1421.457764,716.136108 
	C1420.409058,714.701233 1419.785034,713.332520 1419.177002,711.639282 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1404.002441,717.221313 
	C1404.673096,713.088501 1405.623901,712.259460 1409.909424,711.579102 
	C1410.320190,711.976379 1410.730835,712.373657 1411.141602,712.770935 
	C1409.250854,713.318665 1407.360107,713.866394 1405.469360,714.414124 
	C1406.044434,715.203491 1406.619507,715.992859 1407.194580,716.782227 
	C1408.225586,716.517029 1409.256592,716.251892 1410.639404,715.882874 
	C1409.738647,720.269470 1407.160156,719.688782 1404.002441,717.221313 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1490.821167,687.888550 
	C1490.376221,688.194763 1489.931274,688.500977 1489.229736,688.891846 
	C1485.637573,688.961060 1483.047241,688.316895 1484.968506,683.737915 
	C1486.693970,683.077942 1488.239990,682.697571 1489.607788,682.797546 
	C1488.268921,684.201294 1487.108398,685.124817 1485.771362,686.188660 
	C1487.891724,686.902405 1489.356445,687.395447 1490.821167,687.888550 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1408.898438,694.536743 
	C1408.922485,694.794678 1408.946655,695.052612 1408.995728,695.698486 
	C1405.770386,697.040527 1403.784424,696.264893 1404.086914,691.991943 
	C1404.144775,691.546143 1404.089600,691.553467 1404.407471,691.418701 
	C1404.994873,690.876404 1405.264404,690.468872 1405.533936,690.061279 
	C1405.951660,690.028076 1406.369507,689.994873 1407.270264,689.641602 
	C1408.507202,689.387390 1409.261230,689.453064 1410.015259,689.518738 
	C1410.015259,689.518738 1409.812378,689.614258 1409.695923,690.023560 
	C1408.515625,691.556030 1407.451782,692.679138 1406.387939,693.802307 
	C1407.224731,694.047119 1408.061646,694.291931 1408.898438,694.536743 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1409.143921,694.299194 
	C1408.061646,694.291931 1407.224731,694.047119 1406.387939,693.802307 
	C1407.451782,692.679138 1408.515625,691.556030 1409.723511,690.132202 
	C1410.401367,689.587585 1410.935303,689.343567 1411.761353,689.050781 
	C1411.165405,690.688477 1410.277344,692.375061 1409.143921,694.299194 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1415.644287,688.583435 
	C1414.796997,688.936951 1413.775513,689.015076 1412.368896,689.071106 
	C1413.145996,688.802002 1414.307861,688.554993 1415.644287,688.583435 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1409.892944,689.149170 
	C1409.261230,689.453064 1408.507202,689.387390 1407.376953,689.439453 
	C1407.924072,689.298035 1408.847290,689.038818 1409.892944,689.149170 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1405.286011,689.831543 
	C1405.264404,690.468872 1404.994873,690.876404 1404.379639,691.419495 
	C1404.368652,690.903931 1404.703247,690.252869 1405.286011,689.831543 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1497.991455,652.190918 
	C1498.124512,652.387695 1498.062134,652.583130 1497.999756,652.778503 
	C1497.931763,652.582214 1497.863770,652.385864 1497.991455,652.190918 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1386.783447,633.123901 
	C1389.977051,632.674438 1392.842163,635.815857 1395.975220,633.152222 
	C1396.166992,632.989075 1397.822388,634.547302 1398.650635,635.821106 
	C1398.344116,636.898010 1398.183594,637.458435 1397.866943,638.199646 
	C1397.494629,638.614868 1397.281006,638.851379 1396.754761,639.195068 
	C1394.810913,638.961121 1393.182007,638.622192 1391.045166,638.177551 
	C1390.435669,639.155396 1389.599976,640.496155 1388.764160,641.836914 
	C1384.887451,641.003967 1381.594360,639.688049 1383.697754,635.515991 
	C1384.646362,634.789551 1385.595093,634.063110 1386.783447,633.123901 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1398.023071,638.018799 
	C1398.183594,637.458435 1398.344116,636.898010 1398.745850,636.168823 
	C1401.190674,635.843567 1403.394043,635.687073 1405.804199,635.781982 
	C1406.652344,638.093384 1407.293945,640.153381 1407.930420,642.618164 
	C1401.837891,642.671936 1395.750610,642.320740 1389.213623,641.903320 
	C1389.599976,640.496155 1390.435669,639.155396 1391.045166,638.177551 
	C1393.182007,638.622192 1394.810913,638.961121 1396.816528,639.574707 
	C1399.713501,640.206909 1402.233643,640.564392 1404.753906,640.921875 
	C1404.831787,640.086243 1404.909668,639.250610 1404.987671,638.415039 
	C1402.666138,638.282959 1400.344604,638.150879 1398.023071,638.018799 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1403.606567,648.104553 
	C1404.081787,648.131165 1404.120361,648.402466 1404.125977,648.538330 
	C1396.220825,648.674255 1388.310059,648.674255 1380.399170,648.674255 
	C1380.395508,648.475464 1380.391724,648.276733 1380.388062,648.077942 
	C1387.969116,648.077942 1395.550293,648.077942 1403.606567,648.104553 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1319.020996,699.022156 
	C1320.076538,698.336670 1321.132080,697.651184 1322.711670,697.024048 
	C1324.102417,699.229309 1324.969116,701.376282 1325.838013,703.522156 
	C1326.337891,695.683289 1326.784790,695.274780 1331.750244,698.118530 
	C1331.832031,699.544495 1331.913818,700.970398 1332.011108,702.666748 
	C1330.468872,702.509460 1329.417603,702.402283 1328.309448,702.289307 
	C1328.430664,704.576538 1328.539307,706.625977 1328.457275,708.987915 
	C1327.158691,710.108887 1326.087402,710.974854 1324.933716,711.711853 
	C1323.419067,712.679504 1321.839355,713.545593 1319.034790,715.189880 
	C1319.034790,711.766541 1319.034790,709.871094 1319.115967,707.195251 
	C1319.120361,705.951965 1319.043579,705.489075 1318.931152,704.597900 
	C1318.937378,702.453735 1318.979126,700.737976 1319.020996,699.022156 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1333.985596,684.943420 
	C1334.934692,685.838440 1335.883667,686.733521 1336.829590,687.632324 
	C1343.288086,684.854736 1347.085815,685.210571 1349.477417,690.246826 
	C1350.389526,692.167603 1348.096680,695.610352 1347.060791,697.768799 
	C1346.499390,695.302490 1346.141479,693.426880 1345.830688,691.797913 
	C1344.499756,692.060547 1342.782837,692.399292 1341.148193,692.721863 
	C1341.148193,693.926392 1341.148193,695.122070 1341.148193,697.356262 
	C1338.855103,694.783264 1337.357666,693.102966 1335.210693,690.693970 
	C1334.371826,691.950989 1333.749634,692.883240 1333.104736,693.403931 
	C1333.383057,690.309448 1333.684326,687.626465 1333.985596,684.943420 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1333.798096,684.675903 
	C1333.684326,687.626465 1333.383057,690.309448 1333.043701,693.711182 
	C1332.994019,695.281372 1332.982544,696.132812 1332.970947,696.984253 
	C1332.971069,696.984253 1332.996826,697.004150 1332.750732,697.023682 
	C1332.213013,697.263062 1332.093506,697.553711 1332.146118,697.915100 
	C1332.146118,697.915100 1331.948975,698.015686 1331.849609,698.067139 
	C1326.784790,695.274780 1326.337891,695.683289 1325.838013,703.522156 
	C1324.969116,701.376282 1324.102417,699.229309 1322.935181,696.799805 
	C1322.186279,694.864563 1321.737671,693.211975 1321.151855,691.239624 
	C1323.535889,690.607056 1326.090088,690.446716 1328.564575,689.915222 
	C1329.750488,689.660522 1330.785400,688.703247 1331.888672,688.064026 
	C1330.846924,687.357666 1329.805054,686.651306 1328.354248,685.892334 
	C1329.165283,682.716370 1331.196411,682.804626 1333.798096,684.675903 
M1328.884644,692.490295 
	C1328.329346,692.291077 1327.774048,692.091858 1327.218750,691.892578 
	C1327.297241,692.662964 1327.375854,693.433289 1327.454346,694.203674 
	C1327.915771,693.840454 1328.377319,693.477295 1328.884644,692.490295 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1333.247437,697.023560 
	C1332.982544,696.132812 1332.994019,695.281372 1333.066650,694.122681 
	C1333.749634,692.883240 1334.371826,691.950989 1335.210693,690.693970 
	C1337.357666,693.102966 1338.855103,694.783264 1341.148193,697.356262 
	C1341.148193,695.122070 1341.148193,693.926392 1341.148193,692.721863 
	C1342.782837,692.399292 1344.499756,692.060547 1345.830688,691.797913 
	C1346.141479,693.426880 1346.499390,695.302490 1346.859863,697.544556 
	C1346.862183,697.911011 1346.406982,697.941406 1345.839844,697.805054 
	C1341.542358,697.471924 1337.812256,697.275146 1334.082031,697.078369 
	C1333.895996,697.073303 1333.709961,697.068115 1333.247437,697.023560 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1326.541138,715.876587 
	C1327.421143,714.286865 1328.301270,712.697205 1329.371216,710.827026 
	C1329.953125,710.112732 1330.345215,709.678955 1330.828857,708.958130 
	C1334.263306,712.120911 1331.282959,715.966370 1330.888428,719.824280 
	C1329.116699,720.027771 1328.061523,720.012146 1326.981689,719.582336 
	C1326.818359,718.070984 1326.679810,716.973755 1326.541138,715.876587 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1334.038818,697.471191 
	C1337.812256,697.275146 1341.542358,697.471924 1345.616455,697.851318 
	C1342.965088,699.043823 1340.017456,700.310425 1336.940918,700.882690 
	C1336.172729,701.025635 1334.988403,698.930542 1334.038818,697.471191 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1332.358154,697.832947 
	C1332.093506,697.553711 1332.213013,697.263062 1332.748535,697.007874 
	C1332.979004,697.301086 1332.838257,697.560486 1332.358154,697.832947 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1492.982910,619.726074 
	C1492.733887,619.662170 1492.526245,619.230713 1492.655273,618.709412 
	C1493.002563,618.865845 1493.013306,619.112183 1492.982910,619.726074 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1276.014282,653.959351 
	C1274.442139,655.354553 1272.869873,656.749756 1271.297729,658.144958 
	C1270.882080,658.026123 1270.466553,657.907349 1270.051025,657.788513 
	C1270.463379,655.786377 1270.247070,652.825317 1271.453491,652.034119 
	C1272.839844,651.124939 1275.485962,652.136841 1277.792969,652.661072 
	C1278.001221,653.007874 1278.037598,652.985657 1277.735474,652.796997 
	C1276.941772,653.077087 1276.450195,653.545837 1275.975464,654.003662 
	C1275.992310,653.992798 1276.014282,653.959351 1276.014282,653.959351 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1277.985840,653.022461 
	C1278.869019,655.138794 1279.767700,657.240601 1281.006714,660.138428 
	C1277.512207,660.138428 1275.504639,660.138428 1272.909790,660.138428 
	C1274.196167,658.080933 1275.260864,656.377991 1276.169922,654.317139 
	C1276.014282,653.959351 1275.992310,653.992798 1276.291504,654.167603 
	C1277.072998,653.890137 1277.555298,653.437866 1278.037598,652.985657 
	C1278.037598,652.985657 1278.001221,653.007874 1277.985840,653.022461 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1326.287964,715.902588 
	C1326.679810,716.973755 1326.818359,718.070984 1326.721313,719.615112 
	C1326.256226,720.207092 1326.026855,720.352173 1325.797485,720.497314 
	C1326.265259,720.720581 1326.733154,720.943909 1327.143311,721.598633 
	C1327.109985,724.622864 1327.134277,727.215515 1327.158691,729.808228 
	C1326.657471,729.862610 1326.156250,729.917053 1325.277466,729.932983 
	C1324.899902,727.491089 1324.899902,725.087524 1324.899902,722.226257 
	C1323.006226,722.129883 1321.444702,722.050354 1319.448242,721.997070 
	C1319.022339,721.547180 1319.031494,721.071106 1319.360107,720.112549 
	C1320.127441,718.636230 1320.575439,717.642395 1321.023438,716.648560 
	C1321.528564,716.445923 1322.033691,716.243408 1322.804321,716.359985 
	C1323.490723,716.994385 1323.911621,717.309570 1324.332642,717.624756 
	C1324.557861,717.081726 1324.783081,716.538696 1325.008301,715.995605 
	C1325.350464,715.973267 1325.692505,715.950867 1326.287964,715.902588 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1327.552246,729.848450 
	C1327.134277,727.215515 1327.109985,724.622864 1327.517578,721.594238 
	C1328.960327,721.137390 1329.971313,721.116516 1330.982178,721.095703 
	C1331.326904,723.751831 1331.671509,726.408020 1331.962036,729.481995 
	C1330.587158,729.896118 1329.266479,729.892395 1327.552246,729.848450 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1485.666504,627.989136 
	C1486.018799,627.968079 1486.104492,628.442322 1486.153564,628.678223 
	C1486.061523,631.014771 1485.920410,633.115417 1485.734375,635.883423 
	C1483.724854,635.710388 1481.836914,635.547791 1478.381714,635.250244 
	C1481.377930,632.121155 1483.346069,630.065674 1485.666504,627.989136 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1481.359863,625.872742 
	C1482.201904,626.398010 1483.136108,627.211365 1483.017456,627.416931 
	C1481.979492,629.215027 1480.753418,630.904419 1479.314575,633.020996 
	C1475.714233,626.910950 1475.970947,626.389893 1481.359863,625.872742 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1477.330200,633.204102 
	C1476.885376,633.187256 1476.571655,632.998230 1476.380127,632.723755 
	C1476.334106,632.657776 1476.693604,632.308777 1476.866699,632.089966 
	C1477.079834,632.393494 1477.292969,632.696960 1477.330200,633.204102 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1346.428223,625.391052 
	C1347.765503,625.279480 1348.702271,625.196472 1349.639038,625.113464 
	C1349.798340,625.463928 1349.957520,625.814453 1350.116699,626.164917 
	C1348.577515,627.131104 1347.038330,628.097229 1345.499146,629.063416 
	C1345.145752,628.750610 1344.792480,628.437805 1344.439209,628.125000 
	C1344.968628,627.223206 1345.498169,626.321411 1346.428223,625.391052 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1408.943604,667.988037 
	C1410.554077,667.059204 1412.164429,666.130310 1414.250732,664.926941 
	C1414.250732,667.178345 1414.250732,669.264587 1414.250732,671.661560 
	C1411.912231,671.661560 1409.673340,671.661560 1407.221558,671.321594 
	C1407.008545,670.981628 1406.993774,671.008179 1407.348877,670.976562 
	C1408.126343,669.950562 1408.548584,668.956299 1408.979004,667.982849 
	C1408.986938,668.003662 1408.943604,667.988037 1408.943604,667.988037 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1406.879761,668.955139 
	C1403.615967,666.465576 1404.204224,664.380249 1407.770264,662.301880 
	C1409.062378,662.026428 1410.132446,662.033142 1411.602295,662.001587 
	C1410.976562,663.735840 1409.951172,665.508301 1408.934692,667.634399 
	C1408.943604,667.988037 1408.986938,668.003662 1408.681885,668.000977 
	C1407.877930,668.317261 1407.378784,668.636169 1406.879761,668.955139 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1330.980957,720.852844 
	C1329.971313,721.116516 1328.960327,721.137390 1327.575195,721.162720 
	C1326.733154,720.943909 1326.265259,720.720581 1325.797485,720.497314 
	C1326.026855,720.352173 1326.256226,720.207092 1326.745850,720.029297 
	C1328.061523,720.012146 1329.116699,720.027771 1330.565308,720.084106 
	C1330.958618,720.124817 1330.979614,720.609985 1330.980957,720.852844 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1406.842529,669.302856 
	C1407.378784,668.636169 1407.877930,668.317261 1408.673950,667.980225 
	C1408.548584,668.956299 1408.126343,669.950562 1407.339966,670.988892 
	C1406.918945,670.572205 1406.862183,670.111389 1406.842529,669.302856 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M543.970459,608.240295 
	C543.978821,605.585022 543.978821,603.394043 543.978821,601.203125 
	C544.611694,601.194641 545.244568,601.186096 545.877441,601.177551 
	C546.022461,609.558777 546.053833,617.943787 545.907593,626.334656 
	C545.259094,626.369202 544.610657,626.403809 543.962158,626.438354 
	C543.962158,620.527100 543.962158,614.615906 543.970459,608.240295 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M497.634766,673.024780 
	C498.524994,673.127686 499.066742,673.278137 499.747925,673.676086 
	C499.887360,673.923523 499.829254,674.258972 499.652588,674.559509 
	C498.970734,675.231384 498.465546,675.602783 497.960388,675.974182 
	C495.546570,675.515442 495.251678,674.564514 497.634766,673.024780 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M498.057648,676.268982 
	C498.465546,675.602783 498.970734,675.231384 499.675659,674.728149 
	C499.589081,675.576599 499.302765,676.556885 499.016449,677.537170 
	C498.729279,677.212708 498.442108,676.888245 498.057648,676.268982 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M517.305481,677.416504 
	C517.364990,676.954773 517.619995,676.712463 518.045044,676.267822 
	C518.668701,676.506348 519.122314,676.947144 519.575928,677.388000 
	C518.884277,677.470642 518.192627,677.553223 517.305481,677.416504 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M562.588074,575.937012 
	C565.131287,579.192566 567.211060,582.464722 570.086975,586.989319 
	C562.144287,586.989319 555.512634,586.989319 548.411987,587.059204 
	C547.956360,584.992676 547.969849,582.856323 548.116516,580.397095 
	C551.454468,573.918640 557.288269,576.615295 562.588074,575.937012 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M550.083801,574.096985 
	C549.939514,574.152283 549.659485,574.068420 549.525024,573.835083 
	C549.670593,573.685608 549.948120,573.957825 550.083801,574.096985 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M458.416351,608.282349 
	C458.011627,607.132141 458.002869,606.002136 457.991394,604.451660 
	C459.236206,605.267334 462.366394,606.140503 458.416351,608.282349 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M503.696289,666.256714 
	C503.424194,666.062683 503.334351,665.721497 503.244507,665.380249 
	C503.473602,665.364746 503.702759,665.349243 504.070709,665.555664 
	C504.209534,665.777588 503.878571,666.109436 503.696289,666.256714 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M547.809326,588.361938 
	C554.899475,588.086426 561.751587,588.086426 568.805481,588.086426 
	C565.326965,598.847717 563.846558,599.688782 550.697388,598.463806 
	C550.004700,597.564636 549.701782,596.900696 549.144287,596.110596 
	C548.450134,593.535461 548.010681,591.086426 547.809326,588.361938 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M660.418945,711.695557 
	C662.240967,711.685974 664.073181,711.784790 665.880249,711.615479 
	C666.641968,711.544128 668.029358,710.775696 667.984924,710.546509 
	C666.601440,703.406799 671.729614,708.356750 674.217529,707.979431 
	C671.290100,711.070129 668.362732,714.160828 664.127747,718.631958 
	C662.565186,716.298645 661.366882,714.509216 660.148315,712.310791 
	C660.127991,711.901733 660.418945,711.695557 660.418945,711.695557 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M657.118652,707.855225 
	C659.489502,707.580322 661.860352,707.305481 665.743591,706.855225 
	C663.158875,708.905701 661.753662,710.020508 660.383667,711.415405 
	C660.418945,711.695557 660.127991,711.901733 659.987793,712.013794 
	C658.879761,711.016663 657.911926,709.907410 656.966431,708.403137 
	C656.988831,708.008118 657.118652,707.855225 657.118652,707.855225 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M656.922119,708.083008 
	C656.448608,707.950073 656.041748,707.742188 655.634827,707.534302 
	C656.038391,707.503540 656.441956,707.472839 656.982056,707.648682 
	C657.118652,707.855225 656.988831,708.008118 656.922119,708.083008 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M649.293579,649.503662 
	C650.761719,652.129883 652.036316,654.415039 653.713501,657.421997 
	C645.453430,657.421997 637.956360,657.421997 629.027771,657.421997 
	C631.621643,653.626526 633.278015,648.474182 636.182739,647.633057 
	C639.934937,646.546692 645.320068,644.774536 649.293579,649.503662 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M639.929382,669.925171 
	C632.399170,670.845459 633.408081,663.609863 629.237793,659.936035 
	C637.673157,659.936035 644.809021,659.936035 653.118896,659.936035 
	C650.087830,663.927612 650.797607,669.405823 644.417480,669.547729 
	C645.916504,662.291199 645.063904,660.851318 638.988403,661.881836 
	C639.299316,664.539551 639.614380,667.232361 639.929382,669.925171 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M640.339355,669.983398 
	C639.614380,667.232361 639.299316,664.539551 638.988403,661.881836 
	C645.063904,660.851318 645.916504,662.291199 644.028625,669.551758 
	C642.951111,669.977844 641.850159,670.009705 640.339355,669.983398 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M640.384155,553.113525 
	C639.977356,553.044922 640.044617,552.638733 640.062134,552.434082 
	C640.586548,551.965759 641.093384,551.702148 641.816650,551.251038 
	C642.393066,551.244141 642.753113,551.424805 643.320679,551.830200 
	C642.615845,552.430603 641.703430,552.806396 640.384155,553.113525 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M574.001343,694.003052 
	C574.135498,694.345764 574.268372,694.685547 574.401245,695.025330 
	C574.341003,694.951721 574.254883,694.886841 574.226501,694.802551 
	C574.180359,694.665588 574.171936,694.515991 574.076233,694.187256 
	C574.004883,694.002808 574.000000,694.000000 574.001343,694.003052 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M587.013916,558.982300 
	C585.183655,559.934082 583.353333,560.885864 581.592834,561.801392 
	C580.207947,557.757812 582.495911,555.158813 588.013062,555.671692 
	C587.594971,556.605347 587.222961,557.436035 586.924500,558.634644 
	C586.997986,559.002563 587.013916,558.982300 587.013916,558.982300 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M590.289795,557.876343 
	C590.511169,559.472168 590.500916,560.858276 590.466492,562.587402 
	C589.351746,561.829834 588.261230,560.729248 587.092346,559.305420 
	C587.013916,558.982300 586.997986,559.002563 586.989319,559.012817 
	C588.006470,558.570801 589.032288,558.118652 590.289795,557.876343 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M649.095337,691.204041 
	C651.238770,690.476807 653.264832,690.139465 655.979126,689.687500 
	C655.121887,691.589355 654.627563,692.686035 653.761169,694.608215 
	C655.644287,693.735474 656.713745,693.239807 658.045166,692.622742 
	C658.045166,694.730286 658.045166,696.533936 658.045166,698.766663 
	C655.890747,698.766663 653.831848,698.766663 651.500305,698.766663 
	C652.053955,697.692749 652.610901,696.612366 653.540344,694.809570 
	C651.779419,695.529175 650.675354,695.980286 648.978027,696.673889 
	C648.978027,694.756104 648.978027,693.174988 649.095337,691.204041 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M563.006592,679.989441 
	C561.741699,680.934021 560.476807,681.878662 558.087708,683.662781 
	C558.375977,680.779907 557.873047,677.884827 558.964966,677.032410 
	C560.146973,676.109558 562.816528,677.091980 564.919189,677.620483 
	C565.001038,677.997498 565.014465,677.981018 564.732300,678.013977 
	C564.294617,678.368896 564.138977,678.690796 563.991699,679.006348 
	C564.000000,679.000000 564.012695,678.983459 563.731323,679.014893 
	C563.295837,679.367371 563.141785,679.688416 562.993164,680.005249 
	C562.998596,680.001160 563.006592,679.989441 563.006592,679.989441 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M567.072266,680.686035 
	C567.030640,682.142944 566.996155,683.235596 567.150208,684.600586 
	C565.350464,685.319702 563.362122,685.766541 560.190491,686.479248 
	C561.719421,683.678101 562.528564,682.195679 563.172119,680.351318 
	C563.006592,679.989441 562.998596,680.001160 563.330322,680.081909 
	C563.778870,679.769592 563.895813,679.376526 564.012695,678.983459 
	C564.012695,678.983459 564.000000,679.000000 564.327515,679.067810 
	C564.774902,678.750732 564.894653,678.365906 565.014465,677.981018 
	C565.014465,677.981018 565.001038,677.997498 564.992188,678.003052 
	C565.682068,678.779663 566.380737,679.550659 567.072266,680.686035 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M684.161194,595.958740 
	C683.874695,594.224182 683.866211,592.464417 684.268677,590.219727 
	C685.855774,589.492188 687.031921,589.249634 688.208008,589.007080 
	C688.435120,588.919495 688.643738,588.951782 688.885742,589.259766 
	C687.567932,591.375854 686.198120,593.335876 684.910950,595.648193 
	C684.808838,595.978210 684.624023,595.955872 684.161194,595.958740 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M678.911255,698.963379 
	C678.765991,698.943909 678.627502,698.438599 678.700378,697.719604 
	C678.913879,697.829834 678.915955,698.153687 678.911255,698.963379 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M633.897949,565.312622 
	C633.410461,565.515015 632.870178,565.456604 632.339233,565.101929 
	C632.847534,564.887695 633.346313,564.969727 633.897949,565.312622 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M557.566406,640.469360 
	C557.613098,640.537781 557.519714,640.400940 557.566406,640.469360 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M714.571167,633.955872 
	C718.014954,634.696167 719.993835,636.015686 717.674683,639.654358 
	C717.277527,640.277588 718.112976,641.686401 718.385925,642.759460 
	C718.076538,642.783142 717.430481,642.832581 716.478638,642.933350 
	C714.301147,642.046143 712.474854,640.998230 710.545715,640.198120 
	C708.346741,639.286194 706.056213,638.595093 703.300293,637.631348 
	C708.180603,633.095764 708.963928,633.085693 711.622925,637.005127 
	C712.535522,636.000977 713.396057,635.054077 714.571167,633.955872 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M728.833252,680.755066 
	C726.136658,681.608887 723.478943,682.765259 720.718262,683.119690 
	C719.957764,683.217346 717.870850,680.238281 718.137207,679.841125 
	C719.383728,677.982605 721.239624,676.532898 722.877869,674.937073 
	C723.313477,675.377502 723.749084,675.817932 724.184692,676.258362 
	C722.780334,677.501465 721.375977,678.744568 719.971619,679.987610 
	C720.171448,680.380066 720.371277,680.772522 720.571106,681.164978 
	C722.453796,680.508667 724.336487,679.852356 726.622314,679.164734 
	C727.628113,679.674011 728.230652,680.214539 728.833252,680.755066 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M729.210205,680.887817 
	C728.230652,680.214539 727.628113,679.674011 726.884888,678.913696 
	C727.534973,677.546509 728.381592,675.412720 729.104980,675.453735 
	C730.695618,675.543884 732.223938,676.733032 733.520508,678.077393 
	C733.791504,679.817749 734.319763,680.954407 734.848022,682.090942 
	C733.094421,681.734131 731.340820,681.377319 729.210205,680.887817 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M740.031799,681.063416 
	C741.618103,680.440918 743.204346,679.818420 746.025391,678.711426 
	C743.551636,677.789673 742.376526,677.351868 740.453918,676.635498 
	C742.023132,675.915039 743.504456,674.896484 743.790710,675.162720 
	C744.960388,676.250732 745.996704,677.632996 746.619446,679.102966 
	C747.010132,680.025330 746.577515,681.296509 746.441467,683.695740 
	C744.154297,682.953125 742.196838,682.317627 740.123108,681.341553 
	C740.006836,681.001038 740.031799,681.063416 740.031799,681.063416 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M729.373474,650.036499 
	C730.803711,650.021545 731.925049,650.083191 733.128662,650.149353 
	C733.546448,651.939941 733.942688,653.638245 734.484497,655.960327 
	C732.826782,655.960327 731.283630,655.960327 729.330872,655.937500 
	C728.968994,653.980774 729.016724,652.046875 729.373474,650.036499 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M735.127014,681.817383 
	C734.319763,680.954407 733.791504,679.817749 733.433350,678.453613 
	C735.776184,678.788513 738.952026,676.154358 740.025574,680.651367 
	C740.031799,681.063416 740.006836,681.001038 740.037476,681.015747 
	C738.514099,681.201599 736.960083,681.372681 735.127014,681.817383 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M685.543335,685.742065 
	C684.970398,685.149536 684.400940,684.581848 684.514526,684.352600 
	C685.741089,681.876587 687.075134,679.453857 688.332214,677.105408 
	C691.557434,680.180664 690.727417,683.975525 685.543335,685.742065 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M722.298462,636.414795 
	C722.251404,636.569336 722.104187,636.616394 721.957031,636.663452 
	C722.037415,636.544739 722.117859,636.426025 722.298462,636.414795 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M717.973328,625.014282 
	C718.299988,624.764526 718.643799,624.495911 719.002808,624.249573 
	C719.013794,624.242065 719.182861,624.465027 719.278198,624.580627 
	C718.996521,624.689331 718.714844,624.798035 718.201660,624.972046 
	C717.970215,625.037354 717.992737,624.998779 717.973328,625.014282 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M734.287720,663.964233 
	C734.235962,663.484497 734.452026,662.957275 734.738831,662.471863 
	C734.767212,662.423950 735.228882,662.632019 735.489502,662.721252 
	C735.184814,663.123657 734.880066,663.526123 734.287720,663.964233 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M712.667297,700.797180 
	C712.829224,701.468689 712.707764,702.042542 712.342651,702.788269 
	C708.828918,705.226440 705.480530,706.781982 701.423401,703.811279 
	C701.423401,702.362000 701.423401,700.610168 701.423401,698.089966 
	C702.288147,699.153625 702.787354,699.767639 703.220093,700.299927 
	C705.192139,699.527405 707.197205,698.741882 709.577148,698.004395 
	C710.762634,698.934814 711.573242,699.817139 712.667297,700.797180 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M688.178955,588.670044 
	C687.031921,589.249634 685.855774,589.492188 684.346436,589.868286 
	C683.857056,585.167786 683.700867,580.333618 683.863647,575.220459 
	C685.365234,575.113586 686.547852,575.285706 686.591431,575.292053 
	C686.885498,579.573853 687.125244,583.064575 687.658691,586.775879 
	C688.018250,587.442017 688.084045,587.887512 688.178955,588.670044 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M690.455078,585.679321 
	C689.883972,583.656494 689.675781,581.738525 689.468689,579.830139 
	C693.952820,580.352539 694.150391,580.630554 693.059326,585.292480 
	C692.300537,585.673218 691.559204,585.728638 690.455078,585.679321 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M696.353760,586.255005 
	C696.975769,585.986877 697.605591,586.001099 698.551636,585.998413 
	C698.032471,586.166809 697.197083,586.352051 696.353760,586.255005 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M759.751343,696.844238 
	C758.397339,697.634827 756.752930,698.192627 754.982666,698.474365 
	C755.398682,696.549622 755.940552,694.900940 756.206055,693.038574 
	C756.515564,692.277954 757.101318,691.731140 757.987549,691.106201 
	C758.679016,692.889282 759.070007,694.750427 759.751343,696.844238 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M824.385010,628.116821 
	C832.390747,627.681091 840.353210,627.681091 848.315735,627.681091 
	C848.333618,627.971558 848.351562,628.262085 848.369507,628.552551 
	C840.360229,628.552551 832.350952,628.552551 824.385010,628.116821 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M814.005737,637.005737 
	C812.259338,636.326965 810.513000,635.648193 808.349365,634.963379 
	C808.028625,634.472900 808.125061,633.988464 808.669128,633.064209 
	C809.477600,632.236084 809.838440,631.847778 810.199219,631.459473 
	C811.394409,632.295715 812.589478,633.131897 813.978821,634.104004 
	C815.201721,633.022095 816.292419,632.057068 817.656250,630.877502 
	C818.717773,632.181641 819.506287,633.700317 820.339233,635.537781 
	C818.533813,636.200806 816.683838,636.545044 814.416992,636.944702 
	C814.000000,637.000000 814.005737,637.005737 814.005737,637.005737 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M848.202209,633.951233 
	C848.160217,633.525024 848.118225,633.098755 848.109314,632.352539 
	C848.322693,632.002380 848.503052,631.972229 848.948975,631.867493 
	C850.617859,632.042969 852.021179,632.293030 852.917725,632.916748 
	C851.007996,633.510681 849.605103,633.730957 848.202209,633.951233 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M848.133118,634.187744 
	C849.605103,633.730957 851.007996,633.510681 852.755859,633.169250 
	C853.240784,633.590515 853.380615,634.132874 853.760132,634.842163 
	C852.147095,635.166321 850.294373,635.323486 848.201294,635.193481 
	C847.960938,634.906189 848.064026,634.424255 848.133118,634.187744 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M809.884399,631.343140 
	C809.838440,631.847778 809.477600,632.236084 808.892029,632.748596 
	C807.737000,632.810913 806.806763,632.748962 805.876465,632.687073 
	C805.838196,632.356689 805.799866,632.026367 805.761597,631.695984 
	C807.030945,631.539612 808.300293,631.383179 809.884399,631.343140 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M809.950195,674.946228 
	C810.549438,676.818787 811.148621,678.691284 811.844727,680.943848 
	C810.037109,680.523987 808.132507,679.724060 805.902832,678.838318 
	C804.808472,677.052002 804.039307,675.351562 803.149170,673.315186 
	C803.019775,672.534058 803.011475,672.088928 802.983765,671.309326 
	C803.552673,669.854614 804.140991,668.734314 804.901611,667.349976 
	C805.479553,667.065125 805.885132,667.044250 806.885742,666.993042 
	C807.676025,666.968140 807.871338,666.973511 808.093384,667.357788 
	C808.101379,670.119385 808.082581,672.502136 808.070984,675.197815 
	C808.472778,675.860413 808.867310,676.210022 809.261902,676.559631 
	C809.493164,676.020508 809.724365,675.481445 809.965576,674.948242 
	C809.975525,674.954224 809.950195,674.946228 809.950195,674.946228 
M807.688110,678.695862 
	C807.465515,678.685425 807.242920,678.674927 807.020325,678.664490 
	C807.126404,678.777588 807.232544,678.890686 807.688110,678.695862 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M847.656738,642.954163 
	C850.568176,645.199280 853.077271,647.459839 855.871521,649.977356 
	C847.914429,648.939575 839.544617,651.992310 831.336670,648.336975 
	C830.564636,647.993103 829.454102,648.409424 828.260132,648.755188 
	C827.654297,647.258789 827.289124,645.480103 827.316711,643.290527 
	C832.703491,644.586853 837.719238,646.530212 842.100342,641.558899 
	C843.818359,642.029114 845.536438,642.499329 847.656738,642.954163 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M842.124939,641.240967 
	C837.719238,646.530212 832.703491,644.586853 827.290894,642.875732 
	C823.679138,640.466858 824.038452,638.318054 827.846863,637.664734 
	C830.832275,637.152710 833.297241,632.531433 836.552429,636.648193 
	C833.895264,637.291199 831.167480,637.453064 832.549561,640.884521 
	C832.895264,641.742981 834.857910,641.950073 836.077393,642.456543 
	C836.368225,640.646118 836.659058,638.835754 836.949951,637.025330 
	C838.399109,639.037170 840.908447,632.799805 842.044434,638.023560 
	C842.115845,639.223694 842.132690,640.073364 842.124939,641.240967 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M826.590332,686.107056 
	C826.977234,687.746338 827.003967,689.451111 827.021362,691.583740 
	C823.681213,690.476318 824.669678,688.362793 826.590332,686.107056 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M821.184265,649.969482 
	C821.105652,649.785950 821.259216,649.570618 821.627502,649.306641 
	C821.758728,649.518127 821.616821,649.744751 821.184265,649.969482 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M821.825684,652.655518 
	C821.596680,652.807251 821.357727,652.637024 821.089111,652.161133 
	C821.335022,651.964844 821.587158,652.124268 821.825684,652.655518 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M814.903564,662.344360 
	C813.200928,662.988098 811.544983,663.279053 809.635498,663.796814 
	C806.660522,661.840149 808.040283,659.682495 810.099426,658.648071 
	C813.019348,657.181152 814.470947,659.195618 814.903564,662.344360 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M808.066650,666.978943 
	C807.871338,666.973511 807.676025,666.968140 807.187622,666.951538 
	C807.210449,666.377136 807.526428,665.813843 808.183105,665.346313 
	C808.523743,665.442139 808.508179,665.789978 808.385803,666.082764 
	C808.197815,666.576660 808.132263,666.777832 808.066650,666.978943 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M808.508179,665.789978 
	C808.508179,665.789978 808.523743,665.442139 808.514282,665.267822 
	C808.504822,665.093567 808.446838,665.076660 808.417542,665.084656 
	C808.388245,665.092651 808.400696,665.461548 808.446777,665.653809 
	C808.492920,665.846130 808.508179,665.789978 808.508179,665.789978 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M813.997070,636.997131 
	C814.158386,637.630676 814.665161,638.478516 814.426025,638.865906 
	C813.531494,640.315125 812.582458,641.888306 811.239807,642.815369 
	C810.786743,643.128235 809.103577,641.659485 807.982544,641.004944 
	C809.047180,639.657104 810.033936,638.233032 811.228760,637.012756 
	C811.545654,636.689026 812.502319,636.991882 813.585510,637.006409 
	C814.005737,637.005737 814.000000,637.000000 813.997070,636.997131 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M937.982239,674.993469 
	C937.694336,675.200195 937.406372,675.406921 936.630188,675.853821 
	C935.039062,676.347229 933.936218,676.600525 932.833374,676.853760 
	C933.744202,674.443359 930.368774,670.565552 935.666870,669.777588 
	C937.078064,670.473633 938.158325,670.935791 939.019897,671.973450 
	C938.528259,673.363770 938.255249,674.178589 937.982239,674.993469 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M932.784058,676.972412 
	C933.936218,676.600525 935.039062,676.347229 936.575195,676.118042 
	C936.404419,677.895386 935.800354,679.648621 935.145508,681.762451 
	C934.592285,682.387390 934.089783,682.651855 933.300171,682.917053 
	C932.880981,681.419800 932.749023,679.921814 932.342529,677.979248 
	C932.068054,677.534546 932.233276,677.168030 932.233276,677.168030 
	C932.407410,677.188110 932.574585,677.162415 932.784058,676.972412 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M931.810181,677.110596 
	C932.233276,677.168030 932.068054,677.534546 932.055786,677.735107 
	C931.255859,677.955078 930.468323,677.974426 929.294189,677.914856 
	C928.907593,677.835938 928.932495,677.466248 928.882263,677.288269 
	C929.683716,677.091309 930.535400,677.072266 931.810181,677.110596 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M887.229736,633.716919 
	C888.596558,633.244080 889.862305,633.150452 891.512390,633.095825 
	C892.218506,633.716797 892.867188,634.339905 892.800110,634.873047 
	C892.607422,636.406067 892.397461,638.068848 891.625732,639.333557 
	C891.307983,639.854187 888.740784,639.849792 888.564392,639.462036 
	C887.808167,637.799683 887.561218,635.905640 887.229736,633.716919 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M865.078125,638.800537 
	C864.205872,639.475769 863.338074,639.939758 862.412842,640.199036 
	C862.064758,640.296570 861.583740,639.919861 859.849792,639.257996 
	C862.002991,638.378113 863.132996,637.916382 864.611572,637.692200 
	C864.993469,638.122498 865.026611,638.315186 865.078125,638.800537 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M869.021362,639.026062 
	C868.567932,638.808044 868.093689,638.565247 867.619385,638.322510 
	C867.823730,638.097778 868.028015,637.873047 868.232300,637.648315 
	C868.485840,637.930664 868.739380,638.213074 869.021790,638.768494 
	C869.050598,639.041504 869.000427,639.001343 869.021362,639.026062 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M893.342957,654.167664 
	C893.743958,655.553711 893.803223,656.802795 893.822510,658.425049 
	C891.842529,659.143005 889.902405,659.487732 887.591797,659.803833 
	C886.718689,653.001953 887.649414,652.077026 893.342957,654.167664 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M851.383301,642.668396 
	C850.876343,642.982849 850.198486,642.991943 849.007996,642.991455 
	C849.401062,642.775574 850.306702,642.569275 851.383301,642.668396 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M693.989563,576.992737 
	C695.218689,576.780396 696.724731,576.033752 697.606995,576.490845 
	C698.712708,577.063782 699.306763,578.624390 700.121643,579.758728 
	C699.777954,580.002502 699.434204,580.246216 699.090515,580.489990 
	C697.628296,579.344482 696.166138,578.198914 694.352112,577.026855 
	C694.000305,577.000366 693.989563,576.992737 693.989563,576.992737 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M694.005249,577.005493 
	C693.778931,577.540039 693.547668,578.069397 693.316345,578.598816 
	C693.105835,578.492188 692.895264,578.385498 692.684814,578.278870 
	C692.960449,577.928284 693.236084,577.577637 693.750610,577.109863 
	C693.989563,576.992737 694.000305,577.000366 694.005249,577.005493 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1542.210693,842.238403 
	C1543.705078,841.882629 1545.422852,841.813232 1547.559814,841.847290 
	C1547.849731,843.076172 1547.720093,844.201416 1547.601685,845.230774 
	C1545.773071,844.273193 1544.103516,843.398926 1542.210693,842.238403 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1540.137695,841.244019 
	C1540.626221,841.089661 1541.231812,841.227966 1541.943481,841.674561 
	C1541.451294,841.834106 1540.853027,841.685364 1540.137695,841.244019 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1611.509521,841.751709 
	C1613.320801,841.213440 1615.145508,840.972473 1616.949097,841.082214 
	C1617.285156,841.102661 1617.535400,842.531555 1617.912598,843.654907 
	C1618.000000,843.999390 1618.007568,843.993042 1617.644775,843.935791 
	C1616.185059,845.255188 1615.087891,846.631897 1613.995361,848.004272 
	C1614.000000,848.000000 1614.009033,847.989746 1613.717285,847.993774 
	C1613.291992,848.324951 1613.158325,848.652039 1613.039062,848.951172 
	C1613.053589,848.923279 1613.081055,848.866699 1613.081055,848.866699 
	C1609.160889,847.428833 1611.997314,844.411743 1611.509521,841.751709 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1617.996094,844.003418 
	C1618.764404,843.836060 1619.536865,843.664734 1620.309570,843.493225 
	C1621.689575,850.321228 1621.129272,850.986389 1613.720459,850.807251 
	C1613.329834,850.322998 1613.350586,849.888367 1613.226196,849.160217 
	C1613.081055,848.866699 1613.053589,848.923279 1613.357300,849.025757 
	C1613.776978,848.748718 1613.892944,848.369202 1614.009033,847.989746 
	C1614.009033,847.989746 1614.000000,848.000000 1614.357910,848.096313 
	C1615.812988,846.792786 1616.910278,845.392944 1618.007568,843.993042 
	C1618.007568,843.993042 1618.000000,843.999390 1617.996094,844.003418 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1214.989746,772.292236 
	C1216.647095,772.755310 1218.285645,773.530029 1220.001465,774.583130 
	C1218.592896,774.847168 1217.107300,774.832825 1215.206055,774.636841 
	C1214.850708,773.838135 1214.910889,773.221069 1214.989746,772.292236 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1919.000000,743.999817 
	C1919.000977,744.182922 1919.001953,744.366089 1919.049805,744.820801 
	C1919.096680,757.806702 1919.096680,770.521057 1919.096680,784.033813 
	C1916.299438,784.033813 1913.864014,784.260620 1911.487915,783.981812 
	C1907.725464,783.540344 1906.549805,785.572510 1906.149780,788.759094 
	C1905.842529,791.205566 1905.383057,793.632996 1904.899658,796.458679 
	C1904.505615,797.316956 1904.203369,797.785461 1903.840088,798.536804 
	C1900.387451,801.612976 1898.199463,806.220703 1892.482666,805.969727 
	C1891.772583,805.127686 1891.433228,804.383667 1891.061035,803.330322 
	C1890.364990,801.571716 1889.702026,800.122559 1889.005127,798.346313 
	C1889.124390,792.527771 1890.403198,787.052490 1886.886841,781.810486 
	C1885.591187,779.879089 1886.065308,776.640442 1886.044556,773.995605 
	C1885.971313,764.665833 1886.033447,755.335022 1886.060303,745.542114 
	C1896.459595,744.818420 1906.843018,744.557312 1917.844482,744.114990 
	C1918.641968,743.955811 1918.821045,743.977844 1919.000000,743.999817 
M1900.209473,769.215027 
	C1901.994263,768.522034 1903.779053,767.828979 1905.563721,767.135986 
	C1905.543579,766.792053 1905.523315,766.448181 1905.503174,766.104248 
	C1903.431274,766.247375 1901.359375,766.390442 1899.287354,766.533569 
	C1899.507812,767.701172 1899.728149,768.868774 1899.641235,770.688660 
	C1899.856445,772.290466 1899.813110,775.160461 1900.333130,775.266479 
	C1902.217651,775.650452 1904.351807,775.306763 1906.239380,774.734741 
	C1906.591797,774.627930 1906.241333,772.201599 1906.215210,770.845642 
	C1904.519165,771.019958 1902.823120,771.194214 1900.991821,771.382385 
	C1900.895386,771.232422 1900.480713,770.587708 1900.209473,769.215027 
M1904.418823,764.007263 
	C1905.164062,762.490601 1906.539429,760.964417 1906.516724,759.459351 
	C1906.475586,756.744324 1905.598267,754.041870 1905.064453,751.304382 
	C1898.697998,750.786682 1898.713257,750.784668 1899.611816,757.547302 
	C1899.691284,758.144470 1899.444946,758.804626 1899.270386,759.412720 
	C1898.115356,763.434692 1899.463501,765.072144 1904.418823,764.007263 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1884.080811,787.032837 
	C1883.110352,786.899475 1882.482788,786.759521 1881.855103,786.619629 
	C1886.115356,782.992798 1882.303589,778.578125 1883.003174,774.591187 
	C1883.382935,772.427673 1883.401367,770.200806 1883.582764,768.002502 
	C1884.045044,768.019531 1884.507324,768.036499 1884.969604,768.053528 
	C1884.969604,773.171570 1885.320435,778.332336 1884.705933,783.375549 
	C1884.564575,784.535889 1888.599731,786.422913 1884.080811,787.032837 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1866.099121,818.915466 
	C1860.799561,818.206116 1864.753662,814.173462 1863.710571,811.578674 
	C1865.635498,812.461975 1869.344727,810.248962 1869.822388,814.673340 
	C1870.001465,815.008423 1870.038208,814.980530 1869.682861,814.837036 
	C1868.214844,816.134155 1867.102417,817.574829 1866.016113,818.989258 
	C1866.042358,818.963074 1866.099121,818.915466 1866.099121,818.915466 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1866.175171,821.006592 
	C1865.758423,820.549866 1865.809326,820.079956 1865.979736,819.262756 
	C1866.099121,818.915466 1866.042358,818.963074 1866.360718,819.019653 
	C1867.798828,817.711060 1868.918579,816.345825 1870.038208,814.980591 
	C1870.038208,814.980530 1870.001465,815.008423 1869.985962,815.025635 
	C1875.027710,814.621521 1871.896729,818.546082 1873.624146,820.993469 
	C1870.740601,820.993469 1868.691772,820.993469 1866.175171,821.006592 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1799.010742,856.011475 
	C1798.816650,851.793579 1801.413940,853.144409 1803.455688,853.388977 
	C1803.622559,853.823669 1803.789551,854.258362 1803.956421,854.693054 
	C1802.563354,855.129639 1801.170288,855.566284 1799.377197,855.990234 
	C1798.977295,855.977661 1799.000000,856.000000 1799.010742,856.011475 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1826.588623,813.486084 
	C1826.631104,813.540649 1826.546021,813.431519 1826.588623,813.486084 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1935.318848,846.430176 
	C1934.658081,846.221252 1934.349121,845.915955 1933.992920,845.237305 
	C1935.061157,844.964783 1936.176758,845.065552 1938.118042,845.240906 
	C1936.841431,845.811035 1936.256104,846.072449 1935.318848,846.430176 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1901.341309,863.307861 
	C1900.779175,862.304749 1900.518188,861.084839 1900.645264,859.667908 
	C1902.250854,859.608948 1903.468384,859.747070 1905.847534,860.017029 
	C1903.873047,861.460388 1902.757690,862.275696 1901.341309,863.307861 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1901.131104,858.296387 
	C1900.845215,858.144226 1900.590210,857.893127 1900.335205,857.641968 
	C1900.590332,857.619934 1900.845459,857.597900 1901.221802,857.835938 
	C1901.343018,858.096069 1901.162109,858.197449 1901.131104,858.296387 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1636.788330,730.148804 
	C1637.956177,729.621643 1639.161865,729.457825 1640.707642,729.421875 
	C1641.047607,729.549744 1641.039551,729.570740 1641.048218,730.038452 
	C1640.753174,733.009277 1641.748657,734.116455 1644.374634,734.014709 
	C1648.649414,733.848999 1652.932739,733.902344 1657.212402,733.863220 
	C1657.487915,733.411072 1657.763428,732.958923 1658.109985,732.235046 
	C1661.238037,731.986938 1664.314575,731.819214 1667.342896,732.125488 
	C1668.610962,732.253845 1669.777222,733.387756 1671.027466,734.464600 
	C1658.693237,734.864624 1646.321167,734.864624 1633.949219,734.864624 
	C1633.926880,734.692871 1633.904663,734.521057 1633.882446,734.349304 
	C1635.269653,734.138428 1636.656860,733.927490 1637.973755,733.727295 
	C1637.569702,732.595337 1637.197876,731.553711 1636.788330,730.148804 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1604.446777,729.505005 
	C1610.149414,729.377625 1615.554443,729.347656 1621.362305,729.353882 
	C1621.842407,730.338196 1621.919556,731.286499 1621.938477,732.588379 
	C1615.911377,732.960205 1609.942627,732.978333 1603.521484,732.986633 
	C1603.428955,731.851990 1603.789062,730.727173 1604.446777,729.505005 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1623.256348,739.076416 
	C1622.946045,738.883301 1622.941772,738.707886 1622.913818,738.270325 
	C1623.538452,738.018860 1624.186646,738.029602 1624.834839,738.040283 
	C1624.410645,738.391602 1623.986450,738.742859 1623.256348,739.076416 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1627.747070,737.997681 
	C1627.530029,738.203918 1627.318359,738.205872 1627.106567,738.207764 
	C1627.110962,738.071594 1627.096069,737.821655 1627.122559,737.817200 
	C1627.328003,737.782471 1627.541260,737.795288 1627.747070,737.997681 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1572.014648,718.013855 
	C1572.832642,717.232727 1573.621338,715.768982 1574.448486,715.746887 
	C1581.388916,715.562134 1588.336182,715.641785 1595.470947,715.641785 
	C1594.602295,717.732971 1593.661621,719.997131 1592.860229,722.632874 
	C1592.999390,723.004517 1593.028442,722.976685 1592.704346,722.906738 
	C1592.243530,723.232544 1592.106934,723.628296 1591.983154,724.010620 
	C1591.995972,723.997131 1592.017212,723.966614 1591.728516,724.038025 
	C1590.959351,724.738464 1590.479004,725.367432 1589.998535,725.996460 
	C1589.813232,726.003601 1589.627808,726.010803 1588.736084,726.036499 
	C1585.378784,726.080627 1582.727783,726.106140 1580.076782,726.131714 
	C1579.883179,726.107910 1579.689575,726.084106 1578.890869,726.018188 
	C1577.183350,724.970093 1576.080811,723.964111 1574.978394,722.958191 
	C1574.978394,722.958191 1575.004639,722.995911 1575.004150,722.666870 
	C1573.997437,720.881042 1572.991211,719.424316 1571.985107,717.967651 
	C1571.984985,717.967651 1572.003418,717.999451 1572.014648,718.013855 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1598.893921,715.149658 
	C1599.627563,715.998901 1600.036865,716.972229 1601.005493,719.275024 
	C1601.005493,713.926025 1601.005493,709.936707 1601.277710,705.359009 
	C1602.044678,704.508545 1602.539429,704.246521 1603.151489,704.192261 
	C1603.463623,704.663208 1603.712769,704.855896 1603.901367,705.246094 
	C1603.676392,706.101990 1603.566528,706.689941 1603.456665,707.277893 
	C1603.700073,707.329346 1603.943359,707.380859 1604.186768,707.432312 
	C1604.435791,706.585449 1604.684814,705.738525 1605.227051,704.772705 
	C1606.047852,706.231018 1606.792114,707.773193 1607.054810,709.393433 
	C1607.518188,712.253235 1607.688354,715.160583 1607.981201,718.047974 
	C1607.985962,718.231506 1607.990601,718.415100 1607.583130,718.899475 
	C1602.007080,718.343201 1603.320557,722.484619 1602.632568,725.081421 
	C1602.632568,725.081421 1602.421997,725.122314 1602.213501,724.948120 
	C1601.895996,724.716431 1601.786987,724.658936 1601.677979,724.601440 
	C1601.812622,724.804565 1601.947266,725.007751 1602.081909,725.210876 
	C1602.086060,725.592468 1602.090332,725.974060 1601.785767,726.664062 
	C1600.797729,727.165955 1600.118286,727.359497 1599.438965,727.553101 
	C1599.170044,727.616455 1598.901001,727.679932 1598.327637,727.366577 
	C1597.655518,724.263428 1597.287842,721.537170 1596.920166,718.810852 
	C1597.469971,717.631836 1598.019897,716.452759 1598.893921,715.149658 
M1604.181763,716.108521 
	C1604.636963,716.466370 1605.092163,716.824219 1605.547363,717.182129 
	C1605.608765,716.421936 1605.670166,715.661743 1605.731567,714.901611 
	C1605.193726,715.097595 1604.655762,715.293518 1604.181763,716.108521 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1602.717407,725.441650 
	C1603.320557,722.484619 1602.007080,718.343201 1607.586182,719.174927 
	C1609.408081,719.408569 1610.814697,719.667603 1612.612061,720.231445 
	C1613.021606,720.705383 1613.040649,720.874451 1613.059692,721.043518 
	C1615.085083,722.007751 1617.110596,722.971924 1619.746826,724.226929 
	C1617.479858,725.221558 1615.284302,726.184814 1613.011475,727.517395 
	C1610.596802,727.950439 1608.259399,728.014099 1605.485352,728.116943 
	C1604.299683,727.371277 1603.551025,726.586548 1602.717407,725.441650 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1596.603149,718.940063 
	C1597.287842,721.537170 1597.655518,724.263428 1597.915039,727.407104 
	C1595.685913,727.747742 1593.564941,727.670959 1591.482422,727.076660 
	C1591.686401,725.695007 1591.851807,724.830811 1592.017090,723.966614 
	C1592.017212,723.966614 1591.995972,723.997131 1592.308105,724.082397 
	C1592.756226,723.770691 1592.892334,723.373718 1593.028442,722.976685 
	C1593.028442,722.976685 1592.999390,723.004517 1592.986816,723.020142 
	C1594.078125,721.713623 1595.182129,720.391418 1596.603149,718.940063 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1580.363159,726.383484 
	C1582.727783,726.106140 1585.378784,726.080627 1588.467041,726.107971 
	C1586.152588,726.318970 1583.401123,726.477112 1580.363159,726.383484 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1574.881226,723.299927 
	C1576.080811,723.964111 1577.183350,724.970093 1578.602905,726.069580 
	C1578.442505,726.427185 1577.964966,726.691223 1577.204346,726.995972 
	C1576.208862,725.904968 1575.496460,724.773315 1574.881226,723.299927 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1591.728516,724.038025 
	C1591.851807,724.830811 1591.686401,725.695007 1591.240479,726.769531 
	C1590.644775,726.880432 1590.387939,726.698792 1590.093750,726.215698 
	C1590.479004,725.367432 1590.959351,724.738464 1591.728516,724.038025 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1599.716919,727.655701 
	C1600.118286,727.359497 1600.797729,727.165955 1601.769043,726.949829 
	C1601.372314,727.204285 1600.683594,727.481262 1599.716919,727.655701 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1500.005859,692.539612 
	C1500.373779,691.319824 1500.765869,690.434326 1501.076904,689.229370 
	C1500.995850,688.910034 1500.934204,688.956055 1501.280029,689.070312 
	C1502.249390,689.015625 1502.873047,688.846680 1503.496582,688.677795 
	C1503.479736,688.475952 1503.462891,688.274109 1503.446045,688.072327 
	C1502.594238,687.996033 1501.742432,687.919800 1500.941284,687.477905 
	C1502.854492,686.688904 1504.717163,686.265442 1506.863159,685.777649 
	C1506.941040,685.026245 1507.053101,683.943970 1507.165283,682.861694 
	C1509.289307,682.468811 1511.413452,682.075867 1513.537598,681.682922 
	C1513.528076,681.604614 1513.518555,681.526306 1513.509155,681.447998 
	C1516.215576,681.252625 1518.922119,681.057251 1521.942261,680.724426 
	C1522.457520,682.481018 1522.659058,684.375122 1522.796631,686.744934 
	C1518.637451,686.744080 1514.806763,686.381714 1511.999634,690.719727 
	C1511.090942,692.124084 1508.259766,692.378235 1506.247925,692.945923 
	C1504.543701,693.426575 1502.752930,693.600342 1500.630615,694.052490 
	C1500.183838,693.754639 1500.106812,693.314270 1500.005859,692.539612 
M1505.833862,688.946594 
	C1505.766113,689.092834 1505.663940,689.234192 1505.642578,689.386841 
	C1505.627441,689.495300 1505.733887,689.724609 1505.764771,689.720947 
	C1507.066528,689.566345 1508.365967,689.391479 1509.665527,689.219177 
	C1509.578003,688.798279 1509.490356,688.377441 1509.402832,687.956604 
	C1508.417114,688.176636 1507.431396,688.396729 1505.833862,688.946594 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1551.935913,708.194275 
	C1552.427490,707.191833 1552.798950,706.475403 1553.092407,705.428711 
	C1553.409790,705.088440 1553.805054,705.078430 1554.361084,705.420410 
	C1552.547485,709.131836 1555.981567,708.882751 1557.353638,710.320801 
	C1559.923584,711.112793 1562.490112,711.595703 1565.056641,712.078552 
	C1565.056641,712.078552 1565.044922,712.578186 1565.018188,712.826782 
	C1564.793823,713.346069 1564.596313,713.616760 1564.166992,714.005615 
	C1561.686890,714.835388 1559.423218,716.309937 1557.235840,713.988831 
	C1555.808105,715.518433 1554.379272,717.046997 1552.954712,718.579590 
	C1552.852417,718.689453 1552.780640,718.827698 1552.694824,718.952820 
	C1552.514038,718.698242 1552.333130,718.443726 1552.062500,717.709717 
	C1551.955200,716.924438 1551.977295,716.621521 1552.150146,715.893799 
	C1551.831665,713.897583 1551.401733,712.329041 1550.971924,710.760437 
	C1551.253174,710.000427 1551.534424,709.240356 1551.935913,708.194275 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1534.374023,715.917480 
	C1534.536377,714.879822 1535.089722,713.798767 1535.793579,712.443726 
	C1536.103638,712.107239 1536.263062,712.044861 1536.666748,711.902588 
	C1537.482910,712.999878 1538.054688,714.177063 1538.833496,715.147095 
	C1539.840088,714.724915 1540.639771,714.509949 1541.742676,714.195862 
	C1542.280640,714.373962 1542.515259,714.651184 1542.963989,715.219360 
	C1543.178101,715.510315 1543.218140,715.877075 1543.018799,715.990173 
	C1543.197144,716.636780 1543.574829,717.170349 1543.952393,717.703857 
	C1540.890015,717.093933 1537.827515,716.484070 1534.374023,715.917480 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1544.370972,717.800293 
	C1543.574829,717.170349 1543.197144,716.636780 1543.454102,716.020874 
	C1546.738525,716.066101 1549.388550,716.193787 1552.038574,716.321411 
	C1551.977295,716.621521 1551.955200,716.924438 1552.012695,717.453979 
	C1550.509521,717.758362 1548.966187,717.839111 1546.962158,717.898560 
	C1546.501343,717.877258 1546.502930,717.849976 1546.502930,717.849976 
	C1545.931763,717.865540 1545.360718,717.881165 1544.370972,717.800293 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1546.369385,718.076416 
	C1546.502930,717.849976 1546.501343,717.877258 1546.498535,717.863037 
	C1546.644531,718.408203 1546.793213,718.967712 1546.941895,719.527222 
	C1546.630127,719.535278 1546.318481,719.543335 1546.006714,719.551392 
	C1546.083130,719.135193 1546.159546,718.719055 1546.369385,718.076416 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1571.809204,718.312744 
	C1572.991211,719.424316 1573.997437,720.881042 1575.018799,722.684509 
	C1573.900269,721.573425 1572.766846,720.115662 1571.809204,718.312744 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1460.969604,696.025818 
	C1464.882812,696.301758 1462.719482,699.607056 1463.844238,701.689087 
	C1464.051270,702.001770 1463.987305,702.476257 1463.974121,702.715210 
	C1461.609497,703.412964 1459.258301,703.871704 1455.987305,704.509949 
	C1456.987671,702.590759 1457.489014,701.629150 1457.997437,700.336426 
	C1458.004517,700.005310 1457.979248,700.038818 1458.292236,699.909973 
	C1458.742676,699.174011 1458.880127,698.566895 1459.005615,697.977295 
	C1458.993774,697.994812 1458.964844,698.025574 1459.309570,698.076477 
	C1459.776489,697.741638 1459.898682,697.355896 1460.009277,696.983765 
	C1459.997681,696.997314 1459.970703,697.020874 1460.320068,697.104614 
	C1460.786499,696.782715 1460.903687,696.377014 1461.008789,695.985718 
	C1460.996826,696.000061 1460.969604,696.025818 1460.969604,696.025818 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1795.105835,785.395142 
	C1795.421997,781.599548 1795.773560,778.171143 1796.147827,774.273315 
	C1796.562378,773.803406 1796.954346,773.803040 1797.637207,773.849915 
	C1797.980469,777.309570 1798.131836,780.724548 1798.045166,784.133423 
	C1797.998657,785.966797 1797.754883,788.171143 1795.105835,785.395142 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1801.694946,773.923950 
	C1802.742065,777.579407 1803.537964,781.226929 1804.730225,784.916138 
	C1803.388550,788.327209 1801.551025,787.885864 1800.958008,784.559021 
	C1800.400635,781.432068 1800.906982,778.115479 1800.954346,774.473755 
	C1801.123657,774.015442 1801.283569,773.965759 1801.694946,773.923950 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1771.760986,771.180176 
	C1772.384521,770.689392 1773.115112,770.702881 1773.845581,770.716370 
	C1773.824219,771.284424 1773.802979,771.852417 1773.781616,772.420471 
	C1773.143799,772.175110 1772.505859,771.929749 1771.760986,771.180176 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1769.156128,716.043213 
	C1765.485229,717.048828 1764.595581,715.348938 1764.505005,711.925049 
	C1764.417725,708.622437 1765.492188,707.456299 1768.755371,707.592773 
	C1773.329468,707.783875 1777.917358,707.640930 1782.772095,708.244080 
	C1779.751099,710.722107 1781.484375,718.199707 1774.238037,715.067688 
	C1773.637451,712.831177 1773.000610,711.200684 1772.363770,709.570190 
	C1771.360474,710.773315 1770.191162,711.880432 1769.419678,713.217224 
	C1768.989746,713.961853 1769.222168,715.088623 1769.156128,716.043213 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1769.437744,716.138794 
	C1769.222168,715.088623 1768.989746,713.961853 1769.419678,713.217224 
	C1770.191162,711.880432 1771.360474,710.773315 1772.363770,709.570190 
	C1773.000610,711.200684 1773.637451,712.831177 1774.035400,714.709229 
	C1772.437500,715.382568 1771.078369,715.808472 1769.437744,716.138794 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1917.869751,743.157715 
	C1907.582886,743.089355 1897.295898,743.020996 1886.549561,742.491211 
	C1887.386475,741.406311 1888.651855,740.336548 1889.985107,740.244019 
	C1893.775879,739.980957 1897.613770,739.865234 1901.396484,740.160950 
	C1905.873169,740.510925 1909.303345,739.828735 1909.463013,734.443848 
	C1912.724365,734.443848 1915.615112,734.443848 1918.749512,734.443848 
	C1918.946655,737.550781 1919.122681,740.326843 1918.941895,743.126953 
	C1918.346436,743.153259 1918.108032,743.155518 1917.869751,743.157715 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1919.298828,743.102905 
	C1919.122681,740.326843 1918.946655,737.550781 1918.749512,734.443848 
	C1915.615112,734.443848 1912.724365,734.443848 1909.463013,734.443848 
	C1909.303345,739.828735 1905.873169,740.510925 1901.396484,740.160950 
	C1897.613770,739.865234 1893.775879,739.980957 1889.985107,740.244019 
	C1888.651855,740.336548 1887.386475,741.406311 1886.090942,742.423279 
	C1885.292603,742.589783 1884.493286,742.362793 1883.370361,742.100342 
	C1882.903076,736.801331 1887.337402,738.855835 1889.562988,738.528625 
	C1894.399170,737.817566 1899.386230,738.169678 1904.305420,737.950378 
	C1905.141846,737.913147 1906.137329,737.374817 1906.726318,736.752747 
	C1907.763062,735.657349 1908.428955,733.380066 1909.451782,733.255737 
	C1912.702759,732.860291 1916.853882,732.088440 1919.092407,733.672974 
	C1920.803223,734.883850 1920.138916,739.450195 1920.059082,742.794189 
	C1919.587158,743.064453 1919.298828,743.102905 1919.298828,743.102905 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1873.483643,742.932800 
	C1875.716675,739.360840 1878.988892,741.212891 1882.520752,741.918884 
	C1879.917847,742.315247 1876.920776,742.653076 1873.483643,742.932800 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1918.941895,743.126953 
	C1919.298828,743.102905 1919.587158,743.064453 1919.728271,743.038696 
	C1919.809448,743.326599 1919.650146,743.577209 1919.195801,743.882324 
	C1918.821045,743.977844 1918.641968,743.955811 1918.192383,743.920898 
	C1917.921997,743.908020 1917.878174,743.533997 1917.874023,743.345825 
	C1918.108032,743.155518 1918.346436,743.153259 1918.941895,743.126953 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1867.340088,765.089722 
	C1867.319092,764.871277 1867.545532,764.621704 1868.033447,764.382019 
	C1868.161255,764.722473 1867.925415,764.944702 1867.340088,765.089722 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1863.951416,750.706482 
	C1864.881958,750.258972 1866.023193,750.065247 1867.164429,749.871521 
	C1867.195435,750.234436 1867.226562,750.597290 1867.257568,750.960205 
	C1866.225708,750.960205 1865.193970,750.960205 1863.951416,750.706482 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1952.981934,729.002563 
	C1953.075928,728.938171 1953.182617,728.867737 1953.142700,728.906616 
	C1952.996216,729.015930 1952.994507,728.996582 1952.981934,729.002563 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1714.999512,733.340454 
	C1715.283813,733.293152 1715.596069,733.563110 1715.953369,734.158813 
	C1715.674683,734.208984 1715.351074,733.933350 1714.999512,733.340454 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1674.584473,724.450073 
	C1678.708252,723.700562 1682.934570,723.383240 1687.626587,723.129150 
	C1688.590576,724.130432 1689.089111,725.068542 1689.843994,726.489197 
	C1686.436890,725.915588 1686.313721,733.455200 1681.376953,728.841675 
	C1682.556396,727.466370 1683.790771,726.583740 1685.025269,725.701111 
	C1684.842773,725.268311 1684.660400,724.835510 1684.477905,724.402710 
	C1682.012817,724.796021 1679.547607,725.189331 1677.082520,725.582642 
	C1677.417358,727.169739 1677.752197,728.756836 1678.087036,730.343872 
	C1677.324341,730.603821 1676.561768,730.863770 1675.477783,731.096680 
	C1674.999878,729.007202 1674.843506,726.944702 1674.584473,724.450073 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1678.343506,730.137207 
	C1677.752197,728.756836 1677.417358,727.169739 1677.082520,725.582642 
	C1679.547607,725.189331 1682.012817,724.796021 1684.477905,724.402710 
	C1684.660400,724.835510 1684.842773,725.268311 1685.025269,725.701111 
	C1683.790771,726.583740 1682.556396,727.466370 1681.116333,728.638062 
	C1680.140381,729.261536 1679.370117,729.596008 1678.343506,730.137207 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1259.748047,744.993164 
	C1259.950562,745.068237 1259.965942,745.251343 1259.994263,745.709717 
	C1259.858521,745.690369 1259.709717,745.395813 1259.748047,744.993164 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1209.989502,751.026123 
	C1212.904175,749.246277 1215.275146,745.730774 1219.558350,748.875061 
	C1220.394409,749.488708 1222.772583,748.001465 1224.782104,747.376709 
	C1226.909302,746.193604 1223.897217,753.177673 1228.232422,749.405762 
	C1229.093018,749.714050 1229.522583,750.329712 1229.952271,750.945312 
	C1228.511353,754.375916 1223.772339,756.273254 1220.299927,754.965332 
	C1219.586670,754.696655 1218.617432,755.107361 1217.291992,755.541016 
	C1214.511963,754.866394 1212.206177,753.855774 1209.907959,752.542114 
	C1209.940186,751.834717 1209.964844,751.430420 1209.989502,751.026123 
M1224.176758,751.518860 
	C1223.493408,751.528931 1222.807861,751.518433 1222.130859,751.588806 
	C1222.077515,751.594421 1222.082886,752.163818 1222.060913,752.471069 
	C1222.760742,752.435730 1223.460449,752.400330 1224.176758,751.518860 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1209.900513,752.845215 
	C1212.206177,753.855774 1214.511963,754.866394 1217.183105,755.895691 
	C1218.598389,757.948975 1217.150757,759.905762 1213.828979,760.312195 
	C1210.497559,760.719727 1207.112549,760.688293 1203.331909,760.788330 
	C1202.930176,760.175903 1202.947510,759.624268 1203.243164,758.591187 
	C1204.077026,757.729492 1204.632446,757.349365 1205.405762,756.713989 
	C1207.049194,755.254211 1208.474854,754.049683 1209.900513,752.845215 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1230.350830,750.970581 
	C1229.522583,750.329712 1229.093018,749.714050 1228.348999,749.062134 
	C1227.993530,744.930176 1230.966309,747.833191 1232.658447,747.326660 
	C1233.662964,748.610291 1234.402832,749.715698 1234.911499,750.978760 
	C1233.369995,751.089600 1232.059570,751.042725 1230.350830,750.970581 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1236.228027,755.032166 
	C1236.072021,754.273010 1236.144165,753.543091 1236.216187,752.813171 
	C1236.394897,752.814209 1236.573486,752.815247 1236.752075,752.816223 
	C1236.827637,753.376892 1236.903198,753.937561 1236.944702,754.816040 
	C1236.910767,755.133789 1236.456299,755.061401 1236.228027,755.032166 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1235.091919,748.110779 
	C1235.021973,747.918579 1235.508423,747.953918 1235.751953,747.965698 
	C1235.717651,748.085938 1235.439819,748.194458 1235.091919,748.110779 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1371.408447,739.900513 
	C1370.877197,739.539001 1370.625122,739.013611 1370.186035,738.259521 
	C1370.530640,737.861572 1371.062378,737.692444 1371.593994,737.523315 
	C1371.625244,738.261108 1371.656494,738.998901 1371.408447,739.900513 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1209.907959,752.542114 
	C1208.474854,754.049683 1207.049194,755.254211 1205.351929,756.371399 
	C1204.921631,755.610474 1204.762817,754.936768 1204.603882,754.263123 
	C1205.776611,753.653625 1206.949219,753.044128 1208.763672,752.101135 
	C1207.367065,751.996277 1206.623047,751.940491 1205.546021,751.939453 
	C1203.542847,748.069397 1204.929810,746.970093 1208.628174,748.599243 
	C1208.239258,749.180786 1207.996094,749.304626 1207.752808,749.428467 
	C1208.177246,749.610901 1208.601562,749.793396 1209.026001,749.975830 
	C1209.251221,750.198975 1209.473145,750.425354 1209.840576,750.840576 
	C1209.964844,751.430420 1209.940186,751.834717 1209.907959,752.542114 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1216.275391,742.154053 
	C1215.073486,741.420776 1214.146484,740.838013 1212.963989,740.029175 
	C1213.182495,738.682983 1213.656372,737.562805 1214.492920,736.790405 
	C1216.182251,737.783020 1217.508667,738.427917 1218.835205,739.072815 
	C1218.790405,739.299683 1218.745728,739.526489 1218.783936,740.119385 
	C1218.867065,740.485413 1218.792480,740.527161 1218.792480,740.527161 
	C1218.835571,740.795227 1218.878784,741.063293 1218.725830,741.853027 
	C1218.248291,742.528687 1217.966553,742.682617 1217.684937,742.836609 
	C1217.306763,742.659302 1216.928467,742.481934 1216.275391,742.154053 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1224.003906,740.073608 
	C1225.308594,739.647888 1226.613159,739.222107 1227.943848,738.787842 
	C1227.943848,739.877625 1227.943848,741.360840 1227.943848,742.078857 
	C1226.808594,741.678467 1225.765137,741.310425 1224.318359,740.723022 
	C1223.915039,740.503723 1224.003906,740.073608 1224.003906,740.073608 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1219.095215,738.846375 
	C1217.508667,738.427917 1216.182251,737.783020 1214.834106,736.774414 
	C1216.895020,735.978516 1219.263550,734.958252 1219.095215,738.846375 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1223.647705,739.973022 
	C1224.003906,740.073608 1223.915039,740.503723 1223.937866,740.722046 
	C1223.239746,740.999573 1222.518921,741.058716 1221.551270,740.823730 
	C1221.304565,740.529602 1221.174072,740.322937 1221.174072,740.322937 
	C1221.879883,740.172729 1222.585571,740.022583 1223.647705,739.973022 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1217.941162,743.061401 
	C1217.966553,742.682617 1218.248291,742.528687 1218.969971,742.178955 
	C1219.587036,742.345886 1219.764038,742.708557 1219.941162,743.071228 
	C1219.359863,743.142883 1218.778687,743.214600 1217.941162,743.061401 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1220.769775,740.396851 
	C1221.174072,740.322937 1221.304565,740.529602 1221.236572,740.631226 
	C1220.642700,740.584778 1220.116577,740.436707 1219.191528,740.407959 
	C1218.792480,740.527161 1218.867065,740.485413 1218.828857,740.463257 
	C1219.315552,740.450928 1219.840576,740.460815 1220.769775,740.396851 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1209.021240,749.730286 
	C1208.601562,749.793396 1208.177246,749.610901 1207.752808,749.428467 
	C1207.996094,749.304626 1208.239258,749.180786 1208.737793,749.025513 
	C1208.993042,748.994080 1209.016602,749.484741 1209.021240,749.730286 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1204.263672,754.178345 
	C1204.762817,754.936768 1204.921631,755.610474 1205.134277,756.626709 
	C1204.632446,757.349365 1204.077026,757.729492 1203.246338,758.311951 
	C1202.421997,756.854004 1198.290771,754.421875 1204.263672,754.178345 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1287.612549,705.080200 
	C1288.325928,705.889221 1288.633301,706.758179 1288.818848,707.955505 
	C1286.094360,707.993530 1283.492065,707.475952 1280.889038,707.472839 
	C1277.921265,707.469299 1276.864014,706.448120 1276.838379,703.354065 
	C1276.764771,694.479553 1277.804932,693.687561 1288.628052,695.868774 
	C1286.533569,698.682983 1284.038940,701.390442 1280.583984,705.140076 
	C1283.542114,705.140076 1285.374268,705.140076 1287.612549,705.080200 
M1281.603271,701.191040 
	C1282.972656,699.644409 1284.342163,698.097778 1286.387329,695.788025 
	C1283.890259,695.788025 1281.692017,695.159119 1280.947754,695.931458 
	C1279.748413,697.175903 1279.507812,699.344299 1278.862305,701.122437 
	C1279.599731,701.316528 1280.337280,701.510559 1281.603271,701.191040 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1258.360107,722.876099 
	C1257.905640,722.423462 1257.926270,722.034058 1257.952271,721.351929 
	C1266.260986,721.010071 1274.617188,720.414307 1282.839355,721.206970 
	C1285.738647,721.486511 1288.310547,725.161987 1291.012695,727.640015 
	C1284.019165,720.840576 1275.088623,723.337830 1266.719971,723.081787 
	C1264.092651,723.001343 1261.463379,722.985046 1258.360107,722.876099 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1310.923462,706.908630 
	C1311.486206,708.683533 1312.488892,710.483948 1312.387939,712.220276 
	C1312.349243,712.884705 1309.317993,714.263062 1308.475586,713.803528 
	C1306.844238,712.913574 1305.797729,710.951416 1305.013184,709.166870 
	C1305.966797,708.673401 1306.416138,708.446716 1307.072266,708.492920 
	C1308.493896,708.146790 1309.708740,707.527710 1310.923462,706.908630 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1310.989380,706.578369 
	C1309.708740,707.527710 1308.493896,708.146790 1307.073242,708.116516 
	C1306.841553,707.217529 1306.815918,706.967957 1306.790283,706.718445 
	C1307.306885,705.244690 1307.823364,703.770935 1309.025879,702.138489 
	C1312.796875,702.995300 1315.881836,704.010803 1318.966797,705.026245 
	C1319.043579,705.489075 1319.120361,705.951965 1319.148682,706.766479 
	C1317.578125,706.465881 1316.103394,705.520264 1314.517212,705.263550 
	C1313.444458,705.089905 1312.214722,705.885925 1310.989380,706.578369 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1320.670898,716.761475 
	C1320.575439,717.642395 1320.127441,718.636230 1319.371704,719.755737 
	C1319.481934,718.879089 1319.900146,717.876709 1320.670898,716.761475 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1324.689941,715.989441 
	C1324.783081,716.538696 1324.557861,717.081726 1324.332642,717.624756 
	C1323.911621,717.309570 1323.490723,716.994385 1323.085938,716.372437 
	C1323.525146,716.038147 1323.948364,716.010681 1324.689941,715.989441 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1306.501709,706.786377 
	C1306.815918,706.967957 1306.841553,707.217529 1306.866211,707.843628 
	C1306.416138,708.446716 1305.966797,708.673401 1305.264893,708.938843 
	C1305.019409,708.578552 1305.026367,708.179504 1305.054932,707.482788 
	C1305.455566,707.074768 1305.834351,706.964539 1306.501709,706.786377 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1318.931152,704.597900 
	C1315.881836,704.010803 1312.796875,702.995300 1309.310547,701.937744 
	C1308.911499,701.206543 1308.913818,700.517395 1308.898438,699.483948 
	C1310.823242,698.349670 1312.765747,697.559692 1315.133667,696.596802 
	C1315.626953,696.964966 1316.974976,697.971069 1318.671997,698.999634 
	C1318.979126,700.737976 1318.937378,702.453735 1318.931152,704.597900 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1667.024658,714.382935 
	C1667.320312,714.744812 1667.359497,715.108154 1667.398682,715.471558 
	C1667.137573,715.468689 1666.876343,715.465820 1666.615112,715.462952 
	C1666.666138,715.103455 1666.717163,714.743896 1667.024658,714.382935 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1898.466187,716.840088 
	C1896.097168,716.525513 1894.083252,716.083618 1891.200073,715.450928 
	C1895.083740,715.039124 1898.133789,714.715637 1901.183838,714.392212 
	C1901.276245,714.702148 1901.368652,715.012146 1901.461060,715.322083 
	C1900.581177,715.785645 1899.701172,716.249207 1898.466187,716.840088 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1885.212158,717.734619 
	C1885.826660,717.861877 1886.313354,718.148804 1886.888550,718.730591 
	C1886.976929,719.025574 1886.897827,719.106506 1886.897827,719.106506 
	C1886.330078,718.964539 1885.762451,718.822571 1885.008301,718.443970 
	C1884.822021,718.207397 1885.084595,717.894226 1885.212158,717.734619 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1896.400391,718.357666 
	C1896.517212,718.258484 1896.587158,718.600342 1896.587158,718.600342 
	C1896.587158,718.600342 1896.283447,718.456787 1896.400391,718.357666 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1887.755127,720.256775 
	C1887.511963,720.034119 1887.268677,719.811462 1886.961670,719.347656 
	C1886.897827,719.106506 1886.976929,719.025574 1887.018799,718.986084 
	C1887.394775,719.015564 1887.678711,719.174194 1887.936523,719.735291 
	C1887.960571,720.048035 1887.755127,720.256775 1887.755127,720.256775 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1888.065918,719.946167 
	C1888.352417,720.053467 1888.533691,720.262695 1888.714966,720.471924 
	C1888.482422,720.515198 1888.249878,720.558472 1887.886230,720.429260 
	C1887.755127,720.256775 1887.960571,720.048035 1888.065918,719.946167 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1892.982422,722.045471 
	C1893.092896,721.868103 1893.217285,721.654785 1893.341553,721.441467 
	C1893.331421,721.620483 1893.321289,721.799438 1893.116089,722.002136 
	C1892.921143,722.025818 1892.996216,722.009460 1892.982422,722.045471 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1839.305664,631.004456 
	C1839.425903,630.264648 1839.865601,629.510803 1840.668213,628.868530 
	C1840.562500,629.650208 1840.093750,630.320312 1839.305664,631.004456 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1842.330322,627.919556 
	C1842.101807,627.417542 1842.215454,626.805176 1842.670654,626.080322 
	C1842.899048,626.581665 1842.785767,627.195435 1842.330322,627.919556 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1843.323486,626.115601 
	C1843.134521,625.699219 1843.298462,625.378723 1843.742188,625.013062 
	C1843.906860,625.382507 1843.791626,625.797058 1843.323486,626.115601 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1841.261719,628.990234 
	C1841.095825,628.619446 1841.214233,628.208191 1841.679810,627.889038 
	C1841.866699,628.303955 1841.706421,628.626892 1841.261719,628.990234 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2214.967773,870.405151 
	C2219.585449,866.886414 2224.288086,867.982849 2229.388672,869.866821 
	C2228.522217,870.849487 2227.357910,872.537109 2225.995605,872.716248 
	C2222.701904,873.149231 2219.315430,872.878113 2215.573975,872.857178 
	C2215.121338,872.133362 2215.061279,871.443298 2214.967773,870.405151 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1948.081299,707.327026 
	C1946.921143,707.506653 1946.018311,707.438965 1945.115479,707.371277 
	C1945.238037,706.909363 1945.360596,706.447449 1945.483032,705.985535 
	C1946.434937,706.350281 1947.386719,706.715027 1948.081299,707.327026 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M2229.054932,850.725708 
	C2230.909424,853.694885 2232.564453,856.334106 2234.640869,859.645386 
	C2226.020264,859.645386 2218.215332,859.645386 2210.367188,859.645386 
	C2212.941895,849.877075 2220.166992,846.311462 2229.054932,850.725708 
M2217.498535,857.423157 
	C2220.971680,857.160156 2224.444824,856.897156 2228.042236,856.624756 
	C2227.964355,855.269531 2227.889404,853.966797 2227.783936,852.136719 
	C2225.662354,852.957458 2223.911133,853.634949 2221.827881,854.440796 
	C2221.427979,854.008484 2220.363037,852.856995 2218.452393,850.791077 
	C2217.764893,853.724915 2217.298340,855.715515 2217.498535,857.423157 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2119.108643,819.010986 
	C2119.795166,815.500000 2124.141602,814.640991 2127.457031,817.466125 
	C2129.812500,819.473267 2132.019287,821.654785 2134.578613,823.924683 
	C2138.529541,827.843628 2142.194824,831.596191 2145.912598,835.695435 
	C2145.965332,836.042053 2145.990723,836.010803 2145.558105,835.980469 
	C2141.722168,835.719482 2138.301270,835.159363 2134.920166,835.339661 
	C2130.479736,835.576538 2122.835449,830.095337 2123.011719,825.714478 
	C2123.150635,822.270386 2121.537354,820.583740 2119.108643,819.010986 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2108.759277,775.033691 
	C2108.956299,775.455750 2108.862549,775.898071 2108.394287,776.194702 
	C2108.169434,775.717224 2108.318848,775.385559 2108.759277,775.033691 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1604.016479,704.978088 
	C1603.712769,704.855896 1603.463623,704.663208 1603.406982,703.909912 
	C1604.703125,702.543335 1606.839844,701.680969 1606.852661,700.788208 
	C1606.913940,696.496399 1606.401855,692.196411 1606.093262,687.899292 
	C1606.174316,687.584351 1606.350464,687.337280 1606.828125,687.050171 
	C1607.726685,685.992371 1608.743408,685.136902 1609.052979,684.075317 
	C1610.210693,680.104858 1611.136963,676.066833 1612.263672,671.704224 
	C1612.927246,670.872009 1613.477295,670.389832 1614.108643,669.637817 
	C1616.745117,668.041443 1619.214600,666.476196 1621.900024,665.514404 
	C1622.871826,665.166504 1625.361328,666.108337 1625.457031,666.725525 
	C1626.294312,672.124939 1626.746216,677.584167 1627.386597,683.407349 
	C1628.380615,684.814697 1629.119019,686.315735 1630.263428,686.752014 
	C1631.884399,687.370117 1633.804688,687.203125 1635.785889,688.024658 
	C1636.271240,696.408386 1636.567505,704.139282 1636.863892,711.870117 
	C1633.013672,711.447205 1629.169312,710.945740 1625.308960,710.661072 
	C1624.435425,710.596619 1623.506470,711.284912 1622.301514,711.814087 
	C1622.000000,712.001465 1622.009766,712.000000 1621.986572,711.591919 
	C1622.344482,706.576233 1619.956665,704.421570 1615.489868,703.628052 
	C1617.142578,703.177734 1618.795288,702.727478 1620.447998,702.277161 
	C1620.413696,702.029480 1620.379272,701.781738 1620.344849,701.534058 
	C1618.699463,701.206177 1617.054077,700.878235 1613.992065,700.268066 
	C1616.693726,698.700623 1618.232300,697.807983 1620.537842,696.470276 
	C1617.802002,695.526306 1615.756958,694.820679 1614.045288,694.230042 
	C1613.618652,698.532593 1613.227295,702.480774 1612.807129,706.719482 
	C1615.016724,706.930969 1616.588623,706.787292 1617.873779,707.326721 
	C1618.528687,707.601685 1618.668335,709.103882 1618.610352,710.040405 
	C1616.604858,710.110535 1615.029785,710.191589 1613.033813,710.294250 
	C1613.033813,712.163879 1613.033813,713.603088 1613.033813,715.042236 
	C1613.346191,718.501160 1610.832031,717.903870 1608.389160,718.021179 
	C1607.688354,715.160583 1607.518188,712.253235 1607.054810,709.393433 
	C1606.792114,707.773193 1606.047852,706.231018 1604.997314,704.787109 
	C1604.474121,704.920471 1604.016479,704.978088 1604.016479,704.978088 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1613.342285,715.250732 
	C1613.033813,713.603088 1613.033813,712.163879 1613.033813,710.294250 
	C1615.029785,710.191589 1616.604858,710.110535 1618.647461,710.382507 
	C1620.079956,711.156982 1621.044922,711.578491 1622.009766,712.000000 
	C1622.009766,712.000000 1622.000000,712.001465 1622.000000,711.997009 
	C1622.000000,713.291382 1622.000000,714.590271 1622.000000,715.883972 
	C1624.119019,715.123047 1626.067505,714.423340 1628.016113,713.723633 
	C1623.514893,718.375366 1618.642456,718.731628 1613.342285,715.250732 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M1637.104736,712.144409 
	C1636.567505,704.139282 1636.271240,696.408386 1636.040405,688.344910 
	C1637.016968,688.086853 1638.725342,688.155029 1638.725952,688.236938 
	C1638.790039,696.227173 1638.728271,704.218445 1638.612671,712.208801 
	C1638.611694,712.284851 1637.786011,712.348938 1637.104736,712.144409 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1635.053833,724.632996 
	C1633.917236,725.760620 1632.747192,726.426025 1631.287231,726.993286 
	C1631.454956,724.118164 1631.912354,721.341187 1632.359985,718.624512 
	C1631.790527,717.694031 1631.017334,716.430786 1630.244263,715.167542 
	C1631.185181,714.692139 1632.126221,714.216736 1633.067139,713.741333 
	C1633.694092,715.069580 1634.655518,716.346436 1634.869873,717.738220 
	C1635.193481,719.840271 1634.996216,722.022583 1635.053833,724.632996 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1613.483887,721.022461 
	C1613.040649,720.874451 1613.021606,720.705383 1612.987061,720.281738 
	C1616.283936,719.668274 1620.096313,716.813110 1621.917725,720.974182 
	C1623.867920,720.332397 1625.732056,719.718933 1627.596313,719.105469 
	C1627.721069,719.476196 1627.845703,719.846985 1627.970459,720.217773 
	C1626.225098,721.359924 1624.479614,722.502075 1623.514771,723.133423 
	C1619.917480,722.335083 1616.912842,721.668274 1613.483887,721.022461 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1586.495361,620.662598 
	C1587.038574,621.007446 1587.364380,621.568542 1587.690186,622.129578 
	C1587.411499,622.258484 1587.132935,622.387451 1586.854248,622.516418 
	C1586.662109,621.970520 1586.469971,621.424622 1586.495361,620.662598 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1574.843750,628.144409 
	C1574.547729,628.042908 1574.387329,627.806274 1574.227051,627.569641 
	C1574.465576,627.517212 1574.704224,627.464783 1575.094482,627.573181 
	C1575.246338,627.734070 1574.979614,628.009216 1574.843750,628.144409 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1616.185913,665.162598 
	C1615.854614,665.822510 1615.249634,666.205688 1614.644653,666.588867 
	C1614.695679,665.916565 1614.746704,665.244263 1615.066650,664.467041 
	C1615.527832,664.536682 1615.719971,664.711243 1616.185913,665.162598 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1571.994141,711.026001 
	C1572.963135,709.783142 1574.280273,708.670166 1574.874268,707.253845 
	C1576.203247,704.084717 1578.376099,703.473145 1581.696655,702.893616 
	C1590.268677,701.397644 1592.902588,706.938782 1595.928711,713.837463 
	C1588.068237,713.837463 1580.559692,713.860901 1573.052979,713.752136 
	C1572.750854,713.747681 1572.466797,712.491699 1572.095581,711.397461 
	C1572.016846,710.976135 1572.013916,711.018250 1571.994141,711.026001 
M1580.179810,710.404663 
	C1583.170044,710.404663 1586.160278,710.404663 1589.413330,710.404663 
	C1589.248413,708.648010 1589.128662,707.371826 1588.992554,705.921875 
	C1587.111328,706.676147 1585.464600,707.336487 1583.315796,708.198059 
	C1583.299683,708.175903 1582.895874,707.491455 1582.360962,706.932495 
	C1581.854004,706.402527 1581.209595,706.004028 1580.191406,705.208557 
	C1579.616455,707.034790 1579.185547,708.403503 1578.368530,710.112122 
	C1578.390503,710.354431 1578.412476,710.596680 1578.434448,710.838928 
	C1578.768799,710.716003 1579.103149,710.593018 1580.179810,710.404663 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1565.430664,711.849609 
	C1562.490112,711.595703 1559.923584,711.112793 1557.514038,710.333008 
	C1557.671021,710.036011 1557.993408,710.004639 1557.993408,710.004639 
	C1561.634155,710.213623 1561.876465,707.541321 1561.987793,705.036011 
	C1562.141357,701.579468 1562.026245,698.110962 1562.026245,694.258545 
	C1563.313965,694.541443 1564.223999,694.741394 1565.502441,695.013489 
	C1565.848877,700.597351 1565.826782,706.109070 1565.430664,711.849609 
M1564.653687,707.144348 
	C1564.612305,706.172241 1564.570801,705.200073 1564.529541,704.228027 
	C1564.500244,704.269592 1564.470947,704.311218 1564.393921,704.420471 
	C1564.393921,705.654541 1564.393799,706.956360 1564.404541,708.258118 
	C1564.404541,708.262817 1564.564087,708.266235 1564.649170,708.270264 
	C1564.646118,708.107422 1564.643188,707.944580 1564.653687,707.144348 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1641.039551,729.570740 
	C1641.039551,729.570740 1641.047607,729.549744 1641.051514,729.566895 
	C1645.309326,729.459473 1649.571289,729.155273 1653.811157,729.344727 
	C1654.793335,729.388611 1655.700317,731.118286 1656.200195,732.494263 
	C1652.956177,732.934082 1650.137939,732.772705 1647.355713,733.018555 
	C1644.364258,733.282898 1642.231567,732.397156 1641.039551,729.570740 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1641.048218,730.038452 
	C1642.231567,732.397156 1644.364258,733.282898 1647.355713,733.018555 
	C1650.137939,732.772705 1652.956177,732.934082 1656.192871,732.883972 
	C1656.740601,733.011230 1656.853882,733.170776 1657.089844,733.596741 
	C1652.932739,733.902344 1648.649414,733.848999 1644.374634,734.014709 
	C1641.748657,734.116455 1640.753174,733.009277 1641.048218,730.038452 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1580.471191,662.983398 
	C1579.981201,662.805725 1579.936035,662.645142 1579.954224,662.242432 
	C1582.058472,660.123718 1584.099243,658.247192 1586.627197,655.922607 
	C1586.627197,658.424866 1586.627197,660.417786 1586.627197,663.000488 
	C1584.776855,663.000488 1582.846313,663.000488 1580.471191,662.983398 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1578.052490,656.005737 
	C1580.056030,654.788818 1582.039185,653.979919 1584.022339,653.171082 
	C1584.214844,653.565979 1584.407349,653.960938 1584.599854,654.355896 
	C1582.493896,656.222656 1580.387939,658.089417 1578.248779,659.985718 
	C1578.196533,659.123108 1578.114258,657.768372 1578.052490,656.005737 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1605.713745,687.917053 
	C1606.401855,692.196411 1606.913940,696.496399 1606.852661,700.788208 
	C1606.839844,701.680969 1604.703125,702.543335 1603.289551,703.702026 
	C1602.539429,704.246521 1602.044678,704.508545 1601.300537,704.897339 
	C1601.051025,698.929688 1601.051025,692.835266 1601.051025,686.375854 
	C1602.760010,686.997864 1604.047119,687.466370 1605.713745,687.917053 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1575.621094,674.614380 
	C1575.673828,674.952454 1575.726562,675.290588 1575.954346,675.820801 
	C1575.819824,676.495850 1575.510254,676.978760 1575.128174,677.740479 
	C1574.586914,678.290710 1574.117920,678.562073 1573.381958,678.884277 
	C1573.318848,677.490540 1573.522705,676.045959 1573.860718,674.239929 
	C1574.159668,673.974976 1574.324219,674.071411 1574.875244,674.306030 
	C1575.261475,674.444092 1575.621094,674.614380 1575.621094,674.614380 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1575.746582,674.250244 
	C1575.621094,674.614380 1575.261475,674.444092 1575.159424,674.273254 
	C1575.191162,673.806213 1575.324951,673.509949 1575.708740,673.167297 
	C1575.929932,673.375977 1575.901123,673.631104 1575.746582,674.250244 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2161.114746,855.504028 
	C2161.013428,855.816345 2160.902100,856.403870 2160.812256,856.400574 
	C2158.519775,856.317444 2156.199707,856.355652 2153.948242,855.984558 
	C2151.898193,855.646729 2149.934570,860.363953 2147.778320,856.010010 
	C2147.510498,855.469055 2145.614014,855.369568 2144.617920,855.627930 
	C2142.313232,856.225525 2140.111084,857.206543 2137.840820,857.951965 
	C2136.925293,858.252502 2135.555176,858.836365 2135.055176,858.444702 
	C2132.878418,856.739319 2131.052002,857.214783 2128.830322,858.366211 
	C2128.196777,858.694519 2126.686279,857.330200 2125.219238,856.406067 
	C2124.856689,855.793030 2124.874023,855.517700 2125.203125,855.421631 
	C2126.909424,855.276489 2128.324707,854.953674 2129.848145,854.174683 
	C2130.019531,853.412476 2130.082520,853.106384 2130.145752,852.800171 
	C2131.434082,852.693848 2133.129883,853.075928 2133.942139,852.398621 
	C2137.967285,849.042175 2141.548340,850.371460 2145.391357,853.116882 
	C2146.451660,852.273743 2147.573975,851.381287 2149.120605,850.151367 
	C2149.483643,851.710083 2149.785400,853.005249 2150.295654,855.196106 
	C2151.721680,853.456604 2152.794434,852.148132 2153.335938,851.487366 
	C2155.418213,852.629822 2157.675781,853.868347 2160.427734,855.094727 
	C2160.922119,855.082458 2161.114746,855.504028 2161.114746,855.504028 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M2118.842285,818.972046 
	C2121.537354,820.583740 2123.150635,822.270386 2123.011719,825.714478 
	C2122.835449,830.095337 2130.479736,835.576538 2134.920166,835.339661 
	C2138.301270,835.159363 2141.722168,835.719482 2145.572266,835.966187 
	C2145.536377,836.801575 2145.252441,837.977783 2144.535400,838.377441 
	C2141.731201,839.940613 2128.820801,836.505798 2126.206299,834.732666 
	C2120.740479,831.025879 2121.536377,824.247803 2118.015625,819.340454 
	C2118.217285,818.955139 2118.396484,818.944153 2118.842285,818.972046 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2128.641602,840.615112 
	C2129.419189,840.069275 2130.091553,839.622437 2130.649658,839.736450 
	C2132.585693,840.131958 2134.482178,840.722351 2136.393555,841.238647 
	C2136.032959,842.399780 2136.019531,844.141113 2135.242432,844.606140 
	C2133.403076,845.706665 2131.237061,846.346497 2129.128418,846.873657 
	C2128.199219,847.105957 2127.092529,846.520935 2126.058350,846.467834 
	C2124.370605,846.381104 2122.565918,846.758179 2121.038818,846.251526 
	C2120.432373,846.050293 2119.804443,843.492920 2120.282959,842.913147 
	C2121.532959,841.398315 2123.388428,840.383118 2125.002441,839.168640 
	C2125.263916,839.546143 2125.525391,839.923584 2125.786621,840.301086 
	C2124.449951,841.502380 2123.113037,842.703674 2120.967041,844.632263 
	C2125.189209,844.891724 2127.999756,845.101868 2128.641602,840.615112 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2127.050049,866.057800 
	C2127.611816,864.873169 2128.275391,863.808899 2129.129883,862.438232 
	C2132.062988,862.438232 2135.470703,862.438232 2138.878418,862.438232 
	C2138.955566,862.872314 2139.032959,863.306396 2139.110107,863.740417 
	C2140.272461,862.929077 2141.434814,862.117737 2143.062988,860.981201 
	C2143.062988,863.258972 2143.062988,865.226990 2143.062988,866.021973 
	C2140.548828,866.267395 2138.317627,866.485229 2135.850830,866.726074 
	C2134.260742,862.628357 2130.948242,865.406250 2127.691406,866.226929 
	C2127.352783,866.421326 2127.151855,866.178162 2127.050049,866.057800 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2170.028320,852.006470 
	C2169.016846,853.815979 2168.018555,855.647278 2166.724854,858.020569 
	C2165.081787,857.192139 2163.417969,856.353271 2161.434326,855.509155 
	C2161.114746,855.504028 2160.922119,855.082458 2160.715820,854.973999 
	C2160.248779,853.945862 2159.988037,853.026245 2159.727783,852.108459 
	C2161.258301,851.843262 2162.806885,851.569275 2164.359131,851.319153 
	C2164.456787,851.303406 2164.635498,851.450928 2164.685059,851.562805 
	C2164.747559,851.703857 2164.716553,851.886292 2164.463379,852.547607 
	C2164.325928,853.560303 2164.450928,854.076355 2164.576172,854.592407 
	C2164.932373,854.319397 2165.288818,854.046387 2165.645264,853.773438 
	C2166.888428,853.248413 2168.131592,852.723328 2169.729004,852.128052 
	C2170.083252,852.057861 2170.041504,852.028381 2170.028320,852.006470 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2124.912109,855.243958 
	C2124.874023,855.517700 2124.856689,855.793030 2124.871094,856.184692 
	C2124.354004,856.419556 2123.406738,856.731567 2123.360840,856.631348 
	C2122.656006,855.089600 2122.068604,853.494080 2121.162109,851.160095 
	C2121.626709,851.079529 2123.059326,850.830994 2124.777344,851.132690 
	C2125.094482,852.204102 2125.126465,852.725220 2125.136230,853.580566 
	C2125.046875,854.357788 2124.979492,854.800903 2124.912109,855.243958 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M2177.879883,868.133789 
	C2176.062256,866.987549 2174.325684,865.751282 2172.589111,864.515076 
	C2173.066406,863.961121 2173.543701,863.407227 2174.020996,862.853271 
	C2175.318359,864.310852 2176.615967,865.768372 2178.020996,867.547363 
	C2178.128662,867.868774 2177.961182,868.043884 2177.879883,868.133789 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2130.308105,852.497681 
	C2130.082520,853.106384 2130.019531,853.412476 2129.492188,854.166382 
	C2128.223877,854.258728 2127.419922,853.903320 2126.719727,853.140625 
	C2126.886475,852.176758 2126.949707,851.620239 2127.012695,851.063660 
	C2128.165283,851.440796 2129.318115,851.817932 2130.308105,852.497681 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M2126.683350,851.069336 
	C2126.949707,851.620239 2126.886475,852.176758 2126.503906,852.981812 
	C2125.842529,853.235657 2125.500488,853.240967 2125.158203,853.246338 
	C2125.126465,852.725220 2125.094482,852.204102 2125.055664,851.291199 
	C2125.483643,850.957947 2125.918945,851.016479 2126.683350,851.069336 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2043.716064,839.970947 
	C2043.914551,840.409790 2043.805542,840.849670 2043.333496,841.155273 
	C2043.116455,840.671387 2043.262573,840.321655 2043.716064,839.970947 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M2042.719482,840.974609 
	C2042.920898,841.409424 2042.820557,841.848755 2042.343994,842.154907 
	C2042.117676,841.674194 2042.267700,841.326599 2042.719482,840.974609 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1557.908936,709.681030 
	C1557.993408,710.004639 1557.671021,710.036011 1557.510620,710.023804 
	C1555.981567,708.882751 1552.547485,709.131836 1554.656372,705.461670 
	C1555.039307,705.150146 1555.287598,705.149292 1555.894775,705.255859 
	C1556.777344,706.694641 1557.300903,708.026001 1557.908936,709.681030 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M838.974487,663.367981 
	C836.981445,663.415222 834.950500,663.079651 832.533447,662.673706 
	C831.774536,660.225464 832.018188,658.141602 835.040222,658.000061 
	C838.557434,657.835266 838.938110,660.303345 838.974487,663.367981 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M849.585571,653.400391 
	C849.652710,653.477600 849.408264,653.502075 849.408264,653.502075 
	C849.408264,653.502075 849.518372,653.323181 849.585571,653.400391 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M833.589722,666.943481 
	C834.752075,667.031250 835.523254,667.112122 837.096375,667.277100 
	C834.496277,670.125854 832.344727,672.483154 830.193176,674.840393 
	C831.170593,672.101135 827.258423,667.379517 833.589722,666.943481 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M900.719849,697.909729 
	C901.110107,698.720276 901.135986,699.572205 901.064209,700.693054 
	C900.350708,700.644104 899.734863,700.326172 899.118958,700.008301 
	C899.531189,699.322571 899.943359,698.636841 900.719849,697.909729 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M900.778625,702.970459 
	C901.460632,702.961914 901.865784,703.004822 902.574951,703.064880 
	C901.924500,703.333740 900.970032,703.585449 900.015625,703.837158 
	C900.177673,703.565430 900.339722,703.293701 900.778625,702.970459 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M896.207764,702.015808 
	C895.869019,701.537170 895.880249,701.075623 895.917542,700.267578 
	C896.542908,700.255554 897.142273,700.589905 897.741577,700.924316 
	C897.346985,701.293823 896.952332,701.663330 896.207764,702.015808 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M900.634155,696.053345 
	C900.927673,696.072754 900.933533,696.499878 900.929443,696.712891 
	C900.288208,696.925964 899.651123,696.925964 899.014038,696.925964 
	C898.978577,696.753784 898.943115,696.581543 898.907654,696.409363 
	C899.385315,696.284241 899.862976,696.159119 900.634155,696.053345 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M808.385803,666.082764 
	C808.508179,665.789978 808.492920,665.846130 808.465698,665.837463 
	C810.757263,666.437866 813.075989,667.046997 815.636597,667.719604 
	C813.825439,669.984924 812.068054,672.183044 810.130432,674.663696 
	C809.950195,674.946228 809.975525,674.954224 809.650024,674.998535 
	C808.904297,674.990173 808.484070,674.937500 808.063782,674.884827 
	C808.082581,672.502136 808.101379,670.119385 808.093384,667.357788 
	C808.132263,666.777832 808.197815,666.576660 808.385803,666.082764 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M965.572937,650.037781 
	C965.980652,653.134033 966.014099,656.154724 966.072876,659.636841 
	C962.919800,660.255249 959.741516,660.412354 956.265381,660.302856 
	C955.991699,658.069519 956.233459,656.075500 955.991699,654.142029 
	C955.573425,650.796814 957.155029,649.736694 960.444763,649.746094 
	C962.222168,649.699158 963.710449,649.830688 965.572937,650.037781 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M940.012024,666.049866 
	C940.379883,666.826050 940.539246,667.603943 940.599243,668.778809 
	C940.195923,669.376770 939.892029,669.577759 939.441284,669.891235 
	C939.095764,668.933533 938.896973,667.863403 938.833069,666.393372 
	C939.246216,666.015869 939.524780,666.035217 940.012024,666.049866 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M938.253906,675.083618 
	C938.255249,674.178589 938.528259,673.363770 939.057495,672.347656 
	C939.051086,673.155518 938.788391,674.164612 938.253906,675.083618 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M592.399963,797.145813 
	C592.279236,796.886597 592.462036,796.596985 592.882446,796.195618 
	C592.981140,796.427734 592.842285,796.771545 592.399963,797.145813 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M584.179382,768.167236 
	C584.575195,767.693848 585.142761,767.420532 586.043091,767.234863 
	C585.700928,767.670715 585.026001,768.019043 584.179382,768.167236 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M483.438660,669.692566 
	C482.947662,669.603333 482.347321,669.188904 481.869873,668.362427 
	C482.438263,668.422668 482.883789,668.895020 483.438660,669.692566 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M482.067261,667.764648 
	C481.636871,667.881653 481.229523,667.744873 480.638123,667.311523 
	C480.709930,666.999268 480.965790,666.983521 481.605591,666.989441 
	C482.023102,667.177734 482.056671,667.344299 482.067261,667.764648 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M425.260406,650.245483 
	C423.920471,651.847229 422.580536,653.449036 421.011780,655.231689 
	C420.584991,655.282715 420.386993,655.152710 419.927734,654.780701 
	C421.115906,652.981262 422.565399,651.423889 424.468842,649.890686 
	C424.922791,649.914856 425.260406,650.245483 425.260406,650.245483 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M424.758911,649.744568 
	C424.952698,649.416016 425.310364,649.257751 425.668030,649.099487 
	C425.680298,649.362854 425.692566,649.626221 425.482605,650.067505 
	C425.260406,650.245483 424.922791,649.914856 424.758911,649.744568 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M503.690613,650.088562 
	C503.896301,650.585449 503.776306,651.208984 503.320251,651.933960 
	C503.111115,651.428711 503.238037,650.821960 503.690613,650.088562 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M504.739624,649.007629 
	C504.896942,649.357361 504.770996,649.745056 504.305481,650.078735 
	C504.129425,649.698303 504.292877,649.371948 504.739624,649.007629 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M525.061218,681.315186 
	C525.060730,680.952576 525.531372,680.897156 525.763184,680.946411 
	C525.924438,682.073303 525.853943,683.151062 525.783508,684.228760 
	C525.542908,684.222961 525.302307,684.217163 525.061707,684.211365 
	C525.061707,683.366821 525.061707,682.522278 525.061218,681.315186 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M527.245911,685.086670 
	C527.288330,684.852173 527.627563,684.861206 527.789917,684.909668 
	C527.702698,685.079163 527.453064,685.200134 527.245911,685.086670 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M594.955078,705.064331 
	C594.365906,705.545654 593.776672,706.027039 593.027283,706.218201 
	C592.867065,705.927979 592.940918,705.968018 592.928223,705.927490 
	C593.422058,705.674622 593.928589,705.462341 594.718567,705.130981 
	C595.001953,705.011902 594.955078,705.064270 594.955078,705.064331 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M594.975464,705.029663 
	C595.071045,704.606812 595.193054,704.166199 595.315125,703.725647 
	C595.418274,703.752930 595.521484,703.780212 595.624634,703.807495 
	C595.536377,704.139648 595.448181,704.471863 595.157532,704.934204 
	C594.955078,705.064270 595.001953,705.011902 594.975464,705.029663 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M474.991577,667.308594 
	C475.851471,668.162415 476.419159,669.206665 476.986847,670.250977 
	C475.173859,670.746826 473.378174,671.546936 471.542908,671.651306 
	C469.773743,671.752075 467.963654,671.134460 465.778687,670.766235 
	C466.498138,669.278870 467.265991,667.691406 467.927948,666.322876 
	C470.341309,666.742126 472.520325,667.120605 474.991577,667.308594 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M274.500061,770.062073 
	C275.015472,772.026489 275.605164,774.248413 275.052917,776.136292 
	C274.853058,776.819458 271.919098,776.702881 270.232697,776.951172 
	C269.932617,776.551086 269.632538,776.151062 269.332428,775.750977 
	C270.929230,773.896484 272.526031,772.041992 274.500061,770.062073 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M522.447754,814.866699 
	C522.262573,811.963989 522.377869,809.227295 522.493164,806.490540 
	C523.239685,806.451599 523.986145,806.412720 524.732666,806.373779 
	C525.135376,809.261658 525.538025,812.149536 525.928223,815.452393 
	C524.859863,815.589050 523.804077,815.310852 522.447754,814.866699 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M395.108734,834.637451 
	C394.995148,834.886902 394.723053,834.897095 394.041687,834.872070 
	C394.071716,834.690613 394.510986,834.544373 395.108734,834.637451 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M503.991699,769.006348 
	C503.867676,768.863525 503.752045,768.713928 503.636414,768.564392 
	C503.725403,768.611694 503.814362,768.658997 503.958496,768.844421 
	C504.013672,768.982544 504.000092,768.999573 503.991699,769.006348 
z"
    />
    <path
      fill="#B5B5B5"
      opacity="1.000000"
      stroke="none"
      d="
M426.970093,816.970459 
	C427.094391,816.507080 427.245514,816.068176 427.396606,815.629333 
	C427.429871,816.023438 427.463104,816.417542 427.274902,816.926147 
	C427.053497,817.040649 426.996918,816.994995 426.970093,816.970459 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M198.941376,780.034119 
	C200.319580,779.232605 201.697754,778.431091 203.541962,777.358521 
	C203.541962,779.836060 203.541962,782.005005 203.541962,784.592346 
	C201.396118,784.078003 198.315216,787.713318 196.981934,782.415344 
	C196.995758,781.992737 196.948975,782.041443 197.262482,782.018921 
	C197.731705,781.646423 197.887421,781.296509 198.018890,780.971313 
	C197.994659,780.996155 197.944138,781.043640 198.249603,781.027466 
	C198.717682,780.656555 198.880310,780.301819 199.018997,779.970581 
	C198.995056,779.993958 198.941376,780.034119 198.941376,780.034119 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M197.015381,781.965271 
	C195.981216,781.299377 193.945511,780.337036 194.032028,780.074829 
	C194.620239,778.291870 195.298889,776.198914 196.669144,775.130493 
	C197.620163,774.389038 199.721771,775.123352 202.078812,775.234680 
	C200.827560,777.019592 200.029419,778.158142 199.086319,779.665405 
	C198.941376,780.034119 198.995056,779.993958 198.758148,780.065674 
	C198.328873,780.439453 198.136520,780.741577 197.944153,781.043640 
	C197.944138,781.043640 197.994659,780.996155 197.742294,781.034912 
	C197.309631,781.396301 197.129318,781.718872 196.949005,782.041443 
	C196.948975,782.041443 196.995758,781.992737 197.015381,781.965271 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1532.048340,711.565674 
	C1527.359985,711.799622 1525.104614,709.489990 1525.151367,704.855835 
	C1525.204468,699.585938 1525.144531,694.315002 1525.142456,688.584656 
	C1527.576416,687.829956 1530.001587,687.535095 1532.155273,687.273254 
	C1532.155273,695.375793 1532.155273,703.284546 1532.048340,711.565674 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1552.150146,715.893799 
	C1549.388550,716.193787 1546.738525,716.066101 1543.653320,715.907776 
	C1543.218140,715.877075 1543.178101,715.510315 1543.219238,715.330444 
	C1545.651367,713.833679 1548.042603,712.516663 1550.702759,710.980042 
	C1551.401733,712.329041 1551.831665,713.897583 1552.150146,715.893799 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1500.098389,676.272217 
	C1494.650513,678.678772 1489.394165,677.983032 1482.955933,674.875916 
	C1485.234375,673.449707 1487.018677,672.332825 1489.213013,670.959229 
	C1489.425781,671.152832 1490.283325,671.932922 1491.384644,672.934998 
	C1494.141968,669.711548 1497.155151,669.610291 1500.650391,672.191162 
	C1500.711670,673.388367 1500.394409,674.662231 1500.098389,676.272217 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1493.810181,664.108704 
	C1492.449585,666.647766 1485.903564,668.218750 1484.425659,666.171997 
	C1483.721924,665.197327 1483.315308,662.965881 1483.900269,662.313538 
	C1485.233398,660.827026 1487.261719,659.963745 1489.007690,658.847229 
	C1489.304077,659.334351 1489.600464,659.821472 1489.896851,660.308594 
	C1488.639160,661.357788 1487.381348,662.407043 1486.123657,663.456299 
	C1486.221191,663.706970 1486.318848,663.957581 1486.416382,664.208252 
	C1488.063477,663.909058 1489.710449,663.609924 1491.895264,663.337891 
	C1492.892090,663.612854 1493.351196,663.860779 1493.810181,664.108704 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M1494.240967,664.180176 
	C1493.351196,663.860779 1492.892090,663.612854 1492.276489,663.237671 
	C1491.599365,660.549683 1492.352905,659.135742 1495.251587,659.020020 
	C1501.457397,658.772400 1496.352295,664.147583 1498.611938,665.982361 
	C1497.298462,665.405457 1495.985107,664.828491 1494.240967,664.180176 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1506.935547,682.567810 
	C1507.053101,683.943970 1506.941040,685.026245 1506.863159,685.777649 
	C1504.717163,686.265442 1502.854492,686.688904 1500.861572,687.759705 
	C1500.798828,688.590088 1500.866455,688.773071 1500.934204,688.956055 
	C1500.934204,688.956055 1500.995850,688.910034 1500.982422,688.874268 
	C1500.476562,688.647095 1499.984131,688.455688 1499.305908,687.670837 
	C1499.076782,685.368958 1499.033447,683.660645 1498.990112,681.952271 
	C1501.562012,682.059509 1504.133789,682.166687 1506.935547,682.567810 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1498.754883,681.677734 
	C1499.033447,683.660645 1499.076782,685.368958 1499.102417,687.503906 
	C1497.609253,687.906921 1496.134033,687.883240 1494.221924,687.486816 
	C1493.520630,685.275635 1493.256226,683.437256 1492.991821,681.598938 
	C1494.834473,681.533691 1496.677124,681.468506 1498.754883,681.677734 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1491.186279,687.883789 
	C1489.356445,687.395447 1487.891724,686.902405 1485.771362,686.188660 
	C1487.108398,685.124817 1488.268921,684.201294 1489.747681,683.137329 
	C1490.512207,683.020752 1490.958496,683.044678 1491.798706,683.451416 
	C1492.215820,685.127563 1492.239136,686.420837 1492.262573,687.714111 
	C1492.025391,687.769104 1491.788330,687.824036 1491.186279,687.883789 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1492.512695,687.773621 
	C1492.239136,686.420837 1492.215820,685.127563 1492.134277,683.447327 
	C1492.239746,682.709961 1492.403198,682.359497 1492.779297,681.803955 
	C1493.256226,683.437256 1493.520630,685.275635 1493.781860,687.443787 
	C1493.440063,687.793457 1493.101562,687.813293 1492.512695,687.773621 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1424.926880,705.920288 
	C1421.922241,710.570068 1418.314819,706.223267 1415.013672,706.442566 
	C1412.863525,706.585388 1410.623535,705.372803 1408.733765,704.852722 
	C1411.086060,703.395630 1413.508667,701.895081 1416.085693,700.298889 
	C1416.478149,701.063965 1416.903076,701.892334 1417.339111,702.742371 
	C1417.620483,702.707397 1418.009766,702.780090 1418.176025,702.620422 
	C1421.483398,699.445496 1424.973999,699.429932 1428.853638,701.628540 
	C1429.673950,702.093567 1431.101929,701.486755 1432.856689,701.178589 
	C1433.649414,700.987061 1433.835449,700.990723 1434.021606,700.994324 
	C1434.046387,701.444885 1434.071167,701.895447 1433.704468,702.705933 
	C1432.542480,703.673096 1431.771851,704.280273 1431.001221,704.887390 
	C1430.028320,705.333130 1429.055420,705.778931 1427.764160,706.629883 
	C1427.275513,707.059692 1427.105347,707.084351 1426.935059,707.109070 
	C1426.798584,706.756348 1426.661987,706.403687 1426.223389,705.624756 
	C1425.589844,705.439026 1425.258423,705.679688 1424.926880,705.920288 
M1422.945923,705.377930 
	C1423.096680,704.348450 1423.247437,703.318970 1423.398315,702.289490 
	C1422.314819,702.783875 1421.231445,703.278198 1420.147949,703.772522 
	C1420.848877,704.422180 1421.549805,705.071838 1422.945923,705.377930 
M1426.335083,703.505737 
	C1426.335083,703.505737 1426.535034,703.645386 1426.535034,703.645386 
	C1426.535034,703.645386 1426.514404,703.322815 1426.335083,703.505737 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1437.063477,704.650513 
	C1437.150269,703.357483 1437.236938,702.064453 1437.392456,699.746765 
	C1440.083618,702.487671 1442.140259,704.582336 1444.196899,706.677002 
	C1443.662720,707.101196 1443.128540,707.525391 1442.594360,707.949524 
	C1441.921875,706.834778 1441.249512,705.720032 1440.567749,704.589966 
	C1439.659058,704.962402 1438.649536,705.376099 1437.294189,705.625366 
	C1436.915527,705.180603 1436.953857,704.910522 1437.063477,704.650513 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1448.836914,705.074341 
	C1448.836914,705.074341 1448.590942,705.142883 1448.465210,705.122986 
	C1447.763428,704.901794 1447.187256,704.700439 1445.279053,704.033569 
	C1447.486450,702.616821 1449.235107,701.494446 1451.405762,700.340637 
	C1451.902344,700.751160 1451.977051,701.193176 1451.625977,701.654541 
	C1450.412354,702.807373 1449.624634,703.940857 1448.836914,705.074341 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1431.350586,704.901123 
	C1431.771851,704.280273 1432.542480,703.673096 1433.619385,703.035645 
	C1434.196289,703.484619 1434.467041,703.963867 1434.910278,705.034424 
	C1435.059204,706.067078 1435.035645,706.508301 1435.012207,706.949524 
	C1433.908203,706.271362 1432.804077,705.593140 1431.350586,704.901123 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1435.313965,707.010437 
	C1435.035645,706.508301 1435.059204,706.067078 1435.031250,705.429077 
	C1435.413330,704.954468 1435.846924,704.676575 1436.671997,704.524597 
	C1436.953857,704.910522 1436.915527,705.180603 1436.953857,705.670044 
	C1436.511475,706.276611 1436.063721,706.674011 1435.313965,707.010437 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1425.263794,705.858521 
	C1425.258423,705.679688 1425.589844,705.439026 1426.082031,705.378723 
	C1426.028809,705.638306 1425.814697,705.717529 1425.263794,705.858521 
z"
    />
    <path
      fill="#989898"
      opacity="1.000000"
      stroke="none"
      d="
M1433.983643,700.758057 
	C1433.835449,700.990723 1433.649414,700.987061 1433.184570,700.967041 
	C1433.252319,700.807678 1433.598877,700.664734 1433.983643,700.758057 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1427.032959,707.308777 
	C1427.105347,707.084351 1427.275513,707.059692 1427.700073,706.991272 
	C1427.679810,707.134521 1427.405396,707.321472 1427.032959,707.308777 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1458.020508,699.991821 
	C1456.913086,700.451355 1455.789551,700.924377 1453.663940,701.819336 
	C1454.198120,698.942139 1454.229248,696.372864 1455.325562,694.393677 
	C1455.637451,693.830505 1459.047363,694.983337 1461.003174,695.687500 
	C1460.969604,696.025818 1460.996826,696.000061 1460.723145,696.021240 
	C1460.289795,696.368530 1460.130249,696.694702 1459.970703,697.020874 
	C1459.970703,697.020874 1459.997681,696.997314 1459.729736,697.029663 
	C1459.296143,697.383179 1459.130493,697.704346 1458.964844,698.025574 
	C1458.964844,698.025574 1458.993774,697.994812 1458.707275,698.112854 
	C1458.273560,698.833496 1458.126343,699.436157 1457.979248,700.038818 
	C1457.979248,700.038818 1458.004517,700.005310 1458.020508,699.991821 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1449.203613,705.088379 
	C1449.624634,703.940857 1450.412354,702.807373 1451.570557,701.880249 
	C1451.150635,703.091858 1450.360352,704.097107 1449.203613,705.088379 
z"
    />
    <path
      fill="#DEDEDE"
      opacity="1.000000"
      stroke="none"
      d="
M1497.862793,696.880371 
	C1498.180542,704.401855 1497.501953,704.986816 1490.644287,702.254761 
	C1493.278320,700.121277 1495.506958,698.316162 1497.862793,696.880371 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1492.056152,698.753296 
	C1491.061890,699.682922 1490.357910,700.305115 1489.022217,701.485718 
	C1489.022217,698.930725 1489.022217,697.170349 1489.022217,695.008606 
	C1491.288330,695.326477 1494.776001,693.817261 1492.056152,698.753296 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M1397.866943,638.199646 
	C1400.344604,638.150879 1402.666138,638.282959 1404.987671,638.415039 
	C1404.909668,639.250610 1404.831787,640.086243 1404.753906,640.921875 
	C1402.233643,640.564392 1399.713501,640.206909 1397.131470,639.469727 
	C1397.281006,638.851379 1397.494629,638.614868 1397.866943,638.199646 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1328.861694,692.802246 
	C1328.377319,693.477295 1327.915771,693.840454 1327.454346,694.203674 
	C1327.375854,693.433289 1327.297241,692.662964 1327.218750,691.892578 
	C1327.774048,692.091858 1328.329346,692.291077 1328.861694,692.802246 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1277.735474,652.796997 
	C1277.555298,653.437866 1277.072998,653.890137 1276.274658,654.178467 
	C1276.450195,653.545837 1276.941772,653.077087 1277.735474,652.796997 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M564.732300,678.013977 
	C564.894653,678.365906 564.774902,678.750732 564.319214,679.074219 
	C564.138977,678.690796 564.294617,678.368896 564.732300,678.013977 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M563.731323,679.014893 
	C563.895813,679.376526 563.778870,679.769592 563.324829,680.086060 
	C563.141785,679.688416 563.295837,679.367371 563.731323,679.014893 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M808.070984,675.197815 
	C808.484070,674.937500 808.904297,674.990173 809.640137,674.992554 
	C809.724365,675.481445 809.493164,676.020508 809.261902,676.559631 
	C808.867310,676.210022 808.472778,675.860413 808.070984,675.197815 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M807.513367,678.849854 
	C807.232544,678.890686 807.126404,678.777588 807.020325,678.664490 
	C807.242920,678.674927 807.465515,678.685425 807.513367,678.849854 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M836.978577,637.016724 
	C836.659058,638.835754 836.368225,640.646118 836.077393,642.456543 
	C834.857910,641.950073 832.895264,641.742981 832.549561,640.884521 
	C831.167480,637.453064 833.895264,637.291199 836.597900,637.026978 
	C836.979431,637.062378 837.007263,637.008179 836.978577,637.016724 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1617.644897,843.935791 
	C1616.910278,845.392944 1615.812988,846.792786 1614.353271,848.100586 
	C1615.087891,846.631897 1616.185059,845.255188 1617.644897,843.935791 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1613.717285,847.993774 
	C1613.892944,848.369202 1613.776978,848.748718 1613.342773,849.053711 
	C1613.158325,848.652039 1613.291992,848.324951 1613.717285,847.993774 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1904.031494,764.028809 
	C1899.463501,765.072144 1898.115356,763.434692 1899.270386,759.412720 
	C1899.444946,758.804626 1899.691284,758.144470 1899.611816,757.547302 
	C1898.713257,750.784668 1898.697998,750.786682 1905.064453,751.304382 
	C1905.598267,754.041870 1906.475586,756.744324 1906.516724,759.459351 
	C1906.539429,760.964417 1905.164062,762.490601 1904.031494,764.028809 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1900.066040,769.942932 
	C1900.480713,770.587708 1900.895386,771.232422 1900.991821,771.382385 
	C1902.823120,771.194214 1904.519165,771.019958 1906.215332,770.845642 
	C1906.241333,772.201599 1906.591797,774.627930 1906.239380,774.734741 
	C1904.351807,775.306763 1902.217651,775.650452 1900.333130,775.266479 
	C1899.813110,775.160461 1899.856445,772.290466 1899.824829,770.339844 
	C1900.008423,769.991028 1900.066040,769.942932 1900.066040,769.942932 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1899.978516,770.013672 
	C1899.728149,768.868774 1899.507812,767.701172 1899.287354,766.533569 
	C1901.359375,766.390442 1903.431274,766.247375 1905.503174,766.104248 
	C1905.523315,766.448181 1905.543579,766.792053 1905.563721,767.135986 
	C1903.779053,767.828979 1901.994263,768.522034 1900.137695,769.578979 
	C1900.066040,769.942932 1900.008423,769.991028 1899.978516,770.013672 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1869.682861,814.837036 
	C1868.918579,816.345825 1867.798828,817.711060 1866.334473,819.045898 
	C1867.102417,817.574829 1868.214844,816.134155 1869.682861,814.837036 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1592.704346,722.906738 
	C1592.892334,723.373718 1592.756226,723.770691 1592.295166,724.095886 
	C1592.106934,723.628296 1592.243530,723.232544 1592.704346,722.906738 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1604.149902,715.799011 
	C1604.655762,715.293518 1605.193726,715.097595 1605.731567,714.901611 
	C1605.670166,715.661743 1605.608765,716.421936 1605.547363,717.182129 
	C1605.092163,716.824219 1604.636963,716.466370 1604.149902,715.799011 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1603.901367,705.246094 
	C1604.016479,704.978088 1604.474121,704.920471 1604.703857,704.906006 
	C1604.684814,705.738525 1604.435791,706.585449 1604.186768,707.432312 
	C1603.943359,707.380859 1603.700073,707.329346 1603.456665,707.277893 
	C1603.566528,706.689941 1603.676392,706.101990 1603.901367,705.246094 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1602.094604,725.158813 
	C1601.947266,725.007751 1601.812622,724.804565 1601.677979,724.601440 
	C1601.786987,724.658936 1601.895996,724.716431 1602.108154,724.927979 
	C1602.211426,725.081909 1602.107300,725.106689 1602.094604,725.158813 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1506.139771,688.781677 
	C1507.431396,688.396729 1508.417114,688.176636 1509.402832,687.956604 
	C1509.490356,688.377441 1509.578003,688.798279 1509.665527,689.219177 
	C1508.365967,689.391479 1507.066528,689.566345 1505.764771,689.720947 
	C1505.733887,689.724609 1505.627441,689.495300 1505.642578,689.386841 
	C1505.663940,689.234192 1505.766113,689.092834 1506.139771,688.781677 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1501.280029,689.070312 
	C1500.866455,688.773071 1500.798828,688.590088 1500.810913,688.125366 
	C1501.742432,687.919800 1502.594238,687.996033 1503.446045,688.072327 
	C1503.462891,688.274109 1503.479736,688.475952 1503.496582,688.677795 
	C1502.873047,688.846680 1502.249390,689.015625 1501.280029,689.070312 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1458.292236,699.909973 
	C1458.126343,699.436157 1458.273560,698.833496 1458.718994,698.095337 
	C1458.880127,698.566895 1458.742676,699.174011 1458.292236,699.909973 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1459.309570,698.076477 
	C1459.130493,697.704346 1459.296143,697.383179 1459.741455,697.016113 
	C1459.898682,697.355896 1459.776489,697.741638 1459.309570,698.076477 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1460.320068,697.104614 
	C1460.130249,696.694702 1460.289795,696.368530 1460.735107,696.006836 
	C1460.903687,696.377014 1460.786499,696.782715 1460.320068,697.104614 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1224.168457,751.941895 
	C1223.460449,752.400330 1222.760742,752.435730 1222.060913,752.471069 
	C1222.082886,752.163818 1222.077515,751.594421 1222.130859,751.588806 
	C1222.807861,751.518433 1223.493408,751.528931 1224.168457,751.941895 
z"
    />
    <path
      fill="#F3F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M1281.338989,701.447815 
	C1280.337280,701.510559 1279.599731,701.316528 1278.862305,701.122437 
	C1279.507812,699.344299 1279.748413,697.175903 1280.947754,695.931458 
	C1281.692017,695.159119 1283.890259,695.788025 1286.387329,695.788025 
	C1284.342163,698.097778 1282.972656,699.644409 1281.338989,701.447815 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M2217.165039,857.564575 
	C2217.298340,855.715515 2217.764893,853.724915 2218.452393,850.791077 
	C2220.363037,852.856995 2221.427979,854.008484 2221.827881,854.440796 
	C2223.911133,853.634949 2225.662354,852.957458 2227.783936,852.136719 
	C2227.889404,853.966797 2227.964355,855.269531 2228.042236,856.624756 
	C2224.444824,856.897156 2220.971680,857.160156 2217.165039,857.564575 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1621.986572,711.591980 
	C1621.044922,711.578491 1620.079956,711.156982 1619.078003,710.393433 
	C1618.668335,709.103882 1618.528687,707.601685 1617.873779,707.326721 
	C1616.588623,706.787292 1615.016724,706.930969 1612.807129,706.719482 
	C1613.227295,702.480774 1613.618652,698.532593 1614.045288,694.230042 
	C1615.756958,694.820679 1617.802002,695.526306 1620.537842,696.470276 
	C1618.232300,697.807983 1616.693726,698.700623 1613.992065,700.268066 
	C1617.054077,700.878235 1618.699463,701.206177 1620.344849,701.534058 
	C1620.379272,701.781738 1620.413696,702.029480 1620.447998,702.277161 
	C1618.795288,702.727478 1617.142578,703.177734 1615.489868,703.628052 
	C1619.956665,704.421570 1622.344482,706.576233 1621.986572,711.591980 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1578.754761,709.772217 
	C1579.185547,708.403503 1579.616455,707.034790 1580.191406,705.208557 
	C1581.209595,706.004028 1581.854004,706.402527 1582.360962,706.932495 
	C1582.895874,707.491455 1583.299683,708.175903 1583.315796,708.198059 
	C1585.464600,707.336487 1587.111328,706.676147 1588.992554,705.921875 
	C1589.128662,707.371826 1589.248413,708.648010 1589.413330,710.404663 
	C1586.160278,710.404663 1583.170044,710.404663 1579.641357,710.259583 
	C1579.103027,710.114502 1578.754761,709.772217 1578.754761,709.772217 
z"
    />
    <path
      fill="#777777"
      opacity="1.000000"
      stroke="none"
      d="
M1579.270264,710.292236 
	C1579.103149,710.593018 1578.768799,710.716003 1578.434448,710.838989 
	C1578.412476,710.596680 1578.390503,710.354431 1578.561646,709.942139 
	C1578.754761,709.772217 1579.103027,710.114502 1579.270264,710.292236 
z"
    />
    <path
      fill="#868686"
      opacity="1.000000"
      stroke="none"
      d="
M1564.646973,707.463013 
	C1564.643188,707.944580 1564.646118,708.107422 1564.649170,708.270264 
	C1564.564087,708.266235 1564.404541,708.262817 1564.404541,708.258118 
	C1564.393799,706.956360 1564.393921,705.654541 1564.393921,704.420471 
	C1564.470947,704.311218 1564.500244,704.269592 1564.529419,704.228027 
	C1564.570801,705.200073 1564.612305,706.172241 1564.646973,707.463013 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2125.136230,853.580505 
	C2125.500488,853.240967 2125.842529,853.235657 2126.400391,853.389099 
	C2127.419922,853.903320 2128.223877,854.258728 2129.384033,854.622559 
	C2128.324707,854.953674 2126.909424,855.276489 2125.203125,855.421631 
	C2124.979492,854.800903 2125.046875,854.357788 2125.136230,853.580505 
z"
    />
    <path
      fill="#FCFCFC"
      opacity="1.000000"
      stroke="none"
      d="
M2165.413086,853.483887 
	C2165.288818,854.046387 2164.932373,854.319397 2164.576172,854.592407 
	C2164.450928,854.076355 2164.325928,853.560303 2164.424805,852.860657 
	C2164.826172,852.849487 2165.003418,853.021973 2165.413086,853.483887 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M198.249603,781.027466 
	C198.136520,780.741577 198.328873,780.439453 198.782089,780.042236 
	C198.880310,780.301819 198.717682,780.656555 198.249603,781.027466 
z"
    />
    <path
      fill="#404040"
      opacity="1.000000"
      stroke="none"
      d="
M197.262512,782.018921 
	C197.129318,781.718872 197.309631,781.396301 197.766541,781.010132 
	C197.887421,781.296509 197.731705,781.646423 197.262512,782.018921 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1422.598389,705.549744 
	C1421.549805,705.071838 1420.848877,704.422180 1420.147949,703.772522 
	C1421.231445,703.278198 1422.314819,702.783875 1423.398315,702.289490 
	C1423.247437,703.318970 1423.096680,704.348450 1422.598389,705.549744 
z"
    />
    <path
      fill="#C7C7C7"
      opacity="1.000000"
      stroke="none"
      d="
M1426.424805,703.414307 
	C1426.514404,703.322815 1426.535034,703.645386 1426.535034,703.645386 
	C1426.535034,703.645386 1426.335083,703.505737 1426.424805,703.414307 
z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ImageView',
};
</script>
